//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';


//React & Amplify
import React from 'react';
import  { API, Auth, graphqlOperation } from 'aws-amplify';

//Utils
import { getDataRecordById, invokeAPI } from "./databaseUtils";

//Queries and Mutations
import { processCustomerByLambda } from '../graphql/mutations';
import { getCustomer } from '../graphql/queries';


//Sorting object by 'name' field
export  function compareCustomersByName(a, b) {
	if (a.name.toUpperCase() > b.name.toUpperCase())  return 1;
	else if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
	return 0;
}

//Sorting object by 'customerName' field
export  function compareCustomersByCustomerName(a, b) {
	if (!a.customerName || !b.customerName) return 0;
	if (a.customerName.toUpperCase() > b.customerName.toUpperCase())  return 1;
	else if (a.customerName.toUpperCase() < b.customerName.toUpperCase()) return -1;
	return 0;
}

//Safe return of customer name from a customer object
export function getCustomerName(customer) {
	if (!customer || !customer.name) return ""; 
	return (customer.name);
}

//
// Main function to CREATE a new customer an all related DB records
// cmdIssuerID - optional, identifies the user that is issuing the command; may be blank for the signup wizard
//
export async function invokeCreateCustomer({ cmdIssuerID, customerToAdd, configDetailsToAdd, customerBillingToAdd }) {


	if (!customerToAdd || !configDetailsToAdd ||  !customerBillingToAdd ) {
		if (DEBUG_MODE >= 2) console.log("ERROR CREATING CUSTOMER - improper params",  customerToAdd, configDetailsToAdd, customerBillingToAdd );
		return ({successFlag:false, customer:null, error:'Unable to create customer'});             
	} 
	if (DEBUG_MODE >= 2) console.log("Issuing Create Customer Command to Lambda", cmdIssuerID, customerToAdd, configDetailsToAdd, customerBillingToAdd);
	
	try {
		//Direct call to our Enqueue Command lambda
		 const params = { cmdIssuerID, customerToAdd, configDetailsToAdd, customerBillingToAdd };
	 
		const enqueueCommandInput = {
			command: 'CREATE_CUSTOMER',
			params: JSON.stringify(params),
		};   
		
		//Call GraphQL to directly issue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to CREATE CUSTOMER", enqueueCommandInput);
		const response = await invokeAPI(processCustomerByLambda, 'processCustomerByLambda', enqueueCommandInput);
		if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		let returnParams;
		if (response) {
			//Turn the return string into an object
			if (response.returnParams) returnParams = JSON.parse(response.returnParams);
			if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);

			//Specific error?  If so, return it.
			if (returnParams && returnParams.error && response.lambdaReturnStatus === "FAILED") {
					return ({successFlag:false, customer:null, error:returnParams.error});             
			}
			
			//Success? If so, fetch our new customer via App Sync and return the object to the caller
			if (returnParams && returnParams.id && response.lambdaReturnStatus === "SUCCESS") {
				const tempCustomer =  await getDataRecordById(getCustomer, 'getCustomer', returnParams.id);
				if (DEBUG_MODE >= 2) console.log('Successfully wrote new customer to DB', tempCustomer);
				return ({successFlag:true, customer:tempCustomer, error:''});  
			} 
		} 
	
		//Must have failed if reached here 
		if (DEBUG_MODE >= 2) console.log('ERROR - unable to create the customer:');
		return ({successFlag:false, customer:null, error:'Unable to create customer'});             

	} catch (err) {
		 if (DEBUG_MODE >= 2) console.error('Error creating customer:', err);
		 return ({successFlag:false, customer:null, err:err});
	}   
}

//
// Main function to UPDATE a customer 
// cmdIssuerID - identifies the user that is issuing the command; may be blank for the signup wizard
//
export async function invokeUpdateCustomer({ cmdIssuerID, customerToEdit, configDetailsToEdit, customerBillingToEdit }) {


	if (!customerToEdit || !configDetailsToEdit ||  !customerBillingToEdit ) {
		if (DEBUG_MODE >= 2) console.log("ERROR CREATING CUSTOMER - improper params",  customerToEdit, configDetailsToEdit, customerBillingToEdit );
		return ({successFlag:false, customer:null, error:'Unable to create customer'});             
	} 
	if (DEBUG_MODE >= 2) console.log("Issuing Update Customer Command to Lambda", cmdIssuerID, customerToEdit, configDetailsToEdit, customerBillingToEdit);
	
	try {
		//Direct call to our Enqueue Command lambda
		 const params = { cmdIssuerID, customerToEdit, configDetailsToEdit, customerBillingToEdit };
		 
		const enqueueCommandInput = {
			 command: 'UPDATE_CUSTOMER',
			 params: JSON.stringify(params),
		};   
		
		//Call GraphQL to directly issue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to UPDATE CUSTOMER", enqueueCommandInput);
		const response = await invokeAPI(processCustomerByLambda, 'processCustomerByLambda', enqueueCommandInput);
		if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        
	
		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		let returnParams;
		if (response) {
			 //Turn the return string into an object
			 if (response.returnParams) returnParams = JSON.parse(response.returnParams);
			 if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);
	
			 //Specific error?  If so, return it.
			 if (returnParams && returnParams.error && response.lambdaReturnStatus === "FAILED") {
					return ({successFlag:false, customer:null, error:returnParams.error});             
			 }
			 
			 //Success? If so, fetch our new customer via App Sync and return the object to the caller
			 if (returnParams && returnParams.id && response.lambdaReturnStatus === "SUCCESS") {
				  const tempCustomer =  await getDataRecordById(getCustomer, 'getCustomer', returnParams.id);
				  if (DEBUG_MODE >= 2) console.log('Successfully updated customer', tempCustomer);
				  return ({successFlag:true, customer:tempCustomer, error:''});  
			 } 
		  } 
	 
		 //Must have failed if reached here 
		if (DEBUG_MODE >= 2) console.log('ERROR - unable to update the customer:');
		return ({successFlag:false, customer:null, error:'Unable to update customer'});             
	
		} catch (err) {
			 if (DEBUG_MODE >= 2) console.error('Error updating customer:', err);
			 return ({successFlag:false, customer:null, err:err});
		}   
	}
	
export async function invokeDeleteCustomer({customerToDelete, cmdIssuerID}) {
    
	//Safety Check
	if (!cmdIssuerID || !customerToDelete || !customerToDelete.id) {
		 if (DEBUG_MODE >= 2) console.log("Error deleting customer - improper params", cmdIssuerID, customerToDelete);
		 return false;
	}
	if (DEBUG_MODE >= 2) console.log("Issuing DELETE User to Lambda", customerToDelete);

	try {

	 //Direct call to our Enqueue Command lambda
	 const params = {
			  cmdIssuerID: cmdIssuerID,           //Who issued the this delete CMD
	 };
	 
	const enqueueCommandInput = {
		 customerID: customerToDelete.id,    //ID of the customer to delete
		 command: 'DELETE_CUSTOMER',
		 params: JSON.stringify(params),
	};   
	
	//Call GraphQL to directly issue the command with our Lambda function 
	if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to DELETE CUSTOMER", enqueueCommandInput);
	const response = await invokeAPI(processCustomerByLambda, 'processCustomerByLambda', enqueueCommandInput);
	if (DEBUG_MODE >= 2) console.log("ProcessCustomerByLambda returned", response);        

	//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
	if (response) {
		let returnParams;
		//Turn the return string into an object
		 if (response.returnParams) returnParams = JSON.parse(response.returnParams);
		 if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);

		//Specific error?  If so, return it.
		if (returnParams && returnParams.error && response.lambdaReturnStatus === "FAILED") {
			return ({successFlag:false, customer:response.returnParams, error:returnParams.error});             
		}
		
		//Success? 
		if (returnParams && returnParams.id && response.lambdaReturnStatus === "SUCCESS") {
			return ({successFlag:true, customer:returnParams, error:''});  
		} 
	}

	//Must have failed if reached here 
	if (DEBUG_MODE >= 2) console.log('ERROR - unable to delete the customer:');
	return ({successFlag:false, customer:null, error:'Unable to delete customer'});  

	} catch (err) {
		 if (DEBUG_MODE >= 2) console.log('error deleting customer:', err);
		 return ({successFlag:false});
	}
}
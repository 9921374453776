//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import "./productDetailedView.css";


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR, COLOR_WHITE, ENABLE_30_PERCENT_DISCOUNT} from '../../data/conectereConfigData'; 

//Amplify, React
import React from 'react';

//Our components
import ModalNoBackgroundFixed from "../modalNoBackgroundFixed/modalNoBackgroundFixed";

//Utils
import { truncateStringByWord, displayAwards, truncateStringWithDots} from "../../utils/generalUtils";

import CloseIcon from '@mui/icons-material/Close';


const ProductDetailedView = ({ showModal, product, handleProductOrder, handleCancelDetailedView, displayCustomer, dollarToCoinConversion }) => {
    
    //Upon load, determine if the screen already has a Y position, i.e, has been scrolled down
    //If so, use that for TOP in our position ABSOLUTE so the modal pops up centered on the current screen rather than back up on the top
    //Note, we have to use position ABSOLUTE so that screen scroll with the page if the user scrolls down to read the rest of the modal
    //Also, we need to read the full height of the entire document and dynamically set the height of our background layer so that it covers the entire document
    //as the user scrolls up/down
    
    var screenPositionY = window.scrollY;
    const documentHeight = document.body.clientHeight;

   //Recompute the coins needed based on any company-specific coin value set by the admin
   //Basically, this overrides the pre-computed coins
   let productPrice = 0;
   if (product.dollarCost) {
      productPrice= (dollarToCoinConversion ? dollarToCoinConversion*product.dollarCost : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION*product.dollarCost);
   }

    //Any Special Events that discount our coins?
    if (ENABLE_30_PERCENT_DISCOUNT) {
        productPrice = Math.floor(productPrice * 0.70);
        console.log("30% discount enabled and applied.  New price:", productPrice);
    }

    return (
 
        <ModalNoBackgroundFixed showModal={showModal} closeCallback={handleCancelDetailedView} cardColor={TEAM_COLOR} closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR} noWidth={true} noHeight={true}>
            <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
                <img className="avatarImageCenterPortrait ccImagePadding"  src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt='' />  
            </div>
            <div  className="productDetailedCard" >
                <div className="ContainerVerticalStartStart">
                    <div className="ContainerNoHeightCenter fullWidth imageContainerNoRadius">
                            <img src={product.image} alt={''} className="ccImgXXXXXXL" /> 
                    </div>
                    <h3 className="productDetailedHeader grey fullWidth">
                        {truncateStringByWord(product.title,200, false)}
                    </h3>
                    <div className="TextStyle4 black" > {truncateStringByWord(product.description, 500, true)}   </div>             
                     {product.externalLink &&  product.externalLink.length > 7 ? 
                            <a href={product.externalLink} target="_blank" > <span className="blue"> (more details) </span> </a>
                        :
                        null
                    }            
                    <div className="productDetailedCostRow ContainerNoHeightFlexLeft">
                        {displayAwards({fontSize:'lg', coins:productPrice, badgesDEI:product.badgesDEI, badgesCS:product.badgesCS,  badgesSpotlight:product.badgesSpotlight})}
                    </div>
                    <div className="productDetailedButtonRow">
                        <button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleProductOrder} > Order </button>
                        <button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleCancelDetailedView} > Cancel </button> 
                    </div>
                </div >

                {displayCustomer ?   
                    <div className="productCustomerNameRow" > 
                        { product.managedByLogo ? 
                            <img className="ccImgXL" src={product.managedByLogo}  alt='product'/>
                        : <div className="TextStyle2">{product.managedByName}</div>
                        }
                    </div> 
                    :
                    null
                }  
            </div>                      
        </ModalNoBackgroundFixed >

    );
    
};

export default ProductDetailedView;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023

//  ADMIN for managing New Employee Support program


//Config Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, NEW_EMPLOYEE_SUPPORT_PROGRAM, TEAM_COLOR, IMAGE_COMMUNITY_1} from '../../shared/data/conectereConfigData';

import { HELP_TEXT_ADMIN_NEW_EMPLOYEE_SUPPORT_1 } from '../../shared/data/helpData.js';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL;
import { Link } from 'react-router-dom';

//Queries and Mutations
import {  updateConectereConfig } from '../../shared/graphql/mutations';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Checkbox from '@mui/material/Checkbox';

import Switch from '@mui/material/Switch';
import Select from 'react-select';   //Version with support from multiple selections

//Icons
import { DeleteOutline} from '@material-ui/icons';
import SettingsIcon from '@mui/icons-material/Settings';

//Our Components
import ConectivityDetailedView from "../../shared/Components/conectivityDetailedView/conectivityDetailedView";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { DisplayConectivitySelectComponent, setConectivityImageUrlByCategory, setHeaderColorByCategory } from "../../shared/utils/conectivityUtils";
import { OpenButton,  HelpButton, EditButton, DeleteButton, SettingsButton, truncateStringWithDots } from "../../shared/utils/generalUtils";
import { storeUpdatedCustomerConfig }  from "../../shared/utils/databaseUtils";

//Icons
import { IconButton } from '@mui/material';

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import moment from 'moment';


const AdminNewEmployeeSupport = () => {

   // Context
   const { authState, currentUser,  isSuperAdmin,  isAdmin,  permissionAnalytics, } = useContext(AuthContext);    
   const { conectivitiesReadyForLaunch, conectivitiesReadyForLaunchOptions } = useContext(ConectivityContext);
   const { setShowSpinner } = useContext(DisplayContext); 
   const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

    //Master Config Details
    const [configDetailsToEdit, setConfigDetailsToEdit] = useState(null);
    const [inEditMode, setInEditMode] = useState(false);
  
    // New Employee Scheduling Options
    const [selectedConectivityNewEmpOnboarding,setSelectedConectivityNewEmpOnboarding] = useState(null);
    const [selectedConectivityNewEmpWelcome, setSelectedConectivityNewEmpWelcome] = useState(null);
    const [selectedConectivityNewEmpOneOnOneSupervisor, setSelectedConectivityNewEmpOneOnOneSupervisor] = useState(null);
    const [selectedConectivityNewEmpOneOnOneMentor, setSelectedConectivityNewEmpOneOnOneMentor] = useState(null);
    const [selectedConectivityNewEmpOneOnOneSponsor, setSelectedConectivityNewEmpOneOnOneSponsor] = useState(null);
    const [selectedConectivityNewEmpLunch, setSelectedConectivityNewEmpLunch] = useState(null);
    const [newEmpOnboardingChecked, setNewEmpOnboardingChecked] = useState(true);
    const [newEmpWelcomeChecked, setNewEmpWelcomeChecked] = useState(true);
    const [newEmpOneOnOneSupervisorChecked, setNewEmpOneOnOneSupervisorChecked] = useState(true);
    const [newEmpOneOnOneMentorChecked, setNewEmpOneOnOneMentorChecked] = useState(true);
    const [newEmpOneOnOneSponsorChecked, setNewEmpOneOnOneSponsorChecked] = useState(true);
    const [newEmpLunchChecked, setNewEmpLunchChecked] = useState(true);
    const [conectivityNewEmpOnboarding,setConectivityNewEmpOnboarding] = useState(null);
    const [conectivityNewEmpWelcome,setConectivityNewEmpWelcome] = useState(null);
    const [conectivityNewEmpOneOnOneSupervisor,setConectivityNewEmpOneOnOneSupervisor] = useState(null);
    const [conectivityNewEmpOneOnOneMentor,setConectivityNewEmpOneOnOneMentor] = useState(null);
    const [conectivityNewEmpOneOnOneSponsor,setConectivityNewEmpOneOnOneSponsor] = useState(null);
    const [conectivityNewEmpLunch,setConectivityNewEmpLunch] = useState(null);
    const [newEmpOnboardingTime, setNewEmpOnboardingTime] = useState(null);
    const [newEmpWelcomeTime, setNewEmpWelcomeTime] = useState(null);
    const [newEmpOneOnOneSupervisorTime, setNewEmpOneOnOneSupervisorTime] = useState(null);
    const [newEmpOneOnOneMentorTime, setNewEmpOneOnOneMentorTime] = useState(null);
    const [newEmpOneOnOneSponsorTime, setNewEmpOneOnOneSponsorTime] = useState(null);
    const [newEmpLunchTime, setNewEmpLunchTime] = useState(null);
 
    //Init some defaults
    const DEFAULT_NEW_EMPLOYEE_ONBOARDING_CONECTIVITY_ID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === 'ONBOARDING').id;
    const DEFAULT_NEW_EMPLOYEE_WELCOME_CONECTIVITY_ID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === 'WELCOME').id;
    const DEFAULT_NEW_EMPLOYEE_LUNCH_CONECTIVITY_ID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === 'LUNCH').id;
    const DEFAULT_NEW_EMPLOYEE_ONEONONE_SUPERVISOR_CONECTIVITY_ID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === 'ONEONONE_SUPERVISOR').id;
    const DEFAULT_NEW_EMPLOYEE_ONEONONE_MENTOR_CONECTIVITY_ID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === 'ONEONONE_MENTOR').id;
    const DEFAULT_NEW_EMPLOYEE_ONEONONE_SPONSOR_CONECTIVITY_ID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === 'ONEONONE_SPONSOR').id;
        
    //Conectivity Previewing
    const [showModalConectivityDetailed, setShowModalConectivityDetailed] = useState(false);      //Detailed Conectivity View
    const [conectivityToView, setConectivityToView] = useState(null);              //Used to hold a conectivity that is to be launched
    
     //state variables for messages

        
    //Copy the customer's settings upon opening the page or any change to the edit mode
    useEffect(() => {
        
       //Safety check
        if (authState !== "signedin" || !currentUser || !currentUser.customer || !currentUser.customer.configDetails || !conectivitiesReadyForLaunch || conectivitiesReadyForLaunch.length === 0)  {
            console.log("NOT YET", authState, currentUser, conectivitiesReadyForLaunch);
            return;
        }

        buildPageData();
    }, [conectivitiesReadyForLaunch]);


/*
    //rebuild page data upon any change to our local config details, like loading the page or the Program Toggle
    useEffect(() => {
        rebuildPageData();
    }, [configDetailsToEdit]);
*/

    async function buildPageData() {
        
        if (!currentUser || !currentUser.customer || !currentUser.customer.configDetails) return;
        
        try {
             //Load New Employee Support options based on customer data
            let configDetails = {...currentUser.customer.configDetails};    //Make a copy
            setConfigDetailsToEdit(configDetails);                          //Store in our local React state var for the page
            
            if (DEBUG_MODE >= 2) console.log("initializing new employee support vars", configDetails);
            storeNewEmployeeConectivityConfigData(configDetails.newEmpConectivitySchedulingEnabled, configDetails.newEmployeeOnboardingConectivityID, "ONBOARDING", configDetails.newEmployeeOnboardingConectivityStartTime, setSelectedConectivityNewEmpOnboarding, setConectivityNewEmpOnboarding, setNewEmpOnboardingChecked, setNewEmpOnboardingTime); 
            storeNewEmployeeConectivityConfigData(configDetails.newEmpConectivitySchedulingEnabled, configDetails.newEmployeeWelcomeConectivityID, "WELCOME", configDetails.newEmployeeWelcomeConectivityStartTime, setSelectedConectivityNewEmpWelcome, setConectivityNewEmpWelcome, setNewEmpWelcomeChecked, setNewEmpWelcomeTime); 
            storeNewEmployeeConectivityConfigData(configDetails.newEmpConectivitySchedulingEnabled, configDetails.newEmployeeOneOnOneSupervisorConectivityID, "ONEONONE_SUPERVISOR", configDetails.newEmployeeOneOnOneSupervisorConectivityStartTime, setSelectedConectivityNewEmpOneOnOneSupervisor, setConectivityNewEmpOneOnOneSupervisor, setNewEmpOneOnOneSupervisorChecked, setNewEmpOneOnOneSupervisorTime); 
            storeNewEmployeeConectivityConfigData(configDetails.newEmpConectivitySchedulingEnabled, configDetails.newEmployeeOneOnOneMentorConectivityID, "ONEONONE_MENTOR", configDetails.newEmployeeOneOnOneMentorConectivityStartTime, setSelectedConectivityNewEmpOneOnOneMentor, setConectivityNewEmpOneOnOneMentor, setNewEmpOneOnOneMentorChecked, setNewEmpOneOnOneMentorTime); 
            storeNewEmployeeConectivityConfigData(configDetails.newEmpConectivitySchedulingEnabled, configDetails.newEmployeeOneOnOneSponsorConectivityID, "ONEONONE_SPONSOR", configDetails.newEmployeeOneOnOneSponsorConectivityStartTime, setSelectedConectivityNewEmpOneOnOneSponsor, setConectivityNewEmpOneOnOneSponsor, setNewEmpOneOnOneSponsorChecked, setNewEmpOneOnOneSponsorTime); 
            storeNewEmployeeConectivityConfigData(configDetails.newEmpConectivitySchedulingEnabled, configDetails.newEmployeeLunchConectivityID, "LUNCH", configDetails.newEmployeeLunchConectivityStartTime, setSelectedConectivityNewEmpLunch, setConectivityNewEmpLunch, setNewEmpLunchChecked, setNewEmpLunchTime); 
       
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error building page data", err);
        } 
    }

    function storeNewEmployeeConectivityConfigData(newEmpConectivitySchedulingEnabled, conectivityID, slotLabel, configuredStartTime, selectedConectivityOptionSetter, conectivitySetter, checkedSetter, startTimeSetter) {

        //Program enabled?
        if (newEmpConectivitySchedulingEnabled && conectivityID) {
            
            checkedSetter(true);    //Enable the checkbox for this timeslot
            selectedConectivityOptionSetter(conectivitiesReadyForLaunchOptions.find(c => c.id === conectivityID));
            conectivitySetter(conectivitiesReadyForLaunch.find(c => c.id === conectivityID));

            //Set the start time either based on a previous admin selection or our default
            let eventStartDateTimeMom = moment().startOf('day');   //Init to the start of the day in the Admin current TimeZone
      
            if (configuredStartTime && configuredStartTime.startHour && configuredStartTime.startMin) {
                eventStartDateTimeMom.set({'hour': configuredStartTime.startHour, 'minute': configuredStartTime.startMin, 'second':0});      
                console.log("Set timeslot based on customer config", slotLabel, eventStartDateTimeMom.toISOString());
            } else {
                const newEmployeeProgramSlot = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slotLabel);    
                console.log("Fetched new employee slot",newEmployeeProgramSlot);
                eventStartDateTimeMom.set({'hour': newEmployeeProgramSlot.startHour, 'minute': newEmployeeProgramSlot.startMinutes, 'second':0});      
                console.log("Set timeslot based on NEW EMPLOYEE SUPPORT DEFAULTS", slotLabel, eventStartDateTimeMom.toISOString());
            }
            startTimeSetter(eventStartDateTimeMom);   //Save the event start back to the react state var    
            
        } else {
            selectedConectivityOptionSetter(null);
            checkedSetter(false);
            conectivitySetter(null);
            startTimeSetter(null);
        }   
    }

      //handle detailed view of conectivity 
    function handleDetailedView (conectivityToViewID) {
        setConectivityToView(conectivitiesReadyForLaunch.find(c => c.id === conectivityToViewID));
        setShowModalConectivityDetailed(true);
    } 

     //handle close detailed view
    const handleCancelDetailedView = () => {
        setShowModalConectivityDetailed(false);
        setConectivityToView(null);
    };
    
    function displayAutomaticallyScheduledConectivityTimeSlotText(slot, time, setTime, disabled) {
        // console.log("Displaying timeslot",slot)
        if (!slot) return null;
        const timeSlot = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(sl => sl.label === slot);
        if (!timeSlot) return null;
        return (
            <>
                <div className="TextStyle4" >
                    {disabled ?
                         <i>when: {time ? time.format('h:mm a') : timeSlot.startTime}</i> 
                    :
    
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                              <TimePicker
                                label='time'
                                value={time}
                                disabled={disabled }
                                onChange={setTime}
                              />
                        </LocalizationProvider>
                    }
                </div>
                <div className="TextStyle4" style={{padding:"5px 0px"}}> <i>invitees: {timeSlot.whoText}</i>  </div>
            </>
        );
    }   

    function displayAutomaticallyScheduledConectivityTimeSlot({slot, disabled, checkedState, setCheckedState, conectivity, setConectivity, selectedConectivityOption, setSelectionConectivityOption,  time, setTime, zIndex}) {

    if (!slot) return;
    const text = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot).text;
    const helpText = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot).helpText;
    const defaultConectivityID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot).id;
    
        return (
            <div className="ContainerNoHeight fullWidth" style={{padding:"2.0rem", zIndex:zIndex}}>
                 <div className="ContainerVerticalStartStart fullWidth" style={{flex:"1"}}>
                    <div className="ContainerNoHeightFlexLeft ">
                             <Checkbox
                              checked={checkedState}
                              disabled={disabled}
                              onChange={()=>{
                                setCheckedState(!checkedState);
                                if(checkedState) {   //Transitioning to Unchecked? If so, clear selections
                                    setConectivity(null); 
                                    setSelectionConectivityOption(null);
                                    setTime(null);
                                } else { //Transitioning to CHECKED? Load up the defaults
                                    setSelectionConectivityOption(conectivitiesReadyForLaunchOptions.find(c => c.id === defaultConectivityID));
                                    setConectivity(conectivitiesReadyForLaunch.find(c => c.id === defaultConectivityID));
                                    const newEmployeeProgramSlot = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot);    
                                    let eventStartDateTimeMom = moment().startOf('day');   //Init to the start of the day in the Admin current TimeZone
                                    eventStartDateTimeMom.set({'hour': newEmployeeProgramSlot.startHour, 'minute': newEmployeeProgramSlot.startMinutes, 'second':0}); 
                                    setTime(eventStartDateTimeMom);
                                }
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <div className="TextStyle4 headerBlue">{text}</div>
                            <HelpButton hoverText={helpText}  /> 
                    </div>
                    
                    <div className="ContainerVerticalStartStart fullWidth" style={{paddingLeft:"3.0rem"}}>
                        <div className="ContainerNoHeightFlexLeft " style={{minWidth:"600px"}}>
                            {disabled ?
                                <div className="ContainerNoHeightFlexLeft" style={{minWidth:"250px"}}> 
                                    {!selectedConectivityOption ? null :
                                        <>
                                            <img className="ccImgLarge" src={setConectivityImageUrlByCategory (selectedConectivityOption.shortLabel, true)} alt="Conectivity" />
                                            <span style={{paddingLeft: "5px",color: setHeaderColorByCategory(selectedConectivityOption.shortLabel), fontSize:"16px"}}>{truncateStringWithDots(selectedConectivityOption.title,50)}</span> 
                                        </>
                                    }
                                </div>                       
                            :
                               <DisplayConectivitySelectComponent 
                                    name="conectivityOnboardingDropdown" 
                                    options={conectivitiesReadyForLaunchOptions} 
                                    onChangeCallback={(option) => {
                                        setSelectionConectivityOption(option);
                                        if (option) setConectivity(conectivitiesReadyForLaunch.find(c => c.id === option.id)); else setConectivity(null);
                                        }} 
                                    isMulti={false} 
                                    value={selectedConectivityOption} 
                                    disabled={!checkedState}  
                                    placeholder=""
                                    padding="0px"
                                />
                            }
                            {checkedState ? <div><OpenButton hoverText="Preview" onClickCallback={handleDetailedView} onClickCallbackParam={defaultConectivityID}/> </div> : null }
                        </div>
                        {checkedState ? displayAutomaticallyScheduledConectivityTimeSlotText(slot, time, setTime, disabled) : null}
                    </div>
                    
                </div>    
                {/* <div className="ContainerVertical blue fullWidth" style={{flex:"1", position:"relative"}}>
                    <DisplayCardConectivityPreview isSmall conectivityToPreview={conectivityNewEmpOnboarding} hideViewButton={true}/> 
                </div>
                */}
            </div> 
            );
    }

function initNewEmployeeConfigData({slotLabel, enableSlot, selectedConectivityOptionSetter, conectivitySetter, checkedSetter, startTimeSetter}) {

    //Program enabled?
    if (enableSlot) {
        checkedSetter(true);    //Enable the checkbox for this timeslot
        const DEFAULT_CONECTIVITY_ID =  NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slotLabel).id;
        selectedConectivityOptionSetter(conectivitiesReadyForLaunchOptions.find(c => c.id === DEFAULT_CONECTIVITY_ID));
        conectivitySetter(conectivitiesReadyForLaunch.find(c => c.id === DEFAULT_CONECTIVITY_ID));
         

        //Set the start time either based on a previous admin selection or our default
        let eventStartDateTimeMom = moment().startOf('day');   //Init to the start of the day in the Admin current TimeZone
        const newEmployeeProgramSlot = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slotLabel);    
        console.log("Fetched new employee slot",newEmployeeProgramSlot);
        eventStartDateTimeMom.set({'hour': newEmployeeProgramSlot.startHour, 'minute': newEmployeeProgramSlot.startMinutes, 'second':0});      
        console.log("Set timeslot based on NEW EMPLOYEE SUPPORT DEFAULTS", slotLabel, eventStartDateTimeMom.toISOString());
        startTimeSetter(eventStartDateTimeMom);   //Save the event start back to the react state var    
        
    } else {
        selectedConectivityOptionSetter(null);
        checkedSetter(false);
        conectivitySetter(null);
        startTimeSetter(null);
    }   
}
    
    const handleNewEmployeeEnableProgramToggle = () => {
        
        //Toggle the value, which will trigger a rebuild of the page data based on current settings
        setConfigDetailsToEdit({ ...configDetailsToEdit, newEmpConectivitySchedulingEnabled: !configDetailsToEdit.newEmpConectivitySchedulingEnabled });
    
       
        //Transitioning to DISALBED?
        if (configDetailsToEdit.newEmpConectivitySchedulingEnabled) {
            
            initNewEmployeeConfigData({slotLabel:'ONBOARDING', enableSlot:false, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOnboarding, conectivitySetter:setConectivityNewEmpOnboarding, checkedSetter:setNewEmpOnboardingChecked, startTimeSetter:setNewEmpOnboardingTime});
            initNewEmployeeConfigData({slotLabel:'WELCOME', enableSlot:false, selectedConectivityOptionSetter:setSelectedConectivityNewEmpWelcome, conectivitySetter:setConectivityNewEmpWelcome, checkedSetter:setNewEmpWelcomeChecked, startTimeSetter:setNewEmpWelcomeTime});
            initNewEmployeeConfigData({slotLabel:'ONEONONE_SUPERVISOR', enableSlot:false, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOneOnOneSupervisor, conectivitySetter:setConectivityNewEmpOneOnOneSupervisor, checkedSetter:setNewEmpOneOnOneSupervisorChecked, startTimeSetter:setNewEmpOneOnOneSupervisorTime});
            initNewEmployeeConfigData({slotLabel:'ONEONONE_MENTOR', enableSlot:false, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOneOnOneMentor, conectivitySetter:setConectivityNewEmpOneOnOneMentor, checkedSetter:setNewEmpOneOnOneMentorChecked, startTimeSetter:setNewEmpOneOnOneMentorTime});
            initNewEmployeeConfigData({slotLabel:'ONEONONE_SPONSOR', enableSlot:false, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOneOnOneSponsor, conectivitySetter:setConectivityNewEmpOneOnOneSponsor, checkedSetter:setNewEmpOneOnOneSponsorChecked, startTimeSetter:setNewEmpOneOnOneSponsorTime});
            initNewEmployeeConfigData({slotLabel:'LUNCH', enableSlot:false, selectedConectivityOptionSetter:setSelectedConectivityNewEmpLunch, conectivitySetter:setConectivityNewEmpLunch, checkedSetter:setNewEmpLunchChecked, startTimeSetter:setNewEmpLunchTime});

        } else {
                       
            initNewEmployeeConfigData({slotLabel:'ONBOARDING', enableSlot:true, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOnboarding, conectivitySetter:setConectivityNewEmpOnboarding, checkedSetter:setNewEmpOnboardingChecked, startTimeSetter:setNewEmpOnboardingTime});
            initNewEmployeeConfigData({slotLabel:'WELCOME', enableSlot:true, selectedConectivityOptionSetter:setSelectedConectivityNewEmpWelcome, conectivitySetter:setConectivityNewEmpWelcome, checkedSetter:setNewEmpWelcomeChecked, startTimeSetter:setNewEmpWelcomeTime});
            initNewEmployeeConfigData({slotLabel:'ONEONONE_SUPERVISOR', enableSlot:true, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOneOnOneSupervisor, conectivitySetter:setConectivityNewEmpOneOnOneSupervisor, checkedSetter:setNewEmpOneOnOneSupervisorChecked, startTimeSetter:setNewEmpOneOnOneSupervisorTime});
            initNewEmployeeConfigData({slotLabel:'LUNCH', enableSlot:true, selectedConectivityOptionSetter:setSelectedConectivityNewEmpLunch, conectivitySetter:setConectivityNewEmpLunch, checkedSetter:setNewEmpLunchChecked, startTimeSetter:setNewEmpLunchTime});
            if(configDetailsToEdit.mentorProgramEnabled) initNewEmployeeConfigData({slotLabel:'ONEONONE_MENTOR', enableSlot:true, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOneOnOneMentor, conectivitySetter:setConectivityNewEmpOneOnOneMentor, checkedSetter:setNewEmpOneOnOneMentorChecked, startTimeSetter:setNewEmpOneOnOneMentorTime});
            if(configDetailsToEdit.sponsorProgramEnabled) initNewEmployeeConfigData({slotLabel:'ONEONONE_SPONSOR', enableSlot:true, selectedConectivityOptionSetter:setSelectedConectivityNewEmpOneOnOneSponsor, conectivitySetter:setConectivityNewEmpOneOnOneSponsor, checkedSetter:setNewEmpOneOnOneSponsorChecked, startTimeSetter:setNewEmpOneOnOneSponsorTime});
        }
    };  
    
    const handlePutInEditMode = () => {
        if (DEBUG_MODE >= 2) console.log("User entered edit mode.");
        setInEditMode(true);
    };

   //Process the SAVE function from Edit mode for updating customer
    async function handleConfirmationEdit (e) {
        e.preventDefault();     //prevent React page refresh
        setShowSpinner(true); //Show spinners
        setInEditMode(false); // Shut down the edit mode
        try {
            
        let tempConfigToEdit = {
                id: configDetailsToEdit.id,

                //New Employee Support Program config
                newEmpConectivitySchedulingEnabled:(configDetailsToEdit.newEmpConectivitySchedulingEnabled ? true : false),
 
                //Selected Conectivities
                newEmployeeOnboardingConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpOnboarding ? conectivityNewEmpOnboarding.id : null),
                newEmployeeWelcomeConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpWelcome ? conectivityNewEmpWelcome.id : null),
                newEmployeeOneOnOneSupervisorConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  &&  conectivityNewEmpOneOnOneSupervisor ? conectivityNewEmpOneOnOneSupervisor.id : null),
                newEmployeeOneOnOneMentorConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpOneOnOneMentor ? conectivityNewEmpOneOnOneMentor.id : null),
                newEmployeeOneOnOneSponsorConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpOneOnOneSponsor ? conectivityNewEmpOneOnOneSponsor.id : null),
                newEmployeeLunchConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpLunch ? conectivityNewEmpLunch.id : null),

                //Start Times
 /*               newEmployeeOnboardingConectivityTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpOnboardingTime ? newEmpOnboardingTime.toISOString() : null),
                newEmployeeWelcomeConectivityTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpWelcomeTime ? newEmpWelcomeTime.toISOString() : null),
                newEmployeeOneOnOneSupervisorConectivityTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  &&  newEmpOneOnOneSupervisorTime ? newEmpOneOnOneSupervisorTime.toISOString() : null),
                newEmployeeOneOnOneMentorConectivityTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpOneOnOneMentorTime ? newEmpOneOnOneMentorTime.toISOString() : null),
                newEmployeeOneOnOneSponsorConectivityTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpOneOnOneSponsorTime ? newEmpOneOnOneSponsorTime.toISOString() : null),
                newEmployeeLunchConectivityTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpLunchTime ? newEmpLunchTime.toISOString() : null),
*/ 
                newEmployeeOnboardingConectivityStartTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpOnboardingTime ? {startHour: newEmpOnboardingTime.get('hour'), startMin:newEmpOnboardingTime.get('minute') } : null),
                newEmployeeWelcomeConectivityStartTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpWelcomeTime ? {startHour: newEmpWelcomeTime.get('hour'), startMin:newEmpWelcomeTime.get('minute') }: null),
                newEmployeeOneOnOneSupervisorConectivityStartTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  &&  newEmpOneOnOneSupervisorTime ? {startHour: newEmpOneOnOneSupervisorTime.get('hour'), startMin:newEmpOneOnOneSupervisorTime.get('minute') } : null),
                newEmployeeOneOnOneMentorConectivityStartTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpOneOnOneMentorTime ? {startHour: newEmpOneOnOneMentorTime.get('hour'), startMin:newEmpOneOnOneMentorTime.get('minute') } : null),
                newEmployeeOneOnOneSponsorConectivityStartTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpOneOnOneSponsorTime ? {startHour: newEmpOneOnOneSponsorTime.get('hour'), startMin:newEmpOneOnOneSponsorTime.get('minute') } : null),
                newEmployeeLunchConectivityStartTime:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && newEmpLunchTime ? {startHour: newEmpLunchTime.get('hour'), startMin:newEmpLunchTime.get('minute') } : null),
 
            }; 
            
            if (DEBUG_MODE >= 2) console.log("Writing customer config", tempConfigToEdit);
            const tempInsertedConfig = await storeUpdatedCustomerConfig(tempConfigToEdit);
            if (DEBUG_MODE >= 2) console.log("Successfully edited customer config", tempInsertedConfig );

            setModalGeneralMessage("Changes saved ");
            setModalGeneralTitle("Success!");       
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error editing customer config:', err);
            setModalGeneralMessage("Unable to save changes to customer.  ", err);
            setModalGeneralTitle("Ooops!");                    

        }
        
        setShowSpinner(false); //Hide spinners
        setShowModalGeneral(true); //display error message
    }
 
    const handleResetEditForm = () => {
        //Reset any changed - Load New Employee Support options based on customer data
        buildPageData();
        setInEditMode(false);
        if (DEBUG_MODE >= 2) console.log("Exiting edit mode; Restored original config data");
    };
    
    //Render function for authenticated user 
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else  return (

    <Authenticator>
        <div className="adminPage">
            <div className="dashboardContainer boxShadow" style={{position:"relative"}}>
                <div className="dashboardTitle ">
                    <div className="ContainerNoHeightSpaceBetween" >
                        <div className="ContainerNoHeightCenter">
                            <div>NEW EMPLOYEE ONBOARDING</div>
                            <HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_ADMIN_NEW_EMPLOYEE_SUPPORT_1}  /> 
                        </div>
                        <div className="ContainerNoHeightCenter">
                            {!inEditMode ? 
                                <EditButton onClickCallback={handlePutInEditMode} hoverText="Edit"/> 
                            : 
                                null 
                            }
                        </div>
                    </div>
                 </div>   
                 
                <div className="ContainerVerticalSpaceAround" >
                    <div className="ContainerNoHeightCenter purple" style={{padding:"2rem 0rem"}}>
                        <h3> Support Your New Employees! </h3>
                    </div>
                    <div className="ContainerNoHeightCenter" style = {{padding:"5px 10px"}}>
                         <div> {(configDetailsToEdit && configDetailsToEdit.newEmpConectivitySchedulingEnabled ? 'Program Enabled' : 'Program Disabled' )} </div> 
                            <Switch
                                name="enableNewEmployeeSupportProgram"
                                checked={(configDetailsToEdit && configDetailsToEdit.newEmpConectivitySchedulingEnabled ? true : false)}   
                                onChange={handleNewEmployeeEnableProgramToggle}
                                color="primary"
                                disabled={inEditMode ? false : true}
                            />
                   </div>
                   
                   <div className="ContainerNoHeightCenter wrap" style={{padding:"2.0rem"}}>
                        <div style={{flex:"1"}}> 
                            <p className="black-text">A positive onboarding experience sets the stage for an exciting and impactful career within your organization. 
                            A new employee's first few days provide a great opportunity to ensure that he or she quickly feels welcome and appreciated </p>
                            <p  className="black-text" style={{paddingTop:"1.0rem"}}>Customize your new employee's experience to reflect your company's culture, and automate the scheduling of conectivities to 
                            easily connect them with colleagues, team leads, mentors, supervisors, and direct them to important resources and material. </p>
                        </div>    
                        <div className="imageContainer" style={{flex:"1"}}><img className="ccImgXXXXXL" src={IMAGE_COMMUNITY_1} alt=''/> </div>
                    </div>
        
                    {!configDetailsToEdit ? null : 
                    <>
                        {displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONBOARDING", disabled:(!inEditMode), checkedState:newEmpOnboardingChecked, setCheckedState:setNewEmpOnboardingChecked, conectivity:conectivityNewEmpOnboarding, setConectivity:setConectivityNewEmpOnboarding, selectedConectivityOption:selectedConectivityNewEmpOnboarding, setSelectionConectivityOption:setSelectedConectivityNewEmpOnboarding, time:newEmpOnboardingTime, setTime:setNewEmpOnboardingTime, zIndex:"100"})} 
                        {displayAutomaticallyScheduledConectivityTimeSlot({slot:"WELCOME", disabled:(!inEditMode), checkedState:newEmpWelcomeChecked, setCheckedState:setNewEmpWelcomeChecked, conectivity:conectivityNewEmpWelcome, setConectivity:setConectivityNewEmpWelcome, selectedConectivityOption:selectedConectivityNewEmpWelcome, setSelectionConectivityOption:setSelectedConectivityNewEmpWelcome,  time:newEmpWelcomeTime, setTime:setNewEmpWelcomeTime, zIndex:"90" })} 
                        {displayAutomaticallyScheduledConectivityTimeSlot({slot:"LUNCH", disabled:(!inEditMode), checkedState:newEmpLunchChecked, setCheckedState:setNewEmpLunchChecked, conectivity:conectivityNewEmpLunch, setConectivity:setConectivityNewEmpLunch, selectedConectivityOption:selectedConectivityNewEmpLunch, setSelectionConectivityOption:setSelectedConectivityNewEmpLunch,  time:newEmpLunchTime, setTime:setNewEmpLunchTime, zIndex:"80" })} 
                        {displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONEONONE_SUPERVISOR", disabled:(!inEditMode), checkedState:newEmpOneOnOneSupervisorChecked, setCheckedState:setNewEmpOneOnOneSupervisorChecked, conectivity:conectivityNewEmpOneOnOneSupervisor, setConectivity:setConectivityNewEmpOneOnOneSupervisor, selectedConectivityOption:selectedConectivityNewEmpOneOnOneSupervisor, setSelectionConectivityOption:setSelectedConectivityNewEmpOneOnOneSupervisor,  time:newEmpOneOnOneSupervisorTime, setTime:setNewEmpOneOnOneSupervisorTime, zIndex:"70" })} 
                        {displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONEONONE_MENTOR", disabled:(!inEditMode || !configDetailsToEdit.mentorProgramEnabled), checkedState:newEmpOneOnOneMentorChecked, setCheckedState:setNewEmpOneOnOneMentorChecked, conectivity:conectivityNewEmpOneOnOneMentor, setConectivity:setConectivityNewEmpOneOnOneMentor, selectedConectivityOption:selectedConectivityNewEmpOneOnOneMentor, setSelectionConectivityOption:setSelectedConectivityNewEmpOneOnOneMentor, time:newEmpOneOnOneMentorTime, setTime:setNewEmpOneOnOneMentorTime,  zIndex:"60" })} 
                        {displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONEONONE_SPONSOR", disabled:(!inEditMode || !configDetailsToEdit.sponsorProgramEnabled), checkedState:newEmpOneOnOneSponsorChecked, setCheckedState:setNewEmpOneOnOneSponsorChecked, conectivity:conectivityNewEmpOneOnOneSponsor, setConectivity:setConectivityNewEmpOneOnOneSponsor, selectedConectivityOption:selectedConectivityNewEmpOneOnOneSponsor, setSelectionConectivityOption:setSelectedConectivityNewEmpOneOnOneSponsor,  time:newEmpOneOnOneSponsorTime, setTime:setNewEmpOneOnOneSponsorTime, zIndex:"50" })} 
                    </>
                    }
               </div> 

                {!inEditMode ? 
                     null
                :
                <div className = "ContainerNoHeightFlexEnd buttonWrapperWithMargin fullWidth" >
                    <button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleConfirmationEdit} > Save </button>
                    <button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleResetEditForm} > Cancel </button>
                </div>
                }
            
            </div> 
        </div>   
        
         <ConectivityDetailedView showModal={showModalConectivityDetailed} conectivityToView={conectivityToView}  handleCancelDetailedView={handleCancelDetailedView} />
    </Authenticator>
 
  );
};


export default AdminNewEmployeeSupport;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, DAVY_GREY, LIGHT_GREY, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_WHITE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, COLOR_GOLD, COLOR_SILVER, COLOR_BRONZE, COLOR_ALERT_OPAQUE, COLOR_WARNING_HIGH_OPAQUE, COLOR_WARNING_LOW_OPAQUE, COLOR_GOOD_OPAQUE, COLOR_ALERT, COLOR_WARNING_HIGH, COLOR_WARNING_LOW, COLOR_GOOD } from '../data/conectereConfigData';

//React & Amplify
import React from 'react';
import { useState, useRef, useEffect } from "react";
// import  { Storage } from 'aws-amplify'; //V5 version
import { uploadData, remove } from 'aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL;

import config from '../aws-exports';     //load in AWS constants as a 'config' element; NOTE - ALWAYS COPY FROM THE ACCOUNT APP IN THE MONO-REPO OR FROM CC07.  SHARED BACKEND SO SHOULD BE THE SAME FOR ALL APPS
const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;

/*
    Function to store an image file to our images directory in our S3 Bucket
        imageToStore - must be a File object with a 'name' attribute.  Specs: https://developer.mozilla.org/en-US/docs/Web/API/File

        returns:
            successFlag
            URL: - url to location on S3 bucket is successfully stored
*/
export async function storeImageFile(imageToStore) {

    let successFlag = false, url = null, key=null;
    //Safety Check
    if (!imageToStore || !imageToStore.name) {
        if (DEBUG_MODE >= 1) console.error("Error - improper params to store file. ", imageToStore);
        return ({successFlag, url})
    }

    try {
        const extension = imageToStore.name.split(".")[1];
        const name = imageToStore.name.split(".")[0];
        key = `images/${uuidv4()}${name}.${extension}`;
        url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        
        // Upload the file to s3 with public access level. 
        /*
        await Storage.put(key, imageToStore, {
            level: 'public',
            contentType: imageToStore.type
        });
        */
        const result = await uploadData({
            path:url,
            data:imageToStore
          });

        console.log ("Successfully wrote image to S3 with URL to corporate LOGO", result);
        console.log ("URL:", url);
        return ({successFlag:true, url, key})

    } catch (err) {
        if (DEBUG_MODE >= 1) console.error("Error storing file. ", err);
        return ({successFlag:false, url:null, key:null})
    }
  }


  export async function deleteImageFile(path) {
 
    try {
        let successFlag = false;

        //Safety Check
        if (!path || path.length === 0) {
            if (DEBUG_MODE >= 1) console.error("Error - improper params to delete file. ", path);
            return ({successFlag})
        }   

        /* V5 AMPLIFY VERSION REMOVED 10.18.24
        const key = path.split("/public/")[1]; //grab just the key from the URL

        //Safety Check
        if (!key || !key.length > 0) {
            if (DEBUG_MODE >= 1) console.error("Error - could not extract key. ", key);
            return ({successFlag})
        } 

        await Storage.remove(key);
        */

        await remove({path});


    } catch (err) {
        if (DEBUG_MODE >= 1) console.error("Error removing file. ", err);
        successFlag = true;
    }
    return ({successFlag:true})
  
  }
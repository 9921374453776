import "./lineChart.css";

import  { DEBUG_MODE } from '../../../../shared/data/conectereConfigData';

//Amplify, React
import  React from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,  } from 'recharts';



//React component
const CCLineChart = ({title, data, dataKeyX, 
        dataKeyY1, dataKeyY2, dataKeyY3, dataKeyY4, 
        color1, color2, color3, color4, backgroundColor, strokeXAxis,
        grid, height, width, toolTip, legend, yAxis, xAxis,
        xTickAngle, margin, xTickSize,
        renderLegend, renderTooltip,    //Our customer render functions
       }) => {
            
    if (!height) height = "clamp(600px, 80vh, 1400px)";
    if (!width) width = "clamp(600px, 80vw, 1400px)";
    if (!xTickAngle) xTickAngle = 0;
    if (!xTickSize) xTickSize = 6;    //default px length of tick mark, which sets distance from axis
    if (!margin) margin = { top: 5, right: 5, bottom: 5, left: 5 }; //ReCharts default
    
 
    if (backgroundColor == null) backgroundColor = "white";
    if (strokeXAxis == null) strokeXAxis = "#5550BD";
 
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", data);
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", dataKeyX);
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", dataKeyY1);
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", dataKeyY2);
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", dataKeyY3);
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", dataKeyY4);
    // if (DEBUG_MODE >= 2) console.log("Line Chart called", grid);
    if (DEBUG_MODE >= 2) console.log("Line Chart called width", width);
    if (DEBUG_MODE >= 2) console.log("Line Chart called xTickAngle", xTickAngle);
    if (DEBUG_MODE >= 2) console.log("Line Chart called Margin", margin);
    
    return (

        <div className="lineChart" style={{height:height, width:width, backgroundColor:backgroundColor}}>
                { !title ? null : <h3 className="lineChartTitle">{title}</h3>}
                <ResponsiveContainer >
                    <LineChart data={data} margin={margin}> 
                        {xAxis && <XAxis dataKey={dataKeyX} stroke={strokeXAxis} tickSize={xTickSize} angle={xTickAngle}/>}
                        {yAxis && <YAxis />}
                        {dataKeyY1 && <Line type="monotone" dataKey={dataKeyY1} stroke={color1} activeDot={{ r: 12}}/>}
                        {dataKeyY2 && <Line type="monotone" dataKey={dataKeyY2} stroke={color2} activeDot={{ r: 12}}/>}
                        {dataKeyY3 && <Line type="monotone" dataKey={dataKeyY3} stroke={color3} activeDot={{ r: 12}}/>}
                        {dataKeyY4 && <Line type="monotone" dataKey={dataKeyY4} stroke={color4} activeDot={{ r: 12}}/>}
                        {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray = "5 5"/>}
                        {(toolTip && renderTooltip) &&  <Tooltip content = {renderTooltip}/> }
                        {(toolTip && !renderTooltip) &&  <Tooltip />}
                        {(legend && renderLegend) && <Legend  content = {renderLegend} />}
                        {(legend && !renderLegend) && <Legend 
                                verticalAlign="bottom" 
                                height={14} 
                                iconType={'circle'}
                            />
                        }
                       
                    </LineChart>
              </ResponsiveContainer>
        </div>
        
    );
};



export default CCLineChart;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


//config
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, CONECTIVITY_INITIAL_STATE, RATING_STATS_INITIAL_STATE, portfolioWideConectivityAlyticsInitialState, 
			DAYS_FOR_RECENTLY_SHARED_CONECTIVITIES, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR, CONECTIVITY_PUBLICATION_SELECTION_OPTIONS,
			GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, AUTO_FILL_CONECTIVITY_EDITOR_ON_CATEGORY_SWITCH, addToCalendarIcon, 
			GOOGLE_CALENDAR_CLIENT_SECRET, GOOGLE_CALENDAR_CLIENT_ID, GOOGLE_SCOPES, GOOGLE_INDENTITY_URL, GOOGLE_APIS_URL, GOOGLE_DISCOVERY_DOC, QUICK_FILTERS_FOR_CONTECTIVITIES
} from '../../shared/data/conectereConfigData';
import { HELP_TEXT_ADMIN_CALENDAR_1, HELP_TEXT_NON_ADMIN_CALENDAR_1 } from '../../shared/data/helpData.js';

//React & Amplify components
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { API, graphqlOperation, } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';
import {useLocation} from 'react-router-dom'
import { Navigate  } from "react-router-dom";  
// import { v4 as uuidv4 } from 'uuid';  
// import ReactDOM from "react-dom";

//Styles
import "./userConectivitySchedule.css";
	
//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Queries and Mutations
import { getScheduledConectivity   } from '../../shared/graphql/queries';

//Utils
import {  conectivitiesMatchesSearch, conectivitySatisfiesFilter, conectivityTextContainsTerm, displayLaunchRuleAsRow, setHeaderColorByStringLength } from "../../shared/utils/conectivityUtils";
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,
	   getLaunchRuleDateTime, getrecurrenceRuleLaunchDates, formatInvitationInstanceDateTime } from "../../shared/utils/dateTimeUtils";
import { DisplayUserSelectComponent, displayUsersRow } from "../../shared/utils/userAndTeamUtils";
import { fetchInvitationsByLaunchRule, fetchMessagesByLaunchRule, getDataRecordById} from "../../shared/utils/databaseUtils";
import { HelpButton, sortMessages, CloudSyncButton }  from "../../shared/utils/generalUtils";
import { findInvitationMatchingLRInstance } from "../../shared/utils/invitationUtils.js";
import moment from 'moment';

//Our Components
// import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import CalendarView from "../../shared/Components/calendarView/calendarView";
import ConectivityLauncher from "../../shared/Components/conectivityLauncher/conectivityLauncher";
import CalendarEntryPreview from "../../shared/Components/calendarEntryPreview/calendarEntryPreview";
import SearchInputBar from "../../shared/Components/searchInputBar/searchInputBar.js";
import { ModalConectivityComplete } from "../../shared/Components/modalConectivityComplete/modalConectivityComplete";


//Bootstrap and other React components
import Switch from '@material-ui/core/Switch';


//Icons
import RepeatIcon from '@mui/icons-material/Repeat';


//GOOGLE CALANDAR TEST CODE
// https://www.npmjs.com/package/react-google-login
// import { GoogleLogin } from '@react-oauth/google';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import { useGoogleLogin } from '@react-oauth/google';

//Here's the start of our Javascrpt App
const UserConectivitySchedule = () => {

   // Context
	const { isRenderedWithinMsftClient, isMobileViewPort, setShowSpinner } = useContext(DisplayContext);
	const { authState, currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);    
	const { users, teamsOptions, usersOptions,  } = useContext(CustomerContext);   
	const { conectivitiesForDashboard,conectivitiesReadyForLaunch, launchRules,  } = useContext(ConectivityContext);
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 


	//State variables
	const [showListView, setShowListView] = useState(isMobileViewPort);                //Toggle switch for whether to display List View or Calendar View; default based on view port size
	const [searchMode, setSearchMode] = useState(false);  //True when displaying multi-user select
	// const [showCalendarSettings, setShowCalendarSetting] = useState(setInitialValue());
	const [usersToShowHover, setUsersToShowHover] = useState([]);
	const [senderHover,setSenderHover] = useState(null);
	const [dragAndDropEvent, setDragAndDropEvent] = useState(null);
	const [dragStartMom, setDragStartMom] = useState(null);
	const [dragEndMom, setDragEndMom] = useState(null);
	
	const [conectivityRows,setConectivityRows]=useState([]);                                //Formatted rows for user grid    
	// const [recurringConectivityRows, setRecurringConectivityRows] = useState([]);

	//variable to store dynamic lists of selectedusers 
	const [selectedUsersOptionsForSearch, setSelectedUsersOptionsForSearch] = useState(null);     //for Search selections
	const [selectedTeamsOptionsForSearch, setSelectedTeamsOptionsForSearch] = useState([]);     							 //for Search selections
	
	// const [selectedConectivity, setSelectedConectivity] = useState([]);     //for multi-select

	//Modal Control
	const [showLaunchRuleCalendarHover, setShowLaunchRuleCalendarHover] = useState(false);
	const [calendarEventHoverTopPos, setCalendarEventHoverTopPos] = useState(0);
	const [calendarEventHoverLeftPos, setCalendarEventHoverLeftPos] = useState(0);
	const [showModalDragAndDrop, setShowModalDragAndDrop] = useState(false);
	const [isUpdatingBackend, setIsUpdatingBackend] = useState(false);
	const [isDragInProgress, setIsDragInProgress] = useState(false);
	const [hoverDate, setHoverDate] = useState("");
	const [showModalCalendarEntryPreview, setShowModalCalendarEntryPreview] = useState(false);
	const [showModalDone, setShowModalDone] = useState(false);

	//Conectivity Launcher Inputs
	const [launchRuleToEdit, setLaunchRuleToEdit]  = useState(null);
	const [conectivityToUpdate, setConectivityToUpdate] = useState(null);
	const [scheduledConectivityToProcess, setScheduledConectivityToProcess] = useState(null);
	const [invitationToUpdate, setInvitationToUpdate] = useState(null);
	const [showModalReadOnlyView, setShowModalReadOnlyView] = useState(false);
	const [showModalEditInviteToConect, setShowModalEditInviteToConect] = useState(false);
	const [showModalDeleteInviteToConect, setShowModalDeleteInviteToConect] = useState(false);
	const [launchRuleInstanceStartDateTime, setLaunchRuleInstanceStartDateTime] = useState(null); //Start DateTime ISO string for the particular invitation instance that is being edited or deleted by the user
	const [launchRuleInstanceEndDateTime, setLaunchRuleInstanceEndDateTime] = useState(null);     //End DateTime ISO string for the particular invitation instance that is being edited or deleted by the user 
	const [launchRuleInstanceIndex, setLaunchRuleInstanceIndex] = useState(1); // Index of this invitation in a series of events, if the LR has an RRULE.  Index from 1 to N ...
	const [autoSave, setAutoSave] = useState(false);
	const [autoSaveEventStartDateTime, setAutoSaveEventStartDateTime] = useState(null);
	const [autoSaveEventEndDateTime, setAutoSaveEventEndDateTime] = useState(null);
	const [autoRSVP, setAutoRSVP] =  useState (false);
	const [autoRsvpStatus, setAutoRsvpStatus] =  useState ('');
	
	// const [showModalEditLaunchRule, setShowModalEditLaunchRule] = useState(false);
	// const [showModalDeleteLaunchRule, setShowModalDeleteLaunchRule] = useState(false);
	// const [showModalEditInvitation, setShowModalEditInvitation] =  useState (false);
	// const [showModalDeleteInvitation, setShowModalDeleteInvitation] =  useState (false);


	// const [newMessageGiphyGridContainerDivSize, setNewMessageGiphyGridContainerDivSize]  = useState(450);   

	//for showing particpants in a future scheduled conectivity
	// const [showModalFutureInvitees, setShowModalFutureInvitees] = useState(false);
	const [futureInviteesToShow, setFutureInviteesToShow] = useState([]);
	const [futureTeamInviteesToShow, setTeamFutureInviteesToShow] = useState([]);

	// const [eventIDtoFetch,setEventIDtoFetch] = useState("");
	// const [launchRuleIDtoUpdate, setLaunchRuleIDtoUpdate] = useState("");

	const [showModalSendInviteToConect, setShowModalSendInviteToConect] = useState(false);
	const [launchRulesForSelectedUsers, setLaunchRulesForSelectedUsers] = useState([]);
	const [futureLaunchEvents, setFutureLaunchRules] = useState([]);

	//State for Search Bar
	const [searchTerm, setSearchTerm] = useState("");
	const [searchActive, setSearchActive] = useState(false);
	const [activeFilters, setActiveFilters] = useState([]);
	const [redirectToPage, setRedirectToPage] = useState(null);     //state variable for invoking (rendering) React redirct function

	//UseEffect to change our view if the viewport changes to a small (mobile viewport)
	useEffect (() => {
		if (isMobileViewPort && !showListView) setShowListView(true);
	},[isMobileViewPort, showListView])

	function preloadSelectedUsers() {
		
		if (currentUser && usersOptions && usersOptions.length > 0 && !selectedUsersOptionsForSearch) {
			//Pre-load user selection
			let tempSelectedUsersOptions = [];
			const thisUserOption = usersOptions.find(userOption => userOption.id === currentUser.id);
		
			if (thisUserOption) {
				tempSelectedUsersOptions = [thisUserOption]; //Make a new array of options that includes the option for the current user
				if (DEBUG_MODE >= 0) console.log("Presetting user option");
				setSelectedUsersOptionsForSearch(tempSelectedUsersOptions);
			} else {
				// if (DEBUG_MODE >= 2) console.log("No matching user option for pre-loading calendar")
			}
		}
	}

	//Preload selected users on change of auth state
	useEffect(() => {
		if (currentUser) preloadSelectedUsers();
	}, [currentUser, usersOptions]);     
	
	//Update calendar data (launch rules to display) based on any change to selected users to display or the underlying launch rules
	//Note, we need the Conectivities to be loaded since the context embeds the conectivity data into the launch rules for display
	//Note #2, this is where we also apply any filters that the user may have selected
	
	useEffect(() => {
		if ( selectedUsersOptionsForSearch && selectedUsersOptionsForSearch.length > 0 && conectivitiesReadyForLaunch && conectivitiesReadyForLaunch.length > 0 &&  launchRules && launchRules.length > 0 ) {
			buildCalendarData(); //Rebuild the calendar view
		}
	}, [conectivitiesReadyForLaunch, launchRules, selectedUsersOptionsForSearch, selectedTeamsOptionsForSearch, searchTerm, activeFilters]);      

	//useEffect for updating LR being displayed in the event the LR is updated via subscription
   useEffect(() => {
		let mounted = true;
		if (mounted && launchRules) {
			//Is the User viewing a specific calendar event?  IF SO, UPDATE the calendar event 
			if (showModalCalendarEntryPreview && launchRuleToEdit) {
				const newVersionOfLaunchRule = launchRules.find(lr => lr.id === launchRuleToEdit.id);
				if (DEBUG_MODE >= 2) console.log("User viewing a Calendar Event; determining whether to update LR; comparing launchRules.", launchRuleToEdit, newVersionOfLaunchRule); 
				if (launchRuleToEdit.updatedAt !== newVersionOfLaunchRule.updatedAt) {
					if (DEBUG_MODE >= 2) console.log("LR UpdatedAt is newer; updating LRtoEdit"); 
					setLaunchRuleToEdit(newVersionOfLaunchRule);    //Update the LR being viewed
				}
			} else {
				if (DEBUG_MODE >= 2) console.log("no invitations for this user anymore for this SC; initializing blank message window");
					setLaunchRuleToEdit(null);      //Clear the chat window
					setInvitationToUpdate(null);    //Probs not necessary as not used on this page
			} //END IF VIEWING CALENDAR ENTRY 
		}
		//Clean-up Function
		return () => mounted = false; // Mark as unmounted so no state update
		
	}, [launchRules]);      


	//grab data needed for rendering the dashboard
	async function buildCalendarData() {     
		const tempLaunchRulesForSelectedUsers = [];       
		if (DEBUG_MODE >= 0) console.log("Building calendar data for user", launchRules);
	   
	   for (const launchRule of launchRules){
			
			//First, does this launch rule applies to any of the selected USERs; note, this should really cover the next two steps as
			//The individual invitees includes the entire team, when launched
			var launchRuleMatchesFilters = false;
			
			if (launchRule.usersToInvite && launchRule.usersToInvite.length > 0) {
					launchRuleMatchesFilters =  selectedUsersOptionsForSearch.some((userOption) => {
						return launchRule.usersToInvite.includes(userOption.id); //for each seleced user, test with the launch rule in involves the user
					});
				}

			if (launchRuleMatchesFilters) {
				if (DEBUG_MODE >= 3) console.log("This USER matched Launch rule", launchRule);
			} else {
				if (DEBUG_MODE >= 3) console.log("This USER did NOT match Launch rule", launchRule);
			}
			
			//If not already including the LR on the calendar, does the LR apply to any of the selected teams?
			if (!launchRuleMatchesFilters &&  launchRule.teamsToInvite && launchRule.teamsToInvite.length > 0) {
				launchRuleMatchesFilters =  selectedTeamsOptionsForSearch.some((teamOption) => {
					return launchRule.teamsToInvite.includes(teamOption.id); //for each selected team, test with the launch rule in involves the team
				});
			}            
 
					   
			//If not already including the LR, are any of the selected users on an invited team?
			//We do this because we want to see the USER(S) calendar(s), which are influenced by the teams they are on
			if (!launchRuleMatchesFilters &&  launchRule.teamsToInvite && launchRule.teamsToInvite.length > 0) {
				
				//If not already applicable, test whether any of the users are on a team having an ID that is listed in the launch rule?
				
				launchRuleMatchesFilters = selectedUsersOptionsForSearch.some((userOption) => {
					//Get the corresponding user
					const thisUser = users.find(user => user.id === userOption.id);
					
					if (thisUser) {
						//Test whether the corresponding user is on a Team that is participating in the launch rule
						return thisUser.teams.items.some((join) => {
							if (DEBUG_MODE >= 3) console.log("Checking user/team JOIN against launch rule teams", join, launchRule.teamsToInvite);
							return launchRule.teamsToInvite.includes(join.teamID);
						});
					} else return false;                 
				});           
				if (DEBUG_MODE >= 2) console.log("Checked Launch rule for matching team", launchRule, launchRuleMatchesFilters);
			}

			//LR matched the filter settings?  if so, apply our quick filters before adding it to our array of LR's to display on the calendar
			if (launchRuleMatchesFilters) {
				
				if (launchRuleMatchesSearchFilters(launchRule)) tempLaunchRulesForSelectedUsers.push(launchRule);
			}
		 }

		if (DEBUG_MODE >= 1) console.log("Rebuilt calendar date  (launch rules) for selected users", selectedUsersOptionsForSearch, tempLaunchRulesForSelectedUsers);        
		setLaunchRulesForSelectedUsers(tempLaunchRulesForSelectedUsers);

		//Now, reduce down to only future events for display as a list view
		const futureLaunchEvents = tempLaunchRulesForSelectedUsers.filter(launchRule => {
			return (launchRule.nextLaunchDateISO && launchRule.nextLaunchDateISO !== 'EXPIRED' && moment(launchRule.nextLaunchDateISO).isAfter(NOW()));
		});
		
		if (DEBUG_MODE >= 1) console.log("Reduced calendar date  (launch rules) to future events for list view", futureLaunchEvents);        
		setFutureLaunchRules(futureLaunchEvents);
	}

	function launchRuleMatchesSearchFilters(launchRule) {
		// if (DEBUG_MODE >= 2) console.log("Applying filters to LR", launchRule, searchTerm, activeFilters);
		
		//Return TRUE if the conectivity for LR matches our search term AND matches ALL of the filters that are set
		
		//DOES THE conectivity LR MATCH ANY FILTERS
		var passesFilters = false;  //default to no, exclude from calendar

		if (launchRule && launchRule.conectivityID) {
			const conectivityToTest = conectivitiesForDashboard.find(con => con.id === launchRule.conectivityID);
	
			if (conectivityToTest) {
				return conectivitiesMatchesSearch({conectivity:conectivityToTest, activeFilters, keywords:searchTerm});
			} //END IF CONECTICITY FOUND
		} //END IF VALID LR
		return passesFilters;    
	}          

	
{/*
	//Page handlers
	useEffect(() => {
		buildRecurringConectivitiesRows();    //Rebuild our UI on any change to the conectivities or their portfolioWideConectivityAlytics
	}, [launchRulesInvolvingThisUser, conectivitiesForDashboard]);

	function buildRecurringConectivitiesRows () {
		
		// if (DEBUG_MODE >= 2) console.log("Rebuilding Recurring Conectivites ROWS");

		//Basic safe guards
		if (!launchRulesInvolvingThisUser) return;
		if (launchRulesInvolvingThisUser.length == 0) return;
		if (!conectivitiesForDashboard) return;
		if (conectivitiesForDashboard.length == 0) return;
 
	   //Build rows for Recurring Conectivities Data Grid
		var tempRecurringConectivitiesRows = [];
		
		for(var i = 0; i<launchRulesInvolvingThisUser.length; i++){
			
			const thisConectivity = conectivitiesForDashboard.find(conectivity => conectivity.id == launchRulesInvolvingThisUser[i].conectivityID);
			
			if (thisConectivity) {
				// if (DEBUG_MODE >= 2) console.log("Building rows - Found matching conectivity", thisConectivity);
			} else {
				// if (DEBUG_MODE >= 2) console.log("Building rows - no matching conectivity for this launch rule", launchRules[i]);
			}
			
			tempRecurringConectivitiesRows[i] = {
			id:i+1,
			eventStartDateTime: formatDate(launchRulesInvolvingThisUser[i].eventStartDateTime),
			launchUntilDate: (launchRulesInvolvingThisUser[i].launchRepeatOption != 1 ? formatDate(launchRulesInvolvingThisUser[i].launchUntilDate) : ""),
			usersToInvite:launchRulesInvolvingThisUser[i]. usersToInvite,
			teamsToInvite:launchRulesInvolvingThisUser[i].teamsToInvite,
			title: (thisConectivity ? thisConectivity.title : "None"),
			shortLabel: (thisConectivity ? thisConectivity.category.label : ""),
			image: (thisConectivity ? thisConectivity.image : ""),
			category:(thisConectivity ? thisConectivity.category.name : ""),
			conectivityImageURL: (thisConectivity ? setConectivityImageUrlByCategory(thisConectivity.category.label, true) : ""),
			frequency:(launchRulesInvolvingThisUser[i].launchRepeatOption ? CALENDAR_REPEAT_OPTIONS[launchRulesInvolvingThisUser[i].launchRepeatOption-1].label : "None"),    //Just an error check
			recurrenceRule:buildRecurrenceString(launchRulesInvolvingThisUser[i]),    
			message: launchRulesInvolvingThisUser[i].message,                   //Any message to include when launching the conectivity
			messageGIF: launchRulesInvolvingThisUser[i].messageGif,            //Any GIF  to also include when launching
			senderID: launchRulesInvolvingThisUser[i].senderID,                                  //Who sent the invite
			senderAvatarUrl: launchRulesInvolvingThisUser[i].senderAvatarUrl,                    //location where avatar image is stored on the S3 bucket
			senderAvatarName: launchRulesInvolvingThisUser[i].senderAvatarName,
			senderAvatarInitials: launchRulesInvolvingThisUser[i].senderAvatarInitials,
			nextLaunchDate: formatDateWithDayOfWeek(getRecurrenceRuleNextLaunchDate(launchRulesInvolvingThisUser[i])),
			arrayOfLaunchDates:getrecurrenceRuleLaunchDates(launchRulesInvolvingThisUser[i]),          //No need for all of these, but adding them to the row records for now
			isLaunchToday:isRecurrenceRuleLaunchToday(launchRulesInvolvingThisUser[i]),               //No need for all of these, but adding them to the row records for now
			 };
		   
		   //As a test, evaluate the rule and print the results to the console
		//   var arrayOfDates = getrecurrenceRuleLaunchDates(launchRulesInvolvingThisUser[i]);
		//   var nextLaunchDate = getRecurrenceRuleNextLaunchDate(launchRulesInvolvingThisUser[i]);
		//   var isLaunchToday = isRecurrenceRuleLaunchToday(launchRulesInvolvingThisUser[i]);
 
			// if (DEBUG_MODE >= 2) console.log("Testing launch rule", launchRulesInvolvingThisUser[i]);
			// if (DEBUG_MODE >= 2) console.log("Recurrence:" + tempRecurringConectivitiesRows[i].recurrenceRule);
			// if (DEBUG_MODE >= 2) console.log("Launch Today?", isLaunchToday);
			// if (DEBUG_MODE >= 2) console.log("Next launch date ISO:" + nextLaunchDate);
			// // var launchDateLocalTime = "";
			// // if (nextLaunchDate) launchDateLocalTime = moment(nextLaunchDate);
			// if (DEBUG_MODE >= 2) console.log("Next launch date local:" + moment(nextLaunchDate).format("YYYY MM DDTHH mm ssZ"));
			// if (DEBUG_MODE >= 2) console.log("Upcoming launch dates", arrayOfDates);
		}

		
		// if (DEBUG_MODE >= 2) console.log("Built RECURRING Conectivity Rows:", tempRecurringConectivitiesRows);
		setRecurringConectivityRows(tempRecurringConectivitiesRows);

	}
*/}

// Pop up a modal for viewing members that participated in the Conectivity

/*
	function handleShowFutureInvitees (futureInvitees, teamParticipants, category) {
 
		  
		if (DEBUG_MODE >= 2) console.log("View Future Invitees Button pressed.", futureInvitees, teamParticipants, category);    

		var tempFutureInvitees = [];
		
		for (var j=0; j<futureInvitees?.length; j++) {
			const tempParticipant = users.find(user => user.id == futureInvitees[j]);

			if (tempParticipant != undefined) tempFutureInvitees.push(tempParticipant);
		}

		if (DEBUG_MODE >= 2) console.log("Built Future Invitee list:", tempFutureInvitees);    

		setFutureInviteesToShow(tempFutureInvitees);
		
		 var tempTeamFutureInvitees = [];
		
		for (var x=0; x<teamParticipants?.length; x++) {
			const tempTeamParticipant = teams.find(team => team.id == teamParticipants[x]);

			if (DEBUG_MODE >= 2) console.log("Retrieved Team:", tempTeamParticipant, teamParticipants[x]);    
			
			if (tempTeamParticipant != undefined)  tempTeamFutureInvitees.push(tempTeamParticipant);
		}

		if (DEBUG_MODE >= 2) console.log("Built Team Future Invitee list:", tempTeamFutureInvitees, teamParticipants);    

		setTeamFutureInviteesToShow(tempTeamFutureInvitees);
		setShowModalFutureInvitees(true); //pop-up Modal
		
	}
*/    


	const handleCancelFutureInviteesModal = () => {
		
		// setShowModalFutureInvitees(false);
		setFutureInviteesToShow ([]);
		setTeamFutureInviteesToShow([]);
		
		if (DEBUG_MODE >= 2) console.log("Cancelled Future Invitees Modal");
	};  
	

	 //Component for displaying participants for an upcoming scheduled conectivity
	/*
	function displayFutureInvitees () {
		if (futureInviteesToShow.length == 0 && futureTeamInviteesToShow.length == 0 ) return null;
		
		 return (
			 <div> 
 
		 {futureInviteesToShow.length == 0 ? "" :
				<div >
					<div style={{fontSize:"1.3rem", fontWeight:"500", color:COLOR_BLUE_TEXT}} >Invitees</div>
	
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
					
						{futureInviteesToShow.map((target, index) => ( 
										<div key={target.id} className="avatarContainer"> 
											<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
												{!target.avatarUrl  ? <div className="avatarInitials" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
											</div>
											<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>{target.firstName + " " + target.lastName}</span>
										</div>
	
						))}
					  </div>
				  </div>
		 }

		{futureTeamInviteesToShow.length == 0 ? "" :
				<div>
					<div style={{fontSize:"1.3rem", fontWeight:"500", color:COLOR_BLUE_TEXT, paddingTop:"clamp(8px,2vw,20px)"}} >Teams</div>
					  
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
					
						{futureTeamInviteesToShow.map((target, index) => ( 
										<div key={target.id} className="avatarContainer"> 
											<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
												{!target?.avatarUrl  ? <div className="avatarInitials" > {target.name.substring(0,3).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.name.substring(0,3).toUpperCase()} /> }
											</div>
											<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.name)}}>{target.name}</span>
										</div>
	
						))}
					  </div>                  
				  </div>
		}
			  </div>
			);
	}
	*/

	const handleCloseLaunchModal = ({result, message}) => {
		setShowModalReadOnlyView(false);
		setShowModalSendInviteToConect(false);
		setShowModalEditInviteToConect(false);
		setShowModalDeleteInviteToConect(false);
		setIsUpdatingBackend(false);
		setAutoSave(false);
		setAutoRSVP(false);
		setAutoRsvpStatus('');
		//  setShowModalEditLaunchRule(false);
		// setShowModalDeleteLaunchRule(false);
		// setShowModalEditInvitation(false);
		// setShowModalDeleteInvitation(false);
		// setConectivityToUpdate(CONECTIVITY_INITIAL_STATE);

		//Was conectivity launcher handling a drag and then cancelled?  If so, we need to do a revert
		if(dragAndDropEvent) dragAndDropEvent.revert();
		else console.log('No calendar event to revert', dragAndDropEvent);
		 

		if (result && result !== "CANCELED" && message) {
			if (result) setModalGeneralTitle("Success!!");  
			else setModalGeneralTitle("Ooops!");
			setModalGeneralMessage(message);
			setShowModalGeneral(true);
		}
		
		if (DEBUG_MODE >= 2) console.log("Closed Conectivity Editor", result, message);
	};

	//
	// Google Test Code
	//
	{/*
	const responseGoogle  = (response) => {
		if (DEBUG_MODE >= 2) console.log("Google Login Response", response);
	};
	const responseGoogleError  = (error) => {
		if (DEBUG_MODE >= 2) console.log("Google Login Error", error);
	};

	const login = useGoogleLogin({
	  scope:'openid email profile https://www.googleapis.com/auth/calendar',    
	  onSuccess: responseGoogle,
	  onError:responseGoogleError,
	  flow: 'auth-code',
	});

*/}

/* 7.28.2024 Removed List View

  const displayLaunchRulesAsList = () => {
 
	//safety check
	if (!futureLaunchEvents || futureLaunchEvents.length === 0) return null;
	return (
		<div className="ContainerVerticalStart">
				{futureLaunchEvents.map((launchRule) => {
					if (!launchRule.nextLaunchDateISO || launchRule.nextLaunchDateISO === 'EXPIRED') return null;
					else return displayLaunchRuleAsRow(launchRule, users, teams, handleCalendarEvent, calendarListViewEventClickHandler);
				})}
		</div>
	);
  };   

	function handleToggleListView(event) {
		setShowListView(!showListView);
	}    


/*
	function displayCalendarControl() {
		
		return(
			<div className="ContainerNoHeightWrap" >
				<div style={{zIndex:"999", minWidth:"clamp(600px,40vw,1000px)"}}>
					<DisplayUserSelectComponent usersOptions={usersOptions} handleSelectUser={setSelectedUsersOptionsForSearch} isMulti={true} selectedUsersOptions={selectedUsersOptionsForSearch} />
				</div>
			</div>
		);
	}
*/
	// 
	// Date Click FullCalendar Callback
	// This callback is invoked when the user has clicked directly on a blank DATE
	// 
	const calendarDateClickHandler = (e) => {
		if (e) e.jsEvent.preventDefault();
		
		if (DEBUG_MODE >= 2) console.log("Calendar Date Click Handler Invoked", e);

	};

	//Main function for handling user action with respect to a calendar entry; called from either our Calendar View event (like click or drag) handlers or List View click
	//Pop-up a modal and ask conectivity to confirm. Handle confirmation
	async function handleCalendarEvent({launchRuleToEdit, launchRuleInstanceIndex, launchRuleInstanceStartDateTime, launchRuleInstanceEndDateTime, changeType, isDelete}) {
		
		if (DEBUG_MODE >= 2) console.log("Calendar Event Launch Rule button pressed or Drag-n-Drop.  LaunchRule=", launchRuleToEdit, changeType);
		if (!launchRuleToEdit || !launchRuleToEdit.id) {
			if (DEBUG_MODE >= 2) console.log("ERROR - Edit Launch Rule button pressed.  NO LAUNCH RULE", launchRuleToEdit);
			return;
		}

		try {
			//Save off the launch rule and instance date and index
			setLaunchRuleToEdit(launchRuleToEdit);
			setLaunchRuleInstanceStartDateTime(launchRuleInstanceStartDateTime);
			setLaunchRuleInstanceEndDateTime(launchRuleInstanceEndDateTime);
			setLaunchRuleInstanceIndex(launchRuleInstanceIndex);
			setIsUpdatingBackend(true);

			//Turn off the hover
			setShowLaunchRuleCalendarHover(false);
			
			//Grab the conectivity 
			const tempConectivity = conectivitiesForDashboard.find(conectivity => conectivity.id === launchRuleToEdit.conectivityID);
			if (tempConectivity) {
				setConectivityToUpdate(tempConectivity);
				console.log("Fetched the relevant conectivity", tempConectivity);
			} else setConectivityToUpdate(null);
	

			//Now, let's see if this LR already has an active SC that we can present to the user.  Work backward from the invitations
			var tempInvitations =  await fetchInvitationsByLaunchRule(launchRuleToEdit.id); 
			
			//Valid Invitation fetched for this launch rule or LR in the future? 
			let tempScheduledConectivity, tempInvitiationToUpdate;
			if (tempInvitations && tempInvitations.length > 0 && tempInvitations[0].scheduledConectivityID) {
				console.log("Invitation(s) found for this LR", tempInvitations);
				
				//So, there is an invitation; within the LR, find the invitation object that matches the instance that was selected by the user
				tempInvitiationToUpdate = findInvitationMatchingLRInstance({invitations:tempInvitations, launchRuleInstanceIndex, launchRuleInstanceStartDateTime });
				if (tempInvitiationToUpdate && tempInvitiationToUpdate.scheduledConectivityID) {
					if (DEBUG_MODE) console.log("Found invitation matching this LR instance", tempInvitiationToUpdate);
					//grab the corresponding scheduled conectivity
					tempScheduledConectivity =  await getDataRecordById(getScheduledConectivity, 'getScheduledConectivity', tempInvitiationToUpdate.scheduledConectivityID );
					if (tempScheduledConectivity) {
						if (DEBUG_MODE >= 2) console.log("Found SC for this invitation", tempScheduledConectivity);
					} else {
						if (DEBUG_MODE >= 2) console.log("Error - No SC found for this invitation");
					}
				} else {
					if (DEBUG_MODE) console.log("No invitation found matching this LR instance", tempInvitiationToUpdate);
				}
			} else {
				if (DEBUG_MODE >= 2) console.log("No active invitation found for this launch rule");
				setInvitationToUpdate(null);
				setScheduledConectivityToProcess(null);
			}
			//Store results, if any
			setScheduledConectivityToProcess(tempScheduledConectivity);
			setInvitationToUpdate(tempInvitiationToUpdate);

			//Now, are we processing a DRAG-N-DROP or a calendar click event?
			if (changeType === "DRAG") {
			   //Pop-up our Conectivity Launcher    
				setShowModalEditInviteToConect(true); 
				setShowModalCalendarEntryPreview(false);
				
			} else {			   
				//Show our preview modal
				setShowModalCalendarEntryPreview(true);
			}
		} catch (err) {
			console.log("Error handling click", err);
		}
	}   
	
	

/* Removed LIST view
//
//  Handle user click on calendar entry from LIST View
//  - We will assume the user wishes to edit the displayed launch INSTANCE, which is the next one
// 
	const calendarListViewEventClickHandler = async (launchRuleToEdit) => {
		
		if (launchRuleToEdit) { 
			if (DEBUG_MODE >= 2) console.log("Calendar LIST view Click Handler Invoked", launchRuleToEdit);

			//Default to non-recurring
			let tempLaunchRuleInstanceStartDateTime = launchRuleToEdit.nextLaunchDateISO, instanceIndex = 1, changeType = "CLICK";
			
			 //Grab the next launch date, which is what we display in LIST view       
			let tempLaunchRuleInstanceStartDateTimeMoment = moment(launchRuleToEdit.nextLaunchDateISO);

			
			//Where we able to extract a valid ORIGINAL start date for the conectivity clicked or dragged?                     
			if (tempLaunchRuleInstanceStartDateTimeMoment.isValid()) {
				if (DEBUG_MODE >= 2) console.log("Set instance date", launchRuleToEdit.nextLaunchDateISO);

				//Is this a recurring launch rule?  If so, we need to extract a bit more information
				if (launchRuleToEdit && launchRuleToEdit.launchRepeatOption > 1 && launchRuleToEdit.launchRepeatOption > 1) {
				
					//Determine the setLaunchRuleInstanceIndex for this particular recurring Launch Rule
					// Retrieve array of instance dates for this recurring launch rule
					const arrayOfLaunchDates = getrecurrenceRuleLaunchDates(launchRuleToEdit);
					if (!arrayOfLaunchDates || arrayOfLaunchDates.length === 0) {
						if (DEBUG_MODE >= 2) console.log("Error - Unable to generate any recurrence dates"); 
					} else {
						console.log("Event series for this launch rule",arrayOfLaunchDates);
						const matchingInstanceDate = arrayOfLaunchDates.find(launchDate =>  moment(launchDate).isSame(tempLaunchRuleInstanceStartDateTimeMoment,'day'));
						if (matchingInstanceDate) {
							instanceIndex = arrayOfLaunchDates.indexOf(matchingInstanceDate) + 1;   //ADD 1 since our instance index starts at 1 for 1 TO N instances
							if (DEBUG_MODE >= 2) console.log("Found launch rule instance date that matches the selected instance launch date", matchingInstanceDate, instanceIndex);
						}
					}
				}
				
			}
			
		   
			//Store the instance date and index
			setLaunchRuleInstanceStartDateTime(tempLaunchRuleInstanceStartDateTime);
			setLaunchRuleInstanceIndex(instanceIndex);
			
			//Kick off our preview modal
			setIsUpdatingBackend(true);
			await handleCalendarEvent({launchRuleToEdit, isDelete:false, changeType});
			
		} else {
			if (DEBUG_MODE >= 2) console.log("Error -calendar list view event click callback but no event");
		}
		
		setDragAndDropEvent(null);
	  };
*/

//
//  Handle user clicks on calendar entry from Calendar View
//  This common routine is invoked by the calendar CLICK handler and the calendar (drag and) DROP handler
//
	const calendarViewEventHandler = async (e, changeType) => {

		if (DEBUG_MODE >= 2) console.log("Calendar EVENT Handler Invoked", e, changeType);
		try {
			if (e.event && e.event.id) { 
				setShowSpinner(true);
				
				//The calendar "event" provides an API to the launch rule
				if (DEBUG_MODE >= 2) console.log("Event Title", e.event.title);
				if (DEBUG_MODE >= 2) console.log("Event ID", e.event.id);
				if (DEBUG_MODE >= 2) console.log("Event changeType", changeType);
	
				//Grab the launch rule           
				const thisLaunchRule = launchRules.find(launchRule => launchRule.id === e.event.id);
				var tempLaunchRuleToEdit = {...thisLaunchRule}; //Make a copy
				
				//Grab the instance start / end time from the calendar event that the user manipulated.  Default to non-recurring
				let tempLaunchRuleInstanceStartDateTime = '', tempLaunchRuleInstanceEndDateTime = '', instanceIndex = 1;
	
					
				//Determine the ORIGINAL start date for the instance & the instance index for this calendar event associated with the recurring launch rule         
				let tempLaunchRuleInstanceStartDateTimeMoment, tempLaunchRuleInstanceEndDateTimeMoment;
				if(changeType === "CLICK"){
					tempLaunchRuleInstanceStartDateTimeMoment = moment(e.event.startStr);
					tempLaunchRuleInstanceEndDateTimeMoment = moment(e.event.endStr);
					setDragAndDropEvent(null);
				} else if(changeType === "DRAG"){
					tempLaunchRuleInstanceStartDateTimeMoment = moment(e.oldEvent.startStr);
					tempLaunchRuleInstanceEndDateTimeMoment = moment(e.oldEvent.endStr);
				 }

				//Where we able to extract a valid ORIGINAL start date for the conectivity clicked or dragged?                     
				if (tempLaunchRuleInstanceStartDateTimeMoment.isValid() && tempLaunchRuleInstanceEndDateTimeMoment.isValid()) {
					tempLaunchRuleInstanceStartDateTime = tempLaunchRuleInstanceStartDateTimeMoment.toISOString();
					tempLaunchRuleInstanceEndDateTime = tempLaunchRuleInstanceEndDateTimeMoment.toISOString();
					if (DEBUG_MODE >= 2) console.log("Set instance start & end date", tempLaunchRuleInstanceStartDateTime, tempLaunchRuleInstanceEndDateTime);

					//Is this a recurring launch rule?  If so, we need to extract a bit more information
					if (tempLaunchRuleToEdit && tempLaunchRuleToEdit.launchRepeatOption > 1 && tempLaunchRuleToEdit.launchRepeatOption > 1) {
					
						//Determine the setLaunchRuleInstanceIndex for this particular  recurring Launch Rule
						// Retrieve array of instance dates for this recurring launch rule
						const arrayOfLaunchDates = getrecurrenceRuleLaunchDates(tempLaunchRuleToEdit);
						if (!arrayOfLaunchDates || arrayOfLaunchDates.length === 0) {
							if (DEBUG_MODE >= 2) console.log("Error - Unable to generate any recurrence dates"); 
						} else {
							console.log("Event series for this launch rule",arrayOfLaunchDates);
							const matchingInstanceDate = arrayOfLaunchDates.find(launchDate =>  moment(launchDate).isSame(tempLaunchRuleInstanceStartDateTimeMoment,'day'));
							
							if (matchingInstanceDate) {
								instanceIndex = arrayOfLaunchDates.indexOf(matchingInstanceDate) + 1;   //ADD 1 since our instance index starts at 1 for 1 TO N instances
								if (DEBUG_MODE >= 2) console.log("Found launch rule instance date that matches the selected instance", matchingInstanceDate, instanceIndex);
							}
						}
					}
					
				} 

				//Invoke our generic event handler
				if (tempLaunchRuleToEdit) {
					await handleCalendarEvent({
						launchRuleToEdit:tempLaunchRuleToEdit, 
						launchRuleInstanceIndex:instanceIndex, 
						launchRuleInstanceStartDateTime:tempLaunchRuleInstanceStartDateTime, 
						launchRuleInstanceEndDateTime:tempLaunchRuleInstanceEndDateTime, 
						isDelete:false, 
						changeType
					});
				} //End IF LR found		
			} else {
				if (DEBUG_MODE >= 2) console.log("Error -calendar event click callback but no event");
				setLaunchRuleInstanceStartDateTime(null); setLaunchRuleInstanceEndDateTime(null); setLaunchRuleInstanceIndex(1);
			}
		} catch (err) {
			if (DEBUG_MODE >= 1) console.log("Error handling edit event", err, e);
			setLaunchRuleInstanceStartDateTime(null); 
			setLaunchRuleInstanceEndDateTime(null);
			setLaunchRuleInstanceIndex(1);
			setIsUpdatingBackend(false);
		}
		
		setShowSpinner(false);

	  };

//
//  Handle user click on calendar entry from Calendar View
//
	const calendarEventClickHandler = async (e) => {
		if (e) e.jsEvent.preventDefault();
		if (DEBUG_MODE >= 2) console.log("Calendar EVENT  Click Handler Invoked", e);
		const CHANGE_TYPE = "CLICK";
		await calendarViewEventHandler(e, CHANGE_TYPE);
	};

	const calendarEventMouseEnterHandler = (e) => {

		const OFFSET_Y = 5; //Just an amount to move down from the hovered element
		// if (DEBUG_MODE >= 2) console.log("Calendar MOUSE ENTER Handler Invoked", isUpdatingBackend, e);
		
		if (isDragInProgress || isUpdatingBackend) {
			if (DEBUG_MODE >= 2) console.log("Ignoring hover.  Currently updating background", isUpdatingBackend);
		}

		if (e && e.jsEvent) {

			e.jsEvent.preventDefault();

			// if (DEBUG_MODE >= 2) console.log("Mouse position", e.jsEvent.clientX,  e.jsEvent.clientY);

/*
			const rect = e.jsEvent.target.getBoundingClientRect();
			// if (DEBUG_MODE >= 2) console.log("position detected", rect);
			
  
			//Use ViewPort locoation for displaying hover content based on sensed mouse
			const topPos = Math.round(rect.bottom + OFFSET_Y).toString() + "px";                       //Display at bottom of the hovered element
			const leftPos = Math.round(rect.left - (rect.width)/2).toString() + "px";       //Display at middle of the hovered element

			if (DEBUG_MODE >= 2) console.log("Absolute TOP POS of element ", topPos);
			if (DEBUG_MODE >= 2) console.log("Absolute LEFT POS of element ",  leftPos);
			setCalendarEventHoverTopPos(topPos);
			setCalendarEventHoverLeftPos(leftPos);
*/
			setCalendarEventHoverTopPos(e.jsEvent.clientY);
			setCalendarEventHoverLeftPos(e.jsEvent.clientX);
		}

		if (e.event) { 
			//The calendar "event" provides an API to the launch rule
			// if (DEBUG_MODE >= 2) console.log("Event Title", e.event.title);
			// if (DEBUG_MODE >= 2) console.log("Event ID", e.event.id);
			
			const tempLaunchRuleToEdit = launchRules.find(launchRule => launchRule.id === e.event.id);
			
			if (tempLaunchRuleToEdit) {
				
				setLaunchRuleToEdit(tempLaunchRuleToEdit);
				if (DEBUG_MODE >= 3) console.log("Stored launch rule for hover", tempLaunchRuleToEdit);
  
				//Set the hover date for display
				setHoverDate(formatInvitationInstanceDateTime({eventStartDateTime:e.event.startStr, eventEndDateTime:e.event.endStr, allDayEvent:tempLaunchRuleToEdit.allDayEvent, timeZoneCode:currentUser.timeZoneCode}));
				
				//Grab the users to display as part of the hover
				var tempUsersToShow = [];
				for (let j = 0; j < tempLaunchRuleToEdit.usersToInvite.length; j++){  
					 //Look in the array of USER Options to find the dropdown option for the user on this team
					const userToShow = users.find(user => user.id === tempLaunchRuleToEdit.usersToInvite[j]);
					
					if (userToShow) {
						tempUsersToShow.push(userToShow);  //Add team to inital selections 
						// console.log ("Pushed user onto UsersToShow:", userToShow);
					} else {
						if (DEBUG_MODE >= 2) console.log("Skipped - user does no longer exists");
					}
				}
				setUsersToShowHover(tempUsersToShow);
				const sender = users.find(user => user.id === tempLaunchRuleToEdit.senderID);
				
				//Fetch the sender              
				if (sender) {
					setSenderHover(sender);   //Set the SENDER to display first
					setShowLaunchRuleCalendarHover(true); 
				} else {
					console.log("Error - sender not found", sender, tempLaunchRuleToEdit.senderID);
				}
			}
			
		} else {
			if (DEBUG_MODE >= 2) console.log("Error -calendar event MOUSE ENER callback but no event");
		}
	  };    

	const calendarEventMouseLeaveHandler = (e) => {
		if (DEBUG_MODE >= 3) console.log("Calendar MOUSE EXIT Handler Invoked", e);
		if (e) e.jsEvent.preventDefault();
		setShowLaunchRuleCalendarHover(false);
	  };    

	
	function displayCalendarEventHover({launchRuleToEdit, showLaunchRuleCalendarHover}) {
		if (!launchRuleToEdit) return null;
		return (
			<div className ="calendarEventHoverOuter" style={{top:calendarEventHoverTopPos, left:calendarEventHoverLeftPos}} hidden={!showLaunchRuleCalendarHover} >

				 <div className="ContainerVerticalSpaceBetween" style={{width:"100%", position:"relative"}}>
				 
					<div className="calendarEventHoverAnchor" >
						<div style={{fontSize:"1.2rem", color:launchRuleToEdit.color}}> &#9650; </div>
					</div>
					
					{launchRuleToEdit && launchRuleToEdit.launchRepeatOption !== 1 ? <div className="calendarRepeatEventAnchor"  style={{fontSize:"1.5rem", color:launchRuleToEdit.color}}> <RepeatIcon />  </div> : null}
					
					
					<div style={{backgroundColor: launchRuleToEdit.color, width:"100%", height:"0.4rem"}}> </div>
 
					 <div className="ContainerNoHeightFlexLeft" style={{width:"100%"}} >
						<div style={{padding:"5px"}} > 
							<img src={launchRuleToEdit.conectivityImageURL} height='40px' alt=''/>
						</div>
						<div className="ContainerVerticalCenter" style={{width:"100%"}}>
							<div className="calendarEventHoverTitleText" > {launchRuleToEdit.title} </div>
							<div style={{fontSize:"0.8rem", height:"0.8rem"}}> {hoverDate} </div>  
							<div style={{padding:"1.0rem"}}>{displayUsersRow({sender:senderHover, usersToShow:usersToShowHover, numUsersToShow:7, isSmall:false, rsvpRecords:launchRuleToEdit.rsvpRecords, launchRuleInstanceIndex:launchRuleToEdit.launchRuleInstanceIndex})}</div>
						</div>
					</div>
				 </div>
				
			</div>
		);	
	}    

	// Callbacks for Drag & Drop
	
	const handleEventDragStart = (e) => {
		if (e) e.jsEvent.preventDefault();
		if (DEBUG_MODE >= 2) console.log("Calendar EVENT  Drag Start Invoked", e);
		setShowLaunchRuleCalendarHover(false);  //Drop our HOVER display as it may be in the way of the user
		setDragStartMom(null);
		setDragEndMom(null);
		setIsDragInProgress(true);
	};
	
	const handleEventDragStop = (e) => {
		if (e) e.jsEvent.preventDefault();
		if (DEBUG_MODE >= 2) console.log("Calendar EVENT  Drag Stop Invoked", e);
	};
	
	const handleEventDrop = (e) => {	
		if (e) e.jsEvent.preventDefault();
		if (DEBUG_MODE >= 2) console.log("Calendar EVENT  Drop Invoked", e);
		
		// store e and pop-up confirmation model
		// .calendar({sameElse: 'ddd, MMM Do, h:mm a'}))
		if (e.oldEvent.startStr) {setDragStartMom(moment(e.oldEvent.startStr));  if (DEBUG_MODE >= 2) console.log("Drag start set", e.oldEvent.startStr);}
		if (e.event.startStr) {setDragEndMom(moment(e.event.startStr)); if (DEBUG_MODE >= 2) console.log("Drag end set", e.event.startStr);}
		
		setDragAndDropEvent(e);
		setAutoSave(false);
		setShowModalDragAndDrop(true);	
	};
	
	const closeDropModalCallBack = () => {
		if (DEBUG_MODE >= 2) console.log("Closed Drag And Drop Modal", dragAndDropEvent);
		
		// undo the drag and drop change
		if(dragAndDropEvent) dragAndDropEvent.revert();
		else console.log('No calendar event to revert', dragAndDropEvent);
		setShowLaunchRuleCalendarHover(false);
		setShowModalDragAndDrop(false);
		setDragAndDropEvent(null);
		setIsUpdatingBackend(false);
		setAutoSave(false);
		setIsDragInProgress(false);
		setAutoRSVP(false);
		setAutoRsvpStatus('');
	};
	
	const handleConfirmationDragAndDrop = async (e) => {
		
		if (e) e.preventDefault();
		if (DEBUG_MODE >= 2) console.log("Handle Confirm Drag And Drop Edit", e, dragAndDropEvent);
		const CHANGE_TYPE = "DRAG";
		
		setIsUpdatingBackend(true);
		setAutoSave(true);
		setAutoRSVP(false);
		setAutoRsvpStatus('');
		setShowModalDragAndDrop(false);
	   if(dragAndDropEvent && dragAndDropEvent.event && dragAndDropEvent.event.startStr && dragAndDropEvent.event.endStr) {
			let newEventStart = moment(dragAndDropEvent.event.startStr);
			let newEventEnd = moment(dragAndDropEvent.event.endStr);
			setAutoSaveEventStartDateTime(newEventStart);
			setAutoSaveEventEndDateTime(newEventEnd);
			 if (DEBUG_MODE >= 2) console.log("Updated Launch Rule with times from dragged calendar entry", newEventStart.toISOString(), newEventEnd.toISOString());
		} else {
			 if (DEBUG_MODE >= 2) console.error("Improper event received from drag and drop", dragAndDropEvent);
		}

		if (dragAndDropEvent) await calendarViewEventHandler(dragAndDropEvent, CHANGE_TYPE);
		else console.log('No calendar event to update', e, dragAndDropEvent);
		
		setIsDragInProgress(false);
		setDragAndDropEvent(null);
	};

	
	function displayCalendar () {
		
		return (
			<div className="ContainerVerticalLeft" style={{position:"relative", zIndex:"1", width:"100%"}}  >
			
					{/*<div style={{color:COLOR_BLUE_TEXT, fontSize:"2.0rem", fontWeight:"500"}}>Employees</div> */}
			
					<div className = "ContainerMaxHeightSpaceBetween alignStart fullWidth" style={{zIndex:"999", paddingTop:"clamp(4px,1vw,10px)"}}>
						 <div className="fullWidth">
							<SearchInputBar 
								//General search bar props
								handleSearchClick={handleSearchClick}
								// zIndex="99999"
								searchButtonHoverText='Calendar Filters'
								buttonPositionLeft
								searchContainerAlignedLeft
								showHeaders

								//User Select Props
								enableUserSelectSearch={true} 
								usersOptions={usersOptions} 
								handleSelectUser={setSelectedUsersOptionsForSearch} 
								isMultiUserSelect={true} 
								selectedUsersOptions={selectedUsersOptionsForSearch}

								 //Team Select Props
								enableTeamSelectSearch={true} 
								teamsOptions={teamsOptions} 
								handleSelectTeam={setSelectedTeamsOptionsForSearch} 
								isMultiTeamSelect={true} 
								selectedTeamsOptions={selectedTeamsOptionsForSearch}
								
								//Conectivity Search Props
								enableSearchInput={true} 
								onSearchStringChangeCallback={onSearchStringChangeCallback} 
								onFilterChangeCallback={onFilterChangeCallback} 
								searchBarIsActiveCallback={searchBarIsActiveCallback} 
								searchTermInitialValue={searchTerm} 
								searchActiveInitialValue={searchActive} 
								quickFilters={QUICK_FILTERS_FOR_CONTECTIVITIES}                   
							/>  
						</div>           
						{/*
						<div className = "ContainerNoHeightFlexLeft" style={{minHeight:"4.0rem"}}>
							<SearchButton onClickCallback={handleSearchClick} hoverText='Search' fontSize="2.0rem" hoverTextClassName='TextStyle4 blue' />
			
							{false && searchMode ? 
									displayCalendarControl()
							:
								null
							}
						</div>
						*/}
			
						{isMobileViewPort ? null : 
							<div className = "ContainerNoHeightFlexEndCentered" style={{paddingLeft:"clamp(4px,1vw,10px)"}}>
								{/*
									<div className="ContainerNoHeightCenter noWordWrap TextStyle4" >
									<div>List View</div> 
									<Switch checked={showListView} onChange={handleToggleListView}/>
									</div>
								*/}

								{ isRenderedWithinMsftClient ? null : <CloudSyncButton onClickCallback={() => setRedirectToPage("/userAccountSettings")} hoverText='Calendar Synchronization' fontSize="2.0rem" hoverTextClassName='TextStyle4 blue' /> }

							</div>
						}
					</div>

					{/* displayUsersRow({usersToShow:selectedUsersOptionsForSearch, numUsersToShow:7}) */}

					<div className = "fullWidth positionRelative" style={{zIndex:"888"}} >
					   
						<CalendarView 
							events={launchRulesForSelectedUsers}
							// events={eventsTest}
							dateClick={calendarDateClickHandler}
							eventClick={calendarEventClickHandler}
							eventMouseEnter={calendarEventMouseEnterHandler}
							eventMouseLeave={calendarEventMouseLeaveHandler}
							eventDragStart={handleEventDragStart}
							eventDragStop={handleEventDragStop}
							eventDrop={handleEventDrop}
							addButtonHandler={addButtonHandler}
						/>
							
							{displayCalendarEventHover({launchRuleToEdit:launchRuleToEdit, showLaunchRuleCalendarHover:showLaunchRuleCalendarHover})}
											   
				  </div>
				</div>            
		);
	}    

// SEARCH BAR CALLBACKS
// https://alexsidorenko.com/blog/react-list-rerender/
const onSearchStringChangeCallback = useCallback ((value) => {
	 setSearchTerm(value);
	//  if (DEBUG_MODE >= 2) console.log("Search bar CALLBACK called", value);
   
},[]);

const onFilterChangeCallback = useCallback ((activeFilters) => {
	 setActiveFilters(activeFilters);
	//  if (DEBUG_MODE >= 2) console.log("Search bar QUICK FILTER CALLBACK called", activeFilters);
   
},[]);

const searchBarIsActiveCallback = useCallback ((active) => {
	 setSearchActive(active);
	//  if (DEBUG_MODE >= 2) console.log("Search bar IS ACTIVE CALLBACK called", active);
   
},[]);

const handleSearchClick = useCallback(() => {
	if (DEBUG_MODE >= 2) console.log("User Calendar: Search click");
	setSearchMode(!searchMode);
},[searchMode]);

/*
	CALENDAR ENTRY PREVIEW HANDLERS
*/

	 //handle close calendar entry preview
	const handleCancelCalendarEntryModal = () => {
		setShowModalCalendarEntryPreview(false);
	   //if (DEBUG_MODE >= 2) console.log("Closed Conectivity Detail View.");
	};
	
	//Handle user click on EDIT from Entry Preview
	 const handleEditCalendarEntry = useCallback(() => {
		if (DEBUG_MODE >= 2) console.log("Edit calendar entry callback invoked");

		//Safety check
		if (!conectivityToUpdate || !launchRuleToEdit || !launchRuleInstanceStartDateTime || !launchRuleInstanceIndex) {
			if (DEBUG_MODE >= 2) console.log("Error - incorrect params for an edit");
			return;
		}
			  
		//Pop-up our Conectivity Launcher    
		setShowModalEditInviteToConect(true); 
		setShowModalCalendarEntryPreview(false);

	},[conectivityToUpdate, launchRuleToEdit, launchRuleInstanceStartDateTime, launchRuleInstanceIndex]);

	//Handle user click on DELETE from Entry Preview
	 const handleDeleteCalendarEntry = useCallback(() => {
		if (DEBUG_MODE >= 2) console.log("Delete calendar entry callback invoked");

	   //Safety check
		if (!conectivityToUpdate || !launchRuleToEdit || !launchRuleInstanceStartDateTime || !launchRuleInstanceIndex) {
			if (DEBUG_MODE >= 2) console.log("Error - incorrect params for a delete");
			return;
		}
			  
		//Pop-up our Conectivity Launcher    
		setShowModalDeleteInviteToConect(true); 
		setShowModalCalendarEntryPreview(false);

	},[conectivityToUpdate, launchRuleToEdit, launchRuleInstanceStartDateTime, launchRuleInstanceIndex]);


	//Handle user click on DECLINE from Entry Preview
	 const handleDeclineCalendarEntry = useCallback(() => {
		if (DEBUG_MODE >= 2) console.log("Decline calendar entry callback invoked");

	   //Safety check
		if (!conectivityToUpdate || !launchRuleToEdit || !launchRuleInstanceStartDateTime || !launchRuleInstanceIndex) {
			if (DEBUG_MODE >= 2) console.log("Error - incorrect params for a decline");
			return;
		}
			  
		//Invoke our Conectivity Launcher for an auto-save  
		setAutoRSVP(true);                  //Tell our launcher that this is an RSVP being submitted rather than an edit tot the LR        
		setAutoRsvpStatus('DECLINED');      //status           
		setAutoSave(true);                  //enable auto-save
		setShowModalEditInviteToConect(true);   //Invoke our launcher component; no modal will be displayed since AutoSave is set to true

	},[conectivityToUpdate, launchRuleToEdit, launchRuleInstanceStartDateTime, launchRuleInstanceIndex]);


	//Handle user click on ACCEPT from Entry Preview
	const handleAcceptCalendarEntry = useCallback(() => {
		if (DEBUG_MODE >= 2) console.log("Accept calendar entry callback invoked");

	   //Safety check
		if (!conectivityToUpdate || !launchRuleToEdit || !launchRuleInstanceStartDateTime || !launchRuleInstanceIndex) {
			if (DEBUG_MODE >= 2) console.log("Error - incorrect params for accept rsvp");
			return;
		}
			  
		//Invoke our Conectivity Launcher for an auto-save  
		setAutoRSVP(true);                      //Tell our launcher that this is an RSVP being submitted rather than an edit tot the LR        
		setAutoRsvpStatus('ACCEPTED');          //status           
		setAutoSave(true);                      //enable auto-save
		setShowModalEditInviteToConect(true);   //Invoke our launcher component; no modal will be displayed since AutoSave is set to true

	},[conectivityToUpdate, launchRuleToEdit, launchRuleInstanceStartDateTime, launchRuleInstanceIndex]);
			
			
	//Add Button Handler - callBack from FullCalendar
	const addButtonHandler = useCallback(() => {
		if (DEBUG_MODE >= 2) console.log("Add Button Callback invoked");
 
		//Reset vars and invoke our Launcher
		setLaunchRuleToEdit(null);
		setConectivityToUpdate(null);
		setLaunchRuleInstanceStartDateTime(null);
		setLaunchRuleInstanceIndex(1);
		setShowModalSendInviteToConect(true);
		
	},[showModalSendInviteToConect, conectivityToUpdate, launchRuleToEdit, launchRuleInstanceStartDateTime, launchRuleInstanceIndex]);

	//This function handles marking a conectivity as complete; Pop-up a modal and ask user to confirm. Handle confirmation
	const handleDidItClick = (e) => {
		if (DEBUG_MODE >= 2) console.log("Done button pressed.  ");
		if (e) e.stopPropagation();   

		//Safety check - these should have already been selected when the user clocked on a calendar entry
		if (!launchRuleToEdit || !launchRuleToEdit.id || !scheduledConectivityToProcess || !scheduledConectivityToProcess.id || !invitationToUpdate || !invitationToUpdate.id) {
			if(DEBUG_MODE) console.error("Error - improper params to mark conectivity as complete", launchRuleToEdit, scheduledConectivityToProcess, invitationToUpdate);
			return;
		}
		if (DEBUG_MODE >= 2) console.log("Processing DID IT button for the SC & Invitation already selected by the user", invitationToUpdate);
		if (DEBUG_MODE >= 2) console.log("Launch Rule:", launchRuleToEdit);
		if (DEBUG_MODE >= 2) console.log("Scheduled conectivity:", scheduledConectivityToProcess);
		if (DEBUG_MODE >= 2) console.log("Invitation:", invitationToUpdate);
 
		setShowModalCalendarEntryPreview(false);   //Close the calendar entry view 
		setShowModalDone(true); //pop-up Conectivity Complete Modal
	};

	const handleCloseDoneModal = () => {
		setShowModalDone(false);
	}
  //
  // Page Render
  //

	//Handle access by unauthenticated user
	if (authState !== "signedin"  || !currentUser) return null;

	//Render function for authenticated user 
  return (

	<Authenticator>

			 <CalendarEntryPreview 
				showModal={showModalCalendarEntryPreview} 
				conectivityToView={conectivityToUpdate} 
				launchRule={launchRuleToEdit}
				launchRuleInstanceStartDateTime={launchRuleInstanceStartDateTime}
				launchRuleInstanceEndDateTime={launchRuleInstanceEndDateTime}
				launchRuleInstanceIndex={launchRuleInstanceIndex}
				editCallback={handleEditCalendarEntry}
				deleteCallback={handleDeleteCalendarEntry}
				declineCallback={handleDeclineCalendarEntry}
				acceptCallback={handleAcceptCalendarEntry}
				closeModalCallback={handleCancelCalendarEntryModal} 
				markCompleteCallback={handleDidItClick}
				scheduledConectivity={scheduledConectivityToProcess}
				invitation={invitationToUpdate}
			/>

			 <ConectivityLauncher  
				showModalSendInviteToConect={showModalSendInviteToConect}  
				showModalEditInviteToConect={showModalEditInviteToConect}
				showModalDeleteInviteToConect={showModalDeleteInviteToConect}
				autoSave={autoSave}
				autoSaveEventStartDateTime={autoSaveEventStartDateTime}
				autoSaveEventEndDateTime={autoSaveEventEndDateTime}
				autoRSVP={autoRSVP}
				autoRsvpStatus={autoRsvpStatus}
				scheduledConectivityToProcess={scheduledConectivityToProcess} 
				invitationToUpdate={invitationToUpdate}                 //Probably no longer needed as backend fetches  the invitationns (or should) and matches invitations to LR instance dates
				conectivityToProcess={conectivityToUpdate} 
				launchRuleToEdit={launchRuleToEdit}
				handleCloseLauncher={handleCloseLaunchModal}  
				launchRuleInstanceStartDateTime={launchRuleInstanceStartDateTime}
				launchRuleInstanceIndex={launchRuleInstanceIndex}
			 />
 
			<ModalNoBackgroundFixed showModal={showModalDragAndDrop} closeCallback={closeDropModalCallBack} cardColor={TEAM_COLOR}  >
				<div className="ContainerVerticalCenter modalGeneralInnerCard" >
					<div className="ContainerVerticalSpaceBetween modalGeneralCenterColumn" >
						<div className="TextStyle4 black" style={{padding:"20px"}}> Change the date of this conectivity   { dragStartMom && dragEndMom ?  <span>from  {dragStartMom.calendar({sameElse: 'ddd, MMM Do'})} to {dragEndMom.calendar({sameElse: 'ddd, MMM Do '})}? </span> : '?'  }  </div>
						<div className="modalNoBkgFooter flexEnd">
							<div className="ContainerNoHeightCenter"> 
								<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Confirm" onClick={(e) => handleConfirmationDragAndDrop(e)} >  Yes!  </button> 
								<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="Cancel" onClick={closeDropModalCallBack} >  No  </button>  
							</div>
						</div>
					</div>
				</div>
			</ModalNoBackgroundFixed >

	
			<ModalConectivityComplete showModal={showModalDone} launchRule={launchRuleToEdit} scheduledConectivityToProcess={scheduledConectivityToProcess} conectivityAction={'COMPLETED_CONECTIVITY'} handleClose={handleCloseDoneModal} />
				   
 {/*        REMOVED AND REPLACED WITH DASHBOARD WRAPPER BELOW       
	<div className="userUpcomingScheduleOuterWrapper" >
				 <div className="userUpcomingScheduleInnerWrapper" >
 */}                

			<div className="adminPage">
				<div className="dashboardContainer">
					<div className="dashboardTitle" style={{zIndex:"2"}}>
						<div className="ContainerNoHeightFlexLeft">
							<div>Conectivities Calendar</div>
							<HelpButton hoverTextClassName='TextStyle4 blue hoverTextStyle1 hoverTextStyle1ExtraWide' hoverText={isSuperAdmin || isAdmin ? HELP_TEXT_ADMIN_CALENDAR_1 : HELP_TEXT_NON_ADMIN_CALENDAR_1}  /> 
						</div>
					</div>                    
					{displayCalendar()}
				</div>
			</div>

			{!redirectToPage ? "" : <Navigate to={redirectToPage} />}

		</Authenticator>
  );
};




export default UserConectivitySchedule;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import React from 'react';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_GOLD } from '../../../../shared/data/conectereConfigData';

//Utils
import { displayCommunityTextInline } from "../../../../shared/utils/generalUtils";

import { Divider } from "../../../../shared/utils/generalUtils";

//React component
const DisplayCardMentorStats = React.memo(({programAnalytics, showMentorship, showSponsorship}) => {
    if (DEBUG_MODE >= 2) console.log("Mentor Analtytics", programAnalytics, showMentorship, showSponsorship)
    if (!programAnalytics || (!showMentorship && !showSponsorship)) return null;
    const title=(showMentorship ? "MENTORSHIP PROGRAM" : "SPONSORSHIP PROGRAM"); 
    const footer="";
   return (
        <div className="dashboardCard">
            <div className="ContainerVerticalCenterFullWidth">
                <div className="TextStyle4 headerBlue heavy"> {title} </div>
                <Divider width={80} />
            </div>
            
            <div className="ContainerNoHeight fullWidth wrap" >
                <div className="ContainerVerticalCenter" style={{padding:"clamp(8px,1vw,16px)"}}>
                    <div className="dashboardScoreCircle" style={{borderColor:SOCIAL_COLOR}}>
                        { (showMentorship ? programAnalytics.usersTotal : programAnalytics.sponsorshipCandidatesTotal)}
                    </div>
                    <div className="dashboardCardText">{ (showMentorship ? "EMPLOYEES" : "CANDIDATES") }</div>
                </div>
                
                <div className="ContainerVerticalCenter"style={{padding:"clamp(8px,1vw,16px)"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:GROWTH_COLOR}}>
                        { (showMentorship ? programAnalytics.numMentors : programAnalytics.numSponsors)}
                    </div>
                     <div className="dashboardCardText">{ (showMentorship ? "MENTORS" : "SPONSORS" )}</div>
                </div> 
            </div>

            <div className="ContainerNoHeight fullWidth wrap" >
                <div className="ContainerVerticalCenter"style={{padding:"clamp(8px,1vw,16px)"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:BALANCE_COLOR}}>
                        { (showMentorship ? programAnalytics.usersWithMentors  : programAnalytics.candidatesWithSponsors)}
                    </div>
                     <div className="dashboardCardText">{ (showMentorship ?  "MENTEES" : "SPONSOREES" )}</div>
                </div>  
                
                <div className="ContainerVerticalCenter"style={{padding:"clamp(8px,1vw,16px)"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:TEAM_COLOR}}>
                        { (showMentorship ? programAnalytics.usersWithoutMentors  : programAnalytics.candidatesWithoutSponsors)}
                    </div>
                     <div className="ContainerVerticalCenter" >
                        <div className="dashboardCardText">UNASSIGNED</div>
                        <div className="dashboardCardText">{ (showMentorship ? "EMPLOYEES" : "CANDIDATES" )}</div>
                    </div>
                </div> 
            </div >

            <div className="ContainerNoHeightCenter dashboardCardTextFooter">{footer} </div>
        </div>
        
    );        

});


export default DisplayCardMentorStats;



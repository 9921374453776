//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//React & Amplify
import React from 'react';

import "./fileUpload.css";

//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLACK, COLOR_WHITE  } from '../../data/conectereConfigData'; 

import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL (I think);

//Color theme not working yet
const theme = createTheme({

  palette: {
    primary: {
      main: '#409cf7',
      darker: '#409cf7',
    },
    neutral: {
      main: '#409cf7',
      contrastText: '#fff',
    },
  },
});



//React component for uploading an image from the user
export const FileUploader = ({imageChangeCallback, fileTypesToAccept, buttonText, color, fileIconFlag, isDisabled}) => {

    if (color == null) color = "black";
    if (fileIconFlag == null) fileIconFlag = false;
    
    const id = uuidv4();
    
    if (!fileTypesToAccept) fileTypesToAccept = "image/*" ;
    if (!buttonText) buttonText = "Upload File" ;

    //Our callback function to parent on any change
    function invokeCallBack(e) {

        if (imageChangeCallback) {
            if (DEBUG_MODE >= 2) console.log("Image Upload Callback Invoked", e);
            imageChangeCallback (e);
        } else {
            if (DEBUG_MODE >= 2) console.log("Error - No Image Upload Callback", e);
        }
    }

    // if (DEBUG_MODE >= 2) console.log("File Upload Component called.  Key generated:", id);

    return (
        <div>
            <input 
                accept={fileTypesToAccept}
                type="file" 
                id={id}
                onChange={(e) => invokeCallBack(e)}  
                style={{ display: 'none'}}
                disabled={isDisabled}
            />
            <label htmlFor={id}>
                <ThemeProvider theme={theme}>   
                    <Button variant="outlined" component="span" disabled={isDisabled}>
                        <div  style={{paddingBottom:"5px", color: color}}>
                            {fileIconFlag ? <NoteAddIcon style={{color:color}} />
                                : <AddAPhotoIcon style={{color:color}} />
                            }
                        </div>
                        <div style={{paddingLeft:"5px", color:color}}>{buttonText}</div>
                   </Button>
               </ThemeProvider>
            </label>        
        </div>
    );

};





//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';
import React, { useEffect, useState, useContext, useRef } from "react";
import { generateClient } from 'aws-amplify/api';   // Amplify V6

//Context
import { AuthContext } from './authContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context

//Queries and Mutations
import { listOrders,  getOrdersByCustomer, getOrdersByCustomerByDate} from '../graphql/queries';

//Subscriptions
import { onCreateOrderByCustomer, onUpdateOrderByCustomer, onDeleteOrderByCustomer } from "../graphql/subscriptions";

//Utils
import { queryDataTableWithPagination} from "../utils/databaseUtils";
import { compareByCreatedAtReverseChronological } from "../utils/generalUtils";
import {NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING, ONE_MONTH_AGO, ONE_MONTH_AGO_STRING} from "../utils/dateTimeUtils";

import moment from 'moment';

const API = generateClient(); //Amplify V6
const OrdersContext = React.createContext();
const OrdersProvider = ({ children }) => {

   // Authentication context
	const {
		   authState, currentUser, isSuperAdmin, isAdmin, permissionOrders, permissionBilling,
	} = useContext(AuthContext);

   // Customer context
	const {
			selectedCustomerOptions 
	} = useContext(CustomerContext);  


	//Order cache
	const [orderCache, setOrderCache] = useState([]);
	const [orderStartDateTime, setOrderStartDateTime] = useState(ONE_YEAR_AGO());               
	const [orderEndDateTime, setOrderEndDateTime] = useState(NOW());               
	const [orderOldestStartDateTimeFetched, setOrderOldestStartDateTimeFetched] = useState(ONE_YEAR_AGO());               
	const [orderCacheInitialized, setOrderCacheInitialized] = useState(false);
	const [orderCountFilteredDateRange, setOrderCountFilteredDateRange] = useState(0)
	const [ordersFiltered, setOrdersFiltered] = useState([]);


	//Get order data upon opening this page, return an array of existing orders, if any
	useEffect(() => {
		updateOrderCache();  
	}, [authState, orderStartDateTime]);
	
	//let's write the function to grab the order data
	async function updateOrderCache() {
		
		if (authState !== "signedin") {
			setOrderCacheInitialized(false);	//Chage in Auth state so mark our cache as dirty to make sure we reload it as user may have signed out and signed in as a different user
			return;
		}

		//User authorized for Orders?
		if (!isSuperAdmin && !isAdmin && !permissionOrders && !permissionBilling) return;

		if (!orderStartDateTime) {
			if (DEBUG_MODE >= 2) console.log("ORDER CACHE: NULL ORDER START DATE ");
		   return;
		}

		//This check catches changes to the date that are not yet valid dates
		if (!orderStartDateTime.toISOString()) {
			if (DEBUG_MODE >= 2) console.log("ORDER CACHE: NULL ORDER START DATE STRING");
		   return;
		}

		//Set our start of search period
		var tempOrderStartDate = orderStartDateTime.clone().startOf('day');

		//Start date out of range, i.e.,in the future
		if (tempOrderStartDate.clone().isAfter(NOW())) {
			if (DEBUG_MODE >= 2) console.log("ORDER CACHE: ORDER START DATE IN THE FUTURE; SKIPPING");
			return;
		}

 
		//Start date out of range, i.e., too long ago?
		if (tempOrderStartDate.clone().isBefore(NOW().subtract(1,'years').startOf('day'))) {
			if (DEBUG_MODE >= 2) console.log("ORDER CACHE: ORDER START DATE OUT OF BOUNDS; SETTING TO 1 YEAR AGO");
			tempOrderStartDate = ONE_YEAR_AGO().startOf('day');
		}
 
		 //Have we already fetched at least the requested orders for this particular customer?
		//Check to see if we have fetched order prior to OR equal to the same day, but only if not a forced reload due to a new wallet or auth change
		if (orderCacheInitialized) {
			if (orderOldestStartDateTimeFetched.clone().startOf('day').isBefore(tempOrderStartDate.clone().startOf('day')) ||
					orderOldestStartDateTimeFetched.clone().startOf('day').isSame(tempOrderStartDate.clone().startOf('day'))) {
				if (DEBUG_MODE >= 2) console.log("ORDER CACHE: ALREADY FETCHED SUFFICIENT ORDERS ");
				return;
			}
		}

		// if (DEBUG_MODE >= 2) console.log("ORDER CACHE:  orderOldestStartDateTimeFetched=" + orderOldestStartDateTimeFetched.toISOString() );
		// if (DEBUG_MODE >= 2) console.log("ORDER CACHE:  orderStartDateTime=" + tempOrderStartDate.toISOString() );


		try {
			var tempOrders = [];

			if (process.env.IS_MANAGMENT_PORTAL) {
				// gather ALL order data from backend
				 const queryParams = {
					limit: 5000,
				};
 
				tempOrders =  await queryDataTableWithPagination(listOrders, "listOrders", queryParams);
				 if (DEBUG_MODE >= 2) console.log("ORDER CACHE: Fetched orders for ALL customers  back to " + tempOrderStartDate.toISOString(), tempOrders);		   
			} else {
				// gather order data for just the customer
				 const queryParams = {
					customerID: currentUser.customerID,                                           //Primary Key
					// createdAt: {between: [ tempOrderStartDate.toISOString(), NOW_STRING() ]},     //Secondary Key
				};
			  
				// THIS QUERY IS NOT YET AVAILABLE - COULD NOT DO AN AMPLIFY PUSH
				//ALSO - THIS WOULD EXPOSE THE CREATED AT DATE AS A MANAGED FIELD SO MAY REQUIRE AN UPDATE TO THE MOBILE APP
				// tempOrders =  await queryDataTableWithPagination(getOrdersByCustomerByDate, "getOrdersByCustomerByDate", queryParams);
				tempOrders =  await queryDataTableWithPagination(getOrdersByCustomer, "getOrdersByCustomer", queryParams);
				// if (DEBUG_MODE >= 2) console.log("ORDER CACHE: Fetched orders for customer: " + currentUser.customerID, tempOrders);
				// if (DEBUG_MODE >= 2) console.log("ORDER CACHE: Fetched orders for customer: " + currentUser.customerID + " back to " + tempOrderStartDate.toISOString(), tempOrders);
			}
 
			//Sort orders by date
			tempOrders.sort(compareByCreatedAtReverseChronological);
			
			if (DEBUG_MODE >= 2) console.log("Filtered and sorted orders", tempOrders);
			
			//Save the orders to our cache and update cache params
			if (tempOrders) {
				setOrderCacheInitialized(true); 
				setOrderCache([...tempOrders]);
				setOrderCountFilteredDateRange(tempOrders.length);
				setOrderOldestStartDateTimeFetched(tempOrderStartDate); 
				
				// if (DEBUG_MODE >= 2) console.log("Order cache initialized");
			}

		} catch (err) { if (DEBUG_MODE >= 2) console.log('ORDER CACHE: error fetching  data', err); }            
}


	// Respond to changes in the order cache or the time range 
	
	useEffect(() => {
		updateFilteredOrders();
	}, [orderCache, orderStartDateTime, orderEndDateTime]);


	async function updateFilteredOrders() {

		if (authState !== "signedin" || !orderCacheInitialized) return;

		//These checks catch improper dates that may occur WHILE the user is typing
		if (!orderStartDateTime || !orderEndDateTime) {
			if (DEBUG_MODE >= 2) console.log("ORDER CACHE FILTER: NULL ORDER START DATE ");
		   return;
		}

		//This check catches changes to the date that are not yet valid dates
		if (!orderStartDateTime.toISOString() || !orderStartDateTime.toISOString()) {
			if (DEBUG_MODE >= 2) console.log("ORDER CACHE FILTER: NULL ORDER START DATE STRING");
		   return;
		}

		const startOfSearchPeriod = orderStartDateTime.clone().startOf('day');
		const endOfSearchPeriod = orderEndDateTime.clone().endOf('day');
		
		if (DEBUG_MODE >= 2) console.log("Orders Search period", startOfSearchPeriod.toISOString(), endOfSearchPeriod.toISOString());
		
		//Filter orders based on user selections, order date  and any category or order type filter selected
		let tempOrders = [...orderCache]; //Create a new variable to trigger any updates
		tempOrders =  tempOrders.filter(order => 
				(moment(order.createdAt).isAfter(startOfSearchPeriod)) &&
				(moment(order.createdAt).isBefore(endOfSearchPeriod)));

		if (DEBUG_MODE >= 2) console.log("UPDATED Filtered orders", tempOrders);

		setOrdersFiltered(tempOrders);
	}
	   
 
	//Set up subscriptions to receive user orders upon creation and updates for this user
	// Note, we need to make use either Functional State Data Updates or use Ref() to get access to current values of state data
	// since this UseEffect call is called upon mount (Auth change)
	// https://reactjs.org/docs/hooks-reference.html#functional-updates
	//https://stackoverflow.com/questions/69285519/access-latest-value-of-state-in-aws-amplify-subscriptions-react

	const orderCacheRef = useRef();  //Defining a React Reference so that we can use the current state data during this useEffect call

	useEffect(() => {
	  orderCacheRef.current = orderCache;           
	});   //Update on every state change


//
// Subscriptions for Orders
//

 
	//Functions and state data for real-time updates
	const [orderCreateSubscriptionSetUp, setOrderCreateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [orderUpdateSubscriptionSetUp, setOrderUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [orderDeleteSubscriptionSetUp, setOrderDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	
	useEffect(() => {
		
		//User authorized for Orders?

		if (!orderCreateSubscriptionSetUp && authState == 'signedin'  && currentUser && currentUser.customerID) {

			if (isSuperAdmin || isAdmin || permissionOrders || permissionBilling) {
			
			//   if (DEBUG_MODE >= 2) console.log("Setting up subscription for orders created for customer", currentUser.customerID);
				
			   const subscription = API.graphql({
					query:onCreateOrderByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("New order data received via subscription",messageData);
							if (messageData.data.onCreateOrderByCustomer !== null) {
								
								const newOrder = messageData.data.onCreateOrderByCustomer;
								
								if (DEBUG_MODE >= 2) console.log("New order extracted from message",newOrder);
	
								const tempOrders = [...orderCacheRef.current];   //Get our state data for existing orders
	
								 if (DEBUG_MODE >= 2) console.log("Current orders",tempOrders);
	
								tempOrders.push(newOrder);    //Add the new order
								
								//Sort the Orders      
								tempOrders.sort(compareByCreatedAtReverseChronological);
	
								//Update system-wide state data with the new orders
								setOrderCache(tempOrders);
								if (DEBUG_MODE >= 2) console.log("Orders after CREATE from Subscription", tempOrders);
	
							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing order create subscription message",messageData);
	
						}
					},
					error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setOrderCreateSubscriptionSetUp(true);
				
			   return () => {
					subscription.unsubscribe();
					setOrderCreateSubscriptionSetUp(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE PRODUCTS");
				};
			}   
 
		}
	},[authState]);                     //Call function when a change to authState occurs
	  

	  
	useEffect(() => {
		if (!orderUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser !== null && currentUser.customerID) {

			if (isSuperAdmin || isAdmin || permissionOrders || permissionBilling) {
			
			  if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for orders for this user", currentUser);
				
			   const subscription = API.graphql({
					query:onUpdateOrderByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							// if (DEBUG_MODE >= 2) console.log("New order UPDATE data received via subscription",messageData);
							if (messageData.data.onUpdateOrderByCustomer !== null) {
								
								const updatedOrder = messageData.data.onUpdateOrderByCustomer;
								
								// if (DEBUG_MODE >= 2) console.log("Order update extracted from message",updatedOrder);
								
								const tempOrders = [...orderCacheRef.current];   //Get our state data for existing orders
	
								//  if (DEBUG_MODE >= 2) console.log("Current orders",tempOrders);
								 
								 const index = tempOrders.findIndex(order => order.id == updatedOrder.id);
								 
								 if (index > -1) {
	

								//Update system-wide state data with the received object
								//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
								tempOrders[index] = updatedOrder;    //Replace the existing order with the updated one just received
								setOrderCache(tempOrders);
								// if (DEBUG_MODE >= 2) console.log("Orders after UPDATE from Subscription", tempOrders);
	
	
								 } else {
									 if (DEBUG_MODE >= 2) console.log("Error - no matching order found to update", updatedOrder, tempOrders);
								 }
								
							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing order update subscription message",messageData);
	
						}
					},
					error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setOrderUpdateSubscriptionSetUp(true);
				
			   return () => {
					subscription.unsubscribe();
					setOrderUpdateSubscriptionSetUp(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE PRODUCTS");
				};
			}
 
		}
	},[authState]);                     //Call function when a change to authState occurs
	


   useEffect(() => {
		if (!orderDeleteSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {

		   if (isSuperAdmin || isAdmin || permissionOrders || permissionBilling) {
			
			  if (DEBUG_MODE >= 2) console.log("Setting up subscription for deleted orders created by THIS COMPANY ");
				
			   const subscription = API.graphql({
					query:onDeleteOrderByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							// if (DEBUG_MODE >= 2) console.log("New orderByCustomer data received via subscription",messageData);
							if (messageData.data.onDeleteOrderByCustomer !== null) {
								
								const deletedOrderByCustomer = {... messageData.data.onDeleteOrderByCustomer };
								// if (DEBUG_MODE >= 2) console.log("Deleted order extracted from message",deletedOrderByCustomer);
								
								 //Remove the matching order rule from local state. 
								var tempOrders = orderCacheRef.current.filter(element => element.id != deletedOrderByCustomer.id);
								setOrderCache(tempOrders); //Delete state data with newly sorted list  
								// if (DEBUG_MODE >= 2) console.log("Deleted order", tempOrders);
								
							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing  DELETE order subscription message",messageData);
	
						}
					},
					error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setOrderDeleteSubscriptionSetUp(true);
			   return () => {
					subscription.unsubscribe();
					setOrderDeleteSubscriptionSetUp(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE order by company");
				};
		   }
 
		}
	},[authState]);                     
	
   
	//return the Orders Context provider
	return (

		  
		<OrdersContext.Provider value={
			{   
				ordersFiltered, orderStartDateTime, setOrderStartDateTime, orderEndDateTime, setOrderEndDateTime, orderCache, setOrderCache,
			
			}}>
			{children}
		</OrdersContext.Provider>
  ); 
	
};


export { OrdersContext, OrdersProvider };

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved
//

import '../styles/App.css';
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD, COLOR_BRONZE, COLOR_SILVER, TEAM_COLOR } from '../data/conectereConfigData';

import React, { useEffect, useState, useContext, useRef } from "react";
import { generateClient } from 'aws-amplify/api';   // Amplify V6

//Our Components
import ChallengeDataEditor from "../Components/challengeDataEditor/challengeDataEditor";

//Utils
import { fetchConectivity, fetchLeaderboardSpotsByCustomer, fetchLeaderboardSpotsByLeaderboard, fetchLeaderboardsByCustomer, fetchLeaderboardsByCustomerShallow, } from "../utils/databaseUtils";
import { selectLeaderboardsToCache } from "../utils/challengeUtils";
import moment from 'moment-timezone';

//User Authentication context
import { AuthContext } from './authContext';
import { ConectivityContext } from '../../shared/context/conectivitiesContext';

//Queries and Mutations


//Subscriptions
// https://docs.amplify.aws/lib/graphqlapi/subscribe-data/q/platform/js/
import { onCreateCustomerLeaderBoardByCustomer, onDeleteCustomerLeaderBoardByCustomer, onUpdateCustomerLeaderBoardByCustomer , 
			onCreateLeaderBoardSpotByCustomer, onDeleteLeaderBoardSpotByCustomer, onUpdateLeaderBoardSpotByCustomer } from "../graphql/subscriptions";

const API = generateClient(); //Amplify V6
const LeaderboardContext = React.createContext();
const LeaderboardProvider = ({ children }) => {

   //Context
	const {  authState, currentUser } = useContext(AuthContext);
	const { launchRulesLoaded, launchRules, launchRulesInvolvingThisUser, conectivitiesReadyForLaunch } = useContext(ConectivityContext);

	//Local State
	const [leaderboards, setLeaderboards] = useState([]);
	const [leaderboardSpotsCache, setLeaderboardSpotsCache] = useState([])
	const [challengesToDisplay, setChallengesToDisplay] =  useState([]);					//All challenge leaderboards in which this user is a participant	
	const [activeChallenges,setActiveChallenges] = useState([]);							//All challenges for this user that have started and not yet finished
	const [showChallengeDataEditor, setShowChallengeDataEditor] = useState(false);
	const [currentLeaderboardSpot,setCurrentLeaderboardSpot] = useState(null);
	const [currentLeaderboard, setCurrentLeaderboard] = useState(null); 					//Cache the specific LB record for the selected display
	
	// CUSTOMER LEADERBOARDS AND CHALLENGES
	useEffect(() => {
		fetchLeaderBoard();     //Get initial leaderboard upon page load
	}, [launchRulesLoaded]);

	//Load the customer leaderboard and the specific leaderboard spots, daily, weekly, etc.
	async function fetchLeaderBoard() { 
		//Notably, we hold off loading the leaderboard until the initial LRs have been loaded 
		if (authState == 'signedin' && currentUser && currentUser.customerID && launchRulesLoaded) {
			if (DEBUG_MODE >= 2) console.log("Fetching customer leaderboards");
			try {
				//Fetch all leaderboards for this particular customer - note the shallow query
				let leaderboards = await fetchLeaderboardsByCustomer(currentUser.customerID);    
				if (DEBUG_MODE) console.log("Fetched leaderboards for this customer shallow (without fetching all Spots)", leaderboards);        
				leaderboards =  selectLeaderboardsToCache({leaderboards, launchRules, currentUser});     
				if (DEBUG_MODE) console.log("Selected leaderboards to cache for this user", leaderboards);        
				setLeaderboards(leaderboards);            
			} catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching leaderBoardSpots', err); }
		}
	 }
 
	 //LEADERBOARD SPOTS (data)
	 useEffect(() => {
		fetchLeaderboardSpots();    // Change to our set of leaderboard cache?  Fetch new set of spots for all LBs
	}, [leaderboards]);

	async function fetchLeaderboardSpots() {
		let tempSpots = [];
		if (leaderboards && leaderboards.length > 0) {
		for (const lb of leaderboards) {
			const leaderBoardSpots = await fetchLeaderboardSpotsByLeaderboard(lb.id);
			if (leaderBoardSpots && leaderBoardSpots.length > 0) tempSpots = tempSpots.concat(leaderBoardSpots);
			if (DEBUG_MODE > 2) console.log("Fetched spots for lb", lb, leaderBoardSpots);   
		}   
		setLeaderboardSpotsCache(tempSpots);
		if (DEBUG_MODE > 2) console.log("Updated LB Spot cache", tempSpots);   
		}
	}
	
	//Respond to changes in leaderboards
	useEffect(() => {
		updateLeaderboardData();
	}, [conectivitiesReadyForLaunch, launchRulesInvolvingThisUser, leaderboards]);		

	async function updateLeaderboardData() {
		if (!launchRulesInvolvingThisUser || launchRulesInvolvingThisUser.length === 0 || !conectivitiesReadyForLaunch || conectivitiesReadyForLaunch.length ===0 || !leaderboards || leaderboards.length === 0) return;
		let challengeLBs = (leaderboards ? leaderboards.filter(lb => lb.isChallenge) : []);

		//For each leaderboard that is a challenge, inject conectivity and LR info
		if (challengeLBs && challengeLBs.length > 0) {

			for (let index=0; index < challengeLBs.length; index++) {
				let relatedLR, relatedCon;
				relatedLR = launchRulesInvolvingThisUser.find(lr => lr.id === challengeLBs[index].launchRuleID);
				// if (DEBUG_MODE) console.log("LR related to this cached challenge lb", relatedLR);
				if (relatedLR) {
					relatedLR.challengeOptions = (relatedLR.options ? JSON.parse(relatedLR.options).challengeOptions : null);
					relatedCon = conectivitiesReadyForLaunch.find(con => con.id === relatedLR.conectivityID);
					//Did we find it?
					if (!relatedCon || !relatedCon.id) {
						//Fetch the conectivity since it may no longer be launchable (like a past special event)
						if (DEBUG_MODE > 1) console.log("Conectivity not found locally; fetching from database.  ID:", relatedLR.conectivityID);
						relatedCon = await fetchConectivity(relatedLR.conectivityID);
					}
					if (DEBUG_MODE > 2) console.log("Conectivity related to this cached challenge lb", relatedCon);
				}
				challengeLBs[index] =  {...challengeLBs[index], launchRule:relatedLR, conectivity:relatedCon};
			}; //End FOR

		} //End If Challenges found

		//Find the active challenges - 
		// - note, all day events and multi-day have an ending day of the day AFTER the last day, i.e., 00:00:00 in the TZ of the user that launched it
		const activeChallenges =  (!challengeLBs || challengeLBs.length === 0 ? [] : challengeLBs.filter(lb=> {
			if (moment(lb.startDateTime).isAfter(moment(),'day')) return false;	 //Not yet started
			if (moment(lb.endDateTime).isBefore(moment(),'day')) return false;	//Already finished
			if (lb.allDayEvent && moment(lb.endDateTime).isBefore(moment(),'minute')) return false; //end date/time of invitation is before today
			return true;
		}));
		 
		if (DEBUG_MODE) console.log("Constructed display records for Leaderboards that are challenges", challengeLBs);
		if (DEBUG_MODE) console.log("Identified active challenge-related Leaderboards", activeChallenges);
		setChallengesToDisplay(challengeLBs);  	//Select challenges for display
		setActiveChallenges(activeChallenges);
		
	}

	//Repond to changes to any Leaderboard SPOT - we do this to update our Challenge Data Editor display
	useEffect(() => {
		//Editor open?  If so, update the spot being viewed as it may have just changed via subscription.  This will in turn update the editor display
		if (showChallengeDataEditor && currentLeaderboardSpot && currentLeaderboardSpot.id) {
			const tempSpot = (leaderboardSpotsCache.find(spot => spot.id === currentLeaderboardSpot.id));
			if (tempSpot && tempSpot.id) {
				if (DEBUG_MODE) console.log("Spot cache change while user editing challenge data for spot.  Updated spot.", tempSpot, currentLeaderboardSpot);
				setCurrentLeaderboardSpot(tempSpot);     //update leaderboard spot being viewed
			} else {
				if (DEBUG_MODE) console.error("Error - leaderboard spot being viewed in challenge data editor no longer found in spot cache", currentLeaderboardSpot);
			}
		}
	}, [leaderboardSpotsCache]);


/*
	//Load the user's active challenges
	async function fetchMyChallenges() { 
		if (authState === 'signedin' && currentUser && currentUser.customerID) {
			if (DEBUG_MODE >= 2) console.log("Fetching user challenges");
			try {
				let tempChallenges = await getChallengeByCustomerByStatus({customerID:currentUser.customerID, challengeStatus:'ACTIVE'});

				//Reduce down to challenges for this user
				if (tempChallenges && tempChallenges.length > 0) {
					tempChallenges = selectChallengesToCache({challenges:tempChallenges, currentUser});                 
				}         
				setChallenges(tempChallenges);     
			} catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching challenges', err); }
		}
	 }
*/

	//
	//  REACT REFERENCES USED BY Subscriptions of THIS CONTEXT
	//

	const leaderboardsRef = useRef();  
	const leaderboardSpotsCacheRef = useRef();  
	// const challengesRef = useRef();  
	useEffect(() => {
		leaderboardsRef.current = leaderboards; 
		leaderboardSpotsCacheRef.current = leaderboardSpotsCache; 
		// challengesRef.current = challenges; 
	});  //Update on any state change  

	
	//CUSTOMER LEADERBOARDS SUBSCRIPTIONS
	const [createLeaderboardSubscriptionSetUp, setCreateLeaderboardSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!createLeaderboardSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for CREATE CustomerLeaderboard By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onCreateCustomerLeaderBoardByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => CREATE CustomerLeaderboard data message",messageData);
						if (messageData.data.onCreateCustomerLeaderBoardByCustomer) {

							var createdCustomerLeaderboard = messageData.data.onCreateCustomerLeaderBoardByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => CREATE CustomerLeaderboard data extracted from message",createdCustomerLeaderboard);
							const tempLeaderboard = [...leaderboardsRef.current];        //Make a copy so as to trigger state updates
														
							//Even though a create, does this record already exist in our local data?
							const indexToEdit = tempLeaderboard.findIndex(lb => lb.id === createdCustomerLeaderboard.id);
							if (indexToEdit > -1) {  
								if (DEBUG_MODE >= 3) console.log('Found an existing CustomerLeaderboard:', indexToEdit);
								tempLeaderboard[indexToEdit]=createdCustomerLeaderboard; //Update array
								if (DEBUG_MODE >= 2) console.log("Updated CustomerLeaderboard cache",  tempLeaderboard);
							 } else {
								tempLeaderboard.push(createdCustomerLeaderboard);        //add new item to client-side data for this page to the existing array.
								if (DEBUG_MODE >= 2) console.log("Added leaderboard to our cache ", tempLeaderboard);
							 }
							setLeaderboards(tempLeaderboard); //Update state data 
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Create CustomerLeaderboard subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setCreateLeaderboardSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setCreateLeaderboardSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Create CustomerLeaderboard");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Create CustomerLeaderboard subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!

const [updateLeaderboardSubscriptionSetUp, setUpdateLeaderboardSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!updateLeaderboardSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE CustomerLeaderboard By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onUpdateCustomerLeaderBoardByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => UPDATE CustomerLeaderboard data message",messageData);
						if (messageData.data.onUpdateCustomerLeaderBoardByCustomer) {

							var updatedCustomerLeaderboard = messageData.data.onUpdateCustomerLeaderBoardByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => UPDATE CustomerLeaderboard data extracted from message",updatedCustomerLeaderboard);

							const tempLeaderboard = [...leaderboardsRef.current];        //Make a copy so as to trigger state updates
														
							//Try to find existing local record
							const indexToEdit = tempLeaderboard.findIndex(lb => lb.id === updatedCustomerLeaderboard.id);
  
							//Edit the item in our local data to reflect the fully updated object
							if (indexToEdit > -1) {  
								if (DEBUG_MODE >= 3) console.log('Found leaderboard to update at index:', indexToEdit);
								tempLeaderboard[indexToEdit]=updatedCustomerLeaderboard; //Update array
								if (DEBUG_MODE >= 2) console.log("Updated leaderboard", tempLeaderboard);
							 } else {
								if (DEBUG_MODE >= 2) console.log("Updated leaderboard not found in cache; creating new leaderboard in local state data");
								tempLeaderboard.push(updatedCustomerLeaderboard);        
								if (DEBUG_MODE >= 2) console.log("Updated leaderboard cache ", tempLeaderboard);
							 }
							setLeaderboards(tempLeaderboard); //Update state data 
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Update CustomerLeaderboard subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setUpdateLeaderboardSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setUpdateLeaderboardSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Update CustomerLeaderboard");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Update CustomerLeaderboard subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!


const [deleteLeaderboardSubscriptionSetUp, setDeleteLeaderboardSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!deleteLeaderboardSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE CustomerLeaderboard By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onDeleteCustomerLeaderBoardByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => DELETE CustomerLeaderboard data message",messageData);
						if (messageData.data.onDeleteCustomerLeaderBoardByCustomer) {

							var deletedCustomerLeaderboard = messageData.data.onDeleteCustomerLeaderBoardByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => DELETE CustomerLeaderboard data extracted from message",deletedCustomerLeaderboard);
							var tempLeaderboards = leaderboardsRef.current.filter(element => element.id !== deletedCustomerLeaderboard.id);
							setLeaderboards(tempLeaderboards); //Delete state data 
							if (DEBUG_MODE >= 2) console.log("Leaderboards after DELETE LB", tempLeaderboards);
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Delete CustomerLeaderboard subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setDeleteLeaderboardSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setDeleteLeaderboardSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Delete CustomerLeaderboard");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Delete CustomerLeaderboard subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!


//
//
//  LEADERBOARDSPOTS SUBSCRIPTIONS
//
//
const [createLeaderboardSpotsSubscriptionSetUp, setCreateLeaderboardSpotsSubscription] = useState(false);  //record once we have established the web socket

const injectLeaderboardSpot = (spot) => {
	if (!spot) return;
	//Does this record already exist in our local data?
	const tempLeaderboardSpots = [...leaderboardSpotsCacheRef.current];        //Make a copy so as to trigger state updates
	const indexToEdit = tempLeaderboardSpots.findIndex(leaderboardSpot => leaderboardSpot.id === spot.id);
	if (indexToEdit > -1) {  
		if (DEBUG_MODE >= 2) console.log('Found an existing LeaderboardSpot:', indexToEdit);
		tempLeaderboardSpots[indexToEdit]=spot; //Update array
		if (DEBUG_MODE >= 1) console.log("Updated LeaderboardSpot cache with spot",  spot);
		} else {
		tempLeaderboardSpots.push(spot);        //add new item to client-side data for this page to the existing array.
		if (DEBUG_MODE >= 1) console.log("Added leaderboardSpot to our cache ", tempLeaderboardSpots);
		}
	setLeaderboardSpotsCache(tempLeaderboardSpots); //Update state data 
}

useEffect(() => {
	if (!createLeaderboardSpotsSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for CREATE LeaderboardSpot By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onCreateLeaderBoardSpotByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => CREATE LeaderboardSpot data message",messageData);
						if (messageData.data.onCreateLeaderBoardSpotByCustomer) {

							var createdLeaderboardSpot = messageData.data.onCreateLeaderBoardSpotByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => CREATE LeaderboardSpot data extracted from message",createdLeaderboardSpot);
							injectLeaderboardSpot(createdLeaderboardSpot);
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Create LeaderboardSpot subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setCreateLeaderboardSpotsSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setCreateLeaderboardSpotsSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Create LeaderboardSpot");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Create LeaderboardSpot subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!

const [updateLeaderboardSpotsSubscriptionSetUp, setUpdateLeaderboardSpotsSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!updateLeaderboardSpotsSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE LeaderboardSpot By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onUpdateLeaderBoardSpotByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 1) console.log("Received via App Sync => UPDATE LeaderboardSpot data message",messageData);
						if (messageData.data.onUpdateLeaderBoardSpotByCustomer) {

							var updatedLeaderboardSpot = messageData.data.onUpdateLeaderBoardSpotByCustomer;
							if (DEBUG_MODE >= 1) console.log("App Sync => UPDATE LeaderboardSpot data extracted from message",updatedLeaderboardSpot);
							injectLeaderboardSpot(updatedLeaderboardSpot);
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Update LeaderboardSpot subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setUpdateLeaderboardSpotsSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setUpdateLeaderboardSpotsSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Update LeaderboardSpot");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Update LeaderboardSpot subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!


const [deleteLeaderboardSpotsSubscriptionSetUp, setDeleteLeaderboardSpotsSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!deleteLeaderboardSpotsSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE LeaderboardSpot By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onDeleteLeaderBoardSpotByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => DELETE LeaderboardSpot data message",messageData);
						if (messageData.data.onDeleteLeaderBoardSpotByCustomer) {

							var deletedLeaderboardSpot = messageData.data.onDeleteLeaderBoardSpotByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => DELETE LeaderboardSpot data extracted from message",deletedLeaderboardSpot);
							var tempLeaderboardSpots = leaderboardSpotsCacheRef.current.filter(element => element.id !== deletedLeaderboardSpot.id);
							setLeaderboardSpotsCache(tempLeaderboardSpots); //Delete state data 
							if (DEBUG_MODE >= 2) console.log("LeaderboardSpots after DELETE spot", tempLeaderboardSpots);
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Delete LeaderboardSpot subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setDeleteLeaderboardSpotsSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setDeleteLeaderboardSpotsSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Delete LeaderboardSpot");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Delete LeaderboardSpot subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!

/* NOT YET NEEDED

//
//
//  CHALLENGES SUBSCRIPTIONS
//
//

const [createChallengesSubscriptionSetUp, setCreateChallengesSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!createChallengesSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for CREATE Challenge By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onCreateChallengeByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => CREATE Challenge data message",messageData);
						if (messageData.data.onCreateChallengeByCustomer) {

							var createdChallenge = messageData.data.onCreateChallengeByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => CREATE Challenge data extracted from message",createdChallenge);
							const tempChallenges = [...challengesRef.current];        //Make a copy so as to trigger state updates
														
							//Even though a create, does this record already exist in our local data?
							const indexToEdit = tempChallenges.findIndex(challenge => challenge.id === createdChallenge.id);
							if (indexToEdit > -1) {  
								if (DEBUG_MODE >= 3) console.log('Found an existing Challenge:', indexToEdit);
								tempChallenges[indexToEdit]=createdChallenge; //Update array
								if (DEBUG_MODE >= 2) console.log("Updated Challenge cache",  tempChallenges);
							 } else {
								tempChallenges.push(createdChallenge);        //add new item to client-side data for this page to the existing array.
								if (DEBUG_MODE >= 2) console.log("Added challenge to our cache ", tempChallenges);
							 }
							setChallenges(tempChallenges); //Update state data 
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Create Challenge subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setCreateChallengesSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setCreateChallengesSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Create Challenge");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Create Challenge subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!

const [updateChallengesSubscriptionSetUp, setUpdateChallengesSubscription] = useState(false);  //record once we have established the web socket

useEffect(() => {
	if (!updateChallengesSubscriptionSetUp && authState === 'signedin'  && currentUser) {
		if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE Challenge By customer", currentUser.customerID);
		try {
			 const subscription = API.graphql({
				query:onUpdateChallengeByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Received via App Sync => UPDATE Challenge data message",messageData);
						if (messageData.data.onUpdateChallengeByCustomer) {

							var updatedChallenge = messageData.data.onUpdateChallengeByCustomer;
							if (DEBUG_MODE >= 2) console.log("App Sync => UPDATE Challenge data extracted from message",updatedChallenge);

							const tempChallenges = [...challengesRef.current];        //Make a copy so as to trigger state updates
														
							//Try to find existing local record
							const indexToEdit = tempChallenges.findIndex(challenge => challenge.id === updatedChallenge.id);
  
							//Edit the item in our local data to reflect the fully updated object
							if (indexToEdit > -1) {  
								if (DEBUG_MODE >= 3) console.log('Found challenge to update at index:', indexToEdit);
								tempChallenges[indexToEdit]=updatedChallenge; //Update array
								if (DEBUG_MODE >= 2) console.log("Updated challenge", tempChallenges);
							 } else {
								if (DEBUG_MODE >= 2) console.log("Updated challenge not found in cache; creating new challenge in local state data");
								tempChallenges.push(updatedChallenge);        
								if (DEBUG_MODE >= 2) console.log("Updated challenge cache ", tempChallenges);
							 }
							setChallenges(tempChallenges); //Update state data 
						}                                
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Update Challenge subscription message",messageData);
					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setUpdateChallengesSubscription(true);

		   return () => {
				subscription.unsubscribe();
				setUpdateChallengesSubscription(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for Update Challenge");
			};                

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error processing Update Challenge subscription message",err);

		}
	}   
}, [authState]);  //Trigger this whenever authState changes state!
*/


	/*
		EDITOR HANDLERS
	*/
	const closeChallengeEditorCallback = ({result, message}) => {
		setShowChallengeDataEditor(false);

		if (result !== "CANCELED" && message) {
			 if (result) setModalGeneralTitle("Success!!");  
			 else setModalGeneralTitle("Ooops!");
			 setModalGeneralMessage(message);
			 setShowModalGeneral(true);
		}
		if (DEBUG_MODE >= 2) console.log("Closed Challenge Data Editor", result, message);
  };

	//Pop up editor once template selected
	const handleLeaderboardCardSelected = (leaderboardSpot) => {
		if (DEBUG_MODE) console.log("User Leaderboard Spot Selected.  Handler invoked.", leaderboardSpot);
		//Pull the LB from our challenges, which have been modified to include the LR and the conectivity
		const currentLeaderboard = challengesToDisplay.find(lb => lb.id === leaderboardSpot.customerLeaderBoardID);
		if (currentLeaderboard) {
			setCurrentLeaderboard(currentLeaderboard);
			setShowChallengeDataEditor(true);      			//pop-up editor to allow user to submit / edit challenge data
			setCurrentLeaderboardSpot(leaderboardSpot);     //record leaderboard slot
			if (DEBUG_MODE) console.log("User Leaderboard Spot Selected.  Handler invoked.  Showing editor.", leaderboardSpot, currentLeaderboard);
		} else console.error("Error - could not find leaderboard in set of cached leaderboards", leaderboardSpot);
  };


	//return the Notifications Context provider
	  return (
		<>
			<div className='leaderboardContext'>
				<ChallengeDataEditor showEditor={showChallengeDataEditor} leaderboard={currentLeaderboard}  leaderboardSpot={currentLeaderboardSpot} closeEditorCallback={closeChallengeEditorCallback}  injectLeaderboardSpot={injectLeaderboardSpot}/>
			</div>
			
			<LeaderboardContext.Provider value={
			{   
				//leaderboard data
				leaderboards, leaderboardSpotsCache,	

				//Challenges for this user			
				challengesToDisplay,	activeChallenges,						

				//access to leaderboard Editor
				setShowChallengeDataEditor, setCurrentLeaderboard, setCurrentLeaderboardSpot, 
				currentLeaderboard, currentLeaderboardSpot, handleLeaderboardCardSelected,
			}}>
			  {children}
			</LeaderboardContext.Provider>
			
		</>
	  ); 
	
};


export { LeaderboardContext, LeaderboardProvider };


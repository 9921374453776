import React, { useContext } from 'react';

// style
import './LogoutModal.css';

// icons
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { AuthContext } from '../../context/authContext';
import { DEBUG_MODE } from '../../data/conectereConfigData';

export const LogoutModal = React.memo(({ showLogoutModal, handleClose }) => {
  const { ccAuthLogoutUser } = useContext(AuthContext);

  const handleLogoutClick = async () => {
    try {
      handleClose();
      await ccAuthLogoutUser(); //ccall our auth context to signout the user
      if (DEBUG_MODE >= 2) console.log('User signed out!');
    } catch (error) {
      if (DEBUG_MODE >= 2) console.log('error signing out: ', error);
    }
  };

  return (
    <div className='logoutModalContainer' hidden={!showLogoutModal}>
      <div
        hidden={!showLogoutModal}
        className='ModalBgContainer'
        onClick={handleClose}
        onScroll={(e) => e.stopPropagation()}
      />
      <div className='logoutModalOuterCard boxShadow navbarRightContainerSpacing'>
        <CloseIcon
          className='TextStyle6 pointer dust-grey connectivityModalCloseIcon'
          onClick={handleClose}
        />
        <div className='ContainerNoHeightCenter'>
          <ErrorIcon className='TextStyle7 dust-grey' />
        </div>
        <div className='dust-grey TextStyle3'>
          Are you sure you want to log out?
        </div>
        <div className='ContainerNoHeightCenter buttonContainer animatedRingsInnerWrapper noWrap'>
          <button
            className='buttonStyle1 buttonStyle1Red imageEditModalStyle'
            style={{ borderRadius: '0.6vw' }}
            onClick={handleLogoutClick}
          >
            Yes, I'm Sure
          </button>
          <button
            className='very-light-grey-border grey-text buttonStyle1 buttonStyle1HeaderBlue imageEditModalStyle white-background noBoxShadow'
            style={{ borderRadius: '0.6vw' }}
            onClick={handleClose}
          >
            No, Cancel
          </button>
        </div>
      </div>
    </div>
  );
});

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import React from 'react';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_GOLD } from '../../data/conectereConfigData';

//Utils
import { displayCommunityTextInline } from "../../utils/generalUtils";


import { Divider } from "../../utils/generalUtils";

//React component
const DisplayCardConectivityPortfolios = React.memo(({analytics}) => {

    if (!analytics) return null;
    
    const title="CONECTIVITIES"; 
    
    const footer="";

   return (

        <div className="dashboardCard">

            <div className="ContainerVerticalCenterFullWidth">
                <div className="TextStyle4 headerBlue heavy"> {title} </div>
                <Divider width={80} />
            </div>


            <div className="dashboardCardTextRow">
                <div className="ContainerVerticalCenter" style={{minWidth:"0px"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:BALANCE_COLOR}}>
                        {analytics.conectivitiesTotal}
                    </div>
                    <div className="dashboardCardText">Total</div>
                </div>
                <div className="ContainerVerticalCenter"style={{minWidth:"0px"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:TEAM_COLOR}}>
                        {analytics.globalTotal}
                    </div>
                     <div className="dashboardCardText">Conectere</div>
                </div>                   
                <div className="ContainerVerticalCenter" style={{minWidth:"0px"}}>
                    <div className="dashboardScoreCircle" style={{borderColor:SOCIAL_COLOR}}>
                        { analytics.privateTotal}
                    </div>
                    <div className="dashboardCardText">Private</div>
                </div>    
            </div >
            
            <div className="dashboardCardTextRow" >

                <div className="ContainerVerticalCenter"style={{minWidth:"0px"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:GROWTH_COLOR}}>
                        { analytics.installedFromCommunityTotal}
                    </div>
                     <div className="dashboardCardText">{displayCommunityTextInline({ useDownload:true})}</div>
                </div>  
                
                <div className="ContainerVerticalCenter"style={{minWidth:"0px"}} >
                    <div className="dashboardScoreCircle" style={{borderColor:GROWTH_COLOR}}>
                        { analytics.sharedToCommunityTotal}
                    </div>
                     <div className="dashboardCardText">{displayCommunityTextInline({ useUpload:true})}</div>
                </div> 
 
            </div >           
            <div className="ContainerNoHeightCenter dashboardCardTextFooter">{footer} </div>
        </div>
        
    );        

});


export default DisplayCardConectivityPortfolios;



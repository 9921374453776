//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//


import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';
import React, { useEffect, useState, useContext, useRef } from "react";
import { generateClient } from 'aws-amplify/api';   // Amplify V6

//Context
import { AuthContext } from './authContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context

//Queries and Mutations
import { listInvoices, getInvoicesByCustomerByDate, } from '../graphql/queries';
import { createInvoice, updateInvoice, deleteInvoice, createInvoiceLineItems, deleteInvoiceLineItems, updateCustomerBilling, updateOrder }  from '../graphql/mutations';

//Subscriptions
import { onCreateInvoiceByCustomer, onUpdateInvoiceByCustomer, onDeleteInvoiceByCustomer,  } from "../graphql/subscriptions";

//Utils
import { queryDataTableWithPagination, invokeAPI} from "../utils/databaseUtils";
import { compareByCreatedAtReverseChronological } from "../utils/generalUtils";
import {NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING, ONE_MONTH_AGO, ONE_MONTH_AGO_STRING} from "../utils/dateTimeUtils";

import moment from 'moment';

const API = generateClient(); //Amplify v6
const InvoicesContext = React.createContext();
const InvoicesProvider = ({ children }) => {

   // Authentication context
    const {
           authState, currentUser, isSuperAdmin, isAdmin, permissionInvoices, permissionBilling,
    } = useContext(AuthContext);

   // Customer context
    const {
            selectedCustomerOptions 
    } = useContext(CustomerContext);  


    //Invoice cache
    const [invoiceCache, setInvoiceCache] = useState([]);
    const [invoiceStartDateTime, setInvoiceStartDateTime] = useState(ONE_YEAR_AGO());               
    const [invoiceEndDateTime, setInvoiceEndDateTime] = useState(NOW());               
    const [invoiceOldestStartDateTimeFetched, setInvoiceOldestStartDateTimeFetched] = useState(ONE_YEAR_AGO());               
    const [invoiceCacheInitialized, setInvoiceCacheInitialized] = useState(false);
    const [invoiceCountFilteredDateRange, setInvoiceCountFilteredDateRange] = useState(0)
    const [invoicesFiltered, setInvoicesFiltered] = useState([]);


    //Get invoice data upon opening this page, return an array of existing invoices, if any
    useEffect(() => {
        updateInvoiceCache();  
    }, [authState, invoiceStartDateTime]);
    
    //let's write the function to grab the invoice data
    async function updateInvoiceCache() {
        
        if (authState !== "signedin"  || !currentUser) return;

        //User authorized for Invoices?
        if (!isSuperAdmin && !isAdmin && !permissionInvoices && !permissionBilling) return;

        if (!invoiceStartDateTime) {
            // if (DEBUG_MODE >= 2) console.log("ORDER CACHE: NULL ORDER START DATE ");
           return;
        }

        //This check catches changes to the date that are not yet valid dates
        if (!invoiceStartDateTime.toISOString()) {
            // if (DEBUG_MODE >= 2) console.log("ORDER CACHE: NULL ORDER START DATE STRING");
           return;
        }

        //Set our start of search period
        var tempInvoiceStartDate = invoiceStartDateTime.clone().startOf('day');

        //Start date out of range, i.e.,in the future
        if (tempInvoiceStartDate.clone().isAfter(NOW())) {
            // if (DEBUG_MODE >= 2) console.log("ORDER CACHE: ORDER START DATE IN THE FUTURE; SKIPPING");
            return;
        }

 
        //Start date out of range, i.e., too long ago?
        if (tempInvoiceStartDate.clone().isBefore(NOW().subtract(1,'years').startOf('day'))) {
            // if (DEBUG_MODE >= 2) console.log("ORDER CACHE: ORDER START DATE OUT OF BOUNDS; SETTING TO 1 YEAR AGO");
            tempInvoiceStartDate = ONE_YEAR_AGO().startOf('day');
        }
 
         //Have we already fetched at least the requested invoices for this particular customer?
        //Check to see if we have fetched invoice prior to OR equal to the same day, but only if not a forced reload due to a new wallet or auth change
        if (invoiceCacheInitialized) {
            if (invoiceOldestStartDateTimeFetched.clone().startOf('day').isBefore(tempInvoiceStartDate.clone().startOf('day')) ||
                    invoiceOldestStartDateTimeFetched.clone().startOf('day').isSame(tempInvoiceStartDate.clone().startOf('day'))) {
                // if (DEBUG_MODE >= 2) console.log("ORDER CACHE: ALREADY FETCHED SUFFICIENT ORDERS ");
                return;
            }
        }

        // if (DEBUG_MODE >= 2) console.log("ORDER CACHE:  invoiceOldestStartDateTimeFetched=" + invoiceOldestStartDateTimeFetched.toISOString() );
        // if (DEBUG_MODE >= 2) console.log("ORDER CACHE:  invoiceStartDateTime=" + tempInvoiceStartDate.toISOString() );


        try {

             // gather ccInvoice data from backend
           
            var tempInvoices = [];


            if (isSuperAdmin) {
                // gather ALL invoice data from backend

                 const queryParams = {
                    limit: 5000,
                };
 
                tempInvoices =  await queryDataTableWithPagination(listInvoices, "listInvoices", queryParams);
 
                //  if (DEBUG_MODE >= 2) console.log("INVOICES CACHE: Fetched ALL invoices for ALL customers " , tempInvoices);
               
            } else {

                // gather invoice data for just the customer
              
                 const queryParams = {
                    customerID: currentUser.customerID,                                             //Primary Key
                    createdAt: {between: [ tempInvoiceStartDate.toISOString(), NOW_STRING() ]},     //Secondary Key
                };
              
                tempInvoices =  await queryDataTableWithPagination(getInvoicesByCustomerByDate, "getInvoicesByCustomerByDate", queryParams);
                // if (DEBUG_MODE >= 2) console.log("Fetched invoices for customer: " + currentUser.customerID + " back to " + tempInvoiceStartDate.toISOString(), tempInvoices);
              
            }
 
            //Sort invoices by date
            tempInvoices.sort(compareByCreatedAtReverseChronological);
            
            // if (DEBUG_MODE >= 2) console.log("Filtered and sorted invoices", tempInvoices);
            
            //Save the invoices to our cache and update cache params
            if (tempInvoices) {
                setInvoiceCacheInitialized(true); 
                setInvoiceCache([...tempInvoices]);
                setInvoiceCountFilteredDateRange(tempInvoices.length);
                setInvoiceOldestStartDateTimeFetched(tempInvoiceStartDate); 
                
                // if (DEBUG_MODE >= 2) console.log("Invoice cache initialized");
            }

        } catch (err) { if (DEBUG_MODE >= 2) console.log('ORDER CACHE: error fetching  data', err); }            
}


    // Respond to changes in the invoice cache or the time range 
    
    useEffect(() => {
        updateFilteredInvoices();
    }, [invoiceCache, invoiceStartDateTime, invoiceEndDateTime]);


    async function updateFilteredInvoices() {

        if (authState != "signedin" || !invoiceCacheInitialized) return;

        //These checks catch improper dates that may occur WHILE the user is typing
        if (!invoiceStartDateTime || !invoiceEndDateTime) {
            // if (DEBUG_MODE >= 2) console.log("ORDER CACHE FILTER: NULL ORDER START DATE ");
           return;
        }

        //This check catches changes to the date that are not yet valid dates
        if (!invoiceStartDateTime.toISOString() || !invoiceStartDateTime.toISOString()) {
            // if (DEBUG_MODE >= 2) console.log("ORDER CACHE FILTER: NULL ORDER START DATE STRING");
           return;
        }

        const startOfSearchPeriod = invoiceStartDateTime.clone().startOf('day');
        const endOfSearchPeriod = invoiceEndDateTime.clone().endOf('day');
        
        // if (DEBUG_MODE >= 2) console.log("Search period", startOfSearchPeriod.toISOString(), endOfSearchPeriod.toISOString());
        
        //Filter invoices based on user selections, invoice date  and any category or invoice type filter selected
        var tempInvoices =  invoiceCache.filter(invoice => 
                (moment(invoice.createdAt).isAfter(startOfSearchPeriod)) &&
                (moment(invoice.createdAt).isBefore(endOfSearchPeriod)));

        // if (DEBUG_MODE >= 2) console.log("UPDATED Filtered invoices", tempInvoices);

        setInvoicesFiltered([...tempInvoices]);
    }
       
 
    //Set up subscriptions to receive user invoices upon creation and updates for this user
    // Note, we need to make use either Functional State Data Updates or use Ref() to get access to current values of state data
    // since this UseEffect call is called upon mount (Auth change)
    // https://reactjs.org/docs/hooks-reference.html#functional-updates
    //https://stackoverflow.com/questions/69285519/access-latest-value-of-state-in-aws-amplify-subscriptions-react

    const invoiceCacheRef = useRef();  //Defining a React Reference so that we can use the current state data during this useEffect call

    useEffect(() => {
      invoiceCacheRef.current = invoiceCache;           
    });   //Update on every state change


//
// Subscriptions for Invoices
//

 
    //Functions and state data for real-time updates
    // const [invoiceCreateSubscriptionSetUp, setInvoiceCreateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
    const [invoiceUpdateSubscriptionSetUp, setInvoiceUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
    const [invoiceDeleteSubscriptionSetUp, setInvoiceDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

/* NOTE - THIS IS HANDLED VIA SUBSCRIPTIONS TO UPDATE INVOICE RECORDS, WHICH IS TRIGGERED AT THE END OF THE CREATE PROCESS
    useEffect(() => {
        
        //User authorized for Invoices?

        if (!invoiceCreateSubscriptionSetUp && authState == 'signedin'  && currentUser && currentUser.customerID) {

            if (isSuperAdmin || isAdmin || permissionInvoices || permissionBilling) {
            
               let variables;
               if (isSuperAdmin) variables = { };
               else  variables = { customerID: currentUser.customerID };

               if (DEBUG_MODE >= 2) console.log("Setting up subscription for invoices created for customer", variables);
                
               const subscription = API.graphql({
                    query:onCreateInvoiceByCustomer,
                    variables: variables,
                })
                .subscribe ({
                    next: messageData => {
                        try {
                            if (DEBUG_MODE >= 2) console.log("New invoice data received via subscription",messageData);
                            if (messageData.data.onCreateInvoiceByCustomer !== null) {
                                
                                const newInvoice = messageData.data.onCreateInvoiceByCustomer;
                                
                                if (DEBUG_MODE >= 2) console.log("New invoice extracted from message",newInvoice);
    
                                const tempInvoices = [...invoiceCacheRef.current];   //Get our state data for existing invoices
                                if (DEBUG_MODE >= 2) console.log("Current invoices",tempInvoices);
                                tempInvoices.push(newInvoice);    //Add the new invoice
                                
                                //Sort the Invoices      
                                tempInvoices.sort(compareByCreatedAtReverseChronological);
    
                                //Update system-wide state data with the new invoices
                                setInvoiceCache(tempInvoices);
                                if (DEBUG_MODE >= 2) console.log("Invoices after CREATE from Subscription", tempInvoices);
    
                             }
                        } catch (err) {
                            if (DEBUG_MODE >= 2) console.log("Error processing invoice create subscription message",messageData);
    
                        }
                    },
                    error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)};
                });
                
                setInvoiceCreateSubscriptionSetUp(true);
                
               return () => {
                    subscription.unsubscribe();
                    setInvoiceCreateSubscriptionSetUp(false);
                    if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE PRODUCTS");
                };
            }   
 
        }
    },[authState]);                     //Call function when a change to authState occurs
      
*/

    
    useEffect(() => {
        if (!invoiceUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {

            if (isSuperAdmin || isAdmin || permissionInvoices || permissionBilling) {
            

              let variables;
               if (isSuperAdmin) variables = { };
               else  variables = { customerID: currentUser.customerID };

            //   if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for invoices", variables);
                
               const subscription = API.graphql({
                    query:onUpdateInvoiceByCustomer,
                    variables: variables,
                })
                .subscribe ({
                    next: messageData => {
                        try {
                            // if (DEBUG_MODE >= 2) console.log("New invoice UPDATE data received via subscription",messageData);
                            if (messageData.data.onUpdateInvoiceByCustomer !== null) {
                                
                                const updatedInvoice = messageData.data.onUpdateInvoiceByCustomer;
                                
                                // if (DEBUG_MODE >= 2) console.log("Invoice update extracted from message",updatedInvoice);
                                
                                const tempInvoices = [...invoiceCacheRef.current];   //Get our state data for existing invoices
    
                                 if (DEBUG_MODE >= 2) console.log("Current invoices",tempInvoices);
                                 
                                 const index = tempInvoices.findIndex(invoice => invoice.id == updatedInvoice.id);
                                 
                                 if (index > -1) {
    
                                    //Update system-wide state data with the received object
                                    //Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
                                    tempInvoices[index] = updatedInvoice;    //Replace the existing invoice with the updated one just received
                                    setInvoiceCache(tempInvoices);
                                    // if (DEBUG_MODE >= 2) console.log("Invoices after UPDATE from Subscription", tempInvoices);
    
    
                                 } else {
                                     if (DEBUG_MODE >= 2) console.log("No matching invoice found; creating a new one in our local state data", updatedInvoice, tempInvoices);
                                    tempInvoices.push(updatedInvoice);    //Add the new invoice
                                    
                                    //Sort the Invoices      
                                    tempInvoices.sort(compareByCreatedAtReverseChronological);
        
                                    //Update system-wide state data with the new invoices
                                    setInvoiceCache(tempInvoices);
                                    // if (DEBUG_MODE >= 2) console.log("Invoices after CREATE from Subscription", tempInvoices);
                                 }
                             }
                        } catch (err) {
                            if (DEBUG_MODE >= 2) console.log("Error processing invoice update subscription message",messageData);
    
                        }
                    },
                    error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
                });
                
                setInvoiceUpdateSubscriptionSetUp(true);
                
               return () => {
                    subscription.unsubscribe();
                    setInvoiceUpdateSubscriptionSetUp(false);
                    // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE PRODUCTS");
                };
            }
 
        }
    },[authState]);                     //Call function when a change to authState occurs
    

   useEffect(() => {
        if (!invoiceDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {

           if (isSuperAdmin || isAdmin || permissionInvoices || permissionBilling) {
            
              let variables;
               if (isSuperAdmin) variables = { };
               else  variables = { customerID: currentUser.customerID };
              if (DEBUG_MODE >= 2) console.log("Setting up subscription for deleted invoices", variables);

               const subscription = API.graphql({
                    query:onDeleteInvoiceByCustomer,
                    variables: variables,
                })
                .subscribe ({
                    next: messageData => {
                        try {
                            if (DEBUG_MODE >= 2) console.log("New invoiceByCustomer data received via subscription",messageData);
                            if (messageData.data.onDeleteInvoiceByCustomer !== null) {
                                
                                const deletedInvoiceByCustomer = {... messageData.data.onDeleteInvoiceByCustomer };
                                if (DEBUG_MODE >= 2) console.log("Deleted invoice extracted from message",deletedInvoiceByCustomer);
                                
                                 //Remove the matching invoice from local state. 
                                var tempInvoices = invoiceCacheRef.current.filter(element => element.id != deletedInvoiceByCustomer.id);
                                setInvoiceCache(tempInvoices); //Delete state data with newly sorted list  
                                if (DEBUG_MODE >= 2) console.log("Deleted invoice", tempInvoices);
                                
                             }
                        } catch (err) {
                            if (DEBUG_MODE >= 2) console.log("Error processing  DELETE invoice subscription message",messageData);
    
                        }
                    },
                    error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
                });
                
                setInvoiceDeleteSubscriptionSetUp(true);
               return () => {
                    subscription.unsubscribe();
                    setInvoiceDeleteSubscriptionSetUp(false);
                    if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE invoice by company");
                };
           }
 
        }
    },[authState]);                     



//
// CONECTIVITY DB UTILITIES THAT UTILIZE AUTH CONTEXT PERMISSIONS
//


    async function invokeCreateInvoice ({ customerBillingID, invoiceNumber, customer, servicePlan, billingPeriodStartDateString, billingPeriodEndingDateString, invoice,  lineItems, invoiceStatusSelection, customerSelection}) {
    
        //Safety check
        if (!customer || !invoice || !invoiceStatusSelection || !customerSelection) return false;
        
        var successFlag = true;  

        try {
            
            const tempInvoiceToAdd = {
                customerID:customer.id,		                  // Identifies the customer to which this order belongs
                customerName: customer.name,                   // Added this so we don't need to pull the entire Customer object
                invoiceNumber: invoiceNumber,
                billingPeriodStartDate: billingPeriodStartDateString,      //Service period start date
                billingPeriodEndingDate: billingPeriodEndingDateString,    //Service period end date
                description: invoice.description,
                charges: invoice.charges,                      // Amount charged the customer for service, items, etc.
                shipping: invoice.shipping,                    // Shipping, if any
                credits: invoice.credits,                      // Any credits applied
                tax: invoice.tax,                              // Taxes
                discount: invoice.discount,                    // Any discount applied to the entire invoice
                total:invoice.total,                           // Bottom line
                activeUsersForBillingPeriod: customer.activeEmployees,
                serviceForBillingPeriod: servicePlan,
                invoiceStatus: invoiceStatusSelection.label,
            };
            
            //Create record
            const tempInsertedInvoice = await invokeAPI(createInvoice,'createInvoice',tempInvoiceToAdd);
    
           for (var i=0; i < lineItems.length; i++) {
    
                const tempInvoiceLineItemToAdd = { ... lineItems[i], invoiceID:tempInsertedInvoice.id};
                if (DEBUG_MODE >= 2) console.log("Writing new line item",tempInvoiceLineItemToAdd);
                
                //Create record
                const tempInsertedInvoiceLineItem = await invokeAPI(createInvoiceLineItems, 'createInvoiceLineItems', tempInvoiceLineItemToAdd);
                if (DEBUG_MODE >= 2) console.log('Successfully wrote new invoice line item', tempInsertedInvoiceLineItem);
                
                //Mark the item as billed, if charging for an order
                if (lineItems[i].lineItemType === "ORDER_FILLED" && lineItems[i].orderID) {
                    const tempUpdatedOrder = await invokeAPI(updateOrder, 'updateOrder', {id: tempInsertedInvoiceLineItem.orderID, orderStatus:'INVOICED'});
                    if (DEBUG_MODE >= 2) console.log('Successfully marked the order as INVOICED', tempUpdatedOrder);
                }   
            }            

            //Update the invoice to trigger an appsync push with all our line item data 
            //Note - this will also trigger our Lamda function to send an invoice so be sure only to do 1 update in the creation process
            const tempUpdatedInvoice = await invokeAPI(updateInvoice, 'updateInvoice', {id:tempInsertedInvoice.id, customerID:customer.id});
            if (DEBUG_MODE >= 2) console.log('Successfully wrote new invoice', tempUpdatedInvoice);

            //Update the custumer's next Invoice Number
            const tempUpdatedCustomerBilling = await invokeAPI(updateCustomerBilling, 'updateCustomerBilling', {id:customerBillingID, nextInvoiceNumber:invoiceNumber+1});
            if (DEBUG_MODE >= 2) console.log('Successfully updated next invoice number', tempUpdatedCustomerBilling);
     
                 
        } catch (err) {
            successFlag = false;
            if (DEBUG_MODE >= 2) console.log('error creating invoice:', err);
        }

        return successFlag;
    
    }
    
    async function invokeUpdateInvoice ({invoice, invoiceStatusSelection,  customerSelection}) {
    
    
        var successFlag = true;  

        
        return successFlag;
    
    }
    
    async function invokeDeleteInvoice ({invoice}) {
    
        if (DEBUG_MODE >= 2) console.log("Deleting Invoice", invoice);
        
        if (!invoice) return false;
        
        var successFlag = true;  

        try {
            
            if (invoice.lineItems && invoice.lineItems.items) {
                //First, process each line item on the invoice  
               for (var i=0; i < invoice.lineItems.items.length; i++) {
        
                     //If the line item is an employee reward, mark the reweard if UNBILLED
                    if (invoice.lineItems.items[i].lineItemType === "ORDER_FILLED" && invoice.lineItems.items[i].orderID) {
                        const tempUpdatedOrder = await invokeAPI(updateOrder, 'updateOrder',  {id: invoice.lineItems.items[i].orderID, orderStatus:'FILLED'});
                        if (DEBUG_MODE >= 2) console.log('Successfully reverted the order from INVOICED to FILLED', tempUpdatedOrder);
                    }     
                    
                    //Delete the line item record
                    const tempDeletedInvoiceLineItem = await invokeAPI(deleteInvoiceLineItems, 'deleteInvoiceLineItems', {id: invoice.lineItems.items[i].id});
                    if (DEBUG_MODE >= 2) console.log('Successfully deleted invoice line item', tempDeletedInvoiceLineItem);
                }    
            }
            
            //Delete the invoice record
            const tempDeletedInvoice = await invokeAPI(deleteInvoice, 'deleteInvoice',  {id:invoice.id});
            if (DEBUG_MODE >= 2) console.log('Successfully deleted the invoice', tempDeletedInvoice);
 
 /*     FOUND OUT HOW TO SUBSCRIBE W/O SPECIFYING CUSTOMER      
           //Since SuperAdmin and not subscribing to all customers, immediately update local state data
            var tempInvoices = invoicesFiltered.filter(i => i.id != invoice.id);
            setInvoicesFiltered(tempInvoices);  
            if (DEBUG_MODE >= 2) console.log("New invoices after delete", tempInvoices);
*/
        } catch (err) {
            successFlag = false;
            if (DEBUG_MODE >= 2) console.log('error deleting invoice:', err);
        }
        
        return successFlag;
    
    }       
    
    //return the Invoices Context provider
    return (

          
        <InvoicesContext.Provider value={
            {   
                invoicesFiltered, invoiceStartDateTime, setInvoiceStartDateTime, invoiceEndDateTime, setInvoiceEndDateTime, 
                invokeCreateInvoice, invokeUpdateInvoice, invokeDeleteInvoice,
            }}>
            {children}
        </InvoicesContext.Provider>
  ); 
    
};


export { InvoicesContext, InvoicesProvider };

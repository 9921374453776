//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//React & Amplify
import React from 'react';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';
import {  truncateStringWithDots} from "./generalUtils";
import  { API, graphqlOperation } from 'aws-amplify';
import {  sendOrderEmail } from '../graphql/mutations';
import moment from 'moment';
import { NOW } from "./dateTimeUtils";
import  {   DAVY_GREY, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, 
            COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, COLOR_GOLD, COLOR_SILVER, COLOR_BRONZE, 
            COLOR_ALERT_OPAQUE, COLOR_WARNING_HIGH_OPAQUE, COLOR_WARNING_LOW_OPAQUE, COLOR_GOOD_OPAQUE, 
            COLOR_ALERT, COLOR_WARNING_HIGH, COLOR_WARNING_LOW, COLOR_GOOD } from '../data/conectereConfigData';


//Functions for sorting Conectivities based on last name & first name
export function compareProductsByTitle(a, b) {

    if (a.title.toUpperCase() > b.title.toUpperCase()) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return 1;
        }
    if (a.title.toUpperCase() < b.title.toUpperCase()) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return -1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}
    
    
//Functions for sorting ProductNumber based Most Favorite 
export function compareProductsMostFavorite(a, b) {

    if (a.purchases > b.purchases) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return -1;
        }
    if (a.purchases < b.purchases) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return 1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}

//Functions for sorting ProductNumber based Most Favorite 
export function compareProductsLeastFavorite(a, b) {

    if (a.purchases > b.purchases) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return 1;
        }
    if (a.purchases < b.purchases) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return -1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}

export function compareProductsByPurchases(a, b) {

    if (a.purchases > b.purchases) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return -1;
        }
    if (a.purchases < b.purchases) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return +1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}

export function compareProductsByCoinsSpent(a, b) {

    if (a.coinsSpent > b.coinsSpent) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return -1;
        }
    if (a.coinsSpent < b.coinsSpent) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return 1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}

export  function  setOrderDateBackground({date, status}) {
   if (status === "FILLED" || status === 'INVOICED') return COLOR_GOOD;  
   
   // if (!date) return "white";
   
   const orderDateMoment = moment(date, "YYYY MM DDTHH mm ssZ");
   const daysOld = NOW().diff(orderDateMoment, 'days'); 
   // if (DEBUG_MODE >= 2) console.log("Setting Order Background - order days old:", daysOld);
   // if (DEBUG_MODE >= 2) console.log("Setting background color by rank", rank, numItems, distFromBottom, q1, q2, q3);
   if (daysOld > 5) return COLOR_ALERT;  //RED
   if (daysOld > 2) return COLOR_WARNING_HIGH;  //ORANGE
   // if (daysOld > 1) return COLOR_WARNING_LOW;  //YELLOW
   return COLOR_GOOD;  
}  

function productTextContainsTerm (searchTerm, product) {       
    var searchTermToTest = searchTerm.toLowerCase();       
   // if (DEBUG_MODE >= 2) console.log("Testing product", product, searchTerm);       
   //Now, if searching, only return matches
   if (product.title.toLowerCase().includes(searchTermToTest) || product.description.toLowerCase().includes(searchTermToTest)) {
       return true;
   }       
   return false;           
}

export function productSatisfiesFilter(filter, product) {
    // Our filters "gift cards", "< 20 coins", "< 50 coins"  
    if (DEBUG_MODE >= 2) console.log("Checking product against filter", product, filter);

    var passes = false;  
    switch (filter) {
        
        case "gift cards":
            passes = productTextContainsTerm("gift card", product);
            break;
        case "< 20 coins":
            passes =  (product.price <= 20);
            break;
        case "< 50 coins":
            passes =  (product.price <= 50);
            break;
        case "< 100 coins":
            passes =  (product.price <= 100);
            break;
        case "< 500 coins":
            passes =  (product.price <= 500);
            break;
        default:
          passes = true;
    }
    
//   if (DEBUG_MODE >= 2) console.log("Product filter check returning", passes);
   
    return passes;
}   


export function productMatchesSearch({product, activeFilters, keywords}) {
    if (!product || (!activeFilters && !keywords)) return false;

    // if (DEBUG_MODE >= 2) console.log("Testing", product, keywords, activeFilters);
    //Return TRUE if the product matches our search term AND matches ALL of the filters that are set 
    //DOES THE PRODUCT MATCH ANY FILTERS
    var passesFilters = true;  //default to yes, it passes
    if (activeFilters && activeFilters.length > 0) passesFilters = activeFilters.some((filter) => productSatisfiesFilter(filter,product));   
    
    //DOES THE PRODUCT MATCH OUR SEARCH TERM, IF ANY?
    if (passesFilters && keywords && keywords.length > 0) {
 		// Break the keywords into an array of terms.  For example, split "elderly, flowers, team" into an array ["elderly", "flowers", "team"]
		// Use regex followed by a filter to remove any empty values
		// https://stackoverflow.com/questions/10346722/how-to-split-a-string-by-white-space-or-comma
		const searchTerms = keywords.split(/[ ,]+/).filter(Boolean);
		if (DEBUG_MODE) console.log("Generated array of terms to further filter search", searchTerms);


        /* CODE FOR IF ALL KEYWORDS MUST BE A MATCH RATHER THAN ANY KEYWORD
		//Set our pass flag to false if ANY search terms is NOT contained (i.e. if the ContainsTerm call fails for SOME terms)
		if (searchTerms.some((searchTerm) => (productTextContainsTerm(searchTerm, product)===false))) {
			if (DEBUG_MODE) console.log("Search terms not satisfied")
			passesFilters=false;
		}
		*/

 		/* FAIL MATCH IF NO KEYWORDS ARE FOUND TO MATCH (i.e., the .some fails) */
         if (searchTerms.some((searchTerm) => (productTextContainsTerm(searchTerm, product)))===false ) {
			if (DEBUG_MODE) console.log("Search terms not satisfied")
			passesFilters=false;
		}       
    }
    
    return passesFilters;    
}  


//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


//config
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, CONECTIVITY_INITIAL_STATE, RATING_STATS_INITIAL_STATE, portfolioWideConectivityAlyticsInitialState, 
    DAYS_FOR_RECENTLY_SHARED_CONECTIVITIES, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR, CONECTIVITY_PUBLICATION_SELECTION_OPTIONS,
    GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, AUTO_FILL_CONECTIVITY_EDITOR_ON_CATEGORY_SWITCH, addToCalendarIcon, 
    GOOGLE_CALENDAR_CLIENT_SECRET, GOOGLE_CALENDAR_CLIENT_ID, GOOGLE_SCOPES, GOOGLE_INDENTITY_URL, GOOGLE_APIS_URL, GOOGLE_DISCOVERY_DOC
} from '../../shared/data/conectereConfigData';
import { HELP_TEXT_ADMIN_SLACK_BUTTON } from '../../shared/data/helpData.js';

import React,{ useEffect, useState, useContext, useRef } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';
// import { v4 as uuidv4 } from 'uuid';  
// import ReactDOM from "react-dom";

//Styles
import "./userConectivitySchedule.css";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
// import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
// import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { CalendarContext } from '../../shared/context/calendarContext';
// import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context

//Queries and Mutations
import { fetchSlackChannels } from '../../shared/graphql/mutations';


//Utils
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,
buildRecurrenceString, getrecurrenceRuleLaunchDates, getRecurrenceRuleNextLaunchDate,isRecurrenceRuleLaunchToday, getLaunchRuleDateTime,
} from "../../shared/utils/dateTimeUtils";
import { HelpButton  } from "../../shared/utils/generalUtils";
// import { DisplayUserSelectComponent, displayUsersRow, generateUsersToInvite } from "../../shared/utils/userAndTeamUtils";
// import { displayInvitees } from "../../shared/utils/invitationUtils";
// import { getUserInitials } from "../../shared/utils/userAndTeamUtils";

// import moment from 'moment';
// import { v4 as uuidv4 } from 'uuid';    

//Our Components
// 
//Bootstrap and other React components
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Select from 'react-select';   //Version with support from multiple selections
// import Switch from '@material-ui/core/Switch';


//Icons


//Here's the start of our Javascrpt App
const UseAccountSettings = () => {


// Authentication context
const {
    authState, currentUser, isAdmin,
} = useContext(AuthContext);    


// Calendar context
const {
displayGoogleCalendarAccessControlButton, displayMicrosoftCalendarAccessControlButton,
} = useContext(CalendarContext);


//State variables
const [slackChannelOptons, setSlackChannelOptons] = useState([]);

// Rebuild Teams Options if there has been a change to TEAMS
useEffect(() => {

 if (authState !== 'signedin' || !currentUser || !currentUser.customer.configDetails.slackEnabled) return;
 console.log("Fetching Slack channels");
 
 buildSlackChannelOptions(currentUser.id, currentUser.customerID);

 //build list of dropdown options for Slack channels, if Slack is enabled
 const tempSlackChannelOptions = [];

}, [authState]);

async function buildSlackChannelOptions(userID, customerID) {

//Fetch Conversations from Slack
//Call GraphQL to directly invoke our Lambda function 
if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to Fetch Slack Channels - ");
const response = await fetchSlackChannels({userID, customerID});
if (DEBUG_MODE >= 2) console.log("Fetch Slack Channels returned", response);        

//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
if (response && response.ok) {
       const tempChannels = [...response.channels];
       for(var l = 0; l<tempChannels.length; l++){
       
          tempChannels[l] = {...tempChannels[l],
             label:tempChannels[l].name,
             value:tempChannels[l].id,
          };
       }                  
       setSlackChannelOptons(tempChannels);
       if (DEBUG_MODE >= 2) console.log("Extracted fetched channels", tempChannels);
} else {
 if (DEBUG_MODE >= 1) console.log("ERROR - Slack returned error when fetching channels", response);
}
}

/*
const handleChannelSelection = (selectedChannelOption) => {
if (DEBUG_MODE >= 2) console.log("Channel selected", selectedChannelOption);
setSlackChannelSelection(selectedChannelOption);
};
*/     
//
// Page Render
//

//Handle access by unauthenticated user
if (authState !== "signedin"  || !currentUser) {
return null;
}

//Render function for authenticated user 

return (

<Authenticator>
 <div className="adminPage">     
    <div className="dashboardContainer boxShadow">
        <div className="dashboardTitle">
            USER SETTINGS
            </div>                     
            <div className="ContainerVerticalCenter alignStart"  style={{margin:"4vh"}}>
                <div className="TextStyle4" style={{paddingBottom:"clamp(4px,1vh,10px)"}}>Calendar Integration</div>
                    <div className="ContainerNoHeightWrap " >
                        {displayGoogleCalendarAccessControlButton()}
                        {displayMicrosoftCalendarAccessControlButton()}
                    </div>

{/* REMOVED AS SLACK WAS CHANGED TO A CUSTOMER SETTTING NOT A USER SETTING
                <div className="ContainerVerticalCenter" style={{margin:"4vh"}}>
                    <div className="ContainerNoHeight">
                        <h4>Workflow Integration </h4>
                        <HelpButton hoverTextClassName='TextStyle4 grey hoverTextStyle1 hoverTextStyle1ExtraWide' hoverText={HELP_TEXT_ADMIN_SLACK_BUTTON}  /> 
                    </div>
                    <div style={{paddingTop:"clamp(4px,1vw,10px)"}}>
                        {displaySlackAccessControlButton()}
                    </div>   


                {isAdmin ?
                        <div style={{flexShrink:'0', width:"100%", height:"auto", marginLeft:"auto"}}>
                        <Select  
                            isMulti={false} 
                            name="channelDropdown" 
                            options={slackChannelOptons} 
                            isOptionDisabled={(option) => option.isdisabled}
                            isDisabled={!isAdmin}  //Disable if not Admin
                            onChange={handleChannelSelection} 
                            value={!slackChannelSelection  ? null : slackChannelSelection.name}  
                            placeholder=" - select -"
                            formatOptionLabel={channel => (
                                <div className="ContainerNoHeightFlexLeft noWrap TextStyle4"  style={{whiteSpace:"nowrap"}}>#{channel.label} </div>
                            )}

                        />
                    </div>
                :  
                    <div className="black"> Spotlight channel: {!slackChannelSelection  ? 'unassigned' : slackChannelSelection.name} </div>
                }
                </div>
*/}
            </div>
        </div>
    </div>    

</Authenticator>
);
};




export default UseAccountSettings;


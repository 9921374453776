//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import "./inputFieldText.css";
import  { DEBUG_MODE } from '../../../../shared/data/conectereConfigData';
import React from 'react';

//Icons
import {MailOutline, Add, Edit, DeleteOutline, PermIdentity, PhoneAndroid, Group} from '@material-ui/icons';
import FaceIcon from '@mui/icons-material/Face';

import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

//Color theme not working yet
const theme = createTheme({

  palette: {
    primary: {
      main: '#409cf7',
      darker: '#409cf7',
    },
    neutral: {
      main: '#409cf7',
      contrastText: '#fff',
    },
  },
});



//React component for uploading an image from the user
export const InputFieldText = ({inputChangeCallback, label, value, placeholder, required, name}) => {

    if (!label) label = "" ;
    if (!value) value = "" ;
    if (!placeholder) placeholder = "";

    //Our callback function to parent on any change
    function invokeCallBack(e) {
        
        if (DEBUG_MODE >= 2) console.log("Image Upload Callback Invoked", e);
        
        if (inputChangeCallback) {
            inputChangeCallback (e);
        }
    }

    if (DEBUG_MODE >= 2) console.log("File Upload Component called");

    return (
        <div className="ContainerVerticalLeft">
            <div> {label} </div>
            <input
                name={name}
                type="text"
                value={value}
                onChange={e => invokeCallBack(e)}
                required={required ? "true" : "" }
            />
        </div>
    );

};





//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

// style
import './BonusModal.css';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Animation
import Celebration from './CelebrationAnimation.json';
import Coin from './CoinAnimation.json';
import CoinAnimation from './CoinAnimation';

const BonusModal = React.memo(({ showModal, onClose, modalBonusAmount, modalBonusMessage }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (!showModal && isAnimating) {
      const timeout = setTimeout(() => {
        setIsAnimating(false);
      }, 2900);

      return () => clearTimeout(timeout);
    }
  }, [showModal, isAnimating]);

  const handleClose = () => {
    onClose();
    setIsAnimating(true);
  };

  return (
    <>
      {!showModal && isAnimating && (
        <CoinAnimation isAnimating={isAnimating}>
          <div style={{ zIndex: 1000 }}>
            <Lottie
              animationData={Coin}
              loop={false}
            />
          </div>
        </CoinAnimation>

      )}
      <div className='BonusModalContainer' hidden={!showModal}>
        <div
          hidden={!showModal}
          className='ModalBgContainer'
          onClick={handleClose}
          onScroll={(e) => e.stopPropagation()}
        />
        <div className='navbarRightContainerSpacing BonusModalCard boxShadow isCard'>
          <CloseIcon
            className='TextStyle6 pointer dust-grey connectivityModalCloseIcon'
            onClick={handleClose}
          />
          <div className='ContainerNoHeightCenter'>
            <Lottie
              speed={2}
              animationData={Celebration}
              loop={true}
              className='position-absolute celebration-animation'
            />
            <div className='ccRing' style={{ zIndex: 1 }}>
              <Lottie animationData={Coin} loop={true} />
            </div>
          </div>
          <span className='TextStyle5 blue-gradient'>Congratulations!</span>
          <span className='TextStyle5 perple-yellow-gradient'>You've Earned {modalBonusAmount || "0"} Coins!</span>
          <span className='TextStyle2 textAlignCenter formDataStyleGrey'>
            {modalBonusMessage}
          </span>
          <div className='buttonContainer animatedRingsInnerWrapper'>
            <button
              onClick={handleClose}
              className='very-light-grey-border grey-text buttonStyle1 buttonStyle1HeaderBlue imageEditModalStyle white-background noBoxShadow'
              style={{ borderRadius: '6px' }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default BonusModal;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, GIPHY_SDK_KEY_WEB, HEADER_FULL_MODE, COLOR_GOLD, COLOR_BLUE_HEADER, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR,
	CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD, GIPHY_ID_IDENTIFIER  } from '../../data/conectereConfigData';

//Amplify, React
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

//Our components
import { MessageInputBar }  from "../messageInputBar/messageInputBar";

//Context
import { CustomerContext } from '../../context/customerContext';            //Customer Authentication context
import { GiphyCacheContext } from '../../context/giphyCacheContext';
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context
import { ModalContext } from '../../context/modalContext';            

//Utils
import { invokeDeleteMessage, invokeSendMessage, invokeUpdateMessage, invokeUpdateMessageLikes } from "../../utils/messageUtils";
import { setHeaderColorByStringLength } from "../../utils/conectivityUtils"; 
import { sortMessages, timeAgo, displayConectereAsAvatar, EditButton, DeleteButton} from "../../utils/generalUtils";
import { NOW } from "../../utils/dateTimeUtils"; 
import { getUserInitials, displayUserAvatar } from "../../utils/userAndTeamUtils";
import { storeImageFile }  from "../../utils/storageUtils";
import { v4 as uuidv4 } from 'uuid';    

//Material UI components for Cards
import { IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

//GIPHY support
import { Gif } from '@giphy/react-components';
import CommentInput from '../commentInput/commentInput';

/* Main CHAT Interface
INPUTS
MessagesToShow - An array of messages to display
InvitationToUpdate (Optional) - the specific invitation to which the messages, IF ANY
LaunchRule - the parent LaunchRule with which the messages are associated
*/
export const ChatWindow = React.memo(({
currentUser, 
messagesToShow, 
placeholderMessage, 
launchRuleID, 		//LR to which to associate with the messages - OPTIONAL but LR ID or REC ID required unless the sendMessageCallbackOverride is supplied
recognitionID, 	    //Recognition to associate with the messages - OPTIONAL
sendMessageCallbackOverride, // Parent-provided handler in case the chat window is not used to post LR or Recognition messages.  Leave OFF otherwise
disableReplies, 
disableGif,			//Disables the ability for the user to enter a GIF
// noFixedHeight,      //Let the height adjust dynamically, like on a Spotlight card or the Spotlight send tool
// minHeight,
messageSelectCallback,
messageSelectLabel,
isAIChat,
}) => {

// Context
const { users } = useContext(CustomerContext);  
const { darkMode } = useContext(DisplayContext); 
const { fetchGif, giphyCache } = useContext(GiphyCacheContext);  
const { setShowModalMediaDisplay, setMediaUrl } = useContext(ModalContext); 

//Messaging local state data
const [messagesToShowLocal, setMessagesToShowLocal] = useState([]);             //Our LOCAL cache of messages that we can update immediately upon sending a message
const [showLikesList, setShowLikesList] = useState(null);                       //ID of Spotlight or Comment that is currently being hovered over
const [newMessageGiphyGridContainerDivSize, setNewMessageGiphyGridContainerDivSize]  = useState(450);   //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match

//Local Giphy state
// const [giphyCache, setGiphysCache] = useState([]);  //local cache for this component

//Replies
const [showMessageReply, setShowMessageReply] = useState(null);                 //ID of Comment for which to show the reply region
// const [reply, setReply] = useState("");
// const [replyGif, setReplyGif] = useState(null);
const [messageToEdit, setMessageToEdit] = useState(null);                         
const [isEditMode, setIsEditMode] = useState(false);


//React References
const messageModalRef = useRef();


//UseEffect upon EVERY RENDER of the component    
useEffect(() => {
//Update size of Giphy Containers in order to dynamically set size of grids
if (messageModalRef && messageModalRef.current && messageModalRef.current.clientWidth && messageModalRef.current.clientWidth !== newMessageGiphyGridContainerDivSize) {
	setNewMessageGiphyGridContainerDivSize(messageModalRef.current.clientWidth);
	// if (DEBUG_MODE >= 2) console.log("Updated CHAT Window GIPHY Grid Container width", messageModalRef.current.clientWidth);
} else {
	// if (DEBUG_MODE >= 2) console.log("DID NOT update CHAT Window GIPHY Grid Container width", messageModalRef.current.clientWidth);
}
});  

//UseEffect that runs ONLY first Mount
useEffect(() => {
if (!messagesToShow || !messagesToShow.length) return;
// console.log("MESSAGES TO SHOW", messagesToShow)
const tempNewMessages = [...messagesToShow]; //Copy the new messages
setMessagesToShowLocal(tempNewMessages);
startScrollTimer();
},[]);  


/*
//UseEffect that runs on change of our Master GIF cache
useEffect(() => {

const tempNewMessages = [...messagesToShow]; //Copy the new messages
setMessagesToShowLocal(tempNewMessages);
startScrollTimer();
},[]); 
*/    

//UseEffect that runs on a message change
useEffect(() => {

// if (DEBUG_MODE >= 1) console.log("Change detected to messagesToShow", messagesToShow);

let tempNewMessages=[], newMessageReceivedFlag=false;
if (messagesToShow) {    
	tempNewMessages = [...messagesToShow]; //Copy the new messages
	newMessageReceivedFlag =  (tempNewMessages && messagesToShow && (messagesToShow.length > messagesToShowLocal.length));
}
setMessagesToShowLocal(tempNewMessages);

//Fetch our Giphy GIFs upon changes to messages being viewed    
if (messagesToShow.length > 0)  fetchGifs(); //Update our GIF cache based on the viewed messages

//Did a new message come in?  If so, scroll to the bottom of the component.  Otherwise, no
if (newMessageReceivedFlag)  startScrollTimer();
},[messagesToShow]);  


function startScrollTimer() {
//Scroll to the bottom of the component
// if (DEBUG_MODE >= 2) console.log("Starting Scroll Timer", messageModalRef.current.clientWidth);
const msgTimer = setTimeout(() => {
	scrollToBottom();  //Trigger a timer to scroll to the bottom of the message window AFTER it is displayed; Plus give time for any GIFs to load
	// if (DEBUG_MODE >= 2) console.log("Scrolled to bottom");
}, 100);
}

async function fetchGifs() {

//Safety check
if (!messagesToShow || messagesToShow.length===0) return;
try {       
 // if (DEBUG_MODE >= 2) console.log("Chat window fetching GIFs", messagesToShow);

//Update our MASTER array of GIPHY objects to render to the user based on the messages that are shown
for (var i=0; i < messagesToShow.length; i++) {
	if (messagesToShow[i].message && typeof messagesToShow[i].message === 'string' && messagesToShow[i].message.includes(GIPHY_ID_IDENTIFIER)) { 
		const gifId = messagesToShow[i].message.slice(GIPHY_ID_IDENTIFIER.length); 
		// if (DEBUG_MODE >= 2) console.log("Extracted GIF ID", gifId);
		const tempObject = giphyCache.find(msg => (msg && msg.data && msg.data.id === gifId));
		//Direct our Context to Fetch the GIF from GIPHY but only IF it has not already been fetched and in our cache
		if (!tempObject) await fetchGif(gifId); //Fetch from our master Giphy Cache Context
	}
}
} catch (err) {
  if (DEBUG_MODE >= 2) console.error("ERROR - Chat window unable to fetch GIFs", err);
}
}

//
// Messaging
//


async function handleLikeClick ({e, userAlreadyLike, message}) {
if (DEBUG_MODE >= 2) console.log("LIKE clicked", userAlreadyLike, message);

//Safety check
if (!message || !currentUser) return;

const currentUserLikes = (!message.userLikes ? [] : [...message.userLikes]); //Copy current list or init a new one for message likes
//Update the list of IDs
if (userAlreadyLike) {
	//Remove the userID from the list
	var indexToRemove = currentUserLikes.indexOf(currentUser.id);
		
	if (!indexToRemove>-1) {
		currentUserLikes.splice(indexToRemove,1); //remove current user
	} else {
		if (DEBUG_MODE >= 1) console.log('Error removing user from LIKE list.  User not found');
	}
	//Empty list?  If so, hide the list since currently being hovered over
	if (currentUserLikes.length == 0) setShowLikesList(null);
} else {
	
	//Add the user to the like List
	currentUserLikes.push(currentUser.id);
	// if (DEBUG_MODE >= 2) console.log('Added user to the message LIKE list');
	//Just started a list?  If so, show list since currently being hovered over
	if (currentUserLikes.length == 1) setShowLikesList(message.id);
}

//Now, invoke lambda to update the DB                
try {         

	//Update the message currently being viewed 
	message.userLikes = [...currentUserLikes]; //Update with new array in memory so as to update display
	let messageParams = {
		messageID:message.id,
		senderID:currentUser.id,
		customerID:currentUser.customerID,
		launchRuleID:launchRuleID, 
		userLikes: (currentUserLikes ? currentUserLikes : []),
	};
	
	//message relate to a Spotlight?  If so, pass it to our Lambda so we can do an App Sync Push at the Spotlight level
	if (message.recognitionID) messageParams.recognitionID = message.recognitionID;

	let {successFlag} = await invokeUpdateMessageLikes(messageParams);
	if (DEBUG_MODE >= 2) console.log('UPDATE MESSAGE lambda returned', successFlag); 

} catch (err) {
	if (DEBUG_MODE >= 2) console.log("Error toggling LIKE for the message", err);
}
	
}    
//Display likes list for the chat message
function displayLikeList(message) {      
if (!message || !message.userLikes) return null;
var currentUserLikesList = "";     
const NAMES_TO_DISPLAY = 5;     
const arrayToUse =  message.userLikes;          
if (!arrayToUse) return null;

//Build the Like list
for (var i=0; i < arrayToUse.length && i < NAMES_TO_DISPLAY; i++) {
	var userThatLiked = users.find(user => user.id == arrayToUse[i]); 

	if (userThatLiked) currentUserLikesList = currentUserLikesList + userThatLiked.firstName + " " +userThatLiked.lastName.substring(0,1) + ".";
	if (i < arrayToUse.length-1 && i < NAMES_TO_DISPLAY-1) currentUserLikesList = currentUserLikesList + ", ";
}

if (arrayToUse.length > i) currentUserLikesList = currentUserLikesList + " + " + (arrayToUse.length - i).toString() + " more";

return (
	<div>
		{currentUserLikesList}
	</div>
	);
}

const handleMouseLikesLeave = (e) => {
// if (DEBUG_MODE >= 2) console.log("Mouse left LIKES",e);
setShowLikesList(null);
};      

const handleMouseLikesEnter = (e, message) => {
// if (DEBUG_MODE >= 2) console.log("Mouse entered LIKES", e, message);
if (message && message.userLikes &&message.userLikes.length > 0) setShowLikesList(message.id);  /* Show the Like List, if one exists, for this COMMENT */
};

function showLikesCount({message}) {

 //Has this person LIKED the message already?
 var userAlreadyLike = false;
 var numLikes = 0;

 if (message && message.userLikes) {
	 userAlreadyLike = message.userLikes.find (userID => userID == currentUser.id);
	 numLikes = message.userLikes.length;
 }

return (
		<div className="ContainerNoHeightCenter"  > 
			<IconButton size="small" onClick={(e) => handleLikeClick({e: e, userAlreadyLike: userAlreadyLike, message: message})} onMouseLeave={handleMouseLikesLeave} onMouseEnter={(e) => handleMouseLikesEnter(e,message)}>
				<div className="TextStyle2 blue-text" > {numLikes == 0 ? "" : numLikes} </div>
				{userAlreadyLike ? <ThumbUpIcon className="TextStyle2" style={{color:"gold"}} /> : <ThumbUpOffAltIcon className="TextStyle2"  /> }
			</IconButton>
			
		</div>    
	);
}

function displayLikeAndReplyButton({message, isSender, showMessageReply}) {
/*Hide the message button row if we are in REPLY mode or if the parent module disabled them*/
if (disableReplies || showMessageReply) return null;
return (
	<>
		{showLikesCount({message:message})}
		{ /*Only display REPLY button if not already a REPLY */
			(message.parentMessage) ? null :

			<div className="messageReplyButton"  style={{ position:"relative"}}> 
				<IconButton size={'small'} onClick={(e) => {
							if (e) e.stopPropagation();
							if (DEBUG_MODE >1) console.log("Setting reply message", message);
							setShowMessageReply(showMessageReply === message.id ? null : message.id);
							// setReply("");
							// setReplyGif(null);
							// setReplyGifLoaded(null);
						}
				}>
						<div className="TextStyle2 blue-text" >REPLY</div>
				</IconButton>
			
			</div> 
		}                                         
		<div className={`messageLikesList ${showLikesList === message.id && "messageLikesListHover"}`} style={{zIndex:"44", right: (isSender ? "0%" : "100%"), transform: (isSender ? "translate(0,0)" : "translate(100%,0)"),}}> {displayLikeList(message)} </div>
	</>
)
}

function displayMessageSelectButton({message, isSender, messageSelectCallback, messageSelectLabel}) {
if (!messageSelectCallback || !messageSelectLabel  || isSender || !message || !message.isUserSelectable) return null;
return (
	<div className="messageReplyButton"  style={{ position:"relative"}}> 
		<IconButton size={'small'} onClick={(e) => {
					if (e) e.stopPropagation();
					if (DEBUG_MODE >1) console.log("Copying message for the user", message);
					if (messageSelectCallback) messageSelectCallback(message);
				}
		}>
				<div className="messageReplyButtonText" >{messageSelectLabel}</div>
		</IconButton>					
	</div> 
	
)
}

function displayMessageEditButtons({message, isSender, messageEditCallback, messageDeleteCallback}) {
if (!messageEditCallback || !messageDeleteCallback  || !isSender || !message) return null;
return (
	<>
		<EditButton className="grey-text TextStyle2" onClickCallback={()=>messageEditCallback(message)} />
		<DeleteButton className="grey-text TextStyle2" onClickCallback={()=>messageDeleteCallback(message)} />
	</>		
)
}


function displayMessageButtonRow({message, isSender, showMessageReply, messageSelectCallback, messageSelectLabel, mouseOver, messageEditCallback, messageDeleteCallback}) {
return (
	<div className="ContainerNoHeightCenter fullWidth messageButtonRow" style={{justifyContent: (isSender ? "flex-end" : "flex-start"),  position:"relative"}}>
		{isSender && mouseOver ? displayMessageEditButtons({message, isSender, messageEditCallback, messageDeleteCallback}) : null}
		{displayLikeAndReplyButton({message, isSender, showMessageReply})}
		{!isSender && displayMessageSelectButton({message, isSender, messageSelectCallback, messageSelectLabel})}
	</div>
)
}

function renderMessage({message, giphyCache, isSender, showMessageReply, messageSelectCallback, messageSelectLabel, mouseOver, messageEditCallback, messageDeleteCallback}) {
if (!message || (!message.message && !message.mediaUrl) || message.isHidden) return null;    //Note, do not display any 'hidden' messages that are used to exchange state with our AI bots
let gif = null, missingGIF = false;
if (message.message && message.message.includes(GIPHY_ID_IDENTIFIER)){ 
 const gifId = message.message.slice(GIPHY_ID_IDENTIFIER.length);
 // if (DEBUG_MODE >= 2) console.log('Rendered message gifId', gifId);
 gif = giphyCache.find(msg => (msg && msg.data && msg.data.id === gifId));
 if (gif) {
	 // if (DEBUG_MODE >= 2) console.log('Selected gif',gif);
 } else {
	 // if (DEBUG_MODE >= 2) console.log('GIF not found in cache.  NOT rendering message',message);
	  missingGIF = true; //Do NOT render the message text - GIF not fetched (yet, possibly)
 }
}

return (
 <div className="ContainerVertical" style={{cursor: (isSender ? "pointer" : "default"), flexGrow:"1"}}> 
	 <div className="ContainerNoHeight" style={{alignItems: "center", justifyContent: (isSender ? "flex-end" : "flex-start"), width:"100%", margin:"5px 10px",  position:"relative"}}>
		{!message || !message.mediaUrl ? null :
			<div className="ContainerNoHeightCenter positionRelative " >
				<img className="ccImgXXXL" src={message.mediaUrl} alt="" />
			</div>
		}	
		{!gif ? null :  
			  <Gif gif={gif.data} width={200} noLink={true} hideAttribution={true} style={{padding:"5px"}}/>
		}
		{gif || !message || !message.message ? null :
			 <div className={`TextStyle4 ${isSender ? 'messageRowTextSenderV2' : 'messageRowTextV2'}`}>{missingGIF ? '...' : message.message}</div>
		}
	 </div>
	 {message && message.isAiGenerated ? <div className="TextStyle1"><em>AI generated - review for accuracy</em></div> : null }
	{displayMessageButtonRow({message, isSender, showMessageReply, messageSelectCallback, messageSelectLabel, mouseOver, messageEditCallback, messageDeleteCallback})}  
			  
 </div>
);
}  

/* Messaging Interface Handlers */
async function handleSendMessage({message, messageGif, parentMessageID, mediaUrl, mediaFile}) {

try {
	//Safety checks
	if (!launchRuleID && !recognitionID && !sendMessageCallbackOverride) return; //Must be one of these
	if (!message && !messageGif && !mediaFile) return;   //Must be one or the other
 
	//Before hitting the backend, construct fake placeholder message(s) and update our local state with the placeholder messages so we can provide immediate feedback to the user
	const tempMessageList = [ ...messagesToShowLocal]; 
	let tempInsertedMessage = {
		isPlaceholderMessage:true,
		senderID:currentUser.id,
		senderAvatarUrl: currentUser.avatarUrl,      //location where avatar image is stored on the S3 bucket
		senderAvatarName: currentUser.firstName + " " + currentUser.lastName,
		senderAvatarInitials: getUserInitials(currentUser),
	};
	if (parentMessageID) tempInsertedMessage.parentMessage = parentMessageID;     //Record parent comment if this is a REPLY
	if (launchRuleID) tempInsertedMessage.launchRuleID = launchRuleID;            //Related to a LaunchRule?
	if (recognitionID) tempInsertedMessage.recognitionID = recognitionID;         //Related to a Spotlight?
 
	//First, temporarily post any GIF
	if (messageGif && messageGif.id) {            
		tempInsertedMessage = {...tempInsertedMessage, 
			message:GIPHY_ID_IDENTIFIER + messageGif.id,
			id:uuidv4(),
			createdAt:NOW().toISOString(),
			updatedAt:NOW().toISOString(),
		};
		tempMessageList.push({...tempInsertedMessage});                 
		if (DEBUG_MODE >= 2) console.log('Push placeholder GIF message to local messages', tempInsertedMessage); 
	}	

	//Second, temporarily post any Media File message
	if (mediaUrl) {
		tempInsertedMessage = {...tempInsertedMessage, 
			message:'',
			mediaUrl,
			id:uuidv4(),
			createdAt:NOW().toISOString(),
			updatedAt:NOW().toISOString(),
		};

		tempMessageList.push({...tempInsertedMessage});                
		if (DEBUG_MODE >= 2) console.log('Push placeholder MEDIA message to local messages', tempInsertedMessage); 
	}   

	//Third, temporarily post any text message
	if (message) {
		tempInsertedMessage = {...tempInsertedMessage, 
			message,
			id:uuidv4(),
			createdAt:NOW().toISOString(),
			updatedAt:NOW().toISOString(),
		};

		tempMessageList.push({...tempInsertedMessage});                
		if (DEBUG_MODE >= 2) console.log('Push placeholder TEXT message to local messages', tempInsertedMessage); 
	}           


	//Now, sort and update the ChatWindow with our placehoder messages          
	const sortedMessages= sortMessages(tempMessageList);
	if (DEBUG_MODE >= 2) console.log('New message array after sort', sortedMessages); 
	setMessagesToShowLocal(sortedMessages);             //Update message currenty being viewed to give immediate feedback!
	startScrollTimer();
	if (DEBUG_MODE >= 2) console.log('Updated local messages; triggering scroll'); 

	//Are we posting the message or returning to our parent for handling?
	if (sendMessageCallbackOverride) {
		if (DEBUG_MODE) console.log("sendMessageCallbackOverride detected.  Posting message from chat window to our parent", message);
		sendMessageCallbackOverride({
			message,
			newChatMessage:tempInsertedMessage,
		});
	} else if (launchRuleID || recognitionID) {
		//Send the message(s) to our backend
		let messageParams = {
			senderID:currentUser.id,
			customerID:currentUser.customerID,
			launchRuleID:launchRuleID, 
			recognitionID:recognitionID,
			parentMessageID: (parentMessageID ? parentMessageID : null),
		};
		
		//Message relate to a specific invitation?  If so, add it to our params
		// if (invitationToUpdate) messageParams.invitationID=invitationToUpdate.id;
			
		//First, send any GIF
		if (messageGif && messageGif.id) {
			messageParams.message = GIPHY_ID_IDENTIFIER + messageGif.id;
			messageParams.pushMessage="Attachment:1 gif";
			if (DEBUG_MODE >= 2) console.log('Sending GIF to backend', messageParams);	
			let {successFlag, tempInsertedMessage} = await invokeSendMessage(messageParams);		
			if (successFlag) if (DEBUG_MODE >= 2) console.log('Successfully sent GIF', tempInsertedMessage);   
		}


		//Second, send any media message
		if (mediaUrl && mediaFile) {
			// Upload the media file to s3 with public access level   
			let publicUrl;
			try {
				let {successFlag, url} = await storeImageFile(mediaFile);
				if (successFlag) publicUrl = url; if (DEBUG_MODE > 1) console.log ("Successfully wrote media file to S3 ", publicUrl);
				else  if (DEBUG_MODE > 0) console.error ("ERROR - failed avatar image to S3");                            
			} catch (err) { if (DEBUG_MODE >= 2) console.log("Error writing media file to S3", err); } 

			if (publicUrl) {
				messageParams.message = '';
				messageParams.mediaUrl = publicUrl;
				messageParams.pushMessage="Attachment:1 image";
				if (DEBUG_MODE >= 2) console.log('Sending media type message to backend', message );
				let {successFlag, tempInsertedMessage}  = await invokeSendMessage(messageParams);
				if (successFlag) if (DEBUG_MODE >= 2) console.log('Successfully sent Media type message', tempInsertedMessage);   
				else if (DEBUG_MODE) console.error("Error sending media type message to backend");
			}
		}   

		//Third, send any text message
		if (message) {
			if (DEBUG_MODE >= 2) console.log('Sending message to backend', message );
			messageParams.message = message;
			messageParams.pushMessage=message;
			let {successFlag, tempInsertedMessage}  = await invokeSendMessage(messageParams);		
			if (successFlag) console.log('Successfully sent TEXT', tempInsertedMessage); 
			else if (DEBUG_MODE) console.error("Error sending text type message to backend");
		}  				
	} //END if Handling message locally
} catch (err) {
	if (DEBUG_MODE >= 1) console.log("Error sending message", err);
}

//Clear text input form and reply input
// scrollToBottom();
// setSendMessage("");
// setMessageGif(null);
// setReply("");
// setReplyGif(null);
setShowMessageReply(null);      //Clear any message ID so as to hide the reply input field
}

async function updateMessageCallback ({isEditMode, messageToEdit, message}) {

try {
	//Safety checks
	if (!isEditMode || !messageToEdit || !messageToEdit.id || !message) {
		console.log("Improper params to update a message", isEditMode, messageToEdit, message); 
	}
 
	//Before hitting the backend, update our local state with the placeholder messages so we can provide immediate feedback to the user
	let tempMessageList = [ ...messagesToShowLocal]; 
	let tempMessage = {...messageToEdit,
		message:message,
		updatedAt:NOW().toISOString(),
	};
	tempMessageList = tempMessageList.filter(msg => msg.id !== tempMessage.id); //remove the message being edited
	tempMessageList.push({...tempMessage});                
	if (DEBUG_MODE >= 2) console.log('Push placeholder message to local messages', tempMessage); 
	const sortedMessages= sortMessages(tempMessageList);
	if (DEBUG_MODE >= 2) console.log('New message array after sort', sortedMessages); 
	setMessagesToShowLocal(sortedMessages);             //Update message currenty being viewed to give immediate feedback!
	startScrollTimer();
	if (DEBUG_MODE >= 2) console.log('Updated local messages; triggering scroll'); 
	setIsEditMode(null);      		
	setMessageToEdit(null);   
	let {successFlag}  = await invokeUpdateMessage(tempMessage);
	if (successFlag) {
		if (DEBUG_MODE >= 2) console.log('Successfully updated message', tempMessage); 
	}   
} catch (err) {
	if (DEBUG_MODE >= 1) console.log("Error sending message", err);
}
}

const messageEditCallback = async (message) => {
if (DEBUG_MODE) console.log("Edit message called", message);
if (!message || !message.id) return;
setMessageToEdit(message);	//Grab the message and place into edit mode
setIsEditMode(true);
}

const cancelEditModeCallback = () =>{
setIsEditMode(false);
}
const messageDeleteCallback = async (message) => {
if (DEBUG_MODE) console.log("Delete message called", message);
if (!message || !message.id) return;	//Safety check
try {
	//Safety checks
	if (!message || !message.id) return; 
	//First, let's remove it from our local state
	let tempNewMessages=[]
	if (messagesToShow && messagesToShow.length > 0) {    
		tempNewMessages = [...messagesToShow].filter(msg => msg.id !== message.id); //Copy the new messages
	}
	setMessagesToShowLocal(tempNewMessages);

	//Now, invoke our cloud to remove the message
	let {successFlag} = await invokeDeleteMessage({userID:currentUser.id,message});		
	if (successFlag) {
		if (DEBUG_MODE >= 2) console.log('Successfully deleted message'); 
	} else {
		if (DEBUG_MODE >= 2) console.log('Something went wrong deleting the message'); 
	}
} catch(err) {
	if (DEBUG_MODE) console.error("Error deleting message", err);
}
}


/* 12.20.2023 - eliminated the seperate reply region for each message and instead uses the single message input bar     

function handleReplyChange({value, parentMessageID}) {
	 
	//Dynamically adjust height of our input region as the user types
	var objDiv = document.getElementById("conecterReplyMessageInputDIV"+parentMessageID);
	if (objDiv) {
		// var newHeight = (value.length < 20 ? "30px" : objDiv.scrollHeight.toString() + "px" );
		var newHeight = ((objDiv.offsetHeight > objDiv.scrollHeight ? objDiv.offsetHeight : objDiv.scrollHeight).toString() + "px");
		setReplyInputBarHeight(newHeight);
		if (DEBUG_MODE >= 2) console.log("Reply input bar change.  Amount to scroll to bottom", objDiv.scrollHeight, newHeight);
	} else {
		if (DEBUG_MODE >= 2) console.log("No Reply Div");
	}             

	//Close GIFPHY Search, if open
	setShowSearchExperienceReply(null);
	setReply(value);
	
}   

function handleReplyFocus(value) {
if (DEBUG_MODE >= 2) console.log("Focus on the REPLY comment input bar");
setShowSearchExperienceReply(null);            
setShowPickerReply(null);
}  

const onEmojiClickReply = (event, emojiObject) => {
if (DEBUG_MODE >= 2) console.log("Emoji Click Reply Comment invoked");

setReply(sendReply => sendReply + emojiObject.emoji);
setShowPickerReply(null);
};

function clearGifReply() {
setReplyGif(null);
setReplyGifLoaded(null);          
} 


function replyInputRegion({giphyGridWidth, parentMessageID, isSender}) {
		
return (
	<div className = "ContainerVerticalStart fullWidth conectereMessagingInputAreaWrapper" >
		<div className = "ContainerNoHeightCenter conectereMessagingInputAreaInnerWrapper" >
			{(replyGif && (replyGifLoaded == parentMessageID)) && (
				<div className="conectereMessagingGiphyStagingAreaWrapper" style={{position:"relative", zIndex:"999"}}>
					<div className="conectereMessagingGiphyStagingArea">
						<Gif gif={replyGif} width={200} noLink={true} hideAttribution={true} style={{padding:"5px"}}/>
					</div>
					<div className="conectereMessagingGiphyClear" onClick={clearGifReply}>
						X
					</div>
				</div>
			  )}

		  {isSender ? null : 
			 <>
				{displayParentLinkReply(isSender)}
				{displayUserAvatar({user:currentUser, isSmall:true})}
			 </>
		  }
		  
			<div className = "conectereMessagingInputArea" style={{position:"relative", zIndex:"888"}}>
				<textarea
					id={"conecterReplyMessageInputDIV"+parentMessageID}
					className="TextStyle3 conectereMessagingInputBar"
					style={{height:replyInputBarHeight}}
					value={reply}
					onChange={event => handleReplyChange({value:event.target.value, parentMessageID:parentMessageID})}
					onFocus={event => handleReplyFocus(event.target.value)}
					placeholder="Add reply"
					// cols="25" 
					rows="1"
				/>
				<div className="ContainerNoHeightCenter conectereMessagingInputAreaButtons" >
					<EmojiButton onClickCallback={() => setShowPickerReply(showPickerReply == parentMessageID ? null : parentMessageID)} /> 
					<GIFButton onClickCallback={() => {
						setShowSearchExperienceReply(showSearchExperienceReply == parentMessageID ? null : parentMessageID);
						setReplyGif(null);
						setReplyGifLoaded(null);}}
					/> 
					<SendButton onClickCallback={() => handleSendMessage({parentMessageID:parentMessageID})} /> 
				</div>

				{(showPickerReply == parentMessageID) &&
				
					<Picker
						pickerStyle={{ width: '35%', minWidth:"250px", zIndex: '20', position: "absolute", right: "-20%", top: "-10%", fontSize: '12px'}}
						onEmojiClick={onEmojiClickReply}/>
				}


			</div>  

				{isSender ?
					<>
					  {displayUserAvatar({user:currentUser, isSmall:true})}
					  {displayParentLinkReply(isSender)}
					</>
					:
					  null
				}                    
			
		</div>

		{(showSearchExperienceReply == parentMessageID) ? 
				<SearchContextManager apiKey={GIPHY_SDK_KEY_WEB}>
					<SearchComponents giphyGridWidth={giphyGridWidth} parentMessageID={parentMessageID}/>
				</SearchContextManager>

		: null}
	

	</div>
);
}

const displayParentLinkReply = (isSender) => {
return(
 <div className="spotlightReplyInputIndent"> 
	 <svg height="40" width="23" >                 
		{ isSender ?
		 <path d="M 0 20 C 20 20 20 20 20 0" stroke="#f7f7f7" strokeWidth="5" fill="none" /> //Define a path for a cool linkage to parent
		 :
		 <path d="M 3 0 C 3 20 3 20 23 20" stroke="#f7f7f7" strokeWidth="5" fill="none" /> //Define a path for a cool linkage to parent
		}
	 </svg> 
 </div> 
);
};
*/

const displayParentLink = (isSender) => {
return (
 <div className="spotlightReplyIndent" > 
	<svg height="40" width="75" >                                                         
		{isSender ?
		 <path d="M 0 20 C 25 20 25 20 25 0" stroke="#f7f7f7" strokeWidth="5" fill="none" />        //Define a path for a cool linkage to parent
		  :
		  <path d="M 52 0 C 52 20 52 20 75 20" stroke="#f7f7f7" strokeWidth="5" fill="none" />      //Sender msg - Define a path for a cool linkage to parent
		}
	</svg> 
 </div>   
 );
};

const displayMessageAvatarRegion = ({message}) => {
//Safety check
if (!message) return;

//Is this a system message from Conectere BOT?  If so, display our log0
if (message.isFromConectere) return (
 <div className="ContainerVerticalStart messageSenderAvatarPortion">
	{displayConectereAsAvatar({ isSmall:true})}
	 <div className="TextStyle1 ">{timeAgo(message.createdAt)}</div>
 </div>			
)

//Must be from a user
const userToDisplay = users.find (user => user.id === message.senderID);
if (!userToDisplay) return;

return(
 <div className="ContainerVerticalStart messageSenderAvatarPortion">
	{displayUserAvatar({user:userToDisplay, isSmall:true})}
	 {/* <div className="TextStyle1 ">{timeAgo(message.createdAt)}</div> */}
 </div>
);
};

const DisplayChatMessage = ({message, giphyCache, index, showMessageReply, messageSelectCallback, messageSelectLabel, messageEditCallback, messageDeleteCallback, isEditMode}) => {
const [mouseOver, setMouseOver] = useState(false);

//Safety check
if (!message || (!message.message && !message.mediaUrl) || message.isHidden) return null;    //Note, do not display any 'hidden' messages that are used to exchange state with our AI bots


//Set a horizontal blank row (margin) if this message is not a reply and not the top message
var marginTop = 0;
if (index > 0) marginTop = (!message.parentMessage ? "1.5rem" : "-1.0");


//Display Sender messages on Right and non-sender messages on Left UNLESS
//the message is a reply, in which case base it off the parent

var parentMessage = messagesToShow.find(m => m.id === message.parentMessage);
var isSender = (currentUser.id === message.senderID);

//Adjust based on parent, if one
if (parentMessage) isSender = (parentMessage.senderID === currentUser.id);

return (
  <div onMouseEnter={()=>{setMouseOver(true)}} onMouseLeave={()=>setMouseOver(false)}  className={`messageRow ${(isEditMode || showMessageReply && showMessageReply !== message.id) ?  "messageRowNotParentOfReply" : null}`} key={message.id + "messageRow"} style={{ marginTop:marginTop, justifyContent:(isSender ? "flex-end" : "flex-start" )}}>
		{!isSender && message.parentMessage ? displayParentLink(isSender) : null }        
	  <div className="ContainerNoHeightFlexLeftTop fullWidth"  style={{justifyContent:(isSender ? "flex-end" : "flex-start" )}}>
		  {isSender ? renderMessage({message, giphyCache, isSender, showMessageReply, messageSelectCallback, messageSelectLabel, mouseOver, messageEditCallback, messageDeleteCallback}) : null}
		  {displayMessageAvatarRegion({message})}
		  {isSender ? null : renderMessage({message, giphyCache, isSender, showMessageReply, messageSelectCallback, messageSelectLabel})  }
	  </div>
	  {isSender && message.parentMessage ? displayParentLink(isSender) : null }        
  </div>
);
};

//Main display region
function messagesDisplayRegion({messagesToShowLocal, giphyCache, showMessageReply, messageSelectCallback, messageSelectLabel, darkMode, messageEditCallback, messageDeleteCallback, isEditMode}) {
let styleObject = {};
// if (noFixedHeight) styleObject = {height:"auto"}; else
// if (minHeight) styleObject = {minHeight:minHeight, height:"unset"};

return (
	<div ref={messageModalRef} className={`chatWindowInnerContainer ${darkMode ? "black-background white" : ""} `}  style={styleObject} onClick={(e) => {if (e) e.stopPropagation(); console.log("Click on the Chat window"); setIsEditMode(false); setMessageToEdit(null);setShowMessageReply(null);}}>
		{!messagesToShowLocal ? null :
			<>
				{messagesToShowLocal.map((message, index) => (
					<DisplayChatMessage key={message.id} isEditMode={isEditMode} message={message} giphyCache={giphyCache} index={index} showMessageReply={showMessageReply} messageSelectCallback={messageSelectCallback} messageSelectLabel={messageSelectLabel} messageEditCallback={messageEditCallback} messageDeleteCallback={messageDeleteCallback}/>
				))}
			</>
		}
	</div>
	);
}

function scrollToBottom() {
if (messageModalRef && messageModalRef.current) {
	messageModalRef.current.scrollTo({ top: messageModalRef.current.scrollHeight, left:0, behavior: "smooth"});
	// if (DEBUG_MODE >= 2) console.log("Called scroll to bottom", objDiv.scrollHeight);
} else {
	// if (DEBUG_MODE >= 2) console.log("Did NOT call scroll  to bottom");
}        

}

//Safety Check
if (!currentUser) return;
let placeholderMessageLocal = (placeholderMessage ? placeholderMessage : "new message");
if (showMessageReply) placeholderMessageLocal = "Reply";
else if(recognitionID) placeholderMessage = "Comment on this Spotlight!";

return (
	<div className='ContainerVertical fullWidth fullHeight borderRadius-V2 overflow-hidden boxShadow'>
		<div className={`ContainerVerticalSpaceBetween chatWindowOuterContainer fullWidth`}>
			{messagesDisplayRegion({messagesToShowLocal, giphyCache, showMessageReply, messageSelectCallback, messageSelectLabel, darkMode, messageEditCallback, messageDeleteCallback, isEditMode})}
			{isAIChat ? (
			<CommentInput
				isEditMode={isEditMode}
				messageToEdit={messageToEdit}
				setFocusTrigger={showMessageReply}
				placeholderMessage={placeholderMessageLocal}
				giphyGridWidth={newMessageGiphyGridContainerDivSize}
				sendMessageCallback={handleSendMessage}
				updateMessageCallback={updateMessageCallback}
				disabled={
				!sendMessageCallbackOverride && !recognitionID && !launchRuleID
				}
				parentMessageID={showMessageReply}
				disableGif={disableGif}
				cancelEditModeCallback={cancelEditModeCallback}
				isAIChat
			/>
			) : (
			<MessageInputBar
				isEditMode={isEditMode}
				messageToEdit={messageToEdit}
				setFocusTrigger={showMessageReply}
				placeholderMessage={placeholderMessageLocal}
				giphyGridWidth={newMessageGiphyGridContainerDivSize}
				sendMessageCallback={handleSendMessage}
				updateMessageCallback={updateMessageCallback}
				disabled={
				!sendMessageCallbackOverride && !recognitionID && !launchRuleID
				}
				parentMessageID={showMessageReply}
				disableGif={disableGif}
				cancelEditModeCallback={cancelEditModeCallback}
			/>
			)}
		</div>
	</div>
);
}, (prevProps, nextProps) => {
//Our React MEMO function - do not re-render if no change to the events array
if (
(prevProps.messagesToShow === nextProps.messagesToShow)

)
{
// if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Chat Window");
return true; // props are equal
}
// if (DEBUG_MODE >= 2) console.log("Re-RENDER the Chat Window!");
return false; // props are not equal -> update the component
});
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../shared/data/conectereConfigData';

//Amplify, React & Context
import React, { useContext } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Context
import { AuthContext } from '../../shared/context/authContext';
import { LeaderboardContext } from '../../shared/context/leaderboardContext';


//Components
import { CustomerLeaderBoards } from '../../shared/Components/leaderboard/leaderboard';

//React Component for this page
const EmployeeLeaderboardsPage = () => {

   //Context
    const { authState, currentUser, isSuperAdmin } = useContext(AuthContext);

  
    //Handle access by unauthenticated user
    if (authState !== "signedin"  || !currentUser) {
      return null;
    }
    
 //Render function for authenticated user  
 return (
    <Authenticator>	 
        <CustomerLeaderBoards />
    </Authenticator>	 
  );
};

export default EmployeeLeaderboardsPage;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//


import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, HEADER_FULL_MODE, COLOR_GOLD, COLOR_BLUE_HEADER, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR, COLOR_WHITE,
    CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD, GIPHY_ID_IDENTIFIER  } from '../../data/conectereConfigData';
 
 //Amplify, React
 import React, { useCallback, useEffect, useState, useContext, useRef, useLayoutEffect} from 'react';
 
 //Context
 import { DisplayContext } from '../../context/displayContext';            
 import { LeaderboardContext } from '../../context/leaderboardContext';            //Customer Authentication context
 import { AuthContext } from '../../context/authContext';
 
 //Our Components
 import { ChatWindow } from "../chatWindow/chatWindow";
 import StarRatings  from "../../Components/starRatings/starRatings";
 import ConectivityDurationTimer from '../conectivityDurationTimer/conectivityDurationTimer';
 import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
 import { LeaderBoardList } from "../../Components/leaderboard/leaderboard";
 // import ChallengeDataEditor from "../../Components/challengeDataEditor/challengeDataEditor";
 // import ModalGeneral from "../../Components/modalGeneral/modalGeneral";
 
 //Utils
 import { doesConectivityHaveInvitationForUser, displayInvitees, displayInviteesV2 } from "../../utils/invitationUtils";
 import { setConectivityImageUrlByConectivity, setHeaderColorByScheduledConectivity, setBorderColorClassByCategory, setBackgroundColorClassByCategory, setHeaderColorByCategoryWithOpacity, expiredConectivity } from "../../utils/conectivityUtils";
 import { getInvitationInstanceIndex, getInvitationDateTime, getLaunchRuleDateTime, getRecurrenceRuleNextLaunchDate, formatInvitationInstanceDateTime, getrecurrenceRuleLaunchDates } from "../../utils/dateTimeUtils"; 
 import { OpenButton, truncateStringWithDots, compareByCreatedAt } from "../../utils/generalUtils";
 import moment from 'moment';
 import { v4 as uuidv4 } from 'uuid'; 
 import parse from "html-react-parser";              //Needed to render HTML from the Editor
 
 //Icons
 import { IconButton } from '@mui/material';
 import MoreVertIcon from '@material-ui/icons/MoreVert';
 import AddIcon from '@mui/icons-material/Add';
 import DoneIcon from '@material-ui/icons/Done';
 import MessageIcon from '@mui/icons-material/Message';
 import SendIcon from '@mui/icons-material/Send';
 import CloseIcon from '@mui/icons-material/Close';
 import {Edit, DeleteOutline} from '@material-ui/icons';
 import MergeIcon from '@mui/icons-material/Merge';
 import PanToolIcon from '@mui/icons-material/PanTool';
 import MenuItem from '@mui/material/MenuItem';
 import MenuList from '@mui/material/MenuList';
 import NotInterestedIcon from '@material-ui/icons/NotInterested';
 import StarIcon from '@mui/icons-material/Star';
 
 //MUI
 import CircularProgress from '@mui/material/CircularProgress';
 import ModalNoBackgroundFixed from '../modalNoBackgroundFixed/modalNoBackgroundFixed';
 
 
 export const InvitationsV2 = ({
    currentUser, scheduledConectivity, invitationsWithThisUser, editInvitationCallback, deleteInvitationCallback, mergeInvitationsCallback, declineInvitationCallback,
    closeAllSubMenusToggle, idOfSubMenutoDisplay, setIdOfSubMenutoDisplay, addBottomBorder, newInvitationCallback,
    pendingInvitationUpdate, headerColor, handleShowMessages}) => {
 
 
 // Display each inviation as a different row on the Conectivity Card
 // handleMergeInvitations is a CallBack from a user menu selection for merging all invitations
 // Note, we add a scroll bar if necessary
 
 // if (DEBUG_MODE >= 2) console.log("Rendering Invitations for this user", invitationsWithThisUser, " Close All Sub Menus:", closeAllSubMenusToggle);
 
 //PENDING UPATE - SHOW RINGS
 //If pending update, do not show an invitation but instead show our cool 4 rings in it's place
 const pendingRingSize = "1.5rem";
 if (pendingInvitationUpdate) return (
 <div className="ContainerVerticalCenter positionRelative wrap fullWidth conectivityCardInvitationsRow"  >
    <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-40%,-85%)"}}><CircularProgress style={{color: GROWTH_COLOR}} size={pendingRingSize}/></div>
    <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-90%,-60%)"}}><CircularProgress style={{color: SOCIAL_COLOR}} size={pendingRingSize}/></div>
    <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-5%,-35%)"}}><CircularProgress style={{color: BALANCE_COLOR}} size={pendingRingSize} /></div>
    <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-55%,-5%)"}}><CircularProgress style={{color: TEAM_COLOR}} size={pendingRingSize} /></div>
 </div>   
 );
 
 //NO INVITATIONS - SHOW INVITE BUTTON
 if (invitationsWithThisUser.length === 0) return (
    <div className="ContainerNoHeightFlexLeft fullWidth" >
          <button onClick={(e) => newInvitationCallback(e,scheduledConectivity)} className="buttonStyle2 btn-invite-V2 border-none white" style={{backgroundColor:headerColor, zIndex:'0'}} >  Invite colleagues  </button>
     </div>                
 );
 
 //INVITAITONS TO SHOW
 return (
 
    <div className="ContainerNoHeightFlexLeftFlexStart noWrap white-background isCard" style={{overflowX : (invitationsWithThisUser.length > 1 ? "auto" : "unset")}} >
 
       {invitationsWithThisUser.map((invitation, index) => ( 
 
             <Invitation 
                key={uuidv4()} 
                invitation={invitation} 
                currentUser={currentUser}
                scheduledConectivity={scheduledConectivity} 
                editInvitationCallback={editInvitationCallback}
                deleteInvitationCallback={deleteInvitationCallback}
                mergeInvitationsCallback={mergeInvitationsCallback} 
                declineInvitationCallback={declineInvitationCallback}
                idOfSubMenutoDisplay={idOfSubMenutoDisplay} 
                setIdOfSubMenutoDisplay={setIdOfSubMenutoDisplay} 
                closeAllSubMenusToggle={closeAllSubMenusToggle} 
                numberInvitationsWithThisUser={invitationsWithThisUser.length}
                handleShowMessages={handleShowMessages}
             />
 
       ))}
    </div>
 );
 }  
 
 export const Invitation = React.memo(({
 invitation, currentUser, scheduledConectivity, editInvitationCallback, deleteInvitationCallback, declineInvitationCallback,
 mergeInvitationsCallback, closeAllSubMenusToggle, idOfSubMenutoDisplay, setIdOfSubMenutoDisplay, numberInvitationsWithThisUser, handleShowMessages}) => {
 
 // Display context
 const { darkMode } = useContext(DisplayContext); 
 
 const currentUserIsSender = (invitation && currentUser ? invitation.senderID === currentUser.id : false);
 const minMenuHeight = setMinimumMenuHeight(currentUserIsSender, numberInvitationsWithThisUser);
 
 const [displayInvitationSubMenuFlag, setDisplayInvitationSubMenuFlag] = useState(invitation.id == idOfSubMenutoDisplay ? true : false); 
 const [showUserHomeDeleteInvitationConfirmation, setShowUserHomeDeleteInvitationConfirmation] = useState(false);
 const [dynamicMenuHeight, setDynamicMenuHeight] = useState(invitation.id == idOfSubMenutoDisplay ? minMenuHeight : 0);        //Need to initialize correctly or a re-render will clear this
 const [menuHeight, setMenuHeight] = useState(0);
 const [containerHeight, setContainerHeight] = useState(0);
 
 //TODO - Handle these two functions differently; We are invoking the Recurrence Rule twice just to generate two different strings
 const [invitationInstanceDate, setInvitationInstanceDate] =useState("");
 const [invitationInstanceIndex, setInvitationInstanceIndex ] = useState(1);
 const [invitationInstanceDateTimeFormatted, setInvitationInstanceDateTimeFormatted] =useState("");
 
 //Adjust for whether or not to display MERGE menu item && both edit & delete for sender
 function setMinimumMenuHeight(currentUserIsSender, numberInvitationsWithThisUser) {
 if (currentUserIsSender && numberInvitationsWithThisUser < 2) return 90;
 if (currentUserIsSender && numberInvitationsWithThisUser >= 2) return 120;
 if (!currentUserIsSender && numberInvitationsWithThisUser < 2) return 60;
 if (!currentUserIsSender && numberInvitationsWithThisUser >= 2) return 90;
 return 120;
 }
 
 //On any change to invitation prop, calculate the instance date/time and index, if a recurring LR
 useEffect(() => {
 if (invitation && invitation.launchRule) {
 
    //Determine Invitation Instance Date/Time && Index
    // if (DEBUG_MODE >= 2) console.log("UseEffect - Storing Invitation Instance Date/Time", invitation);
    if (!invitation.launchRuleInstanceIndex || !invitation.launchRuleInstanceStartDateTime || !invitation.launchRuleInstanceEndDateTime) {
       if (DEBUG_MODE >= 1) console.error("ERROR - Invitation does not include instance information", invitation);
       return;
    }
 
    setInvitationInstanceIndex(invitation.launchRuleInstanceIndex);
    setInvitationInstanceDate(invitation.launchRuleInstanceStartDateTime);
    setInvitationInstanceDateTimeFormatted(formatInvitationInstanceDateTime({eventStartDateTime:invitation.launchRuleInstanceStartDateTime, eventEndDateTime:invitation.launchRuleInstanceEndDateTime, allDayEvent:invitation.launchRule.allDayEvent, timeZoneCode:currentUser.timeZoneCode}));
    
    /*
    let invitationInstanceDateTime = getInvitationDateTime(invitation);
    let tempInstanceDateTimeTrimmed = formatInvitationInstanceDateTime(invitation.launchRule, invitationInstanceDateTime);
    
    setInvitationInstanceDate(invitationInstanceDateTime);
    setInvitationInstanceDateTimeFormatted(tempInstanceDateTimeTrimmed);
    if (DEBUG_MODE >= 2) console.log("Invitation Instance Date/Time set", invitationInstanceDateTime, tempInstanceDateTimeTrimmed);
 
    let instanceIndex = getInvitationInstanceIndex({invitation:invitation, invitationInstanceDateTime:invitationInstanceDateTime});
    setInvitationInstanceIndex(instanceIndex);
    */
 }
 }, [invitation]);   
 
 /*    
 //React to any change in state of the sub menu toggle prop from parent; if flipped, close our sub-menu
 useEffect(() => {
 // handleCancelSubMenu();   //Eliminated the Toggle as it was triggering on every re-render
 }, [closeAllSubMenusToggle]);   
 */
 //React to any change in state of the sub menu toggle prop from parent; if flipped, close our sub-menu
 useEffect(() => {
    // if (DEBUG_MODE >= 2) console.log("Display Invitation UseEffect called", invitation.id, idOfSubMenutoDisplay)
    if (idOfSubMenutoDisplay == invitation.id) setDisplayInvitationSubMenuFlag(true);
    else setDisplayInvitationSubMenuFlag(false);
 }, [idOfSubMenutoDisplay]);                //Note, reload this whenever authState changes state!
 
 //Dynamically adjust the container heights for this invitation's sub-menu
 useEffect(() => {
    // if (DEBUG_MODE >= 2) console.log("Recomputing container heights. Dynamic Height:", dynamicMenuHeight);
    setMenuHeight(dynamicMenuHeight == 0 ? minMenuHeight.toString() + "px" : dynamicMenuHeight.toString() + "px");                    //Limit lower height so as to make smooth transition when closing outer DIV
    setContainerHeight(dynamicMenuHeight == 0 || !displayInvitationSubMenuFlag ? "0px" : (dynamicMenuHeight+20).toString() + "px");    //If not zero, add a bit of space beyond menu size for rounded corners
 
 }, [dynamicMenuHeight]);
 
 
 const headerColor = setHeaderColorByScheduledConectivity(scheduledConectivity); 
 const backgroundClass = setBackgroundColorClassByCategory(scheduledConectivity.conectivity.category.label);
 
 // if (DEBUG_MODE >= 2) console.log("Invitation rendering", invitation.id, " ID of Menu to Display:", idOfSubMenutoDisplay);
 
 // Inner functions within a particular Display Invitation component so we can access props
 
 //Handle menu selection - NOTE the SCOPE is INSIDE the SubMenuDisplay so we can access the props including the specific invitation and scheduled conectivity to handle
 const handleInvitationSubMenuSelection = (e) => {
 
 if (e) e.stopPropagation();        //Prevent outer Card OnClick from firing 
 
 const menuAction  = e.currentTarget.dataset.value;
 if (DEBUG_MODE >= 2) console.log("Invitation Sub Menu item selected.", menuAction, invitation.id);
 setDisplayInvitationSubMenuFlag(false);                          //Close the sub menu
 setIdOfSubMenutoDisplay("");                                     //Clear registration with parent
 switch (menuAction) {
    case "EDIT":
       if (editInvitationCallback) editInvitationCallback(invitation, scheduledConectivity, invitationInstanceDate, invitationInstanceIndex);        //simply pass the event on to our other handler, which pops up the modal for EDIT
       break;
    case "DECLINE":
       // handleDeclineInvitation(e);   10.21.2023 - replaced with callback to our Launcher
       if (declineInvitationCallback) declineInvitationCallback(e, invitation, scheduledConectivity, invitationInstanceDate);          //simply pass the event on to our other handler, which pops up the modal for EDIT
       break;
    case "MERGE":
       if (mergeInvitationsCallback) mergeInvitationsCallback(e, scheduledConectivity);
       break;
       
    case "DELETE":
       // setDynamicMenuHeight(numberInvitationsWithThisUser < 2 ? 140 : 170);    //Dynamically increase sub-menu size for Delete confirmation
       // setShowUserHomeDeleteInvitationConfirmation(true);                   //Show additional confirmation area
       if (deleteInvitationCallback) deleteInvitationCallback(e, invitation, scheduledConectivity, invitationInstanceDate);          //simply pass the event on to our other handler, which pops up the modal for EDIT
       break;
 } 
 };        
 
 //Functions for invitation processing sub-menu
 //Component for displaying invitees and messages for a specific invitation
 function displayUnreadMessageBadge ({invitation, scheduledConectivity, currentUser}) {
 
 //Safety check
 if (!invitation || !scheduledConectivity || !currentUser) return null;
 
 var unreadMessageCount = 0;
 var lastReadDateTime = "";
 
 if (currentUserIsSender) lastReadDateTime = invitation.messagesReadAt;
 
 else {
    const userInvite = invitation.invitees.items.find (invitee => invitee.inviteeID === currentUser.id);
    
    if (typeof userInvite !="undefined") {
       lastReadDateTime = userInvite.messagesReadAt;
    }
 }
 
 // Where we able to set the last read time?  If not, then must be an error
 if (lastReadDateTime == "") return null;
 
 // if (DEBUG_MODE >= 2) console.log("Checking for unread messages.  Last read date:", lastReadDateTime);
 
 const lastReadDateTimeMoment = moment(lastReadDateTime, "YYYY-MM-DDTHH:mm:ssZ");
 
 for (var j=0; j<invitation.messages.items.length; j++) {
    
    const messageDateTimeMoment = moment(invitation.messages.items[j].createdAt, "YYYY-MM-DDTHH:mm:ssZ");
 
    // if (DEBUG_MODE >= 2) console.log("Checking messages.  Last read timestamp:", lastReadDateTimeMoment.format("YYYY-MM-DDTHH:mm:ssZ"));
    // if (DEBUG_MODE >= 2) console.log("Checking messages.  Message created timestamp:", messageDateTimeMoment.format("YYYY-MM-DDTHH:mm:ssZ"));
    
    if (messageDateTimeMoment.isAfter(lastReadDateTimeMoment)) unreadMessageCount++;
 
 }
 
 //Any unread messages?
 if (unreadMessageCount == 0) return null;
 
 return (
       <div className="ContainerNoHeightCenter unreadMessageBadge white bold TextStyle3" >
         {unreadMessageCount}
       </div>
 );
 }    
 
 function displayHoverMessages(invitation) {
 // build out temp messages array (sort, iterate etc)
 const tempMessageList = [... invitation.messages.items];
 
 // if (DEBUG_MODE >= 2) console.log("Messages before sort", tempMessageList);
 
 tempMessageList.sort(compareByCreatedAt);
 
 // if (DEBUG_MODE >= 2) console.log("Messages after sort", tempMessageList);
 
 const tempMessagesToShow = tempMessageList.slice(-1);
 
 return (
    <div>
       {tempMessagesToShow.map((target, index) => (
          <div className="TextStyle2 white" key={uuidv4()}>
             {target.senderAvatarInitials + ": " + (target.message.includes(GIPHY_ID_IDENTIFIER) ? "GIF" : truncateStringWithDots(target.message, 15))}
          </div>
       ))}
    </div>
    );
 }
 
 //Function for displaying a submenu for a given invitation if the invitation ID matches the state data based on user input  
 const displayInvitationSubMenu = () => {
 
 //   if (DEBUG_MODE >= 2) console.log("Rendering Submenu.  Display menu=", displayInvitationSubMenuFlag, numberInvitationsWithThisUser, menuHeight, dynamicMenuHeight, containerHeight);
 
 
 return (
       <div key={uuidv4()} className={"conectivityCardInvitationSubMenuContainer"} style={{height:containerHeight, overflow:displayInvitationSubMenuFlag ? "visible":"hidden"}}>
       
          <div style={{position:"absolute", top:"6px", right:"10px", zIndex:"1"}}><CloseIcon onClick={handleCancelSubMenu} style={{color:headerColor, backgroundColor:"white", fontSize:"12px"}}/></div>
 
          <div className={`conectivityCardInvitationSubMenuConnector  ${(displayInvitationSubMenuFlag)  && "showUserHomenvitationSubMenuConnector"} `} >
             <div style={{color:headerColor}}> &#9650; </div>
          </div>
 
           <div className="userHomeInvitationSubMenu" style={{backgroundColor:headerColor, height:menuHeight}}>
             <MenuList >
             {currentUserIsSender ?    
                //Display on if this user IS the sender
                <MenuItem onClick={handleInvitationSubMenuSelection} data-value={"EDIT"}>
                   <div className="ContainerNoHeight"> <Edit /> <div style={{paddingLeft:"3px"}}>Edit Invitation</div></div> 
                </MenuItem>
 
             : 
                //Display on if this user is NOT the sender
                <MenuItem onClick={handleInvitationSubMenuSelection} data-value={"DECLINE"}>
                   <div className="ContainerNoHeight"> <NotInterestedIcon /> <div style={{paddingLeft:"3px"}}>Decline Invitation</div></div> 
                </MenuItem>   
             }    
 
             {numberInvitationsWithThisUser > 1 ?    
                <MenuItem onClick={handleInvitationSubMenuSelection} data-value={"MERGE"}>
                   <div className="ContainerNoHeight"> <MergeIcon /> <div style={{paddingLeft:"3px"}}>Merge Invitations</div></div> 
                </MenuItem>    
             : null }    
 
             {currentUserIsSender ?    
                //Display on if this user IS the sender
                <MenuItem onClick={handleInvitationSubMenuSelection} data-value={"DELETE"}>
                   <div className="ContainerNoHeight"> <DeleteOutline /> <div style={{paddingLeft:"3px"}}>Cancel Invitation</div></div> 
                </MenuItem>
             : null }    
 
                <div className={`userHomeInvitationSubMenuConfirmation ${(showUserHomeDeleteInvitationConfirmation)  && "showUserHomeInvitationSubMenuConfirmation"} `} >
                   <div className="ContainerNoHeightSpaceAround">
                      <div className="userHomeInvitationSubMenuConfirmationText" >Delete?</div>
                      <div className="ContainerNoHeightSpaceAround"  style={{width:"100%"}}>
                         <button className="userHomeButtonStyle3" onClick={(e) => deleteInvitationCallback(e, invitation, scheduledConectivity)}  >
                            <DoneIcon style={{fontSize:"16px"}}/> YES </button>
                         <button className="userHomeButtonStyle3" onClick={handleVerticalMorePress}> 
                            <CloseIcon style={{fontSize:"16px"}}/>NO </button>
                      </div>                                        
                   </div>
                </div>
             </MenuList>
          </div>
             
 
       </div>
 
       
 );        
 
 };
 
 const handleCancelSubMenu = (e) => {
 // if (DEBUG_MODE >= 2) console.log("Cancelling invitation menu");
 if (e) e.stopPropagation();                     //Prevent outer Card OnClick from firing 
 setDisplayInvitationSubMenuFlag(false);                 //Close the sub menu
 setIdOfSubMenutoDisplay("");                                     //Clear registration with parent
 setShowUserHomeDeleteInvitationConfirmation(false);     //Show additional confirmation area
 };        
 
 function handleVerticalMorePress (e) {
 
 e.stopPropagation();        //Prevent outer Card OnClick from firing 
 
 // if (DEBUG_MODE >= 2) console.log("User selected a submenu", invitation.id, displayInvitationSubMenuFlag); //record the ID of the invitation for which to display the submenu
 
 //Test whether the particular invitation submenu is already open.  If so, close it (toggle)
 if (displayInvitationSubMenuFlag) {
    if (DEBUG_MODE >= 2) console.log("Closing sub menu", invitation.id);
    setShowUserHomeDeleteInvitationConfirmation(false);          //Hide additional confirmation area
    setDisplayInvitationSubMenuFlag(false);                     //Close the sub menu
    setIdOfSubMenutoDisplay("");                                     //Clear registration with parent
    setDynamicMenuHeight(0);
 }
 else {
    if (DEBUG_MODE >= 2) console.log("Showing sub menu", invitation.id);
    setShowUserHomeDeleteInvitationConfirmation(false);          //Hide additional confirmation area
    setDynamicMenuHeight(numberInvitationsWithThisUser < 2 ? 120 : 150);
    setDisplayInvitationSubMenuFlag(true);                      //Show the sub menu
    setIdOfSubMenutoDisplay(invitation.id);                     //Clear registration with parent
 
 }
 
 }    
 
 if (!invitation || !invitation.launchRule || invitation.invitees.items.length == 0  && !invitation.senderUrl ) return null;
 
 // const numInviteesToShow = (showModalConectivityDetailed ? 7 : 3);
 const numInviteesToShow = 3;
 
 //Safety check
 if (!invitation || !currentUser || !scheduledConectivity) return null;
 
 //Render the Invitation Row        
 return (
    <div className="ContainerVerticalCenter positionRelative wrap conectivityCardInvitationWrapper connectivityCardInvitationWrapperV2 margin-none" style={{color: headerColor}} >
        <div className={`ContainerNoHeightCenter conectivityCardAvatarWrapper positionRelative  `}   >
          {displayInviteesV2({headerColor:headerColor, invitation:invitation, numInviteesToShow:numInviteesToShow })}
          
          {/* { handleShowMessages ? 
             <>
                <div className="displayRelative conectivityCardChatMessageButton" >
                   <IconButton size="small" aria-label="skip"  onClick={(e) => handleShowMessages(e, invitation, scheduledConectivity, false)}  >
                      <MessageIcon className='TextStyle5' style={{color: headerColor}}/>
                   </IconButton>
                   {displayUnreadMessageBadge({invitation:invitation, scheduledConectivity:scheduledConectivity, currentUser:currentUser})}
                </div>
 
 
                {invitation.messages.items.length == 0 ? "" : <span className="messageButtonHover" style={{background: headerColor}}>{displayHoverMessages(invitation)}</span>}
             
             </>
          :
             null
          } */}
          <div className="userHomeProcessMultipleInvitationsButtonAnchor" >
             {!currentUser || doesConectivityHaveInvitationForUser(scheduledConectivity, currentUser.id)==false ? null :        
               <IconButton aria-label="skip" size="small" onClick={(e) => handleVerticalMorePress(e) }  id={invitation.ID} >
                   <AddIcon  className="TextStyle4V2" style={{color: TEAM_COLOR}} />
                </IconButton>
              }   
           </div>
        </div>
 
       {displayInvitationSubMenu()}
       
    
    {/* <div className={`TextStyle2  ${darkMode ? headerColor : "black"}  `}  > {invitationInstanceDateTimeFormatted ? invitationInstanceDateTimeFormatted : null} </div> */}
  </div>
 );
 });  //Wrapped in React.Memo
 
 
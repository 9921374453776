//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import "./dashboardCoulson.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COULSON_ALERT_THRESHOLD} from '../../shared/data/conectereConfigData';


import React, { useEffect, useState, useContext } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries needed for analytics
import {  getCCTransactionsByUserByDate } from '../../shared/graphql/queries';
import { getUserEngagementSignalByCustomer} from '../../shared/graphql/queries';


//Bootstrap and other React components
import Switch from '@material-ui/core/Switch';

//Graphing components
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context

//Utils
import { setConectivityImageUrlByCategory, setHeaderColorRandomCategory } from "../../shared/utils/conectivityUtils";
import {fetchRecordsByPrimaryKey, queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import { compareByAlertTimestamp  } from "../../shared/utils/generalUtils";
import { DashboardHeader } from "../../shared/utils/dashboardUtils";

import moment from 'moment';

//For testing
// import {couslonData, couslonEnagementSignalTestData} from "./Components/testData" 


//Main React component
const DashboardCoulson = () => {
  

    //State variables 
    const [userEnagementSignals, setUserEnagementSignals] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [transactionsForUsers, setTransactionsForUsers] = useState([]);
    //new vars for multi-select
    // const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);
    // const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);  //Note, this stores an array of objects of the select Options.  See react-select

    const [balanceConectivitiesOnGraph, setBalanceConectivitiesOnGraph] = useState(false);
    const [teamConectivitiesOnGraph, setTeamConectivitiesOnGraph] = useState(false);
    const [socialConectivitiesOnGraph, setSocialConectivitiesOnGraph] = useState(false);
    const [growthConectivitiesOnGraph, setGrowthConectivitiesOnGraph] = useState(false);

    
    const[radioValue, setRadioValue] = useState("TODAY");
    const[radioSortValue, setRadioSortValue] = useState("Most Active");
    const[checkedByCategory, setCheckedByCategory] = useState(false);
    const[radioByCoinValue, setRadioByCoinValue] = useState("Total Conectivities");
    const[showAlertThreshold, setShowAlertThreshold] = useState(true); //Toggle switch for whether or not to display employee images in the graph nodes
    const[showMissedConectivities, setShowMissedConectivities] = useState(false); //Toggle switch for whether or not to display an X over empty time slots
   
   // Authentication context
    const {
            authState,
            currentUser,
            isSuperAdmin,
            isAdmin,
            permissionAnalytics,
            permissionLaunch,
            permissionEditor,
            userWallet,
            triggerForgotPasswordProcess,
            
    } = useContext(AuthContext);    

   // Customer context
    const {
            customers, users, teams,
            categoryOptions,teamsOptions, customerOptions, usersOptions,
            selectedCustomerOptions,
    } = useContext(CustomerContext);  
   
        
  
    //Analytics context
    const {
        displayReportControls, selectedUsersOptions, 
    } = useContext(AnalyticsContext);
        
       

    //Update Coulson records on user login and any change to selected customers
    useEffect(() => {
        fetchCoulsonRecords();  
    }, [authState, users, teams]);      //Trigger DB record fetch ON ANY CHANGE TO AuthState OR users / teams 
    
    //grab data needed for rendering the dashboard
    async function fetchCoulsonRecords() {

        if (authState != "signedin") return;

        try {
            
             //Get records for entire company
            var tempUserEngagementSignals =  await fetchRecordsByPrimaryKey(getUserEngagementSignalByCustomer, "getUserEngagementSignalByCustomer","customerID", selectedCustomerOptions.id);

            //TESTING - USE TEST DATA FROM TEST FILE
            // if (DEBUG_MODE >= 2) console.log("Using Coulson Test data", couslonEnagementSignalTestData);
            // tempUserEngagementSignals = [...couslonEnagementSignalTestData]; //Copy test data
            
            //Sort the signal values in each signal
            for (var i=0; i < tempUserEngagementSignals.length; i++)
                tempUserEngagementSignals[i].engagementValues.items.sort(compareByAlertTimestamp);
            
            if (DEBUG_MODE >= 2) console.log("Loaded coulson records", tempUserEngagementSignals);
            setUserEnagementSignals(tempUserEngagementSignals);
            

            } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching Coulson records', err); }
          
     }


    //Update Coulson records on user login and any change to selected customers
    useEffect(() => {
        buildGraphData(); 
    }, [userEnagementSignals, selectedUsersOptions]);      //Trigger rebuild of graph data on change to user records or to user selection
    
    //grab data needed for rendering the dashboard
     async function buildGraphData() {
         
          if (DEBUG_MODE >= 2) console.log("Build graph data called", selectedUsersOptions, userEnagementSignals);

         const todayStart = moment().startOf('day');
         
         //Basic checks
         if (selectedUsersOptions == null || selectedUsersOptions == undefined) return;
         if (userEnagementSignals == null || userEnagementSignals == undefined) return;
 
         //Loop through selected users 
         const tempGraphData = [];
         
         for (var i=0; i<selectedUsersOptions.length; i++) {
             
             //For each selected user, get their engagement signal and generate line data
             const signalToProcess = userEnagementSignals.find(signal => signal.userID == selectedUsersOptions[i].id);
             
             if (DEBUG_MODE >= 2) console.log("Processing Coulson signal records", signalToProcess);
             
             if (signalToProcess != undefined) {
                 
                  //Fetch this user's recent transactions if we don't already have them cached locally
                 var tempTransactionsForSelectedUsers = [...transactionsForUsers]; //Copy
                 
                 if (tempTransactionsForSelectedUsers.some(transaction => transaction.userID == selectedUsersOptions[i].id) == false) {
     
                    // Determine oldest engagement value for this user
                    const oldestEngagementValueDateString = moment(signalToProcess.engagementValues.items[0].alertTimeStamp, "YYYY MM DDTHH mm ssZ").toISOString();             
                    const todayString =  moment().toISOString();
                    // if (DEBUG_MODE >= 2) console.log("Fetching user transactions; earliest momentum date " + oldestEngagementValueDateString);
     
                     //Now, grab transactions for this user
            
                    const queryParams = {
                                userID: selectedUsersOptions[i].id,                                         //Primary Key
                                createdAt: {between: [ oldestEngagementValueDateString, todayString ]},     //Secondary Key
                            };
                    
                    var  tempCCTransactions =  await queryDataTableWithPagination(getCCTransactionsByUserByDate, "getCCTransactionsByUserByDate", queryParams);
                    
                    
                    tempCCTransactions = tempCCTransactions.filter(transaction => transaction.transactionType =="COMPLETED_CONECTIVITY" );
                    
                    
                    tempTransactionsForSelectedUsers = [...tempTransactionsForSelectedUsers, ...tempCCTransactions];  //Build a new array of user transactions to include old plus any new
                    setTransactionsForUsers(tempTransactionsForSelectedUsers);  //Save
    
                    if (DEBUG_MODE >= 2) console.log("Fetched transactions for this user and updated master array: ", queryParams, tempCCTransactions, tempTransactionsForSelectedUsers);
 
                     } else {
                        if (DEBUG_MODE >= 2) console.log("Transactions for this user PREVIOUSLY FETCHED.  Master array unchanged ", tempTransactionsForSelectedUsers);
                     
                     }                   
                    
                
                     //Process each signal value and add as a point to our graph data
                     for (var j=0; j<signalToProcess.engagementValues.items.length ; j++) {
                         
                         //Did the user complete a transaction in the time slot for this signal?
                         const dayOfAlert = moment(signalToProcess.engagementValues.items[j].alertTimeStamp, "YYYY MM DDTHH mm ssZ");
                         const dayOfAlertString = dayOfAlert.toISOString().substr(0,10);
                         const futureValueFlag = dayOfAlert.isAfter(todayStart); //Are we in the future?
                         if (DEBUG_MODE >= 2) console.log("Checking for user transaction on:" + dayOfAlertString, futureValueFlag);
                        const matchingUserTransactions = tempTransactionsForSelectedUsers.filter( transaction => (transaction.createdAt.substr(0,10) == dayOfAlertString && transaction.userID == selectedUsersOptions[i].id));
                        
                        updateLinePoint(tempGraphData, selectedUsersOptions[i].avatarInitials, signalToProcess, signalToProcess.engagementValues.items[j], matchingUserTransactions, futureValueFlag);
                         
                     }
                     
                 }
             
         }
         
         if (DEBUG_MODE >= 2) console.log("Built graph data", tempGraphData);
         
         setGraphData(tempGraphData);
        
    }

    function updateLinePoint (graphData, userID, signalToProcess, value, matchingUserTransactions, futureValueFlag) {
        
        if (DEBUG_MODE >= 2) console.log("Updating point", graphData, userID, signalToProcess, value, matchingUserTransactions);
        
        var conectivityComplete = false;
        
        if (matchingUserTransactions.length > 0) conectivityComplete = true;
        
        const balanceConectivityComplete = matchingUserTransactions.some(transaction => transaction.category == "STRESS");
        const teamConectivityComplete = matchingUserTransactions.some(transaction => transaction.category == "TEAM");
        const socialConectivityComplete = matchingUserTransactions.some(transaction => transaction.category == "SOCIAL");
        const growthConectivityComplete = matchingUserTransactions.some(transaction => transaction.category == "PERSONAL");
       
        const roundedMomentum = Math.round(value.momentum * 100) / 100;
        
        //Does this point already exist in our array of points?
        const dateKey = value.alertTimeStamp.substr(5,5);
        var index = graphData.findIndex(point => point.name == dateKey);
        
        if (value.conectivityCompleted == undefined) value.conectivityCompleted = false;
        
        //Nope, add it
        if (index == -1) {
            console.log ("New DateKey=", dateKey);
            const newPoint = {name:dateKey, category:signalToProcess.category};
            newPoint[userID] = roundedMomentum; //Set attribute using the userID
            newPoint[userID+"alert"] = value.alert; //Add new attribute whether an alert for this user
            newPoint[userID+"futureValueFlag"] = futureValueFlag; //Add new attribute whether an this point is in the future
            newPoint[userID+"conectivityCompleted"] = conectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            newPoint[userID+"balanceConectivityComplete"] = balanceConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            newPoint[userID+"teamConectivityComplete"] = teamConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            newPoint[userID+"socialConectivityComplete"] = socialConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            newPoint[userID+"growthConectivityComplete"] = growthConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user

            graphData.push(newPoint);
            
            //Update flags that control our legend
            if (signalToProcess.category == "STRESS") setBalanceConectivitiesOnGraph(true);
            if (signalToProcess.category == "TEAM") setTeamConectivitiesOnGraph(true);
            if (signalToProcess.category == "SOCAL") setSocialConectivitiesOnGraph(true);
            if (signalToProcess.category == "PERSONAL") setGrowthConectivitiesOnGraph(true);
            
        } else {    //Add data from this user value to the existing point
            graphData[index][userID] = roundedMomentum; //Add new attribute to the existing object in the array
            graphData[index][userID+"alert"] = value.alert; //Add new attribute whether an alert for this user
            graphData[index][userID+"futureValueFlag"] = futureValueFlag; //Add new attribute whether an this point is in the future
            graphData[index][userID+"conectivityCompleted"] = conectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            graphData[index][userID+"balanceConectivityComplete"] = balanceConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            graphData[index][userID+"teamConectivityComplete"] = teamConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            graphData[index][userID+"socialConectivityComplete"] = socialConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user
            graphData[index][userID+"growthConectivityComplete"] = growthConectivityComplete; //Boolean whether a conectivity of this type was completed on this date by the user

        }
        
    }

    //Function for creating or updating a point record; multiple lines may share the same point value, which are unique by X axis


    
    //User has elected to view specific employees (nodes) as source
    // const handleSelectUser = (eventKey) => {

    //   if (DEBUG_MODE >= 2) console.log("User selection updated", eventKey);
    //   setSelectedUsersOptions(eventKey);

    //  };


    //
    // Engagement Graph Component
    //

    const CoulsonLegend = () => {
    
    return (
        <center>
            <div className = "coulsonLegendWrapper">
                <div className = "ContainerNoHeightSpaceAround wrap">
                    <div className = "coulsonLegendItem">
                        <img className = "ccImgXL" src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH}  alt='' />  
                        <div className = "TextStyle2"> Balance Conectivity </div>
                    </div>

                    <div className = "coulsonLegendItem">
                        <img className = "ccImgXL" src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH}  alt='' />  
                        <div className = "TextStyle2"> Social Conectivity </div>
                    </div>

                    <div className = "coulsonLegendItem">
                        <img className = "ccImgXL" src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH}  alt='' />  
                        <div className = "TextStyle2"> Team Conectivity </div>
                    </div>
                    
                    <div className = "coulsonLegendItem">
                        <img className = "ccImgXL" src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH}  alt='' />  
                        <div className = "TextStyle2"> Growth Conectivity </div>
                    </div>
                    
                    <div className = "coulsonLegendItem">
                        <div className = "">
                         <svg className = "" width={20} height={20}  >
                            <circle cx="10" cy="10" r="10" strokeWidth="1" fill="green" />
                            <circle cx="10" cy="10" r="7" strokeWidth="1" fill="white" />
                        </svg>
                           </div>
                        <div className = "TextStyle2"> Predicted Employee Engagement </div>
                        <div className = "TextStyle1"> <i>AI generated - may be inaccurate </i>  </div>
                    </div>
 
                    <div className = "coulsonLegendItem">
                        <div className = "">
                             <svg className = ""width={20} height={20} fill="red" xmlns="http://www.w3.org/2000/svg"  >
                                    <polygon points="0,0 20,0 10,20"/>
                              </svg> 
                          </div>
                        <div className = "TextStyle2"> ALERT - Predicted Low Level </div>
                    </div>                      
                    
                </div>
            </div>
        </center>
        
        );
    };
    
    
    const EngagementGraph = ({data, title, height, width, showAlertThreshold, alertThreshold}) => {
    
        if (data == null || data == undefined) return null;
        if (height == null || height == undefined) height = 500;
        if (width == null || width == undefined) width = 800;
        
        var alertYPos = Math.round(height * alertThreshold / 100) + 15; //Map the percent into a height position of the DIV; add offset for Labels
        
        if (selectedUsersOptions.length > 0) alertYPos += 20;
        
        // if (DEBUG_MODE >= 2) console.log("Alert Y Position", alertYPos);
    
    
        if (DEBUG_MODE >= 2) console.log("Engagement Graph called");
        if (DEBUG_MODE >= 2) console.log("data", data);
        
        // if (DEBUG_MODE >= 2) console.log("dataKeyX", dataKeyX);
        // if (DEBUG_MODE >= 2) console.log("dataKeyY1", dataKeyY1);
        // if (DEBUG_MODE >= 2) console.log("dataKeyY2", dataKeyY2);
        // if (DEBUG_MODE >= 2) console.log("dataKeyY3", dataKeyY3);
        // if (DEBUG_MODE >= 2) console.log("grid", grid);
        // if (DEBUG_MODE >= 2) console.log("width", width);
        // if (DEBUG_MODE >= 2) console.log("height", height);
 
 
        // Customized DOT component
        const CustomizedDot = (props) => {
            
          const { cx, cy, stroke, payload, value, dataKey } = props;
          
          const alertKey = dataKey + "alert";
          const conectivityCompletedKey = dataKey + "conectivityCompleted";
          const futureValueFlagKey = dataKey + "futureValueFlag";
          
        //   if (DEBUG_MODE >= 2) console.log("DOT called", props, alertKey, props.payload[alertKey], dataKey, futureValueFlagKey);
          
          
        //First, check whether this DOT is a future (predicted) value 
        const futureValueFlag = props.payload[futureValueFlagKey];
        
        if (futureValueFlag) {
            // if (DEBUG_MODE >= 2) console.log("Constructing DOT for a future value", props.payload.name);
                // Make a small green circle with a white center
                return (
                    <svg x={cx - 10} y={cy - 10} width={20} height={20} >
                        <circle cx="10" cy="10" r="10" stroke-width="1" fill="green" />
                        <circle cx="10" cy="10" r="7" stroke-width="1" fill="white" />
                    </svg>
                    );
        }

        
        //Return DOT based on whether the user completed a conectivity on this day
        const completedConectivityToday = props.payload[conectivityCompletedKey];
 
 
        //If completed a conectivity, return Conectivity Image based on caegory
        if (completedConectivityToday && props.payload[dataKey + "growthConectivityComplete"]) {
            
            const imageURL = setConectivityImageUrlByCategory("PERSONAL", true); //Get color version of the Conectivity image
            // if (DEBUG_MODE >= 2) console.log("Set team image ULR", imageURL);
            
            return (
                    <image x={cx - 10} y={cy - 10} width={20} height={20} xlinkHref={imageURL}/>
                );
            
        } else if (completedConectivityToday && props.payload[dataKey + "socialConectivityComplete"]) {
            
            const imageURL = setConectivityImageUrlByCategory("SOCIAL", true); //Get color version of the Conectivity image
            // if (DEBUG_MODE >= 2) console.log("Set social image ULR", imageURL);
            
            return (
                    <image x={cx - 10} y={cy - 10} width={20} height={20} xlinkHref={imageURL}/>
                );
            
        } else if (completedConectivityToday && props.payload[dataKey + "teamConectivityComplete"]) {
            
            const imageURL = setConectivityImageUrlByCategory("TEAM", true); //Get color version of the Conectivity image
            // if (DEBUG_MODE >= 2) console.log("Set personal image ULR", imageURL);
            
            return (
                    <image x={cx - 10} y={cy - 10} width={20} height={20} xlinkHref={imageURL}/>
                );
            
        } else if (completedConectivityToday && props.payload[dataKey + "balanceConectivityComplete"]) {
            
            const imageURL = setConectivityImageUrlByCategory("STRESS", true); //Get color version of the Conectivity image
            // if (DEBUG_MODE >= 2) console.log("Set stress image ULR", imageURL);
            
            return (
                    <image x={cx - 10} y={cy - 10} width={20} height={20} xlinkHref={imageURL}/>
                );
            
        } else {
                //No conectivity, so show ALERT if an alert is prediced else show a slot miss
            
              //ALERT - A RED trianble
              if (props.payload[alertKey] == 1 && props.payload[dataKey] < COULSON_ALERT_THRESHOLD) {
                  return (
                   <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" xmlns="http://www.w3.org/2000/svg" className="svg-triangle" >
                        <polygon points="0,0 20,0 10,20"/>
                  </svg>             
                  );
              } else if (showMissedConectivities) {
                
                //Make a small red circle
                // return (
                //     <svg x={cx - 10} y={cy - 10} width={20} height={20} >
                //         <circle cx="10" cy="10" r="10" stroke-width="1" fill="red" />
                //     </svg>
                //     );
                    
                //Make a small red X
                return (
                    <svg x={cx - 10} y={cy - 10} width={20} height={20} >
                        <path d="M 5,5 L 15,15    M 5,15 L 15,5" stroke="red" stroke-width="2" />
                    </svg>                    
                    );
              } else return null;
        }
         
        /// NOT USED - JUST EXAMPLES
        
          //A GREEN smiley face 
          if (completedConectivityToday) {
            return (
              <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
              </svg>
            );
          }
        
         //A RED frowny face
          return (
            <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
              <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
            </svg>
          );
        };
 
     function displayLines() {
        return (
            <div>
                <Line type="monotone" dataKey="CHS" stroke="#5DADE2" dot={<CustomizedDot />}/>
                <Line type="monotone" dataKey="KJS" stroke="#BB8FCE" dot={<CustomizedDot />}/>
            </div>
            )
    }    
    
        return (
                <div className="coulsonChartWrapper">
                     <div className="coulsonChartTitle">
                        {title}
                     </div>
                     <div className="coulsonInnerWrapper">
                          <LineChart 
                              width={width} 
                              height={height} 
                              data={data}
                              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                              isAnimationActive={false}
    
                              >
                              
                              {selectedUsersOptions.map ((user) => (
                                <Line type="monotone" dataKey={user.avatarInitials} stroke={setHeaderColorRandomCategory()} dot={<CustomizedDot />}/>
                                ))}
                                
                            {!showAlertThreshold || false ? null :
                                <ReferenceLine y={alertThreshold} stroke="red" strokeDasharray="5 5" />
                            }
    
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <XAxis dataKey="name" />
                            <YAxis domain={[0, 100]} />
                            <Tooltip />
                            <Legend />                    
                          </LineChart>  
                          <div className="coulsonAlertThresholdLabel" style={{bottom:alertYPos}}>
                            AI Alert &#9654;
                          </div>
                      </div>
              </div>
                
            );
    };

    function handleAlertThresholdSwitch (event) {
    
        setShowAlertThreshold(!showAlertThreshold);
    }    
 
     function handleMissedConectivitiesSwitch (event) {
    
        setShowMissedConectivities (!showMissedConectivities);
        
        if (DEBUG_MODE >= 2) console.log("Updating show missed conectivities", showMissedConectivities);
    }     

    //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">COUSLSON</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else return (
    <div >
    
        <DashboardHeader header={'Iggy'} footer={'AI-POWERED MONITORING AND ALERTING'} color={TEAM_COLOR}/>
        
        {/* <DisplayUserSelectComponent usersOptions={usersOptions} handleSelectUser={setSelectedUsersOptions} isMulti={true} selectedUsersOptions={selectedUsersOptions} /> */}



         <div className="adminPage" style={{position:"relative"}}>
        
            {displayReportControls({ hideDisabledFields:true, hideTitle:true, useUserSelect:true, useTeamSelect:false, useDateSelect:false,  doNotShowAll:true, minHeight:'0px', noBoxShadow:true})}

            <div className="ContainerNoHeightCenter" style={{color:COLOR_BLUE_TEXT}}>
                <div>
                    <Switch checked={showAlertThreshold} onChange={handleAlertThresholdSwitch}/>
                    Show Alert Threshold
                </div>
                <div>
                    <Switch checked={showMissedConectivities} onChange={handleMissedConectivitiesSwitch}/>
                    Highlight Missed Conectivities
                </div>
            </div>

        </div>

        <EngagementGraph 
            data = {graphData}
            title = "AI-Computed Employee Engagement"
            showAlertThreshold = {showAlertThreshold}
            alertThreshold = {COULSON_ALERT_THRESHOLD}
            
        />
        
        <CoulsonLegend />                                   

    </div>
  );
}




export default DashboardCoulson;


                        //   <Line type="monotone" dataKey="CHS" stroke="#5DADE2" dot={<CustomizedDot />}/>
                        //     <Line type="monotone" dataKey="KJS" stroke="#BB8FCE" dot={<CustomizedDot />}/>

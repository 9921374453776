//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, DAVY_GREY, LIGHT_GREY, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_WHITE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, COLOR_GOLD, COLOR_SILVER, COLOR_BRONZE, COLOR_ALERT_OPAQUE, COLOR_WARNING_HIGH_OPAQUE, COLOR_WARNING_LOW_OPAQUE, COLOR_GOOD_OPAQUE, COLOR_ALERT, COLOR_WARNING_HIGH, COLOR_WARNING_LOW, COLOR_GOOD } from '../data/conectereConfigData';


//React & Amplify
import React from 'react';
import { useState, useRef, useEffect } from "react";
import {useLocation} from 'react-router-dom'
import parse from "html-react-parser";              //Needed to render HTML from the Editor


//Component for showing nice dashboard header
export const DashboardHeader = ({header, footer, color, hideBorder}) => {

   var styleObject = {};
   
   if (hideBorder) styleObject.background = "transparent";
   if (!color) color = TEAM_COLOR;

   return (
       <center>
           <div className="ContainerVerticalCenter dashboardBannerHeader" style={styleObject}>
               <div className="ContainerMaxHeightFlexLeft">
                   <div className = "ContainerVerticalCenter">
                       <div className="ContainerNoHeightCenter ">
                           <h2 style={{color:color}}> {header} </h2>
                       </div>
                       {footer && <h5 style={{color:color}}><i> {footer}</i></h5>}
                   </div>
               </div>
           </div>
       </center>

       );
};


export const DashboardCircle = ({value, title, titleClass, textClass, circleClass}) => {

   if (!textClass) textClass = 'TextStyle4 headerBlue';
   if (!titleClass) titleClass = 'TextStyle4 headerBlue';
   if (!circleClass) circleClass = 'dashboardScoreCircle';

   return (
       <div className="ContainerVertical" >
       <div className={titleClass}> {title} </div>
       <div className={circleClass}>
           <div className={textClass}> {value}  </div>
       </div>
   </div>
   )
}

export const DashboardRectangle = ({value, title, textClass, titleClass, rectangularClass}) => {

   if (!textClass) textClass = 'TextStyle4 headerBlue';
   if (!titleClass) titleClass = 'TextStyle4 headerBlue';
   if (!rectangularClass) rectangularClass = 'dashboardRectangle';

   return (
       <div className="ContainerVertical" >
            <div className={titleClass}> {title} </div>
            <div className={rectangularClass}>
                <div className={textClass}> {value}  </div>
            </div>
        </div>
   )
}
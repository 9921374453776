//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

/*

	FullCalendar 
		Docs: 
			https://fullcalendar.io/docs/react
		Event Click: 
			https://fullcalendar.io/docs/eventClick
		Event Drag & Drop:
			https://fullcalendar.io/docs/eventDragStart
			https://fullcalendar.io/docs/eventDragStop
			https://fullcalendar.io/docs/eventDrop
			https://fullcalendar.io/docs/editable
			https://fullcalendar.io/docs/event-dragging-resizing-demo
			https://codesandbox.io/s/react-fullcalendar-external-events-drag-and-drop-neoky?file=/src/index.js
			editable flag: dragged and resized
			eventDurationEditable: resizeable through dragging (grab right end and drag to following days)
			eventStartEditable: start times editable through dragging (move the whole event)


			FullCalendar handles all-day events in a way that they span from midnight to midnight in the user's local timezone. 
			When users in different time zones view the calendar, the all-day event will still appear to span the entire day in their respective time zones. 
			This means that the event will display correctly as an all-day event regardless of the viewer's timezone.

			Also note, Microsoft automatically changes an "All Day Event" to have start and end times at 000.000Z  (e.g., 2024-07-25T00:00:00.000Z) which is midnight UTC

			Handling all-day events that span multiple days in FullCalendar is straightforward. You need to specify both the start and end dates and 
			set the allDay property to true. The event will then span from the start date to the end date, inclusive of both dates.

			Full Calendar Key Points:
				Start and End Dates:

				The start date is the first day of the event.
				The end date is the day after the last full day of the event. For example, if the event spans from July 25 to July 27, you should set end to July 28.
				All-Day Property:

				Set the allDay property to true to indicate that the event spans entire days.

				Example:
							const events = [
								{
									title: 'Multi-Day All Day Event',
									start: '2024-07-25', // Start date
									end: '2024-07-28', // End date
									allDay: true, // Indicates an all-day event that does NOT include 7-28
								},
								{
									title: 'Timed Event',
									start: '2024-07-25T12:30:00', // Specific start time
									end: '2024-07-25T14:30:00', // Specific end time
								},
							];
							
*/

//Amplify, React
import  React, { useContext }from 'react';

//Config
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_WHITE } from '../../data/conectereConfigData';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
						
//Utils
import {  setConectivityImageUrlByCategory, setHeaderColorByCategory  } from "../../utils/conectivityUtils";
import { LaunchIcon, AddButton } from "../../utils/generalUtils";
import { getRSVPStatus }  from "../../utils/userAndTeamUtils";
import { formatInvitationInstanceDateTime } from "../../utils/dateTimeUtils";
import moment from 'moment';
					 
//Full Calendar React Component
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';

//Icons
import HubIcon from '@mui/icons-material/Hub';


//
//
// REACT CALENDAR VIEW (UI)
//
//


//Customized event rendering 

const renderEventContent = (eventInfo) => {
	if (DEBUG_MODE >= 3) console.log("Calendar event info", eventInfo);
	
	let category, viewType, color, rsvpStatus, allDayEvent=false, timeText = '';
	if (eventInfo.event && eventInfo.event._def && eventInfo.event._def.extendedProps) {

	 //Determine event category
		category = eventInfo.event._def.extendedProps.category;
		
		//Does the event span multiple days or a single all-day event?  In both cases we need to display the text a bit differently  
		allDayEvent = eventInfo.event._def.allDay;

		//Default to the "time" shown in the event
		timeText=eventInfo.timeText;

		//Check the actual start / stop date/times in case the allDay event flag is not set and the event spans midnight
		if (eventInfo.event.startStr && eventInfo.event.endStr) {
			let tempEventStartDateTimeMoment = moment(eventInfo.event.startStr);
			let tempEventEndDateTimeMoment = moment(eventInfo.event.endStr);
			// if (DEBUG_MODE >= 2) console.log("Rendering event - start & end times:" + tempEventStartDateTimeMoment.clone().toISOString() + " to " + tempEventEndDateTimeMoment.clone().toISOString());
			allDayEvent = tempEventEndDateTimeMoment.isAfter(tempEventStartDateTimeMoment,"day");  
			if (allDayEvent) {
				timeText = formatInvitationInstanceDateTime({eventStartDateTime:eventInfo.event.startStr, eventEndDateTime:eventInfo.event.endStr, allDayEvent, timeZoneCode:(eventInfo.event._def.timeZoneCode ? eventInfo.event._def.timeZoneCode : 'America/New_York')});
				if (DEBUG_MODE >= 2) console.log("Rendering event - All Day event detected");
			}
		}
 
		//Has the user declined this event?
		if (eventInfo.event._def.extendedProps.rsvpRecords && 
				eventInfo.event._def.extendedProps.rsvpRecords.length > 0 &&
				eventInfo.event._def.extendedProps.currentUserID) {
					
					rsvpStatus = getRSVPStatus(eventInfo.event._def.extendedProps.currentUserID, eventInfo.event._def.extendedProps.eventInstanceNumber, eventInfo.event._def.extendedProps.rsvpRecords); 
					// if (rsvpStatus && DEBUG_MODE >= 2) console.log("rsvpStatus", eventInfo.event.title, eventInfo.event._def.extendedProps.rsvpRecords, rsvpStatus);
		}
	}

	 
	//Determine user's current view  
	if (eventInfo && eventInfo.view && eventInfo.view.type) viewType = eventInfo.view.type;
	let imageUrl;

	// var boxShadow = "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px";

	 const EVENT_TIME_CLASS = `fullCalendarEventTime TextStyle3 ${rsvpStatus === "DECLINED" ? "strikeThrough" : ""}`;
	 const EVENT_TITLE_CLASS = `fullCalendarEventTitle TextStyle3 ${rsvpStatus === "DECLINED" ? "strikeThrough" : ""}`;

	 switch (viewType)  {
			
			/* MONTH VIEW */
			case "dayGridMonth":
				if (category) imageUrl = setConectivityImageUrlByCategory(category, !allDayEvent);
				//Set our text color for Month view
				if (category) color = (allDayEvent ? "white" : setHeaderColorByCategory(category));
			 	if (DEBUG_MODE >= 3) console.log("Rendering Month View Calendar event", eventInfo.event.title, color, eventInfo);

				return (
					<div className="calendarEventContainer ContainerNoHeightFlexLeftFlexStart noWrap" >
							<div className="fullCalendarEventImage imageContainer ccImgLargeSquare" >
									<img  className="imageCenterPortrait" src={imageUrl}  />  
							</div>            
							<div className='calendarTimeContainer'>
								<span className={EVENT_TIME_CLASS} style={{color:color}}>{timeText}</span>
								<span className={EVENT_TITLE_CLASS} style={{color:color}}>{eventInfo.event.title}</span>
							</div>
							<div className="fullCalendarEventDeclinedMask" style={{opacity: (rsvpStatus === "DECLINED" ? "0.5" : "0")}}/>
					</div>
				);

			/* WEEK VIEW */
			case "timeGridWeek":
				if (category) imageUrl = setConectivityImageUrlByCategory(category, false);
				return (
						<div className="calendarEventContainer ContainerNoHeightFlexLeftFlexStart noWrap" >
							 <div className="fullCalendarEventImage imageContainer ccImgLargeSquare" > <img  className="imageCenterPortrait" src={imageUrl}  />  </div>    
							 <div className='calendarTimeContainer'>
							 		<span className={EVENT_TIME_CLASS + " white"} style={{color:color}}>{timeText+' '}</span>
									<span className={EVENT_TITLE_CLASS + " white"} style={{color:color}}>{eventInfo.event.title}</span>
							 </div>
							 <div className="fullCalendarEventDeclinedMask" style={{opacity: (rsvpStatus === "DECLINED" ? "0.5" : "0")}}/>
						</div>
				);


			/* DAY VIEW */
			case "timeGridDay":
			 if (category) imageUrl = setConectivityImageUrlByCategory(category, false);
				return (
						<div className="calendarEventContainer ContainerNoHeightFlexLeftFlexStart noWrap">
								<div className="fullCalendarEventImage imageContainer ccImgLargeSquare" > <img  className="imageCenterPortrait"  src={imageUrl}  />  </div>   
								<div className='calendarTimeContainer'>
									<span className={EVENT_TIME_CLASS + " white"} style={{color:color}}>{timeText} </span>
									<span className={EVENT_TITLE_CLASS + " white"} style={{color:color}}>{eventInfo.event.title}</span>
								</div>
							 <div className="fullCalendarEventDeclinedMask" style={{opacity: (rsvpStatus === "DECLINED" ? "0.5" : "0")}}/>
						</div>
				);
				
			/* UNKNOWN VIEW */
			default:
				if (category) imageUrl = setConectivityImageUrlByCategory(category, true);

				return (
						<div className="calendarEventContainer ContainerNoHeightFlexLeftFlexStart">
							<div className="fullCalendarEventImage imageContainer ccImgLargeSquare" >
									<img  className="imageCenterPortrait"  src={imageUrl}  />  
							</div>           
							<div className='calendarTimeContainer'> 
								<span className={EVENT_TIME_CLASS} style={{color:color}}>{timeText}</span>
								<span className={EVENT_TITLE_CLASS} style={{color:color}}>{eventInfo.event.title}</span>
							</div>
					</div>
				);       
	} //End Switch
	
	
};

const CalendarView = React.memo(({events, eventClick, dateClick, eventMouseEnter, eventMouseLeave, pointer, eventDragStart, eventDragStop, eventDrop, addButtonHandler}) => {
	
	if (DEBUG_MODE >= 2) console.log("Rendering Calendar view.  Events:", events);
	

	return (
		<div style={{position:'relative'}}>
 
			<div style={{position:"absolute", right:'0', top:'0px'}}>
					<AddButton icon = {<LaunchIcon color={COLOR_WHITE} />} onClickCallback={addButtonHandler} useRegularContainer={true} hoverText='Schedule a conectivity!' />
			</div>
	 
			<FullCalendar
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
				initialView="dayGridMonth"
				default 
				// headerToolbar 
				properties
				// customButtons= {{
				//   addButton: {
				//     text: '+',
				//     hint:'schedule conectivity',
				//     click: addButtonHandler
				//   }
				// }}      
				headerToolbar = {{
					start: 'title',
					center: 'today dayGridMonth,timeGridWeek,timeGridDay prev,next',
					end: ''
					// end: 'today prev,next addButton'
					// end: 'today prev,next'
				}}
				
				// headerToolbar={{
				//   center: 'dayGridMonth,timeGridWeek,timeGridDay',
				// }}            
				weekends={true}
				events={events}
				nowIndicator
				dateClick={dateClick}
				eventClick={eventClick}
				eventMouseEnter={eventMouseEnter}
				eventMouseLeave={eventMouseLeave}
				eventDragStart={eventDragStart}
				eventDragStop={eventDragStop}
				eventDrop={eventDrop}
				dayMaxEvents={true} // when too many events in a day, show the popover
				eventContent={renderEventContent}
				scrollTime="08:00:00" //Start the day calendar scrolled to 8:00 am
				timeZone="local" // Use "local" to ensure users see events in their local time
			/>
		</div>
	);
}, (prevProps, nextProps) => {
		//Our React MEMO function - do not re-render if no change to the events array
		if ((prevProps.events === nextProps.events)) 
		{
				// if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the CalendarView",  prevProps.events);
				return true; // props are equal
		}
		// if (DEBUG_MODE >= 2) console.log("Re-RENDER the CalendarView!", prevProps.events);
		return false; // props are not equal -> update the component
});

export default CalendarView;

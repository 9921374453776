//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021


import "./radarChart.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, RADAR_CHART_WIDTH, RADAR_CHART_HEIGHT} from '../../../../shared/data/conectereConfigData';
//Amplify, React
import  React from 'react';

import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const CCRadarChart = ({title, data, dataKeyAngle, radarName1, radarName2, radarName3, radarName4, dataKey1, dataKey2, dataKey3, dataKey4, color1, color2, color3, color4, grid, targetName}) => {
 
    
    // if (DEBUG_MODE >= 2) console.log("RadarChart called", title);
    // if (DEBUG_MODE >= 2) console.log("data", data);
    // if (DEBUG_MODE >= 2) console.log("Radar Name 1", radarName1);
    // if (DEBUG_MODE >= 2) console.log("Radar Name 2", radarName2);
    // if (DEBUG_MODE >= 2) console.log("Radar Name 3", radarName3);
    // if (DEBUG_MODE >= 2) console.log("Radar Name 4", radarName4);
    // if (DEBUG_MODE >= 2) console.log("dataKeyAngle", dataKeyAngle);
    // if (DEBUG_MODE >= 2) console.log("dataKey1", dataKey1);
    // if (DEBUG_MODE >= 2) console.log("dataKey2", dataKey2);
    // if (DEBUG_MODE >= 2) console.log("dataKey3", dataKey3);
    // if (DEBUG_MODE >= 2) console.log("dataKey4", dataKey3);
    // if (DEBUG_MODE >= 2) console.log("grid", grid);

    // if (!data || data.length ==0) return null;

    return (

        <div className="dashboardCard" >
        
            <div className="ContainerVerticalCenterFullWidth TextStyle4 headerBlue heavy">
                <span >{title}</span>
                {!targetName ? "" :
                    <span >{targetName}</span>
                }
                <Divider width={80} />
            </div>
            <div className="radarChartContainer" style={{height:RADAR_CHART_HEIGHT, width:RADAR_CHART_WIDTH }} >
            
            
                {(data && data.length  > 0) ? 

                    <RadarChart cx="50%" cy="50%" outerRadius="90%" data={data} height={RADAR_CHART_HEIGHT} width={RADAR_CHART_WIDTH} cx="50%" cy="50%" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <PolarAngleAxis dataKey={dataKeyAngle} />
                        <PolarRadiusAxis angle={30}  />
                        {(radarName1 && dataKey1) ? <Radar name={radarName1} dataKey={dataKey1} stroke={color1} fill={color1} fillOpacity={0.8} /> : ""}
                        {(radarName2 && dataKey2) ? <Radar name={radarName2} dataKey={dataKey2} stroke={color2} fill={color2} fillOpacity={0.8} /> : ""}
                        {(radarName3 && dataKey3) ? <Radar name={radarName3} dataKey={dataKey3} stroke={color3} fill={color3} fillOpacity={0.8} /> : ""}
                        {(radarName4 && dataKey4) ? <Radar name={radarName4} dataKey={dataKey4} stroke={color4} fill={color4} fillOpacity={0.8} /> : ""}
                        {grid && <PolarGrid />}
                        <Legend verticalAlign="bottom" height={14} iconType={'circle'}/>
                    </RadarChart>
                    : 
                    null
                }
                
                <div className="imageContainer radarChartImage" style={{position:"absolute", top:"0", left:"50%", transform:"translate(-50%,0)"}}>
                    <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} alt='' />  
                </div>

                <div className="imageContainer radarChartImage" style={{position:"absolute", top:"65px", left:"60px"}}>
                    <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt=''/>  
                </div>

                <div className="imageContainer radarChartImage" style={{position:"absolute", top:"65px", right:"60px"}}>
                    <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH}   alt=''/>  
                </div>

                <div className="imageContainer radarChartImage" style={{position:"absolute", top:"205px", left:"60px"}}>
                    <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}   alt=''/>  
                </div>


                <div className="imageContainer radarChartImage" style={{position:"absolute", top:"205px", right:"60px"}}>
                    <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH}   alt=''/>  
                </div>

                <div className="imageContainer radarChartImage" style={{position:"absolute", bottom:"0", left:"50%", transform:"translate(-50%,-25%)"}}>
                    <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH}   alt=''/>  
                </div>


          </div>
        </div>
        
    );

}


export default CCRadarChart;


                    // <PolarRadiusAxis angle={30} domain={[0, 1]} />



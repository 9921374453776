//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021

//  ADMIN for managing users, including user analytics


//Config Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, CONECTIVITY_INITIAL_STATE, MENTOR_ANALYTICS_INITIAL_STATE, TEAM_COLOR, COLOR_WHITE, COLOR_BLUE_HEADER, SOCIAL_COLOR, DATA_GRID_HIDE_ROW_THRESHOLD1, DATA_GRID_HIDE_ROW_THRESHOLD2, 
	USER_INITIAL_STATE, GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, DEFAULT_MENTOR_CONECTIVITY_ID, DEFAULT_SPONSOR_CONECTIVITY_ID, GROWTH_RING} from '../../shared/data/conectereConfigData';
import { HELP_TEXT_ADMIN_MENTOR_1 } from '../../shared/data/helpData.js';
import { USER_COLUMN, NO_MENTORSHIP_ROWS, NO_SPONSORSHIP_ROWS } from '../../shared/data/gridColumns.js';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL;
import { Link } from 'react-router-dom';

//Queries and Mutations
import { updateUser } from '../../shared/graphql/mutations';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Switch from '@mui/material/Switch';
import Select from 'react-select';   //Version with support from multiple selections

//Icons
import { DeleteOutline} from '@material-ui/icons';
import SettingsIcon from '@mui/icons-material/Settings';

//Our Components
import DisplayCardMentorStats from "./Components/displayCardMentorStats/displayCardMentorStats";
import DisplayCardMentorLoad from "./Components/displayCardMentorLoad/displayCardMentorLoad";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { DisplayConectivitySelectComponent, DisplayCardConectivityPreview, invokeInviteToConect, invokeUpdateLaunchRule, invokeRemoveInviteToConect, setHeaderColorByStringLength, setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../../shared/utils/conectivityUtils";
import { getUserInitials, getUserName, displayUsersRow, compareUsersByName, compareTeamsByName, generateUsersToInvite} from "../../shared/utils/userAndTeamUtils";
import {updateMentorSelection, updateSponsorSelection} from "../../shared/utils/databaseUtils";
import {compareTransactionsByDate, compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { truncateStringWithDots, formatDate, formatDateWithDayOfWeek, InfoButton,  HelpButton, EditButton, DeleteButton, LaunchButtonWithoutIcon, SettingsButton, CalendarButton, TabPanel } from "../../shared/utils/generalUtils";
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,
buildRecurrenceString, getrecurrenceRuleLaunchDates, getRecurrenceRuleNextLaunchDate,isRecurrenceRuleLaunchToday, getLaunchRuleDateTime,
} from "../../shared/utils/dateTimeUtils";

//Icons
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import GifIcon from '@mui/icons-material/Gif';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

//Tabs
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import moment from 'moment';

//emoji support
import Picker from 'emoji-picker-react';

//GIPHY support
import { Gif, 
Grid as GiphyGrid,
SearchBar, // the search bar the user will type into
SearchContext, // the context that wraps and connects our components
SearchContextManager, // the context manager, includes the Context.Provider
SuggestionBar,
} from '@giphy/react-components';

import { GiphyFetch } from '@giphy/js-fetch-api';

const AdminMentorSponsorPage = () => {

//Context
const { authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics, } = useContext(AuthContext);    
const { users, teams, usersOptions } = useContext(CustomerContext);  
const { conectivitiesReadyForLaunch, launchRules, conectivitiesReadyForLaunchOptions, } = useContext(ConectivityContext);
const { setShowSpinner } = useContext(DisplayContext); 
const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

//Local State
const [rowsToDisplay,setRowsToDisplay]=useState([]);					//Rows that are actually rendered in the UI
const [columnsToDisplay,setColumnsToDisplay]=useState([]);					//Rows that are actually rendered in the UI
const [userRowsMentorProgram,setUserRowsMentorProgram]=useState([]);                                    //Formatted rows for user grid
const [userRowsSponsorProgram,setUserRowsSponsorProgram]=useState([]);                                    //Formatted rows for user grid
const [showModalCreateMentorLaunchRule, setShowModalCreateMentorLaunchRule] = useState(false);
const [showModalCreateSponsorLaunchRule, setShowModalCreateSponsorLaunchRule] = useState(false);
const [showModalEditMentorLaunchRule, setShowModalEditMentorLaunchRule] = useState(false);
const [showModalEditSponsorLaunchRule, setShowModalEditSponsorLaunchRule] = useState(false);
const [showModalDeleteMentorLaunchRule, setShowModalDeleteMentorLaunchRule] = useState(false);
const [showModalDeleteSponsorLaunchRule, setShowModalDeleteSponsorLaunchRule] = useState(false);
// const [selectedMentorOption, setSelectedMentorOption] = useState(null);
const [selectedMenteeOrSponsorOption, setSelectedMenteeOrSponsorOption ] = useState(null);
const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);     
// const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);     
// const [menteeToSchedule,setMenteeToSchedule] = useState(null);
const [programAnalytics,setProgramAnalytics] = useState(MENTOR_ANALYTICS_INITIAL_STATE);
const [programNumbers,setProgramNumbers] = useState([]);
const [oneTimeLaunchForAll, setOneTimeLaunchForAll] = useState(false);

const [selectedConectivity, setSelectedConectivity] = useState(null);     
const [conectivityToLaunch, setConectivityToLaunch] = useState(CONECTIVITY_INITIAL_STATE);            //Used to hold a conectivity that is to be launched
const [launchRuleToEdit, setLaunchRuleToEdit]  = useState(CONECTIVITY_INITIAL_STATE);
const [conectivityToLaunchEventStartDateTime, setConectivityToLaunchEventStartDateTime] = useState(null);               //Used to hold the DatePicker Date value for when a conectivity is to be launched
const [conectivityToLaunchEventEndDateTime, setConectivityToLaunchEventEndDateTime] = useState(null);               //Used to hold the DatePicker Date value for when a conectivity is to be launched
const [conectivityToLaunchRepeatOption, setConectivityToLaunchRepeatOption] = useState(null);      //Used to hold any selected Repeat value for a conectivity is to be launched
const [conectivityToLaunchRepeatWeeklyOptions, setConectivityToLaunchRepeatWeeklyOptions] = useState([]);      //Used to hold any selected Repeat value for a conectivity is to be launched
const [conectivityToLaunchRepeatMonthlyOptions, setConectivityToLaunchRepeatMonthlyOptions] = useState(null);      //Used to hold any selected Repeat value for a conectivity is to be launched
const [conectivityToLaunchRepeatMonthlyOptions_2, setConectivityToLaunchRepeatMonthlyOptions_2] = useState(null);      //Used to hold any selected Repeat value for a conectivity is to be launched
const [conectivityToLaunchRepeatMonthlyOptions_3, setConectivityToLaunchRepeatMonthlyOptions_3] = useState(null);      //Used to hold any selected Repeat value for a conectivity is to be launched
const [conectivityToLaunchRepeatUntilDate, setConectivityToLaunchRepeatUntilDate] = useState(null);               //Used to hold the DatePicker Date value for when a conectivity is to be launched
const [conectivityToLaunchMonthOption1Checked, setConectivityToLaunchMonthOption1Checked] = useState(true);
const [conectivityToLaunchMonthOption2Checked, setConectivityToLaunchMonthOption2Checked] = useState(false);
const [allDayEvent, setAllDayEvent] = useState(false);

//Tabs for Mentorship and Sponsorship Dashboards
const [tabIndexValue, setTabIndexValue] = useState(0);

// Emoji picker and GIPHY state data
const [showPicker, setShowPicker] = useState(false);
const [showSearchExperienceMessage, setShowSearchExperienceMessage] = useState(false);
const [messageGif, setMessageGif] = useState(null);
const [showSearchExperienceSpotlight, setShowSearchExperienceSpotlight] = useState(false);
const [messageInputBarHeight, setMessageInputBarHeight] = useState("32px");
const [inviteMessage, setInviteMessage] = useState("");
const [newMessageGiphyGridContainerDivSize, setNewMessageGiphyGridContainerDivSize]  = useState(450);   
const gf = new GiphyFetch(GIPHY_SDK_KEY_WEB);


// Main React useEffect for maintaining updated page data
useEffect(() => {
if (authState !== "signedin") return;
//Build rows for Data Grid
if (DEBUG_MODE >= 2) console.log("Building user rows in useEffect", users);

if (users && launchRules) {
	buildUserRows();
	calcMentorStats();
}

}, [users, launchRules]);


/* Effect to switch our rows to display based on a change to the selected Tab or to the underlying Row data*/
useEffect(() => {	
let tempRows, tempColumns;
switch (tabIndexValue) {
 case 0:
		tempRows = [...userRowsMentorProgram];
		tempColumns = [...mentorshipProgramColumns];
	  break;
 case 1:
	  tempRows = [...userRowsSponsorProgram];
	  tempColumns = [...sponsorshipProgramColumns];
	  break;										
 default:
 tempRows = [...userRowsMentorProgram];
 break;
}
setRowsToDisplay(tempRows);	
setColumnsToDisplay(tempColumns);
}, [tabIndexValue, userRowsMentorProgram, userRowsSponsorProgram ]);


function calcMentorStats () {
console.log ("Inputs to build Mentor Data", users);
const tempNumbers = [];  

var tempAnalytics = {...MENTOR_ANALYTICS_INITIAL_STATE};
tempAnalytics.usersTotal = users.length;

//First, initialize our array so we have a spot for each user
for (var j = 0; j < users.length; j++){
	
	tempNumbers[j] = {
		userID: users[j].id,
		numMentees:0,
		numProteges:0,
		name:users[j].firstName + (!users[j].middleName ? "" : " " + users[j].middleName.substr(0,1)) + " " + users[j].lastName,
		title:users[j].title,
	};    
}

//Walk the users one more time and update our stats
for (var j = 0; j < users.length; j++){
	//Update company Mentorship totals
	if (users[j].mentorID) {
		tempAnalytics.usersWithMentors +=1;
		
		//Find the Mentor and increment their mentee count
		const tempMentorIndex = tempNumbers.findIndex(mentor => mentor.userID === users[j].mentorID);
		if (tempMentorIndex > -1) {
			// if (DEBUG_MODE >= 2) console.log("Found mentor",tempMentorIndex, tempNumbers[tempMentorIndex]);
			tempNumbers[tempMentorIndex].numMentees +=1;
		}
	}
	
	 //Update company Sponsorship totals
	if (users[j].isUnderrepresentedGroup && users[j].sponsorID ) {
		tempAnalytics.candidatesWithSponsors +=1;
		
		//Find the Sponsor and increment their protegee count
		const tempSponsorIndex = tempNumbers.findIndex(sponsor => sponsor.userID === users[j].sponsorID);
		if (tempSponsorIndex > -1) {
			if (DEBUG_MODE >= 2) console.log("Found sponsor",tempSponsorIndex, tempNumbers[tempSponsorIndex], users[j]);
			tempNumbers[tempSponsorIndex].numProteges +=1;
		}
	}   
	
	if (users[j].isUnderrepresentedGroup) tempAnalytics.sponsorshipCandidatesTotal +=1;
}
	
//Totals
tempAnalytics.usersWithoutMentors  =  tempAnalytics.usersTotal - tempAnalytics.usersWithMentors;
tempAnalytics.candidatesWithoutSponsors  =  tempAnalytics.sponsorshipCandidatesTotal - tempAnalytics.candidatesWithSponsors;

const tempMentors = tempNumbers.filter(user => user.numMentees >0);
tempAnalytics.numMentors = (tempMentors ? tempMentors.length : 0);

const tempSponsors = tempNumbers.filter(user => user.numProteges >0);
tempAnalytics.numSponsors = (tempSponsors ? tempSponsors.length : 0);

if (DEBUG_MODE >= 2) console.log("Computed mentor & sponsor stats", tempAnalytics, tempNumbers);
setProgramAnalytics(tempAnalytics);
setProgramNumbers(tempNumbers);

}

//Define User Grid Format
//Note - resizable appears to be available only in Data Grid Pro

const mentorshipProgramColumns = [
USER_COLUMN,
{
field: 'mentor',
headerName: 'MENTOR',
headerAlign: 'center',
disableColumnMenu: true,
align:'center',
minWidth: 200,   
maxWidth:300,
flex:1,
color:'white',
sortable: false,
renderCell: (params)=> {
		return (
			<div className="ContainerMaxHeightCenter fullWidth ">
				<div className="fullWidth">
					 <Select 
						isMulti={false} 
						isClearable={true}
						name="userDropdown" 
						options={usersOptions} 
						onChange={async (value) => await updateMentorSelection({option:value, userID:params.row.dynamoDBID}) } 
						value={params.row.mentorSelectionOption} 
						placeholder=" none "   
						isDisabled={!isAdmin || !currentUser.customer.configDetails.mentorProgramEnabled}
						
					/>   
				</div>
			</div>
	);

  },
},  

{
field: 'recurrenceRule',
headerName: 'RECURRING SCHEDULE',
headerAlign: 'center',
disableColumnMenu: true,
sortable:false,
minWidth: 100,
editable: false,
color:'white',
flex:1,
renderHeader: (params) => {
	return (
		<div className="menuDataGridTextField TextStyle4 TextStyle3" style={{whiteSpace: "normal", wordBreak: "break-word" }}>
		RECURRING SCHEDULE
		</div>
	)
},	
renderCell: (params)=> {
	return (
		<div className="menuDataGridTextField TextStyle4 TextStyle2" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
			{params.row.recurrenceRuleMentorConectivity}
		</div>
		);
 },   
},  
{
field: 'title',
headerName: 'RECURRING CONECTIVITY',
headerAlign: 'center',
disableColumnMenu: true,
sortable:false,
minWidth: 100,
editable: true,
color:'white',
align:'center',
flex:1,
renderHeader: (params) => {
	return (
		<div className="menuDataGridTextField TextStyle4 TextStyle3" style={{whiteSpace: "normal", wordBreak: "break-word" }}>
		RECURRING CONECTIVITY
		</div>
	)
},
renderCell: (params)=> {
return (
	<div className="ContainerNoHeightCenter fullWidth">
			<img className="ccImgXL" src={params.row.imageMentorConectivity} alt='' />
			<div className="menuDataGridTextField TextStyle4" style={{padding:'clamp(2px,0.5vw,4px)', color:setHeaderColorByCategory(params.row.shortLabelMentorConectivity), height:"60px", whiteSpace: "normal", wordBreak: "break-word"}}>
				{params.row.titleMentorConectivity}
			</div>
	</div>
	);
},
},  
/* 
{
field: 'usersToInvite',
headerName: 'INVITEES',
headerAlign: 'center',
disableColumnMenu: true,
align:'center',
minWidth: 150,
// resizable: true,     //Not valid unless PRO
color:'white',
sortable: false,
renderCell: (params)=> {

	 if (!params.row.usersToInviteMentorConectivity || !params.row.usersToInviteMentorConectivity.length===0) return null;
		
	const MAX_AVATARS_TO_SHOW = 5;

	return (
		<div className="ContainerNoHeightCenter fullWidth maxHeight">
			 {params.row.usersToInviteMentorConectivity.length == 0 ? "" :
				<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >

								  
					{params.row.usersToInviteMentorConectivity.map((target, index) => ( 
						<div key={target.id} > 
							{index <=MAX_AVATARS_TO_SHOW-1 ? 
								<div className="avatarContainer">
									<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
										{!target.avatarUrl  ? <div className="avatarInitialsSmall" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
									</div>
									<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>{target.firstName + " " + target.lastName}</span>
								</div>
							: "" }
						</div>

					))}
					
					{params.row.usersToInviteMentorConectivity.length > MAX_AVATARS_TO_SHOW ? 
						<div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:SOCIAL_COLOR, color:"white"}}>
							<div className="avatarInitials" >+{params.row.usersToInviteMentorConectivity.length-MAX_AVATARS_TO_SHOW}</div>
						</div>
					 : ""   
					}
					
				  </div>
			 }

		</div>
		);
	}
}, 
*/
{
field: ' ',
headerName: ' ',
headerAlign: 'center',
disableColumnMenu: true,
align:'center',
// minWidth: 220,
// flex:0.5,
 // resizable: true,
color:'white',
sortable: false,
renderCell: (params)=> {

	if ((isAdmin || isSuperAdmin) && params.row.mentorID) return (
		<div className="dataGridActionButtonRow" >
			{params.row.mentorLaunchRuleID ?  <div className="blankButton" ></div> : <CalendarButton hoverLeft onClickCallback={handleCreateMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{userID:params.row.dynamoDBID, mentorOrSponsorID:params.row.mentorID, userSelectionOption:params.row.userSelectionOption, mentorOrSponsorSelectionOption:params.row.mentorSelectionOption, isMentorCreate:true, isSponsorCreate:false,}} hoverText="Schedule Recurring Conectivity" /> }
			{!params.row.mentorLaunchRuleID ?  <div className="blankButton" ></div> : <EditButton onClickCallback={handleEditMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{userID:params.row.dynamoDBID, mentorOrSponsorID:params.row.mentorID, launchRuleIDToEdit:params.row.mentorLaunchRuleID, userSelectionOption:params.row.userSelectionOption, mentorOrSponsorSelectionOption:params.row.mentorSelectionOption, isMentorEdit:true, isSponsorEdit:false, isMentorDelete:false, isSponsorDelete:false}}  hoverText="Edit Schedule" /> }
			{!params.row.mentorLaunchRuleID ?  <div className="blankButton" ></div> : <DeleteButton hoverLeft onClickCallback={handleEditMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{userID:params.row.dynamoDBID, mentorOrSponsorID:params.row.mentorID, launchRuleIDToEdit:params.row.mentorLaunchRuleID, userSelectionOption:params.row.userSelectionOption, mentorOrSponsorSelectionOption:params.row.mentorSelectionOption, isMentorEdit:false, isSponsorEdit:false, isMentorDelete:true, isSponsorDelete:false}} hoverText="Delete Schedule" /> }
		</div>
	); else return null;
}
},      
];

const sponsorshipProgramColumns = [
USER_COLUMN,
{
field: 'sponsor',
headerName: 'SPONSOR',
headerAlign: 'center',
disableColumnMenu: true,
align:'center',
minWidth: 250,   
maxWidth:500,
flex:1,
color:'white',
sortable: true,
renderCell: (params)=> {
		return (
			<div className="ContainerMaxHeightCenter fullWidth ">
				<div className="fullWidth">
					 <Select 
						isMulti={false} 
						isClearable={true}
						name="userDropdown" 
						options={usersOptions} 
						onChange={async (value) => await updateSponsorSelection({option:value, userID:params.row.dynamoDBID}) } 
						value={params.row.sponsorSelectionOption} 
						placeholder=" none "   
						isDisabled={!isAdmin || !currentUser.customer.configDetails.sponsorProgramEnabled}
						
					/>   
				</div>
			</div>
	);

  },
},  

{
field: 'recurrenceRule',
headerName: 'RECURRING SCHEDULE',
headerAlign: 'center',
disableColumnMenu: true,
minWidth: 100,
editable: false,
color:'white',
flex:1,
renderHeader: (params) => {
	return (
		<div className="menuDataGridTextField TextStyle4 TextStyle3" style={{whiteSpace: "normal", wordBreak: "break-word" }}>
		RECURRING SCHEDULE
		</div>
	)
},
renderCell: (params)=> {
	return (
		<div className="menuDataGridTextField TextStyle4 TextStyle2" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
			{params.row.recurrenceRuleSponsorConectivity}
		</div>
		);
 },   
},  
{
field: 'title',
headerName: 'RECURRING CONECTIVITY',
headerAlign: 'center',
disableColumnMenu: true,
minWidth: 100,
editable: true,
color:'white',
align:'center',
flex:1,
renderHeader: (params) => {
	return (
		<div className="menuDataGridTextField TextStyle4 TextStyle3" style={{whiteSpace: "normal", wordBreak: "break-word" }}>
		RECURRING CONECTIVITY
		</div>
	)
},
renderCell: (params)=> {
return (
		<div className="ContainerNoHeightCenter fullWidth">
				<img className="ccImgXL" src={params.row.imageSponsorConectivity} alt='' />
				<div className="menuDataGridTextField TextStyle4" style={{padding:'clamp(2px,0.5vw,4px)', color:setHeaderColorByCategory(params.row.shortLabelSponsorConectivity), height:"60px", whiteSpace: "normal", wordBreak: "break-word"}}>
					{params.row.titleSponsorConectivity}
				</div>
		</div>
	);
},
},  
{
field: ' ',
headerName: ' ',
headerAlign: 'center',
disableColumnMenu: true,
align:'center',
// minWidth: 220,
// flex:0.5,
 // resizable: true,
color:'white',
sortable: false,
renderCell: (params)=> {

	if ((isAdmin || isSuperAdmin) && params.row.sponsorID) return (
		<div className="dataGridActionButtonRow" >
			{params.row.sponsorLaunchRuleID ?  <div className="blankButton" ></div> : <CalendarButton hoverLeft onClickCallback={handleCreateMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{userID:params.row.dynamoDBID, mentorOrSponsorID:params.row.sponsorID, userSelectionOption:params.row.userSelectionOption, mentorOrSponsorSelectionOption:params.row.sponsorSelectionOption, isMentorCreate:false, isSponsorCreate:true}} hoverText="Schedule Recurring Conectivity" /> }
			{!params.row.sponsorLaunchRuleID ?  <div className="blankButton" ></div> : <EditButton onClickCallback={handleEditMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{userID:params.row.dynamoDBID, mentorOrSponsorID:params.row.sponsorID, launchRuleIDToEdit:params.row.sponsorLaunchRuleID, userSelectionOption:params.row.userSelectionOption, mentorOrSponsorSelectionOption:params.row.sponsorSelectionOption, isMentorEdit:false, isSponsorEdit:true, isMentorDelete:false, isSponsorDelete:false}}  hoverText="Edit Schedule" /> }
			{!params.row.sponsorLaunchRuleID ?  <div className="blankButton" ></div> : <DeleteButton hoverLeft onClickCallback={handleEditMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{userID:params.row.dynamoDBID, mentorOrSponsorID:params.row.sponsorID, launchRuleIDToEdit:params.row.sponsorLaunchRuleID, userSelectionOption:params.row.userSelectionOption, mentorOrSponsorSelectionOption:params.row.sponsorSelectionOption, isMentorEdit:false, isSponsorEdit:true, isMentorDelete:false, isSponsorDelete:true}} hoverText="Delete Schedule" /> }
		</div>
	); else return null;
}
},      
];


function buildUserRows() {

if (users==null) return;
if (users.length == 0) return;

//Build rows for Data Grid
var tempUserRows = [];

for (var i = 0; i<users.length; i++){

	 //Set user values for our Mentorship Program
	 //Set user values for our Sponsorship Program
	tempUserRows[i] = {
		id:i+1,
		
		//User values
		dynamoDBID:users[i].id,
		userName:getUserName(users[i]),
		title:users[i].title,
		email: users[i].email,
		avatarUrl:users[i].avatarUrl,
		initials:getUserInitials(users[i]),
		permissionAnalytics: (users[i].permissionAnalytics ? true : false),
		permissionEditor: (users[i].permissionEditor  ? true : false),
		permissionLaunch: (users[i].permissionLaunch ? true : false),
		permissionBilling: (users[i].permissionBilling ? true : false),
		permissionOrders: (users[i].permissionOrders ? true : false),
		mentorID:(users[i].mentorID ? users[i].mentorID : "" ),	   
		sponsorID:(users[i].sponsorID  ? users[i].sponsorID : "" ),	    	  
		supervisorID:(users[i].supervisorID  ? users[i].supervisorID : "" ),	     
		canBeMentor: (users[i].canBeMentor ? true : false),    
		canBeSponsor: (users[i].canBeSponsor  ? true : false),  
		canBeSupervisor: (users[i].canBeSupervisor ? true : false),
		isUnderrepresentedGroup: (users[i].isUnderrepresentedGroup ? true : false),
		userSelectionOption:usersOptions.find(option => option.id === users[i].id),  
		mentorSelectionOption:usersOptions.find(option => option.id === users[i].mentorID),  
		sponsorSelectionOption:usersOptions.find(option => option.id === users[i].sponsorID), 
		

		//Mentor calendar data this user (mentee)
		mentorLaunchRuleID:null,
		eventStartDateTimeMentorConectivity: "",
		launchUntilDateMentorConectivity:"",
		usersToInviteMentorConectivity:[],
		titleMentorConectivity: "None",
		shortLabelMentorConectivity: "",
		imageMentorConectivity: "",
		categoryMentorConectivity:"",
		conectivityImageURLMentorConectivity: "",
		frequencyMentorConectivity:"None",    
		recurrenceRuleMentorConectivity:"---",    
		messageMentorConectivity: "",                  
		messageGIFMentorConectivity: "",           
		senderIDMentorConectivity: "",                                 
		senderAvatarUrlMentorConectivity: "",             
		senderAvatarNameMentorConectivity: "",
		senderAvatarInitialsMentorConectivity: "",
		nextLaunchDateMentorConectivity: "",
		
		//Sponsor calendar data this user (sponsor)
		sponsorLaunchRuleID:null,
		eventStartDateTimeSponsorConectivity: "",
		launchUntilDateSponsorConectivity:"",
		usersToInviteSponsorConectivity:[],
		titleSponsorConectivity: "None",
		shortLabelSponsorConectivity: "",
		imageSponsorConectivity: "",
		categorySponsorConectivity:"",
		conectivityImageURLSponsorConectivity: "",
		frequencySponsorConectivity:"None",    
		recurrenceRuleSponsorConectivity:"---",    
		messageSponsorConectivity: "",                  
		messageGIFSponsorConectivity: "",           
		senderIDSponsorConectivity: "",                                 
		senderAvatarUrlSponsorConectivity: "",             
		senderAvatarNameSponsorConectivity: "",
		senderAvatarInitialsSponsorConectivity: "",
		nextLaunchDateSponsorConectivity: "",

	};

	//Get relevant mentor and sponsor program launch rules for this user
	const thisUsersMenteeLaunchRule = launchRules.find(lr => lr.senderID === users[i].id && lr.isMentorMeeting);
	const thisUsersSponsoreeLaunchRule = launchRules.find(lr => lr.senderID === users[i].id && lr.isSponsorMeeting);
	let thisConectivity;
	
	if (thisUsersMenteeLaunchRule) {
		 if (DEBUG_MODE >= 2) console.log("Found Launch Rule for Mentee", thisUsersMenteeLaunchRule);
		 thisConectivity = conectivitiesReadyForLaunch.find(conectivity => conectivity.id == thisUsersMenteeLaunchRule.conectivityID);

		 tempUserRows[i].mentorLaunchRuleID=thisUsersMenteeLaunchRule.id;
		 tempUserRows[i].eventStartDateTimeMentorConectivity =  formatDate(thisUsersMenteeLaunchRule.eventStartDateTime);
		 tempUserRows[i].launchUntilDateMentorConectivity =  (thisUsersMenteeLaunchRule.launchRepeatOption != 1 ? formatDate(thisUsersMenteeLaunchRule.launchUntilDate) : "");
		 tempUserRows[i].titleMentorConectivity =  (thisConectivity ? thisConectivity.title : "None");
		 tempUserRows[i].shortLabelMentorConectivity =  (thisConectivity ? thisConectivity.category.label : "");
		 tempUserRows[i].imageMentorConectivity =  (thisConectivity ? thisConectivity.image : "");
		 tempUserRows[i].categoryMentorConectivity = (thisConectivity ? thisConectivity.category.name : "");
		 tempUserRows[i].conectivityImageURLMentorConectivity = (thisConectivity ? setConectivityImageUrlByCategory(thisConectivity.category.label, true) : "");
		 tempUserRows[i].frequencyMentorConectivity = (thisUsersMenteeLaunchRule.launchRepeatOption ? CALENDAR_REPEAT_OPTIONS[thisUsersMenteeLaunchRule.launchRepeatOption-1].label : "None");    //Just an error check
		 tempUserRows[i].recurrenceRuleMentorConectivity = buildRecurrenceString(thisUsersMenteeLaunchRule);    
		 tempUserRows[i].messageMentorConectivity =  thisUsersMenteeLaunchRule.message;                            //Any message to include when launching the conectivity
		 tempUserRows[i].messageGIFMentorConectivity =  thisUsersMenteeLaunchRule.messageGif;                      //Any GIF  to also include when launching
		 tempUserRows[i].senderIDMentorConectivity =  thisUsersMenteeLaunchRule.senderID;                          //Who sent the invite
		 tempUserRows[i].senderAvatarUrlMentorConectivity =  thisUsersMenteeLaunchRule.senderAvatarUrl;            //location where avatar image is stored on the S3 bucket
		 tempUserRows[i].senderAvatarNameMentorConectivity =  thisUsersMenteeLaunchRule.senderAvatarName;
		 tempUserRows[i].senderAvatarInitialsMentorConectivity =  thisUsersMenteeLaunchRule.senderAvatarInitials;
		 tempUserRows[i].nextLaunchDateMentorConectivity =  formatDateWithDayOfWeek(getRecurrenceRuleNextLaunchDate(thisUsersMenteeLaunchRule));

		//Build out the array of mentors for display in our grid
		var mentorsToShow = [];
		for (var j=0; j<thisUsersMenteeLaunchRule.usersToInvite?.length; j++) {
			const tempParticipant = users.find(user => user.id == thisUsersMenteeLaunchRule.usersToInvite[j]);
	
			if (tempParticipant != undefined) mentorsToShow.push(tempParticipant);
		}  
		tempUserRows[i].usersToInviteMentorConectivity = [...mentorsToShow]; //Copy the array
		
	} else { 
		//if (DEBUG_MODE >= 2) console.log("No mentor launch rule", users[i].id, launchRules); 
	}

	if (thisUsersSponsoreeLaunchRule) {
		 thisConectivity = conectivitiesReadyForLaunch.find(conectivity => conectivity.id == thisUsersSponsoreeLaunchRule.conectivityID);
		if (DEBUG_MODE >= 2) console.log("Found Launch Rule for Sponsoree", thisUsersSponsoreeLaunchRule);

		 tempUserRows[i].sponsorLaunchRuleID=thisUsersSponsoreeLaunchRule.id;
		 tempUserRows[i].eventStartDateTimeSponsorConectivity =  formatDate(thisUsersSponsoreeLaunchRule.eventStartDateTime);
		 tempUserRows[i].launchUntilDateSponsorConectivity =  (thisUsersSponsoreeLaunchRule.launchRepeatOption != 1 ? formatDate(thisUsersSponsoreeLaunchRule.launchUntilDate) : "");
		 tempUserRows[i].titleSponsorConectivity =  (thisConectivity ? thisConectivity.title : "None");
		 tempUserRows[i].shortLabelSponsorConectivity =  (thisConectivity ? thisConectivity.category.label : "");
		 tempUserRows[i].imageSponsorConectivity =  (thisConectivity ? thisConectivity.image : "");
		 tempUserRows[i].categorySponsorConectivity = (thisConectivity ? thisConectivity.category.name : "");
		 tempUserRows[i].conectivityImageURLSponsorConectivity = (thisConectivity ? setConectivityImageUrlByCategory(thisConectivity.category.label, true) : "");
		 tempUserRows[i].frequencySponsorConectivity = (thisUsersSponsoreeLaunchRule.launchRepeatOption ? CALENDAR_REPEAT_OPTIONS[thisUsersSponsoreeLaunchRule.launchRepeatOption-1].label : "None");    //Just an error check
		 tempUserRows[i].recurrenceRuleSponsorConectivity = buildRecurrenceString(thisUsersSponsoreeLaunchRule);    
		 tempUserRows[i].messageSponsorConectivity =  thisUsersSponsoreeLaunchRule.message;                            //Any message to include when launching the conectivity
		 tempUserRows[i].messageGIFSponsorConectivity =  thisUsersSponsoreeLaunchRule.messageGif;                      //Any GIF  to also include when launching
		 tempUserRows[i].senderIDSponsorConectivity =  thisUsersSponsoreeLaunchRule.senderID;                          //Who sent the invite
		 tempUserRows[i].senderAvatarUrlSponsorConectivity =  thisUsersSponsoreeLaunchRule.senderAvatarUrl;            //location where avatar image is stored on the S3 bucket
		 tempUserRows[i].senderAvatarNameSponsorConectivity =  thisUsersSponsoreeLaunchRule.senderAvatarName;
		 tempUserRows[i].senderAvatarInitialsSponsorConectivity =  thisUsersSponsoreeLaunchRule.senderAvatarInitials;
		 tempUserRows[i].nextLaunchDateSponsorConectivity =  formatDateWithDayOfWeek(getRecurrenceRuleNextLaunchDate(thisUsersSponsoreeLaunchRule));

		//Build out the array of mentors for display in our grid
		var sponsorsToShow = [];
		for (var j=0; j<thisUsersSponsoreeLaunchRule.usersToInvite?.length; j++) {
			const tempParticipant = users.find(user => user.id == thisUsersSponsoreeLaunchRule.usersToInvite[j]);
	
			if (tempParticipant != undefined) sponsorsToShow.push(tempParticipant);
		}  
		tempUserRows[i].usersToInviteSponsorConectivity = [...sponsorsToShow]; //Copy the array
		
	}
}

const tempUserRowsForSponsorshipProgram = tempUserRows.filter(u => (u.isUnderrepresentedGroup));      
if (DEBUG_MODE >= 2) console.log("Built User Rows:", tempUserRows, tempUserRowsForSponsorshipProgram, users, usersOptions, launchRules);
setUserRowsMentorProgram(tempUserRows);
setUserRowsSponsorProgram(tempUserRowsForSponsorshipProgram);

}

// Pop up a modal for launching a conectivity for a specific user or team
const handleCreateMentorOrSponsorMeetingLaunchRule = ({userID, mentorOrSponsorID, userSelectionOption, mentorOrSponsorSelectionOption, oneTimeLaunchForAll, isMentorCreate, isSponsorCreate}) => {

//safety check
if ((!isMentorCreate && !isSponsorCreate ) || !currentUser.customer.configDetails) {if (DEBUG_MODE >= 2) console.log("ERROR invalid parameters"); return;}


if (DEBUG_MODE >= 2) {
	console.log("Initializing defaults for launch rule", userID, mentorOrSponsorID, userSelectionOption, mentorOrSponsorSelectionOption, oneTimeLaunchForAll, isMentorCreate, isSponsorCreate);
	console.log("Customer launch rule settings", currentUser.customer.configDetails);
}

// Set defaults for scheduling parameters
const configDetails = currentUser.customer.configDetails;
var tempWeeklyOptions = [];
var tempLaunchRule = {};
var nextLaunchDateMoment = null;

//Load the default conectivity to launch
var conectivityToLaunch = null;
conectivityToLaunch = conectivitiesReadyForLaunch.find(c => c.id === (isMentorCreate ? DEFAULT_MENTOR_CONECTIVITY_ID : DEFAULT_SPONSOR_CONECTIVITY_ID));
if (!conectivityToLaunch) {
	if (DEBUG_MODE >= 2) console.log("ERROR - New mentor / sponsor meeting button pressed - No default conectivity to launch ");
	return;
}
setConectivityToLaunch(conectivityToLaunch);

const tempConectivitySelectionOption = conectivitiesReadyForLaunchOptions.find(c => c.id === (isMentorCreate ? DEFAULT_MENTOR_CONECTIVITY_ID : DEFAULT_SPONSOR_CONECTIVITY_ID));
if (!tempConectivitySelectionOption) {
	if (DEBUG_MODE >= 2) console.log("ERROR - New mentor / sponsor meeting button pressed - No OPTION for the default conectivity to launch ");
	return;
}
setSelectedConectivity(tempConectivitySelectionOption);

if (!oneTimeLaunchForAll) {
	
	if (DEBUG_MODE >= 2) console.log("SCHEDULE INDIVIDUAL NEW MENTOR/SPONSOR MEETING button pressed.", userID, mentorOrSponsorID, userSelectionOption, mentorOrSponsorSelectionOption, oneTimeLaunchForAll, conectivityToLaunch); 

	//Preload our invitees options with the Mentor and the Mentee
	const tempSelectedUsersOptions = [userSelectionOption, mentorOrSponsorSelectionOption];
	setSelectedUsersOptions(tempSelectedUsersOptions);
	console.log ("Pre-loadeded invitees", tempSelectedUsersOptions, userSelectionOption, mentorOrSponsorSelectionOption);

	//Pre-load the mentee / sponsor option based on the selected user to be used as Sender for any invite
	setSelectedMenteeOrSponsorOption(userSelectionOption);
}

//Build a temp launch rule so we can get the next launch date to help the user with schedule
if (configDetails.mentorLaunchRepeatOption || configDetails.sponsorLaunchRepeatOption) {       
	tempLaunchRule = {
		launchUntilDate: SIX_MONTHS_FROM_NOW(),     //No specific UNTIL date in our constomer config in the DB
		launchRepeatOption:(isMentorCreate ? configDetails.mentorLaunchRepeatOption : configDetails.sponsorLaunchRepeatOption),               
		launchRepeatMonthlyOptions:(isMentorCreate ? configDetails.mentorLaunchRepeatMonthlyOptions  : configDetails.sponsorLaunchRepeatMonthlyOptions),       
		launchRepeatMonthlyOptions_2:(isMentorCreate ? configDetails.mentorLaunchRepeatMonthlyOptions_2  : configDetails.sponsorLaunchRepeatMonthlyOptions_2),     
		launchRepeatMonthlyOptions_3:(isMentorCreate ? configDetails.mentorLaunchRepeatMonthlyOptions_3  : configDetails.sponsorLaunchRepeatMonthlyOptions_3),     
		launchMonthOption1Checked:(isMentorCreate ?  configDetails.mentorLaunchMonthOption1Checked  : configDetails.sponsorLaunchMonthOption1Checked),    
		launchMonthOption2Checked: (isMentorCreate ? configDetails.mentorLaunchMonthOption2Checked : configDetails.sponsorLaunchMonthOption2Checked),               
	};

	//Set days of week for weekly options, if they exist
	for (var j = 0; j < (isMentorCreate ? configDetails.mentorLaunchRepeatWeeklyOptions.length : configDetails.sponsorLaunchRepeatWeeklyOptions.length); j++){  
		tempWeeklyOptions.push(WEEK_REPEAT_OPTIONS[   (isMentorCreate ? configDetails.mentorLaunchRepeatWeeklyOptions[j]-1 :  configDetails.sponsorLaunchRepeatWeeklyOptions[j]-1)  ]);  //Add day option to inital selections 
	}
	tempLaunchRule.launchRepeatWeeklyOptions = tempWeeklyOptions;
	if (DEBUG_MODE >= 2) console.log("Created temp launch rule based on defaults", tempLaunchRule);
	
	//Get Next Launch DATE so we can pre-load the event DATE 
	var nextLaunchDateString = getRecurrenceRuleNextLaunchDate(tempLaunchRule);
	nextLaunchDateMoment = moment(nextLaunchDateString);
	if (DEBUG_MODE >= 2) console.log("Next launch DATE based on default settings", nextLaunchDateString);
	
	//Set to the correct default TIME
	const tempLaunchTime = moment(isMentorCreate ? configDetails.mentorLaunchEventStartDateTime : configDetails.sponsorLaunchEventStartDateTime);    //Get the time
	nextLaunchDateMoment = nextLaunchDateMoment.clone().set({hour:tempLaunchTime.hour(), minute:tempLaunchTime.minute(),second:0,millisecond:0});
	if (DEBUG_MODE >= 2) console.log("Next launch DATE & TIME based on default settings WITH TIME", nextLaunchDateMoment.toISOString());
	tempLaunchRule = {...tempLaunchRule, eventStartDateTime:nextLaunchDateMoment.clone(), eventEndDateTime: nextLaunchDateMoment.clone().add((conectivityToLaunch ? conectivityToLaunch.approvedPaidTime : 30),"minutes")  };

	
} else {
	if (DEBUG_MODE >= 2) console.log("Error - no customer default settings; using pre-defined ones; should not happen");
	tempLaunchRule = {
		eventStartDateTime: TODAY_NOON(),
		eventEndDateTime: TODAY_NOON().add(30,"minutes"),
		launchUntilDate: SIX_MONTHS_FROM_NOW(),     
		launchRepeatOption:3, 
		launchRepeatWeeklyOptions: [WEEK_REPEAT_OPTIONS[3]],
		launchRepeatMonthlyOptions:0,       
		launchRepeatMonthlyOptions_2:0, 
		launchRepeatMonthlyOptions_3:3,     
		launchMonthOption1Checked:true, 
		launchMonthOption2Checked: false,               
	};
}

setOneTimeLaunchForAll(oneTimeLaunchForAll ? true : false);
// setInviteMessage("");
// setMessageGif(null);

 //Set calendar state vars based on the above temp launch rule     
if (DEBUG_MODE >= 2) console.log("Initializing calendar settings with the following launch rule", tempLaunchRule);
setConectivityToLaunchEventStartDateTime(tempLaunchRule.eventStartDateTime);
setConectivityToLaunchEventEndDateTime(tempLaunchRule.eventEndDateTime);
setConectivityToLaunchRepeatUntilDate(tempLaunchRule.launchUntilDate);
setConectivityToLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[tempLaunchRule.launchRepeatOption-1]);                         //Start at MONTH
setConectivityToLaunchRepeatWeeklyOptions(tempLaunchRule.launchRepeatWeeklyOptions);
setConectivityToLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[tempLaunchRule.launchRepeatMonthlyOptions-1]);
setConectivityToLaunchRepeatMonthlyOptions_2(MONTH_REPEAT_OPTIONS_2[tempLaunchRule.launchRepeatMonthlyOptions_2-1]);
setConectivityToLaunchRepeatMonthlyOptions_3(MONTH_REPEAT_OPTIONS_3[tempLaunchRule.launchRepeatMonthlyOptions_3-1]);
setConectivityToLaunchMonthOption1Checked(tempLaunchRule.launchMonthOption1Checked);
setConectivityToLaunchMonthOption2Checked(tempLaunchRule.launchMonthOption2Checked);
setAllDayEvent(tempLaunchRule.allDayEvent);


//pop-up Modal
if (isMentorCreate) setShowModalCreateMentorLaunchRule(true); 
else if (isSponsorCreate) setShowModalCreateSponsorLaunchRule(true);
};

//This function handles the EDIT button, which could be editing an existing one or even deleting it
//Pop-up a modal and ask conectivity to confirm. Handle confirmation
async function handleEditMentorOrSponsorMeetingLaunchRule({userID, mentorOrSponsorID, launchRuleIDToEdit, isMentorEdit, isSponsorEdit, isMentorDelete, isSponsorDelete, userSelectionOption, mentorOrSponsorSelectionOption}) {

if (isMentorDelete || isSponsorDelete) if (DEBUG_MODE >= 2) console.log("DELETE Launch Rule button pressed.  UserID=" + userID + " LaunchRuleID=" + launchRuleIDToEdit);
else if (DEBUG_MODE >= 2) console.log("Edit Launch Rule button pressed.  UserID=" + userID + " LaunchRuleID=" + launchRuleIDToEdit);

// Get mentee being edited
/*
const thisMentee = users.find(u => u.id === userID);
if (DEBUG_MODE >= 2) console.log("This Mentee being edited", thisMentee);

//Pre-load the mentee option and the mentor option
const menteeOption = usersOptions.find(userOption => userOption.id === thisMentee.id);
if (menteeOption)   setSelectedMenteeOrSponsorOption(menteeOption);
else console.log ("Error - no user matching mentee ");

const mentorOption = usersOptions.find(userOption => userOption.id === thisMentee.mentorID);
if (mentorOption)   setSelectedMentorOption(mentorOption);
else console.log ("Error - no user matching mentor ");
*/

//Pre-load the mentee option based on the selected user to be used as Sender for any invite
setSelectedMenteeOrSponsorOption(userSelectionOption);

//Preload our invitees options with the Mentor and the Mentee
const tempSelectedUsersOptions = [userSelectionOption, mentorOrSponsorSelectionOption];
setSelectedUsersOptions(tempSelectedUsersOptions);
console.log ("Pre-loadeded invitees", tempSelectedUsersOptions, userSelectionOption, mentorOrSponsorSelectionOption);

//Get the mentee's corresponding launch rule
const tempLaunchRuleToEdit = launchRules.find(lr => lr.id === launchRuleIDToEdit);
if(!tempLaunchRuleToEdit) {if (DEBUG_MODE >= 2) console.log("Error - no launch rule found to edit", launchRuleIDToEdit); return}
setLaunchRuleToEdit(tempLaunchRuleToEdit);

//Get the corresponding Conectivity to launch
const tempConectivityToLaunch = conectivitiesReadyForLaunch.find(conectivity => conectivity.id === tempLaunchRuleToEdit.conectivityID);
if(!tempConectivityToLaunch) {if (DEBUG_MODE >= 2) console.log("Error - no conectivity found corresponding to the launch rule"); return}
setConectivityToLaunch(tempConectivityToLaunch);

const tempConectivitySelectionOption = conectivitiesReadyForLaunchOptions.find(c => c.id === tempLaunchRuleToEdit.conectivityID);
if (!tempConectivitySelectionOption) { if (DEBUG_MODE >= 2) console.log("ERROR - No OPTION for the conectivity to launch for mentor / mentee"); return;  }
setSelectedConectivity(tempConectivitySelectionOption);


//Set calendar event data fields
setConectivityToLaunchEventStartDateTime(moment(tempLaunchRuleToEdit.eventStartDateTime, "YYYY MM DDTHH mm ssZ"));      //Used to hold the DatePicker Date value for start date/time of the conectivity
setConectivityToLaunchEventEndDateTime(moment(tempLaunchRuleToEdit.eventEndDateTime, "YYYY MM DDTHH mm ssZ"));          //Used to hold the DatePicker Date value for end date/time of the conectivity
setConectivityToLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[tempLaunchRuleToEdit.launchRepeatOption-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setConectivityToLaunchRepeatWeeklyOptions(tempLaunchRuleToEdit.launchRepeatWeeklyOptions);
setConectivityToLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[tempLaunchRuleToEdit.launchRepeatMonthlyOptions-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setConectivityToLaunchRepeatMonthlyOptions_2(MONTH_REPEAT_OPTIONS_2[tempLaunchRuleToEdit.launchRepeatMonthlyOptions_2-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setConectivityToLaunchRepeatMonthlyOptions_3(MONTH_REPEAT_OPTIONS_3[tempLaunchRuleToEdit.launchRepeatMonthlyOptions_3-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setConectivityToLaunchRepeatUntilDate(moment(tempLaunchRuleToEdit.launchUntilDate,"YYYY MM DDTHH mm ssZ"));               //Used to hold the DatePicker Date value for when a conectivity is to be launched
setConectivityToLaunchMonthOption1Checked(tempLaunchRuleToEdit.launchMonthOption1Checked);
setConectivityToLaunchMonthOption2Checked(tempLaunchRuleToEdit.launchMonthOption2Checked);
setAllDayEvent(tempLaunchRuleToEdit.allDayEvent);

//Set days of week for weekly options, if they exist
var tempWeeklyOptions = [];
for (var j = 0; j < tempLaunchRuleToEdit.launchRepeatWeeklyOptions.length; j++){  
	tempWeeklyOptions.push(WEEK_REPEAT_OPTIONS[tempLaunchRuleToEdit.launchRepeatWeeklyOptions[j]-1]);  //Add day option to inital selections 
}
console.log ("Created day of week selections:", tempWeeklyOptions);
setConectivityToLaunchRepeatWeeklyOptions(tempWeeklyOptions);      
 
 /*
 //Now, set the message and any GIF
 if (tempLaunchRuleToEdit.message) setInviteMessage(tempLaunchRuleToEdit.message); else setInviteMessage("");
 if (tempLaunchRuleToEdit.messageGIF) {
	const gifId = tempLaunchRuleToEdit.messageGIF.slice(GIPHY_ID_IDENTIFIER.length); 
	if (DEBUG_MODE >= 2) console.log("Extracted GIF ID", gifId);
	const giphyObject = await gf.gif(gifId);
	if (giphyObject) {
		if (DEBUG_MODE >= 2) console.log("Fetched Gif", giphyObject);
		setMessageGif(giphyObject.data); 
	}
 } else setMessageGif(null) ;
 */



if (isMentorDelete) setShowModalDeleteMentorLaunchRule(true); //pop-up Modal 
if (isSponsorDelete) setShowModalDeleteSponsorLaunchRule(true); //pop-up Modal 
else if (isMentorEdit) setShowModalEditMentorLaunchRule(true); //pop-up Modal 
else if (isSponsorEdit) setShowModalEditSponsorLaunchRule(true); //pop-up Modal 

}  

/*    
function handleMessageChange(value) {
 
//Dynamically adjust height of our input region as the user types
var objDiv = document.getElementById("conectereMessagingInputDIV");
if (objDiv) {
	var newHeight = (value.length < 20 ? "30px" : objDiv.scrollHeight.toString() + "px" );
	setMessageInputBarHeight(newHeight);
	// if (DEBUG_MODE >= 2) console.log("Message input bar change.  Amount to scroll to bottom", objDiv.scrollHeight, newHeight);
}             

//Close GIFPHY Search, if open
setShowSearchExperienceMessage(false);
// if (DEBUG_MODE >= 2) console.log('Key to add: ', value);

setInviteMessage(value);

}           

function handleMessageFocus(value) {
// if (DEBUG_MODE >= 2) console.log("Focus on the comment input bar");
setShowSearchExperienceMessage(false);            
setShowPicker(false);
}   
const onEmojiClick = (event, emojiObject) => {
setInviteMessage(inviteMessage => inviteMessage + emojiObject.emoji);
setShowPicker(false);
};

// Giphy search
const SearchComponents = ({giphyGridWidth}) => {
const { fetchGifs, searchKey } = useContext(SearchContext);

var numColumns = 3;

if (giphyGridWidth && giphyGridWidth> 450) numColumns = Math.floor(giphyGridWidth /150);

// if (DEBUG_MODE >= 2) console.log("Search components called. giphyGridWidth:", giphyGridWidth, numColumns );

return (
	<div className="conectereMessagingGiphySearchContainer" style={{width:giphyGridWidth}}>
		<div style={{padding:"5px 0px"}}>
			<SearchBar  />
		</div>
		<SuggestionBar />                    
		
		<div className="conectereMessagingGiphyGridContainer">
				<GiphyGrid key={searchKey} columns={numColumns} width={giphyGridWidth} fetchGifs={() => fetchGifs()} hideAttribution={true}
					onGifClick={(gif, e) => {
					  if (DEBUG_MODE >= 2) console.log("gif", gif);
					  e.preventDefault();
					  setShowSearchExperienceMessage(false);
					//   setShowSearchExperienceSpotlight(false);
					  setMessageGif(gif);
				}}/>
		</div>
	</div>
);
};
*/

async function handleConfirmationProcessLaunch ({deleteFlag, isMentorLaunch, isSponsorLaunch}) {

if (DEBUG_MODE >= 2) console.log("Confirmation called.", oneTimeLaunchForAll, deleteFlag, selectedMenteeOrSponsorOption);
var successFlag;
var completionMessage = "Oops - something went wrong";
setShowSpinner(true); //Pop up spinners to lock screen

if (deleteFlag) {

	const sendingMentee = users.find (u => u.id === selectedMenteeOrSponsorOption.id);

	successFlag = await invokeRemoveInviteToConect({
		currentUser:sendingMentee,      //Overload the mentee as the "sender"
		launchRuleToDeleteID:launchRuleToEdit.id, 
		recurrenceOption: 'ALL',
		});
	if (successFlag) completionMessage = "Calendar event deleted";


} else if (oneTimeLaunchForAll) {
	//HANDLE BATCH One-time launch for all Mentor / Mentee pairs;
	if (DEBUG_MODE >= 2) console.log("Launching conectivity for all mentors / mentees");
	
	//Loop through all users and see if that user is a mentee.  If so, schedule the conectivity with the mentor & mentee
	for (const mentee of users) {
		
		//Does this user have a mentor?
		if (mentee && mentee.mentorID) {
			
			//Construct temp selectedUsersOptions for this mentor / mentee pair
			const tempUserSelectionOption = usersOptions.find(option => option.id === mentee.id); 
			const tempMentorSelectionOption = usersOptions.find(option => option.id === mentee.mentorID); 
			
			const tempSelectedUsersOptions = [tempUserSelectionOption, tempMentorSelectionOption];
			console.log ("Pre-loadeded invitees", tempSelectedUsersOptions, tempUserSelectionOption, tempMentorSelectionOption);
			
			const usersAndTeamsToInvite = generateUsersToInvite(
				{
					selectedTeamsOptions:[], 
					selectedUsersOptions:tempSelectedUsersOptions, 
					users:users, 
					teams:teams,
					includeSenderFlag:false,     //Mentor / Mentee already included in selections
				});


			successFlag = await invokeInviteToConect ({
				sendingUser:mentee,       //SENDER of the invitation is set to the MENTEE
				conectivityID:conectivityToLaunch.id, 
				usersToInvite:usersAndTeamsToInvite.usersInvitedToParticipate,
				teamsToInvite:[], 
				inviteMessage:inviteMessage, 
				messageGIF:messageGif, 
				eventStartDateTime:conectivityToLaunchEventStartDateTime, 
				eventEndDateTime:conectivityToLaunchEventEndDateTime,
				launchUntilDate:conectivityToLaunchRepeatUntilDate, 
				launchRepeatOption:conectivityToLaunchRepeatOption, 
				launchRepeatWeeklyOptions:conectivityToLaunchRepeatWeeklyOptions, 
				launchRepeatMonthlyOptions:conectivityToLaunchRepeatMonthlyOptions,
				launchRepeatMonthlyOptions_2:conectivityToLaunchRepeatMonthlyOptions_2, 
				launchRepeatMonthlyOptions_3:conectivityToLaunchRepeatMonthlyOptions_3, 
				launchMonthOption1Checked:conectivityToLaunchMonthOption1Checked, 
				launchMonthOption2Checked:conectivityToLaunchMonthOption2Checked,
				allDayEvent:allDayEvent,
				isMentorMeeting: false,  //This batch launch is not counted as the DEFAULT mentor conectivity
				isSponsorMeeting: false,
				attendeeEmailsForCalendar:usersAndTeamsToInvite.attendeeEmailsForCalendar,
			});
			
			if (successFlag) completionMessage = "Conectivity Launched!";
			
			
		} else {
			if (DEBUG_MODE >= 2) console.log("No mentor assinged to this user", mentee);
		}
		
	}
	
} else { 

	//HANDLE CREATE AND UPDATE INDIVIDUAL LAUNCH RULE
	const sendingMenteeOrProtege= users.find (u => u.id === selectedMenteeOrSponsorOption.id);

	const usersAndTeamsToInvite = generateUsersToInvite(
		{
			selectedTeamsOptions:[], 
			selectedUsersOptions:selectedUsersOptions, 
			users:users, 
			teams:teams,
			includeSenderFlag:false,     //Mentor / Mentee already included in selections
		});

	if (showModalCreateMentorLaunchRule || showModalCreateSponsorLaunchRule) {
		if (DEBUG_MODE >= 2) console.log("Creating mentor program or sponsor launch rule");
		

		successFlag = await invokeInviteToConect ({
			sendingUser:sendingMenteeOrProtege,    //Overloading the SENDER of the invitation to this MENTEE no the currentUser
			conectivityID:conectivityToLaunch.id, 
			usersToInvite:usersAndTeamsToInvite.usersInvitedToParticipate,
			teamsToInvite:[], 
			inviteMessage:inviteMessage, 
			messageGIF:messageGif, 
			eventStartDateTime:conectivityToLaunchEventStartDateTime, 
			eventEndDateTime:conectivityToLaunchEventEndDateTime,
			launchUntilDate:conectivityToLaunchRepeatUntilDate, 
			launchRepeatOption:conectivityToLaunchRepeatOption, 
			launchRepeatWeeklyOptions:conectivityToLaunchRepeatWeeklyOptions, 
			launchRepeatMonthlyOptions:conectivityToLaunchRepeatMonthlyOptions,
			launchRepeatMonthlyOptions_2:conectivityToLaunchRepeatMonthlyOptions_2, 
			launchRepeatMonthlyOptions_3:conectivityToLaunchRepeatMonthlyOptions_3, 
			launchMonthOption1Checked:conectivityToLaunchMonthOption1Checked, 
			launchMonthOption2Checked:conectivityToLaunchMonthOption2Checked,
			allDayEvent:allDayEvent,
			isMentorMeeting: showModalCreateMentorLaunchRule,
			isSponsorMeeting: showModalCreateSponsorLaunchRule,
			attendeeEmailsForCalendar:usersAndTeamsToInvite.attendeeEmailsForCalendar,
		});
		
		if (successFlag) completionMessage = (showModalCreateMentorLaunchRule ? "Mentorship calendar event created for the mentor & mentee" : "Sponsorship calendar event created for the sponsor & sponsoree");

	} else if (showModalEditMentorLaunchRule || showModalEditSponsorLaunchRule) {

		successFlag = await invokeInviteToConect ({
			launchRuleID:launchRuleToEdit.id, 
			sendingUser:sendingMenteeOrProtege,    //Overloading the SENDER of the invitation to this MENTEE no the currentUser
			conectivityID:conectivityToLaunch.id, 
			usersToInvite:usersAndTeamsToInvite.usersInvitedToParticipate,
			teamsToInvite:[], 
			inviteMessage:inviteMessage, 
			messageGIF:messageGif, 
			eventStartDateTime:conectivityToLaunchEventStartDateTime, 
			eventEndDateTime:conectivityToLaunchEventEndDateTime,
			launchUntilDate:conectivityToLaunchRepeatUntilDate, 
			launchRepeatOption:conectivityToLaunchRepeatOption, 
			launchRepeatWeeklyOptions:conectivityToLaunchRepeatWeeklyOptions, 
			launchRepeatMonthlyOptions:conectivityToLaunchRepeatMonthlyOptions,
			launchRepeatMonthlyOptions_2:conectivityToLaunchRepeatMonthlyOptions_2, 
			launchRepeatMonthlyOptions_3:conectivityToLaunchRepeatMonthlyOptions_3, 
			launchMonthOption1Checked:conectivityToLaunchMonthOption1Checked, 
			launchMonthOption2Checked:conectivityToLaunchMonthOption2Checked,
			allDayEvent:allDayEvent,
			isMentorMeeting: showModalEditMentorLaunchRule,
			isSponsorMeeting: showModalEditSponsorLaunchRule,
			attendeeEmailsForCalendar:usersAndTeamsToInvite.attendeeEmailsForCalendar,
		});

		if (successFlag) completionMessage = (showModalCreateMentorLaunchRule ? "Mentorship calendar event updated for the mentor & mentee" : "Sponsorship calendar event updated for the sponsor & sponsoree");
	}
}

if (successFlag) { 
	setModalGeneralMessage(completionMessage);
	setModalGeneralTitle("Success!");
	setShowModalGeneral(true);   
	
} else {
	setModalGeneralMessage(completionMessage);
	setModalGeneralTitle("Ooops!");
	setShowModalGeneral(true);   

}  

setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
setShowModalCreateMentorLaunchRule(false);            //hide the pop-up
setShowModalCreateSponsorLaunchRule(false);           //hide the pop-up
setShowModalEditMentorLaunchRule(false);              //hide the pop-up
setShowModalEditSponsorLaunchRule(false);             //hide the pop-up
setShowModalDeleteMentorLaunchRule(false);            //hide the pop-up
setShowModalDeleteSponsorLaunchRule(false);           //hide the pop-up
setShowSpinner(false);                                //Hide spinners 
}    


const handleCancelMentorMeetingLaunchRule = () => {
// Set defaults for scheduling parameters
setConectivityToLaunchEventStartDateTime(NOW_ROUNDED_UP_TO_TEN_MIN());
setConectivityToLaunchEventEndDateTime(NOW_ROUNDED_UP_TO_TEN_MIN());
setConectivityToLaunchRepeatUntilDate(SIX_MONTHS_FROM_NOW());
setConectivityToLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[0]); //Start at NONE
setConectivityToLaunchRepeatWeeklyOptions([WEEK_REPEAT_OPTIONS[NOW().day()]]);        //Default to current day of week, with Sunday being 0 entry
setConectivityToLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[NOW().date()-1]);   //Default to current day of month, with 1st being 0 entry
setConectivityToLaunchRepeatMonthlyOptions_2(MONTH_REPEAT_OPTIONS_2[0]);
setConectivityToLaunchRepeatMonthlyOptions_3(MONTH_REPEAT_OPTIONS_3[NOW().day()]);  //Default to current day of week, with Sunday being 0 entry

setSelectedConectivity(null);
setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
setSelectedMenteeOrSponsorOption(null);
setShowModalCreateMentorLaunchRule(false);            //hide the pop-up
setShowModalCreateSponsorLaunchRule(false);           //hide the pop-up
setShowModalEditMentorLaunchRule(false);              //hide the pop-up
setShowModalEditSponsorLaunchRule(false);             //hide the pop-up
setShowModalDeleteMentorLaunchRule(false);            //hide the pop-up
setShowModalDeleteSponsorLaunchRule(false);           //hide the pop-up
setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
// if (DEBUG_MODE >= 2) console.log("Cancelled Conectivity Launch.");
};


const handleTabChange = (event, newValue) => {
setTabIndexValue(newValue);
};      

//Handle access by unauthenticated user
if (authState != "signedin"  || !currentUser) {
//   if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
// if (authState == "signedout") popUpLoginWindow();
return null;
}

const eventStartDateTimeChangeHandler = (newDateValue) => {

//First, set the new start time
setConectivityToLaunchEventStartDateTime(newDateValue);
if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());

//Now, auto-adjust the end time based on the change
var momentChange = newDateValue.diff(conectivityToLaunchEventStartDateTime.clone().startOf('minute'), 'minutes');      //Calc from the beginning of the minute to avoid rounding errors
if (DEBUG_MODE >= 2) console.log("User Adjusted start date/time by (minutes):", momentChange);

if (momentChange < 0) momentChange = momentChange-1;
const tempEndDateTime = conectivityToLaunchEventEndDateTime.clone().startOf().add(momentChange,"minutes");           
if (DEBUG_MODE >= 2) console.log("New launch END date/time auto-set:", tempEndDateTime.toISOString());
setConectivityToLaunchEventEndDateTime(tempEndDateTime);
};

const eventEndDateTimeChangeHandler = (newDateValue) => {

//First, set the new END date/ time
setConectivityToLaunchEventEndDateTime(newDateValue);
if (DEBUG_MODE >= 2) console.log("New launch END date/time:", newDateValue.toISOString());

//Is the new end date BEFORE the start date?  If so, set the start date to match

/* STILL A BUG HERE IN THAT IT ADJUSTS PER KEY STROKE - NEED TO FIND A WAY TO RESOLVE
if (newDateValue.isBefore(conectivityToLaunchEventStartDateTime)) {
	if (DEBUG_MODE >= 2) console.log("New launch START date/time: auto-set", newDateValue.toISOString());
	setConectivityToLaunchEventStartDateTime(newDateValue);
}
*/
};  


const launchRepeatOptionChangeHandler = (option) => {

//First, set the option
setConectivityToLaunchRepeatOption(option);
if (DEBUG_MODE >= 2) console.log("New launch repeat option", option);

//Now, adjust the UNTIL date
let tempUntilDate;
if (option && option.value === 'DAILY') 
	tempUntilDate = NOW().endOf('month');   //If DAILY, just default to the end of the month 
else 
	tempUntilDate = SIX_MONTHS_FROM_NOW();  
setConectivityToLaunchRepeatUntilDate(tempUntilDate);
}; 

const handleSelectedConectivity = (e) => {
if (DEBUG_MODE >= 2) console.log("Conectivity to Schedule Launch Selected", e);
setSelectedConectivity(e);
if (e) setConectivityToLaunch(conectivitiesReadyForLaunch.find(c => c.id == e.id)); else setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
};

//Render function for authenticated user 
//confirm access to this page
if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
return (
  <Authenticator>
  <div>
	<p></p>
		<h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
		<center>Sorry, off limits</center>
  </div>
  </Authenticator>
);
} else  return (

<Authenticator>
  <ModalNoBackground showModal={showModalCreateMentorLaunchRule || showModalCreateSponsorLaunchRule || showModalEditMentorLaunchRule || showModalEditSponsorLaunchRule || showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule} 
					 closeCallback={handleCancelMentorMeetingLaunchRule}  closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR} cardColor={TEAM_COLOR}  >
	<div className="modalNoBkgInnerCard" style={{position:"relative"}}>
	
		<div  className="modalNoBkgImage ccImgXXXXLSquare " style={{borderColor: TEAM_COLOR}}> 
			<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt=''/>  
		</div>

		{(showModalCreateSponsorLaunchRule || showModalEditSponsorLaunchRule || showModalDeleteSponsorLaunchRule) ?
		<h3>
			<span className="blue">SP</span>
			<span className="purple">ONS</span>
			<span className="green">ORS</span>
			<span className="yellow">HIP</span>
		</h3>
		:
		<h3>
			<span className="blue">ME</span>
			<span className="purple">NTO</span>
			<span className="green">RS</span>
			<span className="yellow">HIP</span>
		</h3>
		}
		
		{/*
			<div className="ContainerMaxHeightCenterFullWidth"  style={{height:"100px"}}>
				<div className="fullWidth" style={{flex:"1"}}>
					 <p className="purple" style={{paddingTop:"1.2rem", textAlign:"center"}}>                   
						{showModalCreateMentorLaunchRule && oneTimeLaunchForAll ? 'Launch a conectivity for each mentor / mentee pair in your mentorship program?' : null}
						{showModalCreateMentorLaunchRule && !oneTimeLaunchForAll ? 'SCHEDULE MENTOR CONECTIVITY ' : null}
						{showModalCreateSponsorLaunchRule && oneTimeLaunchForAll ? 'Launch a conectivity for each sponsor / sponsoree pair in your sponsorship program?' : null}
						{showModalCreateSponsorLaunchRule && !oneTimeLaunchForAll ? 'SCHEDULE SPONSOR CONECTIVITY ' : null}
						{showModalEditMentorLaunchRule ? 'UPDATE MENTOR CONECTIVITY ' : null}
						{showModalEditSponsorLaunchRule ? 'UPDATE SPONSOR CONECTIVITY ' : null}
						{showModalDeleteMentorLaunchRule ? 'DELETE MENTOR CONECTIVITY ' : null}
						{showModalDeleteSponsorLaunchRule ? 'DELETE SPONSOR CONECTIVITY ' : null}
					</p>
				</div>
				<div style={{flex:"1"}}>
					<div style={{position:"relative", height:"100px"}}>
						<img  className="avatarImageCenterPortrait" style={{width:'auto', height:"100%"}} src={process.env.PUBLIC_URL+'/img/community-s-1.png'} />  
					</div>
				</div>
			</div>
		*/}
		
		
		{oneTimeLaunchForAll && (showModalCreateMentorLaunchRule || showModalCreateSponsorLaunchRule) ?  
			<h5 className="black">Would you like to launch a conectivity for <b>each</b> {showModalCreateMentorLaunchRule ? 'mentor / mentee' : 'sponsor / sponsoree'} pair in your program? &nbsp;&nbsp;
			Each {showModalCreateMentorLaunchRule ? 'mentor / mentee ' : 'sponsor / sponsoree '} pair will receive a seperate calendar invitation to enage in the conectivity you select.  
			This is useful when you would like each pair to participate together in a specific event, such as a one-on-one training session, networking event or instructional seminar, for example.&nbsp;&nbsp; </h5> : null  }
			
		<div className="ContainerNoHeight" style={{height:"100%", width:"100%", padding:"10px", justifyContent:"center", flexWrap:"wrap"}}>
			<div id="newMessageGiphyGridContainerDivID" className="ContainerVerticalStart" style={{flex:1,  height:"100%", width:"100%", maxWidth:"550px", alignItems:"flex-start"}}>

					<div className="blue fullWidth" style={{position:"relative",  zIndex:"99999"}}>
						<div>Invitees</div> 
						<div style={{padding:"1.0rem"}}>
							{oneTimeLaunchForAll ? 
								<div className="TextStyle4 black"> Each {showModalCreateMentorLaunchRule ? 'mentor / mentee' : 'sponsor / sponsoree '} pair </div>
							:    
							
							displayUsersRow({usersToShow:selectedUsersOptions, numUsersToShow:7, isSmall:false})
							}
						</div>
					</div>
				
				
				<div className="ContainerNoHeightFlexLeft" style={{zIndex:"77770", paddingTop:"clamp(8px,2vw,20px)", alignItems:"flex-start", position:"relative" }}>
					 <div >
						<LocalizationProvider dateAdapter={AdapterMoment}>
							  <DatePicker
								label="Start"
								value={conectivityToLaunchEventStartDateTime}
								disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule }
								onChange={eventStartDateTimeChangeHandler}
								/>
						</LocalizationProvider>
					</div>

					 <div className="ContainerVerticalStart" style={{zIndex:"77770", position:"relative", paddingLeft:"clamp(4px,1vw,10px)" }}>
							 <div>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									  <TimePicker
										label=""
										value={conectivityToLaunchEventStartDateTime}
										disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule || allDayEvent}
										onChange={eventStartDateTimeChangeHandler}
									  />
								</LocalizationProvider>
							</div>    
						</div>
					</div> 
					<div className="ContainerNoHeightFlexLeft" style={{zIndex:"6666", paddingTop:"clamp(8px,2vw,20px)", alignItems:"flex-start", position:"relative" }}>
						 <div >
							<LocalizationProvider dateAdapter={AdapterMoment}>
								  <DatePicker
									label="End"
									disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule }
									value={conectivityToLaunchEventEndDateTime}
									onChange={eventEndDateTimeChangeHandler}
								  />
							</LocalizationProvider>
						</div>
						<div className="ContainerVerticalStart" style={{zIndex:"7777", position:"relative", paddingLeft:"clamp(4px,1vw,10px)" }}>
							 <div>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									  <TimePicker
										label=""
										value={conectivityToLaunchEventEndDateTime}
										disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule || allDayEvent}
										onChange={(newDateValue) => {
										  setConectivityToLaunchEventEndDateTime(newDateValue);
										  if (DEBUG_MODE >= 2) console.log("New launch END date/time:", newDateValue.toISOString());
										}}
										// renderInput={(params) => <TextField {...params} />}
									  />
								</LocalizationProvider>
							</div>    
						</div>

					</div>
				
					<div className="ContainerNoHeightFlexLeft" style={{paddingRight:"1rem"}}>
						 <Checkbox
						  checked={allDayEvent}
						  disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
						  onChange={()=>{
							setAllDayEvent(!allDayEvent);
						  }}
						  inputProps={{ 'aria-label': 'controlled' }}
						/>
					 <div style={{fontSize:"0.8em", fontWeight:"400", color:"black"}} >All Day Event</div>
					</div>


				{oneTimeLaunchForAll ? null :
					<div style={{padding:"10px 0px", width:"300px", zIndex:"9966", position:"relative"}}>
						<Select  isMulti={false} 
							name="repeatDropdown" 
							options={CALENDAR_REPEAT_OPTIONS} 
							onChange={launchRepeatOptionChangeHandler} 
							value={conectivityToLaunchRepeatOption} 
							placeholder="Does not repeat"
							isDisabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
							/>
						<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > Recurrence </div>
					</div>
				}

			{oneTimeLaunchForAll || conectivityToLaunchRepeatOption?.value !== "WEEKLY" ? null :
				<div style={{padding:"10px 0px", width:"20rem", zIndex:"8888", position:"relative"}}>
					<Select  isMulti={true} 
						name="weeklyRepeatOptionsDropdown" 
						options={WEEK_REPEAT_OPTIONS} 
						onChange={setConectivityToLaunchRepeatWeeklyOptions}
						value={conectivityToLaunchRepeatWeeklyOptions} 
						placeholder="Every"
							isDisabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
						/>
						
					<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > Every </div>

				</div>
			}

			 {oneTimeLaunchForAll || conectivityToLaunchRepeatOption?.value !== "MONTHLY" ? null :
			 <div>
				<div className="Container">
					 <Checkbox
					  checked={conectivityToLaunchMonthOption1Checked}
					  disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
					  onChange={()=>{
						setConectivityToLaunchMonthOption1Checked(!conectivityToLaunchMonthOption1Checked);
						setConectivityToLaunchMonthOption2Checked(!conectivityToLaunchMonthOption2Checked);
					  }}
					  inputProps={{ 'aria-label': 'controlled' }}
					/>
					
					<div style={{padding:"10px 0px", width:"8rem", zIndex:"7777", position:"relative"}}>
						<Select  isMulti={false} 
							name="monthlyRepeatOptionsDropdown_2" 
							options={MONTH_REPEAT_OPTIONS_2} 
							onChange={setConectivityToLaunchRepeatMonthlyOptions_2} 
							value={conectivityToLaunchRepeatMonthlyOptions_2} 
							placeholder="- select -"
							isDisabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
							/>
						<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > On the </div>
					</div>
					
					<div style={{padding:"10px 0px", width:"13rem", zIndex:"7777", position:"relative"}}>
						<Select  isMulti={false} 
							name="monthlyRepeatOptionsDropdown_3" 
							options={MONTH_REPEAT_OPTIONS_3} 
							onChange={setConectivityToLaunchRepeatMonthlyOptions_3} 
							value={conectivityToLaunchRepeatMonthlyOptions_3} 
							placeholder="- select -"
							isDisabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
							/>
					</div>
				</div>
				 <div className="Container">

					<Checkbox
					  checked={conectivityToLaunchMonthOption2Checked}
					 disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
					 onChange={()=>{
						setConectivityToLaunchMonthOption1Checked(!conectivityToLaunchMonthOption1Checked);
						setConectivityToLaunchMonthOption2Checked(!conectivityToLaunchMonthOption2Checked);
						 
					  }}
					  inputProps={{ 'aria-label': 'controlled' }}
					 />
				  
					<div style={{padding:"10px 0px", width:"10rem", zIndex:"6666", position:"relative"}}>
						<Select  isMulti={false} 
							name="monthlyRepeatOptionsDropdown" 
							options={MONTH_REPEAT_OPTIONS} 
							onChange={setConectivityToLaunchRepeatMonthlyOptions} 
							value={conectivityToLaunchRepeatMonthlyOptions} 
							placeholder="- select day -"
							isDisabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
							/>
							
						<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > On this day </div>

					</div>
				</div>
			</div>
				
			 }
			 
			 {oneTimeLaunchForAll || conectivityToLaunchRepeatOption?.value === "NONE" ? null :
			
				<div style={{padding:"20px 0px"}}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						  <DatePicker
							label="End by"
							value={conectivityToLaunchRepeatUntilDate}
							disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}
							onChange={(newDateValue) => {
							  setConectivityToLaunchRepeatUntilDate(newDateValue);
							//   if (DEBUG_MODE >= 2) console.log("End launch dates by:", newDateValue);
							}}
							// renderInput={(params) => <TextField {...params} />}
						  />
						</LocalizationProvider>
				</div>
			 }

			{/*
						<div className = "ContainerVerticalStart fullWidth conectereMessagingInputAreaWrapper" >
								<div className = "conectereMessagingInputAreaInnerWrapper" >
									{messageGif && (
										<div className="conectereMessagingGiphyStagingAreaWrapper" >
											<div className="conectereMessagingGiphyStagingArea" style={{backgroundColor:"#f0f0f0"}}>
												<Gif gif={messageGif} width={200} noLink={true} hideAttribution={true} style={{padding:"5px"}}/>
											</div>
											<div className="conectereMessagingGiphyClear" onClick={() => setMessageGif(null)}>
												X
											</div>
										</div>
									  )}
		
		
									
									<div className = "conectereMessagingInputArea" >
									
										 <div className="ContainerNoHeightCenter">
											<div className="avatarImage avatarImageSmall" style={{backgroundColor: setHeaderColorByStringLength(currentUser.lastName)}}>
												{!currentUser.avatarUrl  ? 
													<div className="avatarInitialsSmall"> {getUserInitials(currentUser)} </div>: 
													<img className="avatarImageCenterPortrait" src={currentUser.avatarUrl} alt={""} />
												}                                                
											</div>
										  </div>
								  
									
										<textarea
											id="conectereMessagingInputDIV"
											className="TextStyle3 conectereMessagingInputBar"
											style={{height:messageInputBarHeight}}
											value={inviteMessage}
											disabled={showModalDeleteLaunchRule}
											onChange={event => handleMessageChange(event.target.value)}
											onFocus={event => handleMessageFocus(event.target.value)}
											placeholder="Add a message"
											// cols="25" 
											rows="1"
										/>
										<div className="conectereMessagingInputAreaButtons" >
											<IconButton size="small"disabled={showModalDeleteLaunchRule} onClick={() => setShowPicker(!showPicker)}>
												<SentimentSatisfiedOutlinedIcon className="conectereMessagingSmileyIcon"/>
											</IconButton>
											<IconButton disabled={showModalDeleteLaunchRule} size="small" onClick={() => {
												setShowSearchExperienceMessage(!showSearchExperienceMessage);
												setMessageGif(null);}}
											>
												<GifIcon style={{fontSize:"24px"}} className='messageGifIcon' />
											</IconButton>
										</div>
			
										{showPicker &&
										
											<Picker
												pickerStyle={{ width: '35%', minWidth:"250px", zIndex: '20', position: "absolute", right: "-15%", top: "-350%", fontSize: '12px'}}
												onEmojiClick={onEmojiClick}/>
										}
			  
									</div>  
								</div>
		
								{showSearchExperienceMessage ? 
									<SearchContextManager apiKey={GIPHY_SDK_KEY_WEB}>
										<SearchComponents giphyGridWidth={newMessageGiphyGridContainerDivSize}/>
									</SearchContextManager>
								: null}
							
								
		
							</div> 
					*/}        
			</div>
			
				<div className="ContainerVertical" style={{flex:1,  height:"100%"}}>
					<div className="ContainerVertical fullWidth" style={{flex:1, padding:"20px"}}>
					<div className="blue fullWidth" style={{position:"relative",  zIndex:"99999"}}>
						<div className="ContainerNoHeightCenter" style={{position:"relative",  zIndex:"2"}}>
							<div>{(showModalCreateSponsorLaunchRule || showModalEditSponsorLaunchRule || showModalDeleteSponsorLaunchRule) ? 'Sponsorship' : 'Mentorship'} Conectivity</div> 
							<HelpButton hoverText={"Select the conectivity you would like to schedule for " + (oneTimeLaunchForAll ? 'each ' : 'the ' ) + (showModalCreateSponsorLaunchRule || showModalEditSponsorLaunchRule || showModalDeleteSponsorLaunchRule ? 'sponsor / sponsoree' : 'mentor / mentee') + ' pair'}  /> 
						</div>
						<div style={{zIndex:"1"}}>
							<DisplayConectivitySelectComponent name="conectivityDropdown" options={conectivitiesReadyForLaunchOptions} onChangeCallback={handleSelectedConectivity} isMulti={false} value={selectedConectivity} disabled={showModalDeleteMentorLaunchRule || showModalDeleteSponsorLaunchRule}  placeholder=""/>
						</div>
					</div>
				</div>


				<div style={{minHeight:"300px"}}>
					<DisplayCardConectivityPreview conectivityToPreview={conectivityToLaunch} hideViewButton={true}/>
				</div>
				
			</div>
		</div>


			<div className="ContainerMaxHeightFlexEnd fullWidth" style={{padding:"10px 0px"}}>
				{!showModalDeleteMentorLaunchRule && !showModalDeleteSponsorLaunchRule ? null :
					<button className="buttonStyle1 buttonStyle1Red" aria-label="delete"  onClick={() => handleConfirmationProcessLaunch({deleteFlag:true, isMentorLaunch:showModalDeleteMentorLaunchRule, isSponsorLaunch:showModalDeleteSponsorLaunchRule})} > <DeleteOutline  />  Delete  </button>
				}
				{showModalEditMentorLaunchRule || showModalCreateMentorLaunchRule || showModalEditSponsorLaunchRule || showModalCreateSponsorLaunchRule ?  
					<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="save"  onClick={() => handleConfirmationProcessLaunch({deleteFlag:false, isMentorLaunch:(showModalEditMentorLaunchRule || showModalCreateMentorLaunchRule), isSponsorLaunch:(showModalEditSponsorLaunchRule || showModalCreateSponsorLaunchRule )})} disabled={!oneTimeLaunchForAll && (!selectedUsersOptions || selectedUsersOptions.length===0)}>{oneTimeLaunchForAll ? 'Launch' : 'Save' }</button>
					: null
				}
				<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={handleCancelMentorMeetingLaunchRule} disabled={false}>Cancel</button>
		  </div>
	  
		</div>

	</ModalNoBackground>
	

<div className="adminPage">
	<div className="dashboardContainer ">

		<div className="ContainerNoHeightCenter purple fullWidth" >
			<h3>Employee Growth and Development Programs</h3>
				<InfoButton isHTML className="TextStyle4 purple" hoverTextClassName='TextStyle4 hoverTextStyle1' hoverText={HELP_TEXT_ADMIN_MENTOR_1} /> 
				<div className="ContainerNoHeightCenter purple">
					<Link to="/adminCustomerSettings?tab=mentorship" target="_blank">
						 <SettingsButton className="TextStyle5 purple" hoverText='Program settings'  /> 
					</Link>
				</div>
		</div>

			<div className="dashboardCardRow">
				{!programAnalytics ? "" : <DisplayCardMentorStats programAnalytics={programAnalytics}  showMentorship={tabIndexValue===0} showSponsorship={tabIndexValue===1} />}
				{!programNumbers ? "" : <DisplayCardMentorLoad programNumbers={programNumbers}  showMentorship={tabIndexValue===0} showSponsorship={tabIndexValue===1}/>}
			</div>
			
		<div className="dashboardTitle ">
			<div className="ContainerNoHeightSpaceBetween fullWidth">
				<Tabs value={tabIndexValue} onChange={handleTabChange} aria-label="tabs">
					<Tab label="MENTORSHIP PROGRAM" value={0} />
					{currentUser && currentUser.customer && currentUser.customer.configDetails.sponsorProgramEnabled ? <Tab label="SPONSORSHIP PROGRAM" value={1} /> : null }
				</Tabs>
				{tabIndexValue === 0 ?  <LaunchButtonWithoutIcon hoverLeft hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1Wide' onClickCallback={handleCreateMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{oneTimeLaunchForAll:true, isMentorCreate:true}} hoverText='Quick launch a special mentorship conectivity for each assigned pair' /> : null }
				{tabIndexValue === 1 && rowsToDisplay && rowsToDisplay.length > 0 ? <LaunchButtonWithoutIcon hoverLeft hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1Wide' onClickCallback={handleCreateMentorOrSponsorMeetingLaunchRule} onClickCallbackParam={{oneTimeLaunchForAll:true, isSponsorCreate:true}} hoverText='Quick a launch a special sponsorship conectivity for each assigned pair' /> : null }
			</div>	
		</div>   

			<div className="dataGridWrapperTop" >        
				<MenuDataGrid 
					dataGridRows={rowsToDisplay} 
					dataGridColumns={columnsToDisplay} 
					noRowsLabel={tabIndexValue === 0 ? NO_MENTORSHIP_ROWS : NO_SPONSORSHIP_ROWS}
				/>                     
			</div>					
	</div> 
</div>  
</Authenticator>

);
};


export default AdminMentorSponsorPage;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import "./adminTeamsConectivity.css";


import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_GOOD, COLOR_WARNING_LOW, COLOR_WARNING_HIGH, COLOR_ALERT } from '../../shared/data/conectereConfigData';

// React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";

//Queries and Mutations

//Bootstrap and other React components
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';   //Version with support from multiple selections

//Graphing components
import { ScatterChart, Scatter, CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Legend, Cell, LabelList } from 'recharts';


//CONTEXT
import { AuthContext } from '../../shared/context/authContext';
import { AnalyticsContext } from '../../shared/context/analyticsContext';
import { GraphAnalyticsContext } from '../../shared/context/graphAnalyticsContext';      // Graph Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context

//Utils
import { setHeaderColorByStringLength, setHeaderColorRandomCategory } from "../../shared/utils/conectivityUtils";
import { DisplayTeamSelectComponent,  } from "../../shared/utils/userAndTeamUtils";


const AdminTeamsConectivity = () => {

    //vars for multi-select for controlling graph UI
    const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);
    const [selectedTeamsOptionsToEdit, setSelectedTeamsOptionsToEdit] = useState([]);
    const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);  //Note, this stores an array of objects of the select Options.  See react-select

    const[radioByCategory, setRadioByCategory] = useState("ALL");
    const[timeframeRadioValue, setTimeframeRadioValue] = useState("2M");
    const [showAvatars, setShowAvatars] = useState(true); //Toggle switch for whether or not to display employee images in the graph nodes

    const [graphWidth, setGraphWidth] = useState("1200px");
    const [graphHeight, setGraphHeight] = useState("800px");

     //Local graph data for this component
    const [currentGraphData, setCurrentGraphData] = useState(null);             //Holds the particular graph from which to draw the team stats
    const [scatterChartGraphData, setScatterChartGraphData] = useState([]);    //Holds the particular graph data for the scatter chart but futher filtered by any parameters, such as specific teams.  
  
   // Authentication context
    const {
            authState,
            currentUser,
            isSuperAdmin,
            isAdmin,
            permissionAnalytics,
            
    } = useContext(AuthContext);    

   // Customer context
    const {
            customers, users, teams,
            categoryOptions,teamsOptions, customerOptions, usersOptions,
            selectedCustomerOptions,
    } = useContext(CustomerContext);  
    
     //Analytics context
    const {
           analyticsGenerated,
    } = useContext(AnalyticsContext);
 

    const {
            graphDataBuiltFlag, 
            masterGraphData,
            fetchRecordsTrigger, setFetchRecordsTrigger,      
    } = useContext(GraphAnalyticsContext);

  //variables for holding state data 
  const [showModalSummary, setShowModalSummary] = useState(false);  
  const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);


    //Get update page data upon change to auth state or to the analytics generated flag
    useEffect(() => {
        updatePageData();  
    }, [authState, graphDataBuiltFlag]);

    // On ANY page regen, tell our graph analytics component to fetch Graph records if not already fetched
    useEffect(() => {
        if (DEBUG_MODE >= 2) console.log("Tickling graph context");
        setFetchRecordsTrigger(!fetchRecordsTrigger); //Toggle the state on page load causing the graph analytics to check whether to load the context
    }, []);  
    
    
    //grab data needed for rendering the dashboard
    async function updatePageData() {

        if (authState != "signedin") return;
        
        //wait for users, teams, transactions and corresponding analytics to be loaded by context
        if (!graphDataBuiltFlag) return;
        
        if (DEBUG_MODE >= 2) console.log("Updating Page Data", masterGraphData)

      if (masterGraphData !== null) {
          //Set default to ALL Conectivities this YEAR
          const startingGraph = masterGraphData.find(graph => graph.period == "2M" && graph.category== "ALL");
          
          if (startingGraph != undefined) {
            
            setCurrentGraphData(startingGraph); 
            
            } else if (DEBUG_MODE >= 2) console.log("Error - no graph found");
    }
        
       setSelectedUsersOptions ([]);
 
}


    // On any change to current graph or selected team options, regen filtered graph data that is to be displayed 
    useEffect(() => {
        buildGraphData();

    }, [currentGraphData, selectedTeamsOptions]);
  
   function buildGraphData (){
     
        if (currentGraphData == null || currentGraphData == undefined) return;
 
        if (authState !== 'signedin') return null;
        

        if (DEBUG_MODE >= 2) console.log("Building newGraphData", currentGraphData, selectedTeamsOptions);
        
        var tempGraphData = [];
        
        //No selections? Graph ALL teams
        if (selectedTeamsOptions.length == 0) {
  
            tempGraphData = [...currentGraphData.teamStats];

        } else {
            
            //Walk the selected teams and, for each team, push the team stats from the current network graph
            for (var l=0; l < selectedTeamsOptions.length; l++) {
                
                const currentTeamStats = currentGraphData.teamStats.find(teamStats => teamStats.teamID == selectedTeamsOptions[l].id);
                
                if (currentTeamStats != undefined) tempGraphData.push(currentTeamStats);
                
            }
        }
        
        if (DEBUG_MODE >= 2) console.log("Built new data for selected teams", tempGraphData);
        
        setScatterChartGraphData(tempGraphData);
        
   }


  function setNetworkGraph (radioByCategory, timeframeRadioValue){
    
    if (DEBUG_MODE >= 2) console.log("Setting new graph", radioByCategory, timeframeRadioValue);

    //Find the graph within the master set of graphs received from the Analytics Context
    if (masterGraphData !== null) {

        const newGraph = masterGraphData.find(graph => graph.period == timeframeRadioValue && graph.category== radioByCategory);
        
        if (newGraph != undefined) setCurrentGraphData(newGraph); else if (DEBUG_MODE >= 2) console.log("Error - no graph found");
    }
  }
  
  const handleByCategoryChange = (event) => {
    setRadioByCategory(event.target.value);
   setNetworkGraph(event.target.value, timeframeRadioValue);
  };

   const handleRadioChange = (event) => {
     
     setTimeframeRadioValue(event.target.value);
     
    setNetworkGraph(radioByCategory, event.target.value);
     
   };  


   //Component for showing at the top of the page 
    const DashboardHeader = () => {
    
        return (
            <center>
                <div className="teamConectivityBannerHeader">
                    <div className="teamConectivityBannerInnerContainer">
                        <div className = "ContainerVerticalCenter">
                            <div className="ContainerNoHeightCenter teamConectivityBannerTitle">
                                <div style={{color:SOCIAL_COLOR}}> TEAM CONECTIVITY </div>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
    
            );
    };


    //User has elected to view specific employees (nodes) as source
    const handleSelectTeam = (eventKey) => {

      if (DEBUG_MODE >= 2) console.log("User selection updated", eventKey);
      setSelectedTeamsOptions(eventKey);

     };
     
     

 //
 // Component for rendering Scatter chart
 //
 
  const TeamConectivityGraph = ({data, title, height, width}) => {
    
        if (data == null || data == undefined) return null;
        if (height == null || height == undefined) height = 500;
        if (width == null || width == undefined) width = 800;
    
    
        if (DEBUG_MODE >= 2) console.log("Team Connectedness Graph called");
        if (DEBUG_MODE >= 2) console.log("data", data);
        
        
        function setFillColor(entry) {
            
            if (DEBUG_MODE >= 2) console.log("Setting fill color", entry);
            
            if (entry.memberConnectionsInverseNormalizedStandardDeviation < 50 && entry.memberConnectionsNormalizedMean < 50) return COLOR_ALERT;
            if (entry.memberConnectionsInverseNormalizedStandardDeviation < 50 || entry.memberConnectionsNormalizedMean < 50) return COLOR_WARNING_HIGH;
            return COLOR_GOOD;
            
        }
        
        return (
                <div className="teamConectivityChartWrapper">
                     <div className="teamConectivityChartTitle">
                        {title}
                     </div>
                      <ScatterChart 
                          width={width} 
                          height={height} 
                          data={data}
                          margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
                          isAnimationActive={false}
                          >
                        <Scatter data={data} fill={setHeaderColorRandomCategory()} >
                           {data.map((entry, index) => (
                              <Cell 
                                key={`cell-${index}`} 
                                fill={setFillColor(entry)} 
                                />
                            ))}

                            <LabelList dataKey="name" position="bottom"  />
                        </Scatter>
                        
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis 
                            type="number" 
                            dataKey="memberConnectionsNormalizedMean" 
                            name="Density"
                            label={{ value: 'Average Member Participation',  position: 'insideBottom', offset:-20}}
                            // domain={[0, 100]}
                            unit="%"
                        />
                        <YAxis 
                            type="number" 
                            dataKey="memberConnectionsInverseNormalizedStandardDeviation" 
                            name="Distribution"
                            label={{ value: 'Participation Distribution Across Team',  angle: -90, position: 'insideBottomLeft' }}
                            // domain={[0, 100]}
                            unit="%"

                        />
                        <ZAxis type="number" dataKey="memberCount" range={[100, 1000]} name="Members"/>
                        <Tooltip />
                      </ScatterChart>   
              </div>
                
            );        
  };

    //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 
    
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">TEAM CONECTIVITY</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else if (!analyticsGenerated) { //Graphs generated yet?
    return (
        <h2><center>Analyzing . . .</center></h2>
      );
  } else return (

        <div className="adminPage">
            <DashboardHeader />         
    
           <div className="dashboardMenuWrapper">

              <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
        
                  <div  style={{fontSize:"1em", fontWeight:"400", color:"#409cf7", width:"100px", marginRight:"10px"}}>
                      Connectivity Type
                  </div> 
                    <div style={{width:"100%"}}>
                      <RadioGroup row aria-label="byCategory" name="byCategory" value={radioByCategory} onChange={handleByCategoryChange}>
                          <FormControlLabel value="ALL" control={<Radio />} label="All Conectivities" />
                          <FormControlLabel value="BALANCE" control={<Radio />} label="Balance" />
                          <FormControlLabel value="SOCIAL" control={<Radio />} label="Social" />
                          <FormControlLabel value="TEAM" control={<Radio />} label="Team Building" />
                          <FormControlLabel value="PERSONAL" control={<Radio />} label="Personal Growth" />
                          <FormControlLabel value="DEI" control={<Radio />} label="Diversity, Equity & Inclusion" />
                          <FormControlLabel value="CS" control={<Radio />} label="Community Service" />
                      </RadioGroup>
                  </div>
              </div>
     
               <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
                  <div  style={{fontSize:"1em", fontWeight:"400", color:"#409cf7", width:"100px", marginRight:"10px"}}>
                      Period
                  </div>
                  <div style={{width:"100%"}}>
                    <RadioGroup row aria-label="period" name="period" value={timeframeRadioValue} onChange={handleRadioChange}>
                            <FormControlLabel value="DAY" control={<Radio />} label="1D" />
                              <FormControlLabel value="1W" control={<Radio />} label="1W" />
                              <FormControlLabel value="1M" control={<Radio />} label="1M" />
                              <FormControlLabel value="2M" control={<Radio />} label="2M" />
                              <FormControlLabel value="WEEK" control={<Radio />} label="WTD" />
                              <FormControlLabel value="MONTH" control={<Radio />} label="MTD" />
                              
                              {/*
                              <FormControlLabel value="QUARTER" control={<Radio />} label="Quarter" />
                              <FormControlLabel value="YEAR" control={<Radio />} label="Year" />
                              */
                              }
                      </RadioGroup>
                    </div>
                </div>                 

               <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
                  <div  style={{fontSize:"1em", fontWeight:"400", color:"#409cf7", width:"100px", marginRight:"10px"}}>
                      Teams
                  </div> 
                  <DisplayTeamSelectComponent teamsOptions={teamsOptions} handleSelectTeam={handleSelectTeam} isMulti={true} selectedTeamsOptions={selectedTeamsOptions} disabled={false}  />
                </div>
        </div>
    
            
        <TeamConectivityGraph 
            data = {scatterChartGraphData}
            title = "Team Connectedness"
        />        
    </div>
  );
};




export default AdminTeamsConectivity;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023

//  ADMIN for managing New Employee Support program


//Config Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, NEW_EMPLOYEE_SUPPORT_PROGRAM, TEAM_COLOR, COLOR_WHITE, IMAGE_COMMUNITY_1, SLACK_CONSENT_URL} from '../../shared/data/conectereConfigData';

import { HELP_TEXT_ADMIN_SLACK_BUTTON } from '../../shared/data/helpData.js';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';
import {useLocation} from 'react-router-dom';

//React components
import Select from 'react-select';   //Version with support from multiple selections

//Icons
import HighlightIcon from '@mui/icons-material/Highlight';

//Our Components
import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { buildSlackChannelOptions, displaySlackAccessControlButton, disableSlack, sendSlackAuthCodeToLambda, getSlackAuthCode } from "../../shared/utils/slackUtils";
import { HelpButton, EditButton, } from "../../shared/utils/generalUtils";
import { storeUpdatedCustomerConfig }  from "../../shared/utils/databaseUtils";

//3rd Party Components
import Checkbox from '@mui/material/Checkbox';

const AdminSlackIntegration = () => {

	// Context
	const { authState,  currentUser,  isSuperAdmin, isAdmin,  permissionAnalytics  } = useContext(AuthContext);    
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	//Slack Integration
   const [slackChannelOptions, setSlackChannelOptions] = useState([]);
   const [slackChannelSelection, setSlackChannelSelection] = useState(null);
   const [slackChannelCreationEnabled, setSlackChannelCreationEnabled] = useState(true);
   const currentReactPage = useLocation(); //This React reference is updated every time the component loads

	//state vars
	const [inEditMode, setInEditMode] = useState(false);
	const [showModalConfirmation, setShowModalConfirmation] = useState(false);

	//
	// UseEffect for checking whether Slack integration is request in the URL
	// Called from our Company website Slack integration landing page
	// Note, this runs even prior to the user authentication since ultimately this results in a page redirect
	
	useEffect(() => {
 
		//Now, check the URL params
		 const searchParams = new URLSearchParams(currentReactPage?.search);
		 if (DEBUG_MODE >= 1) console.log("URL parser - Current React Page", currentReactPage);
		 if (DEBUG_MODE >= 1) console.log("URL parser - Page React Search Params (after ?)", searchParams);
		 //Does the URL contain an install command to kick off an AUTH 2 process for a cloud provider?
		 if (searchParams.has("install")) {  
			if (DEBUG_MODE >= 1) console.log("Install Cloud State Detected!!");
			const cloud = searchParams.get("install");
			if (DEBUG_MODE >= 1) console.log(" INSTALL =", cloud);
			switch (cloud) {
  
				case 'SLACK':
					if (DEBUG_MODE >= 1) console.log("Install SLACK command detected in URL");
					if (DEBUG_MODE >= 1) console.log("Redirecting user; requesting authorization code from SLACK");
					getSlackAuthCode();
					break;
				
				default:
					if (DEBUG_MODE >= 1) console.log("Unkown auth code detected in URL");
			}
		 }       
	}, []);


	// MASTER SLACK OAuth redirect handler for processing OAuth code in the URL; on initial page LOAD extract the auth code from the URL along with and STATE value
	// Note, we need to run this after the user is authenticated since it results in an API call to our lambda
	//
	useEffect(() => {
	   
	  let isMounted = true;
	  
	  try {
	
		  //Our async function inside of our UseEffect so we can use an AWAIT
		  const enableSlack = async ({code,authorizationStateData }) => {
				 setShowSpinner(true);                    
				 const {lambdaReturnStatus} = await sendSlackAuthCodeToLambda({customerID:currentUser.customerID, authorizationCode:code, authorizationStateData:authorizationStateData});

				 if (DEBUG_MODE >= 1) console.log("SLACK Integration lambda returned", lambdaReturnStatus, isMounted);
				 
				 //Safety check - still mounted or did the page re-render and this is an oldHook?
				 if (isMounted) {
					setShowSpinner(false);                    
					let successMessage;
					if (lambdaReturnStatus === "SUCCESS") {
						successMessage = "Integration with Slack enabled! You and your team members can now view invitations for conectivities, chat with colleagues, participate in conectivities and even cast spotlights on colleagues directly from Slack!  " +
										  "When you close this window, be sure to select a channel for employee Spotlights!";
						setModalGeneralMessage(successMessage);
						setModalGeneralTitle("Success!");
						setShowModalGeneral(true);   
					} else {
						successMessage = "Sorry, something went wrong. We could not enable Slack integration.  ";
						setModalGeneralMessage(successMessage);
						setModalGeneralTitle("Ooops!");
						setShowModalGeneral(true);   
					}
				 }
		   };
   
		   //Need to wait until the current user is fully signed in via Amplify
		   if (authState !== "signedin") return;
   
		   //To avoid multiple calls, ignore the URL params if already logged in and all services enabled
		   if (currentUser.customer.configDetails.slackEnabled) return;
   
		   //Now, check the URL params
		   const searchParams = new URLSearchParams(currentReactPage?.search);
		   if (DEBUG_MODE >= 1) console.log("Slack integration - Current React Page", currentReactPage);
		   if (DEBUG_MODE >= 1) console.log("Slack integration - Page React Search Params (after ?)", searchParams);
   
		   //Does the URL contain an Oauth code for enabling a 3rd party cloud?
			if (searchParams.has("code")) { 
			   if (DEBUG_MODE >= 1) console.log("3rd Party Cloud AUTH CODE Detected!!");
			   const code = searchParams.get("code");
			   const state = searchParams.get("state");
			   if (DEBUG_MODE >= 1) console.log(" STATE =", state); if (DEBUG_MODE >= 1) console.log(" CODE =", code);
			   // setAuthorizationStateData(state);
			   
			   if (state.includes('SLACK')) {
				   if (DEBUG_MODE >= 1) console.log("SLACK authorization code detected in URL; enabling slack");
				   
				  //Safety check against our previously generated UUID
				  const authorizationStateData = window.localStorage.getItem("oauth_state");
				  if (DEBUG_MODE >= 2) console.log("Fetched our dynamic, secure code that we previously stored to browser", authorizationStateData);       
				  
				  //Double safety check - state data must include this user's hashed ID as well as our dynamically generated state data, which we previously cached in the browser
				  if (state.includes(authorizationStateData)) {
					   if (DEBUG_MODE >= 1) console.log("SLACK State Data confirmed in the URL; sending code to our Lambda");
						enableSlack({code,authorizationStateData});   //Call our Async Function to handle calling our Lambda
					   
				   } else {
					   if (DEBUG_MODE >= 1) console.log("INCORRECT SLACK State Data in the URL");
				   }
			   }
			}  
		 } catch (err) {
			if (DEBUG_MODE >= 1) console.error("Error in URL processing UseEffect", err);
		 }

	  //Clear LOAD 
	  return () => {
		 isMounted = false;
		 if (DEBUG_MODE >= 1) console.error("UnMounting UseEffect");
	  };
	}, [authState]);
	
	//Copy the customer's settings upon opening the page or any change to the edit mode
	//Need to fire on both AuthState and CurrentUser to handle both login sequence and disable / enable interctions
	useEffect(() => {
		
	   //Safety check
		if (authState !== "signedin" || !currentUser || !currentUser.customer || !currentUser.customer.configDetails) return;
		 
	  //initPageSettings
	  initSlackSettings(currentUser);
		
	}, [authState, currentUser]);
	
	const initSlackSettings = async (currentUser) => {

	  if (currentUser && currentUser.customer.configDetails) {
		 if (DEBUG_MODE >= 2) console.log("initSlackSettings invoked", currentUser.customer.configDetails);
   
		 if (currentUser.customer.configDetails && currentUser.customer.configDetails.slackEnabled) {
			setSlackChannelCreationEnabled(currentUser.customer.configDetails.slackChannelCreationEnabled ? true : false);
   
			//Fetch Slack Channel Options
			if (DEBUG_MODE >= 2) console.log("Fetching Slack Channel Options from UseEffect");
			setShowSpinner(true);
			const tempOptions = await buildSlackChannelOptions(currentUser.customerID);
			setSlackChannelOptions(tempOptions);
			if (currentUser.customer.configDetails.slackSpotlightChannelID) {
			   setSlackChannelSelection(tempOptions.find(option => option.id === currentUser.customer.configDetails.slackSpotlightChannelID));
			} else {
			   if (DEBUG_MODE >= 2) console.log("Slack enabled but no Spotlight channel set yet; placing in edit mode");
			   setInEditMode(true); //Put in EDIT mode since the user HAS to select a Spotlight Channel!
			}
			setShowSpinner(false);
		 }    
	  }
	};
	
	const handlePutInEditMode = async () => {
		if (DEBUG_MODE >= 2) console.log("User entered edit mode.");
		setInEditMode(true);
	};


	async function handleDisableSlackIntegrationConfirmed() {
	  if (DEBUG_MODE >= 1) console.log("Disabling Slack Integration");
	  setShowModalConfirmation(false);   
	  try { 

		 if (DEBUG_MODE >= 1) console.log("Disabling SLACK integration");
		 setShowSpinner(true);
		 const LambdaReturnObject  = await disableSlack({customerID:currentUser.customerID});
		 
		 if (LambdaReturnObject.lambdaReturnStatus === "SUCCESS") {
			setModalGeneralMessage('Integration with Slack disabled');
			setModalGeneralTitle("Success");
		 } else {
			setModalGeneralMessage("Sorry, something went wrong....");
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
		 }            
		 setShowSpinner(false);        
		 setShowModalGeneral(true);   

	  } catch (err) {
		 if (DEBUG_MODE >= 1) console.error("Error disabling SLACK", err);
		 setModalGeneralMessage("Sorry, something went wrong...." + err);
		 setModalGeneralTitle("Ooops!");
		 setShowModalGeneral(true);         
	  }
	}   
 
	async function handleToggleSlackIntegration() {
	  if (DEBUG_MODE >= 1) console.log("Processing SLACK Toggle", currentUser);
	  try { 
		 // DISABLE SLACK
		 if (currentUser.customer.configDetails.slackEnabled) {
			setShowModalConfirmation(true);   
		 } else {
			// ENABLE SLACK
			if (DEBUG_MODE >= 1) console.log("Invoking OAuth Flow to request authorization code from SLACK");
			await getSlackAuthCode();
		  }
	  } catch (err) {
		 if (DEBUG_MODE >= 1) console.error("Activating SLACK");
		 
	  }
	}   
	
   //Process the SAVE function from Edit mode for updating customer
	async function handleConfirmationEdit (e) {
		e.preventDefault();     //prevent React page refresh
		
		setInEditMode(false); // Shut down the edit mode
		if (!slackChannelSelection && slackChannelSelection.id ) {
		 if (DEBUG_MODE >= 2) console.log("Error - no Slack channel selected!");
			setModalGeneralMessage("No Spotlight Channel selected");
			setModalGeneralTitle("Ooops!");                    
		   return;
		}
	  //  setShowSpinner(true); //Show spinners
		
		try {
			

	   //Note - Slack Integration Boolean is controlled by our backend Lambda; we need only write the channel ID
		 let tempConfigToEdit = {
				id: currentUser.customer.configDetails.id,
				  slackSpotlightChannelID: (slackChannelSelection && slackChannelSelection.id ? slackChannelSelection.id : null),   //ID of the channel selected for Spotlights
				  slackChannelCreationEnabled: (slackChannelCreationEnabled === true ? true : false),   
				  
			}; 
			
			if (DEBUG_MODE >= 2) console.log("Writing customer config", tempConfigToEdit);
			const tempInsertedConfig = await storeUpdatedCustomerConfig(tempConfigToEdit);
			if (DEBUG_MODE >= 2) console.log("Successfully edited customer config", tempInsertedConfig );

			setModalGeneralMessage("Changes saved ");
			setModalGeneralTitle("Success!");       
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error editing customer config:', err);
			setModalGeneralMessage("Unable to save changes to customer.  ", err);
			setModalGeneralTitle("Ooops!");                    

		}
		
	  //  setShowSpinner(false); //Hide spinners
		setShowModalGeneral(true); //display error message
	}
 
   const handleChannelSelection = (selectedChannelOption) => {
	  if (DEBUG_MODE >= 2) console.log("Channel selected", selectedChannelOption);
	  setSlackChannelSelection(selectedChannelOption);
   };

	const handleCancel = () => {
		setInEditMode(false);
		if (DEBUG_MODE >= 2) console.log("Exiting edit mode");
	};
	
	//Render function for authenticated user 
	//confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
  } else  return (

	<Authenticator>

		 <ModalNoBackgroundFixed showModal={showModalConfirmation} closeCallback={()=>setShowModalConfirmation(false)} cardColor={TEAM_COLOR}  closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR}>
			<div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
				<div className="modalNoBkgImage ccImgXXXXLSquare"  style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
				</div>
				 <div className="ContainerVerticalStart" style={{height:"100%"}}>
					 <h3 className="blue" style={{flex:"0"}}> Disable integration with Slack? 🥺</h3>
					  <p className="TextStyle4 black" style={{flex:"1",  paddingTop:"clamp(8px,2vw,20px)"}}>
						 Just to double check, are you sure you want to disconnect Slack and Conectere for your <b>entire</b> company?  
						 You and your team members won't be able to view progress rings, participate in conectivities or cast spotlights directly from Slack.       
					  </p>

					 <div className="adminConectivitiesTemplateButtonsWrapper" style={{paddingTop:"clamp(8px,2vw,20px)", flex:"0"}}>
						 <button className="adminConectivitiesButtonStyle1" aria-label="done" onClick={handleDisableSlackIntegrationConfirmed} >
							 Disable Integration
						 </button>
						 <button className="adminConectivitiesButtonStyle2" style={{marginLeft:"20px"}} aria-label="skip" onClick={()=>setShowModalConfirmation(false)} >
							 Cancel
						 </button>
					 </div>
				</div>
			</div>
		 </ModalNoBackgroundFixed >
		 
		<div className="adminPage">
			<div className="dashboardContainer boxShadow" style={{position:"relative"}}>
				<div className="dashboardTitle ">
					<div className="ContainerNoHeightSpaceBetween" >
						<div className="ContainerNoHeightCenter">
							<div>SLACK</div>
						</div>
					</div>
				 </div>   
				
					 <div className = "ContainerVerticalCenter" style={{padding:"0rem 3rem"}}>
						 <div className="ContainerNoHeightCenter purple" style={{position:"relative", margin:"2rem 0rem 0rem 0rem"}}>
							 <h4> {currentUser && currentUser.customer.configDetails.slackEnabled ? 'Integration with Slack Enabled 👍' : 'Integration with Slack Disabled 🥺'}</h4>
						</div>
						<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
						   Link your Conectere account to Slack 👋!!  Enabling integration with Slack allows employees to connect with colleagues, view conectivities, 
						   chat with team members and even cast Spotlights to recognize and celebrate the achievements of colleagues, all from their Slack workspace!
						</p>   
						<div className="ContainerVerticalCenter" style={{padding:"1.0rem 0rem 1.0rem 0rem"}}>
							{displaySlackAccessControlButton({currentUser, isAdmin, clickCallback:(e) => {
							   handleToggleSlackIntegration();
							}})}
						</div>   

						{currentUser && currentUser.customer.configDetails.slackEnabled ?  
						   <>
							   <div className="ContainerNoHeight " style={{marginTop:"50px"}}>
								  <div className="ContainerVerticalStart very-light-grey-background" style={{borderRadius:"10px"}}>
										<div className="ContainerNoHeightCenter" style={{}}>
										  <span style={{paddingRight:"5px"}}>Select the channel in Slack to use for employee Spotlights</span>
										  <HighlightIcon style={{fontSize:"32px", color:"gold", transform: "rotate(-145deg) translate(0,0px)"}}/> 
			
									   </div>
			
			
										  <div style={{minWidth:"400px", zIndex:'100'}}>
											 <Select  
												   isMulti={false} 
												   isClearable={false}
												   required={true}
												   name="channelDropdown" 
												   options={slackChannelOptions} 
												   isOptionDisabled={(option) => option.isdisabled}
												   isDisabled={!inEditMode || !currentUser.customer.configDetails.slackEnabled}   
												   onChange={handleChannelSelection} 
												   value={slackChannelSelection}  
												   styles={{
													   control: (baseStyles, state) => ({
														 ...baseStyles,
														 borderColor: slackChannelSelection ? 'grey' : 'red',
													   }),
												   }}
												   placeholder=" - select  -"
												   // formatOptionLabel={channel => (
												   //     <div className="ContainerNoHeightFlexLeft noWrap TextStyle4"  style={{whiteSpace:"nowrap"}}>#{channel.name} </div>
												   // )}
												/>
											 </div>
			
									   {slackChannelSelection && slackChannelSelection.id ? null : <div className="TextStyle3 red bold" style={{paddingTop:"1.0rem"}}><em>You must select a channel in order for Spotlights to post to your Slack workspace</em> </div> }
			
									  <div className="ContainerNoHeightFlexLeft " style={{paddingTop:"2.0rem"}}>
											   <Checkbox
												checked={slackChannelCreationEnabled}
												disabled={!inEditMode || !currentUser.customer.configDetails.slackEnabled}
												onChange={()=>{
												  setSlackChannelCreationEnabled(!slackChannelCreationEnabled);
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											  />
											  <div className="TextStyle4">Dynamic channel creation</div>
											  <HelpButton 
											  hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide'
											  hoverText={"When enabled, we'll dynamically create private Slack channels for your team's conectivities and will synchronize messages between Slack and Conectere.  " +
											  "We'll auto-archive the channels when the conectivities are complete.  If disabled, your teams will not be able to chat in Slack when connecting in conectivities."}  /> 
									  </div>
								 </div>  
   
								 <div style={{width:"2.0rem"}}>
									 {!inEditMode ? 
										 <EditButton onClickCallback={handlePutInEditMode} hoverText="Edit"/> 
									 : 
										 null 
									 }
								 </div> 
	  
							  </div>
   
							  <p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
								 ✅ TIP: We synchronize conectivities, calendar invitations and chat messages to Slack according to a user's email address.  Have your 
								 team members use the same email addresses for Slack and Conectere to ensure full integration and ease of use.
							  </p>
						   </>
						
						: null }
	
					 </div>            

				<div className = "ContainerNoHeightFlexEnd buttonWrapperWithMargin fullWidth" style={{height:"2.0rem"}} >
				   {!inEditMode ? 
						null
				   :
					   <> 
						  <button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleConfirmationEdit} > Save </button>
						  <button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleCancel} > Cancel </button>
					   </>
				  }
				</div>
			</div> 
		</div>   
	</Authenticator>
 
  );
};


export default AdminSlackIntegration;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023

//  ADMIN for managing New Employee Support program

//Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, NEW_EMPLOYEE_SUPPORT_PROGRAM, TEAM_COLOR, COLOR_WHITE, COLOR_GOLD, COLOR_BRONZE, COLOR_SILVER, COLOR_BLACK, CHALLENGE_IMAGE} from '../../shared/data/conectereConfigData';
import { HELP_TEXT_CHALLENGE_ADMIN_PAGE, HELP_TEXT_CHALLENGE_ADMIN_PAGE_2, HELP_TEXT_CHALLENGE_ADMIN_PAGE_3, HELP_TEXT_CHALLENGE_ADMIN_PAGE_4, HELP_TEXT_CHALLENGE_ADMIN_PAGE_5 } from '../../shared/data/helpData.js';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Bootstrap and other React components

import Switch from '@mui/material/Switch';

//Icons

import HighlightIcon from '@mui/icons-material/Highlight';


//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { HelpButton, EditButton, } from "../../shared/utils/generalUtils";
import { storeUpdatedCustomerConfig }  from "../../shared/utils/databaseUtils";


//Icons

//Material-X

const AdminChallenges = () => {

	// Context
	const {  authState,  currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);    
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
   
	//Master Config Details
	const [configDetailsToEdit, setConfigDetailsToEdit] = useState(null);
	const [inEditMode, setInEditMode] = useState(false);
	
	const RESET_REWARDS_CAPS = {challengeCoinCap: 25,	 challengeBadgesDEICap: 1, challengeBadgesCSCap:1, challengeRecognitionsCap:1, disableAIChallengeRecognitionMessages:false };

	//Copy the customer's settings upon opening the page or any change to the edit mode
	useEffect(() => {
		
	   //Safety check
		if (authState !== "signedin" || !currentUser || !currentUser.customer || !currentUser.customer.configDetails) {
			if (DEBUG_MODE) console.log("NOT YET", authState, currentUser);
			return;
		}

		buildPageData();
	}, [authState, currentUser]);


	async function buildPageData() {
		if (!currentUser || !currentUser.customer || !currentUser.customer.configDetails) return;
		try {
			let configDetails = {...currentUser.customer.configDetails };    //Make a copy
			setConfigDetailsToEdit(configDetails);                          //Store in our local React state var for the page
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error building page data", err);
		} 
	}

	const handlePutInEditMode = () => {
		if (DEBUG_MODE >= 2) console.log("User entered edit mode.");
		setInEditMode(true);
	};

   //Process the SAVE function from Edit mode for updating customer
	async function handleConfirmationEdit (e) {
		e.preventDefault();     //prevent React page refresh
		setShowSpinner(true); //Show spinners
		setInEditMode(false); // Shut down the edit mode
		try {
			
			//construct an object of just the fields exposed by this page
			let tempConfigToEdit = {
				id: configDetailsToEdit.id,
				enableAdminChallenges: (configDetailsToEdit.enableAdminChallenges ? true : false),					        //Allow Admin to launch conectivities as challenges
				enableEmployeeChallenges: (configDetailsToEdit.enableEmployeeChallenges ? true : false),				    //Allow all employees to launch conectivities as challenges
				enableEmployeeChallengeRewards: (configDetailsToEdit.enableEmployeeChallengeRewards ? true : false),		//Allow employees to specify rewards
				challengeCoinCap: (configDetailsToEdit.challengeCoinCap >= 0 ? configDetailsToEdit.challengeCoinCap : 25),						    //Max # of coins a non-admin can specify for a challenge
				challengeBadgesDEICap: (configDetailsToEdit.challengeBadgesDEICap >= 0 ? configDetailsToEdit.challengeBadgesDEICap : 1),		    //Max # of DEI badges a non-admin can specify for a challenge
				challengeBadgesCSCap: (configDetailsToEdit.challengeBadgesCSCap >= 0 ? configDetailsToEdit.challengeBadgesCSCap : 1),			    //Max # of CS badges a non-admin can specify for a challenge
				challengeRecognitionsCap: (configDetailsToEdit.challengeRecognitionsCap >= 0 ? configDetailsToEdit.challengeRecognitionsCap : 1),   //Max # of recognitions a non-admin can specify for a challenge
				disableAIChallengeRecognitionMessages: (configDetailsToEdit.disableAIChallengeRecognitionMessages >= 0 ? true : false), //Controls whether we use static messages or ChatGPT-generated messages when recognizing winners of conectivity challenges
			}; 
			
			if (DEBUG_MODE >= 2) console.log("Writing customer config", tempConfigToEdit);
			const tempInsertedConfig = await storeUpdatedCustomerConfig(tempConfigToEdit);
			if (DEBUG_MODE >= 2) console.log("Successfully edited customer config", tempInsertedConfig );

			setModalGeneralMessage("Changes saved ");
			setModalGeneralTitle("Success!");       
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error editing customer config:', err);
			setModalGeneralMessage("Unable to save changes to customer.  ", err);
			setModalGeneralTitle("Ooops!");                    

		}
		
		setShowSpinner(false); //Hide spinners
		setShowModalGeneral(true); //display error message
	}
 
	const handleResetEditForm = () => {
		//Reset any changed - Load New Employee Support options based on customer data
		buildPageData();
		setInEditMode(false);
		if (DEBUG_MODE >= 2) console.log("Exiting edit mode; Restored original config data");
	};
	
	function displayRewardCaps() {
		return(
			<div className={`adminChallengesCapsRegion ContainerVerticalCenter very-light-grey-background borderRadius  ${(!configDetailsToEdit || !configDetailsToEdit.enableEmployeeChallengeRewards ? 'disabled' : '')} `}  >
				<div className='ContainerNoHeightCenter' style={{zIndex:'7300'}}>
					<div>Max rewards per challenge participant</div>								
					<HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_ADMIN_PAGE_5}  /> 
				</div>

				<div className="ContainerNoHeightCenter fullWidth " style={{padding:'clamp(4px,0.5v,8px)'}}>
					<div className='ContainerNoHeightCenter ' >
						<input
							style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
							name="coinCap"
							className='TextStyle3 grey-text border borderRadius'
							type="number"
							value={configDetailsToEdit && configDetailsToEdit.challengeCoinCap ? configDetailsToEdit.challengeCoinCap : 20}
							onChange={(e)=>{setConfigDetailsToEdit({ ...configDetailsToEdit, challengeCoinCap: e.target.value })}}
							required
							disabled={!inEditMode || !configDetailsToEdit || !configDetailsToEdit.enableEmployeeChallengeRewards}
							min="0" max="9999"
						/>
						<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

					</div>

					<div className='ContainerNoHeightCenter' >
						<input
							style={{width:'clamp(16px,4vw,40px)', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
							name="deiCap"
							className='TextStyle3 grey-text border borderRadius'
							type="number"
							value={configDetailsToEdit && configDetailsToEdit.challengeBadgesDEICap ? configDetailsToEdit.challengeBadgesDEICap : 0}
							onChange={(e)=>{setConfigDetailsToEdit({ ...configDetailsToEdit, challengeBadgesDEICap: e.target.value })}}
							required
							disabled={!inEditMode ||  !configDetailsToEdit || !configDetailsToEdit.enableEmployeeChallengeRewards}
							min="0" max="9"
						/>
						<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
					</div>
					<div className='ContainerNoHeightCenter' >
						<input
							style={{width:'clamp(16px,4vw,40px)', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
							name="csCap"
							className='TextStyle3 grey-text border borderRadius'
							type="number"
							value={configDetailsToEdit && configDetailsToEdit.challengeBadgesCSCap ? configDetailsToEdit.challengeBadgesCSCap : 0}
							onChange={(e)=>{setConfigDetailsToEdit({ ...configDetailsToEdit, challengeBadgesCSCap: e.target.value })}}
							required
							disabled={!inEditMode || !configDetailsToEdit || !configDetailsToEdit.enableEmployeeChallengeRewards}
							min="0" max="9"
						/>
						<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
					</div>
					<div className='ContainerNoHeightCenter' >
						<input
							style={{width:'clamp(16px,4vw,40px)', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
							name="recognitionCap"
							className='TextStyle3 grey-text border borderRadius'
							type="number"
							value={configDetailsToEdit && configDetailsToEdit.challengeRecognitionsCap ? configDetailsToEdit.challengeRecognitionsCap : 0}
							onChange={(e)=>{setConfigDetailsToEdit({ ...configDetailsToEdit, challengeRecognitionsCap: e.target.value })}}
							required
							disabled={!inEditMode || !configDetailsToEdit || !configDetailsToEdit.enableEmployeeChallengeRewards}
							min="0" max="9"
						/>
						<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
					</div>
				</div>
			</div>
		)
	}

	function handleEnableToggle() {
		//Toggle enable and reset the other options
		let tempConfig = { ...configDetailsToEdit,  ...RESET_REWARDS_CAPS}
		if (configDetailsToEdit.enableAdminChallenges) tempConfig = { ...tempConfig, enableAdminChallenges:false, enableEmployeeChallenges:false, enableEmployeeChallengeRewards:false };
		else  tempConfig = { ...tempConfig, enableAdminChallenges:true, enableEmployeeChallenges:true, enableEmployeeChallengeRewards:true };
		setConfigDetailsToEdit(tempConfig);
	}

	function handleAllowEmployeeRewardsToggle() {
		//Toggle enable and reset the other options
		let tempConfig = { ...configDetailsToEdit,  ...RESET_REWARDS_CAPS}
		if (configDetailsToEdit.enableEmployeeChallenges) tempConfig = { ...tempConfig,  enableEmployeeChallenges:false, enableEmployeeChallengeRewards:false  };
		else  tempConfig = { ...tempConfig, enableEmployeeChallenges:true, enableEmployeeChallengeRewards:true };
		setConfigDetailsToEdit(tempConfig);
	}
	function displayOptions() {
		return (
			<div className='ContainerVerticalCenter '>
				<div className="adminChallengesEnableRegion ContainerNoHeightCenter" style={{zIndex:'7777'}}>
					<div> Enable challenges? </div> 
					<Switch
						name="enableNewEmployeeSupportProgram"
						checked={(configDetailsToEdit && configDetailsToEdit.enableAdminChallenges ? true : false)}   
						onChange={handleEnableToggle}
						color="primary"
						disabled={inEditMode ? false : true}
					/>
					<HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_ADMIN_PAGE_2}  /> 
				</div> 	
				<div className={`adminChallengesSettingsRegion ContainerVerticalCenter borderRadius ${(!configDetailsToEdit || !configDetailsToEdit.enableAdminChallenges ? 'disabled' : '')} `}  >
					<div className=" ContainerNoHeightSpaceAround fullWidth" style = {{padding:"5px 10px"}}>
						<div className="ContainerNoHeightCenter" style={{zIndex:'7500'}}>
							<div> Can all employees launch challenges? </div> 
							<Switch
								name="enableNewEmployeeSupportProgram"
								checked={(configDetailsToEdit && configDetailsToEdit.enableEmployeeChallenges ? true : false)}   
								onChange={handleAllowEmployeeRewardsToggle}
								color="primary"
								disabled={inEditMode ? false : true}
							/>
							<HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_ADMIN_PAGE_3}  /> 
						</div> 
						<div className="ContainerNoHeightCenter style={{zIndex:'7400'}}">
							<div> Can employees give rewards? </div> 
							<Switch
								name="enableNewEmployeeSupportProgram"
								checked={(configDetailsToEdit && configDetailsToEdit.enableEmployeeChallengeRewards ? true : false)}   
								onChange={()=>{setConfigDetailsToEdit({ ...configDetailsToEdit, enableEmployeeChallengeRewards: !configDetailsToEdit.enableEmployeeChallengeRewards })}}
								color="primary"
								disabled={inEditMode && configDetailsToEdit && configDetailsToEdit.enableEmployeeChallenges ? false : true}
							/>
							<HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_ADMIN_PAGE_4}  /> 
						</div> 
					</div>
					{displayRewardCaps()}
				</div>
			</div>
		)
	}
	//Render function for authenticated user 
	//confirm access to this page
  	if (!isSuperAdmin && !isAdmin) return null;
	else  return (
		<Authenticator>
			<div className="adminPage adminChallengesPage">
				<div className="dashboardContainer boxShadow" style={{position:"relative", overflow:'visible'}}>
					<div className="dashboardTitle ">
						<div className="ContainerNoHeightSpaceBetween" >
							<div className="ContainerNoHeightCenter">
								<div>Company Challenges</div>
								<HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_ADMIN_PAGE}  /> 
							</div>
							<div className="ContainerNoHeightCenter">
								{!inEditMode ? 
									<EditButton onClickCallback={handlePutInEditMode} hoverText="Edit"/> 
								: 
									null 
								}
							</div>
						</div>
					</div>   
					

					<div className="ContainerVertical fullWidth whiteBackground isHeader" style={{marginLeft:"clamp(2px,0.5vw,4px)", padding:"clamp(2px,0.5vw,4px)" }}>
					<h3 className="purple"  style={{padding:"clamp(4px,1vw,10px) 0"}}> 🏃🧘‍♀️🏋️‍♀️ Who's ready for a challenge?? 🏃🧘‍♀️🏋️‍♀️ </h3> 

					{displayOptions()}
					
					<div className="ContainerNoHeightCenter wrap" style={{padding:"2.0rem"}}>
						<div style={{flex:"1"}}> 
								<p className="TextStyle3 grey-text">Corporate challenges provide wonderful opportunities to get together, collaborate toward a common goal, develop new skills that can generate positive outcomes for the business.  
									Whether team-building activities like 'Get Your Steps!' or mentorship initiatives or community-service events, corporate challenges can provide many values! </p>
								<div className="TextStyle4 purple">🏆 Rapport</div>
								<div className="TextStyle3 grey-text" style={{paddingLeft:"clamp(8px,3vw,24px)"}}>Challenges help employees develop new relationships and strengthen existing ones. This can lead to employees feeling more confident about sharing ideas with people they're comfortable with, and can help build collaboration. </div>
								<div className="TextStyle4 purple" style={{paddingTop:"1.0rem"}}>🏆 Innovation</div>
								<div className="TextStyle3 grey-text" style={{paddingLeft:"clamp(8px,3vw,24px)"}}>Challenges encourage innovation by bringing companies together to develop new ideas, technologies, and solutions. </div>
								<div className="TextStyle4 purple" style={{paddingTop:"1.0rem"}}>🏆 Perseverance</div>
								<div className="TextStyle3 grey-text" style={{paddingLeft:"clamp(8px,3vw,24px)"}}>Challenges help people develop perseverance, which is the ability to continue trying to achieve something despite difficulties.</div>

								<p className="TextStyle3 grey-text" style={{paddingTop:"1.0rem"}}> Once enabled you and (optionally) individual employees can can launch
								any conectivity as a fun challenge for their teams and colleagues! You can even set goals and specify rewards, which will automatically be distributed at the end of the challenge. </p>
						</div>    
						<div className="imageContainer" style={{flex:"1", borderRadius:'unset'}}>							
							<img  className="ccImgXXXXXXXL" src={CHALLENGE_IMAGE} alt=''/> 
						</div>
					</div>

						{!configDetailsToEdit ? null : null

						}
					</div> 


					{!inEditMode ? 
						null
					:
					<div className = "ContainerNoHeightFlexEnd buttonWrapperWithMargin fullWidth" >
						<button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleConfirmationEdit} > Save </button>
						<button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleResetEditForm} > Cancel </button>
					</div>
					}
				
				</div> 
			</div>   
			
		</Authenticator>
 
  );
};


export default AdminChallenges;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import './notificationsContext.css';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';

//Icons
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useState, useContext, useRef } from "react";
import { generateClient } from 'aws-amplify/api';   // Amplify V6
import { Navigate  } from "react-router-dom";

//User Authentication context
import { AuthContext } from './authContext';

//Queries and Mutations
import { getNotificationsByUser, listNotifications, getNotificationsByUserByExpirationDate} from '../graphql/queries';
import { updateNotification, createNotification} from '../graphql/mutations';
import { getNotificationTypes, listNotificationTypes,} from '../graphql/queries';

//Subscriptions
// https://docs.amplify.aws/lib/graphqlapi/subscribe-data/q/platform/js/
import { onCreateNotificationForUser, onUpdateNotificationForUser } from "../graphql/subscriptions";

//Our components
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";

//Bootstrap components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Material UI and my components therefrom
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

//Utils
import { truncateIntegerByThousand, setRankBackground, setBackgroundByRawScore, formatDays, timeAgo } from "../utils/generalUtils";
import { getUserInitials } from "../utils/userAndTeamUtils";
import { invokeAPI, fetchNotificationTypes, fetchNotificationsByUser } from "../utils/databaseUtils";

import moment from 'moment';

const API = generateClient(); //Amplify V6
const NotificationContext = React.createContext();
const NotificationsProvider = ({ children }) => {

	// Authentication context
	 const {
			  authState, currentUser,
	 } = useContext(AuthContext);
	 
	 //state variables for managing notifications for the current user
	 //these will all be available to the the wrapped components
	 
	 const [notifications, setNotifications] = useState([]);
	 const [notificationTypes, setNotificationTypes] = useState([]);
	 const [notificationTypesLoaded, setNotificationTypesLoaded] =useState(false);
	 const [showModalNotifications, setShowModalNotifications] = useState(false);

	 //state variable for invoking (rendering) React redirct function
	 const [redirectToPage, setRedirectToPage] = useState(null);    
	 const [notificationClicked, setNotificationClicked] = useState(null);    
	 const [notificationClickedTriggerOtherPages, setNotificationClickedTriggerOtherPages] = useState(false);    
	 const [notificationRedirectHandled, setNotificationRedirectHandled] = useState(true);  //Initialialize for TRUE so that a page refresh doesn't redisplay the modal; this will be set to false upon viewing the notifications
	 const [redirectState, setRedirectState] = useState(null);
	 
	 // Get data when user authentication changes
	 useEffect(() => {
		  fetchPageData();
	 },[authState]);


	 //Set up subscriptions to receive user notifications upon creation and updates for this user
	 // Note, we need to make use either Functional State Data Updates or use Ref() to get access to current values of state data
	 // since this UseEffect call is called upon mount (Auth change)
	 // https://reactjs.org/docs/hooks-reference.html#functional-updates
	 //https://stackoverflow.com/questions/69285519/access-latest-value-of-state-in-aws-amplify-subscriptions-react

	 const notificationsRef = useRef();  //Defining a React Reference so that we can use the current state data during this useEffect call

	 useEffect(() => {
		notificationsRef.current = notifications; //Update on every state change
	 });  
	 
	 //Functions and state data for real-time updates
	 const [notificationSubscriptionSetUp, setNotificationSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	
	 useEffect(() => {
		  if (!notificationSubscriptionSetUp && authState === 'signedin'  && currentUser) {
				
				if (DEBUG_MODE >= 1) console.log("Setting up subscription for notifications created for user", currentUser);
				
			  const subscription = API.graphql({
					 query:onCreateNotificationForUser,
					 variables: {
								userID: currentUser.id,
						  }
				})
				.subscribe ({
					 next: messageData => {
						  try {
								if (DEBUG_MODE >= 2) console.log("New notification data received via subscription",messageData);
								if (messageData.data.onCreateNotificationForUser !== null) {
									 
									 const newNotification = messageData.data.onCreateNotificationForUser;
									 
									 // if (DEBUG_MODE >= 2) console.log("New notification extracted from message",newNotification);
									 
									 const tempNotifications = [...notificationsRef.current];   //Get our state data for existing notifications

									  if (DEBUG_MODE >= 2) console.log("Current notifications",tempNotifications);

									 tempNotifications.push(newNotification);    //Add the new notification
									 
									 //Update system-wide state data with the new notifications
									 
									//Not needed, but reduce to only any those that are active and the user has not marked as READ
									 const filteredNotifications = tempNotifications.filter(activeNotification);
				
									 //Sort the Notifications      
									 filteredNotifications.sort(compareNotifications);
												
									 setNotifications(filteredNotifications);
									 
									 // if (DEBUG_MODE >= 2) console.log("Active Notifications after CREATE from Subscription", filteredNotifications);
									 
								 }
						  } catch (err) {
								if (DEBUG_MODE >= 1) console.log("Error processing notification create subscription message",messageData);

						  }
					 },
					 error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setNotificationSubscriptionSetUp(true);
			  return () => {
					 subscription.unsubscribe();
					 setNotificationSubscriptionSetUp(false);
					 if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE NOTIFICATIONS");
				};
 
		  }
	 },[authState]);                     //Call function when a change to authState occurs
		

	 const [notificationUpdateSubscriptionSetUp, setNotificationUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	 
	 useEffect(() => {
		  if (!notificationUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
				
				if (DEBUG_MODE >= 1) console.log("Setting up subscription for UPDATES for notifications for this user", currentUser);
				
			  const subscription = API.graphql({
					 query:onUpdateNotificationForUser,
					 variables: {
								userID: currentUser.id,
						  }
				})
				.subscribe ({
					 next: messageData => {
						  try {
								// if (DEBUG_MODE >= 2) console.log("New notification UPDATE data received via subscription",messageData);
								if (messageData.data.onUpdateNotificationForUser !== null) {
									 
									 const updatedNotification = messageData.data.onUpdateNotificationForUser;
									 
									 // if (DEBUG_MODE >= 2) console.log("Notification update extracted from message",updatedNotification);
									 
									 const tempNotifications = [...notificationsRef.current];   //Get our state data for existing notifications

									 //  if (DEBUG_MODE >= 2) console.log("Current notifications",tempNotifications);
									  
									  const index = tempNotifications.findIndex(notification => notification.id == updatedNotification.id);
									  
									  if (index > -1) {


									 //Update system-wide state data with the received object
									 //Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
									 tempNotifications[index] = updatedNotification;    //Replace the existing notification with the updated one just received
									 
									//Reduce updated list to only any those that are active and the user has not marked as READ
									 const filteredNotifications = tempNotifications.filter(activeNotification);
				
									 //Sort the Notifications      
									 filteredNotifications.sort(compareNotifications);
												
									 setNotifications(filteredNotifications);
									 
									 // if (DEBUG_MODE >= 2) console.log("Active Notifications after UPDATE from Subscription", filteredNotifications);
									  } else {
										  //  if (DEBUG_MODE >= 2) console.log("Error - no matching notification found to update", updatedNotification, tempNotifications);
									  }
									 
								 }
						  } catch (err) {
								if (DEBUG_MODE >= 2) console.log("Error processing notification update subscription message",messageData);

						  }
					 },
					 error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setNotificationUpdateSubscriptionSetUp(true);
			  return () => {
					 subscription.unsubscribe();
					 setNotificationUpdateSubscriptionSetUp(false);
					 if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE NOTIFICATIONS");
				};
 
		  }
	 },[authState]);                     //Call function when a change to authState occurs
	 
	
	 async function fetchPageData() {
		  
		  if (authState != "signedin") return;

		  //Get types
		  try {
		  
				// grab all NotificationTypes
				var tempNotificationTypes = await fetchNotificationTypes();
				// if (DEBUG_MODE >= 2) console.log("Fetched NotificationTypes:", tempNotificationTypes);

				//Sort the NotificationTypes
				tempNotificationTypes.sort(compareNotificationTypes);
				  
				setNotificationTypes(tempNotificationTypes);
				setNotificationTypesLoaded(true);
	

		  } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching page data', err); }        
 
		  //Load user's notifications   
		  if (currentUser) loadUserNotifications();
		  
	 }

	 function activeNotification(notification) {
		  // if (DEBUG_MODE >= 2) console.log("Checking whether the notification is active or has expired", notification);
		  
		  if (notification.readByUser) return false; //Not active since already read
		  
		  // get time set in user's computer time / OS
		  const currentDateTime = moment(); 
		  
			// transform expiration time into a Moment time object
		  const expirationDateTime = moment(notification.expiration, "YYYY MM DDTHH mm ssZ");
		 
		  if (expirationDateTime.isBefore (currentDateTime)) return false;  //not active since expired
		 
		  return true; //still active; not expired
	 }
  
  
	 async function markAllNotificationsRead () {
		  
		  try {    
				//Mark all of the notifications as READ BY USER
				for (var j=0; j<notifications.length; j++) {
	 
					 const tempNotification = await invokeAPI(updateNotification, 'updateNotification', {id: notifications[j].id, readByUser:true});					 
					 // if (DEBUG_MODE >= 2) console.log("Updated Notification as READ BY USER", tempNotification);
				} 
				
				setNotifications([]);    //Clear all of our local notifications since we marked them as READ BY USER, although not needed since our subscription will handle anyway
				
			  
		  } catch (err) {
				
				if (DEBUG_MODE >= 2) console.log("Error updating notifications as READ BY USER", err);

		  }
	 }
	 
	async function loadUserNotifications () {
 
		//Safety check
		if (!currentUser || !currentUser.id) return;
			
		try {

			const tempNotifications = await fetchNotificationsByUser({userID:currentUser.id});
			if (DEBUG_MODE >= 2) console.log("Fetched notifications for user ", tempNotifications);

			//Reduce to only any those that are active and the user has not marked as READ
			const filteredNotifications = tempNotifications.filter(activeNotification);

			//Sort the Notifications
			filteredNotifications.sort(compareNotifications);					
			setNotifications(filteredNotifications);

			// if (DEBUG_MODE >= 2) console.log("Active Notifications", filteredNotifications);

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error fetching notifications", err);
			return;
		}			
	}

	 //Functions for sorting NotificationTypes based on last name & first name
	 function compareNotificationTypes(a, b) {
	 
		  if (a.notificationType.toUpperCase() > b.notificationType.toUpperCase()) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
				return 1;
				}
		  if (a.notificationType.toUpperCase() < b.notificationType.toUpperCase()) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
				return -1;
		  }
		  
		  // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
		  return 0;
	 
	 }


	 //Functions for sorting Notifications based when created
	 function compareNotifications (a, b) {
	 
		  if (a.createdAt < b.createdAt) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
				return 1;
				}
		  if (a.createdAt > b.createdAt) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
				return -1;
		  }
		  
		  // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
		  return 0;
	 
	 }


	 function constructPushNotificationText(notificationTypeToPush, userForPush, sendingUser, dynamicMessage) {

		  var returnText = notificationTypeToPush.text;
		  let senderInitials='', senderName='';
		  if (sendingUser) {
				senderInitials = getUserInitials(sendingUser);
				senderName = (sendingUser == null ? "" : sendingUser.firstName + " " + sendingUser.lastName);
		  }
		  
		  switch (notificationTypeToPush.notificationType) {
			 
				case "BALANCE_INVITATION_RECEIVED":
					 returnText = senderInitials + " " + notificationTypeToPush.text;
					  break;
				case "RECOGNITION_RECIEVED":
					 returnText = senderInitials + " " + notificationTypeToPush.text;
					  break;
				case "SOCIAL_INVITATION_RECEIVED":
					 returnText = senderInitials + " " + notificationTypeToPush.text;
					  break;
				case "TEAM_INVITATION_RECEIVED":
					 returnText = senderInitials + " " + notificationTypeToPush.text;
					  break;
				case "PERSONAL_INVITATION_RECEIVED":
					 returnText = senderInitials + " " + notificationTypeToPush.text;
					  break;
				case "NEW_MESSAGE":
					 returnText = senderName + ": " + dynamicMessage;
					 break;          
				case "SPOTLIGHT_NEW_COMMENT":
					 returnText = senderName + ": " + dynamicMessage;
					 break;   
				case "SPOTLIGHT_NEW_LIKE":
					 returnText = senderName + ": " + dynamicMessage;
					 break;                   
				case "GIFT_RECEIVED":
				case "GIFT_SENT":
				case "ORDER_PLACED":
				case "ORDER_FILLED":
				case "ADJUSTMENT":
					 returnText = dynamicMessage;
					 break;    
				default:
					 return dynamicMessage;
		  }
		  
		  return returnText;
		  
}
	 
	 async function pushNotification ({notificationTypeLabel, userForPush, sendingUser, dynamicMessage, invitationID, messageID, spotlightID, commentID, scheduledConectivityID, urlRedirect}) {
		  
		  // USER is """ if for ALL Users
		  // CUSTOMER is """ if for ALL Customers
		  // MESSAGE is "" unless a specific message string is to be sent rather than the predefined text; only for messaging
	 
		  if (notificationTypes == null) return;
		  if (!invitationID) invitationID = "";
		  if (!messageID) messageID = "";
		  if (!spotlightID) spotlightID = "";
		  if (!commentID) commentID = "";
		  if (!scheduledConectivityID) scheduledConectivityID = "";
		  if (!urlRedirect) urlRedirect = "";
		  
		  const notificationTypeToPush = notificationTypes.find(notificationTypeEntry =>  notificationTypeEntry.notificationType == notificationTypeLabel);
		  
		  if (notificationTypeToPush !== null) {    
	 
				try {
					 // var currentDate = moment().add(notificationTypeToPush.signupDays-1, 'days');  // set end day.  Subtract 1 since a "1 day" notification ends TODAY
																										  
					 const expirationAWS = moment().add(7, 'days').format("YYYY-MM-DDT23:59:01Z");  //Get the current time and convert to AWS format, set to EOD expiration time
					 
					 if (DEBUG_MODE >= 2) console.log("Pushing notification with expiration:",expirationAWS, notificationTypeLabel, userForPush, sendingUser, dynamicMessage, invitationID, messageID, scheduledConectivityID, urlRedirect);
					 
					 var textToSend = constructPushNotificationText(notificationTypeToPush, userForPush, sendingUser, dynamicMessage);

					 const tempNotificationToPush = {
								notificationTypeID: notificationTypeToPush.id,
								notificationType: notificationTypeToPush.notificationType,          // Label that identifies the specific notification type; same as in notification type table
								userID: (userForPush ? userForPush.id : "" ),                       // ID of user to whom the notification is directed; DEFAULT to SuperAdmin if GLOBAL or CUSTOMER_WIDE
								customerID:(userForPush ? userForPush.customerID : ""),             // Launch for ALL companies if SuperAdmin else just current company
								scope:(userForPush ? "USER_SPECIFIC" : "GLOBAL"),                   // Scope of notification: GLOBAL, CUSTOMER_SPECIFIC or USER_SPECIFIC
								expiration:expirationAWS,	                                        // Date by which the Conectivity must be completed 
								readByUser:false,     		                                        // Boolean indicating the targeted user, if any, has read the message.  Only applies to targeted messages
								pushNotificationSent:false,                                         // Boolean indicating the message has been sent via our Lamda function to the push platform for distribution 
								title: notificationTypeToPush.title,		                        // Content title
								text: textToSend,		                                            // Content of the notification in plain text format
								textFormatted: notificationTypeToPush.textFormatted,                // Formatted content for sending via email or displaying in App
								image: notificationTypeToPush.image,		                        // Image, if any; URL pointing into S3 bucket; same URL as in the Image record
								imageID: notificationTypeToPush.imageID,
								invitationID:invitationID,                                          // ID of the invitation to which the notification relates, if any    
								messageID:messageID,                                                // ID of particular message to which the notification relates, if any   
								spotlightID:spotlightID,                                            // ID of the spotlight to which the notification relates, if any    
								commentID:commentID,                                                // ID of particular comment to which the notification relates, if any   
								scheduledConectivityID:scheduledConectivityID,                      // ID of particular scheduled conecitivity to which the notification relates, if any   
								urlRedirect:urlRedirect,                                            // Deep Link URL for the notification, if any
				  };
		  
					
					 //Call GraphQL with correct parameters to launch the scheduled notificationType record and the progress data record
					 const newScheduledNotification = await invokeAPI(createNotification, 'createNotification', tempNotificationToPush);
					 if (DEBUG_MODE >= 2) console.log("Pushed Notification", newScheduledNotification);
					 
				} catch (err) {
					 if (DEBUG_MODE >= 2) console.log('error launching notificationType:', err);
				}
		  } else {
				if (DEBUG_MODE >= 2) console.log("Error - no matching notification type");
		  }
	 }

	//Material UI CSS styles on this page
	 const useStyles = makeStyles((theme) => ({
		root: {
		  flexGrow: 1,
		  width: 470,
		  boxShadow: '0 8px 16px 0 #BDC9D7',
		  borderRadius: 16,
		  marginTop: 10,
 
		},
		
	 }));
	 
	 
	 const classes = useStyles();

	 //React component for showing a preview of the notification
	 const NotificationPreview = ({notification, notificationClickCallback}) => {

		  async function handleNotificationRedirect (e,notification) {	
				e.stopPropagation();        //Prevent any outer OnClick from firing 	
				if (DEBUG_MODE >= 2) console.log("Click on notification; local handler called", notification);	
				if (notificationClickCallback) notificationClickCallback(notification);
				else if (DEBUG_MODE >= 2) console.log("No callback registered");
 
		  }


			async function handleClearNotification (e,id) {	
				e.stopPropagation();        //Prevent outer Card OnClick from firing 		
				try {    
						const tempNotification = await invokeAPI(updateNotification, 'updateNotification',  {id: id, readByUser:true});
						if (DEBUG_MODE >= 2) console.log("Updated Notification as READ BY USER", tempNotification);
				} catch (err) {						
						if (DEBUG_MODE >= 2) console.log("Error updating notifications as READ BY USER: ", id, err);
				}
						
			};          

		return (
			  <div className="notificationCardContainer"  onClick={(e) => handleNotificationRedirect(e,notification)}>
						 <div className="ContainerNoHeightFlexLeft fullWidth">
							  <div  style={{position:"absolute", top:"4px", right:"6px", backgroundColor:"white", color:"grey", cursor: "pointer"}}>
									 <CloseIcon  onClick={(e) => handleClearNotification(e, notification.id)} />
								</div>

								<img className="ccImgXXL" src={notification.image}  />
								<div className="ContainerVerticalLeft"  style={{paddingLeft:"clamp(4px,1vw,10px)",}}>
									 <div className="TextStyle4"> {notification.title}</div>
									 <div className="TextStyle3"> {notification.text} </div>
								</div>
								<div className="TextStyle2 grey">  {timeAgo(notification.createdAt)} </div>
						  </div>
				</div>
				);
	  };   
	  
	  
	 function displayNotifications () {
		  if (notifications?.length == 0) return (
					  <div className="TextStyle4 white" >
						  No unread notifications
					 </div>
				);        

		  else return (
					 <div className="ContainerVertical">
					{
						  notifications.map((notification, index) => (					  
							<NotificationPreview key={notification.id} notification={notification} notificationClickCallback={notificationClickCallback}/>
						  
						  ))						  
					 } 
					 </div>
				);      
	 }        

	  const handleCloseNotifications = () => {
			
				setShowModalNotifications(false);
				setRedirectToPage(null);            
				// if (DEBUG_MODE >= 2) console.log("Closing Notifications.");
				
	 };       
	 
	 
	 const displayNotificationsModal = () => {
	 
	 setShowModalNotifications(true);
	 setRedirectToPage(null);    //Initialize to null so redirects will render    
	 setNotificationRedirectHandled(false); //Reset to NOT handled
	 if (DEBUG_MODE >= 2) console.log("Diplaying Notifications.");
	 
	 };       

	  const handleClearNotifications = () => {
 
		  markAllNotificationsRead();
		  
		  // if (DEBUG_MODE >= 2) console.log("Clearing Notifications.");
	 
		  setShowModalNotifications(false);
				
	 };     
	 
	  function notificationClickCallback (notification) {			

			if (DEBUG_MODE >= 2) console.log("Received notification redirect callback", notification);			
			//Close the modal   
			setShowModalNotifications(false);
			
			//Record the notification so our Redirect can carry along the state data
			// setNotificationClicked(notification);
			
			setRedirectState({ 
									invitationID:notification.invitationID,                       // ID of the invitation to which the notification relates, if any    
									messageID:notification.messageID,                             // ID of particular message to which the notification relates, if any   
									scheduledConectivityID:notification.scheduledConectivityID,   // ID of particular scheduled conecitivity to which the notification relates, if any   
									urlRedirect:notification.urlRedirect,                         // Deep Link URL for the notification, if any
									
							});

			setNotificationClickedTriggerOtherPages(!notificationClickedTriggerOtherPages);  //Change state to trigger page update
				
			//Redirect to HOME
			setRedirectToPage("/UserHome"); //Jump to User Home on notification click	
				
		  }
		  
	 //return the Notifications Context provider
		return (
	 <div>
	 
		<ModalNoBackground showModal={showModalNotifications} closeCallback={handleCloseNotifications} >
			<div className="ContainerNoHeightCenter purple-background" >
				<h3 className="white" > Notifications </h3>
			</div>

			<div className="modalNoBkgInnerCard purple-background" style={{padding:"clamp(4px,1vw,10px"}} >		
					{displayNotifications()}				
			</div>
			<div className="modalNoBkgFooter purple-background" >

				{notifications.length == 0 ? "" :
						<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done" onClick={handleClearNotifications}>
							Clear All 
						</button>
						}
					<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="Close" onClick={handleCloseNotifications}>
						<div > Close</div>
					</button>
			</div>
		</ModalNoBackground>    
			 
		  <NotificationContext.Provider value={
		  {   
				notificationTypes, setNotificationTypes, notificationTypesLoaded, compareNotificationTypes,
				notifications, setNotifications, loadUserNotifications, 
				pushNotification,
				displayNotificationsModal,
				notificationClickedTriggerOtherPages,
				notificationRedirectHandled, setNotificationRedirectHandled,
				redirectState,
		  }}>
			 {children}
		  </NotificationContext.Provider>
				  {!redirectToPage ? "" : 
						  <Navigate 
								to={{
								pathname: redirectToPage,
								// state: { 
								//     invitationID:notificationClicked.invitationID,                       // ID of the invitation to which the notification relates, if any    
								//     messageID:notificationClicked.messageID,                             // ID of particular message to which the notification relates, if any   
								//     scheduledConectivityID:notificationClicked.scheduledConectivityID,   // ID of particular scheduled conecitivity to which the notification relates, if any   
								//     urlRedirect:notificationClicked.urlRedirect,                         // Deep Link URL for the notification, if any
									 
								// }

								}}
					 />} 
		  
	 </div>
  ); 
	 
};


export { NotificationContext, NotificationsProvider };


	 //   {!redirectToPage ? "" : 
	 //         <Redirect 
	 //             to={{
	 //             pathname: {redirectToPage},
	 //             // search: "?utm=your+face",
	 //             state: { message: "Hi" }
	 //             }}
	 //     />} 
	 

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR,
            SPOTLIGHT_DAYS_TO_EXPIRATION, GIPHY_ID_IDENTIFIER, COLOR_WHITE,
            SPOTLIGHT_TRANSACTION_MEMO_SENDER, SPOTLIGHT_TRANSACTION_MEMO_RECIPIENT, SPOTLIGHT_TRANSACTION__IMAGE} from '../data/conectereConfigData';
import { HELP_TEXT_SPOTLIGHT_1 } from '../data/helpData';
//React & Amplify
import React, { useEffect, useState, useContext, useMemo, useRef, useCallback } from 'react';
import parse from "html-react-parser";              //Needed to render HTML from the Editor

//Queries and Mutations
import { enqueueCommand }  from '../graphql/mutations';

//Icons
import HighlightIcon from '@mui/icons-material/Highlight';

//Utils
import { invokeAPI } from "./databaseUtils";

export function drawSpotlightCurvedText({id, isSmall}) {
    
    // if (DEBUG_MODE >= 2) console.log("Rendering curve text", isSmall, isFull);
    if (isSmall) {
        var curveID = id.toString();
        return (
            <div className="spotlightCurvedTextWrapperIsSmall">
                <svg height="25" width="200" >                                                         
                    <path id={curveID} d="M 50 25 Q 100 0 150 25" stroke="transparent" strokeWidth="5" fill="none" />         //Define a path for the text - https://www.w3schools.com/graphics/svg_path.asp
                    <text width="200">
                        <textPath href={"#"+curveID} fill="gold">
                            SPOTLIGHT
                        </textPath>
                    </text>
                </svg> 
            </div>                
            );
    } else return (
    <div className="spotlightCurvedTextWrapper">
        <svg >                                                         
            <path id="curve" d="M 010 55 Q 110 20 210 55" stroke="transparent" strokeWidth="5" fill="none" />         //Define a path for the text - https://www.w3schools.com/graphics/svg_path.asp
            <text width="400">
                <textPath href="#curve" fill="gold">
                    SPOTLIGHT
                </textPath>
            </text>
        </svg> 
    </div>                
    );
}

//Display the user(s) for this spotlight
export function displayRecognizedUsersAvatars({recognizedEmployees, maxUsersToShow, isSmall, isFull, showQuestionMarkIfNone, showHalo, bgColor}) {
 
    //Note, the recognizedEmployees array may be from a Spotlight already issued or from the Spotlight tool during which the user is sending a Spotlight
    //In each case, the field names are slightly different

   if ((!recognizedEmployees || recognizedEmployees.length === 0 || !recognizedEmployees[0]) && !showQuestionMarkIfNone) return null;
   if (maxUsersToShow == 'undefined') maxUsersToShow = 7;
   var avatarClassName = "avatarImage avatarImageSmall";
   var avatarInitialsClassName = "avatarInitialsSmall TextStyle2 white";
   
   var hoverOverTextSize = "0.7rem";
   var avatarBackgroundColor = BALANCE_COLOR;
   if (bgColor) avatarBackgroundColor = bgColor;

   if (!isSmall) {
       avatarClassName = "avatarImage avatarImageMedium";
       avatarInitialsClassName = "avatarInitials TextStyle3 white";
       hoverOverTextSize = "1.0rem";
   }
   if (isFull) avatarClassName = "avatarImage avatarImageLarge";
   if (showHalo) avatarClassName = avatarClassName.concat(" avatarHalo"); 
   if ((!recognizedEmployees || recognizedEmployees.length == 0 || !recognizedEmployees[0]) && showQuestionMarkIfNone) 
     return (
        <div className="userHomeInviteeAvatarRowOverlapping">
            <div className="avatarContainer">
               <div className={avatarClassName} style={{backgroundColor: avatarBackgroundColor}}>
                    <div className={avatarInitialsClassName} style={{fontSize:"2.0rem"}}> ? </div>

                </div>
             </div>
            <div style={{width:"15px"}}></div>
        </div>         
         ); 
   else if (recognizedEmployees && recognizedEmployees.length > maxUsersToShow) 
        return (
            <div className="userHomeInviteeAvatarRowOverlapping">
                    {recognizedEmployees.map((target, index) => ( 
                            
                            <div key={target.id + index.toString()} className="avatarContainer" >
                            {index <=maxUsersToShow-1 ? 
                                <div className="avatarContainer"> 
                                    <div className={avatarClassName} style={{backgroundColor: avatarBackgroundColor}}>
                                        {(!target.recognizedUserAvatarUrl && !target.avatarUrl) ? 
                                            <div className={avatarInitialsClassName}> {target.recognizedUserAvatarInitials ? target.recognizedUserAvatarInitials : target.avatarInitials}</div> : 
                                            <img className="avatarImageCenterPortrait" src={target.recognizedUserAvatarUrl ? target.recognizedUserAvatarUrl : target.avatarUrl} alt={target.recognizedUserAvatarInitials ? target.recognizedUserAvatarInitials : target.avatarInitials} /> 
                                            
                                        }
                                    </div>
                                    <div className="avatarHoverText" style={{background: avatarBackgroundColor, fontSize:hoverOverTextSize}}>{target.recognizedUserAvatarName ? target.recognizedUserAvatarName : target.label}</div>
                                </div>
                                : ""
                            }
                            </div>
                        )) }
             
                <div className={avatarClassName} style={{background: avatarBackgroundColor, color:"white"}}>
                    <div className={avatarInitialsClassName} >+{recognizedEmployees.length-maxUsersToShow}</div>
                </div>
            </div>
        );           
 

   return(
     <div className="userHomeInviteeAvatarRowOverlapping">
         {recognizedEmployees.map((target, index) => ( 
                 <div key={target.id + index.toString()} className="avatarContainer">
                    <div className={avatarClassName} style={{backgroundColor: avatarBackgroundColor}}>
                         {(!target.recognizedUserAvatarUrl && !target.avatarUrl) ? 
                             <div className={avatarInitialsClassName}> {target.recognizedUserAvatarInitials ? target.recognizedUserAvatarInitials : target.avatarInitials}</div> : 
                             <img className="avatarImageCenterPortrait" src={target.recognizedUserAvatarUrl ? target.recognizedUserAvatarUrl : target.avatarUrl} alt={target.recognizedUserAvatarInitials ? target.recognizedUserAvatarInitials : target.avatarInitials} /> 
                             
                         }
                     </div>
                     <div className="avatarHoverText" style={{background: avatarBackgroundColor, fontSize:hoverOverTextSize}}>{target.recognizedUserAvatarName ? target.recognizedUserAvatarName : target.label}</div>
                  </div>
             ))}
         <div style={{width:"15px"}}></div>
     </div>
   );
}

//
//Database handler functions for use by parent components
//

export async function invokeCreateSpotlight ({sender,spotlightToPost, recognitionMessage, messageGif, selectedUsersToRecognize, selectedTeamsToRecognize})  {

   let successFlag = true;
   var bonusGenerated = false;    
   var usersRecognized = []; //temp array of IDs to keep track of users as we send out invites so as to ensure we don't send multiple
   var teamsRecognized = []; //temp array of IDs to keep track of teams as we send out invites 
   
   try {
    
        //Safety check
        if ((!selectedUsersToRecognize || selectedUsersToRecognize.length === 0) && (!selectedTeamsToRecognize || selectedTeamsToRecognize.length === 0)) {
            if (DEBUG_MODE >= 1) console.log("Error casting spotlight.  No users or teams to recognize", selectedUsersToRecognize, selectedTeamsToRecognize);
        }
        
        //Build arrays of user IDs and team IDs.  Don't worry about overlap as our Lambda will sort it out
        if (selectedUsersToRecognize) selectedUsersToRecognize.forEach (user => {usersRecognized.push(user.id);});
        if (selectedTeamsToRecognize) selectedTeamsToRecognize.forEach (team => {teamsRecognized.push(team.id);});
    
        var params = {
            recognitionMessage: recognitionMessage,
            amount: (spotlightToPost.amount ? spotlightToPost.amount : 0),  //Amount
            badgesDEI: (spotlightToPost.deiRelated ? 1 : 0),    //DEI badges awarded
            badgesCS: (spotlightToPost.csRelated ? 1 : 0),      //CS badges awarded
            usersToRecognize: usersRecognized,
            teamsToRecognize: teamsRecognized,
        };
        
        if (messageGif) params.messageGif = GIPHY_ID_IDENTIFIER + messageGif.id;
        
        const enqueueCommandInput = {
            userID: sender.id,
            customerID:sender.customerID,
            command: 'CAST_SPOTLIGHT',
            params: JSON.stringify(params),
        };
            
        //Call GraphQL to directly invoke our Lambda function 
        if (DEBUG_MODE >= 2) console.log("Enqueuing Command Cast Spotlight ", enqueueCommandInput);
        const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
        if (DEBUG_MODE >= 2) console.log("Enqueue Command Lambda returned", response);        
    
        // NOTE - SUCCESSFLAG AND BONUS ARE NOT RETURNED FROM ENQUEUE LAMBDA SO THESE VARS ARE BASICALLY HARDCODED HERE
        // TODO - BONUS FOR SPOTLIGHTS COULD BE PUSHED ASYNCRONOUSLY VIA APP SYNC AS AN EVENT
        // OTHERWISE WE WOULD NEED TO DO A DIRECT CALL TO OUR CAST LAMBDA AND THEN BLOCK
    } catch (err) {
        if (DEBUG_MODE >= 2) console.log("Error creating Spotlight", err);        
        successFlag = false;
    }
    return {successFlag, bonusGenerated};
}

export async function invokeEditSpotlight ({spotlight, recognitionMessage, selectedUsersToRecognize, selectedTeamsToRecognize})  {

    let successFlag = true;
    var usersRecognized = []; //temp array of IDs to keep track of users as we send out invites so as to ensure we don't send multiple
    var teamsRecognized = []; //temp array of IDs to keep track of teams as we send out invites 

    try {
    
        //Safety check
        if ((!selectedUsersToRecognize || selectedUsersToRecognize.length === 0) && (!selectedTeamsToRecognize || selectedTeamsToRecognize.length === 0)) {
            if (DEBUG_MODE >= 1) console.log("Error casting spotlight.  No users or teams to recognize", selectedUsersToRecognize, selectedTeamsToRecognize);
        }

        if (DEBUG_MODE >= 2) console.log("Invoke Edit Spotlight called ", spotlight, recognitionMessage, selectedUsersToRecognize, selectedTeamsToRecognize);
        
        //Build arrays of user IDs and team IDs.  Don't worry about overlap as our Lambda will sort it out
        if (selectedUsersToRecognize) selectedUsersToRecognize.forEach (user => {usersRecognized.push(user.id);});
        if (selectedTeamsToRecognize) selectedTeamsToRecognize.forEach (team => {teamsRecognized.push(team.id);});
    
        var params = {
            spotlightID: spotlight.id,
            recognitionMessage: recognitionMessage,
            amount: (spotlight.amount ? spotlight.amount : 0),  //Amount
            badgesDEI: (spotlight.badgesDEI ? spotlight.badgesDEI : 0),    //DEI badges awarded
            badgesCS: (spotlight.badgesCS ? spotlight.badgesCS : 0),       //CS badges awarded
            usersToRecognize: usersRecognized,
            teamsToRecognize: teamsRecognized,
        };
        
        const enqueueCommandInput = {
            userID: spotlight.senderID,
            customerID:spotlight.customerID,
            command: 'EDIT_SPOTLIGHT',
            params: JSON.stringify(params),
        };
            
        //Call GraphQL to directly invoke our Lambda function 
        if (DEBUG_MODE >= 2) console.log("Enqueuing Command Edit Spotlight ", enqueueCommandInput);
        const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
        if (DEBUG_MODE >= 2) console.log("Enqueue Command Lambda returned", response);        
     } catch (err) {
        if (DEBUG_MODE >= 2) console.log("Error editing Spotlight", err);        
        successFlag = false;
    }
    return successFlag;
}

export async function invokeDeleteSpotlight({spotlight}) {
   let successFlag;
   
   //Safety check
   if (!spotlight || !spotlight.id) {
      if (DEBUG_MODE >= 1) console.error("Error = improper params to delete spotlight");
      return successFlag;
   }
   
   try {
      var params = {
         spotlightID: spotlight.id,
      };
      
      const enqueueCommandInput = {
         userID: spotlight.senderID,
         customerID:spotlight.customerID,
         command: 'DELETE_SPOTLIGHT',
         params: JSON.stringify(params),
      };
         
      //Call GraphQL to directly invoke our Lambda function 
      if (DEBUG_MODE >= 2) console.log("Enqueuing Command DELETE_SPOTLIGHT ", enqueueCommandInput);
      const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
      if (DEBUG_MODE >= 2) console.log("Enqueue Command Lambda returned", response);        
      successFlag=true; 
   } catch (err) {
     if (DEBUG_MODE >= 2) console.log("Error deleting spotlight:", err);
     successFlag=false;
   }
   return successFlag;
}

export const SpotlightCreateButton = ({currentUser, callback}) => {

    //Safety check
    if (!currentUser || !currentUser.userProgressData || !currentUser.customer || !currentUser.customer.configDetails || !currentUser.customer.configDetails.spotlightsEnabled || (currentUser.customer.configDetails.spotlightsAdminSendOnly && !isAdmin)) return null;

    const [textColor, setTextColor] = useState(COLOR_WHITE);
    const [mouseOver, setMouseOver] = useState(false);

    //Has the user sent all of his/her Spotlights for this period?
    let maxSpotlightsSent, numSent = 0, spotlightCap=0;
    let percentSent = 0;
    if (currentUser.customer.configDetails.spotlightCastCapEnabled && currentUser.customer.configDetails.spotlightCastCapLimit > 0 ) {
        numSent = (currentUser.userProgressData.spotlightPeriodNumCast ? currentUser.userProgressData.spotlightPeriodNumCast : 0);
        spotlightCap = currentUser.customer.configDetails.spotlightCastCapLimit;
         maxSpotlightsSent = (currentUser.customer.configDetails.spotlightCastCapEnabled && (numSent >= spotlightCap));
        if (numSent > 0) percentSent = Math.ceil(100*numSent / spotlightCap);
        if (percentSent > 100) percentSent = 100;
        // if (DEBUG_MODE) console.log("Spotlights capped. Calculated progress bar", percentSent.toString() + '%');
    }

    let buttonText = (maxSpotlightsSent ? "WELL DONE!" : "CAST A SPOTLIGHT!");
    let hoverText = HELP_TEXT_SPOTLIGHT_1;

    if (currentUser.customer.configDetails.spotlightCastCapEnabled && spotlightCap && currentUser.customer.configDetails.spotlightCastCapPeriod) {
        if (maxSpotlightsSent)
            hoverText = "Way to go!  You've cast all your Spotlights!  You can cast " + 
                `<span className="TextStyle4 purple">` + spotlightCap.toString() + `</span>` +
                (spotlightCap === 1 ? " spotlight " : " spotlights ") + 
                `<span className="TextStyle4 purple">` + currentUser.customer.configDetails.spotlightCastCapPeriod.toLowerCase()  + `</span>` + ".";

        else 
            hoverText = "Cast " + 
                `<span className="TextStyle4 purple">` + spotlightCap.toString() + `</span>` +
                (currentUser.customer.configDetails.spotlightCastCapLimit === 1 ? " spotlight " : " spotlights ") + 
                `<span className="TextStyle4 purple">` + currentUser.customer.configDetails.spotlightCastCapPeriod.toLowerCase()  + `</span>` + ".  " + HELP_TEXT_SPOTLIGHT_1;
    }

    return (
        <section className='spotlight-button-section' onMouseEnter={()=>{setMouseOver(true);if (!maxSpotlightsSent) setTextColor(TEAM_COLOR);}} onMouseLeave={()=>{if (!maxSpotlightsSent) setTextColor(COLOR_WHITE); setMouseOver(false)}} >
            <button className="buttonStyle1 buttonStyle1Purple positionRelative"  disabled={maxSpotlightsSent} style={{margin:"clamp(2px,0.3vw,4px)"}}
                onClick={(e) => callback(true)} >
                <div className="ContainerVerticalCenter" >
                    <div className="ContainerMaxHeightCenter" style={{position:"relative"}}>
                        <div className="spotlightAddButton" >
                            {buttonText}
                        </div>
                        <div className=" spotlightAddButtonIconWrapper" > 
                            <HighlightIcon className="TextStyle6A " style={{color:textColor}}/> 
                        </div>
                    </div>

                    {!currentUser.customer.configDetails.spotlightCastCapEnabled ? null :
                        <div className='spotlightOuterProgressBar boxShadow'>
                            <div className='spotlightInnerProgressBar ' style={{backgroundColor:textColor, width:percentSent.toString() + '%'}} >
                            </div>
                            <div className="fullWidth TextStyle3 grey"style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>{numSent.toString() + ` of ` + spotlightCap.toString()}</div>
                        </div>
                    }
                </div>
                <div className={` hoverTextStyle1 hoverTextStyle1Wide  ${mouseOver ? "hoverTextStyle1Visible" : ""} `} > {parse(hoverText)}</div>
            </button>   
        </section>
    );
};

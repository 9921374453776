import './loadingConectere.css';
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import Celebration from './ConnectereLoading.json';
import ConectereFullLogo from '../../../account/public/img/Conectere_Logo_New_full.svg';

const LoadingView = ({ showSpinner }) => {
  const [isRippleVisible, setIsRippleVisible] = useState(false);
  const [isOuterRippleVisible, setIsOuterRippleVisible] = useState(false);

  useEffect(() => {
    if (showSpinner) {
      setIsOuterRippleVisible(true);
      setIsRippleVisible(false);
    } else {
      setIsOuterRippleVisible(false);
      setIsRippleVisible(true);

      const timer = setTimeout(() => setIsRippleVisible(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [showSpinner]);

  if (!showSpinner && !isRippleVisible) return null;

  return (
    <div className='LoadingNoBackgroundContainer'>
      {isOuterRippleVisible && <div className='ripple-effect-outer' />}
      {isRippleVisible && <div className='ripple-effect-inner' />}
      {!isRippleVisible && (
        <>
          <div className='gradient-line loading-gradient-line' />
          <div className='LoadingInnerContainer'>
            <div className='LoadingContectereImg'>
              <img src={ConectereFullLogo} alt='' height='100%' width='100%' />
            </div>
            <Lottie
              speed={2}
              animationData={Celebration}
              loop
              className='LoadingAnimationSize'
            />
          </div>
          <div className='LoadingNoBackgroundOpaqueContainer'></div>
        </>
      )}
    </div>
  );
};

export default LoadingView;


//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  {  DEBUG_MODE, BALANCE_COLOR, GROWTH_COLOR, SOCIAL_COLOR, TEAM_COLOR, COLOR_WHITE } from '../../data/conectereConfigData';

//Amplify, React
import React, { useCallback, useLayoutEffect, useEffect, useState, useContext, useRef } from 'react';
import parse from "html-react-parser";              //Needed to render HTML from the Editor

//Our Context
import { AuthContext } from '../../context/authContext';

//Icons
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Our Components
// import ConectivityDurationTimer from '../conectivityDurationTimer/conectivityDurationTimer';

//Utils
import { NOW, displayExpiration } from "../../utils/dateTimeUtils";
import { InfoButton, InfoLink } from "../../utils/generalUtils";
import moment from 'moment';

//
//
// ANNOUNCEMENTS REGION AT TOP OF PAGE
//
//

function setColorByIndex(index) {
    const colorCat = index % 4;
    switch (colorCat) {
        case 0: return TEAM_COLOR;
        case 1: return BALANCE_COLOR;
        case 2: return SOCIAL_COLOR;
        case 3: return GROWTH_COLOR;
        default: return BALANCE_COLOR;
    }
}

const SpecialEventCard = React.memo(({index, specialEvent, specialEventClickCallback }) => {

    const [displaySpecialEvent, setDisplaySpecialEvent] = useState(true);
    const [timeLeft, setTimeLeft] = useState(null);  //Define state variable; initialize to time remaining
    let countdownUntil;

    //This function updates the timeLeft Momement variable maintained in the State data
    function updateTimeLeft () {
        const currentTime = moment(); // get time set in user's computer time / OS
        if (countdownUntil) return moment.duration(countdownUntil.diff(currentTime));    //Recalculate the duration until this event expires   
        return null;
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(updateTimeLeft(countdownUntil));      //set state of timeLeft based on return value from updateTimeLeft
        }, 1000); //Fire in 1 second

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });


    //Threshold checks
    if (!specialEvent || !displaySpecialEvent || !specialEvent.eventStartDateTime || !specialEvent.eventEndDateTime) return null;
    if (moment(specialEvent.eventEndDateTime).isBefore(NOW(),'day')) {
        if (DEBUG_MODE > 0) console.log("NOT displaying enabled Special Event - already in the past", specialEvent);
        return null;     
    }
    const cardColor = setColorByIndex(index);
    const showTimeUntilStart = moment(specialEvent.eventStartDateTime).isAfter(NOW());
    const showTimeUntilEnd = !showTimeUntilStart && moment(specialEvent.eventEndDateTime).isAfter(NOW());
    const isMultiDay = !(moment(specialEvent.eventStartDateTime).isSame(moment(specialEvent.eventEndDateTime),'day'));

    //Init our UNTIL date for counter
    if (showTimeUntilStart) countdownUntil = moment(specialEvent.eventStartDateTime);
    else if (showTimeUntilEnd) countdownUntil = moment(specialEvent.eventEndDateTime); 

    return (
        <div key={specialEvent.id} className='ContainerMaxHeightSpaceBetween specialEventCard TextStyle4 white' style={{backgroundColor:cardColor, zIndex:900-index}} >
            <div className='ContainerNoHeightFlexLeft fullWidth'>
                <AutoAwesomeIcon />
                <div className='ContainerVerticalStartStart'>
                    
                    <div style={{paddingLeft:'clamp(4px,0.5vw,10px'}}>
                        {specialEvent.title + ' ('}
                        <span className='TextStyle3 white'>{moment(specialEvent.eventStartDateTime).format('ddd, MMM Do')}</span>
                        <span className='TextStyle3 white'>{isMultiDay ? ' - ' + moment(specialEvent.eventEndDateTime).format('ddd, MMM Do') + ')' : ')'}</span>
                    </div> 
                    {showTimeUntilStart || showTimeUntilEnd ? 
                        <div className='TextStyle3 white' style={{paddingLeft:'10px'}}>
                            <i>{showTimeUntilStart ? 'starts in' : 'ends in' } {displayExpiration(timeLeft)}</i>
                        </div>
                        : null
                    }
                </div>    
            </div>
            <InfoButton hoverText={specialEvent.announcement} isHTML={true} hoverTextClassName='TextStyle4 hoverTextStyle1' className='white' noTransform={true} zIndex={index > -1 ? (9999-index).toString() : '99'}/> 
            <IconButton  onClick={()=>setDisplaySpecialEvent(false)}>
                <CloseIcon className="TextStyle4" style={{backgroundColor:COLOR_WHITE, cursor: "pointer", color:cardColor}}/>
            </IconButton>
        </div>      
    )

}, (prevProps, nextProps) => {
    //Our React MEMO function - do not re-render if no change to the spotlightToView
    if (
        (prevProps.specialEvent === nextProps.specialEvent) 
        )
    {
        // if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the SpecialEventCard",  prevProps.specialEvent);
        return true; // props are equal
    }
    // if (DEBUG_MODE >= 2) console.log("Re-RENDER the SpecialEventCard", prevProps.specialEvent);
    return false; // props are not equal -> update the component
}
);

const EventView = React.memo(({ index, specialEvent, isLastEvent, onClickDown, onClickUp, setInfoLinkActive }) => {

    const [displaySpecialEvent, setDisplaySpecialEvent] = useState(true);
    const [timeLeft, setTimeLeft] = useState(null);
    let countdownUntil;

    //This function updates the timeLeft Momement variable maintained in the State data
    function updateTimeLeft() {
        const currentTime = moment(); // get time set in user's computer time / OS
        if (countdownUntil) return moment.duration(countdownUntil.diff(currentTime));
        return null;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(updateTimeLeft(countdownUntil));      //set state of timeLeft based on return value from updateTimeLeft
        }, 1000); //Fire in 1 second

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });


    //Threshold checks
    if (!specialEvent || !displaySpecialEvent || !specialEvent.eventStartDateTime || !specialEvent.eventEndDateTime) return null;
    if (moment(specialEvent.eventEndDateTime).isBefore(NOW(),'day')) {
        if (DEBUG_MODE > 0) console.log("NOT displaying enabled Special Event - already in the past", specialEvent);
        return null;     
    }
    const cardColor = setColorByIndex(index);
    const showTimeUntilStart = moment(specialEvent.eventStartDateTime).isAfter(NOW());
    const showTimeUntilEnd = !showTimeUntilStart && moment(specialEvent.eventEndDateTime).isAfter(NOW());
    const isMultiDay = !(moment(specialEvent.eventStartDateTime).isSame(moment(specialEvent.eventEndDateTime),'day'));

    if (showTimeUntilStart) countdownUntil = moment(specialEvent.eventStartDateTime);
    else if (showTimeUntilEnd) countdownUntil = moment(specialEvent.eventEndDateTime);

    return (
        <div key={specialEvent.id} style={{ backgroundColor: cardColor, width: '100%', zIndex: 900 - index,  }} >
            <div className={`w-full ContainerNoHeightCenter eventViewWrapper white ${isLastEvent ? 'top-to-bottom' : 'bottom-to-top'}`} style={{ backgroundColor: cardColor }} >
                <div className='ContainerNoHeightCenter eventCardViewInfoWrapper TextStyle4V2'>
                    <IconButton className="padding-none" onClick={onClickUp}>
                        <ExpandLessIcon className="TextStyle8V2 white" />
                    </IconButton>
                    <IconButton className="padding-none" onClick={onClickDown}>
                        <ExpandMoreIcon className="TextStyle8V2 white" />
                    </IconButton>
                    <AutoAwesomeIcon />
                    <div>
                        {specialEvent.title + ' ('}
                        <span className='TextStyle4V2 white'>{moment(specialEvent.eventStartDateTime).format('DD/MM')}</span>
                        <span className='TextStyle4V2 white'>{isMultiDay ? ' - ' + moment(specialEvent.eventEndDateTime).format('DD/MM') + ')' : ')'}</span>
                    </div>
                    {showTimeUntilStart || showTimeUntilEnd ?
                        <div className='eventCardExpireTimeWrapper TextStyle2V2 white' style={{ paddingLeft: '10px' }}>
                            <span>{showTimeUntilStart ? 'starts in' : 'ends in'} {displayExpiration(timeLeft)}</span>
                        </div>
                        : null
                    }
                    <InfoLink hoverText={specialEvent.announcement} setInfoLinkActive={setInfoLinkActive} isHTML={true} hoverTextClassName='TextStyle4 hoverTextStyle1' className='white' noTransform={true} zIndex={index > -1 ? (9999 - index).toString() : '99'} />
                </div>
                <div className="eventCardCloseButton">
                    <IconButton onClick={() => setDisplaySpecialEvent(false)}>
                        <CloseIcon className="TextStyle5V2 white" />
                    </IconButton>
                </div>
            </div>
        </div>
    );

}, (prevProps, nextProps) => {
    //Our React MEMO function - do not re-render if no change to the spotlightToView
    if (
        (prevProps.specialEvent === nextProps.specialEvent) 
        )
    {
        // if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the SpecialEventCard",  prevProps.specialEvent);
        return true; // props are equal
    }
    // if (DEBUG_MODE >= 2) console.log("Re-RENDER the SpecialEventCard", prevProps.specialEvent);
    return false; // props are not equal -> update the component
}
);

///Component for showing a list of enabled specialEvents
export const SpecialEvents = React.memo(({ enabledSpecialEvents, specialEventClickCallback }) => {

    // Authentication context
    const { authState, currentUser } = useContext(AuthContext);
    if (authState !== "signedin" || !currentUser) return null;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [infoLinkActive, setInfoLinkActive] = useState(false);
    const intervalRef = useRef(null);

    const startInterval = () => {
        if (intervalRef.current) clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (!infoLinkActive) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % enabledSpecialEvents?.length);
            }
        }, 4000);
    }
    const onClickUp = () => {
        setCurrentIndex(currentIndex === 0 ? enabledSpecialEvents.length - 1 : currentIndex - 1);
        startInterval();
    }

    const onClickDown = () => {
        setCurrentIndex(currentIndex === enabledSpecialEvents.length - 1 ? 0 : currentIndex + 1);
        startInterval();
    }

    useEffect(() => {
        startInterval();

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [enabledSpecialEvents?.length]);

    useEffect(() => {
        startInterval();
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [infoLinkActive]);

    //Return Header for authenticated user

    // if (DEBUG_MODE > 1) console.log("Special Events Display:",enabledSpecialEvents);
    const localAnnouncementClickCallback = (specialEvent) => {
        if (DEBUG_MODE >= 2) console.log("Handler called in response to specialEvent click", specialEvent);
        if (specialEventClickCallback) {
            if (DEBUG_MODE >= 2) console.log("Calling parent handler");
            specialEventClickCallback(specialEvent);
        } else {
            if (DEBUG_MODE >= 2) console.log("No callback registered");
        }
    };

    if (!enabledSpecialEvents || enabledSpecialEvents.length === 0) return null;
    return (
        <div className='ContainerVertical fullWidth' style={{ position: "relative" }} >
            <EventView
                key={enabledSpecialEvents[currentIndex]?.id}
                index={currentIndex}
                specialEvent={enabledSpecialEvents[currentIndex]}
                specialEventClickCallback={localAnnouncementClickCallback}
                isVisible={currentIndex}
                isLastEvent={currentIndex + 1 === enabledSpecialEvents.length}
                onClickUp={onClickUp}
                onClickDown={onClickDown}
                setInfoLinkActive={setInfoLinkActive}
            />
        </div>
    );

}, (prevProps, nextProps) => {
    //Our React MEMO function - do not re-render if no change
    if (prevProps.enabledSpecialEvents === nextProps.enabledSpecialEvents) {
        if (DEBUG_MODE >= 3) console.log("Do NOT Re-Render the Spotlight Row!");
        return true; // props are equal
    }
    if (DEBUG_MODE >= 3) console.log("Re-RENDER the Spotlight Row!");
    return false; // props are not equal -> update the component
});

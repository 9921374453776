//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import "./displayCardUserStatsModal.css";
import React from 'react';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER,  COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR,  } from '../../../../shared/data/conectereConfigData';

//React component
const CCdisplayCardUserStatsModal = React.memo(({analytics}) => {

    const footer="";

    if (DEBUG_MODE >= 2) console.log("User Stats Card called", analytics);

    if (!analytics) return null;
    
    return (

        <div className="dashboardCard" >
            <span className="ContainerVertical" style={{backgroundColor:SOCIAL_COLOR, width:"100%"}}>
                    <div  className="dashboardCardTitle white">{!analytics ? "" : analytics.name}</div>
                    <div className="dashboardCardTextSmall white" >{!analytics ? "" : analytics.title}</div>
            </span>

            <div className="dashboardCardTextRow" >
                <div className="ContainerVertical">
                    <div className="dashboardScoreCircle " style={{borderColor:BALANCE_COLOR}}>{!analytics ? "" : analytics.relativeConectivityScore.toFixed(1)} </div>
                    <div className="dashboardCardText blue"  >Score</div>
                </div>
                <div className="ContainerVertical">
                    <div className="dashboardScoreCircle " style={{borderColor:TEAM_COLOR}}>{!analytics ? "" : analytics.userRank} </div>
                    <div className="dashboardCardText blue"  >Rank</div>
                </div>

                <div className="ContainerVertical">
                    <div className="dashboardScoreCircle " style={{borderColor:GROWTH_COLOR}}>{!analytics ? "" : analytics.conectivitiesCompleted}</div>
                    <div className="dashboardCardText blue" >Conectivities</div>

                </div>
            </div>


            <div className="ContainerNoHeightSpaceAround" style={{width:"100%"}}>
                <div className="ContainerNoHeightCenter">
                    <div  className="dashboardScoreRectangle dashboardScoreRectangleSmall blue-background"  style={{marginRight:"3px"}}>{!analytics ? "" : analytics.stressConectivitiesCompleted}</div>
                    <img src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} height='28' />
                </div>    
                <div className="ContainerNoHeightCenter">
                    <div  className="dashboardScoreRectangle dashboardScoreRectangleSmall purple-background"  style={{marginRight:"3px"}}>{!analytics ? "" : analytics.teamConectivitiesCompleted}</div>
                    <img src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} height='28' />
                </div>    
                <div className="ContainerNoHeightCenter">
                    <div  className="dashboardScoreRectangle dashboardScoreRectangleSmall green-background"  style={{marginRight:"3px"}}>{!analytics ? "" : analytics.socialConectivitiesCompleted}</div>
                    <img src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} height='28' />
                </div>    
                <div className="ContainerNoHeightCenter">
                    <div  className="dashboardScoreRectangle dashboardScoreRectangleSmall yellow-background" style={{marginRight:"3px"}}>{!analytics ? "" : analytics.individualConectivitiesCompleted}</div>
                    <img src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} height='28' />
                </div>
            </div >


            <div className="ContainerVertical" style={{width:"100%"}}>
                <div className="displayUserCardLabel blue" >Rewards</div>
                <div className="dashboardCardTextRow" >
                        <div className="dashboardCardNumber">{!analytics ? "" : analytics.coinsAwarded.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /></div>
                        <div className="dashboardCardNumber">{!analytics ? "" : analytics.badgesDEIAwarded.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /></div>
                        <div className="dashboardCardNumber">{!analytics ? "" : analytics.badgesCSAwarded.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /></div>
                </div >
            </div >


            {!footer ? "" : <span className="dashboardCardTextFooter">{footer} </span> }
        </div>
        
    );

});


export default CCdisplayCardUserStatsModal;



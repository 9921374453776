//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, GIPHY_ID_IDENTIFIER, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR,  } from '../data/conectereConfigData';

//React & Amplify
import React from 'react';

//Utils
import { getRandomInt, truncateStringByWord, truncateStringWithDots, formatDateWithDayOfWeek, displayExpirationTime } from "./generalUtils";
import {NOW, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3, getRecurrenceRuleNextLaunchDate, constructRecurrenceRule} from "./dateTimeUtils";
import { invokeRemoveInviteToConect, setHeaderColorByStringLength } from "./conectivityUtils";
import { getUserInitials } from "./userAndTeamUtils";
import { invokeAPI } from "./databaseUtils";

//GraphQL API
import { enqueueCommand } from '../graphql/mutations';


import { v4 as uuidv4 } from 'uuid';    
import moment from 'moment';

///
//
// INVITATION UTILITIES
//
//
//

export function setInvitationNotificationTypeByCategory(label) {
	
	var returnString ="";
	
	switch (label) {
		case "STRESS":
			returnString = "BALANCE_INVITATION_RECEIVED";
			break;
		case "SOCIAL":
			returnString = "SOCIAL_INVITATION_RECEIVED";
			break;            
		case "TEAM":
			returnString = "TEAM_INVITATION_RECEIVED";
			break;
		case "PERSONAL":
			returnString = "PERSONAL_INVITATION_RECEIVED";
			break;                
	}
	
	return returnString;
	
}  


//Function for determining whether an invitation's event start & end date/time falls within an SC window and has not expired
// return TRUE if invitation has a non-expired event window before the SC closing time

function nonExpiredValidInvitationDateTime({invitation, scheduledConectivity}) {

	//Safety check
	if (!invitation || !scheduledConectivity || !scheduledConectivity.closingDateTime) {
		if (DEBUG_MODE) console.error("Error - improper params to validate invitation", invitation, scheduledConectivity);
		return false;
	}

	try {
		//Found an invitation for this user.  Confirm a valid date /time relative to this Scheduled Conectivity
		const launchRuleClosingTimeMoment = moment(scheduledConectivity.closingDateTime);

		//FIRST check the instance date within the invitation itself
		// - note, all day events and multi-day have an ending day of the day AFTER the last day, i.e., 00:00:00 in the TZ of the user that launched it
		if (invitation.launchRuleInstanceEndDateTime && invitation.launchRuleInstanceStartDateTime) {
			if (DEBUG_MODE > 2) console.log(scheduledConectivity.conectivity.title);
			if (DEBUG_MODE > 2) console.log("Invitation instance has LR instance info.");
			if (DEBUG_MODE > 2) console.log("Invitation start: " + invitation.launchRuleInstanceStartDateTime);
			if (DEBUG_MODE > 2) console.log("Invitation end: " + invitation.launchRuleInstanceEndDateTime);
			if (DEBUG_MODE > 2) console.log("Invitation all day event: " + invitation.allDayEvent);
			if (DEBUG_MODE > 2) console.log("SC Closing time: " + scheduledConectivity.closingDateTime);
			if (moment(invitation.launchRuleInstanceStartDateTime).isAfter(launchRuleClosingTimeMoment,'day'))  {if(DEBUG_MODE >= 2)console.log("Rejected - starting after SC over"); return false;} //date/time of invitation is after SC closing
			if (moment(invitation.launchRuleInstanceEndDateTime).isBefore(NOW(),'day')) {if(DEBUG_MODE >= 2)console.log("Rejected - expired"); return false;} //end date/time of invitation is before today
			if (invitation.allDayEvent && moment(invitation.launchRuleInstanceEndDateTime).isBefore(NOW(),'minute')) {if(DEBUG_MODE >= 2)console.log("Rejected - expired all day event"); return false;} //end date/time of invitation is before today
		} else {			

			//Finally, is there a future launch before the SC window closes
			const nextLaunchDateString = getRecurrenceRuleNextLaunchDate(invitation.launchRule); 
			if (DEBUG_MODE >= 2) console.log("Invitation instance does NOT have LR instance info.  Checking LR itself.  Invitation Next Launch Date String ", nextLaunchDateString );
			if (nextLaunchDateString === 'EXPIRED') return false;   //no future invitation date 	
			if (moment(nextLaunchDateString).isAfter(launchRuleClosingTimeMoment,'minute')) return false; 	//date/time of next LR is after SC closing
			if (moment(nextLaunchDateString).isBefore(NOW(),'day')) return false; 								//date/time of next LR is before today but we apparently do not have
		}
		return true; //Yes - we found an invitation and it ends before the closing of this SC

	} catch (err) {
		if (DEBUG_MODE) console.error("Error validating invitation date/time", err);
	}
}

//Construct an array of invitations for which this user has an INVITEE for a given scheduled conectivity
//Note, we no longer look at the SENDER field as a user can launch for others since the sender is included on the Invitee list
export function getMyInvitations(scheduledConectivity, currentUser, includeExpired) {

	var invitationsWithThisUser = [];
	try {
		if (!scheduledConectivity.invitations || !scheduledConectivity.invitations.items || scheduledConectivity.invitations.items.length <1) return invitationsWithThisUser;

		// Generate an array of invitations of this SC for which the user is an INVITEE
		invitationsWithThisUser = scheduledConectivity.invitations.items.filter((invitation) => {
			
			// safety check
			if (!invitation || !invitation.launchRule || !invitation.invitees || !invitation.invitees.items) return false; 
			
			//Is the user on the Invitee list?
			if (invitation.invitees.items.some (invitee => invitee.inviteeID === currentUser.id) === false)
			{
				if (DEBUG_MODE >= 2) console.log("User not involved in this invitation for this Scheduled Conectivity", scheduledConectivity, invitation);
				return false; //exclude
			}

			//Include expired? If not, check the date/time
			if (includeExpired) return true; //include
			if (DEBUG_MODE >= 2) console.log("Scheduled Conectivity found where current user sent an invitation or is an invitee.  Checking invitation date/time", invitation, currentUser);
			return nonExpiredValidInvitationDateTime({invitation, scheduledConectivity}); //include but only if not expired
		});
	} catch (err) {
		if (DEBUG_MODE) console.error("Error getting user's invitations for the SC", err);
	}

	return invitationsWithThisUser;
}


//Return a Boolean as to whether the user is an invitee on at least one invitation that is BEFORE the closing time of the SC 
// -- includeExpired - whether or not to include expired invitations or only active ones
export function doesConectivityHaveInvitationForUser(scheduledConectivity, userID) {
	
	try {
		if (!userID ||  !scheduledConectivity || !scheduledConectivity.invitations || !scheduledConectivity.invitations.items || scheduledConectivity.invitations.items.length <1) return false;

		//Is there an inviation where the currentUser is an invitee?
		return (scheduledConectivity.invitations.items.find((invitation) => {
			if (!invitation.invitees.items.some(invitee => invitee.inviteeID === userID)) return false; //userUI an invitee on this invitation? If not, return false
	
			if (DEBUG_MODE > 2) console.log("SC selected - Pending Invitation found for this user.  Checking for valid date", invitation, scheduledConectivity );
			return nonExpiredValidInvitationDateTime({invitation, scheduledConectivity}) 
		}));
	} catch (err) {
		if (DEBUG_MODE) console.error("Error processing scheduled conectivity", err);
		return false;
	}
}

export function findInvitationMatchingLRInstance ({invitations, launchRuleInstanceIndex, launchRuleInstanceStartDateTime}) {

	//Safety check
	if (!invitations || invitations.length === 0 || !launchRuleInstanceIndex) {
		if (DEBUG_MODE) console.error("Error - improper params to find invitation that matches LR instance");
		return null;
	}

	if (DEBUG_MODE >- 2) console.log("checking invitations for matching LR instance", invitations, launchRuleInstanceIndex, launchRuleInstanceStartDateTime);
	//Look for invitation that has the exact instance or a starting time that matches within the minute
	let tempMatchingInvitation = invitations.find(invitation => {
		if (invitation.launchRuleInstanceIndex === launchRuleInstanceIndex) {
			if (DEBUG_MODE >=2) console.log("Found matching invitation instance", invitation);
			return true;
		}
		if (moment(launchRuleInstanceStartDateTime).isSame(moment(invitation.launchRuleInstanceStartDateTime),'minute')) {
			if (DEBUG_MODE >=2) console.log("Found invitation with matching time (by the minute)", invitation);
			return true;
		}
		return false;
	})

	//Return the results
	return tempMatchingInvitation;

}

// Function for showing the participants on an invitation
// showSender boolean causes the function to call out the sender first and then do not show again in the invitee list
export function displayInvitees({headerColor, invitation, numInviteesToShow,showSender}) {
	
	// console.log("Display Invitees Called", headerColor, invitation, numInviteesToShow,showSender)
	   
   if (!invitation || !invitation.invitees) return null;
   if (!numInviteesToShow) numInviteesToShow = 7;
   
	return (
		<div className="Container">
		
			  {showSender ? 
					//Show the sender first
					<div className="ContainerNoHeightCenter">
							<div className="ContainerNoHeightCenter">
								<div className="avatarImage avatarImageMedium" style={{backgroundColor: headerColor, marginRight:"unset"}}>
									{!invitation.senderAvatarUrl  ? <div className="avatarInitials"> {invitation.senderAvatarInitials} </div>: 
									<img className="avatarImageCenterPortrait" src={invitation.senderAvatarUrl} alt={invitation.senderAvatarInitials} />}
								</div>
								<span className="avatarHoverText" style={{background: headerColor}}>{invitation.senderAvatarName}</span> 
							</div>
							
							{invitation.invitees.items.length > 1 ? <div className="TextStyle5" style={{paddingLeft:"5px", color:headerColor}}> &#x7c; </div> : null }
					</div>
					
				: null }
						
				{invitation.invitees.items.map((target, index) => { 
				
						//Display the invitee unless we are beyond the max or this is the sender and we already displayed him/her
						if (index <= numInviteesToShow  && (!showSender || target.inviteeID !== invitation.senderID)) return (
							<div key={target.id} >
								<div className="avatarContainer"> 
									<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.inviteeAvatarName)}}>
										{!target.inviteeAvatarUrl  ? <div className="avatarInitials" > {target.inviteeAvatarInitials}</div> : <img className="avatarImageCenterPortrait" src={target.inviteeAvatarUrl} alt={target.inviteeAvatarInitials} /> }
									</div>
									<span className="avatarHoverText" style={{background: headerColor}}>{target.inviteeAvatarName}</span>
								</div>
							</div>);
						else return null;
					}) }


				{ invitation.invitees.items.length - (showSender ? 1 : 0 ) > numInviteesToShow ?
					//Display an extra cirle with a PLUS if we have exceeded our limit, excluding the SENDER if shown
					<div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:headerColor, color:"white"}}>
						<div className="avatarInitials" >+{invitation.invitees.items.length-numInviteesToShow - (showSender ? 1 : 0 )}</div>
					</div>
				: null
				}
		</div>
	);           
}

// showSender boolean causes the function to call out the sender first and then do not show again in the invitee list
export function displayInviteesV2({headerColor, invitation, numInviteesToShow,showSender}) {
	
	// console.log("Display Invitees Called", headerColor, invitation, numInviteesToShow,showSender)
	   
   if (!invitation || !invitation.invitees) return null;
   if (!numInviteesToShow) numInviteesToShow = 7;
   
	return (
		<div className="Container">
		
			  {showSender ? 
					//Show the sender first
					<div className="ContainerNoHeightCenter">
							<div className="ContainerNoHeightCenter">
								<div className="avatarImage connectivityInviteAvatarImg" style={{backgroundColor: headerColor, marginRight:"unset"}}>
									{!invitation.senderAvatarUrl  ? <div className="avatarInitials TextStyle2V2"> {invitation.senderAvatarInitials} </div>: 
									<img className="avatarImageCenterPortrait" src={invitation.senderAvatarUrl} alt={invitation.senderAvatarInitials} />}
								</div>
								<span className="avatarHoverText" style={{background: headerColor}}>{invitation.senderAvatarName}</span> 
							</div>
							
							{invitation.invitees.items.length > 1 ? <div className="TextStyle5" style={{paddingLeft:"5px", color:headerColor}}> &#x7c; </div> : null }
					</div>
					
				: null }
						
				{invitation.invitees.items.map((target, index) => { 
				
						//Display the invitee unless we are beyond the max or this is the sender and we already displayed him/her
						if (index <= numInviteesToShow  && (!showSender || target.inviteeID !== invitation.senderID)) return (
							<div key={target.id} >
								<div className="avatarContainer"> 
									<div className="avatarImage connectivityInviteAvatarImg" style={{backgroundColor:setHeaderColorByStringLength(target.inviteeAvatarName)}}>
										{!target.inviteeAvatarUrl  ? <div className="avatarInitials TextStyle2V2" > {target.inviteeAvatarInitials}</div> : <img className="avatarImageCenterPortrait" src={target.inviteeAvatarUrl} alt={target.inviteeAvatarInitials} /> }
									</div>
									<span className="avatarHoverText" style={{background: headerColor}}>{target.inviteeAvatarName}</span>
								</div>
							</div>);
						else return null;
					}) }


				{ invitation.invitees.items.length - (showSender ? 1 : 0 ) > numInviteesToShow ?
					//Display an extra cirle with a PLUS if we have exceeded our limit, excluding the SENDER if shown
					<div className="displayCardConectivityAvatarNoPaddingSmall connectivityInviteAvatarImg margin-none"style={{background:headerColor, color:"white"}}>
						<div className="avatarInitials TextStyle2V2" >+{invitation.invitees.items.length-numInviteesToShow - (showSender ? 1 : 0 )}</div>
					</div>
				: null
				}
		</div>
	);           
}

//Function invoked by this user to merge all the invitations that this user is on for a given scheduled conectivity
export async function mergeInvitations ({scheduledConectivity, currentUser, users, pushNotification }) {

	var successFlag = false;  

	//Safety check
	if (!scheduledConectivity || !scheduledConectivity.id || !currentUser) {
		if (DEBUG_MODE) console.error("Error - improper params to merge invitations", scheduledConectivity, currentUser);
		return successFlag;
	}

	try {

		const params = {
			scheduledConectivityID: scheduledConectivity.id,
		};
		const enqueueCommandInput = {
			userID: currentUser.id,
			customerID:currentUser.customerID,
			command: 'MERGE_INVITATIONS',
			params: JSON.stringify(params),
		};    

		//Call GraphQL to enqueue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Enqueuing Command Merge Invitations ", enqueueCommandInput);
		const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
		successFlag = (response === 'SUCCESS');
		if (DEBUG_MODE >= 2) console.log("Merge Invitations - Lambda returned", response, successFlag);        
	} catch (err) {
		
			if (DEBUG_MODE >= 2) console.log('error merginig invitations:', err);
			successFlag = false;
	} 
		
	return successFlag;
	
}
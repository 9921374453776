/*
    PROPRIETARY AND CONFIDENTIAL

    PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
    copyright - 2020, 2021

*/
import "./pieChart.css";

//React & Amplify
import React, { useEffect, useState, useContext, useRef  } from 'react';

//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR, COLOR_BLUE_HEADER } from '../../data/conectereConfigData';

//3rd party components
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Label, Tooltip } from 'recharts';


// Documentation
// Article on center labels
// https://celiaongsl.medium.com/2-secret-pie-chart-hacks-to-up-your-recharts-game-hack-recharts-1-9fa62ff9416a
// https://github.com/recharts/recharts/issues/191
//

//Colors and Data are arrays of equal sizes as in the following example

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 }
// ];
// const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];


//React component
const CCPieChart = ({title, title2, width, height, data, dataKey, colors, fillColor, innerRadius, outerRadius, showCenterTotal, centerTotalUnits, centerTotalLeadingUnits, renderLegend, renderTooltip}) => {

    const chartContainerRef = useRef(null);     //Use a ReactRef to track our container height / width
    const [centerTotal, setCenterTotal] = useState(0);
    
    // if (DEBUG_MODE >= 2) console.log("Pie called", title);
    // if (DEBUG_MODE >= 2) console.log("data", data);
    // if (DEBUG_MODE >= 2) console.log("dataKey", dataKey);
    // if (DEBUG_MODE >= 2) console.log("colors array", colors);
    // if (DEBUG_MODE >= 2) console.log("fillColor", fillColor);
    // if (DEBUG_MODE >= 2) console.log("innerRadius", innerRadius);
    // if (DEBUG_MODE >= 2) console.log("outerRadius", outerRadius);


    if (!height) height = "clamp(360px, 30vw, 500px)";
    if (!width) width = "clamp(288px, 24vw, 400px)";


    // if (DEBUG_MODE >= 2) console.log("Pie Chart - width / height", (chartContainerRef.current ? chartContainerRef.current.scrollWidth : 0), (chartContainerRef && chartContainerRef.current ? chartContainerRef.current.scrollHeight : 0));
    
   if (!centerTotalUnits) centerTotalUnits = "";
   if (!centerTotalLeadingUnits) centerTotalLeadingUnits = "";
   

    useEffect (() => {
        if (data) updateCenterTotal();
    },[data]);
 
    function updateCenterTotal() {
        let tempCenterTotal = 0;
       if (dataKey==="value") { 
            //NOTE - FOR NOW WE ARE ASSUMING THE DATAKEY IS 'value' FIELD; TBD USE DATAKEY
            for (var j=0;j<data.length;j++) {
                tempCenterTotal += data[j].value;
            }
            tempCenterTotal = Math.round(100*tempCenterTotal)/100;
       }   
       setCenterTotal(tempCenterTotal);
    }

   
    return (
        
        <div className="pieChart" id="chartContainer">
            <div className="pieChartTitle">{title}</div>
            {title2 ? <div className="pieChartTitle2">{title2}</div> : ""}
            
            <div ref={chartContainerRef} style={{width:width, height:height, position:"relative"}}>

                    <PieChart 
                        width={width}
                        height={height}
                        width={chartContainerRef && chartContainerRef.current && chartContainerRef.current.scrollWidth > 0 ? chartContainerRef.current.scrollWidth : 500} 
                        height={chartContainerRef && chartContainerRef.current && chartContainerRef.current.scrollHeight > 0  ? chartContainerRef.current.scrollHeight :400}>
                        
                        <Pie data={data} 
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            fill={fillColor}
                            paddingAngle={5}
                            dataKey={dataKey}
                            label
                        >
                            
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                          ))}
    
    
                            {!showCenterTotal ? "" : 
                            
                              <Label 
                                    value={centerTotalLeadingUnits + centerTotal + centerTotalUnits} 
                                    position="center" 
                                    fill="#6495ED"
                                    style={{
                                    fontSize: "1.4rem",
                                    fontWeight: "bold",
                                  }}                               
                                />
                            }
    
                        </Pie>
                        
                        
                            { renderTooltip ?
                                <Tooltip content = {renderTooltip}/>
                                :
                                <Tooltip />
                            }
                                
                            
                            {renderLegend ?
                                <Legend 
                                    content = {renderLegend}
                                />
                            :
                                <Legend 
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom" 
                                    height={14} 
                                    iconType={'circle'}
                                />
    
                            }
                            
    
                    </PieChart>
            </div>
        </div>
    );
};


export default CCPieChart;



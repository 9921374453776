//
//  PROPRIETARY AND CONFIDENTIAL
//
//  copyright - 2020, 2021
//
// Examples:
// https://www.youtube.com/watch?v=XtMThy8QKqU
// https://www.youtube.com/watch?v=x_EEwGe-a9o


import "./launchpad.css";

//Config data
import  { CONECTERE_CONFIG_DATA, TEAM_COLOR, DEBUG_MODE, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, RATING_STATS_INITIAL_STATE, GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, QUICK_FILTERS_FOR_CONTECTIVITIES, DAYS_FOR_RECENTLY_ADDED_CONECTIVITIES } from '../../shared/data/conectereConfigData';

//React & Amplify components
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';


//CONTEXT
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { ModalContext } from '../../shared/context/modalContext';            


//Our Components
import ConectivityDetailedView from "../../shared/Components/conectivityDetailedView/conectivityDetailedView";
import ConectivityLauncher from "../../shared/Components/conectivityLauncher/conectivityLauncher";
import SearchInputBar from "../../shared/Components/searchInputBar/searchInputBar.js";
import LaunchRow from  "./Components/launchRow/launchRow";
import StarRatings from "../../shared/Components/starRatings/starRatings";

//Utils
import { conectivitiesMatchesSearch, compareConectivitiesMostFavorite, compareConectivitiesLeastFavorite, setHeaderColorByCategory, setBackgroundGradientClassByCategory, setConectivityImageUrlByCategory, compareConectivitiesByTitle } from "../../shared/utils/conectivityUtils";
import { LaunchIcon, LaunchButton, QuickFilterButton, getRandomInt, truncateStringWithDots, compareByCreatedAt} from "../../shared/utils/generalUtils";
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,
		buildRecurrenceString, getrecurrenceRuleLaunchDates, getRecurrenceRuleNextLaunchDate,isRecurrenceRuleLaunchToday,
		} from "../../shared/utils/dateTimeUtils";
import moment from 'moment';
		
   //
   // BANNER
   //
	
	//Component for showing at the top of the page the being viewed at Conectivity in a ROW
	const Banner = ({initialConectivityToSpotlight, conectivities, handleLaunch, handleDetailedView}) => {
		
		const [conectivityToSpotlight, setConectivityToSpotlight]  = useState(initialConectivityToSpotlight);   
	
		//Set up React useEffect set up a timer for once second, causing just the Banner to render without changing the rest of the page
		//Upon render, restart the timer
		
		useEffect(() => {
		  const timer = setTimeout(() => {              
			if (conectivities && conectivities.length > 0) {
				
				var foundConectivity = false;
				var guesses = 0;
				var nextConectivity = {};
				while (!foundConectivity) {
					nextConectivity = conectivities[getRandomInt(0, conectivities.length-1)];
					guesses++;
					if ((nextConectivity && nextConectivity.category && nextConectivity.category.label !== "TUTORIAL") || guesses > 10) foundConectivity = true;
				}               
				setConectivityToSpotlight(nextConectivity );  
			}
			// if (DEBUG_MODE >= 2) console.log("updating Conectivity to preview in Banner", conectivities);
	
		  }, 7000); //Fire in 7 seconds
		
			// Clear timeout if the component is unmounted
			return () => clearTimeout(timer);
		});
		
		if (conectivityToSpotlight == null) return null;
	
		const backgroundImageURL = "url('" + setConectivityImageUrlByCategory(conectivityToSpotlight.category.label,true) +  "')";
		// if (DEBUG_MODE >= 2) console.log("Conectivity to preview in Banner", conectivityToSpotlight, backgroundImageURL);

		return (    
			<header className="bannerHeader" style={{backgroundImage:backgroundImageURL}}>
				<div className="bannerInnerContainer" >
					<div className="bannerContents">
					
						 <div className="TextStyle6A bannerTitle" >
							{truncateStringWithDots(conectivityToSpotlight.title,50)}
						</div>
						<div className="bannerButtons">
							<button className="bannerButton" onClick={()=> handleLaunch(conectivityToSpotlight)}>
								<div className="ContainerNoHeightCenter" >
									<LaunchIcon color={setHeaderColorByCategory(conectivityToSpotlight.category.label)}  />
									<div style={{paddingLeft:"5px"}}>LAUNCH</div>
								</div>
							</button>
											
							<button className="bannerButton" style={{marginLeft:"5px"}} onClick={()=> handleDetailedView(conectivityToSpotlight)}>VIEW</button>
						</div>
						 <div className="bannerDescription TextStyle5" >
							{truncateStringWithDots(conectivityToSpotlight.description, CONECTERE_CONFIG_DATA.CONECTIVITY_DESCRIPTION_MAX_CHARS)}
						</div>
						<div  className="bannerAPT TextStyle3">
						   <i> {!conectivityToSpotlight.approvedPaidTime ? "" : conectivityToSpotlight.approvedPaidTime + " min"}</i>
						</div>
						<div className="bannerStarWrapper" >
							<StarRatings rating={conectivityToSpotlight.ratingStats.ratingsAverage} readOnly={true} starSize={"1.0vw"}/>
						</div>
					</div>
					<div className={`bannerFadeLeftToRight ${setBackgroundGradientClassByCategory(conectivityToSpotlight.category.label)}`} ></div>
				</div>
			</header>
		);
		
	};
//React Component
const AdminLaunchpad = () => {
    // Context
	 const { currentReactPage } = useContext(DisplayContext); 
	 const { authState, currentUser, } = useContext(AuthContext);    
	 const { conectivitiesReadyForLaunch } = useContext(ConectivityContext);
	 const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
	  
	// local state
	const [conectivitiesFilteredMaster, setConectivitiesFilteredMaster] = useState([]); //Filtered list based on Search; used to drive all the row data
	const [conectivitiesFavorites, setConectivitiesFavorites] = useState([]);
	const [conectivitiesRecentlyAdded, setConectivitiesRecentlyAdded] = useState([]);
	const [conectivitiesCompanySpecific, setConectivitiesCompanySpecific]  = useState([]);
	const [conectivitiesChallenges, setConectivitiesChallenges]  = useState([]);
	const [conectivitiesSpecialEvents, setConectivitiesSpecialEvents]  = useState([]);
	const [conectivitiesSuggestions, setConectivitiesSuggestions] = useState([]);
	const [conectivitiesBalance, setConectivitiesBalance] = useState([]);
	const [conectivitiesTeam, setConectivitiesTeam] = useState([]);
	const [conectivitiesSocial, setConectivitiesSocial] = useState([]);
	const [conectivitiesGrowth, setConectivitiesGrowth] = useState([]);
	const [conectivitiesTutorials, setConectivitiesTutorials] = useState([]);
	const [conectivitiesDEI, setConectivitiesDEI] = useState([]);
	const [conectivitiesCS, setConectivitiesCS] = useState([]);
	const [conectivityToSpotlight, setConectivityToSpotlight]  = useState(null);   
	const [conectivityToLaunch, setConectivityToLaunch] = useState(CONECTIVITY_INITIAL_STATE);              //Used to hold a conectivity that is to be launched
	const [conectivityToView, setConectivityToView] = useState(CONECTIVITY_INITIAL_STATE);              //Used to hold a conectivity that is to be launched

	
	// const [pauseBannerUpdate, setPauseBannerUpdate] = useState(false);  //Used as a state variable set to TRUE when updating the backend
	const [showModalLaunch, setShowModalLaunch] = useState(false);
	const [showModalConectivityDetailed, setShowModalConectivityDetailed] = useState(false);      //Detailed Conectivity View
	// const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);     //for multi-select
	// const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);     //for multi-select

	//State for Search Bar
	const [searchTerm, setSearchTerm] = useState("");
	const [searchActive, setSearchActive] = useState(false);
	const [activeFilters, setActiveFilters] = useState([]);


	
	//
	// Main UseEffect for maintaining conectivities based on updates to parent context
	//

   //On any change to the underlying conectivity data, reset our page state variables
	useEffect(() => {
		
		if (DEBUG_MODE >= 2) console.log("Updated conectivities ready for launch", conectivitiesReadyForLaunch);
		setConectivitiesFilteredMaster([...conectivitiesReadyForLaunch]);     //Make a fresh copy of the master published conectivities; this list is effected by the Search 

		//Initialize the banner
		if (conectivitiesReadyForLaunch && conectivitiesReadyForLaunch.length >0) setConectivityToSpotlight(conectivitiesReadyForLaunch[getRandomInt(0, conectivitiesReadyForLaunch.length-1)]);
	   
	}, [conectivitiesReadyForLaunch]);

	//Pre-load conectivity to display on page load based on passed in URL  
	useEffect(() => {
		
		//Safety check - no conectivities yet?  Exit
		if (!conectivitiesReadyForLaunch) return;
	 
		if (DEBUG_MODE >= 2) console.log("Launchpad parsing URL", currentReactPage.pathname);
		
		const url = currentReactPage.pathname;
 
		//Now, check the URL params to see if we should open a specific Conectivity for th user
		const searchParams = new URLSearchParams(currentReactPage?.search);
		if (DEBUG_MODE >= 1) console.log("Launchpad - Current React Page", currentReactPage);
		if (DEBUG_MODE >= 1) console.log("Launchpad - Page React Search Params (after ?)", searchParams);
		if (searchParams.has("CONECTIVITY")) { 
			if (DEBUG_MODE >= 1) console.log("Conectivity param Detected!!");
			const conectivityID = searchParams.get("CONECTIVITY");
			if (DEBUG_MODE >= 1) console.log(" CONECTIVITY ID =", conectivityID);    
			if (conectivityID) {
				const tempConectivityToView = conectivitiesReadyForLaunch.find(c => c.id === conectivityID);
				if (tempConectivityToView) {
					if (DEBUG_MODE >= 2) console.log("Found conectivity to view based on URL", tempConectivityToView);
					setConectivityToView(tempConectivityToView);
					setShowModalConectivityDetailed(true);  
				} {
					if (DEBUG_MODE >= 2) console.log("Error - conectivity from URL not found", url);
				}
			} else if (DEBUG_MODE >= 2) console.log("Error - no conectivity param", url);                             
		}  
	},[conectivitiesReadyForLaunch, currentReactPage]); 
	
	//Use effect for generating new rows and spotlighted conectivitiy; any change to Conectivities will fire a filtering operation
	
	useEffect(() => {
		generateRows();  
	}, [conectivitiesFilteredMaster]);

	function generateRows() {
		if (DEBUG_MODE) console.log("Generating new rows");
 
			//Copy Master
			var filteredConectivities = [...conectivitiesFilteredMaster];
			
			//Filter Conectivities to only those that are PUBLISHED; save as Master
			var tempFurtherFiltered =[];
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			var filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "STRESS");
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Stress row", filteredConectivitiesForRow);
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesBalance(filteredConectivitiesForRow);

			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "SOCIAL");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesSocial(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Social row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "TEAM");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesTeam(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Team row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label === "PERSONAL");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesGrowth(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for DEI row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.badgesDEI > 0);
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesDEI(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for DEI row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.badgesCS > 0);
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesCS(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for CS row", filteredConectivitiesForRow);

			//Generate row of Recently Added
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => (conectivityRecentlyAdded(conectivity) && conectivity.category.label !== "TUTORIAL"));
			filteredConectivitiesForRow.sort(compareByCreatedAt);                      //Sort the Conectivities
			setConectivitiesRecentlyAdded(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Recently Added row", filteredConectivitiesForRow);

			//Generate row of company-specific
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => ((conectivity.conectivityScope == "CUSTOMER_SPECIFIC" || conectivity.conectivityScope === "CUSTOMER_SHARED") && (conectivity.createdByCustomerID == currentUser.customerID)));
			filteredConectivitiesForRow.sort(compareByCreatedAt);                      //Sort the Conectivities
			setConectivitiesCompanySpecific(filteredConectivitiesForRow);
			if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Company Specific row", currentUser, filteredConectivitiesForRow);

			//Generate row of challenges
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => (conectivity.isChallenge));
			filteredConectivitiesForRow.sort(compareByCreatedAt);                      //Sort the Conectivities
			setConectivitiesChallenges(filteredConectivitiesForRow);
			if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for CHALLENGES row", currentUser, filteredConectivitiesForRow);

			//Generate row of company-specific
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => (conectivity.isSpecialEvent));
			filteredConectivitiesForRow.sort(compareByCreatedAt);                      //Sort the Conectivities
			setConectivitiesSpecialEvents(filteredConectivitiesForRow);
			if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for SPECIAL EVENTS row", currentUser, filteredConectivitiesForRow);

		   //Generate list of favorites
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.ratingStatsID !== null && conectivity.ratingStatsID != undefined); //May not really be necessary but some conectivities won't have this until rated
			// if (DEBUG_MODE >= 2) console.log("Initial Filtered Conectivities for Favorites row", filteredConectivitiesForRow);
			tempFurtherFiltered = filteredConectivitiesForRow.filter(conectivity => (conectivity.ratingStats.ratingsAverage >= 3.0 && conectivity.category.label != "TUTORIAL"));  //Grab all that have at least a 3 star rating
			tempFurtherFiltered.sort(compareConectivitiesMostFavorite);     //Sort the Conectivities
			tempFurtherFiltered = tempFurtherFiltered.slice(0,8);           //Grap the top 8

			setConectivitiesFavorites(tempFurtherFiltered);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Favorites row", tempFurtherFiltered);

			//TBD - maybe turn the above into a top 10 list by doing a slice?


		   //Generate list of recommendations
		   //In the future, this will be based on areas where the user may need help, i.e., low ratings in some categories using a weighting system
		   // may also be based on their previous ratings
		   // TBD - write this algorithm
		   // For now, simply pick random conectivities
			filteredConectivitiesForRow = [];
			
			if (filteredConectivities.length > 0) {
				
				for (var loop = 0; loop < 8 && loop < filteredConectivities.length ; loop++) {
					var randomConectivityIndex = getRandomInt(0, filteredConectivities.length-1);
					
					//Add conectivity only if not already in the array of suggestions
					if ((filteredConectivitiesForRow.some(c => c.id == filteredConectivities[randomConectivityIndex].id) == false) && filteredConectivities[randomConectivityIndex].category.label != "TUTORIAL") {
						// if (DEBUG_MODE >= 2) console.log("Random index added", randomConectivityIndex, filteredConectivities[randomConectivityIndex]);
						filteredConectivitiesForRow.push(filteredConectivities[randomConectivityIndex]);
					} else {
						// if (DEBUG_MODE >= 2) console.log("Random index already added", randomConectivityIndex, filteredConectivities[randomConectivityIndex]);

					}
				}                
				
				filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                 //Sort the Conectivities
				// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Suggestions row", filteredConectivitiesForRow);
			} 
			setConectivitiesSuggestions(filteredConectivitiesForRow);

			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "TUTORIAL");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesTutorials(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Tutorials row", filteredConectivitiesForRow);

	}  

	function conectivityRecentlyAdded(conectivity) {

		const startOfPeriod= moment().subtract(DAYS_FOR_RECENTLY_ADDED_CONECTIVITIES, 'days').startOf('day');
		const conectivityCreatedDate = moment(conectivity.createdAt, "YYYY MM DDTHH mm ssZ");
		

		if (conectivityCreatedDate.isAfter(startOfPeriod)) {
			// if (DEBUG_MODE >= 2) console.log("RETURNED TRUE - Comparing conectivity date and 90 day period", conectivityCreatedDate.format("YYYY-MM-DDTHH:mm:ssZ"), startOfPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));
			return true;
		} else {
			// if (DEBUG_MODE >= 2) console.log("RETURNED FALSE - Comparing conectivity date and 90 day period", conectivityCreatedDate.format("YYYY-MM-DDTHH:mm:ssZ"), startOfPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));
			return false;
		}
	}    
	
	//Use effect for processing updates to the Search Term
	//Also invoked if our conectivities have asynchronously updated
	useEffect(() => {
		processSearchTermChange();  
		if (DEBUG_MODE >= 2) console.log("Search term change detected in Use Effect", searchTerm);
	}, [searchTerm, activeFilters, conectivitiesReadyForLaunch]);
	
	function processSearchTermChange () {
		if (DEBUG_MODE >= 2) console.log("Processing search term change", searchActive, activeFilters);
		let tempConectivities = [...conectivitiesReadyForLaunch]; //Copy Master
	  
		if (searchActive || (searchTerm || (activeFilters && activeFilters.length > 0))) {           
			tempConectivities = tempConectivities.filter(conectivity => conectivitiesMatchesSearch({conectivity, activeFilters, keywords:searchTerm})); //Filter based on new term          
			if (DEBUG_MODE >= 2) console.log("Generated new filtered list based on search term", searchTerm, tempConectivities);       
		}
		setConectivitiesFilteredMaster(tempConectivities);
   }   
 
 
   // Pop up a modal for launching a conectivity for a specific user or team
   const handleLaunch = (conectivityToLaunch) => {
   
	 // e.stopPropagation();        //prevent outer Card OnClick from firing and displaying full instruction modal
   
	 if (DEBUG_MODE >= 2) console.log("LAUNCH button pressed.  Launch Conectivity=", conectivityToLaunch); 
	 
	 //Safety check
	 if (!conectivityToLaunch) {if (DEBUG_MODE >= 2) console.log("Error no conectivity to launch"); return;}
	 
	 //Set START and END time based on duration of conectivity to Launch
	 // setConectivityToLaunchEventStartDateTime(NOW_ROUNDED_UP_TO_TEN_MIN());
	 // setConectivityToLaunchEventEndDateTime(NOW_ROUNDED_UP_TO_TEN_MIN().add(conectivityToLaunch.approvedPaidTime,"minutes"));
	 
	 setConectivityToLaunch(conectivityToLaunch);
	 setShowModalLaunch(true); //pop-up Modal
	 
   };
   
   
   const handleCloseLaunchModal = ({result, message}) => {
	 setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
	 setShowModalLaunch(false);
   
	 if (result && result !== "CANCELED" && message) {
		 if (result) setModalGeneralTitle("Success!!");  
		 else setModalGeneralTitle("Ooops!");
		 setModalGeneralMessage(message);
		 setShowModalGeneral(true);
	 }
	 
	 if (DEBUG_MODE >= 2) console.log("Closed Conectivity Editor", result, message);
   };
	
		
   //REACT USECALLBACK to avoid re-render
   // https://alexsidorenko.com/blog/react-list-rerender/
   const onChangeCallback = useCallback ((value) => {
		setSearchTerm(value);
		if (DEBUG_MODE >= 2) console.log("Search bar CALLBACK called", value);
	  
   },[]);
   
   const onFilterChangeCallback = useCallback ((activeFilters) => {
		setActiveFilters(activeFilters);
		if (DEBUG_MODE >= 2) console.log("Search bar QUICK FILTER CALLBACK called", activeFilters);
	  
   },[]);
   
   const searchBarIsActiveCallback = useCallback ((active) => {
		setSearchActive(active);
		if (DEBUG_MODE >= 2) console.log("Search bar IS ACTIVE CALLBACK called", active);
   },[]);
   
	 //handle close modal
	const handleCancelDetailedView = () => {
		// setPauseBannerUpdate(false); //disable buttons on main page
		setShowModalConectivityDetailed(false);
		setConectivityToView(CONECTIVITY_INITIAL_STATE);
	   //if (DEBUG_MODE >= 2) console.log("Closed Conectivity Detail View.");
	};
		
	  //handle open modal
	function handleDetailedView (conectivityToViewDetailed) {
		// setPauseBannerUpdate(true); //disable buttons on main page
		if (DEBUG_MODE >= 2) console.log("Opened Conectivity Detail View.", conectivityToViewDetailed);
		setConectivityToView(conectivityToViewDetailed);
		setShowModalConectivityDetailed(true);
	} 

	//Set the title to be used for the row of customer-specific conectivities
	function assignCompanyRowTitle() {
	   if (!currentUser) return "";
	   if (currentUser.customer.nickname) return currentUser.customer.nickname;
	   else return currentUser.customer.name;
	}    

 //Handle access by unauthenticated user
  if (authState !== "signedin"  || !currentUser) {
	  if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState == "signedout") popUpLoginWindow();
	  return null;
  }

 //Render function for authenticated user 
  return (
		<Authenticator>
			
			 <ConectivityLauncher  
				showModalSendInviteToConect={showModalLaunch}  
				conectivityToProcess={conectivityToLaunch} 
				handleCloseLauncher={handleCloseLaunchModal}  
			 />
			 
		
			 <ConectivityDetailedView showModal={showModalConectivityDetailed} conectivityToView={conectivityToView} callbackLaunchClick={()=>{setShowModalConectivityDetailed(false);handleLaunch(conectivityToView)}} handleCancelDetailedView={handleCancelDetailedView} />
			 
			{/*. launchModal() */}
			
			<div className="launchPadPageWrapper" >
			
				<SearchInputBar useDarkInput={true} useAnchorPostion={false} enableSearchInput={true} onSearchStringChangeCallback={onChangeCallback} onFilterChangeCallback={onFilterChangeCallback} searchBarIsActiveCallback={searchBarIsActiveCallback} searchTermInitialValue={searchTerm} searchActiveInitialValue={searchActive} quickFilters={QUICK_FILTERS_FOR_CONTECTIVITIES}/>
			
				<Banner initialConectivityToSpotlight={conectivityToSpotlight} conectivities={conectivitiesReadyForLaunch} handleLaunch={handleLaunch} handleDetailedView={handleDetailedView}/> 
 
				<LaunchRow key={"0.5"} id={"0.5"} title={assignCompanyRowTitle()} conectivities={conectivitiesCompanySpecific} isLargeRow showPreviewCallback={handleDetailedView} launchCallback={handleLaunch}/>

				{/*<LaunchRow key={"1"} id={"1"} title="Recommendations" conectivities={conectivitiesSuggestions} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} isLargeRow/> */}

				<LaunchRow key={"2"} id={"2"} title="Recently Added" conectivities={conectivitiesRecentlyAdded} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} isLargeRow/>

				 {/* <LaunchRow key={"2.5"} id={"2.5"} title="Favorites" conectivities={conectivitiesFavorites} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} isLargeRow/> */}

				<LaunchRow key={"2.5"} id={"2.5"} title="Challenges" conectivities={conectivitiesChallenges} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} isLargeRow/>

				<LaunchRow key={"3"} id={"3"} title="Special Events" conectivities={conectivitiesSpecialEvents} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} isLargeRow/>

				<LaunchRow key={"4"} id={"4"} title="Balance" conectivities={conectivitiesBalance} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />
	
				<LaunchRow key={"5"} id={"5"} title="Team Building" conectivities={conectivitiesTeam} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />
				
				<LaunchRow key={"6"} id={"6"} title="Social" conectivities={conectivitiesSocial} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />
	
				<LaunchRow key={"7"} id={"7"} title="Growth & Development" conectivities={conectivitiesGrowth} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />
				
				<LaunchRow key={"8"} id={"8"} title="Diversity, Equity & Inclusion" conectivities={conectivitiesDEI} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />
				
				<LaunchRow key={"9"} id={"9"} title="Community Service" conectivities={conectivitiesCS} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />

				<LaunchRow key={"10"} id={"10"} title="Tutorials" conectivities={conectivitiesTutorials} showPreviewCallback={handleDetailedView} launchCallback={handleLaunch} />
  
			</div>
  
		<div className="launchPadBlankRow" > </div>
	</Authenticator>  
  );

};

export default AdminLaunchpad;


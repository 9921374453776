//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, GIPHY_ID_IDENTIFIER, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR,  } from '../data/conectereConfigData';

//React & Amplify
import React from 'react';

//Utils
import { invokeAPI } from "./databaseUtils";


//GraphQL API
import { updateScheduledConectivity, createInvitation, deleteInvitation, updateInvitation, updateLaunchRule } from '../graphql/mutations';
import { createInvitee, deleteInvitee, updateInvitee } from '../graphql/mutations';
import { createMessage, deleteMessage, updateMessage } from '../graphql/mutations';
import { enqueueCommand } from '../graphql/mutations';


import { v4 as uuidv4 } from 'uuid';    
import moment from 'moment';


///
//
// MESSAGING UTILITIES
//
//
//
/*  INPUTS
		message - Text to send as a message
		pushMessage - Text to send as a notification to mobile devices
		launchRuleID  - the parent LaunchRule with which the messages are associated
		recognitionID  - the parent LaunchRule with which the messages are associated
		invitation (Optional) - the specific invitation to which the messages, IF ANY
		
		Either launchRuleID or recognitionID MUST be supplied
*/
export async function invokeSendMessage({message, senderID, customerID, launchRuleID, recognitionID, invitationID, parentMessageID, pushMessage, mediaUrl}) {
		

   //Safety check
   if (!senderID || !customerID || !(launchRuleID || recognitionID) || (!message && !mediaUrl)) {
      if (DEBUG_MODE >= 1) console.log("Error sending message - improper params", senderID, customerID, launchRuleID, recognitionID, message, mediaUrl);            
       return {successFlag:false, tempInsertedMessage:null};
   }
   
   let successFlag = false, tempInsertedMessage = null;
   
   try {

      //Call to our Enqueue Command lambda to inject the command into our cloud
       const params = {
         origin:'CONECTERE',
         message: message,                 
         launchRuleID:launchRuleID,         //launchRuleID OR recognitionID is REQUIRED
         recognitionID:recognitionID,       //launchRuleID OR recognitionID is REQUIRED
         pushMessage: pushMessage, 
         mediaUrl:mediaUrl,                 //S3 location of file, if any, associated with the message
      };     
      
      if (invitationID) params.invitationID = invitationID;            //OPTIONAL - only included if the message relates to a specific invitation rather than just the parent LR
      if (parentMessageID) params.parentMessageID = parentMessageID;   //OPTIONAL - only included if the message has a parent message
      
      const enqueueCommandInput = {
         userID: senderID,              //REQUIRED
         customerID:customerID,   //REQUIRED
         command: 'SEND_MESSAGE',
         params: JSON.stringify(params),
      };   
      
      //Call GraphQL to enqueue the command with our Lambda function 
      if (DEBUG_MODE >= 2) console.log("Enqueuing Command SEND_MESSAGE", enqueueCommandInput);
      const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
      successFlag = (response === 'SUCCESS');
      if (DEBUG_MODE >= 2) console.log("Enqueue Lambda returned", response, successFlag);        

      successFlag = true;
      
   } catch (err) {
      console.log("Error sending message", err);
      successFlag = false;
   }

   return {successFlag, tempInsertedMessage};
}

export async function invokeUpdateMessage(message) {
      //Safety check
      if (!message || !message.id || !message.customerID) {
         if (DEBUG_MODE >= 1) console.log("Error updating message - improper params", message);            
          return {successFlag:false};
      }
      let successFlag = false;
      if (DEBUG_MODE >= 1) console.log("Updating message", message);            
   
      try {
   
         //Call to our Enqueue Command lambda to inject the command into our cloud
          const params = {
            origin:'CONECTERE',
            message: message,                 
         };     
               
         const enqueueCommandInput = {
            customerID:message.customerID,       //REQUIRED
            userID:message.senderID,                      //Required
            command: 'UPDATE_MESSAGE',
            params: JSON.stringify(params),
         };   
         
         //Call GraphQL to enqueue the command with our Lambda function 
         if (DEBUG_MODE >= 1) console.log("Enqueuing Command UPDATE_MESSAGE", enqueueCommandInput);
         const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
         successFlag = (response === 'SUCCESS');
         if (DEBUG_MODE >= 1) console.log("Enqueue Lambda returned", response, successFlag);        
         successFlag = true;
      } catch (err) {
         if (DEBUG_MODE) console.log("Error updating message", err);
         successFlag = false;
      }
   
      return {successFlag};

}

export async function invokeDeleteMessage({userID, message}) {
		

   //Safety check
   if (!message || !message.id || !message.customerID) {
      if (DEBUG_MODE >= 1) console.log("Error deleting message - improper params", message);            
       return {successFlag:false};
   }
   
   let successFlag = false;
   if (DEBUG_MODE >= 1) console.log("Deleting message", message);            

   try {

      //Call to our Enqueue Command lambda to inject the command into our cloud
       const params = {
         origin:'CONECTERE',
         messageID: message.id,                 
      };     
            
      const enqueueCommandInput = {
         customerID:message.customerID,       //REQUIRED
         userID:userID,                      //Required
         command: 'DELETE_MESSAGE',
         params: JSON.stringify(params),
      };   
      
      //Call GraphQL to enqueue the command with our Lambda function 
      if (DEBUG_MODE >= 2) console.log("Enqueuing Command DELETE_MESSAGE", enqueueCommandInput);
      const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
      successFlag = (response === 'SUCCESS');
      if (DEBUG_MODE >= 2) console.log("Enqueue Lambda returned", response, successFlag);        
      successFlag = true;
   } catch (err) {
      if (DEBUG_MODE) console.log("Error deleting message", err);
      successFlag = false;
   }

   return {successFlag};
}

export async function invokeUpdateMessageLikes({senderID, customerID, messageID, userLikes, recognitionID, }) {
   
   //Safety check
   if (!messageID  || !customerID || !senderID) {
      if (DEBUG_MODE >= 1) console.log("Error updating message - improper params", senderID, customerID, messageID, userLikes);            
       return {successFlag:false};
   }
   
   let successFlag = false;
   
   try {

      //Call to our Enqueue Command lambda to inject the command into our cloud
       let params = {
         origin:'CONECTERE',
         messageID:messageID,         //REQUIRED
         userLikes:userLikes,   //REQUIRED
      };     
      
      if (recognitionID) params.recognitionID = recognitionID;
      
      const enqueueCommandInput = {
         userID: senderID,            //REQUIRED
         customerID:customerID,       //REQUIRED
         command: 'UPDATE_MESSAGE_LIKES',
         params: JSON.stringify(params),
      };   
      
      //Call GraphQL to enqueue the command with our Lambda function 
      if (DEBUG_MODE >= 2) console.log("Enqueuing Command UPDATE_MESSAGE_LIKES", enqueueCommandInput);
      const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
      successFlag = (response === 'SUCCESS');
      if (DEBUG_MODE >= 2) console.log("Enqueue Lambda returned", response, successFlag);        

      successFlag = true;
      
   } catch (err) {
      console.log("Error updating message", err);
      successFlag = false;
   }

   return {successFlag};
}

export async function updateMessageReadAt(invitation, currentUser){

//Safety check
if (!currentUser || !invitation || !invitation.id) return;

//Confirm this invitation has invitees - safety check
if (!invitation.invitees || !invitation.invitees.items || invitation.invitees.items.length === 0) {
   if (DEBUG_MODE > 0) console.error("Error - no invitees for this invitation", invitation);
   return;
}

try {    
   //Update messagesReadAt for this user
   var newTimestampAWS = moment().toISOString();

   //Is the User one of the invitees?
   const userInvite = invitation.invitees.items.find(invitee => invitee.inviteeID === currentUser.id);
   if (userInvite && userInvite.id) await invokeAPI(updateInvitee, 'updateInvitee', {id: userInvite.id, messagesReadAt: newTimestampAWS});    //update DB
   else if (DEBUG_MODE > 0) console.error("Error - current user is NOT an Invitee");
   
   //Is this user the SENDER of the invitation?  Note, could be both an invitee and the sender
   if (invitation.senderID == currentUser.id)  await invokeAPI(updateInvitation, 'updateInvitation', {id: invitation.id, messagesReadAt: newTimestampAWS});  //update Invitation - trigger subscription push
   
   //Trigger an update and page refresh via SC subscription
   // const result = await invokeAPI(updateScheduledConectivity, 'updateScheduledConectivity', {id: invitation.scheduledConectivityID});  //update Invitation - trigger subscription push.  Need this since no subscription for invitees
   // console.log("UPDATE:", result);
} catch (err) {
  if (DEBUG_MODE >= 2) console.error("ERROR updating messages readAt", err, invitation);        
}
}   

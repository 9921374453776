//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import '../styles/App.css';

import './authContext.css';
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, IS_MANAGMENT_PORTAL } from '../data/conectereConfigData';


//CONTEXT
import { AuthContext } from './authContext';                //User Authentication context

import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
//Note - Redirect is no longer used https://stackabuse.com/redirects-in-react-router/
import { Navigate  } from "react-router-dom";  
import { generateClient } from 'aws-amplify/api';   // Amplify V6

//Queries and Mutations
import { listUsers} from '../graphql/queries';
import { getCustomer, listCustomers } from '../graphql/queries';
import { getUserByCustomer } from '../graphql/queries';
import { listTeams, getTeamByCustomerID } from '../graphql/queries';
import { listCategories,  } from '../graphql/queries';
import { listUsersShallow, getUsersByCustomerShallow, listTeamsShallow, getTeamsByCustomerShallow} from '../graphql/queries-custom';


// import {ConfigContext } from './configContext'  //Customer Config context

//Utils
import { compareUsersByName, compareTeamsByName, getUserInitials } from "../utils/userAndTeamUtils";
import { compareCustomersByName} from "../utils/customerUtils";
import { compareByName } from "../utils/generalUtils";
import { queryDataTableWithPagination, getDataRecordById, fetchCategories } from "../utils/databaseUtils";
import moment from 'moment-timezone';

//Subscriptions
import { onUpdateCustomer, onDeleteCustomer } from "../graphql/subscriptions";
import { onUpdateUser, onDeleteUser } from "../graphql/subscriptions";
import { onUpdateTeam, onDeleteTeam } from "../graphql/subscriptions";

const API = generateClient(); //Amplify v6
const CustomerContext = React.createContext();
const CustomerProvider = ({ children }) => {

	// Determine our platform
	const IS_MANAGMENT_PORTAL = (process.env ? process.env.IS_MANAGMENT_PORTAL : false);

   // Authentication context
	const { authState, setAuthState, currentUser, setCurrentUser, isSuperAdmin, isAdmin,   } = useContext(AuthContext);    

	//State variables for customers, users and teams; storing as part of context since we need this for analytics 
	const[customers, setCustomers] = useState([]);
	const [users, setUsers] = useState([]);
	const [teams, setTeams] = useState([]);
	// const [usersMaster, setUsersMaster] = useState([]);
	// const [teamsMaster, setTeamsMaster] = useState([]);

	//variables to store dynamic lists of options 
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [customerOptions, setCustomerOptions] = useState([]);
	const [customerOptionsNoAll, setCustomerOptionsNoAll] = useState([]);
	const [teamsOptions, setTeamsOptions] = useState([]);
	const [usersOptions, setUsersOptions] = useState([]);
	const [participatingUsersOptions, setParticipatingUsersOptions] = useState([]);  //All users except for current user


	//Master state variables used by SuperAdmin
	const [selectedCustomerOptions, setSelectedCustomerOptions] = useState(null); //Fixed but for SuperAdmin
	
	//state variable for invoking (rendering) React redirct function
	const [redirectToPage, setRedirectToPage] = useState(null);
	const [redirectToHome, setRedirectToHome] = useState(false);

	//variable for causing AWS Login screen to be displayed
	// const [showSignInPopUp, setShowSignInPopUp] = useState (false);
	
	//Create an Ampllify listener to listen to Amplify Auth messages (events)
	//Use this to trigger responses to changes, especially to cause rendering of pages & the Header whent he user logs in

	const currentReactPage = useLocation(); //This React reference is updated every time the URL changes

	// In resopnse to change in AuthState indicating user authenticated and loaded from DB, fetch relevant customer data 
	// Retrieve relevant customers, users, teams & generate drop-down options and other user-specific data structures 
	useEffect(() => {
		if (DEBUG_MODE >= 2) console.log("Customer Context AUTH STATE Use Effect", authState, currentUser, isSuperAdmin, IS_MANAGMENT_PORTAL);
		if (authState ==='userDataLoaded')  fetchCustomersUsersTeams();
	},[authState]);


	//Master routine for fetching users and teams info for the customer
	//This routine is called whenever the user has been fully signed in -i.e., 'authenticated' and the user's profile loaded from the DB
	async function fetchCustomersUsersTeams() {
		if (!currentUser || !currentUser.customerID) return;
		try {

			//
			//Fetch Users and Teams
			//
			var tempUsers = [],  tempTeams = [], tempCustomers = [];
			
			if (IS_MANAGMENT_PORTAL) {     
				//FETCH CUSTOMER, USERS AND TEAMS FOR ALL CUSTOMERS FOR MANAGEMENT PORTAL
			
				// grab all companies
				tempCustomers =  await queryDataTableWithPagination(listCustomers, "listCustomers", {limit:1000});
				if (DEBUG_MODE >= 2) console.log("Retreived customers for SuperAdmin", tempCustomers);
				tempCustomers.sort(compareCustomersByName);
				setCustomers(tempCustomers);
				  
				// grab all users; use Shallow query
				tempUsers =  await queryDataTableWithPagination(listUsersShallow, "listUsers", {limit:5000});  
				if (DEBUG_MODE >= 2) console.log("Retreived users for ALL Customers (SuperAdmin) - SHALLOW", tempUsers);

				// grab all teams
				tempTeams =  await queryDataTableWithPagination(listTeamsShallow, "listTeams", {limit:5000});  
				if (DEBUG_MODE >= 2) console.log("Retreived teams for ALL Customers (SuperAdmin) - SHALLOW", tempTeams);

				
			} else {
				//FETCH CUSTOMER, USERS AND TEAMS FOR ACCOUNT PORTAL
					
				//grab this user's company
				const tempCustomer =  await getDataRecordById(getCustomer, "getCustomer", currentUser.customerID);
				if (tempCustomer) { 
					tempCustomers[0] = tempCustomer;
					setCustomers([tempCustomer]);
				}
				if (DEBUG_MODE >= 2) console.log("Initialized customer array for Admin", tempCustomers);                    
				
				//grab users for just the same company
				tempUsers =  await queryDataTableWithPagination(getUsersByCustomerShallow, "getUserByCustomer", {customerID: currentUser.customerID, limit:5000});  
				if (DEBUG_MODE >= 2) console.log("Retreived users for this customer (Admin) - SHALLOW", tempUsers);

				
				//grab teams for just the same company
				tempTeams =  await queryDataTableWithPagination(getTeamsByCustomerShallow, "getTeamByCustomerID", {customerID: currentUser.customerID, limit:5000}); 
				if (DEBUG_MODE >= 2) console.log("Retreived teams for this Customer (Admin) - SHALLOW", tempTeams);
				
			}

			//Sort and Save State
			tempUsers.sort(compareUsersByName);
			// setUsersMaster(tempUsers);
			setUsers(tempUsers); //Update state data with newly sorted list              
			if (DEBUG_MODE >= 2) console.log("Fetched Users:", tempUsers);

			// setTeamsMaster(tempTeams);
			tempTeams.sort(compareTeamsByName);
			setTeams(tempTeams);
			if (DEBUG_MODE >= 2) console.log("Retreived teams", tempTeams);
	
	
			// populate dropdown option list data structure for selecting a customer in a dropdown
			  const tempOptions = [];
			  const tempOptionsNoAll = [];
			 
			 //Initialize our ALL options 
			  tempOptions.push({
					id:0,
					label:" - ALL -",
					value:0
				  
			  });
 
			 //Add ALL customers              
			  for(var i = 0; i<tempCustomers.length; i++){

				  tempOptions[i+1] = {
					id:tempCustomers[i].id,
					label:tempCustomers[i].name,
					value:i+1 //PLUS ONE because we already pushed the ALL object
				  };
				  
				//   if (DEBUG_MODE >= 2) console.log('Temp option', i, tempOptions[i]);

				  tempOptionsNoAll[i] = {
					id:tempCustomers[i].id,
					label:tempCustomers[i].name,
					value:i,
				  };

			  }
			  setCustomerOptions(tempOptions);
			  setCustomerOptionsNoAll(tempOptionsNoAll);
			  
			//   if (DEBUG_MODE >= 2) console.log('Built customer dropdown customerOptions', tempOptions);


			//Pre-set Customer Selection to the user's customerID if a valid custiomerID
			if (currentUser.customerID) {
				const indexOfCustomer = tempOptions.findIndex ( option => option.id === currentUser.customerID);
				
				if (indexOfCustomer > -1) {
					setSelectedCustomerOptions(tempOptions[indexOfCustomer]);  //Found a match so set the state variable to this option
					// if (DEBUG_MODE >= 2) console.log("SET MATCHING CUSTOMER FOR THE USER", tempOptions[indexOfCustomer]);         
				} else {
					if (DEBUG_MODE >= 2) console.error("ERROR - NO MATCHING CUSTOMER FOR THE ADMIN USER", currentUser);         
				}      
			} else {
				setSelectedCustomerOptions(null); //Initialize SuperAdmin without a customer to no customer selected
				if (DEBUG_MODE >= 2) console.error("ERROR - NO Customer ID for currentUser", currentUser);         
			}	
		} catch (err) { if (DEBUG_MODE >= 2) console.log('Customer context: error fetching users and teams', err); }

		try {
			
			// populate dropdown option list data structure for selecting a category in a dropdown
		  const categories = await fetchCategories();
		  const tempOptions = [];
		  categories.sort(compareByName);        //Sort before we build our options list so the order matches
		  for(var x = 0; x<categories.length; x++){
			  
			  //Build option list - include Tutorial as an option only if the user has the correct permissions
			  if (categories[x].label != "TUTORIAL" || isSuperAdmin || currentUser.permissionEditor) {
				  tempOptions[x] = {
					id:categories[x].id,
					label:categories[x].name,
					value:x,
					shortLabel:categories[x].label,
				  }; 
			  }
		  }
		  setCategoryOptions(tempOptions);
		  		  
		} catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching categories', err); }   


		//Redirect page content from MAIN page to User HOME ONLY.  Stay on other screens as this is triggered by a Refresh
		/* HANDLED BY REACT ROUTER 6.14.2024
		 if (currentReactPage.pathname =="/") {
			// setRedirectToPage(null);    //Re-initialize the redirection page to null on login so the redirect re-renders on subsequent logouts
			setRedirectToPage("/UserHome"); //Jump to User Home on login
			// if (DEBUG_MODE >= 2) console.log("Redirecting user to home page", currentReactPage.pathname);
		 }
		*/

		/* FINAL LOGIN STATE - INDICATE THE ALL USER AND CUSTOMER CONTEXT DATA HAS BEEN FULLY LOADED */
		//Do this last as an indicator for triggering useEffect reload on pages that use this context
		// if (DEBUG_MODE >= 2) console.log("Customer Context: Setting user to SignedIn");
		setAuthState('signedin');
		if (DEBUG_MODE) console.log("SIGNED IN.  Customer and users loaded - Current date / time: " + moment().format('HH:mm:ss:SSS'));
	
	}
	
	/* NO LONGER USING SELECTED CUSTOMER NOW THAT WE HAVE A MANAGEMEN PORTAL 
	// FOR MANAGEMENT PORTAL ONLY, Filter Users and Teams if there is a change to the selected CUSTOMER by the SuperAdmin or to the underlying master data
	useEffect(() => {

		if (authState !== 'signedin' || !IS_MANAGMENT_PORTAL) return;
		
		// if (DEBUG_MODE >= 2) console.log("REBUILDING OPTIONS BASED ON CHANGE OF CUSTOMER");
 
	   if (selectedCustomerOptions && selectedCustomerOptions.value!== 0 ) {
			const tempTeams = teamsMaster.filter(team => team.customerID === selectedCustomerOptions.id);  
			const tempUsers = usersMaster.filter(user => user.customerID === selectedCustomerOptions.id);
			const tempTeamsOptions = teamsOptionsMaster.filter(team => team.customerID === selectedCustomerOptions.id);
			const tempUsersOptions = usersOptionsMaster.filter(user => user.customerID === selectedCustomerOptions.id);
			const tempParticipatingUsersOptions = participatingUsersOptionsMaster.filter(user => user.customerID === selectedCustomerOptions.id);
			
			setTeams(tempTeams);
			setUsers(tempUsers);
			setTeamsOptions(tempTeamsOptions);
			setUsersOptions(tempUsersOptions);
			setParticipatingUsersOptions(tempParticipatingUsersOptions); 
	 
			 if (DEBUG_MODE >= 2) console.log("Filtered users and teams by Selected Customer", selectedCustomerOptions, tempUsers, tempTeams, tempTeamsOptions, tempUsersOptions, tempParticipatingUsersOptions);
		   
	   } else {
		 //No selection or selection of ALL CUSTOMERS

			setTeams([...teamsMaster]);
			setUsers([...usersMaster]);
			setTeamsOptions([...teamsOptionsMaster]);
			setUsersOptions([...usersOptionsMaster]);
			setParticipatingUsersOptions([...participatingUsersOptionsMaster]); 
			
			// if (DEBUG_MODE >= 2) console.log("Filtered options by NO Customer selected");

	   }
	   
	},[authState, selectedCustomerOptions, usersOptionsMaster]);
	*/
 
	 // Rebuild Users Options if there has been a change to USERS MASTER
	useEffect(() => {
		if (authState !== 'signedin' || !users) return;
		// if (DEBUG_MODE >= 2) console.log('Building user options', users);      
	
		//Build user options for multi-select drop downs
		const tempUsersOptions = [];                  //Options including all users
		const tempParticipatingUsersOptions = [];    //Options that exclude the current user
		var tempOption = {};

		for(var i = 0; i<users.length; i++){
			
			if(users[i].customer == null) {
				if (DEBUG_MODE >= 2) console.log("Error - user has no customer:", users[i]);
			} else {
					
					tempOption = {
					id:users[i].id,
					label:users[i].firstName + (!users[i].middleName ? "" : " " + users[i].middleName.substr(0,1)) + " " + users[i].lastName,
				//   label:users[i].firstName + (!users[i].middleName ? "" : " " + users[i].middleName.substr(0,1)) + " " + users[i].lastName + (isSuperAdmin ? " (" + users[i]?.customer.name +")" : "" ),
					name:users[i].firstName + (!users[i].middleName ? "" : " " + users[i].middleName.substr(0,1)) + " " + users[i].lastName,
					value:i,
					avatarUrl:users[i].avatarUrl,
					avatarInitials:getUserInitials(users[i]),
					isDisabled: false,      //For now, enable all users in dropdown
					customerID: users[i].customerID,
				};
				
				tempUsersOptions[i] = {...tempOption};
				
				if (users[i].id !== currentUser.id) {
					tempParticipatingUsersOptions.push({...tempOption});
				}
			}
		}

		// Set newly generated options
		setUsersOptions(tempUsersOptions);
		setParticipatingUsersOptions(tempParticipatingUsersOptions); 		
		if (DEBUG_MODE >= 2) console.log('Built master user options', tempUsersOptions, tempParticipatingUsersOptions);      
			 
	}, [users, authState]);
	
	// Rebuild Teams Options if there has been a change to TEAMS
	useEffect(() => {

			if (!teams || authState !== 'signedin') return;

			//build list of dropdown options for Teams
			const tempTeamsOptions = [];
			
			for(var l = 0; l<teams.length; l++){
				
				tempTeamsOptions[l] = {
					id:teams[l].id,
					label:teams[l].name,
					name:teams[l].name,
					value:l,
					customerID: teams[l].customerID,
					avatarUrl: teams[l].avatarUrl,
					avatarInitials: teams[l].avatarInitials,
				};
				
				if (tempTeamsOptions[l].avatarInitials == null) tempTeamsOptions[l].avatarInitials = teams[l].name.substring(0,2).toUpperCase();
			  
			  }
			  
			  setTeamsOptions(tempTeamsOptions);
			//   setTeamsOptionsMaster(tempTeamsOptions);
			  
			//   if (DEBUG_MODE >= 2) console.log('Built teams dropdown', teams, tempTeamsOptions);        
	}, [teams, authState]);

 

	//React References
	const customersRef = useRef();
	const usersRef = useRef();  
	const teamsRef = useRef();  
	const currentUserRef = useRef();

	//Update on any state change
	useEffect(() => {
		customersRef.current = customers;
		usersRef.current = users; 
		teamsRef.current = teams; 
		currentUserRef.current = currentUser;
	});   
	

	//
	//	CUSTOMER SUBSCRIPTIONS
	//

	const [customerUpdateSubscriptionSetUp, setCustomerUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [customerDeleteSubscriptionSetUp, setCustomerDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket


	//Subscribe to UPDATES to users for this customer      
	useEffect(() => {
		
		if (!customerUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			//Set up proper subscription based on portal
			let subscriptionParams;
			if (process.env.IS_MANAGMENT_PORTAL && isSuperAdmin) {
				if (DEBUG_MODE >= 2) console.log("Setting up Management Portal subscription for UPDATE CUSTOMER", currentUser.customerID);
				subscriptionParams = { query:onUpdateCustomer };
			} else {
				subscriptionParams = { query:onUpdateCustomer, variables: { customerID: currentUser.customer.id }};
				if (DEBUG_MODE >= 2) console.log("Setting up Management Portal subscription for UPDATE CUSTOMER", currentUser.customerID);
			}
			
			const subscription = API.graphql(subscriptionParams)
			.subscribe ({
				next: messageData => {
					try {
						// if (DEBUG_MODE >= 2) console.log("Subscription message received for UPDATE CUSTOMER",messageData);
						if (messageData.data.onUpdateCustomer) {
							const updatedCustomer = {... messageData.data.onUpdateCustomer };
							if (DEBUG_MODE >= 2) console.log("App Sync => Updated CUSTOMER extracted from message",updatedCustomer);
							if (updatedCustomer && updatedCustomer.id) {
								const tempCustomers = [...customersRef.current];        //Make a copy so as to trigger state updates						
								const indexToEdit = tempCustomers.findIndex(customer => customer.id === updatedCustomer.id);
								if (indexToEdit > -1) {
									if (DEBUG_MODE) console.log('Found customer to edit at index:', indexToEdit);
									//Update system-wide state data with the received object
									//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
									tempCustomers[indexToEdit]=updatedCustomer; //Update array
									tempCustomers.sort(compareCustomersByName);
									setCustomers(tempCustomers); //Update state data with newly sorted list  									
									if (DEBUG_MODE >= 2) console.log("Updated CUSTOMER", currentUserRef.current.customerID, tempCustomers);

									//Was the company for the CurrentUser updated?  If so, update the CurrentUser object
									if (currentUserRef.current.customerID === updatedCustomer.id) {
										var tempCurrentUser = {...currentUserRef.current};    //copy the current user
										tempCurrentUser.customer = {...updatedCustomer}; 		//Copy in the new customer record including config
										if (DEBUG_MODE >= 2) console.log("Updated customer is the customer of the CurrentUser logged in; updated current user object", tempCurrentUser);
										setCurrentUser(tempCurrentUser);
									}

								} else if (process.env.IS_MANAGMENT_PORTAL && isSuperAdmin) {
									if (DEBUG_MODE >= 2) console.log("Customer not found in state data and confirmed we are in management portal; creating new customer in local state data");
									tempCustomers.push(updatedCustomer);        //add new item to client-side data for this page to the existing array.
									tempCustomers.sort(compareCustomersByName);
									setCustomers(tempCustomers); //Update state data with newly sorted list  
								}
							} //End If UpdatedCustomer
						 } //End If Proper Message
					} catch (err) {
						if (DEBUG_MODE >= 1) console.log("Error processing CUSTOMERS subscription message",messageData);
					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setCustomerUpdateSubscriptionSetUp(true);

			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setCustomerUpdateSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE CUSTOMER");
			};
		}
	},[authState]);  


	//Subscribe to DELETE Customer  
	useEffect(() => {
		if (!customerDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser && process.env.IS_MANAGMENT_PORTAL) {
			
			if (DEBUG_MODE >= 2) console.log("Management Portal: Setting up subscription for DELETE CUSTOMER");
			
			const subscription = API.graphql({
				query:onDeleteCustomer,
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE) console.log("Subscription message received for DELETE CUSTOMER",messageData);
						if (messageData.data.onDeleteCustomer !== null) {
							
							const deletedCustomer = {... messageData.data.onDeleteCustomer };
							if (DEBUG_MODE) console.log("Deleted CUSTOMER extracted from message",deletedCustomer);
							 //Remove the matching user from local state and also local userRows.  Note, this technique does not update the state array directly like "splice" would
							var tempCustomers = customersRef.current.filter(c => c.id !== deletedCustomer.id);
							tempCustomers.sort(compareCustomersByName);
							setCustomers(tempCustomers); //Delete state data with newly sorted list  
							if (DEBUG_MODE) console.log("Deleted CUSTOMER", currentUser.customerID, tempCustomers);
						 }
						 
					} catch (err) {
						if (DEBUG_MODE) console.log("Error processing CUSTOMERS subscription message",messageData);
					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setCustomerDeleteSubscriptionSetUp(true);
			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setCustomerDeleteSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE CUSTOMER");
			};
		}
	},[authState]);  	

	//
	// USERS SUBSCRIPTIONS
	// 

	const [userUpdateSubscriptionSetUp, setUserUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [userDeleteSubscriptionSetUp, setUserDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket


	//Subscribe to UPDATES to users for this customer      
	useEffect(() => {
		
		if (!userUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE USER", currentUser.customerID);
			
			const subscription = API.graphql({
				query:onUpdateUser,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						// if (DEBUG_MODE >= 2) console.log("Subscription message received for UPDATE USER",messageData);
						if (messageData.data.onUpdateUser !== null) {
							
							const updatedUser = {... messageData.data.onUpdateUser };
							if (DEBUG_MODE >= 2) console.log("App Sync => Updated USER extracted from message",updatedUser);
							

							// Update system-wide state data with the USERS data for this customer
							const tempUsers = [...usersRef.current];        //Make a copy so as to trigger state updates
														
							//Try to find the one we just edited based on ID
							const indexToEdit = tempUsers.findIndex(user => user.id === updatedUser.id);
  
							//Edit the item in our local data to reflect the fully updated user object
							if (indexToEdit > -1) {
									
								if (DEBUG_MODE >= 3) console.log('Found user to edit at index:', indexToEdit);

								//Update system-wide state data with the received object
								//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
								tempUsers[indexToEdit]=updatedUser; //Update array
								
								 //Sort the new set of objects
								tempUsers.sort(compareUsersByName);
								setUsers(tempUsers); //Update state data with newly sorted list  
								
								if (DEBUG_MODE >= 2) console.log("Updated USER for company", currentUser.customerID, tempUsers);

								//Was the CurrentUser updated?  If so, update system-wide state data for the currentuser
								if (currentUser.id === updatedUser.id) {
									var tempCurrentUser = {...updatedUser};      //copy the updated user record
									tempCurrentUser.customer.configDetails = {...currentUser.customer.configDetails}; //Fetch the config details, which do not flow along with our shallow user record received from App Sync
									if (DEBUG_MODE >= 2) console.log("Updated users is the CurrentUser logged in; updated current user object", tempCurrentUser);
									setCurrentUser(tempCurrentUser);
								}

							 } else {
								// if (DEBUG_MODE >= 2) console.log("User not found in state data; creating new user in local state data");
								tempUsers.push(updatedUser);        //add new item to client-side data for this page to the existing array.

								 //Sort the new set of objects
								tempUsers.sort(compareUsersByName);
								// setUsersMaster(tempUsers); //Update state data with newly sorted list  
								setUsers(tempUsers); //Update state data with newly sorted list  
	
								if (DEBUG_MODE >= 2) console.log("Created USER for company", currentUser.customerID, tempUsers);

							 }
						 }
						 
					} catch (err) {
						if (DEBUG_MODE >= 1) console.log("Error processing USERS subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setUserUpdateSubscriptionSetUp(true);
			// if (DEBUG_MODE >= 2) console.log("Subscription set up for UPDATE USER", currentUser.customerID);

			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setUserUpdateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE USER");
			};
			
		}
	},[authState]);  


	//Subscribe to deletion of a user for this customer; to be pushed in real-time by Appsync     
	useEffect(() => {
		if (!userDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE USER", currentUser.customerID);
			
			const subscription = API.graphql({
				query:onDeleteUser,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Subscription message received for DELETE USER",messageData);
						if (messageData.data.onDeleteUser !== null) {
							
							const deletedUser = {... messageData.data.onDeleteUser };
							if (DEBUG_MODE >= 2) console.log("Deleted USER extracted from message",deletedUser);
							

							// Delete system-wide state data with the USERS data for this customer

							 //Remove the matching user from local state and also local userRows.  Note, this technique does not update the state array directly like "splice" would
							var tempUsers = usersRef.current.filter(element => element.id != deletedUser.id);

							 //Sort the new set of objects
							tempUsers.sort(compareUsersByName);
							setUsers(tempUsers); //Delete state data with newly sorted list  
							// setUsersMaster(tempUsers); //Delete state data with newly sorted list  
							if (DEBUG_MODE >= 2) console.log("Deleted USER for company", currentUser.customerID, tempUsers);
						 }
						 
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing USERS subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setUserDeleteSubscriptionSetUp(true);
			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setUserDeleteSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE USER");
			};

		}
	},[authState]);  

	//
	// TEAMS SUBSCRIPTIONS
	//

	const [teamUpdateSubscriptionSetUp, setTeamUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [teamDeleteSubscriptionSetUp, setTeamDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	//Subscribe to updates to teams for this customer    
	useEffect(() => {
		if (!teamUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE USER", currentUser.customerID);
			
			const subscription = API.graphql({
				query:onUpdateTeam,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Subscription message received for UPDATE TEAM",messageData);
						if (messageData.data.onUpdateTeam !== null) {
							
							const updatedTeam = {... messageData.data.onUpdateTeam };
							if (DEBUG_MODE >= 3) console.log("Updated TEAM extracted from message",updatedTeam);
							

							// Update system-wide state data with the TEAMS data for this customer
							const tempTeams = [...teamsRef.current];        //Make a copy so as to trigger state updates
														
							//Try to find the one we just edited based on ID
							const indexToEdit = tempTeams.findIndex(team => team.id === updatedTeam.id);
  
							//Edit the item in our local data to reflect the fully updated team object
							if (indexToEdit > -1) {
									
								// if (DEBUG_MODE >= 2) console.log('Found team to edit at index:', indexToEdit);


								//Update system-wide state data with the received object
								//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
								tempTeams[indexToEdit]=updatedTeam; //Update array
								 //Sort the new set of objects
								tempTeams.sort(compareByName);
								setTeams(tempTeams); //Update state data with newly sorted list  
								
								// if (DEBUG_MODE >= 2) console.log("Updated TEAM for company", currentUser.customerID, tempTeams);

							 } else {
								// if (DEBUG_MODE >= 2) console.log("Team not found in state data; creating NEW team in local state data");
								tempTeams.push(updatedTeam);        //add new item to client-side data for this page to the existing array.

								//Sort the new set of objects
								tempTeams.sort(compareByName);
								setTeams(tempTeams); //Update state data with newly sorted list  
								
								if (DEBUG_MODE >= 2) console.log("Created TEAM for company", currentUser.customerID, tempTeams);

							 }
						 }
						 
					} catch (err) {
						if (DEBUG_MODE >= 1) console.log("Error processing TEAMS subscription message",err, messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setTeamUpdateSubscriptionSetUp(true);
			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setTeamUpdateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE TEAM");
			};
		}
	},[authState]);                        //Invoke when a change to authState occurs


	//Handled via Team Update Subscription which is triggered after creating a Team
	
	//Subscribe to deletion of a team for this customer; to be pushed in real-time by Appsync   
	useEffect(() => {
		if (!teamDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE TEAM", currentUser.customerID);
			
			const subscription = API.graphql({
				query:onDeleteTeam,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Subscription message received for DELETE TEAM",messageData);
						if (messageData.data.onDeleteTeam !== null) {
							
							const deletedTeam = {... messageData.data.onDeleteTeam };
							if (DEBUG_MODE >= 3) console.log("Deleted TEAM extracted from message",deletedTeam);
							

							// Delete system-wide state data with the TEAMS data for this customer

							 //Remove the matching team from local state and also local teamRows.  Note, this technique does not update the state array directly like "splice" would
							var tempTeams = teamsRef.current.filter(element => element.id != deletedTeam.id);

							 //Sort the new set of objects
							tempTeams.sort(compareByName);
							setTeams(tempTeams); //Delete state data with newly sorted list  

							// if (DEBUG_MODE >= 2) console.log("Deleted TEAM for company", currentUser.customerID, tempTeams);
							

						 }
						 
					} catch (err) {
						if (DEBUG_MODE >= 1) console.log("Error processing TEAMS subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setTeamDeleteSubscriptionSetUp(true);
			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setTeamDeleteSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE TEAM");
			};

		}
	},[authState]);                        //Invoke when a change to authState occurs
	

	//
	// Propogate any change from the Current User to our Users state data for this customer
	
	
	useEffect(() => {
		//Update system-wide "users" state data with any change to this particular user (like wallet or progress data) so the array of users is also correct
		const tempUsers = [...users];        //Make a copy so as to trigger state updates
									
		//Try to find the one we just edited based on ID
		const indexToEdit = tempUsers.findIndex(user => user.id === currentUser.id);

		//Edit the item in our local data to reflect the fully updated user object
		if (indexToEdit > -1) {

			//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
			if (DEBUG_MODE >= 2) console.log('Update to Current User, such as Progress data update: Found user to edit at index:', indexToEdit);
			tempUsers[indexToEdit]= currentUser; //Update array 
			setUsers(tempUsers); //Update state data with newly sorted list  
		}
	},[currentUser]);
		

		//return the provider
	  return (
		  
	<div>
			   
		<CustomerContext.Provider value={
		{   
			customers, users, teams, setCustomers, setUsers, setTeams,
			categoryOptions,teamsOptions, customerOptions, customerOptionsNoAll, usersOptions, participatingUsersOptions,
			selectedCustomerOptions, setSelectedCustomerOptions,
		}}>
		  {children}
		</CustomerContext.Provider>
		
	   {!redirectToPage ? "" : <Navigate to={redirectToPage} />} 
	   
	   {/* <RedirectToHome redirectToHome={redirectToHome} /> */}

	</div>
	
  ); 
	
};


export { CustomerContext, CustomerProvider };
import "./displayCard.css";
import {  Divider } from "../../../../shared/utils/generalUtils";
import React from 'react';

//React component
const CCdisplayCard = ({title, text1, text2, text3, text4, text5, footer}) => {
 
    // if (DEBUG_MODE >= 2) console.log("Card called", title, text1, text2, text3, text4, text5, footer);
    
    return (

        <div className="displayCard">
            <span className="displayCardTitle">{title}</span>
            <Divider percent={50} />
            <div className="displayCardTextContainer"> 
                <span className="displayCardText1">{text1}</span>
                <span className="displayCardText2">{text2}</span>
                <span className="displayCardText3">{text3}</span>
                <span className="displayCardText4">{text4}</span>
                <span className="displayCardText5">{text5}</span>
            </div>
            <span className="displayCardTextFooter">{footer} </span>
        </div>
        
    );

}


export default CCdisplayCard;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, BALANCE_COLOR, CONECTIVITY_PUBLICATION_SELECTION_OPTIONS, CONECTIVITY_INITIAL_STATE, CONECTIVITY_TITLE_MAX_INPUT_LENGTH, CONECTIVITY_DESCRIPTION_MAX_INPUT_LENGTH } from '../../data/conectereConfigData'; 
import { HELP_TEXT_CHALLENGE_EDITOR_1 } from '../../data/helpData.js';

//React & Amplify
import React, { useEffect, useState, useContext, useRef  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { ConectivityContext } from '../../context/conectivitiesContext';
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context
import { ModalContext } from '../../context/modalContext';            

//Bootstrap and other React components
import Select from 'react-select';   //Version with support from multiple selections
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';


//Utils
// import { DisplayCardConectivityPreview } from "../../utils/conectivityUtils";
import { displayConectereCommunityText,  Divider, HelpButton, CopyLinkButton, ViewButton, UndoButton, RedoButton } from "../../utils/generalUtils";
import {  DisplayRecurrenceSelect, DisplayDateTimeSelect, DisplayAllDayEventSelect, DisplayAnytimeEventSelect, NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TOMORROW, 
			TODAY_NOON, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,  } from "../../utils/dateTimeUtils";
import { issuePromptToAiInterface, injectMessagesFromAI, genConectivityFromAiResponse } from "../../utils/aiInterfaceUtils";
import { DisplayChallengeSelect } from  "../../utils/challengeUtils";
import moment from 'moment';


//Our components
import ModalNoBackgroundFixed from "../../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import ModalGeneral from "../../Components/modalGeneral/modalGeneral";
import ConectivityDetailedView from "../conectivityDetailedView/conectivityDetailedView";
import { ChatWindow } from "../chatWindow/chatWindow";

//  TINY editor
// https://www.npmjs.com/package/@tinymce/tinymce-react
// https://www.tiny.cloud/blog/upgrade-to-tinymce-6/ 
import { Editor } from '@tinymce/tinymce-react'; 

const ConectivityEditor = React.memo(({ showModalAdd, showModalEdit, showModalShare, showModalEditPost, conectivityToEdit, handleCloseEditor, }) => {

	// Context
	const { currentUser, isSuperAdmin, isAdmin, permissionEditor, } = useContext(AuthContext);    
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	//Edit options
	const [conectivityToEditLocal, setConectivityToEditLocal] = useState({...CONECTIVITY_INITIAL_STATE}); //Used to hold a conectivity that is to be edited
	const [conectivityToPreview, setConectivityPreview] = useState({...CONECTIVITY_INITIAL_STATE}); //Used to hold a conectivity for previewing without necessarily re-rendering the page
	const [categoryDropDownSelection, setCategoryDropDownSelection] = useState("");  //Note, this stores an object of the select Option.  See react-select
	const [conectivityStatusDropDownSelection, setConectivityStatusDropDownSelection] = useState("");  //Note, this stores an object of the select Option.  See react-select
	const [authorSelectionOptions,setAuthorSelectionOptions] = useState("");
	const [authorSelection,setAuthorSelection] = useState(null);
	const [imageDropDownSelection, setImageDropDownSelection] = useState("");  
	const [customerScopeSelection, setCustomerScopeSelection] = useState(null);
	// const [refreshPreview, setRefreshPreview] = useState(false);
	const [conectivityStatusOptions, setConectivityStatusOptions] = useState (CONECTIVITY_PUBLICATION_SELECTION_OPTIONS);
	const [newSaveAsTitle, setNewSaveAsTitle] = useState("");
	const [showModalSaveAs, setShowModalSaveAs] = useState(false);
	const [forceClosedTrigger1, setForceClosedTrigger1] = useState(false);

	//Conectivity cache - for use with Iggy
	const [conectivityToEditCache, setConectivityToEditCache] = useState([]);
	const [conectivityToEditCachePointer, setConectivityToEditCachePointer]  = useState(-1);

	//Conectivity Previewing
	const [showModalConectivityDetailed, setShowModalConectivityDetailed] = useState(false);      //Detailed Conectivity View

	//Define a state reference for tracking the Editor and getting content when we do a Save
	const editorRef = useRef(null);

	//Calendar launch preferences
	const [eventStartDateTime, setEventStartDateTime] = useState(TODAY_NOON().add({days:1}));               
	const [eventEndDateTime, setEventEndDateTime] = useState(TODAY_NOON().add({days:1, hours:1}));               
	const [launchRepeatOption, setLaunchRepeatOption] = useState(CALENDAR_REPEAT_OPTIONS[0]);      //Start at NONE
	const [launchRepeatWeeklyOptions, setLaunchRepeatWeeklyOptions] = useState([WEEK_REPEAT_OPTIONS[NOW().day()]]);      
	const [launchRepeatMonthlyOptions, setLaunchRepeatMonthlyOptions] = useState(MONTH_REPEAT_OPTIONS[NOW().date()-1]);   //Default to current day of month, with 1st being 0 entry
	const [launchRepeatMonthlyOptions_2, setLaunchRepeatMonthlyOptions_2] = useState(MONTH_REPEAT_OPTIONS_2[0]);
	const [launchRepeatMonthlyOptions_3, setLaunchRepeatMonthlyOptions_3] = useState(MONTH_REPEAT_OPTIONS_3[NOW().day()]);  //Default to current day of week, with Sunday being 0 entry
	const [launchUntilDate, setLaunchUntilDate] = useState(SIX_MONTHS_FROM_NOW());               
	const [launchMonthOption1Checked, setLaunchMonthOption1Checked] = useState(true);
	const [launchMonthOption2Checked, setLaunchMonthOption2Checked] = useState(false);
	// const [multiDayEvent, setMultiDayEvent] = useState(false);

	//AI state
	const [aiInterfaceMessages, setAiInterfaceMessages]=useState([]);
	const [AIinterfaceEnabled, setAIinterfaceEnabled] = useState(false);
	const [promptMessageFromUser, setPromptMessageFromUser] = useState(null);
	const [promptTrigger, setPromptTrigger] = useState(false);
	

	// Customer context
	 const { usersOptions,  categoryOptions, customerOptionsNoAll, users } = useContext(CustomerContext);  

	// Conectivity context
	 const { invokeCreateConectivity, invokeUpdateConectivity } = useContext(ConectivityContext);

 
	 useEffect (() => {
		//Init are state params
		if (conectivityToEdit && currentUser && showModalAdd) initializeAdd();
		else if (conectivityToEdit && currentUser && (showModalEdit || showModalEditPost|| showModalShare)) initializeEdit({conectivityToEdit, showModalShare});

		//Invoke backend to initialize our chat interface
		if (showModalAdd || (showModalEdit && isSuperAdmin)) {
			if (DEBUG_MODE) console.log("Enabling AI chatbot");
			setAIinterfaceEnabled(true);
			setAiInterfaceMessages([]);
			setPromptMessageFromUser('');
			setPromptTrigger(!promptTrigger);
		} else {
			if (DEBUG_MODE) console.log("Disable AI chatbot");
			setAIinterfaceEnabled(false);
		}
	},[showModalAdd, showModalEdit, showModalEditPost, showModalShare]);
	 
	function initializeAdd() {
		if (DEBUG_MODE >= 2) console.log("Editor Add: opening new conectivity", conectivityToEdit);
		var tempConectivityToEdit = (conectivityToEdit ? {...conectivityToEdit} : {...CONECTIVITY_INITIAL_STATE});   //Copy the selected template

		//Set conectivityScope and status based on credentials
		//Any user that does not have Conectere Editing permission is treated as a customer adding their own Conectivity
		if (!permissionEditor) {
			tempConectivityToEdit.conectivityScope="CUSTOMER_SPECIFIC";   //Mark as scope customer-specific
			tempConectivityToEdit.publicationStatus="PRIVATE";            //Mark as publication status private
			setConectivityStatusDropDownSelection(conectivityStatusOptions[4]);                //Set the state variable to PRIVATE

		} else {
			tempConectivityToEdit.conectivityScope="GLOBAL";              //Initial draft of a Global Conectere Conectivity 
			tempConectivityToEdit.publicationStatus="DRAFT";            
			setConectivityStatusDropDownSelection(conectivityStatusOptions[0]);                //Set the state variable to DRAFT
		}


		//Init the launch calendar preferences
		setEventStartDateTime(TODAY_NOON().add({days:1}));               
		setEventEndDateTime(TODAY_NOON().add({days:1, hours:1}).add(1,"hour"));               
		setLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[0]);      //Start at NONE
		setLaunchRepeatWeeklyOptions([WEEK_REPEAT_OPTIONS[NOW().day()]]);      
		setLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[NOW().date()-1]);   //Default to current day of month, with 1st being 0 entry
		setLaunchRepeatMonthlyOptions_2(MONTH_REPEAT_OPTIONS_2[0]);
		setLaunchRepeatMonthlyOptions_3(MONTH_REPEAT_OPTIONS_3[NOW().day()]);  //Default to current day of week, with Sunday being 0 entry
		setLaunchUntilDate(SIX_MONTHS_FROM_NOW());               
		setLaunchMonthOption1Checked(true);
		setLaunchMonthOption2Checked(false);
		// setMultiDayEvent(false);

		//Confirm booleans for switches in the event the initial conectivity or template does not include any of the fields
		if (!tempConectivityToEdit.inviteAllEmployees) tempConectivityToEdit.inviteAllEmployees = false;
		if (!tempConectivityToEdit.allDayEvent) tempConectivityToEdit.allDayEvent = false;
		if (!tempConectivityToEdit.hasNoSpecificTime) tempConectivityToEdit.hasNoSpecificTime = true;
		if (!tempConectivityToEdit.isTutorial) tempConectivityToEdit.isTutorial = false;
		if (!tempConectivityToEdit.isTemplate) tempConectivityToEdit.isTemplate = false;
		if (!tempConectivityToEdit.isSpecialEvent) tempConectivityToEdit.isSpecialEvent = false;
		if (!tempConectivityToEdit.isManuallyScheduledOnly) tempConectivityToEdit.isManuallyScheduledOnly = false;
		if (!tempConectivityToEdit.isSchedulableByAdminOnly) tempConectivityToEdit.isSchedulableByAdminOnly = false;
		if (!tempConectivityToEdit.isTemplate) tempConectivityToEdit.isTemplate = false;        //Updating new isTemplate field
		if (!tempConectivityToEdit.allDayEvent) tempConectivityToEdit.allDayEvent = false;   
		if (tempConectivityToEdit.hasNoSpecificTime === false) tempConectivityToEdit.hasNoSpecificTime = false; else tempConectivityToEdit.hasNoSpecificTime = true;
		if (!tempConectivityToEdit.inviteAllEmployees) tempConectivityToEdit.inviteAllEmployees = false;     
		if (!tempConectivityToEdit.isChallenge || !tempConectivityToEdit.challengeOptions) {		  
			tempConectivityToEdit.isChallenge = false;
			tempConectivityToEdit.challengeOptions = { isAnonymous:false, inputEval:'CUMULATIVE', inputDataType:'INTEGER'};
		} else {
			tempConectivityToEdit.challengeOptions = JSON.parse(tempConectivityToEdit.challengeOptions);	//Replace the JSON string with an object
		}
		setConectivityToEditLocal({...tempConectivityToEdit}); //Set our local conectivity for our editor

		//If Admin and not SuperAdmin, then disable ability to PUBLISH 
		//Note, customer-specific Conectivities will not go through DRAFT / REVIEW / PUBLISH process
		var tempConectivityStatusOptions = [...conectivityStatusOptions];
		if (isAdmin && !isSuperAdmin)  tempConectivityStatusOptions[2].isdisabled = true;  //disable PUBLISHED option
		else tempConectivityStatusOptions[2].isdisabled = false;                           //enable PUBLISHED option
		setConectivityStatusOptions(tempConectivityStatusOptions);
		if (DEBUG_MODE >= 2) console.log("Add Conectivity.  Pub Option set to:", tempConectivityStatusOptions);
		
		//Preset the company options to the company of this user
		setCustomerScopeSelection(customerOptionsNoAll.find (customer => customer.id === currentUser.customerID));

		//Filter the author options based on this user's company     
		const tempAuthorOptions = usersOptions.filter(user => user.customerID === currentUser.customerID);
		setAuthorSelectionOptions([...tempAuthorOptions]); 
		if (DEBUG_MODE >= 2) console.log("Set initial author selection options", tempAuthorOptions);

		//Pre-select the author option in the drop-down to this user doing the add
		const tempAuthor = tempAuthorOptions.find(userOption => userOption.id === currentUser.id);
		if (DEBUG_MODE >= 2) console.log("Set initial author", tempAuthor);
		setAuthorSelection({...tempAuthor});

		//Set the category dropdown selection to the matching option in the category options; may be a template
		setCategoryDropDownSelection(categoryOptions.find(category => category.id === tempConectivityToEdit.categoryID));  //Grab the entire object for use as initial value for React-Select
		
		//Init our cache to the intial state of the conectivity being added
		const tempCache = [];
		tempCache.push(tempConectivityToEdit);
		setConectivityToEditCache(tempCache);
		let newCacheIndex = 0; //Point to first entry in the cache
		setConectivityToEditCachePointer(newCacheIndex);		
		if (DEBUG_MODE) console.log("Add: Updated editor's conectivity", tempConectivityToEdit);
		if (DEBUG_MODE) console.log("Add: New conectivity cache:", tempCache);
		if (DEBUG_MODE) console.log("Add: Conectivity cache pointer", newCacheIndex);
		
		//reset state data
		setShowModalGeneral(false);   

	 }
	 
function initializeEdit({conectivityToEdit, showModalShare}) {
	
	var tempConectivityToEdit = {...conectivityToEdit};
	
	//Override Scope and Pubication Status if the user is sharing this conectivty by making a public copy
	if (showModalShare) {
		tempConectivityToEdit.publicationStatus = "DRAFT";
		tempConectivityToEdit.conectivityScope = "CUSTOMER_SHARED";
		tempConectivityToEdit.userLikes = [];
		tempConectivityToEdit.subscribingCustomers = [];
		tempConectivityToEdit.numDownloads = 0;
		if (DEBUG_MODE >= 2) console.log("Setting SHARED scope", tempConectivityToEdit);
	}        

	//Update temp boolean flag carried by the object based on whether the conectivity is set to inactive
	if (tempConectivityToEdit.inactiveForCustomers)   tempConectivityToEdit.inactive = tempConectivityToEdit.inactiveForCustomers.some(entry => entry === currentUser.customerID); //set flag
	else tempConectivityToEdit.inactive = false; //false if list does not exist at all
	
	//Set flags for switches based on whether there is a badge count
	tempConectivityToEdit.deiRelated = (tempConectivityToEdit.badgesDEI >0);
	tempConectivityToEdit.csRelated = (tempConectivityToEdit.badgesCS > 0);
	
	//Confirm booleans for switches in the event the conectivity does not include any of the fields
	if (!tempConectivityToEdit.isTutorial) tempConectivityToEdit.isTutorial = false;
	if (!tempConectivityToEdit.isTemplate) tempConectivityToEdit.isTemplate = false;
	if (!tempConectivityToEdit.isSpecialEvent) tempConectivityToEdit.isSpecialEvent = false;
	if (!tempConectivityToEdit.isManuallyScheduledOnly) tempConectivityToEdit.isManuallyScheduledOnly = false;
	if (!tempConectivityToEdit.isSchedulableByAdminOnly) tempConectivityToEdit.isSchedulableByAdminOnly = false;
	if (!tempConectivityToEdit.isTemplate) tempConectivityToEdit.isTemplate = false;        //Updating new isTemplate field
	if (!tempConectivityToEdit.allDayEvent) tempConectivityToEdit.allDayEvent = false;   
	if (tempConectivityToEdit.hasNoSpecificTime === false) tempConectivityToEdit.hasNoSpecificTime = false; else tempConectivityToEdit.hasNoSpecificTime = true;
	if (!tempConectivityToEdit.inviteAllEmployees) tempConectivityToEdit.inviteAllEmployees = false;     
	if (!tempConectivityToEdit.isChallenge || !tempConectivityToEdit.challengeOptions) {		  
		tempConectivityToEdit.isChallenge = false;
		tempConectivityToEdit.challengeOptions = { isAnonymous:false, inputEval:'CUMULATIVE', inputDataType:'INTEGER'};
	} else {
		tempConectivityToEdit.challengeOptions = JSON.parse(tempConectivityToEdit.challengeOptions);	//Replace the JSON string with an object
	}

	setConectivityToEditLocal({...tempConectivityToEdit}); //Set our local conectivity for our editor

	//Set Publication Dropdown Option
	const indexOfPubStatus = conectivityStatusOptions.findIndex ( option => option.label === tempConectivityToEdit.publicationStatus);
	
	if (indexOfPubStatus > -1) {
		setConectivityStatusDropDownSelection(conectivityStatusOptions[indexOfPubStatus]);  //Found a match so set the state variable to this option
	} else {
		setConectivityStatusDropDownSelection(conectivityStatusOptions[0]);                //No match so set the state variable to DRAFT
		if (DEBUG_MODE >= 2) console.log("Error - No matching publication status found", tempConectivityToEdit, conectivityStatusOptions);
	}

	//If Admin and not SuperAdmin, then disable ability to PUBLISH 
	//Note, customer-specific Conectivities will not go through DRAFT / REVIEW / PUBLISH process

	var tempConectivityStatusOptions = [...conectivityStatusOptions];
	if (isAdmin && !isSuperAdmin)  tempConectivityStatusOptions[2].isdisabled = true;  //disable PUBLISHED option
	else tempConectivityStatusOptions[2].isdisabled = false;                           //enable PUBLISHED option
	setConectivityStatusOptions(tempConectivityStatusOptions);

	//Set the category dropdown selection to the matching option in the category options
	setCategoryDropDownSelection(categoryOptions.find(category => category.id === tempConectivityToEdit.categoryID));  //Grab the entire object for use as initial value for React-Select


	//Find the author
	const tempAuthoredByUser = users.find(user => user.id === tempConectivityToEdit.authorID);
	// if (DEBUG_MODE >= 2) console.log("Author that created the conectivity being edit", tempAuthoredByUser, users);
	
	//Filter the author options based on the company that created the conectivity      
	const tempAuthorOptions = usersOptions.filter(user => user.customerID === tempAuthoredByUser.customerID);
	setAuthorSelectionOptions([...tempAuthorOptions]); //Filter potential authors based on any local change to customer scope; for assigning a conectivity
//   if (DEBUG_MODE >= 2) console.log("Set initial author selection options", tempAuthorOptions);

	//Pre-select the author option in the drop-down, provided he/she is in the available options
	const tempAuthor = tempAuthorOptions.find(userOption => userOption.id === tempAuthoredByUser.id);
	// if (DEBUG_MODE >= 2) console.log("Set initial author", tempAuthor);
	setAuthorSelection({...tempAuthor});

	//Preset the company options to the company that created the conectivity
	setCustomerScopeSelection(customerOptionsNoAll.find (customer => customer.id === tempAuthoredByUser.customerID));

//Initialize launch selection items
setEventStartDateTime(conectivityToEdit.eventStartDateTime ? moment(conectivityToEdit.eventStartDateTime) : TODAY_NOON().add({days:1, hours:1}));               //Used to hold the DatePicker Date value for when a conectivity is to be launched
setEventEndDateTime(conectivityToEdit.eventEndDateTime ? moment(conectivityToEdit.eventEndDateTime) : TODAY_NOON().add({days:1, hours:1}).add(1,"hour"));               //Used to hold the DatePicker Date value for when a conectivity is to be launched
setLaunchRepeatOption(conectivityToEdit.launchRepeatOption ? CALENDAR_REPEAT_OPTIONS[conectivityToEdit.launchRepeatOption-1] : CALENDAR_REPEAT_OPTIONS[0]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[NOW().date()-1]);   //Default to current day of month, with 1st being 0 entry

setLaunchRepeatMonthlyOptions(conectivityToEdit.launchRepeatMonthlyOptions ? MONTH_REPEAT_OPTIONS[conectivityToEdit.launchRepeatMonthlyOptions-1] : MONTH_REPEAT_OPTIONS[NOW().date()-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setLaunchRepeatMonthlyOptions_2(conectivityToEdit.launchRepeatMonthlyOptions_2 ? MONTH_REPEAT_OPTIONS_2[conectivityToEdit.launchRepeatMonthlyOptions_2-1] : MONTH_REPEAT_OPTIONS_2[0]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setLaunchRepeatMonthlyOptions_3(conectivityToEdit.launchRepeatMonthlyOptions_3 ? MONTH_REPEAT_OPTIONS_3[conectivityToEdit.launchRepeatMonthlyOptions_3-1] : MONTH_REPEAT_OPTIONS_3[NOW().day()]);      //Used to hold any selected Repeat value for a conectivity is to be launched
setLaunchUntilDate(conectivityToEdit.launchRepeatOption ? moment(conectivityToEdit.launchUntilDate,"YYYY MM DDTHH mm ssZ") : SIX_MONTHS_FROM_NOW());               //Used to hold the DatePicker Date value for when a conectivity is to be launched
setLaunchMonthOption1Checked(conectivityToEdit.launchMonthOption1Checked ? true: false);
setLaunchMonthOption2Checked(conectivityToEdit.launchMonthOption2Checked ? true: false);
// setMultiDayEvent(conectivityToEdit.launchRepeatOption && conectivityToEdit.launchRepeatOption > 1 ? true : false);

//Set days of week for weekly options, if they exist
var tempWeeklyOptions = [];
if (conectivityToEdit.launchRepeatWeeklyOptions && conectivityToEdit.launchRepeatWeeklyOptions.length >0) {
	for (var j = 0; j < conectivityToEdit.launchRepeatWeeklyOptions.length; j++){  
		tempWeeklyOptions.push(WEEK_REPEAT_OPTIONS[conectivityToEdit.launchRepeatWeeklyOptions[j]-1]);  //Add day option to inital selections 
	}
}
console.log ("Created day of week selections:", tempWeeklyOptions);
setLaunchRepeatWeeklyOptions(tempWeeklyOptions);    

//Init our cache to the intial state of the conectivity being edited
const tempCache = [];
tempCache.push(tempConectivityToEdit);
setConectivityToEditCache(tempCache);
let newCacheIndex = (tempCache && tempCache.length > 0 ? 0 : -1);
setConectivityToEditCachePointer(newCacheIndex);		
if (DEBUG_MODE) console.log("Updated editor's conectivity", tempConectivityToEdit);
if (DEBUG_MODE) console.log("New conectivity cache:", tempCache);
if (DEBUG_MODE) console.log("Conectivity cache pointer", newCacheIndex);

//reset state data
setShowModalGeneral(false);   
setNewSaveAsTitle("");  //Clear since doing an Edit and won't want this affecting the handleSubmit (Save)
}    


	 //Effect to sync the Preview conectivity to the Local one
	 useEffect (() => {
		  setConectivityPreview({...conectivityToEditLocal});
	 },[conectivityToEditLocal]);
 


	  const handleImageAddSelection = (eventKey) => {
		  if (DEBUG_MODE >= 2) console.log("Image selected", eventKey);
		  setImageDropDownSelection(eventKey);
	  };
	  
	  
	 //define function to handle submitButton, which is used to create a new conectivity 
	 async function handleSubmit (e)  {
		  e.preventDefault();     //prevent React page refresh
		  let successFlag, message;

		  if (showModalShare) {
				if (DEBUG_MODE >= 2) console.log("Sharing conectivity with community", conectivityToEditLocal);
		  } else {
				if (DEBUG_MODE >= 2) console.log('Called handler to create new conectivity', conectivityToEditLocal);
		  }

		  //Double check required fields for conectivities
		  if (!conectivityToEditLocal || !conectivityToEditLocal.title || !conectivityToEditLocal.description ) {
				if (DEBUG_MODE >= 2) console.log('error creating conectivity, incomplete fields'); 
				setModalGeneralMessage("Please enter a title and a description.");
				setModalGeneralTitle("Ooops!");
				setShowModalGeneral(true);   
				return;
		  }

		  if (conectivityToEditLocal.conectCoins < 1 || conectivityToEditLocal.signupDays <1 || conectivityToEditLocal.conectCoins>9999|| conectivityToEditLocal.signupDays >99) {
				setModalGeneralMessage("Please specify coins");
				setModalGeneralTitle("Ooops!");
				setShowModalGeneral(true);   
				if (DEBUG_MODE >= 2) console.log('error creating conectivity, coins:', conectivityToEditLocal.conectCoins, ' Expiration: ', conectivityToEditLocal.signupDays); 
				return;
		  }

		  try { 
				//Get content of TINY Editor using React Reference; note, this will be empty for a Save As, so we have already cached them
				var tempInstructions = "";
				if (editorRef.current) {
					tempInstructions = editorRef.current.getContent();
				//   if (DEBUG_MODE >= 2) console.log("Retrieved temp instructions for Conectivity ADD", tempInstructions);
				 } else {
					  if (DEBUG_MODE >= 2) console.log("No instructions from Editor Ref");
				 }
	 
				setShowSpinner(true); //Show spinners
				
				successFlag = await invokeCreateConectivity ({
					conectivityToEdit:conectivityToEditLocal, newSaveAsTitle:newSaveAsTitle, 
					instructions:tempInstructions, categoryDropDownSelection, conectivityStatusDropDownSelection, imageDropDownSelection, 
					authorSelection, customerScopeSelection, isSharing:showModalShare,
					eventStartDateTime, eventEndDateTime, 
					launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, 
					launchUntilDate,   
					launchMonthOption1Checked, launchMonthOption2Checked,                                 
				});
			  
				if (successFlag) {
					 if (showModalShare)  message = "Thank you for sharing your Conectivity with the Conectere community. The Conectivity will be reviewed and posted shortly.";
					 else message = "Conectivity created";
				} else {
					 message = "Something went wrong.";

				}
		 } catch (err) {
					  message = "Something went wrong. " + err;
					 if (DEBUG_MODE >= 2) console.log("Error creating conectivity", err);
		  }
		  
		  // close the editor
		  setShowSpinner(false); //hide spinners
		  handleCloseEditor({
				result:successFlag,
				message: message,
		  });
	 }
	 
	 //Edit Functions 
	 async function handleConfirmationEdit () {
		  
		  setShowSpinner(true); //Show spinners
		  let successFlag, message;

		  if (DEBUG_MODE >= 2) console.log("Updating conectivity", conectivityToEditLocal);    

		  try {
				//Get content of TINY Editor using React Reference
				var tempInstructions = "";
				if (editorRef.current) {
					tempInstructions = editorRef.current.getContent();
				//   if (DEBUG_MODE >= 2) console.log("Retrieved temp instructions for Conectivity Edit", tempInstructions);
				 } else {
					  if (DEBUG_MODE >= 2) console.log("No instructions from Editor Ref");
				 }
	 
	 
				successFlag = await invokeUpdateConectivity ({
					conectivityToEdit:conectivityToEditLocal, 
					instructions:tempInstructions, conectivityStatusDropDownSelection, 
					categoryDropDownSelection, conectivityStatusDropDownSelection, imageDropDownSelection, 
					authorSelection, customerScopeSelection,
					eventStartDateTime, eventEndDateTime, 
					launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, 
					launchUntilDate,
					launchMonthOption1Checked, launchMonthOption2Checked                                                   
				});
 
				if (successFlag) {
					 message = "Conectivity updated";
				} else {
					 message  = "Something went wrong.";
				}

		  } catch (err) {
					 message = "Something went wrong. " + err;
					 if (DEBUG_MODE >= 2) console.log("Error creating conectivity", err);
		  }

			// close the editor
		  handleCloseEditor({
				result:successFlag,
				message: message,
		  });
		  
		  setShowSpinner(false); //hide spinners
		  // setShowModalGeneral(true);
		 
	 }   

/*    
	 const handleRefresh = () => {
		  setRefreshPreview(!refreshPreview);

		  //Update the instructions in the state data from the Editor.  This will force a Render of the Preview
		  var tempInstructions = "";
		 
		  if (editorRef.current) {
			  tempInstructions = editorRef.current.getContent();
				
				setConectivityToEditLocal({ ...conectivityToEditLocal, instructions: tempInstructions });

				// if (showModalEdit) {
				//     setConectivityToEdit({ ...conectivityToEdit, instructions: tempInstructions })
				// } else if (showModalAdd) {
				//     setConectivityDetails({ ...conectivityDetails, instructions: tempInstructions })
				// }
			}
		  
	 };
*/    

	 //Returning simply the scope of the Conectivity
	  function displayConectivityScope() {
		  
		  if (conectivityToEditLocal.conectivityScope === "CUSTOMER_SPECIFIC" || conectivityStatusDropDownSelection.label === "PRIVATE")  return 'Company Specific';
		  if (conectivityToEditLocal.conectivityScope === "CUSTOMER_SHARED" && conectivityStatusDropDownSelection.label === "DRAFT")  return 'Sharing'; 
		  if (conectivityToEditLocal.conectivityScope === "CUSTOMER_SHARED" && conectivityStatusDropDownSelection.label === "REVIEW")  return 'Sharing'; 
		  if (conectivityToEditLocal.conectivityScope === "CUSTOMER_SHARED" && conectivityStatusDropDownSelection.label === "PUBLISHED") return 'Shared To Community';              
		  if (conectivityToEditLocal.isTemplate) return ' Template'; 
			
		  //Default       
		  return 'Global Portfolio';
	 }  

	const handleStatusSelection = (eventKey) => {
		if (DEBUG_MODE >= 2) console.log("Pub status selected.  Updating Scope", eventKey);
		//Update the Scope for certain selections
		if (eventKey && eventKey.label) {
			switch (eventKey.label) {
				case "PRIVATE":
					setConectivityToEditLocal({...conectivityToEditLocal, conectivityScope: "CUSTOMER_SPECIFIC"})
					break;
				case "DRAFT":
				case "REVIEW":
				case "PUBLISHED":
					setConectivityToEditLocal({...conectivityToEditLocal, conectivityScope: "GLOBAL"})
					break;									
			} //End Switch
		}//End IF
		setConectivityStatusDropDownSelection(eventKey);
	}

	  const handleCompanyScopeSelection = (eventKey) => {
		  if (DEBUG_MODE >= 2) console.log("Assigned to a Company", eventKey);
		  setCustomerScopeSelection(eventKey);

		 //Filter the author options based on the selected customer        
		 const tempAuthorOptions = usersOptions.filter(user => user.customerID === eventKey.id);
		 setAuthorSelectionOptions([...tempAuthorOptions]); //Filter potential authors based on any local change to customer scope; for assigning a conectivity

		//Pre-select the author, provided he/she is in the available options
		const tempAuthor = tempAuthorOptions.find(userOption => userOption.id === conectivityToEditLocal.authorID);
		if (tempAuthor) setAuthorSelection(tempAuthor); else setAuthorSelection(null);
		

	  };
	  
	  const handleAuthorSelection = (eventKey) => {
		  if (DEBUG_MODE >= 2) console.log("Author selected", eventKey);
		  setAuthorSelection(eventKey);
	  };     
	  
	 const handleAPT = (value) => {
		  if (DEBUG_MODE >= 2) console.log("Handle APT called", value, categoryDropDownSelection.shortLabel);
		  var tempCoins = 0;
		  
		  switch (categoryDropDownSelection.shortLabel) {
				case "STRESS":
					 if (value >= CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_BALANCE && value <= CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_BALANCE) tempCoins =  value * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_BALANCE;
					 break;
				case "TEAM":
					 if (value >= CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TEAM && value <= CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_TEAM) tempCoins =  value * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_TEAM;
					 break;
				case "SOCIAL":
					 if (value >= CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_SOCIAL && value <= CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_SOCIAL) tempCoins =  value * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_SOCIAL;
					 break;
				case "PERSONAL":
					 if (value >= CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_PERSONAL && value <= CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_PERSONAL) tempCoins =  value * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_PERSONAL;
					 break;
				case "TUTORIAL":
					 if (value >= CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TUTORIAL && value <= CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_TUTORIAL) tempCoins =  value * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_TUTORIAL;
					 break;
				default:
					tempCoins = value * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_BALANCE;
				} 

		  setConectivityToEditLocal({ ...conectivityToEditLocal, approvedPaidTime: value, conectCoins: tempCoins});           

	 };
	  
	 const displayPaidTimeRange = () => {
		  if (!categoryDropDownSelection) return " --- ";
		  if (categoryDropDownSelection.shortLabel === "STRESS") return (CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_BALANCE + " - "+ CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_BALANCE + " (mins) ");
		  if (categoryDropDownSelection.shortLabel === "TEAM") return (CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TEAM + " - "+ CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_TEAM + " (mins) ");
		  if (categoryDropDownSelection.shortLabel === "SOCIAL") return (CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_SOCIAL + " - "+ CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_SOCIAL + " (mins) ");
		  if (categoryDropDownSelection.shortLabel === "PERSONAL") return (CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_PERSONAL + " - "+ CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_PERSONAL + " (mins) ");
		  if (categoryDropDownSelection.shortLabel === "TUTORIAL") return (CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TUTORIAL + " - "+ CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_TUTORIAL + " (mins) ");
	 
		  return " --- ";
	 };


	  const handleSpecialEventToggle =  () => {
		  //Reset APT, expiration (signup) and coins to minimum required for the selected category
		  if (conectivityToEditLocal.category.label === "STRESS")  setConectivityToEditLocal({ ...conectivityToEditLocal, isSpecialEvent: !conectivityToEditLocal.isSpecialEvent, signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_BALANCE, });
		  else if (conectivityToEditLocal.category.label === "TEAM")  setConectivityToEditLocal({ ...conectivityToEditLocal, isSpecialEvent: !conectivityToEditLocal.isSpecialEvent, signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_TEAM,  });
		  else if (conectivityToEditLocal.category.label === "SOCIAL")  setConectivityToEditLocal({ ...conectivityToEditLocal, isSpecialEvent: !conectivityToEditLocal.isSpecialEvent, signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_SOCIAL, });
		  else if (conectivityToEditLocal.category.label === "PERSONAL")  setConectivityToEditLocal({ ...conectivityToEditLocal, isSpecialEvent: !conectivityToEditLocal.isSpecialEvent, signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_PERSONAL, }); 
		  else if (conectivityToEditLocal.category.label === "TUTORIAL")  setConectivityToEditLocal({ ...conectivityToEditLocal, isSpecialEvent: !conectivityToEditLocal.isSpecialEvent, signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_TUTORIAL, });
	  }; 
	  
	  const handleCategorySelect = (eventKey) => {
			
		  if (DEBUG_MODE >= 2) console.log("Category selected", eventKey);
		  
		  setCategoryDropDownSelection(eventKey);

		  //Reset APT, expiration (signup) and coins to minimum required for the selected category
		  if (eventKey.shortLabel === "STRESS") {
				setConectivityToEditLocal({ ...conectivityToEditLocal, 
				category: {label: eventKey.shortLabel},
				image:process.env.PUBLIC_URL+'/img/balanceMaster.png',
				signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_BALANCE, approvedPaidTime: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_BALANCE, conectCoins: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_BALANCE * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_BALANCE});


		  } else if (eventKey.shortLabel === "TEAM") {
				setConectivityToEditLocal({ ...conectivityToEditLocal, 
					 image: process.env.PUBLIC_URL+'/img/teamMaster.png',
					 category: {label: eventKey.shortLabel},
					 signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_TEAM, approvedPaidTime: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TEAM, conectCoins: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TEAM * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_TEAM});


		  } else if (eventKey.shortLabel === "SOCIAL") {
				setConectivityToEditLocal({ ...conectivityToEditLocal, 
				category: {label: eventKey.shortLabel},
				image:process.env.PUBLIC_URL+'/img/socialMaster.png',
				signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_SOCIAL, approvedPaidTime: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_SOCIAL, conectCoins: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_SOCIAL *CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_SOCIAL});

		  } else if (eventKey.shortLabel === "PERSONAL") {
				setConectivityToEditLocal({ ...conectivityToEditLocal, 
				category: {label: eventKey.shortLabel},
				image:process.env.PUBLIC_URL+'/img/personalMaster.png',
				signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_PERSONAL, approvedPaidTime: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_PERSONAL, conectCoins: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_PERSONAL * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_PERSONAL});


		  } else if (eventKey.shortLabel === "TUTORIAL") {
				setConectivityToEditLocal({ ...conectivityToEditLocal, 
				category: {label: eventKey.shortLabel},
				image:CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH,
				signupDays: CONECTERE_CONFIG_DATA.CONECTIVITY_DURATION_TUTORIAL, 
				approvedPaidTime: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TUTORIAL, 
				conectCoins: CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TUTORIAL * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_TUTORIAL});
		  } 
	  };    
	  
	 //Set the state of the SAVE button
	 function disableSaveButton () {
		if (DEBUG_MODE > 2) console.log("Testing SAVE buttons", conectivityToEditLocal)
		  //Page still loading?
		  if (!currentUser) return true;
		  
		  //Unassigned author?
		  if (!customerScopeSelection || !authorSelection) {
				// if (DEBUG_MODE >= 2) console.log("Disabling Save - no customer or author", customerScopeSelection, authorSelection);
				return true;
		  }
		  
		  //Required fields?
		  if (!conectivityToEditLocal.title || !conectivityToEditLocal.description || 
				(conectivityToEditLocal.isChallenge && (!conectivityToEditLocal.challengeOptions || !conectivityToEditLocal.challengeOptions.inputLabel)) ||
				(conectivityToEditLocal.isChallenge && conectivityToEditLocal.challengeOptions && conectivityToEditLocal.challengeOptions.useGoal && !conectivityToEditLocal.challengeOptions.goal)
			) {
				// if (DEBUG_MODE >= 2) console.log("Disabling Save - no title or description", conectivityToEditLocal.title, conectivityToEditLocal.description);
				return true;
		  }

		  if (!(conectivityToEditLocal.signupDays>0)  
						|| categoryDropDownSelection==""
						|| !(conectivityToEditLocal.conectCoins > 0)  || !(conectivityToEditLocal.approvedPaidTime>0))  {
							 
				
				if (DEBUG_MODE >= 2) console.log("Fields not complete for save", conectivityToEditLocal, categoryDropDownSelection);              
				return true;
		  }   

		  //Now, if ADD mode, then let the user SAVE otherwise check if they can overwrite this Conectivity
		  if (showModalAdd) return false;
		  
		  //If SuperAdmin always enable the button for EDIT
		  if (isSuperAdmin) return false;
		  
		  //Otherwise, is the user EDITING an existing Conectivity?  If so, check whether the user has access priviledges to do a SAVE for this Conectivity
		  //Disable if user is not SuperAdmin and did not Author the conectivity 
		  //NOTE - as such, only SuperAdmin can UNPUBLISH a Conectivity (3.17.2022 - disabled this check; allowed user to edit a PUBLISHED one but transitions back to REVIEW)
		  if (conectivityToEditLocal.authorID !== currentUser.id) return true;
		  
		  return false;
	 }

	 //Set the state of the SAVE AS button
	 function disableSaveAsButton () {
		  //Page still loading?
		  if (!currentUser) return true;
 
		  //Unassigned author?
		  if (!customerScopeSelection || !authorSelection) return true;

		  //Required fields?
		  if (!conectivityToEditLocal.title || !conectivityToEditLocal.description) return true;

		  if (!(conectivityToEditLocal.signupDays>0)  
						|| categoryDropDownSelection==""
						|| !(conectivityToEditLocal.conectCoins > 0)  || !(conectivityToEditLocal.approvedPaidTime>0))  {
							 
				
				if (DEBUG_MODE >= 2) console.log("Fields not complete for save", conectivityToEditLocal, categoryDropDownSelection);              
				return true;
			 }   
		 
			return false;
  
	 }
	 
	 //Function for rendering buttons on Conectivity Editor Modal
	 function displayEditModalButtons () {
	
		  if (showModalAdd) {
				
				return (
					 <div className="ContainerNoHeightCenter"> 
							 <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Save"  onClick={handleSubmit} disabled={disableSaveButton()}> Save </button>
							 <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="Cancel" onClick={() => handleCloseEditor({result:"CANCELED"})} >  Cancel  </button>  
					 </div>
				);
				
		  } else if (showModalEdit && conectivityToEditLocal.conectivityScope === "CUSTOMER_SHARED") {        //SuperAdmin EDITING a conectivity already shared to the community, perhaps under review
				return (
					 <div className="ContainerNoHeightCenter"> 
							 <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationEdit} disabled={disableSaveButton()}> Save </button>
							 <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={() => handleCloseEditor({result:"CANCELED"})} >  Cancel  </button>  
					 </div>
				);            
		  } else if (showModalEdit) {
				return (
					 <div className="ContainerNoHeightCenter"> 
							 <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationEdit} disabled={disableSaveButton()}> Save </button>
							 <button className="buttonStyle1 buttonStyle1Green" aria-label="done" onClick={handleSaveAs} disabled={disableSaveAsButton() }> Save AS </button>
							 <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={() => handleCloseEditor({result:"CANCELED"})} >  Cancel  </button>  
					 </div>
				); 
		  } else if (showModalShare) {    //User is in the process of sharing a conectivity TO the community
				return (
					 <div className="ContainerNoHeightCenter"> 
							 <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleSubmit} disabled={disableSaveButton()}> Post </button>
						  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={() => handleCloseEditor({result:"CANCELED"})} >  Cancel  </button>  
					 </div>
				);            
		 } else if (showModalEditPost) {
				return (
					 <div className="ContainerNoHeightCenter"> 
							 <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationEdit} disabled={disableSaveButton()}> Save </button>
							 <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={() => handleCloseEditor({result:"CANCELED"})} >  Cancel  </button>  
					 </div>
				);            
		  } else return null;
		  
		  
		  
		  /* OLD SHARE BUTTON FROM EDIT SCREEN - NOT USED
							  {conectivityToEditLocal.conectivityScope === "CUSTOMER_SHARED" && (conectivityToEditLocal.publicationStatus === "REVIEW" || conectivityToEditLocal.publicationStatus === "PUBLISHED") ? 
								<button className="buttonStyle1 buttonStyle1Purple" aria-label="done" onClick={handleWithdraw} >
									 WITHDRAW
								</button>
								: null }
			
		  */
				  

	 }
	 
	 //SAVE AS MODAL CONFIRMATIN HANDLERS
	 function handleSaveAs (e) {
		  e.stopPropagation();        //Prevent outer OnClick from firing 

		  // if (DEBUG_MODE >= 2) console.log("User selected Save As", conectivityToEdit); 

		  setNewSaveAsTitle(conectivityToEditLocal.title);
		  // setShowModalEdit(false);
		  setShowModalSaveAs(true);
	 }

	 //Cancel Save As handler
	 function handleCancelSaveAs (e) {
		  if (e !=null) e.stopPropagation();        //Prevent outer OnClick from firing 
		  setNewSaveAsTitle("");
		  setShowModalSaveAs(false);
		  setConectivityToEditLocal({...conectivityToEditLocal, title: newSaveAsTitle});

		  // setShowModalEdit(true);
	 }
 
	  //Save As Confirmation
	 function handleSaveAsConfirmation (e) {
		  if (e !=null) e.stopPropagation();        //Prevent outer OnClick from firing 
		  if (DEBUG_MODE >= 2) console.log("User submitted Save As", conectivityToEditLocal, newSaveAsTitle); 
		  setShowModalSaveAs(false);
		  handleSubmit(e);
	 }
	 
	 //On an editor change, only update or Preview conectivity and not the local Edit object so that we don't trigger a render of the entire page, which would cause the Editor to lose focus
	 const handleEditorChange = () => {
		  if (DEBUG_MODE >= 2) console.log("Editor changed");

		  if (editorRef.current) {
			  var tempInstructions = editorRef.current.getContent();
			  setConectivityPreview({ ...conectivityToPreview, instructions: tempInstructions });

			}        
		  
	 };
 
	//handle detailed view of conectivity 
	function handleDetailedView (conectivityToViewID) {
		setShowModalConectivityDetailed(true);
	} 

	//handle close detailed view
	const handleCancelDetailedView = () => {
		setShowModalConectivityDetailed(false);
	};

	//handle click of the Undo button - restore state of previous conectivity in our cach
	const handleUndo = () => {
		//Safety check
		if (!conectivityToEditCache || conectivityToEditCache.length<2 || conectivityToEditCachePointer <= 0) {
			if (DEBUG_MODE) console.error('Error - cannot restore prior state for conectivity being edited', conectivityToEditCachePointer, conectivityToEditCache);
		}

		//Decrement our cache index
		let newCacheIndex = conectivityToEditCachePointer - 1;
		setConectivityToEditCachePointer(newCacheIndex);

		//Copy the cached conectivity
		const tempNewConectivity = ({...conectivityToEditCache[newCacheIndex]});
		setConectivityToEditLocal(tempNewConectivity);
		if (DEBUG_MODE) console.log("Updated editor's conectivity", tempNewConectivity);
		if (DEBUG_MODE) console.log("Conectivity cache pointer", newCacheIndex);
	};

		//handle click of the Redo button - advance state to the next conectivity in our cach
		const handleRedo = () => {
			//Safety check
			if (!conectivityToEditCache || conectivityToEditCache.length<2 || conectivityToEditCachePointer >= conectivityToEditCache.length-1) {
				if (DEBUG_MODE) console.error('Error - cannot advance state for conectivity being edited', conectivityToEditCachePointer, conectivityToEditCache);
			}
	
			//Decrement our cache index
			let newCacheIndex = conectivityToEditCachePointer + 1;
			setConectivityToEditCachePointer(newCacheIndex);
	
			//Copy the cached conectivity
			const tempNewConectivity = ({...conectivityToEditCache[newCacheIndex]});
			setConectivityToEditLocal(tempNewConectivity);
			if (DEBUG_MODE) console.log("Updated editor's conectivity", tempNewConectivity);
			if (DEBUG_MODE) console.log("Conectivity cache pointer", newCacheIndex);
		};

	 //handle click on the LINK button
	 const handleCopyLink = (conectivity) => {
		  
		  if (!conectivity || !conectivity.id || !conectivity.conectivityScope) {if (DEBUG_MODE >= 2) console.log("Error - invalid conectivity", conectivity); return;}
		  
		  try {
		  
				let linkToCopy;
	 
				//Are we linking to a conectivity published to the Launchpad or to one shared to the Community?  The links will be different
				switch (conectivity.conectivityScope) {
					 case "GLOBAL":
					 case "CUSTOMER_SPECIFIC":
					 case "INSTALLED_FROM_COMMUNITY":
						  linkToCopy = "https://account.conectere.net/launchpad?CONECTIVITY=" + conectivity.id;
						  break;
					 
					 case "CUSTOMER_SHARED":
						  linkToCopy = "https://account.conectere.net/conectereCommunity?CONECTIVITY=" + conectivity.id;
						  break;                
				}
				if (linkToCopy) {
					 window.navigator.clipboard.writeText(linkToCopy);
					 if (DEBUG_MODE >= 2) console.log("Copying link", linkToCopy);
				} else {if (DEBUG_MODE >= 2) console.log("Error - no link generated"); }

		  } catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error copying URL", err);
		  }
		  
	 };
 
	/* DISPLAY FUNCTIONS */
	function displayPreviewSelect() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" style={{maxWidth:"60px"}}>
				<div> Preview </div> 
				<ViewButton onClickCallback={handleDetailedView} hoverText='Preview'/>
			</div>        
		)
	}

	function displayUndoRedo() {
		return (
			<div className='ContainerNoHeightCenter' >
				<div className="inputFormVerticalLeftInputField TextStyle3" style={{maxWidth:"60px"}}>
					<div> Undo </div> 
					<UndoButton onClickCallback={handleUndo} hoverText="Undo Iggy's conectivity changes" disabled={!conectivityToEditCache || conectivityToEditCache.length<2 || conectivityToEditCachePointer === 0}/>
				</div>        
				<div className="inputFormVerticalLeftInputField TextStyle3" style={{maxWidth:"60px"}} >
					<div> Redo </div> 
					<RedoButton onClickCallback={handleRedo} hoverText="Redo Iggy's conectivity changes"disabled={!conectivityToEditCache || conectivityToEditCache.length<2 || (conectivityToEditCachePointer >= conectivityToEditCache.length-1)} />
				</div>   
			</div>
		)
	}	

	function displayPubStatusSelect() {
		return(
			<div className="inputFormVerticalLeftInputField TextStyle3" >
					<div>Publication Status </div> 
					<div style={{width:"250px"}}>
						{(isSuperAdmin || permissionEditor) ?

							<Select  isMulti={false} name="statusDropdownEdit" 
									options={conectivityStatusOptions} 
									isOptionDisabled={(option) => option.isdisabled}
									isDisabled={(!isSuperAdmin && !permissionEditor) || (conectivityToEditLocal.isTemplate)}  //Disable if not SuperAdmin or Conectere Editor or if a Template, which cannot be published
									onChange={handleStatusSelection} 
									value={!conectivityStatusDropDownSelection ? "" : conectivityStatusOptions[conectivityStatusDropDownSelection.value]}  
									placeholder=" - select -"
							/>
						:  
							<div className="black"> {!conectivityStatusDropDownSelection ? "" : conectivityStatusDropDownSelection.displayString} </div>
						}
					</div>
			</div>        
		)
	}
	function displayCustomerSelect() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" >
				<div>Created By</div> 
				<div style={{width:"250px"}}>
					{isSuperAdmin  ?
						<Select  isMulti={false} name="companyDropdownEdit" 
								options={customerOptionsNoAll} 
								isOptionDisabled={(option) => option.isdisabled}
								isDisabled={!isSuperAdmin }  //Disable if not SuperAdmin 
								onChange={handleCompanyScopeSelection} 
								value={!customerScopeSelection ? "" : customerScopeSelection}  
								placeholder=" - select -"
						/>
					: <div className="black"> {!customerScopeSelection ? "" : customerScopeSelection.label} </div>
					}
				</div>
			</div>        
		)
	}
	function displayAuthorSelect() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" >
				<div>Author</div> 
				<div style={{width:"250px"}}>
					{isSuperAdmin  ?
							<Select  isMulti={false} name="authorDropdownEdit" 
								options={authorSelectionOptions} 
								isOptionDisabled={(option) => option.isdisabled}
								isDisabled={!isSuperAdmin }  //Disable if not SuperAdmin 
								onChange={handleAuthorSelection} 
								value={!authorSelection ? "" : authorSelection}  
								placeholder=" - select -"
							/>
					: 
							<div className="black"> {!authorSelection ? "" : authorSelection.label} </div>
					}
				</div>
			</div>        
		)
	}
	function displayCategoryInput() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" >
				<div>Category </div>
				<div 
					style={{width:"250px", border: (!categoryDropDownSelection ? "1px solid red" : "none")}}
				>
					<Select  isMulti={false} name="categoryDropdownModal" options={categoryOptions} onChange={handleCategorySelect} value={!categoryDropDownSelection ? "" : categoryOptions[categoryDropDownSelection.value]} placeholder=" - select -"/>
				</div>
			</div> 
		)    
	}
	function displayTitleInput() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" style={{minWidth:"400px"}}>
					<div> Title </div> 
					<input
						placeholder="Enter title"
						style={{width:'100%', border: (conectivityToEditLocal.title.length===0 ? "1px solid red" : "1px solid grey")}}
						value={conectivityToEditLocal.title}
						onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, title: event.target.value})}
						maxLength={CONECTIVITY_TITLE_MAX_INPUT_LENGTH}
						required
					/>
			</div>         
		)
	}
	function displayBadgesSelect() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3">
					<div> Badges </div> 
				<div className="ContainerNoHeightCenter"><div> DEI </div> <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt="DEI badge" />
						<Switch
							name="deiRelated"
							checked={(conectivityToEditLocal.deiRelated > 0)}   
							onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, deiRelated: !conectivityToEditLocal.deiRelated,  badgesDEI:(conectivityToEditLocal.deiRelated ? 0 : 1) })}
						/>
						<div> CS </div> <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt="CS badge" />
					<Switch
							name="csRelated"
							checked={( conectivityToEditLocal.csRelated > 0)}   
							onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, csRelated: !conectivityToEditLocal.csRelated,  badgesCS:(conectivityToEditLocal.csRelated ? 0 : 1) })}
						/>
				</div>
			</div>
		)
	}
	function displayTemplateEnable() {
		if (!isSuperAdmin && !permissionEditor) return null
		return (
				<div className="inputFormVerticalLeftInputField TextStyle3">
						<div> Template </div> 
							<Switch
								name="isTemplate"
								checked={conectivityToEditLocal.isTemplate}   
								onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, isTemplate: !conectivityToEditLocal.isTemplate})}
							/>
				</div>
		)
	}
	function displayOverviewInput() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" style={{minWidth:"400px", flex:"1.5 0 0px"}}>
				<div> Overview </div> 
				<textarea
					className="adminConectivitiesOverviewInput fullWidth"
					style={{border: (!conectivityToEditLocal.description || conectivityToEditLocal.description.length===0 ? "1px solid red" : "1px solid grey")}}
					name="description"
					type="textarea"
					maxLength={CONECTIVITY_DESCRIPTION_MAX_INPUT_LENGTH}
					// rows="4" 
					// cols="60"
					value={conectivityToEditLocal.description}
					onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, description: event.target.value })}
					required
				/>
			</div>        
		)
	}
	function displayTimeToCompleteInput() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" >
				<div className="ContainerNoHeightCenter">
					<span>Time to Complete </span> 
					<HelpButton hoverText="How long will it likely take the participants to complete the conectivity?  Be sure to include any time to coordinate and set up for the conectivity."  /> 
				</div>
				<div className="ContainerNoHeightCenter" >
					<input
							style={{border: (conectivityToEditLocal.conectCoins===0 ? "1px solid red" : "1px solid grey")}}
							name="approvedPaidTime"
							type="text"
							size="5"
							value={conectivityToEditLocal.approvedPaidTime}
							onChange={event => handleAPT(event.target.value)}
							required
							disabled={!categoryDropDownSelection ? true : false}
					/>
					<div className="TextStyle2" style={{paddingLeft:"5px"}}>{displayPaidTimeRange()} </div>
				</div>        
			</div>
		)
	}
	function displayDaysActiveSelect() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle3" >
				<div className="ContainerNoHeight">
					<div>How many days </div>
					<HelpButton hoverText="The duration the conectivity will be displayed to the user and available for completion, once launched.  This field can be adjusted for
								any conectivity that can be manually launched and is otherwise locked."  /> 
				</div>
				<div className="ContainerNoHeightCenter" >
					<input
							style={{border: (!Number(conectivityToEditLocal.signupDays) || Number(conectivityToEditLocal.signupDays===0 || Number(conectivityToEditLocal.signupDays > 99)) ? "1px solid red" : "1px solid grey")}}
							name="signupDays"
							type="text"
							size="5"
							value={conectivityToEditLocal.signupDays}
							onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, signupDays: event.target.value })}
							required
							disabled={!conectivityToEditLocal.isSpecialEvent}
					/>
					<div className="TextStyle2" style={{paddingLeft:"5px"}}>(manual launch only)</div>
				</div>
			</div>
		)
	}
	function displayEditor() {
		return (
			<div className="inputFormVerticalLeftInputField TextStyle5" >
				<div className="TextStyle5 purple" style={{minHeight:"2.0rem"}} >Instructions </div> 
				<Editor
							apiKey="941y7dc26vh4t4laezbgyxqjzrwq1py7hl2l95qdephwb134"
							initialValue={conectivityToEditLocal.instructions}
							onInit={(evt, editor) => editorRef.current = editor}
							onEditorChange={handleEditorChange}
							init={{
							autoresize_bottom_margin: 50,
							branding: false,
							min_height: 800,
							max_height: 2000,
							// width: 600,
							menubar: true,
							auto_focus: true,
							plugins: ['autoresize', 'emoticons', 'advlist', 'autolink', 'lists', 'link', 'image', 
								'charmap', 'print', 'preview', 'anchor', 'help',
								'searchreplace', 'visualblocks', 'code',
								'insertdatetime', 'media', 'table', 'paste', 'wordcount'
							],
							toolbar:
								'undo redo | link emoticons | formatselect | bold italic | alignleft aligncenter alignright |  bullist numlist outdent indent | help'
							}}
					/> 
			</div>
		)
	}

	function displayLaunchPadSelect() {
		return (
				<div className="ContainerNoHeightSpaceBetween fullWidth noWrap TextStyle3" style={{padding:"1rem 1rem 0rem 1rem"}}>
					<div className="ContainerNoHeightFlexLeft" >
						<div style={{padding:"10px 0px 10px 10px"}}>Admin launch only? </div> 
						<HelpButton hoverText="Would you like the conectivity to only be launchable by an administrator from this dashboard?  If so, check this box.  Otherwise, the conectivity to be available for all employees in your company's launchpad.  By default this box is unchecked." 
						hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' /> 
					</div>
					<Checkbox
						disabled={false }
						checked={conectivityToEditLocal.isSchedulableByAdminOnly}
						onChange={event => setConectivityToEditLocal({ ...conectivityToEditLocal, isSchedulableByAdminOnly: !conectivityToEditLocal.isSchedulableByAdminOnly})}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</div>
		);     
	}
	function displaySpecialEventSelect() {
		return (
			<div className="ContainerNoHeightSpaceBetween fullWidth noWrap TextStyle3" style={{padding:"1rem 1rem 0rem 1rem"}}>
				<div className="ContainerNoHeightFlexLeft" >
					<div style={{padding:"10px 0px 10px 10px"}}>Is the conectivity for an event that starts on a <b>specific date?</b> </div> 
					<HelpButton hoverText={"Check this box if the conectivity is for an event that ONLY occurs (or starts on) a specific date, such as National Bike to Work Day, Random Acts of Kindness Week, World Heart Day or a specific volunteer event organized by a charity.  Additional examples include conectivities for specific training sessions or company events that occur (or start on) certain dates." +
						"  When you check this box, our AI engine will ensure that the conectivity is displayed on the employees' Home Screen on the specified date(s), and won't recommend the conectivity on other days throughout the year.  By default, this box is unchecked."}  
						hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' /> 
				</div>
				
				<Checkbox
					disabled={false }
					checked={conectivityToEditLocal.isSpecialEvent}   
					onChange={handleSpecialEventToggle} 
					inputProps={{ 'aria-label': 'controlled' }}
				/>
						  
			</div>
		);
	}

	function displaySpecialEventDetails () {
		if (!conectivityToEditLocal.isSpecialEvent) return null;
		return (
			<div className="boxShadow purple-border" style={{border:"1px solid", borderRadius:"10px", margin:"10px 20px", padding:"5px", }}>
				<div className="ContainerVerticalLeft" style={{padding:"1rem 1rem 0rem 1rem", position:"relative"}}>
					<h5 className="purple" style={{}}>Special Events</h5> 
					<div style={{padding:"10px"}}>When does the event start and end? </div> 
					<DisplayDateTimeSelect value={eventStartDateTime} onChange={eventStartDateTimeChangeHandler} disabledDate={false} disabledTime={conectivityToEditLocal.allDayEvent || conectivityToEditLocal.hasNoSpecificTime} hideTime={conectivityToEditLocal.allDayEvent || conectivityToEditLocal.hasNoSpecificTime}  zIndex='7777' labelDate="Start Date" labelTime='Start Time' forceClosedTrigger = {forceClosedTrigger1}/>
					<DisplayDateTimeSelect value={eventEndDateTime} onChange={eventEndDateTimeChangeHandler} disabledDate={false} disabledTime={conectivityToEditLocal.allDayEvent || conectivityToEditLocal.hasNoSpecificTime} hideTime={conectivityToEditLocal.allDayEvent || conectivityToEditLocal.hasNoSpecificTime} zIndex='7770' labelDate="End Date" labelTime='End Time' forceClosedTrigger = {forceClosedTrigger1}/> 
					<div className="ContainerNoHeightCenter wrap">
						<DisplayAllDayEventSelect 
							value={conectivityToEditLocal.allDayEvent} 
							onChange={()=>{
								if (!conectivityToEditLocal.allDayEvent) setConectivityToEditLocal({...conectivityToEditLocal, allDayEvent:true, hasNoSpecificTime:false});
								else setConectivityToEditLocal({...conectivityToEditLocal, allDayEvent:false});
							}} 
							zIndex='6500' isDisabled={false}  
						/>
						<DisplayAnytimeEventSelect 
							value={conectivityToEditLocal.hasNoSpecificTime} 
							onChange={()=>{
								if (!conectivityToEditLocal.hasNoSpecificTime) setConectivityToEditLocal({...conectivityToEditLocal, hasNoSpecificTime:true, allDayEvent:false});
								else {
									//Turning OFF Any Time select so init the specific times to noon to 1:00; leave current date value unchanged
									setEventStartDateTime(eventStartDateTime.set({hour:12,minute:0,second:0,millisecond:0}));               
									setEventEndDateTime(eventEndDateTime.set({hour:13,minute:0,second:0,millisecond:0}));                                    
									setConectivityToEditLocal({...conectivityToEditLocal, hasNoSpecificTime:false});
								}
							}} 
							zIndex='6400' isDisabled={false}  
						/>
					</div>
					
					{/*<div className="ContainerNoHeightSpaceBetween fullWidth noWrap TextStyle3" style={{zIndex:'6666'}}>
						<div style={{padding:"10px"}}>Does the event span multiple days? </div> 
						<div className="ContainerNoHeightFlexRightTop" style={{flex:"0"}}>
						<Checkbox
								disabled={false }
								checked={multiDayEvent}   
								onChange={()=>{
									if (multiDayEvent) {
										if (DEBUG_MODE > 1) console.log("Switching to NON multi-day event; resetting recurrence");
										setLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[0]);

									}
									setMultiDayEvent(!multiDayEvent);

								}} 
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						<HelpButton hoverText={"Check this box if the conectivity is for an event spans multiple days, such as a week long challenge or month long fundraiser, where the employee completes the conectivity and receives rewards at the end of the period."}  
							hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' /> 
						</div>
					</div>
					*/}


			
					

							{/*<div style={{padding:"10px"}}>{displayDaysActiveSelect()}</div> */}
							<DisplayRecurrenceSelect 
								introText="Does the event repeat, like a recurring daily challenge or weekly volunteer opportunity?"
								helpText = {'Select this option when you would like employees to complete the conectivity and receive rewards multiple times over a date range.  ' +
								'One example is a Team Get Your Steps challenge in which team members compete each day to see who can get the most steps.  In this case, the conectivity ' +
								'would have a Daily recurrence, and team members are able to complete the conectivity and receive rewards each day.  Another example would be a community service initiative in which employees have the opportunity to volunteer each Wednesday at the local elementary school.'}
								repeatOption={launchRepeatOption}  repeatOptionChangeHandler={recurrenceOptionChangeHandler}
								weekOption = {launchRepeatWeeklyOptions} weekOptionHandler = {setLaunchRepeatWeeklyOptions}
								monthCheck1 = {launchMonthOption1Checked} monthCheck2 = {launchMonthOption2Checked}
								monthCheck1Handler = {setLaunchMonthOption1Checked} monthCheck2Handler={setLaunchMonthOption2Checked}
								monthOption1 = {launchRepeatMonthlyOptions} monthOption1Handler = {setLaunchRepeatMonthlyOptions}
								monthOption2 = {launchRepeatMonthlyOptions_2} monthOption2Handler = {setLaunchRepeatMonthlyOptions_2}
								monthOption3 = {launchRepeatMonthlyOptions_3} monthOption3Handler = {setLaunchRepeatMonthlyOptions_3}
								repeatUntil = {launchUntilDate} repeatUntilHandler = {setLaunchUntilDate}
								isDisabled={false}  
								forceClosedTrigger = {forceClosedTrigger1}
								hideRepeatUntil = {false}
							/>


						<div className="ContainerNoHeightSpaceBetween fullWidth noWrap" style={{zIndex:'3333'}}>
							<div className="ContainerNoHeightFlexLeft" >
								<div style={{padding:"10px 0px 10px 10px"}}>Will all employees be invited to participate in the event? </div> 
								<HelpButton hoverText={"Check this box if the all employees will have the opportunity to participate in the event, such as a company-wide charity event or an all-employee challenge.  " +
									"If so, we'll go ahead and automatically put the event on everyone's calendar."}  
								hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' /> 
							</div>
							<Checkbox
								disabled={false }
								checked={conectivityToEditLocal.inviteAllEmployees}   
								onChange={()=>setConectivityToEditLocal({...conectivityToEditLocal, inviteAllEmployees:!conectivityToEditLocal.inviteAllEmployees})} 
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</div>              
					</div>
			</div>
		);
	}

	const handleChallengeToggle = () => {
			setConectivityToEditLocal({ 
				...conectivityToEditLocal, 
				isChallenge: !conectivityToEditLocal.isChallenge, //Flip the state
				challengeOptions:{ isAnonymous:false, inputEval:'CUMULATIVE', inputDataType:'INTEGER'} //Regardless, init or reset params
			})
	}

	function displayChallengeSelect() {
		return (
			<div className="ContainerNoHeightSpaceBetween fullWidth noWrap TextStyle3" style={{padding:"1rem 1rem 0rem 1rem"}}>
				<div className="ContainerNoHeightFlexLeft" >
					<div style={{padding:"10px 0px 10px 10px"}}> Does the conectivity involve a friendly challenge? 🏃🧘‍♀️🏋️‍♀️ </div> 
					<HelpButton hoverText={HELP_TEXT_CHALLENGE_EDITOR_1}  isHTML={true}
						hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyleWide' /> 
				</div>
				
				<Checkbox
					disabled={false }
					checked={conectivityToEditLocal.isChallenge ? conectivityToEditLocal.isChallenge : false}   
					onChange={handleChallengeToggle}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
						  
			</div>
		);
	}

	/* Handler for Challenge Input */
	const challengeHandler = (options) => {
		if (DEBUG_MODE >= 2) console.log("New challenge options", options);
		const tempConectivity = { ...conectivityToEditLocal, challengeOptions:options};
		if (DEBUG_MODE >= 2) console.log("Updated local conectivity being edited", JSON.stringify(tempConectivity));
		setConectivityToEditLocal(tempConectivity);
	}; 

	function displayChallengeDetails () {
		if (!conectivityToEditLocal.isChallenge) return null;
		return (
			<div className="boxShadow purple-border" style={{border:"1px solid", borderRadius:"10px", margin:"clamp(4px,1vw,10px)"}}>
				<div className="ContainerVerticalLeft" style={{padding:"clamp(4px,1vw,10px)", position:"relative"}}>
					<h5 className="purple" style={{}}>Challenges </h5> 
					<div className="very-light-grey-background borderRadius fullWidth" style={{marginLeft:"clamp(4px,1vw,10px)", padding:"clamp(2px,0.5vw,8px)" }}>
						<DisplayChallengeSelect 
							challengeOptions={conectivityToEditLocal.challengeOptions}  
							callback={challengeHandler}
							disabled={!conectivityToEditLocal.isChallenge} />
					</div>	
				</div>
			</div>
		);
	}
	/* Handlers for Recurrence Input */
	const recurrenceOptionChangeHandler = (option) => {

		//First, set the option
		setLaunchRepeatOption(option);
		if (DEBUG_MODE >= 2) console.log("New launch repeat option", option);

		//Now, adjust the UNTIL date
		let tempUntilDate;
		if (option && option.value === 'DAILY') 
			 tempUntilDate = NOW().endOf('month');   //If DAILY, just default to the end of the month 
		else 
			 tempUntilDate = SIX_MONTHS_FROM_NOW();  
		setLaunchUntilDate(tempUntilDate);
	};    


	const eventStartDateTimeChangeHandler = (newDateValue) => {

		//First, set the new start time
		setEventStartDateTime(newDateValue);
		if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());

		//Now, auto-adjust the end time based on the change
		var momentChange = newDateValue.diff(eventStartDateTime.clone().startOf('minute'), 'minutes');      //Calc from the beginning of the minute to avoid rounding errors
		if (DEBUG_MODE >= 2) console.log("User Adjusted start date/time by (minutes):", momentChange);
		
		if (momentChange < 0) momentChange = momentChange-1;
		const tempEndDateTime = eventEndDateTime.clone().startOf().add(momentChange,"minutes");           
		if (DEBUG_MODE >= 2) console.log("New launch END date/time auto-set:", tempEndDateTime.toISOString());
		setEventEndDateTime(tempEndDateTime);
	};
	
  const eventEndDateTimeChangeHandler = (newDateValue) => {

		//First, set the new END date/ time
		setEventEndDateTime(newDateValue);
		if (DEBUG_MODE >= 2) console.log("New launch END date/time:", newDateValue.toISOString());

		//Is the new end date BEFORE the start date?  If so, set the start date to match
		
		/* STILL A BUG HERE IN THAT IT ADJUSTS PER KEY STROKE - NEED TO FIND A WAY TO RESOLVE
		if (newDateValue.isBefore(eventStartDateTime)) {
			 if (DEBUG_MODE >= 2) console.log("New launch START date/time: auto-set", newDateValue.toISOString());
			 setEventStartDateTime(newDateValue);
		}
		*/
	};    

  //
   //AI Functions
   //
   
   useEffect(() => {

		//Safety check
		if (!showModalAdd && !showModalEdit) return;
		
		if (DEBUG_MODE) console.log("AI prompt useEffect fired", promptMessageFromUser);

		const invokeAI = async () => {

			 //Invoke our backend AI LLM Interface
			 const response = await issuePromptToAiInterface({
				  userID:currentUser.id,
				  customerID:currentUser.customerID, 
				  prompt:promptMessageFromUser, 
				  history:aiInterfaceMessages, 
				  isForConectivity:true, 
				  isCommunityServiceRelated:conectivityToEditLocal.csRelated,
				  isDEIRelated:conectivityToEditLocal.deiRelated,
				  conectivity:conectivityToEditLocal,
				  category:categoryDropDownSelection,
			 });
			 if (DEBUG_MODE) console.log("AI Interface Lambda returned", response);

			 //Inject the message into our chat interface
			 injectMessagesFromAI({
				  aiInterfaceMessages, 
				  setAiInterfaceMessages,
				  response, 
				  replaceEntireHistoryFlag:true
			 });

			//Construct an updated conectivity for our editor
			let tempConectivity = genConectivityFromAiResponse({
				response, 										//Generated content
				conectivity:conectivityToEditLocal,			//Current values
		  	});
		  
			//Now, update our local conectivity and our conectivity cache for Undo / Redo
			if (tempConectivity && tempConectivity.id) {
				setConectivityToEditLocal(tempConectivity);
				const tempCache = ([...conectivityToEditCache]);
				tempCache.push(tempConectivity);
				setConectivityToEditCache(tempCache);
				//Regardless of where the user was in the cache, move him / her to the end which is now being shown
				//Note, we build on the end even if the user 'backed-up' with undos since that conectivity being viewed is the one fed to Iggy
				let newCacheIndex = tempCache.length-1; //End
				setConectivityToEditCachePointer(newCacheIndex);		
				if (DEBUG_MODE) console.log("AI: Updated editor's conectivity", tempConectivity);
				if (DEBUG_MODE) console.log("AI: New conectivity cache:", tempCache);
				if (DEBUG_MODE) console.log("AI: Conectivity cache pointer", newCacheIndex);
			} else console.error("Error - unable to update local conectivity", tempConectivity);
		}      
		invokeAI();
 },[promptTrigger]);

 	async function handleAIPromptFromUser ({message, newChatMessage}) {
		if (!newChatMessage || newChatMessage.length === 0 || !message || message.length === 0) {
			 if (DEBUG_MODE) console.error("Error - improper params in the Callback", message);
			 return;
		}
		//Add the user's prompt to our messages
		const tempNewMessages = [...aiInterfaceMessages];
		tempNewMessages.push({...newChatMessage, messageType:'PROMPT'}); //Label as a PROMPT to distinquish from other message types in our chat history
		setAiInterfaceMessages(tempNewMessages);

		//Store the message to trigger our useEffect
		if (DEBUG_MODE) console.log("Processing AI prompt via Callback", message);
		setPromptMessageFromUser(message);
		setPromptTrigger(!promptTrigger);
 	}

	 const messageSelectCallback = (message) => {
		if (DEBUG_MODE) console.log("Message Select Callback Invoked", message);
		if (!message || !message.message) return;

		//Process the selected message
	}

	/* RENDER FUNCTION */

	 if (!isSuperAdmin && !isAdmin && !permissionEditor) return null;
	 if (!showModalEdit && !showModalEditPost && !showModalAdd && !showModalShare) return null;
	 
	return (
		  <div>
					
		  <ModalNoBackground showModal={showModalEdit || showModalEditPost || showModalAdd || showModalShare} closeCallback={() => handleCloseEditor({result:"CANCELED"})} cardColor={TEAM_COLOR}  >
				<div className="modalNoBkgInnerCard" onClick = {(e) =>  e.stopPropagation()}>
					 <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
						  <img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  style={{padding:"clamp(4px,1vw,10px)"}} alt='logo'/>  
					 </div>
					 {(conectivityToEditLocal.conectivityScope === "CUSTOMER_SHARED") ?
						  <div className="TextStyle6" style={{paddingTop:"clamp(4px,1vw,10px)", paddingBottom:"clamp(8px,2vw,20px)", fontWeight:"600" }}>
								{displayConectereCommunityText ({ displayFooter:true})}
								
								<div className="ContainerNoHeightSpaceAround very-light-grey-background TextStyle3" style={{borderRadius:"10px", padding:"10px"}}>
									 <div className="Container" style={{padding:"0px clamp(4px,1vw,10px)"}}>  &#9671; Create your public version!  </div>
									 <div className="Container" style={{padding:"0px clamp(4px,1vw,10px)"}}>  &#9671; Remove any confidential information </div>
									 <div className="Container" style={{padding:"0px clamp(4px,1vw,10px)"}}>  &#9671; Include only links to publicly-available resources </div>
								</div>
						  </div>
					 :
						  <h3 className="blue"> CONECTIVITY EDITOR </h3>
					 }
					<form className="fullWidth" id='conectivityEditForm' style={{padding:"0px clamp(4px,1vw,10px)"}}>
						<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
							<div className="inputFormVerticalLeftInputField TextStyle3" >
									<div>Scope </div> 
									<div className="black" style={{minWidth:"150px"}}> {displayConectivityScope()} </div>
							</div>
							{displayPubStatusSelect()}
							{displayCustomerSelect()}
							{displayAuthorSelect()}         
							{isSuperAdmin ? <CopyLinkButton onClickCallback={handleCopyLink} onClickCallbackParam={conectivityToEditLocal} /> : null }
						</div>
							
						<Divider percent={100} marginTop = '10px' />

						<div className="ContainerRowLeftStretch inputFormRowWrapper" >
					 		<div className="ContainerVertical" style={{flex:"1"}}>
							 <div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
									{displayCategoryInput()}
									{displayPreviewSelect()}
									{displayUndoRedo()}
								</div>
								<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
									{displayTitleInput()}
								</div>
								<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
									{displayOverviewInput()}
								</div>
								<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
									{displayBadgesSelect()}    
									{displayTemplateEnable()}
									{displayTimeToCompleteInput()}
								</div>						

							</div>
							{!AIinterfaceEnabled ? null : 
								<div style={{marginLeft:"clamp(10px,1.0vw,20px", flex:'1'}}>
									<ChatWindow 
										messagesToShow={aiInterfaceMessages} 
										currentUser={currentUser} 
										disableReplies={true}
										disableGif={true}
										minHeight={'400px'}
										placeholderMessage={(aiInterfaceMessages && aiInterfaceMessages.length > 2 ? 'Want some help?  Enter a prompt' : 'Enter a prompt')}
										sendMessageCallbackOverride={handleAIPromptFromUser}
										messageSelectCallback={messageSelectCallback}
										messageSelectLabel="SELECT"
									/>  
								</div>
							}
						</div>
				
						<div className="AdminConectivitiesInstructionsRowWrapper"  >
							<div style={{flex:'1'}} >
								{displayEditor()}
							</div>

							<div className="inputFormVerticalLeftInputField TextStyle3 " style={{flex:"1", position:"relative"}}>
								<div className="ContainerNoHeightCenter" style={{zIndex:"100"}}>
										<span  className="TextStyle5 purple" >Scheduling Preferences</span>
										<HelpButton hoverText="These settings provide fine-grained control over how your conectivity is launched.
										If the conectivitity you are writing is related to a specific type of challenge or an event on a specific date, use these settings to help employees easily schedule the conectivity."  
										hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' /> 
								</div>
								<div className="ContainerVerticalLeft fullWidth boxShadow borderRadius"  onClick={() => setForceClosedTrigger1(!forceClosedTrigger1)}>
										<div style={{zIndex:"95",width:"100%"}}>{displayLaunchPadSelect()}</div>
										<div style={{zIndex:"90",width:"100%"}}>{displaySpecialEventSelect()} </div> 
										<div style={{zIndex:"85",width:"100%"}}>{displaySpecialEventDetails()} </div>                          
										<div style={{zIndex:"90",width:"100%"}}>{displayChallengeSelect()} </div> 
										<div style={{zIndex:"85",width:"100%"}}>{displayChallengeDetails()} </div>                          
								</div>
							</div>
						</div>
					</form>
				<div className="modalNoBkgFooter flexEnd">
					 {displayEditModalButtons()}       
				</div>
		  </div>
	  </ModalNoBackground> 

	  <ModalNoBackgroundFixed showModal={showModalSaveAs} closeCallback={handleCancelSaveAs} cardColor={TEAM_COLOR}  >
				<div className="modalNoBkgInnerCard" style={{padding:"clamp(10px,2vw,20px)"}}>
					<div  className="modalNoBkgImage ccImgXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
						<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt=''/>  
					</div>
					<div className="TextStyle5" style={{padding:"20px 0px", textAlign:"center"}}>Save as a new conectivity?</div>
					<div className="inputFormVerticalLeftInputField TextStyle3" style={{minWidth:"clamp(200px,30vw,400px)", color: BALANCE_COLOR}}>
						<div > New Title </div> 
						<input
								style={{width:'100%', padding:"10px 10px", border:(newSaveAsTitle === conectivityToEditLocal.title ? "1px solid red" : "1px solid black")}}
								value={newSaveAsTitle}
								onChange={event => setNewSaveAsTitle(event.target.value)}
								required
						/>
					</div>        
					<div className="adminConectivitiesTemplateButtonsWrapper">
						{(newSaveAsTitle != conectivityToEditLocal.title) ?
						<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleSaveAsConfirmation}> Yes </button>
						: "" }
						<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={handleCancelSaveAs} disabled={disableSaveAsButton() }> Cancel </button>
					</div>
				</div>
		</ModalNoBackgroundFixed >

	  <ConectivityDetailedView showModal={showModalConectivityDetailed} conectivityToView={conectivityToPreview}  handleCancelDetailedView={handleCancelDetailedView} />
	</div>
	);

}
	 /*  ,(prevProps, nextProps) => {
		  //Our React MEMO function - do not re-render if no conectivity or all the props stay the same
		  if (
				(!prevProps.conectivityToEdit && !nextProps.conectivityToEdit) || 
				(!prevProps.conectivityToEdit.id && !nextProps.conectivityToEdit.id) || 
				((prevProps.conectivityToEdit.id === nextProps.conectivityToEdit.id) &&
				(prevProps.showModalAdd === nextProps.showModalAdd) &&
				(prevProps.showModalEdit === nextProps.showModalEdit) &&
				(prevProps.showModalShare === nextProps.showModalShare) && 
				(prevProps.showModalEditPost === nextProps.showModalEditPost))
				) 
		  {
				if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Editor!", !prevProps.conectivityToEdit, !nextProps.conectivityToEdit, !prevProps.conectivityToEdit.id, nextProps.conectivityToEdit.id, prevProps.conectivityToEdit, nextProps.conectivityToEdit);
				return true; // props are equal
		  }
		  if (DEBUG_MODE >= 2) console.log("Re-RENDER the Editor!", prevProps.conectivityToEdit, nextProps.conectivityToEdit);
		  return false; // props are not equal -> update the component
	 } */
);

export default ConectivityEditor;
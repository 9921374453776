//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


//Config data
import  { CONECTERE_CONFIG_DATA, CHALLENGE_EVAL_SELECTION_OPTIONS, DEBUG_MODE, GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, QUICK_FILTERS_FOR_CONTECTIVITIES,
	COLOR_BLUE_HEADER, COLOR_WHITE, TEAM_COLOR, BALANCE_COLOR, CONECTIVITY_PUBLICATION_SELECTION_OPTIONS, CONECTIVITY_INITIAL_STATE,
	COLOR_SILVER
  } from '../../data/conectereConfigData'; 
import { HELP_TEXT_CHALLENGE_SETUP } from '../../data/helpData.js';


//React & Amplify
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { ConectivityContext } from '../../context/conectivitiesContext';
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context

//Bootstrap and other React components
import Select from 'react-select';   //Version with support from multiple selections
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';

//Icons
import { DeleteOutline } from '@material-ui/icons';
import GifIcon from '@mui/icons-material/Gif';
import { IconButton } from '@mui/material';
// import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';

//Utils
import { truncateStringWithDots, displayConectereCommunityText,  ConectereDivider, Divider, HelpButton, InfoButton, CopyLinkButton, EmojiButton, SendButton,  GIFButton, ConectereDividerV2, GIFButtonV2 } from "../../utils/generalUtils";
import { conectivitiesMatchesSearch, DisplayConectivitySelectComponent, setHeaderColorByCategory, invokeUpdateLaunchRule, DisplayCardConectivityPreview, 
 invokeInviteToConect, invokeUpdateInviteToConect, invokeRemoveInviteToConect,  invokeRsvpToInviteToConect, setHeaderColorByStringLength,  } from "../../utils/conectivityUtils";
import { DisplayRecurrenceSelect, DisplayDateTimeSelect, DisplayAllDayEventSelect, NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, 
 WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,  } from "../../utils/dateTimeUtils";
import { DisplayUserSelectComponent, DisplayTeamSelectComponent, getUserInitials, getUserName, generateUsersToInvite, DisplayUserSelectComponentV2, DisplayTeamSelectComponentV2 } from "../../utils/userAndTeamUtils";
import { DisplayChallengeSelect } from "../../utils/challengeUtils"
import { v4 as uuidv4, v1ToV6 } from 'uuid';    

//Our components
import ModalNoBackgroundFixed from "../../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import SearchInputBar from "../../Components/searchInputBar/searchInputBar.js";

import moment from 'moment';

//emoji support
import Picker from 'emoji-picker-react';

//GIPHY support
import { Gif, 
Grid as GiphyGrid,
SearchBar, // the search bar the user will type into
SearchContext, // the context that wraps and connects our components
SearchContextManager, // the context manager, includes the Context.Provider
SuggestionBar, } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';


/*
COMPONENT FOR LAUNCHING CONECTIVITIES, EDITING EXISTING LAUNCH RULES INCLUDING
EDITING AN EXISTING INVITATION FOR AN ACTIVE (SCHEDULED) CONECTIVITY THAT HAS ALREADY BEEN LAUNCHED


*/

const ConectivityLauncher = React.memo(({
 showModalReadOnlyView,
 showModalSendInviteToConect, showModalEditInviteToConect, showModalDeleteInviteToConect,
 autoSave, autoSaveEventStartDateTime, autoSaveEventEndDateTime,             // Params for updating a LR event date/time without requiring user verification, 
 autoRSVP, autoRsvpStatus,                                                   // Params to auto update a LR to RSVP for the user
																			 // such as in response to a drag-n-drop; only applies to non-recurring or if user selects SINGLE_INSTANCE in an recurring LR
 conectivityToProcess,                                                       // Conectivity to launch.  OPTIONAL - if the user has not already selected one (like from the launchPad)
 launchRuleToEdit,                                                           // Launch rule to edit or delete, if already exists
 scheduledConectivityToProcess, invitationToUpdate,                          // Handling an invitation for an already scheduled conectivity?
 handleCloseLauncher, 
 launchRuleInstanceStartDateTime, 
 launchRuleInstanceIndex,
 // showModalLaunch, showModalEditLaunchRule, showModalDeleteLaunchRule, 
 // showModalSendInvitation, showModalEditInvitation, showModalDeleteInvitation, 
}) => {

// Context
const { currentUser } = useContext(AuthContext);    
const { users, teams, teamsOptions, participatingUsersOptions, } = useContext(CustomerContext); 
const { conectivitiesReadyForLaunch,  conectivitiesReadyForLaunchOptions,  } = useContext(ConectivityContext);
const { setShowSpinner } = useContext(DisplayContext); 

//Modal
const [showModalMaster, setShowModalMaster] = useState(false);     //master control for our launcher modal; necessary since menus must be pre-loaded before render

//Launch settings
const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);     //for multi-select
const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);     //for multi-select
const [inviteMessage, setInviteMessage] = useState("");

//Calendar settings
const [eventStartDateTime, setEventStartDateTime] = useState(NOW_ROUNDED_UP_TO_TEN_MIN());               
const [eventEndDateTime, setEventEndDateTime] = useState(NOW_ROUNDED_UP_TO_TEN_MIN());               
const [launchRepeatOption, setLaunchRepeatOption] = useState(CALENDAR_REPEAT_OPTIONS[0]);      //Start at NONE
const [launchRepeatWeeklyOptions, setLaunchRepeatWeeklyOptions] = useState([WEEK_REPEAT_OPTIONS[NOW().day()]]);      
const [launchRepeatMonthlyOptions, setLaunchRepeatMonthlyOptions] = useState(MONTH_REPEAT_OPTIONS[NOW().date()-1]);   //Default to current day of month, with 1st being 0 entry
const [launchRepeatMonthlyOptions_2, setLaunchRepeatMonthlyOptions_2] = useState(MONTH_REPEAT_OPTIONS_2[0]);
const [launchRepeatMonthlyOptions_3, setLaunchRepeatMonthlyOptions_3] = useState(MONTH_REPEAT_OPTIONS_3[NOW().day()]);  //Default to current day of week, with Sunday being 0 entry
const [launchRepeatUntilDate, setLaunchRepeatUntilDate] = useState(SIX_MONTHS_FROM_NOW());               
const [launchMonthOption1Checked, setLaunchMonthOption1Checked] = useState(true);
const [launchMonthOption2Checked, setLaunchMonthOption2Checked] = useState(false);
const [allDayEvent, setAllDayEvent] = useState(false);
const [forceClosedTrigger1, setForceClosedTrigger1] = useState(false);

// For allowing the user to select a conectivity using the Launcher UI
const [selectedConectivity, setSelectedConectivity] = useState(null);     
const [conectivityToLaunch, setConectivityToLaunch] = useState(CONECTIVITY_INITIAL_STATE);            //Used to hold a conectivity that is to be launched
const [enableUserSelectionMode, setEnableUserSelectionMode] = useState(false);

// Emoji picker and GIPHY state data
const [showPicker, setShowPicker] = useState(false);
const [showSearchExperienceMessage, setShowSearchExperienceMessage] = useState(false);
const [messageGif, setMessageGif] = useState(null);
const [showSearchExperienceSpotlight, setShowSearchExperienceSpotlight] = useState(false);
const [messageInputBarHeight, setMessageInputBarHeight] = useState("32px");

//Recurrence Editing State Vars
const [showModalRecurrenceSelection, setShowModalRecurrenceSelection] = useState(false);     //
const [recurrenceOption, setRecurrenceOption] = useState("ALL");    //Default to breaking into a single event

//Challenge
const [challengeOptions, setChallengeOptions] = useState({isChallenge:false});

//AutoSave
const [triggerAutoSave, setTriggerAutoSave] = useState(null);

//State for Search Bar
const [conectivitiesReadyForLaunchOptionsFiltered, setConectivitiesReadyForLaunchOptionsFiltered] = useState([]); //Filtered list based on Search; used to drive all the row data
const [searchTerm, setSearchTerm] = useState("");
const [searchActive, setSearchActive] = useState(true);
const [activeFilters, setActiveFilters] = useState([]);

const onEmojiClick = (event, emojiObject) => {
 setInviteMessage(inviteMessage => inviteMessage + emojiObject.emoji);
 setShowPicker(false);
};

const [newMessageGiphyGridContainerDivSize, setNewMessageGiphyGridContainerDivSize]  = useState(450);   //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match

//React References
const conectereMessagingInputRef = useRef();

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch(GIPHY_SDK_KEY_WEB);  


//On any change to the underlying conectivity data, reset our Search state variables
useEffect(() => {
 setConectivitiesReadyForLaunchOptionsFiltered([...conectivitiesReadyForLaunchOptions]);     //Make a fresh copy; this list is effected by the Search input
}, [conectivitiesReadyForLaunchOptions]);


//Main UseEffect to trigger operation of the Launcher    
useEffect (() => {

 //Safety check
 if (currentUser) {

	 //Initialization 
	 if (showModalSendInviteToConect) initializeNewInviteToConect(conectivityToProcess);
	 else if (showModalEditInviteToConect || showModalDeleteInviteToConect) initializeEditInviteToConect({conectivityToProcess:conectivityToProcess, launchRuleToEdit:launchRuleToEdit});

	 // if (conectivityToProcess && (showModalLaunch || showModalSendInvitation)) initializeNewInvitation(conectivityToProcess);
	 // else if (conectivityToProcess && (showModalEditInviteToConect || showModalDeleteInviteToConect)) initializeEditInviteToConect({conectivityToProcess:conectivityToProcess, launchRuleToEdit:launchRuleToEdit});
	 // else if (conectivityToProcess && (showModalEditLaunchRule || showModalDeleteLaunchRule )) initializeEditLaunchRule(conectivityToProcess, launchRuleToEdit);
	 // else if (conectivityToProcess && (showModalEditInvitation || showModalDeleteInvitation)) initializeEditInvitation(conectivityToProcess, invitationToUpdate);
	 // else if (scheduledConectivityToProcess && showModalSendInvitation) initializeNewInvitation(conectivityToProcess);
	 // else if (scheduledConectivityToProcess && invitationToUpdate && showModalEditInvitation) initializeEditLaunchRule(conectivityToProcess);
 }
 
},[showModalSendInviteToConect, showModalEditInviteToConect, showModalDeleteInviteToConect, showModalReadOnlyView,]);

//July 28 - new master functions for sending new or editing a connection, i.e., a launch rule and (optionally) and associated invitation if the SC has been activated
// Note - our backend Process Invitation lambda EditInviteToConnect function updates both the launch rule and the invitation, if it exists
// This keeps the users on the launch rule (calendar data) and the invite in sync.  So, we need only PULL it from the launch rule here

function initializeNewInviteToConect(conectivityToProcess) {

 if (conectivityToProcess) {
	 if (DEBUG_MODE >= 2) console.log("LAUNCHER: launching new conectivity", conectivityToProcess);
	 setEnableUserSelectionMode(false);
 } else {
	 if (DEBUG_MODE >= 2) console.log("LAUNCHER: user has not yet selected conectivity", conectivityToProcess);
	 setEnableUserSelectionMode(true);
	 setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
	 setSelectedConectivity(null);
 }
 

 //Calendar settings
 //Set START to NOW plus 10 minutes and set END time based on duration of conectivity to Launch
 setEventStartDateTime(NOW_ROUNDED_UP_TO_TEN_MIN());
 setEventEndDateTime(NOW_ROUNDED_UP_TO_TEN_MIN().add((conectivityToProcess ? conectivityToProcess.approvedPaidTime : 10),"minutes"));    //Init, if possible

 setLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[0]);      //Start at NONE
 setLaunchRepeatWeeklyOptions([WEEK_REPEAT_OPTIONS[NOW().day()]]);      
 setLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[NOW().date()-1]);   //Default to current day of month, with 1st being 0 entry
 setLaunchRepeatMonthlyOptions_2(MONTH_REPEAT_OPTIONS_2[0]);
 setLaunchRepeatMonthlyOptions_3(MONTH_REPEAT_OPTIONS_3[NOW().day()]);  //Default to current day of week, with Sunday being 0 entry
 setLaunchRepeatUntilDate(SIX_MONTHS_FROM_NOW());               
 setLaunchMonthOption1Checked(true);
 setLaunchMonthOption2Checked(false);
 setAllDayEvent(false);

 //Init any challenge preferences for the conectivity
 if (conectivityToProcess.isChallenge && conectivityToProcess.challengeOptions) {
	 const tempChallengeOptions = JSON.parse(conectivityToProcess.challengeOptions);
	 setChallengeOptions({isChallenge:true, ...tempChallengeOptions});
 } else 
	 setChallengeOptions({isChallenge:false, isAnonymous:false, inputEval : 'CUMULATIVE', inputDataType:'INTEGER'});

  // Reset state data
 setSelectedUsersOptions([]);
 setSelectedTeamsOptions([]);
 setInviteMessage("");
 setShowPicker(false);
 setShowSearchExperienceMessage(false);
 setMessageGif(null);
 setShowSearchExperienceSpotlight(false);
 setShowModalMaster(true);   //Render our modal!
}  

async function initializeEditInviteToConect({conectivityToProcess, launchRuleToEdit}) {

 if (DEBUG_MODE >= 2) console.log("LAUNCHER: editing existing connection", conectivityToProcess, launchRuleToEdit, launchRuleInstanceStartDateTime, launchRuleInstanceIndex);

 if (!conectivityToProcess ||  !launchRuleToEdit) {
	 console.log("Error - imporoper params");
	 return;
 }
 
 //Preload Calendar data 
 setEventStartDateTime(moment(launchRuleToEdit.eventStartDateTime));               //Used to hold the DatePicker Date value for when a conectivity is to be launched
 setEventEndDateTime(moment(launchRuleToEdit.eventEndDateTime));               //Used to hold the DatePicker Date value for when a conectivity is to be launched


	 
 setLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[launchRuleToEdit.launchRepeatOption-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
 setLaunchRepeatMonthlyOptions(MONTH_REPEAT_OPTIONS[launchRuleToEdit.launchRepeatMonthlyOptions-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
 setLaunchRepeatMonthlyOptions_2(MONTH_REPEAT_OPTIONS_2[launchRuleToEdit.launchRepeatMonthlyOptions_2-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
 setLaunchRepeatMonthlyOptions_3(MONTH_REPEAT_OPTIONS_3[launchRuleToEdit.launchRepeatMonthlyOptions_3-1]);      //Used to hold any selected Repeat value for a conectivity is to be launched
 setLaunchRepeatUntilDate(moment(launchRuleToEdit.launchUntilDate,"YYYY MM DDTHH mm ssZ"));               //Used to hold the DatePicker Date value for when a conectivity is to be launched
 setLaunchMonthOption1Checked(launchRuleToEdit.launchMonthOption1Checked);
 setLaunchMonthOption2Checked(launchRuleToEdit.launchMonthOption2Checked);
 setAllDayEvent(launchRuleToEdit.allDayEvent);

 
 //Set days of week for weekly options, if they exist
 var tempWeeklyOptions = [];
 for (var j = 0; j < launchRuleToEdit.launchRepeatWeeklyOptions.length; j++){  
	 tempWeeklyOptions.push(WEEK_REPEAT_OPTIONS[launchRuleToEdit.launchRepeatWeeklyOptions[j]-1]);  //Add day option to inital selections 
 }
 console.log ("Created day of week selections:", tempWeeklyOptions);
 setLaunchRepeatWeeklyOptions(tempWeeklyOptions);      

 //If the Launch Rule has one or more users, build the users options, which won't include the current user 
 var tempSetSelectedUsersOptions = [];
 for (var j = 0; j < launchRuleToEdit.usersToInvite.length; j++){  
	 if (DEBUG_MODE >= 2) console.log("Pre-loading USER ",launchRuleToEdit.usersToInvite[j]);
	  //Look in the array of USER Options to find the dropdown option for the user
	 const userOption = participatingUsersOptions.find(userOption => userOption.id === launchRuleToEdit.usersToInvite[j]);
	 if (userOption) {
		 tempSetSelectedUsersOptions.push(userOption);  //Add user to inital selections 
		 console.log ("Pushed user onto selections:", userOption);
	 } else if (DEBUG_MODE >= 2) console.log("User not found -  skipped; may be current user", launchRuleToEdit.usersToInvite[j], participatingUsersOptions);
 }
 setSelectedUsersOptions(tempSetSelectedUsersOptions);

 //If the Launch Rule has one or more TEAMS, build the teams options
 var tempSetSelectedTeamsOptions = [];
 if (launchRuleToEdit.teamsToInvite && launchRuleToEdit.teamsToInvite.length >0) {
	 for (const teamID of launchRuleToEdit.teamsToInvite){  
		 if (DEBUG_MODE >= 2) console.log("Pre-loading TEAM ",teamID);
		 //Look in the array of TEAMS Options to find the dropdown option for the team 
		 const teamOption = teamsOptions.find(teamOption => teamOption.id === teamID);
		 if (teamOption) {
			 tempSetSelectedTeamsOptions.push(teamOption);  //Add team to inital selections 
			 if (DEBUG_MODE >= 2) console.log ("Pushed team onto selections:", teamOption);
		 } else if (DEBUG_MODE >= 1) console.log("Team not found -  skipped; may be current user", teamID, teamsOptions);
	 }
 }
  setSelectedTeamsOptions(tempSetSelectedTeamsOptions);
  
  //Now, set the message and any GIF
  if (launchRuleToEdit.message) setInviteMessage(launchRuleToEdit.message); else setInviteMessage("");
  if (launchRuleToEdit.messageGIF) {
	 const gifId = launchRuleToEdit.messageGIF.slice(GIPHY_ID_IDENTIFIER.length); 
	 if (DEBUG_MODE >= 2) console.log("Extracted GIF ID", gifId);
	 const giphyObject = await gf.gif(gifId);
	 if (giphyObject) {
		 if (DEBUG_MODE >= 2) console.log("Fetched Gif", giphyObject);
		 setMessageGif(giphyObject.data); 
	 }
  } else setMessageGif(null) ;

 setShowPicker(false);

 // Reset Emoji picker and GIPHY state data - Note, not really neaded as these are not displayed (editable) when editing an existing invitation
 // These are only used for a future launch of a conectivity so just displayed when launching or editing a launch rule
 setInviteMessage("");
 setShowPicker(false);
 setShowSearchExperienceMessage(false);
 setMessageGif(null);
 setShowSearchExperienceSpotlight(false);
 setRecurrenceOption('ALL');
 
 //Initialize Recurrence edit mode for recurring events
 if (launchRuleToEdit.launchRepeatOption !== 1){
	 if (DEBUG_MODE >= 2) console.log("Recurring event invitation to edit",launchRuleToEdit, invitationToUpdate);
	 setShowModalRecurrenceSelection(true); //Render an initial prompt to query user regarding editing the entire series or just the instance
	 return;
 }  

  //challenge settings
  let challengeOptions = {};
  if (launchRuleToEdit.options) {
	 const tempOptions = JSON.parse(launchRuleToEdit.options);
	 if (tempOptions && tempOptions.challengeOptions) challengeOptions = tempOptions.challengeOptions;
	 if (DEBUG_MODE) console.log("Extracted challenge options", challengeOptions);
  }
  setChallengeOptions(challengeOptions); 

 //Handle AUTO SAVE, if requested
 if (autoSave && launchRuleToEdit.launchRepeatOption === 1) {
	 if (DEBUG_MODE >= 2) console.log("In response to DRAG or DECLINE, initiating auto-save for non-recurring launchRuleToEdit", autoSave, autoRSVP, autoRsvpStatus, (autoSaveEventStartDateTime ? autoSaveEventStartDateTime.toISOString() : null), (autoSaveEventEndDateTime ? autoSaveEventEndDateTime.toISOString() : null), launchRuleToEdit);
	 setTriggerAutoSave(uuidv4());
 } else setShowModalMaster(true);   //Render the main launcher modal
}


//Use Effect for handling a toggle that triggers auto-saving the current params without displaying a modal to the user
useEffect (() => {
 if (autoSave && (autoRSVP || launchRuleToEdit.launchRepeatOption === 1)) {
	 if (DEBUG_MODE >= 2) console.log("Auto saving launch rule", autoSave, autoRSVP, (autoSaveEventStartDateTime ? autoSaveEventStartDateTime.toISOString() : null), (autoSaveEventEndDateTime ? autoSaveEventEndDateTime.toISOString() : null), launchRuleToEdit);
	 invokeAutoSave();
 }
},[triggerAutoSave]);


const invokeAutoSave = async () => {
 await handleConfirmationInviteToConect();  // Invoke save and send params to our Lambda without popping up edit modal
};    

const handleRecurrenceSelection = (event) => {
 
 setRecurrenceOption(event.target.value);
 
};

//Handle Recurrence Check Modal - user selected an instance a recurring conectivity; we asked whether they want to edit (or decline) the
//series or the specific instance
async function handleConfirmationRecurringCheck(e) {
 
 if (e) e.preventDefault();

  // close the recurrence selection modal
 setShowModalRecurrenceSelection(false);
 
 //process the confirmation
 
 //Was this process initiated by an RSVP or an EDIT?
 if (autoRSVP) {
	 if (DEBUG_MODE >= 2) console.log("User is DECLINING a recurring conectivity.  Processing recurrence check modal confiramtion", recurrenceOption);
 }

 if (DEBUG_MODE >= 2) console.log("User is EDITING a recurring conectivity.  Processing recurrence check modal confiramtion",recurrenceOption, launchRuleInstanceStartDateTime, launchRuleInstanceIndex,);

 //Did the user elect to edit or RSVP to only a single instance? If so, override the launch rules existing launch repeat option and set to no recurrence
 //If elected to edit a SINGLE INSTANCE, the pre-load calendar params based on selected instance DATE, adjust the start and end times
 if (recurrenceOption === 'SINGLE' && launchRuleInstanceStartDateTime) {
	 //Set to NO Recurrence
	 setLaunchRepeatOption(CALENDAR_REPEAT_OPTIONS[0]);      //Set to NO RECURRENCE as the user is editing a specific instance
	 // Set the event start & end date/time to the day of this INSTANCE
	 // Same as the original LR, just an event start date/time & event end date/time that is 1 day PASSED the
	 const launchRuleInstanceStartDateTimeMom = moment(launchRuleInstanceStartDateTime);
	 
	 //Init to the start date/time for this instance to be the same as the original time
	 let newEventStartDateTime = moment(launchRuleToEdit.eventStartDateTime);
	 
	 //Now set the new start date, leaving the time itself unchanged
	 newEventStartDateTime.set('date', launchRuleInstanceStartDateTimeMom.date());
	 newEventStartDateTime.set('month', launchRuleInstanceStartDateTimeMom.month());
	 newEventStartDateTime.set('year', launchRuleInstanceStartDateTimeMom.year());
	 
	 //Init to the end date/time for this instance to be the same as the original end time
	 let newEventEndDateTime = moment(launchRuleToEdit.eventEndDateTime);
	 //Now init the new end date to the same as the start, leaving the time itself unchanged
	 newEventEndDateTime.set('date', launchRuleInstanceStartDateTimeMom.date());
	 newEventEndDateTime.set('month', launchRuleInstanceStartDateTimeMom.month());
	 newEventEndDateTime.set('year', launchRuleInstanceStartDateTimeMom.year());

	 //Finally, adjust the end time to have the same day duration as the original event in case this was a multi-day event
	 if (launchRuleToEdit && launchRuleToEdit.allDayEvent && launchRuleToEdit.eventStartDateTime && launchRuleToEdit.eventEndDateTime) {
		 const originalEventDurationInDays = moment(launchRuleToEdit.eventEndDateTime).diff(moment(launchRuleToEdit.eventStartDateTime),'days');
		 if (DEBUG_MODE >= 2) console.log("All day event.  Determined duration in days", newEventStartDateTime.toISOString(), newEventEndDateTime.toISOString());

		 //Updating end date for this instance               
		 if (originalEventDurationInDays > 0) {
			 newEventEndDateTime.add(originalEventDurationInDays,'days');
		 }
	 }


	 setEventStartDateTime(newEventStartDateTime);
	 setEventEndDateTime(newEventEndDateTime);
	  if (DEBUG_MODE >= 2) console.log("Initialized instance date/time:", newEventStartDateTime.toISOString(), newEventEndDateTime.toISOString());
 }

 
 // AUTO-SAVE AVAILABLE ONLY WHEN USER CHOSE TO BREAK OUT A SINGLE INSTANCE FOR RECURRING EVENTS; OTHERWISE USER MUST REVIEW THE RRULE OPTIONS
 //Otherwise, the we will have the user edit the full (existing) LR settings, which will ignore the drop date for any drag and drop
 //However, for DECLINE, we will always trigger the autoSave functionality as the user won't 'Edit' the LR at all
 if (autoRSVP || (recurrenceOption === 'SINGLE' && autoSave)){
	 setTriggerAutoSave(uuidv4());
 } else setShowModalMaster(true);   //Render the main launcher modalsetShowModalMaster(true);
}

/*Main handler that is invoked when the user: 
 (1) sends a new calendar invitation (LR), 
 (2) edits and existing calendar inviation or 
 (3) RSVPs to an existing calendar invitation
*/
async function handleConfirmationInviteToConect(e) {

 if (e) e.preventDefault();
 
 //Safety Check 
 if (!showModalSendInviteToConect && !showModalEditInviteToConect) {
	 if (DEBUG_MODE >= 2) console.log("ERROR - No Invite To Conect modal set", scheduledConectivityToProcess);
	 return;
 }
 
 setShowSpinner(true);
 
 if (DEBUG_MODE >= 2) console.log("Updating this user's invites for this particular scheduled conectivity:", scheduledConectivityToProcess);
 
 //Struct to cache users that where already on an existing invitation and, thus already received a notification so we don't want to send another for a change that doesn't affect them
 let successFlag, message;

 try {


	 //Is the user simply RSVPing to an existing invitation?
	 if (autoRSVP) {
		  if (DEBUG_MODE >= 2) console.log("Sending RSVP");

		 successFlag = await invokeRsvpToInviteToConect ({
			 senderID:currentUser.id,                        //User sending the RSVP
			 customerID:currentUser.customerID,                        
			 launchRuleID:launchRuleToEdit.id,
			 invitationID:(invitationToUpdate ? invitationToUpdate.id : null), 
			 launchRuleInstanceIndex:launchRuleInstanceIndex, 
			 rsvpStatus:autoRsvpStatus,
		 });
		  let localMessage = "";
		  if (autoRsvpStatus === "DECLINED") localMessage = "We declined the invitation.";
		  if (autoRsvpStatus === "ACCEPTED") localMessage = "We accepted the invitation.";
		  if (autoRsvpStatus === "MAYBE") localMessage = "We said you were a maybe....";
		  if (autoRsvpStatus === "REMOVED") localMessage = "We declined the invitation and removed it from your caledar.";
		  
		  // 10-24-23 - REMOVED to make RSVPs seamless for the user
		 //  message = (successFlag ? "RVSP sent. " + localMessage : "Hmm...something went wrong...");   
		  
	 } else {
	 
		 
		 //Determine least common denominator of invitees, i.e., the full, unique list of individuals to invite based on all selected teams and individuals
		 // Function call returns an object with three arrays: userIDs, teamIDs, and user emails 
		 const usersAndTeamsToInvite = generateUsersToInvite(
			 {
				 selectedTeamsOptions:selectedTeamsOptions, 
				 selectedUsersOptions:selectedUsersOptions, 
				 users:users, 
				 teams:teams,
				 senderID:currentUser.id,     
				 includeSenderFlag:true,          //Yes, the current user must be included as an invitee in this use case
			 });
			
		 if (DEBUG_MODE >= 2) console.log("Generated users/teams/emails for new or updated InviteToConect", usersAndTeamsToInvite);

		 if (showModalSendInviteToConect) {
			 if (DEBUG_MODE >= 2) console.log("Sending a new InviteToConect");

			 successFlag = await invokeInviteToConect ({
				 sendingUser:currentUser,    //SendingUser is same as CurrentUser when manually launching
				 scheduledConectivityID:(scheduledConectivityToProcess ? scheduledConectivityToProcess.id : null), //Existing SC?  If so, include the ID
				 conectivityID:(enableUserSelectionMode ? conectivityToLaunch.id : conectivityToProcess.id), 
				 usersToInvite:usersAndTeamsToInvite.usersInvitedToParticipate,
				 teamsToInvite:usersAndTeamsToInvite.teamsInvitedToParticipate, 
				 inviteMessage:inviteMessage, 
				 messageGIF:messageGif, 
				 eventStartDateTime:eventStartDateTime, 
				 eventEndDateTime:eventEndDateTime,
				 launchUntilDate:launchRepeatUntilDate, 
				 launchRepeatOption:launchRepeatOption, 
				 launchRepeatWeeklyOptions:launchRepeatWeeklyOptions, 
				 launchRepeatMonthlyOptions:launchRepeatMonthlyOptions,
				 launchRepeatMonthlyOptions_2:launchRepeatMonthlyOptions_2, 
				 launchRepeatMonthlyOptions_3:launchRepeatMonthlyOptions_3, 
				 launchMonthOption1Checked:launchMonthOption1Checked, 
				 launchMonthOption2Checked:launchMonthOption2Checked,
				 allDayEvent:allDayEvent,
				 isMentorMeeting: false,  //This batch launch is not counted as the DEFAULT mentor conectivity
				 isSponsorMeeting: false,
				 attendeeEmailsForCalendar:usersAndTeamsToInvite.attendeeEmailsForCalendar,
				 options:{
					 challengeOptions:challengeOptions //Options as to whether or not this LR is to kick off a challenge
				 },
			 });

			  message = (successFlag ? "Invitation sent!" : "Hmm...something went wrong...");
			 
		 } else if (showModalEditInviteToConect) {
			 if (DEBUG_MODE >= 2) console.log("Updating an existing InviteToConect", scheduledConectivityToProcess, invitationToUpdate);

			 successFlag = await invokeUpdateInviteToConect ({
				 senderID:launchRuleToEdit.senderID,
				 launchRuleID:launchRuleToEdit.id,
				 invitationID:(invitationToUpdate ? invitationToUpdate.id : null), 
				 usersToInvite:usersAndTeamsToInvite.usersInvitedToParticipate,
				 teamsToInvite:usersAndTeamsToInvite.teamsInvitedToParticipate, 
				 inviteMessage:inviteMessage, 
				 messageGIF:messageGif, 
				 eventStartDateTime:(autoSave && autoSaveEventStartDateTime ? autoSaveEventStartDateTime : eventStartDateTime), 
				 eventEndDateTime:(autoSave && autoSaveEventEndDateTime ? autoSaveEventEndDateTime : eventEndDateTime),
				 launchUntilDate:launchRepeatUntilDate, 
				 launchRepeatOption:launchRepeatOption, 
				 launchRepeatWeeklyOptions:launchRepeatWeeklyOptions, 
				 launchRepeatMonthlyOptions:launchRepeatMonthlyOptions,
				 launchRepeatMonthlyOptions_2:launchRepeatMonthlyOptions_2, 
				 launchRepeatMonthlyOptions_3:launchRepeatMonthlyOptions_3, 
				 launchMonthOption1Checked:launchMonthOption1Checked, 
				 launchMonthOption2Checked:launchMonthOption2Checked,
				 allDayEvent:allDayEvent,
				 isMentorMeeting: false,  //This batch launch is not counted as the DEFAULT mentor conectivity
				 isSponsorMeeting: false,
				 attendeeEmailsForCalendar:usersAndTeamsToInvite.attendeeEmailsForCalendar,
				 recurrenceOption:recurrenceOption,
				 launchRuleInstanceStartDateTime:launchRuleInstanceStartDateTime,
				 launchRuleInstanceIndex:launchRuleInstanceIndex,
			 });
			 
			 message = (successFlag ? "We sent an updated invitation!" : "Hmm...something went wrong...");
		 }
		 

		 if (DEBUG_MODE >= 2) console.log("Invitation processed", successFlag, message);
		 
	 } // END IF NOT AUTO RSVP

 } catch (err) {
  message = "Something went wrong. " + err;
 if (DEBUG_MODE >= 2) console.log('error creating invitee and updating invitees:', err);
 }    

 // close the editor
 handleCloseLauncher({
	 result:successFlag,
	 message: message,
 });
 
 setShowModalMaster(false);   //Close our modal!
 setShowModalRecurrenceSelection(false);
 setShowSpinner(false); //Hide spinners
}

async function handleConfirmationDeleteInviteToConect (e) {

 if (e) e.preventDefault();
 if (DEBUG_MODE >= 2) console.log("Confirmation DELETE Invoked", currentUser, launchRuleToEdit, invitationToUpdate);
 
 let successFlag, message;
 
 //Safety Check
 if (currentUser && launchRuleToEdit) {
	 setShowSpinner(true); //Pop up spinners to lock screen
	 
	 try {
		 //Invoke our Lambda function, which will remove the launch rule and any invitation, invitees, etc., if any
		 successFlag = await invokeRemoveInviteToConect({
			 currentUser:currentUser, 
			 launchRuleToDeleteID:launchRuleToEdit.id,
			 recurrenceOption:recurrenceOption,          //Pass in the recurrence selection, if a recurring conectivity
			 launchRuleInstanceStartDateTime:launchRuleInstanceStartDateTime,
			 launchRuleInstanceIndex:launchRuleInstanceIndex,
		 });
		 if (successFlag) message = "Invitation canceled";
		 else message = "Hmmm... Something did not go quite right.";
	 
	 } catch (err) {
		 message = "Something went wrong. " + err;
		 if (DEBUG_MODE >= 2) console.log('error creating invitee and updating invitees:', err);
	 }    

	 setShowSpinner(false); //Hide spinners 
 
 } else {
	 successFlag = false; message = 'Hmmm... Something did not go quite right.';          
 }

 // close the editor
 handleCloseLauncher({
	 result:successFlag,
	 message: message,
 });

 setShowModalMaster(false);   //Close our modal!
 setShowModalRecurrenceSelection(false);
}   


// Giphy search
const SearchComponents = ({giphyGridWidth}) => {
 const { fetchGifs, searchKey } = useContext(SearchContext);       
 var numColumns = 3;      
 if (giphyGridWidth && giphyGridWidth> 450) numColumns = Math.floor(giphyGridWidth /150);
 
 // if (DEBUG_MODE >= 2) console.log("Search components called. giphyGridWidth:", giphyGridWidth, numColumns );
 
 return (
	 <div className="conectereMessagingGiphySearchContainer" style={{width:giphyGridWidth}} onClick={(e)=> {e.stopPropagation();}}>
		 <div style={{padding:"5px 0px"}} >
			 <SearchBar  />
		 </div>
			 <SuggestionBar />                               
		 <div className="conectereMessagingGiphyGridContainer">
				 <GiphyGrid key={searchKey} columns={numColumns} width={giphyGridWidth} noLink={true} fetchGifs={() => fetchGifs()} hideAttribution={true}
					 onGifClick={(gif, e) => {
						e.preventDefault();
						if (DEBUG_MODE >= 2) console.log("gif", gif);
						setShowSearchExperienceMessage(false);
					 //   setShowSearchExperienceSpotlight(false);
						setMessageGif(gif);
				 }}/>
		 </div>
	 </div>
 );
};

async function handleMessageChange(value) {

 //Trap the return key and Launch the conectivity if a return is pressed AND all the input has been provided
 // if (DEBUG_MODE >= 2) console.log('Key to add: ', value);
 var match = /\r|\n/.exec(value);
 if(match && (showModalSendInviteToConect)) {
	 await handleConfirmationInviteToConect();
 } else {        

	 // Dynamically adjust height of our input region as the user types
	 // assume 10px width per 
	 if (conectereMessagingInputRef && conectereMessagingInputRef.current) {
		 var newHeight = (value.length <  (conectereMessagingInputRef.current.scrollWidth/10) && !value.includes("\r") && !value.includes("\n")  ? "30px" : conectereMessagingInputRef.current.scrollHeight.toString() + "px" );
		 setMessageInputBarHeight(newHeight);
		 // if (DEBUG_MODE >= 2) console.log("Message input bar change.  Amount to scroll to bottom", conectereMessagingInputRef.current.scrollHeight, newHeight);
		 // if (DEBUG_MODE >= 2) console.log("Message input bar width", conectereMessagingInputRef.current.scrollWidth);
	 }             

	 //Close GIFPHY Search, if open
	 setShowSearchExperienceMessage(false);
	 // if (DEBUG_MODE >= 2) console.log('Key to add: ', value);

	 setInviteMessage(value);
 }
 
}           

function handleMessageFocus(value) {
 // if (DEBUG_MODE >= 2) console.log("Focus on the comment input bar");
 setShowSearchExperienceMessage(false);            
 setShowPicker(false);
}   

function clearGif() {
 setMessageGif(null);
} 

const eventStartDateTimeChangeHandler = (newDateValue) => {

 //First, set the new start time
 setEventStartDateTime(newDateValue);
 if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());

 //Now, auto-adjust the end time based on the change
 var momentChange = newDateValue.diff(eventStartDateTime.clone().startOf('minute'), 'minutes');      //Calc from the beginning of the minute to avoid rounding errors
 if (DEBUG_MODE >= 2) console.log("User Adjusted start date/time by (minutes):", momentChange);
 
 if (momentChange < 0) momentChange = momentChange-1;
 const tempEndDateTime = eventEndDateTime.clone().startOf().add(momentChange,"minutes");           
 if (DEBUG_MODE >= 2) console.log("New launch END date/time auto-set:", tempEndDateTime.toISOString());
 setEventEndDateTime(tempEndDateTime);

 //If start and end dates are not on the same day then auto set the All Day flag
 //TODO - check this in the user's local TZ?

 if (!(newDateValue.isSame(tempEndDateTime, 'day'))) {
	 if (DEBUG_MODE) console.log("multi-day event detected.  Automatically setting All Day flag");
	 setAllDayEvent(true);
 };
};

const eventEndDateTimeChangeHandler = (newDateValue) => {

 //First, set the new END date/ time
 setEventEndDateTime(newDateValue);
 if (DEBUG_MODE >= 2) console.log("New launch END date/time:", newDateValue.toISOString());

 //Is the new end date BEFORE the start date?  If so, set the start date to match
 
 /* STILL A BUG HERE IN THAT IT ADJUSTS PER KEY STROKE - NEED TO FIND A WAY TO RESOLVE
 if (newDateValue.isBefore(eventStartDateTime)) {
	 if (DEBUG_MODE >= 2) console.log("New launch START date/time: auto-set", newDateValue.toISOString());
	 setEventStartDateTime(newDateValue);
 }
 */

 //If start and end dates are not on the same day then auto set the All Day flag
 //TODO - check this in the user's local TZ?
 if (!(newDateValue.isSame(eventEndDateTime, 'day'))) {
	 if (DEBUG_MODE) console.log("multi-day event detected.  Setting All Day flag");
	 setAllDayEvent(true);
 } else setAllDayEvent(false);
};    

const allDayEventChangeHandler = (e) => {
 if (e) e.stopPropagation();
 if (DEBUG_MODE) console.log("All Day Event Handler Invoked", allDayEvent,eventStartDateTime.toISOString(),eventEndDateTime.toISOString());
 if (allDayEvent) {
	 //user is unchecking the All Day event flag.  Adjust the end date to match the start date
	 if (!eventStartDateTime.isSame(eventEndDateTime, 'day')) {
		 const newEndDateMom = eventEndDateTime.clone();
		 newEndDateMom.year(eventStartDateTime.year()).month(eventStartDateTime.month()).date(eventStartDateTime.date());
		 setEventEndDateTime(newEndDateMom)
		 if (DEBUG_MODE) console.log("user is unchecking the All Day event flag.  Adjust the end date to match the start date", newEndDateMom.toISOString());
	 }
 }
 setAllDayEvent(!allDayEvent);
}

const launchRepeatOptionChangeHandler = (option) => {

 //First, set the option
 setLaunchRepeatOption(option);
 if (DEBUG_MODE >= 2) console.log("New launch repeat option", option);

 //Now, adjust the UNTIL date
 let tempUntilDate;
 if (option && option.value === 'DAILY') 
	 tempUntilDate = NOW().endOf('month');   //If DAILY, just default to the end of the month 
 else 
	 tempUntilDate = SIX_MONTHS_FROM_NOW();  
 setLaunchRepeatUntilDate(tempUntilDate);
}; 


const displayButtonText = () => {
 
 //New conection?
 if (showModalSendInviteToConect) {
	 if (selectedUsersOptions.length === 0 && selectedTeamsOptions.length === 0 ) return 'Add to your calendar'; else return  'Send Invitation';

 }  if (showModalEditInviteToConect) {
	 if (selectedUsersOptions.length === 0 && selectedTeamsOptions.length === 0 ) return 'Update your calendar'; else return  'Update Invitation';
 } else return 'MODAL ERROR';
};    

const closeModalCallback = () => {
 if (DEBUG_MODE >= 2) console.log("Closing modal");
 setShowModalMaster(false);                  //Close our modal!
 setShowModalRecurrenceSelection(false);
 handleCloseLauncher({result:"CANCELED"});   //Callback Parent
};

const handleSelectedConectivity = (e) => {
 if (DEBUG_MODE >= 2) console.log("Conectivity to Schedule Launch Selected", e);
 setSelectedConectivity(e);
 
 if (e) {
	 let tempSelectedConectivity = conectivitiesReadyForLaunch.find(c => c.id == e.id);
	 if (tempSelectedConectivity) {
		 setConectivityToLaunch(tempSelectedConectivity); 
		 //  setEventStartDateTime(NOW_ROUNDED_UP_TO_TEN_MIN());  //10-26-2024 - REMOVED; IT WAS ALREADY INITIALIZED AND MAY HAVE BEEN SUBSEQUENTLY MANUALLY CHANGED BY THE USER
		 setEventEndDateTime(eventStartDateTime.clone().add((tempSelectedConectivity ? tempSelectedConectivity.approvedPaidTime : 10),"minutes"));    //Init, if possible

		 if (DEBUG_MODE >= 2) console.log("Event times", (eventStartDateTime ? eventStartDateTime.toISOString() : 'No Start Time'), (eventStartDateTime ? eventStartDateTime.clone().add((conectivityToProcess ? conectivityToProcess.approvedPaidTime : 10),"minutes").toISOString() : 'No End Time'));

	 }
 } else setConectivityToLaunch(null);
};

//
// Search functions
//

//Use effect for processing updates to the Search Term
//Also invoked if our conectivities have asynchronously updated
useEffect(() => {
 processSearchTermChange();  
//  if (DEBUG_MODE >= 2) console.log("Search term change detected in Use Effect", searchTerm);
}, [searchTerm, activeFilters, conectivitiesReadyForLaunchOptions]);

function processSearchTermChange () {
//  if (DEBUG_MODE >= 2) console.log("Processing search term change", searchActive, activeFilters);
//Did the user CLEAR the input or deactivate it?
if (!searchActive || (searchTerm === '' && (!activeFilters || activeFilters.length === 0))) {
  setConectivitiesReadyForLaunchOptionsFiltered([...conectivitiesReadyForLaunchOptions]); //Make a fresh copy of the master published conectivities options; thus do a reset on the page
  // if (DEBUG_MODE >= 2) console.log("Generated new master list");
} else {
  var tempConectivitiesReadyForLaunchOptions = [...conectivitiesReadyForLaunchOptions]; //Copy Master Options
  var tempConectivitiesReadyForLaunchOptionsFiltered = tempConectivitiesReadyForLaunchOptions.filter((conectivityOption) => {
	 const conectivity = conectivitiesReadyForLaunch.find(con => con.id === conectivityOption.id);
	 if (conectivity) return conectivitiesMatchesSearch({conectivity, activeFilters, keywords:searchTerm});
	 else return false;
	 }); //Filter based on new term
  // if (DEBUG_MODE >= 2) console.log("Generated new filtered list of options based on search term", searchTerm, tempConectivitiesReadyForLaunchOptionsFiltered);
  setConectivitiesReadyForLaunchOptionsFiltered(tempConectivitiesReadyForLaunchOptionsFiltered);
}
} 

const onChangeCallback = useCallback ((value) => {
 setSearchTerm(value);
 if (DEBUG_MODE >= 2) console.log("Search bar CALLBACK called", value);
},[]);

const onFilterChangeCallback = useCallback ((activeFilters) => {
 setActiveFilters(activeFilters);
 if (DEBUG_MODE >= 2) console.log("Search bar QUICK FILTER CALLBACK called", activeFilters);
},[]);

const searchBarIsActiveCallback = useCallback ((active) => {
 setSearchActive(active);
 if (DEBUG_MODE >= 2) console.log("Search bar IS ACTIVE CALLBACK called", active);
},[]);    

/* Handler for Challenge Input */
const challengeHandler = (option) => {
 setChallengeOptions(option);
 // if (DEBUG_MODE >= 2) console.log("New challenge options", option);
};    

function invitationNotReady() {
 if (challengeOptions && challengeOptions.isChallenge && ((!selectedUsersOptions || selectedUsersOptions.length < 1) && (!selectedTeamsOptions || selectedTeamsOptions.length < 1))) return true;
 if (challengeOptions && challengeOptions.isChallenge && !challengeOptions.inputLabel) return true;
 if (challengeOptions && challengeOptions.useGoal && (!challengeOptions.goal || challengeOptions.goal < 1)) return true;
 // console.log("Challenge options", challengeOptions)
 return false;
}

//
// RENDER COMPONENT
//

//Safety check
if (!showModalMaster && !showModalRecurrenceSelection) return null;

return (
 <div>
	 
	  <ModalNoBackgroundFixed showModal={showModalRecurrenceSelection} closeCallback={closeModalCallback} closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR} cardColor={TEAM_COLOR} cardWidth={"500px"} >
		 <div className="ContainerVerticalCenter modalGeneralInnerCard" >
			 <div className="ContainerVerticalCenter modalGeneralCenterColumn" >
				 <h5 > This is a recurring conectivity.   {autoRSVP ? 'How would you like to RSVP?' : 'What would you like to update?'} </h5>
				 <div  className="ContainerNoHeightFlexLeftWrap"   style={{zIndex:"55",  borderRadius:"10px", padding:"0px 10px"}}>
					  <RadioGroup aria-label="recurrenceOption" name="recurrenceOption" value={recurrenceOption} onChange={handleRecurrenceSelection}>
							<FormControlLabel control={<Radio disabled={false}/>} value="SINGLE" label="This event only" />
							<FormControlLabel control={<Radio />} value="ALL" label="All events" /> 
					 </RadioGroup>
				 </div>
				 <div className="modalNoBkgFooter flexEnd">
					 <div className="ContainerNoHeightCenter"> 
						 <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Confirm" onClick={(e) => handleConfirmationRecurringCheck(e)} >  Continue  </button> 
						 <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="Cancel" onClick={closeModalCallback} >  Cancel  </button>  
					 </div>
				 </div>
			 </div>
		 </div>
	 </ModalNoBackgroundFixed >
	 
	 
	 
	 <ModalNoBackground showModal={showModalMaster} hideInnerBorder={true} closeCallback={closeModalCallback} cardColor={TEAM_COLOR} closeButtonClassName={"TextStyle6V2 cardBG"} closeButtonColor={COLOR_SILVER} closeButtonBackground={TEAM_COLOR} innerCloseIcon={true} cardClassName="connectivityLauncherCard" >
		 <div className="modalNoBkgInnerCard" onClick = {(e) =>  e.stopPropagation()}>
			 {/* <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
				 <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt='logo'/>  
			 </div> */}

			 <div className="ContainerMaxHeightCenterFullWidth" style={{margin:"clamp(4px,1vw,10px)"}}>
				 <h4 className ="black-text" > {showModalDeleteInviteToConect ? 'Cancel conectivity?' : 'Connect with your colleagues!'} </h4> 
				 {/* <div className="ccImgXXXXL" style={{marginLeft:"clamp(5px,4vw,20px)", position:"relative", width:"clamp(40px,10vw,100px)"}}>
					  {conectivityToProcess && conectivityToProcess.category && conectivityToProcess.category.label === "STRESS" ?  <img className="avatarImageCenterPortrait" style={{width:'auto', height:"100%"}} src="/img/image-ring-1.png" alt=""/> : null }
					  {conectivityToProcess && conectivityToProcess.category && conectivityToProcess.category.label === "TEAM" ?  <img className="avatarImageCenterPortrait" style={{width:'auto', height:"100%"}} src="/img/image-ring-2.png" alt=""/> : null }
					  {conectivityToProcess && conectivityToProcess.category && conectivityToProcess.category.label === "SOCIAL" ?  <img className="avatarImageCenterPortrait" style={{width:'auto', height:"100%"}} src="/img/image-ring-3.png" alt=""/> : null }
					  {conectivityToProcess && conectivityToProcess.category && conectivityToProcess.category.label === "PERSONAL" ?  <img className="avatarImageCenterPortrait" style={{width:'auto', height:"100%"}} src="/img/image-ring-4.png" alt=""/> : null }
				 </div> */}
			 </div>

				 <ConectereDividerV2 height="3px" />
				 
				  <div className="ContainerNoHeightCenter fullHeight fullWidth" onClick={() => {setForceClosedTrigger1(!forceClosedTrigger1);}}>
					 <div id="newMessageGiphyGridContainerDivID" className="ContainerVerticalLeft conectivity-launcher-scheduling-portion" >
						<div className='ContainerNoHeightCenter fullHeight fullWidth'>
							<div className='ContainerVerticalLeft connectivityRingInfoInnerWrapper'>
								<div className="ContainerVerticalStartStart" style={{zIndex:"99995", position:"relative" }}>
									<div className="ContainerNoHeightCenter">
										<div className="avatarImage avatarImageMedium" style={{ backgroundColor: setHeaderColorByStringLength(currentUser.lastName)}}>
											{!currentUser.avatarUrl  ? 
												<div className="avatarInitialsSmall"> {getUserInitials(currentUser)} </div>: 
												<img className="avatarImageCenterPortrait" src={currentUser.avatarUrl} alt={""} />
											}                                                
										</div>
										<div className="TextStyle5 black-text" style={{paddingLeft:"clamp(5px,4vw,20px)"}}> {getUserName(currentUser)} </div>
									</div> 
								</div>
								<div className ="TextStyle5V2" style={{paddingTop:"clamp(5px,4vw,20px)"}}> Who would you like to invite? </div> 
								<DisplayUserSelectComponentV2 usersOptions={participatingUsersOptions} handleSelectUser={setSelectedUsersOptions} isMulti={true} selectedUsersOptions={selectedUsersOptions} disabled={showModalDeleteInviteToConect} zIndex="99999" />
								<DisplayTeamSelectComponentV2 teamsOptions={teamsOptions} handleSelectTeam={setSelectedTeamsOptions} isMulti={true} selectedTeamsOptions={selectedTeamsOptions} disabled={showModalDeleteInviteToConect}  zIndex="98888"/>

								<hr className="fullWidth light-grey-border opacity1" />

								<div className ="TextStyle5V2 fullWidth" style={{paddingBottom:"10px"}}> When would you like to connect? </div> 
								<div className='ContainerVerticalStartStart'>
									<div className='TextStyle3V2 black-text'>Conectivity Start</div>
									<DisplayDateTimeSelect value={eventStartDateTime} onChange={eventStartDateTimeChangeHandler} disabledDate={showModalDeleteInviteToConect} disabledTime={allDayEvent || showModalDeleteInviteToConect} zIndex='7777' labelTime='' forceClosedTrigger = {forceClosedTrigger1} datepickerContainerStyleObj={{ padding: "8px 0" }} timepickerContainerStyleObj={{ padding: "8px 0" }} />
								</div>
								<div className='ContainerVerticalStartStart'>
									<div className='TextStyle3V2 black-text'>Conectivity End</div>
									<DisplayDateTimeSelect value={eventEndDateTime} onChange={eventEndDateTimeChangeHandler} disabledDate={showModalDeleteInviteToConect} disabledTime={allDayEvent || showModalDeleteInviteToConect} zIndex='6666' labelTime='' forceClosedTrigger = {forceClosedTrigger1} datepickerContainerStyleObj={{ padding: "8px 0" }} timepickerContainerStyleObj={{ padding: "8px 0" }} />
								</div>
								<DisplayAllDayEventSelect value={allDayEvent} onChange={allDayEventChangeHandler} isDisabled={showModalDeleteInviteToConect}  />
							</div>
							{/* RIGHT PORTION OF THE CONECTIVITY LAUNCH SCREEN  */}
							<div className="ContainerVerticalStart conectivity-launcher-preview-portion connectivityRingInfoInnerWrapper" >
								{!enableUserSelectionMode ?  null :       
									<div className="ContainerVertical fullWidth" style={{flex:1, padding:"10px"}}>
										<div className="ContainerNoHeightCenter">
											<div>Which conectivity would you like to schedule?</div> 
										</div>
				
										<SearchInputBar 
											useDarkInput={true} useAnchorPostion={false} enableSearchInput={true} onSearchStringChangeCallback={onChangeCallback} hideSearchButton={true}
											onFilterChangeCallback={onFilterChangeCallback} searchBarIsActiveCallback={searchBarIsActiveCallback} 
											searchTermInitialValue={searchTerm} searchActiveInitialValue={searchActive} quickFilters={QUICK_FILTERS_FOR_CONTECTIVITIES}
											noWrap={false} noBorder={true} hideTextInput={true}
										/>                                           
										<DisplayConectivitySelectComponent name="conectivityDropdown" options={conectivitiesReadyForLaunchOptionsFiltered} onChangeCallback={handleSelectedConectivity} isMulti={false} value={selectedConectivity} disabled={false} />
									</div>
								}
								<DisplayCardConectivityPreview conectivityToPreview={(enableUserSelectionMode ? conectivityToLaunch : conectivityToProcess )} size='medium' hideViewButton={true} />
							</div>
						</div>
						{/* For clarity, hide recurrence option if SINGLE instance of a series; launchRepeatOption.value will be forced to NONE */}
						{recurrenceOption === 'SINGLE' ? null : 
							<DisplayRecurrenceSelect 
								repeatOption={launchRepeatOption}  repeatOptionChangeHandler={launchRepeatOptionChangeHandler}
								weekOption = {launchRepeatWeeklyOptions} weekOptionHandler = {setLaunchRepeatWeeklyOptions}
								monthCheck1 = {launchMonthOption1Checked} monthCheck2 = {launchMonthOption2Checked}
								monthCheck1Handler = {setLaunchMonthOption1Checked} monthCheck2Handler={setLaunchMonthOption2Checked}
								monthOption1 = {launchRepeatMonthlyOptions} monthOption1Handler = {setLaunchRepeatMonthlyOptions}
								monthOption2 = {launchRepeatMonthlyOptions_2} monthOption2Handler = {setLaunchRepeatMonthlyOptions_2}
								monthOption3 = {launchRepeatMonthlyOptions_3} monthOption3Handler = {setLaunchRepeatMonthlyOptions_3}
								repeatUntil = {launchRepeatUntilDate} repeatUntilHandler = {setLaunchRepeatUntilDate}
								isDisabled={showModalDeleteInviteToConect}  
								forceClosedTrigger = {forceClosedTrigger1}
								isHorizontalLine={true}
								isLableText={true}
							/>
							}
					 <hr className="fullWidth light-grey-border opacity1" />
					 <div className="ContainerNoHeightSpaceBetween fullWidth">
						<div className='ContainerNoHeightFlexLeft'>
						<div className ="TextStyle5V2" > Set up a friendly challenge? </div> 
						<HelpButton isHTML hoverTextClassName='TextStyle4 hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_SETUP} className={'light-grey'} noTransform/> 
						</div>

						 <Switch
							 disabled={showModalDeleteInviteToConect || (!challengeOptions && !challengeOptions.isChallenge && (!selectedUsersOptions || selectedUsersOptions.length < 1) && (!selectedTeamsOptions || selectedTeamsOptions.length < 1))} 
							 checked={challengeOptions && challengeOptions.isChallenge ? true : false}
							 onChange={event => {setChallengeOptions({isChallenge: (!challengeOptions.isChallenge), inputEval : CHALLENGE_EVAL_SELECTION_OPTIONS[0].value});}}
							 inputProps={{ 'aria-label': 'controlled' }}
							 className="switch-V2"
						 />
					 </div>                       

					 {!challengeOptions.isChallenge ? null :   
						 <div className="very-light-grey-background borderRadius" style={{margin:"clamp(4px,1vw,10px)", padding:"15px", width:"97%"}}>
							 <DisplayChallengeSelect challengeOptions={challengeOptions}  callback={challengeHandler} disabled={showModalDeleteInviteToConect} />
						 </div>
					 }

					  {/* MESSAGING AREA FOR NEW LAUNCH OR NEW INVITTION FOR SCHEDULED CONECTIVITY  */}
					  
					  {(!showModalSendInviteToConect) ? null : 
						  <>
							 <hr className="fullWidth light-grey-border opacity1" />
							 <div className ="TextStyle5V2 fullWidth" style={{ marginBottom: 10 }}> Include a fun message with the invitation? </div> 
									<div className = "ContainerVerticalStart conectereMessagingInputAreaWrapper fullWidth" >
									  <div className = "conectereMessagingInputAreaInnerWrapper" >
										 {messageGif && (
												<div className="conectereMessagingGiphyStagingAreaWrapper" >
												  <div className="conectereMessagingGiphyStagingArea" style={{backgroundColor:"#f0f0f0"}}>
													 <Gif gif={messageGif} width={200} noLink={true} hideAttribution={true} style={{padding:"5px"}}/>
												  </div>
												  <div className="conectereMessagingGiphyClear" onClick={clearGif}>
													 X
												  </div>                                                    
												</div>
											 )}
			  
										 <div className = "ContainerNoHeightFlexLeft conectereMessagingInputArea justifyStart fullWidth inputContainer" >                                         
												<input
												  ref={conectereMessagingInputRef}
												  // id="conectereMessagingInputDIV"
												  className="commentTextarea"
												  style={{height:messageInputBarHeight, maxWidth:'90%'}}
												  disabled={showModalDeleteInviteToConect }
												  value={inviteMessage}
												  onChange={event => handleMessageChange(event.target.value)}
												  onFocus={event => handleMessageFocus(event.target.value)}
												  placeholder="Add a message"
												  // cols="25" 
												  //   rows="1"
												/>
												<div className="ContainerNoHeightCenter conectereMessagingInputAreaButtonsV2" >
												  <EmojiButton onClickCallback={() => setShowPicker(!showPicker)}  /> 
												  <GIFButtonV2 onClickCallback={() => {
													 if (DEBUG_MODE > 0) console.log("GIFButton Click")
													 setShowSearchExperienceMessage(!showSearchExperienceMessage);
													 setMessageGif(null);}}
												  /> 
												</div>
				 
												{showPicker && !showModalDeleteInviteToConect ?
												  <Picker
													 pickerStyle={{ width: '35%', minWidth:"250px", zIndex: '20', position: "absolute", right: "-15%", top: "-350%", fontSize: '12px'}}
													 onEmojiClick={onEmojiClick}/>
												  : null }
										 </div>  
									  </div>
									  {!showSearchExperienceMessage || showModalDeleteInviteToConect ? null :
										 <SearchContextManager apiKey={GIPHY_SDK_KEY_WEB}>
											 <SearchComponents giphyGridWidth={newMessageGiphyGridContainerDivSize}/>
										 </SearchContextManager>
									  }          
							 </div>
						  </>
					  }

					 { recurrenceOption === 'SINGLE' ? <div className="TextStyle2 blue" ><i>{showModalDeleteInviteToConect ? 'You are DELETING a single event within a series' : 'You are EDITING a single event within a series'}</i></div> : null }   
					 
					 </div>
					 
					 {/* RIGHT PORTION OF THE CONECTIVITY LAUNCH SCREEN  */}
						 {/* <div className="ContainerVerticalStart conectivity-launcher-preview-portion" >

							 {!enableUserSelectionMode ?  null :       

								 <div className="ContainerVertical fullWidth" style={{flex:1, padding:"10px"}}>
										 <div className="ContainerNoHeightCenter">
										 <div>Which conectivity would you like to schedule?</div> 
									 </div>
									 
									 <SearchInputBar 
											 useDarkInput={true} useAnchorPostion={false} enableSearchInput={true} onSearchStringChangeCallback={onChangeCallback} hideSearchButton={true}
											 onFilterChangeCallback={onFilterChangeCallback} searchBarIsActiveCallback={searchBarIsActiveCallback} 
											 searchTermInitialValue={searchTerm} searchActiveInitialValue={searchActive} quickFilters={QUICK_FILTERS_FOR_CONTECTIVITIES}
											 noWrap={false} noBorder={true} hideTextInput={true}
									 />                                           
									 <DisplayConectivitySelectComponent name="conectivityDropdown" options={conectivitiesReadyForLaunchOptionsFiltered} onChangeCallback={handleSelectedConectivity} isMulti={false} value={selectedConectivity} disabled={false} />
								 </div>
							 }

								 <DisplayCardConectivityPreview conectivityToPreview={(enableUserSelectionMode ? conectivityToLaunch : conectivityToProcess )} size='medium' hideViewButton={true} />

						 </div> */}
				 </div>
			 <div className="connectivityLauncherFooter fullWidth">
			 	<hr className="light-grey-border opacity1 fullWidth" />
				 <div className="ContainerNoHeightCenter"> 
						{showModalSendInviteToConect || showModalEditInviteToConect ?  <button className="buttonStyle1-V2 buttonStyle1HeaderBlue" aria-label="Save"  disabled={invitationNotReady()} onClick={(e) => handleConfirmationInviteToConect(e)} > {displayButtonText() } </button> :  null}
						{showModalDeleteInviteToConect ? <button className="buttonStyle1-V2 buttonStyle1Red" aria-label="Delete"  onClick={(e) => handleConfirmationDeleteInviteToConect (e)} > <DeleteOutline /> </button> :  null }
						<button className="buttonStyle1-V2 buttonStyle1DavyGray" aria-label="Cancel" onClick={closeModalCallback} >  { !showModalReadOnlyView ? 'Cancel' : 'Close'}  </button>  

{/*
						{!showModalLaunch ? null : <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Launch"  onClick={(e) => handleConfirmationLaunch(e)} disabled={false}> {(selectedUsersOptions.length === 0 && selectedTeamsOptions.length === 0 ) ? 'Launch for just me' :'Launch!' } </button> }
						{!showModalEditLaunchRule ? null : <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Save"  onClick={(e) => handleConfirmationUpdateLaunchRule(e,false)} > {(selectedUsersOptions.length === 0 && selectedTeamsOptions.length === 0 ) ? 'Update your calendar' :'Update Invitation' } </button> }
						{!showModalDeleteLaunchRule ? null : <button className="buttonStyle1 buttonStyle1Red" aria-label="Delete"  onClick={(e) => handleConfirmationUpdateLaunchRule(e,true)} > Delete </button> }
						{!showModalSendInvitation ? null : <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Send Invitation"  onClick={(e) => handleSendNewOrUpdatedInvitation(e)} disabled={false}> {(selectedUsersOptions.length === 0 && selectedTeamsOptions.length === 0 ) ? 'Add to your calendar' :'Send Invitation!' } </button> }
						{!showModalEditInvitation ? null : <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Edit Invitation"  onClick={(e) => handleSendNewOrUpdatedInvitation(e)} disabled={false}> {(selectedUsersOptions.length === 0 && selectedTeamsOptions.length === 0 ) ? 'Update your calendar' :'Update Invitation' }  </button> }
						{!showModalDeleteInvitation ? null : <button className="buttonStyle1 buttonStyle1Red" aria-label="Save"  onClick={(e) => handleConfirmationDeleteInvitation (e)} > Delete </button> }
*/}                          
				 </div>
			 </div>                    
	 </div>
  </ModalNoBackground> 

  {/*
	  */}
  
</div>
);

}
/*  ,(prevProps, nextProps) => {
 //Our React MEMO function - do not re-render if no conectivity or all the props stay the same
 if (
	 (!prevProps.conectivityToProcess && !nextProps.conectivityToProcess) || 
	 (!prevProps.conectivityToProcess.id && !nextProps.conectivityToProcess.id) || 
	 ((prevProps.conectivityToProcess.id === nextProps.conectivityToProcess.id) &&
	 (prevProps.showModalAdd === nextProps.showModalAdd) &&
	 (prevProps.showModalEdit === nextProps.showModalEdit) &&
	 (prevProps.showModalShare === nextProps.showModalShare) && 
	 (prevProps.showModalEditPost === nextProps.showModalEditPost))
	 ) 
 {
	 if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Editor!", !prevProps.conectivityToProcess, !nextProps.conectivityToProcess, !prevProps.conectivityToProcess.id, nextProps.conectivityToProcess.id, prevProps.conectivityToProcess, nextProps.conectivityToProcess);
	 return true; // props are equal
 }
 if (DEBUG_MODE >= 2) console.log("Re-RENDER the Editor!", prevProps.conectivityToProcess, nextProps.conectivityToProcess);
 return false; // props are not equal -> update the component
} */
);

export default ConectivityLauncher;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  copyright - 2020-2024
//


import "./searchInputBar.css";

//React & Amplify components
import React, { useState, useRef, useEffect, useContext  } from 'react';

//Material UI
import Checkbox from '@mui/material/Checkbox';

//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../data/conectereConfigData';

//Utils
import { QuickFilterButton, SearchButton} from "../../utils/generalUtils";
import { DisplayUserSelectComponent, DisplayTeamSelectComponent, displayUsersRow, displayTeamsRow } from "../../utils/userAndTeamUtils";

//Context
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context


//Search component for top of the page
const SearchInputBar = React.memo(({
    //General props
    buttonPositionLeft, searchContainerAlignedLeft, showHeaders, noWrap, hideSearchButton,
    searchBarIsActiveCallback, zIndex, searchButtonHoverText, minHeight, useAnchorPostion, useDarkInput, clearFiltersOnClose,
    
    //User select input props
    enableUserSelectSearch, usersOptions, handleSelectUser, isMultiUserSelect, selectedUsersOptions, hideTextInput, 

    //Team select input props
    enableTeamSelectSearch, teamsOptions, handleSelectTeam, isMultiTeamSelect, selectedTeamsOptions, 
    
    //search input props
    enableSearchInput, onSearchStringChangeCallback, onFilterChangeCallback, searchTermInitialValue, searchActiveInitialValue, quickFilters, placeholder,
    
    }) => {

    // if (DEBUG_MODE >= 2) console.log("Search bar CALLED", enableSearchInput, quickFilters);
 
     const [searchInputField, setSearchInputField] = useState(searchTermInitialValue);          //local state for holding the field value
     const [searchTermLocal, setSearchTermLocal] = useState('');                                //Search term, as SUBMITTED by the user
     const [searchActiveLocal, setSearchActiveLocal] = useState(searchActiveInitialValue);
     const [activeFiltersLocal, setActiveFiltersLocal] = useState([]);
    //  const [dynamicHeight, setDynamicHeight] = useState('0px');
    //  const [dynamicHeightSummary, setDynamicHeightSummary] = useState('0px');

    
     //References, for dynamic heights
     const searchContainerRef = useRef(null);
     const searchSummaryContainerRef = useRef(null);
 
    // Display context
    const {  innerWindowWidth, innerWindowHeight, } = useContext(DisplayContext); 

     if (!placeholder) placeholder = "keywords";     
     if (!zIndex) zIndex = '99';
     if (!searchButtonHoverText) searchButtonHoverText = 'Search';
     if (!minHeight) minHeight = '75px';

   //Handler for keystrokes   
    const handleChange = (e) => {
        if (e) {
            e.preventDefault();
            if (DEBUG_MODE >= 2) console.log("Search bar change handler called", e.target.value);
            setSearchInputField(e.target.value); //Store the full value of the input field
            // setSearchTermLocal(e.target.value); //Store the full value of the input field
            
            //is this KEY and 'enter' and is the onChange Callback registered?
            // if(e.key === "Enter" && onSearchStringChangeCallback)  onSearchStringChangeCallback(e.target.value, searchActiveLocal);
        }
    };
    
    //Handler for keystrokes   
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
            if (DEBUG_MODE >= 2) console.log("Search bar SUBMIT called", e);
            setSearchTermLocal(searchInputField); //Store the full value of the input field for display to the user as part of the filters

            // onChange Callback registered?
            if(onSearchStringChangeCallback)  onSearchStringChangeCallback(searchInputField, searchActiveLocal);
        }
    };
    
    //Dynamically adjust the height of the search area so as to get smooth scroll 
    /*
    useEffect(() => {

        //Search Input Active?
        if (searchActiveLocal) {
            //Dynamically set the height for a smooth transition
            if (searchContainerRef && searchContainerRef.current && searchContainerRef.current.scrollHeight) {
                const newHeightString = searchContainerRef.current.scrollHeight.toString()+'px';
                setDynamicHeight(newHeightString);
                // if (DEBUG_MODE >= 2) console.log("Search bar: Set new height", newHeightString);
                setDynamicHeightSummary("0px");
                // if (DEBUG_MODE >= 2) console.log("Search bar: Set summary 0 height");
            } //END IF SEARCH_CONTAINER_REF
        } else { 
            const newHeightSummaryString = searchSummaryContainerRef.current.scrollHeight.toString()+'px';
            setDynamicHeightSummary(newHeightSummaryString);
            // if (DEBUG_MODE >= 2) console.log("Search bar: Set new summary height", newHeightSummaryString);

            setDynamicHeight("0px");
            // if (DEBUG_MODE >= 2) console.log("Search bar: Set 0 height");
        } // END IF SEARCH ACTIVE
        
    },[searchActiveLocal, innerWindowWidth, innerWindowHeight ]);     
*/
    function handleSearchClick () {
        
        // if (DEBUG_MODE >= 2) console.log("Search bar: Search click", searchActiveLocal);
        
        //Search currently becoming active 
        if (!searchActiveLocal) {
            
            //Dynamically set the height for a smooth transition
            if (searchContainerRef && searchContainerRef.current && searchContainerRef.current.scrollHeight) {
                // const newHeightString = searchContainerRef.current.scrollHeight.toString()+'px';
                // setDynamicHeight(newHeightString);
                // if (DEBUG_MODE >= 2) console.log("Search bar: Set new height", newHeightString);
            } //END IF SEARCH_CONTAINER_REF
            
        } else { 
            //User closed the search bar
            //Reset filters?
            if (clearFiltersOnClose) {
               setSearchInputField('');
                setSearchTermLocal(""); //Clear search term
                setActiveFiltersLocal([]); //Clear filters
            }
            // setDynamicHeight("0px");
            // if (DEBUG_MODE >= 2) console.log("Search bar: Set 0 height");
        } // END IF SEARCH ACTIVE
        
        setSearchActiveLocal(!searchActiveLocal);
        
        //Tell parent of change
        if (searchBarIsActiveCallback) {
            searchBarIsActiveCallback(!searchActiveLocal);
            // if (DEBUG_MODE >= 2) console.log("Search bar: invoking Search Bar Active callback", !searchActiveLocal, searchBarIsActiveCallback);
        }
    }

    // Display the summary of filters
    const displayFilters = ({activeFiltersLocal,searchTermLocal}) => {
        
        let activeFiltersLocalString = '';
        if (activeFiltersLocal && activeFiltersLocal.length >0) activeFiltersLocal.map (filter => activeFiltersLocalString = activeFiltersLocalString.concat(filter +' , '));
        if (activeFiltersLocalString.length > 0) activeFiltersLocalString = activeFiltersLocalString.slice(0, -3);
        activeFiltersLocalString = searchTermLocal + (searchTermLocal ? ", " : "") + activeFiltersLocalString;
        if (!activeFiltersLocalString || activeFiltersLocalString.length===0) return null;
        return (
            <div className="TextStyle3">
                <span className="purple">match any:</span> <i>{activeFiltersLocalString} </i>
            </div>
        );
    };

    // Callback handler for Quick Filter buttons


    const handleQuickFilter = (changedFilter) => {
         var tempActiveFilters = [...activeFiltersLocal];

         //Is the filer already IN our set of active filters?  If not, add it. If so, remove it
         if (tempActiveFilters.some((f) => (f === changedFilter))) {
             tempActiveFilters = tempActiveFilters.filter((f) => (f !== changedFilter));
            //  if (DEBUG_MODE >= 2) console.log("Removed filter", tempActiveFilters);
        } else {
            tempActiveFilters.push(changedFilter);
            //  if (DEBUG_MODE >= 2) console.log("Added filter", tempActiveFilters);
        }
         
         if (DEBUG_MODE >= 2) console.log("Currently active filters", tempActiveFilters);
         setActiveFiltersLocal(tempActiveFilters);
         //Report the filter status change back to the parent
         if (onFilterChangeCallback) onFilterChangeCallback(tempActiveFilters);
    };
    
    // if (DEBUG_MODE >= 2) console.log("Search bar rendered", searchActiveLocal, searchTermLocal, activeFiltersLocal);

    return (
    <>
    <div className={`ContainerNoHeight fullWidth ${useAnchorPostion ? "searchBarAnchor" : ''}`} style={{position:"relative", zIndex:zIndex}} >
        {!buttonPositionLeft || hideSearchButton ? null :
         <div style={{zIndex:"10"}}>
            <SearchButton onClickCallback={handleSearchClick} hoverText={searchButtonHoverText} fontSize="3.0rem" hoverTextClassName='TextStyle4 blue' />
        </div>
        }

        <div className="ContainerVerticalStart fullWidth" style={{zIndex:"5"}}>

        {!enableUserSelectSearch && !enableTeamSelectSearch ? null : 
                <div ref={searchSummaryContainerRef} className={`searchSummaryContainer fullWidth ${searchActiveLocal ? "searchActive" : ""} `} >
                    <div className='ContainerVerticalLeft'>
                        <div className="ContainerNoHeightFlexLeft">
                            <div style={{paddingLeft:"clamp(4px,1vw,10px)"}}>{displayUsersRow({usersToShow:selectedUsersOptions, numUsersToShow:7})}</div>
                            <div style={{paddingLeft:"clamp(4px,1vw,10px)"}}>{displayTeamsRow({teamsToShow:selectedTeamsOptions, numTeamsToShow:7})}</div>
                            {displayFilters({activeFiltersLocal,searchTermLocal})}
                        </div>
                    </div>
                </div>
        }

            <div className = {`${searchContainerAlignedLeft ? "ContainerNoHeightFlexLeft" : "ContainerNoHeightFlexEndCentered"} fullWidth`} >              
                <div ref={searchContainerRef} className={`searchContainer ${noWrap ? ' overflowHidden fullWidth noBoxShadow ' : ' '} ${useDarkInput ? 'searchContainerDark' : 'searchContainerLight'} ${searchActiveLocal ? "searchActive" : ""}`} >
                    <div className={"ContainerVerticalStart"}>
            
                    {!enableUserSelectSearch ? null : 
                        <div className="ContainerVerticalStartStart fullWidth " style={{zIndex:"20"}}>
                            {!showHeaders ? null : <div className="TextStyle4"><b>Employees</b></div>}
                            <DisplayUserSelectComponent usersOptions={usersOptions} handleSelectUser={handleSelectUser} isMulti={isMultiUserSelect} selectedUsersOptions={selectedUsersOptions} /> 
                        </div>
                    }

                    {!enableTeamSelectSearch ? null : 
                        <div className="ContainerVerticalStartStart fullWidth " style={{zIndex:"10", }}>
                            {!showHeaders ? null : <div className="TextStyle4"><b>Teams</b></div> }
                            <DisplayTeamSelectComponent teamsOptions={teamsOptions} handleSelectTeam={handleSelectTeam} isMulti={isMultiTeamSelect} selectedTeamsOptions={selectedTeamsOptions}  />
                        </div>
                    }
                    
                    {!enableSearchInput ? null : 
                        <div className="ContainerVerticalStartStart fullWidth" >
    
                            {!showHeaders ? null :  <div className="TextStyle4"><b>Conectivity filters</b></div> }
                            
                            {hideTextInput ? null : 
                                <div className="ContainerNoHeightFlexEndCentered fullWidth" >
                                    <form className="fullWidth noBorder" onSubmit={handleSubmit}>
                                            <input
                                                className={`TextStyle3 searchInput ${useDarkInput ? 'searchInputDark' : "blue"} `}
                                                value={searchInputField}
                                                type="text"
                                                onChange={handleChange}
                                                placeholder={placeholder} 
                                                disabled={!searchActiveLocal}
                                            />
                                            {/* !searchActiveLocal ? null : <button onClick={handleGo} style ={{fontSize:"0.8rem", color:"white", backgroundColor:"#82E0AA", padding:"4px", margin:"0px 4px", borderRadius:"10px"}} > GO </button> */}
                                        </form>
                                </div> 
                            }
                            
                            {(!enableSearchInput || !quickFilters || quickFilters.length === 0) ? null : 
                                <div className={`${searchContainerAlignedLeft ? "ContainerNoHeightFlexLeft" : "ContainerNoHeightFlexLeft"} fullwidth ${noWrap ? " scrollX " : " wrap "}`} style={{maxWidth:"100%",padding:"5px 10px"}}>
                                {quickFilters.map((qf,index) => (
                                    <QuickFilterButton key={index.toString()} onClickCallback={handleQuickFilter} onClickCallbackParam={qf} buttonText={qf} /> 
                                    )) }
                                </div>
                            }                                

                            <div > 
                                {displayFilters({activeFiltersLocal,searchTermLocal})}
                            </div>

                        </div>
                    }
                    
                    </div>
                </div>
            </div> 
         </div>

        {buttonPositionLeft || hideSearchButton ? null : 
            <>
                <SearchButton onClickCallback={handleSearchClick} hoverText={searchButtonHoverText} fontSize="3.0rem" hoverTextClassName='TextStyle4 blue' />
                <div style={{paddingRight:"20px"}} />
            </>
        }
      </div>
    </>
    );
},(prevProps, nextProps) => {
        //Our React MEMO function - do not re-render if no change
        if (
            (prevProps.searchActiveInitialValue === nextProps.searchActiveInitialValue) &&
            (prevProps.searchBarIsActiveCallback === nextProps.searchBarIsActiveCallback) &&
            (prevProps.selectedUsersOptions === nextProps.selectedUsersOptions) &&
            (prevProps.selectedTeamsOptions === nextProps.selectedTeamsOptions) &&
            (prevProps.searchTermInitialValue === nextProps.searchTermInitialValue))
        {
            // if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Search bar!",  prevProps.searchActiveInitialValue, nextProps.searchActiveInitialValue, prevProps.searchTermInitialValue, nextProps.searchTermInitialValue);
            return true; // props are equal
        }
        // if (DEBUG_MODE >= 2) console.log("Re-RENDER the Search Bar!", prevProps.searchActiveInitialValue, nextProps.searchActiveInitialValue, prevProps.searchTermInitialValue, nextProps.searchTermInitialValue);
        return false; // props are not equal -> update the component
    });
 
 
 export default SearchInputBar;

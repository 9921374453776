//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';

//React & Amplify
import React from 'react';
// import { API, graphqlOperation } from 'aws-amplify';  - Amplify V5
import { generateClient } from 'aws-amplify/api';   // Amplify V6


//Queries and Mutations
import { getLeaderBoardByCustomerIDShallow } from '../graphql/queries-custom';
import { listRecognitionTypes, listInvoices, getNotificationsByUserByExpirationDate, 
        getConectivity, getChallenge, getChallengeData, getChallengeDataByLeaderboardByUser, getChallengeDataByLeaderboardSpot, listNewsReleases, listSpecialEvents, listContacts, getInvitationByLaunchRule, getMessageByLaunchRule, 
         getCustomer, listCustomers, getGraphTimeSeriesByCustomer, getLeaderBoardByCustomerID, getLeaderBoardSpotsByCustomerID, getLeaderBoardSpotsByCustomerLeaderBoard,
         getGraphByCustomer, getUserAnalyticsByCustomer, getTeamAnalyticsByCustomer, getCustomerAnalyticsByCustomer, getGraphNodesByCustomer, getGraphEdgesByCustomer,
         getScheduledConectivitiesByCustomerByState, scheduledConectivityByState, 
         getLeaderBoardSpot} from '../graphql/queries';
import { getConfigByCustomer, listImages, listConectivities } from '../graphql/queries';
import { processChallengeData, createContact, updateContact, deleteContact, deleteOrder, updateRecognition } from '../graphql/mutations';
import { createCustomer, deleteCustomer, updateCustomer } from '../graphql/mutations';
import { getUser, getUserByCustomer, getUserByCognitoID, listUsers, getUserProgressDataByCustomer } from '../graphql/queries';
import { createUser, deleteUser, updateUser } from '../graphql/mutations';
import { getTeam, listTeams, getTeamUserJoinByUser, getTeamByCustomerID } from '../graphql/queries';
import { createTeam, deleteTeam, updateTeam } from '../graphql/mutations';
import { createUserProgressData, deleteUserProgressData, updateUserProgressData } from '../graphql/mutations';
import { createCCTransaction, updateCCWallet, updateConectereConfig } from '../graphql/mutations';
import { getTeamUserJoin, listTeamUserJoins } from '../graphql/queries';
import { createTeamUserJoin, deleteTeamUserJoin, updateTeamUserJoin } from '../graphql/mutations';
import { listCCTransactions, getCCTransactionsByCustomerIDByTransactionType, getCCTransactionsBytransactionType, getCCTransactionsByWalletIDBySequenceNumber, getCCTransactionsByCreatedAt, getCCTransactionsByCustomerIDByCreatedAt } from '../graphql/queries';
import { getCCWallet, getInvoicesByCustomerByDate, listCategories, listNotificationTypes } from '../graphql/queries';
import { updateConectivity, } from '../graphql/mutations';

//Utils
import { NOW, ONE_YEAR_AGO, NOW_STRING, ONE_MONTH_AGO_STRING, ONE_YEAR_AGO_STRING} from "./dateTimeUtils";

import { v4 as uuidv4 } from 'uuid';    
import moment from 'moment';

//Create our GraphQL Client per Amplify V6
//https://docs.amplify.aws/gen1/react/build-a-backend/troubleshooting/migrate-from-javascript-v5-to-v6/
const API = generateClient(); //Amplify V6

/* QUERIES - GENERIC DATABASE QUERY WRAPPERS */
export  async function getDataRecordById(query, queryFunction, id) {

    let record;
    const queryParams = {
      id: id,
    };
    
    try {
      //Issue our initial query    
    //   record = await API.graphql(graphqlOperation(query, queryParams)); 	REMOVED - V5
         record = await API.graphql({
             query,
             variables: queryParams
         })
 
      if (DEBUG_MODE >= 3) console.log("Get record by ID results", queryParams, record);
      if (record && record.data) {
          if (DEBUG_MODE >= 3) console.log("Query results", record.data[queryFunction]);
          record = record.data[queryFunction];
      }
    } catch (err) {
      if (DEBUG_MODE >= 3) console.log("Error loading record", err, queryParams, queryFunction);
    }
    
    //Return resulting records
    return record;
 }
 
 export  async function fetchRecordsByPrimaryKey(query, queryFunction, primaryKey, primaryKeyValue) {
 
     var recordsData = [];
     var records = [];
     var loopCount = 0;
     
     const queryParams = {
         limit: 1000,
         nextToken: null,
     };
 
     queryParams[primaryKey] = primaryKeyValue;
 
     try {
         //Issue our initial query    
       //   recordsData = await API.graphql(graphqlOperation(query, queryParams)); - REMOVED AMPLIFY V5
           recordsData = await API.graphql({
             query,
             variables: { input: queryParams}
         })
 
         if (DEBUG_MODE >= 2) console.log("Query results", queryParams, recordsData, queryFunction);
         if (DEBUG_MODE >= 2) console.log("Query results", recordsData.data);
         if (DEBUG_MODE >= 2) console.log("Query results", recordsData.data[queryFunction]);
 
         records = recordsData.data[queryFunction].items;
     
         //Update token
         queryParams.nextToken =  recordsData.data[queryFunction].nextToken;
         // if (DEBUG_MODE >= 2) console.log("Query results", queryParams.nextToken, recordsData.data[queryFunction]);
     
         //Repeat until token is null
         while (queryParams.nextToken !== null ) {
             // if (DEBUG_MODE >= 2) console.log("Querying next page");
             // recordsData = await API.graphql(graphqlOperation(query, queryParams));
                 recordsData = await API.graphql({
                     query,
                     variables: { input: queryParams}
                 })
     
             queryParams.nextToken =  recordsData.data[queryFunction].nextToken;
             loopCount++;
             
             //Mutate temp transactions array with pushed values from the spread operator
             records.push(...recordsData.data[queryFunction].items);
             // if (DEBUG_MODE >= 2) console.log("Query results page", records);
         }
             
         } catch (err) {
             if (DEBUG_MODE >= 2) console.log("Error loading record", err, queryParams, queryFunction);
         }
     
     //Return resulting records
     return records;
 
 }
 
 //Generic function for fetching pages from a table using tokens based on passed in query parameters
 export async function queryDataTableWithPagination(query, queryFunction, queryParams){
     
   if (queryParams.limit === undefined) queryParams.limit = 10000;
   
   const LOOPCOUNTMAX = 2000;
   try {
 
     var nextToken = null;
     var dataRecords = [];
     var data = [];
     var loopCount = 0;
     
     //Get initial page 
     queryParams.nextToken = null;
    //  dataRecords = await API.graphql(graphqlOperation(query, queryParams));	REMOVED AMPLIFY V5
     dataRecords = await API.graphql({
         query,
         variables: queryParams
     })	
     if (DEBUG_MODE >= 2) console.log("Query returned", queryParams, queryFunction, dataRecords, nextToken);
 
    //Safety check
    if (!dataRecords || !dataRecords.data || !dataRecords.data[queryFunction]) {
       if (DEBUG_MODE >= 2) console.error("ERROR - improper response", dataRecords);
       return dataRecords;
    }
     // if (DEBUG_MODE >= 2) console.log("Query returned", dataRecords.data[queryFunction]);
     data=dataRecords.data[queryFunction].items;
     nextToken = dataRecords.data[queryFunction].nextToken;
     
     
     
     //Repeat until DB key returned in response is null
     while (nextToken !== null && loopCount <LOOPCOUNTMAX) {
 
         queryParams.nextToken=nextToken;
 
         // if (DEBUG_MODE >= 2) console.log("Querying next page of data");
       //   dataRecords = await API.graphql(graphqlOperation(query, queryParams));	REMOVED AMPLIFY V5
         dataRecords = await API.graphql({
             query,
             variables: queryParams
         })	
         nextToken = dataRecords.data[queryFunction].nextToken;
         loopCount++;
         
         //Mutate temp transactions array with pushed values from the spread operator
         if (dataRecords.data && dataRecords.data[queryFunction]) data.push(...dataRecords.data[queryFunction].items);
         // if (DEBUG_MODE >= 2) console.log("Query results page", dataRecords.Items, nextToken);
     }
     
     return data;
     
   } catch (err) {
     if (DEBUG_MODE) console.error("Error querying data table", err, queryFunction, queryParams);  
     return [];
   }
 }
 
 
/* MUTATIONS - GENERIC DATABASE MUTATION WRAPPERS */

/* Generic function for performing a DB operations
    query - the imported graphQL query
    queryFunction - the name of the query in String form
    params - INPUT fields for the objet being MUTATED (created or updated).  Note, issuing a query queries do not use the 'input' variable
*/
export async function invokeAPI(query, queryFunction, params) {

    let record;
    try {
      //Issue our initial query    
    	//   record = await API.graphql(graphqlOperation(query, { input: params}));
		record = await API.graphql({
			query,
			variables: { input: params}
		})

      if (DEBUG_MODE >= 2) console.log("invokeAPI record results", params, record);
      if (record && record.data) {
          if (DEBUG_MODE >= 2) console.log("Query results", record.data[queryFunction]);
          record = record.data[queryFunction];
      }
    } catch (err) {
      if (DEBUG_MODE >= 2) console.log("Error performing invokeAPI", err, params, queryFunction);
    }
    
    //Return resulting record
    return record;
 }

/* Generic function for getting a single record by it's primary key value 
    query - the imported graphQL query
    queryFunction - the name of the query in String form
    id - ID of the record to GET - primary key value
*/

//Various functions for formulating the query parameters for specific tables

/* CONFIG */
export async function storeUpdatedCustomerConfig(config) {
	if (!config || !config.id) return null;
	if (DEBUG_MODE >= 2) console.log("Writing updated customer config", config);
	return await invokeAPI(updateConectereConfig, 'updateConectereConfig', config);
}

export async function fetchConfigByCustomer (customerID) {
   //  const tempCustomerConfigDetailsData = await API.graphql(graphqlOperation(getConfigByCustomer, {  customerID: customerID, } )); 
	 const tempCustomerConfigDetailsData = await API.graphql({
		query:getConfigByCustomer,
		variables: { input: {  customerID: customerID }}
	})	 
    let tempCustomerConfigDetails = (tempCustomerConfigDetailsData.data.getConfigByCustomer && 
        tempCustomerConfigDetailsData.data.getConfigByCustomer.items &&
        tempCustomerConfigDetailsData.data.getConfigByCustomer.items.length > 0 ?
        tempCustomerConfigDetailsData.data.getConfigByCustomer.items[0] : {} );
    return tempCustomerConfigDetails;
}

/* CUSTOMERS */
export async function fetchCustomer (customerID) {
	if (!customerID) return null;
	return await getDataRecordById(getCustomer,'getCustomer',customerID);
}

export async function fetchCustomers() {
	const queryParams = {  limit: 1000, nextToken: null };
	var records =  await queryDataTableWithPagination(listCustomers, "listCustomers", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched customers', records);
	return records;    
} 


/* USERS */
export async function fetchUser (userID) {
	if (!userID) return null;
	return await getDataRecordById(getUser,'getUser',userID);
}

export async function fetchUserByCognitoID({userCognitoID}) {
    if (!userCognitoID) {
        console.error("Error - no cognito ID for which to fetch the user", userCognitoID);
    }
    const queryParams = {
        userCognitoID: userCognitoID,  //Primary Key
    };
    var userRecords =  await queryDataTableWithPagination(getUserByCognitoID, "getUserByCognitoID", queryParams);
    if (userRecords && userRecords.length > 0) {
        if (DEBUG_MODE >= 2) console.log("Fetched the Cognito user from DynamoDB:", userRecords[0]);
        return userRecords[0];
    } else {
        if (DEBUG_MODE >= 2) console.error("Error - unable to fetch the Cognito user from DynamoDB:", userCognitoID, userRecords);
        return null;
    }
}

export async function fetchUsersByCustomer(customerID){
    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var tempUsers =  await queryDataTableWithPagination(getUserByCustomer, "getUserByCustomer", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched users', tempUsers);
    return tempUsers;
}

export async function storeUpdatedUser(updatedUser) {
	if (!updatedUser || !updatedUser.id) return null;
	if (DEBUG_MODE >= 2) console.log("Writing updated user", updatedUser);
	return await invokeAPI(updateUser, 'updateUser', updatedUser);
}

/* IMAGES */
export async function fetchImages() {
	const queryParams = {  limit: 1000, nextToken: null };
	var records =  await queryDataTableWithPagination(listImages, "listImages", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched images', records);
	return records;    
} 

/* CATEGORIES */
export async function fetchCategories() {
	const queryParams = {  limit: 1000, nextToken: null };
	var records =  await queryDataTableWithPagination(listCategories, "listCategories", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched categories', records);
	return records;    
} 

/* CHALLENGE */
export async function fetchChallenge(challengeID) {
    if (!challengeID) return null;
    return await getDataRecordById(getChallenge, 'getChallenge', challengeID);
}

/* CONECTIVITES */
export async function fetchConectivity(conectivityID) {
    if (!conectivityID) return null;
    return await getDataRecordById(getConectivity, 'getConectivity', conectivityID);
}

export async function fetchConectivities() {
	const queryParams = {  limit: 1000, nextToken: null };
	var records =  await queryDataTableWithPagination(listConectivities, "listConectivities", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched conectivities', records);
	return records;    
} 

export async function fetchScheduledConectivitiesByState({runningState}) {
	if (!runningState) return null;
	const queryParams = {  runningState, limit: 5000, nextToken: null };
	var records =  await queryDataTableWithPagination(scheduledConectivityByState, "scheduledConectivityByState", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched scheduledConectivityByState', records);
	return records;    
} 

export async function fetchScheduledConectivitiesByCustomerByState({runningState, customerID}) {
	if (!runningState || !customerID) return null;
	const queryParams = {  
		customerID, 							//Primary key
		runningState:{eq: 'ACTIVE'},     //Secondary key
		limit: 5000, 
		nextToken: null 
	};
	var records =  await queryDataTableWithPagination(getScheduledConectivitiesByCustomerByState, "getScheduledConectivitiesByCustomerByState", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched scheduledConectivityByCustomerByState', records);
	return records;    
} 

export async function storeUpdatedConectivity(updatedConectivity) {
	if (!updatedConectivity || !updatedConectivity.id) return null;
	if (DEBUG_MODE >= 2) console.log("Writing updated conectivity", updatedConectivity);
	return await invokeAPI(updateConectivity, 'updateConectivity', updatedConectivity);
}

/* RECOGNITIONS */
export async function storeUpdatedRecognition(updatedRecognition) {
	if (!updatedRecognition || !updatedRecognition.id) return null;
	if (DEBUG_MODE >= 2) console.log("Writing updated recognition", updatedRecognition);
	return await invokeAPI(updateRecognition, 'updateRecognition', updatedRecognition);
}

export async function fetchRecognitionTypes() {
	const queryParams = {  limit: 1000, nextToken: null };
	var records =  await queryDataTableWithPagination(listRecognitionTypes, "listRecognitionTypes", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched recognition types', records);
	return records;    
} 

/* TRANSACTIONS */
export async function fetchTransactionsBytransactionType(transactionType) {
	if (!transactionType) return null;
	const queryParams = {  limit: 5000, transactionType: transactionType, nextToken: null };
	var records =  await queryDataTableWithPagination(getCCTransactionsBytransactionType, "getCCTransactionsBytransactionType", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched TransactionsBytransactionType', transactionType);
	return records;  
}

export async function fetchTransactionsyCustomerIDByTransactionType({customerID, transactionType}) {
	if (!transactionType || !customerID) return null;
	const queryParams = {  limit: 5000, transactionType, customerID, nextToken: null };
	var records =  await queryDataTableWithPagination(getCCTransactionsByCustomerIDByTransactionType, "getCCTransactionsByCustomerIDByTransactionType", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched fetchTransactionsyCustomerIDByTransactionType', customerID, transactionType);
	return records;  
}


/* INVOICES */
export async function fetchInvoices() {
	const queryParams = { limit: 5000, nextToken: null };
	var records =  await queryDataTableWithPagination(listInvoices, "listInvoices", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched invoices', records);
	return records;    
} 

export async function fetchInvoicesByCustomerByDate({customerID, fromDateString}) {
	if (!customerID || !fromDateString) return null;
	const queryParams = {  
		customerID, 							//Primary key
		createdAt: {between: [ fromDateString, NOW_STRING() ]},     //Secondary Key
		limit: 5000, 
		nextToken: null 
	};
	var records =  await queryDataTableWithPagination(getInvoicesByCustomerByDate, "getInvoicesByCustomerByDate", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched invoicesByCustomerByDate', records);
	return records;    
} 

/* LEADERBOARDS */
export async function storeUpdatedCustomerLeaderboard(updateCustomerLeaderBoard) {
	if (!updateCustomerLeaderBoard || !updateCustomerLeaderBoard.id) return null;
	if (DEBUG_MODE >= 2) console.log("Writing updated leaderboard", updateCustomerLeaderBoard);
	return await invokeAPI(updateCustomerLeaderBoard, 'updateCustomerLeaderBoard', updateCustomerLeaderBoard);
}


/* NOTIFICATIONS */
export async function fetchNotificationTypes() {
	const queryParams = {  limit: 1000, nextToken: null };
	var records =  await queryDataTableWithPagination(listNotificationTypes, "listNotificationTypes", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched notification types', records);
	return records;    
} 

export async function fetchNotificationsByUser({userID}) {
	if (!userID) return null;
	const todayString = moment().startOf('day').toISOString();
	const queryParams = {  
		userID,           //Primary Key
		expiration: {ge: todayString},    //Secondary key
		limit: 1000,
	 };
	var records =  await queryDataTableWithPagination(getNotificationsByUserByExpirationDate, "getNotificationsByUserByExpirationDate", queryParams);
	if (DEBUG_MODE >= 2) console.log('Fetched notifications by user', records);
	return records;    
} 

export async function fetchActiveScheduledConectivities(){
  
    const queryParams = {
                runningState: 'ACTIVE',  //Primary key
            };
    
    var tempScheduledConectivities =  await queryDataTableWithPagination(scheduledConectivityByState, "scheduledConectivityByState", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetch scheduled transationcs query parameters', queryParams, tempScheduledConectivities);
    return tempScheduledConectivities;
}

export async function fetchActiveScheduledConectivitiesByCustomer(customerID) {
  
    const queryParams = {
        customerID: customerID,         //Primary key
        runningState: {eq: 'ACTIVE'},   //Secondary key
    };
    
    var tempScheduledConectivities =  await queryDataTableWithPagination(getScheduledConectivitiesByCustomerByState, "getScheduledConectivitiesByCustomerByState", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetch scheduled transationcs query parameters',queryParams, tempScheduledConectivities);
    return tempScheduledConectivities;
}

export async function fetchTransactionsByCustomerByDate(customerID, dateStringStart, dateStringEnd){
  
    const queryParams = {
                customerID: customerID,                              //Primary Key
                createdAt: {between: [ dateStringStart, dateStringEnd ]},     //Secondary Key
            };
    
    var tempTransactions =  await queryDataTableWithPagination(getCCTransactionsByCustomerIDByCreatedAt, "getCCTransactionsByCustomerIDByCreatedAt", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetch transactions query parameters',queryParams, tempTransactions);
    return tempTransactions;
}


export async function fetchUserProgressDataByCustomer(customerID){

    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var tempUserProgressDataRecords =  await queryDataTableWithPagination(getUserProgressDataByCustomer, "getUserProgressDataByCustomer", queryParams);

    // if (DEBUG_MODE >= 2) console.log('Fetched userProgressData', tempUserProgressDataRecords);
    
    return tempUserProgressDataRecords;	
}
	
	
export async function fetchTeamsByCustomer(customerID){
  
    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var tempTeams =  await queryDataTableWithPagination(getTeamByCustomerID, "getTeamByCustomerID", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched teams', tempTeams);
    return tempTeams;
}

export async function fetchGraphsByCustomer(customerID){
  
    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var tempGraphs =  await queryDataTableWithPagination(getGraphByCustomer, "getGraphByCustomer", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched customer graphs', tempGraphs);
    return tempGraphs;
}  


export async function fetchGraphTimeSeriesByCustomer(customerID) {

    const queryParams = {
        customerID: customerID,  //Primary Key
    };
    
    var tempGraphs =  await queryDataTableWithPagination(getGraphTimeSeriesByCustomer, "getGraphTimeSeriesByCustomer", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched customer time series graphs', tempGraphs);
    return tempGraphs;    
}


export async function fetchLeaderboardSpotsByCustomer (customerID) {

    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var tempLeaderboardSpots =  await queryDataTableWithPagination(getLeaderBoardSpotsByCustomerID, "getLeaderBoardSpotsByCustomerID", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched this leaderboard SPOTS for this customers', tempLeaderboardSpots);
    return tempLeaderboardSpots;    
}

export async function fetchLeaderboardSpotsByLeaderboard (customerLeaderBoardID) {

    const queryParams = {
        customerLeaderBoardID: customerLeaderBoardID,  //Primary Key
    };
    
    var tempLeaderboardSpots =  await queryDataTableWithPagination(getLeaderBoardSpotsByCustomerLeaderBoard, "getLeaderBoardSpotsByCustomerLeaderBoard", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched this leaderboard SPOTS for this leaderboard', tempLeaderboardSpots);
    return tempLeaderboardSpots;    
}


export async function fetchLeaderboardsByCustomerShallow (customerID) {
    const queryParams = {
        customerID: customerID,  //Primary Key  
    };
    
    var tempLeaderboards =  await queryDataTableWithPagination(getLeaderBoardByCustomerIDShallow, "getLeaderBoardByCustomerIDShallow", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched this leaderboar(s) for this customers', tempLeaderboards);
    return tempLeaderboards;    
}

export async function fetchLeaderboardsByCustomer (customerID) {
    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var tempLeaderboards =  await queryDataTableWithPagination(getLeaderBoardByCustomerID, "getLeaderBoardByCustomerID", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched this leaderboar(s) for this customers', tempLeaderboards);
    return tempLeaderboards;    
}


export async function fetchUserAnalyticsByCustomer(customerID) {

    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getUserAnalyticsByCustomer, "getUserAnalyticsByCustomer", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched user analytics by customer', records); 
    return records;    
}




export async function fetchTeamAnalyticsByCustomer(customerID) {
    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getTeamAnalyticsByCustomer, "getTeamAnalyticsByCustomer", queryParams);
    // if (DEBUG_MODE >= 2) console.log('Fetched team analytics by customer', records);   
    return records;    
}



export async function fetchCustomerAnalyticsByCustomer(customerID) {

    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getCustomerAnalyticsByCustomer, "getCustomerAnalyticsByCustomer", queryParams);

    // if (DEBUG_MODE >= 2) console.log('Fetched customer analytics by customer', records);
    
    return records;    
} 


export async function fetchGraphNodesByCustomer(customerID) {

    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getGraphNodesByCustomer, "getGraphNodesByCustomer", queryParams);

    // if (DEBUG_MODE >= 2) console.log('Fetched graph nodes by customer', records);
    
    return records;    
} 

export async function fetchGraphEdgesByCustomer(customerID) {

    const queryParams = {
            customerID: customerID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getGraphEdgesByCustomer, "getGraphEdgesByCustomer", queryParams);

    // if (DEBUG_MODE >= 2) console.log('Fetched graph edges by customer', records);
    
    return records;    
} 


export async function fetchNewsReleases() {
    const queryParams = {  limit: 1000, nextToken: null };
    var records =  await queryDataTableWithPagination(listNewsReleases, "listNewsReleases", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched news releases', records);
    return records;    
} 

export async function fetchSpecialEvents() {
    const queryParams = {  limit: 1000, nextToken: null };
    var records =  await queryDataTableWithPagination(listSpecialEvents, "listSpecialEvents", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched special events', records);
    return records;    
} 

export async function fetchContacts() {
    const queryParams = {  limit: 5000, nextToken: null };
    var records =  await queryDataTableWithPagination(listContacts, "listContacts", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched contacts', records);
    return records;    
} 
export async function fetchMessagesByLaunchRule(launchRuleID) {

    const queryParams = {
            launchRuleID: launchRuleID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getMessageByLaunchRule, "getMessageByLaunchRule", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched messages by launch rule', records);
    return records;    
} 


export async function fetchInvitationsByLaunchRule(launchRuleID) {

    const queryParams = {
            launchRuleID: launchRuleID,  //Primary Key
        };
    
    var records =  await queryDataTableWithPagination(getInvitationByLaunchRule, "getInvitationByLaunchRule", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched invitations by launch rule', records);
    return records;    
} 


/*  Valid Transaction Types - see schema
    CC_REDEMPTION		
    COMPLETED_CONECTIVITY
    REJECTED_CONECTIVITY
    ADJUSTMENT
    SPOTLIGHT_SENT
    SPOTLIGHT_RECEIVED
    SPOTLIGHT_DELETED   #by admin due to error  
    GIFT_RECEIVED
    GIFT_SENT
  */ 

export async function recordTransaction ({walletOwner, title, memo, amount, transactionType}) {

    //Safety function
    if (!walletOwner || !walletOwner.ccWalletID || !amount || !title || !transactionType ) {
        if (DEBUG_MODE) console.error("Improper params to record transaction",walletOwner, title, memo, amount, transactionType );
        return;
    }

    try {

        //Get current wallet information for walletOwner
        const tempCCWallet = await getDataRecordById(getCCWallet, 'getCCWallet', walletOwner.ccWalletID);
        if (DEBUG_MODE >= 2) console.log("Fetched the recognized walletOwners's wallet", tempCCWallet);

        if (tempCCWallet && tempCCWallet.id && tempCCWallet.currentBalance) {
            //Compute CreatedAt date for transaction since we use it as a database hash key                
            var createdAtTimestamp = moment().toISOString();  // set CreatedAt time according to ISO standard in GMT
        
            var tempTransactionToAdd = {
                createdAt: createdAtTimestamp,
                customerID: walletOwner.customerID,
                userID: walletOwner.id,
                sequenceNumber: tempCCWallet.nextSequenceNumber,          //assign the next open sequence number for this user's wallet
                name: title,
                memo: memo, 
                amount: amount,
                ccWalletID: tempCCWallet.id,
                newBalance:  parseInt(tempCCWallet.currentBalance) + parseInt(amount),    //updated balance at the time this transaction is recorded; ensure input handled as a string
                badgesDEIBalance: tempCCWallet.badgesDEI,   //Badge balance
                badgesCSBalance: tempCCWallet.badgesCS,     //Badge balance                
                transactionType: transactionType,
                image: CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH,     
                timeSeries: 'CONECTERE',                                                                                                 
            };
            

            //Create a new transaction 
            const tempInsertedTransaction = await invokeAPI(createCCTransaction, 'createCCTransaction', tempTransactionToAdd);

            if (tempInsertedTransaction && tempInsertedTransaction.id) {
                if (DEBUG_MODE >= 2) console.log('Successfully wrote new transaction', tempInsertedTransaction);

                //Update user's wallet based on transaction
                const tempCCWalletToUpdate = {
                    id: tempCCWallet.id,
                    currentBalance: tempCCWallet.currentBalance + amount,
                    nextSequenceNumber: tempCCWallet.nextSequenceNumber +1,
                };
                
                //Update wallet record in the backend
                const tempInsertedCCWalletRecord = await invokeAPI(updateCCWallet, 'updateCCWallet', tempCCWalletToUpdate);
                if (DEBUG_MODE >= 2) console.log('Successfully updated user wallet', tempInsertedCCWalletRecord);

                return tempInsertedTransaction;  //Return the transaction upon success
            } else {
                if (DEBUG_MODE >= 2) console.log("Error recording transaction.  Could not create the transaction", tempTransactionToAdd);
            }
        } else {
            if (DEBUG_MODE >= 2) console.log("Error recording transaction.  Walled not found");
        } // END if Wallet  
    } catch (err) {
        if (DEBUG_MODE >= 2) console.log("Error recording transaction" + err);
    }    
}


// CONTACTS
//Various functions for formulating the query parameters for specific tables
export async function invokeDeleteContact(contactID) {
	if (!contactID) {
		console.error("Error - no ID - cannot delete contact", contactID);
		return({successFlag:false});
	}
	const deletedContact = await invokeAPI(deleteContact, 'deleteContact', {id:contactID});
	if (DEBUG_MODE) console.log("Deleted contact:", deletedContact);
	return({successFlag:true, contact:deletedContact});
}

export async function invokeCreateContact (contact) {

	//Safety function
	if (!contact || !contact.email || !contact.contactName ) {
		 if (DEBUG_MODE) console.error("Improper params to create contact",contact );
		 return({successFlag:false});
	}
	
	try {
		var tempContactToAdd = {
			contactName: contact.contactName ,
			title: contact.title ,
			company: contact.company ,         		//Company name
			website: contact.website ,            	//URL to the company website
			numEmployees: contact.numEmployees,       		
			address1: contact.address1 ,
			address2: contact.address2 ,
			city: contact.city ,
			state:contact.state ,
			postalCode: contact.postalCode ,
			country:contact.country ,
			phoneNumber:contact.phoneNumber ,
			email:contact.email ,
			timeZoneCode:contact.timeZoneCode ,         //Timezone code as defined in tz database time zones https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
		
			//Data captured from input form
			message:contact.message ,						//Input message from the new contact when completing our survey
			identifiedBalance: contact.identifiedBalance,
			identifiedTeam: contact.identifiedTeam,
			identifiedSocial: contact.identifiedSocial,
			identifiedGrowth: contact.identifiedGrowth,  
			identifiedDEI: contact.identifiedDEI,
			identifiedCS: contact.identifiedCS,  
			surveyData:contact.surveyData ,		//Additional JSON Stringified data from whatever form we used to collect the contact info		
			hasOptedOut: contact.hasOptedOut,	//Whether the contact has specifically asked to opt out of any announcements             
		};
		

		//Create a new contact 
		const tempInsertedContact = await invokeAPI(createContact, 'createContact', tempContactToAdd);
		if (tempInsertedContact && tempInsertedContact.id) {
			if (DEBUG_MODE >= 2) console.log('Successfully wrote new contact', tempInsertedContact);
			return({successFlag:true, contact:tempInsertedContact});
		} else {
			if (DEBUG_MODE >= 2) console.log("Error creating contact.  Could not create the contact", tempContactToAdd);
		}

	} catch (err) {
		 if (DEBUG_MODE >= 2) console.log("Error creating contact" + err);
	}    
	return({successFlag:false});
}

export async function invokeUpdateContact (contact) {

	//Safety function
	if (!contact || !contact.email || !contact.contactName ) {
		 if (DEBUG_MODE) console.error("Improper params to update contact",contact );
		 return({successFlag:false});
	}
	
	try {
		var tempContact = {
			id:contact.id,
			contactName: contact.contactName ,
			title: contact.title ,
			company: contact.company ,         		//Company name
			website: contact.website ,            	//URL to the company website
			numEmployees: contact.numEmployees,       		
			address1: contact.address1 ,
			address2: contact.address2 ,
			city: contact.city ,
			state:contact.state ,
			postalCode: contact.postalCode ,
			country:contact.country ,
			phoneNumber:contact.phoneNumber ,
			email:contact.email ,
			timeZoneCode:contact.timeZoneCode ,         //Timezone code as defined in tz database time zones https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
		
			//Data captured from input form
			message:contact.message ,						//Input message from the new contact when completing our survey
			identifiedBalance: contact.identifiedBalance,
			identifiedTeam: contact.identifiedTeam,
			identifiedSocial: contact.identifiedSocial,
			identifiedGrowth: contact.identifiedGrowth,  
			identifiedDEI: contact.identifiedDEI,
			identifiedCS: contact.identifiedCS,  
			surveyData:contact.surveyData ,		//Additional JSON Stringified data from whatever form we used to collect the contact info		
			hasOptedOut: contact.hasOptedOut,	//Whether the contact has specifically asked to opt out of any announcements             
		};
		

		//Update contact 
		const tempUpdatedContact = await invokeAPI(updateContact, 'updateContact', tempContact);
		if (tempUpdatedContact && tempUpdatedContact.id) {
			if (DEBUG_MODE >= 2) console.log('Successfully update contact', tempUpdatedContact);
			return({successFlag:true, contact:tempUpdatedContact});
		} else {
			if (DEBUG_MODE >= 2) console.log("Error updated contact.  Could not create the contact", tempContact);
		}

	} catch (err) {
		 if (DEBUG_MODE >= 2) console.log("Error updating contact" + err);
	}    
	return({successFlag:false});
}


	
export async function updateMentorSelection({option, userID}) {
    // if (DEBUG_MODE >= 2) console.log("Updating sponsor selection", option, userID);
    
    //safety check
    if (!userID) return;
    
    //Update user record with sponsor assignment
    var tempUpdatedUser = await invokeAPI(updateUser, "updateUser", {id: userID, mentorID: (option ? option.id : null) } );
    if (DEBUG_MODE >= 2) console.log('Updated user sponsor', tempUpdatedUser);  
    
}


export async function updateSponsorSelection({option, userID}) {
    // if (DEBUG_MODE >= 2) console.log("Updating sponsor selection", option, userID);
    
    //safety check
    if (!userID) return;
    
    //Update user record with sponsor assignment
    var tempUpdatedUser = await invokeAPI(updateUser, 'updateUser',  {id: userID, sponsorID: (option ? option.id : null) } );
    if (DEBUG_MODE >= 2) console.log('Updated user sponsor', tempUpdatedUser);  
    
}

export async function updateDisplayThemeSelection({option, userID}) {
    // if (DEBUG_MODE >= 2) console.log("Updating sponsor selection", option, userID);
    
    //safety check
    if (!userID || !option) {
        if (DEBUG_MODE) console.error("Error - improper params to update display them", option, userID);
        return;
    }
    //Update user record with displayTheme
    var tempUpdatedUser = await invokeAPI(updateUser, 'updateUser',  {id: userID, displayTheme:option });
    if (DEBUG_MODE >= 2) console.log('Updated user displayTheme', tempUpdatedUser);  
}

// ORDERS
export async function invokeDeleteOrder(orderID) {
	if (!orderID) {
		console.error("Error - no ID - cannot delete order", orderID);
		return({successFlag:false});
	}
	const deletedOrder = await invokeAPI(deleteOrder, 'deleteOrder', {id:orderID});
	if (DEBUG_MODE) console.log("Deleted order:", deletedOrder);
	return({successFlag:true, order:deletedOrder});
}

// CHALLENGES
export async function fetchChallengeDataByLeaderboardByUser ({leaderboardID, userID}) {
  
    if (!leaderboardID || !userID) {
        if (DEBUG_MODE) console.error("Error - improper params to fetch challenge data by leaderboard by user", leaderboardID, userID)
    }
    const queryParams = {
        leaderboardID: leaderboardID,         //Primary key
        userID: {eq: userID},               //Secondary key
    };
    
    const challengeData =  await queryDataTableWithPagination(getChallengeDataByLeaderboardByUser, "getChallengeDataByLeaderboardByUser", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched challengeData',queryParams, challengeData);
    return challengeData;
}

export async function fetchChallengeDataByLeaderboardSpot ({leaderboardSpotID}) {
  
    if (!leaderboardSpotID) {
        if (DEBUG_MODE) console.error("Error - improper params to fetch challenge data by leaderboardSpotID", leaderboardSpotID)
    }
    const queryParams = {
        leaderboardSpotID: leaderboardSpotID,         //Primary key
    };
    if (DEBUG_MODE >= 2) console.log('Fetching challengeData by leaderboardSpotID',queryParams);
    const challengeData =  await queryDataTableWithPagination(getChallengeDataByLeaderboardSpot, "getChallengeDataByLeaderboardSpot", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched challengeData',queryParams, challengeData);
    return challengeData;
}

export async function getChallengeByCustomerByStatus({customerID, challengeStatus}) {
  
    if (!customerID || !challengeStatus) {
        if (DEBUG_MODE) console.error("Error - improper params to fetch challenges")
    }
    const queryParams = {
        customerID: customerID,                    //Primary key
        challengeStatus: {eq: challengeStatus},   //Secondary key
    };
    
    const challenges =  await queryDataTableWithPagination(getChallengeByCustomerByStatus, "getChallengeByCustomerByStatus", queryParams);
    if (DEBUG_MODE >= 2) console.log('Fetched challenges',queryParams, challenges);
    return challenges;
}


export async function invokeCreateChallengeData (challengeDataRecord) {

	//Safety function
	if (!challengeDataRecord || !challengeDataRecord.customerID || !challengeDataRecord.userID || !challengeDataRecord.activityDate || !challengeDataRecord.challengeID  || !challengeDataRecord.challengeDataValue) {
		 if (DEBUG_MODE) console.error("Improper params to create challengeDataRecord",challengeDataRecord );
		 return({successFlag:false});
	}
	
	try {
        //Direct call to our Enqueue Command lambda	 
        const params = challengeDataRecord;
		const enqueueCommandInput = {
            customerID:challengeDataRecord.customerID,
            userID:challengeDataRecord.userID,
			command: 'STORE_CHALLENGE_DATA',
			params: JSON.stringify(params),
		};   
		
		//Call GraphQL to directly issue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to CREATE Challenge Data Record", enqueueCommandInput);
		// const response = await API.graphql(graphqlOperation(processChallengeData, { input:enqueueCommandInput})); 	REMOVED AMPLIFY V5
		const response = await invokeAPI(processChallengeData, 'processChallengeData', enqueueCommandInput );
		if (DEBUG_MODE >= 2) console.log("processChallengeData Lambda returned", response);        

		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		let returnParams;
		if (response && response.data && response.data.processChallengeData) {
			//Turn the return string into an object
			if (response.data.processChallengeData.returnParams) returnParams = JSON.parse(response.data.processChallengeData.returnParams);
			if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);

			//Specific error?  If so, return it.
			if (returnParams && !returnParams.successFlag) {
				return ({successFlag:false, challengeData:null, error:returnParams.error});             
			}
			
			//Success? If so, fetch our new challenge data record via App Sync and return the object to the caller
			if (returnParams && returnParams.successFlag && returnParams.id ) {
				const tempNewRecord =  await getDataRecordById(getChallengeData, 'getChallengeData', returnParams.id);
				if (DEBUG_MODE >= 2) console.log('Successfully wrote new challenge data to DB', tempNewRecord);
                let tempUpdatedLBSpot;
                //Now, let's update our leaderboard spot directly in case the subscription does not work
                if (tempNewRecord && tempNewRecord.leaderboardSpotID) {
                    tempUpdatedLBSpot =  await getDataRecordById(getLeaderBoardSpot, 'getLeaderBoardSpot', tempNewRecord.leaderboardSpotID);
                    if (DEBUG_MODE) console.log("Fetched updated spot.  Injecting into Spotcache", tempUpdatedLBSpot);
                }
				return ({successFlag:true, challengeData:tempNewRecord, leaderboardSpot:tempUpdatedLBSpot, error:''});  
			} 
		} 

	} catch (err) {
		 if (DEBUG_MODE >= 2) console.log("Error creating contact" + err);
	}    
	return({successFlag:false});
}

export async function invokeUpdateChallengeData (challengeDataRecord) {

	//Safety function
	if (!challengeDataRecord || !challengeDataRecord.id || !challengeDataRecord.activityDate || !challengeDataRecord.challengeDataValue) {
        if (DEBUG_MODE) console.error("Improper params to update challengeDataRecord",challengeDataRecord );
		 return({successFlag:false});
	}
	
	try {

        //Direct call to our Enqueue Command lambda	 
        const params = challengeDataRecord;
		const enqueueCommandInput = {
            userID: challengeDataRecord.userID,
            customerID:challengeDataRecord.customerID,            
			command: 'UPDATE_CHALLENGE_DATA',
			params: JSON.stringify(params),
		};   
		
		//Call GraphQL to directly issue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to UPDATE CHALLENGE DATA", enqueueCommandInput);
		// const response = await API.graphql(graphqlOperation(processChallengeData, { input:enqueueCommandInput}));
		const response = await invokeAPI(processChallengeData, 'processChallengeData', enqueueCommandInput );
		if (DEBUG_MODE >= 2) console.log("processChallengeData Lambda returned", response);        

		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		let returnParams;
		if (response && response.data && response.data.processChallengeData) {
			//Turn the return string into an object
			if (response.data.processChallengeData.returnParams) returnParams = JSON.parse(response.data.processChallengeData.returnParams);
			if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);

			//Specific error?  If so, return it.
			if (returnParams && returnParams.error && response.data.processChallengeData.lambdaReturnStatus === "FAILED") {
					return ({successFlag:false, customer:null, error:returnParams.error});             
			}
			
			//Success? If so, fetch our updated customer data via App Sync and return the object to the caller
			if (returnParams && response.data.processChallengeData.lambdaReturnStatus === "SUCCESS") {
				const tempRecord =  await getDataRecordById(getChallengeData, 'getChallengeData', challengeDataRecord.id);
				if (DEBUG_MODE >= 2) console.log('Successfully updated challenge Data in DB', tempRecord);
				return ({successFlag:true, challengeData:tempRecord, error:''});  
			} 
		} 

	} catch (err) {
		 if (DEBUG_MODE >= 2) console.log("Error updating challengeDataRecord" + err);
	}    
	return({successFlag:false});
}


export async function invokeDeleteChallengeData (challengeData)  {
    let successFlag;
    
    //Safety check
    if (!challengeData || !challengeData.id) {
       if (DEBUG_MODE >= 1) console.error("Error = improper params to delete challengeData");
       return successFlag;
    }
    
    try {
       var params = {
        challengeDataID: challengeData.id,
       };
       
       const enqueueCommandInput = {
          userID: challengeData.userID,
          customerID:challengeData.customerID,
          command: 'DELETE_CHALLENGE_DATA',
          params: JSON.stringify(params),
       };
          
		//Call GraphQL to directly issue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to UPDATE CHALLENGE DATA", enqueueCommandInput);
		// const response = await API.graphql(graphqlOperation(processChallengeData, { input:enqueueCommandInput}));
		const response = await invokeAPI(processChallengeData, 'processChallengeData', enqueueCommandInput );
		if (DEBUG_MODE >= 2) console.log("processChallengeData Lambda returned", response);        

		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		let returnParams;
		if (response && response.data && response.data.processChallengeData) {
            
			//Turn the return string into an object
			if (response.data.processChallengeData.returnParams) returnParams = JSON.parse(response.data.processChallengeData.returnParams);
			if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);
            return returnParams;  
		} 
	

    } catch (err) {
      if (DEBUG_MODE >= 2) console.log("Error deleting challengeData:", err);
      successFlag=false;
    }
    return successFlag;
 
 }

/* SLACK */
export async function fetchSlackChannels({customerID, userID}) {

	if (!customerID || !userID) return null;
	const enqueueCommandInput = {
		userID,
		customerID,
		command: 'FETCH_SLACK_PUBLIC_CHANNELS',
  };

	const records = await invokeAPI(fetchSlackChannels, 'fetchSlackChannels', enqueueCommandInput);
	return records;    
} 
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//
//
// SPOTLIGHT VERTICAL FEED
//
//
import './spotlightFeed.css';
import { v4 as uuidv4 } from 'uuid';    

//Amplify, React
import React from 'react';

//Our components
import { SpotlightCard } from "../../../../shared/Components/spotlightCard/spotlightCard";

///Component for showing a column of spotlights
export const SpotlightFeed = ({spotlights, callBack, handleDeleteSpotlightClick, handleEditSpotlightClick}) => {

    const rowKey = uuidv4();
    if (!spotlights  || spotlights.length===0) return null;
    
    // if (DEBUG_MODE >= 2) console.log("Spotlight FEED called", spotlights);
    
    return (
     <div style={{position:"relative"}} 
    >

        <div className="spotlightFeed" id={rowKey}  >
            {spotlights.map((spotlight, index) => (
            
                <div key={"outer" + index.toString()} className="spotlightFeedInnerWrapper">
                    <SpotlightCard key={"SpotlightFeed" + spotlight.id} id={index} spotlightToView={spotlight} isSmall={false} isFull={true} handleDeleteSpotlightClick={handleDeleteSpotlightClick} handleEditSpotlightClick={handleEditSpotlightClick}/>
                </div>

            ))}
  
        </div>
     </div>
    );
    
};   
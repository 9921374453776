//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE1.  All Rights Reserved
//


//React Component for detailed view of a scheduled conectivity
//  -- Conectivity Card
//  -- Leaderboard, if any
//	 -- Chat window

import './connectivityInfoViewerStyle.css';

import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, HEADER_FULL_MODE, COLOR_GOLD, COLOR_BLUE_HEADER, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR, COLOR_WHITE,
	CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD, GIPHY_ID_IDENTIFIER  } from '../../data/conectereConfigData';

//Amplify, React
import React, { useCallback, useEffect, useState, useContext, useRef, useLayoutEffect} from 'react';

//Context
import { DisplayContext } from '../../context/displayContext';            
import { LeaderboardContext } from '../../context/leaderboardContext';            //Customer Authentication context
import { SpecialEventsContext } from '../../context/specialEventsContext';            

//Our Components
import { ChatWindow } from "../chatWindow/chatWindow";
import ConectivityDurationTimer from '../conectivityDurationTimer/conectivityDurationTimer';
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import { LeaderBoardList } from "../../Components/leaderboard/leaderboard";
import { Invitations } from "../invitation/invitation";


//Utils
import { getMyInvitations, doesConectivityHaveInvitationForUser, displayInvitees } from "../../utils/invitationUtils";
import { SpecialEventsGraphic, setSpecialEventMultiplier, ConectivityDetailedViewWindowControl,  displayConectivityHeader, setConectivityImageUrlByConectivity, 
		setHeaderColorByScheduledConectivity, setBorderColorClassByCategory, setBackgroundColorClassByCategory, 
		displayScheduledConectivityCardActionsRow, expiredConectivity, 
		setBadgeByScheduledConectivity,
		SpecialEventsBadges} from "../../utils/conectivityUtils";
import {  } from "../../utils/generalUtils";
import parse from "html-react-parser";              //Needed to render HTML from the Editor

//Icons

import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

//MUI
import ModalNoBackgroundFixed from '../modalNoBackgroundFixed/modalNoBackgroundFixed';
import { InvitationsV2 } from '../invitation/invitaionV2';


export const ConnectivityInfoViewer = React.memo(({
	currentUser,
	showScheduledConectivityDetailedView, 
	scheduledConectivity, 
	showInvitations,
	showCompletionButtons,
	showMessages,
	newInvitationCallback, 
	mergeInvitationsCallback, editInvitationCallback, deleteInvitationCallback, declineInvitationCallback, 
	handleCancelDetailedView,
	handleSkipClick,
	handleDidItClick,
	pendingUpdateSCID,
	// invitationToUpdate,
	messagesToShow,
	
}) => {

	// Context
	const { darkMode } = useContext(DisplayContext); 
	const { challengesToDisplay, leaderboardSpotsCache, currentLeaderboard, handleLeaderboardCardSelected, setCurrentLeaderboard, setShowChallengeDataEditor } = useContext(LeaderboardContext);
	const { activeSpecialEvents } = useContext(SpecialEventsContext);

	//Local state
	// const [showChallengeDataEditor, setShowChallengeDataEditor] = useState(false);
	// const [currentLeaderboardSpot,setCurrentLeaderboardSpot] = useState(null);
	const [displayLeaderboard, setDisplayLeaderboard] = useState(false);
	const [leaderBoardSpotsToDisplay, setLeaderBoardSpotsToDisplay] = useState([]);
	// const [currentLeaderBoard, setCurrentLeaderBoard] = useState(null); 				//Cache the specific LB record for the selected display
	const [invitationsWithThisUser, setinvitationsWithThisUser] = useState([]);
	const [closeAllSubMenusToggle, setCloseAllSubMenusToggle] = useState("false"); 	//Flip this state to cause all child invitations to CLOSE any open submenus
	const [idOfSubMenutoDisplay, setIdOfSubMenutoDisplay] = useState("");       		//Since we want to control the sub-menus from the parent Conectivity, 
																												// we need a call back state variable to register which menu was just opened so we can tell the others to close

	const [pendingInvitationUpdate, setPendingInvitationUpdate] = useState(false);    //Internal state var to indicate that this SC has invoked a callback 
																												//that should result in a state change from the backend; used to show spinners or disable input temporarily

	const [chatPortionIsExpanded, setChatPortionIsExpanded] = useState(true);
	const [leaderboardPortionIsExpanded, setLeaderboardPortionIsExpanded] = useState(true);
	const [isSpecialEvent, setIsSpecialEvent] = useState(false);
	const [specialEventHoverText, setSpecialEventHoverText] = useState('');
	const [specialEventGraphicText, setSpecialEventGraphicText] = useState('');

	//Init Data
	useEffect (() => {
		if (!scheduledConectivity || !scheduledConectivity.id || !scheduledConectivity.conectivity || !currentUser) return;

		try {
			// Get an array of invitations for which the user is an INVITEE on this particular Scheduled Conectivity
			// Display each inviation as a different row on the Conectivity Card
			if (DEBUG_MODE) console.log("Initializing SC Detailed View", scheduledConectivity);

			const myInvitations = getMyInvitations(scheduledConectivity, currentUser, true);
			if (DEBUG_MODE) console.log("This user's invitations", myInvitations);
			setinvitationsWithThisUser(myInvitations);

			//Does this SC have a corresponding Leaderboard, if so, load it
			if (myInvitations && myInvitations.length > 0 && myInvitations[0].leaderboardID) {
				if (DEBUG_MODE) console.log("Detected leaaderboard for the conectivity being viewed.  Initializing LB", myInvitations[0].leaderboardID);
				let tempLB, tempSpots = [];
				tempLB = challengesToDisplay.find(lb => lb.id === myInvitations[0].leaderboardID);
				if (tempLB && tempLB.id) tempSpots = leaderboardSpotsCache.filter(lbSpot => lbSpot.customerLeaderBoardID === tempLB.id); 
				setCurrentLeaderboard(tempLB);
				setLeaderBoardSpotsToDisplay(tempSpots);
				setDisplayLeaderboard(tempLB ? true : false);
			} else {
				setCurrentLeaderboard(null);
				setLeaderBoardSpotsToDisplay([]);
				setDisplayLeaderboard(false);
			}   
			//Init the challenge data editor as closed
			setShowChallengeDataEditor(false);

			//Init any Special Event multiplier and graphic
			let tempIsSpecialEvent = (scheduledConectivity.conectivity.isSpecialEvent ? true : false);

			//Does this particular SC matches an active special event?
			if (activeSpecialEvents && activeSpecialEvents.length > 0) {
				let {specialEventMultipler, reason} = setSpecialEventMultiplier({ conectivity:scheduledConectivity.conectivity, activeSpecialEvents });
				if (specialEventMultipler && specialEventMultipler > 1) {
					let multiplierString = specialEventMultipler.toString() + 'X';
					if (DEBUG_MODE > 1) console.log("Set multiplier string", multiplierString);
					tempIsSpecialEvent = true; setSpecialEventHoverText(reason);setSpecialEventGraphicText(multiplierString);									
				} else {
					setSpecialEventHoverText('');setSpecialEventGraphicText('');
				}
			}

			//Update our state
			setIsSpecialEvent(tempIsSpecialEvent);			
							
		} catch (err) {
			if (DEBUG_MODE) console.error("Error initializing leaderboard for SC.", err);
			setCurrentLeaderboard(null);
			setLeaderBoardSpotsToDisplay([]);
			setDisplayLeaderboard(false);
		}

	},[scheduledConectivity, leaderboardSpotsCache]);


	//Pending Invitation Update Timer - execute on any change to the pendingUpdateSCID
	useEffect(() => {
		let timer;
		if (pendingUpdateSCID && pendingUpdateSCID === scheduledConectivity.id) {
			// if (DEBUG_MODE >= 2) console.log("SC setting pending update; starting pending update timer", pendingInvitationUpdate);
			setPendingInvitationUpdate(true); 
			timer = setTimeout(() => { 
				setPendingInvitationUpdate(false); 
				// if (DEBUG_MODE >= 2) console.log("SC pending update timer FIRED", pendingInvitationUpdate);
			}, 5000); //Clear local spinners in 5 seconds if not already cleared
		} else {
			setPendingInvitationUpdate(false); 
			// if (DEBUG_MODE >= 2) console.log("SC - clearing pending update", pendingInvitationUpdate);
		}

		// Clear timeout if the component is unmounted
		return () => {if (timer) clearTimeout(timer)};
	},[pendingUpdateSCID]);    
	
	
	//Safety checks
	if (!handleDidItClick || !handleSkipClick || !handleCancelDetailedView || !currentUser) return
	if (!showScheduledConectivityDetailedView || !scheduledConectivity || !scheduledConectivity.conectivity) return null;

	//Upon load, determine if the screen already has a Y position, i.e, has been scrolled down
	//If so, use that for TOP in our position ABSOLUTE so the modal pops up centered on the current screen rather than back up on the top
	//Note, we have to use position ABSOLUTE so that screen scroll with the page if the user scrolls down to read the rest of the modal
	//Also, we need to read the full height of the entire document and dynamically set the height of our background layer so that it covers the entire document
	//as the user scrolls up/down
	
	const screenPositionY = window.scrollY + 20;
	// const documentHeight = document.body.clientHeight;
	const headerColor = setHeaderColorByScheduledConectivity(scheduledConectivity);
	const borderClass = setBorderColorClassByCategory(scheduledConectivity.conectivity.category.label);
	const badgeTextColor = setBadgeByScheduledConectivity(scheduledConectivity, true);
	const badgeImage = setBadgeByScheduledConectivity(scheduledConectivity);
															  
	// var dynamicLeftPostionInvitationButton = "5px";
	// if (invitationsWithThisUser.length == 0) dynamicLeftPostionInvitationButton  = "50%";    
	
	// if (DEBUG_MODE >= 2) console.log("Detailed View Launch - page scrolled", screenPositionY, documentHeight, invitationsWithThisUser);

	const backgroundClass = setBackgroundColorClassByCategory(scheduledConectivity.conectivity.category.label);
	const conectivitytoDisplay = (scheduledConectivity && scheduledConectivity.conectivity ? scheduledConectivity.conectivity : conectivity);

	return (
		<div hidden={!showScheduledConectivityDetailedView} className='ModelContainer'>
			<div
				hidden={!showScheduledConectivityDetailedView}
				className='ModalBgContainer'
				onClick={handleCancelDetailedView}
				onScroll={(e) => e.stopPropagation()}
			/>
			<div className="ModalOuterCard">
				<div className="ConectivityDetailedViewContainer noBackground noBorder">  
					<ConectivityDetailedViewWindowControl displayChatButton={invitationsWithThisUser && invitationsWithThisUser.length > 0} chatIsButtonActive={chatPortionIsExpanded} chatButtonCallback={()=>{setChatPortionIsExpanded(!chatPortionIsExpanded)}} lbButtonIsActive={leaderboardPortionIsExpanded} lbButtonCallback={()=> {setLeaderboardPortionIsExpanded(!leaderboardPortionIsExpanded)}} displayLbButton={ currentLeaderboard} />
					
					<div className={`ConnectivityInfoViewPaper ContainerNoHeightSpaceAround alignStretch  ${(chatPortionIsExpanded ? "chatExpanded" : "chatClosed")} ${(leaderboardPortionIsExpanded ? "lbExpanded" : "lbClosed")}  `} >


						<div className={`connectivityInfoPortion overflow-visible positionRelative`}  >

							<SpecialEventsBadges badgeImage={badgeImage} textColor={badgeTextColor} isSpecialEvent={isSpecialEvent} specialEventGraphicText={specialEventGraphicText} specialEventHoverText={specialEventHoverText} />

							<div className="ConectivitiesDetailedViewCard connectivityInfoViewCard overflow-visible" onClick = {() => setCloseAllSubMenusToggle(!closeAllSubMenusToggle)} >

								<div className={`connectivityInfoHeaderContainer position-relative fullWidth ${backgroundClass}`}>
									<CloseIcon className="TextStyle6 pointer white connectivityModalCloseIcon" onClick={handleCancelDetailedView} />
									<div className='ContainerNoHeightFlexLeftFlexStart connectivityInfoHeaderInnerWrapper noWrap'>
										<div className='ConnectivityImageWrapper boxShadow'>
											<img src={setConectivityImageUrlByConectivity(conectivitytoDisplay, true)} className="ccImgXXXL" alt=''/>
										</div>
										<div className="ContainerVerticalStartStart connectivityScheduledWrapperGap noWrap">
											<div className="TextStyle7V2" style={{color:'white'}}> 
												{conectivitytoDisplay.title}
											</div>
											<div className='ContainerNoHeightCenter connectivityScheduledWrapperGap'>
												{!scheduledConectivity || (invitationsWithThisUser && invitationsWithThisUser.length > 0) ? null :
													<div className="ContainerNoHeightCenter white-text connectivityRightBorderWrapper">
														<AccessTimeIcon  className="TextStyle5" />
														<ConectivityDurationTimer label="Time Left:" labelClass="TextStyle2V2" closingDateTime={scheduledConectivity.closingDateTime} hideTimeIcon />
													</div>
												}
												{!scheduledConectivity.conectivity.approvedPaidTime ? null : (
													<div className="ContainerNoHeightCenter white-text TextStyle2V2 connectivityRightBorderWrapper">
														<AccessTimeIcon  className="TextStyle5" />
														{scheduledConectivity.conectivity.approvedPaidTime + " min"}
													</div>
												)}
												{!scheduledConectivity.conectivity.conectCoins ? "" :
													<div className="ContainerNoHeightCenter noWrap white-text TextStyle2V2" > 
														<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
														{ scheduledConectivity.conectivity.conectCoins } 
													</div>                        
												}
												{!scheduledConectivity.conectivity.badgesDEI>0 ? "" :  
													<div className="ContainerNoHeightCenter noWrap white-text TextStyle2V2" > 
														<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
														{scheduledConectivity.conectivity.badgesDEI} 
													</div>
												}
												{!scheduledConectivity.conectivity.badgesCS>0 ? "" :  
													<div className="ContainerNoHeightCenter noWrap white-text TextStyle2V2" > 
														<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> 
														{scheduledConectivity.conectivity.badgesCS} 
													</div>
												}
											</div>
										</div>
									</div>
								</div>

								<div className='connectivityInfoContentContainer'>
									<div className="ContainerVerticalStart positionRelative conectivityCardInnerWrapper fullWidth maxHeight" >
										<div className="ContainerVerticalStart" >
											<div className="ConectivityDescriptionWrapperPreview" >
												<div className="TextStyle4_3 black-text" > {scheduledConectivity.conectivity.description}</div>
											</div>
										
											<div className="ConectivityInstructionPreview" >
												{scheduledConectivity.conectivity.instructions==null ? "" : parse(scheduledConectivity.conectivity.instructions)}
											</div>
										</div>
									</div>
								</div>

								<div className="connectivity-actionRow">
									<div className="fullWidth ContainerNoHeightSpaceBetween actionRow-innerContainer">
										<InvitationsV2
											currentUser={currentUser} scheduledConectivity={scheduledConectivity} invitationsWithThisUser={ invitationsWithThisUser} 
											editInvitationCallback={editInvitationCallback} deleteInvitationCallback={deleteInvitationCallback} 
											mergeInvitationsCallback={mergeInvitationsCallback} declineInvitationCallback={declineInvitationCallback}
											newInvitationCallback={newInvitationCallback}
											closeAllSubMenusToggle={closeAllSubMenusToggle} 
											idOfSubMenutoDisplay={idOfSubMenutoDisplay} setIdOfSubMenutoDisplay={setIdOfSubMenutoDisplay} addBottomBorder={false}
											pendingInvitationUpdate={pendingInvitationUpdate} headerColor={headerColor}
										/>
										<div className='ContainerNoHeightFlexLeft'>
											<button className='buttonStyle2 TextStyle2V2 noBoxShadow silver' onClick={(e) => handleSkipClick(e,scheduledConectivity)} id={scheduledConectivity.conectivity.id}>Skip</button>
											<button className='buttonStyle1 connectivity-markAsDoneBtn' onClick={(e) => handleDidItClick(e, scheduledConectivity)}  id={scheduledConectivity.conectivity.id} disabled={expiredConectivity(scheduledConectivity)} >Mark as done</button>
										</div>
									</div>
								</div>

							</div>
						</div>

						{ !invitationsWithThisUser || invitationsWithThisUser.length === 0 ? null : 
							<div className='chatPortion' >
								{!displayLeaderboard ? null : 
									<div className="lboard_wrap white-background borderRadius positionRelative boxShadow" style={{marginBottom:"clamp(2px,0.5vw,4px)"}}> 
										<LeaderBoardList leaderBoardSpots={leaderBoardSpotsToDisplay}  displayZeros={true} leaderBoard={currentLeaderboard} cardSelectedCallback= {handleLeaderboardCardSelected}/>		
									</div>	
								}
								<ChatWindow launchRuleID={invitationsWithThisUser[0].launchRuleID} messagesToShow={messagesToShow} currentUser={currentUser} headerColor={headerColor} />  
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
	
},  (prevProps, nextProps) => {
	//Our React MEMO function - do not re-render if no change 
	if (
		(prevProps.scheduledConectivity === nextProps.scheduledConectivity) &&
		(prevProps.messagesToShow === nextProps.messagesToShow) &&
		(prevProps.pendingUpdateSCID === nextProps.pendingUpdateSCID) &&
		(prevProps.showScheduledConectivityDetailedView === nextProps.showScheduledConectivityDetailedView)
		)
	{
		 // if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the SC Detailed View",  prevProps.scheduledConectivity);
		return true; // props are equal
	}
   //  if (DEBUG_MODE >= 2) console.log("Re-RENDER the SC Detailed View!", prevProps.scheduledConectivity);
	return false; // props are not equal -> update the component
});
  
	
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';
import { generateClient } from 'aws-amplify/api';   // Amplify V6
import React, { useEffect, useState, useContext, useRef } from "react";

//Context
import { AuthContext } from './authContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context

//Queries and Mutations
import { listProductCategories, getProductsByScope,  listProducts, getProductsByCustomer  } from '../graphql/queries';

//Subscriptions
import { onCreateProductByCustomer, onUpdateProductByCustomer, onDeleteProductByCustomer } from "../graphql/subscriptions";
import { onCreateProductByScope, onUpdateProductByScope, onDeleteProductByScope } from "../graphql/subscriptions";

//Utils
import { queryDataTableWithPagination} from "../utils/databaseUtils";
import { compareProductsByTitle } from "../utils/productUtils";


const API = generateClient(); //Amplify V6
const ProductsContext = React.createContext();
const ProductsProvider = ({ children }) => {

   // Authentication context
    const {
           authState, currentUser, isSuperAdmin,
    } = useContext(AuthContext);

   // Customer context
    const {
            usersOptions, selectedCustomerOptions 
    } = useContext(CustomerContext);  

    
    //state variables for managing products for the current user
    //these will all be available to the the wrapped components
    
    const [products, setProducts] = useState([]);
    const [activeProducts, setActiveProducts] = useState([]);
    const [productCategories, setProductCategories] = useState([]);

    // Get data when user authentication changes
    useEffect(() => {
        fetchProducts();
    },[authState]);

    async function fetchProducts () {
 
        if (authState != "signedin" || !currentUser) return;

        //
        //Fetch product categories
        //
        let queryParams;
        
       try {
            

            queryParams ={ limit: 1000  };
            const tempProductCategories  =  await queryDataTableWithPagination(listProductCategories, "listProductCategories", queryParams);
        //   if (DEBUG_MODE >= 2) console.log("Retreived product categories",tempProductCategories);
          
          setProductCategories(tempProductCategories);
          
        //   if (DEBUG_MODE >= 2) console.log('Loaded product categories', tempProductCategories);
          
        } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching product categories', err); }
        
        //
        // Fetch Products
        //

 
         try {

            var products = [];

             //NEW 2/15/2023 - For SuperAdmin, grab ALL products for ALL customers can create, edit and assign products to customers
            if (isSuperAdmin) {
                

                    // gather all product data from backend for ALL customers
                     products =  await queryDataTableWithPagination(listProducts, "listProducts", { limit: 5000 });
                    // if (DEBUG_MODE >= 2) console.log("Fetched SuperAdmin Products", products);

            } else if (currentUser) { //NOT SuperAdmin
            

                // gather product data for just the customer of the current user / selected company
                queryParams ={ customerID: currentUser.customerID, limit: 1000  };
                var privateProducts  =  await queryDataTableWithPagination(getProductsByCustomer, "getProductsByCustomer", queryParams);
                // if (DEBUG_MODE >= 2) console.log("Fetched Products assigned to THIS Customer", privateProducts);                

                //Filter down to just private ones so as to remove any overlap with items fetched below, at least for users in the Conectere account
                if (privateProducts) privateProducts = privateProducts.filter(product => product.productScope == "CUSTOMER_SPECIFIC"); 
                // if (DEBUG_MODE >= 2) console.log("Fetched CUSTOMER_SPECIFIC Products", privateProducts);                

                //Get the product data for default store items as well as gobal    
                queryParams ={ productScope: "DEFAULT", limit: 1000  };
                const defaultProducts  =  await queryDataTableWithPagination(getProductsByScope, "getProductsByScope", queryParams);
                // if (DEBUG_MODE >= 2) console.log("Fetched DEFAULT Products", defaultProducts);                
                
                queryParams ={ productScope: "GLOBAL", limit: 1000  };
                const globalProducts  =  await queryDataTableWithPagination(getProductsByScope, "getProductsByScope", queryParams);
                // if (DEBUG_MODE >= 2) console.log("Fetched GLOBAL Products", defaultProducts);       
    
                products = privateProducts.concat(defaultProducts, globalProducts);
                // if (DEBUG_MODE >= 2) console.log("Formed GLOBAL + DEFAULT + CUSTOMER_SPECIFIC Products:", products);

            }
            
            //Sort the products
            products.sort(compareProductsByTitle);

            //Store or master list of products available for this customer
            setProducts(products);
            // if (DEBUG_MODE >= 2) console.log("Fetched Products ", products);


        } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching products', err); }
         
    }
 
 
    // Main UseEffect for filtering products based on a change to the selected CUSTOMER by the SuperAdmin or to the underlying master data
    useEffect(() => {

        if (authState != 'signedin' || !currentUser) return;
        
        // if (DEBUG_MODE >= 2) console.log("Updating active products", products);

        //Determine the set of active products to be displayed in the store
        
        //Remove any products for which this customer has marked the product INACTIVE
        var tempProducts = products.filter(product => {
            if (!product.inactiveForCustomers) return false;
            return !product.inactiveForCustomers.includes((isSuperAdmin && selectedCustomerOptions ? selectedCustomerOptions.id : currentUser.customerID));
            
        });
        //  if (DEBUG_MODE >= 2) console.log("Filtered products to just active products", tempProducts);
       
        //If SuperAdmin, further filter active products to remove any customer-specific products that are not for the particular company being viewed
        if (isSuperAdmin && (selectedCustomerOptions && (selectedCustomerOptions.label.includes("- ALL -") === false))) {
            
            tempProducts = tempProducts.filter(product => ((product.productScope !== "CUSTOMER_SPECIFIC") || (product.customerID === selectedCustomerOptions.id)));
            
            // if (DEBUG_MODE >= 2) console.log("Filtered active products based on view of SuperAdmin", selectedCustomerOptions, tempProducts);
        }

        // if (DEBUG_MODE >= 2) console.log("Further Filtered products to active products", tempProducts);

        setActiveProducts(tempProducts);
       
    },[selectedCustomerOptions, products]);
    
    
 
    //Set up subscriptions to receive user products upon creation and updates for this user
    // Note, we need to make use either Functional State Data Updates or use Ref() to get access to current values of state data
    // since this UseEffect call is called upon mount (Auth change)
    // https://reactjs.org/docs/hooks-reference.html#functional-updates
    //https://stackoverflow.com/questions/69285519/access-latest-value-of-state-in-aws-amplify-subscriptions-react

    const productsRef = useRef();  //Defining a React Reference so that we can use the current state data during this useEffect call

    useEffect(() => {
      productsRef.current = products;           
    });   //Update on every state change


//
// Subscriptions for GLOBAL Products
//



    //Functions and state data for real-time updates
    const [productGlobalCreateSubscriptionSetUp, setProductGlobalCreateSubscriptionSetUp] = useState(false);  
    const [productGlobalUpdateSubscriptionSetUp, setProductGlobalUpdateSubscriptionSetUp] = useState(false);  
    const [productGlobalDeleteSubscriptionSetUp, setProductGlobalDeleteSubscriptionSetUp] = useState(false); 


  useEffect(() => {
        if (!productGlobalCreateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            if (DEBUG_MODE >= 2) console.log("Setting up subscription for created GLOBAL products");
            
          const subscription = API.graphql({
                query:onCreateProductByScope,
                variables: {
                        productScope: "GLOBAL",
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productGlobal data received via subscription",messageData);
                        if (messageData.data.onCreateProductByScope !== null) {
                            
                            const newProductGlobal = messageData.data.onCreateProductByScope;
                            
                            // if (DEBUG_MODE >= 2) console.log("New productGlobal extracted from message",newProductGlobal);

                            const tempProducts = [...productsRef.current];   //Get our state data for existing products

                            //  if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);

                            tempProducts.push(newProductGlobal);    //Add the new product
                            
                            //Sort the Products      
                            tempProducts.sort(compareProductsByTitle);

                            //Update system-wide state data with the new products
                            setProducts(tempProducts);
                            // if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            // if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);
                            setActiveProducts(tempActiveProducts);
                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing productGlobal create subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductGlobalCreateSubscriptionSetUp(true);
          return () => {
                subscription.unsubscribe();
                setProductGlobalCreateSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs
      
    useEffect(() => {
        if (!productGlobalUpdateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for global products");
            
           const subscription = API.graphql({
                query:onUpdateProductByScope,
                variables: {
                        productScope: "GLOBAL",
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productGlobal UPDATE data received via subscription",messageData);
                        if (messageData.data.onUpdateProductByScope !== null) {
                            
                            const updatedProductGlobal = messageData.data.onUpdateProductByScope;
                            
                            // if (DEBUG_MODE >= 2) console.log("ProductGlobal update extracted from message",updatedProductGlobal);
 
                             const tempProducts = [...productsRef.current];   //Get our state data for existing products

                            //  if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);

                             const index = tempProducts.findIndex(product => product.id == updatedProductGlobal.id);
                             
                             if (index > -1) {


                            //Update system-wide state data with the received object
                            //Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
                            tempProducts[index] = updatedProductGlobal;    //Replace the existing product with the updated one just received
                            
                            //Sort the Products      
                            tempProducts.sort(compareProductsByTitle);

                            //Update system-wide state data with the new products
                            setProducts(tempProducts);
                            // if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            // if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);
                            setActiveProducts(tempActiveProducts);
                            
                             } else {
                                 if (DEBUG_MODE >= 2) console.log("Error - no matching product found to update", updatedProductGlobal, tempProducts);
                             }
 
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing GLOBAL product update subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductGlobalUpdateSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductGlobalUpdateSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs


   useEffect(() => {
        if (!productGlobalDeleteSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            if (DEBUG_MODE >= 2) console.log("Setting up subscription for delete GLOBAL products ");
            
           const subscription = API.graphql({
                query:onDeleteProductByScope,
                variables: {
                        productScope: "GLOBAL",
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        // if (DEBUG_MODE >= 2) console.log("New productGlobal data received via subscription",messageData);
                        if (messageData.data.onDeleteProductByScope !== null) {
                            
                            const deletedProductGlobal = {... messageData.data.onDeleteProductByScope };
                            // if (DEBUG_MODE >= 2) console.log("Deleted product extracted from message",deletedProductGlobal);
                            
                             //Remove the matching launch rule from local state.  Note, this technique does not update the state array directly like "splice" would
                            var tempProducts = productsRef.current.filter(element => element.id != deletedProductGlobal.id);

                            setProducts(tempProducts); //Delete state data with newly sorted list  

                            // if (DEBUG_MODE >= 2) console.log("Deleted global product", tempProducts);

                           //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            if (DEBUG_MODE >= 2) console.log("Active Products after DELETE from Subscription", tempActiveProducts);
                            setActiveProducts(tempActiveProducts);
                            
                         }
                    } catch (err) {
                        // if (DEBUG_MODE >= 2) console.log("Error processing  DELETE global product subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductGlobalDeleteSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductGlobalDeleteSubscriptionSetUp(false);
                if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE global Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs


//
// Subscriptions for DEFAULT Products
//


    //Functions and state data for real-time updates
    const [productDefaultCreateSubscriptionSetUp, setProductDefaultCreateSubscriptionSetUp] = useState(false);  
    const [productDefaultUpdateSubscriptionSetUp, setProductDefaultUpdateSubscriptionSetUp] = useState(false);  
    const [productDefaultDeleteSubscriptionSetUp, setProductDefaultDeleteSubscriptionSetUp] = useState(false); 

  useEffect(() => {
        if (!productDefaultCreateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Setting up subscription for created DEFAULT products");
            
          const subscription = API.graphql({
                query:onCreateProductByScope,
                variables: {
                        productScope: "DEFAULT",
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productDefault data received via subscription",messageData);
                        if (messageData.data.onCreateProductByScope !== null) {
                            
                            const newProductDefault = messageData.data.onCreateProductByScope;
                            
                            // if (DEBUG_MODE >= 2) console.log("New productDefault extracted from message",newProductDefault);

                            const tempProducts = [...productsRef.current];   //Get our state data for existing products

                            // if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);

                            tempProducts.push(newProductDefault);    //Add the new product
                            
                            //Sort the Products      
                            tempProducts.sort(compareProductsByTitle);

                            //Update system-wide state data with the new products
                            setProducts(tempProducts);
                            // if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            // if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);
                            setActiveProducts(tempActiveProducts);

                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing productDefault create subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductDefaultCreateSubscriptionSetUp(true);
          return () => {
                subscription.unsubscribe();
                setProductDefaultCreateSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs
      
    useEffect(() => {
        if (!productDefaultUpdateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for global products");
            
           const subscription = API.graphql({
                query:onUpdateProductByScope,
                variables: {
                        productScope: "DEFAULT",
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productDefault UPDATE data received via subscription",messageData);
                        if (messageData.data.onUpdateProductByScope !== null) {
                            
                            const updatedProductDefault = messageData.data.onUpdateProductByScope;
                            
                            // if (DEBUG_MODE >= 2) console.log("ProductDefault update extracted from message",updatedProductDefault);

                             const tempProducts = [...productsRef.current];   //Get our state data for existing products

                            //  if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);

                             const index = tempProducts.findIndex(product => product.id == updatedProductDefault.id);
                             
                             if (index > -1) {

                                //Update system-wide state data with the UPDATED product
                                tempProducts[index] = {...updatedProductDefault};    //Replace the existing product with the updated one just received
                                
                                //Sort the Products      
                                tempProducts.sort(compareProductsByTitle);
    
                                //Update system-wide state data with the new products
                                setProducts(tempProducts);
                                // if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);
    
                                //Remove any products for which this customer has marked the product INACTIVE
                                var tempActiveProducts = tempProducts.filter(product => {
                                    if (!product.inactiveForCustomers) return false;
                                    return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                    
                                });
                                
                                // if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);
                                setActiveProducts(tempActiveProducts);
                            
                             } else {
                                 if (DEBUG_MODE >= 2) console.log("Error - no matching product found to update", updatedProductDefault, tempProducts);
                             }
 

                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing DEFAULT product update subscription message",messageData);

                    }
                },
                 error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
           });
            
            setProductDefaultUpdateSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductDefaultUpdateSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs

   useEffect(() => {
        if (!productDefaultDeleteSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Setting up subscription for delete DEFAULT products ");
            
           const subscription = API.graphql({
                query:onDeleteProductByScope,
                variables: {
                        productScope: "DEFAULT",
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productDefault data received via subscription",messageData);
                        if (messageData.data.onDeleteProductByScope !== null) {
                            
                            const deletedProductDefault = {... messageData.data.onDeleteProductByScope };
                            // if (DEBUG_MODE >= 2) console.log("Deleted product extracted from message",deletedProductDefault);
                            
                             //Remove the matching launch rule from local state.  Note, this technique does not update the state array directly like "splice" would
                            var tempProducts = productsRef.current.filter(element => element.id != deletedProductDefault.id);

                            setProducts(tempProducts); //Delete state data with newly sorted list  

                            if (DEBUG_MODE >= 2) console.log("Deleted default product", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            if (DEBUG_MODE >= 2) console.log("Active Products after DELETE from Subscription", tempActiveProducts);
                            setActiveProducts(tempActiveProducts);
                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing  DELETE global product subscription message",messageData);

                    }
                },
                 error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
           });
            
            setProductDefaultDeleteSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductDefaultDeleteSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE global Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs
    

//
// Subscriptions for CUSTOMER SPECIFIC Products
//


    //Functions and state data for real-time updates
    const [productCustomerSpecificCreateSubscriptionSetUp, setProductCustomerSpecificCreateSubscriptionSetUp] = useState(false);  
    const [productCustomerSpecificUpdateSubscriptionSetUp, setProductCustomerSpecificUpdateSubscriptionSetUp] = useState(false);  
    const [productCustomerSpecificDeleteSubscriptionSetUp, setProductCustomerSpecificDeleteSubscriptionSetUp] = useState(false); 

  useEffect(() => {
        if (!productCustomerSpecificCreateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Setting up subscription for CREATE CUSTOMER_SPECIFIC products");
            //Configure subscription based on user role
            let variables;
            if (isSuperAdmin)
                variables = { productScope: "CUSTOMER_SPECIFIC"};   //For SuperAdmin, listen for changes to any customer specific product
            else
                variables = { productScope: "CUSTOMER_SPECIFIC", customerID: currentUser.customerID };
            
          const subscription = API.graphql({
                query:onCreateProductByScope,
                variables: variables,
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productCustomerSpecific data received via subscription",messageData);
                        if (messageData.data.onCreateProductByScope !== null) {
                            
                            const newProductCustomerSpecific = messageData.data.onCreateProductByScope;
                            
                            if (DEBUG_MODE >= 2) console.log("New productCustomerSpecific extracted from message",newProductCustomerSpecific);

                            const tempProducts = [...productsRef.current];   //Get our state data for existing products

                             if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);

                            tempProducts.push(newProductCustomerSpecific);    //Add the new product
                            
                            //Sort the Products      
                            tempProducts.sort(compareProductsByTitle);

                            //Update system-wide state data with the new products
                            setProducts(tempProducts);
                            if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);

                            //If SuperAdmin, remove any products not for the particular company being viewed
                            if (isSuperAdmin) {
                                tempActiveProducts = tempActiveProducts.filter(product => product.customerID == selectedCustomerOptions.id);
                                if (DEBUG_MODE >= 2) console.log("Filtered products based on view of SuperAdmin", tempActiveProducts);
                            }
 
                            setActiveProducts(tempActiveProducts);
                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing productCustomerSpecific create subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductCustomerSpecificCreateSubscriptionSetUp(true);
          return () => {
                subscription.unsubscribe();
                setProductCustomerSpecificCreateSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs
      
    useEffect(() => {
        if (!productCustomerSpecificUpdateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE for CUSTOMER_SPECIFIC products");

            //Configure subscription based on user role
            let variables;
            if (isSuperAdmin)
                variables = { productScope: "CUSTOMER_SPECIFIC"};   //For SuperAdmin, listen for changes to any customer specific product
            else
                variables = { productScope: "CUSTOMER_SPECIFIC", customerID: currentUser.customerID };
            
           const subscription = API.graphql({
                query:onUpdateProductByScope,
                variables: variables,
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("UPDATE productCustomerSpecific data received via subscription",messageData);
                        if (messageData.data.onUpdateProductByScope !== null) {
                            
                            const updatedProductCustomerSpecific = messageData.data.onUpdateProductByScope;
                            
                            // if (DEBUG_MODE >= 2) console.log("ProductCustomerSpecific update extracted from message",updatedProductCustomerSpecific);
 
                             const tempProducts = [...productsRef.current];   //Get our state data for existing products

                            //  if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);

                             const index = tempProducts.findIndex(product => product.id == updatedProductCustomerSpecific.id);
                             
                             if (index > -1) {

                                //Update system-wide state data with the UPDATED product
                                tempProducts[index] = {...updatedProductCustomerSpecific};    //Replace the existing product with the updated one just received
                                
                                //Sort the Products      
                                tempProducts.sort(compareProductsByTitle);
    
                                //Update system-wide state data with the new products
                                setProducts(tempProducts);
                                if (DEBUG_MODE >= 2) console.log("Products after UPDATE from Subscription", tempProducts);
    
                                //Remove any products for which this customer has marked the product INACTIVE
                                var tempActiveProducts = tempProducts.filter(product => {
                                    if (!product.inactiveForCustomers) return false;
                                    return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                    
                                });
                                
                                if (DEBUG_MODE >= 2) console.log("Active Products after UPDATE from Subscription", tempActiveProducts);


                                //If SuperAdmin, remove any products not for the particular company being viewed
                                if (isSuperAdmin) {
                                    tempActiveProducts = tempActiveProducts.filter(product => product.customerID == selectedCustomerOptions.id);
                                    if (DEBUG_MODE >= 2) console.log("Filtered products based on view of SuperAdmin", tempActiveProducts);
                                }

                                setActiveProducts(tempActiveProducts);
                            
                             } else {
                                 if (DEBUG_MODE >= 2) console.log("Error - no matching product found to update", updatedProductCustomerSpecific, tempProducts);
                             }
 
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing CUSTOMER_SPECIFIC product update subscription message",err, messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductCustomerSpecificUpdateSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductCustomerSpecificUpdateSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs

   useEffect(() => {
        if (!productCustomerSpecificDeleteSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE CUSTOMER_SPECIFIC products ");

            //Configure subscription based on user role
            let variables;
            if (isSuperAdmin)
                variables = { productScope: "CUSTOMER_SPECIFIC"};   //For SuperAdmin, listen for changes to any customer specific product
            else
                variables = { productScope: "CUSTOMER_SPECIFIC", customerID: currentUser.customerID };

            
           const subscription = API.graphql({
                query:onDeleteProductByScope,
                variables: variables,
            })
            .subscribe ({
                next: messageData => {
                    try {
                        // if (DEBUG_MODE >= 2) console.log("DELETE productCustomerSpecific data received via subscription",messageData);
                        if (messageData.data.onDeleteProductByScope !== null) {
                            
                            const deletedProductCustomerSpecific = {... messageData.data.onDeleteProductByScope };
                            if (DEBUG_MODE >= 2) console.log("Deleted product extracted from message",deletedProductCustomerSpecific);
                            
                             //Remove the matching launch rule from local state.  Note, this technique does not update the state array directly like "splice" would
                            var tempProducts = productsRef.current.filter(element => element.id != deletedProductCustomerSpecific.id);

                            setProducts(tempProducts); //Delete state data with newly sorted list  

                            if (DEBUG_MODE >= 2) console.log("Deleted customer specific product", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            if (DEBUG_MODE >= 2) console.log("Active Products after DELETE from Subscription", tempActiveProducts);

                            //If SuperAdmin, remove any products not for the particular company being viewed
                            if (isSuperAdmin) {
                                tempActiveProducts = tempActiveProducts.filter(product => product.customerID == selectedCustomerOptions.id);
                                if (DEBUG_MODE >= 2) console.log("Filtered products based on view of SuperAdmin", tempActiveProducts);
                            }

                            setActiveProducts(tempActiveProducts);
                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing  DELETE global product subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductCustomerSpecificDeleteSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductCustomerSpecificDeleteSubscriptionSetUp(false);
                // if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE global Product");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs    



/*  NOT NEEDED AS ALL CRUDS COVERED BY ABOVE SUBSCRIPTIONS

    //Functions and state data for real-time updates
    const [productCreateSubscriptionSetUp, setProductCreateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
    const [productUpdateSubscriptionSetUp, setProductUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
    const [productDeleteSubscriptionSetUp, setProductDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket
    
    useEffect(() => {
        if (!productCreateSubscriptionSetUp && authState == 'signedin'  && currentUser) {
            
            if (DEBUG_MODE >= 2) console.log("Setting up subscription for products created for customer", currentUser.customerID);
            
           const subscription = API.graphql({
                query:onCreateProductByCustomer,
                variables: {
                        customerID: currentUser.customerID,
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New product data received via subscription",messageData);
                        if (messageData.data.onCreateProductByCustomer !== null) {
                            
                            const newProduct = messageData.data.onCreateProductByCustomer;
                            
                            if (DEBUG_MODE >= 2) console.log("New product extracted from message",newProduct);

                            //Ensure this is a company specific product or it will overlap with the subscriptions below
                            if (newProduct.productScope == "CUSTOMER_SPECIFIC") {
                                const tempProducts = [...productsRef.current];   //Get our state data for existing products
    
                                 if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);
    
                                tempProducts.push(newProduct);    //Add the new product
                                
                                //Sort the Products      
                                tempProducts.sort(compareProductsByTitle);
    
                                //Update system-wide state data with the new products
                                setProducts(tempProducts);
                                if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);
    
                                //Remove any products for which this customer has marked the product INACTIVE
                                var tempActiveProducts = tempProducts.filter(product => {
                                    if (!product.inactiveForCustomers) return false;
                                    return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                    
                                });
                                
                                if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);
                                setActiveProducts(tempActiveProducts);
                            }
                
                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing product create subscription message",messageData);

                    }
                },
                error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
            });
            
            setProductCreateSubscriptionSetUp(true);
            
           return () => {
                subscription.unsubscribe();
                setProductCreateSubscriptionSetUp(false);
                if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE PRODUCTS");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs
      

      
    useEffect(() => {
        if (!productUpdateSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for products for this user", currentUser);
            
           const subscription = API.graphql({
                query:onUpdateProductByCustomer,
                variables: {
                        customerID: currentUser.customerID,
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        // if (DEBUG_MODE >= 2) console.log("New product UPDATE data received via subscription",messageData);
                        if (messageData.data.onUpdateProductByCustomer !== null) {
                            
                            const updatedProduct = messageData.data.onUpdateProductByCustomer;
                            
                            // if (DEBUG_MODE >= 2) console.log("Product update extracted from message",updatedProduct);
                            
                            const tempProducts = [...productsRef.current];   //Get our state data for existing products

                            //  if (DEBUG_MODE >= 2) console.log("Current products",tempProducts);
                             
                             const index = tempProducts.findIndex(product => product.id == updatedProduct.id);
                             
                             if (index > -1) {

                            //Update system-wide state data with the UPDATED product
                            tempProducts[index] = {...updatedProduct};    //Replace the existing product with the updated one just received
                            
                            //Sort the Products      
                            tempProducts.sort(compareProductsByTitle);

                            //Update system-wide state data with the new products
                            setProducts(tempProducts);
                            if (DEBUG_MODE >= 2) console.log("Active Products after CREATE from Subscription", tempProducts);

                            //Remove any products for which this customer has marked the product INACTIVE
                            var tempActiveProducts = tempProducts.filter(product => {
                                if (!product.inactiveForCustomers) return false;
                                return !product.inactiveForCustomers.includes((isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID));
                                
                            });
                            
                            if (DEBUG_MODE >= 2) console.log("Filtered new products based on inactive", tempActiveProducts);
                            setActiveProducts(tempActiveProducts);
                            
                             } else {
                                 if (DEBUG_MODE >= 2) console.log("Error - no matching product found to update", updatedProduct, tempProducts);
                             }
                            
                         }
                    } catch (err) {
                        // if (DEBUG_MODE >= 2) console.log("Error processing product update subscription message",messageData);

                    }
                }
            });
            
            setProductUpdateSubscriptionSetUp(true);
            
           return () => {
                subscription.unsubscribe();
                setProductUpdateSubscriptionSetUp(false);
                if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE PRODUCTS");
            };
 
        }
    },[authState]);                     //Call function when a change to authState occurs
    

   useEffect(() => {
        if (!productDeleteSubscriptionSetUp && authState == 'signedin'  && currentUser !== null) {
            
            if (DEBUG_MODE >= 2) console.log("Setting up subscription for deleted products created by THIS COMPANY ");
            
           const subscription = API.graphql({
                query:onDeleteProductByCustomer,
                variables: {
                        customerID: currentUser.customerID,
                    }
            })
            .subscribe ({
                next: messageData => {
                    try {
                        if (DEBUG_MODE >= 2) console.log("New productByCustomer data received via subscription",messageData);
                        if (messageData.data.onDeleteProductByCustomer !== null) {
                            
                            const deletedProductByCustomer = {... messageData.data.onDeleteProductByCustomer };
                            // if (DEBUG_MODE >= 2) console.log("Deleted product extracted from message",deletedProductByCustomer);
                            
                             //Remove the matching product rule from local state. 
                            var tempProducts = productsRef.current.filter(element => element.id != deletedProductByCustomer.id);

                            setProducts(tempProducts); //Delete state data with newly sorted list  

                            // if (DEBUG_MODE >= 2) console.log("Deleted product", tempProducts);
                            
                         }
                    } catch (err) {
                        if (DEBUG_MODE >= 2) console.log("Error processing  DELETE product subscription message",messageData);

                    }
                }
            });
            
            setProductDeleteSubscriptionSetUp(true);
           return () => {
                subscription.unsubscribe();
                setProductDeleteSubscriptionSetUp(false);
                if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE product by company");
            };
 
        }
    },[authState]);                     
    

*/
    
    //return the Products Context provider
    return (

          
        <ProductsContext.Provider value={
            {   
                products, activeProducts, productCategories
            
            }}>
            {children}
        </ProductsContext.Provider>
  ); 
    
};


export { ProductsContext, ProductsProvider };


    //   {!redirectToPage ? "" : 
    //         <Redirect 
    //             to={{
    //             pathname: {redirectToPage},
    //             // search: "?utm=your+face",
    //             state: { message: "Hi" }
    //             }}
    //     />} 
    

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  copyright - 2020, 2021
//
// Examples:
// https://www.youtube.com/watch?v=XtMThy8QKqU
// https://www.youtube.com/watch?v=x_EEwGe-a9o


//Style
import "./conectereCommunity.css";
import "./Components/launchRow/launchRow.css"; //TODO reuse the LaunchRow component here

//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, HEADER_COMPRESSED_MENU_MODE, HEADER_FULL_MODE, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, RATING_STATS_INITIAL_STATE, DAYS_FOR_RECENTLY_SHARED_CONECTIVITIES, 
		  COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, LEGAL_TERMS_AND_CONDITIONS, LEGAL_PRIVACY_POLICY, QUICK_FILTERS_FOR_CONTECTIVITIES} from '../../shared/data/conectereConfigData';

//React & Amplify components
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries and Mutations

//Material UI 

//Bootstrap and other React components

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

import moment from 'moment';

//Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudIcon from '@mui/icons-material/Cloud';


//Components
import StarRatings from "../../shared/Components/starRatings/starRatings";
import ConectivityDetailedView from "../../shared/Components/conectivityDetailedView/conectivityDetailedView";
// import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import ConectivityEditor from "../../shared/Components/conectivityEditor/conectivityEditor";
import SearchInputBar from "../../shared/Components/searchInputBar/searchInputBar.js";

//Utils
import { conectivitiesMatchesSearch, DisplayCardConectivityPreview, compareConectivitiesByDateShared, compareConectivitiesMostFavorite, setBackgroundGradientClassByCategory, setHeaderColorByCategory, setHeaderColorByStringLength, setConectivityImageUrlByCategory, compareConectivitiesByTitle } from "../../shared/utils/conectivityUtils";
import { getRandomInt, truncateStringWithDots, formatDateAgoString, displayConectereCommunityText, EditButton, DeleteButton, displayCommunityText, displayCommunityTextInline} from "../../shared/utils/generalUtils";
import { storeUpdatedConectivity }  from "../../shared/utils/databaseUtils";
import { v4 as uuidv4 } from 'uuid';    


	//
	// BANNER
	//
	
		
	//Component for showing at the top of the page the being viewed at Conectivity in a ROW
	const Banner = ({conectivities, handleAddToCustomerPortfolio, handleDetailedView}) => {
	
		const [conectivityToSpotlight, setConectivityToSpotlight] = useState(null);
	
		//Set up React useEffect set up a timer for once second, causing just the Banner to render without changing the rest of the page
		//Upon render, restart the timer
		
		useEffect(() => {
		  const timer = setTimeout(() => {
			  
			if (conectivities) setConectivityToSpotlight(conectivities[getRandomInt(0, conectivities.length-1)]);        
			
		  }, 10000); //Fire in 10 seconds
		
		// Clear timeout if the component is unmounted
		return () => clearTimeout(timer);
		});
	
	
		useEffect(() => {
			
			//On render, set an initial product right away without waiting for Timer to expire in 10 seconds
			if (conectivityToSpotlight == null) setConectivityToSpotlight(conectivities[getRandomInt(0, conectivities.length-1)]); 
			
		},[conectivities]);
		
		
		if (conectivityToSpotlight == null) return null;
	
		const backgroundImageURL = "url('" +conectivityToSpotlight.image+ "')";
		// if (DEBUG_MODE >= 2) console.log("Conectivity to preview in Banner", conectivityToSpotlight, backgroundImageURL);
		
		return (    
			<header className="bannerHeader" style={{backgroundImage:backgroundImageURL}}>
			
			
				<div className="bannerInnerContainer" >
					<div className="bannerContents">
					
						<div className="TextStyle6A bannerTitle" >
							{conectivityToSpotlight.title}
						</div>
						<div className="bannerButtons">
							<button className="bannerButton" onClick={(e)=> handleAddToCustomerPortfolio(e, conectivityToSpotlight)}>
								<div className="ContainerNoHeightCenter" >
									<CloudDownloadIcon className="communityButton" style={{color:setHeaderColorByCategory(conectivityToSpotlight.category.label),  paddingRight:"5px"}}/>                                     
									<div>ADD TO PORTFOLIO</div>
								</div>
							</button>
											
							<button className="bannerButton" onClick={()=> handleDetailedView(conectivityToSpotlight)}>VIEW</button>
						</div>
						 <div className="bannerDescription TextStyle5" >
							{truncateStringWithDots(conectivityToSpotlight.description, 200)}
						</div>
						<div  className="TextStyle4">
						   <i> {!conectivityToSpotlight.approvedPaidTime ? "" : conectivityToSpotlight.approvedPaidTime + " min"}</i>
						</div>
						<div className="bannerStarWrapper">
							<StarRatings rating={conectivityToSpotlight.ratingStats.ratingsAverage} readOnly={true} starSize={"1.5rem"}/>
						</div>
					</div>

					<div className={`bannerFadeLeftToRight ${setBackgroundGradientClassByCategory(conectivityToSpotlight.category.label)}`} ></div>
				</div>
			</header>
		);
		
	}

//React Component
const ConectereCommunity = () => {
  
	//variable for holding on this page data for cusomters 
	// const [conectivitiesMaster, setConectivitiesMaster] = useState([]); //Master list
	const [conectivitiesFilteredMaster, setConectivitiesFilteredMaster] = useState([]); //Filtered list based on Search; used to drive all the row data
	const [conectivitiesFavorites, setConectivitiesFavorites] = useState([]);
	const [conectivitiesSuggestions, setConectivitiesSuggestions] = useState([]);
	const [conectivitiesBalance, setConectivitiesBalance] = useState([]);
	const [conectivitiesTeam, setConectivitiesTeam] = useState([]);
	const [conectivitiesSocial, setConectivitiesSocial] = useState([]);
	const [conectivitiesGrowth, setConectivitiesGrowth] = useState([]);
	const [conectivitiesDEI, setConectivitiesDEI] = useState([]);
	const [conectivitiesCS, setConectivitiesCS] = useState([]);
	const [conectivitiesRecentlyShared, setConectivitiesRecentlyShared] = useState([]);
	
	const [conectivityToProcess, setConectivityToProcess] = useState(CONECTIVITY_INITIAL_STATE);        //Used to hold a conectivity that is to be launched, edited, deleted, etc.
	const [conectivityToView, setConectivityToView] = useState(CONECTIVITY_INITIAL_STATE);              //Used to hold a conectivity that is to be launched

	const [showModalAddToPortfolio, setShowModalAddToPortfolio] = useState(false);
	const [showModalRemoveFromPortfolio, setShowModalRemoveFromPortfolio] = useState(false);
	const [showModalConectivityDetailed, setShowModalConectivityDetailed] = useState(false);      //Detailed Conectivity View
	const [showModalSubscribeSuccess, setShowModalSubscribeSuccess] = useState(false);
	const [showModalRemoveSuccess, setShowModalRemoveSuccess] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [showModalEditPost, setShowModalEditPost] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchActive, setSearchActive] = useState(false);
	const [activeFilters, setActiveFilters] = useState([]);

	// const [inviteMessage, setInviteMessage] = useState("");
 
   // Context
	const { currentReactPage, setShowSpinner} = useContext(DisplayContext); 
	const { authState, currentUser, isAdmin,     } = useContext(AuthContext);   
	const { users, customers  } = useContext(CustomerContext);  
	const { conectivitiesCommunity, invokeDeleteConectivity, invokeDownloadCommunityConectivity } = useContext(ConectivityContext);
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 


	//Pre-load conectivity to display on page load based on passed in URL  
	useEffect(() => {
		
		//Safety check - no conectivities yet?  Exit
		if (!conectivitiesCommunity || !currentReactPage) return;
	 
		if (DEBUG_MODE >= 2) console.log("Conectere Community Page - parsing URL", currentReactPage.pathname);
  
		 //Now, check the URL params to see if we should open a specific Conectivity for the user
		 const searchParams = new URLSearchParams(currentReactPage?.search);
		 if (DEBUG_MODE >= 1) console.log("Launchpad - Current React Page", currentReactPage);
		 if (DEBUG_MODE >= 1) console.log("Launchpad - Page React Search Params (after ?)", searchParams);
		 if (searchParams.has("CONECTIVITY")) { 
			 if (DEBUG_MODE >= 1) console.log("Conectivity param Detected!!");
			 const conectivityID = searchParams.get("CONECTIVITY");
			 if (DEBUG_MODE >= 1) console.log(" CONECTIVITY ID =", conectivityID);    
			 if (conectivityID) {
				 const tempConectivityToView = conectivitiesCommunity.find(c => c.id === conectivityID);
				 if (tempConectivityToView) {
					 if (DEBUG_MODE >= 2) console.log("Found conectivity to view based on URL", tempConectivityToView);
					 setConectivityToView(tempConectivityToView);
					 setShowModalConectivityDetailed(true);  
				 } {
					 if (DEBUG_MODE >= 2) console.log("Error - conectivity from URL not found", currentReactPage.pathname);
				 }
			 } else if (DEBUG_MODE >= 2) console.log("Error - no conectivity param", currentReactPage.pathname);                             
		 }         

	},[conectivitiesCommunity, currentReactPage]); 
	
	

	//Use effect for generating new rows and spotlighted conectivitiy; any change to Conectivities will fire a filtering operation
	
	useEffect(() => {
		generateRows();  
	}, [conectivitiesFilteredMaster]);

	function generateRows() {
 
			//Copy Master
			var filteredConectivities = [...conectivitiesFilteredMaster];
			
			//Filter Conectivities to only those that are PUBLISHED; save as Master
			var tempFurtherFiltered =[];
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			var filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "STRESS");
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Stress row", filteredConectivitiesForRow);
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesBalance(filteredConectivitiesForRow);

			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "SOCIAL");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesSocial(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Social row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "TEAM");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesTeam(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Team row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.category.label == "PERSONAL");
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesGrowth(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for DEI row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.badgesDEI > 0);
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesDEI(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for DEI row", filteredConectivitiesForRow);
			
			//Filter PUBLISHED Conectivities based on category / type; save to state data for page
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.badgesCS > 0);
			filteredConectivitiesForRow.sort(compareConectivitiesByTitle);                      //Sort the Conectivities
			setConectivitiesCS(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for CS row", filteredConectivitiesForRow);

		   //Generate list of favorites
			filteredConectivitiesForRow = [];
			filteredConectivitiesForRow = filteredConectivities.filter(conectivity => conectivity.ratingStatsID !== null && conectivity.ratingStatsID != undefined); //May not really be necessary but some conectivities won't have this until rated
			// if (DEBUG_MODE >= 2) console.log("Initial Filtered Conectivities for Favorites row", filteredConectivitiesForRow);
			tempFurtherFiltered = filteredConectivitiesForRow.filter(conectivity => conectivity.ratingStats.ratingsAverage >= 3.0);  //Grab all that have at least a 3 star rating
			tempFurtherFiltered.sort(compareConectivitiesMostFavorite);                 //Sort the Conectivities
			setConectivitiesFavorites(tempFurtherFiltered);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Favorites row", tempFurtherFiltered);

		   //Generate list of recently shared conectivities
			tempFurtherFiltered = filteredConectivities.filter(conectivity => conectivityRecentlyShared(conectivity)); 
			tempFurtherFiltered.sort(compareConectivitiesByDateShared);              //Sort the Conectivities
			setConectivitiesRecentlyShared(filteredConectivitiesForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Conectivities for Recently Shared row", tempFurtherFiltered);

	}  

	function conectivityRecentlyShared(conectivity) {
		
		if (conectivity.sharedDate == undefined) return false;

		const startOfPeriod= moment().subtract(DAYS_FOR_RECENTLY_SHARED_CONECTIVITIES, 'days').startOf('day');
		const productCreatedDate = moment(conectivity.sharedDate, "YYYY MM DDTHH mm ssZ");
		

		if (productCreatedDate.isAfter(startOfPeriod)) {
			// if (DEBUG_MODE >= 2) console.log("RETURNED TRUE - Comparing conectivity date and day period", productCreatedDate.format("YYYY-MM-DDTHH:mm:ssZ"), startOfPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));
			return true;
		} else {
			// if (DEBUG_MODE >= 2) console.log("RETURNED FALSE - Comparing conectivity date and day period", productCreatedDate.format("YYYY-MM-DDTHH:mm:ssZ"), startOfPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));
			return false;
		}
		
		
	}   
	
	
	//Use effect for processing any updates to the Search Term or to the underlying Master conectivities, should they change
	
	useEffect(() => {
		processSearchTermChange();  
		if (DEBUG_MODE >= 2) console.log("Search term change detected in Use Effect", searchTerm);
	}, [searchTerm, activeFilters, conectivitiesCommunity]);
	
	function processSearchTermChange () {      
		if (DEBUG_MODE >= 2) console.log("Processing search term change", searchActive, activeFilters);
		let tempConectivities = [...conectivitiesCommunity]; //Copy Master
		if (searchActive || (searchTerm || (activeFilters && activeFilters.length > 0))) {           
			tempConectivities = tempConectivities.filter(conectivity => conectivitiesMatchesSearch({conectivity, activeFilters, keywords:searchTerm})); //Filter based on new term          
			if (DEBUG_MODE >= 2) console.log("Generated new filtered list based on search term", searchTerm, tempConectivities);       
		}
		setConectivitiesFilteredMaster(tempConectivities);
	}   
	
	// Pop up a modal for creating a private copy of the conectivity for the user's company
	const handleAddToCustomerPortfolio = (e, conectivityToProcess) => {
		e.stopPropagation();        //prevent outer Card OnClick from firing and displaying full instruction modal       
		setShowModalSubscribeSuccess(false);

		if (DEBUG_MODE >= 2) console.log("ADD TO PORTFOLIO button pressed.  ADD Conectivity=", conectivityToProcess); 
		
		setConectivityToProcess(conectivityToProcess);
		setShowModalAddToPortfolio(true); //pop-up Modal
		
	};
	

/*
	const handleRemoveFromCustomerPortfolio = (e, conectivityToProcess) => {

		e.stopPropagation();        //prevent outer Card OnClick from firing and displaying full instruction modal
		
		setShowModalRemoveSuccess(false);

		if (DEBUG_MODE >= 2) console.log("REMOVE FROM PORTFOLIO button pressed.  Conectivity=", conectivityToProcess); 
		
		setConectivityToProcess(conectivityToProcess);
		setShowModalRemoveFromPortfolio(true); //pop-up Modal
		
	};
*/
	

	const handleCancelAddToPortfolio = () => {
			setShowModalAddToPortfolio(false);
			setShowModalSubscribeSuccess(false);
			setShowModalRemoveSuccess(false);            
			setConectivityToProcess(CONECTIVITY_INITIAL_STATE);
			if (DEBUG_MODE >= 2) console.log("Cancelled Conectivity Download.");
		};
		
	async function handleConfirmationAddToPortfolio () {

		//Safety check - should not happen
		//Is the user trying so subscribe to a Conectivy they authored?  If so, return
		 if (conectivityToProcess.createdByCustomerID === currentUser.customerID) {
			if (DEBUG_MODE >= 2) console.log("Cannot Add To Portfolio - Authored by the same company");
			setModalGeneralMessage("Sorry - no need to download a conectivity your company originally posted");
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
			return;
		}
		
		setShowSpinner(true); //Pop up spinners to lock screen

		try {
			
			//First, create a private copy of the conectivity for this customer, even if already downloaded (possible a prior version)
			var successFlag = await invokeDownloadCommunityConectivity ({connectivityToDownload:conectivityToProcess});

			if (successFlag) {
				//Second, record the fact that the customer downloaded this conectivity
				
				//Has the customer already downloaded this shared conectivity?
				var alreadySubscribing = false;
				
				if (conectivityToProcess.subscribingCustomers !== null)
					if (conectivityToProcess.subscribingCustomers.some(id => id == currentUser.customerID)) alreadySubscribing = true;
		 
		 
				//If not already downlaoded, update the posted conectivity to record that this company has already downloaded (subscribed)
				if (!alreadySubscribing) {
	 
					//If current field is undefined, initialize it
					if (conectivityToProcess.subscribingCustomers == null || conectivityToProcess.subscribingCustomers == undefined) conectivityToProcess.subscribingCustomers = [];
					
					const newSubscriberList = [...conectivityToProcess.subscribingCustomers];
					
					newSubscriberList.push(currentUser.customerID); //Add to the list 
					
					if (DEBUG_MODE >= 2) console.log("Created new subscriber list", newSubscriberList, currentUser, conectivityToProcess);
		
					const updatedConectivity = {
						id:conectivityToProcess.id, 
						subscribingCustomers:newSubscriberList,
					};
					
					//Get the returned, updated object so we can update our local data with all the fields
					const tempInsertedConectivity = await storeUpdatedConectivity(updatedConectivity);
					
					//Update state - find the corresponding object on our page
	
					/*  HANDLE VIA SUBSCRIPTION
	
					setConectivitiesFilteredMaster([...conectivitiesCommunity]); //Make a fresh copy of the master published conectivities; thus do a reset on the page
					const tempConectivities = [...conectivitiesCommunity];   //Copy our master state date
					const indexToEdit = tempConectivities.findIndex ( conectivityRecord => conectivityRecord.id == conectivityToProcess.id);
	
					 
					//Edit the item in our local data using the FULL object returned from the backed on the update
					if (indexToEdit > -1) {
						tempConectivities[indexToEdit] = { ... tempInsertedConectivity};
						setConectivitiesMaster(tempConectivities); //update our master state data on the page, which will trigger filtering and page refresh
					}
					*/                
					if (DEBUG_MODE >= 2) console.log('Successfully subscribed customer to conectivity', tempInsertedConectivity);
				}

				setConectivityToProcess(CONECTIVITY_INITIAL_STATE);
				setModalGeneralMessage("A copy of the Conectivity has been added to your portfolio and can be managed in your Conectivity Dashboard");
				setModalGeneralTitle("Success!!");
			
			}  else {
				setModalGeneralMessage("Something went wrong.");
				setModalGeneralTitle("Ooops!");
				if (DEBUG_MODE >= 2) console.log('error adding conectivity to customer portfolio:');
			}
			
		} catch (err) {
			setModalGeneralMessage("Something went wrong.  " + err);
			setModalGeneralTitle("Ooops!");
			if (DEBUG_MODE >= 2) console.log('error adding conectivity to customer portfolio:', err);
		}
		
		setShowModalGeneral(true);   
		setShowModalAddToPortfolio(false);          //hide the pop-up
		setShowSpinner(false);              //Hide the spinners to lock screen

	}


	async function handleConfirmationRemoveFromPortfolio () {

		//Safety check - should not happen
		//Is the user trying so unsubscribe to a Conectivy they authored?  If so, return
 
		var authoredByThisCompany = false;
		
		const authoringUser = users.find (user => user.id == conectivityToProcess.authorID);
		
		if (authoringUser != undefined)
			if (authoringUser.customerID == currentUser.customerID) authoredByThisCompany = true;
			
		if (authoredByThisCompany == false) {
			if (DEBUG_MODE >= 2) console.log("Cannot Add To Portfolio - Authored by the same company");
		}
		
		setShowSpinner(true); //Pop up spinners to lock screen

		try {

			//Confirm the company is, in fact, already subscribing to this conectivity            
			var alreadySubscribing = false;
			
			 if (conectivityToProcess.subscribingCustomers !== null)
				if (conectivityToProcess.subscribingCustomers.some(id => id == currentUser.customerID)) alreadySubscribing = true;
	 
	 
			//Check the list to confirm the customer does not ALREADY subscribe to the Conectivity
			if (alreadySubscribing) {
	 
	
				 const newSubscriberList = conectivityToProcess.subscribingCustomers.filter( customerID => customerID != currentUser.customerID); //Include all BUT this company
				
				if (DEBUG_MODE >= 2) console.log("Created new subscriber list after removing this customer", newSubscriberList, currentUser, conectivityToProcess);
	
				const updatedConectivity = {
					id:conectivityToProcess.id, 
					subscribingCustomers:newSubscriberList,
				};
				
				//Get the returned, updated object so we can update our local data with all the fields
				const tempInsertedConectivity = await storeUpdatedConectivity(updatedConectivity);		
				
				//Update state - find the corresponding object on our page
				setConectivitiesFilteredMaster([...conectivitiesCommunity]); //Make a fresh copy of the master published conectivities; thus do a reset on the page
				const tempConectivities = [...conectivitiesCommunity];   //Copy our master state date
				const indexToEdit = tempConectivities.findIndex ( conectivityRecord => conectivityRecord.id == conectivityToProcess.id);

/* HANDLE THIS                    
				//Edit the item in our local data using the FULL object returned from the backed on the update
				if (indexToEdit > -1) {
					tempConectivities[indexToEdit] = { ... tempInsertedConectivity};
					setConectivitiesMaster(tempConectivities); //update our master state data on the page, which will trigger filtering and page refresh
				}
*/                
				if (DEBUG_MODE >= 2) console.log('Successfully UNsubscribed customer to conectivity', tempInsertedConectivity);
				setModalGeneralMessage("The Conectivity has been removed from your portfolio");
				setModalGeneralTitle("Success");
				setConectivityToProcess(CONECTIVITY_INITIAL_STATE);
					
			}
		} catch (err) {
			setModalGeneralMessage("Something went wrong.  " + err);
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
			if (DEBUG_MODE >= 2) console.log('error removing conectivity to customer portfolio:', err);
		}

		setShowModalRemoveFromPortfolio(false);          //hide the pop-up
		setShowSpinner(false);              //Hide the spinners to lock screen

	}


	///Delete Functions
	//Note at some point this would be handled by backend to do cleanup on related records, like wallet and transactions
	async function handleConfirmationDelete () {
 
		setShowSpinner(true); //Show spinners
	   
		try {
			
			var successFlag = await invokeDeleteConectivity ({connectivityToDelete:conectivityToProcess});
				
			if (successFlag) {
				if (DEBUG_MODE >= 2) console.log('Successfully deleted conectivity', conectivityToProcess);
				setModalGeneralMessage("Conectivity deleted");
				setModalGeneralTitle("Success!!");
			} else {
				setModalGeneralMessage("Something went wrong.  Unable to delete conectivity");
				setModalGeneralTitle("Ooops!");
				if (DEBUG_MODE >= 2) console.log('error deleting conectivity:');
			}
 
			
		} catch (err) {
			setModalGeneralMessage("Something went wrong.  " + err);
			setModalGeneralTitle("Ooops!");
			if (DEBUG_MODE >= 2) console.log('error deleting conectivity:', err);
		}

		setConectivityToProcess(CONECTIVITY_INITIAL_STATE);
		setShowModalGeneral(true);   
		setShowModalDelete(false);  //hide the pop-up
		setShowSpinner(false);      //Hide spinners
	}


	//Pop-up Editor to allow user to edit their shared conectivity
	const handleEdit = (conectivityToEdit) => {
		if (DEBUG_MODE >= 2) console.log("EDIT button pressed.  Edit Community Conectivity=", conectivityToEdit); 
		setConectivityToProcess(conectivityToEdit);
		setShowModalEditPost(true); //pop-up Modal
	};

	//Close the Editor
	const handleCloseEditor = () => {
			setShowModalEditPost(false);
			setConectivityToProcess(CONECTIVITY_INITIAL_STATE);
			if (DEBUG_MODE >= 2) console.log("Closed Conectivity Editor.");
		};
		
		
	//Pop-up a modal and ask conectivity to confirm. Handle confirmation
	const handleDelete = (conectivityToDelete) => {
		
		if (DEBUG_MODE >= 2) console.log("DELETE button pressed.  Delete Community Conectivity=", conectivityToDelete); 
		setConectivityToProcess(conectivityToDelete);
		setShowModalDelete(true); //pop-up Modal
	};

	//handle cancel in delete modal
	const handleCancelDelete = () => {
			setShowModalDelete(false);
			setConectivityToProcess(CONECTIVITY_INITIAL_STATE);
			if (DEBUG_MODE >= 2) console.log("Cancelled Conectivity Delete.");
		};
		
	//
	//Conectivity Card Preview
	//
	const CommunityCardConectivityPreview = ({conectivity, isLargeCard, disableLaunchButton, disableHover, handleEdit, handleDelete, footerTextColorClass}) => {
		
		const footerTextClass = (isLargeCard ? "TextStyle3" : "TextStyle2" );
		
		// const [conectivity, setConectivity] = useState({...conectivityToPreview});     //Our local React state var for this component
		const [showHoverContent, setShowHoverContent] = useState(false);
		// const [showConectivityInnerMenu, setShowConectivityInnerMenu] = useState(false);

		// if (DEBUG_MODE >= 2) console.log("Shared Conectivity Preview", conectivityToPreview);

		const displayColored = false;
		const headerColor = (displayColored ? "white" : setHeaderColorByCategory(conectivity.category.label));
		// const fontColor = setHeaderColorByCategory(conectivityToPreview.category.label);
		// const titleFontColor = (displayColored ? setHeaderColorByCategory(conectivityToPreview.category.label) : "white" );
		const alreadySubscribing= isSubscribing(conectivity);

		//Determine whether this particular conectivity is already installed in the customer's portfolio
		function isSubscribing(conectivity) {

			 if (conectivity && conectivity.subscribingCustomers !== null)
				if (conectivity.subscribingCustomers.some(id => id == currentUser.customerID)) {
					// if (DEBUG_MODE >= 2) console.log("Already subscribing", conectivity);
					return true;
				}
			return false;
		}

/*
		//Handle menu selection - NOTE the SCOPE is INSIDE the SubMenuDisplay so we can access the props including the specific invitation and scheduled conectivity to handle
		const handleConectivityMenuSelection = (e) => {
			e.stopPropagation();        //Prevent outer Card OnClick from firing 
			const menuAction  = e.currentTarget.dataset.value;
			if (DEBUG_MODE >= 2) console.log("CONECTIVITY Menu item selected.", menuAction, handleEditConectivityClick, handleDeleteConectivityClick);
			
			   switch (menuAction) {
				   case "EDIT":
						// if (handleEditConectivityClick) handleEditConectivityClick(spotlight);  //Callback parent and invoke edit
						setShowConectivityInnerMenu(false);                          //Close the sub menu
					break;
				   case "DELETE":
						// if (handleDeleteConectivityClick) handleDeleteConectivityClick(spotlight);  //Callback parent and invoke delete
						setShowConectivityInnerMenu(false);                          //Close the sub menu
					break;
			   } 
		};  

	   function conectivityInnerMenu () {
			if (!showConectivityInnerMenu) return null;
			return (
					<div className="ContainerNoHeightCenter conectivityInnerMenuContainer" >
					
						 <MenuList >
								<MenuItem onClick={handleConectivityMenuSelection} data-value={"EDIT"}>
									<div className="ContainerNoHeightCenter"> <Edit style={{fontSize:"1.0rem"}}/> <div style={{paddingLeft:"3px", fontSize:"0.8rem"}}>Edit Conectivity</div></div> 
								</MenuItem>
								
								<MenuItem onClick={handleConectivityMenuSelection} data-value={"DELETE"}>
									<div className="ContainerNoHeightCenter"> <DeleteOutline  style={{fontSize:"1.0rem"}}/> <div style={{paddingLeft:"3px", fontSize:"0.8rem"}}>Delete Conectivity</div></div> 
								</MenuItem>   
						</MenuList>
					</div>   
				);
		}   


		function conectivityInnerMenuIcon () {
			
			if (!conectivity || !currentUser) return null;
			if (conectivity.authorID != currentUser.id) return null;

			return (
					<div className="ContainerNoHeightCenter conectivityInnerMenuIconContainer" > 
						<IconButton size="medium" onClick={(e) => { 
								e.stopPropagation();        //Prevent outer Card OnClick from firing 
								setShowConectivityInnerMenu(!showConectivityInnerMenu);

						}} >
							<MoreVertIcon className="white" style={{}}/> 
						</IconButton>
					</div>   
				);
		}
*/
		function displayStatusGraphic() {
	
			//Dynamically display graphic on this conectivity based on whether the Conectivity is already in the user's portfolio
			//Could be a company that already downloaded it or could be the company that posted it
			 if (!conectivity || !currentUser) return null;


			 //Written by this author and not yet published by Conectere?
			 if (conectivity.authorID === currentUser.id && conectivity.publicationStatus !== "PUBLISHED") {
				 
				 return (
						<div className="alreadyDownloadedConectivityGraphic TextStyle3 headerBlue white-background" style={{padding:"clamp(2px,0.5vw,5px) clamp(4px,1vw,10px)", borderRadius:"clamp(4px,1vw,10px)"}}> UNDER REVIEW</div>
					 );
				 
			 } else if ((conectivity.createdByCustomerID === currentUser.customerID) || alreadySubscribing) {
	
				return (
					<div>
						<div className="alreadyDownloadedConectivityGraphic white" > <CloudIcon  className="TextStyle7 white" /> </div>
						<div className="alreadyDownloadedConectivityGraphic2 TextStyle4 purple" > &#10003; </div>
					</div>
	 
				);
			 } else {
				 return null;
			 }
		}
	 
	 function hoverButtons() {
		//Dynamically adjust our cool community buttons based on whether the Conectivity is already in the user's portfolio
	
	
		//Post created by this Company OR this company already downlaoded??  If so, no hover buttons
		if (alreadySubscribing || (conectivity.createdByCustomerID === currentUser.customerID)) return  null;          
		else  return (
			<div className ={`ContainerNoHeightSpaceAround TextStyle3 launchButtonsWrapper ${showHoverContent && "launchButtonsShow"}`}> 
				<button className="communityBannerButton" onClick={(e)=> handleAddToCustomerPortfolio(e, conectivity)}>
					<div className="ContainerNoHeightCenter" >
						<CloudDownloadIcon className=" communityButton" style={{ paddingRight:"clamp(2px,0.5vw,5px)"}}/>                                     
						<div>{isAdmin ? "INSTALL CONECTIVITY" : "HOW TO ACCESS"} </div>
					</div>
				</button>
			</div>   
		);

			 /* 2.6.2023 - SINCE DOWNLOADED CONECTIVITIES ARE SEPERATE VERSIONS - NO 'REMOVAL' ON THIS PAGE
			
		  else {
			 
			 return (

					 <div className ={`ContainerNoHeightSpaceAround launchButtonsWrapper ${showHoverContent && "launchButtonsShow"}`}> 
						<button className="communityBannerButton" onClick={(e)=> handleRemoveFromCustomerPortfolio(e, conectivity)}>
							<div className="ContainerNoHeightCenter" >
								<HighlightOffIcon className="communityButton" style={{color:"#cc3232",  paddingRight:"5px"}}/>                                     
								<div>REMOVE POST</div>
							</div>
						</button>
					</div>   
				 
				 );
				
		 }
	  */
	}

	function editButtons() {
		//Post created by this Company?  If so, let the user edit / remove IF they are an ADMIN or the AUTHOR?
		if ((conectivity.createdByCustomerID === currentUser.customerID) && (isAdmin || (conectivity.authorID === currentUser.id))) {
			return (
				<div className="ContainerNoHeightCenter"> 
						{/*   EDIT & DELETE  */}
						<EditButton  className="TextStyle2 white" hoverTextClassName="communityButtonHoverText" hoverText="Edit Post" onClickCallback={handleEdit} onClickCallbackParam={conectivity}/>
						<DeleteButton  className="TextStyle2 white" hoverTextClassName="communityButtonHoverText" hoverText="Delete Post" onClickCallback={handleDelete} onClickCallbackParam={conectivity}/>
				</div>   
			);
		} else return null;
	}    
		const handleMouseEnter = (e) => {
			if (DEBUG_MODE >= 2) console.log("Mouse enter",e);
			if (!disableLaunchButton) setShowHoverContent(true);
		};
		
		const handleMouseLeave = (e) => {
			if (DEBUG_MODE >= 2) console.log("Mouse leave",e);
			setShowHoverContent(false);
		};        

		
		if (!conectivity) return null;

		//Selectively disable the HOVER features based on prop
		//  if (launchCardOuterDiv.current && disableHover ) {
		//     launchCardOuterDiv.current.classList.remove("launchCardOuterWrapperHover"); 
		//     launchCardOuterDiv.current.classList.add("launchCardOuterWrapperHover"); 
		//  }        
		
		return (
			<div className={`conectivityCardOuterWrapper conectivityCardScale  ${disableHover ? "noPointer" : "pointer" }`}>
 
				{displayStatusGraphic()}                    

				<div 
					className={`conectivityCardRoot ContainerVerticalSpaceBetween positionRelative ${!isLargeCard ? "conectivityCardSmall" : ""}`} 
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onClick={!disableLaunchButton ? ()=> handleDetailedView(conectivity) : null}
				>

				 {/* conectivityInnerMenuIcon() */}
				 {/* conectivityInnerMenu() */}
	
					<div className="ContainerNoHeightCenter fullWidth conectivityCardHeaderWrapper" style={{backgroundColor: headerColor}} >
					  <div className="conectivityCardHeaderImageWrapper" >
							<img src={setConectivityImageUrlByCategory(conectivity.category.label)} className={isLargeCard ? "conectivityCardHeaderImageLarge" : "conectivityCardHeaderImage"}  />
						</div>
						<div className="conectivityCardHeaderTextWrapper" >
							<div className={isLargeCard ? "TextStyle5 white" : "TextStyle4 white"} > {conectivity.title} </div>
						</div>
					</div>

						
					  <div>
					<div className="ContainerVerticalCenter conectivityCardDescriptionWrapper" >
						   <div className = {isLargeCard ? "TextStyle3 black-text" : "TextStyle2 black"}>{truncateStringWithDots(conectivity.description, CONECTERE_CONFIG_DATA.CONECTIVITY_DESCRIPTION_MAX_CHARS)}</div>
					</div>
						
					</div>
					   <div className="ContainerNoHeightSpaceAround fullWidth positionRelative wrap conectivityCardActionsWrapper" >
								{conectivity.ratingStatsID == null ? "" :
									<div  style={{display:"flex", padding:"10px 5px", color:headerColor}}>
										   <StarRatings rating={conectivity.ratingStats.ratingsAverage} readOnly={true} starSize={"12px"}/>
									</div>
								}
								
								<div  style={{display:"flex", padding:"10px 5px", color:headerColor}}>
								   <i> {!conectivity.approvedPaidTime ? "" : conectivity.approvedPaidTime + " min"}</i>
								</div>
								
								{!conectivity.conectCoins >0 ? "" :
								<div style={{display:"flex", padding:"10px 5px", flexwrap:"no-wrap", color:headerColor}}> 
									{!conectivity ? "" : conectivity.conectCoins} 
									<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
								</div>                        
								}
								{!conectivity.badgesDEI>0 ? "" :  
									<div style={{ padding:"0px 10px", flexwrap:"no-wrap", color:headerColor}}> 
										{!conectivity ? "" : conectivity.badgesDEI} 
										<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
									</div>
								}
								{!conectivity.badgesCS>0 ? "" :  
									<div style={{padding:"0px 10px", flexwrap:"no-wrap", color:headerColor}}> 
										{!conectivity ? "" : conectivity.badgesCS} 
										<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> 
									</div>
								}
								
								{hoverButtons(showHoverContent, conectivity, alreadySubscribing)}
		
						</div>
			
				</div>
				<div className="ContainerVerticalStartStart fullWidth" style={{position:"relative", padding:"0px 10px"}}>
					{!conectivity.createdByCustomerID ? "" :
						<div className="ContainerNoHeightSpaceBetween fullWidth">
							<div className={`${footerTextClass} ${footerTextColorClass}`}>Shared by {conectivity.createdByCustomerName}</div>
							{editButtons()}
					   </div>
					}
				   {!conectivity.authorID ? "" :
						<div className="ContainerNoHeight">
							<div className="avatarImage avatarImageTiny" style={{backgroundColor: setHeaderColorByStringLength(conectivity.author)}}>
								{conectivity.authorUrl  ?  
									<img className="avatarImageCenterPortrait" src={conectivity.authorUrl} alt={conectivity.authorInitials} />
									:
									<div className="avatarInitialsSmall"> {conectivity.authorInitials} </div> 
								}                                                
							</div>
							<div className={`${footerTextClass} ${footerTextColorClass}`} style = {{paddingLeft:"5px", padingRight:"5px"}}>{conectivity.author}</div>
						</div>
					}
						<div className="ContainerNoHeight">
							<div className={`${footerTextClass} ${footerTextColorClass}`} style = {{paddingLeft:"5px", padingRight:"5px"}}>&#x2022; </div>
							<div className={`${footerTextClass} ${footerTextColorClass}`}> {formatDateAgoString(conectivity.sharedDate)}</div>
						</div>
				</div>
						  
			</div>
		);
	};
	
	

	///Component for showing a ROW of conectivities
	const LaunchRow = ({title, conectivities, isLargeRow, handleDelete, handleEdit}) => {
	
		//State variables for Row component
		const [showRowNavButtons, setShowRowNavButtons] = useState(false); 
		const scrollRowRef = useRef(null); //React Ref representing scroll Div
		const [scrollLeftCurrentValue, setScrollLeftCurrentValue] = useState(0);
		const [maxScrollForRow, setMaxScrollForRow] = useState(0);
		const scrollDuration = 2000; // 2 second scroll  
		const rowKey = uuidv4();
		
		//Set amount to scroll based on actual client window size
		const [pixelsToScrollPerButtonPress, setPixelsToScrollPerButtonPress] = useState(1000);
	
		// if (DEBUG_MODE >= 2) console.log("Rowkey", rowKey);
	
		//Re-calc max scroll width on any new render of the row
		useEffect(() => {
	
			if (scrollRowRef.current == null)  return;
		
			var maxScrollLeft = scrollRowRef.current.scrollWidth - scrollRowRef.current.clientWidth;
			setMaxScrollForRow(maxScrollLeft);
			setPixelsToScrollPerButtonPress( Math.floor(scrollRowRef.current.clientWidth * 0.8));  //Set scroll about to 80% of screen width
	
			// if (DEBUG_MODE >= 2) console.log("Set row", rowKey, "Max Scroll", maxScrollLeft, " scroll amount per button", Math.floor(scrollRowRef.current.clientWidth * 0.8));
		});    
			
		 
		if (conectivities == null  || conectivities.length===0) return null;
		
		// if (DEBUG_MODE >= 2) console.log("Launch Row called", conectivities, title, isLargeRow);
	
	  
	
	   //Functions to show/hide left/right nav buttons upon mouse entering/leaving the row      
		const handleMouseLeave = (e) => {
			// if (DEBUG_MODE >= 2) console.log("Mouse left row",e);
			setShowRowNavButtons(false);
		};        
		const handleMouseEnter = (e) => {
			// if (DEBUG_MODE >= 2) console.log("Mouse entered row",e);
			setShowRowNavButtons(true);
		}; 
		
		//Functions to scroll the row in response to a user click
		const handleLeftNav = () => {
	
			// if (DEBUG_MODE >= 2) console.log("Handling Left Nav",scrollRowRef.current.scrollLeft);
			// if (DEBUG_MODE >= 2) console.log("width", scrollRowRef.current.offsetWidth);
			
			if (scrollRowRef.current !== null)  
				animateScroll({
					initialScrollPosition:scrollLeftCurrentValue, 
					scrollAmount:pixelsToScrollPerButtonPress,
					direction:"LEFT", 
					duration:scrollDuration,
				});
			else 
				if (DEBUG_MODE >= 2) console.log("Error: No Ref to scroll");
			
		};
		
		const handleRightNav = () => {
			
			// if (DEBUG_MODE >= 2) console.log("Handling Right Nav",scrollRowRef.current.scrollLeft);
			// if (DEBUG_MODE >= 2) console.log("width", scrollRowRef.current.offsetWidth);
			
			if (scrollRowRef.current !== null)  
				// scrollRowRef.current.scrollLeft += 200;
				animateScroll({
					initialScrollPosition:scrollLeftCurrentValue, 
					scrollAmount:pixelsToScrollPerButtonPress,
					direction:"RIGHT", 
					duration:scrollDuration
					});             
	
			else 
				if (DEBUG_MODE >= 2) console.log("Error: No Ref to scroll");
		};
	
	
		//Handler to track scroll position while user is manually scrolling; allows us to display/hide nav buttons based on position
		const handleScroll = () => {
	
			if (scrollRowRef.current == null)  return;
		
			setScrollLeftCurrentValue(scrollRowRef.current.scrollLeft);
			
			// if (DEBUG_MODE >= 2) console.log("Current Scroll Left Value", scrollRowRef.current.scrollLeft);
				
		 };
	
		//
		//Animation functions for smoothly scrollings row when the user clicks a nav row button
		//  https://www.ackee.agency/blog/scroll-to-element-with-react-and-vanilla-javascript
		//  https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
		//
		
		
		const pow = Math.pow;
		
		// The easing function that makes the scroll decelerate over time
		function easeOutQuart(x) {
		 return 1 - pow(1 - x, 4);
		}
		
		//scrollAmount in pixels
		//direction is LEFT or RIGHT
		//duration in ms
		function animateScroll({initialScrollPosition, scrollAmount, direction, duration}) {
			let startTime;
			let animationFrame;
			let amountScrolled;
			let targetScrollPosition;
			var loopCount = 0;
			const maxLoopCount = 200;
			
			// if (DEBUG_MODE >= 2) console.log("Animation Scroll Called", initialScrollPosition, scrollAmount, direction, duration);
			
			//Basic checks            
			if (direction==null  || direction == undefined) return;
			if (scrollAmount == null || scrollAmount <=0) return;
	
			//Initilalize           
			const requestAnimationFrame = window.requestAnimationFrame;
			const cancelAnimationFrame = window.cancelAnimationFrame;
	
			if (direction == "LEFT") {
				targetScrollPosition = initialScrollPosition - scrollAmount;
				if (targetScrollPosition < 0) targetScrollPosition = 0;
			} else if (direction == "RIGHT") {
				targetScrollPosition = initialScrollPosition + scrollAmount;
				if (targetScrollPosition > maxScrollForRow) targetScrollPosition = maxScrollForRow;
			}
	
			// if (DEBUG_MODE >= 2) console.log("Animate Scroll initialized", " initial position:", initialScrollPosition, " scroll amount:", scrollAmount, "  target position:", targetScrollPosition);
			
			//Our callback function that is called by the browser every refresh during animation        
			function step(timestamp) {
				if (startTime === undefined) {
					// if (DEBUG_MODE >= 2) console.log("Initializing scroll animation timestep");
					startTime = timestamp;
					amountScrolled = 0;
				}
				
				const elapsedTime = timestamp - startTime;
				
				// this just gives us a number between 0 (start) and 1 (end) representing % of time that has passed
				const relativeProgress = elapsedTime / duration;
				
				// ease out that number; returns a number between 0 and 1 representing the % of the pixel amount we should have scrolled
				const easedScrollProgress = easeOutQuart(relativeProgress);
				const targetScrollAmount = scrollAmount * Math.min(easedScrollProgress, 1);
			  
				// calculate an incremental scroll amount based on our current scroll target amount 
				const incrementalScrollAmount = targetScrollAmount - amountScrolled;
			   
				// calculate new position for every tick of the requesAnimationFrame
				// Math.min ensures the element stops exactly at the intended scroll amount
				// position = initialPosition - maxAmountOfPixelsToScroll * Math.min(easedScrollProgress, 1);
				
				
				// Scroll the incremental amount
				if (direction == "LEFT")
					scrollRowRef.current.scrollLeft -= incrementalScrollAmount;
				else if (direction == "RIGHT") 
					scrollRowRef.current.scrollLeft += incrementalScrollAmount;
			
				//Update amount scrolled so far, although screen has not yet completed this amount
				amountScrolled += incrementalScrollAmount;
				
				// Stop when max scroll is actually reached by the screen
				if (direction == "RIGHT" && scrollLeftCurrentValue >= targetScrollPosition) {
				 cancelAnimationFrame(animationFrame);
				 return;
				} else if (direction == "LEFT" && scrollLeftCurrentValue <= targetScrollPosition) {
				 cancelAnimationFrame(animationFrame);
				 return;
				} else if (loopCount > maxLoopCount) {
				 cancelAnimationFrame(animationFrame);
				 if (DEBUG_MODE >= 2) console.log("Cancelled animation; max loopCount reached");
				 return;
				}       
				
				// repeat until the end is reached
				if (elapsedTime < duration) {
				 animationFrame = requestAnimationFrame(step);
				}        
			}
			
			//Initial animation request
			animationFrame = requestAnimationFrame(step);
			
		}
			
		  
		return (
			 <div style={{position:"relative"}} 
				onMouseLeave={handleMouseLeave}
				onMouseEnter={handleMouseEnter}
			>
				<div className = "TextStyle7 launchRowTitle">{title}</div>
	
				<div className={`launchRowNavigator launchRowNavigatorLeft ${(showRowNavButtons && (scrollLeftCurrentValue >0)) && "launchRowNavigatorActive"}`}
					  style={{visibility:(showRowNavButtons && (scrollLeftCurrentValue >0) ? "visible" : "hidden")}}
					  onClick={handleLeftNav}>&#171;</div>
					  
				<div className={`launchRowNavigator launchRowNavigatorRight ${(showRowNavButtons && (scrollLeftCurrentValue < maxScrollForRow)) && "launchRowNavigatorActive"}`} 
					 style={{right:"0vw", top:"0vw", visibility:(showRowNavButtons && (scrollLeftCurrentValue < maxScrollForRow) ? "visible" : "hidden")}}
					 onClick={handleRightNav} >&#187;</div>
				
				<div className={isLargeRow ? 'launchRowLarge' : "launchRow"} ref={scrollRowRef} onScroll={handleScroll}>
					{conectivities.map((conectivity) => (
						<CommunityCardConectivityPreview  handleDelete={handleDelete} handleEdit={handleEdit} key={rowKey + conectivity.id} conectivity={conectivity} isLargeCard={isLargeRow ? true : false}/>            
					))}
		  
				</div>
			 </div>
			);
		
	};
	
   //REACT USECALLBACK to avoid re-render
   // https://alexsidorenko.com/blog/react-list-rerender/
   const onChangeCallback = useCallback ((value) => {
		setSearchTerm(value);
		if (DEBUG_MODE >= 2) console.log("Search bar CALLBACK called", value);
	
	},[]);
	const onFilterChangeCallback = useCallback ((activeFilters) => {
		setActiveFilters(activeFilters);
		if (DEBUG_MODE >= 2) console.log("Search bar QUICK FILTER CALLBACK called", activeFilters);
	  
   },[]);
   
   const searchBarIsActiveCallback = useCallback ((active) => {
		setSearchActive(active);
		if (DEBUG_MODE >= 2) console.log("Search bar IS ACTIVE CALLBACK called", active);
   },[]);
   
   
/*
	function handleSearchClick () {
		
		if (DEBUG_MODE >= 2) console.log("Search click", searchActive);
		
		//Search currently active and becoming inactive?
		if (searchActive) {
			setSearchTerm(""); //Clear search term
			setConectivitiesFilteredMaster([...conectivitiesCommunity]); //Make a fresh copy of the master published conectivities; thus do a reset on the page
		}
		
		setSearchActive(!searchActive);
		
	}
		
	//Search component for top of the page t
	function searchInputBar() {
		
		return (
			<div className = "communitySearchBar" >
				<div>
					<input
						className={`TextStyle4 communitySearchInput ${searchActive && "communitySearchInputActive"}`}
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
						placeholder="phrase or category"
						disabled={!searchActive}
						/>
				</div>
				<div className="communitySearchIcon">
					<SearchIcon className="searchButton" style={{fontSize:"48px"}} onClick={handleSearchClick}/>                          
				</div>      
			</div>      
		)
	}
  */      

	 //handle close modal
	const handleCancelDetailedView = () => {
		// setPauseBannerUpdate(false); //disable buttons on main page
		setShowModalConectivityDetailed(false);
		setConectivityToView({...CONECTIVITY_INITIAL_STATE, createdByCustomerName:""});
		// if (DEBUG_MODE >= 2) console.log("Closed Conectivity Detail View.");
	};
		
	  //handle open modal
	function handleDetailedView (conectivityToViewDetailed) {
		
		const tempCustomer = customers.find(customer => customer.id === conectivityToViewDetailed.createdByCustomerID);    //All clients will have a customer array, even if only 1 entry
		if (tempCustomer) setConectivityToView({...conectivityToViewDetailed, createdByCustomerName:tempCustomer.name});
		else setConectivityToView(conectivityToViewDetailed);
		// setPauseBannerUpdate(true); //disable buttons on main page
		setShowModalConectivityDetailed(true);
		// if (DEBUG_MODE >= 2) console.log("Opened Conectivity Detail View.", conectivityToViewDetailed);
	}
	
	//Handle access by unauthenticated user
	if (authState != "signedin"  || !currentUser) {
		//   if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState == "signedout") popUpLoginWindow();
	  return null;
	}
	
	//Render function for authenticated user 
		
	//confirm access to this page; must be logged in user (CHANGED) 
  if (!currentUser) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<h3 className="sectionHeader">Launchpad</h3>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
  } else return (
	<Authenticator>
			
		 <ModalNoBackground showModal={showModalAddToPortfolio} closeCallback={handleCancelAddToPortfolio} cardColor={TEAM_COLOR}  >
			<div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt=''/>  
				</div>
				<h3 className="purple" style={{padding:"clamp(20px,5vw,50px) 0px clamp(10px,2vw,20px) 0px"}}> {isAdmin ? 'ADD TO YOUR LAUNCHPAD?' : 'INTERESTED IN ADDING TO YOUR COMPANY LAUNCHPAD?' } </h3> 
				<div className="ContainerMaxHeightCenter fullWidth" >
					<div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>

						{isAdmin ? 
						   <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>
								<div className="ContainerNoHeightFlexLeft" style={{paddingTop:"clamp(4px,1vw,10px)", paddingLeft:"clamp(4px,1vw,10px)"}}>
									<p style={{paddingRight:"clamp(4px,1vw,10px)"}}> &#9671; </p>
									<p >Once downloaded, you can customize the conectivity for your employees and corporate culture from your admin dashboard</p>
								</div>
								<div className="ContainerNoHeightFlexLeft" style={{paddingTop:"clamp(4px,1vw,10px)", paddingLeft:"clamp(4px,1vw,10px)" }}>
									<p style={{paddingRight:"clamp(4px,1vw,10px)"}}> &#9671; </p>
									<p >Come up with a fun, effective version?  Share it back to the {displayCommunityTextInline({})}</p>
								</div>
								<div className="ContainerNoHeightFlexLeft" style={{paddingTop:"clamp(4px,1vw,10px)", paddingLeft:"clamp(4px,1vw,10px)"}}>
									<p style={{paddingRight:"clamp(4px,1vw,10px)"}}> &#9671; </p>
									<p>By proceeding, you agree to Conectere’s &nbsp; 
										<a href={LEGAL_PRIVACY_POLICY} target="_blank"> Privacy Policy </a>                             
										&nbsp; and &nbsp; 
										 <a href={LEGAL_TERMS_AND_CONDITIONS} target="_blank">  Terms & Conditions </a>
									</p>
								</div>
							</div>
							:
						   <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>
								<div className="ContainerNoHeightFlexLeft" style={{paddingTop:"clamp(4px,1vw,10px)", paddingLeft:"clamp(4px,1vw,10px)" }}>
									<p style={{paddingRight:"clamp(4px,1vw,10px)"}}> &#9671; </p>
									<p >Would you like to try this conectivity with your team members and colleagues?  <b>Reach out to your wellness officer or conectere platform administrator and ask her or him to install the conectivity into your company's launchpad from the {displayCommunityTextInline({})}</b></p>
								</div>
								<div className="ContainerNoHeightFlexLeft" style={{paddingTop:"clamp(4px,1vw,10px)", paddingLeft:"clamp(4px,1vw,10px)"}}>
									<p style={{paddingRight:"clamp(4px,1vw,10px)"}}> &#9671; </p>
									<p >Once downloaded, you can launch the conectivity and invite your colleagues like all conectivities.</p>
								</div>
								{/*
								<div className="ContainerNoHeightFlexLeft" style={{paddingTop:"clamp(4px,1vw,10px)", paddingLeft:"clamp(4px,1vw,10px)" }}>
									<p style={{paddingRight:"clamp(4px,1vw,10px)"}}> &#9671; </p>
									<p >This conectivity was shared with the {displayCommunityTextInline({})} by {conectivityToProcess.createdByCustomerName}.  After completing the conectivity, be sure come back and rate the conectivity and leave a comment!</p>
								</div>
								*/}
							</div>                           
						}
					</div>
					<div className="ContainerVerticalCenter maxHeight " style={{flex:1,  padding:"clamp(8px,2vw,20px)" }}>
							<CommunityCardConectivityPreview footerTextColorClass="grey" conectivity={conectivityToProcess} disableLaunchButton disableHover/>
					</div>
				</div>


				<div className="ContainerNoHeightCenter" >
					{isAdmin ? 
					<div className="adminConectivitiesTemplateButtonsWrapper">
							<button className="buttonStyle1 buttonStyle1Purple" onClick={event => handleConfirmationAddToPortfolio (true)} >
								 <CloudDownloadIcon /> 
								 <div style={{paddingLeft:"clamp(2px,0.5vw,5px)"}}>Install</div>
							</button>                       
							<button className="buttonStyle1 buttonStyle1DavyGray"  aria-label="skip" onClick={handleCancelAddToPortfolio} >
								Cancel
							</button>
						</div>
					:
						<div className="adminConectivitiesTemplateButtonsWrapper">
								<button className="buttonStyle1 buttonStyle1DavyGray"  aria-label="skip" onClick={handleCancelAddToPortfolio} >
									Close
								</button>
						</div>
					}
				</div>
			</div>
		 </ModalNoBackground >

		 <ModalNoBackground showModal={showModalRemoveFromPortfolio} closeCallback={handleCancelAddToPortfolio} cardColor={TEAM_COLOR}  >
			<div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
				</div>
				<div className="ContainerNoHeightCenter" >
				  <div className="TextStyle4" >UNINSTALL CONECTIVITY</div>
				</div> 
				<div className="ContainerNoHeightCenter" style={{height:"100%", width:"100%", minHeight:"400px"}}>
					<div className="ContainerVerticalStart" style={{flex:1,  height:"100%", minHeight:"250px"}}>
							<div className="TextStyle3" style={{paddingTop:"0px", color: BALANCE_COLOR}}>Conectivities unistalled from your portfolio will no longer be available 
							to your employees and will be removed from your conectivity control panel</div>

						<div className="adminConectivitiesTemplateButtonsWrapper" style={{paddingTop:"40px"}}>
							<button className="adminConectivitiesButtonStyle1" aria-label="done" onClick={handleConfirmationRemoveFromPortfolio} >
								Uninstall 
							</button>
							
							<button className="adminConectivitiesButtonStyle2" style={{marginLeft:"clamp(8px,2vw,20px)"}} aria-label="skip" onClick={handleCancelAddToPortfolio} >
								Cancel
							</button>
						</div>
					</div>
					<div className="ContainerNoHeight" style={{flex:1}}>
						<CommunityCardConectivityPreview conectivity={conectivityToProcess} disableLaunchButton/>
					</div>
				</div>
			</div>
		 </ModalNoBackground >

{/*         
		<ModalNoBackground showModal={showModalSubscribeSuccess} closeCallback={event => setShowModalSubscribeSuccess(false)} cardColor={TEAM_COLOR}  >
			<div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
				</div>
				<div className="ContainerNoHeightCenter" >
				  <div className="TextStyle4" >CONECTIVITY INSTALLED</div>
				</div> 
				<div className="ContainerVerticalCenter" style={{height:"100%", width:"100%"}}>
					<div className="TextStyle4" style={{paddingTop:"30px", color: BALANCE_COLOR}}>Success! </div>  
					<div className="TextStyle3" style={{paddingTop:"30px", color: BALANCE_COLOR}}>The Conectivity has been added to your portfolio and can be managed
					in your Conectivity Dashboard</div>
				 </div>
			</div>
		 </ModalNoBackground >

		<ModalNoBackground showModal={showModalRemoveSuccess} closeCallback={event => setShowModalRemoveSuccess(false)} cardColor={TEAM_COLOR}  >
			<div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
				</div>
				<div className="ContainerNoHeightCenter" >
				  <div className="TextStyle4" >CONECTIVITY UNINSTALLED</div>
				</div> 
				<div className="ContainerVerticalCenter" style={{height:"100%", width:"100%"}}>
					<div className="TextStyle3" style={{paddingTop:"30px"}}>The Conectivity has been removed from your portfolio</div>
				 </div>
			</div>
		 </ModalNoBackground >
		 
 */}

			<ConectivityEditor  showModalEditPost={showModalEditPost} conectivityToEdit={conectivityToProcess} handleCloseEditor={handleCloseEditor}  />
			
			<ModalNoBackground showModal={showModalDelete} closeCallback={event => handleCancelDelete(false)} cardColor={TEAM_COLOR}  >
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> <img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  /> </div>
				<h3 className="white">Remove Shared Conectivity?</h3>
				<div className="modalNoBkgInnerCard" style={{padding:"10px 20px"}}>
					<DisplayCardConectivityPreview conectivityToPreview={conectivityToProcess} hideViewButton={true} expanded={true}/>
				</div>
			   
				<div className="modalNoBkgFooter flexEnd">
				  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationDelete} > Delete </button>
				  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCancelDelete} >  Cancel  </button>               
				</div>
			 </ModalNoBackground>
		 
			 <ConectivityDetailedView showModal={showModalConectivityDetailed} conectivityToView={conectivityToView} handleCancelDetailedView={handleCancelDetailedView} />
	
			<div className="communityPadPageWrapper" >
			
				<div className="ContainerVerticalCenter conectereCommunityBannerHeader ">
					<div className="conectereCommunityBannerInnerContainer TextStyle6A" style={{fontWeight:"800"}}>
						{displayConectereCommunityText ({displayFooter:true})}
					</div>
				</div>

				<SearchInputBar useDarkInput={true} useAnchorPostion={false} enableSearchInput={true} onSearchStringChangeCallback={onChangeCallback} onFilterChangeCallback={onFilterChangeCallback} searchBarIsActiveCallback={searchBarIsActiveCallback} searchTermInitialValue={searchTerm} searchActiveInitialValue={searchActive} quickFilters={QUICK_FILTERS_FOR_CONTECTIVITIES}/>

				<Banner conectivities={conectivitiesFilteredMaster} handleAddToCustomerPortfolio={handleAddToCustomerPortfolio} handleDetailedView={handleDetailedView}/>
	
				<LaunchRow title="Favorites" conectivities={conectivitiesFavorites} isLargeRow handleDelete={handleDelete} handleEdit={handleEdit} />
	
				<LaunchRow title="Recently Shared" conectivities={conectivitiesRecentlyShared} isLargeRow handleDelete={handleDelete} handleEdit={handleEdit} />
	
				<LaunchRow title="Balance" conectivities={conectivitiesBalance} handleDelete={handleDelete} handleEdit={handleEdit} />
	
				<LaunchRow title="Team Building" conectivities={conectivitiesTeam} handleDelete={handleDelete} handleEdit={handleEdit} />
				
				<LaunchRow title="Social" conectivities={conectivitiesSocial} handleDelete={handleDelete} handleEdit={handleEdit} />
	
				<LaunchRow title="Growth & Development" conectivities={conectivitiesGrowth} handleDelete={handleDelete} handleEdit={handleEdit} />
				
				<LaunchRow title="Diversity, Equity & Inclusion" conectivities={conectivitiesDEI} handleDelete={handleDelete} handleEdit={handleEdit} />
				
				<LaunchRow title="Community Service" conectivities={conectivitiesCS} handleDelete={handleDelete} handleEdit={handleEdit} />
			</div>
			
		<div className="communityPadBlankRow" > </div>

	</Authenticator>
	
  );

};

export default ConectereCommunity;


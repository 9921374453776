//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021

//  ADMIN for managing users, including user analytics

import "./adminReportEmployeesByConectivity.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH, userAnalyticsInitialState} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import {Authenticator,  } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL;


//Queries and Mutations

import { getCCTransactionsByWalletIDBySequenceNumber } from '../../shared/graphql/queries';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';

//Icons

import AssignmentIcon from '@mui/icons-material/Assignment';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context

//Our Components
import CCRadarChart from "./Components/radarChart/radarChart";
import { ConectivityGraph } from "./Components/conectivityGraph/conectivityGraph";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import CCdisplayCardUserStatsModal from "./Components/displayCardUserStatsModal/displayCardUserStatsModal";
import UserConectivitySummary from "./Components/userConectivitySummary/userConectivitySummary";

//Utils
import { getUserInitials, compareUsersByName, compareRowsByUserRank} from "../../shared/utils/userAndTeamUtils";
import {fetchRecordsByPrimaryKey, queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import { compareConectivitiesMostFavorite, setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../../shared/utils/conectivityUtils";
import {compareTransactionsByDate, compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { truncateStringWithDots, displayConectivityScoreAndRank, DisplayEmployee, displayAwards, Divider, displayTransactionAmount } from "../../shared/utils/generalUtils";
import { displayTeams } from "../../shared/utils/userAndTeamUtils";

import moment from 'moment';

//Here's the start of our Javascrpt App
const ReportEmployeesByConectivityPage = () => {
	

		const [userRows,setUserRows]=useState([]);                                    //Formatted rows for user grid
		const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);
		const [userToSummarize, setUserToSummarize] = useState(userAnalyticsInitialState);                
		const [transactionsForUserSummary,setTransactionForUserSummary] = useState([]);
		const [userIDsToSummarize, setUserIDsToSummarize] = useState(null);         //User ID to summarize  

	 //variable to control confirmation Modal if user presses DELETE; default to hidden
		const [showModalSummary, setShowModalSummary] = useState(false);  
 

	// Context
	const { authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics,  } = useContext(AuthContext); 
	const { users } = useContext(CustomerContext);      
	const { analyticsGenerated,  userAnalytics, displayReportControls, selectedUsersOptions, transactionStartDateTime, transactionEndDateTime, setSelectedReport  } = useContext(AnalyticsContext);
				
		
		//On load, set Report select option to THIS page
		useEffect(() => {
				const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "EMP_CON");
				if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
		});

		// Handle updates to context
		useEffect(() => {
				//Rebuild rows for Data Grid using updated Context
				 buildUserRows();
		}, [authState, analyticsGenerated, users, userAnalytics, selectedUsersOptions, transactionStartDateTime, transactionEndDateTime]);
		
						 
		function formatDate(inputTime) {
				
			const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
				return tempTime.format(" MMM DD, YYYY ");

		}

		//Define Grid for User Summary
		const transactionSummaryColumns = [
			{
				field: 'createdAt',
				headerName: 'Date',
				headerAlign: 'center',
				align:'center',
				minWidth: 175,
				 // resizable: true,
				color:'white',
				valueFormatter: ({ value }) => {
					const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
						return tempTime.format(" MMM DD, YYYY ");
								
				},
				renderCell: (params)=> {
						return (
								<span> 
								<center>{formatDate(params.row.createdAt)}</center>
								</span>
								)
				}

			},
			{
				field: 'name',
				headerName: 'Transaction',
				headerAlign: 'center',
				align:'center',
				minWidth: 300,
				flex:1.0,
				 // resizable: true,
				color:'white',
				renderCell: (params)=> {
						return (
								<div className="ContainerNoHeightFlexLeft" style={{width:"100%", height:"100%"}}>
										<div>
												<img src={params.row.image} height='24' />
										</div>
										<div style={{padding:'10px', fontSize:"0.8rem", color:setHeaderColorByCategory(params.row.category)}}>
												{truncateStringWithDots(params.row.name,35)}
										</div>
								</div>
								)
				},

			},
			{
				field: 'memo',
				headerName: 'Memo',
				headerAlign: 'center',
				align:'center',
				minWidth: 250,
				 // resizable: true,
				color:'white',
				renderCell: (params)=> {
						return (
								<div className="ContainerNoHeightFlexLeft" style={{width:"100%", height:"100%"}}>
										<div style={{padding:'10px', fontSize:"0.8rem", color:setHeaderColorByCategory(params.row.category)}}>
												{truncateStringWithDots(params.row.memo,30)}
										</div>
								</div>
								);
				},
			},
			{
				field: 'amount',
				headerName: 'Amount',
				headerAlign: 'center',
				align:'center',
				minWidth: 175,
				 // resizable: true,
				color:'white',
				disableExport: true,         
				renderCell: (params)=> {
						return displayTransactionAmount({amount:params.row.amount, badgesDEI:params.row.badgesDEI, badgesCS:params.row.badgesCS});
				}

			},
		];
 
		//Define User Grid Format
		//Note - resizable appears to be available only in Data Grid Pro
		
		const userColumnsAdmin = [
			{
				field: 'userRank',
				headerName: 'CONECTIVITY SCORE',
				sortable: true,
				headerAlign: 'center',
				align:'center',
				minWidth: 220,
				 // resizable: true,
				color:'white',
				renderCell: (params)=> {
						return displayConectivityScoreAndRank(params.row.relativeConectivityScore, params.row.userRank);
				}

			},
			{
				field: 'name',
				headerName: 'EMPLOYEE',
				headerAlign: 'center',
				align:'center',
				flex:1,
				minWidth: 400,        
				color:'white',
				sortable: true,
				renderCell: (params) => {
								return <DisplayEmployee name={params.row.name} initials={params.row.initials} avatarUrl={params.row.avatarUrl} permissionAnalytics={params.row.permissionAnalytics} permissionEditor={params.row.permissionEditor} permissionLaunch={params.row.permissionLaunch} />;
					},
			},

		//   {
		//     field: 'userRank',
		//     headerName: 'RANK',
		//     headerAlign: 'center',
		//     align:'center',
		//     minWidth: 150,
		//     resizable: true,
		//     background:COLOR_BLUE_HEADER,
		//     color:'white',
		//   },
			{
				field: 'conectivitiesCompleted',
				headerName: 'CONECTIVITIES',
				headerAlign: 'center',
				align:'center',
				minWidth: 165,
				 // resizable: true,
				color:'white',
				hide:true,
			},
			{
				field: 'coinsAwarded',
				type:'number',
				headerName: 'AWARDS',
				headerAlign: 'center',
				align:'center',
				minWidth: 250,
				 // resizable: true,
				color:'white',
				renderCell: (params)=> {

						return displayAwards({coins:params.row.coinsAwarded, badgesDEI:params.row.badgesDEIAwarded, badgesCS:params.row.badgesCSAwarded});

				 }
			},
		{
				field: 'badgesDEIAwarded',
				type:'number',
				headerName: 'DEI',
				width: 105,
				editable: false,
				color:'white',
				hide:true,
				 renderCell: (params)=> {
						return (
										
										<div>{!params.row.badgesDEIAwarded ? "" :
												<div>
														{params.row.badgesDEIAwarded} 
														<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
												</div>
												}
										</div>
								)
				}
				
			},
			{
				field: 'badgesCSAwarded',
				type:'number',
				headerName: 'CS',
				width: 105,
				editable: false,
				color:'white',
				hide:true,
				 renderCell: (params)=> {
						return (
										
								<div>{!params.row.badgesCSAwarded ? "" :
										<div>
												{params.row.badgesCSAwarded} 
												<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
										</div>
										}
								</div>
						)
				 }
			},

			{
				field: 'teams',
				headerName: 'TEAMS',
				headerAlign: 'center',
				align:'center',
				sortable: true,
				 // resizable: true,
				minWidth: 250,
				flex:1,
				color:'white',
				hide:(isSuperAdmin),

				},     
			{
				field: 'action',
				headerName: '',
				headerAlign: 'center',
				align:'center',
				minWidth: 100,
				 // resizable: true,
				color:'white',
				sortable: false,
				renderCell: (params)=> {

						return (
								<div className="ContainerMaxHeightSpaceBetween">
										<div className="menuDataGridListButton" >
												<Button variant="light" size="sm" >
														<AssignmentIcon className="summaryButton" onClick={()=>handleSummary(params.row.dynamoDBID)}/>
												</Button>
										</div>
							</div>
							 
						);
				}
				},        
		];



/*    
		//Set out the functions sequentially in one larger function as order matters
		async function fetchPageData() {

				setShowSpinner(true); //Show spinners

				if (authState != "signedin") return;

			 //wait for users, teams, transactions and corresponding analytics to be loaded by context
				if (!analyticsGenerated) return;

								
			//Build rows for Data Grid
				buildUserRows(users, userAnalytics);

				setShowSpinner(false); //Hide spinners

		}
				
*/


		function buildUserRows() {
				if (authState != "signedin") return;

			 //wait for users, teams, transactions and corresponding analytics to be loaded by context
				if (!analyticsGenerated) return;
				
				if (users==null) return;
				if (users.length == 0) return;

				//Filter transactions based on user selections
				var tempUsers = [];
				if (selectedUsersOptions.length===0) 
					 tempUsers = [...users];
				else 
						tempUsers = users.filter(user => selectedUsersOptions.find(userOption => userOption.id == user.id));


				if (DEBUG_MODE >= 2) console.log("Filtered users", tempUsers);
	 
				
			//Build rows for Data Grid
				var tempUserRows = [];
				
				for (var i = 0; i<tempUsers.length; i++){
						
						 //Set initial user values
						tempUserRows[i] = {
							id:i+1,
							dynamoDBID:tempUsers[i].id,
							name:tempUsers[i].firstName + (!tempUsers[i].middleName ? "" : " " + tempUsers[i].middleName.substr(0,1)) + " " + tempUsers[i].lastName,
							initials:getUserInitials(users[i]),
							title:tempUsers[i].title,
							email: tempUsers[i].email,
							teams: displayTeams(tempUsers[i]),
							avatarUrl:tempUsers[i].avatarUrl,
							permissionAnalytics: (tempUsers[i].permissionAnalytics == true ? true : false),
							permissionEditor: (tempUsers[i].permissionEditor  == true ? true : false),
							permissionLaunch: (tempUsers[i].permissionLaunch == true ? true : false),
							
							//Initialize user analytics
							relativeConectivityScore: 0,
							coinsAwarded: 0,
							conectivitiesCompleted: 0,
							badgesCSAwarded: 0,
							badgesDEIAwarded: 0,
							userRank: 0,
						};
						
						//Update with matching STATS object in User Numbers once available
						const thisUsersAnalytics = userAnalytics.find(user => user.userID === tempUsers[i].id);  // Grab match for DynamoDB ID
			 
						if (thisUsersAnalytics !== null) {
								tempUserRows[i].relativeConectivityScore= thisUsersAnalytics.relativeConectivityScore;
								tempUserRows[i].coinsAwarded = thisUsersAnalytics.coinsAwarded;
								tempUserRows[i].conectivitiesCompleted = thisUsersAnalytics.conectivitiesCompleted;
								tempUserRows[i].badgesCSAwarded = thisUsersAnalytics.badgesCSAwarded;
								tempUserRows[i].badgesDEIAwarded = thisUsersAnalytics.badgesDEIAwarded;
								tempUserRows[i].userRank = thisUsersAnalytics.userRank;
								
								// if (DEBUG_MODE >= 2) console.log("Found matching user stats object for user", thisUsersAnalytics, tempUsers[i]);
						
						}

				}
				
				//Sort the user rows by rank
				tempUserRows.sort(compareRowsByUserRank);
				
				// if (DEBUG_MODE >= 2) console.log("Built User Rows:", tempUserRows);
				
				setUserRows(tempUserRows);
		}

		// Tabs - move to a component
		const [tabIndexValue, setTabIndexValue] = useState(0);

		const handleTabChange = (event, newValue) => {
				setTabIndexValue(newValue);
		};

		
		function TabPanel({children, value, index}) {

			return (
				<div
					role="tabpanel"
					hidden={value !== index}
					id={`tabpanel-${index}`}
					aria-labelledby={`tabs-${index}`}
				>
				
				{children}
				
				</div>
			);
		}

		const handleCancelUserSummary = () => {
				setShowModalSummary(false);
		};
				
		async function handleSummary (id) {

				if (DEBUG_MODE >= 2) console.log("Displaying summary for user having DynamoDB ID:", id);

			 //Initialize an array of 1 entry as our component can summarize multiple individuals
				const tempArray = [id];
				setUserIDsToSummarize(tempArray);
				
				//TBD Update schema so we can fetch transactions by UserID and CreatedAt date
			 //Fetch user transactions
				const queryParams = {
										ccWalletID: currentUser.ccWalletID,              //Primary Key
										sequenceNumber: {ge: 0},  //Secondary Key
										limit: 5000,
								};
				
				var tempTransactions =  await queryDataTableWithPagination(getCCTransactionsByWalletIDBySequenceNumber, "getCCTransactionsByWalletIDBySequenceNumber", queryParams);
				if (DEBUG_MODE >= 2) console.log("Fetched transactions for user summary", tempTransactions);

				setShowModalSummary(true);

		}
		
		//Handle access by unauthenticated user
		if (authState != "signedin"  || !currentUser) {
			if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
			// if (authState == "signedout") popUpLoginWindow();
			return null;
		}
		
		//Render function for authenticated user 

		//confirm access to this page
	if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
			return (
					<Authenticator>
					<div>
						<p></p>
								<h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
								<center>Sorry, off limits</center>
					</div>
					</Authenticator>
			);
	} else  return (
		<Authenticator>
		
		
		
				<UserConectivitySummary 
						showUserSummary={showModalSummary} 
						transactions={transactionsForUserSummary} 
						handleCancelUserSummary={handleCancelUserSummary} 
						userIDsToSummarize={userIDsToSummarize} 
						showConectivityGraphTab={true}
				/>
				 
{/*    
				<Modal show={false && showModalSummary} onHide={handleCancelUserSummary} size="xl" centered>
						<Modal.Header closeButton>
							<Modal.Title>Employee Conectivity Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							 <div className="tabHeaderBox">
										<Tabs value={tabIndexValue} onChange={handleTabChange} aria-label="tabs">
											<Tab label="Stats" value={0} />
			{true ? "" : 
											<Tab label="Conectivity Graph" value={1} />
			}
											<Tab label="History" value={2} />
										</Tabs>
										<Divider />
									<TabPanel value={tabIndexValue} index={0}>
											 <div className="dashboardCardRow" style={{paddingBottom:"clamp(8px,2vw,20px)"}}>
														<CCdisplayCardUserStatsModal analytics={userToSummarize}/>
														<div>
																<CCRadarChart 
																	data={radarChartData} title={!radarChartTargetName ? "Conectivity Visualization" :  radarChartTargetName + " Conectivity"}  
																	dataKeyAngle="subject" 
																	radarName1={radarChartNames.length < 0 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 0 ? "" : radarChartColors[0]}
																	radarName2={radarChartNames.length < 1 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length < 1 ? "" : radarChartColors[1]}
																grid/>
														</div>
												</div>
								 </TabPanel>

				{true ? "" :

									<TabPanel value={tabIndexValue} index={1}>
										<center>
												<div className="dataGridWrapperTop" style = {{width:"1000px"}}>        
																<ConectivityGraph graphWidth="1200px" graphHeight="800px" userToGraph={userToSummarize}/>
												</div>
										</center>

									</TabPanel>

				}

									<TabPanel value={tabIndexValue} index={2}>
										<center>
												<div className="dataGridWrapperTop" >        
														<MenuDataGrid dataGridRows={transactionRowsSummary}  maxWidth={1000} rowHeight={40} dataGridColumns={transactionSummaryColumns}/>
												</div>
										</center>
								</TabPanel>
								</div>

						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>
								<div > Close </div>
							</Button>
						</Modal.Footer>
				 </Modal>    
*/}
						<div className="adminPage">
								<div className="ContainerVertical">

										<div className="ContainerNoHeightCenter">
												{displayReportControls({useUserSelect:true, useTeamSelect:false,  useDateSelect:false,  useReportSelect:true, width:"auto"})}
										</div>
										<div className="dashboardContainer boxShadow">
												<div className="dashboardTitle">
														EMPLOYEE CONECTIVITY
												 </div>   
												
												<div className="dataGridWrapperTop" >        
														<MenuDataGrid 
																title="EMPLOYEE CONECTIVITY"
																showToolBar = {true}
															 dataGridRows={userRows} 
																dataGridColumns={userColumnsAdmin} 
																// width={(isSuperAdmin) ? REPORT_DATAGRID_WIDTH_SUPERADMIN : REPORT_DATAGRID_WIDTH}
														/>

												</div>
										</div> 
								</div>
						</div>    
				</Authenticator>
	);
};

export default ReportEmployeesByConectivityPage;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


import '../styles/App.css';

import './analyticsContext.css';

import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifySignOut } from "@aws-amplify/ui-react";
import { Navigate  } from "react-router-dom";  

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, customerWideAnalyticsInitialState, REPORT_SELECTION_OPTIONS, BALANCE_COLOR, TEAM_COLOR, GROWTH_COLOR, SOCIAL_COLOR, DEI_COLOR1, CS_COLOR1} from '../data/conectereConfigData.js';

//Queries and Mutations
import { getCustomer } from '../graphql/queries';
import { getCCTransactionsByCustomerIDByCreatedAt } from '../graphql/queries'; 

//Context
import { AuthContext } from './authContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context

import Select from 'react-select';   //Version with support from multiple selections
import moment from 'moment';

//Consider perhaps whether we should not display components from a Context

//Utils
import {fetchCustomer, queryDataTableWithPagination} from "../utils/databaseUtils";
import {NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING, ONE_WEEK_AGO, ONE_MONTH_AGO, ONE_MONTH_AGO_STRING, THREE_MONTHS_AGO, THREE_MONTHS_AGO_STRING} from "../utils/dateTimeUtils";
import { displayUsersRow, DisplayUserSelectComponent, displayTeamsRow, DisplayTeamSelectComponent } from "../utils/userAndTeamUtils";
import { setHeaderColorByStringLength} from "../utils/conectivityUtils";
import {  DashboardHeader, truncateStringWithDots } from "../utils/generalUtils";
import {  calcAnalytics, setNodeAndEdgeAttributes, reduceGraphEdges } from "../utils/analyticsUtils";
import { filterTransactionsByPeriod } from "../utils/transactionsUtils.js"; 

//Icons
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Bootstrap and other React components
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@mui/material/Checkbox';

const AnalyticsContext = React.createContext();

//ANALYTICS CONTEXT
const AnalyticsProvider = ({ children }) => {
    
    //Trigger state variable
    const [analyticsGenerated, setAnalyticsGenerated] = useState(false);
    

    //LAMBDA CALCULATED ANALTYICS FOR DASHBOARDS
    const [userAnalytics, setUserAnalytics] = useState([]);
    const [teamAnalytics, setTeamAnalytics] = useState([]);
    const [customerAnalytics, setCustomerAnalytics] = useState(customerWideAnalyticsInitialState);
    
    
    const[radarChartData, setRadarChartData] = useState([]);
    const[radarChartNames, setRadarChartNames] = useState([]);
    const[radarChartColors, setRadarChartColors] = useState([]);
    const [radarChartTargetName, setRadarChartTargetName] = useState("");

    //
    //REPORTING 
    //
    
    //STATE DATA FOR TIME-SERIES GRAPH DATA
    const [timeSeriesGraphDataDay, setTimeSeriesGraphDataDay] = useState([]);
    const [timeSeriesGraphDataWeek, setTimeSeriesGraphDataWeek] = useState([]);
    const [timeSeriesGraphDataMonth, setTimeSeriesGraphDataMonth] = useState([]);
    const [timeSeriesGraphDataQuarter, setTimeSeriesGraphDataQuarter] = useState([]);
    const [timeSeriesGraphDataYear, setTimeSeriesGraphDataYear] = useState([]);

    //STATE DATA FOR ENTERPRISE VISUALIZATION GRAPH DATA
    const [networkGraphGraphDataDay, setNetworkGraphGraphDataDay] = useState({});
    const [networkGraphGraphDataWeek, setNetworkGraphGraphDataWeek] = useState({});
    const [networkGraphGraphDataMonth, setNetworkGraphGraphDataMonth] = useState({});
    const [networkGraphGraphDataQuarter, setNetworkGraphGraphDataQuarter] = useState({});
    const [networkGraphGraphDataYear, setNetworkGraphGraphDataYear] = useState({});
    const [networkGraphGraphDataOneWeek, setNetworkGraphGraphDataOneWeek] = useState({});
    const [networkGraphGraphDataOneMonth, setNetworkGraphGraphDataOneMonth] = useState({});
    const [networkGraphGraphDataTwoMonths, setNetworkGraphGraphDataTwoMonths] = useState({});
    const [networkGraphGraphDataEntirePeriod, setNetworkGraphGraphDataEntirePeriod] = useState({});
    
    //Report controls
    const [transactionStartDateTime, setTransactionStartDateTime] = useState(ONE_WEEK_AGO());               
    const [transactionEndDateTime, setTransactionEndtDateTime] = useState(NOW());               
    const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);     //for multi-select
    const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);     //for multi-select
    const [searchMode, setSearchMode] = useState(true);             //True when displaying multi-user select
    const [searchModeTeam, setSearchModeTeam] = useState(true);     //True when displaying multi-user select
    const [selectedReport, setSelectedReport] = useState(REPORT_SELECTION_OPTIONS[0]);      //Default to first report object               
    const [graphPeriod, setGraphPeriod] = useState("WEEK");    //Default to Month
    const [selectedGraph, setSelectedGraph] = useState("CONECTIVITY_BY_CAT");

    //Filters
    const [filterByBalance, setFilterByBalance] = useState(false);
    const [filterByTeam, setFilterByTeam] = useState(false);
    const [filterBySocial, setFilterBySocial] = useState(false);
    const [filterByGrowth, setFilterByGrowth] = useState(false);
    const [filterByDEI, setFilterByDEI] = useState(false);
    const [filterByCS, setFilterByCS] = useState(false);
    const [filterBySpotlights, setFilterBySpotlights] = useState(false);
    const [filterByPeriod, setFilterByPeriod] = useState(false);

    //Dynamic Graph Inputs
    const[graphTitle, setGraphTitle] = useState("");
    const[dataKeyY1, setDataKeyY1] = useState("");
    const[dataKeyY2, setDataKeyY2] = useState("");
    const[dataKeyY3, setDataKeyY3] = useState("");
    const[dataKeyY4, setDataKeyY4] = useState("");
    const[color1, setColor1] = useState("");
    const[color2, setColor2] = useState("");
    const[color3, setColor3] = useState("");
    const[color4, setColor4] = useState("");

    //Transaction cache for reports and analyltics
    const [transactionCache, setTransactionCache] = useState([]);
    const [transactionOldestStartDateTimeFetched, setTransactionOldestStartDateTimeFetched] = useState(ONE_YEAR_AGO());               
    const [transactionCacheCustomerID, setTransactionCacheCustomerID] = useState("");
    const [transactionCacheInitialized, setTransactionCacheInitialized] = useState(false);
    const [transactionCountFilteredDateRange, setTransactionCountFilteredDateRange] = useState(0)
    const [ringClosureCountFilteredDateRange, setRingClosureCountFilteredDateRange] = useState(0)
    const [conectivitiesCompleteCountFilteredDateRange, setConectivitiesCompleteonectionsCountFilteredDateRange] = useState(0)
    const [ordersCountFilteredDateRange, setOrdersCountFilteredDateRange] = useState(0)
    const [conectivityRejectionsCountFilteredDateRange, setConectivityRejectionsCountFilteredDateRange] = useState(0);
    const [transactionsFiltered, setTransactionsFiltered] = useState([]);
    
     //state variable for invoking (rendering) React redirct function
    const [redirectToPage, setRedirectToPage] = useState(null);

    //Context
    const {  authState,  currentUser,  isSuperAdmin, isAdmin, permissionAnalytics, permissionOrders, permissionBilling, } = useContext(AuthContext);
    const { selectedCustomerOptions, users, usersOptions, teams, teamsOptions   } = useContext(CustomerContext);   

   //Maintain the transaction cache
    useEffect(() => {
        updateTransactionCache();  
    }, [authState, selectedCustomerOptions, transactionStartDateTime]);


    
/*    
    // Fetch analytics records based on Auth change
    useEffect(() => {
        
        // if (DEBUG_MODE >= 2) console.log("useEffect: ANALYTICS CONTEXT", authState, selectedCustomerOptions);
        
        //Do nothing if not yet logged in the user
        if (authState != "signedin" || selectedCustomerOptions == null) {
            setAnalyticsGenerated(false);
            return;
        }

        loadAnalyticsFromDB();

    }, [authState, selectedCustomerOptions]);      //TRIGGERED ON ANY CHANGE TO AuthState OR SelectedCustomer (SuperAdmin)


    //This function loads the analytics from the backend DB and formulates the analytics context data for the app
    async function loadAnalyticsFromDB () {
    
        // var tempGraphs = [];
        var tempUserNumbers = [];
        var tempTeamNumbers = [];
        var tempCustomerNumbers = [];
        var tempTimeSeriesGraphs = [];
        var newValues = [];
        
        setShowSpinner(true); //Show spinners
        
        if (DEBUG_MODE >= 2) console.log("Loading Analytics Records for selected customer", selectedCustomerOptions);

        try {
            
            //Get user analytics
            tempUserNumbers =  await fetchRecordsByPrimaryKey(getUserAnalyticsByCustomer, "getUserAnalyticsByCustomer","customerID", selectedCustomerOptions.id);
            if (DEBUG_MODE >= 2) console.log("Loaded per user analytics", tempUserNumbers);
            setUserAnalytics(tempUserNumbers);
 
             //Get team analytics
            tempTeamNumbers =  await fetchRecordsByPrimaryKey(getTeamAnalyticsByCustomer, "getTeamAnalyticsByCustomer","customerID", selectedCustomerOptions.id);
            // if (DEBUG_MODE >= 2) console.log("Loaded per team analytics", tempTeamNumbers);
            setTeamAnalytics(tempTeamNumbers);

            //Get customer-wide analytics
            tempCustomerNumbers =  await fetchRecordsByPrimaryKey(getCustomerAnalyticsByCustomer, "getCustomerAnalyticsByCustomer","customerID", selectedCustomerOptions.id);
            
            if (tempCustomerNumbers.length > 0) {
                setCustomerAnalytics(tempCustomerNumbers[0]); //Note, this fetch should return an array with exactly 1 record since, for now, 1 customer-wide analytics record per customer
                if (DEBUG_MODE >= 2) console.log("Loaded customer-wide analytics", tempCustomerNumbers[0]);
            } else if (DEBUG_MODE >= 2) console.log("Error loading customer-wide analytics - no records found", tempCustomerNumbers);

             //Get all time-series graphs for this custiomer
            tempTimeSeriesGraphs =  await fetchRecordsByPrimaryKey(getGraphTimeSeriesByCustomer, "getGraphTimeSeriesByCustomer","customerID", selectedCustomerOptions.id);
            //  if (DEBUG_MODE >= 2) console.log("Loaded time-series graphs", tempTimeSeriesGraphs);
             
            for (var i =0; i < tempTimeSeriesGraphs.length; i++) {
                //Make a temp copy of values
                newValues =[...tempTimeSeriesGraphs[i].values.items];
                newValues.sort(compareByPeriod);
                tempTimeSeriesGraphs[i].values = newValues;

                //Store to the appropriate context state variable
                switch (tempTimeSeriesGraphs[i].period) {
                    case "DAY":
                         setTimeSeriesGraphDataDay(tempTimeSeriesGraphs[i].values);
                        break;
                    case "WEEK":
                         setTimeSeriesGraphDataWeek(tempTimeSeriesGraphs[i].values);
                        break;
                    case "MONTH":
                         setTimeSeriesGraphDataMonth(tempTimeSeriesGraphs[i].values);
                        break;
                    case "QUARTER":
                          setTimeSeriesGraphDataQuarter(tempTimeSeriesGraphs[i].values);
                        break;
                    case "YEAR":
                         setTimeSeriesGraphDataYear(tempTimeSeriesGraphs[i].values);
                        break;
                }                
            }
            
            if (DEBUG_MODE >= 2) console.log("Updated and sorted time-series graphs", tempTimeSeriesGraphs);

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error loading analytics", err);
        }
        
         setShowSpinner(false); //Hide spinners
     
        setAnalyticsGenerated(true);

    }
*/

    //
    // Transaction Cache - use for providing up-to-date reports
    //



    
    async function updateTransactionCache() {
        
        //Safety check
        if (authState !== "signedin") return;
        if (DEBUG_MODE >= 2) console.log("Transaction Cache Update Triggered", selectedCustomerOptions, authState);

        //User authorized for transactions?
        if (!isSuperAdmin && !isAdmin && !permissionOrders && !permissionBilling && !permissionAnalytics) return;


        if (!transactionStartDateTime) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: NULL TRANSACTION START DATE ");
           return;
        }

        //This check catches changes to the date that are not yet valid dates
        if (!transactionStartDateTime.toISOString()) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: NULL TRANSACTION START DATE STRING");
           return;
        }

        //Set our start of search period
        var tempTransactionStartDate = transactionStartDateTime.clone().startOf('day');

        //Start date out of range, i.e.,in the future
        if (tempTransactionStartDate.clone().isAfter(NOW())) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: TRANSACTION START DATE IN THE FUTURE; SKIPPING");
            return;
        }

 
        //Start date out of range, i.e., too long ago?
        if (tempTransactionStartDate.clone().isBefore(NOW().subtract(1,'years').startOf('day'))) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: TRANSACTION START DATE OUT OF BOUNDS; SETTING TO 1 YEAR AGO");
            tempTransactionStartDate = ONE_YEAR_AGO().startOf('day');
        }
        
       var customerIDtoQuery = currentUser.customerID; //Default to current User for company ID

         //SuperAdim and has either NOT selected any Customer OR has selected ALL CUSTOMERS?
        if (isSuperAdmin && selectedCustomerOptions !== null && selectedCustomerOptions?.value!=0) {
          customerIDtoQuery = selectedCustomerOptions.id;
        }
            
        //Have we already fetched at least the requested transactions for this particular customer?
        //Check to see if we have fetched transaction prior to OR equal to the same day
        if (transactionCacheInitialized && (customerIDtoQuery == transactionCacheCustomerID)) {
            if (transactionOldestStartDateTimeFetched.clone().startOf('day').isBefore(tempTransactionStartDate.clone().startOf('day')) ||
                    transactionOldestStartDateTimeFetched.clone().startOf('day').isSame(tempTransactionStartDate.clone().startOf('day'))) {
                // if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: ALREADY FETCHED SUFFICIENT TRANSACTIONS ");
                return;
            }
        }

        // if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: FETCHING TRANSACTIONS",  selectedCustomerOptions);
        // if (DEBUG_MODE >= 2) console.log("TransactionCacheCustomerID=" + transactionCacheCustomerID );
        // if (DEBUG_MODE >= 2) console.log("transactionOldestStartDateTimeFetched=" + transactionOldestStartDateTimeFetched.toISOString() );
        // if (DEBUG_MODE >= 2) console.log("transactionStartDateTime=" + tempTransactionStartDate.toISOString() );
        
        //Fetch the requested transactions        
        try {

            //Now, grab transactions for this Customer all the way back to the date specified by the transaction start date, which must be older than our transaction cache start date
            const queryParams = {
                        customerID: customerIDtoQuery,                                                  //Primary Key
                        createdAt: {between: [ tempTransactionStartDate.toISOString(), NOW_STRING() ]},  //Secondary Key
                    };
            
            var tempTransactions =  await queryDataTableWithPagination(getCCTransactionsByCustomerIDByCreatedAt, "getCCTransactionsByCustomerIDByCreatedAt", queryParams);
            
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: Fetched transactions for company: " + customerIDtoQuery + " back to " + tempTransactionStartDate.toISOString(), tempTransactions);
    
            //Save the transactions to our cache and update cache params
            if (tempTransactions && tempTransactions.length > 0) {

                let ordersCountFilteredDateRange = 0, conectivitiesCompleteCountFilteredDateRange = 0, ringClosureCountFilteredDateRange = 0, conectivityRejectionsCountFilteredDateRange = 0;
                for (let index=0; index < tempTransactions.length; index++) {
                    switch (tempTransactions[index].transactionType) {
                        case "RING_COMPLETION_BONUS":
                            ringClosureCountFilteredDateRange++;
                            break;
                        case "COMPLETED_CONECTIVITY":
                            conectivitiesCompleteCountFilteredDateRange++;
                            break;
                        case "CC_REDEMPTION":
                            ordersCountFilteredDateRange++;
                            break;   
                        case "REJECTED_CONECTIVITY":
                            conectivityRejectionsCountFilteredDateRange++;
                            break;                          
                    }
                }

                //Update our counts
                setConectivitiesCompleteonectionsCountFilteredDateRange(conectivitiesCompleteCountFilteredDateRange);
                setOrdersCountFilteredDateRange(ordersCountFilteredDateRange);
                setRingClosureCountFilteredDateRange(ringClosureCountFilteredDateRange);
                setConectivityRejectionsCountFilteredDateRange(conectivityRejectionsCountFilteredDateRange);

                setTransactionCacheCustomerID(customerIDtoQuery);
                setTransactionCache(tempTransactions);
                setTransactionCacheInitialized(true); 
                setTransactionCountFilteredDateRange(tempTransactions.length);
                setTransactionOldestStartDateTimeFetched(tempTransactionStartDate); 
            }

        } catch (err) { if (DEBUG_MODE >= 2) console.log('TRANSACTION CACHE: error fetching  data', err); }
        
        setAnalyticsGenerated(false);   //Retrigger generation of the analytics   
     }  
     

    // Handle updates to report context; build graphs specific to reporting selections
    // FOR NOW - UPDATE ALL ANALYTICS ON A USER CHANGE TO THE PERIOD; THIS WILL ALLOW US TO RE-CALC USER, TEAM RANKS AND PRODUCT RANKS WRT THE SELECTED PERIOD
    // THIS DEFEATS THE POINT OF PRE-CALCULATING THE NETWORK GRAPHS BUT NOTE THAT WE ARE AVOIDING LOADING TRANSACTIONS
    // AS WE HAVE A SMART CACHE
    
    useEffect(() => {
        buildAnalyticsGraphs();
    }, [transactionCache, transactionStartDateTime, transactionEndDateTime, selectedUsersOptions,
        filterByBalance, filterByGrowth, filterBySocial, filterByTeam, filterBySpotlights, filterByCS, filterByDEI, graphPeriod]);
    
    async function buildAnalyticsGraphs() {

        if (authState !== "signedin" || !transactionCache || !users || users.length === 0) return;

        //These checks catch improper dates that may occur WHILE the user is typing
        if (!transactionStartDateTime || !transactionEndDateTime) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE FILTER: NULL TRANSACTION START DATE ");
           return;
        }

        //This check catches changes to the date that are not yet valid dates
        if (!transactionStartDateTime.toISOString() || !transactionStartDateTime.toISOString()) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE FILTER: NULL TRANSACTION START DATE STRING");
           return;
        }

        const startOfSearchPeriod = transactionStartDateTime.clone().startOf('day');
        const endOfSearchPeriod = transactionEndDateTime.clone().endOf('day');
        
        // if (DEBUG_MODE >= 2) console.log("Search period", startOfSearchPeriod.toISOString(), endOfSearchPeriod.toISOString());
        
        //Filter transactions based on user selections, transaction date  and any category or transaction type filter selected
        var tempccTransactions = [];
        
        if (selectedUsersOptions.length===0) 
        
           tempccTransactions = transactionCache.filter(transaction => 
                (moment(transaction.createdAt).isAfter(startOfSearchPeriod)) &&
                (moment(transaction.createdAt).isBefore(endOfSearchPeriod)) &&
                (transactionTypeMatchesFilters(transaction)));
        else 
            tempccTransactions = transactionCache.filter(transaction => 
                ((selectedUsersOptions.find(userOption => userOption.id == transaction.userID)) &&
                (moment(transaction.createdAt).isAfter(startOfSearchPeriod)) &&
                (moment(transaction.createdAt).isBefore(endOfSearchPeriod)) &&
                (transactionTypeMatchesFilters(transaction))));


        // if (DEBUG_MODE >= 2) console.log("Filtered transactions", tempccTransactions);

        //Futher reduce transactions by any Period Selection but ONLY for certain types of reports

        if (filterByPeriod && graphPeriod && graphPeriod != "ALL") {
            tempccTransactions = filterTransactionsByPeriod(tempccTransactions, graphPeriod);        
        // if (DEBUG_MODE >= 2) console.log("Further Filtered transactions by Period", graphPeriod, tempccTransactions);

        }
        
        setTransactionsFiltered([...tempccTransactions]);
        
        //Generated new graphs based on the reporting context
        let tempCustomer = currentUser.customer; //Default to current User for company

         //SuperAdim and has either NOT selected any Customer OR has selected ALL CUSTOMERS?
        if (isSuperAdmin && selectedCustomerOptions !== null && selectedCustomerOptions?.value!=0) {
             //Fetch just the selected customer
              tempCustomer = await fetchCustomer(selectedCustomerOptions.id); 
        }

        //Call our main analytics routine that processes transactions
        //Instead of storing to DB, grab the return data
        const {
            calculatedUserNumbers, 
            calculatedTeamNumbers, 
            calculatedCustomerWideAnalytics, 
            calculatedTimeSeriesGraphDataDay, 
            calculatedTimeSeriesGraphDataWeek, 
            calculatedTimeSeriesGraphDataMonth, 
            calculatedTimeSeriesGraphDataQuarter, 
            calculatedTimeSeriesGraphDataYear, 
            calculatedGraphDataAllDay,
            calculatedGraphDataAllWeek,
            calculatedGraphDataAllMonth,
            calculatedGraphDataAllOneWeek,
            calculatedGraphDataAllOneMonth,
            calculatedGraphDataAllTwoMonths,
            calculatedGraphDataAllQuarter,
            calculatedGraphDataAllYear,
            calculatedGraphDataAllEntirePeriod, 
            customerNodeArray,
            
        } = await calcAnalytics({
            customer: tempCustomer,
            users:users,
            teams:teams,
            transactions:tempccTransactions,
            
        });   //rebuild the graphs based on the transactions

 
        //  if (DEBUG_MODE >= 2) console.log("CUSTOMER NODE ARRAY - Analytics engine returned", customerNodeArray);

        //Store the graph data
        setUserAnalytics(calculatedUserNumbers); 
        setTeamAnalytics(calculatedTeamNumbers); 
        setCustomerAnalytics(calculatedCustomerWideAnalytics); 
        
        setTimeSeriesGraphDataDay(calculatedTimeSeriesGraphDataDay); 
        setTimeSeriesGraphDataWeek(calculatedTimeSeriesGraphDataWeek); 
        setTimeSeriesGraphDataMonth(calculatedTimeSeriesGraphDataMonth); 
        setTimeSeriesGraphDataQuarter(calculatedTimeSeriesGraphDataQuarter); 
        setTimeSeriesGraphDataYear(calculatedTimeSeriesGraphDataYear); 

        //Update the nodes in each graph to be a SEPERATE COPY of the common master node list
        if (customerNodeArray && customerNodeArray.length > 0) {
            calculatedGraphDataAllDay.nodes =  customerNodeArray.map(node => {return {...node}});  
            calculatedGraphDataAllWeek.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllMonth.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllOneWeek.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllOneMonth.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllTwoMonths.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllQuarter.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllYear.nodes = customerNodeArray.map(node => {return {...node}});
            calculatedGraphDataAllEntirePeriod.nodes = customerNodeArray.map(node => {return {...node}});
        }
 
        //Set edge counts for each node, adjust labels and set color of each node based on the max connectivity for any given user in the particular graph
        setNodeAndEdgeAttributes(calculatedGraphDataAllDay);
        setNodeAndEdgeAttributes(calculatedGraphDataAllWeek);
        setNodeAndEdgeAttributes(calculatedGraphDataAllMonth);
        setNodeAndEdgeAttributes(calculatedGraphDataAllOneWeek);
        setNodeAndEdgeAttributes(calculatedGraphDataAllOneMonth);
        setNodeAndEdgeAttributes(calculatedGraphDataAllTwoMonths);
        setNodeAndEdgeAttributes(calculatedGraphDataAllQuarter);
        setNodeAndEdgeAttributes(calculatedGraphDataAllYear);
        setNodeAndEdgeAttributes(calculatedGraphDataAllEntirePeriod);

/*
        if (DEBUG_MODE >= 2) console.log("Analytics engine returned", 
            calculatedUserNumbers, 
            calculatedTeamNumbers, 
            calculatedCustomerWideAnalytics, 
            // calculatedTimeSeriesGraphDataDay, 
            // calculatedTimeSeriesGraphDataWeek, 
            // calculatedTimeSeriesGraphDataMonth, 
            // calculatedTimeSeriesGraphDataQuarter, 
            // calculatedTimeSeriesGraphDataYear,            

            // calculatedGraphDataAllDay,
            // calculatedGraphDataAllWeek,
            // calculatedGraphDataAllMonth,
            // calculatedGraphDataAllOneWeek,
            // calculatedGraphDataAllOneMonth,
            // calculatedGraphDataAllTwoMonths,
            // calculatedGraphDataAllQuarter,
            // calculatedGraphDataAllYear,
            // calculatedGraphDataAllEntirePeriod
            // customerNodeArray,
        );
*/
        //Process the network graphs to remove duplicate edges so only a single edge between pairs of nodes
        // reduceGraphEdges(calculatedGraphDataAllDay);
        // reduceGraphEdges(calculatedGraphDataAllWeek);
        // reduceGraphEdges(calculatedGraphDataAllMonth);
        // reduceGraphEdges(calculatedGraphDataAllOneWeek);
        // reduceGraphEdges(calculatedGraphDataAllOneMonth);
        // reduceGraphEdges(calculatedGraphDataAllTwoMonths);
        // reduceGraphEdges(calculatedGraphDataAllQuarter);
        // reduceGraphEdges(calculatedGraphDataAllYear);
        // reduceGraphEdges(calculatedGraphDataAllEntirePeriod);

        //Store the graphs
        setNetworkGraphGraphDataDay({...calculatedGraphDataAllDay});
        setNetworkGraphGraphDataWeek({...calculatedGraphDataAllWeek});
        setNetworkGraphGraphDataMonth({...calculatedGraphDataAllMonth});
        setNetworkGraphGraphDataOneWeek({...calculatedGraphDataAllOneWeek});
        setNetworkGraphGraphDataOneMonth({...calculatedGraphDataAllOneMonth});
        setNetworkGraphGraphDataTwoMonths({...calculatedGraphDataAllTwoMonths});
        setNetworkGraphGraphDataQuarter({...calculatedGraphDataAllQuarter});
        setNetworkGraphGraphDataYear({...calculatedGraphDataAllYear});
        setNetworkGraphGraphDataEntirePeriod({...calculatedGraphDataAllEntirePeriod});

        //Trigger other pages that our analytics have loaded
        setAnalyticsGenerated(true);
        
    }

    function transactionTypeMatchesFilters(transaction) {
        
        if (!transaction) return false;

        //A filter checked?
        if (!filterByBalance 
            && !filterBySocial 
            && !filterByTeam 
            && !filterByGrowth 
            && !filterByDEI 
            && !filterByCS 
            && !filterBySpotlights) return true;
    
        //Must be a filter, so return TRUE if it matches at least one of the filters
        if (filterByBalance && transaction.category == "STRESS") return true; 
        if (filterBySocial && transaction.category == "SOCIAL") return true; 
        if (filterByTeam && transaction.category == "TEAM") return true; 
        if (filterByGrowth && transaction.category == "PERSONAL") return true; 
        if (filterBySpotlights && transaction.transactionType == "SPOTLIGHT_SENT") return true; 
        if (filterByCS && transaction.badgesCS > 0) return true; 
        if (filterByDEI && transaction.badgesDEI > 0) return true; 
        
        return false;       
    }
    
    // Fetch analytics records based on Auth change
    useEffect(() => {
        
        //Do nothing if not yet logged in the user
        if (authState != "signedin" ||  !analyticsGenerated) {
            // if (DEBUG_MODE >= 2) console.log("Radar chart not initialized yet", authState, analyticsGenerated);
            return;
        }

        // if (DEBUG_MODE >= 2) console.log("Creating initial Radar Chart");
        
        computeRadarChartData();        
        
/*        
        const tempRadarChartData = computeInitalRadarChartData(customerAnalytics);

        setRadarChartData(tempRadarChartData);  
        
        const companyName = currentUser.customer.name ? truncateStringWithDots(currentUser.customer.name, 10) : "Company";

        setRadarChartNames([
            companyName + " " + customerAnalytics.avgRelativeConectivityScoreByUsers.toFixed(1),

            // "Company Avg (" + customerAnalytics.avgRelativeConectivityScoreByUsers.toFixed(1) + ")",
            ]);  //Make an array with one element for now
            
        setRadarChartColors ([
            TEAM_COLOR,
            ]);
*/
  
    }, [customerAnalytics, analyticsGenerated]);      //TRIGGERED change to underlying customer analytics, such as when a different customer is selected

    
   function computeRadarChartData(id, teamFlag) {

        //Safety checks
        if (!currentUser || !currentUser.customer || !analyticsGenerated) return;
        
        // if (id) if (DEBUG_MODE >= 2) console.log("computing radar chart data for userID:", id, customerAnalytics);
        // else  if (DEBUG_MODE >= 2) console.log("Computing radar chart for just Company Wide view");

        //Get user / team
        const companyName = currentUser.customer.name ? truncateStringWithDots(currentUser.customer.name, 10) : "Company";
        let entity;
        
        if (id && teamFlag == true) {
            // if (DEBUG_MODE >= 2) console.log("Radar graph, finding TeamID ", id);
            entity = teamAnalytics.find(team => team.teamID == id);
            if (entity == undefined) {
                if (DEBUG_MODE >= 2) console.log("Error - no entity for request radar graph");
                return;
            }            
        } else if (id) {
            entity = userAnalytics.find(user => user.userID == id);
            // if (DEBUG_MODE >= 2) console.log("Radar graph, finding UserID ", id);

            if (entity == undefined) {
                if (DEBUG_MODE >= 2) console.log("Error - no entity for request radar graph");
                return;
            }
        }
        
        // if (id) if (DEBUG_MODE >= 2) console.log("Radar graph, entity found", entity);

        if (id)
            setRadarChartTargetName(entity.name);  //Set the name for display in the Radar Chart title
        else
            setRadarChartTargetName(currentUser.customer.name); //Set the Company name for display in the Radar Chart title

        
        //If the user is above the company average then graph out of a full 1.0 scale
       
        var fullMark = 1.0;
        let entityLabel, customerLabel, tempRadarChartData, tempRadarChartNames, tempRadarChartColors;


        //Generating data for a specific USER or TEAM?
        if (id) {        
             //Select order to put smallest graph on top
            if (entity.relativeConectivityScore < customerAnalytics.avgRelativeConectivityScoreByUsers) {
                
                //If the user is below the company average then set the scale to the company average so as to enlarge the graphs
                // fullMark = setRadarGraphFullMarkBasedOnCompanyAverages();
                customerLabel = 'A';
                entityLabel = 'B';
               tempRadarChartNames = [
                    companyName + " (" + customerAnalytics.avgRelativeConectivityScoreByUsers.toFixed(1) + ")",
                    entity.name + " (" + entity.relativeConectivityScore.toFixed(1) + ")",
                    ];  //Make an array with one element for now
                    
                tempRadarChartColors = [
                    TEAM_COLOR,
                    SOCIAL_COLOR
                    ];
                
            } else {
                entityLabel = 'A';
                customerLabel = 'B';
                tempRadarChartNames = [
                    entity.name + " (" + entity.relativeConectivityScore.toFixed(1) + ")",
                    companyName + " (" + customerAnalytics.avgRelativeConectivityScoreByUsers.toFixed(1) + ")",
                    ];  //Make an array with one element for now
        
                tempRadarChartColors = [
                    SOCIAL_COLOR,
                    TEAM_COLOR,
                    ];           
            }
    
    
                tempRadarChartData = [{
                    // subject: 'Balance',
                    [customerLabel]: (customerAnalytics.stressComponent ? customerAnalytics.stressComponent : 0),
                    [entityLabel]: (entity.stressComponent ? entity.stressComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Team',
                    [customerLabel]: (customerAnalytics.teamComponent ? customerAnalytics.teamComponent : 0),
                    [entityLabel]: (entity.teamComponent ? entity.teamComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Personal',
                    [customerLabel]: (customerAnalytics.individualComponent ? customerAnalytics.individualComponent : 0),
                    [entityLabel]: (entity.individualComponent ? entity.individualComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Social',
                    [customerLabel]: (customerAnalytics.socialComponent ? customerAnalytics.socialComponent : 0),
                    [entityLabel]: (entity.socialComponent ? entity.socialComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'DEI',
                    [customerLabel]: (customerAnalytics.badgesDEIComponent ? customerAnalytics.badgesDEIComponent : 0),
                    [entityLabel]: (entity.stressComponent ? entity.badgesDEIComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Community',
                    [customerLabel]: (customerAnalytics.maxCSBadgesEarnedByUsers ? customerAnalytics.avgCSBadgesEarnedByUsers / customerAnalytics.maxCSBadgesEarnedByUsers : 0),
                    [entityLabel]: (entity.badgesCSComponent ? entity.badgesCSComponent : 0),
                    //fullMark: fullMark,
                  },
                ];    
        } else {

            //Company view only
            
            //Select order to put smallest graph on top

                //If the user is below the company average then set the scale to the company average so as to enlarge the graphs
                // fullMark = setRadarGraphFullMarkBasedOnCompanyAverages();
                customerLabel = 'A';
                tempRadarChartNames = [
                    companyName + " (" + customerAnalytics.avgRelativeConectivityScoreByUsers.toFixed(1) + ")",
                    ];  
                    
                tempRadarChartColors = [
                    TEAM_COLOR,
                    SOCIAL_COLOR
                    ];
    
    
                tempRadarChartData = [{
                    // subject: 'Balance',
                    [customerLabel]: (customerAnalytics.stressComponent ? customerAnalytics.stressComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Team',
                    [customerLabel]: (customerAnalytics.teamComponent ? customerAnalytics.teamComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Personal',
                    [customerLabel]: (customerAnalytics.individualComponent ? customerAnalytics.individualComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Social',
                    [customerLabel]: (customerAnalytics.socialComponent ? customerAnalytics.socialComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'DEI',
                    [customerLabel]: (customerAnalytics.badgesDEIComponent ? customerAnalytics.badgesDEIComponent : 0),
                    //fullMark: fullMark,
                  },
                  {
                    // subject: 'Community',
                    [customerLabel]: (customerAnalytics.badgesCSComponent ? customerAnalytics.badgesCSComponent : 0),
                    //fullMark: fullMark,
                  },
                ];             
        }


        // if (DEBUG_MODE >= 2) console.log("New radar chart data", tempRadarChartData, tempRadarChartNames, tempRadarChartColors);
        
        
        setRadarChartColors(tempRadarChartColors);
        setRadarChartData(tempRadarChartData);    
        setRadarChartNames(tempRadarChartNames);
        
    }
    
    function computeRadarChartDataTeam(id) {
        computeRadarChartData(id, true);
    }

    const handleSelectReport = (selectedReportOption) => {
    
    if (DEBUG_MODE >= 2) console.log("Select Report Called", selectedReportOption);
    
    setSelectedReport(selectedReportOption);
    
    if (selectedReportOption.report) setRedirectToPage(selectedReportOption.report); //Jump to User Home on login
    
    };

    const handleGraphSelection = (event) => {

        setSelectedGraph(event.target.value);
 
    };
      
    const handlePeriodSelection = (event) => {
        
        setGraphPeriod(event.target.value);

    };  

// usePeriodType - Use the full range of periods
// usePeriodTypeTwoSelect - Do not use 1W, 1M and 2M.  Use labels D,W,M,Q Y
// usePeriodTypeThreeSelect - Do not use 1W, 1M and 2M.  Use labels D,WTD,MTD,QTQ YTD

 function displayReportControls({
        hideDisabledFields, hideTitle, useUserSelect, useTeamSelect, useDateSelect, useReportSelect, width, doNotShowAll, 
        usePeriodSelect, usePeriodTypeTwoSelect, usePeriodTypeThreeSelect, useGraphItemSelect, minHeight, noBoxShadow,
        showTransactionsCount, showOrdersCount, showConectivitiesCount, showSkippedCount, showRingClosuresCount,
     }) {

    
    if (!width) width = "100%";
    if (!minHeight) minHeight = "100px";

    if (!selectedUsersOptions) return null;
    
    setFilterByPeriod(usePeriodTypeTwoSelect ? false : true);   //Store whether to filter transactions by the  period or not when selected by the suer
                                                                //For PeriodTypeTwo - we do NOT filter since this is used to graph conectivities over type where period is the granularity
    return (        
        <div className={`ContainerNoHeightFlexLeftTop wrap analyticsReportControlsContainer ${noBoxShadow && "noBoxShadow"}`} style={{zIndex:"50", minHeight:minHeight, width:width}} >
        
            {true || hideTitle ? null : <DashboardHeader header={'REPORT CENTER'}  color={TEAM_COLOR} hideBorder/>}
    
            {hideDisabledFields && !useReportSelect ? null :
                <div className="ContainerVerticalLeft" style={{height:"100%", zIndex:"100", padding:"0.3rem 0.8rem"}}>

                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Report</div>
        
 
                    <div className="ContainerNoHeightWrap maxHeight fullWidth" style={{minWidth:"275px"}}>
                        <Select  isMulti={false} name="categoryDropdownModal" options={REPORT_SELECTION_OPTIONS} onChange={handleSelectReport} 
                          value={selectedReport} 
                          placeholder=" - select report -"
                          formatOptionLabel={report => (
                                   <div className="ContainerMaxHeightCenter noWrap" style={{whiteSpace:"nowrap", padding:"0rem 0.3rem"}}>
                                          <span className="TextStyle4" >{report.label}</span>
                                    </div>
                             )}
                          />
                    </div>
                </div>
            }  

          <div className="ContainerNoHeightFlexLeftTop" >

             {hideDisabledFields &&  !useDateSelect ? null :
                <div className="ContainerVerticalLeft" style={{height:"100%",  zIndex:"99", padding:"0.3rem 0.8rem"}}>

                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Date Range</div>
        
                    <div className="ContainerNoHeightFlexLeft" style={{ borderRadius:"10px",  flexWrap:"wrap",  }}>
                        <div className="ContainerNoHeightFlexLeft" style={{zIndex:"99", alignItems:"flex-start", position:"relative", maxWidth:"12.0rem"}}>
                             <div style={{borderRadius:"10px", backgroundColor:"white"}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <DatePicker
                                        // minDate={NOW()}
                                        // maxdate={SIX_MONTHS_FROM_NOW()}
                                        disabled={!useDateSelect}
                                        label="From"
                                        value={transactionStartDateTime}
                                        onChange={(newDateValue) => {
                                          setTransactionStartDateTime(newDateValue);
                                          if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
                                        }}
                                        // renderInput={(params) => <TextField size="small" {...params} />}
                                      />
                                </LocalizationProvider>
                            </div>
                        </div>
        
        
                       <div className="ContainerNoHeightFlexLeft" style={{zIndex:"99", alignItems:"flex-start", position:"relative", maxWidth:"12.0rem"}}>
                             <div style={{paddingLeft:"clamp(4px,1vw,10px)", backgroundColor:"white"}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <DatePicker
                                        // minDate={NOW()}
                                        // maxdate={SIX_MONTHS_FROM_NOW()}
                                        disabled={!useDateSelect}
                                        label="To"
                                        value={transactionEndDateTime}
                                        onChange={(newDateValue) => {
                                          setTransactionEndtDateTime(newDateValue);
                                          if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
                                        }}
                                        // renderInput={(params) => <TextField size="small" {...params} />}
                                      />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>    
                </div>
            }
            
             {!showTransactionsCount ?   null   :
                <div className="ContainerVerticalStart" style={{height:"100%", padding:"0.3rem 0.8rem"}}>
                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Transactions</div>
                     <div className="analyticsTransactionNumbers" style={{borderColor:SOCIAL_COLOR}}>
                        {transactionCountFilteredDateRange}
                    </div>
                </div>
            }

            {!showConectivitiesCount ?   null  :
                <div className="ContainerVerticalStart" style={{height:"100%", padding:"0.3rem 0.8rem"}}>
                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Conectivities</div>
                    <div className="analyticsTransactionNumbers" style={{borderColor:SOCIAL_COLOR}}>
                        {conectivitiesCompleteCountFilteredDateRange}
                    </div>
                </div>
            }

            {!showOrdersCount ?  null   :
                <div className="ContainerVerticalStart" style={{height:"100%", padding:"0.3rem 0.8rem"}}>
                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Orders</div>
                    <div className="analyticsTransactionNumbers" style={{borderColor:SOCIAL_COLOR}}>
                        {ordersCountFilteredDateRange}
                    </div>
                </div>
            }


            {!showRingClosuresCount ?  null  :
                <div className="ContainerVerticalStart" style={{height:"100%", padding:"0.3rem 0.8rem"}}>
                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Ring Closures</div>
                    <div className="analyticsTransactionNumbers" style={{borderColor:SOCIAL_COLOR}}>
                        {ringClosureCountFilteredDateRange}
                    </div>
                </div>
            }

            {!showSkippedCount ?  null  :
                <div className="ContainerVerticalStart" style={{height:"100%", padding:"0.3rem 0.8rem"}}>
                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Skipped Conectivities</div>
                    <div className="analyticsTransactionNumbers" style={{borderColor:SOCIAL_COLOR}}>
                        {conectivityRejectionsCountFilteredDateRange}
                    </div>
                </div>
            }

        </div> {/*End Report and Date Selection Row */}


        {/*EMPLOYEES select */}

            {hideDisabledFields && !useUserSelect ? null :
                <div className="ContainerVerticalLeft" style={{height:"100%", zIndex:"9", padding:"0.3rem 0.8rem"}}>

                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Employees</div>
        
                    <div className = "ContainerNoHeightFlexLeft" style={{borderRadius:"10px", }}>

                        <IconButton disabled={!useUserSelect} size="small" onClick={() => setSearchMode(!searchMode)}>
                            <SearchIcon style={{cursor:"pointer", fontSize:"2.0rem", color:"#5DADE2", fontWeight: "500"}} />                          
                        </IconButton>

        
                       {!searchMode && selectedUsersOptions.length == 0 && !doNotShowAll ?
                                <div className="ContainerNoHeightFlexLeft" >
                                        <div className="avatarContainer">
                                            <div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength("ALL")}}>
                                                <div className="avatarInitials" > ALL</div> 
                                            </div>
                                        </div>
                                    <div style={{width:"15px"}}></div>
                                </div>
                        :
    
                            <>
                                {searchMode ? 
                                    <DisplayUserSelectComponent usersOptions={usersOptions} handleSelectUser={setSelectedUsersOptions} isMulti={true} selectedUsersOptions={selectedUsersOptions} disabled={!useUserSelect} />
                                :
                                    displayUsersRow({usersToShow:selectedUsersOptions, numUsersToShow:7})
                                }
                           </>
                       }
                     </div>
            
                </div>
            }

        {/*TEAMS select */}

            {hideDisabledFields && !useTeamSelect ? null :
                <div className="ContainerVerticalLeft" style={{height:"100%", zIndex:"8", padding:"0.3rem 0.8rem"}}>

                    <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Teams</div>
        
                    <div className = "ContainerNoHeightFlexLeft" style={{borderRadius:"10px", }}>

                        <IconButton  size="small" disabled={!useTeamSelect} onClick={() => setSearchModeTeam(!searchModeTeam)}>
                            <SearchIcon style={{cursor:"pointer", fontSize:"2.0rem", color:"#5DADE2", fontWeight: "500"}} />                          
                        </IconButton>

        
                       {!searchModeTeam && selectedTeamsOptions.length == 0 && !doNotShowAll ?
                                <div className="ContainerNoHeightFlexLeft" >
                                        <div className="avatarContainer">
                                            <div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength("ALL")}}>
                                                <div className="avatarInitials" > ALL</div> 
                                            </div>
                                        </div>
                                    <div style={{width:"15px"}}></div>
                                </div>
                        :
    
                            <div className="ContainerNoHeightWrap" >
                                <div style={{zIndex:"77"}}>
                                
                                        {searchModeTeam ? 
                                       
                                            <DisplayTeamSelectComponent teamsOptions={teamsOptions} handleSelectTeam={setSelectedTeamsOptions} isMulti={true} selectedTeamsOptions={selectedTeamsOptions} disabled={!useTeamSelect} />
                                        :
                        
                                        displayTeamsRow({teamsToShow:selectedTeamsOptions, numTeamsToShow:7})
                                        }
                                </div>
                            </div>
                       }
                     </div>
                </div>
            }

        {/*PERIOD select */}

        {hideDisabledFields && !usePeriodSelect && !usePeriodTypeTwoSelect ? null :
            <div className="ContainerVerticalLeft" style={{height:"100%", zIndex:"7", padding:"0.3rem 0.8rem",  borderRadius:"10px"}}>

                <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>{usePeriodTypeTwoSelect ? "Frequency" : "Time Period"}</div>
    
                <div className = "ContainerNoHeightFlexLeft" style={{flexWrap:"wrap", borderRadius:"10px", border:"1px solid grey",}}>

                       <div  className="ContainerNoHeightFlexLeftWrap"   style={{zIndex:"55",  borderRadius:"10px", padding:"0px 10px", backgroundColor:"white"}}>
                             <RadioGroup row aria-label="graphPeriod" name="graphPeriod" value={graphPeriod} onChange={handlePeriodSelection}>
                                  <FormControlLabel value="DAY" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label="1D" />
                                  <FormControlLabel value="WEEK" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label={usePeriodTypeTwoSelect ? "Week" : "WTD"} /> 
                                  { usePeriodTypeTwoSelect ? null : <FormControlLabel value="1W" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label="1W" /> }
                                  <FormControlLabel value="MONTH" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label={usePeriodTypeTwoSelect ? "Month" : "MTD"} /> 
                                  { usePeriodTypeTwoSelect ? null : <FormControlLabel value="1M" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label="1M" /> }
                                  { usePeriodTypeTwoSelect ? null : <FormControlLabel value="2M" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label="2M" /> }
                                  <FormControlLabel value="QUARTER" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label={usePeriodTypeTwoSelect ? "Quarter" : "QTD"} />
                                  <FormControlLabel value="YEAR" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label={usePeriodTypeTwoSelect ? "Year" : "YTD"} />
                                  {usePeriodTypeTwoSelect ? null : <FormControlLabel value="ALL" control={<Radio disabled={!usePeriodSelect && !usePeriodTypeTwoSelect} />} label="ALL" /> }
                            </RadioGroup>
                        </div>
                 </div>
            </div>
        }
        

        {/*GRAPH ITEM select */}

        {hideDisabledFields && !useGraphItemSelect ? null :
            <div className="ContainerVerticalLeft" style={{height:"100%", zIndex:"6", padding:"0.3rem 0.8rem"}}>

                <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Graph Item</div>
    
                <div className = "ContainerNoHeightFlexLeft" style={{borderRadius:"10px", border:"1px solid grey",}}>

                       <div className="ContainerNoHeightFlexLeftWrap"  style={{zIndex:"44",  borderRadius:"10px", padding:"0px 10px", backgroundColor:"white"}}>
                                <RadioGroup row aria-label="byCoin" name="byCoin" value={selectedGraph} onChange={handleGraphSelection}>
                                    {/* <FormControlLabel value="TOT_CONECTIVITY" control={<Radio disabled={!useGraphItemSelect} />} label="Employee Conectivities" /> */}
                                    <FormControlLabel value="CONECTIVITY_BY_CAT" control={<Radio disabled={!useGraphItemSelect} />} label="Conectivities" />
                                    {/* <FormControlLabel value="TOT_COINS" control={<Radio disabled={!useGraphItemSelect} />} label="Coins Earned" /> */}
                                    <FormControlLabel value="COINS_BY_CAT" control={<Radio disabled={!useGraphItemSelect} />} label="Coins" />
                                    <FormControlLabel value="BADGES" control={<Radio disabled={!useGraphItemSelect} />} label="Badges" />
                                </RadioGroup>
                        </div>
    
                   
                 </div>
        
            </div>
        }

        {/*CATEGORY select */}
            
       {hideDisabledFields && !useGraphItemSelect ? null :
            <div className="ContainerVerticalLeft" style={{height:"100%", zIndex:"5", padding:"0.3rem 0.8rem"}}>

                <div className="TextStyle4" style={{paddingBottom:"0.3rem"}}>Filter</div>
                   <div className="ContainerNoHeightFlexLeftWrap" style={{zIndex:"44",  borderRadius:"10px", border:"1px solid grey",padding:"0px 10px",}}>

                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterByBalance}
                                      onChange={()=>{
                                        setFilterByBalance(!filterByBalance);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >Balance</div>
                                </div>                       


                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterBySocial}
                                      onChange={()=>{
                                        setFilterBySocial(!filterBySocial);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >Social</div>
                                </div>  
                                
                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterByTeam}
                                      onChange={()=>{
                                        setFilterByTeam(!filterByTeam);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >Team</div>
                                </div>  
                                
                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterByGrowth}
                                      onChange={()=>{
                                        setFilterByGrowth(!filterByGrowth);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >Growth</div>
                                </div>  
                                
                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterByDEI}
                                      onChange={()=>{
                                        setFilterByDEI(!filterByDEI);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >DEI</div>
                                </div>  
                                
                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterByCS}
                                      onChange={()=>{
                                        setFilterByCS(!filterByCS);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >Community</div>
                                </div>  
                                
                                 <div className="ContainerNoHeightFlexLeft">
                                   <Checkbox
                                      disabled={!useGraphItemSelect}
                                      size="small" 
                                      checked={filterBySpotlights}
                                      onChange={()=>{
                                        setFilterBySpotlights(!filterBySpotlights);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <div style={{fontSize:"1.0rem", fontWeight:"400", color:"#409cf7"}} >Spotlights</div>
                                </div>  
                    </div>
            </div>
        }   

    </div>
    );
} 

    //return the provider
   return (
    <div>
        <AnalyticsContext.Provider value={
        {   
            analyticsGenerated,
            userAnalytics, teamAnalytics, customerAnalytics,
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData, computeRadarChartDataTeam,
            timeSeriesGraphDataDay, timeSeriesGraphDataWeek, timeSeriesGraphDataMonth, timeSeriesGraphDataQuarter, timeSeriesGraphDataYear,                      //Time Series Data
            setTimeSeriesGraphDataDay, setTimeSeriesGraphDataWeek, setTimeSeriesGraphDataMonth, setTimeSeriesGraphDataQuarter, setTimeSeriesGraphDataYear,                      //Time Series Data
            displayReportControls, graphPeriod, selectedGraph, selectedUsersOptions, selectedTeamsOptions, transactionStartDateTime, transactionEndDateTime, selectedReport, setSelectedReport,
            graphTitle, dataKeyY1, dataKeyY2, dataKeyY3, dataKeyY4, color1, color2, color3, color4,
            transactionCache, transactionOldestStartDateTimeFetched, transactionCacheCustomerID, transactionCacheInitialized, transactionCountFilteredDateRange, transactionsFiltered,
            networkGraphGraphDataDay,   networkGraphGraphDataWeek,  networkGraphGraphDataMonth,  networkGraphGraphDataQuarter, networkGraphGraphDataYear, 
            networkGraphGraphDataOneWeek, networkGraphGraphDataOneMonth, networkGraphGraphDataTwoMonths, networkGraphGraphDataEntirePeriod,
        }}>
          {children}
        </AnalyticsContext.Provider>
                
       {!redirectToPage ? "" : <Navigate to={redirectToPage} />} 


    </div>
  ); 
    
};


export { AnalyticsContext, AnalyticsProvider };
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import React from 'react';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_GOLD } from '../../../../shared/data/conectereConfigData';


//Utils

//React component
const DisplayCardSpotlightStats = React.memo(({analytics}) => {

    const title="SPOTLIGHT STATISTICS";
    
    const DATA_PERIOD_IN_DAYS = 30;
    
    const footer="*past " + DATA_PERIOD_IN_DAYS + " days";

    // if (DEBUG_MODE >= 2) console.log("Spotlight Stats Card called", analytics);

    return (

        <div className="dashboardCard">

            <div className="dashboardCardTextRow">
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreCircle" style={{borderColor:BALANCE_COLOR}}>
                        {analytics.spotlightsSentTotal}
                    </div>
                    <div className="dashboardCardText">Spotlights Sent</div>
                </div>
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreCircle" style={{borderColor:TEAM_COLOR}}>
                        {analytics.percentEmployeesRecognized}%
                    </div>
                     <div className="dashboardCardText">Employees Recognized</div>
                </div>                   
            </div >
            
            <div className="dashboardCardTextRow" >
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreCircle" style={{borderColor:SOCIAL_COLOR}}>
                        { analytics.avgSpotlightsSent}
                    </div>
                    <div className="dashboardCardText">Avg Sent Per Emp</div>
                </div>
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreCircle" style={{borderColor:GROWTH_COLOR}}>
                        { analytics.avgSpotlightsReceived}
                    </div>
                     <div className="dashboardCardText">Avg Received Per Emp</div>
                </div>                   
            </div >

            <div className="dashboardCardTextRow" >
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreCircle" style={{borderColor:COLOR_BLUE_HEADER}}>
                        {analytics.mostSpotlightsSent}
                    </div>
                    <div className="dashboardCardText">Most Sent</div>
                </div>
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreCircle" style={{borderColor:SOCIAL_COLOR}}>
                        {analytics.mostSpotlightsReceived}
                    </div>
                     <div className="dashboardCardText">Most Received</div>
                </div>                   
            </div >
            
            <div className="ContainerNoHeightCenter dashboardCardTextFooter">{footer} </div>
        </div>
        
    );

});


export default DisplayCardSpotlightStats;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';
import React from 'react';

//Utils
import { compareMentorsByNumMentees, compareMentorsByNumProteges } from "../../../../shared/utils/userAndTeamUtils";
import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const DisplayCardMentorLoad = React.memo(({programNumbers, showMentorship, showSponsorship}) => {


    if (DEBUG_MODE >= 2) console.log("Mentor Analtytics", programNumbers, showMentorship, showSponsorship)

    if (!programNumbers || (!showMentorship && !showSponsorship)) return null;

    const title=(showMentorship ? "MENTOR DISTRIBUTION" : "SPONSOR DISTRIBUTION"); 
    
    if (DEBUG_MODE >= 2) console.log("Mentor Numbers before sort:",programNumbers);
    
    let tempNumbers;
    if (showMentorship) {
        tempNumbers = programNumbers.filter(u => u.numMentees > 0);
        tempNumbers.sort(compareMentorsByNumMentees);
    } else {
        tempNumbers = programNumbers.filter(u => u.numProteges > 0);
        tempNumbers.sort(compareMentorsByNumProteges);
    }

    if (DEBUG_MODE >= 2) console.log("Dasboard Program Numbers after sort:",tempNumbers);

    //Use only top set    
    const filteredNumbers = tempNumbers.slice(0,8);
    
    //Mentors to show?
    if (!filteredNumbers || filteredNumbers.length===0)  return  (

        <div className="dashboardCard  dashboardCardFlexStartProfile" >
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            <Divider width={80} />

            <div className="ContainerMaxHeightCenter" > No Mentees </div>

        </div>
        
    );
    
 
    //YES   
    return (

        <div className="dashboardCard  dashboardCardFlexStartProfile">
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            <Divider width={80}/>

           <div className="dashboardCardHeaderRow">
                <div className="dashboardCardText" style={{flex:"1 1", textAlign:"center"}}>{(showMentorship ? "Mentees" : "Sponsorees")}</div>
                <div className="dashboardCardText" style={{flex:"3 1", textAlign:"center"}}>{(showMentorship ? "Mentor" : "Sponsor")}</div>
           </div >


            { filteredNumbers.map((u, index) => (
                <div key={u.userID} className="dashboardCardTextRow" >
                    <div className="dashboardCardTextSmall"  style={{flex:"1 1", textAlign:"center"}}>{(showMentorship ? u.numMentees : u.numProteges)}</div>
                    <div className="dashboardCardTextSmall"  style={{flex:"3 1", textAlign:"center"}}>{u.name} </div>
                </div >
            )) }
            
        </div>
        
    );

});


export default DisplayCardMentorLoad;



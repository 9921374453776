//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021


import "./adminReportConectivitiesByFavorite.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, TEAM_COLOR, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH } from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator  } from '@aws-amplify/ui-react';

//Icons
import GroupsIcon from '@mui/icons-material/Groups';


//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';        //Customer Authentication context

//Our Components
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";

//Utils
import { getUserInitials, displayUsersRow, displayTeamsRow } from "../../shared/utils/userAndTeamUtils";
import { compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { setHeaderColorByCategory, setConectivityImageUrlByCategory, setBackgroundByPublishedStatus,  compareConectivitiesByTitle, setHeaderColorByStringLength} from "../../shared/utils/conectivityUtils";
import { DisplayEmployee, displayTransactionAmount } from "../../shared/utils/generalUtils";

import moment from 'moment';

const ReportTransactionHistory = () => {
  
    const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);
    const [showModalParticipants, setShowModalParticipants] = useState(false);
    const [participantsToShow, setParticipantsToShow] = useState([]);
    const [teamParticipantsToShow, setTeamParticipantsToShow] = useState([]);
    const [participantsHeaderColor, setParicipantsHeaderColor] = useState('blue');
    

   // Authentication context
    const {
            authState,
            currentUser,
            isSuperAdmin,
            permissionAnalytics,
            isAdmin,
             
    } = useContext(AuthContext);   
    
   // Customer context
    const {
            users, teams, 
            selectedCustomerOptions,
    } = useContext(CustomerContext);  
        

    //State data for this report page
    // const [ccTransactions, setCCTransactions] = useState([]);

    //Analytics context
    const {
            displayReportControls, setSelectedReport,
            transactionsFiltered,
    } = useContext(AnalyticsContext);


    //On load, set Report select option to THIS page
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag === "HISTORY");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
    });
    
    
/* NOT NEEDED SINCE TRANSACTION CACHE MAINTAINED BY ANALYTICS CONTEXT 

    //Get transaction data upon opening this page
    useEffect(() => {
        updatePageData();  
    }, [authState, selectedCustomerOptions]);

    //on a user auth or customer change, grab the conectivity data and related transactions
    async function updatePageData() {
 
        if (authState !== "signedin") return;

        setShowSpinner(true); //Show spinners
       
        
        try {

            var customerIDtoQuery = currentUser.customerID; //Default to current User for company ID

             //SuperAdim and has either NOT selected any Customer OR has selected ALL CUSTOMERS?
            if (isSuperAdmin && selectedCustomerOptions !== null && selectedCustomerOptions?.value!=0) {
              customerIDtoQuery = selectedCustomerOptions.id;
            }

            //Calc date object 1 year ago GMT in ISO format to set Fetch range
            const todayString = moment().toISOString();
            const oneYearAgoString = moment().subtract(365, 'days').toISOString();
            if (DEBUG_MODE >= 2) console.log("Date strings:",oneYearAgoString, todayString);

            //Now, grab transactions for this Customer

            const queryParams = {
                        customerID: customerIDtoQuery,                              //Primary Key
                        createdAt: {between: [ oneYearAgoString, todayString ]},     //Secondary Key
                    };
            
            var tempTransactions =  await queryDataTableWithPagination(getCCTransactionsByCustomerIDByCreatedAt, "getCCTransactionsByCustomerIDByCreatedAt", queryParams);
            
            if (DEBUG_MODE >= 2) console.log("Fetched transactions for company: " + customerIDtoQuery + " by createdAt", tempTransactions);
    
            //Save the transactions
            setCCTransactions(tempTransactions);

        } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching page data', err); }
        
         setShowSpinner(false); //Hide spinners
   
     }         

*/

    // Handle updates to transactions provided by analytics context
    useEffect(() => {
        buildTransactionRows();
    }, [transactionsFiltered]);

    function buildTransactionRows() {



         //Build rows for user transaction Data Grid 
        var tempRows = [];
        
        for(var i = 0; i<transactionsFiltered.length; i++){
            
            tempRows[i] = {
                id:i+1,
                name:transactionsFiltered[i].name,
                amount:transactionsFiltered[i].amount,
                badgesCS:transactionsFiltered[i].badgesCS,
                badgesDEI:transactionsFiltered[i].badgesDEI,
                newBalance:transactionsFiltered[i].newBalance,
                badgesDEIBalance:transactionsFiltered[i].badgesDEIBalance,
                badgesCSBalance:transactionsFiltered[i].badgesCSBalance,
                createdAt:transactionsFiltered[i].createdAt,
                memo:transactionsFiltered[i].memo,
                sequenceNumber:transactionsFiltered[i].sequenceNumber,
                image:transactionsFiltered[i].image,
                category:transactionsFiltered[i].category,
                usersThatParticipatedWithMe:transactionsFiltered[i].usersThatParticipatedWithMe,
                teamsThatParticipatedWithMe:transactionsFiltered[i].teamsThatParticipatedWithMe,
                scheduledConectivityID: transactionsFiltered[i].scheduledConectivityID,
                userName: "",
                avatarUrl: "",
            };
 
            //Add user that recorded the transaction to the array of participants for purposes of this report so as to show as a participant
            if (!tempRows[i].usersThatParticipatedWithMe) tempRows[i].usersThatParticipatedWithMe = [];
            if (!tempRows[i].usersThatParticipatedWithMe.includes(transactionsFiltered[i].userID)) tempRows[i].usersThatParticipatedWithMe.push(transactionsFiltered[i].userID);

             //Get user that recorded the transaction
            const tempUser = users.find(user => user.id === transactionsFiltered[i].userID);
            
            if (tempUser !== undefined) {
                tempRows[i].userName = tempUser.firstName + (!tempUser.middleName ? "" : " " + tempUser.middleName.substr(0,1)) + " " + tempUser.lastName;
                tempRows[i].avatarUrl = tempUser.avatarUrl;
                tempRows[i].initials = getUserInitials(tempUser);
            }
            
            if (transactionsFiltered[i].transactionType === "COMPLETED_CONECTIVITY" || transactionsFiltered[i].transactionType === "REJECTED_CONECTIVITY") {
                tempRows[i].image = setConectivityImageUrlByCategory(transactionsFiltered[i].category, true);
            }            
        }
        
        if (DEBUG_MODE >= 2) console.log("Built transaction rows", tempRows);
        
        //Sort the new set of objects
        tempRows.sort(compareTransactionsByDateReverseChronological);
        setTransactionRowsSummary(tempRows);
    }

    //Define Grid Format
    const transactionSummaryColumns = [
      {
        field: 'createdAt',
        headerName: 'DATE',
        type:'dateTime',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        color:'white',
        valueFormatter: ({ value }) => {
        	const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
            return tempTime.format(" MMM DD, YYYY ");
                
        },
        renderCell: (params)=> {
            return (
                <span> 
                <center>{formatDate(params.row.createdAt)}</center>
                </span>
                )
        }

      },
      {
        field: 'userName',
        headerName: 'EMPLOYEE',
        headerAlign: 'center',
        align:'center',
        minWidth: 320,        
        color:'white',
        sortable: true,
        renderCell: (params)=> {
            return <DisplayEmployee
                name={params.row.userName} 
                avatarUrl={params.row.avatarUrl} 
                initials={params.row.initials} 
            />;
          },
      },


      {
        field: 'name',
        headerName: 'TRANSACTION',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
        flex:1,
         // resizable: true,
        color:'white',
        renderCell: (params)=> {
            return (
                <div className="ContainerNoHeightFlexLeft" style={{width:"100%", height:"100%"}}>
                    <div>
                        <img src={params.row.image} height='36' />
                    </div>
                    <div style={{padding:'10px', color:setHeaderColorByCategory(params.row.category)}}>
                        {params.row.name}
                    </div>
                </div>
             )
            }
        },
    //   {
    //     field: 'memo',
    //     headerName: 'Memo',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 350,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //     renderCell: (params)=> {
    //         return (
    //              <div className="TextStyle2 memoDataStyle">
    //                 {params.row.memo}
    //             </div>
    //         )
    //     },
    //   },
    //   {
    //     field: 'memo',
    //     headerName: 'Memo',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 200,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //   },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        renderCell: (params)=> {
            
            return displayTransactionAmount({amount:params.row.amount, badgesDEI:params.row.badgesDEI, badgesCS:params.row.badgesCS});
            
        }

      },
    //   {
    //     field: 'balance',
    //     headerName: 'Balance',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 240,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //     renderCell: (params)=> {
    //         return (
    //             <div className="ContainerNoHeight">
    //                 <div className= "TextStyle3 creditDataStyle"> 

    //                     {params.row.newBalance}  
    //                     <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
    //                 </div>
                    
    //                 {!params.row.badgesDEIBalance || params.row.badgesDEIBalance ==0 ? "" :
    //                     <div > 
    //                         {params.row.badgesDEIBalance}  
    //                         <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
    //                     </div>
    //                 }
    //                 {!params.row.badgesCSBalance || params.row.badgesCSBalance ==0 ? "" :
    //                     <div className= "TextStyle3 creditDataStyle"> 
    //                         {params.row.badgesCSBalance}  
    //                         <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
    //                     </div>
    //                 }
    //             </div>

    //             );
    //     }        

    //   },      
      {
        field: 'action',
        headerName: 'PARTICIPANTS',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        sortable: false,
        renderCell: (params)=> {
            //Skip if no users and no teams, or only 1 user 
            if ((!params.row.usersThatParticipatedWithMe || params.row.usersThatParticipatedWithMe.length <= 1) && (!params.row.teamsThatParticipatedWithMe || params.row.teamsThatParticipatedWithMe.length === 0)) return null;
            
            const tempParticipants = (params.row.usersThatParticipatedWithMe?.length > 0 ? params.row.usersThatParticipatedWithMe : []);
            const tempTeamParticipants = (params.row.teamsThatParticipatedWithMe?.length > 0 ? params.row.teamsThatParticipatedWithMe : []);

            if (tempParticipants?.length === 0 && tempTeamParticipants?.length === 0) return null;
            
            return (
                    <div >
                        <div className="ContainerMaxHeightCenter"  onClick={() => handleShowParticipants(tempParticipants, tempTeamParticipants, params.row.category)} style={{cursor:"pointer"}}>
                            <div className="menuDataGridListButton" >
                                  <GroupsIcon className="previewButton" style={{fontSize:'32px'}}/> 
                            </div>
                           <div className="participantCountBadge">
                                {tempParticipants.length}
                            </div>
                        </div>

                  </div>
                   
                );
            }
        },
        
    ];      

    function formatDate(inputTime) {
        
    	const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
        return tempTime.format(" MMM DD, YYYY ");

    }
    
    // Tabs - move to a component
    const [tabIndexValue, setTabIndexValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndexValue(newValue);
    };

   // Pop up a modal for viewing members that participated in the Conectivity
    function handleShowParticipants (participants, teamParticipants, category) {     
        if (DEBUG_MODE >= 2) console.log("View Participants Button pressed.", participants, teamParticipants, category); 
        //Build an array of individuals that participated   
        let tempParticipants = [];
        participants.forEach (participantID => {
            const tempParticipant = users.find(user => user.id === participantID);
            if (tempParticipant) tempParticipants.push(tempParticipant);
        });
        if (DEBUG_MODE >= 2) console.log("Built Participants list.  Participants:", tempParticipants);    
        setParticipantsToShow(tempParticipants);
        
        //Build an array of teams that participated   
        var tempTeamParticipants = [];
         teamParticipants.forEach(teamID => {
            const tempTeamParticipant = teams.find(team => team.id === teamID);
            if (tempTeamParticipant !== undefined)  tempTeamParticipants.push(tempTeamParticipant);
        });
        if (DEBUG_MODE >= 2) console.log("Built Team Participants list.  Participants:", tempTeamParticipants, teamParticipants);    
        setTeamParticipantsToShow(tempTeamParticipants);
        
        setParicipantsHeaderColor(setHeaderColorByCategory(category));
        setShowModalParticipants(true); //pop-up Modal
    }
    
    const handleCancelParticipants = () => {
        
        setShowModalParticipants(false);
        setParticipantsToShow ([]);
        setTeamParticipantsToShow([]);
        
        if (DEBUG_MODE >= 2) console.log("Cancelled Participants Modal");
    };    


    //Handle access by unauthenticated user
    if (authState !== "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState === "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 

    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else  return (

    <Authenticator>
        <ModalNoBackground showModal={showModalParticipants} closeCallback={() => handleCancelParticipants()} cardColor={TEAM_COLOR}  >
            <div className="modalNoBkgInnerCard" onClick = {(e) =>  e.stopPropagation()} style={{padding:"2rem"}}>
               <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
                  <img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  style={{padding:"clamp(4px,1vw,10px)"}} alt='logo'/>  
               </div>
               <h2 className="purple"> Participants</h2>
               <h4 className="blue"> Teams</h4>
               {!teamParticipantsToShow || teamParticipantsToShow.length === 0 ? 'None' :
                  displayTeamsRow({teamsToShow:teamParticipantsToShow, numTeamsToShow:11, isSmall:false})
               }
               <h4 className="blue" style={{paddingTop:"2rem"}}> Colleagues</h4>
               {!participantsToShow || participantsToShow.length === 0 ? 'None' :
                  displayUsersRow({usersToShow:participantsToShow, numUsersToShow:11, isSmall:false})
               }
            </div>
        </ModalNoBackground>

       <div className="adminPage">
            <div className="ContainerVertical">  
                {displayReportControls({useUserSelect:true, useDateSelect:true,  showConectivitiesCount:true, showOrdersCount:true, showRingClosuresCount:true, usePeriodSelect:true, width:"auto", useReportSelect:true, transactionCount: (!transactionsFiltered ? 0 : transactionsFiltered.length )})}
                <div className="dashboardContainer boxShadow">
                    <div className="dashboardTitle">
                        TRANSACTION HISTORY
                    </div>   
                    <div className="dataGridWrapperTop" >        
                        <MenuDataGrid 
                            title="TRANSACTION HISTORY"
                            showToolBar = {true}
                            dataGridRows={transactionRowsSummary} 
                            dataGridColumns={transactionSummaryColumns }
                                // width={(isSuperAdmin) ? REPORT_DATAGRID_WIDTH_SUPERADMIN : REPORT_DATAGRID_WIDTH}
                        />
                    </div>   
                </div>  
             </div>
        </div>
    </Authenticator>
  );
};




export default ReportTransactionHistory;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD, DEI_COLOR1, CS_COLOR1, COLOR_BLUE_TEXT, BALANCE_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TEAM_COLOR, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH } from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';

//Our Analytics components
import CCStackedBarChart from "../../shared/Components/stackedBarChart/stackedBarChart";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context

//Utils
import {  renderOurRechartLegend, renderRechartTooltip } from "../../shared/utils/analyticsUtils";
import { NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING} from "../../shared/utils/dateTimeUtils";

//Here's the start of our Javascrpt App
const AdminGraphConectivityByEmployee = () => {

    //State data for this report page
  
    //Dynamic Graph Inputs
    const[graphTitle, setGraphTitle] = useState("");
    const[dataKeyY1, setDataKeyY1] = useState("");
    const[dataKeyY2, setDataKeyY2] = useState("");
    const[dataKeyY3, setDataKeyY3] = useState("");
    const[dataKeyY4, setDataKeyY4] = useState("");
    const[color1, setColor1] = useState("");
    const[color2, setColor2] = useState("");
    const[color3, setColor3] = useState("");
    const[color4, setColor4] = useState("");
   
   // Authentication context
    const {
            authState,
            currentUser,
            isSuperAdmin,
            isAdmin,
            permissionAnalytics,
            
    } = useContext(AuthContext);    


    //Analytics context
    const {
            analyticsGenerated,
            userAnalytics, teamAnalytics, customerAnalytics,
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData,
            timeSeriesGraphDataDay, timeSeriesGraphDataWeek, timeSeriesGraphDataMonth, timeSeriesGraphDataQuarter, timeSeriesGraphDataYear,                      //Time Series Data
            setTimeSeriesGraphDataDay, setTimeSeriesGraphDataWeek, setTimeSeriesGraphDataMonth, setTimeSeriesGraphDataQuarter, setTimeSeriesGraphDataYear,                      //Time Series Data
            displayReportControls, graphPeriod, selectedGraph, selectedUsersOptions, selectedTeamsOptions, transactionStartDateTime, transactionEndDateTime, selectedReport, setSelectedReport,
            transactionCache, transactionOldestStartDateTimeFetched, transactionCacheCustomerID, transactionCacheInitialized, transactionCountFilteredDateRange, transactionsFiltered,

    } = useContext(AnalyticsContext);

    //On load, set Report select option to THIS page
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "CONECTIVITY_BY_EMP_GRAPH");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
    });

    // 
    // HANDLERS FOR RESPONDING TO UPDATES TO REPORT CONTROLS 
    //
    
    // Handle change to Period - select a different graph to display
    useEffect(() => {
        updateGraphKeys();
    }, [graphPeriod, selectedGraph]);

    function updateGraphKeys () {  

       if (DEBUG_MODE >= 2) console.log("Updating graph keys", graphPeriod, selectedGraph, userAnalytics);

       //First, switch on the type of graph
        switch (selectedGraph) {
         case "CONECTIVITY_BY_CAT":   //Conectivity graph    
           switch(graphPeriod) {    
            case 'DAY':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteDay");
                setDataKeyY2("teamPeriodConectivitiesCompleteDay");
                setDataKeyY3("individualPeriodConectivitiesCompleteDay");
                setDataKeyY4("socialPeriodConectivitiesCompleteDay");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'WEEK':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteWeek");
                setDataKeyY2("teamPeriodConectivitiesCompleteWeek");
                setDataKeyY3("individualPeriodConectivitiesCompleteWeek");
                setDataKeyY4("socialPeriodConectivitiesCompleteWeek");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'MONTH':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteMonth");
                setDataKeyY2("teamPeriodConectivitiesCompleteMonth");
                setDataKeyY3("individualPeriodConectivitiesCompleteMonth");
                setDataKeyY4("socialPeriodConectivitiesCompleteMonth");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;    
            case 'QUARTER':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteQuarter");
                setDataKeyY2("teamPeriodConectivitiesCompleteQuarter");
                setDataKeyY3("individualPeriodConectivitiesCompleteQuarter");
                setDataKeyY4("socialPeriodConectivitiesCompleteQuarter");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'YEAR':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteYear");
                setDataKeyY2("teamPeriodConectivitiesCompleteYear");
                setDataKeyY3("individualPeriodConectivitiesCompleteYear");
                setDataKeyY4("socialPeriodConectivitiesCompleteYear");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '1W':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteOneWeek");
                setDataKeyY2("teamPeriodConectivitiesCompleteOneWeek");
                setDataKeyY3("individualPeriodConectivitiesCompleteOneWeek");
                setDataKeyY4("socialPeriodConectivitiesCompleteOneWeek");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '1M':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteOneMonth");
                setDataKeyY2("teamPeriodConectivitiesCompleteOneMonth");
                setDataKeyY3("individualPeriodConectivitiesCompleteOneMonth");
                setDataKeyY4("socialPeriodConectivitiesCompleteOneMonth");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '2M':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteTwoMonths");
                setDataKeyY2("teamPeriodConectivitiesCompleteTwoMonths");
                setDataKeyY3("individualPeriodConectivitiesCompleteTwoMonths");
                setDataKeyY4("socialPeriodConectivitiesCompleteTwoMonths");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '1Y':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteOneYear");
                setDataKeyY2("teamPeriodConectivitiesCompleteOneYear");
                setDataKeyY3("individualPeriodConectivitiesCompleteOneYear");
                setDataKeyY4("socialPeriodConectivitiesCompleteOneYear");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'ALL':
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressConectivitiesCompleted");
                setDataKeyY2("teamConectivitiesCompleted");
                setDataKeyY3("individualConectivitiesCompleted");
                setDataKeyY4("socialConectivitiesCompleted");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            default:
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressPeriodConectivitiesCompleteDay");
                setDataKeyY2("teamPeriodConectivitiesCompleteDay");
                setDataKeyY3("individualPeriodConectivitiesCompleteDay");
                setDataKeyY4("socialPeriodConectivitiesCompleteDay");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            }
            break;
        case "COINS_BY_CAT":
          switch(graphPeriod) {    
            case 'DAY':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedDay");
                setDataKeyY2("teamPeriodCoinsEarnedDay");
                setDataKeyY3("individualPeriodCoinsEarnedDay");
                setDataKeyY4("socialPeriodCoinsEarnedDay");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'WEEK':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedWeek");
                setDataKeyY2("teamPeriodCoinsEarnedWeek");
                setDataKeyY3("individualPeriodCoinsEarnedWeek");
                setDataKeyY4("socialPeriodCoinsEarnedWeek");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'MONTH':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedMonth");
                setDataKeyY2("teamPeriodCoinsEarnedMonth");
                setDataKeyY3("individualPeriodCoinsEarnedMonth");
                setDataKeyY4("socialPeriodCoinsEarnedMonth");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'QUARTER':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedQuarter");
                setDataKeyY2("teamPeriodCoinsEarnedQuarter");
                setDataKeyY3("individualPeriodCoinsEarnedQuarter");
                setDataKeyY4("socialPeriodCoinsEarnedQuarter");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'YEAR':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedYear");
                setDataKeyY2("teamPeriodCoinsEarnedYear");
                setDataKeyY3("individualPeriodCoinsEarnedYear");
                setDataKeyY4("socialPeriodCoinsEarnedYear");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '1W':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedOneWeek");
                setDataKeyY2("teamPeriodCoinsEarnedOneWeek");
                setDataKeyY3("individualPeriodCoinsEarnedOneWeek");
                setDataKeyY4("socialPeriodCoinsEarnedOneWeek");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '1M':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedOneMonth");
                setDataKeyY2("teamPeriodCoinsEarnedOneMonth");
                setDataKeyY3("individualPeriodCoinsEarnedOneMonth");
                setDataKeyY4("socialPeriodCoinsEarnedOneMonth");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '2M':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedTwoMonths");
                setDataKeyY2("teamPeriodCoinsEarnedTwoMonths");
                setDataKeyY3("individualPeriodCoinsEarnedTwoMonths");
                setDataKeyY4("socialPeriodCoinsEarnedTwoMonths");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case '1Y':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressPeriodCoinsEarnedOneYear");
                setDataKeyY2("teamPeriodCoinsEarnedOneYear");
                setDataKeyY3("individualPeriodCoinsEarnedOneYear");
                setDataKeyY4("socialPeriodCoinsEarnedOneYear");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            case 'ALL':
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressCoinsEarned");
                setDataKeyY2("teamCoinsEarned");
                setDataKeyY3("individualCoinsEarned");
                setDataKeyY4("socialCoinsEarned");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            default:
                setGraphTitle("COINS EARNED");
               setDataKeyY1("stressPeriodConectivitiesCompleteDay");
                setDataKeyY2("teamPeriodConectivitiesCompleteDay");
                setDataKeyY3("individualPeriodConectivitiesCompleteDay");
                setDataKeyY4("socialPeriodConectivitiesCompleteDay");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
            }
            break;
        case "BADGES":
          switch(graphPeriod) {    
            case 'DAY':
                setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedDay");
                setDataKeyY2("badgesCSReceivedDay");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case 'WEEK':
                setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedWeek");
                setDataKeyY2("badgesCSReceivedWeek");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case 'MONTH':
                setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedMonth");
                setDataKeyY2("badgesCSReceivedMonth");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case 'QUARTER':
                setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedQuarter");
                setDataKeyY2("badgesCSReceivedQuarter");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case 'YEAR':
                 setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedYear");
                setDataKeyY2("badgesCSReceivedYear");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case '1W':
                 setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedOneWeek");
                setDataKeyY2("badgesCSReceivedOneWeek");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case '1M':
                 setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedOneMonth");
                setDataKeyY2("badgesCSReceivedOneMonth");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case '2M':
                 setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedTwoMonths");
                setDataKeyY2("badgesCSReceivedTwoMonths");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case '1Y':
                 setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedOneYear");
                setDataKeyY2("badgesCSReceivedOneYear");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            case 'ALL':
                 setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIAwarded");
                setDataKeyY2("badgesCSAwarded");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;            
            default:
                setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceivedDay");
                setDataKeyY2("badgesCSReceivedDay");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
            }
            break;
        }
    } 

    //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) return null;
    
    //Render function for authenticated user     
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else {
      
      return (

        <Authenticator>
            <div className="adminPage">
                <div className="ContainerVertical">
                    <div>
                        {displayReportControls({useUserSelect:true, useDateSelect:true,  width:"auto", useReportSelect:true, usePeriodSelect:true, useGraphItemSelect:true})}
                    </div>
                    <div className="dashboardContainer boxShadow">
                        <div className="dashboardTitle">
                            {graphTitle + " THIS PAST " + graphPeriod}
                         </div>   

                             {userAnalytics  ?

                                 <div className="dataGridWrapperTop" >        
                                      <CCStackedBarChart  
                                        data={userAnalytics}
                                        dataKeyX="initials" 
                                        dataKeyY1={dataKeyY1} color1={color1}
                                        dataKeyY2={dataKeyY2} color2={color2}
                                        dataKeyY3={dataKeyY3} color3={color3}
                                        dataKeyY4={dataKeyY4} color4={color4}
                                        // height:{}
                                        width={REPORT_DATAGRID_WIDTH}
                                        grid 
                                        renderLegend = {renderOurRechartLegend}
                                        renderTooltip = {renderRechartTooltip}
                                    />
                                </div>
                                    
                                : null
                             }
            
                    </div>  
                </div>
           </div>
    
        </Authenticator>
        
      );
  }
};


export default AdminGraphConectivityByEmployee;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//Styles
import "./creditCard.css";
import "../../styles/App.css";

//Config
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_GOOD } from '../../data/conectereConfigData';

//React
import React, { useCallback, useState, useEffect } from "react";

//Icons
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Imports for React-credit-cards
//https://www.npmjs.com/package/react-credit-cards-2
import Cards from "react-credit-cards-2";
import 'react-credit-cards-2/dist/es/styles-compiled.css'

//3rd Party Components
import Select from 'react-select';   //Version with support from multiple selections
// import { table } from "console";

const monthOptions =
  [
    {label:"JAN", value:"01", index:0, isdisabled: false }, 
    {label:"FEB", value:"02", index:1, isdisabled: false }, 
    {label:"MAR", value:"03", index:2, isdisabled: false },  
    {label:"ARIL", value:"04", index:3, isdisabled: false },
     {label:"MAY", value:"05", index:4, isdisabled: false }, 
    {label:"JUN", value:"06", index:5, isdisabled: false }, 
    {label:"JUL", value:"07", index:6, isdisabled: false },  
    {label:"AUG", value:"08", index:7, isdisabled: false },
     {label:"SEP", value:"09", index:8, isdisabled: false }, 
    {label:"OCT", value:"10", index:9, isdisabled: false }, 
    {label:"NOV", value:"11", index:10, isdisabled: false },  
    {label:"DEC", value:"12", index:11, isdisabled: false }];

const yearOptions =
  [
    {label:"2022", value:"22", index:0, isdisabled: false }, 
    {label:"2023", value:"23", index:1, isdisabled: false }, 
    {label:"2024", value:"24", index:2, isdisabled: false },  
    {label:"2025", value:"25", index:3, isdisabled: false },
    {label:"2026", value:"26", index:4, isdisabled: false }, 
    {label:"2027", value:"27", index:5, isdisabled: false }, 
    {label:"2028", value:"28", index:6, isdisabled: false },  
    {label:"2029", value:"29", index:7, isdisabled: false },
    {label:"2030", value:"30", index:8, isdisabled: false }, 
    {label:"2031", value:"31", index:9, isdisabled: false }];
      
      
//React component
export const CreditCard = React.memo(({
    myCallBack, cellBackgroundColor, 
    inputNumber, inputName, inputMonth, inputYear, inputExpiry, inputCvc, inputIssuer, inputBillingZipCode, tableBackgroundClass,
    readOnly}) => {
 
  //
  //Initialize inputs, as the may be null, before setting up our State data.  "Cards" component fails if these are empty
  //
  if (!cellBackgroundColor) cellBackgroundColor = "#6495ED";
  if (!tableBackgroundClass) tableBackgroundClass = "formStyleGrey";

    const [number, setNumber] = useState('');
    const [name, setName] = useState("");
    const [month, setMonth] = useState(monthOptions[0].value);
    const [year, setYear] = useState(yearOptions[0].value);
    const [expiry, setExpiry] = useState(monthOptions[0].value + yearOptions[0].value);
    const [cvc, setCvc] = useState('');
    const [billingZipCode, setBillingZipCode] = useState('');
    const [issuer, setIssuer] = useState('');
    const [focus, setFocus] = useState("");
    const [yearDropDownSelection, setYearDropDownSelection] = useState(yearOptions[0]);
    const [monthDropDownSelection, setMonthDropDownSelection] = useState(monthOptions[0]);    
  
    //Update local state on prop changes
    useEffect(() => {

      if (DEBUG_MODE >= 2) console.log("Credit Card UseEffect invoked - updated local vars", inputNumber, inputName, inputMonth, inputYear, inputExpiry, inputCvc, inputIssuer, inputBillingZipCode, readOnly);

      //set vars      
      setNumber(inputNumber ? inputNumber : "");
      setName(inputName ? inputName : "");
      setBillingZipCode(inputBillingZipCode ? inputBillingZipCode : "");
      setCvc(inputCvc ? inputCvc : "");

      //Set Month Dropdown Option
      if (inputMonth) {
        setMonth(inputMonth);
        const monthOption = monthOptions.find ( month => month.value == inputMonth);
        if (monthOption) {
            setMonthDropDownSelection(monthOption); //Found a match so set the state variable to this option
            // if (DEBUG_MODE >= 2) console.log("Initialized month dropdown selections", monthOption);
         }
      }
    

      //Set Year Dropdown Option
      if (inputYear) {
        setYear(inputYear);
        const yearOption = yearOptions.find ( year => year.value === inputYear);
        if (yearOption) {
          setYearDropDownSelection(yearOption); //Found a match so set the state variable to this option
          // if (DEBUG_MODE >= 2) console.log("Initialized year dropdown selections", yearOption);
        } else {
          // if (DEBUG_MODE >= 2) console.error("Error - could not initialize year dropdown selections", yearOption, inputYear);
        }
      }

      if (inputMonth && inputYear) {
         setExpiry(inputMonth + inputYear); 
        //  if (DEBUG_MODE >= 2) console.log("Initialized expiry", inputMonth + inputYear);
      }


    }, [inputNumber, inputName, inputMonth, inputYear, inputExpiry, inputCvc, inputIssuer, inputBillingZipCode, readOnly]);

    
    const handleMonth = (e) => {   
        // if (DEBUG_MODE >= 2) console.log("Handle month", e);
        // if (DEBUG_MODE >= 2) console.log("Setting CC date", e.value);
        setMonthDropDownSelection(e);
        setMonth(e.value.toString());
        setExpiry(e.value.toString() + year);
    };
  
    const handleYear = (e) => {
      // if (DEBUG_MODE >= 2) console.log("Handle year", e);
        setYearDropDownSelection(e);
        setYear(e.value.toString());
        setExpiry(month + (e.value.toString()));
    };

  const handleCallback = useCallback(( issuerObject, isValid) => {   
    // if (DEBUG_MODE >= 2) console.log("Callback from Card component.  Issuer & isValid", issuerObject, isValid, number , name, expiry, month , year , issuerObject.issuer , cvc, billingZipCode);
    if (isValid && number && name  && expiry && month && year && issuerObject.issuer && cvc && billingZipCode && billingZipCode.length > 4) {
      setIssuer(issuerObject.issuer); //record the issuer
      if (DEBUG_MODE >= 2) console.log("Reporting valid issuer");
      if (myCallBack) {
            var results = {
              number:number,
              name:name,
              expiry:expiry,
              month:month,
              year:year,
              issuer:issuerObject.issuer,
              cvc:cvc,
              billingZipCode:billingZipCode,
            };
            
            myCallBack (results);
              
      } else {
          if (DEBUG_MODE >= 2) console.log("No callback to report CC info");
      }      
    } else if (issuer && issuer.length > 0) {
      if (DEBUG_MODE >= 2) console.log("Not yet fully valid input; Clearing issuer");
      setIssuer(''); //clear the issuer
    }
  },[number,name,expiry, month,year,cvc, billingZipCode]);

  function handleNumberChange(e) {
    setNumber(e.target.value);
 
}

  function handleNameChange(e) {
    setName(e.target.value);
}

  function handleCvcChange(e) {
    setCvc(e.target.value);
}


	return (
		<div className="ContainerVerticalCenter positionRelative">
      
         <h5 className="red" style={{height:"1rem"}}>{!issuer || issuer.length===0 ? 'Please enter a valid payment method' : ""}</h5>
         <div className="Container positionRelative">   
            <Cards
               number={number}
               name={name}
               expiry={expiry}
               cvc={cvc}
               focused={focus}
               callback={handleCallback}

            />
              {issuer && issuer.length>0 ? 
                  <div className="ContainerNoHeightCenter" style={{backgroundColor:"white", borderRadius:"50%", position:"absolute", top:"0", right:"0", transform: "translate(50%,-65%)"}}>
                        <CheckCircleIcon style={{color:COLOR_GOOD, fontSize:"4.0vh"}}/> 
                  </div>                                
            : null }          
         </div>
         <div id='creditCardForm' className="creditCardForm"  hidden={false && readOnly}>
         <table className={tableBackgroundClass}>
            <tbody >
            <tr>
                  <td className="popEditFormHeaderStyle" style={{backgroundColor:cellBackgroundColor}}><div > Credit Card Number</div></td>
                  <td className="formDataStyle" colSpan="2">
                        <input
                           type="tel"
                           className={`textInputElement ${!number || number.length < 15 ? 'textInputElementRequired' : ""} `}                                       
                           disabled={readOnly}
                           value={number}
                           required
                           name="number"
                           maxLength="16"
                           pattern="[0-9]+"
                           onChange={handleNumberChange}
                           onFocus={(e) => setFocus(e.target.name)}
                        />
                  </td>
               </tr>
               <tr>

                  <td className="popEditFormHeaderStyle" style={{backgroundColor:cellBackgroundColor}}>Cardholder Name</td>
                  <td className="formDataStyle" colSpan="2">
                        <input
                           type="text"
                           disabled={readOnly}
                           required
                           className={`textInputElement ${!name || name.length===0 ? 'textInputElementRequired' : ""} `}                                       
                           value={name}
                           name="name"
                           onChange={handleNameChange}
                           onFocus={(e) => setFocus(e.target.name)}
                       />
                  </td>
               </tr>
               <tr>
                  <td className="popEditFormHeaderStyle" style={{backgroundColor:cellBackgroundColor}}>Valid thru</td>
                  <td className="formDataStyle">
                     <div  style={{minWidth:"100px"}}  >
                        <Select  
                           isMulti={false} 
                           name="monthDropDown" 
                           options={monthOptions} 
                           isDisabled={readOnly}
                           onChange={handleMonth} 
                           value={monthDropDownSelection ==="" ? "" : monthOptions[monthDropDownSelection.index]} 
                           placeholder=""/>
                     </div>
                     </td>
                  <td style={{minWidth:"100px"}}>
                     <div  style={{minWidth:"100px"}}  >
                     <Select  isMulti={false} name="yearDropDown" isDisabled={readOnly} options={yearOptions} onChange={handleYear} value={!yearDropDownSelection ? "" : yearOptions[yearDropDownSelection.index]} placeholder=""/>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td className="popEditFormHeaderStyle" style={{backgroundColor:cellBackgroundColor}}>CVC</td>
                  <td className="formDataStyle">
                     <input
                        type="tel"
                        className={`textInputElement ${!cvc || cvc.length<3 ? 'textInputElementRequired' : ""} `}                                       
                        name="cvc"
                        maxLength="4"
                        required
                        value={cvc}
                        pattern="\d*"
                        disabled={readOnly}
                        onChange={handleCvcChange}
                        onFocus={(e) => setFocus(e.target.name)}
                     />
                  </td>
               </tr>
               <tr>
                  <td className="popEditFormHeaderStyle" style={{backgroundColor:cellBackgroundColor}}>Billing Zip Code</td>
                  <td className="formDataStyle">
                     <input
                        type="tel"
                        disabled={readOnly}
                        className={`textInputElement ${!billingZipCode || billingZipCode.length<5 ? 'textInputElementRequired' : ""} `}                                       
                        name="billingZipCode"
                        maxLength="5"
                        required
                        value={(billingZipCode)}
                        pattern="\d*"
                        onChange={(e)=>setBillingZipCode(e.target.value)}  
                        onFocus={(e) => setFocus(e.target.name)}
                     />
                  </td>
               </tr>
            </tbody>
         </table>
         <input type="hidden" name="issuer" value={issuer} />
         </div>
		</div>
	);
	
}, (prevProps, nextProps) => {
    //Our React MEMO function - do not re-render if no change to these props
    if ((prevProps.inputNumber === nextProps.inputNumber) &&
      (prevProps.inputName === nextProps.inputName) &&
      (prevProps.inputMonth === nextProps.inputMonth) &&
      (prevProps.inputCvc === nextProps.inputCvc) &&
      (prevProps.inputBillingZipCode === nextProps.inputBillingZipCode) &&
      (prevProps.readOnly === nextProps.readOnly)
    ) 
    {
        // if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Credit Card",  prevProps, nextProps);
        return true; // props are equal
    }
    // if (DEBUG_MODE >= 2) console.log("Re-RENDER the Credit Card!", prevProps, nextProps);
    return false; // props are not equal -> update the component
});

  // MOVED BUTTONS HERE SINCE NO LONGER NEEDED
      // <div className="ContainerNoHeightCenter">
      //     {readOnly || formNotComplete() ? "" :
              
      //         <div className = "buttonWrapperWithMargin">
      //             <center><button className="validateButton" onClick={handleValidateCard} ><div > Validate Card <PaymentIcon /></div></button></center>
      //         </div>
             
      //       }        
      //       {readOnly ? "" :
      //         <div className = "buttonWrapperWithMargin">
      //             {<center><button className="validateButton" onClick={handleClear}><div > Clear </div></button></center>}
      //         </div>
      //       }
      //     </div>
 
 
 /* 
    if (!inputNumber) inputNumber = "";
    if (!inputName) inputName = "";
    if (!inputMonth) inputMonth = "";
    if (!inputYear) inputYear = "";
    if (!inputExpiry) {
      if (!inputMonth || !inputYear) {
        inputExpiry = "";
      } else {
        inputExpiry = inputMonth + inputYear;
      }
    }
    if (!inputCvc) inputCvc = "";
    if (!inputIssuer) inputIssuer = "";
    if (!inputBillingZipCode) inputBillingZipCode = "";
    if (DEBUG_MODE >= 2) console.log("Credit Card Called", inputNumber, inputName, inputMonth, inputYear, inputExpiry, inputCvc, inputIssuer);
*/

/*  
    //handle clear button
    async function handleClear (e) {
      e.preventDefault(); 
      
      setNumber("");
      setName("");
      setMonth("");
      setYear("");
      setExpiry("");
      setCvc("");
      setIssuer("");
      
      // document.getElementById("creditCardForm").reset();    //Clear the form
 
      //Tell the parent the user just cleared the data
       if (myCallBack) {
              var results = {
                  number:"",
                  name:'',
                  expiry:"",
                  month:"",
                  year:"",
                  issuer:"",
                  cvc:"",
              };
              
              myCallBack (results);
                
        } else {
            if (DEBUG_MODE >= 2) console.log("No callback to report CC info");
        }      
        
      setReadOnly(false); //go back to edit mode
      
    }

    function invokeCallBack (currentData) {
      
    if (myCallBack) {
          var results = {
            number:currentData.number,
            name:currentData.name,
            expiry:currentData.expiry,
            month:currentData.month,
            year:currentData.year,
            issuer:currentData.issuer,
            cvc:currentData.cvc,
          };
          
          myCallBack (results);
            
    } else {
        if (DEBUG_MODE >= 2) console.log("No callback to report CC info");
    }
}    


    //handle the validate button
    function handleValidateCard (e)  {
      
      // setReadOnly(true); //lock the data
      
      //Checks to confirm proper format
      
      // if (DEBUG_MODE >= 2) console.log("Credit Card Submitted By User");
        
      invokeCallBack({
        number:number,
        name:name,
        expiry:expiry,
        month:month,
        year:year,
        issuer:issuer,
        cvc:cvc,        
      });
        
     }
*/
 
    // if (DEBUG_MODE >= 2) console.log("Credit Cards called");

    //State data for handling credit card input
/*    const [number, setNumber] = useState(inputNumber);
    const [name, setName] = useState(inputName);
    const [month, setMonth] = useState(inputMonth);
    const [year, setYear] = useState(inputYear);
    const [expiry, setExpiry] = useState(inputExpiry);
    const [cvc, setCvc] = useState(inputCvc);
    const [billingZipCode, setBillingZipCode] = useState(inputBillingZipCode);
    const [issuer, setIssuer] = useState(inputIssuer);
    const [focus, setFocus] = useState("");
    const [readOnly, setReadOnly] = useState(false);
    const [yearDropDownSelection, setYearDropDownSelection] = useState(yearOptions[0]);
    const [monthDropDownSelection, setMonthDropDownSelection] = useState(monthOptions[0]);
*/


/*
    //Update parent if card is valid and form complete
    useEffect(() => {
      console.log("Credit Card Use Effect Called", number, name, expiry, month, year, issuer, cvc);
      
      if (number != "" && name != "" && expiry != "" && month != "" && year != "" && issuer != "" && cvc != "") {

        console.log("Credit Card Callback Invoked");

        invokeCallBack({
          number:number,
          name:name,
          expiry:expiry,
          month:month,
          year:year,
          issuer:issuer,
          cvc:cvc,        
        });
      }
  }, [number, name, expiry, month, year, issuer, cvc]);
    
  function formNotComplete () {
    
    
    var result = true;
    
    if (number != "" && name != "" && expiry != "" && month != "" && year != "" && issuer != "" && cvc != "") {
      result = false;
    }
    
    // if (DEBUG_MODE >= 2) console.log("Form Not Complete Called",result, number, name, expiry, month, year, issuer, cvc); 

    return result;
  }
  
*/  

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved


import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD, DEI_COLOR1, CS_COLOR1, COLOR_BLUE_TEXT, BALANCE_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TEAM_COLOR, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH } from '../data/conectereConfigData';

//React & Amplify
import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';    

//Utils
import { invokeAPI, fetchLeaderboardsByCustomer, fetchLeaderboardSpotsByCustomer, 
  fetchUsersByCustomer, fetchUserProgressDataByCustomer, fetchTeamsByCustomer, fetchTransactionsByCustomerByDate, 
  fetchGraphsByCustomer, fetchGraphNodesByCustomer, fetchGraphEdgesByCustomer, fetchGraphTimeSeriesByCustomer, 
  fetchUserAnalyticsByCustomer, fetchTeamAnalyticsByCustomer, fetchCustomerAnalyticsByCustomer} from "./databaseUtils";
import { NOW, ONE_YEAR_AGO, NOW_STRING, ONE_MONTH_AGO_STRING, ONE_YEAR_AGO_STRING} from "./dateTimeUtils";
import { getUserInitials } from "./userAndTeamUtils";

//Queries and Mutations
import { deleteLeaderBoardSpot, deleteCustomerAnalytics, deleteUserAnalytics, deleteTeamAnalytics, 
        createCustomerAnalytics, createTeamAnalytics, createUserAnalytics, createGraphTimeSeries, 
        createGraphTimeSeriesValue, createGraphNode, createGraphEdge, createGraph, deleteGraph, deleteGraphNode, 
        deleteGraphEdge, deleteGraphTimeSeries, deleteGraphTimeSeriesValue, updateUserProgressData,
        createLeaderBoardSpot, updateLeaderBoardSpot} from '../graphql/mutations';

/*
export function computeInitalRadarChartData(customerAnalytics) {

    if (DEBUG_MODE >= 2) console.log("computing radar chart data for company", customerAnalytics);
    
    //If the user is above the company average then graph out of a full 1.0 scale
   
    var fullMark = 1.0;


        //If the user is below the company average then set the scale to the company average so as to enlarge the graphs
        fullMark = setRadarGraphFullMarkBasedOnCompanyAverages(customerAnalytics);


        var tempRadarChartData =       [{
            // subject: 'Balance',
            A: (customerAnalytics.stressComponent ? customerAnalytics.stressComponent : 0),
            //fullMark: fullMark,
          },
          {
            // subject: 'Team',
            A: (customerAnalytics.teamComponent ? customerAnalytics.stressComponent : 0),
            //fullMark: fullMark,
          },
          {
            // subject: 'Personal',
            A: (customerAnalytics.individualComponent ? customerAnalytics.stressComponent : 0),
            //fullMark: fullMark,
          },
          {
            // subject: 'Social',
            A: (customerAnalytics.socialComponent ? customerAnalytics.stressComponent : 0),
            //fullMark: fullMark,
          },
          {
            // subject: 'DEI',
            A: (customerAnalytics.badgesDEIComponent ? customerAnalytics.stressComponent : 0),
            //fullMark: fullMark,
          },
          {
            // subject: 'Community',
            A: ( customerAnalytics.avgCSBadgesEarnedByUsers &&  customerAnalytics.maxCSBadgesEarnedByUsers ? customerAnalytics.avgCSBadgesEarnedByUsers / customerAnalytics.maxCSBadgesEarnedByUsers : 0),
            //fullMark: fullMark,
          },
        ];    

    if (DEBUG_MODE >= 2) console.log("Computed initialized radar chart data", tempRadarChartData);
        
    return (tempRadarChartData);

    
}
*/

   //Function to look at all 6 components for the user's metric and all 6 components for the customer's metric and return the highest value
    //All should be between 0.0 and 1.0
    
    function setRadarGraphFullMarkBasedOnCompanyAverages(customerAnalytics) {
 
        if (!customerAnalytics.stressComponent || !customerAnalytics.teamComponent || !customerAnalytics.individualComponent || !customerAnalytics.socialComponent || !customerAnalytics.badgesDEIComponent || !customerAnalytics.badgesCSComponent) return 0;
        
        const maxComponent = Math.max(
            customerAnalytics.stressComponent,
            customerAnalytics.teamComponent,
            customerAnalytics.individualComponent,
            customerAnalytics.socialComponent,
            customerAnalytics.badgesDEIComponent,
            customerAnalytics.badgesCSComponent);
        
        // if (DEBUG_MODE >= 2) console.log("Set Radar Graph Full Mark to:", maxComponent);
        
        return maxComponent;
        
    }        

    async function invokeDeleteGraphNode (node) {
            
      try {

            //Delete record
             await invokeAPI(deleteGraphNode, 'deleteGraphNode', {id: node.id});
            if (DEBUG_MODE >= 2) console.log("Deleted graph node", node);
            
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error deleting graph node record:', err);
        }
 
    }    
    
    async function invokeDeleteGraphEdge (edge) {
            
      try {

            //Delete record
             await invokeAPI(deleteGraphEdge, 'deleteGraphEdge', {id: edge.id});
            if (DEBUG_MODE >= 2) console.log("Deleted graph edge", edge);
            
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error deleting graph edge record:', err);
        }
 
    }    

    async function invokeDeleteGraph(graph) {          
          try {

                //Delete each NODE
                if (graph.nodes) {
                     for (var i=0; i < graph.nodes.length; i++) {
                        await invokeDeleteGraphNode(graph.nodes[i]);        
                    }     
                }
                
                //Delete each EDGE
                if (graph.edges) {
                     for (var i=0; i < graph.edges.length; i++) {
                        await invokeDeleteGraphEdge(graph.edges[i]);        
                    }     
                }
                
                //Delete the GRAPH record
                await invokeAPI(deleteGraph, 'deleteGraph',  {id: graph.id});
                if (DEBUG_MODE >= 2) console.log("Deleted graph record  ", graph);
                
            } catch (err) {
                if (DEBUG_MODE >= 2) console.log('error deleting graph record:', err);
            }
        }
        
    export async function invokeDeleteGraphs(customerID) {
 
 	    //For this Customer, get all Graph records
	    const graphs = await fetchGraphsByCustomer(customerID);
	    if (DEBUG_MODE >= 2) console.log("Fetched Existing Graphs", graphs.length);   

        try {
                for (var i=0; i < graphs.length; i++) {
                   //Delete record
                    await invokeAPI(deleteGraph, 'deleteGraph', {id: graphs[i].id});
                    // if (DEBUG_MODE >= 2) console.log("Deleted graph node", graphs[i]);
                }    
                
         	    //For this Customer, get all Graph Edges
                const edges =  await fetchGraphEdgesByCustomer(customerID);
                // if (DEBUG_MODE >= 2) console.log("Loaded edges for this graph", edges);
        
                for (var i=0; i < edges.length; i++) {
                   //Delete record
                    await invokeAPI(deleteGraphEdge, 'deleteGraphEdge', {id: edges[i].id});
                    // if (DEBUG_MODE >= 2) console.log("Deleted graph edge", edges[i]);
                }  
 
                //  if (DEBUG_MODE >= 2) console.log("Deleted " + edges.length + " graph edges");
               
        	    //For this Customer, get all Graph Nodes, which are customer-specific but not tied to any Graph
        	    const graphNodes = await fetchGraphNodesByCustomer(customerID);
        	    if (DEBUG_MODE >= 2) console.log("Fetched Existing Graph Nodes", graphNodes.length);   
        
                for (var i=0; i < graphNodes.length; i++) {
        
                    //Delete record
                    await invokeAPI(deleteGraphNode, 'deleteGraphNode',  {id: graphNodes[i].id});
                    // if (DEBUG_MODE >= 2) console.log("Deleted graph node", graphNodes[i]);
        
                }    
                
                //  if (DEBUG_MODE >= 2) console.log("Deleted " + graphNodes.length + " graph edges");
                
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Delete graph error", err);
        }
    }
 
    export async function invokeDeleteLeaderboardSpots (customerID) {
 
 	    //For this Customer, get all leaderboard spots
	    const leaderBoardSpots = await fetchLeaderboardSpotsByCustomer(customerID);
	    
	    if (DEBUG_MODE >= 2) console.log("Fetched Customer's Leaderboard spots", leaderBoardSpots.length);   
        

        try {
            
                for (var i=0; i < leaderBoardSpots.length; i++) {
                    
                   //Delete record
                    await invokeAPI(deleteLeaderBoardSpot, 'deleteLeaderBoardSpot',  {id: leaderBoardSpots[i].id});
                    // if (DEBUG_MODE >= 2) console.log("Deleted leaderboard spot", leaderBoardSpots[i]);
                }    
                
                  if (DEBUG_MODE >= 2) console.log("Deleted total of " + leaderBoardSpots.length + " leaderboard spots");
                
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error deleting leaderboard spots", err);
        }   
    
    } 
    
    
    async function invokeDeleteTimeSeriesGraph(timeSeriesGraph) {
        

       //Delete each graph VALUE
        if (timeSeriesGraph.values) {
             for (var i=0; i < timeSeriesGraph.values.length; i++) {

                //Delete record
                await invokeAPI(deleteGraphTimeSeriesValue, 'deleteGraphTimeSeriesValue',  {id: timeSeriesGraph.values[i].id});
                if (DEBUG_MODE >= 2) console.log("Deleted time series graph value", timeSeriesGraph.values[i]);
 
            }     
        }
        
        //Now, delete the graph record itself
        await invokeAPI(deleteGraphTimeSeries, 'deleteGraphTimeSeries',  {id: timeSeriesGraph.id});
        if (DEBUG_MODE >= 2) console.log("Deleted time series graph ", timeSeriesGraph); 
    }
 
 

     async function invokeDeleteTimeSeriesGraphs(customerID) {
 
	    //For this Customer, get all Time Seres Graph records
	    const graphTimeSeries = await fetchGraphTimeSeriesByCustomer(customerID);
	    if (DEBUG_MODE >= 2) console.log("Fetched Time Series Graphs for deleting", graphTimeSeries);   

        for (var i=0; i < graphTimeSeries.length; i++) {
            
            await invokeDeleteTimeSeriesGraph(graphTimeSeries[i]);
        }
    }


     async function invokeDeleteUserAnalytics(customerID) {
 
	    //For this Customer, get all User Analytics records
	    const userAnalytics = await fetchUserAnalyticsByCustomer(customerID);
	    if (DEBUG_MODE >= 2) console.log("Fetched User Analytics for deleting", userAnalytics);   

        for (var i=0; i < userAnalytics.length; i++) {
            
                await invokeAPI(deleteUserAnalytics, 'deleteUserAnalytics', {id: userAnalytics[i].id});
                if (DEBUG_MODE >= 2) console.log("Deleted user analytic record", userAnalytics[i].id);

        }
    }


     async function invokeDeleteTeamAnalytics(customerID) {
 
	    //For this Customer, get all Team Analytics records
	    const teamAnalytics = await fetchTeamAnalyticsByCustomer(customerID);
	    if (DEBUG_MODE >= 2) console.log("Fetched Team Analytics for deleting", teamAnalytics);   

        for (var i=0; i < teamAnalytics.length; i++) {
            
                await invokeAPI(deleteTeamAnalytics, 'deleteTeamAnalytics', {id: teamAnalytics[i].id});
                if (DEBUG_MODE >= 2) console.log("Deleted team analytic record", teamAnalytics[i].id);

        }
    }
    
     async function invokeDeleteCustomerAnalytics(customerID) {
 
	    //For this Customer, customer-wide analytics record
	    const customerAnalytics = await fetchCustomerAnalyticsByCustomer(customerID);
	    if (DEBUG_MODE >= 2) console.log("Fetched Customer Analytics for deleting", customerAnalytics);   

        //Should be only 1 record but for clean-up, delete all that were retrieved
        for (var i=0; i < customerAnalytics.length; i++) {
            
                await invokeAPI(deleteCustomerAnalytics, 'deleteCustomerAnalytics', {id: customerAnalytics[i].id});
                if (DEBUG_MODE >= 2) console.log("Deleted customer analytic record", customerAnalytics[i].id);

        }
    }    
    
    //This functions deletes all analytics records associated with the customer
    //We only need delete the Graph records since WE ARE USING THE appsync-autogenerated USERID, TEAMID and CUSTOMERID AS THE ID PARTITION KEY ON those TABLES and they will be overwritten

    export async function deleteAnalytics(customerID) {
    
       try {
    
        //Delete (network) graphs
        await invokeDeleteGraphs(customerID);       
        
        //Delete Time Series Graphs
        await invokeDeleteTimeSeriesGraphs(customerID);   
        
        
        //Delete User-specific analytics
        await invokeDeleteUserAnalytics(customerID);   

        //Delete Team-specific analytics
        await invokeDeleteTeamAnalytics(customerID);   

        //Delete customer analytics
        await invokeDeleteCustomerAnalytics(customerID);   

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error deleting analytics', err);
        }
    }   
    
    
    //
    //
    // MAIN ANALYTICS CALC FUNCTION
    //
    //

//
//  Analytics Data structs and functions used by these routines
//

    // Structures for holding data
    var userNumbers = [];
    var teamNumbers = [];
    var customerWideAnalytics = {};

    var timeSeriesGraphDataDay = [];
    var timeSeriesGraphDataWeek = [];
    var timeSeriesGraphDataMonth = [];
    var timeSeriesGraphDataQuarter  = [];
    var timeSeriesGraphDataYear = [];
        
    //This process runs at 9:00 AM GMT (4:00 AM ET / 1:00 AM ET)
    //We need to shift the time to ET before calculating StartOf / EndOf so the DAY is correct
    // GMT is either 4 or 5 hours ahead of ET (7 or 8 hours) PT based on Daylight Savings; use 5 hour offset to be sure we are in the proper day regardless of DST
    //Note - for consistency and easy, periods start / end of are based on ET for now
    //Changed period meanings: Week=1W, Month=30D Quarter=90D Year=YTD
    
    const dayStart = NOW().clone().subtract(5, 'hours').startOf('day').add(5, 'hours');
    const weekStart = NOW().clone().subtract(5, 'hours').startOf('week').add(5, 'hours');
    const monthStart = NOW().clone().subtract(5, 'hours').startOf('month').add(5, 'hours');
    const quarterStart = NOW().clone().subtract(5, 'hours').startOf('quarter').add(5, 'hours');
    const yearStart = NOW().clone().subtract(5, 'hours').startOf('year').add(5, 'hours');  

    const oneWeekAgo = NOW().clone().subtract(5, 'hours').subtract(1,'weeks').startOf('day').add(5, 'hours');
    const oneMonthAgo = NOW().clone().subtract(5, 'hours').subtract(1,'months').startOf('day').add(5, 'hours');
    const twoMonthsAgo = NOW().clone().subtract(5, 'hours').subtract(2,'months').startOf('day').add(5, 'hours');
    const oneYearAgo = NOW().clone().subtract(5, 'hours').subtract(1,'years').startOf('day').add(5, 'hours');

    //TimeStamps are in the last DAY of the period at the end of the day in ET
    const endOfDay =  NOW().clone().subtract(5, 'hours').endOf('day').add(5, 'hours');
    const endOfWeek =  NOW().clone().subtract(5, 'hours').endOf('week').add(5, 'hours');
    const endOfMonth =  NOW().clone().subtract(5, 'hours').endOf('month').add(5, 'hours');
    const endOfQuarter =  NOW().clone().subtract(5, 'hours').endOf('quarter').add(5, 'hours');
    const endOfYear =  NOW().clone().subtract(5, 'hours').endOf('year').add(5, 'hours');

    //Vars for Network Graphs
    var edgesAlreadyAddedForScheduledConectivities = [];
    
    var customerNodeArray = [];
    var tempGraphDataAllDay = {};
    var tempGraphDataAllWeek = {};
    var tempGraphDataAllMonth     = {};
    var tempGraphDataAllOneWeek = {};
    var tempGraphDataAllOneMonth = {};
    var tempGraphDataAllTwoMonths = {};
    var tempGraphDataAllQuarter = {};
    var tempGraphDataAllYear = {};
    var tempGraphDataAllEntirePeriod = {};
    
    var tempGraphDataBalanceDay = {};
    var tempGraphDataBalanceWeek = {};   
    var tempGraphDataBalanceMonth = {};  
    var tempGraphDataBalanceOneWeek = {};
    var tempGraphDataBalanceOneMonth = {};
    var tempGraphDataBalanceTwoMonths = {};
    var tempGraphDataBalanceQuarter = {};
    var tempGraphDataBalanceYear = {};
    
    var tempGraphDataSocialDay = {};
    var tempGraphDataSocialWeek = {};
    var tempGraphDataSocialMonth = {};
    var tempGraphDataSocialOneWeek = {};
    var tempGraphDataSocialOneMonth = {};
    var tempGraphDataSocialTwoMonths = {};
    var tempGraphDataSocialQuarter = {};  
    var tempGraphDataSocialYear = {}; 
    
    var tempGraphDataTeamDay  = {};
    var tempGraphDataTeamWeek   = {};
    var tempGraphDataTeamMonth = {};   
    var tempGraphDataTeamOneWeek = {};
    var tempGraphDataTeamOneMonth = {};
    var tempGraphDataTeamTwoMonths = {};
    var tempGraphDataTeamQuarter = {};  
    var tempGraphDataTeamYear = {}; 
    
    var tempGraphDataPersonalDay  = {};
    var tempGraphDataPersonalWeek  = {};
    var tempGraphDataPersonalMonth = {};   
    var tempGraphDataPersonalOneWeek = {};
    var tempGraphDataPersonalOneMonth = {};
    var tempGraphDataPersonalTwoMonths = {};
    var tempGraphDataPersonalQuarter = {};    
    var tempGraphDataPersonalYear = {};    
    
    var tempGraphDataDEIDay  = {};
    var tempGraphDataDEIWeek  = {};
    var tempGraphDataDEIMonth = {};   
    var tempGraphDataDEIOneWeek = {};
    var tempGraphDataDEIOneMonth = {};
    var tempGraphDataDEITwoMonths = {};
    var tempGraphDataDEIQuarter = {};    
    var tempGraphDataDEIYear = {};    
    
    var tempGraphDataCSDay  = {};
    var tempGraphDataCSWeek  = {};
    var tempGraphDataCSMonth = {};   
    var tempGraphDataCSOneWeek = {};
    var tempGraphDataCSOneMonth = {};
    var tempGraphDataCSTwoMonths = {};
    var tempGraphDataCSQuarter = {};    
    var tempGraphDataCSYear = {};    
    
    //vars for User and Team Analytics   
   const userNumbersInitialState = {
        userID: "",
	    customerID: "",
        name:"",
        title:"",
        customer:"",
        onNumberOfTeams: 0,
        conectivitiesCompleted: 0,
        coinsAwarded: 0,
        badgesDEIAwarded: 0,
        badgesCSAwarded: 0,
        socialConectivitiesCompleted:0,
        stressConectivitiesCompleted:0,
        teamConectivitiesCompleted:0,
        individualConectivitiesCompleted:0,
        socialCoinsEarned:0,
        stressCoinsEarned:0,
        teamCoinsEarned:0,
        individualCoinsEarned:0,
      	spotlightsSent: 0,
      	spotlightsReceived: 0,
      	spotlightsCoinsEarned: 0,
      	spotlightsBadgesDEIEarned: 0,
      	spotlightsBadgesCSEarned: 0,
      	
        //Conectere Rankinging along the 6 pillars
        stressComponent: 0,
        socialComponent: 0,
        teamComponent: 0,
        individualComponent: 0,
        badgesCSComponent: 0,
        badgesDEIComponent: 0,
        relativeConectivityScore: 0,
        userRank:0,
 
        //Time Series Per-User Analytics    
        //Stats for DAY graph
        conectivitiesDay: 0,
        coinsReceivedDay: 0,
        badgesDEIReceivedDay: 0,
        badgesCSReceivedDay: 0,
        purchasesDay: 0,
        coinsSpentDay: 0,
        badgesDEISpentDay: 0,
        badgesCSSpentDay: 0,
        socialPeriodCoinsEarnedDay: 0,
      	socialPeriodConectivitiesCompleteDay: 0,
      	stressPeriodCoinsEarnedDay: 0,
      	stressPeriodConectivitiesCompleteDay: 0,
      	teamPeriodCoinsEarnedDay: 0,
      	teamPeriodConectivitiesCompleteDay: 0,
      	individualPeriodCoinsEarnedDay: 0,
      	individualPeriodConectivitiesCompleteDay: 0,
      	spotlightsSentDay: 0,
      	spotlightsReceivedDay: 0,
      	spotlightsCoinsEarnedDay: 0,
      	spotlightsBadgesDEIEarnedDay: 0,
      	spotlightsBadgesCSEarnedDay: 0,
     	

        //Stats for WEEK graph
        conectivitiesWeek: 0,
        coinsReceivedWeek: 0,
        badgesDEIReceivedWeek: 0,
        badgesCSReceivedWeek: 0,
        purchasesWeek: 0,
        coinsSpentWeek: 0,
        badgesDEISpentWeek: 0,
        badgesCSSpentWeek: 0,
        socialPeriodCoinsEarnedWeek: 0,
      	socialPeriodConectivitiesCompleteWeek: 0,
      	stressPeriodCoinsEarnedWeek: 0,
      	stressPeriodConectivitiesCompleteWeek: 0,
      	teamPeriodCoinsEarnedWeek: 0,
      	teamPeriodConectivitiesCompleteWeek: 0,
      	individualPeriodCoinsEarnedWeek: 0,
      	individualPeriodConectivitiesCompleteWeek: 0,
       	spotlightsSentWeek: 0,
      	spotlightsReceivedWeek: 0,
      	spotlightsCoinsEarnedWeek: 0,
      	spotlightsBadgesDEIEarnedWeek: 0,
      	spotlightsBadgesCSEarnedWeek: 0,
      	
        //Stats for Month graph
        conectivitiesMonth: 0,
        coinsReceivedMonth: 0,
        badgesDEIReceivedMonth: 0,
        badgesCSReceivedMonth: 0,
        purchasesMonth: 0,
        coinsSpentMonth: 0,
        badgesDEISpentMonth: 0,
        badgesCSSpentMonth: 0,
        socialPeriodCoinsEarnedMonth: 0,
      	socialPeriodConectivitiesCompleteMonth: 0,
      	stressPeriodCoinsEarnedMonth: 0,
      	stressPeriodConectivitiesCompleteMonth: 0,
      	teamPeriodCoinsEarnedMonth: 0,
      	teamPeriodConectivitiesCompleteMonth: 0,
      	individualPeriodCoinsEarnedMonth: 0,
      	individualPeriodConectivitiesCompleteMonth: 0,
      	spotlightsSentMonth: 0,
      	spotlightsReceivedMonth: 0,
      	spotlightsCoinsEarnedMonth: 0,
      	spotlightsBadgesDEIEarnedMonth: 0,
      	spotlightsBadgesCSEarnedMonth: 0,
      	
        //Stats for Quarter graph
        conectivitiesQuarter: 0,
        coinsReceivedQuarter: 0,
        badgesDEIReceivedQuarter: 0,
        badgesCSReceivedQuarter: 0,
        purchasesQuarter: 0,
        coinsSpentQuarter: 0,
        badgesDEISpentQuarter: 0,
        badgesCSSpentQuarter: 0,
        socialPeriodCoinsEarnedQuarter: 0,
      	socialPeriodConectivitiesCompleteQuarter: 0,
      	stressPeriodCoinsEarnedQuarter: 0,
      	stressPeriodConectivitiesCompleteQuarter: 0,
      	teamPeriodCoinsEarnedQuarter: 0,
      	teamPeriodConectivitiesCompleteQuarter: 0,
      	individualPeriodCoinsEarnedQuarter: 0,
      	individualPeriodConectivitiesCompleteQuarter: 0,
      	spotlightsSentQuarter: 0,
      	spotlightsReceivedQuarter: 0,
      	spotlightsCoinsEarnedQuarter: 0,
      	spotlightsBadgesDEIEarnedQuarter: 0,
      	spotlightsBadgesCSEarnedQuarter: 0,
      	
        //Stats for YEAR graph
        conectivitiesYear: 0,
        coinsReceivedYear: 0,
        badgesDEIReceivedYear: 0,
        badgesCSReceivedYear: 0,
        purchasesYear: 0,
        coinsSpentYear: 0,
        badgesDEISpentYear: 0,
        badgesCSSpentYear: 0,
        socialPeriodCoinsEarnedYear: 0,
      	socialPeriodConectivitiesCompleteYear: 0,
      	stressPeriodCoinsEarnedYear: 0,
      	stressPeriodConectivitiesCompleteYear: 0,
      	teamPeriodCoinsEarnedYear: 0,
      	teamPeriodConectivitiesCompleteYear: 0,
      	individualPeriodCoinsEarnedYear: 0,
      	individualPeriodConectivitiesCompleteYear: 0, 
      	spotlightsSentYear: 0,
      	spotlightsReceivedYear: 0,
      	spotlightsCoinsEarnedYear: 0,
      	spotlightsBadgesDEIEarnedYear: 0,
      	spotlightsBadgesCSEarnedYear: 0,

        //Stats for 1W
        conectivitiesOneWeek:0,
        coinsReceivedOneWeek: 0,
        badgesDEIReceivedOneWeek: 0,
        badgesCSReceivedOneWeek: 0,
        purchasesOneWeek: 0,
        coinsSpentOneWeek: 0,
        badgesDEISpentOneWeek: 0,
        badgesCSSpentOneWeek: 0,
        socialPeriodCoinsEarnedOneWeek: 0,
        socialPeriodConectivitiesCompleteOneWeek: 0,
        stressPeriodCoinsEarnedOneWeek: 0,
        stressPeriodConectivitiesCompleteOneWeek: 0,
        teamPeriodCoinsEarnedOneWeek: 0,
        teamPeriodConectivitiesCompleteOneWeek: 0,
        individualPeriodCoinsEarnedOneWeek: 0,
        individualPeriodConectivitiesCompleteOneWeek: 0,   
        spotlightsSentOneWeek: 0,
        spotlightsReceivedOneWeek: 0,
        spotlightsCoinsEarnedOneWeek: 0,
        spotlightsBadgesDEIEarnedOneWeek: 0,
        spotlightsBadgesCSEarnedOneWeek: 0,
        
         //Stats for 1M
        conectivitiesOneMonth: 0,
        coinsReceivedOneMonth: 0,
        badgesDEIReceivedOneMonth: 0,
        badgesCSReceivedOneMonth: 0,
        purchasesOneMonth: 0,
        coinsSpentOneMonth: 0,
        badgesDEISpentOneMonth: 0,
        badgesCSSpentOneMonth: 0,
        socialPeriodCoinsEarnedOneMonth: 0,
        socialPeriodConectivitiesCompleteOneMonth: 0,
        stressPeriodCoinsEarnedOneMonth: 0,
        stressPeriodConectivitiesCompleteOneMonth: 0,
        teamPeriodCoinsEarnedOneMonth: 0,
        teamPeriodConectivitiesCompleteOneMonth: 0,
        individualPeriodCoinsEarnedOneMonth: 0,
        individualPeriodConectivitiesCompleteOneMonth: 0,   
        spotlightsSentOneMonth: 0,
        spotlightsReceivedOneMonth: 0,
        spotlightsCoinsEarnedOneMonth: 0,
        spotlightsBadgesDEIEarnedOneMonth: 0,
        spotlightsBadgesCSEarnedOneMonth: 0,
        
         //Stats for 2M
        conectivitiesTwoMonths: 0,
        coinsReceivedTwoMonths: 0,
        badgesDEIReceivedTwoMonths: 0,
        badgesCSReceivedTwoMonths: 0,
        purchasesTwoMonths: 0,
        coinsSpentTwoMonths: 0,
        badgesDEISpentTwoMonths: 0,
        badgesCSSpentTwoMonths: 0,
        socialPeriodCoinsEarnedTwoMonths: 0,
        socialPeriodConectivitiesCompleteTwoMonths: 0,
        stressPeriodCoinsEarnedTwoMonths: 0,
        stressPeriodConectivitiesCompleteTwoMonths: 0,
        teamPeriodCoinsEarnedTwoMonths: 0,
        teamPeriodConectivitiesCompleteTwoMonths: 0,
        individualPeriodCoinsEarnedTwoMonths: 0,
        individualPeriodConectivitiesCompleteTwoMonths: 0,   
        spotlightsSentTwoMonths: 0,
        spotlightsReceivedTwoMonths: 0,
        spotlightsCoinsEarnedTwoMonths: 0,
        spotlightsBadgesDEIEarnedTwoMonths: 0,
        spotlightsBadgesCSEarnedTwoMonths: 0,  

         //Stats for 1Y
        conectivitiesOneYear: 0,
        coinsReceivedOneYear: 0,
        badgesDEIReceivedOneYear: 0,
        badgesCSReceivedOneYear: 0,
        purchasesOneYear: 0,
        coinsSpentOneYear: 0,
        badgesDEISpentOneYear: 0,
        badgesCSSpentOneYear: 0,
        socialPeriodCoinsEarnedOneYear: 0,
        socialPeriodConectivitiesCompleteOneYear: 0,
        stressPeriodCoinsEarnedOneYear: 0,
        stressPeriodConectivitiesCompleteOneYear: 0,
        teamPeriodCoinsEarnedOneYear: 0,
        teamPeriodConectivitiesCompleteOneYear: 0,
        individualPeriodCoinsEarnedOneYear: 0,
        individualPeriodConectivitiesCompleteOneYear: 0,   
        spotlightsSentOneYear: 0,
        spotlightsReceivedOneYear: 0,
        spotlightsCoinsEarnedOneYear: 0,
        spotlightsBadgesDEIEarnedOneYear: 0,
        spotlightsBadgesCSEarnedOneYear: 0,  

    };

 const teamNumbersInitialState = {
        teamID: "",
        name:"",
        nickname:"",
        department:"",
	    customerID: "",
        customer:"",
        conectivitiesCompleted: 0,
        coinsAwarded: 0,
        badgesDEIAwarded: 0,
        badgesCSAwarded: 0,
        socialConectivitiesCompleted:0,
        stressConectivitiesCompleted:0,
        teamConectivitiesCompleted:0,
        individualConectivitiesCompleted:0,
        socialCoinsEarned:0,
        stressCoinsEarned:0,
        teamCoinsEarned:0,
        individualCoinsEarned:0,
      	spotlightsSent: 0,
      	spotlightsReceived: 0,
      	spotlightsCoinsEarned: 0,
      	spotlightsBadgesDEIEarned: 0,
      	spotlightsBadgesCSEarned: 0,
       
        //Conectere score
        stressComponent: 0,
        socialComponent: 0,
        teamComponent: 0,
        individualComponent: 0,
        badgesCSComponent: 0,
        badgesDEIComponent: 0,
        relativeConectivityScore: 0,
        teamRank:0,
        
        //Stats for DAY graph
        conectivitiesDay: 0,
        coinsReceivedDay: 0,
        badgesDEIReceivedDay: 0,
        badgesCSReceivedDay: 0,
        purchasesDay: 0,
        coinsSpentDay: 0,
        badgesDEISpentDay: 0,
        badgesCSSpentDay: 0,
        socialPeriodCoinsEarnedDay: 0,
      	socialPeriodConectivitiesCompleteDay: 0,
      	stressPeriodCoinsEarnedDay: 0,
      	stressPeriodConectivitiesCompleteDay: 0,
      	teamPeriodCoinsEarnedDay: 0,
      	teamPeriodConectivitiesCompleteDay: 0,
      	individualPeriodCoinsEarnedDay: 0,
      	individualPeriodConectivitiesCompleteDay: 0,
      	spotlightsSentDay: 0,
      	spotlightsReceivedDay: 0,
      	spotlightsCoinsEarnedDay: 0,
      	spotlightsBadgesDEIEarnedDay: 0,
      	spotlightsBadgesCSEarnedDay: 0,
    	

        //Stats for WEEK graph
        conectivitiesWeek: 0,
        coinsReceivedWeek: 0,
        badgesDEIReceivedWeek: 0,
        badgesCSReceivedWeek: 0,
        purchasesWeek: 0,
        coinsSpentWeek: 0,
        badgesDEISpentWeek: 0,
        badgesCSSpentWeek: 0,
        socialPeriodCoinsEarnedWeek: 0,
      	socialPeriodConectivitiesCompleteWeek: 0,
      	stressPeriodCoinsEarnedWeek: 0,
      	stressPeriodConectivitiesCompleteWeek: 0,
      	teamPeriodCoinsEarnedWeek: 0,
      	teamPeriodConectivitiesCompleteWeek: 0,
      	individualPeriodCoinsEarnedWeek: 0,
      	individualPeriodConectivitiesCompleteWeek: 0,
       	spotlightsSentWeek: 0,
      	spotlightsReceivedWeek: 0,
      	spotlightsCoinsEarnedWeek: 0,
      	spotlightsBadgesDEIEarnedWeek: 0,
      	spotlightsBadgesCSEarnedWeek: 0,

        //Stats for Month graph
        conectivitiesMonth: 0,
        coinsReceivedMonth: 0,
        badgesDEIReceivedMonth: 0,
        badgesCSReceivedMonth: 0,
        purchasesMonth: 0,
        coinsSpentMonth: 0,
        badgesDEISpentMonth: 0,
        badgesCSSpentMonth: 0,
        socialPeriodCoinsEarnedMonth: 0,
      	socialPeriodConectivitiesCompleteMonth: 0,
      	stressPeriodCoinsEarnedMonth: 0,
      	stressPeriodConectivitiesCompleteMonth: 0,
      	teamPeriodCoinsEarnedMonth: 0,
      	teamPeriodConectivitiesCompleteMonth: 0,
      	individualPeriodCoinsEarnedMonth: 0,
      	individualPeriodConectivitiesCompleteMonth: 0,
      	spotlightsSentMonth: 0,
      	spotlightsReceivedMonth: 0,
      	spotlightsCoinsEarnedMonth: 0,
      	spotlightsBadgesDEIEarnedMonth: 0,
      	spotlightsBadgesCSEarnedMonth: 0,

      	
        //Stats for Quarter graph
        conectivitiesQuarter: 0,
        coinsReceivedQuarter: 0,
        badgesDEIReceivedQuarter: 0,
        badgesCSReceivedQuarter: 0,
        purchasesQuarter: 0,
        coinsSpentQuarter: 0,
        badgesDEISpentQuarter: 0,
        badgesCSSpentQuarter: 0,
        socialPeriodCoinsEarnedQuarter: 0,
      	socialPeriodConectivitiesCompleteQuarter: 0,
      	stressPeriodCoinsEarnedQuarter: 0,
      	stressPeriodConectivitiesCompleteQuarter: 0,
      	teamPeriodCoinsEarnedQuarter: 0,
      	teamPeriodConectivitiesCompleteQuarter: 0,
      	individualPeriodCoinsEarnedQuarter: 0,
      	individualPeriodConectivitiesCompleteQuarter: 0,
      	spotlightsSentQuarter: 0,
      	spotlightsReceivedQuarter: 0,
      	spotlightsCoinsEarnedQuarter: 0,
      	spotlightsBadgesDEIEarnedQuarter: 0,
      	spotlightsBadgesCSEarnedQuarter: 0,

      	
        //Stats for YEAR graph
        conectivitiesYear: 0,
        coinsReceivedYear: 0,
        badgesDEIReceivedYear: 0,
        badgesCSReceivedYear: 0,
        purchasesYear: 0,
        coinsSpentYear: 0,
        badgesDEISpentYear: 0,
        badgesCSSpentYear: 0,
        socialPeriodCoinsEarnedYear: 0,
      	socialPeriodConectivitiesCompleteYear: 0,
      	stressPeriodCoinsEarnedYear: 0,
      	stressPeriodConectivitiesCompleteYear: 0,
      	teamPeriodCoinsEarnedYear: 0,
      	teamPeriodConectivitiesCompleteYear: 0,
      	individualPeriodCoinsEarnedYear: 0,
      	individualPeriodConectivitiesCompleteYear: 0, 
       	spotlightsSentYear: 0,
      	spotlightsReceivedYear: 0,
      	spotlightsCoinsEarnedYear: 0,
      	spotlightsBadgesDEIEarnedYear: 0,
      	spotlightsBadgesCSEarnedYear: 0,
     	
        //Stats for 1W
        conectivitiesOneWeek:0,
        coinsReceivedOneWeek: 0,
        badgesDEIReceivedOneWeek: 0,
        badgesCSReceivedOneWeek: 0,
        purchasesOneWeek: 0,
        coinsSpentOneWeek: 0,
        badgesDEISpentOneWeek: 0,
        badgesCSSpentOneWeek: 0,
        socialPeriodCoinsEarnedOneWeek: 0,
        socialPeriodConectivitiesCompleteOneWeek: 0,
        stressPeriodCoinsEarnedOneWeek: 0,
        stressPeriodConectivitiesCompleteOneWeek: 0,
        teamPeriodCoinsEarnedOneWeek: 0,
        teamPeriodConectivitiesCompleteOneWeek: 0,
        individualPeriodCoinsEarnedOneWeek: 0,
        individualPeriodConectivitiesCompleteOneWeek: 0,   
        spotlightsSentOneWeek: 0,
        spotlightsReceivedOneWeek: 0,
        spotlightsCoinsEarnedOneWeek: 0,
        spotlightsBadgesDEIEarnedOneWeek: 0,
        spotlightsBadgesCSEarnedOneWeek: 0,
        
         //Stats for 1M
        conectivitiesOneMonth: 0,
        coinsReceivedOneMonth: 0,
        badgesDEIReceivedOneMonth: 0,
        badgesCSReceivedOneMonth: 0,
        purchasesOneMonth: 0,
        coinsSpentOneMonth: 0,
        badgesDEISpentOneMonth: 0,
        badgesCSSpentOneMonth: 0,
        socialPeriodCoinsEarnedOneMonth: 0,
        socialPeriodConectivitiesCompleteOneMonth: 0,
        stressPeriodCoinsEarnedOneMonth: 0,
        stressPeriodConectivitiesCompleteOneMonth: 0,
        teamPeriodCoinsEarnedOneMonth: 0,
        teamPeriodConectivitiesCompleteOneMonth: 0,
        individualPeriodCoinsEarnedOneMonth: 0,
        individualPeriodConectivitiesCompleteOneMonth: 0,   
        spotlightsSentOneMonth: 0,
        spotlightsReceivedOneMonth: 0,
        spotlightsCoinsEarnedOneMonth: 0,
        spotlightsBadgesDEIEarnedOneMonth: 0,
        spotlightsBadgesCSEarnedOneMonth: 0,
        
         //Stats for 2M
        conectivitiesTwoMonths: 0,
        coinsReceivedTwoMonths: 0,
        badgesDEIReceivedTwoMonths: 0,
        badgesCSReceivedTwoMonths: 0,
        purchasesTwoMonths: 0,
        coinsSpentTwoMonths: 0,
        badgesDEISpentTwoMonths: 0,
        badgesCSSpentTwoMonths: 0,
        socialPeriodCoinsEarnedTwoMonths: 0,
        socialPeriodConectivitiesCompleteTwoMonths: 0,
        stressPeriodCoinsEarnedTwoMonths: 0,
        stressPeriodConectivitiesCompleteTwoMonths: 0,
        teamPeriodCoinsEarnedTwoMonths: 0,
        teamPeriodConectivitiesCompleteTwoMonths: 0,
        individualPeriodCoinsEarnedTwoMonths: 0,
        individualPeriodConectivitiesCompleteTwoMonths: 0,   
        spotlightsSentTwoMonths: 0,
        spotlightsReceivedTwoMonths: 0,
        spotlightsCoinsEarnedTwoMonths: 0,
        spotlightsBadgesDEIEarnedTwoMonths: 0,
        spotlightsBadgesCSEarnedTwoMonths: 0,      	

         //Stats for 1Y
        conectivitiesOneYear: 0,
        coinsReceivedOneYear: 0,
        badgesDEIReceivedOneYear: 0,
        badgesCSReceivedOneYear: 0,
        purchasesOneYear: 0,
        coinsSpentOneYear: 0,
        badgesDEISpentOneYear: 0,
        badgesCSSpentOneYear: 0,
        socialPeriodCoinsEarnedOneYear: 0,
        socialPeriodConectivitiesCompleteOneYear: 0,
        stressPeriodCoinsEarnedOneYear: 0,
        stressPeriodConectivitiesCompleteOneYear: 0,
        teamPeriodCoinsEarnedOneYear: 0,
        teamPeriodConectivitiesCompleteOneYear: 0,
        individualPeriodCoinsEarnedOneYear: 0,
        individualPeriodConectivitiesCompleteOneYear: 0,   
        spotlightsSentOneYear: 0,
        spotlightsReceivedOneYear: 0,
        spotlightsCoinsEarnedOneYear: 0,
        spotlightsBadgesDEIEarnedOneYear: 0,
        spotlightsBadgesCSEarnedOneYear: 0,        	
    };
    
    //Company-wide analytics
    //Total running STATS for the customer

   const customerWideAnalyticsInitialState = {
        
        employeesTotal: 0,
        teamsTotal: 0,                      //Total # of teams in the company

        //Company-wide USER-related stats
        totalConectivitiesCompletedByUsers: 0,
        maxConectivitiesCompletedByUsers: 0,
        minConectivitiesCompletedByUsers: 99999,
        avgConectivitiesCompletedByUsers: 0,
        totalCoinsEarnedByUsers: 0,
        maxCoinsEarnedByUsers: 0,
        minCoinsEarnedByUsers: 99999,
        avgCoinsEarnedByUsers: 0,
        totalDEIBadgesEarnedByUsers: 0,
        maxDEIBadgesEarnedByUsers: 0,
        minDEIBadgesEarnedByUsers: 99999,
        avgDEIBadgesEarnedByUsers: 0,
        totalCSBadgesEarnedByUsers: 0,
        maxCSBadgesEarnedByUsers: 0,
        minCSBadgesEarnedByUsers: 99999,
        avgCSBadgesEarnedByUsers: 0,
        totalSocialConectivitiesCompletedByUsers: 0,
        maxSocialConectivitiesCompletedByUsers: 0,
        minSocialConectivitiesCompletedByUsers: 99999,
        avgSocialConectivitiesCompletedByUsers: 0,
        totalStressConectivitiesCompletedByUsers: 0,
        maxStressConectivitiesCompletedByUsers: 0,
        minStressConectivitiesCompletedByUsers: 99999,
        avgStressConectivitiesCompletedByUsers: 0,
        totalTeamConectivitiesCompletedByUsers: 0,
        maxTeamConectivitiesCompletedByUsers: 0,
        minTeamConectivitiesCompletedByUsers: 99999,
        avgTeamConectivitiesCompletedByUsers: 0,
        totalIndividualConectivitiesCompletedByUsers: 0,
        maxIndividualConectivitiesCompletedByUsers: 0,
        minIndividualConectivitiesCompletedByUsers: 99999,
        avgIndividualConectivitiesCompletedByUsers: 0,
        maxRelativeConectivityScoreByUsers: 0,
        minRelativeConectivityScoreByUsers: 99999,
        avgRelativeConectivityScoreByUsers: 0,

        //Analytics for the longest streaks currently active for the customer        
        balanceStreakCount: 0,
        balanceStreakCountMax: 0,
        balanceStreakStartDateEarliest: "2001-01-01T00:00:00+09:00",
        balanceStreakMaxUsers: [],     // Array of user IDs for individuals with the longest streak
        socialStreakCount: 0,
        socialStreakCountMax: 0,
        socialStreakStartDateEarliest: "2001-01-01T00:00:00+09:00",
        socialStreakMaxUsers: [],     // Array of user IDs for individuals with the longest streak
        teamStreakCount: 0,
        teamStreakCountMax: 0,
        teamStreakStartDateEarliest: "2001-01-01T00:00:00+09:00",
        teamStreakMaxUsers: [],     // Array of user IDs for individuals with the longest streak
        growthStreakCount: 0,
        growthStreakCountMax: 0,
        growthStreakStartDateEarliest: "2001-01-01T00:00:00+09:00",        
        growthStreakMaxUsers: [],     // Array of user IDs for individuals with the longest streak

        //Company-wide TEAM-related stats
        totalConectivitiesCompletedByTeams: 0,
        maxConectivitiesCompletedByTeams: 0,
        minConectivitiesCompletedByTeams: 99999,
        avgConectivitiesCompletedByTeams: 0,
        totalCoinsEarnedByTeams: 0,
        maxCoinsEarnedByTeams: 0,
        minCoinsEarnedByTeams: 99999,
        avgCoinsEarnedByTeams: 0,
        totalDEIBadgesEarnedByTeams: 0,
        maxDEIBadgesEarnedByTeams: 0,
        minDEIBadgesEarnedByTeams: 99999,
        avgDEIBadgesEarnedByTeams: 0,
        totalCSBadgesEarnedByTeams: 0,
        maxCSBadgesEarnedByTeams: 0,
        minCSBadgesEarnedByTeams: 99999,
        avgCSBadgesEarnedByTeams: 0,
        totalSocialConectivitiesCompletedByTeams: 0,
        maxSocialConectivitiesCompletedByTeams: 0,
        minSocialConectivitiesCompletedByTeams: 99999,
        avgSocialConectivitiesCompletedByTeams: 0,
        totalStressConectivitiesCompletedByTeams: 0,
        maxStressConectivitiesCompletedByTeams: 0,
        minStressConectivitiesCompletedByTeams: 99999,
        avgStressConectivitiesCompletedByTeams: 0,
        totalTeamConectivitiesCompletedByTeams: 0,
        maxTeamConectivitiesCompletedByTeams: 0,
        minTeamConectivitiesCompletedByTeams: 99999,
        avgTeamConectivitiesCompletedByTeams: 0,
        totalIndividualConectivitiesCompletedByTeams: 0,
        maxIndividualConectivitiesCompletedByTeams: 0,
        minIndividualConectivitiesCompletedByTeams: 99999,
        avgIndividualConectivitiesCompletedByTeams: 0,
        maxRelativeConectivityScoreByTeams: 0,
        minRelativeConectivityScoreByTeams: 99999,
        avgRelativeConectivityScoreByTeams: 0,        
        largestTeam: 0,
        smallestTeam: 99999,

        //Company-wide SPOTLIGHT-related stats
        spotlightsSentTotal: 0,
        spotlightsReceivedTotal: 0,
        spotlightsCoinsEarnedTotal: 0,
        spotlightsBadgesDEIEarnedTotal: 0,
        spotlightsBadgesCSEarnedTotal: 0,
        leastSpotlightsReceived: 99999,
        mostSpotlightsReceived: 0,
        leastSpotlightsSent: 9999,
        mostSpotlightsSent: 0,  
        avgSpotlightsSent: 0,
        avgSpotlightsReceived: 0,
        employeesRecognized: 0,
        employeesUnrecognized: 0,
        percentEmployeesRecognized: 0,      
        
        //Customer-wide Time-Series Totals    
        //DAY
        conectivitiesDay: 0,
        coinsReceivedDay: 0,
        badgesDEIReceivedDay: 0,
        badgesCSReceivedDay: 0,
        purchasesDay: 0,
        coinsSpentDay: 0,
        badgesDEISpentDay: 0,
        badgesCSSpentDay: 0,
        socialPeriodCoinsEarnedDay: 0,
        socialPeriodConectivitiesCompleteDay: 0,
        stressPeriodCoinsEarnedDay: 0,
        stressPeriodConectivitiesCompleteDay: 0,
        teamPeriodCoinsEarnedDay: 0,
        teamPeriodConectivitiesCompleteDay: 0,
        individualPeriodCoinsEarnedDay: 0,
        individualPeriodConectivitiesCompleteDay: 0,
      	spotlightsSentDay: 0,
      	spotlightsReceivedDay: 0,
      	spotlightsCoinsEarnedDay: 0,
      	spotlightsBadgesDEIEarnedDay: 0,
      	spotlightsBadgesCSEarnedDay: 0,
        avgSpotlightsSentDay: 0,
        avgSpotlightsReceivedDay: 0,

        //Stats for WEEK graph
        conectivitiesWeek: 0,
        coinsReceivedWeek: 0,
        badgesDEIReceivedWeek: 0,
        badgesCSReceivedWeek: 0,
        purchasesWeek: 0,
        coinsSpentWeek: 0,
        badgesDEISpentWeek: 0,
        badgesCSSpentWeek: 0,
        socialPeriodCoinsEarnedWeek: 0,
        socialPeriodConectivitiesCompleteWeek: 0,
        stressPeriodCoinsEarnedWeek: 0,
        stressPeriodConectivitiesCompleteWeek: 0,
        teamPeriodCoinsEarnedWeek: 0,
        teamPeriodConectivitiesCompleteWeek: 0,
        individualPeriodCoinsEarnedWeek: 0,
        individualPeriodConectivitiesCompleteWeek: 0,
      	spotlightsSentWeek: 0,
      	spotlightsReceivedWeek: 0,
      	spotlightsCoinsEarnedWeek: 0,
      	spotlightsBadgesDEIEarnedWeek: 0,
      	spotlightsBadgesCSEarnedWeek: 0,
        avgSpotlightsSentWeek: 0,
        avgSpotlightsReceivedWeek: 0,
      	
        //Stats for Month graph
        conectivitiesMonth: 0,
        coinsReceivedMonth: 0,
        badgesDEIReceivedMonth: 0,
        badgesCSReceivedMonth: 0,
        purchasesMonth: 0,
        coinsSpentMonth: 0,
        badgesDEISpentMonth: 0,
        badgesCSSpentMonth: 0,
        socialPeriodCoinsEarnedMonth: 0,
        socialPeriodConectivitiesCompleteMonth: 0,
        stressPeriodCoinsEarnedMonth: 0,
        stressPeriodConectivitiesCompleteMonth: 0,
        teamPeriodCoinsEarnedMonth: 0,
        teamPeriodConectivitiesCompleteMonth: 0,
        individualPeriodCoinsEarnedMonth: 0,
        individualPeriodConectivitiesCompleteMonth: 0,
      	spotlightsSentMonth: 0,
      	spotlightsReceivedMonth: 0,
      	spotlightsCoinsEarnedMonth: 0,
      	spotlightsBadgesDEIEarnedMonth: 0,
      	spotlightsBadgesCSEarnedMonth: 0,
        avgSpotlightsSentMonth: 0,
        avgSpotlightsReceivedMonth: 0,
      	
        //Stats for Quarter graph
        conectivitiesQuarter: 0,
        coinsReceivedQuarter: 0,
        badgesDEIReceivedQuarter: 0,
        badgesCSReceivedQuarter: 0,
        purchasesQuarter: 0,
        coinsSpentQuarter: 0,
        badgesDEISpentQuarter: 0,
        badgesCSSpentQuarter: 0,
        socialPeriodCoinsEarnedQuarter: 0,
        socialPeriodConectivitiesCompleteQuarter: 0,
        stressPeriodCoinsEarnedQuarter: 0,
        stressPeriodConectivitiesCompleteQuarter: 0,
        teamPeriodCoinsEarnedQuarter: 0,
        teamPeriodConectivitiesCompleteQuarter: 0,
        individualPeriodCoinsEarnedQuarter: 0,
        individualPeriodConectivitiesCompleteQuarter: 0,
      	spotlightsSentQuarter: 0,
      	spotlightsReceivedQuarter: 0,
      	spotlightsCoinsEarnedQuarter: 0,
      	spotlightsBadgesDEIEarnedQuarter: 0,
      	spotlightsBadgesCSEarnedQuarter: 0,
        avgSpotlightsSentQuarter: 0,
        avgSpotlightsReceivedQuarter: 0,
     	
        //Stats for YEAR graph
        conectivitiesYear: 0,
        coinsReceivedYear: 0,
        badgesDEIReceivedYear: 0,
        badgesCSReceivedYear: 0,
        purchasesYear: 0,
        coinsSpentYear: 0,
        badgesDEISpentYear: 0,
        badgesCSSpentYear: 0,
        socialPeriodCoinsEarnedYear: 0,
        socialPeriodConectivitiesCompleteYear: 0,
        stressPeriodCoinsEarnedYear: 0,
        stressPeriodConectivitiesCompleteYear: 0,
        teamPeriodCoinsEarnedYear: 0,
        teamPeriodConectivitiesCompleteYear: 0,
        individualPeriodCoinsEarnedYear: 0,
        individualPeriodConectivitiesCompleteYear: 0,         
      	spotlightsSentYear: 0,
      	spotlightsReceivedYear: 0,
      	spotlightsCoinsEarnedYear: 0,
      	spotlightsBadgesDEIEarnedYear: 0,
      	spotlightsBadgesCSEarnedYear: 0,
        avgSpotlightsSentYear: 0,
        avgSpotlightsReceivedYear: 0,
        
        //Stats for 1W graph
        conectivitiesOneWeek: 0,
        coinsReceivedOneWeek: 0,
        badgesDEIReceivedOneWeek: 0,
        badgesCSReceivedOneWeek: 0,
        purchasesOneWeek: 0,
        coinsSpentOneWeek: 0,
        badgesDEISpentOneWeek: 0,
        badgesCSSpentOneWeek: 0,
        socialPeriodCoinsEarnedOneWeek: 0,
        socialPeriodConectivitiesCompleteOneWeek: 0,
        stressPeriodCoinsEarnedOneWeek: 0,
        stressPeriodConectivitiesCompleteOneWeek: 0,
        teamPeriodCoinsEarnedOneWeek: 0,
        teamPeriodConectivitiesCompleteOneWeek: 0,
        individualPeriodCoinsEarnedOneWeek: 0,
        individualPeriodConectivitiesCompleteOneWeek: 0,         
      	spotlightsSentOneWeek: 0,
      	spotlightsReceivedOneWeek: 0,
      	spotlightsCoinsEarnedOneWeek: 0,
      	spotlightsBadgesDEIEarnedOneWeek: 0,
      	spotlightsBadgesCSEarnedOneWeek: 0,
        avgSpotlightsSentOneWeek: 0,
        avgSpotlightsReceivedOneWeek: 0,
        
        //Stats for 1M graph
        conectivitiesOneMonth: 0,
        coinsReceivedOneMonth: 0,
        badgesDEIReceivedOneMonth: 0,
        badgesCSReceivedOneMonth: 0,
        purchasesOneMonth: 0,
        coinsSpentOneMonth: 0,
        badgesDEISpentOneMonth: 0,
        badgesCSSpentOneMonth: 0,
        socialPeriodCoinsEarnedOneMonth: 0,
        socialPeriodConectivitiesCompleteOneMonth: 0,
        stressPeriodCoinsEarnedOneMonth: 0,
        stressPeriodConectivitiesCompleteOneMonth: 0,
        teamPeriodCoinsEarnedOneMonth: 0,
        teamPeriodConectivitiesCompleteOneMonth: 0,
        individualPeriodCoinsEarnedOneMonth: 0,
        individualPeriodConectivitiesCompleteOneMonth: 0,         
      	spotlightsSentOneMonth: 0,
      	spotlightsReceivedOneMonth: 0,
      	spotlightsCoinsEarnedOneMonth: 0,
      	spotlightsBadgesDEIEarnedOneMonth: 0,
      	spotlightsBadgesCSEarnedOneMonth: 0,
        avgSpotlightsSentOneMonth: 0,
        avgSpotlightsReceivedOneMonth: 0,
        
        //Stats for 2M graph
        conectivitiesTwoMonths: 0,
        coinsReceivedTwoMonths: 0,
        badgesDEIReceivedTwoMonths: 0,
        badgesCSReceivedTwoMonths: 0,
        purchasesTwoMonths: 0,
        coinsSpentTwoMonths: 0,
        badgesDEISpentTwoMonths: 0,
        badgesCSSpentTwoMonths: 0,
        socialPeriodCoinsEarnedTwoMonths: 0,
        socialPeriodConectivitiesCompleteTwoMonths: 0,
        stressPeriodCoinsEarnedTwoMonths: 0,
        stressPeriodConectivitiesCompleteTwoMonths: 0,
        teamPeriodCoinsEarnedTwoMonths: 0,
        teamPeriodConectivitiesCompleteTwoMonths: 0,
        individualPeriodCoinsEarnedTwoMonths: 0,
        individualPeriodConectivitiesCompleteTwoMonths: 0,         
      	spotlightsSentTwoMonths: 0,
      	spotlightsReceivedTwoMonths: 0,
      	spotlightsCoinsEarnedTwoMonths: 0,
      	spotlightsBadgesDEIEarnedTwoMonths: 0,
      	spotlightsBadgesCSEarnedTwoMonths: 0,
        avgSpotlightsSentTwoMonths: 0,
        avgSpotlightsReceivedTwoMonths: 0,        

         //Stats for 1Y
        conectivitiesOneYear: 0,
        coinsReceivedOneYear: 0,
        badgesDEIReceivedOneYear: 0,
        badgesCSReceivedOneYear: 0,
        purchasesOneYear: 0,
        coinsSpentOneYear: 0,
        badgesDEISpentOneYear: 0,
        badgesCSSpentOneYear: 0,
        socialPeriodCoinsEarnedOneYear: 0,
        socialPeriodConectivitiesCompleteOneYear: 0,
        stressPeriodCoinsEarnedOneYear: 0,
        stressPeriodConectivitiesCompleteOneYear: 0,
        teamPeriodCoinsEarnedOneYear: 0,
        teamPeriodConectivitiesCompleteOneYear: 0,
        individualPeriodCoinsEarnedOneYear: 0,
        individualPeriodConectivitiesCompleteOneYear: 0,   
        spotlightsSentOneYear: 0,
        spotlightsReceivedOneYear: 0,
        spotlightsCoinsEarnedOneYear: 0,
        spotlightsBadgesDEIEarnedOneYear: 0,
        spotlightsBadgesCSEarnedOneYear: 0, 
        avgSpotlightsSentOneYear: 0,
        avgSpotlightsReceivedOneYear: 0,   
    };
 
 


  //This function determines whether to give credit for a conectivity done as a TEAM
  //For credit, the TEAM must be identified in the Conectivity transaction
  function creditTeamForConectivity(team, transaction) {
    
    if (team == null || transaction == null) return false;   //Should never happen
    
    
    //check with the transaction has participating teams specifically identified
      if (transaction.teamsThatParticipatedWithMe !== null) {
          if (transaction.teamsThatParticipatedWithMe.some(teamID => (teamID == team.id))) {
            // if (DEBUG_MODE >= 2) console.log("Giving credit for a team conectivity due to this team being identified in the transaction", team, transaction);
            return true; //Found a matching team ID
          }
      }
  
    return false;
    
    //Second, alternatively, give credit if at least one other participant is on  identified are on this same team
    // if (transaction.usersThatParticipatedWithMe !== null) return false;
    // if (transaction.usersThatParticipatedWithMe.length == 0) return false;
    
    // if (DEBUG_MODE >= 2) console.log("Checking transaction to determine whether participating users are on same team", team, transaction);

    // var giveCredit = true;

    // for (var y=0; y<transaction.usersThatParticipatedWithMe.length; y++) {
        
    //   //Are all participants of the transaction on this same team, which we know the user is on?

    //     //Is there NO JOIN having this participating user and this team ID?
    //     if (userTeamJoins.some(join => (join.teamID == team.id && join.userID == transaction.usersThatParticipatedWithMe[y])) == false) {
    //       giveCredit = false;
    //       break; //Jump out of the FOR loop                  
    //     }
    //   }

    //   if (DEBUG_MODE >= 2) console.log("Giving credit for a team conectivity since all participanats are on the same team as the user", team, transaction);
    //   return giveCredit;
    
  }
    
//
// TIME SERIES HELPER FUNCTIONS
//

  function updateTimeSeriesTotals({transaction, index, analyticsArray}){
      
      const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
      
      //Is transaction in the future, like in our simulation data?  If so, skip it
        if (transactionDate.isAfter(NOW())) return;

      //Update TODAY counts
      if (transactionDate.isAfter(dayStart)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesDay +=  1;
              analyticsArray [index].coinsReceivedDay +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedDay +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedDay +=  transaction.badgesCS;

              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedDay += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteDay += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedDay += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteDay += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedDay += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteDay += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedDay += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteDay += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesDay +=  1;
              analyticsArray [index].coinsSpentDay +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentDay +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentDay +=  transaction.badgesCS;
              
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentDay +=1;
              	
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED") analyticsArray[index].spotlightsReceivedDay +=1;
              	analyticsArray[index].spotlightsCoinsEarnedDay +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedDay +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedDay +=1;  
          }
      }


      //Update WEEK counts
      if (transactionDate.isAfter(weekStart)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesWeek +=  1;
              analyticsArray [index].coinsReceivedWeek +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedWeek +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedWeek +=  transaction.badgesCS;

              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedWeek += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteWeek += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedWeek += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteWeek += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedWeek += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteWeek += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedWeek += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteWeek += 1;
              }


          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesWeek +=  1;
              analyticsArray [index].coinsSpentWeek +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentWeek +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentWeek +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

              //Update Spotlight totals for this user
             	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentWeek +=1;
              if (transaction.transactionType == "SPOTLIGHT_RECEIVED") analyticsArray[index].spotlightsReceivedWeek +=1;
            	analyticsArray[index].spotlightsCoinsEarnedWeek +=1;
            	analyticsArray[index].spotlightsBadgesDEIEarnedWeek +=1;
            	analyticsArray[index].spotlightsBadgesCSEarnedWeek +=1;  
          }
      }

      //Update MONTH counts
      if (transactionDate.isAfter(monthStart)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesMonth +=  1;
              analyticsArray [index].coinsReceivedMonth +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedMonth +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedMonth +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedMonth += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteMonth += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedMonth += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteMonth += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedMonth += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteMonth += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedMonth += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteMonth += 1;
              }


          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesMonth +=  1;
              analyticsArray [index].coinsSpentMonth +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentMonth +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentMonth +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentMonth +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED") analyticsArray[index].spotlightsReceivedMonth +=1;
              	analyticsArray[index].spotlightsCoinsEarnedMonth +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedMonth +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedMonth +=1;  
          }
      }

      //Update QUARTER counts
      if (transactionDate.isAfter(quarterStart)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesQuarter +=  1;
              analyticsArray [index].coinsReceivedQuarter +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedQuarter +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedQuarter +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedQuarter += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteQuarter += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedQuarter += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteQuarter += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedQuarter += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteQuarter += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedQuarter += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteQuarter += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesQuarter +=  1;
              analyticsArray [index].coinsSpentQuarter +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentQuarter +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentQuarter +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT")  analyticsArray[index].spotlightsSentQuarter +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED") analyticsArray[index].spotlightsReceivedQuarter +=1;
              	analyticsArray[index].spotlightsCoinsEarnedQuarter +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedQuarter +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedQuarter +=1;  
          }
      }

      //Update YEAR counts
      if (transactionDate.isAfter(yearStart)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesYear +=  1;
              analyticsArray [index].coinsReceivedYear +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedYear +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedYear +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedYear += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteYear += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedYear += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteYear += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedYear += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteYear += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedYear += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteYear += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesYear +=  1;
              analyticsArray [index].coinsSpentYear +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentYear +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentYear +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentYear +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED")  analyticsArray[index].spotlightsReceivedYear +=1;
              	analyticsArray[index].spotlightsCoinsEarnedYear +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedYear +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedYear +=1;  
          }
      }  

      //Update 1W counts
      if (transactionDate.isAfter(oneWeekAgo)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesOneWeek +=  1;
              analyticsArray [index].coinsReceivedOneWeek +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedOneWeek +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedOneWeek +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedOneWeek += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteOneWeek += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedOneWeek += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteOneWeek += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedOneWeek += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteOneWeek += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedOneWeek += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteOneWeek += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesOneWeek +=  1;
              analyticsArray [index].coinsSpentOneWeek +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentOneWeek +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentOneWeek +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentOneWeek +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED")  analyticsArray[index].spotlightsReceivedOneWeek +=1;
              	analyticsArray[index].spotlightsCoinsEarnedOneWeek +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedOneWeek +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedOneWeek +=1;  
          }
      }  
      
      //Update 1M counts
      if (transactionDate.isAfter(oneMonthAgo)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesOneMonth +=  1;
              analyticsArray [index].coinsReceivedOneMonth +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedOneMonth +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedOneMonth +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedOneMonth += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteOneMonth += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedOneMonth += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteOneMonth += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedOneMonth += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteOneMonth += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedOneMonth += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteOneMonth += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesOneMonth +=  1;
              analyticsArray [index].coinsSpentOneMonth +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentOneMonth +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentOneMonth +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentOneMonth +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED")  analyticsArray[index].spotlightsReceivedOneMonth +=1;
              	analyticsArray[index].spotlightsCoinsEarnedOneMonth +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedOneMonth +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedOneMonth +=1;  
          }
      }  
      
      //Update 2M counts
      if (transactionDate.isAfter(twoMonthsAgo)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesTwoMonths +=  1;
              analyticsArray [index].coinsReceivedTwoMonths +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedTwoMonths +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedTwoMonths +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedTwoMonths += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteTwoMonths += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedTwoMonths += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteTwoMonths += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedTwoMonths += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteTwoMonths += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedTwoMonths += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteTwoMonths += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesTwoMonths +=  1;
              analyticsArray [index].coinsSpentTwoMonths +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentTwoMonths +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentTwoMonths +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {

                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentTwoMonths +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED")  analyticsArray[index].spotlightsReceivedTwoMonths +=1;
              	analyticsArray[index].spotlightsCoinsEarnedTwoMonths +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedTwoMonths +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedTwoMonths +=1;  
          }
      }      

      //Update 1Y counts
      if (transactionDate.isAfter(oneYearAgo)) {
          if (transaction.transactionType === "COMPLETED_CONECTIVITY") {
              analyticsArray [index].conectivitiesOneYear +=  1;
              analyticsArray [index].coinsReceivedOneYear +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEIReceivedOneYear +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSReceivedOneYear +=  transaction.badgesCS;
              //Update per category stats for this period
              if (transaction.category ==="STRESS") {
                  analyticsArray [index].stressPeriodCoinsEarnedOneYear += transaction.amount;
                  analyticsArray [index].stressPeriodConectivitiesCompleteOneYear += 1;
              }
              if (transaction.category ==="SOCIAL") {
                  analyticsArray [index].socialPeriodCoinsEarnedOneYear += transaction.amount;
                  analyticsArray [index].socialPeriodConectivitiesCompleteOneYear += 1;
              }
              if (transaction.category ==="TEAM") {
                  analyticsArray [index].teamPeriodCoinsEarnedOneYear += transaction.amount;
                  analyticsArray [index].teamPeriodConectivitiesCompleteOneYear += 1;
              }
              if (transaction.category ==="PERSONAL") {
                  analyticsArray [index].individualPeriodCoinsEarnedOneYear += transaction.amount;
                  analyticsArray [index].individualPeriodConectivitiesCompleteOneYear += 1;
              }

          } else if (transaction.transactionType === "CC_REDEMPTION") {
              analyticsArray [index].purchasesOneYear +=  1;
              analyticsArray [index].coinsSpentOneYear +=  transaction.amount;
              if (transaction.badgesDEI) analyticsArray [index].badgesDEISpentOneYear +=  transaction.badgesDEI;
              if (transaction.badgesCS) analyticsArray [index].badgesCSSpentOneYear +=  transaction.badgesCS;
          } else if (transaction.transactionType == "SPOTLIGHT_SENT" || transaction.transactionType == "SPOTLIGHT_RECEIVED") {
 
                //Update Spotlight totals for this user
               	if (transaction.transactionType == "SPOTLIGHT_SENT") analyticsArray[index].spotlightsSentOneYear +=1;
                if (transaction.transactionType == "SPOTLIGHT_RECEIVED")  analyticsArray[index].spotlightsReceivedOneYear +=1;
              	analyticsArray[index].spotlightsCoinsEarnedOneYear +=1;
              	analyticsArray[index].spotlightsBadgesDEIEarnedOneYear +=1;
              	analyticsArray[index].spotlightsBadgesCSEarnedOneYear +=1;  
          }
      }
        
    }

  //Receive a user index and add that user's stats into the customer-wide stats
  
  function updateCustomerWideTimeSeriesData(userIndex) {
        //Stats for DAY graph
        customerWideAnalytics.conectivitiesDay += userNumbers[userIndex].conectivitiesDay;
        customerWideAnalytics.coinsReceivedDay += userNumbers[userIndex].coinsReceivedDay;
        customerWideAnalytics.badgesDEIReceivedDay += userNumbers[userIndex].badgesDEIReceivedDay;
        customerWideAnalytics.badgesCSReceivedDay += userNumbers[userIndex].badgesCSReceivedDay;
        customerWideAnalytics.purchasesDay += userNumbers[userIndex].purchasesDay;
        customerWideAnalytics.coinsSpentDay += userNumbers[userIndex].coinsSpentDay;
        customerWideAnalytics.badgesDEISpentDay += userNumbers[userIndex].badgesDEISpentDay;
        customerWideAnalytics.badgesCSSpentDay += userNumbers[userIndex].badgesCSSpentDay;
        customerWideAnalytics.socialPeriodCoinsEarnedDay += userNumbers[userIndex].socialPeriodCoinsEarnedDay;
      	customerWideAnalytics.socialPeriodConectivitiesCompleteDay += userNumbers[userIndex].socialPeriodConectivitiesCompleteDay;
      	customerWideAnalytics.stressPeriodCoinsEarnedDay += userNumbers[userIndex].stressPeriodCoinsEarnedDay;
      	customerWideAnalytics.stressPeriodConectivitiesCompleteDay += userNumbers[userIndex].stressPeriodConectivitiesCompleteDay;
      	customerWideAnalytics.teamPeriodCoinsEarnedDay += userNumbers[userIndex].teamPeriodCoinsEarnedDay;
      	customerWideAnalytics.teamPeriodConectivitiesCompleteDay += userNumbers[userIndex].teamPeriodConectivitiesCompleteDay;
      	customerWideAnalytics.individualPeriodCoinsEarnedDay += userNumbers[userIndex].individualPeriodCoinsEarnedDay;
      	customerWideAnalytics.individualPeriodConectivitiesCompleteDay += userNumbers[userIndex].individualPeriodConectivitiesCompleteDay;
     	  customerWideAnalytics.spotlightsSentDay += userNumbers[userIndex].spotlightsSentDay;
      	customerWideAnalytics.spotlightsReceivedDay += userNumbers[userIndex].spotlightsReceivedDay;
      	customerWideAnalytics.spotlightsCoinsEarnedDay += userNumbers[userIndex].spotlightsCoinsEarnedDay;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedDay += userNumbers[userIndex].spotlightsBadgesDEIEarnedDay;
      	customerWideAnalytics.spotlightsBadgesCSEarnedDay += userNumbers[userIndex].spotlightsBadgesCSEarnedDay;

        //Stats for WEEK graph
        customerWideAnalytics.conectivitiesWeek += userNumbers[userIndex].conectivitiesWeek;
        customerWideAnalytics.coinsReceivedWeek += userNumbers[userIndex].coinsReceivedWeek;
        customerWideAnalytics.badgesDEIReceivedWeek += userNumbers[userIndex].badgesDEIReceivedWeek;
        customerWideAnalytics.badgesCSReceivedWeek += userNumbers[userIndex].badgesCSReceivedWeek;
        customerWideAnalytics.purchasesWeek += userNumbers[userIndex].purchasesWeek;
        customerWideAnalytics.coinsSpentWeek += userNumbers[userIndex].coinsSpentWeek;
        customerWideAnalytics.badgesDEISpentWeek += userNumbers[userIndex].badgesDEISpentWeek;
        customerWideAnalytics.badgesCSSpentWeek += userNumbers[userIndex].badgesCSSpentWeek;
        customerWideAnalytics.socialPeriodCoinsEarnedWeek += userNumbers[userIndex].socialPeriodCoinsEarnedWeek;
        customerWideAnalytics.socialPeriodConectivitiesCompleteWeek += userNumbers[userIndex].socialPeriodConectivitiesCompleteWeek;
        customerWideAnalytics.stressPeriodCoinsEarnedWeek += userNumbers[userIndex].stressPeriodCoinsEarnedWeek;
        customerWideAnalytics.stressPeriodConectivitiesCompleteWeek += userNumbers[userIndex].stressPeriodConectivitiesCompleteWeek;
        customerWideAnalytics.teamPeriodCoinsEarnedWeek += userNumbers[userIndex].teamPeriodCoinsEarnedWeek;
        customerWideAnalytics.teamPeriodConectivitiesCompleteWeek += userNumbers[userIndex].teamPeriodConectivitiesCompleteWeek;
        customerWideAnalytics.individualPeriodCoinsEarnedWeek += userNumbers[userIndex].individualPeriodCoinsEarnedWeek;
        customerWideAnalytics.individualPeriodConectivitiesCompleteWeek += userNumbers[userIndex].individualPeriodConectivitiesCompleteWeek;
     	  customerWideAnalytics.spotlightsSentWeek += userNumbers[userIndex].spotlightsSentWeek;
      	customerWideAnalytics.spotlightsReceivedWeek += userNumbers[userIndex].spotlightsReceivedWeek;
      	customerWideAnalytics.spotlightsCoinsEarnedWeek += userNumbers[userIndex].spotlightsCoinsEarnedWeek;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedWeek += userNumbers[userIndex].spotlightsBadgesDEIEarnedWeek;
      	customerWideAnalytics.spotlightsBadgesCSEarnedWeek += userNumbers[userIndex].spotlightsBadgesCSEarnedWeek;
      	
        //Stats for Month graph
        customerWideAnalytics.conectivitiesMonth += userNumbers[userIndex].conectivitiesMonth;
        customerWideAnalytics.coinsReceivedMonth += userNumbers[userIndex].coinsReceivedMonth;
        customerWideAnalytics.badgesDEIReceivedMonth += userNumbers[userIndex].badgesDEIReceivedMonth;
        customerWideAnalytics.badgesCSReceivedMonth += userNumbers[userIndex].badgesCSReceivedMonth;
        customerWideAnalytics.purchasesMonth += userNumbers[userIndex].purchasesMonth;
        customerWideAnalytics.coinsSpentMonth += userNumbers[userIndex].coinsSpentMonth;
        customerWideAnalytics.badgesDEISpentMonth += userNumbers[userIndex].badgesDEISpentMonth;
        customerWideAnalytics.badgesCSSpentMonth += userNumbers[userIndex].badgesCSSpentMonth;
        customerWideAnalytics.socialPeriodCoinsEarnedMonth += userNumbers[userIndex].socialPeriodCoinsEarnedMonth;
        customerWideAnalytics.socialPeriodConectivitiesCompleteMonth += userNumbers[userIndex].socialPeriodConectivitiesCompleteMonth;
        customerWideAnalytics.stressPeriodCoinsEarnedMonth += userNumbers[userIndex].stressPeriodCoinsEarnedMonth;
        customerWideAnalytics.stressPeriodConectivitiesCompleteMonth += userNumbers[userIndex].stressPeriodConectivitiesCompleteMonth;
        customerWideAnalytics.teamPeriodCoinsEarnedMonth += userNumbers[userIndex].teamPeriodCoinsEarnedMonth;
        customerWideAnalytics.teamPeriodConectivitiesCompleteMonth += userNumbers[userIndex].teamPeriodConectivitiesCompleteMonth;
        customerWideAnalytics.individualPeriodCoinsEarnedMonth += userNumbers[userIndex].individualPeriodCoinsEarnedMonth;
        customerWideAnalytics.individualPeriodConectivitiesCompleteMonth += userNumbers[userIndex].individualPeriodConectivitiesCompleteMonth;
     	  customerWideAnalytics.spotlightsSentMonth += userNumbers[userIndex].spotlightsSentMonth;
      	customerWideAnalytics.spotlightsReceivedMonth += userNumbers[userIndex].spotlightsReceivedMonth;
      	customerWideAnalytics.spotlightsCoinsEarnedMonth += userNumbers[userIndex].spotlightsCoinsEarnedMonth;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedMonth += userNumbers[userIndex].spotlightsBadgesDEIEarnedMonth;
      	customerWideAnalytics.spotlightsBadgesCSEarnedMonth += userNumbers[userIndex].spotlightsBadgesCSEarnedMonth;
      	
        //Stats for Quarter graph
        customerWideAnalytics.conectivitiesQuarter += userNumbers[userIndex].conectivitiesQuarter;
        customerWideAnalytics.coinsReceivedQuarter += userNumbers[userIndex].coinsReceivedQuarter;
        customerWideAnalytics.badgesDEIReceivedQuarter += userNumbers[userIndex].badgesDEIReceivedQuarter;
        customerWideAnalytics.badgesCSReceivedQuarter += userNumbers[userIndex].badgesCSReceivedQuarter;
        customerWideAnalytics.purchasesQuarter += userNumbers[userIndex].purchasesQuarter;
        customerWideAnalytics.coinsSpentQuarter += userNumbers[userIndex].coinsSpentQuarter;
        customerWideAnalytics.badgesDEISpentQuarter += userNumbers[userIndex].badgesDEISpentQuarter;
        customerWideAnalytics.badgesCSSpentQuarter += userNumbers[userIndex].badgesCSSpentQuarter;
        customerWideAnalytics.socialPeriodCoinsEarnedQuarter += userNumbers[userIndex].socialPeriodCoinsEarnedQuarter;
        customerWideAnalytics.socialPeriodConectivitiesCompleteQuarter += userNumbers[userIndex].socialPeriodConectivitiesCompleteQuarter;
        customerWideAnalytics.stressPeriodCoinsEarnedQuarter += userNumbers[userIndex].stressPeriodCoinsEarnedQuarter;
        customerWideAnalytics.stressPeriodConectivitiesCompleteQuarter += userNumbers[userIndex].stressPeriodConectivitiesCompleteQuarter;
        customerWideAnalytics.teamPeriodCoinsEarnedQuarter += userNumbers[userIndex].teamPeriodCoinsEarnedQuarter;
        customerWideAnalytics.teamPeriodConectivitiesCompleteQuarter += userNumbers[userIndex].teamPeriodConectivitiesCompleteQuarter;
        customerWideAnalytics.individualPeriodCoinsEarnedQuarter += userNumbers[userIndex].individualPeriodCoinsEarnedQuarter;
        customerWideAnalytics.individualPeriodConectivitiesCompleteQuarter += userNumbers[userIndex].individualPeriodConectivitiesCompleteQuarter;
     	  customerWideAnalytics.spotlightsSentQuarter += userNumbers[userIndex].spotlightsSentQuarter;
      	customerWideAnalytics.spotlightsReceivedQuarter += userNumbers[userIndex].spotlightsReceivedQuarter;
      	customerWideAnalytics.spotlightsCoinsEarnedQuarter += userNumbers[userIndex].spotlightsCoinsEarnedQuarter;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedQuarter += userNumbers[userIndex].spotlightsBadgesDEIEarnedQuarter;
      	customerWideAnalytics.spotlightsBadgesCSEarnedQuarter += userNumbers[userIndex].spotlightsBadgesCSEarnedQuarter;
      	
        //Stats for YEAR graph
        customerWideAnalytics.conectivitiesYear += userNumbers[userIndex].conectivitiesYear;
        customerWideAnalytics.coinsReceivedYear += userNumbers[userIndex].coinsReceivedYear;
        customerWideAnalytics.badgesDEIReceivedYear += userNumbers[userIndex].badgesDEIReceivedYear;
        customerWideAnalytics.badgesCSReceivedYear += userNumbers[userIndex].badgesCSReceivedYear;
        customerWideAnalytics.purchasesYear += userNumbers[userIndex].purchasesYear;
        customerWideAnalytics.coinsSpentYear += userNumbers[userIndex].coinsSpentYear;
        customerWideAnalytics.badgesDEISpentYear += userNumbers[userIndex].badgesDEISpentYear;
        customerWideAnalytics.badgesCSSpentYear += userNumbers[userIndex].badgesCSSpentYear;
        customerWideAnalytics.socialPeriodCoinsEarnedYear += userNumbers[userIndex].socialPeriodCoinsEarnedYear;
        customerWideAnalytics.socialPeriodConectivitiesCompleteYear += userNumbers[userIndex].socialPeriodConectivitiesCompleteYear;
        customerWideAnalytics.stressPeriodCoinsEarnedYear += userNumbers[userIndex].stressPeriodCoinsEarnedYear;
        customerWideAnalytics.stressPeriodConectivitiesCompleteYear += userNumbers[userIndex].stressPeriodConectivitiesCompleteYear;
        customerWideAnalytics.teamPeriodCoinsEarnedYear += userNumbers[userIndex].teamPeriodCoinsEarnedYear;
        customerWideAnalytics.teamPeriodConectivitiesCompleteYear += userNumbers[userIndex].teamPeriodConectivitiesCompleteYear;
        customerWideAnalytics.individualPeriodCoinsEarnedYear += userNumbers[userIndex].individualPeriodCoinsEarnedYear;
        customerWideAnalytics.individualPeriodConectivitiesCompleteYear += userNumbers[userIndex].individualPeriodConectivitiesCompleteYear;       
     	  customerWideAnalytics.spotlightsSentYear += userNumbers[userIndex].spotlightsSentYear;
      	customerWideAnalytics.spotlightsReceivedYear += userNumbers[userIndex].spotlightsReceivedYear;
      	customerWideAnalytics.spotlightsCoinsEarnedYear += userNumbers[userIndex].spotlightsCoinsEarnedYear;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedYear += userNumbers[userIndex].spotlightsBadgesDEIEarnedYear;
      	customerWideAnalytics.spotlightsBadgesCSEarnedYear += userNumbers[userIndex].spotlightsBadgesCSEarnedYear;
      	
      	
         //Stats for 1W graph
        customerWideAnalytics.conectivitiesOneWeek += userNumbers[userIndex].conectivitiesOneWeek;
        customerWideAnalytics.coinsReceivedOneWeek += userNumbers[userIndex].coinsReceivedOneWeek;
        customerWideAnalytics.badgesDEIReceivedOneWeek += userNumbers[userIndex].badgesDEIReceivedOneWeek;
        customerWideAnalytics.badgesCSReceivedOneWeek += userNumbers[userIndex].badgesCSReceivedOneWeek;
        customerWideAnalytics.purchasesOneWeek += userNumbers[userIndex].purchasesOneWeek;
        customerWideAnalytics.coinsSpentOneWeek += userNumbers[userIndex].coinsSpentOneWeek;
        customerWideAnalytics.badgesDEISpentOneWeek += userNumbers[userIndex].badgesDEISpentOneWeek;
        customerWideAnalytics.badgesCSSpentOneWeek += userNumbers[userIndex].badgesCSSpentOneWeek;
        customerWideAnalytics.socialPeriodCoinsEarnedOneWeek += userNumbers[userIndex].socialPeriodCoinsEarnedOneWeek;
        customerWideAnalytics.socialPeriodConectivitiesCompleteOneWeek += userNumbers[userIndex].socialPeriodConectivitiesCompleteOneWeek;
        customerWideAnalytics.stressPeriodCoinsEarnedOneWeek += userNumbers[userIndex].stressPeriodCoinsEarnedOneWeek;
        customerWideAnalytics.stressPeriodConectivitiesCompleteOneWeek += userNumbers[userIndex].stressPeriodConectivitiesCompleteOneWeek;
        customerWideAnalytics.teamPeriodCoinsEarnedOneWeek += userNumbers[userIndex].teamPeriodCoinsEarnedOneWeek;
        customerWideAnalytics.teamPeriodConectivitiesCompleteOneWeek += userNumbers[userIndex].teamPeriodConectivitiesCompleteOneWeek;
        customerWideAnalytics.individualPeriodCoinsEarnedOneWeek += userNumbers[userIndex].individualPeriodCoinsEarnedOneWeek;
        customerWideAnalytics.individualPeriodConectivitiesCompleteOneWeek += userNumbers[userIndex].individualPeriodConectivitiesCompleteOneWeek;       
     	customerWideAnalytics.spotlightsSentOneWeek += userNumbers[userIndex].spotlightsSentOneWeek;
      	customerWideAnalytics.spotlightsReceivedOneWeek += userNumbers[userIndex].spotlightsReceivedOneWeek;
      	customerWideAnalytics.spotlightsCoinsEarnedOneWeek += userNumbers[userIndex].spotlightsCoinsEarnedOneWeek;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedOneWeek += userNumbers[userIndex].spotlightsBadgesDEIEarnedOneWeek;
      	customerWideAnalytics.spotlightsBadgesCSEarnedOneWeek += userNumbers[userIndex].spotlightsBadgesCSEarnedOneWeek;

        //Stats for 1M graph
        customerWideAnalytics.conectivitiesOneMonth += userNumbers[userIndex].conectivitiesOneMonth;
        customerWideAnalytics.coinsReceivedOneMonth += userNumbers[userIndex].coinsReceivedOneMonth;
        customerWideAnalytics.badgesDEIReceivedOneMonth += userNumbers[userIndex].badgesDEIReceivedOneMonth;
        customerWideAnalytics.badgesCSReceivedOneMonth += userNumbers[userIndex].badgesCSReceivedOneMonth;
        customerWideAnalytics.purchasesOneMonth += userNumbers[userIndex].purchasesOneMonth;
        customerWideAnalytics.coinsSpentOneMonth += userNumbers[userIndex].coinsSpentOneMonth;
        customerWideAnalytics.badgesDEISpentOneMonth += userNumbers[userIndex].badgesDEISpentOneMonth;
        customerWideAnalytics.badgesCSSpentOneMonth += userNumbers[userIndex].badgesCSSpentOneMonth;
        customerWideAnalytics.socialPeriodCoinsEarnedOneMonth += userNumbers[userIndex].socialPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.socialPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].socialPeriodConectivitiesCompleteOneMonth;
        customerWideAnalytics.stressPeriodCoinsEarnedOneMonth += userNumbers[userIndex].stressPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.stressPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].stressPeriodConectivitiesCompleteOneMonth;
        customerWideAnalytics.teamPeriodCoinsEarnedOneMonth += userNumbers[userIndex].teamPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.teamPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].teamPeriodConectivitiesCompleteOneMonth;
        customerWideAnalytics.individualPeriodCoinsEarnedOneMonth += userNumbers[userIndex].individualPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.individualPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].individualPeriodConectivitiesCompleteOneMonth;       
     	customerWideAnalytics.spotlightsSentOneMonth += userNumbers[userIndex].spotlightsSentOneMonth;
      	customerWideAnalytics.spotlightsReceivedOneMonth += userNumbers[userIndex].spotlightsReceivedOneMonth;
      	customerWideAnalytics.spotlightsCoinsEarnedOneMonth += userNumbers[userIndex].spotlightsCoinsEarnedOneMonth;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedOneMonth += userNumbers[userIndex].spotlightsBadgesDEIEarnedOneMonth;
      	customerWideAnalytics.spotlightsBadgesCSEarnedOneMonth += userNumbers[userIndex].spotlightsBadgesCSEarnedOneMonth;

        //Stats for 2M graph
        customerWideAnalytics.conectivitiesOneMonth += userNumbers[userIndex].conectivitiesOneMonth;
        customerWideAnalytics.coinsReceivedOneMonth += userNumbers[userIndex].coinsReceivedOneMonth;
        customerWideAnalytics.badgesDEIReceivedOneMonth += userNumbers[userIndex].badgesDEIReceivedOneMonth;
        customerWideAnalytics.badgesCSReceivedOneMonth += userNumbers[userIndex].badgesCSReceivedOneMonth;
        customerWideAnalytics.purchasesOneMonth += userNumbers[userIndex].purchasesOneMonth;
        customerWideAnalytics.coinsSpentOneMonth += userNumbers[userIndex].coinsSpentOneMonth;
        customerWideAnalytics.badgesDEISpentOneMonth += userNumbers[userIndex].badgesDEISpentOneMonth;
        customerWideAnalytics.badgesCSSpentOneMonth += userNumbers[userIndex].badgesCSSpentOneMonth;
        customerWideAnalytics.socialPeriodCoinsEarnedOneMonth += userNumbers[userIndex].socialPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.socialPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].socialPeriodConectivitiesCompleteOneMonth;
        customerWideAnalytics.stressPeriodCoinsEarnedOneMonth += userNumbers[userIndex].stressPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.stressPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].stressPeriodConectivitiesCompleteOneMonth;
        customerWideAnalytics.teamPeriodCoinsEarnedOneMonth += userNumbers[userIndex].teamPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.teamPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].teamPeriodConectivitiesCompleteOneMonth;
        customerWideAnalytics.individualPeriodCoinsEarnedOneMonth += userNumbers[userIndex].individualPeriodCoinsEarnedOneMonth;
        customerWideAnalytics.individualPeriodConectivitiesCompleteOneMonth += userNumbers[userIndex].individualPeriodConectivitiesCompleteOneMonth;       
     	customerWideAnalytics.spotlightsSentOneMonth += userNumbers[userIndex].spotlightsSentOneMonth;
      	customerWideAnalytics.spotlightsReceivedOneMonth += userNumbers[userIndex].spotlightsReceivedOneMonth;
      	customerWideAnalytics.spotlightsCoinsEarnedOneMonth += userNumbers[userIndex].spotlightsCoinsEarnedOneMonth;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedOneMonth += userNumbers[userIndex].spotlightsBadgesDEIEarnedOneMonth;
      	customerWideAnalytics.spotlightsBadgesCSEarnedOneMonth += userNumbers[userIndex].spotlightsBadgesCSEarnedOneMonth;

        //Stats for 1Y graph
        customerWideAnalytics.conectivitiesOneYear += userNumbers[userIndex].conectivitiesOneYear;
        customerWideAnalytics.coinsReceivedOneYear += userNumbers[userIndex].coinsReceivedOneYear;
        customerWideAnalytics.badgesDEIReceivedOneYear += userNumbers[userIndex].badgesDEIReceivedOneYear;
        customerWideAnalytics.badgesCSReceivedOneYear += userNumbers[userIndex].badgesCSReceivedOneYear;
        customerWideAnalytics.purchasesOneYear += userNumbers[userIndex].purchasesOneYear;
        customerWideAnalytics.coinsSpentOneYear += userNumbers[userIndex].coinsSpentOneYear;
        customerWideAnalytics.badgesDEISpentOneYear += userNumbers[userIndex].badgesDEISpentOneYear;
        customerWideAnalytics.badgesCSSpentOneYear += userNumbers[userIndex].badgesCSSpentOneYear;
        customerWideAnalytics.socialPeriodCoinsEarnedOneYear += userNumbers[userIndex].socialPeriodCoinsEarnedOneYear;
        customerWideAnalytics.socialPeriodConectivitiesCompleteOneYear += userNumbers[userIndex].socialPeriodConectivitiesCompleteOneYear;
        customerWideAnalytics.stressPeriodCoinsEarnedOneYear += userNumbers[userIndex].stressPeriodCoinsEarnedOneYear;
        customerWideAnalytics.stressPeriodConectivitiesCompleteOneYear += userNumbers[userIndex].stressPeriodConectivitiesCompleteOneYear;
        customerWideAnalytics.teamPeriodCoinsEarnedOneYear += userNumbers[userIndex].teamPeriodCoinsEarnedOneYear;
        customerWideAnalytics.teamPeriodConectivitiesCompleteOneYear += userNumbers[userIndex].teamPeriodConectivitiesCompleteOneYear;
        customerWideAnalytics.individualPeriodCoinsEarnedOneYear += userNumbers[userIndex].individualPeriodCoinsEarnedOneYear;
        customerWideAnalytics.individualPeriodConectivitiesCompleteOneYear += userNumbers[userIndex].individualPeriodConectivitiesCompleteOneYear;       
     	customerWideAnalytics.spotlightsSentOneYear += userNumbers[userIndex].spotlightsSentOneYear;
      	customerWideAnalytics.spotlightsReceivedOneYear += userNumbers[userIndex].spotlightsReceivedOneYear;
      	customerWideAnalytics.spotlightsCoinsEarnedOneYear += userNumbers[userIndex].spotlightsCoinsEarnedOneYear;
      	customerWideAnalytics.spotlightsBadgesDEIEarnedOneYear += userNumbers[userIndex].spotlightsBadgesDEIEarnedOneYear;
      	customerWideAnalytics.spotlightsBadgesCSEarnedOneYear += userNumbers[userIndex].spotlightsBadgesCSEarnedOneYear;
     	
      	
    }

    //For Time-series graphs, create or update the proper X-axis date entry  
    function updateTimeSeriesArrayElement (array, xKey, transaction, period) {

        var index = array.findIndex((entry => entry.xKey == xKey));
        
        // if (DEBUG_MODE >= 2) console.log("Input array", array);
        // if (DEBUG_MODE >= 2) console.log("Input transaction", transaction);

        
        if (index > -1) {    
        
            // if (DEBUG_MODE >= 2) console.log("Updating existing Time Series entry at index:", index);
            
            //Update entry at the index with new totals    
            array[index].conectivities +=1;
            array[index].amount += transaction.amount;
            array[index].badgesDEIReceived +=transaction.badgesDEI;
            array[index].badgesCSReceived +=transaction.badgesCS;

            //Update entry at the index with new totals for each category
            if (transaction.category == "SOCIAL") {
                array[index].socialCoinsEarned += transaction.amount;
                array[index].socialConectivities += 1;
            } else if (transaction.category == "PERSONAL") {
                array[index].individualCoinsEarned += transaction.amount;
                array[index].individualConectivities +=1;
            } else if (transaction.category == "TEAM") {
                array[index].teamCoinsEarned= transaction.amount;
                array[index].teamConectivities +=1;
            } else if (transaction.category == "STRESS") {
                array[index].stressCoinsEarned += transaction.amount;
                array[index].stressConectivities +=1;
            } else if (transaction.category == "TUTORIAL") {
                // if (DEBUG_MODE >= 2) console.log("CONECTIVITY TYPE = TUTORIAL.  SKIPPED", transaction);
            } else {
                if (DEBUG_MODE >= 2) console.log("ERROR - CONECTIVITY TYPE NOT FOUND DURING ANALYSIS", transaction);
            }
        } else {
            //  if (DEBUG_MODE >= 2) console.log("Creating new time series entry.");
             
             let newObject = {
                xKey: xKey,
                period: period,
                conectivities: 1,
                amount: transaction.amount,
                socialCoinsEarned: 0,
                socialConectivities: 0,
                teamCoinsEarned: 0,
                teamConectivities: 0,
                individualCoinsEarned: 0,
                individualConectivities: 0,
                stressCoinsEarned: 0,
                stressConectivities: 0,
                badgesDEIReceived: 0,
                badgesCSReceived: 0,
                 
             }; 
            
            if (transaction.category == "SOCIAL") {
                newObject.socialCoinsEarned = transaction.amount;
                newObject.socialConectivities = 1;
            } else if (transaction.category == "PERSONAL") {
                newObject.individualCoinsEarned= transaction.amount;
                newObject.individualConectivities=1;
            } else if (transaction.category == "TEAM") {
                newObject.teamCoinsEarned= transaction.amount;
                newObject.teamConectivities=1;
            } else if (transaction.category == "STRESS") {
                newObject.stressCoinsEarned= transaction.amount;
                newObject.stressConectivities=1;
            } else if (transaction.category == "TUTORIAL") {
                // if (DEBUG_MODE >= 2) console.log("CONECTIVITY TYPE = TUTORIAL.  SKIPPED", transaction);
            } else {
                if (DEBUG_MODE >= 2) console.log("ERROR - CONECTIVITY TYPE NOT FOUND DURING ANALYSIS", transaction);
            }

            //Create a new entry for the XKey    
            array.push(newObject);
        } 
    
    // if (DEBUG_MODE >= 2) console.log("Updated array", array);
    
    }
 
    function getXKey(dateTimeStamp, period) {
        
        // Timstamp format is "YYYY MM DDTHH mm ssZ"
    
        const transactionDate = moment(dateTimeStamp, "YYYY MM DDTHH mm ssZ");
        var periodStart ="";
        
        var returnString = "";
    
        if (period == "DAILY") {
            returnString  = transactionDate.format("D-MMM-YY");
                                            
        } else if (period == "WEEKLY") {
            periodStart = transactionDate.startOf('week');
            returnString  = periodStart.format("D-MMM-YY");

        } else if (period == "MONTHLY") {
            periodStart = transactionDate.startOf('month');
            returnString  = periodStart.format("MMM-YY");
    
        } else if (period == "QUARTERLY") {
            periodStart = transactionDate.startOf('quarter');
            returnString  = periodStart.format("MMM-YY");
            if (returnString[1] === "a") returnString = "Q1-" + returnString[4] + returnString[5]; 
            if (returnString[0] === "A") returnString = "Q2-" + returnString[4] + returnString[5]; 
            if (returnString[1] === "u") returnString = "Q3-" + returnString[4] + returnString[5]; 
            if (returnString[0] === "O") returnString = "Q4-" + returnString[4] + returnString[5]; 
    
        } else if (period == "YEARLY") {
            periodStart = transactionDate.startOf('year');
            returnString  = periodStart.format("YYYY");
        } else {

        }

    // console.log ("Generated XKey:", returnString, " for " , dateTimeStamp);
    
    return returnString;    
    } 


    function generateTimeSeriesData (customer, timeArrayByDay, timeArrayByWeek, timeArrayByMonth, timeArrayByQuarter, timeArrayByYear) {
     
    //  console.log ("Inputs to build User Graph Data", timeArrayByDay, timeArrayByDay.length);
    
    // Generate data for all the different graphs
    // Need to generate in the following format for graphing purposes    
    //   {
    //     name: 'J.Smith',
    //  conectivities: XX,
    //   },  
    
        //Temp variables for 5 different graphs; period is set to a year since that's the default duration for all the transactions
        var tempGraphDataDay = {
        	    customerID: customer.id,
                customer: customer.name,
                period:"DAY",
                periodStart: ONE_YEAR_AGO_STRING(),
                periodEnd: NOW_STRING(),
                values: []};
        
        
        var tempGraphDataWeek = {
        	      customerID: customer.id,
                customer: customer.name,
                period:"WEEK",
                periodStart: ONE_YEAR_AGO_STRING(),
                periodEnd: NOW_STRING(),
                values: []};
          
        var tempGraphDataMonth = {
        	      customerID: customer.id,
                customer: customer.name,
                 period:"MONTH",
                periodStart: ONE_YEAR_AGO_STRING(),
                periodEnd: NOW_STRING(),
                values: []};
                
        var tempGraphDataQuarter = {
        	      customerID: customer.id,
                customer: customer.name,
                period:"QUARTER",
                periodStart: ONE_YEAR_AGO_STRING(),
                periodEnd: NOW_STRING(),
                values: []};
                
        var tempGraphDataYear = {
        	      customerID: customer.id,
                customer: customer.name,
                period:"YEAR",
                periodStart: ONE_YEAR_AGO_STRING(),
                periodEnd: NOW_STRING(),
                values: []};
    
    
        //walk user data and generate graph data for all graphs using select fields
        for (var j=0; j<timeArrayByDay.length; j++) {
    
            tempGraphDataDay.values[j] ={
                name: timeArrayByDay[j].xKey,
                period: timeArrayByDay[j].period,
                conectivities: timeArrayByDay[j].conectivities,
                balance: timeArrayByDay[j].stressConectivities,
                social: timeArrayByDay[j].socialConectivities,
                team: timeArrayByDay[j].teamConectivities,
                personal: timeArrayByDay[j].individualConectivities,
                amount: timeArrayByDay[j].amount,
                socialCoinsEarned: timeArrayByDay[j].socialCoinsEarned,
                teamCoinsEarned: timeArrayByDay[j].teamCoinsEarned,
                individualCoinsEarned: timeArrayByDay[j].individualCoinsEarned,
                balanceCoinsEarned: timeArrayByDay[j].stressCoinsEarned,
                DEI_badges: timeArrayByDay[j].badgesDEIReceived,
                CS_badges: timeArrayByDay[j].badgesCSReceived,
    
            };
        }
    
        for (var j=0; j<timeArrayByWeek.length; j++) {
    
            tempGraphDataWeek.values[j] ={
                name: timeArrayByWeek[j].xKey,
                period: timeArrayByWeek[j].period,
                conectivities: timeArrayByWeek[j].conectivities,
                balance: timeArrayByWeek[j].stressConectivities,
                social: timeArrayByWeek[j].socialConectivities,
                team: timeArrayByWeek[j].teamConectivities,
                personal: timeArrayByWeek[j].individualConectivities,
                amount: timeArrayByWeek[j].amount,
                socialCoinsEarned: timeArrayByWeek[j].socialCoinsEarned,
                teamCoinsEarned: timeArrayByWeek[j].teamCoinsEarned,
                individualCoinsEarned: timeArrayByWeek[j].individualCoinsEarned,
                balanceCoinsEarned: timeArrayByWeek[j].stressCoinsEarned,
                DEI_badges: timeArrayByWeek[j].badgesDEIReceived,
                CS_badges: timeArrayByWeek[j].badgesCSReceived,
    
            };
        }
        for (var j=0; j<timeArrayByMonth.length; j++) {
    
            tempGraphDataMonth.values[j] ={
                name: timeArrayByMonth[j].xKey,
                period: timeArrayByMonth[j].period,
                conectivities: timeArrayByMonth[j].conectivities,
                balance: timeArrayByMonth[j].stressConectivities,
                social: timeArrayByMonth[j].socialConectivities,
                team: timeArrayByMonth[j].teamConectivities,
                personal: timeArrayByMonth[j].individualConectivities,
                amount: timeArrayByMonth[j].amount,
                socialCoinsEarned: timeArrayByMonth[j].socialCoinsEarned,
                teamCoinsEarned: timeArrayByMonth[j].teamCoinsEarned,
                individualCoinsEarned: timeArrayByMonth[j].individualCoinsEarned,
                balanceCoinsEarned: timeArrayByMonth[j].stressCoinsEarned,
                DEI_badges: timeArrayByMonth[j].badgesDEIReceived,
                CS_badges: timeArrayByMonth[j].badgesCSReceived,
    
            };
        }
        for (var j=0; j<timeArrayByQuarter.length; j++) {
    
            tempGraphDataQuarter.values[j] ={
                name: timeArrayByQuarter[j].xKey,
                period: timeArrayByQuarter[j].period,
                conectivities: timeArrayByQuarter[j].conectivities,
                balance: timeArrayByQuarter[j].stressConectivities,
                social: timeArrayByQuarter[j].socialConectivities,
                team: timeArrayByQuarter[j].teamConectivities,
                personal: timeArrayByQuarter[j].individualConectivities,
                amount: timeArrayByQuarter[j].amount,
                socialCoinsEarned: timeArrayByQuarter[j].socialCoinsEarned,
                teamCoinsEarned: timeArrayByQuarter[j].teamCoinsEarned,
                individualCoinsEarned: timeArrayByQuarter[j].individualCoinsEarned,
                balanceCoinsEarned: timeArrayByQuarter[j].stressCoinsEarned,
                DEI_badges: timeArrayByQuarter[j].badgesDEIReceived,
                CS_badges: timeArrayByQuarter[j].badgesCSReceived,
    
            };
        }
        for (var j=0; j<timeArrayByYear.length; j++) {
    
            tempGraphDataYear.values[j] ={
                name: timeArrayByYear[j].xKey,
                period: timeArrayByYear[j].period,
                conectivities: timeArrayByYear[j].conectivities,
                balance: timeArrayByYear[j].stressConectivities,
                social: timeArrayByYear[j].socialConectivities,
                team: timeArrayByYear[j].teamConectivities,
                personal: timeArrayByYear[j].individualConectivities,
                amount: timeArrayByYear[j].amount,
                socialCoinsEarned: timeArrayByYear[j].socialCoinsEarned,
                teamCoinsEarned: timeArrayByYear[j].teamCoinsEarned,
                individualCoinsEarned: timeArrayByYear[j].individualCoinsEarned,
                balanceCoinsEarned: timeArrayByYear[j].stressCoinsEarned,
                DEI_badges: timeArrayByYear[j].badgesDEIReceived,
                CS_badges: timeArrayByYear[j].badgesCSReceived,
            };
        }
        
    
        // console.log ("Built Time Series Graph Day", tempGraphDataDay);
        // console.log ("Built Time Series Graph Week", tempGraphDataWeek);
        // console.log ("Built Time Series Graph Month", tempGraphDataMonth);
        // console.log ("Built Time Series Graph Quarter", tempGraphDataQuarter);
        // console.log ("Built Time Series Graph Year", tempGraphDataYear);
    
        //Save graph data to state data
        timeSeriesGraphDataDay = tempGraphDataDay;
        timeSeriesGraphDataWeek = tempGraphDataWeek;
        timeSeriesGraphDataMonth = tempGraphDataMonth;
        timeSeriesGraphDataQuarter  = tempGraphDataQuarter;
        timeSeriesGraphDataYear = tempGraphDataYear;
    
    }
    
    
    //
    // NETWORK GRAPH HELPERS 
    //

    //Functions for sorting based on conectivity score 
    function compareConectivityScore(a, b) {
        
        if (a.relativeConectivityScore > b.relativeConectivityScore) return -1;
        if (a.relativeConectivityScore < b.relativeConectivityScore) return 1;
        
        return 0;
    }

    //
    // Graph Functions
    //
     function getNewID() {
        
        return uuidv4();//generate a UUID

    }
    

    //This function addes a particular user's node to EVERY graph  
    function buildUserNode(user) {

    //make an initial object for the user
      const tempNode  = 
      { 
        userID: user.id,
        customerID: user.customerID,
        userInitials:getUserInitials(user),  //Store for later use
        label: getUserInitials(user), 
        color: '#f26df0', 
        edgeCount: 0,
        //Set title which is used for hover
        title: user.firstName + (!user.middleName ? "" : " " + user.middleName.substr(0,1)) + " " + user.lastName, 
        shape:"ellipse",        //Default 
        borderWidth:1,
        image:"",
      };
  
    //Does this user have an avatar, i.e., a profile photo?
    //If a image, perhaps have a thick border with color RED/YELLOW/GREEN?
    if (user.avatarUrl && user.avatarUrl.length > 0) {
      tempNode.shape = 'circularImage';
      tempNode.image = user.avatarUrl;
      tempNode.borderWidth = 10;  //add border since this will show the user's conectivity color
      tempNode.label=""; //Remove label since it will show outside the photo

    } else {
      //TBD set shape to circle and include initials
      
    }

    //NEW - use A SINGLE master list of nodes for a company rather than NODE records for each graph
     customerNodeArray.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
    
    //Assign our OWN IDs instead of relying on APPSYNC because we need to use the IDs in the EDGEs and the 
    //NODES have not been created in the DB yet
    //Add a deep copy of the new node object into each graph ARRAY since we may adjust user node attributes seperately in each array
    //Probs didn't need a deep copy since not underlying objects but that's ok
    //Note, each will be written as a seperate record to the DB so each has a different ID

/*    
     tempGraphDataAllDay.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllTwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataAllYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
    
     tempGraphDataBalanceDay.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataBalanceWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));   
     tempGraphDataBalanceMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));  
     tempGraphDataBalanceOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataBalanceOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataBalanceTwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataBalanceQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataBalanceYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
    
     tempGraphDataSocialDay.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataSocialWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataSocialMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataSocialOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataSocialOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataSocialTwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataSocialQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));  
     tempGraphDataSocialYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()}))); 
    
     tempGraphDataTeamDay.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataTeamWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataTeamMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));   
     tempGraphDataTeamOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataTeamOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataTeamTwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataTeamQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));  
     tempGraphDataTeamYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()}))); 
    
     tempGraphDataPersonalDay.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataPersonalWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataPersonalMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));   
     tempGraphDataPersonalOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataPersonalOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataPersonalTwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataPersonalQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));    
     tempGraphDataPersonalYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));    
   
     tempGraphDataDEIDay .nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataDEIWeek .nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataDEIMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));   
     tempGraphDataDEIOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataDEIOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataDEITwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataDEIQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));    
     tempGraphDataDEIYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));    
     
     tempGraphDataCSDay.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataCSWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataCSMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));   
     tempGraphDataCSOneWeek.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataCSOneMonth.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataCSTwoMonths.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));
     tempGraphDataCSQuarter.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));    
     tempGraphDataCSYear.nodes.push(JSON.parse(JSON.stringify({...tempNode, id:getNewID()})));  
*/

     //Output for testing
    //  if (DEBUG_MODE >= 2) console.log("Graph data all year", tempGraphDataAllMonth);
    }


  function addEdges (transaction, fromUserID, toUserID) {

     const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
     const now = moment();
     
     //Skip transactions in the FUTURE, as in our DEMO data
     if (transactionDate.isAfter(now)) return;

    //   if (DEBUG_MODE >= 2) console.log('Function: Adding edge from ', fromUserID, " to ", toUserID, transaction);                   


      //Update TODAY counts
      if (transactionDate.isAfter(dayStart)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllDay);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceDay);

            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialDay);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamDay);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalDay);
            }
            
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSDay);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIDay);
            }              
              
      }  

      //Update WEEK counts
      if (transactionDate.isAfter(weekStart)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllWeek);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceWeek);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialWeek);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamWeek);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalWeek);
            }
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSWeek);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIWeek);
            }              
              
     }  
      
      //Update MONTH counts
      if (transactionDate.isAfter(monthStart)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllMonth);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceMonth);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialMonth);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamMonth);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalMonth);
            }
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSMonth);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIMonth);
            }              
      }        

      //Update ONE WEEK counts
      if (transactionDate.isAfter(oneWeekAgo)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllOneWeek);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceOneWeek);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialOneWeek);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamOneWeek);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalOneWeek);
            }
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSOneWeek);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIOneWeek);
            }              
              
     }  
     

     //Update ONE Month (1M) counts
      if (transactionDate.isAfter(oneMonthAgo)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllOneMonth);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceOneMonth);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialOneMonth);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamOneMonth);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalOneMonth);
            }
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSOneMonth);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIOneMonth);
            }              
     }  
     
      //Update TWO Month (2M) counts
      if (transactionDate.isAfter(twoMonthsAgo)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllTwoMonths);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceTwoMonths);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialTwoMonths);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamTwoMonths);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalTwoMonths);
            }
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSTwoMonths);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEITwoMonths);
            }              
     }  
     
      //Update QUARTER counts
      if (transactionDate.isAfter(quarterStart)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllQuarter);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceQuarter);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialQuarter);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamQuarter);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalQuarter);
            }
             if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSQuarter);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIQuarter);
            }              
      }  
      
      //Update YEAR counts
      if (transactionDate.isAfter(yearStart)) {
        
            addEdge(transaction, fromUserID, toUserID, tempGraphDataAllYear);

            //Update per category stats for this period
            if (transaction.category ==="STRESS") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataBalanceYear);
            }
            if (transaction.category ==="SOCIAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataSocialYear);
            }
            if (transaction.category ==="TEAM") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataTeamYear);
            }
            if (transaction.category ==="PERSONAL") {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataPersonalYear);
            }
            if (transaction.badgesCS > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataCSYear);
            }
            
            if (transaction.badgesDEI > 0) {
              addEdge(transaction, fromUserID, toUserID, tempGraphDataDEIYear);
            }              
      }  
      
      //Finally, add an edge to the network graph covering the entire analytics period, whatever it is
      addEdge(transaction, fromUserID, toUserID, tempGraphDataAllEntirePeriod);

  }

  //Process the network graphs to remove duplicate edges so only a single edge between pairs of nodes
  export function reduceGraphEdges (graph) {

    try {
        if (!graph.edges || !customerNodeArray) return;
        if (graph.edges.length < 2 || customerNodeArray.length < 2) return;
       
        // if (DEBUG_MODE >= 2) console.log("Eliminating redundant edges", "NUM edges="+graph.edges.length, "NUM nodes="+customerNodeArray.length, graph.edges);
        
        for (var n1=0; n1 < customerNodeArray.length; n1++) {
            for (var n2=0; n2 < customerNodeArray.length; n2++) {
                
                //Make sure we are not looking at the same node
                if (n1 != n2) {
                    const node1 = customerNodeArray[n1].id;
                    const node2 = customerNodeArray[n2].id;
    
                    const indexEdge1 = graph.edges.findIndex( edge => (edge.from == node1 && edge.to == node2 ));   //Is there a first edge?
                    const indexEdge2 = graph.edges.findIndex( edge => (edge.from == node2 && edge.to == node1 ));   //Is there a second edge?
                    
                    //if two edges, delete the one with the lower weight
                    if (indexEdge1 > -1 && indexEdge2 > -1) {             //Valid
                         var edge1value = parseInt(graph.edges[indexEdge1].label, 10);
                         var edge2value = parseInt(graph.edges[indexEdge2].label, 10);
                   
                        //Both valid values?
                        if (edge1value && edge2value) {         
                            if (edge1value > edge2value) {  
                                graph.edges.splice(indexEdge2, 1);  //Remove the edge
                            } else {  
                                 graph.edges.splice(indexEdge1, 1);  //Remove the edge since it is less than or equal to the other
                               
                            }
    
                        }
    
                    }
                    
                    
                }
                
            }
        }
    } catch (err) {
        if (DEBUG_MODE >= 2) console.log("Error eliminating redundancies", err);
    }

    // if (DEBUG_MODE >= 2) console.log("Processed graph to elimate redundant edges", "NUM edges="+graph.edges.length, "NUM nodes="+customerNodeArray.length, graph.edges);
 
  }
  
  //This function updates the edge count in the corresponding FROM node
  function bumpEdgeCount (nodeID, graphDataArray, edgeWeight) {
      
    // if (DEBUG_MODE >= 2) console.log("Bump Edge Count called", edgeWeight, nodeID, graphDataArray);
    
    const index = graphDataArray.nodes.findIndex (node => (node.id == nodeID));

    if (index !== -1) {

        
        graphDataArray.nodes[index].edgeCount = graphDataArray.nodes[index].edgeCount + edgeWeight; //Bump edge count for user by edge weight
        
        if (graphDataArray.period=="DAY" && graphDataArray.nodes[index].userID == "6ee06830-c6b8-4575-a8c8-fe776b3a8256" ) if (DEBUG_MODE >= 2) console.log("New edge count for this user node", nodeID, graphDataArray.nodes[index].edgeCount);
        
    //   if (DEBUG_MODE >= 2) console.log("GRAPH:", graphDataArray.period, " NODE:", nodeID, " Edge count:", graphDataArray.nodes[index].edgeCount);


        //Update the max edge count for the company for the particular ARRAY
        if (graphDataArray.nodes[index].edgeCount > graphDataArray.maxEdgeCount) {
           graphDataArray.maxEdgeCount = graphDataArray.nodes[index].edgeCount;
        }

        // //Update the min edge count for the company for the particular ARRAY
        // if (graphDataArray.nodes[index].edgeCount < graphDataArray.minEdgeCount) {
        //   graphDataArray.minEdgeCount = graphDataArray.nodes[index].edgeCount;
        // }

    }    
    
  }
 
 export function setNodeAndEdgeAttributes (graphDataArray) {
     

   //Walk the array of edges for this finalized graph, which was generated have incoming and outgoing edges on each node 
    for (var y=0; y<graphDataArray.edges.length; y++) {

        //For the edge, get both the FROM node and the TO node since we need to count the connection for both users
        const fromID = graphDataArray.edges[y].from;
        const toID = graphDataArray.edges[y].to;

        var edge1value = 0; 
        if (graphDataArray.edges[y].label != "") edge1value = parseInt(graphDataArray.edges[y].label, 10);

        
        //Updates the connection count for both the FROM node and the TO node of the edge
        bumpEdgeCount (fromID, graphDataArray, edge1value);        
        bumpEdgeCount (toID, graphDataArray, edge1value);        
     }

     //Set Node color based on the max connectivity for any given user node in the graph
    for (var y=0; y<graphDataArray.nodes.length; y++) {
        setNodeColor(graphDataArray.maxEdgeCount, graphDataArray.nodes[y]);
     }

 }           

function hasScheduledConectivityAlreadyAddedThisEdge (transaction, fromUserID, toUserID, graphDataArray) {

    //Return whether or any record exists indicating that this scheduled conectivity has already being recorded (counted) for these two nodes in this graph  
    
    return (edgesAlreadyAddedForScheduledConectivities.some((record) => {
            
            //Our inner function for testing the required conditions of the record
            return (record.scheduledConectivityID == transaction.scheduledConectivityID &&
                    record.graphDataArrayID == graphDataArray.id &&
                    ((record.fromUserID == fromUserID && record.toUserID == toUserID) || (record.fromUserID == toUserID && record.toUserID == fromUserID))
            );
    }));

    
}
//
//  ADD EDGE 2.0 - BUILD GRAPH WITH ONLY A SINGLE EDGE PER NOE
//

  function addEdge (transaction, fromUserID, toUserID, graphDataArray) {
      
    
    if (fromUserID == null || fromUserID==undefined || toUserID == null || toUserID==undefined) {
            if (DEBUG_MODE >= 2) console.log("ERROR - Improper Edge.  Add Edge skipped", transaction, fromUserID, toUserID);
            return;
    }

    //Find nodes in master array for this customer based on the fromUserID and the toUserID
    const fromUserNode = customerNodeArray.find (node => node.userID == fromUserID);
    const toUserNode = customerNodeArray.find (node => node.userID == toUserID);
    
    if (fromUserNode == undefined || toUserNode == undefined) {
            // if (DEBUG_MODE >= 2) console.log("FROM/TO Nodes not found in graph.  User no longer exists.  Add Edge skipped", transaction, fromUserID, toUserID);
            return;      
    }

    //Threshold check, have we already counted a connected between FROM and TO for this particular scheduled conectivity?  If so, skip the transaction
    if (transaction.scheduledConectivityID && transaction.transactionType=="COMPLETED_CONECTIVITY") {

        if (!hasScheduledConectivityAlreadyAddedThisEdge(transaction, fromUserID, toUserID, graphDataArray)) {
            
            edgesAlreadyAddedForScheduledConectivities.push(
                {
                    graphDataArrayID: graphDataArray.id,
                    scheduledConectivityID: transaction.scheduledConectivityID,
                    fromUserID:fromUserID,
                    toUserID:toUserID,
                });
 
            /*
            if (DEBUG_MODE >= 2) console.log("New connection; Adding conectivity transaction to our scratchpad", 
                        edgesAlreadyAddedForScheduledConectivities,
                        graphDataArray.id,
                        transaction.scheduledConectivityID,
                        fromUserID,
                        toUserID,
                );
            */
         
            
        } else {

            /*
            if (DEBUG_MODE >= 2) console.log("Skipping transaction.  Scheduled Conectivity already added this edge for this graph", 
                    edgesAlreadyAddedForScheduledConectivities,
                    graphDataArray.id,
                    transaction.scheduledConectivityID,
                    fromUserID,
                    toUserID,
                );
            */
            
            return; //Jump out of the function, thus skipping the transaction without bumping our edge count
        }

    }
    
    //Check whether we already have an edge from A to B OR an edge from B to A in this particular array, if so update just the count and keep existing edge 
    //Else, if NO edge between the nodes then add a new edge to the array
    //Note, in each edge, the FROM and TO are equal to the NODE IDs not the user IDs
    
    //Existing EDGE FROM->TO and/or TO->FROM
    const indexFromToEdge = graphDataArray.edges.findIndex( entry => (entry.from == fromUserNode.id && entry.to == toUserNode.id ));
    const indexToFromEdge = graphDataArray.edges.findIndex( entry => (entry.from == toUserNode.id && entry.to == fromUserNode.id ));

    let newCount;
    //Existing edge FROM->TO?  If so, bump it
    if (indexFromToEdge > -1 ) {

        newCount = parseInt(graphDataArray.edges[indexFromToEdge].label, 10) + 1;
      
        graphDataArray.edges[indexFromToEdge].label = newCount.toString(); 
        graphDataArray.edges[indexFromToEdge].value = newCount.toString();

        // if (DEBUG_MODE >= 2) console.log("GRAPH " + graphDataArray.id + " " + graphDataArray.period + " Updated existing edge "  +  fromUserID + " => " + toUserID, graphDataArray.edges[indexFromToEdge], transaction, fromUserID, toUserID,);
        
    } else if (indexToFromEdge > -1 ) {
        // if (DEBUG_MODE >= 2) console.log("Updating edge count for existing TO->FROM", fromUserNode, toUserNode);
        newCount = parseInt(graphDataArray.edges[indexToFromEdge].label, 10) + 1;
      
        graphDataArray.edges[indexToFromEdge].label = newCount.toString(); 
        graphDataArray.edges[indexToFromEdge].value = newCount.toString();

        // if (DEBUG_MODE >= 2) console.log("GRAPH " + graphDataArray.id + " " + graphDataArray.period + " Updated edge  "  +  fromUserID + " => " + toUserID, graphDataArray.edges[indexToFromEdge], transaction, fromUserID, toUserID,);
          
    } else {
    
      //Create a new edge; this will serve as the single edge between the two nodes
      graphDataArray.edges.push({ 
        from: fromUserNode.id,  //Add FROM Node id
        to:  toUserNode.id,     //Add TO Node id
        label: "1", 
        value: "1", 
        // penwidth: 2,
        // add font via post-processing on client-side since this field is itself an object; could store in DB as a JSON string
        // font: { 
        //     align: "horizontal" 
        //   }
        }
      );
      
        // if (DEBUG_MODE >= 2) console.log("GRAPH " + graphDataArray.id + " " + graphDataArray.period + ": Added new edge " +  fromUserID + " => " + toUserID, graphDataArray, transaction);
        
    }

    // if (DEBUG_MODE >= 2) console.log("processed edge", graphDataArray);    
  }
  
/* 
  function addEdge (transaction, fromUserID, toUserID, graphDataArray) {
      
    
    if (fromUserID == null || fromUserID==undefined || toUserID == null || toUserID==undefined) {
            if (DEBUG_MODE >= 2) console.log("ERROR - Improper Edge.  Add Edge skipped", transaction, fromUserID, toUserID);
            return;
    }

    //Find nodes in master array for this customer based on the fromUserID and the toUserID
    const fromUserNode = customerNodeArray.find (node => node.userID == fromUserID);
    const toUserNode = customerNodeArray.find (node => node.userID == toUserID);
    
    if (fromUserNode == undefined || toUserNode == undefined) {
            // if (DEBUG_MODE >= 2) console.log("FROM/TO Nodes not found in graph.  User no longer exists.  Add Edge skipped", transaction, fromUserID, toUserID);
            return;      
    }
    
    
    //Check whether we already have an edge from A to B in this particular array, if so update just the count and keep existing edge data
    //Else add the new edge to the array
    //Note, in each edge, the FROM and TO are equal to the NODE IDs not the user IDs
    const index = graphDataArray.edges.findIndex( entry => (entry.from == fromUserNode.id && entry.to == toUserNode.id ));
    
    if (index > -1) {
    //   if (DEBUG_MODE >= 2) console.log("Updating edge count");
      var newCount = parseInt(graphDataArray.edges[index].label, 10) + 1;
      
        graphDataArray.edges[index].label = newCount.toString(); 
        graphDataArray.edges[index].value = newCount.toString();

        // if (DEBUG_MODE >= 2) console.log("Updated edge", graphDataArray.edges[index], transaction, fromUserID, toUserID,);
    }
    else {
    
      graphDataArray.edges.push({ 
        from: fromUserNode.id,  //Add FROM Node id
        to:  toUserNode.id,     //Add TO Node id
        label: "1", 
        value: "1", 
        // penwidth: 2,
        // add font via post-processing on client-side since this field is itself an object; could store as a JSON string
        // font: { 
        //     align: "horizontal" 
        //   }
        }
      );
      
        // if (DEBUG_MODE >= 2) console.log("Added new edge", graphDataArray, transaction, fromUserID, toUserID,);

    }

    // if (DEBUG_MODE >= 2) console.log("processed edge", graphDataArray);    
  }
  
*/  
  
    //Set the node color based on edge count of node relative to max edge count for the particular graph array
    // See http://www.graphviz.org/doc/info/colors.html
    
    function setNodeColor (maxEdgeCount, node) {

      // if (DEBUG_MODE >= 2) console.log("setting color based on ", maxEdgeCount, node.edgeCount);

      
      var userRelativeRank = 0; //default percent
      
      if (maxEdgeCount > 0)  {
          userRelativeRank = ( 100 * node.edgeCount / maxEdgeCount);
      }
  
      if (userRelativeRank < 25) {          //Less than 25% of user with max connections for this graph
        node.color = '#f0676e';   
      } else if (userRelativeRank < 50) {   //Less than 50% of user with max connections for this graph
        node.color = '#f5ae20';   
      } else if (userRelativeRank < 75) {   //Less than 75% of user with max connections for this graph
        node.color = '#f5df3d';   
      } else {                              //More than 75% of user with max connections for this graph
        node.color = '#92ed61';   
      }  
    }

//
// User Progress Data - ground truth update based on analytics just generated
//
   //Function to return a new end date in AWS datetime format for a given period type
   //Used when a period has ended and the user is now in a new period and our backend data needs to be updated
   //Note, the endOf timestamps will be the last day of the period, shortly before 5:00 AM GMT (thus before midnight ET)
   
    // function getCurrentEndDate (period) {
    
    //     switch (period) {
    //         case "DAILY":
    //             return endOfDay.toISOString();
    //         case "WEEKLY":
    //             return endOfWeek.toISOString();
    //       case "MONTHLY":
    //             return endOfMonth.toISOString();
    //       case "QUARTERLY":
    //             return endOfQuarter.toISOString();
    //       case "YEARLY":
    //             return endOfYear.toISOString();
    //         default:
    //             return endOfDay.toISOString();
    //     }
    // }
    
    function grabRingAnalytics(userAnalyticsRecord, period, category) {

        var statsObject = {};
        
        switch (category) {
            case "SOCIAL":
                switch (period) {
                    case "DAILY":
                        statsObject.periodStart = dayStart.toISOString();
                        statsObject.periodEnd = endOfDay.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.socialPeriodCoinsEarnedDay;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.socialPeriodConectivitiesCompleteDay;
                        break;
                    case "WEEKLY":
                        statsObject.periodStart = weekStart.toISOString();
                        statsObject.periodEnd = endOfWeek.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.socialPeriodCoinsEarnedWeek;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.socialPeriodConectivitiesCompleteWeek;
                        break;
                    case "MONTHLY":
                        statsObject.periodStart = monthStart.toISOString();
                        statsObject.periodEnd = endOfMonth.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.socialPeriodCoinsEarnedMonth;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.socialPeriodConectivitiesCompleteMonth;
                        break;
                   case "QUARTERLY":
                        statsObject.periodStart = quarterStart.toISOString();
                        statsObject.periodEnd = endOfQuarter.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.socialPeriodCoinsEarnedQuarter;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.socialPeriodConectivitiesCompleteQuarter;
                        break;
                   case "YEARLY":
                        statsObject.periodStart = yearStart.toISOString();
                        statsObject.periodEnd = endOfYear.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.socialPeriodCoinsEarnedYear;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.socialPeriodConectivitiesCompleteYear;
                        break;
                   default:
                      if (DEBUG_MODE >= 2) console.log("Error - no matching period", userAnalyticsRecord, period, category);
                }
                break;
                
            case "TEAM":
               switch (period) {
                    case "DAILY":
                        statsObject.periodStart = dayStart.toISOString();
                        statsObject.periodEnd = endOfDay.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.teamPeriodCoinsEarnedDay;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.teamPeriodConectivitiesCompleteDay;
                        break;
                    case "WEEKLY":
                        statsObject.periodStart = weekStart.toISOString();
                        statsObject.periodEnd = endOfWeek.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.teamPeriodCoinsEarnedWeek;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.teamPeriodConectivitiesCompleteWeek;
                        break;
                    case "MONTHLY":
                        statsObject.periodStart = monthStart.toISOString();
                        statsObject.periodEnd = endOfMonth.toISOString();
                       statsObject.periodCoinsEarned = userAnalyticsRecord.teamPeriodCoinsEarnedMonth;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.teamPeriodConectivitiesCompleteMonth;
                        break;
                   case "QUARTERLY":
                        statsObject.periodStart = quarterStart.toISOString();
                        statsObject.periodEnd = endOfQuarter.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.teamPeriodCoinsEarnedQuarter;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.teamPeriodConectivitiesCompleteQuarter;
                        break;
                   case "YEARLY":
                        statsObject.periodStart = yearStart.toISOString();
                        statsObject.periodEnd = endOfYear.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.teamPeriodCoinsEarnedYear;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.teamPeriodConectivitiesCompleteYear;
                        break;
                   default:
                      if (DEBUG_MODE >= 2) console.log("Error - no matching period", userAnalyticsRecord, period, category);
                }
                break;
             
            case "BALANCE":
               switch (period) {
                    case "DAILY":
                        statsObject.periodStart = dayStart.toISOString();
                        statsObject.periodEnd = endOfDay.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.stressPeriodCoinsEarnedDay;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.stressPeriodConectivitiesCompleteDay;
                        break;
                    case "WEEKLY":
                        statsObject.periodStart = weekStart.toISOString();
                        statsObject.periodEnd = endOfWeek.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.stressPeriodCoinsEarnedWeek;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.stressPeriodConectivitiesCompleteWeek;
                        break;
                    case "MONTHLY":
                        statsObject.periodStart = monthStart.toISOString();
                        statsObject.periodEnd = endOfMonth.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.stressPeriodCoinsEarnedMonth;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.stressPeriodConectivitiesCompleteMonth;
                        break;
                   case "QUARTERLY":
                         statsObject.periodStart = quarterStart.toISOString();
                        statsObject.periodEnd = endOfQuarter.toISOString();
                       statsObject.periodCoinsEarned = userAnalyticsRecord.stressPeriodCoinsEarnedQuarter;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.stressPeriodConectivitiesCompleteQuarter;
                        break;
                   case "YEARLY":
                         statsObject.periodStart = yearStart.toISOString();
                        statsObject.periodEnd = endOfYear.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.stressPeriodCoinsEarnedYear;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.stressPeriodConectivitiesCompleteYear;
                        break;
                   default:
                      if (DEBUG_MODE >= 2) console.log("Error - no matching period", userAnalyticsRecord, period, category);
                }
                break;
             
            case "GROWTH":
               switch (period) {
                    case "DAILY":
                        statsObject.periodStart = dayStart.toISOString();
                        statsObject.periodEnd = endOfDay.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.individualPeriodCoinsEarnedDay;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.individualPeriodConectivitiesCompleteDay;
                        break;
                    case "WEEKLY":
                        statsObject.periodStart = weekStart.toISOString();
                        statsObject.periodEnd = endOfWeek.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.individualPeriodCoinsEarnedWeek;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.individualPeriodConectivitiesCompleteWeek;
                        break;
                    case "MONTHLY":
                        statsObject.periodStart = monthStart.toISOString();
                        statsObject.periodEnd = endOfMonth.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.individualPeriodCoinsEarnedMonth;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.individualPeriodConectivitiesCompleteMonth;
                        break;
                   case "QUARTERLY":
                         statsObject.periodStart = quarterStart.toISOString();
                        statsObject.periodEnd = endOfQuarter.toISOString();
                       statsObject.periodCoinsEarned = userAnalyticsRecord.individualPeriodCoinsEarnedQuarter;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.individualPeriodConectivitiesCompleteQuarter;
                        break;
                   case "YEARLY":
                        statsObject.periodStart = yearStart.toISOString();
                        statsObject.periodEnd = endOfYear.toISOString();
                        statsObject.periodCoinsEarned = userAnalyticsRecord.individualPeriodCoinsEarnedYear;
                        statsObject.periodConectivitiesComplete = userAnalyticsRecord.individualPeriodConectivitiesCompleteYear;
                        break;
                   default:
                      if (DEBUG_MODE >= 2) console.log("Error - no matching period", userAnalyticsRecord, period, category);
                }
                break;
            default:
              if (DEBUG_MODE >= 2) console.log("Error - no matching category", userAnalyticsRecord, period, category);
          }

        // if (DEBUG_MODE >= 2) console.log("Grabbed analytics for progress data", statsObject, userAnalyticsRecord, period, category);
                
        return statsObject;
        
    }    

   	//Reset any existing streak if necessary when a RING has expired
   	//Does the user's last completed conectivity for this category fall within the most recently-ended period?

     function userBrokeStreak (streakLastClosureDate, period, streakStartDate) {

        const streakLastClosureDateMoment = moment(streakLastClosureDate, "YYYY-MM-DDTHH:mm:ssZ");
        const streakStartDateMoment = moment(streakStartDate, "YYYY-MM-DDTHH:mm:ssZ");

        //Determine START and END of the immediately PRIOR period that is FINISHED
        //Note, we are shifting 5 hours below so the start and end of days generally line up with ET
        var endOfPriorRingPeriod = null;
        var startOfPriorRingPeriod = null;
        
        if (period == "DAILY") {
             //Need to set prior period to yesterday or last Friday if
             var daysToSubtract = 1;

             const dayNumber = moment().day(); //0 for Sunday, 1 for Monday, etc.
            
             if (dayNumber == 0 ) daysToSubtract = 2;
             if (dayNumber == 1) daysToSubtract = 3; 
            
             startOfPriorRingPeriod = moment().subtract(daysToSubtract, 'days').subtract(5, 'hours').startOf('day');
             endOfPriorRingPeriod = moment().subtract(daysToSubtract, 'days').subtract(5, 'hours').endOf('day');
             
        } else if (period == "WEEKLY") {
             startOfPriorRingPeriod = moment().subtract(1, 'weeks').subtract(5, 'hours').startOf('week');
             endOfPriorRingPeriod = moment().subtract(1, 'weeks').subtract(5, 'hours').endOf('week');
 
        } else if (period == "MONTHLY") {
            startOfPriorRingPeriod = moment().subtract(1, 'months').subtract(5, 'hours').startOf('month');
             endOfPriorRingPeriod = moment().subtract(1, 'months').subtract(5, 'hours').endOf('month');
        } else if (period == "QUARTERLY") {
              startOfPriorRingPeriod = moment().subtract(1, 'quarters').subtract(5, 'hours').startOf('quarter');
             endOfPriorRingPeriod = moment().subtract(1, 'quarters').subtract(5, 'hours').endOf('quarter');
 
        } else if (period == "YEARLY") {
             startOfPriorRingPeriod = moment().subtract(1, 'years').subtract(5, 'hours').startOf('year');
             endOfPriorRingPeriod = moment().subtract(1, 'years').subtract(5, 'hours').endOf('year');
         } else {
            console.log ("Error - incorrect period.  Cannot determine whether user broke streak", streakLastClosureDate, period, streakStartDate);
            return false;
        }


        if (endOfPriorRingPeriod !== null && startOfPriorRingPeriod !== null) {
            // if (DEBUG_MODE >= 2) console.log("checking whether" + period + " streak has been broken:", streakLastClosureDate,  streakStartDate, startOfPriorRingPeriod.format("YYYY-MM-DDTHH:mm:ssZ"), endOfPriorRingPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));   

            //Did the current streak start AFTER the prior period OR did the user close the PRIOR period?  
            //If so, current streak not broken 
            if (streakStartDateMoment.isAfter(endOfPriorRingPeriod)) {
                // if (DEBUG_MODE >= 2) console.log("Streak not broken.  New existing streak started after PRIOR period end"); 
                  return false;             
              } else if (streakLastClosureDateMoment.isAfter(startOfPriorRingPeriod)  && streakLastClosureDateMoment.isBefore(endOfPriorRingPeriod)) {
                // if (DEBUG_MODE >= 2) console.log("Streak not broken.  User closed previous RING period"); 
                return false;
            } else {
              // if (DEBUG_MODE >= 2) console.log(period + "Streak broken.  No streak in current period and no closure in the prior period");  
              return true;
            }
        } else {
            if (DEBUG_MODE >= 2) console.log("Error updating userProgressDataRecords - start and/or end of ring period somehow not set", startOfPriorRingPeriod, endOfPriorRingPeriod);
            return false;
        }
    
    }
    
    export async function recalcUserProgressData ({userProgressDataRecords, users}) {
        
        if (DEBUG_MODE >= 2) console.log("Processing user progress data", userProgressDataRecords, userNumbers);

     	//Process each User Progress data record:
        for (var i=0; i < userProgressDataRecords.length; i++) {
            
            const user = users.find(user => user.id == userProgressDataRecords[i].userID);
            const userAnalyticsRecord = userNumbers.find(userRecord => userRecord.userID == userProgressDataRecords[i].userID);
            
            if (user != undefined && userAnalyticsRecord != undefined) {
                
                
                var tempProgressData = {...userProgressDataRecords[i]};     //Initialize a record
                
                //Remove these attributes so we can do a graphql update using the entire object
                delete tempProgressData.createdAt;
                delete tempProgressData.updatedAt;
                
                if (DEBUG_MODE >= 2) console.log("Processing user progress data", tempProgressData, userAnalyticsRecord);

                //Using the analytics just calculated, update current progress STATS USE IN LEADERBOARD 
                tempProgressData.dayClosingDateTime = endOfDay.toISOString();
                tempProgressData.dayCoinsEarned = userAnalyticsRecord.coinsReceivedDay;
                tempProgressData.dayConectivitiesComplete = userAnalyticsRecord.conectivitiesDay;
                tempProgressData.weekClosingDateTime = endOfWeek.toISOString();
                tempProgressData.weekCoinsEarned = userAnalyticsRecord.coinsReceivedWeek;
                tempProgressData.weekConectivitiesComplete = userAnalyticsRecord.conectivitiesWeek;
                tempProgressData.monthClosingDateTime = endOfMonth.toISOString();
                tempProgressData.monthCoinsEarned = userAnalyticsRecord.coinsReceivedMonth;
                tempProgressData.monthConectivitiesComplete = userAnalyticsRecord.conectivitiesMonth;
                tempProgressData.quarterClosingDateTime = endOfQuarter.toISOString();
                tempProgressData.quarterCoinsEarned = userAnalyticsRecord.coinsReceivedQuarter;
                tempProgressData.quarterConectivitiesComplete = userAnalyticsRecord.conectivitiesQuarter;
                tempProgressData.yearClosingDateTime = endOfYear.toISOString();
                tempProgressData.yearCoinsEarned = userAnalyticsRecord.coinsReceivedYear;
                tempProgressData.yearConectivitiesComplete = userAnalyticsRecord.conectivitiesYear;

            
                //RESET PROGRESS RINGS AND STREAKS FOR THE DIFFERENT CATEGORIES
                //That is, re-use the analytics for updating the progress rings expirations and values
                var updatedRingStats = grabRingAnalytics(userAnalyticsRecord, tempProgressData.socialPeriod, "SOCIAL");
                if (updatedRingStats !== null)  {
                      tempProgressData.socialPeriodStart = updatedRingStats.periodStart;
                      tempProgressData.socialPeriodEnd = updatedRingStats.periodEnd;
                      tempProgressData.socialPeriodCoinsEarned = updatedRingStats.periodCoinsEarned;
                      tempProgressData.socialPeriodConectivitiesComplete = updatedRingStats.periodConectivitiesComplete;
                    	tempProgressData.socialPeriodBonusGiven = updatedRingStats.periodCoinsEarned > tempProgressData.socialPeriodCoinGoal;
                    // 	if (DEBUG_MODE >= 2) console.log("Received updates to SOCIAL progress data", tempProgressData);
                }           
                
                 updatedRingStats = grabRingAnalytics(userAnalyticsRecord, tempProgressData.stressPeriod, "BALANCE");
                 if (updatedRingStats !== null)  {
                      tempProgressData.stressPeriodStart = updatedRingStats.periodStart;
                      tempProgressData.stressPeriodEnd = updatedRingStats.periodEnd;
                      tempProgressData.stressPeriodCoinsEarned = updatedRingStats.periodCoinsEarned;
                      tempProgressData.stressPeriodConectivitiesComplete = updatedRingStats.periodConectivitiesComplete;
                    	tempProgressData.stressPeriodBonusGiven = updatedRingStats.periodCoinsEarned > tempProgressData.stressPeriodCoinGoal;
                    // 	if (DEBUG_MODE >= 2) console.log("Received updates to BALANCE progress data", tempProgressData);
                }           

                updatedRingStats = grabRingAnalytics(userAnalyticsRecord, tempProgressData.teamPeriod, "TEAM");
                if (updatedRingStats !== null)  {
                      tempProgressData.teamPeriodStart = updatedRingStats.periodStart;
                      tempProgressData.teamPeriodEnd = updatedRingStats.periodEnd;
                      tempProgressData.teamPeriodCoinsEarned = updatedRingStats.periodCoinsEarned;
                      tempProgressData.teamPeriodConectivitiesComplete = updatedRingStats.periodConectivitiesComplete;
                    	tempProgressData.teamPeriodBonusGiven = updatedRingStats.periodCoinsEarned > tempProgressData.teamPeriodCoinGoal;
                    // 	if (DEBUG_MODE >= 2) console.log("Received updates to TEAM progress data", tempProgressData);
                }           

                 updatedRingStats = grabRingAnalytics(userAnalyticsRecord, tempProgressData.individualPeriod, "GROWTH");
                if (updatedRingStats !== null)  {
                      tempProgressData.individualPeriodStart = updatedRingStats.periodStart;
                      tempProgressData.individualPeriodEnd = updatedRingStats.periodEnd;
                      tempProgressData.individualPeriodCoinsEarned = updatedRingStats.periodCoinsEarned;
                      tempProgressData.individualPeriodConectivitiesComplete = updatedRingStats.periodConectivitiesComplete;
                     	tempProgressData.individualPeriodBonusGiven = updatedRingStats.periodCoinsEarned > tempProgressData.individualPeriodCoinGoal;
                    // 	if (DEBUG_MODE >= 2) console.log("Received updates to GROWTH progress data", tempProgressData);
               }           
                	
                //Reset any existing streak if necessary
                if (userBrokeStreak(tempProgressData.socialStreakLastClosureDate, tempProgressData.socialPeriod, tempProgressData.socialStreakStartDate)) {
                    tempProgressData.socialStreakCount = 0;
                  	tempProgressData.socialStreakStartDate = "2001-01-01T00:00:00+09:00";    	    
                }
                
                	if (userBrokeStreak(tempProgressData.balanceStreakLastClosureDate, tempProgressData.stressPeriod, tempProgressData.balanceStreakStartDate)) {
                    	tempProgressData.balanceStreakCount = 0;
                    	tempProgressData.balanceStreakStartDate = "2001-01-01T00:00:00+09:00";
                	}
                	
                	if (userBrokeStreak(tempProgressData.teamStreakLastClosureDate, tempProgressData.teamPeriod, tempProgressData.teamStreakStartDate)) {
                    	tempProgressData.teamStreakCount = 0;
                    	tempProgressData.teamStreakStartDate = "2001-01-01T00:00:00+09:00";
                	}
                	if (userBrokeStreak(tempProgressData.growthStreakLastClosureDate, tempProgressData.individualPeriod, tempProgressData.growthStreakStartDate)) {
                    	tempProgressData.growthStreakCount = 0;
                    	tempProgressData.growthStreakStartDate = "2001-01-01T00:00:00+09:00";
                	}
                	
                
                //Call GraphQL with correct parameters to edit the item in the  backend 
                
                const tempProgressDataResult = await invokeAPI(updateUserProgressData, 'updateUserProgressData', tempProgressData);
                console.log ("Successfully write updated user progress data to DB", tempProgressDataResult, user.firstName + " " + user.lastName, user, tempProgressData, userAnalyticsRecord);

            } else {
                if (DEBUG_MODE >= 2) console.log("Error updating userProgressDataRecords - no matching user", userProgressDataRecords[i], userAnalyticsRecord);
            } //END FOR VALID USER
       
        } //END FOR EACH PROGRESS DATA RECORD
        
    } 
 
 //
 // MAIN CALC FUNCTION
 //
 
    //This function processes the transaction data in view of the fetched conectivities and computes all stats needed
    export async function calcAnalytics ({customer, users, teams, transactions, userProgressDataRecords}) {
      //Safety check
      if (!customer || !customer.id || !users || users.length === 0 ) {
        if (DEBUG_MODE) console.error ("Error no customer or users for which to calc analytics", customer, users, userProgressDataRecords);
        return {};
      }

        // if (DEBUG_MODE >= 2) console.log("Calculating analytics", customer, users, teams, transactions);
        
       // compute network graph data for corporate-wide conectivity

        // const tempNodes = [];     //Initial graph data arrays that we will build up


        //Initialize calcs for the company
        initializeAnalyticsStructures(customer);        
        
        //Update certain data in the two customer objects 
        customerWideAnalytics.employeesTotal = users.length;
        customerWideAnalytics.teamsTotal = teams.length;

        
        //Walk all USERS and initialize graph data and stats (numbers) for the user
        for (var j = 0; j < users.length; j++){

            //Construct Graph Node for the user
            await buildUserNode(users[j]);

            //Initialize array of analytical data to have a spot for each USER, including those with ZERO transactions
            //This holds all the data for each user that we need for all the different graphs

            userNumbers[j] = {... userNumbersInitialState,
                userID: users[j].id,
                name:users[j].firstName + (!users[j].middleName ? "" : " " + users[j].middleName.substr(0,1)) + " " + users[j].lastName,
                initials:getUserInitials(users[j]),     //Used as the data Xkey for recharts
                title:users[j].title,
                onNumberOfTeams:users[j].onNumberOfTeams,
        	    customerID: customer.id,
                customer: customer.name,
            };
        }

        //Initialize array of analytical data to have a spot for each TEAM, including those with ZERO transactions
        //This holds all the data for each team that we need for all the different graphs
        for (var j = 0; j < teams.length; j++){

            teamNumbers[j] = {...teamNumbersInitialState,
                teamID: teams[j].id,
                name:teams[j].name,
                memberCount: teams[j].memberCount,      
        	    customerID: customer.id,
                customer: customer.name,
            };
        }
            
        // if (DEBUG_MODE >= 2) console.log("Starting to process transactions for analytics.  Initialized Users & Teams Numbers Array:", userNumbers, teamNumbers);

        //
        // Process all transactions related to completed conectivities for this customer and update analytical data
        //
    
    
        //Variables for company-wide, time-based series
        const tempTimeArrayByDay = [];
        const tempTimeArrayByWeek = [];
        const tempTimeArrayByMonth = [];
        const tempTimeArrayByQuarter = [];
        const tempTimeArrayByYear = [];
    
        var tempXKey = "";
        
        if (transactions && transactions.length > 0) {
          //Now, walk the entire list of transaction O(N) in FORWARD chronological order and update analytics
          for (var j = 0; j < transactions.length; j++){
              
              // if (DEBUG_MODE >= 2) console.log("processing transaction at index:", j);

              
              //UPDATE GRAPH DATA FOR CONECTIVITIES AND SPOTLIGHTS SENT; DO NOT COUNT SPOTLIGHTS RECEIVED TO AVOID DOUBLE COUNTING
              if (transactions[j].transactionType === "COMPLETED_CONECTIVITY" || transactions[j].transactionType == "SPOTLIGHT_SENT" ) {
                  
                  // if (DEBUG_MODE >= 2) console.log("processing transaction at index:", transactions[j]);

                  //UPDATE NETWORK GRAPH DATA
                  //For each completed conectivity, add / update edges between all pairs of users that participated in the connectivity
                  
                  if (transactions[j].usersThatParticipatedWithMe !== null) {  //Participants beyond just the user recording the transaction?

                      //First, handle the edges between the user that recorded the transaction and everyone else
                      for (var y=0; y<transactions[j].usersThatParticipatedWithMe.length; y++) {
                            
                          //Check that both FROM and TO for the edge are valid users
                          if (transactions[j].userID !== null && transactions[j].usersThatParticipatedWithMe[y] !=null ) {
                              //Check that FROM and TO are not the SAME USER
                                if (transactions[j].userID != transactions[j].usersThatParticipatedWithMe[y]) {
                                  // if (DEBUG_MODE >= 2) console.log('In all graphs, adding edge from ', transactions[j].userID, " to ", transactions[j].usersThatParticipatedWithMe[y]);                   
                                  addEdges(transactions[j], transactions[j].userID, transactions[j].usersThatParticipatedWithMe[y]);
                                }
                          }
                      }
                      
                      
                      //Second, update the edges between everyone else for completed conectivities and spotlights sent
                      //Important, skip any participant that is the same as the user who recorded the transaction since this would be duplicative of ablve
                      //This could happen if the userID of the user that recorded the transaction was also includined in the usersThatParticipatedWithMe array
                      if (transactions[j].usersThatParticipatedWithMe.length > 1) {  //More than 1 participant
                      
                          for (var outerLoop=0; outerLoop<transactions[j].usersThatParticipatedWithMe.length; outerLoop++) {
                              for (var innerLoop=outerLoop; innerLoop<transactions[j].usersThatParticipatedWithMe.length; innerLoop++) {
                                
                                  //Check that both participants are valid users
                                  if (transactions[j].usersThatParticipatedWithMe[outerLoop] && transactions[j].usersThatParticipatedWithMe[innerLoop]) {
                                      //Check that both participants are not the SAME USER and neither is the one who recorded the transaction
                                        if ((transactions[j].usersThatParticipatedWithMe[outerLoop] != transactions[j].usersThatParticipatedWithMe[innerLoop]) &&
                                        (transactions[j].usersThatParticipatedWithMe[outerLoop] != transactions[j].userID) &&
                                        (transactions[j].usersThatParticipatedWithMe[innerLoop] != transactions[j].userID)) {
                                          // if (DEBUG_MODE >= 2) console.log('Adding edge between participants from ' + transactions[j].usersThatParticipatedWithMe[outerLoop] + " to " + transactions[j].usersThatParticipatedWithMe[innerLoop]);                   
                                          addEdges(transactions[j], transactions[j].usersThatParticipatedWithMe[outerLoop], transactions[j].usersThatParticipatedWithMe[innerLoop]);
                                        }
                                  }
                              }
                          }
                      }
                  }


                  //UPDATE TIME SERIES GRAPH DATA
          
                  //Update DAILY time series bucket
                  tempXKey = getXKey(transactions[j].createdAt, "DAILY");
                  updateTimeSeriesArrayElement(tempTimeArrayByDay, tempXKey, transactions[j],"DAY");
          
                  //Update WEEKLY time series bucket
                  tempXKey = getXKey(transactions[j].createdAt, "WEEKLY");
                  updateTimeSeriesArrayElement(tempTimeArrayByWeek, tempXKey, transactions[j],"WEEK");
          
                  //Update MONTHLY time series bucket
                  tempXKey = getXKey(transactions[j].createdAt, "MONTHLY");
                  updateTimeSeriesArrayElement(tempTimeArrayByMonth, tempXKey, transactions[j],"MONTH");
          
                  //Update QUARTERLY time series bucket
                  tempXKey = getXKey(transactions[j].createdAt, "QUARTERLY");
                  updateTimeSeriesArrayElement(tempTimeArrayByQuarter, tempXKey, transactions[j],"QUARTER");
          
                  //Update YEARLY time series bucket
                  tempXKey = getXKey(transactions[j].createdAt, "YEARLY");
                  updateTimeSeriesArrayElement(tempTimeArrayByYear, tempXKey, transactions[j],"YEAR");

              }
              //UPDATE USER ANALYTICS BASED ON THIS TRANSACTION
              //Find the particular user related to the transaction and update conectivity-specific data
              var index=userNumbers.findIndex((user) => user.userID === transactions[j].userID);

              //Update user-specific numbers and customer-wide data based on this transaction
              if (index >-1) {
                  
                  // if (DEBUG_MODE >= 2) console.log("Updating user at index:", index);
                  
                if (transactions[j].transactionType === "COMPLETED_CONECTIVITY") {

                    //Update user-specific transaction numbers and category totals
                    userNumbers[index].conectivitiesCompleted +=1;
                    userNumbers[index].coinsAwarded +=transactions[j].amount;
                    userNumbers[index].badgesDEIAwarded +=transactions[j].badgesDEI;
                    userNumbers[index].badgesCSAwarded +=transactions[j].badgesCS;
                    
                    if (transactions[j].category == "SOCIAL") {
                      userNumbers[index].socialConectivitiesCompleted +=1; 
                      userNumbers[index].socialCoinsEarned +=transactions[j].amount; 
                    } else if (transactions[j].category == "STRESS") {
                        userNumbers[index].stressConectivitiesCompleted +=1; 
                        userNumbers[index].stressCoinsEarned +=transactions[j].amount; 
                    } else if (transactions[j].category == "TEAM") {
                        userNumbers[index].teamConectivitiesCompleted +=1; 
                        userNumbers[index].teamCoinsEarned +=transactions[j].amount; 
                    } else if (transactions[j].category == "PERSONAL") {
                        userNumbers[index].individualConectivitiesCompleted +=1; 
                        userNumbers[index].individualCoinsEarned +=transactions[j].amount; 
                    }

                
                    //Update customer-wide transaction numbers and category totals
                    customerWideAnalytics.totalConectivitiesCompletedByUsers +=1;
                    customerWideAnalytics.totalCoinsEarnedByUsers +=transactions[j].amount;             //Amount is negative $$ in the transaction
                    customerWideAnalytics.totalDEIBadgesEarnedByUsers +=transactions[j].badgesDEI;
                    customerWideAnalytics.totalCSBadgesEarnedByUsers +=transactions[j].badgesCS;
                    if (transactions[j].category == "SOCIAL") customerWideAnalytics.totalSocialConectivitiesCompletedByUsers +=1; 
                    if (transactions[j].category == "STRESS") customerWideAnalytics.totalStressConectivitiesCompletedByUsers +=1; 
                    if (transactions[j].category == "TEAM") customerWideAnalytics.totalTeamConectivitiesCompletedByUsers +=1; 
                    if (transactions[j].category == "PERSONAL") customerWideAnalytics.totalIndividualConectivitiesCompletedByUsers +=1; 
                  }

                  //Update spotlight stats based on the transaction, for this user and for the customer-wide stats
                  if (transactions[j].transactionType == "SPOTLIGHT_SENT" || transactions[j].transactionType == "SPOTLIGHT_RECEIVED") {

                    //Update Spotlight totals for this user
                    if (transactions[j].transactionType == "SPOTLIGHT_SENT") {
                      userNumbers[index].spotlightsSent +=1;
                        customerWideAnalytics.spotlightsSentTotal +=1;
                    }
                    
                    if (transactions[j].transactionType == "SPOTLIGHT_RECEIVED") {
                      userNumbers[index].spotlightsReceived +=1;
                      customerWideAnalytics.spotlightsReceivedTotal +=1;
                      // if (DEBUG_MODE >= 2) console.log("Found Spotlight Received", index, userNumbers);
                    }
                    
                    userNumbers[index].spotlightsCoinsEarned +=transactions[j].amount;
                    userNumbers[index].spotlightsBadgesDEIEarned +=transactions[j].badgesDEI;
                    userNumbers[index].spotlightsBadgesCSEarned +=transactions[j].badgesCS;  
                    
                    //Update customer-wide spotlight totals
                    customerWideAnalytics.spotlightsCoinsEarnedTotal += transactions[j].amount;
                    customerWideAnalytics.spotlightsBadgesDEIEarnedTotal += transactions[j].badgesDEI;
                    customerWideAnalytics.spotlightsBadgesCSEarnedTotal += transactions[j].badgesCS;                   	
                  }
                  
                  //For this user and company, update totals for time-based data (day totals, week totals, etc). 
                  updateTimeSeriesTotals({transaction:transactions[j], analyticsArray:userNumbers, index:index} );

              }
              
              
              //Now, update each TEAM with the stats for this transaction for the teams to which this user belongs
              //NOTE - FUTURE - UPDATE STATS ONLY IF TRANSACTION IS DESIGNATED FOR A SPECIFIC TEAM
              
              //Get distinct teams for the user associated with this transaction
              const teamsForUser = teams.filter(team => (team.users.items.some (join => join.userID === transactions[j].userID)));  

              //Is the user ON a team (i.e., are there join records)?  If so, for each Join, update that Team's stats
              if (teamsForUser.length >0 ) {
                  // if (DEBUG_MODE >= 2) console.log("Teams - processing transaction for user" + transactions[j].userID);
                  // if (DEBUG_MODE >= 2) console.log("Teams list for user that generated the transaction is:", teamsForUser);

                  for (var z=0; z < teamsForUser.length; z++) {
                    
                      //Give this team credit for a Conectivity between team members?
                      if (creditTeamForConectivity(teamsForUser[z], transactions[j] )) {
                      
                        //Yes, so find matching element in Teams Numbers
                        const indexTeamNumbersToUpdate = teamNumbers.findIndex(team => team.teamID === teamsForUser[z].id);

                        //Update Team Numbers based on this transaction
                        if (indexTeamNumbersToUpdate >-1) {
                    
                          //   if (DEBUG_MODE >= 2) console.log("Updating team at index: " + indexTeamNumbersToUpdate);
                            
                            teamNumbers[indexTeamNumbersToUpdate].conectivitiesCompleted +=1;
                            teamNumbers[indexTeamNumbersToUpdate].coinsAwarded +=transactions[j].amount;
                            teamNumbers[indexTeamNumbersToUpdate].badgesDEIAwarded +=transactions[j].badgesDEI;
                            teamNumbers[indexTeamNumbersToUpdate].badgesCSAwarded +=transactions[j].badgesCS;
                            
                            //Update category totals for this TEAM
                          if (transactions[j].category == "SOCIAL") {
                              teamNumbers[indexTeamNumbersToUpdate].socialConectivitiesCompleted +=1; 
                              teamNumbers[indexTeamNumbersToUpdate].socialCoinsEarned +=transactions[j].amount; 
                            } else if (transactions[j].category == "STRESS") {
                                teamNumbers[indexTeamNumbersToUpdate].stressConectivitiesCompleted +=1; 
                                teamNumbers[indexTeamNumbersToUpdate].stressCoinsEarned +=transactions[j].amount; 
                            } else if (transactions[j].category == "TEAM") {
                                teamNumbers[indexTeamNumbersToUpdate].teamConectivitiesCompleted +=1; 
                                teamNumbers[indexTeamNumbersToUpdate].teamCoinsEarned +=transactions[j].amount; 
                            } else if (transactions[j].category == "PERSONAL") {
                                teamNumbers[indexTeamNumbersToUpdate].individualConectivitiesCompleted +=1; 
                                teamNumbers[indexTeamNumbersToUpdate].individualCoinsEarned +=transactions[j].amount; 
                            }

                          //For this team, update totals for time-based data (day totals, week totals, etc). 
                          updateTimeSeriesTotals({transaction:transactions[j], analyticsArray:teamNumbers, index:indexTeamNumbersToUpdate} );

                        }
        
                        //Update total team-WIDE transaction numbers
                        customerWideAnalytics.totalConectivitiesCompletedByTeams +=1;
                        customerWideAnalytics.totalCoinsEarnedByTeams +=transactions[j].amount;             //Amount is negative $$ in the transaction
                        customerWideAnalytics.totalDEIBadgesEarnedByTeams +=transactions[j].badgesDEI;
                        customerWideAnalytics.totalCSBadgesEarnedByTeams +=transactions[j].badgesCS;
                        
                        //Update customer-wide team category totals
                        if (transactions[j].category == "SOCIAL") customerWideAnalytics.totalSocialConectivitiesCompletedByTeams +=1; 
                        if (transactions[j].category == "STRESS") customerWideAnalytics.totalStressConectivitiesCompletedByTeams +=1; 
                        if (transactions[j].category == "TEAM") customerWideAnalytics.totalTeamConectivitiesCompletedByTeams +=1; 
                        if (transactions[j].category == "PERSONAL") customerWideAnalytics.totalIndividualConectivitiesCompletedByTeams +=1; 
                      }
                  }                                        

              }
              
              
              //UPDATE CONECTIVITY ANALYTICS
              //Find the particular conectivity related to the transaction and update conectivity-specific data
              //TBD
                  
          }
        }


        //Now that we have finished computing the per-user STATs, walk the USER stats array and update COMPANY-WIDE user stats like MAXs, MINs and averages 
         for (var j = 0; j < userNumbers.length; j++){
           
            //Running max / mins
            if (userNumbers[j].conectivitiesCompleted > customerWideAnalytics.maxConectivitiesCompletedByUsers) customerWideAnalytics.maxConectivitiesCompletedByUsers = userNumbers[j].conectivitiesCompleted;
            if (userNumbers[j].conectivitiesCompleted < customerWideAnalytics.minConectivitiesCompletedByUsers) customerWideAnalytics.minConectivitiesCompletedByUsers = userNumbers[j].conectivitiesCompleted;

            if (userNumbers[j].coinsAwarded > customerWideAnalytics.maxCoinsEarnedByUsers) customerWideAnalytics.maxCoinsEarnedByUsers = userNumbers[j].coinsAwarded;
            if (userNumbers[j].coinsAwarded < customerWideAnalytics.minCoinsEarnedByUsers) customerWideAnalytics.minCoinsEarnedByUsers = userNumbers[j].coinsAwarded;
            if (userNumbers[j].badgesDEIAwarded > customerWideAnalytics.maxDEIBadgesEarnedByUsers) customerWideAnalytics.maxDEIBadgesEarnedByUsers = userNumbers[j].badgesDEIAwarded;
            if (userNumbers[j].badgesDEIAwarded < customerWideAnalytics.minDEIBadgesEarnedByUsers) customerWideAnalytics.minDEIBadgesEarnedByUsers = userNumbers[j].badgesDEIAwarded;
            if (userNumbers[j].badgesCSAwarded > customerWideAnalytics.maxCSBadgesEarnedByUsers) customerWideAnalytics.maxCSBadgesEarnedByUsers = userNumbers[j].badgesCSAwarded;
            if (userNumbers[j].badgesCSAwarded < customerWideAnalytics.minCSBadgesEarnedByUsers) customerWideAnalytics.minCSBadgesEarnedByUsers = userNumbers[j].badgesCSAwarded;


            if (userNumbers[j].socialConectivitiesCompleted > customerWideAnalytics.maxSocialConectivitiesCompletedByUsers) customerWideAnalytics.maxSocialConectivitiesCompletedByUsers = userNumbers[j].socialConectivitiesCompleted;
            if (userNumbers[j].socialConectivitiesCompleted < customerWideAnalytics.minSocialConectivitiesCompletedByUsers) customerWideAnalytics.minSocialConectivitiesCompletedByUsers = userNumbers[j].socialConectivitiesCompleted;
            if (userNumbers[j].stressConectivitiesCompleted > customerWideAnalytics.maxStressConectivitiesCompletedByUsers) customerWideAnalytics.maxStressConectivitiesCompletedByUsers = userNumbers[j].stressConectivitiesCompleted;
            if (userNumbers[j].stressConectivitiesCompleted < customerWideAnalytics.minStressConectivitiesCompletedByUsers) customerWideAnalytics.minStressConectivitiesCompletedByUsers = userNumbers[j].stressConectivitiesCompleted;
            if (userNumbers[j].teamConectivitiesCompleted > customerWideAnalytics.maxTeamConectivitiesCompletedByUsers) customerWideAnalytics.maxTeamConectivitiesCompletedByUsers = userNumbers[j].teamConectivitiesCompleted;
            if (userNumbers[j].teamConectivitiesCompleted < customerWideAnalytics.minTeamConectivitiesCompletedByUsers) customerWideAnalytics.minTeamConectivitiesCompletedByUsers = userNumbers[j].teamConectivitiesCompleted;
            if (userNumbers[j].individualConectivitiesCompleted > customerWideAnalytics.maxIndividualConectivitiesCompletedByUsers) customerWideAnalytics.maxIndividualConectivitiesCompletedByUsers = userNumbers[j].individualConectivitiesCompleted;
            if (userNumbers[j].individualConectivitiesCompleted < customerWideAnalytics.minIndividualConectivitiesCompletedByUsers) customerWideAnalytics.minIndividualConectivitiesCompletedByUsers = userNumbers[j].individualConectivitiesCompleted;
            

            if (userNumbers[j].spotlightsReceived > customerWideAnalytics.mostSpotlightsReceived) customerWideAnalytics.mostSpotlightsReceived = userNumbers[j].spotlightsReceived;
            if (userNumbers[j].spotlightsReceived < customerWideAnalytics.leastSpotlightsReceived) customerWideAnalytics.leastSpotlightsReceived = userNumbers[j].spotlightsReceived;
            if (userNumbers[j].spotlightsSent > customerWideAnalytics.mostSpotlightsSent) customerWideAnalytics.mostSpotlightsSent = userNumbers[j].spotlightsSent;
            if (userNumbers[j].spotlightsSent < customerWideAnalytics.leastSpotlightsSent) customerWideAnalytics.leastSpotlightsSent = userNumbers[j].spotlightsSent;

            if (userNumbers[j].spotlightsReceived > 0) customerWideAnalytics.employeesRecognized+=1; else customerWideAnalytics.employeesUnrecognized +=1;

            
            //Compute customer-wide time-series data as the sum of the data for all users
            updateCustomerWideTimeSeriesData(j);
         }

        //Calculate averages
        customerWideAnalytics.avgConectivitiesCompletedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalConectivitiesCompletedByUsers / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgCoinsEarnedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalCoinsEarnedByUsers / customerWideAnalytics.employeesTotal; 
        customerWideAnalytics.avgDEIBadgesEarnedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalDEIBadgesEarnedByUsers / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgCSBadgesEarnedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalCSBadgesEarnedByUsers / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSocialConectivitiesCompletedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalSocialConectivitiesCompletedByUsers / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgStressConectivitiesCompletedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalStressConectivitiesCompletedByUsers / customerWideAnalytics.employeesTotal; 
        customerWideAnalytics.avgTeamConectivitiesCompletedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalTeamConectivitiesCompletedByUsers / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgIndividualConectivitiesCompletedByUsers = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.totalIndividualConectivitiesCompletedByUsers / customerWideAnalytics.employeesTotal;

        customerWideAnalytics.avgConectivitiesCompletedByUsers = Math.round(customerWideAnalytics.avgConectivitiesCompletedByUsers * 100) / 100;
        customerWideAnalytics.avgCoinsEarnedByUsers = Math.round(customerWideAnalytics.avgCoinsEarnedByUsers * 100) / 100; 
        customerWideAnalytics.avgDEIBadgesEarnedByUsers = Math.round(customerWideAnalytics.avgDEIBadgesEarnedByUsers * 100) / 100;
        customerWideAnalytics.avgCSBadgesEarnedByUsers = Math.round(customerWideAnalytics.avgCSBadgesEarnedByUsers * 100) / 100;
        customerWideAnalytics.avgSocialConectivitiesCompletedByUsers = Math.round(customerWideAnalytics.avgSocialConectivitiesCompletedByUsers * 100) / 100;
        customerWideAnalytics.avgStressConectivitiesCompletedByUsers = Math.round(customerWideAnalytics.avgStressConectivitiesCompletedByUsers * 100) / 100; 
        customerWideAnalytics.avgTeamConectivitiesCompletedByUsers = Math.round(customerWideAnalytics.avgTeamConectivitiesCompletedByUsers * 100) / 100;
        customerWideAnalytics.avgIndividualConectivitiesCompletedByUsers = Math.round(customerWideAnalytics.avgIndividualConectivitiesCompletedByUsers * 100) / 100;
        
        customerWideAnalytics.avgSpotlightsReceived = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedTotal / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSent = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentTotal / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedDay = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedDay / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentDay = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentDay / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedWeek = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedWeek / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentWeek = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentWeek / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedMonth = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedMonth / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentMonth = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentMonth / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedQuarter = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedQuarter / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentQuarter = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentQuarter / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedYear = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedYear / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentYear = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentYear / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedOneWeek = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedOneWeek / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentOneWeek = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentOneWeek / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedOneMonth = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedOneMonth / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentOneMonth = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentOneMonth / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedTwoMonths = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedTwoMonths / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentTwoMonths = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentTwoMonths / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsReceivedOneYear = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsReceivedOneYear / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgSpotlightsSentOneYear = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.spotlightsSentOneYear / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.percentEmployeesRecognized = customerWideAnalytics.employeesTotal==0 ? 0 : customerWideAnalytics.employeesRecognized / customerWideAnalytics.employeesTotal;

        customerWideAnalytics.avgSpotlightsReceived = Math.round(customerWideAnalytics.avgSpotlightsReceived  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSent = Math.round(customerWideAnalytics.avgSpotlightsSent  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedDay = Math.round(customerWideAnalytics.avgSpotlightsReceivedDay  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentDay = Math.round(customerWideAnalytics.avgSpotlightsSentDay  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedWeek = Math.round(customerWideAnalytics.avgSpotlightsReceivedWeek  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentWeek = Math.round(customerWideAnalytics.avgSpotlightsSentWeek  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedMonth = Math.round(customerWideAnalytics.avgSpotlightsReceivedMonth  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentMonth = Math.round(customerWideAnalytics.avgSpotlightsSentMonth  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedQuarter = Math.round(customerWideAnalytics.avgSpotlightsReceivedQuarter  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentQuarter = Math.round(customerWideAnalytics.avgSpotlightsSentQuarter  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedYear = Math.round(customerWideAnalytics.avgSpotlightsReceivedYear  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentYear = Math.round(customerWideAnalytics.avgSpotlightsSentYear  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedOneWeek = Math.round(customerWideAnalytics.avgSpotlightsReceivedOneWeek  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentOneWeek = Math.round(customerWideAnalytics.avgSpotlightsSentOneWeek  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedOneMonth = Math.round(customerWideAnalytics.avgSpotlightsReceivedOneMonth  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentOneMonth = Math.round(customerWideAnalytics.avgSpotlightsSentOneMonth  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedTwoMonths = Math.round(customerWideAnalytics.avgSpotlightsReceivedTwoMonths  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentTwoMonths = Math.round(customerWideAnalytics.avgSpotlightsSentTwoMonths  * 100) / 100;
        customerWideAnalytics.avgSpotlightsReceivedOneYear = Math.round(customerWideAnalytics.avgSpotlightsReceivedOneYear  * 100) / 100;
        customerWideAnalytics.avgSpotlightsSentOneYear = Math.round(customerWideAnalytics.avgSpotlightsSentOneYear  * 100) / 100;
        customerWideAnalytics.percentEmployeesRecognized = 100*Math.round(customerWideAnalytics.percentEmployeesRecognized  * 100) / 100;
        
        //Assign our Conectivity Score to each employee as an indication of their conectivity relative to other employees within the company
        //This is not a raw score, which could be a function of ring closes or similar metrics
        //Top RANK is a 6.0 for matching the MAX for any employee in all six pillars of conectivity
        
        var corporateRelativeConectivityScore = 0;
        
         for (var j = 0; j < userNumbers.length; j++){

            //Each of these is a ratio of 0 to 1.0 where 1.0 represents the max in the company
            userNumbers[j].stressComponent = customerWideAnalytics.maxStressConectivitiesCompletedByUsers ==0 ? 0 : userNumbers[j].stressConectivitiesCompleted / customerWideAnalytics.maxStressConectivitiesCompletedByUsers; 
            userNumbers[j].socialComponent = customerWideAnalytics.maxSocialConectivitiesCompletedByUsers ==0 ? 0 : userNumbers[j].socialConectivitiesCompleted / customerWideAnalytics.maxSocialConectivitiesCompletedByUsers; 
            userNumbers[j].teamComponent = customerWideAnalytics.maxTeamConectivitiesCompletedByUsers ==0 ? 0 : userNumbers[j].teamConectivitiesCompleted / customerWideAnalytics.maxTeamConectivitiesCompletedByUsers; 
            userNumbers[j].individualComponent = customerWideAnalytics.maxIndividualConectivitiesCompletedByUsers ==0 ? 0 : userNumbers[j].individualConectivitiesCompleted / customerWideAnalytics.maxIndividualConectivitiesCompletedByUsers; 
            userNumbers[j].badgesCSComponent = customerWideAnalytics.maxCSBadgesEarnedByUsers ==0 ? 0 : userNumbers[j].badgesCSAwarded / customerWideAnalytics.maxCSBadgesEarnedByUsers;
            userNumbers[j].badgesDEIComponent = customerWideAnalytics.maxDEIBadgesEarnedByUsers ==0 ? 0 : userNumbers[j].badgesDEIAwarded / customerWideAnalytics.maxDEIBadgesEarnedByUsers;
 
            userNumbers[j].stressComponent = Math.round(userNumbers[j].stressComponent * 100) / 100;
            userNumbers[j].socialComponent =  Math.round( userNumbers[j].socialComponent * 100) / 100;
            userNumbers[j].teamComponent =Math.round(userNumbers[j].teamComponent * 100) / 100;
            userNumbers[j].individualComponent = Math.round(userNumbers[j].individualComponent * 100) / 100;
            userNumbers[j].badgesCSComponent = Math.round(userNumbers[j].badgesCSComponent * 100) / 100;
            userNumbers[j].badgesDEIComponent = Math.round(userNumbers[j].badgesDEIComponent * 100) / 100;
            
            userNumbers[j].relativeConectivityScore = (
                userNumbers[j].stressComponent + 
                userNumbers[j].socialComponent + 
                userNumbers[j].teamComponent +
                userNumbers[j].individualComponent + 
                userNumbers[j].badgesCSComponent + 
                userNumbers[j].badgesDEIComponent);
                
                
            userNumbers[j].relativeConectivityScore = Math.round( userNumbers[j].relativeConectivityScore * 100) / 100;
            
            if (userNumbers[j].relativeConectivityScore > customerWideAnalytics.maxRelativeConectivityScoreByUsers) customerWideAnalytics.maxRelativeConectivityScoreByUsers = userNumbers[j].relativeConectivityScore;
            if (userNumbers[j].relativeConectivityScore < customerWideAnalytics.minRelativeConectivityScoreByUsers) customerWideAnalytics.minRelativeConectivityScoreByUsers = userNumbers[j].relativeConectivityScore;

            corporateRelativeConectivityScore += userNumbers[j].relativeConectivityScore;  //Keep a running total

        }

        //Compute overall corporate average conectivity score for the users
        customerWideAnalytics.avgRelativeConectivityScoreByUsers = corporateRelativeConectivityScore / customerWideAnalytics.employeesTotal;
        customerWideAnalytics.avgRelativeConectivityScoreByUsers = Math.round (customerWideAnalytics.avgRelativeConectivityScoreByUsers * 100) /100;
        

        //Calculate and store the COMPANY Conectivity metrics, i.e., the Conectivity metrics for the COMPANY, i.e., company-wide users averages relative to the max scores in each component
        customerWideAnalytics.stressComponent = customerWideAnalytics.maxStressConectivitiesCompletedByUsers ==0 ? 0 : customerWideAnalytics.avgStressConectivitiesCompletedByUsers / customerWideAnalytics.maxStressConectivitiesCompletedByUsers; 
        customerWideAnalytics.socialComponent = customerWideAnalytics.maxSocialConectivitiesCompletedByUsers ==0 ? 0 : customerWideAnalytics.avgSocialConectivitiesCompletedByUsers / customerWideAnalytics.maxSocialConectivitiesCompletedByUsers; 
        customerWideAnalytics.teamComponent = customerWideAnalytics.maxTeamConectivitiesCompletedByUsers ==0 ? 0 : customerWideAnalytics.avgTeamConectivitiesCompletedByUsers / customerWideAnalytics.maxTeamConectivitiesCompletedByUsers; 
        customerWideAnalytics.individualComponent = customerWideAnalytics.maxIndividualConectivitiesCompletedByUsers ==0 ? 0 : customerWideAnalytics.avgIndividualConectivitiesCompletedByUsers / customerWideAnalytics.maxIndividualConectivitiesCompletedByUsers; 
        customerWideAnalytics.badgesCSComponent = customerWideAnalytics.maxCSBadgesEarnedByUsers ==0 ? 0 : customerWideAnalytics.avgCSBadgesEarnedByUsers / customerWideAnalytics.maxCSBadgesEarnedByUsers;
        customerWideAnalytics.badgesDEIComponent = customerWideAnalytics.maxDEIBadgesEarnedByUsers ==0 ? 0 : customerWideAnalytics.avgDEIBadgesEarnedByUsers / customerWideAnalytics.maxDEIBadgesEarnedByUsers;

        customerWideAnalytics.stressComponent = Math.round(customerWideAnalytics.stressComponent * 100) / 100;
        customerWideAnalytics.socialComponent =  Math.round( customerWideAnalytics.socialComponent * 100) / 100;
        customerWideAnalytics.teamComponent =Math.round(customerWideAnalytics.teamComponent * 100) / 100;
        customerWideAnalytics.individualComponent = Math.round(customerWideAnalytics.individualComponent * 100) / 100;
        customerWideAnalytics.badgesCSComponent = Math.round(customerWideAnalytics.badgesCSComponent * 100) / 100;
        customerWideAnalytics.badgesDEIComponent = Math.round(customerWideAnalytics.badgesDEIComponent * 100) / 100;
        
        
        customerWideAnalytics.relativeConectivityScore = (
            customerWideAnalytics.stressComponent + 
            customerWideAnalytics.socialComponent + 
            customerWideAnalytics.teamComponent +
            customerWideAnalytics.individualComponent + 
            customerWideAnalytics.badgesCSComponent + 
            customerWideAnalytics.badgesDEIComponent);
            
            
        customerWideAnalytics.relativeConectivityScore = Math.round( customerWideAnalytics.relativeConectivityScore * 100) / 100;        

        //
        //Update All Team Stats like we did above for users
        //
        // if (DEBUG_MODE >= 2) console.log("Analytics - Generating Team wide totals from team analytics", teamNumbers, customerWideAnalytics);

        //Walk the TEAM stats array and update COMPANY-WIDE team stats like MAXs, MINs and averages 
         for (var j = 0; j < teamNumbers.length; j++){
            if (teamNumbers[j].conectivitiesCompleted > customerWideAnalytics.maxConectivitiesCompletedByTeams) customerWideAnalytics.maxConectivitiesCompletedByTeams = teamNumbers[j].conectivitiesCompleted;
            if (teamNumbers[j].conectivitiesCompleted < customerWideAnalytics.minConectivitiesCompletedByTeams) customerWideAnalytics.minConectivitiesCompletedByTeams = teamNumbers[j].conectivitiesCompleted;

            if (teamNumbers[j].coinsAwarded > customerWideAnalytics.maxCoinsEarnedByTeams) customerWideAnalytics.maxCoinsEarnedByTeams = teamNumbers[j].coinsAwarded;
            if (teamNumbers[j].coinsAwarded < customerWideAnalytics.minCoinsEarnedByTeams) customerWideAnalytics.minCoinsEarnedByTeams = teamNumbers[j].coinsAwarded;
            if (teamNumbers[j].badgesDEIAwarded > customerWideAnalytics.maxDEIBadgesEarnedByTeams) customerWideAnalytics.maxDEIBadgesEarnedByTeams = teamNumbers[j].badgesDEIAwarded;
            if (teamNumbers[j].badgesDEIAwarded < customerWideAnalytics.minDEIBadgesEarnedByTeams) customerWideAnalytics.minDEIBadgesEarnedByTeams = teamNumbers[j].badgesDEIAwarded;
            if (teamNumbers[j].badgesCSAwarded > customerWideAnalytics.maxCSBadgesEarnedByTeams) customerWideAnalytics.maxCSBadgesEarnedByTeams = teamNumbers[j].badgesCSAwarded;
            if (teamNumbers[j].badgesCSAwarded < customerWideAnalytics.minCSBadgesEarnedByTeams) customerWideAnalytics.minCSBadgesEarnedByTeams = teamNumbers[j].badgesCSAwarded;

            if (teamNumbers[j].socialConectivitiesCompleted > customerWideAnalytics.maxSocialConectivitiesCompletedByTeams) customerWideAnalytics.maxSocialConectivitiesCompletedByTeams = teamNumbers[j].socialConectivitiesCompleted;
            if (teamNumbers[j].socialConectivitiesCompleted < customerWideAnalytics.minSocialConectivitiesCompletedByTeams) customerWideAnalytics.minSocialConectivitiesCompletedByTeams = teamNumbers[j].socialConectivitiesCompleted;
            if (teamNumbers[j].stressConectivitiesCompleted > customerWideAnalytics.maxStressConectivitiesCompletedByTeams) customerWideAnalytics.maxStressConectivitiesCompletedByTeams = teamNumbers[j].stressConectivitiesCompleted;
            if (teamNumbers[j].stressConectivitiesCompleted < customerWideAnalytics.minStressConectivitiesCompletedByTeams) customerWideAnalytics.minStressConectivitiesCompletedByTeams = teamNumbers[j].stressConectivitiesCompleted;
            if (teamNumbers[j].teamConectivitiesCompleted > customerWideAnalytics.maxTeamConectivitiesCompletedByTeams) customerWideAnalytics.maxTeamConectivitiesCompletedByTeams = teamNumbers[j].teamConectivitiesCompleted;
            if (teamNumbers[j].teamConectivitiesCompleted < customerWideAnalytics.minTeamConectivitiesCompletedByTeams) customerWideAnalytics.minTeamConectivitiesCompletedByTeams = teamNumbers[j].teamConectivitiesCompleted;
            if (teamNumbers[j].individualConectivitiesCompleted > customerWideAnalytics.maxIndividualConectivitiesCompletedByTeams) customerWideAnalytics.maxIndividualConectivitiesCompletedByTeams = teamNumbers[j].individualConectivitiesCompleted;
            if (teamNumbers[j].individualConectivitiesCompleted < customerWideAnalytics.minIndividualConectivitiesCompletedByTeams) customerWideAnalytics.minIndividualConectivitiesCompletedByTeams = teamNumbers[j].individualConectivitiesCompleted;
            
            if (teamNumbers[j].memberCount < customerWideAnalytics.smallestTeam) customerWideAnalytics.smallestTeam = teamNumbers[j].memberCount;
            if (teamNumbers[j].memberCount > customerWideAnalytics.largestTeam) customerWideAnalytics.largestTeam = teamNumbers[j].memberCount;

         }

        //Calculate averages
        customerWideAnalytics.avgConectivitiesCompletedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalConectivitiesCompletedByTeams / customerWideAnalytics.teamsTotal;
        customerWideAnalytics.avgCoinsEarnedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalCoinsEarnedByTeams / customerWideAnalytics.teamsTotal; 
        customerWideAnalytics.avgDEIBadgesEarnedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalDEIBadgesEarnedByTeams / customerWideAnalytics.teamsTotal;
        customerWideAnalytics.avgCSBadgesEarnedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 :customerWideAnalytics.totalCSBadgesEarnedByTeams / customerWideAnalytics.teamsTotal;
        customerWideAnalytics.avgSocialConectivitiesCompletedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalSocialConectivitiesCompletedByTeams / customerWideAnalytics.teamsTotal;
        customerWideAnalytics.avgStressConectivitiesCompletedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalStressConectivitiesCompletedByTeams / customerWideAnalytics.teamsTotal; 
        customerWideAnalytics.avgTeamConectivitiesCompletedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalTeamConectivitiesCompletedByTeams / customerWideAnalytics.teamsTotal;
        customerWideAnalytics.avgIndividualConectivitiesCompletedByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : customerWideAnalytics.totalIndividualConectivitiesCompletedByTeams / customerWideAnalytics.teamsTotal;

        customerWideAnalytics.avgConectivitiesCompletedByTeams = Math.round( customerWideAnalytics.avgConectivitiesCompletedByTeams * 100) / 100;
        customerWideAnalytics.avgCoinsEarnedByTeams =  Math.round( customerWideAnalytics.avgCoinsEarnedByTeams * 100) / 100;
        customerWideAnalytics.avgDEIBadgesEarnedByTeams =  Math.round( customerWideAnalytics.avgDEIBadgesEarnedByTeams * 100) / 100;
        customerWideAnalytics.avgCSBadgesEarnedByTeams =  Math.round( customerWideAnalytics.avgCSBadgesEarnedByTeams * 100) / 100;
        customerWideAnalytics.avgSocialConectivitiesCompletedByTeams =  Math.round( customerWideAnalytics.avgSocialConectivitiesCompletedByTeams * 100) / 100;
        customerWideAnalytics.avgStressConectivitiesCompletedByTeams =  Math.round( customerWideAnalytics.avgStressConectivitiesCompletedByTeams * 100) / 100;
        customerWideAnalytics.avgTeamConectivitiesCompletedByTeams =  Math.round( customerWideAnalytics.avgTeamConectivitiesCompletedByTeams * 100) / 100;
        customerWideAnalytics.avgIndividualConectivitiesCompletedByTeams =  Math.round( customerWideAnalytics.avgIndividualConectivitiesCompletedByTeams * 100) / 100;


        //Assign a Conectivity Score to each TEAM as an indication of their conectivity relative to other TEAMS within the company
        //This is not a raw score, which could be a function of ring closes or similar metrics
        //Top RANK is a 6.0 for matching the MAX for any employee in all six pillars of conectivity
        
        corporateRelativeConectivityScore = 0;
        
         for (var j = 0; j < teamNumbers.length; j++){

            //Each of these is a ratio of 0 to 1.0 where 1.0 represents the max in the company
            teamNumbers[j].stressComponent = customerWideAnalytics.maxStressConectivitiesCompletedByTeams==0 ? 0 : teamNumbers[j].stressConectivitiesCompleted / customerWideAnalytics.maxStressConectivitiesCompletedByTeams; 
            teamNumbers[j].socialComponent = customerWideAnalytics.maxSocialConectivitiesCompletedByTeams==0 ? 0 : teamNumbers[j].socialConectivitiesCompleted / customerWideAnalytics.maxSocialConectivitiesCompletedByTeams; 
            teamNumbers[j].teamComponent = customerWideAnalytics.maxTeamConectivitiesCompletedByTeams==0 ? 0 : teamNumbers[j].teamConectivitiesCompleted / customerWideAnalytics.maxTeamConectivitiesCompletedByTeams; 
            teamNumbers[j].individualComponent = customerWideAnalytics.maxIndividualConectivitiesCompletedByTeams==0 ? 0 : teamNumbers[j].individualConectivitiesCompleted / customerWideAnalytics.maxIndividualConectivitiesCompletedByTeams; 
            teamNumbers[j].badgesCSComponent = customerWideAnalytics.maxCSBadgesEarnedByTeams==0 ? 0 : teamNumbers[j].badgesCSAwarded / customerWideAnalytics.maxCSBadgesEarnedByTeams;
            teamNumbers[j].badgesDEIComponent = customerWideAnalytics.maxDEIBadgesEarnedByTeams==0 ? 0 : teamNumbers[j].badgesDEIAwarded / customerWideAnalytics.maxDEIBadgesEarnedByTeams;
            
            teamNumbers[j].stressComponent = Math.round(teamNumbers[j].stressComponent * 100) / 100;
            teamNumbers[j].socialComponent =  Math.round( teamNumbers[j].socialComponent * 100) / 100;
            teamNumbers[j].teamComponent =Math.round(teamNumbers[j].teamComponent * 100) / 100;
            teamNumbers[j].individualComponent = Math.round(teamNumbers[j].individualComponent * 100) / 100;
            teamNumbers[j].badgesCSComponent = Math.round(teamNumbers[j].badgesCSComponent * 100) / 100;
            teamNumbers[j].badgesDEIComponent = Math.round(teamNumbers[j].badgesDEIComponent * 100) / 100;
            
            
            teamNumbers[j].relativeConectivityScore = (
                teamNumbers[j].stressComponent + 
                teamNumbers[j].socialComponent + 
                teamNumbers[j].teamComponent +
                teamNumbers[j].individualComponent + 
                teamNumbers[j].badgesCSComponent + 
                teamNumbers[j].badgesDEIComponent);

            teamNumbers[j].relativeConectivityScore = Math.round( teamNumbers[j].relativeConectivityScore * 100) / 100;

            if (teamNumbers[j].relativeConectivityScore > customerWideAnalytics.maxRelativeConectivityScoreByTeams) customerWideAnalytics.maxRelativeConectivityScoreByTeams = teamNumbers[j].relativeConectivityScore;
            if (teamNumbers[j].relativeConectivityScore < customerWideAnalytics.minRelativeConectivityScoreByTeams) customerWideAnalytics.minRelativeConectivityScoreByTeams = teamNumbers[j].relativeConectivityScore;

            corporateRelativeConectivityScore += teamNumbers[j].relativeConectivityScore;  //Keep a running total

        }


        //Compute overall corporate average conectivity score
        customerWideAnalytics.avgRelativeConectivityScoreByTeams = customerWideAnalytics.teamsTotal==0 ? 0 : corporateRelativeConectivityScore / customerWideAnalytics.teamsTotal;
        customerWideAnalytics.avgRelativeConectivityScoreByTeams = Math.round (customerWideAnalytics.avgRelativeConectivityScoreByTeams * 100) /100;

        // if (DEBUG_MODE >= 2) console.log("Analytics - FINISHED Generating Team wide totals from team analytics", teamNumbers);

        // END TEAM STATS
        
        
       //Sort so we can set the ranks based on Conectivity Scores
        userNumbers.sort(compareConectivityScore);
        teamNumbers.sort(compareConectivityScore);

         for (var j = 0; j < userNumbers.length; j++){
             userNumbers[j].userRank = j+1;
         }

         for (var j = 0; j < teamNumbers.length; j++){
             teamNumbers[j].teamRank = j+1;
         }

        if (userProgressDataRecords) {
            //Analytics for the user streaks based on user progress data records
             for (var j = 0; j < userProgressDataRecords.length; j++) {
                 
                //Let's confirm this record is for a valid, active user for this customer
                if (users.some(user => user.id == userProgressDataRecords[j].userID)) {
                     
                    try {
                
                        if (userProgressDataRecords[j].balanceStreakCount >0) {
                            customerWideAnalytics.balanceStreakCount++; //Increment number of users currently with a streak of this category
                            if (userProgressDataRecords[j].balanceStreakCount > customerWideAnalytics.balanceStreakCountMax) {
                                customerWideAnalytics.balanceStreakCountMax = userProgressDataRecords[j].balanceStreakCount;
                                customerWideAnalytics.balanceStreakStartDateEarliest = userProgressDataRecords[j].balanceStreakStartDate;
                                customerWideAnalytics.balanceStreakMaxUsers = [userProgressDataRecords[j].userID];     // Make new Array of user IDs for individuals with the longest streak
                            } else if (userProgressDataRecords[j].balanceStreakCount > 0 && userProgressDataRecords[j].balanceStreakCount == customerWideAnalytics.balanceStreakCountMax) {
                                customerWideAnalytics.balanceStreakMaxUsers.push(userProgressDataRecords[j].userID);     // Add to the Array of user IDs for individuals with the longest streak
                            }
                        }
                        if (userProgressDataRecords[j].socialStreakCount >0) {
                            customerWideAnalytics.socialStreakCount++; //Increment number of users currently with a streak of this category
                            if (userProgressDataRecords[j].socialStreakCount > customerWideAnalytics.socialStreakCountMax) {
                                customerWideAnalytics.socialStreakCountMax = userProgressDataRecords[j].socialStreakCount;
                                customerWideAnalytics.socialStreakStartDateEarliest = userProgressDataRecords[j].socialStreakStartDate;
                                customerWideAnalytics.socialStreakMaxUsers = [userProgressDataRecords[j].userID];     // Make new Array of user IDs for individuals with the longest streak
                            } else if (userProgressDataRecords[j].socialStreakCount > 0 && userProgressDataRecords[j].socialStreakCount == customerWideAnalytics.socialStreakCountMax){
                                customerWideAnalytics.socialStreakMaxUsers.push(userProgressDataRecords[j].userID);     // Add to the Array of user IDs for individuals with the longest streak
                            }
                        }
                        if (userProgressDataRecords[j].teamStreakCount >0) {
                            customerWideAnalytics.teamStreakCount++; //Increment number of users currently with a streak of this category
                            if (userProgressDataRecords[j].teamStreakCount > customerWideAnalytics.teamStreakCountMax) {
                                customerWideAnalytics.teamStreakCountMax = userProgressDataRecords[j].teamStreakCount;
                                customerWideAnalytics.teamStreakStartDateEarliest = userProgressDataRecords[j].teamStreakStartDate;
                                customerWideAnalytics.teamStreakMaxUsers = [userProgressDataRecords[j].userID];     // Make new Array of user IDs for individuals with the longest streak
                            } else if (userProgressDataRecords[j].teamStreakCount > 0 && userProgressDataRecords[j].teamStreakCount == customerWideAnalytics.teamStreakCountMax){
                                customerWideAnalytics.teamStreakMaxUsers.push(userProgressDataRecords[j].userID);     // Add to the Array of user IDs for individuals with the longest streak
                            }
                        }
                        if (userProgressDataRecords[j].growthStreakCount >0) {
                            customerWideAnalytics.growthStreakCount++; //Increment number of users currently with a streak of this category
                            if (userProgressDataRecords[j].growthStreakCount > customerWideAnalytics.growthStreakCountMax) {
                                customerWideAnalytics.growthStreakCountMax = userProgressDataRecords[j].growthStreakCount;
                                customerWideAnalytics.growthStreakStartDateEarliest = userProgressDataRecords[j].growthStreakStartDate;
                                customerWideAnalytics.growthStreakMaxUsers = [userProgressDataRecords[j].userID];     // Make new Array of user IDs for individuals with the longest streak
                            } else if (userProgressDataRecords[j].growthStreakCount > 0 && userProgressDataRecords[j].growthStreakCount == customerWideAnalytics.growthStreakCountMax){
                                customerWideAnalytics.growthStreakMaxUsers.push(userProgressDataRecords[j].userID);     // Add to the Array of user IDs for individuals with the longest streak
                            }
                        }    
                    } catch (err) {
                            
                        if (DEBUG_MODE >= 2) console.log("Error processing user progress data",err);
                        
                    }
                }
            } 
            // console.log ("Built customer-wide user analytics data from transactions:", customerWideAnalytics);
        }
        
        // console.log ("Built user-specific data from transactions:", userNumbers);
        // console.log ("Built team-specific data from transactions:", teamNumbers);

        /* THIS IS NOW HANDLED BY THE FRONT-END WHEN LOADING GRAPHS
        //Set node color based on the max connections counted for a user in each graph; could move this to the front-end to make generic
        setNodeAndEdgeAttributes(tempGraphDataAllDay);
        setNodeAndEdgeAttributes(tempGraphDataAllWeek);
        setNodeAndEdgeAttributes(tempGraphDataAllMonth);
        setNodeAndEdgeAttributes(tempGraphDataAllOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataAllOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataAllTwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataAllQuarter);
        setNodeAndEdgeAttributes(tempGraphDataAllYear);
        
        setNodeAndEdgeAttributes(tempGraphDataBalanceDay);
        setNodeAndEdgeAttributes(tempGraphDataBalanceWeek);   
        setNodeAndEdgeAttributes(tempGraphDataBalanceMonth);  
        setNodeAndEdgeAttributes(tempGraphDataBalanceOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataBalanceOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataBalanceTwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataBalanceQuarter);
        setNodeAndEdgeAttributes(tempGraphDataBalanceYear);
        
        setNodeAndEdgeAttributes(tempGraphDataSocialDay);
        setNodeAndEdgeAttributes(tempGraphDataSocialWeek);
        setNodeAndEdgeAttributes(tempGraphDataSocialMonth);
        setNodeAndEdgeAttributes(tempGraphDataSocialOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataSocialOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataSocialTwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataSocialQuarter);  
        setNodeAndEdgeAttributes(tempGraphDataSocialYear); 
        
        setNodeAndEdgeAttributes(tempGraphDataTeamDay );
        setNodeAndEdgeAttributes(tempGraphDataTeamWeek  );
        setNodeAndEdgeAttributes(tempGraphDataTeamMonth);   
        setNodeAndEdgeAttributes(tempGraphDataTeamOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataTeamOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataTeamTwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataTeamQuarter);  
        setNodeAndEdgeAttributes(tempGraphDataTeamYear); 
        
        setNodeAndEdgeAttributes(tempGraphDataPersonalDay );
        setNodeAndEdgeAttributes(tempGraphDataPersonalWeek );
        setNodeAndEdgeAttributes(tempGraphDataPersonalMonth);   
        setNodeAndEdgeAttributes(tempGraphDataPersonalOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataPersonalOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataPersonalTwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataPersonalQuarter);    
        setNodeAndEdgeAttributes(tempGraphDataPersonalYear);   

        setNodeAndEdgeAttributes(tempGraphDataDEIDay );
        setNodeAndEdgeAttributes(tempGraphDataDEIWeek );
        setNodeAndEdgeAttributes(tempGraphDataDEIMonth);   
        setNodeAndEdgeAttributes(tempGraphDataDEIOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataDEIOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataDEITwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataDEIQuarter);    
        setNodeAndEdgeAttributes(tempGraphDataDEIYear);   

        setNodeAndEdgeAttributes(tempGraphDataCSDay );
        setNodeAndEdgeAttributes(tempGraphDataCSWeek );
        setNodeAndEdgeAttributes(tempGraphDataCSMonth);   
        setNodeAndEdgeAttributes(tempGraphDataCSOneWeek);
        setNodeAndEdgeAttributes(tempGraphDataCSOneMonth);
        setNodeAndEdgeAttributes(tempGraphDataCSTwoMonths);
        setNodeAndEdgeAttributes(tempGraphDataCSQuarter);    
        setNodeAndEdgeAttributes(tempGraphDataCSYear);  
        */

        //Store time-series data pulled from the transactions
        // if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Day:", tempTimeArrayByDay);
        // if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Weekly:", tempTimeArrayByWeek);
        // if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Monthly:", tempTimeArrayByMonth);
        // if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Quarterly:", tempTimeArrayByQuarter);
        // if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Yearly:", tempTimeArrayByYear);
        
        
        //Store user-specific data pulled from the transactions
        // console.log ("Built user-specific data from transactions:", userNumbers);
        
        //Generate all time series graphs
        generateTimeSeriesData (customer, tempTimeArrayByDay, tempTimeArrayByWeek, tempTimeArrayByMonth, tempTimeArrayByQuarter, tempTimeArrayByYear);

       // At this point, the data in graph format is stored in the global variables for capture by the client device
        
        return {
            calculatedUserNumbers:userNumbers, 
            calculatedTeamNumbers:teamNumbers, 
            calculatedCustomerWideAnalytics:customerWideAnalytics, 
            calculatedTimeSeriesGraphDataDay:tempTimeArrayByDay, 
            calculatedTimeSeriesGraphDataWeek:tempTimeArrayByWeek, 
            calculatedTimeSeriesGraphDataMonth:tempTimeArrayByMonth, 
            calculatedTimeSeriesGraphDataQuarter:tempTimeArrayByQuarter, 
            calculatedTimeSeriesGraphDataYear:tempTimeArrayByYear,

            //Return the enterprise network graphs
            //Note,these are based on filtered transactions so the ALL graph contains the composite values
            calculatedGraphDataAllDay: tempGraphDataAllDay,
            calculatedGraphDataAllWeek: tempGraphDataAllWeek,
            calculatedGraphDataAllMonth    : tempGraphDataAllMonth,
            calculatedGraphDataAllOneWeek: tempGraphDataAllOneWeek,
            calculatedGraphDataAllOneMonth: tempGraphDataAllOneMonth,
            calculatedGraphDataAllTwoMonths: tempGraphDataAllTwoMonths,
            calculatedGraphDataAllQuarter: tempGraphDataAllQuarter,
            calculatedGraphDataAllYear: tempGraphDataAllYear,
            calculatedGraphDataAllEntirePeriod: tempGraphDataAllEntirePeriod,
            customerNodeArray: customerNodeArray,
        };
      
    }
 
 
 
 
 
     //Reset stats each customer loop    
    function initializeAnalyticsStructures(customer) {
      //Safety check
      if (!customer || !customer.id) {
        if (DEBUG_MODE) console.error("Error - no customer for which to initialize customer analtyics");
      }

        customerNodeArray = [];
        userNumbers = [];
        teamNumbers = [];
        customerWideAnalytics = {...customerWideAnalyticsInitialState}; //Make a copy so we are not modifying the initial state
        edgesAlreadyAddedForScheduledConectivities = [];

        //IDs used by client-side to track the different graphs as they are created; also used by Lambda to store in DB
        tempGraphDataAllDay = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllMonth     = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllTwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};
        tempGraphDataAllEntirePeriod = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"ALL", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"ALL"};

        tempGraphDataBalanceDay = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};
        tempGraphDataBalanceWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};   
        tempGraphDataBalanceMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};  
        tempGraphDataBalanceOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};
        tempGraphDataBalanceOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};
        tempGraphDataBalanceTwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};
        tempGraphDataBalanceQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};
        tempGraphDataBalanceYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"BALANCE"};
        
        tempGraphDataSocialDay = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};
        tempGraphDataSocialWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};
        tempGraphDataSocialMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};
        tempGraphDataSocialOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};
        tempGraphDataSocialOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};
        tempGraphDataSocialTwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};
        tempGraphDataSocialQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"};  
        tempGraphDataSocialYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"SOCIAL"}; 
        
        tempGraphDataTeamDay  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};
        tempGraphDataTeamWeek   = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};
        tempGraphDataTeamMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};   
        tempGraphDataTeamOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};
        tempGraphDataTeamOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};
        tempGraphDataTeamTwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};
        tempGraphDataTeamQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"};  
        tempGraphDataTeamYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"TEAM"}; 
        
        tempGraphDataPersonalDay  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};
        tempGraphDataPersonalWeek  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};
        tempGraphDataPersonalMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};   
        tempGraphDataPersonalOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};
        tempGraphDataPersonalOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};
        tempGraphDataPersonalTwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};
        tempGraphDataPersonalQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};    
        tempGraphDataPersonalYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"PERSONAL"};    
        
        tempGraphDataDEIDay  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};
        tempGraphDataDEIWeek  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};
        tempGraphDataDEIMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};   
        tempGraphDataDEIOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};
        tempGraphDataDEIOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};
        tempGraphDataDEITwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};
        tempGraphDataDEIQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};    
        tempGraphDataDEIYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"DEI"};    
        
        tempGraphDataCSDay  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"DAY", periodStart: dayStart.toISOString(), periodEnd: NOW_STRING(), category:"CS"};
        tempGraphDataCSWeek  = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"WEEK", periodStart: weekStart.toISOString(), periodEnd: NOW_STRING(), category:"CS"};
        tempGraphDataCSMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"MONTH", periodStart: monthStart.toISOString(), periodEnd: NOW_STRING(), category:"CS"};   
        tempGraphDataCSOneWeek = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1W", periodStart: oneWeekAgo.toISOString(), periodEnd: NOW_STRING(), category:"CS"};
        tempGraphDataCSOneMonth = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"1M", periodStart: oneMonthAgo.toISOString(), periodEnd: NOW_STRING(), category:"CS"};
        tempGraphDataCSTwoMonths = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"2M", periodStart: twoMonthsAgo.toISOString(), periodEnd: NOW_STRING(), category:"CS"};
        tempGraphDataCSQuarter = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"QUARTER", periodStart: quarterStart.toISOString(), periodEnd: NOW_STRING(), category:"CS"};    
        tempGraphDataCSYear = {id:getNewID(), nodes: [], edges: [], maxEdgeCount: 0, customerID:customer.id,  period:"YEAR", periodStart: yearStart.toISOString(), periodEnd: NOW_STRING(), category:"CS"};    


       timeSeriesGraphDataDay = [];
       timeSeriesGraphDataWeek = [];
       timeSeriesGraphDataMonth = [];
       timeSeriesGraphDataQuarter  = [];
       timeSeriesGraphDataYear = [];
    
    }

    //This functions generates all the DB records for the customer currenty being processed (customerID)
    //based on the calculated data structures
  
    export async function storeAnalytics(customer) {
        
        //Store User Numbers in the "UserAnalytics" table; each user has a corresponding record

        for (var i=0; i < userNumbers.length; i++) {
            
          try {

                //Write object to user analytics record
                const response = await invokeAPI(createUserAnalytics, 'createUserAnalytics', userNumbers[i]);
                if (DEBUG_MODE >= 2) console.log("Successfully wrote new user analytics record ", response);
                
                
            } catch (err) {
                if (DEBUG_MODE >= 2) console.log('error writing user record:', err);
            }
 
        }
        

        //Store Team Numbers in the "TeamAnalytics" table; each team has a corresponding record

        for (var i=0; i < teamNumbers.length; i++) {
            
          try {

                //Write object to team analytics record
                 const response = await invokeAPI(createTeamAnalytics, 'createTeamAnalytics', teamNumbers[i]);
                // if (DEBUG_MODE >= 2) console.log("Successfully wrote new team analytics record ", response);

                
            } catch (err) {
                if (DEBUG_MODE >= 2) console.log('error writing team record:', err);
            }
 
        }
          
        //Construct and store object containing customer-wide analytics, like averages, max, min, etc
        try {        
        
            
            //Set certain data for the combined record            
            customerWideAnalytics.name = customer.name;
            customerWideAnalytics.customerID = customer.id;

            //Store the customer analytics record
            const response = await invokeAPI(createCustomerAnalytics,'createCustomerAnalytics', customerWideAnalytics);
            // if (DEBUG_MODE >= 2) console.log("Successfully wrote customer-wide analytics record " + response);

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error writing customer analytics record:', err, customerWideAnalytics);
        }        
        
        //Store all the Graph Data for this company, including the master set of nodes
        
        //Store Nodes for the customer
        if (customerNodeArray) {
            for (var i=0; i < customerNodeArray.length; i++) {
                
                const record = {...customerNodeArray[i],
                    // graphID:tempInsertedGraph.id,    //NO LONGER TIED TO A SPECIFIC GRAPH
                    customerID: customer.id,
                    edgeCount:0,    //RESET to zero since node is now graph independent
                };
    
                var tempInsertedGraphNode = await invokeAPI(createGraphNode, 'createGraphNode', record);
                if (DEBUG_MODE >= 2) console.log('Successfully wrote new graph node', tempInsertedGraphNode);
                
            }
        }
      
        
        // Note 7.16.2022 - Not storing full YTD or QTD graphs to DB as simply too big and expensive at this point
        await invokeCreateGraph(tempGraphDataAllDay);
        await invokeCreateGraph(tempGraphDataAllWeek);
        await invokeCreateGraph(tempGraphDataAllMonth);
        await invokeCreateGraph(tempGraphDataAllOneWeek);
        await invokeCreateGraph(tempGraphDataAllOneMonth);
        await invokeCreateGraph(tempGraphDataAllTwoMonths);
        // await invokeCreateGraph(tempGraphDataAllQuarter);
        // await invokeCreateGraph(tempGraphDataAllYear);
        // await invokeCreateGraph(tempGraphDataAllEntirePeriod);
        
        await invokeCreateGraph(tempGraphDataBalanceDay);
        await invokeCreateGraph(tempGraphDataBalanceWeek);   
        await invokeCreateGraph(tempGraphDataBalanceMonth);  
        await invokeCreateGraph(tempGraphDataBalanceOneWeek);
        await invokeCreateGraph(tempGraphDataBalanceOneMonth);
        await invokeCreateGraph(tempGraphDataBalanceTwoMonths);
        // await invokeCreateGraph(tempGraphDataBalanceQuarter);
        // await invokeCreateGraph(tempGraphDataBalanceYear);
        
        await invokeCreateGraph(tempGraphDataSocialDay);
        await invokeCreateGraph(tempGraphDataSocialWeek);
        await invokeCreateGraph(tempGraphDataSocialMonth);
        await invokeCreateGraph(tempGraphDataSocialOneWeek);
        await invokeCreateGraph(tempGraphDataSocialOneMonth);
        await invokeCreateGraph(tempGraphDataSocialTwoMonths);
        // await invokeCreateGraph(tempGraphDataSocialQuarter);  
        // await invokeCreateGraph(tempGraphDataSocialYear); 
        
        await invokeCreateGraph(tempGraphDataTeamDay);
        await invokeCreateGraph(tempGraphDataTeamWeek );
        await invokeCreateGraph(tempGraphDataTeamMonth);   
        await invokeCreateGraph(tempGraphDataTeamOneWeek);
        await invokeCreateGraph(tempGraphDataTeamOneMonth);
        await invokeCreateGraph(tempGraphDataTeamTwoMonths);
        // await invokeCreateGraph(tempGraphDataTeamQuarter);  
        // await invokeCreateGraph(tempGraphDataTeamYear); 
        
        await invokeCreateGraph(tempGraphDataPersonalDay);
        await invokeCreateGraph(tempGraphDataPersonalWeek);
        await invokeCreateGraph(tempGraphDataPersonalMonth);   
        await invokeCreateGraph(tempGraphDataPersonalOneWeek);
        await invokeCreateGraph(tempGraphDataPersonalOneMonth);
        await invokeCreateGraph(tempGraphDataPersonalTwoMonths);
        // await invokeCreateGraph(tempGraphDataPersonalQuarter);    
        // await invokeCreateGraph(tempGraphDataPersonalYear);    
        
        await invokeCreateGraph(tempGraphDataDEIDay);
        await invokeCreateGraph(tempGraphDataDEIWeek);
        await invokeCreateGraph(tempGraphDataDEIMonth);   
        await invokeCreateGraph(tempGraphDataDEIOneWeek);
        await invokeCreateGraph(tempGraphDataDEIOneMonth);
        await invokeCreateGraph(tempGraphDataDEITwoMonths);
        // await invokeCreateGraph(tempGraphDataDEIQuarter);    
        // await invokeCreateGraph(tempGraphDataDEIYear);    
        
        await invokeCreateGraph(tempGraphDataCSDay);
        await invokeCreateGraph(tempGraphDataCSWeek);
        await invokeCreateGraph(tempGraphDataCSMonth);   
        await invokeCreateGraph(tempGraphDataCSOneWeek);
        await invokeCreateGraph(tempGraphDataCSOneMonth);
        await invokeCreateGraph(tempGraphDataCSTwoMonths);
        // await invokeCreateGraph(tempGraphDataCSQuarter);    
        // await invokeCreateGraph(tempGraphDataCSYear);    
        
        
        await invokeCreateTimeSeriesGraph(timeSeriesGraphDataDay);
        await invokeCreateTimeSeriesGraph(timeSeriesGraphDataWeek);
        await invokeCreateTimeSeriesGraph(timeSeriesGraphDataMonth);
        await invokeCreateTimeSeriesGraph(timeSeriesGraphDataQuarter);
        await invokeCreateTimeSeriesGraph(timeSeriesGraphDataYear);
  }   
  
    async function invokeCreateGraph(graph) {
        
        // if (DEBUG_MODE >= 2) console.log("Storing network graph:", graph);
        
        //Before storing the graph records, pair down the graph to eliminate any redundant edges between the same two nodes
        //We need only a single edge
        
        reduceGraphEdges(graph);
        
        try {

            //Store the graph itself        
            const graphDataRecord = 
            {
                customerID:graph.customerID, 
                period:graph.period, 
                periodStart: graph.periodStart, 
                periodEnd: graph.periodEnd, 
                category:graph.category,
                maxEdgeCount: 0,    //RESET to zero since node is now graph independent
                // maxEdgeCount: graph.maxEdgeCount,
                numEdges: graph.edges.length,
                // numNodes: graph.nodes.length,
                numNodes: customerNodeArray.length,     //Master node list
    
            };   
            
            var tempInsertedGraph = await invokeAPI(createGraph, 'createGraph', graphDataRecord);
            // if (DEBUG_MODE >= 2) console.log('Successfully wrote new graph', tempInsertedGraph);
        
            //Store Edges
            for (var i=0; i < graph.edges.length; i++) {
                
                const record =  {...graph.edges[i],
                    graphID:tempInsertedGraph.id,
                    customerID: graph.customerID,
                };
                
                var tempInsertedGraphEdge = await invokeAPI(createGraphEdge, 'createGraphEdge', record);
                // if (DEBUG_MODE >= 2) console.log('Successfully wrote new graph edge', tempInsertedGraphEdge);
                
            }
	
     
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error storing graph", err, graph);
        }
    }

  async function invokeCreateTimeSeriesGraph(graph) {
    
        // if (DEBUG_MODE >= 2) console.log("Creating time series graph:", graph);

        if (!graph.values)  {
            if (DEBUG_MODE >= 2) console.log("ERROR - NO VALUES IN GRAPH:", graph);
            return;
        }

        try {

            //Store the graph itself        
            const record = 
            {
                customerID:graph.customerID, 
                period:graph.period, 
                periodStart: graph.periodStart, 
                periodEnd: graph.periodEnd, 
            };   

            var tempInsertedGraphTimeSeries = await invokeAPI(createGraphTimeSeries, 'createGraphTimeSeries', record);
            // if (DEBUG_MODE >= 2) console.log('Successfully wrote new graph', tempInsertedGraphTimeSeries);
            
    
            //Store graph values
            for (var i=0; i < graph.values.length; i++) {
                
                const record = {...graph.values[i],
                    graphID:tempInsertedGraphTimeSeries.id,
                    customerID: graph.customerID,
                };
                 
                var tempInsertedGraphTimeSeriesValue = await invokeAPI(createGraphTimeSeriesValue, 'createGraphTimeSeriesValue', record);
                // if (DEBUG_MODE >= 2) console.log('Successfully wrote time series graph value', tempInsertedGraphTimeSeriesValue);
            }

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error storing time series graph", err, graph);
        }
    }    
   
   
   //
   // Simplified function that only generates the time-series graphs; used by client-side reporting
   //
   
/*   
    export async function calcTimeSeriesGraphs ({transactions}) {


        const tempTimeArrayByDay = [];
        const tempTimeArrayByWeek = [];
        const tempTimeArrayByMonth = [];
        const tempTimeArrayByQuarter = [];
        const tempTimeArrayByYear = [];

        if (transactions && transactions.length > 0) {
            
            try {
                if (DEBUG_MODE >= 2) console.log("Starting to process transactions for Time Series Graphs");
    
    
            
                var tempXKey = "";
                
                //Now, walk the entire list of transaction O(N) in FORWARD chronological order and update analytics
                for (var j = 0; j < transactions.length; j++){
                    
                    // if (DEBUG_MODE >= 2) console.log("processing transaction at index:", j);
        
                    
                    if (transactions[j].transactionType === "COMPLETED_CONECTIVITY" || transactions[j].transactionType == "SPOTLIGHT_SENT" || transactions[j].transactionType == "SPOTLIGHT_RECEIVED") {
                        
                        // if (DEBUG_MODE >= 2) console.log("processing transaction at index:", transactions[j]);
        
                        //UPDATE TIME SERIES GRAPH DATA
                
                        //Update DAILY time series bucket
                        tempXKey = getXKey(transactions[j].createdAt, "DAILY");
                        updateTimeSeriesArrayElement(tempTimeArrayByDay, tempXKey, transactions[j],"DAY");
                
                        //Update WEEKLY time series bucket
                        tempXKey = getXKey(transactions[j].createdAt, "WEEKLY");
                        updateTimeSeriesArrayElement(tempTimeArrayByWeek, tempXKey, transactions[j],"WEEK");
                
                        //Update MONTHLY time series bucket
                        tempXKey = getXKey(transactions[j].createdAt, "MONTHLY");
                        updateTimeSeriesArrayElement(tempTimeArrayByMonth, tempXKey, transactions[j],"MONTH");
                
                        //Update QUARTERLY time series bucket
                        tempXKey = getXKey(transactions[j].createdAt, "QUARTERLY");
                        updateTimeSeriesArrayElement(tempTimeArrayByQuarter, tempXKey, transactions[j],"QUARTER");
                
                        //Update YEARLY time series bucket
                        tempXKey = getXKey(transactions[j].createdAt, "YEARLY");
                        updateTimeSeriesArrayElement(tempTimeArrayByYear, tempXKey, transactions[j],"YEAR");
        
                    }
                }
        
                //Store time-series data pulled from the transactions
                if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Day:", tempTimeArrayByDay);
                if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Weekly:", tempTimeArrayByWeek);
                if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Monthly:", tempTimeArrayByMonth);
                if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Quarterly:", tempTimeArrayByQuarter);
                if (DEBUG_MODE >= 2) console.log("Built Time Series Data - Yearly:", tempTimeArrayByYear);
                
    
            } catch (err) {
                if (DEBUG_MODE >= 2) console.log("Error building time series graphs", err);
            }

        }
        
        // At this point, the data in graph format is stored in the global variables for capture by the client device
        
        return {
            calculatedTimeSeriesGraphDataDay:tempTimeArrayByDay, 
            calculatedTimeSeriesGraphDataWeek:tempTimeArrayByWeek, 
            calculatedTimeSeriesGraphDataMonth:tempTimeArrayByMonth, 
            calculatedTimeSeriesGraphDataQuarter:tempTimeArrayByQuarter, 
            calculatedTimeSeriesGraphDataYear:tempTimeArrayByYear
        };
    }
*/    
    
    //Function for displaying the image in the Legend
    function renderCustomItem({tooltipItem, legendItem, entry, index, isSmall}) {

        if (!entry) return null;
        
        if (!tooltipItem && !legendItem) return null;
        
        const switchOn = (legendItem ? entry.value : entry.name);
        var itemColor = entry.color;
        if (tooltipItem && itemColor == "white") itemColor = '#409cf7';
        var height="40px";
        var width="40px";
        var paddingLeft="1.0rem";
        var boxShadow = "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px";
        var containerPadding = "5px 10px";
        if (isSmall) {
            height="25px";
            width="25px";
            paddingLeft="2px";
            boxShadow = "none";
            containerPadding = "5px 5px";
        }
        
        switch(switchOn) {
            
           case "conectivities":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}   alt=''/>  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Conectivities${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                </div>
                );            
            case "Balance":
            case "stressConectivities":
            case "stressPeriodConectivitiesCompleteDay":
            case "stressPeriodConectivitiesCompleteWeek":
            case "stressPeriodConectivitiesCompleteMonth":
            case "stressPeriodConectivitiesCompleteQuarter":
            case "stressPeriodConectivitiesCompleteYear":
            case "stressCoinsEarned":
            case "stressPeriodCoinsEarnedDay":
            case "stressPeriodCoinsEarnedWeek":
            case "stressPeriodCoinsEarnedQuarter":
            case "stressPeriodCoinsEarnedMonth":
            case "stressPeriodCoinsEarnedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH}  />  
                    </div>
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Balance${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                 </div> 

                );
            case "Team":
            case "teamConectivities":
            case "teamPeriodConectivitiesCompleteDay":
            case "teamPeriodConectivitiesCompleteWeek":
            case "teamPeriodConectivitiesCompleteMonth":
            case "teamPeriodConectivitiesCompleteQuarter":
            case "teamPeriodConectivitiesCompleteYear":
            case "teamCoinsEarned":
            case "teamPeriodCoinsEarnedDay":
            case "teamPeriodCoinsEarnedWeek":
            case "teamPeriodCoinsEarnedQuarter":
            case "teamPeriodCoinsEarnedMonth":
            case "teamPeriodCoinsEarnedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH}  />  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Team${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                </div>
                );
            case "Social":
            case "socialConectivities":
            case "socialPeriodConectivitiesCompleteDay":
            case "socialPeriodConectivitiesCompleteWeek":
            case "socialPeriodConectivitiesCompleteMonth":
            case "socialPeriodConectivitiesCompleteQuarter":
            case "socialPeriodConectivitiesCompleteYear":
            case "socialCoinsEarned":
            case "socialPeriodCoinsEarnedDay":
            case "socialPeriodCoinsEarnedWeek":
            case "socialPeriodCoinsEarnedQuarter":
            case "socialPeriodCoinsEarnedMonth":
            case "socialPeriodCoinsEarnedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH}  />  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Social${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                </div>
                );
            case "Growth":
            case "individualConectivities":
            case "individualPeriodConectivitiesCompleteDay":
            case "individualPeriodConectivitiesCompleteWeek":
            case "individualPeriodConectivitiesCompleteMonth":
            case "individualPeriodConectivitiesCompleteQuarter":
            case "individualPeriodConectivitiesCompleteYear":
            case "individualCoinsEarned":
            case "individualPeriodCoinsEarnedDay":
            case "individualPeriodCoinsEarnedWeek":
            case "individualPeriodCoinsEarnedQuarter":
            case "individualPeriodCoinsEarnedMonth":
            case "individualPeriodCoinsEarnedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH}  />  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Growth${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                </div>
                );
              case "amount":
              case "coinsReceivedDay":
              case "coinsReceivedWeek":
              case "coinsReceivedMonth":
              case "coinsReceivedQuarter":
              case "coinsReceivedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  />  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Coins${tooltipItem ? ": " + entry.value : ""}`}
                     </div>
                </div>
                );                  

            case "badgesDEIReceived":
            case "badgesDEIReceivedDay":
            case "badgesDEIReceivedWeek":
            case "badgesDEIReceivedMonth":
            case "badgesDEIReceivedQuarter":
            case "badgesDEIReceivedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  />  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`DEI Badges${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                </div>
                );
            case "badgesCSReceived":
            case "badgesCSReceivedDay":
            case "badgesCSReceivedWeek":
            case "badgesCSReceivedMonth":
            case "badgesCSReceivedQuarter":
            case "badgesCSReceivedYear":
                return (
                <div className="ContainerNoHeightCenter" key={`item-${index}`} style={{padding:containerPadding}}>
                     <div className="imageContainer radarChartImage" style={{boxShadow:boxShadow, width:width, height:height}} >
                        <img  className="imageCenterPortrait" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  />  
                    </div>               
                    <div className='TextStyle3' style={{paddingLeft:paddingLeft, color:itemColor}}>
                        {`Community Service Badges${tooltipItem ? ": " + entry.value : ""}`}
                      </div>
                </div>
                );
            default:
                return null;
        }
    }
    
    //Our custom render LEGEND function; called by Recharts
     export const renderOurRechartLegend = (props) => {
          const { payload } = props;

        //   if (DEBUG_MODE >= 2) console.log("Render Legend called", payload);

        if (payload && payload.length) {
          return (
            <div className="ContainerNoHeightCenter" style={{flexWrap:"wrap", zIndex:"99"}}>
              {
                payload.map((entry, index) => (
                    renderCustomItem({tooltipItem:false, legendItem:true, entry:entry, index:index})
                ))
              }
            </div>
          );

        }
        
        return null;
    };    
    

    //Our custom render LEGEND function; called by Recharts
     export const renderOurRechartLegendFixedWidth = (props) => {
          const { payload } = props;

        //   if (DEBUG_MODE >= 2) console.log("Render Legend called", payload);

        if (payload && payload.length) {
          return (
            <div className="ContainerNoHeightCenter" style={{flexWrap:"wrap", maxWidth:"100%"}}>
              {
                payload.map((entry, index) => (
                    renderCustomItem({tooltipItem:false, legendItem:true, entry:entry, index:index, isSmall:true})
                ))
              }
            </div>
          );
        }
        
        return null;
    };       
    
    //Our custom render TOOLTIP function; called by Recharts
     export const renderRechartTooltip = ({active, payload, label}) => {

        if (active && payload && payload.length) {

        //   if (DEBUG_MODE >= 2) console.log("Render Tooltip called", active, payload, label);

          //Is this graph happen to be user analyics?  If so, use the full name rather than the label (initials) for the tooltip          
          var ourTooltipLabel = label;
          if (payload[0].payload)
            if (payload[0].payload.name) ourTooltipLabel =  payload[0].payload.name;

          return (
            <div className="ContainerVerticalCenter" 
                    style={{backgroundColor:"white", 
                            borderRadius:"10px", 
                            border:"1px solid grey", 
                            padding:"10px",
                            boxShadow:"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            zIndex:"9999"
                    }}>
            
                <div style={{}}>{ourTooltipLabel} </div>
                <div style={{width:"50%", margin:"1px 0px", borderBottom:"1px solid #a1a6b5"}}></div>
                <div className="ContainerVerticalLeft" >
                      {
                        payload.map((entry, index) => (
                            renderCustomItem({tooltipItem:true, legendItem:false, entry:entry, index:index})
                        ))
                      }
                </div>
            </div>
          );
        }
        
        return null;
    };    
    
    
    //Our custom render TICK function; called by Recharts
     export const renderOurRechartTick = ({x, y, stroke, payload}) => {

          if (DEBUG_MODE >= 2) console.log("Render TICK called", x, y, stroke, payload);

        if (payload && payload.value) {
          return (

              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                  {payload.value}
                </text>
              </g>
          );

        }
        
        return null;
    };  
    

function compareProgressDataByDay(a, b) {
    
        if (a.dayCoinsEarned > b.dayCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return 1;
            }
            
        if (a.dayCoinsEarned < b.dayCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
}   

function compareProgressDataByWeek(a, b) {
    
        if (a.weekCoinsEarned > b.weekCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return -1;
            }
            
        if (a.weekCoinsEarned < b.weekCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return 1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
}  

function compareProgressDataByMonth(a, b) {
    
        if (a.monthCoinsEarned > b.monthCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return -1;
            }
            
        if (a.monthCoinsEarned < b.monthCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return 1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
}  

function compareProgressDataByQuarter(a, b) {
    
        if (a.quarterCoinsEarned > b.quarterCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return -1;
            }
            
        if (a.quarterCoinsEarned < b.quarterCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return 1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
}  

function compareProgressDataByYear(a, b) {
    
        if (a.yearCoinsEarned > b.yearCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return -1;
            }
            
        if (a.yearCoinsEarned < b.yearCoinsEarned) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return 1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
}  

export function sortUserProgressDataRecords(userProgressDataRecords, period) {
    
    if (!userProgressDataRecords || !period) return [];
    
    //Make a scratch copy
    const tempUserProgressDataRecordsToProcess = [...userProgressDataRecords];
    
	switch(period) {
		case "DAY":
			tempUserProgressDataRecordsToProcess.sort(compareProgressDataByDay);
			break;
		case "WEEK":
			tempUserProgressDataRecordsToProcess.sort(compareProgressDataByWeek);
			break;
		case "MONTH":
			tempUserProgressDataRecordsToProcess.sort(compareProgressDataByMonth);
			break;
		case "QUARTER":
			tempUserProgressDataRecordsToProcess.sort(compareProgressDataByQuarter);
			break;
		case "YEAR":
			tempUserProgressDataRecordsToProcess.sort(compareProgressDataByYear);
			break;			
	}
	
	return tempUserProgressDataRecordsToProcess;
}

export function compareLeaderboardSpotsByCurrentBalance(a, b) {

    if (a.currentBalance > b.currentBalance) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return -1;
        }
    if (a.currentBalance < b.currentBalance) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return 1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}    



export async function updateLeaderboardSpot({existingSlotID, user, period, userProgressDataRecord, newRank, userMove}) {

	//Grab the balance to write to the SPOT based on the period	
	var currentBalance = 0;
	switch(period) {
		case "DAY":
			currentBalance = userProgressDataRecord.dayCoinsEarned;
			break;
		case "WEEK":
			currentBalance = userProgressDataRecord.weekCoinsEarned;
			break;
		case "MONTH":
			currentBalance = userProgressDataRecord.monthCoinsEarned;
			break;
		case "QUARTER":
			currentBalance = userProgressDataRecord.quarterCoinsEarned;
			break;
		case "YEAR":
			currentBalance = userProgressDataRecord.yearCoinsEarned;
			break;			
	}	
	
	const tempNewLeaderBoardSpot = {
	    id:existingSlotID,
		currentBalance: currentBalance,	
		userRank:newRank,
		userMove:userMove,           //+-movement on LeaderBoard for this person since in the last update of the LeaderBoard
    };



    try {
    
        const response = await invokeAPI(updateLeaderBoardSpot, 'updateLeaderBoardSpot', tempNewLeaderBoardSpot);
        if (DEBUG_MODE >= 2) console.log("Successfully updated leaderboard spot", response);
     
    } catch (err) {
        if (DEBUG_MODE >= 2) console.log("Error writing new leaderboard spot", err);

    }	
}


//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//config
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE,  COLOR_WHITE} from '../../data/conectereConfigData';

//Amplify, React
import React, { memo, useEffect, useState, useContext } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { DisplayContext } from '../../context/displayContext';            
import { ModalContext } from '../../context/modalContext';            

//Our components
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import StarRatings from "../../Components/starRatings/starRatings";

//Utils
import {invokeProcessConectivity, displayConectivityHeader, setHeaderColorClassByCategory, setConectivityImageUrlByCategory, setHeaderColorByScheduledConectivity, setBackgroundColorClassByCategory } from "../../utils/conectivityUtils";
import { DisplayUserSelectComponent, DisplayTeamSelectComponent } from "../../utils/userAndTeamUtils";
import { Divider } from "../../utils/generalUtils";


//Utils

// - scheduledConectivityToProcess - required
// - launchRuleToEdit - only present if the SC being complete has a LR or invitation rather than just individually
export const ModalConectivityComplete = ({showModal, launchRule, scheduledConectivityToProcess, conectivityAction, handleClose }) => {

	// Context
	const { darkMode, setShowSpinner } = useContext(DisplayContext); 
	const { currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);    
	const { users, teams, teamsOptions, participatingUsersOptions } = useContext(CustomerContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle,  setModalBonusLabel,  setModalBonusHeaderColor, setModalBonusMessage, setModalBonusAmount, setShowModalBonusDelayed,  } = useContext(ModalContext); 
	
	//local state
	const [selectedParticipatingUsers, setSelectedParticipatingUsers] = useState([]);     //For DONE selections
	const [selectedParticipatingTeams, setSelectedParticipatingTeams] = useState([]);     //For DONE selections
	const [modalHeaderColor, setModalHeaderColor] = useState("");
	const [userConectivityRating, setUserConectivityRating] = useState(5); //Range of 1 - 5 if entered by user; default to 5 star review

	//Init
	useEffect(() => {

		if (!showModal || !scheduledConectivityToProcess || !scheduledConectivityToProcess.conectivity ) {
			setSelectedParticipatingUsers([]); setSelectedParticipatingTeams([]);
			return;
		}

		init();	//Init the modal
	
	},[showModal, launchRule, scheduledConectivityToProcess])

	async function init() {
		if (DEBUG_MODE >1) console.log("Initializing Conectivity Complete Modal", showModal, launchRule, scheduledConectivityToProcess, conectivityAction);

		//To aid the user, let's pre-load the dropdown selections based on LR Teams and Users		
		let tempParticipatingUsersOptions = []; 	
		let tempParticipatingTeamsOptions = [];    
		if (launchRule && launchRule.usersToInvite && launchRule.usersToInvite.length > 0) {
			for (let i=0 ; i < launchRule.usersToInvite.length; i++) {
				//Look in the array of Participating Users Options (which excludes the current user) to find the existing dropdown option for the invited user
				const userOption = participatingUsersOptions.find(userOption => userOption.id === launchRule.usersToInvite[i]);
				if (userOption) {
					tempParticipatingUsersOptions.push(userOption); 
					if (DEBUG_MODE >= 2) console.log("preloaded user options", userOption, tempParticipatingUsersOptions);
				}
			}
		} //End if invitees
		

		if (launchRule && launchRule.teamsToInvite && launchRule.teamsToInvite.length > 0) {
			for (let i=0 ; i < launchRule.teamsToInvite.length; i++) {	
				//Look in the array of Team Options to find the existing dropdown option for the team ID stored as an invitee on this particular invitation
				const teamOption = teamsOptions.find(teamOption => teamOption.id === launchRule.teamsToInvite[i]);
				if (teamOption) {
					tempParticipatingTeamsOptions.push(teamOption); 
					if (DEBUG_MODE >= 2) console.log("prelaoded team options", teamOption, tempParticipatingTeamsOptions);
				}
			}
		}
		

		//Update pre-loaded data; store emptly arrays if blank     
		setSelectedParticipatingUsers(tempParticipatingUsersOptions);  //Pre-load participating USERS
		setSelectedParticipatingTeams(tempParticipatingTeamsOptions);  //Pre-load participating TEAMS	
		setModalHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivityToProcess));
	}

	//   
	//Main function for processing a Conectivity that the user has completed
	//Also handles REJECTED conectivity
	//
	
	async function handleConfirmation(e) {
		if (e) e.preventDefault();
		//Safety check
		if (!conectivityAction || !scheduledConectivityToProcess) {
			if (DEBUG_MODE) console.error("Error - improper params to mark conectivity as complete", scheduledConectivityToProcess, conectivityAction );
			return;
		}
		
		setShowSpinner(true); //Show spinners
		try {    
			var params = {
				user:currentUser,
				command:conectivityAction,
				scheduledConectivity:scheduledConectivityToProcess,
				conectivity:scheduledConectivityToProcess.conectivity,
				usersThatParticipatedWithMe:[],
				teamsThatParticipatedWithMe:[], 
				userRating:userConectivityRating,
			};
			
			
			//Handling a completed conectivity?  If so, construct an array of user IDs and an array of team IDs that participated in the Conectivity 
			//Loop through participating user options and construct an array of user IDs
			//Skip any ID that happens to be the same as the user recording the transaction
			if (conectivityAction==="COMPLETED_CONECTIVITY") {
				//Construct array of user IDs that participated in the conectivity
				if (selectedParticipatingUsers) {
					for (var j=0 ; j < selectedParticipatingUsers.length; j++) {
						//if (DEBUG_MODE >= 2) console.log("Adding selected user:", selectedParticipatingUsers[j]);
						if (selectedParticipatingUsers[j].id !==currentUser.id) params.usersThatParticipatedWithMe.push (selectedParticipatingUsers[j].id);
					}
				}
				
				//Construct an array of IDs of teams that participated in the conectivity 
				if (selectedParticipatingTeams) {
					for (var j=0 ; j < selectedParticipatingTeams.length; j++) {
						//if (DEBUG_MODE >= 2) console.log("Adding selected team:", selectedParticipatingTeams[j]);
						params.teamsThatParticipatedWithMe.push (selectedParticipatingTeams[j].id);
					}
				}
			} //End if COMPLETED CONECTICITY
			const returnObject = await invokeProcessConectivity(params);            
			if (DEBUG_MODE >= 2) console.log("Process Conectivity Lambda returned", returnObject);        
			
			//Did the user close a ring and earn a bonus?
			if (returnObject && returnObject.returnParams && returnObject.returnParams.successFlag && returnObject.returnParams.bonusGenerated) {
				if (DEBUG_MODE >= 2) console.log("BONUS earned", returnObject.returnParams.bonusMemo, returnObject.returnParams.bonusAmount);        
				setModalBonusLabel(scheduledConectivityToProcess.conectivity.category.label);
				setModalBonusMessage(returnObject.returnParams.bonusMemo);
				setModalBonusAmount(returnObject.returnParams.bonusAmount);
				setModalBonusHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivityToProcess)); 

				//Set a timer to trigger a modal after animation has closed ring 
				setShowModalBonusDelayed(true);              
			} 
			//Did the lambda return an error?
			else if (returnObject && returnObject.returnParams && returnObject.returnParams.successFlag === false) {
				setModalGeneralTitle("Ooops!");
				setModalGeneralMessage('Something did not go quite right...');
				setShowModalGeneral(true);
			}
		}
		
		catch (err) {
			if (DEBUG_MODE >= 1) console.log('error creating marking conectivity as complete:', err);
			setModalGeneralTitle("Ooops!");
			setModalGeneralMessage('Something did not go quite right...' + err);
			setShowModalGeneral(true);
		}
		setShowSpinner(false); //Hide spinners
		handleClose();	//Tell the parent to close us
	}
		

	//Render function
	if (!showModal || !scheduledConectivityToProcess || !scheduledConectivityToProcess.conectivity) return null;
	const backgroundClass = setBackgroundColorClassByCategory(scheduledConectivityToProcess.conectivity.category.label);
	return (
		<ModalNoBackground showModal={showModal} closeCallback={handleClose} cardColor={modalHeaderColor} closeButtonColor={COLOR_WHITE} closeButtonBackground={modalHeaderColor} >

			{displayConectivityHeader({conectivity:scheduledConectivityToProcess.conectivity, backgroundClass, darkMode, headerColor:modalHeaderColor, isFullView:true})}

			<div className="modalNoBkgInnerCard" >
				<div className="ContainerVerticalStart fullWidth" style={{padding:"clamp(8px,2vw,16px)"}}>
					{conectivityAction==="COMPLETED_CONECTIVITY" ? <div className="TextStyle6 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}> Woohoo!! Way to go! </div> : null }
					{conectivityAction==="REJECTED_CONECTIVITY" ? <div className="TextStyle3 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}> Would you like to skip this conectivity and have us pick a new one? </div> : null }

					{/* removed 8/20 to streamline process.  Use LR invitees
					conectivityAction === "REJECTED_CONECTIVITY" ? "" : 
					<>
						<div className="TextStyle5 headerBlue"  style={{paddingTop:"clamp(4px,1vw,10px)"}}> Who participated? </div> 
						<div className="ContainerVerticalLeft headerBlue" style={{minWidth:"clamp(150px,30vw,300px)"}}>
							<div className="TextStyle4 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}> Colleagues </div> 
							<DisplayUserSelectComponent usersOptions={participatingUsersOptions} handleSelectUser={setSelectedParticipatingUsers} isMulti={true} selectedUsersOptions={selectedParticipatingUsers} disabled={false} zIndex="9999" />
							<div className=" TextStyle4 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}> Teams </div> 
							<DisplayTeamSelectComponent teamsOptions={teamsOptions} handleSelectTeam={setSelectedParticipatingTeams} isMulti={true} selectedTeamsOptions={selectedParticipatingTeams} disabled={false}  zIndex="8888"/>
						</div>
					</>
					*/}
				</div>

				{(conectivityAction !== "COMPLETED_CONECTIVITY" || scheduledConectivityToProcess.conectivity.ratingStatsID == null) ? "" :
					<div className="ContainerVerticalCenter">
						<div className="TextStyle3"> Rate this conectivity! </div>
						<StarRatings rating={userConectivityRating} setRating = {setUserConectivityRating} readOnly={false} starSize={"28px"}/>
					</div>
				}     	

				<Divider percent={100} marginTop="clamp(8px,2vw,20px)"/>
				
				<div className="modalNoBkgFooter" >
							<div className="ContainerNoHeightCenter TextStyle4">
								{!scheduledConectivityToProcess.conectivity.conectCoins>0 ? "" :  
									<div className="Container" > 
										{!scheduledConectivityToProcess.conectivity ? "" : scheduledConectivityToProcess.conectivity.conectCoins} 
										<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
									</div>
								}
								{!scheduledConectivityToProcess.conectivity.badgesDEI>0 ? "" :  
										<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
								}
								{!scheduledConectivityToProcess.conectivity.badgesCS>0 ? "" :  
										<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> 
								}
							</div>
					

                 
						<div className="ContainerNoHeightCenter">
							<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmation} disabled={false}>
									{conectivityAction==="REJECTED_CONECTIVITY" ? "Yes" : ""} 
									{conectivityAction==="COMPLETED_CONECTIVITY" ? "Complete!" : ""}
							</button>
							<button className="buttonStyle2 " aria-label="cancel" onClick={handleClose} >
								{conectivityAction==="REJECTED_CONECTIVITY"  ? "Cancel" : "not yet..."} 
							</button>
						</div>
				</div>
				
			</div>
		</ModalNoBackground>
	)
}
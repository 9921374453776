//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright.  All Rights Reserved

import { DEBUG_MODE, CONECTERE_CONFIG_DATA } from "../data/conectereConfigData";
import { invokeAPI }  from "./databaseUtils";
import { invokeAI_LLM, invokeAI_LLM_V2 } from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';    
import moment from 'moment';

//Construct and return an ARRAY of one or more messages to be displayed to the user
export function constructResponseMessages({response}) {

   if (!response) {
      if (DEBUG_MODE) console.error("Error - unable to construct response message", response);
      return null;
   }
   try {
      const responseObject = JSON.parse(response);
      if (DEBUG_MODE) console.log("Formatting response messages", responseObject);

      //Did we receive ANY valid options?
      let formattedResponseMessages = []
      if ((responseObject.long && responseObject.long.length > 0) ||
         (responseObject.long && responseObject.long.length > 0) ||
         (responseObject.long && responseObject.long.length > 0)) {
            formattedResponseMessages = [`Here are a few options:`];
            if(responseObject.long && responseObject.long.length > 0) formattedResponseMessages.push(`(1)${responseObject.long}`);
            if(responseObject.short && responseObject.short.length > 0) formattedResponseMessages.push(`(2)${responseObject.short}`);
            if(responseObject.create && responseObject.create.length > 0) formattedResponseMessages.push(`(3)${responseObject.create}`);
      } else {
         formattedResponseMessages = [`Hmmm...that didn't quite work.`];
      }

      if (DEBUG_MODE) console.log("Constructed response message", formattedResponseMessages);
   } catch(err) {
      if (DEBUG_MODE) console.error("Error constructing response message");
   }
   return formattedResponseMessages;
}


export async function issuePromptToAiInterface({userID, customerID, prompt, history, isForSpotlight, isForConectivity, selectedUserOptions, selectedTeamOptions, conectivity, category, isDEIRelated, isCommunityServiceRelated}) {

   //Safety Check
   if (!userID || !customerID || (!isForSpotlight && !isForConectivity)) {
      if (DEBUG_MODE) console.error("Error - improper params to issue prompt to AI interface", userID, customerID, prompt, isForSpotlight, isForConectivity);
      return;
   }

   if(DEBUG_MODE) console.log("Issuing prompt to LLM Interface Lambda", userID, customerID, prompt, history, isForSpotlight, selectedUserOptions, selectedTeamOptions, isForConectivity, isDEIRelated, isCommunityServiceRelated)

   //Construct the names
   let userNames=[], teamNames=[];
   if (selectedUserOptions && selectedUserOptions.length > 0) for (const selectedUserOption of selectedUserOptions) userNames.push(selectedUserOption.name);
   if (selectedTeamOptions && selectedTeamOptions.length > 0) for (const selectedTeamOption of selectedTeamOptions) teamNames.push(selectedTeamOption.name);

   try {
      //Make direct call to our backend
      let params = { 
         prompt, 
         history, 
         isForSpotlight, 
         isForConectivity, 
         userNames, 
         teamNames,
         isDEIRelated, 
         isCommunityServiceRelated,
         conectivity,
         category,
       }

       const enqueueCommandInput = {
            userID: userID,
            customerID:customerID,
            command: (isForSpotlight ? 'PROMPT_LLM_SPOTLIGHT' : 'PROMPT_LLM_CONECTIVITY'),
            params: JSON.stringify(params),

      };
            
      //Call GraphQL to directly invoke our Lambda function 
      let response;
      if (userID === 'dfab78f3-8f1a-4742-80e2-8bb869b1d86b') {
         if (DEBUG_MODE >= 2) console.log("Directly Invoking IGGY 2 to issue prompt to AI Interface - ", enqueueCommandInput, JSON.stringify(params));
         response = await invokeAPI(invokeAI_LLM_V2, 'invokeAI_LLM_V2', enqueueCommandInput);
      } else {
         if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to issue prompt to AI Interface - ", enqueueCommandInput, JSON.stringify(params));
         response = await invokeAPI(invokeAI_LLM, 'invokeAI_LLM', enqueueCommandInput);
      }
      
      if (DEBUG_MODE >= 2) console.log("AI Lambda returned", response);        

      //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
      if (response && response.lambdaReturnStatus && response.lambdaReturnStatus === 'SUCCESS') {
         const returnParams = JSON.parse(response.returnParams);
         if (DEBUG_MODE >= 2) console.log("Success.  Lambda returned:", returnParams);
         return returnParams;
      } else {
         if (DEBUG_MODE >= 1) console.log("ERROR - AI Interface Lambda returned error", response);
         return;
      }
   } catch (err) {
      if (DEBUG_MODE) console.error("Error issing AI prompt", err);
      return;
   }
}


//
// SPOTLIGHT-RELATED AI FUNCTIONS



export function injectMessagesFromAI({aiInterfaceMessages,setAiInterfaceMessages, response, replaceEntireHistoryFlag}) {

   //Success?  If so, create a new message for our user.
   let tempMessages = (replaceEntireHistoryFlag ?[] : [...aiInterfaceMessages]), constructedNewMessages = false;

   if (response && response.successFlag && response.result && response.result.messages && response.result.messages.length >0) {
         try {
            if (DEBUG_MODE) console.log("Injecting response message", JSON.stringify(response.result));

            for (const msg of response.result.messages) tempMessages.push(msg);
            constructedNewMessages=true;          
         } catch (err) {
            if (DEBUG_MODE) console.error("Error injecting messages from AI", err);
         }
   }
   

   //Were we able to create a new message back to the user from LLM?  If not, push an error message
   if (!constructedNewMessages) {
         if (DEBUG_MODE) console.log("Unable to create response message for user");
         tempMessages.push({
            id:uuidv4(),
            createdAt:moment().toISOString(),
            isFromConectere:true,
            senderAvatarUrl: CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH,
            senderAvatarName: 'Conectere AI BOT',
            senderAvatarInitials: 'CC',
            message:"Sorry, I couldn't quite understand that.  Try again?",
         });
   }
   //Update display
   setAiInterfaceMessages(tempMessages); 

   }

   //This function returns a new / updated conectiity 
   export function genConectivityFromAiResponse({ response,  conectivity  }) {
 
      if (!response || !response.successFlag || !response.result.conectivity) {
         if (DEBUG_MODE) console.error("Error - improper params to update conectivity with generated content", response);
         return null;
      }
      const tempConectivity = {...conectivity};

      if (response.result.conectivity.title) tempConectivity.title = response.result.conectivity.title;
      if (response.result.conectivity.description) tempConectivity.description = response.result.conectivity.description;
      if (response.result.conectivity.instructions) tempConectivity.instructions = response.result.conectivity.instructions;
      if (response.result.conectivity.coins) tempConectivity.coins = response.result.conectivity.coins;
      if (response.result.conectivity.approvedPaidTime) tempConectivity.approvedPaidTime = response.result.conectivity.approvedPaidTime;

      if (DEBUG_MODE) console.log("Updating conectivity with generated content", tempConectivity);

      return (tempConectivity);

   }
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_GOOD, COLOR_WARNING_LOW, COLOR_WARNING_HIGH, COLOR_ALERT, TEAM_COLOR,
				progressDataInitialState, walletInitialState, USER_INITIAL_STATE, userAnalyticsInitialState,
				LEADERBOARD_PERIODS, TUTORIALS_INDEX, NEW_EMPLOYEE_SUPPORT_PROGRAM, 
	 } from '../data/conectereConfigData';

//React & Amplify
import React from 'react';

//Utils
import moment from 'moment';
import { recordTransaction } from './databaseUtils';


//Queries and Mutations
import { sendGiftEmail } from '../graphql/mutations';
import { getUser, } from '../graphql/queries';
import { processUsersAndTeamsByLambda,  createLeaderBoardSpot } from '../graphql/mutations';

//Utils
import { invokeAPI, getDataRecordById } from "./databaseUtils";
import { setHeaderColorByStringLength, setHeaderColorByStringLengthV2 } from "./conectivityUtils";

//Components
import Select from 'react-select';   //Version with support from multiple selections

import { v4 as uuidv4 } from 'uuid';    

export function isValidEmail(email){
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
		 return true;
	  }
	return false;
}

export function compareUsersByName(a, b) {
	 if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) return 1;
	 else if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) return -1;
	 else if (a.firstName.toUpperCase() > b.firstName.toUpperCase()) return 1;
	 else if (a.firstName.toUpperCase() < b.firstName.toUpperCase()) return -1;
	 else return 0;
}

export function comparesByUserID(a, b) {  
		  if (a.userID > b.userID) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
				return 1;
				}
				
		  if (a.userID < b.userID) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
				return -1;
		  }
		  
		  // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
		  return 0;  
	 }    

export  function compareRowsByUserRank(a, b) {
	 
	 if (a.userRank === undefined || b.userRank==undefined) return 0;
	 
	 if (a.userRank > b.userRank) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		  return 1;
	 }
		  
	 if (a.userRank < b.userRank) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		  return -1;
	 }
	 
	 // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	 return 0;
	 
}

export function compareTeamsByName(a, b) {
	 if (!a || !a.name || !b || !b.name) return 0;
	 if (a.name.toUpperCase() > b.name.toUpperCase())  return 1;
	 else if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
	 return 0;
}

export function compareTeamUserJoinsByTeamName (a,b) {
	 if (!a || !a.team || !a.team.name || !b || !b.team || !b.team.name ) return 0;
	 if (a.team.name.toUpperCase() > b.team.name.toUpperCase())  return 1;
	 else if (a.team.name.toUpperCase() < b.team.name.toUpperCase()) return -1;
	 return 0;
	 
}

export  function compareRowsByTeamRank(a, b) {
	 
	 if (a.teamRank === undefined || b.teamRank==undefined) return 0;
	 
	 if (a.teamRank > b.teamRank) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		  return 1;
	 }
		  
	 if (a.teamRank < b.teamRank) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		  return -1;
	 }
	 
	 // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	 return 0;
	 
}


export function compareMentorsByNumMentees(a, b) {
	 if (a.numMentees > b.numMentees)  return -1;
	 if (a.numMentees < b.numMentees)  return 1;
	 return 0;   
}

export function compareMentorsByNumProteges(a, b) {
	 if (a.numProteges > b.numProteges)  return -1;
	 if (a.numProteges < b.numProteges)  return 1;
	 return 0;   
}

//Functions for  sorting scores
export function compareLeaders(a, b) {

	 if (a.currentBalance > b.currentBalance) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		  return -1;
		  }
	 if (a.currentBalance < b.currentBalance) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		  return 1;
	 }
	 
	 //Tiebreak by USERID
	 if (a.userID > b.userID) {
	 // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
	 return -1;
	 }
	 
	 if (a.userID < b.userID) {
		  // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		  return 1;
	 }
	 
	 // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	 return 0;

}

export function buildTeamsForDisplay(user) {
 
	  //Safety check
	  if (!user || !user.teams || !user.teams.items || user.teams.items.length === 0) return "";
	 
	 //build Teams string for display
	 const tempTeamUserJoins = [...user.teams.items];

	 tempTeamUserJoins.sort(compareTeamUserJoinsByTeamName);

	 let teamsString ="";
	 for (const join of tempTeamUserJoins) {
		  if (join && join.team) {
				if (join.team.name === "ALL EMPLOYEES") join.team.name = "All Employees";
				teamsString = teamsString.concat(join.team.name, ", ");
		  }
	 }
	 if (teamsString && teamsString.length > 2) teamsString.slice(-2);
	 return teamsString;
}
  
//Process user selections of Teams and Users and generate an array of unique user ids to invite along with a unique array of team ids
export function generateUsersToInvite({selectedTeamsOptions, selectedUsersOptions, users, teams, senderID, includeSenderFlag}) {

	var usersInvitedToParticipate = []; //temp array of IDs to keep track of users as we send out invites so as to ensure we don't send multiple
	var teamsInvitedToParticipate = []; //temp array of IDs to keep track of teams as we send out invites
	var attendeeEmailsForCalendar = []; //temp array of emails to use with calendar event
  
	try {
		if (DEBUG_MODE >= 2) console.log("Generating Users & Teams", selectedTeamsOptions, selectedUsersOptions, senderID, includeSenderFlag);
		
		if (selectedTeamsOptions && selectedTeamsOptions.length > 0) {
			selectedTeamsOptions.forEach (selectedTeam => {

			//First, get details on the invited team from the "teams" array based on object in Team selection   
				const invitedTeam = teams.find(team => team.id === selectedTeam.id);
				
				if (invitedTeam && invitedTeam.users && invitedTeam.users.items && invitedTeam.users.items.length > 0 ) {
					if (DEBUG_MODE >= 2) console.log("Processing team", invitedTeam);               
					teamsInvitedToParticipate.push(invitedTeam.id); //Update array of invited teams
					
					invitedTeam.users.items.forEach(teamMember => {
						
						//Has the user already been added, i.e., on another team that was selected?                   
						if (!usersInvitedToParticipate.includes(teamMember.userID)) {
						
							//If not, ADD user as an invitees on the invitation
							//Get invitee details using the "user" records 
							const invitee  = users.find(user => user.id === teamMember.userID);
							
							if (invitee) {
									if (DEBUG_MODE >= 2) console.log('Added invitee user info as team member', invitee);                   
									usersInvitedToParticipate.push(invitee.id);  //Update array of invited users
									if (invitee.email) attendeeEmailsForCalendar.push({email:invitee.email.toLowerCase()}); 
							}
						
						} else { 
							if (DEBUG_MODE >= 2) console.log('User already invited', teamMember.userID, usersInvitedToParticipate);    
						}
					}); //END ForEach                                  
				} //END IF TEAM FOUND
			})// END forEach SElected Team
		}
		
		if (selectedUsersOptions && selectedUsersOptions.length > 0) {  
			selectedUsersOptions.forEach (selectedUser => {     
				if (DEBUG_MODE >= 2) console.log("Processing selected user:", selectedUser);
				if (!usersInvitedToParticipate.includes(selectedUser.id)) {

					//ADD user as an invitees on the invitation
					const invitee = users.find(user => user.id === selectedUser.id);
					if (DEBUG_MODE >= 2) console.log('Pulled invitee info', invitee);                   

					if (invitee) {
						if (invitee.id) usersInvitedToParticipate.push(invitee.id);  //Update array of invited users
						if (invitee.email) attendeeEmailsForCalendar.push({email:invitee.email.toLowerCase()}); 
					}

				} else {         
					if (DEBUG_MODE >= 2) console.log('User already invited', selectedUser.id, usersInvitedToParticipate);              
				} //END IF USER ALREADY ADDED
			}); // END ForEach SELECTED USER
		}
		  
				
		  //Inlcude sender autmatically?
		  if (includeSenderFlag && senderID) {
				//Already included?
				if (!usersInvitedToParticipate.includes(senderID)) {

					 //ADD sender as an invitees on the invitation
					 const invitee = users.find(user => user.id === senderID);
					 if (invitee) {
						  usersInvitedToParticipate.push(invitee.id);  //Update array of invited users
						  if (invitee.email) attendeeEmailsForCalendar.push({email:invitee.email.toLowerCase()}); 
						  if (DEBUG_MODE >= 2) console.log('Added sender as an invitee', invitee);  
					 } else {
						  console.log("ERROR - no user found for this senderID", senderID);
					 }
				} else {
					 if (DEBUG_MODE >= 2) console.log('Sender not added; Sender already invited');   
				}
		  } else {
				if (DEBUG_MODE >= 2) console.log('Sender intentionally excluded');   
		  }//END IF INCLUDE SENDER
		 
	 } catch (err) {
		  if (DEBUG_MODE >= 0) console.error('ERROR generating users & teams arrays', err);   
	 }

	return {
	  usersInvitedToParticipate: usersInvitedToParticipate, 
	  teamsInvitedToParticipate: teamsInvitedToParticipate,
	  attendeeEmailsForCalendar: attendeeEmailsForCalendar,
	};
}

export const DisplayUserSelectComponent = ({usersOptions, handleSelectUser, isMulti, selectedUsersOptions, disabled, zIndex, placeholder, name, isClearable}) => {
	 
	if (!placeholder) placeholder = " - colleagues -";
	if (!zIndex) zIndex = "99";
	if (!name) name = 'userDropdownModal';
	if (isClearable === undefined) isClearable = true;
	 
	return (
	  <div style={{flexShrink:'0', width:"100%",  padding:"clamp(4px,1vw,10px)", height:"auto", marginLeft:"auto", zIndex:zIndex}}>
			<Select    
				 isMulti={isMulti} 
				 name={name} 
				 options={usersOptions} 
				 onChange={handleSelectUser} 
				 value={selectedUsersOptions} 
				 placeholder={placeholder}
				 isDisabled={disabled}
				 isClearable={isClearable}
				 formatOptionLabel={user => (
						  <div className="ContainerNoHeightFlexLeft noWrap">
								 <div className="avatarImage avatarImageSmall" style={{marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(user.label)}}>
									  {!user.avatarUrl  ? 
											<div className="avatarInitialsSmall"> {user.avatarInitials} </div>: 
											<img className="avatarImageCenterPortrait" src={user.avatarUrl} alt={""} />
									  }                                                
								 </div>
								 <span className="TextStyle4 blue"  style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap"}}>{user.label}</span>   
							</div>
				  )}
			  />
		</div>
	);
}; 

export const DisplayUserSelectComponentV2 = ({
	 usersOptions, 
	 handleSelectUser, 
	 isMulti, 
	 selectedUsersOptions, 
	 disabled, 
	 zIndex, 
	 placeholder, 
	 name, 
	 isClearable,
	 label,
}) => {
	 if (!placeholder) placeholder = "Add Colleagues";
	 if (!zIndex) zIndex = "99";
	 if (!name) name = 'userDropdownModal';
	 if (!label) label = 'Colleagues';

	 const handleRemoveChip = (userToRemove) => {
		  const updatedSelectedUsers = selectedUsersOptions.filter(
				(user) => user.value !== userToRemove.value
		  );
		  handleSelectUser(updatedSelectedUsers);
	 };

	 // Conditionally hide the clear (X) icon if there are selected users
	 if (isClearable === undefined) {
		  isClearable = selectedUsersOptions.length === 0;
	 }

	 return (
		  <div style={{ flexShrink: '0', width: "100%", padding: "clamp(4px,1vw,10px) 0 0 0", height: "auto", marginLeft: "auto", zIndex: zIndex }}>
				<span className="TextStyle3V2 black-text">{label}</span>
				<Select    
					 isMulti={isMulti} 
					 name={name} 
					 options={usersOptions} 
					 onChange={handleSelectUser} 
					 value={selectedUsersOptions} 
					 placeholder={placeholder} 
					 isDisabled={disabled}
					 isClearable={isClearable}
					 formatOptionLabel={user => (
						  <div className="ContainerNoHeightFlexLeft noWrap">
								<div className="avatarImage avatarImageSmall" style={{ marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(user.label) }}>
									 {!user.avatarUrl ? 
										  <div className="avatarInitialsSmall"> {user.avatarInitials} </div> :
										  <img className="avatarImageCenterPortrait" src={user.avatarUrl} alt={""} />
									 }
								</div>
								<span className="TextStyle4 blue" style={{ paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace: "nowrap" }}>{user.label}</span>   
						  </div>
					 )}
					 styles={{
						  control: (base) => ({
								...base,
								borderRadius: 8,
								marginTop: 8,
								minHeight: 45,
								backgroundColor:'var(--card-background)',
						  }),
						  indicatorSeparator: (baseStyles) => ({
								...baseStyles,
								width: 0,
						  }),
						  multiValue: (base) => ({
								...base,
								display: 'none', // Hide default multi-value chips
						  }),
						  option: (provided) => ({
								...provided,
								backgroundColor:'var(--card-background)',
						  }),
						  menuList: (provided) => ({
								...provided,
								backgroundColor:'var(--card-background)',
							 }),
					 }}
				/>

				{/* Display selected users as custom chips */}
				<div className="selected-chips">
					 {selectedUsersOptions.map((user) => (
						  <div key={user.value} className="chip" style={{backgroundColor: setHeaderColorByStringLengthV2(user.label), border: `1px solid ${setHeaderColorByStringLength(user.label)}`}}>
								<div className='homeContainer3'>
									 <div className="avatarImage avatarImageTiny" style={{ marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(user.label) }}>
										  {!user.avatarUrl ? 
												<div className="avatarInitialsSmall"> {user.avatarInitials} </div> :
												<img className="avatarImageCenterPortrait" src={user.avatarUrl} alt={""} />
										  }
									 </div>
									 <span className="TextStyle3V2 bold"  style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap",color:setHeaderColorByStringLength(user.label)}}>{user.label}</span>   
								</div>
								<span
									 className="remove-chip"
									 style={{color: setHeaderColorByStringLength(user.label)}}
									 onClick={() => handleRemoveChip(user)}
								>
									 &times;
								</span>
						  </div>
					 ))}
				</div>
		  </div>
	 );
};

//Returns null or the RSVP status string if the user has RSVP'd for this event    
export function getRSVPStatus(userID, launchRuleInstanceIndex, rsvpRecords) {
	  //safety check
	  if (!userID || !rsvpRecords || rsvpRecords.length === 0) return null;
	 
	 let  userRsvpRecord = rsvpRecords.find(rsvp => (rsvp.userID === userID && (!launchRuleInstanceIndex || rsvp.launchRuleInstanceIndex===0 || (rsvp.launchRuleInstanceIndex===launchRuleInstanceIndex))));
	 return (userRsvpRecord ? userRsvpRecord.rsvpStatus : null);
}

export function displayRSVPStatus(userID, launchRuleInstanceIndex, rsvpRecords) {

	 //Dynamically display an RSVP status on this user 

	  //safety check
	  if (!userID || !rsvpRecords || rsvpRecords.length === 0) return null;
	  
	  //Find this user's rsvp for the matching launchRuleInstanceIndex, if specified
	  const userRsvpStatus = getRSVPStatus(userID, launchRuleInstanceIndex, rsvpRecords);
	  if (!userRsvpStatus) return null;
	//  if (DEBUG_MODE >= 2) console.log("Found User RSVP", userRsvpStatus, rsvpRecords);
	 
	 let backgroundColor, indicator; 
	 switch (userRsvpStatus){
		  case 'ACCEPTED': backgroundColor=COLOR_GOOD; indicator=String.fromCharCode(0x2713); break;
		  case 'DECLINED': backgroundColor=COLOR_ALERT; indicator='x'; break;
		  case 'MAYBE': backgroundColor=COLOR_WARNING_LOW; indicator='?'; break;
		  case 'REMOVED':backgroundColor=COLOR_ALERT; indicator='x'; break;
		  default: return null;
	 }

	 return (
		  <div className='rsvpStatusAnchor avatarImage avatarImageTiny' style={{backgroundColor:backgroundColor, marginRight:"unset"}}>
				<div className="TextStyle2 white" >{indicator}</div>
		  </div>

	 );
}   

export function displayUserAvatar({user, isSmall, avatarClass}) {
	//Safety check
	if (!user || !user.id) return;
	if (!avatarClass) avatarClass = "avatarImage avatarImageMedium";
	if (isSmall) {
	 avatarClass = "avatarImage avatarImageMediumV2";
	}
	
	return (
		<div className="avatarContainer"> 
			<div className={avatarClass} style={{marginRight:"0px", backgroundColor:setHeaderColorByStringLength(user.name ? user.name :  user.firstName + " " + user.lastName)}}>
				 {!user.avatarUrl  ? 
					  <div className="avatarInitials" > {user.avatarInitials ? user.avatarInitials :getUserInitials(user) }</div> 
					  : 
					  <img className="avatarImageCenterPortrait" src={user.avatarUrl} alt={user.avatarInitials ? user.avatarInitials :getUserInitials(user)} /> 
				 }
			</div>
			<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(user.name ? user.name : user.firstName + user.lastName), color:"white"}}>{user.name ? user.name : user.firstName + ' ' + user.lastName}</span>
		</div>      
	); 
}
	 
/*Function shows a row of avatars for a set of users associated with an inviation or calendar event.  
	 Inputs:
		  sender: the sender (user record) that sent the invitation; If a SENDER is passed in then the sender is displayed and not included in the remaining list
		  usersToShow: an array of USERS or an array of selections OPTIONS; in either case the ID is the user ID
		  numUsersToShow: INT - the number of bubbles to show before a generic +
		  isSmall: BOOL - controls CSS class selection
		  rsvpRecords: an array of user RSVPs in the form shown below.  If present then an RSVP indicator is shown on the corresponding user
		  launchRuleInstanceIndex: INT 0 - N indicating the specific instance of the LR (calendar event) to which the row of avatars applies if part of a recurring event, where 0 indicates the RSVP applies to the entire LR

		  "rsvpRecords": [
									 {
										  "userID": "3eb949f0-ad65-4413-8a02-d7ab0ab174e8",
										  "rsvpStatus": "DECLINED",
										  "launchRuleID": "028a22d3-bc2c-48e2-8003-d09d670eb37c",
										  "launchRuleInstanceIndex": 0
									 }
								]
*/
export function displayUsersRow({sender, usersToShow, numUsersToShow, isSmall, rsvpRecords, launchRuleInstanceIndex}) {

	// if (DEBUG_MODE >= 2) console.log("Displaying user avatars", usersToShow, numUsersToShow, isSmall, rsvpRecords, launchRuleInstanceIndex);
	if (!usersToShow || usersToShow.length===0) return null;
	if (numUsersToShow === 'undefined') numUsersToShow = 7;
	var avatarClass = "avatarImage avatarImageMedium";
	 let avatarInitialsClass = 'avatarInitials'
	if (isSmall) {
	 avatarClass = "avatarImage avatarImageSmall";
	 avatarInitialsClass = "avatarInitialsSmall"
	}
	 
	 return (
				<div className="Container">
				
						{sender ? 
								//Show the sender first
								<div className="ContainerNoHeightCenter">
										  <div className="avatarContainer">
												<div className={avatarClass} style={{backgroundColor: setHeaderColorByStringLength(sender.name ? sender.name : sender.firstName + sender.lastName), marginRight:"unset"}}>
													 {!sender.avatarUrl  ? 
													 <div className={avatarInitialsClass}> {sender.avatarInitials ? sender.avatarInitials : getUserInitials(sender)} </div>
													 : 
													 <img className="avatarImageCenterPortrait" src={sender.avatarUrl} alt={sender.avatarInitials ? sender.avatarInitials : getUserInitials(sender)} />}
												</div>
												<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(sender.name ? sender.name : sender.firstName + sender.lastName)}}>{sender.name ? sender.name : sender.firstName + ' ' + sender.lastName}</span> 

												{(!rsvpRecords || rsvpRecords.length ===0) ? null : displayRSVPStatus(sender.id, launchRuleInstanceIndex, rsvpRecords)}                    

										  </div>
										  
										  {usersToShow.length > 1 ? <div className="TextStyle5" style={{paddingLeft:"clamp(2px,0.5vw,5px)", color:TEAM_COLOR}}> &#x7c; </div> : null }
								</div>
								
						  : null }
				
						  {usersToShow.map((user, index) => { 

								//Display the invitee unless we are beyond the max or this is the sender and we already displayed him/her
								 if (index <= numUsersToShow  && (!sender || user.id !== sender.id)) return (

									 <div key={uuidv4()}>
									 {index <=numUsersToShow-1 ? 
										  <div className="avatarContainer"> 
												<div className={avatarClass} style={{backgroundColor:setHeaderColorByStringLength(user.name ? user.name :  user.firstName + user.lastName)}}>
													 {!user.avatarUrl  ? 
														  <div className={avatarInitialsClass} > {user.avatarInitials ? user.avatarInitials :getUserInitials(user) }</div> 
														  : 
														  <img className="avatarImageCenterPortrait" src={user.avatarUrl} alt={user.avatarInitials ? user.avatarInitials :getUserInitials(user)} /> 
													 }
												</div>
												<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(user.name ? user.name : user.firstName + user.lastName), color:"white"}}>{user.name ? user.name : user.firstName + ' ' + user.lastName}</span>

												{(!rsvpRecords || rsvpRecords.length ===0) ? null : displayRSVPStatus(user, launchRuleInstanceIndex, rsvpRecords)}                    

										  </div>
										  : ""
									 }
									 </div>);
								else return null;

								}) }
					 { usersToShow.length > numUsersToShow ?
						  //Display an extra cirle with a PLUS if we have exceeded our limit
						  <div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:TEAM_COLOR, color:"white"}}>
								<div className="avatarInitials" >+{usersToShow.length-numUsersToShow}</div>
						  </div>
				: null
				}                    
					 
				</div>
	 );           
 
	 /*
	 return(
		  <div className="Container">
				{usersToShow.map((target, index) => ( 
						  <div key={uuidv4()} className="avatarContainer">
								<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
									 {!target.avatarUrl  ? <div className="avatarInitials" > {target.avatarInitials}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.avatarInitials} /> }
								</div>
								<span className="avatarHoverText" style={{background: TEAM_COLOR, color:"white"}}>{target.name}</span>
						  </div>
					 ))}
				<div style={{width:"15px"}}></div>
		  </div>
		  );
	 */
}


export const DisplayTeamSelectComponent = ({teamsOptions, handleSelectTeam, isMulti, selectedTeamsOptions, disabled, zIndex, placeholder}) => {
	 
	 if (!placeholder) placeholder = " - teams -";
	 if (!zIndex) zIndex = "99";
	 
	 return (
		  <div style={{flexShrink:'0', width:"100%",  padding:"clamp(4px,1vw,10px)", height:"auto", marginLeft:"auto", zIndex:zIndex}}>
				<Select    
					 isMulti={isMulti} 
					 name="categoryDropdownModal" 
					 options={teamsOptions} 
					 onChange={handleSelectTeam} 
					 value={selectedTeamsOptions} 
					 placeholder={placeholder}
					 isDisabled={disabled}
					 formatOptionLabel={team => (
								<div className="ContainerNoHeightFlexLeft noWrap blue"> 
									 <div className="avatarImage avatarImageSmall" style={{marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(team.label)}}>
										  {!team.avatarUrl  ? 
												<div className="avatarInitialsSmall"> {team.avatarInitials} </div>: 
												<img className="avatarImageCenterPortrait" src={team.avatarUrl} alt={""} />
										  }                                                
									 </div>
									 <div>
										  <span className="TextStyle4"  style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap"}}>{team.label}</span>   
									 </div>
								</div>
					  )}
				  />
				</div>
		);
};
	 
export const DisplayTeamSelectComponentV2 = ({teamsOptions, isClearable, handleSelectTeam, isMulti, selectedTeamsOptions, disabled, zIndex, placeholder, label}) => {
	 
	 if (!placeholder) placeholder = "Add Team";
	 if (!zIndex) zIndex = "99";
	 if (!label) label = 'Teams';
	 
	 const handleRemoveChip = (userToRemove) => {
		  const updatedSelectedTeam = selectedTeamsOptions.filter(
				(user) => user.value !== userToRemove.value
		  ); 
		  handleSelectTeam(updatedSelectedTeam);
	 };

	 if (isClearable === undefined) {
		  isClearable = selectedTeamsOptions.length === 0;
	 }

	 return (
		  <div style={{flexShrink:'0', width:"100%",  padding:"clamp(4px,1vw,10px) 0 0 0", height:"auto", marginLeft:"auto", zIndex:zIndex}}>
				<span className="TextStyle3V2 black-text">{label}</span>
				<Select    
					 isMulti={isMulti} 
					 name="categoryDropdownModal" 
					 options={teamsOptions} 
					 onChange={handleSelectTeam} 
					 value={selectedTeamsOptions} 
					 placeholder={placeholder}
					 isClearable={isClearable}
					 isDisabled={disabled}
					 formatOptionLabel={team => (
								<div className="ContainerNoHeightFlexLeft noWrap blue"> 
									 <div className="avatarImage avatarImageSmall" style={{marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(team.label)}}>
										  {!team.avatarUrl  ? 
												<div className="avatarInitialsSmall"> {team.avatarInitials} </div>: 
												<img className="avatarImageCenterPortrait" src={team.avatarUrl} alt={""} />
										  }                                                
									 </div>
									 <div>
										  <span className="TextStyle4"  style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap"}}>{team.label}</span>   
									 </div>
								</div>
					  )}
					  styles={{
						  control: (base) => ({
								...base,
								borderRadius: 8,
								marginTop: 8,
								minHeight: 45,
								backgroundColor:'var(--card-background)',
						  }),
						  indicatorSeparator: (baseStyles) => ({
								...baseStyles,
								width: 0,
						  }),
						  multiValue: (base) => ({
								...base,
								display: 'none', // Hide default multi-value chips
						  }),
						  option: (provided) => ({
								...provided,
								backgroundColor:'var(--card-background)',
						  }),
						  menuList: (provided) => ({
								...provided,
								backgroundColor:'var(--card-background)',
						  }),
					 }}
				  />

				  {/* Display selected users as custom chips */}
				<div className="selected-chips">
					 {selectedTeamsOptions.map((team) => (
						  <div key={team.value} className="chip" style={{backgroundColor: setHeaderColorByStringLengthV2(team.label), border: `1px solid ${setHeaderColorByStringLength(team.label)}`}}>
								<div className='homeContainer3'>
									 <div className="avatarImage avatarImageTiny" style={{ marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(team.label) }}>
										  {!team.avatarUrl ? 
												<div className="avatarInitialsSmall"> {team.avatarInitials} </div> :
												<img className="avatarImageCenterPortrait" src={team.avatarUrl} alt={""} />
										  }
									 </div>
									 <span className="TextStyle3V2 bold"  style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap",color:setHeaderColorByStringLength(team.label)}}>{team.label}</span>   
								</div>
								<span
									 className="remove-chip"
									 style={{color: setHeaderColorByStringLength(team.label)}}
									 onClick={() => handleRemoveChip(team)}
								>
									 &times;
								</span>
						  </div>
					 ))}
				</div>
				</div>
		);
};

export function displayTeamAvatar({team, isSmall, avatarClass}) {
	//Safety check
	if (!team || !team.id) return;
	if (!avatarClass) avatarClass = "avatarImage avatarImageMedium";
	if (isSmall) {
	 avatarClass = "avatarImage avatarImageSmall";
	}
	 return (
		  <div className="avatarContainer"> 
				<div className={avatarClass} style={{backgroundColor:setHeaderColorByStringLength(team.name)}}>
					 {!team.avatarUrl  ? <div className="avatarInitials" > {getTeamInitials(team)}</div> : <img className="avatarImageCenterPortrait" src={team.avatarUrl} alt={team.avatarInitials} /> }
				</div>
				<span className="avatarHoverText" style={{background: TEAM_COLOR, color:"white"}}>{team.name}</span>
		  </div>
	 )
}
export function displayTeamsRow({teamsToShow, numTeamsToShow, isSmall}) { 

	 if (!teamsToShow || teamsToShow.length===0) return null;  
	 if (!numTeamsToShow) numTeamsToShow = 7;
	 var avatarClass = "avatarImage avatarImageMedium";
	 if (isSmall) avatarClass = "avatarImage avatarImageTiny";
	
		  return (
					 <div className="Container">
						  {teamsToShow.map((team, index) => ( 
									 
									 <div key={uuidv4()}>
									 {index <=numTeamsToShow-1 ? 
										  displayTeamAvatar({team, isSmall, avatarClass})
										  : null
									 }
									 </div>
								)) }
					  
							{ teamsToShow.length >= numTeamsToShow ?

								<div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:TEAM_COLOR, color:"white"}}>
									 <div className="avatarInitials" >+{teamsToShow.length-numTeamsToShow}</div>
								</div>
								: null
							}
								
					 </div>
		  );           
 
	 /*
	 return(
		  <div className="Container">
				{teamsToShow.map((target, index) => ( 
						  <div key={uuidv4()} className="avatarContainer">
								<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
									 {!target.avatarUrl  ? <div className="avatarInitials" > {target.avatarInitials}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.avatarInitials} /> }
								</div>
								<span className="avatarHoverText" style={{background: TEAM_COLOR, color:"white"}}>{target.name}</span>
						  </div>
					 ))}
				<div style={{width:"15px"}}></div>
		  </div>
		  );
	 */
}  

//Function for constructing a string of team names for which a given user is a member
export function displayTeams(user) {
	 if (!user || !user.teams || !user.teams.items || user.teams.items.length === 0 ) return "none";

	 let teamsString ="";
	 //build Teams string for display
	 for (const join of user.teams.items) {
		 if (join && join.team) teamsString += join.team.name + ", ";
	 } 
	 if (teamsString && teamsString.length > 2) teamsString = teamsString.slice(0,-2);
	 else if (!teamsString) teamsString = "none"; 
	 return teamsString; 
  }


//Safe return of initials of a user object
export function getUserName(user) {
	 // if (DEBUG_MODE >= 2) console.log("GetUserInitials", user);
	 if (!user || !user.lastName) return ""; 
	 return (!user.firstName ? "" : user.firstName) + (!user.middleName ? "" : " " + user.middleName.substring(0,1)) + (!user.lastName ? "" : " " + user.lastName);
}

//Safe return of initials of a user object
export function getUserInitials(user) {
	 return (!user || !user.firstName ? "" : user.firstName.substring(0,1).toUpperCase()) + (!user.middleName ? "" : user.middleName.substring(0,1).toUpperCase()) + (!user.lastName ? "" : user.lastName.substring(0,1).toUpperCase());
}

//Safe return of initials of a team object
export function getTeamInitials(team) { 
	 // if (DEBUG_MODE >= 2) console.log("GetTeamInitials", team);
	 if (!team || !team.name || team.name.length === 0) return ""; 
	 if (team.name.length < 4) return team.name.toUpperCase(); 

	 //Split on space ' '
	 const teamNameStrings  = team.name.split(' '); //Grab up to the 1st three words
	 // if (DEBUG_MODE >= 2) console.log("SPLIT", teamNameStrings);
	 let tempInitials = '', initialsUsed = 0;
	 teamNameStrings.forEach(word => {
		  let initial = word.slice(0,1);
		  if (initial.match(/[A-Z|a-z|0-9]/i) && initialsUsed < 3) {
				tempInitials = tempInitials + initial; initialsUsed++;
		  }
	 });
	 return (tempInitials.toUpperCase()); 

	 // return (team.name.substring(0,2).toUpperCase()); 
}


export async function writeLeaderboardSpot({customerLeaderboardID, user, period, userProgressDataRecord, newRank, userMove}) {

	 try {
	 	//Grab the balance to write to the SPOT based on the period	
	 	var currentBalance = 0;
	 	switch(period) {
	 		case "DAY":
	 			currentBalance = userProgressDataRecord.dayCoinsEarned;
	 			break;
	 		case "WEEK":
	 			currentBalance = userProgressDataRecord.weekCoinsEarned;
	 			break;
	 		case "MONTH":
	 			currentBalance = userProgressDataRecord.monthCoinsEarned;
	 			break;
	 		case "QUARTER":
	 			currentBalance = userProgressDataRecord.quarterCoinsEarned;
	 			break;
	 		case "YEAR":
	 			currentBalance = userProgressDataRecord.yearCoinsEarned;
	 			break;			
	 	}	
	 
	 	const tempNewLeaderBoardSpot = {
	 		firstName: user.firstName,
	 		lastName: user.lastName,
	 		title: user.title,
	 		currentBalance: currentBalance,	
	 		userRank:newRank,
	 		userMove:userMove,           //+-movement on LeaderBoard for this person since in the last update of the LeaderBoard
	 		avatarUrl: user.avatarUrl,          								//location where avatar image is stored on the S3 bucket  
	 		leaderBoard: period,
	 		customerID: user.customerID,
	 		userID: user.id,
	 		customerLeaderBoardID: customerLeaderboardID,                 //ID of customer leader board to which this spot belongs - must be the only record we found
	 	};   
	 	
		  //Write object to team analytics record
		  const response = await invokeAPI(createLeaderBoardSpot, 'createLeaderBoardSpot', tempNewLeaderBoardSpot);        
		  // if (DEBUG_MODE >= 2) console.log("Successfully wrote new leaderboard spot", response);
		  
	 } catch (err) {
		  if (DEBUG_MODE >= 2) console.log("Error writing new leaderboard spot", err);
	 }
}

//Direct call to lambda to check with our user pool already has a userName (email)
//customerID - optional as this call may be made from the SignupWizard and the email must be unique across the entire ppol
export async function isCognitoUserNameAvailable(userName, customerID) {
	//Safety check
	if (!userName || userName.length ===0) {
		console.error("Error - improper params to check username availability", userName);
		return ({successFlag:false, error:'Improper params'});             
	}
	if (DEBUG_MODE >= 2) console.log("Issuing CONFIRM_USER_AVAILABLE Command to Lambda", userName);
	try {    
		//Direct call to our Enqueue Command lambda
		const params = { userName: userName }
		const enqueueCommandInput = {
			customerID:customerID,
			command: 'CONFIRM_USER_AVAILABLE',
			params: JSON.stringify(params),
		};   
	 
		//Call GraphQL to directly issue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to CONFIRM_USER_AVAILABLE", enqueueCommandInput);
		
		const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda', enqueueCommandInput);
		if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		if (response && response.lambdaReturnStatus === "SUCCESS") {
			if (DEBUG_MODE >= 2) console.log('Username available in Cognito!');
			return ({successFlag:true, error:''});  
		} 
		if (DEBUG_MODE >= 2) console.log('Username unavailable - already present in Cognito');
		return ({successFlag:false, error:'Username unavailable - already present in Cognito'});             
	} catch (err) {
		if (DEBUG_MODE >= 2) console.erro('ERROR - to confirm availability of username', err);
		return ({successFlag:false,  err:err});
	}   
}

//
//Main function to create a new user an all related DB records
//
export async function invokeCreateUser(
	 {   cmdIssuerID, customerID, userInputData, teams, 
		  customerConfigDetails, bio, avatarWidth, avatarHeight, avatarUrl, selectedTeamsOptions,
		  mentorID, sponsorID, supervisorID,
		  startDate, timeZoneCode, isFromBatchUpload
	 }) {


if (!cmdIssuerID || !customerID ||  !userInputData || !userInputData.firstName || !userInputData.lastName || !userInputData.email || !customerConfigDetails || !customerConfigDetails.id || !teams) {
	 if (DEBUG_MODE >= 2) console.log("ERROR CREATING USER - improper params", cmdIssuerID, customerID, userInputData, customerConfigDetails, teams);
	 return ({successFlag:false, newUser:null, error:'Unable to create user'});             
} 

if (DEBUG_MODE >= 2) console.log("Issuing Create User Command to Lambda", userInputData);

try {

	 //Generate array of IDs for teams on which to include the new user
	 let teamIDs = [];
	 const allEmployeesTeam = teams.find(team => team.name === "ALL EMPLOYEES" && team.customerID === customerID);
	 if (allEmployeesTeam && allEmployeesTeam.id) teamIDs.push(allEmployeesTeam.id);
	 //More teams to add?
	 if (selectedTeamsOptions && selectedTeamsOptions.length >0) {
		  for (var i=0; i<selectedTeamsOptions.length;i++) {
				if (selectedTeamsOptions[i].id !== allEmployeesTeam.id) teamIDs.push(selectedTeamsOptions[i].id);
		  }
	 }
	 if (DEBUG_MODE >= 2) console.log("Generated array of team IDs", teamIDs);

	 //Direct call to our Enqueue Command lambda
	  const params = {
				cmdIssuerID: cmdIssuerID,
				customerID: customerID,                         //ID of the customer in which to create the new user
				configDetailsID: customerConfigDetails.id,
				firstName: userInputData.firstName,
				middleName: (!userInputData.middleName ? "" : userInputData.middleName),
				lastName: userInputData.lastName,
				pronouns: userInputData.pronouns,
				bio: (bio ? bio : ""),
				email: userInputData.email.toLowerCase(),
				title: userInputData.title,
				avatarHeight: avatarHeight,
				avatarWidth: avatarWidth,
				avatarUrl: avatarUrl,
				permissionAnalytics:userInputData.permissionAnalytics,
				permissionLaunch: userInputData.permissionLaunch,
				permissionEditor: userInputData.permissionEditor,
				permissionOrders: userInputData.permissionOrders,
				permissionBilling: userInputData.permissionBilling,
				mentorID:  mentorID ? mentorID : "",
				sponsorID:  sponsorID ? sponsorID : "",
				supervisorID:  supervisorID ? supervisorID : "",
				canBeMentor: userInputData.canBeMentor ? true : false,          // User can be selected as a Mentor
				canBeSponsor: userInputData.canBeSponsor ? true : false,        // User can be selected as a Sponsor
				canBeSupervisor:userInputData.canBeSupervisor ? true : false,   // User can be selected as a Supervisor
				isUnderrepresentedGroup:userInputData.isUnderrepresentedGroup ? true : false,
				startDate: startDate,
				timeZoneCode:timeZoneCode,
				tutorialLevel: 0,  
				teamIDs:teamIDs,
				isFromBatchUpload:isFromBatchUpload,
	  };
	  
	 const enqueueCommandInput = {
		  customerID:customerID,
		  command: 'CREATE_USER',
		  params: JSON.stringify(params),
	 };   
	 
	 //Call GraphQL to directly issue the command with our Lambda function 
	 if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to CREATE USER", enqueueCommandInput);
	 const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda', enqueueCommandInput);
	 if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

	 //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
	 let returnParams;
	 if (response && response.data && response.data.processUsersAndTeamsByLambda) {
		  //Turn the return string into an object
		  if (response.data.processUsersAndTeamsByLambda.returnParams) returnParams = JSON.parse(response.data.processUsersAndTeamsByLambda.returnParams);
		  if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);

		  //Specific error?  If so, return it.
		  if (returnParams && returnParams.error && response.data.processUsersAndTeamsByLambda.lambdaReturnStatus === "FAILED") {
				 return ({successFlag:false, newUser:null, error:returnParams.error});             
		  }
		  
		  //Success? If so, fetch our new user via App Sync and return the object to the caller
		  if (returnParams && returnParams.id && response.data.processUsersAndTeamsByLambda.lambdaReturnStatus === "SUCCESS") {
				const tempInsertedUser =  await getDataRecordById(getUser, 'getUser', returnParams.id);
				if (DEBUG_MODE >= 2) console.log('Successfully wrote new user to DB', tempInsertedUser);
				return ({successFlag:true, newUser:tempInsertedUser, error:''});  
		  } 
	} 
  
  //Must have failed if reached here 
 if (DEBUG_MODE >= 2) console.log('ERROR - unable to create the user:');
 
 return ({successFlag:false, newUser:null, error:'Unable to create user'});             

	 } catch (err) {
		  if (DEBUG_MODE >= 2) console.log('error creating user:', err);
		  return ({successFlag:false, newUser:null, err:err});
	 }   
}

export async function invokeUpdateUser(
	 {   cmdIssuerID, userInputData,  teams, avatarWidth, avatarHeight, avatarUrl, selectedTeamsOptions,
		  mentorID, sponsorID, supervisorID, canBeMentor, canBeSponsor, canBeSupervisor, bio,
		  startDate, timeZoneCode
	 }) {


if (!cmdIssuerID ||  !userInputData  || !userInputData.customerID  || !userInputData.firstName || !userInputData.lastName || !userInputData.email ) {
	 if (DEBUG_MODE >= 2) console.log("ERROR UPDATING USER - improper params", cmdIssuerID, userInputData);
	 return ({successFlag:false, error:'Unable to create user'});             
} 

if (DEBUG_MODE >= 2) console.log("Issuing UPDATE User Command to Lambda", userInputData);

try {

	 //Generate array of IDs for teams on which to include the user
	 let teamIDs = [];
	 const allEmployeesTeam = teams.find(team => team.name === "ALL EMPLOYEES" && team.customerID === userInputData.customerID);
	 if (allEmployeesTeam && allEmployeesTeam.id) teamIDs.push(allEmployeesTeam.id);
	 //More teams to add?
	 if (selectedTeamsOptions && selectedTeamsOptions.length >0) {
		  for (var i=0; i<selectedTeamsOptions.length;i++) {
				if (selectedTeamsOptions[i].id !== allEmployeesTeam.id) teamIDs.push(selectedTeamsOptions[i].id);
		  }
	 }
	 
	 if (DEBUG_MODE >= 2) console.log("Generated array of team IDs", teamIDs);

	 //Direct call to our Enqueue Command lambda
	  const params = {
				cmdIssuerID: cmdIssuerID,
				customerID: userInputData.customerID,                         //ID of the customer in which to create the new user
				firstName: userInputData.firstName,
				middleName: (!userInputData.middleName ? "" : userInputData.middleName),
				lastName: userInputData.lastName,
				pronouns: userInputData.pronouns,
				bio: (bio ? bio : ""),
				email: userInputData.email.toLowerCase(),
				title: userInputData.title,
				avatarHeight: avatarHeight,
				avatarWidth: avatarWidth,
				avatarUrl: avatarUrl,
				permissionAnalytics:userInputData.permissionAnalytics,
				permissionLaunch: userInputData.permissionLaunch,
				permissionEditor: userInputData.permissionEditor,
				permissionOrders: userInputData.permissionOrders,
				permissionBilling: userInputData.permissionBilling,
				mentorID:  mentorID ? mentorID : "",
				sponsorID:  sponsorID ? sponsorID : "",
				supervisorID:  supervisorID ? supervisorID : "",
				canBeMentor: userInputData.canBeMentor ? true : false,          // User can be selected as a Mentor
				canBeSponsor: userInputData.canBeSponsor ? true : false,        // User can be selected as a Sponsor
				canBeSupervisor:userInputData.canBeSupervisor ? true : false,   // User can be selected as a Supervisor
				isUnderrepresentedGroup:userInputData.isUnderrepresentedGroup ? true : false,
				startDate: startDate,
				timeZoneCode:timeZoneCode,
				tutorialLevel: 0,  
				teamIDs:teamIDs,
	  };
	  
	 const enqueueCommandInput = {
		  userID: userInputData.id,               //ID of user to edit
		  customerID:userInputData.customerID,
		  command: 'UPDATE_USER',
		  params: JSON.stringify(params),
	 };   
	 
	 //Call GraphQL to directly issue the command with our Lambda function 
	 if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to UPDATE USER", enqueueCommandInput);
	 const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda',enqueueCommandInput);
	 if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

	 if (response && response.data && response.data.processUsersAndTeamsByLambda) {
		 //Success?
		  if (response.data.processUsersAndTeamsByLambda.lambdaReturnStatus === "SUCCESS") {
				if (DEBUG_MODE >= 2) console.log('Successfully update user');
				return ({successFlag:true,  error:''});  
		  } else {

				//Extract results from our returnObject; Our params is a stringified JSON, so lets unpack it
				let returnParams;
				if (response.data.processUsersAndTeamsByLambda.returnParams) returnParams = JSON.parse(response.data.processUsersAndTeamsByLambda.returnParams);
				if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);
				return ({successFlag:false, error:(returnParams && returnParams.error ? returnParams.error : "Unable to update user")});    
		  }
	} else {
		  //Something went wrong if reached here 
		  if (DEBUG_MODE >= 2) console.log('ERROR - unable to update the user:');
		  return ({successFlag:false, error:'Unable to update user'});   
	}

} catch (err) {
	 if (DEBUG_MODE >= 2) console.log('error updating user:', err);
	 return ({successFlag:false, err:err});
}   
}

export async function invokeDeleteUser({userToDelete, cmdIssuerID}) {

//Safety Check
if (!cmdIssuerID || !userToDelete || !userToDelete.id || !userToDelete.customerID) {
	 if (DEBUG_MODE >= 2) console.log("Error deleting user - improper params", cmdIssuerID, userToDelete);
	 return false;
}
if (DEBUG_MODE >= 2) console.log("Issuing DELETE User to Lambda", userToDelete);

try {

 //Direct call to our Enqueue Command lambda
 const params = {
		  cmdIssuerID: cmdIssuerID,           //Who issued the this delete CMD
 };
 
const enqueueCommandInput = {
	 userID: userToDelete.id,        //ID of user to delete
	 customerID: userToDelete.customerID,                         //ID of the customer in which the user to be deleted exists
	 command: 'DELETE_USER',
	 params: JSON.stringify(params),
};   

//Call GraphQL to directly issue the command with our Lambda function 
if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to DELETE USER", enqueueCommandInput);
const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda', enqueueCommandInput);
if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

if (response && response.data && response.data.processUsersAndTeamsByLambda) return (response.data.processUsersAndTeamsByLambda.lambdaReturnStatus ==="SUCCESS");
else return false;

} catch (err) {
	 if (DEBUG_MODE >= 2) console.log('error deleting user:', err);
	 return false;
}
}

//
//  TEAM CRUD OPERATIONS
//

export async function invokeCreateTeam({ cmdIssuerID, customerID, teamInputData, selectedUsersOptions }) {
 
	 if (!cmdIssuerID || !customerID ||  !teamInputData || !teamInputData.name ) {
		  if (DEBUG_MODE >= 2) console.log("ERROR CREATING USER - improper params", cmdIssuerID, customerID, teamInputData, selectedUsersOptions);
		  return ({successFlag:false, newTeamID:null, error:'Unable to create team'});             
	 } 
	 
		  if (DEBUG_MODE >= 2) console.log("Issuing Create Team Command to Lambda", teamInputData);
	 
		  try {

		  //Generate array of IDs for users to include on the new team
		  let memberIDs = [];
			if (selectedUsersOptions && selectedUsersOptions.length >0) {
				for (const user of selectedUsersOptions)  memberIDs.push(user.id);
		  }
		  if (DEBUG_MODE >= 2) console.log("Generated array of member IDs", memberIDs);

		  //Direct call to our Enqueue Command lambda
			const params = {
					 cmdIssuerID: cmdIssuerID,
					 name: teamInputData.name,
					 nickname: teamInputData.nickname,
					 department: teamInputData.department,
					 memberIDs:memberIDs,
			};
			
		  const enqueueCommandInput = {
				customerID:customerID,
				command: 'CREATE_TEAM',
				params: JSON.stringify(params),
		  };   
		  
		  //Call GraphQL to directly issue the command with our Lambda function 
		  if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to CREATE USER", enqueueCommandInput);
		  const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda', enqueueCommandInput);
		  if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

		  //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		  let returnParams;
		  if (response && response.data && response.data.processUsersAndTeamsByLambda) {
				//Turn the return string into an object
				if (response.data.processUsersAndTeamsByLambda.returnParams) returnParams = JSON.parse(response.data.processUsersAndTeamsByLambda.returnParams);
				if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);

				//Specific error?  If so, return it.
				if (returnParams && returnParams.error && response.data.processUsersAndTeamsByLambda.lambdaReturnStatus === "FAILED") {
					  return ({successFlag:false, newTeamID:null, error:returnParams.error});             
				}
				
				//Success? 
				if (returnParams && returnParams.id && response.data.processUsersAndTeamsByLambda.lambdaReturnStatus === "SUCCESS") {
					 if (DEBUG_MODE >= 2) console.log('Successfully wrote new team to DB.  ID:', returnParams.id);
					 return ({successFlag:true, newTeamID:returnParams.id, error:''});  
				} 
		  } 
		
		  //Must have failed if reached here 
		  if (DEBUG_MODE >= 2) console.log('ERROR - unable to create the team:');
		  return ({successFlag:false, newTeamID:null, error:'Unable to create team'});             
 
	 } catch (err) {
		  if (DEBUG_MODE >= 2) console.log('error creating team:', err);
		  return ({successFlag:false, newTeamID:null, err:err});
	 }   
}

export async function invokeUpdateTeam({ cmdIssuerID, teamInputData, selectedUsersOptions }) {
 
	 if (!cmdIssuerID ||  !teamInputData  || !teamInputData.customerID  || !teamInputData.name || !teamInputData.id ) {
		  if (DEBUG_MODE >= 2) console.log("ERROR UPDATING TEAM - improper params", cmdIssuerID, teamInputData);
		  return ({successFlag:false,  error:'Unable to update team'});             
	 } 
	 
		  if (DEBUG_MODE >= 2) console.log("Issuing UPDATE Team Command to Lambda", teamInputData);
	 
		  try {

		 //Generate array of IDs for users to include on the new team
		  let memberIDs = [];
			if (selectedUsersOptions && selectedUsersOptions.length >0) {
				for (const user of selectedUsersOptions)  memberIDs.push(user.id);
		  }
		  if (DEBUG_MODE >= 2) console.log("Generated array of member IDs", memberIDs);

		  //Direct call to our Enqueue Command lambda
			const params = {
					 teamID:teamInputData.id,
					 cmdIssuerID: cmdIssuerID,
					 name: teamInputData.name,
					 nickname: teamInputData.nickname,
					 department: teamInputData.department,
					 memberIDs:memberIDs,
			};
			
		  const enqueueCommandInput = {
				customerID:teamInputData.customerID,
				command: 'UPDATE_TEAM',
				params: JSON.stringify(params),
		  };   
				
		  //Call GraphQL to directly issue the command with our Lambda function 
		  if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to UPDATE USER", enqueueCommandInput);
		  const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda', enqueueCommandInput);
		  if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

		  //Extract results from our returnObject; Our params is a stringified JSON, so lets unpack it
		  if (response) {
			  //Success?
				if (response.lambdaReturnStatus === "SUCCESS") {
					 if (DEBUG_MODE >= 2) console.log('Successfully update team');
					 return ({successFlag:true,  error:''});  
				} else {

					 //Extract results from our returnObject; Our params is a stringified JSON, so lets unpack it
					 let returnParams;
					 if (response.returnParams) returnParams = JSON.parse(response.returnParams);
					 if (DEBUG_MODE >= 2) console.log('Return params from Lambda', returnParams);
					 return ({successFlag:false, error:(returnParams && returnParams.error ? returnParams.error : "Unable to update team")});    
				}
		  } else {
				//Something went wrong if reached here 
				if (DEBUG_MODE >= 2) console.log('ERROR - unable to update the team:');
				return ({successFlag:false, error:'Unable to update team'});   
		  }          

	 } catch (err) {
		  if (DEBUG_MODE >= 2) console.log('error updating team:', err);
		  return ({successFlag:false, err:err});
	 }   
}

export async function invokeDeleteTeam({cmdIssuerID, teamIDToDelete, customerID}) {
	 //Safety Check
	 if (!cmdIssuerID || !teamIDToDelete || !customerID) {
		  if (DEBUG_MODE >= 2) console.log("Error deleting team - improper params", cmdIssuerID, teamIDToDelete, customerID);
		  return false;
	 }
	 
	 if (DEBUG_MODE >= 2) console.log("Processing DELETE TEAM ID", teamIDToDelete);
	 
	 try {

	  //Direct call to our Enqueue Command lambda
	  const params = {
		  cmdIssuerID: cmdIssuerID,           //Who issued the this delete CMD
		  teamID:teamIDToDelete,
	  };
	  
	 const enqueueCommandInput = {
		  customerID: customerID,         //ID of the customer in which the team is to be deleted 
		  command: 'DELETE_TEAM',
		  params: JSON.stringify(params),
	 };   
	 
	 //Call GraphQL to directly issue the command with our Lambda function 
	 if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to DELETE TEAM", enqueueCommandInput);
	 const response = await invokeAPI(processUsersAndTeamsByLambda, 'processUsersAndTeamsByLambda',enqueueCommandInput);
	 if (DEBUG_MODE >= 2) console.log("ProcessUsersAndTeams Lambda returned", response);        

	 return (response && response.lambdaReturnStatus ==="SUCCESS");
		  
	 } catch (err) {
		  if (DEBUG_MODE >= 2) console.log('error deleting team:', err);
		  return false;
	 }
}

//Main function for gifting coins     
export async function invokeSendGift ({currentUser, users, giftMessage, coinsToGift, selectedUsersToGift, pushNotification})  {

		  //Safety checks
		  if (!selectedUsersToGift || selectedUsersToGift.length === 0 || !coinsToGift || coinsToGift =="" || isNaN(coinsToGift)) return false;
		  
		  var successFlag = false;
		  
		  try { 
				//Processes individual users based on selected list
				 for (var j=0 ; j < selectedUsersToGift.length; j++) {
	 
					 //Find the corresponding user object and record transaction from giftor to recipient
					 const recipient = users.find(user => user.id === selectedUsersToGift[j].id);  
					 
					  if (recipient) {

						  //record transaction for Giftor
						  await recordTransaction({
								walletOwner:currentUser, 
								title: getUserInitials(currentUser) + " gave a gift to " + getUserInitials(recipient)  + "!",
								memo:"Gift given!", 
								amount:parseInt(coinsToGift)*-1,
								transactionType:"GIFT_SENT",
						  });              
								
						  //Send a push notification to the Giftor that they gave a gift!
						  await pushNotification({
								notificationTypeLabel:'GIFT_SENT', 
								userForPush:currentUser, 
								dynamicMessage: "Your gift of " + coinsToGift + " coins has been sent to " + getUserInitials(recipient)  + "!", 
						  });    
 
						  //record transaction for Giftee
						  await recordTransaction({
								walletOwner:recipient, 
								title: getUserInitials(currentUser) + " gave a gift to " + getUserInitials(recipient)  + "!",
								memo:"Gift received!", 
								amount:parseInt(coinsToGift),
								transactionType:"GIFT_RECEIVED",
						  });              

						  //Send a push notification to the Giftee that they received a gift!
						  await pushNotification({
								notificationTypeLabel:'GIFT_RECEIVED', 
								userForPush:recipient, 
								dynamicMessage: "You received a gift of " + coinsToGift + " coins from " + getUserInitials(recipient)  + "!", 
						  });    

					 //Call GraphQL to directly invoke our Lambda function for sending a gift email
					 const lambdaResponse = await invokeAPI(sendGiftEmail, 'sendGiftEmail',
						  {
								senderName: currentUser.firstName + " " + currentUser.lastName,
								senderEmail: currentUser.email.toLowerCase(),
								recipientName: recipient.firstName + " " + recipient.lastName,
								recipientEmail: recipient.email.toLowerCase(),
								amount:parseInt(coinsToGift),
								message:giftMessage,
						  });
					 if (DEBUG_MODE >= 2) console.log("Send Gift lambda returned", lambdaResponse);               
					 }               
				}
								
				 successFlag = true;    //Set to TRUE if no thrown errors, ignore lambda response as DB functions worked
				 
		  } catch (err) {
				if (DEBUG_MODE >= 2) console.log('error creating recognition:', err);
				successFlag = false;
		  }

	 return successFlag;

}

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved

/* 
	MSFT Teams Resources
	https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/?view=msteams-client-js-latest

*/

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, OUR_COGNITO_MSFT_IDP, OUR_COGNITO_AZURE_OIDC } from '../data/conectereConfigData';

//Amplify, React
import { signInWithRedirect, signOut, getCurrentUser } from "aws-amplify/auth";

import React, { useCallback, useEffect, useState, useContext, useRef, useLayoutEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

//Our Components
import SpinnersConectere from "../Components/spinnerConectere/spinnerConectere";


//Context
import { AuthContext } from './authContext';
import { NotificationContext } from './notificationsContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context
import { ModalContext } from './modalContext';            
import { DisplayContext } from './displayContext';            //User Authentication Contex

//Utils
import {getCognitoSession, getCognitoAuthenticatedUser, decodeJWT } from "../utils/awsUtils";

const MsftTeamsContext = React.createContext();


const MsftTeamsProvider = ({ children }) => {

	// Context
	const {  authState, currentUser }  = useContext(AuthContext);
	const { usersOptions,  } = useContext(CustomerContext);   
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
	const { setShowSpinner, isRenderedWithinMsftClient, clientDisplayModeDetected, } = useContext(DisplayContext); 

	//Local State
	const [teamsInitialized, setTeamsInitialized] = useState(false);
	const [teamsIdToken, setTeamsIdToken] = useState(null);
	const [teamsContext, setTeamsContext] = useState(null);
	const [authResult, setAuthResult] = useState(null);
	const [authError, setAuthError] = useState(null);
	//
	//Init on Mount
	//

	useEffect(() => {

		//Our internal Init function
		async function initTeams() {
			try {
					if (DEBUG_MODE) console.log("Initializing the MSFT Teams SDK");
	
					// Initialize Teams SDK when the component mounts
					// This will FAIL if we are not wrapped in the Teams environment
					await microsoftTeams.app.initialize();
					if (DEBUG_MODE) console.log('Teams SDK initialized');

	
					// Get context when ready
					let context = await microsoftTeams.app.getContext();
					setTeamsContext(context);
					if (DEBUG_MODE) console.log('Teams context:', context);
	
					//Get the ID Token (i.e. the bootstrap token, which is NOT an AuthToken)
					/* Token format

					*/
					let idToken;
					idToken = await microsoftTeams.authentication.getAuthToken();
					const decodedToken = decodeJWT(idToken);
					if (DEBUG_MODE) console.log("Extracted our idToken from Teams , i.e., the bootstrap token to be used by our backend if needed.  idToken:", decodedToken);

					setTeamsIdToken(idToken);
					setTeamsInitialized(true);
								
			} catch (err) {
				if (DEBUG_MODE) console.error("ERROR inializing MSFT Teams SDK",err);
			}
		}

		//Body of the useEffect - run on Mount
		if (true || isRenderedWithinMsftClient && !teamsInitialized) initTeams();

	}, []); 


	// ***  SINCE WE ARE LEVERAGGING SSO with Microsoft Teams, this FULL OAUTH popup-based flow is unnecessary.
	//Handler for logging in user, and creating a new account if necessary.
	/*
	const loginTeamsUserFullOAuthPopup = async (teamsIdToken, context) => {
		try {
			let success, responseMsg;

			//Initiate Team OAuthFlow so Teams will in fact show a pop-up to our Auth URL
			const authWindowUrl = window.location.origin + '/auth/auth-start-teams'; 	// URL to your auth-start page
			const authCompleteUrl = `${window.location.origin}/auth/auth-end-teams`;  // URL to your auth-complete page
	
			if (DEBUG_MODE) console.log("Teams LOGIN button pressed. Redirecting to " + authWindowUrl);

			await microsoftTeams.authentication.authenticate({
				url: authWindowUrl, // The URL of your authentication page
				width: 600,         // Optional: Customize the width of the popup window
				height: 500,        // Optional: Customize the height of the popup window
				successCallback: (result) => {
					console.log("Microsoft Authentication succeeded:", result);
					success = true, responseMsg = result;
					// Handle successful authentication, e.g., save tokens, etc.
					setAuthResult(result);

					const authenticatedUser = Auth.currentAuthenticatedUser();
					if (DEBUG_MODE) console.log("Loaded Teams user", authenticatedUser);
				 },
				 failureCallback: (error) => {
					console.log("Microsoft Authentication failed:", error);
					responseMsg = error;
					setAuthError(error);
				 }
			});

			// if (!success) microsoftTeams.authentication.notifyFailure(responseMsg || 'Authentication failed');

		

			// if (!teamsIdToken) {
			// 	if (DEBUG_MODE) console.error("Error - no Teams Auth code", teamsIdToken);
			// 	return;
			// }

			// //Send our Teams ID token to the backend for full processing of the user and the user's company
			// if (DEBUG_MODE) console.log("Sending AuthCode to retrieve User login information via backend.  AuthCode:", teamsIdToken);
			// result = await sendAuthCodeToTeamsLambda(teamsIdToken, context);
			// if (DEBUG_MODE) console.log("Teams Lambda returned", result);

		} catch (err) {
			console.log("Error logging in Teams user.  ", err);
			setAuthError(err);
			microsoftTeams.authentication.notifyFailure(err || 'Authentication failed');
		}
	}
*/

/****
 * 	AWS signInWithRedirect - Signs in a user with OAuth flow. Redirects the application to an Identity Provider.
 * 	Resources: 
 * 		https://stackoverflow.com/questions/77717810/aws-amplify-v6-using-signinwithredirect-instead-of-auth-federatedsignin-doesnt
 * 		https://docs.amplify.aws/gen1/react/build-a-backend/auth/add-social-provider/
 */
	const loginTeamsUser = async (teamsIdToken, context) => {
		try {
			//Must have an ID Token from Teams SSO to proceed
			if (!teamsIdToken) {
				if (DEBUG_MODE) console.error("Must have an ID Token from Teams SSO to proceed", teamsIdToken);
				return;
			}			

			let session, credentials;

			if (DEBUG_MODE) console.log("Checking for existing Cognito session");
			session = await getCognitoSession();
			if (session) {
				const accessToken = session.getAccessToken().getJwtToken();
				console.log('Access Token from existing sessions:', accessToken);

			}

			if (!session) {
				if (DEBUG_MODE) console.log("No Cognito session detected.  Attempting Federated SignIn using Teams ID Token", decodeJWT(teamsIdToken));
				
				const provider = {
					custom: "login.microsoftonline.com/e36e0de8-e059-43db-bee6-d2f7a5086805/v2.0"		//Our well-known microsoft OIDC link with our tenent
				 }

				//Invoke OAuth signin, which will cause our HUB listener to handle login state change
				await signInWithRedirect(
					{
						provider,
						token:teamsIdToken,
						// scopes: ['openid', 'profile', 'email'] //Specify these so as to override default scopes 
																			//which may be broader than allowed scopes we specified in Azure 
					});
				

			} // End if no current session


			// if (!teamsIdToken) {
			// 	if (DEBUG_MODE) console.error("Error - no Teams Auth code", teamsIdToken);
			// 	return;
			// }

			// //Send our Teams ID token to the backend for full processing of the user and the user's company
			// if (DEBUG_MODE) console.log("Sending AuthCode to retrieve User login information via backend.  AuthCode:", teamsIdToken);
			// result = await sendAuthCodeToTeamsLambda(teamsIdToken, context);
			// if (DEBUG_MODE) console.log("Teams Lambda returned", result);

		} catch (err) {
			console.log("Error logging in Teams user.  ", err);
			setAuthError(err);
			microsoftTeams.authentication.notifyFailure(err || 'Authentication failed');
		}
	}
   //Render the Teams Login buttons
   const renderMicrosoftLogin = (teamsIdToken, teamsContext) => {

      return (
         <div className="ContainerVerticalCenter">
            <div className="TextStyle5 purple"> Hi there! 👋 Please sign in with your Microsoft account to access Conectere!</div>

            <button onClick={() => loginTeamsUser(teamsIdToken, teamsContext)} >
               Sign In with Microsoft
            </button>

            <div className={`calendarAccessControlCard ContainerNoHeightCenter`} onClick={() => loginTeamsUser(teamsIdToken, teamsContext)}> 
                  <div className="ContainerNoHeightCenter"  style={{minHeight:"70px", width:"50px", backgroundColor:"white"}}>
                     <img  style={{height:"30px"}} src={CONECTERE_CONFIG_DATA.MSFT_LOGO} alt=''/>
                  </div>
                  <div style={{padding:"0.5vh 1vh", flex:1}}>
                        Sign in with Microsoft
                  </div>
            </div>
         </div>
      )
   }

	//return the provider
	return (				

		<MsftTeamsContext.Provider value={
		{   
				// For our children...
				teamsIdToken, teamsContext, renderMicrosoftLogin, microsoftTeams

		}}>

			{children}
		</MsftTeamsContext.Provider>
	); 
};


export { MsftTeamsContext, MsftTeamsProvider };
/*

  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  copyright - 2020, 2021


*/


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLACK, COLOR_WHITE  } from '../../data/conectereConfigData'; 

//Amplify, React
import React, { memo, useEffect, useState, useContext } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { DisplayContext } from '../../context/displayContext';            
import { LeaderboardContext } from '../../context/leaderboardContext';            //Customer Authentication context
import { ConectivityContext } from '../../context/conectivitiesContext';


//Utils
import { ConectivityDetailedViewWindowControl, dispayConectivityDescription, displayConectivityInstructions, setHeaderColorByCategory, displayConectivityHeader, setHeaderColorClassByCategory, setBackgroundColorClassByCategory } from "../../utils/conectivityUtils";
import { displayUsersRow, getRSVPStatus } from "../../utils/userAndTeamUtils";
import { EditButton, DeleteButton, DeclineRsvpButton, AcceptRsvpButton, sortMessages } from "../../utils/generalUtils";
import { buildRecurrenceString, formatInvitationInstanceDateTime, isEndDateInPast } from "../../utils/dateTimeUtils";
import { fetchMessagesByLaunchRule, fetchConectivity, fetchChallenge } from "../../utils/databaseUtils";

//Our components
import ModalNoBackgroundFixed from "../../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import StarRatings from "../../Components/starRatings/starRatings";
import { ChatWindow } from "../../Components/chatWindow/chatWindow";
import { LeaderBoardList, ChallengePreview} from "../../Components/leaderboard/leaderboard";

//Icons
import RepeatIcon from '@mui/icons-material/Repeat';
import StarIcon from '@mui/icons-material/Star';


//Inputs EITHER a challenge OR a leaderboard
//Displays EITHER a Challenge Preview for challenges that have not yet become active leaderboards OR a Leaderboard
const LeaderboardWindow = ({challenge, leaderBoard, conectivity, cardSelectedCallback }) => {
	//Safety check
	if (!challenge && !leaderBoard) return null;
	return (
		<div className="lboard_wrap white-background borderRadius positionRelative boxShadow" style={{marginBottom:"clamp(2px,0.5vw,4px)"}}> 
			{leaderBoard ?
				<LeaderBoardList  leaderBoard={leaderBoard} displayZeros={true}  cardSelectedCallback= {cardSelectedCallback} showCountdown/>
				:
				<ChallengePreview challenge={challenge} conectivity={conectivity} launchRuleInstanceStartDateTime={leaderBoard.startDateTime} launchRuleInstanceEndDateTime={leaderBoard.endDateTime} launchRuleInstanceIndex={leaderBoard.launchRuleInstanceIndex} />
			}
			</div>
	);	
}

//  Inputs:
//       - scheduled conectivity & invitation are optional and only provided if the user is viewing a PAST calendar event having a corresponding SC 


const CalendarEntryPreview = memo(({
	showModal, conectivityToView, launchRule, scheduledConectivity, invitation,
	launchRuleInstanceStartDateTime, launchRuleInstanceEndDateTime, launchRuleInstanceIndex, 
	closeModalCallback, editCallback, deleteCallback, declineCallback, acceptCallback, markCompleteCallback}) => {

   
	// Context
	const { darkMode } = useContext(DisplayContext); 
	const { currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);    
	const { users, teams, teamsOptions, participatingUsersOptions } = useContext(CustomerContext); 
	const { challengesToDisplay, leaderboardSpotsCache, currentLeaderboard, handleLeaderboardCardSelected, setCurrentLeaderboard } = useContext(LeaderboardContext);
	const { messagesToShow, setMessagesToShow, setChatWindowID } = useContext(ConectivityContext);

	//State vars
	const [entryDate, setEntryDate] = useState("");
	const [usersToShow, setUsersToShow] = useState([]);
	const [sender,setSender] = useState(null);
	const [isReadOnly, setIsReadOnly] = useState(true);
	const [colorClass, setColorClass] = useState('balance');
	const [headerColor, setHeaderColor] = useState('black');
	const [recurrenceString, setRecurrenceString] = useState('');
	const [currentUserRsvpStatus, setCurrentUserRsvpStatus] = useState(null);
	const [localRsvpRecords, setLocalRsvpRecords] = useState([]);
	const [displayLeaderboard, setDisplayLeaderboard] = useState(false);
	const [displayChallengePreview, setDisplayChallengePreview] = useState(false);
	const [challengeToDisplay, setChallengeToDisplay] = useState(null);
	// const [leaderBoardSpotsToDisplay, setLeaderBoardSpotsToDisplay] = useState([]);
	const [challengeExpired, setChallengeExpired] = useState(null);
	const [chatPortionIsExpanded, setChatPortionIsExpanded] = useState(true);
	const [leaderboardPortionIsExpanded, setLeaderboardPortionIsExpanded] = useState(true);

 
	// Has the conectivity alread started?  If so, allow the user to mark it as complete

	//Upon load, determine if the screen already has a Y position, i.e, has been scrolled down
	//If so, use that for TOP in our position ABSOLUTE so the modal pops up centered on the current screen rather than back up on the top
	//Note, we have to use position ABSOLUTE so that screen scroll with the page if the user scrolls down to read the rest of the modal
	//Also, we need to read the full height of the entire document and dynamically set the height of our background layer so that it covers the entire document
	//as the user scrolls up/down
	
	const screenPositionY = window.scrollY;
	const documentHeight = document.body.clientHeight;

	// if (DEBUG_MODE >= 2) console.log("Detailed View Launch - page scrolled", conectivityToView, screenPositionY, documentHeight);
	

	//UseEffect to update preview data in reponse to any change of the launchrule
	useEffect(() => {

		//safety check
		if (!showModal || !launchRule || !launchRuleInstanceStartDateTime || !conectivityToView) return;

		//Set whether the user can edit this particular calendar entry
		setIsReadOnly(launchRule.senderID !== currentUser.id);

		//Set the user's RSVP status for this calendar event (LR)
		setCurrentUserRsvpStatus(launchRule.rsvpRecords && launchRule.rsvpRecords.length>0 ? getRSVPStatus(currentUser.id, launchRuleInstanceIndex, launchRule.rsvpRecords) : null);
		setLocalRsvpRecords(launchRule.rsvpRecords && launchRule.rsvpRecords.length>0 ?launchRule.rsvpRecords : []);
		if (DEBUG_MODE >= 2) console.log("Updating RSVP status on Calendar Entry Preview", launchRule);
		

		
		//Set the friendly calendar date to display FOR THE SELECTED LR INSTANCE
		if (launchRuleInstanceStartDateTime && launchRuleInstanceEndDateTime) {
			 setEntryDate(formatInvitationInstanceDateTime({eventStartDateTime:launchRuleInstanceStartDateTime, eventEndDateTime:launchRuleInstanceEndDateTime, allDayEvent:launchRule.allDayEvent, timeZoneCode:currentUser.timeZoneCode}));
		}

		setColorClass(setHeaderColorClassByCategory(conectivityToView.category.label));
		setHeaderColor(setHeaderColorByCategory(conectivityToView.category.label));
		setRecurrenceString(buildRecurrenceString(launchRule));

		//Grab the users to display 
		var tempUsersToShow = [];
		for (var j = 0; j < launchRule.usersToInvite.length; j++){  
			 //Look in the array of USER Options to find the dropdown option for the user on this team
			const userToShow = users.find(user => user.id === launchRule.usersToInvite[j]);
			
			if (userToShow) {
				tempUsersToShow.push(userToShow);  //Add team to inital selections 
				// console.log ("Pushed user onto UsersToShow:", userToShow);
			} else {
				if (DEBUG_MODE >= 2) console.log("Skipped - user does no longer exists");
			}
		}
		setUsersToShow(tempUsersToShow);
		const sender = users.find(user => user.id === launchRule.senderID);
		if (sender) {
			setSender(sender);   //Set the SENDER to display first
		} else {
			console.log("Error - sender not found", sender, launchRule.senderID);
		}      

		//Init messages
		initMessages();

		//Does this LR have have an invitation with an active Leaderboard, if so, load it
		initLeaderboard();
	}, [showModal, launchRule, conectivityToView, invitation]);


	async function initMessages() {
		if (!launchRule || !launchRule.id  || !currentUser) return;
		let chatWindowID = null, sortedMessages = [];

		try {
				
			if (DEBUG_MODE) console.log("Fetching Messages for Calender Entry Preview", launchRule);
			chatWindowID = launchRule.id;   //Record the ID of this invitation so the context will updated the view in real-time if we receive any messages for this LR from the back-end		
			const tempMessageList = await fetchMessagesByLaunchRule(launchRule.id);
			sortedMessages= sortMessages(tempMessageList);
			if (DEBUG_MODE) console.log("Sorted messages for calendar entry", sortedMessages)

		} catch (err) {
			if (DEBUG_MODE) console.error("Error initializing messages calendar entry.", err, launchRule);
		}
		setChatWindowID(chatWindowID);
		setMessagesToShow(sortedMessages);             			//Update message currenty being viewed
	}


	async function initLeaderboard() {
		//safety check
		if (!launchRule) return;
		
		if (!launchRule.challengeID) {
			if (DEBUG_MODE >1) console.log("LR does not specify a challenge.");
			setChallengeToDisplay(null);
			setDisplayChallengePreview(false);
			setCurrentLeaderboard(null);
			// setLeaderBoardSpotsToDisplay([]);
			setDisplayLeaderboard(false);
		}

		//So, did the selected LR also have an invitation instance that we can display or is this LR in the future
		if (invitation && invitation.leaderboardID) {
			if (DEBUG_MODE) console.log("Detected leaaderboard instance for the LR being viewed.  Initializing LB", invitation.leaderboardID);
			let tempLB, isExpired = false;
			tempLB = challengesToDisplay.find(lb => lb.id === invitation.leaderboardID);
			// if (tempLB && tempLB.id) tempSpots = leaderboardSpotsCache.filter(lbSpot => lbSpot.customerLeaderBoardID === tempLB.id); 
			if (launchRuleInstanceEndDateTime) isExpired = isEndDateInPast({eventEndDateTime:launchRuleInstanceEndDateTime, allDayEvent:launchRule.allDayEvent});
			if (DEBUG_MODE> 1) console.log("Set the isExpired flag to:",isExpired);
			setCurrentLeaderboard(tempLB);
			// setLeaderBoardSpotsToDisplay(tempSpots);
			setDisplayLeaderboard(tempLB ? true : false);
			setChallengeToDisplay(null);
			setDisplayChallengePreview(false);
			setChallengeExpired(isExpired);
		} else {
			//No invitation with active LB? Nevertheless, does this LR have a future challenge?
			if (DEBUG_MODE >1) console.log("No invitation.  Future LR.  Checking if applicable challenge", launchRule);
			let challengeToDisplay = await fetchChallenge(launchRule.challengeID);
			if (DEBUG_MODE >1) console.log("LR specified challenge.  Challenge loaded", challengeToDisplay);
			//clear challenge state but clear LB instance state
			setChallengeToDisplay(challengeToDisplay && challengeToDisplay.id ? challengeToDisplay : null);
			setDisplayChallengePreview(true);
			setCurrentLeaderboard(null);
			// setLeaderBoardSpotsToDisplay([]);
			setDisplayLeaderboard(false);
			setChallengeExpired(false);
		}   
	}


	const handleEdit = () => {
		if (DEBUG_MODE >= 2) console.log("Edit button pressed");
		if (editCallback) editCallback();
	};

	const handleDelete = async () => {
		if (DEBUG_MODE >= 2) console.log("Delete button pressed");
		if (deleteCallback) await deleteCallback();
	};

	const handleDecline = async () => {
		if (DEBUG_MODE >= 2) console.log("Decline rsvp button pressed");
		updateLocalRsvps('DECLINED');
		if (declineCallback) await declineCallback();
	};

	const handleAccept = async () => {
		if (DEBUG_MODE >= 2) console.log("Accept rsvp button pressed");
		updateLocalRsvps('ACCEPTED');
		if (acceptCallback) await acceptCallback();
	};

	function updateLocalRsvps(rsvpStatus) {

	  //Safety check
	  if (!launchRule || !launchRule.id || !currentUser || !rsvpStatus) return;

	  //Update the local cached status 
	  setCurrentUserRsvpStatus(rsvpStatus);
	  
	  //Now, update the user avatar row; Prep an array based on any existing RSVPs
	  let tempRSVPs = [];
	  if (launchRule.rsvpRecords && launchRule.rsvpRecords.length > 0) {
		 tempRSVPs = [...launchRule.rsvpRecords];
		 console.log("Copied existing RSVPs", tempRSVPs);
		 if (launchRuleInstanceIndex) {
			tempRSVPs = tempRSVPs.filter (rsvp => rsvp.userID !== currentUser.id && rsvp.launchRuleInstanceIndex !== launchRuleInstanceIndex);
			console.log("Existing RSVPs after filtering this instance for this user",tempRSVPs);
		 } else {
			tempRSVPs = tempRSVPs.filter (rsvp => rsvp.userID !== currentUser.id);
			console.log("Existing RSVPs after filtering ALL Rsvps for this user",tempRSVPs);
		 } //END IF no LR Instance Index
	  } //END IF Exising RSVPs
 
	   //Insert the RSVP for this user into the RSVP array; update if already present
	  let tempRsvpRecord = { 
		 userID:currentUser.id, 
		 rsvpStatus:rsvpStatus, 
		 launchRuleID:launchRule.id,  
		 launchRuleInstanceIndex:(launchRuleInstanceIndex ? launchRuleInstanceIndex:0)
	  };
	  tempRSVPs.push(tempRsvpRecord);
	  setLocalRsvpRecords(tempRSVPs);
	  if (DEBUG_MODE >= 2) console.log("Updated our local array of RSVPs with this new RSVP",tempRsvpRecord, tempRSVPs);
	}    
		
	//Safety check
	if (!showModal || !conectivityToView || !users) return null;

	const backgroundClass = setBackgroundColorClassByCategory(conectivityToView.category.label);

	//Render function
	return (
	 <ModalNoBackground showModal={showModal} closeCallback={closeModalCallback} cardColor={'rgba(0,0,0,0)'} 
		closeButtonColor={headerColor} closeButtonBackground={headerColor} hideShadow={true} hideInnerBorder={true} >
		<div className="ConectivityDetailedViewContainer borderRadius noBorder"  >
			{ !conectivityToView  || !conectivityToView.isSpecialEvent ? null :
				<div className='conectivityCardHeaderSpecialEventAnchor '>
					<StarIcon className='TextStyle7 light-yellow' />
				</div>
			} 
			<ConectivityDetailedViewWindowControl chatIsButtonActive={chatPortionIsExpanded} chatButtonCallback={()=>{setChatPortionIsExpanded(!chatPortionIsExpanded)}} lbButtonIsActive={leaderboardPortionIsExpanded} lbButtonCallback={()=> {setLeaderboardPortionIsExpanded(!leaderboardPortionIsExpanded)}} displayLbButton={challengeToDisplay || currentLeaderboard} />
			<div className={`ConectivityDetailedViewRow ContainerNoHeightSpaceAround alignStretch  ${(chatPortionIsExpanded ? "chatExpanded" : "chatClosed")} ${(leaderboardPortionIsExpanded ? "lbExpanded" : "lbClosed")} positionRelative`} >
				<div className='conectivityPortion borderRadius' >
					<div className="ConectivitiesDetailedViewCard white-background boxShadow isCard"  >

							{displayConectivityHeader({conectivity:conectivityToView, backgroundClass, darkMode, headerColor})}
				
							<div className = "ContainerNoHeightSpaceBetween" >
									<div className = "ContainerNoHeightFlexLeft" style={{marginLeft:'20px'}}>
										{launchRule && launchRule.launchRepeatOption === 1 ? null :
											<>
												<RepeatIcon style={{fontSize:"1.5rem", color:headerColor}} />
												<div className={`TextStyle3 ${colorClass}` } ><i>{recurrenceString}</i></div>
											</>
										}
									</div> 
				
									<div className = "ContainerNoHeightFlexEndCentered" style={{marginRight:'20px'}}>
										{!scheduledConectivity ? null : 
											<button 
												className="buttonStyle1 buttonStyle1HeaderBlue TextStyle2" 
												aria-label="Save"  
												onClick={markCompleteCallback}
												disabled={false}
											> Mark complete! </button>
										 }
										{isReadOnly ? null : <EditButton onClickCallback={handleEdit} className={colorClass} useRegularContainer/> }
										{isReadOnly ? null : <DeleteButton onClickCallback={handleDelete}  className={colorClass} useRegularContainer/> }
										<AcceptRsvpButton onClickCallback={handleAccept}  isSelected={currentUserRsvpStatus === 'ACCEPTED'} color={headerColor} useRegularContainer/>
										<DeclineRsvpButton onClickCallback={handleDecline} isSelected={currentUserRsvpStatus === 'DECLINED'} color={headerColor} useRegularContainer/> 
									</div>
							</div>
							
							<div className="ContainerVerticalCenter" style={{width:"100%"}}>
								<div style={{padding:"1.0rem"}}>{displayUsersRow({sender:sender, usersToShow:usersToShow, numUsersToShow:7, isSmall:false, rsvpRecords:localRsvpRecords, launchRuleInstanceIndex:launchRuleInstanceIndex})}</div>
								<div className="TextStyle3 black-text" > {entryDate} </div>
							</div>

							{dispayConectivityDescription(conectivityToView.description)}
							{displayConectivityInstructions(conectivityToView.instructions)}
												
							<div className="ContainerNoHeightSpaceAround fullWidth positionRelative wrap conectivityCardActionsWrapper">
									{conectivityToView.ratingStatsID == null ? "" :
										<div  style={{display:"flex", padding:"10px 5px"}}>
											<StarRatings rating={conectivityToView.ratingStats.ratingsAverage} readOnly={true} starSize={"12px"}/>
										</div>
									}
									<div  style={{display:"flex", padding:"10px 5px", color:setHeaderColorByCategory(conectivityToView.category.label)}}><i> {!conectivityToView.approvedPaidTime ? "" : conectivityToView.approvedPaidTime + " mins"}</i></div>
									
									{!conectivityToView.conectCoins >0 ? "" :
										<div style={{display:"flex", padding:"10px 5px", flexwrap:"no-wrap", color:setHeaderColorByCategory(conectivityToView.category.label)}}> 
											{!conectivityToView ? "" : conectivityToView.conectCoins} 
											<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /> 
										</div>                        
									}
									{!conectivityToView.badgesDEI>0 ? "" :  
										<div style={{ padding:"0px 10px", flexwrap:"no-wrap", color:setHeaderColorByCategory(conectivityToView.category.label)}}> 
											{!conectivityToView ? "" : conectivityToView.badgesDEI} 
											<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
										</div>
									}
									{!conectivityToView.badgesCS>0 ? "" :  
										<div style={{padding:"0px 10px", flexwrap:"no-wrap", color:setHeaderColorByCategory(conectivityToView.category.label)}}> 
											{!conectivityToView ? "" : conectivityToView.badgesCS} 
											<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /> 
										</div>
									}
							
							</div>                           
					</div>
				</div>
				<div className='chatPortion' >
					<LeaderboardWindow challenge={challengeToDisplay} leaderBoard={currentLeaderboard} conectivity={conectivityToView} cardSelectedCallback= {handleLeaderboardCardSelected}/>
					<ChatWindow launchRuleID={launchRule.id}  messagesToShow={messagesToShow} currentUser={currentUser} headerColor={headerColor} />  
				</div>

			</div>


		</div>
	</ModalNoBackground>    
	);
	
}, (prevProps, nextProps) => {
	//Our React MEMO function - do not re-render if no change to the events array
	if (
		(prevProps.showModal === nextProps.showModal) &&
		(prevProps.conectivityToView === nextProps.conectivityToView) &&
		(prevProps.launchRule === nextProps.launchRule) &&
		(prevProps.launchRuleInstanceStartDateTime === nextProps.launchRuleInstanceStartDateTime) &&
		(prevProps.messagesToShow === nextProps.messagesToShow)
		)
	{
		// if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Calendar Entry Preview");
		return true; // props are equal
	}
	// if (DEBUG_MODE >= 2) console.log("Re-RENDER the Calendar Entry Preview!");
	return false; // props are not equal -> update the component
});

export default CalendarEntryPreview;
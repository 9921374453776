//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved

//Style
import './animatedSpotlightCard.css'; 

//Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, HEADER_FULL_MODE, COLOR_GOLD, COLOR_BLUE_HEADER, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR, COLOR_WHITE,
			CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD, GIPHY_ID_IDENTIFIER  } from '../../data/conectereConfigData';

//Amplify, React
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';

//Queries and Mutations
import { updateRecognitionComment, updateRecognition,  createRecognitionComment } from '../../graphql/mutations';

//Context
import { AuthContext } from '../../context/authContext';
import { CustomerContext } from '../../context/customerContext';            //Customer Authentication context
import { GiphyCacheContext } from '../../context/giphyCacheContext';
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context

//Our Components
import { ChatWindow } from "../chatWindow/chatWindow";

//Utils
import { ConectereDivider,  timeAgo, truncateStringWithDots } from "../../utils/generalUtils";
import { drawSpotlightCurvedText, displayRecognizedUsersAvatars } from "../../utils/spotlightUtils.js";
import { invokeAPI, storeUpdatedRecognition } from "../../utils/databaseUtils";

//Material UI 
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';
import {Edit, DeleteOutline} from '@material-ui/icons';

//Icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageIcon from '@mui/icons-material/Message';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import HighlightIcon from '@mui/icons-material/Highlight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Next, Prev Button
function nextPrevActionButton ({isNext, handleClickNextPrev}) {
	return (
		<div className="spotlightNextPrevButtonContainer">
			<IconButton size="large" onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				handleClickNextPrev(isNext ? 'next' : 'prev')
			}}>
				{isNext ? <ArrowForwardIosIcon className="black-text" /> : <ArrowBackIosNewIcon className="black-text" />}
			</IconButton>
		</div>
	)
}

const SpotlightCard = React.memo(({id, spotlightToView, spotlightClickCallback, isSmall, isFull, noBoxShadow, handleEditSpotlightClick, handleDeleteSpotlightClick, handleClickNextPrev, handleCastClick, animationClass}) => {
	
	if (!handleEditSpotlightClick) if (DEBUG_MODE >= 2) console.log("NO HANDLER for editing Spotlight", spotlightToView, spotlightToView.id, handleEditSpotlightClick, handleDeleteSpotlightClick);

	//Initialize comments to show right away - prior to component mount & render of an empty array
	//This way our screen does not blink blank when a spotlight update is received
	const [messagesToShow, setMessagesToShow] = useState(initMessges(spotlightToView));
	const [spotlight, setSpotlight] = useState(initSpotlight(spotlightToView));     //Our local React state var for this component
	const [showFullSpotlight, setShowFullSpotlight] = useState(false);
	const [mouseOver, setMouseOver] = useState(false);
	const [showSpotlightInnerMenu, setShowSpotlightInnerMenu] = useState(false);
	const [spotlightGiphyGridContainerDivSize, setSpotlightGiphyGridContainerDivSize]  = useState(450);   //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match
	const [showLikesList, setShowLikesList] = useState(null);                       //ID of Spotlight or Comment that is currently being hovered over
	// const [spotlightPreviewReplyGiphyGridContainerDivSize, setSpotlightPreviewReplyGiphyGridContainerDivSize]  = useState(400);   //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match
	const [selectedSpotlight, setSelectedSpotLight] = useState(null);
	const [defaultSelectedSpotlightIndex, setDefaultSelectedSpotlightIndex] = useState(0);

	// const [spotlightLikesContainerDivX, setSpotlightLikesContainerDivX]  = useState(0);   //Dynamically set the position of the LikesList
	// const likesDivID = id.toString() + "LIKESDIV";
	const giphyGridContainerDivID = id.toString() + "spotlightPreviewGiphyGridContainerDivID";

	const spotlightGiphyContainerRef = useRef();  //Defining a React Reference so that we can dynamically track the width for our Giphy container

	// Display context
	const { darkMode } = useContext(DisplayContext); 

   // Auth context
	const {
		currentUser,
		isSuperAdmin,
		isAdmin, 
	}  = useContext(AuthContext);

   // Customer context
	const { users } = useContext(CustomerContext);  
	
   // Giphy Cache context
	const { fetchGif, giphyCache } = useContext(GiphyCacheContext);

	function initSpotlight(spotlightToView) {
		return {...spotlightToView};   
	}
	
	function initMessges(spotlightToView) {
	   return [...spotlightToView.messages.items];
	}
	
	//Updated every render of the component    
	useEffect(() => {
	   //Update size of Giphy Containers in order to dynamically set size of grids
	   if (spotlightGiphyContainerRef && spotlightGiphyContainerRef.current && spotlightGiphyContainerRef.current.clientWidth !== spotlightGiphyGridContainerDivSize) setSpotlightGiphyGridContainerDivSize(spotlightGiphyContainerRef.current.clientWidth);
	});   

	//UseEffect to prepare our comment diplay region, including fetching any Gifs for our GIF Cache
	useEffect(() => {
		if(!spotlightToView || !spotlightToView.messages) return;
		let mounted = true;
 
		//Update our GIF cache based on the viewed messages
		//10.4.2023 - This successfully removed a memory leak by doing clean-up this way!
		(async () =>{
			
			try {
				
				//Update our master array of GIPHY objects to render to the user based on the messages that are shown
				// const tempGiphysToShow = [... giphysToShow];  //Get a copy of our state data
				for (var i=0; i < spotlightToView.messages.items.length; i++) {
					if (spotlightToView.messages.items[i].message.includes(GIPHY_ID_IDENTIFIER)){ 
						// if (DEBUG_MODE >= 2) console.log("Extracting GIF ID", spotlightToView.messages.items[i].message);
						const gifId = spotlightToView.messages.items[i].message.slice(GIPHY_ID_IDENTIFIER.length); 
						// if (DEBUG_MODE >= 2) console.log("Extracted GIF ID", gifId);
						const tempObject =  giphyCache.find(msg => (msg && msg.data && msg.data.id === gifId));
						//Fetch the FIG from GIPHY but only IF it has not already been fetched
						if (!tempObject) await fetchGif(gifId);  //Fetch from our Giphy Cache Context
					}
				}
				
				// if (DEBUG_MODE >= 2) console.log("Built GIPHY cache for spotlight", tempGiphysToShow);
				//Only update local if still Mounted
				//Now that we have fetched all of the giphy's update our local state to update our display, flawlessly
				if (mounted) {
					const tempSpotlight = {...spotlightToView};
					setSpotlight(tempSpotlight);   
					const tempMessages = [...spotlightToView?.messages?.items];
					setMessagesToShow(tempMessages);
					// if (DEBUG_MODE >= 2) console.log("Updated comments; fetched giphys", tempSpotlight, tempMessages);
				}
			} catch (err) {
				if (DEBUG_MODE >= 1) console.log("GIPHY Fetch Error when preparing Spotlight Card", err);
			}
		})();
		
		//Clean-up Function
		return () => mounted = false; // Mark as unmounted so no state update
 
	 }, [spotlightToView]); 
	 
	function handleSpotlightClick (e,spotlight) {
		e.stopPropagation();              //Prevent any outer OnClick from firing 
		setShowSpotlightInnerMenu(false)  // Close the pop-up menu, if open
	  //  if (DEBUG_MODE >= 2) console.log("Click on spotlight; local spotlight handler called", spotlight);
		if (spotlightClickCallback) spotlightClickCallback(spotlight);
		else if (DEBUG_MODE >= 2) console.log("No callback registered");
	}

   const handleMouseLeave = (e) => {
	  // if (DEBUG_MODE >= 2) console.log("Mouse left card",e);
	  setShowSpotlightInnerMenu(false)  // Close the pop-up menu, if open
	  setMouseOver(false);
	  // setShowFullSpotlight(false);
   };        
   const handleMouseEnter = (e,) => {
	  // if (DEBUG_MODE >= 2) console.log("Mouse entered card",e);
	  setMouseOver(true);
	  // setShowFullSpotlight(true);          //Commented this out since now a CLICK displays a full modal rather than doing this on just a hover
   }; 

   const handleMouseLikesLeave = (e) => {
	 // if (DEBUG_MODE >= 2) console.log("Mouse left LIKES",e);
	 setMouseOver(false);
	 setShowLikesList(null);
   };        
   const handleMouseLikesEnter = (e, comment) => {
	  // if (DEBUG_MODE >= 2) console.log("Mouse entered LIKES", e, comment);
	  setMouseOver(true);
	  if (spotlight.userLikes) 
		 if (spotlight.userLikes.length > 0) 
		 setShowLikesList(spotlight.id);     /* Show the Like List, if one exists, for this SPOTLIGHT */
   };
   

	function showCommentsCount(color) {
		if (!isFull && !spotlight.messages) return " ";
		if (!isFull && spotlight.messages.items.length == 0) return " ";
		var commentString = "0";
		if (spotlight.messages) {
			//if (spotlight.messages.items.length >0) commentString = spotlight.messages.items.length.toString() + (spotlight.messages.items.length == 1 ? " Comment" : " Comments");
			if (spotlight.messages.items.length >0) commentString = spotlight.messages.items.length.toString();
			if (spotlight.messages.items.length >99) commentString = "99+ ";
		}

		let className = " ContainerNoHeightCenter ";
		let textClassName = (isSmall ? 'TextStyle1V2' : 'TextStyle2V2') + " silver"
		let bubbleClassName = (isSmall ? 'TextStyle5V2 purple' : 'TextStyle7V2 purple');

		var messageBubbleSpace="5px";
		if (isSmall) {
			messageBubbleSpace="2px"
		}
		return (
				<div className={className} style={{color: color, }}> 
					<span className={textClassName}>{commentString}</span> 
					<ForumOutlinedIcon className={bubbleClassName} style={{paddingLeft: messageBubbleSpace, transform: "translate(0,-0%)"}}/>
				</div>
			);
	}
	
	async function handleLikeClick ({e, userAlreadyLike, message}) {
		
		if (DEBUG_MODE >= 2) console.log("LIKE clicked", userAlreadyLike, message);
		
		var currentUserLikes = [];
		if(message) currentUserLikes = (!message.userLikes ? [] : [...message.userLikes]); //Copy current list or init a new one for comment likes
		else currentUserLikes = (!spotlight.userLikes ? [] : [...spotlight.userLikes]); //Copy current list or init a new one for spotlight likes
			 
		//Update the list of IDs
		if (userAlreadyLike) {
		  
		  //Remove the userID from the list
		  var indexToRemove = currentUserLikes.indexOf(currentUser.id);
		  if (!indexToRemove>-1) {
			 currentUserLikes.splice(indexToRemove,1); //remove current user
			  if (DEBUG_MODE >= 2) console.log('Removed user from spotlight / Comment LIKE list');
		  } else {
				if (DEBUG_MODE >= 2) console.log('Error removing user from LIKE list.  User not found');
		  }
		  
		  //Empty list?  If so, hide the list since currently being hovered over
		  if (currentUserLikes.length == 0) setShowLikesList(null);
		} else {
		  
		  //Add the user to the like List
		  currentUserLikes.push(currentUser.id);
		  if (DEBUG_MODE >= 2) console.log('Added user to the spotlight LIKE list');
		  
		  //Just started a list for a comment?  If so, show list since currently being hovered over
		  if (message && currentUserLikes.length == 1) setShowLikesList(message.id);
		}
 
 
		//Now, update the DB                
		try {         
		  if (message){
			 message.userLikes = [...currentUserLikes]; //Update with new array in memory so as to update display
			 const tempSpotlight = {...spotlight};   //Copy our entire state data for the spotlight (which includes the updated comment) to trigger a re-render
			 setSpotlight(tempSpotlight); //Probably not needed as parent subscription will update the input prop but ...
						 
			 const tempInsertedRecognitionComment = await invokeAPI(updateRecognitionComment,'updateRecognitionComment', { id: message.id,  userLikes: currentUserLikes});
			 if (DEBUG_MODE >= 2) console.log('Successfully edited LIKES for Comment', tempInsertedRecognitionComment);
			 
			 //Perform an UPDATE to parent Spotlight to trigger a Subscription PUSH for the new comments
			 if (DEBUG_MODE >= 2) console.log("Executing an UPDATE on the Spotlight table to trigger a push of comments to all clients");            
			 const tempUpdatedRecognition = await storeUpdatedRecognition({id: spotlight.id});
			 if (DEBUG_MODE >= 2) console.log("Successfully updated spotlight to trigger push", tempUpdatedRecognition);
		  
		  } else{
		  
			 //Update the spotlight currently being viewed by this component
			  const tempSpotlight = {...spotlight};   //Copy our state datea
			  tempSpotlight.userLikes = [...currentUserLikes];
			  setSpotlight(tempSpotlight); //Probably not needed as parent subscription will update the input prop but ...
		
			  const tempInsertedRecognition = await storeUpdatedRecognition({id: spotlight.id, userLikes: currentUserLikes});
 
			  if (DEBUG_MODE >= 2) console.log('Successfully edited LIKES for Spotlight', tempSpotlight, tempInsertedRecognition);
		  }
		} catch (err) {
		  if (DEBUG_MODE >= 2) console.log("Error toggling LIKE for Spotlight", err);
		}
	 }

   //Display likes list for the Spotlight or comment
   function displayLikeList(comment) {
	  if (!spotlight) return null;
	  var currentUserLikesList = "", arrayToUse = [];
	  const NAMES_TO_DISPLAY = 5;
	  if(comment){
		 if (!comment.userLikes || comment.userLikes.length == 0) return null;
		 arrayToUse = comment.userLikes; 
	  } else {
		 if (!spotlight.userLikes) return null;
		 arrayToUse = spotlight.userLikes;          
	  }
	  
	  if (!arrayToUse) return null;
	  
	  //Build the Like list
	  for (var i=0; i < arrayToUse.length && i < NAMES_TO_DISPLAY; i++) {
		 var userThatLiked = users.find(user => user.id == arrayToUse[i]); 
		 if (userThatLiked) currentUserLikesList = currentUserLikesList + userThatLiked.firstName + " " +userThatLiked.lastName.substring(0,1) + ".";
		 // if (userThatLiked) currentUserLikesList = currentUserLikesList + getUserInitials(userThatLiked);
		 if (i < arrayToUse.length-1 && i < NAMES_TO_DISPLAY-1) currentUserLikesList = currentUserLikesList + ", ";
	  }
	  if (arrayToUse.length > i) currentUserLikesList = currentUserLikesList + " + " + (arrayToUse.length - i).toString() + " more";
	  return (
		 <div>
			 {currentUserLikesList}
		 </div>
	  );
   }
	
   function showLikesCount({color}) {
   
	  //Has this person LIKED the Spotlight / comment already?
	  var userAlreadyLike = false;
	  var numLikes = 0;

	  let thumbClassName = (isSmall ? 'TextStyle3V2' : 'TextStyle5V2');
	  var iconButtonSize = "medium";
	  var flexStyle = "1";
		  
	  //Render Likes for the overall Spotlight or for a specific comment?
	  if (spotlight.userLikes) {
		  userAlreadyLike = spotlight.userLikes.find (userID => userID === currentUser.id);
		  numLikes = spotlight.userLikes.length;
	  }
   
	  //Render function
	  if (isFull)
		 return (//full spotlights page version
				 <div className="spotlightLikesContainer"  style={{flex:flexStyle}}> 
					<button className='like-btn' onClick={(e) => handleLikeClick({e: e, userAlreadyLike: userAlreadyLike})} onMouseLeave={handleMouseLikesLeave} onMouseEnter={(e) => handleMouseLikesEnter(e)}>
						<IconButton size={iconButtonSize}>
							{numLikes == 0 ? null : <div className="TextStyle2V2 likesCount" style={{color:color}}> {numLikes} </div>}
							{userAlreadyLike ? <ThumbUpIcon className={thumbClassName} /> : <ThumbUpOffAltIcon className={thumbClassName} /> }
						</IconButton>
					</button>
				 </div>    
			 );
		 
		 return ( //dashboard version
			 <div className="spotlightLikesContainer" > 
			 	<button className='like-btn ContainerNoHeightCenter'>
					{userAlreadyLike ? <ThumbUpAltIcon className={thumbClassName} /> : <ThumbUpOffAltIcon className={thumbClassName} /> }
					{numLikes == 0 ? null : <div className='TextStyle2V2' style={{color:color}}> +{numLikes} </div>}
				</button>
			 </div>    
		 );
   }
	
	function setSpotlightPreviewBackgroundBySpot(location) {
		const remainder = location % 4;
		switch (remainder) {
			case 0: return BALANCE_COLOR;
			case 1: return TEAM_COLOR;
			case 2: return SOCIAL_COLOR;
			case 3: return GROWTH_COLOR;
			// case 4: return "white";
		}
	}

   //Handle menu selection - NOTE the SCOPE is INSIDE the SubMenuDisplay so we can access the props including the specific invitation and scheduled conectivity to handle
   const handleSpotlightMenuSelection = (e) => {
	 e.stopPropagation();        //Prevent outer Card OnClick from firing 
	 const menuAction  = e.currentTarget.dataset.value;
	 if (DEBUG_MODE >= 2) console.log("SPOTLIGHT Menu item selected.", menuAction, handleEditSpotlightClick);
	 
	  switch (menuAction) {
		 case "EDIT":
			if (handleEditSpotlightClick) handleEditSpotlightClick(spotlight);  //Callback parent and invoke edit
			setShowSpotlightInnerMenu(false);                          //Close the sub menu
			break;
		 case "DELETE":
			if (handleDeleteSpotlightClick) handleDeleteSpotlightClick(spotlight);  //Callback parent and invoke delete
			setShowSpotlightInnerMenu(false);                          //Close the sub menu
			break;
	  } 
   };  
   
   function spotlightInnerMenu () {
	  if (!isFull || !showSpotlightInnerMenu) return null;
	  return (
		  <div className={`spotlightInnerMenuContainer TextStyle3 ${darkMode ? "black-background white" : "dark-grey"} `}> 
			   <MenuList >
					  <MenuItem onClick={handleSpotlightMenuSelection} data-value={"EDIT"}>
						  <div className="ContainerNoHeightCenter"> 
								<Edit /> 
								<div style={{paddingLeft:"clamp(1px,0.3vw,3px)", }}>Edit Spotlight</div>
							</div> 
					  </MenuItem>
					  <MenuItem onClick={handleSpotlightMenuSelection} data-value={"DELETE"}>
						  <div className="ContainerNoHeightCenter"> 
								<DeleteOutline  /> 
							<div style={{paddingLeft:"clamp(1px,0.3vw,3px)"}}>Delete Spotlight</div></div> 
					  </MenuItem>   
			  </MenuList>
		  </div>   
	  );
   }        

   function spotlightInnerMenuIcon () {
	  if (!isFull) return null;
	  if (!isAdmin && !isSuperAdmin && currentUser?.id!=spotlight.senderID) return null;
	  
	  return (
		 <div className={`spotlightInnerMenuIconContainer  `} > 
		   <IconButton size="medium" onClick={(e) => {e.stopPropagation();setShowSpotlightInnerMenu(!showSpotlightInnerMenu);}} >
			   <MoreVertIcon className={darkMode ? "white" : "dark-grey"} /> 
		   </IconButton>
		 </div>   
	  );
   }

   //Render function    
	const maxUsersToShow = 4;
	var scaleString = (isSmall ? "scale(0.70)" : "scale(1.0)");
	const outerBackgroundColor = setSpotlightPreviewBackgroundBySpot(id);
	var styleObject= (mouseOver && !isFull ? {transform: "scale(1.05)", zIndex: "1", } : {transform: "scale(1.0)", backgroundColor:outerBackgroundColor});
	if (noBoxShadow) styleObject.boxShadow = "none"; //Otherwise, use the CSS value for FULL
	var styleObjectCard = (mouseOver && isSmall ? {boxShadow: "0px 4px 8px 0 #BDC9D7"} : {});
	var senderAvatarClassName = "avatarImage avatarImageSmall";
	var senderAvatarInitialsClassName = "avatarInitials TextStyle3 white";
	
	if (isSmall) {
		senderAvatarClassName = "avatarImage avatarImageTiny";
		senderAvatarInitialsClassName = "avatarInitialsTiny TextStyle2 white"
	}
	if (isFull) {
		senderAvatarClassName = "avatarImage avatarImageMedium";
	}
	
	if (!spotlight) return null;
	else return (
		<div className={`${animationClass} pointer spotlight-content-innerContainer ContainerVerticalSpaceBetween fullWidth fixHeight`} onClick={(e) => handleSpotlightClick(e, spotlight)}>
			<div className='spotlight-header'>
				{/* <div className='spotlight-div'>
					<h4 className='yellow'>Spotlight</h4>
				</div> */}
				{displayRecognizedUsersAvatars({recognizedEmployees:(spotlight ? spotlight.recognizedEmployees.items : null), maxUsersToShow:maxUsersToShow, isSmall:false, isFull:isFull, bgColor: GROWTH_COLOR})}
			</div>
			<div className='spotlight-textContainer'>
				<p className='textAlignCenter black-text TextStyle3V2 overText'>
					{spotlight.message} 
				</p>
			</div>
			<div className='spotlight-footer ContainerNoHeightSpaceBetween fullWidth'>
				<div className='ContainerNoHeightCenter'>
					<div className="spotlightSenderAvatarWrapper">
						<div className={senderAvatarClassName} style={{ position: 'relative', backgroundColor:BALANCE_COLOR}}>
							{!spotlight.senderAvatarUrl  ? <div className={senderAvatarInitialsClassName} > {spotlight.senderAvatarInitials}</div> : <img className="avatarImageCenterPortrait" src={spotlight.senderAvatarUrl} alt={spotlight.senderAvatarInitials} /> }
						</div>
					</div>
					<div className="ContainerVerticalStartStart TextStyle2V2">
						<span className='silver'>{timeAgo(spotlight.createdAt)}</span>
						<span className='silver'>By {spotlight.senderAvatarName}</span>
					</div>
				</div>

				<div className='ContainerNoHeightCenter spotlightCardActionWrapper'>
					{showCommentsCount(outerBackgroundColor)}
					{showLikesCount({color:outerBackgroundColor})}
				</div>
			</div>
		</div>
		// 	<div key={id} className={`spotlightCardOuterWrapper boxShadow ${isSmall ? "spotlightOuterWrapperIsSmall" : ""} ${isFull ? "spotlightOuterWrapperIsFull" : ""}  `} 
	// 		 onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} style={styleObject}
	// 		 >
	// 	   <div className={`spotlightCard ${isSmall ? "spotlightCardIsSmall" : ""} ${isFull ? "spotlightCardIsFull" : ""} ${darkMode ? "black-background white" : ""}`}  style={styleObjectCard}
	// 			onClick={(e) => handleSpotlightClick(e,spotlight)}>

	// 			{spotlightInnerMenuIcon()}
	// 			{spotlightInnerMenu()}
				 
	// 			 {
	// 			<div className='ContainerNoHeightCenter positionRelative spotlightCardHeader'>
	// 				<div className="" >
	// 					<HighlightIcon className={`${isSmall ? "TextStyle6" : "TextStyle7"} spotlightCardHeaderLightLeft`} /> 
	// 					</div>					
	// 				<div className={`spotlightCardHeaderText ${isSmall ? "spotlightCardHeaderTextSmall" : ""} `}>SPOTLIGHT</div>
	// 				<div className=""  >
	// 					<HighlightIcon className={`${isSmall ? "TextStyle6" : "TextStyle7"} spotlightCardHeaderLightRight`} /> 
	// 				</div>
	// 			</div> 
	// 		}
				 
	// 			<div className="spotlightCardInnerVerticalWrapper" >
	// 				<div className="spotlightRecognizedUsersAvatarsWrapper" >
	// 					{/* <HighlightIcon className={`${isSmall ? "TextStyle6" : "TextStyle7"} spotlightCardHeaderLightLeft`} /> */}
	// 					{displayRecognizedUsersAvatars({recognizedEmployees:(spotlight ? spotlight.recognizedEmployees.items : null), maxUsersToShow:maxUsersToShow, isSmall:false, isFull:isFull})}
	// 					{/* <HighlightIcon className={`${isSmall ? "TextStyle6" : "TextStyle7"} spotlightCardHeaderLightRight`} /> */}
	// 				</div>
	// 					<ConectereDivider />
	// 				 	<div className={`spotlightMessagePreview ${isSmall && "spotlightMessagePreviewIsSmall"} `} >
	// 						<div className="spotlightSenderAvatarPortion">
	// 							<div className={senderAvatarClassName} style={{backgroundColor:BALANCE_COLOR}}>
	// 								{!spotlight.senderAvatarUrl  ? <div className={senderAvatarInitialsClassName} > {spotlight.senderAvatarInitials}</div> : <img className="avatarImageCenterPortrait" src={spotlight.senderAvatarUrl} alt={spotlight.senderAvatarInitials} /> }
	// 							</div>
								
	// 							{isSmall ? "" :
	// 								<div className="TextStyle3 spotlightSenderName"> {spotlight.senderAvatarInitials}</div>
	// 							}
								
	// 							<div className={isSmall ? "TextStyle1 spotlightSenderNameIsSmall" : "TextStyle2 spotlightSenderName"}>{timeAgo(spotlight.createdAt)}</div>
	// 							<span className="avatarHoverText" style={{background: BALANCE_COLOR}}>{spotlight.senderAvatarName}</span>
	// 					   </div>
	// 					   <div 
	// 					   	className={`spotlightMessagePortion  ${(isFull || showFullSpotlight) ? "showFullSpotlightMessage boxShadow" : ""} ${(isSmall ? "spotlightMessagePortionSmall TextStyle2" : "TextStyle3")}  ${darkMode ? "spotlightMessagePortionDarkMode" : ""} `} 			
	// 					   > 
	// 						  {isSmall ? truncateStringWithDots(spotlight.message,90) : (!isFull ? truncateStringWithDots(spotlight.message,150) : spotlight.message) } 
	// 					   </div>
	// 				 </div>
	// 				<div className={`spotlightBottomRowWrapper ${isSmall && "spotlightBottomRowWrapperIsSmall"} `} >
	// 					{showCommentsCount(outerBackgroundColor)}
	// 					{showLikesCount({color:outerBackgroundColor})}
	// 					{!isSmall &&
	
	// 						<div className="spotlightPreviewAwardsWrapper" >
							
	// 						   {!spotlight.amount >0 ? "" :
	// 							<div className="ContainerNoHeightCenter noWrap TextStyle4" style={{padding:"0px 5px", transform: scaleString}}> 
	// 								{!spotlight ? "" : spotlight.amount} 
	// 								<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /> 
	// 							</div>                        
	// 							}
	// 							{!spotlight.badgesDEI>0 ? "" :  
	// 								<div className="ContainerNoHeightCenter noWrap TextStyle4" style={{ padding:"0px 5px", transform: scaleString}}> 
	// 									{!spotlight ? "" : spotlight.badgesDEI} 
	// 									<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
	// 								</div>
	// 							}
	// 							{!spotlight.badgesCS>0 ? "" :  
	// 								<div className="ContainerNoHeightCenter noWrap TextStyle4" style={{padding:"0px 5px", transform: scaleString}}> 
	// 									{!spotlight ? "" : spotlight.badgesCS} 
	// 									<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /> 
	// 								</div>
	// 							}
	// 						</div>
	// 					}
	// 				</div>
	// 			</div>    
	// 		</div>
			
	// 	{isFull ?
	// 		   <div style={{position:"relative", marginTop:"clamp(4px,1vh,10px)"}}>
	// 			  <ChatWindow placeholderMessage='Add a comment!'  recognitionID={spotlightToView.id} currentUser={currentUser} headerColor={outerBackgroundColor} messagesToShow={messagesToShow} noFixedHeight={true}/>    
	// 			  <div className={`spotlightsLikesList ${showLikesList  == spotlight.id && "spotlightsLikesListHover"}`} style={{backgroundColor:outerBackgroundColor}}> {displayLikeList()} </div>
	// 		   </div> 
	// 		:
	// 		null
	// 	}
		
	// </div>
	);

}, (prevProps, nextProps) => {
	//Our React MEMO function - do not re-render if no change to the spotlightToView
	if (
		((prevProps.spotlightToView === nextProps.spotlightToView) &&
		(prevProps.darkMode === nextProps.darkMode) 
		))
	{
		// if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the SpotlightCard",  prevProps.spotlightToView);
		return true; // props are equal
	}
	// if (DEBUG_MODE >= 2) console.log("Re-RENDER the SpotlightCard", prevProps.spotlightToView);
	return false; // props are not equal -> update the component
}
);

export const AnimatedSpotlightCard = React.memo(({key, spotlightClickCallback, handleEditSpotlightClick, handleDeleteSpotlightClick, spotlightsList, handleCastClick, handleFlashNextPrevClick}) => {
	
	const [defaultSelectedSpotlightIndex, setDefaultSelectedSpotlightIndex] = useState(0);
	const [animationDirection, setAnimationDirection] = useState("normal");
	const intervalRef = useRef(null);
	const [dotsList, setDotsList] = useState([]);
	const [castProgressPercentage, setCastProgressPercentage] = useState(25);

	const restartTimer = () => {
	  if (intervalRef.current) {
		clearInterval(intervalRef.current);
	  }
	  intervalRef.current = setInterval(() => {
		setDefaultSelectedSpotlightIndex((prevValue) => (prevValue + 1) % spotlightsList.length);
	      }, ((8 * 1000) - 100)); // here 8 is sec of delay to change data for animation change based on animation time
	};
	const handleClickNextPrev = (type) => {
		if (type === "next") {
		  setDefaultSelectedSpotlightIndex(defaultSelectedSpotlightIndex + 1);
		  if (defaultSelectedSpotlightIndex + 1 === spotlightsList?.length) {
			setDefaultSelectedSpotlightIndex(0);
		  }
		} else if (type === "prev") {
		  setDefaultSelectedSpotlightIndex(defaultSelectedSpotlightIndex - 1);
		  if (defaultSelectedSpotlightIndex === 0) {
			setDefaultSelectedSpotlightIndex(spotlightsList?.length - 1);
		  }
		  setAnimationDirection((prevDirection) => (prevDirection === "normal" ? "reverse" : "normal"));
		  setTimeout(() => setAnimationDirection("normal") , 500);
		}
		handleFlashNextPrevClick();
		restartTimer();
  };

  const handleDots = () => {
	const maxDotsToShow = 5;
	const activeIndex = spotlightsList.findIndex(
		(_el) => _el.id === spotlightsList[defaultSelectedSpotlightIndex]?.id
	);
	
	// Calculate start and end range based on the active index
	let startIndex = Math.max(activeIndex - Math.floor(maxDotsToShow / 2), 0);
	let endIndex = Math.min(startIndex + maxDotsToShow, spotlightsList.length);
	
	// Adjust startIndex if endIndex is less than 5, ensuring you show the max number of dots possible
	if (endIndex - startIndex < maxDotsToShow) {
		startIndex = Math.max(endIndex - maxDotsToShow, 0);
	}

	// Get the visible portion of the list
	const visibleDots = spotlightsList.slice(startIndex, endIndex);
	setDotsList(visibleDots);
  }

  const handleDotClick = (selectedIndex) => {
	// Get the index of the clicked dot
	setDefaultSelectedSpotlightIndex(selectedIndex);
	restartTimer();
	handleFlashNextPrevClick();
  };

  useEffect(() => {
    restartTimer(); 
	setTimeout(() => handleFlashNextPrevClick(), 50);
	handleDots();
    return () => clearInterval(intervalRef.current); 
  }, [spotlightsList]);
 
  const castButton = ()=> {
	return (
		<div className="ContainerVerticalStart" style={{ gap: 10 }}>
			<button className="buttonStyle1 castButton fullWidth" onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				handleCastClick(true);
			}}>
				Cast
			</button>
			<div className="progressBar-v2 pointer">
				<div className="progress-v2" style={{ width: `${castProgressPercentage}%` }} />
			</div>
			<span className="TextStyle2V2 purple bold hover-text"> 1 of 5 </span>
		</div>
	)
  }
  const emptyList = !spotlightsList || !spotlightsList[0] || !spotlightsList[0].id;
	return (
		<div key={key} className="fullWidth position-relative">
			<div className='spotlight-content fullWidth ContainerVerticalSpaceBetween'>
				<div className="spotlight-prev-button spotlight-action-btn">
					{nextPrevActionButton({handleClickNextPrev})}
				</div>
				<div className="spotlight-next-button spotlight-action-btn">
					{nextPrevActionButton({isNext: true, handleClickNextPrev})}
				</div>
				<div className="spotlight-content-innerContainer fullWidth">
					<div className='spotlight-header'>
						<div className='spotlight-div'>
							<h4 className='yellow'>Spotlight</h4>
						</div>
					</div>
				</div>
				{emptyList ? null :
					<SpotlightCard 
						key={spotlightsList[defaultSelectedSpotlightIndex].id} 
						id={spotlightsList[defaultSelectedSpotlightIndex].id} 
						spotlightToView={spotlightsList[defaultSelectedSpotlightIndex]} 
						spotlightClickCallback={spotlightClickCallback} 
						handleDeleteSpotlightClick={handleDeleteSpotlightClick} 
						handleEditSpotlightClick={handleEditSpotlightClick}
						handleClickNextPrev={handleClickNextPrev}
						handleCastClick={handleCastClick}
						animationClass={animationDirection === "normal" ? "animated-slide" : "animated-slide-prev"}
					/>
				}
			</div>
			

				<div className={`${emptyList ? 'castButtonContainerCentered' : 'ContainerNoHeightFlexLeft fullWidth position-relative'} castButtonContainer `}>
					{castButton()}
					{emptyList ? null :
						<div className="ContainerNoHeightCenter dots-container">
							{dotsList?.map((_el, _ind) => {
								var isActive = _el?.id === spotlightsList[defaultSelectedSpotlightIndex].id
								return (
									<div key={_el?.id} className={`dot pointer ${isActive ? 'active-dot' : ''}`} onClick={() => handleDotClick(_ind)} />
								)
							})}
						</div>
					}
				</div>
		</div>
	)
});

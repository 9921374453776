//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023

//  ADMIN for managing New Employee Support program

//Config Data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, EMPLOYEE_CONNECTION_BUILDER_PROGRAM, TEAM_COLOR, IMAGE_COMMUNITY_1} from '../../shared/data/conectereConfigData';

import { HELP_TEXT_ADMIN_NEW_EMPLOYEE_SUPPORT_1 } from '../../shared/data/helpData.js';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';


//Queries and Mutations
import {  updateConectereConfig } from '../../shared/graphql/mutations';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Checkbox from '@mui/material/Checkbox';

import Switch from '@mui/material/Switch';
import Select from 'react-select';   //Version with support from multiple selections

//Icons
import { DeleteOutline} from '@material-ui/icons';
import SettingsIcon from '@mui/icons-material/Settings';

//Our Components
import ConectivityDetailedView from "../../shared/Components/conectivityDetailedView/conectivityDetailedView";
import AutoLaunchRulesEditor from "../../shared/Components/connectionBuilderRulesEditor/connectionBuilderRulesEditor";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { DisplayConectivitySelectComponent, setConectivityImageUrlByCategory, setHeaderColorByCategory } from "../../shared/utils/conectivityUtils";
import { OpenButton,  HelpButton, EditButton, DeleteButton, SettingsButton, truncateStringWithDots } from "../../shared/utils/generalUtils";
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,
		} from "../../shared/utils/dateTimeUtils";
import { storeUpdatedCustomerConfig }  from "../../shared/utils/databaseUtils";
import moment from 'moment-timezone';


//Icons

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';


const AUTO_LAUNCH_RULE_INITIAL_STATE = null; //
const INIT_AUTO_LAUNCH_RULES_SETTINGS = {}
const autoLaunchRules = [
	{
		id:'1234',
		ruleStatement:'Connect everyone, everywhere - often'
	}
]
const AdminConnectionBuilder = () => {

	// Context
	const {  authState,  currentUser, isSuperAdmin,   } = useContext(AuthContext);    
	const { conectivitiesReadyForLaunch, conectivitiesReadyForLaunchOptions  } = useContext(ConectivityContext);
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
   
	//Local state
	const [configDetailsToEdit, setConfigDetailsToEdit] = useState(null);
	const [inEditMode, setInEditMode] = useState(false);
	const [showRulesEditor, setShowEditor] = useState(null);  										/* States = false, ADD, EDIT, DELETE */
	const [ruleToProcess, setRuleToProcess] = useState(AUTO_LAUNCH_RULE_INITIAL_STATE);    //Used to hold a conectivity that is to be deleted
	const [autoLaunchRulesRows,setAutoLaunchRulesRows]=useState([]);                      	//Formatted rows for grid	 
	const [showModalConectivityDetailed, setShowModalConectivityDetailed] = useState(false);      //Detailed Conectivity View
	const [conectivityToView, setConectivityToView] = useState(null);              //Used to hold a conectivity that is to be launched
	const [options, setOptions] = useState({});

		
	//Copy the customer's settings upon opening the page or any change to the edit mode
	useEffect(() => {
		
	   //Safety check
		if (authState !== "signedin" || !currentUser || !currentUser.customer || !currentUser.customer.configDetails || !conectivitiesReadyForLaunch || conectivitiesReadyForLaunch.length === 0)  {
			return;
		}
		buildPageData();
	}, [authState, currentUser, conectivitiesReadyForLaunch]);


	async function buildPageData() {
		if (!currentUser || !currentUser.customer || !currentUser.customer.configDetails) return;
		
		try {
			 //Load New Employee Support options based on customer data
			let configDetails = {...currentUser.customer.configDetails};    //Make a copy
			setConfigDetailsToEdit(configDetails);                          //Store in our local React state var for the page	
			if (DEBUG_MODE >= 2) console.log("initializing connection builder page", configDetails);

			//Init options for default set of auto launch rules
			let tempOptions = (configDetails && configDetails.enableEmployeeConnectionBuilder && configDetails.employeeConnectionBuilderOptions ? JSON.parse(configDetails.employeeConnectionBuilderOptions) : {});
			if (DEBUG_MODE >= 2) console.log("initializing connection builder OPTIONS", tempOptions);
			setOptions(tempOptions);

			//Build rows for Data Grid
			let tempRows = [];
			if (autoLaunchRules) {
				for (let i = 0; i<autoLaunchRules.length; i++) {
					tempRows[i] = {
						id:i+1,
						dynamoDBID:autoLaunchRules[i].id,
						ruleStatement:autoLaunchRules[i].ruleStatement,
					}
				}//End For
			} //End IF
			if (DEBUG_MODE > 1) console.log("Built auto launch rules", tempRows);
			setAutoLaunchRulesRows(tempRows);
	   
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error building auto launch rules page data", err);
		} 
	}

	//Conectivities Columns for Admin of the customer who is NOT a SuperAdmin or an EDITOR
	const autoLaunchRulesColumns = [
		{
		 field: 'ruleStatement',
		 headerName: 'Auto Connection',
		 headerAlign: 'center',
		 minWidth: 125,
		 flex:1,
		 editable: false,
		 sortable: false,
		 disableColumnMenu: true,
		 color:'white',
		 renderCell: (params)=> {
			return (
				<div className="ContainerNoHeightCenter" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
					<div style={{color:setHeaderColorByCategory(params.row.shortLabel)}}>
						{params.row.ruleStatement}
					</div>
				</div>
			)
		}
		},      

		{
		 field: 'action',
		 headerName: ' ',
		 headerAlign: 'left',
		 minWidth: 150,
		 flex:0.5,
		 color:'white',
		 sortable: false,
		 renderCell: (params)=> {
			 if (!params.row.dynamoDBID) return null;
			 return (
					<div className="dataGridActionButtonRow" >
						<EditButton onClickCallback={handleAction} onClickCallbackParam={{ruleID:params.row.dynamoDBID, mode:'EDIT'}} />
						<DeleteButton onClickCallback={handleAction} onClickCallbackParam={{ruleID:params.row.dynamoDBID, mode:'DELETE'}} />
					</div>				
				); 
			 }
		 },
	 ];

	const handleAction = ({ruleID, mode}) => {
		if (DEBUG_MODE > 1) console.log("Action handler invoked", ruleID, mode)
		//Sasfety check
		if (!ruleID || !mode || (mode !== 'EDIT' && mode !== 'DELETE')) {
			if (DEBUG_MODE) console.error("Error - improper params for editing an auto-launch rule", ruleID, mode);
		}
		const ruleToProcess = autoLaunchRules.find(c => c.id === ruleID);
		if (!ruleToProcess) {
			if (DEBUG_MODE) console.error("Error - could not find the auto-launch rule", ruleID, mode);
		}		
		if (DEBUG_MODE >= 2) console.log("Processing auto launch rule", ruleToProcess);
		setRuleToProcess({...ruleToProcess})
		setShowEditor(mode); //invoke our editor 
	};

	  //handle detailed view of conectivity 
	function handleDetailedView (conectivityToViewID) {
		setConectivityToView(conectivitiesReadyForLaunch.find(c => c.id === conectivityToViewID));
		setShowModalConectivityDetailed(true);
	} 

	 //handle close detailed view
	const handleCancelDetailedView = () => {
		setShowModalConectivityDetailed(false);
		setConectivityToView(null);
	};
	

	const handlePutInEditMode = () => {
		if (DEBUG_MODE >= 2) console.log("User entered edit mode.");
		setInEditMode(true);
	};

   //Process the SAVE function from Edit mode for updating customer
	async function handleConfirmationEdit (e) {
		e.preventDefault();     //prevent React page refresh
		setShowSpinner(true); //Show spinners
		setInEditMode(false); // Shut down the edit mode
		try {	
			let tempConfigToEdit = { 
				id: configDetailsToEdit.id, 
				enableEmployeeConnectionBuilder: (configDetailsToEdit.enableEmployeeConnectionBuilder && options ? true : false)
			}; 
			if (tempConfigToEdit.enableEmployeeConnectionBuilder) tempConfigToEdit.employeeConnectionBuilderOptions = JSON.stringify(options);

			if (DEBUG_MODE >= 2) console.log("Writing customer config", tempConfigToEdit);
			const tempInsertedConfig = await storeUpdatedCustomerConfig(tempConfigToEdit);
			if (DEBUG_MODE >= 2) console.log("Successfully edited customer config", tempInsertedConfig );
			setModalGeneralMessage("Changes saved ");
			setModalGeneralTitle("Success!");       
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error editing customer config:', err);
			setModalGeneralMessage("Unable to save changes to customer.  ", err);
			setModalGeneralTitle("Ooops!");                    

		}	
		setShowSpinner(false); //Hide spinners
		setShowModalGeneral(true); //display error message
	}
 
	const handleResetEditForm = () => {
		//Reset any changed - Load New Employee Support options based on customer data
		buildPageData();
		setInEditMode(false);
		if (DEBUG_MODE >= 2) console.log("Exiting edit mode; Restored original config data");
	};
	
	const handleCloseEditor = ({result, message}) => {
		setShowEditor(false);
		setRuleToProcess(AUTO_LAUNCH_RULE_INITIAL_STATE);

		if (result !== "CANCELED" && message) {
			 if (result) setModalGeneralTitle("Success!!");  
			 else setModalGeneralTitle("Ooops!");
			 setModalGeneralMessage(message);
			 setShowModalGeneral(true);
		}
		if (DEBUG_MODE >= 2) console.log("Closed RULES Editor", result, message);
  };
  const handleEnableToggle = ()=> {
	//Toggle enable and reset the other options
	let tempConfig;
	if (configDetailsToEdit.enableEmployeeConnectionBuilder) tempConfig = { ...configDetailsToEdit, enableEmployeeConnectionBuilder:false,  ...INIT_AUTO_LAUNCH_RULES_SETTINGS,  };
	else  tempConfig = { ...configDetailsToEdit, enableEmployeeConnectionBuilder:true };
	setConfigDetailsToEdit(tempConfig);
}

const AutomaticallyScheduledConectivity = React.memo(({slot, options, setOptions, zIndex}) => {


	//local state
	const [selectedConectivityOption, setSelectionConectivityOption] = useState(null);
	const [time, setTime] = useState(null);
	let thisConfigEntry = (slot ? EMPLOYEE_CONNECTION_BUILDER_PROGRAM.find(entry =>entry.label === slot ) : null);

	//Update local state based on a change to options
	useEffect (() => {
		let tempConectivitySelectionOption = null, tempTime = null;
		if (options && options[slot] && options[slot].enabled) {
			tempConectivitySelectionOption = conectivitiesReadyForLaunchOptions.find(c => c.id === options[slot].conectivityID);
			tempTime = moment().startOf('day');   //Init to the start of the day in the Admin current TimeZone
			tempTime.set({'hour': options[slot].startHour, 'minute': options[slot].startMinutes, 'second':0}); 
		} else {
			tempConectivitySelectionOption = conectivitiesReadyForLaunchOptions.find(c => c.id === thisConfigEntry.defaultConectivityID);		
			tempTime = moment().startOf('day');   //Init to the start of the day in the Admin current TimeZone
			tempTime.set({'hour': thisConfigEntry.startHour, 'minute': thisConfigEntry.startMinutes, 'second':0}); 
		}
		if (DEBUG_MODE > 1) console.log("Updated this entries conectivity selection option and time:", slot, tempConectivitySelectionOption, (tempTime ? tempTime.format() : null));
		setSelectionConectivityOption(tempConectivitySelectionOption);
		setTime(tempTime);
	}, [options, slot, thisConfigEntry]);

		
	//Safety check - slot is the label defined int the config array
	if (!configDetailsToEdit || !slot || !options || !setOptions) return null;
	if (!thisConfigEntry) return null;
	if (DEBUG_MODE > 1) console.log("This config entry:", slot, thisConfigEntry, selectedConectivityOption, options[slot]);


	const disabled = !inEditMode || !configDetailsToEdit.enableEmployeeConnectionBuilder;

	return (
		 <div className="ContainerNoHeight fullWidth" style={{padding:"2.0rem", zIndex:(9999-zIndex).toString()}}>
				<div className="ContainerVerticalStartStart fullWidth" style={{flex:"1"}}>
					<div className="ContainerNoHeightFlexLeft ">
								<Checkbox
								 checked={options[slot] ? options[slot].enabled : false}
								 disabled={disabled}
								 onChange={()=>{
									let tempOptions = {...options};
									if(tempOptions[slot] && tempOptions[slot].enabled) {   //Transitioning to Unchecked? If so, clear selections
										tempOptions[slot] = {enabled:false}
										setOptions(tempOptions);
										if (DEBUG_MODE > 1) console.log("Resetting this config entry:", slot, tempOptions, );
									} else { //Transitioning to CHECKED? Load up the defaults
										tempOptions[slot] = {enabled:true, conectivityID:thisConfigEntry.defaultConectivityID, startTime:thisConfigEntry.startTime, startHour:thisConfigEntry.startHour, startMinutes:thisConfigEntry.startMinutes} 
										setOptions(tempOptions);
										if (DEBUG_MODE > 1) console.log("Enabling this config entry:", slot, tempOptions, );
									}
								 }}
								 inputProps={{ 'aria-label': 'controlled' }}
							  />
					<div className='TextStyle5 blue-text'>{thisConfigEntry.title}</div>
							  <HelpButton hoverText={thisConfigEntry.helpText}  /> 
					</div>
					
					<div className="ContainerVerticalStartStart fullWidth" style={{paddingLeft:"3.0rem"}}>
							<div className="TextStyle4 headerBlue">{thisConfigEntry.text}</div>
							<div className="ContainerNoHeightFlexLeft " >
								<div className="TextStyle4"> <i style={{paddingRight:"5px"}}>what:</i></div>
								{disabled ?
									<div className="ContainerNoHeightFlexLeft" style={{minWidth:"250px"}}> 
											{!selectedConectivityOption ? null :
												<>
													<img className="ccImgLarge" src={setConectivityImageUrlByCategory (selectedConectivityOption.shortLabel, true)} alt="Conectivity" />
													<span style={{paddingLeft: "5px",color: setHeaderColorByCategory(selectedConectivityOption.shortLabel), fontSize:"16px"}}>{truncateStringWithDots(selectedConectivityOption.title,50)}</span> 
												</>
											}
									</div>                       
								:
									<DisplayConectivitySelectComponent 
											name="conectivityOnboardingDropdown" 
											options={conectivitiesReadyForLaunchOptions} 
											onChangeCallback={(option) => {
												setSelectionConectivityOption(option);
												//Update options stored in parent
												let currentOptions = {...options};			//current options for all entried
												let currentSlotOptions = options[slot];	//This entry's currentoptions
												let newSlotOptions =  {...currentSlotOptions, conectivityID:option.id }
												currentOptions[slot] = newSlotOptions;
												if (DEBUG_MODE > 1) console.log("Updated slot options based on conectivity selection", currentOptions);
												setOptions(currentOptions);
												}} 
											isMulti={false} 
											value={selectedConectivityOption} 
											disabled={!options[slot] || !options[slot].enabled}  
											placeholder=""
											padding="0px"
									/>
								}
								<div><OpenButton hoverText="Preview" onClickCallback={handleDetailedView} onClickCallbackParam={options[slot] ? options[slot].conectivityID : thisConfigEntry.defaultConectivityID}/> </div>
							</div>
							<div className="TextStyle4" style={{padding:"5px"}}> <i  style={{paddingRight:"5px"}}>who: {thisConfigEntry.whoText}</i>  </div>
							<div className="TextStyle4" style={{padding:"5px"}}> <i  style={{paddingRight:"5px"}}> when:</i> 
									{disabled ?
										<i>{time ? time.format('h:mm a') : thisConfigEntry.startTime}</i> 
									:

										<LocalizationProvider dateAdapter={AdapterMoment}>
												<TimePicker
													label='time'
													value={time}
													disabled={!options[slot] || !options[slot].enabled}  
													onChange={(newTime) => {
														//Update options stored in parent
														let currentOptions = {...options};			//current options for all entried
														let currentSlotOptions = options[slot];	//This entry's currentoptions
														let newSlotOptions =  {...currentSlotOptions, startHour: newTime.get('hour'), startMin:newTime.get('minute') }
														currentOptions[slot] = newSlotOptions;
														if (DEBUG_MODE > 1) console.log("Updated slot options based on time selection", slot, currentOptions);
														setOptions(currentOptions);
													}}
												/>
										</LocalizationProvider>
									}
							</div>
					</div>					
			  </div>    
		 </div> 
		 );
});

	//Render function for authenticated user 
	//confirm access to this page
	if (!isSuperAdmin)return null;
	else  return (

	<Authenticator>
		<div className="adminPage">
			<div className="dashboardContainer boxShadow" style={{position:"relative"}}>
				<div className="dashboardTitle ">
					<div className="ContainerNoHeightSpaceBetween" >
						<div className="ContainerNoHeightCenter">
							<div>Connection Builder</div>
							<HelpButton hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' hoverText={HELP_TEXT_ADMIN_NEW_EMPLOYEE_SUPPORT_1}  /> 
						</div>
						<div className="ContainerNoHeightCenter">
							{!inEditMode ? 
								<EditButton onClickCallback={handlePutInEditMode} hoverText="Edit"/> 
							: 
								null 
							}
						</div>
					</div>
				 </div>   
				 
				<div className="ContainerVerticalSpaceAround" >
					<div className="ContainerNoHeightCenter purple" style={{paddingTop:"2rem"}}>
						<h3> Cultivate Organizational Connections! </h3>
					</div>
					<div className="ContainerNoHeightCenter" style = {{padding:"5px 10px"}}>
					<div> {(configDetailsToEdit && configDetailsToEdit.enableEmployeeConnectionBuilder ? 'Enabled' : 'Disabled' )} </div> 
					<Switch
							name="enableEmployeeConnectionBuilder"
							checked={(configDetailsToEdit && configDetailsToEdit.enableEmployeeConnectionBuilder ? true : false)}   
							onChange={handleEnableToggle}
							color="primary"
							disabled={inEditMode ? false : true}
						/>
				</div>
				
				<div className="ContainerNoHeightCenter wrap" style={{paddingTop:"0.5rem"}}>
					<div style={{flex:"1"}}> 
						<p className="black-text" >Modern work models often lack opportunities for spontaneous moments of innovation that happen naturally in a shared work space.  Customize your employee's experience to reflect your goals, and automatically inject conectivities to 
						easily build connections between colleagues, team leads, mentors and supervisors. </p>
					</div>    
					<div className="imageContainer" style={{flex:"1"}}><img  className="ccImgXXXXXL" src={IMAGE_COMMUNITY_1} alt='' /> </div>
				</div>
	
				{ EMPLOYEE_CONNECTION_BUILDER_PROGRAM.map((entry, index) => {
					return <AutomaticallyScheduledConectivity key={index.toString()+'slot'} slot={entry.label} options={options} setOptions={setOptions} zIndex={index} />
				})}

				{!inEditMode ?  null :
					<div className = "ContainerNoHeightFlexEnd buttonWrapperWithMargin fullWidth" >
						<button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleConfirmationEdit} > Save </button>
						<button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleResetEditForm} > Cancel </button>
					</div>
				}
			
				<div className="dataGridWrapperTop" >
					<MenuDataGrid 
						dataGridRows={autoLaunchRulesRows} 
						dataGridColumns={autoLaunchRulesColumns}
					/>
				</div>

			</div> 
			</div>   
			<AutoLaunchRulesEditor  
				showRulesEditor={showRulesEditor}  
				ruleToProcess={ruleToProcess} 
				handleCloseEditor={handleCloseEditor}  
			/>		
			<ConectivityDetailedView showModal={showModalConectivityDetailed} conectivityToView={conectivityToView}  handleCancelDetailedView={handleCancelDetailedView} />
		</div>
	</Authenticator>
 
  );
};


export default AdminConnectionBuilder;

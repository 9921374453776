//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, CHALLENGE_EVAL_SELECTION_OPTIONS, COLOR_BLACK_OPAQUE, COLOR_GOLD, COLOR_BRONZE, COLOR_SILVER, COLOR_BLACK, FAINT_GREY, COLOR_WHITE, DARK_GREY, TEAM_COLOR } from '../data/conectereConfigData';
import { HELP_TEXT_CHALLENGE_SETUP_2, HELP_TEXT_CHALLENGE_SETUP_3, HELP_TEXT_CHALLENGE_SETUP_4, HELP_TEXT_CHALLENGE_SETUP_5, HELP_TEXT_CHALLENGE_SETUP_6 } from '../data/helpData.js';
import {  setHeaderColorByCategory } from "./conectivityUtils";
import { EditButton, DeleteButton, PublishButton, CancelButton, displayAwards } from "../../shared/utils/generalUtils";

//React & Amplify
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';

//Context
import { AuthContext } from '../../shared/context/authContext';                    //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context


//Utils
import moment from 'moment';
import { displayUserAvatar, displayTeamAvatar } from "./userAndTeamUtils";


//Icons
import HighlightIcon from '@mui/icons-material/Highlight';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';


//Utils
import { invokeAPI, getDataRecordById } from "./databaseUtils";
import { setHeaderColorByStringLength } from "./conectivityUtils";
import {HelpButton, Divider  } from "./generalUtils";
import { formatDateSimple } from "./dateTimeUtils";
import { DisplayDateTimeSelect, TODAY_NOON  } from "./dateTimeUtils";

//Components
import Select from 'react-select';   //Version with support from multiple selections
import Checkbox from '@mui/material/Checkbox';
import { v4 as uuidv4 } from 'uuid';    

export const DisplayChallengeSelect = ({challengeOptions, callback, disabled}) => {

	//Context
	const { isAdmin, currentUser } = useContext(AuthContext);    

	//Local state
	const [selectedEvalOption, setSelectedEvalOption] = useState(selectEvalOption(challengeOptions));

	function selectEvalOption(challengeOptions) {
		if (!challengeOptions || !challengeOptions.inputEval) return  CHALLENGE_EVAL_SELECTION_OPTIONS[0];
		return CHALLENGE_EVAL_SELECTION_OPTIONS.find(option => option.value === challengeOptions.inputEval);
	}

	function updateRewards(updatedRewardObject) {
		if (DEBUG_MODE) console.log("Update Rewards invoked", updatedRewardObject);
		let tempRewards = (challengeOptions.rewards ? {...challengeOptions.rewards} : {}); //Copy existing rewards
		if (DEBUG_MODE) console.log("Existing rewards", tempRewards);
		tempRewards = {...tempRewards, ...updatedRewardObject}  //inject updated reward options from UI
		if (DEBUG_MODE) console.log("Updated rewards", tempRewards);
		if (callback) callback({ ...challengeOptions, rewards:tempRewards});    //Inform parent of new params
		else if (DEBUG_MODE) console.error("Error - no callback");
	}

	function limitMaxCoins(){
		if (!currentUser || isAdmin) return '9999';
		let coinLimit = '9999';
		if (currentUser && currentUser.customer && currentUser.customer.configDetails && currentUser.customer.configDetails.challengeCoinCap) coinLimit=currentUser.customer.configDetails.challengeCoinCap.toString();
		return coinLimit;
	}
	function limitMaxDEIBadges(){
		if (!currentUser || isAdmin) return '9';
		let limit = '9';
		if (currentUser && currentUser.customer && currentUser.customer.configDetails && currentUser.customer.configDetails.challengeBadgesDEICap) limit=currentUser.customer.configDetails.challengeBadgesDEICap.toString();
		return limit;
	}
	function limitMaxCSBadges(){
		if (!currentUser || isAdmin) return '9';
		let limit = '9';
		if (currentUser && currentUser.customer && currentUser.customer.configDetails && currentUser.customer.configDetails.challengeBadgesCSCap) limit=currentUser.customer.configDetails.challengeBadgesCSCap.toString();
		return limit;
	}	
	function limitMaxSpotlights(){
		if (!currentUser || isAdmin) return '9';
		let limit = '9';
		if (currentUser && currentUser.customer && currentUser.customer.configDetails && currentUser.customer.configDetails.challengeRecognitionsCap) limit=currentUser.customer.configDetails.challengeRecognitionsCap.toString();
		return limit;
	}	
	//Init options object
	if (!challengeOptions) challengeOptions={selectedEvalOption: CHALLENGE_EVAL_SELECTION_OPTIONS[0]};

   return (
		<div className="ContainerVerticalStartStart positionRelative" >
			<div className="ContainerNoHeightCenter" style={{zIndex:"100"}}>
				<div className='TextStyle3' style={{paddingRight:"clamp(4px,0.7vw,8px)"}}>tracked item</div>
				<input
					style={{border: (!challengeOptions.inputLabel ? "1px solid red" : "1px solid grey")}}
					name="inputLabel"
					className='TextStyle3 grey-text white-background'
					type="text"
					size="15"
					value={challengeOptions.inputLabel ? challengeOptions.inputLabel : ""}
					onChange={event => callback({ ...challengeOptions, inputLabel: event.target.value })}
					required
					disabled={disabled}
				/>
				<HelpButton hoverText='Enter a label so your colleagues know what to enter throughout the challenge, like "steps" or "miles" or "minutes"  ' /> 
			</div>

			<div className="ContainerNoHeightCenter" style={{zIndex:"90"}}>
				<div className='TextStyle3' style={{paddingRight:"clamp(4px,0.7vw,8px)"}}>ranked by</div>
				<Select    
					isMulti={false} 
					name='inputEval' 
					options={CHALLENGE_EVAL_SELECTION_OPTIONS} 
					onChange={event => {setSelectedEvalOption(event); callback({ ...challengeOptions, inputEval: event.value });}}
					value={selectedEvalOption ? selectedEvalOption : CHALLENGE_EVAL_SELECTION_OPTIONS[0]}
					// placeholder={placeholder}
					isDisabled={disabled}
					isClearable={false}
					formatOptionLabel={option => (
						<div className="ContainerNoHeightFlexLeft noWrap">
								<span className="TextStyle2"  style={{paddingLeft: "5px", whiteSpace:"nowrap"}}>{option.displayString}</span>   
						</div>
					)}
				/>

				<HelpButton hoverText={HELP_TEXT_CHALLENGE_SETUP_2} isHTML={true} hoverTextClassName='hoverTextStyle1Wide' /> 
			</div>

			<div className="ContainerNoHeightCenter" style={{zIndex:"85"}}>
				<div className='TextStyle3' style={{paddingRight:"clamp(4px,0.7vw,8px)"}}>compete as teams?</div>
				<Checkbox
					disabled={disabled }
					checked={challengeOptions.isTeamCompetition ? challengeOptions.isTeamCompetition : false}
					onChange={event => callback({ ...challengeOptions, isTeamCompetition: !challengeOptions.isTeamCompetition })}
					inputProps={{ 'aria-label': 'controlled' }}
				/>      
				<HelpButton hoverText={HELP_TEXT_CHALLENGE_SETUP_4} /> 
			</div> 

    
			<div className="ContainerNoHeightCenter" style={{zIndex:"82"}}>
				<div className='TextStyle3' style={{paddingRight:"clamp(4px,0.7vw,8px)"}}>set a goal?</div>
				<Checkbox
					disabled={disabled }
					checked={challengeOptions.useGoal ? challengeOptions.useGoal : false}
					onChange={event => callback({ ...challengeOptions, useGoal: !challengeOptions.useGoal })}
					inputProps={{ 'aria-label': 'controlled' }}
				/>      
				<HelpButton hoverTextClassName='hoverTextStyle1Wide' hoverText={HELP_TEXT_CHALLENGE_SETUP_3} /> 
			</div> 

			{!challengeOptions.useGoal ? null :   
				<div className='ContainerNoHeightCenter ' style={{marginLeft:"clamp(2px,0.5vw,4px)"}}>
					<input
						style={{width:'clamp(30px,5vw,60px)', borderRadius:'clamp(2px,0.3vw,4px)', textAlign:"end", border: (challengeOptions.useGoal && !challengeOptions.goal ? "1px solid red" : "1px solid grey")}}

						name="goal"
						className='TextStyle3 grey-text white-background'
						type="number"
						value={challengeOptions.goal ? challengeOptions.goal : ''}
						onChange={event => callback({ ...challengeOptions, goal: event.target.value })}
						required
						disabled={disabled}
						min="1" max="999999"
					/>
					<div className='TextStyle3 grey-text white-background'>&nbsp;{challengeOptions.inputLabel ? challengeOptions.inputLabel : null}</div>
				</div>
			}

		{!isAdmin && !currentUser.customer.configDetails.enableEmployeeChallengeRewards ? null :
			<div className="ContainerNoHeightCenter" style={{zIndex:"80"}}>
				<div className='TextStyle3' style={{paddingRight:"clamp(4px,0.7vw,8px)"}}>give rewards?</div>
				<Checkbox
					disabled={(disabled || (!isAdmin && !currentUser.customer.configDetails.enableEmployeeChallengeRewards))}
					checked={challengeOptions.giveRewards ? challengeOptions.giveRewards : false}
					onChange={event => callback({ ...challengeOptions, giveRewards: !challengeOptions.giveRewards })}
					inputProps={{ 'aria-label': 'controlled' }}
				/>      
				<HelpButton hoverText={HELP_TEXT_CHALLENGE_SETUP_6}  hoverTextClassName='hoverTextStyle1Wide' isHTML={false}/> 
			</div> 
			}

			{!challengeOptions.giveRewards ? null :   
				<div className="ContainerVertical fullWidth whiteBackground isHeader" style={{marginLeft:"clamp(2px,0.5vw,4px)", padding:"clamp(2px,0.5vw,4px)" }}>

					<div className="ContainerNoHeightSpaceAround white-background fullWidth " style={{padding:'clamp(4px,0.5v,8px)'}}>
						<div className="ContainerNoHeightCenter" style={{minWidth:'clamp(16px,2vw,24px)'}}>
							<div className="ccImgMediumSquare lb_rank TextStyle2 rankText ContainerVerticalCenter" style={{color:COLOR_WHITE, backgroundColor:COLOR_GOLD}} >1</div>
						</div>
						<div className='ContainerNoHeightCenter ' >
							<input
								style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="firstCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.firstCoins ? challengeOptions.rewards.firstCoins : 0}
								onChange={event => updateRewards({firstCoins:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCoins()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

						</div>

						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="firstCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.firstDEIBadges ? challengeOptions.rewards.firstDEIBadges : 0}
								onChange={event => updateRewards({firstDEIBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxDEIBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="firstCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.firstCSBadges ? challengeOptions.rewards.firstCSBadges : 0}
								onChange={event => updateRewards({firstCSBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCSBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="firstCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.firstSpotlights ? challengeOptions.rewards.firstSpotlights : 0}
								onChange={event => updateRewards({firstSpotlights:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxSpotlights()}
							/>
							<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
						</div>
					</div>

					<div className="ContainerNoHeightSpaceAround fullWidth white-background " style={{marginLeft:'clamp(4px,0.5v,8px)',padding:'clamp(4px,0.5v,8px)'}}>
						<div className=" ContainerNoHeightCenter" style={{minWidth:'clamp(16px,2vw,24px)'}}>
							<div className="ccImgMediumSquare lb_rank TextStyle2 rankText ContainerVerticalCenter" style={{color:COLOR_WHITE, backgroundColor:COLOR_SILVER}} >2</div>
						</div>
						<div className='ContainerNoHeightCenter ' >
							<input
								style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="secondCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.secondCoins ? challengeOptions.rewards.secondCoins : 0}
								onChange={event => updateRewards({secondCoins:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCoins()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="secondCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.secondDEIBadges ? challengeOptions.rewards.secondDEIBadges : 0}
								onChange={event => updateRewards({secondDEIBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxDEIBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="secondCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.secondCSBadges ? challengeOptions.rewards.secondCSBadges : 0}
								onChange={event => updateRewards({secondCSBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxSpotlights()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="secondCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.secondSpotlights ? challengeOptions.rewards.secondSpotlights : 0}
								onChange={event => updateRewards({secondSpotlights:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCSBadges()}
							/>
							<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
						</div>
					</div>

					 <div className="ContainerNoHeightSpaceAround fullWidth white-background " style={{marginLeft:'clamp(4px,0.5v,8px)',padding:'clamp(4px,0.5v,8px)'}}>
						<div className=" ContainerNoHeightCenter" style={{minWidth:'clamp(16px,2vw,24px)'}}>
							<div className="ccImgMediumSquare lb_rank TextStyle2 rankText ContainerVerticalCenter" style={{color:COLOR_WHITE, backgroundColor:COLOR_BRONZE}} >3</div>
						</div>
						<div className='ContainerNoHeightCenter ' >
							<input
								style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="thirdCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.thirdCoins ? challengeOptions.rewards.thirdCoins : 0}
								onChange={event => updateRewards({thirdCoins:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCoins()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="thirdCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.thirdDEIBadges ? challengeOptions.rewards.thirdDEIBadges : 0}
								onChange={event => updateRewards({thirdDEIBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxDEIBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="thirdCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.thirdCSBadges ? challengeOptions.rewards.thirdCSBadges : 0}
								onChange={event => updateRewards({thirdCSBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCSBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="thirdCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.thirdSpotlights ? challengeOptions.rewards.thirdSpotlights : 0}
								onChange={event => updateRewards({thirdSpotlights:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxSpotlights()}
							/>
							<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
						</div>
					</div>

					<div className="ContainerNoHeightSpaceAround fullWidth white-background" style={{marginLeft:'clamp(4px,0.5v,8px)',padding:'clamp(4px,0.5v,8px)'}}>
						<div className="ContainerNoHeightCenter" style={{minWidth:'clamp(16px,2vw,24px)'}}>
							<div className="TextStyle2 rankText ContainerVerticalCenter black-text" style={{color:COLOR_BLACK}} >Last</div>
						</div>
						<div className='ContainerNoHeightCenter ' >
							<input
								style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="lastCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.lastCoins ? challengeOptions.rewards.lastCoins : 0}
								onChange={event => updateRewards({lastCoins:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCoins()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="lastCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.lastDEIBadges ? challengeOptions.rewards.lastDEIBadges : 0}
								onChange={event => updateRewards({lastDEIBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxDEIBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="lastCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.lastCSBadges ? challengeOptions.rewards.lastCSBadges : 0}
								onChange={event => updateRewards({lastCSBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCSBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="lastCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.lastSpotlights ? challengeOptions.rewards.lastSpotlights : 0}
								onChange={event => updateRewards({lastSpotlights:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxSpotlights()}
							/>
							<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
						</div>
					</div>


					<div className="ContainerNoHeightSpaceAround fullWidth white-background" style={{marginLeft:'clamp(4px,0.5v,8px)',padding:'clamp(4px,0.5v,8px)'}}>
						<div className="ContainerNoHeightCenter" style={{minWidth:'clamp(16px,2vw,24px)'}}>
							<div className="TextStyle2 rankText ContainerVerticalCenter black-text"  >All</div>
						</div>
						<div className='ContainerNoHeightCenter ' >
							<input
								style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="allCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.allCoins ? challengeOptions.rewards.allCoins : 0}
								onChange={event => updateRewards({allCoins:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCoins()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="allCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.allDEIBadges ? challengeOptions.rewards.allDEIBadges : 0}
								onChange={event => updateRewards({allDEIBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxDEIBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="allCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.allCSBadges ? challengeOptions.rewards.allCSBadges : 0}
								onChange={event => updateRewards({allCSBadges:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxCSBadges()}
							/>
							<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
						</div>
						<div className='ContainerNoHeightCenter' >
							<input
								style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
								name="allCoins"
								className='TextStyle3 grey-text white-background'
								type="number"
								value={challengeOptions.rewards && challengeOptions.rewards.allSpotlights ? challengeOptions.rewards.allSpotlights : 0}
								onChange={event => updateRewards({allSpotlights:event.target.value})}
								required
								disabled={disabled}
								min="0" max={limitMaxSpotlights()}
							/>
							<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
						</div>
					</div>

					{!challengeOptions || !challengeOptions.useGoal ? null : 
						<div className="ContainerNoHeightSpaceAround fullWidth white-background" style={{marginLeft:'clamp(4px,0.5v,8px)',padding:'clamp(4px,0.5v,8px)'}}>
							<div className="ContainerNoHeightCenter" style={{minWidth:'clamp(16px,2vw,24px)'}}>
								<div className="TextStyle2 rankText ContainerVerticalCenter black-text" >Goal</div>
							</div>
							<div className='ContainerNoHeightCenter ' >
								<input
									style={{width:'4em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
									name="goalCoins"
									className='TextStyle3 grey-text white-background'
									type="number"
									value={challengeOptions.rewards && challengeOptions.rewards.goalCoins ? challengeOptions.rewards.goalCoins : 0}
									onChange={event => updateRewards({goalCoins:event.target.value})}
									required
									disabled={disabled}
									min="0" max={limitMaxCoins()}
								/>
								<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>

							</div>
							<div className='ContainerNoHeightCenter' >
								<input
									style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
									name="goalCoins"
									className='TextStyle3 grey-text white-background'
									type="number"
									value={challengeOptions.rewards && challengeOptions.rewards.goalDEIBadges ? challengeOptions.rewards.goalDEIBadges : 0}
									onChange={event => updateRewards({goalDEIBadges:event.target.value})}
									required
									disabled={disabled}
									min="0" max={limitMaxDEIBadges()}
								/>
								<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI Badges'/>
							</div>
							<div className='ContainerNoHeightCenter' >
								<input
									style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
									name="goalCoins"
									className='TextStyle3 grey-text white-background'
									type="number"
									value={challengeOptions.rewards && challengeOptions.rewards.goalCSBadges ? challengeOptions.rewards.goalCSBadges : 0}
									onChange={event => updateRewards({goalCSBadges:event.target.value})}
									required
									disabled={disabled}
									min="0" max={limitMaxCSBadges()}
								/>
								<img style={{zIndex:'1'}} className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS Badges'/>
							</div>

							<div className='ContainerNoHeightCenter' >
								<input
									style={{width:'2em', borderRadius:'4px', height:'2.3em', border:"none", textAlign:"end"}}
									name="goalCoins"
									className='TextStyle3 grey-text white-background'
									type="number"
									value={challengeOptions.rewards && challengeOptions.rewards.goalSpotlights ? challengeOptions.rewards.goalSpotlights : 0}
									onChange={event => updateRewards({goalSpotlights:event.target.value})}
									required
									disabled={disabled}
									min="0" max={limitMaxSpotlights()}
								/>
								<HighlightIcon className='TextStyle4' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
							</div>
						</div>
					}

				</div>
			}

			<div className="ContainerNoHeightCenter" style={{zIndex:"78"}}>
				<div className='TextStyle3' style={{paddingRight:"clamp(4px,0.7vw,8px)"}}>anonymous leaderboard?</div>
				<Checkbox
					disabled={disabled }
					checked={challengeOptions.isAnonymous ? challengeOptions.isAnonymous : false}
					onChange={event => callback({ ...challengeOptions, isAnonymous: !challengeOptions.isAnonymous })}
					inputProps={{ 'aria-label': 'controlled' }}
				/>      
				<HelpButton hoverText='Would you like to show participants on the leaderboard using anonymous IDs? instead of actual names"  ' /> 
			</div>       

		</div>    
   );
}; 

// Identify which LBs to maintain in our user cache
export function selectLeaderboardsToCache({leaderboards, launchRules, currentUser}) {
	let tempLeaderboards =[];

	if (leaderboards && leaderboards.length > 0 && currentUser) {
		tempLeaderboards = leaderboards.filter(lb => {

			//Patch for legacy code; remove once seperate customer LBs for DAILY, WEEKLY, MONTHLY, etc.
			if (!lb.leaderBoardType || lb.leaderBoardType !== 'CHALLENGE') return true; //cache the master record for this company

			//Is challenge viewable to everyone, like a public challenge or the DAILY, WEEKLY, MONTHLY ones that are always displayed?
			if (lb.isViewableCompanyWide) return true;

			//Is the LB associated with a challenge?
			if (launchRules && launchRules.length > 0) {
				const launchRule = launchRules.find(lr => lr.id === lb.launchRuleID);
				if (launchRule && launchRule.challengeID) {
					//Is the user participating in the challenge individually?
					if (launchRule.usersToInvite && launchRule.usersToInvite.includes (currentUser.id)) return true;

					//Is the user participating in the challenge on one of the teams?
					if (launchRule.teamsToInvite && launchRule.teamsToInvite.length > 0) {
						for (const teamId of launchRule.teamsToInvite) {
							if (currentUser.teams.items.some((join) => join.teamID === teamId)) return true;
						} //End FOR
					} //End if on Team
				} else if (DEBUG_MODE) console.error("Error - could not find LR for leaderboard even though a CHALLENGE LB", lb );
			}  else if (DEBUG_MODE) console.error("Error - no launch rules even though a CHALLENGE LB");
		}); //End Filter
	}
	if (DEBUG_MODE > 1) console.log("Selected LBs to cache for this user",tempLeaderboards);
	return tempLeaderboards
}


/*
	- Display Components for Challenge Data Editor
*/

//Component for creating or editing a challenge data entry
// - challengeDataRecord - optional - provided if editing an existing record
export const ChallengeDataEntry = ({leaderboard, saveLabel, challenge, challengeDataRecord, createCallback, updateCallback, cancelCallback, hideLabels}) => {

	//local state
	const [challengeDataValueLocal, setChallengeDataValueLocal] = useState(null);    //challenge data being submitted
	const [challengeDataType, setChallengeDataType] = useState('number');
	const [activityDateTime, setActivityDateTime] = useState(TODAY_NOON());         //date for challenge data being submitted
	const [forceClosedTrigger1, setForceClosedTrigger1] = useState(false);

	useEffect (() => {
		//Init state params
		initInput({challenge, challengeDataRecord});
		
	},[challenge, challengeDataRecord]);
	 
	async function initInput({challenge, challengeDataRecord}) {
		if (DEBUG_MODE > 1) console.log("Initializing Challenge Data Entry", challenge, challengeDataRecord);

		//Safety check
		if (!challenge) return;
 
		//Set the type of input to capture by our Input HTML element
		let inputType = "number", initialValue=0;
		switch (challenge.inputDataType) {
			case 'DECIMAL':
			case 'INTEGER':
				inputType = "number"; initialValue=0;break;
			case 'TRUE_FALSE':
				inputType = 'checkbox'; initialValue=false;break;
			case 'TEXT':
				inputType = 'text'; initialValue='';  break;
			default:
				inputType = "number"; initialValue=0;break;
		}

		let challengeDataValue = (challengeDataRecord && challengeDataRecord.challengeDataValue ? JSON.parse(challengeDataRecord.challengeDataValue) : initialValue); 
		if (DEBUG_MODE) console.log("Set challenge data input type", inputType);
		if (DEBUG_MODE) console.log("Determined challengeDataRecord value", challengeDataValue);
		setChallengeDataType(inputType);
		setChallengeDataValueLocal(challengeDataValue);
		if (challengeDataRecord) {
			if (DEBUG_MODE) console.log("Setting date for record being edited", challengeDataRecord.activityDate);
			setActivityDateTime(moment(challengeDataRecord.activityDate));
		} 
	}

	const activityDateTimeChangeHandler = (newDateValue) => {
		setActivityDateTime(newDateValue);
	};  

	//Set the state of the SAVE button
	function disableSaveButton () {
		return (!activityDateTime || !challengeDataValueLocal);
	}

	return (
		<div className="challengeDataEntry ContainerNoHeightSpaceAround fullWidth alignEnd" >
			<div className="ContainerVertical ContainerVertical TextStyle3"  onClick={() => {if (DEBUG_MODE) console.log("Forcing close"); setForceClosedTrigger1(!forceClosedTrigger1)}}>
				{hideLabels ? null : <div>activity date</div> }
				<DisplayDateTimeSelect 
					value={activityDateTime} onChange={activityDateTimeChangeHandler} disabledDate={false} disableFuture 
					disabledTime={true} hideTime={true}  zIndex='7777' labelDate="" labelTime=''  forceClosedTrigger = {forceClosedTrigger1}
					minDateString={!leaderboard || !leaderboard.startDateTime  ? null : leaderboard.startDateTime}
					maxDateString={!leaderboard || !leaderboard.endDateTime ? null : leaderboard.endDateTime}
				/>
			</div>
			<div className="ContainerVertical ContainerVertical TextStyle3" style={{zIndex:'8888'}}>
				{hideLabels ? null : 
					<div className="ContainerNoHeightCenter positionRelative">
						<div>{challenge && challenge.inputLabel ? challenge.inputLabel : "#"}</div> 
						<HelpButton positionAbsolute={true} hoverText="Enter a value for the activity being tracked by the challenge, like number of steps, miles, minutes, bags of trash collected, meals served, etc."  /> 
					</div>
				}
				<div className="ContainerNoHeightCenter black alignEnd" style={{padding:"clamp(4px,1vw,10px)"}}>
					<input
						className=''
						style={{border: (!challengeDataValueLocal ? "1px solid red" : "1px solid " + COLOR_BLACK_OPAQUE), width:'5em', borderRadius:'4px', height:'2.3em'}}
						name="challengeDataValueLocal"
						type={challengeDataType}
						value={challengeDataValueLocal || challengeDataValueLocal === 0 ? challengeDataValueLocal : ''}
						onChange={event => setChallengeDataValueLocal(event.target.value)}
						required
						disabled={false}
					/>
				</div>        
			</div>	
			<div className="ContainerNoHeightCenter" style={{padding:"clamp(4px, 1vw, 10px)"}}>

				<button className="buttonStyle1 buttonStyle1HeaderBlue TextStyle2" aria-label="Save"  
					onClick={() => {updateCallback && challengeDataRecord && challengeDataRecord.id ? updateCallback({challengeDataRecord, activityDateTime, challengeDataValue:challengeDataValueLocal}) : createCallback({activityDateTime, challengeDataValue:challengeDataValueLocal}) }} 
					disabled={disableSaveButton()}> {saveLabel} 
				</button>
				{cancelCallback ? 
						 <button  className="buttonStyle1 buttonStyle1DavyGray TextStyle2"  aria-label="cancel" onClick={cancelCallback} > cancel </button>

				: null }
			</div>
		</div>        
	)
}
//Component for displaying challenge data input history
export const ChallengeHistory = ({isReadOnly, leaderboard, leaderboardSpot, cardColor, challenge, challengeDataRecords, updateCallback, deleteCallback}) => {

	//Safety check
	if (!challengeDataRecords || challengeDataRecords.length === 0) return null;

	//Organize the challenge data records into groups by date
	const dateGroups = challengeDataRecords.reduce((dateGroups, challengeDataRecord) => {
		const date = moment(challengeDataRecord.activityDate).format('MMM DD,YYYY');
		if (!dateGroups[date]) dateGroups[date] = [];       //Create a new entry if none
		dateGroups[date].push(challengeDataRecord);
		return dateGroups;
	}, {});

	if (DEBUG_MODE >= 2) console.log("Built challenge data record date GROUPS:", dateGroups);

	// Construct a groups array where each date group is an element in the array indexed by a date label
	const groupArrays = Object.keys(dateGroups).map(date => {
		return {
			date,
			days: dateGroups[date],
		};
	});

	if (DEBUG_MODE >= 2) console.log("Built challenge data records date groups", groupArrays);

	return (
		<div className="ContainerVerticalStart challengeHistory">
			{groupArrays.map((dateGroup,index) => {
				return <ChallengeDateGroup key={index.toString()} isReadOnly={isReadOnly} dateGroup={dateGroup} leaderboard={leaderboard} leaderboardSpot={leaderboardSpot} cardColor={cardColor} challenge={challenge} updateCallback={updateCallback} deleteCallback={deleteCallback}/>
			})}
		</div>
	);
}


export const ChallengeDateGroup = ({isReadOnly, dateGroup, leaderboard, leaderboardSpot, challenge, cardColor, challengeDataRecord, updateCallback, deleteCallback}) => {
	//Safety check
	if (!dateGroup || !dateGroup.days || dateGroup.days.length === 0 || !dateGroup.date) return null;
	if (DEBUG_MODE > 3) console.log("Rendering dateGroup", dateGroup);

	//render function
	try {
		return (
			<div className="ContainerVerticalStart fullWidth ">
				<div className='ContainerNoHeightSpaceBetween fullWidth'>
					<div className="TextStyle4" style={{marginBottom:"clamp(2px,0.5vw,4px"}}> {dateGroup.date} </div>
				</div>     
				{dateGroup.days.map((challengeDataRecord) => {
					return <ChallengeDataRecordCard  key={challengeDataRecord.id} isReadOnly={isReadOnly} leaderboard={leaderboard} leaderboardSpot={leaderboardSpot} cardColor={cardColor} challenge={challenge} challengeDataRecord={challengeDataRecord} updateCallback={updateCallback} deleteCallback={deleteCallback} />
				})}
			</div>
		)
	} catch (err) {
		if (DEBUG_MODE) console.error("Error rendering challenge date group", err);
		return null;
	}
}

//ChallengeDataRecord card 
export const ChallengeDataRecordCard = ({isReadOnly, leaderboard, leaderboardSpot, challenge, cardColor, challengeDataRecord, updateCallback, deleteCallback}) => {

   // Context
   const { users } = useContext(CustomerContext); 

	//Local state
	const [editMode, setEditMode ] = useState(false);
 
	//safety check
	if(!challengeDataRecord) return null;
	let user = users.find(user=>user.id === challengeDataRecord.userID);
	if (DEBUG_MODE >2) console.log("Rendering ChallengeDataRecord ", challenge, challengeDataRecord, user);

	//render function
	try {
		return (
				<div className='challengeDataRecordCard ContainerVertical wrap boxShadow fullWidth' style={{borderTopColor:cardColor}}>
		
					{editMode ?
		
						<ChallengeDataEntry leaderboard={leaderboard} saveLabel="update" challenge={challenge} challengeDataRecord={challengeDataRecord} 
							updateCallback={({challengeDataRecord, activityDateTime, challengeDataValue}) => {setEditMode(false); updateCallback({challengeDataRecord, activityDateTime, challengeDataValue})}} 
							cancelCallback={() => setEditMode(false)} 
							hideLabels 
						/>

					:
						<div className='ContainerNoHeightSpaceAround fullWidth'>
							{/* <div className="TextStyle3" style={{padding:"0px 0.5em"}}> {formatDateSimple(challengeDataRecord.activityDate)} </div> */}

							<div className="avatarContainerPortion">
								{displayUserAvatar({user})}
							</div>     

							<div className="TextStyle3" >
								<span>{challengeDataRecord.challengeDataValue ? JSON.parse(challengeDataRecord.challengeDataValue) : ''} </span>
								<span> {challenge.inputLabel} </span>
							</div>
							{isReadOnly ? null : 
								<div className='ContainerNoHeightCenter' >
									{updateCallback ? <EditButton onClickCallback={()=>setEditMode(!editMode)} onClickCallbackParam={challengeDataRecord.id} /> : null }
									{deleteCallback ? <DeleteButton onClickCallback={deleteCallback} onClickCallbackParam={challengeDataRecord.id} /> : null }
								</div>
							}
						</div>  
					}
				</div>    
		)
	} catch (err) {
		if (DEBUG_MODE) console.error("Error rendering challenge data record card", err);
		return null;
	}
}


//Sort based on activity date;  newest to oldest
export function compareByActivityDatetReverseChronological(a, b) {
   if (!a || !a.activityDate || !b || !b.activityDate) return 0;
   const aDateTime = moment(a.activityDate);
   const bDateTime = moment(b.activityDate); 
   if (aDateTime.isAfter(bDateTime))  return -1;
   if (bDateTime.isAfter(aDateTime)) return 1;
   return 0;
}

export 	function displayChallengePrizes(rewards) {

	//Challenge awards defined?
	if (!rewards) return null;
	if (DEBUG_MODE > 1) console.log("Contstructing challenge info text", rewards);

	try {

		return (

			<div className="ContainerVertical fullWidth white-background isHeader"  >
				<h3 className="ContainerNoHeightCenter ">
					<div><EmojiEventsIcon className="TextStyle6 gold" /><span style={{padding:"clamp(2px,1vw,8px)"}}>Prizes</span><EmojiEventsIcon className="TextStyle6 gold" /></div>
				</h3>
				<Divider percent={80} color={TEAM_COLOR} />

				<div className='ContainerNoHeightSpaceAround fullWidth wrap'>
					{!rewards.firstCoins && !rewards.firstDEIBadges && !rewards.firstCSBadges && !rewards.firstSpotlights ? null :
						<div className="ContainerNoHeightSpaceAround white-background prizesRow" >
							<div className="ContainerNoHeightCenter prizesRank" >
								<div className="lb_rank ccImgLargeSquare TextStyle2 rankText ContainerVerticalCenter" style={{backgroundColor:COLOR_GOLD, color:COLOR_WHITE}} >1</div>
							</div>
							{displayAwards({containerClass:"ContainerNoHeightFlexLeft ", enableCountUp:false, fontSize:'med', coins:rewards.firstCoins, badgesDEI:rewards.firstDEIBadges, badgesCS:rewards.firstCSBadges,  badgesSpotlight:rewards.firstSpotlights, includeBackground:false, darkMode:false, showZeros:false})}
						</div>
					}

					{!rewards.secondCoins && !rewards.secondDEIBadges && !rewards.secondCSBadges && !rewards.secondSpotlights ? null :
						<div className="ContainerNoHeightSpaceAround white-background  prizesRow" >
							<div className="ContainerNoHeightCenter prizesRank " >
								<div className=" lb_rank ccImgLargeSquare TextStyle2 rankText ContainerVerticalCenter" style={{backgroundColor:COLOR_SILVER, color:COLOR_WHITE}} >2</div>
							</div>
								{displayAwards({containerClass:"ContainerNoHeightFlexLeft ", enableCountUp:false, fontSize:'med', coins:rewards.secondCoins, badgesDEI:rewards.secondDEIBadges, badgesCS:rewards.secondCSBadges,  badgesSpotlight:rewards.secondSpotlights, includeBackground:false, darkMode:false, showZeros:false})}
						</div>
					}

					{!rewards.thirdCoins && !rewards.thirdDEIBadges && !rewards.thirdCSBadges && !rewards.thirdSpotlights ? null :
						<div className="ContainerNoHeightSpaceAround white-background prizesRow" >
							<div className="ContainerNoHeightCenter prizesRank " style={{textAlign:"center"}}>
								<div className="ccImgLargeSquare lb_rank TextStyle2 rankText ContainerVerticalCenter" style={{backgroundColor:COLOR_BRONZE, color:COLOR_WHITE}} >3</div>
							</div>
							{displayAwards({containerClass:"ContainerNoHeightFlexLeft", enableCountUp:false, fontSize:'med', coins:rewards.thirdCoins, badgesDEI:rewards.thirdDEIBadges, badgesCS:rewards.thirdCSBadges,  badgesSpotlight:rewards.thirdSpotlights, includeBackground:false, darkMode:false, showZeros:false})}
						</div>
					}

					{!rewards.lastCoins && !rewards.lastDEIBadges && !rewards.lastCSBadges && !rewards.lastSpotlights ? null :
						<div className="ContainerNoHeightSpaceAround white-background prizesRow" >
							<div className="ContainerNoHeightCenter prizesRank" >
								<div className="TextStyle2 rankText ContainerVerticalCenter"  >Sweeper</div>
							</div>
							{displayAwards({containerClass:"ContainerNoHeightFlexLeft", enableCountUp:false, fontSize:'med', coins:rewards.lastCoins, badgesDEI:rewards.lastDEIBadges, badgesCS:rewards.lastCSBadges,  badgesSpotlight:rewards.lastSpotlights, includeBackground:false, darkMode:false, showZeros:false})}
						</div>
					}

					{!rewards.allCoins && !rewards.allDEIBadges && !rewards.allCSBadges && !rewards.allSpotlights ? null :
						<div className="ContainerNoHeightSpaceAround white-background prizesRow" >
							<div className="ContainerNoHeightCenter prizesRank" >
								<div className="TextStyle2 rankText black-text ContainerVerticalCenter"  >All participants</div>
							</div>
							{displayAwards({containerClass:"ContainerNoHeightFlexLeft", enableCountUp:false, fontSize:'med', coins:rewards.allCoins, badgesDEI:rewards.allDEIBadges, badgesCS:rewards.allCSBadges,  badgesSpotlight:rewards.allSpotlights, includeBackground:false, darkMode:false, showZeros:false})}
						</div>
					}

					{!rewards.goalCoins && !rewards.goalCSBadges && !rewards.goalDEIBadges && !rewards.goalSpotlights ? null :
						<div className="ContainerNoHeightSpaceAround white-background prizesRow" >
							<div className="ContainerNoHeightCenter prizesRank" >
								<div className="TextStyle2 grey-text rankText ContainerVerticalCenter" >Goal achievers!!</div>
							</div>
							{displayAwards({containerClass:"ContainerNoHeightFlexLeft ", enableCountUp:false, fontSize:'med', coins:rewards.goalCoins, badgesDEI:rewards.goalDEIBadges, badgesCS:rewards.goalCSBadges,  badgesSpotlight:rewards.goalSpotlights, includeBackground:false, darkMode:false, showZeros:false})}
						</div>
					}
				</div>


			</div>

		)
	} catch (err) {
		if (DEBUG_MODE) console.error("Error rendering challenge prizes.", err);
		return null
	}
}
//test data
export  const testData = [
  {
    month: 'Jan',
    users: 4000,
  },
  {
    month: 'Feb',
    users: 3000,
  },
  {
    month: 'Mar',
    users: 2000,
  },
  {
    month: 'Apr',
    users: 2780,
  },
  {
    month: 'May',
    users: 1780,
  },
  {
    month: 'Jun',
    users: 1890,
  },
  {
    month: 'Jul',
    users: 2390,
  },
  {
    month: 'Aug',
    users: 1490,
  },
    {
    month: 'Sep',
    users: 3490,
  },
  {
    month: 'Oct',
    users: 2490,
  },

  {
    month: 'Nov',
    users: 1490,
  },
  {
    month: 'Dec',
    users: 3490,
  },

];


export const conectivityData = [
  {
    month: 'Jan',
   balance: 4000,
   team: 1000,
   personal: 2000,
   social: 3500,

  },
  {
    month: 'Feb',
   balance: 4000,
   team: 1000,
   personal: 3000,
   social: 2600,
  },
  {
    month: 'Mar',
   balance: 1300,
   team: 4200,
   personal: 1000,
   social: 2300,
  },
  {
    month: 'Apr',
   balance: 2700,
   team: 4000,
   personal: 3000,
   social: 3000,
  },
  {
    month: 'May',
   balance: 2400,
   team: 3200,
   personal: 1000,
   social: 3000,
  },
  {
    month: 'Jun',
   balance: 4000,
   team: 1700,
   personal: 3200,
   social: 1200,
  },
  {
    month: 'Jul',
   balance: 2300,
   team: 3100,
   personal: 3241,
   social: 2000,
  },
  {
    month: 'Aug',
   balance: 2000,
   team: 3000,
   personal: 1000,
   social: 2000,
  },
    {
    month: 'Sep',
   balance: 1000,
   team: 2000,
   personal: 3000,
   social: 4000,
  },
  {
    month: 'Oct',
   balance: 4000,
   team: 3200,
   personal: 2500,
   social: 1800,
  },

  {
    month: 'Nov',
   balance: 1400,
   team: 4000,
   personal: 3200,
   social: 4000,
  },
  {
    month: 'Dec',
   balance: 4200,
   team: 2400,
   personal: 2500,
   social: 2600,
  },

];


export const spendData = [
  {
    month: 'Jan',
   "Employee Store": 352,
   Conectere: 53,
  },
  {
    month: 'Feb',
   "Employee Store": 462,
   Conectere: 27,
  },
  {
    month: 'Mar',
   "Employee Store": 265,
   Conectere: 65,
  },
  {
    month: 'Apr',
   "Employee Store": 465,
   Conectere: 10,
  },
  {
    month: 'May',
   "Employee Store": 221,
   Conectere: 23,
  },
  {
    month: 'Jun',
   "Employee Store": 236,
   Conectere: 23,
  },
  {
    month: 'Jul',
   "Employee Store": 723,
   Conectere: 23,
  },
  {
    month: 'Aug',
   "Employee Store": 254,
   Conectere: 54,
  },
    {
    month: 'Sep',
   "Employee Store": 423,
   Conectere: 143,
  },
  {
    month: 'Oct',
   "Employee Store": 363,
   Conectere: 14,
  },

  {
    month: 'Nov',
   "Employee Store": 456,
   Conectere: 24,
  },
  {
    month: 'Dec',
   "Employee Store": 276,
   Conectere: 92,
  },

];


export const userData = [
  {
    name: 'Jon Smith',
   balance: 4000,
   team: 1000,
   personal: 2000,
   social: 3500,

  },
  {
    name: 'Sue Daly',
   balance: 4000,
   team: 1000,
   personal: 3000,
   social: 2600,
  },
  {
    name: 'Kim Elfstrom',
   balance: 1300,
   team: 4200,
   personal: 1000,
   social: 2300,
  },
  {
    name: "Bob Malley",
   balance: 2700,
   team: 4000,
   personal: 3000,
   social: 3000,
  },
  {
    name: 'Fred F',
   balance: 2400,
   team: 3200,
   personal: 1000,
   social: 3000,
  },
  {
    name: 'Barney R',
   balance: 4000,
   team: 1700,
   personal: 3200,
   social: 1200,
  },
  {
    name: 'Wilma',
   balance: 2300,
   team: 3100,
   personal: 3241,
   social: 2000,
  },
  {
    name: 'Jeff Jones',
   balance: 2000,
   team: 3000,
   personal: 1000,
   social: 2000,
  },
    {
    name: 'Tim Riddle',
   balance: 1000,
   team: 2000,
   personal: 3000,
   social: 4000,
  },
  
];




export const groupData = [
  {
    subject: 'Balance',
    A: 120,
    B: 80,
    fullMark: 150,
  },
  {
    subject: 'Team',
    A: 98,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Personal',
    A: 86,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Social',
    A: 67,
    B: 100,
    fullMark: 150,
  },
  {
    subject: 'Diversity, Equity & Inclusion',
    A: 85,
    B: 120,
    fullMark: 150,
  },
  {
    subject: 'Community Service',
    A: 65,
    B: 85,
    fullMark: 150,
  },
];


export const ringData = [
  { name: 'Group A', value: 15 },
  { name: 'Group B', value: 23 },
  { name: 'Group C', value: 17 },
  { name: 'Group D', value: 19 },
];


export const ringColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    },
];

export const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


export  const testProductDataByCount = [
  {
    product: 'Airpods',
    purchase: 20,
  },
  {
    product: 'Wild Tickets',
    purchase: 15,
  },
  {
    product: 'Backpack',
    purchase: 12,
  },
  {
    product: 'LapDesk',
    purchase: 11,
  },
  {
    product: 'Oculus',
    purchase: 9,
  },
  
];

export  const testProductDataBySpend = [
  {
    product: 'Airpods',
    purchase: 2000,
  },
  {
    product: 'Wild Tickets',
    purchase: 1500,
  },
  {
    product: 'Backpack',
    purchase: 1200,
  },
  {
    product: 'LapDesk',
    purchase: 1100,
  },
  {
    product: 'Oculus',
    purchase: 900,
  },
  
];



export const couslonData = [
  {
    name: 'Jon Smith',
   balance: 4000,
   team: 1000,
   personal: 2000,
   social: 3500,

  },
  {
    name: 'Sue Daly',
   balance: 4000,
   team: 1000,
   personal: 3000,
   social: 2600,
  },
  {
    name: 'Kim Elfstrom',
   balance: 3300,
   team: 4200,
   personal: 1000,
   social: 2300,
  },
  {
    name: "Bob Malley",
   balance: 2700,
   team: 4000,
   personal: 3000,
   social: 3000,
  },
  {
    name: 'Fred F',
   balance: 2400,
   team: 3200,
   personal: 1000,
   social: 3000,
  },
  {
    name: 'Barney R',
   balance: 4000,
   team: 1700,
   personal: 3200,
   social: 1200,
  },
  {
    name: 'Wilma',
   balance: 2300,
   team: 3100,
   personal: 3241,
   social: 2000,
  },
  {
    name: 'Jeff Jones',
   balance: 2000,
   team: 3000,
   personal: 1000,
   social: 2000,
  },
    {
    name: 'Tim Riddle',
   balance: 1000,
   team: 2000,
   personal: 3000,
   social: 4000,
  },
  
];





//Example momentum values
const couslonEnagementSignalValuesTestData1 = [
  {
    alert: 0,
    alertTimeStamp: "2022-06-10T00:00:00.000Z",
    createdAt: "2022-06-10T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 59.523809523809524,
    sequenceNumber: 0,
    updatedAt: "2022-06-10T23:30:53.119Z",
    //conectivityCompleted:true,
  },
  {
    alert: 0,
    alertTimeStamp: "2022-06-11T00:00:00.000Z",
    createdAt: "2022-06-11T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 49.523809523809524,
    sequenceNumber: 1,
    updatedAt: "2022-06-11T23:30:53.119Z",
    //conectivityCompleted:true,
  },
  {
    alert: 0,
    alertTimeStamp: "2022-06-12T00:00:00.000Z",
    createdAt: "2022-06-12T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 39.523809523809524,
    sequenceNumber: 2,
    updatedAt: "2022-06-12T23:30:53.119Z",
    //conectivityCompleted:true,
  },
  {
    alert: 1,
    alertTimeStamp: "2022-06-13T00:00:00.000Z",
    createdAt: "2022-06-13T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 29.523809523809524,
    sequenceNumber: 3,
    //conectivityCompleted:false,
    updatedAt: "2022-06-13T23:30:53.119Z",
  },
  {
    alert: 1,
    alertTimeStamp: "2022-06-14T00:00:00.000Z",
    createdAt: "2022-06-14T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 19.523809523809524,
    sequenceNumber: 4,
    updatedAt: "2022-06-14T23:30:53.119Z",
    //conectivityCompleted:false,
  },
  {
    alert: 1,
    alertTimeStamp: "2022-06-15T00:00:00.000Z",
    createdAt: "2022-06-15T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 22.523809523809524,
    sequenceNumber: 5,
    updatedAt: "2022-06-15T23:30:53.119Z",
    //conectivityCompleted:true,
  },
]
const couslonEnagementSignalValuesTestData2 = [
  {
    alert: 0,
    alertTimeStamp: "2022-06-10T00:00:00.000Z",
    createdAt: "2022-06-10T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 49.523809523809524,
    sequenceNumber: 0,
    updatedAt: "2022-06-10T23:30:53.119Z",
    //conectivityCompleted:true,
  },
  {
    alert: 0,
    alertTimeStamp: "2022-06-11T00:00:00.000Z",
    createdAt: "2022-06-11T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 49.523809523809524,
    sequenceNumber: 1,
    updatedAt: "2022-06-11T23:30:53.119Z",
    //conectivityCompleted:true,
  },
  {
    alert: 0,
    alertTimeStamp: "2022-06-12T00:00:00.000Z",
    createdAt: "2022-06-12T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 34.523809523809524,
    sequenceNumber: 2,
    updatedAt: "2022-06-12T23:30:53.119Z",
    //conectivityCompleted:false,
  },
  {
    alert: 0,
    alertTimeStamp: "2022-06-13T00:00:00.000Z",
    createdAt: "2022-06-13T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 49.523809523809524,
    sequenceNumber: 3,
    updatedAt: "2022-06-13T23:30:53.119Z",
    //conectivityCompleted:true,
  },
  {
    alert: 0,
    alertTimeStamp: "2022-06-14T00:00:00.000Z",
    createdAt: "2022-06-14T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 19.523809523809524,
    sequenceNumber: 4,
    updatedAt: "2022-06-14T23:30:53.119Z",
    //conectivityCompleted:false,
  },
  {
    alert: 1,
    alertTimeStamp: "2022-06-15T00:00:00.000Z",
    createdAt: "2022-06-15T23:30:53.118Z",
    engagementSignalID: "9d674da9cf6a4f3fbea0199ff25801b9",
    id: "c81d63995e604b428814f7c61a2917bc",
    momentum: 22.523809523809524,
    sequenceNumber: 5,
    updatedAt: "2022-06-15T23:30:53.119Z",
    //conectivityCompleted:false,
  },
  ];
  
  
//Array of signal(s) mimicking the records we would read from the DB
export const couslonEnagementSignalTestData = [
    {
    
    category: "STRESS",
    createdAt: "2022-06-16T23:30:53.077Z",
    customerID: "10a31f74-1b96-4c4a-95b3-bc25c4801f96",
    engagementValues: {items: couslonEnagementSignalValuesTestData1},
    id: "9d674da9cf6a4f3fbea0199ff25801b9",
    nextSequenceNumber: 1,
    periodEnd: "2022-06-16T00:00:00.000Z",
    periodStart: "2022-05-26T00:00:00.000Z",
    timeStep: "DAILY",
    updatedAt: "2022-06-16T23:30:53.077Z",
    userID: "6ee06830-c6b8-4575-a8c8-fe776b3a8256",
    
  },
  {
    category: "STRESS",
    createdAt: "2022-06-16T23:30:53.077Z",
    customerID: "10a31f74-1b96-4c4a-95b3-bc25c4801f96",
    engagementValues: {items: couslonEnagementSignalValuesTestData2},
    id: "9d674da9cf6a4f3fbea0199ff25801ba",
    nextSequenceNumber: 1,
    periodEnd: "2022-06-16T00:00:00.000Z",
    periodStart: "2022-05-26T00:00:00.000Z",
    timeStep: "DAILY",
    updatedAt: "2022-06-16T23:30:53.077Z",
    userID: "dfab78f3-8f1a-4742-80e2-8bb869b1d86b",
    
  },  
];



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, SLACK_CONSENT_URL } from '../data/conectereConfigData';
import { queryDataTableWithPagination }  from "./databaseUtils";
import { getMsftTeamsInstallationByCustomerID } from '../graphql/queries';


//React & Amplify
import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';

export function displayMsftTeamsAccessControlButton({msftTeamsEnabled, isAdmin, clickCallback}) {
     
     if (!clickCallback) return null;
     
     //Button always enabled; probably adjust this with an ADMIN setting for enabling MsftTeams integration
     const disabled = !isAdmin;

     return(
          <div className={`calendarAccessControlCard ContainerNoHeightCenter ${(disabled) && "calendarAccessControlCardDisabled"}`} onClick={event => clickCallback()}> 
              <div className="ContainerNoHeightCenter"  style={{minHeight:"70px", width:"50px", backgroundColor:"white"}}>
                  <img  style={{height:"30px"}} src={CONECTERE_CONFIG_DATA.SLACK_LOGO} alt='Microsoft Teams'/>
              </div>
              <div style={{padding:"0.5vh 1vh", flex:1}}>
                   {isAdmin ? <div > {!msftTeamsEnabled ? "Enable Microsoft Teams" : "Disable Microsoft Teams" } </div>
                    : <div > {msftTeamsEnabled ? "Microsoft Teams Integration Enabled" : "Microsoft Teams Integration Disabled" } </div> }
              </div>
          
              {/* msftTeamsEnabled ? 
                  <div className="ContainerNoHeightCenter" style={{backgroundColor:"white", borderRadius:"50%",  position:"absolute", top:"0", right:"0", transform: "translate(50%,-65%)"}}>
                      <CheckCircleIcon style={{color:COLOR_GOOD, fontSize:"3.0vh"}}/> 
                  </div>                                
              : null */}
         </div>

     );
 }

 export async function buildMsftTeamsChannelOptions(customerID) {
    console.log("Fetching MsftTeams channels");  

    let installations = await  queryDataTableWithPagination(getMsftTeamsInstallationByCustomerID, 'getMsftTeamsInstallationByCustomerID', { customerID: customerID });
    if (DEBUG_MODE > 0) console.log("Fetched Teams installations", installations);
    installations = installations.filter(record => (record.conversation_type === 'channel'));
    if (DEBUG_MODE > 0) console.log("Filtered Teams installations to channels", installations);

    let tempChannels = [];
    //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
    if (installations && installations.length > 0) {
        for(const channel of installations){
            tempChannels.push ({
                ...channel,
                label:channel.msft_team_name + " -> " + channel.channel_name,
                value:channel.id,
            });
        }                  
        if (DEBUG_MODE >= 2) console.log("Extracted fetched channels", tempChannels);

    //Set the selected option
    } else {
    if (DEBUG_MODE >= 1) console.log("No MsftTeams returned channel installations yet", installations);
    }

    return tempChannels;
} 

export function inMsftTeams() {
    //safety check
    if (!window) {
        console.log("No window detected");
        return;
    }

    
    console.log("Window parent:", window.parent);
    console.log("Window navigator:", window.navigator);
    console.log("Window name:", window.name);

    if (
        window.navigator.userAgent.toLowerCase().includes("teams/") ||
        window.navigator.userAgent.toLowerCase().includes("outlook") ||
        window.navigator.userAgent.toLowerCase().includes("m365") ||
        window.name === "embedded-page-container" ||
        window.name === "extension-tab-frame"
    ) {
        return true;
    }
    return false;
}
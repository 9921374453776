//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved
//

import './calendarContext.css';


//config
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_TEXT, COLOR_GOOD,
            REDIRECT_URI, POST_LOGOUT_REDIRECT_URI,
            GOOGLE_CALENDAR_CLIENT_SECRET, GOOGLE_CALENDAR_CLIENT_ID, GOOGLE_SCOPES, GOOGLE_INDENTITY_URL, GOOGLE_APIS_URL, GOOGLE_DISCOVERY_DOC,
            MSAL_CONFIG, MSFT_AUTHCODE_URL_PARAMS, MSFT_LOGIN_PARAMS, REDIRECT_URI_MSFT_TO_LOGOUT,
            TEAM_COLOR, 
} from '../data/conectereConfigData';

import React, { useEffect, useState, useContext, useRef } from "react";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {useLocation} from 'react-router-dom';

//CONTEXT
import { AuthContext } from './authContext';                //User Authentication context
import { ConectivityContext } from './conectivitiesContext';

//Queries and Mutations
import { calendarFetchUserEvents, calendarEnableAccess, calendarCreateEvent, calendarDeleteEvent, calendarDisableAccess, updateUser, updateConectereConfig } from '../graphql/mutations';           //Our mutation for directly calling our Lambda function to exchange the AuthCode from the client for  Access Tokens from the correct backend Cloud provider, like Google Cloud

//Subscriptions

//Material UI & Bootstrap components

//Material UI and my components therefrom

//Utils
import { invokeAPI, storeUpdatedUser } from "../utils/databaseUtils";

//Our components
// import ModalNoBackground from "../Components/modalNoBackground/modalNoBackground";


//Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//MSFT REACT & SPA Access Libraries
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";

const CalendarContext = React.createContext();
 
const CalendarsProvider = ({ children }) => {

    const currentReactPage = useLocation(); //This React reference is updated every time the component loads
    const crypto = require('crypto');

   // Authentication context
    const { authState, currentUser, setCurrentUser, isAdmin } = useContext(AuthContext);

   //state variables for error messages


   //
   // MASTER Calendar OAuth redirect handler; on initial LOAD extract the auth code from the URL along with and STATE value
   // Note, we need to run this after the user is authenticated since it results in an API call to our lambda
   //
    useEffect(() => {

        //Need to wait until the current user is fully signed in via Amplify
        if (authState !== "signedin") return;
        if (DEBUG_MODE >= 1) console.log("Calendar context - Checking current user status; Current React Page", currentReactPage, currentUser);

        //To avoid multiple calls, ignore the URL params if already logged in and all services enabled
        if (currentUser.googleCalendarEnabled || currentUser.ms365CalendarEnabled) return;

        //Now, check the URL params
        const searchParams = new URLSearchParams(currentReactPage?.search);
        if (DEBUG_MODE >= 1) console.log("Calendar context - Current React Page", currentReactPage);
        if (DEBUG_MODE >= 1) console.log("Calendar context - Page React Search Params (after ?)", searchParams);

        //Does the URL contain an Oauth code for enabling a 3rd party cloud?
         if (searchParams.has("code")) { 
            if (DEBUG_MODE >= 1) console.log("3rd Party Cloud AUTH CODE Detected!!");
            const code = searchParams.get("code");
            const state = searchParams.get("state");
            if (DEBUG_MODE >= 1) console.log(" STATE =", state); if (DEBUG_MODE >= 1) console.log(" CODE =", code);
            setAuthorizationStateData(state);
            
            if (state && state.includes('MS365')) {
                
                if (DEBUG_MODE >= 1) console.log("MS365 authorization code detected in URL");
                setShowSpinner(true);                    
                setCalendarToAuthorize("MS365");
                setAuthorizationCode(code); //Trigger our useEffect to invoke Lambda for processing the AUTH code
                
            }
         }      
         
    }, [authState]);

    // State Vars

    // ********************** GENERAL CALENDAR VARS ********
    
    const [authorizationCode, setAuthorizationCode] = useState();
    const [authorizationStateData, setAuthorizationStateData] = useState();
    const [calendarToAuthorize, setCalendarToAuthorize] = useState();
    const [calendarEventToSend, setCalendarEventToSend] = useState(null);   //Object to trigger send to Lambda; eliminates blocking of main thread
    const [calendarEventToDelete, setCalendarEventToDelete] = useState(null);   //Object to trigger send to Lambda; eliminates blocking of main thread


    // ********************** MSFT CALENDAR SUPPORT ********

    //NODE JS VERSION
    var msal = require('@azure/msal-node');
    // if (DEBUG_MODE >= 1) console.log("Microsoft params", MSAL_CONFIG);
    // const msalInstance = new msal.ConfidentialClientApplication(MSAL_CONFIG);
    const msalInstance = new msal.PublicClientApplication(MSAL_CONFIG);
    

    //REACT / BROWSER MSAL LIBS    
    // if (DEBUG_MODE >= 1) console.log("Microsoft params", MSAL_CONFIG);
    // const msalInstance = new PublicClientApplication(MSAL_CONFIG);
    
    // if (DEBUG_MODE >= 1) console.log("MSAL instance", msalInstance);


// ********************** GOOGLE CALENDAR SUPPORT ********
//
// our Google CONSTs GOOGLE_CALENDAR_CLIENT_SECRET, GOOGLE_CALENDAR_CLIENT_ID, GOOGLE_DISCOVERY_DOC, GOOGLE_SCOPES, GOOGLE_INDENTITY_URL, GOOGLE_APIS_URL
//

        // GOOGLE state vars 
        // const [googleTokenClient, setGoogleTokenClient]  = useState(null);
        // const [tokenClient, setTokenClient] = useState();
        // const [accessToken, setAccessToken] = useState();
        
        //vars for tracking whether the Google Auth and Calendar API scripts have been loaded on the page
        const [gapiScriptLoaded, setGapiScriptLoaded] = useState(false);
        const [gapiInitiated, setGapiInitiated] = useState(false);
        const [gIdentityServicesScriptLoaded, setGIdentityServicesScriptLoaded] = useState(false);
        const [gisInitiated, setGisInitiated] = useState(false);
        

        const [authCodeClientGoogle, setAuthCodeClientGoogle] = useState();
        // const [authCodeClientMicrosoft, setAuthCodeClientMicrosoft] = useState();
        
        
        // const [calendarResults, setCalendarResults] = useState([]);
        // const [googleButtonImage, setGoogleButtonImage] = useState(CONECTERE_CONFIG_DATA.GOOGLE_BUTTON_IMAGE_NORMAL);
        
   
    // useEffect(() => {
    
    // //Confirm we have an authenticated "current user"
    // if (authState != "signedin") return;
    
    //     if (currentUser.googleCalendarEnabled) setGoogleButtonImage(CONECTERE_CONFIG_DATA.GOOGLE_BUTTON_IMAGE_DISABLED);
    //     else setGoogleButtonImage(CONECTERE_CONFIG_DATA.GOOGLE_BUTTON_IMAGE_NORMAL);
    
    // },[authState]);
   
        
  // Dynamically load Google Scripts with React and the useEffect Hook
  // https://rangen.medium.com/dynamically-load-google-scripts-with-react-and-the-useeffect-hook-3700b908e50f
  // https://stackoverflow.com/questions/8578617/inject-a-script-tag-with-remote-src-and-wait-for-it-to-execute
  // https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#js-client-library
  
  // Load Google Identity Services and APIs from remote source upon mount
    useEffect(() => {

        //Confirm we have an authenticated "current user"
        // if (authState != "signedin") return;
 
        // if (currentUser.googleCalendarEnabled) {       

           //Load Google API if enabled for this user OR if enableCalendarRequest is received from the user
           if (!gIdentityServicesScriptLoaded) {
    
            //  if (DEBUG_MODE >= 1) console.log("Loading Google Identity Services Script");
             
             //Code to dynamically insert a script to load the GoogleIS on page load
            const striptTag = document.createElement('script');
            striptTag.src = GOOGLE_INDENTITY_URL;
            striptTag.addEventListener('load',  () => {
                setGIdentityServicesScriptLoaded(true); 
                // if (DEBUG_MODE >= 1) console.log("Listener: Google Identity Services Script loaded");
            });
            striptTag.addEventListener("error", (e) => {
                setGIdentityServicesScriptLoaded(false); 
                // if (DEBUG_MODE >= 1) console.log("Listener: Google Identity Services Script load error",e);
            });
            striptTag.async = true;
            document.body.appendChild(striptTag);
            // if (DEBUG_MODE >= 1) console.log("IS Tag created", striptTag);
           }
    

           // Load Google Identity Services from remote source        
           if (!gapiScriptLoaded) {
            //  if (DEBUG_MODE >= 1) console.log("Loading Google API Script");
    
             //Code to dynamically insert a script to load the GoogleAPI on page load
            const striptTag = document.createElement('script');
            striptTag.src = GOOGLE_APIS_URL;
            striptTag.async = true;
            document.body.appendChild(striptTag);
            striptTag.addEventListener('load',  () => setGapiScriptLoaded(true));
            striptTag.addEventListener("error", (e) => {setGapiScriptLoaded(false); if (DEBUG_MODE >= 1) console.log("Listener: Google API Script load error",e);});
            // if (DEBUG_MODE >= 1) console.log("API Script Tag created", striptTag);
           }
        // }

    },[]);

    //Invoked upon the Google API Script being installed (loaded) in the DOM from the remote source
    useEffect(() => {
        
       if (gapiScriptLoaded) {
        // if (DEBUG_MODE >= 1) console.log("Listener: Google API Script loaded");
         //Since the Google API has been loaded on our page, initialize a client using the function we provide
         window.gapi.load('client', intializeGapiClient);
       }

    },[gapiScriptLoaded]);

 
      //  Our function to initialize the Google client after the API script has been laoded.  This loads the discovery doc to initialize the api.
      // https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams
      
      async function intializeGapiClient() {
          
        try {
            await window.gapi.client.init({
            //   client_id: GOOGLE_CALENDAR_CLIENT_ID,
            //   scope: GOOGLE_SCOPES,
            discoveryDocs: [GOOGLE_DISCOVERY_DOC],

            // responseType:'code',
            // accessType:'offline',

            });
            
            // if (DEBUG_MODE >= 1) console.log("Google API Client initialized!");

            setGapiInitiated(true); //Update state
        } catch (error) {
            if (DEBUG_MODE >= 1) console.log("Error initializing Google APIs", error);
        }
      }
      
      
      

    //Invoked upon the Google Identity Services Script being installed (loaded) in the DOM from the remote source
    useEffect(() => {
        
       if (gIdentityServicesScriptLoaded) {
            initializeGIS();
       }
        
    },[gIdentityServicesScriptLoaded]);


    async function initializeGIS() {
        try {
 
             //Since the Google Identity Services has been loaded on our page, initialize a client 
             //Note, we are using the AUTHORIZATION CODE MODEL of the new Identity Services so we can do offline updates via our Lambda function!
             // https://developers.google.com/identity/oauth2/web/guides/migration-to-gis
 
            // AUTH CODE CLIENT TECHNIQUE
            
            var tempCodeClient = await window.google.accounts.oauth2.initCodeClient({
              client_id: GOOGLE_CALENDAR_CLIENT_ID,
              scope: GOOGLE_SCOPES,
              prompt:'consent',  
              ux_mode: 'popup',                     //This is the default
              callback: authorizationCodeCallback,  // our handler for receiving the authorization code, which we exchange for an access token and refresh token
            });
            
            setAuthCodeClientGoogle(tempCodeClient);      //Store to our Auth Code state var
            setGisInitiated(true);
            
            // if (DEBUG_MODE >= 1) console.log("Listener: Google Code Client Initialized", tempCodeClient);

            //TOKEN CLIENT TECHNIQUE
            
            // var tempTokenClient = await window.google.accounts.oauth2.initTokenClient({
            //   client_id: GOOGLE_CALENDAR_CLIENT_ID,
            //   scope: GOOGLE_SCOPES,
            //   prompt:'consent',                 
            //   //callback: '', // defined at request time in await/promise scope
            //   callback: accessTokenCallback,    //Set a callback to our handler for receiving the accessToken when requested
            // });
            
            // setTokenClient(tempTokenClient);
            // setGisInitiated(true);
            // if (DEBUG_MODE >= 1) console.log("Listener: Google Token Client Initialized", tempTokenClient);
   
             
           } catch (error) {
            if (DEBUG_MODE >= 1) console.log("Error initializing Google Identity Service");
        }
   }
   
    //
    //  useEffect to send newly received code to OUR LAMBDA; triggered when processing a new AUTH code
    //
     useEffect(() => {


        //Safety checks to confirm this is not a search param left over in the URL
        //Need to wait until the current user is fully signed in via Amplify
        if (authState !== "signedin" || !authorizationCode) return;
        if (DEBUG_MODE >= 1) console.log("Processing Auth Code ", authorizationCode, currentUser);

        //To avoid multiple calls, ignore the URL params if already connected to a calendar
        // if( currentUser.googleCalendarEnabled || currentUser.ms365CalendarEnabled) return;
        
       if (authorizationCode) {
        if (DEBUG_MODE >= 1) console.log("Sending Auth Code to our Lambda", authorizationCode);
        sendAuthCodeToLambda(authorizationCode, authorizationStateData);       
       }

    },[authorizationCode]);

    
    async function sendAuthCodeToLambda(authorizationCode, authorizationStateData) {
        
        let successFlag = false;

       try {
               
         setShowSpinner(true);
         
         const calendarEnableAccessInput = {
           customerID: currentUser.customerID,
           userID: currentUser.id,
           calendarType: calendarToAuthorize,
           authCode: authorizationCode,
           authState:authorizationStateData,
         };
         
         if (DEBUG_MODE >= 1) console.log("Invoking Calendar Lambda", calendarEnableAccessInput);
         
         //Call GraphQL to directly invoke our Lambda function 
         const lambdaResponse = await invokeAPI(calendarEnableAccess, 'calendarEnableAccess', calendarEnableAccessInput);
         if (DEBUG_MODE >= 1) console.log("Enable Calendar Lambda returned", lambdaResponse);
         
         if (lambdaResponse.data.calendarEnableAccess) {
            if (lambdaResponse.data.calendarEnableAccess === "SUCCESS") successFlag=true;
            if (DEBUG_MODE >= 1) console.log("ENABLE CALENDAR RETURNED " + successFlag);
         }

        } catch(err) {
            if (DEBUG_MODE >= 1) console.log("Enable Calendar Lambda Error", err);
        }
        
        setShowSpinner(false);

        let successMessage;
        if (successFlag) {
            successMessage = "Calendar sync enabled!! Your invitations for conectivities will now sync to your calendar and the invitees' calendars!  You can also edit invitations, including changing dates, times and invitees, directly from your calendar.";
            setModalGeneralMessage(successMessage);
            setModalGeneralTitle("Success!");
            setShowModalGeneral(true);   
        } else {
            successMessage = "Sorry, we could not enable your calendar sync";
            setModalGeneralMessage(successMessage);
            setModalGeneralTitle("Ooops!");
            setShowModalGeneral(true);   
        }
    }  
    

 
    //This function is called (by Google) in response to our CodeClient requesting an Auth Code, which we then send to our Lambda for processing in the backend to get access tokens
    //For MSFT this is handled by a redirect
    async function authorizationCodeCallback (response) {
        
        //Response format = {code: xxx, scopes: 'scope string'}
        
        if (DEBUG_MODE >= 1) console.log("Authorization Code Handler Invoked by Google API", response);
        
        if (response?.code) {
 
            //Save the code to our state data, which will trigger a useEffect to send to our Lambda
             setAuthorizationCode(response.code);
      
         }
    }
    
    async function getAuthCode(cloud) {

        if (!currentUser) {
            if (DEBUG_MODE >= 1) console.log("Error getting Oauth code.  No CurrentUser", cloud, currentUser);
            return;
        }
        
        try {
            
            switch (cloud) {
                case 'GOOGLE':
                    if (DEBUG_MODE >= 1) console.log("Request authorization code from GOOGLE");
                    setCalendarToAuthorize("GOOGLE");
                    if (authCodeClientGoogle) await authCodeClientGoogle.requestCode(); // Request authorization code and obtain user consent
                    else console.log("Error - No Google AuthCodeClient");
                    break;
                    
                case 'MS365':

                    // REACT MSAL Lib calls
                    if (DEBUG_MODE >= 1) console.log("Request authorization code from MSFT", MSFT_LOGIN_PARAMS);
                    // const response = await msalInstance.loginPopup(MSFT_LOGIN_PARAMS);
                    // const response = await msalInstance.loginRedirect(MSFT_LOGIN_PARAMS);
                    // if (DEBUG_MODE >= 1) console.log("MSFT Login Pop-up returned", response);
                        
                    // await authorizationCodeCallback(response); //Store the code

                    //NODE JS version
                    // get url to sign user in and consent to scopes needed for application
                    await msalInstance.getAuthCodeUrl(MSFT_AUTHCODE_URL_PARAMS).then((response) => {
                        if (DEBUG_MODE >= 1) console.log("MSFT Get Auth Code URL returned", response);
                        
                        try {
                            if (response) window.location.href = response; //Jump to MSFT consent page
                        } catch (err) {
                            console.log("Error thrown when jumping to consent page", response);
                        }
                        
                    }).catch((error) => {if (DEBUG_MODE >= 1) console.log("MSFT Get Auth Code URL error",JSON.stringify(error))});
                    
                    break;     
                    
                default:
                    if (DEBUG_MODE >= 1) console.log("Error getting AUTH CODE - invalid cloud param received");
            }    

        } catch (err) {
            if (DEBUG_MODE >= 1) console.log("Error requesting authorization code", err);
        }
    }

    // async function revokeAuthCode() {
    //     try {

    //         // Revoke authorization code
    //           await authCodeClientGoogle.revoke();
              
    //     } catch (err) {
    //         if (DEBUG_MODE >= 1) console.log("Error revoking authorization code", err);
    //     }
    // }
    
    async function disableCalendar(calendar) {

        let successFlag;
        
        try {
            const calendarDisableAccessInput = {
                customerID: currentUser.customerID,
                userID: currentUser.id,
                calendarType: calendar,     //Pass GOOGLE or MS365 etc.
            };
            
            if (DEBUG_MODE >= 1) console.log("Invoking Disable Calendar Lambda", calendarDisableAccessInput);

            //Call GraphQL to directly invoke our Lambda function 
            const calendarResponse = await invokeAPI(calendarDisableAccess, 'calendarDisableAccess', calendarDisableAccessInput);
            if (DEBUG_MODE >= 1) console.log("Calendar Disable Access returned", calendarResponse);
            successFlag = (calendarResponse.data.calendarDisableAccess === 'SUCCESS');
            
        } catch(err) {
            if (DEBUG_MODE >= 1) console.log("Calendar Disable Access Lambda Error", err);
        }
            
        if (successFlag) {
            const successMessage  = 'Calendar integration disabled. Your invitations for conectivities will no longer sync to your calendar.';
            setModalGeneralMessage(successMessage);
            setModalGeneralTitle("Success");
            setShowModalGeneral(true);   
        } else {
           setModalGeneralMessage("Sorry, something went wrong....");
            setModalGeneralTitle("Ooops!");
            setShowModalGeneral(true);   
        }            

    } 

    function setCalendarEventTitle(conectivity, isFull) {

        if (isFull) {        
            if (conectivity.category.label == "STRESS") return "BALANCE CONECTIVITY" + " - " + conectivity.title; 
            if (conectivity.category.label == "PERSONAL") return "GROWTH CONECTIVITY" + " - " + conectivity.title; 
            return conectivity.category.label + " CONECTIVITY - " + conectivity.title;
        }
 
        if (conectivity.category.label == "STRESS") return "BALANCE" + " - " + conectivity.title; 
        if (conectivity.category.label == "PERSONAL") return "GROWTH" + " - " + conectivity.title; 
        return conectivity.category.label + " - " + conectivity.title;

    }

    async function handleToggleCloudIntegration(calendar) {


        // setGoogleButtonImage(CONECTERE_CONFIG_DATA.GOOGLE_BUTTON_IMAGE_PRESSED);

        setShowSpinner(true);     
        
        switch (calendar) {
        
            case "GOOGLE":
                //Calendar sysc currently ON and turning OFF?
                if (currentUser.googleCalendarEnabled) {
                    
                    //Invoke our lambda
                    if (DEBUG_MODE >= 1) console.log("Disabling Google Calendar");
                    await disableCalendar("GOOGLE");
                    
                    //Update state to no access; even if token deletion in the cloud failed, we will have the user request new tokens if they ever re-activate
                    setCurrentUser({...currentUser, googleCalendarEnabled:false});
                    const tempUpdatedUser = await storeUpdatedUser({id:currentUser.id, googleCalendarEnabled:false});
                    if (DEBUG_MODE >= 1) console.log("Updated current user calendar enable to false", tempUpdatedUser);
                    
                } else {
                    if (DEBUG_MODE >= 1) console.log("Activating Google Calendar Sync");
        
                    //Invoke our get Auth Code routine, which will automatically trigger a state update and set the flags in the current user          
                    await getAuthCode("GOOGLE");
        
                }
                break;
 
            case "MS365":
                //Calendar sysc currently ON and turning OFF?
                if (currentUser.ms365CalendarEnabled) {
                    
                     //Invoke our lambda
                    if (DEBUG_MODE >= 1) console.log("Disabling MS365 Calendar");
                    await disableCalendar("MS365");
                    
                    //Update state to no access; even if token deletion in the cloud failed, we will have the user request new tokens if they ever re-activate
                    setCurrentUser({...currentUser, ms365CalendarEnabled:false});
                    const tempUpdatedUser = await storeUpdatedUser({id:currentUser.id, ms365CalendarEnabled:false});
                    if (DEBUG_MODE >= 1) console.log("Updated current user calendar enable to false", tempUpdatedUser);
                    
                    //Redirect the browser to the MSFT logout page
                    if (DEBUG_MODE >= 1) console.log("Redirecting browser to " + REDIRECT_URI_MSFT_TO_LOGOUT);
                    window.location.href = REDIRECT_URI_MSFT_TO_LOGOUT; //Jump to MSFT logout consent page

                    
                } else {
                    if (DEBUG_MODE >= 1) console.log("Activating Google Calendar Sync");
        
                    //Invoke our get Auth Code routine, which will automatically trigger a state update and set the flags in the current user          
                    await getAuthCode("MS365");
        
                }
                break;    
         
        }
        
        setShowSpinner(false);        

        
    }   

/*
   //Material UI CSS styles on this page
    const useStyles = makeStyles((theme) => ({

    switchMUI: {             //https://mui.com/material-ui/api/tab/
        // "& .MuiSwitch-root": {
        //   backgroundColor: "pink",
        // //   height: 4,
        // },
        "& .MuiSwitch-track": {
          backgroundColor: "grey",
        //   color:"yellow",
        //   height: 4,
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: "white",
          color:"yellow",
        //   height: 4,
        },     
        // "& .MuiSwitch-input": {
        //   backgroundColor: "orange",
        //   color:"yellow",
        // //   height: 4,
        // },            
        // "& .MuiSwitch-edgeStart": {
        //   backgroundColor: "yellow",
        //   color:"yellow",
        // //   height: 4,
        // },  
        // "& .MuiSwitch-checked": {
        //   backgroundColor: "purple",
        //   color:"yellow",
        // //   height: 4,
        // },  
    }      
      
    }));
    const classes = useStyles();
*/    
    
    function displayGoogleCalendarAccessControlButton() {
        
        if (!currentUser) return null;
       //dynamically adjust properties if other calendars are enabled
        // currently supporting google calendar and msft365 (for all msft)
        const disabled = currentUser.ms365CalendarEnabled || currentUser.msteamsCalendarEnabled || currentUser.msoutlookCalendarEnabled || currentUser.icloudCalendarEnabled;
         
        return(
 
                <div className={`calendarAccessControlCard ContainerNoHeightCenter ${(disabled) && "calendarAccessControlCardDisabled"}`} onClick={event => handleToggleCloudIntegration("GOOGLE")}> 
                    <div className="ContainerNoHeightCenter" style={{minHeight:"70px", width:"50px", backgroundColor:"white"}}>
                        <img  style={{height:"30px"}} src={CONECTERE_CONFIG_DATA.GOOGLE_LOGO}  alt='' />
                    </div>
                    <div style={{padding:"0.5vh 1vh", flex:1}}>
                         <div > {!currentUser.googleCalendarEnabled ? "Connect to Google Calendar" : "Sign out of Google Calendar" } </div>
                    </div>
                
                    {currentUser.googleCalendarEnabled ? 
                        <div className="ContainerNoHeightCenter" style={{backgroundColor:"white", borderRadius:"50%", position:"absolute", top:"0", right:"0", transform: "translate(50%,-65%)"}}>
                            <CheckCircleIcon style={{color:COLOR_GOOD, fontSize:"3.0vh"}}/> 
                        </div>                                
                    : null }
               </div>
                
        );
    }


    function displayMicrosoftCalendarAccessControlButton() {
        
        if (!currentUser) return null;
        
        //dynamically adjust properties if other calendars are enabled
        // currently supporting google calendar and msft365 (for all msft)
        const disabled = currentUser.googleCalendarEnabled || currentUser.msteamsCalendarEnabled || currentUser.msoutlookCalendarEnabled || currentUser.icloudCalendarEnabled;
  
        return(
                <div className={`calendarAccessControlCard ContainerNoHeightCenter ${(disabled) && "calendarAccessControlCardDisabled"}`} onClick={event => handleToggleCloudIntegration("MS365")}> 
                    <div className="ContainerNoHeightCenter"  style={{minHeight:"70px", width:"50px", backgroundColor:"white"}}>
                        <img  style={{height:"30px"}} src={CONECTERE_CONFIG_DATA.MSFT_LOGO}  alt=''/>
                    </div>
                    <div style={{padding:"0.5vh 1vh", flex:1}}>
                         <div > {!currentUser.ms365CalendarEnabled ? "Connect to Microsoft Calendar" : "Sign out of Microsoft Calendar" } </div>
                    </div>
                
                    {currentUser.ms365CalendarEnabled ? 
                        <div className="ContainerNoHeightCenter" style={{backgroundColor:"white", borderRadius:"50%", position:"absolute", top:"0", right:"0", transform: "translate(50%,-65%)"}}>
                            <CheckCircleIcon style={{color:COLOR_GOOD, fontSize:"3.0vh"}}/> 
                        </div>                                
                    : null }
               </div>
        );
    }
    
 
    

    //return the Calendars Context provider
      return (
    <div>
 
         {/*<MsalProvider instance={msalInstance}> {/* Wrap this context in the Microsoft Access Library provider React context */}
     
            <CalendarContext.Provider value={
            {   
                displayGoogleCalendarAccessControlButton, displayMicrosoftCalendarAccessControlButton,
                gapiInitiated, gisInitiated, handleToggleCloudIntegration,
     
            }}>
              {children}
            </CalendarContext.Provider>
    
 
                
            {/*</MsalProvider>*/}

    </div>
  ); 
    
};


export { CalendarContext, CalendarsProvider };



// Token-based routines, used when no server-side handler for access keys

/*
    function accessTokenCallback (response) {
        
        //Response format = {code: xxx, scopes: 'scope string'}
        
        if (DEBUG_MODE >= 1) console.log("Access Code Handler Invoked by Google API", response);
        if (response.code) {
                setAccessToken(response.code);
 
             // GIS has automatically updated gapi.client with the newly issued access token.
              if (DEBUG_MODE >= 1) console.log('GIS has automatically updated gapi.client with the newly issued access token.. gapi.client access token: ' + JSON.stringify(response));
             
        }
        
    }
    
    function getTokenByTokenClient() {
        
        if (DEBUG_MODE >= 1) console.log("Requesting Access Token");
    
        try {
            // tokenClient.callback = (resp) => {
    
            //       // GIS has automatically updated gapi.client with the newly issued access token.
            //       if (DEBUG_MODE >= 1) console.log('GIS has automatically updated gapi.client with the newly issued access token.. gapi.client access token: ' + JSON.stringify(window.gapi.client.getToken()));
                  
            //       //TBD THE RESPNSE (resp) AS STORE THE NEW ACCESS TOKEN?
            //     };
                
            tokenClient.requestAccessToken();
                
                
        } catch (err) {
            if (DEBUG_MODE >= 1) console.log("Error requesting access token", err);
            
        }
    }

      // CURRENTLY CONFIGURED FOR TOKEN
      
      // Sign in the user upon button click. 
      async function showEvents() {
         
         if(gapiInitiated && gisInitiated && tokenClient) { 

             try {
                 
                 //Define the callback
                tokenClient.callback = async (resp) => {
                  if (resp.error !== undefined) {
                    throw (resp);
                  }
                //   document.getElementById('signout_button').style.visibility = 'visible';
                //   document.getElementById('authorize_button').innerText = 'Refresh';
                //   await listUpcomingEvents();
                };
        
                // Is there a current token?
                if (window.gapi.client.getToken() === null) {
                  // Prompt the user to select a Google Account and ask for consent to share their data
                  // when establishing a new session.
                  if (DEBUG_MODE >= 1) console.log("No access token for this session.  Prompting user");
                  await tokenClient.requestAccessToken({prompt: 'consent'});
                }
                
              //Call the Calendar API
              const request = {
                'calendarId': 'primary',
                'timeMin': (new Date()).toISOString(),
                'showDeleted': false,
                'singleEvents': true,
                'maxResults': 10,
                'orderBy': 'startTime',
              };
              const response = await window.gapi.client.calendar.events.list(request);
            //   const formattedResponse = JSON.stringify(response);
               
              if (DEBUG_MODE >= 1) console.log("Calendar API returned.  Results:", response);

                if (response?.result) {

                    const events = response.result.items;
                    if (DEBUG_MODE >= 1) console.log("Calendar API Events:", events.length, events);

                    // Flatten to string to display
                    // const output = events.reduce(
                    //     (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
                    //     'Events:\n');
                    // if (DEBUG_MODE >= 1) console.log("events", output);
                    
                    setCalendarResults(events);
                }
         
 
             } catch (err) {
                 if (DEBUG_MODE >= 1) console.log("Error fecthing events", err);

                // Check whether the access token is missing, invalid, or expired, prompt for user consent to obtain one.
                if (err.result.error.code == 401 || (err.result.error.code == 403) && (err.result.error.status == "PERMISSION_DENIED")) {
                    await getTokenByTokenClient();
                }
                
             }
         }
      }

      //
     //  Sign out the user upon button click.
      //
     function revokeToken() {
      let cred = window.gapi.client.getToken();
      if (cred !== null) {
        window.google.accounts.oauth2.revoke(cred.access_token, () => {if (DEBUG_MODE >= 1) console.log('Revoked: ' + cred.access_token)});
        window.gapi.client.setToken('');
      }
    }

      //
      // Print the summary and start datetime/date of the next ten events in
      // the authorized user's calendar. If no events are found an
      // appropriate message is printed.
      //
      async function listUpcomingEvents() {
        let response;
        try {
          const request = {
            'calendarId': 'primary',
            'timeMin': (new Date()).toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 10,
            'orderBy': 'startTime',
          };
        //   response = await window.gapi.client.calendar.events.list(request);
          response = await window.google.client.calendar.events.list(request);
          
        } catch (err) {
          if (DEBUG_MODE >= 1) console.log(err.message);
          return;
        }

        const events = response.result.items;
        if (DEBUG_MODE >= 1) console.log("events", events);
        
        // if (!events || events.length == 0) {
        //   document.getElementById('content').innerText = 'No events found.';
        //   return;
        // }
        // Flatten to string to display
        const output = events.reduce(
            (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
            'Events:\n');
        document.getElementById('content').innerText = output;
        if (DEBUG_MODE >= 1) console.log("events", output);
      }


                            <div className="ContainerVerticalCenter" style={{position:"relative", paddingTop:"40px",  minWidth:"500px"}}>
                                <div className="ContainerNoHeightCenter" style = {{fontSize:"1.0rem", color:"black", padding:"10px"}}>
                                     <button disabled={!gapiInitiated || !gisInitiated} onClick = {showEvents}>
                                        Show Google Events 🚀
                                    </button>
                                     <button style={{marginLeft:"10px"}} disabled={!gapiInitiated || !gisInitiated} onClick = {revokeToken}>
                                        Revoke Google Access 
                                    </button>
                                    <button style={{marginLeft:"10px"}} disabled={!gapiInitiated || !gisInitiated} onClick={getTokenByTokenClient}>
                                        Request Access Token Using Token Client
                                    </button>
                                    </div>

                                <div className="ContainerNoHeightCenter" style = {{fontSize:"1.0rem", color:"black", padding:"10px"}}>
   
                                    <button style={{marginLeft:"10px"}} disabled={!gapiInitiated || !gisInitiated} onClick={getAuthCode}>
                                        Request Auth Code
                                    </button>
                                    <button style={{marginLeft:"10px"}} disabled={!gapiInitiated || !gisInitiated || !authorizationCode} onClick={createEvent}>
                                        Create Event
                                    </button>
                                </div>
                                    
                                {calendarResults && 
                                    <div>
                                        {calendarResults.map((event, index) => ( 
                                            <div className="ContainerNoHeightCenter" style = {{fontSize:"1.0rem", color:"black"}}>
                                                <div> 
                                                    {event.start.dateTime || event.start.date}
                                                </div>
                                               <div style={{paddingLeft:"clamp(4px,1vw,10px)"}}> 
                                                    {event.summary}
                                                </div>                                        
                                            </div>
                                          ))}   
                                      </div>
                                }
                            </div>
            */
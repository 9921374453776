//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import './modalNoBackground.css';
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, BALANCE_COLOR,  } from '../../data/conectereConfigData'; 

//React & Amplify components
import React, { useEffect, useState, useContext, useRef  } from 'react';
		
//Context
import { DisplayContext } from '../../context/displayContext';            

//Utils
import { setBackgroundColorClassByColorValue } from "../../utils/conectivityUtils";

//Icons
import CloseIcon from '@mui/icons-material/Close';

//React component for uploading an image from the user

const ModalNoBackground = React.memo(({children, showModal, smallOffset, closeCallback, cardColor, cardHeight, opaqueBackground, closeButtonColor, closeButtonBackground, hideShadow, hideInnerBorder, closeButtonClassName, innerCloseIcon, cardClassName}) => {
	
	// Context
	const { darkMode } = useContext(DisplayContext); 

	let backgroundColorClass = (cardColor ? setBackgroundColorClassByColorValue(cardColor) : 'purple-background');

	const POSITION_OFFSET = (smallOffset ? 90 : 125);
	//Render nothing if not to be show
	
	const [topPostion, setTopPostion] = useState(POSITION_OFFSET);    
	if (!showModal) showModal = false;
	if (!closeButtonColor) closeButtonColor = (cardColor ? 'white' : 'black');
	if (!hideShadow) hideShadow = false;
	if (!closeButtonBackground) closeButtonBackground = (cardColor ? cardColor : "white");
	// if (closeButtonColor==null) closeButtonColor = BALANCE_COLOR;

	
	let styleObj = {
		top:topPostion,
		padding:(hideInnerBorder ? '0px' : 'clamp(4px,0.5vw,8px'),    
	}
	if (cardHeight) styleObj.height = cardHeight;

	//Upon load, determine if the screen already has a Y position, i.e, has been scrolled down
	//If so, use that for TOP in our position ABSOLUTE so the modal pops up centered on the current screen rather than back up on the top
	//Note, we have to use position ABSOLUTE so that screen scroll with the page if the user scrolls down to read the rest of the modal
	//Also, we need to read the full height of the entire document and dynamically set the height of our background layer so that it covers the entire document
	//as the user scrolls up/down
	
	useEffect (() => {
		if (showModal) setTopPostion(calcScreenPosition());
	},[showModal]);
	
	function calcScreenPosition() {
		const tempScreenPositionY  = window.scrollY;
		const topPostion = Math.floor(tempScreenPositionY + POSITION_OFFSET).toString() + 'px';
		return topPostion;
	}

	if (!showModal) return null;    
	return (
		<div hidden={!showModal} className="ModalNoBackgroundContainer ModalScroll" style={{ display: showModal ? 'flex' : 'none' }} onClick={closeCallback}>
			<div className = {`ModalNoBackgroundCard borderRadius-V2  ${!hideShadow ? "boxShadow" : "" } ${opaqueBackground ? "opaque-background" : backgroundColorClass} isCard ${cardClassName}`} style={styleObj} onClick={(e)=>e.stopPropagation()} >
				<div className={`ModalNoBackgroundCloseButton ${innerCloseIcon ? 'modalInnerCloseButton' : ''}`} onClick={closeCallback}>
					<CloseIcon className={`TextStyle3V2 ${backgroundColorClass} ${closeButtonClassName} pointer isCard `} onClick={closeCallback} style={{color:(closeButtonColor ? closeButtonColor : darkMode ? closeButtonColor : "white")}} />
				</div>                    
				{children}                      
			</div>
		</div>  
	);

});

export default ModalNoBackground;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved

/* 
	AWS UTILS
   - This set of utilities abstracts AWS calls so that we have a single, centralized file to change when we upgrade AWS or the Amplify SDK
*/

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';

//Amplify, React
import { Amplify  } from 'aws-amplify';

//Amplify V6
import { signUp, signIn, fetchAuthSession, getCurrentUser, signOut, resetPassword } from 'aws-amplify/auth';

export const signUpUser = async ({ username, password,  attributes }) => {
  try {
    const { isSignUpComplete, userId, nextStep } = await signUp({
      username,
      password,
      options: {
        userAttributes: attributes,
        // optional
        // autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
      }
    });

    if (DEBUG_MODE) console.log("Cognito user record successfully created.");
    if (DEBUG_MODE) console.log("UserID:",userId);
    if (DEBUG_MODE) console.log("SigUp Complete Flag:", isSignUpComplete);
    if (DEBUG_MODE) console.log("Next step:", nextStep);
    return userId;
  } catch (error) {
    if (DEBUG_MODE) console.error('error signing up user:', error);
    return null;
  }
}

export async function signInUser({ username, password }) {
  try {
    const { isSignedIn, nextStep } = await signIn({ username, password });
    if (DEBUG_MODE) console.log("Cognito user sign in result:", isSignedIn);
    if (DEBUG_MODE) console.log("Next step:", nextStep);
  } catch (error) {
    console.log('error signing in', error);
  }
}

export async function resetCognitoPassword ({ username } ) {
      if (DEBUG_MODE) console.log("Initiating Cognito Password reset for user", username);
      const result = await resetPassword({ username } );
      return result;
}

export async function signOutUser () {
   await signOut();
}

/*
  Function for invokeing AWS fetchAuthSession API which returns a tokens object containing the JWTs (JSON Web Tokens)
*/

export async function getCognitoSession() {
   let session;
   try {
      session = await fetchAuthSession();
      console.log('Cognito session detected.  Session object:', session);
   }
   catch (err) {
      if (DEBUG_MODE) console.log("No existing Cognito session.", err);
   }
   return session;
}

export async function getCognitoAuthenticatedUser() {
   let currentAuthenticatedUser;
   try {
      currentAuthenticatedUser = await getCurrentUser();
      if (DEBUG_MODE) console.log('Cognito authenticated user detected.  User object:', currentAuthenticatedUser);
   }
   catch (err) {
      if (DEBUG_MODE) console.log("No existing Cognito currentAuthenticatedUser.", err);
   }
   return currentAuthenticatedUser;
}

export function getCognitoUserIdentifier(session) {
  // Extract the user identifier from the 'userSub' field directly
  return session?.userSub || null;
}

//Function for extracting the user groups from the Cognito session object returned via fetchAuthSession
export function getCognitoUserGroups(session) {
  const accessTokenGroups = session?.tokens?.accessToken?.payload?.['cognito:groups'];
  const idTokenGroups = session?.tokens?.idToken?.payload?.['cognito:groups'];

  // Combine the groups from both tokens and remove duplicates if necessary
  const allGroups = [...new Set([...(accessTokenGroups || []), ...(idTokenGroups || [])])];
  if (DEBUG_MODE) console.log('Extracted Cognito user groups:', allGroups);

  return allGroups;
}


export function decodeJWT(token) {
   // JWTs are in the format: header.payload.signature
   const parts = token.split('.');
 
   if (parts.length !== 3) {
     throw new Error('Invalid JWT format');
   }
 
   // Decode base64Url encoded payload
   const base64Url = parts[1];
   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   
   try {
     const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
     }).join(''));
 
     return JSON.parse(jsonPayload);  // Return the decoded payload as a JS object
   } catch (e) {
     throw new Error('Error decoding JWT: ' + e.message);
   }
 }
 
 /* N0TE - VERIFY THIS - DOES THE AUTH PORTION ACTUALLY EXIST IN THE ORIGINAL JSON? MAYBE AFTER A GETCONFIG CALL? */
 export function updateSignInRedirectURI(newRedirectURI) {
   // Step 1: Get the current configuration
   const currentConfig = Amplify.getConfig();
 
   // Step 2: Update only the redirectSignIn field in Auth.oauth, preserving everything else
   const updatedConfig = {
     ...currentConfig, // Preserve other Amplify services (API, Storage, etc.)
     Auth: {
       ...currentConfig.Auth, // Preserve existing Auth settings
       oauth: {
         ...currentConfig.Auth.oauth, // Preserve existing OAuth settings
         redirectSignIn: newRedirectURI, // Update only the SignInRedirectURI
       },
     },
   };
 
   // Step 3: Reapply the updated configuration to Amplify
   Amplify.configure(updatedConfig);
 }


/*
  Example Session Object
{
    "tokens": {
        "accessToken": {
            "payload": {
                "sub": "a6d247bd-961b-42eb-8859-e52e5e3891ae",
                "cognito:groups": [
                    "admin",
                    "superAdmin"
                ],
                "iss": "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_iVY0ObuEO",
                "client_id": "6jkdg4ajki6l1q394vnb0729s2",
                "origin_jti": "086bcc82-7cd5-4c88-ab0e-01d35c421ff3",
                "event_id": "f9e2e6ff-d8d8-4647-a2dd-d8ee62772ac0",
                "token_use": "access",
                "scope": "aws.cognito.signin.user.admin",
                "auth_time": 1729564868,
                "exp": 1729568468,
                "iat": 1729564868,
                "jti": "9109bc8c-1dd0-4e89-bcad-50db67cde132",
                "username": "kentjsiesl8"
            }
        },
        "idToken": {
            "payload": {
                "sub": "a6d247bd-961b-42eb-8859-e52e5e3891ae",
                "cognito:groups": [
                    "admin",
                    "superAdmin"
                ],
                "email_verified": true,
                "cognito:preferred_role": "arn:aws:iam::950331828468:role/us-east-2_iVY0ObuEO-superAdminGroupRole",
                "iss": "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_iVY0ObuEO",
                "cognito:username": "kentjsiesl8",
                "custom:userID": "-1",
                "origin_jti": "086bcc82-7cd5-4c88-ab0e-01d35c421ff3",
                "cognito:roles": [
                    "arn:aws:iam::950331828468:role/us-east-2_iVY0ObuEO-superAdminGroupRole",
                    "arn:aws:iam::950331828468:role/us-east-2_iVY0ObuEO-adminGroupRole"
                ],
                "aud": "6jkdg4ajki6l1q394vnb0729s2",
                "event_id": "f9e2e6ff-d8d8-4647-a2dd-d8ee62772ac0",
                "custom:tempPassword": "p8ry78oa",
                "token_use": "id",
                "auth_time": 1729564868,
                "exp": 1729568468,
                "iat": 1729564868,
                "jti": "62c5a0d4-f1b1-4235-8044-e45b473e64f2",
                "email": "conecterenet@gmail.com"
            }
        },
        "signInDetails": {
            "loginId": "kentjsiesl8",
            "authFlowType": "USER_SRP_AUTH"
        }
    },
    "credentials": {
        "accessKeyId": "ASIA52RBQ5D2G33WL7VA",
        "secretAccessKey": "PQQgqaaOQSlqEP7Kq3QsMmk6mMAZW0MuREHPNLQK",
        "sessionToken": "IQoJb3JpZ2luX2VjEDMaCXVzLWVhc3QtMiJHMEUCIQDod7Rya4t9F+/4U3ZO3OxhDQ5pUkZrLchd/5H6eSUMEwIgRV8VMuE2M4FMnk034empCuI//nVPJXagpkYjyj9ixc4qzQQInP//////////ARABGgw5NTAzMzE4Mjg0NjgiDAxLCHtL39ecEgE3TyqhBKvYnwlc3jw97QxESOdSsW6yWsDCcc9iZaka3p6oUmouWw30XM4P5zIa70EUlaDRBygfLuDK5Gr8flUI5SFtHuMux2QM3qhVeHYjIZIpEXykRx9pHoAF9KkIW6Gupf6uLxFyliZ/v8MVUwIDdPRha7kK6soJnybvPg87iCLqjNqfcKK9y6U0Da3MMck1GiWNrg2nHcBDAuI/1AQV0o7ZZOG1K+K8plxzW7lKV7iRhYXnanJpnJZz+JsvuIzcz7NbI7otTICDFysPbjQKCcuGP0PH8feNUz0j6lsPT/jbvfT7NaTTQREePfNzLjwAO4QXe+LZ4aLMUcKUN6G9IDCdDlRcLXAeOZvblQt2wACSmk+iSicr5qWQigjVjGxZ81Pggd/5chfsG9vcIenRT8LXfWnkK9fdtz8vm0OHHW4MHNQqj1uruGZ+Oje2+DKjfb/gGIJrFcEoWdf2wLiGy2muWKDuVAhe2vjxs1+tpMUpjzua8M3McYxUv2PauATDk9SJyJMaewVyM2CKUHX/amtBA6LZ3N4r52d7FWzQupJ+Zt8Ok5ksO/W4xu5MlKMsym3Wv4G4vkHJ9LdHyHP3VvlQZalVPy3lU81sLA+EBJ6NM0loGx1rdnwdWXJG0cIwAwEZIkRzSDUrSeK1Ya2MyDCFKQaEFTtRnB8SSO9mN9ztK/U4/NhoNYD63uwCqfy1LB72OK+c6jdFXHaO374dSYf5MaMbMMWh3LgGOoUCrGG2tN5FoyQsgrhibIQq32SYrgSnT0MUHSzm/6M081Aiu55qokLIo8azz299hbUelxfK50afnXZvKEJSlxw0b18cOVCrO2M2YUx5ZO/df/DnlW1cVx8e3LwkRNxmp67aRgs5Y4gNO2lq96f+A25nU+r4NIphpdSsqSMpPtFdAebSTaxirVL6M9yjLMWhv7aaC7OKiTm4IkEJKE4O16NhhsUCvyDpxGpkDFa2STAQ1fQTkbPgdwK1vN8AwhqwVaZ96OI/dItD9SbZefXg/fxry1Yg/py7cm9fBfZCTf28uQRNePjy/dYZouZ2Gf/9qKXHqyogoHYDcMo17h12A1AqgZh6qhjJ",
        "expiration": "2024-10-22T03:41:09.000Z"
    },
    "identityId": "us-east-2:53b9aa15-9f4e-40a9-971a-bbfeb4d04be3",
    "userSub": "a6d247bd-961b-42eb-8859-e52e5e3891ae"
}  
*/
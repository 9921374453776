//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright CONECTERE
//


//DATA
import  {   CONECTERE_CONFIG_DATA, PERIOD_OPTIONS, DEBUG_MODE, COLOR_BLUE_HEADER, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, 
	IMAGE_COMMUNITY_1, configDetailsInitialState, customerInitialState, customerBillingInitialState, SPOTLIGHT_AMOUNT_SENDER, SPOTLIGHT_AMOUNT_RECEIVER,
	NEW_EMPLOYEE_SUPPORT_PROGRAM, SERVICE_PLAN_OPTIONS, COLOR_GOOD, SUBSCRIPTION_LEVEL_1_COST_STR, SUBSCRIPTION_LEVEL_2_COST_STR, SUBSCRIPTION_LEVEL_1, SUBSCRIPTION_LEVEL_2, CATEGORY_COLORS,
} from '../../data/conectereConfigData';
import { HELP_TEXT_SPOTLIGHT_SEND_CAP, HELP_TEXT_ADMIN_SPOTLIGHTS_1, HELP_TEXT_SERVICE_PLANS, HELP_TEXT_ADMIN_SPOTLIGHTS_2, HELP_TEXT_SET_COIN_VALUE, HELP_TEXT_RING_CLOSURE_OVERRIDE, HELP_TEXT_RING_CLOSURE_FIRM_CAP, HELP_TEXT_SPOTLIGHTS_AFTER_RING_CLOSURE} from '../../data/helpData.js';

//REACT
import React, { useEffect, useState, useContext, useRef, useCallback, useMemo  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { ConectivityContext } from '../../context/conectivitiesContext';
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context

//Queries and Mutations
import { getCustomerBilling } from '../../graphql/queries';


//Utils
import { TableText, OpenButton, TabPanel, validImageFileType, Divider, HelpButton, EditButton, DeleteButton} from "../../utils/generalUtils";
import {  renderOurRechartLegend, renderRechartTooltip } from "../../utils/analyticsUtils";
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3
		} from "../../utils/dateTimeUtils";
import { DisplayConectivitySelectComponent } from "../../utils/conectivityUtils";
import {getDataRecordById} from "../../utils/databaseUtils";
import { deleteImageFile, storeImageFile }  from "../../utils/storageUtils";
import { DashboardCircle, DashboardRectangle } from "../../utils/dashboardUtils";
import { invokeCreateCustomer, invokeUpdateCustomer,  } from "../../utils/customerUtils";
// import { inMsftTeams } from "../../utils/msftTeamsUtils";

//Our components

import CCPieChart from "../pieChart/pieChart";
import { FileUploader } from  "../fileUpload/fileUpload";
import ConectivityDetailedView from "../conectivityDetailedView/conectivityDetailedView";
import ModalGeneral from "../modalGeneral/modalGeneral";

//Tabs 
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import HighlightIcon from '@mui/icons-material/Highlight';

//Other components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';   //Version with support from multiple selections
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';    

//MUI & Icons
import {MailOutline, Add, Edit, DeleteOutline, PermIdentity, PhoneAndroid, Group} from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';
import Slider from '@mui/material/Slider';          //Note documentation: https://mui.com/api/slider/ 
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

//Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Our components
import { CreditCard } from "../creditCard/creditCard";

const CustomerEditor = React.memo(({ showEditor, customer, isAdd, isEdit, initialTab, closeCallback, startInEditMode}) => {

	// Context
	const { authState, currentUser, setCurrentUser, isSuperAdmin, isAdmin, permissionBilling,  } = useContext(AuthContext);    // Authentication context
	const { users, teams, usersOptions, teamsOptions, customerOptions, selectedCustomerOptions,setSelectedCustomerOptions, customers, setCustomers } = useContext(CustomerContext);  					// Customer context
	const { conectivitiesReadyForLaunch, launchRules, conectivitiesReadyForLaunchOptions } = useContext(ConectivityContext);
	const {  setShowSpinner, isRenderedWithinMsftClient } = useContext(DisplayContext); 

	//Local State Data
	const [customerToEdit, setCustomerToEdit] = useState(customerInitialState);         //Used to hold a customer that is to be edited
	const [customerToEditOriginalLogoURL, setCustomerToEditOriginalLogoURL] = useState(null);  //holds an internal URL generated for  the image file just uploaded into the browser by the user
	const [inEditMode, setInEditMode] = useState(false);

	const [configDetails, setConfigDetails] = useState(configDetailsInitialState);
	const [configDetailsToEdit, setConfigDetailsToEdit] = useState(configDetailsInitialState);
	const [configDetailsToEditCache, setConfigDetailsToEditCache] = useState(configDetailsInitialState);
	const [configDetailsToDelete, setConfigDetailsToDelete] = useState(configDetailsInitialState);
	const [customerBilling, setCustomerBilling] = useState(customerBillingInitialState);
	const [customerBillingCache, setCustomerBillingCache] = useState(customerBillingInitialState);
	const [coinValueInCents, setCoinValueInCents] = useState(Math.round(100/CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION)); //compute coin value in CENTS

	//Service Plan

	// New Employee Scheduling Options
	const [selectedConectivityNewEmpOnboarding,setSelectedConectivityNewEmpOnboarding] = useState(null);
	const [selectedConectivityNewEmpWelcome, setSelectedConectivityNewEmpWelcome] = useState(null);
	const [selectedConectivityNewEmpOneOnOneSupervisor, setSelectedConectivityNewEmpOneOnOneSupervisor] = useState(null);
	const [selectedConectivityNewEmpOneOnOneMentor, setSelectedConectivityNewEmpOneOnOneMentor] = useState(null);
	const [selectedConectivityNewEmpOneOnOneSponsor, setSelectedConectivityNewEmpOneOnOneSponsor] = useState(null);
	const [selectedConectivityNewEmpLunch, setSelectedConectivityNewEmpLunch] = useState(null);
	const [newEmpOnboardingChecked, setNewEmpOnboardingChecked] = useState(true);
	const [newEmpWelcomeChecked, setNewEmpWelcomeChecked] = useState(true);
	const [newEmpOneOnOneSupervisorChecked, setNewEmpOneOnOneSupervisorChecked] = useState(true);
	const [newEmpOneOnOneMentorChecked, setNewEmpOneOnOneMentorChecked] = useState(true);
	const [newEmpOneOnOneSponsorChecked, setNewEmpOneOnOneSponsorChecked] = useState(true);
	const [newEmpLunchChecked, setNewEmpLunchChecked] = useState(true);
	const [conectivityNewEmpOnboarding,setConectivityNewEmpOnboarding] = useState(null);
	const [conectivityNewEmpWelcome,setConectivityNewEmpWelcome] = useState(null);
	const [conectivityNewEmpOneOnOneSupervisor,setConectivityNewEmpOneOnOneSupervisor] = useState(null);
	const [conectivityNewEmpOneOnOneMentor,setConectivityNewEmpOneOnOneMentor] = useState(null);
	const [conectivityNewEmpOneOnOneSponsor,setConectivityNewEmpOneOnOneSponsor] = useState(null);
	const [conectivityNewEmpLunch,setConectivityNewEmpLunch] = useState(null);
	
	//Mentor Default Scheduling Options
	const [mentorLaunchEventStartDateTime, setMentorLaunchEventStartDateTime] = useState(TODAY_NOON());                       //Pre-load TIME to noon
	const [mentorLaunchRepeatOption, setMentorLaunchRepeatOption] = useState(CALENDAR_REPEAT_OPTIONS[3]);                     //Start at MONTH
	const [mentorLaunchRepeatWeeklyOptions, setMentorLaunchRepeatWeeklyOptions] = useState([WEEK_REPEAT_OPTIONS[3]]);         //Pre-load Wednesday
	const [mentorLaunchRepeatMonthlyOptions, setMentorLaunchRepeatMonthlyOptions] = useState(MONTH_REPEAT_OPTIONS[0]);        //Default to the 1st of the month
	const [mentorLaunchRepeatMonthlyOptions_2, setMentorLaunchRepeatMonthlyOptions_2] = useState(MONTH_REPEAT_OPTIONS_2[0]);  //Default to 1st WED
	const [mentorLaunchRepeatMonthlyOptions_3, setMentorLaunchRepeatMonthlyOptions_3] = useState(MONTH_REPEAT_OPTIONS_3[3]);  //Default to WEDNESDAY
	const [mentorLaunchMonthOption1Checked, setMentorLaunchMonthOption1Checked] = useState(true);
	const [mentorLaunchMonthOption2Checked, setMentorLaunchMonthOption2Checked] = useState(false);


	//Sponsor Default Scheduling Options
	const [sponsorLaunchEventStartDateTime, setSponsorLaunchEventStartDateTime] = useState(TODAY_NOON());                       //Pre-load TIME to noon
	const [sponsorLaunchRepeatOption, setSponsorLaunchRepeatOption] = useState(CALENDAR_REPEAT_OPTIONS[3]);                     //Start at MONTH
	const [sponsorLaunchRepeatWeeklyOptions, setSponsorLaunchRepeatWeeklyOptions] = useState([WEEK_REPEAT_OPTIONS[3]]);         //Pre-load Wednesday
	const [sponsorLaunchRepeatMonthlyOptions, setSponsorLaunchRepeatMonthlyOptions] = useState(MONTH_REPEAT_OPTIONS[0]);        //Default to the 1st of the month
	const [sponsorLaunchRepeatMonthlyOptions_2, setSponsorLaunchRepeatMonthlyOptions_2] = useState(MONTH_REPEAT_OPTIONS_2[0]);  //Default to 1st WED
	const [sponsorLaunchRepeatMonthlyOptions_3, setSponsorLaunchRepeatMonthlyOptions_3] = useState(MONTH_REPEAT_OPTIONS_3[3]);  //Default to WEDNESDAY
	const [sponsorLaunchMonthOption1Checked, setSponsorLaunchMonthOption1Checked] = useState(true);
	const [sponsorLaunchMonthOption2Checked, setSponsorLaunchMonthOption2Checked] = useState(false);
 
	const [selectedPlan, setSelectedPlan] = useState(SUBSCRIPTION_LEVEL_2);
   const [subscriptionEndDate, setSubscriptionEndDate] = useState(moment().startOf("day"));
	const [subscriptionStartDate, setSubscriptionStartDate] = useState(moment().add(12,"months").startOf("day"));

	
	// Support for Tabs     
	const [tabIdentifier, setTabIdentifier] = useState('conectivity_goals');
	const handleTabChange = (event, newValue) => {
		setTabIdentifier(newValue);
	};  


	//Image vars
	const [imageFileUploadedEdit, setImageFileUploadedEdit] = useState(null); //holds the image uploaded during edit
	const [imageUploadedEdit, setImageUploadedEdit] = useState(null);  //holds an internal URL generated for  the image file just uploaded into the browser by the user
	const [newLogoUploaded, setNewLogoUploaded] = useState(false);      //state data that shows a change to the logo has been made during edit, including just removing the original

	//Conectivity Previewing
	const [showModalConectivityDetailed, setShowModalConectivityDetailed] = useState(false);      //Detailed Conectivity View
	const [conectivityToView, setConectivityToView] = useState(null);              //Used to hold a conectivity that is to be launched

	 //Modals
	 const [showModalMessage, setShowModalMessage] = useState(false);
	 const [modalMessage, setModalMessage] = useState("");
	 const [modalMessageTitle, setModalMessageTitle] = useState("");

   //Data Loader
	useEffect (() => {
		//Safety check
		if (authState !== "signedin" || !currentUser || (!isSuperAdmin && !isAdmin)) return;

		//Showing editor?
		if (!showEditor) return;

		//Safety check;
		if (!isAdd && !isEdit) {
			if (DEBUG_MODE) console.error("Error - improper params", isAdd, isEdit);
		}

		//Init local state params 
		if (isEdit) initializeEdit(); 
		else if (isAdd) initializeAdd();
	},[authState, showEditor]);
	 
async function initializeAdd() {
	if (DEBUG_MODE >= 2) console.log("Customer Editor - Initialized ADD NEW customer", authState, customer);
	setInEditMode(true);                        //Put our form in Edit mode
	setTabIdentifier('conectivity_goals');

	//Set defaul Conectivity investment details 
	var tempConfigDetails = {...configDetailsInitialState};
	setConfigDetailsToEdit(tempConfigDetails);          //Save state for editing
	setConfigDetailsToEditCache(tempConfigDetails);     //Save a copy to see if the Ring parameters change
	calculateCustomerSpend(tempConfigDetails);

	setCustomerToEdit(customerInitialState);
	setCustomerBilling(customerBillingInitialState);
	setImageUploadedEdit(null);                   //reset URL in case just used
	setShowSpinner(false); //Hide spinners
}

async function initializeEdit() {

	if (DEBUG_MODE >= 2) console.log("Customer Editor - Initialized EDIT customer", authState, customer);
	
	try {
		setCustomerToEdit({...customer});
		
		const tempConfigDetails = {...customer.configDetails};
		setCoinValueInCents(Math.round(100/(tempConfigDetails.dollarToCoinConversion ? tempConfigDetails.dollarToCoinConversion : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION))); // Convert to coin value in cents
		
		//Set Spotlight settings if they do not already exist
		if (tempConfigDetails.spotlightsEnabled === null) tempConfigDetails.spotlightsEnabled = true;
		if (tempConfigDetails.spotlightsAdminSendOnly === null) tempConfigDetails.spotlightsAdminSendOnly = false;
		if ( tempConfigDetails.spotlightAmountSender === null) tempConfigDetails.spotlightAmountSender = SPOTLIGHT_AMOUNT_SENDER;
		if (tempConfigDetails.spotlightAmountReceiver === null) tempConfigDetails.spotlightAmountReceiver = SPOTLIGHT_AMOUNT_RECEIVER;

		
		//Load New Employee Support options based on customer data
		initializeNewEmployeeSupportSettings(tempConfigDetails);
		
		//Store the config details
		setConfigDetailsToEdit(tempConfigDetails);
		setConfigDetailsToEditCache(tempConfigDetails);     //Save a copy to see if the Ring parameters change
		setImageUploadedEdit(customer.logo); //Grab the S3 URL if present
		setNewLogoUploaded(false); //Original logo for now
		calculateCustomerSpend(customer.configDetails);   //Calc pie charts based on retrieved customer details

		//Get/Set Credit Card selections; Set Year Dropdown Option
		let tempBillingInformation;
		if (customer.customerBillingID) tempBillingInformation =  await getDataRecordById(getCustomerBilling, "getCustomerBilling", customer.customerBillingID);
		if (DEBUG_MODE >= 2) console.log("Fetched billing record", tempBillingInformation);
		if (!tempBillingInformation || !tempBillingInformation.id) {
			if (DEBUG_MODE >= 2) console.log("Error fetching billing information", tempBillingInformation);
		} else {
			setCustomerBilling(tempBillingInformation);
			setCustomerBillingCache(tempBillingInformation);
		}

		if (DEBUG_MODE >= 2) console.log("Editing config details", customer, tempConfigDetails);

		//Are we supposed to open to a specific tab?
		if (initialTab) {
			if (DEBUG_MODE) console.log("Customer Editor reloading", initialTab);
			setTabIdentifier(initialTab);
		}
	
		//Start in edit mode?  Only if instructed via props
		if (startInEditMode) setInEditMode(true);
		
	} catch (err) {
		if (DEBUG_MODE >= 2) console.log("Error fetching page data", err);
	}
}


const handleImageUploadEdit = async (e) => {
	e.preventDefault();
	const file = e.target.files[0];  //grab the first file in the list of files selected by the user
	
	if (validImageFileType(file)) {
		try {
			const localURL = URL.createObjectURL(file);					
			console.log ("URL to local file in web browser:", localURL);					
			setImageUploadedEdit(localURL);  //update 'image' so as to display a preview to the user
			setImageFileUploadedEdit(file);  //save file for uploading to S3 bucket if the user hits the ADD button
			setNewLogoUploaded(true);        //set flag that state has changed
			console.log ("Successfully generated internal URL and stored New Logo FILE locally.");		
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error locally processing selected image", err);
		}
	}
	else {
			if (DEBUG_MODE >= 2) console.log("Error, wrong file type");
	}
};

	//define function to handle submitButton, which is used to create a new customer
	async function handleConfirmationAdd (e)  {
		e.preventDefault(); //necesssary to prevent React page refresh on Submit when using a conventional HTML Button, which interferes with API call
		setShowSpinner(true); //Show spinners
 
		if (DEBUG_MODE >= 2) console.log('Called handler to create new customer - top', customerToEdit);
 
		//For Customers, the name is required
		if (!customerToEdit.name ) {if (DEBUG_MODE >= 2) console.log('error creating customer, blank name'); return;}
 
		let tempUrl="", key="", fileUploaded = false, logoWidth = 0, logoHeight = 0;
		try {          
			//Did the user upload a logo file?
			if (imageFileUploadedEdit) {  
			   const {successFlag, url} = await storeImageFile(imageFileUploadedEdit);
			   if (successFlag) {
				   fileUploaded = true; tempUrl = url;
				   console.log ("Successfully wrote image to S3 with URL to corporate LOGO", tempUrl);
			   } else if (DEBUG_MODE >= 2) console.log("Error writing logo image file to S3");            
			}
			
			// create customer object to send to DynamoDB
			const tempCustomerToAdd = {
				name: customerToEdit.name,
				nickname: customerToEdit.nickname,
				logo: tempUrl,
				virtualStore: "",
				useExternalStore: false,                         
				// configDetailsID: tempInsertedConfig.id,
				website:customerToEdit.website,
				logoHeight: logoHeight,
				logoWidth: logoWidth,
				activeEmployees: 0,
				fullName:customerToEdit.fullName, 
				address1:customerToEdit.address1, 
				address2:customerToEdit.address2, 
				city:customerToEdit.city, 
				state:customerToEdit.state, 
				postalCode:customerToEdit.postalCode, 
				country:customerToEdit.country, 
				phoneNumber:customerToEdit.phoneNumber,
				email:customerToEdit.email,
			};

		   // create config details using 
		   var tempConfigDetails = {
				// customerID: tempInsertedCustomer.id, //Grab ID of customer just created
				dollarToCoinConversion:configDetailsToEdit.dollarToCoinConversion,
				ringClosureOverride:configDetailsToEdit.ringClosureOverride,   
				ringClosureFirmCap:configDetailsToEdit.ringClosureFirmCap,         

				socialConectivitiesToCloseRing: configDetailsToEdit.socialConectivitiesToCloseRing,
				socialMinActiveConectivities: configDetailsToEdit.socialMinActiveConectivities,
				socialCoinsToCloseRing: configDetailsToEdit.socialConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_SOCIAL,
				socialPeriodToCloseRing: configDetailsToEdit.socialPeriodToCloseRing,
				socialRewardForClosingRing: configDetailsToEdit.socialRewardForClosingRing,

				stressConectivitiesToCloseRing: configDetailsToEdit.stressConectivitiesToCloseRing,
				stressMinActiveConectivities: configDetailsToEdit.stressMinActiveConectivities,
				stressCoinsToCloseRing: configDetailsToEdit.stressConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_BALANCE,
				stressPeriodToCloseRing: configDetailsToEdit.stressPeriodToCloseRing,
				stressRewardForClosingRing: configDetailsToEdit.stressRewardForClosingRing,
 
				teamConectivitiesToCloseRing: configDetailsToEdit.teamConectivitiesToCloseRing,
				teamMinActiveConectivities: configDetailsToEdit.teamMinActiveConectivities,
				teamCoinsToCloseRing: configDetailsToEdit.teamConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_TEAM,
				teamPeriodToCloseRing: configDetailsToEdit.teamPeriodToCloseRing,
				teamRewardForClosingRing: configDetailsToEdit.teamRewardForClosingRing,

				individualConectivitiesToCloseRing: configDetailsToEdit.individualConectivitiesToCloseRing,
				individualMinActiveConectivities: configDetailsToEdit.individualMinActiveConectivities,
				individualCoinsToCloseRing: configDetailsToEdit.individualConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_PERSONAL,
				individualPeriodToCloseRing: configDetailsToEdit.individualPeriodToCloseRing,
				individualRewardForClosingRing: configDetailsToEdit.individualRewardForClosingRing,

				leaderBoardRewardDailyWin: configDetailsToEdit.leaderBoardRewardDailyWin,
				leaderBoardRewardWeeklyWin: configDetailsToEdit.leaderBoardRewardWeeklyWin,
				leaderBoardRewardMonthlyWin: configDetailsToEdit.leaderBoardRewardMonthlyWin,
				leaderBoardRewardQuarterlyWin: configDetailsToEdit.leaderBoardRewardQuarterlyWin,
				leaderBoardRewardYearlyWin: configDetailsToEdit.leaderBoardRewardYearlyWin,
				spotlightAwardCappedAtTeamRing:configDetailsToEdit.spotlightAwardCappedAtTeamRing,

				spotlightsEnabled: configDetailsToEdit.spotlightsEnabled,
				spotlightsAdminSendOnly: configDetailsToEdit.spotlightsAdminSendOnly,
				spotlightAmountSender: configDetailsToEdit.spotlightAmountSender,
				spotlightAmountReceiver: configDetailsToEdit.spotlightAmountReceiver,


			   //New Employee Program
				newEmpConectivitySchedulingEnabled:(configDetailsToEdit.newEmpConectivitySchedulingEnabled ? true : false),
				newEmployeeOnboardingConectivityID:(conectivityNewEmpOnboarding ? conectivityNewEmpOnboarding.id : null),
				newEmployeeWelcomeConectivityID:(conectivityNewEmpWelcome ? conectivityNewEmpWelcome.id : null),
				newEmployeeOneOnOneSupervisorConectivityID:(conectivityNewEmpOneOnOneSupervisor ? conectivityNewEmpOneOnOneSupervisor.id : null),
				newEmployeeOneOnOneMentorConectivityID:(conectivityNewEmpOneOnOneMentor ? conectivityNewEmpOneOnOneMentor.id : null),
				newEmployeeOneOnOneSponsorConectivityID:(conectivityNewEmpOneOnOneSponsor ? conectivityNewEmpOneOnOneSponsor.id : null),
				newEmployeeLunchConectivityID:(conectivityNewEmpLunch ? conectivityNewEmpLunch.id : null),
				
				

				//Mentor Program Default Settings
				mentorProgramEnabled: (configDetailsToEdit.mentorProgramEnabled ? true : false),
				mentorLaunchEventStartDateTime:mentorLaunchEventStartDateTime.toISOString(),
				mentorLaunchRepeatOption:mentorLaunchRepeatOption.id,                       //See Conectere Config - id of selected option
				mentorLaunchRepeatWeeklyOptions:[],                                         //See Conectere Config - array of id of selected days of week
				mentorLaunchRepeatMonthlyOptions:mentorLaunchRepeatMonthlyOptions.id,       //See Conectere Config - id of selected option
				mentorLaunchRepeatMonthlyOptions_2:mentorLaunchRepeatMonthlyOptions_2.id,   //See Conectere Config - id of selected option
				mentorLaunchRepeatMonthlyOptions_3:mentorLaunchRepeatMonthlyOptions_3.id,   //See Conectere Config - id of selected option
				mentorLaunchMonthOption1Checked: mentorLaunchMonthOption1Checked,    
				mentorLaunchMonthOption2Checked: mentorLaunchMonthOption2Checked,   
				
				//Sponsor Program Default Settings
				sponsorProgramEnabled: (configDetailsToEdit.sponsorProgramEnabled  ? true : false),
				sponsorLaunchEventStartDateTime:sponsorLaunchEventStartDateTime.toISOString(),
				sponsorLaunchRepeatOption:sponsorLaunchRepeatOption.id,                       //See Conectere Config - id of selected option
				sponsorLaunchRepeatWeeklyOptions:[],                                         //See Conectere Config - array of id of selected days of week
				sponsorLaunchRepeatMonthlyOptions:sponsorLaunchRepeatMonthlyOptions.id,       //See Conectere Config - id of selected option
				sponsorLaunchRepeatMonthlyOptions_2:sponsorLaunchRepeatMonthlyOptions_2.id,   //See Conectere Config - id of selected option
				sponsorLaunchRepeatMonthlyOptions_3:sponsorLaunchRepeatMonthlyOptions_3.id,   //See Conectere Config - id of selected option
				sponsorLaunchMonthOption1Checked: sponsorLaunchMonthOption1Checked,    
				sponsorLaunchMonthOption2Checked: sponsorLaunchMonthOption2Checked,   
			};           

			//Loop through selected weekly options (days of weeks) and construct array of IDs
			 if (mentorLaunchRepeatWeeklyOptions) {
				 for (var j=0 ; j < mentorLaunchRepeatWeeklyOptions.length; j++) {
					//if (DEBUG_MODE >= 2) console.log("Adding selected weekly option:", mentorLaunchRepeatWeeklyOptions[j]);
					tempConfigDetails.mentorLaunchRepeatWeeklyOptions.push (mentorLaunchRepeatWeeklyOptions[j].id);
				 }
			 }

			// create customer object to send to DynamoDB
			const tempCustomerBilling = {
				// customerID: tempInsertedCustomer.id, //Grab ID of customer just created
				creditCardNumber:customerBilling.creditCardNumber, 
				creditCardHolder:customerBilling.creditCardHolder, 
				creditCardYear:customerBilling.creditCardYear, 
				creditCardMonth:customerBilling.creditCardMonth,
				creditCardCVC:customerBilling.creditCardCVC,
				creditCardExpiry:customerBilling.creditCardExpiry, 
				creditCardIssuer:customerBilling.creditCardIssuer,
				billingZipCode:customerBilling.billingZipCode,
				// nextInvoiceNumber: 1,
				// nextPaymentTransactionNumber: 1,  
				subscriptionStartDate: subscriptionStartDate.toISOString(),
				subscriptionEndDate: subscriptionEndDate.toISOString(),
				subscription:selectedPlan,                                     //Subscription level (e.g, PLAN-L1 = GOLD, PLAN-L2 = PLATINUM)             
			};
			
			const result = await invokeCreateCustomer({
				customerToAdd:tempCustomerToAdd,
				configDetailsToAdd:tempConfigDetails,
				customerBillingToAdd:tempCustomerBilling,
			});
			
			//New customer created?
			if (result && result.successFlag) {
				if (result.customer && result.customer.id) {
					let tempCustomers = [...customers];   //add new customer to client-side data for this page to the existing array.
					tempCustomers = tempCustomers.filter(customer => customer.id !== result.customer.id); //safety check 
					tempCustomers.push(result.customer);               
					setCustomers(tempCustomers); //Update state data with newly sorted list
				} else if (DEBUG_MODE) console.error("Error - lambda did not return the new customer object", result);
				let successMessage = "Customer created";
				setModalMessage(successMessage);
				setModalMessageTitle("Success!");
				setCustomerDetails(customerInitialState);
				if (DEBUG_MODE >= 2) console.log("Successfully created customer");			 
			} else {
				if (DEBUG_MODE >= 2) console.log("Error creating customer", result);
				setModalMessage((result.error ? result.error : "Could not create the customer account")); //Show specific error, if there is one
				setModalMessageTitle("Ooops!");
			}
			//Reset input fields for form
			setCustomerDetails(customerInitialState);
 
		} catch (err) {
			// Delete the file uploaded to s3 
			if (fileUploaded) await deleteImageFile(tempUrl);
			if (DEBUG_MODE >= 2) console.log('error creating customer:', err);
			setModalMessageTitle("Error");
			setModalMessage("Unable to create customer. ", err);
		}
		
		setShowSpinner(false); //Hide spinners
		setCustomerToEdit(customerInitialState);
		setCustomerBilling(customerBillingInitialState);
		setCustomerBillingCache(customerBillingInitialState);
		setConfigDetailsToEdit(configDetailsInitialState);
		setImageUploadedEdit(null);                     //clear the URL to the uploaded image
		setImageFileUploadedEdit(null);                     //clear the file itself
		setShowModalMessage(true); //display error message
	}

//Process the SAVE function from Edit mode for updating customer
async function handleConfirmationEdit (e) {
  e.preventDefault();     //prevent React page refresh
  setShowSpinner(true); //Show spinners
  setInEditMode(false); // Shut down the edit mode
  let tempUrl="", fileUploaded = false, logoWidth = 0, logoHeight = 0;

  try {
		
	 //Did the user upload a NEW logo file?
	 //This should only be set if original logo was cleared and, possibly, if a NEW logo file was uploaded
	 if (newLogoUploaded) {              
		try {

			//Logo changed or removed?
			if (customerToEditOriginalLogoURL && customerToEditOriginalLogoURL.length >0 && customerToEdit.logo !== customerToEditOriginalLogoURL) {
			  await deleteImageFile(customerToEditOriginalLogoURL);
			  if (DEBUG_MODE >= 2) console.log('Deleted Original logo Image', customerToEditOriginalLogoURL);
			}
			//New logo to upload?
			if (imageFileUploadedEdit) {
			  const {successFlag, url} = await storeImageFile(imageFileUploadedEdit);
			  if (successFlag) {
					fileUploaded = true; tempUrl = url; if (DEBUG_MODE > 1) console.log ("Successfully wrote user's avatar image to S3 ", tempUrl);
			  } else {
					fileUploaded = false;  if (DEBUG_MODE > 0) console.error ("ERROR - failed avatar image to S3");                  
			  }                   
			} else if (DEBUG_MODE > 1) console.log ("User removed original logo but did not upload a new one");                             
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error writing logo image file to S3", err);
			setImageUploadedEdit(null); //clear the URL to the uploaded image
			setImageFileUploadedEdit(null);  //clear the file itself			
			setModalMessageTitle("Error")
			setModalMessage("Unable to save image file.", err);
			setShowModalMessage(true); //display error message
			return;                   
		}
	 } else if (DEBUG_MODE > 1) console.log ("User made no change to the logo");
		
		
		//so, we need to make an object that conforms to the GraphQL mutation for editing the item
		//note, this object does not have all the connection info
		
		// dummy variable to be assigned conditionally
		var tempConfigToEdit = null;
		var tempCustomerToEdit = null;
		var tempCustomerBillingToEdit = null;
		
		tempCustomerToEdit = {
				id: customerToEdit.id,
				name: customerToEdit.name,
				nickname: customerToEdit.nickname,
				website: customerToEdit.website,
				virtualStore: customerToEdit.virtualStore,
				useExternalStore: customerToEdit.useExternalStore,
				logo: (fileUploaded ? tempUrl : customerToEdit.logo),
				logoHeight: (fileUploaded ? logoHeight : customerToEdit.logoHeight),
				logoWidth: (fileUploaded ? logoWidth : customerToEdit.logoWidth),
				fullName:customerToEdit.fullName, 
				address1:customerToEdit.address1, 
				address2:customerToEdit.address2, 
				city:customerToEdit.city, 
				state:customerToEdit.state, 
				postalCode:customerToEdit.postalCode, 
				country:customerToEdit.country, 
				phoneNumber:customerToEdit.phoneNumber,
				email:customerToEdit.email,				
		};
		
		tempCustomerBillingToEdit = {
				id: customerBilling.id,
				creditCardNumber:customerBilling.creditCardNumber, 
				creditCardHolder:customerBilling.creditCardHolder, 
				creditCardYear:customerBilling.creditCardYear, 
				creditCardMonth:customerBilling.creditCardMonth,
				creditCardCVC:customerBilling.creditCardCVC,
				creditCardExpiry:customerBilling.creditCardExpiry, 
				creditCardIssuer:customerBilling.creditCardIssuer,
				billingZipCode:customerBilling.billingZipCode,
				subscription: customerBilling.subscription,
		};

					
		
	tempConfigToEdit = {
			id: configDetailsToEdit.id,
			dollarToCoinConversion:configDetailsToEdit.dollarToCoinConversion,
			ringClosureOverride:!!configDetailsToEdit.ringClosureOverride,            
			ringClosureFirmCap:!!configDetailsToEdit.ringClosureFirmCap,            
			socialConectivitiesToCloseRing: configDetailsToEdit.socialConectivitiesToCloseRing,
			socialMinActiveConectivities: configDetailsToEdit.socialMinActiveConectivities,
			socialCoinsToCloseRing: configDetailsToEdit.socialConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_SOCIAL,
			socialPeriodToCloseRing: configDetailsToEdit.socialPeriodToCloseRing,
			socialRewardForClosingRing: configDetailsToEdit.socialRewardForClosingRing,

			stressConectivitiesToCloseRing: configDetailsToEdit.stressConectivitiesToCloseRing,
			stressMinActiveConectivities: configDetailsToEdit.stressMinActiveConectivities,
			stressCoinsToCloseRing: configDetailsToEdit.stressConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_BALANCE,
			stressPeriodToCloseRing: configDetailsToEdit.stressPeriodToCloseRing,
			stressRewardForClosingRing: configDetailsToEdit.stressRewardForClosingRing,

			teamConectivitiesToCloseRing: configDetailsToEdit.teamConectivitiesToCloseRing,
			teamMinActiveConectivities: configDetailsToEdit.teamMinActiveConectivities,
			teamCoinsToCloseRing: configDetailsToEdit.teamConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_TEAM,
			teamPeriodToCloseRing: configDetailsToEdit.teamPeriodToCloseRing,
			teamRewardForClosingRing: configDetailsToEdit.teamRewardForClosingRing,

			individualConectivitiesToCloseRing: configDetailsToEdit.individualConectivitiesToCloseRing,
			individualMinActiveConectivities: configDetailsToEdit.individualMinActiveConectivities,
			individualCoinsToCloseRing: configDetailsToEdit.individualConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.MIN_COINS_PER_CONECTIVITY_PERSONAL,
			individualPeriodToCloseRing: configDetailsToEdit.individualPeriodToCloseRing,
			individualRewardForClosingRing: configDetailsToEdit.individualRewardForClosingRing,

			leaderBoardRewardDailyWin: configDetailsToEdit.leaderBoardRewardDailyWin,
			leaderBoardRewardWeeklyWin: configDetailsToEdit.leaderBoardRewardWeeklyWin,
			leaderBoardRewardMonthlyWin: configDetailsToEdit.leaderBoardRewardMonthlyWin,
			leaderBoardRewardQuarterlyWin: configDetailsToEdit.leaderBoardRewardQuarterlyWin,
			leaderBoardRewardYearlyWin: configDetailsToEdit.leaderBoardRewardYearlyWin,

			customerID: customerToEdit.id,

			spotlightsEnabled: (configDetailsToEdit.spotlightsEnabled  ? true : false),
			spotlightsAdminSendOnly: configDetailsToEdit.spotlightsAdminSendOnly,
			spotlightAmountSender: configDetailsToEdit.spotlightAmountSender,
			spotlightAmountReceiver: configDetailsToEdit.spotlightAmountReceiver,
			spotlightsFullBccEnabled: (configDetailsToEdit.spotlightsFullBccEnabled  ? true : false),
			spotlightAwardCappedAtTeamRing:!!configDetailsToEdit.spotlightAwardCappedAtTeamRing,
			spotlightCastCapEnabled:  (configDetailsToEdit.spotlightCastCapEnabled  ? true : false),		 											// Flag for capping the number of Spotlights a user can send - default FALSE (no cap)
			spotlightCastCapLimit: (configDetailsToEdit.spotlightCastCapLimit  ? configDetailsToEdit.spotlightCastCapLimit : configDetailsInitialState.spotlightCastCapLimit),				// If capped, the number each employee can send
			spotlightCastCapPeriod: (configDetailsToEdit.spotlightCastCapPeriod  ? configDetailsToEdit.spotlightCastCapPeriod :"DAILY"),	// The period for which the number of spotlights can be sent (if capped), like 1 DAILY or 5 WEEKLY
			

			//New Employee Program
/*                newEmpConectivitySchedulingEnabled:(configDetailsToEdit.newEmpConectivitySchedulingEnabled ? true : false),
			newEmployeeOnboardingConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpOnboarding ? conectivityNewEmpOnboarding.id : null),
			newEmployeeWelcomeConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpWelcome ? conectivityNewEmpWelcome.id : null),
			newEmployeeOneOnOneSupervisorConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  &&  conectivityNewEmpOneOnOneSupervisor ? conectivityNewEmpOneOnOneSupervisor.id : null),
			newEmployeeOneOnOneMentorConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpOneOnOneMentor ? conectivityNewEmpOneOnOneMentor.id : null),
			newEmployeeOneOnOneSponsorConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpOneOnOneSponsor ? conectivityNewEmpOneOnOneSponsor.id : null),
			newEmployeeLunchConectivityID:(configDetailsToEdit.newEmpConectivitySchedulingEnabled  && conectivityNewEmpLunch ? conectivityNewEmpLunch.id : null),
*/    
			//Mentorship & Sponsorshiop
			mentorProgramEnabled: (configDetailsToEdit.mentorProgramEnabled ? true : false),
			sponsorProgramEnabled: (configDetailsToEdit.sponsorProgramEnabled  ? true : false),

			//Mentor Program Default Settings
			mentorLaunchEventStartDateTime:mentorLaunchEventStartDateTime.toISOString(),
			mentorLaunchRepeatOption:mentorLaunchRepeatOption.id,                       //See Conectere Config - id of selected option
			mentorLaunchRepeatWeeklyOptions:[],                                         //See Conectere Config - array of id of selected days of week
			mentorLaunchRepeatMonthlyOptions:mentorLaunchRepeatMonthlyOptions.id,       //See Conectere Config - id of selected option
			mentorLaunchRepeatMonthlyOptions_2:mentorLaunchRepeatMonthlyOptions_2.id,   //See Conectere Config - id of selected option
			mentorLaunchRepeatMonthlyOptions_3:mentorLaunchRepeatMonthlyOptions_3.id,   //See Conectere Config - id of selected option
			mentorLaunchMonthOption1Checked: mentorLaunchMonthOption1Checked,    
			mentorLaunchMonthOption2Checked: mentorLaunchMonthOption2Checked,   

			//Sponsor Program Default Settings
			sponsorLaunchEventStartDateTime:sponsorLaunchEventStartDateTime.toISOString(),
			sponsorLaunchRepeatOption:sponsorLaunchRepeatOption.id,                       //See Conectere Config - id of selected option
			sponsorLaunchRepeatWeeklyOptions:[],                                         //See Conectere Config - array of id of selected days of week
			sponsorLaunchRepeatMonthlyOptions:sponsorLaunchRepeatMonthlyOptions.id,       //See Conectere Config - id of selected option
			sponsorLaunchRepeatMonthlyOptions_2:sponsorLaunchRepeatMonthlyOptions_2.id,   //See Conectere Config - id of selected option
			sponsorLaunchRepeatMonthlyOptions_3:sponsorLaunchRepeatMonthlyOptions_3.id,   //See Conectere Config - id of selected option
			sponsorLaunchMonthOption1Checked: sponsorLaunchMonthOption1Checked,    
			sponsorLaunchMonthOption2Checked: sponsorLaunchMonthOption2Checked,   

		}; 

		const result = await invokeUpdateCustomer({
			cmdIssuerID: currentUser.id,
			customerToEdit:tempCustomerToEdit,
			configDetailsToEdit:tempConfigToEdit,
			customerBillingToEdit:tempCustomerBillingToEdit,
		});

		//Customer updated?
		if (result && result.successFlag) {
			if (result.customer && result.customer.id) {

				//HANDLED VIA SUBSCRIPTION
				//Update local state data
				//Note - would need to handle both admin and management portal where EDIT could be a different company

			} else if (DEBUG_MODE) console.error("Error - lambda did not return the customer object", result);
			let successMessage = "Enterprise settings updated";
			setModalMessage(successMessage);
			setModalMessageTitle("Success!");
			if (DEBUG_MODE >= 2) console.log("Successfully updated customer", result);			 
		} else {
			if (DEBUG_MODE >= 2) console.log("Error updating customer", result);
			setModalMessage((result.error && result.error.length > 0 ? result.error : "Could not update the customer account")); //Show specific error, if there is one
			setModalMessageTitle("Ooops!");
		}			

		setInEditMode(false); //for Admin, just make the form read-only
		setNewLogoUploaded(false); //Using current logo, allow user to replace              		

	} catch (err) {          
		if (DEBUG_MODE >= 2) console.log('error editing customer:', err);
		// Delete the new logo file that was successfully uploaded to s3 
		if (fileUploaded) await deleteImageFile(tempUrl);
		setModalMessageTitle("Error");
		setModalMessage("Unable to save changes to customer.", err);
	}
	setShowSpinner(false); //Hide spinners
	setShowModalMessage(true); //display error message
}


function initializeNewEmployeeSupportSettings (configDetails) {

	//Safety Chek       
	if (!configDetails || !conectivitiesReadyForLaunch || !conectivitiesReadyForLaunch.length === 0) {
		if (DEBUG_MODE >= 2) console.log("Error initializing new employee support", configDetails, conectivitiesReadyForLaunch);
		return;
	}

	if (DEBUG_MODE >= 2) console.log("initializing new employee support", configDetails);

	if (configDetails.newEmployeeOnboardingConectivityID) {
		setSelectedConectivityNewEmpOnboarding(conectivitiesReadyForLaunchOptions.find(c => c.id === configDetails.newEmployeeOnboardingConectivityID));
		setNewEmpOnboardingChecked(true);
		setConectivityNewEmpOnboarding(conectivitiesReadyForLaunch.find(c => c.id === configDetails.newEmployeeOnboardingConectivityID));
	} else {
		setSelectedConectivityNewEmpOnboarding(null);
		setNewEmpOnboardingChecked(false);
		setConectivityNewEmpOnboarding(null);
	}
	if (configDetails.newEmployeeWelcomeConectivityID) {
		setSelectedConectivityNewEmpWelcome(conectivitiesReadyForLaunchOptions.find(c => c.id === configDetails.newEmployeeWelcomeConectivityID));
		setNewEmpWelcomeChecked(true);
		setConectivityNewEmpWelcome(conectivitiesReadyForLaunch.find(c => c.id === configDetails.newEmployeeWelcomeConectivityID));
	} else {
		setSelectedConectivityNewEmpWelcome(null);
		setNewEmpWelcomeChecked(false);
		setConectivityNewEmpWelcome(null);
		
	}
	 if (configDetails.newEmployeeOneOnOneSupervisorConectivityID) {
		setSelectedConectivityNewEmpOneOnOneSupervisor(conectivitiesReadyForLaunchOptions.find(c => c.id === configDetails.newEmployeeOneOnOneSupervisorConectivityID));
		setNewEmpOneOnOneSupervisorChecked(true);
		setConectivityNewEmpOneOnOneSupervisor(conectivitiesReadyForLaunch.find(c => c.id === configDetails.newEmployeeOneOnOneSupervisorConectivityID));
	} else  {
		setSelectedConectivityNewEmpOneOnOneSupervisor(null);
		setNewEmpOneOnOneSupervisorChecked(false);
		setConectivityNewEmpOneOnOneSupervisor(null);
	}    
	
	 if (configDetails.newEmployeeOneOnOneMentorConectivityID) {
		setSelectedConectivityNewEmpOneOnOneMentor(conectivitiesReadyForLaunchOptions.find(c => c.id === configDetails.newEmployeeOneOnOneMentorConectivityID));
		setNewEmpOneOnOneMentorChecked(true);
		setConectivityNewEmpOneOnOneMentor(conectivitiesReadyForLaunch.find(c => c.id === configDetails.newEmployeeOneOnOneMentorConectivityID));
	} else  {
		setSelectedConectivityNewEmpOneOnOneMentor(null);
		setNewEmpOneOnOneMentorChecked(false);
		setConectivityNewEmpOneOnOneMentor(null);
	}             
	 if (configDetails.newEmployeeOneOnOneSponsorConectivityID) {
		setSelectedConectivityNewEmpOneOnOneSponsor(conectivitiesReadyForLaunchOptions.find(c => c.id === configDetails.newEmployeeOneOnOneSponsorConectivityID));
		setNewEmpOneOnOneSponsorChecked(true);
		setConectivityNewEmpOneOnOneSponsor(conectivitiesReadyForLaunch.find(c => c.id === configDetails.newEmployeeOneOnOneSponsorConectivityID));
	} else  {
		setSelectedConectivityNewEmpOneOnOneSponsor(null);
		setNewEmpOneOnOneSponsorChecked(false);
		setConectivityNewEmpOneOnOneSponsor(null);
	}             
	 if (configDetails.newEmployeeLunchConectivityID) {
		setSelectedConectivityNewEmpLunch(conectivitiesReadyForLaunchOptions.find(c => c.id === configDetails.newEmployeeLunchConectivityID));
		setNewEmpLunchChecked(true);
		setConectivityNewEmpLunch(conectivitiesReadyForLaunch.find(c => c.id === configDetails.newEmployeeLunchConectivityID));
	} else {
		setSelectedConectivityNewEmpLunch(null);
		setNewEmpLunchChecked(false);
		setConectivityNewEmpLunch(null);
	}        
}


//Local handler for the Close button
const handleCancel = () => {

	//Reset local state data based on the current operation
	if (isAdd) {
		if (DEBUG_MODE >= 2) console.log("Cancelled Customer Addition.");
		setShowSpinner(false); //Hide spinners
		setCustomerToEdit(customerInitialState);
		setCustomerBilling(customerBillingInitialState);
		setCustomerBillingCache(customerBillingInitialState);
		setConfigDetailsToEdit(configDetailsInitialState);
		setImageUploadedEdit(null);                    
		setImageFileUploadedEdit(null);                     
		setShowModalMessage(false); 
	} else if (isEdit) {
		if (DEBUG_MODE >= 2) console.log("Edit Customer Cancel button pressed.  Restoring original data", customerBillingCache);

		//Grab the original data from our cache in case the user made some scratch changes
		setCustomerToEdit({...customer});
		setCustomerBilling({...customerBillingCache});
		setConfigDetailsToEdit({...customer.configDetails});
		setConfigDetailsToEditCache({...customer.configDetails});
		setImageUploadedEdit(customer.logo); //Grab the S3 URL if present
		setInEditMode(false);
	}


	setNewLogoUploaded(false); //Original logo for now
	setCustomerToEditOriginalLogoURL(null);

	//Invoke parent callback, if supplied
	if (closeCallback) closeCallback();
	else if (DEBUG_MODE) console.log("No callback to invoke");
  }


//Function to handle user removing the image during edit
const handleClearLogoEdit = () => {

	setNewLogoUploaded(true);       //Record this event for use if the user saves the changes
	setImageUploadedEdit("");       //Clear the URL as if we don't have a file yet
	setImageFileUploadedEdit("");   //clear file to be uploaded to S3 bucket 
	setInEditMode(true);            //set if not already in edit mode

	setCustomerToEdit(
		{ 
			...customerToEdit, logo: "", logoWidth: 0, logoHeight: 0,
		}
		
	) //Clear the logo URL and H/W
	
	if (DEBUG_MODE >= 2) console.log("User Removed the existing logo when editing the Customer.");
};


//Admin Edit Button
const handlePutInEditMode = async () => {
	if (DEBUG_MODE >= 2) console.log("User entered edit mode.");
	setInEditMode(true);
	setConfigDetailsToEditCache({...configDetailsToEdit});     //Save a copy to see if the Ring parameters change
	setCoinValueInCents(Math.round(100/(configDetailsToEdit.dollarToCoinConversion ? configDetailsToEdit.dollarToCoinConversion : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION))); // Convert to coin value in cents
	calculateCustomerSpend(configDetailsToEdit);
};

 const handleCloseModalMessage = () => {
	setShowModalMessage(false);
};

//Spend analytics based on Conectivity Customization
const [categoryRingData, setCategoryRingData] = useState([]);
const [categoryRingDataTime, setCategoryRingDataTime] = useState([]);


//Compute total time (in minutes) spent per month give a Ring Period for closure and a minimum number of minutes spent to close the required number of rings for that period

function calcMonthlyTime(period, minutesToCloseRings) {
	
	// if (DEBUG_MODE >= 2) console.log("Minutes per month calc", period, minutesToCloseRings);
	
	if (period === "DAILY") {
		return (Math.round(100 * (minutesToCloseRings / 60) * 5 * 4.3 ) / 100);  //5 DAYS PER WEEK, 4.3 WEEKS PER MONTH
	} else if (period === "WEEKLY") {
		return (Math.round(100*(minutesToCloseRings / 60) * 4.3)/100); 
	} else if (period === "MONTHLY") {
		return (Math.round(100*minutesToCloseRings / 60)/100); 
	} else if (period === "QUARTERLY") {
		return (Math.round(100*(minutesToCloseRings / 60) / 3 )/100); 
	} else if (period === "YEARLY") {
		return (Math.round(100*(minutesToCloseRings / 60) / 12)/100); 
	} else {
		console.log ("Error - incorrect period.  Cannot calculate monthly time");
		return (0);
	}
	
	
}        

function calculateCustomerSpend (currentConfigDetails) {
  //Use customer-specific value rate, if set
  let dollarToCoinConversion = (currentConfigDetails.dollarToCoinConversion ? currentConfigDetails.dollarToCoinConversion : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION);

  if (DEBUG_MODE >= 2) console.log("Re-calculating customer spend", currentConfigDetails);
  if (DEBUG_MODE >= 2) console.log("Using dollar-to-coin conversion rate of", dollarToCoinConversion);
  
  var stressTimePerMonth = calcMonthlyTime(currentConfigDetails.stressPeriodToCloseRing, currentConfigDetails.stressConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_BALANCE); 
  var socialTimePerMonth = calcMonthlyTime(currentConfigDetails.socialPeriodToCloseRing, currentConfigDetails.socialConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_SOCIAL); 
  var teamTimePerMonth = calcMonthlyTime(currentConfigDetails.teamPeriodToCloseRing, currentConfigDetails.teamConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TEAM); 
  var individualTimePerMonth = calcMonthlyTime(currentConfigDetails.individualPeriodToCloseRing, currentConfigDetails.individualConectivitiesToCloseRing * CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_PERSONAL); 

  var stressCostPerMonth = Math.round(100*60*stressTimePerMonth * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_BALANCE / dollarToCoinConversion) /100;
  var socialCostPerMonth = Math.round(100*60*socialTimePerMonth * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_SOCIAL / dollarToCoinConversion) /100;
  var teamCostPerMonth = Math.round(100*60*teamTimePerMonth * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_TEAM / dollarToCoinConversion) /100;
  var individualCostPerMonth = Math.round(100*60*individualTimePerMonth * CONECTERE_CONFIG_DATA.MINUTE_TO_COIN_CONVERSION_PERSONAL / dollarToCoinConversion) /100;
  
  var tempCategoryRingDataTime = [
	 { name: 'Balance', value: stressTimePerMonth },
	 { name: 'Social', value:socialTimePerMonth },
	 { name: 'Team', value: teamTimePerMonth },
	 { name: 'Growth', value:individualTimePerMonth },
  ];

  var tempCategoryRingData = [
	 { name: 'Balance', value: stressCostPerMonth },
	 { name: 'Social', value:socialCostPerMonth },
	 { name: 'Team', value: teamCostPerMonth },
	 { name: 'Growth', value:individualCostPerMonth },
  ];

  setCategoryRingData (tempCategoryRingData);
  setCategoryRingDataTime (tempCategoryRingDataTime);
	
	// if (DEBUG_MODE >= 2) console.log("Calculated customer spend time", tempCategoryRingDataTime);
	// if (DEBUG_MODE >= 2) console.log("Calculated customer spend date", tempCategoryRingData);
}

const handleCategorySelection = (category, selection) => {

	console.log ("Category Selection Called", category, selection);
	
	if (category === "STRESS") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, stressPeriodToCloseRing: selection }); 
		calculateCustomerSpend({ ...configDetailsToEdit, stressPeriodToCloseRing: selection });
	}
	if (category === "TEAM") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, teamPeriodToCloseRing: selection }); 
		calculateCustomerSpend({ ...configDetailsToEdit, teamPeriodToCloseRing: selection }); 
	}
	if (category === "SOCIAL") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, socialPeriodToCloseRing: selection }); 
		calculateCustomerSpend({ ...configDetailsToEdit, socialPeriodToCloseRing: selection }); 
	}
	if (category === "INDIVIDUAL") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, individualPeriodToCloseRing: selection }); 
		calculateCustomerSpend({ ...configDetailsToEdit, individualPeriodToCloseRing: selection }); 
	}
	
};  

 const handleConectivitiesToCloseSelection = (category, value) => {

	console.log ("Category Selection Called", category, value);
	
	if (category === "STRESS") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, stressConectivitiesToCloseRing: value }); 
		calculateCustomerSpend({ ...configDetailsToEdit, stressConectivitiesToCloseRing: value });
	}
	if (category === "TEAM") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, teamConectivitiesToCloseRing: value }); 
		calculateCustomerSpend({ ...configDetailsToEdit, teamConectivitiesToCloseRing: value }); 
	}
	if (category === "SOCIAL") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, socialConectivitiesToCloseRing: value }); 
		calculateCustomerSpend({ ...configDetailsToEdit, socialConectivitiesToCloseRing: value }); 
	}
	if (category === "INDIVIDUAL") {
		setConfigDetailsToEdit({ ...configDetailsToEdit, individualConectivitiesToCloseRing: value }); 
		calculateCustomerSpend({ ...configDetailsToEdit, individualConectivitiesToCloseRing: value }); 
	}
	
};  



//PREVIEW CARDS for configuration purposes
const CCRingsPreview = () => {


	return (
		<div className = "ringsPreview ContainerNoHeightSpaceAround wrap" style={{paddingTop:"clamp(8px,2vw,20px)", paddingBottom:"clamp(8px,2vw,20px)"}}>
			<div className='ContainerNoHeightFlexLeft nowrap'>
				<div className="ContainerVerticalSpaceBetween conectivityRingBackgroundWrapper isHeader " style={{margin:"clamp(8px,2vw,20px)",minWidth:"clamp(200px,35vw,300px)"}}>
					<div className="conectivityRingsInfoRow ContainerNoHeightSpaceBetween fullWidth">
						<div className="ContainerVerticalCenter conectivityRingsInfoRowLeft">
							<h4 className="blue"> Balance </h4>
							<div className="TextStyle3 blue"> {CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_BALANCE + " - " + CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_BALANCE + " mins"} </div>
						</div>
						<div className="ContainerVerticalCenter fullWidth maxHeight conectivityRingsInfoRowRight">
							<img className="conectivityCardHeaderImage" src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} alt=''/>
						</div>
					</div>


					<Divider percent={80} color={BALANCE_COLOR}/>

					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)", }} className="ContainerNoHeightCenter">
						<div className="TextStyle3 blue" style={{paddingRight:"5px"}}> Period </div>
						<select 
							name="stressPeriodDropdown" 
							disabled={inEditMode ? "" : "disabled"}
							id="stressPeriodDropdown" 
							onChange={event => handleCategorySelection("STRESS", event.target.value)} 
							value={PERIOD_OPTIONS.find(p => p.value === configDetailsToEdit.stressPeriodToCloseRing).value}
						>
							{PERIOD_OPTIONS.map((option, key) => 
							<option 
								// selected={configDetailsToEdit.stressPeriodToCloseRing == option.value ? "selected" : ""} 
								key={key}>
								{option.label}
							</option>)}
						</select>
					</div>
					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)", paddingBottom:"10px"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 blue" style={{paddingRight:"5px"}}> Conectivities </div>
						<input
							style={{width:"3em", textAlign:"center"}}
							name="stressConectivitiesToCloseRing"
							disabled={inEditMode ? "" : "disabled"}
							type="number"
							onChange={event => handleConectivitiesToCloseSelection("STRESS", event.target.value)} 
							value={configDetailsToEdit.stressConectivitiesToCloseRing}
							required
						/>

					</div>

					<Divider percent={80} color={BALANCE_COLOR}/>


					<div className="TextStyle2 blue" style={{display:"flex", justifyContent:"space-between", padding:"0px 2px"}}>
							<div  style={{paddingRight:"10px"}}>stretch breaks</div>
							<div  style={{paddingRight:"10px"}}> yoga</div>
							<div  style={{paddingRight:"10px"}}>meditation</div>
							<div  >walks </div>
					</div>
				</div>

				<div className="ContainerVerticalSpaceBetween conectivityRingBackgroundWrapper isHeader" style={{margin:"clamp(8px,2vw,20px)", minWidth:"clamp(200px,35vw,300px)"}}>
					<div className="conectivityRingsInfoRow ContainerNoHeightSpaceBetween fullWidth">
						<div className="ContainerVerticalCenter conectivityRingsInfoRowLeft">
							<h4 className="green"> Social </h4>
							<div className="TextStyle3 green"> {CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_SOCIAL + " - " + CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_SOCIAL + " mins"} </div>
						</div>
						<div className="ContainerVerticalCenter fullWidth maxHeight conectivityRingsInfoRowRight">
							<img className="conectivityCardHeaderImage"  src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} alt=''/>
						</div>
					</div>


					<Divider percent={80} color={SOCIAL_COLOR}/>

					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 green" style={{paddingRight:"5px"}}> Period </div>
						<select 
							name="socialPeriodDropdown" 
							disabled={inEditMode ? "" : "disabled"}
							id="socialPeriodDropdown" 
							onChange={event => handleCategorySelection("SOCIAL", event.target.value)} 
							value={PERIOD_OPTIONS.find(p => p.value === configDetailsToEdit.socialPeriodToCloseRing).value}

						>
							{PERIOD_OPTIONS.map((option, key) => 
							<option 
								// selected={configDetailsToEdit.socialPeriodToCloseRing == option.value ? "selected" : ""} 
								key={key}>
								{option.label}
							</option>)}
						</select>
					</div>
					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)", paddingBottom:"10px"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 green" style={{paddingRight:"5px"}}> Conectivities </div>
						<input
							style={{width:"3em", textAlign:"center"}}
							name="socialConectivitiesToCloseRing"
							disabled={inEditMode ? "" : "disabled"}
							type="number"
							onChange={event => handleConectivitiesToCloseSelection("SOCIAL", event.target.value)} 
							value={configDetailsToEdit.socialConectivitiesToCloseRing}
							required
						/>

					</div>

					<Divider percent={80} color={SOCIAL_COLOR}/>


					<div className="TextStyle2 green" style={{display:"flex", alignItems:"spaceBetween",  padding:"0px 2px"}}>
							<div style={{paddingRight:"10px"}}>virtual trips</div>
							<div style={{paddingRight:"10px"}}>coffee breaks</div>
							<div style={{paddingRight:"10px"}}> lunches</div>
					</div>
				</div>
			</div>
			<div className='ContainerNoHeightFlexLeft nowrap'>
				<div className="ContainerVerticalSpaceBetween conectivityRingBackgroundWrapper isHeader" style={{margin:"clamp(8px,2vw,20px)", minWidth:"clamp(200px,35vw,300px)"}}>
					<div className="conectivityRingsInfoRow ContainerNoHeightSpaceBetween fullWidth">
						<div className="ContainerVerticalCenter conectivityRingsInfoRowLeft">
							<h4 className="purple"> Team </h4>
							<div className="TextStyle3 purple"> {CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_TEAM + " - " + CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_TEAM + " mins"} </div>
						</div>
						<div className="ContainerVerticalCenter fullWidth maxHeight conectivityRingsInfoRowRight">
							<img className="conectivityCardHeaderImage"  src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} alt=''/>
						</div>
					</div>


					<Divider percent={80} color={TEAM_COLOR}/>

					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 purple" style={{paddingRight:"5px"}}> Period </div>
						<select 
							name="teamPeriodDropdown" 
							disabled={inEditMode ? "" : "disabled"}
							id="teamPeriodDropdown" 
							onChange={event => handleCategorySelection("TEAM", event.target.value)} 
							value={PERIOD_OPTIONS.find(p => p.value === configDetailsToEdit.teamPeriodToCloseRing).value}

						>
							{PERIOD_OPTIONS.map((option, key) => 
							<option 
								// selected={configDetailsToEdit.teamPeriodToCloseRing == option.value ? "selected" : ""} 
								key={key}>
								{option.label}
							</option>)}
						</select>
					</div>
					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)", paddingBottom:"10px"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 purple" style={{paddingRight:"5px"}}> Conectivities </div>
						<input
							style={{width:"3em", textAlign:"center"}}
							name="teamConectivitiesToCloseRing"
							disabled={inEditMode ? "" : "disabled"}
							type="number"
							onChange={event => handleConectivitiesToCloseSelection("TEAM", event.target.value)} 
							value={configDetailsToEdit.teamConectivitiesToCloseRing}
							required
						/>

					</div>

					<Divider percent={80} color={TEAM_COLOR}/>

					<div className="TextStyle2 purple" style={{display:"flex", alignItems:"spaceBetween", padding:"0px 2px"}}>
						<div  style={{paddingRight:"10px"}}>activities</div>
						<div  style={{paddingRight:"10px"}}>puzzles</div>
						<div > community service </div>
					</div>
				</div>
				
				<div className="ContainerVerticalSpaceBetween conectivityRingBackgroundWrapper isHeader" style={{margin:"clamp(8px,2vw,20px)", minWidth:"clamp(200px,35vw,300px)"}}>
					<div className="conectivityRingsInfoRow ContainerNoHeightSpaceBetween fullWidth">
						<div className="ContainerVerticalCenter conectivityRingsInfoRowLeft">
							<h4 className="yellow"> Growth </h4>
							<div className="TextStyle3 yellow"> {CONECTERE_CONFIG_DATA.APT_MIN_PER_CONECTIVITY_PERSONAL + " - " + CONECTERE_CONFIG_DATA.APT_MAX_PER_CONECTIVITY_PERSONAL + " mins"} </div>
						</div>
						<div className="ContainerVerticalCenter fullWidth maxHeight conectivityRingsInfoRowRight">
							<img className="conectivityCardHeaderImage"  src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} alt=''/>
						</div>
					</div>

					<Divider percent={80} color={GROWTH_COLOR}/>

					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 yellow" style={{paddingRight:"5px"}}> Period </div>
						<select 
							name="individualPeriodDropdown" 
							disabled={inEditMode ? "" : "disabled"}
							id="individualPeriodDropdown" 
							onChange={event => handleCategorySelection("INDIVIDUAL", event.target.value)} 
							value={PERIOD_OPTIONS.find(p => p.value === configDetailsToEdit.individualPeriodToCloseRing).value}
						>
							{PERIOD_OPTIONS.map((option, key) => 
							<option 
								// selected={configDetailsToEdit.individualPeriodToCloseRing == option.value ? "selected" : ""} 
								key={key}>
								{option.label}
							</option>)}
						</select>
					</div>
					<div style={{width:"80%", paddingTop:"clamp(4px,1vw,10px)", paddingBottom:"10px"}} className="ContainerNoHeightCenter">
						<div className="TextStyle3 yellow" style={{paddingRight:"5px"}}> Conectivities </div>
						<input
							style={{width:"3em", textAlign:"center"}}
							name="individualConectivitiesToCloseRing"
							disabled={inEditMode ? "" : "disabled"}
							type="number"
							onChange={event => handleConectivitiesToCloseSelection("INDIVIDUAL", event.target.value)} 
							value={configDetailsToEdit.individualConectivitiesToCloseRing}
							required
						/>

					</div>

					<Divider percent={80} color={GROWTH_COLOR}/>

					<div className="TextStyle2 yellow" style={{display:"flex", alignItems:"spaceBetween",  padding:"0px 2px"}}>
						<div style={{margin:"0", paddingRight:"10px"}}>mentor lunches</div>
						<div style={{margin:"0", paddingRight:"10px"}}>discussions</div>
						<div style={{margin:"0"}}>lunch & learns </div>
					</div>
				</div>
			</div>
	  </div>
	);
};    


const customerBillingRef = useRef();
useEffect(() => {
  customerBillingRef.current = customerBilling;  //React ref for the current user as its state data is used during useEffect functions
}); 

const handleCreditCardCallback = useCallback((results) => {
  
	if (DEBUG_MODE >= 2) console.log("Results from Credit Card callback",results);
	
	const tempCustomerBilling = { ...customerBillingRef.current, 
		creditCardNumber:results.number, 
		creditCardHolder:results.name, 
		creditCardYear:results.year, 
		creditCardMonth:results.month, 
		creditCardCVC:results.cvc, 
		creditCardExpiry:results.expiry, 
		creditCardIssuer:results.issuer,
		billingZipCode:results.billingZipCode,
		};
	  
	setCustomerBilling(tempCustomerBilling);

},[customerBilling]);       //Need to re-render on any change to Customer Details or this version of the object loses state. Must list every dependency used within the UseCallback handler

const MemoizedCreditCard = useMemo( () => <CreditCard 
	cellBackgroundColor={BALANCE_COLOR} 
	inputNumber={customerBilling.creditCardNumber}
	inputName={customerBilling.creditCardHolder}
	inputExpiry={customerBilling.creditCardExpiry}
	inputCvc={customerBilling.creditCardCVC}
	inputMonth={customerBilling.creditCardMonth}
	inputYear={customerBilling.creditCardYear}
	inputBillingZipCode={customerBilling.billingZipCode}
	readOnly={!inEditMode}
	myCallBack={handleCreditCardCallback
	}/>, [customerBilling, inEditMode]); // memoizing so will not re-render based on any changes to the parent

function displayAutomaticallyScheduledConectivityTimeSlotText(slot) {
	// console.log("Displaying timeslot",slot)
	if (!slot) return null;
	const timeSlot = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(sl => sl.label === slot);
	if (!timeSlot) return null;
	return (
		<>
			<div className="TextStyle4 grey" style={{paddingLeft:"clamp(4px,1vw,10px)"}}> <i>who: {timeSlot.whoText}</i>  </div>
			<div className="TextStyle4 grey" style={{paddingLeft:"clamp(4px,1vw,10px)"}}> <i>when: {timeSlot.startTime}</i>  </div>
		</>
	);
}   


	//handle detailed view of conectivity 
	function handleDetailedView (conectivityToViewDetailed) {
		setConectivityToView(conectivityToViewDetailed);
		setShowModalConectivityDetailed(true);
	} 

	//handle close detailed view
	const handleCancelDetailedView = () => {
		setShowModalConectivityDetailed(false);
		setConectivityToView(null);
	};


function displayAutomaticallyScheduledConectivityTimeSlot({slot, disabled, checkedState, setCheckedState, conectivity, setConectivity, selectedConectivityOption, setSelectionConectivityOption,  zIndex}) {

if (!slot) return;
const text = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot).text;
const helpText = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot).helpText;
const defaultConectivityID = NEW_EMPLOYEE_SUPPORT_PROGRAM.find(entry =>entry.label === slot).id;

	return (
		<div className="ContainerNoHeight fullWidth" style={{padding:"2.0rem", zIndex:zIndex}}>
			 <div className="ContainerVerticalStartStart fullWidth" style={{flex:"1"}}>
				<div className="ContainerNoHeightFlexLeft ">
						 <Checkbox
						  checked={checkedState}
						  disabled={disabled}
						  onChange={()=>{
							setCheckedState(!checkedState);
							if(checkedState) {   //Transitioning to Unchecked? If so, clear selections
								setConectivity(null); 
								setSelectionConectivityOption(null);
							} else { //Transitioning to CHECKED? Load up the defaults
								setSelectionConectivityOption(conectivitiesReadyForLaunchOptions.find(c => c.id === defaultConectivityID));
								setConectivity(conectivitiesReadyForLaunch.find(c => c.id === defaultConectivityID));
							}
						  }}
						  inputProps={{ 'aria-label': 'controlled' }}
						/>
						<div className="TextStyle4">{text}</div>
						<HelpButton hoverText={helpText}  /> 
				</div>
				<div className="ContainerNoHeightFlexLeft " style={{minWidth:"600px"}}>
						<DisplayConectivitySelectComponent 
							name="conectivityOnboardingDropdown" 
							options={conectivitiesReadyForLaunchOptions} 
							onChangeCallback={(option) => {
								setSelectionConectivityOption(option);
								if (option) setConectivity(conectivitiesReadyForLaunch.find(c => c.id === option.id)); else setConectivity(null);
								}} 
							isMulti={false} 
							value={selectedConectivityOption} 
							disabled={!checkedState}  
							placeholder=""
						/>
					{checkedState ? <div><OpenButton hoverText="Preview" onClickCallback={handleDetailedView} onClickCallbackParam={conectivityNewEmpOnboarding}/> </div> : null }
				</div>
				{checkedState ? displayAutomaticallyScheduledConectivityTimeSlotText(slot) : null}
			</div>    
			{/* <div className="ContainerVertical blue fullWidth" style={{flex:"1", position:"relative"}}>
				<DisplayCardConectivityPreview isSmall conectivityToPreview={conectivityNewEmpOnboarding} hideViewButton={true}/> 
			</div>
			*/}
		</div> 
		);
}

function updateCoinValue(data) {
  setCoinValueInCents(data);
  let newDollarToCoinConversionValue = Math.round(100/data);
  if (newDollarToCoinConversionValue < 1) newDollarToCoinConversionValue = 1;
  if (newDollarToCoinConversionValue > 100) newDollarToCoinConversionValue = 100;
  if (DEBUG_MODE > 0) console.log("Calculated new dollar-to-coin conversion (num coins per dollar):", newDollarToCoinConversionValue);
  setConfigDetailsToEdit({ ...configDetailsToEdit, dollarToCoinConversion:newDollarToCoinConversionValue});   
  calculateCustomerSpend({ ...configDetailsToEdit, dollarToCoinConversion:newDollarToCoinConversionValue})
}

const updateSliderValueSender = (e, data) => {
	if (DEBUG_MODE >= 2) console.log("SLIDER EVENT", e, data);
	setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightAmountSender: data });        
	
};

const updateSliderValueReceiver = (e, data) => {
	if (DEBUG_MODE >= 2) console.log("SLIDER EVENT", e, data);
	setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightAmountReceiver: data });           
};  


function formReadyToSubmit() {

  var readyFlag = false;
  
  if (
	customerToEdit.name && 
  //   customerBilling.creditCardNumber &&  
  //   customerBilling.creditCardHolder && 
  //   customerBilling.creditCardYear && 
  //   customerBilling.creditCardMonth && 
  //   customerBilling.creditCardCVC && 
  //   customerBilling.creditCardExpiry &&  
  //   customerBilling.creditCardIssuer && 
  //   customerBilling.billingZipCode && 
	customerToEdit.fullName && 
	customerToEdit.address1 && 
	customerToEdit.city && 
	customerToEdit.state && 
	customerToEdit.postalCode &&  
	customerToEdit.country && 
	customerToEdit.phoneNumber && 
	customerToEdit.email 
	) readyFlag = true;
	
	// if (DEBUG_MODE >= 2) console.log("Returning ready to submit flag", readyFlag, customerToEdit, customerBilling);

	return readyFlag;
} 

	/* RENDER FUNCTION */
	 if (!isSuperAdmin && !isAdmin && !permissionEditor) return null;
	 
	return (
		<>
			<div style={{position:"relative", paddingTop:"clamp(30px,5vh,50px)", zIndex:"0"}}>
				 {isAdd ? <h3> ADD CUSTOMER</h3> : "" } 

				<div className = "ContainerNoHeightFlexEnd" style={{minHeight:"clamp(24px,3vh,40px)"}}>
					{!inEditMode ? 
								<EditButton onClickCallback={handlePutInEditMode} hoverText="Edit"/>
						: 
						<>
							<button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={isAdd ? handleConfirmationAdd : handleConfirmationEdit} disabled={!formReadyToSubmit()} > Save </button>
							<button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleCancel} > Cancel </button>
						</>
					}
				</div>
	

				<div className="ConatinerNoHeightFlexLeft" style={{paddingBottom:"10px"}}>
					<Tabs value={tabIdentifier} onChange={handleTabChange} aria-label="tabs" centered>
						<Tab label="Conectivity Goals" value={'conectivity_goals'} />
						<Tab label="Spotlights" value={'tab_spotlights'} />
						{/*<Tab label="New Employees" value={'New Employees'} />*/}
						<Tab label="Mentorship" value={'mentorship'} />
						<Tab label="Contact Info" value={'corporate info'} />
						{isRenderedWithinMsftClient ? null : <Tab label="Billing & Service Plan" value={'billing'} /> }
					</Tabs>
					<Divider percent={95}/>
				</div>

					
				<div className="customerEditCard">

					<form id='customerEditForm' key="CustomerInputForm">
					<TabPanel value={tabIdentifier} index={'conectivity_goals'} key="conectivity_goals">
						<div className = "ContainerVerticalSpaceBetween maxHeight">
						<CCRingsPreview/> 

						<div className="dashboardCardRow wrap">
									
							<CCPieChart 
									data={categoryRingData} 
									title="Max Rewards" 
									title2="(per employee per month)" 
									width="clamp(360px, 30vw, 500px)"
									height="clamp(288px, 24vw, 400px)"
									dataKey="value" 
									colors={CATEGORY_COLORS} 
									fillColor="#8884d8" 
									innerRadius="65" 
									outerRadius="100"
									showCenterTotal={true}
									centerTotalUnits=""
									centerTotalLeadingUnits="$"
									renderLegend = {renderOurRechartLegend}
									renderTooltip = {renderRechartTooltip}
									
								/>
								
							<CCPieChart 
									data={categoryRingDataTime} 
									title="Max Time"
									title2="(per employee per month)"
									width="clamp(360px, 30vw, 500px)"
									height="clamp(288px, 24vw, 400px)"
									dataKey="value" 
									colors={CATEGORY_COLORS} 
									fillColor="#8884d8" 
									innerRadius="65" 
									outerRadius="100" 
									showCenterTotal={true}
									centerTotalUnits=" hrs"
									centerTotalLeadingUnits=""
									renderLegend = {renderOurRechartLegend}
									renderTooltip = {renderRechartTooltip}

								/>
						</div>

						

							<div className="ContainerVerticalCenter" style = {{padding:"5px 10px", background:"#fafafa", borderRadius:"10px", margin:"40px 0px", width:"40%", zIndex:'9999' }}>
							<div className="ContainerNoHeightCenter">
								<div className="TextStyle4"> Coin Value  </div> 
								<HelpButton hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1ExtraWide' hoverText={HELP_TEXT_SET_COIN_VALUE}  /> 
							</div>
							<div className="ContainerNoHeightFlexLeft headerBlue" style={{width:"100%", height:"100%"}}>
								<div className="ContainerNoHeightCenter" style ={{width:"100%", height:"40px", marginLeft:"10px"}}>
									<Slider 
										defaultValue={coinValueInCents} 
										// step={step} 
										// marks={true} 
										min={1} 
										max={25}
										name="coinValueSlider"
										onChangeCommitted={(e, data) => { updateCoinValue(data)}}
										onChange={(e, data) => {
											setCoinValueInCents(data);
										}}
										value={coinValueInCents}
										valueLabelDisplay="auto"
										disabled={inEditMode ? false : true}  
									/>          
								</div>  
								<div className="ContainerNoHeightCenter TextStyle3"  style ={{padding:"0px 20px"}}>
									<span className="TextStyle5">
										{coinValueInCents} 
									</span>
									<span className="TextStyle3">
										&nbsp;
										{coinValueInCents && coinValueInCents === 1 ? 'cent ' : 'cents'}
									</span>
								</div>   
								<button className="buttonStyle1 buttonStyle1Purple buttonStyle1small  " aria-label="save" onClick={()=>{updateCoinValue(Math.round(100/CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION))}} disabled={!inEditMode} >  Reset  </button>                         
							</div>
						</div>
						<div className="ContainerNoHeightCenter" style={{zIndex:'9990'}}>
							<div className="TextStyle4"> Allow employees to earn coins for conectivities completed after closing rings? </div> 
							<HelpButton hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_RING_CLOSURE_OVERRIDE}  /> 
							<Checkbox
								name="ringClosureOverride"
								checked={configDetailsToEdit.ringClosureOverride ? configDetailsToEdit.ringClosureOverride : false}   
								onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, ringClosureOverride: !configDetailsToEdit.ringClosureOverride})}
								color="primary"
								disabled={inEditMode ? false : true}
							/>
						</div>

						<div className="ContainerNoHeightCenter" style={{zIndex:'9980'}}>
							<div className="TextStyle4"> Apply firm cap when an employee completes a conectivity that closes a ring? </div> 
							<HelpButton hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_RING_CLOSURE_FIRM_CAP}  /> 
							<Checkbox
								name="ringClosureFirmCap"
								checked={configDetailsToEdit.ringClosureFirmCap ? configDetailsToEdit.ringClosureFirmCap : false}   
								onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, ringClosureFirmCap: !configDetailsToEdit.ringClosureFirmCap})}
								color="primary"
								disabled={inEditMode ? false : true}
							/>
						</div>
						</div>
					</TabPanel>

					<TabPanel value={tabIdentifier} index={'tab_spotlights'}  key="tab_spotlights">
						<div className = "ContainerVerticalSpaceBetween maxHeight">
							<div className="ContainerNoHeightCenter TextStyle6A">
								<HighlightIcon style={{fontSize:"32px", color:"gold", transform: "rotate(-145deg) translate(0,0px)"}}/> 
								<div className="green" style={{fontWeight:'800'}}> SPOTLIGHTS </div>
								<HighlightIcon style={{fontSize:"32px", color:"gold", transform: "rotate(+145deg) translate(0,0px)"}}/>
								<div className="spotlightLightRingRight" style={{backgroundColor:"gold"}}></div>
								<div className="spotlightLightRingLeft" style={{backgroundColor:"gold"}}></div>
							</div>


							<div className="ContainerNoHeightSpaceAround fullWidth" >
								<div className="ContainerVerticalCenter" style = {{padding:"5px 10px"}}>
										<div> Enable Spotlights </div> 
										<Switch
											name="enableSpotlights"
											checked={(configDetailsToEdit.spotlightsEnabled ? true : false)}   
											onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightsEnabled: !configDetailsToEdit.spotlightsEnabled })}
											color="primary"
											disabled={inEditMode ? false : true}
										/>
								</div>

								<div className="ContainerVerticalCenter" style = {{padding:"5px 10px"}}>
									<div className="ContainerNoHeightCenter">
										<div>Admin Only (non-peer-to-peer) </div>
										<HelpButton hoverText={HELP_TEXT_ADMIN_SPOTLIGHTS_1}  /> 
									</div>                                        
									<Switch
										name="adminOnlySpotlights"
										checked={configDetailsToEdit.spotlightsAdminSendOnly ? true : false}   
										onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightsAdminSendOnly: !configDetailsToEdit.spotlightsAdminSendOnly })}
										color="primary"
										disabled={inEditMode ? false : true}
									/>
								</div>
								
								<div className="ContainerVerticalCenter" style = {{padding:"5px 10px"}}>
									<div className="ContainerNoHeightCenter">
										<div>Company-wide Bcc</div>
										<HelpButton hoverText={HELP_TEXT_ADMIN_SPOTLIGHTS_2}  /> 
									</div>                                        
									<Switch
											name="fullBCC"
											checked={configDetailsToEdit.spotlightsFullBccEnabled ? true : false}   
											onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightsFullBccEnabled: !configDetailsToEdit.spotlightsFullBccEnabled })}
											color="primary"
											disabled={inEditMode ? false : true}
										/>
								</div>
							</div>
							

							<div className="ContainerVerticalCenter" style = {{padding:"5px 10px", background:"#fafafa", borderRadius:"10px", marginTop:"40px", width:"40%" }}>
									<div> Sender Awards  </div> 
								<div className="ContainerNoHeightFlexLeft headerBlue" style={{width:"100%", height:"100%"}}>
									<div className="ContainerNoHeightCenter" style ={{width:"100%", height:"40px", marginLeft:"10px"}}>
										<Slider 
											defaultValue={SPOTLIGHT_AMOUNT_SENDER} 
											// step={step} 
											// marks={true} 
											min={0} 
											max={SPOTLIGHT_AMOUNT_SENDER}
											name="spotlightSenderSlider"
											onChange={updateSliderValueSender}           
										//   onChange={updateLocalSliderValue}
										//   onChangeCommitted={updateSliderValue}
											value={configDetailsToEdit.spotlightAmountSender}
											valueLabelDisplay="auto"
											disabled={inEditMode ? false : true}
										/>          
									</div>  
									<div className="ContainerNoHeightCenter"  style ={{fontSize:"1.2rem", paddingLeft:"20px"}}>
										{configDetailsToEdit.spotlightAmountSender} 
										<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>
									</div>                           
								</div>
							</div>
							
							<div className="ContainerVerticalCenter" style = {{marginTop:"40px", padding:"5px 10px", background:"#fafafa", borderRadius:"10px", width:"60%" }}>
								<div> Recipient Awards  </div> 
								<div className="ContainerNoHeightFlexLeft headerBlue" style={{width:"100%",  height:"100%"}}>
									<div className="ContainerNoHeightCenter"style ={{width:"100%", height:"40px", marginLeft:"10px"}}>
										<Slider 
											defaultValue={SPOTLIGHT_AMOUNT_RECEIVER} 
											// step={step} 
											// marks={true} 
											min={0} 
											max={SPOTLIGHT_AMOUNT_RECEIVER}
											name="spotlightRecipientSlider"
											onChange={updateSliderValueReceiver}

										//   onChange={updateLocalSliderValue}
										//   onChangeCommitted={updateSliderValue}
											value={configDetailsToEdit.spotlightAmountReceiver}
											valueLabelDisplay="auto"
											disabled={inEditMode ? false : true}

										/>          
									</div>   
									<div className="ContainerNoHeightCenter" style ={{fontSize:"1.2rem", paddingLeft:"20px"}}>
										{configDetailsToEdit.spotlightAmountReceiver} 
									<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>
									</div>                           
								</div>
							</div>

							<div className="ContainerNoHeightCenter" style={{zIndex:'9980', marginTop:"40px"}}>
								<div className="TextStyle4"> Cap coins rewarded for Spotlight recognitions once an employee's Team ring has been closed? </div> 
								<HelpButton hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_SPOTLIGHTS_AFTER_RING_CLOSURE}  /> 
								<Checkbox
									name="spotlightAwardCappedAtTeamRing"
									checked={configDetailsToEdit.spotlightAwardCappedAtTeamRing ? configDetailsToEdit.spotlightAwardCappedAtTeamRing : false}   
									onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightAwardCappedAtTeamRing: !configDetailsToEdit.spotlightAwardCappedAtTeamRing})}
									color="primary"
									disabled={inEditMode ? false : true}
								/>
							</div>

							<div className="ContainerNoHeightCenter" style={{zIndex:'9970', marginTop:"clamp(10px,2vh,20px)"}}>
								<div className="TextStyle4"> Limit the number of Spotlights an employee can cast? </div> 
								<HelpButton hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1Wide' hoverText={HELP_TEXT_SPOTLIGHT_SEND_CAP}  /> 
								<Checkbox
									name="spotlightCastCapEnabled"
									checked={configDetailsToEdit.spotlightCastCapEnabled ? configDetailsToEdit.spotlightCastCapEnabled : false}   
									onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightCastCapEnabled: (configDetailsToEdit.spotlightCastCapEnabled ? !configDetailsToEdit.spotlightCastCapEnabled : true)})}
									color="primary"
									disabled={inEditMode ? false : true}
								/>
							</div>

								<div className="ContainerNoHeightCenter very-light-grey-background" style={{borderRadius:"clamp(4px,1vw,10px)", overflow:'hidden', padding:(configDetailsToEdit.spotlightCastCapEnabled ? "clamp(5px,1vh,10px)" : '0'), margin:"clamp(5px,1vh,10px)", transition:'all 0.5s', height:(configDetailsToEdit.spotlightCastCapEnabled ? '60px' : 0) }}>
									<div className="ContainerNoHeightCenter" >
											<input
												style={{width:"3em", textAlign:"center"}}
												name="spotlightCastCapLimit"
												disabled={inEditMode ? "" : "disabled"}
												type="number"
												min={1} 
												onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightCastCapLimit: (event.target.value < 0 ? 0 : event.target.value)})}
												value={configDetailsToEdit.spotlightCastCapLimit}
												required
											/>
											<div className="TextStyle3 blue" style={{ minWidth:'clamp(70px,10vw,100px)', padding:"clamp(4px,1vw,10px)"}}> {!configDetailsToEdit.spotlightCastCapLimit || configDetailsToEdit.spotlightCastCapLimit > 1 ? 'Spotlights' : 'Spotlight'} </div>
									</div>									
									<select 
										name="spotlightCastCapPeriod" 
										disabled={inEditMode ? "" : "disabled"}
										id="spotlightCastCapPeriod" 
										onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, spotlightCastCapPeriod:  event.target.value})}
										value={configDetailsToEdit.spotlightCastCapPeriod ? (PERIOD_OPTIONS.find(p => p.value === configDetailsToEdit.spotlightCastCapPeriod).value) : PERIOD_OPTIONS[0]}
									>
										{PERIOD_OPTIONS.map((option, key) => 
										<option 
											// selected={configDetailsToEdit.stressPeriodToCloseRing == option.value ? "selected" : ""} 
											key={key}>
											{option.label}
										</option>)}
									</select>									
								</div>
						</div>

					</TabPanel>

					{/*<TabPanel value={tabIdentifier} index={'New Employees'}  key="tab_new_employee">
						<div className="ContainerVerticalSpaceAround" style={{position:"relative"}}>
							<div className="ContainerNoHeightCenter purple" style={{padding:"2rem 0rem"}}>
								<h3> Support Your New Employees! </h3>
							</div>
							<div className="ContainerVerticalCenter" style = {{padding:"5px 10px"}}>
									<div> {configDetailsToEdit && configDetailsToEdit.newEmpConectivitySchedulingEnabled ? 'Program Disabled' : 'Program Disabled'} </div> 
									<Switch
										name="enableNewEmployeeSupportProgram"
										checked={(configDetailsToEdit && configDetailsToEdit.newEmpConectivitySchedulingEnabled ? true : false)}   
										onChange={handleNewEmployeeEnableProgramToggle}
										color="primary"
										disabled={inEditMode ? false : true}
									/>
							</div>
							
							<div className="ContainerNoHeightCenter fullWidth" style={{padding:"2.0rem"}}>
								<div style={{flex:"1"}}> <p className="grey">Onboarding provides a great opportunity to help new hires feel welcome and appreciated, and to start them 
								along a path to success.  Customize your own new employee support plan in which conectivities are automatically scheduled for new hires.
								Connect them with colleagues, team leads, mentors, supervisors, and easily direct them to necessary resources and training material. </p>  </div>    
								<div className="imageContainer" style={{flex:"1"}}><img  className="ccImgXXXXXL" src={IMAGE_COMMUNITY_1} /> </div>
							</div>

							{displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONBOARDING", disabled:(!inEditMode), checkedState:newEmpOnboardingChecked, setCheckedState:setNewEmpOnboardingChecked, conectivity:conectivityNewEmpOnboarding, setConectivity:setConectivityNewEmpOnboarding, selectedConectivityOption:selectedConectivityNewEmpOnboarding, setSelectionConectivityOption:setSelectedConectivityNewEmpOnboarding, zIndex:"100"})} 
							{displayAutomaticallyScheduledConectivityTimeSlot({slot:"WELCOME", disabled:(!inEditMode), checkedState:newEmpWelcomeChecked, setCheckedState:setNewEmpWelcomeChecked, conectivity:conectivityNewEmpWelcome, setConectivity:setConectivityNewEmpWelcome, selectedConectivityOption:selectedConectivityNewEmpWelcome, setSelectionConectivityOption:setSelectedConectivityNewEmpWelcome, zIndex:"90" })} 
							{displayAutomaticallyScheduledConectivityTimeSlot({slot:"LUNCH", disabled:(!inEditMode), checkedState:newEmpLunchChecked, setCheckedState:setNewEmpLunchChecked, conectivity:conectivityNewEmpLunch, setConectivity:setConectivityNewEmpLunch, selectedConectivityOption:selectedConectivityNewEmpLunch, setSelectionConectivityOption:setSelectedConectivityNewEmpLunch, zIndex:"80" })} 
							{displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONEONONE_SUPERVISOR", disabled:(!inEditMode), checkedState:newEmpOneOnOneSupervisorChecked, setCheckedState:setNewEmpOneOnOneSupervisorChecked, conectivity:conectivityNewEmpOneOnOneSupervisor, setConectivity:setConectivityNewEmpOneOnOneSupervisor, selectedConectivityOption:selectedConectivityNewEmpOneOnOneSupervisor, setSelectionConectivityOption:setSelectedConectivityNewEmpOneOnOneSupervisor, zIndex:"70" })} 
							{displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONEONONE_MENTOR", disabled:(!inEditMode || !configDetailsToEdit.mentorProgramEnabled), checkedState:newEmpOneOnOneMentorChecked, setCheckedState:setNewEmpOneOnOneMentorChecked, conectivity:conectivityNewEmpOneOnOneMentor, setConectivity:setConectivityNewEmpOneOnOneMentor, selectedConectivityOption:selectedConectivityNewEmpOneOnOneMentor, setSelectionConectivityOption:setSelectedConectivityNewEmpOneOnOneMentor, zIndex:"60" })} 
							{displayAutomaticallyScheduledConectivityTimeSlot({slot:"ONEONONE_SPONSOR", disabled:(!inEditMode || !configDetailsToEdit.sponsorProgramEnabled), checkedState:newEmpOneOnOneSponsorChecked, setCheckedState:setNewEmpOneOnOneSponsorChecked, conectivity:conectivityNewEmpOneOnOneSponsor, setConectivity:setConectivityNewEmpOneOnOneSponsor, selectedConectivityOption:selectedConectivityNewEmpOneOnOneSponsor, setSelectionConectivityOption:setSelectedConectivityNewEmpOneOnOneSponsor, zIndex:"50" })} 
						</div>
					</TabPanel>*/}
					

					<TabPanel value={tabIdentifier} index={'mentorship'}  key="'mentorship'">
						<div className="ContainerVerticalSpaceAround" style={{padding: "0px clamp(4px,2vw,20px)"}}>
							<div className="ContainerNoHeightCenter purple" style={{padding:"2rem 0rem"}}>
								<h3> MENTORSHIP AND SPONSORSHIP PROGRAMS </h3>
								<img  className="ccImgXL" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
							</div>


							<div className="ContainerNoHeightSpaceAround fullWidth blue wrap" >
								<div className="ContainerVerticalCenter boxShadow " style={{padding:"clamp(4px,2vw,20px)", margin:"clamp(4px,2vw,20px)"}}>
										<div className="TextStyle5"> Enable Mentorship Program? </div> 
										<Switch
											name="enableMentorship" 
											checked={configDetailsToEdit.mentorProgramEnabled ? true : false}   
											onChange={(event) => {
												setConfigDetailsToEdit({ ...configDetailsToEdit, mentorProgramEnabled: !configDetailsToEdit.mentorProgramEnabled });
												if (configDetailsToEdit.mentorProgramEnabled) { //Transition to DISABLED? Clear the New Employee Mentorship selections.
													setSelectedConectivityNewEmpOneOnOneMentor(null);
													setNewEmpOneOnOneMentorChecked(false);
													setConectivityNewEmpOneOnOneMentor(null); 
												}
											}}
											color="primary"
											disabled={inEditMode ? false : true}
										/>
										
									<div className="ContainerVerticalStart fullWidth" style={{flex:1,  height:"100%",  alignItems:"flex-start"}}>
										<div className="ContainerNoHeightFlexLeft" style={{zIndex:"77770", paddingTop:"clamp(8px,2vw,20px)", alignItems:"flex-start", position:"relative" }}>
												<LocalizationProvider dateAdapter={AdapterMoment}>
														<TimePicker
														label="Time"
														value={mentorLaunchEventStartDateTime}
														disabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
														onChange={(newDateValue) => {
															setMentorLaunchEventStartDateTime(newDateValue);
														//   if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
														}}
														// renderInput={(params) => <TextField {...params} />}
														/>
												</LocalizationProvider>
										</div> 

										<div style={{padding:"10px 0px", width:"300px", zIndex:"9966", position:"relative"}}>
											<Select  isMulti={false} 
												name="repeatDropdown" 
												options={CALENDAR_REPEAT_OPTIONS} 
												onChange={setMentorLaunchRepeatOption} 
												value={mentorLaunchRepeatOption} 
												placeholder="Does not repeat"
												isDisabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
												/>
											<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > Recurrence </div>
										</div>
											
						
										{mentorLaunchRepeatOption?.value !== "WEEKLY" ? null :
											<div style={{padding:"10px 0px", width:"20rem", zIndex:"8888", position:"relative"}}>
												<Select  
													isMulti={true} 
													name="weeklyRepeatOptionsDropdown" 
													options={WEEK_REPEAT_OPTIONS} 
													onChange={setMentorLaunchRepeatWeeklyOptions}
													value={mentorLaunchRepeatWeeklyOptions} 
													placeholder="Every"
													isDisabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
													/>
												<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > Every </div>
											</div>
										}
						
										{mentorLaunchRepeatOption?.value !== "MONTHLY" ? null :
												<div className="ContainerVerticalStart fullWidth">
												<div className="ContainerNoHeightFlexLeft fullWidth">
														<Checkbox
														checked={mentorLaunchMonthOption1Checked}
														disabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
														onChange={()=>{
														setMentorLaunchMonthOption1Checked(!mentorLaunchMonthOption1Checked);
														setMentorLaunchMonthOption2Checked(!mentorLaunchMonthOption2Checked);
														}}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
													
													<div style={{padding:"10px 0px", width:"8rem", zIndex:"7777", position:"relative"}}>
														<Select  
															isMulti={false} 
															name="monthlyRepeatOptionsDropdown_2" 
															options={MONTH_REPEAT_OPTIONS_2} 
															onChange={setMentorLaunchRepeatMonthlyOptions_2} 
															value={mentorLaunchRepeatMonthlyOptions_2} 
															placeholder="- select -"
															isDisabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
															/>
														<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > On the </div>
													</div>
													
													<div style={{padding:"10px 5px", width:"13rem", zIndex:"7777", position:"relative"}}>
														<Select  
															isMulti={false} 
															name="monthlyRepeatOptionsDropdown_3" 
															options={MONTH_REPEAT_OPTIONS_3} 
															onChange={setMentorLaunchRepeatMonthlyOptions_3} 
															value={mentorLaunchRepeatMonthlyOptions_3} 
															placeholder="- select -"
															isDisabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
															/>
													</div>
												</div>
												<div className="ContainerNoHeightFlexLeft fullWidth">
													<Checkbox
														checked={mentorLaunchMonthOption2Checked}
														disabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
														onChange={()=>{
														setMentorLaunchMonthOption1Checked(!mentorLaunchMonthOption1Checked);
														setMentorLaunchMonthOption2Checked(!mentorLaunchMonthOption2Checked);
															
														}}
														inputProps={{ 'aria-label': 'controlled' }}
														/>
													
													<div style={{padding:"10px 0px", width:"10rem", zIndex:"6666", position:"relative"}}>
														<Select  
															isMulti={false} 
															name="monthlyRepeatOptionsDropdown" 
															options={MONTH_REPEAT_OPTIONS} 
															onChange={setMentorLaunchRepeatMonthlyOptions} 
															value={mentorLaunchRepeatMonthlyOptions} 
															placeholder="- select day -"
															isDisabled={!inEditMode || !configDetailsToEdit.mentorProgramEnabled}
															/>
															
														<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > On this day </div>
							
													</div>
												</div>
											</div>
											
											}
									</div>

								</div>
								
								<div className="ContainerVerticalCenter  boxShadow" style = {{padding:"clamp(4px,2vw,20px)", margin:"clamp(4px,2vw,20px)"}}>
										<div className="TextStyle5 "> Enable Sponsorship Program? </div> 
										<Switch
											name="enableSponsorship" 
											checked={configDetailsToEdit.sponsorProgramEnabled ? true : false}   
											onChange={event => setConfigDetailsToEdit({ ...configDetailsToEdit, sponsorProgramEnabled: !configDetailsToEdit.sponsorProgramEnabled })}

												onChange={(event) => {
												setConfigDetailsToEdit({ ...configDetailsToEdit, sponsorProgramEnabled: !configDetailsToEdit.sponsorProgramEnabled });
												if (configDetailsToEdit.sponsorProgramEnabled) { //Transition to DISABLED? Clear the New Employee Mentorship selections.
													setSelectedConectivityNewEmpOneOnOneSponsor(null);
													setNewEmpOneOnOneSponsorChecked(false);
													setConectivityNewEmpOneOnOneSponsor(null); 
												}
											}}

											color="primary"
											disabled={(inEditMode ? false : true)}
											// disabled={true}
										/>
								
									<div className="ContainerVerticalStart fullWidth" style={{flex:1,  height:"100%",  alignItems:"flex-start"}}>
										<div className="ContainerNoHeightFlexLeft" style={{zIndex:"77770", paddingTop:"clamp(8px,2vw,20px)", alignItems:"flex-start", position:"relative" }}>
												<LocalizationProvider dateAdapter={AdapterMoment}>
														<TimePicker
														label="Time"
														value={sponsorLaunchEventStartDateTime}
														disabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
														onChange={(newDateValue) => {
															setSponsorLaunchEventStartDateTime(newDateValue);
														//   if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
														}}
														// renderInput={(params) => <TextField {...params} />}
														/>
												</LocalizationProvider>
										</div> 

										<div style={{padding:"10px 0px", width:"300px", zIndex:"9966", position:"relative"}}>
											<Select  isMulti={false} 
												name="repeatDropdown" 
												options={CALENDAR_REPEAT_OPTIONS} 
												onChange={setSponsorLaunchRepeatOption} 
												value={sponsorLaunchRepeatOption} 
												placeholder="Does not repeat"
												isDisabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
												/>
											<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > Recurrence </div>
										</div>
											
						
										{sponsorLaunchRepeatOption?.value !== "WEEKLY" ? null :
											<div style={{padding:"10px 0px", width:"20rem", zIndex:"8888", position:"relative"}}>
												<Select  
													isMulti={true} 
													name="weeklyRepeatOptionsDropdown" 
													options={WEEK_REPEAT_OPTIONS} 
													onChange={setSponsorLaunchRepeatWeeklyOptions}
													value={sponsorLaunchRepeatWeeklyOptions} 
													placeholder="Every"
													isDisabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
													/>
												<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > Every </div>
											</div>
										}
						
										{sponsorLaunchRepeatOption?.value !== "MONTHLY" ? null :
												<div className="ContainerVerticalStart fullWidth">
												<div className="ContainerNoHeightFlexLeft fullWidth">
														<Checkbox
														checked={sponsorLaunchMonthOption1Checked}
														disabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
														onChange={()=>{
														setSponsorLaunchMonthOption1Checked(!sponsorLaunchMonthOption1Checked);
														setSponsorLaunchMonthOption2Checked(!sponsorLaunchMonthOption2Checked);
														}}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
													
													<div style={{padding:"10px 0px", width:"8rem", zIndex:"7777", position:"relative"}}>
														<Select  
															isMulti={false} 
															name="monthlyRepeatOptionsDropdown_2" 
															options={MONTH_REPEAT_OPTIONS_2} 
															onChange={setSponsorLaunchRepeatMonthlyOptions_2} 
															value={sponsorLaunchRepeatMonthlyOptions_2} 
															placeholder="- select -"
															isDisabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
															/>
														<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > On the </div>
													</div>
													
													<div style={{padding:"10px 5px", width:"13rem", zIndex:"7777", position:"relative"}}>
														<Select  
															isMulti={false} 
															name="monthlyRepeatOptionsDropdown_3" 
															options={MONTH_REPEAT_OPTIONS_3} 
															onChange={setSponsorLaunchRepeatMonthlyOptions_3} 
															value={sponsorLaunchRepeatMonthlyOptions_3} 
															placeholder="- select -"
															isDisabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
															/>
													</div>
												</div>
												<div className="ContainerNoHeightFlexLeft fullWidth">
													<Checkbox
														checked={sponsorLaunchMonthOption2Checked}
														disabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
														onChange={()=>{
														setSponsorLaunchMonthOption1Checked(!sponsorLaunchMonthOption1Checked);
														setSponsorLaunchMonthOption2Checked(!sponsorLaunchMonthOption2Checked);
															
														}}
														inputProps={{ 'aria-label': 'controlled' }}
														/>
													
													<div style={{padding:"10px 0px", width:"10rem", zIndex:"6666", position:"relative"}}>
														<Select  
															isMulti={false} 
															name="monthlyRepeatOptionsDropdown" 
															options={MONTH_REPEAT_OPTIONS} 
															onChange={setSponsorLaunchRepeatMonthlyOptions} 
															value={sponsorLaunchRepeatMonthlyOptions} 
															placeholder="- select day -"
															isDisabled={!inEditMode || !configDetailsToEdit.sponsorProgramEnabled}
															/>
															
														<div style={{position:"absolute", top:"0", left:"20px", fontSize:"12px", color:"grey", backgroundColor:"white", padding:"2px"}} > On this day </div>
							
													</div>
												</div>
											</div>
											
											}
									</div>
								</div>
								
							</div>
							
							<p style={{paddingTop:"2.0rem"}} >Configure default settings for automated scheduling of mentorship & sponsorship conectivities.  You can easily modify these later.</p>      

						</div>
					</TabPanel>

					<TabPanel value={tabIdentifier} index={'corporate info'}  key="corporate info">
						<div className = "ContainerVerticalCenter">
							<div className="ContainerNoHeightCenter purple" style={{padding:"2rem 0rem 1rem 0rem"}}>
								<h4> COMPANY INFO </h4>
							</div>

							<div className="ContainerVerticalStartStart ">
								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
										<TableText text="Company Name" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>
									<div className="formDataStyle">
										<input
										name="CustomerName"
										className={`textInputElement ${!customerToEdit.name || customerToEdit.name.length===0 ? 'textInputElementRequired' : ""} `}                                       
										size="30"
										type="text"
										value={customerToEdit.name ? customerToEdit.name : ""}
										onChange={(event) => {setCustomerToEdit({ ...customerToEdit, name: event.target.value });}}										
										required
										disabled={inEditMode ? "" : "disabled"}
										/>
									</div>
								</div > 

								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
											<TableText text="Marketing Name" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>
									<div className="formDataStyle">
										<input
											key="input_1"
											name="CustomerNickname"
											className={`textInputElement ${!customerToEdit.nickname || customerToEdit.nickname.length===0 ? 'textInputElementRequired' : ""} `}                                       
											disabled={inEditMode ? "" : "disabled"}
											type="text"
											value={customerToEdit.nickname ? customerToEdit.nickname : ""}
											onChange={(event) => {
												setCustomerToEdit({ ...customerToEdit, nickname: event.target.value });
											}}
											required
											/>
									</div>

								</div >

								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
											<TableText text="Logo" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>

										{imageUploadedEdit ? 
												<img id="uploadedImagedIDEdit" src={imageUploadedEdit} height='64' alt="" /> 
											: 
												<>
													<FileUploader
													imageChangeCallback={handleImageUploadEdit} 
													fileTypesToAccept="image/png, image/jpeg"
													buttonText="Upload Logo"
													isDisabled={!inEditMode}
													/>  
												</>
											}
								</div >

								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
											<TableText text="Website" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>
									<div className="formDataStyle">
									<textarea
												key="input_2"
												disabled={inEditMode ? "" : "disabled"}
												rows="2"
												cols="40"
												name="website"
												type="text"
												value={customerToEdit.website ? customerToEdit.website : ""}
												onChange={(event) => {
													setCustomerToEdit({ ...customerToEdit, website: event.target.value });
												}}
												required
												size="15"
											/>										
									</div>
								</div >
							</div>	

{/*
							<table className="formStyleGrey">
								<tbody>
									<tr>
										<td className="popEditFormHeaderStyle">Company Name</td>
										<td className="formDataStyle">
											<input
											key="input_1"
											name="CustomerName"
											disabled={inEditMode ? "" : "disabled"}
											type="text"
											value={customerToEdit.name ? customerToEdit.name : ""}
											onChange={(event) => {
												setCustomerToEdit({ ...customerToEdit, name: event.target.value });
											}}
											required
											/>
										</td>
									</tr>
									<tr>
										<td className="popEditFormHeaderStyle">Marketing Name</td>
										<td className="formDataStyle">
											<input
											key="input_1"
											name="CustomerNickname"
											disabled={inEditMode ? "" : "disabled"}
											type="text"
											value={customerToEdit.nickname ? customerToEdit.nickname : ""}
											onChange={(event) => {
												setCustomerToEdit({ ...customerToEdit, nickname: event.target.value });
											}}
											required
											/>
										</td>
									</tr>
								<tr>
										<td style={{backgroundColor:"white"}} colSpan="1"></td>
									</tr>
									<tr>
										<td className="popEditFormHeaderStyle">Logo</td>
										<td className="formDataStyle">
																				
											{imageUploadedEdit ? 
												<img id="uploadedImagedIDEdit" src={imageUploadedEdit} height='64' alt="" /> 
											: 
												<>
													<FileUploader
													imageChangeCallback={handleImageUploadEdit} 
													fileTypesToAccept="image/png, image/jpeg"
													buttonText="Upload Logo"
													isDisabled={!inEditMode}
													/>  
												</>
											}
												
										</td>
										
										<td>
										{!imageUploadedEdit || !inEditMode ? "" : <Button className="btn btn-secondary btn-sm" onClick={handleClearLogoEdit}><div > Clear </div></Button> }
										</td>
									</tr>
									<tr>
										<td style={{backgroundColor:"white"}} colSpan="1"></td>
									</tr>
									<tr>
										<td className="popEditFormHeaderStyle">Website</td>
										<td className="formDataStyle" colSpan="4">
											<textarea
												key="input_2"
												disabled={inEditMode ? "" : "disabled"}
												rows="2"
												cols="40"
												name="website"
												type="text"
												value={customerToEdit.website ? customerToEdit.website : ""}
												onChange={(event) => {
													setCustomerToEdit({ ...customerToEdit, website: event.target.value });
												}}
												required
												size="15"
											/>
										</td>
									</tr>
								</tbody>
							</table>
*/}
						</div>

						<div className = "ContainerVerticalCenter" style={{paddingBottom:"2.0rem"}}>
							<div className="ContainerNoHeightCenter purple" style={{padding:"2rem 0rem 1rem 0rem"}}>
								<h4> PRIMARY CONTACT </h4>
							</div>

						<div className="ContainerVerticalStartStart ">
								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
										<TableText text="Full Name" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>
									<div className="formDataStyle">
										<input
											name="fullName"
											className={`textInputElement ${!customerToEdit.fullName || customerToEdit.fullName.length===0 ? 'textInputElementRequired' : ""} `}                                       
											disabled={inEditMode ? "" : "disabled"}
											type="text"
											value={!customerToEdit || !customerToEdit.fullName ? "" : customerToEdit.fullName}
											onChange={(event) => { setCustomerToEdit({ ...customerToEdit, fullName: event.target.value }); }}
											required
										/>
									</div>
								</div > 

								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
										<TableText text="Address (line 1)" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>
									<div className="formDataStyle">
									<input
										name="address1"
										className={`textInputElement ${!customerToEdit.address1 || customerToEdit.address1.length===0 ? 'textInputElementRequired' : ""} `}                                       
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="40"
										value={!customerToEdit ? "" : customerToEdit.address1}
										onChange={event => setCustomerToEdit({ ...customerToEdit, address1: event.target.value })}
										required
										/>	
									</div>
								</div > 

								<div className='ContainerNoHeightFlexLeft nowrap'>
									<div className="TextStyle5 ">
										<TableText text="Address (line 2)" required={true} minWidth='clamp(150px,20vw,250px)'/>
									</div>
									<div className="formDataStyle">
									<input
										name="address1"
										className={`textInputElement  `}                                       
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="40"
										value={!customerToEdit ? "" : customerToEdit.address2}
										onChange={event => setCustomerToEdit({ ...customerToEdit, address2: event.target.value })}
										/>	
									</div>
								</div > 
								<div className='ContainerNoHeightFlexLeft '>
									<div className='ContainerNoHeightFlexLeft nowrap'>

										<div className="TextStyle5 ">
											<TableText text="City" required={true} minWidth='clamp(150px,20vw,250px)'/>
										</div>
										<div className="formDataStyle">
											<input
												name="City"
												className={`textInputElement ${!customerToEdit.city || customerToEdit.city.length===0 ? 'textInputElementRequired' : ""} `}                                       
												disabled={inEditMode ? "" : "disabled"}
												type="text"
												value={!customerToEdit ? "" : customerToEdit.city}
												onChange={event => setCustomerToEdit({ ...customerToEdit, city: event.target.value })}
												size="15"
												required
											/>	
										</div>
									</div>
									<div className='ContainerNoHeightFlexLeft nowrap'>

										<div className="TextStyle5 ">
											<TableText text="State" required={true} minWidth='clamp(150px,20vw,250px)'/>
										</div>
										<div className="formDataStyle">
											<input
												name="State"
												className={`textInputElement ${!customerToEdit.state || customerToEdit.state.length===0 ? 'textInputElementRequired' : ""} `}                                       
												disabled={inEditMode ? "" : "disabled"}
												type="text"
												value={!customerToEdit ? "" : customerToEdit.state}
												onChange={event => setCustomerToEdit({ ...customerToEdit, state: event.target.value })}
												size="15"
												required
											/>	
										</div>
									</div>
								</div > 

								<div className='ContainerNoHeightFlexLeft '>
									<div className='ContainerNoHeightFlexLeft nowrap'>
										<div className="TextStyle5 ">
											<TableText text="Postal Code" required={true} minWidth='clamp(150px,20vw,250px)'/>
										</div>
										<div className="formDataStyle">
											<input
												name="postalCode"
												disabled={inEditMode ? "" : "disabled"}
												type="text"
												size="15"
												value={!customerToEdit ? "" : customerToEdit.postalCode}
												onChange={event => setCustomerToEdit({ ...customerToEdit, postalCode: event.target.value })}
												required
												className={`textInputElement ${!customerToEdit.postalCode || customerToEdit.postalCode.length===0 ? 'textInputElementRequired' : ""} `}                                       
												/>	
										</div>
									</div>
									<div className='ContainerNoHeightFlexLeft nowrap'>
										<div className="TextStyle5 ">
											<TableText text="Country" required={true} minWidth='clamp(150px,20vw,250px)'/>
										</div>
										<div className="formDataStyle">
											<input
												name="country"
												disabled={inEditMode ? "" : "disabled"}
												type="text"
												size="15"
												value={!customerToEdit ? "" : customerToEdit.country}
												onChange={event => setCustomerToEdit({ ...customerToEdit, country: event.target.value })}
												className={`textInputElement ${!customerToEdit.country || customerToEdit.country.length===0 ? 'textInputElementRequired' : ""} `}                                       
												required
											/>	
										</div>
									</div>
								</div > 

								<div className='ContainerNoHeightFlexLeft wrap'>
									<div className='ContainerNoHeightFlexLeft nowrap'>

										<div className="TextStyle5 ">
											<TableText text="Phone" required={true} minWidth='clamp(150px,20vw,250px)'/>
										</div>
										<div className="formDataStyle">
											<input
													name="phoneNumber"
													disabled={inEditMode ? "" : "disabled"}
													size="15"
													type="text"
													value={!customerToEdit ? "" : customerToEdit.phoneNumber}
													onChange={event => setCustomerToEdit({ ...customerToEdit, phoneNumber: event.target.value })}
													required
													className={`textInputElement ${!customerToEdit.phoneNumber || customerToEdit.phoneNumber.length===0 ? 'textInputElementRequired' : ""} `}                                       
												/>	
										</div>
									</div>
									<div className='ContainerNoHeightFlexLeft nowrap'>
										<div className="TextStyle5 ">
											<TableText text="Email" required={true} minWidth='clamp(150px,20vw,250px)'/>
										</div>
										<div className="formDataStyle">
											<input
												name="email"
												disabled={inEditMode ? "" : "disabled"}
												size="25"
												type="text"
												value={!customerToEdit ? "" : customerToEdit.email}
												onChange={event => setCustomerToEdit({ ...customerToEdit, email: event.target.value })}
												required
												className={`textInputElement ${!customerToEdit.email || customerToEdit.email.length===0 ? 'textInputElementRequired' : ""} `}                                       
											/>	
										</div>
									</div>
								</div > 	
							</div>						
{/*}
							<table className="formStyleGrey">
								<tbody>
								<tr>
									<td className="popEditFormHeaderStyle">Full Name</td>
									<td className="formDataStyle">
										<input
										name="fullName"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										value={!customerToEdit || !customerToEdit.fullName ? "" : customerToEdit.fullName}
										onChange={(event) => {
											setCustomerToEdit({ ...customerToEdit, fullName: event.target.value });
										}}
										required
										/>
									</td>
								</tr>
								<tr>
									<td style={{backgroundColor:"white"}} colSpan="1"></td>
								</tr>
								<tr>
									<td className="popEditFormHeaderStyle">Address (line 1)</td>
									<td className="formDataStyle">
										<input
										name="address1"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="40"
										value={!customerToEdit ? "" : customerToEdit.address1}
										onChange={event => setCustomerToEdit({ ...customerToEdit, address1: event.target.value })}
										required

										/>
									</td>
								</tr>
								<tr>
									<td style={{backgroundColor:"white"}} colSpan="1"></td>
								</tr>
								<tr>
									<td className="popEditFormHeaderStyle">Address (line 2)</td>
									<td className="formDataStyle">
										<input
										name="address2"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="40"
										value={!customerToEdit ? "" : customerToEdit.address2}
										onChange={event => setCustomerToEdit({ ...customerToEdit, address2: event.target.value })}
										required
										/>
									</td>
								</tr>
								<tr>
									<td style={{backgroundColor:"white"}} colSpan="1"></td>
								</tr>
								<tr>
									<td className="popEditFormHeaderStyle">City</td>
									<td className="formDataStyle">
										<input
										name="City"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										value={!customerToEdit ? "" : customerToEdit.city}
										onChange={event => setCustomerToEdit({ ...customerToEdit, city: event.target.value })}
										required
										/>
									</td>
									<td className="popEditFormHeaderStyle">State</td>
									<td className="formDataStyle">
										<input
										name="state"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="15"
										value={!customerToEdit ? "" : customerToEdit.state}
										onChange={event => setCustomerToEdit({ ...customerToEdit, state: event.target.value })}
										required

										/>
									</td>
								</tr>
								<tr>
									<td style={{backgroundColor:"white"}} colSpan="1"></td>
								</tr>
								<tr>
									<td className="popEditFormHeaderStyle">Postal Code</td>
									<td className="formDataStyle">
										<input
										name="postalCode"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="10"
										value={!customerToEdit ? "" : customerToEdit.postalCode}
										onChange={event => setCustomerToEdit({ ...customerToEdit, postalCode: event.target.value })}
										required

										/>
									</td>
									<td className="popEditFormHeaderStyle">Country</td>
									<td className="formDataStyle">
										<input
										name="country"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="25"
										value={!customerToEdit ? "" : customerToEdit.country}
										onChange={event => setCustomerToEdit({ ...customerToEdit, country: event.target.value })}
										required

										/>
									</td>
								</tr>
								<tr>
									<td style={{backgroundColor:"white"}} colSpan="1"></td>
								</tr>
								<tr>
									<td className="popEditFormHeaderStyle">Phone</td>
									<td className="formDataStyle">
										<input
											name="phoneNumber"
											disabled={inEditMode ? "" : "disabled"}
											size="15"
											type="text"
											value={!customerToEdit ? "" : customerToEdit.phoneNumber}
											onChange={event => setCustomerToEdit({ ...customerToEdit, phoneNumber: event.target.value })}
											required
										/>
									</td>
									<td className="popEditFormHeaderStyle">Email</td>
									<td className="formDataStyle">
										<input
										name="email"
										disabled={inEditMode ? "" : "disabled"}
										type="text"
										size="25"
										value={!customerToEdit ? "" : customerToEdit.email}
										onChange={event => setCustomerToEdit({ ...customerToEdit, email: event.target.value })}
										required

										/>
									</td>
								</tr>
								</tbody>
							</table>
*/}
						</div>
					</TabPanel>


				{!permissionBilling ? "" :					
					<TabPanel value={tabIdentifier} index={'billing'} key="billing">
							<div className="ContainerVerticalCenter purple" style={{padding:"2rem 0rem 1rem 0rem"}}>

								<div className="ContainerNoHeightCenter">
									<h4> SERVICE PLAN </h4>
								<HelpButton hoverTextClassName='TextStyle3 hoverTextStyle1 hoverTextStyle1ExtraWide' hoverText={HELP_TEXT_SERVICE_PLANS} /> 
							</div>
							<div className="ContainerNoHeightCenter alignEnd">
									<div style={{minWidth:"300px", maxWidth:"500px", marginTop:"clamp(8px,2vw,20px)"}}>
										<Select  
											isMulti={false} 
											name="servicePlans" 
											options={SERVICE_PLAN_OPTIONS} 
											onChange={(option) => setCustomerBilling({...customerBilling,subscription:option.label})} 
											value={customerBilling && customerBilling.subscription ? SERVICE_PLAN_OPTIONS.find(plan => plan.label === customerBilling.subscription) : SERVICE_PLAN_OPTIONS.find(plan => plan.label === SUBSCRIPTION_LEVEL_2)} 
											placeholder=" - select a plan - "
											isDisabled={!inEditMode }
											formatOptionLabel={plan => (
												<div className="ContainerNoHeightFlexLeft noWrap">
														<span className=""  style={{paddingLeft: "5px", whiteSpace:"nowrap"}}>{plan.displayString}</span>   
												</div>
											)}
										/>
									</div>		
									<div  style={{marginLeft:"10px"}}>
											<a class="TextStyle2 blue italic" href='https://www.conectere.net/plans.html' target="_blank" rel="noreferrer">  (plans) </a>
									</div>
							</div>
							<div className="ContainerVerticalCenter" style={{paddingTop:"40px"}}>
								<h4> PAYMENT METHOD </h4>				
								{MemoizedCreditCard}
								{customerBilling.creditCardIssuer ? "" : <div style={{color:"red"}}> Please enter a valid credit card number </div> }
							</div>
						</div>						
					</TabPanel>
				}
				</form>
				</div>
			</div>

			<ConectivityDetailedView showModal={showModalConectivityDetailed} conectivityToView={conectivityToView}  handleCancelDetailedView={handleCancelDetailedView} />
		</>
	);
}
);

export default CustomerEditor;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//React & Amplify
import React from 'react';

//Data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';

//Utils
import moment from 'moment';
import { NOW } from "./dateTimeUtils"; 
import { displayUsersRow }  from "./userAndTeamUtils";
import { displayTransactionAmount, displayAwards,  } from "./generalUtils";
import { setHeaderColorByCategory  } from "./conectivityUtils";


//Component for displaying transaction history
export const TransactionHistory = ({transactionGroups}) => {

    //Safety check
    if (!transactionGroups || transactionGroups.length === 0) return null;

    return (
        <div className="ContainerVerticalStart">
            {transactionGroups.map((transactionGroup, index) => {
                return <TransactionGroup key={index.toString()} transactionGroup={transactionGroup}/>
            })}
        </div>
    );
}

export const TransactionGroup = ({transactionGroup}) => {
    //Safety check
    if (!transactionGroup || !transactionGroup.days || transactionGroup.days.length === 0 || !transactionGroup.date) return null;

    if (DEBUG_MODE > 3) console.log("Rendering Transaction Group", transactionGroup);

    return (
        <div key={transactionGroup.days[0].id} className="ContainerVerticalStart transactionGroup fullWidth" >
            <div className='ContainerNoHeightSpaceBetween fullWidth'>
                <div className="TextStyle5" > {transactionGroup.date} </div>
                {displayAwards({coins:transactionGroup.days[0].newBalance, badgesDEI:transactionGroup.days[0].badgesDEIBalance, badgesCS:transactionGroup.days[0].badgesCSBalance, badgesSpotlight:transactionGroup.days[0].badgesSpotlightBalance, showZeros:false})}   
            </div>         
            {transactionGroup.days.map((transaction) => {               
                return <TransactionCard transaction={transaction} />;
            })}
        </div>
    )
}

//Transaction card displayed on mobile view
export const TransactionCard = ({transaction}) => {
    //safety check
    if(!transaction) return null;
    if (DEBUG_MODE >3) console.log("Rendering Transaction ", transaction);
    const USERS_TO_SHOW = 5;

    return (
            <div className='transactionCard ContainerVerticalStartStart wrap boxShadow fullWidth' key={transaction.id} style={{borderTopColor:setHeaderColorByCategory(transaction.category)}}>
                <div className='ContainerNoHeightSpaceBetween fullWidth'>
                    <div className="ContainerNoHeightFlexLeft">
                        <img className='ccImgXXL' src={transaction.image}  alt=''/>
                        <div className="TextStyle5" style={{paddingLeft:"clamp(4px,0.5vw,8px)", color:setHeaderColorByCategory(transaction.category), textWrap:"wrap", wordBreak:"break-word"}}> {transaction.name} </div>
                    </div>         
                    {displayTransactionAmount({amount:transaction.amount, badgesDEI:transaction.badgesDEI, badgesCS:transaction.badgesCS, badgesSpotlight:transaction.badgesSpotlight})}
                </div>       
                <div className="TextStyle2 memoDataStyle"> {transaction.memo} </div>
                <div className="ContainerNoHeightCenter" style={{height:"100%"}}>
                    {transaction.usersToShow.length == 0 ? "" :
                        <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper blue"  >
                            {displayUsersRow({usersToShow:transaction.usersToShow, numUsersToShow:USERS_TO_SHOW, isSmall:true})}
                        </div>
                    }
                </div>  
            </div>    
    )
}


//Functions for  sorting Conectivities based on expiration
export function compareTransactionsByDate(a, b) {

    // if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
    
    const aDateTime = moment(a.createdAt, "YYYY-MM-DDTHH:mm:ssZ");
    const bDateTime = moment(b.createdAt, "YYYY-MM-DDTHH:mm:ssZ");
    
    if (aDateTime.isAfter(bDateTime)) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return 1;
        }
    if (bDateTime.isAfter(aDateTime)) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return -1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}

export function compareTransactionsByDateReverseChronological(a, b) {

    // if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
    
    const aDateTime = moment(a.createdAt, "YYYY-MM-DDTHH:mm:ssZ");
    const bDateTime = moment(b.createdAt, "YYYY-MM-DDTHH:mm:ssZ");
    
    if (aDateTime.isAfter(bDateTime)) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
        return -1;
        }
    if (bDateTime.isAfter(aDateTime)) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return 1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}
 
//Functions for sorting CCTransactions based on sequence number
export function compareTransactionsBySequenceNumber(a, b) {

    if (a.sequenceNumber > b.sequenceNumber) {
        return -1;
        }
    
    if (a.sequenceNumber < b.sequenceNumber) {
        // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
        return 1;
    }
    
    // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
    return 0;

}


//Function for processing a set of transactions and generating a subset that represent unique conectivities, i.e, no a single transaction for each conectivity
//regardless of the number of users that participated
export function generateUniqueTransactionsForEdge({transactions, fromUserID, toUserID}) {
    
    const uniqueTransactions = [];
    var tempUniqueTransactions = [];
    const scheduledConectivitiesAlreadyAdded = [];

    if (DEBUG_MODE >= 2) console.log("Generating unique transactions for edge.", transactions, fromUserID, toUserID);
    
    if (transactions && transactions.length && transactions.length > 0) {
       //Filter the transactions to those listing the FROM user for the edge
        tempUniqueTransactions =  transactions.filter(transaction => {
            // if (DEBUG_MODE >= 2) console.log("Checking transaction", transaction);
            return ((transaction.userID == fromUserID) || (transaction.usersThatParticipatedWithMe && transaction.usersThatParticipatedWithMe.includes(fromUserID)));
        });
        
        if (DEBUG_MODE >= 2) console.log("Generating unique transactions for edge.  Matching transactions for FROM userID of the edge", tempUniqueTransactions);
     
        //Further Filter the transactions to those listing the TO user for the edge
        tempUniqueTransactions =  tempUniqueTransactions.filter(transaction => {
            // if (DEBUG_MODE >= 2) console.log("Checking transaction", transaction);
            return ((transaction.userID == toUserID) || (transaction.usersThatParticipatedWithMe && transaction.usersThatParticipatedWithMe.includes(toUserID)));
        });
    
        if (DEBUG_MODE >= 2) console.log("Generating unique transactions for edge.  Matching transactions for both FROM userID and TO UserID of the edge", tempUniqueTransactions);
        
        
        //Now, process the transactions such that there is only 1 per scheduled conectivity
    
        tempUniqueTransactions.map (transaction => {

            //Transaction a conectivity?  If so, only count it if we have not seen a transaction already for this scheduled conectivity so as not to double count            
            if (transaction.scheduledConectivityID && transaction.transactionType=="COMPLETED_CONECTIVITY") {
                
                if (!scheduledConectivitiesAlreadyAdded.includes(transaction.scheduledConectivityID)) {
                    uniqueTransactions.push(transaction);
                    scheduledConectivitiesAlreadyAdded.push(transaction.scheduledConectivityID);
                    // if (DEBUG_MODE >= 2) console.log("Adding conectivity transaction.", transaction);
                } else {
                    // if (DEBUG_MODE >= 2) console.log("Skipping transaction.  Scheduled Conectivity already accounted for.", transaction);
                }
                
            }
            

            //If SENDING a spotlight, add the transaction to our unique list
            if (transaction.transactionType=="SPOTLIGHT_SENT") {
                uniqueTransactions.push(transaction);
            }
            
            
        });
    
        if (DEBUG_MODE >= 2) console.log("Generated unique transactions for edge.", uniqueTransactions);
    }
        
    return uniqueTransactions;
    
}


//Reduce a set of transactions by the selected graph Period
export function filterTransactionsByPeriod(transactions, graphPeriod) {

    if (!graphPeriod || !transactions) return [];
    
    switch (graphPeriod) {
        case "DAY":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().startOf('day'))); 
            });
        case "WEEK":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().startOf('week'))); 
            });
        case "MONTH":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().startOf('month'))); 
            });
        case "QUARTER":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().startOf('quarter'))); 
            });
        case "YEAR":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().startOf('year'))); 
            });
        case "1W":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().subtract(1,'weeks'))); 
            });
        case "1M":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().subtract(1,'month'))); 
            });
        case "2M":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().subtract(2,'months'))); 
            });
        case "1Y":
            return transactions.filter(transaction => { 
                const transactionDate = moment(transaction.createdAt, "YYYY MM DDTHH mm ssZ");
                return (transactionDate.clone().isAfter(NOW().subtract(1,'years'))); 
            });
        case "all":
            return transactions;
        default:
             return transactions;
    }                
    
}

export function isPastTransaction(transaction) {

    const createdDateTime = moment(transaction.createdAt, "YYYY-MM-DDTHH:mm:ssZ");
    const currentDateTime = moment();
    
    if (createdDateTime.isBefore(currentDateTime)) return true;
    
    return false;

}
    

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import "./conectivityTimeSeries.css";

//config
import  { DEBUG_MODE } from '../../../../shared/data/conectereConfigData';

import React, { useEffect, useState, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';    


//CONTEXT
import { AuthContext } from '../../../../shared/context/authContext';
import { CustomerContext } from '../../../../shared/context/customerContext';            //Customer Authentication context
import { AnalyticsContext } from '../../../../shared/context/analyticsContext';

import ChartRace from 'react-chart-race';
import CCLineChart from "../lineChart/lineChart";

  
  // ChartRace provide an animated horizontal bar chart
  // Examples and documents here:
  // https://www.npmjs.com/package/react-chart-race

//Bootstrap and other React components
import Switch from '@material-ui/core/Switch';
import Slider from '@mui/material/Slider';          //Note documentation: https://mui.com/api/slider/ 
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { IconButton } from '@mui/material';
import Select from 'react-select';                   //Version with support from multiple selections

//Utils
import {  renderOurRechartLegend, renderRechartTooltip } from "../../../../shared/utils/analyticsUtils";
import moment from 'moment';

//Our React component
//graphHeight and graphWidth set the window size for displaying the bar chart race
const ConectivityTimeSeriesAnimation = ({transactions, userToGraph, hoverCallBack, doubleClickCallback, graphWidth, graphHeight}) => {

  if (!graphWidth)  graphWidth = "1200px";
  if (!graphHeight)  graphHeight = "800px";
  
   
   // Authentication context
    const {
            authState,
     } = useContext(AuthContext);   
    
   // Customer context
    const {
            users,  
    } = useContext(CustomerContext);   
 
    //Analytics context
    const {
            analyticsGenerated,
            timeSeriesGraphDataDay,                     //Time Series Data
            selectedUsersOptions, 
    } = useContext(AnalyticsContext);
 
    //React state
    // const [transactions, setCCTransactions] = useState([]);
    
    // const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);
    const [selectedTeamsOptionsToEdit, setSelectedTeamsOptionsToEdit] = useState([]);
    // const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);  //Note, this stores an array of objects of the select Options.  See react-select

    // const[timeframeRadioValue, setTimeframeRadioValue] = useState("QUARTER");
    // const [showAvatars, setShowAvatars] = useState(true);               //Toggle switch for whether or not to display employee images in the graph nodes
    const [useSlidingWindow, setUseSlidingWindow] = useState(true);     //Toggle switch for whether or not to use a sliding window

    // const [graphWidth, setGraphWidth] = useState("1200px");
    // const [graphHeight, setGraphHeight] = useState("800px");
    
    const [animationSpeedOptions, setAnimationSpeedOptions] = useState ([{label:"0.5x", value:0, isdisabled: false }, {label:"1x", value:1, isdisabled: false }, {label:"1.5x", value:2, isdisabled: false },  {label:"2x", value:3, isdisabled: false }, {label:"3x", value:4, isdisabled: false },  {label:"5x", value:5, isdisabled: false }, ,  {label:"10x", value:6, isdisabled: false }]);
    const [animationSpeedDropDownSelection, setAnimationSpeedDropDownSelection] = useState({label:"1x", value:1, isdisabled: false });  //Note, this stores an object of the select Option.  See react-select

    //Note - options are in terms of business days per day/week/month/quarter/year
    const [windowSizeInDaysOptions, setWindowSizeInDays] = useState ([{label:"Day", value:0, isdisabled: false, windowSizeInDays:1 }, {label:"Week", value:1, isdisabled: false, windowSizeInDays:5 }, {label:"Month", value:2, isdisabled: false, windowSizeInDays:20 },  {label:"Quarter", value:3, isdisabled: false, windowSizeInDays:60 }, {label:"Year", value:6, isdisabled: false, windowSizeInDays:240 }]);
    const [windowSizeInDaysDropDownSelection, setWindowSizeInDaysDropDownSelection] = useState({label:"Month", value:2, isdisabled: false });  //Note, this stores an object of the select Option.  See react-select

    const showLabelsOnSlider = false;
    
    const [filteredTransactions, setFilteredTransaction] = useState([]);
  
    const [dateHeadPointer, setDateHeadPointer] = useState(0);                  //Head pointer into DATE array
    const [dateTailPointer, setDateTailPointer] = useState(0);                  //Tail pointer into DATE array
    const [transactionHeadPointer, setTransactionHeadPointer] = useState(0);    //Head pointer into TRANSACTION array
    const [transactionTailPointer, setTransactionTailPointer] = useState(0);    //Tail pointer into TRANSACTION array
  
    const [animationGraphData, setAnimationGraphData]  = useState([]);
    const [animationGraphRenderTrigger, setAnimationGraphRenderTrigger] = useState(false);
    
    const DEFAULT_TRANSITION_TIME = 1000;  //Update every X milliseconds
    const TIME_BAR_WIDTH = "clamp(800px, 60vw, 1800px)";
    const TIME_LINE_GRAPH_HEIGHT="250px";
    const TIME_LINE_GRAPH_WIDTH="1200px";
    
    const [animationTransitionTime, setAnimationTransitionTime] = useState(DEFAULT_TRANSITION_TIME);      //Update time in ms
    const [startAnimationTimer, setStartAnimationTimer] = useState(false); 
    const [animationTimeBarSliderValue, setAnimationTimeBarSliderValue] = useState([0,0]);                //Array of head value and tail value for SLIDER; scale = 1 to Number of Days in DATE Array
    const [animationDataReady, setAnimationDataReady] = useState(false);
    
    const [timeSeriesGraphKey, setTimeSeriesGraphKey] = useState("conectivities");
    const [timeSeriesBackgroundColor, setTimeSeriesBackgroundColor] = useState("#6495ED");

    const [maxDaysInTimeSeries, setMaxDaysInTimeSeries] = useState(0);
    
    // // Use Material UI Cards
    // //Examples:
    // // https://material-ui.com/components/cards/
    
    // //Material UI CSS styles on this page
    // const useStyles = makeStyles((theme) => ({
    //   timeBarSlider: {
    //     backgroundColor: red[500],
    //   },
    // }));

    // const classes = useStyles();


/*
    //Update transaction records on any change to selected customers
    useEffect(() => {
        fetchUserTransactions(); 
    }, [selectedUsersOptions]);      //Trigger rebuild of graph data on change to user records or to user selection

    
    async function fetchUserTransactions() {
 
          //Fetch this user's recent transactions if we don't already have them cached locally
         var tempTransactionsForSelectedUsers = [...transactions]; //Copy master
         

        const startDateString = moment().subtract(365,"days").toISOString();             
        const todayString =  moment().toISOString();
        if (DEBUG_MODE >= 2) console.log("Fetching user transactions from: " + startDateString);
        
        for (var i=0; i<selectedUsersOptions.length; i++) {
        
          //Fetch this user's recent transactions if we don't already have them cached locally
           if (tempTransactionsForSelectedUsers.some(transaction => transaction.userID == selectedUsersOptions[i].id) == false) {
        
               //Now, grab transactions for this user
        
              const queryParams = {
                          userID: selectedUsersOptions[i].id,                         //Primary Key
                          createdAt: {between: [ startDateString, todayString ]},     //Secondary Key
                      };
              
              var  tempCCTransactions =  await queryDataTableWithPagination(getCCTransactionsByUserByDate, "getCCTransactionsByUserByDate", queryParams);
              
              
              tempCCTransactions = tempCCTransactions.filter(transaction => transaction.transactionType =="COMPLETED_CONECTIVITY" );
              
              
              tempTransactionsForSelectedUsers = [...tempTransactionsForSelectedUsers, ...tempCCTransactions];  //Build a new array of user transactions to include old plus any new
        
              if (DEBUG_MODE >= 2) console.log("Fetched transactions for this user and updated master array: ", queryParams, tempCCTransactions, tempTransactionsForSelectedUsers);
        
           } else {
              // if (DEBUG_MODE >= 2) console.log("Transactions for this user PREVIOUSLY FETCHED.  Master array unchanged ", tempTransactionsForSelectedUsers);
          
           }  
        }
       
       
       //Sort and save the transactions
       tempTransactionsForSelectedUsers.sort(compareTransactionsByDate);
       setCCTransactions(tempTransactionsForSelectedUsers);    //Save

    }

*/    
    //Trigger page set up in response to change in transactions or a change in which users to graph
    useEffect(() => {
        initializePage();  

    }, [transactions, timeSeriesGraphDataDay, selectedUsersOptions]);

    //generate data needed for rendering the animation
    async function initializePage() {

        //wait for users, teams, transactions and corresponding analytics to be loaded by context
        if (authState != "signedin" || !analyticsGenerated) return;

        if (!transactions || transactions.length == 0) return;
        
        if (!timeSeriesGraphDataDay || !timeSeriesGraphDataDay.length) return;

        if (DEBUG_MODE >= 2) console.log("Initializing Time Series Animation page", timeSeriesGraphDataDay);

        setMaxDaysInTimeSeries(timeSeriesGraphDataDay.length);
        
        //Compute the Time Bar labels based on transaction data
        genTimeBarLabels();
      
        initializeAnimation();
        
        setStartAnimationTimer(false);
        setAnimationDataReady(true);
        
  }
  
  function initializeAnimation() {
    
      var tempSelectedUsers = []; //By default use an empty array
      
      if (selectedUsersOptions !== null) tempSelectedUsers = [...selectedUsersOptions];   

      var tempAnimationData = [];
        
        if (tempSelectedUsers.length == 0) if (DEBUG_MODE >= 2) console.log("processing users", tempSelectedUsers);

        //Build array of USERS for Racing Bar Chart
        //Only include those that have been selected

        for (var j=0; j<users.length; j++) {
 
          //Add if NO user selected OR if this user is one of the selected ones         
          if (tempSelectedUsers.length == 0 || tempSelectedUsers.some (user => user.id === users[j].id)) {
          
          tempAnimationData.push({ 
            id: j, 
            title:users[j].firstName.toUpperCase() + " " + (!users[j].middleName ? "" : users[j].middleName.substr(0,1).toUpperCase() + " ") +users[j].lastName.toUpperCase(),
            value: 0, 
            color: '#50c4fe', 
            userID:users[j].id });
           }
        }
  
      if (DEBUG_MODE >= 2) console.log("Initialized animation data", tempAnimationData);

      setAnimationGraphData(tempAnimationData);
      setAnimationTimeBarSliderValue([0,0]);
      setDateHeadPointer(0);
      setDateTailPointer(0);
      setTransactionHeadPointer(0);
      setTransactionTailPointer(0);
  }





  function handleSlidingWindowSwitch (event) {
    
     setUseSlidingWindow(!useSlidingWindow);
    
    if (!useSlidingWindow) {
      if (DEBUG_MODE >= 2) console.log("Switching Sliding Window display to:", !useSlidingWindow, dateTailPointer, dateHeadPointer);
      setAnimationTimeBarSliderValue([0, 0]);   //Switching to TRUE to TWO state variables in Slider array
    } else {
    if (DEBUG_MODE >= 2) console.log("Switching Sliding Window display to:", !useSlidingWindow, dateTailPointer, dateHeadPointer);
      setAnimationTimeBarSliderValue(0);        //Switching to FALSE so ONE state variable in Slider array
    }
    
    initializeAnimation(); //reset our animation graph and start over
    
  }  


  //
  //Animation
  //
  
   //React component for animation time bar
   const [timeBarLabels, setTimeBarLabels] = useState([""]);
   
   //Generate labels for our Time Bar based on the number of unique Months across the time series data
   function genTimeBarLabels () {


    if (timeSeriesGraphDataDay == null) return;
    const numDays = (timeSeriesGraphDataDay.length ? timeSeriesGraphDataDay.length : 0);

    if (numDays < 2) return;
    
    var tempLabels = []; 
    
    const dateA = moment(transactions[0].createdAt, "YYYY MM DDTHH mm ssZ");
    const dateB = moment(transactions[transactions.length-1].createdAt, "YYYY MM DDTHH mm ssZ");
    var numMonths = dateB.diff(dateA, "months");
    
    if (numMonths > 8) numMonths = 12; //Max at 8 across the Time Bar regardless of larger time span
    
    const numInnerMonths = numMonths - 1;
    if (DEBUG_MODE >= 2) console.log("Label calc total months", numMonths, numInnerMonths);
    
    // var tempIndex = Math.round(1 *numDays / 8);
    // var tempDate = moment(transactions[tempIndex].createdAt, "YYYY MM DDTHH mm ssZ");
    // var tempDateLabel = tempDate.format("MMM YY");
    // if (DEBUG_MODE >= 2) console.log("LABEL", tempIndex, tempDateLabel, transactions[tempIndex]);
    
    tempLabels[0] = timeSeriesGraphDataDay[0].xKey;

    if (numMonths > 0 && numInnerMonths > 0) {
      for (var j=0; j < numInnerMonths; j++) {
        
        //Graph label from date array
        if (DEBUG_MODE >= 2) console.log("Grabbing label from Time Series Graph", timeSeriesGraphDataDay);
        tempLabels.push(timeSeriesGraphDataDay[Math.round((j+1) * numDays / numMonths)].xKey);
      }
    }
    
    tempLabels.push(timeSeriesGraphDataDay[timeSeriesGraphDataDay.length-1].xKey);

    if (DEBUG_MODE >= 2) console.log("Generated Time Bar labels", tempLabels);

    setTimeBarLabels(tempLabels);
     
   }
   
   //
   //Animation Time Bar React component
   //
   
  const AnimationTimeBar = ({animationTimeBarSliderValue, setAnimationTimeBarSliderValue}) => {
   

    //Handlers and state for sliders; local with this React component
    const [localTimeBarSliderValue, setLocalTimeBarSliderValue] = useState(animationTimeBarSliderValue);  

    const updateTimeBarSliderValue = (e, data) => {

        if (DEBUG_MODE >= 2) console.log("EVENT", e);
        
        //Store locally AND update parent since this is a mouse UP event
        setLocalTimeBarSliderValue(data);
        
        if (DEBUG_MODE >= 2) console.log("Set Time Bar Value", data);
    };

    const updateLocalTimeBarSliderValue = (e, data) => {

        // if (DEBUG_MODE >= 2) console.log("EVENT", e);
        
        setLocalTimeBarSliderValue(data);
    };
    
  const timeBarLabelFormatter = (value) => {
    
    //Turn slider value into a timestamp label
    //Slider value will basically be a % value 1 to 100 as an index into the transactions
    
    // var index = Math.round (value * transactions.length / 100);

    //Safety checks    
    // if (index < 0 ) index = 0;
    // if (index > transactions.length -1) index = transactions.length -1;
    // const returnLabel = moment(transactions[index].createdAt, "YYYY MM DDTHH mm ssZ").format("D MMM YY");

    var returnLabel = "";    

    // if (DEBUG_MODE >= 2) console.log("Time bar rendering label based on this value", value);
    
    if (value > -1  && value < timeSeriesGraphDataDay.length ) returnLabel = timeSeriesGraphDataDay[value].xKey;

    // if (DEBUG_MODE >= 2) console.log("Time bar rendering label", value, returnLabel);

    return (
        <div>
          {returnLabel}
        </div>
      );
  };

    //Customizing the Slider: https://mui.com/customization/how-to-customize/    
    // https://mui.com/system/basics/#the-sx-prop
    return (
          <div className="AnimationTimeBar" >
              <div className="dashboardContainer boxShadow">
                  <Slider 
                    className="TimeBarStyle"
                    defaultValue={0} 
                    // step={step} 
                    // marks={true} 
                    min={0} 
                    max={maxDaysInTimeSeries-1}
                    name="TimeBarSlider"
                    onChange={updateLocalTimeBarSliderValue}
                    onChangeCommitted={updateTimeBarSliderValue}
                    value={localTimeBarSliderValue}
                     disabled
                    valueLabelDisplay="on"
                    valueLabelFormat={value => timeBarLabelFormatter(value)}
                    sx={{
                        height: 10,
                      }}                    
                                            
                  />
            {!showLabelsOnSlider  ? null : 
                <div className="ContainerNoHeightSpaceBetween" style={{width:"100%"}}>
                    {
                        timeBarLabels.map((label, index) => (
                          <div className="TimeBarLabel">
                            {label}
                          </div>
                        ))
                        
                    }
                </div>
            }
              </div>
          </div>
      );
  };  
  
  

   useEffect(() => {

     
     if (!startAnimationTimer) return;
     
    const timer = setTimeout(() => {
        
      //update state of graph
      updateAnimationGraphData();
   
        }, animationTransitionTime);  //Fire in defined milliseconds
        
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    });
  
 
  function updateAnimationGraphData(){

    //Out of bounds?  Reset and pause animation
    if (dateHeadPointer < 0 || dateHeadPointer >= maxDaysInTimeSeries-1) {
      setStartAnimationTimer (false);  //Stop the animation
      if (DEBUG_MODE >= 2) console.log("Stopping animation");
      return;
    }


    // We need to move the DATE HEAD Pointer 1 entry (day) along the DATE array to a new HEAD DATE
    // For this, we to move the TRANSACTION HEAD pointer N number of transactions until we reach the a transaction with the new HEAD DATE or we reach the end
    // In other words, we need to process all transaction until we reach the next date
    
    var newDateHeadPointer = dateHeadPointer;
    var newDateTailPointer = dateTailPointer;
    var tempAnimationData = animationGraphData;
    var changedAnimationDataFlag = false;

    
    if (dateHeadPointer < maxDaysInTimeSeries-1) { //Not yet at the last day?

        //Increase totals based on new DAY
        newDateHeadPointer = newDateHeadPointer + 1;  //Bump to next day
        updateTotalsForNewDateHead(newDateHeadPointer, tempAnimationData, changedAnimationDataFlag);
        
        //Decrease totals based on falling off DAY if using a sliding window
        if (useSlidingWindow) {

          const currentWindowSizeSetting = windowSizeInDaysOptions[windowSizeInDaysDropDownSelection.value].windowSizeInDays;

          //Have we reached the full window yet?
          // if (DEBUG_MODE >= 2) console.log("Determining whether to remove transactions", newDateHeadPointer, newDateTailPointer, currentWindowSizeSetting);
          if (newDateTailPointer < newDateHeadPointer - currentWindowSizeSetting) { 
            newDateTailPointer = newDateTailPointer + 1;  //Bump TAIL to next day
            updateTotalsForNewDateTail(newDateHeadPointer, newDateTailPointer, tempAnimationData, changedAnimationDataFlag);
          }
        }
    }

    if (changedAnimationDataFlag) {
      setAnimationGraphData(tempAnimationData);
    }
    

    //Update Time Bar Slider Head and Tail pointers across the range of 0 to 100
    
    // if (DEBUG_MODE >= 2) console.log("Sliding Window Settings", useSlidingWindow, animationTimeBarSliderValue);
    
    if (maxDaysInTimeSeries < 1) {
      setAnimationTimeBarSliderValue([0,0]);
    } else {

      //Update HEAD and TAIL values.  For now, move one DATE entry
  
      if (useSlidingWindow) {
          setAnimationTimeBarSliderValue([newDateTailPointer, newDateHeadPointer]);    //TWO state variables in Slider array
      } else {
          setAnimationTimeBarSliderValue(newDateHeadPointer);                          //ONE state variable in Slider array
      }
      
      // if (DEBUG_MODE >= 2) console.log("Updated time bar value", newDateHeadPointer,newDateTailPointer);
    }
    
    setDateHeadPointer(newDateHeadPointer);
    setDateTailPointer(newDateTailPointer);

  }

  function typeOfTransactionCurrentlyGraphing(tempTransactionHeadPointer) {
 
    if (timeSeriesGraphKey == "conectivities")  return(true);
    if (timeSeriesGraphKey == "balance" && transactions[tempTransactionHeadPointer].category =="STRESS") return true;
    if (timeSeriesGraphKey == "social" && transactions[tempTransactionHeadPointer].category =="SOCIAL") return true;
    if (timeSeriesGraphKey == "team" && transactions[tempTransactionHeadPointer].category =="TEAM") return true;
    if (timeSeriesGraphKey == "personal" && transactions[tempTransactionHeadPointer].category =="PERSONAL") return true;
    if (timeSeriesGraphKey == "DEI_badges" && transactions[tempTransactionHeadPointer].badgesDEI > 0) return true;
    if (timeSeriesGraphKey == "CS_badges" && transactions[tempTransactionHeadPointer].badgesCS > 0) return true;
    
    // if (DEBUG_MODE >= 2) console.log("TRANSACTION SKIPPED - DOES NOT MATCH CURRENT USER RADIO SELECTION", timeSeriesGraphKey, transactions[tempTransactionHeadPointer]);
    
    return false;
  }
  
  function updateTotalsForNewDateHead(newDateHeadPointer, tempAnimationData, changedAnimationDataFlag) {
    
    if (newDateHeadPointer <0 || newDateHeadPointer > maxDaysInTimeSeries-1) return; // No updates if out of bounds

    if (newDateHeadPointer >= maxDaysInTimeSeries -1) return; //No updates if the head pointer has already reached the end
    
    var newDateKey = timeSeriesGraphDataDay[newDateHeadPointer].xKey;
    const newDate = moment(newDateKey, "D-MMM-YY");

    // if (DEBUG_MODE >= 2) console.log("Adding transactions until", newDateKey, transactionHeadPointer, transactions);
    
    
    var validTransaction = true;
    var tempTransactionHeadPointer = transactionHeadPointer;  //Get our current index into the transaction

    while (validTransaction) {
        
        if (tempTransactionHeadPointer < transactions.length-1) {
        
          const transactionDate = moment(transactions[tempTransactionHeadPointer].createdAt, "YYYY MM DDTHH mm ssZ");
          
          if (transactionDate.isBefore(newDate)) {

            // if (DEBUG_MODE >= 2) console.log("Adding transaction", transactions[tempTransactionHeadPointer].userID, );

            if (typeOfTransactionCurrentlyGraphing(tempTransactionHeadPointer)) {
              
                  //Identify User associated with the transaction
                  var index = animationGraphData.findIndex(user => user.userID === transactions[tempTransactionHeadPointer].userID);
              
                  //Update animation graph data for that user
                  if (index > -1) {
                    tempAnimationData[index].value = tempAnimationData[index].value + 1;    //Increase count for this user
                    changedAnimationDataFlag = true;
                  }
            }

            tempTransactionHeadPointer++; //Move on to next transaction and process it

            
          } else {
            validTransaction = false; //Reached a transaction beyond our new date
          }  
            
        } else {
          validTransaction = false;   //Reached the end
        }
    }
    
    setTransactionHeadPointer(tempTransactionHeadPointer); //Now, move along our transaction pointer as we have processed these transaction
    
  } 

  function updateTotalsForNewDateTail(newDateHeadPointer, newDateTailPointer, tempAnimationData, changedAnimationDataFlag) {

    if (newDateTailPointer <0 || newDateTailPointer > maxDaysInTimeSeries-1) return;
    
    if (newDateHeadPointer >= maxDaysInTimeSeries -1) return; //No updates if the head pointer has already reached the end

    
    var newDateKey = timeSeriesGraphDataDay[newDateTailPointer].xKey;
    const newDate = moment(newDateKey, "D-MMM-YY");

    // if (DEBUG_MODE >= 2) console.log("Removing transactions until", newDateKey)
    
    
    var validTransaction = true;
    var tempTransactionTailPointer = transactionTailPointer;  //Get our current index into the transaction

    while (validTransaction) {
        
        if (tempTransactionTailPointer < transactions.length-1) {
        
          // if (DEBUG_MODE >= 2) console.log("Removing transaction", transactions[tempTransactionTailPointer]);
          
          const transactionDate = moment(transactions[tempTransactionTailPointer].createdAt, "YYYY MM DDTHH mm ssZ");
          
          if (transactionDate.isBefore(newDate)) {

            if (typeOfTransactionCurrentlyGraphing(tempTransactionTailPointer)) {
  
              //Identify User associated with the transaction
              var index = animationGraphData.findIndex(user => user.userID === transactions[tempTransactionTailPointer].userID);
          
              //Update animation graph data for that user to DECREMENT count
              if (index > -1) {
                tempAnimationData[index].value = tempAnimationData[index].value - 1;    //Decrease count for this user
                changedAnimationDataFlag = true;
              }
            }
            
            tempTransactionTailPointer++; //Move on to next transaction and process it

            
          } else {
            validTransaction = false; //Reached a transaction beyond our new date
          }  
            
        } else {
          validTransaction = false;   //Reached the end
        }
    }   

    setTransactionTailPointer (tempTransactionTailPointer); //Now, move along our transaction pointer as we have processed these transaction
    
  }
  
  
  const handleWindowSizeSelection = (eventKey) => {
    if (DEBUG_MODE >= 2) console.log("Window size selected", eventKey);
    initializeAnimation();
    setWindowSizeInDaysDropDownSelection(eventKey);
  };
  
  const handleSpeedSelection = (eventKey) => {
    if (DEBUG_MODE >= 2) console.log("Animation speed selected", eventKey);
    setAnimationSpeedDropDownSelection(eventKey);
    
    if (eventKey.value == 0) {
      setAnimationTransitionTime(2 * DEFAULT_TRANSITION_TIME);
      return;
    } else if (eventKey.value == 1) {
      setAnimationTransitionTime(DEFAULT_TRANSITION_TIME);
      return;
    } else if (eventKey.value == 2) {
      setAnimationTransitionTime(Math.round(2 * DEFAULT_TRANSITION_TIME / 3));
      return;
    } else if (eventKey.value == 3) {
      setAnimationTransitionTime(Math.round(0.5 * DEFAULT_TRANSITION_TIME));
      return;
    } else if (eventKey.value == 4) {
      setAnimationTransitionTime(Math.round(DEFAULT_TRANSITION_TIME /3 ));
      return;
    } else if (eventKey.value == 5) {
      setAnimationTransitionTime(Math.round( DEFAULT_TRANSITION_TIME / 5));
      return;
    } else if (eventKey.value == 6) {
      setAnimationTransitionTime(Math.round( DEFAULT_TRANSITION_TIME / 10));
      return;
    }
  };

  function handlePlay () {
    if (DEBUG_MODE >= 2) console.log("Playing Animation");
    setStartAnimationTimer(true);
  };

  function handleFirstPage () {
    if (DEBUG_MODE >= 2) console.log("Restarting Animation");
    initializeAnimation();

  };

  
  function handlePause () {
    if (DEBUG_MODE >= 2) console.log("Pausing Animation");
    setStartAnimationTimer(false);
  }   
  
  
    return (
      <div style={{paddingTop:"5px"}}>
        <center>

              <div className="ContainerNoHeightCenter" style={{padding:"20px"}}>

                  <div className="NetworkCanvasSizeWrapper" style={{justifyContent:"flex-start"}}>
                      <div className="NetworkCanvasSizeTitle">
                          Playback Speed
                      </div>
                      <div style={{width:"150px", padding:"20px 10px"}}>
                        <Select  
                              isMulti={false} name="statusDropdownEdit" 
                              // isDisabled={startAnimationTimer} 
                              options={animationSpeedOptions} 
                              isOptionDisabled={(option) => option.isdisabled}
                              onChange={handleSpeedSelection} 
                              value={animationSpeedDropDownSelection =="" ? "" : animationSpeedOptions[animationSpeedDropDownSelection.value]}  
                              placeholder=" - select -"
                          />  
                      </div>
                  </div>

                  <div className="NetworkCanvasSizeWrapper" style={{justifyContent:"flex-start"}}>
                      <div className="NetworkCanvasSizeTitle">
                          Sliding Window
                      </div>
                      <div style={{width:"150px", padding:"20px 10px 10px 10px"}}>
                        <Select  
                              isMulti={false} name="statusDropdownEdit" 
                              isDisabled={startAnimationTimer} 
                              options={windowSizeInDaysOptions} 
                              isOptionDisabled={(option) => option.isdisabled}
                              onChange={handleWindowSizeSelection} 
                              value={windowSizeInDaysDropDownSelection =="" ? "" : windowSizeInDaysOptions[windowSizeInDaysDropDownSelection.value]}  
                              placeholder=" - select -"
                          />  
                      </div>
                      <Switch checked={useSlidingWindow} onChange={handleSlidingWindowSwitch} disabled={startAnimationTimer}/>

                  </div>
  
               <div className="NetworkCanvasSizeWrapper" style={{flexDirection: "row", justifyContent:"space-around", alignItems:"center", width:"300px", height:"70px", padding: "0px"}}>
                  <div className="ContainerVertical">
                     <div style={{height:"10px", color:"red"}}>
                        {startAnimationTimer? null : "Paused"}
                     </div>                  
                    <div>  
                      <IconButton size="large" >
                        <FirstPageIcon onClick={handleFirstPage}/>
                      </IconButton>
                      <IconButton size="large" >
                       <PlayArrowIcon  onClick={handlePlay} />
                      </IconButton>
                      <IconButton size="large" >
                        <PauseIcon onClick={handlePause} />
                      </IconButton>
                    </div>
                  </div>
               </div>
            </div>

       {animationDataReady==false ? null : 
                <CCLineChart 
                  legend={false} 
                  yAxis={false} 
                  xAxis={true} 
                  data={timeSeriesGraphDataDay} 
                  title="" 
                  dataKeyX="xKey" 
                  dataKeyY1={timeSeriesGraphKey}
                  xTickAngle={-25}
                  xTickSize={28}
                  margin={{
                          top: 10,
                          right: 25,
                          left: 25,
                          bottom: 40,
                        }}              
                  grid={false} 
                  height={TIME_LINE_GRAPH_HEIGHT} 
                  width={TIME_LINE_GRAPH_WIDTH} 
                  toolTip={true}
                  // color1="#6495ED"
                  // backgroundColor="white"
                  // strokeXAxis="#6495ED"
                  color1="white"
                  backgroundColor={timeSeriesBackgroundColor}
                  strokeXAxis="white"
                  renderTooltip = {renderRechartTooltip}  //Render our own custom Tooltip
    
                />
      }
  
   {animationDataReady==false ? null : 

        <div style={{height:graphHeight, width:graphWidth}}  >
          <AnimationTimeBar animationTimeBarSliderValue={animationTimeBarSliderValue} setAnimationTimeBarSliderValue={setAnimationTimeBarSliderValue}/>
        </div>
   }
  
     </center>    
       
   {animationDataReady==false ? null : 

      
        <div style={{height:graphHeight, width:graphWidth}}  >
        
              <ChartRace
                data={animationGraphData}
                backgroundColor='white'
                width={1200}
                padding={12}
                itemHeight={24}
                gap={12}
                titleStyle={{ width:'250px', font: 'normal 400 13px Arial', color: '#409cf7' }}
                valueStyle={{ font: 'normal 600 11px Arial', color: 'rgba(0,0,0, 0.42)' }}
              />

            </div>
 
     }     
             
      </div>       
   
    );

};

export {ConectivityTimeSeriesAnimation};


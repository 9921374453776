//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//Styles
import '../styles/App.css';
import './authContext.css';

//Data
import  { CONECTERE_CONFIG_DATA, TEAM_COLOR, DEBUG_MODE, COLOR_WHITE, COLOR_BLUE_HEADER, LEGAL_PRIVACY_POLICY, LEGAL_TERMS_AND_CONDITIONS } from '../data/conectereConfigData';

// Advanced Auth Workflows  https://docs.amplify.aws/lib/auth/advanced/q/platform/js/
// For React, you can use the Authenticator component directly, or wrap your app in withAuthenticator Higher-Order Component
// Amplify HUB Documentation for pub / sub model for listenting to Amplify events https://docs.amplify.aws/lib/utilities/hub/q/platform/js/
// Auth events listener https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/

// https://aws.amazon.com/blogs/mobile/amplify-uis-new-authenticator-component-makes-it-easy-to-add-customizable-login-pages-to-your-react-angular-or-vue-app/

//  AWS Authenticator - may swap out complete as we take over the Auth flows including dedicated login / logout screens
// https://ui.docs.amplify.aws/react/connected-components/authenticator

import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
//Note - Redirect is no longer used https://stackabuse.com/redirects-in-react-router/
import { Navigate  } from "react-router-dom";  
import { Authenticator, AmplifyProvider, useTheme, View, Image, Text, Heading, Theme,  } from "@aws-amplify/ui-react";         //Import Amplify's REACT UI components
import '@aws-amplify/ui-react/styles.css';

//Amplify V6
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Hub } from "aws-amplify/utils";
import { generateClient } from 'aws-amplify/api';


// Redirection
// https://reactrouter.com/en/main/hooks/use-navigate
import { useNavigate } from "react-router-dom";

//Queries and Mutations
import { getUserByCognitoID, getCustomer} from '../graphql/queries';
import { updateUser }  from '../graphql/mutations';

//Context
import { DisplayContext } from './displayContext';            //User Authentication Context
import { ModalContext } from './modalContext';            

//Our Components
import ModalNoBackground from "../Components/modalNoBackground/modalNoBackground";
// import ModalNoBackgroundFixed from "../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";

//Utils
import {getCognitoSession, getCognitoAuthenticatedUser, getCognitoUserGroups, signOutUser } from "../utils/awsUtils";
import {getDataRecordById, invokeAPI, fetchUserByCognitoID, storeUpdatedUser} from "../utils/databaseUtils";
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';    

//Subscriptions
import { onUpdateCCWalletByUser, onUpdateConfigDataByCustomer } from "../graphql/subscriptions";
import { onUpdateUserProgressDataByUser } from "../graphql/subscriptions";

const API = generateClient();
const AuthContext = React.createContext();
const AuthenticatorProvider = ({ children }) => {

	// Context
	const { darkMode, toggleDarkMode, setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	// local state
	const [currentCognitoUser, setCurrentCognitoUser] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
   //  const [currentCustomer, setCurrentCustomer] = useState(null);
	const [isSuperAdmin,setIsSuperAdmin] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isBetaTester, setIsBetaTester] = useState(false);
	const [permissionAnalytics, setPermissionAnalytics] = useState(false);
	const [permissionLaunch, setPermissionLaunch] = useState(false);
	const [permissionEditor, setPermissionEditor] = useState(false);
	const [permissionOrders, setPermissionOrders] = useState(false);
	const [permissionBilling, setPermissionBilling] = useState(false);
	const [authState, setAuthState] = useState("initializing");

	//state variable for holding user wallet, also used by a lot of components
	const walletInitialState = {description: "", currentBalance: 0, badgesCS: 0, badgesDEI: 0, status: "CLOSED", nextSequenceNumber: 1};
	const [userWallet, setUserWallet] = useState(walletInitialState);
	
	// State variables for handling password reset and authentication messages
	const [showModalForgotPassword, setShowModalForgotPassword] = useState(false);
	const [showModalForgotPasswordSubmit, setShowModalForgotPasswordSubmit] = useState(false);
	const forgotPasswordInfoInitialState = {username:"", code:'', newPassword:"", verifyNewPassword:""};
	const [forgotPasswordInfo, setForgotPasswordInfo] = useState(forgotPasswordInfoInitialState);
	const [isResetingPassword, setIsResettingPassword] = useState(false);
	const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
	const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
	const [forgotPasswordError, setForgotPasswordError] = useState(false);
	const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState("");
	const [showModalForgotPasswordResult, setShowModalForgotPasswordResult] = useState(false);


	const navigate = useNavigate();
	const [redirectToPage,setRedirectToPage] = useState(null);

	//variable for causing AWS Login screen to be displayed
	// const [showSignInPopUp, setShowSignInPopUp] = useState (false);
	
	//Create an Ampllify listener to listen to Amplify Auth messages (events)
	//Use this to trigger responses to changes, especially to cause rendering of pages & the Header whent he user logs in

	const currentReactPage = useLocation(); //This React reference is updated every time the URL changes

	const [loggingInUser, setLoggingInUser] = useState(false);
	const [signoutTrigger,setSignoutTrigger] = useState(false);

	// Determine our platform
	const IS_MANAGMENT_PORTAL = (process.env ? process.env.IS_MANAGMENT_PORTAL : false);
 
	//React References
	const currentUserRef = useRef();
	const authStateRef = useRef();

/********************************************************* 
	AUTH STATE CHANGE HANDLER
	 State Machine for responding to login state changes
	 initializing  => loadingUserDB  => signedout => signedin
	 		
	 * signedin - FINAL LOGIN STATE - INDICATE THE ALL USER AND CUSTOMER CONTEXT DATA HAS BEEN FULLY LOADED

/********************************************************/
useEffect(() => {

		if (DEBUG_MODE >= 2) console.log("Auth Context AUTH CHANGE Use Effect", authState);
		
		//SPECIAL CASE - DO NOT HANDLE AUTH STATE CHANGES IF THE CURRENT PAGE IS SIGNUP-WIZARD
		if (currentReactPage.pathname.toUpperCase().includes("SIGNUPWIZARD")) {
			console.log("AuthState change while accessing SignUpWizard.  Skipping auto-login", authState);
			return;      //SignUp Wizard, do not process changes to authstate if on the Wizard; it will set the auth state as the user progresses; it will finish by redirecting to UserHome and triggering an authstate change
		}

		//STATE MACHINE - respond to changes in Auth state
		switch (authState) {
			case "signedout":
				setRedirectToPage("/userLogin"); //Jump to Login page		
				break;

			case "initializing":
			case "loadingUserDB":
				if (!loggingInUser) {
					attemptToLogInUser();     //User authenticated via Cognito or page load with session cache
				}
				break;
			case "signedin":
				if (currentReactPage.pathname.toUpperCase().includes("USERLOGIN")) {
					if (DEBUG_MODE) console.log("Auth state SIGN IN state change.  Redirecting from LOGIN screen to HOME");
					if (IS_MANAGMENT_PORTAL) setRedirectToPage("/conectereDashboard"); else setRedirectToPage("/userHome"); 	 
				}
				break;
			default:
				break;
		}
	
		// Master control of our Spinners during login
		if (authState !== 'signedin'  && authState !== 'signedout') setShowSpinner(true); 
		else setShowSpinner(false);
		
	},[authState]);


	//UseEffect for updating references and other state; fires every render
	useEffect(() => {
	  currentUserRef.current = currentUser; 			//React ref for the current user as its state data is used during useEffect functions
	  authStateRef.current = authState;     			//React ref for the auth state as used in HUB
	});   

	//Navigation UseEffect responsive to changes in redirectToPage
	//Call to 'navigate' should always be in a Hook
	useEffect(() => {
		if (redirectToPage && !currentReactPage.pathname.toUpperCase().includes("SIGNUPWIZARD")) 
		{
			navigate(redirectToPage); 			      
		}
	},[redirectToPage]); 

	/******************************************************************************
	 
		AWS COMMUNICATION HUBS
			- useEffect(s) to prevent memory leaks
			https://stackoverflow.com/questions/73671849/why-use-hub-listen-inside-useeffect-amplify-auth
		
	********************************************************************************/
	useEffect(() => {
		const unsubscribe = Hub.listen('auth', authListener);  //Add our AUTH listener
		return unsubscribe;
	}, []);
	
	useEffect(() => {
		const unsubscribe = Hub.listen('api', apiListener);;  //Add our API listener
		return unsubscribe;
	}, []);

	//AUTH Listener    
	const authListener = async (data) => {
		try {
			if (DEBUG_MODE >= 2) console.log("HUB:", data.payload.event, " Current Auth state:", (authState ? authState : null));
			// if (DEBUG_MODE >= 1) console.log("HUB:", data.payload.event, " Current Auth state REF:", (authStateRef ? authStateRef.current : null));
			// if (DEBUG_MODE >= 1) console.log ("HUB: Current path:",currentReactPage);

			switch (data.payload.event) {
				case 'signIn':
				case 'signedIn':
					//Transition to processing the signed in Cognito user if not already doing so
					if (authState !=='loadingUserDB') setAuthState('loadingUserDB'); 
					break;
				case 'signUp':
					//Event indicates that a user has been signed up.  Typically through our signup wizard or simply an Admin adding new users
					//If the user is not already signed in, the trigger a state update.
					//If signed in already then must be simply adding a DIFFERENT user, so no AuthState change for this user
					// if (!currentUser) setAuthState('signUp');          //Trigger a fetch on this page which will THEN set AuthState as a trigger to other pages after data from DB is loaded
					// if (DEBUG_MODE >= 2) console.log('HUB STATE:user sign up event', currentUser, authState, data.payload);
					break;
				case 'signOut':
				case 'signedOut':
					if (authState !=='signedout') setAuthState('signedout');
					break;
				case 'signIn_failure':
					if (DEBUG_MODE >= 1) console.log('HUB STATE:user sign in failed');
					break;
				case 'tokenRefresh':
					// if (DEBUG_MODE >= 2) console.log('HUB STATE:token refresh succeeded');
					break;
				case 'tokenRefresh_failure':
					if (DEBUG_MODE >= 2) console.log('HUB STATE:token refresh failed');
					break;
				case "signInWithRedirect":
					// getUser();
					if (DEBUG_MODE >= 2) console.log('HUB STATE: signInWithRedirect');
					break;
				case "signInWithRedirect_failure":
					if (DEBUG_MODE >= 2) console.log('HUB STATE: signInWithRedirect_failure');
					break;
				case "customOAuthState":
					// setCustomState(data.payload.data); // this is the customState provided on signInWithRedirect function
					if (DEBUG_MODE >= 2) console.log('HUB STATE: customOAuthState', data.payload.data);
					break;					
				case 'configured':
					// if (DEBUG_MODE >= 2) console.log('HUB STATE:the Auth module is configured');
					break;
				default:
					if (DEBUG_MODE >= 2) console.log('HUB STATE:unknown event', data.payload.event);		
			}
		} catch (err) {
			if (DEBUG_MODE >= 2) console.error('ERROR IN AUTH HUB:', err);		
		}
	};

	//AppSync API Listener    
	const apiListener = (data) => {
		if (DEBUG_MODE >= 3) console.log("HUB AppSync Listener:", data.payload);
		switch (data.payload.event) {
			case 'ConnectionStateChange':
				if (DEBUG_MODE >= 1) console.log('HUB AppSync:New Connection State', data.payload.data.connectionState);
				break;
		default:
			if (DEBUG_MODE >= 2) console.log('HUB AppSync - STATE:unknown event', data.payload.event);		
			
		}
	};
	
	//Callbacks for use in our other code modules
   const handleCancelForgotPassword = () => {
			setShowModalForgotPassword(false);
			setShowModalForgotPasswordResult(false);
			setShowModalForgotPasswordSubmit(false);
			setShowModalGeneral(false);
			setIsResettingPassword(false);
			setForgotPasswordSuccess(false);
			setForgotPasswordError(false);
			setForgotPasswordErrorMessage("");
			setForgotPasswordInfo(forgotPasswordInfoInitialState);
			if (DEBUG_MODE >= 2) console.log("Cancelled forgot password.");
	};

//
//  Amplify Forgot Password Process
//
// https://stackoverflow.com/questions/50573770/aws-amplify-forgot-password-function-with-custom-ui 
// https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#change-password

	function triggerForgotPasswordProcess(){
		setIsResettingPassword(true);
		setForgotPasswordInfo(forgotPasswordInfoInitialState);
		setShowModalForgotPassword(true); //pop-up Modal
	}
	
	async function handleInitiateForgotPassword (e) {
		e.preventDefault();
		setShowModalForgotPassword(false);
		setForgotPasswordSuccess(false);
		setForgotPasswordError(false);

		//Do we have user name to use?
		let tempUserName;
		//grab user name if logged in already
		if (currentCognitoUser && currentCognitoUser.username && currentCognitoUser.username.length > 0) {
			tempUserName = currentCognitoUser.username;
			if (DEBUG_MODE >= 2) console.log("Set username based on currentCognitoUser", tempUserName);

		} else if (forgotPasswordInfo && forgotPasswordInfo.username && forgotPasswordInfo.username.length > 0) {
			tempUserName = currentCognitoUser.username;
			if (DEBUG_MODE >= 2) console.log("Set username based on currentCognitoUser", tempUserName);
		} else {
			if (DEBUG_MODE >= 2) console.log("Error - no username; cannot reset password");
			setForgotPasswordErrorMessage("Error - incorrect user name");
			setIsResettingPassword(false);
			setForgotPasswordError(true);
			return;
		}
		
		//Start the process
		try {
			if (DEBUG_MODE >= 2) console.log("Starting password reset process.  Sending Code to:", tempUserName);

			// const result = await Auth.forgotPassword(tempUserName); - Amplify V5
			const result = await resetPassword({ username:tempUserName });

			if (DEBUG_MODE >= 2) console.log("Amplify forgot password process returned:", result);

		} catch (error) {
			if (DEBUG_MODE >= 2) console.log("Error initiating forgot password process", error);
			setForgotPasswordErrorMessage(error);
			setIsResettingPassword(false);
			setForgotPasswordError(true);
			setShowModalForgotPasswordResult(true);
			return;
		}        

		setShowModalForgotPasswordSubmit(true);
	}

	async function handleSubmitForgotPassword (e) {
		e.preventDefault();
				
		//grab user name if logged in already
		if (currentCognitoUser!=null) { 
			if (currentCognitoUser.username !="") forgotPasswordInfo.username = currentCognitoUser.username;
		}        
		
		setShowModalForgotPasswordSubmit(false);
		setIsResettingPassword(false);
		if (DEBUG_MODE >= 2) console.log("Submitting new password information.", currentUser, forgotPasswordInfo.username, forgotPasswordInfo.code, forgotPasswordInfo.newPassword );

		try {
			/* Amplify V5
		  await Auth.forgotPasswordSubmit(
			forgotPasswordInfo.username,
			forgotPasswordInfo.code,
			forgotPasswordInfo.newPassword
		  );
		  */

		  await confirmResetPassword({ username:forgotPasswordInfo.username, confirmationCode:forgotPasswordInfo.code, newPassword:forgotPasswordInfo.newPassword });

			setForgotPasswordSuccess(true);
		} catch (error) {
			if (DEBUG_MODE >= 2) console.log("Error submitting new password information. ", error);
			setForgotPasswordError(true);
			setForgotPasswordErrorMessage(error);
		}
		setShowModalForgotPasswordResult(true);
	}

	function verifyNewPasswordProper () {
		let returnVal = false;
		if ((forgotPasswordInfo.newPassword === forgotPasswordInfo.verifyNewPassword) && (forgotPasswordInfo.newPassword.length>7)) {
			returnVal = true;
		}
		if (DEBUG_MODE >= 2) console.log("verifying password info", forgotPasswordInfo.newPassword, forgotPasswordInfo.newPassword);
		return returnVal;
	}

	//Set up a subscription to receive configuration data updates in case the backend is updated by system Admin
	//For example, Admin may change the Ring Period of the Conectivities required to complete a given ring
	
	//Functions and state data for real-time updates
	const [configSubscriptionSetUp, setConfigSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [walletSubscriptionSetUp, setWalletSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	useEffect(() => {
		if (!configSubscriptionSetUp && authState === 'signedin'  && currentUser && true) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for customer config", currentUser.customer);
			
			const subscription = API.graphql({
				query:onUpdateConfigDataByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("New config message",messageData);
						if (messageData && messageData.value && messageData.value.data && messageData.data.onUpdateConfigDataByCustomer && messageData.data.onUpdateConfigDataByCustomer.customerID) {
							//Since the update may involve an update to the customer generally, get an updated full record
							if (DEBUG_MODE) console.log("Updating customerID", messageData.data.onUpdateConfigDataByCustomer.customerID);
							updateCurrentCustomer(messageData.data.onUpdateConfigDataByCustomer.customerID);
						 } else {
							if (DEBUG_MODE) console.error("Error - improper appsync config message", messageData);
						 }
						 
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing user config subscription message",messageData);
					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});

			setConfigSubscriptionSetUp(true);
			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setConfigSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for COMPANY CONFIG DATA");
			};
		   
		} 
	},[authState]);                     //Call function when a change to authState occurs

	
	const updateCurrentCustomer = async (customerID) => {
		//Safety check
		if (!customerID) return;

		try {
			const updatedCustomer =  await getDataRecordById(getCustomer, 'getCustomer',customerID);
			if (DEBUG_MODE >= 2) console.log("Fetched updated customer record",updatedCustomer);
			
			//Update system-wide state data with the config data for this user
			const tempCurrentUser = {...currentUser, customer:updatedCustomer}; 
			if (DEBUG_MODE >= 2) console.log("Updated customer && customer config details for current user", tempCurrentUser);
			setCurrentUser(tempCurrentUser);
		} catch (err) {
			if (DEBUG_MODE) console.error("Error updating customer. ", err);
		}
	}

	//Set up a subscription to receive wallet updates in case the backend is updated by a different window or a mobile App
	//Note, this will call us to receive data even for updates we do on our page but that should be ok
	//Other than b/w usage

	//Subscribe to changes to user's wallet; to be pushed in real-time by Appsync 
	useEffect(() => {
		if (!walletSubscriptionSetUp && authState === 'signedin'  && currentUser && true) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for user wallet", userWallet);
			
			const subscription = API.graphql({
				query:onUpdateCCWalletByUser,
				variables: {
						userID: currentUserRef.current.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("New wallet message",messageData);
						if (messageData.data.onUpdateCCWalletByUser !== null) {
							const tempUserWallet = {...messageData.data.onUpdateCCWalletByUser};
							if (DEBUG_MODE >= 2) console.log("New wallet extracted from message",tempUserWallet);
							setUserWallet(tempUserWallet);
						}
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing user wallet subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setWalletSubscriptionSetUp(true);

			//Important -  return function for execution on unmount for cleanup
		   return () => {
				subscription.unsubscribe();
				setWalletSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for WALLET");
			};
		}
	},[authState]); //Call function when a change to authState occurs


	//Set up a subscription to receive user progress data updates for this user in case the backend is updated by a different window or a mobile App
	//Note, this will call us to receive data even for updates we do on our page but that should be ok
	//Other than b/w usage
	
	//Functions and state data for real-time updates, including Progress Data and Invitations
	// const [progressDataInboundSubscriptionData, setProgressDataInboundSubscriptionData] = useState("");
	const [progressDataSubscriptionSetUp, setProgressDataSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [triggerFetchScheduledConectivities, setTriggerFetchScheduledConectivities] = useState(0);


	//Subscribe to changes to user's progress data; to be pushed in real-time by Appsync    
	useEffect(() => {
		if (!progressDataSubscriptionSetUp && authState === 'signedin'  && currentUser && true) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for user progress data for user", currentUserRef.current);
			
			const subscription = API.graphql({
				query:onUpdateUserProgressDataByUser,
				variables: {
						userID: currentUser.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("New progress data message",messageData);
						if (messageData.data.onUpdateUserProgressDataByUser !== null) {
							if (DEBUG_MODE >= 2) console.log("New progress data extracted from message",messageData.data.onUpdateUserProgressDataByUser);

							//First, update the CurrentUser state element
							const tempCurrentUser = {...currentUserRef.current, userProgressData:messageData.data.onUpdateUserProgressDataByUser};

							// if (DEBUG_MODE >= 2) console.log("Updated current user", tempCurrentUser);
							
							setCurrentUser(tempCurrentUser);
					
							const newValue = uuidv4();
							setTriggerFetchScheduledConectivities(newValue);          // Trigger a fetch of new connectivities based on useEffect Toggle  


						}
					} catch (err) {
						//if (DEBUG_MODE >= 2) console.log("Error processing user progress data subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setProgressDataSubscriptionSetUp(true);
			
		   return () => {
				subscription.unsubscribe();
				setProgressDataSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for PROGRESS DATA");
			};
		}
	}, [authState]);                            //Note, reload this whenever authState changes state!
	

	//
	//
	// END SUBSCRIPTIONS
	//
	//
	
	//This function is called when there is a change to Cognito AuthState by our Listener
	//The function tries to fetch the user into our DynamoDB framework
	//Note - this will be called upon page load, which will auto-log in the user from any cached Auth session info
	
	async function attemptToLogInUser() {

		//Close any modals
		setShowModalForgotPassword(false);
		setShowModalForgotPasswordResult(false);
		setShowModalForgotPasswordSubmit(false);
		setShowModalGeneral(false);		  
		setRedirectToPage(null); //Re-initialize the redirection page to null on each Auth change so the redirect re-renders on subsequent uses

		//Update lock
		setLoggingInUser(true);
		
		if (DEBUG_MODE >= 1) console.log("Attempting to log user into platform", authState);

		let userIsAuthenticated, tempUser;

		// Try to log in the user based on an existing authentication session; 
		// will throw an error if the user is not logged in, which is fine as it will pop-up the login screen 
		let tempCognitoUser;
		try {
			//Session info?
			const session = await getCognitoSession();
			if (!session || !session.tokens) {
				if (DEBUG_MODE) console.log("No cognito session detected.", session);
				setCurrentCognitoUser(null);
				setCurrentUser(null);
				setIsSuperAdmin(false);
				setIsAdmin(false);
				setLoggingInUser(false);
				setAuthState('signedout');
				await signOutUser();
				return;
			}
			//Session found
			if (DEBUG_MODE) console.log("Current session:", session);
			const tempGroups= getCognitoUserGroups(session);	
			tempCognitoUser = await getCognitoAuthenticatedUser();

			//using local vars in case we need them later in this function
			let tempIsAdmin = (tempGroups && tempGroups.includes('admin'));
			let tempIsSuperAdmin = (tempGroups && tempGroups.includes('superAdmin'));
			if (DEBUG_MODE >= 1) console.log("Current Cognito user =", JSON.stringify(tempCognitoUser));
			if (DEBUG_MODE >= 1) console.log("Current Cognito user ID = ", tempCognitoUser.userId);
			if (DEBUG_MODE >= 1) console.log("Current Cognito user groups =", tempGroups);
			if (DEBUG_MODE >= 1) console.log("isAdmin =", tempIsAdmin);
			if (DEBUG_MODE >= 1) console.log("isSuperAdmin", tempIsSuperAdmin);
			setIsAdmin(tempIsAdmin);
			setIsSuperAdmin(tempIsSuperAdmin);
			setCurrentCognitoUser(tempCognitoUser);          
			userIsAuthenticated = true;                 
		} catch (err) {
			setLoggingInUser(false);
			setAuthState('signedout');
			if (DEBUG_MODE >= 2) console.log("User not signed in. Redirecting user", err);
			//redirect to LOGIN page
			setRedirectToPage('/userLogin');
			return;
		}            

	   
	   //So, we must have fetched the authenticated Congnito info.  Grab corresponding Dynamodb user data
		try {
			//Confirm the Cognito User is carrying our custom attribute which specifies their dynamoDB record
			if (tempCognitoUser && tempCognitoUser.userId) {
			   //now grab the user information from dynamoDB, if it exists  
			   if (DEBUG_MODE >= 2) console.log("fetching user profile from DynamoDB for Cognito user:", tempCognitoUser.userId);
			   tempUser = await fetchUserByCognitoID({userCognitoID:tempCognitoUser.userId});
			   if (DEBUG_MODE >= 2) console.log("Fetched user data", tempUser);
				
			   //DynamoDB User record retrieved?
			   if (tempUser && tempUser.id) {
				  
				  const tempCustomer =  await getDataRecordById(getCustomer, "getCustomer", tempUser.customerID);
				  if (DEBUG_MODE >= 2) console.log("Fetched the customer record from DynamoDB:", tempCustomer);

				  if (tempCustomer) {
					//Insert the customer into our current user record since we no longer include it as part of every user
					tempUser.customer = tempCustomer; 
					let isBetaTester = (tempUser.customerID === '10a31f74-1b96-4c4a-95b3-bc25c4801f96' || tempUser.customerID === "80bf5d00-9302-4149-b377-baf33cc2d223" || tempUser.customerID === "4295d31f-01d0-43ee-bcb6-173a88d0a233");
					if (DEBUG_MODE) console.log("Set BETA TESTER", isBetaTester);
					setIsBetaTester(isBetaTester);
					setPermissionAnalytics(tempUser.permissionAnalytics ? true : false);
					setPermissionLaunch(tempUser.permissionLaunch ? true : false);
					setPermissionEditor(tempUser.permissionEditor ? true : false);
					setPermissionOrders(tempUser.permissionOrders ? true : false);
					setPermissionBilling(tempUser.permissionBilling ? true : false);
					setCurrentUser(tempUser);
					if (DEBUG_MODE) console.log("Set the current user", tempUser);

					//Update the display mode based on user preference
					if (tempUser && tempUser.displayTheme) {
						if (DEBUG_MODE) console.log("User display theme detected", tempUser.displayTheme);
							if (tempUser.displayTheme === 'DARK'  && !darkMode) {
								if (DEBUG_MODE) console.log("Toggling to Dark Mode");
								toggleDarkMode();
							}
						}
					
					//Set the user wallet
					setUserWallet(tempUser.ccWallet);
					// if (DEBUG_MODE >= 2) console.log(tempUser.ccWallet);
				  } else {
					if (DEBUG_MODE) console.error("Error - unable to fetch corresponding customer object", tempCustomer);
					setCurrentCognitoUser(null);
					setCurrentUser(null);
					setIsSuperAdmin(false);
					setIsAdmin(false);
					setLoggingInUser(false);
					setAuthState('signedout');
					setModalGeneralMessage("Error retrieving user information. Unable to retrieve user account.");
					setModalGeneralTitle("Ooops. Something went wrong.");
					setShowModalGeneral(true);   
					return;

				  }           
				} else {
					if (DEBUG_MODE >= 2) console.log("Error fetching user from DynamoDB - no USER", tempUser);
					setCurrentCognitoUser(null);
					setCurrentUser(null);
					setIsSuperAdmin(false);
					setIsAdmin(false);
					setLoggingInUser(false);
					setAuthState('signedout');
					setModalGeneralMessage("Error retrieving user information. Invalid user account.");
					setModalGeneralTitle("Ooops. Something went wrong.");
					setShowModalGeneral(true);   
					return;

				} //END Failed to fetch DynamoDB user record(s)
			} //END IF Cognito User has CognitoID
		} catch (err) {
			setCurrentCognitoUser(null);
			setCurrentUser(null);
			setIsSuperAdmin(false);
			setIsAdmin(false);
			setLoggingInUser(false);
			setAuthState('signedout');
			setModalGeneralMessage("Error retrieving user information. " + err);
			setModalGeneralTitle("Ooops. Something went wrong.");
			setShowModalGeneral(true);   
			if (DEBUG_MODE >= 2) console.log("Error fetching user", err);
			return;
		}


		//Double check
		//Were we successfull in loading the user from DB
		if (!userIsAuthenticated || !tempUser) {
			if (DEBUG_MODE >= 2) console.log("User not authenticated / No user profile");
			setLoggingInUser(false);
			setCurrentCognitoUser(null);
			setCurrentUser(null);
			setIsSuperAdmin(false);
			setIsAdmin(false);
			setAuthState('signedout');   
			setPermissionAnalytics(false);
			setPermissionLaunch(false);
			setPermissionEditor(false);
			await signOutUser(); 
			setModalGeneralMessage("Error retrieving user information. ");
			setModalGeneralTitle("Ooops. Something went wrong.");
			setShowModalGeneral(true);   
			return;  //Was not able to fetch the DynamoDB user profile; exit this useEffect routine
		}
		
		//Successfully logged in user and retrieved his/her dynamoDB Conectere records!
		
		//Grab the user's timezone and update the backend
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (DEBUG_MODE >= 2) console.log("Local TimeZone: " + timezone);   
		if (tempUser && tempUser.timeZoneCode && tempUser.timeZoneCode !== timezone) {
			let tempUserData = await storeUpdatedUser({id:tempUser.id, timeZoneCode:timezone});
			if (DEBUG_MODE >= 2) console.log('Successfully updated user timezone in DB', tempUserData);
		}

		setLoggingInUser(false);	
		
		/* INDICATE THE USER HAS BEEN AUTHENTICATED AND THAT THE CURRENT USER OBJECT HAS BEEN SET */
		setAuthState('userDataLoaded'); //Transition to next state
		if (DEBUG_MODE) console.log("AUTHENTICATED USER - Current date / time: " + moment().format('HH:mm:ss:SSS'));

	}

	//Callback to cause our Auth context to signout the user
	const ccAuthLogoutUser = useCallback( async () => {		
		try {
			if (DEBUG_MODE >= 2) console.log("Logout callback invoked");

			setAuthState('signedout')	//Update our state so we don't try to sign back in while we are signing out
			await signOutUser();   	//Make Cognito Call 
	
			if (DEBUG_MODE >= 2) console.log("Logged out the user.");           
			//Redirect the browser out of our React APP and to our corporate home page
			setRedirectToPage("/userLogin"); //Jump to home on Logout			
		} catch(err) {
			if (DEBUG_MODE >= 2) console.log("Error logging out user.");
			setModalGeneralMessage("Error logging out user. " + err);
			setModalGeneralTitle("Ooops. Something went wrong.");
			setShowModalGeneral(true);   
		}
		
	},[]);

  //return the provider
  return (
	<div>

		<ModalNoBackground showModal={showModalForgotPasswordResult ||showModalForgotPassword || showModalForgotPasswordSubmit} closeCallback={handleCancelForgotPassword} cardColor={TEAM_COLOR} closeButtonBackground={TEAM_COLOR} closeButtonColor={COLOR_WHITE}  >
			   <div className="modalNoBkgInnerCard" style={{minHeight:"250px"}}>
			   
					<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> <img  className="avatarImageCenterPortrait" style={{padding:'1rem'}} src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  /> </div>
 
					{!showModalForgotPassword ? null :
						<div className="ContainerVerticalSpaceAround">
							<h3 className="blue" >Reset Password?</h3>
							{authState != "signedin" ?
								<div className="ContainerVerticalCenter">
									<p className="textAlignCenter" style={{padding:"20px"}}> Enter your user name and press the button below to receive a code for reseting your password</p>
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div> User Name </div> 
											<input
												name="username"
												type="text"
												placeholder=""
												onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, username: event.target.value })}
												required
											/>
									</div>
								</div>
							 :
								<p className="textAlignCenter" style={{padding:"20px"}}> {!currentUser ? "" : "Press the button below to receive a verification code at " + currentUser.email   }</p>
			
							}
			
							<div className="modalNoBkgFooter flexEnd" style={{paddingTop:"clamp(4px,2vh,20px)"}}>
								<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="send code" onClick={handleInitiateForgotPassword} disabled={!isResetingPassword || (!currentUser && !forgotPasswordInfo.userName)}>Send Code</button>
								<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={handleCancelForgotPassword}> Cancel </button>
							</div>  
						</div>
					}
						
					{!showModalForgotPasswordResult ? null :
						<div className="ContainerVerticalSpaceAround" >
							<h2 className="blue" >{forgotPasswordSuccess ? "Success!" : "Oops. Something went wrong"}</h2>
							<p> {forgotPasswordSuccess ? "Your password has been reset" : "Unable to reset your password" + forgotPasswordErrorMessage}</p>
						</div>
					}                   
						
					{!showModalForgotPasswordSubmit  ? null :
						<div className="ContainerVerticalSpaceAround">
							<h3 className="blue" >Reset Password</h3>
							<p className="textAlignCenter" style={{paddingTop:"clamp(4px,2vh,20px)"}}>Check your email address {(currentUser && currentUser.email ? "at " + currentUser.email : null)} and enter the confirmation code.  Password must be at least 8 characters in length.</p>
							<div className="ContainerVerticalCenter">
							

								<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div> User Name </div> 
											<input
												name="username"
												type="text"
												onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, username: event.target.value })}
												required
											/>
									</div>
	
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div> Code </div> 
											<input
												name="code"
												type="text"
												onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, code: event.target.value })}
												required
											/>
									</div>
								</div>
								
								 <div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div>New password</div>
											<input
												name="newPassword"
												type="text"
												onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, newPassword: event.target.value })}
												required
											/>
									</div>
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div>Verify password</div>
											<input
												name="newPassword"
												type="text"
												onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, verifyNewPassword: event.target.value })}
												required
											/>
									</div>
								</div>
							 </div>
							<div className="modalNoBkgFooter flexEnd" style={{paddingTop:"clamp(4px,2vh,20px)"}}>
								<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="submit password" onClick={handleSubmitForgotPassword} disabled={!isResetingPassword  || ! verifyNewPasswordProper()}>Submit</button>
								<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={handleCancelForgotPassword}> Cancel </button>
							</div> 
						</div>
					}                   
			</div>
		 </ModalNoBackground>

		 
		<AuthContext.Provider value={
		{   
			authState, setAuthState,
			currentCognitoUser, setCurrentCognitoUser,
			currentUser, setCurrentUser,
			isSuperAdmin, setIsSuperAdmin,
			isAdmin, setIsAdmin,
			isBetaTester,
			permissionAnalytics,
			permissionLaunch,
			permissionEditor,
			permissionBilling,
			permissionOrders,
			userWallet, setUserWallet,
			ccAuthLogoutUser, 
			triggerForgotPasswordProcess,
			triggerFetchScheduledConectivities, setTriggerFetchScheduledConectivities, progressDataSubscriptionSetUp,      //NEW - Progress Data Subscription data; updates progress data in current user
		}}>
		  {children}
		</AuthContext.Provider>
	</div>
  ); 	
};

export { AuthContext, AuthenticatorProvider };

/* 
		 <Modal show={showModalForgotPassword && false} onHide={handleCancelForgotPassword} size="md" className="defaultBootstrapModal">
			<Modal.Header  closeButton>
			  <Modal.Title >Reset Password?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <center><form id='userForm' >
					<table className="formStyleMaxWidth">
					<tbody>
						<tr >
							<td className="formDataStyle">
								{!currentUser ? "" : "Press the button below to receive a code at " + currentUser.email + " for reseting your password."  }
							</td>
						</tr>
						<tr >

							{currentUser ? "" :

							<td className="formDataStyle">

								<input
									name="username"
									type="text"
									placeholder="user name"
									onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, username: event.target.value })}
									required
								/>
							</td>
							}
						</tr>
						
						</tbody>
						</table>
			  </form></center>
			</Modal.Body>
			<Modal.Footer>
					<Button id='submit' variant="primary"  active={isResetingPassword} onClick={isResetingPassword ? handleInitiateForgotPassword : null} disabled={!isResetingPassword}>Send Code</Button>{' '} 
					 <Button variant="secondary" onClick={handleCancelForgotPassword}> Cancel </Button>
			</Modal.Footer>
		 </Modal>     
		 
	   
		  <Modal show={showModalForgotPasswordResult} onHide={handleCancelForgotPassword} size="md" className="defaultBootstrapModal">
			<Modal.Header  closeButton>
			  <Modal.Title >Reset Password</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <center><form id='userForm' >
					<table className="formStyleMaxWidth">
					<tbody>
						<tr >
							<td className="formDataStyle">
							{forgotPasswordSuccess ? "Success!  Your password has been reset." : ""}
							{forgotPasswordError ? "Unable to reset your password.  " + forgotPasswordErrorMessage : ""}
							</td>
						</tr>
						</tbody>
						</table>
			  </form></center>
			</Modal.Body>
			<Modal.Footer>
					 <Button variant="secondary" onClick={handleCancelForgotPassword}> Close </Button>
			</Modal.Footer>
		 </Modal>     


	   

		   <Modal show={showModalForgotPasswordSubmit} onHide={handleCancelForgotPassword} size="md" className="defaultBootstrapModal">
			<Modal.Header closeButton>
			  <Modal.Title >Reset Password</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <center><form id='userForm' >
					<table className="formStyleMaxWidth">
					<tbody>
						<tr >
							{!currentUser ? "" : <td colspan='2' className="formDataStyle">Check your email address at {currentUser.email} and enter the confirmation code.  Password must be at least 8 characters in length.</td>}
						</tr>
						<tr >
							<td className="popEditFormHeaderStyle">Code</td>
							<td className="formDataStyle">
								<input
									name="code"
									type="text"
									placeholder="code"
									onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, code: event.target.value })}
									required
								/>
							</td>
						</tr>
						<tr >
							<td className="popEditFormHeaderStyle">New password</td>
							<td className="formDataStyle">
								<input
									name="newPassword"
									type="text"
									placeholder="new pwd"
									onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, newPassword: event.target.value })}
									required
								/>
							</td>
						</tr>
						<tr >
							<td className="popEditFormHeaderStyle">Verify password</td>
							<td className="formDataStyle">
								<input
									name="newPassword"
									type="text"
									placeholder="verify"
									onChange={event => setForgotPasswordInfo({ ...forgotPasswordInfo, verifyNewPassword: event.target.value })}
									required
								/>
							</td>
						</tr>

						</tbody>
						</table>
			  </form></center>
			</Modal.Body>
			<Modal.Footer>
					<Button id='submit' variant="primary"  active={isResetingPassword && verifyNewPasswordProper()} onClick={isResetingPassword ? handleSubmitForgotPassword : null} disabled={!isResetingPassword  || ! verifyNewPasswordProper()}>Submit</Button>{' '} 
					 <Button variant="secondary" onClick={handleCancelForgotPassword}> Cancel </Button>
			</Modal.Footer>
		 </Modal>            
 */ 
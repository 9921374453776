//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER,  COLOR_BLUE_TEXT, ENABLE_30_PERCENT_DISCOUNT } from '../../data/conectereConfigData';
import "./product.css";

//Amplify, React
import React from 'react';

//Utils
import { displayAwards, truncateStringWithDots} from "../../utils/generalUtils";

//React component
const Product = ({product, handleOrderCallback, isLargeCard, isDetailedView, dollarToCoinConversion, displayCustomer}) => {


    if (!product) return null;
    
    const descriptionLength = (isDetailedView ? 500 : 160);
    const titleLength = (isDetailedView ? 200 : 44);

    //Function to invoke CallBack
    const handleProductClick = (e) => {     
        if (DEBUG_MODE >= 2) console.log("Product clicked", e, product);       
        if (handleOrderCallback)  handleOrderCallback(product);
    };
    
    //Recompute the coins needed based on any company-specific coin value set by the admin
    //Basically, this overrides the pre-computed coins
    let productPrice = 0;
    if (product.dollarCost) {
      productPrice= (dollarToCoinConversion ? dollarToCoinConversion*product.dollarCost : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION*product.dollarCost);
    }
   
   //Any Special Events that discount our coins?
   if (ENABLE_30_PERCENT_DISCOUNT) {
      productPrice = Math.floor(productPrice * 0.70);
      console.log("30% discount enabled and applied.  New price:", productPrice);
   }
      
    return (
        <div  className={`${isDetailedView ? "productCardDetailedView" : "productCard"} ${isLargeCard ? "productCardLarge" : ""} `} onClick={handleProductClick}>
          <div className="productBody">
                <div className="ContainerNoHeightCenter fullWidth imageContainerNoRadius">
                        <img src={product.image} alt={''} className={isLargeCard || isDetailedView ? "ccImgXXXXXXL" : "ccImgXXXXXL"} /> 
                </div>
    
                {isLargeCard || isDetailedView ?
                    <>
                        <div className="TextStyle5 grey bold productHeader">
                            {product.title}
                        </div>
                        <div className={(isDetailedView ? "TextStyle4" : "TextStyle3") + " productDescription grey "} > 
                            {truncateStringWithDots(product.description, descriptionLength)}
                            {(product.externalLink && isDetailedView) ? 
                                <a href={product.externalLink} target="_blank" style={{paddingLeft:"4px", fontSize:"1rem"}} rel="noreferrer" >  (more details)  </a>              
                                :  "" 
                            }
                        </div> 
                    </>                    
                    :
                    <div className="productHeader">
                        {truncateStringWithDots(product.title,titleLength)}
                    </div>
                }
                <div className={`ContainerNoHeightFlexLeft productCostRow`}>
                   {displayAwards({enableCountUp:false, fontSize:'lg', coins:productPrice, badgesDEI:product.badgesDEI, badgesCS:product.badgesCS,  badgesSpotlight:product.badgesSpotlight})}
                </div>     

                     {displayCustomer && product.customerName && !product.managedByLogo ?  
                        <div className="productCustomerNameRow" > 
                            <div style={{borderBottom:"1px solid #6e6e6f"}}><i>Customer </i></div>
                            <div>{product.managedByName}</div>
                        </div> 
                        :
                        ""
                     }
                     

                    {displayCustomer && product.managedByLogo  ? 
                        <div className="productCustomerNameRow" > 
                            <div style={{borderBottom:"1px solid #6e6e6f"}}><i>Customer </i></div>
                            <div>{product.managedByName}</div>
                        </div> 
                        :
                        ""
                     }                         

          </div >
        </div>
    );

};


export default Product;


//Code I saved
//                     {!product.featured ? "" : <img className="ccImg" src={process.env.PUBLIC_URL+'/img/featured.png'}height='24' /> }

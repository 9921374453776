/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "29c9b66fd8e340159d21442b2b5bd66b",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "29c9b66fd8e340159d21442b2b5bd66b",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "29c9b66fd8e340159d21442b2b5bd66b",
                "region": "us-east-1"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://a4qajeqaojdunii5vvqm4jwwqa.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bfwsmzjcprcgdmjv5ughdhyiny",
    "aws_cognito_identity_pool_id": "us-east-2:412fd59a-c7a6-4908-8fcb-5c0ec3977319",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_iVY0ObuEO",
    "aws_user_pools_web_client_id": "6jkdg4ajki6l1q394vnb0729s2",
    "oauth": {
        "domain": "cc07d314f669-d314f669-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "cc07://,cc07://",
        "redirectSignOut": "cc07://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cc075b7f325156a848e3bf1d0e0bd9c2ecc2220825-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright CONECTERE
//


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR, USER_INITIAL_STATE, } from '../../data/conectereConfigData'; 

//React & Amplify
import React, { useEffect, useState, useContext, useRef  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context
import { ModalContext } from '../../context/modalContext';            

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import { FileUploader } from  "../../Components/fileUpload/fileUpload";
import Switch from '@mui/material/Switch';
import Select from 'react-select';   //Version with support from multiple selections
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Icons
import { DeleteOutline } from '@material-ui/icons';


//Utils
import {  HelpButton, validImageFileType  } from "../../utils/generalUtils";
import { TOMORROW, TIME_ZONE_OPTIONS, DisplayTimeZoneSelectComponent } from "../../utils/dateTimeUtils";
import { setHeaderColorByStringLength,  } from "../../utils/conectivityUtils";
import { getUserName, getUserInitials, DisplayUserSelectComponent, DisplayTeamSelectComponent, invokeCreateUser, invokeDeleteUser, invokeUpdateUser } from "../../utils/userAndTeamUtils";
import { fetchConfigByCustomer } from "../../utils/databaseUtils";
import { deleteImageFile, storeImageFile }  from "../../utils/storageUtils";
import moment from 'moment';


//Our components
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import ModalGeneral from "../../Components/modalGeneral/modalGeneral";

const UserEditor = React.memo(({ showEditor, isAdd, isEdit, isDelete, user, handleCloseEditor, }) => {

	// Context
	const { currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);    // Authentication context
	const { users, teams, usersOptions, teamsOptions, customerOptions, selectedCustomerOptions,setSelectedCustomerOptions } = useContext(CustomerContext);  					// Customer context
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	//Edit options

	//Local state data
	const [imageUploaded, setImageUploaded] = useState(null);  //holds an internal URL generated for  the image file just uploaded into the browser by the user
	const [imageFileUploaded, setImageFileUploaded] = useState(null);
	const [newImageUploaded, setNewImageUploaded] = useState(false);                //state data that shows a change to the avatar image has been made during edit, including just removing the original
	const [isUpdatingUser, setIsUpdatingUser] = useState(false);  //Used as a state variable set to TRUE when updating the backend
	const [userToEdit, setUserToEdit] = useState(USER_INITIAL_STATE);              //Used to hold a user that is to be edited
	const [userToEditOriginalAvatarURL, setUserToEditOriginalAvatarURL] = useState(null);
	const [selectedMentorOption, setSelectedMentorOption] = useState(null);
	const [selectedSponsorOption, setSelectedSponsorOption] = useState(null);
	const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);
	const [selectedTimeZoneCode, setSelectedTimeZoneCode] = useState(null);
	const [selectedTeamsOptions, setSelectedTeamsOptions] = useState(null);
	const [startDateMom, setStartDateMom] = useState(null);  //Default    

	 useEffect (() => {
		//Safety check
		if (!currentUser || (!isSuperAdmin && !isAdmin)) return;

		//Showing editor?
		if (!showEditor) return;

		//Init state params based on operation to perform
		if (isAdd) initializeAdd();
		else if (user && (isEdit || isDelete)) initializeEdit({userToEdit:user, deleteFlag:isDelete});
		else if (DEBUG_MODE) console.error("Error - seems to be improper params to show the user editor", user, isAdd, isEdit, isDelete);
	},[showEditor, isAdd, isEdit, isDelete]);
	 
	function initializeAdd() {
		if (DEBUG_MODE >= 2) console.log("Editor Add: initializing new user");
		// Set default selection for TimeZoneCode based on TZ of the current user (Admin)
		setSelectedTimeZoneCode(currentUser.timeZoneCode ? TIME_ZONE_OPTIONS.find(tz=>tz.value === currentUser.timeZoneCode) : TIME_ZONE_OPTIONS.find(tz=>tz.value ==='America/New_York'));
		setStartDateMom(TOMORROW()); 
		setImageUploaded(null);
		setImageFileUploaded(null);     //clear the file itself
		setIsUpdatingUser(true); //disable buttons on main page
		setUserToEdit(USER_INITIAL_STATE);
		setSelectedMentorOption(null);
		setSelectedSponsorOption(null);
		setSelectedSupervisorOption(null);
		setSelectedTeamsOptions(null);
		setShowModalGeneral(false);  
	 }

	 function initializeEdit({userToEdit, deleteFlag}) {
		//Safety check
		if (!userToEdit) {
			console.error("Error cannot initialize user to edit", userToEdit);
			return;
		}
		if (DEBUG_MODE >= 2) console.log("Editor: initializing user to edit", userToEdit);	 
		setIsUpdatingUser(true);                    //disable buttons on main page
		setImageUploaded(userToEdit.avatarUrl);     //Grab the S3 URL if present
		setNewImageUploaded(false);                 //No new image yet - Original user profile pic
		setUserToEdit(userToEdit); //Store the passed in object into state data variable to record object being edit


		//Set the TZ selection
		setSelectedTimeZoneCode(userToEdit.timeZoneCode ? TIME_ZONE_OPTIONS.find(tz=>tz.value===userToEdit.timeZoneCode) : TIME_ZONE_OPTIONS.find(tz=>tz.value==='America/New_York'));

		//Check if the user is on a team and, if so, buid the selectedOptions in the dropdown, i.e., the current value
		var tempSetSelectedTeamsOptions = [];
		if (userToEdit.teams.items.length > 0) {
			for (var j = 0; j < teamsOptions.length; j++){  //Walk the full dropdown
				// if (DEBUG_MODE >= 2) console.log("Checking userToEdit.teams.items", userToEdit.teams.items);
				// if (DEBUG_MODE >= 2) console.log("contains:", teamsOptions[j].id);
				if (userToEdit.teams.items.some(teamUserJoinRecord => teamUserJoinRecord.teamID === teamsOptions[j].id)) { // Is this team element in the overall dropdown somewhere in the TeamsUserJoin object array of the user?
					tempSetSelectedTeamsOptions.push(teamsOptions[j]);  //Add team to inital selections 
					// console.log ("Pushed team onto selections:", teamsOptions[j].label);
				}
			}
		}

		setSelectedTeamsOptions (tempSetSelectedTeamsOptions);  //Update state data for multi-select; clear if none

		 //Pre-load Mentor / Sponsor / Supervisor Selections - find will return null if no match
		if (userToEdit.mentorID) setSelectedMentorOption(usersOptions.find ( option => option.id == userToEdit.mentorID));   
		if (userToEdit.sponsorID) setSelectedSponsorOption(usersOptions.find ( option => option.id == userToEdit.sponsorID));  
		if (userToEdit.supervisorID) setSelectedSupervisorOption(usersOptions.find ( option => option.id == userToEdit.supervisorID));  

		//Pre-load Customer Selection based on the USER being edited if Super Admin viewing ALL customers
		setSelectedCustomerOptions(customerOptions.find ( option => option.id == userToEdit.customerID));
		
		//INIT start date
		if (userToEdit.startDate) setStartDateMom(moment(userToEdit.startDate));
		else setStartDateMom(null);
	 }

	 const handleImageUpload = (e) => {
		const file = e.target.files[0];  //grab the first file in the list of files selected by the user
 
		if (validImageFileType(file)) {
		  try {               
			 const localURL = URL.createObjectURL(file);                   
			 console.log ("URL to local file in web browser:", localURL);                  
			 setImageUploaded(localURL);  //update 'product' so as to display a preview to the user
			 setImageFileUploaded(file);  //save file for uploading to S3 bucket if the user hits the ADD button       
			 console.log ("Successfully generated internal URL and stored FILE locally");
			 setNewImageUploaded(true);        //set flag that state has changed
 
		  } catch (err) {
			 if (DEBUG_MODE >= 2) console.log("Error locally processing selected product", err);
		  }
		}
		else  if (DEBUG_MODE >= 2) console.log("Error, wrong file type", file.type);
	 };
	 
	 //Function to handle user removing the image
	 const handleClearImage = () => {
		setNewImageUploaded(true);          //Record this event for use if the user saves the changes
		setImageUploaded("");               //Clear the URL as if we don't have a file yet
		setImageFileUploaded("");           //clear file to be uploaded to S3 bucket 
		setUserToEditOriginalAvatarURL(userToEdit.avatarUrl);   //cache the original URL in case we need to remove the file
		setUserToEdit( {  ...userToEdit, avatarUrl: "", avatarWidth: 0, avatarHeight: 0, } ); //Clear the URL and H/W
		if (DEBUG_MODE >= 2) console.log("User Removed the existing avatar");
	 }; 
 
	 //Edit Functions 
	 async function handleConfirmationEdit () {
		 if (DEBUG_MODE >= 2) console.log("Processing EDIT confirmation", userToEdit);
		 setShowSpinner(true); //Show spinners
		 let result, tempUrl="", fileUploaded = false, avatarWidth = 0, avatarHeight = 0;
		 try {
			 //Was a change made to the avatar URL?            
			 if (userToEditOriginalAvatarURL !== userToEdit.avatarUrl) {
				 try {
					//Was there an original avatar? If so, remove it 
					if (userToEditOriginalAvatarURL && userToEditOriginalAvatarURL.length > 0) {
					  await deleteImageFile(userToEdit.avatarUrl); 
					  if (DEBUG_MODE >= 2) console.log('Deleted original avatar image ', userToEdit.avatarUrl);
					}
 
					//New avatar image to upload?
					if (imageFileUploaded) {
					  const {successFlag, url} = await storeImageFile(imageFileUploaded);
					  if (successFlag) {
							 fileUploaded = true; tempUrl = url; if (DEBUG_MODE > 1) console.log ("Successfully wrote user's avatar image to S3 ", tempUrl);
					  } else {
							 fileUploaded = false;  if (DEBUG_MODE > 0) console.error ("ERROR - failed avatar image to S3");                  
					  }                   
					  var img = document.getElementById('uploadedImagedID'); 
					  avatarWidth = img.naturalWidth; avatarHeight = img.naturalHeight;
					  if (DEBUG_MODE >= 2) console.log("Image - width", avatarWidth, "height:", avatarHeight);                   
					}  else if (DEBUG_MODE > 1) console.log ("User removed original avatar but did not upload a new one");               
				 } catch (err) {
					 if (DEBUG_MODE >= 2) console.log("Error writing avatar image file to S3", err);
					 setImageUploaded(null); //clear the URL to the uploaded image
					 setImageFileUploaded(null);  //clear the file itself
					 setIsUpdatingUser(false); //enable buttons on main page
					 showModalEdit(false);
					 setNewImageUploaded(false); 
					 setSelectedTeamsOptions (null);
					 // setSelectedCustomerOptions(null);
					 setUserToEdit(USER_INITIAL_STATE);
					 setShowSpinner(false); //Hide spinners
					 setModalGeneralMessage("Could not edit user.  ", err);
					 setModalGeneralTitle("Ooops!");                    
					 setShowModalGeneral(true);   
					 return;
				 }
			 } else if (DEBUG_MODE > 1) console.log ("User made no change to the avatar");
 
			 //OK, we are now ready to update the user records.  Invoke backend
			 result = await invokeUpdateUser({
				 cmdIssuerID:currentUser.id,
				 userInputData: userToEdit,       //data object of user being edited
				 teams:teams,            //Pointer to our overall Teams array
				 avatarHeight: (newImageUploaded ? avatarHeight : userToEdit.avatarHeight),
				 avatarWidth: (newImageUploaded ? avatarWidth : userToEdit.avatarWidth),
				 avatarUrl: (newImageUploaded ? tempUrl : userToEdit.avatarUrl),
				 selectedTeamsOptions:selectedTeamsOptions,
				 mentorID:  selectedMentorOption ? selectedMentorOption.id : "",
				 sponsorID:  selectedSponsorOption ? selectedSponsorOption.id : "",
				 supervisorID:  selectedSupervisorOption ? selectedSupervisorOption.id : "",
				 startDate: (startDateMom ? startDateMom.toISOString() : null),
				 canBeMentor: userToEdit.canBeMentor ? true : false,          // User can be selected as a Mentor
				 canBeSponsor: userToEdit.canBeSponsor ? true : false,        // User can be selected as a Sponsor
				 canBeSupervisor:userToEdit.canBeSupervisor ? true : false,   // User can be selected as a Supervisor
				 isUnderrepresentedGroup:userToEdit.isUnderrepresentedGroup ? true : false,   // User can be selected as a protege in the Sponsorship Program
				 timeZoneCode:(selectedTimeZoneCode ? selectedTimeZoneCode.value : 'America/New_York'),
			 });
  
			 //User updated?
			 if (result && result.successFlag) {
				 setUserToEdit(USER_INITIAL_STATE);
				 setModalGeneralMessage("Saved updated user");
				 setModalGeneralTitle("Success!");
			 } else {
				 setModalGeneralMessage("Could not edit user.");
				 setModalGeneralTitle("Ooops!");
			 }
		 } catch (err) {
			 if (DEBUG_MODE >= 2) console.log('error editing user:', err);
			 // Delete the new avatar file that was successfully uploaded to s3 since the EDIT failed
			 if (fileUploaded) await deleteImageFile(tempUrl);
			 setModalGeneralMessage("Could not edit user.  ", err);
			 setModalGeneralTitle("Ooops!");
		 }
 
		 setNewImageUploaded(false); 
		 setSelectedTeamsOptions (null);
		 setIsUpdatingUser(false); //enable buttons on main page
		 setShowSpinner(false); //Hide spinners
		 setShowModalGeneral(true);   
		 handleCloseEditor(result);
	 }
	
	 
	 ///Delete Functions
	 async function handleConfirmationDelete () {
		 
		 //Safety check
		 if (!userToEdit || !userToEdit.id) {
			 if (DEBUG_MODE >= 1) console.log('Error - no user to delete', userToEdit);
			 setModalGeneralMessage("Could not delete user.");
			 setModalGeneralTitle("Ooops!");
			 setShowModalGeneral(true);   
			 return;
		 }
		 
		 if (DEBUG_MODE >= 2) console.log("Processing DELETE confirmation", userToEdit);
		 setShowSpinner(true); //Show spinners
 
		 let successFlag;
		 try {
			 
			successFlag = await invokeDeleteUser({userToDelete:userToEdit, cmdIssuerID:currentUser.id});
 
			 if (successFlag) {
				 setModalGeneralMessage("User account deleted");
				 setModalGeneralTitle("Success!");
			 } else {
				 setModalGeneralMessage("Could not delete user account");
				 setModalGeneralTitle("Ooops!");
			 }
			 
			 // if (DEBUG_MODE >= 2) console.log('Successfully deleted user', userToEdit);
			 //NOTE - DELETE THE COGNITO USER IS HANDLED BY THE DeleteUserLambda Function which is triggered by a DELETE mutation on the user table!
			 
		 } catch (err) {
			 if (DEBUG_MODE >= 2) console.log('error deleting user:', err);
				 setModalGeneralMessage("Could not delete user.  ", err);
				 setModalGeneralTitle("Ooops!");
		 }
		 
		 setUserToEdit(USER_INITIAL_STATE);
		 setIsUpdatingUser(false);       //enable buttons on main page
		 setShowSpinner(false);          //Hide spinners
		 setShowModalGeneral(true);   
		 handleCloseEditor({successFlag:successFlag});
	 }
	 
 
	 //define function to handle submitButton, which is used to create a new user
	 async function handleSubmit (e)  {
		e.preventDefault();         
		let result;
		try {

			//Safety check
			if (!selectedCustomerOptions || !selectedCustomerOptions.id) {
			if (DEBUG_MODE) console.error("Error - no customer selected for which to create the user", selectedCustomerOptions);
			setIsUpdatingUser(false); //enable buttons on main page
			setSelectedTeamsOptions (null);
			setModalGeneralMessage("Could not create the user account.  Please select a customer.");
			setModalGeneralTitle("Ooops!  Incomplete information.");
			setShowModalGeneral(true);  		
			}
			setIsUpdatingUser(true);    //disable buttons on main page
			if (DEBUG_MODE >= 2) console.log('Called handler to create new user - top', userToEdit);

			var tempCustomerID ="";
			var tempCustomerConfigDetails;


			tempCustomerID = selectedCustomerOptions.id;   
			if (DEBUG_MODE >= 2) console.log("ID of customer selected in dropdown", tempCustomerID);

			//Grab THAT customer's config details
			tempCustomerConfigDetails = await fetchConfigByCustomer(tempCustomerID);
			if (DEBUG_MODE >= 2) console.log("Fetched that company's config details", tempCustomerConfigDetails);


			//Required fields for users
			if (!userToEdit.firstName || !userToEdit.lastName || !userToEdit.email || !tempCustomerConfigDetails || !tempCustomerConfigDetails.id) {
			if (DEBUG_MODE >= 2) console.log('error creating user, incomplete fields', userToEdit);
			if (DEBUG_MODE >= 2) console.log('firstName:', userToEdit.firstName);
			if (DEBUG_MODE >= 2) console.log('lastName:', userToEdit.lastName); 
			if (DEBUG_MODE >= 2) console.log('email:', userToEdit.email); 
			if (DEBUG_MODE >= 2) console.log('customer config details:', tempCustomerConfigDetails); 
			setIsUpdatingUser(false); //enable buttons on main page
			setSelectedTeamsOptions (null);
			setModalGeneralMessage("Could not create the user account.  Please complete first name, last name and email address.");
			setModalGeneralTitle("Ooops!  Incomplete information.");
			setShowModalGeneral(true);   
			return;
			}

			setShowSpinner(true);       //Pop up spinners to lock screen
			let tempUrl="", fileUploaded = false, avatarWidth = 0, avatarHeight = 0;
			//New avatar image to upload?

			if (imageFileUploaded) {
				
				var img = document.getElementById('uploadedImagedID');      
				avatarWidth = img.naturalWidth; avatarHeight = img.naturalHeight;          
				if (DEBUG_MODE >= 2) console.log("Image - width", avatarHeight, "height:", avatarWidth);
				
				const {successFlag, url} = await storeImageFile(imageFileUploaded);
				if (successFlag) {
					fileUploaded = true; tempUrl = url; if (DEBUG_MODE > 1) console.log ("Successfully wrote user's avatar image to S3 ", tempUrl);
				} else {
					fileUploaded = false;  if (DEBUG_MODE > 0) console.error ("ERROR - failed avatar image to S3");                  
				}                              
			}
				
			//Now that we have the data, determine the tomorrow in the new employee's TZ; init a moment var in THEIR TZ first, then set the date/time to their start date on 9:00 AM
			let startTimeNewEmpMom = moment().tz(selectedTimeZoneCode ? selectedTimeZoneCode.value : 'America/New_York');
			startTimeNewEmpMom.set({'year': startDateMom.get('year'), 'month': startDateMom.get('month'), 'date':startDateMom.get('date'), 'hour':9, 'minute':0, 'second':0});
			// if (DEBUG_MODE >= 2) console.log("set new employee start date / time:", startTimeNewEmpMom.format());

			//OK, we are now ready to create all the user records
			const params = {
				cmdIssuerID:currentUser.id,
				customerID:tempCustomerID,
				userInputData: userToEdit,       //data object of user to create
				teams:teams,            //Pointer to our overall Teams array
				customerConfigDetails:tempCustomerConfigDetails,
				bio:'',
				avatarHeight:avatarHeight,
				avatarWidth:avatarWidth,
				avatarUrl:tempUrl,
				selectedTeamsOptions:selectedTeamsOptions,
				// leaderboardID:tempLeaderboardID,         //APRIL 2023 - removed as the leaderboard ID depends on the customer so we'd have to grab a new one; just let our lambda creates the slots         
				mentorID:  selectedMentorOption ? selectedMentorOption.id : "",
				sponsorID:  selectedSponsorOption ? selectedSponsorOption.id : "",
				supervisorID:  selectedSupervisorOption ? selectedSupervisorOption.id : "",
				startDate: (startTimeNewEmpMom ? startTimeNewEmpMom.toISOString() : null),
				timeZoneCode:(selectedTimeZoneCode ? selectedTimeZoneCode.value : 'America/New_York'),
			};

			if (DEBUG_MODE >= 2) console.log("Creating new user", params);
			result = await invokeCreateUser(params);

			//New user created?
			if (result && result.successFlag) {
				let successMessage = "Employee account created.  A welcome email with a userID and password has been sent to: " + userToEdit.email +".";
				if (startDateMom && startDateMom.isAfter(moment(),'day') && tempCustomerConfigDetails.newEmpConectivitySchedulingEnabled) 
					successMessage = successMessage + "  Conectivities have automatically been scheduled according to your New Employee Support program, starting " + startDateMom.format('ddd, MMM Do') + ".";
				setUserToEdit(USER_INITIAL_STATE);
				setModalGeneralMessage(successMessage);
				setModalGeneralTitle("Success!");
				if (DEBUG_MODE >= 2) console.log("Successfully created user");
				
			} else {
				if (fileUploaded) await deleteImageFile(tempUrl);
				if (DEBUG_MODE >= 2) console.log("Error creating user", result);
				setModalGeneralMessage((result.error ? result.error : "Could not create the user account")); //Show specific error, if there is one
				setModalGeneralTitle("Ooops!");
			}

		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error creating user", err);
			setModalGeneralMessage("Could not create the user account.  " + err);
			setModalGeneralTitle("Ooops!");
		}

		 setImageUploaded(null);                             //clear the URL to the uploaded image
		 setImageFileUploaded(null);                         //clear the file itself
		 setIsUpdatingUser(false); //enable buttons on main page
		 setSelectedTeamsOptions (null);
		 setSelectedMentorOption(null);
		 setSelectedSponsorOption(null);
		 setSelectedSupervisorOption(null);
		 setShowSpinner(false); //Hide spinners
		 setShowModalGeneral(true);   
		 handleCloseEditor(result);
	 }
	
	 
 
	 function displayModalButtons () {
 
	  return (
		 <div className="ContainerNoHeight">
				{!isAdd && !isEdit ? null :  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="save" onClick={isAdd ? handleSubmit : handleConfirmationEdit} disabled={(!startDateMom || !startDateMom.isValid()) || !userToEdit.firstName || !userToEdit.lastName || !userToEdit.email || !selectedCustomerOptions} > Save </button> }
				{!isDelete ? null : <button className="buttonStyle1 buttonStyle1Red" aria-label="delete" onClick={handleConfirmationDelete}> <DeleteOutline />  </button>}
				<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={handleCloseEditor}> Cancel  </button>
		 </div>
		 );
	 }
 

	/* RENDER FUNCTION */

	 if (!isSuperAdmin && !isAdmin && !permissionEditor) return null;
	 
	return (
		<>					
		  	<ModalNoBackground showModal={showEditor} closeCallback={() => handleCloseEditor({result:"CANCELED"})} cardColor={TEAM_COLOR}  >
				<div className="modalNoBkgInnerCard" >
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt='Logo' />  
				</div>

				<h3>
						{!isAdd ? "" : <div>ADD EMPLOYEE</div>}
						{!isEdit ? "" : <div>EDIT EMPLOYEE</div>}
						{!isDelete ? "" : <div>DELETE EMPLOYEE?</div>}
				</h3>

				<div style={{fontSize:"18px", color:"#6495ED", width:"300px"}}>
					<Select 
						isDisabled={isDelete || isEdit ? true : false}
						isMulti={false} 
						name="customerDropdown" 
						options={customerOptions} 
						onChange={setSelectedCustomerOptions} 
						value={selectedCustomerOptions} 
						placeholder=" -- select customer --"                            
					/>
				</div>

				<div className="modalNoBkgCenterColumn" style={{paddingTop:"clamp(8px,2vw,20px)"}} >
						<div className="ContainerVertical fullWidth wrap" style={{padding:"clamp(4px,1vw,10px)"}}>
							<div className="ContainerNoHeightCenter fullWidth wrap"  >
								<div className="ContainerVerticalLeft fullWidth wrap" onClick = {(e) =>  e.stopPropagation()}>
									<div className="Container wrap" style={{width:"100%", paddingBottom: "clamp(4px,1vw,10px)"}} >
										<div className="inputFormVerticalLeftInputField TextStyle5">
											<div>First Name </div>
											<input
												// id="input_1"
												name="firstName"
												style={{border: (!user|| !userToEdit.firstName || userToEdit.firstName===0 ? "1px solid red" : "1px solid grey")}}
												size="20"
												type="text"
												placeholder=""
												value={userToEdit.firstName}
												onChange={(event) => setUserToEdit({ ...userToEdit, firstName: event.target.value })}
												required
												disabled={isDelete}
											/>
										</div>
										<div className="inputFormVerticalLeftInputField TextStyle5">
											<div>Middle Name </div>
											<input
												id="input_2"
												name="middleName"
												size="20"
												type="text"
												value={userToEdit.middleName ? userToEdit.middleName : ""}
												placeholder=""
												onChange={event => setUserToEdit({ ...userToEdit, middleName: event.target.value })}
												required
												disabled={isDelete}
											/>
										</div>
										<div className="inputFormVerticalLeftInputField TextStyle5">
											<div>Last Name </div>
											<input
												id="input_3"
												name="lastName"
												style={{border: (!user|| !userToEdit.lastName || userToEdit.lastName===0 ? "1px solid red" : "1px solid grey")}}
												size="30"
												type="text"
												value={userToEdit.lastName}
												placeholder=""
												onChange={event => setUserToEdit({ ...userToEdit, lastName: event.target.value })}
												disabled={isDelete}
												required
											/>
										</div>
									</div>
									
									<div className="Container wrap" style={{width:"100%",  paddingBottom: "10px"}}>
											<div className="inputFormVerticalLeftInputField TextStyle5">
												<div>Email </div>
												<input
													id="input_4"
													name="email"
													style={{border: (!user|| !userToEdit.email || userToEdit.email===0 ? "1px solid red" : "1px solid grey")}}
													type="text"
													placeholder=""
													value={userToEdit.email}
													size="30"
													onChange={event => setUserToEdit({ ...userToEdit, email: event.target.value })}
													required
													disabled={(isEdit && !isSuperAdmin) || isDelete}
												/>
											</div>
											<div className="inputFormVerticalLeftInputField TextStyle5">
												<div>Title </div>
												<input
													id="input_5"
													name="title"
													size="40"
													type="text"
													placeholder=""
													value={userToEdit.title}
													disabled={isDelete}
													onChange={event => setUserToEdit({ ...userToEdit, title: event.target.value })}
													required
												/>
											</div>
											<div className="inputFormVerticalLeftInputField TextStyle5">
												<div>Pronouns </div>
												<input
													id="input_6"
													name="pronouns"
													size="15"
													type="text"
													disabled={isDelete}
													placeholder=""
													value={userToEdit.pronouns}
													onChange={event => setUserToEdit({ ...userToEdit, pronouns: event.target.value })}
													required
												/>
											</div>
									</div>
									
									<div className="ContainerNoHeightFlexLeft" style={{width:"100%",  paddingBottom: "10px", zIndex:"9999" }}>
											<div className="inputFormVerticalLeftInputField TextStyle5" style={{width:"100%"}}>
												<div>Team(s) </div>
												<DisplayTeamSelectComponent name="teamDropdown" teamsOptions={teamsOptions} handleSelectTeam={setSelectedTeamsOptions} isMulti={true} selectedTeamsOptions={selectedTeamsOptions} disabled={isDelete ? true : false}  placeholder=""/>
											</div>
									</div>
			
									<div className="ContainerNoHeightFlexLeft fullWidth wrap" style={{paddingBottom: "10px",  zIndex:"9500" }}>
										<div className="inputFormVerticalLeftInputField fullWidth" >                        
											<div  className="TextStyle5">Start Date</div>
											<LocalizationProvider dateAdapter={AdapterMoment}>
											<DatePicker
												sx={(startDateMom && startDateMom.isValid() ? {} : {
														color: '#1565c0',
														borderWidth: '1px',
														borderColor: '#2196f3',
														border: '1px solid red',
														backgroundColor: 'rgba(255,0,0,0.5)',
												})}
												label=""
												disabled={isDelete}
												value={startDateMom}
												onChange={(newDateValue) => {
													setStartDateMom(newDateValue);
												}}
											/>
											</LocalizationProvider>
										</div>
										<div className="inputFormVerticalLeftInputField fullWidth" >                        
											<div className="TextStyle5">Employee's Primary Time Zone</div>
											<DisplayTimeZoneSelectComponent name={"tzDropdown"} options={TIME_ZONE_OPTIONS} handleSelectUser={setSelectedTimeZoneCode} isMulti={false} selectedOptions={selectedTimeZoneCode} disabled={!isAdmin || isDelete ? true : false} placeholder=' - select - ' padding="0px"/>
										</div>                                    
									</div>
									
									<div className="ContainerNoHeightFlexLeft" style={{width:"100%",  paddingBottom: "10px", zIndex:"8888" }}>
											<div className="inputFormVerticalLeftInputField TextStyle5" style={{width:"100%"}}>
												<div  className="TextStyle5">Supervisor</div>
												<DisplayUserSelectComponent name={"supervisorDropdown"} usersOptions={usersOptions} handleSelectUser={setSelectedSupervisorOption} isMulti={false} selectedUsersOptions={selectedSupervisorOption} disabled={!isAdmin || isDelete ? true : false} placeholder=' --- ' padding="0px"/>
											</div>  
											<div className="inputFormVerticalLeftInputField TextStyle5" style={{width:"100%"}}>
												<div  className="TextStyle5">Mentor</div>
												<DisplayUserSelectComponent name={"mentorDropdown"} usersOptions={usersOptions} handleSelectUser={setSelectedMentorOption} isMulti={false} selectedUsersOptions={selectedMentorOption} disabled={!isAdmin || !currentUser.customer.configDetails.mentorProgramEnabled || isDelete ? true : false} placeholder=' --- ' padding="0px"/>
											</div>
			
											<div className="inputFormVerticalLeftInputField TextStyle5" style={{width:"100%"}}>
												<div  className="TextStyle5">Sponsor</div>
												<DisplayUserSelectComponent name={"sponsorDropdown"} usersOptions={usersOptions} handleSelectUser={setSelectedSponsorOption} isMulti={false} selectedUsersOptions={selectedSponsorOption} disabled={!isAdmin || !currentUser.customer.configDetails.sponsorProgramEnabled || !userToEdit.isUnderrepresentedGroup || isDelete ? true : false} placeholder=' --- ' padding="0px"/>
											</div>
									</div>
			
			{/*
									<div className="ContainerNoHeightFlexLeft fullWidth" style={{paddingBottom: "10px" }}>
										<div className="inputFormVerticalLeftInputField fullWidth" >                        
											<div> Roles </div>
											<div className = "ContainerNoHeightFlexLeft fullWidth">
											
												<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
													<div style={{textAlign:'center', color:'grey'}} >Mentor  </div>
														<Switch
															name="roleMentor"
															disabled={(!isAdmin && !isSuperAdmin) || isDelete}
															checked={userToEdit.canBeMentor ? true : false}   onChange={event => setUserToEdit({ ...userToEdit, canBeMentor: !userToEdit.canBeMentor })}
														/>
												</div>
												<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
													<div style={{textAlign:'center', color:'grey'}} >Sponsor  </div>
														<Switch
															name="roleSponsor"
															disabled={(!isAdmin && !isSuperAdmin) || isDelete}
															checked={userToEdit.canBeSponsor ? true : false}   onChange={event => setUserToEdit({ ...userToEdit, canBeSponsor: !userToEdit.canBeSponsor })}
														/>
												</div>                                    
												<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}} >
													<div style={{textAlign:'center', color:'grey'}} >Supervisor  </div>
														<Switch
															name="roleSupervisor"
															disabled={(!isAdmin && !isSuperAdmin) || isDelete}
															checked={userToEdit.canBeSupervisor ? true : false}   onChange={event => setUserToEdit({ ...userToEdit, canBeSupervisor: !userToEdit.canBeSupervisor })}
														/>
												</div>                                    
											</div>
										</div>
									</div>
			*/}
			
									
									<div className="ContainerNoHeightFlexLeft" style={{width:"100%"}}>
										<div className="inputFormVerticalLeftInputField TextStyle5"> 
												<div> Roles </div>
							
												<div className = "ContainerNoHeightFlexLeft fullWidth wrap" >
				
													{!isSuperAdmin ? "" : 
													
														<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
															<div style={{textAlign:'center', color:'grey'}} > Conectere Editor </div>
																
																<Switch
																	name="permissionEditor"
																	disabled={!isSuperAdmin || isDelete}
																	checked={userToEdit.permissionEditor ? true : false}   
																	onChange={event => setUserToEdit({ ...userToEdit, permissionEditor: !userToEdit.permissionEditor })}
																/>
					
																
														</div>
													}
													{!isSuperAdmin ? "" :                                      
														<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
			
															<div style={{textAlign:'center', color:'grey'}} > Billing & Payments  </div>
																<Switch
																	name="permissionBilling"
																	disabled={!isSuperAdmin || isDelete}
																	checked={userToEdit.permissionBilling ? true : false}   
																	onChange={event => setUserToEdit({ ...userToEdit, permissionBilling: !userToEdit.permissionBilling })}
																/>
				
														</div>
													}
													
													{/*
													<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
														<div style={{textAlign:'center', color:'grey'}} >Launch Conectivities  </div>
															<Switch
																name="permissionLaunch"
																disabled={(!isAdmin && !isSuperAdmin) || isDelete}
																checked={userToEdit.permissionLaunch ? true : false}   onChange={event => setUserToEdit({ ...userToEdit, permissionLaunch: !userToEdit.permissionLaunch })}
															/>
													</div>
													*/}
													<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
														<div style={{textAlign:'center', color:'grey'}} >Order Processing  </div>
															<Switch
																name="permissionOrders"
																disabled={(!isAdmin && !isSuperAdmin) || isDelete}
																checked={userToEdit.permissionOrders ? true : false}   onChange={event => setUserToEdit({ ...userToEdit, permissionOrders: !userToEdit.permissionOrders })}
															/>
													</div>
													<div className="ContainerVerticalCenter" style={{paddingLeft:"clamp(5px,1vw,10px)", paddingRight:"clamp(5px,1vw,10px)"}}>
														<div style={{textAlign:'center', color:'grey'}} >Analytics  </div>
															<Switch
																name="permissionAnalytics"
																disabled={(!isAdmin && !isSuperAdmin) || isDelete}
																checked={userToEdit.permissionAnalytics ? true : false}   onChange={event => setUserToEdit({ ...userToEdit, permissionAnalytics: !userToEdit.permissionAnalytics })}
															/>
													</div>
													
													<div className="ContainerVerticalCenter" style={{minWidth:"200px", position:"relative"}}>
														<div className="ContainerNoHeightCenter" style={{zIndex:"1"}}>
															<div className="grey"> Sponsorship Candidate </div>
															<HelpButton hoverText="If selected, the employee will be available as a candidate in your sponsorship dashboard.  This selection is typically only used for employees who are comfortable with having a formalized sponsor and have expressly agreed to participate in the program.  Your sponsorship program can be open to all candidates or to a subset of employees, such as underrepresented groups.  
															Examples of underrepresented groups include but are not limited to people of color, women, indigenous people, LGBTQ+ community, people with disabilities and military veterans."  /> 
														</div>
														<Switch
															name="isUnderrepresented"
															disabled={(!isAdmin && !isSuperAdmin) || isDelete}
															checked={userToEdit.isUnderrepresentedGroup ? true : false}   onChange={ (event) => {
																if (!userToEdit.isUnderrepresentedGroup) setUserToEdit({ ...userToEdit, isUnderrepresentedGroup:true});
																else {
																	setUserToEdit({ ...userToEdit, isUnderrepresentedGroup: false});
																	setSelectedSponsorOption(null);
																	}
																}} />
													</div>                                                
													
												</div>
										</div>
									</div>
								</div>
								

								
								<div className="ContainerVertical" style={{width:"300px"}}>
										<div >
											{!isDelete ? "" : 
												<div className="avatarContainer">
													<div className='avatarImage avatarImageXLarge' style={{backgroundColor:setHeaderColorByStringLength(getUserName(user))}}>
														{!userToEdit.avatarUrl ? <div className="avatarInitials" > {getUserInitials(user)}</div> : <img className="avatarImageCenterPortrait" src={userToEdit.avatarUrl} alt={getUserInitials(user)} /> }
													</div>
												</div>
											}
											
											{imageUploaded && !isDelete ? 
												<div className="avatarImage avatarImageXLarge">
													<img id="uploadedImagedID" src={imageUploaded} alt="" className="avatarImageCenterPortrait"  />
												</div>   
											: ""}
											{!imageUploaded && !isDelete ? 
												<FileUploader
													imageChangeCallback={handleImageUpload} 
													fileTypesToAccept="image/png, image/jpeg"
													buttonText="Upload Photo"
												/>   
											: "" }                                                  
											</div>
											<div style={{padding:'5px'}}>
												{!imageUploaded || isDelete ? "" :
													<Button size="sm" variant="outline-secondary" onClick={handleClearImage}><div className="buttonTextBlack"> Remove Photo</div></Button>
												}
				
											</div>
									</div>
								</div>
								
								{/* userToEdit.permissionEditor == false ? "" :
									<div className="ContainerNoHeightFlexLeft" style={{width:"100%"}}>
										<div className="inputFormVerticalLeftInputField TextStyle5"> 
												<div> Biography </div>
													<Editor
														apiKey="941y7dc26vh4t4laezbgyxqjzrwq1py7hl2l95qdephwb134"
														initialValue={userToEdit.bio}
														onInit={(evt, editor) => editorRef.current = editor}
														init={{
														height: 400,
														width: 800,
														menubar: true,
															plugins: ['autoresize', 'emoticons', 'advlist', 'autolink', 'lists', 'link', 'image', 
																'charmap', 'print', 'preview', 'anchor', 'help',
																'searchreplace', 'visualblocks', 'code',
																'insertdatetime', 'media', 'table', 'paste', 'wordcount'
															],
														toolbar:
															'undo redo | link emoticons | formatselect | bold italic | \
															alignleft aligncenter alignright | \
															bullist numlist outdent indent | help'
													}}
													
												/>                                    
										</div>
									</div>
								*/}
								
							</div>
						<div className="modalNoBkgFooter" style={{paddingTop:"clamp(8px,2vw,20px)"}}>
							{displayModalButtons()}
						</div>
					</div>
				</div>
		 	</ModalNoBackground> 	

	  		</>
	);
}
);

export default UserEditor;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - CONECTERE
//


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, DARK_GREY, LIGHT_GREY, DAVY_GREY, FAINT_GREY, COLOR_BLACK_OPAQUE } from '../../data/conectereConfigData'; 

//React & Amplify
import React, { useEffect, useState, useContext, useRef  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context
import { ModalContext } from '../../context/modalContext';            

//Bootstrap and other React components
import Select from 'react-select';   //Version with support from multiple selections
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

//Queries and Mutations
import { getChallenge } from '../../graphql/queries';

//Utils
import { setHeaderColorByCategory, setConectivityImageUrlByConectivity } from "../../utils/conectivityUtils";
import { Divider } from "../../utils/generalUtils";
import { formatDateSimple, isEndDateInPast, formatInvitationInstanceDateTime} from "../../utils/dateTimeUtils";
import { ChallengeHistory, ChallengeDataEntry, compareByActivityDatetReverseChronological }  from "../../utils/challengeUtils";
import { fetchChallengeDataByLeaderboardByUser, fetchChallengeDataByLeaderboardSpot, invokeCreateChallengeData, invokeUpdateChallengeData, invokeDeleteChallengeData, getDataRecordById } from "../../utils/databaseUtils";
import { DashboardCircle } from "../../utils/dashboardUtils";
import {  displayUserAvatar, displayTeamAvatar } from "../../utils/userAndTeamUtils";


//Our components
import ModalGeneral from "../modalGeneral/modalGeneral";
import ModalNoBackground from "../modalNoBackground/modalNoBackground";
import ModalNoBackgroundFixed from "../modalNoBackgroundFixed/modalNoBackgroundFixed";

//Icons
import { DeleteOutline} from '@material-ui/icons';

const ChallengeDataEditor = React.memo(({ showEditor, leaderboard, leaderboardSpot, closeEditorCallback, injectLeaderboardSpot }) => {

	// Context
	const { currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);    
	const { users, teams } = useContext(CustomerContext);  
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	// Local state
	const [challengeDataRecords, setChallengeDataRecords] = useState([]); 										//set of historical challenge data records for this leaderboard
	const [forceClosedTrigger1, setForceClosedTrigger1] = useState(false);
	const [challengeDataRecordToDelete, setChallengeDataRecordToDelete] = useState(null);            //Used to hold a conectivity that is to be deleted
	const [currentChallenge, setCurrentChallenge] = useState(null);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [user, setUser] = useState(null);
	const [team, setTeam] = useState(null);
	// const [isContributor, setIsContributor] = useState(false);
	const [isTeamCompetition,setIsTeamCompetition] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(true);	//Default to read-only so we don't show open fields and then take them away

	//state variables for messages

	
	 useEffect (() => {
		//Init state params
		if (currentUser && showEditor && leaderboard  && leaderboard.launchRule && leaderboard.launchRule.challengeID && leaderboardSpot && leaderboardSpot.id) {
			fetchChallengeData({leaderboard, leaderboardSpot});
		} else if (DEBUG_MODE) console.log("Editor not opened", leaderboard);

	},[showEditor, leaderboard]);
	 
	async function fetchChallengeData({leaderboard, leaderboardSpot}) {
		let challengeDataRecords = [];
		try {
			const challenge = await getDataRecordById(getChallenge, 'getChallenge', leaderboard.launchRule.challengeID);
			if (DEBUG_MODE) console.log("Fetched challenge record", challenge);
			setCurrentChallenge(challenge ? challenge : null);

			if (challenge) {

				//Fetch challenge data for this Spot
				if (DEBUG_MODE) console.log("Fetching challenge data for leaderboardSpot", leaderboardSpot);
				challengeDataRecords = await fetchChallengeDataByLeaderboardSpot({leaderboardSpotID:leaderboardSpot.id});    
				if (DEBUG_MODE) console.log("Fetched challenge data for leaderboardSpot", challengeDataRecords); 

				let tempTeam = null, tempUser = null, tempIsContributor = false, tempIsTeamCompetition = false;;
				//Team challenge or individual?
				if (challenge.isTeamCompetition && leaderboardSpot.teamID) {
					tempIsTeamCompetition = true;
				 	tempTeam = teams.find(team => team.id === leaderboardSpot.teamID);
					tempUser = users.find(user => user.id === currentUser.id);
					if (tempTeam && tempTeam.users) tempIsContributor = (tempTeam.users.items.some(join => join.userID === currentUser.id));  //Is the user a member of THIS team's SPOT?
				} else if (leaderboardSpot.userID) {
					tempUser = users.find(user => user.id === leaderboardSpot.userID);
					tempIsContributor = (leaderboardSpot.userID === currentUser.id);
					if (DEBUG_MODE) console.log("Grabbed the corresponding user for the selected spot", tempUser)
					setUser(tempUser); setIsTeamCompetition(false);setTeam(null); 
				} else {
					if (DEBUG_MODE) console.error("Error - improper params to fetch team or user challenge data", challenge, leaderboardSpot)
				}

				//Read only?
				let isExpired = isEndDateInPast({eventEndDateTime:leaderboard.endDateTime, allDayEvent:leaderboard.launchRule.allDayEvent});
				let isReadOnly = (isExpired || (currentUser.id !== leaderboardSpot.userID && !tempIsContributor) );
				// let isReadOnly = ((isExpired || (currentUser.id !== leaderboardSpot.userID && !tempIsContributor)) && !isAdmin && !isSuperAdmin );
				//Set params
				setIsReadOnly(isReadOnly); setTeam(tempTeam); setIsTeamCompetition(tempIsTeamCompetition);setUser(tempUser); 
				if (DEBUG_MODE) console.log("Set corresponding team and user records for the selected SPOT");
				if (DEBUG_MODE) console.log("SPOT is read only:", isReadOnly, );
				if (DEBUG_MODE) console.log("User is contributor", tempIsContributor);
				if (DEBUG_MODE) console.log("User and Team", tempUser, tempTeam,);
				if (DEBUG_MODE) console.log("Is team competition", tempIsTeamCompetition);

			} else {
				if (DEBUG_MODE) console.error("Error - no parent challenge record for this leaderboard", leaderboard, challenge);
			}

		} catch (err) {
			if (DEBUG_MODE) console.log("Error fetching challenge data for current user for this leaderboard", err);
		}

		challengeDataRecords.sort(compareByActivityDatetReverseChronological);
		setChallengeDataRecords(challengeDataRecords);
	}


	//Handle SAVE Challege Data Record
	async function handleSubmit ({activityDateTime, challengeDataValue})  {
		//safety check
		if (!activityDateTime || !challengeDataValue || (!user && !team)) {
			if (DEBUG_MODE) console.error("Error - improper params to store challenge data record", activityDateTime, challengeDataValue, user, team);
			return;
		}

		let successFlag, message;
		setShowSpinner(true); //Show spinners
		if (DEBUG_MODE >= 2) console.log("Storing challenge data", leaderboard, leaderboardSpot, activityDateTime, challengeDataValue, user, team);    

		if (currentUser && activityDateTime && leaderboardSpot && leaderboardSpot.id && leaderboard && leaderboard.id && leaderboard.launchRule && leaderboard.launchRule.challengeID && challengeDataValue) {
			try { 	
				const tempChallengeDataRecord = {
					customerID: currentUser.customerID,	             
					userID: user.id,													// user submitting the challenge Data
					teamID: (isTeamCompetition && team && team.id ? team.id : null),				// team to which the challenge Data corresponds
					activityDate: activityDateTime.toISOString(), 		 	// date the activity was completed for the challenge data, which may be different from the submission date
					leaderboardID:leaderboard.id,									// Stores the ID of the corresponding leaderboard 
					leaderboardSpotID:leaderboardSpot.id,
					challengeID: leaderboard.launchRule.challengeID,		// ID of the parent challenge to which this leaderboard corresponds, if any
					challengeDataValue: challengeDataValue,					// value of the challenge data 
				};

				const response = await invokeCreateChallengeData(tempChallengeDataRecord);
				successFlag = (response ? response.successFlag : false);

				//Update local state since we aren't using subscriptions to challenge data
				if (successFlag && response && response.challengeData) {
					let tempChallengeData = [...challengeDataRecords];
					tempChallengeData.push(response.challengeData);
					tempChallengeData.sort(compareByActivityDatetReverseChronological )
					setChallengeDataRecords(tempChallengeData);
					if (DEBUG_MODE) console.log("Success creating the new challenge datea record.  adding it to local state.", response.challengeData, tempChallengeData )
					//Update Spot cache directly
					if (response.leaderboardSpot && injectLeaderboardSpot) {
						injectLeaderboardSpot(response.leaderboardSpot);
					}
				} else {
					message = "Something went wrong.  Unable to create challenge data";
					if (DEBUG_MODE >= 2) console.log('error creating challenge data:');					
				}
			} catch (err) {
						message = "Something went wrong. " + err;
						if (DEBUG_MODE >= 2) console.log("Error creating challenge data", err);
			}
		} //End if proper params
		//Success?
		message = (successFlag ? "Challenge data updated" :  "Something went wrong.");

		// close the editor
		setShowSpinner(false); //hide spinners
		setModalGeneralMessage(message);
		setModalGeneralTitle(successFlag ? "Success!!" : "Ooops");
		setShowModalGeneral(true);
	 }

	//Handle UPDATE Challege Data Record
	async function handleConfirmationEdit ({challengeDataRecord, activityDateTime, challengeDataValue})  {
		//safety check
		if (!challengeDataRecord || !challengeDataRecord.id || !activityDateTime || !challengeDataValue) {
			if (DEBUG_MODE) console.error("Error - improper params to update challenge data record",challengeDataRecord, activityDateTime, challengeDataValue);
			return
		}

		let successFlag, message;
		setShowSpinner(true); //Show spinners
		if (DEBUG_MODE >= 2) console.log("Updating challenge data", leaderboard, leaderboardSpot, activityDateTime, challengeDataValue);    

		try { 	
			const tempChallengeDataRecord = {
				id:challengeDataRecord.id,
				customerID: challengeDataRecord.customerID,	             
				userID: challengeDataRecord.userID,											
				activityDate: activityDateTime.toISOString(), 		 	// date the activity was completed for the challenge data, which may be different from the submission date
				challengeDataValue: challengeDataValue,					// value of the challenge data 
			};

			const response = await invokeUpdateChallengeData(tempChallengeDataRecord);
			successFlag = (response ? response.successFlag : false);

			//Update local state since we aren't using subscriptions to challenge data
			if (successFlag && response && response.challengeData) {
				if (DEBUG_MODE) console.log("Success updating the challenge datea record. updating local state.", response.challengeData )
				const tempChallengeData = [...challengeDataRecords];
				const index = tempChallengeData.findIndex(record => record.id === response.challengeData.id);
				if (index > -1) tempChallengeData[index] = {...response.challengeData};
				tempChallengeData.sort(compareByActivityDatetReverseChronological);
				setChallengeDataRecords(tempChallengeData);
				message = "Challenge data updated. ";
			} else {
				message = "Something went wrong.  Unable to update challenge data";
				if (DEBUG_MODE >= 2) console.log('error updating challenge data:');
			}
		} catch (err) {
			message = "Something went wrong. " + err;
			if (DEBUG_MODE >= 2) console.log("Error updating challenge data", err);
		}		
		setModalGeneralMessage(message);
		setModalGeneralTitle(successFlag ? "Success!!" : "Ooops");
		setShowModalGeneral(true);
		setShowSpinner(false); //hide spinners
	 }


    //This function handles the delete buttons from list of items
    //Pop-up a modal and ask conectivity to confirm. Handle confirmation
    const handleDelete = (challengeDataRecordID) => {
		const challengeDataRecordToDelete = challengeDataRecords.find(c => c.id === challengeDataRecordID);
		if (!challengeDataRecordToDelete) return;
		if (DEBUG_MODE >= 2) console.log("DELETE button pressed.  Delete challengeDataRecordToDelete=", challengeDataRecordToDelete); 
		setChallengeDataRecordToDelete(challengeDataRecordToDelete);
		setShowModalDelete(true); //pop-up Modal
  };

  const handleCancelDelete = () => {
	setShowModalDelete(false);
	setChallengeDataRecordToDelete(null);
  }

     ///Delete Functions
    async function handleConfirmationDelete () { 
		setShowSpinner(true); //Show spinners
		let successFlag;
		try {
			 const response = await invokeDeleteChallengeData (challengeDataRecordToDelete);
			 successFlag = (response ? response.successFlag : false);
			 if (successFlag) {
				  if (DEBUG_MODE >= 2) console.log('Successfully deleted conectivity', challengeDataRecordToDelete);

				  //Update local state since we aren't using subscriptions to challenge data
				  const tempChallengeData = challengeDataRecords.filter(record => record.id !== challengeDataRecordToDelete.id);
				  setChallengeDataRecords(tempChallengeData);
				  setModalGeneralMessage("Challenge data entry deleted");
				  setModalGeneralTitle("Success!!");
			 } else {
				  setModalGeneralMessage("Something went wrong.  Unable to delete challenge data");
				  setModalGeneralTitle("Ooops!");
				  if (DEBUG_MODE >= 2) console.log('error deleting challenge data:');
			 }
		} catch (err) {
			 setModalGeneralMessage("Something went wrong.  " + err);
			 setModalGeneralTitle("Ooops!");
			 if (DEBUG_MODE >= 2) console.log('error deleting challenge data:', err);
		}
		setChallengeDataRecordToDelete(null);
		setShowModalDelete(false);   //hide the pop-up
		setShowSpinner(false); //Hide spinners
		setShowModalGeneral(true);   
  }


  	//Render function
	if (!showEditor || !leaderboard || !leaderboard.conectivity) return null;
	 
	const cardColor = setHeaderColorByCategory(leaderboard.conectivity.category.label);

	function cancelEditor() {
		setChallengeDataRecords([])										
		setChallengeDataRecordToDelete(null);            
		setCurrentChallenge(null);
		if (closeEditorCallback) closeEditorCallback({result:"CANCELED"});
	};


	return (
		  <div>
					
		<ModalNoBackgroundFixed showModal={showEditor} closeCallback={cancelEditor} cardColor={cardColor}  >
			<div className="challengeDataEditor modalNoBkgInnerCard justifyStart" onClick = {(e) => { e.stopPropagation(); setForceClosedTrigger1(!forceClosedTrigger1);}} >

				<div className=" ContainerNoHeightCenter fullWidth conectivityCardHeaderWrapper" style={{backgroundColor: cardColor, flexShrink:'0'}} >
					<div className="ContainerNoHeightCenter conectivityCardHeaderImageWrapper" >
						<img className="conectivityCardHeaderImage" src={setConectivityImageUrlByConectivity(leaderboard.conectivity)} />
					</div>
					<div className="ContainerVerticalSpaceAround maxHeight conectivityCardHeaderTextWrapper" >
						<div className="TextStyle5 white" >  {leaderboard.conectivity.title}  </div>
						<div className="TextStyle3 white"><i>{currentChallenge && currentChallenge.launchRule ? formatInvitationInstanceDateTime({eventStartDateTime:currentChallenge.launchRule.eventStartDateTime, eventEndDateTime:currentChallenge.launchRule.eventEndDateTime, allDayEvent:currentChallenge.launchRule.allDayEvent}) : null}</i></div>
					</div>
				</div>		


				<div className="ContainerNoHeightSpaceAround fullWidth" style={{padding:"clamp(4px,2vw,16px)"}}>
					<div className="avatarContainerPortion" >
						{isTeamCompetition ? displayTeamAvatar({team, avatarClass:"avatarImage avatarImageLarge"}) : displayUserAvatar({user, avatarClass:"avatarImage avatarImageLarge"})}
					</div>
					<DashboardCircle value={leaderboardSpot ? leaderboardSpot.currentBalance : 0} titleClass="TextStyle5 headerBlue" title={currentChallenge && currentChallenge.inputLabel ? 'total ' + currentChallenge.inputLabel : ""} textClass={'TextStyle5 headerBlue'} circleClass="dashboardScoreCircle ccImgXXXXLSquare" />
				</div>
				{isReadOnly ? null : 
					<>
						<h4 className="purple"  style={{paddingTop:"clamp(4px,2vw,16px) 0px"}}>Update the leaderboard!</h4> 
						<div className='boxShadow borderRadius very-light-grey-background' style={{padding:"clamp(10px,1vw,16px", margin:"clamp(10px,1vw,16px"}}>
							<ChallengeDataEntry leaderboard={leaderboard} challenge={currentChallenge} createCallback={handleSubmit} saveLabel="submit"/>
						</div>
					</>
				}

				{ challengeDataRecords && challengeDataRecords.length > 0 ?
					<>
						<h4 className="purple" style={{paddingTop:"clamp(8px,2vw,20px)"}}>Entries</h4> 
						<ChallengeHistory isReadOnly={isReadOnly} leaderboard={leaderboard} leaderboardSpot={leaderboardSpot} cardColor={cardColor} challengeDataRecords={challengeDataRecords} deleteCallback={handleDelete} updateCallback={handleConfirmationEdit} challenge={currentChallenge}/>
					</>
					: null
				}
				

			</div>
		</ModalNoBackgroundFixed> 


		<ModalNoBackgroundFixed showModal={showModalDelete} closeCallback={handleCancelDelete} cardColor={TEAM_COLOR}>
			<div className="modalNoBkgInnerCard" style={{padding:"10px 10px 0 10px"}} >
				<div className="modalNoBkgCenterColumn" style={{padding:"20px 10px 0px 10px"}} >
					<div className="TextStyle4">Delete data entry?</div>
					<div className="ContainerNoHeightCenter TextStyle3" style={{paddingTop:"1em"}}>
						<span style={{paddingRight:"0.5em"}}>{challengeDataRecordToDelete && challengeDataRecordToDelete.challengeDataValue ? JSON.parse(challengeDataRecordToDelete.challengeDataValue) : ''} </span>
						<span> {currentChallenge && currentChallenge.inputLabel ? currentChallenge && currentChallenge.inputLabel : ''} </span>
					</div>
					<div className="ContainerNoHeightCenter TextStyle2">
						({challengeDataRecordToDelete && challengeDataRecordToDelete.activityDate ? formatDateSimple(challengeDataRecordToDelete.activityDate) : ''})
					</div>

						<div className="modalNoBkgFooter" style={{paddingTop:"clamp(8px,2vw,20px)"}}>
							<button className="buttonStyle1 buttonStyle1Red" aria-label="delete" onClick={handleConfirmationDelete} > <DeleteOutline />   </button>
							<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={handleCancelDelete} > Cancel  </button>
						</div>
					</div>   
			</div>
		</ModalNoBackgroundFixed> 



	</div>
	);

});

export default ChallengeDataEditor;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import  { CONECTERE_CONFIG_DATA, FAINT_GREY, FILTERED_LEADERBOARD_SIZE, MAX_LEADERBOARD_SIZE, DEBUG_MODE, COLOR_GOOD, TEAM_COLOR, COLOR_GOLD, COLOR_BRONZE, COLOR_SILVER, COLOR_BLACK, COLOR_WHITE } from '../../data/conectereConfigData';
import React, { useEffect, useState,  useContext, useCallback} from "react";

//Context
import { CustomerContext } from '../../context/customerContext';            //Customer Authentication context
import { AuthContext } from '../../context/authContext';
import { LeaderboardContext } from '../../context/leaderboardContext';            //Customer Authentication context
import { ConectivityContext } from '../../context/conectivitiesContext';

//Our components
import ModalNoBackgroundFixed from "../../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ConectivityDurationTimer from '../../Components/conectivityDurationTimer/conectivityDurationTimer';

//Utils
import { compareLeaders, displayUserAvatar, displayTeamAvatar, getUserName } from "../../utils/userAndTeamUtils";
import { setConectivityImageUrlByCategory, getConectivityCategoryText, setHeaderColorClassByCategory  } from "../../utils/conectivityUtils";
import { formatDateFriendly, isEndDateInPast} from "../../utils/dateTimeUtils";
import { displayChallengePrizes } from "../../utils/challengeUtils";
import moment from 'moment-timezone';

//Icons
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Material UI and my components therefrom
import { IconButton } from '@mui/material';

//
// UI COMPONENTS
// 

//Compoent for displaying an upcoming challenge that has not yet started.  Note, there will be no SC and no Leaderboard
export  const ChallengePreview = ({challenge, conectivity, launchRuleInstanceStartDateTime, launchRuleInstanceEndDateTime, launchRuleInstanceIndex}) => {
	   
	//Local state
	const [isInFuture, setIsInFuture]=useState(true);
	const [rewards, setChallengeRewards] = useState(null);


	const colorClass = setHeaderColorClassByCategory(conectivity && conectivity.category ?  setHeaderColorClassByCategory(conectivity.category.label) : "purple");
	const label=(challenge && challenge.inputLabel? challenge.inputLabel : '');

	useEffect(()=> {
		if (!challenge | !launchRuleInstanceStartDateTime || !launchRuleInstanceEndDateTime) return;
		const tempTime = moment(launchRuleInstanceStartDateTime);
		if (!tempTime || !tempTime.isValid || tempTime.isValid() === false) return;
		setIsInFuture(moment(launchRuleInstanceStartDateTime).isAfter(moment())); //set our isInFuture flag

		//Init our rewards object
		let rewards = null;
		if (challenge && challenge.giveRewards && challenge.rewards) {
			rewards = JSON.parse(challenge.rewards);
			if (DEBUG_MODE > 1) console.log("Parsed challenge rewards", rewards);
		}
		setChallengeRewards(rewards);
	},[challenge, launchRuleInstanceStartDateTime, launchRuleInstanceEndDateTime])

	function displayStartTime() {
		if (!launchRuleInstanceStartDateTime || !launchRuleInstanceEndDateTime) return null;
		return (
			<ConectivityDurationTimer closingDateTime={(isInFuture ? launchRuleInstanceStartDateTime : launchRuleInstanceEndDateTime)} label={(isInFuture ? "Starts" : "Ends:")} labelClass="TextStyle3 blue-text"  timeClass="TextStyle3 black-text"/>
		);
	}

	//Render function 
	if (!challenge || !conectivity) return null;
	 return (
		<div className="lboard_list " > 
				<div className="ContainerVerticalCenter">			 	
					<h3 className={colorClass} style={{whiteSpace: "normal", lineHeight:"normal", textAlign:"center", wordBreak: "break-word" }}>
						{conectivity ? conectivity.title : '' }
					</h3>
					<div className='ContainerNoHeightSpaceAround fullWidth'>
						{displayStartTime()}
						{challenge && challenge.useGoal && challenge.goal && challenge.inputLabel ?  <div className="TextStyle3 black-text"  ><span className='blue-text'>{challenge.isTeamCompetition ? 'Team goal' : 'Goal'}</span> {parseInt(challenge.goal) + ' ' + challenge.inputLabel}</div> : null }					
					</div>
				</div>
				<div className="modalNoBkgInnerCard challengePrizesModal"  >
					{challenge && challenge.giveRewards && rewards ?  displayChallengePrizes(rewards) : null }
				</div>
		 </div>  
	 );
 } ;   

/*  LEADERBOARD CARD */
const LeaderBoardCard = ({leaderBoardSpot, position, useGoal, goal, maxValue, displayIfZero, label, cardSelectedCallback, isChallenge }) => {

	// Context
	const { users, teams, participatingUsersOptions } = useContext(CustomerContext);  
	const { authState, currentUser, isSuperAdmin } = useContext(AuthContext);

	//Safety check
	if (!leaderBoardSpot || !users || !currentUser || !currentUser.id) return null;

	//Display if zero?
	if ((!displayIfZero && (!leaderBoardSpot.currentBalance || leaderBoardSpot.currentBalance===0))) return null;

	//constants
	const goalIcon = '🏁'; const firstIcon = '🥇'; const secondIcon ='🥈'; const thirdIcon = '🥉'; const sweeperIcon = '🧹';const downArrowIcon='🔻'; const upArrowIcon='🔺';

	//Grab user / team info
	let user, team, isCurrentUser;
	if (leaderBoardSpot.teamID) team =  teams.find(team=>team.id === leaderBoardSpot.teamID);
	else user = users.find(user=>user.id === leaderBoardSpot.userID);
	if (!user && !team) return null;//find a user or team to show?

	//Now, does this SPOT belong to the current user?  Depends if a team competion or not
	if (team) isCurrentUser = (team.users.items.some(join => join.userID === currentUser.id));  //Is the user a member of THIS team's SPOT?
	else isCurrentUser = (leaderBoardSpot.userID === currentUser.id);		//Is this SPOT the user's?

	//Figure out the medal color
	var medalColor = "white";
	var medalTextColor = COLOR_BLACK;
	var cardBackgrounColor = "white";
	var cardTextColor = "black";
	
	// Set color scheme if TOP 3 or current user
	switch (position) {
		case 1: 
			medalColor = COLOR_GOLD; medalTextColor = "white";
			break;
		case 2:
			medalColor = COLOR_SILVER; medalTextColor = "white";
			break;
		case 3: 
			medalColor = COLOR_BRONZE; medalTextColor = "white";
			break;
		default:
			break;
	}  
	

	//Adjust color based on whether the spot corresponds to the current user
	if (isCurrentUser) {
			cardBackgrounColor = "#5DADE2";
			cardTextColor = "white";            
	}

	function leaderMove ({leaderMove}) {
		if (leaderMove > 0)  return (<div className="leaderUp">{leaderMove} &#8593;</div>);
		else if (leaderMove < 0) return (<div className="leaderDown">{leaderMove} &#8595;</div>);
		else return (<div className="leaderNoMove"></div>);
	 }

	 function renderGoal({goal, useGoal, maxValue}) { 
		if (!goal || !useGoal || !maxValue) return null;
		const fromLeft = (100 * goal/maxValue) +'%';
		// console.log("Rendering GOAL", goal, useGoal, fromLeft);
	
		return (
			<>	
				<div className="ContainerVerticalCenter TextStyle5 goalMarker" style={{left:fromLeft}}>
					{goalIcon}
				</div>
				<div className='tickMarker' style={{left:fromLeft}}></div>
			</>
	
		);
	}

	function renderProgressMarker() {
		if (!leaderBoardSpot  || !leaderBoardSpot.rank || !maxValue) return null;
		if (!leaderBoardSpot.currentBalance) leaderBoardSpot.currentBalance = 0;
		const fromLeft = (100 * leaderBoardSpot.currentBalance/maxValue) +'%';
		// console.log("Rendering location", leaderBoardSpot.currentBalance, fromLeft);
		let icon=upArrowIcon;
		switch (leaderBoardSpot.rank) {
			case 1: icon=firstIcon; break;
			case 2: icon=secondIcon; break;
			case 3: icon=thirdIcon; break;
			default: icon=upArrowIcon; break;
		}
		if (leaderBoardSpot.rank > 1 && leaderBoardSpot.isLowestRank) icon = sweeperIcon;
		return (
			<>	
				<div className="ContainerVerticalCenter TextStyle6 progressMarker" style={{left:fromLeft}}>
					{icon}
				</div>
				<div className='tickMarker' style={{left:fromLeft}}></div>
			</>

		);
	}

	return (
		<div className="lboard_card boxShadow" key={leaderBoardSpot.id} style={{cursor:(isChallenge ? "pointer" : "default"), backgroundColor:cardBackgrounColor, color:cardTextColor}} onClick={isChallenge && cardSelectedCallback ? () => cardSelectedCallback(leaderBoardSpot) : null}>
			<div className="avatarContainerPortion">
				{team ? displayTeamAvatar({team}) : displayUserAvatar({user})}
				<div className="ccImgMediumSquare lb_rank_anchor lb_rank ContainerNoHeightCenter" style={{backgroundColor:medalColor}}>
					<div className={(position < 10 ? "TextStyle3" : "TextStyle2") + " rankText"} style={{color:medalTextColor}} >{position < 99 ? position : "99+"} </div>
				</div>
			</div>
		
			<div className="name_bar black" > 
				<div className='lb_participant' >{team ? team.name : getUserName(user)}</div>
				<div className="bar_wrap">
					<div className="inner_bar" style={{width: (100* leaderBoardSpot.currentBalance/maxValue) +'%'}}></div>
					{useGoal ? renderGoal({goal, useGoal, maxValue})	: null}
					{renderProgressMarker()}
				</div>
			</div>
			
			<div className="ContainerNoHeightFlexEndCentered points borderRadius " >
				<div className="lb_score black"> {leaderBoardSpot.currentBalance}  </div>
				<div className="leaderboardSlotCoinContainer">  
					{label
						? null
						: <img className="ccImgXL" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt="" /> 
					}
				</div>
			</div> 
			{true || !isSuperAdmin ? "" : leaderMove(leaderBoardSpot.userMove) }
		</div>
	);
};

/*  LEADERBOARD LIST 
	- component for showing an active (or past) leaderboard for a specific SC and LR instance
	- leaderBoard is the main object that dictates which Spots to display
	- showDate if a text version of ending is desired (like 'challenge ends tomorrow')
	- showCountdown if a countdown timer to ending is desired
	- neither are shown if the challenge is already over
*/
export  const LeaderBoardList = ({leaderBoard, currentUserID, displayZeros, showConectivityTitle, isExpired, showDate, showCountdown, cardSelectedCallback}) => {
	//Context
	const {leaderboardSpotsCache } = useContext(LeaderboardContext);
	   
	//Local state
	const [localLeaderBoardSpots, setLocalLeaderBoardSpots] = useState([]);
	const [localLeaderboard, setLocalLeaderboard] = useState(null);
	const [localDisplayZeros, setLocalDisplayZeros] = useState(false);
	const [maxValue, setMaxValue] = useState(0);      //State to store max value for the current leader board for purposes of scaling
	const [positionOfCurrentUser, setPositionOfCurrentUser] = useState(0); 
	const [showModalChallengeInfo, setShowModalChallengeInfo] = useState(false);
	const [rewards, setChallengeRewards] = useState(null);
	const [challengeExpired, setChallengeExpired] = useState(null);
	const [useGoal, setUseGoal] = useState(false);
	const [goal, setGoal] = useState(null);
	const [isTeamCompetition, setIsTeamCompetition] = useState(null);

	const colorClass = setHeaderColorClassByCategory(leaderBoard && leaderBoard.conectivity && leaderBoard.conectivity.category ?  setHeaderColorClassByCategory(leaderBoard.conectivity.category.label) : "purple");

	//Respond to changes in designated leaderboard or spots cache; sort and set max; update local leaderboard record; do this in 1 useEffect to make smooth
	useEffect(() => {
		if (!leaderBoard) return;

		//Has the challenge closed?
		let isExpired = false;
		if (leaderBoard.endDateTime && leaderBoard.launchRule.allDayEvent) isExpired = isEndDateInPast({eventEndDateTime:leaderBoard.endDateTime, allDayEvent:leaderBoard.launchRule.allDayEvent});

		//Update Spots
		let tempMax = 0, challengeRewards = null, goal=null, useGoal = false, isTeamCompetition = false;
		let tempSpots = selectSpotsToDisplay();
		if (tempSpots && tempSpots.length > 0) {
			tempSpots = tempSpots.sort(compareLeaders);
			tempMax = tempSpots[0].currentBalance;
			let tempLowestBalance = tempSpots[tempSpots.length-1].currentBalance;
			//Insert rank
			tempSpots[0].rank=1;
			for (let i=1;i<tempSpots.length;i++) {
				if (tempSpots[i].currentBalance === tempSpots[i-1].currentBalance) tempSpots[i].rank = tempSpots[i-1].rank;
				else tempSpots[i].rank = i+1;
				if (tempSpots[i].currentBalance === tempLowestBalance) tempSpots[i].isLowestRank = true;
			}
		}
		if (leaderBoard && leaderBoard.isChallenge && leaderBoard.launchRule && leaderBoard.launchRule.options) {
			let options = JSON.parse(leaderBoard.launchRule.options);
			if (DEBUG_MODE > 1) console.log("Parsed leaderboard options", options);
			challengeRewards = (options && options.challengeOptions && options.challengeOptions.rewards ? options.challengeOptions.rewards : null);
			useGoal = (options && options.challengeOptions && options.challengeOptions.useGoal ? options.challengeOptions.useGoal : false);
			goal = (useGoal && options && options.challengeOptions && options.challengeOptions.goal ? parseInt(options.challengeOptions.goal) : null);
			isTeamCompetition = (options && options.challengeOptions ? options && options.challengeOptions.isTeamCompetition : false);

			//Update MAX to draw if challenge has a goal and it's greater than our current max
			if (useGoal && (goal > tempMax)) tempMax = goal;
		}
		if (DEBUG_MODE > 1) console.log("Set leaderboard", leaderBoard)
		if (DEBUG_MODE > 1) console.log("Sorted spots", tempSpots)
		if (DEBUG_MODE > 1) console.log("Set max", tempMax)
		if (DEBUG_MODE > 1) console.log("Set useGoal, goal", useGoal, goal)
		if (DEBUG_MODE > 1) console.log("Set display zeros", displayZeros);
		if (DEBUG_MODE > 1) console.log("Set challenge rewards", challengeRewards);
		if (DEBUG_MODE > 1) console.log("Set Team Competition", isTeamCompetition);

		//Set state
		setChallengeExpired(isExpired);
		setChallengeRewards(challengeRewards);
		setLocalLeaderboard(leaderBoard);
		setLocalDisplayZeros(displayZeros);
		setMaxValue(tempMax);
		setUseGoal(useGoal);
		setGoal(goal);
		setLocalLeaderBoardSpots(tempSpots);
		setPositionOfCurrentUser(tempSpots.findIndex(leaderBoardSpot => leaderBoardSpot.userID === currentUserID));
		setIsTeamCompetition(isTeamCompetition);
	}, [leaderBoard, leaderboardSpotsCache, displayZeros]);


	function selectSpotsToDisplay() {
		//Safety check
		if (!leaderBoard) return;
		if(DEBUG_MODE) console.log("Selectings SPOTS to display", leaderBoard, leaderboardSpotsCache);

		let tempSpots = [], displayZeros=false;
		//Is this an enterprise-wide period LB?
		let type =(leaderBoard.leaderBoardType ? leaderBoard.leaderBoardType.toLowerCase() : '');
		switch (leaderBoard.leaderBoardType) {
			case "day":
				tempSpots = leaderboardSpotsCache.filter(leaderBoard => leaderBoard.leaderBoard === "DAY"); 
				break;
			case "week":
				tempSpots = leaderboardSpotsCache.filter(leaderBoard => leaderBoard.leaderBoard === "WEEK"); 
				break;
			case "month":
				tempSpots = leaderboardSpotsCache.filter(leaderBoard => leaderBoard.leaderBoard === "MONTH"); 
				break;
			case "quarter":
				tempSpots = leaderboardSpotsCache.filter(leaderBoard => leaderBoard.leaderBoard === "QUARTER"); 
				break;
			case "year":
				tempSpots = leaderboardSpotsCache.filter(leaderBoard => leaderBoard.leaderBoard === "YEAR"); 
				break;
			case "challenge":
			default:
				//Otherwise, user is viewing a Challenge in which case the variable is the ID
				if (DEBUG_MODE >1) console.log("Selecting spots for challenge");
				tempSpots = leaderboardSpotsCache.filter(spot => spot.customerLeaderBoardID === leaderBoard.id); 
				displayZeros = true;
				break;
		}
		if(DEBUG_MODE) console.log("Selected SPOTS to display", tempSpots, leaderBoard, displayZeros);
		return(tempSpots);
	}

	const label=(localLeaderboard && localLeaderboard.launchRule && localLeaderboard.launchRule.challengeOptions ? localLeaderboard.launchRule.challengeOptions.inputLabel : '');

	function renderSpots() {
		if (!localLeaderBoardSpots) return null;
		return (
			<div className="ContainerVerticalCenter fullWidth">			
				{leaderBoard && leaderBoard.leaderBoardType === 'CHALLENGE' ? <h4 >Total {label}!</h4> : null }
				{localLeaderBoardSpots.map((leaderBoardSpot, index) => (
					<LeaderBoardCard key={leaderBoardSpot.id} isChallenge={localLeaderboard && localLeaderboard.isChallenge} useGoal={useGoal} goal={goal} leaderBoardSpot={leaderBoardSpot} position={leaderBoardSpot.rank} maxValue={maxValue} displayIfZero={localDisplayZeros} label={label} cardSelectedCallback={cardSelectedCallback}/>

					))
				}
				{/* Show user if not already shown */}
				{positionOfCurrentUser < FILTERED_LEADERBOARD_SIZE-1 ? null :
					<LeaderBoardCard isChallenge={localLeaderboard && localLeaderboard.isChallenge} useGoal={useGoal} goal={goal} leaderBoardSpot={localLeaderBoardSpots[positionOfCurrentUser]} position={positionOfCurrentUser} maxValue={maxValue} displayIfZero={localDisplayZeros} cardSelectedCallback={cardSelectedCallback}/>
				} 
			</div>
		)
	}
	//Render function
	if (!localDisplayZeros && maxValue === 0) {
		return (
			 <h3 style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>
				 <span className="blue">Le</span><span className="purple">t'</span><span className="green">s G</span><span className="yellow">o!</span>
			 </h3>
		 );
	}
	 
	 return (
		<div className="lboard_list " > 

			<ModalNoBackgroundFixed showModal={showModalChallengeInfo} closeCallback={()=>setShowModalChallengeInfo(false)} cardColor={TEAM_COLOR}>
				<div className="modalNoBkgInnerCard challengePrizesModal"  >
						{displayChallengePrizes(rewards)}
				</div>
			</ModalNoBackgroundFixed> 

			{localLeaderboard && localLeaderboard.isChallenge ?
				<div className="ContainerVerticalCenter">			 	
					<h3 className={colorClass} style={{whiteSpace: "normal", lineHeight:"normal", textAlign:"center", wordBreak: "break-word" }}>
						
						{showConectivityTitle ? (localLeaderboard && localLeaderboard.conectivity ? localLeaderboard.conectivity.title : '') : 
							'Leaderboard'
						}
					</h3>
					<div className='ContainerNoHeightSpaceAround fullWidth'>
						<div className="ContainerNoHeightCenter black-text TextStyle3">
							
							<div><EmojiEventsIcon className="TextStyle6 gold" />Prizes</div>
							<div className="challengeInfoAnchor">
								<IconButton size="small" onClick={(e) => {e.stopPropagation(); setShowModalChallengeInfo(!showModalChallengeInfo);}} >
									<InfoIcon className={"TextStyle4 " + colorClass}  />
								</IconButton>
							</div>
						</div>
						{ challengeExpired ?  <div className="TextStyle3 blue-text">challenge complete! <span className='TextStyle6'> 🏁 </span> </div> : null }
						{ !challengeExpired && showDate && localLeaderboard && localLeaderboard.endDateTime? <div className="TextStyle3 black-text"><i>{"challenge ends " + formatDateFriendly(localLeaderboard.endDateTime)}</i> </div> : null }
						{ !challengeExpired && showCountdown && localLeaderboard && localLeaderboard.endDateTime? <ConectivityDurationTimer closingDateTime={localLeaderboard.endDateTime} label="Ends" labelClass="TextStyle3 blue-text"  timeClass="TextStyle3 black-text" /> : null }
						{useGoal && goal && label ?  <div className="TextStyle3 black-text"  ><span className='blue-text'>{isTeamCompetition ? 'Team goal' : 'Goal'}</span> {goal.toString() + ' ' + label}</div> : null }					

					</div>
				</div>
				: null 
			}

			{!localLeaderBoardSpots || localLeaderBoardSpots.length === 0 ? null : renderSpots()}            
		 </div>  
	 );
 } ;   

 //Component for rendering Company-wide leaderboards
export const CustomerLeaderBoards = React.memo(() => {
  
	//Context
	const { activeChallenges, leaderboardSpotsCache, handleLeaderboardCardSelected, setCurrentLeaderboard } = useContext(LeaderboardContext);

	//Local state
	const [lbToDisplay, setLbToDisplay] = useState ("month"); 			//an ID for the selected LB to display; day/week/month in the case of general tabs
	const [displayZeros, setDisplayZeros] = useState(false);
	const [currentLeaderBoard, setCurrentLeaderBoard] = useState(null); //Cache the specific LB record for the selected display

	//Respond to changes in leaderboard SPOTS or a selection of an LB to display
	useEffect(() => {
		initLeaderboard();  	//Update leaderboards
	}, [leaderboardSpotsCache, lbToDisplay, activeChallenges]);

	function initLeaderboard() {
		//Safety check
		if (!lbToDisplay) return;
		if(DEBUG_MODE) console.log("Initializing LB to display", lbToDisplay, );

		let tempSpots = [], displayZeros=false, currentLeaderBoard = null;
		//User selected an enterprise-wide period LB?
		switch (lbToDisplay) {
			case "day": 
			case "week": 
			case "month": 
			case "quarter": 
			case "year":
				if (DEBUG_MODE > 1) console.log("User selected enterprise-wide LB")
				currentLeaderBoard = {leaderBoardType:lbToDisplay};
				break;
			default:
				//Otherwise, user is viewing a Challenge in which case the variable is the ID
				currentLeaderBoard = (activeChallenges ? activeChallenges.find(lb=>lb.id === lbToDisplay) : null);
				if (currentLeaderBoard) {
					displayZeros = true;
				} else {
					if (DEBUG_MODE > 1) console.log("Leaderboard being viewed deleted.  Resetting page");
					setLbToDisplay('month'); //No challenge LB found to match the one displayed?  That means it was just deleted via subscription.  Reset the page
					return;
				}
				break;
		}

		if(DEBUG_MODE) console.log("Initialized LB", tempSpots, currentLeaderBoard, displayZeros);
		setCurrentLeaderBoard(currentLeaderBoard);
		setDisplayZeros(displayZeros);
	}
	
	/*
	function buildLeaderboards () {

		if (DEBUG_MODE >= 2) console.log("Change in leaderboard SPOTS detected - updating LB");
		let leaderBoardSpotsToday = [], leaderBoardSpotsWeek =[],  leaderBoardSpotsMonth = [], leaderBoardSpotsQuarter = [], leaderBoardSpotsYear = [];

		if (leaderboardSpotsCache && leaderboardSpotsCache.length > 0) {
			leaderBoardSpotsToday = leaderboardSpotsCache.filter(lb => lb.leaderBoard === "DAY"); 
			leaderBoardSpotsWeek = leaderboardSpotsCache.filter(lb => lb.leaderBoard === "WEEK");
			leaderBoardSpotsMonth = leaderboardSpotsCache.filter(lb => lb.leaderBoard === "MONTH"); 
			leaderBoardSpotsQuarter = leaderboardSpotsCache.filter(lb => lb.leaderBoard === "QUARTER");
			leaderBoardSpotsYear = leaderboardSpotsCache.filter(lb => lb.leaderBoard === "YEAR");
		}
		
		//Store the different company leaderboards SPOTS
		setLeaderBoardSpotsToday (leaderBoardSpotsToday);
		setLeaderBoardSpotsWeek (leaderBoardSpotsWeek);
		setLeaderBoardSpotsMonth (leaderBoardSpotsMonth);
		setLeaderBoardSpotsQuarter (leaderBoardSpotsQuarter);
		setLeaderBoardSpotsYear (leaderBoardSpotsYear);	 
		return;
	}    
	*/

	const tabClickHandler = (lbID) => {		
		setLbToDisplay(lbID);
	}; 


	function displayChallengeNav(activeChallenges) {
		if (!activeChallenges || activeChallenges.length === 0) return null;
		//Since a challenge, must be corresponding conectivity
		return (
				<ul>
					<div className='TextStyle5 bold white ContainerNoHeightCenter fullWidth textAlignCenter'>Active Challenges</div>
					{activeChallenges.map ((lb, index) => (
						<li key={lb.id} onClick = {() => tabClickHandler(lb.id)} className={`'' ${lbToDisplay === lb.id ? "active" : "" }`} data-li={lb.id}>
							<div className="tabLabel ContainerNoHeightFlexEndCentered ">
									{lb.conectivity && lb.conectivity.category ? 
									<>
										<div className='TextStyle2' style={{paddingRight:"clamp(2px,0.5vw,4px)"}}> {lb.conectivity.title} </div>
										<img className="ccImgXL" src={setConectivityImageUrlByCategory(lb.conectivity.category.label, (lbToDisplay === lb.id))} alt='' /> 
									</>
									: "Challenge" 
									} 
							</div>
						</li>
					))}
				</ul>
		)
	}

	/*
		EDITOR HANDLERS
	
	const handleCloseEditor = ({result, message}) => {
		setShowChallengeDataEditor(false);

		if (result !== "CANCELED" && message) {
			 if (result) setModalGeneralTitle("Success!!");  
			 else setModalGeneralTitle("Ooops!");
			 setModalGeneralMessage(message);
			 setShowModalGeneral(true);
		}
		if (DEBUG_MODE >= 2) console.log("Closed Challenge Data Editor", result, message);
  };

	//Pop up editor once template selected
	const handleUserCardSelected = (leaderboardSpot) => {
		if (DEBUG_MODE > 1) console.log("User Leaderboard Spot Selected.  Handler invoked.  Showing editor.", leaderboardSpot);
		setShowChallengeDataEditor(true);      			//pop-up editor to allow user to submit / edit challenge data
		setCurrentLeaderboardSpot(leaderboardSpot);     //record leaderboard slot
  };
*/

	//Render function
	return (

			<div className="ContainerVertical">
				<div className="lboard_section boxShadow">
					<div className="leaderboardSectionRow ContainerRowLeftStretch noWrap" >
						<div className="navigationPortion ContainerVerticalTopRight TextStyle3 " style={{flexGrow:"0"}}>
							<div className='TextStyle5 bold white ContainerNoHeightCenter fullWidth textAlignCenter'>Company Leaderboards</div>
							<ul >
								<li onClick = {() => tabClickHandler('day')} className={`'' ${lbToDisplay === 'day' ? "active TextStyle4" : "" }`} data-li="day">
									<div className="tabLabel"> Today </div>
								</li>
								<li onClick = {() => tabClickHandler('week')} className={`'' ${lbToDisplay === 'week' ? "active TextStyle4" : "" }`} data-li="week">
									<div className="tabLabel"> Week</div>
								</li>
								<li onClick = {() => tabClickHandler('month')} className={`'' ${lbToDisplay === 'month' ? "active TextStyle4" : "" }`} data-li="month">
									<div className="tabLabel"> Month</div>
								</li>
								<li onClick = {() => tabClickHandler('quarter')} className={`'' ${lbToDisplay === 'quarter' ? "active TextStyle4" : "" }`} data-li="quarter">
									<div className="tabLabel"> Quarter</div>
								</li>
								<li onClick = {() => tabClickHandler('year')} className={`'' ${lbToDisplay === 'year' ? "active TextStyle4" : "" }`} data-li="year">
									<div className="tabLabel"> Year</div>
								</li>
							</ul>

							{displayChallengeNav(activeChallenges)}
						</div>

						<div className="leaderboardPortion lboard_wrap white-background borderRadius positionRelative"> 
							<LeaderBoardList leaderBoard={currentLeaderBoard} displayZeros={displayZeros}  cardSelectedCallback={handleLeaderboardCardSelected} showCountdown showConectivityTitle />		
						</div>
					</div>
				</div>
			</div>

	);
});      
	
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConectereConfig = /* GraphQL */ `
  query GetConectereConfig($id: ID!) {
    getConectereConfig(id: $id) {
      id
      customerID
      master
      useBadges
      ringClosureFirmCap
      ringClosureOverride
      conectivityMinToCoinConversionOverride
      dollarToCoinConversion
      socialConectivitiesToCloseRing
      socialMinActiveConectivities
      socialCoinsToCloseRing
      socialPeriodToCloseRing
      socialRewardForClosingRing
      stressConectivitiesToCloseRing
      stressMinActiveConectivities
      stressCoinsToCloseRing
      stressPeriodToCloseRing
      stressRewardForClosingRing
      teamConectivitiesToCloseRing
      teamMinActiveConectivities
      teamCoinsToCloseRing
      teamPeriodToCloseRing
      teamRewardForClosingRing
      individualConectivitiesToCloseRing
      individualMinActiveConectivities
      individualCoinsToCloseRing
      individualPeriodToCloseRing
      individualRewardForClosingRing
      leaderBoardRewardDailyWin
      leaderBoardRewardWeeklyWin
      leaderBoardRewardMonthlyWin
      leaderBoardRewardQuarterlyWin
      leaderBoardRewardYearlyWin
      spotlightsEnabled
      spotlightsAdminSendOnly
      spotlightAmountSender
      spotlightAmountReceiver
      spotlightsFullBccEnabled
      spotlightAwardCappedAtTeamRing
      spotlightCastCapEnabled
      spotlightCastCapLimit
      spotlightCastCapPeriod
      newEmpConectivitySchedulingEnabled
      newEmployeeOnboardingConectivityID
      newEmployeeWelcomeConectivityID
      newEmployeeOneOnOneSupervisorConectivityID
      newEmployeeOneOnOneMentorConectivityID
      newEmployeeOneOnOneSponsorConectivityID
      newEmployeeLunchConectivityID
      newEmployeeOnboardingConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeWelcomeConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSupervisorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneMentorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSponsorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeLunchConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeConectivitiesToSchedule
      mentorProgramEnabled
      sponsorProgramEnabled
      mentorLaunchEventStartDateTime
      mentorLaunchRepeatOption
      mentorLaunchRepeatWeeklyOptions
      mentorLaunchRepeatMonthlyOptions
      mentorLaunchRepeatMonthlyOptions_2
      mentorLaunchRepeatMonthlyOptions_3
      mentorLaunchMonthOption1Checked
      mentorLaunchMonthOption2Checked
      sponsorLaunchEventStartDateTime
      sponsorLaunchRepeatOption
      sponsorLaunchRepeatWeeklyOptions
      sponsorLaunchRepeatMonthlyOptions
      sponsorLaunchRepeatMonthlyOptions_2
      sponsorLaunchRepeatMonthlyOptions_3
      sponsorLaunchMonthOption1Checked
      sponsorLaunchMonthOption2Checked
      slackEnabled
      slackSpotlightChannelID
      slackSpotlightChannelName
      slackChannelCreationEnabled
      slackOptions
      msTeamsEnabled
      msTeamsSpotlightChannelID
      msTeamsSpotlightWebhook
      msTeamsChannelCreationEnabled
      msTeamsSpotlightsInstallRecordID
      msTeamsOptions
      enableAdminChallenges
      enableEmployeeChallenges
      enableEmployeeChallengeRewards
      challengeCoinCap
      challengeBadgesDEICap
      challengeBadgesCSCap
      challengeRecognitionsCap
      disableAIChallengeRecognitionMessages
      enableEmployeeConnectionBuilder
      employeeConnectionBuilderOptions
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConectereConfigs = /* GraphQL */ `
  query ListConectereConfigs(
    $filter: ModelConectereConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConectereConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConfigByCustomer = /* GraphQL */ `
  query GetConfigByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelConectereConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getConfigByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      nickname
      logo
      virtualStore
      logoWidth
      logoHeight
      useExternalStore
      website
      activeEmployees
      fullName
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      configDetailsID
      customerBillingID
      createdAt
      updatedAt
      configDetails {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerBilling = /* GraphQL */ `
  query GetCustomerBilling($id: ID!) {
    getCustomerBilling(id: $id) {
      id
      customerID
      creditCardNumber
      creditCardHolder
      creditCardYear
      creditCardMonth
      creditCardExpiry
      creditCardIssuer
      creditCardCVC
      billingZipCode
      nextInvoiceNumber
      nextPaymentTransactionNumber
      subscriptionStartDate
      subscriptionEndDate
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerBillings = /* GraphQL */ `
  query ListCustomerBillings(
    $filter: ModelCustomerBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerBillings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        creditCardNumber
        creditCardHolder
        creditCardYear
        creditCardMonth
        creditCardExpiry
        creditCardIssuer
        creditCardCVC
        billingZipCode
        nextInvoiceNumber
        nextPaymentTransactionNumber
        subscriptionStartDate
        subscriptionEndDate
        subscription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerBillingByCustomer = /* GraphQL */ `
  query GetCustomerBillingByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerBillingByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        creditCardNumber
        creditCardHolder
        creditCardYear
        creditCardMonth
        creditCardExpiry
        creditCardIssuer
        creditCardCVC
        billingZipCode
        nextInvoiceNumber
        nextPaymentTransactionNumber
        subscriptionStartDate
        subscriptionEndDate
        subscription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      middleName
      lastName
      email
      title
      pronouns
      bio
      userCognitoID
      userNameCognito
      avatarWidth
      avatarHeight
      avatarUrl
      customerID
      userProgressDataID
      ccWalletID
      mentorID
      sponsorID
      supervisorID
      canBeMentor
      canBeSponsor
      canBeSupervisor
      isUnderrepresentedGroup
      isAdmin
      isSuperAdmin
      permissionLaunch
      permissionAnalytics
      permissionEditor
      permissionOrders
      permissionBilling
      onNumberOfTeams
      tutorialLevel
      googleCalendarEnabled
      msteamsCalendarEnabled
      msoutlookCalendarEnabled
      ms365CalendarEnabled
      icloudCalendarEnabled
      slackEnabled
      timeZoneCode
      startDate
      displayTheme
      options
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teams {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      ccWallet {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      userProgressData {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByCustomer = /* GraphQL */ `
  query GetUserByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByCognitoID = /* GraphQL */ `
  query GetUserByCognitoID(
    $userCognitoID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCognitoID(
      userCognitoID: $userCognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      nickname
      department
      avatarUrl
      avatarInitials
      customerID
      memberCount
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      users {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamByCustomerID = /* GraphQL */ `
  query GetTeamByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamUserJoin = /* GraphQL */ `
  query GetTeamUserJoin($id: ID!) {
    getTeamUserJoin(id: $id) {
      id
      customerID
      teamID
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      team {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listTeamUserJoins = /* GraphQL */ `
  query ListTeamUserJoins(
    $filter: ModelTeamUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamUserJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        teamID
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        team {
          id
          name
          nickname
          department
          avatarUrl
          avatarInitials
          customerID
          memberCount
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          users {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamUserJoinByUser = /* GraphQL */ `
  query GetTeamUserJoinByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamUserJoinByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        teamID
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        team {
          id
          name
          nickname
          department
          avatarUrl
          avatarInitials
          customerID
          memberCount
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          users {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamUserJoinByTeam = /* GraphQL */ `
  query GetTeamUserJoinByTeam(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamUserJoinByTeam(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        teamID
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        team {
          id
          name
          nickname
          department
          avatarUrl
          avatarInitials
          customerID
          memberCount
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          users {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamUserJoinByCustomer = /* GraphQL */ `
  query GetTeamUserJoinByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamUserJoinByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        teamID
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        team {
          id
          name
          nickname
          department
          avatarUrl
          avatarInitials
          customerID
          memberCount
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          users {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConectivity = /* GraphQL */ `
  query GetConectivity($id: ID!) {
    getConectivity(id: $id) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listConectivities = /* GraphQL */ `
  query ListConectivities(
    $filter: ModelConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConectivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConectivitiesByCustomer = /* GraphQL */ `
  query GetConectivitiesByCustomer(
    $createdByCustomerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getConectivitiesByCustomer(
      createdByCustomerID: $createdByCustomerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConectivitiesByScope = /* GraphQL */ `
  query GetConectivitiesByScope(
    $conectivityScope: String
    $sortDirection: ModelSortDirection
    $filter: ModelConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getConectivitiesByScope(
      conectivityScope: $conectivityScope
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScheduledConectivity = /* GraphQL */ `
  query GetScheduledConectivity($id: ID!) {
    getScheduledConectivity(id: $id) {
      id
      creationDateTime
      closingDateTime
      runningState
      customerID
      conectivityID
      launchRuleID
      options
      createdAt
      updatedAt
      conectivity {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      invitations {
        items {
          id
          customerID
          senderID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          messagesReadAt
          forLaunchedConectivity
          usersInvitedToParticipate
          teamsInvitedToParticipate
          scheduledConectivityID
          launchRuleID
          launchRuleInstanceIndex
          launchRuleInstanceStartDateTime
          launchRuleInstanceEndDateTime
          allDayEvent
          leaderboardID
          createdAt
          updatedAt
          launchRule {
            id
            customerID
            conectivityID
            usersToInvite
            teamsToInvite
            senderID
            message
            messageGIF
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            eventStartDateTime
            eventEndDateTime
            allDayEvent
            launchUntilDate
            launchUntilCount
            launchRepeatOption
            launchRepeatWeeklyOptions
            launchRepeatMonthlyOptions
            launchRepeatMonthlyOptions_2
            launchRepeatMonthlyOptions_3
            launchMonthOption1Checked
            launchMonthOption2Checked
            calendarUid
            calendarEventID
            parentRecurringEventId
            eventInstanceNumber
            originalLaunchStartDate
            nextInstanceNumber
            isMentorMeeting
            isSponsorMeeting
            messagesReadAtRecords {
              messagesReadAt
              userID
              __typename
            }
            rsvpRecords {
              userID
              rsvpStatus
              launchRuleID
              launchRuleInstanceIndex
              __typename
            }
            challengeID
            options
            createdAt
            updatedAt
            __typename
          }
          invitees {
            items {
              id
              inviteeID
              inviteeAvatarUrl
              inviteeAvatarName
              inviteeAvatarInitials
              invitationID
              messagesReadAt
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          messages {
            items {
              id
              customerID
              senderID
              message
              invitationID
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              parentMessage
              userLikes
              origin
              isSystemMessage
              mediaUrl
              launchRuleID
              recognitionID
              slackMessageID
              slackParentMessageID
              slackChannel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      designatedFor {
        items {
          id
          name
          label
          userID
          teamID
          scheduledConectivityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listScheduledConectivities = /* GraphQL */ `
  query ListScheduledConectivities(
    $filter: ModelScheduledConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduledConectivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creationDateTime
        closingDateTime
        runningState
        customerID
        conectivityID
        launchRuleID
        options
        createdAt
        updatedAt
        conectivity {
          id
          title
          description
          instructions
          image
          approvedPaidTime
          conectCoins
          badgesDEI
          badgesCS
          costPerPerson
          costFixed
          signupDays
          completionDays
          categoryID
          conectivityScope
          publicationStatus
          inactiveForCustomers
          authorID
          author
          authorUrl
          authorInitials
          createdByCustomerID
          createdByCustomerName
          createdByCustomerlogo
          sharedDate
          sharedConectivityID
          subscribingCustomers
          numDownloads
          userLikes
          ratingStatsID
          imageID
          isSchedulableByAdminOnly
          isTemplate
          isTutorial
          isManuallyScheduledOnly
          isSpecialEvent
          inviteAllEmployees
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          hasNoSpecificTime
          launchRepeatOption
          launchUntilDate
          launchUntilCount
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          isChallenge
          challengeOptions
          isReoccuring
          reocurringFrequency
          reocurringParamI
          reocurringParamS
          options
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          imageS3 {
            id
            name
            title
            description
            width
            height
            url
            categoryID
            createdAt
            updatedAt
            category {
              id
              name
              description
              image
              label
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          ratingStats {
            id
            conectivityID
            numberOfRatings
            ratingsAverage
            ratingsMax
            ratingsMin
            numberOfCompletions
            numberOfSkips
            numberOfLaunches
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        invitations {
          items {
            id
            customerID
            senderID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            messagesReadAt
            forLaunchedConectivity
            usersInvitedToParticipate
            teamsInvitedToParticipate
            scheduledConectivityID
            launchRuleID
            launchRuleInstanceIndex
            launchRuleInstanceStartDateTime
            launchRuleInstanceEndDateTime
            allDayEvent
            leaderboardID
            createdAt
            updatedAt
            launchRule {
              id
              customerID
              conectivityID
              usersToInvite
              teamsToInvite
              senderID
              message
              messageGIF
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              eventStartDateTime
              eventEndDateTime
              allDayEvent
              launchUntilDate
              launchUntilCount
              launchRepeatOption
              launchRepeatWeeklyOptions
              launchRepeatMonthlyOptions
              launchRepeatMonthlyOptions_2
              launchRepeatMonthlyOptions_3
              launchMonthOption1Checked
              launchMonthOption2Checked
              calendarUid
              calendarEventID
              parentRecurringEventId
              eventInstanceNumber
              originalLaunchStartDate
              nextInstanceNumber
              isMentorMeeting
              isSponsorMeeting
              messagesReadAtRecords {
                messagesReadAt
                userID
                __typename
              }
              rsvpRecords {
                userID
                rsvpStatus
                launchRuleID
                launchRuleInstanceIndex
                __typename
              }
              challengeID
              options
              createdAt
              updatedAt
              __typename
            }
            invitees {
              items {
                id
                inviteeID
                inviteeAvatarUrl
                inviteeAvatarName
                inviteeAvatarInitials
                invitationID
                messagesReadAt
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            messages {
              items {
                id
                customerID
                senderID
                message
                invitationID
                senderAvatarUrl
                senderAvatarName
                senderAvatarInitials
                parentMessage
                userLikes
                origin
                isSystemMessage
                mediaUrl
                launchRuleID
                recognitionID
                slackMessageID
                slackParentMessageID
                slackChannel
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        designatedFor {
          items {
            id
            name
            label
            userID
            teamID
            scheduledConectivityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScheduledConectivitiesByCustomer = /* GraphQL */ `
  query GetScheduledConectivitiesByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelScheduledConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getScheduledConectivitiesByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creationDateTime
        closingDateTime
        runningState
        customerID
        conectivityID
        launchRuleID
        options
        createdAt
        updatedAt
        conectivity {
          id
          title
          description
          instructions
          image
          approvedPaidTime
          conectCoins
          badgesDEI
          badgesCS
          costPerPerson
          costFixed
          signupDays
          completionDays
          categoryID
          conectivityScope
          publicationStatus
          inactiveForCustomers
          authorID
          author
          authorUrl
          authorInitials
          createdByCustomerID
          createdByCustomerName
          createdByCustomerlogo
          sharedDate
          sharedConectivityID
          subscribingCustomers
          numDownloads
          userLikes
          ratingStatsID
          imageID
          isSchedulableByAdminOnly
          isTemplate
          isTutorial
          isManuallyScheduledOnly
          isSpecialEvent
          inviteAllEmployees
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          hasNoSpecificTime
          launchRepeatOption
          launchUntilDate
          launchUntilCount
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          isChallenge
          challengeOptions
          isReoccuring
          reocurringFrequency
          reocurringParamI
          reocurringParamS
          options
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          imageS3 {
            id
            name
            title
            description
            width
            height
            url
            categoryID
            createdAt
            updatedAt
            category {
              id
              name
              description
              image
              label
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          ratingStats {
            id
            conectivityID
            numberOfRatings
            ratingsAverage
            ratingsMax
            ratingsMin
            numberOfCompletions
            numberOfSkips
            numberOfLaunches
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        invitations {
          items {
            id
            customerID
            senderID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            messagesReadAt
            forLaunchedConectivity
            usersInvitedToParticipate
            teamsInvitedToParticipate
            scheduledConectivityID
            launchRuleID
            launchRuleInstanceIndex
            launchRuleInstanceStartDateTime
            launchRuleInstanceEndDateTime
            allDayEvent
            leaderboardID
            createdAt
            updatedAt
            launchRule {
              id
              customerID
              conectivityID
              usersToInvite
              teamsToInvite
              senderID
              message
              messageGIF
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              eventStartDateTime
              eventEndDateTime
              allDayEvent
              launchUntilDate
              launchUntilCount
              launchRepeatOption
              launchRepeatWeeklyOptions
              launchRepeatMonthlyOptions
              launchRepeatMonthlyOptions_2
              launchRepeatMonthlyOptions_3
              launchMonthOption1Checked
              launchMonthOption2Checked
              calendarUid
              calendarEventID
              parentRecurringEventId
              eventInstanceNumber
              originalLaunchStartDate
              nextInstanceNumber
              isMentorMeeting
              isSponsorMeeting
              messagesReadAtRecords {
                messagesReadAt
                userID
                __typename
              }
              rsvpRecords {
                userID
                rsvpStatus
                launchRuleID
                launchRuleInstanceIndex
                __typename
              }
              challengeID
              options
              createdAt
              updatedAt
              __typename
            }
            invitees {
              items {
                id
                inviteeID
                inviteeAvatarUrl
                inviteeAvatarName
                inviteeAvatarInitials
                invitationID
                messagesReadAt
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            messages {
              items {
                id
                customerID
                senderID
                message
                invitationID
                senderAvatarUrl
                senderAvatarName
                senderAvatarInitials
                parentMessage
                userLikes
                origin
                isSystemMessage
                mediaUrl
                launchRuleID
                recognitionID
                slackMessageID
                slackParentMessageID
                slackChannel
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        designatedFor {
          items {
            id
            name
            label
            userID
            teamID
            scheduledConectivityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScheduledConectivitiesByCustomerByState = /* GraphQL */ `
  query GetScheduledConectivitiesByCustomerByState(
    $customerID: ID
    $runningState: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduledConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getScheduledConectivitiesByCustomerByState(
      customerID: $customerID
      runningState: $runningState
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creationDateTime
        closingDateTime
        runningState
        customerID
        conectivityID
        launchRuleID
        options
        createdAt
        updatedAt
        conectivity {
          id
          title
          description
          instructions
          image
          approvedPaidTime
          conectCoins
          badgesDEI
          badgesCS
          costPerPerson
          costFixed
          signupDays
          completionDays
          categoryID
          conectivityScope
          publicationStatus
          inactiveForCustomers
          authorID
          author
          authorUrl
          authorInitials
          createdByCustomerID
          createdByCustomerName
          createdByCustomerlogo
          sharedDate
          sharedConectivityID
          subscribingCustomers
          numDownloads
          userLikes
          ratingStatsID
          imageID
          isSchedulableByAdminOnly
          isTemplate
          isTutorial
          isManuallyScheduledOnly
          isSpecialEvent
          inviteAllEmployees
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          hasNoSpecificTime
          launchRepeatOption
          launchUntilDate
          launchUntilCount
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          isChallenge
          challengeOptions
          isReoccuring
          reocurringFrequency
          reocurringParamI
          reocurringParamS
          options
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          imageS3 {
            id
            name
            title
            description
            width
            height
            url
            categoryID
            createdAt
            updatedAt
            category {
              id
              name
              description
              image
              label
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          ratingStats {
            id
            conectivityID
            numberOfRatings
            ratingsAverage
            ratingsMax
            ratingsMin
            numberOfCompletions
            numberOfSkips
            numberOfLaunches
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        invitations {
          items {
            id
            customerID
            senderID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            messagesReadAt
            forLaunchedConectivity
            usersInvitedToParticipate
            teamsInvitedToParticipate
            scheduledConectivityID
            launchRuleID
            launchRuleInstanceIndex
            launchRuleInstanceStartDateTime
            launchRuleInstanceEndDateTime
            allDayEvent
            leaderboardID
            createdAt
            updatedAt
            launchRule {
              id
              customerID
              conectivityID
              usersToInvite
              teamsToInvite
              senderID
              message
              messageGIF
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              eventStartDateTime
              eventEndDateTime
              allDayEvent
              launchUntilDate
              launchUntilCount
              launchRepeatOption
              launchRepeatWeeklyOptions
              launchRepeatMonthlyOptions
              launchRepeatMonthlyOptions_2
              launchRepeatMonthlyOptions_3
              launchMonthOption1Checked
              launchMonthOption2Checked
              calendarUid
              calendarEventID
              parentRecurringEventId
              eventInstanceNumber
              originalLaunchStartDate
              nextInstanceNumber
              isMentorMeeting
              isSponsorMeeting
              messagesReadAtRecords {
                messagesReadAt
                userID
                __typename
              }
              rsvpRecords {
                userID
                rsvpStatus
                launchRuleID
                launchRuleInstanceIndex
                __typename
              }
              challengeID
              options
              createdAt
              updatedAt
              __typename
            }
            invitees {
              items {
                id
                inviteeID
                inviteeAvatarUrl
                inviteeAvatarName
                inviteeAvatarInitials
                invitationID
                messagesReadAt
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            messages {
              items {
                id
                customerID
                senderID
                message
                invitationID
                senderAvatarUrl
                senderAvatarName
                senderAvatarInitials
                parentMessage
                userLikes
                origin
                isSystemMessage
                mediaUrl
                launchRuleID
                recognitionID
                slackMessageID
                slackParentMessageID
                slackChannel
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        designatedFor {
          items {
            id
            name
            label
            userID
            teamID
            scheduledConectivityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scheduledConectivityByState = /* GraphQL */ `
  query ScheduledConectivityByState(
    $runningState: String
    $sortDirection: ModelSortDirection
    $filter: ModelScheduledConectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduledConectivityByState(
      runningState: $runningState
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creationDateTime
        closingDateTime
        runningState
        customerID
        conectivityID
        launchRuleID
        options
        createdAt
        updatedAt
        conectivity {
          id
          title
          description
          instructions
          image
          approvedPaidTime
          conectCoins
          badgesDEI
          badgesCS
          costPerPerson
          costFixed
          signupDays
          completionDays
          categoryID
          conectivityScope
          publicationStatus
          inactiveForCustomers
          authorID
          author
          authorUrl
          authorInitials
          createdByCustomerID
          createdByCustomerName
          createdByCustomerlogo
          sharedDate
          sharedConectivityID
          subscribingCustomers
          numDownloads
          userLikes
          ratingStatsID
          imageID
          isSchedulableByAdminOnly
          isTemplate
          isTutorial
          isManuallyScheduledOnly
          isSpecialEvent
          inviteAllEmployees
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          hasNoSpecificTime
          launchRepeatOption
          launchUntilDate
          launchUntilCount
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          isChallenge
          challengeOptions
          isReoccuring
          reocurringFrequency
          reocurringParamI
          reocurringParamS
          options
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          imageS3 {
            id
            name
            title
            description
            width
            height
            url
            categoryID
            createdAt
            updatedAt
            category {
              id
              name
              description
              image
              label
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          ratingStats {
            id
            conectivityID
            numberOfRatings
            ratingsAverage
            ratingsMax
            ratingsMin
            numberOfCompletions
            numberOfSkips
            numberOfLaunches
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        invitations {
          items {
            id
            customerID
            senderID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            messagesReadAt
            forLaunchedConectivity
            usersInvitedToParticipate
            teamsInvitedToParticipate
            scheduledConectivityID
            launchRuleID
            launchRuleInstanceIndex
            launchRuleInstanceStartDateTime
            launchRuleInstanceEndDateTime
            allDayEvent
            leaderboardID
            createdAt
            updatedAt
            launchRule {
              id
              customerID
              conectivityID
              usersToInvite
              teamsToInvite
              senderID
              message
              messageGIF
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              eventStartDateTime
              eventEndDateTime
              allDayEvent
              launchUntilDate
              launchUntilCount
              launchRepeatOption
              launchRepeatWeeklyOptions
              launchRepeatMonthlyOptions
              launchRepeatMonthlyOptions_2
              launchRepeatMonthlyOptions_3
              launchMonthOption1Checked
              launchMonthOption2Checked
              calendarUid
              calendarEventID
              parentRecurringEventId
              eventInstanceNumber
              originalLaunchStartDate
              nextInstanceNumber
              isMentorMeeting
              isSponsorMeeting
              messagesReadAtRecords {
                messagesReadAt
                userID
                __typename
              }
              rsvpRecords {
                userID
                rsvpStatus
                launchRuleID
                launchRuleInstanceIndex
                __typename
              }
              challengeID
              options
              createdAt
              updatedAt
              __typename
            }
            invitees {
              items {
                id
                inviteeID
                inviteeAvatarUrl
                inviteeAvatarName
                inviteeAvatarInitials
                invitationID
                messagesReadAt
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            messages {
              items {
                id
                customerID
                senderID
                message
                invitationID
                senderAvatarUrl
                senderAvatarName
                senderAvatarInitials
                parentMessage
                userLikes
                origin
                isSystemMessage
                mediaUrl
                launchRuleID
                recognitionID
                slackMessageID
                slackParentMessageID
                slackChannel
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        designatedFor {
          items {
            id
            name
            label
            userID
            teamID
            scheduledConectivityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLaunchRule = /* GraphQL */ `
  query GetLaunchRule($id: ID!) {
    getLaunchRule(id: $id) {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLaunchRules = /* GraphQL */ `
  query ListLaunchRules(
    $filter: ModelLaunchRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLaunchRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLaunchRulesByCustomerByStartDate = /* GraphQL */ `
  query GetLaunchRulesByCustomerByStartDate(
    $customerID: ID
    $eventStartDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLaunchRulesByCustomerByStartDate(
      customerID: $customerID
      eventStartDateTime: $eventStartDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLaunchRulesByCustomer = /* GraphQL */ `
  query GetLaunchRulesByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLaunchRulesByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      messagesReadAt
      forLaunchedConectivity
      usersInvitedToParticipate
      teamsInvitedToParticipate
      scheduledConectivityID
      launchRuleID
      launchRuleInstanceIndex
      launchRuleInstanceStartDateTime
      launchRuleInstanceEndDateTime
      allDayEvent
      leaderboardID
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      invitees {
        items {
          id
          inviteeID
          inviteeAvatarUrl
          inviteeAvatarName
          inviteeAvatarInitials
          invitationID
          messagesReadAt
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        messagesReadAt
        forLaunchedConectivity
        usersInvitedToParticipate
        teamsInvitedToParticipate
        scheduledConectivityID
        launchRuleID
        launchRuleInstanceIndex
        launchRuleInstanceStartDateTime
        launchRuleInstanceEndDateTime
        allDayEvent
        leaderboardID
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        invitees {
          items {
            id
            inviteeID
            inviteeAvatarUrl
            inviteeAvatarName
            inviteeAvatarInitials
            invitationID
            messagesReadAt
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitationByConectivityByStatus = /* GraphQL */ `
  query GetInvitationByConectivityByStatus(
    $scheduledConectivityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationByConectivityByStatus(
      scheduledConectivityID: $scheduledConectivityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        messagesReadAt
        forLaunchedConectivity
        usersInvitedToParticipate
        teamsInvitedToParticipate
        scheduledConectivityID
        launchRuleID
        launchRuleInstanceIndex
        launchRuleInstanceStartDateTime
        launchRuleInstanceEndDateTime
        allDayEvent
        leaderboardID
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        invitees {
          items {
            id
            inviteeID
            inviteeAvatarUrl
            inviteeAvatarName
            inviteeAvatarInitials
            invitationID
            messagesReadAt
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitationByCustomerID = /* GraphQL */ `
  query GetInvitationByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        messagesReadAt
        forLaunchedConectivity
        usersInvitedToParticipate
        teamsInvitedToParticipate
        scheduledConectivityID
        launchRuleID
        launchRuleInstanceIndex
        launchRuleInstanceStartDateTime
        launchRuleInstanceEndDateTime
        allDayEvent
        leaderboardID
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        invitees {
          items {
            id
            inviteeID
            inviteeAvatarUrl
            inviteeAvatarName
            inviteeAvatarInitials
            invitationID
            messagesReadAt
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitationByLaunchRule = /* GraphQL */ `
  query GetInvitationByLaunchRule(
    $launchRuleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationByLaunchRule(
      launchRuleID: $launchRuleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        messagesReadAt
        forLaunchedConectivity
        usersInvitedToParticipate
        teamsInvitedToParticipate
        scheduledConectivityID
        launchRuleID
        launchRuleInstanceIndex
        launchRuleInstanceStartDateTime
        launchRuleInstanceEndDateTime
        allDayEvent
        leaderboardID
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        invitees {
          items {
            id
            inviteeID
            inviteeAvatarUrl
            inviteeAvatarName
            inviteeAvatarInitials
            invitationID
            messagesReadAt
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitee = /* GraphQL */ `
  query GetInvitee($id: ID!) {
    getInvitee(id: $id) {
      id
      inviteeID
      inviteeAvatarUrl
      inviteeAvatarName
      inviteeAvatarInitials
      invitationID
      messagesReadAt
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvitees = /* GraphQL */ `
  query ListInvitees(
    $filter: ModelInviteeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inviteeID
        inviteeAvatarUrl
        inviteeAvatarName
        inviteeAvatarInitials
        invitationID
        messagesReadAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInviteeByInvitation = /* GraphQL */ `
  query GetInviteeByInvitation(
    $invitationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInviteeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInviteeByInvitation(
      invitationID: $invitationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inviteeID
        inviteeAvatarUrl
        inviteeAvatarName
        inviteeAvatarInitials
        invitationID
        messagesReadAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInviteeByInviteeID = /* GraphQL */ `
  query GetInviteeByInviteeID(
    $inviteeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInviteeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInviteeByInviteeID(
      inviteeID: $inviteeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inviteeID
        inviteeAvatarUrl
        inviteeAvatarName
        inviteeAvatarInitials
        invitationID
        messagesReadAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      customerID
      senderID
      message
      invitationID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentMessage
      userLikes
      origin
      isSystemMessage
      mediaUrl
      launchRuleID
      recognitionID
      slackMessageID
      slackParentMessageID
      slackChannel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageByInvitation = /* GraphQL */ `
  query GetMessageByInvitation(
    $invitationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByInvitation(
      invitationID: $invitationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageByLaunchRule = /* GraphQL */ `
  query GetMessageByLaunchRule(
    $launchRuleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByLaunchRule(
      launchRuleID: $launchRuleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageByRecognition = /* GraphQL */ `
  query GetMessageByRecognition(
    $recognitionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByRecognition(
      recognitionID: $recognitionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageBySlackMessageID = /* GraphQL */ `
  query GetMessageBySlackMessageID(
    $slackMessageID: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageBySlackMessageID(
      slackMessageID: $slackMessageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageByCustomerID = /* GraphQL */ `
  query GetMessageByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessagePacket = /* GraphQL */ `
  query GetMessagePacket($id: ID!) {
    getMessagePacket(id: $id) {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listMessagePackets = /* GraphQL */ `
  query ListMessagePackets(
    $filter: ModelMessagePacketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagePackets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        customerID
        messageID
        createdAt
        updatedAt
        message {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessagePacketsByUser = /* GraphQL */ `
  query GetMessagePacketsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessagePacketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagePacketsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        messageID
        createdAt
        updatedAt
        message {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageByCustomer = /* GraphQL */ `
  query GetMessageByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessagePacketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        messageID
        createdAt
        updatedAt
        message {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessagePacketByMessageID = /* GraphQL */ `
  query GetMessagePacketByMessageID(
    $messageID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessagePacketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagePacketByMessageID(
      messageID: $messageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        messageID
        createdAt
        updatedAt
        message {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThread = /* GraphQL */ `
  query GetMessageThread($id: ID!) {
    getMessageThread(id: $id) {
      id
      channelID
      threadID
      customerID
      launchRuleID
      invitationID
      scheduledConectivityID
      messageID
      spotlightID
      senderID
      inviteeID
      isConectivitySpecific
      isSpotlightSpecific
      threadStatus
      cloudProvider
      threadParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessageThreads = /* GraphQL */ `
  query ListMessageThreads(
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThreadsByCustomer = /* GraphQL */ `
  query GetMessageThreadsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageThreadsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThreadsByInvitation = /* GraphQL */ `
  query GetMessageThreadsByInvitation(
    $invitationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageThreadsByInvitation(
      invitationID: $invitationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThreadsByLaunchRule = /* GraphQL */ `
  query GetMessageThreadsByLaunchRule(
    $launchRuleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageThreadsByLaunchRule(
      launchRuleID: $launchRuleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThreadsByChannel = /* GraphQL */ `
  query GetMessageThreadsByChannel(
    $channelID: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageThreadsByChannel(
      channelID: $channelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThreadsBySpotlight = /* GraphQL */ `
  query GetMessageThreadsBySpotlight(
    $spotlightID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageThreadsBySpotlight(
      spotlightID: $spotlightID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageThreadsByThreadStatus = /* GraphQL */ `
  query GetMessageThreadsByThreadStatus(
    $threadStatus: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageThreadsByThreadStatus(
      threadStatus: $threadStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        threadID
        customerID
        launchRuleID
        invitationID
        scheduledConectivityID
        messageID
        spotlightID
        senderID
        inviteeID
        isConectivitySpecific
        isSpotlightSpecific
        threadStatus
        cloudProvider
        threadParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDesignator = /* GraphQL */ `
  query GetDesignator($id: ID!) {
    getDesignator(id: $id) {
      id
      name
      label
      userID
      teamID
      scheduledConectivityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDesignators = /* GraphQL */ `
  query ListDesignators(
    $filter: ModelDesignatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        label
        userID
        teamID
        scheduledConectivityID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCWallet = /* GraphQL */ `
  query GetCCWallet($id: ID!) {
    getCCWallet(id: $id) {
      id
      dateCreated
      description
      currentBalance
      badgesDEI
      badgesCS
      badgesSpotlight
      status
      nextSequenceNumber
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listCCWallets = /* GraphQL */ `
  query ListCCWallets(
    $filter: ModelCCWalletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCCWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCWalletByUser = /* GraphQL */ `
  query GetCCWalletByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCCWalletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCWalletByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransaction = /* GraphQL */ `
  query GetCCTransaction($id: ID!) {
    getCCTransaction(id: $id) {
      id
      createdAt
      timeSeries
      sequenceNumber
      transactionType
      name
      amount
      dollarCost
      badgesDEI
      badgesCS
      badgesSpotlight
      newBalance
      badgesCSBalance
      badgesDEIBalance
      badgesSpotlightBalance
      memo
      image
      ccWalletID
      conectivityID
      category
      scheduledConectivityID
      usersThatParticipatedWithMe
      teamsThatParticipatedWithMe
      orderID
      recognitionID
      leaderboardID
      slotID
      customerID
      userID
      productID
      productTitle
      updatedAt
      __typename
    }
  }
`;
export const listCCTransactions = /* GraphQL */ `
  query ListCCTransactions(
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCCTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByUserByTransactionType = /* GraphQL */ `
  query GetCCTransactionsByUserByTransactionType(
    $userID: ID
    $transactionType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByUserByTransactionType(
      userID: $userID
      transactionType: $transactionType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByUserByDate = /* GraphQL */ `
  query GetCCTransactionsByUserByDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByUserByDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByWalletIDBySequenceNumber = /* GraphQL */ `
  query GetCCTransactionsByWalletIDBySequenceNumber(
    $ccWalletID: ID
    $sequenceNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByWalletIDBySequenceNumber(
      ccWalletID: $ccWalletID
      sequenceNumber: $sequenceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsBytransactionType = /* GraphQL */ `
  query GetCCTransactionsBytransactionType(
    $transactionType: String
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsBytransactionType(
      transactionType: $transactionType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByCustomerIDBySequenceNumber = /* GraphQL */ `
  query GetCCTransactionsByCustomerIDBySequenceNumber(
    $customerID: ID
    $sequenceNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByCustomerIDBySequenceNumber(
      customerID: $customerID
      sequenceNumber: $sequenceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByCustomerIDByTransactionType = /* GraphQL */ `
  query GetCCTransactionsByCustomerIDByTransactionType(
    $customerID: ID
    $transactionType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByCustomerIDByTransactionType(
      customerID: $customerID
      transactionType: $transactionType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByCustomerIDByCreatedAt = /* GraphQL */ `
  query GetCCTransactionsByCustomerIDByCreatedAt(
    $customerID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByCustomerIDByCreatedAt(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByCustomerIDByScheduledConectivityID = /* GraphQL */ `
  query GetCCTransactionsByCustomerIDByScheduledConectivityID(
    $customerID: ID
    $scheduledConectivityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByCustomerIDByScheduledConectivityID(
      customerID: $customerID
      scheduledConectivityID: $scheduledConectivityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCCTransactionsByTimeSeriesByCreatedAt = /* GraphQL */ `
  query GetCCTransactionsByTimeSeriesByCreatedAt(
    $timeSeries: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCCTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCCTransactionsByTimeSeriesByCreatedAt(
      timeSeries: $timeSeries
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timeSeries
        sequenceNumber
        transactionType
        name
        amount
        dollarCost
        badgesDEI
        badgesCS
        badgesSpotlight
        newBalance
        badgesCSBalance
        badgesDEIBalance
        badgesSpotlightBalance
        memo
        image
        ccWalletID
        conectivityID
        category
        scheduledConectivityID
        usersThatParticipatedWithMe
        teamsThatParticipatedWithMe
        orderID
        recognitionID
        leaderboardID
        slotID
        customerID
        userID
        productID
        productTitle
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerLeaderBoard = /* GraphQL */ `
  query GetCustomerLeaderBoard($id: ID!) {
    getCustomerLeaderBoard(id: $id) {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCustomerLeaderBoards = /* GraphQL */ `
  query ListCustomerLeaderBoards(
    $filter: ModelCustomerLeaderBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerLeaderBoards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        leaderBoardType
        isViewableCompanyWide
        isAllEmployees
        lastDeltaDate
        isChallenge
        launchRuleID
        launchRuleInstanceIndex
        startDateTime
        endDateTime
        allDayEvent
        challengeStatus
        createdAt
        updatedAt
        leaderBoardSpots {
          items {
            id
            customerID
            userID
            teamID
            customerLeaderBoardID
            currentBalance
            firstName
            lastName
            title
            rank
            userRank
            userMove
            avatarUrl
            leaderBoard
            firstEntry
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardByCustomerID = /* GraphQL */ `
  query GetLeaderBoardByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerLeaderBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        leaderBoardType
        isViewableCompanyWide
        isAllEmployees
        lastDeltaDate
        isChallenge
        launchRuleID
        launchRuleInstanceIndex
        startDateTime
        endDateTime
        allDayEvent
        challengeStatus
        createdAt
        updatedAt
        leaderBoardSpots {
          items {
            id
            customerID
            userID
            teamID
            customerLeaderBoardID
            currentBalance
            firstName
            lastName
            title
            rank
            userRank
            userMove
            avatarUrl
            leaderBoard
            firstEntry
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardByChallengeStatusByEndDate = /* GraphQL */ `
  query GetLeaderBoardByChallengeStatusByEndDate(
    $challengeStatus: ChallengeStatus
    $endDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerLeaderBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardByChallengeStatusByEndDate(
      challengeStatus: $challengeStatus
      endDateTime: $endDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        leaderBoardType
        isViewableCompanyWide
        isAllEmployees
        lastDeltaDate
        isChallenge
        launchRuleID
        launchRuleInstanceIndex
        startDateTime
        endDateTime
        allDayEvent
        challengeStatus
        createdAt
        updatedAt
        leaderBoardSpots {
          items {
            id
            customerID
            userID
            teamID
            customerLeaderBoardID
            currentBalance
            firstName
            lastName
            title
            rank
            userRank
            userMove
            avatarUrl
            leaderBoard
            firstEntry
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardSpot = /* GraphQL */ `
  query GetLeaderBoardSpot($id: ID!) {
    getLeaderBoardSpot(id: $id) {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeaderBoardSpots = /* GraphQL */ `
  query ListLeaderBoardSpots(
    $filter: ModelLeaderBoardSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaderBoardSpots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        customerLeaderBoardID
        currentBalance
        firstName
        lastName
        title
        rank
        userRank
        userMove
        avatarUrl
        leaderBoard
        firstEntry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardSpotsByCustomerID = /* GraphQL */ `
  query GetLeaderBoardSpotsByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderBoardSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardSpotsByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        customerLeaderBoardID
        currentBalance
        firstName
        lastName
        title
        rank
        userRank
        userMove
        avatarUrl
        leaderBoard
        firstEntry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardSpotsByCustomerLeaderBoard = /* GraphQL */ `
  query GetLeaderBoardSpotsByCustomerLeaderBoard(
    $customerLeaderBoardID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderBoardSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardSpotsByCustomerLeaderBoard(
      customerLeaderBoardID: $customerLeaderBoardID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        customerLeaderBoardID
        currentBalance
        firstName
        lastName
        title
        rank
        userRank
        userMove
        avatarUrl
        leaderBoard
        firstEntry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardSpotsByCustomerLeaderBoardByUserID = /* GraphQL */ `
  query GetLeaderBoardSpotsByCustomerLeaderBoardByUserID(
    $customerLeaderBoardID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderBoardSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardSpotsByCustomerLeaderBoardByUserID(
      customerLeaderBoardID: $customerLeaderBoardID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        customerLeaderBoardID
        currentBalance
        firstName
        lastName
        title
        rank
        userRank
        userMove
        avatarUrl
        leaderBoard
        firstEntry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderBoardSpotsByCustomerLeaderBoardByTeamID = /* GraphQL */ `
  query GetLeaderBoardSpotsByCustomerLeaderBoardByTeamID(
    $customerLeaderBoardID: ID
    $teamID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderBoardSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardSpotsByCustomerLeaderBoardByTeamID(
      customerLeaderBoardID: $customerLeaderBoardID
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        customerLeaderBoardID
        currentBalance
        firstName
        lastName
        title
        rank
        userRank
        userMove
        avatarUrl
        leaderBoard
        firstEntry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProgressData = /* GraphQL */ `
  query GetUserProgressData($id: ID!) {
    getUserProgressData(id: $id) {
      id
      userID
      customerID
      subscriptionTrigger
      socialPeriodCoinsEarned
      socialPeriodStart
      socialPeriodEnd
      socialPeriod
      socialPeriodCoinGoal
      socialPeriodConectivitiesComplete
      socialPeriodBonusGiven
      stressPeriodCoinsEarned
      stressPeriodStart
      stressPeriodEnd
      stressPeriod
      stressPeriodCoinGoal
      stressPeriodConectivitiesComplete
      stressPeriodBonusGiven
      teamPeriodCoinsEarned
      teamPeriodStart
      teamPeriodEnd
      teamPeriod
      teamPeriodCoinGoal
      teamPeriodConectivitiesComplete
      teamPeriodBonusGiven
      individualPeriodCoinsEarned
      individualPeriodStart
      individualPeriodEnd
      individualPeriod
      individualPeriodCoinGoal
      individualPeriodConectivitiesComplete
      individualPeriodBonusGiven
      spotlightPeriodNumCast
      spotlightPeriodStart
      spotlightPeriodEnd
      spotlightPeriod
      dayCoinsEarned
      dayClosingDateTime
      dayConectivitiesComplete
      weekCoinsEarned
      weekClosingDateTime
      weekConectivitiesComplete
      monthCoinsEarned
      monthClosingDateTime
      monthConectivitiesComplete
      quarterCoinsEarned
      quarterClosingDateTime
      quarterConectivitiesComplete
      yearCoinsEarned
      yearClosingDateTime
      yearConectivitiesComplete
      balanceStreakCount
      balanceStreakStartDate
      balanceStreakLastClosureDate
      socialStreakCount
      socialStreakStartDate
      socialStreakLastClosureDate
      teamStreakCount
      teamStreakStartDate
      teamStreakLastClosureDate
      growthStreakCount
      growthStreakStartDate
      growthStreakLastClosureDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserProgressData = /* GraphQL */ `
  query ListUserProgressData(
    $filter: ModelUserProgressDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProgressData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProgressDataByUser = /* GraphQL */ `
  query GetUserProgressDataByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserProgressDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserProgressDataByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProgressDataByCustomer = /* GraphQL */ `
  query GetUserProgressDataByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserProgressDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserProgressDataByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductsByCustomer = /* GraphQL */ `
  query GetProductsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductsByScope = /* GraphQL */ `
  query GetProductsByScope(
    $productScope: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsByScope(
      productScope: $productScope
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($id: ID!) {
    getProductCategory(id: $id) {
      id
      title
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        image
        label
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      name
      title
      description
      width
      height
      url
      categoryID
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderStatus
        amount
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        tax
        shipping
        total
        dateProcessed
        memo
        productID
        customerID
        userID
        createdAt
        updatedAt
        product {
          id
          customerID
          title
          description
          image
          imageWidth
          imageHeight
          featured
          charity
          price
          badgesDEI
          badgesCS
          badgesSpotlight
          dollarCost
          useInStore
          inactiveForCustomers
          productScope
          externalLink
          expiration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrdersByCustomer = /* GraphQL */ `
  query GetOrdersByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderStatus
        amount
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        tax
        shipping
        total
        dateProcessed
        memo
        productID
        customerID
        userID
        createdAt
        updatedAt
        product {
          id
          customerID
          title
          description
          image
          imageWidth
          imageHeight
          featured
          charity
          price
          badgesDEI
          badgesCS
          badgesSpotlight
          dollarCost
          useInStore
          inactiveForCustomers
          productScope
          externalLink
          expiration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrdersByUser = /* GraphQL */ `
  query GetOrdersByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderStatus
        amount
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        tax
        shipping
        total
        dateProcessed
        memo
        productID
        customerID
        userID
        createdAt
        updatedAt
        product {
          id
          customerID
          title
          description
          image
          imageWidth
          imageHeight
          featured
          charity
          price
          badgesDEI
          badgesCS
          badgesSpotlight
          dollarCost
          useInStore
          inactiveForCustomers
          productScope
          externalLink
          expiration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        customerID
        customerName
        invoiceNumber
        billingPeriodStartDate
        billingPeriodEndingDate
        description
        charges
        credits
        tax
        shipping
        discount
        total
        activeUsersForBillingPeriod
        serviceForBillingPeriod
        invoiceStatus
        datePaid
        updatedAt
        lineItems {
          items {
            id
            invoiceID
            lineItemType
            memo
            amount
            taxable
            orderID
            quantity
            price
            discount
            tax
            shipping
            fulfillmentFee
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoicesByCustomerByDate = /* GraphQL */ `
  query GetInvoicesByCustomerByDate(
    $customerID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvoicesByCustomerByDate(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        customerID
        customerName
        invoiceNumber
        billingPeriodStartDate
        billingPeriodEndingDate
        description
        charges
        credits
        tax
        shipping
        discount
        total
        activeUsersForBillingPeriod
        serviceForBillingPeriod
        invoiceStatus
        datePaid
        updatedAt
        lineItems {
          items {
            id
            invoiceID
            lineItemType
            memo
            amount
            taxable
            orderID
            quantity
            price
            discount
            tax
            shipping
            fulfillmentFee
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoiceLineItems = /* GraphQL */ `
  query GetInvoiceLineItems($id: ID!) {
    getInvoiceLineItems(id: $id) {
      id
      invoiceID
      lineItemType
      memo
      amount
      taxable
      orderID
      quantity
      price
      discount
      tax
      shipping
      fulfillmentFee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoiceLineItems = /* GraphQL */ `
  query ListInvoiceLineItems(
    $filter: ModelInvoiceLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceID
        lineItemType
        memo
        amount
        taxable
        orderID
        quantity
        price
        discount
        tax
        shipping
        fulfillmentFee
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoiceLineItemsByInvoice = /* GraphQL */ `
  query GetInvoiceLineItemsByInvoice(
    $invoiceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvoiceLineItemsByInvoice(
      invoiceID: $invoiceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceID
        lineItemType
        memo
        amount
        taxable
        orderID
        quantity
        price
        discount
        tax
        shipping
        fulfillmentFee
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentTransaction = /* GraphQL */ `
  query GetPaymentTransaction($id: ID!) {
    getPaymentTransaction(id: $id) {
      id
      customerID
      invoiceID
      transactionNumber
      memo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaymentTransactions = /* GraphQL */ `
  query ListPaymentTransactions(
    $filter: ModelpaymentTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        invoiceID
        transactionNumber
        memo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentsByCustomer = /* GraphQL */ `
  query GetPaymentsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelpaymentTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        invoiceID
        transactionNumber
        memo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentsByInvoice = /* GraphQL */ `
  query GetPaymentsByInvoice(
    $invoiceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelpaymentTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentsByInvoice(
      invoiceID: $invoiceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        invoiceID
        transactionNumber
        memo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognitionType = /* GraphQL */ `
  query GetRecognitionType($id: ID!) {
    getRecognitionType(id: $id) {
      id
      recognitionType
      message
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecognitionTypes = /* GraphQL */ `
  query ListRecognitionTypes(
    $filter: ModelRecognitionTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecognitionTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recognitionType
        message
        image
        imageID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognition = /* GraphQL */ `
  query GetRecognition($id: ID!) {
    getRecognition(id: $id) {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listRecognitions = /* GraphQL */ `
  query ListRecognitions(
    $filter: ModelRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecognitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        expiration
        recognitionType
        message
        image
        imageID
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        amount
        senderAmount
        badgesDEI
        badgesCS
        userLikes
        isChallenge
        isSystemRecognition
        createdAt
        updatedAt
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        recognizedEmployees {
          items {
            id
            customerID
            recognizedUserID
            recognizedUserAvatarUrl
            recognizedUserAvatarName
            recognizedUserAvatarFirstName
            recognizedUserAvatarInitials
            recognizedUserEmail
            recognitionID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            senderID
            comment
            recognitionID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentComment
            userLikes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognitionsByCustomer = /* GraphQL */ `
  query GetRecognitionsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecognitionsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        recognitionType
        message
        image
        imageID
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        amount
        senderAmount
        badgesDEI
        badgesCS
        userLikes
        isChallenge
        isSystemRecognition
        createdAt
        updatedAt
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        recognizedEmployees {
          items {
            id
            customerID
            recognizedUserID
            recognizedUserAvatarUrl
            recognizedUserAvatarName
            recognizedUserAvatarFirstName
            recognizedUserAvatarInitials
            recognizedUserEmail
            recognitionID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            senderID
            comment
            recognitionID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentComment
            userLikes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognitionsBySender = /* GraphQL */ `
  query GetRecognitionsBySender(
    $senderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecognitionsBySender(
      senderID: $senderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        recognitionType
        message
        image
        imageID
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        amount
        senderAmount
        badgesDEI
        badgesCS
        userLikes
        isChallenge
        isSystemRecognition
        createdAt
        updatedAt
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        recognizedEmployees {
          items {
            id
            customerID
            recognizedUserID
            recognizedUserAvatarUrl
            recognizedUserAvatarName
            recognizedUserAvatarFirstName
            recognizedUserAvatarInitials
            recognizedUserEmail
            recognitionID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            senderID
            comment
            recognitionID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentComment
            userLikes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognitionsByCustomerByExpirationDate = /* GraphQL */ `
  query GetRecognitionsByCustomerByExpirationDate(
    $customerID: ID
    $expiration: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecognitionsByCustomerByExpirationDate(
      customerID: $customerID
      expiration: $expiration
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        recognitionType
        message
        image
        imageID
        customerID
        senderID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        amount
        senderAmount
        badgesDEI
        badgesCS
        userLikes
        isChallenge
        isSystemRecognition
        createdAt
        updatedAt
        messages {
          items {
            id
            customerID
            senderID
            message
            invitationID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentMessage
            userLikes
            origin
            isSystemMessage
            mediaUrl
            launchRuleID
            recognitionID
            slackMessageID
            slackParentMessageID
            slackChannel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        recognizedEmployees {
          items {
            id
            customerID
            recognizedUserID
            recognizedUserAvatarUrl
            recognizedUserAvatarName
            recognizedUserAvatarFirstName
            recognizedUserAvatarInitials
            recognizedUserEmail
            recognitionID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            senderID
            comment
            recognitionID
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            parentComment
            userLikes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognizedUser = /* GraphQL */ `
  query GetRecognizedUser($id: ID!) {
    getRecognizedUser(id: $id) {
      id
      customerID
      recognizedUserID
      recognizedUserAvatarUrl
      recognizedUserAvatarName
      recognizedUserAvatarFirstName
      recognizedUserAvatarInitials
      recognizedUserEmail
      recognitionID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecognizedUsers = /* GraphQL */ `
  query ListRecognizedUsers(
    $filter: ModelRecognizedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecognizedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        recognizedUserID
        recognizedUserAvatarUrl
        recognizedUserAvatarName
        recognizedUserAvatarFirstName
        recognizedUserAvatarInitials
        recognizedUserEmail
        recognitionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognizedUserByRecognition = /* GraphQL */ `
  query GetRecognizedUserByRecognition(
    $recognitionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRecognizedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecognizedUserByRecognition(
      recognitionID: $recognitionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        recognizedUserID
        recognizedUserAvatarUrl
        recognizedUserAvatarName
        recognizedUserAvatarFirstName
        recognizedUserAvatarInitials
        recognizedUserEmail
        recognitionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognizedUserByCustomerID = /* GraphQL */ `
  query GetRecognizedUserByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRecognizedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecognizedUserByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        recognizedUserID
        recognizedUserAvatarUrl
        recognizedUserAvatarName
        recognizedUserAvatarFirstName
        recognizedUserAvatarInitials
        recognizedUserEmail
        recognitionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognitionComment = /* GraphQL */ `
  query GetRecognitionComment($id: ID!) {
    getRecognitionComment(id: $id) {
      id
      senderID
      comment
      recognitionID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentComment
      userLikes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecognitionComments = /* GraphQL */ `
  query ListRecognitionComments(
    $filter: ModelRecognitionCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecognitionComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        comment
        recognitionID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentComment
        userLikes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecognitionCommentByRecognition = /* GraphQL */ `
  query GetRecognitionCommentByRecognition(
    $recognitionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRecognitionCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecognitionCommentByRecognition(
      recognitionID: $recognitionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        comment
        recognitionID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentComment
        userLikes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationType = /* GraphQL */ `
  query GetNotificationType($id: ID!) {
    getNotificationType(id: $id) {
      id
      notificationType
      title
      text
      textFormatted
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotificationTypes = /* GraphQL */ `
  query ListNotificationTypes(
    $filter: ModelNotificationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationType
        title
        text
        textFormatted
        image
        imageID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      expiration
      readByUser
      pushNotificationSent
      title
      text
      textFormatted
      image
      imageID
      notificationType
      scope
      invitationID
      spotlightID
      messageID
      commentID
      scheduledConectivityID
      recognitionID
      urlRedirect
      notificationTypeID
      customerID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        expiration
        readByUser
        pushNotificationSent
        title
        text
        textFormatted
        image
        imageID
        notificationType
        scope
        invitationID
        spotlightID
        messageID
        commentID
        scheduledConectivityID
        recognitionID
        urlRedirect
        notificationTypeID
        customerID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByCustomer = /* GraphQL */ `
  query GetNotificationsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        readByUser
        pushNotificationSent
        title
        text
        textFormatted
        image
        imageID
        notificationType
        scope
        invitationID
        spotlightID
        messageID
        commentID
        scheduledConectivityID
        recognitionID
        urlRedirect
        notificationTypeID
        customerID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByUser = /* GraphQL */ `
  query GetNotificationsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        readByUser
        pushNotificationSent
        title
        text
        textFormatted
        image
        imageID
        notificationType
        scope
        invitationID
        spotlightID
        messageID
        commentID
        scheduledConectivityID
        recognitionID
        urlRedirect
        notificationTypeID
        customerID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByScope = /* GraphQL */ `
  query GetNotificationsByScope(
    $scope: String
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByScope(
      scope: $scope
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        readByUser
        pushNotificationSent
        title
        text
        textFormatted
        image
        imageID
        notificationType
        scope
        invitationID
        spotlightID
        messageID
        commentID
        scheduledConectivityID
        recognitionID
        urlRedirect
        notificationTypeID
        customerID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByUserByExpirationDate = /* GraphQL */ `
  query GetNotificationsByUserByExpirationDate(
    $userID: ID
    $expiration: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByUserByExpirationDate(
      userID: $userID
      expiration: $expiration
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        readByUser
        pushNotificationSent
        title
        text
        textFormatted
        image
        imageID
        notificationType
        scope
        invitationID
        spotlightID
        messageID
        commentID
        scheduledConectivityID
        recognitionID
        urlRedirect
        notificationTypeID
        customerID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByCustomerByExpirationDate = /* GraphQL */ `
  query GetNotificationsByCustomerByExpirationDate(
    $customerID: ID
    $expiration: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByCustomerByExpirationDate(
      customerID: $customerID
      expiration: $expiration
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiration
        readByUser
        pushNotificationSent
        title
        text
        textFormatted
        image
        imageID
        notificationType
        scope
        invitationID
        spotlightID
        messageID
        commentID
        scheduledConectivityID
        recognitionID
        urlRedirect
        notificationTypeID
        customerID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPushToken = /* GraphQL */ `
  query GetPushToken($id: ID!) {
    getPushToken(id: $id) {
      id
      pushToken
      channel
      expiration
      userID
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPushTokens = /* GraphQL */ `
  query ListPushTokens(
    $filter: ModelPushTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPushTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pushToken
        channel
        expiration
        userID
        customerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPushTokensByCustomer = /* GraphQL */ `
  query GetPushTokensByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPushTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPushTokensByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pushToken
        channel
        expiration
        userID
        customerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPushTokensByUser = /* GraphQL */ `
  query GetPushTokensByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPushTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPushTokensByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pushToken
        channel
        expiration
        userID
        customerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      conectivityID
      userID
      customerID
      ratingValue
      comment
      processed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conectivityID
        userID
        customerID
        ratingValue
        comment
        processed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRatingsByCustomer = /* GraphQL */ `
  query GetRatingsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRatingsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conectivityID
        userID
        customerID
        ratingValue
        comment
        processed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRatingsByUser = /* GraphQL */ `
  query GetRatingsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRatingsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conectivityID
        userID
        customerID
        ratingValue
        comment
        processed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRatingStats = /* GraphQL */ `
  query GetRatingStats($id: ID!) {
    getRatingStats(id: $id) {
      id
      conectivityID
      numberOfRatings
      ratingsAverage
      ratingsMax
      ratingsMin
      numberOfCompletions
      numberOfSkips
      numberOfLaunches
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRatingStats = /* GraphQL */ `
  query ListRatingStats(
    $filter: ModelRatingStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatingStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsRelease = /* GraphQL */ `
  query GetNewsRelease($id: ID!) {
    getNewsRelease(id: $id) {
      id
      title
      content
      image
      imageID
      releaseDate
      dateTestEmailSent
      sentToCustomers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNewsReleases = /* GraphQL */ `
  query ListNewsReleases(
    $filter: ModelNewsReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsReleases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        image
        imageID
        releaseDate
        dateTestEmailSent
        sentToCustomers
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAnalytics = /* GraphQL */ `
  query GetUserAnalytics($id: ID!) {
    getUserAnalytics(id: $id) {
      id
      userID
      customerID
      name
      title
      customer
      onNumberOfTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      userRank
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserAnalytics = /* GraphQL */ `
  query ListUserAnalytics(
    $filter: ModelUserAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAnalytics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        customerID
        name
        title
        customer
        onNumberOfTeams
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        userRank
        conectivitiesCompleted
        coinsAwarded
        badgesDEIAwarded
        badgesCSAwarded
        socialConectivitiesCompleted
        stressConectivitiesCompleted
        teamConectivitiesCompleted
        individualConectivitiesCompleted
        socialCoinsEarned
        stressCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        spotlightsSent
        spotlightsReceived
        spotlightsCoinsEarned
        spotlightsBadgesDEIEarned
        spotlightsBadgesCSEarned
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAnalyticsByUser = /* GraphQL */ `
  query GetUserAnalyticsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserAnalyticsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        name
        title
        customer
        onNumberOfTeams
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        userRank
        conectivitiesCompleted
        coinsAwarded
        badgesDEIAwarded
        badgesCSAwarded
        socialConectivitiesCompleted
        stressConectivitiesCompleted
        teamConectivitiesCompleted
        individualConectivitiesCompleted
        socialCoinsEarned
        stressCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        spotlightsSent
        spotlightsReceived
        spotlightsCoinsEarned
        spotlightsBadgesDEIEarned
        spotlightsBadgesCSEarned
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAnalyticsByCustomer = /* GraphQL */ `
  query GetUserAnalyticsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserAnalyticsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        name
        title
        customer
        onNumberOfTeams
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        userRank
        conectivitiesCompleted
        coinsAwarded
        badgesDEIAwarded
        badgesCSAwarded
        socialConectivitiesCompleted
        stressConectivitiesCompleted
        teamConectivitiesCompleted
        individualConectivitiesCompleted
        socialCoinsEarned
        stressCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        spotlightsSent
        spotlightsReceived
        spotlightsCoinsEarned
        spotlightsBadgesDEIEarned
        spotlightsBadgesCSEarned
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamAnalytics = /* GraphQL */ `
  query GetTeamAnalytics($id: ID!) {
    getTeamAnalytics(id: $id) {
      id
      teamID
      customerID
      name
      nickname
      department
      customer
      memberCount
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      teamRank
      rank
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeamAnalytics = /* GraphQL */ `
  query ListTeamAnalytics(
    $filter: ModelTeamAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamAnalytics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        customerID
        name
        nickname
        department
        customer
        memberCount
        conectivitiesCompleted
        coinsAwarded
        badgesDEIAwarded
        badgesCSAwarded
        socialConectivitiesCompleted
        stressConectivitiesCompleted
        teamConectivitiesCompleted
        individualConectivitiesCompleted
        socialCoinsEarned
        stressCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        spotlightsSent
        spotlightsReceived
        spotlightsCoinsEarned
        spotlightsBadgesDEIEarned
        spotlightsBadgesCSEarned
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        teamRank
        rank
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamAnalyticsByTeam = /* GraphQL */ `
  query GetTeamAnalyticsByTeam(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamAnalyticsByTeam(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        customerID
        name
        nickname
        department
        customer
        memberCount
        conectivitiesCompleted
        coinsAwarded
        badgesDEIAwarded
        badgesCSAwarded
        socialConectivitiesCompleted
        stressConectivitiesCompleted
        teamConectivitiesCompleted
        individualConectivitiesCompleted
        socialCoinsEarned
        stressCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        spotlightsSent
        spotlightsReceived
        spotlightsCoinsEarned
        spotlightsBadgesDEIEarned
        spotlightsBadgesCSEarned
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        teamRank
        rank
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamAnalyticsByCustomer = /* GraphQL */ `
  query GetTeamAnalyticsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamAnalyticsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        customerID
        name
        nickname
        department
        customer
        memberCount
        conectivitiesCompleted
        coinsAwarded
        badgesDEIAwarded
        badgesCSAwarded
        socialConectivitiesCompleted
        stressConectivitiesCompleted
        teamConectivitiesCompleted
        individualConectivitiesCompleted
        socialCoinsEarned
        stressCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        spotlightsSent
        spotlightsReceived
        spotlightsCoinsEarned
        spotlightsBadgesDEIEarned
        spotlightsBadgesCSEarned
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        teamRank
        rank
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerAnalytics = /* GraphQL */ `
  query GetCustomerAnalytics($id: ID!) {
    getCustomerAnalytics(id: $id) {
      id
      customerID
      name
      initials
      employeesTotal
      teamsTotal
      largestTeam
      smallestTeam
      totalConectivitiesCompletedByUsers
      maxConectivitiesCompletedByUsers
      minConectivitiesCompletedByUsers
      avgConectivitiesCompletedByUsers
      totalCoinsEarnedByUsers
      maxCoinsEarnedByUsers
      minCoinsEarnedByUsers
      avgCoinsEarnedByUsers
      totalDEIBadgesEarnedByUsers
      maxDEIBadgesEarnedByUsers
      minDEIBadgesEarnedByUsers
      avgDEIBadgesEarnedByUsers
      totalCSBadgesEarnedByUsers
      maxCSBadgesEarnedByUsers
      minCSBadgesEarnedByUsers
      avgCSBadgesEarnedByUsers
      totalSocialConectivitiesCompletedByUsers
      maxSocialConectivitiesCompletedByUsers
      minSocialConectivitiesCompletedByUsers
      avgSocialConectivitiesCompletedByUsers
      totalStressConectivitiesCompletedByUsers
      maxStressConectivitiesCompletedByUsers
      minStressConectivitiesCompletedByUsers
      avgStressConectivitiesCompletedByUsers
      totalTeamConectivitiesCompletedByUsers
      maxTeamConectivitiesCompletedByUsers
      minTeamConectivitiesCompletedByUsers
      avgTeamConectivitiesCompletedByUsers
      totalIndividualConectivitiesCompletedByUsers
      maxIndividualConectivitiesCompletedByUsers
      minIndividualConectivitiesCompletedByUsers
      avgIndividualConectivitiesCompletedByUsers
      maxRelativeConectivityScoreByUsers
      minRelativeConectivityScoreByUsers
      avgRelativeConectivityScoreByUsers
      balanceStreakCount
      balanceStreakCountMax
      balanceStreakStartDateEarliest
      balanceStreakMaxUsers
      socialStreakCount
      socialStreakCountMax
      socialStreakStartDateEarliest
      socialStreakMaxUsers
      teamStreakCount
      teamStreakCountMax
      teamStreakStartDateEarliest
      teamStreakMaxUsers
      growthStreakCount
      growthStreakCountMax
      growthStreakStartDateEarliest
      growthStreakMaxUsers
      totalConectivitiesCompletedByTeams
      maxConectivitiesCompletedByTeams
      minConectivitiesCompletedByTeams
      avgConectivitiesCompletedByTeams
      totalCoinsEarnedByTeams
      maxCoinsEarnedByTeams
      minCoinsEarnedByTeams
      avgCoinsEarnedByTeams
      totalDEIBadgesEarnedByTeams
      maxDEIBadgesEarnedByTeams
      minDEIBadgesEarnedByTeams
      avgDEIBadgesEarnedByTeams
      totalCSBadgesEarnedByTeams
      maxCSBadgesEarnedByTeams
      minCSBadgesEarnedByTeams
      avgCSBadgesEarnedByTeams
      totalSocialConectivitiesCompletedByTeams
      maxSocialConectivitiesCompletedByTeams
      minSocialConectivitiesCompletedByTeams
      avgSocialConectivitiesCompletedByTeams
      totalStressConectivitiesCompletedByTeams
      maxStressConectivitiesCompletedByTeams
      minStressConectivitiesCompletedByTeams
      avgStressConectivitiesCompletedByTeams
      totalTeamConectivitiesCompletedByTeams
      maxTeamConectivitiesCompletedByTeams
      minTeamConectivitiesCompletedByTeams
      avgTeamConectivitiesCompletedByTeams
      totalIndividualConectivitiesCompletedByTeams
      maxIndividualConectivitiesCompletedByTeams
      minIndividualConectivitiesCompletedByTeams
      avgIndividualConectivitiesCompletedByTeams
      maxRelativeConectivityScoreByTeams
      minRelativeConectivityScoreByTeams
      avgRelativeConectivityScoreByTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      customerRank
      rank
      spotlightsSentTotal
      spotlightsReceivedTotal
      spotlightsCoinsEarnedTotal
      spotlightsBadgesDEIEarnedTotal
      spotlightsBadgesCSEarnedTotal
      leastSpotlightsReceived
      mostSpotlightsReceived
      leastSpotlightsSent
      mostSpotlightsSent
      avgSpotlightsSent
      avgSpotlightsReceived
      employeesRecognized
      employeesUnrecognized
      percentEmployeesRecognized
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      avgSpotlightsSentDay
      avgSpotlightsReceivedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      avgSpotlightsSentWeek
      avgSpotlightsReceivedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      avgSpotlightsSentMonth
      avgSpotlightsReceivedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      avgSpotlightsSentQuarter
      avgSpotlightsReceivedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      avgSpotlightsSentYear
      avgSpotlightsReceivedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      avgSpotlightsSentOneWeek
      avgSpotlightsReceivedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      avgSpotlightsSentOneMonth
      avgSpotlightsReceivedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      avgSpotlightsSentTwoMonths
      avgSpotlightsReceivedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      avgSpotlightsSentOneYear
      avgSpotlightsReceivedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerAnalytics = /* GraphQL */ `
  query ListCustomerAnalytics(
    $filter: ModelCustomerAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerAnalytics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        name
        initials
        employeesTotal
        teamsTotal
        largestTeam
        smallestTeam
        totalConectivitiesCompletedByUsers
        maxConectivitiesCompletedByUsers
        minConectivitiesCompletedByUsers
        avgConectivitiesCompletedByUsers
        totalCoinsEarnedByUsers
        maxCoinsEarnedByUsers
        minCoinsEarnedByUsers
        avgCoinsEarnedByUsers
        totalDEIBadgesEarnedByUsers
        maxDEIBadgesEarnedByUsers
        minDEIBadgesEarnedByUsers
        avgDEIBadgesEarnedByUsers
        totalCSBadgesEarnedByUsers
        maxCSBadgesEarnedByUsers
        minCSBadgesEarnedByUsers
        avgCSBadgesEarnedByUsers
        totalSocialConectivitiesCompletedByUsers
        maxSocialConectivitiesCompletedByUsers
        minSocialConectivitiesCompletedByUsers
        avgSocialConectivitiesCompletedByUsers
        totalStressConectivitiesCompletedByUsers
        maxStressConectivitiesCompletedByUsers
        minStressConectivitiesCompletedByUsers
        avgStressConectivitiesCompletedByUsers
        totalTeamConectivitiesCompletedByUsers
        maxTeamConectivitiesCompletedByUsers
        minTeamConectivitiesCompletedByUsers
        avgTeamConectivitiesCompletedByUsers
        totalIndividualConectivitiesCompletedByUsers
        maxIndividualConectivitiesCompletedByUsers
        minIndividualConectivitiesCompletedByUsers
        avgIndividualConectivitiesCompletedByUsers
        maxRelativeConectivityScoreByUsers
        minRelativeConectivityScoreByUsers
        avgRelativeConectivityScoreByUsers
        balanceStreakCount
        balanceStreakCountMax
        balanceStreakStartDateEarliest
        balanceStreakMaxUsers
        socialStreakCount
        socialStreakCountMax
        socialStreakStartDateEarliest
        socialStreakMaxUsers
        teamStreakCount
        teamStreakCountMax
        teamStreakStartDateEarliest
        teamStreakMaxUsers
        growthStreakCount
        growthStreakCountMax
        growthStreakStartDateEarliest
        growthStreakMaxUsers
        totalConectivitiesCompletedByTeams
        maxConectivitiesCompletedByTeams
        minConectivitiesCompletedByTeams
        avgConectivitiesCompletedByTeams
        totalCoinsEarnedByTeams
        maxCoinsEarnedByTeams
        minCoinsEarnedByTeams
        avgCoinsEarnedByTeams
        totalDEIBadgesEarnedByTeams
        maxDEIBadgesEarnedByTeams
        minDEIBadgesEarnedByTeams
        avgDEIBadgesEarnedByTeams
        totalCSBadgesEarnedByTeams
        maxCSBadgesEarnedByTeams
        minCSBadgesEarnedByTeams
        avgCSBadgesEarnedByTeams
        totalSocialConectivitiesCompletedByTeams
        maxSocialConectivitiesCompletedByTeams
        minSocialConectivitiesCompletedByTeams
        avgSocialConectivitiesCompletedByTeams
        totalStressConectivitiesCompletedByTeams
        maxStressConectivitiesCompletedByTeams
        minStressConectivitiesCompletedByTeams
        avgStressConectivitiesCompletedByTeams
        totalTeamConectivitiesCompletedByTeams
        maxTeamConectivitiesCompletedByTeams
        minTeamConectivitiesCompletedByTeams
        avgTeamConectivitiesCompletedByTeams
        totalIndividualConectivitiesCompletedByTeams
        maxIndividualConectivitiesCompletedByTeams
        minIndividualConectivitiesCompletedByTeams
        avgIndividualConectivitiesCompletedByTeams
        maxRelativeConectivityScoreByTeams
        minRelativeConectivityScoreByTeams
        avgRelativeConectivityScoreByTeams
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        customerRank
        rank
        spotlightsSentTotal
        spotlightsReceivedTotal
        spotlightsCoinsEarnedTotal
        spotlightsBadgesDEIEarnedTotal
        spotlightsBadgesCSEarnedTotal
        leastSpotlightsReceived
        mostSpotlightsReceived
        leastSpotlightsSent
        mostSpotlightsSent
        avgSpotlightsSent
        avgSpotlightsReceived
        employeesRecognized
        employeesUnrecognized
        percentEmployeesRecognized
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        avgSpotlightsSentDay
        avgSpotlightsReceivedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        avgSpotlightsSentWeek
        avgSpotlightsReceivedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        avgSpotlightsSentMonth
        avgSpotlightsReceivedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        avgSpotlightsSentQuarter
        avgSpotlightsReceivedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        avgSpotlightsSentYear
        avgSpotlightsReceivedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        avgSpotlightsSentOneWeek
        avgSpotlightsReceivedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        avgSpotlightsSentOneMonth
        avgSpotlightsReceivedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        avgSpotlightsSentTwoMonths
        avgSpotlightsReceivedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        avgSpotlightsSentOneYear
        avgSpotlightsReceivedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerAnalyticsByCustomer = /* GraphQL */ `
  query GetCustomerAnalyticsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerAnalyticsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        name
        initials
        employeesTotal
        teamsTotal
        largestTeam
        smallestTeam
        totalConectivitiesCompletedByUsers
        maxConectivitiesCompletedByUsers
        minConectivitiesCompletedByUsers
        avgConectivitiesCompletedByUsers
        totalCoinsEarnedByUsers
        maxCoinsEarnedByUsers
        minCoinsEarnedByUsers
        avgCoinsEarnedByUsers
        totalDEIBadgesEarnedByUsers
        maxDEIBadgesEarnedByUsers
        minDEIBadgesEarnedByUsers
        avgDEIBadgesEarnedByUsers
        totalCSBadgesEarnedByUsers
        maxCSBadgesEarnedByUsers
        minCSBadgesEarnedByUsers
        avgCSBadgesEarnedByUsers
        totalSocialConectivitiesCompletedByUsers
        maxSocialConectivitiesCompletedByUsers
        minSocialConectivitiesCompletedByUsers
        avgSocialConectivitiesCompletedByUsers
        totalStressConectivitiesCompletedByUsers
        maxStressConectivitiesCompletedByUsers
        minStressConectivitiesCompletedByUsers
        avgStressConectivitiesCompletedByUsers
        totalTeamConectivitiesCompletedByUsers
        maxTeamConectivitiesCompletedByUsers
        minTeamConectivitiesCompletedByUsers
        avgTeamConectivitiesCompletedByUsers
        totalIndividualConectivitiesCompletedByUsers
        maxIndividualConectivitiesCompletedByUsers
        minIndividualConectivitiesCompletedByUsers
        avgIndividualConectivitiesCompletedByUsers
        maxRelativeConectivityScoreByUsers
        minRelativeConectivityScoreByUsers
        avgRelativeConectivityScoreByUsers
        balanceStreakCount
        balanceStreakCountMax
        balanceStreakStartDateEarliest
        balanceStreakMaxUsers
        socialStreakCount
        socialStreakCountMax
        socialStreakStartDateEarliest
        socialStreakMaxUsers
        teamStreakCount
        teamStreakCountMax
        teamStreakStartDateEarliest
        teamStreakMaxUsers
        growthStreakCount
        growthStreakCountMax
        growthStreakStartDateEarliest
        growthStreakMaxUsers
        totalConectivitiesCompletedByTeams
        maxConectivitiesCompletedByTeams
        minConectivitiesCompletedByTeams
        avgConectivitiesCompletedByTeams
        totalCoinsEarnedByTeams
        maxCoinsEarnedByTeams
        minCoinsEarnedByTeams
        avgCoinsEarnedByTeams
        totalDEIBadgesEarnedByTeams
        maxDEIBadgesEarnedByTeams
        minDEIBadgesEarnedByTeams
        avgDEIBadgesEarnedByTeams
        totalCSBadgesEarnedByTeams
        maxCSBadgesEarnedByTeams
        minCSBadgesEarnedByTeams
        avgCSBadgesEarnedByTeams
        totalSocialConectivitiesCompletedByTeams
        maxSocialConectivitiesCompletedByTeams
        minSocialConectivitiesCompletedByTeams
        avgSocialConectivitiesCompletedByTeams
        totalStressConectivitiesCompletedByTeams
        maxStressConectivitiesCompletedByTeams
        minStressConectivitiesCompletedByTeams
        avgStressConectivitiesCompletedByTeams
        totalTeamConectivitiesCompletedByTeams
        maxTeamConectivitiesCompletedByTeams
        minTeamConectivitiesCompletedByTeams
        avgTeamConectivitiesCompletedByTeams
        totalIndividualConectivitiesCompletedByTeams
        maxIndividualConectivitiesCompletedByTeams
        minIndividualConectivitiesCompletedByTeams
        avgIndividualConectivitiesCompletedByTeams
        maxRelativeConectivityScoreByTeams
        minRelativeConectivityScoreByTeams
        avgRelativeConectivityScoreByTeams
        stressComponent
        socialComponent
        teamComponent
        individualComponent
        badgesCSComponent
        badgesDEIComponent
        relativeConectivityScore
        customerRank
        rank
        spotlightsSentTotal
        spotlightsReceivedTotal
        spotlightsCoinsEarnedTotal
        spotlightsBadgesDEIEarnedTotal
        spotlightsBadgesCSEarnedTotal
        leastSpotlightsReceived
        mostSpotlightsReceived
        leastSpotlightsSent
        mostSpotlightsSent
        avgSpotlightsSent
        avgSpotlightsReceived
        employeesRecognized
        employeesUnrecognized
        percentEmployeesRecognized
        conectivitiesDay
        coinsReceivedDay
        badgesDEIReceivedDay
        badgesCSReceivedDay
        purchasesDay
        coinsSpentDay
        badgesDEISpentDay
        badgesCSSpentDay
        socialPeriodCoinsEarnedDay
        socialPeriodConectivitiesCompleteDay
        stressPeriodCoinsEarnedDay
        stressPeriodConectivitiesCompleteDay
        teamPeriodCoinsEarnedDay
        teamPeriodConectivitiesCompleteDay
        individualPeriodCoinsEarnedDay
        individualPeriodConectivitiesCompleteDay
        spotlightsSentDay
        spotlightsReceivedDay
        spotlightsCoinsEarnedDay
        spotlightsBadgesDEIEarnedDay
        spotlightsBadgesCSEarnedDay
        avgSpotlightsSentDay
        avgSpotlightsReceivedDay
        conectivitiesWeek
        coinsReceivedWeek
        badgesDEIReceivedWeek
        badgesCSReceivedWeek
        purchasesWeek
        coinsSpentWeek
        badgesDEISpentWeek
        badgesCSSpentWeek
        socialPeriodCoinsEarnedWeek
        socialPeriodConectivitiesCompleteWeek
        stressPeriodCoinsEarnedWeek
        stressPeriodConectivitiesCompleteWeek
        teamPeriodCoinsEarnedWeek
        teamPeriodConectivitiesCompleteWeek
        individualPeriodCoinsEarnedWeek
        individualPeriodConectivitiesCompleteWeek
        spotlightsSentWeek
        spotlightsReceivedWeek
        spotlightsCoinsEarnedWeek
        spotlightsBadgesDEIEarnedWeek
        spotlightsBadgesCSEarnedWeek
        avgSpotlightsSentWeek
        avgSpotlightsReceivedWeek
        conectivitiesMonth
        coinsReceivedMonth
        badgesDEIReceivedMonth
        badgesCSReceivedMonth
        purchasesMonth
        coinsSpentMonth
        badgesDEISpentMonth
        badgesCSSpentMonth
        socialPeriodCoinsEarnedMonth
        socialPeriodConectivitiesCompleteMonth
        stressPeriodCoinsEarnedMonth
        stressPeriodConectivitiesCompleteMonth
        teamPeriodCoinsEarnedMonth
        teamPeriodConectivitiesCompleteMonth
        individualPeriodCoinsEarnedMonth
        individualPeriodConectivitiesCompleteMonth
        spotlightsSentMonth
        spotlightsReceivedMonth
        spotlightsCoinsEarnedMonth
        spotlightsBadgesDEIEarnedMonth
        spotlightsBadgesCSEarnedMonth
        avgSpotlightsSentMonth
        avgSpotlightsReceivedMonth
        conectivitiesQuarter
        coinsReceivedQuarter
        badgesDEIReceivedQuarter
        badgesCSReceivedQuarter
        purchasesQuarter
        coinsSpentQuarter
        badgesDEISpentQuarter
        badgesCSSpentQuarter
        socialPeriodCoinsEarnedQuarter
        socialPeriodConectivitiesCompleteQuarter
        stressPeriodCoinsEarnedQuarter
        stressPeriodConectivitiesCompleteQuarter
        teamPeriodCoinsEarnedQuarter
        teamPeriodConectivitiesCompleteQuarter
        individualPeriodCoinsEarnedQuarter
        individualPeriodConectivitiesCompleteQuarter
        spotlightsSentQuarter
        spotlightsReceivedQuarter
        spotlightsCoinsEarnedQuarter
        spotlightsBadgesDEIEarnedQuarter
        spotlightsBadgesCSEarnedQuarter
        avgSpotlightsSentQuarter
        avgSpotlightsReceivedQuarter
        conectivitiesYear
        coinsReceivedYear
        badgesDEIReceivedYear
        badgesCSReceivedYear
        purchasesYear
        coinsSpentYear
        badgesDEISpentYear
        badgesCSSpentYear
        socialPeriodCoinsEarnedYear
        socialPeriodConectivitiesCompleteYear
        stressPeriodCoinsEarnedYear
        stressPeriodConectivitiesCompleteYear
        teamPeriodCoinsEarnedYear
        teamPeriodConectivitiesCompleteYear
        individualPeriodCoinsEarnedYear
        individualPeriodConectivitiesCompleteYear
        spotlightsSentYear
        spotlightsReceivedYear
        spotlightsCoinsEarnedYear
        spotlightsBadgesDEIEarnedYear
        spotlightsBadgesCSEarnedYear
        avgSpotlightsSentYear
        avgSpotlightsReceivedYear
        conectivitiesOneWeek
        coinsReceivedOneWeek
        badgesDEIReceivedOneWeek
        badgesCSReceivedOneWeek
        purchasesOneWeek
        coinsSpentOneWeek
        badgesDEISpentOneWeek
        badgesCSSpentOneWeek
        socialPeriodCoinsEarnedOneWeek
        socialPeriodConectivitiesCompleteOneWeek
        stressPeriodCoinsEarnedOneWeek
        stressPeriodConectivitiesCompleteOneWeek
        teamPeriodCoinsEarnedOneWeek
        teamPeriodConectivitiesCompleteOneWeek
        individualPeriodCoinsEarnedOneWeek
        individualPeriodConectivitiesCompleteOneWeek
        spotlightsSentOneWeek
        spotlightsReceivedOneWeek
        spotlightsCoinsEarnedOneWeek
        spotlightsBadgesDEIEarnedOneWeek
        spotlightsBadgesCSEarnedOneWeek
        avgSpotlightsSentOneWeek
        avgSpotlightsReceivedOneWeek
        conectivitiesOneMonth
        coinsReceivedOneMonth
        badgesDEIReceivedOneMonth
        badgesCSReceivedOneMonth
        purchasesOneMonth
        coinsSpentOneMonth
        badgesDEISpentOneMonth
        badgesCSSpentOneMonth
        socialPeriodCoinsEarnedOneMonth
        socialPeriodConectivitiesCompleteOneMonth
        stressPeriodCoinsEarnedOneMonth
        stressPeriodConectivitiesCompleteOneMonth
        teamPeriodCoinsEarnedOneMonth
        teamPeriodConectivitiesCompleteOneMonth
        individualPeriodCoinsEarnedOneMonth
        individualPeriodConectivitiesCompleteOneMonth
        spotlightsSentOneMonth
        spotlightsReceivedOneMonth
        spotlightsCoinsEarnedOneMonth
        spotlightsBadgesDEIEarnedOneMonth
        spotlightsBadgesCSEarnedOneMonth
        avgSpotlightsSentOneMonth
        avgSpotlightsReceivedOneMonth
        conectivitiesTwoMonths
        coinsReceivedTwoMonths
        badgesDEIReceivedTwoMonths
        badgesCSReceivedTwoMonths
        purchasesTwoMonths
        coinsSpentTwoMonths
        badgesDEISpentTwoMonths
        badgesCSSpentTwoMonths
        socialPeriodCoinsEarnedTwoMonths
        socialPeriodConectivitiesCompleteTwoMonths
        stressPeriodCoinsEarnedTwoMonths
        stressPeriodConectivitiesCompleteTwoMonths
        teamPeriodCoinsEarnedTwoMonths
        teamPeriodConectivitiesCompleteTwoMonths
        individualPeriodCoinsEarnedTwoMonths
        individualPeriodConectivitiesCompleteTwoMonths
        spotlightsSentTwoMonths
        spotlightsReceivedTwoMonths
        spotlightsCoinsEarnedTwoMonths
        spotlightsBadgesDEIEarnedTwoMonths
        spotlightsBadgesCSEarnedTwoMonths
        avgSpotlightsSentTwoMonths
        avgSpotlightsReceivedTwoMonths
        conectivitiesOneYear
        coinsReceivedOneYear
        badgesDEIReceivedOneYear
        badgesCSReceivedOneYear
        purchasesOneYear
        coinsSpentOneYear
        badgesDEISpentOneYear
        badgesCSSpentOneYear
        socialPeriodCoinsEarnedOneYear
        socialPeriodConectivitiesCompleteOneYear
        stressPeriodCoinsEarnedOneYear
        stressPeriodConectivitiesCompleteOneYear
        teamPeriodCoinsEarnedOneYear
        teamPeriodConectivitiesCompleteOneYear
        individualPeriodCoinsEarnedOneYear
        individualPeriodConectivitiesCompleteOneYear
        spotlightsSentOneYear
        spotlightsReceivedOneYear
        spotlightsCoinsEarnedOneYear
        spotlightsBadgesDEIEarnedOneYear
        spotlightsBadgesCSEarnedOneYear
        avgSpotlightsSentOneYear
        avgSpotlightsReceivedOneYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphNode = /* GraphQL */ `
  query GetGraphNode($id: ID!) {
    getGraphNode(id: $id) {
      id
      userID
      customerID
      userInitials
      label
      color
      edgeCount
      title
      shape
      borderWidth
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGraphNodes = /* GraphQL */ `
  query ListGraphNodes(
    $filter: ModelGraphNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        customerID
        userInitials
        label
        color
        edgeCount
        title
        shape
        borderWidth
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphNodesByCustomer = /* GraphQL */ `
  query GetGraphNodesByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphNodesByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        customerID
        userInitials
        label
        color
        edgeCount
        title
        shape
        borderWidth
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphEdge = /* GraphQL */ `
  query GetGraphEdge($id: ID!) {
    getGraphEdge(id: $id) {
      id
      customerID
      graphID
      from
      to
      label
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGraphEdges = /* GraphQL */ `
  query ListGraphEdges(
    $filter: ModelGraphEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        graphID
        from
        to
        label
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphEdgesByCustomer = /* GraphQL */ `
  query GetGraphEdgesByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphEdgesByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        graphID
        from
        to
        label
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphEdgesByGraph = /* GraphQL */ `
  query GetGraphEdgesByGraph(
    $graphID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphEdgesByGraph(
      graphID: $graphID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        graphID
        from
        to
        label
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraph = /* GraphQL */ `
  query GetGraph($id: ID!) {
    getGraph(id: $id) {
      id
      customerID
      period
      periodStart
      periodEnd
      category
      maxEdgeCount
      numEdges
      numNodes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGraphs = /* GraphQL */ `
  query ListGraphs(
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        period
        periodStart
        periodEnd
        category
        maxEdgeCount
        numEdges
        numNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphByCustomer = /* GraphQL */ `
  query GetGraphByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        period
        periodStart
        periodEnd
        category
        maxEdgeCount
        numEdges
        numNodes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphTimeSeriesValue = /* GraphQL */ `
  query GetGraphTimeSeriesValue($id: ID!) {
    getGraphTimeSeriesValue(id: $id) {
      id
      customerID
      graphID
      period
      name
      conectivities
      balance
      social
      team
      personal
      amount
      socialCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      balanceCoinsEarned
      DEI_badges
      CS_badges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGraphTimeSeriesValues = /* GraphQL */ `
  query ListGraphTimeSeriesValues(
    $filter: ModelGraphTimeSeriesValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphTimeSeriesValues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        graphID
        period
        name
        conectivities
        balance
        social
        team
        personal
        amount
        socialCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        balanceCoinsEarned
        DEI_badges
        CS_badges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphTimeSeriesValuesByCustomer = /* GraphQL */ `
  query GetGraphTimeSeriesValuesByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphTimeSeriesValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphTimeSeriesValuesByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        graphID
        period
        name
        conectivities
        balance
        social
        team
        personal
        amount
        socialCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        balanceCoinsEarned
        DEI_badges
        CS_badges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphTimeSeriesValuesByGraph = /* GraphQL */ `
  query GetGraphTimeSeriesValuesByGraph(
    $graphID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphTimeSeriesValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphTimeSeriesValuesByGraph(
      graphID: $graphID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        graphID
        period
        name
        conectivities
        balance
        social
        team
        personal
        amount
        socialCoinsEarned
        teamCoinsEarned
        individualCoinsEarned
        balanceCoinsEarned
        DEI_badges
        CS_badges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphTimeSeries = /* GraphQL */ `
  query GetGraphTimeSeries($id: ID!) {
    getGraphTimeSeries(id: $id) {
      id
      customerID
      period
      periodStart
      periodEnd
      createdAt
      updatedAt
      values {
        items {
          id
          customerID
          graphID
          period
          name
          conectivities
          balance
          social
          team
          personal
          amount
          socialCoinsEarned
          teamCoinsEarned
          individualCoinsEarned
          balanceCoinsEarned
          DEI_badges
          CS_badges
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listGraphTimeSeries = /* GraphQL */ `
  query ListGraphTimeSeries(
    $filter: ModelGraphTimeSeriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphTimeSeries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        period
        periodStart
        periodEnd
        createdAt
        updatedAt
        values {
          items {
            id
            customerID
            graphID
            period
            name
            conectivities
            balance
            social
            team
            personal
            amount
            socialCoinsEarned
            teamCoinsEarned
            individualCoinsEarned
            balanceCoinsEarned
            DEI_badges
            CS_badges
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphTimeSeriesByCustomer = /* GraphQL */ `
  query GetGraphTimeSeriesByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGraphTimeSeriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGraphTimeSeriesByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        period
        periodStart
        periodEnd
        createdAt
        updatedAt
        values {
          items {
            id
            customerID
            graphID
            period
            name
            conectivities
            balance
            social
            team
            personal
            amount
            socialCoinsEarned
            teamCoinsEarned
            individualCoinsEarned
            balanceCoinsEarned
            DEI_badges
            CS_badges
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEngagementValue = /* GraphQL */ `
  query GetEngagementValue($id: ID!) {
    getEngagementValue(id: $id) {
      id
      customerID
      userID
      engagementSignalID
      momentum
      alert
      alertTimeStamp
      sequenceNumber
      conectivityCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEngagementValues = /* GraphQL */ `
  query ListEngagementValues(
    $filter: ModelEngagementValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEngagementValues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        engagementSignalID
        momentum
        alert
        alertTimeStamp
        sequenceNumber
        conectivityCompleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEngagementSignalByID = /* GraphQL */ `
  query GetEngagementSignalByID(
    $engagementSignalID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEngagementValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEngagementSignalByID(
      engagementSignalID: $engagementSignalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        engagementSignalID
        momentum
        alert
        alertTimeStamp
        sequenceNumber
        conectivityCompleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEngagementValueByCustomerIDByDate = /* GraphQL */ `
  query GetEngagementValueByCustomerIDByDate(
    $customerID: ID
    $alertTimeStamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEngagementValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEngagementValueByCustomerIDByDate(
      customerID: $customerID
      alertTimeStamp: $alertTimeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        engagementSignalID
        momentum
        alert
        alertTimeStamp
        sequenceNumber
        conectivityCompleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserEngagementSignal = /* GraphQL */ `
  query GetUserEngagementSignal($id: ID!) {
    getUserEngagementSignal(id: $id) {
      id
      customerID
      userID
      category
      periodStart
      periodEnd
      timeStep
      nextSequenceNumber
      createdAt
      updatedAt
      engagementValues {
        items {
          id
          customerID
          userID
          engagementSignalID
          momentum
          alert
          alertTimeStamp
          sequenceNumber
          conectivityCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUserEngagementSignals = /* GraphQL */ `
  query ListUserEngagementSignals(
    $filter: ModelUserEngagementSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEngagementSignals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        category
        periodStart
        periodEnd
        timeStep
        nextSequenceNumber
        createdAt
        updatedAt
        engagementValues {
          items {
            id
            customerID
            userID
            engagementSignalID
            momentum
            alert
            alertTimeStamp
            sequenceNumber
            conectivityCompleted
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserEngagementSignalByCustomer = /* GraphQL */ `
  query GetUserEngagementSignalByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserEngagementSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserEngagementSignalByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        category
        periodStart
        periodEnd
        timeStep
        nextSequenceNumber
        createdAt
        updatedAt
        engagementValues {
          items {
            id
            customerID
            userID
            engagementSignalID
            momentum
            alert
            alertTimeStamp
            sequenceNumber
            conectivityCompleted
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserEngagementSignalByUser = /* GraphQL */ `
  query GetUserEngagementSignalByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserEngagementSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserEngagementSignalByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        category
        periodStart
        periodEnd
        timeStep
        nextSequenceNumber
        createdAt
        updatedAt
        engagementValues {
          items {
            id
            customerID
            userID
            engagementSignalID
            momentum
            alert
            alertTimeStamp
            sequenceNumber
            conectivityCompleted
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarToken = /* GraphQL */ `
  query GetCalendarToken($id: ID!) {
    getCalendarToken(id: $id) {
      id
      customerID
      userID
      expiration
      accessToken
      idToken
      refreshToken
      scope
      tokenType
      calendarType
      jwt
      tokenCache
      calendarUserID
      calendarUserName
      subscriptionID
      calendarID
      deltaLink
      deltaQueryEndDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCalendarTokens = /* GraphQL */ `
  query ListCalendarTokens(
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensByCustomer = /* GraphQL */ `
  query GetCalendarTokensByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensByUser = /* GraphQL */ `
  query GetCalendarTokensByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensByUserByExpirationDate = /* GraphQL */ `
  query GetCalendarTokensByUserByExpirationDate(
    $userID: ID
    $expiration: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensByUserByExpirationDate(
      userID: $userID
      expiration: $expiration
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensByCustomerByExpirationDate = /* GraphQL */ `
  query GetCalendarTokensByCustomerByExpirationDate(
    $customerID: ID
    $expiration: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensByCustomerByExpirationDate(
      customerID: $customerID
      expiration: $expiration
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensByCalendarUser = /* GraphQL */ `
  query GetCalendarTokensByCalendarUser(
    $calendarUserID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensByCalendarUser(
      calendarUserID: $calendarUserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensBySubscription = /* GraphQL */ `
  query GetCalendarTokensBySubscription(
    $subscriptionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensBySubscription(
      subscriptionID: $subscriptionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarTokensByCalendarID = /* GraphQL */ `
  query GetCalendarTokensByCalendarID(
    $calendarID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarTokensByCalendarID(
      calendarID: $calendarID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        expiration
        accessToken
        idToken
        refreshToken
        scope
        tokenType
        calendarType
        jwt
        tokenCache
        calendarUserID
        calendarUserName
        subscriptionID
        calendarID
        deltaLink
        deltaQueryEndDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpecialEvent = /* GraphQL */ `
  query GetSpecialEvent($id: ID!) {
    getSpecialEvent(id: $id) {
      id
      customerID
      eventStatus
      title
      description
      eventStartDateTime
      eventEndDateTime
      announcement
      announcementStartDateTime
      announcementEndDateTime
      notification
      forAdminOnly
      showCountdown
      rewardMultiplier
      keywords
      minParticipantsRequired
      applyToConectivities
      applyToSpotlights
      requireBadgesDEI
      requireBadgesCS
      categoryID
      isCustomerSpecific
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpecialEvents = /* GraphQL */ `
  query ListSpecialEvents(
    $filter: ModelSpecialEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        eventStatus
        title
        description
        eventStartDateTime
        eventEndDateTime
        announcement
        announcementStartDateTime
        announcementEndDateTime
        notification
        forAdminOnly
        showCountdown
        rewardMultiplier
        keywords
        minParticipantsRequired
        applyToConectivities
        applyToSpotlights
        requireBadgesDEI
        requireBadgesCS
        categoryID
        isCustomerSpecific
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpecialEventsByEventStatus = /* GraphQL */ `
  query GetSpecialEventsByEventStatus(
    $eventStatus: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSpecialEventsByEventStatus(
      eventStatus: $eventStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        eventStatus
        title
        description
        eventStartDateTime
        eventEndDateTime
        announcement
        announcementStartDateTime
        announcementEndDateTime
        notification
        forAdminOnly
        showCountdown
        rewardMultiplier
        keywords
        minParticipantsRequired
        applyToConectivities
        applyToSpotlights
        requireBadgesDEI
        requireBadgesCS
        categoryID
        isCustomerSpecific
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpecialEventsByCustomer = /* GraphQL */ `
  query GetSpecialEventsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSpecialEventsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        eventStatus
        title
        description
        eventStartDateTime
        eventEndDateTime
        announcement
        announcementStartDateTime
        announcementEndDateTime
        notification
        forAdminOnly
        showCountdown
        rewardMultiplier
        keywords
        minParticipantsRequired
        applyToConectivities
        applyToSpotlights
        requireBadgesDEI
        requireBadgesCS
        categoryID
        isCustomerSpecific
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallenge = /* GraphQL */ `
  query GetChallenge($id: ID!) {
    getChallenge(id: $id) {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listChallenges = /* GraphQL */ `
  query ListChallenges(
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        userID
        captains
        title
        description
        inputPeriod
        inputLabel
        inputDataType
        inputEval
        isAnonymous
        isViewableCompanyWide
        isTeamCompetition
        giveRewards
        useGoal
        goal
        rewards
        adminInputOnly
        adminConfirmationReq
        launchRuleID
        challengeScope
        customers
        options
        challengeStatus
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeByStatus = /* GraphQL */ `
  query GetChallengeByStatus(
    $challengeStatus: ChallengeStatus
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeByStatus(
      challengeStatus: $challengeStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        captains
        title
        description
        inputPeriod
        inputLabel
        inputDataType
        inputEval
        isAnonymous
        isViewableCompanyWide
        isTeamCompetition
        giveRewards
        useGoal
        goal
        rewards
        adminInputOnly
        adminConfirmationReq
        launchRuleID
        challengeScope
        customers
        options
        challengeStatus
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeByCustomer = /* GraphQL */ `
  query GetChallengeByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        captains
        title
        description
        inputPeriod
        inputLabel
        inputDataType
        inputEval
        isAnonymous
        isViewableCompanyWide
        isTeamCompetition
        giveRewards
        useGoal
        goal
        rewards
        adminInputOnly
        adminConfirmationReq
        launchRuleID
        challengeScope
        customers
        options
        challengeStatus
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeByCustomerByStatus = /* GraphQL */ `
  query GetChallengeByCustomerByStatus(
    $customerID: ID
    $challengeStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeByCustomerByStatus(
      customerID: $customerID
      challengeStatus: $challengeStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        captains
        title
        description
        inputPeriod
        inputLabel
        inputDataType
        inputEval
        isAnonymous
        isViewableCompanyWide
        isTeamCompetition
        giveRewards
        useGoal
        goal
        rewards
        adminInputOnly
        adminConfirmationReq
        launchRuleID
        challengeScope
        customers
        options
        challengeStatus
        createdAt
        updatedAt
        launchRule {
          id
          customerID
          conectivityID
          usersToInvite
          teamsToInvite
          senderID
          message
          messageGIF
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          eventStartDateTime
          eventEndDateTime
          allDayEvent
          launchUntilDate
          launchUntilCount
          launchRepeatOption
          launchRepeatWeeklyOptions
          launchRepeatMonthlyOptions
          launchRepeatMonthlyOptions_2
          launchRepeatMonthlyOptions_3
          launchMonthOption1Checked
          launchMonthOption2Checked
          calendarUid
          calendarEventID
          parentRecurringEventId
          eventInstanceNumber
          originalLaunchStartDate
          nextInstanceNumber
          isMentorMeeting
          isSponsorMeeting
          messagesReadAtRecords {
            messagesReadAt
            userID
            __typename
          }
          rsvpRecords {
            userID
            rsvpStatus
            launchRuleID
            launchRuleInstanceIndex
            __typename
          }
          challengeID
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeData = /* GraphQL */ `
  query GetChallengeData($id: ID!) {
    getChallengeData(id: $id) {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChallengeData = /* GraphQL */ `
  query ListChallengeData(
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengeData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeDataByCustomer = /* GraphQL */ `
  query GetChallengeDataByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeDataByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeDataByUser = /* GraphQL */ `
  query GetChallengeDataByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeDataByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeDataByLeaderboard = /* GraphQL */ `
  query GetChallengeDataByLeaderboard(
    $leaderboardID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeDataByLeaderboard(
      leaderboardID: $leaderboardID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeDataByLeaderboardSpot = /* GraphQL */ `
  query GetChallengeDataByLeaderboardSpot(
    $leaderboardSpotID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeDataByLeaderboardSpot(
      leaderboardSpotID: $leaderboardSpotID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeDataByLeaderboardByUser = /* GraphQL */ `
  query GetChallengeDataByLeaderboardByUser(
    $leaderboardID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeDataByLeaderboardByUser(
      leaderboardID: $leaderboardID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallengeDataByChallenge = /* GraphQL */ `
  query GetChallengeDataByChallenge(
    $challengeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChallengeDataByChallenge(
      challengeID: $challengeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teamID
        activityDate
        leaderboardID
        leaderboardSpotID
        challengeID
        challengeDataValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackToken = /* GraphQL */ `
  query GetSlackToken($id: ID!) {
    getSlackToken(id: $id) {
      id
      customerID
      userID
      slack_team_id
      accessToken
      slack_enterprise_id
      slack_user_id
      slack_user_name
      slack_email
      slack_team_name
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSlackTokens = /* GraphQL */ `
  query ListSlackTokens(
    $filter: ModelSlackTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlackTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        userID
        slack_team_id
        accessToken
        slack_enterprise_id
        slack_user_id
        slack_user_name
        slack_email
        slack_team_name
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackTokensByCustomer = /* GraphQL */ `
  query GetSlackTokensByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlackTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackTokensByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        accessToken
        slack_enterprise_id
        slack_user_id
        slack_user_name
        slack_email
        slack_team_name
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackTokensByUser = /* GraphQL */ `
  query GetSlackTokensByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlackTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackTokensByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        accessToken
        slack_enterprise_id
        slack_user_id
        slack_user_name
        slack_email
        slack_team_name
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackTokensBySlackUser = /* GraphQL */ `
  query GetSlackTokensBySlackUser(
    $slack_user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackTokensBySlackUser(
      slack_user_id: $slack_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        accessToken
        slack_enterprise_id
        slack_user_id
        slack_user_name
        slack_email
        slack_team_name
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackTokensBySlackUserName = /* GraphQL */ `
  query GetSlackTokensBySlackUserName(
    $slack_user_name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackTokensBySlackUserName(
      slack_user_name: $slack_user_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        accessToken
        slack_enterprise_id
        slack_user_id
        slack_user_name
        slack_email
        slack_team_name
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackTokensBySlackTeam = /* GraphQL */ `
  query GetSlackTokensBySlackTeam(
    $slack_team_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackTokensBySlackTeam(
      slack_team_id: $slack_team_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        accessToken
        slack_enterprise_id
        slack_user_id
        slack_user_name
        slack_email
        slack_team_name
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackBotToken = /* GraphQL */ `
  query GetSlackBotToken($id: ID!) {
    getSlackBotToken(id: $id) {
      id
      customerID
      slack_team_id
      accessToken
      bot_user_id
      slack_team_name
      slack_enterprise_id
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSlackBotTokens = /* GraphQL */ `
  query ListSlackBotTokens(
    $filter: ModelSlackBotTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlackBotTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        slack_team_id
        accessToken
        bot_user_id
        slack_team_name
        slack_enterprise_id
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackBotTokensByCustomerID = /* GraphQL */ `
  query GetSlackBotTokensByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlackBotTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackBotTokensByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        slack_team_id
        accessToken
        bot_user_id
        slack_team_name
        slack_enterprise_id
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackBotTokensBySlackTeamID = /* GraphQL */ `
  query GetSlackBotTokensBySlackTeamID(
    $slack_team_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackBotTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackBotTokensBySlackTeamID(
      slack_team_id: $slack_team_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        slack_team_id
        accessToken
        bot_user_id
        slack_team_name
        slack_enterprise_id
        slack_channel
        slack_channel_id
        slack_channel_url
        slack_configuration_url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackUser = /* GraphQL */ `
  query GetSlackUser($id: ID!) {
    getSlackUser(id: $id) {
      id
      customerID
      userID
      slack_team_id
      slack_user_id
      slack_user_name
      slack_email
      slack_display_name
      slack_real_name
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSlackUsers = /* GraphQL */ `
  query ListSlackUsers(
    $filter: ModelSlackUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlackUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        userID
        slack_team_id
        slack_user_id
        slack_user_name
        slack_email
        slack_display_name
        slack_real_name
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackUsersByCustomerID = /* GraphQL */ `
  query GetSlackUsersByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlackUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackUsersByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        slack_user_id
        slack_user_name
        slack_email
        slack_display_name
        slack_real_name
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackUserByUserID = /* GraphQL */ `
  query GetSlackUserByUserID(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlackUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackUserByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        slack_user_id
        slack_user_name
        slack_email
        slack_display_name
        slack_real_name
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackUserBySlackUserID = /* GraphQL */ `
  query GetSlackUserBySlackUserID(
    $slack_user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackUserBySlackUserID(
      slack_user_id: $slack_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        slack_user_id
        slack_user_name
        slack_email
        slack_display_name
        slack_real_name
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackUserBySlackUserName = /* GraphQL */ `
  query GetSlackUserBySlackUserName(
    $slack_user_name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackUserBySlackUserName(
      slack_user_name: $slack_user_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        slack_user_id
        slack_user_name
        slack_email
        slack_display_name
        slack_real_name
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSlackUserBySlackTeamID = /* GraphQL */ `
  query GetSlackUserBySlackTeamID(
    $slack_team_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelSlackUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSlackUserBySlackTeamID(
      slack_team_id: $slack_team_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        slack_team_id
        slack_user_id
        slack_user_name
        slack_email
        slack_display_name
        slack_real_name
        messages_tab_channel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMsftTeamsInstallation = /* GraphQL */ `
  query GetMsftTeamsInstallation($id: ID!) {
    getMsftTeamsInstallation(id: $id) {
      id
      customerID
      userID
      teams_user_id
      tenant_id
      conversation_type
      conversation_id
      msft_team_id
      msft_team_name
      channel_name
      serviceUrl
      conversationReference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMsftTeamsInstallations = /* GraphQL */ `
  query ListMsftTeamsInstallations(
    $filter: ModelMsftTeamsInstallationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMsftTeamsInstallations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teams_user_id
        tenant_id
        conversation_type
        conversation_id
        msft_team_id
        msft_team_name
        channel_name
        serviceUrl
        conversationReference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMsftTeamsInstallationByCustomerID = /* GraphQL */ `
  query GetMsftTeamsInstallationByCustomerID(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMsftTeamsInstallationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMsftTeamsInstallationByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teams_user_id
        tenant_id
        conversation_type
        conversation_id
        msft_team_id
        msft_team_name
        channel_name
        serviceUrl
        conversationReference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMsftTeamsInstallationByUserID = /* GraphQL */ `
  query GetMsftTeamsInstallationByUserID(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMsftTeamsInstallationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMsftTeamsInstallationByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teams_user_id
        tenant_id
        conversation_type
        conversation_id
        msft_team_id
        msft_team_name
        channel_name
        serviceUrl
        conversationReference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMsftTeamsInstallationByMsftTeamID = /* GraphQL */ `
  query GetMsftTeamsInstallationByMsftTeamID(
    $msft_team_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelMsftTeamsInstallationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMsftTeamsInstallationByMsftTeamID(
      msft_team_id: $msft_team_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teams_user_id
        tenant_id
        conversation_type
        conversation_id
        msft_team_id
        msft_team_name
        channel_name
        serviceUrl
        conversationReference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMsftTeamsInstallationByTenantIDByConversationId = /* GraphQL */ `
  query GetMsftTeamsInstallationByTenantIDByConversationId(
    $tenant_id: String
    $conversation_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMsftTeamsInstallationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMsftTeamsInstallationByTenantIDByConversationId(
      tenant_id: $tenant_id
      conversation_id: $conversation_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teams_user_id
        tenant_id
        conversation_type
        conversation_id
        msft_team_id
        msft_team_name
        channel_name
        serviceUrl
        conversationReference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMsftTeamsInstallationByTenantIDByMsftTeamID = /* GraphQL */ `
  query GetMsftTeamsInstallationByTenantIDByMsftTeamID(
    $tenant_id: String
    $msft_team_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMsftTeamsInstallationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMsftTeamsInstallationByTenantIDByMsftTeamID(
      tenant_id: $tenant_id
      msft_team_id: $msft_team_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        userID
        teams_user_id
        tenant_id
        conversation_type
        conversation_id
        msft_team_id
        msft_team_name
        channel_name
        serviceUrl
        conversationReference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      createdAt
      contactName
      title
      company
      website
      numEmployees
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      timeZoneCode
      message
      identifiedBalance
      identifiedTeam
      identifiedSocial
      identifiedGrowth
      identifiedDEI
      identifiedCS
      surveyData
      hasOptedOut
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        contactName
        title
        company
        website
        numEmployees
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        timeZoneCode
        message
        identifiedBalance
        identifiedTeam
        identifiedSocial
        identifiedGrowth
        identifiedDEI
        identifiedCS
        surveyData
        hasOptedOut
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactByCompany = /* GraphQL */ `
  query GetContactByCompany(
    $company: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContactByCompany(
      company: $company
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        contactName
        title
        company
        website
        numEmployees
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        timeZoneCode
        message
        identifiedBalance
        identifiedTeam
        identifiedSocial
        identifiedGrowth
        identifiedDEI
        identifiedCS
        surveyData
        hasOptedOut
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactByCreatedAt = /* GraphQL */ `
  query GetContactByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContactByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        contactName
        title
        company
        website
        numEmployees
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        timeZoneCode
        message
        identifiedBalance
        identifiedTeam
        identifiedSocial
        identifiedGrowth
        identifiedDEI
        identifiedCS
        surveyData
        hasOptedOut
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactSurvey = /* GraphQL */ `
  query GetContactSurvey($id: ID!) {
    getContactSurvey(id: $id) {
      id
      isActive
      startDate
      startEndDate
      surveyName
      title
      totalVotes
      voteTotal1
      voteTotal2
      voteTotal3
      voteTotal4
      voteTotal5
      voteTotal6
      surveyData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactSurveys = /* GraphQL */ `
  query ListContactSurveys(
    $filter: ModelContactSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isActive
        startDate
        startEndDate
        surveyName
        title
        totalVotes
        voteTotal1
        voteTotal2
        voteTotal3
        voteTotal4
        voteTotal5
        voteTotal6
        surveyData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyByName = /* GraphQL */ `
  query GetSurveyByName(
    $surveyName: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSurveyByName(
      surveyName: $surveyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        startDate
        startEndDate
        surveyName
        title
        totalVotes
        voteTotal1
        voteTotal2
        voteTotal3
        voteTotal4
        voteTotal5
        voteTotal6
        surveyData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021


import "./adminReportConectivitiesByFavorite.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {Authenticator,  } from '@aws-amplify/ui-react';


//Queries and Mutations

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


//Icons
import AssignmentIcon from '@mui/icons-material/Assignment';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';

//Our Components
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import StarRatings from "../../shared/Components/starRatings/starRatings";

//Utils
import {fetchTransactionsBytransactionType, fetchTransactionsyCustomerIDByTransactionType } from "../../shared/utils/databaseUtils";
import {compareTransactionsByDate, compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { compareConectivitiesMostFavorite, compareConectivitiesRowsMostFavorite, setHeaderColorByCategory, setConectivityImageUrlByCategory, setBackgroundByPublishedStatus,  compareConectivitiesByTitle} from "../../shared/utils/conectivityUtils";
import { truncateStringWithDots, displayConectivitySourceColumn, formatSourceColumnValue, formatDays } from "../../shared/utils/generalUtils";
import { displayTeams } from "../../shared/utils/userAndTeamUtils";

// import { createPDF } from "../../shared/utils/pdfUtils";

import moment from 'moment';

//Here's the start of our Javascrpt App
const ReportConectivitiesByFavoritePage = () => {
   // Context
   const {  authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics, permissionEditor, } = useContext(AuthContext);    
   const { users, customers } = useContext(CustomerContext);  
   const { conectivities } = useContext(ConectivityContext);
   const { displayReportControls, transactionsFiltered, setSelectedReport } = useContext(AnalyticsContext);
  
   // local state
	const [conectivityRows,setConectivityRows]=useState([]);                                //Formatted rows for user grid    
	const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);

   //variable to control confirmation Modal if user presses DELETE; default to hidden
	const [showModalSummary, setShowModalSummary] = useState(false);  
  
	//Summary Modal state data
	const [conectivityToSummarize, setConectivityToSummarize] = useState({});                

	//Analytics for conectivity report page
	// const [ccTransactions, setCCTransactions] = useState([]);
	const [perConectivityAnalytics, setPerConectivityAnalytics] = useState([]);

   const customerWideConectivityAlyticsInitialState = {
		conectivitiesTotal: 0,
		active: 0,
		inactive: 0,
		leastCCs: 9999,
		mostCCs: 0,
		deiConectivities: 0,
		csConectivities: 0,
		companySpecific: 0,
		socialConectivities: 0,
		stressConectivities: 0,
		teamConectivities: 0,
		individualConectivities: 0,
		coinsEarnedTotal: 0,
		badgesDEIEarnedTotal: 0,
		badgesCSEarnedTotal: 0,
		earliestTransation: "9999",

		completionsTotal: 0,
		completionsMax:0,
		completionsMin:99999,
		completionsAvg: 0,
		
	};

	const[customerWideConectivityAlytics,setCustomerWideConectivityAlytics] = useState(customerWideConectivityAlyticsInitialState);
	

	//On load, set Report select option to THIS page
	useEffect(() => {
		const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "FAV_CON");
		if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
	});
	
/* NOT NEEDED SINCE TRANSACTION CACHE MAINTAINED BY ANALYTICS CONTEXT 

	//Get conectivity data upon opening this page, return an array of existing conectivities, if any
	useEffect(() => {
		updatePageData();  
	}, [authState, analyticsGenerated, selectedCustomerOptions]);

	
	//on a user auth or customer change, grab the conectivity data and related transactions
	async function updatePageData() {
 
		if (authState != "signedin") return;

	   //wait for users, teams, transactions and corresponding analytics to be loaded by context
		if (!analyticsGenerated) return;
 
		setShowSpinner(true); //Show spinners
	   
		
		try {

			// var conectivityData = [];
			// var tempConectivities= [];
			var tempCCTransactionData =[];
			var tempCCTransactions = [];
		
			 //SuperAdim and has either NOT selected any Customer OR has selected ALL CUSTOMERS?
			if (isSuperAdmin && (selectedCustomerOptions == null || selectedCustomerOptions?.value===0)) {		   	
						tempCCTransactions = await fetchTransactionsBytransactionType("COMPLETED_CONECTIVITY");				   
						if (DEBUG_MODE >= 2) console.log("Fetched SuperAdmin Transactions", tempCCTransactions);
								  
			} else {
				
				//If SuperAdmin then use the selected customer for the query otherwise use the customer ID of the user herself (admin)
				const customerIDtoQuery = (isSuperAdmin ? selectedCustomerOptions.id : currentUser.customerID);
 
	
				//Grab transactions for this company for analytics
				tempCCTransactions = await fetchTransactionsyCustomerIDByTransactionType({customerID:customerIDtoQuery, transactionType:"COMPLETED_CONECTIVITY"});				   
				
			}
	
			//Save the transactions
			setCCTransactions(tempCCTransactions);
			if (DEBUG_MODE >= 2) console.log("Fetched Transactions", tempCCTransactions);
 
	
		} catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching page data', err); }
		
		 setShowSpinner(false); //Hide spinners
   
	 }         
*/

	useEffect(() => {
		calcConectivityAnalytics();    //call our funciton to fetch prodcuts for the main table
	}, [conectivities, transactionsFiltered]);

	//This function processes the transaction data in view of the fetched conectivities and computes all stats needed
	function calcConectivityAnalytics () {
		
		if (conectivities == undefined || transactionsFiltered == undefined) return;
		if (conectivities.length == 0 || transactionsFiltered.length == 0) return;

		
		console.log ("Inputs to build Conectivity Data", conectivities, transactionsFiltered);
	
		const tempConectivityNumbers = [];
		var tempAnalytics = customerWideConectivityAlytics;
		
		
		tempAnalytics.conectivitiesTotal = conectivities.length;
		
		//First, initialize our array so we have a spot for each conectivity, including those with ZERO transactions
		//This holds all the data for each conectivity that we need for all the different graphs
		for (var j = 0; j < conectivities.length; j++){

			tempConectivityNumbers[j] = {
				conectivityID: conectivities[j].id,
				publicationStatus: conectivities[j].publicationStatus,
				title:conectivities[j].title,
				categoryLabel:conectivities[j].category.label,
				image:conectivities[j].image,
				completions: 0,
				coinsAwarded: 0,
				badgesDEIAwarded: 0,
				badgesCSAwarded: 0,
				socialConectivities:0,
				stressConectivities:0,
				teamConectivities:0,
				individualConectivities:0,
				
				numberOfRatings: 0,     // The number of ratings that have been submitted for this Conectivitiy
				ratingsAverage:0,	    // Average rating in two decimal precision
				ratingsMax:0,		    // Max rating 1 - 5
				ratingsMin:0,		    // Min rating 1 - 5                
				numberOfCompletions:0,
				numberOfSkips: 0,
				numberOfLaunches: 0,
			};
 
			//update ratings if they exist for this conectivity
			if (conectivities[j].ratingStatsID !== null) {
				tempConectivityNumbers[j].numberOfRatings = conectivities[j].ratingStats.numberOfRatings;     // The number of ratings that have been submitted for this Conectivitiy
				tempConectivityNumbers[j].ratingsAverage = conectivities[j].ratingStats.ratingsAverage;	    // Average rating in two decimal precision
				tempConectivityNumbers[j].ratingsMax = conectivities[j].ratingStats.ratingsMax;		    // Max rating 1 - 5
				tempConectivityNumbers[j].ratingsMin = conectivities[j].ratingStats.ratingsMin;		    // Min rating 1 - 5                
				tempConectivityNumbers[j].numberOfCompletions = conectivities[j].ratingStats.numberOfCompletions;		                   
				tempConectivityNumbers[j].numberOfSkips = conectivities[j].ratingStats.numberOfSkips;		                 
				tempConectivityNumbers[j].numberOfLaunches = conectivities[j].ratingStats.numberOfLaunches;		                 

			}

			//update numbers for each conectivity installed in the portfolio 
			if (conectivities[j].conectCoins > tempAnalytics.mostCCs) tempAnalytics.mostCCs = conectivities[j].conectCoins;
			if (conectivities[j].conectCoins < tempAnalytics.leastCCs) tempAnalytics.leastCCs = conectivities[j].conectCoins;
			if (conectivities[j].badgesDEI > 0) tempAnalytics.deiConectivities+=1;
			if (conectivities[j].badgesCS > 0) tempAnalytics.csConectivities+=1;

			if (currentUser && conectivities[j].inactiveForCustomers) {
					if (conectivities[j].inactiveForCustomers.some(entry => entry == currentUser.customerID) == false ) tempAnalytics.active +=1;  //Count if NOT excluded by this company
					else tempAnalytics.inactive +=1;
			} else tempAnalytics.active +=1;  //Count if not excluded by ANY company
			
			if (conectivities[j].conectivityScope =="CUSTOMER_SPECIFIC") tempAnalytics.customerSpecific +=1; 
			if (conectivities[j].category.label == "SOCIAL") tempAnalytics.socialConectivities +=1; 
			if (conectivities[j].category.label == "STRESS") tempAnalytics.stressConectivities +=1; 
			if (conectivities[j].category.label == "TEAM") tempAnalytics.teamConectivities +=1; 
			if (conectivities[j].category.label == "PERSONAL") tempAnalytics.individualConectivities +=1; 

		}
			
		if (DEBUG_MODE >= 2) console.log("Starting to process transactions for customerWideConectivityAlytics.  Initialized Conectitivy Numbers Array:", tempConectivityNumbers);

		//Now, walk the entire list of transaction O(N) and update counts
		for (var j = 0; j < transactionsFiltered.length; j++){
			if (transactionsFiltered[j].transactionType === "COMPLETED_CONECTIVITY") {
				
				//Find the particular conectivity related to the transaction and update conectivity-specific data
				var index=conectivities.findIndex((conectivity) => conectivity.id === transactionsFiltered[j].conectivityID);
				
				//Update conectivity-specific numbers
				if (index >-1) {
					tempConectivityNumbers[index].completions +=1;
					tempConectivityNumbers[index].coinsAwarded +=transactionsFiltered[j].amount;
					tempConectivityNumbers[index].badgesDEIAwarded +=transactionsFiltered[j].badgesDEI;
					tempConectivityNumbers[index].badgesCSAwarded +=transactionsFiltered[j].badgesCS;
					
				}

				//Update total store-WIDE transaction numbers
				tempAnalytics.completionsTotal +=1;
				tempAnalytics.coinsEarnedTotal +=transactionsFiltered[j].amount;             
				tempAnalytics.badgesDEIEarnedTotal +=transactionsFiltered[j].badgesDEI;
				tempAnalytics.badgesCSEarnedTotal +=transactionsFiltered[j].badgesCS;
				
				
				
				//Record earliest transaction for future reference
				if (transactionsFiltered[j].createdAt < tempAnalytics.earliestTransation) {
					tempAnalytics.earliestTransation = transactionsFiltered[j].createdAt;
					// if (DEBUG_MODE >= 2) console.log("Earliest transction set", tempAnalytics.earliestTransation);
				}
				
			}
		}

		if (tempAnalytics.earliestTransation < "9999") tempAnalytics.earliestTransation = formatDate(tempAnalytics.earliestTransation);


		//Now that we have finished computing the per-conectvity stats, walk the Conectivity stats array and update CONECTICITY-WIDE MAXs, MINs and averages 
		
		 for (var j = 0; j < tempConectivityNumbers.length; j++){
			 
			 if (tempConectivityNumbers[j].completions >  tempAnalytics.completionsMax) tempAnalytics.completionsMax = tempConectivityNumbers[j].completions;
			 if (tempConectivityNumbers[j].completions <  tempAnalytics.completionsMin) tempAnalytics.completionsMin = tempConectivityNumbers[j].completions;

		 }

		//Set average completions per active conectivity
		tempAnalytics.completionsAvg = tempAnalytics.completionsTotal / tempAnalytics.active;
		setCustomerWideConectivityAlytics(tempAnalytics);                
		setPerConectivityAnalytics(tempConectivityNumbers);
		
		console.log ("Built conectivity-specific data from transactions:", tempConectivityNumbers);
		console.log ("Built customerWideConectivityAlytics data from transactions:", tempAnalytics);
		

	}
	

	useEffect(() => {
		buildConectivityRows();    //Rebuild our UI on any change to the conectivities or their customerWideConectivityAlytics
	}, [conectivities, perConectivityAnalytics, customerWideConectivityAlytics]);
	
	function buildConectivityRows () {

		//Basic safe guards
		if (conectivities == undefined || conectivities == null) return;
		if (conectivities.length == 0) return;
		if (perConectivityAnalytics == undefined || perConectivityAnalytics == null) return;
		if (perConectivityAnalytics.length == 0) return;
		if (customerWideConectivityAlytics == undefined || customerWideConectivityAlytics == null) return;
		
	   //Build rows for Data Grid
		var tempConectivityRows = [];
		
		for(var i = 0; i<conectivities.length; i++){
			
			//Get stats object
			const tempConectivityNumbers = perConectivityAnalytics.find(conectivity => conectivity.conectivityID === conectivities[i].id);
		   
				tempConectivityRows[i] = {
				id:i+1,
				dynamoDBID: conectivities[i].id, 
				author: conectivities[i].author,
				authorID: conectivities[i].authorID,
				title:conectivities[i].title,
				description: conectivities[i].description,
				// image:conectivities[i].imageS3.url,
				image:conectivities[i].image,
				conectCoins:conectivities[i].conectCoins,
				badgesDEI:conectivities[i].badgesDEI,
				badgesCS:conectivities[i].badgesCS,
				// customerName: !conectivities[i].createdByCustomer ? "---" : conectivities[i].createdByCustomer.name,
				createdByCustomerID : conectivities[i].createdByCustomerID,
				signupDays:formatDays(conectivities[i].signupDays),
				category:conectivities[i].category.name,
				shortLabel:conectivities[i].category.label,
				active: (!isAdmin || !conectivities[i].inactiveForCustomers ? true : conectivities[i].inactiveForCustomers.some(entry => entry == currentUser.customerID)==false),
				conectivityScope: conectivities[i].conectivityScope,
				// scopeUrl:(conectivities[i].conectivityScope === "CUSTOMER_SPECIFIC") ?  conectivities[i].createdByCustomer.logo : CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH,
				publicationStatus: (conectivities[i].isTemplate ? "TEMPLATE" : conectivities[i].publicationStatus), 
				isTemplate:conectivities[i].isTemplate,

				
				//Ratings fields
				numberOfRatings: 0,     // The number of ratings that have been submitted for this Conectivitiy
				ratingsAverage: 0,	    // Average rating in two decimal precision
				ratingsMax: 0,		    // Max rating 1 - 5
				ratingsMin: 0,		    // Min rating 1 - 5                
			
				//Stats
				completionsThisTransactionSet: tempConectivityNumbers.completions,
			
			};


			 //Set Customer fields
				if (conectivities[i].conectivityScope == "CUSTOMER_SPECIFIC" && conectivities[i].createdByCustomerID) {
					
					//CONECTIVITY WRITTEN BY A SPECIFIC COMPANY
					const tempCustomer = customers.find(customer => customer.id === conectivities[i].createdByCustomerID);    //All clients will have a customer array, even if only 1 entry
					tempConectivityRows[i].customerName = (tempCustomer ? tempCustomer.name : "");
					tempConectivityRows[i].scopeUrl = (tempCustomer ? tempCustomer.logo : "");
				} else {
	
					//CONECTERE SPONSORED (GLOBAL) PRODUCTS and DEFAULT STORE PRODUCTS      
					tempConectivityRows[i].customerName = "Conectere";
					tempConectivityRows[i].scopeUrl = CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
				}    
				
			//update ratings in the row if they exist for this conectivity
			if (conectivities[i].ratingStatsID != undefined) {
				tempConectivityRows[i].numberOfRatings = conectivities[i].ratingStats.numberOfRatings;     // The number of ratings that have been submitted for this Conectivitiy
				tempConectivityRows[i].ratingsAverage = conectivities[i].ratingStats.ratingsAverage;	    // Average rating in two decimal precision
				tempConectivityRows[i].ratingsMax = conectivities[i].ratingStats.ratingsMax;		    // Max rating 1 - 5
				tempConectivityRows[i].ratingsMin = conectivities[i].ratingStats.ratingsMin;		    // Min rating 1 - 5                
			}
		}
		
		//Sort the rows based on favorites
		tempConectivityRows.sort(compareConectivitiesRowsMostFavorite);
	
		if (DEBUG_MODE >= 2) console.log("Built Conectivity Rows:", tempConectivityRows);
		setConectivityRows(tempConectivityRows);

	}

	
	//Define Grid Format
	const conectivityColumns = [
	   {
		field: 'ratingsAverage',
		headerName: 'RATING',
		headerAlign: 'center',
		width: 200,
		editable: false,
		sortable: true,
		color:'white',
		renderCell: (params)=> {
			if (params.row.numberOfRatings > 0) {
				return (            
					<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
						<StarRatings rating={params.row.ratingsAverage} readOnly={true} starSize={"12px"} addNumber={true}/>  
						<div style={{fontSize:"0.8rem", paddingLeft:"5px"}}>({params.row.ratingsAverage.toFixed(1)})</div>
					</div>
				); 
			}else {
				return (            
					<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
						no ratings
					</div>
				);                 
			}
		}
	  },
	  {
		field: 'category',
		headerName: 'CATEGORY',
		headerAlign: 'center',
		width: 175,
		editable: false,
		color:'white',
		hide:false,
		renderCell: (params)=> {
			return (
				<div className="ContainerNoHeightCenter">
						<img className="ccImgXXL" src={setConectivityImageUrlByCategory(conectivities[params.row.id-1].category.label, true)}  />
						 <div style={{padding:'10px', color:setHeaderColorByCategory(params.row.shortLabel)}}>
							{params.row.category}
						</div>
				</div>
				);
			  },
	  },
	  {
		field: 'title',
		headerName: 'CONECTIVITY',
		headerAlign: 'center',
		// width: 320,
		minWidth: 320,
		flex:1,
		editable: false,
		sortable: true,
		color:'white',
		align:'left',
		renderCell: (params)=> {
		return (
			<div className="ContainerNoHeightCenter">
				<div style={{padding:'10px', color:setHeaderColorByCategory(params.row.shortLabel)}}>
					{params.row.title}
				</div>
			</div>
			);
		},
	  },
	  {
		field: 'description',
		headerName: 'DESCRIPTION',
		headerAlign: 'center',
		// width: 320,
		minWidth: 320,
		flex:1,
		editable: false,
		sortable: true,
		color:'white',
		renderCell: (params)=> {
			return (
				<div className="ContainerNoHeight" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
						{truncateStringWithDots (params.row.description,130)}
				</div>
				);
		},
	  },     
	 {
		field: 'conectCoins',
		headerName: 'AWARDS',
		headerAlign: 'center',
		width: 140,
		editable: true,
		color:'white',
		// valueFormatter: ({ value }) => {
		//     return value.toString() + ' coins';
				
		// },
		 renderCell: (params)=> {
			return (
					<div className="ContainerNoHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
						<div >
							{params.row.conectCoins} 
							<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
						</div>
						{!params.row.badgesDEI ? "" :
						<div>
							{params.row.badgesDEI} 
							<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
						</div>
						}
						{!params.row.badgesCS ? "" :
						<div>
							{params.row.badgesCS} 
							<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  />
						</div>
						}
					</div>
				);
		}
	  },
	  {
		field: 'conectivityScope',
		headerAlign: 'center',
		headerName: 'SOURCE',
		width: 135,
		editable: false,
		color:'white',
		hide: false,                 
		headerAlign: 'center',
		sortable:true,
		valueFormatter: ({ value }) => {
			
			return formatSourceColumnValue({value:value, customerName:currentUser.customer.name});
		},
		renderCell: (params)=> {
			return displayConectivitySourceColumn({customerID:currentUser.customerID, conectivityScope:params.row.conectivityScope, scopeUrl:params.row.scopeUrl, createdByCustomerID:params.row.createdByCustomerID, customerName:params.row.customer,  isSuperAdmin:isSuperAdmin, permissionEditor:permissionEditor});
		}

	  },

	   {
		field: 'action',
		headerAlign: 'center',
		headerName: ' ',
		minWidth: 100,
		flex:0.1,
		color:'#5DADE2',
		sortable: false,
		renderCell: (params)=> {
			
				if (params.row.completionsThisTransactionSet > 0) {
					return (
						<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
								<Button variant="light" size="sm" >
									<AssignmentIcon className="summaryButton" onClick={()=>handleSummary(params.row.dynamoDBID)}/>
								</Button>                            
						</div>
						);
				} else return null;
			}
		},
		
	];



	//Define Grid for User Summary
	const transactionSummaryColumns = [
	  {
		field: 'createdAt',
		headerName: 'DATE',
		headerAlign: 'center',
		align:'center',
		minWidth: 175,
		 // resizable: true,
		color:'white',
		valueFormatter: ({ value }) => {
			const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
			return tempTime.format(" MMM DD, YYYY ");
				
		},
		renderCell: (params)=> {
			return (
				<span> 
					<center>{formatDate(params.row.createdAt)}</center>
				</span>
				)
		}

	  },
	//   {
	//     field: 'name',
	//     headerName: 'Transaction',
	//     headerAlign: 'center',
	//     align:'center',
	//     minWidth: 200,
	//     resizable: true,
	//     background:COLOR_BLUE_HEADER,
	//     color:'white',
	//     renderCell: (params)=> {
	//         return (
	//             <div className="ContainerNoHeightCenter">
	//                 {params.row.permissionAnalytics==true ?  <Equalizer /> : ""}
	//                 {params.row.permissionEditor==true ?  <Edit /> : ""}
	//                 {params.row.permissionLaunch==true ?  <LaunchIcon /> : ""}
	//                 <div>
	//                     <img src={params.row.image} height='24' />
	//                 </div>
	//                 <div style={{padding:'10px', color:setHeaderColorByCategory(params.row.category)}}>
	//                     {params.row.name}
	//                 </div>
	//             </div>
	//             )
	//     },

	//   },

	{
		field: 'userName',
		headerName: 'EMPLOYEE',
		headerAlign: 'center',
		align:'center',
		minWidth: 400,  
		flex:1,
		color:'white',
		sortable: true,
		renderCell: (params)=> {
		return (
			<div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}}>
				 <div style={{width:"60px", }}>
						<div className="ContainerNoHeightFlexLeft" style={{width:"100%"}} >
							{params.row.avatarUrl =="" ? "" :
								<div className="avatarImage avatarImageMedium"> 
									<img src={params.row.avatarUrl} className="avatarImageCenterPortrait"  alt=""   /> 
								</div>
							}
						</div>
				</div>
				<div style={{width:"180px", textAlign:"left"}}>
						<div style={{padding:'5px', fontSize:"18px", fontWeight:"400"}}>{params.row.userName}</div>
				</div>
		  </div>
			);
		  },
	  },
	  
	  {
		field: 'amount',
		headerName: 'AMOUNT',
		headerAlign: 'center',
		align:'center',
		minWidth: 175,
		 // resizable: true,
		color:'white',
		disableExport: true,         
		renderCell: (params)=> {
			return (
				<div className="ContainerNoHeight">
					<div className= {params.row.amount<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 

						{params.row.amount<0 ? "" : "+"} 
						{params.row.amount}  
						<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
					</div>
					
					{!params.row.badgesDEI || params.row.badgesDEI ==0 ? "" :
						<div className= {params.row.badgesDEI<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
							{params.row.badgesDEI<0 ? "" : "+"} 
							{params.row.badgesDEI}  
							<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
						</div>
					}
					{!params.row.badgesCS || params.row.badgesCS ==0 ? "" :
						<div className= {params.row.badgesCS<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
							{params.row.badgesCS<0 ? "" : "+"} 
							{params.row.badgesCS}  
							<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
						</div>
					}
				</div>


				)
		}

	  },
	];
 
	function formatDate(inputTime) {
		
		const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
		return tempTime.format(" MMM DD, YYYY ");

	}


	const handleCancelUserSummary = () => {
		setShowModalSummary(false);
	};
		
	async function handleSummary (id) {

		if (DEBUG_MODE >= 2) console.log("Displaying transaction summary for CONECTIVITY having DynamoDB ID:", id);

		const tempConectivityToSummarize = perConectivityAnalytics.find(conectivity => conectivity.conectivityID === id);
		
		if (tempConectivityToSummarize == undefined) return;
		
		if (DEBUG_MODE >= 2) console.log("Displaying summary for conectivity ", tempConectivityToSummarize);

		setConectivityToSummarize(tempConectivityToSummarize);
		
		var tempTransactions =  transactionsFiltered.filter(transaction => transaction.conectivityID == id);
		
		if (DEBUG_MODE >= 2) console.log("Fetched transactions for conectivity summary", tempTransactions);

		
		//Build rows for a user-specific transaction log
		//Sort the new set of objects
		tempTransactions.sort(compareTransactionsByDateReverseChronological);
		
		if (DEBUG_MODE >= 2) console.log("computing transaction log for conectivity ",id, tempTransactions);
		
		 //Build rows for user Summary Data Grid 
		var tempThisConectivitiesRows = [];
		for(var i = 0; i<tempTransactions.length; i++){
			
			//Get relevant user
			const tempUserOnTransaction = users.find(user => user.id === tempTransactions[i].userID);

			tempThisConectivitiesRows[i] = {
				id:i+1,
				name:tempTransactions[i].name,
				amount:tempTransactions[i].amount,
				badgesCS:tempTransactions[i].badgesCS,
				badgesDEI:tempTransactions[i].badgesDEI,
				newBalance:tempTransactions[i].newBalance,
				badgesDEIBalance:tempTransactions[i].badgesDEIBalance,
				badgesCSBalance:tempTransactions[i].badgesCSBalance,
				createdAt:tempTransactions[i].createdAt,
				memo:tempTransactions[i].memo,
				sequenceNumber:tempTransactions[i].sequenceNumber,
				image:setConectivityImageUrlByCategory(tempTransactions[i].category, true),
				category:tempTransactions[i].category,
				
				userName:tempUserOnTransaction.firstName + (!tempUserOnTransaction.middleName ? "" : " " + tempUserOnTransaction.middleName.substr(0,1)) + " " + tempUserOnTransaction.lastName,
				title:tempUserOnTransaction.title,
				email: tempUserOnTransaction.email,
				teams: displayTeams(tempUserOnTransaction),
				customer: tempUserOnTransaction.customer.name,
				avatarUrl:tempUserOnTransaction.avatarUrl,            
			  
				};
		}
		
		if (DEBUG_MODE >= 2) console.log("Built transaction rows for conectivity summary:", tempThisConectivitiesRows);
		
		 setTransactionRowsSummary(tempThisConectivitiesRows);
		 
		setShowModalSummary(true);

	}

/*
	const handleCreatePDF = () => {
	   
	   if (DEBUG_MODE >= 2) console.log("Generating PDF");
	   setIsReporting(true);    //Render Report HTML
	   
	   createPDF(
		   {
			   title:'FAVORITE CONECTIVITIES',
			   columns:conectivityColumns,
			   rows:conectivityRows,
			   HTMLForReporting: reportRef.current,    //Pass in element via reference
		   });
	  
	};   


	function generateHTMLForReporting() {
		
		// if (!isReporting) return null;
	
			return (
						`<center>
							<div className="dashboardTitle">
								FAVORITE CONECTIVITIES
							 </div>   
		
						</center>`
		
				);
	}    
	
*/   

	//Handle access by unauthenticated user
	if (authState != "signedin"  || !currentUser) {
	  if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState == "signedout") popUpLoginWindow();
	  return null;
	}
	
	//Render function for authenticated user 
	//confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
  } else  return (
	<div className="pageWrapper" >

		<Authenticator>


		<Modal show={showModalSummary} onHide={handleCancelUserSummary} size="xl" centered>
			<Modal.Header closeButton>
		<Modal.Title>           
			  <div className="ContainerMaxHeightCenter">
				<div style={{minWidth:"100px", paddingRight:"10px", display:"flex", alignItems:"center", justifyContent:"center", color:"#409cf7"}}>
					<img src={setConectivityImageUrlByCategory(conectivityToSummarize.categoryLabel)} alt="..." height='48'  />
				</div>
				<div >
					{conectivityToSummarize.title}
				</div>
			</div>
		</Modal.Title>
			</Modal.Header>
			<Modal.Body>

					<div className="ContainerNoHeightCenter" >
						<div className="dataGridWrapperTop">        
							<MenuDataGrid dataGridRows={transactionRowsSummary} dataGridColumns={transactionSummaryColumns} pageSize={5} turnOffBorder={true} maxWidth={1000}/>
						</div>
					</div>


			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>
				<div > Close </div>
			  </Button>
			</Modal.Footer>
		 </Modal>    

			<div className="adminPage">
				<div className="ContainerVertical">

				<div >
					{displayReportControls({useUserSelect:false, useDateSelect:false,  width:"auto", usePeriodSelect:false, useReportSelect:true})}
				</div>
	
					<div className="dashboardContainer boxShadow">
						<div className="dashboardTitle" >
							FAVORITE CONECTIVITIES
						 </div>   
						
							<div className="dataGridWrapperTop"  >        
								<MenuDataGrid 
								title="FAVORITE CONECTIVITIES"
								showToolBar = {true}
								dataGridRows={conectivityRows} 
								dataGridColumns={conectivityColumns }
									// width={(isSuperAdmin) ? REPORT_DATAGRID_WIDTH_SUPERADMIN : REPORT_DATAGRID_WIDTH}
								/>
								
							</div>
					</div>  
				</div>
			</div>    
		</Authenticator>
 
	</div>
	
			
			
  );
};




export default ReportConectivitiesByFavoritePage;
/*

    PROPRIETARY AND CONFIDENTIAL
    
    PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
    Copyright 2020-2023.  All Rights Reserved

    Custom queries for Conectere
    10.29.2023
    
*/

//  Our custom queries have their own name and invokes an API resolver function to return objects that are defined in our schema
//  For example, our listUsersShallow invokes listUsers resolver function to return User objects

export const listUsersShallow = /* GraphQL */ `
  query ListUsersShallow(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          website
          activeEmployees
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const getUsersByCustomerShallow = /* GraphQL */ `
  query getUsersByCustomerShallow(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          website
          activeEmployees
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;


export const listTeamsShallow = /* GraphQL */ `
  query ListTeamsShallow(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeamsByCustomerShallow = /* GraphQL */ `
  query getTeamsByCustomerShallow(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getLeaderBoardByCustomerIDShallow = /* GraphQL */ `
  query getLeaderBoardByCustomerIDShallow(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerLeaderBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeaderBoardByCustomerIDShallow(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        leaderBoardType
        isViewableCompanyWide
        isAllEmployees
        lastDeltaDate
        isChallenge
        launchRuleID
        launchRuleInstanceIndex
        startDateTime
        endDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
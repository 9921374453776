//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';
import React from 'react';
// import "./displayCardProductFavorites.css";

//Utils
import { compareProductsByTitle, compareProductsByDateCreated, compareProductsLeastFavorite, compareProductsMostFavorite} from "../../../../shared/utils/productUtils";
import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const CCdisplayCardProductFavorites = React.memo(({productNumbers}) => {

    const title="STORE FAVORITES";
    
    // if (DEBUG_MODE >= 2) console.log("Product Numbers before sort:",productNumbers);
    
    var tempProductNumbers = productNumbers;
    
    if (productNumbers) tempProductNumbers = productNumbers.filter(product => product.purchases > 0);
    tempProductNumbers.sort(compareProductsMostFavorite);
    if (DEBUG_MODE >= 2) console.log("Product Numbers after sort:",tempProductNumbers);

    //Use only top 5    
    const filteredProductNumbers = tempProductNumbers.slice(0,8);
    
    const footer="*Items awarded / total $$";

    // if (DEBUG_MODE >= 2) console.log("Card called", productNumbers);

    //Purchases to show?
    if (!filteredProductNumbers || filteredProductNumbers.length===0)  return  (

        <div className="dashboardCard dashboardCardWideProfile dashboardCardFlexStartProfile" >
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            <Divider width={80} />

            <div className="ContainerMaxHeightCenter" > No employee rewards given </div>

        </div>
        
    );
    
 
    //YES   
    return (

        <div className="dashboardCard dashboardCardWideProfile dashboardCardFlexStartProfile">
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            <Divider width={80}/>

           <div className="dashboardCardHeaderRow">
                <div className="dashboardCardNumber" >Rewards</div>
                <div className="dashboardCardAward" >Coins</div>
                <div className="dashboardCardText black" style={{flex:"3 1"}}>Item</div>
           </div >


            {filteredProductNumbers.map((productI, index) => (
                <div className="dashboardCardTextRow" key={productI.productID}>
                    <div className="dashboardCardNumber"   >{productI.purchases}</div>
                    <div className="dashboardCardAward"  >{productI.coinsSpent}<img className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  /></div>
                    <div className="dashboardCardTextSmall"  style={{flex:"3 1"}}>{productI.title} </div>
                </div >
            ))}
            
        </div>
        
    );

});


export default CCdisplayCardProductFavorites;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR } from '../../../../shared/data/conectereConfigData';
import React from 'react';

// import "./displayCardUserStats1.css";

//Utils
import { truncateIntegerByThousand, Divider } from "../../../../shared/utils/generalUtils";

//React component
const CCdisplayCardUserStats1 = React.memo(({analytics}) => {

    const title="Employees (" + analytics.employeesTotal + ")";
    
    const footer="";

    // if (DEBUG_MODE >= 2) console.log("User Stats Card called", analytics);

    return (

        <div className="dashboardCard">
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            <Divider />

            <div className="dashboardCardTextRow">
                <div className="dashboardCardText" >Employee Averages</div>
            </div >

            <div className="ContainerNoHeightSpaceAround" style={{width:"100%"}}>
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall blue-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgStressConectivitiesCompletedByUsers.toFixed(1)}</div>
                </div>    
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall purple-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgTeamConectivitiesCompletedByUsers.toFixed(1)}</div>
                </div>    
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall green-background" style={{ marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgSocialConectivitiesCompletedByUsers.toFixed(1)}</div>
                </div>    
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall yellow-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgIndividualConectivitiesCompletedByUsers.toFixed(1)}</div>
                </div >
            </div>    

            <div className="ContainerNoHeightCenter TextStyle3" style={{ width:"100%"}}>
                    <span>{analytics.avgCoinsEarnedByUsers.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />&nbsp;</span>
                    <span>{analytics.avgDEIBadgesEarnedByUsers.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />&nbsp;</span>
                    <span>{analytics.avgCSBadgesEarnedByUsers.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />&nbsp;</span>
            </div >
 
             <div className="dashboardCardTextRow">
                <div className="dashboardCardText" >Company Totals</div>
            </div >

            <div className="dashboardCardTextRow" style={{padding:"5px", width:"100%"}}>
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall blue-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalStressConectivitiesCompletedByUsers)}</div>
                </div>    
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall purple-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalTeamConectivitiesCompletedByUsers)}</div>
                </div>    
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall green-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalSocialConectivitiesCompletedByUsers)}</div>
                </div>    
                <div className="ContainerVertical">
                    <img src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall yellow-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalIndividualConectivitiesCompletedByUsers)}</div>
                </div>
            </div >

            
            <div className="ContainerNoHeightCenter TextStyle3" style={{ width:"100%"}}>
                <span>{truncateIntegerByThousand(analytics.totalCoinsEarnedByUsers)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>&nbsp;</span>
                <span>{truncateIntegerByThousand(analytics.totalDEIBadgesEarnedByUsers)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/>&nbsp;</span>
                <span>{truncateIntegerByThousand(analytics.totalCSBadgesEarnedByUsers)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/>&nbsp;</span>
            </div >


           <div className="ContainerNoHeightCenter" >
                <div className="TextStyle2" >AVG CONECTIVITY</div>
                <div className="dashboardScoreOval purple">{analytics.avgRelativeConectivityScoreByUsers.toFixed(1) } </div>
            </div >

            {!footer ? "" : <span className="dashboardCardTextFooter">{footer} </span> }
        </div>
        
    );

});


export default CCdisplayCardUserStats1;



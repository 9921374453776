//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//Admin page by which superAdmin manages the images available in the system
//including  assignment to Conectivties

//DATA
import  { CONECTERE_CONFIG_DATA, INVOICE_INITIAL_STATE, DEBUG_MODE, CATEGORY_COLORS } from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {Authenticator, AmplifySignOut } from '@aws-amplify/ui-react';

//Queries and Mutations
//Bootstrap and other React components

//Our Analytics components
import CCdisplayCardSpendStats from "./Components/displayCardSpendStats/displayCardSpendStats";
import CCLineChart from "./Components/lineChart/lineChart"
import CCStackedAreaChart from "../../shared/Components/stackedAreaChart/stackedAreaChart"
import CCRadarChart from "./Components/radarChart/radarChart"
import CCStackedBarChart from "../../shared/Components/stackedBarChart/stackedBarChart"
import CCPieChart from "../../shared/Components/pieChart/pieChart"

//Utils
import {  renderOurRechartLegendFixedWidth, renderRechartTooltip } from "../../shared/utils/analyticsUtils";
import {NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING, ONE_MONTH_AGO, ONE_MONTH_AGO_STRING} from "../../shared/utils/dateTimeUtils";
import { truncateStringWithDots, ViewButton } from "../../shared/utils/generalUtils";
import { fetchInvoicesByCustomerByDate, fetchInvoices } from "../../shared/utils/databaseUtils";

//User Authentication context
import { AuthContext } from '../../shared/context/authContext';


//Material UI and my components therefrom
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";

//Icons

//Our Components
import InvoiceEditor from "../../shared/Components/invoiceEditor/invoiceEditor";

import moment from 'moment';

//Here's the start of our Javascrpt App
const DashboardSpend = () => {

    const [monthlySpendData, setMonthlySpendData] = useState([]);
    const [categoryRingData, setCategoryRingData] = useState([]);
    const [categoryRingDataTime, setCategoryRingDataTime] = useState([]);

    //variable for holding on this page data for cusomters 
    const [invoices, setInvoices] = useState([]);
    const [invoiceRows,setInvoiceRows]=useState([]);                                //Formatted rows for user grid    
    const [invoiceToView, setInvoiceToView] = useState(INVOICE_INITIAL_STATE);    //Used to hold a conectivity that is to be 

   //variable to control confirmation Modal if product presses DELETE; default to hidden
    const [showModalView, setShowModalView] = useState(false);
    
   // Authentication context
    const {
            authState,
            currentUser,
            isSuperAdmin,
            isAdmin,
            permissionAnalytics,
            permissionEditor,
            
    } = useContext(AuthContext);    

    //Analytics for Spend Dashboard 
    const analyticsInitialState = {
        
        activeEmployees: 0,
        serviceCostPerMonth: 0,
        
        //Company Totals
        totalMonthyInvestmentPerEmployee: 0,
        totalMonthyInvestment: 0,
        totalMonthlyTimePerEmployee: 0,
        totalMonthlyTime: 0,
        
        //Company Totals - Stress Management
        MonthyInvestmentPerEmployeeBalance: 0,
        MonthyInvestmentBalance: 0,
        MonthlyTimePerEmployeeBalance: 0,
        MonthlyTimeBalance: 0,

        //Company Totals - Team Building
        MonthyInvestmentPerEmployeeTeam: 0,
        MonthyInvestmentTeam: 0,
        MonthlyTimePerEmployeeTeam: 0,
        MonthlyTimeTeam: 0,

        //Company Totals - Social
        MonthyInvestmentPerEmployeeSocial: 0,
        MonthyInvestmentSocial: 0,
        MonthlyTimePerEmployeeSocial: 0,
        MonthlyTimeSocial: 0,

        //Company Totals - Personal Growth & Development
        MonthyInvestmentPerEmployeePersonal: 0,
        MonthyInvestmentPersonal: 0,
        MonthlyTimePerEmployeePersonal: 0,
        MonthlyTimePersonal: 0,


    }

    const[analytics,setAnalytics] = useState(analyticsInitialState);


    const invoiceColumns = [
      {
        field: 'invoiceNumber',
        headerName: 'INVOICE',
        headerAlign: 'center',
        align:'center',
        width: 100,
        editable: false,
        color:'white',
      },

      {
        field: 'invoiceStatus',
        headerName: 'STATUS',
        headerAlign: 'center',
        align:'center',
        width: 125,
        editable: false,
        color:'white',
         
      },
      {
        field: 'billingPeriodEndingDate',
        headerName: 'BILLING PERIOD',
        headerAlign: 'center',
        align:'center',
        width: 175,
        editable: false,
        color:'white',
         
      },
      {
        field: 'description',
        headerName: 'SUMMARY',
        headerAlign: 'center',
        minWidth: 125,
        flex:1,
         // resizable: true,
        editable: false,
        color:'white',
        renderCell: (params)=> {
                    return (
                        <div className="ContainerNoHeightCenter" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
                                {truncateStringWithDots(params.row.description,50)}
                        </div>
                    );
            },
      },
      {
        field: 'memo',
        headerName: 'MEMO',
        headerAlign: 'center',
        minWidth: 250,
        flex:1,
        editable: false,
        color:'white',
        renderCell: (params)=> {
                    return (
                        <div className="ContainerNoHeightCenter" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
                                {truncateStringWithDots(params.row.memo,100)}
                        </div>
                    );
            },
      },
      {
        field: 'charges',
        headerName: 'CHARGES',
        headerAlign: 'center',
        align:'center',
        width: 140,
        editable: false,
        color:'white',
         
      },
      {
        field: 'credits',
        headerName: 'CREDITS',
        headerAlign: 'center',
        align:'center',
        width: 140,
        editable: false,
        color:'white',
         
      },
      {
        field: 'tax',
        headerName: 'TAX',
        headerAlign: 'center',
        align:'center',
        width: 125,
        editable: false,
        color:'white',
      },
      {
        field: 'total',
        headerName: 'TOTAL',
        headerAlign: 'center',
        align:'center',
        width: 150,
        editable: false,
        color:'white',
      },
      {
        field: 'action',
        headerName: ' ',
        headerAlign: 'center',
        align:'center',
        width: 150,
        color:'white',
        sortable: false,
        renderCell: (params)=> {
            return (
                <div className="ContainerMaxHeightSpaceBetween">
                    <ViewButton onClickCallback={handleView} onClickCallbackParam={params.row.dynamoDBID} />
                </div>
                );
            }
        },
    ];
    



    //Get image data upon opening this page, return an array of existing images, if any
    useEffect(() => {
        fetchPageData();  //call our funciton
    }, [authState]);
    
    //let's write the function to grab the image data
    async function fetchPageData() {

      if (DEBUG_MODE >= 2) console.log("Fetching data", authState);

      if (authState != "signedin") return;

      if (DEBUG_MODE >= 2) console.log("Config data loaded", CONECTERE_CONFIG_DATA);


      //Set the forecasted spend data based on system config and # of active employees
      
     var tempAnalytics = {
        activeEmployees: currentUser.customer.activeEmployees,
        serviceCostPerMonth: CONECTERE_CONFIG_DATA.CONECTERE_COST_PER_USER_PER_MONTH_LEVEL_2,     //Default this to LEVEL 2 - CHANGE WHEN READ FROM THE DB

        //Company Totals
        totalMonthyInvestmentPerEmployee: CONECTERE_CONFIG_DATA.TOTAL_MONTHLY_INVESTMENT_PER_EMPLOYEE,
        totalMonthyInvestment: CONECTERE_CONFIG_DATA.TOTAL_MONTHLY_INVESTMENT_PER_EMPLOYEE * currentUser.customer.activeEmployees,
        totalMonthlyTimePerEmployee: CONECTERE_CONFIG_DATA.TOTAL_MONTHLY_TIME_PER_EMPLOYEE,
        totalMonthlyTime: CONECTERE_CONFIG_DATA.TOTAL_MONTHLY_TIME_PER_EMPLOYEE * currentUser.customer.activeEmployees,
        
        //Company Totals - Stress Management
        MonthyInvestmentPerEmployeeBalance: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_BALANCE,
        MonthyInvestmentBalance: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_BALANCE * currentUser.customer.activeEmployees,
        MonthlyTimePerEmployeeBalance: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_BALANCE,
        MonthlyTimeBalance: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_BALANCE * currentUser.customer.activeEmployees,

        //Company Totals - Team Building
        MonthyInvestmentPerEmployeeTeam: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_TEAM,
        MonthyInvestmentTeam: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_TEAM * currentUser.customer.activeEmployees,
        MonthlyTimePerEmployeeTeam: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_TEAM,
        MonthlyTimeTeam: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_TEAM * currentUser.customer.activeEmployees,

        //Company Totals - Social
        MonthyInvestmentPerEmployeeSocial: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_SOCIAL,
        MonthyInvestmentSocial: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_SOCIAL * currentUser.customer.activeEmployees,
        MonthlyTimePerEmployeeSocial: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_SOCIAL,
        MonthlyTimeSocial: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_SOCIAL * currentUser.customer.activeEmployees,

        //Company Totals - Personal Growth & Development
        MonthyInvestmentPerEmployeePersonal: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_PERSONAL,
        MonthyInvestmentPersonal: CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_PERSONAL * currentUser.customer.activeEmployees,
        MonthlyTimePerEmployeePersonal: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_PERSONAL,
        MonthlyTimePersonal: CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_PERSONAL * currentUser.customer.activeEmployees,

    }


    var tempCategoryRingData = [
      { name: 'Balance', value: Math.round(100*CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_BALANCE * currentUser.customer.activeEmployees)/100 },
      { name: 'Team', value: Math.round(100*CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_TEAM * currentUser.customer.activeEmployees)/100 },
      { name: 'Social', value: Math.round(100*CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_SOCIAL * currentUser.customer.activeEmployees)/100 },
      { name: 'Growth', value: Math.round(100*CONECTERE_CONFIG_DATA.INVESTMENT_PER_MONTH_PER_EMPLOYEE_PERSONAL * currentUser.customer.activeEmployees)/100 },
    ];


    var tempCategoryRingDataTime = [
      { name: 'Balance', value: Math.round(100*CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_BALANCE * currentUser.customer.activeEmployees/60)/100 },
      { name: 'Team', value: Math.round(100*CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_TEAM * currentUser.customer.activeEmployees/60)/100 },
      { name: 'Social', value: Math.round(100*CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_SOCIAL * currentUser.customer.activeEmployees/60)/100 },
      { name: 'Growth', value: Math.round(100*CONECTERE_CONFIG_DATA.APT_PER_MONTH_PER_EMPLOYEE_PERSONAL * currentUser.customer.activeEmployees/60)/100 },
    ];
    
    setAnalytics(tempAnalytics);
    setCategoryRingData (tempCategoryRingData);
    setCategoryRingDataTime (tempCategoryRingDataTime);

    // if (DEBUG_MODE >= 2) console.log("Temp Analytics", tempAnalytics);
    // if (DEBUG_MODE >= 2) console.log("TempCategoryRingData", tempCategoryRingData);
    // if (DEBUG_MODE >= 2) console.log("TempCategoryRingDataTime", tempCategoryRingDataTime);
    
    //Set forecast of monthly
    setMonthlySpendData ([
      {
        month: 'Forecast (monthly)',
       "Reward Center": Math.round(100* CONECTERE_CONFIG_DATA.TOTAL_MONTHLY_INVESTMENT_PER_EMPLOYEE * currentUser.customer.activeEmployees)/100,  
       "Conectere Service": Math.round(100*CONECTERE_CONFIG_DATA.CONECTERE_COST_PER_USER_PER_MONTH_LEVEL_2 * currentUser.customer.activeEmployees)/100,    
      }]);

    if (DEBUG_MODE >= 2) console.log("Set Monthly spend data for this customer", CONECTERE_CONFIG_DATA, currentUser.customer);
    //Get Billing History Information
    try {

            
       var tempInvoices = [];
        
        if (isSuperAdmin) {
            // gather all invoice data from backend
            tempInvoices = await fetchInvoices();

        } else if (currentUser){
            // gather invoice data for just the customer
          tempInvoices = await fetchInvoicesByCustomerByDate({customerID:currentUser.customerID, fromDateString:ONE_YEAR_AGO_STRING()})
        }
        setInvoices(tempInvoices);
        
       //Sort the new set of objects
        const sortedInvoices = tempInvoices;
        sortedInvoices.sort(compareInvoices);
        setInvoices(sortedInvoices); //Update state data with newly sorted list

        if (DEBUG_MODE >= 2) console.log("Fetched Invoices:", tempInvoices);

        buildInvoiceRows(tempInvoices);
   
      } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching products', err); }
  
     }

  function buildInvoiceRows (tempInvoices) {
       //Build rows for Data Grid
      var tempInvoiceRows = [];
      for(var i = 0; i<tempInvoices.length; i++){
        
          const billingPeriodEndingDateMoment = moment(tempInvoices[i].billingPeriodEndingDate);

          tempInvoiceRows[i] = {
          id:i+1,
          dynamoDBID: tempInvoices[i].id, 
          customerID:tempInvoices[i].customerID,
          invoiceNumber: tempInvoices[i].invoiceNumber,
          description:tempInvoices[i].description,
          billingPeriodEndingDate:billingPeriodEndingDateMoment.format("MMMM YYYY"),
          memo:(tempInvoices[i].lineItems && tempInvoices[i].lineItems.items && tempInvoices[i].lineItems.items.length >0 ? tempInvoices[i].lineItems.items[0].memo : "" ),
          charges:"$"+tempInvoices[i].charges.toString(),
          credits:"$"+tempInvoices[i].credits.toString(),
          tax:"$"+tempInvoices[i].tax.toString(),
          total:"$"+tempInvoices[i].total.toString(),
          invoiceStatus:tempInvoices[i].invoiceStatus,
          customerName: tempInvoices[i].customerName,
          };
      }

      setInvoiceRows(tempInvoiceRows);
      if (DEBUG_MODE >= 2) console.log("Built Invoice Rows:", tempInvoiceRows);
  }
    //Functions for sorting invoiceNumber
    function compareInvoices(a, b) {
    
        if (a.invoiceNumber > b.invoiceNumber) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.invoiceNumber < b.invoiceNumber) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    const handleView = (invoiceID) => {

        if (DEBUG_MODE >= 2) console.log("VIEW button pressed.  ID =", invoiceID,invoices); 

        const invoiceToView = invoices.find(c => c.id === invoiceID);
        if (!invoiceToView) return;
        
        if (DEBUG_MODE >= 2) console.log("VIEW button pressed.  VIEW Invoice =", invoiceToView); 
        setInvoiceToView(invoiceToView);
        setShowModalView(true); //pop-up Modal
    };

    const handleCloseEditor = () => {
            setShowModalView(false);
            setInvoiceToView(INVOICE_INITIAL_STATE);
            if (DEBUG_MODE >= 2) console.log("Closed INVOICE Editor");
    };
    
    //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 
     //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">SPEND ANALYTICS</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else return (


        <div className="adminPage">


          <InvoiceEditor  showModalView={showModalView}  invoiceToEdit={invoiceToView} handleCloseEditor={handleCloseEditor}  />

            <div className="dashboardContainer boxShadow">
                <div className="dashboardTitle">
                    BILLING HISTORY
                 </div>   
 
               {!isSuperAdmin ? "" :
                <div className="dashboardCardRow">
                
                    <CCdisplayCardSpendStats analytics={analytics}/>

                   <div className="dashboardCard dashboardCardWideProfile " >
                   
                      <CCPieChart data={categoryRingData} 
                            title="Investment ($ per month)" 
                            width="500px"
                            height="30vh"
                            dataKey="value" 
                            colors={CATEGORY_COLORS} 
                            fillColor="#8884d8" 
                            innerRadius="65" 
                            outerRadius="100" 
                            renderLegend = {renderOurRechartLegendFixedWidth}
                            renderTooltip = {renderRechartTooltip}
                       />
                      </div>
                </div>
               }

               {!isSuperAdmin ? "" :
               
                <div className="dashboardCardRow" >
                    <div className="dashboardCard dashboardCardWideProfile " >
                      <CCStackedBarChart 
                        data={monthlySpendData} 
                        title="Monthly Spend" 
                        dataKeyX="month" 
                        dataKeyY1="Conectere Service" color1="#BB8FCE"
                        dataKeyY2="Reward Center" color2="#5DADE2"
                        height="300px"
                        width="400px"
                        grid/>
                    </div>
  
                     <div className="dashboardCard dashboardCardWideProfile " >
                      <CCPieChart 
                            data={categoryRingDataTime}
                            width="500px"
                            height="30vh"
                            
                            title="Paid Time (hrs per month)" 
                            dataKey="value" 
                            colors={CATEGORY_COLORS} 
                            fillColor="#8884d8" 
                            innerRadius="65" 
                            outerRadius="100" 
                            renderLegend = {renderOurRechartLegendFixedWidth}
                            renderTooltip = {renderRechartTooltip}
                       />
                     </div>
                </div>
               }
               
              <div className="dataGridWrapperTop" >
                <MenuDataGrid dataGridRows={invoiceRows} dataGridColumns={invoiceColumns}/>
              </div>
        </div>    
    </div>    
  );
};

export default DashboardSpend;
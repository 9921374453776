//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//Style
import '../styles/App.css';

//React, Amplify
import React, { useEffect, useState, useRef } from "react";
import { Authenticator, AmplifyProvider, useTheme, View, Image, Text, Heading, Theme,  } from "@aws-amplify/ui-react";         //Import Amplify's REACT UI components

//Data
import  { CONECTERE_CONFIG_DATA, TEAM_COLOR, DEBUG_MODE, COLOR_WHITE, COLOR_BLUE_HEADER, LEGAL_PRIVACY_POLICY, LEGAL_TERMS_AND_CONDITIONS } from '../data/conectereConfigData';


	//
	//Styling the Authenticator 
	//  https://ui.docs.amplify.aws/react/connected-components/authenticator/customization 
	// https://ui.docs.amplify.aws/components/authenticator#styling
	// https://ui.docs.amplify.aws/
	//
	

	//This struct controls the input fields
	export const authenticatorFormFields = {
      signIn: {
       username: {
         labelHidden: false,
         placeholder: 'Username (Email)',
         isRequired: true,
         label: 'Username'
       },
       password: {
          label: 'Password:',
          placeholder: 'Password:',
          isRequired: true,
        },  
      },
    };
 
    
    //This struct controls the elements displayed within the signup screen
    export const authenticatorComponents = {
       Header() {
       
          return (
             <div className="ContainerVerticalCenter"  >
                <div  className=" ccImgXXXXLSquare ccImagePadding" > 
                   <img alt="Conectere logo"  src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} />
                </div>
                <div className="TextStyle4 grey">New to Conectere? <a href="https://signup.conectere.net " target="_blank" >
                  <span className="TextStyle4 white bold">Sign up today!</span>
                  </a>
               </div>
                <p className="TextStyle4 grey" style={{paddingBottom: "clamp(4px,1vh,10px)"}}> Need help? <a href="https://www.conectere.net/contact.html" target="_blank" >Contact us</a></p>
             </div>
          );
       },
 
       Footer() {
          return (
             <div className="ContainerNoHeightCenter TextStyle2 grey" style={{marginTop: "1.0rem"}}>
                   <div>By clicking Sign In, you agree to Conectere’s &nbsp;</div> 
                   <div> <a href={LEGAL_PRIVACY_POLICY} target="_blank">Privacy Policy</a>                             
                      &nbsp; and &nbsp; 
                      <a href={LEGAL_TERMS_AND_CONDITIONS} target="_blank">Terms & Conditions.</a>
                   </div>
             </div>
          );
       },
       
       /* Left as examples
       SignIn: {
          // Header() {
          //   const { tokens } = useTheme();
       
          //   return (
          //     <Heading
          //       padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          //       level={3}
          //     >
          //       Sign In
          //     </Heading>
          //   );
          // },
          // Footer() {
             
          // const handleCancelLogin = () => {
          //      setShowSignInPopUp(false); //Hide the Authenticator DIV
          //      setRedirectToPage("/"); //Jump back to HOME, if not already there
          // };
       
          //   return (
          //     <View textAlign="center">
          //       <Button
          //         fontWeight="normal"
          //         onClick={handleCancelLogin}
          //         size="small"
          //         variation="link"
          //       >
          //         Cancel
          //       </Button>
          //     </View>
          //   );
          // },
       },
 
       SignUp: {
          Header() {
             const { tokens } = useTheme();
 
             return (
             <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
             >
                Create a new account
             </Heading>
             );
          },
          Footer() {
             const { toSignIn } = useAuthenticator();
 
             return (
             <View textAlign="center">
                <Button
                   fontWeight="normal"
                   onClick={toSignIn}
                   size="small"
                   variation="link"
                >
                   Back to Sign In
                </Button>
             </View>
             );
          },
       },
       ConfirmSignUp: {
          Header() {
             const { tokens } = useTheme();
             return (
             <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
             >
                Enter Information:
             </Heading>
             );
          },
          Footer() {
             return <Text>Footer Information</Text>;
          },
       },
       SetupTOTP: {
          Header() {
             const { tokens } = useTheme();
             return (
             <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
             >
                Enter Information:
             </Heading>
             );
          },
          Footer() {
             return <Text>Footer Information</Text>;
          },
       },
       ConfirmSignIn: {
          Header() {
             const { tokens } = useTheme();
             return (
             <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
             >
                Enter Information:
             </Heading>
             );
          },
          Footer() {
             return <Text>Footer Information</Text>;
          },
       },
       ResetPassword: {
          Header() {
             const { tokens } = useTheme();
             return (
             <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
             >
                Enter Information:
             </Heading>
             );
          },
          Footer() {
             return <Text>Footer Information</Text>;
          },
       },
       ConfirmResetPassword: {
          Header() {
             const { tokens } = useTheme();
             return (
             <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
             >
                Enter Information:
             </Heading>
             );
          },
          Footer() {
             return <Text>Footer Information</Text>;
          },
       },
       */
    };
    
    export const authenticatorComponentsWhite = {

   };
    
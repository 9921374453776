//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//DATA
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOOD, COLOR_WARNING_LOW, COLOR_WARNING_HIGH, COLOR_ALERT, TEAM_COLOR, FAINT_GREY } from '../../../../shared/data/conectereConfigData';
import { userSummaryColumns } from '../../../../shared/data/gridColumns';

//React and AWS components
import React, { useState, useContext, useEffect, useRef } from 'react';

//MUI, Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

//Our Components
import MenuDataGrid from "../../../../shared/Components/menuDataGrid/menuDataGrid";
import { ConectivityGraph } from "../conectivityGraph/conectivityGraph";
import CCRadarChart from "../radarChart/radarChart";
import CCdisplayCardUserStatsModal from "../displayCardUserStatsModal/displayCardUserStatsModal";
import ModalNoBackground from "../../../../shared/Components/modalNoBackground/modalNoBackground";
import GiftCoins from "../giftCoins/giftCoins";
import TransactionAdjustment from "../transactionAdjustment/transactionAdjustment";


//CONTEXT
import { AnalyticsContext } from '../../../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../../../shared/context/customerContext';        //User Authentication context

//Utils
import { setHeaderColorByStringLength } from "../../../../shared/utils/conectivityUtils";
import { GiftButton, TransactionButton, Divider, TabPanel, displayAwards } from "../../../../shared/utils/generalUtils";
import { getUserInitials } from "../../../../shared/utils/userAndTeamUtils";


// This component provides a summary modal for one or more users as specified in an array of user IDs
// IF a SINGLE user is specified, then the summary provides a more detailed version for that user based on the input transactions
// IF multiple users are specified, then the components summarizes the 'conectivity' between those users based on the input transactions
const UserConectivitySummary = ({showUserSummary, showConectivityGraphTab, transactions, handleCancelUserSummary,  userIDsToSummarize }) => {

    //Modals
    const [showModalGift, setShowModalGift] = useState(false);
    const [showModalAdjustment, setShowModalAdjustment] = useState(false);
 

   // Customer context
	const { users,  } = useContext(CustomerContext);  
   
	//Analytics context
	const {
			analyticsGenerated,
			userAnalytics, 
			radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData,
	} = useContext(AnalyticsContext);

   const [rows,setRows] = useState([]);

	//State variables
	const [userAnalyticsToSummarize, setUserAnalyticsToSummarize] = useState(null);                
	const [usersToSummarize, setUsersToSummarize] = useState(null);      //User objects for users to summarize in pop-up modal
	const [isMultiUser, setIsMultiUser] = useState(false);   

	//Reference for tracking width of container so we can control inner grid size
	const userSummaryDivRef = useRef();
	const userSummaryMultiDivRef = useRef();

   useEffect(() => {

		// if (DEBUG_MODE >= 2) console.log("User Summary UseEffect invoked", showUserSummary, userIDsToSummarize);

		if (!analyticsGenerated) return;
		if (!showUserSummary || !userIDsToSummarize ||  !userIDsToSummarize.length || userIDsToSummarize.length < 1) return null;

		//Get a user list
		
		const tempUsersToSummarize = users.filter(user => userIDsToSummarize.includes(user.id));
		setUsersToSummarize(tempUsersToSummarize);

		if (userIDsToSummarize.length === 1) {

			//Compute radar data for Modal for this user
			computeRadarChartData(userIDsToSummarize[0]);

		   //Get analytics for this user node based on user ID 
			const tempUserToSummarize = userAnalytics.find(user => user.userID === userIDsToSummarize[0]);
			
			if (!tempUserToSummarize) return;
			
			if (DEBUG_MODE >= 2) console.log("Displaying summary for user ", tempUserToSummarize);  
			setUserAnalyticsToSummarize(tempUserToSummarize);
			setIsMultiUser(false);
	
		} else if (userIDsToSummarize.length >1) {
			setIsMultiUser(true);
		}

	  //Build rows for Data Grid
		buildUserSummaryRows();

	}, [userIDsToSummarize, transactions, analyticsGenerated]);


	function buildUserSummaryRows() {
		if (DEBUG_MODE >= 2) console.log("computing transaction log for users ", transactions);
		
		 //Build rows for user Summary Data Grid 
		var tempThisUsersRows = [];
		for(var i = 0; i<transactions.length; i++){
			tempThisUsersRows[i] = {
			id:i+1,
			name:transactions[i].name,
			amount:transactions[i].amount,
			badgesCS:transactions[i].badgesCS,
			badgesDEI:transactions[i].badgesDEI,
			badgesSpotlight:transactions[i].badgesSpotlight,
			newBalance:transactions[i].newBalance,
			badgesDEIBalance:transactions[i].badgesDEIBalance,
			badgesCSBalance:transactions[i].badgesCSBalance,
			badgesSpotlightBalance:transactions[i].badgesSpotlightBalance,
			createdAt:transactions[i].createdAt,
			memo:transactions[i].memo,
			sequenceNumber:transactions[i].sequenceNumber,
			image: transactions[i].image,
			category:transactions[i].category,
			usersThatParticipatedWithMe:transactions[i].usersThatParticipatedWithMe,
			teamsThatParticipatedWithMe:transactions[i].teamsThatParticipatedWithMe,
			scheduledConectivityID: transactions[i].scheduledConectivityID,
			};
			
			//Populate row with an array of users - the user who recorded the transaction and any participants 
			var usersToShow = [];
			const userWhoRecTrans = users.find(user => transactions[i].userID === user.id);		
			if (userWhoRecTrans) usersToShow.push(userWhoRecTrans);
			if (transactions[i].usersThatParticipatedWithMe) {
				
				for (var j=0; j<transactions[i].usersThatParticipatedWithMe.length; j++) {					
					//Note - only add participants that are different from the user who recorded the transaction, which we already addede above
					if (transactions[i].usersThatParticipatedWithMe[j] !== userWhoRecTrans.id ) {
						const tempParticipant = users.find(participatingUser => participatingUser.id == transactions[i].usersThatParticipatedWithMe[j] );
						if (tempParticipant) usersToShow.push(tempParticipant);
					}
				}     
			}

			//Store users
			tempThisUsersRows[i].usersToShow = [...usersToShow];
			
		}
		
		if (DEBUG_MODE >= 2) console.log("Built user rows for user summary:", tempThisUsersRows);
		
		setRows(tempThisUsersRows);
	}

	    //Functions for handling gifts
    // Pop up a modal for launching a conectivity for a specific user or team
    const handleGift = (e) => {
		if (e) e.stopPropagation();        //prevent click propagation       
		if (DEBUG_MODE >= 2) console.log("GIFT button pressed");       
		setShowModalGift(true); //pop-up Modal
  };    

  const handleCloseGiftModal = () => {
		setShowModalGift(false);
		// if (DEBUG_MODE >= 2) console.log("Close Gift component called");
  };

	//User Summary Tabs
	const [tabIndexValue, setTabIndexValue] = useState(0);
	const handleTabChange = (event, newValue) => {
		setTabIndexValue(newValue);
	};

	//
	// MULTI-USER SUMMARY
	//
	function displayMuliUserSummary() {
		if (!usersToSummarize || usersToSummarize.length === 0) return null;
		return (
			// CONTENT FOR MULTI-USER SUMMARY
			<div className="ContainerVerticalCenter" ref={userSummaryMultiDivRef} style = {{width:"100%"}}>
			
				<div className="ContainerNoHeightCenter" style = {{width:"100%"}}>
					<div className="avatarContainer"> 
						<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(usersToSummarize[0].lastName)}}>
							{!usersToSummarize[0].avatarUrl  ?  
								<div className="avatarInitials" > {getUserInitials(usersToSummarize[0])}</div>
								: 
								<img className="avatarImageCenterPortrait" src={usersToSummarize[0].avatarUrl} alt={getUserInitials(usersToSummarize)} /> 
								
							}
						</div>
						<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(usersToSummarize[0].lastName)}}>{usersToSummarize[0].firstName + " " + (!usersToSummarize[0].middleName ? "" : usersToSummarize[0].middleName.substring(0,1) + " ") + usersToSummarize[0].lastName}</span>
					</div>

					 <div className="avatarContainer"> 
						<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(usersToSummarize[1].lastName)}}>
							{!usersToSummarize[1].avatarUrl  ?  
								<div className="avatarInitials" > {getUserInitials(usersToSummarize[1])}</div>
								: 
								<img className="avatarImageCenterPortrait" src={usersToSummarize[1].avatarUrl} alt={getUserInitials(usersToSummarize[1])} /> 
								
							}
						</div>
						<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(usersToSummarize[0].lastName)}}>{usersToSummarize[1].firstName + " " + (!usersToSummarize[1].middleName ? "" : usersToSummarize[1].middleName.substring(0,1) + " ") + usersToSummarize[1].lastName}</span>
					</div>
				</div>
				
				<div className="dataGridWrapperTop" style = {{width:"1000px"}}>        
					<MenuDataGrid 
					dataGridRows={rows}  
					rowHeight={40} 
					maxWidth={(userSummaryMultiDivRef && userSummaryMultiDivRef.current) ? userSummaryMultiDivRef.current.clientWidth : ""} 
					dataGridColumns={userSummaryColumns}
					/>
				</div>
			</div>  			
		)
	}

	//
	//	SINGLE USER SUMMARY
	//

	function displaySingleUserSummary() {
		if (!usersToSummarize || usersToSummarize.length === 0) return null;
		return (
			<div className="tabHeaderBox fullWidth" ref={userSummaryDivRef} style={{paddingLeft:"20px", paddingRight:"20px"}}>
				<Tabs value={tabIndexValue} onChange={handleTabChange} aria-label="tabs">
					<Tab label="Stats" value={0} />
					{!showConectivityGraphTab ? null : <Tab label="Conectivity Graph" value={1} /> }
					<Tab label="Wallet" value={2} />
				</Tabs>
				
				<Divider percent={100} color={FAINT_GREY}/>

				<TabPanel value={tabIndexValue} index={0}>
					<div className="ContainerNoHeightCenter wrap" style={{paddingBottom:"clamp(8px,2vw,20px)"}}>
						<CCdisplayCardUserStatsModal analytics={userAnalyticsToSummarize}/>
						<div>
							<CCRadarChart 
								data={radarChartData} title={!radarChartTargetName ? "Conectivity Visualization" :  radarChartTargetName }  
								dataKeyAngle="subject" 
								radarName1={radarChartNames.length < 1 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 1 ? "" : radarChartColors[0]}
								radarName2={radarChartNames.length < 2 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length <1 ? "" : radarChartColors[1]}
							grid/>
						</div>
					</div>
				</TabPanel>

				{!showConectivityGraphTab ? null :
					<TabPanel value={tabIndexValue} index={1}>
						<div className="dataGridWrapperTop" >        
								<ConectivityGraph userToGraph={userAnalyticsToSummarize}/>
						</div>
					</TabPanel>
				}

					<TabPanel value={tabIndexValue} index={2}>
						<div className="dataGridWrapperTop" >       
							<div className="ContainerNoHeightSpaceAround fullWidth maxWidthLarge" style={{paddingBottom:"10px"}}>             

								<div className="ContainerVertical" >
									<div className="TextStyle4 headerBlue" > Current Balance </div>
									<div className="dashboardScoreRectangle-Root" >
												{displayAwards({
												fontSize:"lg", 
												// paddingBetweenAwards:"50px", imageHeight:"24px", 
												coins: (usersToSummarize[0].ccWallet ? usersToSummarize[0].ccWallet.currentBalance : 0),
												badgesCS: (usersToSummarize[0].ccWallet ? usersToSummarize[0].ccWallet.badgesCS : 0),
												badgesDEI: (usersToSummarize[0].ccWallet ? usersToSummarize[0].ccWallet.badgesDEI : 0),
												badgesSpotlight: (usersToSummarize[0].ccWallet ? usersToSummarize[0].ccWallet.badgesDEI : 0),
												showZeros:true,
											})
											}
									</div>
								</div>	

								<GiftButton onClickCallback={handleGift} hoverText="Want to recognize a colleague's contributions? Know someone that could use a bit of help?  Send a gift of coins!!"  fontSize="36px"/> 
								<TransactionButton onClickCallback={()=>setShowModalAdjustment(true)} hoverText="Make a transaction adjustment to an employees wallet, including making a credit or debit to their coin balance."  fontSize="36px"/> 
							</div>
							<MenuDataGrid 
								dataGridRows={rows}  
								maxWidth={(userSummaryDivRef && userSummaryDivRef.current) ? userSummaryDivRef.current.clientWidth : ""} 
								rowHeight={40} 
								dataGridColumns={userSummaryColumns}
							/>
						</div>
				</TabPanel>				
			</div>
		);			
	}

	//Safety checks
	if (!userIDsToSummarize ||  !userIDsToSummarize.length || userIDsToSummarize.length < 1) return null;

	return (
		<>
			<ModalNoBackground showModal={showUserSummary} cardWidth='80vw' closeCallback={handleCancelUserSummary} >
				<div className="modalNoBkgInnerCard" >
					<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
							<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt='Logo' />  
					</div>
						<h4>
							{(!isMultiUser && userAnalyticsToSummarize) ?  userAnalyticsToSummarize.name : null }
						</h4>
						<h5>
							{isMultiUser ? "Peer-to-peer" : null} Employee Conectivity Summary 
						</h5>

					<div className="modalNoBkgCenterColumn" style={{paddingTop:"clamp(8px,2vw,20px)"}} >
						{isMultiUser ? displayMuliUserSummary() :  displaySingleUserSummary() }		
					</div>

					<div className="modalNoBkgFooter" style={{paddingTop:"clamp(8px,2vw,20px)"}}>
						<Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>  Close  </Button>
					</div>
				</div>
			</ModalNoBackground>      
			
			<GiftCoins showModalGift={showModalGift} handleCloseGiftModal={handleCloseGiftModal} />
			<TransactionAdjustment showModalAdjustment={showModalAdjustment} handleCloseAdjustmentModal={()=>setShowModalAdjustment(false)} />
		</>
	); 
};

export default UserConectivitySummary;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021

//  ADMIN for managing users, including user analytics

import "./adminTeams.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, configDetailsInitialState, progressDataInitialState, walletInitialState, USER_INITIAL_STATE, userAnalyticsInitialState, teamInitialState, teamAnalyticsInitialState} from '../../shared/data/conectereConfigData';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries and Mutations

//Our components
import CCdisplayCardTeamStats from "./Components/displayCardTeamStats/displayCardTeamStats";
import CCdisplayCardTeamNumbers from "./Components/displayCardTeamNumbers/displayCardTeamNumbers";
import CCdisplayCardTeamStatsModal from "./Components/displayCardTeamStatsModal/displayCardTeamStatsModal";
import CCRadarChart from "./Components/radarChart/radarChart";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Select from "react-dropdown-select";
import Select from 'react-select'   //Version with support from multiple selections
// import GridTeams from "./Components/gridTeams/gridTeams"
// import UserList from "./Components/userList/userList"


//Our Components
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";


//Utils
import { DisplayTeam, displayConectivityScoreAndRank, displayAwards, EditButton, AddButton, DeleteButton, SummaryButton, RadarButton } from "../../shared/utils/generalUtils";
import { invokeCreateTeam, invokeUpdateTeam, invokeDeleteTeam, DisplayUserSelectComponent, getTeamInitials } from "../../shared/utils/userAndTeamUtils";
import { displayTeams } from "../../shared/utils/userAndTeamUtils";


//Icons
import {MailOutline, Add, Edit, DeleteOutline, PermIdentity, PhoneAndroid, Group} from '@material-ui/icons';
import RadarIcon from '@mui/icons-material/Radar';
import AssignmentIcon from '@mui/icons-material/Assignment';


//Here's the start of our Javascrpt App
const AdminTeamsPage = () => {
  
    //Context
    const { setShowSpinner } = useContext(DisplayContext); 
    const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
    const { authState, currentUser, isSuperAdmin, isAdmin,  permissionAnalytics } = useContext(AuthContext);    
    const { users, teams, customerOptions, usersOptions, selectedCustomerOptions, setSelectedCustomerOptions  } = useContext(CustomerContext);       
    const {  analyticsGenerated,  userAnalytics, teamAnalytics, customerAnalytics,
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData, computeRadarChartDataTeam,
    } = useContext(AnalyticsContext);
   
    //define variables to hold form state; initialize to empty
    const teamInitialState = {name: "", nickname: "", department: "", customer: {name: ""}, customerID:"", };

    // const [teamDetails, setTeamDetails] = useState(teamInitialState);
    
    //variable for holding on this page data for cusomters 
    // const [teams, setTeams] = useState([]);
    // const [users, setUsers] = useState([]);
    const [isUpdatingTeam, setIsUpdatingTeam] = useState(false);  //Used as a state variable set to TRUE when updating the backend
    // const [teamToDelete, setTeamToDelete] = useState(teamInitialState);         //Used to hold a team that is to be deleted
    const [teamToEdit, setTeamToEdit] = useState(teamInitialState);              //Used to hold a team that is to be edited
    const [teamRows,setTeamRows]=useState([]);                                    //Formatted rows for user grid
    // const [customerDropDownSelection, setCustomerDropDownSelection] = useState("");  //Note, this stores an object of the select Option.  See react-select
    const [userRows,setUserRows]=useState([]);                                    //Formatted rows for user grid

   //variable to control confirmation Modal if team presses DELETE; default to hidden
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalSummary, setShowModalSummary] = useState(false);      
    const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);     //for multi-select

    const teamSummaryDivRef = useRef();

 

    //Summary Modal state data
    const [teamToSummarize, setTeamToSummarize] = useState(teamAnalyticsInitialState);                
    const [userRowsSummary,setUserRowsSummary] = useState([]);


    //Define User Grid for Team Summary
    const userColumnsSummary = [
      {
        field: 'name',
        headerName: 'NAME',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        flex:1,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
      },
      {
        field: 'relativeConectivityScore',
        headerName: 'SCORE',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
        renderCell: (params)=> {
            return (
                <span> 
                <center>{params.row.relativeConectivityScore.toFixed(1)}</center>
                </span>
                )
        }

      },
      {
        field: 'teamRank',
        headerName: 'RANK',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
      },
      {
        field: 'conectivitiesCompleted',
        headerName: 'CONECTIVITIES',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
      },
      {
        field: 'coinsAwarded',
        headerName: 'ACHIEVEMENTS',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
        renderCell: (params)=> {
            return (
                    <center>
                        <div className="ContainerNoHeight">
                            <div className= {params.row.coinsAwarded<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
        
                                {params.row.coinsAwarded<0 ? "" : "+"} 
                                {params.row.coinsAwarded}  
                                <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
                            </div>
                            
                            {!params.row.badgesDEIAwarded || params.row.badgesDEIAwarded ==0 ? "" :
                                <div className= {params.row.badgesDEIAwarded<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
                                    {params.row.badgesDEIAwarded<0 ? "" : "+"} 
                                    {params.row.badgesDEIAwarded}  
                                    <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
                                </div>
                            }
                            {!params.row.badgesCSAwarded || params.row.badgesCSAwarded ==0 ? "" :
                                <div className= {params.row.badgesCSAwarded<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
                                    {params.row.badgesCSAwarded<0 ? "" : "+"} 
                                    {params.row.badgesCSAwarded}  
                                    <img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
                                </div>
                            }
                        </div>

                    </center>
                )
        }
      },
      {
        field: 'teams',
        headerName: 'TEAMS',
        headerAlign: 'center',
        align:'center',
        sortable: true,
         // resizable: true,
        minWidth: 250,
        color:'white',
        disableColumnMenu: true,

        },

    ];    
    
    
    //Define Team Grid Format
    //Note - resizable appears to be available only in Data Grid Pro
    
    const teamColumnsAdmin = [
      {
        field: 'customer',
        headerName: 'COMPANY',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        color:'white',
        filterable:true,
        sortable:true,
        editable: false,
        disableColumnMenu: false,
        hide: true,
      },
    {
        field: 'name',
        headerName: 'TEAM',
        headerAlign: 'center',
        align:'center',
        minWidth: 165,
        flex:1,
         // resizable: true,
        color:'white',
        filterable:true,
        disableColumnMenu: false,
        sortable:true,
        renderCell: (params)=> {
            return <DisplayTeam name={params.row.name} avatarUrl={params.row.avatarUrl} initials={params.row.initials} />;
        }
      },
    {
        field: 'nickname',
        headerName: 'NICKNAME',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        filterable:true,
        editable: false,
        disableColumnMenu: true,
        hide: true,
      },
      {
        field: 'department',
        headerName: 'DEPARTMENT',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        color:'white',
        filterable:true,
        editable: false,
        disableColumnMenu: true,
        hide: true,
      },
      {
        field: 'memberCount',
        headerName: 'MEMBERS',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        filterable:true,
        disableColumnMenu: true,
        color:'white',
        renderCell: (params)=> {
            return (
                    <div className="ContainerNoHeightSpaceAround" style={{width:"100%",  fontSize:"16px", fontWeight:"500"}}>
                        <div> {params.row.memberCount} </div>
                    </div>
            );
        }
 
      },      
    //   {
    //     field: 'relativeConectivityScore',
    //     headerName: 'SCORE',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 125,
    //     resizable: true,
    //     filterable:true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //     disableColumnMenu: true,
    //     renderCell: (params)=> {
    //         return (
    //             <span> 
    //             <center>{params.row.relativeConectivityScore.toFixed(1)}</center>
    //             </span>
    //             )
    //     }

    //   },
    //   {
    //     field: 'teamRank',
    //     headerName: 'Rank',
    //     headerAlign: 'center',
    //     filterable:false,
    //     align:'center',
    //     minWidth: 125,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //   },
      {
        field: 'teamRank',
        headerName: 'CONECTIVITY',
        sortable: true,
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
        filterable:true,
        renderCell: (params)=> {
            return displayConectivityScoreAndRank(params.row.relativeConectivityScore, params.row.teamRank);
       }

      },
      {
        field: 'conectivitiesCompleted',
        headerName: 'TEAM CONECTIVITIES',
        headerAlign: 'center',
        align:'center',
        minWidth: 210,
         // resizable: true,
        disableColumnMenu: true,
        filterable:true,
        color:'white',
        renderCell: (params)=> {
            return (
                    <div className="ContainerNoHeightSpaceAround" style={{width:"100%",  fontWeight:"500"}}>
                        <div> {params.row.conectivitiesCompleted} </div>
                    </div>
            );
        }
      },
      {
        field: 'coinsAwarded',
        headerName: 'AWARDS',
        headerAlign: 'center',
        align:'center',
        minWidth: 250,
         // resizable: true,
        color:'white',
        filterable:true,
        disableColumnMenu: true,
        renderCell: (params)=> {
            return displayAwards({coins:params.row.coinsAwarded, badgesDEI:params.row.badgesDEIAwarded, badgesCS:params.row.badgesCSAwarded});
        }
      },
    {
        field: 'badgesDEIAwarded',
        headerName: 'DEI',
        width: 105,
        editable: false,
        color:'white',
        hide:true,
        filterable:true,
        disableColumnMenu: true,
         renderCell: (params)=> {
            return (
                    
                    <div>{!params.row.badgesDEIAwarded ? "" :
                        <div>
                            {params.row.badgesDEIAwarded} 
                            <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
                        </div>
                        }
                    </div>
                )
        }
        
      },
      {
        field: 'badgesCSAwarded',
        headerName: 'CS',
        width: 105,
        editable: false,
        color:'white',
        hide:true,
        filterable:true,
        disableColumnMenu: true,
         renderCell: (params)=> {
            return (
                    
                <div>{!params.row.badgesCSAwarded ? "" :
                    <div>
                        {params.row.badgesCSAwarded} 
                        <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
                    </div>
                    }
                </div>
            )
         }
      },
     {   
        field: 'action',
        headerName: '',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
        flex:0.1,
         // resizable: true,
        color:'white',
        sortable: false,
        filterable:false,
        disableColumnMenu: false,
        disableExport: true,         
        renderCell: (params)=> {
               if (!teams || teams.length === 0) return null;
               if (isAdmin || isSuperAdmin) return (
                    <div className="dataGridActionButtonRow" >
                        <RadarButton onClickCallback={computeRadarChartDataTeam} onClickCallbackParam={params.row.dynamoDBID} />
                        <SummaryButton onClickCallback={handleSummary} onClickCallbackParam={params.row.dynamoDBID} />
                        {params.row.name === "ALL EMPLOYEES" ? null : 
                            <>
                                <EditButton onClickCallback={handleEdit} onClickCallbackParam={{teamToEdit:teams[params.row.id-1], deleteFlag:false}} />
                                <DeleteButton onClickCallback={handleEdit} onClickCallbackParam={{teamToEdit:teams[params.row.id-1], deleteFlag:true}} />
                            </>
                        }
                    </div>
                );
            else return (
                <div className="dataGridActionButtonRow" >
                        <RadarButton onClickCallback={computeRadarChartDataTeam} onClickCallbackParam={params.row.dynamoDBID} />
                        <SummaryButton onClickCallback={handleSummary} onClickCallbackParam={params.row.dynamoDBID} />
                </div>
                );

        }
        },
        
    ];
 
 /*   
    const teamColumnsSuperAdmin = [
      {
        field: 'customer',
        headerName: 'Customer',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
      },
    {
        field: 'name',
        headerName: 'Team',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
        filterable:false,
      },
    {
        field: 'nickname',
        headerName: 'Nickname',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
      },
      {
        field: 'department',
        headerName: 'Department',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
      },
      {
        field: 'memberCount',
        headerName: 'Members',
        headerAlign: 'center',
        align:'center',
        minWidth: 125,
        resizable: true,
        filterable:false,
        background:COLOR_BLUE_HEADER,
        color:'white',
      },      
      {
        field: 'relativeConectivityScore',
        headerName: 'Score',
        headerAlign: 'center',
        align:'center',
        minWidth: 125,
        resizable: true,
        filterable:false,
        background:COLOR_BLUE_HEADER,
        color:'white',
        renderCell: (params)=> {
            return (
                <span> 
                <center>{params.row.relativeConectivityScore.toFixed(1)}</center>
                </span>
                )
        }

      },
      {
        field: 'teamRank',
        headerName: 'Rank',
        headerAlign: 'center',
        filterable:false,
        align:'center',
        minWidth: 125,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
      },
      {
        field: 'conectivitiesCompleted',
        headerName: 'Conectivities',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
      },
      {
        field: 'coinsAwarded',
        headerName: 'Earnings',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        resizable: true,
        filterable:false,
        background:COLOR_BLUE_HEADER,
        color:'white',
        renderCell: (params)=> {
            return (
                    <center><div>{params.row.coinsAwarded}
                        <img className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
                    </div></center>
                )
        }
      },
    {
        field: 'badgesDEIAwarded',
        headerName: 'DEI',
        width: 100,
        filterable:false,
        editable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
         renderCell: (params)=> {
            return (
                    
                    <div>{!params.row.badgesDEIAwarded ? "" :
                        <div>
                            {params.row.badgesDEIAwarded} 
                            <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
                        </div>
                        }
                    </div>
                )
        }
        
      },
      {
        field: 'badgesCSAwarded',
        headerName: 'CS',
        filterable:false,
        width: 100,
        editable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
         renderCell: (params)=> {
            return (
                    
                <div>{!params.row.badgesCSAwarded ? "" :
                    <div>
                        {params.row.badgesCSAwarded} 
                        <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
                    </div>
                    }
                </div>
            )
         }
      },
      {   
        field: 'action',
        headerName: 'Action',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
        resizable: true,
        background:COLOR_BLUE_HEADER,
        color:'white',
        sortable: false,
        filterable:false,
        renderCell: (params)=> {
            return (
                <div className="ContainerMaxHeightSpaceBetween">
                    <div className="menuDataGridListButton" >
                        <Button variant="light" size="sm" >
                            <RadarIcon className="radarButton" onClick={()=>computeRadarChartData(params.row.dynamoDBID)}/>
                        </Button>
                    </div>
                    <div className="menuDataGridListButton" >
                        <Button variant="light" size="sm" >
                            <AssignmentIcon className="summaryButton" onClick={()=>handleSummary(params.row.dynamoDBID)}/>
                        </Button>
                    </div>
                    <div className="menuDataGridListButton">
                            <Button variant="light" size="sm" >
                                <Edit className="editButton" onClick={()=>handleEdit(teams[params.row.id-1])}/>
                            </Button>
                    </div>
                    <div className="menuDataGridListButton">
                            <Button variant="light" size="sm" >
                                <DeleteOutline className="deleteButton" onClick={()=>handleDelete(teams[params.row.id-1])}/> 
                            </Button>
                    </div>
                </div>
                )
        }
        },
    ];
    */
        

    // Handle updates to context
    useEffect(() => {

        //wait for users, teams, transactions and corresponding analytics to be loaded by context
        if (authState !== "signedin" || !analyticsGenerated) return;
 
        //Rebuild rows for Data Grid using updated Context
        buildTeamRows(teams, teamAnalytics);
    }, [authState, analyticsGenerated, teams, teamAnalytics]);
 
 

    function buildTeamRows(tempTeams, tempTeamNumbers) {
        
           //Build rows for Data Grid
            var tempTeamRows = [];
            
            for(var i = 0; i<tempTeams.length; i++){
                
                //Find matching Teams STATS object for this team
                var tempCurrentTeamStats = tempTeamNumbers.find(team => team.teamID === tempTeams[i].id);  // Grab match for DynamoDB ID

                if (tempCurrentTeamStats == undefined) tempCurrentTeamStats = teamAnalyticsInitialState; 
                    
            //   if (DEBUG_MODE >= 2) console.log("Building Row - Found matching team stats object for user", tempCurrentTeamStats, tempTeams[i]);
              
                tempTeamRows[i] = {
                    id:i+1,
                    dynamoDBID:tempTeams[i].id,
                    name:tempTeams[i].name,
                    initials:getTeamInitials(tempTeams[i]),
                    avatarUrl:tempTeams[i].avatarUrl,
                    nickname:tempTeams[i].nickname,
                    department:tempTeams[i].department,
                    relativeConectivityScore: tempCurrentTeamStats.relativeConectivityScore,
                    coinsAwarded: tempCurrentTeamStats.coinsAwarded,
                    conectivitiesCompleted: tempCurrentTeamStats.conectivitiesCompleted,
                    badgesCSAwarded: tempCurrentTeamStats.badgesCSAwarded,
                    badgesDEIAwarded: tempCurrentTeamStats.badgesDEIAwarded,
                    teamRank:tempCurrentTeamStats.teamRank,  
                    memberCount:(tempTeams[i].users.items ? tempTeams[i].users.items.length : 0),
                };
            }

        // if (DEBUG_MODE >= 2) console.log("Built Team Rows:", tempTeamRows);
        setTeamRows(tempTeamRows);
    }
    
    const handleAdd = () => {

        //Reset Customer Selection for SuperAdmin
        // if (isSuperAdmin) {
        //     setSelectedCustomerOptions(null); //Initialize to no customer selected
        // }

        setSelectedUsersOptions([]);    //Clear
        setIsUpdatingTeam(true);       //disable buttons on main page
        setShowModalAdd(true); //pop-up Modal 

    };


    const handleCancelTeamSummary = () => {
        setShowModalSummary(false);
        setIsUpdatingTeam(false); //disable buttons on main page
    };
        
    //Edit Functions 
    async function handleConfirmationEdit (e) {
        e.preventDefault(); //necesssary to prevent React page refresh on Submit, which interferes with API call
        
       //Required fields for teams
        if (!teamToEdit.name || (teamToEdit.name === "ALL EMPLOYEES" && !isSuperAdmin)) {
            if (DEBUG_MODE >= 2) console.log('error creating team, incomplete fields');
            setIsUpdatingTeam(false); //enable buttons on main page
            setModalGeneralMessage("Could not edit the team account.");
            setModalGeneralTitle("Ooops!");
            setShowModalGeneral(true);   
            return;
        }
        
        try {

            //just a double check - cannot edit the ALL EMPLOYEES team
            if (isSuperAdmin || teamToEdit.name !== "ALL EMPLOYEES") {
                setShowSpinner(true); //Show spinners
                const success = await invokeUpdateTeam({
                    cmdIssuerID:currentUser.id,         //ID of user creating the team
                    teamInputData: teamToEdit,          //data object of user to create
                    selectedUsersOptions:selectedUsersOptions,
                });             
                
               //Team updated?
                if (success && success.successFlag) {
                    setModalGeneralMessage("Saved updated team");
                    setModalGeneralTitle("Success!");
                } else {
                   setModalGeneralMessage("Could not edit user.");
                   setModalGeneralTitle("Ooops!");
                }   
            }
            
        } catch (err) {
           if (DEBUG_MODE >= 2) console.log('error editing team:', err);
           setModalGeneralMessage("Could not edit team.  ", err);
           setModalGeneralTitle("Ooops!");
            
        }
        
        // setSelectedCustomerOptions(null);
        setShowModalEdit(false);   //hide the pop-up
        setTeamToEdit(teamInitialState);
        setIsUpdatingTeam(false); //enable buttons on main page
        setShowSpinner(false); //Hide spinners
        setShowModalGeneral(true);   
    }
        
    const handleCancelUserSummary = () => {
        setShowModalSummary(false);
        setIsUpdatingTeam(false); //enable buttons on main page
    };
        
    const handleSummary = (id) => {


        setIsUpdatingTeam(true); //disable buttons on main page

        if (DEBUG_MODE >= 2) console.log("Displaying summary for TEAM having DynamoDB ID:", id);

        //Compute radar data for Modal for this team
        computeRadarChartData(id, true);

        //Get analytics for this team from numbers table based on ID since position in team array may have been sorted and not 1-to-1 correspondence between arrays
        const tempTeamToSummarize = teamAnalytics.find(team => team.teamID === id);
        
        if (tempTeamToSummarize == undefined) return;
        
        if (DEBUG_MODE >= 2) console.log("Displaying summary for team ", tempTeamToSummarize);

        setTeamToSummarize(tempTeamToSummarize);

        //Build user rows for a team-specific summary
        const thisTeamsUsers = users.filter(user => user.teams.items.some (join => join.teamID === id));

        if (DEBUG_MODE >= 2) console.log("setting user rows for team summary",id, thisTeamsUsers);
        
         var tempUserRows = [];
        
        for(var i = 0; i<thisTeamsUsers.length; i++){
            
            //Find matching STATS object
            const tempCurrentUser = userAnalytics.filter(user => user.userID === thisTeamsUsers[i].id);  // Grab match for DynamoDB ID
            // if (DEBUG_MODE >= 2) console.log("Found matching user stats object for user", tempCurrentUser[0], tempUsers[i]);

            //Set user values for summary
            tempUserRows[i] = {
            id:i+1,
            dynamoDBID:thisTeamsUsers[i].id,
            name:thisTeamsUsers[i].firstName + (!thisTeamsUsers[i].middleName ? "" : " " + thisTeamsUsers[i].middleName.substr(0,1)) + " " + thisTeamsUsers[i].lastName,
            title:thisTeamsUsers[i].title,
            email: thisTeamsUsers[i].email,
            teams: displayTeams(thisTeamsUsers[i]),
            relativeConectivityScore: tempCurrentUser[0].relativeConectivityScore,
            coinsAwarded: tempCurrentUser[0].coinsAwarded,
            conectivitiesCompleted: tempCurrentUser[0].conectivitiesCompleted,
            badgesCSAwarded: tempCurrentUser[0].badgesCSAwarded,
            badgesDEIAwarded: tempCurrentUser[0].badgesDEIAwarded,
            teamRank:tempCurrentUser[0].teamRank,
            };

        }
        
        if (DEBUG_MODE >= 2) console.log("Built user rows for team summary:", tempUserRows);
        setUserRowsSummary(tempUserRows);

        setShowModalSummary(true);

    };
        
    //Common cancel function
    const handleCancelEdit = () => {
            setShowModalDelete(false);
            setShowModalEdit(false);
            setShowModalAdd(false);
            setTeamToEdit(teamInitialState);
            setIsUpdatingTeam(false); //enable buttons on main page
            if (DEBUG_MODE >= 2) console.log("Cancelled Team Add/Edit/Delete.");
        };
        
    //This function handles the EDIT buttons
    //Pop-up a modal and ask team to confirm. Handle confirmation
    const handleEdit = ({teamToEdit, deleteFlag}) => {

        if (DEBUG_MODE >= 2) console.log("Edit button pressed.  Edit Team=", teamToEdit);

         //Pre-load Customer Selection based on the USER being edited if Super Admin viewing ALL customers
        if (isSuperAdmin && selectedCustomerOptions == null) {
            const indexOfCustomer = customerOptions.findIndex ( option => option.id == teamToEdit.customerID);
            
            if (indexOfCustomer > -1) {
                setSelectedCustomerOptions(customerOptions[indexOfCustomer]);  //Found a match so set the state variable to this option
            } else {
                if (DEBUG_MODE >= 2) console.log("ERROR - NO MATCHING CUSTOMER FOR THIS USER");         
            }
        }

       //Check if the TEAM has one or more USERS and, if so, build the selectedOptions in the dropdown, i.e., the current value
        var tempSetSelectedUsersOptions = [];
        
        if (teamToEdit.users && teamToEdit.users.items) {
            //For each user on the Team
            for (var j = 0; j < teamToEdit.users.items.length; j++){  
                if (DEBUG_MODE >= 3) console.log("Pre-loading USER from teamToEdit.users.items", teamToEdit.users.items[j], usersOptions);
    
                 //Look in the array of USER Options to find the dropdown option for the user on this team
                const userOption = usersOptions.find(userOption => userOption.id === teamToEdit.users.items[j].userID);
                tempSetSelectedUsersOptions.push(userOption);  //Add team to inital selections 
                 if (DEBUG_MODE >= 3) console.log ("Pushed user onto selections:", userOption);
            }
        }
        setSelectedUsersOptions (tempSetSelectedUsersOptions);  //Update state data for multi-select
        setIsUpdatingTeam(true); //disable buttons on main page
        setTeamToEdit(teamToEdit); //Store the passed in object into state data variable to record object being edit
        if (deleteFlag) setShowModalDelete(true); else setShowModalEdit(true); //pop-up Modal
    };
   
   
    
    ///Delete Functions
    //Note need to only allow a Team delete if users are removed from the team first or remove them here
    async function handleConfirmationDelete () {

        //Safety check
        if (!teamToEdit || !teamToEdit.id) {
            if (DEBUG_MODE >= 1) console.log('Error - no team to delete', teamToEdit);
            
        }
        if (DEBUG_MODE >= 2) console.log("Processing DELETE confirmation", teamToEdit);
        setShowSpinner(true); //Show spinners
        
        try {
            const successFlag = await invokeDeleteTeam({cmdIssuerID:currentUser.id, teamIDToDelete:teamToEdit.id, customerID:currentUser.customerID});
            if (DEBUG_MODE >= 2) console.log('Successfully deleted team', teamToEdit);
            if (successFlag) {
                setModalGeneralMessage("Team deleted");
                setModalGeneralTitle("Success!");
            } else {
               setModalGeneralMessage("Could not delete team");
               setModalGeneralTitle("Ooops!");
            }
             
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error deleting team:', teamToEdit, err);
            setModalGeneralMessage("Could not delete team.  ", err);
            setModalGeneralTitle("Ooops!");
        }
        
        // setSelectedCustomerOptions(null);
        setShowModalDelete(false);   //hide the pop-up
        setTeamToEdit(teamInitialState);
        setIsUpdatingTeam(false); //enable buttons on main page
        setShowSpinner(false); //Hide spinners
        setShowModalGeneral(true);   
    }
        
        
/*        
    //handle cancel in delete modal
    const handleCancelDelete = () => {
            // setSelectedCustomerOptions(null);
            setShowModalDelete(false);
            setTeamToDelete(teamInitialState);
            setIsUpdatingTeam(false); //enable buttons on main page
            if (DEBUG_MODE >= 2) console.log("Cancelled Team Delete.");
        };

       
    //This function handles the delete buttons from list of items
    //Pop-up a modal and ask team to confirm. Handle confirmation
    const handleDelete = (teamToDelete) => {
        
       //Set Customer Selection
        
        const indexOfCustomer = customerOptions.findIndex ( option => option.id == teamToDelete.customerID);
        
        if (indexOfCustomer > -1) {
            setSelectedCustomerOptions(customerOptions[indexOfCustomer]);  //Found a match so set the state variable to this option
        } else {
            if (DEBUG_MODE >= 2) console.log("ERROR - NO MATCHING CUSTOMER FOR THIS USER");         
        }
  
        setIsUpdatingTeam(true); //disable buttons on main page
        
        if (DEBUG_MODE >= 2) console.log("DELETE button pressed.  Delete Team=", teamToDelete);
        
        setTeamToDelete(teamToDelete);
        setShowModalDelete(true); //pop-up Modal
        
    };
*/   
  
    //define function to handle submitButton, which is used to create a new team
    async function handleSubmit (e)  {
        e.preventDefault(); //necesssary to prevent React page refresh on Submit, which interferes with API call
        setIsUpdatingTeam(true); //disable buttons on main page
        if (DEBUG_MODE >= 2) console.log('Called handler to create new team - top', teamToEdit);

        var tempCustomerID ="";
        
        //If not a Super Admin then set the company to this team's company
        if (!isSuperAdmin) {
            tempCustomerID = currentUser.customerID;
        } else {
            tempCustomerID = selectedCustomerOptions.id;
            if (DEBUG_MODE >= 2) console.log("ID of customer selected in dropdown", tempCustomerID);
        }
       
        //Required fields for teams
        if (!teamToEdit.name ) {
            if (DEBUG_MODE >= 2) console.log('error creating team, incomplete fields');
            setIsUpdatingTeam(false); //enable buttons on main page
            setModalGeneralMessage("Could not create the team account.  Please complete team name.");
            setModalGeneralTitle("Ooops!  Incomplete information.");
            setShowModalGeneral(true);   
            return;
        }

        //OK, we are now ready to create the team record
        setShowSpinner(true); //Show spinners
        let success;
        try {
            
            success = await invokeCreateTeam({
                cmdIssuerID:currentUser.id,         //ID of user creating the team
                customerID:tempCustomerID,          //ID of customer in which to create the team
                teamInputData: teamToEdit,          //data object of user to create
                selectedUsersOptions:selectedUsersOptions,
            });            
                
            if (success && success.successFlag) {
                setModalGeneralMessage("Team created");
                setModalGeneralTitle("Success!");
            } else {
                if (DEBUG_MODE >= 2) console.log("Error creating user");
                setModalGeneralMessage((success.error ? success.error : "Could not create the user account")); //Show specific error, if there is one
                setModalGeneralTitle("Ooops!");
            }

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error creating team:', err);
            setModalGeneralMessage("Could not create the team account. ", err);
            setModalGeneralTitle("Ooops!");
        }
        
        // setSelectedCustomerOptions(null);
        setShowModalAdd(false);
        setTeamToEdit(teamInitialState);
        setIsUpdatingTeam(false); //enable buttons on main page
        setShowSpinner(false);   //Hide spinners
        setShowModalGeneral(true);   
    }
  
  
 
    //   const handleCustomerSelect = async (eventKey) => {

    //     if (DEBUG_MODE >= 2) console.log("Customer selected", eventKey);
    //     setCustomerDropDownSelection(eventKey);

    //  };

    //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) {
    //   if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 
  
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">TEAMS AND GROUPS</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else return (
        <Authenticator>
      
        <ModalNoBackground showModal={showModalAdd || showModalDelete || showModalEdit} closeCallback={handleCancelEdit} >
            <div className="modalNoBkgInnerCard" >
                <div  className="modalNoBkgImage ccImgXXXXLSquare ccImagePadding" style={{borderColor: TEAM_COLOR}}> 
                    <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt='' />  
                </div>

                <h3>
                        {!showModalAdd ? "" : <div>ADD TEAM</div>}
                        {!showModalEdit ? "" : <div>EDIT TEAM</div>}
                        {!showModalDelete ? "" : <div>DELETE TEAM?</div>}
                </h3>
            
             <div className="modalNoBkgCenterColumn fullWidth wrap" style={{padding:"clamp(10px,2vw,20px) clamp(6px,1vw,10px)"}} onClick = {(e) =>  e.stopPropagation()}>
                     <div className="ContainerNoHeightCenter fullWidth wrap"  >
                        <div className="inputFormVerticalLeftInputField TextStyle5">
                            <div className="inputFormVerticalLeftInputField TextStyle5">
                                    <div>Team Name</div>
                                        <input
                                            name="team name"
                                            type="text"
                                            size="35"
                                            value={teamToEdit.name}
                                            onChange={event => setTeamToEdit({ ...teamToEdit, name: event.target.value })}
                                            disabled={showModalDelete}
                                            required
                                        />
                                </div>
                        </div>
                        <div className="inputFormVerticalLeftInputField TextStyle5">
                            <div className="inputFormVerticalLeftInputField TextStyle5">
                                    <div>Nickname</div>
                               <input
                                    name="nickname"
                                    size="35"
                                    type="text"
                                     value={teamToEdit.nickname}
                                    onChange={event => setTeamToEdit({ ...teamToEdit, nickname: event.target.value })}
                                    disabled={showModalDelete}
                                    required
                                />
                            </div>
                        </div>
                     </div>
                     <div className="ContainerNoHeightCenter fullWidth wrap" style={{zIndex:"2"}} >
                        <div className="inputFormVerticalLeftInputField TextStyle5">
                            <div className="inputFormVerticalLeftInputField TextStyle5">
                                <div>Department</div>
                                <input
                                    name="department"
                                    size="35"
                                    type="text"
                                     value={teamToEdit.department}
                                    onChange={event => setTeamToEdit({ ...teamToEdit, department: event.target.value })}
                                    disabled={showModalDelete}
                                    required
                                />
                            </div>
                        </div>
                       {!isSuperAdmin  ? "" :                        
                          <div className="inputFormVerticalLeftInputField TextStyle5">
                                <div className="inputFormVerticalLeftInputField TextStyle5">
                                    <div>Customer</div>
                                   <div style={{fontSize:"18px", color:"#6495ED", width:"clamp(150px,30vw,300px"}}>
                                        <Select 
                                            isDisabled={showModalEdit || showModalDelete}
                                            isMulti={false} 
                                            name="customerDropdown" 
                                            options={customerOptions} 
                                            onChange={setSelectedCustomerOptions} 
                                            value={selectedCustomerOptions} 
                                            placeholder=" -- select customer --"                            
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                  <div className="ContainerNoHeightCenter fullWidth wrap" style={{ zIndex:"1"}} >
                   
                      <div className="inputFormVerticalLeftInputField TextStyle5">
                            <div className="inputFormVerticalLeftInputField fullWidth" style={{maxWidth:"800px"}}>
                                <div>Members</div>
                                 <DisplayUserSelectComponent name="userDropdown" usersOptions={usersOptions} handleSelectUser={setSelectedUsersOptions} isMulti={true} selectedUsersOptions={selectedUsersOptions} disabled={showModalDelete || (!isSuperAdmin && teamToEdit?.name === "ALL EMPLOYEES")} placeholder='-- select users --' />
                            </div>
                        </div>
                    </div> 
                    <div className="modalNoBkgFooter" style={{paddingTop:"clamp(8px,1.5vw,20px)"}}>
                        <div className="ContainerNoHeight">
                          {!showModalAdd && !showModalEdit ? null :  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="save" onClick={showModalAdd ? handleSubmit : handleConfirmationEdit} disabled={!teamToEdit.name || (selectedCustomerOptions==null)} > Save </button> }
                          {!showModalDelete ? null : <button className="buttonStyle1 buttonStyle1Red" aria-label="delete" onClick={handleConfirmationDelete}>  <DeleteOutline />    </button>}
                          <button className="buttonStyle1 buttonStyle1DavyGray" onClick={handleCancelEdit}> Cancel  </button>
                      </div>
                    </div>
               </div>
           </div>
        </ModalNoBackground>

        <Modal show={showModalSummary} onHide={handleCancelUserSummary} dialogClassName="adminTeamSummaryModal">
            <Modal.Header closeButton>
              <Modal.Title>Team Conectivity Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="dashboardCardRow">
                    <CCdisplayCardTeamStatsModal analytics={teamToSummarize}/>
                    <div>
                        <CCRadarChart 
                          data={radarChartData} title={!radarChartTargetName ? "Conectivity Visualization" :  radarChartTargetName + " Conectivity"}  
                          dataKeyAngle="subject" 
                          radarName1={radarChartNames.length < 1 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 1 ? "" : radarChartColors[0]}
                          radarName2={radarChartNames.length < 2 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length <1 ? "" : radarChartColors[1]}
                        grid/>
                    </div>

                </div>

                <div className="dataGridWrapperTop" ref={teamSummaryDivRef}>        
                    <MenuDataGrid 
                        dataGridRows={userRowsSummary} 
                        maxWidth={(teamSummaryDivRef && teamSummaryDivRef.current) ? teamSummaryDivRef.current.clientWidth : ""} 
                        dataGridColumns={userColumnsSummary} 
                        pageSize={5}/>
                </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>
                <div > Close </div>
              </Button>
            </Modal.Footer>
         </Modal>   

             
            <div className="adminPage">
                <div className="dashboardContainer">
                    <div className="dashboardCardRow">
                        <CCdisplayCardTeamStats analytics={customerAnalytics}/>
                        <CCdisplayCardTeamNumbers teamAnalytics={teamAnalytics} />
                        <div>
                            <CCRadarChart 
                              data={radarChartData} title={!radarChartTargetName ? "Conectivity Visualization" :  radarChartTargetName + " Conectivity"}  
                              dataKeyAngle="subject" 
                              radarName1={radarChartNames.length < 1 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 1 ? "" : radarChartColors[0]}
                              radarName2={radarChartNames.length < 2 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length <1 ? "" : radarChartColors[1]}
                              targetName={""}
                            grid/>
                        </div>
                    </div>            
                    <div className="dashboardTitle">
                        TEAMS
                        <AddButton icon = {<Group />} onClickCallback={handleAdd} hoverText="Add Team" /> 
                    </div>     
                    <div className="dataGridWrapperTop">        
                        <MenuDataGrid 
                            dataGridRows={teamRows} 
                            dataGridColumns={teamColumnsAdmin }
                        />
                    </div>
                </div>  
            </div>
        </Authenticator>
  );
}




export default AdminTeamsPage;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021

//  ADMIN for managing spotlights, including spotlight analytics

import "./adminSpotlights.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, SPOTLIGHT_INITIAL_STATE, COLOR_BLUE_TEXT, BALANCE_COLOR, customerWideSpotlightAlyticsInitialState, SPOTLIGHT_TRANSACTION_TITLE_SENDER, SPOTLIGHT_TRANSACTION_TITLE_RECIPIENT} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries and Mutations

//Our components
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import DisplayCardSpotlightNumbers from "./Components/displayCardSpotlightNumbers/displayCardSpotlightNumbers";
import DisplayCardSpotlightStats from "./Components/displayCardSpotlightStats/displayCardSpotlightStats";
import { SpotlightSendTool } from "../../shared/Components/spotlightSendTool/spotlightSendTool";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //Spotlight Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { SpotlightContext } from '../../shared/context/spotlightContext';
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { formatDate, truncateStringWithDots, AddButton, EditButton, DeleteButton, SummaryButton, RadarButton } from "../../shared/utils/generalUtils";
import { invokeDeleteSpotlight } from "../../shared/utils/spotlightUtils.js";
import {  setHeaderColorByStringLength } from "../../shared/utils/conectivityUtils";

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Icons
import GroupsIcon from '@mui/icons-material/Groups';
import HighlightIcon from '@mui/icons-material/Highlight';


const AdminSpotlightsPage = () => {

   //variable for holding on this page data for cusomters 
	const [isUpdatingSpotlight, setIsUpdatingSpotlight] = useState(false);  //Used as a state variable set to TRUE when updating the backend
	const [spotlightToEdit, setSpotlightToEdit] = useState(SPOTLIGHT_INITIAL_STATE);              //Used to hold a spotlight that is to be edited
	const [spotlightEditMode, setSpotlightEditMode] = useState("")
	const [spotlightRows,setSpotlightRows]=useState([]);                                    //Formatted rows for spotlight grid
	const [recipientsToShow, setRecipientsToShow] = useState([]);
	const [showModalRecipients, setShowModalRecipients] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);


   // Context
	const { authState, currentUser, isSuperAdmin, isAdmin,  permissionAnalytics,  } = useContext(AuthContext);         
	const { analyticsGenerated, userAnalytics, customerAnalytics  } = useContext(AnalyticsContext);
	const { recognitions, setShowSendSpotlightModal } = useContext(SpotlightContext);
   const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

   // Main React useEffect for maintaining updated page data
	useEffect(() => {
		if (authState !== "signedin") return;

	   //wait for users, teams, transactions and corresponding analytics to be loaded by context
		if (!analyticsGenerated) return;
		setShowSpinner(true); //Show spinners
			   
		//Build rows for Data Grid
		buildSpotlightRows();
		setShowSpinner(false); //Hide spinners
	}, [authState, analyticsGenerated, recognitions]);



	//Define User Grid Format
	//Note - resizable appears to be available only in Data Grid Pro
	
	const spotlightColumns = [
	{
		field: 'dateSent',
		headerName: 'DATE',
		width: 165,
		editable: false,
		color:'white',
	},
	{
		field: 'name',
		headerName: 'SENDER',
		headerAlign: 'center',
		align:'center',
		minWidth: 250,        
		color:'white',
		sortable: true,
		renderCell: (params)=> {
		return (
			<div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}}>
				 <div style={{width:"60px", }}>
						<div className="ContainerNoHeightFlexLeft" style={{width:"100%"}} >
							{params.row.senderAvatarUrl =="" ? "" :
								<div className="avatarImage avatarImageMedium"> 
									<img src={params.row.senderAvatarUrl} className="avatarImageCenterPortrait"  alt=""   /> 
								</div>
							}
						</div>
				</div>
				<div style={{width:"180px", textAlign:"left"}}>
						<div style={{padding:'5px', fontSize:"12px", fontWeight:"400"}}>{params.row.senderAvatarName}</div>
				</div>
		   </div>
			);
		  },
	},
	{
		field: 'message',
		headerName: 'RECOGNITION',
		headerAlign: 'center',
		minWidth: 320,
		flex:1,
		editable: false,
		sortable: true,
		color:'white',
		renderCell: (params)=> {
		return (
			<div className="ContainerNoHeightCenter" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
				{truncateStringWithDots(params.row.message,130)}
			</div>
			);
		},
		},
		
		{
			field: 'recognizedEmployees',
			headerName: 'RECIPIENT(S)',
			headerAlign: 'center',
			align:'center',
			minWidth: 150,
			flex:0,
			// resizable: true,
			color:'white',
			sortable: false,
			renderCell: (params)=> {
			if (!params.row.recognizedEmployees) return null;
			if (params.row.recognizedEmployees.length == 0) return null;
			
			// if (DEBUG_MODE >= 2) console.log("GRID", params.row.recognizedEmployees)
			
			return (
				<div >
					<div className="ContainerMaxHeightCenter"  onClick={() => handleShowReciptients(params.row.recognizedEmployees)} style={{cursor:"pointer"}}>
						<div className="menuDataGridListButton" >
							  <GroupsIcon className="previewButton" style={{fontSize:'32px'}}/> 
						</div>
					   <div className="participantCountBadge">
							{params.row.recognizedEmployees.length}
						</div>
					</div>
				 </div>

				   
				);
			}
		},      
	  {
		field: 'amount',
		headerName: 'AWARDS',
		headerAlign: 'center',
		align:'center',
		minWidth: 250,
		 // resizable: true,
		color:'white',
		renderCell: (params)=> {
			return (
					<div className="ContainerNoHeightCenter" style={{width:"100%",  fontSize:"18px", fontWeight:"600"}}>
						<div>{params.row.amount}
							<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>
						</div>
						{!params.row.badgesDEI ? "" :
							<div style={{padding:"0px 5px"}}>
								{params.row.badgesDEI} 
								<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/>
							</div>
							}  
						{!params.row.badgesCS ? "" :
							<div  style={{padding:"0px 5px"}}>
								{params.row.badgesCS} 
								<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/>
							</div>
						}
					</div>
				);
		}
	  },   
	  {
		field: 'action',
		headerName: '',
		headerAlign: 'center',
		align:'center',
		minWidth: 200,
		 // resizable: true,
		color:'white',
		sortable: false,
		renderCell: (params)=> {

			if (isAdmin || isSuperAdmin) return (
				<div className="ContainerMaxHeightSpaceAroundFullWidth" >
						<EditButton onClickCallback={handleEdit} onClickCallbackParam={recognitions[params.row.id-1]} />
						<DeleteButton onClickCallback={handleDelete} onClickCallbackParam={recognitions[params.row.id-1]} />
				</div>
			);
			else return null;
		}
		},      
	];

	function buildSpotlightRows() {
		if (recognitions == null) return;
		if (recognitions.length == null) return;

		//Build rows for Data Grid
		var tempSpotlightRows = [];
		
		for (var i = 0; i<recognitions.length; i++){
			
			 //Set initial user values
			tempSpotlightRows[i] = {
				id:i+1,
				dynamoDBID:recognitions[i].id,
				dateSent: formatDate(recognitions[i].createdAt),
				message:recognitions[i].message,
				senderID: recognitions[i].senderID,                    
				senderAvatarUrl: recognitions[i].senderAvatarUrl,           
				senderAvatarName: recognitions[i].senderAvatarName,
				senderAvatarInitials: recognitions[i].senderAvatarInitials,
				amount: recognitions[i].amount,                    
				badgesDEI: recognitions[i].badgesDEI,                    
				badgesCS: recognitions[i].badgesCS,                    
				recognizedEmployees:(!recognitions[i].recognizedEmployees.items ? [] : recognitions[i].recognizedEmployees.items),

			};
		}
		
		// if (DEBUG_MODE >= 2) console.log("Built Spotlight Rows:", tempSpotlightRows);
		setSpotlightRows(tempSpotlightRows);
	}        

   // Pop up a modal for viewing members that participated in the Conectivity
	function handleShowReciptients (recipients) {
		//if (DEBUG_MODE >= 2) console.log("View Recipients Button pressed.", recipients);    
		setRecipientsToShow(recipients);
		setShowModalRecipients(true); //pop-up Modal
	}
	
 
	//Function for handling ADD / EDIT / DELETE Spotlight buttons
	const handleAdd = () => {
		setIsUpdatingSpotlight(true); //disable buttons on main page
		setShowSendSpotlightModal(true);  
		setSpotlightEditMode("ADD");      
		if (DEBUG_MODE >= 2) console.log("ADD Spotlight button pressed.");

	};
	
	
	const handleEdit = (recognitionToEdit) => {
		
		setIsUpdatingSpotlight(true); //disable buttons on main page
		setSpotlightEditMode("EDIT");      
		setShowSendSpotlightModal(true);      
		
		if (DEBUG_MODE >= 2) console.log("Edit button pressed.  Edit Recognition=", recognitionToEdit);

	   setSpotlightToEdit(recognitionToEdit);
		
	};

	const handleDelete = (recognitionToDelete) => {
		setIsUpdatingSpotlight(true); //disable buttons on main page
		setSpotlightToEdit({...recognitionToDelete});
		setShowModalDelete(true);         
		if (DEBUG_MODE >= 2) console.log("Delete button pressed.  Delete Recognition=", recognitionToDelete);

	};

	//Component for displaying recipients for a previously completed conectivity
	function displayRecipients () {

		if (recipientsToShow.length == 0 ) return null;
		
		 return (
				 <div >
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: 'blue'}} >
					
						{recipientsToShow.map((target, index) => ( 
							<div key={target.id} className="avatarContainer"> 
								<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.recognizedUserAvatarName)}}>
									{!target.avatarUrl  ? <div className="avatarInitialsSmall" > {target.recognizedUserAvatarInitials}</div> : <img className="avatarImageCenterPortrait" src={target.recognizedUserAvatarUrl} alt={target.recognizedUserAvatarInitials} /> }
								</div>
								<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.recognizedUserAvatarFirstName)}}>{target.recognizedUserAvatarName}</span>
							</div>
	
						))}
					  </div>
				  </div>
			);
	}  

	const handleCancel = () => {
		setIsUpdatingSpotlight(false);      //enable buttons on main page
		setShowSendSpotlightModal(false);
		setShowModalDelete(false);
		setShowModalRecipients(false);
		setRecipientsToShow ([]);            // if (DEBUG_MODE >= 2) console.log("Cancel button called")
	 };


	///Delete Functions
	//Note at some point this would be handled by backend to do cleanup on related records, like wallet and transactions
	async function handleConfirmationDelete () {
		if (DEBUG_MODE >= 2) console.log("Deleting Spotlight:", spotlightToEdit);
		setShowSpinner(true); //Show spinners
		const successFlag = await invokeDeleteSpotlight({spotlight:spotlightToEdit});
		if (DEBUG_MODE >= 2) console.log("Delete Spotlight returned:", successFlag);
		setShowSpinner(false); //Hode spinners
		setShowModalDelete(false);
		if (successFlag) {
			setModalGeneralMessage('Spotlight deleted');
			setModalGeneralTitle("Success!");
			setShowModalGeneral(true);   
		} else {
		   setModalGeneralMessage('Something went wrong and we could not delete the Spotlight');
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
		}        
	}
	
	//Handle access by unauthenticated user
	if (authState != "signedin"  || !currentUser) {
		//   if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState == "signedout") popUpLoginWindow();
	  return null;
	}
	
	//Render function for authenticated user 
	
	//confirm access to this page
	if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<h3 className="sectionHeader">SPOTLIGHT DASHBOARD</h3>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
	} else  return (
	<Authenticator>

		<Modal show={showModalRecipients} onHide={handleCancel}  centered >
			<Modal.Header closeButton>
			  <Modal.Title>RECOGNIZED EMPLOYEES</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				  <center> {displayRecipients()}</center>
			</Modal.Body>
		 </Modal>

		{/*
		 <Modal show={false && showModalDelete} onHide={handleCancel}  dialogClassName="defaultBootstrapModal">
			<Modal.Header closeButton>
			  <Modal.Title>DELETE SPOTLIGHT</Modal.Title>
			</Modal.Header>
			<Modal.Body>
					<div style={{padding:"10px 20px"}}>
						<SpotlightPreview key={spotlightToEdit.id} id={0} spotlightToView={spotlightToEdit}  isSmall={false} isFull={true}/> 
					</div>
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="primary" onClick={handleConfirmationDelete} disabled={false}>
					<div > Delete </div>
			  </Button>
			  <Button variant="secondary" onClick={handleCancel} disabled={false}>
				<div > Cancel </div>
			  </Button>
			</Modal.Footer>
		 </Modal>
		 */}
		 

		  <ModalNoBackground showModal={showModalDelete} closeCallback={handleCancel} cardColor={BALANCE_COLOR} >
		   <div className="modalNoBkgInnerCard" >
				<div className="modalNoBkgCenterColumn" >
					<h4 className='blue'>Delete Spotlight?</h4>
				</div>
				<div className="modalNoBkgFooter">
					<Button variant="primary" onClick={handleConfirmationDelete}  > DELETE </Button>
					<Button variant="secondary" onClick={handleCancel} > Cancel </Button>
				</div>
			</div>
		 </ModalNoBackground>         
 

		<div className="adminPage">
			<div className="dashboardContainer ">
				<div className="dashboardCardRow">
					<DisplayCardSpotlightStats analytics={customerAnalytics}/>
					<DisplayCardSpotlightNumbers userAnalytics={userAnalytics} showSent/>
					<DisplayCardSpotlightNumbers userAnalytics={userAnalytics}/>
				</div>
				<div className="dashboardTitle">
					SPOTLIGHTS
					<AddButton icon = {<HighlightIcon style={{transform: "translate(-5%,-5%) rotate(-145deg) "}}/>} onClickCallback={handleAdd} hoverText="Cast a Spotlight!" /> 
				</div>             
				<div className="dataGridWrapperTop" >        
					<MenuDataGrid 
						dataGridRows={spotlightRows} 
						dataGridColumns={spotlightColumns} 
					/>               
				</div>
			</div>
		</div>
	
	</Authenticator>
	
	);
};
	
export default AdminSpotlightsPage;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//


import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, HEADER_FULL_MODE, COLOR_GOLD, COLOR_BLUE_HEADER, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR, COLOR_WHITE,
			CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD, GIPHY_ID_IDENTIFIER  } from '../../data/conectereConfigData';

//Amplify, React
import React, { useCallback, useEffect, useState, useContext, useRef, useLayoutEffect} from 'react';

//Context
import { DisplayContext } from '../../context/displayContext';            
import { SpecialEventsContext } from '../../context/specialEventsContext';            

//Our Components
import { ChatWindow } from "../chatWindow/chatWindow";
import StarRatings  from "../../Components/starRatings/starRatings";
import ConectivityDurationTimer from '../conectivityDurationTimer/conectivityDurationTimer';
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import { LeaderBoardList } from "../../Components/leaderboard/leaderboard";
import { Invitations } from "../invitation/invitation";

//Utils
import { getMyInvitations,  } from "../../utils/invitationUtils";
import { SpecialEventsGraphic, setSpecialEventMultiplier, setConectivityImageUrlByConectivity, setHeaderColorByScheduledConectivity, 
	setBorderColorClassByCategory, setBackgroundColorClassByCategory, displayScheduledConectivityCardActionsRow, displayConectivityHeader, 
	displayScheduledConectivityCardInfoRow, displayConectivityCardHeader, scheduledConectivityActionsRow, setBorderColorClassByConnectivityCardCategory, 
	setBadgeByScheduledConectivity,
	SpecialEventsBadges} from "../../utils/conectivityUtils";
import { OpenButton, truncateStringWithDots, compareByCreatedAt } from "../../utils/generalUtils";

//MUI

//Main Scheduled Conectivity Card React component
//Scheduled Conectivity is a running connectivity object
export const ScheduledConectivityCard = React.memo(({
		index, scheduledConectivity, currentUser, 
		newInvitationCallback, editInvitationCallback, deleteInvitationCallback, mergeInvitationsCallback, declineInvitationCallback,
		handleDetailedView, handleShowMessages,  handleDidItClick, handleSkipClick, pendingUpdateSCID}) => {

// Context
const { darkMode } = useContext(DisplayContext); 
const { activeSpecialEvents } = useContext(SpecialEventsContext);

//Local React State Vars   
const conectivity = (scheduledConectivity ? scheduledConectivity.conectivity : null);
const headerColor = setHeaderColorByScheduledConectivity(scheduledConectivity);
const [closeAllSubMenusToggle, setCloseAllSubMenusToggle] = useState(false);        //Flip this state to cause all child invitations to CLOSE any open submenus
const [idOfSubMenutoDisplay, setIdOfSubMenutoDisplay] = useState(null);             //Since we want to control the sub-menus from the parent Conectivity, we need a call back state variable 
const [pendingInvitationUpdate, setPendingInvitationUpdate] = useState(false);                //Internal state var to indicate that this SC has invoked a callback that should result in a state change from the backend; used to show spinners or disable input temporarily
// const [clearPendingInvitationTrigger, setClearPendingInvitationTrigger] = useState(null); //Trigger to initiate timer when needed
const [isSpecialEvent, setIsSpecialEvent] = useState(false);
const [specialEventHoverText, setSpecialEventHoverText] = useState('');
const [specialEventGraphicText, setSpecialEventGraphicText] = useState('');
const badgeTextColor = setBadgeByScheduledConectivity(scheduledConectivity, true);
const badgeImage = setBadgeByScheduledConectivity(scheduledConectivity);

const zIndex = (index ? parseInt(9+index) : '9');

//Update selection of any Special Events graphic for this SC
useEffect(() => {
	//safety check
	if (!scheduledConectivity || !activeSpecialEvents || activeSpecialEvents.length === 0 || !conectivity) {
		setIsSpecialEvent(false); setSpecialEventHoverText('');setSpecialEventGraphicText('');
	}

	//Regardless, set our boolean if this conectivity is, by definition, a special event.
	let tempIsSpecialEvent = (conectivity.isSpecialEvent ? true : false);

	//Now, does this particular SC matches an active special event?
	let {specialEventMultipler, reason} = setSpecialEventMultiplier({ conectivity, activeSpecialEvents });
	if (specialEventMultipler && specialEventMultipler > 1) {
		let multiplierString = specialEventMultipler.toString() + 'X';
		if (DEBUG_MODE > 1) console.log("Set multiplier string", multiplierString);
		tempIsSpecialEvent = true; setSpecialEventHoverText(reason);setSpecialEventGraphicText(multiplierString);									
	} else {
		setSpecialEventHoverText('');setSpecialEventGraphicText('');
	}

	//Update our state
	setIsSpecialEvent(tempIsSpecialEvent);

}, [scheduledConectivity, activeSpecialEvents,]);

//Pending Invitation Update Timer - execute on any change to the pendingUpdateSCID
useEffect(() => {

let timer;
if (pendingUpdateSCID && pendingUpdateSCID === scheduledConectivity.id) {
	// if (DEBUG_MODE >= 2) console.log("SC setting pending update; starting pending update timer", pendingInvitationUpdate);
	setPendingInvitationUpdate(true); 
	timer = setTimeout(() => { 
		setPendingInvitationUpdate(false); 
		if (DEBUG_MODE >= 2) console.log("SC pending update timer FIRED", pendingInvitationUpdate);
	}, 5000); //Clear local spinners in 5 seconds if not already cleared
} else {
	setPendingInvitationUpdate(false); 
	// if (DEBUG_MODE >= 2) console.log("SC - clearing pending update", pendingInvitationUpdate);
}

// Clear timeout if the component is unmounted
return () => {if (timer) clearTimeout(timer)};
},[pendingUpdateSCID]);    

// Get an array of invitations for which the user is an INVITEE on this particular Scheduled Conectivity
const invitationsWithThisUser = getMyInvitations(scheduledConectivity, currentUser, true);   

// if (DEBUG_MODE >= 2) console.log("Displaying conectivity", scheduledConectivity, invitationsWithThisUser);

//Callback function to set the ID of a particular menu for display
function invitationCallbackSetIdOfSubMenutoDisplay(id) {
// if (DEBUG_MODE >= 2) console.log("Display Card Callback to setIdOfSubMenutoDisplay", id, scheduledConectivity.id);
setIdOfSubMenutoDisplay(id);          
}

//Function to close any open sub-menu up mouse leaving the card      
const handleMouseLeave = (e) => {
// if (DEBUG_MODE >= 2) console.log("Mouse leave",e);
// setCloseAllSubMenusToggle(!closeAllSubMenusToggle)
setIdOfSubMenutoDisplay("");  //Display NONE - Added 9/13
};        


//Safety Check
if (!currentUser || !scheduledConectivity || !scheduledConectivity.conectivity || !conectivity) return null;

const backgroundClass = setBackgroundColorClassByCategory(scheduledConectivity.conectivity.category.label);
const borderClass = setBorderColorClassByConnectivityCardCategory(scheduledConectivity.conectivity.category.label);

//RENDER SC COMPONENT 
return (
<div className="conectivityCardOuterWrapper connectivityCardMainWrapper" style ={{zIndex:zIndex}} onClick={(e) => {e.stopPropagation(); handleDetailedView(scheduledConectivity);}} >

	<SpecialEventsBadges badgeImage={badgeImage} isSpecialEvent={isSpecialEvent} specialEventGraphicText={specialEventGraphicText} specialEventHoverText={specialEventHoverText} textColor={badgeTextColor} />

    {/* <div className="conectivityCardHeaderOpenAnchor" style={{borderColor:headerColor}}> 
			<OpenButton className="TextStyle2" hoverText="View"  onClickCallback={handleDetailedView} onClickCallbackParam={scheduledConectivity} color={COLOR_WHITE} noIconButton={true}/> 
	</div> */}

    <div
      className={`conectivityCardRootWrapper ContainerVerticalSpaceBetween positionRelative  ${borderClass}`}
      onMouseLeave={handleMouseLeave}
      onClick={() => setCloseAllSubMenusToggle(!closeAllSubMenusToggle)}
    >
      <div className='fullWidth'>
        {displayConectivityCardHeader({
          scheduledConectivity,
          backgroundClass,
          darkMode,
          headerColor,
          invitationsWithThisUser,
          handleDetailedView,
          scheduledConectivity,
        })}

        {/* <Invitations
					currentUser={currentUser} scheduledConectivity={scheduledConectivity} invitationsWithThisUser={ invitationsWithThisUser} 
					editInvitationCallback={editInvitationCallback} deleteInvitationCallback={deleteInvitationCallback} 
					mergeInvitationsCallback={mergeInvitationsCallback} declineInvitationCallback={declineInvitationCallback}
					newInvitationCallback={newInvitationCallback}
					closeAllSubMenusToggle={closeAllSubMenusToggle} 
					idOfSubMenutoDisplay={idOfSubMenutoDisplay} setIdOfSubMenutoDisplay={invitationCallbackSetIdOfSubMenutoDisplay} addBottomBorder={false}
					pendingInvitationUpdate={pendingInvitationUpdate} headerColor={headerColor} handleShowMessages={handleShowMessages}
				/> */}
      </div>

      <div
        className={`ContainerVerticalNoHeightFlexLeft fullWidth conectivityCardDescriptionWrapper connectivityCardInfoWrapper TextStyle3V2 silver`}
      >
        {displayScheduledConectivityCardInfoRow({
          scheduledConectivity,
          invitationsWithThisUser,
          headerColor,
          darkMode,
        })}
        <div className='ContainerMaxHeightFlexLeft fullWidth textAlignLeft'>
          {truncateStringWithDots(
            conectivity.description,
            CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD
          )}
        </div>
      </div>
      <div className='scheduledActionRowWrapper'>
        {scheduledConectivityActionsRow({
          currentUser,
          scheduledConectivity,
          invitationsWithThisUser,
          editInvitationCallback,
          deleteInvitationCallback,
          mergeInvitationsCallback,
          declineInvitationCallback,
          newInvitationCallback,
          closeAllSubMenusToggle,
          idOfSubMenutoDisplay,
          invitationCallbackSetIdOfSubMenutoDisplay,
          pendingInvitationUpdate,
          headerColor,
          handleShowMessages,
          addBottomBorder: false,
		  handleDidItClick, 
		  handleSkipClick,
        })}
      </div>

      {/* {displayScheduledConectivityCardActionsRow({
					scheduledConectivity:scheduledConectivity, 
					handleDidItClick:handleDidItClick, 
					handleSkipClick:handleSkipClick, 
					showCompletionButtons:true,
					darkMode
				})} */}
    </div>
  </div>
);
});


//React Component for CHAT view of a scheduled conectivity for a specific invitation
export const ScheduledConectivityChatView = React.memo(({
	currentUser,
	showScheduledConectivityChatView, 
	scheduledConectivity, 
	showInvitations,
	mergeInvitationsCallback, editInvitationCallback, deleteInvitationCallback, newInvitationCallback, declineInvitationCallback,
	handleCloseModalMessages,
	pendingUpdateSCID,
	invitationToUpdate,
	messagesToShow,
}) => {

	const { darkMode } = useContext(DisplayContext); 
	
	//Local state
	const [invitationsWithThisUser, setinvitationsWithThisUser] = useState([]);
	const [closeAllSubMenusToggle, setCloseAllSubMenusToggle] = useState("false"); //Flip this state to cause all child invitations to CLOSE any open submenus
	const [idOfSubMenutoDisplay, setIdOfSubMenutoDisplay] = useState("");        //Since we want to control the sub-menus from the parent Conectivity, we need a call back state variable 
																				// to register which menu was just opened so we can tell the others to close

	const [pendingInvitationUpdate, setPendingInvitationUpdate] = useState(false);                //Internal state var to indicate that this SC has invoked a callback that should result in a state change from the backend; used to show spinners or disable input temporarily

	useEffect (() => {
		if (!scheduledConectivity) return;

		// Get an array of invitations for which the user is an INVITEE on this particular Scheduled Conectivity
		setinvitationsWithThisUser(getMyInvitations(scheduledConectivity, currentUser, true));

	},[scheduledConectivity]);

	//Pending Invitation Update Timer - execute on any change to the pendingUpdateSCID
	useEffect(() => {
		let timer;
		if (pendingUpdateSCID && pendingUpdateSCID === scheduledConectivity.id) {
			// if (DEBUG_MODE >= 2) console.log("SC setting pending update; starting pending update timer", pendingInvitationUpdate);
			setPendingInvitationUpdate(true); 
			timer = setTimeout(() => { 
				setPendingInvitationUpdate(false); 
				// if (DEBUG_MODE >= 2) console.log("SC pending update timer FIRED", pendingInvitationUpdate);
			}, 5000); //Clear local spinners in 5 seconds if not already cleared
		} else {
			setPendingInvitationUpdate(false); 
			// if (DEBUG_MODE >= 2) console.log("SC - clearing pending update", pendingInvitationUpdate);
		}

		// Clear timeout if the component is unmounted
		return () => {if (timer) clearTimeout(timer)};
	},[pendingUpdateSCID]);    
	
	
	//Safety checks
	if ( !showScheduledConectivityChatView || !handleCloseModalMessages || !currentUser || !scheduledConectivity)  return null;

	
	// Get an array of invitations for which the user is an INVITEE on this particular Scheduled Conectivity
	// Display each inviation as a different row on the Conectivity Card
	// const invitationsWithThisUser = getMyInvitations(scheduledConectivity, currentUser);                                                                                    
	const headerColor = setHeaderColorByScheduledConectivity(scheduledConectivity);
	
	return (
		 <ModalNoBackground showModal={showScheduledConectivityChatView} closeCallback={handleCloseModalMessages} cardColor={headerColor} closeButtonColor={COLOR_WHITE} closeButtonBackground={headerColor} >
		  			
				<div className="ContainerNoHeightCenter fullWidth conectivityCardHeaderWrapper" style={{backgroundColor:  headerColor}} >
					<div className="ContainerNoHeightCenter conectivityCardHeaderImageWrapper" >
						<img src={setConectivityImageUrlByConectivity(scheduledConectivity.conectivity)} className="conectivityCardHeaderImage" />
					</div>
					<div className="ContainerVerticalSpaceAround maxHeight conectivityCardHeaderTextWrapper" >
						<div className="ContainerNoHeightCenter wrap TextStyle4" style={{color: 'white'}}>
							{scheduledConectivity.conectivity.title}
						</div>
						{invitationsWithThisUser && invitationsWithThisUser.length > 0 ? null :
							<ConectivityDurationTimer closingDateTime={scheduledConectivity.closingDateTime}/>
						}
					</div>
				</div>
				
				<div className={`ContainerNoHeightCenter fullWidth `} style={{ paddingBottom:"clamp(5px,1vh,10px)", marginBottom:"clamp(5px,1vh,10px)", borderRadius:"0.5rem"}}>
					 {displayInvitationRow({currentUser:currentUser, scheduledConectivity:scheduledConectivity, invitationsWithThisUser: invitationsWithThisUser, 
						editInvitationCallback:editInvitationCallback, deleteInvitationCallback:deleteInvitationCallback, declineInvitationCallback:declineInvitationCallback,
						mergeInvitationsCallback:mergeInvitationsCallback, 
						newInvitationCallback:newInvitationCallback,
						closeAllSubMenusToggle:closeAllSubMenusToggle, 
						idOfSubMenutoDisplay:idOfSubMenutoDisplay, setIdOfSubMenutoDisplay:setIdOfSubMenutoDisplay, addBottomBorder:false,
						pendingInvitationUpdate:pendingInvitationUpdate, headerColor:headerColor, 
					}) }
				</div>
				
				<ChatWindow launchRuleID={invitationToUpdate.launchRuleID} currentUser={currentUser} headerColor={headerColor} messagesToShow={messagesToShow}/>    
				
		 </ModalNoBackground>
		);   
	
},  (prevProps, nextProps) => {
	//Our React MEMO function - do not re-render if no change 
	if (
		(prevProps.showScheduledConectivityChatView === nextProps.showScheduledConectivityChatView) &&
		(prevProps.scheduledConectivity === nextProps.scheduledConectivity) &&
		(prevProps.invitationToUpdate === nextProps.invitationToUpdate) &&
		(prevProps.messagesToShow === nextProps.messagesToShow) &&
		(prevProps.pendingUpdateSCID === nextProps.pendingUpdateSCID) 
		)
	{
		// if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the SC CHAT View",  prevProps.scheduledConectivity);
		return true; // props are equal
	}
	// if (DEBUG_MODE >= 2) console.log("Re-RENDER the SC CHAT View!", prevProps.scheduledConectivity);
	return false; // props are not equal -> update the component
} 
);
  
	

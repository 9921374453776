/*

	PROPRIETARY AND CONFIDENTIAL
	PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
	copyright - 2020, 2021

	- COMPONENT FOR CREATING AND EDITING RULES FOR AUTO-LAUNCHING CONECTIVITIES TO CONNECT SYSTEM-SELECTED USERS AND TEAMS

*/


//Config data
import  { CONECTERE_CONFIG_DATA, CHALLENGE_EVAL_SELECTION_OPTIONS, DEBUG_MODE, GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, QUICK_FILTERS_FOR_CONTECTIVITIES,
		  COLOR_BLUE_HEADER, COLOR_WHITE, TEAM_COLOR, BALANCE_COLOR, CONECTIVITY_PUBLICATION_SELECTION_OPTIONS, CONECTIVITY_INITIAL_STATE
		 } from '../../data/conectereConfigData'; 
import { HELP_TEXT_CHALLENGE_SETUP } from '../../data/helpData.js';


//React & Amplify
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { ConectivityContext } from '../../context/conectivitiesContext';
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context

//Bootstrap and other React components
import Select from 'react-select';   //Version with support from multiple selections
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@mui/material/Checkbox';

//Icons
import { DeleteOutline } from '@material-ui/icons';
import GifIcon from '@mui/icons-material/Gif';
import { IconButton } from '@mui/material';
// import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';

//Utils
import { truncateStringWithDots, displayConectereCommunityText,  ConectereDivider, Divider, HelpButton, InfoButton, CopyLinkButton, EmojiButton, SendButton,  GIFButton } from "../../utils/generalUtils";
import { conectivitiesMatchesSearch, DisplayConectivitySelectComponent, setHeaderColorByCategory, invokeUpdateLaunchRule, DisplayCardConectivityPreview, 
		invokeInviteToConect, invokeUpdateInviteToConect, invokeRemoveInviteToConect,  invokeRsvpToInviteToConect, setHeaderColorByStringLength,  } from "../../utils/conectivityUtils";
import { DisplayRecurrenceSelect, DisplayDateTimeSelect, DisplayAllDayEventSelect, NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, 
		WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,  } from "../../utils/dateTimeUtils";
import { DisplayUserSelectComponent, DisplayTeamSelectComponent, getUserInitials, getUserName, generateUsersToInvite } from "../../utils/userAndTeamUtils";
import { DisplayChallengeSelect } from "../../utils/challengeUtils"
import { v4 as uuidv4, v1ToV6 } from 'uuid';    

//Our components
import ModalNoBackgroundFixed from "../../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import SearchInputBar from "../../Components/searchInputBar/searchInputBar.js";

const AutoLaunchRulesEditor = React.memo(({ showRulesEditor, ruleToProcess, handleCloseEditor }) => {

	// Context
	const { currentUser } = useContext(AuthContext);    
	const { users, teams, teamsOptions, participatingUsersOptions, } = useContext(CustomerContext); 
	const { conectivitiesReadyForLaunch,  conectivitiesReadyForLaunchOptions,  } = useContext(ConectivityContext);
	const { setShowSpinner } = useContext(DisplayContext); 

	//Local state
	const [showRulesEditorLocal, setShowRulesEditorLocal] = useState(false);     //master control for our editor; this allows us to init the editor before presenting to the user
	const [ruleToProcessLocal, setRuleToProcessLocal] = useState(null);    //Used to hold a conectivity that is to be deleted
	const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);     //for multi-select
	const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);     //for multi-select
	const [inviteMessage, setInviteMessage] = useState("");

	//State for Search Bar
	const [conectivitiesReadyForLaunchOptionsFiltered, setConectivitiesReadyForLaunchOptionsFiltered] = useState([]); //Filtered list based on Search; used to drive all the row data
	const [searchTerm, setSearchTerm] = useState("");
	const [searchActive, setSearchActive] = useState(true);
	const [activeFilters, setActiveFilters] = useState([]);

   //Main UseEffect to trigger operation of the Editor    
	useEffect (() => {

		//Safety check
		if (!currentUser) return;
		
		let mode = showRulesEditor; if (!mode) mode = false;
		switch (mode) {
			case false:				//reset?
				setShowRulesEditorLocal(false);
				setRuleToProcessLocal(null);
				break;
			case 'ADD':				
				setShowRulesEditorLocal('ADD');
				setRuleToProcessLocal(null);
				break;
			case 'EDIT':			
				setShowRulesEditorLocal('EDIT');
				setRuleToProcessLocal({...ruleToProcess});
				break;
			case 'DELETE':			
				setShowRulesEditorLocal('DELETE');
				setRuleToProcessLocal({...ruleToProcess});
				break;
				default:
				setShowRulesEditorLocal(false); setRuleToProcessLocal(null);
				if (DEBUG_MODE) console.error("Error - improper mode to open auto launch rule editor", mode, showRulesEditor);
		} //End Switch
	},[showRulesEditor]);
	
	//
	// RENDER COMPONENT
	//
	function renderHeader() {
		switch (showRulesEditorLocal) {
			case 'ADD':
				return (<h3 className ="blue" > Create auto-launch rule!</h3> );
			case 'EDIT':
				return (<h3 className ="blue" > Edit auto-launch rule</h3> );
			case 'DELETE':
				return (<h3 className ="blue" > Delete auto-launch rule</h3> );
			default:
				return null;
			}
	}

	function renderButtons() {
		switch (showRulesEditorLocal) {
			case 'ADD':
				return (<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Save"  disabled={false} > Add </button> );
			case 'EDIT':
				return (<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="Save"   > Save </button> );
			case 'DELETE':
				return ( <button className="buttonStyle1 buttonStyle1Red" aria-label="Delete"   > <DeleteOutline /> </button>);
			default:
				return null;
			}
	}	

	//Safety check
	console.log("MODE:",showRulesEditorLocal)
	if (!showRulesEditorLocal) return null;
	
	return (
		<div>
			
			<ModalNoBackground showModal={showRulesEditorLocal} closeCallback={handleCloseEditor} cardColor={TEAM_COLOR} closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR}  >
				<div className="modalNoBkgInnerCard" onClick = {(e) =>  e.stopPropagation()}>
					<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
						<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt='logo'/>  
					</div>

					<div className="ContainerMaxHeightCenterFullWidth" style={{height:"clamp(30px,10vh,150px)", margin:"clamp(4px,1vw,10px)"}}>
						{renderHeader()}
						<div className="ccImgXXXXL" style={{marginLeft:"clamp(5px,4vw,20px)", position:"relative", width:"clamp(40px,10vw,100px)"}}>
							 <img className="avatarImageCenterPortrait" style={{width:'auto', height:"100%"}} src="/img/image-ring-1.png" alt=""/> 
						</div>
					</div>
	
					<ConectereDivider />
						
					<div className="modalNoBkgFooter flexEnd">
						<div className="ContainerNoHeightCenter"> 
							{renderButtons()}
							<button className="buttonStyle1 buttonStyle1DavyGray" aria-label="Cancel" onClick={handleCloseEditor} > Cancel </button>                        
						</div>
					</div>                    
			</div>
		 </ModalNoBackground> 
	</div>
	);

});

export default AutoLaunchRulesEditor;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, DEFAULT_TAX_RATE, SERVICE_PLAN_OPTIONS, FAINT_GREY, 
			 TEAM_COLOR, BALANCE_COLOR, COLOR_BLUE_TEXT, INVOICE_STATUS_SELECTION_OPTIONS, INVOICE_INITIAL_STATE, customerInitialState, customerBillingInitialState,
			 ORDER_FULFILLIMENT_FEE_MAX, ORDER_FULFILLIMENT_FEE_MIN, ORDER_FULFILLIMENT_FEE_PERCENT,
			} from '../../data/conectereConfigData'; 

//React & Amplify
import React, { useEffect, useState, useContext, useRef  } from 'react';

//CONTEXT
import { AuthContext } from '../../context/authContext';            //User Authentication context
import { CustomerContext } from '../../context/customerContext';    //Customer Authentication context
import { OrdersContext } from '../../context/ordersContext';        //Orders context
import { InvoicesContext } from '../../context/invoicesContext';    //Invoices context
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context
import { ModalContext } from '../../context/modalContext';            

//Bootstrap and other React components
import Select from 'react-select';   //Version with support from multiple selections

//Queries and Mutations
import { getCustomerBillingByCustomer, getUser, getProduct } from '../../graphql/queries';

//Icons
import Switch from '@mui/material/Switch';

//Utils
import { displayConectereCommunityText,  Divider } from "../../utils/generalUtils";
import {NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING, ONE_MONTH_AGO, ONE_MONTH_AGO_STRING} from "../../utils/dateTimeUtils";
import { getUserInitials } from "../../utils/userAndTeamUtils";
import { invokeAPI, getDataRecordById, queryDataTableWithPagination } from "../../utils/databaseUtils";

//Our components
// import ModalNoBackgroundFixed from "../../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import ModalGeneral from "../../Components/modalGeneral/modalGeneral";

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import TextField from '@mui/material/TextField';


//TINY editor
// import { Editor } from '@tinymce/tinymce-react'; 

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';    


const InvoiceEditor = React.memo(({ showModalAdd, showModalEdit, showModalShare, showModalDelete, showModalView, invoiceToEdit, handleCloseEditor }) => {

	//Affirmatively set to a Boolean for controlled inputs
	if (!showModalDelete) showModalDelete = false;
	
	// if (DEBUG_MODE >= 2) console.log("Editor rendered", invoiceToEdit);
	
	//State data
	const [invoiceToEditLocal, setInvoiceToEditLocal] = useState({...INVOICE_INITIAL_STATE}); //Used to hold a invoice that is to be edited
	const [invoiceStatusSelection, setInvoiceStatusSelection] = useState("");  //Note, this stores an object of the select Option.  See react-select
	const [invoiceStatusOptions, setInvoiceStatusOptions] = useState (INVOICE_STATUS_SELECTION_OPTIONS);
	const [customerSelection, setCustomerSelection] = useState(null);
	const [billRewards, setBillRewards] = useState(true);
	const [billService, setBillService] = useState(true);
	const [invoiceDate, setInvoiceDate] = useState(NOW()); 
	const [invoiceLineItems, setInvoiceLineItems] = useState([]);
	const [customerToInvoice, setCustomerToInvoice] = useState(customerInitialState);
	const [toAddressessForInvoice, setToAddressesForInvoice] = useState([]);
	const [servicePlanForInvoice, setServicePlanForInvoice] = useState("");
	const [customerBillingInfoForInvoice, setCustomerBillingInfoForInvoice] = useState(customerBillingInitialState);
	const [ordersToInvoice, setOrdersToInvoice] = useState([]);
	// const [invoiceAmount, setInvoiceAmount] = useState(0);
	// const [invoiceTax, setInvoiceTax] = useState(0);
	// const [invoiceTotal, setInvoiceTotal] = useState(0);
	const [invoiceDiscount, setInvoiceDiscount] = useState(0);
	const [invoicePeriod, setInvoicePeriod] = useState("");

	
   // Context
	const { setShowSpinner } = useContext(DisplayContext); 
	const { currentUser, isSuperAdmin,  } = useContext(AuthContext);    
	const { users, customers, customerOptionsNoAll } = useContext(CustomerContext);  
	const { ordersFiltered } = useContext(OrdersContext);  
 	const { invokeCreateInvoice, invokeUpdateInvoice, invokeDeleteInvoice } = useContext(InvoicesContext);  
	 const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 


	//On load, initialize our local data
	useEffect (() => {
		if (invoiceToEdit && currentUser && showModalAdd) initializeAdd({invoiceToEdit});
		else if (invoiceToEdit && currentUser && (showModalEdit || showModalDelete || showModalView) ) initializeEdit({invoiceToEdit});
	},[showModalAdd, showModalEdit, showModalDelete, showModalView]);
	
	function initializeAdd({invoiceToEdit}) {

		if (DEBUG_MODE >= 2) console.log("Editor Add: opening new invoice", invoiceToEdit);
		setInvoiceToEditLocal({...invoiceToEdit}); //Set our local conectivity for our editor

		//Preset the invoice status dropdown selection to peding; our Lambda will automatically email the invoice and update to UNPAID
		setInvoiceStatusSelection(INVOICE_STATUS_SELECTION_OPTIONS.find(status => status.label === "PENDING"));  //Grab the entire object for use as initial value for React-Select

		//Preset the company options to NULL - SuperAdmin must select the company 
		//   const tempNewCustomerSelection = customerOptionsNoAll.find (customer => customer.id == currentUser.customerID);
		//     setCustomerSelection({ ...tempNewCustomerSelection}); //Trigger state update to build the invoice
		setCustomerSelection(null);
		setToAddressesForInvoice([]);
		
		//Preset no discount
		setInvoiceDiscount(0);
		
	}
	
	function initializeEdit({invoiceToEdit}) {
		
		if (DEBUG_MODE >= 2) console.log("Editor EDIT: opening existing invoice", invoiceToEdit);
		setInvoiceToEditLocal({...invoiceToEdit}); //Set our local conectivity for our editor

	   //Preset the company options to the company of this user
		setCustomerSelection(customerOptionsNoAll.find (customer => customer.id === invoiceToEdit.customerID));

		//Preset the invoice status dropdown selection 
		setInvoiceStatusSelection(INVOICE_STATUS_SELECTION_OPTIONS.find(status => status.label === invoiceToEdit.invoiceStatus));  //Grab the entire object for use as initial value for React-Select

		//Set the discount
		setInvoiceDiscount(invoiceToEdit.discount ? invoiceToEdit.discount.toString() : '');
		
		generateToAddressesForInvoice(invoiceToEdit.customerID);
		
		const billingPeriodEndingDateMoment = moment(invoiceToEdit.billingPeriodEndingDate);
		setInvoicePeriod(billingPeriodEndingDateMoment.format("MMMM YYYY"));
		
	}    

	//When creating an invoice, handle a change in customer selection
	useEffect (() => {
		if (customerSelection && showModalAdd) loadCustomerInformation();
	},[customerSelection]);


	async function loadCustomerInformation () {
		
		if (DEBUG_MODE >= 2) console.log("Invoice Editor: pulling customer information new new invoice")
		
		if (showModalAdd) {
			//grab the billing information for the customer as we will need to know the service plan
			const billingData = await queryDataTableWithPagination(getCustomerBillingByCustomer, 'getCustomerBillingByCustomer', { customerID: customerSelection.id, });            
			if (!billingData || billingData.length === 0) {
				if (DEBUG_MODE >= 2) console.log("Error fetching billing information", billingData);          
			} else {    
				const tempCustomerBilling = billingData[0];
				if (DEBUG_MODE >= 2) console.log("Retreived billing information", tempCustomerBilling); 
				setCustomerBillingInfoForInvoice({...tempCustomerBilling});
			}
	
			//Set the customer 
			const tempCustomer = customers.find (customer => customer.id === customerSelection.id);
			if (DEBUG_MODE >= 2) console.log("Set customer details", tempCustomer); 
			setCustomerToInvoice({...tempCustomer});
	
	
			//Get unbilled orders for this company; note, we filter out any order that are FILLED_BY_CUSTOMER
			var tempOrdersToBill = ordersFiltered.filter(order => order.orderStatus === "FILLED" && order.customerID === customerSelection.id);
			setOrdersToInvoice(tempOrdersToBill);
			if (DEBUG_MODE >= 2) console.log("Orders to bill for the selected customer", tempOrdersToBill);
		}
		
		generateToAddressesForInvoice(customerSelection.id);
	}
	
	function generateToAddressesForInvoice(customerID) {
		
		const admins = users.filter(user => (user.permissionBilling && (user.customerID === customerID)));     //Find ALL the admin for THIS customer
		var adminEmails = "";
		admins.forEach(admin => {
			adminEmails = adminEmails + admin.email + ", ";
		});
			
		if (adminEmails.length > 0) adminEmails = adminEmails.slice(0, -2); //Remove last comma and space
		setToAddressesForInvoice(adminEmails);
		
	}


	//Genereate line items based on changes to inputs
	useEffect (() => {
		if (customerSelection && showModalAdd) buildInvoice();
	},[billRewards, billService, ordersToInvoice]);
	
 
	 async function buildInvoice() {
	  try {
		 let tempInvoiceLineItems = [],  serviceAmountTotal = 0, serviceTaxTotal = 0, discount = 0, itemsAmountTotal = 0, itemsShippingTotal = 0, itemsTaxTotal = 0;  
		 
		 setShowSpinner(true);
		 
		 //Push the service charge first
		 if (billService) {
			
			//Find the selected service
			const servicePlan = SERVICE_PLAN_OPTIONS.find(plan => plan.subscription === customerBillingInfoForInvoice.subscription);
			if (servicePlan) {
				if (DEBUG_MODE >= 2) console.log("Found the customer's service plan", servicePlan);
			
				tempInvoiceLineItems.push( {
					lineItemType:"SERVICE_CHARGE",
					memo: servicePlan.displayString + " - " + customerToInvoice.activeEmployees.toString() + " users @ $" + servicePlan.monthlyCost.toString() + "  month / user " ,
					taxable: true,
					quantity:customerToInvoice.activeEmployees,
					price:servicePlan.monthlyCost,
					discount:0,
					amount:(servicePlan.monthlyCost * customerToInvoice.activeEmployees),
				});
			} else {
				if (DEBUG_MODE >= 2) console.log("Error - no matching customer's service plan", servicePlan, customerBillingInfoForInvoice, SERVICE_PLAN_OPTIONS);
			}
			
			serviceAmountTotal = (servicePlan.monthlyCost * customerToInvoice.activeEmployees); //Compute service amount
			serviceTaxTotal = Math.round(100 * serviceAmountTotal * DEFAULT_TAX_RATE) / 100;    //Compute tax for service amount
			setServicePlanForInvoice(servicePlan);
		 }
 
		 if (DEBUG_MODE >= 2) console.log("Computed service amount", serviceAmountTotal);
		 if (DEBUG_MODE >= 2) console.log("Computed service tax", serviceTaxTotal);
		 
		 //Build line items for the invoice
		 if (billRewards) {
			for(var i = 0; i<ordersToInvoice.length; i++){
				
				if (DEBUG_MODE >= 2) console.log("Processing order", ordersToInvoice[i]); 

				//Get the ordered item
			   const tempProduct =  await getDataRecordById(getProduct, "getProduct", ordersToInvoice[i].productID);               
			   if (tempProduct) {
				  if (DEBUG_MODE >= 2) console.log("Retreived ordered product", tempProduct); 

					 //Get the user that ordered the item.  Try the users context first
					 let tempUser =  users.find(user => user.id === ordersToInvoice[i].userID);
					 if (!tempUser) tempUser = await getDataRecordById(getUser, "getUser", ordersToInvoice[i].userID);       
					 if (DEBUG_MODE >= 2) console.log("Retreived user that placed the ordered", tempUser); 
					 
					//Calculate our fullfillment Fee 
					// const ORDER_FULFILLIMENT_FEE_MAX = 10.00		//Max amount we charge for filling and order 
					// const ORDER_FULFILLIMENT_FEE_MIN = 3.00		//Min amoune we charge for filling and order 
					// const ORDER_FULFILLIMENT_FEE_PERCENT = 0.2	//Min amoune we charge for filling and order 
					let fulfillmentFee = (Math.round(100 * (ORDER_FULFILLIMENT_FEE_PERCENT * ordersToInvoice[i].dollarCost)) / 100);
					if (fulfillmentFee > ORDER_FULFILLIMENT_FEE_MAX) fulfillmentFee = ORDER_FULFILLIMENT_FEE_MAX;
					else if (fulfillmentFee < ORDER_FULFILLIMENT_FEE_MIN) fulfillmentFee = ORDER_FULFILLIMENT_FEE_MIN;
					if (DEBUG_MODE >= 2) console.log("Calculated our fee for filling the order fee", fulfillmentFee);

					const lineItem = {
						orderID: ordersToInvoice[i].id,
						lineItemType:"ORDER_FILLED",
						memo: "Employee reward: " + tempProduct.title + " (" + getUserInitials(tempUser)  + ") ",
						taxable: true,
						quantity:1,
						price:ordersToInvoice[i].dollarCost,
						discount:0,
						amount:ordersToInvoice[i].dollarCost + fulfillmentFee,
						fulfillmentFee:fulfillmentFee,
					 };
					 if (DEBUG_MODE >= 2) console.log("Built line item", lineItem); 

					 //Now, build the line item
					 tempInvoiceLineItems.push(lineItem);
					 
					 itemsAmountTotal += (lineItem.amount ? lineItem.amount : 0);  										//Update total for items
					 itemsShippingTotal += (lineItem.shipping ? lineItem.shipping : 0);    //Update shipping for items
					 itemsTaxTotal += (lineItem.tax ? lineItem.tax : 0);                   //Update tax for items

					 if (DEBUG_MODE >= 2) console.log("Updated items amount", itemsAmountTotal);
					 if (DEBUG_MODE >= 2) console.log("Updated items shipping", itemsShippingTotal);
					 if (DEBUG_MODE >= 2) console.log("Updated items tax", itemsTaxTotal);
			
			   }  else { //END IF PRODUCT FOUND
					 if (DEBUG_MODE >= 2) console.log("NOTE - ordered product not found", ordersToInvoice[i].productID); 
			   }
			} // END FOR EACH ORDERS
		 } //END IF BILL REWARDS ENABLED
		 
		 //Compute Totals
		 let amountTotal = (Math.round(100 * (serviceAmountTotal + itemsAmountTotal)) / 100);
		 let taxTotal = (Math.round(100 * (serviceTaxTotal + itemsTaxTotal)) / 100);
		 let invoiceTotal = (Math.round(100 * (taxTotal + amountTotal + itemsShippingTotal - discount)) /100);
		 
		 if (DEBUG_MODE >= 2) console.log("Built Line Items", tempInvoiceLineItems);
		 if (DEBUG_MODE >= 2) console.log("Amount", amountTotal);
		 if (DEBUG_MODE >= 2) console.log("Taxes", taxTotal);
		 if (DEBUG_MODE >= 2) console.log("Shipping", itemsShippingTotal);
		 if (DEBUG_MODE >= 2) console.log("Total", invoiceTotal);
		 // setInvoiceLineItems(tempInvoiceLineItems);
		 // setInvoiceAmount(amountTotal);
		 // setInvoiceTax(taxTotal);
		 // setInvoiceTotal(taxTotal + amountTotal);
		 
		 //Store line items and computed totals into our local object in App Sync format so we can reuse the editor as an invoice viewer
		 const tempInvoice = {
			...invoiceToEditLocal,
			charges:amountTotal,
			discount:discount, 
			tax:taxTotal,
			shipping:itemsShippingTotal,
			total:invoiceTotal,           
			lineItems: {items: [...tempInvoiceLineItems]},
		 };
		 if (DEBUG_MODE >= 2) console.log("Build temp invoice", tempInvoice);     
		 setInvoiceToEditLocal (tempInvoice);
	  } catch (err) {
		 if (DEBUG_MODE >= 1) console.error("Error generating invoice", err);
	  }
		 
	  setShowSpinner(false);
	}
 
	
	
 /*   
	function removeServiceLineItem() {
		var tempInvoiceLineItems = [...invoiceLineItems];
		tempInvoiceLineItems = tempInvoiceLineItems.filter(item => item.x != "");
	}
*/    
	
	//define function to handle submitButton, which is used to create a new invoice 
	async function handleSubmit (e)  {
		e.preventDefault();     //prevent React page refresh

		if (DEBUG_MODE >= 2) console.log('Called handler to create new invoice', invoiceToEditLocal);

		try { 
		   
	
			setShowSpinner(true); //Show spinner
			
			var successFlag = await invokeCreateInvoice ({ 
					invoiceNumber: customerBillingInfoForInvoice.nextInvoiceNumber,
					customer:customerToInvoice,
					customerBillingID: customerBillingInfoForInvoice.id,  // Identifies the billing info record for this customer
					servicePlane: servicePlanForInvoice.subscription,
					billingPeriodStartDateString:  invoiceDate.clone().startOf('month').toISOString(), 
					billingPeriodEndingDateString: invoiceDate.clone().endOf('month').toISOString(), 
					invoice:invoiceToEditLocal,  
					lineItems: invoiceToEditLocal.lineItems.items, 
					invoiceStatusSelection:invoiceStatusSelection,  
					customerSelection:customerSelection,
			});
		   
			if (successFlag) {
				setModalGeneralTitle("Success!");
				setModalGeneralMessage("Invoice created");
			} else {
				setModalGeneralMessage("Something went wrong.");
				setModalGeneralTitle("Ooops!");
				
			}
	   } catch (err) {
				setModalGeneralMessage("Something went wrong. " + err);
				setModalGeneralTitle("Ooops!");
				if (DEBUG_MODE >= 2) console.log("Error creating invoice", err);
		}
		
		// close the editor
		setShowSpinner(false); //hide spinners
		handleCloseEditor();
		setShowModalGeneral(true);   

	}
	
	//Edit Functions 
	async function handleConfirmationEdit () {
		
		setShowSpinner(true); //Show spinners

		if (DEBUG_MODE >= 2) console.log("Updating invoice", invoiceToEditLocal);    

		try {
 
			var successFlag = await invokeUpdateInvoice ({ invoice:invoiceToEditLocal,  invoiceStatusSelection:invoiceStatusSelection,  customerSelection:customerSelection});
 
			if (successFlag) {
				setModalGeneralTitle("Success!");
				setModalGeneralMessage("Invoice updated");
			} else {
				setModalGeneralMessage("Something went wrong.");
				setModalGeneralTitle("Ooops!");
			}

		} catch (err) {
				setModalGeneralMessage("Something went wrong. " + err);
				setModalGeneralTitle("Ooops!");
				if (DEBUG_MODE >= 2) console.log("Error creating conectivity", err);
		}

		 // close the editor
		handleCloseEditor();
		setShowSpinner(false); //hide spinners
		setShowModalGeneral(true);
	}   


	//Edit Functions 
	async function handleConfirmationDelete () {
		
		setShowSpinner(true); //Show spinners

		if (DEBUG_MODE >= 2) console.log("Deleting invoice", invoiceToEditLocal);    

		try {
 
			var successFlag = await invokeDeleteInvoice ({ invoice:invoiceToEditLocal});
 
			if (successFlag) {
				setModalGeneralTitle("Success!");
				setModalGeneralMessage("Invoice deleted");
			} else {
				setModalGeneralMessage("Something went wrong.");
				setModalGeneralTitle("Ooops!");
			}

		} catch (err) {
				setModalGeneralMessage("Something went wrong. " + err);
				setModalGeneralTitle("Ooops!");
				if (DEBUG_MODE >= 2) console.log("Error creating conectivity", err);
		}

		 // close the editor
		handleCloseEditor();
		setShowSpinner(false); //hide spinners
		setShowModalGeneral(true);
	}  
	
	//Set the state of the SAVE button
	function disableSaveButton () {
		//Page still loading?
		if (!currentUser) return true;
		
		//Unassigned author?
		if (!customerSelection || !invoiceStatusSelection) return true;
		
		//Does the customer have at least 1 user designated with BILLING permissions?
		if (toAddressessForInvoice.length === 0) return true;
		
		return false;
	}

	
	//Function for rendering buttons on Conectivity Editor Modal
	function displayEditorButtons () {
   
		if (showModalAdd) {
			
			return (
				<div className="ContainerNoHeightCenter"> 
					  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleSubmit} disabled={disableSaveButton()}> Generate </button>
					  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCloseEditor} >  Cancel  </button>  
				</div>
			);
			
		} else if (showModalEdit) { 
			return (
				<div className="ContainerNoHeightCenter"> 
					  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationEdit} disabled={disableSaveButton()}> Save </button>
					  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCloseEditor} >  Cancel  </button>  
				</div>
			);  
		} else if (showModalDelete) { 
			return (
				<div className="ContainerNoHeightCenter"> 
					  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationDelete} disabled={disableSaveButton()}> Delete </button>
					  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCloseEditor} >  Cancel  </button>  
				</div>
			);  
						
		}  else if (showModalView) { 
			return (
				<div className="ContainerNoHeightCenter"> 
					  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCloseEditor} >  Close  </button>  
				</div>
			);  
						
		} else return null;
		
	}

	 const handleStatusSelection = (eventKey) => {
		if (DEBUG_MODE >= 2) console.log("Invoice status selected", eventKey);
		setInvoiceStatusSelection(eventKey);
	 };

	 const handleCompanySelection = (eventKey) => {
		if (DEBUG_MODE >= 2) console.log("Company selected", eventKey);
		setCustomerSelection(eventKey);
	 };



	function displayToAddresses() {
		
		//safety check
		if (!invoiceToEditLocal) return null;
		
	   return (
			<div className="ContainerNoHeightFlexLeft fullWidth TextStyle4 black" style={{border: (toAddressessForInvoice.length === 0 ? "1px solid red" : "none"), padding:"10px 0px"}} >
				To: {toAddressessForInvoice}               
			</div>
			);
	}
	
	
	function displayLineItems() {
		
		//safety check
		if (!invoiceToEditLocal.lineItems || !invoiceToEditLocal.lineItems.items) return null;
	   return (
		   <div className="ContainerVertical fullWidth TextStyle3 black" >
				{invoiceToEditLocal.lineItems.items.map((target, index) => (
					<div key={uuidv4()} className="ContainerNoHeightSpaceBetween fullWidth very-light-grey-background" style={{padding:"10px 0px 10px 20px", marginTop:"5px"}}>
						<div style={{textAlign:"start"}}>{target.memo} </div>
						<div style={{textAlign:"end"}}>${target.amount.toFixed(2)} </div>
					</div>
				))}
			   
			</div>
			);
	}

	const applyDiscount = (value) => {
		if (DEBUG_MODE >= 2) console.log("Type of discount", typeof value);
		setInvoiceDiscount(value); //Store the UI input
		
		//Update our calcs if needed
		var newDiscount = Number(value);
		newDiscount = Math.round(newDiscount * 100) / 100; //Round to 2 digits
		
		if (!newDiscount) setInvoiceToEditLocal({...invoiceToEditLocal, discount:0});
		else {
			var newTotal = invoiceToEditLocal.charges + invoiceToEditLocal.tax - newDiscount; 
			newTotal = Math.round(newTotal * 100) / 100; //Round to 2 digits
			if (DEBUG_MODE >= 2) console.log("Appying discount", value, newTotal);
			setInvoiceToEditLocal({...invoiceToEditLocal, discount:newDiscount, total:newTotal});
		} 
	};
	 
	return (
		<div>
	   
		   <ModalNoBackground showModal={showModalEdit || showModalDelete || showModalAdd || showModalView || showModalShare} closeCallback={handleCloseEditor} cardColor={TEAM_COLOR}  >
			<div className="modalNoBkgInnerCard justifyStart" style={{minHeight:"600px"}}>
			   <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt=''/>  
			   </div>
			   <h3 className="blue"> INVOICE {showModalAdd || showModalEdit ? "EDITOR" : null} </h3>
			   <div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >

				{(!showModalView && !showModalDelete) ? "" :
					<div className="inputFormVerticalLeftInputField TextStyle5" style={{zIndex:"99"}}>
						 <div >Invoice</div>
						 <div className="black">{invoiceToEdit.invoiceNumber}</div>
					</div>
				}

				{(showModalView || showModalDelete) ? 
					<div className="inputFormVerticalLeftInputField TextStyle5" style={{zIndex:"99"}}>
						 <div >Period</div>
						  <div className="black">{invoicePeriod} </div>
					</div>
				:
					<div className="inputFormVerticalLeftInputField TextStyle5" style={{zIndex:"99"}}>
						 <div >Invoice Date</div>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								  <DatePicker
									// minDate={NOW()}
									// maxdate={SIX_MONTHS_FROM_NOW()}
									disabled={showModalDelete || showModalView}
									label=""
									value={invoiceDate}
									onChange={(newDateValue) => {
									  setInvoiceDate(newDateValue);
									  if (DEBUG_MODE >= 2) console.log("Invoice date:", newDateValue.toISOString());
									}}
									// renderInput={(params) => <TextField size="small" {...params} />}
								  />
							</LocalizationProvider>
					</div>
				}

					  <div className="inputFormVerticalLeftInputField TextStyle5" >
							<div>Invoice Status </div> 
							<div style={{width:"250px"}}>
								{isSuperAdmin ?
									<Select  isMulti={false} name="statusDropdownEdit" 
										options={INVOICE_STATUS_SELECTION_OPTIONS} 
										isOptionDisabled={(option) => option.isdisabled}
										isDisabled={showModalDelete || showModalView}  
										onChange={handleStatusSelection} 
										value={!invoiceStatusSelection ? "" : INVOICE_STATUS_SELECTION_OPTIONS[invoiceStatusSelection.value]}  
										placeholder=" - select -"
									/>
								:  
									<div className="black"> {invoiceStatusSelection =="" ? "" : invoiceStatusSelection.displayString} </div>
								}
							 </div>
						</div>


						<div className="inputFormVerticalLeftInputField TextStyle5" >
							<div>Billed To</div> 
							<div style={{width:"250px"}}>
								{isSuperAdmin  ?
									<Select  isMulti={false} name="companyDropdownEdit" 
										options={customerOptionsNoAll} 
										isOptionDisabled={(option) => option.isdisabled}
										isDisabled={!showModalAdd }  //Disable if not creating a new invoice 
										onChange={handleCompanySelection} 
										value={!customerSelection ? "" : customerSelection}  
										placeholder=" - select -"
									/>
							   : <div className="black"> {!customerSelection ? "" : customerSelection.label} </div>
							   }
							</div>
						</div>
					</div>
					
					{ showModalDelete || showModalView ? null :
					  <div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
							<div className="inputFormVerticalLeftInputField TextStyle5">
								 <div> Charge Service </div> 
								<div className="ContainerNoHeightCenter">
									<Switch
										name="billService"
										checked={billService}   
										disabled={showModalDelete || showModalView}
										onChange={event => setBillService(!billService)}
									/>
								</div>
						   </div>
	
							<div className="inputFormVerticalLeftInputField TextStyle5">
								 <div> Charge Items </div> 
								   <Switch
										name="billRewards"
										checked={billRewards}   
										disabled={showModalDelete || showModalView}
										onChange={event => setBillRewards(!billRewards)}
									/>
						   </div>
						   <div className="inputFormVerticalLeftInputField TextStyle5" >
								<div>Discount Amount </div> 
								<div className="ContainerNoHeight" >
									$<input
										style={{border: (invoiceToEditLocal.discount > 0 ? "1px solid red" : "1px solid grey")}}
										name="discount"
										type="number"
										size="6"
										value={invoiceDiscount}
										onChange={(event) => applyDiscount(event.target.value)}
										required
										disabled={showModalDelete || showModalView}
									/>
								</div>
							</div>
					   </div>
					}
					 
					 
						<Divider percent={100} marginTop = '10px' />
  
					  { !customerSelection ? null :
						<div className="fullWidth">
							{displayToAddresses()}
							{displayLineItems()}
		 
							<div className="ContainerNoHeightFlexEndCentered fullWidth black" style={{borderTop:"1px solid black"}}>
								<div>${invoiceToEditLocal.charges || invoiceToEditLocal.charges===0 ? invoiceToEditLocal.charges.toFixed(2) : 'n/a'}</div>
							</div>
							
							{((showModalView || showModalDelete) && (invoiceToEditLocal.discount === 0)) ? "" :
							<div className="ContainerNoHeightFlexEndCentered fullWidth black" >
								<div>Discount: (${invoiceToEditLocal.discount.toFixed(2)})</div>
							</div>
							}
		
							<div className="ContainerNoHeightFlexEndCentered fullWidth black" >
								<div>Shipping: ${invoiceToEditLocal.shipping || invoiceToEditLocal.shipping===0 ? invoiceToEditLocal.shipping.toFixed(2) : 'n/a'}</div>
							</div>
							<div className="ContainerNoHeightFlexEndCentered fullWidth black" >
								<div>Taxes: ${invoiceToEditLocal.tax || invoiceToEditLocal.tax===0 ? invoiceToEditLocal.tax.toFixed(2) : 'n/a'}</div>
							</div>
						   <div className="ContainerNoHeightFlexEndCentered fullWidth black" > 
								<div style={{borderTop:"1px solid black"}}> Total: ${invoiceToEditLocal.total || invoiceToEditLocal.total===0 ? invoiceToEditLocal.total.toFixed(2) : 'n/a'}</div>
							</div>                   
		 
							<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" >
							   <div className="inputFormVerticalLeftInputField TextStyle5" style={{minWidth:"400px", flex:"1.5 0 0px"}}>
									<div> Memo </div> 
									<textarea
										className="adminConectivitiesOverviewInput fullWidth"
										style={{minHeight:"10px", backgroundColor:FAINT_GREY, borderRadius:"4px", border: "1px solid grey"}}
										name="description"
										type="textarea"
										// rows="4" 
										// cols="60"
										value={invoiceToEditLocal.description}
										onChange={event => setInvoiceToEditLocal({ ...invoiceToEditLocal, description: event.target.value })}
										required
										disabled={!showModalAdd}
									/>
								</div>
						   </div>
					   
						   <div className="modalNoBkgFooter flexEnd">
								{displayEditorButtons()}     
							</div>
					   </div>
					  }
				 </div>
			 </ModalNoBackground>   
			 
				
			</div>
		);

}
	/*  ,(prevProps, nextProps) => {
		//Our React MEMO function - do not re-render if no conectivity or all the props stay the same
		if (
			(!prevProps.conectivityToEdit && !nextProps.conectivityToEdit) || 
			(!prevProps.conectivityToEdit.id && !nextProps.conectivityToEdit.id) || 
			((prevProps.conectivityToEdit.id === nextProps.conectivityToEdit.id) &&
			(prevProps.showModalAdd === nextProps.showModalAdd) &&
			(prevProps.showModalEdit === nextProps.showModalEdit) &&
			(prevProps.showModalShare === nextProps.showModalShare) && 
			(prevProps.showModalEditPost === nextProps.showModalEditPost))
			) 
		{
			if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the Editor!", !prevProps.conectivityToEdit, !nextProps.conectivityToEdit, !prevProps.conectivityToEdit.id, nextProps.conectivityToEdit.id, prevProps.conectivityToEdit, nextProps.conectivityToEdit);
			return true; // props are equal
		}
		if (DEBUG_MODE >= 2) console.log("Re-RENDER the Editor!", prevProps.conectivityToEdit, nextProps.conectivityToEdit);
		return false; // props are not equal -> update the component
	} */
);

export default InvoiceEditor;
import "./stackedAreaChart.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../data/conectereConfigData';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//Amplify, React
import  React from 'react';


//React component
const CCStackedAreaChart = ({title, data, dataKeyX, dataKeyY1, dataKeyY2, dataKeyY3, dataKeyY4, color1, color2, color3, color4, grid, height, width, renderLegend, renderTooltip}) => {

 
    if (!height) height = "clamp(600px, 80vh, 1400px)";
    if (!width) width = "clamp(600px, 80vw, 1400px)";
    
    // if (DEBUG_MODE >= 2) console.log("AreaChart called", title);
    // if (DEBUG_MODE >= 2) console.log("dataKey", data);
    // if (DEBUG_MODE >= 2) console.log("dataKeyX", dataKeyX);
    // if (DEBUG_MODE >= 2) console.log("dataKeyY1", dataKeyY1);
    // if (DEBUG_MODE >= 2) console.log("dataKeyY2", dataKeyY2);
    // if (DEBUG_MODE >= 2) console.log("dataKeyY3", dataKeyY3);
    // if (DEBUG_MODE >= 2) console.log("grid", grid);
    // if (DEBUG_MODE >= 2) console.log("width", width);
    // if (DEBUG_MODE >= 2) console.log("height", height);    

    
    return (

        <div className="stackedAreaChart">
            <h3 className="stackedAreaChartTitle">{title}</h3>
            
                <div style={{width:width, height:height}}>
                    <ResponsiveContainer >
                     <AreaChart data={data} height={height} width={width} >
                        <XAxis dataKey={dataKeyX} />
                        <YAxis />
                        {dataKeyY1 && <Area type="monotone" dataKey={dataKeyY1} stackId="1" stroke={color1} fill={color1} />}
                        {dataKeyY2 && <Area type="monotone" dataKey={dataKeyY2} stackId="1" stroke={color2} fill={color2} />}
                        {dataKeyY3 && <Area type="monotone" dataKey={dataKeyY3} stackId="1" stroke={color3} fill={color3} />}
                        {dataKeyY4 && <Area type="monotone" dataKey={dataKeyY4} stackId="1" stroke={color4} fill={color4} />}
                        {grid && <CartesianGrid strokeDasharray="3 3" />}
                        { renderTooltip ?
                            <Tooltip content = {renderTooltip}/>
                            :
                            <Tooltip />
                        }
                            
                        
                        {renderLegend ?
                            <Legend 
                                content = {renderLegend}
                            />
                        :
                            <Legend 
                                verticalAlign="bottom" 
                                height={14} 
                                iconType={'circle'}
                            />
                        
                        }
                    </AreaChart>
                </ResponsiveContainer>
          </div>
        </div>
        
    );

}


export default CCStackedAreaChart;



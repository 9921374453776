//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//
//  Context for Conectivities AND Scheduled Conectivities for the particular user

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER,  COLOR_BLUE_TEXT, RATING_STATS_INITIAL_STATE } from '../data/conectereConfigData';

//React / Amplify
import React, { useEffect, useState, useContext, useRef } from "react";
import { generateClient } from 'aws-amplify/api';	//Amplify V6

//User Authentication context
import { AuthContext } from './authContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context

//Queries and Mutations
import { listConectivities,  getConectivitiesByScope, getConectivitiesByCustomer} from '../graphql/queries';
import { listImages} from '../graphql/queries';
import { listLaunchRules, getLaunchRulesByCustomer } from '../graphql/queries';
import {  getCCTransactionsByUserByDate } from '../graphql/queries';
import { createConectivity, deleteConectivity, updateConectivity } from '../graphql/mutations';
import { createRatingStats, deleteRatingStats } from '../graphql/mutations';

//Subscriptions
import { onCreateLaunchRuleForCustomer, onUpdateLaunchRuleForCustomer, onDeleteLaunchRuleByCustomer } from "../graphql/subscriptions";
import { onUpdateConectivity, onDeleteConectivity } from "../graphql/subscriptions";
import { onUpdateConectivityByScope, onDeleteConectivityByScope } from "../graphql/subscriptions";
import { onUpdateConectivityByCompany, onDeleteConectivityByCompany } from "../graphql/subscriptions";
import { onUpdateInvitationDataByCustomer, onDeleteInvitationDataByCustomer } from "../graphql/subscriptions";
import { onUpdateScheduledConectivityByCustomer, onDeleteScheduledConectivityByCustomer } from "../graphql/subscriptions";
import { onCreateMessagePacketByUser, onUpdateMessagePacketByUser, onDeleteMessagePacketByUser } from "../graphql/subscriptions";

//Utils
import { compareByLabel, compareRandomly, compareByCreatedAt, compareByLaunchDate, compareByNextLaunchDateISO, sortMessages,  } from "../utils/generalUtils";
import { compareScheduledConectivitiesByClosingDate, compareScheduledConectivitiesByCategory, expiredConectivity, compareConectivitiesByExpirationDate,
		 setConectivityImageUrlByCategory,  
		 compareConectivitiesByTitle, setHeaderColorByCategory} from "../utils/conectivityUtils";
import {NOW, NOW_STRING, ONE_WEEK_AGO_STRING, ONE_YEAR_AGO, ONE_MONTH_AGO_STRING, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3,
		buildRecurrenceString, getrecurrenceRuleLaunchDates, getRecurrenceRuleNextLaunchDate,isRecurrenceRuleLaunchToday, constructRecurrenceRule, 
		} from "../utils/dateTimeUtils";
import { queryDataTableWithPagination, fetchActiveScheduledConectivitiesByCustomer, fetchActiveScheduledConectivities} from "../utils/databaseUtils";
import { doesConectivityHaveInvitationForUser } from "../utils/invitationUtils";
import { getUserInitials } from "../utils/userAndTeamUtils";
import { invokeAPI }  from "../utils/databaseUtils";

import moment from 'moment';

const API = generateClient();	//Amplify V6
const ConectivityContext = React.createContext();
const ConectivitiesProvider = ({ children }) => {

   //Authentication context
	const {
			authState,
			currentUser, 
			isSuperAdmin,
			isAdmin,
			permissionEditor,  
			triggerFetchScheduledConectivities,      
	  } = useContext(AuthContext);

   // Customer context
	const {
			customers, users, usersOptions, selectedCustomerOptions 
	} = useContext(CustomerContext);  

	//state variables
	const [launchRulesLoaded, setLaunchRulesLoaded] = useState(false);
	const [conectivities, setConectivities] = useState([]);
	const [conectivitiesReadyForLaunch, setConectivitiesReadyForLaunch] = useState([]);
	const [conectivitiesCommunity, setConectivitiesCommunity] = useState([]);
	const [conectivitiesForDashboard, setConectivitiesForDashboard] = useState([]);
	const [conectivitiesReadyForLaunchOptions, setConectivitiesReadyForLaunchOptions] = useState([]);
	const [launchRules, setLaunchRules] = useState([]);
	const [launchRulesInvolvingThisUser, setLaunchRulesInvolvingThisUser] = useState([]);     //array of Launch Dates involving this user
	const [numberOfLaunchesToday, setNumberOfLaunchesToday] = useState(0);
	const [launchRulesCreatedByThisUser, setLaunchRulesCreatedByThisUser] = useState([]);  
	const [scheduledConectivities, setScheduledConectivities] = useState([]);
	const [scheduledConectivitiesToDisplay, setScheduledConectivitiesToDisplay] = useState([]);
	const [upcomingLaunchesCreatedByThisUser, setUpcomingLaunchesCreatedByThisUser] = useState([]);     //array of  Launch Rules for which this user is an invitee
	const [upcomingLaunches, setUpcomingLaunches] = useState([]);                                       //array of  Launch Rules for the entire company
	const [templateConectivities,setTemplateConectivities] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [imageOptions, setImageOptions] = useState([]);                           //These two state variables for images are no longer used
	const [filteredImageOptions, setFilteredImageOptions] = useState([]);           //Options filtered based on category selection
	const [triggerScheduledConectivitiesReload, setTriggerScheduledConectivitiesReload] = useState(false);

	//Invitations state data
	const [invitationToUpdate, setInvitationToUpdate] = useState(null);
	
	//Messages state data - used to track current view of Chat component
	const [messagesToShow, setMessagesToShow] = useState([]);
	const [chatWindowID, setChatWindowID] = useState(null);       //Holds the ID of the object associated with the Chat window; typically the LaunchRuleID of the LR to which the CHAT window corresponds or perhaps the SpotlightID
	

	//Get conectivity data upon opening this page, return an array of existing conectivities, if any
	useEffect(() => {
		if (authState !== "signedin") {
			//Initialize vars on auth change that is NOT the user signing in
			setConectivities([]);
			setLaunchRules([]);
			setLaunchRulesLoaded(false);
			return;
		} else {
			fetchConectivities();  
			fetchLaunchRules();
		}
	}, [authState]);


	//on a user auth or customer change, grab the conectivity data and related transactions
	async function fetchConectivities() {
 	   
		try {

			var conectivityData = [];
			var tempConectivities= [];
			let queryParams;

		
			 //NEW 6.16.2024 - For Management Portal, grab ALL conectivities for ALL customers as we have to review & approve shared ones
			if (process.env.IS_MANAGMENT_PORTAL) {
			   
					// grab ALL Conectivities
					queryParams = { limit: 5000, };
					tempConectivities =  await queryDataTableWithPagination(listConectivities, "listConectivities", queryParams);
					if (DEBUG_MODE >= 2) console.log("Fetched SuperAdmin Conectivities", tempConectivities);

				  
			} else {
				
				//First, grab ALL GLOBAL Conectivities that make up the Conectere portfolio
				queryParams = { conectivityScope: "GLOBAL", limit: 5000, };
				const tempAdminConectivities =  await queryDataTableWithPagination(getConectivitiesByScope, "getConectivitiesByScope", queryParams);
				if (DEBUG_MODE >= 2) console.log("Fetched Global Conectivities:", tempAdminConectivities);                

				//Now, grab the CUSTOMER_SHARED Conectivities, i.e., conectivities shared by any company
				queryParams = { conectivityScope: "CUSTOMER_SHARED", limit: 1000, };
				const customerSharedConectivities =  await queryDataTableWithPagination(getConectivitiesByScope, "getConectivitiesByScope", queryParams);
				if (DEBUG_MODE >= 2) console.log("Fetched CUSTOMER_SHARED Conectivities, not yet filtered", customerSharedConectivities);                

				// var filteredCustomerSharedConectivities = customerSharedConectivities.filter(conectivity => (conectivity.subscribingCustomers.includes(currentUser.customerID) && (conectivity.createdByCustomerID !== currentUser.customerID)));

				//Now, grab any Conectivities that were created by THIS Customer, regardless of conectivityScope
				queryParams = { createdByCustomerID: currentUser.customerID };
				const customerConectivities =  await queryDataTableWithPagination(getConectivitiesByCustomer, "getConectivitiesByCustomer", queryParams);
				if (DEBUG_MODE >= 2) console.log("Fetched Customer-Specific Conectivities for this Customer BEFORE filter:", currentUser.customerID, customerConectivities);

				//Reduce to any customer-specific that are private or installed from the community; this will exclude those that are CUSTOMER_SHARED to the Conectere community
				var customerSpecificConectivities  = customerConectivities.filter(conectivity => (conectivity.conectivityScope === "CUSTOMER_SPECIFIC" || conectivity.conectivityScope === "INSTALLED_FROM_COMMUNITY"));
				if (DEBUG_MODE >= 2) console.log("Fetched Customer-Specific Conectivities for this Customer:", currentUser.customerID, customerSpecificConectivities);
				
				tempConectivities= tempAdminConectivities.concat(customerSharedConectivities, customerSpecificConectivities);  //Add customer-shared and customer-specific tempConectivities to overall array of tempConectivities                 
				// tempConectivities= tempAdminConectivities.concat(filteredCustomerSharedConectivities, customerSpecificConectivities);  //Add customer-shared and customer-specific tempConectivities to overall array of tempConectivities                 
	
				if (DEBUG_MODE >= 2) console.log("Formed GLOBAL + CUSTOMER SHARED + CUSTOMER_SPECIFIC Conectivities:", tempConectivities);
			}
			
			//Generate template array and template options from fetched conectivities, prior to reducing to just PUBLISHED
			var tempTemplateConectivities = tempConectivities.filter(conectivity => conectivity.isTemplate === true);
			setTemplateConectivities(tempTemplateConectivities);
	
			const tempTemplateOptions = [];
			
			tempTemplateOptions.push({
				id:0,
				label:"Blank",
				image:"",
				value:0
			});
	
			  for(var i = 0; i<tempTemplateConectivities.length; i++){
				 
				  tempTemplateOptions[i+1] = {
					id:tempTemplateConectivities[i].id,
					label:tempTemplateConectivities[i].title.replace("Template - ",""), //Remove "template" from the title for our icons
					image: tempTemplateConectivities[i].image,
					category: tempTemplateConectivities[i].category,
					value:i+1 //PLUS ONE because we already pushed the BLANK object
				  };
				  
				//   if (DEBUG_MODE >= 2) console.log('Temp template option', i, tempTemplateOptions[i]);
			  }
			  
			  setTemplateOptions(tempTemplateOptions);
		  
			//   if (DEBUG_MODE >= 2) console.log('Built template dropdown', tempTemplateOptions);
	
			//Filter Conectivities to only those that are PUBLISHED or conectivities created by this company if this user is not a SuperAdmin or a Conectere Editor so as to remove unpublished drafts that were fetched as GLOBAL
			//Note - this defines the set of conectivities that will be available to the company admin, in the dashboard, etc.
			//Superadmin and editors will be able to review conectivities across all customers
			var filteredConectivities = [];
			
			if (!isSuperAdmin && !permissionEditor)
				filteredConectivities = tempConectivities.filter(conectivity => conectivity.publicationStatus === "PUBLISHED" || conectivity.createdByCustomerID === currentUser.customerID);
			else
				filteredConectivities = [...tempConectivities];
	
			if (DEBUG_MODE >= 2) console.log("Filtered Conectivities:", filteredConectivities);
			
			//Sort and save the Conectivities
			filteredConectivities.sort(compareConectivitiesByTitle);
			setConectivities(filteredConectivities);

		} catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching page data', err); }

		// compute analytics data for conectivities - HANDLED BY useEffect
		// calcConectivityAnalytics (tempConectivities, ccTransactions);   
 
	   try {
		 const imageData = await invokeAPI(listImages, "listImages");
		 if (DEBUG_MODE >= 2) console.log("Retreived categories", imageData);

		  const tempImageOptions = [];
		  for(var i = 0; i<imageData.items.length; i++){
			  tempImageOptions[i] = {
				id:imageData.items[i].id,
				url:imageData.items[i].url,
				label:imageData.items[i].name,
				value:i,
				category:imageData.items[i].category.name,    //Add category name for sorting
				  
			  };
			  
			//   if (DEBUG_MODE >= 2) console.log('Temp option', i, tempImageOptions[i])
		  }
		  tempImageOptions.sort(compareByLabel);
		  setImageOptions(tempImageOptions);
		  setFilteredImageOptions (tempImageOptions);
		  
		 //  if (DEBUG_MODE >= 2) console.log('Built image dropdown imageOptions', tempImageOptions);
		
		} catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching images', err); }
		
		//  setShowSpinner(false); //Hide spinners
   
	 }        

	//
	//Main Effect for responding to changes to the master conectivities list, such as changes by way of app-sync subscriptions, and regenerating conectivities for various pages
	//
	
	useEffect(() => {
		//Safety check
		if (authState !== "signedin") return;
		
		//Conectivities to process?
		if (!conectivities) {if (DEBUG_MODE) console.error("Error - user signed in and no conectivities"); return}

		//Distill Conectivities ready for launchpad in THIS company. Must be a non-template, PUBLISHED Global conectivity from the Conectere portfolio or a Private one created by this company AND must be ACTIVE
		var tempConectivities = conectivities.filter(conectivity => (
			(!conectivity.inactiveForCustomers || conectivity.inactiveForCustomers.some(entry => entry === currentUser.customerID) === false) &&
			(!conectivity.isTemplate) &&
			((conectivity.conectivityScope === "GLOBAL" && conectivity.publicationStatus === "PUBLISHED") || 
			(conectivity.createdByCustomerID === currentUser.customerID && conectivity.publicationStatus === "PRIVATE"))));
		if (DEBUG_MODE >= 2) console.log("Conectivities Ready for Launch", tempConectivities);
		setConectivitiesReadyForLaunch(tempConectivities);

		//Next, build options for selecting a launchable conectivity from a drop-down
		const tempOptions = [];                  //Options including all users
		for(var i = 0; i<tempConectivities.length; i++){
				tempOptions.push({
				id:tempConectivities[i].id,
				value:tempConectivities[i].id,
				label:tempConectivities[i].title,
				conectivityID: (tempConectivities[i] ? tempConectivities[i].id : ""),
				title: (tempConectivities[i] ? tempConectivities[i].title : "None"),
				shortLabel: (tempConectivities[i] ? tempConectivities[i].category.label : ""),
				image: (tempConectivities[i] ? tempConectivities[i].image : ""),
				category: (tempConectivities[i] ? tempConectivities[i].category.name : ""),
				conectivityImageURL: (tempConectivities[i] ? setConectivityImageUrlByCategory(tempConectivities[i].category.label, true) : ""),                          
				});
			}
		if (DEBUG_MODE >= 2) console.log("Built conectivity options for multi-select",tempOptions);
		setConectivitiesReadyForLaunchOptions(tempOptions);            
		

		//Distill Community Conectivities, i.e., conectivities shared by any company and ready for use
		//Must be of scope 'CUSTOMER_SHARED' and then either a published status or authored by this particular user, so they can edit / delete in the Community
		tempConectivities = conectivities.filter(conectivity => (
			(conectivity.conectivityScope === "CUSTOMER_SHARED") && 
			(conectivity.publicationStatus === "PUBLISHED" || (conectivity.authorID === currentUser.id))
			));
		if (DEBUG_MODE >= 2) console.log("Community Conectivities", tempConectivities);
		setConectivitiesCommunity(tempConectivities);

		//Distill Dashboard Conectivities, i.e., conectivities to show in admin control center dashboard. If not SuperAdmin, we will exclude the  conectivities in the Community
		if (isSuperAdmin) tempConectivities = [...conectivities];
		else tempConectivities = conectivities.filter(conectivity => conectivity.conectivityScope !== "CUSTOMER_SHARED");
		if (DEBUG_MODE >= 2) console.log("Dashboard Conectivities", tempConectivities);
		setConectivitiesForDashboard(tempConectivities);

		//Update our launch rules to include conectivity information for the conectivity to be launched
		dynamicallyInjectConectivityDataIntoLaunchRules(conectivities);
 
	}, [conectivities]);


	//on a user auth  change, load the launch rules for this specific user
	async function fetchLaunchRules() {

		if (DEBUG_MODE >= 2) console.log("Fetching launch rules", currentUser);
		
		// var launchRuleData = [];
		var tempLaunchRules= [];

	   if (isSuperAdmin && (selectedCustomerOptions === null || selectedCustomerOptions?.value===0)) {

			 if (DEBUG_MODE >= 2) console.log("Fetching page data for ALL customers.  No Selected Customer.");

				const queryParams = { limit: 5000, };
				tempLaunchRules =  await queryDataTableWithPagination(listLaunchRules, "listLaunchRules", queryParams);
				if (DEBUG_MODE >= 2) console.log("Fetched Launch Rules for ALL customers:", tempLaunchRules);                

	   } else {
		   
		   //Grab launch rules for this particular company
			const queryParams = { customerID: currentUser.customerID };
			tempLaunchRules =  await queryDataTableWithPagination(getLaunchRulesByCustomer, "getLaunchRulesByCustomer", queryParams);
			if (DEBUG_MODE >= 2) console.log("Fetched Launch Rules:", tempLaunchRules);                
			
	   }
		
		tempLaunchRules.sort(compareByNextLaunchDateISO);
		setLaunchRules(tempLaunchRules);
		setLaunchRulesLoaded(true);
	}

	function dynamicallyInjectConectivityDataIntoLaunchRules(conectivities) {   
		
		const tempLaunchRules = [...launchRules];
		
		//For each non-expired launch rule, let's dynamically add the next launch date info so we don't need to look it up later
		if (DEBUG_MODE >= 3) console.log("INJECTING CONECTIVITY DATA INTO MESSAGE PACKETS", conectivities);
		for (var i=0; i < tempLaunchRules.length; i++) {
			tempLaunchRules[i] = dynamicallyAddAdditionalLaunchRuleData(conectivities,tempLaunchRules[i], currentUser.timeZoneCode);
		}

		tempLaunchRules.sort(compareByNextLaunchDateISO);
		setLaunchRules(tempLaunchRules);

		if (DEBUG_MODE >= 3) console.log("Launch rules after adding next launch date and dynamic data", tempLaunchRules);
	}
		

	//This function inserts relevent conectivity data into the launch rule object
	function dynamicallyAddAdditionalLaunchRuleData(conectivities,launchRule, timeZoneCode) {

		 var tempLaunchRule = {...launchRule};
		 
		 //Add Conectivity Data to this Launch Rule, if we have loaded the conectivities already
		 if (conectivities) {
			 const thisConectivity = conectivities.find(conectivity => conectivity.id === tempLaunchRule.conectivityID);
			 if (thisConectivity) {
				 tempLaunchRule.title = (thisConectivity ? thisConectivity.title : "None");
				 tempLaunchRule.shortLabel = (thisConectivity ? thisConectivity.category.label : "");
				 tempLaunchRule.image = (thisConectivity ? thisConectivity.image : "");
				 tempLaunchRule.category = (thisConectivity ? thisConectivity.category.name : "");
				 tempLaunchRule.conectivityImageURL = (thisConectivity ? setConectivityImageUrlByCategory(thisConectivity.category.label, true) : "");            
				 tempLaunchRule.color = (thisConectivity ? setHeaderColorByCategory(thisConectivity.category.label) : COLOR_BLUE_TEXT);  
				 tempLaunchRule.textColor = (thisConectivity ? setHeaderColorByCategory(thisConectivity.category.label) : COLOR_BLUE_TEXT); 
				 tempLaunchRule.textColor = "#FFFFFF"; 
			 }
		 }

		 //Add calendar informtation to this launch rule, if not already added present
		 tempLaunchRule.currentUserID = currentUser.id;   //Pass along the ID of the current user so we can render the calendar events according to RSVPs
		 
		 if (!tempLaunchRule.nextLaunchDateISO) {
			 tempLaunchRule.nextLaunchDateISO = getRecurrenceRuleNextLaunchDate(launchRule);
			 tempLaunchRule.recurrenceString = buildRecurrenceString(tempLaunchRule);     
			 
			 //Add attributes for compatibility with Full Calendar React component
			 // if (tempLaunchRule.launchRepeatOption === 1) {       //For FULL CALENDAR, only include if not an RRULE
				 tempLaunchRule.start = tempLaunchRule.eventStartDateTime;
				 tempLaunchRule.end = tempLaunchRule.eventEndDateTime;
			 // }
			 tempLaunchRule.allDay = tempLaunchRule.allDayEvent || !(moment(tempLaunchRule.eventStartDateTime).isSame(moment(tempLaunchRule.eventEndDateTime),'day'));
			 tempLaunchRule.editable = (currentUser.id === launchRule.senderID);
 
			 if (tempLaunchRule.launchRepeatOption > 1) {
				 tempLaunchRule.rrule = constructRecurrenceRule({launchRule:tempLaunchRule, calendarType:"FULLCALENDAR"});     
			  }
			 
			 if (timeZoneCode ) tempLaunchRule.timeZone = timeZoneCode;
		 }
		 return tempLaunchRule;
	}        
		

/*  5.22 - REMOVED THIS AT LEAST TEMPORARILY.  THIS SEEMED TO BE THE CAUSE OF APP SYNC TERMINATING ALL SUBSCRIPTIONS
			TOOK DAYS TO FIND THIS PROBLEM 

	//Effect handler for updating the date/time of the events for the loaded Launch Rules
	useEffect(() => {
		updateCalendarEventsForLaunchRules();
	}, [authState, launchRules]);   

	async function updateCalendarEventsForLaunchRules() {
		
		
		if ( !launchRules || authState !== "signedin" || !currentUser) {
			if (DEBUG_MODE >= 2) console.log("useEffect SKIPPED for updating calendar events for displayed Conectivities", authState, currentUser, launchRules);
			return;
		}

		if (DEBUG_MODE >= 2) console.log("useEffect Invoked for updating calendar events for displayed Conectivities");
 
		//Update user's launch rules based on remote calendars
		if (launchRules.length !== 0) {

			if (DEBUG_MODE >= 2) console.log("Calendar Event Update called", launchRules);
		  
			//Update the date for each of the conectivitie being displayed to the user
			
			if (currentUser.googleCalendarEnabled || currentUser.ms365CalendarEnabled || currentUser.msteamsCalendarEnabled  || currentUser.msoutlookCalendarEnabled  || currentUser.icloudCalendarEnabled) {
	
				if (DEBUG_MODE >= 2) console.log("Updating datesTimes from remote calendar");
	
				for (var i=0; i<launchRules.length; i++) {
	
					 //Valid calendarEventID for event on 3rd party calendar?  If so, update Conectere's date/time for the invitation to match
					 if (launchRules[i].calendarEventID) {
						if (DEBUG_MODE >= 2) console.log("Invoking calendar event fetch", launchRules[i].senderAvatarName, launchRules[i].calendarEventID, launchRules[i].calendarEventID, launchRules[i].id, launchRules[i].createdAt);
						await fetchCalendarEvent(launchRules[i].calendarEventID, launchRules[i].id);
					 }
				}
				
			}
		}

		if (DEBUG_MODE >= 2) console.log("Reloading Scheduled Conectivities");
		
		//Regardless, trigger a load of Scheduled Conectivities, which will trigger selection for display - THIS WAS DUMP AS MESSAGE PACKETS NOT USED FOR SCHEDULED CONECTIVITY SELECTION - USES INVITATIONS
		setTriggerScheduledConectivitiesReload(!triggerScheduledConectivitiesReload);
	}

*/
	
	// Fetch scheduled (active) conectiviteis from DB upon login and any trigger, which may be used by other Context's such as a wallet change
	useEffect(() => {
		
		//Fetch conectivity context data
		fetchScheduledConectivities();    
	},[authState, triggerFetchScheduledConectivities]);

  
	async function fetchScheduledConectivities () {
		
		
		//Safety check - user must be signedin either via auth transition or via trigger from subscriptions
		if (authState !== "signedin" || !currentUser || !currentUser.customerID ) {
			if (DEBUG_MODE >= 2) console.log("Safety check not satisfied when fetching Scheduled Conectivities", authState, currentUser);
			return;
		}
		
		if (DEBUG_MODE >= 2) console.log("Fetching Scheduled Conectivities", authState, triggerFetchScheduledConectivities);
		
		var tempScheduledConectivities = [];
 
	   if (process.env.IS_MANAGMENT_PORTAL) {
			if (DEBUG_MODE >= 2) console.log("Fetching Scheduled Conectivities for ALL companies");
			tempScheduledConectivities = await fetchActiveScheduledConectivities();

	   } else {
			if (DEBUG_MODE >= 2) console.log("Fetching Scheduled Conectivities for THIS company:", currentUser.customerID);
			tempScheduledConectivities = await fetchActiveScheduledConectivitiesByCustomer(currentUser.customerID);
	   }        
		
		if (DEBUG_MODE >= 2) console.log("Fetched Scheduled Conectivities", tempScheduledConectivities);
		tempScheduledConectivities.sort(compareScheduledConectivitiesByClosingDate);
		setScheduledConectivities(tempScheduledConectivities);       
	}    



   //Effect handler for selecting conectivities for display to the user
   //Select new ones to display upon a change to the (master) scheduled conectiviites or to the current user's progress data
   useEffect(() => {
	  if (DEBUG_MODE >= 2) console.log("useEffect Invoked for selecting which conectivities to display");
	  selectScheduledConectivitiesToDisplay();
	  }, [scheduledConectivities]);    //Reload and select Scheduled Conectivities on an update to the master scheduled conectivities


   //Select Sheduled Conectivities for a given user based on past transactions and progress data
   async function selectScheduledConectivitiesToDisplay() {

	  //Safety check
	  if (!currentUser  || !scheduledConectivities || scheduledConectivities.length===0) return;
	  if (DEBUG_MODE >= 2) console.log("Selecting new Scheduled Conectivities to display: ", scheduledConectivities);

	  try {

		 //Fetch recent transactions for this user so we can use the info to remove any scheduled connectivities already finished by this user IF the user is not an invitee
		 const queryParams = {
			userID: currentUser.id,                                              //Primary Key
			createdAt: {between: [ ONE_WEEK_AGO_STRING(), NOW_STRING() ]},       //Secondary Key
		 };

		 var tempCCTransactions =  await queryDataTableWithPagination(getCCTransactionsByUserByDate, "getCCTransactionsByUserByDate", queryParams);
		 if (DEBUG_MODE >= 2) console.log("Filtering based on user transactions: ", tempCCTransactions);
		 tempCCTransactions = tempCCTransactions.filter(transaction => (transaction.transactionType === "COMPLETED_CONECTIVITY" || transaction.transactionType === "REJECTED_CONECTIVITY"));

		 // (1) exclude any SC that is expired OR the user already completed
		 let SCsToConsider = scheduledConectivities.filter((sc) => {
			if (expiredConectivity(sc)) return false;    //Expired?
			if (tempCCTransactions.some(transaction => (transaction.scheduledConectivityID === sc.id))) return false; //Already complete? - updated 8.20.2024
			return true;
		 });

		 if (DEBUG_MODE >= 2) console.log("SCs after excluding expired or already finished conectivities: ", SCsToConsider);

		 // Build up a new array of scheduled connectivities for this user
		 // (2) Init to include any SC to which the user has an invitation
		 let myTempScheduledConectivities = SCsToConsider.filter((sc) => {
			if (doesConectivityHaveInvitationForUser(sc, currentUser.id, false)) return true;    //User invited?
			return false;
		 })
		 if (DEBUG_MODE >= 2) console.log("Initialized My SCs to include any SC to which the user has an invitation: ", myTempScheduledConectivities);

		// (3) Add any SC for a conectivity that is a special event having a conectivityID NOT ALREADY included in the list of SCs
		for (const scToConsider of SCsToConsider){
			if (scToConsider.isSpecialEvent && !myTempScheduledConectivities.some(sc => sc.conectivityID === scToConsider.conectivityID)) myTempScheduledConectivities.push(scToConsider);   //Add Special Event if not already present
		 }
		 if (DEBUG_MODE >= 2) console.log("Updated My SCs after INCLUDING special events: ", myTempScheduledConectivities);

		 //Reduce down to a remaining set of SCs not already selected as a special event or one w/ an invite for the user.  We'll use this to fill in the categories
		 SCsToConsider = SCsToConsider.filter(sCon => {
			return (!myTempScheduledConectivities.some(sc => sc.id === sCon.id)); //Include if not already selected in the SC's for the user
		 });
		  if (DEBUG_MODE >= 2) console.log("Remaining general conectivities for consideration", SCsToConsider);
		 
		 //Sort the remaining conectivities 
		 SCsToConsider.sort(compareByCreatedAt);    //Add so as to be deterministic for the user on a refresh, etc.
				  
				   
		 // PROCESS NON-SELECTED SCs TO FILL OUT EACH CATEGORY
		 for (const scToConsider of SCsToConsider) {
			let displaySCtoUser = true;       //Flag for including this SC
			// if (DEBUG_MODE >= 2) console.log("Processing SC:",scToConsider.id,scToConsider.conectivity.category.label,scToConsider);

			//Exclude this SC?
			if (CONECTERE_CONFIG_DATA.DISPLAY_ONE_CONECTIVITY_PER_CATEGORY && progressRingClosed({category:scToConsider.conectivity.category.label, user:currentUser})) {
				displaySCtoUser = false; 
			//    if (DEBUG_MODE > 1) console.log("Skipping SC as that ring is already closed")
			} else if (myTempScheduledConectivities.some((sc) => sc.conectivity.category.label === scToConsider.conectivity.category.label)) {
				displaySCtoUser = false; 
			//    if (DEBUG_MODE > 1) console.log("Skipping SC as another SC of that category has already been included")        
			}
			
			//If not excluded for any of the reasons above, add to users's scheduled connectivities if no reason to exlude
			if (displaySCtoUser ) {                 
			   myTempScheduledConectivities.push(scToConsider);
			//    if (DEBUG_MODE >= 3) console.log("Adding scheduled Conectivity", scToConsider.conectivity.category.label,  scToConsider);          
			} //End !DoNotAdd
		 } // END For each SC

		  
		 //Generated the SCs for display array
		 if (DEBUG_MODE >= 2) console.log("Conectivities after filter ", myTempScheduledConectivities);
		 
		 //Sort the array based on expiration
		 myTempScheduledConectivities.sort(compareConectivitiesByExpirationDate);
		 
		 //New 12.28.2022 - Pre-process each loaded Scheduled Conectivity prior to rendering so all user data in the invitations is current
		 for (var l=0; l < myTempScheduledConectivities.length; l++) {
			   
			   if (myTempScheduledConectivities[l].invitations && myTempScheduledConectivities[l].invitations.items) {
				  
				  //Process each invitation of this scheduled conectivity
				  for (var k=0; k < myTempScheduledConectivities[l].invitations.items.length; k++) {
					 //Update the invitation itself
					 preProcessInvitation(myTempScheduledConectivities[l].invitations.items[k]);  
				  }
			   }       
		 }             
			if (DEBUG_MODE >= 2) console.log("Sheduled Conectivities selected for display; after preprocessing to update user data in invitations", myTempScheduledConectivities);
			
			//Make a new array so we absolutely force a React update
			const tempNewArrayOfScheduledConectivities = [...myTempScheduledConectivities];
			tempNewArrayOfScheduledConectivities.sort(compareScheduledConectivitiesByCategory);

			//If debug mode, make the 1st scheduled conectivity a special event to display badge
			if (DEBUG_MODE > 1 && tempNewArrayOfScheduledConectivities && tempNewArrayOfScheduledConectivities.length > 0) {
				tempNewArrayOfScheduledConectivities[0].conectivity.isSpecialEvent = true;
			}
			   
			//Update persistent state data with local data;
			setScheduledConectivitiesToDisplay(tempNewArrayOfScheduledConectivities);
			
	  } catch (err) { if (DEBUG_MODE >= 1) console.log('error selecting Scheduled Conectivities to display', err); }
   
   }

   function progressRingClosed({category, user}) {
 
	  //Safety check
	  if (!category || !user || !user.userProgressData) {
		 if (DEBUG_MODE > 1) console.log("Error - improper params to check ring closure", category, user);
		 return false;
	  }
	  switch (category) {
		 case "STRESS":
			return (user.userProgressData.stressPeriodCoinsEarned >= user.userProgressData.stressPeriodCoinGoal);
		 case  "SOCIAL":
			return (user.userProgressData.socialPeriodCoinsEarned >= user.userProgressData.socialPeriodCoinGoal);
		 case "TEAM":
			return (user.userProgressData.teamPeriodCoinsEarned >= user.userProgressData.teamPeriodCoinGoal);
		 case "PERSONAL":
			return (user.userProgressData.individualPeriodCoinsEarned >= user.userProgressData.individualPeriodCoinGoal);
		 case "TUTORIAL":
			//Do NOT add because we only add Tutorials if there is an inviation for THIS user
			return (true);
		 default:
			if (DEBUG_MODE > 1) console.log("Error - unrecogized category", category)
			return false;
	  }
   }

	//Update Scheduled Conectivity with current user data prior to rendering
	function preProcessInvitation(invitation) {
			
		if (DEBUG_MODE >= 3) console.log("Preprocessing invitation", invitation);
		
		 //Populate the avatar data based on user IDs to accomodate any user profile changes, such as names or pictures
		 var tempUser = usersOptions.find(user => user.id === invitation.senderID);    //Find SENDER
		 if (tempUser) {
			 invitation.senderAvatarUrl = tempUser.avatarUrl;     
			 invitation.senderAvatarName = tempUser.name;
			 invitation.senderAvatarInitials = tempUser.avatarInitials;
			 
			// 	 if (DEBUG_MODE >= 2) console.log("Updated Invitation Sender", tempUser, invitation.senderID, usersOptions);
		 } else {
			 if (DEBUG_MODE >= 2) console.log("PREPROCESSING ERROR - NO USER OPTION FOR INVITATION SENDER", invitation.senderID, usersOptions);
		 }
		 
		//Populate data for each recognized employee based on ID
		if (invitation.invitees && invitation.invitees.items) {
			
			
			for (var j=0; j < invitation.invitees.items.length; j++) {

				const invitee = invitation.invitees.items[j];   //Point at that invitee object
				
		 	  //   if (DEBUG_MODE >= 2) console.log("Updating Recipient", invitee, usersOptions);

				  //Populate the avatar data based on user IDs to accomodate any user profile changes, such as names or pictures
				 tempUser = usersOptions.find(user => {
					if (user) return (user.id === invitee.inviteeID);
				 });    
					 
				 if (tempUser) {
					 invitee.inviteeAvatarUrl = tempUser.avatarUrl;     
					 invitee.inviteeAvatarName = tempUser.name;
					 invitee.inviteeAvatarInitials = tempUser.avatarInitials;

			 	  //   if (DEBUG_MODE >= 2) console.log("Updated Recipient", tempUser);

				 } else {
					 if (DEBUG_MODE >= 2) console.log("ERROR - NO USER OPTION");
				 }
			}
		}
	}
	
	//Function to test for the case where the user received another invite after completing an unexpired conectivity, in which case we will show it to the user
	function receivedInviteAfterCompletingConectivity(invitationsWithThisUser, transactionsCompletingThisConectivity) {
		
		if (invitationsWithThisUser.length === 0) return false;               //No pertinent invitations
		if (transactionsCompletingThisConectivity.length === 0) return false; //No pertinent transactions
		
		const mostRecentTransaction = transactionsCompletingThisConectivity.reduce((prev, curr) => prev.createdAt > curr.createdAt ? prev : curr); //Find the invitation with the greatest createdAt date
		
		if (DEBUG_MODE >= 3) console.log("Found most recent transaction for this user for the scheduled conectivity", mostRecentTransaction, transactionsCompletingThisConectivity);
		
		if (invitationsWithThisUser.some(invitation => invitation.createdAt > mostRecentTransaction.createdAt)) {
			if (DEBUG_MODE >= 2) console.log("USER HAS INVITATION MORE RECENT THAN HIS/HER COMPLETION OF THE SCHEDULED CONECTIVITY");
			return true;
			
		} else {
			return false;
			
		}
	}

//
//
//  REACT REFERENCES USED BY Subscriptions of THIS CONTEXT
//
//


	const scheduledConectivitiesRef = useRef();  
	const scheduledConectivitiesToDisplayRef = useRef();
	const conectivitiesRef = useRef();  
	const launchRulesRef = useRef();  
	const invitationToUpdateRef = useRef();
	const messagesToShowRef = useRef();
	const chatWindowIDRef = useRef();
	
	useEffect(() => {
	  scheduledConectivitiesRef.current = scheduledConectivities; 
	  scheduledConectivitiesToDisplayRef.current = scheduledConectivitiesToDisplay;  //Track the scheduled conectivities we are currently displaying
	  conectivitiesRef.current = conectivities; 
	  launchRulesRef.current = launchRules; 
	  invitationToUpdateRef.current = invitationToUpdate; //React ref for the particular invitation currently being processed, if any, by the user - including showing the chat window
	  messagesToShowRef.current = messagesToShow; //React Ref to messages currently being viewed, if any
	  chatWindowIDRef.current = chatWindowID;  //ID of the object being viewed by our ChatWindow (like the LaunchRuleID or RecognitionID), if any
	});  //Update on any state change  
	
	
//
// SUPER ADMIN SUBSCRIPTIONS TO ANY CHANGES TO THE CONECTIVITY PORTFOLIO

	const [conectivityUpdateSubscriptionSetUp, setConectivityUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [conectivityDeleteSubscriptionSetUp, setConectivityDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	// NOTE - NEW CONECTIVITIES ARE HANDLED VIA SUBSCRIPTIONS TO UPDATE CONECTIVITY RECORDS, WHICH ARE TRIGGERED AT THE END OF THE CREATE PROCESS

	useEffect(() => {
		if ((isSuperAdmin || permissionEditor) && !conectivityUpdateSubscriptionSetUp  && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up SuperAdmin & Editors subscription for UPDATES to ANY conectivity. ");
			
			try {
				var subscriptionParams = {
						query:onUpdateConectivity,
					 };
			   const subscription = API.graphql(subscriptionParams)
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 1) console.log("App Sync =>  Conectivity UPDATE data received via SuperAdmin subscription",messageData);
							if (messageData.data.onUpdateConectivity !== null) {
								
								const updatedConectivity = messageData.data.onUpdateConectivity;
								
								if (DEBUG_MODE >= 2) console.log("Conectivity UPDATE extracted from message",updatedConectivity);
	
								const tempConectivities = [...conectivitiesRef.current];   //Get our state data for existing conectivityGlobals
	
								 if (DEBUG_MODE >= 2) console.log("Current conectivities",tempConectivities);
								 
								 const index = tempConectivities.findIndex(conectivity => conectivity.id === updatedConectivity.id);
								 
								 if (index > -1) {
	
									//Update system-wide state data with the UPDATED conectivity
									//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
									tempConectivities[index] = updatedConectivity;    //Replace the existing conectivity with the updated one just received
									setConectivities(tempConectivities);
									
									if (DEBUG_MODE >= 2) console.log("Conectivities after UPDATE from Subscription", tempConectivities);
									
								 } else {
									 if (DEBUG_MODE >= 2) console.log("No matching conectivity found; CREATING new conectivity");
									tempConectivities.push({...updatedConectivity});    //Add the new conectivity
									tempConectivities.sort(compareConectivitiesByTitle);
									
									//Update system-wide state data with the new conectivitys
									setConectivities(tempConectivities);
									 
								 }

							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("ERROR processing conectivity UPDATE subscription message",messageData);
	
						}
					},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription ERROR", error, subscription)}
				});
				
				setConectivityUpdateSubscriptionSetUp(true);
				
			   return () => {
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Conectivity");
					subscription.unsubscribe();
					setConectivityUpdateSubscriptionSetUp(false);
				};

			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("ERROR setting up conectivity UPDATE subscription message", err);
			}  
		}
	},[authState]); //Call function when a change to authState occurs


   useEffect(() => {
		if ((isSuperAdmin || permissionEditor) && !conectivityDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) { 
			if (DEBUG_MODE >= 2) console.log("Setting up SuperAdmin subscription for DELETE conectivities");
			try {
				var subscriptionParams = {
						query:onDeleteConectivity,
					 };
				 
			   const subscription = API.graphql(subscriptionParams)
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("App Sync => DELETE conectivity data received via SuperAdmin subscription",messageData);
							if (messageData.data.onDeleteConectivity !== null) {
								
								const deletedConectivity = {... messageData.data.onDeleteConectivity };
								if (DEBUG_MODE >= 2) console.log("Deleted conectivity extracted from message",deletedConectivity);
								var tempConectivities = conectivitiesRef.current.filter(element => element.id !== deletedConectivity.id);
								setConectivities(tempConectivities); //Delete state data with newly generated array  
								if (DEBUG_MODE >= 2) console.log("Conectivities after DELETE conectivity", tempConectivities);
							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing DELETE conectivity subscription message",err, messageData);
	
						}
					},
					error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setConectivityDeleteSubscriptionSetUp(true);
			   return () => {
					if (DEBUG_MODE >= 1) console.log("Tearing down SuperAdmin subscription for DELETE Global Conectivity");
					subscription.unsubscribe();
					setConectivityDeleteSubscriptionSetUp(false);
				};

			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error setting up SuperAdmin conectivity DELETE subscription message ", err);
			} 
		}
	},[authState]); //Call function when a change to authState occurs
	
// GLOBAL CONECTIVITY PORTFOLIO
// Conectivities Subscriptions by Scope and Status
// A typical user receives updates for  GLOBAL conectivities that are PUBLISHED
// SuperAdmin receives EVERYTHING - ALL Conectivities using a different subscription type


	//Functions and state data for real-time updates
	const [globalConectivityUpdateSubscriptionSetUp, setGlobalConectivityUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [globalConectivityDeleteSubscriptionSetUp, setGlobalConectivityDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	// NOTE - NEW CONECTIVITIES ARE HANDLED VIA SUBSCRIPTIONS TO UPDATE CONECTIVITY RECORDS, WHICH ARE TRIGGERED AT THE END OF THE CREATE PROCESS

	useEffect(() => {
		if (!isSuperAdmin && !permissionEditor && !globalConectivityUpdateSubscriptionSetUp  && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for global conectivities.  Excludes Super Admin & Editors");
			
			try {
				var subscriptionParams = {
						query:onUpdateConectivityByScope,
						variables:{
							conectivityScope: "GLOBAL",
							publicationStatus: "PUBLISHED",
						}
					 };
			   const subscription = API.graphql(subscriptionParams)
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 1) console.log("App Sync => Global Conectivity UPDATE data received via subscription",messageData);
							if (messageData.data.onUpdateConectivityByScope !== null) {
								
								const updatedConectivity = messageData.data.onUpdateConectivityByScope;
								
								if (DEBUG_MODE >= 2) console.log("Conectivity UPDATE extracted from message",updatedConectivity);
	
								const tempConectivities = [...conectivitiesRef.current];   //Get our state data for existing conectivityGlobals
	
								 if (DEBUG_MODE >= 2) console.log("Current conectivities",tempConectivities);
								 
								 const index = tempConectivities.findIndex(conectivity => conectivity.id === updatedConectivity.id);
								 
								 if (index > -1) {
	
									//Update system-wide state data with the UPDATED conectivity
									//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
									tempConectivities[index] = updatedConectivity;    //Replace the existing conectivity with the updated one just received
									setConectivities(tempConectivities);
									
									if (DEBUG_MODE >= 2) console.log("Conectivities after UPDATE from Subscription", tempConectivities);
									
								 } else {
									 if (DEBUG_MODE >= 2) console.log("No matching conectivity found; CREATING new conectivity");
									tempConectivities.push({...updatedConectivity});    //Add the new conectivity
									tempConectivities.sort(compareConectivitiesByTitle);
									
									//Update system-wide state data with the new conectivitys
									setConectivities(tempConectivities);
									 
								 }

							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("ERROR processing conectivity UPDATE subscription message",messageData);
	
						}
					},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription ERROR", error, subscription)}
				});
				
				setGlobalConectivityUpdateSubscriptionSetUp(true);
			   return () => {
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Conectivity");
					subscription.unsubscribe();
					setGlobalConectivityUpdateSubscriptionSetUp(false);
				};

			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("ERROR setting up conectivity UPDATE subscription message", err);
			}  
		}
	},[authState]); //Call function when a change to authState occurs


   useEffect(() => {
		if (!isSuperAdmin && !permissionEditor && !globalConectivityDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) { 
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE GLOBAL conectivities");
			try {
				var subscriptionParams = {
						query:onDeleteConectivityByScope,
						variables:{
							conectivityScope: "GLOBAL",
							publicationStatus: "PUBLISHED",
						}
					 };
				 
			   const subscription = API.graphql(subscriptionParams)
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("App Sync => DELETE global conectivity data received via subscription",messageData);
							if (messageData.data.onDeleteConectivityByScope !== null) {
								
								const deletedConectivity = {... messageData.data.onDeleteConectivityByScope };
								if (DEBUG_MODE >= 2) console.log("Deleted conectivity extracted from message",deletedConectivity);
								var tempConectivities = conectivitiesRef.current.filter(element => element.id !== deletedConectivity.id);
								setConectivities(tempConectivities); //Delete state data with newly generated array  
								if (DEBUG_MODE >= 2) console.log("Conectivities after DELETE conectivity", tempConectivities);
							 }
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing DELETE conectivity subscription message",err, messageData);
	
						}
					},
					error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
				});
				
				setGlobalConectivityDeleteSubscriptionSetUp(true);
			   return () => {
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE Global Conectivity");
					subscription.unsubscribe();
					setGlobalConectivityDeleteSubscriptionSetUp(false);
				};

			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error setting up Global conectivity DELETE subscription message ", err);
			} 
		}
	},[authState]); //Call function when a change to authState occurs



// CONECTIVITIES BY COMPANY
// Subscriptions for Conectivities By Company so as to provide real-time updates to PRIVATE companies by that the same company
// This allows a user to subscribe to his/her company PRIVATE conectivities

	//Functions and state data for real-time updates
	const [conectivityByCompanyUpdateSubscriptionSetUp, setConectivityByCompanyUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [conectivityByCompanyDeleteSubscriptionSetUp, setConectivityByCompanyDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	// NOTE - THIS IS HANDLED VIA SUBSCRIPTIONS TO UPDATE CONECTIVITY RECORDS, WHICH IS TRIGGERED AT THE END OF THE CREATE PROCESS

	useEffect(() => {
		if (!isSuperAdmin && !permissionEditor && !conectivityByCompanyUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {

		   const  variables = { 
			   createdByCustomerID: currentUser.customerID,
			   publicationStatus: "PRIVATE",
		   };
		   
		  if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES to PRIVATE conectivities", variables);
			
		   const subscription = API.graphql({
				query:onUpdateConectivityByCompany,
				variables: variables,
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("App Sync => UPDATE PRIVATE Conectivity data message received via subscription",messageData);
						
						if (messageData.data.onUpdateConectivityByCompany !== null) {
							
							const updatedConectivityByCompany = messageData.data.onUpdateConectivityByCompany;
							
							if (DEBUG_MODE >= 2) console.log("PRIVATE Conectivity update extracted from message",updatedConectivityByCompany);

							//Safety check
							if (updatedConectivityByCompany.createdByCustomerID === currentUser.customerID) {
								
								const tempConectivities = [...conectivitiesRef.current];   //Get our state data for existing conectivityByCompanys
	
								 if (DEBUG_MODE >= 2) console.log("Current conectivities",tempConectivities);
								 
								 const index = tempConectivities.findIndex(conectivityByCompany => conectivityByCompany.id === updatedConectivityByCompany.id);
								 
								 if (index > -1) {
	
									//Update system-wide state data with the received object
									//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
									tempConectivities[index] = updatedConectivityByCompany;    //Replace the existing conectivity with the updated one just received
									setConectivities(tempConectivities);
									
									if (DEBUG_MODE >= 2) console.log("Conectivities after UPDATE from Subscription", tempConectivities);
								 } else {
									 
									tempConectivities.push({...updatedConectivityByCompany});    //Add the new conectivityGlobal
									tempConectivities.sort(compareConectivitiesByTitle);
									if (DEBUG_MODE >= 2) console.log("No matching company conectivity found; CREATING new private conectivity", tempConectivities);
									
									//Update system-wide state data with the new conectivityGlobals
									setConectivities(tempConectivities);
								 }
							} else {
								if (DEBUG_MODE >= 2) console.log("Did not update conectivities; Created by company not relevant for user", currentUser, updatedConectivityByCompany);
							}
						 }
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing PRIVATE conectivity update subscription message. ",err, messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error. ", error, subscription)}
			});
			
			setConectivityByCompanyUpdateSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setConectivityByCompanyUpdateSubscriptionSetUp(false);
				if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Conectivity");
			};
 
		}
	},[authState]);                     //Call function when a change to authState occurs

   useEffect(() => {
		if (!isSuperAdmin && !permissionEditor && !conectivityByCompanyDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {

		   const variables  = { 
			   createdByCustomerID: currentUser.customerID,
			   publicationStatus: "PRIVATE",
		   };
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for deleted conectivities", variables);
			
		   const subscription = API.graphql({
				query:onDeleteConectivityByCompany,
				variables: variables,
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Delete PRIVATE conectivity data message received via subscription",messageData);
						if (messageData.data.onDeleteConectivityByCompany !== null) {
							
							const deletedConectivityByCompany = {... messageData.data.onDeleteConectivityByCompany };
							if (DEBUG_MODE >= 2) console.log("Deleted conectivity extracted from message",deletedConectivityByCompany);
							var tempConectivities = conectivitiesRef.current.filter(element => element.id !== deletedConectivityByCompany.id);
							setConectivities(tempConectivities); //Delete state data with newly sorted list  

							if (DEBUG_MODE >= 2) console.log("Deleted PRIVATE conectivity", tempConectivities);
							
						 }
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing  DELETE private conectivity subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setConectivityByCompanyDeleteSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setConectivityByCompanyDeleteSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE global Conectivity");
			};
		}
	},[authState]);                     //Call function when a change to authState occurs



//
// Launch Rule Subscriptions
//

	//Functions and state data for real-time updates
	const [launchRuleCreateSubscriptionSetUp, setLaunchRuleCreateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [launchRuleUpdateSubscriptionSetUp, setLaunchRuleUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [launchRuleDeleteSubscriptionSetUp, setLaunchRuleDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

   useEffect(() => {
		if (!launchRuleCreateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for launchRules created for this customer", currentUser);
			
		   const subscription = API.graphql({
				query:onCreateLaunchRuleForCustomer,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("NEW launchRule data received via subscription",messageData);
						if (messageData.data.onCreateLaunchRuleForCustomer !== null) {
							
							var newLaunchRule = {...messageData.data.onCreateLaunchRuleForCustomer};
				
							newLaunchRule = dynamicallyAddAdditionalLaunchRuleData(conectivitiesRef.current,newLaunchRule, currentUser.timeZoneCode);

							if (DEBUG_MODE >= 2) console.log("New launchRule extracted from message",newLaunchRule);
							
							const tempLaunchRules = [...launchRulesRef.current];   //Get our state data for existing launchRules

							//  if (DEBUG_MODE >= 2) console.log("Current launchRules",tempLaunchRules);

							tempLaunchRules.push(newLaunchRule);    //Add the new launchRule
							tempLaunchRules.sort(compareByNextLaunchDateISO);
							
							//Update system-wide state data with the new launchRules
							 setLaunchRules(tempLaunchRules);
							
							if (DEBUG_MODE >= 2) console.log("Active LaunchRules after CREATE from Subscription", tempLaunchRules);
							
						 }
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing launchRule create subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setLaunchRuleCreateSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setLaunchRuleCreateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE Launch Rule");
			};
 
		}
	},[authState]);                     //Call function when a change to authState occurs
	  
	useEffect(() => {
		if (!launchRuleUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for launchRules for this user", currentUser);
			
		   const subscription = API.graphql({
				query:onUpdateLaunchRuleForCustomer,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("UPDATE launchRule data received via subscription",messageData);
						if (messageData.data.onUpdateLaunchRuleForCustomer !== null) {                           
						var updatedLaunchRule = {...messageData.data.onUpdateLaunchRuleForCustomer};

						//Dynamically add conectivity data to the local launch rule to aid rendering
						// updatedLaunchRule.nextLaunchDateISO = getRecurrenceRuleNextLaunchDate(updatedLaunchRule);
						updatedLaunchRule = dynamicallyAddAdditionalLaunchRuleData(conectivitiesRef.current, updatedLaunchRule, currentUser.timeZoneCode);
						if (DEBUG_MODE >= 2) console.log("LaunchRule update extracted from message",updatedLaunchRule);                        
						const tempLaunchRules = [...launchRulesRef.current];   //Get our state data for existing launchRules
						//  if (DEBUG_MODE >= 2) console.log("Current launchRules",tempLaunchRules);

						const index = tempLaunchRules.findIndex(launchRule => launchRule.id === updatedLaunchRule.id);
						if (index > -1) {
						   //Update system-wide state data with the UPDATED launchRule
						   tempLaunchRules[index] = {...updatedLaunchRule};    //Replace the existing launchRule with the updated one just received
						   tempLaunchRules.sort(compareByNextLaunchDateISO);
						   if (DEBUG_MODE >= 2) console.log("Active LaunchRules after UPDATE from Subscription", tempLaunchRules);
							  setLaunchRules(tempLaunchRules);                            
						   } else {
							  if (DEBUG_MODE >= 2) console.log("Error - no matching launchRule found to update", updatedLaunchRule, tempLaunchRules);
						   }
						}
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing launchRule update subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setLaunchRuleUpdateSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setLaunchRuleUpdateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Launch Rule");
			};
 
		}
	},[authState]);                     //Call function when a change to authState occurs

   useEffect(() => {
		if (!launchRuleDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for launchRules DELETED for user", currentUser);
			
		   const subscription = API.graphql({
				query:onDeleteLaunchRuleByCustomer,
				variables: {
						customerID: currentUser.customer.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("DELETE launchRule data received via subscription",messageData);
						if (messageData.data.onDeleteLaunchRuleByCustomer !== null) {
							
							const deletedLaunchRule = {... messageData.data.onDeleteLaunchRuleByCustomer };
							if (DEBUG_MODE >= 2) console.log("Deleted MESSAGE PACKET extracted from message",deletedLaunchRule);
							
							 //Remove the matching launch rule from local state.  Note, this technique does not update the state array directly like "splice" would
							var tempLaunchRules = launchRulesRef.current.filter(element => element.id !== deletedLaunchRule.id);
							tempLaunchRules.sort(compareByNextLaunchDateISO);
							setLaunchRules(tempLaunchRules); //Delete state data with newly sorted list  

							if (DEBUG_MODE >= 2) console.log("Deleted MESSAGE PACKET for company", tempLaunchRules);
							
						 }
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing launchRule DELETE subscription message",messageData);

					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setLaunchRuleDeleteSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setLaunchRuleDeleteSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE Launch Rule");
			};
 
		}
	},[authState]);                     //Call function when a change to authState occurs



	// Based on launch rules, generate upcoming launch dates for this user
	useEffect(() => {
		processLaunchRules(currentUser);
	},[launchRules, conectivities]);


	function processLaunchRules(user) {

		if (!launchRules || !conectivities||launchRules.length===0 || conectivities.length===0) return;

		try {
			//Extract launch rules created by the user and store in state data   
			var tempLaunchRules = launchRules.filter(launchRule => launchRule.senderID === user.id);
			if (DEBUG_MODE >= 2) console.log("Identified Launch Rules created by this user", tempLaunchRules);
			setLaunchRulesCreatedByThisUser(tempLaunchRules);
			
			
			//Process launch rules to identify those that involve the user; store launch rules and upcoming launch dates
			var tempUpcomingLaunchDates = [];
			var tempUpcomingLaunchDatesThisUser = [];
			var tempLaunchRulesThisUser = [];
			let appliesToThisUser, tempNumberOfLaunchesToday=0;

			for(var i = 0; i<launchRules.length; i++){
				
				//First, determine whether this rule applies to the user
				//(1) USER MUST BE AN INVITEE, WHICH INCLUDES THE SENDER - 
				// EVEN IF THEY DECLINED AS WE WANT TO INCLUDE IT ON THEIR CALENDAR (FOR NOW)
				appliesToThisUser =  ((launchRules[i].usersToInvite.includes(user.id)));          
				if (DEBUG_MODE >= 3) console.log("Checked Launch rule", launchRules[i], user, appliesToThisUser);
				
				//If we have not already found a match, check whether this user is on an invited team?
				if (!appliesToThisUser && launchRules[i].teamsToInvite && launchRules[i].teamsToInvite.length > 0 && user.teams.items.length > 0) {
					
					//Is this user on a team having an ID that is listed in the launch rule?
					appliesToThisUser = user.teams.items.some((join) => {
						if (DEBUG_MODE >= 3) console.log("Checking user/team JOIN against launch rule teams", join, launchRules[i].teamsToInvite);
						return launchRules[i].teamsToInvite.includes(join.teamID);
					});
					
					if (DEBUG_MODE >= 3) console.log("Checked Launch rule for matching team", launchRules[i], user, appliesToThisUser);
				}

				//Does the Launch rule apply to this user, if so add it to our array 
				if (appliesToThisUser) {
					tempLaunchRulesThisUser.push(launchRules[i]);
					
					//Since relevant for this user, check whether there is an event instance TODAY, so we can show a bubble
					if (isRecurrenceRuleLaunchToday(launchRules[i])) {
						tempNumberOfLaunchesToday++;
						// if (DEBUG_MODE >= 3) console.log("Launch rule for today", launchRules[i]);
					}            
				}

				//Get the launch dates for THIS rule
				const arrayOfLaunchDates = getrecurrenceRuleLaunchDates(launchRules[i]);    
				const thisConectivity = conectivities.find(conectivity => conectivity.id === launchRules[i].conectivityID);
				
				//For each launch date, add it to our array of launch dates for this user       
				if (arrayOfLaunchDates && arrayOfLaunchDates.length > 0) {
					for (var j=0; j < arrayOfLaunchDates.length; j++) {
					
					const upcomingLaunchDateObject = {
						launchDate: arrayOfLaunchDates[j],
						usersToInvite:launchRules[i].usersToInvite,
						teamsToInvite:launchRules[i].teamsToInvite,
						conectivityID: (thisConectivity ? thisConectivity.id : ""),
						title: (thisConectivity ? thisConectivity.title : "None"),
						shortLabel: (thisConectivity ? thisConectivity.category.label : ""),
						image: (thisConectivity ? thisConectivity.image : ""),
						category:(thisConectivity ? thisConectivity.category.name : ""),
						conectivityImageURL: (thisConectivity ? setConectivityImageUrlByCategory(thisConectivity.category.label, true) : ""),
						senderID: launchRules[i].senderID,
					};
					
					//Build out the two arrays
					tempUpcomingLaunchDates.push(upcomingLaunchDateObject);                                //Upcoming launch dates for entire company
					if (appliesToThisUser) {
						tempUpcomingLaunchDatesThisUser.push(upcomingLaunchDateObject); //Upcoming launch dates for this user
					}
					}
				}
			}

			setLaunchRulesInvolvingThisUser(tempLaunchRulesThisUser);
			if (DEBUG_MODE >= 2) console.log("Extracted launch rules involving this user", user.firstName, user.lastName, tempLaunchRulesThisUser);

			setNumberOfLaunchesToday(tempNumberOfLaunchesToday);
			if (DEBUG_MODE >= 2) console.log("Number of this user's launch rules for today", tempNumberOfLaunchesToday);
		
			tempUpcomingLaunchDates.sort(compareByLaunchDate);
			if (DEBUG_MODE >= 2) console.log("Prepared corporate-wide future launch schedule", tempUpcomingLaunchDates, conectivities);
			setUpcomingLaunches(tempUpcomingLaunchDates);

			tempUpcomingLaunchDatesThisUser.sort(compareByLaunchDate);
			if (DEBUG_MODE >= 2) console.log("Prepared future launch schedule for this user", tempUpcomingLaunchDatesThisUser);
			setUpcomingLaunchesCreatedByThisUser(tempUpcomingLaunchDatesThisUser);
		}catch (err) {
			console.error('Error processing launch rules', err);
		}
		
	}


//
//
//  SCHEDULED CONECTIVITIES SUBSCRIPTIONS
//
//
	const [scheduledConectivitiesSubscriptionSetUp, setScheduledConectivitiesSubscription] = useState(false);  //record once we have established the web socket

	//Subscribe to changes to updates to scheduled conectivities data; to be pushed in real-time by Appsync    
	useEffect(() => {
		
		if (!scheduledConectivitiesSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATE scheduled conectivities data By customer", currentUser.customerID);
			
			try {
				 const subscription = API.graphql({
					query:onUpdateScheduledConectivityByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("Received via App Sync => UPDATE scheduled conectivity data message",messageData);
							
							if (messageData.data.onUpdateScheduledConectivityByCustomer !== null) {

								var updatedSC = messageData.data.onUpdateScheduledConectivityByCustomer;
								if (DEBUG_MODE >= 2) console.log("App Sync => UPDATE scheduled conectivity data extracted from message",updatedSC);
								// Update system-wide state data 
								const tempSCs = [...scheduledConectivitiesRef.current];        //Make a copy so as to trigger state updates
															
								//Try to find the one we just edited based on ID
								const indexToEdit = tempSCs.findIndex(sc => sc.id === updatedSC.id);
	  
								//Edit the item in our local data to reflect the fully updated user object
								if (indexToEdit > -1) {
										
									if (DEBUG_MODE >= 3) console.log('Found scheduled conectivity to edit at index:', indexToEdit);
	
									//Update system-wide state data with the received object
									//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
									tempSCs[indexToEdit]=updatedSC; //Update array
									if (DEBUG_MODE >= 2) console.log("Updated SC for company", currentUser.customerID, tempSCs);
									
									//In addition, check to see whether the user is currently viewing messages for an invitation associated with this SC
									//If so, update the messaging modal 
									
									if (updatedSC.invitations && updatedSC.invitations.items && updatedSC.invitations.items.length >0 && invitationToUpdateRef.current) { //User viewing messages?  If not, skip this
										const matchingInvitation = updatedSC.invitations.items.find(invitation => invitation.id === invitationToUpdateRef.current.id);
										if (matchingInvitation) { //Did we find a match?  I.e., is the user viewing messages for this exact invitation?
											
											// 10.15.2023 - REMOVED AS NOW USING MESSAGE PACKET TO SECURELY PUSH JUST THE MESSAGE TO EVERY USER
											// const tempMessageList = [...matchingInvitation.messages.items];
											// const sortedMessages= sortMessages(tempMessageList);
											// setMessagesToShow([...sortedMessages]);             //Update message currenty being viewed
											// // setInvitationToProcess(receivedInvitation);  //Update invitation currently being viewed
											// const timer = setTimeout(() => {
											//         var objDiv = document.getElementById("messageDIV"); //Finally, scroll to the bottom message smoothly if userHome page rendered
											//         if (objDiv) objDiv.scrollTo({ top: objDiv.scrollHeight, left:0, behavior: "smooth"}); else if (DEBUG_MODE >= 2) console.log("No message DIV to scroll");
											//     }, 600);
	
											setInvitationToUpdate(matchingInvitation);      //ALSO Update invitation that is used to update the backend
											if (DEBUG_MODE >= 2) console.log("Invitation subscription - updated invitation", invitationToUpdateRef.current.id, matchingInvitation.id);
										}
										
									 } //END IF InvitationToUpdate                                    
									
								 } else {
									// if (DEBUG_MODE >= 2) console.log("Scheduled Conectivity not found in state data; creating new SC in local state data");
									tempSCs.push(updatedSC);        //add new item to client-side data for this page to the existing array.
									if (DEBUG_MODE >= 2) console.log("Created SCHEDULED CONECTICITY ", currentUser.customerID, tempSCs);
								 }
								setScheduledConectivities(tempSCs); //Update state data 
							}                                
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing UPDATE scheduled conectivity subscription message",messageData);
						}
					},
					error: error => console.log("AppSync subscription error", error, subscription)
				});
				
				setScheduledConectivitiesSubscription(true);
	
			   return () => {
					subscription.unsubscribe();
					setScheduledConectivitiesSubscription(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE scheduled conectivity");
				};                
 
			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error processing UPDATE scheduled conectivity subscription message",err);
	
			}
		}   
	}, [authState]);  //Trigger this whenever authState changes state!

	const [scheduledConectivitiesDeleteSubscriptionSetUp, setScheduledConectivitiesDeleteSubscription] = useState(false);  //record once we have established the web socket

	//Subscribe to changes to updates to scheduled conectivities data; to be pushed in real-time by Appsync    
	useEffect(() => {
		
		if (!scheduledConectivitiesDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE scheduled conectivities data By customer", currentUser.customerID);
			
			try {
				 const subscription = API.graphql({
					query:onDeleteScheduledConectivityByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("Received via App Sync => DELETE scheduled conectivity data message",messageData);
							
							if (messageData.data.onDeleteScheduledConectivityByCustomer !== null) {

								var deletedSC = messageData.data.onDeleteScheduledConectivityByCustomer;
								
								if (DEBUG_MODE >= 2) console.log("DELETE scheduled conectivities data extracted from message",deletedSC);
								
								// Update system-wide state data 
								const tempSCs = scheduledConectivitiesRef.current.filter(sc => sc.id !== deletedSC.id);        //Make a copy so as to trigger state updates
								setScheduledConectivities(tempSCs);
								if (DEBUG_MODE >= 2) console.log("Deleted scheduled conectivity", tempSCs);
							}
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing DELETE scheduled conectivity subscription message",messageData);
   
						}
					},
				error: error => console.log("AppSync subscription error", error, subscription)
				});
				
				setScheduledConectivitiesDeleteSubscription(true);
	
			   return () => {
					subscription.unsubscribe();
					setScheduledConectivitiesDeleteSubscription(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE SCHEDULED CONECTIVITY");
				};                
 
			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error processing DELETE scheduled conectivity data subscription message",err);
	
			}
		}   
	}, [authState]);  //Trigger this whenever authState changes state!


//
//
//  INVITATIONS SUBSCRIPTIONS
//
//

/* 
   12.16.2023 - NO LONGER NEEDED AS WE UTILIZE OUR MESSAGE PACKET SUBSCRIPTION TO RECEIVE INDIVIDUAL MESSAGES AND THEN, IF NECESSARY,
   UPDATE THE MESSAGES OF ANY INVITATION CURRENTLY BEING DISPLAYED ON THE HOME PAGE AND ALSO THE CHAT WINDOW IF OPEND
   
	const [invitationDataSubscriptionSetUp, setInvitationDataSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	//Subscribe to changes to updates to invitation data; to be pushed in real-time by Appsync    
	useEffect(() => {
		
		if (!invitationDataSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for user invitation data for customer", currentUser.customerID);
			
			try {
				 const subscription = API.graphql({
					query:onUpdateInvitationDataByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("Update invitation appsync data message",messageData);
							if (messageData.data.onUpdateInvitationDataByCustomer !== null) {
								var receivedInvitation = messageData.data.onUpdateInvitationDataByCustomer;
								if (DEBUG_MODE >= 2) console.log("Updated invitation data extracted from message",receivedInvitation);
								
								// Is the invitation for an SC that is currently being displayed on the user Home Page?  If so, update it
								let matchFound=false;
								if (scheduledConectivitiesToDisplayRef && scheduledConectivitiesToDisplayRef.current && scheduledConectivitiesToDisplayRef.current.length > 0) {
									for (const sc of scheduledConectivitiesToDisplayRef.current) {
										// if (DEBUG_MODE >= 2) console.log("Checking SC for matching invitation", sc);
										if (sc && sc.invitations && sc.invitations.items && sc.invitations.items.length > 0) {
											for (var i=0; i < sc.invitations.items.length; i++ ){
												if (!matchFound && sc.invitations.items[i].id === receivedInvitation.id) {
													sc.invitations.items[i] = {...receivedInvitation}; //Update the invitation directly; need to use a conventional FOR loop so as not to make a placeholder object
													if (DEBUG_MODE >= 2) console.log("Updated displayed SCs with invitation data extracted from message",sc);
													matchFound = true;
												} // END IF MATCH
											} // END FOR EACH INVITATION
										} // END IF INVITATIONS
									} // END FOR EACH SC

									//Match?  If so, re-render the displayed SC.  Make a new array so we absolutely force a React update
									if (matchFound) {
										const tempNewArrayOfScheduledConectivitiesToDisplay = [...scheduledConectivitiesToDisplayRef.current];
										setScheduledConectivitiesToDisplay(tempNewArrayOfScheduledConectivitiesToDisplay);
										// if (DEBUG_MODE >= 2) console.log("Generated new array of displayed SCs to force update", tempNewArrayOfScheduledConectivitiesToDisplay);
									}
									
								} else if (DEBUG_MODE >= 2) console.log("No SCs being displayed", scheduledConectivitiesToDisplayRef, scheduledConectivitiesToDisplayRef.current.length); // END IF CURRENTS SC'S FOR DISPLAY

  12.16.2023 REMOVED AS UPDATING MESSAGES BEING VIEWED IS HANDLED BY MESSAGE_PACKET APPSYNC WHEN THERE ARE NEW MESSAGES                            
								if (invitationToUpdateRef && invitationToUpdateRef.current && invitationToUpdateRef.current.id === receivedInvitation.id) { //Is the user viewing messages for this exact invitation?
									setInvitationToUpdate(receivedInvitation);      //ALSO Update invitation that is used to update the backend
									// Refresh the ENTIRE Chat Window if the user happens to be viewing the messages for the specific updated invitation
								   // 10-15-2023 - NECESSARY FOR MOBILE APP SINCE IT DOES AN INVITATION UPDATE WHEN SENDING A MESSAGE
								   // POSSIBLY REMOVE THE CODE BELOW ONCE THE MOBILE APP USES THE MESSAGE PACKET TABLE TO SECURELY SEND INDIVIDUAL MESSAGES TO USERS
									// REALLY, JUST LEAVE THIS CODE AND DO A FULL UPDATE OF THE CHAT WINDOW IF THE INVITATION IS UPDATED
									// NOTE, THE messagesReadAt Field is currently at the invitation level, which means Updates occur just on reads
									// So maybe remove the below code eventually
									const tempMessageList = [...receivedInvitation.messages.items];
									const sortedMessages= sortMessages(tempMessageList);
									setMessagesToShow([...sortedMessages]);             //Update message currenty being viewed
									if (DEBUG_MODE >= 2) console.log("User was viewing messages for the updated invitation; updated display", invitationToUpdateRef.current.id, receivedInvitation.id, tempMessageList);
								} //END IF InvitationToUpdate matches the received invitation
								
							}
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing user invitation data subscription message",err);
   
						}
					},
					error: error => console.log("AppSync subscription error", error, subscription)
				});
				
				setInvitationDataSubscriptionSetUp(true);
	
			   return () => {
					subscription.unsubscribe();
					setInvitationDataSubscriptionSetUp(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for INVITATION DATA");
				};                
 
			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error processing user invitation data subscription message",err);
	
			}
		}   
	}, [authState]);  //Trigger this whenever authState changes state!


	const [invitationDataDeleteSubscriptionSetUp, setInvitationDataDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	//Subscribe to changes to deletions of invitations; to be pushed in real-time by Appsync    
	useEffect(() => {
		if (!invitationDataDeleteSubscriptionSetUp  && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for invitation DELETE for customer", currentUser.customerID);
			
			try {
				 const subscription = API.graphql({
					query:onDeleteInvitationDataByCustomer,
					variables: {
							customerID: currentUser.customerID,
						}
				})
				.subscribe ({
					next: messageData => {
						try {
							if (DEBUG_MODE >= 2) console.log("Received via subscription => message for DELETED invitation",messageData);
							
							if (messageData.data.onDeleteInvitationDataByCustomer !== null) {
							
									// For all user cases, simply trigger a fetch of new Scheduled Conectivities for the user 
									// since this invitation may be a new conectivity for them and not even displayed or the user may have been removed and no longer on the invitation
									// in which case we may need to de-prioritize the scheduled conectivity over others
									
									// 5.22.2023 - REMOVED THIS WHEN ADDING MASTER SUBSCRIPTION TO SCHEDULED CONECTIVITIES.  NOW DOING THIS UPDATE AT A HIGHER GRAPHQL LEVEL, WHICH MAKE IT EASER
									// AS SUCH, THIS SUBSCRIPTION IS PROBABLY NO LONGER NEEDED
									// setTriggerFetchScheduledConectivities(uuidv4());
								
  
							}
						} catch (err) {
							if (DEBUG_MODE >= 2) console.log("Error processing user invitation data subscription message - DELETE",messageData);
   
						}
					},
					error: error => console.log("AppSync subscription error", error, subscription)
				});
				
				setInvitationDataDeleteSubscriptionSetUp(true);

			   return () => {
					subscription.unsubscribe();
					setInvitationDataDeleteSubscriptionSetUp(false);
					if (DEBUG_MODE >= 1) console.log("Tearing down subscription for INVITATION DELETE");
				};    
				
			} catch (err) {
				if (DEBUG_MODE >= 2) console.log("Error processing user invitation data subscription message for DELETE",err);
	
			}
		}   
	}, [authState]);  //Trigger this whenever authState changes state!

*/

//
// Message Packet Subscriptions - used to securely receive message data specific to this user
//

	//Functions and state data for real-time updates
	const [messagePacketCreateSubscriptionSetUp, setMessagePacketCreateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [messagePacketUpdateSubscriptionSetUp, setMessagePacketUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	const [messagePacketDeleteSubscriptionSetUp, setMessagePacketDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

   useEffect(() => {
		if (!messagePacketCreateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for messagePackets created for this user", currentUser.id);
			
		   const subscription = API.graphql({
				query:onCreateMessagePacketByUser,
				variables: {
						userID: currentUser.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 1) console.log("NEW messagePacket data received via subscription",messageData);
						if (messageData.data.onCreateMessagePacketByUser !== null) {
							var newMessagePacket = {...messageData.data.onCreateMessagePacketByUser};
							if (DEBUG_MODE >= 1) console.log("New messagePacket extracted from message",newMessagePacket);
							if (DEBUG_MODE >= 1 && chatWindowIDRef) console.log("ChatWindowID of object being viewed",chatWindowIDRef.current);

							//Update our local state data if necessary
							if (newMessagePacket && newMessagePacket.message) { 
							   
								 //First, update the MessagesToShow if the corresponding LaunchRule or Invitation is specifically being viewed by the user
								 //User viewing messages?  If not, skip this
								 if (messagesToShowRef.current && chatWindowIDRef && chatWindowIDRef.current === newMessagePacket.message.launchRuleID && !messagesToShowRef.current.some(msg => msg.id === newMessagePacket.message.id)) {
									const tempNewMessage = {...newMessagePacket.message};
									const tempMessageList = [...messagesToShowRef.current];
									tempMessageList.push(tempNewMessage);
									const sortedMessages= sortMessages(tempMessageList);
									setMessagesToShow([...sortedMessages]);             //Update message currenty being viewed
									// const timer = setTimeout(() => {
									//         var objDiv = document.getElementById("messageDIV"); //Finally, scroll to the bottom message smoothly if userHome page rendered
									//         if (objDiv) objDiv.scrollTo({ top: objDiv.scrollHeight, left:0, behavior: "smooth"}); else if (DEBUG_MODE >= 2) console.log("No message DIV to scroll");
									//     }, 600);

									if (DEBUG_MODE >= 2) console.log("User was viewing messages; updated messages", sortedMessages);
								} else { ///END if messagesToShow
									if (DEBUG_MODE >= 2) console.log("Received Message not relevant to Chat Window or already in MessagesToShow - did not insert new message", messagesToShowRef.current);
								}
								
							   //Second, update the message data for the invitation if present in our local state data
							   // 1. scan the SCs selected for display on this U/I
							   // 2. for each SC being displayed, check each invitation to see if it corresponds to the message's LR
							   // 3. for each invitation, does it match the LR for this message?
							   //   - if so, update that invitation's messages
							   // 4. Did we find at least 1 match?  
							   //   - If so re-render the SCs being displayed
   
								let matchFound=false;
								if (scheduledConectivitiesToDisplayRef && scheduledConectivitiesToDisplayRef.current && scheduledConectivitiesToDisplayRef.current.length > 0) {
									for (const sc of scheduledConectivitiesToDisplayRef.current) {
										// if (DEBUG_MODE >= 2) console.log("Checking SC for matching invitation", sc);
										if (sc && sc.invitations && sc.invitations.items && sc.invitations.items.length > 0) {
											for (var i=0; i < sc.invitations.items.length; i++ ){
												if (!matchFound && sc.invitations.items[i].launchRuleID === newMessagePacket.message.launchRuleID) {
													if (DEBUG_MODE >= 2) console.log("Matching displayed invitation found for the incoming message",sc.invitations.items[i]);
													  //Insert the NEW message unless that message already exists, which can happen if we received a different subscription for the LR itself
													  if (!sc.invitations.items[i].messages.items.some(msg => msg.id === newMessagePacket.message.id)) {
														 sc.invitations.items[i].messages.items.push({...newMessagePacket.message}); //Update the invitation directly; need to use a conventional FOR loop so as not to make a placeholder object
														 if (DEBUG_MODE >= 2) console.log("Updated invitation with new message",sc.invitations.items[i], newMessagePacket.message);
													  } else {
														 if (DEBUG_MODE >= 2) console.log("Received new Message already IN the invitation's messages - did not insert new message", messagesToShowRef.current);
													  }
													matchFound = true;
												} // END IF MATCH
											} // END FOR EACH INVITATION
										} // END IF INVITATIONS
									} // END FOR EACH SC
	  
									//Match?  If so, re-render the displayed SC.  Make a new array so we absolutely force a React update
									if (matchFound) {
										const tempNewArrayOfScheduledConectivitiesToDisplay = [...scheduledConectivitiesToDisplayRef.current];
										setScheduledConectivitiesToDisplay(tempNewArrayOfScheduledConectivitiesToDisplay);
										// if (DEBUG_MODE >= 2) console.log("Generated new array of displayed SCs to force update", tempNewArrayOfScheduledConectivitiesToDisplay);
									}
								} else if (DEBUG_MODE >= 2) console.log("No SCs being displayed", scheduledConectivitiesToDisplayRef, scheduledConectivitiesToDisplayRef.current.length); // END IF CURRENTS SC'S FOR DISPLAY
							}//END if properly received message packet
						} //END IF Subscription Data
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing Create MessagePacket subscription data",err);
					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setMessagePacketCreateSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setMessagePacketCreateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE Launch Rule");
			};
		}
	},[authState]);  //Call function when a change to authState occurs
	  
	useEffect(() => {
		if (!messagePacketUpdateSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for messagePackets for this user", currentUser);
			
		   const subscription = API.graphql({
				query:onUpdateMessagePacketByUser,
				variables: {
						userID: currentUser.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("UPDATE messagePacket data received via subscription",messageData);
						if (messageData.data.onUpdateMessagePacketByUser !== null) {
							
							var updatedMessagePacket = {...messageData.data.onUpdateMessagePacketByUser};
							if (DEBUG_MODE >= 2) console.log("MessagePacket update extracted from message",updatedMessagePacket);

							//Update our local state data if necessary
							if (updatedMessagePacket && updatedMessagePacket.message && updatedMessagePacket.message.id) { 
							   
								 //First, update the MessagesToShow if the corresponding LaunchRule or Invitation is specifically being viewed by the user
								 //User viewing messages?  If not, skip this
								 if (messagesToShowRef && messagesToShowRef.current) {
									const tempMessageList = [...messagesToShowRef.current];
									const index = tempMessageList.findIndex(m => m.id === updatedMessagePacket.message.id);
									if (index > -1) {
									   //Update system-wide state data with the UPDATED message
									   tempMessageList[index] = {...updatedMessagePacket.message};   
									   const sortedMessages= sortMessages(tempMessageList);
									   setMessagesToShow([...sortedMessages]);             //Update message currenty being viewed
									   if (DEBUG_MODE >= 2) console.log("User was viewing messages; updated messages", sortedMessages);
									} else { ///END if messagesToShow
									if (DEBUG_MODE >= 2) console.log("Updated Message NOT in MessagesToShow - did not updated messages being viewed", messagesToShowRef.current);
									}
								}
								
							   //Second, update the message data for the corresponding invitation if present in our local state data
							   // 1. scan the SCs selected for display on this U/I
							   // 2. for each SC being displayed, check each invitation to see if it corresponds to the message's LR
							   // 3. for each invitation, does it match the LR for this message?
							   //   - if so, update that invitation's messages
							   // 4. Did we find at least 1 match?  
							   //   - If so re-render the SCs being displayed
   
								let matchFound=false;
								if (scheduledConectivitiesToDisplayRef && scheduledConectivitiesToDisplayRef.current && scheduledConectivitiesToDisplayRef.current.length > 0) {
									for (const sc of scheduledConectivitiesToDisplayRef.current) {
										// if (DEBUG_MODE >= 2) console.log("Checking SC for matching invitation", sc);
										if (sc && sc.invitations && sc.invitations.items && sc.invitations.items.length > 0) {
											for (var i=0; i < sc.invitations.items.length; i++ ){
												if (!matchFound && sc.invitations.items[i].launchRuleID === updatedMessagePacket.message.launchRuleID) {
												   if (DEBUG_MODE >= 2) console.log("Matching displayed invitation found for the incoming message",sc.invitations.items[i]);
												   if (sc.invitations.items[i].messages && sc.invitations.items[i].messages.items && sc.invitations.items[i].messages.items.length > 0) {
													  const index = sc.invitations.items[i].messages.items.findIndex(m => m.id === updatedMessagePacket.message.id);
													  if (index > -1) {
														 sc.invitations.items[i].messages.items[index] = {...updatedMessagePacket.message};   
														 matchFound = true;
														 if (DEBUG_MODE >= 2) console.log("Matching MESSAGE found for the incoming updated message",sc.invitations.items[i]);
													  }
												   } // END IF MATCHING MESSAGE
												 } // END IF MATCHING LR
											} // END FOR EACH INVITATION
										} // END IF INVITATIONS
									} // END FOR EACH SC
	  
									//Match?  If so, re-render the displayed SC.  Make a new array so we absolutely force a React update
									if (matchFound) {
										const tempNewArrayOfScheduledConectivitiesToDisplay = [...scheduledConectivitiesToDisplayRef.current];
										setScheduledConectivitiesToDisplay(tempNewArrayOfScheduledConectivitiesToDisplay);
										if (DEBUG_MODE >= 2) console.log("Generated new array of displayed SCs to force update", tempNewArrayOfScheduledConectivitiesToDisplay);
									}
								} else if (DEBUG_MODE >= 2) console.log("No SCs being displayed", scheduledConectivitiesToDisplayRef, scheduledConectivitiesToDisplayRef.current.length); // END IF CURRENTS SC'S FOR DISPLAY
							}//END if properly received message packet
						} //END IF SUBSCRIPTION DATA EXTRACTED

					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing messagePacket update subscription message",messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setMessagePacketUpdateSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setMessagePacketUpdateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE Message Packet");
			};
 
		}
	},[authState]);                     //Call function when a change to authState occurs

   useEffect(() => {
		if (!messagePacketDeleteSubscriptionSetUp && authState === 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for messagePackets DELETED for user", currentUser);
			
		   const subscription = API.graphql({
				query:onDeleteMessagePacketByUser,
				variables: {
						userID: currentUser.id,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("DELETE messagePacket data received via subscription",messageData);
						if (messageData.data.onDeleteMessagePacketByUser !== null) {
							
							const deletedMessagePacket = {... messageData.data.onDeleteMessagePacketByUser };
							if (DEBUG_MODE >= 1) console.log("Deleted MESSAGE PACKET extracted from message",deletedMessagePacket);

							//Update our local state data if necessary.  Note, the message packet will carry the master message record
							if (deletedMessagePacket && deletedMessagePacket.message && deletedMessagePacket.message.id) { 
							   
								//First, update the MessagesToShow if the corresponding LaunchRule or Invitation is specifically being viewed by the user
								//User viewing messages?  If not, skip this
								if (messagesToShowRef && messagesToShowRef.current && messagesToShowRef.current.some(mes => mes.id === deletedMessagePacket.message.id)) {
									var tempMessagePackets = messagesToShowRef.current.filter(element => element.id !== deletedMessagePacket.message.id);
									setMessagesToShow(tempMessagePackets); //Delete state data with newly sorted list 
								  if (DEBUG_MODE >= 1) console.log("Removed Message from MessagesToShow ", messagesToShowRef.current);
							  }
							  
							  //Second, update the message data for the corresponding invitation if present in our local state data
							  // 1. scan the SCs selected for display on this U/I
							  // 2. for each SC being displayed, check each invitation to see if it corresponds to the message's LR
							  // 3. for each invitation, does it match the LR for this message?
							  //   - if so, update that invitation's messages
							  // 4. Did we find at least 1 match?  
							  //   - If so re-render the SCs being displayed
  
							  let matchFound=false;
							  if (scheduledConectivitiesToDisplayRef && scheduledConectivitiesToDisplayRef.current && scheduledConectivitiesToDisplayRef.current.length > 0) {
								  for (const sc of scheduledConectivitiesToDisplayRef.current) {
									  if (DEBUG_MODE >= 1) console.log("Checking SC for matching invitation", sc);
									  if (sc && sc.invitations && sc.invitations.items && sc.invitations.items.length > 0) {
										  for (var i=0; i < sc.invitations.items.length; i++ ){
											  if (!matchFound && sc.invitations.items[i].launchRuleID === deletedMessagePacket.message.launchRuleID) {
												  if (DEBUG_MODE >= 1) console.log("Matching displayed invitation found for the message being deleted",sc.invitations.items[i]);
												  if (sc.invitations.items[i].messages && sc.invitations.items[i].messages.items && sc.invitations.items[i].messages.items.length > 0) {
														sc.invitations.items[i].messages.items = sc.invitations.items[i].messages.items.filter(mes => mes.id !== deletedMessagePacket.message.id);
														if (DEBUG_MODE > 1) console.log("Removed message from the invitation", sc.invitations.items[i])
												  } // END IF MATCHING MESSAGE
												} // END IF MATCHING LR
										  } // END FOR EACH INVITATION
									  } // END IF INVITATIONS
								  } // END FOR EACH SC
	 
								  //Match?  If so, re-render the displayed SC.  Make a new array so we absolutely force a React update
								  if (matchFound) {
									  const tempNewArrayOfScheduledConectivitiesToDisplay = [...scheduledConectivitiesToDisplayRef.current];
									  setScheduledConectivitiesToDisplay(tempNewArrayOfScheduledConectivitiesToDisplay);
									  if (DEBUG_MODE >= 1) console.log("Generated new array of displayed SCs to force update", tempNewArrayOfScheduledConectivitiesToDisplay);
								  }
							  } else if (DEBUG_MODE >= 1) console.log("No SCs being displayed", scheduledConectivitiesToDisplayRef, scheduledConectivitiesToDisplayRef.current.length); // END IF CURRENTS SC'S FOR DISPLAY
						  }//END if properly received message packet
							if (DEBUG_MODE >= 1) console.log("Deleted MESSAGE PACKET for company", tempMessagePackets);     
						 }
					} catch (err) {
						if (DEBUG_MODE) console.log("Error processing messagePacket DELETE subscription message",messageData);

					}
				},
				error: error => console.log("AppSync subscription error", error, subscription)
			});
			
			setMessagePacketDeleteSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setMessagePacketDeleteSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE Launch Rule");
			};
 
		}
	},[authState]);  //Call function when a change to authState occurs
	



//
// CONECTIVITY DB UTILITIES THAT UTILIZE AUTH CONTEXT PERMISSIONS
//


	async function invokeCreateConectivity ({
		conectivityToEdit, newSaveAsTitle, instructions, categoryDropDownSelection, conectivityStatusDropDownSelection, imageDropDownSelection, authorSelection, customerScopeSelection, isSharing,
		eventStartDateTime, eventEndDateTime, 
		launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, 
		launchUntilDate, launchUntilCount, launchMonthOption1Checked, launchMonthOption2Checked,      
	}) {
	

		 if (DEBUG_MODE > 1) console.log("Invoke conectivity called: ", conectivityToEdit, newSaveAsTitle, instructions, 
			categoryDropDownSelection, conectivityStatusDropDownSelection, imageDropDownSelection, authorSelection, customerScopeSelection, isSharing,
			eventStartDateTime, eventEndDateTime, 
			launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, 
			launchUntilDate, launchUntilCount, launchMonthOption1Checked, launchMonthOption2Checked, 
		);

			var successFlag = true;  
	
			 //Set the customer and author information
			var authorID = (currentUser ? currentUser.id : "");
			var author = currentUser.firstName + (!currentUser.middleName ? "" : " " + currentUser.middleName.substr(0,1)) + " " + currentUser.lastName;
			var authorInitials = getUserInitials(currentUser);
			var authorUrl = currentUser.avatarUrl;
			var createdByCustomerID = currentUser.customer.id;         
			var createdByCustomerName =  currentUser.customer.name;
			var createdByCustomerlogo =  currentUser.customer.logo;	                
			
			//If SuperAdmin, use the company selection and author selection to control these data fields, if set to a different author
			if(isSuperAdmin && authorSelection && (authorID !== authorSelection.id)) {
				
					const tempAuthoredByUser = users.find(user => user.id === authorSelection.id);
					const tempCreatedByCustomer= customers.find(customer => customer.id === customerScopeSelection.id);
	
					if (tempAuthoredByUser && tempCreatedByCustomer) {
						authorID = tempAuthoredByUser.id;
						author = tempAuthoredByUser.firstName + (!tempAuthoredByUser.middleName ? "" : " " + tempAuthoredByUser.middleName.substr(0,1)) + " " + tempAuthoredByUser.lastName;
						authorInitials = getUserInitials(tempAuthoredByUser);
						authorUrl = tempAuthoredByUser.avatarUrl;
						createdByCustomerID = tempCreatedByCustomer.id;         
						createdByCustomerName =  tempCreatedByCustomer.name;
						createdByCustomerlogo =  tempCreatedByCustomer.logo;
					}
			}
			
			try {    
	
				let tempConectivityToAdd = {
					//customer and author data fields
					authorID: authorID,
					author: author,
					authorInitials: authorInitials,
					authorUrl: authorUrl,
					createdByCustomerID: createdByCustomerID,         
					createdByCustomerName:  createdByCustomerName,
					createdByCustomerlogo:  createdByCustomerlogo,	                
					subscribingCustomers: [],
				  
					//conectivity details
					title: conectivityToEdit.title.trimStart(),
					description: conectivityToEdit.description.trimStart() ,
					image: setConectivityImageUrlByCategory(conectivityToEdit.category.label),  //cache the URL directy if needed
					conectCoins: conectivityToEdit.conectCoins,
					approvedPaidTime: conectivityToEdit.approvedPaidTime,
					signupDays: conectivityToEdit.signupDays,                         
					categoryID: categoryDropDownSelection.id,                          //ID of the selected category
					imageID: imageDropDownSelection.id,
					conectivityScope: (conectivityToEdit.conectivityScope ? conectivityToEdit.conectivityScope : "GLOBAL"), //Default - may be changed below
					badgesDEI: conectivityToEdit.badgesDEI,
					badgesCS: conectivityToEdit.badgesCS,
					inactiveForCustomers : [], 
					instructions: instructions,
					// publicationStatus: "DRAFT",                                 //Default to DRAFT, i.e., NOT READY FOR CUSTOMERS
					publicationStatus: (conectivityStatusDropDownSelection && conectivityStatusDropDownSelection.label ? conectivityStatusDropDownSelection.label : 'DRAFT'),
					costPerPerson: conectivityToEdit.costPerPerson,
					costFixed: conectivityToEdit.costFixed,
					
					//Knobs
					isTemplate: conectivityToEdit.isTemplate, 
					isManuallyScheduledOnly: (!conectivityToEdit.isManuallyScheduledOnly ? false : true),
					isSchedulableByAdminOnly: (!conectivityToEdit.isSchedulableByAdminOnly ? false : true),
					inviteAllEmployees:  (!conectivityToEdit.inviteAllEmployees ? false : true),
					isSpecialEvent: (!conectivityToEdit.isSpecialEvent ? false : true),
					isChallenge: (!conectivityToEdit.isChallenge ? false : true),
				};

				//Is the new conectivity associated with a challenge?  If so, add the details in JSON String format
				if (conectivityToEdit.isChallenge && conectivityToEdit.challengeOptions) tempConectivityToAdd.challengeOptions = JSON.stringify(conectivityToEdit.challengeOptions);

			   //Is the new conecitivty a Special Event?  If so, add the details
			   if (conectivityToEdit.isSpecialEvent) {
				  let eventStartDateTimeTrimmed, eventEndDateTimeTrimmed;

				  //Set to event start / end DATE & Time
				  eventStartDateTimeTrimmed = moment(eventStartDateTime).startOf('minute').toISOString();
				  eventEndDateTimeTrimmed = moment(eventEndDateTime).startOf('minute').toISOString();

				  //Add recurrence fields
				  tempConectivityToAdd = {...tempConectivityToAdd,
					 eventStartDateTime: eventStartDateTimeTrimmed,
					 eventEndDateTime: eventEndDateTimeTrimmed,
					 launchRepeatOption: (launchRepeatOption && launchRepeatOption.id ? launchRepeatOption.id : 1),
					 launchRepeatWeeklyOptions: [], 
					 launchRepeatMonthlyOptions:   (launchRepeatMonthlyOptions && launchRepeatMonthlyOptions.id ? launchRepeatMonthlyOptions.id : 1), 
					 launchRepeatMonthlyOptions_2:   (launchRepeatMonthlyOptions_2 && launchRepeatMonthlyOptions_2.id ? launchRepeatMonthlyOptions_2.id : 1), 
					 launchRepeatMonthlyOptions_3:   (launchRepeatMonthlyOptions_3 && launchRepeatMonthlyOptions_3.id ? launchRepeatMonthlyOptions_3.id : 1), 
					 launchUntilDate: (launchRepeatOption && launchRepeatOption.id === 1 ? eventStartDateTime.toISOString() : launchUntilDate.toISOString()), //Adjust end date to TODAY if there is no launchRepeat rule; this will allow us to expired (delete) the launch rule later during cleanup        //End date of when rule no longer applies
					 launchUntilCount: (launchUntilCount ? launchUntilCount : -1),   //Defaulting to unused for now; update UI to support COUNT or UNTIL            
					 launchMonthOption1Checked:   (launchMonthOption1Checked ? true : false), 
					 launchMonthOption2Checked:   (launchMonthOption2Checked ? true : false), 
					 hasNoSpecificTime:(!conectivityToEdit.hasNoSpecificTime ? false : true),
					 allDayEvent:(!conectivityToEdit.allDayEvent ? false : true),
				  };
				  //Loop through selected weekly options (days of weeks) and construct array of IDs
				  if (launchRepeatWeeklyOptions) {
					 for (var j=0 ; j < launchRepeatWeeklyOptions.length; j++) {
						// if (DEBUG_MODE >= 2) console.log("Adding selected weekly option:", launchRepeatWeeklyOptions[j]);
						tempConectivityToAdd.launchRepeatWeeklyOptions.push (launchRepeatWeeklyOptions[j].id);
					 }
				  }   
			   }

				//Is the user creating a new conectivity by Sharing one to the community?
				if (isSharing) {
					tempConectivityToAdd.publicationStatus = "REVIEW";          //Set for review by Conectere
					tempConectivityToAdd.conectivityScope = "CUSTOMER_SHARED";
					tempConectivityToAdd.numDownloads = 0;
					tempConectivityToAdd.userLikes = [];
					tempConectivityToAdd.sharedDate=moment().toISOString();
					if (DEBUG_MODE >= 2) console.log("Setting SHARED scope", tempConectivityToAdd);
				} 
				
				//Did the user do a "SaveAs"?  If so, we need to grab the updated title
				if (newSaveAsTitle !== "" && newSaveAsTitle!=null) tempConectivityToAdd.title = newSaveAsTitle;
				
				//Hard code particular image for the category.  This is a change
				tempConectivityToAdd.imageID = "";
				tempConectivityToAdd.image = setConectivityImageUrlByCategory(categoryDropDownSelection.shortLabel);
				// if (categoryDropDownSelection.shortLabel === "STRESS") {
				//     tempConectivityToAdd.image = CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH;
				// } else if (categoryDropDownSelection.shortLabel === "SOCIAL") {
				//     tempConectivityToAdd.image =  CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH;
				// } else if (categoryDropDownSelection.shortLabel === "TEAM") {
				//   tempConectivityToAdd.image=  CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH;
				// } else if (categoryDropDownSelection.shortLabel === "PERSONAL") {
				//      tempConectivityToAdd.image=  CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH;
				// }
	
	
				//If the user is not SuperAdmin or a user with Editor permissions then make this Conectivity customer-specific
				if (!isSuperAdmin && !permissionEditor) {
					tempConectivityToAdd.conectivityScope = "CUSTOMER_SPECIFIC";
					tempConectivityToAdd.publicationStatus = "PRIVATE";                                 //Set to Published if created by a Customer
				}
	
				//Is this new conectivity being created as a Post to our Community
				if (conectivityToEdit.conectivityScope === "CUSTOMER_SHARED") {
					tempConectivityToAdd.conectivityScope = "CUSTOMER_SHARED";                          //Set scope to Community
					tempConectivityToAdd.publicationStatus = "REVIEW";                                  //Place in our review queue
					tempConectivityToAdd.sharedDate = moment().toISOString();  
				}
				
				//If marked inactive on the EDIT page (NO LONGER Exists)
				if (conectivityToEdit.inactive && currentUser) {
					tempConectivityToAdd.inactiveForCustomers = [currentUser.customerID];   
				}

				if (DEBUG_MODE) console.log("Creating conectivity", tempConectivityToAdd);
			
				//Call GraphQL with correct parameters to create in backend 	   
				let tempInsertedConectivity = await invokeAPI(createConectivity, 'createConectivity', tempConectivityToAdd);
				//Success?
				if (tempInsertedConectivity && tempInsertedConectivity.id) {
					//Create an empty Rating Stats record
					const tempRatingStats = {... RATING_STATS_INITIAL_STATE, conectivityID: tempInsertedConectivity.id};
					const tempInsertedRatingStats = await invokeAPI(createRatingStats, 'createRatingStats', tempRatingStats);
					
					//Update the conectivity with the ratings ID
					tempInsertedConectivity = await invokeAPI(updateConectivity,'updateConectivity', {id:tempInsertedConectivity.id, ratingStatsID: tempInsertedRatingStats.id});
					if (DEBUG_MODE >= 2) console.log('Successfully wrote new conectivity', tempInsertedConectivity, tempInsertedRatingStats);

					//Update our local state just in case the AppSync sockets are offine for this user
					const tempConectivities = [...conectivitiesRef.current];    //Get our state data for existing conectivityGlobals
					tempConectivities.push({...tempInsertedConectivity});       //Add the new conectivityGlobal
					tempConectivities.sort(compareConectivitiesByTitle);
					setConectivities(tempConectivities);    
				} else {
					successFlag = false;
					if (DEBUG_MODE >= 2) console.log('Oops - error creating conectivity:');                    
				}
	
			} catch (err) {
				successFlag = false;
				if (DEBUG_MODE >= 2) console.log('error creating conectivity:', err);
			}
			
				return successFlag;
	
	}
	
	async function invokeUpdateConectivity ({conectivityToEdit, instructions, conectivityStatusDropDownSelection, categoryDropDownSelection, imageDropDownSelection, authorSelection, customerScopeSelection,
		eventStartDateTime, eventEndDateTime, launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, launchUntilDate, launchUntilCount, launchMonthOption1Checked, launchMonthOption2Checked,    
	}) {
			var successFlag = true;  
			try {
	
				let tempConectivityToEdit = {
					id: conectivityToEdit.id,
					// createdByCustomerID: (conectivityToEdit.createdByCustomerID ? conectivityToEdit.createdByCustomerID : currentUser.customerID),
					// authorID: conectivityToEdit.authorID,
					// author: conectivityToEdit.author,
					title: conectivityToEdit.title,
					description: conectivityToEdit.description,
					instructions: instructions,
					image: imageDropDownSelection.url,
					// image: setConectivityImageUrlByCategory(conectivityToEdit.category.label), 
					approvedPaidTime: conectivityToEdit.approvedPaidTime,
					conectCoins: conectivityToEdit.conectCoins,
					signupDays: conectivityToEdit.signupDays,
					completionDays: conectivityToEdit.completionDays,
					categoryID: categoryDropDownSelection.id,
					imageID: imageDropDownSelection.id,
					badgesDEI: conectivityToEdit.badgesDEI,
					badgesCS: conectivityToEdit.badgesCS,
					publicationStatus: conectivityStatusDropDownSelection.label,
					conectivityScope: conectivityToEdit.conectivityScope,
					ratingStatsID: conectivityToEdit.ratingStatsID,
	
					//Knobs
					isTemplate: conectivityToEdit.isTemplate, 
					isManuallyScheduledOnly: (!conectivityToEdit.isManuallyScheduledOnly ? false : true),
					isSchedulableByAdminOnly: (!conectivityToEdit.isSchedulableByAdminOnly ? false : true),
					inviteAllEmployees:  (!conectivityToEdit.inviteAllEmployees ? false : true),
					isSpecialEvent: (!conectivityToEdit.isSpecialEvent ? false : true),
					isChallenge: (!conectivityToEdit.isChallenge ? false : true),
				};

				//Is the new conectivity associated with a challenge?  If so, add the details in JSON String format
				if (conectivityToEdit.isChallenge && conectivityToEdit.challengeOptions) tempConectivityToEdit.challengeOptions = JSON.stringify(conectivityToEdit.challengeOptions);

				//Is the updated conecitivty a Special Event that is manually launched only?  If so, update the details
				if (conectivityToEdit.isSpecialEvent) {
					let eventStartDateTimeTrimmed, eventEndDateTimeTrimmed;
					//Set to event start / end DATE
					eventStartDateTimeTrimmed = moment(eventStartDateTime).startOf('minute').toISOString();
					eventEndDateTimeTrimmed = moment(eventEndDateTime).startOf('minute').toISOString();  

				  //Add recurrence fields
				  tempConectivityToEdit = {...tempConectivityToEdit,
					 eventStartDateTime: eventStartDateTimeTrimmed,
					 eventEndDateTime: eventEndDateTimeTrimmed,
					 launchRepeatOption: launchRepeatOption.id,
					 launchRepeatWeeklyOptions: [], 
					 launchRepeatMonthlyOptions: launchRepeatMonthlyOptions.id, 
					 launchRepeatMonthlyOptions_2: launchRepeatMonthlyOptions_2.id, 
					 launchRepeatMonthlyOptions_3: launchRepeatMonthlyOptions_3.id, 
					 launchMonthOption1Checked: launchMonthOption1Checked, 
					 launchMonthOption2Checked: launchMonthOption2Checked, 
					 launchUntilDate: (launchRepeatOption && launchRepeatOption.id === 1 ? eventStartDateTime.toISOString() : launchUntilDate.toISOString()), //Adjust end date to TODAY if there is no recurrence rule; this will allow us to expired (delete) the launch rule later during cleanup        //End date of when rule no longer applies
					 launchUntilCount: (launchUntilCount ? launchUntilCount : -1),   //Defaulting to unused for now; update UI to support COUNT or UNTIL            
					 hasNoSpecificTime:(!conectivityToEdit.hasNoSpecificTime ? false : true),
					 allDayEvent:(!conectivityToEdit.allDayEvent ? false : true),                  
				  };

				  //Loop through selected weekly options (days of weeks) and construct array of IDs
				  if (launchRepeatWeeklyOptions) {
					 for (var j=0 ; j < launchRepeatWeeklyOptions.length; j++) {
						// if (DEBUG_MODE >= 2) console.log("Adding selected weekly option:", launchRepeatWeeklyOptions[j]);
						tempConectivityToEdit.launchRepeatWeeklyOptions.push (launchRepeatWeeklyOptions[j].id);
					 }
				  }                    
				}

				//Auto update Author bio data and customer data based on drop-downs, in case of a name or URL change to either
				//Note, this also handles any reassignment by the SuperAdmin
				//If SuperAdmin, use the company selection and author selection to control these data fields, if set to a different author
				if(isSuperAdmin && authorSelection && customerScopeSelection) {
					const tempAuthoredByUser = users.find(user => user.id === authorSelection.id);
					const tempCreatedByCustomer= customers.find(customer => customer.id === customerScopeSelection.id);

					if (tempAuthoredByUser && tempCreatedByCustomer) {
						tempConectivityToEdit.authorID = tempAuthoredByUser.id;
						tempConectivityToEdit.author = tempAuthoredByUser.firstName + (!tempAuthoredByUser.middleName ? "" : " " + tempAuthoredByUser.middleName.substr(0,1)) + " " + tempAuthoredByUser.lastName;
						tempConectivityToEdit.authorInitials = getUserInitials(tempAuthoredByUser);
						tempConectivityToEdit.authorUrl = tempAuthoredByUser.avatarUrl;
						tempConectivityToEdit.createdByCustomerID = tempCreatedByCustomer.id;         
						tempConectivityToEdit.createdByCustomerName =  tempCreatedByCustomer.name;
						tempConectivityToEdit.createdByCustomerlogo =  tempCreatedByCustomer.logo;
					} else {
						if (DEBUG_MODE > 0) console.error("Error - could not match author or customer", authorSelection,customerScopeSelection, tempAuthoredByUser, tempCreatedByCustomer );
					}
				}

				//Sync the Scope if the SuperAdmin or Editor assigned a Global Conectivity to a customer (i.e., drafted it for the company); only do this for GLOBAL since could be private and installed from COMMUNITY   
				if (tempConectivityToEdit.publicationStatus === "PRIVATE" && tempConectivityToEdit.conectivityScope === "GLOBAL") {
					tempConectivityToEdit.conectivityScope = "CUSTOMER_SPECIFIC";   //Mark scope customer-specific
				}


				//Did the author edit a PUBLISHED conectivity?  If so, move back to REVIEW unless SuperAdmin 
				//This allows a user to edit their shared conectivities after publication but puts back in our review Q.  Same for Editors of Global conectivities
				if (!isSuperAdmin && tempConectivityToEdit.publicationStatus === "PUBLISHED") {
					tempConectivityToEdit.publicationStatus = "REVIEW";   //Mark scope customer-specific
					if (DEBUG_MODE >= 2) console.log("Reassigned to REVIEW status");
				}

				   
				//If Admin && conectivity has been published then allow user to activate or deactivate the Conectivity for the customer
				if (isAdmin) {
					
					//Remove the customer ID from the inactive list if present and no longer marked inactive
					if (!conectivityToEdit.inactive  && conectivityToEdit.inactiveForCustomers) {
										
						var indexToRemove = conectivityToEdit.inactiveForCustomers.indexOf(currentUser.customerID);
							
						if (indexToRemove>-1) {
							if (DEBUG_MODE >= 2) console.log('Removing customer to make Conectivity active');
					 
							//grab the current list
							tempConectivityToEdit.inactiveForCustomers = conectivityToEdit.inactiveForCustomers;
	
							tempConectivityToEdit.inactiveForCustomers.splice(indexToRemove,1); //remove current customer
							if (DEBUG_MODE >= 2) console.log('Found customer to remove at index:', indexToRemove, tempConectivityToEdit.inactiveForCustomers);
	
						}
					}
	
					//Add customer to the INACTIVE list for the Conectivity if they should be inactive and not present already in the list
					if (conectivityToEdit.inactive) {
						if (!conectivityToEdit.inactiveForCustomers) {
							tempConectivityToEdit.inactiveForCustomers = [currentUser.customerID]; //Just add the current customer to an array
							if (DEBUG_MODE >= 2) console.log('Added customer to empty array of inactiveForCustomers', tempConectivityToEdit.inactiveForCustomers);
							
						} else if (!conectivityToEdit.inactiveForCustomers.some(entry => entry === currentUser.customerID)) { //If exists, does it already contain the customer ID?
	
							if (DEBUG_MODE >= 2) console.log('Adding customer to existing list inactiveForCustomers', tempConectivityToEdit.inactiveForCustomers);
	
							//grab the current list and push this customer
							tempConectivityToEdit.inactiveForCustomers = conectivityToEdit.inactiveForCustomers; //grab existing customers or empty list
							tempConectivityToEdit.inactiveForCustomers.push(currentUser.customerID); //push the new customer onto the existing array                          
							if (DEBUG_MODE >= 2) console.log('Added customer to list inactiveForCustomers', tempConectivityToEdit.inactiveForCustomers);  
						}
					}
				}
	
			   //Hard code particular image for the category.  This is a change
				tempConectivityToEdit.imageID = "";
				if (categoryDropDownSelection.shortLabel === "STRESS") {
					tempConectivityToEdit.image = CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH;
				} else if (categoryDropDownSelection.shortLabel === "SOCIAL") {
					tempConectivityToEdit.image =  CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH;
				} else if (categoryDropDownSelection.shortLabel === "TEAM") {
				   tempConectivityToEdit.image=  CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH;
				} else if (categoryDropDownSelection.shortLabel === "PERSONAL") {
					 tempConectivityToEdit.image=  CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH;
				}
	
				//Create a Rating Statistics record if one does not yet exist for this Conectivity
				//Won't be needed once all existing conectivities have been updated
				if (!tempConectivityToEdit.ratingStatsID) {
					const tempRatingStats = {... RATING_STATS_INITIAL_STATE, conectivityID: tempConectivityToEdit.id};
					const tempInsertedRatingStats = await invokeAPI(createRatingStats, 'createRatingStats', tempRatingStats);
					tempConectivityToEdit.ratingStatsID = tempInsertedRatingStats.id; //Store in the conectivity being edited   
					if (DEBUG_MODE >= 2) console.log('Successfully created new Rating Stats record for conectivity being edited', tempInsertedRatingStats);
				}
				
				//Call GraphQL with correct parameters to edit the Conectivity in the  backend   
				//Get the returned, updated object so we can update our local data with all the fields
				const tempInsertedConectivity = await invokeAPI(updateConectivity, 'updateConectivity', tempConectivityToEdit);        
				if (DEBUG_MODE >= 2) console.log('Successfully edited conectivity', tempInsertedConectivity);
				
				//Update our local state just in case the AppSync sockets are offine for this user
				const tempConectivities = [...conectivitiesRef.current];   //Get our state data for existing conectivityGlobals
				const index = tempConectivities.findIndex(conectivityGlobal => conectivityGlobal.id === tempInsertedConectivity.id);
				if (index > -1) {
					//Update system-wide state data with the UPDATED conectivityGlobal
					//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
					tempConectivities[index] = tempInsertedConectivity;    //Replace the existing conectivityGlobal with the updated one just received
					setConectivities(tempConectivities);
					if (DEBUG_MODE >= 2) console.log("Conectivities after UPDATE", tempConectivities, tempInsertedConectivity);
				
				} else {
					if (DEBUG_MODE >= 2) console.log("Error: No matching conectivity found to update", tempConectivities);
				}                
				
	 
			} catch (err) {
				successFlag = false;
				if (DEBUG_MODE >= 2) console.log('error editing conectivity:', err);
			}
			
			return successFlag;
	
	}
	
	async function invokeDeleteConectivity ({connectivityToDelete}) {
	
		if (DEBUG_MODE >= 2) console.log("Deleting Conectivity", connectivityToDelete);
		
		var successFlag = true;  
	
		if (connectivityToDelete) {
			try {
		
				await invokeAPI(deleteConectivity, 'deleteConectivity', {id: connectivityToDelete.id});
				if (!connectivityToDelete.ratingStatsID) await invokeAPI(deleteRatingStats, 'deleteRatingStats', {id: connectivityToDelete.ratingStatsID});
 
				 //Update our local state just in case the AppSync sockets are offine for this user
				var tempConectivities = conectivitiesRef.current.filter(element => element.id !== connectivityToDelete.id);
				setConectivities(tempConectivities); //Delete state data with newly sorted list  

			} catch (err) {
				if (DEBUG_MODE >= 2) console.log('error deleting conectivity:', err);
				successFlag = false;
			} 
		}
		return successFlag;
	}    
	

	async function invokeDownloadCommunityConectivity ({connectivityToDownload}) {
	
			var successFlag = true;  
	
			 //Set the customer and author information
			var authorID = (currentUser ? currentUser.id : "");
			var author = currentUser.firstName + (!currentUser.middleName ? "" : " " + currentUser.middleName.substr(0,1)) + " " + currentUser.lastName;
			var authorInitials = getUserInitials(currentUser);
			var authorUrl = currentUser.avatarUrl;
			var createdByCustomerID = currentUser.customer.id;         
			var createdByCustomerName =  currentUser.customer.name;
			var createdByCustomerlogo =  currentUser.customer.logo;	                
			

			try {    
	
				const tempConectivityToAdd = {
					//customer and author data fields
					authorID: authorID,
					author: author,
					authorInitials: authorInitials,
					authorUrl: authorUrl,
					createdByCustomerID: createdByCustomerID,         
					createdByCustomerName:  createdByCustomerName,
					createdByCustomerlogo:  createdByCustomerlogo,	                
					subscribingCustomers: [],
				  
					//conectivity details
					title: connectivityToDownload.title,
					description: connectivityToDownload.description,
					conectCoins: connectivityToDownload.conectCoins,
					approvedPaidTime: connectivityToDownload.approvedPaidTime,
					signupDays: connectivityToDownload.signupDays,                         
					categoryID: connectivityToDownload.categoryID,                          
					imageID: connectivityToDownload.imageID,
					image:connectivityToDownload.image,
					conectivityScope: "INSTALLED_FROM_COMMUNITY",                                 
					badgesDEI: connectivityToDownload.badgesDEI,
					badgesCS: connectivityToDownload.badgesCS,
					inactiveForCustomers : [], 
					instructions: connectivityToDownload.instructions,
					publicationStatus: "PRIVATE",                                
					costPerPerson: connectivityToDownload.costPerPerson,
					costFixed: connectivityToDownload.costFixed,
					
					//Data for the Conectivity that was originally shared with the Conectere Community
					sharedConectivityID: connectivityToDownload.id,             //ID of original SHARED Community Conectivity from which this private conectivity was copied,
					sharedDate: connectivityToDownload.sharedDate,              //Date when the (parent) Conectivity was shared with the Conectere Community
					numDownloads:0,
					
					//Knobs
					isTemplate: false, 
					isManuallyScheduledOnly: connectivityToDownload.isManuallyScheduledOnly,
					isSchedulableByAdminOnly: connectivityToDownload.isSchedulableByAdminOnly,
					isSpecialEvent: connectivityToDownload.isSpecialEvent,

					//Ratings and Likes
					userLikes: []               //Array of IDs for users that "LIKED" this new Conectivity   
				};
 
	
				//Call GraphQL with correct parameters to create in backend 
				var tempInsertedConectivity = await invokeAPI(createConectivity, 'createConectivity',  tempConectivityToAdd);
				
				//Create an empty Rating Stats record
				const tempRatingStats = {... RATING_STATS_INITIAL_STATE, conectivityID: tempInsertedConectivity.id};
				const tempInsertedRatingStats = await invokeAPI(createRatingStats, 'createRatingStats', tempRatingStats);
				
				//Update the conectivity with the ratings ID
				tempInsertedConectivity = await invokeAPI(updateConectivity, 'updateConectivity', {id:tempInsertedConectivity.id, ratingStatsID: tempInsertedRatingStats.id});  
				if (DEBUG_MODE >= 2) console.log('Successfully installed new conectivity from community', tempInsertedConectivity, tempInsertedRatingStats);
	
			} catch (err) {
				successFlag = false;
				if (DEBUG_MODE >= 2) console.log('error creating conectivity:', err);
			}
			
		return successFlag;
	}    
		
	//return the Conectivity Context provider
	  return (
	<div>
		  
		<ConectivityContext.Provider value={
		{   
			//Scheduled conectivities
			scheduledConectivities, 
			scheduledConectivitiesToDisplay, 

			//Conectivities
			conectivities,
			conectivitiesReadyForLaunch, conectivitiesReadyForLaunchOptions, conectivitiesCommunity, conectivitiesForDashboard,
			templateConectivities,
			templateOptions, 
			invokeCreateConectivity, invokeUpdateConectivity, invokeDeleteConectivity, invokeDownloadCommunityConectivity,

			//Launch Rules
			launchRulesLoaded, launchRules, launchRulesCreatedByThisUser, numberOfLaunchesToday,
			upcomingLaunches, upcomingLaunchesCreatedByThisUser, launchRulesInvolvingThisUser,

			//Conectivity Images            
			imageOptions, setImageOptions,
			filteredImageOptions, setFilteredImageOptions,

			//Invitations and calendar events state data
			invitationToUpdate, setInvitationToUpdate,
			messagesToShow, setMessagesToShow,
			chatWindowID, setChatWindowID,
		}}>
		  {children}
		</ConectivityContext.Provider>
	</div>
  ); 
	
};


export { ConectivityContext, ConectivitiesProvider};


//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, GIPHY_ID_IDENTIFIER, CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD,
		  COLOR_WHITE, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR, RATING_STATS_INITIAL_STATE,  CONECTIVITY_DESCRIPTION_MAX_LENGTH,   
		  SAFFRON_COLOR,
		  BALANCE_COLOR_CHIP,
		  SOCIAL_COLOR_CHIP,
		  TEAM_COLOR_CHIP,
		  GROWTH_COLOR_CHIP
} from '../data/conectereConfigData';


import React, { useEffect, useState, useContext, useRef  } from 'react';

//Context
import { DisplayContext } from '../context/displayContext';            

//Utils
import { MessagesButton, LeaderboardButton, getRandomInt, truncateStringByWord, truncateStringWithDots, formatDateWithDayOfWeek, displayExpirationTime } from "./generalUtils";
import {NOW, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3, constructRecurrenceRule, getRecurrenceRuleNextLaunchDate} from "./dateTimeUtils";
import { generateUsersToInvite, getUserInitials } from "./userAndTeamUtils";
import { invokeAPI, fetchUser } from "./databaseUtils";

//GraphQL
import { processConectivityByLambda, enqueueCommand } from '../graphql/mutations';
import { getUser } from '../graphql/queries';

import moment from 'moment';
import parse from "html-react-parser";              //Needed to render HTML from the Editor

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

//Components
import Select from 'react-select';   //Version with support from multiple selections

//Our Components
import StarRatings from "../Components/starRatings/starRatings";
import ConectivityDurationTimer from '../Components/conectivityDurationTimer/conectivityDurationTimer';

import { v4 as uuidv4 } from 'uuid';    
import { Invitations } from '../Components/invitation/invitation';
import { InvitationsV2 } from '../Components/invitation/invitaionV2';

//Functions for sorting Conectivities based title
export function compareConectivitiesByTitle(a, b) {
	if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
	if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
	return 0;
}
	
//Functions for sorting ConectivityNumber based Most Favorite 
export function compareConectivitiesMostFavorite(a, b) {

	if (a?.ratingStats?.ratingsAverage > b?.ratingStats?.ratingsAverage) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b);  
		return -1;
		}
	if (a?.ratingStats?.ratingsAverage < b?.ratingStats?.ratingsAverage) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b);  
		return 1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b); 
	return 0;

}

//Functions for sorting ConectivityNumber based Most Favorite 
export function compareConectivitiesLeastFavorite(a, b) {

	if (a?.ratingStats?.ratingsAverage > b?.ratingStats?.ratingsAverage) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (a?.ratingStats?.ratingsAverage < b?.ratingStats?.ratingsAverage) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	return 0;

}

export function compareConectivitiesRowsMostFavorite(a, b) {

	if (a?.ratingsAverage > b?.ratingsAverage) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b);  
		return -1;
		}
	if (a?.ratingsAverage < b?.ratingsAverage) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b);  
		return 1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b); 
	return 0;

}

export function setConectivityImageUrlByCategory (categoryLabel, color) {
	
	// return conectivity.imageS3.url;
	
	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	
	if (color) {          
		if (categoryLabel === "STRESS" || categoryLabel === "BALANCE") {
			return CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH;
		} else if (categoryLabel === "SOCIAL") {
			return CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH;
		} else if (categoryLabel === "TEAM" || categoryLabel === "TEAM BUILDING") {
		   return CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH;
		} else if (categoryLabel === "PERSONAL" || categoryLabel === "GROWTH") {
			 return CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH;
		} else if (categoryLabel === "TUTORIAL") {
			 return CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
		} else if (categoryLabel === "DEI") {
			 return CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH;
		} else if (categoryLabel === "CS") {
			 return CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH;
		}
   } else {
	   
		//NOTE - WE DON'T HAVE WHITE TUTORIAL, DEI, CS IMAGES - UPDATE AT SOME POINT
		if (categoryLabel === "STRESS" || categoryLabel === "BALANCE") {
			return CONECTERE_CONFIG_DATA.BALANCE_IMAGE_WHITE_PATH;
		} else if (categoryLabel === "SOCIAL") {
			return CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_WHITE_PATH;
		} else if (categoryLabel === "TEAM" || categoryLabel === "TEAM BUILDING") {
		   return CONECTERE_CONFIG_DATA.TEAM_IMAGE_WHITE_PATH;
		} else if (categoryLabel === "PERSONAL" || categoryLabel === "GROWTH") {
			return CONECTERE_CONFIG_DATA.GROWTH_IMAGE_WHITE_PATH;
	   } else if (categoryLabel === "TUTORIAL") {
			 return CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
		} else if (categoryLabel === "DEI") {
			 return CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH;
		} else if (categoryLabel === "CS") {
			 return CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH;
		}           
	}
}     

 //Assist functions for preview
 export function setHeaderColorByCategory(categoryLabel) {

	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	 
	//  console.log ("Setting preview header color", categoryLabel)

	if (categoryLabel ==="STRESS" || categoryLabel === "BALANCE") {
		return (BALANCE_COLOR);
	} else if (categoryLabel ==="SOCIAL") {
		return (SOCIAL_COLOR);
	} else if (categoryLabel ==="TEAM" || categoryLabel === "TEAM BUILDING") {
		return (TEAM_COLOR);
	} else if (categoryLabel ==="PERSONAL" || categoryLabel === "GROWTH") {
		return (GROWTH_COLOR);
	} else if (categoryLabel ==="TUTORIAL") {
		return (TUTORIAL_COLOR);
	}  else return(COLOR_BLUE_TEXT);
}    


 export function setBorderColorClassByCategory(categoryLabel) {

	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	 
	if (categoryLabel ==="STRESS" || categoryLabel === "BALANCE") {
		return ('blue-border');
	} else if (categoryLabel ==="SOCIAL") {
		return ('green-border');
	} else if (categoryLabel ==="TEAM" || categoryLabel === "TEAM BUILDING") {
		return ('purple-border');
	} else if (categoryLabel ==="PERSONAL" || categoryLabel === "GROWTH") {
		return ('yellow-border');
	} else return('purple-border');
} 

 export function setBorderColorClassByConnectivityCardCategory(categoryLabel) {

	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	 
	if (categoryLabel ==="STRESS" || categoryLabel === "BALANCE") {
		return ('connectivity-card-blue-border');
	} else if (categoryLabel ==="SOCIAL") {
		return ('connectivity-card-green-border');
	} else if (categoryLabel ==="TEAM" || categoryLabel === "TEAM BUILDING") {
		return ('connectivity-card-purple-border');
	} else if (categoryLabel ==="PERSONAL" || categoryLabel === "GROWTH") {
		return ('connectivity-card-yellow-border');
	} else return('connectivity-card-purple-border');
} 

export function setBackgroundColorClassByColorValue(color) {

	if (color) color = color.toUpperCase();
	 
	if (color === BALANCE_COLOR) {
		return ('blue-background');
	} else if (color ===SOCIAL_COLOR) {
		return ('green-background');
	} else if (color === TEAM_COLOR) {
		return ('purple-background');
	} else if ( color === GROWTH_COLOR) {
		return ('yellow-background');
	} else return('purple-background');
}  

export function setTextColorClassByColorValue(color) {
	if (color) color = color.toUpperCase();
	 
	if (color === BALANCE_COLOR) {
		return ('blue');
	} else if (color ===SOCIAL_COLOR) {
		return ('green');
	} else if (color === TEAM_COLOR) {
		return ('purple');
	} else if ( color === GROWTH_COLOR) {
		return ('yellow');
	} else return('purple');
}

export function setBackgroundColorClassByCategory(categoryLabel) {

	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	 
	if (categoryLabel ==="STRESS" || categoryLabel === "BALANCE") {
		return ('blue-background');
	} else if (categoryLabel ==="SOCIAL") {
		return ('green-background');
	} else if (categoryLabel ==="TEAM" || categoryLabel === "TEAM BUILDING") {
		return ('purple-background');
	} else if (categoryLabel ==="PERSONAL" || categoryLabel === "GROWTH") {
		return ('yellow-background');
	} else if (categoryLabel ==="TUTORIAL") {
		return ('black-background');
	} else return('purple-background');
}  

export function setHeaderColorClassByCategory(categoryLabel) {

	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	 
	//  console.log ("Setting preview header color", categoryLabel)

	if (categoryLabel ==="STRESS" || categoryLabel === "BALANCE") {
		return ('balance');
	} else if (categoryLabel ==="SOCIAL") {
		return ('social');
	} else if (categoryLabel ==="TEAM" || categoryLabel === "TEAM BUILDING") {
		return ('team');
	} else if (categoryLabel ==="PERSONAL" || categoryLabel === "GROWTH") {
		return ('growth');
	} else return('blue');
}  


 export function setHeaderColorByCategoryWithOpacity(categoryLabel,opacity) {
	 
	//  console.log ("Setting preview header color", categoryLabel)

	const BALANCE = "93, 173, 226,";        //  - Balance (blue)
	const TEAM = "187,143,206,";            //Team (purple)
	const SOCIAL = "130, 224, 170,";        //  - Social (green)
	const GROWTH = "247, 220, 111,";        // - Personal (yellow)
	const TUTORIAL = "0, 0, 0,";            // - Tutorial (black)
	 
	if (categoryLabel =="STRESS") {
		return ("rgba(" + BALANCE + opacity.toString() +")");
	} else if (categoryLabel =="SOCIAL") {
		return ("rgba(" + SOCIAL + opacity.toString() +")");
	} else if (categoryLabel =="TEAM") {
		return ("rgba(" + TEAM + opacity.toString() +")");
	} else if (categoryLabel =="PERSONAL") {
		return ("rgba(" + GROWTH + opacity.toString() +")");
	} else if (categoryLabel =="TUTORIAL") {
		return ("rgba(" + TUTORIAL + opacity.toString() +")");
	}  else return(COLOR_BLUE_TEXT);
}


export function setBackgroundGradientClassByCategory(categoryLabel) {
	
	switch (categoryLabel) {
		case "STRESS":
			return "bannerFadeLeftToRightBalance";
		case "SOCIAL":
			return "bannerFadeLeftToRightSocial";
		case "TEAM":
			return "bannerFadeLeftToRightTeam";
		case "PERSONAL":
			return "bannerFadeLeftToRightGrowth";                    
		default:
		return "bannerFadeLeftToRightBalance";
	}
}

export function setHeaderColorByScheduledConectivity(scheduledConectivity) {
	
	if (!scheduledConectivity || !scheduledConectivity.conectivity) return BALANCE_COLOR;

	// if (DEBUG_MODE >= 2) console.log("Setting header color for a scheduledConectivity", scheduledConectivity);
	
	var conectivity = scheduledConectivity.conectivity;
	
	if (conectivity.category.label =="STRESS") {
		return (BALANCE_COLOR);
	} else if (conectivity.category.label =="SOCIAL") {
		return (SOCIAL_COLOR);
	} else if (conectivity.category.label =="TEAM") {
		return (TEAM_COLOR);
	} else if (conectivity.category.label =="PERSONAL") {
		return (GROWTH_COLOR);
	} else if (conectivity.category.label =="TUTORIAL") {
		return (TUTORIAL_COLOR);
	}  else return("green");
}

export function setBadgeByScheduledConectivity(scheduledConectivity, color) {
	
	if (!scheduledConectivity || !scheduledConectivity.conectivity) return BALANCE_COLOR;

	// if (DEBUG_MODE >= 2) console.log("Setting header color for a scheduledConectivity", scheduledConectivity);
	
	var conectivity = scheduledConectivity.conectivity;
	var categoryLabel = conectivity.category.label;

	if (color) {
		if (categoryLabel === "STRESS" || categoryLabel === "BALANCE") {
			return BALANCE_COLOR;
		} else if (categoryLabel === "SOCIAL") {
			return SOCIAL_COLOR;
		} else if (categoryLabel === "TEAM" || categoryLabel === "TEAM BUILDING") {
		   return TEAM_COLOR;
		}  else if (categoryLabel === "PERSONAL" || categoryLabel === "GROWTH") {
			return SAFFRON_COLOR;
		} else if (conectivity.category.label =="TUTORIAL") {
			return (TUTORIAL_COLOR);
		} else return BALANCE_COLOR;
	} else {
		if (categoryLabel === "STRESS" || categoryLabel === "BALANCE") {
			return CONECTERE_CONFIG_DATA.BALANCE_BADGE_IMAGE_PATH;
		} else if (categoryLabel === "SOCIAL") {
			return CONECTERE_CONFIG_DATA.SOCIAL_BADGE_IMAGE_PATH;
		} else if (categoryLabel === "TEAM" || categoryLabel === "TEAM BUILDING") {
		   return CONECTERE_CONFIG_DATA.TEAM_BADGE_IMAGE_PATH;
		}  else if (categoryLabel === "PERSONAL" || categoryLabel === "GROWTH") {
			return CONECTERE_CONFIG_DATA.GROWTH_BADGE_IMAGE_PATH;
		   } else {
			return CONECTERE_CONFIG_DATA.BALANCE_BADGE_IMAGE_PATH;
		}
	}

}

export function setHeaderColorRandomCategory() {

	var category = getRandomInt(1,4);
	
	if (category === 1) {
		return (BALANCE_COLOR);
	} else if (category === 2) {
		return (SOCIAL_COLOR);
	} else if (category === 3) {
		return (TEAM_COLOR);
	} else if (category === 4) {
		return (GROWTH_COLOR);
	}  else return("green");
}

//Useful as a deterministic way to assign a color that won't change on re-renders
export function setHeaderColorByStringLength(str) {
	if (str === null) return;
	
	var category = str?.length % 4;
	if (category === 0) {
		return (BALANCE_COLOR);
	} else if (category === 1) {
		return (SOCIAL_COLOR);
	} else if (category === 2) {
		return (TEAM_COLOR);
	} else if (category === 3) {
		return (GROWTH_COLOR);
	}  else return("green");
}

//Useful as a deterministic way to assign a color that won't change on re-renders
export function setHeaderColorByStringLengthV2(str) {
	if (str === null) return;
	
	var category = str?.length % 4;
	if (category === 0) {
		return (BALANCE_COLOR_CHIP);
	} else if (category === 1) {
		return (SOCIAL_COLOR_CHIP);
	} else if (category === 2) {
		return (TEAM_COLOR_CHIP);
	} else if (category === 3) {
		return (GROWTH_COLOR_CHIP);
	}  else return("green");
}

export function setBackgroundByPublishedStatus (pubStatus) {
  

	if (pubStatus === "PUBLISHED") {
		return ("#2dc937");
	} else if (pubStatus === "REVIEW") {
		return ("#fde52f");
	} else if (pubStatus === "DRAFT") {
		return ("#db7b2b");
	}  else if (pubStatus === "HOLD") {
		return ("#a1a6b5");
   } else return("#6495ED");
} 

export function setScopeLabel (scope) {

	if (scope === "GLOBAL") {
		return ("GLOBAL");
	} else if (scope === "CUSTOMER_SPECIFIC") {
		return ("PRIVATE");
	} else if (scope === "CUSTOMER_SHARED") {
		return ("SHARED");
	}  else return("ERROR");
} 

export function setScopeBackground (scope) {

	if (scope === "GLOBAL") {
		return ("#2dc937");
	} else if (scope === "PRIVATE") {
		return ("#db7b2b");
	} else if (scope === "SHARED") {
		return ("#cc3232");
	}  else return("ERROR");
} 
 

//Functions for  sorting Conectivities based on expiration
export function compareConectivitiesByDateShared(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
	
	if (a.sharedDate === undefined || b.sharedDate === undefined) return 0;
	
	const aDateTime = moment(a.sharedDate, "YYYY-MM-DDTHH:mm:ssZ");
	const bDateTime = moment(b.sharedDate, "YYYY-MM-DDTHH:mm:ssZ");
	
	if (aDateTime.isAfter(bDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (bDateTime.isAfter(aDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	return 0;

}
	
//Functions for  sorting Conectivities based on expiration
export function compareConectivitiesByExpirationDate(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
	
	const aDateTime = moment(a.closingDateTime, "YYYY-MM-DDTHH:mm:ssZ");
	const bDateTime = moment(b.closingDateTime, "YYYY-MM-DDTHH:mm:ssZ");
	
	if (aDateTime.isAfter(bDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (bDateTime.isAfter(aDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	return 0;

}


//Test whether this scheduled conectivity has actually expired but has not yet been marked expired by our Lamda functions
export function expiredConectivity(scheduledConectivity) {
	// if (DEBUG_MODE >= 2) console.log("Checking whether the conectivity is expired", scheduledConectivity);
	// get time set in user's computer time / OS
	const currentDateTime = moment(); 
	
	if (!scheduledConectivity.conectivity) {
		if (DEBUG_MODE >= 2) console.log("Scheduled conectivity has no conectivity");
		return true;
	}
		
   // transform closing time (expiration time) into a Moment time object
   const closingDateTime = moment(scheduledConectivity.closingDateTime, "YYYY MM DDTHH mm ssZ"); 
   if (closingDateTime.isBefore (currentDateTime)) return true;  //expired 
   return false; //not expired
}


export function setConectivityImageUrlByConectivity (conectivity, color){
	
	// if (DEBUG_MODE >= 2) console.log("setImageURL Called", conectivity, color);
	
	if (!conectivity) return "";
	if (!conectivity.category) return "";
	if (!conectivity.category.label) return "";
	
	return setConectivityImageUrlByCategory(conectivity.category.label, color);
}     

export function isSubscribing(conectivity, customerID) {
	
	if (customerID === undefined || conectivity === undefined) return false;
	
	if (conectivity.subscribingCustomers === undefined) return false;
	
	if (conectivity.subscribingCustomers.some(id => id === customerID)) return true;
	else return false;
	
}


//Functions for  sorting Conectivities based on expiration
export function compareScheduledConectivitiesByClosingDate(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
	
	const aDateTime = moment(a.closingDateTime);
	const bDateTime = moment(b.closingDateTime);
	
	if (aDateTime.isAfter(bDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (bDateTime.isAfter(aDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b);   
	return 0;

}

export function getConectivityCategoryText(categoryLabel) {
	if (categoryLabel) categoryLabel = categoryLabel.toUpperCase();
	switch (categoryLabel) {
		case "STRESS": case "BALANCE": return("Balance");
		case "SOCIAL":  return("Social");
		case "TEAM": case "TEAM BUILDING": return("Team");
		case "GROWTH": case "PERSONAL": return("Balance");
		case "TUTORIAL": return("Tutorial");
		default: return("");

	}
}  

//Functions for  sorting Conectivities based on expiration
// SORT to STRESS, TEAM, SOCIAL, GROWTH
export function compareScheduledConectivitiesByCategory(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);  
	const CATEGORY_SORT_ORDER = ['STRESS', 'SOCIAL', 'TEAM', 'PERSONAL', 'TUTORIAL'];
	
	if (!a || !a.conectivity || !a.conectivity.category || !a.conectivity.category.label || 
		!b || !b.conectivity || !b.conectivity.category || !b.conectivity.category.label) return 0;
	
	const indexA = CATEGORY_SORT_ORDER.indexOf(a.conectivity.category.label);
	const indexB = CATEGORY_SORT_ORDER.indexOf(b.conectivity.category.label);
	
	if (indexA > indexB)  return 1;
	if (indexB > indexA) return -1;
	return 0;
}

export function conectivityTextContainsTerm (searchTerm, conectivity) {
	 if (searchTerm && conectivity) {      
			var searchTermToTest = searchTerm.toLowerCase();
			if (conectivity.title.toLowerCase().includes(searchTermToTest) || conectivity.description.toLowerCase().includes(searchTermToTest)) {
				return true;
			}
			
			if (conectivity.category.label.toLowerCase().includes(searchTermToTest)) {
				return true;
			}
		
			//Special operations to check other terms against our categories
			if (("balance".includes(searchTermToTest) && conectivity.category.label.toLowerCase().includes("stress")) ||
				("growth".includes(searchTermToTest) && conectivity.category.label.toLowerCase().includes("personal"))) {
				return true;
			}
	}  
	return false;      
}

export function conectivitySatisfiesFilter(filter, conectivity) {

	// Our filters "mentorship", "new employees", "yoga", walking", "exercise", "< 15 minutes", "< 30 minutes", "virtual tour"];
	
	if (DEBUG_MODE >= 3) console.log("Checking conectivity against filter", conectivity, filter);
	if (!filter) return false;
	var passes = false;
	switch (filter.toLowerCase()) {
		case 'template':
			passes = conectivity.isTemplate;
			break;
		case 'draft':
			passes = conectivity.publicationStatus === "DRAFT" && !conectivity.isTemplate;
			break;
		case 'review':
			passes = conectivity.publicationStatus === "REVIEW";
			break;
		case 'published':
			passes = conectivity.publicationStatus === "PUBLISHED";
			break;
		case 'balance':
			passes = conectivity.category.label === "STRESS";
			break;
		case 'social':
			passes = conectivity.category.label === "SOCIAL";
			break;
		case 'team':
			passes = conectivity.category.label === "TEAM";
			break;
		case 'growth':
			passes = conectivity.category.label === "PERSONAL";
			break;            
		case 'dei':
			passes = conectivity.badgesDEI > 0;
			break;
		case 'community service': case 'community':
			passes = conectivity.badgesCS> 0;
			break;            
		case "mentorship":
			passes = conectivityTextContainsTerm("mentor", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("mentee", conectivity);
			break;
		case "new employees":
			passes = conectivityTextContainsTerm("new employee", conectivity);
			break;
		case "walking":
			passes = conectivityTextContainsTerm("walk", conectivity)  && ((conectivityTextContainsTerm("virtual tour", conectivity) === false));
			if (!passes) passes = conectivityTextContainsTerm("stroll", conectivity)  && ((conectivityTextContainsTerm("virtual tour", conectivity) === false));
			break;
		case "yoga":
			passes = conectivityTextContainsTerm("yoga", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("strech", conectivity);
			break;
		case "icebreakers":
			passes = conectivityTextContainsTerm("icebreak", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("conversation start", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("ice break", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("begin your next team meeting", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("begin your team meeting", conectivity);
			break;
		case "exercise":
			passes = conectivityTextContainsTerm("exercise", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("running", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("workout", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("dance", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("jumping", conectivity);
			if (!passes) passes = (conectivityTextContainsTerm("walk", conectivity) && (conectivityTextContainsTerm("virtual tour", conectivity) === false));
			break;
		case "< 15 minutes":
			passes =  (conectivity.approvedPaidTime <= 15);
			break;
		case "< 30 minutes":
			passes =  (conectivity.approvedPaidTime <= 30);
			break;
		case "virtual tours":
			passes = conectivityTextContainsTerm("virtual tour", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("virtual walk", conectivity);
			if (!passes) passes = conectivityTextContainsTerm("virtual stroll", conectivity);
			break;
		default:
		  passes = true;
		
	}
	
  if (DEBUG_MODE >= 3) console.log("Conectivity filter check returning", passes);
   
	return passes;
}   

//Function for testing whether a particular conectivity matches 
// conectivity - the conectivity to test
// activeFilters - an array filters
// keywords - a String of keywords, like "balance, flowers" that all must be present for the conectivity to be considered a match
export function conectivitiesMatchesSearch({conectivity, activeFilters, keywords}) {
   //  if (DEBUG_MODE >= 2) console.log("Testing conectiity matching", conectivity, searchTerm, activeFilters);
	
	let passesFilters = true;

	//Return TRUE if the conectivity matches our search term AND matches ALL of the filters that are set    
	//DOES THE CONECTIVITY MATCH ANY FILTERS
	if (activeFilters && activeFilters.length > 0) passesFilters = activeFilters.some((filter) => conectivitySatisfiesFilter(filter,conectivity));
	
	//DOES THE CONECTIVITY MATCH OUR SEARCH TERM, IF ANY?
	if (passesFilters && keywords && keywords.length > 0) {

		// Break the keywords into an array of terms.  For example, split "elderly, flowers, team" into an array ["elderly", "flowers", "team"]
		// Use regex followed by a filter to remove any empty values
		// https://stackoverflow.com/questions/10346722/how-to-split-a-string-by-white-space-or-comma
		const searchTerms = keywords.split(/[ ,]+/).filter(Boolean);
		if (DEBUG_MODE) console.log("Generated array of terms to further filter search", searchTerms);


		/* OLD CODE FOR MATCHING ALL KEYWORDS
		//Set our pass flag to false if ANY search terms is NOT contained (i.e. if the ContainsTerm call fails for SOME terms)
		if (searchTerms.some((searchTerm) => (conectivityTextContainsTerm(searchTerm, conectivity)===false))) {
			if (DEBUG_MODE) console.log("Search terms not satisfied")
			passesFilters=false;
		}
		*/
		
		/* NEW CODE - FAIL MATCH IF NO KEYWORDS ARE FOUND TO MATCH (i.e., the .some fails) */
		if (searchTerms.some((searchTerm) => (conectivityTextContainsTerm(searchTerm, conectivity)))===false ) {
			if (DEBUG_MODE) console.log("Search terms not satisfied")
			passesFilters=false;
		}
	 }

	return passesFilters;    
}   


	
// CONECTIVITY SELECT COMPONENT
export const DisplayConectivitySelectComponent = ({options, onChangeCallback, isMulti, value, disabled, zIndex, placeholder, name, padding, isClearable}) => {
	
	if (!placeholder) placeholder = " -- select --";
	if (!zIndex) zIndex = "99";
	if (!name) name = 'userDropdownModal';
	if (!padding) padding = "10px";
	if (isClearable === undefined) isClearable = true;
	
	return (
			<div style={{width:"100%", padding:padding, height:"auto", marginLeft:"auto", zIndex:zIndex}}>
				<Select    
					isMulti={isMulti} 
					name={name} 
					options={options} 
					onChange={onChangeCallback} 
					value={value} 
					placeholder={placeholder}
					isDisabled={disabled}
					isClearable={isClearable}
					formatOptionLabel={(conectivityOption, context) => {
					
						let imageSource, color;
						
						//Is this a non-multi) menu option that is currently selected?
						if (!isMulti && context && context.selectValue && context.selectValue.length > 0 && context.context==='menu' && conectivityOption && conectivityOption.id === context.selectValue[0].id) {
							imageSource = setConectivityImageUrlByCategory (conectivityOption.shortLabel, false);
							color = COLOR_WHITE;
						} else {
							 imageSource = setConectivityImageUrlByCategory (conectivityOption.shortLabel, true);
							 color = setHeaderColorByCategory(conectivityOption.shortLabel);
						}
						
						// console.log("Conectivity format label invoked", conectivityOption, context);
						return (
							
								<div className="ContainerNoHeightFlexLeft" style={{minWidth:"250px"}}> 
										<img className="ccImgLarge" src={imageSource} alt={"Conectivity Image"} />
									<span style={{paddingLeft: "5px",color: color, fontSize:"16px"}}>{truncateStringWithDots(conectivityOption.title,50)}</span>   
								</div>
							);
					}}
				  />
				</div>
	  );
	};
	
//
// Conectivity Card Preview - this component shows the full conectivity including instructions, if the expanded option is true 
// It is used on the right side of many screens
//
export const DisplayCardConectivityPreview = ({conectivityToPreview, hideViewButton, isSmall, expanded, showPointer}) => {

	// Context
	const { darkMode } = useContext(DisplayContext); 

	//Init
	if (!hideViewButton) hideViewButton = false;
	if (!expanded) expanded = false;
	const displayColored = false;

	const [expandedPreview, setExpandedPreview] = useState(expanded);
		
	try {

		if (!conectivityToPreview || !conectivityToPreview.id) return null;       
		
		// if (DEBUG_MODE >= 2) console.log("0: DisplayCard Preview Conectivity", conectivityToPreview, hideViewButton, expanded, expandedPreview);
		// if (DEBUG_MODE >= 2) console.log("Outputting category option selection for preview", categoryDropDownSelection);
		
		const headerColor = (displayColored ? "white" : setHeaderColorByCategory(conectivityToPreview.category.label));
		const fontColor = setHeaderColorByCategory(conectivityToPreview.category.label);
		const titleFontColor = (displayColored ? setHeaderColorByCategory(conectivityToPreview.category.label) : "white" );
	
		// if (DEBUG_MODE >= 2) console.log("Header",headerColor)
	
		// var tempInstructions = "";
	   
	   //Grab the static instructions since no Editor like when Adding or Editing a Conectivity
	//   if (showModalEdit || showModalAdd || showModalDelete || showModalPreview || showModalLaunch || showModalTemplateSelection) {
	//       tempInstructions = conectivityToPreview.instructions;
	//         if (DEBUG_MODE >= 2) console.log("1: DisplayCard Preview Conectivity");
	
	//   } else if (editorRef.current) {
	//       tempInstructions = editorRef.current.getContent();
	//         if (DEBUG_MODE >= 2) console.log("2: DisplayCard Preview Conectivity", tempInstructions);
	
	//  }
	 
		const handleExpand = (e) => {
			e.stopPropagation();        
	
			if (DEBUG_MODE >= 2) console.log("Updating state", e, expandedPreview);
			setExpandedPreview(!expandedPreview);
			if (DEBUG_MODE >= 2) console.log("Updating state 2");
		};
						  
		const backgroundClass = setBackgroundColorClassByCategory(conectivityToPreview.category.label);

		  return (
			<div className={`conectivityCardOuterWrapper ${showPointer ? "" : "noPointer" }  `} >
				<div className={`conectivityCardRoot ${isSmall ? "conectivityCardSmall" : ""}`}  style={{borderColor:headerColor}} >
					<div className={`ContainerVerticalSpaceBetween positionRelative fullWidth ${isSmall ? "conectivityCardInnerWrapperSmall" : "conectivityCardInnerWrapperWithInstructions"}`}>
						{displayConectivityHeader({conectivity:conectivityToPreview, backgroundClass, darkMode, headerColor})}
						<div className={`ContainerNoHeightCenter fullWidth conectivityCardDescriptionWrapper TextStyle3  ${darkMode ? "white" : "black"}  `} >
							{truncateStringWithDots(conectivityToPreview.description,CONECTIVITY_DESCRIPTION_MAX_LENGTH)}
						</div>
						{conectivityToPreview.instructions === ""  || hideViewButton ? "" :
						
							<div onClick = {(e) => handleExpand(e)} className="conectivityCardPopOutButton" style={{backgroundColor:headerColor}}>
									 
									 {!expandedPreview ?  
											<div className="ContainerNoHeight"> 
												<div> View</div> <ExpandMoreIcon style={{fontSize:'24px', color:"white"}}/> 
											</div> 
										: <ExpandLessIcon style={{fontSize:'20px', color:"white"}}/> 
										 
									 }
							 </div>
						}
		
						{conectivityToPreview.instructions === null ? "" :
		
							<div className={`conectivityCardInstructionsWrapperHidden ${(expandedPreview || expanded) && "ConectivityCardShowInstructions"}`}  >
								{parse(conectivityToPreview.instructions)}
						  </div>          
						  
						}
					
		
						<div className={`ContainerNoHeightSpaceAround fullWidth positionRelative wrap conectivityCardActionsWrapper ${isSmall ? "TextStyle2" : "TextStyle2" }` } style={{width:"100%"}}>
			
							 <div  style={{display:"flex", padding:"10px 5px", color:headerColor}}>
								   <StarRatings rating={5} readOnly={true} starSize={"12px"}/>
							</div>
							
							 <div style={{display:"flex", padding:"10px 5px", color:headerColor}}>
							   <i> {!conectivityToPreview.approvedPaidTime ? "" : conectivityToPreview.approvedPaidTime + " min"}</i>
							</div>
						
							{!conectivityToPreview.conectCoins>0  ? "" :
							<div className="ContainerNoHeightCenter" style={{display:"flex", padding:"10px 5px", flexwrap:"no-wrap", color:fontColor}}> 
								{!conectivityToPreview ? "" : conectivityToPreview.conectCoins} 
								<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /> 
							</div>                        
							}
							{!conectivityToPreview.badgesDEI>0 ? "" :  
								<div className="ContainerNoHeightCenter" style={{ padding:"0px 10px", flexwrap:"no-wrap", color:fontColor}}> 
									{!conectivityToPreview ? "" : conectivityToPreview.badgesDEI} 
									<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
								</div>
							}
							{!conectivityToPreview.badgesCS>0 ? "" :  
								<div className="ContainerNoHeightCenter" style={{padding:"0px 10px", flexwrap:"no-wrap", color:fontColor}}> 
									{!conectivityToPreview ? "" : conectivityToPreview.badgesCS} 
									<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /> 
								</div>
							}
		
						</div>
					</div>
				</div>
			</div>
		  );
	} catch (err) {
		if (DEBUG_MODE >= 1) console.log("Error rendering conectivity preview",err);
		return null;
	}
};
 
 
 
// Conectivity Handlers   

export async function invokeProcessConectivity ({user, command, scheduledConectivity, conectivity, usersThatParticipatedWithMe, teamsThatParticipatedWithMe, userRating})  {

	try {
		var params = {
			scheduledConectivityID:scheduledConectivity.id,
			conectivityID:conectivity.id,
			usersThatParticipatedWithMe:usersThatParticipatedWithMe,
			teamsThatParticipatedWithMe:teamsThatParticipatedWithMe, 
			userRating:userRating,
		};
		
		const enqueueCommandInput = {
			userID: user.id,
			customerID:user.customerID,
			command: command,
			params: JSON.stringify(params),
		};
			
		//Call GraphQL to directly invoke our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to Process Conectivity - ", enqueueCommandInput);
		const response = await invokeAPI(processConectivityByLambda, 'processConectivityByLambda', enqueueCommandInput);
		if (DEBUG_MODE >= 2) console.log("Process Conectivity Lambda returned", response);        
	
		//Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
		if (response && response.data && response.data.processConectivityByLambda) 
			return { 
				lambdaReturnStatus:response.data.processConectivityByLambda.lambdaReturnStatus,
				returnParams: JSON.parse(response.data.processConectivityByLambda.returnParams),
			}; 
		else return {lambdaReturnStatus: 'FAILED'};
	} catch (err) {
		console.log("Error processing conectivity", err);
		return {lambdaReturnStatus: 'FAILED'};
	}
}
//
// LAUNCH RULE UTILITIES
//


//
// CLOUD-BASED 'INVITE_TO_CONNECT' FUNCTIONS
// MASTER FUNCTIONS FOR MANAGING LAUCHES AND INVITATIONS
//

export async function invokeInviteToConect ({sendingUser, scheduledConectivityID, conectivityID, usersToInvite, teamsToInvite, inviteMessage, messageGIF,
	eventStartDateTime, eventEndDateTime, launchUntilDate, launchUntilCount, launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions,
	launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, launchMonthOption1Checked, launchMonthOption2Checked, allDayEvent, attendeeEmailsForCalendar,
	isMentorMeeting, isSponsorMeeting, options}) {

	let successFlag = false;
   
	if (!eventStartDateTime || !eventEndDateTime || !sendingUser || ((!usersToInvite || usersToInvite.length === 0) && (!teamsToInvite || teamsToInvite.length === 0))) {
		if (DEBUG_MODE >= 2) console.log("ERROR GENERATING LAUNCH RULE - improper params", sendingUser, usersToInvite, usersToInvite);
		return successFlag;        
	} 

	if (DEBUG_MODE >= 2)  console.log("Handling Invite To Conectivity invoked!", scheduledConectivityID, conectivityID, inviteMessage, messageGIF, 
		eventStartDateTime.toISOString(), eventEndDateTime.toISOString(), usersToInvite, teamsToInvite,
		allDayEvent, launchUntilDate, launchUntilCount, launchRepeatOption,  launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3,  
		launchMonthOption1Checked, launchMonthOption2Checked, attendeeEmailsForCalendar, 
		isMentorMeeting, isSponsorMeeting);

	if (DEBUG_MODE > 1 && options) console.log("Challenge options:", options);
	
	//Init
	if (!usersToInvite) teamsToInvite = [];
	if (!teamsToInvite) teamsToInvite = [];
	
	try {
		//Call to our Enqueue Command lambda to inject the command into our cloud
		 const params = {
			origin:'CONECTERE',
			scheduledConectivityID: scheduledConectivityID,	   //Optional - include if sending an invitation for a running SC; if ScheduledConectivityID is NULL, then a new SC will be created (launched) by the cloud                                         
			conectivityID: conectivityID,	                                             
			usersToInvite: usersToInvite,    //Array of users for whom to launch this Conectivity 
			teamsToInvite: teamsToInvite,    //Array of teams for which to launch this Conectivity            
			message: inviteMessage,                   //Any message to include when launching the conectivity
			messageGIF: (messageGIF ? GIPHY_ID_IDENTIFIER + messageGIF.id : null),            //Any GIF  to also include when launching?
			senderID: sendingUser.id,                                  //Who sent the invite
			senderAvatarUrl: sendingUser.avatarUrl,                    //location where avatar image is stored on the S3 bucket
			senderAvatarName: sendingUser.firstName + " " + sendingUser.lastName,
			senderAvatarInitials: getUserInitials(sendingUser),
			isMentorMeeting: isMentorMeeting,
			isSponsorMeeting: isSponsorMeeting,
			
			//Event date/time information including recurrence
			eventStartDateTime: eventStartDateTime.toISOString(),            //Start date & time of calendar event in UTC
			eventEndDateTime: eventEndDateTime.toISOString(),                //End date & time of calendar event in UTC
			allDayEvent: allDayEvent,
			launchUntilDate: (launchRepeatOption.id === 1 ? eventStartDateTime.toISOString() : launchUntilDate.toISOString()), //Adjust end date to TODAY if there is no recurrence rule; this will allow us to expired (delete) the launch rule later during cleanup        //End date of when rule no longer applies
			launchUntilCount: (launchUntilCount ? launchUntilCount : -1),                                                        //Defaulting to unused for now; update UI to support COUNT or UNTIL
			launchRepeatOption:launchRepeatOption.id,                      //See Conectere Config - id of selected option
			launchRepeatWeeklyOptions:[],                                               //See Conectere Config - id of selected option
			launchRepeatMonthlyOptions:launchRepeatMonthlyOptions.id,       //See Conectere Config - id of selected option
			launchRepeatMonthlyOptions_2:launchRepeatMonthlyOptions_2.id,   //See Conectere Config - id of selected option
			launchRepeatMonthlyOptions_3:launchRepeatMonthlyOptions_3.id,  //See Conectere Config - id of selected option
			launchMonthOption1Checked: launchMonthOption1Checked,    
			launchMonthOption2Checked: launchMonthOption2Checked, 
			attendeeEmailsForCalendar: attendeeEmailsForCalendar,

			options:options,        
		};

	  //Loop through selected weekly options (days of weeks) and construct array of IDs
		 if (launchRepeatWeeklyOptions) {
			 for (var j=0 ; j < launchRepeatWeeklyOptions.length; j++) {
				// if (DEBUG_MODE >= 2) console.log("Adding selected weekly option:", launchRepeatWeeklyOptions[j]);
				params.launchRepeatWeeklyOptions.push (launchRepeatWeeklyOptions[j].id);
			 }
		 }

		// console.log("Generated params object", JSON.stringify(params));
		
		const enqueueCommandInput = {
			userID: sendingUser.id,
			customerID:sendingUser.customerID,
			command: 'INVITE_TO_CONECT',
			params: JSON.stringify(params),
		};   
		
		//Call GraphQL to enqueue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Enqueuing Command  INVITE_TO_CONECTIVITY", enqueueCommandInput);
		const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
		successFlag = (response === 'SUCCESS');
		if (DEBUG_MODE >= 2) console.log("Enqueue Lambda returned", response, successFlag);        

	} catch (err) {
		if (DEBUG_MODE >= 2) console.log('error enqueueing command:', err);
		successFlag = false;
	}        
	
	return successFlag;
}

export async function invokeUpdateInviteToConect ({ launchRuleID, senderID, invitationID, usersToInvite, teamsToInvite, inviteMessage, messageGIF,
	eventStartDateTime, eventEndDateTime, launchUntilDate, launchUntilCount, launchRepeatOption, launchRepeatWeeklyOptions, launchRepeatMonthlyOptions,
	launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3, launchMonthOption1Checked, launchMonthOption2Checked, allDayEvent, attendeeEmailsForCalendar,
	isMentorMeeting, isSponsorMeeting, recurrenceOption, launchRuleInstanceStartDateTime, launchRuleInstanceIndex
}) {

	let successFlag;

	//Safety checks
	if (!launchRuleID || !senderID || ((!usersToInvite || usersToInvite.length === 0) && (!teamsToInvite || teamsToInvite.length === 0)) || !recurrenceOption) {
		if (DEBUG_MODE >= 2) console.log("ERROR UPDATING INVITE TO CONNECT - improper params", launchRuleID, senderID, usersToInvite, usersToInvite);
		return successFlag;        
	} 

	if (DEBUG_MODE >= 2)  console.log("Handling Update to Invite To Conect", launchRuleID, invitationID, inviteMessage, messageGIF, 
		eventStartDateTime.toISOString(), eventEndDateTime.toISOString(), usersToInvite, teamsToInvite,
		allDayEvent, launchUntilDate, launchUntilCount, launchRepeatOption,  launchRepeatWeeklyOptions, launchRepeatMonthlyOptions, launchRepeatMonthlyOptions_2, launchRepeatMonthlyOptions_3,  
		launchMonthOption1Checked, launchMonthOption2Checked, attendeeEmailsForCalendar, 
		isMentorMeeting, isSponsorMeeting, recurrenceOption, launchRuleInstanceStartDateTime, launchRuleInstanceIndex);

	//Use the same Sending User as the original sender as we don't allow it to change and the sender could be different from the current user
	const sendingUser = await fetchUser(senderID);
	if (DEBUG_MODE >= 2) console.log("Retrieved sending user", sendingUser);      
	
	try {
		//Direct call to our Enqueue lambda
		 const params = {
			origin:'CONECTERE',
			launchRuleID: launchRuleID,	                                             
			invitationID: invitationID,	                                             
			usersToInvite: usersToInvite,    //Array of users for whom to launch this Conectivity 
			teamsToInvite: teamsToInvite,    //Array of teams for which to launch this Conectivity            
			message: inviteMessage,                   //Any message to include when launching the conectivity
			messageGIF: (messageGIF ? GIPHY_ID_IDENTIFIER + messageGIF.id : null),            //Any GIF  to also include when launching?
			isMentorMeeting: isMentorMeeting,
			isSponsorMeeting: isSponsorMeeting,
			
			//Event date/time information including recurrence
			eventStartDateTime: eventStartDateTime.toISOString(),            //Start date & time of calendar event
			eventEndDateTime: eventEndDateTime.toISOString(),                //End date & time of calendar event
			allDayEvent: allDayEvent,
			launchUntilDate: (launchRepeatOption.id === 1 ? eventStartDateTime.toISOString() : launchUntilDate.toISOString()),   //Adjust end date to TODAY if there is no recurrence rule; this will allow us to expired (delete) the launch rule later during cleanup        //End date of when rule no longer applies
			launchUntilCount: (launchUntilCount ? launchUntilCount : -1),                                                       //Defaulting to unused for now; update UI to support COUNT or UNTIL
			launchRepeatOption: launchRepeatOption.id,                      //See Conectere Config - id of selected option
			launchRepeatWeeklyOptions: [],                                               //See Conectere Config - id of selected option
			launchRepeatMonthlyOptions: launchRepeatMonthlyOptions.id,       //See Conectere Config - id of selected option
			launchRepeatMonthlyOptions_2: launchRepeatMonthlyOptions_2.id,   //See Conectere Config - id of selected option
			launchRepeatMonthlyOptions_3: launchRepeatMonthlyOptions_3.id,  //See Conectere Config - id of selected option
			launchMonthOption1Checked: launchMonthOption1Checked,    
			launchMonthOption2Checked: launchMonthOption2Checked, 
			attendeeEmailsForCalendar: attendeeEmailsForCalendar,
			recurrenceOption: recurrenceOption,
			launchRuleInstanceStartDateTime:launchRuleInstanceStartDateTime,
			launchRuleInstanceIndex:launchRuleInstanceIndex,
		};

	  //Loop through selected weekly options (days of weeks) and construct array of IDs
		 if (launchRepeatWeeklyOptions) {
			 for (var j=0 ; j < launchRepeatWeeklyOptions.length; j++) {
				// if (DEBUG_MODE >= 2) console.log("Adding selected weekly option:", launchRepeatWeeklyOptions[j]);
				params.launchRepeatWeeklyOptions.push (launchRepeatWeeklyOptions[j].id);
			 }
		 }

		// console.log("Generated params object", JSON.stringify(params));
		
		const enqueueCommandInput = {
			userID: sendingUser.id,
			customerID:sendingUser.customerID,
			command: (recurrenceOption === "SINGLE" ? 'UPDATE_INVITE_TO_CONECT_SINGLE_OCCURRENCE':'UPDATE_INVITE_TO_CONECT'),
			params: JSON.stringify(params),
		};   
		
		//Call GraphQL to enqueue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Enqueuing Command", enqueueCommandInput);
		const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
		successFlag = (response === 'SUCCESS');
		if (DEBUG_MODE >= 2) console.log("Enqueue Lambda returned", response, successFlag);        

	} catch (err) {
		if (DEBUG_MODE >= 2) console.log('error enqueueing command:', err);
		successFlag = false;
	}        
	
	return successFlag;
}

export async function invokeRemoveInviteToConect ({currentUser, launchRuleToDeleteID, recurrenceOption, launchRuleInstanceStartDateTime, launchRuleInstanceIndex, }) {
	if (DEBUG_MODE >= 2) console.log("Removing InviteToConnect", currentUser, launchRuleToDeleteID);
	var successFlag = false;  
	if (!currentUser || !launchRuleToDeleteID || !recurrenceOption) {
		console.log("ERROR - improper parameters", launchRuleToDeleteID, recurrenceOption);
		return successFlag;
	}
		try {

			 const params = {
			  launchRuleID: launchRuleToDeleteID,
			  recurrenceOption: recurrenceOption,
			  launchRuleInstanceStartDateTime:launchRuleInstanceStartDateTime,
			  launchRuleInstanceIndex:launchRuleInstanceIndex,
			  origin: 'CONECTERE'
			};
			const enqueueCommandInput = {
				userID: currentUser.id,
				customerID:currentUser.customerID,
				command: (recurrenceOption === "SINGLE" ? 'REMOVE_INVITE_TO_CONECT_SINGLE_OCCURRENCE':'REMOVE_INVITE_TO_CONECT'),
				params: JSON.stringify(params),
			};    

			//Call GraphQL to enqueue the command with our Lambda function 
			if (DEBUG_MODE >= 2) console.log("Enqueuing Command Delete Launch Rule ", enqueueCommandInput);
			const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
			successFlag = (response  === 'SUCCESS');
			if (DEBUG_MODE >= 2) console.log("Delete Launch Rule Lambda returned", response, successFlag);        
		} catch (err) {
			
				if (DEBUG_MODE >= 2) console.log('error deleting launch rule:', err);
				successFlag = false;
		} 
		
	return successFlag;
}   

export async function invokeRsvpToInviteToConect ({ launchRuleID, senderID, customerID, invitationID, launchRuleInstanceIndex, rsvpStatus}) {

	let successFlag;

	//Safety checks
	if (!launchRuleID || !senderID || !rsvpStatus ) {
		if (DEBUG_MODE >= 2) console.log("ERROR SENDING RSVP FOR INVITE TO CONNECT - improper params", launchRuleID, senderID, rsvpStatus);
		return successFlag;        
	} 

	if (DEBUG_MODE >= 2)  console.log("Handling Update to Invite To Conect", senderID, launchRuleID, invitationID, rsvpStatus, launchRuleInstanceIndex);

	//Use the same Sending User as the original sender as we don't allow it to change and the sender could be different from the current user
	const sendingUser = await fetchUser(senderID);
	if (DEBUG_MODE >= 2) console.log("Retrieved sending user", sendingUser);      
	
	try {
		//Direct call to our Enqueue lambda
		 const params = {
			origin:'CONECTERE',
			launchRuleID: launchRuleID,	                                             
			invitationID: invitationID,	                                             
			launchRuleInstanceIndex:launchRuleInstanceIndex,
			rsvpStatus:rsvpStatus,
		};

		const enqueueCommandInput = {
			userID: senderID,
			customerID:sendingUser.customerID,
			command: 'RSVP_INVITE_TO_CONECT',
			params: JSON.stringify(params),
		};   
		
		//Call GraphQL to enqueue the command with our Lambda function 
		if (DEBUG_MODE >= 2) console.log("Enqueuing Command", enqueueCommandInput);
		const response = await invokeAPI(enqueueCommand, 'enqueueCommand', enqueueCommandInput);
		successFlag = (response === 'SUCCESS');
		if (DEBUG_MODE >= 2) console.log("Enqueue Lambda returned", response, successFlag);        

	} catch (err) {
		if (DEBUG_MODE >= 2) console.log('error enqueueing command:', err);
		successFlag = false;
	}        
	
	return successFlag;
}

//Function for showing an upcoming conectivity
//Receives a conectivity OPTION and displays an ICON
export const displayUpcomingLaunchAsRow = (upcomingLaunch, users, teams) => {

	const sender = users.find(user => upcomingLaunch.senderID === user.id);
	
	// if (DEBUG_MODE >= 2) console.log("Building Upcoming Launch Display Component", sender, upcomingLaunch);
	
	var futureInviteesToShow = [];
	
	for (var j=0; j<upcomingLaunch.usersToInvite?.length; j++) {
		const tempParticipant = users.find(user => user.id === upcomingLaunch.usersToInvite[j]);

		if (tempParticipant != undefined) futureInviteesToShow.push(tempParticipant);
	}

	// if (DEBUG_MODE >= 2) console.log("Built Future Invitee list:", futureInviteesToShow);    


	 var futureTeamInviteesToShow = [];
	
	for (var x=0; x<upcomingLaunch.teamsToInvite?.length; x++) {
		const tempTeamParticipant = teams.find(team => team.id === upcomingLaunch.teamsToInvite[x]);

		// if (DEBUG_MODE >= 2) console.log("Retrieved Team:", tempTeamParticipant, upcomingLaunch.teamsToInvite[x]);    
		
		if (tempTeamParticipant != undefined)  futureTeamInviteesToShow.push(tempTeamParticipant);
	}

	// if (DEBUG_MODE >= 2) console.log("Built Future Invitee list:", futureTeamInviteesToShow);    
	

	const inviteesToShow = 5;
	
	return (

		<div key={uuidv4()} className="ContainerNoHeightFlexLeft" style={{height:"100%", width:"100%", backgroundColor:"#fafafa", borderRadius:"10px"}}>
			<div className="menuDataGridTextField TextStyle4" style={{height:"100%", width:"100px"}}>
				{formatDateWithDayOfWeek(upcomingLaunch.launchDate)}
			</div>
			<div style={{width:"40px"}}>
				<img src={upcomingLaunch.image} width='32' />
			</div>
				
			{/*    
				<div className="menuDataGridTextField TextStyle4" style={{padding:'10px', color:setHeaderColorByCategory(upcomingLaunch.shortLabel)}}>
					{upcomingLaunch.category}
				</div>
			*/}
			
			<div style={{padding:'10px', color:setHeaderColorByCategory(upcomingLaunch.shortLabel), width:"450px"}}>
				{truncateStringWithDots(upcomingLaunch.title,CONECTERE_CONFIG_DATA.CONECTIVITY_TITILE_MAX_CHARS)}
			</div>
			<div className="ContainerNoHeightCenter" style={{height:"100%", width:"300px"}}>
				 {futureInviteesToShow.length === 0 ? "" :
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
 
						{sender ? 
							<div style={{borderRight:"2px solid lightgrey", paddingRight:"10px", marginRight:"10px"}}> 
									<div className="avatarContainer">
										<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(sender.firstName + " " + sender.lastName)}}>
											{!sender.avatarUrl  ? <div className="avatarInitialsSmall" > {sender?.firstName.substring(0,1).toUpperCase() + sender?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={sender.avatarUrl} alt={sender?.firstName.substring(0,1).toUpperCase() + sender?.lastName.substring(0,1).toUpperCase()} /> }
										</div>
										<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(sender.firstName + " " + sender.lastName)}}>{sender.firstName + " " + sender.lastName}</span>
									</div>
							</div>
						 : "" }
									   
						{futureInviteesToShow.map((target, index) => ( 
							<div key={target.id} > 
								{index <=inviteesToShow-1 ? 
									<div className="avatarContainer">
										<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
											{!target.avatarUrl  ? <div className="avatarInitialsSmall" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
										</div>
										<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>{target.firstName + " " + target.lastName}</span>
									</div>
								: "" }
							</div>
	
						))}
						
						{futureInviteesToShow.length > inviteesToShow ? 
							<div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:SOCIAL_COLOR, color:"white"}}>
								<div className="avatarInitials" >+{futureInviteesToShow.length-inviteesToShow}</div>
							</div>
						 : ""   
						}
						
					  </div>
				 }
			
				{futureTeamInviteesToShow.length === 0 ? "" :
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
					
						{sender && futureInviteesToShow.length === 0 ? 
							<div style={{borderRight:"2px solid lightgrey", paddingRight:"10px", marginRight:"10px"}}> 
									<div className="avatarContainer">
										<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(sender.firstName + " " + sender.lastName)}}>
											{!sender.avatarUrl  ? <div className="avatarInitialsSmall" > {sender?.firstName.substring(0,1).toUpperCase() + sender?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={sender.avatarUrl} alt={sender?.firstName.substring(0,1).toUpperCase() + sender?.lastName.substring(0,1).toUpperCase()} /> }
										</div>
										<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(sender.firstName + " " + sender.lastName)}}>{sender.firstName + " " + sender.lastName}</span>
									</div>
							</div>
						 : "" }
 
						{futureTeamInviteesToShow.map((target, index) => ( 
							<div key={target.id} className="avatarContainer"> 
								<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
									{!target?.avatarUrl  ? <div className="avatarInitialsSmall" > {target.name.substring(0,3).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.name.substring(0,3).toUpperCase()} /> }
								</div>
								<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.name)}}>{target.name}</span>
							</div>
	
						))}
					  </div>                  
				}
			</div>
		</div>

	);
	
};

//Function for showing an upcoming conectivity
//Receives a conectivity OPTION and displays an ICON
export const displayLaunchRuleAsRow = (launchRule, users, teams, handleEditLaunchRule, calendarListViewEventClickHandler) => {

	//Safety checks
	if (!launchRule || !users || users.length === 0) return null;

	const sender = users.find(user => launchRule.senderID === user.id); //Find the sender
	if (!sender) return null;
	
	if (DEBUG_MODE >= 2 && launchRule.launchRepeatOption !== 1) console.log("Building Upcoming Launch Display Component", sender, launchRule);
	
	var futureInviteesToShow = [];
	
	for (var j=0; j<launchRule.usersToInvite?.length; j++) {
		const tempParticipant = users.find(user => user.id === launchRule.usersToInvite[j]);
		if (tempParticipant && tempParticipant.id !== sender.id) futureInviteesToShow.push(tempParticipant);
	}

	// if (DEBUG_MODE >= 2) console.log("Built Future Invitee list:", futureInviteesToShow);    


	 var futureTeamInviteesToShow = [];
	
	for (var x=0; x<launchRule.teamsToInvite?.length; x++) {
		const tempTeamParticipant = teams.find(team => team.id === launchRule.teamsToInvite[x]);
		if (DEBUG_MODE >= 2) console.log("Retrieved Team:", tempTeamParticipant, launchRule.teamsToInvite[x]);    
		if (tempTeamParticipant != undefined)  futureTeamInviteesToShow.push(tempTeamParticipant);
	}

	// if (DEBUG_MODE >= 2) console.log("Built Future Invitee list:", futureTeamInviteesToShow);    
	
	const inviteesToShow = 5;
	
	return (

		
		<div key={launchRule.id} className="ContainerVerticalStartStart launchRuleCard"  onClick={() => calendarListViewEventClickHandler(launchRule, false)}  style={{borderTopColor:setHeaderColorByCategory(launchRule.shortLabel)}}>
	
			<div className="TextStyle5" > {formatDateWithDayOfWeek(launchRule.nextLaunchDateISO)}  </div>
			<div className="ContainerNoHeightFlexLeft" style={{padding:"clamp(8px,2vw,20px) 0px"}}>
				<img className='ccImgXXL' src={launchRule.image}  alt=''/>
				<div className="TextStyle4" style={{paddingLeft:"clamp(4px,1vw,8px)", color:setHeaderColorByCategory(launchRule.shortLabel), textWrap:"wrap", wordBreak:"break-word"}}> {launchRule.title} </div>
			</div>  

			<div className="ContainerNoHeightFlexLeft" >
			
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
 
						{sender ? 
							<div style={{borderRight:(futureInviteesToShow.length > 0 || futureTeamInviteesToShow.lenght >0 ? "2px solid lightgrey" : "none"), paddingRight:"10px"}}> 
									<div className="avatarContainer">
										<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(sender.firstName + " " + sender.lastName)}}>
											{!sender.avatarUrl  ? <div className="avatarInitials" > {sender?.firstName.substring(0,1).toUpperCase() + sender?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={sender.avatarUrl} alt={sender?.firstName.substring(0,1).toUpperCase() + sender?.lastName.substring(0,1).toUpperCase()} /> }
										</div>
										<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(sender.firstName + " " + sender.lastName)}}>{sender.firstName + " " + sender.lastName}</span>
									</div>
							</div>
						 : "" }
									   
						{futureInviteesToShow.map((target, index) => ( 
							<div key={target.id} > 
								{index <=inviteesToShow-1 ? 
									<div className="avatarContainer">
										<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
											{!target.avatarUrl  ? <div className="avatarInitialsSmall" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
										</div>
										<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>{target.firstName + " " + target.lastName}</span>
									</div>
								: "" }
							</div>
	
						))}
						
						{futureTeamInviteesToShow.map((target, index) => ( 
							<div key={target.id} className="avatarContainer"> 
								<div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
									{!target?.avatarUrl  ? <div className="avatarInitialsSmall" > {target.name.substring(0,3).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.name.substring(0,3).toUpperCase()} /> }
								</div>
								<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.name)}}>{target.name}</span>
							</div>
	
						))}
						
						{futureInviteesToShow.length > inviteesToShow ? 
							<div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:SOCIAL_COLOR, color:"white"}}>
								<div className="avatarInitials" >+{futureInviteesToShow.length-inviteesToShow}</div>
							</div>
						 : ""   
						}
						
				  </div>
			</div>

			<div className="ContainerNoHeightCenter fullWidth TextStyle2" style={{paddingTop:'clamp(10px,2vw,20px)', color:setHeaderColorByCategory(launchRule.shortLabel)}}>
				<i>{launchRule.launchRepeatOption !== 1 ? launchRule.recurrenceString : ''} </i>
			</div>

		</div>

	);
	
};    

/* Buttons for dynamically selecting windows to display within the SC Detailed View */
export const ConectivityDetailedViewWindowControl = ({displayChatButton, chatIsButtonActive, chatButtonCallback, lbButtonIsActive, lbButtonCallback, displayLbButton}) => {
	if (!displayChatButton && !displayLbButton) return null;
	return (
		<div className={`expandChatPortionAnchor borderRadius`} >
			{displayChatButton ? <MessagesButton isActive={chatIsButtonActive} onClickCallback={chatButtonCallback} /> : null}
			{displayLbButton ? <LeaderboardButton isActive={lbButtonIsActive} onClickCallback={lbButtonCallback} /> : null }
		</div>
	)
}

 /* FUNCTION FOR APPLYING EVENT MULTIPLER FOR SPECIAL PROGRAMS AND EVENTS */
 export function setSpecialEventMultiplier({conectivity, activeSpecialEvents}) {
   
   //TEMP
   if (DEBUG_MODE > 1) console.log("Seting Special Event Multipler", conectivity);
   let specialEventMultipler = 1, reason='';
   const MULTIPLIER_CAP = 5;

   try {   
      
      //Fetch special events
      if (!activeSpecialEvents || activeSpecialEvents.length === 0) {
         if (DEBUG_MODE > 1) console.log("No special events. Returning special events multiplier of 1", specialEventMultipler);
         return specialEventMultipler;
      } 
 
      //Get user's local time using browser local tz
      const usersLocalTime = moment();                                         //Put current time in UTC into user's local TZ
      if (DEBUG_MODE > 1) console.log("User's local time. " + usersLocalTime.format()); 

      //Find any special events that this conectivity satisfies, and that is active for this time period     
       let tempSpecialEvents = activeSpecialEvents.filter((event) => {
         if (event.eventStatus !== 'ENABLED') {if (DEBUG_MODE > 1) console.log("Excluding disabled special event", event); return false;}
         if (!event.applyToConectivities) {if (DEBUG_MODE > 1) console.log("Excluding special event that does not apply to Conectivities", event); return false;}   //exclude events that do not apply to conectivities
         if (!event.rewardMultiplier || event.rewardMultiplier <= 1) return false;  //exclude events that do not actually have a reward multiplier

         //Confirm event time frame spans the user's current time
         if (!event.eventEndDateTime || moment(event.eventEndDateTime).isBefore(usersLocalTime,'day')) {if (DEBUG_MODE > 1) console.log("Excluding past special event", event); return false;}    //exclude events that already ended
         if (!event.eventStartDateTime || moment(event.eventStartDateTime).isAfter(usersLocalTime,'day')) {if (DEBUG_MODE > 1) console.log("Excluding special event that has not yet started", event); return false;} //exclude events that have not yet started

         // if (event.minParticipantsRequired && event.minParticipantsRequired > 1 && (!usersThatParticipatedWithMe || event.minParticipantsRequired > (usersThatParticipatedWithMe.length + 1) )) {if (DEBUG_MODE > 1) console.log("Excluding special event where conectivity did not meet MIN participants requirement", event); return false;}  //exclude events that do not satisfy the required min # of participants
         if (event.categoryID && (event.categoryID !== conectivity.categoryID)) {if (DEBUG_MODE > 1) console.log("Excluding special event where conectivity did not match the type specified by the event", event); return false;}  //exclude events where conectivity did not satisfy type, if required
         if (event.requireBadgesDEI && event.requireBadgesDEI > 0 && (!conectivity.badgesDEI || conectivity.badgesDEI===0 )) {if (DEBUG_MODE > 1) console.log("Excluding special event where conectivity did not have DEI badges", event); return false;}  //exclude events where conectivity did not satisfy the DEI badges, if required
         if (event.requireBadgesCS && event.requireBadgesCS > 0 && (!conectivity.badgesCS || conectivity.badgesCS===0 )) {if (DEBUG_MODE > 1) console.log("Excluding special event where conectivity did not have CS badges", event); return false;}  //exclude events where conectivity did not satisfy the CS badges, if required
         if (event.keywords && event.keywords.length >0) {
            let match = false;
            const keywordArray = event.keywords.split(',');
            for (const keyword of keywordArray) {
               if (!match) match = conectivity.title.toLowerCase().includes(keyword.toLowerCase());
            }
            if (!match) {if (DEBUG_MODE > 1) console.log("No matching keyword found in the message", event); {if (DEBUG_MODE > 1) console.log("Excluding special event where conectivity title fails to match keyword requirements", keywordArray, event); return false;}}   //exlude events where the recognition message does not match ANY of the keywords
         }   
         
         //OK, so we have a match.  Use this opportunity to adjust the multipler
         if (event.rewardMultiplier > specialEventMultipler) specialEventMultipler = event.rewardMultiplier;
         if (DEBUG_MODE > 1) console.log("Matching special event found; adjusted special event multilier", specialEventMultipler);
			reason = specialEventMultipler.toString() + 'X rewards for ' + event.title + ' special event!';
         return true;
      });

      if (!tempSpecialEvents || tempSpecialEvents.length === 0) {
         if (DEBUG_MODE > 1) console.log("No (filtered) special events. Returning special events multiplier of 1", tempSpecialEvents);
      } else {
         if (DEBUG_MODE > 1) console.log("Detected special events having relevant time period and that are enabled AND that apply to conectivities", tempSpecialEvents);
      }
   } catch (err) {
      if (DEBUG_MODE > 1) console.error("Error setting event multiplier", err);
      specialEventMultipler = 1;
   }

   //Safety Check
   if (!specialEventMultipler || specialEventMultipler < 0 || specialEventMultipler > MULTIPLIER_CAP) specialEventMultipler = 1; 

   if (DEBUG_MODE > 1) console.log("Computed Special Event Multipler", specialEventMultipler);
   return {specialEventMultipler, reason};
}

export const SpecialEventsGraphic = ({isSpecialEvent, specialEventGraphicText, specialEventHoverText}) => {

	const [mouseOver, setMouseOver] = useState(false);

	if (!isSpecialEvent) return null;
	if (DEBUG_MODE > 1) console.log("Displaying Special Event graphic", isSpecialEvent, specialEventGraphicText, specialEventHoverText);


	return (
		<div className='conectivityCardHeaderSpecialEventAnchor ' onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			<div className='ContainerNoHeightCenter positionRelative'>
				<StarIcon className='TextStyle7 light-yellow' />
				<div className='conectivityCardHeaderSpecialEventText bold TextStyle1 black ' >{specialEventGraphicText ? specialEventGraphicText : ''}</div>
				<div className={`TextStyle2 hoverTextStyle1 hoverTextStyle1Large && ${mouseOver && "hoverTextStyle1Visible"} `} > {specialEventHoverText ? specialEventHoverText : null}</div>
			</div>
		</div>
	);	
 }

export const SpecialEventsBadges = ({isSpecialEvent, specialEventGraphicText, specialEventHoverText, badgeImage, textColor}) => {

	const [mouseOver, setMouseOver] = useState(false);

	if (!isSpecialEvent) return null;
	if (DEBUG_MODE > 1) console.log("Displaying Special Event graphic", isSpecialEvent, specialEventGraphicText, specialEventHoverText);

	return (
		<div className='conectivityCardHeaderSpecialEventAnchor ' onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			<div className='ContainerNoHeightCenter positionRelative'>
				{!badgeImage ? (
					<StarIcon className='TextStyle7 light-yellow' />
				) : (
					<img src={badgeImage} alt={specialEventGraphicText} className='fixSizeBadgeImg' />
				)}
				<div className='connectivitySpecialEventBadgeText bold TextStyle1' style={{ color: textColor }} >{specialEventGraphicText ? specialEventGraphicText : ''}</div>
				<div className={`TextStyle2 hoverTextStyle1 hoverTextStyle1Large && ${mouseOver && "hoverTextStyle1Visible"} `} > {specialEventHoverText ? specialEventHoverText : null}</div>
			</div>
		</div>
	);	
}


/*
	Render the Header of a conectivity
		- Must receive either an SC or a Con
		- Will display the expiration time IF an SC and invitations are passed in
*/

export function displayConectivityHeader({scheduledConectivity, conectivity, backgroundClass, darkMode, headerColor, invitationsWithThisUser, isFullView}) {

	//Safety check
	if (!scheduledConectivity && !conectivity) return null;
	const conectivitytoDisplay = (scheduledConectivity && scheduledConectivity.conectivity ? scheduledConectivity.conectivity : conectivity);

	if (!darkMode) darkMode = false; if(!headerColor) headerColor = BALANCE_COLOR; if (!backgroundClass) backgroundClass = "blue-background";

	let titleClass = "TextStyle5";
	if (!isFullView) titleClass = (conectivitytoDisplay.title && conectivitytoDisplay.title.length > 70 ? "TextStyle3 " : "TextStyle4 ");

	return(
		<div className={`ContainerNoHeightCenter fullWidth conectivityCardHeaderWrapper ${backgroundClass} `}  >
			<div className="ContainerNoHeightCenter conectivityCardHeaderImageWrapper" >
				<img src={setConectivityImageUrlByConectivity(conectivitytoDisplay, darkMode)} className="conectivityCardHeaderImage" alt=''/>
			</div>
				<div className="ContainerVerticalSpaceAround maxHeight conectivityCardHeaderTextWrapper" >
					<div className="ContainerNoHeightCenter wrap" >
						<div className={titleClass} style={{color:(darkMode ? headerColor : 'white')}}> 
							{conectivitytoDisplay.title}  
						</div>
					</div>
					{/* 7.26.2024 - only display card expiration if an SC and NO invitations for this user */}
					{!scheduledConectivity || (invitationsWithThisUser && invitationsWithThisUser.length > 0) ? null :
						<ConectivityDurationTimer closingDateTime={scheduledConectivity.closingDateTime}/>
					}
				</div>	
		</div>
	)
}

export function dispayConectivityDescription(description) {
	if (!description) return null;
	return (
		<div className="ConectivityDescriptionWrapperPreview" >
			<div className="TextStyle4_3 black-text" > {description}</div>
		</div>
	)
}

export function displayConectivityInstructions(instructions) {
	if (!instructions) return null;
	return (
		<div className="ConectivityInstructionPreview" >
			{parse(instructions)}
		</div>
	)
}
export function displayScheduledConectivityCardActionsRow({scheduledConectivity, handleDidItClick, handleSkipClick }) {

	//Safety Checks
	if (!scheduledConectivity || !scheduledConectivity.conectivity) return null;
	
	return (

		<div className={`ContainerNoHeightSpaceBetween alignCenter fullWidth positionRelative noWrap conectivityCardActionsWrapper  `} >
			{scheduledConectivity.conectivity.ratingStatsID == null ? "" :
				<div  style={{display:"flex",  position:"absolute", left:"clamp(10px,0.3vw,19px)", bottom:"clamp(1px,0.2vh,5px)"}}>
					 <StarRatings rating={scheduledConectivity.conectivity.ratingStats.ratingsAverage} readOnly={true} className={"TextStyle1"}/>
				</div>
			}
			
			<div className="ContainerNoHeightCenter noWrap conectivityCardApprovedPaidTime">
			   <i className="TextStyle2"> {!scheduledConectivity.conectivity.approvedPaidTime ? "" : scheduledConectivity.conectivity.approvedPaidTime + " min"}</i>
			</div>

			<div className="ContainerNoHeightCenter noWrap">
				{!scheduledConectivity.conectivity.conectCoins ? "" :
				<div className="ContainerNoHeightCenter noWrap TextStyle2 conectivityCardAwards" > 
					{ scheduledConectivity.conectivity.conectCoins } 
					<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
				</div>                        
				}
				{!scheduledConectivity.conectivity.badgesDEI>0 ? "" :  
					<div className="ContainerNoHeightCenter noWrap TextStyle2 conectivityCardAwards" > 
						{scheduledConectivity.conectivity.badgesDEI} 
						<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
					</div>
				}
				{!scheduledConectivity.conectivity.badgesCS>0 ? "" :  
					<div className="ContainerNoHeightCenter noWrap TextStyle2 conectivityCardAwards" > 
						{scheduledConectivity.conectivity.badgesCS} 
						<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> 
					</div>
				}
			</div>
		   
			<div className='ContainerNoHeightCenter'>
				<button className="buttonStyle2 noBoxShadow" aria-label="close" onClick={(e) => handleSkipClick(e,scheduledConectivity) } id={scheduledConectivity.conectivity.id} >
					Skip
				</button>			
				<button className="buttonStyle1 buttonStyle1HeaderBlue"  aria-label="done" onClick={(e) => handleDidItClick(e, scheduledConectivity) }  id={scheduledConectivity.conectivity.id}   disabled={expiredConectivity(scheduledConectivity)}>
					Done!
				</button>
			</div>
		</div>    
	);    
}

export function displayConectivityCardHeader({scheduledConectivity, conectivity, backgroundClass, darkMode, headerColor, invitationsWithThisUser, isFullView, handleDetailedView}) {

	//Safety check
	if (!scheduledConectivity && !conectivity) return null;
	const conectivitytoDisplay = (scheduledConectivity && scheduledConectivity.conectivity ? scheduledConectivity.conectivity : conectivity);

	if (!darkMode) darkMode = false; if(!headerColor) headerColor = BALANCE_COLOR; if (!backgroundClass) backgroundClass = "blue-background";

	let titleClass = "TextStyle4V2";
	if (!isFullView) titleClass = (conectivitytoDisplay.title && conectivitytoDisplay.title.length > 70 ? "TextStyle3V2 " : "TextStyle4V2 ");

	return(
		<div className='ContainerNoHeight fullWidth connectivityCardHeaderWrapper' style={{ borderColor: headerColor }}>
			<div className="ContainerNoHeightFlexStart conectivityCardHeaderImageWrapper" >
				<img src={setConectivityImageUrlByConectivity(conectivitytoDisplay, true)} className="conectivityCardHeaderImageV2" alt=''/>
			</div>
			<div className="ContainerNoHeightFlexLeft fullWidth wrap" >
				<div className={`${titleClass} bold black-text`}> 
					{conectivitytoDisplay.title}
				</div>
			</div>
			<div className="conectivityCardHeaderOpenAnchor"> 
				<IconButton aria-label="skip" size="small" onClick={handleDetailedView} >
					<MoreVertIcon  className="TextStyle5V2" />
				</IconButton>
			</div>
		</div>
	)
}

export function displayScheduledConectivityCardInfoRow({scheduledConectivity, invitationsWithThisUser, headerColor, darkMode}) {
	return  (
		<div className='ContainerNoHeightFlexLeft fullWidth black-text TextStyle2V2' style={{ gap: "12px" }}>
			{!scheduledConectivity || (invitationsWithThisUser && invitationsWithThisUser.length > 0) ? null :
				<ConectivityDurationTimer label="Time Left:" labelClass="black-text TextStyle2V2" timeClass={setTextColorClassByColorValue(headerColor)} hideTimeIcon closingDateTime={scheduledConectivity.closingDateTime} />
			}
			{!scheduledConectivity.conectivity.approvedPaidTime ? null : (
				<div className='ContainerNoHeightCenter scheduledInfoActionsWrapper noWrap'>
					<AccessTimeIcon className="TextStyle5V2 silver" />
					<span>{scheduledConectivity.conectivity.approvedPaidTime + " min"}</span>
				</div>
			)}
			{scheduledConectivity.conectivity.conectCoins || scheduledConectivity.conectivity.badgesDEI>0  || scheduledConectivity.conectivity.badgesCS>0 ? (
				<div className="scheduledInfoActionsWrapper TextStyle5V2" style={{ padding: 0 }}>&nbsp;</div>
			) : null}
			{!scheduledConectivity.conectivity.conectCoins ? null : (
				<div className="ContainerNoHeightCenter noWrap bold">
					<img className="connectivityStartIcon" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
					{ scheduledConectivity.conectivity.conectCoins }
				</div>
			)}
			{!scheduledConectivity.conectivity.badgesDEI>0 ? null : (
				<div className="ContainerNoHeightCenter noWrap bold">
					<img  className="connectivityStartIcon" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/>  
					{ scheduledConectivity.conectivity.badgesDEI }
				</div>
			)}
			{!scheduledConectivity.conectivity.badgesCS>0 ? null :  
				<div className="ContainerNoHeightCenter noWrap bold" > 
					<img  className="connectivityStartIcon" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> 
					{scheduledConectivity.conectivity.badgesCS} 
				</div>
			}
			{scheduledConectivity.conectivity.ratingStatsID == null ? null : (
				<div className="ContainerNoHeightCenter scheduledInfoActionsWrapper noWrap">
					<StarRatings rating={scheduledConectivity.conectivity.ratingStats.ratingsAverage} readOnly={true} className="connectivityStartIcon" />
				</div>
			)}
		</div>
	)
}

export function scheduledConectivityActionsRow({currentUser, scheduledConectivity, invitationsWithThisUser, editInvitationCallback, deleteInvitationCallback, mergeInvitationsCallback, declineInvitationCallback, newInvitationCallback, closeAllSubMenusToggle, idOfSubMenutoDisplay, invitationCallbackSetIdOfSubMenutoDisplay, pendingInvitationUpdate, headerColor, handleShowMessages,  handleDidItClick, handleSkipClick }) {

	//Safety Checks
	if (!scheduledConectivity || !scheduledConectivity.conectivity) return null;
	
	return (

		<div className={`ContainerNoHeightSpaceBetween alignCenter fullWidth positionRelative noWrap`} >
			<div className='invitationWrapper'>
				<InvitationsV2
					currentUser={currentUser} scheduledConectivity={scheduledConectivity} invitationsWithThisUser={ invitationsWithThisUser} 
					editInvitationCallback={editInvitationCallback} deleteInvitationCallback={deleteInvitationCallback} 
					mergeInvitationsCallback={mergeInvitationsCallback} declineInvitationCallback={declineInvitationCallback}
					newInvitationCallback={newInvitationCallback}
					closeAllSubMenusToggle={closeAllSubMenusToggle} 
					idOfSubMenutoDisplay={idOfSubMenutoDisplay} setIdOfSubMenutoDisplay={invitationCallbackSetIdOfSubMenutoDisplay} addBottomBorder={false}
					pendingInvitationUpdate={pendingInvitationUpdate} headerColor={headerColor} handleShowMessages={handleShowMessages}
				/>
			</div>
			<div className='ContainerNoHeightCenter'>
				<button className="buttonStyle2 noBoxShadow connectivitySkipButton" aria-label="close" onClick={(e) => handleSkipClick(e,scheduledConectivity) } id={scheduledConectivity.conectivity.id} >
					Skip
				</button>			
				<button className="buttonStyle1 buttonStyle1HeaderBlack"  aria-label="done" onClick={(e) => handleDidItClick(e, scheduledConectivity) }  id={scheduledConectivity.conectivity.id}   disabled={expiredConectivity(scheduledConectivity)}>
					Done
				</button>
			</div>
		</div>    
	);    
}

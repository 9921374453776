//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023

//  ADMIN for managing Teams Integrations


//Config Data
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR, COLOR_WHITE, MSFT_TEAMS_EMOJIS} from '../../shared/data/conectereConfigData';

import { HELP_TEXT_ADMIN_SLACK_BUTTON } from '../../shared/data/helpData.js';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//React components
import Select from 'react-select';   //Version with support from multiple selections
import Switch from '@mui/material/Switch';

//Icons
import HighlightIcon from '@mui/icons-material/Highlight';

//Our Components
import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { displayMsftTeamsAccessControlButton, buildMsftTeamsChannelOptions } from "../../shared/utils/msftTeamsUtils";
import { HelpButton, EditButton, } from "../../shared/utils/generalUtils";
import { storeUpdatedCustomerConfig }  from "../../shared/utils/databaseUtils";

//3rd Party Components
import Checkbox from '@mui/material/Checkbox';

const AdminMsftTeamsIntegration = () => {

	//Context
	const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
	const {  authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics,  } = useContext(AuthContext);    

	//MSFT Teams Integration
	const [msftTeamsEnabled, setMsftTeamsEnabled] = useState(false);
	const [msTeamsSpotlightWebhook, setMsTeamsSpotlightWebhook] = useState(null);
	const [mfstTeamsChannelCreationEnabled, setMsftTeamsChannelCreationEnabled] = useState(true);
	const [mfstTeamsChannelSelection, setMfstTeamsChannelSelection] = useState(null);
	const [mfstTeamsChannelOptions, setMsftTeamsChannelOptions] = useState([]);
	const [msTeamsOptions, setMsTeamsOptions] = useState({})

	//state vars
	const [inEditMode, setInEditMode] = useState(false);
	const [showModalConfirmation, setShowModalConfirmation] = useState(false);
	const [configDetailsToEdit, setConfigDetailsToEdit] = useState(null);

	//Copy the customer's settings upon opening the page or any change to the edit mode
	//Need to fire on both AuthState and CurrentUser to handle both login sequence and disable / enable interctions
	useEffect(() => {
		//Safety check
		if (authState !== "signedin" || !currentUser || !currentUser.customer || !currentUser.customer.configDetails) return;  
		
		initMsftTeamsSettings(currentUser);    //initPageSettings
	}, [authState, currentUser]);
	
	const initMsftTeamsSettings = async (currentUser) => {
		try {
			if (currentUser && currentUser.customer.configDetails) {
				if (DEBUG_MODE >= 2) console.log("initMsftTeamsSettings invoked", currentUser.customer.configDetails);
				let configDetails = {...currentUser.customer.configDetails };    //Make a copy
				setConfigDetailsToEdit(configDetails);                          //Store in our local React state var for the page
				setMsftTeamsChannelCreationEnabled(currentUser.customer.configDetails.msTeamsChannelCreationEnabled ? true : false);
				setMsftTeamsEnabled(currentUser.customer.configDetails.msTeamsEnabled ? true : false);
						
				//Fetch Channel Options
				if (DEBUG_MODE >= 2) console.log("Fetching Teams Channel Options from UseEffect");
				setShowSpinner(true);
				const tempOptions = await buildMsftTeamsChannelOptions(currentUser.customerID);
				if (DEBUG_MODE) console.log("Built channel options:", tempOptions);
				setMsftTeamsChannelOptions(tempOptions);

				//Pre-load our selection
				let tempSelection;
				if (DEBUG_MODE) console.log("Looking for currently selected channel record", currentUser.customer.configDetails.msTeamsSpotlightsInstallRecordID);
				if (currentUser.customer.configDetails.msTeamsSpotlightsInstallRecordID) {
					tempSelection = tempOptions.find(option => option.id === currentUser.customer.configDetails.msTeamsSpotlightsInstallRecordID);
				} else if (DEBUG_MODE > 0) console.log("Spotlights enabled but currently selected channel no longer in installation records");

				if (tempSelection) setMfstTeamsChannelSelection(tempSelection);
				else {
					if (DEBUG_MODE >= 2) console.log("Teams enabled but no Spotlight channel set yet; placing in edit mode");
					setInEditMode(true); //Put in EDIT mode since the user HAS to select a Spotlight Channel!
				}

				//Any additional Teams options?
				let msTeamsOptions = (configDetails && configDetails.msTeamsOptions ? JSON.parse(configDetails.msTeamsOptions) : {});
				if (DEBUG_MODE >= 2) console.log("initializing msTeamsOptions", msTeamsOptions);
				setMsTeamsOptions(msTeamsOptions);

				setShowSpinner(false);
			}
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error initializing Teams data", err);
		} 
	};

	const handlePutInEditMode = async () => {
		if (DEBUG_MODE >= 2) console.log("User entered edit mode.");
		setInEditMode(true);
	};
	 
	//Process the SAVE function from Edit mode for updating customer
	async function handleConfirmationEdit (e) {
		e.preventDefault();     //prevent React page refresh
		setInEditMode(false); // Shut down the edit mode
		  
		try {      

			let tempConfigToEdit = {
				id: currentUser.customer.configDetails.id,
				msTeamsEnabled:(msftTeamsEnabled ? msftTeamsEnabled : false),
				msTeamsChannelCreationEnabled:(mfstTeamsChannelCreationEnabled ? mfstTeamsChannelCreationEnabled : false),
				msTeamsSpotlightWebhook: (msTeamsSpotlightWebhook ? msTeamsSpotlightWebhook : null),   //If used, Webhook for posting to the channel selected for Spotlights                  
				msTeamsSpotlightsInstallRecordID: (mfstTeamsChannelSelection && mfstTeamsChannelSelection.id ? mfstTeamsChannelSelection.id : null),   //ID of the install record selected, which leads us to a specific team & channel 
			}; 
			if (msftTeamsEnabled) tempConfigToEdit.msTeamsOptions = JSON.stringify(msTeamsOptions);

			if (DEBUG_MODE >= 2) console.log("Writing customer config", tempConfigToEdit);
			const tempInsertedConfig = await storeUpdatedCustomerConfig(tempConfigToEdit);
			if (DEBUG_MODE >= 2) console.log("Successfully edited customer config", tempInsertedConfig );

			setModalGeneralMessage("Changes saved ");
			setModalGeneralTitle("Success!");       
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error editing customer config:', err);
			setModalGeneralMessage("Unable to save changes to customer.  ", err);
			setModalGeneralTitle("Ooops!");                    
		}
		  
		setShowModalGeneral(true); //display error message
	}

	async function handleDisableMsftTeamsIntegrationConfirmed() {
		if (DEBUG_MODE >= 1) console.log("Disabling MSFT Teams Integration");
		setShowModalConfirmation(false);   
		try { 

			if (DEBUG_MODE >= 1) console.log("Disabling MSFT Teams integration");
			setMsftTeamsEnabled(false);

			let tempConfigToEdit = {
				id: currentUser.customer.configDetails.id,
				msTeamsEnabled:false,
			}; 
	
			if (DEBUG_MODE >= 2) console.log("Writing customer config", tempConfigToEdit);
			const tempInsertedConfig = await storeUpdatedCustomerConfig(tempConfigToEdit);
			if (DEBUG_MODE >= 2) console.log("Successfully edited customer config", tempInsertedConfig );

			setModalGeneralMessage('Integration with MSFT Teams disabled');
			setModalGeneralTitle("Success");
			setShowModalGeneral(true);  

		} catch (err) {
			if (DEBUG_MODE >= 1) console.error("Error disabling MSFT Teams", err);
			setModalGeneralMessage("Sorry, something went wrong...."+err);
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
		}
	 } 

	async function handleToggleMsftTeamsIntegration() {
		if (DEBUG_MODE >= 1) console.log("Processing MSFT Teams Toggle", msftTeamsEnabled);
		if (msftTeamsEnabled) setShowModalConfirmation(true);   // DISABLE?
		else setMsftTeamsEnabled(!msftTeamsEnabled);
	}  

	const handleCancel = () => {
		setInEditMode(false);

		//Pre-load our selection again as it may have changed but now a cancel has been pressed
		let tempSelection = mfstTeamsChannelOptions.find(option => option.id === currentUser.customer.configDetails.msTeamsSpotlightsInstallRecordID);
		setMfstTeamsChannelSelection(tempSelection ? tempSelection : null);
		if (DEBUG_MODE) console.log("Resetting channel selection", tempSelection);
	};
	 
	 //Render function for authenticated user 
	 //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
		return (
			 <Authenticator>

			 </Authenticator>
		);
  } else  return (

	 <Authenticator>

			<ModalNoBackgroundFixed showModal={showModalConfirmation} closeCallback={()=>setShowModalConfirmation(false)} cardColor={TEAM_COLOR}  closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR}>
				<div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
					 <div className="modalNoBkgImage ccImgXXXXLSquare"  style={{borderColor: TEAM_COLOR}}> 
						  <img  className="avatarImageCenterPortrait avatarImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt=''/>  
					 </div>
					  <div className="ContainerVerticalStart" style={{height:"100%"}}>
							<h3 className="blue" style={{flex:"0"}}> Disable integration with Teams? 🥺</h3>
							 <p className="TextStyle3 black" style={{flex:"1",  paddingTop:"clamp(8px,2vw,20px)"}}>
								 Just to double check, are you sure you want to disconnect Microsoft Teams and Conectere for your <b>entire</b> company?  
								 You and your team members won't be able to view progress rings, participate in conectivities or cast spotlights directly from Teams.       
							 </p>

							<div className="adminConectivitiesTemplateButtonsWrapper" style={{paddingTop:"clamp(8px,2vw,20px)", flex:"0"}}>
								 <button className="adminConectivitiesButtonStyle1" aria-label="done" onClick={handleDisableMsftTeamsIntegrationConfirmed} >
									  Disable Integration
								 </button>
								 <button className="adminConectivitiesButtonStyle2" style={{marginLeft:"20px"}} aria-label="skip" onClick={()=>setShowModalConfirmation(false)} >
									  Cancel
								 </button>
							</div>
					 </div>
				</div>
			</ModalNoBackgroundFixed >
			
		  <div className="adminPage">
				<div className="dashboardContainer boxShadow" style={{position:"relative"}}>
					 <div className="dashboardTitle ">
						  <div className="ContainerNoHeightSpaceBetween" >
								<div className="ContainerNoHeightCenter">
									 <div>MICROSOFT TEAMS</div>
								</div>
						  </div>
					  </div>   
					 
							<div className = "ContainerVerticalCenter" style={{padding:"0rem 3rem"}}>
								 <div className="ContainerNoHeightCenter purple" style={{position:"relative", margin:"2rem 0rem 0rem 0rem"}}>
									  <h4> {msftTeamsEnabled ? 'Integration with Microsoft Teams Enabled 👍' : 'Integration with Microsoft Teams Disabled 🥺'}</h4>
								</div>
								<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
									Link your Conectere account to Microsft Teams!!  Enabling integration with Microsoft Teams allows employees to connect with colleagues, view conectivities, 
									chat with team members and even cast Spotlights to recognize and celebrate the achievements of colleagues, all from Microsoft Teams!
								</p>   
								<div className="ContainerVerticalCenter" style={{padding:"1.0rem 0rem 1.0rem 0rem"}}>
									 {displayMsftTeamsAccessControlButton({msftTeamsEnabled, isAdmin, clickCallback:(e) => {
										 handleToggleMsftTeamsIntegration();
									 }})}
								</div>   

								{msftTeamsEnabled ?  
									<>
										 <div className="ContainerNoHeight " style={{marginTop:"50px"}}>
											 <div className="ContainerVerticalStart " style={{borderRadius:"10px"}}>
													 <div className="ContainerNoHeightCenter" style={{}}>
														<span style={{paddingRight:"5px"}}>Select the channel in Microsoft Teams to use for employee Spotlights</span>
														<HighlightIcon style={{fontSize:"32px", color:"gold", transform: "rotate(-145deg) translate(0,0px)"}}/> 
													</div>
 
											 {/*          
													<div className="ContainerNoHeightFlexLeftFlexStart inputFormRowWrapper" onClick = {(e) =>  e.stopPropagation()}>
														<div className="inputFormVerticalLeftInputField TextStyle5" style={{minWidth:"400px"}}>
															<div> Webhook </div> 
															<input
																placeholder="Spotlight Channel Webhook"
																style={{width:'100%', border: ((!msTeamsSpotlightWebhook || msTeamsSpotlightWebhook.length===0) ? "1px solid red" : "1px solid grey")}}
																value={msTeamsSpotlightWebhook ? msTeamsSpotlightWebhook : ''}
																onChange={event => setMsTeamsSpotlightWebhook(event.target.value )}
																required
																disabled={!inEditMode || !msftTeamsEnabled}
															/>
														</div>
													</div>
				
											*/}

													<div style={{minWidth:"400px", zIndex:'100'}}>
														<Select  
															isMulti={false} 
															isClearable={false}
															required={true}
															name="channelDropdown" 
															options={mfstTeamsChannelOptions} 
															isOptionDisabled={(option) => option.isdisabled}
															isDisabled={!inEditMode || !msftTeamsEnabled}   
															// isDisabled={false}   
															onChange={setMfstTeamsChannelSelection} 
															value={mfstTeamsChannelSelection}  
															styles={{
																	control: (baseStyles, state) => ({
																	...baseStyles,
																	borderColor: mfstTeamsChannelSelection ? 'grey' : 'red',
																	}),
															}}
															placeholder=" - select  -"
															// formatOptionLabel={channel => (
															//     <div className="ContainerNoHeightFlexLeft noWrap TextStyle4"  style={{whiteSpace:"nowrap"}}>#{channel.name} </div>
															// )}
														/>
													</div>

				
													{
														mfstTeamsChannelSelection && mfstTeamsChannelSelection.id ? null : 
															<div className="TextStyle3 red bold" style={{paddingTop:"1.0rem"}}>
																<em>
																	{!mfstTeamsChannelOptions || mfstTeamsChannelOptions.length === 0 ?
																	'No channels detected.  In order to select your Spotlight channel, you must first install the Conectere Teams App in at least one team in Microsoft Teams' :
																	'You must select a channel in order for Spotlights to post to Microsoft Teams'}
																</em> 
															</div> 
													}
				
											{/*
												  <div className="ContainerNoHeightFlexLeft " style={{paddingTop:"2.0rem"}}>
															  <Checkbox
																checked={mfstTeamsChannelCreationEnabled}
																disabled={!inEditMode || !msftTeamsEnabled}
																onChange={()=>{
																  setMsftTeamsChannelCreationEnabled(!mfstTeamsChannelCreationEnabled);
																}}
																inputProps={{ 'aria-label': 'controlled' }}
															 />
															 <div className="TextStyle4">Dynamic channel creation</div>
															 <HelpButton 
															 hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide'
															 hoverText={"When enabled, we'll dynamically create private Slack channels for your team's conectivities and will synchronize messages between Slack and Conectere.  " +
															 "We'll auto-archive the channels when the conectivities are complete.  If disabled, your teams will not be able to chat in Slack when connecting in conectivities."}  /> 
												  </div>
											*/}

												<div className="adminChallengesEnableRegion ContainerNoHeightCenter" style={{zIndex:'7777'}}>
													<div> Enable Emoji Commands? </div> 
													<Switch
														name="enableNewEmployeeSupportProgram"
														checked={msTeamsOptions && msTeamsOptions.emojiCmdsEnabled ? msTeamsOptions.emojiCmdsEnabled : false }
														disabled={!inEditMode || !msftTeamsEnabled}
														onChange={()=>{
															let tempOptions = {...msTeamsOptions, emojiCmdsEnabled: !msTeamsOptions.emojiCmdsEnabled};
															setMsTeamsOptions(tempOptions);
														}}
														color="primary"
													/>
													<HelpButton 
													hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1Wide'
													hoverText={"When enabled, users can easily trigger actions like casting spotlights and launching conectivities by simply using certain emojis in their messages"}  
													/> 												
												</div>
												<div className={`adminChallengesSettingsRegion ContainerVerticalFlexStart borderRadius ${(!configDetailsToEdit || !configDetailsToEdit.enableAdminChallenges ? 'disabled' : '')} `}  >
													<div className="ContainerNoHeightFlexLeft ">
																<Checkbox
																	checked={msTeamsOptions && msTeamsOptions.emojiCmdsEnableCast ? msTeamsOptions.emojiCmdsEnableCast : false }
																	disabled={!inEditMode || !msftTeamsEnabled}
																	onChange={()=>{
																		let tempOptions = {...msTeamsOptions, emojiCmdsEnableCast: !msTeamsOptions.emojiCmdsEnableCast};
																		setMsTeamsOptions(tempOptions);
																	}}
																	inputProps={{ 'aria-label': 'controlled' }}
																/>
																<div className="TextStyle4">cast spotlights using 🔦</div>
																<HelpButton 
																hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide'
																hoverText={"When enabled, a user can cast a spotlight to recognize a peer."}  /> 
													</div>              
													<div className="ContainerVerticalCenter " >
														<div className="ContainerNoHeightFlexLeft " >
																<div className="TextStyle4">Additional cast Spotlight emoji </div>
																<HelpButton 
																	hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide'
																	hoverText={"When enabled, a user can cast a spotlight to recognize a peer."}  
																/> 


														</div>
														<div style={{minWidth:"400px", zIndex:'100'}}>
															<Select  
																isMulti={false} 
																isClearable={false}
																required={true}
																name="channelDropdown" 
																options={MSFT_TEAMS_EMOJIS} 
																isOptionDisabled={(option) => option.isdisabled}
																isDisabled={!inEditMode || !msftTeamsEnabled}   
																// isDisabled={false}   
																onChange={selection => setConfigDetailsToEdit({ ...configDetailsToEdit, msftTeamsCastEmoji: selection})}
																value={configDetailsToEdit.msftTeamsCastEmoji ? configDetailsToEdit.msftTeamsCastEmoji : null}
																styles={{
																		control: (baseStyles, state) => ({
																		...baseStyles,
																		borderColor: mfstTeamsChannelSelection ? 'grey' : 'red',
																		}),
																}}
																placeholder=" - select  -"
																// formatOptionLabel={channel => (
																//     <div className="ContainerNoHeightFlexLeft noWrap TextStyle4"  style={{whiteSpace:"nowrap"}}>#{channel.name} </div>
																// )}
															/>
														</div>
													</div>  
													<div className="ContainerNoHeightFlexLeft " style={{paddingTop:"2.0rem"}}>
																<Checkbox
																	checked={msTeamsOptions && msTeamsOptions.emojiCmdsEnableLaunch ? msTeamsOptions.emojiCmdsEnableLaunch : false }
																	disabled={!inEditMode || !msftTeamsEnabled}
																	onChange={()=>{
																		let tempOptions = {...msTeamsOptions, emojiCmdsEnableLaunch: !msTeamsOptions.emojiCmdsEnableLaunch};
																		setMsTeamsOptions(tempOptions);
																	}}
																	inputProps={{ 'aria-label': 'controlled' }}
																/>
																<div className="TextStyle4">launch conectivities using 🚀</div>
																<HelpButton 
																hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide'
																hoverText={"When enabled, users can easily trigger actions like casting spotlights and launching conectivities by simply using certain emojis in their messages"}  /> 
													</div>                        

												</div>
											</div>  
	
											<div style={{width:"2.0rem"}}>
												 {!inEditMode ? 
													  <EditButton onClickCallback={handlePutInEditMode} hoverText="Edit"/> 
												 : 
													  null 
												 }
											</div> 
		
										</div>

										<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
											<span className=" purple">Step 1: </span>In Microsoft Teams, select Apps and click on <span className="black">Manage your apps</span>.  Install the Conectere app for Microsoft Teams as Personal app.  This will give you full access to our chatbot and your
											Conectere account directly from Teams!
										</p>
										<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
											<span className=" purple">Step 2: </span>In Microsoft Teams, select the team and channel that you would like to use for Spotlights.  For example, you may already use a specific channel for recognizing employees and sharing news.  If not, create a dedicated channel called Spotlights.
										</p>
										<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
											<span className=" purple">Step 3: </span>After you have selected the team and channel to use for Spotlights, install the Conectere App in that team and channel in Microsoft Teams.  Specifically, select the team and click on <span className="black">Manage team</span>.  From the team management page, click on <span>Apps</span> and install the Conectere app.  
											<span className="black">  Be sure the select the channel that you would like Conectere to use to communicate Spotlights to the team members.</span>   Repeat this for each team where you would like team members to be able to interact directly with Conectere.
										</p>   
										<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
											<span className=" purple">Step 4: </span>After you have installed the Conectere app in your Microsoft teams, refresh this page and select the channel that you would like to use for Spotlights.  When a Spotlight is cast, we'll share the Spotlight between Conectere and Microsoft Teams so the entire team is sure to see the appreciation and recognition.
										</p>
		
										<p className="TextStyle4 blue" style={{paddingTop:"50px", maxWidth:"1000px"}}>
											✅ TIP: We synchronize conectivities, calendar invitations and chat messages to Microsoft Teams according to a user's email address.  Have your 
											team members use the same email addresses for Microsoft Teams and Conectere to ensure full integration and ease of use.
										</p>
									</>
								
								: null }
	 
							</div>            

					 <div className = "ContainerNoHeightFlexEnd buttonWrapperWithMargin fullWidth" style={{height:"2.0rem"}} >
						 {!inEditMode ? 
								null
						 :
							  <> 
								  <button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleConfirmationEdit} > Save </button>
								  <button  className="buttonStyle1 buttonStyle1DavyGray"  aria-label="cancel" onClick={handleCancel} > Cancel </button>
							  </>
						}
					 </div>
				</div> 
		  </div>   
		  
	 </Authenticator>
 
  );
};


export default AdminMsftTeamsIntegration;

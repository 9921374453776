//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import  { CONECTERE_CONFIG_DATA, REPORT_SELECTION_OPTIONS, DEBUG_MODE, COLOR_BLUE_HEADER, REPORT_DATAGRID_WIDTH, userAnalyticsInitialState, teamAnalyticsInitialState, customerWideAnalyticsInitialState} from '../../shared/data/conectereConfigData';

//React and AWS components
import React, { useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL ;

//Queries and Mutations
import { getUser, listUsers, getUserByCustomer} from '../../shared/graphql/queries';
import { getTeam, listTeams, getTeamByCustomerID } from '../../shared/graphql/queries';
import { listCustomers } from '../../shared/graphql/queries';
import { getConectivity, listConectivities,  } from '../../shared/graphql/queries';
import { getCategory, listCategories,  } from '../../shared/graphql/queries';
import { getImage, listImages,  } from '../../shared/graphql/queries';
import { getCompletedScheduledConectivityByCustomer } from '../../shared/graphql/queries'; 
import { getCCTransaction, listCCTransactions, getCCTransactionsByCustomerIDByTransactionType, getCCTransactionsByCustomerIDByCreatedAt } from '../../shared/graphql/queries';

//Bootstrap and other React components


//Our  components
import UserConectivitySummary from "./Components/userConectivitySummary/userConectivitySummary";
import { ConectivityGraph } from "./Components/conectivityGraph/conectivityGraph";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context

//Utils
import { compareTransactionsByDateReverseChronological, generateUniqueTransactionsForEdge, filterTransactionsByPeriod } from "../../shared/utils/transactionsUtils.js";

import moment from 'moment';

const AdminGraphConectivity = () => {
  
   //Authentication context
    const {
            authState, currentUser, 
            isSuperAdmin, 
            isAdmin, 
            permissionAnalytics,
            
    } = useContext(AuthContext);    
  
    //Analytics context
    const {
            analyticsGenerated,
            userAnalytics, teamAnalytics, customerAnalytics,
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData,
            timeSeriesGraphDataDay, timeSeriesGraphDataWeek, timeSeriesGraphDataMonth, timeSeriesGraphDataQuarter, timeSeriesGraphDataYear,                      
            setTimeSeriesGraphDataDay, setTimeSeriesGraphDataWeek, setTimeSeriesGraphDataMonth, setTimeSeriesGraphDataQuarter, setTimeSeriesGraphDataYear,  
            displayReportControls, graphPeriod, selectedGraph, selectedUsersOptions, selectedTeamsOptions, transactionStartDateTime, transactionEndDateTime, selectedReport, setSelectedReport,
            transactionCache, transactionOldestStartDateTimeFetched, transactionCacheCustomerID, transactionCacheInitialized, transactionCountFilteredDateRange, transactionsFiltered,
    } = useContext(AnalyticsContext);


  //variables for holding state data 
    const [showUserSummary, setShowUserSummary] = useState(false);  
    const [transactionsForUserSummary,setTransactionForUserSummary] = useState([]);
    const [userIDsToSummarize, setUserIDsToSummarize] = useState(null);  //User IDs based on node or edge click; used to drive the summaries    
    
    const [dashboardWidth, setDashboardWidth] = useState("1200px");     //Initial values which will be overwritten on mount
    const [dashboardHeight, setDashboardHeight] = useState("800px");


    //On load, set Report select option to THIS page
    //On initial mount, set dashboard size; all of this is to allow the user to control the canvas size of the network graph
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "CONECTIVITY_VISUALIZATION");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);

        setDashboardSize();

    });
    

     //Listener for dynamic resizing of the window; update canvas in response
      useEffect(() => {
 
        window.addEventListener('resize', setDashboardSize);
    
        return () => {
          window.removeEventListener('resize', setDashboardSize);
        };
      }, []);

    function setDashboardSize() {
         const {innerWidth, innerHeight} = window; //Get current size of viewport
        //  if (DEBUG_MODE >= 2) console.log("Current viewport", innerWidth, innerHeight);
          
         var tempDashboardWidth = Math.floor(innerWidth*0.7);
        //  if (tempDashboardWidth < 1200) tempDashboardWidth = 1200;
         setDashboardWidth(tempDashboardWidth);

         var tempDashboardHeight = Math.floor(innerHeight*0.8);
        //  if (tempDashboardHeight < 1200) tempDashboardHeight = 1200;
         setDashboardHeight(tempDashboardHeight);
         
    }
    
     //Get data upon opening this page
    useEffect(() => {
        handleSummarizeUsers();  
    }, [userIDsToSummarize]);
    
    
    
    //UsersToSummarize is an array of one or more userIDs.  If 1 then a node was clicked.  If 2 then an edge was clicked
    async function handleSummarizeUsers () {

        if (DEBUG_MODE >= 2) console.log("Handle Summarize Users Invoked", userIDsToSummarize);

        if (!userIDsToSummarize) return;
        if (!userIDsToSummarize.length) return;
        if (userIDsToSummarize.length < 1) return;

        var tempTransactions = [];
  
        // Filter by graph period, if selected     
        if (graphPeriod && graphPeriod !="ALL")  {
            tempTransactions = filterTransactionsByPeriod(transactionsFiltered, graphPeriod);
        } else {
            tempTransactions = transactionsFiltered;
        }

        //If summarizing a single user, filter transactions down to ANY transaction where the user recorded the transaction or is a participant
        //For now - include ALL types of transactions for a summary of a single user
        if (userIDsToSummarize.length == 1) {
             tempTransactions = tempTransactions.filter(transaction => (
            //  (transaction.transactionType=='SPOTLIGHT_SENT' || transaction.transactionType=="COMPLETED_CONECTIVITY") &&             //Must be one of these two types of transactions
            ((transaction.userID === userIDsToSummarize[0]) ||                                                                      
             (transaction.usersThatParticipatedWithMe && transaction.usersThatParticipatedWithMe.includes(userIDsToSummarize[0])))));
             
        } else if (userIDsToSummarize.length > 1) {
            tempTransactions = generateUniqueTransactionsForEdge({transactions:tempTransactions, fromUserID:userIDsToSummarize[0], toUserID:userIDsToSummarize[1]});
        }
        
        //Sort the transactions by sequence Number
        tempTransactions.sort(compareTransactionsByDateReverseChronological);
        setTransactionForUserSummary(tempTransactions); 
        
        if (DEBUG_MODE >= 2) console.log("Displaying user summary modal", userIDsToSummarize, tempTransactions);

        setShowUserSummary(true);

    }


  //
  // HANDLERS FOR GRAPH INTERACTION
  // 

  function handleNodeHover (event) {
    
    // if (DEBUG_MODE >= 2) console.log("Hover on node", event.node);
    // 
  }
  
  function handleNodeDoubleClick (userID) {
    
    if (DEBUG_MODE >= 2) console.log("NODE Double click callback invoked for userID:", userID);
    
    
    if (userID) {
        if (DEBUG_MODE >= 2) console.log("Storing userID", userID);
        const tempArray = [userID];
       setUserIDsToSummarize(tempArray);
    } else {
        if (DEBUG_MODE >= 2) console.log("Invalid userID", userID);
    }
  }


  function handleEdgeDoubleClick (userIDs) {
    
    if (DEBUG_MODE >= 2) console.log("EDGE Double click callback invoked having users:", userIDs);
    
    if (userIDs && userIDs.length && userIDs.length >1) {
       setUserIDsToSummarize(userIDs);
    }
    
  }

    const handleCancelUserSummary = () => {
        setShowUserSummary(false);
    };
 
    
  if (authState !== 'signedin') return null;


      // <Graph
      //   graph={graph}
      //   options={options}
      //   events={events}
      //   getNetwork={network => {
      //     //  if you want access to vis.js network api you can set the state in a parent component using this property
      //   }}
      // />

  //This function is invoked when the user adjusts the slider
  //Make a copy of the Master Graph Data (i.e., the current graph object) and filters it based on slider setting

    
 //Handle access by unauthenticated user
  if (authState !== "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
  }

 //Render function for authenticated user 
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">CONECTIVITY VISUALIZATION</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  }  else return (
        <Authenticator>
         <div className="adminPage">

            <UserConectivitySummary 
                showUserSummary={showUserSummary} 
                transactions={transactionsForUserSummary} 
                handleCancelUserSummary={handleCancelUserSummary} 
                userIDsToSummarize={userIDsToSummarize}
                showConectivityGraphTab={false}
            /> 

            <div className="ContainerVertical">
                <div>
                    {displayReportControls({useUserSelect:true, useDateSelect:true,  width:"auto", useReportSelect:true, usePeriodSelect:true, useGraphItemSelect:true})}
                </div>
                <div className="dashboardContainer boxShadow" >
                    <div className="dashboardTitle">
                        ENTERPRISE CONECTIVITY VISUALIZER 
                     </div>   
     
                     <div className="dataGridWrapperTop" style={{width:REPORT_DATAGRID_WIDTH, height:dashboardHeight}}>
                         <ConectivityGraph doubleClickNodeCallback={handleNodeDoubleClick} doubleClickEdgeCallback={handleEdgeDoubleClick} hoverCallBack={handleNodeHover}/>
                    </div>

               </div>  
            </div>
            
       </div>
   </Authenticator>
  );
};




export default AdminGraphConectivity;
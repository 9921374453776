//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR } from './conectereConfigData';

//React
import React from "react";

//Utils
import moment from 'moment';
import { formatDate, truncateStringWithDots, displayAwards  } from "../utils/generalUtils";
import { setHeaderColorByStringLength, compareConectivitiesMostFavorite, setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../utils/conectivityUtils";
import { displayUsersRow }  from "../utils/userAndTeamUtils";
import { DisplayEmployee, displayTransactionAmount } from "../utils/generalUtils";

// No Rows messages
export const NO_MENTORSHIP_ROWS = "No employee records.";
export const NO_SPONSORSHIP_ROWS = `You have not designated any employees as candidates for sponsorship in your employee profiles.  Any employee selected as a candidate will be listed in this dashboard.`

// Reusable Column Components
export const CUSTOMER_COLUMN = 	{
	field: 'customer',
	headerName: 'CUSTOMER',
	align:'center',
	headerAlign: 'center',
	minWidth: 200,
	flex:1,
	editable: true,
	color:'white',
	renderCell: (params)=> {
		return (
			<div className='TextStyle3'> {params.row.customerName} </div>
		);
	},    
};


export const USER_COLUMN =       {
	field: 'user',
	headerName: 'EMPLOYEE',
	headerAlign: 'center',
	align:'center',
	minWidth: 200,        
	color:'white',
	sortable: true,
	disableColumnMenu: true,
	renderCell: (params)=> {
		 return <DisplayEmployee
			  name={params.row.userName} 
			  avatarUrl={params.row.avatarUrl} 
			  initials={params.row.initials} 
		 />;
	  },
 };

 export const USER_COLUMN_WITH_PERMISSIONS =  {
	field: 'user',
	headerName: 'EMPLOYEE',
	headerAlign: 'center',
	align:'center',
	minWidth: 200,        
	color:'white',
	sortable: true,
	disableColumnMenu: true,
	renderCell: (params)=> {
		return <DisplayEmployee 
		name={params.row.name}
		avatarUrl={params.row.avatarUrl} 
		initials={params.row.initials} 
		permissionAnalytics={params.row.permissionAnalytics} 
		permissionEditor={params.row.permissionEditor} 
		permissionLaunch={params.row.permissionLaunch}
		permissionOrders={params.row.permissionOrders}
		permissionBilling={params.row.permissionBilling}
	/>;
	  },
 };

export const TEAM_COLUMN = {
	field: 'teams',
	headerName: 'TEAMS',
	headerAlign: 'center',
	align:'left',
	sortable: true,
	disableColumnMenu: true,
		// resizable: true,
	minWidth: 250,
	flex:2,
	color:'white',
	// hide: ( innerWindowWidth < DATA_GRID_HIDE_ROW_THRESHOLD1),
	renderCell: (params)=> {
		return (
			<div className="ContainerNoHeighCenter" style={{height:"clamp(30px,5vh,50px)", whiteSpace: "normal", wordBreak: "break-word" }}> {params.row.teams} </div>
			);
		},
};

export const WALLET_COLUMN = {
	field: 'coinsAwarded',
	headerName: 'WALLET',
	headerAlign: 'center',
	align:'center',
	minWidth: 200,
	sortable: false,
	disableColumnMenu: false,
	flex:1,
	// hide: ( innerWindowWidth < DATA_GRID_HIDE_ROW_THRESHOLD2),
	 // resizable: true,
	color:'white',
	renderCell: (params)=> {
		 return displayAwards({
			coins:params.row.coinsAwarded, 
			badgesDEI:params.row.badgesDEIAwarded, 
			badgesCS:params.row.badgesCSAwarded, 
			badgesSpotlight:params.row.badgesSpotlight});
	}
  };

  export const REWARD_COLUMN =
  {
	field: 'conectCoins',
	headerName: 'AWARDS',
	headerAlign: 'center',
	minWidth: 100,
	flex:1,
	editable: true,
	sortable: false,
	disableColumnMenu: true,
	color:'white',
	 renderCell: (params)=> {
		 return (
					<div className="ContainerNoHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
						 <div >
							  {params.row.conectCoins} 
							  <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
						 </div>
						 {!params.row.badgesDEI ? "" :
						 <div>
							  {params.row.badgesDEI} 
							  <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
						 </div>
						 }
						 {!params.row.badgesCS ? "" :
						 <div>
							  {params.row.badgesCS} 
							  <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  />
						 </div>
						 }
					</div>
			  );
	}
 };

  export const AMOUNT_COLUMN = {
	field: 'amount',
	headerName: 'AMOUNT',
	headerAlign: 'center',
	align:'center',
	minWidth: 50,
	sortable: false,
	disableColumnMenu: false,
	// resizable: true,
	background:COLOR_BLUE_HEADER,
	color:'white',
	disableExport: true,         
	renderCell: (params)=> {
		return displayTransactionAmount ({amount:params.row.amount, badgesDEI:params.row.badgesDEI, badgesCS:params.row.badgesCS, badgesSpotlight:params.row.badgesSpotlight});
	}
 };

 export const TRANSACTION_COLUMN = 	{
	field: 'transaction',
	headerName: 'TRANSACTION',
	headerAlign: 'center',
	align:'center',
	minWidth: 100,
	sortable: false,
	disableColumnMenu: false,
	flex:1.5,
	// resizable: true,
	background:COLOR_BLUE_HEADER,
	color:'white',
	renderCell: (params)=> {
		return (
			<div className="ContainerNoHeightFlexLeft" style={{width:"100%"}}>
	 
				 <img className='ccImgLarge' src={params.row.image}  />

					<div style={{paddingLeft:"clamp(4px,0.5vw,8px)", color:setHeaderColorByCategory(params.row.category), textWrap:"wrap", wordBreak:"break-word"}}>
					{params.row.name}
					</div>
			</div>
			);
	},

 };

 export const TRANSACTION_MEMO_COLUMN = 	{
	field: 'memo',
	headerName: 'MEMO',
	headerAlign: 'center',
	hide:true,
	minWidth: 250,
	sortable: false,
	disableColumnMenu: false,
	color:'white',
	renderCell: (params)=> {
		return (
			 <div className="TextStyle2 memoDataStyle">
				{params.row.memo}
			</div>
		);
	},
 };

export const CALENDAR_COLUMN = 	 {
	field: 'calendar',
	headerName: 'CALENDAR',
	headerAlign: 'center',
	align:'center',
	sortable: false,
	minWidth: 90,
	flex:1,
	color:'white',
	sortable: false,
	disableColumnMenu: false,
	// hide: ( innerWindowWidth < DATA_GRID_HIDE_ROW_THRESHOLD1),
	renderCell: (params)=> {
		
		if (params.row.msteamsCalendarEnabled || params.row.msoutlookCalendarEnabled || params.row.ms365CalendarEnabled)
			return (
					<img className="ccImgXXL" src={CONECTERE_CONFIG_DATA.MSFT_LOGO} alt='/>
				);
		else if (params.row.googleCalendarEnabled)
			return (
					<img className="ccImgXXL" src={CONECTERE_CONFIG_DATA.GOOGLE_CALENDAR_LOGO} alt='/>
				);
		return (
				<div>None</div>
			);
		}
	};

 //
 // GRIDS
 // 

// Grid for User Summary
export const userSummaryColumns = [
  {
	field: 'createdAt',
	headerName: 'DATE',
	headerAlign: 'center',
	align:'center',
	minWidth: 50,
	flex:0.5,
	// resizable: true,
	background:COLOR_BLUE_HEADER,
	color:'white',
	sortable: true,
	disableColumnMenu: true,
	valueFormatter: ({ value }) => {
		const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
		return tempTime.format(" MMM DD, YYYY ");
	},
	renderCell: (params)=> {
		return (
			<div className="menuDataGridTextField TextStyle4" >
				{formatDate(params.row.createdAt)}
			</div>
			);
	}
  },
  TRANSACTION_COLUMN,
  TRANSACTION_MEMO_COLUMN,
  {
	field: 'usersToShow',
	headerName: '',
	headerAlign: 'center',
	align:'center',
	minWidth: 50,
	flex:1,
	sortable: false,
	disableColumnMenu: false,
	// resizable: true,
	background:COLOR_BLUE_HEADER,
	color:'white',
	renderCell: (params)=> {
		const USERS_TO_SHOW = 5;
		return (
			<div className="ContainerNoHeightCenter" style={{height:"100%"}}>
				 {params.row.usersToShow.length == 0 ? "" :
					<div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper blue"  >
						{displayUsersRow({usersToShow:params.row.usersToShow, numUsersToShow:USERS_TO_SHOW, isSmall:true})}
					</div>
				 }
			
			</div>            
			);
	}

  },
  AMOUNT_COLUMN,
   {
	field: 'balance',
	headerName: 'BALANCE',
	headerAlign: 'center',
	align:'center',
	minWidth: 100,
	color:'white',
	sortable: false,
	disableColumnMenu: false,
	flex:1,
	renderCell: (params)=> {
		return displayAwards({coins:params.row.newBalance, badgesDEI:params.row.badgesDEIBalance, badgesCS:params.row.badgesCSBalance, badgesSpotlight:params.row.badgesSpotlightBalance, showZeros:false});
	}        
  },  
];

// TRANSACTION COLUMNS
export const transactionColumns = [
	{
	  field: 'createdAt',
	  headerName: 'DATE',
	  headerAlign: 'center',
	  align:'center',
	  minWidth: 175,
	  // resizable: true,
	  background:COLOR_BLUE_HEADER,
	  color:'white',
	  sortable: false,
	  disableColumnMenu: false,
		valueFormatter: ({ value }) => {
		  const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
		  return tempTime.format(" MMM DD, YYYY ");
	  },
	  renderCell: (params)=> {
		  return (
			  <div className="menuDataGridTextField TextStyle4" style={{height:"100%", width:"clamp(50px,10vw,100px)"}}>
				  {formatDate(params.row.createdAt)}
			  </div>
			  );
	  }
	},
	CUSTOMER_COLUMN,
	USER_COLUMN,	
	TRANSACTION_COLUMN,
	TRANSACTION_MEMO_COLUMN,

	{
	  field: 'usersToShow',
	  headerName: 'PARTICIPANTS',
	  headerAlign: 'center',
	  align:'center',
	  minWidth: 180,
	  flex:1,
	  sortable: false,
	  disableColumnMenu: false,
	// resizable: true,
	  background:COLOR_BLUE_HEADER,
	  color:'white',
	  renderCell: (params)=> {
		  
		  const USERS_TO_SHOW = 5;
		  
		  
		  return (
			  <div className="ContainerNoHeightCenter" style={{height:"100%"}}>
				   {params.row.usersToShow.length == 0 ? "" :
					  <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper blue"  >
						  {displayUsersRow({usersToShow:params.row.usersToShow, numUsersToShow:USERS_TO_SHOW, isSmall:true})}
						</div>
				   }
			  
			  </div>            
			  );
	  }
  
	},
	AMOUNT_COLUMN,
  ];
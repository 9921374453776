//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//Config
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_WHITE, COLOR_BLUE_HEADER, BALANCE_COLOR, TEAM_COLOR } from '../../shared/data/conectereConfigData';

//Styles
import '../../shared/styles/App.css';
import './employeeSpotlights.css';

//Amplify, React & Context
import React, {  useState, useContext, useCallback } from 'react';
import { API } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";

//Context
import { AuthContext } from '../../shared/context/authContext';
import { SpotlightContext } from '../../shared/context/spotlightContext';
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { SlackButton }  from "../../shared/utils/generalUtils";
import { SpotlightCreateButton }  from "../../shared/utils/spotlightUtils";

//Our Components
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import { SpotlightSendTool } from "../../shared/Components/spotlightSendTool/spotlightSendTool";
import { SpotlightFeed } from "./Components/spotlightFeed/spotlightFeed";

//Subscriptions
// import { onUpdateUserProgressDataByUser } from "./graphql/subscriptions";

//Components
// import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
// import moment from 'moment';

//TINY editor
// import { Editor } from '@tinymce/tinymce-react'; 
// import { v4 as uuidv4 } from 'uuid';    

//React Component for this page
const EmployeeSpotlightFeed = () => {

   // Context
    const { authState, currentUser } = useContext(AuthContext);    
    const { recognitions,  SpotlightsHeader,  handleDeleteSpotlightClick, handleEditSpotlightClick, setShowSendSpotlightModal } = useContext(SpotlightContext);

    /*
    // Local state
    const [showSendSpotlightModal, setShowSendSpotlightModal] = useState(false);

    //Define a handler that only re-renders if our master recognitions changes
    const spotlightSendtoolCallbackHandler = useCallback((successFlag) => {
        setShowSendSpotlightModal(false);

        if (successFlag) {
            setModalGeneralMessage("Spotlight cast! Your peer recognition message will be posted shortly!");
            setModalGeneralTitle("Success!");
            setShowModalGeneral(true);   
        } else {
            setModalGeneralMessage("Failed to send recognition");
            setModalGeneralTitle("Ooops!");
            setShowModalGeneral(true);   
        }

        if (DEBUG_MODE >= 2) console.log("Employee Spotlight callback called in response to spotlight save", successFlag);
              
     }, [recognitions]);

    const handleCancel = () => {
            setShowSendSpotlightModal(false);
            setShowModalGeneral(false);
        };

 */   
    function spolightFeedLeft() {
            return (
                <div className="ContainerNoHeightFlexRightTop spotlightFeedPageLeft">
                </div>
                );
    }
    
    function spolightFeedCenter() {
        return (
            <div className="ContainerNoHeightCenter spotlightFeedPageCenter">
                <SpotlightFeed spotlights={recognitions} handleDeleteSpotlightClick={handleDeleteSpotlightClick} handleEditSpotlightClick={handleEditSpotlightClick}/>
            </div>
        );
    }
    
    function spolightFeedRight() {
        return null;
    }

    function spolightFeedActionRow() {
        return (
            <div className="ContainerNoHeightCenter"  style={{paddingTop:"clamp(4px,1vw,10px)"}}>
                <SpotlightCreateButton currentUser={currentUser} callback={setShowSendSpotlightModal}/>                             
                 {false && currentUser && currentUser.customer && currentUser.customer.configDetails.slackEnabled ?
                    <div style={{padding:"0 10px"}}>
                        <SlackButton 
                            hoverTextClassName='TextStyle4 hoverTextStyle1 '
                            hoverText={"Integration with Slack Enabled! 👍  We'll share Spotlights to your company's Slack Spotlight channel."} 
                        /> 
                    </div>
                    : null
                }
            </div>
            );
}

    //Handle access by unauthenticated user
    if (authState !== "signedin"  || !currentUser) {
    //   if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 
    
  return (
    <Authenticator>  
        <div className="employeeSpotlightPageWrapper" >
            <SpotlightsHeader />
            {spolightFeedActionRow()}
            <div className="employeeSpotlightFeedInnerPageWrapper" >
                {spolightFeedLeft()}
                {spolightFeedCenter()}
                {spolightFeedRight()}
            </div>
        </div>
    </Authenticator>
    
  );
};  //Wrapped in React.Memo



export default EmployeeSpotlightFeed;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

// import "./displayCardSpendStats.css";
import React from 'react';

import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const CCdisplayCardSpendStats = React.memo(({analytics}) => {

    const title="Monthly Forecast";
    
    const footer="";

    // if (DEBUG_MODE >= 2) console.log("Conectivity Stats Card called", analytics);
    
    return (

        <div className="dashboardCard dashboardCardWideProfile">
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            
            <Divider width={80} />


            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{analytics.activeEmployees}</div>
                <div className="dashboardCardText">Active Employees</div>
            </div >

            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{(analytics.totalMonthlyTimePerEmployee/ 60).toFixed(1)} hrs</div>
                <div className="dashboardCardText">Paid Time per Employee</div>
            </div >
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{(analytics.totalMonthlyTime / 60).toFixed(1)} hrs</div>
                <div className="dashboardCardText">Total Paid Time</div>
            </div >

            <Divider width={80} />

            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">${(analytics.totalMonthyInvestmentPerEmployee).toFixed(2)}</div>
                <div className="dashboardCardText">Awards per Employee </div>
            </div >

            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">${analytics.totalMonthyInvestment.toFixed(2)}</div>
                <div className="dashboardCardText">Total Award Spend</div>
            </div >

            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">${(analytics.serviceCostPerMonth * analytics.activeEmployees).toFixed(2)} </div>
                <div className="dashboardCardText">Conectere Service Cost</div>
            </div >

            <Divider width={80} />

            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">${(analytics.totalMonthyInvestment + analytics.serviceCostPerMonth * analytics.activeEmployees).toFixed(2)}</div>
                <div className="dashboardCardText">Total Cost</div>
            </div >



            <span className="dashboardCardTextFooter"><center> {footer} </center></span>
        </div>
        
    );

});


export default CCdisplayCardSpendStats;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021


import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD, DEI_COLOR1, CS_COLOR1, COLOR_BLUE_TEXT, BALANCE_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TEAM_COLOR, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH } from '../../shared/data/conectereConfigData';

import React, { useEffect, useState, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries needed for analytics
import { getCCTransactionsByCustomerIDByCreatedAt } from '../../shared/graphql/queries'; 
import { getCustomer } from '../../shared/graphql/queries';

//Our Analytics components
import CCStackedBarChart from "../../shared/Components/stackedBarChart/stackedBarChart";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context

//Our Components

//Utils
import {  calcAnalytics, renderOurRechartLegend, renderRechartTooltip } from "../../shared/utils/analyticsUtils";
import { queryDataTableWithPagination } from "../../shared/utils/databaseUtils";
import {NOW, ONE_YEAR_AGO, NOW_STRING, ONE_YEAR_AGO_STRING} from "../../shared/utils/dateTimeUtils";
import moment from 'moment';

//Here's the start of our Javascrpt App
const AdminGraphConectivityByTime = () => {

    //Graph data selected to display
    const [timeSeriesGraphDataMaster, setTimeSeriesGraphDataMaster] = useState(null);

    //State data for this report page

    //Dynamic Graph Inputs
    const[graphTitle, setGraphTitle] = useState("");
    const[dataKeyY1, setDataKeyY1] = useState("");
    const[dataKeyY2, setDataKeyY2] = useState("");
    const[dataKeyY3, setDataKeyY3] = useState("");
    const[dataKeyY4, setDataKeyY4] = useState("");
    const[color1, setColor1] = useState("");
    const[color2, setColor2] = useState("");
    const[color3, setColor3] = useState("");
    const[color4, setColor4] = useState("");

   //New Authentication context
    const {
            authState, currentUser, 
            isSuperAdmin, 
            isAdmin, 
            permissionAnalytics,
            
    } = useContext(AuthContext);    


    //Analytics context
    const {
            analyticsGenerated,
            userAnalytics, teamAnalytics, customerAnalytics,
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData,
            timeSeriesGraphDataDay, timeSeriesGraphDataWeek, timeSeriesGraphDataMonth, timeSeriesGraphDataQuarter, timeSeriesGraphDataYear,                      //Time Series Data
            setTimeSeriesGraphDataDay, setTimeSeriesGraphDataWeek, setTimeSeriesGraphDataMonth, setTimeSeriesGraphDataQuarter, setTimeSeriesGraphDataYear,                      //Time Series Data
            displayReportControls, graphPeriod, selectedGraph, selectedUsersOptions, selectedTeamsOptions, transactionStartDateTime, transactionEndDateTime, selectedReport, setSelectedReport,
    } = useContext(AnalyticsContext);



    //On load, set Report select option to THIS page
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "CONECTIVITY_GRAPH");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
    });



    // 
    // HANDLERS FOR RESPONDING TO UPDATES TO REPORT CONTROLS 
    //
    
    
    // Handle change to selected Period or to the underlying graph data- update graph to display
    useEffect(() => {
        handlePeriodSelection();
    }, [graphPeriod, timeSeriesGraphDataDay, timeSeriesGraphDataWeek, timeSeriesGraphDataMonth, timeSeriesGraphDataQuarter, timeSeriesGraphDataYear]);

    function handlePeriodSelection () {  

    //   if (DEBUG_MODE >= 2) console.log("Handling graph period selection", graphPeriod, timeSeriesGraphDataDay, timeSeriesGraphDataWeek);
       
       switch(graphPeriod) {    
        case 'DAY':
            setTimeSeriesGraphDataMaster(timeSeriesGraphDataDay);
            break;
        case 'WEEK':
            setTimeSeriesGraphDataMaster(timeSeriesGraphDataWeek);
            break;
        case 'MONTH':
            setTimeSeriesGraphDataMaster(timeSeriesGraphDataMonth);
            break;    
        case 'QUARTER':
            setTimeSeriesGraphDataMaster(timeSeriesGraphDataQuarter);
            break;
        case 'YEAR':
            setTimeSeriesGraphDataMaster(timeSeriesGraphDataYear);
            break;
        default:
            setTimeSeriesGraphDataMaster(timeSeriesGraphDataDay);
            break;
        }
    }

    // Handle change to Graph selection - select a different graph to display
    useEffect(() => {
        handleGraphSelection();
    }, [selectedGraph]);

    const handleGraphSelection = () => {

       if (DEBUG_MODE >= 2) console.log("Handling graph selection", selectedGraph);
        
        switch (selectedGraph) {
             case "CONECTIVITY_BY_CAT":
                setGraphTitle("CONECTIVITIES");
                setDataKeyY1("stressConectivities");
                setDataKeyY2("teamConectivities");
                setDataKeyY3("individualConectivities");
                setDataKeyY4("socialConectivities");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);
                break;
             case "COINS_BY_CAT":
                setGraphTitle("COINS EARNED");
                setDataKeyY1("stressCoinsEarned");
                setDataKeyY2("teamCoinsEarned");
                setDataKeyY3("individualCoinsEarned");
                setDataKeyY4("socialCoinsEarned");
                setColor1(BALANCE_COLOR);
                setColor2(TEAM_COLOR);
                setColor3(GROWTH_COLOR);
                setColor4(SOCIAL_COLOR);                
                break;
             case "BADGES":
                setGraphTitle("BADGES EARNED");
                setDataKeyY1("badgesDEIReceived");
                setDataKeyY2("badgesCSReceived");
                setDataKeyY3("");
                setDataKeyY4("");
                setColor1(DEI_COLOR1);
                setColor2(CS_COLOR1);
                setColor3("");
                setColor4("");               
                break;
        } 
    };
    

    //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 

    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else {

      return (

            <Authenticator>
    
               <center>

                <div className="ContainerVertical">
                
                    <div>
                        {displayReportControls({useUserSelect:true, useDateSelect:true,  width:"auto", useReportSelect:true, usePeriodTypeTwoSelect:true, useGraphItemSelect:true})}
                    </div>
                    <div className="dashboardContainer boxShadow">
                    
                        <div className="dashboardTitle">
                            {graphTitle + " EACH " + graphPeriod}
                         </div>   
        
                        <center>
                        
     
                             {timeSeriesGraphDataMaster  ?
                                 <div className="dataGridWrapperTop" style={{width:REPORT_DATAGRID_WIDTH}}>        

                                      <CCStackedBarChart  data={timeSeriesGraphDataMaster}
                                        dataKeyX="xKey" 
                                        dataKeyY1={dataKeyY1} color1={color1}
                                        dataKeyY2={dataKeyY2} color2={color2}
                                        dataKeyY3={dataKeyY3} color3={color3}
                                        dataKeyY4={dataKeyY4} color4={color4}
                                        width={REPORT_DATAGRID_WIDTH}
                                        grid 
                                        renderLegend = {renderOurRechartLegend}
                                        renderTooltip = {renderRechartTooltip}
                                    />
                                 </div>
                                        
                                : null
                             }
            
                         </center>
                    </div>  
                </div>
                
           </center>

        </Authenticator>
 
      );
  }
};




export default AdminGraphConectivityByTime;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import './spotlightSendTool.css';

//React & Amplify
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from 'react';
import { API, graphqlOperation } from 'aws-amplify';

//Config Data
import {
  CONECTERE_CONFIG_DATA,
  DEBUG_MODE,
  COLOR_BLUE_HEADER,
  TEAM_COLOR,
  SPOTLIGHT_MESSAGE_SIZE_LIMIT,
  BALANCE_COLOR,
  SOCIAL_COLOR,
  GROWTH_COLOR,
  SPOTLIGHT_INITIAL_STATE,
  SPOTLIGHT_DAYS_TO_EXPIRATION,
  SPOTLIGHT_AMOUNT_RECEIVER_MIN,
  SPOTLIGHT_AMOUNT_RECEIVER_MAX,
  SPOTLIGHT_SLIDER_STEP,
  SPOTLIGHT_AMOUNT_SENDER,
  SPOTLIGHT_AMOUNT_RECEIVER,
  SPOTLIGHT_TRANSACTION_TITLE_SENDER,
  SPOTLIGHT_TRANSACTION_MEMO_SENDER,
  SPOTLIGHT_TRANSACTION__IMAGE,
  SPOTLIGHT_TRANSACTION_TITLE_RECIPIENT,
  SPOTLIGHT_TRANSACTION_MEMO_RECIPIENT,
  GIPHY_SDK_KEY_WEB,
  GIPHY_ID_IDENTIFIER,
  SPOTLIGHT_ROW_SCROLL_DURATION_BUTTON_PRESS,
  SPOTLIGHT_ROW_PIXELS_PER_SEC,
  SPOTLIGHT_ROW_SCROLL_DURATION_FULL_ROW,
} from '../../data/conectereConfigData';
import {
  HELP_TEXT_SPOTLIGHT_1,
  HELP_TEXT_SPOTLIGHT_AWARDS_1,
} from '../../data/helpData.js';

//Icons
import HighlightIcon from '@mui/icons-material/Highlight';
import SendIcon from '@mui/icons-material/Send';

//Context
import { AuthContext } from '../../context/authContext';
import { CustomerContext } from '../../context/customerContext'; //Customer Authentication context
import { DisplayContext } from '../../context/displayContext'; //User Authentication Context

//Our components
// import ModalBonus from "../modalBonus/modalBonus";
import { ChatWindow } from '../chatWindow/chatWindow';

//Other components

//Utils
import {
  HelpButton,
  ConectereDivider,
  EmojiButton,
  SendButton,
  GIFButton,
  GIFButtonV2,
} from '../../utils/generalUtils';
import {
  setHeaderColorByStringLength,
  setConectivityImageUrlByCategory,
} from '../../utils/conectivityUtils';
import {
  drawSpotlightCurvedText,
  displayRecognizedUsersAvatars,
  invokeCreateSpotlight,
  invokeEditSpotlight,
  invokeDeleteRecognizedEmployee,
  createUserRecognitionRecord,
  recordRecognitionTransaction,
} from '../../utils/spotlightUtils.js';
import {
  DisplayUserSelectComponent,
  DisplayTeamSelectComponent,
  getUserInitials,
  DisplayTeamSelectComponentV2,
  DisplayUserSelectComponentV2,
} from '../../utils/userAndTeamUtils';
import {
  issuePromptToAiInterface,
  injectMessagesFromAI,
  constructResponseMessages,
} from '../../utils/aiInterfaceUtils';
import moment from 'moment';

//Material UI
import Switch from '@mui/material/Switch';
import { IconButton } from '@mui/material';
import Slider from '@mui/material/Slider'; //Note documentation: https://mui.com/api/slider/
import Checkbox from '@mui/material/Checkbox';

import { v4 as uuidv4 } from 'uuid';
// import parse from "html-react-parser";              //Needed to render HTML from the Editor

import Picker from 'emoji-picker-react';

//GIPHY support
import {
  Gif,
  Grid as GiphyGrid,
  SearchBar, // the search bar the user will type into
  SearchContext, // the context that wraps and connects our components
  SearchContextManager, // the context manager, includes the Context.Provider
  SuggestionBar,
} from '@giphy/react-components';

//
//
// SPOTLIGHT BANNER COMPONENT
//
//

const Torch = (props) => {
  const { opposite } = props;

  return (
    <div className={`${opposite ? 'torch-opposite' : 'torch'}`}>
      <div className='torch-img-container'>
        <img
          src={CONECTERE_CONFIG_DATA.SPOTLIGHT_FOCUS}
          alt='spotlight'
          height='100%'
          width='100%'
        />
      </div>
      <div className={`spotlight-effect-wrapper spotlight-effect`} />
    </div>
  );
};

const SpotlightToolBanner = React.memo(
  ({ selectedUsersAndTeamsToRecognize }) => {
    const spotlightSendToolLightRingRightDiv = useRef(null);
    const spotlightSendToolLightRingLeftDiv = useRef(null);
    const spotlightSendToolLightRightDiv = useRef(null);
    const spotlightSendToolLightLeftDiv = useRef(null);
    const spotlightSendToolLightBeamRightDiv = useRef(null);
    const spotlightSendToolLightBeamLeftDiv = useRef(null);
    const [showHalo, setShowHalo] = useState(false);

    const [
      spotlightToolSendRotationTimerState,
      setSpotlightToolSendRotationTimerState,
    ] = useState('START_ROTATION');

    //useEffect Timer for animating the spotights in our Spotlight Tool
    useEffect(() => {
      var timer;
      if (spotlightToolSendRotationTimerState === 'START_ROTATION') {
        timer = setTimeout(() => {
          // if (DEBUG_MODE >= 2) console.log("SPOTLIGHT timer fired - STARTING ROTATION");

          if (
            spotlightSendToolLightRingRightDiv.current &&
            spotlightSendToolLightRingLeftDiv.current
          ) {
            //Add tags to initiate the spotlight rotation of lights and translation of light rings
            spotlightSendToolLightRingRightDiv.current.classList.add(
              'spotlightSendToolLightRingRightRotated'
            );
            spotlightSendToolLightRingLeftDiv.current.classList.add(
              'spotlightSendToolLightRingLeftRotated'
            );
            spotlightSendToolLightRightDiv.current.classList.add(
              'spotlightSendToolLightRightRotated'
            );
            spotlightSendToolLightLeftDiv.current.classList.add(
              'spotlightSendToolLightLeftRotated'
            );
            spotlightSendToolLightBeamRightDiv.current.classList.add(
              'spotlightSendToolLightBeamRightRotated'
            );
            spotlightSendToolLightBeamLeftDiv.current.classList.add(
              'spotlightSendToolLightBeamLeftRotated'
            );
            setShowHalo(false); //No halo since rotating outward
          } else {
            if (DEBUG_MODE >= 2)
              console.log('Rotation error - no DIV References');
          }

          //Timer fired, so set next state
          setSpotlightToolSendRotationTimerState('REMOVE_ROTATION');
        }, 2000); //In 2 seconds, start the rotation
      } else {
        timer = setTimeout(() => {
          // if (DEBUG_MODE >= 2) console.log("SPOTLIGHT timer fired - REMOVING ROTATION");

          if (
            spotlightSendToolLightRingRightDiv.current &&
            spotlightSendToolLightRingLeftDiv.current
          ) {
            //Remove the tags so rotate and translate back to origin
            spotlightSendToolLightRingRightDiv.current.classList.remove(
              'spotlightSendToolLightRingRightRotated'
            );
            spotlightSendToolLightRingLeftDiv.current.classList.remove(
              'spotlightSendToolLightRingLeftRotated'
            );
            spotlightSendToolLightRightDiv.current.classList.remove(
              'spotlightSendToolLightRightRotated'
            );
            spotlightSendToolLightLeftDiv.current.classList.remove(
              'spotlightSendToolLightLeftRotated'
            );
            spotlightSendToolLightBeamRightDiv.current.classList.remove(
              'spotlightSendToolLightBeamRightRotated'
            );
            spotlightSendToolLightBeamLeftDiv.current.classList.remove(
              'spotlightSendToolLightBeamLeftRotated'
            );
            setShowHalo(true); //Add avatar halo since rotating inward
          } else {
            if (DEBUG_MODE >= 2)
              console.log('Rotation error - no DIV References');
          }

          //Timer fired, so set next state
          setSpotlightToolSendRotationTimerState('START_ROTATION');
        }, 2000); //In 2 seconds, remove the rotation
      }

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    });

    return (
      <div className='spotlightSendToolBannerWrapper'>
        <div className='spotlightSendToolBanner'>
          <div
            className='spotlightSendToolLightBeamLeft'
            ref={spotlightSendToolLightBeamLeftDiv}
          ></div>
          <div
            className='spotlightSendToolLightLeft'
            ref={spotlightSendToolLightLeftDiv}
          >
            <HighlightIcon
              style={{
                fontSize: '48px',
                color: 'gold',
                transform: 'rotate(+130deg)',
              }}
            />
          </div>
          <div
            className='spotlightSendToolLightRingLeft'
            ref={spotlightSendToolLightRingLeftDiv}
          ></div>

          {/* drawSpotlightCurvedText({id:uuidv4(), isSmall:false}) */}
          <div className='TextStyle6A gold spotlightSendToolBannerText'>
            SPOTLIGHT
          </div>

          <div
            className='spotlightSendToolLightBeamRight'
            ref={spotlightSendToolLightBeamRightDiv}
          ></div>
          <div
            className='spotlightSendToolLightRight'
            ref={spotlightSendToolLightRightDiv}
          >
            <HighlightIcon
              style={{
                fontSize: '48px',
                color: 'gold',
                transform: 'rotate(-128deg)',
              }}
            />
          </div>
          <div
            className='spotlightSendToolLightRingRight'
            ref={spotlightSendToolLightRingRightDiv}
          ></div>

          <div className='spotlightSendToolBannerShadow' />
        </div>

        <div
          className='spotlightRecognizedUsersAvatarsWrapper'
          style={{ minHeight: '64px', position: 'relative' }}
        >
          {displayRecognizedUsersAvatars({
            recognizedEmployees: selectedUsersAndTeamsToRecognize,
            maxUsersToShow: 7,
            isSmall: false,
            isFull: true,
            showQuestionMarkIfNone: true,
            showHalo: showHalo,
          })}
        </div>
      </div>
    );
  }
);

//
//
// SPOTLIGHT SEND TOOL INCLUDING INPUT AND ASYNC OPERATIONS
//
//

export const SpotlightSendTool = React.memo(
  ({
    backgroundColor,
    callbackOnComplete,
    selectedSpotlight,
    spotlightEditMode,
  }) => {
    // Authentication context
    const { currentUser, isAdmin, isSuperAdmin } = useContext(AuthContext);

    // Customer context
    const {
      users,
      teams,
      teamsOptions,
      usersOptions,
      participatingUsersOptions,
    } = useContext(CustomerContext);

    // Display context
    const { innerWindowWidth, setShowSpinner } = useContext(DisplayContext);

    //State variables for this tool
    const [selectedTeamsToRecognize, setSelectedTeamsToRecognize] = useState(
      []
    ); //for multi-select
    const [selectedUsersToRecognize, setSelectedUsersToRecognize] = useState(
      []
    ); //for multi-select
    const [
      selectedUsersAndTeamsToRecognize,
      setSelectedUsersAndTeamsToRecognize,
    ] = useState([]); //For avatar rendering; combined selections
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    //AI state
    const [aiInterfaceMessages, setAiInterfaceMessages] = useState([]);
    const [AIinterfaceEnabled, setAIinterfaceEnabled] = useState(true);
    const [promptMessageFromUser, setPromptMessageFromUser] = useState(null);
    const [promptTrigger, setPromptTrigger] = useState(false);

    // const [spotlightEditorValue, setSpotlightEditorValue] = useState("");
    const [spotlightToPost, setSpotlightToPost] = useState(
      selectedSpotlight == null || spotlightEditMode !== 'EDIT'
        ? SPOTLIGHT_INITIAL_STATE
        : selectedSpotlight
    );

    //Modal state variables
    const [modalBonusLabel, setModalBonusLabel] = useState('');
    const [modalBonusHeaderColor, setModalBonusHeaderColor] = useState('');
    const [modalBonusMessage, setModalBonusMessage] = useState('');
    const [modalBonusAmount, setModalBonusAmount] = useState(0);
    // const [showModalBonus, setShowModalBonus] =useState(false);        //display Bonus Modal
    // const [showModalError, setShowModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('');

    // Comments, Emoji picker and GIPHY state data
    const [sendMessage, setSendMessage] = useState('');
    const [showPickerMessage, setShowPickerMessage] = useState(false);
    const [showSearchExperienceMessage, setShowSearchExperienceMessage] =
      useState(false);
    const [messageGif, setMessageGif] = useState(null);
    const [messageInputBarHeight, setMessageInputBarHeight] = useState('32px');
    const [
      spotlightSendToolGiphyGridContainerDivSize,
      setSpotlightSendToolGiphyGridContainerDivSize,
    ] = useState(450); //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match

    // use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
    // const gf = new GiphyFetch(GIPHY_SDK_KEY_WEB);

    // if (DEBUG_MODE >= 2) console.log("Send tool called", backgroundColor, spotlightEditMode, selectedSpotlight );

    //Our React References for the Spotlight Tool
    const spotlightSendToolGiphyGridContainerDivRef = useRef(null); //A ref to the DIV itself
    const conectereMessagingInputDIVRef = useRef(null);

    //Run on initial mount only
    useLayoutEffect(() => {
      if (true) {
        if (DEBUG_MODE) console.log('Enabling AI chatbot');
        setAIinterfaceEnabled(true);
        setAiInterfaceMessages([]);
        setPromptMessageFromUser('');
        setPromptTrigger(!promptTrigger);
      } else {
        if (DEBUG_MODE) console.log('Disable AI chatbot');
        setAIinterfaceEnabled(false);
      }
    }, []);

    //Updated every render of the component
    useLayoutEffect(() => {
      //Update size of Giphy Containers in order to dynamically set size of grids
      //Important, ONLY UPDATE IF THE SIZE HAS CHANGED SINCE UPDATING OUR STATE VARIABLE WILL TRIGGER A REACT RENDER
      if (
        spotlightSendToolGiphyGridContainerDivRef.current.clientWidth !==
        spotlightSendToolGiphyGridContainerDivSize
      ) {
        setSpotlightSendToolGiphyGridContainerDivSize(
          spotlightSendToolGiphyGridContainerDivRef.current.clientWidth
        );
        if (DEBUG_MODE >= 2)
          console.log(
            'Updated Send Tool GIPHY Grid Container client width',
            spotlightSendToolGiphyGridContainerDivRef.current.clientWidth,
            spotlightSendToolGiphyGridContainerDivSize
          );
      }

      //Dynamically adjust height of our input region as the user types to accomodate any scroll height of input area
      if (conectereMessagingInputDIVRef.current) {
        var newHeight =
          (conectereMessagingInputDIVRef.current.offsetHeight >
          conectereMessagingInputDIVRef.current.scrollHeight
            ? conectereMessagingInputDIVRef.current.offsetHeight
            : conectereMessagingInputDIVRef.current.scrollHeight
          ).toString() + 'px';
        // var newHeight = (value.length < 20 ? "32px" : conectereMessagingInputDIVRef.current.scrollHeight.toString() + "px" );
        setMessageInputBarHeight(newHeight);
        // if (DEBUG_MODE >= 2) console.log("Message input bar change.  Amount to scroll to bottom", conectereMessagingInputDIVRef.current.scrollHeight, newHeight);
      }

      if (containerRef && containerRef.current)
        setContainerHeight(containerRef.current.offsetHeight);
    }); //Run on any update

    //Pre-load input fields
    useEffect(() => {
      let tempSpotlight;

      //ADD mode?
      if (spotlightEditMode !== 'EDIT') {
        //Must be ADD - set initial values based on Config so (later) user can adjust
        tempSpotlight = {
          ...SPOTLIGHT_INITIAL_STATE,
          amount: currentUser.customer.configDetails.spotlightAmountReceiver
            ? currentUser.customer.configDetails.spotlightAmountReceiver
            : SPOTLIGHT_AMOUNT_RECEIVER,
          senderAmount: currentUser.customer.configDetails.spotlightAmountSender
            ? currentUser.customer.configDetails.spotlightAmountSender
            : SPOTLIGHT_AMOUNT_SENDER,
        };

        setSpotlightToPost(tempSpotlight);
        if (DEBUG_MODE >= 2)
          console.log(
            'Initialized Spotlight To Post for ADD Spotlight',
            tempSpotlight
          );

        return;
      }

      //Edit mode?
      if (selectedSpotlight == null) return;

      setSendMessage(selectedSpotlight.message);

      var tempRecognizedEmployeeOptions = [];

      //For each recognized individual
      for (
        var j = 0;
        j < selectedSpotlight.recognizedEmployees.items.length;
        j++
      ) {
        //Look in the array of USER Options to find the dropdown option for the user on this team
        const userOption = usersOptions.find(
          (userOption) =>
            userOption.id ===
            selectedSpotlight.recognizedEmployees.items[j].recognizedUserID
        );

        if (userOption) {
          tempRecognizedEmployeeOptions.push(userOption); //Add user to inital selections

          // console.log ("Pushed user onto selections:", userOption);
        }
      }

      setSelectedUsersToRecognize(tempRecognizedEmployeeOptions);
      setSelectedUsersAndTeamsToRecognize([...tempRecognizedEmployeeOptions]);

      tempSpotlight = { ...selectedSpotlight };

      if (selectedSpotlight.badgesDEI) tempSpotlight.deiRelated = true;
      else tempSpotlight.deiRelated = false;
      if (selectedSpotlight.badgesCS) tempSpotlight.csRelated = true;
      else tempSpotlight.csRelated = false;

      setSpotlightToPost(tempSpotlight);
    }, [spotlightEditMode, selectedSpotlight]);

    // const handleUpdateMessageValue = (value, editor) => {
    //     const newLength = editor.getContent({ format: "text" }).length;
    //     // if (DEBUG_MODE >= 2) console.log("New content length:",newLength);
    //     if (newLength <= SPOTLIGHT_MESSAGE_SIZE_LIMIT) {
    //         setSpotlightEditorValue(value);
    //         // setCurrentMessageLength(newLength); //No need to store unless we are displaying
    //     }
    // };

    //Main function for creating the DB records for a spotlight
    async function handlePostSpotlight(e) {
      e.preventDefault(); //prevent React page refresh

      if (DEBUG_MODE >= 2)
        console.log('Called handler to post a new Spotlight', spotlightToPost);

      // var tempMessage = "";

      // //Get content of TINY Editor using React Reference
      // if (editorRef.current) {
      //   tempMessage = editorRef.current.getContent();
      //  }

      //Required fields for Spotlights
      if (
        sendMessage == '' ||
        (selectedTeamsToRecognize.length === 0 &&
          selectedUsersToRecognize.length === 0)
      ) {
        return;
      }

      setShowSpinner(true); //Show spinners

      //Main function call to create the Spotlight int the system
      const returnObject = await invokeCreateSpotlight({
        sender: currentUser,
        spotlightToPost: spotlightToPost,
        recognitionMessage: sendMessage,
        messageGif: messageGif,
        selectedUsersToRecognize: selectedUsersToRecognize,
        selectedTeamsToRecognize: selectedTeamsToRecognize,
      });

      var completionMessage = '';
      if (returnObject.successFlag) {
        completionMessage =
          'Spotlight cast! Your peer recognition message will be posted shortly!';
      } else {
        completionMessage = 'Failed to send recognition';
      }

      setSelectedTeamsToRecognize([]);
      setSelectedUsersToRecognize([]);
      setSelectedUsersAndTeamsToRecognize([]);
      setSpotlightToPost(SPOTLIGHT_INITIAL_STATE);
      setSendMessage('');
      setShowSpinner(false); //Hide spinners

      //Invoke the parent callback if there is one
      if (callbackOnComplete)
        callbackOnComplete(returnObject.successFlag, completionMessage);
      else if (DEBUG_MODE >= 2) console.log('No callback registered');
    }

    //Main function for creating the DB records for a spotlight
    async function handleEditSpotlight(e) {
      e.preventDefault(); //prevent React page refresh

      //Safety check
      if (
        !sendMessage ||
        sendMessage === '' ||
        !selectedUsersToRecognize ||
        selectedUsersToRecognize.length === 0
      ) {
        if (DEBUG_MODE >= 1)
          console.error(
            'Error - improper params to edit spotlight',
            sendMessage,
            selectedUsersToRecognize
          );
        return;
      }
      setShowSpinner(true); //Show spinners
      let successFlag;
      try {
        //Main function call to inovke our backend Lambda edit the Spotlight int the system
        successFlag = await invokeEditSpotlight({
          spotlight: spotlightToPost,
          recognitionMessage: sendMessage,
          selectedUsersToRecognize: selectedUsersToRecognize,
          selectedTeamsToRecognize: selectedTeamsToRecognize,
        });

        var completionMessage = '';
        if (successFlag) {
          completionMessage = 'Your updated Spotlight will be posted shortly.';
        } else {
          completionMessage = 'Ooops. Failed to update the Spotlight';
        }
      } catch (err) {
        if (DEBUG_MODE >= 1) console.error('Error editing Spotlight', err);
        if (callbackOnComplete)
          callbackOnComplete(false, 'Could not update Spotlight');
        else if (DEBUG_MODE >= 2) console.log('No callback registered');
      }

      setSelectedTeamsToRecognize([]);
      setSelectedUsersToRecognize([]);
      setSelectedUsersAndTeamsToRecognize([]);
      setSpotlightToPost(SPOTLIGHT_INITIAL_STATE);
      setSendMessage('');
      setShowSpinner(false); //Hide spinners

      //Invoke the parent callback if there is one
      if (callbackOnComplete)
        callbackOnComplete(successFlag, completionMessage);
      else if (DEBUG_MODE >= 2) console.log('No callback registered to Row');
    }

    function handleMessageChange(value) {
      //Close GIFPHY Search, if open
      setShowSearchExperienceMessage(false);
      if (DEBUG_MODE >= 2) console.log('Key to add: ', value);
      setSendMessage(value);
    }

    function handleMessageFocus(value) {
      if (DEBUG_MODE >= 2) console.log('Focus on the message input bar');
      setShowSearchExperienceMessage(false);
      setShowPickerMessage(false);
    }

    // Giphy search
    const SearchComponents = React.memo(({ giphyGridWidth }) => {
      const { fetchGifs, searchKey } = useContext(SearchContext);
      var numColumns = 3;
      if (giphyGridWidth && giphyGridWidth > 450)
        numColumns = Math.floor(giphyGridWidth / 150);
      if (DEBUG_MODE >= 2)
        console.log(
          'SEND TOOL Search components called. GIPHY Grid width',
          giphyGridWidth,
          numColumns
        );
      return (
        <div
          className='conectereMessagingGiphySearchContainer'
          style={{ width: giphyGridWidth }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div style={{ padding: '5px 0px' }}>
            <SearchBar />
          </div>
          <SuggestionBar />

          <div className='conectereMessagingGiphyGridContainer'>
            {/** 
						key will recreate the component, 
						this is important for when you change fetchGifs 
						e.g. changing from search term dogs to cats or type gifs to stickers
						you want to restart the gifs from the beginning and changing a component's key does that 
					**/}
            <GiphyGrid
              key={searchKey}
              columns={numColumns}
              width={giphyGridWidth}
              fetchGifs={() => fetchGifs()}
              hideAttribution={true}
              onGifClick={(gif, e) => {
                if (DEBUG_MODE >= 2) console.log('gif', gif);
                e.preventDefault();
                setShowSearchExperienceMessage(false);
                setMessageGif(gif);
              }}
            />
          </div>
        </div>
      );
    });

    const onEmojiClick = (event, emojiObject) => {
      // if (DEBUG_MODE >= 2) console.log("Emoji Click Send Message invoked");

      setSendMessage((sendMessage) => sendMessage + emojiObject.emoji);
      setShowPickerMessage(false);
    };

    function clearGif() {
      setMessageGif(null);
    }

    function recognitionInputRegion({ giphyGridWidth }) {
      if (DEBUG_MODE >= 2)
        console.log('Spotlight Send Tool GIPHY width', giphyGridWidth);

      return (
        <div className='ContainerVerticalStart fullWidth conectereMessagingInputAreaWrapper'>
          <div className='conectereMessagingInputAreaInnerWrapper'>
            {messageGif && (
              <div className='conectereMessagingGiphyStagingAreaWrapper'>
                <div className='conectereMessagingGiphyStagingArea'>
                  <Gif
                    gif={messageGif}
                    width={200}
                    noLink={true}
                    hideAttribution={true}
                    style={{ padding: '5px' }}
                  />
                </div>
                <div
                  className='conectereMessagingGiphyClear'
                  onClick={clearGif}
                >
                  X
                </div>
              </div>
            )}

            <div className='alignStart ContainerNoHeightFlexLeft paddingInline positionRelative fullWidth maxHeight'>
              <div className='ContainerNoHeightCenter'>
                <div
                  className='avatarImage avatarImageMediumV2'
                  style={{
                    backgroundColor: setHeaderColorByStringLength(
                      currentUser.lastName
                    ),
                  }}
                >
                  {!currentUser.avatarUrl ? (
                    <div className='avatarInitialsSmall'>
                      {getUserInitials(currentUser)}
                    </div>
                  ) : (
                    <img
                      className='avatarImageCenterPortrait'
                      src={currentUser.avatarUrl}
                      alt={''}
                    />
                  )}
                </div>
              </div>
              <div className='fullWidth homeContainer3 inputContainer'>
                <input
                  ref={conectereMessagingInputDIVRef}
                  className='commentTextarea'
                  // style={{ height: messageInputBarHeight }}
                  value={sendMessage}
                  // onKeyDown={handleKeyDown}
                  onChange={(event) => handleMessageChange(event.target.value)}
                  onFocus={(event) => handleMessageFocus(event.target.value)}
                  placeholder='Recognition message'
                />
                <div className='ContainerNoHeightCenter commentInputAreaButtons'>
                  <EmojiButton
                    onClickCallback={() =>
                      setShowPickerMessage(!showPickerMessage)
                    }
                  />
                  {/* 10.4.2023 - AT THIS POINT A SPOTLIGHT DOES NOT STORE A GIPH IN THE ORIGINAL RECOGNITION MESSAGE JUST TEXT; TODO UPDATE SCHEMA TO STORE GIF LIKE INVITATIONS
                   */}
                  <GIFButtonV2
                    onClickCallback={() => {
                      if (DEBUG_MODE) console.log('GIF Button click');
                      setShowSearchExperienceMessage(
                        !showSearchExperienceMessage
                      );
                      setMessageGif(null);
                    }}
                  />
                  <button
                    className='buttonStyle1 castButton fullWidth'
                    onClick={
                      spotlightEditMode === 'EDIT'
                        ? (e) => handleEditSpotlight(e)
                        : (e) => handlePostSpotlight(e)
                    }
                    disabled={
                      !sendMessage ||
                      (selectedTeamsToRecognize.length === 0 &&
                        selectedUsersToRecognize.length === 0)
                    }
                  >
                    Cast
                  </button>
                </div>
              </div>
              {/* <textarea
                ref={conectereMessagingInputDIVRef}
                className='TextStyle4 conectereMessagingInputBar'
                style={{ height: messageInputBarHeight }}
                value={sendMessage}
                onChange={(event) => handleMessageChange(event.target.value)}
                onFocus={(event) => handleMessageFocus(event.target.value)}
                placeholder='Recognition message'
                // cols="25"
                rows='1'
              /> */}
            </div>
            {showPickerMessage && (
              <Picker
                pickerStyle={{
                  width: '35%',
                  minWidth: '250px',
                  zIndex: '100',
                  position: 'absolute',
                  right: '-15%',
                  top: '-350%',
                  fontSize: '12px',
                }}
                onEmojiClick={onEmojiClick}
              />
            )}
          </div>

          {showSearchExperienceMessage ? (
            <div>
              <SearchContextManager apiKey={GIPHY_SDK_KEY_WEB}>
                <SearchComponents giphyGridWidth={giphyGridWidth} />
              </SearchContextManager>
            </div>
          ) : null}
        </div>
      );
    }

    if (!backgroundColor) backgroundColor = 'white';

    // if (DEBUG_MODE >= 2) console.log("Send tool called");

    const handleSelectedUser = (e) => {
      setSelectedUsersToRecognize(e);
      var tempArray = [...e].concat(selectedTeamsToRecognize);
      setSelectedUsersAndTeamsToRecognize(tempArray);
      if (DEBUG_MODE >= 2)
        console.log('Send tool - user selected', e, tempArray);
    };

    const handleSelectedTeam = (e) => {
      setSelectedTeamsToRecognize(e);
      var tempArray = [...e].concat(selectedUsersToRecognize);
      setSelectedUsersAndTeamsToRecognize(tempArray);
    };

    //
    //AI Functions
    //

    useEffect(() => {
      if (DEBUG_MODE)
        console.log('AI prompt useEffect fired', promptMessageFromUser);

      const invokeAI = async () => {
        //Invoke our backend AI LLM Interface
        const response = await issuePromptToAiInterface({
          userID: currentUser.id,
          customerID: currentUser.customerID,
          prompt: promptMessageFromUser,
          history: aiInterfaceMessages,
          isForSpotlight: true,
          selectedUserOptions: selectedUsersToRecognize,
          selectedTeamOptions: selectedTeamsToRecognize,
          isCommunityServiceRelated: spotlightToPost.csRelated,
          isDEIRelated: spotlightToPost.deiRelated,
        });
        if (DEBUG_MODE) console.log('AI Interface Lambda returned', response);

        //Inject the message into our chat interface
        injectMessagesFromAI({
          aiInterfaceMessages,
          setAiInterfaceMessages,
          response,
          replaceEntireHistoryFlag: true,
        });
      };
      invokeAI();
    }, [promptTrigger]);

    async function handleAIPromptFromUser({ message, newChatMessage }) {
      if (
        !newChatMessage ||
        newChatMessage.length === 0 ||
        !message ||
        message.length === 0
      ) {
        if (DEBUG_MODE)
          console.error('Error - improper params in the Callback', message);
        return;
      }
      //Add the user's prompt to our messages
      const tempNewMessages = [...aiInterfaceMessages];
      tempNewMessages.push({ ...newChatMessage, messageType: 'PROMPT' }); //Label as a PROMPT to distinquish from other message types in our chat history
      setAiInterfaceMessages(tempNewMessages);

      //Store the message to trigger our useEffect
      if (DEBUG_MODE) console.log('Processing AI prompt via Callback', message);
      setPromptMessageFromUser(message);
      setPromptTrigger(!promptTrigger); //Use a seperate trigger in case the user repeats the prompt
    }

    const messageSelectCallback = (message) => {
      if (DEBUG_MODE) console.log('Message Select Callback Invoked', message);
      if (!message || !message.message) return;
      handleMessageChange(message.message);
    };

    return (
      <div
        className='sendSpotlightTool ContainerNoHeightSpaceAround alignStretch boxShadow borderRadius-V2 isCard'
        ref={containerRef}
      >
        <div className='sendSpotlightPortionWrapper fullWidth overflow-hidden borderRadius-V2'>
          <div className='sendSpotlightPortion boxShadow borderRadius-V2'>
            <div className='ContainerVerticalStart sendSpotlightCenterColumn'>
              <div className='ContainerVerticalStart spotlightSendToolInnerWrapper'>
                <div className='fullWidth ContainerVertical position-relative userRecognizeOuterWrapper'>
                  <Torch />
                  <Torch opposite />
                  <h4 className='black-text'>Spotlight</h4>
                  <div
                    className='spotlightRecognizedUsersAvatarsWrapper userRecognizeInnerWrapper position-relative'
                    style={{ minHeight: '64px' }}
                  >
                    {displayRecognizedUsersAvatars({
                      recognizedEmployees: selectedUsersAndTeamsToRecognize,
                      maxUsersToShow: 7,
                      isSmall: false,
                      isFull: true,
                      showQuestionMarkIfNone: true,
                    })}
                  </div>
                </div>
                {/* <SpotlightToolBanner
                  selectedUsersAndTeamsToRecognize={
                    selectedUsersAndTeamsToRecognize
                  }
                /> */}
                <hr className='fullWidth light-grey-border opacity1' />
                <div
                  className='ContainerVerticalLeft spotlightSendToolInnerColumn'
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPickerMessage(false);
                  }}
                  ref={spotlightSendToolGiphyGridContainerDivRef}
                >
                  <div
                    className='ContainerVerticalStartStart fullWidth'
                    style={{ zIndex: '90' }}
                  >
                    <div className='ContainerNoHeightCenter'>
                      <div className='TextStyle7V2 black-text'>
                        Who would you like to spotlight?
                      </div>
                      <HelpButton
                        hoverTextClassName='TextStyle4 grey hoverTextStyle1 hoverTextStyle1ExtraWide'
                        hoverText={HELP_TEXT_SPOTLIGHT_1}
                        className='light-grey'
                        noTransform={true}
                      />
                    </div>

                    <div
                      className='ContainerVerticalCenter fullWidth'
                      style={{ minWidth: '90%' }}
                    >
                      <DisplayUserSelectComponentV2
                        zIndex='85'
                        name={'userDropdown'}
                        usersOptions={participatingUsersOptions}
                        handleSelectUser={handleSelectedUser}
                        isMulti={true}
                        selectedUsersOptions={selectedUsersToRecognize}
                        padding='5px 0px'
                      />
                      {spotlightEditMode === 'EDIT' ? null : (
                        <DisplayTeamSelectComponentV2
                          zIndex='84'
                          name='teamDropdown'
                          teamsOptions={teamsOptions}
                          handleSelectTeam={handleSelectedTeam}
                          isMulti={true}
                          selectedTeamsOptions={selectedTeamsToRecognize}
                          padding='5px 0px'
                        />
                      )}
                    </div>
                  </div>
                </div>

                <hr className='fullWidth light-grey-border opacity1' />
                <div
                  className='ContainerVerticalLeft spotlightSendToolInnerColumn fullWidth'
                  style={{ zIndex: '80' }}
                >
                  <div className='ContainerNoHeightCenter'>
                    <div className='TextStyle7V2 black-text'>Awards</div>
                    <HelpButton
                      hoverTextClassName='TextStyle4 grey hoverTextStyle1 hoverTextStyle1ExtraWide'
                      hoverText={HELP_TEXT_SPOTLIGHT_AWARDS_1}
                      className='light-grey'
                      noTransform={true}
                    />
                  </div>
                  <div className='ContainerVerticalLeft wrap awardToolSection fullWidth'>
                    {!isAdmin ? (
                      <div className='ContainerNoHeightCenter'>
                        <div
                          className='spotlightTextStyle3'
                          style={{ textAlign: 'right', minWidth: '60px' }}
                        >
                          {spotlightToPost.amount}{' '}
                        </div>
                        <img
                          className='coinImg'
                          src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}
                          alt=''
                        />
                      </div>
                    ) : (
                      <div
                        className='ContainerNoHeightFlexEnd fullWidth'
                        style={{ gap: 10 }}
                      >
                        <div
                          className='ContainerNoHeightCenter fullWidth'
                          style={{
                            // width: '200px',
                            marginTop: 'clamp(10px,5vh,30px)',
                          }}
                        >
                          <div
                            className='ContainerNoHeightCenter TextStyle3V2 black-text bold'
                            style={{ paddingRight: 'clamp(4px,1vw,20px)' }}
                          >
                            {SPOTLIGHT_AMOUNT_RECEIVER_MIN}&nbsp;
                            <img
                              className='coinImg'
                              src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}
                              alt=''
                            />
                          </div>
                          <Slider
                            defaultValue={SPOTLIGHT_AMOUNT_SENDER}
                            step={SPOTLIGHT_SLIDER_STEP}
                            marks={false}
                            min={SPOTLIGHT_AMOUNT_RECEIVER_MIN}
                            max={SPOTLIGHT_AMOUNT_RECEIVER_MAX}
                            name='clusterSlider'
                            color='info'
                            classes={{
                              root: 'spotlightSlider',
                              valueLabel: 'sliderValueLabel',
                            }}
                            onChange={(event, data) =>
                              setSpotlightToPost({
                                ...spotlightToPost,
                                amount: data,
                              })
                            }
                            disabled={!isAdmin}
                            value={spotlightToPost.amount}
                            valueLabelDisplay='on'
                            valueLabelFormat={(value) => (
                              <div className='ContainerNoHeightCenter'>
                                {value}&nbsp;
                                <img
                                  className='coinImg'
                                  src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}
                                  alt=''
                                />
                                <div style={{ paddingLeft: '2px' }}>
                                  (${(value / 10).toFixed(2)})
                                </div>
                              </div>
                            )}
                          />
                          <div
                            className='ContainerNoHeightCenter TextStyle3V2 black-text bold'
                            style={{ paddingLeft: 'clamp(4px,1vw,20px)' }}
                          >
                            {SPOTLIGHT_AMOUNT_RECEIVER_MAX}&nbsp;
                            <img
                              className='coinImg'
                              src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className='ContainerNoHeightCenter awardValueContainer'>
                          <span className='TextStyle3V2 light-grey bold'>
                            $
                          </span>
                          &nbsp;&nbsp;
                          <span className='TextStyle3V2 black-text bold'>
                            {(spotlightToPost.amount / 10).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className='ContainerNoHeightCenter maxWidth'>
                      <div className='ContainerNoHeightCenter'>
                        <Checkbox
                          name='deiRelated'
                          checked={spotlightToPost.deiRelated}
                          onChange={(event) =>
                            setSpotlightToPost({
                              ...spotlightToPost,
                              deiRelated: !spotlightToPost.deiRelated,
                              badgesDEI: spotlightToPost.deiRelated ? 0 : 1,
                            })
                          }
                          style={{ padding: 0 }}
                          sx={{
                            '&.Mui-checked': {
                              color: 'var(--gloomy-purple)',
                            },
                          }}
                        />
                        <div className='TextStyle4V2 black-text'>DEI BADGE</div>
                        &nbsp;
                        <img
                          className='ccImgMedium'
                          src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}
                          alt=''
                        />
                      </div>
                      <div
                        className='ContainerNoHeightCenter'
                        style={{ marginLeft: 10 }}
                      >
                        <Checkbox
                          name='csRelated'
                          checked={spotlightToPost.csRelated}
                          onChange={(event) =>
                            setSpotlightToPost({
                              ...spotlightToPost,
                              csRelated: !spotlightToPost.csRelated,
                              badgesCS: spotlightToPost.csRelated ? 0 : 1,
                            })
                          }
                          style={{ padding: 0 }}
                          sx={{
                            '&.Mui-checked': {
                              color: 'var(--gloomy-purple)',
                            },
                          }}
                        />
                        <div className='TextStyle4V2 black-text'>
                          SERVICE BADGE
                        </div>
                        &nbsp;
                        <img
                          className='ccImgMedium'
                          src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className='ContainerVerticalCenter fullWidth positionRelative'
                  style={{
                    paddingTop: 'clamp(4px,0.8vw,8px)',
                    zIndex: showPickerMessage ? '100' : '70',
                  }}
                >
                  {recognitionInputRegion({
                    giphyGridWidth: spotlightSendToolGiphyGridContainerDivSize,
                  })}
                </div>

                {/* <div
                  className='ContainerNoHeightFlexEndCentered'
                  style={{ width: '100%', padding: '10px, 0px' }}
                >
                  <button
                    className='buttonStyle3'
                    aria-label='done'
                    onClick={
                      spotlightEditMode === 'EDIT'
                        ? (e) => handleEditSpotlight(e)
                        : (e) => handlePostSpotlight(e)
                    }
                    disabled={
                      !sendMessage ||
                      (selectedTeamsToRecognize.length === 0 &&
                        selectedUsersToRecognize.length === 0)
                    }
                  >
                    <div className='ContainerNoHeightCenter'>
                      <div style={{ paddingRight: 'clamp(4px,0.5vw,8px)' }}>
                        {spotlightEditMode == 'EDIT' ? 'UPDATE ' : 'CAST '}{' '}
                      </div>
                      <SendIcon
                        className='TextStyle5'
                        style={{ color: 'white' }}
                      />
                    </div>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {!AIinterfaceEnabled ? null : (
          <div className='chatPortion'>
            <ChatWindow
              messagesToShow={aiInterfaceMessages}
              currentUser={currentUser}
              disableReplies={true}
              disableGif={true}
              noFixedHeight={true}
              placeholderMessage={
                aiInterfaceMessages && aiInterfaceMessages.length > 2
                  ? 'Want some help?  Enter a promp for Iggy'
                  : 'Enter a prompt for Iggy'
              }
              sendMessageCallbackOverride={handleAIPromptFromUser}
              messageSelectCallback={messageSelectCallback}
              messageSelectLabel='SELECT'
              isAIChat={true}
            />
          </div>
        )}
      </div>
    );
  }
);

/*

		ADMIN ORDERS

		PROPRIETARY AND CONFIDENTIAL

		PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST

		copyright CONECTERE

*/
//Admin page by which superAdmin manages the orders available in the system

//Data
import  { CONECTERE_CONFIG_DATA, PRODUCT_INITIAL_STATE, ORDER_INITIAL_STATE, ORDER_STATS_INITIAL_STATE, DEBUG_MODE, COLOR_BLUE_TEXT, TEAM_COLOR, COLOR_ALERT } from '../../shared/data/conectereConfigData';
import { USER_COLUMN, AMOUNT_COLUMN } from '../../shared/data/gridColumns';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries and Mutations
import { getUser } from '../../shared/graphql/queries';
import { updateOrder} from '../../shared/graphql/mutations';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";

//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

//Our components 
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import Product from "../../shared/Components/product/product";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { OrdersContext } from '../../shared/context/ordersContext';                //Orders context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { formatDate, HelpButton } from "../../shared/utils/generalUtils";
import { invokeAPI, invokeDeleteOrder } from "../../shared/utils/databaseUtils";
import { setOrderDateBackground }  from "../../shared/utils/productUtils";
import { getUserName, getUserInitials } from "../../shared/utils/userAndTeamUtils";
import { getCustomerName } from "../../shared/utils/customerUtils";


import moment from 'moment';

//Here's the start of our Javascrpt App
const AdminOrdersPage = () => {
  
	//Context
	const { authState, currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);   // Authentication context
	const { users, customers } = useContext(CustomerContext);  // Customer context
	const { ordersFiltered, orderStartDateTime, setOrderStartDateTime, orderEndDateTime, setOrderEndDateTime, orderCache, setOrderCache } = useContext(OrdersContext);     // Orders context
   const { setShowSpinner } = useContext(DisplayContext); 
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 


   //Local state variables
	const [showModalOrder, setShowModalOrder] = useState(false);
	const [productForOrder, setProductForOrder] = useState(PRODUCT_INITIAL_STATE);    //Used to hold the product for the order being processed
	const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

	//Order processing
	const [orderMessage, setOrderMessage] = useState("");
	const [isViewingOrder, setIsViewingOrder] = useState(false);              //Used as a state variable set to TRUE when updating the backend
	const [orderToProcess, setOrderToProcess] = useState(ORDER_INITIAL_STATE);      //Used to hold a conectivity that is to be 
	const orderMessageInputRef = useRef();
	const [messageInputBarHeight, setMessageInputBarHeight] = useState("32px");
	const [userThatPlacedOrder, setUserThatPlacedOrder] = useState('');
	
	const [orderRows,setOrderRows]=useState([]);                                //Formatted rows for user grid    
	
	//state variables for managing orders for the current user
	//these will all be available to the the wrapped components
	const [ordersStats, setOrdersStats] = useState(ORDER_STATS_INITIAL_STATE);

	// RESPOND TO ANY CHANGES TO THE SET OF FILTERED ORDERS; UPDATE STATS & REBUILD ROWS
	useEffect(() => {
		updatePageData();
	}, [ordersFiltered]);  


	async function updatePageData() {
		if (authState !== "signedin" || !ordersFiltered) return;
		if (DEBUG_MODE >= 2) console.log("Building orders page data", ordersFiltered);    
		if (isSuperAdmin) setShowSpinner(true); //Show spinners if SuperAdmin since will be making DB calls           
		var tempOrdersStats = {...ORDER_STATS_INITIAL_STATE}; //Make a new object
	 
	   //Walk orders and compute status; build rows for Data Grid
		var tempOrderRows = [];
		
		for(var i = 0; i<ordersFiltered.length; i++){
			
			//Update stats
			tempOrdersStats.orders +=  1;
			tempOrdersStats.coinsSpent +=  ordersFiltered[i].amount;
			if (ordersFiltered[i].badgesDEI) tempOrdersStats.badgesDEISpent +=  ordersFiltered[i].badgesDEI;
			if (ordersFiltered[i].badgesCS) tempOrdersStats.badgesCSSpent +=  ordersFiltered[i].badgesCS;
	   
			if (ordersFiltered[i].orderStatus === "OPEN")  tempOrdersStats.openOrders +=  1;
			else if (ordersFiltered[i].orderStatus === "FILLED" || ordersFiltered[i].orderStatus === "INVOICED")  tempOrdersStats.filledOrders +=  1;
			else if (ordersFiltered[i].orderStatus === "CANCELED")  tempOrdersStats.caneledOrders +=  1;
			else if (ordersFiltered[i].orderStatus === "FILLED_BY_CUSTOMER")  tempOrdersStats.filledByCustomerOrders +=  1;


			//Set the customer and user data from context so we can enforce access control, rather than pull via DB graphQL connections
			const tempCustomer = customers.find(customer => customer.id === ordersFiltered[i].customerID);   //All clients will have a customer array, even if only 1 entry
			let tempUser = users.find(user => user.id === ordersFiltered[i].userID);                       //All clients will have a certain scope of valid users

			if (tempCustomer && tempUser) {
				//Build Row
				tempOrderRows[i] = {
					id:i+1,
					dynamoDBID: ordersFiltered[i].id, 

					//User Fields
					userName:getUserName(tempUser),
					avatarUrl:tempUser.avatarUrl,
					initials:getUserInitials(tempUser),
					email:tempUser.email,

					//Product fields
					title:(ordersFiltered[i].product == null ? "" : ordersFiltered[i].product.title),
					image:(ordersFiltered[i].product == null ? "" : ordersFiltered[i].product.image),
					dollarCost:ordersFiltered[i].dollarCost,
					badgesDEI:ordersFiltered[i].badgesDEI,
					badgesCS:ordersFiltered[i].badgesCS,
					amount:ordersFiltered[i].amount,

					//Order fields
					dateProcessed:ordersFiltered[i].dateProcessed,
					dateOrdered:ordersFiltered[i].createdAt,
					orderStatus:ordersFiltered[i].orderStatus,
					recipient:{...tempUser},    //Cache the entire recipient in case we need address info etc. later

					//customer fields
					customerName: (!tempCustomer ? "" : tempCustomer.name)
				};
			} //End IF customer and User found
			else {
				if (DEBUG_MODE) console.log("Error - could not find customer or user");
			}
		} //END For Each Order

		if (DEBUG_MODE >= 2) console.log("Built Order Rows:", tempOrderRows);
		if (DEBUG_MODE >= 2) console.log("Built Order Stats:", tempOrdersStats);
		setOrdersStats(tempOrdersStats);
		setOrderRows(tempOrderRows);
		setShowSpinner(false); //Hide spinners
	 }

	//Product Grid Format
	const orderColumns = [
	{
		field: 'orderStatus',
		headerName: 'STATUS',
		headerAlign: 'center',
		align:'center',
		minWidth: 75,
		flex:0.5,
		editable: false,
		sortable: true,
		renderCell: (params)=> {
			return (
				<div className="TextStyle3"> {params.row.orderStatus==="FILLED_BY_CUSTOMER" ? "FILLED" : params.row.orderStatus}</div>
			);
		 }
	  },
	  {
		field: 'dateOrdered',
		headerName: 'ORDER DATE',
		headerAlign: 'center',
		align:'center',
		minWidth: 125,
		flex:0.5,
		editable: false,
		renderCell: (params)=> {
		return (
			<div className="ContainerNoHeightCenter" 
				style={{ 
						height:"100%", width:"100%",
						color:"white", 
						backgroundColor: setOrderDateBackground({date: params.row.dateOrdered, status:params.row.orderStatus})}}>
				{params.row.dateOrdered ? formatDate(params.row.dateOrdered) : ""}
			</div>
			);
		  },
	  },
	  USER_COLUMN,
	  {
		field: 'title',
		headerName: 'PRODUCT',
		headerAlign: 'center',
		align:'left',
		minWidth: 200,
		flex:2,
		editable: false,
		sortable: true,
		renderCell: (params)=> {
			return (
				<div className="ContainerNoHeightCenter">
					<div> {params.row.image == null ? "" : <img className="ccImgXXL" src={params.row.image} alt='product' />} </div>
					<div  className=" ContainerNoHeightCenter TextStyle2" style={{paddingLeft:"clamp(4px,1vw,10px)", whiteSpace: "normal", wordBreak: "break-word" }}>
						{params.row.title}
					</div>
				</div>
				);
		  },
	  },
	  
	  {
		field: 'dollarCost',
		headerName: '$$ COST',
		headerAlign: 'center',
		align:'center',
		minWidth: 100,
		flex:0.5,
		editable: false,
		sortable: true,
		renderCell: (params)=> {
			return (
				<div className="ContainerNoHeightCenter TextStyle3"> ${params.row.dollarCost} </div>
				);
		  },
	  },
	  AMOUNT_COLUMN,
	  {
		field: 'action',
		headerName: ' ',
		headerAlign: 'center',
		align:'center',
		width: 150,
		color:'white',
		sortable: false,
		renderCell: (params)=> {
			return (
				<div className="ContainerMaxHeightSpaceBetween">
					<div className="menuDataGridListButton">
							<button id='submit' className="buttonStyle1 buttonStyle1HeaderBlue" onClick={!isViewingOrder ? ()=> handleViewOrder(ordersFiltered[params.row.id-1]) : null} disabled={isViewingOrder}> 
								{params.row.orderStatus==="OPEN" ? "Process" : "View"}
							</button>
					</div>
				</div>
				);
			}
		},
		
	];
 

	//Process Order Functions
	 async function handleFillOrder () {
		if (DEBUG_MODE >= 2) console.log("Filling Order");
		setShowSpinner(true); //Show spinners
		try {   
			
			//Get the current time and convert to AWS format
			const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ssZ");

			//create record for update
			let tempOrderToFill = {            
				id: orderToProcess.id,
				dateProcessed: currentDateTime,
				dollarCost: orderToProcess.dollarCost ? parseFloat(orderToProcess.dollarCost) : 0,
				tax: orderToProcess.tax ? parseFloat(orderToProcess.tax) : 0,
				shipping: orderToProcess.shipping ? parseFloat(orderToProcess.shipping) : 0,
				total:(Math.round(100*(parseFloat(orderToProcess.dollarCost ? orderToProcess.dollarCost : 0) + parseFloat(orderToProcess.tax ? orderToProcess.tax : 0) + parseFloat(orderToProcess.shipping ? orderToProcess.shipping : 0)))/100).toFixed(2),

				//Significant difference from Manage Orders Page - mark order as filled via the Customer so it will not be billed
				orderStatus: "FILLED_BY_CUSTOMER",
			};

			//Add optional fields
			if (orderMessage) tempOrderToFill.memo = orderMessage;            

			//Update the order record - NOTE - OUR LAMBDA IS TRIGGERED BY CRUD OPERATION ON THE ORDER TABLE TO AUTO SEND EMAILS
			const tempUpdatedOrder = await invokeAPI(updateOrder, 'updateOrder', tempOrderToFill);
			if (DEBUG_MODE >= 2) console.log('Marked order as filled', tempUpdatedOrder);


			//Update the cached data on the page
			const tempOrders= [...orderCache];   //get our state datea
			var indexToEdit = tempOrders.findIndex(order => order.id === tempUpdatedOrder.id);
				
			//Edit the item in our local data using the FULL object returned from the backed on the update
			if (indexToEdit > -1) {
				if (DEBUG_MODE >= 2) console.log('Found order processed at index:', indexToEdit);
				tempOrders[indexToEdit].orderStatus = "FILLED";
				tempOrders[indexToEdit].dateProcessed = currentDateTime;
				setOrderCache(tempOrders);
			}
			else if (DEBUG_MODE >= 2) console.log('Did not find a matching local order to update');

			if (DEBUG_MODE >= 2) console.log('Successfully processed order to FILLED', tempUpdatedOrder);
 
			setModalGeneralMessage("Order processed.  An email record has been sent to the recipient");
			setModalGeneralTitle("Success!");
			setShowModalGeneral(true);   
			setOrderToProcess(ORDER_INITIAL_STATE);           
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error updating order:', err);
			setModalGeneralMessage("Something went wrong. " + err);
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
		}
		
		setShowSpinner(false); //Hode spinners
		setShowModalOrder(false);   //hide the pop-up
		setIsViewingOrder(false);   //enable buttons on main page 
	}


	async function handleConfirmDeleteOrder () {
		//Safety check
		if (!orderToProcess || !orderToProcess.id) {
			if (DEBUG_MODE) console.error("Error - no order to delete", orderToProcess);
		}
		if (DEBUG_MODE >= 2) console.log("Deleting Order");
		
		setShowSpinner(true); //Show spinners	
		try {   

			const result = await invokeDeleteOrder(orderToProcess.id);
			if (result && result.successFlag && result.order) {

				//TODO - move all of this to the cloud
				//Add transaction and credit back the user's wallet
				
            //Remove the matching order from local state;  this will trigger our useEffect to rebuild rows
            var tempOrders = orderCache.filter(con => con.id !== orderToProcess.id);
            setOrderCache(tempOrders);
				if (DEBUG_MODE >= 2) console.log('Successfully deleted order', orderToProcess);
            if (DEBUG_MODE >= 2) console.log('Updated local state after delete', tempOrders);
				setModalGeneralMessage("Order deleted");
				setModalGeneralTitle("Success!");
			} else {
				if (DEBUG_MODE >= 2) console.log('Could not delete order', result, orderToProcess);
			   setModalGeneralMessage("Could not delete order");
			   setModalGeneralTitle("Ooops!");
			}			
			
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error updating order:', err);
			setModalGeneralMessage("Something went wrong. " + err);
			setModalGeneralTitle("Ooops!");
		}
		
		setShowModalGeneral(true);   
		setShowModalConfirmDelete(false);
		setShowSpinner(false); //Hode spinners
		setShowModalOrder(false);   //hide the pop-up
		setIsViewingOrder(false);   //enable buttons on main page
		
	}

	const handleCloseDeleteConfirmation = () => {
		setShowModalConfirmDelete(false)
	}

	const handleDeleteOrder = () => {
		setShowModalConfirmDelete(true)
	}

	const handleCancelViewOrder = () => {
		setShowModalOrder(false);
		setOrderToProcess(ORDER_INITIAL_STATE);
		setProductForOrder(PRODUCT_INITIAL_STATE);
		setIsViewingOrder(false); //enable buttons on main page
		if (DEBUG_MODE >= 2) console.log("Cancelled Product View");
	};
		
	//This function handles the order buttons
	//Pop-up a modal and ask product to confirm. Handle confirmation
	const handleViewOrder = (order) => {      
		if (DEBUG_MODE >= 2) console.log("Order button pressed.  Order Product=", order);
		setIsViewingOrder(true); //disable buttons on main page
		setOrderToProcess({...order});    //Store the passed in object into state data variable to record object being edit
		setProductForOrder({...order.product});    //Store the passed in object into state data variable to record object being edit
		setUserThatPlacedOrder(getUserName(users.find(user=>user.id === order.userID)));
		setShowModalOrder(true);    //pop-up Modal 
		setOrderMessage("");
	};
   

	 function handleOrderMessageChange(value) {
		 
		//Dynamically adjust height of our input region as the user types
		if (orderMessageInputRef && orderMessageInputRef.current) {
			var newHeight = (value.length < 20 ? "32px" : orderMessageInputRef.current.scrollHeight.toString() + "px" );
			setMessageInputBarHeight(newHeight);
			// if (DEBUG_MODE >= 2) console.log("Gift Message input bar change.  Amount to scroll to bottom", orderMessageInputRef.current.scrollHeight, newHeight);
		}             
		setOrderMessage(value);
	}    
	

	//Handle access by unauthenticated user
	if (authState !== "signedin"  || !currentUser) {
	  if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState == "signedout") popUpLoginWindow();
	  return null;
	}
 
 
   function displayAmountInput() {
		if (orderToProcess.orderStatus==="FILLED" || orderToProcess.orderStatus==="FILLED_BY_CUSTOMER") 
			return ( 
				<div className='ContainerVertical TextStyle4'>
					<div>Actual Cost</div> 
					<div> ${(orderToProcess.dollarCost).toFixed(2)}</div> 
				</div>
			);
		else 
			return (
				<div className="ContainerVerticalStart TextStyle4" >
					<div className="ContainerNoHeightCenter">
						<span>Actual Cost</span> 
						<HelpButton hoverText="Enter the actual cost paid to purchase the item as the cost may be different from the amount listed in the employee store"  /> 
					</div>
					<div className="ContainerNoHeightCenter black alignEnd" >
						$<input
							style={{border: (!orderToProcess.dollarCost && orderToProcess.orderStatus!=="FILLED" ? "1px solid red" : "1px solid grey"), width:'5em'}}
							name="amountPaid"
							type="number"
							value={orderToProcess.dollarCost || orderToProcess.dollarCost === 0? orderToProcess.dollarCost : ''}
							onChange={event => setOrderToProcess({...orderToProcess, dollarCost:event.target.value})}
							required
							disabled={orderToProcess.orderStatus==="FILLED"}
						/>
					</div>        
				</div>
			)
   }

   function displayTaxesInput() {
		if (orderToProcess.orderStatus==="FILLED" || orderToProcess.orderStatus==="FILLED_BY_CUSTOMER") 
			return ( 
				<div className='ContainerVertical TextStyle4'>
					<div>Tax</div> 
					<div> ${(orderToProcess.tax).toFixed(2)}</div> 
				</div>
			);
		else 
			return (
			<div className="ContainerVertical TextStyle4" >
				<div className="ContainerNoHeightCenter">
					<span>Tax</span> 
				</div>
				<div className="ContainerNoHeightCenter black" >
					$<input
						style={{border: (orderToProcess.tax === undefined && orderToProcess.orderStatus!=="FILLED"  ? "1px solid red" : "1px solid grey"), width:'5rem'}}
						name="taxesPaid"
						type="number"
						value={orderToProcess.tax || orderToProcess.tax === 0 ? orderToProcess.tax : ''}
						onChange={event => setOrderToProcess({...orderToProcess, tax:event.target.value})}
						required
						disabled={orderToProcess.orderStatus==="FILLED"}
					/>
				</div>        
			</div>
		)
   }
   function displayShippingInput() {
		if (orderToProcess.orderStatus==="FILLED" || orderToProcess.orderStatus==="FILLED_BY_CUSTOMER") 
			return ( 
				<div className='ContainerVertical TextStyle4'>
					<div>Shipping</div> 
					<div> ${(orderToProcess.shipping).toFixed(2)}</div> 
				</div>
			);
		else 
			return (
			<div className="ContainerVertical TextStyle4" >
				<div className="ContainerNoHeightCenter">
					<span>Shipping</span> 
				</div>
				<div className="ContainerNoHeightCenter black" >
					$<input
						style={{border: (orderToProcess.shipping === undefined && orderToProcess.orderStatus!=="FILLED" ? "1px solid red" : "1px solid grey"), width:'5em'}}
						name="shippingPaid"
						type="number"
						value={orderToProcess.shipping || orderToProcess.shipping===0 ? orderToProcess.shipping : ''}
						onChange={event => setOrderToProcess({...orderToProcess, shipping:event.target.value})}
						required
						disabled={orderToProcess.orderStatus==="FILLED"}
					/>
				</div>        
			</div>
		)
   }  
   function displayTotal() {
	  return (
		 <div className="ContainerVertical TextStyle4" >
			<div className="ContainerNoHeightCenter">
			   <span>Total</span> 
			</div>
			<div className="ContainerNoHeightCenter TextStyle4 bold" >
			   ${(Math.round(100*(parseFloat(orderToProcess.dollarCost ? orderToProcess.dollarCost : 0) + parseFloat(orderToProcess.tax ? orderToProcess.tax : 0) + parseFloat(orderToProcess.shipping ? orderToProcess.shipping : 0)))/100).toFixed(2)}
			</div>        
		 </div>
	  )
   }     
   //Render function for authenticated user 
   if (!isSuperAdmin && !isAdmin) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
  } else 

  //Define HTML to return in order to create page
  return (
	<Authenticator>      
		
	  <ModalNoBackground showModal={showModalOrder} closeCallback={handleCancelViewOrder} cardColor={TEAM_COLOR}>
		 <div className="modalNoBkgInnerCard " >
			<div className="modalNoBkgCenterColumn" style={{minHeight:"350px", padding:"clamp(8px,2vw,20px)", borderRadius:'0px'}}>
			   <h3 className="" >
					 {!orderToProcess.dateProcessed ? "PROCESS ORDER" : "ORDER FILLED"}                            
			   </h3>  

			   <div className="ContainerNoHeightSpaceAround fullWidth wrap" style={{margin:"clamp(4px,1vw,10px)"}}>
					 <div className="ContainerNoHeightCenter" >
						<div className="modalNoBkgText3 " style={{paddingRight:"10px"}}>Order Date:</div>
						<div className="modalNoBkgText3"> {formatDate(orderToProcess.createdAt)}</div>
					 </div>
					 
					 <div className="ContainerNoHeightCenter">
						<div className="modalNoBkgText3 " style={{paddingRight:"10px"}}>Ordered By:</div>
						{!orderToProcess.userID ? "" : 
						<div className="modalNoBkgText3"> 
						   {userThatPlacedOrder ? userThatPlacedOrder +', ' : null} 
						   &nbsp;{getCustomerName(customers.find(customer=>customer.id === orderToProcess.customerID))}
						</div> }                                
					 </div>
				</div>

				{!orderToProcess.dateProcessed ? "" :
					<div className="ContainerNoHeightSpaceAround fullWidth wrap" style={{margin:"clamp(4px,1vw,10px)"}}>
							<div className="ContainerNoHeightCenter" >
								<div className="modalNoBkgText3 " style={{paddingRight:"10px"}} > Filled by: </div>
								<div className="modalNoBkgText3"> {orderToProcess.orderStatus==='FILLED_BY_CUSTOMER' ? currentUser.customer.name : 'Conectere'}</div>
							</div>
							<div className="ContainerNoHeightCenter" >
								<div className="modalNoBkgText3 " style={{paddingRight:"10px"}} > Date Filled: </div>
								<div className="modalNoBkgText3"> {formatDate(orderToProcess.dateProcessed)}</div>
							</div>
					</div>
				}

			   <Product product={productForOrder} isDetailedView={true}  />         

			</div>

			<div className="ContainerVerticalCenter  fullWidth  borderRadius" style={{ maxWidth:'80%'}} >
			   <div className="ContainerNoHeightSpaceAround inputFormRowWrapper alignEnd " style={{padding:'10px'}} >
				  {displayAmountInput()}
				  {displayTaxesInput()}
				  {displayShippingInput()}
				  {displayTotal()}
			   </div>     

			   <div className = "conectereMessagingInputArea" style={{padding:'20px'}}>
				  {!orderToProcess.dateProcessed ? 
					 <textarea
						   ref={orderMessageInputRef}
						   className="TextStyle3 conectereMessagingInputBar"
						   style={{height:messageInputBarHeight}}
						   value={orderMessage}
						   onChange={event => handleOrderMessageChange(event.target.value)}
						   placeholder="Add a message for the order receipt"
						   rows="1"
					 />
				  :
						<div className='ContainerNoHeightCenter' >
					 		<div className="TextStyle4 " style={{paddingRight:"10px"}} >Memo:</div>
					 		<div className="TextStyle3 very-light-grey-background borderRadius black" style={{padding:"clamp(4px,1vw,10px)"}}>{orderToProcess.memo}</div>
						</div>
					}
			   </div>                            
			</div> 
						   
			<div className="modalNoBkgFooter " >
			   {orderToProcess.orderStatus==="OPEN" ?
				  <button className="buttonStyle1 buttonStyle1HeaderBlue" onClick={handleFillOrder}><div > MARK ORDER FILLED </div></button> :
			   ""}
				<button className="buttonStyle1 buttonStyle1Red"  onClick={handleDeleteOrder}>
				  Delete
			   </button>				  
			   <button className="buttonStyle1 buttonStyle1DavyGray"  onClick={handleCancelViewOrder}>
				  {orderToProcess.orderStatus==="OPEN" ? "Cancel" : "Close"}
			   </button>
			</div>
		 </div>
	  </ModalNoBackground>

	  <ModalNoBackground showModal={showModalConfirmDelete} closeCallback={handleCloseDeleteConfirmation} cardColor={TEAM_COLOR}>
		 <div className="modalNoBkgInnerCard black-background" >
			<div className="modalNoBkgCenterColumn" style={{ padding:"20px", borderRadius:'0px'}}>
				<h3> Delete order?  Are you sure?    </h3>  
				<div className='ContainerNoHeightSpaceAround' style={{ paddingTop:"20px"}}>
					<button className="buttonStyle1 buttonStyle1Red"  onClick={handleConfirmDeleteOrder}> Delete </button>
					<button className="buttonStyle1 buttonStyle1DavyGray"  onClick={handleCloseDeleteConfirmation}> {orderToProcess.orderStatus==="OPEN" ? "Cancel" : "Close"} </button>
				</div>
			</div>
		 </div>
	  </ModalNoBackground>

	  <div className="adminPage">
			  <div className="ContainerNoHeightSpaceAround fullWidth maxWidthLarge">             
				<div className="ContainerVerticalLeft" style={{height:"100%", padding:"5px", zIndex:"99"}}>
					<div style={{color:COLOR_BLUE_TEXT, fontSize:"1.0rem", fontWeight:"500", paddingLeft:"clamp(4px,1vw,10px)"}}>Date Range</div>      
					<div className="ContainerNoHeightCenter" style={{minHeight:"5.0rem", borderRadius:"10px", padding:"10px", flexWrap:"wrap", maxWidth:"30.0rem", backgroundColor:"#f7f7f7"}}>                  
						<div className="ContainerNoHeightFlexLeft" style={{zIndex:"99", alignItems:"flex-start", position:"relative", maxWidth:"24.0rem"}}>
							 <div style={{padding:"10px 10px", borderRadius:"10px", backgroundColor:"white"}}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									  <DatePicker
										// minDate={NOW()}
										// maxdate={SIX_MONTHS_FROM_NOW()}
										disabled={false}
										label="From"
										value={orderStartDateTime}
										onChange={(newDateValue) => {
										  setOrderStartDateTime(newDateValue);
										  if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
										}}
										// renderInput={(params) => <TextField size="small" {...params} />}
									  />
								</LocalizationProvider>
							</div>

							 <div style={{padding:"10px 10px", backgroundColor:"white"}}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									  <DatePicker
										// minDate={NOW()}
										// maxdate={SIX_MONTHS_FROM_NOW()}
										disabled={false}
										label="To"
										value={orderEndDateTime}
										onChange={(newDateValue) => {
										  setOrderEndDateTime(newDateValue);
										  if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
										}}
										// renderInput={(params) => <TextField size="small" {...params} />}
									  />
								</LocalizationProvider>
							</div>
						</div>
					</div>    
				</div> 
				
				
					<div className="ContainerVertical" >
						<div className="TextStyle3 headerBlue" > Orders </div>
						<div className="dashboardScoreCircle header-blue-border">
							<div className="TextStyle4 headerBlue"> {ordersStats.orders}  </div>
						</div>
					</div>                                      
					<div className="ContainerVertical" >
						 <div className="TextStyle3 headerBlue" > Conectere Filled </div>
						 <div className="dashboardScoreCircle green-border" >
							<div className="TextStyle4 green"> {ordersStats.filledOrders}  </div>
						</div>
					</div>
					<div className="ContainerVertical" >
						 <div className="TextStyle3 headerBlue" > Customer Filled </div>
						 <div className="dashboardScoreCircle green-border" >
							<div className="TextStyle4 green"> {ordersStats.filledByCustomerOrders}  </div>
						</div>
					</div>	
					<div className="ContainerVertical" >
						 <div className="TextStyle3 headerBlue" > Open </div>
						 <div className="dashboardScoreCircle red-border" style={{borderColor:COLOR_ALERT}}>
							<div className="TextStyle4 red"> {ordersStats.openOrders}  </div>
						</div>
					</div>
					 <div className="ContainerVertical" >
						<div className="TextStyle3 headerBlue" > Spend </div>
						<div className="accountHistoryCard ContainerNoHeightCenter" >
							<div className="TextStyle4 headerBlue" > {ordersStats.coinsSpent}  </div>
							<img className="ccImgLarge" style={{paddingRight:"1rem"}} src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>
							<div className="TextStyle4 headerBlue"> {ordersStats.badgesDEISpent} </div>
							<img className="ccImgLarge" style={{paddingRight:"1rem"}} src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
							<div className="TextStyle4 headerBlue"> {ordersStats.badgesCSSpent} </div>
							<div> <img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> </div>
						</div>
					</div>
	
				
			</div>
					
			<div className="dashboardContainer">
				<div className="dashboardTitle">
					ORDERS
				 </div>
	
				<div className="dataGridWrapperTop" >        
					<MenuDataGrid 
						dataGridRows={orderRows} 
						dataGridColumns={orderColumns}
					/>
				</div>
			</div>    
		</div>    
	</Authenticator>
  );
};

export default AdminOrdersPage;
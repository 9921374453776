//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_WHITE, COLOR_BLUE_HEADER,   } from '../../data/conectereConfigData'; 

//React & Amplify
import React from 'react';

//Utils
import {setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../../utils/conectivityUtils";
import { LaunchIcon, LaunchButton} from "../../utils/generalUtils";

//Our components
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import StarRatings from "../../Components/starRatings/starRatings";
import CloseIcon from '@mui/icons-material/Close';
import parse from "html-react-parser";              //Needed to render HTML from the Editor

/*  Inputs:
      scheduledConectivityToPreview - optional - if included, SC duration is displayed

*/

const ConectivityDetailedView = ({showModal, scheduledConectivityToPreview, conectivityToView, handleCancelDetailedView, callbackLaunchClick}) => {
    
    //Safety check
    if (!showModal || !conectivityToView) return null;
    
    //Upon load, determine if the screen already has a Y position, i.e, has been scrolled down
    //If so, use that for TOP in our position ABSOLUTE so the modal pops up centered on the current screen rather than back up on the top
    //Note, we have to use position ABSOLUTE so that screen scroll with the page if the user scrolls down to read the rest of the modal
    //Also, we need to read the full height of the entire document and dynamically set the height of our background layer so that it covers the entire document
    //as the user scrolls up/down
    
    const screenPositionY = window.scrollY;
    const documentHeight = document.body.clientHeight;
    const headerColor = setHeaderColorByCategory(conectivityToView.category.label);

    // if (!showModalConectivityDetailed || scheduledConectivityToPreview == null) return null;
    
    function handleLaunch() {
        if (DEBUG_MODE >=2) console.log("LAUNCH clicked from Conectivity Detailed View",conectivityToView);
        if (callbackLaunchClick) callbackLaunchClick(conectivityToView);
    }

    return (
        <ModalNoBackground showModal={showModal} closeCallback={handleCancelDetailedView} cardColor={headerColor} opaqueBackground={true} hideInnerBorder={true} closeButtonBackground={headerColor} closeButtonColor={COLOR_WHITE}hideShadow={true}>
            <div className="ConectivitiesDetailedViewCard"  >
                <div className="ContainerNoHeightCenter fullWidth conectivityCardHeaderWrapper" style={{backgroundColor: headerColor}} >
                    <div className="ContainerNoHeightCenter conectivityCardHeaderImageWrapper" >
                        <img className="ContainerNoHeightCenter conectivityCardHeaderImage" src={setConectivityImageUrlByCategory(conectivityToView.category.label)}  />
                    </div>
                    <div className="ContainerVerticalSpaceAround maxHeight conectivityCardHeaderTextWrapper" style={{justifyContent:"center"}}>
                        <div className="TextStyle5 white" > {conectivityToView.title} </div>
                    </div>
                </div>
                
                <div className="ConectivityDescriptionWrapperPreview" >
                    <div className="TextStyle4_3" style={{color: headerColor}}> {conectivityToView.description}</div>
                </div>
        
                
                <div className="ConectivityInstructionPreview" >
                    {conectivityToView.instructions==null ? "" : parse(conectivityToView.instructions)}
                </div>
           
        
               <div className="ContainerNoHeightSpaceAround fullWidth positionRelative wrap conectivityCardActionsWrapper">
                        {conectivityToView.ratingStatsID == null ? "" :
                            <div  style={{display:"flex", padding:"10px 5px"}}>
                                   <StarRatings rating={conectivityToView.ratingStats.ratingsAverage} readOnly={true} starSize={"12px"}/>
                            </div>
                        }
                        <div  style={{display:"flex", padding:"10px 5px", color:setHeaderColorByCategory(conectivityToView.category.label)}}><i> {!conectivityToView.approvedPaidTime ? "" : conectivityToView.approvedPaidTime + " mins"}</i></div>
                        
                        {!conectivityToView.conectCoins >0 ? "" :
                            <div style={{display:"flex", padding:"10px 5px", flexwrap:"no-wrap", color:setHeaderColorByCategory(conectivityToView.category.label)}}> 
                                {!conectivityToView ? "" : conectivityToView.conectCoins} 
                                <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /> 
                            </div>                        
                        }
                        {!conectivityToView.badgesDEI>0 ? "" :  
                            <div style={{ padding:"0px 10px", flexwrap:"no-wrap", color:setHeaderColorByCategory(conectivityToView.category.label)}}> 
                                {!conectivityToView ? "" : conectivityToView.badgesDEI} 
                                <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
                            </div>
                        }
                        {!conectivityToView.badgesCS>0 ? "" :  
                            <div style={{padding:"0px 10px", flexwrap:"no-wrap", color:setHeaderColorByCategory(conectivityToView.category.label)}}> 
                                {!conectivityToView ? "" : conectivityToView.badgesCS} 
                                <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /> 
                            </div>
                        }
        
                </div>                           
            </div>
            
            {!callbackLaunchClick ? null :
                <button className="bannerButton" onClick={handleLaunch} style={{marginTop:"clamp(8px,2vw,20px)"}}>
                    <div className="ContainerNoHeightCenter" >
                        <LaunchIcon color={setHeaderColorByCategory(conectivityToView.category.label)} />
                        <div style={{paddingLeft:"5px"}}>LAUNCH</div>
                    </div>
                </button>
            }                
        </ModalNoBackground>    
        );
    
};

export default ConectivityDetailedView;
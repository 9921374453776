//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import React from 'react';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../data/conectereConfigData';

//Utils
import { compareConectivitiesMostFavorite, setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../../utils/conectivityUtils";
import { truncateStringWithDots } from "../../utils/generalUtils";

import { Divider } from "../../utils/generalUtils";

import StarRatings from "../../Components/starRatings/starRatings";


//React component
const CCdashboardCard = React.memo(({conectivities}) => {

    // if (DEBUG_MODE >= 2) console.log("Conectivites Favorites Card called", conectivities);

    //Safe guards
    if (conectivities == undefined ) return null;
    if (conectivities.length == 0) return null;
    
    const title="EMPLOYEE FAVORITES";

    //Remove any conectivities that have NOT been rated OR have not been published and that are not company specific
    const conectivitiesFiltered = conectivities.filter(conectivity => conectivity?.ratingStats.numberOfRatings > 0 && conectivity.publicationStatus == "PUBLISHED" || conectivity.publicationStatus == "PRIVATE");

    conectivitiesFiltered.sort(compareConectivitiesMostFavorite);
    // if (DEBUG_MODE >= 2) console.log("Conectivity Numbers after sort:",conectivitiesFiltered);
   
    //Slice down to the top set
    const reducedConectivities = conectivitiesFiltered.slice(0,8);
    // if (DEBUG_MODE >= 2) console.log("Conectivities after slice:",reducedConectivities);
    
    
    return (

        <div className="dashboardCard dashboardCardWideProfile dashboardCardFlexStartProfile">
            <div className="TextStyle4 headerBlue heavy">{title}</div>
            
            <Divider width={80}/>

            {reducedConectivities.map((conectivity, index) => (
                <div key={conectivity.id} className="dashboardCardTextRow">
                    <div className="dashboardCardFavoriteStars">
                        <StarRatings rating={conectivity.ratingStats.ratingsAverage} readOnly={true} starSize={"12px"}/> 
                    </div>
                    <img src={setConectivityImageUrlByCategory(conectivity.category.label, true)} height='18' />
                    <div className="dashboardCardText" style={{paddingLeft:"2px"}}>{truncateStringWithDots(conectivity.title, 50)} </div>
                </div >
            ))}
            
        </div>
        
    );

});


export default CCdashboardCard;



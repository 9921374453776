import "./progressBar.css";

//Amplify, React
import  React from 'react';

//Set the width of the progress bar in % of the container so different categories show nicely
export function setProgressBarWidth (signupDays, maxSignupDays) {
    
    var progressBarPercent = Math.floor((100 * signupDays / maxSignupDays));  //Generally, scale by a month
    if (progressBarPercent > 100) progressBarPercent = 100;             //Just a safety check
    
    var returnValue = progressBarPercent.toString() + "%";
    
    // console.log ("Setting progress bar width", signupDays, maxSignupDays, progressBarPercent, returnValue);

    return returnValue;
}    

//React component
export const ProgressBar = ({color, backgroundColor, labelColor, value, max, height, label, displayLabel, width}) => {

    // Set up the defaults
    if (backgroundColor == null) backgroundColor = '#e0e0de';
    if (height==null) height = '20px'; 
    if (max == null) max = 100;
    if (value == null) value = 0;
    if (label == null) label = (value / max * 100).toString() + '%';
    if (displayLabel == null) displayLabel = true;
    if (width == null) width = "100%";
    if (labelColor == null) labelColor = "#409cf7";
    
    var ourWidth = (100 * value / max).toString() + '%';

    // if (DEBUG_MODE >= 2) console.log("ProgressBar called", color, value, max, ourWidth);

    const progressBarContainerRowStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%'
    };
    
    const progressBarContainerStyles = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: height,
        backgroundColor: backgroundColor,        
        width: width,
        borderRadius:  '50px',
        margin: '10px',  
    }

    const fillerCalculatedStyles = {
        width: ourWidth,    //Calculate the width of the progress bar
        backgroundColor: color,
        height: '100%',
        borderRadius: 50,
        textAlign: 'right',
    };

    const progressBarLabel = {
        paddingLeft: '1.0rem',
        color: labelColor,
        fontWeight: '400',
        fontSize: '0.8rem'
    }
    return (

        <div style={progressBarContainerRowStyles}>
            <div style={progressBarContainerStyles}>
                <div style={fillerCalculatedStyles}>
                    <span >
    
                    </span>    
                </div>
            </div>
            <div style={progressBarLabel}>
                {displayLabel ? label : ""}
            </div>
        </div>
        

    );

}





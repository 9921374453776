//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';

// import "./displayCardSpotlightNumbers.css";
import React, { useState, useEffect} from 'react';

import Switch from '@material-ui/core/Switch';

//Utils
import { truncateIntegerByThousand } from "../../../../shared/utils/generalUtils";

//React component
const DisplayCardSpotlightNumbers = React.memo(({userAnalytics, showSent}) => {
    
    //showSent caused the card to show / sort Spotlight SENT numbers
    //the default is to show / sort RECEIVED numbers
    
    const[checkedMost, setCheckedMost] = useState(true);
    const[filteredUserNumbers, setFilteredUserNumbers] = useState([]);
    const [titleToShow, setTitleToShow] = useState("");
    const MAX_USERS_TO_LIST = 5;

    //
    useEffect(() => {
       sortData();
    //   if (DEBUG_MODE >= 2) console.log("Spotlight Numbers Card called", userAnalytics);
    }, [userAnalytics, checkedMost]);

    function sortData () {
        
        if (!userAnalytics) return;
        if (userAnalytics.length == 0) return;
        
        let tempUserNumbers;
        
        if (showSent) {
            if (checkedMost) {
                tempUserNumbers = userAnalytics.filter (user => user.spotlightsSent > 0); //Drop any users with zero entries
                tempUserNumbers.sort(compareSpotlightsMostSent);
            } else {
                tempUserNumbers = [...userAnalytics]; 
                tempUserNumbers.sort(compareSpotlightsLeastSent);
            }
    
        } else {
             if (checkedMost) {
                tempUserNumbers = userAnalytics.filter (user => user.spotlightsReceived > 0); //Drop any users with zero entries
                tempUserNumbers.sort(compareSpotlightsMostReceived);
            } else {
                tempUserNumbers = [...userAnalytics]; 
                tempUserNumbers.sort(compareSpotlightsLeastReceived);
            }
        }
    
        //Update our data for presentations
        var tempFilterdUserNumbers = tempUserNumbers.slice(0,MAX_USERS_TO_LIST);
        setFilteredUserNumbers(tempFilterdUserNumbers);            
         
        // if (DEBUG_MODE >= 2) console.log("Spotlight Numbers after sort.", showSent, userAnalytics, tempUserNumbers, tempFilterdUserNumbers);

        if (showSent) setTitleToShow((checkedMost ? "Spotlights Sent (most)" : "Spotlights Sent (least)"));
        else setTitleToShow((checkedMost ? "Recognized Employees (most)" : "Recognized Employees (least)"));
        
    }

    
    function compareSpotlightsMostSent(a, b) {
    
        if (a.spotlightsSent > b.spotlightsSent) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
            }
        if (a.spotlightsSent < b.spotlightsSent) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
        }


        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    function compareSpotlightsLeastSent(a, b) {
    
        if (a.spotlightsSent > b.spotlightsSent) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.spotlightsSent < b.spotlightsSent) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

   function compareSpotlightsMostReceived(a, b) {
    
        if (a.spotlightsReceived > b.spotlightsReceived) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
            }
        if (a.spotlightsReceived < b.spotlightsReceived) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
        }


        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    function compareSpotlightsLeastReceived(a, b) {
    
        if (a.spotlightsReceived > b.spotlightsReceived) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.spotlightsReceived < b.spotlightsReceived) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }
    
    const handleCheckedChange = (event) => {

        setCheckedMost(!checkedMost);
        
      };

    return (

        <div className="dashboardCard dashboardCardWideProfile dashboardCardFlexStartProfile">
            <div className="ContainerNoHeightCenter" >
                <div className="TextStyle4 headerBlue heavy">{titleToShow} </div>
              <div style={{ paddingLeft:"5px", }}> 
                    <Switch
                    size="small"
                    checked={checkedMost}
                    onChange={handleCheckedChange}
                    color="primary"
                    name="checkedStacked"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
              </div>
            </div>
            
            <div className="dashboardCardHeaderRow">
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Spotlights</div>
                <div className="dashboardCardTextSmall" style={{flex:"3 1 0", textAlign:"center"}}>Name </div>
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Coins awarded</div>
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>DEI Badges</div>
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>CS Badges</div>
            </div >


            {filteredUserNumbers.map((userI, index) => (
                <div key={userI.userID} className="dashboardCardTextRow">
                    <div className="dashboardCardNumber" style={{flex:"1 1 0"}}>{showSent ? userI.spotlightsSent : userI.spotlightsReceived}</div>
                    <div className="dashboardCardText" style={{flex:"3 1 0"}}>{userI.name} </div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(userI.spotlightsCoinsEarned)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /></div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(userI.spotlightsBadgesDEIEarned)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /></div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(userI.spotlightsBadgesCSEarned)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /></div>
                </div >
            ))}
       
        </div>
        
    );

});


export default DisplayCardSpotlightNumbers;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import './modalNoBackgroundFixed.css';
import '../modalNoBackground/modalNoBackground.css';        //Usefull css for inner elements; for use by parent pages
import React, { memo, useEffect, useState, useContext } from 'react';

//Context
import { DisplayContext } from '../../context/displayContext';            

//Utils
import { setBackgroundColorClassByColorValue } from "../../utils/conectivityUtils";


//Icons
import CloseIcon from '@mui/icons-material/Close';

//React component for uploading an image from the user

const ModalNoBackgroundFixed = React.memo(({children, showModal, closeCallback, cardColor, cardHeight, closeButtonColor, opaqueBackground, closeButtonBackground, hideShadow, hideInnerBorder}) => {

      // Context
	const { darkMode } = useContext(DisplayContext); 

    //Render nothing if not to be show
    if (!showModal) return null;
 
    //Init
    if (!showModal) showModal = false;
    if (!closeButtonColor) closeButtonColor = (cardColor ? 'white' : 'black');
    if (!closeButtonBackground) closeButtonBackground = (cardColor ? cardColor : "white");
    if (!hideShadow) hideShadow = false;

    //Local vars
    let styleObj = (hideInnerBorder ? { padding:'0px' } : {});
    if (cardHeight) styleObj.height = cardHeight;
    if (cardColor) styleObj.backgroundColor = cardColor;
    const backgroundColorClass = (cardColor ? setBackgroundColorClassByColorValue(cardColor) : 'purple-background');

    return (
       <div hidden={!showModal} className="ModalNoBackgroundFixedContainer ModalScroll" style={{ display: showModal ? 'flex' : 'none' }} onClick={closeCallback}>
            <div className = {`ModalNoBackgroundFixedCard ${!hideShadow ? "boxShadow" : "" } ${opaqueBackground ? "opaque-background" : backgroundColorClass} isCard `}  style={styleObj} onClick={(e)=>e.stopPropagation()} >
                <div className={`ModalNoBackgroundCloseButton `}>
                    <CloseIcon className={`TextStyle5 ${backgroundColorClass} pointer isCard `} onClick={closeCallback} style={{color:(darkMode ? closeButtonColor : "white")}} />
                </div>               
                {children}                 
            </div>
        </div>  
    );

});

export default ModalNoBackgroundFixed;




//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';
import React from 'react';
// import "./displayCardProductStats.css";

import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const CCdisplayCardFavorites = React.memo(({analytics}) => {

    const title="STORE STATISTICS";
    
    const footer="";

    // if (DEBUG_MODE >= 2) console.log("Product Stats Card called", analytics);
    
    return (

        <div className="dashboardCard">
            <div className="ContainerVerticalCenterFullWidth">
                <div className="TextStyle4 headerBlue heavy">{title}</div>
                <Divider width={80} />
            </div>
            <div className="dashboardCardTextRow" style={{paddingBottom:"10px"}}>
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreRectangle blue-background" >
                        {analytics.productsTotal}
                    </div>
                    <div className="dashboardCardText">Total Products</div>
                </div>
                <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreRectangle purple-background" >
                        {analytics.inStore}
                    </div>
                     <div className="dashboardCardText">Active</div>
                </div>                   
                 <div className="ContainerVerticalCenter" >
                    <div className="dashboardScoreRectangle yellow-background" >
                        {analytics.inactiveProducts}
                    </div>
                     <div className="dashboardCardText">Inactive</div>
                </div>               
            </div >

            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{"$" + analytics.leastExpensiveStore + " - $" + analytics.mostExpensiveStore}</div>
                <div className="dashboardCardText">Price range in store</div>
            </div >
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{ analytics.leastExpensiveStoreCoins + " - " + analytics.mostExpensiveStoreCoins}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /></div>
                <div className="dashboardCardText">Coin range in store</div>
            </div >

            <span className="dashboardCardTextFooter">{footer} </span>
        </div>
        
    );

});


export default CCdisplayCardFavorites;



import React, { useEffect, useState } from "react";
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../data/conectereConfigData';
import { displayExpiration } from "../../utils/dateTimeUtils";
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

//React component
//ClosingDateTime is a string date/time stamp for the Scheduled Conectivity
// , i.e., time remaining until the conectivity expires 

const ConectivityDurationTimer = ({closingDateTime, isLarge, label, labelClass, timeClass, hideTimeIcon}) => {

    // if (DEBUG_MODE >= 2) console.log("Configuring duration timer:", closingDateTime);
    if (!label) label = "expires in:";
    if (!timeClass) timeClass = (isLarge ? "TextStyle3 white-text" : "TextStyle2 white-text");
    if (!labelClass) labelClass = (isLarge ? "TextStyle2 white-text" : "TextStyle1 white-text");
    
    //This function updates the timeLeft Momement variable maintained in the State data
    function updateTimeLeft () {
        const currentTime = moment(); // get time set in user's computer time / OS
        var timeLeft = moment.duration(eventTime.diff(currentTime));    //Recalculate the duration until this event expires   
        return timeLeft;
    }

    // transform closing time (expiration time) into a Moment time object for use by this React component
    const eventTime = moment(closingDateTime, "YYYY MM DDTHH mm ssZ");
    
    //timeLeft is a Moment variable representing the time duration until the Conectivity expires
    const [timeLeft, setTimeLeft] = useState(updateTimeLeft());  //Define state variable; initialize to time remaining
    

    //Set up React useEffect set up a timer for once second, causing page to render
    //Upon render, restart the timer
    
    useEffect(() => {
        const timer = setTimeout(() => {
        setTimeLeft(updateTimeLeft());      //set state of timeLeft based on return value from updateTimeLeft
        }, 1000); //Fire in 1 second

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });
    
    return (
        <div className="ContainerNoHeightCenter noWrap" >
            <div className={labelClass} >{label}&nbsp;</div>
            <div className={timeClass} > {displayExpiration(timeLeft)} </div>
            {!hideTimeIcon ? (<AccessTimeIcon  className={timeClass} />) : null}  
        </div>
   );
};


export default ConectivityDurationTimer;


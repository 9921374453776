//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021


import "./adminReportProductsByFavorite.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator,  } from '@aws-amplify/ui-react';


//Queries and Mutations

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


//Icons
import {MailOutline, Add, Edit, DeleteOutline, PermIdentity, PhoneAndroid, Group} from '@material-ui/icons';
import FaceIcon from '@mui/icons-material/Face';
import {ExpandLess, ExpandMore, TrendingUp, Equalizer, Person  } from '@material-ui/icons';
import RadarIcon from '@mui/icons-material/Radar';
import AssignmentIcon from '@mui/icons-material/Assignment';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ProductsContext } from '../../shared/context/productsContext';              //Products context

//Our Components
import { ConectivityGraph } from "./Components/conectivityGraph/conectivityGraph";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";

//Utils
import {fetchRecordsByPrimaryKey, queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import {compareTransactionsByDate, compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { compareProductsByTitle, compareProductsByDateCreated, compareProductsLeastFavorite, compareProductsMostFavorite, compareProductsByCoinsSpent, compareProductsByPurchases} from "../../shared/utils/productUtils";
import { compareConectivitiesMostFavorite, setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../../shared/utils/conectivityUtils";
import { truncateStringWithDots, truncateIntegerByThousand, setRankBackground, setBackgroundByRawScore } from "../../shared/utils/generalUtils";
import { displayTeams } from "../../shared/utils/userAndTeamUtils";

import moment from 'moment';


//Here's the start of our Javascrpt App
const ReportProductsByFavoritePage = () => {
  
    const [productRows,setProductRows]=useState([]);                                //Formatted rows for user grid    
    const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);

   //variable to control confirmation Modal if user presses DELETE; default to hidden
    const [showModalSummary, setShowModalSummary] = useState(false);   

   // Authentication context
    const { authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics, } = useContext(AuthContext);    
    const { customers, users } = useContext(CustomerContext);            
    const { transactionsFiltered, displayReportControls,  setSelectedReport  } = useContext(AnalyticsContext);
    const {  products } = useContext(ProductsContext);  

    //Summary Modal state data
    const [productToSummarize, setProductToSummarize] = useState({});                

    //Analytics for product page
    // const [ccTransactions, setCCTransactions] = useState([]);
    const [perProductAnalytics, setPerProductAnalytics] = useState([]);

    const customerWideProductAlyticsInitialState = {
        inStore: 0,
        inactiveProducts: 0,
        productsTotal: 0,
        purchasesTotal: 0,
        coinsSpentTotal: 0,
        badgesDEISpentTotal: 0,
        badgesCSSpentTotal: 0,
        dollarsSpentTotal: 0,
        mostExpensiveStore: 0,
        leastExpensiveStore: 99999,
        mostExpensiveStoreCoins: 0,
        leastExpensiveStoreCoins: 99999,
        minPurchases: 99999,
        maxPurchases: 0,
        minCoinsSpent: 99999,
        maxCoinsSpent: 0,
    }

    const[customerWideProductAlytics,setCustomerWideProductAlytics] = useState(customerWideProductAlyticsInitialState);

    //On load, set Report select option to THIS page
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "FAV_REW");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
    });   
    
    useEffect(() => {
        calcProductActivity();    //call our funciton to fetch prodcuts for the main table
    }, [products, transactionsFiltered]);
    
    useEffect(() => {
        buildProductRows();    //call our funciton to fetch prodcuts for the main table
    }, [products, perProductAnalytics]);
    

    function buildProductRows() {

        if (DEBUG_MODE >= 2) console.log("Build Product Rows called", perProductAnalytics, products);
        
        if (perProductAnalytics == null || products == null) return;
        if (perProductAnalytics.length===0  || products.length===0) return;
        

       //Build rows for Data Grid
        var tempProductRows = [];
        for(var i = 0; i<products.length; i++){
            
            

            tempProductRows[i] = {
                id:i+1,
                dynamoDBID: products[i].id, 
                title:products[i].title,
                description: products[i].description,
                image:products[i].image,
                featured:products[i].featured,
                price:{
                    coins:products[i].price,
                    badgesDEI:products[i].badgesDEI,
                    badgesCS:products[i].badgesCS
                    },
                dollarCost:products[i].dollarCost,
                useInStore:products[i].useInStore,
                imageWidth:products[i].imageWidth,
                imageHeight:products[i].imageHeight,
                externalLink: products[i].externalLink ? true : false,
                
                //Fields to carry analytics in the Row
                purchases: 0,
                purchaseRank:0,
                dollarsSpent:0,
                dollarSpentRank: 0,
                coinsSpent: 0,
                coinSpentRank: 0,
                badgesDEISpent: 0,
                badgesCSSpent: 0,
            };

            //Set Customer 
            if (products[i].productScope === "CUSTOMER_SPECIFIC") {
                
                //PRODUCT OFFERED BY A SPECIFIC COMPANY
                const tempCustomer = customers.find(customer => customer.id === products[i].customerID);    //All clients will have a customer array, even if only 1 entry
                tempProductRows[i].customer = (tempCustomer ? tempCustomer.name : "");
               tempProductRows[i].managedByLogo = (tempCustomer ? tempCustomer.logo : "");
            } else {

                //CONECTERE SPONSORED (GLOBAL) PRODUCTS and DEFAULT STORE PRODUCTS      
                tempProductRows[i].customer = "Conectere";
                tempProductRows[i].managedByLogo = CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
            }
            
            //Find the analytics for the particular product 
            var index=perProductAnalytics.findIndex(record => record.productID === products[i].id);
            
            //Update product-specific numbers for the row
            if (index >-1) {
                tempProductRows[i].purchases = perProductAnalytics[index].purchases;
                tempProductRows[i].dollarsSpent = perProductAnalytics[index].dollarsSpent;
                tempProductRows[i].coinsSpent = perProductAnalytics[index].coinsSpent;
                tempProductRows[i].badgesDEISpent = perProductAnalytics[index].badgesDEISpent;
                tempProductRows[i].badgesCSSpent = perProductAnalytics[index].badgesCSSpent;
                tempProductRows[i].coinSpentRank = perProductAnalytics[index].coinSpentRank;
                tempProductRows[i].dollarSpentRank = perProductAnalytics[index].dollarSpentRank;
                tempProductRows[i].purchaseRank = perProductAnalytics[index].purchaseRank;
            }
        }
        
        //Filter any products with NO orders
        // tempProductRows = tempProductRows.filter(prod => prod.purchases > 0);

        //Sort by number or orders for each product, which will be the first column
        tempProductRows.sort(compareProductsByPurchases);

        if (DEBUG_MODE >= 2) console.log("Built Product Rows:", tempProductRows);
        setProductRows(tempProductRows);        
    }

    //This function processes the transaction data in view of the fetched products and computes all stats needed
    function calcProductActivity () {
 
        if (transactionsFiltered == null || products == null) return;
        if (transactionsFiltered.length===0  || products.length===0) return;
        
        console.log ("Inputs to build Product Data", products, transactionsFiltered);
    
        const tempProductNumbers = [];
        var tempAnalytics = customerWideProductAlytics;
        
        
        tempAnalytics.productsTotal = products.length;
        
        //First, initialize our array so we have a spot for product, including those with ZERO transactions
        //This holds all the data for each product that we need for all the different graphs
        for (var j = 0; j < products.length; j++){
            tempProductNumbers[j] = {
                productID: products[j].id,
                title:products[j].title,
                image:products[j].image,
                purchases: 0,
                dollarsSpent:0,
                coinsSpent: 0,
                badgesDEISpent: 0,
                badgesCSSpent: 0,
            }
            

            //update numbers for products in store
            
            if (products[j].dollarCost > tempAnalytics.mostExpensiveStore) tempAnalytics.mostExpensiveStore = products[j].dollarCost;
            if (products[j].dollarCost < tempAnalytics.leastExpensiveStore) tempAnalytics.leastExpensiveStore = products[j].dollarCost;
            if (products[j].price > tempAnalytics.mostExpensiveStoreCoins) tempAnalytics.mostExpensiveStoreCoins = products[j].price;
            if (products[j].price < tempAnalytics.leastExpensiveStoreCoins) tempAnalytics.leastExpensiveStoreCoins = products[j].price;
            if (products[j].useInStore) 
                tempAnalytics.inStore +=1; 
            else 
                tempAnalytics.inactiveProducts +=1;
            

        }
            
        if (DEBUG_MODE >= 2) console.log("Starting to process transactions.  Initialized Product Array:", tempProductNumbers);

        //Now, walk the entire list of transaction O(N) and update counts
        for (var j = 0; j < transactionsFiltered.length; j++){
            if (transactionsFiltered[j].transactionType === "CC_REDEMPTION") {
                
                //Find the particular product related to the transaction and update product-specific data
                var productIndex=products.findIndex((product) => product.id === transactionsFiltered[j].productID);
                
                //Update product-specific numbers
                if (productIndex >-1) {
                    tempProductNumbers[productIndex].purchases +=1;
                    tempProductNumbers[productIndex].dollarsSpent -=transactionsFiltered[j].dollarCost;
                    tempProductNumbers[productIndex].coinsSpent -=transactionsFiltered[j].amount;
                    tempProductNumbers[productIndex].badgesDEISpent +=transactionsFiltered[j].badgesDEI;
                    tempProductNumbers[productIndex].badgesCSSpent +=transactionsFiltered[j].badgesCS;
                }

                //Update total store-WIDE transaction numbers
                tempAnalytics.purchasesTotal +=1;
                tempAnalytics.coinsSpentTotal -=transactionsFiltered[j].amount;             //Amount is negative $$ in the transaction
                tempAnalytics.badgesDEISpentTotal +=transactionsFiltered[j].badgesDEI;
                tempAnalytics.badgesCSSpentTotal +=transactionsFiltered[j].badgesCS;
                tempAnalytics.dollarsSpentTotal -=transactionsFiltered[j].dollarCost;         //Dollar Cost is negative
            }    

        }

        //Sort the per-product analytics based on spend data for each product and update rankings along with overall min/max
        tempProductNumbers.sort(compareProductsByCoinsSpent);
        for (var i=0; i < tempProductNumbers.length; i++) {
            
           if (tempProductNumbers[i].purchases < tempAnalytics.minPurchases) tempAnalytics.minPurchases = tempProductNumbers[i].purchases;
           if (tempProductNumbers[i].purchases > tempAnalytics.maxPurchases) tempAnalytics.maxPurchases = tempProductNumbers[i].purchases;
           if (tempProductNumbers[i].coinsSpent < tempAnalytics.minCoinsSpent) tempAnalytics.minCoinsSpent = tempProductNumbers[i].coinsSpent;
           if (tempProductNumbers[i].coinsSpent > tempAnalytics.maxCoinsSpent) tempAnalytics.maxCoinsSpent = tempProductNumbers[i].coinsSpent;
            
            tempProductNumbers[i].coinSpentRank = i+1;
            tempProductNumbers[i].dollarSpentRank = i+1;
        }

        //Sort again based on number of purchases per product and update ranking
        tempProductNumbers.sort(compareProductsByPurchases);
        for (var i=0; i < tempProductNumbers.length; i++) {
             tempProductNumbers[i].purchaseRank = i+1;
        }
        
        setCustomerWideProductAlytics(tempAnalytics);                
        setPerProductAnalytics(tempProductNumbers);
        
        console.log ("Built product-specific data from transactionsFiltered:", tempProductNumbers);
        console.log ("Built customerWideProductAlytics data from transactionsFiltered:", tempAnalytics);
        

    }

    
    //Define Product Grid Format
    
    const productColumns = [
      {
        field: 'purchases',
        headerName: 'ORDERS',
        sortable: true,
        headerAlign: 'center',
        align:'center',
        minWidth: 125,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
        filterable:true,
        renderCell: (params)=> {
            return (
                <div className="ContainerNoHeightSpaceAround" style={{width:"100%", height:"100%"}}>
                    <div className="ProductPurchaseRank" style={{backgroundColor:setBackgroundByRawScore(params.row.purchases, customerWideProductAlytics.maxPurchases)}}> {params.row.purchases} </div>
                 </div>
                );
        }

      },
      {
        field: 'coinsSpent',
        headerName: 'SPEND TO DATE',
        sortable: true,
        headerAlign: 'center',
        align:'center',
        minWidth: 130,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
        filterable:true,
        valueFormatter: ({ value }) => {
            if (value == 1) return (value + " coin");
            else return (value + " coins");
        },
        renderCell: (params)=> {
            return (
                <div className="ContainerNoHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}> 
                        {params.row.coinsSpent}
                        <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
                 </div>
 
                );
        }

      },
      {
        field: 'useInStore',
        headerName: 'IN STORE',
        headerAlign: 'center',
        width: 120,
        editable: false,
        sortable: true,
        color:'white',
        hide: (!isAdmin),
        valueFormatter: ({ value }) => {
            
            return (value == false ? "NO" : "YES");
        },
        renderCell: (params)=> {
              return (
                    <div className="ContainerMaxHeightCenter" style={{width:"125px"}}>
                            <div className="ContainerNoHeightCenter" >
                                 <div>{params.row.useInStore == false ? "": <div className="checkMarkActive" style={{fontSize:"1.4rem", fontWeight:"700"}}> &#10003;</div>}</div>
                            </div>
                    </div>
              );
         }
      },  
      {
        field: 'title',
        headerName: 'PRODUCT',
        headerAlign: 'center',
        minWidth: 350,
        flex:1,
        editable: false,
        sortable: true,
        color:'white',
        renderCell: (params)=> {
        return (
            <div className="ContainerMaxHeightCenter">
                <div style={{minWidth:"80px", paddingRight:"10px", display:"flex", alignItems:"center", justifyContent:"flex-start", color:"#409cf7"}}>
                    <img className="ccImgLarge" src={params.row.image} alt="..." height='48'  />
                </div>
                <div className="ContainerNoHeighCenter TextStyle2" style={{height:"clamp(30px,5vh,50px)", paddingLeft:"2px", whiteSpace: "normal", wordBreak: "break-word" }}> 
                    {params.row.title}
                </div>
            </div>
            );
          },
      },
      {
        field: 'description',
        headerName: 'DESCRIPTION',
        headerAlign: 'center',
        // width: 320,
        minWidth: 300,
        flex:0,
        editable: false,
        sortable: true,
        color:'white',
        renderCell: (params)=> {
        return (
            <div className="ContainerNoHeight" style={{height:"50px", whiteSpace: "normal", wordBreak: "break-word" }}>
                    {truncateStringWithDots (params.row.description,130)}
            </div>
            );
        },
      },     
      {
        field: 'dollarCost',
        headerAlign: 'center',
        headerName: 'COST',
        width: 150,
        editable: false,
        color:'white',
        hide:false,
        valueFormatter: ({ value }) => {
            
            return ("$" + value);
        },
         renderCell: (params)=> {
            return (
                <div className="ContainerMaxHeightCenter" style={{width:"100%", color:"#409cf7"}}>
                    <div>${params.row.dollarCost}</div>
                </div>
            );
         }
     },
      {
        field: 'price',
        headerName: 'PRICE',
        headerAlign: 'center',
        width: 200,
        editable: true,
        color:'white',
        valueFormatter: ({ value }) => {
            
            if (DEBUG_MODE >= 2) console.log("Price formatter called", value);
            
            var returnString = value.coins +" coins";
            if (value.badgesCS == 1) returnString = returnString + ", " + value.badgesCS + " Service Badge";
            if (value.badgesCS > 1) returnString = returnString + ", " + value.badgesCS + " Service Badges";
            if (value.badgesDEI == 1) returnString = returnString + ", " + value.badgesDEI + " DEI Badge";
            if (value.badgesDEI > 1) returnString = returnString + ", " + value.badgesDEI + " DEI Badges";
            
            return returnString;
        },
        
         renderCell: (params)=> {
            return (
                    <div className="ContainerMaxHeightCenter" style={{width:"100%", color:"#409cf7"}}>
                        <div>{params.row.price.coins} 
                            <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
                        </div>
                        <div>{!params.row.price.badgesDEI ? "" :
                            <div>
                                {params.row.price.badgesDEI} 
                                <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  />
                            </div>
                            }
                        </div>
                        <div>{!params.row.price.badgesCS ? "" :
                            <div>
                                {params.row.price.badgesCS} 
                                <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  />
                            </div>
                            }
                        </div>
                    </div>

            );
         }
      },
      {
        field: 'action',
        headerName: '',
        headerAlign: 'center',
        minWidth: 150,
        flex:0.1,
        color:'white',
        sortable: false,
        renderCell: (params)=> {
            return (
                <div className="ContainerMaxHeightSpaceBetween">
                    <div className="menuDataGridListButton">
                        <Button variant="light" size="sm" >
                            <AssignmentIcon className="summaryButton" onClick={()=>handleSummary(params.row.dynamoDBID)}/>
                        </Button>                            
                    </div>
                    
                </div>
                );
            }
        },
        
    ];


    //Define Grid for User Summary
    const transactionSummaryColumns = [
      {
        field: 'createdAt',
        headerName: 'DATE',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        color:'white',
        valueFormatter: ({ value }) => {
        	const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
            return tempTime.format(" MMM DD, YYYY ");
                
        },
        renderCell: (params)=> {
            return (
                <span> 
                <center>{formatDate(params.row.createdAt)}</center>
                </span>
                )
        }

      },
    //   {
    //     field: 'name',
    //     headerName: 'Transaction',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 200,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //     renderCell: (params)=> {
    //         return (
    //             <div className="ContainerNoHeightCenter">
    //                 {params.row.permissionAnalytics==true ?  <Equalizer /> : ""}
    //                 {params.row.permissionEditor==true ?  <Edit /> : ""}
    //                 {params.row.permissionLaunch==true ?  <LaunchIcon /> : ""}
    //                 <div>
    //                     <img src={params.row.image} height='24' />
    //                 </div>
    //                 <div style={{padding:'10px', color:setHeaderColorByCategory(params.row.category)}}>
    //                     {params.row.name}
    //                 </div>
    //             </div>
    //             )
    //     },

    //   },

    {
        field: 'userName',
        headerName: 'EMPLOYEE',
        headerAlign: 'center',
        align:'center',
        minWidth: 400,  
        flex:1,
        color:'white',
        sortable: true,
        renderCell: (params)=> {
        return (
            <div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}}>
                 <div style={{width:"60px", }}>
                        <div className="ContainerNoHeightFlexLeft" style={{width:"100%"}} >
                            {params.row.avatarUrl =="" ? "" :
                                <div className="avatarImage avatarImageMedium"> 
                                    <img src={params.row.avatarUrl} className="avatarImageCenterPortrait"  alt=""   /> 
                                </div>
                            }
                        </div>
                </div>
                <div style={{width:"180px", textAlign:"left"}}>
                        <div style={{padding:'5px', fontSize:"18px", fontWeight:"400"}}>{params.row.userName}</div>
                </div>
          </div>
            );
          },
      },
      
      {
        field: 'amount',
        headerName: 'AMOUNT',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
        flex:0.3,
         // resizable: true,
        color:'white',
        disableExport: true,         
        renderCell: (params)=> {
            return (
                <div className="ContainerNoHeight">
                    <div className= {params.row.amount<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 

                        {params.row.amount<0 ? "" : "+"} 
                        {params.row.amount}  
                        <img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
                    </div>
                    
                    {!params.row.badgesDEI || params.row.badgesDEI ==0 ? "" :
                        <div className= {params.row.badgesDEI<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
                            {params.row.badgesDEI<0 ? "" : "+"} 
                            {params.row.badgesDEI}  
                            <img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
                        </div>
                    }
                    {!params.row.badgesCS || params.row.badgesCS ==0 ? "" :
                        <div className= {params.row.badgesCS<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
                            {params.row.badgesCS<0 ? "" : "+"} 
                            {params.row.badgesCS}  
                            <img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
                        </div>
                    }
                </div>


                )
        }

      },
    ];
 
    function formatDate(inputTime) {
        
    	const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
        return tempTime.format(" MMM DD, YYYY ");

    }

    const handleCancelUserSummary = () => {
        setShowModalSummary(false);
    };
        
    async function handleSummary (id) {
        if (DEBUG_MODE >= 2) console.log("Displaying summary for TEAM having DynamoDB ID:", id);
        const tempProductToSummarize = perProductAnalytics.find(product => product.productID === id);
        if (tempProductToSummarize == undefined) return;
        if (DEBUG_MODE >= 2) console.log("Displaying summary for product ", tempProductToSummarize);
        setProductToSummarize(tempProductToSummarize);
        var tempTransactions =  transactionsFiltered.filter(transaction => transaction.productID === id); 
        if (DEBUG_MODE >= 2) console.log("Fetched transactions for product summery", tempTransactions);

        
        //Build rows for a user-specific transaction log
        //Sort the new set of objects
        tempTransactions.sort(compareTransactionsByDateReverseChronological);
        
        if (DEBUG_MODE >= 2) console.log("computing transaction log for user ",id, tempTransactions);
        
         //Build rows for user Summary Data Grid 
        var tempThisProductsRows = [];
        for(var i = 0; i<tempTransactions.length; i++){
            
            //Get relevant user
            const tempUserOnTransaction = users.find(user => user.id === tempTransactions[i].userID);

            tempThisProductsRows[i] = {
            id:i+1,
            name:tempTransactions[i].name,
            amount:tempTransactions[i].amount,
            badgesCS:tempTransactions[i].badgesCS,
            badgesDEI:tempTransactions[i].badgesDEI,
            newBalance:tempTransactions[i].newBalance,
            badgesDEIBalance:tempTransactions[i].badgesDEIBalance,
            badgesCSBalance:tempTransactions[i].badgesCSBalance,
            createdAt:tempTransactions[i].createdAt,
            memo:tempTransactions[i].memo,
            sequenceNumber:tempTransactions[i].sequenceNumber,
            image:setConectivityImageUrlByCategory(tempTransactions[i].category, true),
            category:tempTransactions[i].category,
            
            userName:tempUserOnTransaction.firstName + (!tempUserOnTransaction.middleName ? "" : " " + tempUserOnTransaction.middleName.substr(0,1)) + " " + tempUserOnTransaction.lastName,
            title:tempUserOnTransaction.title,
            email: tempUserOnTransaction.email,
            teams: displayTeams(tempUserOnTransaction),
            customer: tempUserOnTransaction.customer.name,
            avatarUrl:tempUserOnTransaction.avatarUrl,            
          
            };
        }
        
        if (DEBUG_MODE >= 2) console.log("Built transaction rows for product summary:", tempThisProductsRows);
        setTransactionRowsSummary(tempThisProductsRows);        
        setShowModalSummary(true);
    } 

    //Handle access by unauthenticated user
    if (authState !== "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 
 
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else  return (

    <Authenticator>

        <Modal show={showModalSummary} onHide={handleCancelUserSummary} size="xl" centered>
            <Modal.Header closeButton>
        <Modal.Title>           
              <div className="ContainerMaxHeightCenter">
                <div className="avatarImage avatarImageMedium" style={{ color:"#409cf7", backgroundColor:"white", height:"48px", width:"48px", margin:"0px"}}>
                    <img className="avatarImageCenterPortrait" style={{padding:"5px"}}src={productToSummarize.image} alt="..." height='32'  />
                </div>
                <div style={{paddingLeft:"1.0rem"}}>
                    {productToSummarize.title}
                </div>
            </div>
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                    <center>
                        <div className="dataGridWrapperTop" style = {{width:"1000px"}}>        
                            <MenuDataGrid dataGridRows={transactionRowsSummary} dataGridColumns={transactionSummaryColumns} pageSize={5} turnOffBorder={true}/>
                        </div>
                    </center>


            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>
                <div > Close </div>
              </Button>
            </Modal.Footer>
         </Modal>    

            <div className="adminPage">
                <div className="ContainerVertical" style={{overflowX:"scroll"}}>
                
                    {displayReportControls({hideDisabledFields:false, useUserSelect:false, showOrdersCount:true, useDateSelect:true,  width:"auto", useReportSelect:true})}

                    <div className="dashboardContainer boxShadow">
                        <div className="dashboardTitle">
                            FAVORITE REWARDS
                         </div>   
                        <div className="dataGridWrapperTop" >        
                            <MenuDataGrid 
                            title="FAVORITE REWARDS"
                            showToolBar = {true}
                            dataGridRows={productRows} 
                            dataGridColumns={productColumns }
                                // width={(isSuperAdmin) ? REPORT_DATAGRID_WIDTH_SUPERADMIN : REPORT_DATAGRID_WIDTH}
                            />
                        </div>
                    </div>   
                </div>
            </div>    
        </Authenticator>
  );
};




export default ReportProductsByFavoritePage;
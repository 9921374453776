//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//This components provides an outer wrapper for our pages, which changes based on certain React state variables provided
//by the Display Context, such as Dark Mode or screen size
/* 
    <Outlet /> behaves a bit like props.children in standard React. 
    <Outlet /> is the placeholder location for where the nested children routes in our React Router will be rendered. 

*/

import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

//Context
import { SpecialEventsContext } from '../../../../shared/context/specialEventsContext';

// Utils
import { Footer } from '../../../../shared/utils/generalUtils';

//Components
import Header from '../Header/header';
import { SpecialEvents } from '../../../../shared/Components/specialEvents/specialEvents';

import { CONECTERE_CONFIG_DATA } from '../../../../shared/data/conectereConfigData';
import { DisplayContext } from '../../../../shared/context/displayContext';            //User Authentication Context
import { SideBarMenu } from '../Sidebar/SideBarMenu';
import Navbar from '../Navbar/navbar';

const PageLayout = React.memo(() => {
  // Special Events context
  const { enabledSpecialEvents } = useContext(SpecialEventsContext);

  // const currentReactPage = useLocation(); //This React reference is updated every time the component loads

  // console.log("Current Page:", currentReactPage);

  // Display context
  const { darkMode } = useContext(DisplayContext);

  // if (DEBUG_MODE >= 2) console.log("Page Wrapper", darkMode);

  return (
      <div className={`pageWrapper pageLayoutWrapper ${!darkMode ? '' : 'darkMode'}`} >
        <SpecialEvents enabledSpecialEvents={enabledSpecialEvents} />
        <div className='pageLayoutInnerWrapper'>
          <div className='pageLayoutLeftContainerWrapper'>
            <SideBarMenu darkMode={darkMode} />
          </div>
          <div className='pageLayoutRightContainerWrapper'>
            <Navbar />
            <div className='pageLayoutContentContainerWrapper'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
  );
});

export default PageLayout;

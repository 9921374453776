//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//STYLES
import '../../shared/styles/App.css';
 

//DATA
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../shared/data/conectereConfigData';


//React & Amplify
import React, { useContext, useEffect, useState } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';            //User Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context

//Our components
import CustomerEditor from "../../shared/Components/customerEditor/customerEditor";

//Here's the start of our Javascrpt App
const AdminCustomerSettings = () => {

	// Context
	const { authState, currentUser, isSuperAdmin, isAdmin,  } = useContext(AuthContext);    // Authentication context
	const { currentReactPage, darkMode } = useContext(DisplayContext); 

	//Local State
	const [initialTab, setInitialTab] = useState('conectivity_goals');
	const [urlProcessed, setUrlProcessed] = useState(false);

	// Redirect check - open a specific tab?
	useEffect(() => {

		//Need to wait until the current user is fully signed in via Amplify
		if (urlProcessed || authState !== "signedin" || !currentUser || !currentReactPage || !currentReactPage.search) return;

		//Now, check the URL params
		const searchParams = new URLSearchParams(currentReactPage.search);
		if (DEBUG_MODE >= 2) console.log("Current React Page", currentReactPage);
		if (DEBUG_MODE >= 2) console.log("Page React Search Params (after ?)", searchParams);

		let tabString;
		//Does the URL contain a specific TAB param that we should open?
		 if (searchParams.has("tab")) { 
			if (DEBUG_MODE >= 1) console.log("TAB param Detected!!");
			tabString = searchParams.get("tab");
			if (DEBUG_MODE >= 1) console.log(" TAB =", tabString);
		 }
		 
		 //Did we set an initial tab?  If so, open it
		  if (tabString) {
				setInitialTab(tabString);
				if (DEBUG_MODE >= 2) console.log("Admin. Set Tab Index", tabString);
		 }
		 setUrlProcessed(true);
	}, [authState, currentUser, currentReactPage, urlProcessed]); 

	/* RENDER FUNCTION */
	if (authState !== "signedin"  || !currentUser || (!isSuperAdmin && !isAdmin)) return null;

	//Render functions for authenticated users   
	return (
		<Authenticator>
			<div className="adminPage">
				<CustomerEditor 
					showEditor={true}
					isEdit={true}
					customer={currentUser && currentUser.customer ? currentUser.customer : null}
					initialTab={initialTab}
				/>		
				{/*
				<CustomerEditor 
					showEditor={showModalAdd || showModalEdit || showModalDelete || showModalReadOnly}
					customer={customerToEdit}
					isAdd={showModalAdd} isEdit={showModalEdit} isDelete={showModalDelete}
					handleCloseEditor={handleCloseEditor}  
				/>	\*/}				
			</div>
		</Authenticator>
	);
};
 
export default AdminCustomerSettings;


//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER } from '../data/conectereConfigData';

//React & Amplify
import React, { useCallback, useState,  useRef, useEffect} from "react";

import { GiphyFetch } from '@giphy/js-fetch-api';
const GiphyCacheContext = React.createContext();

const GiphyCacheProvider = ({ children }) => {
 
    //REACT Reference
     const giphyCacheRef = useRef();  
   
    //Local STATE
    const [giphyCache, setGiphyCache] = useState([]);
    const gf = new GiphyFetch(GIPHY_SDK_KEY_WEB);  // use @giphy/js-fetch-api to fetch gifs, instantiate with your api key

    useEffect(() => {
      giphyCacheRef.current = giphyCache; 
    });  //Update on any state change  
    
    //Main function by which clients request a GIF from our GIF cache
    //Retrieve from cache or fetch it if we have not alread cached it
    const fetchGif = useCallback (async (gifId) => {
        
        try {
            if (giphyCacheRef && giphyCacheRef.current) {
                // if (DEBUG_MODE >= 2) console.log("Processing request for GIF ID", gifId, giphyCacheRef.current);
                const tempObject = giphyCacheRef.current.find(giphyObj => giphyObj.data.id === gifId);
                //Fetch the GIF from GIPHY but only IF it has not already been fetched
                if (!tempObject) {
                    const tempGiphyCache = [... giphyCacheRef.current];  //Get a copy of our state data
                    const giphyObject = await gf.gif(gifId);
                    tempGiphyCache.push(giphyObject);
                    setGiphyCache(tempGiphyCache);
                    // if (DEBUG_MODE >= 2) console.log("Fetched GIF and added to cache", giphyObject);
                    return(giphyObject);    //Return the object fetched from GIPHY
                } else {
                    // if (DEBUG_MODE >= 2) console.log("Retrieved GIF from cache", tempObject);
                    return(tempObject);  //Return the object fetched from our local master cache
                }
            }
        } catch (err) {
                // if (DEBUG_MODE >= 1) console.log("GIPHY Fetch Error in our Giphy Context", err);
        }
        
        
    },[]);
    
    //Routine for our components to store GIFs to our GIF cache
    const cacheGif = useCallback ((giphyObjToCache) => {
        try {
            if (giphyCacheRef && giphyCacheRef.current) {
                // if (DEBUG_MODE >= 2) console.log("Processing request to cache GIF", giphyObjToCache, giphyCacheRef.current);
                if (!giphyObjToCache || !giphyObjToCache.id) {
                    // if (DEBUG_MODE >= 2) console.log("Improper GIF Object", giphyObjToCache);
                    return;
                }
                const tempObject = giphyCacheRef.current.find(giphyObj => giphyObj.data.id === giphyObjToCache.id);
                //Cache the GIF but only IF it has not already been cached
                if (!tempObject) {
                    const tempGiphyCache = [... giphyCacheRef.current];  //Get a copy of our state data
                    const giphyObject = {data:{...giphyObjToCache}};
                    tempGiphyCache.push(giphyObject);
                    setGiphyCache(tempGiphyCache);
                    // if (DEBUG_MODE >= 2) console.log("Added GIF to cache", giphyObject, tempGiphyCache);
                } else {
                    // if (DEBUG_MODE >= 2) console.log("Did NOT add GIF to cache.  Already present");
                }
            }
        } catch (err) {
                if (DEBUG_MODE >= 1) console.log("Error caching GIF in our Giphy Context", err);
        }
    },[]);    
    
    //return our Giphy Cache Context provider
    return (
      
         <GiphyCacheContext.Provider value={
                {   
                    fetchGif, cacheGif, giphyCache,
                }}>
                  {children}
        </GiphyCacheContext.Provider>
    ); 
};

export { GiphyCacheContext, GiphyCacheProvider };

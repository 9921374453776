//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';

import React from 'react';
// import "./displayCardTeamStats.css";

//Utils
import { truncateIntegerByThousand, Divider } from "../../../../shared/utils/generalUtils";

//
//React component - receives a single obect of customer-wide Team-related analytics
//
const CCdisplayCardTeamStats = React.memo(({analytics}) => {
    
    if (analytics == null) return;

    const title="Teams (" + analytics.teamsTotal + ")";
    
    const footer="";

    if (DEBUG_MODE >= 2) console.log("Team Stats Card called", analytics);

    return (

        <div className="dashboardCard">
            <span className="TextStyle4 headerBlue heavy">{title}</span>
            <Divider />

            <div className="dashboardCardTextRow">
                <div className="dashboardCardText" >Team Averages</div>
            </div >

            <div className="dashboardCardTextRow" style={{paddingTop:"clamp(4px,1vw,10px)"}}>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall blue-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgStressConectivitiesCompletedByTeams.toFixed(1)}</div>
                </div>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall purple-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgTeamConectivitiesCompletedByTeams.toFixed(1)}</div>
                </div>
                <div className="ContainerVertical" >                    
                   <img src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall green-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgSocialConectivitiesCompletedByTeams.toFixed(1)}</div>
                </div>
                <div className="ContainerVertical" >
                   <img src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall yellow-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{analytics.avgIndividualConectivitiesCompletedByTeams.toFixed(1)}</div>
                </div>
            </div >


            <div className="ContainerNoHeightCenter TextStyle3">
                    {analytics.avgCoinsEarnedByTeams.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>&nbsp;
                    {analytics.avgDEIBadgesEarnedByTeams.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/>&nbsp;
                    {analytics.avgCSBadgesEarnedByTeams.toFixed(1)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/>&nbsp;
            </div >

            <div className="dashboardCardTextRow">
                <div className="dashboardCardText" >Company Totals</div>
            </div >

            <div className="dashboardCardTextRow" style={{padding:"5px", width:"100%"}}>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div  className="dashboardScoreRectangle dashboardScoreRectangleSmall blue-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalStressConectivitiesCompletedByTeams)}</div>
                </div>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall purple-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalTeamConectivitiesCompletedByTeams)}</div>
                </div>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall green-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalSocialConectivitiesCompletedByTeams)}</div>
                </div>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall yellow-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{truncateIntegerByThousand(analytics.totalIndividualConectivitiesCompletedByTeams)}</div>
                </div >
            </div >

           
            <div className="ContainerNoHeightCenter TextStyle3" >
                {truncateIntegerByThousand(analytics.totalCoinsEarnedByTeams)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/>&nbsp;
                {truncateIntegerByThousand(analytics.totalDEIBadgesEarnedByTeams)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/>&nbsp;
                {truncateIntegerByThousand(analytics.totalCSBadgesEarnedByTeams)}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/>&nbsp;
            </div >

            <div className="ContainerNoHeightCenter TextStyle2">
                AVG TEAM CONECTIVITY
                <div className="dashboardScoreOval purple">{analytics.avgRelativeConectivityScoreByTeams.toFixed(1)} </div>
            </div >

            <span className="dashboardCardTextFooter">{footer} </span>
        </div>
        
    );

});


export default CCdisplayCardTeamStats;



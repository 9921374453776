//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import React from 'react'

import './modalGeneral.css';
import  { DEBUG_MODE, COLOR_WHITE } from '../../data/conectereConfigData';
import ModalNoBackgroundFixed from "../modalNoBackgroundFixed/modalNoBackgroundFixed";

//Icons
import CloseIcon from '@mui/icons-material/Close';


const ModalGeneral = ({showModalGeneral, setShowModalGeneral, modalGeneralHeaderColor, modalGeneralMessage, modalGeneralTitle, imageURL }) => {

   if (!modalGeneralTitle) modalGeneralTitle = "";
   
   const handleCancel = () => {
         setShowModalGeneral(false);
         if (DEBUG_MODE >= 2) console.log("Cancel button called");
     };
     
   // if (DEBUG_MODE >= 2) console.log("General Modal called", showModalGeneral, modalGeneralMessage);
   
   return (
      <ModalNoBackgroundFixed showModal={showModalGeneral} closeCallback={handleCancel} cardColor={modalGeneralHeaderColor} closeButtonColor={COLOR_WHITE} closeButtonBackground={modalGeneralHeaderColor}>
         <div className="ContainerVerticalCenter modalGeneralInnerCard white-background isCard" >
            {!imageURL ? "" :
              <div className="modalGeneralImage" style={{borderColor: modalGeneralHeaderColor}}> 
                     <img  className="avatarImageCenterPortrait" src={imageURL}  />  
              </div>
            }
      
            <div className="ContainerVerticalCenter modalGeneralCenterColumn" >
                  <h2 className="headerBlue">{modalGeneralTitle}</h2>
                 <div className="TextStyle3 black-text" style={{padding:"10px"}}>{modalGeneralMessage}</div>
            </div>
         </div>
      </ModalNoBackgroundFixed >
   );

};

export default ModalGeneral;




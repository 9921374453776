//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';

//React & Amplify
import React, {useState, useEffect} from 'react';


//3rd Party React Components
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Select from 'react-select';   //Version with support from multiple selections
import Checkbox from '@mui/material/Checkbox';

//Utils
import {  HelpButton } from "./generalUtils";
import moment from 'moment-timezone';
import { formatDate  } from "./generalUtils";

//Date / Time Constants
export function NOW() {return moment();}
export function TODAY() {return moment().startOf('day')};
export function TOMORROW() {return  moment().add(1, 'day').startOf('day');}
export function NOW_STRING() {return moment().toISOString();}
export function NOW_ROUNDED_UP_TO_TEN_MIN() {return moment().set({minute:(Math.ceil(NOW().clone().minute() /10) *10)});}
export function TODAY_NOON() {return  moment().set({hour:12,minute:0,second:0,millisecond:0});}
export function TOMORROW_NOON() {return  moment().add(1, 'day').startOf('day').set({hour:12,minute:0,second:0,millisecond:0});}
export function TODAY_STRING() {return  moment().toISOString();}
export function ONE_WEEK_AGO() {return  moment().subtract(1, 'weeks')}
export function ONE_WEEK_AGO_STRING() {return  moment().subtract(1, 'weeks').toISOString();}
export function ONE_MONTH_AGO() {return  moment().subtract(1, 'months');}
export function ONE_MONTH_AGO_STRING() {return  moment().subtract(1, 'months').toISOString();}
export function THREE_MONTHS_AGO() {return  moment().subtract(3, 'months');}
export function THREE_MONTHS_AGO_STRING() {return  moment().subtract(3, 'months').toISOString();}
export function ONE_YEAR_AGO() {return  moment().subtract(365, 'days');}
export function ONE_YEAR_AGO_STRING() {return  moment().subtract(365, 'days').toISOString();}
export function ONE_MONTH_FROM_NOW() {return  moment().add(1, 'months');}
export function ONE_MONTH_FROM_NOW_STRING() {return  moment().add(1, 'months').toISOString();}
export function SIX_MONTHS_FROM_NOW() {return  moment().add(6, 'months');}
export function SIX_MONTHS_FROM_NOW_STRING() {return  moment().add(6, 'months').toISOString();}
export const TIME_ZONE_OPTIONS = buildTimZoneOptions();


export function compareByPeriod (a, b) {

	// if (DEBUG_MODE >= 3) console.log("Time-series sort compare called", a, b);
	
	if (a == null || b == null) return 0;

	var aDateTime;
	var bDateTime;
	
	switch (a.period) {
		case "DAY":
			// if (DEBUG_MODE >= 3) console.log("Sorting DAY time series graph");
			 aDateTime = moment(a.name, "D-MMM-YY");
			 bDateTime = moment(b.name, "D-MMM-YY");
			break;
			
		case "WEEK":
			// if (DEBUG_MODE >= 3) console.log("Sorting WEEK time series graph");
			 aDateTime = moment(a.name, "D-MMM-YY");
			 bDateTime = moment(b.name, "D-MMM-YY");
			break;
		case "MONTH":
			// if (DEBUG_MODE >= 3) console.log("Sorting MONTH time series graph");
			 aDateTime = moment(a.name, "MMM-YY");
			 bDateTime = moment(b.name, "MMM-YY");
			break;
		case "QUARTER":
			// if (DEBUG_MODE >= 3) console.log("Sorting QUARTER time series graph");
			var month = "";
			if (a.name[1] == "1") month = "Jan";
			if (a.name[1] == "2") month = "Apr";
			if (a.name[1] == "3") month = "Jul";
			if (a.name[1] == "4") month = "Oct";
			var dateFormatStringA = month + "-" + a.name[3]+a.name[4];
			if (b.name[1] == "1") month = "Jan";
			if (b.name[1] == "2") month = "Apr";
			if (b.name[1] == "3") month = "Jul";
			if (b.name[1] == "4") month = "Oct";
			var dateFormatStringB = month + "-" + b.name[3]+b.name[4];
			
			 aDateTime = moment(dateFormatStringA, "MMM-YY");
			 bDateTime = moment(dateFormatStringB, "MMM-YY");
			break;
		case "YEAR":
			//  if (DEBUG_MODE >= 3) console.log("Sorting YEAR time series graph");
			 aDateTime = moment(a.name, "YYYY");
			 bDateTime = moment(b.name, "YYYY");
			break;
	}

	if (aDateTime != undefined && bDateTime != undefined) {
	  if (aDateTime.isAfter(bDateTime)) {
			// if (DEBUG_MODE >= 3) console.log("Time series compare returned 1" , aDateTime.toISOString(), bDateTime.toISOString());   
			return 1;
			}
		if (bDateTime.isAfter(aDateTime)) {
			// if (DEBUG_MODE >= 3) console.log("Time series compare returned -1" , aDateTime.toISOString(), bDateTime.toISOString());   
			return -1;
		}
		
		// if (DEBUG_MODE >= 3) console.log("Time series compare returned 0" , aDateTime.toISOString(), bDateTime.toISOString());   
		return 0;  
	} else {
		// if (DEBUG_MODE >= 3) console.log("Error - Improper comparison", a,b);
	}
	
} 

//Function for sorting TimeZone offsets
//Returns the offsets in ascending order from -12 ... 0 ... +12 relative to GMT
export function compareTimeZoneByOffsetInt(a, b) {
	if (!a || !b) return 0;
	if (a.offsetInt > b.offsetInt) return +1; 
	if (a.offsetInt < b.offsetInt) return -1; 
	return 0;
} 

//Reconstruct and return the invitation instance INDEX for a specific instance date time
export function getInvitationInstanceIndex ({invitation, invitationInstanceDateTime}) {
	let instanceIndex = 1;

	//Is this a recurring launch rule?  If so,set the instanceIndex info.  Compute if necessary
	if (invitationInstanceDateTime && invitation && invitation.launchRule && invitation.launchRule.launchRepeatOption > 1 && invitation.launchRule.launchRepeatOption > 1) {
		//Now, set the index
		if (invitation.launchRuleInstanceIndex) {
			instanceIndex = invitation.launchRuleInstanceIndex;
			// if (DEBUG_MODE >= 2) console.log("Set launch rule instance index",invitation.launchRuleInstanceIndex);
		} else {
			//No embedded information, so do this the hard way
			const tempLaunchRuleInstanceStartDateTimeMoment =  moment(invitationInstanceDateTime);
			
			if (tempLaunchRuleInstanceStartDateTimeMoment.isValid()) {

				const arrayOfLaunchDates = getrecurrenceRuleLaunchDates(invitation.launchRule);
				if (!arrayOfLaunchDates || arrayOfLaunchDates.length === 0) {
					 if (DEBUG_MODE >= 2) console.log("Error - Unable to generate any recurrence dates for this recurring launch rule"); 
				}
				// console.log("Event series for this launch rule",arrayOfLaunchDates);
				const matchingInstanceDate = arrayOfLaunchDates.find(launchDate =>  moment(launchDate).isSame(tempLaunchRuleInstanceStartDateTimeMoment,'day'));
				
				if (matchingInstanceDate) {
					instanceIndex = arrayOfLaunchDates.indexOf(matchingInstanceDate) + 1;   //ADD 1 since our instance index starts at 1 for 1 TO N instances
					//  if (DEBUG_MODE >= 2) console.log("Found launch rule instance date that matches the selected instance", matchingInstanceDate, instanceIndex);
					// if (DEBUG_MODE >= 2) console.log("Set launch rule instance index",instanceIndex);
				}
			}
		}
	}
	
	return instanceIndex;
}

//Return the invittion date & time as an ISO STRING 
export function getInvitationDateTime(invitation) {
	if (DEBUG_MODE >= 2) console.log("Determining Invitation Instance Date/Time", invitation);

	//Safety check
	if (!invitation || !invitation.launchRule || !invitation.launchRule) return "";

	let tempInstanceDateTime = "";
	
	//Does this invitation have our (new) embedded instance information?
	if (invitation.launchRuleInstanceStartDateTime) {
		tempInstanceDateTime = invitation.launchRuleInstanceStartDateTime;
		
		
		// if (DEBUG_MODE >= 2) console.log("Invitation have our (new) embedded instance information", invitation.launchRuleInstanceStartDateTime);
	} else {
		tempInstanceDateTime = getRecurrenceRuleNextLaunchDate(invitation.launchRule);  //Extract the date/time from the LR itself
	}
	return tempInstanceDateTime;
	
}  

export function getLaunchRuleDateTime(launchRule) {
	
	if (!launchRule) return null;
	
	//Start by getting the next launch date/time for this launch rule
	const nextLaunchDateString = getRecurrenceRuleNextLaunchDate(launchRule); 

	// if (DEBUG_MODE >= 3) console.log("Invitation Next Launch Date String ", nextLaunchDateString );

	if (nextLaunchDateString !== 'EXPIRED') {   
		//Turn back into a Moment, for that DATE, then set the time
		const inviteDateTimeMoment = moment(nextLaunchDateString);
		const launchRuleTimeMoment = moment(launchRule.eventStartDateTime);
		inviteDateTimeMoment.set('hour', launchRuleTimeMoment.hours());
		inviteDateTimeMoment.set('minute', launchRuleTimeMoment.minutes());
	
		let dateTimeString;
		if (launchRule.allDayEvent) {
		  dateTimeString = inviteDateTimeMoment.format("ddd, MMM Do") + " (all day)";
		} else {
		  dateTimeString = inviteDateTimeMoment.calendar({sameElse: 'ddd, MMM Do, h:mm a'});
		}
		
		// if (DEBUG_MODE >= 3) console.log("Generated date/time string " + dateTimeString );
		// if (DEBUG_MODE >= 3) console.log("From date/time  " + inviteDateTimeMoment.toISOString() );
		return dateTimeString;        
	}
	
	return 'Expired invitation';
}  


//Format an invitation instance date/time using the fun moment shorthand or, if multi-day, showing the date range
	/* (1) So, here's the thing.  Our inviation start/end times are stored in ISO time and accurate for the user that submitted them.  
		THIS user may be in a different time zone than the user, and they may be different GMT days but the same day for the user.  Need to check in the user's tz
		(2) also note, for All Day events, the end date is always the day AFTER the all day event, which may span multiple days.  So, a single all day event starts on day 1
			and has an end day of 000.000 on day 2 for that scheduling user.  Note, the date will always be different by at least 1 day but again, we can't just convert back to moments in time
			as they may fall on the same day in a given timezone.  This is industry standard and also requried by Full Calendar
	*/
	export function formatInvitationInstanceDateTime({eventStartDateTime, eventEndDateTime, allDayEvent, timeZoneCode})  {
		//Safety check
		if (!eventStartDateTime || !eventEndDateTime) {
			if (DEBUG_MODE > 1) console.log("Error - improper params to format invitation date/time", eventStartDateTime, eventEndDateTime, allDayEvent);
			return '';
		}
		if (DEBUG_MODE >= 2) console.log("Formatting invitation instance date & time", eventStartDateTime, eventEndDateTime, allDayEvent, timeZoneCode);
	
	
		let dateTimeString='', eventStartDateTimeMom, eventEndDateTimeMom;
		//Put event date & times browser's tz
		eventStartDateTimeMom = moment(eventStartDateTime);
		eventEndDateTimeMom = moment(eventEndDateTime);
	
		//For formatting, does the event happen on a single day event? Check both the flag and the start/end times to be sure this is a single day event for this user
		if (!allDayEvent && eventStartDateTimeMom.isSame(eventEndDateTime,'day')) {
			dateTimeString = eventStartDateTimeMom.calendar({sameElse: 'ddd, MMM Do, h:mm a'});
	
		} else {
			//So, this is an all-day or multi-day event, so construct new moments ignoring the time
			// const eventStartDateString =eventStartDateTimeMom.format('MM-DD-YYYY');	 					
			// const eventEndDateString =eventEndDateTimeMom.format('MM-DD-YYYY');	
			const eventStartDateString =eventStartDateTime.slice(0,10);	 					
			const eventEndDateString =eventEndDateTime.slice(0,10);			
			if (DEBUG_MODE > 1) console.log("Constructed new date strings", eventStartDateString, eventEndDateString);
			//Generate new moments in the current user's local tz
			eventStartDateTimeMom = moment(eventStartDateString);
			eventEndDateTimeMom = moment(eventEndDateString);		 					
			if (DEBUG_MODE > 1) console.log("Constructed new date moments in users local TZ", eventStartDateTimeMom.format(), eventEndDateTimeMom.format());
			eventStartDateTimeMom =eventStartDateTimeMom.startOf('day');	 					//reset time but in the user's local tz
			eventEndDateTimeMom =eventEndDateTimeMom.startOf('day').subtract(1, 'day');	//reset time but in the user's local tz; subtract 1 day since EndDate is always the day AFTER an all-day event. 
	
			//How many days?
			if (eventEndDateTimeMom.isSame(eventStartDateTimeMom,'day'))		//Same day in 
				//Single day All Day event
				 dateTimeString = eventStartDateTimeMom.format("ddd, MMM Do") + " (all day)";
			else 
				//Multi-day event, so we need to subtract 1 day from the end date to we show the correct ending day (inclusively)
				dateTimeString = eventStartDateTimeMom.format("ddd, MMM Do") + " - " +  eventEndDateTimeMom.format("ddd, MMM Do");  
		}
		
			if (DEBUG_MODE >= 2) console.log("Generated date/time string " + dateTimeString );
		  return dateTimeString;    
	}  
	

export function formatDateSimple(dateString) {
	if (!dateString) return '';
	return moment(dateString).format("ddd, MMM Do");
}

export function formatDateFriendly(dateString) {

	return moment(dateString).calendar(
		null, {
			sameDay: '[today]',
			nextDay: '[tomorrow]',
			nextWeek: 'dddd',
			lastDay: '[yesterday]',
			lastWeek: '[last] dddd',
			sameElse: 'MMM Do'
	  }
	)
}
function buildTimZoneOptions() {

	//Get all zone names 
	const timeZoneNames = moment.tz.names();
	const tempTimeZoneOptions = [];
	for (const zone of timeZoneNames) {
		var offset = moment.tz(zone).format('Z');    // offset of timezones
		const tempOption = {
			label:'(UTC' + offset + ') ' + zone,
			value: zone,
			offsetInt: (offset && offset.length > 3 ? parseInt(offset.slice(0,3)) : 0), //Extract first 3 chars (like -09 from -09:00)
		};
	   tempTimeZoneOptions.push(tempOption);    //Add option to the end of the array
	}
	if (DEBUG_MODE >= 2) console.log("Generated time zone options", tempTimeZoneOptions );
	tempTimeZoneOptions.sort(compareTimeZoneByOffsetInt);
	return (tempTimeZoneOptions);
}

export const DisplayTimeZoneSelectComponent = ({options, handleSelectUser, isMulti, selectedOptions, disabled, zIndex, placeholder, name, padding, isClearable}) => {
	
	if (!placeholder) placeholder = " - select time zone - ";
	if (!zIndex) zIndex = "99";
	if (!name) name = 'tzDropdown';
	if (!padding) padding = "10px";
	if (isClearable === undefined) isClearable = true;
	
	return (
		<div style={{flexShrink:'0', width:"100%",  padding:padding, height:"auto", marginLeft:"auto", zIndex:zIndex}}>
			<Select    
				isMulti={isMulti} 
				name={name} 
				options={options} 
				onChange={handleSelectUser} 
				value={selectedOptions} 
				placeholder={placeholder}
				isDisabled={disabled}
				isClearable={isClearable}
				formatOptionLabel={ option => (
				   <div className="ContainerNoHeightFlexLeft TextStyle4 noWrap"> {option.label}  </div>
				)}
				styles={{
					control: (base) => ({
						...base,
						backgroundColor:'var(--card-background)',
						color:'var(--page-text)',
						borderRadius: 8,
						minHeight: 45,
					}),
					indicatorSeparator: (baseStyles) => ({
                        ...baseStyles,
                        width: 0,
                    }),
					singleValue: (provided) => ({
						...provided,
						color: 'var(--page-text)',
					}),
					option: (provided, state) => ({
						...provided,
						color: state.isSelected ? 'white' : (state.isFocused ? 'white' : 'gray'), // Change option text color
						backgroundColor: state.isSelected ? 'var(--HEADER-blue)' : (state.isFocused ? 'darkgray' : 'var(--card-background)'), // Highlight selected option
					}),
					menuList: (provided) => ({
						...provided,
						backgroundColor:'var(--card-background)',
					}),
				}}
			  />
		</div>
  );
}; 
// Be CAREFUL changing the order of the entries below
// Arrays are accessed direction in AdminConectivitis.js and assume certain orders

export const CALENDAR_REPEAT_OPTIONS = [
	{id:1, value:"NONE", label:'None', rruleIndex:-1},
	{id:2, value:"DAILY", label:'Daily', rruleIndex:3},
	{id:3, value:"WEEKLY", label:'Weekly', rruleIndex:2},
	{id:4, value:"MONTHLY", label:'Monthly', rruleIndex:1},
 ];

export const WEEK_REPEAT_OPTIONS = [
	{id:1, value:1, label:'Sunday', rruleIndex:6},
	{id:2, value:2, label:'Monday', rruleIndex:0},
	{id:3, value:3, label:'Tuesday', rruleIndex:1},
	{id:4, value:4, label:'Wednesday', rruleIndex:2},
	{id:5, value:5, label:'Thursday', rruleIndex:3},
	{id:6, value:6, label:'Friday', rruleIndex:4},
	{id:7, value:7, label:'Saturday', rruleIndex:5},
];


export const MONTH_REPEAT_OPTIONS = [
	{id:1, value:1, label:'1st'},
	{id:2, value:2, label:'2nd'},
	{id:3, value:3, label:'3rd'},
	{id:4, value:4, label:'4th'},
	{id:5, value:5, label:'5th'},
	{id:6, value:6, label:'6th'},
	{id:7, value:7, label:'7th'},
	{id:8, value:8, label:'8th'},
	{id:9, value:9, label:'9th'},
	{id:10, value:10, label:'10th'},
	{id:11, value:11, label:'11th'},
	{id:12, value:12, label:'12th'},
	{id:13, value:13, label:'13th'},
	{id:14, value:14, label:'14th'},
	{id:15, value:15, label:'15th'},
	{id:16, value:16, label:'16th'},
	{id:17, value:17, label:'17th'},
	{id:18, value:18, label:'18th'},
	{id:19, value:19, label:'19th'},
	{id:20, value:20, label:'20th'},
	{id:21, value:21, label:'21st'},
	{id:22, value:22, label:'22nd'},
	{id:23, value:23, label:'23rd'},
	{id:24, value:24, label:'24th'},
	{id:25, value:25, label:'25th'},
	{id:26, value:26, label:'26th'},
	{id:27, value:27, label:'27th'},
	{id:28, value:28, label:'28th'},
	{id:29, value:29, label:'29th'},
	{id:30, value:30, label:'30th'},
	{id:31, value:31, label:'31st'},
	];

export const MONTH_REPEAT_OPTIONS_2 = [
	{id:1, value:1, label:'1st', fullLabel:"first"},
	{id:2, value:2, label:'2nd',fullLabel:"second"},
	{id:3, value:3, label:'3rd',fullLabel:"third"},
	{id:4, value:4, label:'4th',fullLabel:"fourth"},
	{id:5, value:5, label:'last', fullLabel:"last"},
];
	
export const MONTH_REPEAT_OPTIONS_3 = [
	{id:1, value:1, label:'Sunday'},
	{id:2, value:2, label:'Monday'},
	{id:3, value:3, label:'Tuesday'},
	{id:4, value:4, label:'Wednesday'},
	{id:5, value:5, label:'Thursday'},
	{id:6, value:6, label:'Friday'},
	{id:7, value:6, label:'Saturday'},
	{id:8, value:8, label:'- Weekday -'},
];

export const DisplayDateTimeSelect = React.memo(({value, onChange, disabledDate, disabledTime, hideTime, zIndex, labelDate, labelTime, forceClosedTrigger, disableFuture, minDateString, maxDateString, datepickerContainerStyleObj, timepickerContainerStyleObj}) => {
	const [openDate,setOpenDate] = useState(false);
	const [openTime,setOpenTime] = useState(false);

	// console.log("DATE / TIME RENDERED ", openDate)
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	// if (DEBUG_MODE >= 2) console.log("Local TimeZone: " + timezone); 

	//Get the min / max dates in the current user's TZ
	let minDateMom, maxDateMom;
	if (minDateString) {
		if (DEBUG_MODE > 2) console.log("Processing min date string:", minDateString);
		 minDateMom = moment.tz(minDateString, (timezone ? timezone : 'America/New_York')).startOf('day');
		//  if (DEBUG_MODE ) console.log("MIN date:", minDateMom.toISOString());
	}
	if (maxDateString) {
		if (DEBUG_MODE > 2) console.log("Processing max date string:", maxDateString);
		maxDateMom = moment.tz(maxDateString, (timezone ? timezone : 'America/New_York')).subtract(1,'minute').endOf('day');       //Note, the end date for ALL DAY events is the day AFTER the last day of the challenge period
		// if (DEBUG_MODE ) console.log("MAX date:", maxDateMom.toISOString());
	}

	//useEffect by which the outisde world can tell us to close the selection
	useEffect (() => {
		console.log("forceClosedTrigger", forceClosedTrigger);
		setOpenDate(false); setOpenTime(false);
	},[forceClosedTrigger]);

	return (
		   <div className="ContainerNoHeightFlexLeft"  style={{zIndex:zIndex, alignItems:"flex-start", position:"relative" , gap: 12}}>
				 <div style={{padding:"clamp(4px,1vw,10px) 0"}} onClick={(e)=>{e.stopPropagation();}}>
				 	<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							minDate={minDateMom && minDateMom.isValid() ? minDateMom : undefined}
							maxDate={maxDateMom && maxDateMom.isValid() ? maxDateMom : undefined}
							open={openDate}
							onOpen={() => {if (DEBUG_MODE > 1) console.log("OPENED!"); setOpenDate(true)}}
							onClose={() => {if (DEBUG_MODE > 1) console.log("CLOSED!"); setOpenDate(false)}}
							label={labelDate}
							value={value}
							onChange={(e)=>{if (DEBUG_MODE > 1) console.log("CHANGED!"); setOpenDate(false);onChange(e);}}
							disabled={disabledDate}
							disableFuture={disableFuture}
							slotProps={{
								textField: {
									sx: {
										borderRadius: '8px',
										overflow: 'hidden',
										'& .MuiInputBase-root.MuiOutlinedInput-root': {
											borderRadius: '8px',
											minHeight: 42,
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'var(--border-color-V2)',
											},
										},
									}
								},
							}}
						/>
					</LocalizationProvider>
				</div>
				{hideTime ? null : 
					<div className="ContainerVerticalStart" style={{position:"relative", padding:"clamp(4px,1vw,10px) 0" }} onClick={(e)=>{e.stopPropagation();}}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<TimePicker
								open={openTime}
								onOpen={() => setOpenTime(true)}
								onClose={() => setOpenTime(false)}
								label={labelTime}
								value={value}
								disabled={disabledTime }
								onChange={onChange}
								slotProps={{
									textField: {
										sx: {
											borderRadius: '8px',
											overflow: 'hidden',
											'& .MuiInputBase-root.MuiOutlinedInput-root': {
												borderRadius: '8px',
												minHeight: 42,
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: 'var(--border-color-V2)',
												},
											},
										}
									},
								}}
							/>
						</LocalizationProvider>
					</div>
				}
			</div>
		);
});
 
export const DisplayAllDayEventSelect = ({value, onChange, isDisabled, colorClass, zIndex}) => {

	if (!colorClass) colorClass = "black-text";
	if (!zIndex) zIndex = "6500";
	return (
		<div className="ContainerNoHeightFlexLeft" style={{zIndex:zIndex}}>
			<Checkbox
				checked={value}
				disabled={isDisabled }
				onChange={onChange}
				inputProps={{ 'aria-label': 'controlled' }}
				sx={{
					'&.Mui-checked': {
						color: 'var(--gloomy-purple)',
					},
				}}
			/>
			<div className = {"TextStyle4 " + colorClass} >All Day</div>
			<HelpButton hoverText={"Check this box if you would like to calendar the entire day for the conectivity."}  
				hoverTextClassName='TextStyle4 hoverTextStyle1 ' className={'light-grey'} noTransform /> 
		</div> 
	) ;
}

export const DisplayAnytimeEventSelect = ({value, onChange, isDisabled, colorClass, zIndex}) => {

	if (!colorClass) colorClass = "black";
	if (!zIndex) zIndex = "6500";
	return (
		<div className="ContainerNoHeightFlexLeft" style={{zIndex:zIndex}}>
			<Checkbox
				checked={value}
				disabled={isDisabled }
				onChange={onChange}
				inputProps={{ 'aria-label': 'controlled' }}
				sx={{
					'&.Mui-checked': {
					  color: 'var(--gloomy-purple)',
					},
				}}
			/>
			<div className = {"TextStyle3 " + colorClass} >Any Time</div>
			<HelpButton hoverText={"Check this box if employees can schedule and complete the conectivity any time throughout the day at their convenience"}  
				hoverTextClassName='TextStyle4 hoverTextStyle1 hoverTextStyle1ExtraWide' /> 
		</div> 
	) ;
}

export const DisplayRecurrenceSelect = ({introText, helpText, repeatOption, repeatOptionChangeHandler, weekOption, weekOptionHandler, 
   monthCheck1, monthCheck2, monthCheck1Handler, monthCheck2Handler, monthOption1, monthOption1Handler,
   monthOption2, monthOption2Handler, monthOption3, monthOption3Handler, 
   repeatUntil, repeatUntilHandler, isDisabled, forceClosedTrigger, hideRepeatUntil, isHorizontalLine, isClearable, isLableText}) => {

	const [openEndBy,setOpenEndBy] = useState(false);

	//useEffect by which the outisde world can tell us to close the selection
	useEffect (() => {
		// console.log("forceClosedTrigger", forceClosedTrigger);
		setOpenEndBy(false);
	},[forceClosedTrigger]);
  
	// Conditionally hide the clear (X) icon if there are selected users
    if (isClearable === undefined) {
        isClearable = weekOption.length === 0;
    }

	const handleRemoveChip = (userToRemove) => {
        const updatedSelectedUsers = weekOption.filter(
            (user) => user.value !== userToRemove.value
        );
        weekOptionHandler(updatedSelectedUsers);
    };

   if (!introText) introText = " Would you like this to be a recurring conectivity?";
   if (!helpText) helpText = 'Would this conectivity typically be scheduled as a recurring activity (e.g., daily / weekly / monthly) over a period of time?';

   return (
		<div className='ContainerVerticalLeft fullWidth'>
			<hr className="fullWidth light-grey-border opacity1" />
			<div className="ContainerNoHeightFlexLeftFlexStart noWrap fullWidth">
				<div className ="TextStyle5V2" style={{ zIndex: "6000" }}>
					<span>{introText}  </span>
					<HelpButton hoverText={helpText}  hoverTextClassName='TextStyle4 hoverTextStyle1' className={'light-grey'} noTransform/> 
				</div> 
			</div>
			<div className='fullWidth' style={{ zIndex:"5997", position:"relative"}}>
				{isLableText && (<div className="TextStyle3V2 black-text" style={{paddingBottom:"8px"}} > Recurrence </div>)}
				<Select  isMulti={false} 
					name="repeatDropdown" 
					options={CALENDAR_REPEAT_OPTIONS}                
					onChange={repeatOptionChangeHandler} 
					value={repeatOption} 
					isDisabled={isDisabled}
					placeholder="None"
					isClearable={isClearable}
					styles={{
						control: (base) => ({
							...base,
							backgroundColor:'var(--card-background)',
							color:'var(--page-text)',
							borderRadius: 8,
							minHeight: 45,
						}),
						indicatorSeparator: (baseStyles) => ({
							...baseStyles,
							width: 0,
						}),
						singleValue: (provided) => ({
							...provided,
							color: 'var(--page-text)',
						}),
						option: (provided, state) => ({
							...provided,
							color: state.isSelected ? 'white' : (state.isFocused ? 'white' : 'gray'), // Change option text color
							backgroundColor: state.isSelected ? 'var(--HEADER-blue)' : (state.isFocused ? 'darkgray' : 'var(--card-background)'), // Highlight selected option
						}),
						menuList: (provided) => ({
							...provided,
							backgroundColor:'var(--card-background)',
						}),
					}}
				/>
				{!isLableText && (<div className="TextStyle2 white-background grey-text" style={{position:"absolute", top:"0", left:"20px",  padding:"2px"}} > Recurrence </div>)}
			</div>


			{repeatOption?.value === "WEEKLY" &&
				<div style={{padding:"16px 0 0 0", width:"50%", zIndex:"5995", position:"relative"}}>
					{isLableText && (<div className="TextStyle3V2 black-text"  style={{paddingBottom:"8px"}}> Every </div>)}
					<Select  isMulti={true} 
						name="weeklyRepeatOptionsDropdown" 
						options={WEEK_REPEAT_OPTIONS} 
						onChange={weekOptionHandler}
						isDisabled={isDisabled }
						value={weekOption} 
						placeholder="Every"
						styles={{
							control: (base) => ({
								...base,
								backgroundColor:'var(--card-background)',
								color:'var(--page-text)',
								borderRadius: 8,
								minHeight: 45,
							}),
							indicatorSeparator: (baseStyles) => ({
								...baseStyles,
								width: 0,
							}),
							multiValue: (provided) => ({
								...provided,
								display: 'none',
							}),
							option: (provided, state) => ({
								...provided,
								color: state.isSelected ? 'white' : (state.isFocused ? 'white' : 'gray'), // Change option text color
								backgroundColor: state.isSelected ? 'var(--HEADER-blue)' : (state.isFocused ? 'darkgray' : 'var(--card-background)'), // Highlight selected option
							}),
							menuList: (provided) => ({
								...provided,
								backgroundColor:'var(--card-background)',
							}),
						}}
					/>
						{/* Display selected users as custom chips */}
						<div className="selected-chips">
                {weekOption.map((week) => (
                    <div key={week.value} className="chip">
                        <div className='homeContainer3'>
                            <span className="TextStyle3V2 bold"  style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap"}}>{week.label}</span>   
                        </div>
                        <span
                            className="remove-chip"
                            onClick={() => handleRemoveChip(week)}
                        >
                            &times;
                        </span>
                    </div>
                ))}
            </div>
			{!isLableText && (<div className="TextStyle2 white-background grey-text"  style={{position:"absolute", top:"0", left:"20px", padding:"2px"}} > Every </div>)}

				</div>
			}

			{repeatOption?.value !== "MONTHLY" ? null :
				<div className='ContainerVerticalStartStart fullWidth monthlyViewWrapper'>
					<div className="ContainerNoHeightCenter fullWidth" style={{ zIndex:"5990", gap: 14 }}>
						<div className='ContainerNoHeightFlexEnd fullWidth'>
							<Checkbox
								disabled={isDisabled }
								checked={monthCheck1}
								onChange={()=>{
									monthCheck1Handler(!monthCheck1);
									monthCheck2Handler(!monthCheck2);
								}}
								inputProps={{ 'aria-label': 'controlled' }}
								sx={{
									'&.Mui-checked': {
										color: 'var(--gloomy-purple)',
									},
								}}
							/>
							
							<div className='fullWidth' style={{ position:"relative" }}>
								{isLableText && (<div className="TextStyle3V2 black-text" style={{paddingBottom:"8px"}}> On the </div>)}
								<Select  isMulti={false} 
									name="monthlyRepeatOptionsDropdown_2" 
									options={MONTH_REPEAT_OPTIONS_2} 
									onChange={monthOption2Handler} 
									isDisabled={isDisabled }
									value={monthOption2} 
									placeholder="- select -"
									styles={{
										control: (base) => ({
											...base,
											backgroundColor:'var(--card-background)',
											color:'var(--page-text)',
											borderRadius: 8,
											minHeight: 45,
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											width: 0,
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'var(--page-text)',
										}),
										option: (provided, state) => ({
											...provided,
											color: state.isSelected ? 'white' : (state.isFocused ? 'white' : 'gray'), // Change option text color
											backgroundColor: state.isSelected ? 'var(--HEADER-blue)' : (state.isFocused ? 'darkgray' : 'var(--card-background)'), // Highlight selected option
										}),
										menuList: (provided) => ({
											...provided,
											backgroundColor:'var(--card-background)',
										}),
									}}
								/>
								{!isLableText && (<div className="TextStyle2 white-background grey-text" style={{position:"absolute", top:"0", left:"20px", padding:"2px"}} > On the </div>)}
							</div>
						</div>
						
						<div className='fullWidth' style={{ position:"relative" }}>
							{isLableText && (<div className="TextStyle3V2 black-text" style={{paddingBottom:"8px"}}> Connectivity End </div>)}
							<Select  isMulti={false} 
								name="monthlyRepeatOptionsDropdown_3" 
								options={MONTH_REPEAT_OPTIONS_3} 
								isDisabled={isDisabled }
								onChange={monthOption3Handler} 
								value={monthOption3} 
								placeholder="- select -"
								styles={{
									control: (base) => ({
										...base,
										backgroundColor:'var(--card-background)',
										color:'var(--page-text)',
										borderRadius: 8,
										minHeight: 45,
									}),
									indicatorSeparator: (baseStyles) => ({
										...baseStyles,
										width: 0,
									}),
									singleValue: (provided) => ({
										...provided,
										color: 'var(--page-text)',
									}),
									option: (provided, state) => ({
										...provided,
										color: state.isSelected ? 'white' : (state.isFocused ? 'white' : 'gray'), // Change option text color
										backgroundColor: state.isSelected ? 'var(--HEADER-blue)' : (state.isFocused ? 'darkgray' : 'var(--card-background)'), // Highlight selected option
									}),
									menuList: (provided) => ({
										...provided,
										backgroundColor:'var(--card-background)',
									}),
								}}
							/>
						</div>
					</div>
					<hr className="fullWidth light-grey-border opacity1" />
					<div className="ContainerNoHeightFlexLeft fullWidth" style={{ zIndex:"5980", gap: 14  }}>
						<div className='ContainerNoHeightFlexEnd' style={{ width: "50%" }}>

							<Checkbox
								disabled={isDisabled }
								checked={monthCheck2}
								onChange={()=>{
								monthCheck1Handler(!monthCheck1);
								monthCheck2Handler(!monthCheck2);                
								}}
								inputProps={{ 'aria-label': 'controlled' }}
								sx={{
									'&.Mui-checked': {
										color: 'var(--gloomy-purple)',
									},
								}}
							/>
							
							<div style={{width: '100%', position:"relative" }}>
								{isLableText && (<div className="TextStyle3V2 black-text" style={{paddingBottom:"8px"}}> On this day </div>)}
								<Select  isMulti={false} 
									name="monthlyRepeatOptionsDropdown" 
									isDisabled={isDisabled }
									options={MONTH_REPEAT_OPTIONS} 
									onChange={monthOption1Handler} 
									value={monthOption1} 
									placeholder="- select day -"
									styles={{
										control: (base) => ({
											...base,
											backgroundColor:'var(--card-background)',
											color:'var(--page-text)',
											borderRadius: 8,
											minHeight: 45,
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											width: 0,
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'var(--page-text)',
										}),
										option: (provided, state) => ({
											...provided,
											color: state.isSelected ? 'white' : (state.isFocused ? 'white' : 'gray'), // Change option text color
											backgroundColor: state.isSelected ? 'var(--HEADER-blue)' : (state.isFocused ? 'darkgray' : 'var(--card-background)'), // Highlight selected option
										}),
										menuList: (provided) => ({
											...provided,
											backgroundColor:'var(--card-background)',
										}),
									}}
								/>
									
								{!isLableText && (<div className="TextStyle2 white-background grey-text"  style={{position:"absolute", top:"0", left:"20px", padding:"2px"}} > On this day </div>)}
							</div>
						</div>
					</div>
				</div>  
			}

			{!hideRepeatUntil && repeatOption?.value !== "NONE" &&

				<div style={{paddingTop: 16, zIndex:"5970", width:"100%"}} onClick={(e)=>{e.stopPropagation();}}>
					{isLableText && (<div className="TextStyle3V2 black-text" style={{ paddingBottom:"clamp(4px,1vw,10px)"}} > End by </div>)}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							// minDate={NOW()}
							// maxdate={SIX_MONTHS_FROM_NOW()}
							open={openEndBy}
							onOpen={() => setOpenEndBy(true)}
							disabled={isDisabled }
							// label="End by"
							value={repeatUntil}
							onChange={(newDateValue) => {
								setOpenEndBy(false);
								if (repeatUntilHandler) repeatUntilHandler(newDateValue);
								if (DEBUG_MODE >= 2) console.log("End launch dates by:", newDateValue);
							}}
							className="width-50"
							slotProps={{
								textField: {
									sx: {
										borderRadius: '8px',
										overflow: 'hidden',
										'& .MuiInputBase-root.MuiOutlinedInput-root': {
											borderRadius: '8px',
											minHeight: 42,
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'var(--border-color-V2)',
											},
										},
									}
								},
							}}
							// renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			}
		</div>
	);
}


//Function for determinine whether a LR instance end DATE is in the past, i.e., prior to TODAY for the user
//eventEndDateTime is a UTC time
//Note - this version of the function does not use the TZ input param as moment(eventEndDateTime) returns that time in the user's TZ and moment() returns the current time for the user in their browser environment
//If an ALL day event then we simply need to subtract a day for the event as by definition all day events are marked to end the next day
export function isEndDateInPast({eventEndDateTime, allDayEvent}) {
	if (!eventEndDateTime) return false;

	//If NOT an allDayEvent then simply test whether the end date/time in the browser's local tz is prior to today in that tz, which moment handles by default
	if (!allDayEvent) return moment(eventEndDateTime).isBefore(moment(),'day');
	else 	
		{
		//So, this is an all-day or multi-day event, so construct new moments ignoring the time
		//We do this because the end date/time may be the beginning of the next day in a different TZ which may still be today for the current user
		// const eventEndDateOnlyString =moment(eventEndDateTime).format('YYYY-MM-DD');	
		const eventEndDateOnlyString = eventEndDateTime.slice(0,10);	
		if (DEBUG_MODE > 1) console.log("isEndDateInPast: Constructed new date string", eventEndDateOnlyString);
		//Generate new moments in the current user's local tz
		let eventEndDateTimeMom = moment(eventEndDateOnlyString);		 					
		if (DEBUG_MODE > 1) console.log("Constructed new date only moment in users local TZ", eventEndDateTimeMom.format());
		return moment(eventEndDateTimeMom).subtract(1,'day').isBefore(moment(),'day');
	}
}

/*
//Function for determinine whether a LR instance end DATE is in the past, i.e., prior to TODAY for the user
//eventEndDateTime is a UTC time
export function isEndDateInPast({eventEndDateTime, allDayEvent, timeZoneCode}) {
	if (!eventEndDateTime) return false
	let endDay = moment(eventEndDateTime).tz(timeZoneCode ? timeZoneCode : 'America/New_York').endOf('day'); // Set the end day to the end of the day on which the challeng is to end in the current user's TZ
	if (DEBUG_MODE > 1) console.log("Checking whether end date is in the past.  eventEndDateTime=", eventEndDateTime, allDayEvent, timeZoneCode);
	if (DEBUG_MODE > 1) console.log(" End of day in user's TZ in UTC format", endDay.toISOString(), timeZoneCode);
	if (allDayEvent) {
		endDay.subtract(1,'day');  //By definition, all day events are set to end on at the beginning of the day FOLLOWING the last day
		if (DEBUG_MODE > 1) console.log("Detected All Day Event.  Adjusted End day=", endDay.toISOString());
	}
	const startOfToday =  moment().tz(timeZoneCode ? timeZoneCode : 'America/New_York').startOf('day');
	if (DEBUG_MODE > 1) console.log("Start of TODAY in user's TZ in UTC format", startOfToday.toISOString());
	const isInPreviousDay = endDay.isBefore(startOfToday); 	//Is that day in the past?
	if (DEBUG_MODE > 1) console.log("Comparing End day to start of today.");
	if (DEBUG_MODE > 1) {if (isInPreviousDay) console.log("Determined event end date IS in the past."); else console.log("Determed event end date is NOT in the past");}
	return isInPreviousDay;	

}
*/


//This function formats a string for the expiration days/time until a particular Moment time in the future
export function displayExpiration (timeLeft) {
	
	// if (DEBUG_MODE >= 2) console.log("Duration as days", timeLeft.clone().asDays());
	
	try {
		var returnString ="";
		
		//Check to see if we do not yet have any expiration times, like on first rendering of page?
		if (!timeLeft) {
			return "";
			}
		else if (timeLeft.clone().asMinutes() <= 0) {
			return "expired";
			
		} else if (timeLeft.clone().asDays() > 1) {
			
			returnString = Math.round(timeLeft.clone().asDays()) + " d " + Math.round(timeLeft.clone().hours()) + " h " + Math.round(timeLeft.clone().minutes()) + " m " + Math.round(timeLeft.clone().seconds()) + " s ";
			// console.log ("Displaying time", returnString);
			return returnString;
				
		} else if (timeLeft.clone().asHours() > 1) {
			returnString = Math.round(timeLeft.clone().hours()) + " h " + Math.round(timeLeft.clone().minutes()) + " m " + Math.round(timeLeft.clone().seconds()) + " s ";
			return returnString;
		} else {
			returnString = Math.round(timeLeft.clone().minutes()) + " m " + Math.round(timeLeft.clone().seconds()) + " s ";
			return returnString;
		}

	} catch (err) { 
		if (DEBUG_MODE >= 2) console.log('error formating conectivity expiration timer', err); 
	}
}

//This function generates a calendar recurrance array from our lunchRule object
//The array includes a list of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event, as specified in RFC554
// 
// https://datatracker.ietf.org/doc/rfc5545/
//
// https://freetools.textmagic.com/rrule-generator 
//   (for checking syntax)
//

//This function receives one of our LaunchRules and returns an RRULE object that can be manipulated
//https://github.com/jakubroztocil/rrule


/* STARTED THIS - NOT YET COMPLETE
export function generateRuleRuleObject (launchRule) {

	//Safety Check
	if (!launchRule || !launchRule.eventStartDateTime) {
		if (DEBUG_MODE >= 3)  console.log("Error - improper params", launchRule);
		return null;
	}

   //Init rrule params
	let freq, dtstart, interval, wkst, count, until, bysetpos, bymonth, bymonthday, byyearday, byweekno, byweekday=[];

	//Explicitly confirm this is a recurring launch rule
	if (launchRule.launchRepeatOption === 2 || launchRule.launchRepeatOption === 3 || launchRule.launchRepeatOption === 4) {
	
		//Set initial params
		dtstart = launchRule.eventStartDateTime; 
		until = launchRule.launchUntilDate; 
		interval = 1;
		count = launchRule.launchUntilCount;
		freq = CALENDAR_REPEAT_OPTIONS[launchRule.launchRepeatOption].rruleIndex;   //Set the frequency - required
		
		
		//Set the Weekdays
		if (launchRule.launchRepeatWeeklyOptions && launchRule.launchRepeatWeeklyOptions.length > 0) {
			 for (var i=0; i < launchRule.launchRepeatWeeklyOptions.length-1; i++ ) {
				byweekday.push(WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1].rruleIndex);
			 }
			 if (DEBUG_MODE >= 2) console.log("Generated Weekday options", byweekday);
		}
	   

	} else {
		if (DEBUG_MODE >= 3)  console.log("Non-recurring launch rule.  Returning NULL", launchRule);
		return null;
	}
}

*/

/*
	  Internet Calendaring and Scheduling Core Object Specification (iCalendar)
	  
	  https://datatracker.ietf.org/doc/html/rfc5545
	  
	  The UNTIL rule part defines a DATE or DATE-TIME value that bounds
	  the recurrence rule in an inclusive manner.  If the value
	  specified by UNTIL is synchronized with the specified recurrence,
	  this DATE or DATE-TIME becomes the last instance of the
	  recurrence.  The value of the UNTIL rule part MUST have the same
	  value type as the "DTSTART" property.  Furthermore, if the
	  "DTSTART" property is specified as a date with local time, then
	  the UNTIL rule part MUST also be specified as a date with local
	  time.  If the "DTSTART" property is specified as a date with UTC
	  time or a date with local time and time zone reference, then the
	  UNTIL rule part MUST be specified as a date with UTC time. 

	  The date with UTC time, or absolute time, is identified by a LATIN
	  CAPITAL LETTER Z suffix character, the UTC designator, appended to
	  the time value.  For example, the following represents January 19,
	  1998, at 0700 UTC:

	   DTSTART:19970714T133000                   ; Local time
	   DTSTART:19970714T173000Z                  ; UTC time
	   
*/

export function constructRecurrenceRule ({launchRule, calendarType}) {
	
	// if (DEBUG_MODE >= 3) console.log("Generating recurrence rule for launchRule for calendar type" + calendarType, launchRule);
	
	// HANDLE THE UNTIL DATE
	// Construct a new moment object from the launch end date string.  We need to gen an RRULE end date in a particular format
	// IF DSTART includes a TIME then must include a TIME of FullCalendar assumes 12:00:00 am, which is the EXCLUSIVE of the last day in the series
	// https://stackoverflow.com/questions/58636600/fullcalendar-rrule-until-not-being-inclusive
	let tempLaunchEndDate;
	if (calendarType ==="FULLCALENDAR") tempLaunchEndDate=moment(launchRule.launchUntilDate).format("YYYYMMDDT235959");     //
	else tempLaunchEndDate=moment(launchRule.launchUntilDate).format("YYYYMMDD");
	

	//HANDLE DTSART    
	let rruleStartDateString = "";
	if (launchRule.launchRepeatOption >1 && calendarType === "FULLCALENDAR") {
		//form a start date in UTC Z that conforms to the RRULE standard (20221104T050000Z); ISO UTC format; no offset; strip off milliseconds; remove all occurences of '-' and ':'
		// rruleStartDateString = getRecurrenceRuleNextLaunchDate(launchRule).replace(/\.\d+Z/,'Z').replace(/-/g, '').replace(/:/g, '');  
		// rruleStartDateString = moment(launchRule.eventStartDateTime).format('YYYYMMDD');  //NOT INCLUDING A TIME ELEMENT; ONLY PROVIDING A START DAY
		// rruleStartDateString = moment(launchRule.eventStartDateTime).format('YYYYMMDDThhmmss') + "Z";  //This didn't work as it produces local time in the browser
		// rruleStartDateString = moment(launchRule.eventStartDateTime).toISOString();  //This didn't work as it produces an ISO string with periods and colons
		rruleStartDateString = launchRule.eventStartDateTime.replace(/\.\d+Z/,'Z').replace(/-/g, '').replace(/:/g, '');     //Simply take our existing ISO string and remove the periods and colons
		
		if (rruleStartDateString === "EXPIRED") {
			rruleStartDateString = "";
		} 
			//     if (DEBUG_MODE >= 3) console.log("NOW in ISO", moment().toISOString());
			//     if (DEBUG_MODE >= 3) console.log("RRULE next event date:", rruleStartDateString);
	}
	
	
	// if (DEBUG_MODE >= 3) console.log("Constructed Temp Launch End Date", tempLaunchEndDate);

	var recurrenceRule = "";    //Google requires an array of recurrence rules - https://developers.google.com/calendar/api/concepts/events-calendars#:~:text=happen%20only%20once.-,Recurrence%20rule,should%20be%20repeated%20over%20time).
	var recurrenceObject = {    //MSFT requires an object - https://learn.microsoft.com/en-us/graph/api/resources/patternedrecurrence?view=graph-rest-1.0 
		pattern: {},            //Pattern object
		range:{                 //Range object - for now, just repeating until an end date.  TODO - add ability to end after N occurrences
			type:"endDate",
			endDate:tempLaunchEndDate,
		},               //Range object
	};
	
	switch (launchRule.launchRepeatOption) {
		//No repeat - return blank string
		case 1: 
			// if (DEBUG_MODE >= 3) console.log("NO recurrence option");
			break;
		//DAILY
		case 2: 
			recurrenceRule =  "FREQ=DAILY;UNTIL=" + tempLaunchEndDate; //GOOGLE
			recurrenceObject.pattern.type = "daily";    //MSFT
			recurrenceObject.pattern.interval = 1;    //MSFT - every day
			if (DEBUG_MODE >= 3) console.log("DAILY recurrence option");
			break;
		
		//WEEKLY
		case 3: 
			// if (DEBUG_MODE >= 3) console.log("WEEKLY recurrence option");
			if (launchRule.launchRepeatWeeklyOptions.length == 0) {
					recurrenceRule = "";
					if (DEBUG_MODE >= 3) console.log("Error: Launch weekly - no days specified");
			} else {
				
				recurrenceObject.pattern.type = "weekly";   //MSFT
				recurrenceObject.pattern.interval = 1;    //MSFT - every occurrence of the day by weeks
				recurrenceObject.pattern.daysOfWeek = []; //MSFT - init array of strings
				recurrenceRule =  "FREQ=WEEKLY;INTERVAL=1;UNTIL=" + tempLaunchEndDate  +";BYDAY=";    //GOOGLE

				for (var i=0; i < launchRule.launchRepeatWeeklyOptions.length-1; i++ ) {
					var specifiedDay = (WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1].label).toUpperCase();
					var specifiedDayShort = specifiedDay.substring(0,2);
					// if (DEBUG_MODE >= 3) console.log("WEEKLY - specified day", specifiedDay, specifiedDayShort);
					recurrenceRule = recurrenceRule + specifiedDayShort + ",";
					recurrenceObject.pattern.daysOfWeek.push(specifiedDay);

				}
				
				var lastSpecifiedDay = (WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[launchRule.launchRepeatWeeklyOptions.length-1]-1].label).toUpperCase();
				var lastSpecifiedDayShort = lastSpecifiedDay.substring(0,2);
				// if (DEBUG_MODE >= 3) console.log("WEEKLY - LAST specified day", lastSpecifiedDay, lastSpecifiedDayShort);
				// recurrenceRule = recurrenceRule + lastSpecifiedDayShort  + ";";
				recurrenceRule = recurrenceRule + lastSpecifiedDayShort;
				recurrenceObject.pattern.daysOfWeek.push(lastSpecifiedDay);
			}
	
			break;
		
		//MONTHLY
		case 4: 
			// if (DEBUG_MODE >= 3) console.log("MONTHLY recurrence option");
 
			if (launchRule.launchMonthOption1Checked) { //Monthly by rule?

				recurrenceObject.pattern.type = "relativeMonthly";  //MSFT - 
				recurrenceObject.pattern.interval = 1;              
				recurrenceObject.pattern.daysOfWeek = []; //MSFT - init array of strings
			
			   //Special option - Looking for the 1st (or 2nd ...) "weekday"of a given month
				if (launchRule.launchRepeatMonthlyOptions_3 === 7) {
					// MSFT - Index Specifies on which instance of the allowed days specified in daysOfWeek the event occurs, counted from the first instance in the month. 
					// The possible values are: first, second, third, fourth, last. Default is first. 
					recurrenceObject.pattern.index = MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].fullLabel;     //MSFT - on the 1st, 2nd. etc weekday

					//GOOGLE
					recurrenceRule = "FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=" + (launchRule.launchRepeatMonthlyOptions_2).toString();
			
				} else {
					//Repeat monthly every 1st/2nd etc. MON/TUE etc. of a given month
					recurrenceRule =  "FREQ=MONTHLY;UNTIL=" + tempLaunchEndDate  +";BYDAY=" + (launchRule.launchRepeatMonthlyOptions_2).toString()  + (MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].label).substring(0,2).toUpperCase()  ;
 
					// MSFT - INDEX Specifies on which instance of the allowed days specified in daysOfWeek the event occurs, counted from the first instance in the month. 
					// The possible values are: first, second, third, fourth, last. Default is first. 
 
					recurrenceObject.pattern.index = MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].fullLabel;              //MSFT - on the 1st, 2nd. etc weekday

					//MSFT - 'daysOfWeek' A collection of the days of the week on which the event occurs. The possible values are: sunday, monday, tuesday, wednesday, thursday, friday, saturday
					recurrenceObject.pattern.daysOfWeek.push((MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].label).toLowerCase()); //MSFT push the day of week string, example: 'monday, tuesday'
				}
				
			} else if (launchRule.launchMonthOption2Checked) { //Monthly by day?

				recurrenceObject.pattern.type = "absoluteMonthly";  //MSFT Absolute Monthly type
				recurrenceObject.pattern.interval = 1;              //MSFT - every month
				recurrenceObject.pattern.dayOfMonth = launchRule.launchRepeatMonthlyOptions+1;  


				recurrenceRule =  "FREQ=MONTHLY;UNTIL=" + tempLaunchEndDate  +";BYMONTHDAY="  + (launchRule.launchRepeatMonthlyOptions+1).toString();
			}
			break;
		
	}

	// if (DEBUG_MODE >= 3) console.log("Built recurrence rule for Google calendar: ", recurrenceRule);
	// if (DEBUG_MODE >= 3) console.log("Built recurrence object for MSFT calendar: ", recurrenceObject);
	
	let returnParam;
	if (calendarType == "GOOGLE") returnParam = ["RRULE:" + recurrenceRule];    //Google requires an array of one or more recurrence rules
	if (calendarType == "MS365") returnParam = recurrenceObject;                //MS365 requires an object
	if (calendarType == "FULLCALENDAR") {
		
		returnParam = 'DTSTART:' + rruleStartDateString + "\nRRULE:" + recurrenceRule;   //Our FULL CALENDAR React component for viewing events requires a single recurrence rule string
		// if (DEBUG_MODE >= 3) console.log("RRULE for FULL CALENDAR", returnParam);
	}
	
	return returnParam;
}

//Build a natural language representation of the recurrence for UI display
export function buildRecurrenceString (launchRule) {
	
	// if (DEBUG_MODE >= 3) console.log("Building recurrence string for launch rule", launchRule);
	
	var recurrenceString = "";
	const launchUntilDateString = formatDate(launchRule.launchUntilDate);
	const eventStartDateTimeString = formatDate(launchRule.eventStartDateTime);
	
	switch (launchRule.launchRepeatOption) {
		//No repeat
		case 1: 
			recurrenceString = CALENDAR_REPEAT_OPTIONS[0].label;
			break;
		
		//DAILY
		case 2: 
			recurrenceString = CALENDAR_REPEAT_OPTIONS[1].label  + ' from ' + eventStartDateTimeString + " until " + launchUntilDateString;
			break;
		
		//WEEKLY
		case 3: 
			if (launchRule.launchRepeatWeeklyOptions.length == 0) recurrenceString = "Launch weekly - no days specified";
			else {
				recurrenceString = "Launch weekly every ";
				for (var i=0; i < launchRule.launchRepeatWeeklyOptions.length-1; i++ ) {
					var specifiedDay = WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1].label;
					// if (DEBUG_MODE >= 3) console.log("WEEKLY - specified day", specifiedDay);
					recurrenceString = recurrenceString + specifiedDay + ", ";
				}
				
				var lastSpecifiedDay = WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[launchRule.launchRepeatWeeklyOptions.length-1]-1].label;
				recurrenceString = recurrenceString + lastSpecifiedDay + ' from ' + eventStartDateTimeString  + " until " + launchUntilDateString;
			}
	
			break;
		
		//MONTHLY
		case 4: 
			if (launchRule.launchMonthOption1Checked) { //Monthly by rule?
				recurrenceString = "Launch monthly on the " + MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].label + " " + MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].label  + ' from ' + eventStartDateTimeString + " until " + launchUntilDateString;
			} else if (launchRule.launchMonthOption2Checked) { //Monthly by day?
				 recurrenceString = "Launch monthly on the " + MONTH_REPEAT_OPTIONS[launchRule.launchRepeatMonthlyOptions-1].label  + ' from ' + eventStartDateTimeString + " until " + launchUntilDateString;
			}
			break;
		
	}

	// if (DEBUG_MODE >= 3) console.log("Built recurrence string: " + recurrenceString);
	
	return recurrenceString;
}

//This function returns an array of launch dates for the launch rule but just from TODAY forward - replaced with function below
/*
export function getrecurrenceRuleLaunchDates(launchRule) {


	// if (DEBUG_MODE >= 3) console.log("Evaluating launch rule", launchRule);
	
	const TODAY = moment();
	const launchDates = [];
	var eventStartDateTimeString = launchRule.eventStartDateTime;
	var eventStartDateTime = moment(launchRule.eventStartDateTime, "YYYY MM DDTHH mm ssZ");
	var launchUntilDateString = launchRule.launchUntilDate;
	var launchUntilDate = moment(launchRule.launchUntilDate, "YYYY MM DDTHH mm ssZ");

	// if (DEBUG_MODE >= 3) console.log("Evaluating launch rule", TODAY.toISOString(), launchRule, eventStartDateTimeString, launchUntilDateString);

	//Already expired?  If so, return an empty array    
	if (launchUntilDate.isBefore(TODAY, "day")) {
		// if (DEBUG_MODE >= 3) console.log("Expired Launch Rule", launchRule);
		return launchDates;
	}
	
	switch (launchRule.launchRepeatOption) {
		//No repeat
		case 1: 
			launchDates.push(eventStartDateTimeString); //Will only be launched on the specified start date
			break;
		
		//DAILY
		case 2: 

		   //Walk every day from LATER of START of period or TODAY to end of period or until 14 days; no real need to generate more at this time
		   
			var momentDateDayPointer =eventStartDateTime.clone().startOf('day');  //Get the start of the week of the first launch
			if (momentDateDayPointer.isBefore(TODAY,"day")) momentDateDayPointer=TODAY.clone();
			var outerLoopCount = 0;
			var bizDaysAdded = 0;

			// if (DEBUG_MODE >= 3) console.log("Generating DAILY Launch dates", momentDateDayPointer.toISOString(), TODAY.toISOString());
		  
			//Walk up the 10 days or until end of period
			 while (!momentDateDayPointer.isAfter(launchUntilDate) && outerLoopCount < 14 && bizDaysAdded <= 10) {
		   
				if (momentDateDayPointer.day() > 0 && momentDateDayPointer.day() < 6) {
					launchDates.push(momentDateDayPointer.toISOString());   
					bizDaysAdded++;
					// if (DEBUG_MODE >= 3) console.log("DAILY Launch date added for weekday", momentDateDayPointer.toISOString());
				}
 
				//Update outer DAY loop
				momentDateDayPointer = momentDateDayPointer.add(1,"day");
				outerLoopCount++;
			 }
				
				
			break;
		
		//WEEKLY
		case 3: 
			
			//Walk every week from start of period to end of period
			var momentDateWeekPointer =eventStartDateTime.clone().startOf('week');  //Get the start of the week of the first launch
			var outerLoopCount = 0;
		  
			//Walk up the 30 weeks or until end of period
			while (!momentDateWeekPointer.isAfter(launchUntilDate) && outerLoopCount < 30) {

				// if (DEBUG_MODE >= 3) console.log("Examining week " + momentDateWeekPointer.toISOString() + "Looking for ", launchRule.launchRepeatWeeklyOptions);
			 
				if (launchRule.launchRepeatWeeklyOptions.length != 0) {
					
					const todayDayOfWeek = TODAY.day();
					
					for (var i=0; i < launchRule.launchRepeatWeeklyOptions.length; i++ ) {
						
						const specifiedDayOfWeek = WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1].id-1; //Get the specified day in Day of Week format (0 to 6)
						// if (DEBUG_MODE >= 3) console.log("Retrieved specified week day", WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1], specifiedDayOfWeek);
						
						//Now, push the correct next occurence of that day
						var dayPointerInWeekPointer = momentDateWeekPointer.clone().add(specifiedDayOfWeek,'days');  //Get that day (like TUESDAY) of the week to which we are pointing
						
						if (!dayPointerInWeekPointer.isBefore(TODAY,'day')) {
							launchDates.push(dayPointerInWeekPointer.toISOString());  //Push that day 
							// if (DEBUG_MODE >= 3) console.log("Weekly Launch date added", dayPointerInWeekPointer.toISOString());
						}
					}
				}
				//Update outer WEEK loop
				momentDateWeekPointer = momentDateWeekPointer.add(1,"week");
				outerLoopCount++;
				
			}
	
			break;
		
		//MONTHLY
		case 4: 
			
			//Walk every month from start of period to end of perio
			var momentDateMonthPointer =eventStartDateTime.clone().startOf('month'); //Get the start of the month of the first launch
			var outerLoopCount = 0;
		  
			//Walk up the 12 months or until end of period
			while (!momentDateMonthPointer.isAfter(launchUntilDate) && outerLoopCount < 12) {
				
				if (launchRule.launchMonthOption1Checked) { //Monthly by rule?

					const occurenceToFind = MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].id;     //Which occurrence (1st, 2nd, etc.) in 1-5;
					const specifiedDayOfWeek = MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].id-1; //Get the specified day in Day of Week format (0 to 6) OR EXTRA FEW AT BOTTOM OF ARRAY
					
					//First, find the occurrence in the current month of our outer loop
					var momentDatePointer = momentDateMonthPointer.clone().startOf('month'); 
					var momentDatePointerEndOfMonth = momentDateMonthPointer.clone().endOf('month'); 
					var numberOfOccurencesFound = 0;
					
					var occurenceFound = false;
					var loopCount = 0;
	
					// if (DEBUG_MODE >= 3) console.log("Examining month.  Looking for " + occurenceToFind + " occurences of weekday " + specifiedDayOfWeek + " for date pointer:" + momentDatePointer.toISOString());
					
					//Loop through this month to find the specified occurences
					while (!occurenceFound && !momentDatePointer.isAfter(momentDatePointerEndOfMonth,"day") && loopCount < 40) {
						
						// if (DEBUG_MODE >= 3) console.log("Checking occurrences.  Date pointer:" + momentDatePointer.toISOString() + "  Day:" + momentDatePointer.day());
						
						//Are we looking for this day of week?
						if (specifiedDayOfWeek < 7) { 
							if (specifiedDayOfWeek == momentDatePointer.day()) numberOfOccurencesFound++; //Found an occurrence of the day
						} else {
							//Handle the special case of first (or second ..)  Weekday in the month
							if (momentDatePointer.day() > 0 && momentDatePointer.day() < 7) numberOfOccurencesFound++; //Found an occurrence of a weekday
						}
						
						if (numberOfOccurencesFound == occurenceToFind) {
							occurenceFound = true;
							// if (DEBUG_MODE >= 3) console.log("Found the specified number of occurrences", momentDatePointer.toISOString());
							//Now,if we found the particular occurrence, before storing, check whether the desired occurence has already passed in this month.  If so, skip until next month in our outer loop; otherwise add
							if (!momentDatePointer.isBefore(TODAY,"day")) {
								launchDates.push(momentDatePointer.toISOString());  //Push that day
								// if (DEBUG_MODE >= 3) console.log("Monthly launch date added", momentDatePointer.toISOString());
							}                           
						}
						
						loopCount++;
						momentDatePointer = momentDatePointer.add(1,"day");
						
					}
					
				} else if (launchRule.launchMonthOption2Checked) { //Monthly by day?
	 
					 const specifiedDayOfMonth = MONTH_REPEAT_OPTIONS[launchRule.launchRepeatMonthlyOptions-1].id; //Get the specified day in Day of Month format (0 to 30)
					 if (DEBUG_MODE >= 3) console.log("Retrieved specified MONTH day", specifiedDayOfMonth);
	 
					//Now, push that specific date if not yet passed
					const specifiedDayOfMonthMoment = momentDateMonthPointer.clone().date(specifiedDayOfMonth);
					// if (DEBUG_MODE >= 3) console.log("Monthy: checking:" + specifiedDayOfMonthMoment.toISOString());
					if (!specifiedDayOfMonthMoment.isBefore(TODAY,"day")) {
						 launchDates.push(specifiedDayOfMonthMoment.toISOString());  //Push that day of the month
						//  if (DEBUG_MODE >= 3) console.log("Monthly launch date added", specifiedDayOfMonthMoment.toISOString());
				   }
				}
				
				//Update outer MONTH loop
				momentDateMonthPointer = momentDateMonthPointer.add(1,"month");
				outerLoopCount++;
			}
			break;
		
	}

	// if (DEBUG_MODE >= 3) console.log("Evaluated launch rule.  Generated launch dates.", launchRule, launchDates);
	
	return launchDates;    
}
*/

//This function returns an array of launch dates over the ENTIRE span of the launch rule
export function getrecurrenceRuleLaunchDates(launchRule) {


	// console.log("Evaluating launch rule", launchRule);
	
	const TODAY = moment();
	const launchDates = [];
	var eventStartDateTimeString = launchRule.eventStartDateTime;
	var eventStartDateTime = moment(launchRule.eventStartDateTime);
	var launchUntilDate = moment(launchRule.launchUntilDate).endOf('day');
	var launchUntilDateString = moment(launchUntilDate).toISOString();

	if (DEBUG_MODE >= 3) console.log("Evaluating launch rule", TODAY.toISOString(), launchRule, eventStartDateTimeString, launchUntilDateString);

	//Already expired?  If so, return an empty array    
	if (launchUntilDate.isBefore(TODAY, "day")) {
		// console.log("Expired Launch Rule", launchRule);
		return launchDates;
	}
	
	let outerLoopCount;
	switch (launchRule.launchRepeatOption) {
		//No repeat
		case 1: 
			launchDates.push(eventStartDateTimeString); //Will only be launched on the specified start date
			break;
		
		//DAILY
		case 2: 

		   //Walk every day from START of period to end of period 
		   
			var momentDateDayPointer =eventStartDateTime.clone().startOf('day');  //Get the start of the day
			outerLoopCount = 0;

			console.log("Generating DAILY Launch dates", momentDateDayPointer.toISOString(), TODAY.toISOString());
		  
			//Walk up the 365 days or until the day after end of period
			 while (!momentDateDayPointer.isAfter(launchUntilDate,'day') && outerLoopCount < 356 ) {
				launchDates.push(momentDateDayPointer.toISOString());   
				if (DEBUG_MODE >= 3)  console.log("DAILY Launch date added", momentDateDayPointer.toISOString());

				//Update outer DAY loop
				momentDateDayPointer = momentDateDayPointer.add(1,"day");
				outerLoopCount++;
			 }
				
				
			break;
		
		//WEEKLY
		case 3: 
			
			if (launchRule.launchRepeatWeeklyOptions.length !== 0) {

				//Walk every week from start of period to end of period
				var momentDateWeekPointer =eventStartDateTime.clone().startOf('week');  //Get the start of the week of the first launch
				outerLoopCount = 0;
			  
				//Walk up the 52 weeks or until end of period
				while (!momentDateWeekPointer.isAfter(launchUntilDate) && outerLoopCount < 52) {
	
					// console.log("Examining week " + momentDateWeekPointer.toISOString() + "Looking for ", launchRule.launchRepeatWeeklyOptions);

					for (var i=0; i < launchRule.launchRepeatWeeklyOptions.length; i++ ) {
						
						const specifiedDayOfWeek = WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1].id-1; //Get the specified day in Day of Week format (0 to 6)
						// console.log("Retrieved specified week day", WEEK_REPEAT_OPTIONS[launchRule.launchRepeatWeeklyOptions[i]-1], specifiedDayOfWeek);
						
						//Now, push the correct next occurence of that day
						var dayPointerInWeekPointer = momentDateWeekPointer.clone().add(specifiedDayOfWeek,'days');  //Get that day (like TUESDAY) of the week to which we are pointing
						launchDates.push(dayPointerInWeekPointer.toISOString());  //Push that day 
							// console.log("Weekly Launch date added", dayPointerInWeekPointer.toISOString());
					} //END FOR EACH SELECTED WEEK DAY
					
					//Update outer WEEK loop
					momentDateWeekPointer = momentDateWeekPointer.add(1,"week");
					outerLoopCount++;
					
				} // END WHILE
			} // END IF WEEK DAY OPTIONS
	
			break;
		
		//MONTHLY
		case 4: 
			
			//Walk every month from start of period to end of period for 2 years
			var momentDateMonthPointer =eventStartDateTime.clone().startOf('month'); //Get the start of the month of the first launch
			outerLoopCount = 0;
		  
			//Walk up the 12 months or until end of period
			while (!momentDateMonthPointer.isAfter(launchUntilDate) && outerLoopCount < 24) {
				
				if (launchRule.launchMonthOption1Checked) { //Monthly by rule?

					const occurenceToFind = MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].id;      //Which occurrence (1st, 2nd, etc.) in 1-5;
					const specifiedDayOfWeek = MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].id-1; //Get the specified day in Day of Week format (0 to 6) OR EXTRA FEW AT BOTTOM OF ARRAY
					
					//First, find the occurrence in the current month of our outer loop
					var momentDatePointer = momentDateMonthPointer.clone().startOf('month'); 
					var momentDatePointerEndOfMonth = momentDateMonthPointer.clone().endOf('month'); 
					var numberOfOccurencesFound = 0;
					
					var occurenceFound = false;
					var loopCount = 0;
	
					// console.log("Examining month.  Looking for " + occurenceToFind + " occurences of weekday " + specifiedDayOfWeek + " for date pointer:" + momentDatePointer.toISOString());
					
					//Loop through each day of this month to find the specified occurences
					while (!occurenceFound && !momentDatePointer.isAfter(momentDatePointerEndOfMonth,"day") && loopCount < 40) {
						
						// console.log("Checking occurrences.  Date pointer:" + momentDatePointer.toISOString() + "  Day:" + momentDatePointer.day());
						
						//Are we looking for this day of week?
						if (specifiedDayOfWeek < 7) { 
							if (specifiedDayOfWeek == momentDatePointer.day()) numberOfOccurencesFound++; //Found an occurrence of the day
						} else {
							//Handle the special case of first (or second ..)  Weekday in the month
							if (momentDatePointer.day() > 0 && momentDatePointer.day() < 7) numberOfOccurencesFound++; //Found an occurrence of a weekday
						}
						
						if (numberOfOccurencesFound == occurenceToFind) {
							occurenceFound = true;
							// console.log("Found the specified number of occurrences", momentDatePointer.toISOString());
							//Now,if we found the particular occurrence, before storing, check whether the desired occurence has already passed in this month.  If so, skip until next month in our outer loop; otherwise add
							launchDates.push(momentDatePointer.toISOString());  //Push that day
							// console.log("Monthly launch date added", momentDatePointer.toISOString());
						}
						
						loopCount++;
						momentDatePointer = momentDatePointer.add(1,"day");
						
					}
					
				} else if (launchRule.launchMonthOption2Checked) { //Monthly by day?
	 
					 const specifiedDayOfMonth = MONTH_REPEAT_OPTIONS[launchRule.launchRepeatMonthlyOptions-1].id; //Get the specified day in Day of Month format (0 to 30)
					//  console.log("Retrieved specified MONTH day", specifiedDayOfMonth);
	 
					//Now, push that specific date if not yet passed
					const specifiedDayOfMonthMoment = momentDateMonthPointer.clone().date(specifiedDayOfMonth);
					// console.log("Monthy: checking:" + specifiedDayOfMonthMoment.toISOString());
					launchDates.push(specifiedDayOfMonthMoment.toISOString());  //Push that day of the month
					//  console.log("Monthly launch date added", specifiedDayOfMonthMoment.toISOString());
				   
				}
				
				//Update outer MONTH loop
				momentDateMonthPointer = momentDateMonthPointer.add(1,"month");
				outerLoopCount++;
			}
			break;
		
	}

	// console.log("Evaluated launch rule.  Generated launch dates.", launchRule, launchDates);
	
	return launchDates;    
}



//This function returns the next scheduled launch date for a given rule, including today, in ISO format
//Note, the next launch date is the event start date if in the FUTURE or TODAY
//If in the past, return 'EXPIRED' as the LR won't fire again

export function getRecurrenceRuleNextLaunchDate(launchRule) {

	var launchDate = "";

	//Safety Check
	if (!launchRule) {
		if (DEBUG_MODE >= 1) console.log("Error - improper params", launchRule);
		return launchDate;
	}

	try {    
		const TODAY = moment().startOf("day");
		let eventStartDateTimeString = launchRule.eventStartDateTime;
		let eventStartDateTime = moment(launchRule.eventStartDateTime, "YYYY MM DDTHH mm ssZ");
		let launchUntilDate = moment(launchRule.launchUntilDate, "YYYY MM DDTHH mm ssZ");
	
		// if (DEBUG_MODE >= 2) console.log("Evaluating launch rule to determine next launch date", TODAY.toISOString(), launchRule, eventStartDateTimeString);
		
		//Threshold check 
		//  - if non-recurring and event END is before today then expired (added 7.24.24)
		//  - if a recurring launch and the launch UNTIL date is before TODAY or before the event start date, then the LR has expired and won't be launched again
		 if ((launchRule.launchRepeatOption === 1) && eventStartDateTime.isBefore(TODAY, 'day') ||
			(launchRule.launchRepeatOption > 1 && (launchUntilDate.isBefore(TODAY, 'day') || launchUntilDate.isBefore(eventStartDateTime, 'day')))) {
			if (DEBUG_MODE >= 3) console.log("Launch until date is before event Start Date of Launch Rule or before today; mark expired");
			return 'EXPIRED';
		}        
	
		//Is the event start date today or in the future?  If so, that will be the next launch date regardless of the recurrence rule
		if (eventStartDateTime.isAfter(TODAY)) {
			// if (DEBUG_MODE >= 3) console.log("Event Start Date of Launch Rule is today or in the future; using it as next launch date", launchRule);
			return moment(eventStartDateTimeString).toISOString();
		}   
		

		switch (launchRule.launchRepeatOption) {
			//No repeat
			case 1: 
				launchDate = moment(eventStartDateTimeString).toISOString(); //Will only be launched on the specified start date
				break;
			
			//DAILY
			case 2: 
				 if (eventStartDateTime.isAfter(TODAY,"day")) {
					launchDate = moment(eventStartDateTimeString).toISOString(); //Next launch is the start day of the launch period
				 } else {
					 launchDate = TODAY.toISOString(); //Launch every day, including today
				 }
				break;
			
			//WEEKLY
			case 3: 
				
				if (launchRule.launchRepeatWeeklyOptions.length !== 0) {     //Missing options?
				
					//set our pointer to start at the LATER of TODAY or the START DATE of the term
					var momentDateWeekPointer = TODAY.clone();  //Get today 
					if (momentDateWeekPointer.isBefore(eventStartDateTime,"day")) momentDateWeekPointer=eventStartDateTime.clone();
				
					var loopCount =0;
					var foundMatch = false;
	 
					 //Now, walk forward up to 8 days until we find a DAY that is in our options or we have run past the launch until date
					while (loopCount < 8 && !foundMatch && !momentDateWeekPointer.isAfter(launchUntilDate)) {
						//Does current pointer match our array of options?
						const dayOfWeek = momentDateWeekPointer.clone().day(); //Get DAY (0-6)
						foundMatch = launchRule.launchRepeatWeeklyOptions.includes(dayOfWeek+1);
						if (!foundMatch) momentDateWeekPointer = momentDateWeekPointer.clone().add(1,"days");
						loopCount++;
					 }
					   
					//If found, set that occurrence as the next Launch Date
					if (foundMatch) {
						launchDate = momentDateWeekPointer.toISOString();
						 if (DEBUG_MODE >= 3) console.log("Weekly next launch date match found", launchDate);
					} else {
						 if (DEBUG_MODE >= 3) console.log("NO Weekly next launch date match found", launchDate);
					}
				}
		
				break;
			
			//MONTHLY
			case 4: 
				
				//Get the start of THIS month or the launch date, in case it starts in a later month
				var momentDateMonthPointer =TODAY.clone().startOf('month'); //Get the start of this month 
				if (momentDateMonthPointer.isBefore(eventStartDateTime,"month")) momentDateMonthPointer=eventStartDateTime.clone.startOf('month');
				
				
				var outerLoopCount = 0;
				var occurenceFound = false;
			  
				//Walk up to 2 months or until end of period or until we find a match
				while (!momentDateMonthPointer.isAfter(launchUntilDate) && outerLoopCount < 2 && !occurenceFound) {
					
					if (launchRule.launchMonthOption1Checked) { //Monthly by rule?
	
						const occurenceToFind = MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].id;     //Which occurrence (1st, 2nd, etc.) in 1-5;
						const specifiedDayOfWeek = MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].id-1; //Get the specified day in Day of Week format (0 to 6) OR EXTRA FEW AT BOTTOM OF ARRAY
						
						//First, find the occurrence in the current month of our outer loop
						var momentDatePointer = momentDateMonthPointer.clone().startOf('month'); 
						var momentDatePointerEndOfMonth = momentDateMonthPointer.clone().endOf('month'); 
						var numberOfOccurencesFound = 0; //Found this month
						
						occurenceFound = false;
						var skipMonth = false; //Skip the month if the occurence is found but it is in the past
						var loopCount = 0; //safety check
		
						if (DEBUG_MODE >= 3) console.log("Examining month.  Looking for " + occurenceToFind + " occurences of weekday " + specifiedDayOfWeek + " for date pointer:" + momentDatePointer.toISOString());
						
						//Walk until the end of this month to find the specified occurence
						while (!skipMonth && !occurenceFound && !momentDatePointer.isAfter(momentDatePointerEndOfMonth,"day") && loopCount < 40) {
							
							// if (DEBUG_MODE >= 3) console.log("Checking occurrences.  Date pointer:" + momentDatePointer.toISOString() + "  Day:" + momentDatePointer.day());
							
							//Are we looking for this day of week?
							if (specifiedDayOfWeek < 7) { 
								if (specifiedDayOfWeek == momentDatePointer.day()) numberOfOccurencesFound++; //Found an occurrence of the day
							} else {
								//Handle the special case of first (or second ..)  Weekday in the month
								if (momentDatePointer.day() > 0 && momentDatePointer.day() < 7) numberOfOccurencesFound++; //Found an occurrence of a weekday
							}
							
							if (numberOfOccurencesFound == occurenceToFind) {
								// occurenceFound = true;
								// if (DEBUG_MODE >= 3) console.log("Found the specified number of occurrences", momentDatePointer.toISOString());
								//Now,if we found the particular occurrence, before storing, check whether the desired occurence has already passed in this month.  If so, skip until next month in our outer loop; otherwise add
								if (!momentDatePointer.isBefore(TODAY,"day")) {
									launchDate = momentDatePointer.toISOString();  //Push that day
									occurenceFound = true;
									// if (DEBUG_MODE >= 3) console.log("Monthly launch date found", launchDate);
								}  else {
									skipMonth = true;
									// if (DEBUG_MODE >= 3) console.log("Monthly launch date found; before today; SKIPPING MONTH", launchDate);
	
								}                         
							}
							
							loopCount++;
							momentDatePointer = momentDatePointer.add(1,"day");
							
						}
						
					} else if (launchRule.launchMonthOption2Checked) { //Monthly by day?
		 
						 const specifiedDayOfMonth = MONTH_REPEAT_OPTIONS[launchRule.launchRepeatMonthlyOptions-1].id; //Get the specified day in Day of Month format (0 to 30)
						 if (DEBUG_MODE >= 3) console.log("Retrieved specified MONTH day", specifiedDayOfMonth);
		 
						//Now, push that specific date if not yet passed
						const specifiedDayOfMonthMoment = momentDateMonthPointer.clone().date(specifiedDayOfMonth);
						// if (DEBUG_MODE >= 3) console.log("Monthy: checking:" + specifiedDayOfMonthMoment.toISOString());
						if (!specifiedDayOfMonthMoment.isBefore(TODAY,"day")) {
							launchDate = specifiedDayOfMonthMoment.toISOString();  //Push that day
							occurenceFound = true;
							// if (DEBUG_MODE >= 3) console.log("Monthly launch date found", launchDate);
						}
					}
					
					//Update outer MONTH loop
					momentDateMonthPointer = momentDateMonthPointer.add(1,"month");
					outerLoopCount++;
				}
				break;
			default:
				if (DEBUG_MODE >= 3) console.log("ERROR - incorrect launch date repeat option");
			
			
		}
	
		if (launchDate) {
			// if (DEBUG_MODE >= 2) console.log("Evaluated launch rule.  Generated next launch date:" + launchDate, launchRule);
		} else {
			// if (DEBUG_MODE >= 2) console.log("Evaluated launch rule.  NO NEXT LAUNCH DATE!", launchRule);
		}
	} catch (err) {
		if (DEBUG_MODE >= 1) console.log("Error - cannot process Launch Rule", launchRule, err);
	}

	return launchDate;    
}

//This function evaluates a rule and determines whether today is a launch day for a given rule
export function isRecurrenceRuleLaunchToday(launchRule) {

	
	try {
		//Safety check
		if (!launchRule || !launchRule.eventStartDateTime) return false;
		
		// if (DEBUG_MODE >= 3) console.log("Evaluating launch rule to determine whether to launch date", launchRule);
 
		
		const TODAY = moment().startOf("day");
		var launchToday = false;
		var eventStartDateTimeString = launchRule.eventStartDateTime;
		var eventStartDateTime = moment(launchRule.eventStartDateTime, "YYYY MM DDTHH mm ssZ");
		var launchUntilDateString = launchRule.launchUntilDate;
		var launchUntilDate = moment(launchRule.launchUntilDate, "YYYY MM DDTHH mm ssZ");
	
		if (DEBUG_MODE >= 3) console.log("Evaluating launch rule to test launch TODAY", TODAY.toISOString(), launchRule, eventStartDateTimeString, launchUntilDateString);
	
		//Already expired?  If so, return false  
		if (launchUntilDate.isBefore(TODAY, "day")) {
			// if (DEBUG_MODE >= 3) console.log("Expired Launch Rule", launchRule);
			return false;
		}
	
		//Not yet started?  If so, return false  
		if (eventStartDateTime.isAfter(TODAY, "day")) {
			// if (DEBUG_MODE >= 3) console.log("Launch Rule Not Yet Started", launchRule);
			return false;
		}    
		
		switch (launchRule.launchRepeatOption) {
			//No repeat
			case 1: 
				launchToday = eventStartDateTime.isSame(TODAY,"day");  //Will only be launched on the specified start date
				break;
			
			//DAILY
			case 2: 
				launchToday = true; //Launch every day, including today
				break;
			
			//WEEKLY
			case 3: 
				if (launchRule.launchRepeatWeeklyOptions.length == 0) return false;         //Missing options?
				const dayOfWeek = TODAY.day();                                              //Get current DAY (0-6)
				launchToday = launchRule.launchRepeatWeeklyOptions.includes(dayOfWeek+1); //Return TRUE if today's DAY is within the array of days [1-7]
				break;
			
			//MONTHLY
			case 4: 
				
			   //Get the start of THIS month or the launch date, in case it starts in a later month
				var momentDateMonthPointer =TODAY.clone().startOf('month'); //Get the start of this month 
				if (momentDateMonthPointer.isBefore(eventStartDateTime,"month")) momentDateMonthPointer=eventStartDateTime.clone.startOf('month');
	
				var outerLoopCount = 0;
				var occurenceFound = false;
			  
				//Walk up to 2 months or until end of period or until we find a match
				while (!momentDateMonthPointer.isAfter(launchUntilDate) && outerLoopCount < 2 && !occurenceFound) {
					
					if (launchRule.launchMonthOption1Checked) { //Monthly by rule?
	
						const occurenceToFind = MONTH_REPEAT_OPTIONS_2[launchRule.launchRepeatMonthlyOptions_2-1].id;     //Which occurrence (1st, 2nd, etc.) in 1-5;
						const specifiedDayOfWeek = MONTH_REPEAT_OPTIONS_3[launchRule.launchRepeatMonthlyOptions_3-1].id-1; //Get the specified day in Day of Week format (0 to 6) OR EXTRA FEW AT BOTTOM OF ARRAY
						
						//First, find the occurrence in the current month of our outer loop
						var momentDatePointer = momentDateMonthPointer.clone().startOf('month'); 
						var momentDatePointerEndOfMonth = momentDateMonthPointer.clone().endOf('month'); 
						var numberOfOccurencesFound = 0; //Found this month
						
						occurenceFound = false;
						var skipMonth = false; //Skip the month if the occurence is found but it is in the past
						var loopCount = 0; //safety check
		
						if (DEBUG_MODE >= 3) console.log("Examining month.  Looking for " + occurenceToFind + " occurences of weekday " + specifiedDayOfWeek + " for date pointer:" + momentDatePointer.toISOString());
						
						//Walk until the end of this month to find the specified occurence
						while (!skipMonth && !occurenceFound && !momentDatePointer.isAfter(momentDatePointerEndOfMonth,"day") && loopCount < 40) {
							
							// if (DEBUG_MODE >= 3) console.log("Checking occurrences.  Date pointer:" + momentDatePointer.toISOString() + "  Day:" + momentDatePointer.day());
							
							//Are we looking for this day of week?
							if (specifiedDayOfWeek < 7) { 
								if (specifiedDayOfWeek == momentDatePointer.day()) numberOfOccurencesFound++; //Found an occurrence of the day
							} else {
								//Handle the special case of first (or second ..)  Weekday in the month
								if (momentDatePointer.day() > 0 && momentDatePointer.day() < 7) numberOfOccurencesFound++; //Found an occurrence of a weekday
							}
							
							if (numberOfOccurencesFound == occurenceToFind) {
								// occurenceFound = true;
								if (DEBUG_MODE >= 3) console.log("Found the specified number of occurrences", momentDatePointer.toISOString());
								//Now,if we found the particular occurrence, before storing, check whether the desired occurence has already passed in this month.  If so, skip until next month in our outer loop; otherwise add
								if (!momentDatePointer.isBefore(TODAY,"day")) {
									launchToday = momentDatePointer.isSame(TODAY,"day");  //today?
									occurenceFound = true;
									if (DEBUG_MODE >= 3) console.log("Monthly launch date found.  Launch today:", launchToday, momentDatePointer.toISOString());
								}  else {
									skipMonth = true;
								}                         
							 }
							
							loopCount++;
							momentDatePointer = momentDatePointer.add(1,"day");
							
						}
						
					} else if (launchRule.launchMonthOption2Checked) { //Monthly by day?
		 
						 const specifiedDayOfMonth = MONTH_REPEAT_OPTIONS[launchRule.launchRepeatMonthlyOptions-1].id; //Get the specified day in Day of Month format (0 to 30)
						 if (DEBUG_MODE >= 2) console.log("Retrieved specified MONTH day", specifiedDayOfMonth);
		 
						//Now, push that specific date if not yet passed
						const specifiedDayOfMonthMoment = momentDateMonthPointer.clone().date(specifiedDayOfMonth);
						if (DEBUG_MODE >= 2) console.log("Monthy: checking:" + specifiedDayOfMonthMoment.toISOString());
						if (!specifiedDayOfMonthMoment.isBefore(TODAY,"day")) {
									launchToday = specifiedDayOfMonthMoment.isSame(TODAY,"day");  //today?
									occurenceFound = true;
									if (DEBUG_MODE >= 3) console.log("Monthly launch date found", launchToday, specifiedDayOfMonthMoment.toISOString());
					  }
					}
					
					//Update outer MONTH loop
					momentDateMonthPointer = momentDateMonthPointer.add(1,"month");
					outerLoopCount++;
				}
				
				break;
			
		}
	
		if (launchToday) {
			// if (DEBUG_MODE >= 3) console.log("Evaluated launch rule.  Launch TODAY");
		} else {
			// if (DEBUG_MODE >= 3) console.log("Evaluated launch rule.  DO NOT LAUNCH TODAY!");
	
		}
	} catch (err) {
		if (DEBUG_MODE >= 1) console.log("ERROR evaluating launch rule", err, launchRule);
	}
	
	return launchToday;    
}

//Function to compute new end date in AWS datetime format for a given category type
//Used when a period has ended and the user is now in a new period and our backend data needs to be updated
export function getNewEndDate (period) {

	var newEndDate = moment();  // get time set in user's computer time / OS
	
	if (period === "DAILY") {
										// leave
	} else if (period == "WEEKLY") {
		newEndDate.endOf('week');

	} else if (period == "MONTHLY") {
		newEndDate.endOf('month');

	} else if (period == "QUARTERLY") {
		newEndDate.endOf('quarter');

	} else if (period == "YEARLY") {
		newEndDate.endOf('year');
	} else {
		console.log ("Error - incorrect period.  Cannot set new end date");
	}

	//set end time to 1 minute before midnight on the last day of the period
	var returnString = newEndDate.format("YYYY-MM-DDT23:59:59Z");
	// console.log ("New end day set for ,", period, returnString);
	return returnString;
}
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, DAVY_GREY, LIGHT_GREY, BALANCE_COLOR, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, COLOR_WHITE, COLOR_BLACK, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, COLOR_GOLD, COLOR_SILVER, COLOR_BRONZE, 
	COLOR_ALERT_OPAQUE, COLOR_WARNING_HIGH_OPAQUE, COLOR_WARNING_LOW_OPAQUE, COLOR_GOOD_OPAQUE, COLOR_ALERT, COLOR_WARNING_HIGH, COLOR_WARNING_LOW, COLOR_GOOD } from '../data/conectereConfigData';


//React & Amplify
import React from 'react';
import { useState, useRef, useEffect, useContext } from "react";
import {useLocation} from 'react-router-dom'
import parse from "html-react-parser";              //Needed to render HTML from the Editor

//Context
import { DisplayContext } from '../context/displayContext';            //User Authentication Context

//Utils
import {  setScopeBackground, setHeaderColorByStringLength } from "./conectivityUtils";
import { NOW, NOW_STRING } from "./dateTimeUtils";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL;
import moment from 'moment';

//Icons
import { Edit, DeleteOutline, Equalizer} from '@material-ui/icons';
import CloudIcon from '@mui/icons-material/Cloud';
import RedeemIcon from '@mui/icons-material/Redeem';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HighlightIcon from '@mui/icons-material/Highlight';
import RadarIcon from '@mui/icons-material/Radar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PreviewIcon from '@mui/icons-material/Preview';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import SavingsIcon from '@mui/icons-material/Savings';
import CellTowerIcon from '@mui/icons-material/CellTower';
import LinkIcon from '@mui/icons-material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import HubIcon from '@mui/icons-material/Hub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/Send';
import GifIcon from '@mui/icons-material/Gif';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CloseIcon from '@mui/icons-material/Close';
import IsoIcon from '@mui/icons-material/Iso';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PublishIcon from '@mui/icons-material/Publish';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import MessageIcon from '@mui/icons-material/Message';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AttachFileIcon from '@mui/icons-material/AttachFile';

//Material UI and my components therefrom
import { IconButton } from '@mui/material';


export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  
  var result = Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  
//   if (DEBUG_MODE >= 2) console.log("getRandomInt called", min, max, result);
  
  return result;
}


export function truncateStringWithDots (str, n) {
	return str?.length > n ? str.substr(0, n-1) + "..." : str;
}


export function truncateStringByWord (str, n, withDots){
   
	  if (str.length < n) return str; //nothing to truncate

	
	  var substring = str.substr(0,n); //truncate at n to start with
	  var index=substring.length-1;
	  while(substring[index]!=" "){
		if(index<0) break;
		  index--;
	  }
	  substring = substring.substr(0,index+1);
	  
	  
	  return (withDots ? substring +  "..." : substring);
  }
  
  
export function truncateIntegerByThousand (n) {
  
  if (n < 1000) return n;
  
  var numThou = Math.round(n/1000 * 10) / 10;
  
  // console.log ("Truncated by thousand utility", n, numThou);
  
  return (numThou.toString(10) + "k");
  
  
}

export  function  setRankBackground(rank, numItems) {
	const distFromBottom = numItems-rank;  //Rank from TOP
	
	//Return background color based on quartile 
	// Q1 is bottom quartile ... Q4 is top quartile 
	const q1 = (numItems / 4);
	const q2 = (numItems / 2);
	const q3 =  (3 * numItems / 4);

	// if (DEBUG_MODE >= 2) console.log("Setting background color by rank", rank, numItems, distFromBottom, q1, q2, q3);

	if (distFromBottom < q1) return COLOR_ALERT;  //RED
	if (distFromBottom < q2) return COLOR_WARNING_HIGH;  //ORANGE
	if (distFromBottom < q3) return COLOR_WARNING_LOW;  //YELLOW
	return COLOR_GOOD;  //GREEN
	
}   

export  function  setScoreBackground(score) {
	   //Return background color based on quartile of SCORE

		const q1 = (6 / 4);
		const q2 =(6 / 2);
		const q3 =  (3 * 6 / 4);

		if (score < q1) return COLOR_ALERT;
		if (score < q2) return COLOR_WARNING_HIGH;
		if (score < q3) return COLOR_WARNING_LOW;
		return COLOR_GOOD;       	
}   

export  function  setBackgroundByRawScore(score, maxScore) {
	//Return background color based on quartile 
	// Q1 is bottom quartile ... Q4 is top quartile 
	const q1 = (maxScore / 4);
	const q2 = (maxScore / 2);
	const q3 =  (3 * maxScore / 4);

	if (DEBUG_MODE >= 2) console.log("Setting background color by rank", score, maxScore, q1, q2, q3);

	if (score < q1) return COLOR_ALERT;  //RED
	if (score < q2) return COLOR_WARNING_HIGH;  //ORANGE
	if (score < q3) return COLOR_WARNING_LOW;  //YELLOW
	return COLOR_GOOD;  //GREEN
}   

// Function to group an array of objects having and equal value for a specified field attribute
function groupBy(objectArray, attribute) {

	//safety check
	if (!objectArray || objectArray.length === 0) return [];

	const isNumber = (typeof objectArray[0][attribute] === 'number');	//If the attribute is a number, set flag so we can convert it back below

	const groups = objectArray.reduce((accumulator, obj) => {
		const key = obj[attribute];
		if (!accumulator[key]) {
			accumulator[key] = [];
		}
		// Add object to list for given key's value
		accumulator[key].push(obj);
		return accumulator;
	}, {});
	console.log("Reduced to leader GROUPS ", groups);

	const groupArrays = Object.keys(groups).map(value => {
		let tempValue = (isNumber ? parseInt(value) : value);	//Convert based on Type if necessary since the above reduction makes the attribute value a key string
		return {
		  attribute,							//The field (attribute) on which we have grouped
		  value:tempValue,					//The attribute value for this group
		  members: groups[value],			//An array of members having this value for the attribute
		};
	 });
	console.log("Built array of leader GROUPS ", JSON.stringify(groupArrays));
	return groupArrays;
}

export function displayRankModifier(rank) {
	if (!rank || rank <0) return "";
	switch (rank) {
		case 1: return "st";
		case 2: return "nd";
		case 3: return "rd";
		default: return "th";
	}
}



//Display expiration time for the Conectivity Preview
export function displayExpirationTime (days) {
	return days + " d " + 0 + " h " + 0 + " m " + 0 + " s ";
}   

//Function to format day or days depending on number
export function formatDays(d) {
	if (d<0) return "";
	else if (d<2) return d + " day";
	else return d + " days";
}

export function formatDateAgoString(sharedDateString) {
  
//   if (DEBUG_MODE >= 2) console.log("Shared Date:", sharedDateString);
  const now = moment();
  const sharedDate = moment(sharedDateString, "YYYY MM DDTHH mm ssZ");


	var minDiff = now.clone().diff(sharedDate, 'minutes');
	// if (DEBUG_MODE >= 2) console.log('Hours:' + hourDiff);

	var hourDiff = now.clone().diff(sharedDate, 'hours');
	// if (DEBUG_MODE >= 2) console.log('Hours:' + hourDiff);
 
	var dayDiff = now.clone().diff(sharedDate, 'days');
	// if (DEBUG_MODE >= 2) console.log('Days:' + dayDiff);
  
	var monthDiff = now.clone().diff(sharedDate, 'months');
	// if (DEBUG_MODE >= 2) console.log('Month:' + monthDiff);
  
	var yearDiff = now.clone().diff(sharedDate, 'years');
	// if (DEBUG_MODE >= 2) console.log('Year:' + yearDiff);
	
	if (minDiff <= 1) return minDiff.toString() + " minute ago";
	if (minDiff < 60) return minDiff.toString() + " minutes ago";
	if (hourDiff === 1) return hourDiff.toString() + " hour ago";
	if (hourDiff < 24) return hourDiff.toString() + " hours ago";
	if (dayDiff === 1) return dayDiff.toString() + " day ago";
	if (dayDiff > 1 && monthDiff === 0) return dayDiff.toString() + " days ago";
	if (monthDiff === 1) return monthDiff.toString() + " month ago";
	if (monthDiff < 24) return monthDiff.toString() + " months ago";
	if (yearDiff === 1) return yearDiff.toString() + " year ago";
	if (yearDiff > 1) return yearDiff.toString() + " years ago";
	
	return "";

}

export function formatDate(inputTime) {
	
	const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
	var formattedDateTime = tempTime.format("MMM DD, YYYY");
	// if (formattedDateTime === "Invalid date") if (DEBUG_MODE >= 2) console.log("Invalid date", inputTime);
	return formattedDateTime;

}

export function formatDateWithDayOfWeek(inputTime) {
	
	// if (DEBUG_MODE >= 2) console.log("Formatting date", inputTime);
	
	if (inputTime === "EXPIRED") return "EXPIRED";
	
	const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
	return tempTime.format("ddd, MMM Do");

}
	
//Functions for  sorting objects based on CreatedAt; oldest to newest
export function compareByCreatedAt(a, b) {

	 //Safety check
	if (!a || !b) return 0;
	
	const aDateTime = moment(a.createdAt);
	const bDateTime = moment(b.createdAt);
	if (aDateTime.isAfter(bDateTime))  return 1;
	if (bDateTime.isAfter(aDateTime)) return -1;
	return 0;
}

//Functions for  sorting objectes based on CreatedAt;  newest to oldest
export function compareByCreatedAtReverseChronological(a, b) {
	if (!a || !a.createdAt || !b || !b.createdAt) return 0;
	const aDateTime = moment(a.createdAt);
	const bDateTime = moment(b.createdAt); 
	if (aDateTime.isAfter(bDateTime))  return -1;
	if (bDateTime.isAfter(aDateTime)) return 1;
	return 0;
}

export function compareRandomly(a, b) {
	//Sort RANDOMLY so as to give the user variety
	//Random number between 1 and 10 (inclusive)
	var randNum =  Math.floor(Math.random() * 10) + 1;
	if (randNum < 6)  return 1; else  return -1;
}  


//Function for sorting by NAME attribute
export function compareByName(a, b) {
	if (!a || !a.name || !b || !b.name) return 0;
	if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
	if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
	return 0;
}   

//Function for sorting by contactName attribute
export function compareByContactName(a, b) {
	if (!a || !a.contactName || !b || !b.contactName) return 0;
	if (a.contactName.toUpperCase() > b.contactName.toUpperCase()) return 1;
	if (a.contactName.toUpperCase() < b.contactName.toUpperCase()) return -1;
	return 0;
}   



//Function for sorting by LABEL attribute
export function compareByLabel(a, b) {
	if (!a || !a.label || !b || !b.label) return 0;
	if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
	if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
	return 0;
} 


//Functions for  sorting objectes based on LaunchDate attribute; oldest to newest
export function compareByLaunchDate(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
	
	const aDateTime = moment(a.launchDate);
	const bDateTime = moment(b.launchDate);
	
	if (aDateTime.isAfter(bDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (bDateTime.isAfter(aDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	return 0;

}

//Functions for  sorting objectes based on LaunchDate attribute; oldest to newest
export function compareByNextLaunchDateISO(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
	
	if (a.nextLaunchDateISO === 'EXPIRED' && b.nextLaunchDateISO === 'EXPIRED') return 0;
	if (b.nextLaunchDateISO === 'EXPIRED') return 1;
	if (a.nextLaunchDateISO === 'EXPIRED') return -1;
	
	const aDateTime = moment(a.nextLaunchDateISO);
	const bDateTime = moment(b.nextLaunchDateISO);
	
	if (aDateTime.isAfter(bDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (bDateTime.isAfter(aDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	return 0;

}


export function compareByAlertTimestamp(a, b) {

	// if (DEBUG_MODE >= 2) console.log("sorting Coulson records:", a.alertTimeStamp, b.alertTimeStamp);   
	
	const aDateTime = moment(a.alertTimeStamp);
	const bDateTime = moment(b.alertTimeStamp);
	
	if (aDateTime.isAfter(bDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
		return 1;
		}
	if (bDateTime.isAfter(aDateTime)) {
		// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
		return -1;
	}
	
	// if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
	return 0;

}

//Sorting special events so oldest is listed first, like on the User Home page
export function compareByEventEndDateReverseChronological(a, b) {
	// console.log("comparing event starts", a,b);
	if (!a || !a.eventEndDateTime || !b || !b.eventEndDateTime) {console.log("Error no event end date", a, b); return 0;}
	const aDateTime = moment(a.eventEndDateTime);
	const bDateTime = moment(b.eventEndDateTime); 
	if (aDateTime.isAfter(bDateTime))  return 1;
	if (bDateTime.isAfter(aDateTime)) return -1;
	return 0;
}

//Oldest last, most in the future first
export function compareByEventEndDate(a, b) {
	// console.log("comparing event starts", a,b);
	if (!a || !a.eventEndDateTime || !b || !b.eventEndDateTime) {console.log("Error no event end date", a, b); return 0;}
	const aDateTime = moment(a.eventEndDateTime);
	const bDateTime = moment(b.eventEndDateTime); 
	if (aDateTime.isAfter(bDateTime))  return -1;
	if (bDateTime.isAfter(aDateTime)) return 1;
	return 0;
}

//Sorting special events so oldest is listed first, like on the User Home page
export function compareByEventStartDateReverseChronological(a, b) {
	// console.log("comparing event starts", a,b);
	if (!a || !a.eventStartDateTime || !b || !b.eventStartDateTime) {console.log("Error no event start date", a, b); return 0;}
	const aDateTime = moment(a.eventStartDateTime);
	const bDateTime = moment(b.eventStartDateTime); 
	if (aDateTime.isAfter(bDateTime))  return 1;
	if (bDateTime.isAfter(aDateTime)) return -1;
	return 0;
}

//Functions for sorting objects based on which EventStartDate
export function compareByEventStartDate(a, b) {
	// console.log("comparing event starts", a,b);
	if (!a || !a.eventStartDateTime || !b || !b.eventStartDateTime) {console.log("Error no event start", a, b); return 0;}
	const aDateTime = moment(a.eventStartDateTime);
	const bDateTime = moment(b.eventStartDateTime); 
	if (aDateTime.isAfter(bDateTime))  return -1;
	if (bDateTime.isAfter(aDateTime)) return 1;
	return 0;
}

//Returns a string to display how long ago a time was
//input
export function timeAgo (dateTimeString) {
		
	// get time set in user's computer time / OS
	const currentTime = moment(); 
   const dateTimeMom = moment(dateTimeString);
	//Calc the duration since this notification was created 
	var timeSince = moment.duration(currentTime.diff(dateTimeMom));

   try {
		var returnString ="";
		
		//Check to see if we do not yet have any expiration times, like on first rendering of page?
		if (!timeSince) {
			return "";
			
		} else if (timeSince.asDays() > 1) {
			
			returnString = Math.floor(timeSince.asDays()).toString() + " days";
			return returnString;
				
		} else if (timeSince.asHours() > 1) {
			returnString = Math.floor(timeSince.hours()).toString() + " hrs";
			return returnString;
		} else {
			returnString = Math.floor(timeSince.minutes()).toString() + " min";
			return returnString;
		}

	} catch (err) { 
		if (DEBUG_MODE >= 2) console.log('error formating time since notification', err); 
	}
	
}

	//Display expiration time for the Conectivity Preview
	//This function formats a string for the expiration days/time until a particular Moment time in the future
	function displayExpiration (timeLeft) {
		
		// if (DEBUG_MODE >= 2) console.log("Duration as days", timeLeft.asDays());
		
		try {
			var returnString ="";
			
			//Check to see if we do not yet have any expiration times, like on first rendering of page?
			if (!timeLeft) {
				return "";
			 }
			else if (timeLeft.asMinutes() <= 0) {
				return "expired";
				
			} else if (timeLeft.asDays() > 1) {
				
				returnString = timeLeft.days() + " d " + timeLeft.hours() + " h " + timeLeft.minutes() + " m " + timeLeft.seconds() + " s ";
				return returnString;
					
			} else if (timeLeft.asHours() > 1) {
				returnString = timeLeft.hours() + " h " + timeLeft.minutes() + " m " + timeLeft.seconds() + " s ";
				return returnString;
			} else {
				returnString = timeLeft.minutes() + " m " + timeLeft.seconds() + " s ";
				return returnString;
			}

		} catch (err) { 
			if (DEBUG_MODE >= 2) console.log('error formating conectivity expiration timer', err); 
		}
   }




export function validImageFileType(file) {
  
	if (!file) return false;
	
	const fileTypes = [
	'image/apng',
	'image/bmp',
	'image/gif',
	'image/jpeg',
	'image/pjpeg',
	'image/png',
	'image/svg+xml',
	'image/tiff',
	'image/webp',
	`image/x-icon`
];
	
  return fileTypes.includes(file.type);
}

//Tabs

//Support for Tabs.  NOTE - THIS MUST BE OUTSIDE PAGE COMPONENT OR IT WILL RE-RENDER AS A CHILD ON EVERY KEYSTROKE, CAUSING IT TO LOSE FOCUS
export const TabPanel = ({children, value, index}) => {

  return (
	<div 
		className = "tabPanel"
		role="tabpanel"
		hidden={value !== index}
		id={`tabpanel-${index}`}
		aria-labelledby={`tabs-${index}`}
	>
	
	{children}
	
	</div>
  );
};



//
//
// MENU DISPLAY FUNCTIONS

export function displayConectivityScoreAndRank(relativeConectivityScore, rank) {


	var medalColor = "white";
	var medalTextColor = "#409cf7";

	// Set color scheme if TOP 3 or current user
	if (rank==1) {
		 medalColor = COLOR_GOLD;
		 medalTextColor = "white";
	}  else if (rank==2) { 
		 medalColor = COLOR_SILVER;
		 medalTextColor = "white";
		
	}  else if (rank==3) {
		 medalColor = COLOR_BRONZE;
		 medalTextColor = "white";
		
	}  
	
	
   return (
		<div className="ContainerNoHeightCenter" style={{width:"100%", height:"100%", position:"relative"}}>
			 <div style={{position:"relative"}}>
				<div className="CardScore" style={{backgroundColor:setScoreBackground(relativeConectivityScore)}}>{relativeConectivityScore.toFixed(1)} </div>    
				<div className="TextStyle3 CardRank" style={{backgroundColor:medalColor, color:medalTextColor}}> 
					<div >{rank}</div> 
					<div className="TextStyle1" style={{transform:"translate(0%,-05%)" }}> {displayRankModifier(rank)} </div>
				</div>
				
			</div>
		</div>

		);    
	
}  


export const DisplayEmployee = ({name, initials, avatarUrl, permissionAnalytics, permissionEditor, permissionLaunch, permissionOrders, permissionBilling}) => {

	return (
		<div className="ContainerMaxHeightFlexLeft" style={{width:"100%"}}>
			 <div >
					<div className="avatarContainer">
						<div className='avatarImage avatarImageMedium' style={{backgroundColor:setHeaderColorByStringLength(name)}}>
							{!avatarUrl ? <div className="avatarInitials" > {initials}</div> : <img className="avatarImageCenterPortrait" src={avatarUrl} alt={initials} /> }
						</div>
					</div>
			</div>
			<div className="ContainerVerticalLeft">
				<div className="TextStyle3" style={{textWrap:"wrap", wordBreak:"break-word"}}>{name}</div>
				
				{displayPermissions({
						permissionAnalytics:permissionAnalytics, 
						permissionEditor:permissionEditor, 
						permissionLaunch:permissionLaunch, 
						permissionOrders:permissionOrders, 
						permissionBilling:permissionBilling,
					})}
			</div>			
		</div>
	);
};  

export const DisplayTeam = ({name, avatarUrl, initials}) => {

	return (
		<div className="ContainerMaxHeightFlexLeft" style={{width:"100%"}}>
			 <div style={{width:"60px", }}>
					<div className="avatarContainer">
						<div className='avatarImage avatarImageMedium' style={{backgroundColor:setHeaderColorByStringLength(name)}}>
							{!avatarUrl ? <div className="avatarInitials" > {initials}</div> : <img className="avatarImageCenterPortrait" src={avatarUrl} alt={initials} /> }
						</div>
					</div>
			</div>
			<div style={{width:"180px", textAlign:"left"}}>
					<div style={{color:"#409cf7", padding:'5px', fontSize:"1.0rem", fontWeight:"400"}}>{name}</div>
			</div>
	   </div>
	);
};

export function displayPermissions({name, avatarUrl, permissionAnalytics, permissionEditor, permissionLaunch, permissionOrders, permissionBilling, color, fontSize}) {

		if (!color) color = TEAM_COLOR;
		if (!fontSize) fontSize = "0.8rem";
		
		return (

				
				<div className="ContainerNoHeightCenter" style={{color:color}}>
					{!permissionAnalytics ? "" : <Equalizer style={{fontSize:fontSize}}/> }
					{!permissionEditor ? "" : <Edit style={{fontSize:fontSize}} /> }
					{!permissionLaunch ? "" : <LaunchIcon color={color} scale={0.5} /> }
					{!permissionOrders ? "" : <RedeemIcon style={{fontSize:fontSize}} /> }
					{!permissionBilling ? "" : <AttachMoneyIcon style={{fontSize:fontSize}} /> }
				</div>
			   
			);
	
} 

//fontSize is either SM, MED or LG or XL, which we translate to CSS classes
export function displayAwards ({enableCountUp, paddingLeft, containerClass, coins, badgesDEI, badgesCS, badgesSpotlight, fontSize, showZeros, includeBackground, darkMode}) {
	try {
		if (!coins) coins = 0;
		if (!badgesDEI) badgesDEI = 0;
		if (!badgesCS) badgesCS = 0;
		if (!badgesSpotlight) badgesSpotlight = 0;
		if (!containerClass) containerClass = "ContainerMaxHeightCenter";
		if (!paddingLeft) paddingLeft = 'clamp(4px,0.2vw,8px)';

		if (!fontSize) fontSize = "MED";
		let fontClass = "TextStyle3", imageClass='ccImgMedium';
		switch (fontSize.toLowerCase()) {
			case 'sm': 
				fontClass = "TextStyle2"; imageClass='ccImgSmall';
				break;
			case 'med':
				fontClass = "TextStyle3";  imageClass='ccImgMedium';
				break;
			case 'lg':
				fontClass = "TextStyle4";  imageClass='ccImgXL';
				break;
			case 'xl':
				fontClass = "TextStyle6";  imageClass='ccImgXXL';
				break;
			default:
				fontClass = "TextStyle3";  imageClass='ccImgMedium';
				break;
		}


	let awardWrapperBackground = '';
	if (includeBackground) awardWrapperBackground = `${darkMode ? 'dark-grey-background' : 'white-background'} headerAwardWrapper boxShadow `;

		return (
				<div className={containerClass + ' ' + fontClass + ' headerBlue'} >
					{!showZeros && (!coins || coins === 0) ? "" :
						<div className="ContainerNoHeightCenter" >
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {coins} disableCountUp={!enableCountUp}  /> 
							</div>
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>
						</div>
					}
					{!showZeros && (!badgesDEI || badgesDEI === 0) ? "" :
						<div className="ContainerNoHeightCenter" style={{paddingLeft:paddingLeft}} >
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {badgesDEI} disableCountUp={!enableCountUp} /> 
							</div>
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI badges'/>
						</div>
						}  
					{!showZeros && (!badgesCS || badgesCS === 0) ? "" :
						<div className="ContainerNoHeightCenter" style={{paddingLeft:paddingLeft}} >
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {badgesCS} disableCountUp={!enableCountUp} /> 
							</div>
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS bages'/>
						</div>
					}
					{!showZeros && (!badgesSpotlight || badgesSpotlight === 0) ? "" :
						<div className="ContainerNoHeightCenter" style={{paddingLeft:paddingLeft}} >
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {badgesSpotlight} disableCountUp={!enableCountUp} /> 
							</div>
							<HighlightIcon className='TextStyle6A' style={{color:"gold", transform: "translate(0,-10%) rotate(-128deg) "}} />
						</div>
					}
				</div>
			);
	} catch (err) {
		if (DEBUG_MODE > 1) console.error("ERROR displaying awards",err);
		return null;
	}
	
}

//fontSize is either SM, MED or LG or XL, which we translate to CSS classes
export function displaySidebarLeftAwards ({enableCountUp, paddingLeft, containerClass, coins, badgesDEI, badgesCS, badgesSpotlight, fontSize, showZeros, includeBackground, darkMode}) {
	try {
		if (!coins) coins = 0;
		if (!badgesDEI) badgesDEI = 0;
		if (!badgesCS) badgesCS = 0;
		if (!badgesSpotlight) badgesSpotlight = 0;
		if (!containerClass) containerClass = "ContainerMaxHeightCenter";
		if (!paddingLeft) paddingLeft = 'clamp(4px,0.2vw,8px)';

		if (!fontSize) fontSize = "MED";
		let fontClass = "TextStyle2", imageClass='awardImgMedium';
		switch (fontSize.toLowerCase()) {
			case 'sm': 
				fontClass = "TextStyle1"; imageClass='awardImgSmall';
				break;
			case 'med':
				fontClass = "TextStyle2";  imageClass='awardImgMedium';
				break;
			case 'lg':
				fontClass = "TextStyle3";  imageClass='awardImgXL';
				break;
			case 'xl':
				fontClass = "TextStyle4";  imageClass='awardImgXXL';
				break;
			default:
				fontClass = "TextStyle2";  imageClass='awardImgMedium';
				break;
		}


	let awardWrapperBackground = '';
	if (includeBackground) awardWrapperBackground = `${darkMode ? 'dark-grey-background' : 'white-background'} navbarAwardWrapper boxShadow black-text`;

		return (
				<div className={containerClass + ' ' + fontClass + ' headerBlue'} >
					{!showZeros && (!coins || coins === 0) ? "" :
						<div className="ContainerNoHeightCenter" id='header-target' >
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  alt='coins'/>
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {coins} disableCountUp={!enableCountUp}  /> 
							</div>
						</div>
					}
					{!showZeros && (!badgesCS || badgesCS === 0) ? "" :
						<div className="ContainerNoHeightCenter" style={{paddingLeft:paddingLeft}} >
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  alt='CS bages'/>
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {badgesCS} disableCountUp={!enableCountUp} /> 
							</div>
						</div>
					}
					{!showZeros && (!badgesDEI || badgesDEI === 0) ? "" :
						<div className="ContainerNoHeightCenter" style={{paddingLeft:paddingLeft}} >
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  alt='DEI badges'/>
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {badgesDEI} disableCountUp={!enableCountUp} /> 
							</div>
						</div>
					}
					{!showZeros && (!badgesSpotlight || badgesSpotlight === 0) ? "" :
						<div className="ContainerNoHeightCenter" style={{paddingLeft:paddingLeft}} >
							<img style={{zIndex:'1'}} className={imageClass} src={CONECTERE_CONFIG_DATA.HL_BADGE_IMAGE_PATH}  alt='CS bages'/>
							<div className={awardWrapperBackground}>
								<CountUpDown valueToDisplay = {badgesSpotlight} disableCountUp={!enableCountUp} /> 
							</div>
						</div>
					}
				</div>
			);
	} catch (err) {
		if (DEBUG_MODE > 1) console.error("ERROR displaying awards",err);
		return null;
	}
	
}

export function displayTransactionAmount ({amount, badgesDEI, badgesCS, badgesSpotlight}) {
	
	return (
		<div className="ContainerNoHeightCenter TextStyle3">
				<span className= {amount<0 ? " debitDataStyle" : " creditDataStyle"}> 

					{amount<0 ? "" : "+"} 
					{amount}  
				</span>
				<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />

			{!badgesDEI || badgesDEI ==0 ? "" :
			<>
				<span className= {badgesDEI<0 ? " debitDataStyle" : " creditDataStyle"}> 
					{badgesDEI<0 ? "" : "+"} 
					{badgesDEI}  
					</span>
					<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
				</>
			}
			{!badgesCS || badgesCS ==0 ? "" :
				<span className= {badgesCS<0 ? " debitDataStyle" : " creditDataStyle"}> 
					{badgesCS<0 ? "" : "+"} 
					{badgesCS}  
					<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
				</span>
			}
			{!badgesSpotlight || badgesSpotlight === 0 ? "" :
				<span>
					{badgesSpotlight} 
					<HighlightIcon className="TextStyle3" style={{color:"gold", transform: "rotate(-128deg)"}} />
				</span>
			}			
		</div>

		);

}


//For formatting conectivity source column in when exported from DataGrid
export function formatSourceColumnValue({value, customerName}) {

	if (DEBUG_MODE >= 2) console.log("Formatting Source Column", value, customerName, );
	
	if (value === "GLOBAL") return "Conectere";
	else if (value === "CUSTOMER_SHARED") return "Community";
	else if (value === "CUSTOMER_SPECIFIC") return customerName;
	else return "Conectere";
	
}



//For rendering conectivity source column in DataGrid
export function displayConectivitySourceColumn({customerID, conectivityScope, createdByCustomerID, customerName, scopeUrl, isSuperAdmin, permissionEditor}) {
 
		// if (DEBUG_MODE >= 2) console.log("Display Source Column called", customerID, conectivityScope, createdByCustomerID, customerName, scopeUrl, isSuperAdmin, permissionEditor);
		
		if (conectivityScope === "GLOBAL")
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<img src={ CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} height='24' /> 
				</div>
				); 
		
		//Shared by a different company        
		else if (conectivityScope === "CUSTOMER_SHARED"  && createdByCustomerID != customerID)
			return displayCommunityText({fontSize:"1.0rem", cloudFontSize:"1.4rem", useUpload:true});

		//Shared by THIS company        
		else if (conectivityScope === "CUSTOMER_SHARED"  && createdByCustomerID === customerID)
			return displayCommunityText({fontSize:"1.0rem", cloudFontSize:"1.4rem", useUpload:true});
			
		//Installed from the Community by THIS company
		else if (conectivityScope === "INSTALLED_FROM_COMMUNITY")
			return displayCommunityText({fontSize:"1.0rem", cloudFontSize:"1.4rem", useDownload:true});
			
		//Drafted by THIS company regardless of scope or drafted by a particular company and this user is a SuperAdmin or Editor, which we will allow to see the drafter company name
		else if (createdByCustomerID === customerID || (createdByCustomerID && (isSuperAdmin || permissionEditor))) {         
			 
			 if (scopeUrl)
				 return (            
					<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
							<img src={scopeUrl} height='24' />
				   </div>);
			else return (
				 
			   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"0.8rem", width:"100%"}}>  {truncateStringWithDots(customerName, 14)} </div>
				); 
				
		//A catch all        
		} else return (
		   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"1.0rem", width:"100%", backgroundColor:setScopeBackground(conectivityScope)}}>  {conectivityScope} 
			</div>
			); 
}


export function displayCommunityTextInline ({useUpload, useDownload}) {
	
	return (<span>
				<span style={{color:BALANCE_COLOR, }}>CO</span>
				<span style={{color:TEAM_COLOR, }}>MM</span>
				<span style={{color:SOCIAL_COLOR, }}>UN</span>
				<span style={{color:GROWTH_COLOR, }}>ITY</span>
				{!useUpload && !useDownload ? <CloudIcon style={{color:TEAM_COLOR, transform:"translate(0,-20%)"}}/> : null}
				{useDownload ? <CloudDownloadIcon style={{color:TEAM_COLOR,  transform:"translate(0,-50%)"}}/> : null}
				{useUpload? <CloudUploadIcon style={{color:TEAM_COLOR, transform:"translate(0,-50%)"}}/> : null}
			 </span>
		);
} 

export function displayCommunityText ({fontSize, cloudFontSize, useUpload, useDownload}) {
	
	if (!fontSize) fontSize = "1.0rem";
	if (!cloudFontSize) cloudFontSize = fontSize;

	return (
			<div className="ContainerNoHeightCenter" style={{fontSize:fontSize}}>
				<div style={{color:BALANCE_COLOR}}> CO </div>
				<div style={{color:TEAM_COLOR}}> MM </div>
				<div style={{color:SOCIAL_COLOR}}>UN </div>
				<div style={{color:GROWTH_COLOR}}> ITY </div>
				{!useUpload && !useDownload ? <CloudIcon style={{color:TEAM_COLOR, fontSize:cloudFontSize, transform:"translate(0,-50%)"}}/> : null}
				{useDownload ? <CloudDownloadIcon style={{color:TEAM_COLOR, fontSize:cloudFontSize, transform:"translate(0,-50%)"}}/> : null}
				{useUpload? <CloudUploadIcon style={{color:TEAM_COLOR, fontSize:cloudFontSize, transform:"translate(0,-50%)"}}/> : null}
			</div>
		);
}      

export function displayConectereCommunityText ({ displayFooter}) {
	


	return (
			<div className = "ContainerVerticalCenter">
				<div className="ContainerNoHeightCenter" >
					<div style={{color:BALANCE_COLOR}}> CONEC</div>
					<div style={{color:TEAM_COLOR}}>TERE </div>
					<div style={{color:SOCIAL_COLOR, paddingLeft:"clamp(4px,1vw,10px)"}}>COMM</div>
					<div style={{color:GROWTH_COLOR}}> UNITY</div>
					<CloudIcon style={{color:TEAM_COLOR, transform:"translate(0,-50%)"}}/>
				</div>
				{!displayFooter ? null : <div className="TextStyle2 communityCommunityBannerFootnote" ><i>sharing conectivities for the health and wellness of employees everywhere</i></div>}
			</div>

		);
}      


export function  displayInvoiceDate({date, status}) {

	
	return (
			<div className="ContainerMaxHeightCenter fullWidth TextStyle2"  > {formatDate(date)}  </div>
			);   
   
}  

export function  displayInvoiceStatus({date, status}) {


	//Safety check
	if (!date || ! status) return null;
	
	var backgroundColor = COLOR_GOOD;  
	
	if (status !== "PAID")   {  
		const invoiceDateMoment = moment(date, "YYYY MM DDTHH mm ssZ");
		const daysOld = NOW().diff(invoiceDateMoment, 'days'); 
		if (daysOld > 60) backgroundColor =  COLOR_ALERT;  //RED
		else backgroundColor =  COLOR_WARNING_HIGH;  //ORANGE
	}
	
	return (
			<div className="ContainerMaxHeightCenter fullWidth TextStyle3 white" style={{backgroundColor:backgroundColor}} > {status}  </div>
			);   
   
}  


export  function  displayPercentageAmount(fraction) {

	var amountToDisplay = (100 * fraction).toFixed(0); 
	
	return (
			<div className="ContainerMaxHeightCenter fullWidth black"  > {amountToDisplay}%  </div>
			);   
   
} 

export  function  displayDollarAmount(amount) {

	var amountToDisplay = amount.toFixed(2); 
	
	return (
			<div className="ContainerMaxHeightCenter fullWidth TextStyle3"  > ${amountToDisplay}  </div>
		);   
   
} 

export const TableText = ({text, minWidth}) => {
	if (!minWidth) minWidth = "clamp(50px,10vw,150px)";
	return (
				<div className="ContainerNoHeightFlexEnd" style={{minWidth:minWidth}}>
					<div >{text}</div>
				</div>
		);
 };


/*
//For rendering conectivity source column in DataGrid
export function displayProductSourceColumn({productScope, customerName, scopeUrl}) {
 
		// if (DEBUG_MODE >= 2) console.log("Display Source Column called", productScope, customerName, scopeUrl);

		//CONECTERE SPONSORED PRODUCT        
		if (productScope === "GLOBAL")
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<img src={ CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} height='24' /> 
				</div>
				); 

		//CONECTERE DEFAULT PRODUCT AVAILABLE TO EVERYONE     
		if (productScope === "DEFAULT")
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<img src={ CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} height='24' /> 
				</div>
				); 
		
		//PRODUCT OFFERED BY THIS COMPANY
		if (productScope === "CUSTOMER_SPECIFIC") {         
			 if (scopeUrl)
				 return (            
					<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
							<img src={scopeUrl} height='24' />
				   </div>);
			else return (
				 
			   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"0.8rem", width:"100%"}}>  {truncateStringWithDots(customerName, 14)} </div>
				);
		}  
		
		//A catch all        
		return (
		   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"1.0rem", width:"100%"}}
				style={{width:"100%", backgroundColor:setScopeBackground(productScope)}}>  {productScope} 
			</div>
			); 
}
*/

/* 12.21.2023 - SPOTLIGHT COMMENTS NO LONGER USED.  REPLACED WITH USE OF MESSAGING INFRASTRUCTURE
//Sort the comments generally by date except that REPLY comments follow their parent comments
//May want to move this out of the RENDER path
export function sortComments(tempCommentList) {

	// const tempCommentList = [...spotlight.comments.items];
	// tempCommentList.sort(compareByCreatedAtReverseChronological);

	const tempCommentListParents = tempCommentList.filter(comment => !comment.parentComment);   //Grab just the parent comments
	tempCommentListParents.sort(compareByCreatedAtReverseChronological);                        //Sort the parents

	const tempCommentListReplies = tempCommentList.filter(comment => comment.parentComment);   //Grab just the replies that have parent comments
	tempCommentListReplies.sort(compareByCreatedAtReverseChronological);                        //Sort the replies


	//Now, insert each child into the parent list
	for (var i=0; i < tempCommentListReplies.length; i++) {

		//Find the parent
		 const index = tempCommentListParents.findIndex(parent => parent.id === tempCommentListReplies[i].parentComment);
		 
		 if (index > -1) {                
			 if (index < tempCommentListParents.length-1) {
				 tempCommentListParents.splice(index+1,0, tempCommentListReplies[i]);   //Splice in the reply AFTER the parent
				//  if (DEBUG_MODE >= 2) console.log("Inserted Reply at index ", index);
			 } else {
				//  if (DEBUG_MODE >= 2) console.log("Added Reply to last parent in array");
				 tempCommentListParents.push(tempCommentListReplies[i]);
			 }
		 } else {
			  if (DEBUG_MODE >= 2) console.log("No parent found", tempCommentListReplies[i]);
		 }
	}

	// if (DEBUG_MODE >= 2) console.log("Sorted Comments:", tempCommentListParents, tempCommentListReplies);
	
	return tempCommentListParents;
}
*/

//Sort the comments generally by date except that REPLY messages follow their parent messages
//May want to move this out of the RENDER path
export function sortMessages(tempMessageList) {

	// if (DEBUG_MODE >= 2) console.log("Messages BEFORE Sort:", tempMessageList);

	const tempMessageListParents = tempMessageList.filter(message => !message.parentMessage);   //Grab just the parent messages
	tempMessageListParents.sort(compareByCreatedAt);                                            //Sort the parents; oldest first, most recent message on bottom

	const tempMessageListReplies = tempMessageList.filter(message => message.parentMessage);    //Grab just the replies that have parent messages
	tempMessageListReplies.sort(compareByCreatedAtReverseChronological);                        //Because we splice replies at the END, we sort the replies in reverse order; oldest on bottom, most recent message on TOP
										  

	// if (DEBUG_MODE >= 2) console.log("Sorting Messages:", tempMessageList, tempMessageListParents, tempMessageListReplies);

	//Now, insert each child into the parent list
	for (var i=0; i < tempMessageListReplies.length; i++) {

		//Find the parent
		 const index = tempMessageListParents.findIndex(parent => parent.id === tempMessageListReplies[i].parentMessage);
		 
		 if (index > -1) {                
			 if (index < tempMessageListParents.length-1) {
				 tempMessageListParents.splice(index+1,0, tempMessageListReplies[i]);   //Splice in the reply AFTER the parent
				//  if (DEBUG_MODE >= 2) console.log("Inserted Reply at index ", index);
			 } else {
				//  if (DEBUG_MODE >= 2) console.log("Added Reply to last parent in array");
				 tempMessageListParents.push(tempMessageListReplies[i]);
			 }
		 } else {
			//   if (DEBUG_MODE >= 2) console.log("No parent found", tempMessageListReplies[i]);
		 }
	}

	// if (DEBUG_MODE >= 2) console.log("Sorted Messages:", tempMessageListParents, tempMessageListReplies);
	
	return tempMessageListParents;
}


export const Divider = ({percent, color, marginTop, marginBottom}) => {
	if (!percent) percent=80;
	if (!color) color = LIGHT_GREY;
	if (!marginTop) marginTop = '0px';
	if (!marginBottom) marginBottom = '0px';
	
	const percentString = percent.toString() + "%";
	const borderString = "1px solid "  + color;
	
	return (
		<div className = "ContainerNoHeightCenter" style={{width:"100%", marginTop:marginTop, marginBottom:marginBottom}}>
		  <div style={{width:percentString, border:borderString}}></div>
		</div>
	);
};


export const ConectereDivider = ({width, height}) => {
	
	if (!width) width = "80%";
	if (!height) height='2px'
	
	return (
		<div style={{width:width, display:"flex"}}>
		  <div style={{width:"25%", border:height + " solid #5dade2"}}></div>
		   <div style={{width:"25%", border:height + " solid #bb8fce"}}></div>
		   <div style={{width:"25%", border:height + " solid #82e0aa"}}></div>
		  <div style={{width:"25%", border:height + " solid #f7dc6f"}}></div>
		</div>
	);
};

export const ConectereDividerV2 = ({width, height, top, className}) => {	
	return (
		<div className={`gradient-line ${className}`} />
	);
};


export const Footer = () => {

	return (
		 <div className="footer">     
			<div className="footer-column blue-background"></div>
			<div className="footer-column purple-background"></div>
			<div className="footer-column green-background"></div>
			<div className="footer-column yellow-background"></div>
		</div>
	);
};

export function displayConectereAsAvatar({isSmall}) {

	let avatarClass = "avatarImage avatarImageMedium noBoxShadow";
	if (isSmall) {
	 avatarClass = "avatarImage avatarImageSmall noBoxShadow";
	}
	
	return (
	   <div className="avatarContainer "> 
		  <div className={avatarClass} style={{marginRight:"0px"}}>            
				  <img className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.IGGY_PURPLE_64x64IMAGE_PATH} alt={'Iggy'} /> 
		  </div>
		  <span className="avatarHoverText" style={{background: TEAM_COLOR, color:"white"}}>Iggy</span>
	   </div>      
	); 
 }

//
//
//  BUTTON COMPONENTS
//
//

export const MessagesButton = ({isActive, onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			 	<MessageIcon className={`TextStyle5 ${isActive ? 'purple' : 'light-grey-text'} `} />
			 </IconButton >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > Messages</div>
		</div>
	);
};

export const LeaderboardButton = ({isActive, onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			 	<EmojiEventsIcon className={`TextStyle5 ${isActive ? 'purple' : 'light-grey-text'} `} />
			 </IconButton >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > Leaderboard</div>
		</div>
	);
};


export const RadarButton = ({onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
					<RadarIcon className="radarButton" />
				</IconButton >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > Conectivity Pillars</div>
		</div>
	);
};

export const SummaryButton = ({onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<AssignmentIcon className="summaryButton" />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} >Summary</div>
		</div>
	);
};


export const EditButton = ({onClickCallback, onClickCallbackParam, className, hoverTextClassName, hoverText, hoverLeft, useRegularContainer}) => {
	
	if (!hoverText) hoverText = "Edit";
	if (!className) className = "editButton";
	if (!hoverTextClassName) hoverTextClassName = "TextStyle2 hoverTextStyle1";

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className={useRegularContainer ? "buttonContainer" : "dataGridActionButton"} >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<Edit className={className} />
			</IconButton>
			<div className={ `${hoverTextClassName} ${(mouseOver ? "hoverTextStyle1Visible" : "")} ${hoverLeft ? "hoverLeft" : ""}`} >{hoverText}</div>
		</div>
	);
};


export const DeleteButton = ({onClickCallback, onClickCallbackParam, className, hoverTextClassName, hoverText, hoverLeft, useRegularContainer}) => {

	if (!hoverText) hoverText = "Delete";
	if (!className) className = "deleteButton";
	if (!hoverTextClassName) hoverTextClassName = "TextStyle2 hoverTextStyle1";

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className={useRegularContainer ? "buttonContainer" : "dataGridActionButton"} >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<DeleteOutline className={className} />
			</IconButton>
			<div className={ `${hoverTextClassName} ${(mouseOver ? "hoverTextStyle1Visible" : "")} ${hoverLeft ? "hoverLeft" : ""}`} >{hoverText}</div>
		</div>
	);
};

export const DeclineRsvpButton = ({onClickCallback, className, color, isSelected, onClickCallbackParam, hoverTextClassName, hoverText, useRegularContainer}) => {

	if (!hoverText) hoverText = "Sorry, busy..";
	if (!className) className = "declineRsvpButton";
	if (!hoverTextClassName) hoverTextClassName = "TextStyle2 hoverTextStyle1";

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className={useRegularContainer ? "buttonContainer" : "dataGridActionButton"}>
			<IconButton size="small" onClick={isSelected ? null : (e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<EventBusyIcon style={{ color:COLOR_ALERT, borderBottom:(isSelected ? 'solid 1px ' + COLOR_ALERT : 'none') }}/>
			</IconButton>
			<div className={ `${hoverTextClassName} ${(mouseOver ? "hoverTextStyle1Visible" : "")} `} >{hoverText}</div>
		</div>
	);
};

export const AcceptRsvpButton = ({onClickCallback, className, color, isSelected, onClickCallbackParam, hoverTextClassName, hoverText, useRegularContainer}) => {

	if (!hoverText) hoverText = "I'm going!";
	if (!className) className = "acceptRsvpButton";
	if (!hoverTextClassName) hoverTextClassName = "TextStyle2 hoverTextStyle1";

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className={useRegularContainer ? "buttonContainer" : "dataGridActionButton"} >
			<IconButton size="small" onClick={isSelected ? null : (e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<EventAvailableIcon  style={{ color:COLOR_GOOD, borderBottom:(isSelected ? 'solid 1px '+COLOR_GOOD : 'none') }}/>
			</IconButton>
			<div className={ `${hoverTextClassName} ${(mouseOver ? "hoverTextStyle1Visible" : "")} `} >{hoverText}</div>
		</div>
	);
};


export const LaunchButton = ({onClickCallback, onClickCallbackParam, color, hoverTextClassName, hoverText, hoverLeft}) => {

	const [mouseOver, setMouseOver] = useState(false);
	if (!hoverText) hoverText = "Launch";
	if (!color) color = TEAM_COLOR;
	if (!hoverTextClassName) hoverTextClassName = "TextStyle2 hoverTextStyle1";

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)}  onMouseEnter={()=> setMouseOver(true)}>
					<LaunchIcon color={color} />
			</IconButton >
			<div className={`${hoverTextClassName} ${mouseOver ? "hoverTextStyle1Visible" : ""} ${hoverLeft ? "hoverLeft" : ""}`} > {hoverText}</div>
		</div>
	);
};


export const LaunchButtonWithoutIcon = ({onClickCallback, onClickCallbackParam, hoverTextClassName, hoverText, hoverLeft}) => {

	if (!hoverText) hoverText = "Add";
	if (!hoverTextClassName) hoverTextClassName = "TextStyle2 hoverTextStyle1";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
				<button className="dataGridAddButtonStyle" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
					<LaunchIcon color={COLOR_WHITE}/>
				</button >
			<div className={ `${hoverTextClassName} ${mouseOver ? "hoverTextStyle1Visible" : ""} ${hoverLeft ? "hoverLeft" : ""}`} > {hoverText}</div>
		</div>
	);
};

export const TransmitButton = ({onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
					<CellTowerIcon className="transmitButton" />
				</IconButton >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > Transmit </div>
		</div>
	);
};


export const ShareButton = ({onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
					<CloudUploadIcon className="shareButton" />
				</IconButton >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > Share Conectivity</div>
		</div>
	);
};


export const RemoveSharedButton = ({onClickCallback, onClickCallbackParam}) => {

	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			 <IconButton size="small" onClick={() => onClickCallback(onClickCallbackParam)} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<RemoveSharedButton className="removeShareButton" />
			</IconButton >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > Remove Conectivity</div>
		</div>
	);
};


export const AddButton = ({icon, onClickCallback, hoverText, useRegularContainer, disabled}) => {

	if (!hoverText) hoverText = "Add";
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className={useRegularContainer ? "buttonContainer" : "dataGridActionButton"} >
				<button className="dataGridAddButtonStyle" disabled={disabled} onClick={() => onClickCallback()} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
					{icon ? 
						<div className="ContainerMaxHeightCenter">
							<div> {icon} </div>
							<div style={{ transform:'translate(-10%,-30%)'}}><AddIcon className='TextStyle5 white'  /></div>
						</div>
					: 
						<AddIcon className='TextStyle5 white'  />
					}
				</button >
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const AddButtonWithIcon = ({onClickCallback, onClickCallbackParam, hoverText}) => {

	if (!hoverText) hoverText = "New";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<AddIcon className="addButton" />
			</IconButton>
			<div className={ `hoverTextStyle1 ${(mouseOver ? "hoverTextStyle1Visible" : "")} `} >{hoverText}</div>
		</div>
	);
};

export const PaidButton = ({onClickCallback, onClickCallbackParam, hoverText}) => {

	if (!hoverText) hoverText = "Mark Paid";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<CreditScoreIcon className="paidButton" />
			</IconButton>
			<div className={ `hoverTextStyle1 ${(mouseOver ? "hoverTextStyle1Visible" : "")} `} >{hoverText}</div>
		</div>
	);
};


export const UnPaidButton = ({onClickCallback, onClickCallbackParam, hoverText}) => {

	if (!hoverText) hoverText = "Mark UNPAID";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<CreditCardOffIcon className="paidButton" />
			</IconButton>
			<div className={ `hoverTextStyle1 ${(mouseOver ? "hoverTextStyle1Visible" : "")} `} >{hoverText}</div>
		</div>
	);
};

export const ViewButton = ({onClickCallback, onClickCallbackParam, hoverText}) => {

	if (!hoverText) hoverText = "View";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<PreviewIcon className="viewButton" style={{fontSize:"1.4rem"}} />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const UndoButton = ({onClickCallback, onClickCallbackParam, hoverText, disabled}) => {
	if (!hoverText) hoverText = "Undo";
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)} disabled={disabled}>
				<UndoIcon className={disabled ? "viewButtonDisabled" : "viewButton"} style={{fontSize:"1.4rem"}} />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const RedoButton = ({onClickCallback, onClickCallbackParam, hoverText, disabled}) => {
	if (!hoverText) hoverText = "Redo";
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)} disabled={disabled}>
				<RedoIcon className={disabled ? "viewButtonDisabled" : "viewButton"} style={{fontSize:"1.4rem"}} />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const BonusButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize}) => {

	if (!hoverText) hoverText = "Give Bonus";
	if (!fontSize) fontSize = "1.4rem";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<SavingsIcon className="bonusButton" style={{fontSize:fontSize}} />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};


export const CopyLinkButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize}) => {

	if (!hoverText) hoverText = "Copy Link";
	if (!fontSize) fontSize = "1.4rem";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="dataGridActionButton" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<LinkIcon className="blue" style={{fontSize:fontSize}} />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1 && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};


export const CalendarButton = ({hoverLeft, onClickCallback, onClickCallbackParam, hoverText, fontSize}) => {

	if (!hoverText) hoverText = "Calendar";
	if (!fontSize) fontSize = "1.4rem";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className={`dataGridActionButton  `} >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); onClickCallback(onClickCallbackParam)}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<CalendarTodayIcon className="blue" style={{fontSize:fontSize}} />
			</IconButton>
			<div className={`TextStyle2 hoverTextStyle1  ${mouseOver ? "hoverTextStyle1Visible" : ""} ${hoverLeft ? "hoverLeft" : ""} `} > {hoverText}</div>
		</div>
	);
};

export const HelpButton = ({onClickCallback, onClickCallbackParam, className, hoverText, fontSize, noTransform, hoverTextClassName, zIndex, isHTML, positionAbsolute}) => {

	if (!hoverText) hoverText = "Calendar";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	if (!zIndex) zIndex = '99';
	if (!className) className='blue'
	let transform = (noTransform ? "translate(0,0)" : "translate(-10%,-20%");
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<span className={` ${positionAbsolute ? 'buttonContainerAbsoluteOffset' : 'buttonContainer'} helpButton`} style={{zIndex:zIndex, display:"inline-block", transform:transform}}>
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<HelpIcon className={className} style={{fontSize:fontSize}} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1 hoverTextStyle1Wide  && ${mouseOver && "hoverTextStyle1Visible"} `} > 
				{isHTML ? parse(hoverText) : hoverText}
			</div>
		</span>
	);
};

export const InfoButton = ({onClickCallback, className, hoverText, fontSize, noTransform, hoverTextClassName, zIndex, isHTML}) => {

	if (!hoverText) hoverText = "Calendar";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	if (!zIndex) zIndex = '99';
	if (!className) className='blue'
	let transform = (noTransform ? "translate(0,0)" : "translate(-10%,-20%");
	
	const [showInfo, setShowInfo] = useState(false);

	return (
		<span className="" style={{zIndex:zIndex, display:"inline-block", transform:transform}}>
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); setShowInfo(!showInfo);}} >
				<InfoIcon className={className} style={{fontSize:fontSize}} />
			</IconButton>
			<p className={hoverTextClassName + ` hoverTextStyle1 infoPopUp  && ${showInfo && "hoverTextStyle1Visible"} `} > 
				<div style={{position:"absolute", top:"2px", right:"2px"}}><CloseIcon className="TextStyle4" onClick={(e) => {e.stopPropagation(); setShowInfo(!showInfo);}} style={{backgroundColor:TEAM_COLOR, color:COLOR_WHITE, cursor: "pointer"}}/></div>
				{isHTML ? parse(hoverText) : hoverText}
			</p>
		</span>
	);
};

export const InfoLink = ({ onClickCallback, className, hoverText, fontSize, noTransform, hoverTextClassName, zIndex, isHTML, setInfoLinkActive }) => {

	if (!hoverText) hoverText = "Calendar";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName = "";
	if (!zIndex) zIndex = '99';
	if (!className) className = 'blue'
	let transform = (noTransform ? "translate(0,0)" : "translate(-10%,-20%");

	const [showInfo, setShowInfo] = useState(false);

	return (
		<span className="" style={{ zIndex: zIndex, display: "inline-block", transform: transform }}>
			<span onClick={(e) => { e.stopPropagation(); setShowInfo(!showInfo); setInfoLinkActive && setInfoLinkActive(true) }} className="learnMoreLinkText TextStyle3V2">Learn More</span>
			<p className={hoverTextClassName + ` hoverTextStyle1 infoPopUp  && ${showInfo && "hoverTextStyle1Visible"} `} >
				<div style={{ position: "absolute", top: "2px", right: "2px" }}><CloseIcon className="TextStyle4" onClick={(e) => { e.stopPropagation(); setShowInfo(!showInfo); setInfoLinkActive && setInfoLinkActive(false) }} style={{ backgroundColor: TEAM_COLOR, color: COLOR_WHITE, cursor: "pointer" }} /></div>
				{isHTML ? parse(hoverText) : hoverText}
			</p>
		</span>
	);
};

export const QuickFilterButton = ({onClickCallback, onClickCallbackParam, hoverText, buttonText }) => {

	const [filterActive, setFilterActive] = useState(false);

	return (
		<div className="buttonContainer" >
			<button className={`buttonStyle4 TextStyle2 boxShadow`}  style={{background:filterActive ?  COLOR_BLUE_HEADER : "white", color:!filterActive ?  COLOR_BLUE_HEADER : "white"}} onClick={(e) => {e.stopPropagation(); setFilterActive(!filterActive); if (onClickCallback) onClickCallback(onClickCallbackParam);}}>
				{buttonText} 
			</button>
			{filterActive ? 
				<div className="ContainerNoHeightCenter" style={{backgroundColor:"white", borderRadius:"50%",  position:"absolute", top:"0", right:"0", transform: "translate(50%,-35%)"}}>
					<CheckCircleIcon className={'TextStyle4'} style={{color:COLOR_GOOD}}/> 
				</div>                                
			: null }
		</div>
	);
};


export const OpenButton = ({onClickCallback, onClickCallbackParam, hoverText, color, hoverTextClassName, hoverLeft, noIconButton}) => {
	const { darkMode } = useContext(DisplayContext); 
	if (!hoverText) hoverText = "Preview";

	if (!hoverTextClassName) hoverTextClassName ="";
	if (!color) color =(darkMode ? COLOR_WHITE : COLOR_BLACK);
	
	const [mouseOver, setMouseOver] = useState(false);
	const styleObject = (hoverLeft ? {transform:'translate(-100%, 100%)'} : {} );

	if (noIconButton) return (
		<span className="buttonContainer ContainerNoHeightCenter" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			<OpenInNewIcon className="TextStyle3" style={{color:color}} />
			<div className={hoverTextClassName + ` hoverTextStyle1  && ${mouseOver && "hoverTextStyle1Visible"} `} style={styleObject}> {hoverText}</div>
		</span>
	);
	
	return (
		<span className="buttonContainer" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<OpenInNewIcon style={{color:color}} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1  && ${mouseOver && "hoverTextStyle1Visible"} `} style={styleObject}> {hoverText}</div>
		</span>
	);
};


export const SlackButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize, hoverTextClassName, zIndex}) => {

	if (!hoverText) hoverText = "Integration with Slack Enabled! 👍 ";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	if (!zIndex) zIndex = '99';
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer helpButton" style={{zIndex:zIndex}}>
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			   <div className="ContainerNoHeightCenter">
						<img  style={{height:"1.5rem"}} src={CONECTERE_CONFIG_DATA.SLACK_LOGO_WHITE} />
			   </div>
			</IconButton>
			<p className={hoverTextClassName + ` hoverTextStyle1 hoverTextStyle1Wide  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</p>
		</div>
	);
};


export const CloudSyncButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize, hoverTextClassName}) => {

	if (!hoverText) hoverText = "Cloud synchronication";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<CloudSyncIcon className="settingsIcon blue" style={{fontSize:fontSize}} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1 hoverTextStyle1Wide  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const SettingsButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize, className, hoverTextClassName}) => {

	if (!hoverText) hoverText = "Settings";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	if (!className) className = "TextStyle4 blue"
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer" >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<SettingsIcon className={className}  />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1 hoverTextStyle1Wide  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const SearchButton = ({onClickCallback, onClickCallbackParam, hoverText, hoverTextClassName}) => {

	if (!hoverText) hoverText = "Search";
	if (!hoverTextClassName) hoverTextClassName ="";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer"  >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)} >
				<SearchIcon className="TextStyle7 blue"  />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};


export const GiftButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize, hoverTextClassName}) => {

	if (!hoverText) hoverText = "Gift coins to colleagues!";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer"  >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<RedeemIcon className="purple" style={{fontSize:fontSize}} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1 hoverTextStyle1Wide  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const TransactionButton = ({onClickCallback, onClickCallbackParam, hoverText, fontSize, hoverTextClassName}) => {

	if (!hoverText) hoverText = "Add an adjustment (credit / debit) to a user's balance";
	if (!fontSize) fontSize = "1.4rem";
	if (!hoverTextClassName) hoverTextClassName ="";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer"  >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
				<IsoIcon className="blue" style={{fontSize:fontSize}} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1 hoverTextStyle1Wide  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

//Emoji button for message bar - Scales on hover 
export const EmojiButton = ({onClickCallback, onClickCallbackParam}) => {
	return (
		<div className="buttonContainer"  >
			<SentimentSatisfiedOutlinedIcon className="TextStyle6 dust-grey buttonZoomAbility" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} />
		</div>
	);
};

export const GIFButton = ({onClickCallback, onClickCallbackParam}) => {
	return (
		<div className="buttonContainer"  >
			<GifIcon className="TextStyle6A headerBlue buttonZoomAbility"  onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} />
		</div>
	);
};

export const GIFButtonV2 = ({onClickCallback, onClickCallbackParam}) => {
	return (
		<div className="buttonContainer">
			<img src={CONECTERE_CONFIG_DATA.GIF_ICON_IMAGE_PATH} className='gifImg-V2 buttonZoomAbility' onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} />
		</div>
	);
};

export const SendButton = ({onClickCallback, onClickCallbackParam}) => {
	return (
		<div className="buttonContainer"  >
			<SendIcon className="TextStyle6 headerBlue buttonZoomAbility" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} />
		</div>
	);
};

export const UploadPhotoButton = ({onClickCallback, fileTypesToAccept, resetTrigger}) => {
	const id = uuidv4();
	const inputFile = useRef(null);

	useEffect(() => {
		if (inputFile.current) {
			inputFile.current.value = "";
			if (DEBUG_MODE) console.log("Image Upload component reset");
	  }
	},[resetTrigger]);

	if (!fileTypesToAccept) fileTypesToAccept = "image/*" ;
	return (
		<div>
				<input 
					 ref={inputFile}
                accept={fileTypesToAccept}
                type="file" 
                id={id}
                onChange={(e) => {e.stopPropagation(); if (DEBUG_MODE) console.log("Image upload button change");if (onClickCallback) onClickCallback(e);}}  
                style={{ display: 'none'}}
            />
				<label htmlFor={id}>
					<div className="buttonContainer"  >
						<AttachFileIcon className="TextStyle6 dust-grey buttonZoomAbility"  />
					</div>
				</label>
		</div>
	);
};

export const PublishButton = ({onClickCallback, onClickCallbackParam, hoverText, className, hoverTextClassName}) => {

	if (!hoverText) hoverText = "publish";
	if (!hoverTextClassName) hoverTextClassName ="";
	if (!className) className = "TextStyle7 blue";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer"  >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)} >
				<PublishIcon className={className} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};

export const CancelButton = ({onClickCallback, onClickCallbackParam, hoverText, className, hoverTextClassName}) => {

	if (!hoverText) hoverText = "cancel";
	if (!hoverTextClassName) hoverTextClassName ="";
	if (!className) className = "TextStyle7 blue";
	
	const [mouseOver, setMouseOver] = useState(false);

	return (
		<div className="buttonContainer"  >
			<IconButton size="small" onClick={(e) => {e.stopPropagation(); if (onClickCallback) onClickCallback(onClickCallbackParam);}} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)} >
				<CancelPresentationIcon className={className} />
			</IconButton>
			<div className={hoverTextClassName + ` hoverTextStyle1  && ${mouseOver && "hoverTextStyle1Visible"} `} > {hoverText}</div>
		</div>
	);
};



export const HeaderLink = ({to, text, icon, numNotifications, hoverText}) => {

	const { darkMode } = useContext(DisplayContext); 

	const currentReactPage = useLocation();     //This React reference is updated every time the URL changes
	let isActive = false;
	if (to) isActive=currentReactPage.pathname.toUpperCase().includes(to.toUpperCase());
	const [mouseOver, setMouseOver] = useState(false);
	if (!hoverText) hoverText = text; //Default to LINK text
   
	return (
		<Link className={`headerLink ${(darkMode ? "headerLinkDarkMode" : "")} ${isActive ?  (darkMode ? "headerLinkSelectedDarkMode" : "headerLinkSelected") : ""}  `} to={to} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			<span className='headerLinkIcon'> {icon} </span>
			<span className='headerLinkText'>{text}</span> 
			<span className={ `TextStyle2 hoverTextStyle1 ${(mouseOver ? "headerLinkHoverText" : "")} `} >{hoverText}</span>            
			{!numNotifications || numNotifications === 0 ? null :  <div className="notificationBubble" > {numNotifications} </div> }
		</Link>
	);
};

export const HeaderLinkLaunchPad = ({hoverText}) => {
	const { darkMode } = useContext(DisplayContext); 
	const currentReactPage = useLocation();     //This React reference is updated every time the URL changes
	const [mouseOver, setMouseOver] = useState(false);
   
	let isActive = currentReactPage.pathname.toUpperCase().includes('/launchpad'.toUpperCase());
	const [stroke, setStroke] = useState(darkMode ? BALANCE_COLOR: COLOR_WHITE); 
	if (!hoverText) hoverText = 'Launchpad'; //Default to LINK text

	return (
		<Link  className={`headerLink ${(darkMode ? "headerLinkDarkMode" : "")}  ${isActive ?  (darkMode ? "headerLinkSelectedDarkMode" : "headerLinkSelected") : ""}`} to='/launchpad' onMouseLeave={()=> {setStroke(darkMode ? BALANCE_COLOR: COLOR_WHITE); ;setMouseOver(false)}} onMouseOver={()=> setStroke(!darkMode ? BALANCE_COLOR: COLOR_WHITE)} onMouseEnter={()=> {setStroke(!darkMode ? BALANCE_COLOR: COLOR_WHITE),setMouseOver(true)}} >
			<span className='headerLinkIcon'><LaunchIcon color={stroke} /></span>
			<span className='headerLinkText'>Launchpad</span> 
			<span className={ `TextStyle2 hoverTextStyle1 ${(mouseOver ? "headerLinkHoverText" : "")} `} >{hoverText}</span>            
		</Link>
);
};

export const HeaderCommunityButton = ({callback}) => {
	//style={{transform:(headerDisplayMode === 'FULL' ? "translate(0,-50%)" : "translate(0,0)") }}
	const [mouseOver, setMouseOver] = useState(false);
	return (
		<div className="ContainerNoHeightCenter btn-community"  onClick={callback} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			<span className="headerLinkText blue" style={{padding:"0px"}}>CO</span> 
			<span className="headerLinkText purple"  style={{padding:"0px"}}>MM</span> 
			<span className="headerLinkText green"  style={{padding:"0px"}}>UN</span> 
			<span className="headerLinkText yellow"  style={{padding:"0px"}}>ITY</span>
			<span className='headerLinkIcon'> <CloudIcon className="headerLinkIcon purple" /> </span>
			<div className={ `$TextStyle2 hoverTextStyle1 ${(mouseOver ? "headerLinkHoverText" : "")} `} >Community</div>            
		</div>     
	)
}

export const NavbarGradientCommunityButton = ({callback}) => {
	const [mouseOver, setMouseOver] = useState(false);
	return (
		<div className="ContainerNoHeightCenter btn-gradient-community"  onClick={callback} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
			<span className='TextStyle4 black-text'>Community</span>
		</div>
	)
};

export const NotificationsLink = ({numNotifications, callback, headerDisplayMode}) => {
	const { darkMode } = useContext(DisplayContext); 

   const [mouseOver, setMouseOver] = useState(false);
   return (
	  <div className={`headerLink ${(darkMode ? "headerLinkDarkMode" : "")} `} onClick={callback} onMouseLeave={()=> setMouseOver(false)} onMouseEnter={()=> setMouseOver(true)}>
		 <NotificationsActiveIcon /> 
		 {numNotifications && numNotifications > 0 ? 
			<div className="notificationBubble" >
				  {numNotifications}
			</div>
			: null
		 }
		 <div className={ `$TextStyle2 hoverTextStyle1 ${(mouseOver ? "hoverTextStyle1Visible" : "")} `} >Notifications</div>     
	  </div>
   );
}

export const LaunchIcon = ({color, scale}) => {

	//Define a 24x24 pixel space for the icon
	//Scale our ICON based on a value of 0-1
	
	if (!color) color = TEAM_COLOR;
	if (!scale || scale > 1 || scale < 0) scale=1.0;
	
	const height = Math.ceil(24*scale), width=Math.ceil(12*scale);
	let x11=Math.ceil(width*2/12).toString(), y11=Math.ceil(height*16/24).toString(),  x21=Math.ceil(width*10/12).toString(), y21=Math.ceil(height*8/24).toString();
	let x12=Math.ceil(width*0/12).toString(), y12=Math.ceil(height*24/24).toString(),  x22=Math.ceil(width*12/12).toString(), y22=Math.ceil(height*12/24).toString();
	let x13=Math.ceil(width*6/12).toString(), y13=Math.ceil(height*24/24).toString(),  x23=Math.ceil(width*12/12).toString(), y23=Math.ceil(height*18/24).toString();
	
	let fontSize = (scale * 1.2).toString()+'rem';
	// console.log("Computed Line 1", x11, y11, x21, y21);
	// console.log("Computed Line 2", x12, y12, x22, y22);
	// console.log("Computed Line 3", x13, y13, x23, y23);
	// console.log("Computed FontSize", fontSize);
	
 
	return (
	<span className="ContainerNoHeightCenter noWrap">
		<svg height={height.toString()} width={width.toString()}> 
			<line x1={x11} y1={y11} x2={x21} y2={y21} stroke={color} strokeWidth="1" fill="none" />
			<line x1={x12} y1={y12} x2={x22} y2={y22} stroke={color} strokeWidth="1" fill="none" />
			<line x1={x13} y1={y13} x2={x23} y2={y23} stroke={color} strokeWidth="1" fill="none" />
		</svg>
		<HubIcon style={{color:color, fontSize:fontSize, transform:"translate(0,-5%)"}}/>
	</span>
	);


	/*    THESE ARE OUR HARD-CODED COORDINATES FOR THE LINES
		return (
		<div>
			<svg height="24" width="12" > 
				<line x1="2" y1="16" x2="10" y2="8" stroke={color} strokeWidth="1" fill="none" />
				<line x1="0" y1="24" x2="12" y2="12" stroke={color} strokeWidth="1" fill="none" />
				<line x1="6" y1="24" x2="12" y2="18" stroke={color} strokeWidth="1" fill="none" />
			</svg>
			<HubIcon style={{color:color, fontSize:fontSize, transform:"translate(0,-20%)"}}/>
		</div>
	);
	*/

};

//Component for displaying a target number.  When the number changes, the components increments / decrements the internal cached version until the number is achieved
//  disableCountUp:  if TRUE, then simply show the value immediate
export const CountUpDown = React.memo(({valueToDisplay, disableCountUp}) => {

   const [currentValue, setCurrentValue] = useState(disableCountUp ? valueToDisplay : 0);    
   const [cycleTime, setCycleTime] = useState(100);    
   const [increment, setIncrement] = useState(1);    
   const [timerEnabled, setTimerEnabled] = useState(false);    

//    console.log("Rendering Counter ", currentValue, valueToDisplay)
   //Init our settings
   useEffect(() => {
	  //Calc timer settings but only if our value to display has changed
	  if(valueToDisplay && valueToDisplay !== currentValue && valueToDisplay !== currentValue) {
		 calcIncrement(currentValue, valueToDisplay);
	  }
   },[valueToDisplay]);

   // Return:
   //    cycleTime: ms for 1 increment cycle
   //    increment: amount to increment for each cycle
   function calcIncrement(currentValue, valueToDisplay){
	  let delta = Math.abs(valueToDisplay-currentValue);
	  if (delta === 0) return;

	  //Vars
	  const tempCycleTime = 100; //update number every 100 ms
	  let totalTime = 1000; if(delta > 500) totalTime = 1500;  //Perform the entire animation in either 2.5 or 3.5 seconds
	  let tempIncrement = Math.ceil(delta * tempCycleTime / totalTime);
	//    console.log("Delta", delta);
	//    console.log("Duration", tempCycleTime);
	//    console.log("Calculated increment", tempIncrement);
	  setCycleTime(tempCycleTime);
	  setIncrement(tempIncrement);
	  setTimerEnabled(true); //Trigger our animation
   };

   useEffect(() => {
	  let timer;
	  if (valueToDisplay && valueToDisplay !== currentValue) {
		//  console.log("Triggering timer ", valueToDisplay, currentValue)
		 timer = setTimeout(() => {
				  updateCurrentValue ();
			   }, cycleTime); 
   } else {setTimerEnabled(false); clearTimeout(timer); }

	  // Clear timeout if the component is unmounted
	  return () => clearTimeout(timer);
   });

	//This function updates the current value
	function updateCurrentValue () {
	  //Threshold check
	//   if (currentValue === valueToDisplay) {console.log("No updated needed", currentValue); return;}

	  let newValue;

	  if (currentValue < valueToDisplay) {
		 newValue = currentValue + increment; if (newValue > valueToDisplay ) newValue = valueToDisplay;
	  } else if (currentValue > valueToDisplay) {
		 newValue = currentValue - increment; if (newValue < valueToDisplay ) newValue = valueToDisplay;       
	  }
	//   console.log("Updated value to display", currentValue, newValue );
	  setCurrentValue(newValue);      
	  return ;
  }



	return (<div>{currentValue}</div>);
},(prevProps, nextProps) => {
   //Our React MEMO function - do not re-render if no change 
   if (
	   (prevProps.valueToDisplay === nextProps.valueToDisplay) 
	   )
   {
	//    if (DEBUG_MODE >= 2) console.log("Do NOT Re-Render the CountUpDown",  prevProps.valueToDisplay);
	   return true; // props are equal
   }
//    if (DEBUG_MODE >= 2) console.log("Re-RENDER the CountUpDown!", prevProps.valueToDisplay);
   return false; // props are not equal -> update the component
});

//parse a CSV file into an array of entries
export function csvToArray(str, delimiter = ",") {
	let arr = [];

	//Safety check
	if (!str ||  str.length === 0 || !delimiter ) {
		if (DEBUG_MODE > 0) console.error("Error - improper params for process CSV");
		return arr;
	}

	try {
		// slice from start of text to the first \n index
		// use split to create an array from string by delimiter
		const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
		
		//Change headers to all lower case to add the user
		headers.forEach((element, index) => {
				var correctedHeader = element.replace(/\s/g, '');       //remove spaces
				headers[index] = correctedHeader.toLowerCase();
		});         

		// slice from \n index + 1 to the end of the text
		// use split to create an array of each csv value row
		const rows = str.slice(str.indexOf("\n") + 1).split("\n");
		
		//Remove any end of line in the data entries
		rows.forEach((element, index) => {
				var correctedRow = element.replace(/\r/g, '');  //remove end of row symbol if present
				rows[index] = correctedRow;
		});    
		
		// Map the rows
		// split values from each row into an array
		// use headers.reduce to create an object
		// object properties derived from headers:values
		// the object passed as an element of the array
		arr = rows.map(function (row) {
		const values = row.split(delimiter);
		const el = headers.reduce(function (object, header, index) {
			object[header] = values[index];
			return object;
		}, {});
		return el;
		});
	} catch (err) {
		if (DEBUG_MODE > 0) console.error("Error processing CSV file", err);
		arr = [];
		return arr;
	}
		
// return the array
return arr;          
}  
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR } from '../data/conectereConfigData';
import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";

//Our Components
import ModalGeneral from "../Components/modalGeneral/modalGeneral";
import ModalBonus from "../Components/modalBonus/modalBonus";
import ModalMediaDisplay from "../Components/modalMediaDisplay/modalMediaDisplay";

//Utils
import {  setConectivityImageUrlByCategory  } from "../utils/conectivityUtils";
import BonusModal from '../Components/bonusModal/BonusModal';

const ModalContext = React.createContext();

const ModalProvider = ({ children }) => {

	//General Modal 
	const [showModalGeneral, setShowModalGeneral] = useState(false);
	const [modalGeneralMessage, setModalGeneralMessage] = useState("");
	const [modalGeneralTitle, setModalGeneralTitle] = useState("");

	//Media Display Modal 
	const [showModalMediaDisplay, setShowModalMediaDisplay] = useState(false);
	const [mediaUrl, setMediaUrl] = useState(null);

	//Bonus Modal
	const [modalBonusLabel, setModalBonusLabel] = useState("");
	const [modalBonusHeaderColor, setModalBonusHeaderColor] = useState("");
	const [modalBonusMessage, setModalBonusMessage] = useState("");
	const [modalBonusAmount, setModalBonusAmount] = useState(0);
	const [showModalBonus, setShowModalBonus] =useState(false);        				//display Bonus Modal
	const [showModalBonusDelayed, setShowModalBonusDelayed] = useState(false); 	//display the bonus modal but after a delay so ring animation can complete

	//Trigger for showing our Bonus modal but only after a delay
	useEffect (() => {
		//safety check
		if (showModalBonusDelayed) {
			if (DEBUG_MODE) console.log("Show bonus modal DELAYED invoked.  Starting timer");
			const bonusModalTimer = setTimeout(() => {
				setShowModalBonus(true);        //display Bonus Modal
			 }, 4000);   
			}

	}, [showModalBonusDelayed]);

	const handleCloseBonusModal = useCallback(() => {
		if (DEBUG_MODE > 1) console.log("Close BONUS modal callback invoked.  Closing modal");
		setShowModalBonus(false);
		setShowModalBonusDelayed(false);
	}, [showModalBonus, showModalBonusDelayed]);

	//return the provider
	return (				
		<div>

				<ModalContext.Provider value={
				{   

						//General Modal
						showModalGeneral, setShowModalGeneral,
						modalGeneralMessage, setModalGeneralMessage,
						modalGeneralTitle, setModalGeneralTitle,

						//Upload Media Modal
						showModalMediaDisplay, setShowModalMediaDisplay,
						mediaUrl, setMediaUrl,

						//Bonus Modal
						modalBonusLabel, setModalBonusLabel,
						modalBonusHeaderColor, setModalBonusHeaderColor,
						modalBonusMessage, setModalBonusMessage,
						modalBonusAmount, setModalBonusAmount,
						showModalBonus, setShowModalBonus, setShowModalBonusDelayed,

				}}>

					{children}
					<ModalGeneral showModalGeneral={showModalGeneral} setShowModalGeneral={setShowModalGeneral} modalGeneralHeaderColor={TEAM_COLOR} modalGeneralTitle={modalGeneralTitle} modalGeneralMessage={modalGeneralMessage} />
					<BonusModal showModal={showModalBonus} onClose={handleCloseBonusModal} modalBonusAmount={modalBonusAmount} modalBonusMessage={modalBonusMessage} />
					{/* <ModalBonus showModalBonus={showModalBonus} handleClose={handleCloseBonusModal} modalBonusAmount={modalBonusAmount} modalBonusHeaderColor={modalBonusHeaderColor} modalBonusMessage={modalBonusMessage} bonusImageURL={setConectivityImageUrlByCategory(modalBonusLabel, true)} /> */}
					<ModalMediaDisplay showModalMediaDisplay={showModalMediaDisplay} mediaUrl={mediaUrl} handleClose={()=>{setShowModalMediaDisplay(false); setMediaUrl(null);}}/>

				</ModalContext.Provider>

		</div>		
	); 
};


export { ModalContext, ModalProvider };
 /*
	ADMIN - CONECTIVITIES 

	PROPRIETARY AND CONFIDENTIAL

	PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
	copyright - CONECTIVITIES
*/


//DATA
import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, CONECTIVITY_INITIAL_STATE, RATING_STATS_INITIAL_STATE, portfolioWideConectivityAlyticsInitialState, 
    DAYS_FOR_RECENTLY_SHARED_CONECTIVITIES, COLOR_BLUE_TEXT, BALANCE_COLOR, FAINT_GREY, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TUTORIAL_COLOR, COLOR_BLUE_HEADER, CONECTIVITY_PUBLICATION_SELECTION_OPTIONS,
    GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, AUTO_FILL_CONECTIVITY_EDITOR_ON_CATEGORY_SWITCH,
    LEGAL_TERMS_AND_CONDITIONS, LEGAL_PRIVACY_POLICY, CONECTIVITY_COLUMN_TITLE_MAX_LENGTH,
    QUICK_FILTERS_FOR_CONTECTIVITIES, QUICK_FILTERS_FOR_CONTECTIVITIES_SUPER_ADMIN,
} from '../../shared/data/conectereConfigData';

import { REWARD_COLUMN } from '../../shared/data/gridColumns.js'
//STYLES
import "./adminConectivities.css";

//React & Amplify
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Queries and Mutations
import {  updateConectivity } from '../../shared/graphql/mutations';


//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HubIcon from '@mui/icons-material/Hub';

//Material-X
import Badge from '@mui/material/Badge';
import Switch from '@mui/material/Switch';

//Tabs
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

//Our components
import ConectivityEditor from "../../shared/Components/conectivityEditor/conectivityEditor";
import CCdisplayCardConectivityActivity from "../../shared/Components/displayCardConectivityActivity/displayCardConectivityActivity";
import CCdisplayCardConectivityStats from "../../shared/Components/displayCardConectivityStats/displayCardConectivityStats";
import CCdisplayCardConectivityFavorites from "../../shared/Components/displayCardConectivityFavorites/displayCardConectivityFavorites";
import CCdisplayCardConectivityPubStatus from "../../shared/Components/displayCardConectivityPubStatus/displayCardConectivityPubStatus";
import DisplayCardConectivityPortfolios from "../../shared/Components/displayCardConectivityPortfolios/displayCardConectivityPortfolios";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import StarRatings from "../../shared/Components/starRatings/starRatings";
import {setProgressBarWidth, ProgressBar} from "./Components/progressBar/progressBar";
import ModalUpcomingSchedule from "./Components/modalUpcomingSchedule/modalUpcomingSchedule";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import ConectivityLauncher from "../../shared/Components/conectivityLauncher/conectivityLauncher";
import SearchInputBar from "../../shared/Components/searchInputBar/searchInputBar.js";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { conectivitiesMatchesSearch, DisplayCardConectivityPreview, isSubscribing, setHeaderColorByCategory, 
 setHeaderColorByStringLength, setConectivityImageUrlByCategory, setBackgroundByPublishedStatus,  } from "../../shared/utils/conectivityUtils";
import { displayConectivitySourceColumn, compareByLaunchDate, formatDate, formatDateWithDayOfWeek, TabPanel, truncateStringWithDots, formatDays, displayConectereCommunityText,
    EditButton, DeleteButton, LaunchButton, ShareButton, Divider, AddButton } from "../../shared/utils/generalUtils";
import { invokeAPI }  from "../../shared/utils/databaseUtils";

//Other Components

//React Component
const AdminConectivitiesPage = () => {

// Context
const { setShowSpinner } = useContext(DisplayContext); 
const {  authState, currentUser,  isSuperAdmin, isAdmin, permissionAnalytics,  permissionLaunch, permissionEditor } = useContext(AuthContext);    
const { customers, users, teams, teamsOptions, usersOptions, } = useContext(CustomerContext);  
const {  conectivitiesForDashboard, launchRules,   templateConectivities,  templateOptions,  upcomingLaunches,  invokeDeleteConectivity } = useContext(ConectivityContext);
const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

//variable for holding on this page data for cusomters 
const [conectivityToDelete, setConectivityToDelete] = useState(CONECTIVITY_INITIAL_STATE);            //Used to hold a conectivity that is to be deleted
const [conectivityToEdit, setConectivityToEdit] = useState(CONECTIVITY_INITIAL_STATE);                //Used to hold a conectivity that is to be edited
const [conectivityToLaunch, setConectivityToLaunch] = useState(CONECTIVITY_INITIAL_STATE);            //Used to hold a conectivity that is to be launched
const [conectivityToPreview, setConectivityToPreview] = useState(null);      //Used to hold a conectivity that is to be launched
const [upcomingLaunchesToShow, setUpcomingLaunchesToShow] = useState([]);    //array of one or more Launch Rules for which to show the upcoming calendar schedule
const [conectivitiesFilteredMaster, setConectivitiesFilteredMaster] = useState([]); //Filtered list based on Search; used to drive all the row data for all the tabs


//variable to control confirmation Modal if user presses EDIT or DELETE; default to hidden
const [showModalDelete, setShowModalDelete] = useState(false);
const [showModalEdit, setShowModalEdit] = useState(false);
const [showModalLaunch, setShowModalLaunch] = useState(false);
const [showModalAdd, setShowModalAdd] = useState(false);
const [showModalPreview, setShowModalPreview] = useState(false);
const [showModalTemplateSelection, setShowModalTemplateSelection] = useState(false);
// const [showModalSaveAs, setShowModalSaveAs] = useState(false);
const [showModalRemoveFromPortfolio, setShowModalRemoveFromPortfolio] = useState(false);
const [showModalRemoveSuccess, setShowModalRemoveSuccess] = useState(false);
const [showModalUpcomingSchedule, setShowModalUpcomingSchedule] = useState(false);
const [newMessageGiphyGridContainerDivSize, setNewMessageGiphyGridContainerDivSize]  = useState(450);   //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match

//Community
const [showModalShare, setShowModalShare] = useState(false);
const [showModalStartShare, setShowModalStartShare] = useState(false);
const [showModalWithdraw, setShowModalWithdraw] = useState(false);
const [showModalShareSuccess, setShowModalShareSuccess] = useState(false);
const [showModalWithdrawSuccess, setShowModalWithdrawSuccess] = useState(false);

//Tabs for Conectivities Grids
const [tabIndexValue, setTabIndexValue] = useState(0);

//for showing particpants in a future scheduled conectivity
const [showModalFutureInvitees, setShowModalFutureInvitees] = useState(false);
const [futureInviteesToShow, setFutureInviteesToShow] = useState([]);
const [futureTeamInviteesToShow, setTeamFutureInviteesToShow] = useState([]);
const[portfolioWideConectivityAlytics,setPortfolioWideConectivityAlytics] = useState(portfolioWideConectivityAlyticsInitialState);

//State vars to hold the conectivity sets for our different tabs
const [conectivityRowsGlobal,setConectivityRowsGlobal]=useState([]);                    //Formatted rows for global portfolio - Tab 1 
const [conectivityRowsPrivate,setConectivityRowsPrivate]=useState([]);                  //Formatted rows for private portfolio - Tab 2   
const [conectivityRowsInstalled,setConectivityRowsInstalled]=useState([]);              //Formatted rows for conectivities installed FROM the community - Tab 3   
const [conectivityRowsShared,setConectivityRowsShared]=useState([]);                    //Formatted rows for conectivities posted TO the community - Tab 4 (SuperAdmin only, for now)   
const [conectivityRowsToDisplay,setConectivityRowsToDisplay]=useState([]);					//Rows that are actually rendered in the UI

//React References
const newMessageGiphyGridContainerDivRef = useRef(null);

//State for Search Bar
const [searchTerm, setSearchTerm] = useState("");
const [searchActive, setSearchActive] = useState(false);     //Default to SHOWING the search bar
const [activeFilters, setActiveFilters] = useState([]);


//On any change to the underlying conectivity data, reset our page state variables
useEffect(() => {

if (DEBUG_MODE >= 2) console.log("Change in conectivities detected.  Updated conectivities for the dashboard tabs", conectivitiesForDashboard);
setConectivitiesFilteredMaster([...conectivitiesForDashboard]);     //Make a fresh copy of the master published conectivities; this list is effected by the Search 
calcConectivityAnalytics();    
}, [conectivitiesForDashboard]);


//Use effect for processing updates to the Search Term
//Also invoked if our conectivities have asynchronously updated
useEffect(() => {
processSearchTermChange();  
if (DEBUG_MODE >= 2) console.log("Search term change detected in Use Effect", searchTerm);
}, [searchTerm, activeFilters, conectivitiesForDashboard]);

function processSearchTermChange () {
if (DEBUG_MODE >= 2) console.log("Processing search term change", searchActive, activeFilters);
//Did the user CLEAR the input or deactivate it?
if (!searchActive || (searchTerm === '' && (!activeFilters || activeFilters.length === 0))) {
    setConectivitiesFilteredMaster([...conectivitiesForDashboard]); //Make a fresh copy of the master published conectivities; thus do a reset on the page
    if (DEBUG_MODE >= 2) console.log("Generated new master list");

} else {
    let tempConectivitiesMaster = [...conectivitiesForDashboard]; //Copy Master
    let tempFilteredConectivities = tempConectivitiesMaster.filter(conectivity => conectivitiesMatchesSearch({conectivity, activeFilters, keywords:searchTerm})); //Filter based on new term
    if (DEBUG_MODE >= 2) console.log("Generated new filtered list based on search term", searchTerm, tempFilteredConectivities);
    setConectivitiesFilteredMaster(tempFilteredConectivities);
}
}      

//Adjust container size on any render
useEffect (() => {
if (newMessageGiphyGridContainerDivRef && newMessageGiphyGridContainerDivRef.current) {
    setNewMessageGiphyGridContainerDivSize(newMessageGiphyGridContainerDivRef.current.clientWidth);
    // if (DEBUG_MODE >= 2) console.log("Updated message GIPHY Grid Container width", newMessageGiphyGridContainerDivRef.current.clientWidth);
}       
});

//
//TODO - Incorporate this into our nightly analytics and stop fetching transactions on this page; OR move to a Conectivity Conext that is updated based on any global change
//

//Build rows for each tab in response to a conectivity change for the ADMIN page
useEffect(() => {

//Global Tab
var tempConectivities = conectivitiesFilteredMaster.filter(c => c.conectivityScope === "GLOBAL");
var tempRows = buildConectivityRows(tempConectivities);
setConectivityRowsGlobal(tempRows);

//Private Tab
tempConectivities = conectivitiesFilteredMaster.filter(c => c.conectivityScope === "CUSTOMER_SPECIFIC" && c.createdByCustomerID === currentUser.customerID);
tempRows = buildConectivityRows(tempConectivities);
setConectivityRowsPrivate(tempRows);

//Installed FROM Community Tab
tempConectivities = conectivitiesFilteredMaster.filter(c => c.conectivityScope === "INSTALLED_FROM_COMMUNITY");
tempRows = buildConectivityRows(tempConectivities);
setConectivityRowsInstalled(tempRows);

//Posted TO the Community Tab
tempConectivities = conectivitiesFilteredMaster.filter(c => c.conectivityScope === "CUSTOMER_SHARED" && c.createdByCustomerID === currentUser.customerID);
tempRows = buildConectivityRows(tempConectivities);
setConectivityRowsShared(tempRows);

}, [conectivitiesFilteredMaster]);

/* Switch our rows to display based on a change to the selected Tab or to the underlying Row data*/
useEffect(() => {	
let tempRows;
switch (tabIndexValue) {
case 0:
    tempRows = [...conectivityRowsGlobal];
    break;
case 1:
    tempRows = [...conectivityRowsPrivate];
    break;
case 2:
    tempRows = [...conectivityRowsInstalled];
    break;
case 3:
    tempRows = [...conectivityRowsShared];
    break;											
default:
tempRows = [...conectivityRowsGlobal];
break;
}
setConectivityRowsToDisplay(tempRows);	
}, [tabIndexValue, conectivityRowsGlobal, conectivityRowsPrivate, conectivityRowsInstalled, conectivityRowsShared]);



//Template state variables
//Since we want to control the selection from the parent, we need a call back state variable to store the ID of the selected template

const [idOfTemplateSelected, setIdOfTemplateSelected] = useState("");   

//Conectivities Columns for Admin of the customer who is NOT a SuperAdmin or an EDITOR
const conectivityColumns = [
{
field: 'active',
headerName: 'ACTIVE',
headerAlign: 'center',
width: 125,
editable: false,
sortable: false,
disableColumnMenu: true,
color:'white',
hide: (!isAdmin),
renderCell: (params)=> {
  return displayActiveColumn (params);
 }
},      
{
field: 'category',
headerName: 'TYPE',
headerAlign: 'center',
width: 125,
align:'center',
editable: false,
sortable: false,
disableColumnMenu: true,
color:'white',
renderCell: (params)=> {
    return (
        <div className="ContainerNoHeightCenter">
                <img className="ccImgXXL" src={setConectivityImageUrlByCategory(params.row.shortLabel, true)} alt='' />
            {/*
            <div style={{padding:'10px', color:setHeaderColorByCategory(params.row.shortLabel)}}>
                {params.row.category}
            </div>
            */}
        </div>
        );
      },
},
{
field: 'title',
headerName: 'CONECTIVITY',
headerAlign: 'center',
minWidth: 200,
flex:3,
editable: false,
sortable: true,
disableColumnMenu: true,
color:'white',
renderCell: (params)=> {
return (
    <div className="ContainerNoHeightCenter" style={{height:"60px", whiteSpace: "normal", wordBreak: "break-word" }}>
        <div style={{color:setHeaderColorByCategory(params.row.shortLabel)}}>
            {truncateStringWithDots(params.row.title,CONECTIVITY_COLUMN_TITLE_MAX_LENGTH)}
        </div>
    </div>
    );
},
},
REWARD_COLUMN,
{
field: 'customerName',
headerAlign: 'center',
headerName: 'SOURCE',
width: 150,
sortable: false,
disableColumnMenu: true,
editable: false,
color:'white',
hide: false,                 //Hide but include so the customer can display if desired
renderCell: (params)=> {
    return displayConectivitySourceColumn({customerID:currentUser.customerID, conectivityScope:params.row.conectivityScope, createdByCustomerID:params.row.createdByCustomerID, customerName:params.row.customer,  scopeUrl:params.row.scopeUrl, isSuperAdmin:isSuperAdmin, permissionEditor:permissionEditor});
}
},
{
field: 'action',
headerName: ' ',
headerAlign: 'left',
minWidth: 150,
flex:0.5,
color:'white',
sortable: false,
renderCell: (params)=> {
    if (!params.row.dynamoDBID) return null;
    return renderButtons(params); 
    }
},
];




/*
 Grid Buttons and other helper grid record display functions

  SCOPE
  ------
  GLOBAL                  #Part of the Conectere portfolio
  CUSTOMER_SPECIFIC       #Drafted by this customer, which may be a copy of a connectivity on originally shared to in the community
  CUSTOMER_SHARED         #Drafted by a different company and shared to the community for display in the community.  These should NOT appear any dashboard but the SuperAdmin

  PUBLICATION STATUS
  ------------------
  PRIVATE             #Used for Customer Specific Conectivities - does not go through the draft/review/published queue
  DRAFT               #Work in progress; internal draft by Conectere
  REVIEW              #Being reviewed by Conectere, either from DRAFT or as shared by a Company
  PUBLISHED           #Finalized and ready for customer use; may be globl or an approved customer-shared conetivity
  
*/    


//      ROLE        |       GLOBAL          |       CUSTOMER_SPECIFIC           |   CUSTOMER_SHARED         |   BUTTON
//-----------------------------------------------------------------------------------------------------------------
//      SuperAdmin  |       Y               |           Y                       |           Y               |   EDIT
//                  |       Y               |           Y                       |           Y               |   DELETE
//                  |   IF PUBLISHED        |   IF CREATED BY CUSTOMER          |           N               |   LAUNCH
//                  |       NO SHARE        |    IF CREATED BY CUSTOMER         |           N               |   SHARE
//                  |       N               |              N                    |           N               |   REMOVE(DELETE SHARED)   
//                  |                       |                                   |                           |       
//      EDITOR      |   IF AUTHORED BY USER |    IF AUTHORED BY USER            |           N               |   EDIT
//                  |   IF AUTHORED BY USER |    IF AUTHORED BY USER            |           N               |   DELETE
//                  |   IF PUBLISHED        | IF CREATED BY CUSTOMER or isADMIN |           N               |   LAUNCH 
//                  |       NO SHARE        | IF CREATED BY CUSTOMER or isADMIN |           N               |   SHARE
//                  |       N               |             N                     |           N               |   REMOVE(DELETE SHARED)
//                  |                       |                                   |                           |       
//      ADMIN       |       NO EDIT         |    IF CREATED BY CUSTOMER         |           N               |   EDIT
//                  |       NO DELETE       |    IF CREATED BY CUSTOMER         |           N               |   DELETE
//                  |   IF PUBLISHED        |    IF CREATED BY CUSTOMER         |           N               |   LAUNCH
//                  |       NO SHARE        |    IF CREATED BY CUSTOMER         |           N               |   SHARE
//                  |       N               |             N                     |           N               |   REMOVE(DELETE SHARED)
//                  |                       |                                   |                           |       
//  LAUNCH PERM     |       NO EDIT         |           N                       |           N               |   EDIT
//                  |       NO DELETE       |           N                       |           N               |   DELETE
//                  |   IF PUBLISHED        |    IF CREATED BY CUSTOMER         |           N               |   LAUNCH
//                  |       NO SHARE        |           N                       |           N               |   SHARE
//                  |       N               |           N                       |           N               |   REMOVE(DELETE SHARED)
//
//    



//Render the row's buttons as set out in the chart above
function renderButtons(params) {

//SuperAdmin 
if (isSuperAdmin) return displaySuperAdminButtons(params);
else if (permissionEditor) return displayEditorButtons(params);
else if (isAdmin) return displayAdminButtons(params);
else if (permissionLaunch) displayLaunchButton(params);

return null;
}



//DISPLAY BUTTONS FOR SUPER ADMIN   
function displaySuperAdminButtons(params) {
if (!params || !params.row || !params.row.conectivityScope) return null;

//Render buttons based on the scope of the conectivity
switch (params.row.conectivityScope) {
    
    case "GLOBAL":
        return (
            <div className="dataGridActionButtonRow">
                {/*   SHARE OR REMOVE  */}
                <div className="blankButton" ></div>

                {/*   LAUNCH  */}
                {params.row.publicationStatus != "PUBLISHED" || !params.row.active ? <div className="blankButton" ></div> :
                  <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> } 

                {/*   EDIT & DELETE  */}
                <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
                <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
            </div>
        );                 
        
    case "CUSTOMER_SPECIFIC":
       return (
            <div className="dataGridActionButtonRow">
                {/*   SHARE OR REMOVE  */}
                {params.row.createdByCustomerID != currentUser.customerID ? <div className="blankButton" ></div> :
                  <ShareButton onClickCallback={handleStartShare} onClickCallbackParam={params.row.dynamoDBID} /> }

                 {/*   LAUNCH  */}
                {params.row.createdByCustomerID != currentUser.customerID ? <div className="blankButton" ></div> :
                  <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> } 

                {/*   EDIT & DELETE  */}
               <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
               <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
            </div>
        );    
    case "CUSTOMER_SHARED":
       return (
            <div className="dataGridActionButtonRow">
                 {/*   LAUNCH  */}
                <div className="blankButton" ></div>

                {/*   SHARE OR REMOVE  */}
               <div className="blankButton" ></div>

                {/*   EDIT & DELETE  */}
               <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
               <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
            </div>
        );
    case "INSTALLED_FROM_COMMUNITY":
        return (
            <div className="dataGridActionButtonRow">
                {/*   SHARE OR REMOVE  */}
                <div className="blankButton" ></div>

                {/*   LAUNCH  */}
                <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> 

                {/*   EDIT & DELETE  */}
                <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
                <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
            </div>
        );          
    default:
        if (DEBUG_MODE) console.error("Error - improper conectivity scope - cannot render SUPER ADMIN buttons", params.row.conectivityScope);
            
        
}

return null;      
}


//DISPLAY BUTTONS FOR EDITOR   
function displayEditorButtons(params) {
if (!params || !params.row || !params.row.conectivityScope) return null;

//Render buttons based on the scope of the conectivity
switch (params.row.conectivityScope) {
    
    case "GLOBAL":
        return (
            <div className="dataGridActionButtonRow">
                {/*   LAUNCH  */}
                {params.row.publicationStatus != "PUBLISHED" || !params.row.active ? <div className="blankButton" ></div> :
                  <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> } 

                {/*   SHARE OR REMOVE  */}
                <div className="blankButton" ></div>
                
                {/*   EDIT & DELETE  */}
                {params.row.authorID != currentUser.id ? <div className="blankButton" ></div> :
                <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} /> }
                
                {params.row.authorID != currentUser.id ? <div className="blankButton" ></div> :
                <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} /> }
            </div>
        );                 
        
    case "CUSTOMER_SPECIFIC":
       return (
            <div className="dataGridActionButtonRow">
                 {/*   LAUNCH  */}
                {params.row.createdByCustomerID != currentUser.customerID || !params.row.active? <div className="blankButton" ></div> :
                  <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} />
                } 

                {/*   SHARE OR REMOVE  */}
                {params.row.createdByCustomerID != currentUser.customerID ? <div className="blankButton" ></div> :
                  <ShareButton onClickCallback={handleStartShare} onClickCallbackParam={params.row.dynamoDBID} />
                }

                {/*   EDIT & DELETE  */}
                {((params.row.authorID != currentUser.id ) && !isAdmin) ? <div className="blankButton" ></div> :
                    <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
                }
                {((params.row.authorID != currentUser.id ) && !isAdmin) ? <div className="blankButton" ></div> :
                    <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
                }
            </div>
        );    
    case "CUSTOMER_SHARED":
       return null;     //Should not be an option as these are conectivities IN the community for download, editing, etc.
       
    case "INSTALLED_FROM_COMMUNITY":
        return (
            <div className="dataGridActionButtonRow">
                {/*   SHARE OR REMOVE  */}
                <div className="blankButton" ></div>

                {/*   LAUNCH  */}
                <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> 

                {/*   EDIT & DELETE  */}
                <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
                <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
            </div>
        );         

    default:
        if (DEBUG_MODE) console.error("Error - improper conectivity scope - cannot render EDITOR buttons", params.row.conectivityScope);

}

return null;  
}

function displayAdminButtons(params) {
if (!params || !params.row || !params.row.conectivityScope) return null;

//Render buttons based on the scope of the conectivity
switch (params.row.conectivityScope) {
    
    case "GLOBAL":
        return (
            <div className="dataGridActionButtonRow">

                {/*   SHARE   */}
                <div className="blankButton" ></div>

                {/*   LAUNCH  */}
                {params.row.publicationStatus != "PUBLISHED" || !params.row.active ? <div className="blankButton" ></div> :
                  <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> } 

                {/*   EDIT & DELETE  */}
                {params.row.authorID != currentUser.id ? <div className="blankButton" ></div> :
                <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} /> }
                
                {params.row.authorID != currentUser.id ? <div className="blankButton" ></div> :
                <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} /> }


            </div>
        );                 
        
    case "CUSTOMER_SPECIFIC":
       return (
            <div className="dataGridActionButtonRow">
                {/*   SHARE   */}
                {params.row.createdByCustomerID != currentUser.customerID ? <div className="blankButton" ></div> :
                  <ShareButton onClickCallback={handleStartShare} onClickCallbackParam={params.row.dynamoDBID} />
                }

                 {/*   LAUNCH  */}
                {params.row.createdByCustomerID != currentUser.customerID || !params.row.active? <div className="blankButton" ></div> :
                  <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} />
                } 


                {/*   EDIT & DELETE  */}
                {((params.row.authorID != currentUser.id ) && !isAdmin) ? <div className="blankButton" ></div> :
                    <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
                }
                {((params.row.authorID != currentUser.id ) && !isAdmin) ? <div className="blankButton" ></div> :
                    <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
                }
            </div>
        );
        
    case "INSTALLED_FROM_COMMUNITY":
        return (
            <div className="dataGridActionButtonRow">
                {/*   SHARE OR REMOVE  */}
                <div className="blankButton" ></div>

                {/*   LAUNCH  */}
                <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> 

                {/*   EDIT & DELETE  */}
                <EditButton onClickCallback={handleEdit} onClickCallbackParam={params.row.dynamoDBID} />
                <DeleteButton onClickCallback={handleDelete} onClickCallbackParam={params.row.dynamoDBID} />
            </div>
        );   
    default:
        if (DEBUG_MODE) console.error("Error - improper conectivity scope - cannot render buttons", params.row.conectivityScope);

}  
return null;  
}


function displayLaunchButton(params) {
return (
    <div className="dataGridActionButtonRow">
        {/*   LAUNCH  */}
        {params.row.publicationStatus != "PUBLISHED" || !params.row.active ? <div className="blankButton" ></div> :
          <LaunchButton onClickCallback={handleLaunch} onClickCallbackParam={params.row.dynamoDBID} /> } 
    </div>
);                 
}    

// Pop up a modal for viewing members that participated in the Conectivity

function handleShowFutureInvitees (futureInvitees, teamParticipants, category) {

  
if (DEBUG_MODE >= 2) console.log("View Future Invitees Button pressed.", futureInvitees, teamParticipants, category);    

var tempFutureInvitees = [];

for (var j=0; j<futureInvitees?.length; j++) {
    const tempParticipant = users.find(user => user.id === futureInvitees[j]);

    if (tempParticipant != undefined) tempFutureInvitees.push(tempParticipant);
}

if (DEBUG_MODE >= 2) console.log("Built Future Invitee list:", tempFutureInvitees);    

setFutureInviteesToShow(tempFutureInvitees);

 var tempTeamFutureInvitees = [];

for (var x=0; x<teamParticipants?.length; x++) {
    const tempTeamParticipant = teams.find(team => team.id === teamParticipants[x]);

    if (DEBUG_MODE >= 2) console.log("Retrieved Team:", tempTeamParticipant, teamParticipants[x]);    
    
    if (tempTeamParticipant != undefined)  tempTeamFutureInvitees.push(tempTeamParticipant);
}

if (DEBUG_MODE >= 2) console.log("Built Team Future Invitee list:", tempTeamFutureInvitees, teamParticipants);    

setTeamFutureInviteesToShow(tempTeamFutureInvitees);
setShowModalFutureInvitees(true); //pop-up Modal

}

function displayActiveColumn (params) {
if (!currentUser) return null;

if (params.row.publicationStatus !== "PUBLISHED" && (params.row.createdByCustomerID !== currentUser.customerID)) return "";

return (
    <div className="ContainerNoHeightCenter" style={{width:"100%", height:"20px", position:"relative"}}>
             {params.row.active || true ? "": <div className="checkMarkActive"> &#10060;</div>}
                <Switch
                        name="active"
                        checked={params.row.active}  
                        disabled={!isAdmin}
                        onChange={event => handleRecordConectivityInactive(params.row.dynamoDBID, !params.row.active)}
                        color={"success"}
                        size="small"
                />
    </div>
);
}


//This function processes the transaction data in view of the fetched conectivities and computes all stats needed
function calcConectivityAnalytics () {

if (conectivitiesForDashboard === undefined) return;
if (conectivitiesForDashboard.length === 0) return;

// console.log ("Inputs to build Conectivity Data", conectivitiesForDashboard);

var tempPortfolioWideConectivityAnalytics = {...portfolioWideConectivityAlyticsInitialState}; //Make a copy
tempPortfolioWideConectivityAnalytics.conectivitiesTotal = conectivitiesForDashboard.length;

//First, initialize our array of per-conectivity stats so we have a spot for each conectivity
//This holds all the data for each conectivity that we need for all the different graphs
for (var j = 0; j < conectivitiesForDashboard.length; j++){

    //
    //generate portfolio-wide stats based on each conectivity
    //Update Most / Least CCs 
    if (conectivitiesForDashboard[j].conectCoins > tempPortfolioWideConectivityAnalytics.mostCCs) tempPortfolioWideConectivityAnalytics.mostCCs = conectivitiesForDashboard[j].conectCoins;
    if (conectivitiesForDashboard[j].conectCoins < tempPortfolioWideConectivityAnalytics.leastCCs) tempPortfolioWideConectivityAnalytics.leastCCs = conectivitiesForDashboard[j].conectCoins;
     
     
    //Update Most / Least CCs for published only
     if (conectivitiesForDashboard[j].publicationStatus === "PUBLISHED") {        
            if (conectivitiesForDashboard[j].conectCoins > tempPortfolioWideConectivityAnalytics.mostCCsPublished) tempPortfolioWideConectivityAnalytics.mostCCsPublished = conectivitiesForDashboard[j].conectCoins;
            if (conectivitiesForDashboard[j].conectCoins < tempPortfolioWideConectivityAnalytics.leastCCsPublished) tempPortfolioWideConectivityAnalytics.leastCCsPublished = conectivitiesForDashboard[j].conectCoins;
     }


        //Update Category Counts
       if (conectivitiesForDashboard[j].category.label === "STRESS") {
            tempPortfolioWideConectivityAnalytics.totalStress++;
        } else if (conectivitiesForDashboard[j].category.label === "SOCIAL") {
            tempPortfolioWideConectivityAnalytics.totalSocial++;
        } else if (conectivitiesForDashboard[j].category.label === "TEAM") {
            tempPortfolioWideConectivityAnalytics.totalTeam++;
        } else if (conectivitiesForDashboard[j].category.label === "PERSONAL") {
            tempPortfolioWideConectivityAnalytics.totalDEI++;
        }
        if (conectivitiesForDashboard[j].badgesDEI > 0) tempPortfolioWideConectivityAnalytics.totalDEI+=1;
        if (conectivitiesForDashboard[j].badgesCS > 0) tempPortfolioWideConectivityAnalytics.totalCS+=1;
    
    //Update category counts based on pub status
    if (conectivitiesForDashboard[j].publicationStatus === "DRAFT") {
        
       if (conectivitiesForDashboard[j].category.label === "STRESS") {
            tempPortfolioWideConectivityAnalytics.draftsStress++;
        } else if (conectivitiesForDashboard[j].category.label === "SOCIAL") {
            tempPortfolioWideConectivityAnalytics.draftsSocial++;
        } else if (conectivitiesForDashboard[j].category.label === "TEAM") {
            tempPortfolioWideConectivityAnalytics.draftsTeam++;
        } else if (conectivitiesForDashboard[j].category.label === "PERSONAL") {
            tempPortfolioWideConectivityAnalytics.draftsPersonal++;
        }
        
        if (conectivitiesForDashboard[j].badgesDEI > 0) tempPortfolioWideConectivityAnalytics.draftsDeiConectivities+=1;
        if (conectivitiesForDashboard[j].badgesCS > 0) tempPortfolioWideConectivityAnalytics.draftsCsConectivities+=1;
        
    } else if (conectivitiesForDashboard[j].publicationStatus === "REVIEW") {
        
       if (conectivitiesForDashboard[j].category.label === "STRESS") {
            tempPortfolioWideConectivityAnalytics.reviewsStress++;
        } else if (conectivitiesForDashboard[j].category.label === "SOCIAL") {
            tempPortfolioWideConectivityAnalytics.reviewsSocial++;
        } else if (conectivitiesForDashboard[j].category.label === "TEAM") {
            tempPortfolioWideConectivityAnalytics.reviewsTeam++;
        } else if (conectivitiesForDashboard[j].category.label === "PERSONAL") {
            tempPortfolioWideConectivityAnalytics.reviewsPersonal++;
        }
       if (conectivitiesForDashboard[j].badgesDEI > 0) tempPortfolioWideConectivityAnalytics.reviewsDeiConectivities+=1;
       if (conectivitiesForDashboard[j].badgesCS > 0) tempPortfolioWideConectivityAnalytics.reviewsCsConectivities+=1;
       if (conectivitiesForDashboard[j].conectivityScope === "GLOBAL") tempPortfolioWideConectivityAnalytics.globalNeedingReview+=1;
       if (conectivitiesForDashboard[j].conectivityScope === "CUSTOMER_SHARED") tempPortfolioWideConectivityAnalytics.sharedNeedingReview+=1;
    
    } else if (conectivitiesForDashboard[j].publicationStatus === "PUBLISHED") {
        
       tempPortfolioWideConectivityAnalytics.publishedTotal++;
       if (conectivitiesForDashboard[j].category.label === "STRESS") {
            tempPortfolioWideConectivityAnalytics.publishedStress++;
        } else if (conectivitiesForDashboard[j].category.label === "SOCIAL") {
            tempPortfolioWideConectivityAnalytics.publishedSocial++;
        } else if (conectivitiesForDashboard[j].category.label === "TEAM") {
            tempPortfolioWideConectivityAnalytics.publishedTeam++;
        } else if (conectivitiesForDashboard[j].category.label === "PERSONAL") {
            tempPortfolioWideConectivityAnalytics.publishedPersonal++;
        }
    
       if (conectivitiesForDashboard[j].badgesDEI > 0) tempPortfolioWideConectivityAnalytics.publishedDeiConectivities+=1;
       if (conectivitiesForDashboard[j].badgesCS > 0) tempPortfolioWideConectivityAnalytics.publishedCsConectivities+=1;
    
    } 
    
    //Update Inactive counts based on those for which the company is specifically opting OUT
    if (conectivitiesForDashboard[j].inactiveForCustomers) {
        if (conectivitiesForDashboard[j].inactiveForCustomers.some(entry => entry === currentUser.customerID)) {
            tempPortfolioWideConectivityAnalytics.inactive +=1;  //Count if  excluded by this company
            if (conectivitiesForDashboard[j].publicationStatus === "PUBLISHED") tempPortfolioWideConectivityAnalytics.publishedInactive++;                    
    
        } 
    }  
    
   //Update portfolio type
    if (conectivitiesForDashboard[j].conectivityScope === "GLOBAL") tempPortfolioWideConectivityAnalytics.globalTotal +=1;
    else if (conectivitiesForDashboard[j].conectivityScope === "CUSTOMER_SPECIFIC") tempPortfolioWideConectivityAnalytics.privateTotal +=1;
    else if (conectivitiesForDashboard[j].conectivityScope === "INSTALLED_FROM_COMMUNITY") tempPortfolioWideConectivityAnalytics.installedFromCommunityTotal +=1;
    else if (conectivitiesForDashboard[j].conectivityScope === "CUSTOMER_SHARED" && conectivitiesForDashboard[j].createdByCustomerID === currentUser.customerID) tempPortfolioWideConectivityAnalytics.sharedToCommunityTotal +=1;
    else if (conectivitiesForDashboard[j].conectivityScope === "CUSTOMER_SHARED" && conectivitiesForDashboard[j].createdByCustomerID !== currentUser.customerID) if (DEBUG_MODE) console.log("Community conectivity.  Not counted", conectivitiesForDashboard[j]);
    else {
        if (DEBUG_MODE >= 2) console.log("Error - dashboard conectivity type error", conectivitiesForDashboard[j]);
    }
}

if (tempPortfolioWideConectivityAnalytics.leastCCs === 99999) tempPortfolioWideConectivityAnalytics.leastCCs = 0;
if (tempPortfolioWideConectivityAnalytics.leastCCsPublished === 99999) tempPortfolioWideConectivityAnalytics.leastCCsPublished = 0;
if (conectivitiesForDashboard) tempPortfolioWideConectivityAnalytics.total = conectivitiesForDashboard.length;


setPortfolioWideConectivityAlytics(tempPortfolioWideConectivityAnalytics);                
// console.log ("Built portfolioWideConectivityAlytics data from transactions:", tempPortfolioWideConectivityAnalytics);

}

async function handleRecordConectivityInactive(conectivityID, setActiveFlag) {
const conectivity = conectivitiesForDashboard.find(c => c.id === conectivityID);
if (!conectivity) return;
 
if (DEBUG_MODE >= 2) console.log("Toggling Conectivity active/inactive", conectivity, setActiveFlag);

    var currentInactiveList = [];
    
    //If Admin && conectivity has been published then allow user to activate or deactivate the Conectivity for the customer
    if (isAdmin) {
        
        //Remove the customer ID from the inactive list if present and no longer marked inactive
        if (setActiveFlag && conectivity.inactiveForCustomers) {		
            var indexToRemove = conectivity.inactiveForCustomers.indexOf(currentUser.customerID);
                
            if (!indexToRemove>-1) {
                // if (DEBUG_MODE >= 2) console.log('Removing customer to make Conectivity active');
                //grab the current list
                currentInactiveList = conectivity.inactiveForCustomers;
                currentInactiveList.splice(indexToRemove,1); //remove current customer
                // if (DEBUG_MODE >= 2) console.log('Found customer to remove at index:', indexToRemove);

            }
        }

        //Add customer to the INACTIVE list for the Conectivity if they should be inactive and not present already in the list
        if (!setActiveFlag) {
            if (!conectivity.inactiveForCustomers) { //No field in this object?
                currentInactiveList = [currentUser.customerID]; //Just add the current customer to an array
                if (DEBUG_MODE >= 2) console.log('Added customer to empty array of inactiveForCustomers', currentInactiveList);
            }  else if (!conectivity.inactiveForCustomers.some(entry => entry === currentUser.customerID)) { //If attribute exists, does it already contain the customer ID?
                if (DEBUG_MODE >= 2) console.log('Adding customer to existing list inactiveForCustomers', conectivity.inactiveForCustomers);

                //grab the current list and push this customer
                currentInactiveList = conectivity.inactiveForCustomers; //grab existing customers
                currentInactiveList.push(currentUser.customerID); //push the new customer onto the existing array
                if (DEBUG_MODE >= 2) console.log('Added customer to existing list inactiveForCustomers', currentInactiveList);

            }
        }
    }

//Now, update the DB                
try {         
    //Get the returned, updated object so we can update our local data with all the fields
    const tempInsertedConectivity = await invokeAPI(updateConectivity, 'updateConectivity', {id:conectivity.id, inactiveForCustomers:currentInactiveList});

    /* HANDLED VIA SUBSCRIPTIONS
    //Update state - find the corresponding object on our page
    const tempConectivities = [...conectivities];   //Copy our state datea
    const indexToEdit = tempConectivities.findIndex ( conectivityRecord => conectivityRecord.id === conectivity.id);
        
    //Edit the item in our local data using the FULL object returned from the backed on the update
    if (indexToEdit > -1) {
        tempConectivities[indexToEdit] = { ... tempInsertedConectivity};
        setConectivities(tempConectivities); //update our master state data on the page
    }
    */
    
    if (DEBUG_MODE >= 2) console.log('Successfully edited INACTIVE CUSTOMERS for conectivity', tempInsertedConectivity);
    
} catch (err) {
    if (DEBUG_MODE >= 2) console.log("Error toggling active / inactive product", err);
}
}



//This function handles the Calendar button by displaying the upcoming schedule
const handleShowScheduleButtonPress = (selectedLaunchRuleToShow) => {

if (DEBUG_MODE >= 2) console.log("User selected SHOW SCHEDULE button", selectedLaunchRuleToShow);

//Show Launch Schedule for ALL rules
if (!selectedLaunchRuleToShow) {
    setUpcomingLaunchesToShow(upcomingLaunches);
}
else {
    
    //Show Launch Schedule for just THIS RULE
    var tempArray = [];
    //Prep the array of launch dates from this particular rule
    for (var j=0; j < selectedLaunchRuleToShow.arrayOfLaunchDates.length; j++) {
        tempArray.push({
            launchDate: selectedLaunchRuleToShow.arrayOfLaunchDates[j],
            usersToInvite:selectedLaunchRuleToShow.usersToInvite,
            teamsToInvite:selectedLaunchRuleToShow.teamsToInvite,
            title: selectedLaunchRuleToShow.title,
            shortLabel: selectedLaunchRuleToShow.shortLabel,
            image: selectedLaunchRuleToShow.image,
            category:selectedLaunchRuleToShow.category,
            conectivityImageURL: selectedLaunchRuleToShow.conectivityImageURL,
        });
        tempArray.sort(compareByLaunchDate);
        if (DEBUG_MODE >= 2) console.log("Prepared future launch schedule from selected launch rule", selectedLaunchRuleToShow, tempArray);
        setUpcomingLaunchesToShow(tempArray);
    }
}

setShowModalUpcomingSchedule(true); //Pop up template selection modal

};

//This function handles the ADD button by first displaying a set of templates
const handleAddButtonPress = () => {

if (DEBUG_MODE >= 2) console.log("User selected ADD button");

setIdOfTemplateSelected("");
setShowModalTemplateSelection(true); //Pop up template selection modal
setShowModalEdit(false); //hide other modals 

};


//Pop up editor once template selected
const handleAdd= () => {

//Did the user selected a template?  Set Object based on any template
var selectedTemplate = templateConectivities.find(templateConectivity => templateConectivity.id === idOfTemplateSelected);
if (DEBUG_MODE > 1) console.log("Template selected; creating draft", idOfTemplateSelected);
var tempConectivity = {};

if (selectedTemplate) {       
    if (DEBUG_MODE > 1) console.log("Template found", selectedTemplate)
    tempConectivity = {...selectedTemplate};  //Make a copy so we don't change our local state data pointed to by the Find above
    
    //Set category based on the selected template
    // setCategoryDropDownSelection(categoryOptions.find(category => category.id === tempConectivity.categoryID));  //Grab the entire object for use as initial value for React-Select

    tempConectivity.isTemplate = false; //Need to set false for the conectivity being created
     tempConectivity.title = "";         //Initialize to a blank Title (or append a (x)) so they are forced to create one rather than accidentally saving with the Template title
    delete tempConectivity.createdAt;   //Remove as non-existent for this new Conectivity
    delete tempConectivity.updatedAt;
    if (DEBUG_MODE >= 2) console.log("Template selected; opening", tempConectivity);
    
    
} else {
    if (DEBUG_MODE > 1) console.error("Error - no matching Template found", idOfTemplateSelected, selectedTemplate);
    tempConectivity = {...CONECTIVITY_INITIAL_STATE};
}

//Set conectivity for our editor component
setConectivityToEdit({...tempConectivity});
setShowModalTemplateSelection(false); //Hide the template selection modal used to launch this
setShowModalEdit(false);    //Hide Modal 
setShowModalAdd(true);      //pop-up Modal with editor for creating a new Conectivity
};

const handleCloseEditor = ({result, message}) => {
    setShowModalEdit(false);
    setShowModalAdd(false);
    setShowModalShare(false);
    setConectivityToEdit(CONECTIVITY_INITIAL_STATE);
    
    if (result !== "CANCELED" && message) {
        if (result) setModalGeneralTitle("Success!!");  
        else setModalGeneralTitle("Ooops!");
        setModalGeneralMessage(message);
        setShowModalGeneral(true);
    }
    if (DEBUG_MODE >= 2) console.log("Closed Conectivity Editor", result, message);
};

//This function handles the EDIT buttons; Pop up the Conectivity Editor
const handleEdit = (conectivityID) => {

const conectivityToEdit = conectivitiesForDashboard.find(c => c.id === conectivityID);
if (!conectivityToEdit) return;

if (DEBUG_MODE >= 2) console.log("Editing conectivity", conectivityToEdit);
setConectivityToEdit({...conectivityToEdit})
setShowModalAdd(false); //hide pop-up Modal 
setShowModalEdit(true); //pop-up Modal 
};

//Process the Get Started Sharing button 
const handleEditShared = () => {
setShowModalStartShare(false);      //hide pop-up Get Started Sharing Modal 
setShowModalShare(true);            //Show the Edit Modal but creating a new conectivity for pubishing 
};

///Delete Functions
//Note at some point this would be handled by backend to do cleanup on related records, like wallet and transactions
async function handleConfirmationDelete () {

setShowSpinner(true); //Show spinners

try {
    var successFlag = await invokeDeleteConectivity ({connectivityToDelete:conectivityToDelete});

    if (successFlag) {
        if (DEBUG_MODE >= 2) console.log('Successfully deleted conectivity', conectivityToDelete);
        setModalGeneralMessage("Conectivity deleted");
        setModalGeneralTitle("Success!!");
    } else {
        setModalGeneralMessage("Something went wrong.  Unable to delete conectivity");
        setModalGeneralTitle("Ooops!");
        if (DEBUG_MODE >= 2) console.log('error deleting conectivity:');
    }
    
} catch (err) {
    setModalGeneralMessage("Something went wrong.  " + err);
    setModalGeneralTitle("Ooops!");
    if (DEBUG_MODE >= 2) console.log('error deleting conectivity:', err);
}

setConectivityToDelete(CONECTIVITY_INITIAL_STATE);
setConectivityToEdit(CONECTIVITY_INITIAL_STATE);
setShowModalDelete(false);   //hide the pop-up
setShowSpinner(false); //Hide spinners
setShowModalGeneral(true);   
}

function buildConectivityRows (conectivities) {

// if (DEBUG_MODE >= 2) console.log("Rebuilding ROWS for conectivities", conectivities);

//Basic safe guards
if (conectivities === undefined || conectivities === null) return [];
if (conectivities.length === 0) return  [];
// if (perConectivityAnalytics === undefined || perConectivityAnalytics === null) return  [];
// if (perConectivityAnalytics.length === 0) return  [];
// if (portfolioWideConectivityAlytics === undefined ) return  [];

//Build rows for Data Grid
var tempConectivityRows = [];

for(var i = 0; i<conectivities.length; i++){
    
    tempConectivityRows[i] = {
        id:i+1,
        dynamoDBID: conectivities[i].id, 
        author: conectivities[i].author,
        authorID: conectivities[i].authorID,
        title:conectivities[i].title,
        description: conectivities[i].description,
        // image:conectivities[i].imageS3.url,
        image:conectivities[i].image,
        conectCoins:conectivities[i].conectCoins,
        badgesDEI:conectivities[i].badgesDEI,
        badgesCS:conectivities[i].badgesCS,
        // customerName: !conectivities[i].createdByCustomer ? "---" : conectivities[i].createdByCustomer.name,
        createdByCustomerID : conectivities[i].createdByCustomerID,
        signupDays:formatDays(conectivities[i].signupDays),
        category:conectivities[i].category.name,
        shortLabel:conectivities[i].category.label,
        active: (!isAdmin || !conectivities[i].inactiveForCustomers ? true : conectivities[i].inactiveForCustomers.some(entry => entry === currentUser.customerID)==false),
        publicationStatus: (conectivities[i].isTemplate ? "TEMPLATE" : conectivities[i].publicationStatus), 
        isTemplate:conectivities[i].isTemplate,
        conectivityScope: conectivities[i].conectivityScope,
        // scopeUrl:(conectivities[i].conectivityScope === "CUSTOMER_SPECIFIC") ?  conectivities[i].createdByCustomer.logo : CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH,
    };


    //Set Customer fields
    if (conectivities[i].conectivityScope === "CUSTOMER_SPECIFIC" && conectivities[i].createdByCustomerID) {
        
        //CONECTIVITY WRITTEN BY A SPECIFIC COMPANY
        const tempCustomer = customers.find(customer => customer.id === conectivities[i].createdByCustomerID);    //All clients will have a customer array, even if only 1 entry
        tempConectivityRows[i].customerName = (tempCustomer ? tempCustomer.name : "");
       tempConectivityRows[i].scopeUrl = (tempCustomer ? tempCustomer.logo : "");
    } else {

        //CONECTERE SPONSORED (GLOBAL) PRODUCTS and DEFAULT STORE PRODUCTS      
        tempConectivityRows[i].customerName = "Conectere";
        tempConectivityRows[i].scopeUrl = CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
    }
}

// if (DEBUG_MODE >= 2) console.log("Built Conectivity Rows:", tempConectivityRows);
return tempConectivityRows;

}


//handle cancel in delete modal
const handleCancelDelete = () => {
    setShowModalDelete(false);
    setConectivityToDelete(CONECTIVITY_INITIAL_STATE);
    // if (DEBUG_MODE >= 2) console.log("Cancelled Conectivity Delete.");
};

//This function handles the delete buttons from list of items
//Pop-up a modal and ask conectivity to confirm. Handle confirmation
const handleDelete = (conectivityID) => {
const conectivityToDelete = conectivitiesForDashboard.find(c => c.id === conectivityID);
if (!conectivityToDelete) return;
if (DEBUG_MODE >= 2) console.log("DELETE button pressed.  Delete Conectivity=", conectivityToDelete); 
setConectivityToDelete(conectivityToDelete);
setShowModalDelete(true); //pop-up Modal
};



// Pop up a modal for launching a conectivity for a specific user or team
const handleLaunch = (conectivityID) => {

const thisConectivityToLaunch = conectivitiesForDashboard.find(c => c.id === conectivityID);
if (!thisConectivityToLaunch) return;
if (DEBUG_MODE >= 2) console.log("LAUNCH button pressed.  Launch Conectivity=", thisConectivityToLaunch); 
setConectivityToLaunch(thisConectivityToLaunch);
setShowModalLaunch(true); //pop-up Modal

};



const handleCancelPreview = () => {
setShowModalPreview(false);
// setConectivityToPreview(CONECTIVITY_INITIAL_STATE);
if (DEBUG_MODE >= 2) console.log("Cancelled Conectivity Preview.");
};

function handleCancelTemplateSelection() {
setShowModalTemplateSelection(false);
}

const handleSelectTemplate = (id) => {
  
//Set conectivity object to preview 
var tempConectivityToPreview = templateConectivities.find(templateConectivity => templateConectivity.id === id);

if (DEBUG_MODE >= 2) console.log("User selected template",id, tempConectivityToPreview);

if (tempConectivityToPreview) {
    setConectivityToPreview({...tempConectivityToPreview});
    setIdOfTemplateSelected(id);
}
else {
    setConectivityToPreview(null);
    setIdOfTemplateSelected("");
}

};



//React component for showing a Template icon graphic with state for selection by the user
//Receives a conectivity OPTION and displays an ICON
const TemplateConectivityIcon = ({templateConectivity, idOfTemplateSelected, setIdOfTemplateSelected}) => {
if (!templateConectivity) return null;

//Inner handler
function handleTemplateUserClick (e) {
    e.stopPropagation();        //Prevent outer Card OnClick from firing 
    if (DEBUG_MODE >= 2) console.log("User selected a template", templateConectivity.id); 
    //record the ID of the tempalte for which to display the submenu
    setIdOfTemplateSelected(templateConectivity.id);
}

//Calculate style based on whether THIS Template is currently selected by the user
var  border="none";

if (idOfTemplateSelected === templateConectivity.id) {
    border = "3px solid #f203ff";
}
    

return (
    <div 
        className="templateConectivityIconWrapper" 
        style={{border:border, fontSize:"0.8rem", padding:'10px', margin:"10px 10px", color:"white", backgroundColor:(templateConectivity.category==null ? COLOR_BLUE_TEXT : setHeaderColorByCategory(templateConectivity.category.label))}}
        onClick={handleTemplateUserClick}
    >
        <div className="ContainerVerticalCenter" style={{height:"100%", width:"100%"}}>
                <img className="ccImgXXL" src={templateConectivity.category==null ? CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH : setConectivityImageUrlByCategory(templateConectivity.category.label)} alt='' />
            <div style={{fontSize:"1.0rem", padding:'10px', color:"white", backgroundColor:(templateConectivity.category==null ? COLOR_BLUE_TEXT : setHeaderColorByCategory(templateConectivity.category.label))}}>
                {templateConectivity.label}
            </div>
        </div>

    </div>
);

};



//Share FROM GRID handler
function handleStartShare (conectivityID) {

const conectivityToShare = conectivitiesForDashboard.find(c => c.id === conectivityID);
if (!conectivityToShare) return;

if (DEBUG_MODE >= 2) console.log("Share button pressed from grid.  Conectivity=", conectivityToShare);
// initializeEdit({conectivityToEdit:conectivityToShare, isForSharing:true});
setConectivityToEdit({...conectivityToShare});
setShowModalStartShare(true);
setShowModalEdit(false);
}


//Withdraw handler
function handleWithdraw (e) {
if (e!=null) e.stopPropagation();        //Prevent outer OnClick from firing 

// setExpandedPreview(false);  //Make sure the preview is closed
setShowModalWithdraw(true);
setShowModalEdit(false);
}

//Cancel Share/Withdraw handler
function handleCancelStartShare (e) {
if (e !=null) e.stopPropagation();        //Prevent outer OnClick from firing 
setShowModalShare(false);
setShowModalStartShare(false);
setShowModalWithdraw(false);
setShowModalShareSuccess(false);
setShowModalWithdrawSuccess(false);
setShowModalEdit(false);
}



// This code causes the editor to lose focus
// function handleEditorChange (content) {
//     setConectivityToEdit({...conectivityToEdit, instructions: editorRef.current.getContent()}); 
//     if (DEBUG_MODE >= 2) console.log("Editor change called");
//     editorRef.current.focus(); //Set the focus back on the editor ....
// }


const handleCancelRemoveFromPortfolio = () => {
    setShowModalRemoveSuccess(false);            
    setConectivityToPreview(CONECTIVITY_INITIAL_STATE);
    if (DEBUG_MODE >= 2) console.log("Cancelled Uninstall Shared Conectivity.");
};


async function handleConfirmationRemoveFromPortfolio () {

//Safety check - should not happen
//Is the user trying so unsubscribe to a Conectivy they authored?  If so, return

var authoredByThisCompany = false;

const authoringUser = users.find (user => user.id === conectivityToPreview.authorID);

if (authoringUser !== undefined)
    if (authoringUser.customerID === currentUser.customerID) authoredByThisCompany = true;
    
if (authoredByThisCompany === false) {
    if (DEBUG_MODE >= 2) console.log("Cannot Remove From Portfolio - Authored by the same company");
}

setShowSpinner(true); //Pop up spinners to lock screen

try {

    //Confirm the company is, in fact, already subscribing to this conectivity            
    var alreadySubscribing = false;
    
     if (isSubscribing(conectivityToPreview, currentUser.customerID)) alreadySubscribing = true;


    //Check the list to confirm the customer does not ALREADY subscribe to the Conectivity
    if (alreadySubscribing) {


         const newSubscriberList = conectivityToPreview.subscribingCustomers.filter( customerID => customerID != currentUser.customerID); //Include all BUT this company
        
        if (DEBUG_MODE >= 2) console.log("Created new subscriber list after removing this customer", newSubscriberList, currentUser, conectivityToPreview);

        const updatedConectivity = {
            id:conectivityToPreview.id, 
            subscribingCustomers:newSubscriberList,
        };
        
        //Get the returned, updated object so we can update our local data with all the fields
        const tempInsertedConectivity = await invokeAPI(updateConectivity, 'updateConectivity', updatedConectivity);
        if (DEBUG_MODE >= 2) console.log('Successfully uninstalled shared conectivity', conectivityToPreview);
        if (DEBUG_MODE >= 2) console.log('Successfully UNsubscribed customer to conectivity', tempInsertedConectivity);
        setShowModalRemoveSuccess(true);
        setConectivityToPreview(CONECTIVITY_INITIAL_STATE);
            
    }
} catch (err) {
    if (DEBUG_MODE >= 2) console.log('error removing conectivity to customer portfolio:', err);
}

setShowModalRemoveFromPortfolio(false);          //hide the pop-up
setShowSpinner(false);              //Hide the spinners to lock screen

}


const handleCancelFutureInviteesModal = () => {

setShowModalFutureInvitees(false);
setFutureInviteesToShow ([]);
setTeamFutureInviteesToShow([]);

if (DEBUG_MODE >= 2) console.log("Cancelled Future Invitees Modal");
};  




//Component for displaying participants for an upcoming scheduled conectivity
function displayFutureInvitees () {
if (futureInviteesToShow.length === 0 && futureTeamInviteesToShow.length === 0 ) return null;

 return (
     <div> 

 {futureInviteesToShow.length === 0 ? "" :
        <div >
            <div style={{fontSize:"1.3rem", fontWeight:"500", color:COLOR_BLUE_TEXT}} >Invitees</div>

            <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
            
                {futureInviteesToShow.map((target, index) => ( 
                                <div key={target.id} className="avatarContainer" > 
                                    <div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
                                        {!target.avatarUrl  ? <div className="avatarInitials" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
                                    </div>
                                    <span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>{target.firstName + " " + target.lastName}</span>
                                </div>

                ))}
              </div>
          </div>
 }

{futureTeamInviteesToShow.length === 0 ? "" :
        <div>
            <div style={{fontSize:"1.3rem", fontWeight:"500", color:COLOR_BLUE_TEXT, paddingTop:"clamp(8px,2vw,20px)"}} >Teams</div>
              
            <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
            
                {futureTeamInviteesToShow.map((target, index) => ( 
                                <div key={target.id} className="avatarContainer"> 
                                    <div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
                                        {!target?.avatarUrl  ? <div className="avatarInitials" > {target.name.substring(0,3).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.name.substring(0,3).toUpperCase()} /> }
                                    </div>
                                    <span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.name)}}>{target.name}</span>
                                </div>

                ))}
              </div>                  
          </div>
}
      </div>
    );
}

const handleCloseLaunchModal = ({result, message}) => {
setConectivityToLaunch(CONECTIVITY_INITIAL_STATE);
setShowModalLaunch(false);

if (result && result !== "CANCELED" && message) {
    if (result) setModalGeneralTitle("Success!!");  
    else setModalGeneralTitle("Ooops!");
    setModalGeneralMessage(message);
    setShowModalGeneral(true);
}

if (DEBUG_MODE >= 2) console.log("Closed Conectivity Editor", result, message);
};


const handleTabChange = (event, newValue) => {
setTabIndexValue(newValue);
};  


//REACT USECALLBACK to avoid re-render
// https://alexsidorenko.com/blog/react-list-rerender/
const onSearchTermChangeCallback = useCallback ((value) => {
 setSearchTerm(value);
 // if (DEBUG_MODE >= 2) console.log("Search bar CALLBACK called", value);

},[]);

const onFilterChangeCallback = useCallback ((activeFilters) => {
 setActiveFilters(activeFilters);
 // if (DEBUG_MODE >= 2) console.log("Search bar QUICK FILTER CALLBACK called", activeFilters);

},[]);

const searchBarIsActiveCallback = useCallback ((active) => {
 setSearchActive(active);
 // if (DEBUG_MODE >= 2) console.log("Search bar IS ACTIVE CALLBACK called", active);
},[]);


//Handle access by unauthenticated user
if (authState !== "signedin"  || !currentUser) {
//   if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
// if (authState === "signedout") popUpLoginWindow();
return null;
}


//Render function for authenticated user 
//confirm access to this page
if (!isSuperAdmin && !isAdmin && !permissionAnalytics) return null;
else return (
<Authenticator>

  <ModalNoBackgroundFixed showModal={showModalStartShare} closeCallback={handleCancelStartShare} cardColor={TEAM_COLOR} >
    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
            <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt=''  />  
        </div>
        
        <div className="TextStyle6" style={{ fontWeight:"600", paddingBottom:"clamp(8px,2vw,20px)"}}>
            {displayConectereCommunityText ({displayFooter:true})}
        </div>
        
        
        <div className="ContainerMaxHeightCenterFullWidth wrap"  >
            <div style={{flex:"3"}}>
                <p >
                    Utilize an effective collaborative team building exercise?  Working on a new DEI-related initiative in your industry?
                    Have a unique way of celebrating and showing appreciation in a remote work environment? 
                </p>
                 <h3 className="purple" style={{paddingTop:"clamp(6px, 1vh, 10px)", textAlign:"left"}}>
                     Share!
                </h3>                     
            </div>
            <div className="ContainerNoHeightCenter positionRelative" style={{flex:"1"}}>
                <img  className="imageCenterPortrait " src={process.env.PUBLIC_URL+'/img/community-s-1.png'} alt=''/>  
            </div>
        </div>

        <div className="ContainerNoHeightCenter" style={{height:"100%", width:"100%"}}>
            <div className="ContainerVerticalLeft" >
               <div className="ContainerVerticalLeft" >

                   <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)"}}>
                        <p style={{paddingRight:"clamp(4px,1vw,20px)"}}> &#9671; </p>
                        <p >Start by creating a public version of your conectivity </p>
                    </div>

                    <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)"}}>
                        <p style={{paddingRight:"clamp(4px,1vw,20px)"}}> &#9671; </p>
                        <p >Remove any confidential information</p>
                    </div>
                    <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)" }}>
                        <p style={{paddingRight:"clamp(4px,1vw,20px)"}}> &#9671; </p>
                        <p >Include only links to publicly-available resources</p>
                    </div>
                   <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)"}}>
                        <p style={{paddingRight:"clamp(4px,1vw,20px)"}}> &#9671; </p>
                        <p  >After processing, your conectivity will be published to the Conectere Community for use by others!</p>
                    </div>
                    <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)"}}>
                        <p style={{paddingRight:"clamp(4px,1vw,20px)"}}> &#9671; </p>
                        <p>By proceeding, you agree to Conectere’s &nbsp; 
                            <a href={LEGAL_PRIVACY_POLICY} target="_blank" rel="noreferrer"> Privacy Policy </a>                             
                            &nbsp; and &nbsp; 
                             <a href={LEGAL_TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">  Terms & Conditions </a>
                        </p>
                    </div>
                </div>
             </div>
            <div className="ContainerVerticalCenter" >
                <DisplayCardConectivityPreview conectivityToPreview={conectivityToEdit} hideViewButton={true} />
            </div>
        </div>

        <div className="ContainerNoHeightCenter" style={{height:"100%", width:"100%"}}>
               <div className="adminConectivitiesTemplateButtonsWrapper">

                    <button className="btn btn-primary" onClick={event => handleEditShared (true)} >Get started
                        <svg width="41" height="21" viewBox="0 0 41 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line y1="10.4999" x2="40.3846" y2="10.4999" strokeWidth="1.61538"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M40.3848 9.65609C40.1188 9.68006 39.8494 9.69231 39.5771 9.69231C34.6703 9.69231 30.6925 5.71453 30.6925 0.807693C30.6925 0.535428 30.7048 0.266023 30.7288 0H29.1078C29.0875 0.266572 29.0771 0.535927 29.0771 0.807693C29.0771 6.60668 33.7782 11.3077 39.5771 11.3077C39.8489 11.3077 40.1183 11.2974 40.3848 11.2771V9.65609Z" fill="#5DADE2"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M40.3848 11.3439C40.1188 11.3199 39.8494 11.3077 39.5771 11.3077C34.6703 11.3077 30.6925 15.2855 30.6925 20.1923C30.6925 20.4646 30.7048 20.734 30.7288 21H29.1078C29.0875 20.7334 29.0771 20.4641 29.0771 20.1923C29.0771 14.3933 33.7782 9.69231 39.5771 9.69231C39.8489 9.69231 40.1183 9.70263 40.3848 9.72291V11.3439Z" fill="#5DADE2"/>
                        </svg>
                    </button>                       
                    
                    {/*
                        <button className="adminConectivitiesButtonStyle2" style={{marginLeft:"20px"}} aria-label="skip" onClick={handleCancelStartShare} >
                            Cancel
                        </button>
                    */}
                </div>
        </div>
    </div>
 </ModalNoBackgroundFixed >
 
{/* 3.6.2023 - NO LONGER USED
 <ModalNoBackgroundFixed showModal={showModalWithdraw} closeCallback={handleCancelStartShare} cardColor={TEAM_COLOR}  >
    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
            <img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
        </div>
        <div className="ContainerNoHeightCenter" >
          <div className="TextStyle4" >WITHDRAW SHARED CONECTIVITY</div>
        </div> 
        <div className="ContainerNoHeight" style={{height:"100%", width:"100%"}}>
            <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>
                <div className="TextStyle4" style={{paddingTop:"50px", width:"200px", textAlign:"center", color: BALANCE_COLOR, borderBottom:"1px solid " + BALANCE_COLOR}}>Instructions</div>
                
               <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>
                    <div className="TextStyle3" style={{paddingTop:"30px"}}>Withdrawing a conectivitity removes the conectivity from the Conectere Community and returns the conectivity to private status.</div>
                    <div className="TextStyle3" style={{paddingTop:"30px"}}></div>
                    <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)"}}>
                        <div className="TextStyle3" style={{paddingRight:"10px"}}> &#9671; </div>
                        <div className="TextStyle3" >You will still be able to make use of the conectivity in your company's Conectere employee health and well-being portfolio</div>
                    </div>
                    <div className="ContainerNoHeightFlexLeft" style={{ paddingLeft:"clamp(4px,1vw,10px)" }}>
                        <div className="TextStyle3" style={{paddingRight:"10px"}}> &#9671; </div>
                        <div className="TextStyle3" >The Conectivity will be unusable and hidden from employees of other companies</div>
                    </div>
                 </div>
                <div className="adminConectivitiesTemplateButtonsWrapper">
                    <button className="adminConectivitiesButtonStyle1" aria-label="done"  onClick={event => handleUpdatedSharedStatus(false)} >
                        Mark Private
                    </button>
                    
                    <button className="adminConectivitiesButtonStyle2" style={{marginLeft:"20px"}} aria-label="skip" onClick={handleCancelStartShare} >
                        Cancel
                    </button>
                </div>
            </div>
            <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%", paddingLeft:"clamp(4px,1vw,10px)"}}>
                <div className="TextStyle4" style={{paddingTop:"50px", width:"200px", textAlign:"center", borderBottom:"1px solid " + BALANCE_COLOR}}>Conectivity</div>
                <div className="ContainerNoHeight" style={{transform: "scale(0.8)"}}>
                    <DisplayCardConectivityPreview conectivityToPreview={conectivityToEdit} hideViewButton={true}/>
                </div>
            </div>
        </div>
    </div>
 </ModalNoBackgroundFixed >
  

<ModalNoBackgroundFixed showModal={showModalShareSuccess} closeCallback={event => setShowModalShareSuccess(false)} cardColor={TEAM_COLOR}  >
    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
            <img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
        </div>
        <div className="ContainerNoHeightCenter" >
          <div className="TextStyle4" >CONECTIVITY SHARED</div>
        </div> 
        <div className="ContainerVerticalCenter" style={{height:"100%", width:"100%"}}>
            <div className="TextStyle4" style={{paddingTop:"30px"}}>Success! </div>  
            <div className="TextStyle3" style={{paddingTop:"30px"}}>Thank you for sharing your Conectivity with the Conectere community.  
            The Conectivity will be reviewed and posted shortly.</div>
         </div>
    </div>
 </ModalNoBackgroundFixed >
*/}

<ModalNoBackgroundFixed showModal={showModalWithdrawSuccess} closeCallback={event => setShowModalWithdrawSuccess(false)} cardColor={TEAM_COLOR}  >
    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}  > <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt='' /> </div>
        <div className="ContainerNoHeightCenter" >
          <div className="TextStyle4" >CONECTIVITY WITHDRAWN</div>
        </div> 
        <div className="ContainerVerticalCenter" >
            <div className="TextStyle3" style={{paddingTop:"30px"}}>The Conectivity has been withdrawn from the Conectere community and marked private.</div>
         </div>
    </div>
 </ModalNoBackgroundFixed >


<Modal show={showModalPreview } size="lg" onHide={handleCancelPreview} >
    <Modal.Header closeButton>
      <Modal.Title>PREVIEW</Modal.Title>
    </Modal.Header>
    <Modal.Body>
          <DisplayCardConectivityPreview conectivityToPreview={conectivityToPreview} hideViewButton={false} />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancelPreview} >
        <div > Close </div>
      </Button>
    </Modal.Footer>
 </Modal>

<ConectivityEditor  
    showModalAdd={showModalAdd} showModalEdit={showModalEdit} showModalShare={showModalShare} 
    conectivityToEdit={conectivityToEdit} 
    handleCloseEditor={handleCloseEditor}  
/>

    
 <Modal show={showModalDelete} onHide={handleCancelDelete} size="xl"  dialogClassName="defaultBootstrapModal">
    <Modal.Header closeButton>
      <Modal.Title>DELETE CONECTIVITY</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="ContainerNoHeight" style={{padding:"10px 20px"}}>
                <DisplayCardConectivityPreview conectivityToPreview={conectivityToDelete} hideViewButton={true} expanded={true}/>
        </div>

    </Modal.Body>
    <Modal.Footer>
      <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmationDelete} > Delete </button>
      <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCancelDelete} >  Cancel  </button>               
    </Modal.Footer>
 </Modal>

<ModalNoBackground showModal={showModalTemplateSelection} closeCallback={handleCancelTemplateSelection} cardColor={TEAM_COLOR}  >

    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}} > 
            <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt=''/>  
        </div>
        
        <div className="ContainerNoHeight" style={{height:"100%", width:"100%"}}>
            <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>
            
                <div className="TextStyle3" style={{paddingTop:"50px", width:"200px", marginBottom:"10px", textAlign:"center", borderBottom:"1px solid " + BALANCE_COLOR}}>Templates</div>
                

                 <div className="ContainerNoHeightCenter" style={{flexWrap:"wrap", width:"100%", padding:"10px 10px", borderRadius:"10px", backgroundColor:"#fafafa"}}>
                       
                    {templateOptions.map((templateConectivity) => ( 
                    
                        <TemplateConectivityIcon key={templateConectivity.id} templateConectivity={templateConectivity} idOfTemplateSelected={idOfTemplateSelected} setIdOfTemplateSelected={handleSelectTemplate}/>

                    ))}
                </div>

                <div className="adminConectivitiesTemplateButtonsWrapper">
                  <button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleAdd} > Create draft </button>
                  <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="done" onClick={handleCancelTemplateSelection} >  Cancel  </button>  
                </div>
            </div>
            
            <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%", paddingLeft:"clamp(4px,1vw,10px)"}}>
                <div className="TextStyle3" style={{paddingTop:"50px", marginBottom:"10px", width:"200px", textAlign:"center", borderBottom:"1px solid " + BALANCE_COLOR}}>Preview</div>
                <div className="ContainerNoHeight">
                
                    {idOfTemplateSelected === "" ? null :
                    <DisplayCardConectivityPreview conectivityToPreview={conectivityToPreview} hideViewButton={true} expanded={true}/>
                    }
                    
                </div>
            </div>
        </div>
    </div>
        
</ModalNoBackground >


 <ModalNoBackgroundFixed showModal={showModalRemoveFromPortfolio} closeCallback={handleCancelRemoveFromPortfolio} cardColor={TEAM_COLOR}  >
    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div className="modalNoBkgImage ccImgXXXXLSquare"  style={{borderColor: TEAM_COLOR}}> 
            <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  alt=''/>  
        </div>
        <div className="ContainerNoHeightCenter" >
          <div className="TextStyle4" >UNINSTALL CONECTIVITY</div>
        </div> 
        <div className="ContainerNoHeightCenter" style={{height:"100%", width:"100%", minHeight:"400px"}}>
            <div className="ContainerVerticalStart" style={{flex:1,  height:"100%", minHeight:"250px"}}>
                    <div className="TextStyle3" style={{paddingTop:"0px", color: BALANCE_COLOR}}>Conectivities unistalled from your portfolio will no longer be available 
                    to your employees and will be removed from your conectivity control panel</div>

                <div className="adminConectivitiesTemplateButtonsWrapper" style={{paddingTop:"40px"}}>
                    <button className="adminConectivitiesButtonStyle1" aria-label="done" onClick={handleConfirmationRemoveFromPortfolio} >
                        Uninstall 
                    </button>
                    
                    <button className="adminConectivitiesButtonStyle2" style={{marginLeft:"20px"}} aria-label="skip" onClick={handleCancelRemoveFromPortfolio} >
                        Cancel
                    </button>
                </div>
            </div>
            <div className="ContainerNoHeight" style={{flex:1}}>
                <DisplayCardConectivityPreview conectivityToPreview={conectivityToPreview} disableLaunchButton/>
            </div>
        </div>
    </div>
 </ModalNoBackgroundFixed >

<ModalNoBackgroundFixed showModal={showModalRemoveSuccess} closeCallback={event => setShowModalRemoveSuccess(false)} cardColor={TEAM_COLOR}  >
    <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
        <div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
            <img  className="avatarImageCenterPortrait ccImagePadding" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt='' />  
        </div>
        <div className="ContainerNoHeightCenter" >
          <div className="TextStyle4" >CONECTIVITY UNINSTALLED</div>
        </div> 
        <div className="ContainerVerticalCenter" style={{height:"100%", width:"100%"}}>
            <div className="TextStyle3" style={{paddingTop:"30px", color: BALANCE_COLOR}}>The Conectivity has been removed from your portfolio</div>
         </div>
    </div>
 </ModalNoBackgroundFixed >

<Modal show={showModalFutureInvitees} onHide={handleCancelFutureInviteesModal}  centered >
    <Modal.Header closeButton>
      <Modal.Title>INVITEES</Modal.Title>
    </Modal.Header>
    <Modal.Body>
          <center> {displayFutureInvitees()}</center>
    </Modal.Body>
 </Modal>

    <ModalUpcomingSchedule showModal={showModalUpcomingSchedule} closeModalCallback={() => setShowModalUpcomingSchedule(false)} upcomingLaunchesToShow={upcomingLaunchesToShow} users={users} teams={teams} />

     <ConectivityLauncher  
        showModalSendInviteToConect={showModalLaunch}  
        conectivityToProcess={conectivityToLaunch} 
        handleCloseLauncher={handleCloseLaunchModal}  
     />
     
    <div className="adminPage">
        <div className="dashboardContainer ">    
            <div className="dashboardCardRow">
                <CCdisplayCardConectivityStats conectivityAnalytics={portfolioWideConectivityAlytics} permissionEditor={currentUser.permissionEditor}/>
                {/* <CCdisplayCardConectivityActivity analytics={portfolioWideConectivityAlytics}/> */}
                 <DisplayCardConectivityPortfolios analytics={portfolioWideConectivityAlytics}  /> 
                <CCdisplayCardConectivityFavorites conectivities={conectivitiesForDashboard} analytics={portfolioWideConectivityAlytics}/>
            </div>

             <div className="fullWidth" style={{padding:"10px 0px"}}>
               <SearchInputBar 
                   useDarkInput={false} 
                   useAnchorPostion={false} 
                   enableSearchInput={true} 
                   onSearchStringChangeCallback={onSearchTermChangeCallback} 
                   onFilterChangeCallback={onFilterChangeCallback} 
                   searchBarIsActiveCallback={searchBarIsActiveCallback} 
                   searchTermInitialValue={searchTerm} 
                   searchActiveInitialValue={searchActive} 
                   quickFilters={isSuperAdmin ? QUICK_FILTERS_FOR_CONTECTIVITIES_SUPER_ADMIN : QUICK_FILTERS_FOR_CONTECTIVITIES}
                   buttonPositionLeft
                   searchContainerAlignedLeft     
                   zIndex="99"
               />
            </div>
            <div className="dashboardTitle">
                <Tabs value={tabIndexValue} onChange={handleTabChange} aria-label="tabs">
                    <Tab 
                        label={
                            <Badge color="secondary" badgeContent={!portfolioWideConectivityAlytics.globalNeedingReview ? 0 : portfolioWideConectivityAlytics.globalNeedingReview}>
                                CONECTERE PORTFOLIO
                            </Badge>
                        }
                        value={0} 
                        iconPosition="end"
                    />
                    <Tab label=" PRIVATE PORTFOLIO" value={1} />
                    <Tab label="INSTALLED FROM COMMUNITY" value={2} icon={<CloudDownloadIcon style={{ transform:"translate(0%,-10%)"}}/>} iconPosition="end"/>
                    
                    {isSuperAdmin ? 
                        <Tab 
                            label="SHARED TO COMMUNITY"
                            value={3} 
                            icon={
                                <Badge color="secondary" badgeContent={!portfolioWideConectivityAlytics.sharedNeedingReview ? 0 : portfolioWideConectivityAlytics.sharedNeedingReview}>
                                    <CloudUploadIcon style={{ transform:"translate(0%,-10%)"}}/>
                                </Badge>
                                } 
                            iconPosition="end"
                        /> 

                    : null }
                </Tabs>
                <AddButton onClickCallback={handleAddButtonPress} hoverText="Write a conectivity!" />
            </div> 
            <div className="dataGridWrapperTop" >
                <MenuDataGrid 
                    dataGridRows={conectivityRowsToDisplay} 
                    dataGridColumns={conectivityColumns}
                />
            </div>
        </div>
    </div>   

</Authenticator> 
);

};

export default AdminConectivitiesPage;
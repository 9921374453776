//Admin page by which superAdmin manages the images available in the system
//including  assignment to Conectivties

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
// import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {Authenticator, AmplifySignOut } from '@aws-amplify/ui-react';

//Queries and Mutations
import { listImages  } from '../../shared/graphql/queries';


//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

//Utils
import { queryDataTableWithPagination }  from "../../shared/utils/databaseUtils";

//Our Analytics components
import CCLineChart from "./Components/lineChart/lineChart"
import CCdisplayCard from "./Components/displayCard/displayCard"
import CCStackedAreaChart from "../../shared/Components/stackedAreaChart/stackedAreaChart"
import CCRadarChart from "./Components/radarChart/radarChart"
import CCStackedBarChart from "../../shared/Components/stackedBarChart/stackedBarChart"
import CCPieChart from "../../shared/Components/pieChart/pieChart"


import {ringData, ringColors, spendData, userData, testData, conectivityData, groupData} from "./Components/testData" 

//User Authentication context
import { AuthContext } from '../../shared/context/authContext';

// import config from './aws-exports'     //load in AWS constants into a 'config' element, I think

// const {
//     aws_user_files_s3_bucket_region: region,
//     aws_user_files_s3_bucket: bucket
// } = config

//Here's the start of our Javascrpt App
const AdminAnalytics = () => {
  
    //define variables to hold form state; initialize to empty
    const imageInitialState = {name: "", title: "", description: "", width: -1, height: -1, url: "", category: {name: ""}};
    const [imageDetails, setImageDetails] = useState(imageInitialState);
    const [imageUploaded, setImageUploaded] = useState(null);  //holds an internal URL generated for  the image file just uploaded into the browser by the user
    const [imageFileUploaded, setImageFileUploaded] = useState(null);
    
    
    //variable for holding on this page data for cusomters 
    const [images, setImages] = useState([]);
    const [filteredImages, setFilteredImages] = useState([]);
    const [filteredDropDownSelection, setFilterDropDownSelection] = useState(-1);
    const [isUpdatingImage, setIsUpdatingImage] = useState(false);  //Used as a state variable set to TRUE when updating the backend
    const [imageToDelete, setImageToDelete] = useState(imageInitialState);         //Used to hold a image that is to be deleted
    const [imageToEdit, setImageToEdit] = useState(imageInitialState);              //Used to hold a image that is to be edited


   //variable to control confirmation Modal if image presses DELETE; default to hidden
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    
    //variable to store dynamic list of categories as an option when creating a new image
    // const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryOptionForEdit, setCategoryOptionForEdit] = useState(-1);



   //New Authentication context
    const {
            authState,  
            currentUser, 
            isSuperAdmin,
            isAdmin,
            
    }
        = useContext(AuthContext);   


    //Get image data upon opening this page, return an array of existing images, if any
    useEffect(() => {
        fetchImages();  //call our funciton
    }, [authState]);
    
    //let's write the function to grab the image data
    async function fetchImages() {
        try {
 
            // gather image data from backend
          const images =  await queryDataTableWithPagination(listImages, "listImages", { limit: 5000, });
           setImages(images);
           setFilteredImages(images); //start unfiltered
           setFilterDropDownSelection(-1);          

        //   if (DEBUG_MODE >= 2) console.log(imageData)
        
        } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching images', err); }
     }

   
  
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">ANALYTICS</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  }
  
  const height="50px";
  const width="1400px";

 //Handle access by unauthenticated user
  if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
  }

 //Render function for authenticated user 
  return (
    <div className="adminPage">

    <div >

        <div style={{width:width, height:height}}>
            <CCLineChart legend={false} yAxis={false} xAxis={false} data={testData} title="" dataKeyX="month" dataKeyY1="users" grid={false} height={height} width={width} toolTip={false}/>
        </div>    

        <div className="dashboardCardRow">
            <CCdisplayCard title="Users & Teams" text1="27/30" text2="75% active" text3="7/7 teams active" footer="*this month"/>
            <CCdisplayCard title="Users & Teams" text1="27/30" text2="75% active" text3="7/7 teams active" footer="*this month"/>
            <CCdisplayCard title="Users & Teams" text1="27/30" text2="75% active" text3="7/7 teams active" footer="*this month"/>
        </div>

        <CCStackedBarChart data={spendData} title="Montly Spend $$  " 
          dataKeyX="month" 
          dataKeyY1="Employee Store" color1="#5DADE2"
          dataKeyY2="Conectere" color2="#BB8FCE"
          grid/>


        <CCStackedBarChart data={userData} title="Users" 
          dataKeyX="month" 
          dataKeyY1="balance" color1="#5DADE2"
          dataKeyY2="team" color2="#BB8FCE"
          dataKeyY3="personal" color3="#F7DC6F"
          dataKeyY4="social" color4="#82E0AA"
          grid/>

        <CCRadarChart 
          data={groupData} title="Team Engagement" 
          dataKeyAngle="subject" 
          radarName1="Team A" dataKey1="A" color1="#8884d8"
          radarName2="Team B" dataKey2="B" color2="#82ca9d"
        grid/>
          
          


        <CCStackedAreaChart data={conectivityData} title="Conectivity By Category" 
          dataKeyX="month" 
          dataKeyY1="balance" color1="#5DADE2"
          dataKeyY2="team" color2="#BB8FCE"
          dataKeyY3="personal" color3="#F7DC6F"
          dataKeyY4="social" color4="#82E0AA"
          grid/>


        <div className="dashboardCardRow">
          <CCPieChart data={ringData} title="My Ring" dataKey="value" colors={ringColors} fillColor="#8884d8" innerRadius="0" outerRadius="80" />
          <CCPieChart data={ringData} title="My Ring" dataKey="value" colors={ringColors} fillColor="#8884d8" innerRadius="0" outerRadius="80" />
          <CCPieChart data={ringData} title="My Ring" dataKey="value" colors={ringColors} fillColor="#8884d8" innerRadius="0" outerRadius="80" />
        </div>

    </div>
</div>
                    
  );
}

export default AdminAnalytics;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import '../../../../shared/styles/App.css';
import  { CONECTERE_CONFIG_DATA, USER_INITIAL_STATE, DEBUG_MODE, DAVY_GREY, COLOR_BLUE_HEADER, HEADER_FULL_MODE, HEADER_COMPRESSED_MENU_MODE, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, BALANCE_COLOR } from '../../../../shared/data/conectereConfigData';

//React & Amplify imports include state & context 
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Navigate  } from "react-router-dom";  

//Our Context
import { AuthContext } from '../../../../shared/context/authContext';
import { NotificationContext } from '../../../../shared/context/notificationsContext';
import { ConectivityContext } from '../../../../shared/context/conectivitiesContext';
import { DisplayContext } from '../../../../shared/context/displayContext';            //User Authentication Context
import { CalendarContext } from '../../../../shared/context/calendarContext';

//Our components
// import AnalyticsMenu from "../menuAnalytics/menuAnalytics";
// import ConectivitiesMenu from "../menuConectivities/menuConectivities";
import ProfileMenu from "../menuHeaderProfile/menuHeaderProfile";
import ModalNoBackground from "../../../../shared/Components/modalNoBackground/modalNoBackground";
import MenuSidebar from "../menuSidebar/menuSidebar";                           //Sidebar Menu
import MenuHeaderRightCompressed from "../menuHeaderRightCompressed/menuHeaderRightCompressed";                           
import { FileUploader } from  "../../../../shared/Components/fileUpload/fileUpload";

// Utils
import { HeaderLink, NotificationsLink, HeaderLinkLaunchPad, HeaderCommunityButton, validImageFileType, displayAwards, displayPermissions, displaySidebarLeftAwards, NavbarGradientCommunityButton } from "../../../../shared/utils/generalUtils";
import { deleteImageFile, storeImageFile }  from "../../../../shared/utils/storageUtils";
import { invokeAPI, updateDisplayThemeSelection }  from "../../../../shared/utils/databaseUtils";
import { displayTeams } from "../../../../shared/utils/userAndTeamUtils";
	 

//Icons
import { FlightTakeoff, BeachAccess, Equalizer, Business, Person, Group, LineStyle, Timeline, ShoppingCart, AccountBalanceWallet, Home, TrendingUp, PhotoLibrary, Storefront, List} from '@material-ui/icons';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventIcon from '@mui/icons-material/Event';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import RedeemIcon from '@mui/icons-material/Redeem';
import CloudIcon from '@mui/icons-material/Cloud';
import HubIcon from '@mui/icons-material/Hub';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';


//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import GroupsIcon from '@mui/icons-material/Groups';
import { IconButton } from '@mui/material';

//Icons
import {MailOutline, Add, Edit, DeleteOutline, PermIdentity, PhoneAndroid} from '@material-ui/icons';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import FaceIcon from '@mui/icons-material/Face';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import HighlightIcon from '@mui/icons-material/Highlight';

//Queries and Mutations
import { updateUser } from '../../../../shared/graphql/mutations';

const Navbar = React.memo(() => {

   // Display context
	const {
			currentReactPage,
			innerWindowWidth, innerWindowHeight,
			darkMode, toggleDarkMode,
	} = useContext(DisplayContext); 
	
   // Authentication context
	const {
			authState,
			currentCognitoUser,
			currentUser, setCurrentUser,
			isSuperAdmin,
			isAdmin,
			userWallet,
			ccAuthLogoutUser,  showSignInPopUp,
			triggerForgotPasswordProcess,
	} = useContext(AuthContext);    

 

	// Notification context
	const {
			notifications, 
			displayNotificationsModal,
			} = useContext(NotificationContext);

	// Conectivity context
	const {
			numberOfLaunchesToday
	} = useContext(ConectivityContext);

   // Calendar context
	const { handleToggleCloudIntegration } = useContext(CalendarContext);
	
	const [imageUploaded, setImageUploaded] = useState(null);  //holds an internal URL generated for  the image file just uploaded into the browser by the user
	const [imageFileUploaded, setImageFileUploaded] = useState(null);
	const [newImageUploaded, setNewImageUploaded] = useState(false);                //state data that shows a change to the avatar image has been made during edit, including just removing the original
	
	//define variables to hold form state; initialize to empty
	const [userToView, setUserToView] = useState(USER_INITIAL_STATE);              //Used to hold a user PROFILE
	
   //Modal state variables 
	const [showModalProfile, setShowModalProfile] = useState(false);
	const [showModalUserStats, setShowModalUserStats] =useState(false);       
	// const [showModalUpcomingSchedule, setShowModalUpcomingSchedule] = useState(false);

   
	//state variable for invoking (rendering) React redirct function
	const [redirectToPage, setRedirectToPage] = useState(null);
	const [headerDisplayMode, setHeaderDisplayMode]  = useState('FULL');   //Control weather to display text as part of the header links

	// console.log("HEADER RENDER", redirectToPage)

	 //On page render, initialize our redirect var
	 useEffect(() => {
		// console.log("HEADER REDIRECT USEEFFECT", redirectToPage)
		if (redirectToPage) setRedirectToPage(null);
	},[redirectToPage]);  
 
	//Adjust header based on window dimensions   
	useEffect(() => {
	 
		// if (DEBUG_MODE >= 2) console.log("Header - adjusting mode based on new width",innerWindowWidth);

		if (innerWindowWidth > HEADER_FULL_MODE) {
			setHeaderDisplayMode('FULL');
			// if (DEBUG_MODE >= 2) console.log("Header mode:FULL");
		} else if (innerWindowWidth > HEADER_COMPRESSED_MENU_MODE) {
			setHeaderDisplayMode('ICONS_ONLY');
			// if (DEBUG_MODE >= 2) console.log("Header mode:ICONS_ONLY");
		 } else {
			setHeaderDisplayMode('COMPRESSED_MENU');
			// if (DEBUG_MODE >= 2) console.log("Header mode:COMPRESSED_MENU");
		 }
			
	},[innerWindowWidth]);   
 
   
	const handleUserDropdown =  useCallback(async (eventKey) => {
		
		//Safety check - this fires if menu is closed (eventKey will be null)
		if (!eventKey) return;        
		setUserToView(currentUser); //User must be logged in so grab the state data

		if (DEBUG_MODE >= 2) console.log("User Dropdown selected", eventKey);
		if (DEBUG_MODE >= 2) console.log("User Dropdown selected for user", currentUser);

		switch (eventKey) {
			case "wallet":
				if (DEBUG_MODE >= 2) console.log("User Wallet selected.");
				setRedirectToPage("/userAccountHistory"); //Jump to home on Logout
				break;
			case "settings":
				if (DEBUG_MODE >= 2) console.log("Account Settings selected.");
				setRedirectToPage("/userAccountSettings"); //Jump to home on Logout
				break;
				
			 case "profile":
				if (DEBUG_MODE >= 2) console.log("User Profile selected.");
				setImageUploaded(currentUser.avatarUrl);    //Grab the S3 URL if present so we can display it to the user
				setNewImageUploaded(false);                  //No new image yet - Original logo for now
				setShowModalProfile(true); //pop-up Modal
				break;
			case "streaks":
				if (DEBUG_MODE >= 2) console.log("User streaks selected.");
				setShowModalUserStats(true); //pop-up Modal
				break;                
			case "slack":
				if (DEBUG_MODE >= 2) console.log("SLACK selected.");
				handleToggleCloudIntegration("SLACK");
				break;    
			case "ms365":
				if (DEBUG_MODE >= 2) console.log("MSFT selected.");
				handleToggleCloudIntegration("MS365");
				break;    
			case "google":
				if (DEBUG_MODE >= 2) console.log("GOOGLE selected.");
				handleToggleCloudIntegration("GOOGLE");
				break;    
			case "reset_pwd":
				if (DEBUG_MODE >= 2) console.log("Reset Password selected.");
				triggerForgotPasswordProcess();
				break;  

			case "log_out":
			   try {
					setUserToView(USER_INITIAL_STATE);
					setShowModalProfile(false); //hide Modal
					await ccAuthLogoutUser(); //ccall our auth context to signout the user
					if (DEBUG_MODE >= 2) console.log('User signed out!');
					
				} catch (error) {
					if (DEBUG_MODE >= 2) console.log('error signing out: ', error);
				}
				break;  

			default:
				if (DEBUG_MODE >= 2) console.log("Error - invalid parameter from profile menu.",eventKey);
				break;
		}
	},[currentUser]);
	
	function displayCredentials () {
		var returnString = '';
		if (isAdmin && isSuperAdmin) return("(superAdmin, admin)");       
		if (isSuperAdmin) return("(superAdmin)");
		if (isAdmin) return("(admin)"); 
		return(returnString);
	}
	
	   //handle close modal
	const handleCancelShowProfile = () => {
			setShowModalProfile(false);
			if (DEBUG_MODE >= 2) console.log("Closed User Profile.");
		};
		
	const handleCloseModalMessage = () => {

		//Article on redirection: https://dev.to/projectescape/programmatic-navigation-in-react-3p1l 

		// setModalMessage("");  //Clear message to hide Modal

		// setRedirectToPage("/"); //Jump to home on Logout

	};
	
   const HomeLogo = () => {
	  return (
		<a className="imageContainer ccImgXXLSquare" style={{flexShrink:'0', flexGrow:'0'}}  href="https://conectere.net/">
			<img className="imageCenterPortrait ccImgXXLSquare" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} alt="logo"/>
		</a>
	  );
	};

/*
	//This function handles the Calendar button by displaying the upcoming schedule for this particular user
	const handleShowScheduleButtonPress = () => {
		
		if (DEBUG_MODE >= 2) console.log("User selected SHOW SCHEDULE button in Header");

		setShowModalUpcomingSchedule(true); //Pop up template selection modal

	};
*/    
   function leftMenu ({currentBalance}) {
	/*
	  //Include a link back to the Main page if the user is not logged in and not on the main page, such as trying to log in on UserHome page
	  if (authState !== "signedin") {
		 if (currentReactPage.pathname !=="/") {
		 return (
			 <div className="headerChildLeft">
				 <HomeLogo />
			 </div>
			 );  
		} else return (
			<div className="headerChildLeft">
			 </div>
			);
	  }
	  
	  
	  if (currentReactPage.pathname ==="/") {
		 return (
			 <div className="headerChildLeft">
				  <MenuSidebar  />
				  <HomeLogo />
			 </div>
		  );
	  }
	*/
			
	  return (
		  <div className="headerChildLeft">
			{authState !== "signedin" ? null : 
				<>
					{/* <MenuSidebar  /> */}
					{/* <HomeLogo /> */}
					{headerBalances({ coins:userWallet.currentBalance, badgesDEI:userWallet.badgesDEI, badgesCS:userWallet.badgesCS,  badgesSpotlight:userWallet.badgesSpotlight}) }
					{/*(window.location.href && window.location.href.includes("staging")) || DEBUG_MODE > 0 ?  <div className="TextStyle1 red">ACCOUNT-STAGING</div>:null */}
				</>
			}
		  </div>
	  );
	}
	
	
	const CenterMenu = () => {
		return null;
	};

	const RightMenu = () => {
		// Display context
		const { darkMode } = useContext(DisplayContext);

		// if (DEBUG_MODE >= 2) console.log("Right Menu Check", authState);
	
		if (authState === "signedout" || authState === "signIn_failure") return (
			<div className="headerChildRight" >
			
					{/*
					 <Link className="headerLinkButton" to="/SignUpWizard">
						   <button className="headerButtonStyle2" >Free Trial</button>
					 </Link>

				
					<button disabled={showSignInPopUp} className="headerButtonStyle1" onClick={popUpLoginWindow}>
							<div className="ContainerNoHeightCenter" >
								<AccountCircleIcon className="ProfileIcon" /> 
								<div style={{padding:"0.3rem 1.0rem", fontWeight:"600"}}> Login </div>
							</div>
						</button>

					*/}

			</div>
		);

		if (!currentUser) return null; //Signed in but no Current user

		 return (
			 <div className="headerChildRight navbarRightContainerSpacing" >
				  {/* <div className={`headerChildRightInnerWrap  ${darkMode ? "black-background" : ""}`}>
					 <HeaderLink text='Home' icon = {<HomeIcon />}  to="/UserHome"  /> 
					 <HeaderLinkLaunchPad  />
					 {!currentUser.customer.configDetails.spotlightsEnabled ? "" :
						 <HeaderLink text='Spotlights' icon = {<HighlightIcon  style={{transform: "translate(0px,-2px) rotate(-145deg) "}}/> }   to="/employeeSpotlightFeed" /> 
					 }
					 <HeaderLink text='Leaderboards' icon = {<EmojiEventsIcon /> }  to="/EmployeeLeaderboards" /> 
					 <HeaderLink text='Store' icon = {<RedeemIcon /> }   to="/EmployeeStore" /> 
					 <HeaderLink text='Calendar' icon = {<CalendarTodayIcon /> }   to="/UserConectivitySchedule"  numNotifications={numberOfLaunchesToday}/>                      
					 <MenuHeaderRightCompressed  /> 
					 <NotificationsLink numNotifications={notifications && notifications.length > 0 ? notifications.length : 0} callback={displayNotificationsModal} />
				  </div> */}

				{/* <HeaderCommunityButton callback={() => setRedirectToPage("/conectereCommunity")} />                       */}
				<NavbarGradientCommunityButton callback={() => setRedirectToPage("/conectereCommunity")} />                      


				 <div className="ContainerNoHeightCenter" >
					<IconButton onClick={async (e) => {
						toggleDarkMode();	//callback to update the local display then save the user preference to DB
						if (DEBUG_MODE) console.log("Storing new display mode value to DB", (darkMode ? "LIGHT" : "DARK"));
						if (currentUser && currentUser.id) await updateDisplayThemeSelection({option: (darkMode ? "LIGHT" : "DARK"), userID:currentUser.id});  	//Store the NEW state that the user is toggling to
					}}>
						<LightModeOutlinedIcon className = "TextStyle4_3" style={{color:(darkMode ? "white" : DAVY_GREY)}}/>
					</IconButton>
					 <ProfileMenu currentUser={currentUser} callback={handleUserDropdown} isAdmin={isAdmin} />     
				 </div>

			 </div>
		 );
	};    

	//Function to handle user removing the image during ADD
	const handleClearImage = () => {
		setNewImageUploaded(true);      //Record this event for use if the user saves the changes
		setImageUploaded("");       //Clear the URL as if we don't have a file yet
		setImageFileUploaded("");   //clear file to be uploaded to S3 bucket 

		if (DEBUG_MODE >= 2) console.log("User Removed the existing avatar during ADD.");
	};



	const handleImageUpload = (e) => {
			const file = e.target.files[0];  //grab the first file in the list of files selected by the user
			
			if (validImageFileType(file)) {
				try {                  
					const localURL = URL.createObjectURL(file);                  
					console.log ("URL to local file in web browser:", localURL);                  
					setImageUploaded(localURL);  //update 'photo' so as to display a preview to the user
					setImageFileUploaded(file);  //save file for uploading to S3 bucket if the user hits the ADD button
					setNewImageUploaded(true);  //record this event        
					console.log ("Successfully generated internal URL and stored FILE locally")       
				} catch (err) {
					if (DEBUG_MODE >= 2) console.log("Error locally processing selected product", err);
				}
			}
			else {
					if (DEBUG_MODE >= 2) console.log("Error, wrong file type", file.type);
			}
	};
	
   async function handleUpdateProfile () {

	  if (DEBUG_MODE >= 2) console.log("Updating profile for user to view", userToView);

	  //This should only be set if original avatar photo was cleared and, possibly, if a NEW image file was uploaded
	  //Did the user upload a NEW avatar image file?
	  let tempUrl="", fileUploaded = false, avatarWidth = 0, avatarHeight = 0;
	  try {

		 if (newImageUploaded) {              
			//Delete the user's current image from the S3 bucket
			if (userToView.avatarUrl !== "") {
			   await deleteImageFile(userToView.avatarUrl);
			   if (DEBUG_MODE >= 2) console.log('Deleted original avatar image:', userToView.avatarUrl);
			}

			//New image to upload?
			if (imageFileUploaded) {
			   var img = document.getElementById('uploadedImagedIDEdit');                        
			   avatarWidth = img.naturalWidth;  avatarHeight = img.naturalHeight;                       
			   if (DEBUG_MODE >= 2) console.log("Image - width", avatarWidth, "height:", avatarHeight);
			   const {successFlag, url} = await storeImageFile(imageFileUploaded);
			   if (successFlag) {
				  fileUploaded = true; tempUrl = url; if (DEBUG_MODE > 1) console.log ("Successfully wrote user's avatar image to S3 ", tempUrl);
			   } else {
				  fileUploaded = false;  if (DEBUG_MODE > 0) console.error ("ERROR - failed avatar image to S3");                  
			   }    
			} else if (DEBUG_MODE > 1) console.log ("User removed original avatar but did not upload a new one");               
			   
			//Update user profile, which could be just removing the current image
			var tempUpdatedUser = await invokeAPI(updateUser, 'updateUser', 
			   {
				  id: userToView.id,
				  avatarHeight: avatarHeight,
				  avatarWidth: avatarWidth,
				  avatarUrl: tempUrl,
			   });
			if (DEBUG_MODE >= 2) console.log('Updated user profile', tempUpdatedUser);
			setCurrentUser(tempUpdatedUser);
			}
			else {
				console.log ("User made no change to the avatar");
			}
 
		 } catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error writing avatar image file to S3", err);
			if (fileUploaded) await deleteImageFile(tempUrl);
		 }
			
		setImageUploaded(null);         //clear the URL to the uploaded image
		setImageFileUploaded(null);     //clear the file itself
		setShowModalProfile(false);
		if (DEBUG_MODE >= 2) console.log("Closed User Profile.");            
		
	}
		
	//React component for handling messages
	
	//React component for balances
	function headerBalances({coins, badgesDEI, badgesCS,  badgesSpotlight,}) {
		

		//Calculate sizes based on display context as MUI Icons do not like Clamp as they are SVGs.  Remove if we create our own badge
		
		var imageHeight =  Math.floor(1.5 * innerWindowWidth / 100);    //Get 1.0vw
		// if (DEBUG_MODE >= 2) console.log("Balances - initial values", fontSize, imageHeight);

		if (imageHeight < 18) imageHeight = 18; if (imageHeight > 32) imageHeight = 32;
		// if (DEBUG_MODE >= 2) console.log("Balances - initial values", fontSize, imageHeight);

		imageHeight = imageHeight.toString()+'px';  

		return displaySidebarLeftAwards({enableCountUp:true, fontSize:'med', paddingBetweenAwards:"clamp(4px, 0.5vw, 10px)", imageHeight:imageHeight, coins:coins, badgesDEI:badgesDEI, badgesCS:badgesCS,  badgesSpotlight:badgesSpotlight, includeBackground:true, darkMode, showZeros:false});

	}
 
   const handleCloseUserStatsModal = () => {
			setShowModalUserStats(false);
			// if (DEBUG_MODE >= 2) console.log("Cancel button called")
		};

	//Return Header for authenticated user
	if (authState !== "signedin"  || !currentUser) return null;

	return (
		<>
			<ModalNoBackground showModal={showModalProfile} closeCallback={handleCancelShowProfile} cardColor={TEAM_COLOR} centered>
				<div className="modalNoBkgInnerCard TextStyle4 purple-background isCard white" >
					<div className = "ContainerNoHeightCenter" style={{width:"100%", height:"100%"}}>
						<div className="ContainerVerticalLeft ">
							<div className="ContainerNoHeightFlexLeftTop"  style={{padding:'5px'}}>
								<div className="TextStyle6A white"  > {userToView.firstName + " " + (!userToView.middleName  ? "" : userToView.middleName.substr(0,1)) + " " + userToView.lastName} </div>
								<div className="TextStyle2 white"  style={{padding:"0px 0px 4px 0px"}}> {userToView.pronouns} </div>
							</div>
							<div className="ContainerNoHeightFlexLeft"  style={{padding:'5px'}}><div className="TextStyle4 white"  style={{ paddingTop:"5px"}}> {userToView.title} </div></div>
							<div className="ProfileNameDivider"></div>
							<div className="ContainerNoHeightFlexLeft"  style={{padding:'5px'}}><div style={{width:"20px",paddingRight:'2rem'}}><AccountCircleIcon className="TextStyle5" /> </div><div > {!currentCognitoUser ? "" : currentCognitoUser.username} {displayCredentials()}</div></div>
							<div className="ContainerNoHeightFlexLeft"  style={{padding:'5px'}}><div style={{width:"20px",paddingRight:'2rem'}}><MailOutline className="TextStyle5" /> </div><div > {userToView.email} </div></div>
							<div className="ContainerNoHeightFlexLeft"  style={{padding:'5px'}}><div style={{width:"20px",paddingRight:'2rem'}}><GroupsIcon className="TextStyle4" /></div> <div > {displayTeams(userToView)} </div></div>
							<div className="ContainerNoHeightFlexLeft"  style={{padding:'5px'}}><div style={{width:"20px",paddingRight:'2rem'}}><BusinessIcon className="TextStyle6A" /></div><div > {userToView.customer.name} </div></div>
						</div>
						<div className="ProfileAvatarUploadContainer">
							<div >
									{imageUploaded ? 
										<div className="avatarImage avatarImageXLarge "><img id="uploadedImagedIDEdit" className="avatarImageCenterPortrait" src={imageUploaded} alt='' style={{ padding: '0px'}} /></div>   
										: 
										<FileUploader
										imageChangeCallback={handleImageUpload} 
										fileTypesToAccept="image/png, image/jpeg"
										buttonText="Upload Photo"
										color="white"
										/>                                  
									}                                                  
							</div>
							<div className="ContainerNoHeightCenter" style={{padding:'5px'}}>
								{!imageUploaded ? null : <button  className="buttonStyle1 buttonStyle1HeaderBlue " aria-label="save" onClick={handleClearImage} > Change </button> }
								{!newImageUploaded ? null :   <button  className="buttonStyle1 buttonStyle1HeaderBlue "  aria-label="cancel" onClick={handleUpdateProfile} > Save </button>}
							</div>
						</div>
					</div>
					<div className = "ContainerNoHeightCenter white" style={{width:"100%", paddingTop:"clamp(8px,2vw,16px)"}}>
						<div>Permissions:</div>
						{displayPermissions({
							permissionAnalytics:userToView.permissionAnalytics, 
							permissionEditor:userToView.permissionEditor, 
							permissionLaunch:userToView.permissionLaunch, 
							permissionOrders:userToView.permissionOrders, 
							permissionBilling:userToView.permissionBilling,
							fontSize:"1.2rem",
							color:"white",
							withLabels:true,
						})}
					</div>  
				</div>
			</ModalNoBackground>    


			{authState !== "signedin" || currentUser === null ? "" :
			<ModalNoBackground showModal={showModalUserStats} closeCallback={handleCloseUserStatsModal} cardColor={COLOR_BLUE_HEADER} centered>
				<div className="modalNoBkgInnerCard" >
					<div className="ContainerNoHeightCenter TextStyle5 blue" style={{padding:'10px'}}> 
							<LocalFireDepartmentIcon className="TextStyle6 orange"/>
							CURRENT STREAKS 
							<LocalFireDepartmentIcon className="TextStyle6 orange"/>
					</div>           
					<div className = "ContainerNoHeightSpaceBetween fullWidth wrap" style={{padding:'clamp(4px,1vw,10px)'}}>
						<div className = "ContainerVerticalStart balance" style={{padding:'clamp(4px,1vw,10px)'}}>
							<div className="TextStyle5" > 
								BALANCE  
							</div>
							<div className="imageContainer ccImgXXXLSquare" style={{borderColor: BALANCE_COLOR, border:'5px solid'}} > 
								<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} alt=''/>  
							</div>
							<div className="TextStyle6"  > 
								{currentUser.userProgressData.balanceStreakCount === null ? 0 : currentUser.userProgressData.balanceStreakCount}
							</div>
							<div className="TextStyle2"  > 
								{currentUser.userProgressData.stressPeriod}     
							</div>                        
						</div>
						<div className = "ContainerVerticalStart team" style={{padding:'clamp(4px,1vw,10px)'}}>
							<div className="TextStyle5 "> 
								TEAM  
							</div>
							<div className="imageContainer ccImgXXXLSquare" style={{borderColor: TEAM_COLOR, border:'5px solid'}}> 
								<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} alt=''/>  
							</div>
							<div className="TextStyle6" > 
								{currentUser.userProgressData.teamStreakCount  === null ? 0 : currentUser.userProgressData.teamStreakCount}  
							</div>
							<div className="TextStyle2"  > 
								{currentUser.userProgressData.teamPeriod}      
							</div>                         
						</div>                        
						<div className = "ContainerVerticalStart social" style={{padding:'clamp(4px,1vw,10px)'}}>
							<div className="TextStyle5 social" > 
								SOCIAL  
							</div>
							<div className="imageContainer ccImgXXXLSquare"  style={{borderColor: SOCIAL_COLOR, border:'5px solid'}}> 
								<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} alt=''/>  
							</div>
							<div className="TextStyle6"  > 
								{currentUser.userProgressData.socialStreakCount  === null ? 0 : currentUser.userProgressData.socialStreakCount}   
							</div>
							<div className="TextStyle2" > 
								{currentUser.userProgressData.socialPeriod}     
							</div>                         
						</div>                        
						<div className = "ContainerVerticalStart growth" style={{padding:'clamp(4px,1vw,10px)'}}>
							<div className="TextStyle5"  > 
								GROWTH  
							</div>
							<div className="imageContainer ccImgXXXLSquare" style={{borderColor: GROWTH_COLOR, border:'5px solid'}}> 
								<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} alt=''/>  
							</div>
							<div className="TextStyle6"  > 
								{currentUser.userProgressData.growthStreakCount  === null ? 0 : currentUser.userProgressData.growthStreakCount}  
							</div>
							<div className="TextStyle2" > 
								{currentUser.userProgressData.individualPeriod}   
							</div>                            
						</div>                   
					</div>
				</div>

			</ModalNoBackground >
			}        
			
			{/*<ModalUpcomingSchedule showModal={showModalUpcomingSchedule} closeModalCallback={() => setShowModalUpcomingSchedule(false)} upcomingLaunchesToShow={upcomingLaunchesCreatedByThisUser} users={users} teams={teams} /> */}

			
			{/* TOP OF PAGE */}    
				<div className={`topBar navBar ${darkMode && "darkMode"}`}>     
					{leftMenu ({currentBalance:(userWallet && userWallet.currentBalance ? userWallet.currentBalance : 0)})}            
					{/* <CenterMenu /> */}
					<RightMenu />
				</div>
			
			{/* Add buffer space so pages do not overlap with Header, which is 50px tall and a fixed position */}
			
			{/*
				<div style={{height:"50px"}}> </div> 
			*/}
			
			{!redirectToPage ? "" : <Navigate to={redirectToPage} />}

		</>  
	);
});

export default React.memo(Navbar);


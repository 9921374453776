//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';
import React from 'react';

import "./displayCardTeamStatsModal.css";

import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const CCdisplayCardTeamStatsModal = React.memo(({analytics}) => {

    const footer="";

    // if (DEBUG_MODE >= 2) console.log("Team Stats Card called", analytics);

    return (

        <div className="displayCardTeamStatsModal">
            <span className="displayCardTeamStatsTitleModal"><center>{analytics.name}</center></span>
            {!analytics.title ? "" : <span className="displayCardTeamStatsTitle">{analytics.title}</span>}

            <Divider width={80} />

            <div className="displayCardTeamStatsModalScoreCardRow">
                <div className="displayCardTeamStatsModalScoreCardVertical">
                    <div className="displayCardTeamStatsModalValue" ><center>{analytics.relativeConectivityScore.toFixed(1)} </center></div>
                    <div className="displayCardTeamStatsModalSubtitle"  >Score</div>
                </div>
                <div className="displayCardTeamStatsModalScoreCardVertical">
                    <div className="displayCardTeamStatsModalValue" ><center>{analytics.teamRank} </center></div>
                    <div className="displayCardTeamStatsModalSubtitle"  >Rank</div>
                </div>

                <div className="displayCardTeamStatsModalScoreCardVertical">
                    <div className="displayCardTeamStatsModalValue" ><center>{analytics.conectivitiesCompleted}</center></div>
                    <div className="displayCardTeamStatsModalSubtitle" >Conectivities</div>

                </div>
            </div>

            <div className="displayCardTeamStatsModalTextRow">
                <div className="displayCardLabel" >Distribution</div>
            </div >

            <div className="displayTeamCardModalTextRowPadding">
                <div className="displayCardTeamStatsModalText" style={{color:"#5DADE2"}}>Balance:</div><div className="displayCardTeamStatsModalText">{analytics.stressConectivitiesCompleted.toFixed(1)}</div>
                <div className="displayCardTeamStatsModalText" style={{color:"#BB8FCE"}}>Team:</div><div className="displayCardTeamStatsModalText">{analytics.teamConectivitiesCompleted.toFixed(1)}</div>
                <div className="displayCardTeamStatsModalText"  style={{color:"#82E0AA"}}>Social:</div><div className="displayCardTeamStatsModalText">{analytics.socialConectivitiesCompleted.toFixed(1)}</div>
                <div className="displayCardTeamStatsModalText"  style={{color:"#F7DC6F"}}>Personal:</div><div className="displayCardTeamStatsModalText">{analytics.individualConectivitiesCompleted.toFixed(1)}</div>
            </div >

            <div className="displayCardTeamStatsModalTextRow">
                <div className="displayCardLabel" >Achievements</div>
            </div >

            <div className="displayTeamCardModalTextRowPadding">
                    <div className="displayCardFavoritesCost">{analytics.coinsAwarded.toFixed(1)}<img className="ccImgXL" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}  /></div>
                    <div className="displayCardFavoritesCost">{analytics.badgesDEIAwarded.toFixed(1)}<img className="ccImgXL" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH}  /></div>
                    <div className="displayCardFavoritesCost">{analytics.badgesCSAwarded.toFixed(1)}<img className="ccImgXL" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH}  /></div>
            </div >

            <span className="displayCardTeamStatsModalTextFooter">{footer} </span>
        </div>
        
    );

});


export default CCdisplayCardTeamStatsModal;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//
//  copyright - 2020, 2021

//Admin page by which a customer manages the items available in their Swag Store
import "./adminEmployeeStore.css"

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD, COLOR_BLUE_TEXT, TEAM_COLOR, DATA_GRID_HIDE_ROW_THRESHOLD1, DATA_GRID_HIDE_ROW_THRESHOLD2, PRODUCT_INITIAL_STATE } from '../../shared/data/conectereConfigData';


//React & Amplify
import React, { useEffect, useState, useContext } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Context
import { AuthContext } from '../../shared/context/authContext';
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ProductsContext } from '../../shared/context/productsContext';              //Products context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context

//Queries and Mutations
import { createProduct, deleteProduct, updateProduct} from '../../shared/graphql/mutations';


//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Material UI and my components therefrom
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import Switch from '@mui/material/Switch';
import Select from 'react-select';   //Version with support from multiple selections


//Our components
import CCdisplayCardProductActivity from "./Components/displayCardProductActivity/displayCardProductActivity";
import CCdisplayCardProductStats from "./Components/displayCardProductStats/displayCardProductStats";
import CCdisplayCardProductFavorites from "./Components/displayCardProductFavorites/displayCardProductFavorites";
import { FileUploader } from  "../../shared/Components/fileUpload/fileUpload";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";

//Icons
import StarIcon from '@mui/icons-material/Star';
import HighlightIcon from '@mui/icons-material/Highlight';
import RedeemIcon from '@mui/icons-material/Redeem';

//Utils
import { compareProductsByTitle, compareProductsByDateCreated, compareProductsLeastFavorite, compareProductsMostFavorite} from "../../shared/utils/productUtils";
import { displayAwards, validImageFileType, truncateStringWithDots, EditButton, DeleteButton, SummaryButton, RadarButton, AddButton } from "../../shared/utils/generalUtils";
import { deleteImageFile, storeImageFile }  from "../../shared/utils/storageUtils";
import { invokeAPI }  from "../../shared/utils/databaseUtils";

//Here's the start of our Javascrpt App
const AdminEmployeeStorePage = () => {
 
	// Context
	const { authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics, permissionBilling, permissionOrders,   } = useContext(AuthContext);    
	const { customerOptionsNoAll, customers } = useContext(CustomerContext);  
	const { transactionsFiltered } = useContext(AnalyticsContext);
	const { products } = useContext(ProductsContext);  
	const { setShowSpinner, innerWindowWidth } = useContext(DisplayContext); 
	
	// local state
	const [productDetails, setProductDetails] = useState(PRODUCT_INITIAL_STATE);
	const [productImageOriginalURL,setProductImageOriginalURL] = useState(null);
	const [imageUploaded, setImageUploaded] = useState(null);  //holds an internal URL generated for  the product file just uploaded into the browser by the user
	const [imageFileUploaded, setImageFileUploaded] = useState(null);
	// const [imageFileUploadedEdit, setImageFileUploadedEdit] = useState(null);       //holds the image uploaded during edit
	// const [imageUploadedEdit, setImageUploadedEdit] = useState(null);               //holds an internal URL generated for  the image file just uploaded into the browser by the user
	const [newImageUploaded, setNewImageUploaded] = useState(false);                //state data that shows a change to the product image has been made during edit, including just removing the original
	const [productRows,setProductRows]=useState([]);                                //Formatted rows for user grid    
	const [defaultProductSwitchState,setDefaultProductSwitchState] = useState(false);
	const [globalProductSwitchState,setGlobalProductSwitchState] = useState(false);
	
   //variable to control confirmation Modal if product presses DELETE; default to hidden
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);

	//vars for assigning products to other companies
	const [customerSelection, setCustomerSelection] = useState(null);
		
	//state variables for error messages
	const [showModalError, setShowModalError] = useState(false);
	const [modalErrorMessage, setModalErrorMessage] = useState("");
	
	//variable to store dynamic list of categories as an option when creating a new image
	// const [filteredProducts, setFilteredProducts] = useState([]);
	// const [filteredDropDownSelection, setFilterDropDownSelection] = useState(-1);
	// const [ filteringByCustomerID,setFilteringByCustomerID] = useState("");      NO LONGER NEEDED SINCE DATA GRID HANDLES FILTERING AND SORTING

	//Analytics for product page
	// const [ccTransactions, setCCTransactions] = useState([]);
	const [perProductAnalytics, setPerProductAnalytics] = useState([]);

	const customerWideProductAlyticsInitialState = {
		inStore: 0,
		inactiveProducts: 0,
		productsTotal: 0,
		purchasesTotal: 0,
		coinsSpentTotal: 0,
		badgesDEISpentTotal: 0,
		badgesCSSpentTotal: 0,
		badgesSpotlightsSpentTotal: 0,
		dollarCostTotal: 0,
		mostExpensiveStore: 0,
		leastExpensiveStore: 99999,
		mostExpensiveStoreCoins: 0,
		leastExpensiveStoreCoins: 99999,
	}

	const[customerWideProductAlytics,setCustomerWideProductAlytics] = useState(customerWideProductAlyticsInitialState);
	
	//Product columns for customers
	const productColumns = [
	  {
		field: 'useInStore',
		headerName: 'IN STORE',
		headerAlign: 'center',
		minWidth: 100,
		flex:0.5,
		editable: false,
		sortable: false,
		color:'white',
		hide: (!isAdmin),
		 renderCell: (params)=> {
		  
		  return (
				<div className="ContainerNoHeightCenter" style={{width:"125px"}}>
						<div className="ContainerNoHeightCenter" >
							 <div>{params.row.useInStore ? "": <div style={{fontSize:"0.4rem"}}> &#10060;</div>}</div>
								<Switch
										name="active"
										checked={params.row.useInStore}  
										disabled={!isAdmin}
										onChange={event => handleRecordProductInactive(products[params.row.id-1], !params.row.useInStore)}
										color={"success"}
										size="small"
								/>
						</div>
				</div>
		  );
		 }
	  },  
	  {
		field: 'title',
		headerName: 'PRODUCT',
		headerAlign: 'center',
		minWidth: 150,
		flex:3,
		editable: false,
		sortable: true,
		color:'white',
		renderCell: (params)=> {
		return (
			<div className="ContainerMaxHeightCenter" >
				<div style={{minWidth:"100px", paddingRight:"10px", display:"flex", alignItems:"center", justifyContent:"center", color:"#409cf7"}}>
					<img src={params.row.image} alt="..." height='48'  />
				</div>
				<div >
					{truncateStringWithDots(params.row.title,50)}
				</div>
			{!params.row.featured ? null :
				<StarIcon style={{color:COLOR_GOLD, fontSize:"18px", transform: "translate(0, -8px)"}} />

			}
			
			{(isSuperAdmin ? showScopeBadge(params.row.productScope) : "")}
			{showCharityBadge(params.row.charity)}

			</div>
			);
		  },
	  },
	//   {
	//     field: 'featured',
	//     headerName: 'FEATURED',
	//     headerAlign: 'center',
	//     width: 150,
	//     editable: true,
	//     background:COLOR_BLUE_HEADER,
	//     color:'white',
	//      renderCell: (params)=> {
	//         return (
	//             <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
	//                 {!params.row.featured ? "" :
	//                     <div className="leaderUp"> &#10004;</div>
	//                     }
	//             </div>
	//         );
	//      }
	//   },
	  {
		field: 'dollarCost',
		headerAlign: 'center',
		headerName: 'COST',
		minWidth: 100,
		flex:0.5,
		editable: false,
		hide: ( innerWindowWidth < DATA_GRID_HIDE_ROW_THRESHOLD1),
		color:'white',
		 renderCell: (params)=> {
			return (
				<div className="ContainerMaxHeightCenter" style={{width:"100%", color:"#409cf7"}}>
					<div>${params.row.dollarCost}</div>
				</div>
			);
		 }
	 },
	  {
		field: 'price',
		headerName: 'PRICE',
		headerAlign: 'center',
		align:"center",
		minWidth: 150,
		flex:1,
		editable: true,
		color:'white',
		 renderCell: (params)=> {
			 
			 return displayAwards({coins:params.row.price, badgesDEI:params.row.badgesDEI, badgesCS:params.row.badgesCS,  badgesSpotlight:params.row.badgesSpotlight});
			
		 }
	  },

	{
		field: 'managedByName',
		headerName: 'MANAGED BY',
		headerAlign: 'center',
		minWidth: 125,
		flex:1,
		editable: true,
		background:COLOR_BLUE_HEADER,
		color:'white',
		hide: ( innerWindowWidth < DATA_GRID_HIDE_ROW_THRESHOLD2),
		 renderCell: (params)=> {
			 
			 if (params.row.managedByLogo)
				 return (
						<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
								<img src={params.row.managedByLogo} height='24' />
					   </div>
					 );
			else
			   return (
				   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"0.8rem", width:"100%"}}>  {truncateStringWithDots(params.row.managedByName, 14)} </div>
				);
		 }
	  },

	  {
		field: 'action',
		headerAlign: 'center',
		headerName: '',
		minWidth: 150,
		flex:0.1,
		color:'white',
		sortable: false,
		renderCell: (params)=> {

		   if (isSuperAdmin) 
				return (
				<div className="ContainerMaxHeightSpaceAroundFullWidth" >
						<EditButton onClickCallback={handleEdit} onClickCallbackParam={products[params.row.id-1]} />
						<DeleteButton onClickCallback={handleDelete} onClickCallbackParam={products[params.row.id-1]} />
				</div>);
			//ADMIN can only edit / delete their private products    
			else if (params.row.productScope === "CUSTOMER_SPECIFIC" && isAdmin)
				return (
					<div className="ContainerMaxHeightSpaceAroundFullWidth" >
						<EditButton onClickCallback={handleEdit} onClickCallbackParam={products[params.row.id-1]} />
						<DeleteButton onClickCallback={handleDelete} onClickCallbackParam={products[params.row.id-1]} />
					</div>
					);
			else return null;
			
			}
		},
		
	];

/*
	//Product columns for customers
	const productColumnsSuperAdmin = [
	  {
		field: 'useInStore',
		headerName: 'IN STORE',
		headerAlign: 'center',
		width: 150,
		editable: false,
		sortable: false,
		color:'white',
		hide: (!isAdmin),
		 renderCell: (params)=> {
		  
		  return (
				<div className="ContainerNoHeightCenter" style={{width:"125px"}}>
						<div className="ContainerNoHeightCenter" >
							 <div>{params.row.useInStore ? "": <div style={{fontSize:"0.4rem"}}> &#10060;</div>}</div>
								<Switch
										name="active"
										checked={params.row.useInStore}  
										disabled={!isAdmin}
										onChange={event => handleRecordProductInactive(products[params.row.id-1], !params.row.useInStore)}
										color={"success"}
										size="small"
								/>
						</div>
				</div>
		  );
		 }
	  },  
	  {
		field: 'title',
		headerName: 'PRODUCT',
		headerAlign: 'center',
		minWidth: 500,
		flex:3,
		editable: false,
		sortable: true,
		color:'white',
		renderCell: (params)=> {
		return (
			<div className="ContainerMaxHeightCenter" >
				<div style={{minWidth:"100px", paddingRight:"10px", display:"flex", alignItems:"center", justifyContent:"center", color:"#409cf7"}}>
					<img src={params.row.image} alt="..." height='48'  />
				</div>
				<div >
					{truncateStringWithDots(params.row.title,50)}
				</div>
			{!params.row.featured ? null :
				<StarIcon style={{color:COLOR_GOLD, fontSize:"18px", transform: "translate(0, -8px)"}} />

			}
			
			{showScopeBadge(params.row.productScope)}
			{showCharityBadge(params.row.charity)}

			</div>
			);
		  },
	  },
	//   {
	//     field: 'featured',
	//     headerName: 'FEATURED',
	//     headerAlign: 'center',
	//     width: 150,
	//     editable: true,
	//     background:COLOR_BLUE_HEADER,
	//     color:'white',
	//      renderCell: (params)=> {
	//         return (
	//             <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
	//                 {!params.row.featured ? "" :
	//                     <div className="leaderUp"> &#10004;</div>
	//                     }
	//             </div>
	//         );
	//      }
	//   },
	  {
		field: 'dollarCost',
		headerAlign: 'center',
		headerName: 'COST',
		width: 150,
		editable: false,
		color:'white',
		 renderCell: (params)=> {
			return (
				<div className="ContainerMaxHeightCenter" style={{width:"100%", color:"#409cf7"}}>
					<div>${params.row.dollarCost}</div>
				</div>
			);
		 }
	 },
	  {
		field: 'price',
		headerName: 'PRICE',
		headerAlign: 'center',
		align:"center",
		minWidth: 200,
		flex:1,
		editable: true,
		color:'white',
		 renderCell: (params)=> {
			 
			 return displayAwards({coins:params.row.price, badgesDEI:params.row.badgesDEI, badgesCS:params.row.badgesCS,  badgesSpotlight:params.row.badgesSpotlight});
			
		 }
	  },

	  {
		field: 'externalLink',
		headerName: 'LINK',
		headerAlign: 'center',
		width: 115,
		editable: true,
		color:'white',
		hide:true,
		 renderCell: (params)=> {
			return (
				<div>{!params.row.externalLink ? "" :
					<div className="leaderUp"> &#10004;</div>
					}
				</div>
			);
		 }

	  },
	{
		field: 'managedByName',
		headerName: 'MANAGED BY',
		headerAlign: 'center',
		width: 170,
		editable: true,
		background:COLOR_BLUE_HEADER,
		color:'white',
		hide:false,
		 renderCell: (params)=> {
			 
			 if (params.row.managedByLogo)
				 return (
						<div className="ContainerMaxHeightCenter" style={{width:"100%", height:"100%", color:"#409cf7"}}>
								<img src={params.row.managedByLogo} height='24' />
					   </div>
					 );
			else
			   return (
				   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"0.8rem", width:"100%"}}>  {truncateStringWithDots(params.row.managedByName, 14)} </div>
				);
		 }
	  },
	  {
		field: 'action',
		headerAlign: 'center',
		headerName: '',
		minWidth: 150,
		flex:0.1,
		color:'white',
		sortable: false,
		renderCell: (params)=> {

			if (isSuperAdmin) 
				return (
				<div className="ContainerMaxHeightSpaceBetween">
					<div className="menuDataGridListButton">
							<Button variant="light" size="sm" >
								<Edit className="editButton" onClick={()=>handleEdit(products[params.row.id-1])} disabled={isUpdatingProduct}/>
							</Button>
					</div>
					<div className="menuDataGridListButton">
							<Button variant="light" size="sm" >
								<DeleteOutline className="deleteButton" onClick={()=>handleDelete(products[params.row.id-1])} disabled={isUpdatingProduct}/> 
							</Button>
					</div>
					
				</div>);
			//ADMIN can only edit / delete their private products    
			else if (params.row.productScope === "CUSTOMER_SPECIFIC" && isAdmin)
				return (
					<div className="ContainerMaxHeightSpaceBetween">
						<div className="menuDataGridListButton">
								<Button variant="light" size="sm" >
									<Edit className="editButton" onClick={()=>handleEdit(products[params.row.id-1])} disabled={isUpdatingProduct}/>
								</Button>
						</div>
						<div className="menuDataGridListButton">
								<Button variant="light" size="sm" >
									<DeleteOutline className="deleteButton" onClick={()=>handleDelete(products[params.row.id-1])} disabled={isUpdatingProduct}/> 
								</Button>
						</div>
						
					</div>
					);
			else return null;
			}
		},
		
	];
*/   
   function showScopeBadge(productScope) {

	   //Products offered by Conectere            
	   if (productScope === "GLOBAL")
			return ( 
			   <div className="ContainerMaxHeightCenter" style={{width:"100%", paddingLeft:"clamp(4px,1vw,10px)"}}>
					<div className="employeeStoreSponsoredBadge" >  
						 <div style={{paddingLeft:"clamp(4px,1vw,10px)"}}><img src={ CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH} height='12' /></div>
						<div >
							<div style={{transform:"translate(0,1px)"}}>SPONSORED</div>
						</div>
					</div>
				</div>
				); 
		else if (productScope === "DEFAULT")
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<div className="employeeStoreDefaultBadge" >  
						<div style={{transform:"translate(0,1px)"}}>DEFAULT </div>
					</div>
				</div>
				); 
		else if (productScope === "CUSTOMER_SPECIFIC") return null;     
		else return (
			   <div className="ContainerMaxHeightCenter" style={{color:COLOR_BLUE_TEXT, fontSize:"1.0rem", width:"100%"}}>
				<img src={currentUser.customer.logo} height='24' />
					<div className="employeeStoreCustomerSpecificBadge" >  PRIVATE </div>
				</div>
				);
   }
   
   function showCharityBadge(charity) {

	   //Whether or not this product is a Charity            
	   if (charity)
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<div className="employeeStoreCharityBadge" >  
						<div style={{transform:"translate(0,1px)"}}>CHARITY</div>
					</div>
				</div>
				); 
		return null;   
   }
   
/*   
   function showSpotlightBadge(numRecentSpotlightsRequired) {

	   //Whether or not this product requires a recent Spotlight            
	   if (numRecentSpotlightsRequired === 1)
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<div className="employeeStoreSpotlightsRequiredBadge" >  
						<div style={{transform:"translate(0,1px)"}}>REQUIRES {numRecentSpotlightsRequired.toString()} SPOTLIGHT</div>
					</div>
				</div>
				); 

	   if (numRecentSpotlightsRequired > 0)
			return (
			   <div className="ContainerMaxHeightCenter" style={{width:"100%"}}>
					<div className="employeeStoreSpotlightsRequiredBadge" >  
						<div style={{transform:"translate(0,1px)"}}>REQUIRES {numRecentSpotlightsRequired.toString()} SPOTLIGHTS</div>
					</div>
				</div>
				); 

				
		return null;   
   }   
*/   
   async function handleRecordProductInactive(product, useInStore) {
		
		// if (DEBUG_MODE >= 2) console.log("Toggling Product active/inactive", product, useInStore);

		var currentInactiveList = [];
		
		//Remove the customer ID from the inactive list if present and no longer marked inactive
		if (useInStore && product.inactiveForCustomers) {
							
			var indexToRemove = product.inactiveForCustomers.indexOf(currentUser.customerID);
				
			if (!indexToRemove>-1) {
				if (DEBUG_MODE >= 2) console.log('Removing customer to make product active');
		 
				//grab the current list
				currentInactiveList = product.inactiveForCustomers;

				currentInactiveList.splice(indexToRemove,1); //remove current customer
				if (DEBUG_MODE >= 2) console.log('Found customer to remove at index:', indexToRemove);

			}
		}        

		//Now, ADD customer to the INACTIVE list for the PRODUCT if they should be inactive and not present already in the list

		if (!useInStore) {
			if (!product.inactiveForCustomers) { //No field in this object?

				currentInactiveList = [currentUser.customerID]; //Just add the current customer to an array
				
				if (DEBUG_MODE >= 2) console.log('Added customer to empty array of inactiveForCustomers', currentInactiveList);

				
			} else if (!product.inactiveForCustomers.some(entry => entry === currentUser.customerID)) { //If attribute exists, does it NOT already contain the customer ID?

				if (DEBUG_MODE >= 2) console.log('Adding customer to existing list inactiveForCustomers', product.inactiveForCustomers);

				//grab the current list and push this customer
				currentInactiveList = product.inactiveForCustomers; //grab existing customers
				currentInactiveList.push(currentUser.customerID); //push the new customer onto the existing array
				
				if (DEBUG_MODE >= 2) console.log('Added customer to existing list inactiveForCustomers', currentInactiveList);

			}
		}

		//Now, update the DB                
		try {       
			//Get the returned, updated object so we can update our local data with all the fields
			const tempInsertedProduct = await invokeAPI(updateProduct, 'updateProduct', {id:product.id, inactiveForCustomers:currentInactiveList});
			
			/* 2.7.2023 THIS WILL HAPPEN BY WAY OF SUBSCRIPTION           
			//Update state
			const tempProducts = [...products];   //Copy our state datea
			const indexToEdit = products.findIndex ( productRecord => productRecord.id === product.id);
			
			//Edit the item in our local data using the FULL object returned from the backed on the update
			if (indexToEdit > -1) {
				tempProducts[indexToEdit] = { ... tempInsertedProduct};
				setProducts(tempProducts); //update our master state data on the page
				if (DEBUG_MODE >= 2) console.log("UPDATED")
			}
			
			*/          
			if (DEBUG_MODE >= 2) console.log('Successfully toggled active/inactive  product', tempInsertedProduct);
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error toggling active / inactive product", err);
		}
	}
	
	
	  //Edit Functions 
	  async function handleConfirmationEdit () {
	  let tempUrl="", fileUploaded = false, imageHeight = 0, imageWidth = 0;
	  try {

		 //Did the user upload a NEW product image file?            
		 //This should only be set if original image was cleared and, possibly, if a NEW image file was uploaded
		 if (newImageUploaded) {
			if (DEBUG_MODE > 0) console.log("Processing new product image", productImageOriginalURL, productDetails.image);
			try {
			   //Original image changed or removed?
			   if (productImageOriginalURL && productImageOriginalURL.length > 0 && productImageOriginalURL !== productDetails.image) {
				  await deleteImageFile(productImageOriginalURL);
				  if (DEBUG_MODE >= 2) console.log('Deleted Product Image', productDetails.image);             
			   }
			   //New image to upload?
			   if (imageFileUploaded) { 
				  const {successFlag, url} = await storeImageFile(imageFileUploaded);
				  if (successFlag) {
					  fileUploaded = true; tempUrl = url;
					  console.log ("Successfully wrote PRODUCT image to S3 with URL", tempUrl);
					  var img = document.getElementById('uploadedImagedID'); 
					  imageWidth = img.naturalWidth; imageHeight = img.naturalHeight;  
					  if (DEBUG_MODE >= 2) console.log("Image - width", imageWidth, "height:", imageHeight);
				  } else if (DEBUG_MODE >= 2) console.log("Error writing PRODUCT image file to S3");            
			   }  else  console.log ("User removed original product image but did not upload a new one");
			} catch (err) {
			   if (DEBUG_MODE >= 2) console.log("Error writing image file to S3", err);
			   setImageUploaded(null); //clear the URL to the uploaded image
			   setImageFileUploaded(null);  //clear the file itself
			   return;  
			}
		 } else {
			console.log ("User made no change to the product image");
		 }

		 setShowSpinner(true); //Pop up spinners to lock screen      
		 //Modify external link to include http:// if necessary
		var tempProductURL = productDetails.externalLink;
		if (tempProductURL && !tempProductURL.toUpperCase().includes("HTTP") && tempProductURL.length > 0) tempProductURL =  "http://" + tempProductURL;
			
		   //so, we need to make an object that conforms to the GraphQL mutation for deleting the item
		   //note, this object does not have all the connection info
			const tempProductToEdit = {
				
				id: productDetails.id,
				title: productDetails.title,
				description: productDetails.description,
				featured: productDetails.featured,
				charity: productDetails.charity,
				// numRecentSpotlightsRequired:productDetails.numRecentSpotlightsRequired,
				
				dollarCost: productDetails.dollarCost,
				price: Math.round(CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION*productDetails.dollarCost),
				image: (fileUploaded ? tempUrl : productDetails.image),
				imageHeight: (fileUploaded ? imageHeight : productDetails.imageHeight),
				imageWidth: (fileUploaded ? imageWidth : productDetails.imageWidth),
				
				externalLink: tempProductURL,
				badgesDEI: productDetails.badgesDEI,
				badgesCS: productDetails.badgesCS,
				badgesSpotlight:productDetails.badgesSpotlight,
				// useInStore: productDetails.useInStore,
				productScope: productDetails.productScope,
			};

			//Set the inActiveForCustomer List
			//Remove this customer ID from the inactive list if present in the list and no longer marked inactive
			if (productDetails.useInStore  && productDetails.inactiveForCustomers) {                               
				var indexToRemove = productDetails.inactiveForCustomers.indexOf(currentUser.customerID);                   
				if (indexToRemove>-1) {
					if (DEBUG_MODE >= 2) console.log('Removing customer to make the product active.  Found customer to remove from inactive list at index:', indexToRemove);            
					//grab the current list
					tempProductToEdit.inactiveForCustomers = productDetails.inactiveForCustomers;  
					tempProductToEdit.inactiveForCustomers.splice(indexToRemove,1); //remove current customer
				}
			}
	
			//Add customer to the INACTIVE list for the Conectivity if they should be inactive and not present already in the list
			if (!productDetails.useInStore) {
				if (!productDetails.inactiveForCustomers) { //No list attribute?
					tempProductToEdit.inactiveForCustomers = [currentUser.customerID]; //Just add the current customer to an array             
					if (DEBUG_MODE >= 2) console.log('Added customer to empty array of inactiveForCustomers', tempProductToEdit.inactiveForCustomers);                   
				} else if (!productDetails.inactiveForCustomers.some(entry => entry === currentUser.customerID)) { //If exists, does it already contain the customer ID?   
					if (DEBUG_MODE >= 2) console.log('Adding customer to existing list inactiveForCustomers', tempProductToEdit.inactiveForCustomers);   
					//grab the current list and push this customer
					tempProductToEdit.inactiveForCustomers = productDetails.inactiveForCustomers; //grab existing customers or empty list
					tempProductToEdit.inactiveForCustomers.push(currentUser.customerID); //push the new customer onto the existing array                   
					if (DEBUG_MODE >= 2) console.log('Added customer to list inactiveForCustomers', tempProductToEdit.inactiveForCustomers);   
				}
			}

			//Update assignment to a specific customer, if changed
			if (customerSelection && (productDetails.customerID !== customerSelection.id)) {
				tempProductToEdit.customerID = customerSelection.id;
				if (DEBUG_MODE >= 2) console.log("Reassigned product to customer: ", customerSelection.label);
			}


			//Call GraphQL with correct parameters to edit the item in the  backend   
			//Get the returned, updated object so we can update our local data with all the fields
			const tempInsertedProduct = await invokeAPI(updateProduct, 'updateProduct', tempProductToEdit);
			if (DEBUG_MODE >= 2) console.log('Successfully edited product', tempInsertedProduct);
  
			/* 2.7.2023 THIS WILL HAPPEN BY WAY OF SUBSCRIPTION             
			//Now, let's find the corresponding object on our page
			const tempProducts = [...products];   //copy our state data
			var indexToEdit = tempProducts.findIndex(product => product.id === productDetails.id);

			
			
			//Edit the item in our local data using the FULL object returned from the backed on the update
			if (indexToEdit > -1) {
				if (DEBUG_MODE >= 2) console.log('Found product to edit at index:', indexToEdit);

				tempProducts[indexToEdit]=tempInsertedProduct;
				setProducts(tempProducts); //update our master state data on the page
				
				 //Build rows for Data Grid
				// buildProductRows(tempProducts);
				
			}          
			*/

			setNewImageUploaded(false); //Using current image, allow user to replace 
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error editing product:', err);

			// Delete the new product image file that was successfully uploaded to s3 
			if (fileUploaded) await deleteImageFile(tempUrl);
		}
		setShowSpinner(false);      //hide spinners 
		setShowModalEdit(false);   //hide the pop-up   
	}

	//This function handles the ADD buttons
	//Pop-up a modal and ask product to confirm. Handle confirmation
	const handleAdd = () => {

		//Preset the company options to this user
		setCustomerSelection(customerOptionsNoAll.find (customer => customer.id === currentUser.customerID));

		setImageUploaded(null);
		setImageFileUploaded(null);     //clear the file itself
		setProductDetails(PRODUCT_INITIAL_STATE);        
		setDefaultProductSwitchState(false);
		setGlobalProductSwitchState(false);
		setShowModalAdd(true); //pop-up Modal 

	};
	
	const handleCancel = () => {
			setShowModalEdit(false);
			setShowModalAdd(false);
			setShowModalDelete(false);
			setProductDetails(PRODUCT_INITIAL_STATE);
			setProductImageOriginalURL(null);
			if (DEBUG_MODE >= 2) console.log("Cancelled Product Add / Delete / Edit.");
	};
		
	// const handleCancelEdit = () => {
	//         setShowModalEdit(false);
	//         setProductToEdit(PRODUCT_INITIAL_STATE);
	//         if (DEBUG_MODE >= 2) console.log("Cancelled Product Edit.");
	//     };
		
	//This function handles the EDIT buttons
	//Pop-up a modal and ask product to confirm. Handle confirmation
	const handleEdit = (productToEdit) => {
		setImageUploaded(productToEdit.image);      //Grab the S3 URL if present
		setNewImageUploaded(false);                 //No new image yet - Original image for now
		
		if (DEBUG_MODE >= 2) console.log("Edit button pressed.  Edit Product=", productToEdit);

		var tempProductToEdit = {...productToEdit};

		//Update temp boolean flag carried by the object based on whether the product being edited is set to inactive for this particular customer
		if (tempProductToEdit.inactiveForCustomers) {
			tempProductToEdit.useInStore = !tempProductToEdit.inactiveForCustomers.some(entry => entry === currentUser.customerID); //set flag
		} else {
			tempProductToEdit.useInStore = true; //true if list does not exist at all
		}
			

		if (!tempProductToEdit.useInStore) if (DEBUG_MODE >= 2) console.log("Product is inactive for this customer", tempProductToEdit);
		
		//Set the state of our switches
		if (productToEdit.productScope === "GLOBAL") setGlobalProductSwitchState(true); else setGlobalProductSwitchState(false); 
		if (productToEdit.productScope === "DEFAULT") setDefaultProductSwitchState(true); else setDefaultProductSwitchState(false);
		
		//Initialize settings that do not exist in the DB record so the Switch is not an "uncontrolled" component
		if (!tempProductToEdit.charity) tempProductToEdit.charity = false;
		if (!tempProductToEdit.badgesSpotlight) tempProductToEdit.badgesSpotlight = 0;
		

		//Preset the company options to the company that created the product
		setCustomerSelection(customerOptionsNoAll.find (customer => customer.id === tempProductToEdit.customerID));
		

		setProductDetails(tempProductToEdit); //Store the passed in object into state data variable to record object being edit
		setProductImageOriginalURL(tempProductToEdit.image);
		setShowModalEdit(true); //pop-up Modal 

	};
   

	///Delete Function
	async function handleConfirmationDelete () {

	  //safety check
	  if (!productDetails || !productDetails.id) {
		 if (DEBUG_MODE > 0) console.log("Error - improper params to delete product", productDetails);
	  }
	  
	  let response;
	  try {
   
		 //Delete product
		 response = await invokeAPI(deleteProduct, 'deleteProduct', { id: productDetails.id });
		 if (DEBUG_MODE >= 2) console.log('Successfully deleted product', response);

		 //Remove the actual image file from S3
		 response = await deleteImageFile(productDetails.image);
		 if (DEBUG_MODE >= 2) console.log('Deleted Product Image', response);                                  
	  } catch (err) {
		 if (DEBUG_MODE >= 2) console.log('error deleting product:', err);
	  }
		 setShowModalDelete(false);   //hide the pop-up
		 if (DEBUG_MODE >= 2) console.log("Deleted Product:", productDetails);
	  };
		
		
		
	//handle cancel in delete modal
	// const handleCancelDelete = () => {
	//         setShowModalDelete(false);
	//         if (DEBUG_MODE >= 2) console.log("Cancelled Product Delete.");
	//     };
		
	//This function handles the delete buttons from list of items
	//Pop-up a modal and ask product to confirm. Handle confirmation
	const handleDelete = (productToDelete) => {
				
		if (DEBUG_MODE >= 2) console.log("DELETE button pressed.  Delete Product=", productToDelete);

		//Update temp boolean flag carried by the object based on whether the customer has marked the product inactive
		if (productToDelete.inactiveForCustomers) { //Attribute exist?
			productToDelete.useInStore = !productToDelete.inactiveForCustomers.some(entry => entry === currentUser.customerID);
		} else {
			productToDelete.useInStore = true;
		}
				
		//Preset the company options to the company that created the conectivity
		setCustomerSelection(customerOptionsNoAll.find (customer => customer.id === currentUser.customerID));


		if (productToDelete.productScope === "DEFAULT") setDefaultProductSwitchState(true); else setDefaultProductSwitchState(false);
		if (productToDelete.productScope === "GLOBAL") setGlobalProductSwitchState(true); else setGlobalProductSwitchState(false);
		
		setProductDetails(productToDelete);
		setShowModalDelete(true); //pop-up Modal
		
	};
   
	//On change to products, rebuild UI rows
	useEffect(() => {
		buildProductRows();    //call our funciton to fetch prodcuts for the main table
	}, [products]);
	
	function buildProductRows() {
		if (!products || products.length === 0) return;
			 
	   //Build rows for Data Grid
		var tempProductRows = [];
		
		for(var i = 0; i<products.length; i++){
			tempProductRows[i] = {
				id:i+1,
				customerID: products[i].customerID,
				dynamoDBID: products[i].id, 
				title:products[i].title,
				description: products[i].description,
				image:products[i].image,
				featured:products[i].featured,

			   //Recompute the coins needed based on any company-specific coin value set by the admin
				price:(currentUser.customer.configDetails && currentUser.customer.configDetails.dollarToCoinConversion ? currentUser.customer.configDetails.dollarToCoinConversion * products[i].dollarCost : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION * products[i].dollarCost),

				charity:products[i].charity,
				badgesDEI:products[i].badgesDEI,
				badgesCS:products[i].badgesCS,
				badgesSpotlight:(products[i].badgesSpotlight ? products[i].badgesSpotlight : 0),
				
				// numRecentSpotlightsRequired:products[i].numRecentSpotlightsRequired,
				dollarCost:products[i].dollarCost,
				imageWidth:products[i].imageWidth,
				imageHeight:products[i].imageHeight,
				externalLink: products[i].externalLink ? true : false,
				productScope: products[i].productScope,
				useInStore: (!products[i].inactiveForCustomers ? true : products[i].inactiveForCustomers.some(entry => entry === currentUser.customerID)==false),
			};
			
			//Set Managed By fields
			if (products[i].productScope === "CUSTOMER_SPECIFIC") {
				
				//PRODUCT OFFERED BY A SPECIFIC COMPANY
				const tempCustomer = customers.find(customer => customer.id === products[i].customerID);    //All clients will have a customer array, even if only 1 entry
				tempProductRows[i].managedByName = (tempCustomer ? tempCustomer.name : "");
			   tempProductRows[i].managedByLogo = (tempCustomer ? tempCustomer.logo : "");
			} else {

				//CONECTERE SPONSORED (GLOBAL) PRODUCTS and DEFAULT STORE PRODUCTS      
				tempProductRows[i].managedByName = "Conectere";
				tempProductRows[i].managedByLogo = CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
			}
		}

		if (DEBUG_MODE >= 2) console.log("Built Product Rows:", tempProductRows);
		setProductRows(tempProductRows);        
	}

   //On change to products, recalc analytics
	useEffect(() => {
		calcProductActivity();    //calc analytics on any change to the products or the transactions
	}, [products, transactionsFiltered]);
	
	//This function processes the transaction data in view of the fetched products and computes all stats needed
	function calcProductActivity () {
		
		if (!products || !transactionsFiltered) return null;
		
		console.log ("Inputs to build Product Data", products, transactionsFiltered);
	
		const tempProductNumbers = [];
		var tempAnalytics = {...customerWideProductAlyticsInitialState};
		
		
		tempAnalytics.productsTotal = products.length;
		
		//First, initialize our array so we have a spot for product, including those with ZERO transactions
		//This holds all the data for each product that we need for all the different graphs
		for (var j = 0; j < products.length; j++){
			tempProductNumbers[j] = {
				productID: products[j].id,
				title:products[j].title,
				image:products[j].image,
				purchases: 0,
				dollarCost:0,
				coinsSpent: 0,
				badgesDEISpent: 0,
				badgesCSSpent: 0,
				badgesSpotlightSpent:0,
			};
			

			//Update Inactive counts based on those for which the company is specifically opting OUT
			var thisProductActive = true;
			if (products[j].inactiveForCustomers) { //Attribute exists
				if (products[j].inactiveForCustomers.some(entry => entry === currentUser.customerID)) {
					tempAnalytics.inactiveProducts +=1;  //Count as excluded by this company
					thisProductActive = false;
				} else {
					tempAnalytics.inStore +=1;  //Count as active
				}
				
			} else {
				tempAnalytics.inStore +=1;  //Count as active
			   
			}      
			
			//update numbers for products in store
			if (thisProductActive) {
			   let coins = (currentUser.customer.configDetails && currentUser.customer.configDetails.dollarToCoinConversion ? currentUser.customer.configDetails.dollarToCoinConversion * products[j].dollarCost : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION * products[j].dollarCost)
				if (products[j].dollarCost > tempAnalytics.mostExpensiveStore) {
				  tempAnalytics.mostExpensiveStore = products[j].dollarCost;
				  tempAnalytics.mostExpensiveStoreCoins = coins;
				}
				if (products[j].dollarCost < tempAnalytics.leastExpensiveStore) {
				  tempAnalytics.leastExpensiveStore = products[j].dollarCost;
				  tempAnalytics.leastExpensiveStoreCoins = coins;
				}
			}
		}
			
		if (DEBUG_MODE >= 2) console.log("Starting to process transactions.  Initialized Product Array:", tempProductNumbers);

		//Now, walk the entire list of transaction O(N) and update counts
		for (var j = 0; j < transactionsFiltered.length; j++){
			if (transactionsFiltered[j].transactionType === "CC_REDEMPTION") {
				
				//Find the particular product related to the transaction and update product-specific data
				var productIndex=products.findIndex((product) => product.id === transactionsFiltered[j].productID);
				
				//Update product-specific numbers
				if (productIndex >-1) {
					tempProductNumbers[productIndex].purchases +=1;
					tempProductNumbers[productIndex].dollarCost -=transactionsFiltered[j].dollarCost;
					tempProductNumbers[productIndex].coinsSpent -=transactionsFiltered[j].amount;
					tempProductNumbers[productIndex].badgesDEISpent -=transactionsFiltered[j].badgesDEI;
					tempProductNumbers[productIndex].badgesCSSpent -=transactionsFiltered[j].badgesCS;
					tempProductNumbers[productIndex].badgesSpotlightSpent -=transactionsFiltered[j].badgesSpotlight;
					
				}

				//Update total store-WIDE transaction numbers
				tempAnalytics.purchasesTotal +=1;
				tempAnalytics.coinsSpentTotal -=transactionsFiltered[j].amount;             //Amount is negative $$ in the transaction
				tempAnalytics.badgesDEISpentTotal -=transactionsFiltered[j].badgesDEI;
				tempAnalytics.badgesCSSpentTotal -=transactionsFiltered[j].badgesCS;
				tempAnalytics.badgesSpotlightSpentTotal -=transactionsFiltered[j].badgesSpotlights;
				tempAnalytics.dollarCostTotal -=transactionsFiltered[j].dollarCost;         //Dollar Cost is negative
			}
		}

		setCustomerWideProductAlytics(tempAnalytics);                

		tempProductNumbers.sort(compareProductsMostFavorite);
		setPerProductAnalytics(tempProductNumbers);
		
		console.log ("Built product-specific data from transactions:", tempProductNumbers);
		console.log ("Built analytics data from transactions:", tempAnalytics);

	}

	//define function to handle submitButton, which is used to create a new product
	async function handleSubmit (e)  {
	  e.preventDefault(); //necesssary to prevent React page refresh on Submit, which interferes with API call
	  setShowSpinner(true); //Pop up spinners to lock screen      
	  if (DEBUG_MODE >= 2) console.log('Called handler to create new product - top', productDetails);


	  //Assign the customer    
	  var tempCustomerID ="";
	  if(isSuperAdmin){
		 if (customerSelection) {
			   if (DEBUG_MODE >= 2) console.log("SuperAdmin - Assigning product to specified customer", customerSelection);
			   tempCustomerID = customerSelection.id;   
		 }
		 else {
			   if (DEBUG_MODE >= 2) console.log("SuperAdmin - Assigning product to current user's customer ID");
			   tempCustomerID = currentUser.customerID;
		 }
	  }
	  else{
		 if (DEBUG_MODE >= 2) console.log("Assigning product to current user's customer ID");
		 tempCustomerID = currentUser.customerID;
	  }

	  //Required fields for products
	  if (!productDetails.title) {
		 if (DEBUG_MODE >= 2) console.log('error creating product, incomplete fields'); 

		 setProductDetails(PRODUCT_INITIAL_STATE);
		 // document.getElementById("productForm").reset();    //Clear the form
		 setImageUploaded(null); //clear the URL to the uploaded product
		 setImageFileUploaded(null);  //clear the file itself
		 setShowSpinner(false); //Hide spinners
		 return;
	  }


	  //Get image data
	  var img = document.getElementById('uploadedImagedID'); 
	  if (img) {
		 var imageWidth = img.naturalWidth;
		 var imageHeight = img.naturalHeight;
		 if (DEBUG_MODE >= 2) console.log("Image - width", imageWidth, "height:", imageHeight);
	  }
	  
	  // Upload the image file to s3 with public access level.     
	  let tempUrl;
	  try {
			const {successFlag, url} = await storeImageFile(imageFileUploaded);
			if (successFlag) {
				  tempUrl = url; if (DEBUG_MODE > 1) console.log ("Successfully wrote product image to S3 ", tempUrl);
			} else {
			   if (DEBUG_MODE > 0) console.error ("ERROR - failed avatar image to S3");                  
			}           
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log("Error writing product image file to S3", err);
			setImageUploaded(null); //clear the URL to the uploaded product
			setImageFileUploaded(null);  //clear the file itself
			setShowSpinner(false); //Hide spinners
			return;
		}

		//Modify external link to include http:// if necessary
		var tempProductURL = productDetails.externalLink;
		if (tempProductURL && tempProductURL.length > 0 && !tempProductURL.toUpperCase().includes("HTTP")) tempProductURL =  "http://" + tempProductURL;
		

		//OK, we are now ready to create the DB product record to update Dynamo DB
		//added code to remove the product file from S3 if any error thrown below
		
		try {
			var tempProductToAdd = {
				
				customerID: tempCustomerID,
				title: productDetails.title,
				description: productDetails.description,
				featured: productDetails.featured,
				charity: productDetails.charity,
				// numRecentSpotlightsRequired:productDetails.numRecentSpotlightsRequired,
				dollarCost: productDetails.dollarCost,
				price: Math.round(CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION*productDetails.dollarCost),
				image: tempUrl,
				imageHeight: imageHeight,
				imageWidth: imageWidth,
				externalLink: tempProductURL,
				badgesDEI: productDetails.badgesDEI,
				badgesCS: productDetails.badgesCS,
				badgesSpotlight:productDetails.badgesSpotlight,
				inactiveForCustomers : [], 
				productScope: productDetails.productScope,
			};
 
 
			if (productDetails.useInStore === false) {
				tempProductToAdd.inactiveForCustomers = [currentUser.customerID];   
			}
			
			if (DEBUG_MODE >= 2) console.log('Writing new product', tempProductToAdd);

			//Call GraphQL with correct parameters to create in backend 
			const tempInsertedProduct = await invokeAPI(createProduct, 'createProduct',tempProductToAdd);
			if (DEBUG_MODE >= 2) console.log('Successfully wrote new product', tempInsertedProduct);


   /* 2.7.2023 NOT NEEDED AS STATE UPDATE WILL BE HANDLED VIA SUBSCRIPTION      
			//Update locale state data           
			var tempProducts= [...products, tempInsertedProduct];   //add new item to client-side data for this page to the existing array.
																	  //this uses the spread operator.  could also use the Push operator
																	  //Note, this includes the new KEY from the DB, I believe, which we will need if the product tries to delete this item
			
			
			//Sort the new set of objects
			tempProducts.sort(compareProductsByTitle)
			setProducts(tempProducts); //Update state data with newly sorted list
			if (DEBUG_MODE >= 2) console.log('Updated product state variable', tempProducts);
	*/

			//Reset input fields for form
			setProductDetails(PRODUCT_INITIAL_STATE);
			setImageUploaded(null);         //clear the URL to the uploaded product
			setImageFileUploaded(null);     //clear the file itself

		} catch (err) {

			// Delete the file uploaded to s3 
			if (tempUrl && tempUrl.length >0) await deleteImageFile(tempUrl);

			// document.getElementById("productForm").reset();    //Clear the form
			setImageUploaded(null); //clear the URL to the uploaded product
			setImageFileUploaded(null);  //clear the file itself



			if (DEBUG_MODE >= 2) console.log('error creating product:', err);
		}
				
		setShowSpinner(false); //Hide spinners
		setShowModalAdd(false);                             //pop-up Modal 
	}
  
	

// const handleClear = async (e) => {
//         e.preventDefault();

//         //Reset input fields for form
//         setProductDetails(PRODUCT_INITIAL_STATE);
//         document.getElementById("productForm").reset();    //Clear the form
//         setImageUploaded(null); //clear the URL to the uploaded product
//         setImageFileUploaded(null);  //clear the file itself
		
//     console.log ("Cleared Form");

// }


const handleImageUpload = async (e) => {
try {
		e.preventDefault();
		const file = e.target.files[0];  //grab the first file in the list of files selected by the user	
		if (validImageFileType(file)) {
				const localURL = URL.createObjectURL(file);
				console.log ("URL to local file in web browser:", localURL);
				setImageUploaded(localURL);  //update 'product' so as to display a preview to the user
				setImageFileUploaded(file);  //save file for uploading to S3 bucket if the user hits the ADD button
				console.log ("Successfully generated internal URL and stored FILE locally")	
		}
		else  if (DEBUG_MODE >= 2) console.log("Error, wrong file type", file.type);
	} catch (err) {
		if (DEBUG_MODE >= 2) console.log("Error locally processing selected product", err);
	}
}


	// //Function to handle user removing the product image during edit
	// const handleClearImageEdit = () => {
	//     setNewImageUploaded(true);      //Record this event for use if the user saves the changes
	//     setImageUploaded("");       //Clear the URL as if we don't have a file yet
	//     setImageFileUploadedEdit("");   //clear file to be uploaded to S3 bucket 

	//     setProductToEdit(
	//         { 
	//             ...productToEdit, image: "", imageWidth: 0, imageHeight: 0,
	//         }
			
	//     ) //Clear the URL and H/W
		
	//     if (DEBUG_MODE >= 2) console.log("User Removed the existing image when editing the product.");
	// };

	//Function to handle user removing the product image during ADD
	const handleClearImage = () => {
		setNewImageUploaded(true);      //Record this event for use if the user saves the changes
		setImageUploaded("");       //Clear the URL as if we don't have a file yet
		setImageFileUploaded("");   //clear file to be uploaded to S3 bucket 

		setProductDetails(
			{ 
				...productDetails, image: "", imageWidth: 0, imageHeight: 0,
			}
			
		) //Clear the URL and H/W
		
		if (DEBUG_MODE >= 2) console.log("User Removed the existing image when adding the product.");
	};


	//Dynamically adjust our master form AFTER the modal is displayed so we can find the fields
/*
	useEffect(() => {
	 
		if (showModalAdd || showModalEdit) {
		   //Enable all input fields
			document.getElementById("input_1").disabled = false;
			document.getElementById("input_2").disabled = false;
			document.getElementById("input_3").disabled = false;
			document.getElementById("input_4").disabled = false;
			document.getElementById("input_5").disabled = false;
			document.getElementById("input_6").disabled = false;
			document.getElementById("input_7").disabled = false;
			
		} else if (showModalDelete) {
		  //Disable all input fields
			document.getElementById("input_1").disabled = true;
			document.getElementById("input_2").disabled = true;
			document.getElementById("input_3").disabled = true;
			document.getElementById("input_4").disabled = true;
			document.getElementById("input_5").disabled = true;
			document.getElementById("input_7").disabled = true;

		}

	}, [showModalAdd, showModalDelete, showModalEdit]);

*/
	 const handleCompanyScopeSelection = (eventKey) => {
		if (DEBUG_MODE >= 2) console.log("Company scope selected", eventKey);
		setCustomerSelection(eventKey);

	 };
	 
	 
	//Handle access by unauthenticated user
	if (authState !== "signedin"  || !currentUser) {
	  if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState === "signedout") popUpLoginWindow();
	  return null;
	}
	
	//Render function for authenticated user 
	//confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics && !permissionOrders && !permissionBilling) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<h3 className="sectionHeader"> REWARD CENTER DASHBOARD</h3>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
  } else return (
	<Authenticator>
		<ModalNoBackground showModal={showModalAdd || showModalDelete || showModalEdit} closeCallback={() => handleCancel()} cardColor={TEAM_COLOR}  >
			<div className="modalNoBkgInnerCard" onClick = {(e) =>  e.stopPropagation()}>
				<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: TEAM_COLOR}}> 
					<img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  style={{padding:"clamp(4px,1vw,10px)"}} alt='logo'/>  
				</div>
				<h3 className="blue"> 
					{!showModalAdd ? "" : 'ADD PRODUCT'}
					{!showModalEdit ? "" : 'EDIT PRODUCT'}
					{!showModalDelete ? "" :'DELETE PRODUCT'}
				</h3>
				<div className="ContainerVertical">
					<div className="ContainerNoHeightCenter wrap"  >
						<div className="ContainerVertical" style={{padding:"clamp(4px,1vw,10px"}} >
								<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div>Product Name </div>
											<input
												id="input_1"
												name="title"
												type="text"
												onChange={event => setProductDetails({ ...productDetails, title: event.target.value })}
												required
												size="40"
												value={productDetails.title}
												disabled={showModalDelete}

											/>
									</div>
								</div>
								
								<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >
								<div className="inputFormVerticalLeftInputField TextStyle5">
									<div>Active? </div>
										<Switch
											name="useInStore"
											disabled={showModalDelete}
											color={"success"}
											checked={productDetails.useInStore}
											size="small"
											onChange={event => setProductDetails({ ...productDetails, useInStore: !productDetails.useInStore })}

										/>
								</div>
								<div className="inputFormVerticalLeftInputField TextStyle5">
									<div>Featured? </div>
										<Switch
											name="featured"
											disabled={showModalDelete}
											color={"success"}
											checked={productDetails.featured}
											// size="small"
											onChange={event => setProductDetails({ ...productDetails, featured: !productDetails.featured })}
											
										/>
								</div>                            
								<div className="inputFormVerticalLeftInputField TextStyle5">
									<div>Actual Cost</div>
									<div className="ContainerNoHeightCenter">
										$ <input
											id="input_3"
											name="actualCost"
											type="text"
											size="6"                                    
											onChange={event => setProductDetails({ ...productDetails, dollarCost: event.target.value })}
											value={productDetails.dollarCost}
											disabled={showModalDelete}

										/>
									
										<div style={{marginLeft:"10px"}}>{Math.round(CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION*productDetails.dollarCost)}</div>
										<div><img className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt='' /></div>
									</div>
								</div>
							</div>

							<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div>Description</div>
										<textarea
											id="input_2"
											name="description"
											type="text"
											rows="5"                                    
											cols="70"
											onChange={event => setProductDetails({ ...productDetails, description: event.target.value })}
											value={productDetails.description}
											disabled={showModalDelete}
										/>
								</div>
								</div>
							<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >
								
								<div className="inputFormVerticalLeftInputField TextStyle5">
									<div className="ContainerVerticalCenter">
										<div className="ContainerNoHeightCenter">
											<div>DEI Badges</div>
											<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt='' />
										</div>
											<input
												id="input_4"
												name="badgesDEI"
												type="text"
												size="6"                                    
												onChange={event => setProductDetails({ ...productDetails, badgesDEI: event.target.value })}
													value={productDetails.badgesDEI}
												disabled={showModalDelete}

											/>
									</div>

								</div>
								<div className="inputFormVerticalLeftInputField TextStyle5">
									<div className="ContainerVerticalCenter">
										<div className="ContainerNoHeightCenter">
											<div>CS Badges</div>
											<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/>
										</div>
											<input
												id="input_5"
												name="badgesDEI"
												type="text"
												size="6"                                    
												onChange={event => setProductDetails({ ...productDetails, badgesCS: event.target.value })}
													value={productDetails.badgesCS}
													disabled={showModalDelete}
											/>
										</div>
								</div>
								<div className="inputFormVerticalLeftInputField TextStyle5">
										<div className="ContainerVerticalCenter">
										<div className="ContainerNoHeightCenter">
											<div>Spotlights</div>
											<HighlightIcon style={{fontSize:"24px", color:"gold", transform: "rotate(-128deg)"}} />
										</div>
										<input
											id="input_7"
											name="recentSpotlightsI"
											type="text"
											size="6"                                    
											onChange={event => setProductDetails({ ...productDetails, badgesSpotlight: event.target.value })}
											value={productDetails.badgesSpotlight}
											disabled={showModalDelete}
										/>
									</div>
								</div>

							</div>
							<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div>External URL (e.g., link to an online store or site for more details)</div>
											<textarea
											id="input_6"
											name="link"
											type="text"
											rows="3"                                    
											cols="70"
											onChange={event => setProductDetails({ ...productDetails, externalLink: event.target.value })}
											value={productDetails.externalLink}
											disabled={showModalDelete}
										/>
								</div>
								</div>


								<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >

									{!isSuperAdmin ? "" :
								
										<div className="inputFormVerticalLeftInputField TextStyle5">
											<div className="ContainerVerticalCenter">

											<div>Conectere Sponsored</div>
											<Switch
												name="globalProduct"
												disabled={!isSuperAdmin || showModalDelete}
												checked={globalProductSwitchState}  
												onChange={event => {
													// if (DEBUG_MODE >= 2) console.log("event:", event);
													setProductDetails({ ...productDetails, productScope:(!globalProductSwitchState ? "GLOBAL" : "CUSTOMER_SPECIFIC") });
													setDefaultProductSwitchState(false);
													setGlobalProductSwitchState(!globalProductSwitchState);
													
												}}
												color={"success"}
												size="small"
											/>
										</div>
										
									</div>
									}

									{!isSuperAdmin ? "" :

										<div className="inputFormVerticalLeftInputField TextStyle5">
											<div className="ContainerVerticalCenter">

												<div>Default Product</div>
												<Switch
													name="defaultProduct"
													disabled={!isSuperAdmin || showModalDelete}
													checked={defaultProductSwitchState}  
													onChange={event => {
														// if (DEBUG_MODE >= 2) console.log("event:", event);
														setProductDetails({ ...productDetails, productScope:(!defaultProductSwitchState ? "DEFAULT" : "CUSTOMER_SPECIFIC") });
														setDefaultProductSwitchState(!defaultProductSwitchState);
														setGlobalProductSwitchState(false);
														
													}}
													color={"success"}
													size="small"
												/>
											</div>
			
										</div>

								}
								
								
								<div className="inputFormVerticalLeftInputField TextStyle5">
									<div className="ContainerVerticalCenter">

										<div>Charity</div>
										<Switch
											name="charity"
											disabled={showModalDelete}
											checked={productDetails.charity}
											onChange={(event) => setProductDetails({ ...productDetails, charity: !productDetails.charity})}
											color={"success"}
											size="small"
										/>
									</div>

								</div>

							</div>
							{!isSuperAdmin ? "" :

								<div className="Container" style={{width:"100%", paddingBottom: "10px"}} >
									<div className="inputFormVerticalLeftInputField TextStyle5">
										<div>Assign to Customer</div>
										<div style={{fontSize:"18px", color:"#6495ED", width:"300px"}}>
											<Select 
											isDisabled={showModalDelete  ? true : false}
											isMulti={false} 
											name="customerDropdown" 
											options={customerOptionsNoAll} 
											onChange={handleCompanyScopeSelection} 
											value={!customerSelection ? "" : customerSelection}  
											placeholder=" -- assign to customer --"                            
										/>
									</div>
									
								</div>
							</div>
							}        
						</div>
						<div className="ContainerVertical" style={{padding:"clamp(4px,1vw,10px"}} >
							<div >
								{!showModalDelete ? "" : 
									<div className="productImageXLarge">
										<img src={productDetails.image}  className="productImageCenterPortrait" alt=""/>
									</div>
								}
								
								{imageUploaded && !showModalDelete ? 
									<div className="productImageXLarge">
										<img id="uploadedImagedID" src={imageUploaded} alt="" className="productImageCenterPortrait"  />
									</div>   
								: ""}
								{!imageUploaded && !showModalDelete ? 
									<FileUploader
										imageChangeCallback={handleImageUpload} 
										fileTypesToAccept="image/png, image/jpeg"
										buttonText="Upload Image"
									/>   
								: "" }                                                  
							</div>
							<div style={{padding:'5px'}}>
								{!imageUploaded || !showModalDelete ? "" :
									<Button size="sm" variant="outline-secondary" onClick={handleClearImage}><div className="buttonTextBlack"> Remove Image</div></Button>
								}

							</div>
						</div>
					</div>
				</div>
				<div className="modalNoBkgFooter flexEnd">
					{showModalAdd ? 
						<div className="ContainerNoHeight">
							<div style={{paddingRight:"10px"}}>
								<Button variant="primary" onClick={handleSubmit} disabled={productDetails.title==="" || !imageUploaded}> Save </Button>
							</div>
							<div style={{paddingRight:"10px"}}>
								<Button variant="secondary" onClick={handleCancel}> Cancel</Button>
							</div>
						</div>
					: null  }
						
					{showModalEdit ? 
						<div className="ContainerNoHeight">
								<div style={{paddingRight:"10px"}}>
									<Button variant="primary" onClick={handleConfirmationEdit} disabled={productDetails.title==="" || !imageUploaded}> Save </Button>
								</div>
								<div style={{paddingRight:"10px"}}>
									<Button variant="secondary" onClick={handleCancel}>Cancel </Button>
								</div>
						</div>
					: null  }
				
					{showModalDelete ? 
						<div className="ContainerNoHeight">
								<div style={{paddingRight:"10px"}}>
								<Button variant="primary" onClick={handleConfirmationDelete}> Delete </Button>
								</div>
								<div>
								<Button variant="secondary" onClick={handleCancel}> Cancel </Button>
								</div>
						</div>
					: null  }   
				</div>
			</div>
		</ModalNoBackground>                 
			
		 
		<div className="adminPage">
			<div className="dashboardContainer">
			 
				<div className="dashboardCardRow" >
					<CCdisplayCardProductStats analytics={customerWideProductAlytics}/>
					<CCdisplayCardProductActivity analytics={customerWideProductAlytics}/>
					<CCdisplayCardProductFavorites productNumbers={perProductAnalytics} />
				</div>
				<div className="dashboardTitle">
					REWARDS
					<AddButton icon = {<RedeemIcon />}  onClickCallback={handleAdd} hoverText="Add Item" />  
				</div>   
				<div className="dataGridWrapperTop" >
					<MenuDataGrid 
						dataGridRows={productRows} 
						dataGridColumns={productColumns}
					/>  
				</div>
			</div>
		</div>
			
	</Authenticator>
  );
};

export default AdminEmployeeStorePage;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021


import "./adminReportEmployeesByConectivity.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH, teamAnalyticsInitialState} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator,  } from '@aws-amplify/ui-react';


//Queries and Mutations

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

//Icons
import AssignmentIcon from '@mui/icons-material/Assignment';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                // User Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context

//Our Components
import CCRadarChart from "./Components/radarChart/radarChart";
import { ConectivityGraph } from "./Components/conectivityGraph/conectivityGraph";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import CCdisplayCardTeamStatsModal from "./Components/displayCardTeamStatsModal/displayCardTeamStatsModal";

//Utils
import {compareUsersByName, compareRowsByTeamRank} from "../../shared/utils/userAndTeamUtils";
import {fetchRecordsByPrimaryKey, queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import { compareConectivitiesMostFavorite, setHeaderColorByCategory, setConectivityImageUrlByCategory } from "../../shared/utils/conectivityUtils";
import {compareTransactionsByDate, compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { displayConectivityScoreAndRank, displayAwards, Divider } from "../../shared/utils/generalUtils";
import { displayTeams } from "../../shared/utils/userAndTeamUtils";

import moment from 'moment';


//Here's the start of our Javascrpt App
const ReportTeamsByConectivityPage = () => {

    const [teamRows,setTeamRows]=useState([]);                                    //Formatted rows for user grid

   //variable to control confirmation Modal if user presses DELETE; default to hidden
    const [showModalSummary, setShowModalSummary] = useState(false);  
 

   // Authentication context
    const {  authState, currentUser, isSuperAdmin, isAdmin,  permissionAnalytics, } = useContext(AuthContext);    

   // Customer context
    const {  users, teams,   } = useContext(CustomerContext);    
        
  
    //Analytics context
    const {
           analyticsGenerated,
            userAnalytics, teamAnalytics, customerAnalytics,
            userToSummarize, setUserToSummarize,
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData,
            displayReportControls, selectedUsersOptions, selectedTeamsOptions, transactionStartDateTime, transactionEndDateTime, setSelectedReport,

    } = useContext(AnalyticsContext);
        
    //Summary Modal state data
    const [teamToSummarize, setTeamToSummarize] = useState(teamAnalyticsInitialState);                
    const [userRowsSummary,setUserRowsSummary] = useState([]);
    
    //On load, set Report select option to THIS page
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "TEAM_CON");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
    });   

    // Handle updates to context
    useEffect(() => {
        //Rebuild rows for Data Grid using updated Context
        buildTeamRows();
    }, [authState, analyticsGenerated, teams, teamAnalytics, selectedTeamsOptions]);
 
 
  

    function buildTeamRows() {
        if (authState != "signedin") return;

       //wait for users, teams, transactions and corresponding analytics to be loaded by context
        if (!analyticsGenerated) return;
        
        if (teams==null) return;
        if (teams.length == 0) return;

        //Filter transactions based on team selections
        var tempTeams = [];
        if (selectedTeamsOptions.length===0) 
           tempTeams = [...teams];
        else 
            tempTeams = teams.filter(team => selectedTeamsOptions.find(teamOption => teamOption.id == team.id));


        if (DEBUG_MODE >= 2) console.log("Filtered teams", tempTeams);
 
 
        
           //Build rows for Data Grid
            var tempTeamRows = [];
            
            for(var i = 0; i<tempTeams.length; i++){
                
                //Find matching Teams STATS object
                var tempCurrentTeamStats = teamAnalytics.find(team => team.teamID === tempTeams[i].id);  // Grab match for DynamoDB ID

                if (tempCurrentTeamStats == undefined) tempCurrentTeamStats = teamAnalyticsInitialState; 
                    
              if (DEBUG_MODE >= 2) console.log("Building Row - Found matching team stats object for team", tempCurrentTeamStats, tempTeams[i]);
              
                tempTeamRows[i] = {
                    id:i+1,
                    dynamoDBID:tempTeams[i].id,
                    name:tempTeams[i].name,
                    nickname:tempTeams[i].nickname,
                    department:tempTeams[i].department,
                    relativeConectivityScore: tempCurrentTeamStats.relativeConectivityScore,
                    coinsAwarded: tempCurrentTeamStats.coinsAwarded,
                    conectivitiesCompleted: tempCurrentTeamStats.conectivitiesCompleted,
                    badgesCSAwarded: tempCurrentTeamStats.badgesCSAwarded,
                    badgesDEIAwarded: tempCurrentTeamStats.badgesDEIAwarded,
                    userRank:tempCurrentTeamStats.teamRank,  
                    memberCount:tempTeams[i].users.items.length,
                };
            }

        //Sort the user rows by rank
        tempTeamRows.sort(compareRowsByTeamRank);

        if (DEBUG_MODE >= 2) console.log("Built Team Rows:", tempTeamRows);
        setTeamRows(tempTeamRows);
    }
    
    function formatDate(inputTime) {
        
    	const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
        return tempTime.format(" MMM DD, YYYY ");

    }


    //Define User Grid for Team Summary
    const userColumnsSummary = [
      {
        field: 'name',
        headerName: 'NAME',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        flex:1,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
      },
      {
        field: 'relativeConectivityScore',
        headerName: 'SCORE',
        headerAlign: 'center',
        align:'center',
        minWidth: 100,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
        renderCell: (params)=> {
            return (
                <span> 
                <center>{params.row.relativeConectivityScore.toFixed(1)}</center>
                </span>
                )
        }

      },
      {
        field: 'teamRank',
        headerName: 'RANK',
        headerAlign: 'center',
        align:'center',
        minWidth: 100,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
      },
      {
        field: 'conectivitiesCompleted',
        headerName: 'CONECTIVITIES',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
      },
      {
        field: 'coinsAwarded',
        headerName: 'AWARDS',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
        renderCell: (params)=> {
            return displayAwards({coins:params.row.coinsAwarded, badgesDEI:params.row.badgesDEIAwarded, badgesCS:params.row.badgesCSAwarded});
        }     
      },
      {
        field: 'teams',
        headerName: 'TEAMS',
        headerAlign: 'center',
        align:'center',
        sortable: true,
         // resizable: true,
        minWidth: 250,
        flex:1,
        color:'white',
        disableColumnMenu: true,
        },

    ];    
    
    
    //Define Team Grid Format
    //Note - resizable appears to be available only in Data Grid Pro
  
    //Define Team Grid Format
    //Note - resizable appears to be available only in Data Grid Pro
    
    const teamColumnsAdmin = [
      {
        field: 'teamRank',
        headerName: 'CONECTIVITY',
        sortable: true,
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
        disableColumnMenu: true,
        filterable:true,
        renderCell: (params)=> {
            return displayConectivityScoreAndRank(params.row.relativeConectivityScore, params.row.rank);
       }

      },
    {
        field: 'name',
        headerName: 'TEAM',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        flex:1,
         // resizable: true,
        color:'white',
        filterable:true,
        disableColumnMenu: false,
        sortable:true,
      },
    {
        field: 'nickname',
        headerName: 'NICKNAME',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
         // resizable: true,
        color:'white',
        filterable:true,
        editable: false,
        disableColumnMenu: true,
        hide: true,
      },
      {
        field: 'department',
        headerName: 'DEPARTMENT',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        color:'white',
        filterable:true,
        editable: false,
        disableColumnMenu: true,
        hide: true,
      },
      {
        field: 'memberCount',
        headerName: 'MEMBERS',
        headerAlign: 'center',
        align:'center',
        minWidth: 135,
         // resizable: true,
        filterable:true,
        disableColumnMenu: true,
        color:'white',
      },      
    //   {
    //     field: 'relativeConectivityScore',
    //     headerName: 'SCORE',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 125,
    //     resizable: true,
    //     filterable:true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //     disableColumnMenu: true,
    //     renderCell: (params)=> {
    //         return (
    //             <span> 
    //             <center>{params.row.relativeConectivityScore.toFixed(1)}</center>
    //             </span>
    //             )
    //     }

    //   },
    //   {
    //     field: 'teamRank',
    //     headerName: 'Rank',
    //     headerAlign: 'center',
    //     filterable:false,
    //     align:'center',
    //     minWidth: 125,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //   },
      {
        field: 'conectivitiesCompleted',
        headerName: 'CONECTIVITIES',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
         // resizable: true,
        disableColumnMenu: true,
        filterable:true,
        color:'white',
      },
      {
        field: 'coinsAwarded',
        headerName: 'AWARDS',
        headerAlign: 'center',
        align:'center',
        minWidth: 250,
         // resizable: true,
        color:'white',
        filterable:true,
        disableColumnMenu: true,
        renderCell: (params)=> {
            return displayAwards({coins:params.row.coinsAwarded, badgesDEI:params.row.badgesDEIAwarded, badgesCS:params.row.badgesCSAwarded});
        }
      },
    {
        field: 'badgesDEIAwarded',
        headerName: 'DEI',
        width: 105,
        editable: false,
        color:'white',
        hide:true,
        filterable:true,
        disableColumnMenu: true,
         renderCell: (params)=> {
            return (
                    
                    <div>{!params.row.badgesDEIAwarded ? "" :
                        <div>
                            {params.row.badgesDEIAwarded} 
                            <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
                        </div>
                        }
                    </div>
                );
        }
      },
      {
        field: 'badgesCSAwarded',
        headerName: 'CS',
        width: 105,
        editable: false,
        color:'white',
        hide:true,
        filterable:true,
        disableColumnMenu: true,
         renderCell: (params)=> {
            return (
                    
                <div>{!params.row.badgesCSAwarded ? "" :
                    <div>
                        {params.row.badgesCSAwarded} 
                        <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
                    </div>
                    }
                </div>
            );
         }
      },
     {   
        field: 'action',
        headerName: '  ',
        headerAlign: 'center',
        align:'center',
        minWidth: 100,
         // resizable: true,
        color:'white',
        sortable: false,
        filterable:false,
        disableColumnMenu: false,
        disableExport: true,         
        renderCell: (params)=> {
            return (
                <div className="ContainerMaxHeightSpaceBetween">
                    <div className="menuDataGridListButton" >
                        <Button variant="light" size="sm" >
                            <AssignmentIcon className="summaryButton" onClick={()=>handleSummary(params.row.dynamoDBID)}/>
                        </Button>
                    </div>
                </div>
                );
                
            }
        },
        
    ];


    // Tabs - move to a component
    const [tabIndexValue, setTabIndexValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndexValue(newValue);
    };

    
    function TabPanel({children, value, index}) {

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tabs-${index}`}
        >
        
        {children}
        
        </div>
      );
    }

    const handleCancelUserSummary = () => {
        setShowModalSummary(false);
    };
        
    const handleSummary = (id) => {

        if (DEBUG_MODE >= 2) console.log("Displaying summary for TEAM having DynamoDB ID:", id);

        //Compute radar data for Modal for this team
        computeRadarChartData(id, true);

        //Get analytics for this team from numbers table based on ID since position in team array may have been sorted and not 1-to-1 correspondence between arrays
        const tempTeamToSummarize = teamAnalytics.find(team => team.teamID === id);
        
        if (tempTeamToSummarize == undefined) return;
        
        if (DEBUG_MODE >= 2) console.log("Displaying summary for team ", tempTeamToSummarize);

        setTeamToSummarize(tempTeamToSummarize);

        //Build user rows for a team-specific summary
        const thisTeamsUsers = users.filter(user => user.teams.items.some (join => join.teamID === id));

        if (DEBUG_MODE >= 2) console.log("setting user rows for team summary",id, thisTeamsUsers);
        
         var tempUserRows = [];
        
        for(var i = 0; i<thisTeamsUsers.length; i++){
            
            //Find matching STATS object
            const tempCurrentUser = userAnalytics.filter(user => user.userID === thisTeamsUsers[i].id);  // Grab match for DynamoDB ID
            // if (DEBUG_MODE >= 2) console.log("Found matching user stats object for user", tempCurrentUser[0], tempUsers[i]);

            //Set user values for summary
            tempUserRows[i] = {
            id:i+1,
            dynamoDBID:thisTeamsUsers[i].id,
            name:thisTeamsUsers[i].firstName + (!thisTeamsUsers[i].middleName ? "" : " " + thisTeamsUsers[i].middleName.substr(0,1)) + " " + thisTeamsUsers[i].lastName,
            title:thisTeamsUsers[i].title,
            email: thisTeamsUsers[i].email,
            teams: displayTeams(thisTeamsUsers[i]),
            relativeConectivityScore: tempCurrentUser[0].relativeConectivityScore,
            coinsAwarded: tempCurrentUser[0].coinsAwarded,
            conectivitiesCompleted: tempCurrentUser[0].conectivitiesCompleted,
            badgesCSAwarded: tempCurrentUser[0].badgesCSAwarded,
            badgesDEIAwarded: tempCurrentUser[0].badgesDEIAwarded,
            teamRank:tempCurrentUser[0].teamRank,
            };

        }
        
        if (DEBUG_MODE >= 2) console.log("Built user rows for team summary:", tempUserRows);
        setUserRowsSummary(tempUserRows);

        setShowModalSummary(true);

    };

 
    
     //Handle access by unauthenticated user
    if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
    }
    
    //Render function for authenticated user 

    //confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else  return (

    <Authenticator>

        <Modal show={showModalSummary} onHide={handleCancelUserSummary} size="xl" centered>
            <Modal.Header closeButton>
              <Modal.Title>Team Conectivity Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="tabHeaderBox">
                    <Tabs value={tabIndexValue} onChange={handleTabChange} aria-label="tabs">
                      <Tab label="Stats" value={0} />
      {true ? "" : 
                      <Tab label="Conectivity Graph" value={1} />
      }
                      <Tab label="History" value={2} />
                    </Tabs>
                    <Divider />
                  <TabPanel value={tabIndexValue} index={0}>
                       <div className="dashboardCardRow" style={{paddingBottom:"clamp(8px,2vw,20px)"}}>
                            <CCdisplayCardTeamStatsModal analytics={teamToSummarize}/>
                            <div>
                                <CCRadarChart 
                                  data={radarChartData} title={!radarChartTargetName ? "Conectivity Visualization" :  radarChartTargetName + " Conectivity"}  
                                  dataKeyAngle="subject" 
                                  radarName1={radarChartNames.length < 0 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 0 ? "" : radarChartColors[0]}
                                  radarName2={radarChartNames.length < 1 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length < 1 ? "" : radarChartColors[1]}
                                grid/>
                            </div>
                        </div>
                 </TabPanel>

        {true ? "" :

                  <TabPanel value={tabIndexValue} index={1}>
                    <center>
                        <div className="dataGridWrapperTop" style = {{width:"1000px"}}>        
                                <ConectivityGraph graphWidth="1200px" graphHeight="800px" userToGraph={userToSummarize}/>
                        </div>
                    </center>

                  </TabPanel>

        }

                  <TabPanel value={tabIndexValue} index={2}>
                      <div className="dataGridWrapperTop">        
                          <MenuDataGrid dataGridRows={userRowsSummary} dataGridColumns={userColumnsSummary} pageSize={5} turnOffBorder={true}/>
                      </div>
                </TabPanel>
                </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>
                <div > Close </div>
              </Button>
            </Modal.Footer>
         </Modal>    

            <div className="adminPage">
                <div className="ContainerVertical">
                
                    {displayReportControls({useUserSelect:false, useTeamSelect:true, useDateSelect:false,  useReportSelect:true, width:"auto"})}
                    
                    <div className="dashboardContainer boxShadow">
                        <div className="dashboardTitle">
                            TEAM CONECTIVITY
                         </div>   
                        
                            <div className="dataGridWrapperTop" >        
                                <MenuDataGrid 
                                title="TEAM CONECTIVITY"
                                showToolBar = {true}
                                dataGridRows={teamRows} 
                                dataGridColumns={teamColumnsAdmin }
                                    // width={(isSuperAdmin) ? REPORT_DATAGRID_WIDTH_SUPERADMIN : REPORT_DATAGRID_WIDTH}
                                />
    
                            </div>
    
                    </div>    
                </div>
            </div>    
            
        </Authenticator>
 
    );
};




export default ReportTeamsByConectivityPage;
import React, { useEffect, useState, useRef } from "react";
import "./BonusModal.css";

const CoinAnimation = ({ isAnimating, children }) => {
    const [position, setPosition] = useState({ top: "40%", left: "50%", transform: "scale(1) rotate(10deg)" });
    const [coinSize, setCoinSize] = useState('')
    const imageRef = useRef(null);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        if (isAnimating && imageRef.current) {
            const headerElement = document.querySelector("#header-target");
            const headerRect = headerElement.getBoundingClientRect();

            const targetTop = headerRect.top ;
            const targetLeft = headerRect.left ;

            setPosition({ top: "40vh", left: "50vw", transform: "scale(1) rotate(30deg)" });

            imageRef.current.style.transition = "none";
            imageRef.current.getBoundingClientRect();
            setCoinSize('30px')
            setTimeout(() => {
                imageRef.current.style.transition = "top 1.25s ease-in-out, left 1.25s ease-in-out, transform 3s linear";
                setPosition({
                    top: `${targetTop}px`,
                    left: `${targetLeft}px`,
                    transform: "scale(1) rotate(60deg)",
                });
                setCoinSize('50px')
            }, 50);
            setTimeout(() => {
                setCoinSize('60px')
            }, 400);
            setTimeout(() => {
                setCoinSize('35px')
            }, 1300);
            setTimeout(() => {
                setOpacity(0); 
            }, 1600);
        }
    }, [isAnimating]);

    return (
        <div
            ref={imageRef}
            className="floating-element"
            style={{
                top: position.top,
                left: position.left,
                transform: position.transform,
                zIndex: '9999',
                width: coinSize,
                height: coinSize,
                opacity: opacity, 
                transition: `opacity 0.3s ease-out`,
            }}
        >
            {children}
        </div>
    );
};

export default CoinAnimation;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD, DEI_COLOR1, CS_COLOR1, COLOR_BLUE_TEXT, BALANCE_COLOR, SOCIAL_COLOR, GROWTH_COLOR, TEAM_COLOR, REPORT_SELECTION_OPTIONS, REPORT_DATAGRID_WIDTH_SUPERADMIN, REPORT_DATAGRID_WIDTH } from '../../shared/data/conectereConfigData';

//React and AWS components
import React,{ useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";

//Queries and Mutations
import {  getCCTransactionsByUserByDate } from '../../shared/graphql/queries';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";

//Our Analytics components
import CCdisplayCardUserStatsModal from "./Components/displayCardUserStatsModal/displayCardUserStatsModal";
// import CCdisplayCardUserNumbers from "./Components/displayCardUserNumbers/displayCardUserNumbers";
import CCRadarChart from "./Components/radarChart/radarChart";

//Our Conectivity Graphing module
import { ConectivityTimeSeriesAnimation } from "./Components/conectivityTimeSeries/conectivityTimeSeries";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';
import { AnalyticsContext } from '../../shared/context/analyticsContext';

//Utils
import {queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import {compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";

import moment from 'moment';

const AdminGraphTimeSeriesConectivity = () => {
  
   //Authentication context
    const {
            authState, currentUser, 
            isSuperAdmin, 
            isAdmin, 
            permissionAnalytics,
            
    } = useContext(AuthContext);    
 
    //Analytics context
    const {
            userAnalytics, 
            radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData,
            displayReportControls,  setSelectedReport,
             transactionsFiltered,
    } = useContext(AnalyticsContext);


    //State data 
    const [userToSummarize, setUserToSummarize] = useState(null);  
    const [showModalSummary, setShowModalSummary] = useState(false);  
    const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);


    //Define Grid for User Summary
    const transactionSummaryColumns = [
      {
        field: 'createdAt',
        headerName: 'Date',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
        // resizable: true,
        color:'white',
        valueFormatter: ({ value }) => {
        	const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
            return tempTime.format(" MMM DD, YYYY ");
                
        },
        renderCell: (params)=> {
            return (
                <span> 
                <center>{formatDate(params.row.createdAt)}</center>
                </span>
                );
        }

      },
      {
        field: 'name',
        headerName: 'Transaction',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
        renderCell: (params)=> {
            return (
                <div >
                    <img src={params.row.image} width='24' />
                    {params.row.name}
                </div>
                );
        },

      },
      {
        field: 'memo',
        headerName: 'Memo',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
      },
      {
        field: 'amount',
        headerName: 'Amount',
        headerAlign: 'center',
        align:'center',
        minWidth: 200,
         // resizable: true,
        color:'white',
        renderCell: (params)=> {
            return (
                <div className="ContainerNoHeight">
                    <div className= {params.row.amount<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 

                        {params.row.amount<0 ? "" : "+"} 
                        {params.row.amount}  
                        <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />
                    </div>
                    
                    {!params.row.badgesDEI || params.row.badgesDEI ==0 ? "" :
                        <div className= {params.row.badgesDEI<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
                            {params.row.badgesDEI<0 ? "" : "+"} 
                            {params.row.badgesDEI}  
                            <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
                        </div>
                    }
                    {!params.row.badgesCS || params.row.badgesCS ==0 ? "" :
                        <div className= {params.row.badgesCS<0 ? "TextStyle3 debitDataStyle" : "TextStyle3 creditDataStyle"}> 
                            {params.row.badgesCS<0 ? "" : "+"} 
                            {params.row.badgesCS}  
                            <img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
                        </div>
                    }
                </div>
                );
        }

      },
    ];

    function formatDate(inputTime) {
        
    	const tempTime = moment(inputTime, "YYYY MM DDTHH mm ssZ");
    	
        return tempTime.format(" MMM DD, YYYY ");

    }
 
    const handleCancelUserSummary = () => {
        setShowModalSummary(false);
    };
        
    async function handleSummary (id) {

        setShowModalSummary(true);  //Dispay right away to provide user feedback

        if (DEBUG_MODE >= 2) console.log("Displaying summary for user having DynamoDB ID:", id);


        //Compute radar data for Modal for this user
        computeRadarChartData(id);

        //Get analytics for this user from numbers table based on ID since position in user array may have been sorted and not 1-to-1 correspondence between arrays
        const tempUserToSummarize = userAnalytics.find(user => user.userID === id);
        
        if (tempUserToSummarize != undefined) return;
        
        if (DEBUG_MODE >= 2) console.log("Displaying summary for user ", tempUserToSummarize);

        setUserToSummarize(tempUserToSummarize[0]);
        
        // Determine oldest engagement value for this user
        const startDateString = moment().substract(30,"days").toISOString();             
        const todayString =  moment().toISOString();
        // if (DEBUG_MODE >= 2) console.log("Fetching user transactions; earliest momentum date " + oldestEngagementValueDateString);

         //Now, grab transactions for this user

        const queryParams = {
                    userID: tempUserToSummarize.id,                             //Primary Key
                    createdAt: {between: [ startDateString, todayString ]},     //Secondary Key
                };
        
        var  tempCCTransactions =  await queryDataTableWithPagination(getCCTransactionsByUserByDate, "getCCTransactionsByUserByDate", queryParams);
        var tempTransactions = tempCCTransactions.filter(transaction => transaction.transactionType ==="COMPLETED_CONECTIVITY" );


        //Sort the transactions by sequence Number
        tempTransactions.sort(compareTransactionsByDateReverseChronological);
        
        if (DEBUG_MODE >= 2) console.log("computing transaction log for user ",id, tempTransactions);
        
         //Build rows for user Summary Data Grid 
        var tempThisUsersRows = [];
        for(var i = 0; i<tempTransactions.length; i++){
            tempThisUsersRows[i] = {
            id:i+1,
            name:tempTransactions[i].name,
            amount:tempTransactions[i].amount,
            badbesCS:tempTransactions[i].badgesCS,
            badgesDEI:tempTransactions[i].badgesDEI,
            newBalance:tempTransactions[i].newBalance,
            badgesDEIBalance:tempTransactions[i].badgesDEIBalance,
            badgesCSBalance:tempTransactions[i].badgesCSBalance,
            createdAt:tempTransactions[i].createdAt,
            memo:tempTransactions[i].memo,
            sequenceNumber:tempTransactions[i].sequenceNumber,
            image:tempTransactions[i].image,
          
            }
        }
        
        if (DEBUG_MODE >= 2) console.log("Built user rows for user summary:", tempThisUsersRows)
        setTransactionRowsSummary(tempThisUsersRows);

        setShowModalSummary(true);

    }


    //On load, set Report select option to THIS page
    useEffect(() => {
        const thisReportIndex = REPORT_SELECTION_OPTIONS.findIndex (report => report.tag == "CONECTIVITY_TIME_SERIES_GRAPH");
        if (thisReportIndex > -1) setSelectedReport(REPORT_SELECTION_OPTIONS[thisReportIndex]);
    });


    // 
    // HANDLERS FOR RESPONDING TO UPDATES TO REPORT CONTROLS 
    //


  function handleHoverNode (event) {
    
    if (DEBUG_MODE >= 2) console.log("Hover on node", event.node);
    
  }
  
  function handleNodeDoubleClick (event) {
    
    if (DEBUG_MODE >= 2) console.log("Double click on node", event);
    
    if (event.nodes.length > 0) {
      handleSummary(event.nodes[0]);
    }
    
  }

    // 
    // HANDLERS FOR RESPONDING TO UPDATES TO REPORT CONTROLS 
    //
    
 
 //Handle access by unauthenticated user
  if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
  }

 //Render function for authenticated user     
    
    //confirm access to this page
    if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else return (
        <Authenticator>
             <div className="adminPage">
               
                <Modal show={showModalSummary} onHide={handleCancelUserSummary} size="xl">
                    <Modal.Header closeButton>
                      <Modal.Title>Employee Conectivity Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
        
                        <div className="dashboardCardRow">
                            <CCdisplayCardUserStatsModal analytics={userToSummarize}/>
                            <div>
                                <CCRadarChart 
                                  data={radarChartData} title={!radarChartTargetName ? "Conectivity Visualization" :  radarChartTargetName + " Conectivity"}  
                                  dataKeyAngle="subject" 
                                  radarName1={radarChartNames.length < 1 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 1 ? "" : radarChartColors[0]}
                                  radarName2={radarChartNames.length < 2 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length <1 ? "" : radarChartColors[1]}
                                grid/>
                            </div>
        
                        </div>
        
                        <div className="dataGridWrapperTop">        
                            <MenuDataGrid dataGridColumns={transactionRowsSummary} pageSize='10' rowHeight='40' dataGridColumns={transactionSummaryColumns}/>
                        </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCancelUserSummary} disabled={false}>
                        <div > Close </div>
                      </Button>
                    </Modal.Footer>
                 </Modal>   
                 
                <div className="ContainerVertical">

                    {displayReportControls({useUserSelect:true, useDateSelect:true,  width:"auto", useReportSelect:true, usePeriodSelect:true, useGraphItemSelect:true})}

                    <div className="dashboardContainer boxShadow">
                        <div className="dashboardTitle">
                            EMPLOYEE CONECTIVITY - TIME SERIES ANIMATION
                         </div>   
                        <div className="dataGridWrapperTop" style={{width:(isSuperAdmin) ? REPORT_DATAGRID_WIDTH_SUPERADMIN : REPORT_DATAGRID_WIDTH}}>        
                             <ConectivityTimeSeriesAnimation doubleClickCallback={handleNodeDoubleClick} transactions={transactionsFiltered} graphWidth={REPORT_DATAGRID_WIDTH} graphHeight="auto"/>
                         </div>
                    </div>  
                </div>
                
           </div>
       </Authenticator>

  );
};




export default AdminGraphTimeSeriesConectivity;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import '../../shared/styles/App.css';

//Admin page by which superAdmin manages the ccTransactions available in the system
//including  assignment to Conectivties

//DATA
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR } from '../../shared/data/conectereConfigData';
import { userSummaryColumns } from  '../../shared/data/gridColumns';


//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import { API } from 'aws-amplify';
import {Authenticator } from '@aws-amplify/ui-react';

//Context
import { AuthContext } from '../../shared/context/authContext';
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { DisplayContext } from '../../shared/context/displayContext';


//Queries and Mutations
import { getCCTransactionsByUserByDate} from '../../shared/graphql/queries';
import { createCCTransaction, deleteCCTransaction, updateCCTransaction} from '../../shared/graphql/mutations';
import { getCCWallet, listCCWallet } from '../../shared/graphql/queries'; 
import { getUser, listUsers} from '../../shared/graphql/queries';
import { getTeam, listTeams} from '../../shared/graphql/queries';
import GroupsIcon from '@mui/icons-material/Groups';


//Material-X
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

//Our Components
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import GiftCoins from "./Components/giftCoins/giftCoins";

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';   //Version with support from multiple selections


//Utils
import {queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import { setConectivityImageUrlByCategory, setHeaderColorByCategory, setHeaderColorByStringLength } from "../../shared/utils/conectivityUtils";
import { TransactionHistory, compareTransactionsByDateReverseChronological, isPastTransaction}  from "../../shared/utils/transactionsUtils";
import { GiftButton, formatDateWithDayOfWeek, HelpButton, formatDate, ConectereDivider, displayTransactionAmount, displayAwards, truncateStringWithDots } from "../../shared/utils/generalUtils";
import {NOW, NOW_STRING, ONE_YEAR_AGO, ONE_YEAR_AGO_STRING, ONE_MONTH_AGO, ONE_MONTH_AGO_STRING} from "../../shared/utils/dateTimeUtils";
import moment from 'moment'; 

    
//Here's the start of our Javascrpt App
const UserAccountHistoryPage = () => {

    // Context
	const { authState, currentUser, userWallet  } = useContext(AuthContext);    // Authentication context
	const { isMobileViewPort, darkMode } = useContext(DisplayContext); 
    const { users } = useContext(CustomerContext); 

    //define variables to hold form state; initialize to empty
    const [showModalParticipants, setShowModalParticipants] = useState(false);
    const [participantsToShow, setParticipantsToShow] = useState([]);
    const [teamParticipantsToShow, setTeamParticipantsToShow] = useState([]);
    const [participantsHeaderColor, setParicipantsHeaderColor] = useState('blue');

    
    //Account History Stats
    const userNumbersInitialState = {coinsReceived: 0, conectivities: 0, coinsSpent: 0, purchases: 0, giftsSent:0, giftsReceived:0, badgesDEIReceived: 0, badgesCSReceived: 0,  badgesSpotlightsReceived: 0, badgesDEISpent: 0, badgesCSSpent: 0, badgesSpotlightsSpent: 0};
    const [userNumbers, setUserNumbers] = useState(userNumbersInitialState);
    const [transactionRowsSummary,setTransactionRowsSummary] = useState([]);
    const [transactionGroups, setTransactionGroups] = useState([]);


    //Transaction cache
    const [transactionCache, setTransactionCache] = useState([]);
    const [transactionStartDateTime, setTransactionStartDateTime] = useState(ONE_MONTH_AGO());               
    const [transactionEndDateTime, setTransactionEndDateTime] = useState(NOW());               
    const [transactionOldestStartDateTimeFetched, setTransactionOldestStartDateTimeFetched] = useState(ONE_MONTH_AGO());               
    const [transactionCacheInitialized, setTransactionCacheInitialized] = useState(false);
    const [transactionCountFilteredDateRange, setTransactionCountFilteredDateRange] = useState(0)
    const [transactionsFiltered, setTransactionsFiltered] = useState([]);


    //Modals
    const [showModalGift, setShowModalGift] = useState(false);
 

    //Get transactions on auth change, search param change or an update to the user Wallet, which likely means a new transaction was recorded
    useEffect(() => {
        updateTransactionCache({forceReload:false});     
    }, [authState, currentUser, transactionStartDateTime]);  

    useEffect(() => {
        updateTransactionCache({forceReload:true});     
    }, [ userWallet]);  

/*    
    //Define Grid for User Summary
    const transactionSummaryColumns = [
      {
        field: 'createdAt',
        headerName: 'DATE',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
        color:'white',
        valueFormatter: ({ value }) => {
        	const tempTime = moment(value, "YYYY MM DDTHH mm ssZ");
            return tempTime.format(" MMM DD, YYYY ");
                
        },
        renderCell: (params)=> {
            return (
                <div> 
                    {formatDate(params.row.createdAt)}
                </div>
                );
        }

      },
      {
        field: 'name',
        flex:1,
        headerName: 'TRANSACTION',
        headerAlign: 'center',
        minWidth: 300,
        color:'white',
        renderCell: (params)=> {
            return (
                <div className="ContainerNoHeightCenter">
                    <div>
                        <img src={params.row.image} height='32' />
                    </div>
                    <div style={{padding:'10px', color:setHeaderColorByCategory(params.row.category)}}>
                    {truncateStringWithDots(params.row.name, CONECTERE_CONFIG_DATA.CONECTIVITY_TITILE_MAX_CHARS)}
                    </div>
                </div>
             )
            }
        },
      {
        field: 'memo',
        headerName: 'MEMO',
        headerAlign: 'center',
        hide:true,
        minWidth: 250,
        color:'white',
        renderCell: (params)=> {
            return (
                 <div className="TextStyle2 memoDataStyle">
                    {params.row.memo}
                </div>
            )
        },
      },
    //   {
    //     field: 'memo',
    //     headerName: 'Memo',
    //     headerAlign: 'center',
    //     align:'center',
    //     minWidth: 200,
    //     resizable: true,
    //     background:COLOR_BLUE_HEADER,
    //     color:'white',
    //   },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        headerAlign: 'center',
        align:'center',
        minWidth: 175,
        color:'white',
        renderCell: (params)=> {
            return displayTransactionAmount({amount:params.row.amount, badgesDEI:params.row.badgesDEI, badgesCS:params.row.badgesCS, badgesSpotlight:params.row.badgesSpotlight});
        }

      },

    {
    field: 'usersToShow',
    headerName: 'PARTICIPANTS',
    headerAlign: 'center',
    align:'center',
    minWidth: 250,
    sortable:false,
    // resizable: true,
    background:COLOR_BLUE_HEADER,
    color:'white',
    renderCell: (params)=> {
        
        const USERS_TO_SHOW = 5;
        return (
            <div className="ContainerNoHeightCenter" style={{height:"100%", width:"300px"}}>
                 {params.row.usersToShow.length == 0 ? "" :
                    <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: "#409cf7"}} >
                                       
                        {params.row.usersToShow.map((target, index) => ( 
                            <div key={target.id}> 
                                {index <=USERS_TO_SHOW-1 ? 
                                    <div className="avatarContainer">
                                        <div className="avatarImage avatarImageSmall" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
                                            {!target.avatarUrl  ? <div className="avatarInitialsSmall" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
                                        </div>
                                    </div>
                                : "" }
                            </div>
    
                        ))}
                        
                        {params.row.usersToShow.length > USERS_TO_SHOW ? 
                            <div className="displayCardConectivityAvatarNoPaddingSmall"style={{background:SOCIAL_COLOR, color:"white"}}>
                                <div className="avatarInitials" >+{params.row.usersToShow.length-USERS_TO_SHOW}</div>
                            </div>
                         : ""   
                        }
                        
                      </div>
                 }
            
            </div>            
            );
        }, 
    },
      {
        field: 'balance',
        headerName: 'BALANCE',
        headerAlign: 'center',
        align:'center',
        minWidth: 240,
        color:'white',
        renderCell: (params)=> {
            return displayAwards({coins:params.row.newBalance, badgesDEI:params.row.badgesDEIBalance, badgesCS:params.row.badgesCSBalance, badgesSpotlight:params.row.badgesSpotlightBalance, showZeros:false});

        }        
      },        
      {
        field: 'action',
        headerName: 'CONNECTIONS',
        headerAlign: 'center',
        align:'center',
        minWidth: 150,
        color:'white',
        sortable: false,
        renderCell: (params)=> {
            if (!params.row.usersThatParticipatedWithMe && !params.row.teamsThatParticipatedWithMe) return null;
            
            
            const tempParticipants = (params.row.usersThatParticipatedWithMe?.length > 0 ? params.row.usersThatParticipatedWithMe : []);
            const tempTeamParticipants = (params.row.teamsThatParticipatedWithMe?.length > 0 ? params.row.teamsThatParticipatedWithMe : []);

            if (tempParticipants?.length == 0 && tempTeamParticipants?.length == 0) return null;
            
            return (
                    <div >
                        <div className="ContainerMaxHeightCenter"  onClick={() => handleShowParticipants(tempParticipants, tempTeamParticipants, params.row.category)} style={{cursor:"pointer"}}>
                            <div className="menuDataGridListButton" >
                                  <GroupsIcon className="previewButton" style={{fontSize:'32px'}}/> 
                            </div>
                           <div className="participantCountBadge">
                                {tempParticipants.length + tempTeamParticipants.length}
                            </div>
                        </div>

                  </div>
                   
                );
            }
        }, 
        
    ];      

*/
    
    //function for retrieving and storing ccTransaction data as needed
    async function updateTransactionCache({forceReload}) {

        if (authState != "signedin"  || !currentUser) return;

        if (!transactionStartDateTime) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: NULL TRANSACTION START DATE ");
           return;
        }

        //This check catches changes to the date that are not yet valid dates
        if (!transactionStartDateTime.toISOString()) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: NULL TRANSACTION START DATE STRING");
           return;
        }

        //Set our start of search period
        var tempTransactionStartDate = transactionStartDateTime.clone().startOf('day');

        //Start date out of range, i.e.,in the future
        if (tempTransactionStartDate.clone().isAfter(NOW())) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: TRANSACTION START DATE IN THE FUTURE; SKIPPING");
            return;
        }

 
        //Start date out of range, i.e., too long ago?
        if (tempTransactionStartDate.clone().isBefore(NOW().subtract(1,'years').startOf('day'))) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: TRANSACTION START DATE OUT OF BOUNDS; SETTING TO 1 YEAR AGO");
            tempTransactionStartDate = ONE_YEAR_AGO().startOf('day');
        }
 
         //Have we already fetched at least the requested transactions for this particular customer?
        //Check to see if we have fetched transaction prior to OR equal to the same day, but only if not a forced reload due to a new wallet or auth change
        if (transactionCacheInitialized && !forceReload) {
            if (transactionOldestStartDateTimeFetched.clone().startOf('day').isBefore(tempTransactionStartDate.clone().startOf('day')) ||
                    transactionOldestStartDateTimeFetched.clone().startOf('day').isSame(tempTransactionStartDate.clone().startOf('day'))) {
                if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: ALREADY FETCHED SUFFICIENT TRANSACTIONS ");
                return;
            }
        }

        if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: FETCHING TRANSACTIONS for walletID:", currentUser.ccWalletID);
        if (DEBUG_MODE >= 2) console.log("transactionOldestStartDateTimeFetched=" + transactionOldestStartDateTimeFetched.toISOString() );
        if (DEBUG_MODE >= 2) console.log("transactionStartDateTime=" + tempTransactionStartDate.toISOString() );

        try {
            // gather ccTransaction data from backend
  
            const queryParams = {
                userID: currentUser.id,                                                             //Primary Key
                createdAt: {between: [ tempTransactionStartDate.toISOString(), NOW_STRING() ]},     //Secondary Key
            };
            
            var tempTransactions =  await queryDataTableWithPagination(getCCTransactionsByUserByDate, "getCCTransactionsByUserByDate", queryParams);
            
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE: Fetched transactions for user: " + currentUser.id + " back to " + tempTransactionStartDate.toISOString(), tempTransactions);

            //Remove transactions that are in the future, as in our Demo client
            const pastTranscations = tempTransactions.filter(isPastTransaction);

            //Sort the new set of objects
            pastTranscations.sort(compareTransactionsByDateReverseChronological);
            
            if (DEBUG_MODE >= 2) console.log("Filtered and sorted transactions", tempTransactions, pastTranscations);
            
            //Save the transactions to our cache and update cache params
            if (tempTransactions) {
                setTransactionCache([...pastTranscations]);
                setTransactionCacheInitialized(true); 
                setTransactionCountFilteredDateRange(tempTransactions.length);
                setTransactionOldestStartDateTimeFetched(tempTransactionStartDate); 
            }

        } catch (err) { if (DEBUG_MODE >= 2) console.log('TRANSACTION CACHE: error fetching  data', err); }            
}



    // RESPOND TO ANY CHANGES TO NEW TRANSACTIONS IN OUR SMART CACHE
    
    useEffect(() => {
        updateFilteredTransactions();
    }, [transactionCache, transactionStartDateTime, transactionEndDateTime]);


    async function updateFilteredTransactions() {

        if (authState !== "signedin") return;

        //These checks catch improper dates that may occur WHILE the user is typing
        if (!transactionStartDateTime || !transactionEndDateTime) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE FILTER: NULL TRANSACTION START DATE ");
           return;
        }

        //This check catches changes to the date that are not yet valid dates
        if (!transactionStartDateTime.toISOString() || !transactionStartDateTime.toISOString()) {
            if (DEBUG_MODE >= 2) console.log("TRANSACTION CACHE FILTER: NULL TRANSACTION START DATE STRING");
           return;
        }

        const startOfSearchPeriod = transactionStartDateTime.clone().startOf('day');
        const endOfSearchPeriod = transactionEndDateTime.clone().endOf('day');
        
        if (DEBUG_MODE >= 2) console.log("Search period", startOfSearchPeriod.toISOString(), endOfSearchPeriod.toISOString());
        
        //Filter transactions based on user selections, transaction date  and any category or transaction type filter selected
        var tempccTransactions =  transactionCache.filter(transaction => 
                (moment(transaction.createdAt).isAfter(startOfSearchPeriod)) &&
                (moment(transaction.createdAt).isBefore(endOfSearchPeriod)));

        if (DEBUG_MODE >= 2) console.log("UPDATED Filtered transactions", tempccTransactions);

        setTransactionsFiltered([...tempccTransactions]);
    }
       
       
    // RESPOND TO ANY CHANGES TO THE FILTERED TRANSACTIONS
    
    useEffect(() => {
        updatePageData();
    }, [transactionsFiltered]);

    async function updatePageData() {
       
       if (!transactionsFiltered || transactionsFiltered.length === 0) return;
       
        //Walk transactions and compute current numbers for user's account history
        
        var tempUserNumbers = {...userNumbersInitialState}; //Make a new object
        
        for (var j = 0; j < transactionsFiltered.length; j++){
            if (transactionsFiltered[j].transactionType === "COMPLETED_CONECTIVITY") {
                tempUserNumbers.conectivities +=  1;
                tempUserNumbers.coinsReceived +=  transactionsFiltered[j].amount;
                
                if (transactionsFiltered[j].badgesDEI) tempUserNumbers.badgesDEIReceived +=  transactionsFiltered[j].badgesDEI;
                if (transactionsFiltered[j].badgesCS) tempUserNumbers.badgesCSReceived +=  transactionsFiltered[j].badgesCS;
        
            }
            else if (transactionsFiltered[j].transactionType === "CC_REDEMPTION") {
                tempUserNumbers.purchases +=  1;
                tempUserNumbers.coinsSpent +=  transactionsFiltered[j].amount;
                if (transactionsFiltered[j].badgesDEI) tempUserNumbers.badgesDEISpent +=  transactionsFiltered[j].badgesDEI;
                if (transactionsFiltered[j].badgesCS) tempUserNumbers.badgesCSSpent +=  transactionsFiltered[j].badgesCS;
                if (transactionsFiltered[j].badgesSpotlight) tempUserNumbers.badgesSpotlightsSpent +=  transactionsFiltered[j].badgesSpotlight;
        
            }
           else if (transactionsFiltered[j].transactionType === "GIFT_SENT") {
            //   if (DEBUG_MODE >= 2) console.log("Counting gift sent")
                tempUserNumbers.giftsSent +=  1;
                tempUserNumbers.coinsSpent +=  transactionsFiltered[j].amount;
            }
           else if (transactionsFiltered[j].transactionType === "GIFT_RECEIVED") {
                tempUserNumbers.giftsReceived +=  1;
                tempUserNumbers.coinsReceived +=  transactionsFiltered[j].amount;
            }
           else if (transactionsFiltered[j].transactionType === "SPOTLIGHT_RECEIVED") {
                tempUserNumbers.badgesSpotlightsReceived +=  1;
            }

        }
        
        //if (DEBUG_MODE >= 2) console.log("User Data:", tempUserNumbers)            
        setUserNumbers(tempUserNumbers);


         //Build rows for user transaction Data Grid 
        var tempThisUsersRows = [];
        for(var i = 0; i<transactionsFiltered.length; i++){
                tempThisUsersRows[i] = {...transactionsFiltered[i],
                id:i+1,
                dynamodbId:transactionsFiltered[i].id,
            };
            
            if (transactionsFiltered[i].transactionType === "COMPLETED_CONECTIVITY" || transactionsFiltered[i].transactionType === "REJECTED_CONECTIVITY") {
                tempThisUsersRows[i].image = setConectivityImageUrlByCategory(transactionsFiltered[i].category, true);
            } 
 
 
             //Populate row with an array of users - the user who recorded the transaction and any participants 
            var usersToShow = [];
            if (transactionsFiltered[i].transactionType === "COMPLETED_CONECTIVITY" || transactionsFiltered[i].transactionType === "SPOTLIGHT_RECEIVED" || transactionsFiltered[i].transactionType === "SPOTLIGHT_SENT") {
                const userWhoRecTrans = users.find(user => transactionsFiltered[i].userID == user.id);
                if (userWhoRecTrans) usersToShow.push(userWhoRecTrans);
                if (transactionsFiltered[i].usersThatParticipatedWithMe) {
                    
                    for (var j=0; j<transactionsFiltered[i].usersThatParticipatedWithMe.length; j++) {
                        
                        //Note - only add participants that are different from the user who recorded the transaction, which we already addede above
                        if (!userWhoRecTrans || transactionsFiltered[i].usersThatParticipatedWithMe[j] !== userWhoRecTrans.id ) {
                            const tempParticipant = users.find(participatingUser => participatingUser.id == transactionsFiltered[i].usersThatParticipatedWithMe[j] );
                            if (tempParticipant) usersToShow.push(tempParticipant);
                        }
                    }     
                }
            }

            //Store users
            tempThisUsersRows[i].usersToShow = [...usersToShow];           
        }
        
        //Form groups of rows by date
        if (tempThisUsersRows.length > 0) {
            const groups = tempThisUsersRows.reduce((groups, transactionRow) => {
              const createdAt = (transactionRow.createdAt) || '';
              const date = moment(createdAt).format('MMM DD,YYYY');
    
              if (!groups[date]) {
                groups[date] = [];
              }
              groups[date].push(transactionRow);
              return groups;
            }, {});
            if (DEBUG_MODE >= 2) console.log("Built transaction row GROUPS for user summary:", groups);
   
            // Edit: to add it in the array format instead
            const groupArrays = Object.keys(groups).map(date => {
              return {
                date,
                days: groups[date],
              };
            });
            setTransactionGroups(groupArrays);
            
            if (DEBUG_MODE >= 2) console.log("Built transaction row GROUPS for user summary:", groupArrays);
          }

        if (DEBUG_MODE >= 2) console.log("Built transaction rows for user summary:", tempThisUsersRows);
        setTransactionRowsSummary(tempThisUsersRows);
          
     }

     /*
   // Pop up a modal for viewing members that participated in the Conectivity

    function handleShowParticipants (participants, teamParticipants, category) {
 
        //if (DEBUG_MODE >= 2) console.log("View Participants Button pressed.", participants, teamParticipants, category);    
        var tempParticipants = []; 
        for (var j=0; j<participants?.length; j++) {
            const tempParticipant = users.find(user => user.id == participants[j]);

            if (tempParticipant != undefined) tempParticipants.push(tempParticipant);
        }

        //if (DEBUG_MODE >= 2) console.log("Built Participants list.  Participants:", tempParticipants);    

        setParticipantsToShow(tempParticipants);
        var tempTeamParticipants = [];
        for (var x=0; x<teamParticipants?.length; x++) {
            const tempTeamParticipant = teams.find(team => team.id == teamParticipants[x]);

            //if (DEBUG_MODE >= 2) console.log("Retrieved Team:", tempTeamParticipant, teamParticipants[x]);    
            
            if (tempTeamParticipant != undefined)  tempTeamParticipants.push(tempTeamParticipant);
        }

        //if (DEBUG_MODE >= 2) console.log("Built Team Participants list.  Participants:", tempTeamParticipants, teamParticipants);    
        setTeamParticipantsToShow(tempTeamParticipants);
        setParicipantsHeaderColor(setHeaderColorByCategory(category));
        setShowModalParticipants(true); //pop-up Modal
    }
    
    const handleCancelParticipants = () => {
        
        setShowModalParticipants(false);
        setParticipantsToShow ([]);
        setTeamParticipantsToShow([]);
        
        //if (DEBUG_MODE >= 2) console.log("Cancelled Participants Modal");
    };
*/
	//Component for displaying participants for a previously completed conectivity
	function displayParticipants () {  
		if (participantsToShow.length === 0 && teamParticipantsToShow.length === 0 ) return null;
		return (
             <div> 
 
         {participantsToShow.length === 0 ? "" :
                <div >
                    <div style={{fontSize:"1.3rem", fontWeight:"500", color:COLOR_BLUE_TEXT}} >Participants</div>
                    <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: participantsHeaderColor}} >                   
                        {participantsToShow.map((target, index) => ( 
                            <div key={target.id}  className="avatarContainer"> 
                                <div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>
                                    {!target.avatarUrl  ? <div className="avatarInitials" > {target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target?.firstName.substring(0,1).toUpperCase() + target?.lastName.substring(0,1).toUpperCase()} /> }
                                </div>
                                <span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.firstName + " " + target.lastName)}}>{target.firstName + " " + target.lastName}</span>
                            </div>
    
                        ))}
                      </div>
                  </div>
         }

        {teamParticipantsToShow.length === 0 ? "" :
                <div>
                    <div style={{fontSize:"1.3rem", fontWeight:"500", color:COLOR_BLUE_TEXT, paddingTop:"clamp(8px,2vw,20px)"}} >Team Participants</div>
                      
                    <div className="ContainerNoHeightCenter fullWidth positionRelative wrap conectivityCardAvatarWrapper" style={{color: participantsHeaderColor}} >
                    
                        {teamParticipantsToShow.map((target, index) => ( 
									<div key={target.id} className="avatarContainer"> 
										<div className="avatarImage avatarImageMedium" style={{backgroundColor:setHeaderColorByStringLength(target.name)}}>
											{!target?.avatarUrl  ? <div className="avatarInitials" > {target.name.substring(0,2).toUpperCase()}</div> : <img className="avatarImageCenterPortrait" src={target.avatarUrl} alt={target.name.substring(0,2).toUpperCase()} /> }
										</div>
										<span className="avatarHoverText" style={{background: setHeaderColorByStringLength(target.name)}}>{target.name}</span>
									</div>
    
                        ))}
                      </div>                  
                  </div>
        }
              </div>
            );
    }  

    //Functions for handling gifts
    // Pop up a modal for launching a conectivity for a specific user or team
    const handleGift = (e) => {
        if (e) e.stopPropagation();        //prevent click propagation       
        if (DEBUG_MODE >= 2) console.log("GIFT button pressed");       
        setShowModalGift(true); //pop-up Modal
    };    

    const handleCloseGiftModal = () => {
        setShowModalGift(false);
        // if (DEBUG_MODE >= 2) console.log("Close Gift component called");
    };  
  
  //
  // Page Render
  //

    //Handle access by unauthenticated user
    if (authState !== "signedin"  || !currentUser) return null;
    
    //Render function for authenticated user 
    return (

    <Authenticator>
        <section className="accountHistory" >
 
            {/*<Modal show={showModalParticipants} onHide={handleCancelParticipants}  centered >
                <Modal.Header closeButton>
                  <Modal.Title>YOUR CONNECTIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                      <center> {displayParticipants()}</center>
                </Modal.Body>
             </Modal>
            */}
    
    
            <GiftCoins showModalGift={showModalGift} handleCloseGiftModal={handleCloseGiftModal} />
        
            <div className="ContainerVerticalStart" >            
              <div className="ContainerNoHeightSpaceAround fullWidth maxWidthLarge wrap">     
                        <div className="ContainerNoHeightFlexLeft" style={{zIndex:"99", paddingTop:"clamp(6px,1vw,12px", position:"relative", maxWidth:"24.0rem"}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                        // minDate={NOW()}
                                        // maxdate={SIX_MONTHS_FROM_NOW()}
                                        disabled={false}
                                        label="From"
                                        value={transactionStartDateTime}
                                        onChange={(newDateValue) => {
                                            setTransactionStartDateTime(newDateValue);
                                            if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
                                        }}
                                        // renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                </LocalizationProvider>

                                <div style={{paddingLeft:"clamp(4px,1vw,10px"}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                        // minDate={NOW()}
                                        // maxdate={SIX_MONTHS_FROM_NOW()}
                                        disabled={false}
                                        label="To"
                                        value={transactionEndDateTime}
                                        onChange={(newDateValue) => {
                                            setTransactionEndDateTime(newDateValue);
                                            if (DEBUG_MODE >= 2) console.log("New launch date/time:", newDateValue.toISOString());
                                        }}
                                        // renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                </LocalizationProvider>
                            </div>
                        </div>
 
                    <div className="ContainerNoHeightSpaceAround  wrap">            
                        <div className="ContainerVertical" style={{padding:"clamp(4px,1vw,10px"}}>
                            <div className="TextStyle4 headerBlue" style={{paddingBottom:"clamp(2px,0.5vw,5px"}}> Conectivities </div>
                            <div className="dashboardScoreCircle white-border" style={{borderColor:SOCIAL_COLOR}}>
                                    <div className="TextStyle4 headerBlue"> {userNumbers.conectivities}  </div>
                            </div>
                        </div>            
                        <div className="ContainerVertical" style={{padding:"clamp(4px,1vw,10px"}}>
                            <div className="TextStyle4 headerBlue"style={{paddingBottom:"clamp(2px,0.5vw,5px"}}> Purchases </div>
                            <div className="dashboardScoreCircle white-border" style={{borderColor:TEAM_COLOR}}>
                                <div className="TextStyle4 headerBlue"> {userNumbers.purchases}  </div>
                            </div>
                        </div>
                    </div>
      
                    <div className="ContainerNoHeightSpaceAround  wrap">            
                        <div className="ContainerVertical" style={{padding:"clamp(4px,1vw,10px"}}>
                            <div className="TextStyle4 headerBlue" style={{paddingBottom:"clamp(2px,0.5vw,5px"}}> Total Awards</div>
                            <div className="dashboardScoreRectangle-Root" >
                                {displayAwards({fontSize:"med", paddingBetweenAwards:"5px", imageHeight:"24px", coins:userNumbers.coinsReceived, badgesDEI:userNumbers.badgesDEIReceived, badgesCS:userNumbers.badgesCSReceived, badgesSpotlight:userNumbers.badgesSpotlightsReceived, showZeros:true})}
                            </div>
                        </div>
                        <div className="ContainerVertical" >
                            <div className="TextStyle4 headerBlue" style={{paddingBottom:"clamp(2px,0.5vw,5px"}}> Total Debits </div>
                            <div className="dashboardScoreRectangle-Root" >
                                {displayAwards({fontSize:"med",  paddingBetweenAwards:"5px",imageHeight:"24px", coins:userNumbers.coinsSpent, badgesDEI:userNumbers.badgesDEISpent, badgesCS:userNumbers.badgesCSSpent, badgesSpotlight:userNumbers.badgesSpotlightsSpent, showZeros:true})}  
                            </div>
                        </div>
                    </div>
                    
                    {isMobileViewPort ? null : <GiftButton onClickCallback={handleGift} hoverText="Want to recognize a colleague's contributions? Know someone that could use a bit of help?  Send a gift of coins!!"  /> }

                </div>
    
    
                <div className="dashboardContainer ">
                    <div className="dashboardTitle">
                        ACCOUNT ACTIVITY
                     </div>   
    
                    <div className="dataGridWrapperTop" >        

                     {true || isMobileViewPort ?       
                            <TransactionHistory transactionGroups={transactionGroups} />
                        :
                        <MenuDataGrid 
                            dataGridRows={transactionRowsSummary} 
                            dataGridColumns={userSummaryColumns} 
                            // width={1400}
                        />
                        }

                    </div>
                </div>
            </div>
            
        </section>
        
    </Authenticator>
  );
};




export default UserAccountHistoryPage;
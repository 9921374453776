//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, SLACK_CONSENT_URL   
} from '../data/conectereConfigData';

//React & Amplify
import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';

//GraphQL
import { fetchSlackChannels, slackEnable } from '../graphql/mutations';

//Utils
import { v4 as uuidv4 } from 'uuid';    //Needed for making a URL;
// const crypto = require('crypto');

export async function buildSlackChannelOptions(customerID) {

    console.log("Fetching Slack channels");
    
    let tempChannels = [];

   //Fetch Conversations from Slack
     const enqueueCommandInput = {
         customerID:customerID,
         command: 'FETCH_SLACK_PUBLIC_CHANNELS',
     };
         
     //Call GraphQL to directly invoke our Lambda function 
     if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to Fetch Slack Channels - ", enqueueCommandInput);
     const response = await invokeAPI(fetchSlackChannels, 'fetchSlackChannels', enqueueCommandInput);
     if (DEBUG_MODE >= 2) console.log("Fetch Slack Channels returned", response);        

     //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
     if (response && response.lambdaReturnStatus) {
             const returnParams = JSON.parse(response.returnParams);
             if (returnParams && returnParams.ok && returnParams.channels && returnParams.channels.length > 0) {
               tempChannels = [...returnParams.channels];
               for(var l = 0; l<tempChannels.length; l++){
               
                  tempChannels[l] = {
                     ...tempChannels[l],
                     label:"#" + tempChannels[l].name,
                     value:tempChannels[l].id,
                  };
               }                  
               if (DEBUG_MODE >= 2) console.log("Extracted fetched channels", tempChannels);
               
               //Set the selected option
             } else {
                if (DEBUG_MODE >= 1) console.log("ERROR - Slack returned error when fetching channels", returnParams);
             }
      }
      
      return tempChannels;
} 

export function displaySlackAccessControlButton({currentUser, isAdmin, clickCallback}) {
     
     if (!currentUser || !currentUser.customer || !clickCallback) return null;
     
     //Button always enabled; probably adjust this with an ADMIN setting for enabling Slack integration
     const disabled = !isAdmin;

     return(
          <div className={`calendarAccessControlCard ContainerNoHeightCenter ${(disabled) && "calendarAccessControlCardDisabled"}`} onClick={event => clickCallback()}> 
              <div className="ContainerNoHeightCenter"  style={{minHeight:"70px", width:"50px", backgroundColor:"white"}}>
                  <img  style={{height:"30px"}} src={CONECTERE_CONFIG_DATA.SLACK_LOGO} alt='Slack'/>
              </div>
              <div style={{padding:"0.5vh 1vh", flex:1}}>
                   {isAdmin ? <div > {!currentUser.customer.configDetails.slackEnabled ? "Enable Slack" : "Disable Slack" } </div>
                    : <div > {currentUser.customer.configDetails.slackEnabled ? "Slack Integration Enabled" : "Slack Integration Disabled" } </div> }
              </div>
          
              {/* currentUser.customer.configDetails.slackEnabled ? 
                  <div className="ContainerNoHeightCenter" style={{backgroundColor:"white", borderRadius:"50%",  position:"absolute", top:"0", right:"0", transform: "translate(50%,-65%)"}}>
                      <CheckCircleIcon style={{color:COLOR_GOOD, fontSize:"3.0vh"}}/> 
                  </div>                                
              : null */}
         </div>

     );
 }

export async function sendSlackAuthCodeToLambda({customerID, authorizationCode, authorizationStateData}) {

     try {
        var params = {
              authCode: authorizationCode,
              authState: authorizationStateData,
        };
        
        const enqueueCommandInput = {
            customerID:customerID,
            command: 'ENABLE_SLACK',
            params: JSON.stringify(params),
        };
            
        //Call GraphQL to directly invoke our Lambda function 
        if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to Enable Slack - ", enqueueCommandInput);
        const response = await invokeAPI(slackEnable, 'slackEnable', enqueueCommandInput);
        if (DEBUG_MODE >= 2) console.log("Slack Integration Lambda returned", response);        

        //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
        if (response) 
            return { 
                lambdaReturnStatus:response.lambdaReturnStatus,
                returnParams: JSON.parse(response.returnParams),
            }; 
        else return {lambdaReturnStatus: 'FAILED'};
        
         
     } catch(err) {
         if (DEBUG_MODE >= 1) console.error("Disable Slack Error", err);
         return {lambdaReturnStatus: 'FAILED'};
     }
 } 
 
export async function disableSlack({customerID}) {

     try {
        var params = {};
        
        const enqueueCommandInput = {
            customerID:customerID,
            command: 'DISABLE_SLACK',
            params: JSON.stringify(params),
        };
            
        //Call GraphQL to directly invoke our Lambda function 
        if (DEBUG_MODE >= 2) console.log("Directly Invoking Lambda to Disable Slack - ", enqueueCommandInput);
        const response = await invokeAPI(slackEnable, 'slackEnable', enqueueCommandInput);
        if (DEBUG_MODE >= 2) console.log("Slack Integration Lambda returned", response);        

        //Extract results from our returnObject; Our params is a stringified JSON, so lets unpackage it
        if (response) 
            return { 
                lambdaReturnStatus:response.lambdaReturnStatus,
                returnParams: JSON.parse(response.returnParams),
            }; 
        else return {lambdaReturnStatus: 'FAILED'};
        
         
     } catch(err) {
         if (DEBUG_MODE >= 1) console.error("Disable Slack Error", err);
         return {lambdaReturnStatus: 'FAILED'};
     }
 } 
 
export async function getSlackAuthCode() {

     try {
         //SLACK requires dynamic state data in the redirect URI to prevent spoofing
         // https://auth0.com/docs/secure/attack-protection/state-parameters
 
         //Generate and store a dynamically, randomly generated value to our local session state
         //We will verify this on the client side when this client is redirected back with an auth code
         const ourSecureCode = 'SLACK_'+uuidv4();
         if (DEBUG_MODE >= 2) console.log("Generated our dynamic, secure code and stored to browser code", ourSecureCode);
         window.localStorage.setItem("oauth_state", ourSecureCode);
         const tempState = '&state=' + ourSecureCode;                //Importantly, pass the secure code as a state param seperate from the other params that are already part of the consent URL
         if (DEBUG_MODE >= 1) console.log("Consent URL State data:",tempState);
         const tempURL = SLACK_CONSENT_URL  + tempState;
         if (DEBUG_MODE >= 1) console.log("Redirecting to Slack consent URL: ",tempURL);
         
         //For Slack, redirect the user to the Slack consent page
         window.location.href = tempURL;          

     } catch (err) {
         if (DEBUG_MODE >= 1) console.error("Enable Slack Error", err);
     }
}
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023
//
//  Resources:
//  https://blog.webdevsimplified.com/2022-07/react-router/#:~:text=Another%20incredibly%20powerful%20thing%20you,components%20or%20as%20nested%20Routes%20.
//

/**************************************************** 
                ACCOUNT APP v2
*****************************************************/

//Config data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE,  } from '../../shared/data/conectereConfigData';

//Styles
import '../../shared/styles/App.css';
import '../../shared/styles/conectivities.css';
import '../../shared/styles/spotlights.css';
import '../../shared/styles/calendar.css';
import '../../shared/styles/Mui.css';
import '../../shared/styles/transactions.css';
import '../../shared/styles/challenge.css';
import '../../shared/styles/launchRules.css';
import '../../shared/styles/leaderboards.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//React & Amplify
import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from 'aws-amplify';

//Our Context Providers
import { DisplayProvider } from '../../shared/context/displayContext';                 //User Authentication Context
import { AuthenticatorProvider } from '../../shared/context/authContext';              //User Authentication Context
import { ModalProvider } from '../../shared/context/modalContext';                      //Modals Context
import { CustomerProvider } from '../../shared/context/customerContext';               //Customer Context such as Users and Teams
import { NotificationsProvider } from '../../shared/context/notificationsContext';     //Notifications Context
import { SpecialEventsProvider } from '../../shared/context/specialEventsContext';     //Special Events Context
import { SpotlightProvider } from '../../shared/context/spotlightContext';             //Spotlights Context
import { LeaderboardProvider } from '../../shared/context/leaderboardContext';         //Leaderboard Context
import { AnalyticsProvider } from '../../shared/context/analyticsContext';             //Analytics context
import { GraphAnalyticsProvider } from '../../shared/context/graphAnalyticsContext';   //Graph Analytics context
import { ConectivitiesProvider } from '../../shared/context/conectivitiesContext';     //Conectivities, LaunchRules, Scheduled Conectivities context
import { CalendarsProvider } from '../../shared/context/calendarContext';              //Integration support for 3rd party calendars, such as Google Calendar
import { ProductsProvider } from '../../shared/context/productsContext';               //Products context
import { OrdersProvider } from '../../shared/context/ordersContext';                   //Orders context
import { InvoicesProvider } from '../../shared/context/invoicesContext';               //Invoices context
import { GiphyCacheProvider } from '../../shared/context/giphyCacheContext';            //Giphy Cache context
import { MsftTeamsProvider } from '../../shared/context/msftTeamsContext';            	//TEAMS SDK context

// Employee Pages
// import HomePage from "./Home";
import UserHomePage from "./UserHome";
import UserLogin from "./userLogin";
import UserAccountHistoryPage from "./userAccountHistory";
import EmployeeStorePage from "./employeeStore";
import EmployeeLeaderboardsPage from "./employeeLeaderboards";
// import EmployeeSpotlights from "./employeeSpotlights";
import EmployeeSpotlightFeed from "./employeeSpotlightFeed";
import UserConectivitySchedule from "./userConectivitySchedule";
import UserAccountSettings from "./userAccountSettings";
import ConectereCommunity from "./conectereCommunity";
import Launchpad from "./launchpad";
import DashboardSpend from "./dashboardSpend";
import DashboardCoulson from "./dashboardCoulson";

// Admin Pages
import AdminCustomerSettings from "./adminCustomerSettings";
import AdminUsersPage from "./adminUsers";
import AdminMentorSponsorPage from "./adminMentorSponsorPrograms";
import AdminNewEmployeeSupport from "./adminNewEmployeeSupport";
import AdminChallenges from "./adminChallenges";
import AdminConnectionBuilder from "./adminConnectionBuilder";
import AdminSlackIntegration from "./adminSlackIntegration";
import AdminMsftTeamsIntegration from "./adminMsftTeamsIntegration";
import AdminEmployeeImport from "./adminEmployeeImport";
// import AdminUserSummary from "./adminUserSummary";
import AdminConectivitiesPage from "./adminConectivities";
import AdminEmployeeStorePage from "./adminEmployeeStore";
import AdminOrdersPage from "./adminOrders";
import AdminSpotlightsPage from "./adminSpotlights";
import AdminTeamsPage from "./adminTeams";
import AdminTeamsConectivity from "./adminTeamsConectivity";
import AdminAnalytics from "./adminAnalytics";
import AdminAnalyticsRecalc from "./adminAnalyticsRecalc";
import AdminGraphConectivityVisualizer from "./adminGraphConectivityVisualizer";
import AdminGraphTimeSeriesConectivity from "./adminConectivityGraphTimeSeriesAnimation";
import AdminGraphConectivityByTime from "./adminGraphConectivityByTime";
import AdminGraphConectivityByEmployee from "./adminGraphConectivityByEmployee";
import ReportEmployeesByConectivityPage from "./adminReportEmployeesByConectivity";
import ReportTeamsByConectivityPage from "./adminReportTeamsByConectivity";
import ReportProductsByFavoritePage from "./adminReportProductsByFavorite";
import ReportConectivitiesByFavoritePage from "./adminReportConectivitiesByFavorite";
import ReportTransactionHistory from "./adminReportEnterpriseHistory";

// Components
// import PageWrapper from "./Components/pageWrapper/pageWrapper";
import PageLayout from './Components/PageLayout/pageLayout';		//v2 DEV
import UserDashboard from './UserDashboard';	//v2 DEV

/***** Configure Amplify *******/

/* V6 - Amplify.configure() will now accept either the config JSON file or a strongly typed configuration object. 
		Therefore, if you need to add additional configuration, you will call configure twice: once with the contents of amplifyconfiguration.json, 
		and then again using Amplify.getConfig() plus any additions. 
		Keep in mind that any call to configuration will fully override previous configurations, 
		so pay special attention to nested configurations.
*/

import amplifyconfig from './amplifyconfiguration.json'; //Amplify V6
if (DEBUG_MODE >= 1) console.log("Amplify initial config input", amplifyconfig);


// Added these two lines to dynamically set the redirects based on the window location so it changes between DEV and MASTER
// This was needed to handle redirects from OAUTH2 providers, such as Microsoft.  Otherwise the redirects threw a security error 
// they are not properly configured yet in our Amplify exports package and we need to support two different environemnts anyway
// For setting the redirects, See https://watilde.medium.com/how-to-support-multiple-redirect-urls-of-auth-with-amplify-7bffe5798047
const redirectSignInUrls=`${window.location.origin}/userHome`;
const redirectSignOutUrls=`${window.location.origin}/userLogin`;

const updatedConfig = {
	...amplifyconfig, // Preserve other Amplify services (API, Storage, etc.)
	  oauth: {
		 ...amplifyconfig.oauth, // Preserve existing OAuth settings
		 redirectSignIn:redirectSignInUrls,
		 redirectSignOut:redirectSignOutUrls,
	 }
 };

if (DEBUG_MODE >= 1) console.log("Updated Amplify redirectSignInUrls to", redirectSignInUrls);
if (DEBUG_MODE >= 1) console.log("Updated Amplify redirects to",redirectSignOutUrls);
if (DEBUG_MODE >= 1) console.log("Configuring Amplify with updated config", updatedConfig);
if (DEBUG_MODE) console.log("Process ENV", process.env);
if (DEBUG_MODE) console.log("IS_MANAGMENT_PORTAL = ", process.env.IS_MANAGMENT_PORTAL);

Amplify.configure(updatedConfig);

const App = () => {
	
	return (

		<Router>
 
			{/* PLATFORM CONTEXT */}
			<DisplayProvider >
			<ModalProvider >
			<AuthenticatorProvider >
			<CustomerProvider >
			<CalendarsProvider >
			<MsftTeamsProvider >
			<GiphyCacheProvider >
			<ConectivitiesProvider >
			<NotificationsProvider >
			<SpecialEventsProvider >

			{/* <InvitationProvider > */}
			<LeaderboardProvider>
			<SpotlightProvider>
			<ProductsProvider>
			<OrdersProvider>
			<InvoicesProvider>
			<AnalyticsProvider >
			<GraphAnalyticsProvider> {/* NETWORK GRAPH ANALYTICS (VOLUMINOUS)  - selectivitly fetched by a callback tickle*/ }

					{/* Outer switch for SignUpWizard or the full platform */ }
						
					<Routes>
						{/* Outer wrapper for all pages; 
								note this is an open Route tag to start nested routes
								https://dev.to/tywenk/how-to-use-nested-routes-in-react-router-6-4jhd

							*/}
						<Route element={<PageLayout />} >   {/* Outer wrapper for all pages; */}

								{/* UNAUTHENTICATED USER */}
								<Route path="userLogin" element={<UserLogin />} />
									
								{/* USER PAGES */}             
								<Route path="UserHome" element={<UserDashboard />} />
								<Route path="EmployeeStore" element={ <EmployeeStorePage />} />
								<Route path="EmployeeLeaderboards" element={<EmployeeLeaderboardsPage />} />
								<Route path="employeeSpotlightFeed" element={ <EmployeeSpotlightFeed />} />
								<Route path="userAccountHistory" element={<UserAccountHistoryPage />} />
								<Route path="launchpad" element={<Launchpad />} />
								<Route path="userConectivitySchedule" element={<UserConectivitySchedule />} />
								<Route path="userAccountSettings" element={<UserAccountSettings />} />    


								{/* ADMIN PAGES */}     
								<Route path="adminusers" element={<AdminUsersPage />} />
								<Route path="adminMentorSponsorPrograms" element={<AdminMentorSponsorPage />} /> 
								<Route path="adminChallenges" element={<AdminChallenges />} />
								<Route path="adminNewEmployeeSupport" element={<AdminNewEmployeeSupport />} />
								<Route path="adminConnectionBuilder" element={<AdminConnectionBuilder/>} />
								<Route path="adminSlackIntegration" element={<AdminSlackIntegration />} />    
								<Route path="adminMsftTeamsIntegration" element={<AdminMsftTeamsIntegration />} />             
								<Route path="adminCustomerSettings" element={  <AdminCustomerSettings />} />
								<Route path="adminConectivities" element={  <AdminConectivitiesPage />} />
								<Route path="conectereCommunity" element={  <ConectereCommunity />} />
								<Route path="adminSpotlights" element={  <AdminSpotlightsPage />} />
								<Route path="adminConectivityGraphTimeSeriesAnimation" element={<AdminGraphTimeSeriesConectivity />} />
								<Route path="adminGraphConectivityVisualizer" element={  <AdminGraphConectivityVisualizer />} />
								<Route path="adminEmployeeStore" element={  <AdminEmployeeStorePage />} />
								<Route path="adminOrders" element={  <AdminOrdersPage />} />
								<Route path="adminAnalytics" element={  <AdminAnalytics />} />
								<Route path="adminAnalyticsRecalc" element={  <AdminAnalyticsRecalc />} />                                      
								<Route path="dashboardCoulson" element={  <DashboardCoulson />} />
								<Route path="dashboardSpend" element={  <DashboardSpend />} />                                   
								<Route path="adminGraphConectivityByEmployee" element={<AdminGraphConectivityByEmployee />} />
								<Route path="adminGraphConectivityByTime" element={<AdminGraphConectivityByTime />} />  
								<Route path="adminteams" element={<AdminTeamsPage />} />
								<Route path="adminTeamsConectivity" element={<AdminTeamsConectivity />} />  
								<Route path="adminEmployeeImport" element={<AdminEmployeeImport />} /> 

														
								{/* REPORT PAGES */} 
								<Route path="adminReportEmployeesByConectivity" element={ <ReportEmployeesByConectivityPage />} />   
								<Route path="adminReportTeamsByConectivity" element={ <ReportTeamsByConectivityPage />} />  
								<Route path="adminReportProductsByFavorite" element={ <ReportProductsByFavoritePage />} />  
								<Route path="adminReportConectivitiesByFavorite" element={ <ReportConectivitiesByFavoritePage />} />  
								<Route path="adminReportEnterpriseHistory" element={ <ReportTransactionHistory />} /> 
															

							{/* default redirect to home page */}
							<Route path="*" element={<Navigate to="/UserHome" />} />

						</Route>
					</Routes>
				
				</GraphAnalyticsProvider> 
				</AnalyticsProvider>
				</InvoicesProvider>
				</OrdersProvider>
				</ProductsProvider>                     
				</SpotlightProvider>
				</LeaderboardProvider>
				{/* </InvitationProvider > */ }
				</SpecialEventsProvider >
				</NotificationsProvider>  
				</ConectivitiesProvider >
				</GiphyCacheProvider> 
				</MsftTeamsProvider >
				</CalendarsProvider> 
				</CustomerProvider >
				</AuthenticatorProvider>
				</ModalProvider>
			</DisplayProvider > 
		</Router>   

	);
};

export default App;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

import "./conectivityGraph.css";

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_BLUE_TEXT} from '../../../../shared/data/conectereConfigData';

import React,{ useEffect, useState, useContext, useRef } from 'react';

import { v4 as uuidv4 } from 'uuid';    

//CONTEXT
import { AnalyticsContext } from '../../../../shared/context/analyticsContext';                // Analytics Context
import { DisplayContext } from '../../../../shared/context/displayContext';            //User Authentication Context

//Utils

//Graphing packages

// import Graph from "react-graph-vis";

//Added the import for vis network in order to properly load its CSS and thus get the hover tooltip
// "This is happening because you might not be importing the vis-network CSS. You can import the standalone version of vis-network and it will automatically import the CSS.
//Note, importing "standalone" seems to allow the navigation buttons to show

import { Network } from 'vis-network/standalone';


// A React component to display beautiful network graphs using vis.js
// This component takes three vis.js configuration objects as properties:
// graph: contains two arrays { edges, nodes }
// options: normal vis.js options as described here
// events: an object that has event name as keys and their callback as values

// Tons of examples:https://visjs.github.io/vis-network/examples/

// https://github.com/crubier/react-graph-vis#readme
// https://visjs.github.io/vis-network/docs/network/
// Edge options: https://visjs.github.io/vis-network/docs/network/edges.html#
// General documentation: https://visjs.github.io/vis-network/docs/network/ 

// Example of using images within nodes? 
// https://visjs.github.io/vis-network/examples/static/jsfiddle.ab49bcc64369b1b6b915d82970ef2eddb8b21d43d6a68ac89bde718713a906f4.html
// shape: "image",
// image: DIR + "3.png",
// label: "imagePadding{2,10,8,20}+size",
// imagePadding: { left: 2, top: 10, right: 8, bottom: 20 },
// size: 40,
// color: {
//   border: "green",
//   background: "yellow",
//   highlight: { border: "yellow", background: "green" },
//   hover: { border: "orange", background: "grey" },
// },
//
// Here are more example node options:
// https://visjs.github.io/vis-network/examples/static/jsfiddle.27e34b71b5fb6b9faab281ce50b3ae64e608298c292273c5915fb3a5d6b9740c.html
		// {
		//   id: 11,
		//   font: { size: 30 },
		//   size: 40,
		//   label: "circularImage",
		//   shape: "circularImage",
		//   image: "../img/indonesia/4.png",
		//   shapeProperties: { borderDashes: [15, 5] },
		// },
// Even more options.  Note, you can set the shape and the size in the NODES options but presumably only if all the same
// https://codepen.io/manz/pen/RpxvWB
// nodes: {
//       borderWidth:0,
//       size:66,
//       color: {
//         border: '#222',
//         background: 'transparent'
//       },
//       font: {
//         color: '#111',
//         face: 'Walter Turncoat',
//         size: 16,
//         strokeWidth: 1,
//         strokeColor: '#222'
//       }
//     },

// these are all options in full with their default settings
// var options = {
//   interaction:{
//     dragNodes:true,
//     dragView: true,
//     hideEdgesOnDrag: false,
//     hideEdgesOnZoom: false,
//     hideNodesOnDrag: false,
//     hover: false,
//     hoverConnectedEdges: true,
//     keyboard: {
//       enabled: false,
//       speed: {x: 10, y: 10, zoom: 0.02},
//       bindToWindow: true,
//       autoFocus: true,
//     },
//     multiselect: false,
//     navigationButtons: false,
//     selectable: true,
//     selectConnectedEdges: true,
//     tooltipDelay: 300,
//     zoomSpeed: 1,
//     zoomView: true
//   }
// }


//Bootstrap and other React components

import Slider from '@mui/material/Slider';          //Note documentation: https://mui.com/api/slider/ 

	
//Our React component
const ConectivityGraph = ({userToGraph, hoverCallBack, doubleClickNodeCallback, doubleClickEdgeCallback, stabilizedCallBack }) => {

	//Context
	const {  setShowSpinner } = useContext(DisplayContext); 

		//vars for multi-select for controlling graph UI
		// const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);
		// const [selectedTeamsOptionsToEdit, setSelectedTeamsOptionsToEdit] = useState([]);
		// const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);  //Note, this stores an array of objects of the select Options.  See react-select

		const[radioByCategory, setRadioByCategory] = useState("ALL");
		const[timeframeRadioValue, setTimeframeRadioValue] = useState("1W");
		const [showAvatars, setShowAvatars] = useState(true); //Toggle switch for whether or not to display employee images in the graph nodes

		const [sliderValue, setSliderValue] = useState(0);
		const [sliderValueBootStrap, setSliderValueBootStrap] = useState(0);
		
		// const [graphWidth, setGraphWidth] = useState("1200px");         //Default initial size of the network graph
		// const [graphHeight, setGraphHeight] = useState("800px");        //Default initial size of the network graph


		//Local graph data for this component
		const [currentGraphData, setCurrentGraphData] = useState(null);    //Holds the particular graph that should be displayed
		// const [filteredGraphData, setFilteredGraphData] = useState(null);   //Holds the particular graph but futher filtered by any parameters, such as specific users and team.  
		
		// const[networkReference,setNetworkReference]= useState(null);
		let networkReference = useRef(null);

		//
		//Analytics context which provides all of the graph data for the current company
		//
		
		const {
			analyticsGenerated, graphPeriod,
			networkGraphGraphDataDay,   networkGraphGraphDataWeek,  networkGraphGraphDataMonth,  networkGraphGraphDataQuarter, networkGraphGraphDataYear, 
			networkGraphGraphDataOneWeek, networkGraphGraphDataOneMonth, networkGraphGraphDataTwoMonths, networkGraphGraphDataEntirePeriod,
		} = useContext(AnalyticsContext);
		
			//In react-Graphviz, 'Events' is an object that has graphvis event names as keys and their callback as values
			// select: ({ nodes, edges }) => {
			//   if (DEBUG_MODE >= 2) console.log("Selected nodes:");
			//   if (DEBUG_MODE >= 2) console.log(nodes);
			//   if (DEBUG_MODE >= 2) console.log("Selected edges:");
			//   if (DEBUG_MODE >= 2) console.log(edges);
			//   alert("Selected node: " + nodes);
			// },
			// doubleClick: ({ pointer: { canvas } }) => {
			//   createNode(canvas.x, canvas.y);
			// }
			
			// Select or Click events are fired when the user clicks the mouse or taps on a touchscreen device. Passes an object with properties structured as:
			// {
			//   nodes: [Array of selected nodeIds],
			//   edges: [Array of selected edgeIds],
			//   event: [Object] original click event,
			//   pointer: {
			//     DOM: {x:pointer_x, y:pointer_y},
			//     canvas: {x:canvas_x, y:canvas_y}
			//   }
			// }


//
//Graph Default Options
//
	const conectivityGraphOptionsInitialState = {
			 physics:{
				enabled: true,
				// solver:"repulsion",   //Select the particular physics engine to use.  
															// Possible options: 'barnesHut', 'repulsion', 'hierarchicalRepulsion', 'forceAtlas2Based'
															// barnesHut is the default
				stabilization: {
					iterations:250,       //Default is 1000
					},
				minVelocity:0.2,        //Default is 0.1.  Once the minimum velocity is reached for all nodes, we assume the network has been stabilized and the simulation stops
				barnesHut: {            //Options for this resolver
					theta: 0.5,
					gravitationalConstant: -2000,
					centralGravity: 0.3,
					springLength: 200,      //Changed this from 95 to 200
					springConstant: 0.04,   //Changed from 0.04 to 0.02.  This is how 'sturdy' the springs are. Higher values mean stronger springs.
					damping: 0.1,       // Accepted range: [0 .. 1]. The damping factor is how much of the velocity from the previous physics simulation iteration carries over to the next iteration.
					avoidOverlap: 0.1,  //Changed from 0 to 0.1.  Above this seems to overload the engine.  
															//Accepted range: [0 .. 1]. 
															//When larger than 0, the size of the node is taken into account. 
															//The distance will be calculated from the radius of the encompassing circle of the node for both the gravity model. Value 1 is maximum overlap avoidance.
				},
			},
			interaction: {
				hover:true,
				navigationButtons:true,
			},
			autoResize: true,
			// clickToUse: true,    //Set to true to prevent zooming but requires a click to do anything
			height: '100%',
			width: '100%',
			// clickToUse: true,
			// width: graphWidth,
			// height: graphHeight,  
			layout: {
				hierarchical: false
			},
			// arrowStrikethrough: false,
			edges: {
				// length: 200,
				color: "#000000",
				font: { 
					align: "horizontal",
					color:COLOR_BLUE_TEXT,
					background:"white",
					
				},
				arrows: {
					to: {
						enabled: false,     //Conectivities should be bi-directional so disabling arrow heads 7.16.2022
						type: "arrow"
						},
					from: {
						enabled: false,
						type: "arrow"
						}
					},
				scaling: {            //Scale width of edge based on value
					min: 1,
					max: 3,
					label: {
							enabled: true,
					},
				},
				smooth: {               //This option introduces rounded edges
					enabled: true,
					type: "dynamic",
					roundness: 0.5,
					forceDirection: 'none',  
					},
			},
			// manipulation: {
			//   enabled: true,
			// },
			
			//So, this is a cool options that, when enabled, shows ALL the options in a UI element below the graph and
			//allows you to play with them.  Awesome for manipulating the graph and deciding what options to use
			// configure: {
			//   enabled: true,
			//   filter: 'nodes,edges',
			//   showButton: true,
			// }
		};

	
	
	//variables to store dynamic lists of options for the Graph component
	const [optionsData, setOptionsData] = useState(conectivityGraphOptionsInitialState);  
	
	// Event structure used in react-graph-vis; not used anymore           
	// const events = {
		
	//   select: function(event) {
	//     var { nodes, edges } = event;
	//   }, 
	//   doubleClick: handleNodeDoubleClick,
	//   //hoverNode: handleHoverNode,
	// };


	//
	// Functions to handle UI selections
	//
/*
		// On page load, tell our graph analytics component to fetch
		useEffect(() => {
				if (DEBUG_MODE >= 2) console.log("Tickling graph context");
				setFetchRecordsTrigger(!fetchRecordsTrigger); //Toggle the state on page load causing the graph analytics to check whether to load the context
		}, []);    
		
		// On change of underlying graph data from Analytics Context
		useEffect(() => {
				setInitialUserSelection();
				// setSliderValue(filteredGraphData.maxEdgeCount);
		}, [graphDataBuiltFlag]);


		
		//This function is called on page rendering and sets any pre-selected user as passed via a prop, if any
		function setInitialUserSelection() {
 
			//Graphs yet?     
			if (!graphDataBuiltFlag) return;
			
			if (DEBUG_MODE >= 2) console.log("User selection", userToGraph);
			
 
			//Set default graph
			const startingGraph = masterGraphData.find(graph => graph.period === "1W" && graph.category== "ALL");
			
			if (startingGraph != undefined) {
				
				setCurrentGraphData(startingGraph); 
				
				} else if (DEBUG_MODE >= 2) console.log("Error - no graph found");
		 

			var initialSelection = [];
			var index = -1;


			//Now, set USER selection, if any
			if (userToGraph==undefined) {
						// if (DEBUG_MODE >= 2) console.log("No pre-selected USER");
						
						//Default to no user
						setSelectedUsersOptions ([]);

						//Default to ALL EMPLOYEES for teams
						initialSelection = [];
						index=teamsOptions.findIndex((team) => team.name === "ALL EMPLOYEES");
		
						//Push matching user option as the pre-selected user
						if (index >-1) {
								initialSelection.push(teamsOptions[index]);
						}            
						
						setSelectedTeamsOptions (initialSelection);

						
			} else {
				
					// if (DEBUG_MODE >= 2) console.log("Pre-selecting user", userToGraph, usersOptions);

					initialSelection = [];
					
					//Find the particular user being graphed
					index=usersOptions.findIndex((user) => user.id === userToGraph.userID);
	
					//Push matching user option as the pre-selected user
					if (index >-1) {
							initialSelection.push(usersOptions[index]);
					}
					
					// if (DEBUG_MODE >= 2) console.log("Pre-selecting user", userToGraph, usersOptions, initialSelection);
	
					setSelectedUsersOptions (initialSelection);

			}
		}

		// On any change to current graph or selected user or team options, regen filtered graph data that is to be displayed 
		useEffect(() => {
				updateGraphData();

		}, [currentGraphData, selectedUsersOptions, selectedTeamsOptions]);
		
	 function updateGraphData (){
		 
		 if (currentGraphData === null || currentGraphData === undefined) return;


			//New Graph Data Structure
			const tempFilteredGraphData =  {
				nodes: [], 
				edges: [], 
				maxEdgeCount: currentGraphData.maxEdgeCount,
			};

			// if (DEBUG_MODE >= 2) console.log("Filtering newGraphData", currentGraphData, selectedUsersOptions, selectedTeamsOptions);

			//Process selections of individual USERS
			//Filter edges to only include edges that are sourced by nodes corresponding to the selected users
			tempFilteredGraphData.edges = currentGraphData.edges.filter(edge => (selectedUsersOptions.some(option => option.id === thisEdgeFromUserID(edge))));
		 
		 //Now, filter nodes to inlude nodes that are selected as source node(s) in the DropDown OR that are pointed to by one of the edges filtered above
			tempFilteredGraphData.nodes = currentGraphData.nodes.filter(node => (selectedUsersOptions.some(option => option.id === node.userID) || tempFilteredGraphData.edges.some(edge => edge.to === node.id)));
			
			// if (DEBUG_MODE >= 2) console.log("Regenerated Filtered graph data", tempFilteredGraphData);
			
			//Process each selected team
			for (var j=0; j < selectedTeamsOptions.length; j++) {
					
					const team = teams.find(team => team.id === selectedTeamsOptions[j].id);
					
					if (team != undefined) {

						// console.log ("Filtering graph to include users on team", team);
						
						//Process each user on the selected team to include in the newly filtered graph data
						
						//Every edge where the team has a user that matches the source node for the edge && the edge has not already been included in the new graph per above
						var newEdgesToAdd = currentGraphData.edges.filter(edge => (team.users.items.some(join => join.userID === thisEdgeFromUserID(edge)) &&  tempFilteredGraphData.edges.some(existingEdge => existingEdge.id === edge.id) === false));
						
						//Identify additional nodes to add for the new edges; include ALL users on the selected team if the user is not already in the node set due to being selected individually
						var newNodesToAdd = currentGraphData.nodes.filter(node => (team.users.items.some(join => join.userID === node.userID) && tempFilteredGraphData.nodes.some(existingNode => existingNode.id === node.id) === false));
						
						if (DEBUG_MODE >= 2) console.log("For selected teams, adding edges and nodes", newEdgesToAdd, newNodesToAdd);
						
						tempFilteredGraphData.edges = [...tempFilteredGraphData.edges, ...newEdgesToAdd];
						tempFilteredGraphData.nodes = [...tempFilteredGraphData.nodes, ...newNodesToAdd];
 
						// if (DEBUG_MODE >= 2) console.log("For selected teams, final updated graph data", tempFilteredGraphData);
					 
					}
			}
			 
			
			//Now, update our state variables     
			setFilteredGraphData (tempFilteredGraphData);
			setSliderValue(currentGraphData.maxEdgeCount);

	 }


	//This function return the userID associated with the NODE that sourced this EDGE
	function thisEdgeFromUserID (edge){
	
			 const fromNode = currentGraphData.nodes.find (node => node.id === edge.from);
			 
			 if (fromNode != undefined) return fromNode.userID;
			 else return 0;
		
	}
*/

/*  
	//Brute force function that updates the Options state data to change edge color and font color
	//Probably easier to set up a dictionary or array, but ....
	function setNetworkGraph (radioByCategory, timeframeRadioValue){
		
		if (DEBUG_MODE >= 2) console.log("Setting new graph", radioByCategory, timeframeRadioValue);

		var tempOptionsData = {...optionsData};

		//Swap in the correct pre-built graph data set and update options based on radio value
 
		if (radioByCategory === 'ALL' ) {
			tempOptionsData.edges.color = '#000000';
			tempOptionsData.edges.font = '14px arial #000000';
		} else if (radioByCategory === 'BALANCE' ) {
			tempOptionsData.edges.color = '#5DADE2';
			tempOptionsData.edges.font = '14px arial #5DADE2';
		} else if (radioByCategory === 'SOCIAL' ) {
			tempOptionsData.edges.color = '#82E0AA';
			tempOptionsData.edges.font = '14px arial #82E0AA';
		} else if (radioByCategory === 'TEAM' ) {
			tempOptionsData.edges.color = '#BB8FCE';
			tempOptionsData.edges.font = '14px arial #BB8FCE';
		} else if (radioByCategory === 'PERSONAL' ) {
			tempOptionsData.edges.color = '#F7DC6F';
			tempOptionsData.edges.font = '14px arial #F7DC6F';
		} else if (radioByCategory === 'DEI' ) {
			tempOptionsData.edges.color = '#f203ff';
			tempOptionsData.edges.font = '14px arial #f203ff';
		}

		setOptionsData(tempOptionsData);        //Update Edge Color Options
		
		//Now, find the graph within the master set of graphs received from the Analytics Context
		
		const newGraph = masterGraphData.find(graph => graph.period === timeframeRadioValue && graph.category== radioByCategory);

		if (newGraph != undefined) setCurrentGraphData(newGraph); else if (DEBUG_MODE >= 2) console.log("Error - no graph found", masterGraphData, timeframeRadioValue, radioByCategory);

}
	 
*/

	function handleStabilized (event) {
		
		// if (DEBUG_MODE >= 2) console.log("Network Stabilized", event);

			setShowSpinner(false); //Hide spinners


	}
	
		function handleAfterDrawing (event) {
		// if (DEBUG_MODE >= 2) console.log("Network Drawn", event);
			setShowSpinner(false); //Hide spinners
			ourNetwork.current.stopSimulation(); //Shut down simulation at this point
	}

	function handleZoom (event)  {

		// if (DEBUG_MODE >= 2) console.log("Zoom event", event);
		
	}
	
	function handleHoverNode (event) {
		// if (DEBUG_MODE >= 2) console.log("Hover on node", event);
		if (hoverCallBack) hoverCallBack(event.node);
	}
 
	//Handler for Double Click Events on Graph 
	function handleDoubleClick (event) {
		
		if (DEBUG_MODE >= 2) console.log("Double click handler invoked", event);
		
		
		//Was the double click on a node?  If so, invoke the NODE callback
		if (event.nodes.length === 1 && doubleClickNodeCallback) {
			
			//Based on the event, which is a node ID in our current graph data, find the corresponding USER ID 
			const clickedNode = currentGraphData.nodes.find (node => node.id === event.nodes[0]);
			var returnUserID = null;
			if (clickedNode !== undefined) returnUserID = clickedNode.userID;

			//Invoke callback and pass back a userID, which can be handled by the parent
			
			doubleClickNodeCallback(returnUserID);
		} 
		//Else, was the double click on a link?
		else if (event.edges.length === 1 && doubleClickEdgeCallback) { 
 
			 //Based on the event, get the edge
			const clickedEdge = currentGraphData.edges.find (edge => edge.id === event.edges[0]);
			
			if (clickedEdge) {
				const fromNode = currentGraphData.nodes.find (node => node.id === clickedEdge.from);
				const toNode = currentGraphData.nodes.find (node => node.id === clickedEdge.to);
			 
	
				//Invoke callback and pass back a userID, which can be handled by the parent
				
				doubleClickEdgeCallback([fromNode.userID, toNode.userID]);
			}
		}
	}



	//React Component for Slider for Edge Clustering
	//Receives parent props of edge count, parent slider state variables & function
	const GraphLegend = ({maxEdgeCount, sliderValue, setSliderValue}) =>  {
		
		const [localSliderValue, setLocalSliderValue] = useState(sliderValue);  //Initialize to current parent slider value
		
		
		// On any change to max edge count or slidervalue, initialize our local data since this means a new graph was selected
		useEffect(() => {
				setLocalSliderValue(sliderValue);

		}, [maxEdgeCount, sliderValue]);
		
		// const [sliderValueX, setSliderValueX] = useState(0);  //Local slider value

		// if (graphDataBuiltFlag === false  || maxEdgeCount <= 0) return (null);

		if (DEBUG_MODE >= 2) console.log("Displaying legend", maxEdgeCount, sliderValue, localSliderValue);

		const q1 = (!maxEdgeCount ? 0 : Math.floor (maxEdgeCount / 4));
		const q2 = (!maxEdgeCount ? 0 : Math.floor (maxEdgeCount / 2));
		const q3 = (!maxEdgeCount ? 0 : Math.floor (3 * maxEdgeCount / 4));
		
		var step = Math.floor (maxEdgeCount / 4);
		var includeMarks = false;
		
		if (step === 0) step = 1;
		if (step > 1) includeMarks = true;
		
	
		//Using local handler to prevent re-rendering of parent and, thus, allow smooth scrollin

		const updateLocalSliderValue = (e, data) => {

				if (DEBUG_MODE >= 2) console.log("EVENT", e);
				
				setLocalSliderValue(data);
				
		};
		
		const updateSliderValue = (e, data) => {

				// if (DEBUG_MODE >= 2) console.log("EVENT", e);
				
				//Store locally AND update parent since this is a mouse UP event
				setLocalSliderValue(data);
				setSliderValue(data);
				
		};
		
		return (

			<div className="NetworkClusterControlWrapper">  
			 <div className="NetworkClusterControlTitle">
						CLUSTER BY CONNECTIONS 
				</div>

				<div className="NetworkGraphSlider">
						<Slider 
							disabled={maxEdgeCount <= 0}
							defaultValue={100} 
							// step={step} 
							// marks={true} 
							min={0} 
							max={maxEdgeCount}
							name="clusterSlider"
							onChange={updateLocalSliderValue}
							onChangeCommitted={updateSliderValue}
							value={localSliderValue}
							valueLabelDisplay="auto"
						/>          

				</div>
				
				<div  className="ContainerNoHeightCenter fullWidth" style={{paddingBottom:"10px"}}>
					<div className="ContainerVerticalLeft" style={{flex:"1"}}>
						<div className="NetworkGraphLegendScaleQ1">
								<span> </span>
						</div>
						<div className="NetworkGraphLegendLabel">
								0
						</div>
					</div>
					<div className="ContainerVerticalLeft" style={{flex:"1"}}>
						<div className="NetworkGraphLegendScaleQ2" >
								<span> </span>
						</div>
						<div className="NetworkGraphLegendLabel">
								{q1}
						</div>
					</div>
					<div className="ContainerVerticalLeft" style={{flex:"1"}}>
						<div className="NetworkGraphLegendScaleQ3" >
								<span> </span>
						</div>
						<div className="NetworkGraphLegendLabel">
								{q2}
						</div>
					</div>
					<div className="ContainerVerticalLeft" style={{flex:"1"}}>
						<div className="NetworkGraphLegendScaleQ4">
								<span> </span>
						</div>
						<div className="NetworkGraphLegendLabel">
								{q3} </div>
					</div>
					<div className="ContainerVerticalLeft" style={{flex:"1", maxWidth:"20px"}}>
						<div className="NetworkGraphLegendScaleQ5" >
								<span> </span>
						</div>
						<div className="NetworkGraphLegendLabel">
								{maxEdgeCount}
						</div>
					</div>

				</div>
			</div>
			);  
	};


	function clusterGraph(network) {
		
		if (DEBUG_MODE >= 2) console.log("Clustering graph based on new slider setting", sliderValue);
		
		var clusterOptions = {
			joinCondition: function (parentNode, childNode) {
				
				// if (DEBUG_MODE >= 2) console.log("checking node for clustering", sliderValue, parentNode, childNode);

				//Add to cluster if both parent and child have more than ZERO connections.   This does not clusters outliers that have ZERO connections.
				if (parentNode.edgeCount > sliderValue && childNode.edgeCount > sliderValue) {
					
					// if (DEBUG_MODE >= 2) console.log("clustering nodes:", sliderValue, parentNode, childNode);
					return true;      //Cluster these neighboring nodes as the both exceed the threshold
					
				} else {
					return false;      //Do not cluster
				}
			},
 
			//Our options to style the resulting cluster
			clusterNodeProperties: {
				id: "",
				borderWidth: 3,
				shape: "hexagon",
				color: '#92ed61', 
				title: "Cluster", 
				clusterEdgeCount:0,
				clusterNodeCount: 0,
				label:"",
				size:40,
				font: { 
					align: "center" ,
					color: '#40662b', 
					size: 20,
					vadjust: -60
				}
			},
 
			//Our function to set the custer options just prior to the individual cluster being formed
			//It receives a pointer to the options, an array of childNodes and an array of childEdges
			
			processProperties: function (clusterOptions, childNodes, childEdges) {

					//Give the cluster a unique ID
					clusterOptions.id = uuidv4();
					
					//Set node count and label
					clusterOptions.clusterNodeCount = childNodes.length;
					clusterOptions.label = "+" + childNodes.length;
					
					var totalEdgeCount = 0;
					for (var i = 0; i < childNodes.length; i++) {
						totalEdgeCount += childNodes[i].edgeCount;
					}
					
					clusterOptions.clusterEdgeCount = totalEdgeCount;
					
					//Set size of node and vertical alignment to move the label onto the node
					//Default node size is 25
					//Adjust label vertically so it is in the middle of the node.  Move and offset plus the node size
					var nodeSizeCalc = 25 + childNodes.length*5;   //Calc node size as default node size (25) plus a growth function
					if (nodeSizeCalc > 100) nodeSizeCalc = 100;
					clusterOptions.size = nodeSizeCalc;                   
					clusterOptions.font.vadjust = (-1 * clusterOptions.size) - 22;
					
					if (DEBUG_MODE >= 2) console.log("Setting cluster options", clusterOptions);
					
					return clusterOptions;
				},
			
		};
		
		network.clusterByHubsize(2,clusterOptions);  //Review any node with 2 or more edge for clustering
		
		// setFilteredGraphData (filteredGraphData);

	}
	
		//NOT USED - react-graph-vis callback
		// function callbackForGraph (network) {
			
		//   //Note, only store on an updated pointer otherwise we trigger infinite page renders
		//   if (networkReference== null) {
		//       if (DEBUG_MODE >= 2) console.log("Graph Network Callback", network);
		//       setNetworkReference(network);
		//   }
		// }
		
	
	function handleAvatarSwitch (event) {
		
		setShowAvatars(!showAvatars);
		
		if (DEBUG_MODE >= 2) console.log("Switching Avatar display to:", !showAvatars);
		
		//Update node shape based on settings
		
		//NOTE, WE ARE MAKING A NEW ARRAY SO AS TO CAUSE REACT TO RE-RENDER; IF WE POINT AT ORIGINAL THEN REACT THINKS THERE ARE NO CHANGES
		const tempNewGraphData =  {
			nodes: currentGraphData.nodes, 
			edges: currentGraphData.edges, 
			maxEdgeCount: currentGraphData.maxEdgeCount,
		};

		

		//Now, update node shape based on new setting
		for (var i = 0; i < currentGraphData.nodes.length; i++) {
			//Recall our boolean state data has not yet changed
			if (!showAvatars) {
				//Change only those thave have an avatar        
				if (tempNewGraphData.nodes[i].image !="") {
					tempNewGraphData.nodes[i].shape = 'circularImage';
					tempNewGraphData.nodes[i].borderWidth = 10;
					tempNewGraphData.nodes[i].label="";
				}

			//Change all to ellipses without an avatar
			} else {
				
				tempNewGraphData.nodes[i].shape = 'ellipse';
				tempNewGraphData.nodes[i].label = tempNewGraphData.nodes[i].userInitials;   //Grab our cached version of the initials
				tempNewGraphData.nodes[i].borderWidth = 1;

			}
		}      
		
		if (DEBUG_MODE >= 2) console.log("Modified  graph data based on avatar setting", tempNewGraphData);
		setCurrentGraphData (tempNewGraphData);
		
		
	}  

function compareByID(a, b) {

		// if (DEBUG_MODE >= 2) console.log("sorting connectivities:", a.closingDateTime, b.closingDateTime);   
		
		if (a.id > b.id) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b);   
				return 1;
				}
		if (b.id > a.id) {
				// if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b);   
				return -1;
		}
		
		if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b) ;  
		return 0;

}

	 //New code for directly manipulating network
	const visJsRef = useRef(null);
	const ourNetwork = useRef(null); //Accessible outside the useEffect

	useEffect(() => {
		
			if (currentGraphData) {

				setShowSpinner(true); //Show spinners
	
				if (DEBUG_MODE >= 2) console.log("Generating new network", currentGraphData, optionsData);
				
				const network = visJsRef.current && new Network(visJsRef.current, currentGraphData, optionsData);
	 
				if (network !== null) {
					
					if (DEBUG_MODE >= 2) console.log("Generated new network", currentGraphData, optionsData);
					
					ourNetwork.current = network;
	
					//set up event handlers for the new network
					network.on("doubleClick", handleDoubleClick);
					network.on("hoverNode", handleHoverNode);
					network.on("zoom", handleZoom);
					network.on("stabilized", handleStabilized);     //Fired when the network has stabilized
					network.on("afterDrawing", handleAfterDrawing); //Fired once the canvas has been drawn, which may be well before stabilization
					
					
					//Adjust graph data to implement cluster based on current slider value
					clusterGraph(network);    
					
				} else {
					if (DEBUG_MODE >= 2) console.log("Null network", currentGraphData, optionsData);
				}
			} else {
				if (DEBUG_MODE >= 2) console.log("Null graph data", currentGraphData, optionsData);
			}
 
			setShowSpinner(false); //Show spinners
			
				
	}, [visJsRef, currentGraphData, sliderValue]);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//              Time Animation Component - WORK IN PROGRESS
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// const networkAnimationContainerRef = useRef(null);  
	// const networkAnimationRef = useRef(null);

	// const [filteredTransactions, setFilteredTransaction] = useState([]);
	// const [startWindow, setStartWindow] = useState(0);
	// const [endWindow,setEndWindow]=useState(1000);
	// const [animationGraphData, setAnimationGraphData]  = useState({nodes: [], edges: [], maxEdgeCount: 0});
	// const [animationSequencePointer, setAnimationSequencePointer]  = useState(0);
	// const [animationGraphRenderTrigger, setAnimationGraphRenderTrigger] = useState(false);
	// const [animationGraphID, setAnimationGraphID] = useState (uuidv4());

// 	const animationNetwork = new Network(networkAnimationRef.current, animationGraphData, optionsData);

	// const animationNodes = useRef(null);
	// const animationEdges = useRef(null);
	
	// animationNodes.current = new Network.DataSet([{}]);
	// animationEdges.current = new Network.DataSet([{}]);
	
// 	const network = networkAnimationRef.current && new Network(networkAnimationRef.current, animationGraphData, optionsData);


	// useEffect(() => {
			
	// 		// const network = networkAnimationRef.current && new Network(networkAnimationRef.current, animationGraphData, optionsData);

	// 		if (!animationGraphRenderTrigger) {
	 			 
	// 		   networkAnimationRef.current = new Network(networkAnimationContainerRef.current, animationGraphData, optionsData);
	// 		   setAnimationGraphRenderTrigger(false);
	 			 
	// 		}

	// 		// networkAnimationRef.current.redraw();
	// 		setAnimationGraphID(uuidv4());  //re-render element
			
	// 		// network.current.redraw();
			
	// 		if (DEBUG_MODE >= 2) console.log("Created a NEW network reference");
				
	// }, [networkAnimationContainerRef, animationGraphData]);
	
	
	//Filter transaction to those that are completed conectivities with multiple participants
		// function testTransactionForMultipleParticipants(transaction) {
		//   if (!transaction.usersThatParticipatedWithMe) return false;
		//   if (transaction.usersThatParticipatedWithMe === null) return false;
		//   if (transaction.usersThatParticipatedWithMe.length ==0) return false;
		//   return true;
		// }
	
	//   useEffect(() => {
			
	//   if (analyticsGenerated) {
	//     const tempTransactions = ccTransactions.filter(transaction => (testTransactionForMultipleParticipants(transaction)));
			 
	//     setFilteredTransaction(tempTransactions);
			
	//     if (DEBUG_MODE >= 2) console.log("Filtered transactions", tempTransactions);
	//   }      

	
	// }, [analyticsGenerated]);
		
	// //React Component for time series animation
	// const TimeAnimation = ({startWindow, endWindow, width, height}) => {
		
		
	//   return (
	//     <div className="TimeAnimationControlWrapper" style={{height:height, width:width}} >  
	//       {fromUser + "->" + toUsers}
	//     </div>   
			
	//     );
		
	// };
	
	// function addAnimationNode(user) {
		
	//   //First, check to see whether our animation graph already has this node.  
	//   if (animationGraphData.nodes.some((node) => node.id === user.id)) return;
	 
	//   //make an initial object for the user
	//     const tempNode  = 
	//     { id: user.id,    
	//       userID: user.id,
	//       customerID: user.customerID,
	//       userInitials:getUserInitials(user),  //Store for later use
	//       label: getUserInitials(user), 
	//       color: '#f26df0', 
	//       edgeCount: 0,
	//       //Set title which is used for hover
	//       title: user.firstName + (!user.middleName ? "" : " " + user.middleName.substr(0,1)) + " " + user.lastName, 
	//       shape:"ellipse",        //Default 
	//       borderWidth:1,
	//       image:"",
				
	//     };
			
	//     const tempAnimationGraphData = {...animationGraphData}; //Shallow copy the object so we force a React render
	//     tempAnimationGraphData.nodes.push(JSON.parse(JSON.stringify(tempNode)));
	//     setAnimationGraphData(tempAnimationGraphData);
			
	//     if (DEBUG_MODE >= 2) console.log("Adding node to animation graph", tempNode, tempAnimationGraphData);
			
	// }

	// function addAnimationEdge(transactionToDisplay){
		
		
		
	// }
	
	// const [fromUser, setFromUser] = useState("");
	// const [toUsers, setToUsers] = useState("");
	
	// function updateAnimationGraph(sequencePointer) {
		
	//   const transactionToDisplay = filteredTransactions[sequencePointer];
		
	//   //Find the particular user associated with this transaction
	//   var index=users.findIndex((user) => user.id === transactionToDisplay.userID);
		
	//   var tempToUsers = ""; 
		
	//   //Push matching user option as the pre-selected user
	//     if (index >-1) {
	//         addAnimationNode(users[index]);
	//         setFromUser(users[index].lastName);
	//     }
			
	//   //Add edges for this transaction
	//   for (var y=0; y<transactionToDisplay.usersThatParticipatedWithMe.length; y++) {
		 
	//     if (DEBUG_MODE >= 2) console.log('Adding edge from ', transactionToDisplay.userID, " to ", transactionToDisplay.usersThatParticipatedWithMe[y]);                   

			
	//     const tempAnimationGraphData = {...animationGraphData}; //Shallow copy the object so we force a React render
	//     addEdge(transactionToDisplay, transactionToDisplay.userID, transactionToDisplay.usersThatParticipatedWithMe[y], tempAnimationGraphData);
	//     setAnimationGraphData(tempAnimationGraphData);
			
	//     var index=users.findIndex((user) => user.id === transactionToDisplay.usersThatParticipatedWithMe[y]);
	//     if (index >-1) {
	//           tempToUsers = tempToUsers + " " + users[index].lastName;
	//       }
			 
				
	//     }
	
	//   setToUsers(tempToUsers);

	// }

	// Animation timer
		// useEffect(() => {
		//   const timer = setTimeout(() => {
				
		// //update state of graph
		 
		// var newAnimationSequencePointer = animationSequencePointer +1;
		// if (newAnimationSequencePointer > filteredTransactions.length) newAnimationSequencePointer = 0;
		 
		// updateAnimationGraph(newAnimationSequencePointer);
		 
		// setAnimationSequencePointer(newAnimationSequencePointer);
				
		//   }, 1000); //Fire in 1 second
		// // Clear timeout if the component is unmounted
		// return () => clearTimeout(timer);
		// });
		
	
	//
	
	
/*  
	//React component for resizing the canvas size in which the graph is displayed
	const GraphCanvasSizeControl = ({graphWidth, graphHeight, setGraphWidth, setGraphHeight}) => {
		
		//Convert CSS strings to INTs for sliders
		var calculatedCanvasWidth = parseInt(graphWidth.replace("px",""));
		var calculatedCanvasHeight = parseInt(graphHeight.replace("px",""));
		// if (DEBUG_MODE >= 2) console.log("Graph canvas size control called", calculatedCanvasWidth, calculatedCanvasHeight);
	 
		//Handlers and state for sliders; local with this React component
		const [localXSliderValue, setLocalXSliderValue] = useState(calculatedCanvasWidth);  
		const [localYSliderValue, setLocalYSliderValue] = useState(calculatedCanvasHeight);  
		// const [XSliderValue, setXSliderValue] = useState(graphWidth);  
		// const [YSliderValue, setYSliderValue] = useState(graphHeight);  
		
		
		const updateLocalXSizeSliderValue = (e, data) => {

				// if (DEBUG_MODE >= 2) console.log("EVENT", e);
				
				setLocalXSliderValue(data);
				
		};
		
		const updateXSizeSliderValue = (e, data) => {

				// if (DEBUG_MODE >= 2) console.log("EVENT", e);
				
				//Store locally AND update parent since this is a mouse UP event
				setLocalXSliderValue(data);
				
				setGraphWidth(data.toString() + 'px');
				if (DEBUG_MODE >= 2) console.log("Set graph window width:", data.toString() + 'px');
		};

		const updateLocalYSizeSliderValue = (e, data) => {

				// if (DEBUG_MODE >= 2) console.log("EVENT", e);
				
				setLocalYSliderValue(data);
		};
		
		const updateYSizeSliderValue = (e, data) => {

			// if (DEBUG_MODE >= 2) console.log("EVENT", e);
			
			//Store locally AND update parent since this is a mouse UP event
			setLocalYSliderValue(data);
			setGraphHeight(data.toString() + 'px');
			if (DEBUG_MODE >= 2) console.log("Set graph window height:", data.toString() + 'px');
		};  
 
		
		
		return (
				<div className="NetworkCanvasSizeWrapper">
						<div className="NetworkCanvasSizeTitle">
								Canvas Size
						</div>
						<div className="ContainerNoHeightFlexEnd">
							 <div className="NetworkCanvasSizeSliderY">
									<Slider 
										defaultValue={1200} 
										// step={step} 
										// marks={true} 
										min={800} 
										max={1600}
										name="XSizeSlider"
										onChange={updateLocalYSizeSliderValue}
										onChangeCommitted={updateYSizeSliderValue}
										value={localYSliderValue}
										valueLabelDisplay="auto"
										size="small"
										orientation="vertical"
									/> 
							</div>   
						<div className="NetworkCanvasSizeSliderX">
									<Slider 
										defaultValue={1200} 
										// step={step} 
										// marks={true} 
										min={1200} 
										max={2400}
										name="XSizeSlider"
										onChange={updateLocalXSizeSliderValue}
										onChangeCommitted={updateXSizeSliderValue}
										value={localXSliderValue}
										valueLabelDisplay="auto"
										size="small"
									/>          
							</div>
						</div>
					</div>
			);
	};

*/

//
// Handlers
//


		 // Handle change graphs to display; changes to period will cause these to be regenerated 
		useEffect(() => {
				handleGraphSelection();
		}, [analyticsGenerated, networkGraphGraphDataDay, networkGraphGraphDataWeek, networkGraphGraphDataOneWeek, networkGraphGraphDataMonth, networkGraphGraphDataOneMonth, networkGraphGraphDataQuarter, networkGraphGraphDataYear]);

		function handleGraphSelection () {  

			if (!analyticsGenerated) return;
			
			if (DEBUG_MODE >= 2) console.log("Handling graph period selection or change to underlying graphs");
 
		
			 //Update current graph data; reset slider value to the max value edge count for the new graph
			 switch(graphPeriod) {    
				case 'DAY':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to DAY", networkGraphGraphDataDay);
						setCurrentGraphData(networkGraphGraphDataDay);
						setSliderValue(networkGraphGraphDataDay.maxEdgeCount);
						break;
				case 'WEEK':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to WEEK", networkGraphGraphDataWeek);
						setCurrentGraphData(networkGraphGraphDataWeek);
						setSliderValue(networkGraphGraphDataWeek.maxEdgeCount);
						break;
				case '1W':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to 1W", networkGraphGraphDataOneWeek);
						setCurrentGraphData(networkGraphGraphDataOneWeek);
						 setSliderValue(networkGraphGraphDataOneWeek.maxEdgeCount);
					 break;
				case 'MONTH':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to MONTH", networkGraphGraphDataMonth);
						setCurrentGraphData(networkGraphGraphDataMonth);
						 setSliderValue(networkGraphGraphDataMonth.maxEdgeCount);
					 break;    
				case '1M':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to 1M", networkGraphGraphDataOneMonth);
						setCurrentGraphData(networkGraphGraphDataOneMonth);
						setSliderValue(networkGraphGraphDataOneMonth.maxEdgeCount);
						break;
				case '2M':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to 2M", networkGraphGraphDataTwoMonths);
						setCurrentGraphData(networkGraphGraphDataTwoMonths);
						setSliderValue(networkGraphGraphDataTwoMonths.maxEdgeCount);
						break;
				case 'QUARTER':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to QUARTER", networkGraphGraphDataQuarter);
						setCurrentGraphData(networkGraphGraphDataQuarter);
						setSliderValue(networkGraphGraphDataQuarter.maxEdgeCount);
						break;
				case 'YEAR':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to YEAR", networkGraphGraphDataYear);
						setCurrentGraphData(networkGraphGraphDataYear);
						setSliderValue(networkGraphGraphDataYear.maxEdgeCount);
						break;
				case 'ALL':
						if (DEBUG_MODE >= 2) console.log("Set current graph data to ENTIRE PERIOD", networkGraphGraphDataEntirePeriod);
						setCurrentGraphData(networkGraphGraphDataEntirePeriod);
						setSliderValue(networkGraphGraphDataEntirePeriod.maxEdgeCount);
						break;
				default:
						setCurrentGraphData(networkGraphGraphDataDay);
						setSliderValue(networkGraphGraphDataDay.maxEdgeCount);
						break;
				}
		}

//
// Track component height so we can render the graph dynamically as the window resizes
//

	 
		const [componentWidth, setComponentWidth] = useState(0);     //Initial values which will be overwritten on mount
		const [componentHeight, setComponentHeight] = useState(0);

 
 
			//On initial mount, set component size; all of this is to allow the user to control the canvas size of the network graph
			useEffect(() => {

				setComponentSize();

			});


		 //Listener for dynamic resizing of the window; update canvas in response
			useEffect(() => {
 
				window.addEventListener('resize', setComponentSize);
		
				return () => {
					window.removeEventListener('resize', setComponentSize);
				};
			}, []);

		function setComponentSize() {
				 const {innerWidth, innerHeight} = window; //Get current size of viewport
				// if (DEBUG_MODE >= 2) console.log("Current viewport", innerWidth, innerHeight);
					
				 var tempComponentWidth = Math.floor(innerWidth*0.7);
				//  if (tempComponentWidth < 1200) tempComponentWidth = 1200;
				 setComponentWidth(tempComponentWidth);

				 var tempComponentHeight = Math.floor(innerHeight*0.8)-150;
				//  if (tempComponentHeight < 1200) tempComponentHeight = 1200;
				 setComponentHeight(tempComponentHeight);
				 
		}
		
	if (!analyticsGenerated || currentGraphData === null) {
		
		return (
							<center><h3 style={{color:"#6495ED"}}> CONECTIVITY EXPLORER </h3> </center>

			);

	} else {
		
	
		if (currentGraphData) console.log("Current Graph Data", currentGraphData);
		
		return (		
			<div style={{height:"100%", width:"100%"}}>
				{userToGraph  ? "" :
				<div className="homeContainer3" >
					{/* <GraphCanvasSizeControl graphWidth={graphWidth} graphHeight={graphHeight} /> */}
					<GraphLegend maxEdgeCount={currentGraphData.maxEdgeCount} sliderValue={sliderValue} setSliderValue={setSliderValue}/>
				</div>
				}
				<div className="conectivityChart"   style={{paddingTop:"0px", height:(componentHeight), width:"100%"}} ref={visJsRef}></div>					 
			 </div>       
		);
	}

};

export {ConectivityGraph};



						// {graphDataBuiltFlag==false ? null : 
						//     <center>
			
						//         <div className="conectivityChart" style={{height:graphHeight, width:graphWidth}}  ref={visJsRef}></div>
										
						//         <div className="conectivityChart" style={{height:graphHeight, width:graphWidth}}  ref={networkAnimationRef}></div>
										 
						//         <TimeAnimation startWindow={startWindow} endWindow={endWindow} width={graphWidth} height="200px"/>
							
						//     </center>

						// }     



//OLD react-graph-vis component call
//             {graphDataBuiltFlag==false ? null : 
//                 <center>
//                   <div className="conectivityChart" style={{height:graphHeight, width:graphWidth}}>
//                         <Graph
//                             graph={filteredGraphData}
//                             options={optionsData}
//                             events={events}
//                             key={uuidv4()} 
														
//                             //  get access to vis.js network api you can set the state in a parent component using this property
//                             getNetwork={network => (networkReference.current = network)}
//                           />
//                   </div>
//                 </center>

//             }




 
/*  
		//User has elected to view specific employees (nodes) as source
		const handleSelectUser = (eventKey) => {

			if (DEBUG_MODE >= 2) console.log("User selection updated", eventKey);
			
			setSliderValue(currentGraphData.maxEdgeCount); //Reset the Slider value to FULL


		//   const tempFilteredGraphData =  {
		//     nodes: [], 
		//     edges: [], 
		//     maxEdgeCount: currentGraphData.maxEdgeCount,
		//   };

		//   if (DEBUG_MODE >= 2) console.log("Filtering current graph data set by users", currentGraphData);

 
		//   //Filter edges to only include edges that are sourced by the selected node(s)
		//   tempFilteredGraphData.edges = currentGraphData.edges.filter(edge => (eventKey.length===0 || eventKey.some(option => option.id === edge.from)));
		 
		// //Now, filter nodes to inlude nodes that are selected as source node(s) in the DropDown OR that are pointed to by one of the edges filtered above
		//   tempFilteredGraphData.nodes = currentGraphData.nodes.filter(node => (eventKey.length===0 ||eventKey.some(option => option.id === node.userID) || tempFilteredGraphData.edges.some(edge => edge.to === node.userID)));
			
		//   if (DEBUG_MODE >= 2) console.log("Filtered graph data based on selection of users", tempFilteredGraphData);
		//   setFilteredGraphData (tempFilteredGraphData);
				 
			setSelectedUsersOptions(eventKey); // This triggers a UseEffect to regen graph data

		 };

		//User has elected to view specific teams
		const handleSelectTeam = (eventKey) => {

			if (DEBUG_MODE >= 2) console.log("Team selection updated", eventKey);
			
			setSelectedTeamsOptions(eventKey); // This triggers a UseEffect to regen graph data

		};
*/
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  copyright - 2020, 2021
//


import "./launchRow.css";

//Config data
import  { CONECTERE_CONFIG_DATA, TEAM_COLOR, DEBUG_MODE, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, RATING_STATS_INITIAL_STATE, GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER, QUICK_FILTERS_FOR_CONTECTIVITIES, DAYS_FOR_RECENTLY_ADDED_CONECTIVITIES } from '../../../../shared/data/conectereConfigData';

//React & Amplify components
import React, { useEffect, useState, useContext, useRef, useCallback  } from 'react';

//Utils
import {  setHeaderColorByCategory, setConectivityImageUrlByCategory,  } from "../../../../shared/utils/conectivityUtils";
import { LaunchIcon, truncateStringWithDots } from "../../../../shared/utils/generalUtils";

//Our Components
import StarRatings from "../../../../shared/Components/starRatings/starRatings";


//
//Conectivity Card Preview
//
const LaunchCardConectivityPreview = React.memo(({conectivityToPreview, isLargeCard, showPreviewCallback, launchCallback, disableLaunchButton, disableHover}) => {
	
	const [showHoverContent, setShowHoverContent] = useState(false);

	if (!conectivityToPreview) return null;
	
	const displayColored = false;
	
	// if (DEBUG_MODE >= 2) console.log("Previewing Conectivity Card", conectivityToPreview);
	
	const headerColor = (displayColored ? "white" : setHeaderColorByCategory(conectivityToPreview.category.label));
	// const fontColor = setHeaderColorByCategory(conectivityToPreview.category.label);
	// const titleFontColor = (displayColored ? setHeaderColorByCategory(conectivityToPreview.category.label) : "white" );
	// const isTutorial = (conectivityToPreview.category.label == "TUTORIAL");

	const handleMouseEnter = (e) => {
		// if (DEBUG_MODE >= 2) console.log("Mouse enter",e);
		if (!disableLaunchButton) setShowHoverContent(true);
	};
	
	const handleMouseLeave = (e) => {
		// if (DEBUG_MODE >= 2) console.log("Mouse leave",e);
		setShowHoverContent(false);
	};        
	
	const handleCardClick = (e) => {
		if (DEBUG_MODE >= 2) console.log("Card CLICK",e);
		if (e) e.stopPropagation();
		if (showPreviewCallback) showPreviewCallback(conectivityToPreview); //inform parent that we were clicked on
	};

	const handleLaunchLocal = (e) => {
		if (DEBUG_MODE >= 2) console.log("Launch CLICK",e);
		if (e) e.stopPropagation();
		if (launchCallback) launchCallback(conectivityToPreview);            
	} ;      
	
	  return (
	<div className={`conectivityCardOuterWrapper conectivityCardScale ${disableHover ? "noPointer" : "pointer" }`}>
		<div 
			className={`conectivityCardRoot ContainerVerticalSpaceBetween positionRelative ${!isLargeCard ? "conectivityCardSmall" : ""}`} 
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleCardClick}
		>
				<div className="ContainerNoHeightCenter fullWidth conectivityCardHeaderWrapper" style={{backgroundColor: headerColor}} >
				  <div className="conectivityCardHeaderImageWrapper" >
						<img src={setConectivityImageUrlByCategory(conectivityToPreview.category.label)} className={isLargeCard ? "conectivityCardHeaderImageLarge" : "conectivityCardHeaderImage"}  alt=''/>
					</div>
					<div className="conectivityCardHeaderTextWrapper" >
						<div className={isLargeCard ? "TextStyle4_3 white" : "TextStyle4 white"} > {conectivityToPreview.title} </div>
					</div>
				</div>
			
				<div className={`  ${isLargeCard ? "conectivityCardDescriptionWrapper" : "conectivityCardDescriptionWrapperSmallFlex"} `} >
					   <div className = {isLargeCard ? "TextStyle3 black-text" : "TextStyle2 black-text"}>{truncateStringWithDots(conectivityToPreview.description, CONECTERE_CONFIG_DATA.CONECTIVITY_DESCRIPTION_MAX_CHARS)}</div>
				</div>
				
			   <div className={`ContainerNoHeightSpaceAround fullWidth positionRelative wrap conectivityCardActionsWrapper ${!isLargeCard ? "conectivityCardActionsWrapperSmall" : ""} `} style={{color:headerColor}}>
						{conectivityToPreview.ratingStatsID == null ? "" :
							<div  >
								   <StarRatings rating={conectivityToPreview.ratingStats.ratingsAverage} readOnly={true} starSize={"12px"}/>
							</div>
						}
						
						
						 <i> {!conectivityToPreview.approvedPaidTime ? "" : conectivityToPreview.approvedPaidTime + " min"}</i>
						
						
						{!conectivityToPreview.conectCoins >0 ? "" :
						<div className="ContainerNoHeightCenter"> 
							{!conectivityToPreview ? "" : conectivityToPreview.conectCoins} 
							<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} />  
						</div>                        
						}
						{!conectivityToPreview.badgesDEI>0 ? "" :  
							<div className="ContainerNoHeightCenter"> 
								{!conectivityToPreview ? "" : conectivityToPreview.badgesDEI} 
								<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
							</div>
						}
						{!conectivityToPreview.badgesCS>0 ? "" :  
							<div className="ContainerNoHeightCenter"> 
								{!conectivityToPreview ? "" : conectivityToPreview.badgesCS} 
								<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /> 
							</div>
						}
						
						<div className ={`ContainerNoHeightSpaceAround launchButtonsWrapper ${showHoverContent && "launchButtonsShow"}`}>
							<button className="bannerButton" onClick={handleLaunchLocal}>
								<div className="ContainerNoHeightCenter" >
									<LaunchIcon color={setHeaderColorByCategory(conectivityToPreview.category.label)} />
									<div style={{paddingLeft:"5px"}}>LAUNCH</div>
								</div>
							</button>
						</div>    

				</div>
	
		</div>
	</div>
  );
});


///Component for showing a ROW of launchable conectivities
const LaunchRow = React.memo(({id, title, conectivities, isLargeRow, showPreviewCallback, launchCallback}) => {

	//State variables for Row component
	const [showRowNavButtons, setShowRowNavButtons] = useState(false); 
	const scrollRowRef = useRef(null); //React Ref representing scroll Div
	const [scrollLeftCurrentValue, setScrollLeftCurrentValue] = useState(0);
	const [maxScrollForRow, setMaxScrollForRow] = useState(0);
	const scrollDuration = 2000; // 2 second scroll  

	//Set amount to scroll based on actual client window size
	const [pixelsToScrollPerButtonPress, setPixelsToScrollPerButtonPress] = useState(1000);

	// if (DEBUG_MODE >= 2) console.log("Rowkey", id);

	//Re-calc max scroll width on any new render of the row
	useEffect(() => {

		if (scrollRowRef.current == null)  return;
	
		var maxScrollLeft = scrollRowRef.current.scrollWidth - scrollRowRef.current.clientWidth;
		setMaxScrollForRow(maxScrollLeft);
		setPixelsToScrollPerButtonPress( Math.floor(scrollRowRef.current.clientWidth * 0.8));  //Set scroll about to 80% of screen width

		// if (DEBUG_MODE >= 2) console.log("Max Scroll", maxScrollLeft, " scroll amount per button", Math.floor(scrollRowRef.current.clientWidth * 0.8));
	});    
		
	 
	if (conectivities == null  || conectivities.length===0) return null;
	
	// if (DEBUG_MODE >= 2) console.log("Launch Row called", conectivities, title, isLargeRow);

  

   //Functions to show/hide left/right nav buttons upon mouse entering/leaving the row      
	const handleMouseLeave = (e) => {
		// if (DEBUG_MODE >= 2) console.log("Mouse left row",e);
		setShowRowNavButtons(false);
	};        
	const handleMouseEnter = (e) => {
		// if (DEBUG_MODE >= 2) console.log("Mouse entered row",e);
		setShowRowNavButtons(true);
	}; 
	
	//Functions to scroll the row in response to a user click
	const handleLeftNav = () => {

		// if (DEBUG_MODE >= 2) console.log("Handling Left Nav",scrollRowRef.current.scrollLeft);
		// if (DEBUG_MODE >= 2) console.log("width", scrollRowRef.current.offsetWidth);
		
		if (scrollRowRef.current !== null)  
			animateScroll({
				initialScrollPosition:scrollLeftCurrentValue, 
				scrollAmount:pixelsToScrollPerButtonPress,
				direction:"LEFT", 
				duration:scrollDuration,
			});
		else {
			//if (DEBUG_MODE >= 2) console.log("Error: No Ref to scroll");
		}
		
	};
	
	const handleRightNav = () => {
		
		// if (DEBUG_MODE >= 2) console.log("Handling Right Nav",scrollRowRef.current.scrollLeft);
		// if (DEBUG_MODE >= 2) console.log("width", scrollRowRef.current.offsetWidth);
		
		if (scrollRowRef.current !== null)  
			// scrollRowRef.current.scrollLeft += 200;
			animateScroll({
				initialScrollPosition:scrollLeftCurrentValue, 
				scrollAmount:pixelsToScrollPerButtonPress,
				direction:"RIGHT", 
				duration:scrollDuration
				});             

		else {
			//if (DEBUG_MODE >= 2) console.log("Error: No Ref to scroll");
		}
	};


	//Handler to track scroll position while user is manually scrolling; allows us to display/hide nav buttons based on position
	const handleScroll = () => {

		if (scrollRowRef.current == null)  return;
	
		setScrollLeftCurrentValue(scrollRowRef.current.scrollLeft);
		
		// if (DEBUG_MODE >= 2) console.log("Current Scroll Left Value", scrollRowRef.current.scrollLeft);
			
	 };

	//
	//Animation functions for smoothly scrollings row when the user clicks a nav row button
	//  https://www.ackee.agency/blog/scroll-to-element-with-react-and-vanilla-javascript
	//  https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
	//
	
	
	const pow = Math.pow;
	
	// The easing function that makes the scroll decelerate over time
	function easeOutQuart(x) {
	 return 1 - pow(1 - x, 4);
	}
	
	//scrollAmount in pixels
	//direction is LEFT or RIGHT
	//duration in ms
	function animateScroll({initialScrollPosition, scrollAmount, direction, duration}) {
		let startTime;
		let animationFrame;
		let amountScrolled;
		let targetScrollPosition;
		var loopCount = 0;
		const maxLoopCount = 200;
		
		// if (DEBUG_MODE >= 2) console.log("Animation Scroll Called", initialScrollPosition, scrollAmount, direction, duration);
		
		//Basic checks            
		if (direction==null  || direction == undefined) return;
		if (scrollAmount == null || scrollAmount <=0) return;

		//Initilalize           
		const requestAnimationFrame = window.requestAnimationFrame;
		const cancelAnimationFrame = window.cancelAnimationFrame;

		if (direction == "LEFT") {
			targetScrollPosition = initialScrollPosition - scrollAmount;
			if (targetScrollPosition < 0) targetScrollPosition = 0;
		} else if (direction == "RIGHT") {
			targetScrollPosition = initialScrollPosition + scrollAmount;
			if (targetScrollPosition > maxScrollForRow) targetScrollPosition = maxScrollForRow;
		}

		// if (DEBUG_MODE >= 2) console.log("Animate Scroll initialized", " initial position:", initialScrollPosition, " scroll amount:", scrollAmount, "  target position:", targetScrollPosition);
		
		//Our callback function that is called by the browser every refresh during animation        
		function step(timestamp) {
			if (startTime === undefined) {
				// if (DEBUG_MODE >= 2) console.log("Initializing scroll animation timestep");
				startTime = timestamp;
				amountScrolled = 0;
			}
			
			const elapsedTime = timestamp - startTime;
			
			// this just gives us a number between 0 (start) and 1 (end) representing % of time that has passed
			const relativeProgress = elapsedTime / duration;
			
			// ease out that number; returns a number between 0 and 1 representing the % of the pixel amount we should have scrolled
			const easedScrollProgress = easeOutQuart(relativeProgress);
			const targetScrollAmount = Math.floor(scrollAmount * Math.min(easedScrollProgress, 1));
		  
			// calculate an incremental scroll amount based on our current scroll target amount 
			const incrementalScrollAmount = Math.floor(targetScrollAmount - amountScrolled);
		   
			// calculate new position for every tick of the requesAnimationFrame
			// Math.min ensures the element stops exactly at the intended scroll amount
			// position = initialPosition - maxAmountOfPixelsToScroll * Math.min(easedScrollProgress, 1);
			
			
			// Scroll the incremental amount by setting number of pixels from the LEFT of the page
			if (direction == "LEFT")
				scrollRowRef.current.scrollLeft -= incrementalScrollAmount;
			else if (direction == "RIGHT") 
				scrollRowRef.current.scrollLeft += incrementalScrollAmount;
		
			//Update amount scrolled so far, although screen has not yet completed this amount
			amountScrolled += incrementalScrollAmount;
			
			// Stop when max scroll is actually reached by the screen
			if (direction == "RIGHT" && scrollLeftCurrentValue >= targetScrollPosition) {
			 cancelAnimationFrame(animationFrame);
			 return;
			} else if (direction == "LEFT" && scrollLeftCurrentValue <= targetScrollPosition) {
			 cancelAnimationFrame(animationFrame);
			 return;
			} else if (loopCount > maxLoopCount) {
			 cancelAnimationFrame(animationFrame);
			 //if (DEBUG_MODE >= 2) console.log("Cancelled animation; max loopCount reached");
			 return;
			}       
			
			// repeat until the end is reached
			if (elapsedTime < duration) {
			 animationFrame = requestAnimationFrame(step);
			}        
		}
		
		//Initial animation request
		animationFrame = requestAnimationFrame(step);
		
	}
		
	return (
		 <div style={{position:"relative"}} 
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
		>
			<div className = "TextStyle7 launchRowTitle">{title}</div>

			<div className={`launchRowNavigator launchRowNavigatorLeft ${(showRowNavButtons && (scrollLeftCurrentValue >0)) && "launchRowNavigatorActive"}`}
				  style={{visibility:(showRowNavButtons && (scrollLeftCurrentValue >0) ? "visible" : "hidden")}}
				  onClick={handleLeftNav}>&#171;</div>
				  
			<div className={`launchRowNavigator launchRowNavigatorRight ${(showRowNavButtons && (scrollLeftCurrentValue < maxScrollForRow)) && "launchRowNavigatorActive"}`} 
				 style={{right:"0vw", top:"0vw", visibility:(showRowNavButtons && (scrollLeftCurrentValue < maxScrollForRow) ? "visible" : "hidden")}}
				 onClick={handleRightNav} >&#187;</div>
			
			<div className={isLargeRow ? 'launchRowLarge' : "launchRow"} ref={scrollRowRef} onScroll={handleScroll}>
				{conectivities.map((conectivity) => (
					<LaunchCardConectivityPreview  key={conectivity.id} conectivityToPreview={conectivity} isLargeCard={isLargeRow ? true : false} showPreviewCallback={showPreviewCallback} launchCallback={launchCallback}/>            
				))}
	  
			</div>
		 </div>
		);
	
}, (prevProps, nextProps) => {
  if (prevProps.conectivities.length === nextProps.conectivities.length) {
	//  if (DEBUG_MODE >= 2) console.log("Props equals");
	return true; // props are equal
  }
//   if (DEBUG_MODE >= 2) console.log("Props not equal");
  return false; // props are not equal -> re-render the row
});


export default LaunchRow;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';
import React from 'react';
import { Divider } from "../../../../shared/utils/generalUtils";


//React component
const CCdisplayCardFavorites = React.memo(({analytics}) => {

    const title="STORE ACTIVITY";
    
    const footer="";

    // if (DEBUG_MODE >= 2) console.log("Product Stats Card called", analytics);
    
    return (

        <div className="dashboardCard">
            <div className="ContainerVerticalCenterFullWidth">
                <div className="TextStyle4 headerBlue heavy">{title}</div>
                <Divider width={80} />
            </div>
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{analytics.purchasesTotal}</div>
                <div className="dashboardCardText">Items awarded</div>
            </div >
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{"$" + analytics.dollarCostTotal}</div>
                <div className="dashboardCardText">Employer cost to date</div>
            </div >
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{analytics.coinsSpentTotal}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /></div>
                <div className="dashboardCardText">Coins redeemed</div>
            </div >
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{analytics.badgesDEISpentTotal}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /></div>
                <div className="dashboardCardText">DEI badges redeemed</div>
            </div >
            <div className="dashboardCardTextRow">
                <div className="dashboardCardNumber">{analytics.badgesCSSpentTotal}<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /></div>
                <div className="dashboardCardText">CS badges redeemed</div>
            </div >

            <span className="dashboardCardTextFooter">{footer} </span>
        </div>
        
    );

});


export default CCdisplayCardFavorites;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//

//Style
import "./employeeStore.css";
import "./Components/launchRow/launchRow.css"; //TODO reuse the LaunchRow component here

//CCONFIG
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_WHITE, DAYS_FOR_RECENTLY_ADDED_PRODUCTS, QUICK_FILTERS_FOR_PRODUCTS, ENABLE_30_PERCENT_DISCOUNT, TEAM_COLOR } from '../../shared/data/conectereConfigData';

//React & Amplify components
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';

//Queries and Mutations
import { createOrder, updateCCWallet, createCCTransaction} from '../../shared/graphql/mutations';
import { getCustomer } from '../../shared/graphql/queries';

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { ProductsContext } from '../../shared/context/productsContext';              //Products context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context

//Components
import Product from "../../shared/Components/product/product";
import ProductDetailedView from "../../shared/Components/productDetailedView/productDetailedView";
import SearchInputBar from "../../shared/Components/searchInputBar/searchInputBar.js";
import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";

//Material UI and my components therefrom

//Utils
import { getRandomInt, truncateStringByWord, compareByCreatedAt} from "../../shared/utils/generalUtils";
import { compareProductsByTitle, productMatchesSearch, productSatisfiesFilter} from "../../shared/utils/productUtils";
import { invokeAPI, getDataRecordById } from "../../shared/utils/databaseUtils";

import { v4 as uuidv4 } from 'uuid';    
import moment from 'moment';


//import { Authenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import {Authenticator } from '@aws-amplify/ui-react';

	
const EmployeeStorePage = () => {

	// CONTEXT
	const { setShowSpinner } = useContext(DisplayContext);  
	const { authState,  currentUser, userWallet, setUserWallet, isSuperAdmin, } = useContext(AuthContext);  
	const { selectedCustomerOptions, customers, } = useContext(CustomerContext);  
	const { activeProducts, } = useContext(ProductsContext);  
  
	//variable for holding state data on this page 
	const PRODUCT_INITIAL_STATE = {title: "", description: "", featured: false, price:0, image: "", customer: {name: ""}, externalLink:""};

   //variable to control confirmation Modal if user orders product; default to hidden
	const [showModalOrder, setShowModalOrder] = useState(false);

	//variable for holding on this page data for ordering items
	const [isOrderingProduct, setIsOrderingProduct] = useState(false);              //Used as a state variable set to TRUE when updating the backend
	const [productToOrder, setProductToOrder] = useState(PRODUCT_INITIAL_STATE);    //Used to hold a product that is to be 
	const [productToSpotlight, setProductToSpotlight]  = useState(null);   

	 //State for Search Bar
	const [searchTerm, setSearchTerm] = useState("");
	const [searchActive, setSearchActive] = useState(false);
	const [activeFilters, setActiveFilters] = useState([]);
   
	const [logoToDisplay, setLogoToDisplay] = useState(null);

	//variable for holding on this page data for cusomters 
	const [productsFilteredBySearch, setProductsFilteredBySearch] = useState([]); //Filtered list based on Search; used to drive all the row data
	const [productsFeatured, setProductsFeatured] = useState([]);
	const [productsFavorites, setProductsFavorites] = useState([]);
	const [productsSuggestions, setProductsSuggestions] = useState([]);
	const [productsRecentlyAdded, setProductsRecentlyAdded] = useState([]); 
	const [productsSponsored, setProductsSponsored] = useState([]);
	const [productsCharities, setProductsCharities] = useState([]);
	const [productsDEI, setProductsDEI] = useState([]);
	const [productsCS, setProductsCS] = useState([]);
	const [productsSpotlights, setProductsSpotlights] = useState([]);

	 //state variables for error messages
	const [showModalError, setShowModalError] = useState(false);
	const [modalErrorMessage, setModalErrorMessage] = useState("");
	const [modalErrorTitle, setModalErrorTitle] = useState("");


   //
   // BANNER
   //


   //Component for showing at the top of the page the being viewed at Conectivity in a ROW
   const Banner = ({initialProductToSpotlight, products, handleOrderCallback}) => {

		const [productToSpotlight, setProductToSpotlight] = useState(initialProductToSpotlight);

	  //Set up React useEffect set up a timer for once second, causing just the Banner to render without changing the rest of the page
	  //Upon render, restart the timer
	  
	  useEffect(() => {
		 const timer = setTimeout(() => {             
		 if (products && products.length > 0) setProductToSpotlight(products[getRandomInt(0, products.length-1)]);             
		 // if (DEBUG_MODE >= 2) console.log("Banner timer fired", productToSpotlight, products);          
		 }, 7000); //Fire in 7 seconds
	  
	  // Clear timeout if the component is unmounted
	  return () => clearTimeout(timer);
	  });

	  if (!productToSpotlight) return null;
	  
	  const backgroundImageURL = "url('" +productToSpotlight.image+ "')";
	  // if (DEBUG_MODE >= 2) console.log("Product to preview in Banner", productToSpotlight, backgroundImageURL);
	  
	  return (    
		<header className={`bannerHeader `} style={{backgroundImage:backgroundImageURL}}>
			<div className="bannerOuterContainer" >
				<div className="bannerInnerContainer" >
					<div className="bannerContents">                   
						<div className="TextStyle6A bannerTitle" >
							{productToSpotlight.title}
						</div>
						<div className="bannerButtons">                                           
							<button className="bannerButton" onClick={()=> handleOrderCallback(productToSpotlight)}>VIEW</button>
						</div>
						<div className="bannerDescription TextStyle5" >
							{truncateStringByWord(productToSpotlight.description, 400, true)}
						</div>
					</div>
					<div className="bannerFadeLeftToRight bannerFadeLeftToRightBalance" ></div>
				</div>
			</div>
		</header>
	  );
   
   };
	// Get product data on page load
	useEffect(() => {
		updatePageData();
	}, [authState, selectedCustomerOptions, activeProducts]);


	async function updatePageData() {

		if (authState !== "signedin") return;

	  //   setShowSpinner(true); //Show spinners
	  
		//Update local state data 
		if (activeProducts) {
			const tempActiveProducts = [...activeProducts];     //Make a new var to trigger a state update
			setProductsFilteredBySearch(tempActiveProducts);    //Initialize master list for search
			// if (DEBUG_MODE >= 2) console.log("Stored active products", activeProducts);
		} else {
			if (DEBUG_MODE >= 2) console.log("No active products");
		}

		
		//Set the URL of the logo to display
		//SuperAdmin selecting a customer?
		if (isSuperAdmin && (!selectedCustomerOptions.label.includes("- ALL -"))) {
			const selectedCustomer = await getDataRecordById(getCustomer, 'getCustomer', selectedCustomerOptions.id);       
			if (selectedCustomer && selectedCustomer.logo) {
				if (DEBUG_MODE >= 2) console.log("Fetched customer logo URL",  selectedCustomer) ;  
				setLogoToDisplay(selectedCustomer.logo);
			}    
		} else {
			setLogoToDisplay(currentUser.customer.logo);
		}
			
			

	  //   setShowSpinner(false); //Hide spinners

	  //Initialize the banner
	  if (activeProducts && activeProducts.length >0) setProductToSpotlight(activeProducts[getRandomInt(0, activeProducts.length-1)]); 
	}

	//Use effect for generating new rows and spotlighted product; any change to Products will fire a filtering operation
	
	useEffect(() => {
		generateRows();  
	}, [productsFilteredBySearch]); //Respond to any change to the filtered master list by generating new Rows

	function generateRows() {
 
			// if (DEBUG_MODE >= 2) console.log("Generating Product Rows", productsFilteredBySearch);
			
			//Copy Master as updated by search
			var filteredProducts = [...productsFilteredBySearch];
			
			var filteredProductsForRow = [];


			//Filter Products based on featured
			filteredProductsForRow = filteredProducts.filter(product => product.featured == true);
			filteredProductsForRow.sort(compareProductsByTitle);                      //Sort the Products
			setProductsFeatured(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for Featured row", filteredProductsForRow);

		   //Filter Products based on sponsored
			filteredProductsForRow = filteredProducts.filter(product => product.productScope == "GLOBAL");
			filteredProductsForRow.sort(compareProductsByTitle);                      //Sort the Products
			setProductsSponsored(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for Featured row", filteredProductsForRow);

		   //Generate list of recently added products
			filteredProductsForRow = filteredProducts.filter(product => productRecentlyAdded(product)); 
			filteredProductsForRow.sort(compareByCreatedAt);                 //Sort the Products
			setProductsRecentlyAdded(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for Recently Added row", filteredProductsForRow);
			
			//Generate list of charities
			filteredProductsForRow = filteredProducts.filter(product => product.charity); 
			filteredProductsForRow.sort(compareByCreatedAt);                 //Sort the Products
			setProductsCharities(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for Recently Added row", filteredProductsForRow);

		   //Generate list of DEI products
			filteredProductsForRow = filteredProducts.filter(product => product.badgesCS > 0); //May not really be necessary but some conectivities won't have this until rated
			filteredProductsForRow.sort(compareProductsByTitle);                 //Sort the Products
			setProductsCS(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for CS row", filteredProductsForRow);

		   //Generate list of CS products
			filteredProductsForRow = filteredProducts.filter(product => product.badgesDEI > 0); //May not really be necessary but some conectivities won't have this until rated
			filteredProductsForRow.sort(compareProductsByTitle);                 //Sort the Products
			setProductsDEI(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for CS row", filteredProductsForRow);

		   //Generate list of products the require recent Spotlights
			filteredProductsForRow = filteredProducts.filter(product => product.badgesSpotlight > 0); //May not really be necessary but some conectivities won't have this until rated
			filteredProductsForRow.sort(compareProductsByTitle);                 //Sort the Products
			setProductsSpotlights(filteredProductsForRow);
			// if (DEBUG_MODE >= 2) console.log("Filtered Products for Spotlights row", filteredProductsForRow);



	}  

	function productRecentlyAdded(product) {

		const startOfPeriod= moment().subtract(DAYS_FOR_RECENTLY_ADDED_PRODUCTS, 'days').startOf('day');
		const productCreatedDate = moment(product.createdAt, "YYYY MM DDTHH mm ssZ");
		

		if (productCreatedDate.isAfter(startOfPeriod)) {
			// if (DEBUG_MODE >= 2) console.log("RETURNED TRUE - Comparing product date and 90 day period", productCreatedDate.format("YYYY-MM-DDTHH:mm:ssZ"), startOfPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));
			return true;
		} else {
			// if (DEBUG_MODE >= 2) console.log("RETURNED FALSE - Comparing product date and 90 day period", productCreatedDate.format("YYYY-MM-DDTHH:mm:ssZ"), startOfPeriod.format("YYYY-MM-DDTHH:mm:ssZ"));
			return false;
		}
		
		
	}    
	
	//Use effect for processing updates to the Search Term
	
	useEffect(() => {
		processSearchTermChange();  
		if (DEBUG_MODE >= 2) console.log("Search term or active filters change detected in Use Effect", searchTerm, searchActive);
	}, [searchTerm, activeFilters, activeProducts, ]);
	
	function processSearchTermChange () {
		let tempProducts = [...activeProducts];
		if (searchActive && (searchTerm || (activeFilters && activeFilters.length !== 0))) {
			tempProducts = tempProducts.filter(product => productMatchesSearch({product, activeFilters, keywords:searchTerm} )); //Filter based on new term          
			if (DEBUG_MODE >= 2) console.log("Generated new filtered list based on search term", searchTerm, tempProducts);           
		}
		setProductsFilteredBySearch(tempProducts);
	}   

	const handleCloseModalError = () => {
		setShowModalError(false);
	};

   //Order Functions 
   async function handleConfirmationOrder () {
 
	//Recompute the coins needed based on any company-specific coin value set by the admin
	//Basically, this overrides the pre-computed coins
	//Note, 'price' is the number of coins needed
	let productPrice = (productToOrder.price ? productToOrder.price : 0); //default to current coin value
	if (currentUser.customer.configDetails.dollarToCoinConversion) {
		productPrice= (productToOrder.dollarCost * currentUser.customer.configDetails.dollarToCoinConversion);
		if (DEBUG_MODE > 0) console.log("Custer has specific dollar-to-coin conversion  New price:", productPrice);
	}
	//Any Special Events that discount our coins?
	if (ENABLE_30_PERCENT_DISCOUNT) {
		productPrice = Math.floor(productPrice * 0.70);
		if (DEBUG_MODE > 0) console.log("30% discount enabled and applied.  New price:", productPrice);
	 }  

	  //Check to see whether user has the required amount of coins, badges, etc.
	  if (userWallet.currentBalance < productPrice || 
		 userWallet.badgesCS < productToOrder.badgesCS ||
		 userWallet.badgesDEI < productToOrder.badgesDEI ||
		 (!userWallet.badgesSpotlight && productToOrder.badgesSpotlight > 0) ||
		 userWallet.badgesSpotlight < productToOrder.badgesSpotlight) {
			setModalErrorMessage("Not quite enough coins or badges.");
			setModalErrorTitle("Sorry!");
			setShowModalError(true); //display error message
			return;
	  }

	  setShowSpinner(true); //show spinners
	  if (DEBUG_MODE >= 2) console.log('Processing order for product:', productToOrder);
		
		var success = false;
		
		try {

		   //Compute CreatedAt date for transaction since we use it as a database hash key                
			var createdAtTimestamp = moment().toISOString();  // set CreatedAt time according to ISO standard in GMT

			const tempOrder = {
			   orderStatus: "OPEN",
			   amount: productPrice,
			   dollarCost: productToOrder.dollarCost,
			   badgesDEI: productToOrder.badgesDEI,
			   badgesCS:productToOrder.badgesCS,
			   badgesSpotlight:productToOrder.badgesSpotlight,
			   tax:0,
			   shipping:0,
			   total:productToOrder.dollarCost,
			   memo: "",
			   // memo: "Ordered: " + productToOrder.title,
			   productID: productToOrder.id,
			   customerID: currentUser.customerID,     //Customer ID within which to associate with the Order; i.e. user placing the order even if product created by a different company
			   userID: currentUser.id,                 //User placing the order
			};


			//Call GraphQL with correct parameters to create the item in the  backend   
			//Get the returned, updated object so we can update our local data with all the fields
			const tempCreatedOrder = await invokeAPI(createOrder, 'createOrder', tempOrder);       
			if (DEBUG_MODE >= 2) console.log('Successfully created order', tempCreatedOrder);

			//Record the transaction in the user's wallet
			const tempTransactionToAdd = {
				createdAt: createdAtTimestamp,
				sequenceNumber: userWallet.nextSequenceNumber,          //assign the next open sequence number for this user's wallet
				name:productToOrder.title,
				memo: "Order placed. Coins Redeemed!", 
				amount: productPrice * -1,                      //Debit
				dollarCost: productToOrder.dollarCost *-1,              //Debit
				ccWalletID: userWallet.id,
				conectivityID: "",
				orderID: tempCreatedOrder.id,
				newBalance:  userWallet.currentBalance - productPrice,    //updated balance at the time this transaction is recorded
				transactionType: "CC_REDEMPTION",
				image: productToOrder.image,            //grab the image so we can display with the transaction whenever we need
				productID: productToOrder.id,           //Add product information to the transaction
				productTitle: productToOrder.title,
				badgesDEI: (!productToOrder.badgesDEI ? 0 : productToOrder.badgesDEI *-1),                  //Debit,
				badgesCS: (!productToOrder.badgesCS ? 0 : productToOrder.badgesCS*-1),                      //Debit,
				badgesSpotlight: (!productToOrder.badgesSpotlight ? 0 : productToOrder.badgesSpotlight*-1), //Debit,
				customerID: currentUser.customerID,     //Customer ID within which to associate with the Order; i.e. user placing the order even if product created by a different company
				userID: currentUser.id,
				badgesDEIBalance: userWallet.badgesDEI - productToOrder.badgesDEI,                     //Update badges, if any
				badgesCSBalance: userWallet.badgesCS - productToOrder.badgesCS,                       //Update badges, if any                
				badgesSpotlightBalance: userWallet.badgesSpotlightBalance - productToOrder.badgesSpotlight,                       //Update badges, if any         
				timeSeries: 'CONECTERE',           
			};

			//Call GraphQL with correct parameters to create a new transaction
			const tempInsertedTransaction = await invokeAPI(createCCTransaction, 'createCCTransaction', tempTransactionToAdd);        
			if (DEBUG_MODE >= 2) console.log('Successfully wrote new transaction', tempInsertedTransaction);


			//Update user's wallet based on transaction
			 const tempCCWalletToUpdate = {
				id: userWallet.id,
				// description: userWallet.description,
				currentBalance: userWallet.currentBalance  - productPrice,
				badgesCS: userWallet.badgesCS  - productToOrder.badgesCS,
				badgesDEI: userWallet.badgesDEI - productToOrder.badgesDEI,
				badgesSpotlight: userWallet.badgesSpotlight - productToOrder.badgesSpotlight,
				// status: userWallet.status,
				// userID: userWallet.userID,
				nextSequenceNumber: userWallet.nextSequenceNumber+1,
			};
			
			//Call GraphQL with correct parameters to update wallet record in the backend - note, our Lambda will be triggered on CRUD operation on Orders and will send emails
			const tempInsertedCCWalletRecord = await invokeAPI(updateCCWallet, 'updateCCWallet', tempCCWalletToUpdate);        
			if (DEBUG_MODE >= 2) console.log('Successfully updated user wallet', tempInsertedCCWalletRecord);
			
			//update our local state data
			setUserWallet(tempInsertedCCWalletRecord);            
			
			success = true;
					   
		} catch (err) {
			success = false;
			if (DEBUG_MODE >= 2) console.log('error creating order:', err);            
			setModalErrorMessage(err);

		}
		
		setShowSpinner(false); //Hide spinners
		setShowModalOrder(false);   //hide the pop-up
		setProductToOrder(PRODUCT_INITIAL_STATE); //now reset the data since the modal is gone
		setIsOrderingProduct(false); //now enable buttons on main page for other activity
		
		if (success) {
			setShowModalError(true);
			setModalErrorMessage("Your order has been submitted and will be filled shortly.  An award certificate has been sent to " + currentUser.email + ".  Enjoy!");
			setModalErrorTitle("Success!");    
		} else {
			setShowModalError(true);
			setModalErrorTitle("Ooops!");               
		}      
	}

	const handleCancelOrder = () => {
		setShowModalOrder(false);
		setProductToOrder(PRODUCT_INITIAL_STATE);
		
		setIsOrderingProduct(false); //enable buttons on main page
		//if (DEBUG_MODE >= 2) console.log("Cancelled ProductOrder.");
	};
		
	//This function handles the order buttons
	//Pop-up a modal and ask product to confirm. Handle confirmation
	const handleOrder = (productToOrder) => {    
		setIsOrderingProduct(true); //disable buttons on main page     
		var tempProductToOrder = {...productToOrder};
		
		//Set Managed By fields
		if (tempProductToOrder.productScope === "CUSTOMER_SPECIFIC") {
			
			//PRODUCT OFFERED BY A SPECIFIC COMPANY
			const tempCustomer = customers.find(customer => customer.id === tempProductToOrder.customerID);    //All clients will have a customer array, even if only 1 entry

			tempProductToOrder.managedByName = (tempCustomer ? tempCustomer.name : "");
			tempProductToOrder.managedByLogo = (tempCustomer ? tempCustomer.logo : "");
		} else {

			//CONECTERE SPONSORED (GLOBAL) PRODUCTS and DEFAULT STORE PRODUCTS      
			tempProductToOrder.managedByName = "Conectere";
			tempProductToOrder.managedByLogo = CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH;
		}       
		if (DEBUG_MODE >= 2) console.log("Order button pressed.  Order Product=", tempProductToOrder);        
		setProductToOrder(tempProductToOrder); //Store the passed in object into state data variable to record object being edit
		setShowModalOrder(true); //pop-up Modal 
	};
	
	

	
	//Function to handle filtering store by customer if user has SuperAdmin role
	//This uses a Global
	// const handleFilterByCustomer = async (eventKey) => {
		
	//     setFilterDropDownSelection(eventKey);
		
	//     //filter the customers based on the new setting
	//     //if (DEBUG_MODE >= 2) console.log("filtering customers");
	//     //if (DEBUG_MODE >= 2) console.log("filteredDropDownSelection = ", eventKey);
	//     //if (DEBUG_MODE >= 2) console.log("inputProducts = ", PRODUCT_INITIAL_STATE);
	//     //if (DEBUG_MODE >= 2) console.log("customerOptions = ", customerOptions);
		
		
	//     if (eventKey>=0  && eventKey < customerOptions.length) { 
		
	//         //if (DEBUG_MODE >= 2) console.log("Looking for: ", customerOptions[eventKey].id)
			
	//         //grab the full products for the page as filtered by the SEARCH
	//         var tempProducts=[...productsMaster]; //Make a copy of the state data
	//         var newTempProducts=tempProducts.filter (element => element.customer.id === customerOptions[eventKey].id);
	//         //if (DEBUG_MODE >= 2) console.log("Filtered products by customer", newTempProducts);
	//         setProductsMasterFilteredByCustomer(newTempProducts);
	//         //Since new Customer filter selection, reset search input.  This will also regen the page
	//         setProductsFilteredBySearch(newTempProducts);  //Reset the further filtered list
	//         setSearchTerm("");      //Clear search term
	//         setSearchActive(false); //Reset any prior filtering by search
	//         // setProductToSpotlight(newTempProducts[getRandomInt(0, newTempProducts.length-1)]);        //Update Banner    
			
	//     } else {
			
	//         setProductsMasterFilteredByCustomer([...productsMaster]); //Make a fresh copy or Master Master
	//         setProductsFilteredBySearch([...productsMaster]);  //Reset the further filtered list
	//         // setProductToSpotlight(productsMaster[getRandomInt(0, productsMaster.length-1)]);        //Reset Banner    
	//         setSearchTerm("");      //Clear search term
	//         setSearchActive(false); //Reset any prior filtering by search

	//     }
	// }
	
	function displayCorporateLogo (logoToDisplay) {

		if (logoToDisplay !== null ) return (
			<div className="employeeStoreCustomerLogoWrapper">
				  <img className="ccImgXXXL employeeStoreCenterLogo" src={logoToDisplay} alt={''}  /> 
			</div>
		);
	}        


	//Button for SuperAdmin to filter by Customer
	// function corporateFilterButton () {
		
	//     if (!isSuperAdmin) return null;
		
	//     return (
	//         <div className = "customerFilterWrapperEmployeeStore" >
	//             <DropdownButton id="dropdown-basic-button" 
	//                 title={filteredDropDownSelection==-1 ? 'All Customers' : 'Customer: '+customerOptions[filteredDropDownSelection].label}
	//                 size="small"
	//                 variant="secondary"

	//                 >
	//                 <Dropdown.Item eventKey="-1"  onSelect={!isOrderingProduct ? handleFilterByCustomer: null} active={filteredDropDownSelection == "-1"}>All Customers</Dropdown.Item>
	//                 {customerOptions.map((e, key) => <Dropdown.Item eventKey={key} onSelect={!isOrderingProduct ? handleFilterByCustomer : null} active={filteredDropDownSelection == key}> {e.label} </Dropdown.Item>)}
	//             </DropdownButton>
	//          </div>      
	//     );
	// }

   

///Component for showing a ROW of conectivities
const LaunchRow = React.memo(({title, products, isLargeRow, wrap}) => {

	//State variables for Row component
	const [showRowNavButtons, setShowRowNavButtons] = useState(false); 
	const scrollRowRef = useRef(null); //React Ref representing scroll Div
	const [scrollLeftCurrentValue, setScrollLeftCurrentValue] = useState(0);
	const [maxScrollForRow, setMaxScrollForRow] = useState(0);
	const scrollDuration = 2000; // 2 second scroll
	const rowKey = uuidv4();
 
	//Set amount to scroll based on actual client window size
	const [pixelsToScrollPerButtonPress, setPixelsToScrollPerButtonPress] = useState(1000);


	// if (DEBUG_MODE >= 2) console.log("Rowkey", rowKey);
	
	//Re-calc max scroll width on any new render of the row
	useEffect(() => {
		if (scrollRowRef.current == null)  return;
		var maxScrollLeft = scrollRowRef.current.scrollWidth - scrollRowRef.current.clientWidth;
		setMaxScrollForRow(maxScrollLeft);
		setPixelsToScrollPerButtonPress( Math.floor(scrollRowRef.current.clientWidth * 0.8));  //Set scroll about to 80% of screen width
		// if (DEBUG_MODE >= 2) console.log("Set row", rowKey, "Max Scroll", maxScrollLeft, " scroll amount per button", Math.floor(scrollRowRef.current.clientWidth * 0.8));
	});    
		 
	if (products == null  || products.length===0) return null;
	// if (DEBUG_MODE >= 2) console.log("Launch Row called", products, title, isLargeRow);
	


   //Functions to show/hide left/right nav buttons upon mouse entering/leaving the row      
	const handleMouseLeave = (e) => {
		// if (DEBUG_MODE >= 2) console.log("Mouse left row",e);
		setShowRowNavButtons(false);
	};        
	const handleMouseEnter = (e) => {
		// if (DEBUG_MODE >= 2) console.log("Mouse entered row",e);
		setShowRowNavButtons(true);
	}; 
	
	//Functions to scroll the row in response to a user click
	const handleLeftNav = () => {

		// if (DEBUG_MODE >= 2) console.log("Handling Left Nav",scrollRowRef.current.scrollLeft);
		// if (DEBUG_MODE >= 2) console.log("width", scrollRowRef.current.offsetWidth);
		
		if (scrollRowRef.current !== null)  
			animateScroll({
				initialScrollPosition:scrollLeftCurrentValue, 
				scrollAmount:pixelsToScrollPerButtonPress,
				direction:"LEFT", 
				duration:scrollDuration,
			});
		else {
			//if (DEBUG_MODE >= 2) console.log("Error: No Ref to scroll");
		}
		
	};
	
	const handleRightNav = () => {
		
		//if (DEBUG_MODE >= 2) console.log("Handling Right Nav",scrollRowRef.current.scrollLeft);
		//if (DEBUG_MODE >= 2) console.log("width", scrollRowRef.current.offsetWidth);
		
		if (scrollRowRef.current !== null)  
			// scrollRowRef.current.scrollLeft += 200;
			animateScroll({
				initialScrollPosition:scrollLeftCurrentValue, 
				scrollAmount:pixelsToScrollPerButtonPress,
				direction:"RIGHT", 
				duration:scrollDuration
				});             

		else {
			//if (DEBUG_MODE >= 2) console.log("Error: No Ref to scroll");
		}
	};


	//Handler to track scroll position while user is manually scrolling; allows us to display/hide nav buttons based on position
	const handleScroll = () => {

		if (scrollRowRef.current == null)  return;
	
		setScrollLeftCurrentValue(scrollRowRef.current.scrollLeft);
		
		// if (DEBUG_MODE >= 2) console.log("Current Scroll Left Value", scrollRowRef.current.scrollLeft);
			
	 };

	//
	//Animation functions for smoothly scrollings row when the user clicks a nav row button
	//  https://www.ackee.agency/blog/scroll-to-element-with-react-and-vanilla-javascript
	//  https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
	//
	
	
	const pow = Math.pow;
	
	// The easing function that makes the scroll decelerate over time
	function easeOutQuart(x) {
	 return 1 - pow(1 - x, 4);
	}
	
	//scrollAmount in pixels
	//direction is LEFT or RIGHT
	//duration in ms
	function animateScroll({initialScrollPosition, scrollAmount, direction, duration}) {
		let startTime;
		let animationFrame;
		let amountScrolled;
		let targetScrollPosition;
		var loopCount = 0;
		const maxLoopCount = 200;
		
		//if (DEBUG_MODE >= 2) console.log("Animation Scroll Called", initialScrollPosition, scrollAmount, direction, duration);
		
		//Basic checks            
		if (direction==null  || direction == undefined) return;
		if (scrollAmount == null || scrollAmount <=0) return;

		//Initilalize           
		const requestAnimationFrame = window.requestAnimationFrame;
		const cancelAnimationFrame = window.cancelAnimationFrame;

		if (direction === "LEFT") {
			targetScrollPosition = initialScrollPosition - scrollAmount;
			if (targetScrollPosition < 0) targetScrollPosition = 0;
		} else if (direction === "RIGHT") {
			targetScrollPosition = initialScrollPosition + scrollAmount;
			if (targetScrollPosition > maxScrollForRow) targetScrollPosition = maxScrollForRow;
		}

		// if (DEBUG_MODE >= 2) console.log("Animate Scroll initialized", " initial position:", initialScrollPosition, " scroll amount:", scrollAmount, "  target position:", targetScrollPosition);
		
		//Our callback function that is called by the browser every refresh during animation        
		function step(timestamp) {
			if (startTime === undefined) {
				// if (DEBUG_MODE >= 2) console.log("Initializing scroll animation timestep");
				startTime = timestamp;
				amountScrolled = 0;
			}
			
			const elapsedTime = timestamp - startTime;
			
			// this just gives us a number between 0 (start) and 1 (end) representing % of time that has passed
			const relativeProgress = elapsedTime / duration;
			
			// ease out that number; returns a number between 0 and 1 representing the % of the pixel amount we should have scrolled
			const easedScrollProgress = easeOutQuart(relativeProgress);
			const targetScrollAmount = scrollAmount * Math.min(easedScrollProgress, 1);
		  
			// calculate an incremental scroll amount based on our current scroll target amount 
			const incrementalScrollAmount = targetScrollAmount - amountScrolled;
		   
			// calculate new position for every tick of the requesAnimationFrame
			// Math.min ensures the element stops exactly at the intended scroll amount
			// position = initialPosition - maxAmountOfPixelsToScroll * Math.min(easedScrollProgress, 1);
			
			
			// Scroll the incremental amount
			if (direction === "LEFT")
				scrollRowRef.current.scrollLeft -= incrementalScrollAmount;
			else if (direction === "RIGHT") 
				scrollRowRef.current.scrollLeft += incrementalScrollAmount;
		
			//Update amount scrolled so far, although screen has not yet completed this amount
			amountScrolled += incrementalScrollAmount;
			
			// Stop when max scroll is actually reached by the screen
			if (direction === "RIGHT" && scrollLeftCurrentValue >= targetScrollPosition) {
			 cancelAnimationFrame(animationFrame);
			 return;
			} else if (direction === "LEFT" && scrollLeftCurrentValue <= targetScrollPosition) {
			 cancelAnimationFrame(animationFrame);
			 return;
			} else if (loopCount > maxLoopCount) {
			 cancelAnimationFrame(animationFrame);
			 //if (DEBUG_MODE >= 2) console.log("Cancelled animation; max loopCount reached");
			 return;
			}       
			
			// repeat until the end is reached
			if (elapsedTime < duration) {
			 animationFrame = requestAnimationFrame(step);
			}        
		}
		
		//Initial animation request
		animationFrame = requestAnimationFrame(step);
		
	}
		
		 
	return (
		<div style={{position:"relative"}} 
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
		>
			<div className = "TextStyle7 launchRowTitle">{title}</div>
	
			<div className={`employeStoreRowNavigator employeStoreRowNavigatorLeft ${(showRowNavButtons && (scrollLeftCurrentValue >0)) && "employeStoreRowNavigatorActive"}`}
				  style={{visibility:(showRowNavButtons && (scrollLeftCurrentValue >0) ? "visible" : "hidden")}}
				  onClick={handleLeftNav}>&#171;</div>
				  
			<div className={`employeStoreRowNavigator employeStoreRowNavigatorRight ${(showRowNavButtons && (scrollLeftCurrentValue < maxScrollForRow)) && "employeStoreRowNavigatorActive"}`} 
				 style={{right:"0vw", top:"0vw", visibility:(showRowNavButtons && (scrollLeftCurrentValue < maxScrollForRow) ? "visible" : "hidden")}}
				 onClick={handleRightNav} >&#187;</div>
		   
			<div className={isLargeRow ? 'launchRowLarge ' + (wrap ? ' wrap ' : '') : "launchRow wrap" + (wrap ? ' wrap ' : '')} ref={scrollRowRef} onScroll={handleScroll}>
				{products.map((product) => (
					<Product  
						key={rowKey + product.id} 
						product={product} 
						isLargeCard={isLargeRow ? true : false}  
						handleOrderCallback={handleOrder} 
						displayCustomer={isSuperAdmin}
						dollarToCoinConversion={currentUser.customer.configDetails && currentUser.customer.configDetails.dollarToCoinConversion ? currentUser.customer.configDetails.dollarToCoinConversion : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION}
					/>   
				))}
			</div>

			
		 </div>
		);
	});

	//REACT USECALLBACK to avoid re-render
	// https://alexsidorenko.com/blog/react-list-rerender/
	const onChangeCallback = useCallback ((value) => {
		 setSearchTerm(value);
		 if (DEBUG_MODE >= 2) console.log("Search bar CALLBACK called", value);
	   
	},[]);
	
	const onFilterChangeCallback = useCallback ((activeFilters) => {
		 setActiveFilters(activeFilters);
		 if (DEBUG_MODE >= 2) console.log("Search bar QUICK FILTER CALLBACK called", activeFilters);
	   
	},[]);
	
	const searchBarIsActiveCallback = useCallback ((active) => {
		 setSearchActive(active);
		 if (DEBUG_MODE >= 2) console.log("Search bar IS ACTIVE CALLBACK called", active);
	   
	},[]);    

	//
	// Page Render
	//
	
	//Handle access by unauthenticated user
	if (authState !== "signedin"  || !currentUser) {
	  if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
	  // if (authState == "signedout") popUpLoginWindow();
	  return null;
	}
	
	//Render function for authenticated user 

	return (
	  <Authenticator>

		 <ProductDetailedView 
			showModal={showModalOrder} 
			product={productToOrder} 
			handleProductOrder={handleConfirmationOrder} 
			handleCancelDetailedView={handleCancelOrder} 
			displayCustomer={isSuperAdmin} 
			dollarToCoinConversion={currentUser && currentUser.customer.configDetails && currentUser.customer.configDetails.dollarToCoinConversion ? currentUser.customer.configDetails.dollarToCoinConversion : CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION}
		/>

		 <ModalNoBackgroundFixed showModal={showModalError} closeCallback={handleCloseModalError} cardColor={TEAM_COLOR} closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR} >
			<h3 className="white" >{modalErrorTitle}</h3>  
			<div className="modalNoBkgInnerCard" > 
			   <div className="TextStyle4 black" style={{padding:"clamp(8px,2vw,20px)"}}> {modalErrorMessage} </div>
			</div>
		 </ModalNoBackgroundFixed>

		 <div className="employeeStorePageWrapper" >       
			{/* corporateFilterButton() */}     
			{/*displayCorporateLogo(logoToDisplay) */}      
			<SearchInputBar  useAnchorPostion={false} useDarkInput={true} enableSearchInput={true} onSearchStringChangeCallback={onChangeCallback} onFilterChangeCallback={onFilterChangeCallback} searchBarIsActiveCallback={searchBarIsActiveCallback} searchTermInitialValue={searchTerm} searchActiveInitialValue={searchActive} quickFilters={QUICK_FILTERS_FOR_PRODUCTS}/>
			<Banner initialProductToSpotlight={productToSpotlight}  products={activeProducts} handleOrderCallback={handleOrder}/>
			<LaunchRow title="Recently Added" products={productsRecentlyAdded} isLargeRow/>
			<LaunchRow title="Featured Rewards" products={productsFeatured} isLargeRow/>      
			<LaunchRow title="Conectere Sponsored" products={productsSponsored} isLargeRow/>           
			<LaunchRow title="Charities" products={productsCharities} isLargeRow/>
			<LaunchRow title="Spotlight Rewards" products={productsSpotlights} isLargeRow/>
			<LaunchRow title="Rewards for Diversity, Equity & Inclusion Achievements" products={productsDEI} isLargeRow/>
			<LaunchRow title="Community Service Rewards" products={productsCS} isLargeRow/>
			<LaunchRow title="All Rewards" products={productsFilteredBySearch} isLargeRow wrap/>
		 </div>

	  </Authenticator>
   );};
export default EmployeeStorePage;
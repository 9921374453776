//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//styles
import './modalMediaDisplay.css';

//config
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE,  COLOR_WHITE, TEAM_COLOR} from '../../data/conectereConfigData';

//React & Amplify
import React from 'react';

//Our components
import ModalNoBackground from "../../Components/modalNoBackground/modalNoBackground";
import { FileUploader } from  "../../Components/fileUpload/fileUpload";

const ModalMediaDisplay = ({showModalMediaDisplay, mediaUrl, handleClose }) => {

    if (!mediaUrl) mediaUrl = "https://cc075b7f325156a848e3bf1d0e0bd9c2ecc2220825-dev.s3.us-east-2.amazonaws.com/public/images/642c3b72-1b97-4d02-baa8-a7c0e2c9cf18iggy_400x400.png";
    const handleCancel = () => {
        if (DEBUG_MODE >= 2) console.log("Cancel button called");
        if (handleClose) handleClose();
    };
    
    return (
        <ModalNoBackground showModal={showModalMediaDisplay} hideInnerBorder closeCallback={handleCancel}  closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR}>
            <div className="modalNoBkgInnerCard modalMediaDisplay" >
                <div className="ContainerNoHeightCenter positionRelative " >
                    <img className="ccImgXXXL" src={mediaUrl} alt="" />
                </div>
            </div>
        </ModalNoBackground >
    );

};

export default ModalMediaDisplay;




//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR } from '../data/conectereConfigData';
import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import {useLocation} from 'react-router-dom'

//Our Components
import SpinnersConectere from "../Components/spinnerConectere/spinnerConectere";
import LoadingView from '../Components/loadingView/loadingView';

//Utils


const DisplayContext = React.createContext();

const DisplayProvider = ({ children }) => {

	// Display state variables
	const [isMobileViewPort, setIsMobileViewPort] = useState(getWindowWidth() <= 425);
	const [isTabletViewPort, setIsTabletViewPort] = useState(getWindowWidth() <= 768);
	const [innerWindowWidth, setInnerWindowWidth]  = useState(getWindowWidth());      //Dynamically adjusted state var to track view port width
	const [innerWindowHeight, setInnerWindowHeight]  = useState(getWindowHeight());   //Dynamically adjusted state var to track view port height
	const [darkMode, setDarkMode] = useState(false);                                  //Boolean that indicates whether the App should currently be in Dark Mode
	const [isRenderedWithinMsftClient, setIsRenderedWithinMsftClient] = useState(false);                      //Boolean that indicates whether the App is currently being rendered in an Msft Office wrapper, like Teams or Outlook
	const [clientDisplayModeDetected, setClientDisplayModeDetected] = useState(false);                //Boolean that indicates whether the client app (like Teams or Outlook) is in Dark Mode

	//Conectere Spinner control
	const [showSpinner, setShowSpinner] = useState(false);

	//PAGE BOUNDING BOX
	// const [pageWidth, setPageWidth]  = useState(0);         //Dynamically adjusted state var to track overall page width
	// const [pageHeight, setPageHeight]  = useState(0);      //Dynamically adjusted state var to track overall page width

	// const darkModeRef = useRef(null);
	const pageRef = useRef(null);
	const currentReactPage = useLocation(); //This React reference is updated every time the URL changes

	//
	//On MOUNT
	//

	useEffect(() => {

		//Set URL Search Params for child components
		const searchParams = new URLSearchParams(currentReactPage?.search);
		console.log("Current React Page", currentReactPage);
		console.log("URL Search Params", searchParams);

		//URL PARAMS?
		if (searchParams) { 

			//Microsoft Teams and other O365 Apps
			// https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/access-teams-context?tabs=Json-v2%2Cteamsjs-v2%2Cdefault
			setIsRenderedWithinMsftClient(searchParams.has("is-msft-teams"));
			if (searchParams.has("theme")) {
				const clientTheme = searchParams.get("theme");
				if(DEBUG_MODE) console.log("3rd Party client app Theme detected.  Setting display mode to match: " + clientTheme);
				setDarkMode(clientTheme==='dark');				//Set our own dark mode to match
				setClientDisplayModeDetected(true);	//Set flag so we defer to this override rather than load the user's setting
			}
		}

		// LISTENER to respond to window size changes
		function windowSizeListener() {

			try {
					//In our listener, call our helper functions
					const currentWidth = getWindowWidth();
					setInnerWindowWidth(currentWidth);
					setIsMobileViewPort (currentWidth <= 425);
					setIsTabletViewPort(currentWidth >425 && currentWidth <= 768);

				  if (DEBUG_MODE > 2) console.log("Current window width from listener", currentWidth, currentWidth <= 425, currentWidth <= 768);
					
					const currentHeight = getWindowHeight();
					setInnerWindowHeight(currentHeight);
				//   if (DEBUG_MODE >= 2) console.log("Current window height from listener", currentHeight);
			} catch (err) {
					if (DEBUG_MODE >=1) console.log("Error in display context", err);
			}
		}
		
		//Add Listener add  to track the view port width and adjust header content accordingly
		window.addEventListener('resize', windowSizeListener);

		//Cleanup
		return () => {
			window.removeEventListener('resize', windowSizeListener);
		};
	}, []); //Only on initial mount


	//Window stats helper Functions
	function getWindowWidth() {return window.innerWidth;}    
	function getWindowHeight() {return window.innerHeight;}  

	/*   
	//On every new render
	useLayoutEffect (() => {
			let mounted=true;
			darkModeRef.current = darkMode;
			let box = pageRef.current.getBoundingClientRect();
			if (DEBUG_MODE >= 2)console.log("DISPLAY CONTEXT CALCULATING BOUNDING BOX", box);
			if (mounted && box.height !== pageHeight) setPageHeight(box.height); 
			if (mounted && box.width !== pageWidth) setPageWidth(box.width); 

			//Cleanup
			return () => {
				mounted = false;
			};
			
	},[]);
	*/

	const toggleDarkMode = useCallback(() => {       
			if (DEBUG_MODE >= 2) console.log("Display Context: Toggling Dark Mode", !darkMode);
			setDarkMode(!darkMode);     
	},[darkMode]);

	//return the provider
	return (				
		<div  className={`pageWrapper ${!darkMode ? "" : "darkMode"}`} ref={pageRef}>

				<DisplayContext.Provider value={
				{   
						//Display State
						currentReactPage, pageRef,
						innerWindowWidth, innerWindowHeight, isMobileViewPort, isTabletViewPort,
						darkMode, toggleDarkMode,
						isRenderedWithinMsftClient, clientDisplayModeDetected,

						//Spinners
						showSpinner, setShowSpinner

				}}>

					{children}
					{/* <SpinnersConectere showSpinner={showSpinner}/> */}
					<LoadingView showSpinner={showSpinner}/>
				</DisplayContext.Provider>
		</div>		
	); 
};


export { DisplayContext, DisplayProvider };
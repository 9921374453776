//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOLD } from '../../data/conectereConfigData';

//React & Amplify
import React from 'react';

//Icons
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

//React component
const StarRatings = ({readOnly, rating, starColor, setRating, starSize, className}) => {
    
    const MIN_STARS = 1;
    const MAX_STARS = 5;

    //Initialize props
   if (!rating)  rating = 0;
   if (!starColor) starColor = COLOR_GOLD;

   //Set our font className based on any input size in px
   if (!className) className = "TextStyle2"
   if (starSize === "16px") className = "TextStyle3";
   if (starSize === "28px") className = "TextStyle4";

   //  if (DEBUG_MODE >= 2) console.log("Star Ratings called", readOnly, rating, starColor, setRating )

    //Handler for clicking on stars
     const handleSetStars = (e, userEnteredValue) => {
        e.stopPropagation();        //prevent OnClick from firing parent DIV onClick
        if (DEBUG_MODE >= 2) console.log("Handle star click", userEnteredValue);
        if (setRating !== null) setRating(userEnteredValue);
     };

    //Handler for clicking on stars
     const handleClearStars = () => {
      
        // if (DEBUG_MODE >= 2) console.log("Handle CLEAR star click");

        if (setRating !== null) setRating(0);
        
     };
     
    //This function sets the color of the star based on its position and the current value of the rating as set by the user     
     function setStarColor(starPostion) {
         if (starPostion <= rating) return starColor;
         else return "grey";
     }
     

	function renderReadOnlyStars(numStars, starColor, className) {
		if (!numStars || !numStars > 0) return null;
		// if (DEBUG_MODE) console.log("Rendering read only stars", numStars, starColor, starSize);
		const numberofWholeStars = Math.floor(numStars);
		let componentsToShow = [];
		for (var i=0; i<numberofWholeStars; i++) {
			componentsToShow.push(<StarIcon key={i.toString()} className={className} style={{color:starColor}} />)
		}
		if (numStars > numberofWholeStars) componentsToShow.push(<StarHalfIcon key="half" className={className}  style={{color:starColor}} />)
		// if (DEBUG_MODE) console.log("Generated Star components", componentsToShow);
		return (
			<div className="ContainerNoHeightFlexLeft starRatings">
					{componentsToShow}
			</div>
		);       
     }
    //For READ ONLY, only show the correct number of stars, at least for now
    if (readOnly) {
        //Set the number of stars based on rating from 1-5 precision 2 decimal
    
        var numStars = 0; //default to 0 stars
        
        if (rating > 0  && rating < .7) numStars = 0.5;
        else if (rating >= .7  && rating < 1.2) numStars = 1;
        else if (rating >= 1.2  && rating < 1.7) numStars = 1.5;
        else if (rating >= 1.7  && rating < 2.2) numStars = 2;
        else if (rating >= 2.2  && rating < 2.7) numStars = 2.5;
        else if (rating >= 2.7  && rating < 3.2) numStars = 3;
        else if (rating >= 3.2  && rating < 3.7) numStars = 3.5;
        else if (rating >= 3.7  && rating < 4.2) numStars = 4;   
        else if (rating >= 4.2  && rating < 4.7) numStars = 4.5;
        else if (rating >= 4.7 ) numStars = 5;    
        
        // if (DEBUG_MODE >= 2) console.log("stars", numStars);
        return renderReadOnlyStars(numStars, starColor, className);
    } 
     
    //   Added padding to allow room for the user to click outside stars and CLEAR them
    return(
		<div className="ContainerNoHeightFlexLeft starRatings" onClick={handleClearStars} style={{paddingTop:"5px 5px", cursor:(readOnly ? 'default' : 'pointer')}}>
			<StarIcon onClick={(e) => handleSetStars(e, 1)} className={className} style={{color:setStarColor(1)}} />
			<StarIcon onClick={(e) => handleSetStars(e, 2)}  className={className} style={{color:setStarColor(2)}} />
			<StarIcon onClick={(e) => handleSetStars(e, 3)} className={className} style={{color:setStarColor(3)}} />
			<StarIcon onClick={(e) => handleSetStars(e, 4)} className={className} style={{color:setStarColor(4)}} />
			<StarIcon onClick={(e) => handleSetStars(e, 5)} className={className} style={{color:setStarColor(5)}} />
		</div>  
	);
}
export default StarRatings;



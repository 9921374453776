//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//


// https://jakearchibald.com/2013/animated-line-drawing-svg/
// https://css-tricks.com/building-progress-ring-quickly/

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR, SOCIAL_COLOR, GROWTH_COLOR, BALANCE_COLOR, } from '../../../../shared/data/conectereConfigData';

//Amplify, React
import React, { useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

//Context
import { DisplayContext } from '../../../../shared/context/displayContext';     

//React component
const CCRingBackgroundAnimated = ({color, radius, strokeWidth, normalizedRadius}) => {
    
    //Default if using PROP color
    var strokeOpacity = "20%";
    
    //Uncomment these two lines if going for more constrast for the non-completed part of the ring
    color = color;
    strokeOpacity = "25%";
    
    return (
        <svg   
            height={radius * 2}
            width={radius * 2}
            >
            <circle  
                cx="50%" cy="50%"
                stroke= {color}
                fill="none"
                strokeWidth={strokeWidth}
                strokeOpacity={strokeOpacity}
                r={normalizedRadius} 
                transform-origin="50%"
                transform=" scale(1) rotate(-90)"
                />
        </svg>          
    );

};

//React component
const CCRingForegroundAnimated = ({color, radius, progress, strokeWidth, normalizedRadius, circumference}) => {

    // if (DEBUG_MODE >= 2) console.log("Animated ring called.", color, radius, strokeWidth, normalizedRadius, progress, circumference);
    

    const strokeOpacity = "100%";
    const strokeDashoffset = circumference - progress / 100 * circumference;
    
    return (
        <svg   
            height={radius * 2}
            width={radius * 2}
        >
            
            <circle
                className='animatedProgressRing'
                cx="50%" cy="50%"
                stroke= {color}
                fill="none"
                strokeDasharray={circumference + ' ' + circumference }
                style={ { strokeDashoffset } }
                strokeWidth={strokeWidth}
                strokeOpacity={strokeOpacity}
                r={normalizedRadius} 
                transform-origin="50%"
                transform=" scale(1) rotate(-90)"
                stroke-linecap="round"
                />
        </svg>          
    );

};

//React component
const CCRingAnimated = ({progress, color, fillColor, centerImage, imageH, imageW}) => {

    //progress must be a value from 0 to 100
    var internalProgress = progress;
    if (progress < 0) internalProgress = 0;
    if (progress > 100) internalProgress = 100;
    
    
    //Our ref to track DIV width
    const containerDivRef = useRef(null);
    const [radius, setRadius] = useState(0);
    const [normalizedRadius, setNormalizedRadius] = useState(0);
    const [circumference, setCircumference] = useState(0);
    const [strokeWidth, setStrokeWidth] = useState(0);

    
    useEffect( () => {
        calcRadius();
    });
    

    function calcRadius() {
        
        // if (DEBUG_MODE >= 2) console.log("Updating ring radius", containerDivRef);
        
        if (containerDivRef && containerDivRef.current && containerDivRef.current.clientWidth > 0) {
            
            const shortestDivDimension = (containerDivRef.current.clientWidth < containerDivRef.current.clientHeight ? containerDivRef.current.clientWidth : containerDivRef.current.clientHeight)
            const newRadius = Math.round(shortestDivDimension / 2);
            const newStrokeWidth = Math.round(shortestDivDimension / 12);    //stroke in pixels
            const newNormalizedRadius = newRadius - (newStrokeWidth / 1.5);                      //account for exactly half the width of our pen, which draws the ring exactly at the radius
            const newCircumference = normalizedRadius * 2 * Math.PI;             
            // if (DEBUG_MODE >= 2) console.log("NEW ring radius", newRadius, normalizedRadius, newStrokeWidth, newCircumference);
            
            setRadius(newRadius);
            setNormalizedRadius(newNormalizedRadius);
            setStrokeWidth(newStrokeWidth);
            setCircumference(newCircumference);
           
        }  else {
            // if (DEBUG_MODE >= 2) console.log("Setting ring radius to zero");
            return (0);
        }      
    }
    
    // const endAngle2=90-(360*internalProgress / 100);  //Compute number of degrees to change.  This is negative so we rotate clockwise
 
    // if (DEBUG_MODE >= 2) console.log("end angle", endAngle2);
    
    // // if (DEBUG_MODE >= 2) console.log("data", data);
    // // if (DEBUG_MODE >= 2) console.log("dataKey", dataKey);
    // if (DEBUG_MODE >= 2) console.log("colors array", color);
    // // if (DEBUG_MODE >= 2) console.log("fillColor", fillColor);
    // // if (DEBUG_MODE >= 2) console.log("innerRadius", innerRadius);
    // // if (DEBUG_MODE >= 2) console.log("outerRadius", outerRadius);
    // if (DEBUG_MODE >= 2) console.log("startAngle", startAngle);
    // if (DEBUG_MODE >= 2) console.log("endAngle", endAngle);


    //This is the original code for displaying the image rather than a %.
    //If desired, move this down into the ring below and uncomment
    //   <img height={imageH} width={imageW} src={process.env.PUBLIC_URL+"/img/"+centerImage} />


    // const duration = 10000; 

    return (
        <div className="ccAnimatedRing" ref={containerDivRef}>
            <div className="ContainerVerticalStart fullWidth ccAnimatedRingBgRing">
                <CCRingBackgroundAnimated color={color} radius={radius} strokeWidth={strokeWidth} normalizedRadius={normalizedRadius} />
            </div>
            <div className="ContainerVerticalStart fullWidth ccAnimatedRingForegroundRing">
                <CCRingForegroundAnimated color={color} radius={radius} strokeWidth={strokeWidth} normalizedRadius={normalizedRadius} circumference={circumference} progress={internalProgress}/>
            </div>
            <div className="TextStyle8V2 bold-800 ccRingPercent ContainerVerticalCenter" style={{color:color}}>
                {internalProgress === 100 ? <span>&#127942;</span> : null}
                {internalProgress}%
            </div>

        </div>
    );

}

const Streak = ({color, streak}) => {
    if (streak == null) return null;
    if (streak < 1) return null;
    return (
        <div className={`ContainerNoHeightFlexEnd conectivityRingsStreakLabel`}>
            <div className="TextStyle4V2"  style={{color:color}}>{streak}</div>
            <LocalFireDepartmentIcon style={{color:color}} className="TextStyle5V2"/>
        </div>        
    )
}

//Ring card

const ConnectivityRingCard = ({title, period, closingDate, coinsEarned, coinGoal, streak, progress, category, isLeftRing}) => {

    // Display context
	const { darkMode } = useContext(DisplayContext); 

    let image, color, wrapperClass='ContainerNoHeightFlexLeft maxHeight positionRelative connectivityProgressCardWrapper';
    switch (category) {
        case "BALANCE":
            image=CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH; color=BALANCE_COLOR; wrapperClass=wrapperClass;
            break;
        case "TEAM":
            image=CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH; color=TEAM_COLOR; wrapperClass=wrapperClass;
            break;
        case "SOCIAL":
            image=CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH; color=SOCIAL_COLOR; wrapperClass=wrapperClass;
            break;
        case "GROWTH":
            image=CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH; color=GROWTH_COLOR; wrapperClass=wrapperClass;
            break;
        default:
            image=CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH; color=BALANCE_COLOR; wrapperClass=wrapperClass;
            break;
    }

    return (
        <div className="ContainerNoHeightFlexLeft fullWidth" >
            <div className={wrapperClass}>
                <div className={isLeftRing ? 'ringCardOrder1' : 'ringCardOrder3'}>
                    <div className='ccAnimatedRingOuterWWrapper'>
                        <CCRingAnimated progress={progress} color={color} fillColor={color}  imageH="96px" imageW="107px" centerImage="yoga3.png"/>
                        <Streak color={color} streak={streak} />
                    </div>
                </div>
                <div className="ContainerVerticalCenter maxHeight ringCardOrder2">
                    <img className="connectivityRingCardImage" src={image} alt=''/>
                </div>
                <div className={`ContainerNoHeightCenter fullWidth ${isLeftRing ? 'ringCardOrder3' : 'ringCardOrder1'}`}>
                    <div className="connectivityRingInfoInnerWrapper">
                        <span className="ringInfoTitle TextStyle6V2" style={{ color: color }}> {title} </span>
                        <div className="ContainerVerticalStartStart">
                            <div className="TextStyle3V2 black-text"> Ends&nbsp;{`(${closingDate})`}</div>
                            <div className="ContainerNoHeightCenter TextStyle3V2 black-text"> <img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> &nbsp;{ coinsEarned + " / " + coinGoal} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

//Our main React component
const CCRingsAnimatedView = ({userProgressData}) => {
    
    if (userProgressData == null) return null;

    // transform ring closing time into a Moment time object
    const closeDateStressMoment = moment(userProgressData.stressPeriodEnd, "YYYY MM DDTHH mm ssZ");  
    const closeDateStress = closeDateStressMoment.format("MMM Do");
    const closeDateTeamMoment = moment(userProgressData.teamPeriodEnd, "YYYY MM DDTHH mm ssZ");  
    const closeDateTeam = closeDateTeamMoment.format("MMM Do");
    const closeDateIndividualMoment = moment(userProgressData.individualPeriodEnd, "YYYY MM DDTHH mm ssZ");  
    const closeDateIndividual = closeDateIndividualMoment.format("MMM Do");
    const closeDateSocialMoment = moment(userProgressData.socialPeriodEnd, "YYYY MM DDTHH mm ssZ");  
    const closeDateSocial = closeDateSocialMoment.format("MMM Do");

    // if (DEBUG_MODE >= 2) console.log("CCRings called.  Props=", userProgressData);
    
    var balanceProgressAmount=0;
    var teamProgressAmount=0;
    var growthProgressAmount=0;
    var socialProgressAmount=0;
        

    //compute the current progresss data as an INT from 00 to 100    
    if (userProgressData) {
        
        // if (DEBUG_MODE >= 2) console.log("Calculating new goal.");

        balanceProgressAmount = Math.ceil(100*userProgressData.stressPeriodCoinsEarned / userProgressData.stressPeriodCoinGoal);
        teamProgressAmount = Math.ceil(100*userProgressData.teamPeriodCoinsEarned / userProgressData.teamPeriodCoinGoal);
        growthProgressAmount = Math.ceil(100*userProgressData.individualPeriodCoinsEarned / userProgressData.individualPeriodCoinGoal);
        socialProgressAmount = Math.ceil(100*userProgressData.socialPeriodCoinsEarned / userProgressData.socialPeriodCoinGoal);
    
        // if (DEBUG_MODE >= 2) console.log("New progress data", balanceProgressAmount, teamProgressAmount, growthProgressAmount, socialProgressAmount);
    }

    //Grab current coins earned in each category
    var stressPeriodCoinsEarnedToDisplay = userProgressData.stressPeriodCoinsEarned < userProgressData.stressPeriodCoinGoal ? userProgressData.stressPeriodCoinsEarned : userProgressData.stressPeriodCoinGoal;
    var teamPeriodCoinsEarnedToDisplay = userProgressData.teamPeriodCoinsEarned < userProgressData.teamPeriodCoinGoal ? userProgressData.teamPeriodCoinsEarned : userProgressData.teamPeriodCoinGoal;
    var socialPeriodCoinsEarnedToDisplay = userProgressData.socialPeriodCoinsEarned < userProgressData.socialPeriodCoinGoal ? userProgressData.socialPeriodCoinsEarned : userProgressData.socialPeriodCoinGoal;
    var individualPeriodCoinsEarnedToDisplay = (userProgressData.individualPeriodCoinsEarned < userProgressData.individualPeriodCoinGoal ? userProgressData.individualPeriodCoinsEarned : userProgressData.individualPeriodCoinGoal);

    //Set the boundaries so as not to show above / below these limits
    if (balanceProgressAmount > 100) balanceProgressAmount = 100;
    if (teamProgressAmount > 100) teamProgressAmount = 100;
    if (growthProgressAmount > 100) growthProgressAmount = 100;
    if (socialProgressAmount > 100) socialProgressAmount = 100;

    if (balanceProgressAmount < 0) balanceProgressAmount = 0;
    if (teamProgressAmount < 0) teamProgressAmount = 0;
    if (growthProgressAmount < 0) growthProgressAmount = 0;
    if (socialProgressAmount < 0) socialProgressAmount = 0;
    
    if (stressPeriodCoinsEarnedToDisplay < 0) stressPeriodCoinsEarnedToDisplay = 0;
    if (teamPeriodCoinsEarnedToDisplay < 0) teamPeriodCoinsEarnedToDisplay = 0;
    if (socialPeriodCoinsEarnedToDisplay < 0) socialPeriodCoinsEarnedToDisplay = 0;
    if (individualPeriodCoinsEarnedToDisplay < 0) individualPeriodCoinsEarnedToDisplay = 0;


    return (
        <div className="ContainerVerticalStart wrap fullWidth userHomeRingsWrapper">

            <div className="ContainerNoHeightSpaceBetween nowrap fullWidth animatedRingsInnerWrapper">

                <ConnectivityRingCard 
                    title="Balance" progress={balanceProgressAmount} period={userProgressData.stressPeriod} closingDate={closeDateStress} 
                    coinsEarned={stressPeriodCoinsEarnedToDisplay} coinGoal={userProgressData.stressPeriodCoinGoal} 
                    streak={userProgressData.balanceStreakCount} category="BALANCE"
                    isLeftRing={false}
                />

                <ConnectivityRingCard 
                    title="Team" progress={teamProgressAmount} period={userProgressData.teamPeriod} closingDate={closeDateTeam} 
                    coinsEarned={teamPeriodCoinsEarnedToDisplay} coinGoal={userProgressData.teamPeriodCoinGoal} 
                    streak={userProgressData.teamStreakCount} category="TEAM"
                    isLeftRing={false}
                />
            </div>
            <div className="ContainerNoHeightSpaceBetween nowrap fullWidth animatedRingsInnerWrapper">

                <ConnectivityRingCard 
                    title="Social" progress={socialProgressAmount} period={userProgressData.socialPeriod} closingDate={closeDateSocial} 
                    coinsEarned={socialPeriodCoinsEarnedToDisplay} coinGoal={userProgressData.socialPeriodCoinGoal} 
                    streak={userProgressData.socialStreakCount} category="SOCIAL"
                    isLeftRing={false}
                />

                <ConnectivityRingCard 
                    title="Growth" progress={growthProgressAmount} period={userProgressData.individualPeriod} closingDate={closeDateIndividual} 
                    coinsEarned={individualPeriodCoinsEarnedToDisplay} coinGoal={userProgressData.individualPeriodCoinGoal} 
                    streak={userProgressData.individualStreakCount} category="GROWTH"
                    isLeftRing={false}
                />
            </div>

        </div>
   );
}


export default CCRingsAnimatedView;



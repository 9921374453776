//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//


import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, COLOR_WHITE, SPOTLIGHT_MESSAGE_SIZE_LIMIT, BALANCE_COLOR, SOCIAL_COLOR, GROWTH_COLOR, SPOTLIGHT_INITIAL_STATE, SPOTLIGHT_DAYS_TO_EXPIRATION, 
            SPOTLIGHT_AMOUNT_SENDER, SPOTLIGHT_AMOUNT_RECEIVER, SPOTLIGHT_TRANSACTION_TITLE_SENDER, SPOTLIGHT_TRANSACTION_MEMO_SENDER, SPOTLIGHT_TRANSACTION__IMAGE,
            SPOTLIGHT_TRANSACTION_TITLE_RECIPIENT, SPOTLIGHT_TRANSACTION_MEMO_RECIPIENT, 
            GIPHY_SDK_KEY_WEB, GIPHY_ID_IDENTIFIER,
} from '../data/conectereConfigData';

//React & Amplify
import React, { useCallback, useEffect, useState, useContext, useRef, useLayoutEffect } from "react";
import { generateClient } from 'aws-amplify/api';   // Amplify V6

//Icons

//Context
import { AuthContext } from './authContext';
import { NotificationContext } from './notificationsContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context
import { ModalContext } from '../../shared/context/modalContext';            
import { DisplayContext } from './displayContext';            //User Authentication Context

//Queries and Mutations
import { getRecognitionsByCustomerByExpirationDate, } from '../graphql/queries';

//Subscriptions
// https://docs.amplify.aws/lib/graphqlapi/subscribe-data/q/platform/js/
import { onUpdateRecognitionForCustomer, onDeleteRecognitionByCustomer } from "../graphql/subscriptions";

//Our components
import ModalNoBackground from "../Components/modalNoBackground/modalNoBackground";
import ModalNoBackgroundFixed from "../Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import { SpotlightSendTool} from "../Components/spotlightSendTool/spotlightSendTool";
import { SpotlightCard } from "../Components/spotlightCard/spotlightCard";
import SpotLightInfoViewer from '../Components/spotlightInfoViewer/spotlightInfoViewer.js';

//Other components
import Button from 'react-bootstrap/Button';

//Utils
import { ConectereDivider, sortMessages, timeAgo, truncateStringWithDots } from "../utils/generalUtils";
import { queryDataTableWithPagination} from "../utils/databaseUtils";
import { setHeaderColorByStringLength } from "../utils/conectivityUtils";
import { drawSpotlightCurvedText, displayRecognizedUsersAvatars, invokeDeleteSpotlight } from "../utils/spotlightUtils.js";
import { ONE_MONTH_AGO, ONE_YEAR_AGO_STRING} from "../utils/dateTimeUtils.js";
import { getUserInitials } from "../utils/userAndTeamUtils";

//Material UI 

// import parse from "html-react-parser";              //Needed to render HTML from the Editor
import moment from 'moment';


const API = generateClient(); //Amplify V6
const SpotlightContext = React.createContext();
    
const SpotlightProvider = ({ children }) => {

   // Context
    const {  authState, currentUser }  = useContext(AuthContext);
    const { usersOptions,  } = useContext(CustomerContext);   
    const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 
	const { setShowSpinner } = useContext(DisplayContext); 


    //state variables for managing recognitions for the current user
    //these will all be available to the the wrapped components
    
    const [recognitions, setRecognitions] = useState([]);
    const [spotlightsLoaded, setSpotlightsLoaded] = useState(false);
    const [previewRecognitions, setPreviewRecognitions] = useState([]);
    const [recognitionTypes, setRecognitionTypes] = useState([]);
    const [recognitionTypesLoaded, setRecognitionTypesLoaded] =useState(false);
    // const [showModalRecognitions, setShowModalRecognitions] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [isUpdatingSpotlight, setIsUpdatingSpotlight] = useState(false);                          //Used as a state variable set to TRUE when updating the backend
    // const [spotlightToEdit, setSpotlightToEdit] = useState(SPOTLIGHT_INITIAL_STATE);              //Used to hold a spotlight that is to be edited
    // const [spotlightToDelete, setSpotlightToDelete] = useState(SPOTLIGHT_INITIAL_STATE);              //Used to hold a spotlight that is to be edited
    const [spotlightEditMode, setSpotlightEditMode] = useState("");
    
    //variable to control confirmation Modal if spotlight presses DELETE; default to hidden
    const [showSendSpotlightModal, setShowSendSpotlightModal] = useState(false);

    const [spotlightToProcess, setSpotlightToProcess] = useState(SPOTLIGHT_INITIAL_STATE);

    const [showSpotlightDetailed, setShowSpotlightDetailed] =useState(false);        //display Modal of full Spotlight
    const [numSpotlightsThisMonthForUser, setNumSpotlightsThisMonthForUser] = useState(0);

    //Set up subscriptions to receive user recognitions upon creation and updates for this user
    // Note, we need to make use either Functional State Data Updates or use Ref() to get access to current values of state data
    // since this UseEffect call is called upon mount (Auth change)
    // https://reactjs.org/docs/hooks-reference.html#functional-updates
    //https://stackoverflow.com/questions/69285519/access-latest-value-of-state-in-aws-amplify-subscriptions-react

    const recognitionsRef = useRef();  //Defining a React Reference so that we can use the current state data during this useEffect call
    const previewRecognitionsRef = useRef();
    const userOptionsRef = useRef();   
    const spotlightToProcessRef = useRef();  //Defining a React Reference so that we can use the current state data during this useEffect calls
    



    //
    // SPOTLIGHT DATA AQUISITION - Get data when user authentication changes and user Options are set since we need to fill in user data for rendering, such as Avatar URL
    //
    
    useEffect(() => {
        if (authState === "signedin" && currentUser) fetchSpotlightData();
    },[authState]);
    
    async function fetchSpotlightData() {
        
        //Get Recognition Types
        try {
        
            // grab all RecognitionTypes
            // var RecognitionTypesData = await API.graphql(graphqlOperation(listRecognitionTypes));
            // var tempRecognitionTypes= RecognitionTypesData.data.listRecognitionTypes.items;
            
            // if (DEBUG_MODE >= 2) console.log("Fetched Recognition Types:", tempRecognitionTypes);
            
            //Sort the RecognitionTypes
            // tempRecognitionTypes.sort(compareRecognitionTypes);
              
            // setRecognitionTypes(tempRecognitionTypes);
            setRecognitionTypesLoaded(true);
 
           //Load Recognitions for this company
           await loadUserRecognitions();

        } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching Spotlights', err); }        
    }

/*
     function activeRecognition(recognition) {
         // if (DEBUG_MODE >= 2) console.log("Checking whether the recognition is active or has expired", recognition);
        
         if (recognition.readByUser) return false; //Not active since already read
        
         // get time set in user's computer time / OS
         const currentDateTime = moment(); 
        
          // transform expiration time into a Moment time object
         const expirationDateTime = moment(recognition.expiration, "YYYY MM DDTHH mm ssZ");
       
         if (expirationDateTime.isBefore (currentDateTime)) return false;  //not active since expired
       
         return true; //still active; not expired
     }
*/    
    async function loadUserRecognitions () {
 
        
            const todayString = moment().startOf('day').toISOString();
            
            try {
        
                 //Now, grab active recognitions for this customer
        
                const queryParams = {
                   customerID: currentUser.customerID,             //Primary Key
                //    expiration: {ge: todayString},                 //Secondary key
                   exipiration: {ge: ONE_YEAR_AGO_STRING},
                   limit: 1000,
               };
                
               var  tempRecognitions =  await queryDataTableWithPagination(getRecognitionsByCustomerByExpirationDate, "getRecognitionsByCustomerByExpirationDate", queryParams);
               
               if (DEBUG_MODE >= 2) console.log("Fetched recognitions since " + todayString, tempRecognitions);
               
               //Sort the Recognitions
               tempRecognitions.sort(compareRecognitions);
               if (DEBUG_MODE >= 2) console.log("Sorted array of Spotlights", tempRecognitions);
               
               //Pre-process each loaded Spotlight prior to rendering so all user data is current
               for (var l=0; l < tempRecognitions.length; l++) {
                  preProcessSpotlight(tempRecognitions[l]);
               }
               
               setRecognitions(tempRecognitions);
               //Geneated reduced list for preview on Home page, only include recent Spotlights
               var tempPreviewSortedRecognitions = tempRecognitions.filter(sp=>{
                    return moment().subtract(1,'month').isBefore(moment(sp.createdAt));     //is 1 month ago before the spotlight was created?
                }); 

                if (DEBUG_MODE >= 2) console.log("Generated Spotlights to preview", tempPreviewSortedRecognitions);
                setPreviewRecognitions(tempPreviewSortedRecognitions);
               if (DEBUG_MODE >= 2) console.log("Active Recognitions", tempRecognitions);
            } catch (err) {
                if (DEBUG_MODE >= 2) console.log("Error fetching Recognitions", err);
                return;
            }
            
            setSpotlightsLoaded(true);
    }

    //Update Spotlight with current user data prior to rendering
    function preProcessSpotlight(spotlight) {

        // if (DEBUG_MODE >= 2) console.log("Preprocessing spotlight", userOptionsRef.current, spotlight);
 
        if (userOptionsRef.current && userOptionsRef.current.length > 0) {
            
        
             //Populate the avatar data based on user IDs to accomodate any user profile changes, such as names or pictures
             var tempUser = userOptionsRef.current.find(user => user.id == spotlight.senderID);    //Find SENDER
             if (tempUser) {
            	 spotlight.senderAvatarUrl = tempUser.avatarUrl;     
            	 spotlight.senderAvatarName = tempUser.name;
            	 spotlight.senderAvatarInitials = tempUser.avatarInitials;
            	 
            // 	 if (DEBUG_MODE >= 2) console.log("Updated Sender", tempUser);
             }
             
            //Populate data for each recognized employee based on ID
            if (spotlight.recognizedEmployees && spotlight.recognizedEmployees.items) {
                
                spotlight.recognizedEmployees.items.forEach(recognizedEmployee => {
    
                      //Populate the avatar data based on user IDs to accomodate any user profile changes, such as names or pictures
                     tempUser = userOptionsRef.current.find(user => user.id == recognizedEmployee.recognizedUserID);    //Find RECOGNIZED USER
                     if (tempUser) {
                    	 recognizedEmployee.recognizedUserAvatarUrl = tempUser.avatarUrl;     
                    	 recognizedEmployee.recognizedUserAvatarName = tempUser.name;
                    	 recognizedEmployee.recognizedUserAvatarInitials = tempUser.avatarInitials;
                     }
                     
                //  	 if (DEBUG_MODE >= 2) console.log("Updated Recipient", tempUser);
    
                });
            }
        }

         //Sort the messages in each Spotlight pre-render
        const tempMessageList = [...spotlight.messages.items];
        const sortedMessages = sortMessages(tempMessageList);
        spotlight.messages.items = [...sortedMessages];        
    }


   //
   // SPOTLIGHT SUBSCRIPTIONS
   //
   
    useLayoutEffect(() => {
      recognitionsRef.current = recognitions; 
      previewRecognitionsRef.current = previewRecognitions;
      spotlightToProcessRef.current = spotlightToProcess;
      userOptionsRef.current = usersOptions;
    });   //Update on every display change
    
    // NOT USED SINCE NEED RECOGNIZED USERS TO HAVE BEEN CREATED; THUS USING UPDATE SUBSCRIPTION
 
 /*   
    //Functions and state data for real-time updates
     const [recognitionSubscriptionSetUp, setRecognitionSubscriptionSetUp] = useState(false);  //record once we have established the web socket
    
     useEffect(() => {
         if (!recognitionSubscriptionSetUp && authState == 'signedin'  && currentUser) {
            
             if (DEBUG_MODE >= 2) console.log("Setting up subscription for recognitions created for customer", currentUser.customerID);
            
           const subscription = API.graphql({
                 query:onCreateRecognitionForCustomer,
                 variables: {
                         customerID: currentUser.customerID,
                     }
             })
             .subscribe ({
                 next: messageData => {
                     try {
                         if (DEBUG_MODE >= 2) console.log("New recognition data received via subscription",messageData);
                         if (messageData.data.onCreateRecognitionForCustomer !== null) {
                            
                             const newRecognition = messageData.data.onCreateRecognitionForCustomer;
                            
                             if (DEBUG_MODE >= 2) console.log("New recognition extracted from message",newRecognition);
                            
                             const tempRecognitions = [...recognitionsRef.current];   //Get our state data for existing recognitions

                              if (DEBUG_MODE >= 2) console.log("Current recognitions",tempRecognitions);

                             tempRecognitions.push(newRecognition);    //Add the new recognition
                            
                             //Update system-wide state data with the new recognitions
                            
                           //Not needed, but reduce to only any those that are active and the user has not marked as READ
                             const filteredRecognitions = tempRecognitions.filter(activeRecognition);
                            
            
                             //Sort the Recognitions      
                             filteredRecognitions.sort(compareRecognitions);

                             setRecognitions(filteredRecognitions);
                            
                             if (DEBUG_MODE >= 2) console.log("Active Recognitions after CREATE from Subscription", filteredRecognitions);
                            
                          }
                     } catch (err) {
                         if (DEBUG_MODE >= 2) console.log("Error processing recognition create subscription message",messageData);

                     }
                 }
             });
            
             setRecognitionSubscriptionSetUp(true);
           return () => {
                 subscription.unsubscribe();
                 setRecognitionSubscriptionSetUp(false);
                 if (DEBUG_MODE >= 1) console.log("Tearing down subscription for CREATE RECOGNITIONS");
             };
 
         }
     },[authState]);                     //Call function when a change to authState occurs
*/      

	//Note - an update is performed when a Recognition is first created and then updated after the Recognized user records have been created
	//So, we need to account for NEW items
	const [recognitionUpdateSubscriptionSetUp, setRecognitionUpdateSubscriptionSetUp] = useState(false);  //record once we have established the web socket
	
	useEffect(() => {
		if (!recognitionUpdateSubscriptionSetUp && authState == 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for UPDATES for recognitions for this customer", currentUser.customerID);
			
		   const subscription = API.graphql({
				query:onUpdateRecognitionForCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						if (DEBUG_MODE >= 2) console.log("Update Recognition data received via subscription",messageData);
						if (messageData.data.onUpdateRecognitionForCustomer !== null) {
							const updatedRecognition = messageData.data.onUpdateRecognitionForCustomer;

							//Sort the messages in the recognition received via AppSync; sorted by Date with Replies inserted at correct locations
							if (updatedRecognition.messages.items) {
								const tempCommentList = [...updatedRecognition.messages.items];
								const sortedComments = sortMessages(tempCommentList);
								updatedRecognition.messages.items = [...sortedComments];
							}

							//Update the Spotlight user data so as to reflect current user avatars, etc.
							preProcessSpotlight(updatedRecognition);
							
							if (DEBUG_MODE >= 2) console.log("Recognition update extracted from message",updatedRecognition);
							const tempRecognitions = [...recognitionsRef.current];   //Get our state data for existing recognitions
							 if (DEBUG_MODE >= 2) console.log("Current recognitions",tempRecognitions);
							 const index = tempRecognitions.findIndex(recognition => recognition.id === updatedRecognition.id);
							 
							 if (index > -1) {

								//Update system-wide state data with the received object
								//Need to use the entire new object that includes the deep data fields instead of a shallow copy like {...}
								tempRecognitions[index] = updatedRecognition;    //Replace the existing recognition with the updated one just received
								if (DEBUG_MODE >= 2) console.log("Active Recognitions after UPDATE from Subscription", tempRecognitions);
								setRecognitions(tempRecognitions);
								
							 } else {
								 if (DEBUG_MODE >= 2) console.log("No matching recognition found to update; creating new recognition", updatedRecognition, tempRecognitions);

								//Add the recognition to the overall stack
								 tempRecognitions.push(updatedRecognition); //add the new object to our array
								 tempRecognitions.sort(compareRecognitions); //sort the spotlights themselves
								 setRecognitions(tempRecognitions);
								 
								//  if (DEBUG_MODE >= 2) console.log("Created new recognition", updatedRecognition, tempRecognitions);
							 }

							//Generate reduced list for preview on Home page, only include recent Spotlights
							var tempPreviewSortedRecognitions = tempRecognitions.filter(sp=>{
								return moment().subtract(1,'month').isBefore(moment(sp.createdAt));     //is 1 month ago before the spotlight was created?
							}); 
							
							if (DEBUG_MODE >= 2) console.log("Generated Spotlights to preview", tempPreviewSortedRecognitions);
							setPreviewRecognitions(tempPreviewSortedRecognitions);                           
 
							  //Currently displaying this spotlight? If so, update it with new messages
							if (updatedRecognition.id == spotlightToProcessRef.current.id) {
								setSpotlightToProcess(updatedRecognition);        
								if (DEBUG_MODE >= 2) console.log("Updating spotlight crrently being viewed", updatedRecognition);
							}
						   
						 }
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing recognition update subscription message",err, messageData);

					}
				},
				error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
			});
			
			setRecognitionUpdateSubscriptionSetUp(true);
		   return () => {
				subscription.unsubscribe();
				setRecognitionUpdateSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for UPDATE RECOGNITIONS");
			};
 
		}
	},[authState]);                     //Call function when a change to authState occurs
	
	const [recognitionDeleteSubscriptionSetUp, setRecognitionDeleteSubscriptionSetUp] = useState(false);  //record once we have established the web socket

	//Subscribe to deletion of a Spotlight for this customer; to be pushed in real-time by Appsync     
	useEffect(() => {
		if (!recognitionDeleteSubscriptionSetUp && authState == 'signedin'  && currentUser) {
			
			if (DEBUG_MODE >= 2) console.log("Setting up subscription for DELETE Spotlight", currentUser.customerID);
			
			const subscription = API.graphql({
				query:onDeleteRecognitionByCustomer,
				variables: {
						customerID: currentUser.customerID,
					}
			})
			.subscribe ({
				next: messageData => {
					try {
						// if (DEBUG_MODE >= 2) console.log("Subscription message received for DELETE USER",messageData);
						if (messageData.data.onDeleteRecognitionByCustomer !== null) {
							
							const deletedRecognition = {... messageData.data.onDeleteRecognitionByCustomer };
							if (DEBUG_MODE >= 2) console.log("Deleted Recognition extracted from message",deletedRecognition);
							// Delete the recognition from state data
							removeRecognitionFromStateData(deletedRecognition);
						 }
					} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error processing RECOGNITION subscription message",messageData);

					}
				},
				 error: error => {if (DEBUG_MODE >=1) console.log("AppSync subscription error", error, subscription)}
		   });
			
			setRecognitionDeleteSubscriptionSetUp(true);
			//Important -  return function for execution on unmount for cleanup
			return () => {
				subscription.unsubscribe();
				setRecognitionDeleteSubscriptionSetUp(false);
				// if (DEBUG_MODE >= 1) console.log("Tearing down subscription for DELETE RECOGNITION");
			};

		}
	},[authState]);


   function removeRecognitionFromStateData(deletedRecognition) {

      //Safety check
      if (!deletedRecognition || !deletedRecognition.id) {
         if (DEBUG_MODE >= 1) console.error("Error - improper params for deleting recognition from local state", deletedRecognition);
         return;
      }
   
      try {     
         //Remove the matching recognition from local state and also local recognitionRows.  Note, this technique does not update the state array directly like "splice" would
         var tempRecognitions = recognitionsRef.current.filter(element => element.id != deletedRecognition.id);
         
         //Sort the new set of objects
         // tempRecognitions.sort(compareRecognitions);      //NO NEED TO SORT BECAUSE THE FILTERED ARRAY WILL STILL BE SORTED
         setRecognitions(tempRecognitions); //Delete state data with newly sorted list  
         
         //Remove from sorted preview recognitions as well; should remain sorted
         var tempPreviewRecognitions = previewRecognitionsRef.current.filter(element => element.id != deletedRecognition.id);
         setPreviewRecognitions(tempPreviewRecognitions);                            
         if (DEBUG_MODE >= 2) console.log("Deleted RECOGNITION from local state", currentUser.customerID, tempRecognitions, tempPreviewRecognitions);
      } catch (err) {
         if (DEBUG_MODE >= 0) console.error("Error deleting recognition from local state", err);
      }
      return;
}
   
    //Track whether the user has received a Spotlight in the last month
    //Used by Employee Store
    
    useEffect(() => {
        
        if (authState == "signedin" && recognitions && recognitions.length > 0) {
            
            
            const spotlightsThisUserInTheLastMonth = recognitions.filter( recognition => {
                const recognitionDate = moment(recognition.createdAt, "YYYY MM DDTHH mm ssZ");
                if (recognitionDate.isAfter(ONE_MONTH_AGO().startOf('day'))){
                    return (recognition.recognizedEmployees.items.find(recognizedUser => recognizedUser.recognizedUserID == currentUser.id)); 
                } else
                    return false;
            });
            
            // if (DEBUG_MODE >= 2) console.log("Spotlights over the last month for this employee", spotlightsThisUserInTheLastMonth);
            
            setNumSpotlightsThisMonthForUser(spotlightsThisUserInTheLastMonth.length);
        }

    },[recognitions]);
    
    
    
    //Functions for sorting RecognitionTypes based on last name & first name
    function compareRecognitionTypes(a, b) {
    
        if (a.recognitionType.toUpperCase() > b.recognitionType.toUpperCase()) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.recognitionType.toUpperCase() < b.recognitionType.toUpperCase()) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }


    //Functions for sorting Recognitions based when created
    function compareRecognitions (a, b) {
    
        if (a.createdAt < b.createdAt) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.createdAt > b.createdAt) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }
     

   const SpotlightsHeader = () => {
       
        return (
            <div className="ContainerNoHeightCenter">
                <div className="spotlightBannerHeader"  >
                    <div className="spotlightBannerInnerContainer" >
                        <div className = "ContainerVerticalCenter" style={{height:"100%"}}>
                            <div className="ContainerNoHeightCenter TextStyle7 spotlightBannerTitle">
                                {/* <HighlightIcon className="TextStyle7" style={{color:"gold", transform: "rotate(-145deg) translate(0,0px)"}}/> */}
                                <div className="TextStyle8" style={{color:SOCIAL_COLOR}}> SPOTLIGHTS </div>
                                {/* <HighlightIcon className="TextStyle7" style={{color:"gold", transform: "rotate(+145deg) translate(0,0px)"}}/> 
                                <div className="spotlightLightRing spotlightLightRingRight"></div> 
                                <div className="spotlightLightRing spotlightLightRingLeft"></div> */}
                            </div>
  
 
                            <div className="TextStyle4 spotlightBannerFootnote" ><i> Recognize colleagues in a positive way</i></div>
                            
                        </div>
                    </div>
                </div>
            </div>
    
            );
    }; 


    // const handleCastSpotlight = (e) => {

    //     e.stopPropagation();            //If on home page, prevent outer Card OnClick from firing and displaying full instruction modal

    //     setShowModalSendSpotlight(true); // Show the cast spotlight modal

    // };

    const handleCancel = () => {
        setShowSpotlightDetailed(false);
        // if (DEBUG_MODE >= 2) console.log("Cancel button called");
    };

    const handleEditSpotlightClick = (spotlight) => {
        
        setIsUpdatingSpotlight(true); //disable buttons on main page
        setSpotlightEditMode("EDIT");      
        setShowSendSpotlightModal(true);      
        
        if (DEBUG_MODE >= 2) console.log("Edit button pressed.  Edit Recognition=", spotlightToProcess, spotlight);

       if (spotlight) {
           setSpotlightToProcess(spotlight); /*If this function is called by a specific spotlight, set our parent state variable for processing */
           if (DEBUG_MODE >= 2) console.log("Setting spotlight to edit", spotlight);
       }
        
    };

    
    const editSpotlightPreviewCallback = useCallback((successFlag, completionMessage) => {
        
        // if (DEBUG_MODE >= 2) console.log("Spotlight Preview Callback invoked", successFlag, completionMessage);
        
        if (successFlag) {
            setModalGeneralMessage(completionMessage);
            setModalGeneralTitle("Success!");
            setShowModalGeneral(true);   
        } else {
           setModalGeneralMessage(completionMessage);
            setModalGeneralTitle("Ooops!");
            setShowModalGeneral(true);   
            
        }

        setShowSendSpotlightModal(false); //Close the edit modal
        setShowSpotlightDetailed(false);  //Close the Preview modal from which the SendTool was launched  
    },[]);
 
     const handleCancelEditSpotlightClick = useCallback((spotlight) => {
        setShowSendSpotlightModal(false);      //enable buttons on main page
        },[]);
        
    const handleDeleteSpotlightClick = useCallback((spotlight) => {
        // setShowSpotlightDetailed(false);
        setIsUpdatingSpotlight(true); //disable buttons on main page
        setShowModalDelete(true);         
        if (DEBUG_MODE >= 2) console.log("Delete button pressed.  Delete Recognition=", spotlightToProcess);
       if (spotlight) {
           setSpotlightToProcess(spotlight); /*If this function is called by a specific spotlight, set our parent state variable for processing */
           if (DEBUG_MODE >= 2) console.log("Setting spotlight to delete", spotlight);
       }

    },[]);
    
    const handleCancelDelete = useCallback(() => {
        // setShowSpotlightDetailed(true);
        setShowModalDelete(false);
        // if (DEBUG_MODE >= 2) console.log("Cancel button called");
        // setShowSpotlightDetailed(true);
    },[]);
    
    async function handleConfirmationDeleteSpotlight () {
        if (DEBUG_MODE >= 2) console.log("Deleting Spotlight:", spotlightToProcess);
        try {
          setShowSpinner(true); //Show spinners
          
          //Update our client's local state data to reflect the change
          removeRecognitionFromStateData(spotlightToProcess);
          
          //Invoke our backend Lamda to remove the Spotlight from the system
          const successFlag = await invokeDeleteSpotlight({spotlight:spotlightToProcess});
          if (DEBUG_MODE >= 2) console.log("Delete Spotlight returned:", successFlag);
          setShowSpinner(false); //Hode spinners
          setShowSpotlightDetailed(false);
          setShowModalDelete(false);
          if (successFlag) {
              setModalGeneralMessage('Spotlight deleted');
              setModalGeneralTitle("Success!");
              setShowModalGeneral(true);   
          } else {
              setModalGeneralMessage('Something went wrong and we could not delete the Spotlight');
              setModalGeneralTitle("Ooops!");
              setShowModalGeneral(true);   
          }
      } catch (err) {
          if (DEBUG_MODE) console.error("Error deleting Spotlight", err);
          setModalGeneralMessage('Something went wrong and we could not delete the Spotlight');
          setModalGeneralTitle("Ooops!");
      }
     }

     const spotlightClickCallback = useCallback((spotlight) => {
        if (DEBUG_MODE >= 2) console.log("Spotlight context handler called in response to spotlight click", spotlight);
        setSpotlightToProcess(spotlight);
        setShowSpotlightDetailed(true);        

    },[]);
    
   //return the Recognitions Context provider
   return (
      <div>
        <SpotLightInfoViewer id={0} showModal={showSpotlightDetailed} handleClose={handleCancel} spotlightToView={spotlightToProcess} isSmall={false} isFull={true} noBoxShadow handleDeleteSpotlightClick={handleDeleteSpotlightClick} handleEditSpotlightClick={handleEditSpotlightClick} />
         {/* <ModalNoBackground showModal={showSpotlightDetailed} closeCallback={handleCancel} cardColor={BALANCE_COLOR}>
            <SpotlightCard id={0} spotlightToView={spotlightToProcess}  isSmall={false} isFull={true} noBoxShadow handleDeleteSpotlightClick={handleDeleteSpotlightClick} handleEditSpotlightClick={handleEditSpotlightClick}/>
         </ModalNoBackground > */}

         <ModalNoBackgroundFixed showModal={showModalDelete} closeCallback={handleCancelDelete} cardColor={TEAM_COLOR} closeButtonColor={COLOR_WHITE} closeButtonBackground={TEAM_COLOR} >
           <div className="modalNoBkgInnerCard" >
                <div className="modalNoBkgCenterColumn" style={{padding:"10px 0px"}}>
                    <h4 className='blue'>Delete Spotlight?</h4>
                </div>
                <div className="modalNoBkgFooter">
                    <Button variant="primary" onClick={handleConfirmationDeleteSpotlight}  > Delete </Button>
                    <Button variant="secondary" onClick={handleCancelDelete} > Cancel </Button>
                </div>
            </div>
         </ModalNoBackgroundFixed>

         <ModalNoBackgroundFixed showModal={showSendSpotlightModal} closeCallback={handleCancelEditSpotlightClick} cardColor={TEAM_COLOR} opaqueBackground={true}  hideShadow={true} hideInnerBorder={true} >
                <SpotlightSendTool backgroundColor={"black"} callbackOnComplete={editSpotlightPreviewCallback} selectedSpotlight={spotlightToProcess} spotlightEditMode={spotlightEditMode}/>
         </ModalNoBackgroundFixed >

        <SpotlightContext.Provider value={
           {   
               recognitionTypes, setRecognitionTypes, recognitionTypesLoaded, compareRecognitionTypes,
               recognitions, setRecognitions, previewRecognitions, setPreviewRecognitions, loadUserRecognitions, spotlightsLoaded,
               SpotlightsHeader, spotlightClickCallback,
               setSpotlightToProcess, setShowSpotlightDetailed, numSpotlightsThisMonthForUser,

               //Hooks for SendSpotlight Tool
               setShowSendSpotlightModal,  handleEditSpotlightClick, handleDeleteSpotlightClick,

               
           }}>
             {children}
        </SpotlightContext.Provider>
    </div>
  ); 
};


export { SpotlightContext, SpotlightProvider };

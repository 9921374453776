//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021

//  ADMIN page for batch creating users from a csv file

import "./adminAnalyticsRecalc.css"

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, LEADERBOARD_PERIODS} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {Authenticator, AmplifySignOut } from '@aws-amplify/ui-react';


//Queries and Mutations
import { getCustomer } from '../../shared/graphql/queries';
import { updateCustomerLeaderBoard } from '../../shared/graphql/mutations';

//Bootstrap and other React components
import Select from 'react-select';   //Version with support from multiple selections

//Our components
import {  DashboardHeader } from "../../shared/utils/dashboardUtils";

//Our Components

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context

//Icons
import { IconButton } from '@mui/material';

//Utils
import { invokeAPI, fetchCustomers, fetchCustomer, fetchLeaderboardsByCustomer, fetchLeaderboardSpotsByCustomer, fetchUsersByCustomer, fetchUserProgressDataByCustomer, fetchTeamsByCustomer, fetchTransactionsByCustomerByDate, } from "../../shared/utils/databaseUtils";
import { updateLeaderboardSpot, compareLeaderboardSpotsByCurrentBalance, sortUserProgressDataRecords, calcAnalytics, storeAnalytics, deleteAnalytics, invokeDeleteGraphs, invokeDeleteLeaderboardSpots, recalcUserProgressData } from "../../shared/utils/analyticsUtils";
import { writeLeaderboardSpot } from "../../shared/utils/userAndTeamUtils.js";

import {NOW, ONE_YEAR_AGO, NOW_STRING, ONE_MONTH_AGO_STRING, ONE_YEAR_AGO_STRING} from "../../shared/utils/dateTimeUtils";

//Here's the start of our Javascrpt App
const AdminAnalyticsRecalc = () => {

   // Context
    const { authState,   currentUser, isSuperAdmin, isAdmin, } = useContext(AuthContext);    
    const { customerOptions} = useContext(CustomerContext);   
	const { setShowSpinner } = useContext(DisplayContext); 
   
    const [selectedCustomerOptions, setSelectedCustomerOptions] = useState(null);
    const [isUpdatingBackend, setIsUpdatingBackend] = useState(false);  //Used as a state variable set to TRUE when updating the backend
 
 
    // Analytics State Data
    const [customersToProcess, setCustomersToProcess] = useState([]);
    const [usersToProcess, setUsersToProcess] = useState([]);
    const [userProgressDataRecordsToProcess, setUserProgressDataRecordsToProcess] = useState([]);
    const [teamsToProcess, setTeamsToProcess] = useState([]);
    const [transactionsToProcess, setTransactionsToProcess] = useState([]);
    const [leaderboardToProcess, setLeaderboardToProcess] = useState(null);
    const [leaderboardSpotsToProcess,setLeaderboardSpotsToProcess] = useState([]);
    const [processingState, setProcessingState] = useState("READY");
    const [processingMode, setProcessingMode] = useState("");
    const [customerToProcessIndex, setCustomerToProcessIndex] = useState(-1); //INDEX into the customersToProcess array

    // STATE MACHINE
    useEffect(() => {
        
        if (DEBUG_MODE >= 2) console.log("useEffect: STATE MACHINE INVOKED", processingState);
        
        //Do nothing if not yet logged in the user
        if (authState !== "signedin" || selectedCustomerOptions == null) {
            setProcessingState('READY');
            return;
        }
        
        //Process state change
        switch(processingState) {
            case 'READY':
                return;
                
            case 'LOAD_CUSTOMERS':
                 loadCustomers();
                 return;
                 
            case 'LOAD_CUSTOMER_DATA':
                loadCustomerData();
                return;
                
            case 'DELETE_CUSTOMER_ANALYTICS':
                deleteCustomerAnalytics();
                return;
                
            case 'DELETE_CUSTOMER_GRAPHS':
                deleteCustomerGraphsState();
                return;
                
            case 'DELETE_CUSTOMER_LEADERBOARD_SPOTS':
                deleteCustomerLeaderboardSpots();
                return;
                
            case 'PROCESS_CUSTOMER':
                processCustomer();
                return;
                
            case 'STORE_CUSTOMER_ANALYTICS':
                storeCustomerAnalytics();
                return;
                
            case 'UPDATE_CUSTOMER_PROGRESS_DATA':
                updateCustomerProgressData();
                return;
                
            case 'GEN_LEADERBOARD_SLOTS':
                generateCustomerLeaderboardSlots();
                return;
               
        }


    }, [processingState, customerToProcessIndex]);      //TRIGGERED ON ANY CHANGE TO processingState

    function returnStateMachineToReady() {
        setProcessingState('READY');
        setCustomerToProcessIndex(-1);
        setShowSpinner(false); //Hide spinners        
        setIsUpdatingBackend(false);      
        setProcessingMode("");    
        
    }


    async function updateCustomerProgressData() {
        try {
            //Now, for this customer, since we have already read the data, reset rings and user progress data  	
            await recalcUserProgressData({userProgressDataRecords: userProgressDataRecordsToProcess, users:usersToProcess});
            
            //Have we processed ALL selected customers?
            if (customerToProcessIndex >= customersToProcess.length-1) {
                returnStateMachineToReady();
                return;
            } else {
                setCustomerToProcessIndex(customerToProcessIndex+1); //Bump index
                setProcessingState("LOAD_CUSTOMER_DATA");       //Process next customer
            }   
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error updating customer progress data", err);
        }            
    }

    async function storeCustomerAnalytics() {
        try {
            //Store generated analytics to DB for each user and team of the customer
            await storeAnalytics(customersToProcess[customerToProcessIndex]);
            if (DEBUG_MODE >= 2) console.log("Customer analytics STORED");         
            setProcessingState("UPDATE_CUSTOMER_PROGRESS_DATA");
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error storing customer analytics", err);
        }
    }
    
    async function deleteCustomerAnalytics() {

        try {
            //Safety checks 
            if (!customersToProcess || (customerToProcessIndex < 0) || (customerToProcessIndex > customersToProcess.length)) {
                returnStateMachineToReady();
                return;
            }
            
            //For this customer, delete prior analytics records
            await deleteAnalytics(customersToProcess[customerToProcessIndex].id);
            if (DEBUG_MODE >= 2) console.log("Customer analytics deleted");  
            
            //Set next state
            if (processingMode =="REGEN_ANALYTICS")
                setProcessingState("PROCESS_CUSTOMER");
                
            else if (processingMode =="DELETE_ALL_ANALYTICS") {
                //Finished this customer flow
                if (customerToProcessIndex >= customersToProcess.length-1) {
                    returnStateMachineToReady();
                    return;
                } else {
                    setCustomerToProcessIndex(customerToProcessIndex+1); //Bump index
                }
            } 
            
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error deleting customer analytics", err);
        }
    }

    async function deleteCustomerGraphsState() {

        try {
            //Safety checks 
            if (!customersToProcess || (customerToProcessIndex < 0) || (customerToProcessIndex > customersToProcess.length)) {
                returnStateMachineToReady();
                return;
            }
            
            //For this customer, delete prior analytics records
            await invokeDeleteGraphs(customersToProcess[customerToProcessIndex].id);
            if (DEBUG_MODE >= 2) console.log("Customer graphs deleted", " index=", customerToProcessIndex, customersToProcess[customerToProcessIndex].id);  
            
            //Set next state
            //Finished this customer flow.  More?
            if (customerToProcessIndex >= customersToProcess.length-1) {
                returnStateMachineToReady();
                return;
            } else {
                setCustomerToProcessIndex(customerToProcessIndex+1); //Bump index
            }
            
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error deleting customer analytics", err);
        }
    }
    
    async function deleteCustomerLeaderboardSpots () {
        
        try {
            //Safety checks 
            if (!customersToProcess || (customerToProcessIndex < 0) || (customerToProcessIndex > customersToProcess.length)) {
                returnStateMachineToReady();
                return;
            }
            
    	    //For this Customer, get its customer leader board record (single meta record per company) and all spot, which may not ALL come along via graphql
            const tempCustomerLeaderBoards = await fetchLeaderboardsByCustomer(customersToProcess[customerToProcessIndex].id);
            var tempCustomerLeaderBoard = {};
            if (tempCustomerLeaderBoards && tempCustomerLeaderBoards.length > 0)  tempCustomerLeaderBoard = { ... tempCustomerLeaderBoards[0]}; //Make a clean copy

            //For this customer, delete all existing leaderboard spots
            await invokeDeleteLeaderboardSpots(customersToProcess[customerToProcessIndex].id);
            
            if (DEBUG_MODE >= 2) console.log("Customer leaderboard spots deleted", " index=", customerToProcessIndex, customersToProcess[customerToProcessIndex].id);  


            //Trigger a leaderboard push with a benign write

            if (tempCustomerLeaderBoard) {
                const tempUpdatedCustomerLeaderBoardData = await invokeAPI(updateCustomerLeaderBoard, 'updateCustomerLeaderBoard', {id: tempCustomerLeaderBoard.id, customerID: tempCustomerLeaderBoard.customerID});
                   
                if (DEBUG_MODE >= 2) console.log("Sent leaderboard update trigger", tempCustomerLeaderBoard);
            } else {
                if (DEBUG_MODE >= 2) console.log("Error - no master leaderboard record for sending trigger", tempCustomerLeaderBoard);
            }
            
            
            //Set next state for deleting leaderboard spots flow
            //Finished this customer flow.  More customers?
            
            if (processingMode == 'REGEN_LEADERBOARD_SPOTS') 
                setProcessingState("PROCESS_PROGRESS_RECORDS");

            else if (processingMode == 'DELETE_LEADERBOARD_SPOTS')  { 
                
                if (customerToProcessIndex >= customersToProcess.length-1) {
                    returnStateMachineToReady();
                    return;
                } else {
                    setCustomerToProcessIndex(customerToProcessIndex+1); //Bump index, which starts the state machine over
                }
            }
        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error deleting customer analytics", err);
        }        
    }
    
    async function processCustomer() {

        try {
            //Safety checks 
            if (!customersToProcess || (customerToProcessIndex < 0) || (customerToProcessIndex > customersToProcess.length)) {
                returnStateMachineToReady();
                return;
            }
            
            if (DEBUG_MODE >= 2) console.log("Processing customer", customersToProcess[customerToProcessIndex].name);   
     
             //For this customer, process the transactions to generate the company totals and per-employee / per-team stats
            calcAnalytics({
                customer:customersToProcess[customerToProcessIndex],
                users:usersToProcess,
                teams:teamsToProcess,
                transactions:transactionsToProcess,
                userProgressDataRecords: userProgressDataRecordsToProcess,
            });

            if (DEBUG_MODE >= 2) console.log("Analytics generated");

            if (processingMode =="REGEN_ANALYTICS") 
                setProcessingState("STORE_CUSTOMER_ANALYTICS");       

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error processing customer", err);
        }
    }    

        
    async function generateCustomerLeaderboardSlots() {

       try {
            //Safety checks 
            if (!customersToProcess || (customerToProcessIndex < 0) || (customerToProcessIndex > customersToProcess.length)) {
                returnStateMachineToReady();
                return;
            }
            
            if (DEBUG_MODE >= 2) console.log("Processing customer progress records to update leaderboard slots for company:", customersToProcess[customerToProcessIndex].name);   
 
 
 		    if (userProgressDataRecordsToProcess.length >0) {
                
	      	    //Repeat for each period, generate leaderboard spots
	      	    for (var i=0; i < LEADERBOARD_PERIODS.length; i++) {
	      	    	
	      	    	//Sort the user progress records by the current period we are processing
	     	    	const progressRecordsSortedByPeriod = sortUserProgressDataRecords(userProgressDataRecordsToProcess, LEADERBOARD_PERIODS[i]);
	     	    	// if (DEBUG_MODE >= 2) console.log("User Progress Records sorted by period " + LEADERBOARD_PERIODS[i], userProgressDataRecords);
	     	    	
	     	    	const leaderboardSpotsOfCurrentPeriod = leaderboardSpotsToProcess.filter(spot => spot.leaderBoard == LEADERBOARD_PERIODS[i]);
	     	    	leaderboardSpotsOfCurrentPeriod.sort(compareLeaderboardSpotsByCurrentBalance);
	      	        // 	if (DEBUG_MODE >= 2) console.log("Existing Leaderboard Spots Records sorted by balance ", leaderboardSpotsOfCurrentPeriod);
	    	    	
	     	    	
	      	    	//For each user progress record of this particular customer, update the user's leaderboard spot as sorted
	      	    	for (var l=0; l < progressRecordsSortedByPeriod.length; l++) {
	      	    		
	      	    		var user = usersToProcess.find(user => user.id == progressRecordsSortedByPeriod[l].userID); //Find matching user
	      	    		
	      	    		//Found a matching user for this progress data record?
	      	    		if (user) {
	      	    			//Set new rank of user based on sorted progress data records array index + 1
	      	    			const userNewRank = l+1; 
	      	    			
	      	    			//Set prior rank based on position in prior leaderboard records for this period
	      	    			var existingSlot = leaderboardSpotsOfCurrentPeriod.find(spot => spot.userID == user.id);
	      	    			var userPriorRank = -1;
	      	    			
	      	    			//Did we find an existing spot for this user?  If so update it directly.  If not, create one for the user
	      	    			if (existingSlot) {
	      	    			    if (DEBUG_MODE >= 2) console.log("Found existing slot for user; updating slot", user.id);
	      	    			    userPriorRank = existingSlot.userRank;
	      	    			    await updateLeaderboardSpot({
	      	    			            existingSlotID:existingSlot.id, 
	      	    			            user:user, 
	      	    			            period:LEADERBOARD_PERIODS[i], 
	      	    			            userProgressDataRecord:progressRecordsSortedByPeriod[l], 
	      	    			            newRank: userNewRank, 
	      	    			            userMove: (userPriorRank > 0 ? (userPriorRank - userNewRank) : leaderboardSpotsToProcess.length-userNewRank)
	      	    			    });
	      	    			} else {
  	      	    			   // if (DEBUG_MODE >= 2) console.log("No existing slot for user; creating slot", user.id);
	      	    			    await writeLeaderboardSpot({
	      	    			        customerLeaderboardID: leaderboardToProcess.id,
	      	    			        user:user, 
	      	    			        period:LEADERBOARD_PERIODS[i], 
	      	    			        userProgressDataRecord:progressRecordsSortedByPeriod[l], 
	      	    			        newRank: userNewRank, 
	      	    			        userMove: 0,        //No move since no prior slot for this user
	      	    			    });
	      	    			}
	      	    			
	      	    		} else {
	      	    			if (DEBUG_MODE >= 2) console.log("Error - no matching user for user progress record:",  progressRecordsSortedByPeriod[l].userID, usersToProcess);
	      	    		}
	      	    	}
	      	    }
		    }
 
            if (DEBUG_MODE >= 2) console.log("Leaderboard slots generated");
            
                //Trigger a leaderboard push with a benign write
            if (leaderboardToProcess) {
                const tempUpdatedCustomerLeaderBoardData = await invokeAPI(updateCustomerLeaderBoard, 'updateCustomerLeaderBoard', {id: leaderboardToProcess.id, customerID: leaderboardToProcess.customerID});
                if (DEBUG_MODE >= 2) console.log("Sent leaderboard update trigger");
            } else {
                if (DEBUG_MODE >= 2) console.log("Error - no master leaderboard record for sending trigger", leaderboardToProcess);
            } 

            //Set next state.  Finished or more customers to process?
           if (customerToProcessIndex >= customersToProcess.length-1) {
                returnStateMachineToReady();
                return;
            } else {
                setCustomerToProcessIndex(customerToProcessIndex+1); //Bump index, which starts the state machine over
            }
      

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error processing customer", err);
        }
        
    }   
    
    
    async function loadCustomerData() {

        try {   
            //Safety checks 
            if (!customersToProcess || (customerToProcessIndex < 0) || (customerToProcessIndex > customersToProcess.length)) {
                returnStateMachineToReady();
                return;
            }
    	    
            if (DEBUG_MODE >= 2) console.log("Loading customer data", customersToProcess[customerToProcessIndex].name);   
            
            //For this Customer, get active Users
            const tempUsers = await fetchUsersByCustomer(customersToProcess[customerToProcessIndex].id);
            if (DEBUG_MODE >= 2) console.log("Users", tempUsers.length);   
            
            //For this Customer, get Users progress records
            const tempUserProgressDataRecords = await fetchUserProgressDataByCustomer(customersToProcess[customerToProcessIndex].id);
            if (DEBUG_MODE >= 2) console.log("User progress data", tempUserProgressDataRecords.length, tempUserProgressDataRecords);   
 
            //For this Customer, get active Teams
            const tempTeams = await fetchTeamsByCustomer(customersToProcess[customerToProcessIndex].id);
            if (DEBUG_MODE >= 2) console.log("Teams", tempTeams.length); 

    	    //For this Customer, get its customer leader board record (single meta record per company) and all spot, which may not ALL come along via graphql
            const tempCustomerLeaderBoards = await fetchLeaderboardsByCustomer(customersToProcess[customerToProcessIndex].id);
            var tempCustomerLeaderBoard = {};
            if (tempCustomerLeaderBoards && tempCustomerLeaderBoards.length > 0)  tempCustomerLeaderBoard = { ... tempCustomerLeaderBoards[0]}; //Make a clean copy
    	    const tempCustomerLeaderboardSpots = await fetchLeaderboardSpotsByCustomer(customersToProcess[customerToProcessIndex].id);
    	    if (DEBUG_MODE >= 2) console.log("Customer leaderboard and spots",tempCustomerLeaderBoard, tempCustomerLeaderboardSpots);   

            //Store the loaded records
            setUsersToProcess(tempUsers);
            setTeamsToProcess(tempTeams);
            setUserProgressDataRecordsToProcess(tempUserProgressDataRecords);
            setLeaderboardToProcess(tempCustomerLeaderBoard);
            setLeaderboardSpotsToProcess(tempCustomerLeaderboardSpots);

            //Grab a year worth of transactions if regenerating ALL the analytics
            if (processingMode == "REGEN_ANALYTICS") {            
                
                //For this Customer, get transactions 
                const tempTransactions = await fetchTransactionsByCustomerByDate(customersToProcess[customerToProcessIndex].id, ONE_YEAR_AGO_STRING(), NOW_STRING());
                // const tempTransactions = await fetchTransactionsByCustomerByDate(customersToProcess[customerToProcessIndex].id, ONE_MONTH_AGO_STRING(), NOW_STRING());
                if (DEBUG_MODE >= 2) console.log("Transactions", tempTransactions.length);  
    
                setTransactionsToProcess(tempTransactions);
                
            }

            if (DEBUG_MODE >= 2) console.log("Data loaded and ready for processing");
 
            //Set next state    
            if (processingMode =="REGEN_ANALYTICS") 
                setProcessingState("DELETE_CUSTOMER_ANALYTICS");
                
            else if (processingMode == "REGEN_LEADERBOARD_SPOTS")
                 setProcessingState("GEN_LEADERBOARD_SLOTS");

        } catch (err) {
            if (DEBUG_MODE >= 2) console.log("Error loading customer data", err);
        }
       
    }

    async function loadCustomers() {

        setIsUpdatingBackend(true);
        setShowSpinner(true); //Show spinners
 
        try {

            var tempCustomers = [];
            
            if (selectedCustomerOptions.label === ' - ALL -') {
                // Fetch all companies
                  tempCustomers = await fetchCustomers();
                  setCustomersToProcess(tempCustomers);
                  if (DEBUG_MODE >= 2) console.log("Retreived customers ", tempCustomers);
            } else {
                
              //Fetch just the selected customer
              const customer = fetchCustomer(selectedCustomerOptions.id);
              if (customer) tempCustomers.push(customer); //Push customer object onto array of customers to process
                      
            }

            if (DEBUG_MODE >= 2) console.log("Fetched customer(s) for processing", tempCustomers);
 
            setCustomersToProcess(tempCustomers); //Store customers array

            //Update page mode state to trigger processing of the first customer
            setCustomerToProcessIndex(0);
            
            //Set next state
            if (processingMode =="REGEN_ANALYTICS")
                setProcessingState("LOAD_CUSTOMER_DATA");
                
            else if (processingMode =="DELETE_ALL_ANALYTICS")
                setProcessingState("DELETE_CUSTOMER_ANALYTICS");
                
            else if (processingMode =="DELETE_GRAPHS")
                setProcessingState("DELETE_CUSTOMER_GRAPHS");
                
            else if (processingMode =="DELETE_LEADERBOARD_SPOTS")
                setProcessingState("DELETE_CUSTOMER_LEADERBOARD_SPOTS");
            
            else if (processingMode == "REGEN_LEADERBOARD_SPOTS")
                setProcessingState("LOAD_CUSTOMER_DATA");

        } catch (err) { if (DEBUG_MODE >= 2) console.log('error processing customers', err); }
    

    }

    //
    // HANDLERS
    //
    
    const handleDeleteAllAnalytics = async () => {

        if (authState != "signedin") return;
        
        setProcessingMode("DELETE_ALL_ANALYTICS");
        setProcessingState("LOAD_CUSTOMERS");
        
        if (DEBUG_MODE >= 2) console.log("Deleting analytics for selected customer", selectedCustomerOptions);
        

    };

    const handleDeleteGraphs = async () => {

        if (authState != "signedin") return;
        
        setProcessingMode("DELETE_GRAPHS");
        setProcessingState("LOAD_CUSTOMERS");
        
        if (DEBUG_MODE >= 2) console.log("Deleting graphs for selected customer", selectedCustomerOptions);
     
    };    
    

    const handleDeleteLeaderboardsSpots = async () => {
         if (authState != "signedin") return;
        
        setProcessingMode("DELETE_LEADERBOARD_SPOTS");
        setProcessingState("LOAD_CUSTOMERS");
        
        if (DEBUG_MODE >= 2) console.log("Regenerating leaderboards for selected customer", selectedCustomerOptions);
       
    };   
    
    
   const handleRegenLeaderboardSpots = async () => {
         if (authState != "signedin") return;
        
        setProcessingMode("REGEN_LEADERBOARD_SPOTS");
        setProcessingState("LOAD_CUSTOMERS");
        
        if (DEBUG_MODE >= 2) console.log("Regenerating leaderboards for selected customer", selectedCustomerOptions);
       
    };   

    const handleRegenAnalytics = async () => {

        if (authState != "signedin") return;
        
        if (DEBUG_MODE >= 2) console.log("Regenerating analytics for selected customer", selectedCustomerOptions);
       
        setProcessingMode("REGEN_ANALYTICS");
        setProcessingState("LOAD_CUSTOMERS");

    };
 
 
 //Handle access by unauthenticated user
  if (authState != "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      // if (authState == "signedout") popUpLoginWindow();
      return null;
  }

 //Render function for authenticated user 
  if (!isSuperAdmin && !isAdmin) {
      return (
          <Authenticator>
          <div>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else  return (

    <Authenticator>

        <div className="adminPage">
            <DashboardHeader header={'ANALYTICS ENGINE'}  fontSize={'2.0rem'} color={TEAM_COLOR} />



            <div className="ContainerNoHeightCenter" >
                   <div style={{fontSize:"18px", color:"#6495ED", width:"300px", padding:"10px 20px"}}>
                        <Select 
                            isDisabled={isUpdatingBackend}
                            isMulti={false} 
                            name="customerDropdown" 
                            options={customerOptions} 
                            onChange={setSelectedCustomerOptions} 
                            value={selectedCustomerOptions} 
                            placeholder=" -- select customer --"                            
                        />
                    </div>
            </div>

            <div className="ContainerNoHeightCenter" >

                <div style={{padding:"10px 5px", minWidth:"150px"}}>MODE: {processingMode ? processingMode : "IDLE"}</div>

                <div style={{padding:"10px 5px", minWidth:"150px"}}>STATE: {processingState}</div>
 
             </div>
       
            
             <div className="ContainerNoHeightSpaceBetween" style={{position:"relative", width:"clamp(800px, 50vw, 1400px)"}}>
           
                <div className="closeSettingsButton" style={selectedCustomerOptions ? {} : {backgroundColor:"#a1a6b5", pointer:"none"}}> 
                    <IconButton  onClick={handleRegenAnalytics} disabled={!selectedCustomerOptions}>
                            <div style={{ fontSize:"1.0rem", color:"#FFF", fontWeight: "500"}}>GENERATE ANALYTICS </div>
                    </IconButton>
                </div>
    
     
                 <div className="closeSettingsButton" style={selectedCustomerOptions ? {} : {backgroundColor:"#a1a6b5", pointer:"none"}}> 
                    <IconButton  onClick={handleDeleteAllAnalytics} disabled={!selectedCustomerOptions}>
                            <div style={{ fontSize:"1.0rem", color:"#FFF", fontWeight: "500"}}>DELETE ALL ANALYTICS</div>
                    </IconButton>
                </div>
    
     
                  <div className="closeSettingsButton" style={selectedCustomerOptions ? {} : {backgroundColor:"#a1a6b5", pointer:"none"}}> 
                    <IconButton  onClick={handleDeleteGraphs} disabled={!selectedCustomerOptions}>
                            <div style={{ fontSize:"1.0rem", color:"#FFF", fontWeight: "500"}}>DELETE GRAPH RECORDS</div>
                    </IconButton>
                </div>

                <div className="closeSettingsButton" style={selectedCustomerOptions ? {} : {backgroundColor:"#a1a6b5", pointer:"none"}}> 
                    <IconButton  onClick={handleDeleteLeaderboardsSpots} disabled={!selectedCustomerOptions}>
                            <div style={{ fontSize:"1.0rem", color:"#FFF", fontWeight: "500"}}>DELETE LEADERBOARD SPOTS</div>
                    </IconButton>
                </div>

                <div className="closeSettingsButton" style={selectedCustomerOptions ? {} : {backgroundColor:"#a1a6b5", pointer:"none"}}> 
                    <IconButton  onClick={handleRegenLeaderboardSpots} disabled={!selectedCustomerOptions}>
                            <div style={{ fontSize:"1.0rem", color:"#FFF", fontWeight: "500"}}>REGEN LEADERBOARD</div>
                    </IconButton>

                </div>
     
            </div>

        </div>
        
    </Authenticator>
            
            
  );
};




export default AdminAnalyticsRecalc;


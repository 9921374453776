import React, { useState } from 'react';
import './Style.css';
import {
  Highlight as HighlightIcon,
  Hub as HubIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Diversity2 as Diversity2Icon,
  Support as SupportIcon,
  Redeem as RedeemIcon,
  Equalizer as EqualizerIcon,
  AutoAwesome as AutoAwesomeIcon,
  GroupAdd as GroupAddIcon,
  Settings as SettingsIcon,
  DonutLarge as DonutLargeIcon,
  QrCode2 as QrCode2Icon,
  List as ListIcon,
  Polyline as PolylineIcon,
  Summarize as SummarizeIcon,
  EmojiEvents as LeaderboardIcon,
  DeviceHub as DeviceHubIcon
} from '@mui/icons-material';
import { CONECTERE_CONFIG_DATA } from '../../../../shared/data/conectereConfigData';
import { Link, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { LogoutModal } from '../../../../shared/Components/LogoutModal/LogoutModal';
import { useContext } from 'react';
import { AuthContext } from '../../../../shared/context/authContext';

const MENU_ITEMS = [
  {
    label: 'Dashboard',
    icon: CONECTERE_CONFIG_DATA.SIDEBAR_HOME,
    type: 'menu',
    path: '/UserHome',
  },
  {
    label: 'Launchpad',
    icon: <HubIcon />,
    type: 'menu',
    path: '/launchpad',
  },
  {
    label: 'Calendar',
    icon: CONECTERE_CONFIG_DATA.SIDEBAR_CALENDAR,
    type: 'menu',
    path: '/UserConectivitySchedule',
  },
  {
    label: 'Spotlights',
    icon: <HighlightIcon style={{ transform: 'rotate(-145deg)' }} />,
    type: 'menu',
    path: '/employeeSpotlightFeed',
  },
  {
    label: 'Leaderboards',
    icon: <LeaderboardIcon />,
    type: 'menu',
    path: '/EmployeeLeaderboards',
  },
  {
    label: 'Store',
    icon: CONECTERE_CONFIG_DATA.SIDEBAR_STORE,
    type: 'menu',
    path: '/EmployeeStore',
  },
  {
    label: 'Community',
    icon: CONECTERE_CONFIG_DATA.SIDEBAR_COMMUNITY,
    type: 'menu',
    path: '/conectereCommunity',
  },
  {
    label: 'Connectivities',
    icon: <HubIcon />,
    type: 'control',
    path: '/adminConectivities',
  },
  {
    label: 'Employees',
    icon: <PersonIcon />,
    type: 'control',
    path: '/adminusers',
  },
  { label: 'Teams', icon: <GroupIcon />, type: 'control', path: '/adminteams' },
  {
    label: 'Peer Recognition',
    icon: <HighlightIcon style={{ transform: 'rotate(-145deg)' }} />,
    type: 'control',
    path: '/adminSpotlights',
  },
  {
    label: 'Mentorship & Sponsorship',
    icon: <Diversity2Icon />,
    type: 'control',
    path: '/adminMentorSponsorPrograms',
  },
  {
    label: 'New Employee Experience',
    icon: <SupportIcon />,
    type: 'control',
    path: '/adminNewEmployeeSupport',
  },
  {
    label: 'Connection Builder',
    icon: <DeviceHubIcon />,
    type: 'control',
    path: '/adminConnectionBuilder',
  },
  {
    label: 'Products',
    icon: <QrCode2Icon />,
    type: 'store',
    path: '/adminEmployeeStore',
  },
  {
    label: 'Order processing',
    icon: <ListIcon />,
    type: 'store',
    path: '/adminOrders',
  },
  {
    label: 'Connectivities visualization',
    icon: <PolylineIcon />,
    type: 'analytics',
    path: '/adminGraphConectivityVisualizer',
  },
  {
    label: 'Enterprise-wide History',
    icon: <SummarizeIcon />,
    type: 'analytics',
    path: '/adminReportEnterpriseHistory',
  },
  {
    label: 'Favorite Rewards',
    icon: <SummarizeIcon />,
    type: 'analytics',
    path: '/adminReportProductsByFavorite',
  },
  {
    label: 'Favorite Connectivities',
    icon: <SummarizeIcon />,
    type: 'analytics',
    path: '/adminReportConectivitiesByFavorite',
  },
  {
    label: 'Retention Alert',
    icon: <AutoAwesomeIcon />,
    type: 'other',
    path: '/dashboardCoulson',
  },   
  {
    label: 'Slack',
    icon: CONECTERE_CONFIG_DATA.SLACK_LOGO_WHITE,
    type: 'other',
    path: '/adminSlackIntegration',
  },
  {
    label: 'Import Employees',
    icon: <GroupAddIcon />,
    type: 'other',
    path: '/adminemployeeimport',
  },
  {
    label: 'Company Settings',
    icon: <SettingsIcon />,
    type: 'other',
    path: '/adminCustomerSettings',
  },
  {
    label: 'Billing History',
    icon: <DonutLargeIcon />,
    type: 'other',
    path: '/dashboardSpend',
  },
];

const MenuItem = ({ icon, label, isActive, onClick, path, darkMode }) => (
  <Link
    style={{ position: 'relative' }}
    className={`menuItemStyle ${
      isActive ? 'sidebarActiveMenuItem' : 'sidebarMenuItem'
    }`}
    onClick={onClick}
    to={path}
  >
    {isActive && (
      <>
        <div className='curveImgBottom'>
          <img
            src={
              darkMode
                ? CONECTERE_CONFIG_DATA.SIDEBAR_CURVETOPDARK
                : CONECTERE_CONFIG_DATA.SIDEBAR_CURVETOP
            }
            alt='curve top'
            height='100%'
            width='100%'
          />
        </div>
        <div className='curveImgTop'>
          <img
            src={
              darkMode
                ? CONECTERE_CONFIG_DATA.SIDEBAR_CURVEBOTTOMDARK
                : CONECTERE_CONFIG_DATA.SIDEBAR_CURVEBOTTOM
            }
            alt='curve bottom'
            height='100%'
            width='100%'
          />
        </div>
      </>
    )}
    {typeof icon === 'string' ? (
      <img src={icon} alt={label} className='ccImgMedium' />
    ) : (
      icon
    )}
    <div className='TextStyle4'>{label}</div>
  </Link>
);

const SubMenu = ({ items, extraMargin, checkIsActiveMenu }) => (
  <div
    style={{ marginLeft: extraMargin ? 60 : 35 }}
    className='gap-10 subMenuItem'
  >
    {items.map(({ label, icon, path }, index) => (
      <Link
        key={index}
        to={path}
        className={`menuItem menuItemStyle ${
          checkIsActiveMenu?.(path) ? 'subMenuActive' : 'subMenu'
        }`}
      >
        {typeof icon === 'string' ? (
          <img src={icon} alt={label} className='ccImgMedium' />
        ) : (
          icon
        )}
        <div className='TextStyle4'>{label}</div>
      </Link>
    ))}
  </div>
);

const ExpandableMenu = ({
  icon,
  label,
  isOpen,
  toggleOpen,
  children,
  isInnerMenu,
}) => (
  <div>
    <div
      className={`colorWhite menuItemStyle ${
        isInnerMenu ? 'expandableMenuItem' : ''
      }`}
      onClick={toggleOpen}
    >
      {typeof icon === 'string' ? (
        <img src={icon} alt={label} className='ccImgMedium' />
      ) : (
        icon
      )}
      <div className='expandableMenuDiv'>
        <div className='TextStyle4'>{label}</div>
        <img
          src={CONECTERE_CONFIG_DATA.SIDEBAR_DROPDOWNARROW}
          alt='dropdown arrow'
          style={{
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
    </div>
    {isOpen && children}
  </div>
);

export const SideBarMenu = ({ darkMode }) => {
  const { pathname } = useLocation();
  const [controlCenterOpen, setControlCenterOpen] = useState(false);
  const [employeeStoreOpen, setEmployeeStoreOpen] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const { authState, currentUser } = useContext(AuthContext);

  const handleLogoutClick = () => {
    setShowToast(true);
  };

  const handleLogoutClose = () => {
    setShowToast(false);
  };

  const handleMenuItemClick = (tab) => {
    setControlCenterOpen(false);
    setEmployeeStoreOpen(false);
    setAnalyticsOpen(false);
  };

  const isActiveMenu = useCallback(
    (path) => {
      if (pathname) return path === pathname;
      return false;
    },
    [pathname]
  );

  if (authState !== 'signedin' || !currentUser) return null;

  return (
    <div className='gap-10 sidebar'>
      <div className='ContainerVertical'>
        <img
          src={
            darkMode
              ? CONECTERE_CONFIG_DATA.SIDEBAR_DARK_MODE_LOGO
              : CONECTERE_CONFIG_DATA.SIDEBAR_LOGO
          }
          alt='logo'
          height='65px'
          width='65px'
        />
        <div className='TextStyle6'>
          {darkMode ? (
            <div className='ContainerNoHeightCenter'>
              <div className='blue'>Con</div>
              <div className='purple'>ec</div>
              <div className='green'>te</div>
              <div className='yellow'>re</div>
            </div>
          ) : (
            <div className='white'>Conectere</div>
          )}
        </div>
      </div>
      <div className='gap-10 sidebarMenu'>
        {MENU_ITEMS.filter((item) => item.type === 'menu').map(
          ({ label, icon, path }) => (
            <MenuItem
              key={label}
              darkMode={darkMode}
              icon={icon}
              label={label}
              path={path}
              isActive={isActiveMenu(path)}
              onClick={() => handleMenuItemClick(label)}
            />
          )
        )}

        <ExpandableMenu
          icon={CONECTERE_CONFIG_DATA.SIDEBAR_CONTROL}
          label='Control Center'
          isOpen={controlCenterOpen}
          toggleOpen={() => setControlCenterOpen(!controlCenterOpen)}
        >
          <SubMenu
            items={MENU_ITEMS.filter((item) => item.type === 'control')}
            checkIsActiveMenu={(path) => isActiveMenu(path)}
          />
          <ExpandableMenu
            icon={<RedeemIcon />}
            label='Employee Store'
            isOpen={employeeStoreOpen}
            toggleOpen={() => setEmployeeStoreOpen(!employeeStoreOpen)}
            isInnerMenu
          >
            <SubMenu
              items={MENU_ITEMS.filter((item) => item.type === 'store')}
              extraMargin
              checkIsActiveMenu={(path) => isActiveMenu(path)}
            />
          </ExpandableMenu>
          <ExpandableMenu
            icon={<EqualizerIcon />}
            label='Analytics And Reports'
            isOpen={analyticsOpen}
            toggleOpen={() => setAnalyticsOpen(!analyticsOpen)}
            isInnerMenu
          >
            <SubMenu
              items={MENU_ITEMS.filter((item) => item.type === 'analytics')}
              extraMargin
              checkIsActiveMenu={(path) => isActiveMenu(path)}
            />
          </ExpandableMenu>
          <SubMenu
            items={MENU_ITEMS.filter((item) => item.type === 'other')}
            checkIsActiveMenu={(path) => isActiveMenu(path)}
          />
        </ExpandableMenu>
      </div>
      <div className='gap-10 logOut pointer' onClick={handleLogoutClick}>
        <img
          src={CONECTERE_CONFIG_DATA.SIDEBAR_LOGOUT}
          alt='Logout'
          className='ccImgMedium'
        />
        <div className='TextStyle4 colorWhite'>Logout</div>
      </div>
      <LogoutModal
        showLogoutModal={showToast}
        handleClose={handleLogoutClose}
      />
    </div>
  );
};

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//
//  copyright - 2020, 2021

//
//
// We use the freely licensable version of DataGrid
// Nevertheless, many / most of these props work
//  https://mui.com/x/react-data-grid/components/ 
//
// How to disable menu options: https://stackoverflow.com/questions/66514102/how-can-you-disable-specific-material-ui-datagrid-column-menu-options
//
// Custom toolbar: https://codesandbox.io/s/cpcl8
//
//
// https://smartdevpreneur.com/the-ultimate-guide-to-customizing-material-ui-datagrid/
//
//
// https://stackoverflow.com/questions/66720409/how-to-render-table-footer-in-xgrid-material-ui-component
// 
//https://smartdevpreneur.com/react-mui-v5-data-grid-tutorial-sort-filter-export-pagination-and-cell-editing/
//
// hideFooter: If true, the footer component is hidden.
// hideFooterRowCount: If true, the row count in the footer is hidden.
// hideFooterSelectedRowCount: If true, the selected row count in the footer is hidden.
// hideFooterPagination
//
// https://mui.com/x/react-data-grid/components/
//
// https://mui.com/x/react-data-grid/state/#access-the-state
//
//  // See https://mui.com/material-ui/api/table-pagination/#main-content



import "./menuDataGrid.css";
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, ROWS_PER_PAGE_OPTIONS, DATA_GRID_DEFAULT_WIDTH, DATA_GRID_MAX_WIDTH, DATA_GRID_MIN_WIDTH, DATA_GRID_DEFAULT_ROWS_PER_PAGE, DATA_GRID_DEFAULT_ROW_HEIGHT, DATA_GRID_DEFAULT_HEADER_HEIGHT, COLOR_BLUE_HEADER } from '../../data/conectereConfigData';

import React, { useEffect, useState, useContext } from 'react';

// import TablePagination from '@mui/material/TablePagination';
// import Pagination from '@mui/material/Pagination';

// import Box from '@mui/material/Box';


import { DataGrid, 
    GridToolbar, 
    GridToolbarContainer, 
    GridToolbarColumnsButton, 
    GridToolbarFilterButton, 
    GridToolbarExport, 
    GridToolbarDensitySelector, 
    gridPageCountSelector,
    gridPageSizeSelector,       //returns the number of rows per page
    gridVisibleRowCountSelector, //returns the number of rows after filtering
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';



import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

//Our Context
import { DisplayContext } from '../../context/displayContext';            //User Authentication Context


//React component
const MenuDataGrid = React.memo(({dataGridRows, dataGridColumns, pageSize, rowHeight, headerHeight, width, maxWidth, selectionHandler, turnOffBorder, showToolBar, title, noRowsLabel}) => {

    // if (DEBUG_MODE >= 2) console.log("Data Grid called:", width, maxWidth, title);
    
    //SET DEFAULTS FIRST
    if (!width) width = DATA_GRID_DEFAULT_WIDTH;
    if (!pageSize) pageSize = DATA_GRID_DEFAULT_ROWS_PER_PAGE;
    if (!rowHeight) rowHeight = DATA_GRID_DEFAULT_ROW_HEIGHT;
    if (!headerHeight) headerHeight = DATA_GRID_DEFAULT_HEADER_HEIGHT;
    if (!turnOffBorder) turnOffBorder = false;
    if (!showToolBar) showToolBar = false;
    if (!title) title = "";
    if (!noRowsLabel) noRowsLabel = 'No records';

   // Display context
    const {
            innerWindowWidth, innerWindowHeight, darkMode,
    } = useContext(DisplayContext); 
    
    //State Vars
    const footerHeight = 70;
    const [currentPageSize, setCurrentPageSize] = useState(pageSize ? pageSize : DATA_GRID_DEFAULT_ROWS_PER_PAGE);                                   //Number of rows to use per page
    const [divHeight, setDivHeight] = useState(headerHeight + rowHeight * pageSize + footerHeight);     //Set initial height of DIV based on initial values
    const [selection, setSelection] = useState([]);
    const [gridWidth, setGridWidth] = useState(computeGridWidth());

    // if (DEBUG_MODE >= 2) console.log("Set initial DIV height", divHeight, headerHeight, rowHeight, pageSize, footerHeight, headerHeight + rowHeight * pageSize + footerHeight);

    //Adjust page size based on grid length   
    useEffect(() => {
        if (!dataGridRows || dataGridRows.length === 0) return;

        let tempPageSize = DATA_GRID_DEFAULT_ROWS_PER_PAGE;
        if (dataGridRows.length <= 5) tempPageSize = 5;
        else if (dataGridRows.length < 10) tempPageSize = 10;
        else if (dataGridRows.length < 25) tempPageSize = 25;
        else if (dataGridRows.length < 50) tempPageSize = 50;
        else tempPageSize = 100;
        setCurrentPageSize(tempPageSize);
        if (DEBUG_MODE > 2) console.log("Adjusted page size", tempPageSize);
  
    },[dataGridRows]); 

    //Adjust grid based on window dimensions   
    useEffect(() => {
        setGridWidth(computeGridWidth());
    },[innerWindowWidth]); 
    

    function computeGridWidth() {
        
        let tempWidth = DATA_GRID_DEFAULT_WIDTH;
        
        //If our window is smaller than our default max width (1600px) then set our max grid width to 80% of the actual screen width less 80px of padding
        let maxWidth = (DATA_GRID_MAX_WIDTH > innerWindowWidth * 0.8-80 ? Math.floor(innerWindowWidth * 0.8 - 80) : DATA_GRID_MAX_WIDTH);
        // if (DEBUG_MODE >= 2) console.log("DATA GRID - max width set to:", tempWidth, innerWindowWidth);

        if ((innerWindowWidth < DATA_GRID_MIN_WIDTH)) {
            tempWidth = DATA_GRID_MIN_WIDTH;
        } else if ((innerWindowWidth > maxWidth)) {
            tempWidth = DATA_GRID_MAX_WIDTH;
        } else  if ((innerWindowWidth < DATA_GRID_DEFAULT_WIDTH) && (innerWindowWidth >= DATA_GRID_MIN_WIDTH)) {
            tempWidth = Math.floor(innerWindowWidth);
        } else if ((innerWindowWidth > DATA_GRID_DEFAULT_WIDTH) && (innerWindowWidth <= maxWidth)) {
            tempWidth = DATA_GRID_DEFAULT_WIDTH;
        } 


        //maxWidth param operates as an limiter to our dynamically adjusted width
         if (tempWidth >  maxWidth) tempWidth = maxWidth;
            
        // if (DEBUG_MODE >= 2) console.log("GRID - adjusting grid width based on new width",innerWindowWidth, tempWidth);

        return(tempWidth);
    }

    
   //Dynamically set DIV height based on number of rows for display
    useEffect(() => {

        if (dataGridRows == null) return;  //Do nothing if rows not received yet

        if (dataGridRows?.length == 0) return;  //Do nothing if rows not received yet
        
        const tempFooterHeight = 70 * (currentPageSize > 10 ? 1 : 1);           //adjust this in the future if using Pagination since footer increases in height
        const minDivHeight = headerHeight + rowHeight * 5 + tempFooterHeight;   //Not needed unless using dynamic resizing but ok to leave
        
        var height;
        
        if (dataGridRows.length > currentPageSize) {
            height = headerHeight + rowHeight * currentPageSize + tempFooterHeight;
            // if (DEBUG_MODE >= 2) console.log("Set Grid height based on page size", currentPageSize, dataGridRows.length, rowHeight, height);
    
        } else if (dataGridRows.length < 5) {
            height = headerHeight + rowHeight * 5  + tempFooterHeight;  //some min height if our rows are empty
            //  if (DEBUG_MODE >= 2) console.log("Set Grid height based on MINIMUM rows", currentPageSize, dataGridRows.length, rowHeight, height);

        } else {
            height = headerHeight + rowHeight * dataGridRows.length  + tempFooterHeight;
            
            // if (DEBUG_MODE >= 2) console.log("Set Grid height based on rows", currentPageSize, dataGridRows.length, rowHeight, height);

    }
    
    setDivHeight(height > minDivHeight ? height : minDivHeight);

    }, [currentPageSize, dataGridRows]);

    if (!dataGridRows || !dataGridColumns) return null;
 
    //Adjust height of DIV based on number of rows   




/*
    //NOT YET USED
    
    //TablePagination is defined here: https://mui.com/material-ui/api/table-pagination/#main-content 
    //This function is inside the DataGrid Context as it is passed in
    function CustomToolbar() {
        
    //** NEW
    //** See https://mui.com/x/react-data-grid/state/#access-the-state
    
    //   const apiRef = useGridApiContext();
    //   const page = useGridSelector(apiRef, gridPageSelector);
    //   const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    //   const rowCount = dataGridRows.length;
    //   const rowsPerPageState = useGridSelector(apiRef, gridPageSizeSelector);
      
    //   const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    // const handleChangePage = (event, newPage) => {
    //          if (DEBUG_MODE >= 2) console.log("handle change  page called")
    
    //         setPage(newPage);
    // };
    
    // //Callback function from Pagination component
    //  const handleChangeRowsPerPage = (event) => {
         
    //      if (DEBUG_MODE >= 2) console.log("handle change rows per page called", event.target.value)
         
    //     setCurrentPageSize(parseInt(event.target.value, 10));
        
    //     apiRef.current.setPage(0);
        
    //   };
      
    //** END NEW

      return (
        <GridToolbarContainer sx={{ 
                backgroundColor: "#6495ED"}}>
          <GridToolbarColumnsButton sx={{ 
                backgroundColor: "#6495ED", 
                color: "white", 
                fontSize:"18px", 
                fontWeight:"800"}}/>
          <GridToolbarFilterButton sx={{ 
                backgroundColor: "#6495ED", 
                color: "white", 
                fontSize:"18px", 
                fontWeight:"800"}}/>
          <GridToolbarDensitySelector sx={{ 
                backgroundColor: "#6495ED", 
                color: "white", 
                fontSize:"18px", 
                fontWeight:"800"}}/>
          <GridToolbarExport sx={{ 
                backgroundColor: "#6495ED", 
                color: "white", 
                fontSize:"18px", 
                fontWeight:"800"}}/>




        </GridToolbarContainer>
      );
    }
  
  
//               <Pagination
//                   sx={(theme) => ({ padding: theme.spacing(1.5, 0) })}
//                   color="primary"
//                   count={pageCount}
//                   page={page + 1}
//                   onChange={(event, value) => apiRef.current.setPage(value - 1)}
//                 />

//   <TablePagination
//       sx={{ 
//                 backgroundColor: "#6495ED", 
//                 color: "white", 
//                 fontSize:"18px", 
//                 fontWeight:"800"}}
//       count={rowCount}
//       page={page}
//       rowsPerPage={currentPageSize}
//       onPageChange={(event, value) => apiRef.current.setPage(value)}
//       onRowsPerPageChange={handleChangeRowsPerPage}
//       rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
     
//     />    


//   <TablePagination
//       color="primary"
//       count={rowCount}
//       page={page}
//       rowsPerPage={currentPageSize}
//       onPageChange={(event, value) => apiRef.current.setPage(value)}
//       onRowsPerPageChange={handleChangeRowsPerPage}
      
//     />  

*/

    //Local handler for selection events
    //This function receives an array of identifiers for the selected rows.  If a selection handler prop was received, call the call-back function and pass the event
    function localSelectionHandler (selectionEvent){
        
        // if (DEBUG_MODE >= 2) console.log("Local selection handler called.  Event:", selectionEvent);

        setSelection(selectionEvent); //Record local selection
        
        //Is there a registered selection handler?  If so, call it
        if (selectionHandler) {
            selectionHandler(selectionEvent);
        }
    }
    

// SEE: https://mui.com/x/api/data-grid/grid-csv-export-options/
// SEE: https://mui.com/x/api/data-grid/grid-print-export-options/

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton   />
            <GridToolbarFilterButton   /> 
            <GridToolbarDensitySelector   />
            <GridToolbarExport  
               csvOptions={{
                fileName: title,
              }}
            //   printOptions={{
            //     fileName: title,
            //     hideFooter: true,
            //     hideToolbar: true,
            //   }}              
            />
        </GridToolbarContainer>
      );
    }

    
   //Note - add this for a Tooltip at the top of the grid
    //       components={{
    //     Toolbar: GridToolbar,
    // }}

    if (dataGridColumns === null || dataGridRows===null) return null;
    // if (DEBUG_MODE >= 2) console.log("HIDE DATA GRID SHADOW: ", turnOffBorder, dataGridColumns);
    // if (DEBUG_MODE >= 2) console.log(" DATA GRID HEIGHT: ", divHeight);

    //Custome Overlay
    const NoRowsOverlay = () => {
        if (DEBUG_MODE) console.log("No Rows",noRowsLabel)
        return (
        <div className="TextStyle3" style={{padding:"clamp(4px,1vw,10px)", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>
            {noRowsLabel}
        </div>
        )
    }
    return (

        <div className={`menuDataGridWrapper ${(!turnOffBorder) && "boxShadow"}`}  style = {{width:gridWidth, height:divHeight}}>

               <DataGrid
                autoPageSize={false} //Autocalculate number of rows per page rather than let user control
                autoHeight={false} //Let the height of the grid adjust to the number of rows in the grid
                
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                rows={dataGridRows}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                pageSize={currentPageSize}
                pagination
                onPageSizeChange={(newPageSize) => setCurrentPageSize(newPageSize)}
                columns={dataGridColumns}
                checkboxSelection={false}
                onSelectionModelChange={localSelectionHandler}
                selectionModel={selection} //Tell the grid the current selection entries
    
                // components={!showToolBar ? null : { Toolbar: GridToolbar }} 
                components={{
                    Toolbar: (showToolBar ? CustomToolbar : null),
                    NoRowsOverlay: NoRowsOverlay,
                }}
                // localeText={{ noRowsLabel: noRowsLabel}}

    
                // componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
    
                //   componentsProps={{
                //     GridToolbar: {
                //       printOptions:{
                //         pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }',
                //         hideFooter: true,
                //         hideToolbar: true,
                //         fileName: title,
                //         }
                //     },
                //   }}
    
                //disableSelectionOnClick
                
                //  components={{
                //   Toolbar: CustomToolbar,
                // //   Footer: CustomToolbar,                 
                // }}
                />
    </div>
        
    );

});


export default MenuDataGrid;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

//config
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, TEAM_COLOR, BALANCE_COLOR } from '../../../../shared/data/conectereConfigData';

//Amplify, React
import React from 'react';

import './modalUpcomingSchedule.css';
import ModalNoBackground from "../../../../shared/Components/modalNoBackground/modalNoBackground";
import { displayUpcomingLaunchAsRow }  from "../../../../shared/utils/conectivityUtils";

const ModalUpcomingSchedule = ({showModal, closeModalCallback, upcomingLaunchesToShow, users, teams}) => {

    // if (DEBUG_MODE >= 2) console.log("Upcoming Schedule Modal called", showModal, upcomingLaunchesToShow);
    
    if (!upcomingLaunchesToShow) return null;
    if (upcomingLaunchesToShow.length===0) return null;

    const handleCancel = () => {
            if (closeModalCallback) closeModalCallback();
            if (DEBUG_MODE >= 2) console.log("Cancel button called");
        };
    
    return (
        <ModalNoBackground showModal={showModal} closeCallback={closeModalCallback} cardColor={TEAM_COLOR}  >
            <div className="modalTemplateSelectionInnerCard" style={{position:"relative"}}>
            
            
                <div className="modalNoBkgImage ccImgXXXXLSquare"  style={{borderColor: TEAM_COLOR}}> 
                    <img  className="avatarImageCenterPortrait" src={CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />  
                </div>


                <div className="ContainerNoHeight" style={{height:"100%", width:"100%"}}>
                    <div className="ContainerVerticalLeft" style={{flex:1,  height:"100%"}}>
                        <div className="TextStyle3" style={{paddingTop:"50px", width:"200px", textAlign:"center", color: BALANCE_COLOR, borderBottom:"1px solid " + BALANCE_COLOR}}>Upcoming Conectivities</div>
                        

                         <div className="ContainerVerticalLeft" style={{flexWrap:"wrap", width:"100%", padding:"10px 10px", borderRadius:"10px"}}>
                               
                            {upcomingLaunchesToShow.map((upcomingLaunch) => ( 
                            
                                <div style={{paddingBottom:"10px"}}>
                                    {displayUpcomingLaunchAsRow(upcomingLaunch, users, teams)}
                                </div>

                            ))}
                        </div>

                    </div>
                </div>
            </div>
                
        </ModalNoBackground >
    );

};

export default ModalUpcomingSchedule;




//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved


// Styles
import './spotlightInfoViewer.css';
import '../../styles/conectivities.css';
import '../../styles/App.css';

//Data
import {
  CONECTERE_CONFIG_DATA,
  DEBUG_MODE,
  HEADER_FULL_MODE,
  COLOR_GOLD,
  COLOR_BLUE_HEADER,
  BALANCE_COLOR,
  SOCIAL_COLOR,
  TEAM_COLOR,
  GROWTH_COLOR,
  COLOR_WHITE,
  CONECTIVITY_DESCRIPTION_MAX_LENGTH_LARGE_CARD,
  GIPHY_ID_IDENTIFIER,
} from '../../data/conectereConfigData';

//Amplify, React
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';

//Queries and Mutations
import {
  updateRecognitionComment,
  updateRecognition,
  createRecognitionComment,
} from '../../graphql/mutations';

//Context
import { AuthContext } from '../../context/authContext';
import { CustomerContext } from '../../context/customerContext'; //Customer Authentication context
import { GiphyCacheContext } from '../../context/giphyCacheContext';
import { DisplayContext } from '../../context/displayContext'; //User Authentication Context

//Our Components
import { ChatWindow } from '../chatWindow/chatWindow';

//Utils
import {
  ConectereDivider,
  timeAgo,
  truncateStringWithDots,
} from '../../utils/generalUtils';
import {
  drawSpotlightCurvedText,
  displayRecognizedUsersAvatars,
} from '../../utils/spotlightUtils.js';

import { invokeAPI, storeUpdatedRecognition } from "../../utils/databaseUtils";

//Material UI
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';
import { Edit, DeleteOutline } from '@material-ui/icons';

// Icons
import HighlightIcon from '@mui/icons-material/Highlight';
import CloseIcon from '@mui/icons-material/Close';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageIcon from '@mui/icons-material/Message';

import ChatCard from '../chatCard/chatCard';
import CommentInput from '../commentInput/commentInput';
import { ChatWindowV2 } from '../chatCardV2/chatWindowV2.js';

const Torch = (props) => {
  const { opposite } = props;

  return (
    <div className={`${opposite ? 'torch-opposite' : 'torch'}`}>
      <div className='torch-img-container'>
        <img
          src={CONECTERE_CONFIG_DATA.SPOTLIGHT_FOCUS}
          alt='spotlight'
          height='100%'
          width='100%'
        />
      </div>
      <div className={`spotlight-effect-wrapper spotlight-effect`} />
    </div>
  );
};

const SpotLightInfoViewer = React.memo(
  ({
    id,
    showModal,
    handleClose,
    spotlightToView,
    isSmall,
    isFull,
    noBoxShadow,
    handleDeleteSpotlightClick,
    handleEditSpotlightClick,
  }) => {
    if (!handleEditSpotlightClick)
      if (DEBUG_MODE >= 2)
        console.log(
          'NO HANDLER for editing Spotlight',
          spotlightToView,
          spotlightToView.id,
          handleEditSpotlightClick,
          handleDeleteSpotlightClick
        );

    if (DEBUG_MODE >= 2) console.log('spotlightToView  :', spotlightToView);
    //Initialize comments to show right away - prior to component mount & render of an empty array
    //This way our screen does not blink blank when a spotlight update is received
    const [messagesToShow, setMessagesToShow] = useState(  initMessges(spotlightToView) );
    const [spotlight, setSpotlight] = useState(initSpotlight(spotlightToView)); //Our local React state var for this component
    const [showFullSpotlight, setShowFullSpotlight] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    const [showSpotlightInnerMenu, setShowSpotlightInnerMenu] = useState(false);
    const [
      spotlightGiphyGridContainerDivSize,
      setSpotlightGiphyGridContainerDivSize,
    ] = useState(450); //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match
    const [showLikesList, setShowLikesList] = useState(null); //ID of Spotlight or Comment that is currently being hovered over
    // const [spotlightPreviewReplyGiphyGridContainerDivSize, setSpotlightPreviewReplyGiphyGridContainerDivSize]  = useState(400);   //Adjusted dynamically based on rendered DIV size so we can adjust the Giphy Grid to match

    // const [spotlightLikesContainerDivX, setSpotlightLikesContainerDivX]  = useState(0);   //Dynamically set the position of the LikesList
    // const likesDivID = id.toString() + "LIKESDIV";
    const giphyGridContainerDivID =
      id.toString() + 'spotlightPreviewGiphyGridContainerDivID';

    const spotlightGiphyContainerRef = useRef(); //Defining a React Reference so that we can dynamically track the width for our Giphy container

    // Auth context
    const { currentUser, isSuperAdmin, isAdmin } = useContext(AuthContext);

    // Customer context
    const { users } = useContext(CustomerContext);

    // Giphy Cache context
    const { fetchGif, giphyCache } = useContext(GiphyCacheContext);

    function initSpotlight(spotlightToView) {
      return { ...spotlightToView };
    }

    function initMessges(spotlightToView) {
      if (spotlightToView?.messages?.items?.length)
        return [...spotlightToView?.messages?.items];
      else [];
    }

    //Updated every render of the component
    useEffect(() => {
      //Update size of Giphy Containers in order to dynamically set size of grids
      if (
        spotlightGiphyContainerRef &&
        spotlightGiphyContainerRef.current &&
        spotlightGiphyContainerRef.current.clientWidth !==
          spotlightGiphyGridContainerDivSize
      )
        setSpotlightGiphyGridContainerDivSize(
          spotlightGiphyContainerRef.current.clientWidth
        );
    });

    //UseEffect to prepare our comment diplay region, including fetching any Gifs for our GIF Cache
    useEffect(() => {
      if (!spotlightToView || !spotlightToView.messages) return;
      let mounted = true;

      //Update our GIF cache based on the viewed messages
      //10.4.2023 - This successfully removed a memory leak by doing clean-up this way!
      (async () => {
        try {
          //Update our master array of GIPHY objects to render to the user based on the messages that are shown
          // const tempGiphysToShow = [... giphysToShow];  //Get a copy of our state data
          for (var i = 0; i < spotlightToView.messages.items.length; i++) {
            if (
              spotlightToView.messages.items[i].message.includes(
                GIPHY_ID_IDENTIFIER
              )
            ) {
              // if (DEBUG_MODE >= 2) console.log("Extracting GIF ID", spotlightToView.messages.items[i].message);
              const gifId = spotlightToView.messages.items[i].message.slice(
                GIPHY_ID_IDENTIFIER.length
              );
              // if (DEBUG_MODE >= 2) console.log("Extracted GIF ID", gifId);
              const tempObject = giphyCache.find(
                (msg) => msg && msg.data && msg.data.id === gifId
              );
              //Fetch the FIG from GIPHY but only IF it has not already been fetched
              if (!tempObject) await fetchGif(gifId); //Fetch from our Giphy Cache Context
            }
          }

          // if (DEBUG_MODE >= 2) console.log("Built GIPHY cache for spotlight", tempGiphysToShow);
          //Only update local if still Mounted
          //Now that we have fetched all of the giphy's update our local state to update our display, flawlessly
          if (mounted) {
            const tempSpotlight = { ...spotlightToView };
            setSpotlight(tempSpotlight);
            const tempMessages = [...spotlightToView.messages.items];
            setMessagesToShow(tempMessages);
            // if (DEBUG_MODE >= 2) console.log("Updated comments; fetched giphys", tempSpotlight, tempMessages);
          }
        } catch (err) {
          if (DEBUG_MODE >= 1)
            console.log('GIPHY Fetch Error when preparing Spotlight Card', err);
        }
      })();

      //Clean-up Function
      return () => (mounted = false); // Mark as unmounted so no state update
    }, [spotlightToView]);

    function handleSpotlightClick(e, spotlight) {
      e.stopPropagation(); //Prevent any outer OnClick from firing
      setShowSpotlightInnerMenu(false); // Close the pop-up menu, if open
      //  if (DEBUG_MODE >= 2) console.log("Click on spotlight; local spotlight handler called", spotlight);
      if (spotlightClickCallback) spotlightClickCallback(spotlight);
      else if (DEBUG_MODE >= 2) console.log('No callback registered');
    }

    const handleMouseLeave = (e) => {
      // if (DEBUG_MODE >= 2) console.log("Mouse left card",e);
      setShowSpotlightInnerMenu(false); // Close the pop-up menu, if open
      setMouseOver(false);
      // setShowFullSpotlight(false);
    };
    const handleMouseEnter = (e) => {
      // if (DEBUG_MODE >= 2) console.log("Mouse entered card",e);
      setMouseOver(true);
      // setShowFullSpotlight(true);          //Commented this out since now a CLICK displays a full modal rather than doing this on just a hover
    };

    const handleMouseLikesLeave = (e) => {
      // if (DEBUG_MODE >= 2) console.log("Mouse left LIKES",e);
      setMouseOver(false);
      setShowLikesList(null);
    };
    const handleMouseLikesEnter = (e, comment) => {
      // if (DEBUG_MODE >= 2) console.log("Mouse entered LIKES", e, comment);
      setMouseOver(true);
      if (spotlight.userLikes)
        if (spotlight.userLikes.length > 0)
          setShowLikesList(
            spotlight.id
          ); /* Show the Like List, if one exists, for this SPOTLIGHT */
    };

    function showCommentsCount(color) {
      if (!isFull && !spotlight.messages) return ' ';
      if (!isFull && spotlight.messages.items.length == 0) return ' ';
      var commentString = '0';
      if (spotlight.messages) {
        //if (spotlight.messages.items.length >0) commentString = spotlight.messages.items.length.toString() + (spotlight.messages.items.length == 1 ? " Comment" : " Comments");
        if (spotlight.messages.items.length > 0)
          commentString = spotlight.messages.items.length.toString();
        if (spotlight.messages.items.length > 99) commentString = '99+ ';
      }

      let className =
        (isSmall ? 'TextStyle2' : 'TextStyle3') +
        ' spotlightCommentCountContainer ';
      let bubbleClassName = isSmall ? 'TextStyle4' : 'TextStyle5';

      var messageBubbleSpace = '5px';
      if (isSmall) {
        messageBubbleSpace = '2px';
      }
      return (
        <div className={className} style={{ color: color }}>
          {commentString}
          <MessageIcon
            className={bubbleClassName}
            style={{
              paddingLeft: messageBubbleSpace,
              transform: 'translate(0,-0%)',
            }}
          />
        </div>
      );
    }

    async function handleLikeClick ({e, userAlreadyLike, message}) {
		
      if (DEBUG_MODE >= 2) console.log("LIKE clicked", userAlreadyLike, message);
      
      var currentUserLikes = [];
      if(message) currentUserLikes = (!message.userLikes ? [] : [...message.userLikes]); //Copy current list or init a new one for comment likes
      else currentUserLikes = (!spotlight.userLikes ? [] : [...spotlight.userLikes]); //Copy current list or init a new one for spotlight likes
         
      //Update the list of IDs
      if (userAlreadyLike) {
        
        //Remove the userID from the list
        var indexToRemove = currentUserLikes.indexOf(currentUser.id);
        if (!indexToRemove>-1) {
         currentUserLikes.splice(indexToRemove,1); //remove current user
          if (DEBUG_MODE >= 2) console.log('Removed user from spotlight / Comment LIKE list');
        } else {
          if (DEBUG_MODE >= 2) console.log('Error removing user from LIKE list.  User not found');
        }
        
        //Empty list?  If so, hide the list since currently being hovered over
        if (currentUserLikes.length == 0) setShowLikesList(null);
      } else {
        
        //Add the user to the like List
        currentUserLikes.push(currentUser.id);
        if (DEBUG_MODE >= 2) console.log('Added user to the spotlight LIKE list');
        
        //Just started a list for a comment?  If so, show list since currently being hovered over
        if (message && currentUserLikes.length == 1) setShowLikesList(message.id);
      }
   
   
      //Now, update the DB                
      try {         
        if (message){
         message.userLikes = [...currentUserLikes]; //Update with new array in memory so as to update display
         const tempSpotlight = {...spotlight};   //Copy our entire state data for the spotlight (which includes the updated comment) to trigger a re-render
         setSpotlight(tempSpotlight); //Probably not needed as parent subscription will update the input prop but ...
               
         const tempInsertedRecognitionComment = await invokeAPI(updateRecognitionComment,'updateRecognitionComment', { id: message.id,  userLikes: currentUserLikes});
         if (DEBUG_MODE >= 2) console.log('Successfully edited LIKES for Comment', tempInsertedRecognitionComment);
         
         //Perform an UPDATE to parent Spotlight to trigger a Subscription PUSH for the new comments
         if (DEBUG_MODE >= 2) console.log("Executing an UPDATE on the Spotlight table to trigger a push of comments to all clients");            
         const tempUpdatedRecognition = await storeUpdatedRecognition({id: spotlight.id});
         if (DEBUG_MODE >= 2) console.log("Successfully updated spotlight to trigger push", tempUpdatedRecognition);
        
        } else{
        
         //Update the spotlight currently being viewed by this component
          const tempSpotlight = {...spotlight};   //Copy our state datea
          tempSpotlight.userLikes = [...currentUserLikes];
          setSpotlight(tempSpotlight); //Probably not needed as parent subscription will update the input prop but ...
      
          const tempInsertedRecognition = await storeUpdatedRecognition({id: spotlight.id, userLikes: currentUserLikes});
   
          if (DEBUG_MODE >= 2) console.log('Successfully edited LIKES for Spotlight', tempSpotlight, tempInsertedRecognition);
        }
      } catch (err) {
        if (DEBUG_MODE >= 2) console.log("Error toggling LIKE for Spotlight", err);
      }
     }

    //Display likes list for the Spotlight or comment
    function displayLikeList(comment) {
      if (!spotlight) return null;
      var currentUserLikesList = '',
        arrayToUse = [];
      const NAMES_TO_DISPLAY = 5;
      if (comment) {
        if (!comment.userLikes || comment.userLikes.length == 0) return null;
        arrayToUse = comment.userLikes;
      } else {
        if (!spotlight.userLikes) return null;
        arrayToUse = spotlight.userLikes;
      }

      if (!arrayToUse) return null;

      //Build the Like list
      for (var i = 0; i < arrayToUse.length && i < NAMES_TO_DISPLAY; i++) {
        var userThatLiked = users.find((user) => user.id == arrayToUse[i]);
        if (userThatLiked)
          currentUserLikesList =
            currentUserLikesList +
            userThatLiked.firstName +
            ' ' +
            userThatLiked.lastName.substring(0, 1) +
            '.';
        // if (userThatLiked) currentUserLikesList = currentUserLikesList + getUserInitials(userThatLiked);
        if (i < arrayToUse.length - 1 && i < NAMES_TO_DISPLAY - 1)
          currentUserLikesList = currentUserLikesList + ', ';
      }
      if (arrayToUse.length > i)
        currentUserLikesList =
          currentUserLikesList +
          ' + ' +
          (arrayToUse.length - i).toString() +
          ' more';
      return <div>{currentUserLikesList}</div>;
    }

    function showLikesCount({ color }) {
      //Has this person LIKED the Spotlight / comment already?
      var userAlreadyLike = false;
      var numLikes = 0;

      let thumbClassName = isSmall ? 'TextStyle2' : 'TextStyle3';
      var iconButtonSize = 'medium';
      var flexStyle = '1';

      //Render Likes for the overall Spotlight or for a specific comment?
      if (spotlight.userLikes) {
        userAlreadyLike = spotlight.userLikes.find(
          (userID) => userID === currentUser.id
        );
        numLikes = spotlight.userLikes.length;
      }

      //Render function
      if (isFull)
        return (
          //full spotlights page version
          <div className='spotlightLikesContainer' style={{ flex: flexStyle }}>
            <button
              className='spotlight-like-btn TextStyle3V2'
              size={iconButtonSize}
              onClick={(e) =>
                handleLikeClick({ e: e, userAlreadyLike: userAlreadyLike })
              }
              onMouseLeave={handleMouseLikesLeave}
              onMouseEnter={(e) => handleMouseLikesEnter(e)}
            >
              <div
                className='TextStyle3 white likesCount ContainerNoHeightCenter'
                style={{ color: color }}
              >
                {numLikes == 0 ? 'Like' : numLikes}
              </div>
              {userAlreadyLike ? (
                <ThumbUpIcon className={thumbClassName} />
              ) : (
                <ThumbUpOffAltIcon className={thumbClassName} />
              )}
            </button>
          </div>
        );

      return (
        //dashboard version
        <div className='spotlightLikesContainer'>
          <div className={thumbClassName} style={{ color: color }}>
            {numLikes == 0 ? 'Like' : numLikes}
          </div>
          {userAlreadyLike ? (
            <ThumbUpIcon className={thumbClassName} />
          ) : (
            <ThumbUpOffAltIcon className={thumbClassName} />
          )}
        </div>
      );
    }

    function setSpotlightPreviewBackgroundBySpot(location) {
      const remainder = location % 4;
      switch (remainder) {
        case 0:
          return BALANCE_COLOR;
        case 1:
          return TEAM_COLOR;
        case 2:
          return SOCIAL_COLOR;
        case 3:
          return GROWTH_COLOR;
        // case 4: return "white";
      }
    }

    //Handle menu selection - NOTE the SCOPE is INSIDE the SubMenuDisplay so we can access the props including the specific invitation and scheduled conectivity to handle
    const handleSpotlightMenuSelection = (e) => {
      e.stopPropagation(); //Prevent outer Card OnClick from firing
      const menuAction = e.currentTarget.dataset.value;
      if (DEBUG_MODE >= 2)
        console.log(
          'SPOTLIGHT Menu item selected.',
          menuAction,
          handleEditSpotlightClick
        );

      switch (menuAction) {
        case 'EDIT':
          if (handleEditSpotlightClick) handleEditSpotlightClick(spotlight); //Callback parent and invoke edit
          setShowSpotlightInnerMenu(false); //Close the sub menu
          break;
        case 'DELETE':
          if (handleDeleteSpotlightClick) handleDeleteSpotlightClick(spotlight); //Callback parent and invoke delete
          setShowSpotlightInnerMenu(false); //Close the sub menu
          break;
      }
    };

    function spotlightInnerMenu() {
      if (!isFull || !showSpotlightInnerMenu) return null;
      return (
        <div
          className={`spotlightInnerMenuContainer TextStyle3 white-background dark-grey-text isCard `}
        >
          <MenuList>
            <MenuItem
              onClick={handleSpotlightMenuSelection}
              data-value={'EDIT'}
            >
              <div className='ContainerNoHeightCenter'>
                <Edit />
                <div style={{ paddingLeft: 'clamp(1px,0.3vw,3px)' }}>
                  Edit Spotlight
                </div>
              </div>
            </MenuItem>
            <MenuItem
              onClick={handleSpotlightMenuSelection}
              data-value={'DELETE'}
            >
              <div className='ContainerNoHeightCenter'>
                <DeleteOutline />
                <div style={{ paddingLeft: 'clamp(1px,0.3vw,3px)' }}>
                  Delete Spotlight
                </div>
              </div>
            </MenuItem>
          </MenuList>
        </div>
      );
    }

    function spotlightInnerMenuIcon() {
      if (!isFull) return null;
      if (!isAdmin && !isSuperAdmin && currentUser?.id != spotlight.senderID)
        return null;

      return (
        <div className={`spotlightInnerMenuIconContainer  `}>
          <IconButton
            size='medium'
            onClick={(e) => {
              e.stopPropagation();
              setShowSpotlightInnerMenu(!showSpotlightInnerMenu);
            }}
          >
            <MoreVertIcon className='dark-grey-text' />
          </IconButton>
        </div>
      );
    }

    //Render function
    const maxUsersToShow = 4;
    var scaleString = isSmall ? 'scale(0.70)' : 'scale(1.0)';
    const outerBackgroundColor = setSpotlightPreviewBackgroundBySpot(id);
    var styleObject =
      mouseOver && !isFull
        ? { transform: 'scale(1.05)', zIndex: '1' }
        : { transform: 'scale(1.0)', backgroundColor: outerBackgroundColor };
    if (noBoxShadow) styleObject.boxShadow = 'none'; //Otherwise, use the CSS value for FULL
    var styleObjectCard =
      mouseOver && isSmall ? { boxShadow: '0px 4px 8px 0 #BDC9D7' } : {};
    var senderAvatarClassName = 'avatarImageSmall';
    var senderAvatarInitialsClassName = 'avatarInitials TextStyle3 white';

    if (isSmall) {
      senderAvatarClassName = 'avatarImage avatarImageTiny';
      senderAvatarInitialsClassName = 'avatarInitialsTiny TextStyle2 white';
    }
    if (isFull) {
      senderAvatarClassName = 'avatarImage avatarImageMedium';
    }

    if (!spotlight || !spotlight.id) return null; //Confirm not an empty object
    else
      return (
        <div className='ModelContainer' hidden={!showModal}>
          <div
            hidden={!showModal}
            className='ModalBgContainer'
            onClick={handleClose}
            onScroll={(e) => e.stopPropagation()}
          />
          <div
            className='ModalOuterCard ConnectivityInfoViewPaper'
            style={{ maxHeight: 'calc(70vh - 3em)' }}
          >
            <div className='SpotlightModalCard boxShadow spotlightContentContainer ConectivitiesDetailedViewCard'>
              <CloseIcon
                onClick={handleClose}
                className='TextStyle6 pointer dust-grey connectivityModalCloseIcon'
              />

              <div className='spotlightContentContainer'>
                <div className='homeContainer3 navbarRightContainerSpacing black'>
                  <HighlightIcon className='rotate lightIcon' />
                  <span className='TextStyle7V2 bold black-text'>Spotlight</span>
                  <HighlightIcon className='rotateOpposite lightIcon' />
                </div>
              </div>
              {/* <div className='fullWidth position-absolute ContainerNoHeightSpaceAround'>
                <img src={CONECTERE_CONFIG_DATA.LEFT_FOX_LIGHT} alt='' />
               
                <img src={CONECTERE_CONFIG_DATA.RIGHT_FOX_LIGHT} alt='' />
              </div> */}
              <div className='spotlightInnerContainer ContainerVerticalCenter spotlightCommongap position-relative'>
                <Torch />
                <Torch opposite />
                {/* <hr className='fullWidth silverColor margin-none' /> */}
                <div className='ContainerVerticalCenter paddingTop navbarRightContainerSpacing spotlightInfoContainer'>
                  <div className='ContainerNoHeightCenter fullWidth'>
                    <div className='spotlightRecognizedUsersAvatarsWrapper'>
                      {displayRecognizedUsersAvatars({
                        recognizedEmployees: spotlight
                          ? spotlight?.recognizedEmployees?.items
                          : null,
                        maxUsersToShow: maxUsersToShow,
                        isSmall: false,
                        isFull: isFull,
                      })}
                    </div>
                  </div>
                  <div className='ContainerVertical fullWidth'>
                    <p className='textAlignCenter TextStyle3V2 black-text'>
                      {spotlight.message}
                    </p>
                    <p className='textAlignCenter TextStyle3V2 black-text'>
                      Keep shining and inspiring us all!
                    </p>
                  </div>
                  <div className='ContainerNoHeightSpaceBetween fullWidth'>
                    <div className='ContainerNoHeightCenter animatedRingsInnerWrapper'>
                      <div className='homeContainer3 gap10'>
                        {!spotlight.senderAvatarUrl ? (
                          <div className='ContainerNoHeightCenter userAvatar white'>
                            {spotlight.senderAvatarInitials}
                          </div>
                        ) : (
                          <img
                            className='userAvatar'
                            src={spotlight.senderAvatarUrl}
                            alt={spotlight.senderAvatarInitials}
                          />
                        )}
                        <div className='ContainerVerticalLeft TextStyle2V2 silver'>
                          <span>{timeAgo(spotlight.createdAt)}</span>
                          <span>By {spotlight.senderAvatarName}</span>
                        </div>
                      </div>
                      <div>
                        {!spotlight.amount > 0 ? null : (
                          <div className='homeContainer3 coinCountSpan '>
                            <span className='TextStyle4V2 black-text bold'>
                              {!spotlight ? '' : spotlight.amount}
                            </span>
                            <div className='ccImgMediumSquare homeContainer3'>
                              {' '}
                              <img
                                src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH}
                                alt=''
                                height='100%'
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='ContainerNoHeightCenter navbarRightContainerSpacing'>
                      {showLikesCount({ color: outerBackgroundColor })}
                      {/* <button
                      className='borderRadius noBoxShadow very-light-grey-border grey-text buttonStyle2 buttonStyle1HeaderBlue imageEditModalStyle white-background'
                      style={{ borderRadius: '6px' }}
                      onClick={handleClose}
                    >
                      Close
                    </button> */}
                    </div>
                  </div>
                  <div className='ContainerNoHeightSpaceBetween fullWidth paddingTop'>
                    <h4 className='TextStyle7V2 black-text'>Join the chat</h4>
                    {/* <div className='ContainerNoHeightCenter shareCard black-text'>
                      Share
                      <FacebookOutlinedIcon className='gray-blue avatarImageTiny' />
                      <TwitterIcon className='avatarImageTiny header-blue2' />
                      <InstagramIcon className='avatarImageTiny' />
                      <LinkedInIcon className='avatarImageTiny header-blue2' />
                      <YouTubeIcon className='avatarImageTiny red' />
                    </div> */}
                  </div>
                  <div className='fullWidth animatedRingsInnerWrapper ContainerVertical'>
                    {/* {spotlight?.messages?.items?.map((chat) => (
                      <ChatCard
                        placeholderMessage='Add a comment!'
                        key={chat.id}
                        chatInfo={chat}
                        currentUser={currentUser}
                        username={chat.senderAvatarName}
                        time={timeAgo(chat.createdAt)}
                        message={chat.message}
                      />
                    ))}
                    <CommentInput spotlight={spotlight} /> */}

                    <ChatWindowV2
                      placeholderMessage='Add a comment!'
                      recognitionID={spotlightToView.id}
                      currentUser={currentUser}
                      headerColor={outerBackgroundColor}
                      messagesToShow={messagesToShow}
                      noFixedHeight={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
);

export default SpotLightInfoViewer;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021

//  ADMIN page for batch creating users from a csv file

import "./adminEmployeeImport.css"

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, TEAM_COLOR, COLOR_BLUE_HEADER, configDetailsInitialState, progressDataInitialState, walletInitialState, USER_INITIAL_STATE, userNumbersInitialState} from '../../shared/data/conectereConfigData';

//React & Amplify
import React, { useEffect, useState, useContext } from 'react';

import {Authenticator } from '@aws-amplify/ui-react';

//Bootstrap and other React components
import Button from 'react-bootstrap/Button';
import Select from 'react-select';   //Version with support from multiple selections
import Checkbox from '@mui/material/Checkbox';

//Our components
import { FileUploader } from  "../../shared/Components/fileUpload/fileUpload";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import { InputFieldText } from  "./Components/inputFieldText/inputFieldText";

//Material UI and my components therefrom
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";

//Icons

//Our Components

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context

//Utils
import {  invokeCreateUser, isCognitoUserNameAvailable, isValidEmail } from "../../shared/utils/userAndTeamUtils";
import { fetchConfigByCustomer } from "../../shared/utils/databaseUtils";
import { csvToArray } from "../../shared/utils/generalUtils";

import moment from 'moment';

//Here's the start of our Javascrpt App
const AdminEmployeeImport = () => {

   //Context
   const { authState,  currentUser, isSuperAdmin, isAdmin, } = useContext(AuthContext);    
   const { setShowSpinner } = useContext(DisplayContext); 
   const { users, teams,  customerOptions,   } = useContext(CustomerContext); 
   
    //State data
    const [fileUploaded, setFileUploaded] = useState(null);
    const [isUpdatingBackend, setIsUpdatingBackend] = useState(false);  //Used as a state variable set to TRUE when updating the backend
    const [userRows,setUserRows]=useState([]);   //Formatted rows for user grid
    const [selectedCustomerOptions, setSelectedCustomerOptions] = useState(null);
    const [arrayOfImportedData, setArrayOfImportedData] = useState([]);

    //state variables for modal messages
    const [errorMessages, setErrorMessages] = useState("");
    const [showMessagingModal, setShowMessagingModal] = useState(false)
    const [importState, setImportState] = useState("INIT"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT

 
    
    const userColumnsImport = [
    {
        field: 'name',
        headerName: 'Employee',
        headerAlign: 'center',
        disableColumnMenu:true,
        align:'center',
        minWidth: 125,   
        flex:1,
        editable: false,
        color:'white',
        sortable: true,
        renderHeader: (params)=> {
         return ( <div className="ContainerNoHeightCenter"> Employee </div>  )
         }, 
        renderCell: (params)=> {
         return (
               <div style={{whiteSpace: "normal", lineHeight:"normal", textAlign:"center", wordBreak: "break-word" }}> {params.row.name} </div>
            );
         },
      },
      {
        field: 'title',
        headerName: 'Title',
        headerAlign: 'center',
        disableColumnMenu:true,
        align:'center',
        sortable: false,
        minWidth: 125,
        flex:1,
        color:'white',
        editable: false,
        renderHeader: (params)=> {
         return ( <div className="ContainerNoHeightCenter "> Title </div>  )
         }, 
        renderCell: (params)=> {
         return (
               <div style={{whiteSpace: "normal", lineHeight:"normal", textAlign:"center", wordBreak: "break-word" }}> {params.row.title} </div>
            );
         },        
        },    
      {
        field: 'email',
        headerName: 'Email',
        headerAlign: 'center',
        disableColumnMenu:true,
        align:'center',
        sortable: true,
        minWidth: 124,
        flex:1,
        color:'white',
        editable: false,
        renderHeader: (params)=> {
            return ( <div className="ContainerNoHeightCenter "> Email </div>  )
         }, 
         renderCell: (params)=> {
         return (
               <div style={{whiteSpace: "normal", lineHeight:"normal", textAlign:"center", wordBreak: "break-word" }}> {params.row.email} </div>
            );
         },        
        },              
      {
        field: 'pronouns',
        headerName: 'Pronouns',
        headerAlign: 'center',
        align:'center',
        disableColumnMenu:true,
        sortable: false,
        minWidth: 90,
        flex:1,
        color:'white',
        editable: false,
        renderHeader: (params)=> {
            return ( <div className="ContainerNoHeightCenter "> Pronouns </div>  )
         },        
        renderCell: (params)=> {
         return (
               <div style={{whiteSpace: "normal", lineHeight:"normal", textAlign:"center", wordBreak: "break-word" }}> {params.row.pronouns} </div>
            );
         },        
        },   
     {
         field: 'anaytics',
         headerName: 'Anaytics',
         headerAlign: 'center',
         disableColumnMenu:true,
         minWidth: 90,
         flex:1,
         editable: false,
         sortable: false,
         color:'white',
         hide: (!isAdmin),
         renderHeader: (params)=> {
            return (
               <div className="ContainerNoHeightCenter ">
                     Analytics
               </div>
            )
         },
         renderCell: (params)=> {
            return (
            <div className="ContainerNoHeightCenter" style={{width:"100%"}}>
               <Checkbox
               disabled={false }
               checked={params.row.permissionAnalytics}  
               onChange={event => togglePermission(params.row.id-1, 'permissionAnalytics')}
               inputProps={{'aria-label': 'controlled' }}
               />
            </div>
            );
         }
      }, 
      {
         field: 'editor',
         headerName: 'Conectivity Creation',
         headerAlign: 'center',
         minWidth: 100,
         flex:1,
         disableColumnMenu:true,
         editable: false,
         sortable: false,
         color:'white',
         hide: (!isAdmin),
         renderHeader: (params)=> {
           
            return (
               <div className="ContainerNoHeightCenter  ">
                     Conectivity Creation
               </div>
            )
         },
         renderCell: (params)=> {
           return (
               <div className="ContainerNoHeightCenter" style={{width:"100%"}}>
                  <Checkbox
                  disabled={false }
                  checked={params.row.permissionEditor}  
                  onChange={event => togglePermission(params.row.id-1, 'permissionEditor')}
                  inputProps={{'aria-label': 'controlled' }}
                  />
               </div>
           );
          }
       },                    
       {
         field: 'store',
         headerName: 'Product Selection',
         headerAlign: 'center',
         minWidth: 75,
         flex:1,
         disableColumnMenu:true,
         editable: false,
         sortable: false,
         color:'white',
         hide: (!isAdmin),
         renderHeader: (params)=> {
           
            return (
               <div className="ContainerNoHeightCenter TextStyle3 white">
                     Store Mgmt
               </div>
            )
         },
         renderCell: (params)=> {
           
           return (
               <div className="ContainerNoHeightCenter" style={{width:"100%"}}>
                  <Checkbox
                  disabled={false }
                  checked={params.row.permissionOrders}  
                  onChange={event => togglePermission(params.row.id-1, 'permissionOrders')}
                  inputProps={{'aria-label': 'controlled' }}
                  />
               </div>
           );
          }
       }, 
      {
         field: 'billing',
         headerName: 'Billing',
         headerAlign: 'center',
         minWidth: 100,
         disableColumnMenu:true,
         flex:1,
         editable: false,
         sortable: false,
         color:'white',
         hide: (!isAdmin),
         renderHeader: (params)=> {
           
            return (
               <div className="ContainerNoHeightCenter  TextStyle3 white">
                     Billing
               </div>
            )
         },
         renderCell: (params)=> {  
           return (
               <div className="ContainerNoHeightCenter" style={{width:"100%"}}>
                  <Checkbox
                  disabled={false }
                  checked={params.row.permissionBilling}  
                  onChange={event => togglePermission(params.row.id-1, 'permissionBilling')}
                  inputProps={{'aria-label': 'controlled' }}
                  />
               </div>
           );
          }
       }, 
      ];

     //Generic function for toggling a permission for a user at a specified row
     // rowIndex - INT index for the row
     // permission - String label for the permission
     function togglePermission (rowIndex, permission) {
      if (DEBUG_MODE >= 2) console.log("Toggling Employee permissions", rowIndex, permission);
      if (DEBUG_MODE >= 2) console.log("Current permission value:", arrayOfImportedData[rowIndex][permission]);
     //Update state
      const tempArrayOfImportedData = [...arrayOfImportedData];   //Copy the origninal data
      tempArrayOfImportedData[rowIndex] = {...arrayOfImportedData[rowIndex], [permission]: !arrayOfImportedData[rowIndex][permission]};
      setArrayOfImportedData(tempArrayOfImportedData); //Update React State
      if (DEBUG_MODE >= 2) console.log('Successfully locally toggled user permssion', tempArrayOfImportedData);
  }    

    // React to change in status for the file uploaded state data
    useEffect(() => {
        readNewFile();
    }, [fileUploaded]);
    
    
   const ourFileReader = new FileReader();
      
   function readNewFile() {   
      if (fileUploaded) {     
         if (DEBUG_MODE >= 2) console.log("Read New File function called", fileUploaded);          
         ourFileReader.onload = processNewFile;          
         ourFileReader.readAsText(fileUploaded);                
      }        
   }  
 
   function processNewFile(e) {
      const textFromFile = e.target.result;
      if (DEBUG_MODE >= 2) console.log("Process New File function called", textFromFile);
      const tempArrayOfImportedData = csvToArray(textFromFile, ",");
      if (DEBUG_MODE >= 2) console.log("Generated array of CSV data", tempArrayOfImportedData);
      setArrayOfImportedData(tempArrayOfImportedData);
      validateLoadedFiled(tempArrayOfImportedData);
   }   
  
       // React to change in status for the file uploaded state data
       useEffect(() => {
         buildUserRows();
     }, [arrayOfImportedData]);
    
    function buildUserRows() {    
      if (!arrayOfImportedData || arrayOfImportedData.length === 0) {
         if (DEBUG_MODE > 0) console.log("No user records; skipping build", arrayOfImportedData);
         return;
      }
        if (DEBUG_MODE > 0) console.log("Building user rows", arrayOfImportedData);
        
        //Process CSV file to gen the data grid
        
        //Build rows for Data Grid
        var tempUserRows = [];         
        
        for (var i = 0; i<arrayOfImportedData.length; i++){

             //Set initial user values based on import
            tempUserRows[i] = {
               id:i+1,
               name:arrayOfImportedData[i].firstname + (!arrayOfImportedData[i].middlename ? "" : " " + arrayOfImportedData[i].middlename.substr(0,1)) + " " + arrayOfImportedData[i].lastname,
               title:arrayOfImportedData[i].title,
               email: arrayOfImportedData[i].email,
               pronouns: arrayOfImportedData[i].pronouns,
               avatarUrl:arrayOfImportedData[i].avatarurl,
               permissionAnalytics:arrayOfImportedData[i].permissionAnalytics  ? true : false,
               permissionLaunch: arrayOfImportedData[i].permissionLaunch  ? true : false,
               permissionEditor: arrayOfImportedData[i].permissionEditor  ? true : false,
               permissionOrders: arrayOfImportedData[i].permissionOrders  ? true : false,
               permissionBilling: arrayOfImportedData[i].permissionBilling  ? true : false,         
               canBeMentor: arrayOfImportedData[i].canBeMentor ? true : false,          // User can be selected as a Mentor
               canBeSponsor: arrayOfImportedData[i].canBeSponsor ? true : false,        // User can be selected as a Sponsor
               canBeSupervisor:arrayOfImportedData[i].canBeSupervisor ? true : false,   // User can be selected as a Supervisor
               isUnderrepresentedGroup:arrayOfImportedData[i].isUnderrepresentedGroup ? true : false,                                      
 
            };
        }
        
        if (DEBUG_MODE >= 2) console.log("Built User Rows For Import:", tempUserRows, arrayOfImportedData);
        setUserRows(tempUserRows);
    }

      function validFileType(file) { 
         const fileTypes = [`text/csv`];
         return fileTypes.includes(file.type);
      }
    


   const handleFileUpload = (e) => {
      setShowMessagingModal(false);
      setErrorMessages([]);
      const file = e.target.files[0];  //grab the first file in the list of files selected by the user

      try {
         if (validFileType(file)) {
            const localURL = URL.createObjectURL(file);
            console.log ("URL to local file in web browser:", localURL);
            setFileUploaded(file);  //save file for uploading to S3 bucket if the user hits the ADD button
            console.log ("Successfully generated internal URL and stored FILE locally; triggering validation process");
         } else {
            if (DEBUG_MODE >= 2) console.log("Error, wrong file type", file.type);
            const tempErrorMessages=["Error, wrong file type.  Please select a CSV file as input."];
            setErrorMessages(tempErrorMessages);
            setImportState("ERRORS"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT           
            setShowMessagingModal(true);
         }
      } catch (err) {
         if (DEBUG_MODE >= 2) console.log("Error selecting file", err);
         const tempErrorMessages=["Ooops - something went wrong. " + err];
         setErrorMessages(tempErrorMessages);
         setImportState("ERRORS"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT
         setShowMessagingModal(true);
      }
   };


   //define function to handle submitButton, which is used to kick off the import process
   async function handleSubmit() {      
      if (DEBUG_MODE >= 2) console.log("SUBMIT handler to create new users - top", arrayOfImportedData);

      //Safety check
      if (!arrayOfImportedData || arrayOfImportedData.length === 0) return;

      setShowSpinner(true); //Pop up spinners to lock screen
      setIsUpdatingBackend(true); //disable buttons on main page

      //Set Customer for the batch
      let tempCustomerID ="", tempCustomerConfigDetails;

      //if not a Super Admin then set the company to this user's company
      if (!isSuperAdmin) {
         tempCustomerID = currentUser.customerID;
         tempCustomerConfigDetails = {...currentUser.customer.configDetails};    //Shallow copy the customer
      } else {           
         //Grab THAT customer's config details
         tempCustomerID = selectedCustomerOptions.id;   
         tempCustomerConfigDetails = await fetchConfigByCustomer(tempCustomerID);
         if (DEBUG_MODE >= 2) console.log("Fetched that company's config details for SuperAdmin", tempCustomerConfigDetails);
      }
 
      //Grab the Admin's timezone 
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (DEBUG_MODE >= 2) console.log("Local TimeZone: " + timezone);   
      
      //Set start date for all employees as 9:00 AM TODAY; this will trigger an invitation and auto-scheduling of SC for tutorial #1
      let startTimeNewEmpMom = moment().tz(timezone ? timezone : 'America/New_York');
      startTimeNewEmpMom.set({'hour':9, 'minute':0, 'second':0});
      if (DEBUG_MODE >= 2) console.log("set employee start date / time:", startTimeNewEmpMom.format());


      //Loop through the array of user data
      for(const userToImport of arrayOfImportedData) {

         //invoke backend to create the user record
         try {       

            let userInputData = {
               firstName: userToImport.firstname,
               middleName: (!userToImport.middlename ? "" : userToImport.middlename),
               lastName: userToImport.lastname,
               pronouns: userToImport.pronouns,
               email: userToImport.email,
               title: userToImport.title,
               
               permissionAnalytics:userToImport.permissionAnalytics  ? true : false,
               permissionLaunch: userToImport.permissionLaunch  ? true : false,
               permissionEditor: userToImport.permissionEditor  ? true : false,
               permissionOrders: userToImport.permissionOrders  ? true : false,
               permissionBilling: userToImport.permissionBilling  ? true : false,

               canBeMentor: userToImport.canBeMentor ? true : false,          // User can be selected as a Mentor
               canBeSponsor: userToImport.canBeSponsor ? true : false,        // User can be selected as a Sponsor
               canBeSupervisor:userToImport.canBeSupervisor ? true : false,   // User can be selected as a Supervisor
               isUnderrepresentedGroup:userToImport.isUnderrepresentedGroup ? true : false,                                      
            }

            const params = {
               cmdIssuerID:currentUser.id,
               customerID:tempCustomerID,
               userInputData: userInputData,       //data object of user to create
               teams:teams,                        //Pointer to our overall Teams array
               customerConfigDetails:tempCustomerConfigDetails,
               bio:'',
               avatarHeight:0,
               avatarWidth:0,
               avatarUrl:'',
               selectedTeamsOptions:null,
               mentorID:  "",
               sponsorID:  "",
               supervisorID:  "",
               startDate: (startTimeNewEmpMom ? startTimeNewEmpMom.toISOString() : null),
               timeZoneCode:(timezone ? timezone : 'America/New_York'),
               isFromBatchUpload: true,
            };

            if (DEBUG_MODE >= 2) console.log("Creating new user", params);
            const result = await invokeCreateUser(params);

            if (result && result.successFlag) {
               if (DEBUG_MODE >= 2) console.log('Successfully imported user ', userToImport.firstName + ' ' + userToImport.lastName);            
            } else {
               if (DEBUG_MODE >= 2) console.log('error IMPORTING user ', params);            
            }
         } catch (err) {
            if (DEBUG_MODE >= 2) console.log('error IMPORTING user', userToImport);            
         }                
      }
         

      setFileUploaded(null);                          //clear the file itself
      setArrayOfImportedData([]);                     //
      setIsUpdatingBackend(false); //enable buttons on main page
      setShowSpinner(false); //Hide spinners
      setErrorMessages([]);
      setImportState("SUCCESS"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT
      setShowMessagingModal(true);
      setUserRows([]);
   }
  

    //Function to handle user removing CSV file
    const handleClearFile = () => {
        setFileUploaded(null);          //clear file
        setArrayOfImportedData([]);     //Clear the array generated from the CSV file 
        setUserRows([]);                //Clear the grid
        setShowMessagingModal(false);
        setImportState("INIT"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT
        setErrorMessages([]);    
        if (DEBUG_MODE >= 2) console.log("Removed CSV file.");
    };

    
    //Functions for displaying any result messages
    function displaySuccessMessages () {          
        return (
            <div className="ContainerVerticalCenter">
                  <h4 className="purple"> Employees successfully created!</h4 >
                  <div className="TextStyle3" > 
                     Each employee should receive a Conectere email with their login credentials.  Enjoy connecting with Conectere!
                  </div >
            </div>
         );
    }   
    function displayImportValid() {
      return (
         <div className="ContainerVerticalCenter">
            <h4 className="purple" > Import file looks good! 👍</h4 >
            <div className="TextStyle3" > 
               No errors detected in the import file.  Please review the extracted user records and adjust the user permissions as necessary.
            </div >
            <div className="TextStyle3" > 
               Hit the <span className="purple">Create Users</span> button when ready!!
            </div >            
         </div>
      );
    }

    function displayErrorMessages () {
        //Safety check
        if (!fileUploaded || !errorMessages || errorMessages.length === 0) return null;
        
        return (
           <div className="ContainerVerticalCenter" >
                <h4 className="purple">  Errors detected in import data - please fix to proceed </h4 >
                <div style={{borderBottom: "1px solid red", width:"80%"}}> </div>
                {errorMessages.map((message, index) => ( 
                    <div key={'error'+index.toString()} className="TextStyle4 red">  {message}  </div>
                ))}
            </div>
         );
    }   
    

   async function validateLoadedFiled(arrayOfImportedData) {   
      if (DEBUG_MODE >= 2) console.log("Validating array of data", arrayOfImportedData);

      const tempErrorMessages = [];  
      if (arrayOfImportedData && arrayOfImportedData.length > 0) {
         setShowSpinner(true);

         //Test every user row
         for (var i = 0; i<arrayOfImportedData.length; i++){

            //Confirm each entry has the required fields
            //Check FIRST NAME
            if (!arrayOfImportedData[i].firstname  || arrayOfImportedData[i].firstname.length===0) {
               tempErrorMessages.push("User row[" + (i+1).toString() + "] missing first name");
            }

            //OPTIONAL Check MIDDLE NAME or INITIAL
 /*           if (!arrayOfImportedData[i].middlename || arrayOfImportedData[i].middlename.length===0) {
               tempErrorMessages.push("User row[" + (i+1).toString() + "] missing middle name or initial");
            }
*/
            
            //Check LAST NAME
            if (!arrayOfImportedData[i].lastname || arrayOfImportedData[i].lastname.length===0) {
               tempErrorMessages.push("User row[" + (i+1).toString() + "] missing last name");
            }

            //Check EMAIL
            if (!arrayOfImportedData[i].email) {
               tempErrorMessages.push("User row[" + (i+1).toString() + "] email required");
            } else if (!arrayOfImportedData[i].email || !isValidEmail(arrayOfImportedData[i].email)){
               tempErrorMessages.push("User row[" + (i+1).toString() + "] invalid email: " + arrayOfImportedData[i].email);
            } else if (users && users.some((user) => user.email.toLowerCase() === arrayOfImportedData[i].email)) {
               console.log("Email already exists as Username in DynamoDB - ", arrayOfImportedData[i].email);
               tempErrorMessages.push("User row[" + (i+1).toString() + "] - An account already exists with email " + arrayOfImportedData[i].email );
            } else {
               const result = await isCognitoUserNameAvailable(arrayOfImportedData[i].email, currentUser.customerID);  //Passed all the above? Check COGNITO to confirm availability
               if (!result || !result.successFlag) {
                  console.log("Email already exists as Username in Cognito - ", arrayOfImportedData[i].email);
                  tempErrorMessages.push("User row[" + (i+1).toString() + "] - An account already exists with email " + arrayOfImportedData[i].email );      
               }
            }
         } //End FOR each user row
      } //End IF Array

      if (tempErrorMessages.length > 0) {
         if (DEBUG_MODE >= 2) console.log("Import error messages generated", tempErrorMessages, arrayOfImportedData);
         setImportState("ERRORS"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT
      } else {
         if (DEBUG_MODE >= 2) console.log("Import file valid", arrayOfImportedData);
         setImportState("UPLOAD_VALID"); // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT
      }
      setErrorMessages(tempErrorMessages); 
      setShowSpinner(false); 
      setShowMessagingModal(true); 
   }
    

    function  handleCloseMessagingModal () {
        setShowMessagingModal(false);
        //Did we just inform the user of errors with the file?  If so, discard the file and restart the state flow upon close of the modal
        if (importState === "ERRORS") handleClearFile();
    }    

    //Handle access by unauthenticated user
    if (authState !== "signedin"  || !currentUser) {
      if (DEBUG_MODE >= 2) console.log("User not authenticated", authState);
      return null;
    }
 
    //display content of messaging modal based on import state
    function displayMessageModalContent() {
      if (!importState) return null;

      // INIT => ERRORS => UPLOAD_VALID => SUCCESS => INIT
      switch(importState) {
         case 'ERRORS': return displayErrorMessages();
         case 'UPLOAD_VALID':  return displayImportValid();
         case 'SUCCESS':  return displaySuccessMessages();
         default: return null;
      }
    }

    //Render function for authenticated user 
    //confirm access to this page
  if (!isSuperAdmin && !isAdmin) {
      return (
          <Authenticator>
          <div>
            <p></p>
                <h3 className="sectionHeader">EMPLOYEE IMPORT</h3>
                <center>Sorry, off limits</center>
          </div>
          </Authenticator>
      );
  } else  return (
     <div className="adminPage">
        <Authenticator>         
            <div className="dashboardContainer boxShadow" style={{position:"relative"}}>
                <div className="dashboardTitle">
                    Employee Import
                 </div>   

                <div className="ContainerNoHeightSpaceAround fullWidth" >               
 
                     <div className="ContainerVerticalCenter">
                        <h5 className="purple" > Employee records </h5>
                        <div className="dashboardScoreCircle white-border">
                           {(!errorMessages || errorMessages.length === 0) && userRows ? userRows.length : 0}
                        </div>
                    </div> 

                    <div className="ContainerVerticalCenter" style={{padding:"40px 0px"}}>
                           {!fileUploaded  ? 
                                 <FileUploader
                                    imageChangeCallback={handleFileUpload} 
                                    fileTypesToAccept=".csv"
                                    buttonText="Upload CSV File"
                                    fileIconFlag={true}
                                 />   
                           : 
                              <>
                                       {!errorMessages || errorMessages.length === 0 ? 
                                          <div className="adminEmployeeImportLoadedIndicator" >
                                             <div>&#10003;</div> 
                                          </div>
                                       :
                                          <div className="adminEmployeeImportLoadedIndicator" style={{backgroundColor:"red"}}>
                                             <div>X</div>
                                          </div>
                                       }
                                       
                                 <div style={{padding:"20px 0px"}}>
                                       <Button size="sm" variant="outline-secondary" onClick={handleClearFile}><div className="buttonTextBlack"> Clear File</div></Button>
                                 </div>
                              </>
                           }

                     </div> 

                     {!isSuperAdmin ? "" :
                        <div className="ContainerVerticalCenter"> 
                           <h5 className={`${!selectedCustomerOptions ? 'red' : ''}`}> Customer for import?</h5>
                           <Select 
                              isDisabled={isUpdatingBackend}
                              isMulti={false} 
                              name="customerDropdown" 
                              options={customerOptions} 
                              onChange={setSelectedCustomerOptions} 
                              value={selectedCustomerOptions} 
                              placeholder=" -- select  --"                            
                           />
                        </div>
                     }
                </div>          

               {importState === "UPLOAD_VALID" || importState === "SUCCESS" ?

                  <div className="TextStyle4">
                     <div > 1. Review the extracted data to confirm accuracy</div>
                     <div > 2. Set any permissions for the employees</div>
                     <div  style={{paddingLeft:"20px"}}><span className="purple">Analytics</span> - enables the user to access enterprise-wide analytics, dashboards and visualizations</div>
                     <div  style={{paddingLeft:"20px"}}><span className="purple">Conectivity Creator</span> - enables the user to create company-specific conectivities</div>
                     <div  style={{paddingLeft:"20px"}}><span className="purple">Store Management</span> - enables the user to add and remove items from your employee reward center</div>
                     <div  style={{paddingLeft:"20px"}}><span className="purple">Billing</span> - designates the user as the recipient of electronic invoices</div>
                     <div > 3. Once satisfied, select the <span className="purple">Create Users</span> button to initiate the set up process</div>
                  </div>  
               :
                  <>
                     <div className="TextStyle4">
                        <div > 1. Generate a CSV file having the column headers shown below</div>
                           <div  style={{paddingLeft:"20px"}}><span className="red">*</span> = required</div>
                           <div  style={{paddingLeft:"20px"}}><span className="purple">**</span> = middle name or initial recommended</div>
                        <div > 2. Upload the CSV file using the UPLOAD button to the right</div>
                     </div>

                     <div className="ContainerVerticalCenter" style={{paddingTop:"clamp(8px,2vw,20px)"}}>
                        <h5 > Example CSV Format </h5>

                        <table>
                              <tr className="adminEmployeeImportTableHeader TextStyle4">
                                 <th className="adminEmployeeImportTableHeader">firstName<span className="red">*</span></th>
                                 <th className="adminEmployeeImportTableHeader">middleName<span className="purple">**</span></th>
                                 <th className="adminEmployeeImportTableHeader">lastName<span className="red">*</span></th>
                                 <th className="adminEmployeeImportTableHeader">title</th>
                                 <th className="adminEmployeeImportTableHeader">email<span className="red">*</span></th>
                                 <th className="adminEmployeeImportTableHeader">pronouns</th>
                              </tr>
                              <tr className="TextStyle3">
                                 <td className="adminEmployeeImportTableData">Robert</td>
                                 <td className="adminEmployeeImportTableData">J</td>
                                 <td className="adminEmployeeImportTableData">Franks</td>
                                 <td className="adminEmployeeImportTableData">V.P. Renewable Packaging</td>
                                 <td className="adminEmployeeImportTableData">rob.franks@myco.com</td>
                                 <td className="adminEmployeeImportTableData">he/him/his</td>
                              </tr>
                              <tr className="TextStyle3">
                                 <td className="adminEmployeeImportTableData">Linda</td>
                                 <td className="adminEmployeeImportTableData">Sarah</td>
                                 <td className="adminEmployeeImportTableData">Cooper</td>
                                 <td className="adminEmployeeImportTableData">Chief People Officer</td>
                                 <td className="adminEmployeeImportTableData">lcooper@myco.com</td>
                                 <td className="adminEmployeeImportTableData">she/her/hers</td>
                              </tr>
                        </table>
                     </div>
                  </> 
               }

               {importState !== "UPLOAD_VALID"  ? null : 
                  <>              
                     <div className = "ContainerNoHeightSpaceBetween fullWidth" style={{marginTop:"40px"}}>
                        <h4>USER RECORDS</h4>
                        <button  className="buttonStyle1 buttonStyle1Purple" aria-label="save" onClick={handleSubmit} 
                                 disabled={isUpdatingBackend || (errorMessages.length > 0 || (isSuperAdmin && !selectedCustomerOptions))} >
                           Create Users 
                        </button>
                     </div>
                     <div className="dataGridWrapperTop">
                        <MenuDataGrid dataGridRows={userRows} dataGridColumns={userColumnsImport}/>
                     </div>
                 </>
               }
            </div>

            <ModalNoBackground showModal={showMessagingModal } closeCallback={ handleCloseMessagingModal} >
               <div className="modalNoBkgInnerCard" >
                  <div className="modalNoBkgCenterColumn" style={{padding:"20px"}} > {displayMessageModalContent()} </div>
                  <div className="modalNoBkgFooter" style={{paddingTop:"clamp(8px,2vw,20px)"}}>
                     <div className="ContainerNoHeight">
                        <button className="buttonStyle1 buttonStyle1DavyGray" aria-label="cancel" onClick={ handleCloseMessagingModal}> Close  </button>
                     </div>
                  </div>
               </div>
            </ModalNoBackground>                   
 
        </Authenticator>
    </div>

  );
};




export default AdminEmployeeImport;


//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import  {   CONECTERE_CONFIG_DATA, DEBUG_MODE, GIPHY_SDK_KEY_WEB, COLOR_GOLD, COLOR_BLUE_HEADER, BALANCE_COLOR, SOCIAL_COLOR, TEAM_COLOR, GROWTH_COLOR, GIPHY_ID_IDENTIFIER  } from '../../data/conectereConfigData';

//Amplify, React
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';

//Context
import { GiphyCacheContext } from '../../context/giphyCacheContext';
import { AuthContext } from '../../context/authContext';            //User Authentication context

//Utils
import { setHeaderColorByStringLength } from "../../utils/conectivityUtils"; 
import { EmojiButton, SendButton,  GIFButton, UploadPhotoButton, validImageFileType} from "../../utils/generalUtils";


//GIPHY support
import { Gif, 
	// Carousel,
	Grid as GiphyGrid,
	SearchBar, // the search bar the user will type into
	SearchContext, // the context that wraps and connects our components
	SearchContextManager, // the context manager, includes the Context.Provider
	SuggestionBar, } from '@giphy/react-components';
	
import Picker from 'emoji-picker-react';

export const MessageInputBar = React.memo(({
	isEditMode,     //Is the user editing an existing message?
	messageToEdit,  //If so, here's the master message record
	placeholderMessage, 
	giphyGridWidth, 
	parentMessageID, 
	sendMessageCallback, 
	updateMessageCallback,
	disabled, disableGif,
	setFocusTrigger,
	cancelEditModeCallback}) => {

	//Context
	const { currentUser } = useContext(AuthContext);    
	const { cacheGif } = useContext(GiphyCacheContext);  

	
	// Local state 
	const [message, setMessage] = useState("");
	const [messageGif, setMessageGif] = useState(null);
	const [showPickerMessage, setShowPickerMessage] = useState(false);
	const [showSearchExperienceMessage, setShowSearchExperienceMessage] = useState(false);
	const [messageInputBarHeight, setMessageInputBarHeight] = useState("30px");
	const [mediaUrl, setMediaUrl] = useState(null);  //holds an internal URL generated for  the image file just uploaded into the browser by the user
	const [mediaFile, setMediaFile] = useState(null);
	const [resetImageUploaderTrigger, setResetImageUploaderTrigger] = useState(false);

	//React References
	const conectereMessagingInputRef = useRef();

	//UseEffect that runs on change to EDIT mode
	useEffect(() => {
		if(isEditMode && messageToEdit && messageToEdit.id)  {
			if (DEBUG_MODE > 1) console.log("EDIT message detected.  Copying text", messageToEdit);
			setMessage(messageToEdit.message);	//Copy the text
		} else {
			//Reset state
			setMessage(''); setMessageGif(null); 
			setShowPickerMessage(false);setShowSearchExperienceMessage(false);setMessageInputBarHeight('30px');
			setMediaFile(null); setMediaUrl(null);
			setResetImageUploaderTrigger(!resetImageUploaderTrigger); //Reset the image loader to clear it's internal state			  
		}
	},[isEditMode, messageToEdit]);
	
	//UseEffect that runs on change to our setFocusTrigger prop
	useEffect(() => {
		if(setFocusTrigger && conectereMessagingInputRef && conectereMessagingInputRef.current)  {
		   conectereMessagingInputRef.current.focus();
		}
	},[setFocusTrigger]);
	
	
	// function handleMessageChange(value) {
	//     //Close GIFPHY Search, if open
	//     setShowSearchExperienceMessage(false);
	//     // if (DEBUG_MODE >= 2) console.log('Key to add: ', value);
	//     // var match = /\r|\n/.exec(value);
	//     // if(match){
	//     //     //if (DEBUG_MODE >= 2) console.log('Sending Message');
	//     //     handleSendMessage();
	//     // }
	//     // else {
	//         setMessage(value);
	//     // }
	// }
	const handleImageUpload = (e) => {
		if (DEBUG_MODE >1) console.log ("Processing uploaded image", e);                  
		try {
			//Clear any GIF that may have been uploaded
			setMessageGif(null);

			const file = e.target.files[0];  //grab the first file in the list of files selected by the user
			if (validImageFileType(file)) {
				const localURL = URL.createObjectURL(file);                  
				if (DEBUG_MODE >1) console.log ("URL to local file in web browser:", localURL);                  
				setMediaUrl(localURL);  //update 'photo' so as to display a preview to the user
				setMediaFile(file);  //save file for uploading to S3 bucket if the user hits the ADD button
				if (DEBUG_MODE >1) console.log ("Successfully generated internal URL and stored FILE locally")       
			} else if (DEBUG_MODE >= 2) console.log("Error, wrong file type", file.type);
		} catch (err) {
			if (DEBUG_MODE > 1) console.log("ERROR processing uploaded image", err);
		}
	};

	//Special handler to check if a keypress s 
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
		  if (DEBUG_MODE >1) console.log("ESCAPE PRESSED");
		  if (cancelEditModeCallback) cancelEditModeCallback();
		}
	 };

	async function handleMessageChange({value, containerWidth, trapReturn}) {
		 
		 //Close GIFPHY Search, if open
		setShowSearchExperienceMessage(false);
		
		  //Trap the return key and send the message if a return is pressed
		// if (DEBUG_MODE >= 1) console.log('Key to add: ', value);
		var match = /\r|\n/.exec(value);
		if(match){
			//if (DEBUG_MODE >= 2) console.log('Sending Message');
			if (sendMessageCallback) {
				let tempMessage = message, tempGif = messageGif;
				setMessage(""); setMessageGif(null); setMediaFile(null);setMediaUrl(null);setResetImageUploaderTrigger(!resetImageUploaderTrigger);
				await sendMessageCallback({
					message:tempMessage, 
					messageGif:tempGif, 
					parentMessageID,
					mediaFile,
					mediaUrl,		//Pass the URL to the locally cache image in the browser so we can immediately render a temp message 
				});  //Invoke the Send Message callback
			}
		} else {        
			
			//Dynamically adjust height of our input region as the user types in case we have a long message
			// Resize down to a min of 30px if they delete all the text
			//   var objDiv = document.getElementById("conectereMessagingInputDIV");
		   if (conectereMessagingInputRef && conectereMessagingInputRef.current) {
				// if (DEBUG_MODE >= 2) console.log("Message input bar change. ", value);
				var newHeight = (value.length <  (containerWidth/10) && !value.includes("\r") && !value.includes("\n")  ? "30px" : conectereMessagingInputRef.current.scrollHeight.toString() + "px" );
				setMessageInputBarHeight(newHeight);
				
				// if (DEBUG_MODE >= 2) console.log("Message input bar change. Value:", value);
				// if (DEBUG_MODE >= 2) console.log("Message input bar change. Params:", value.length, containerWidth, trapReturn, !value.includes("\r"), !value.includes("\n"), conectereMessagingInputRef.current.scrollHeight, conectereMessagingInputRef.current.scrollHeight, newHeight);
			}             
	
			setMessage(value);  //Update the state var for the message
		}
   }      
   
   function handleMessageFocus(value) {
		if (DEBUG_MODE >= 2) console.log("Focus on the message input bar");
		setShowSearchExperienceMessage(false);            
		setShowPickerMessage(false);
	}   

	const onEmojiClick = (event, emojiObject) => {
		if (DEBUG_MODE >= 2) console.log("Emoji Click Send Message invoked");

		setMessage(message => message + emojiObject.emoji);
		setShowPickerMessage(false);
	};

	function clearStagingArea() {
		setMessageGif(null);
		setMediaUrl(null); 		//clear the URL to the uploaded image
		setMediaFile(null);  	//clear the file itself
		setResetImageUploaderTrigger(!resetImageUploaderTrigger);
	} 

	// Giphy search component
	const SearchComponents = ({giphyGridWidth}) => {
		const { fetchGifs, searchKey } = useContext(SearchContext);
		var numColumns = 3;  
		if (giphyGridWidth && giphyGridWidth> 450) numColumns = Math.floor(giphyGridWidth /150);  
		if (DEBUG_MODE >= 2) console.log("Search components called. giphyGridWidth:", giphyGridWidth, numColumns );  
		return (
			<div className="conectereMessagingGiphySearchContainer" style={{width:giphyGridWidth}} onClick={(e)=> {e.stopPropagation();}}>
				<div style={{padding:"5px 0px"}}>
					<SearchBar  />
				</div>
				<SuggestionBar />                    
				
				<div className="conectereMessagingGiphyGridContainer">

					{/** 
						key will recreate the component, 
						this is important for when you change fetchGifs 
						e.g. changing from search term dogs to cats or type gifs to stickers
						you want to restart the gifs from the beginning and changing a component's key does that 
					**/}
						<GiphyGrid key={searchKey} columns={numColumns} width={giphyGridWidth} fetchGifs={() => fetchGifs()} hideAttribution={true}
							onGifClick={(gif, e) => {
							  if (DEBUG_MODE >= 2) console.log("Storing gif and clearing any previously uploaded image", gif);
							  e.preventDefault();
							  setShowSearchExperienceMessage(false);
							  cacheGif(gif);    //Let's add the selected GIF to our Giphy Cache Context so we can display it later in the Chat Window if needed
							  setMessageGif(gif);
							  setMediaUrl(null); 			//clear the URL for any previousy uploaded image
							  setMediaFile(null);  	//clear the file itself		
							  setResetImageUploaderTrigger(!resetImageUploaderTrigger); //Reset the image loader to clear it's internal state			  
						}}/>
				</div>
			</div>
		);
	};   

	function renderStagingArea() {
		if (!messageGif && !mediaUrl) return null;
		return (
			<div className="conectereMessagingGiphyStagingAreaWrapper" >
					<div className="conectereMessagingGiphyStagingArea">
						{messageGif ? 
							<Gif gif={messageGif} width={200} noLink={true} hideAttribution={true} style={{padding:"5px"}}/>
						:
						<div className="ContainerNoHeightCenter positionRelative " >
							<img id="uploadedImagedID" className="ccImgXXXL" src={mediaUrl} alt="" />
						</div>					
						}
					</div>
					<div className="conectereMessagingGiphyClear" onClick={clearStagingArea}>
						X
					</div>
			</div>
		)
	}
	//Render function
   return (
	   <div className = "ContainerVerticalStart fullWidth conectereMessagingInputAreaWrapper" >
		   <div className = "conectereMessagingInputAreaInnerWrapper" >
		   
				{renderStagingArea()}
				  
			   <div className = "conectereMessagingInputArea">
					 <div className="ContainerNoHeightCenter">
						<div className="avatarImage avatarImageSmall" style={{backgroundColor: setHeaderColorByStringLength(currentUser.lastName)}}>
							{!currentUser.avatarUrl  ? 
								<div className="avatarInitialsSmall"> {currentUser.firstName.substring(0,1) + currentUser.middleName.substring(0,1) + currentUser.lastName.substring(0,1)} </div>: 
								<img className="avatarImageCenterPortrait" src={currentUser.avatarUrl} alt={""} />
							}                                                
						</div>
					  </div>
					<textarea
						id="conectereMessagingInputDIV"
						ref={conectereMessagingInputRef}
						className="TextStyle3 conectereMessagingInputBar"
						style={{height:messageInputBarHeight}}
						value={message}
						onChange={event => handleMessageChange({value:event.target.value, containerWidth:giphyGridWidth, trapReturn:true})}
						onKeyDown={handleKeyDown}
						onFocus={event => handleMessageFocus(event.target.value)}
						placeholder={placeholderMessage}
						// cols="25" 
						rows="1"
						disabled={disabled}
					/>
					<div className="ContainerNoHeightCenter conectereMessagingInputAreaButtons" >
						{disabled ? null : 
							<>
								<UploadPhotoButton onClickCallback={handleImageUpload}  resetTrigger={resetImageUploaderTrigger}/> 

								<EmojiButton onClickCallback={setShowPickerMessage} onClickCallbackParam={!showPickerMessage} /> 
								{disableGif ? null :
									<GIFButton onClickCallback={() => {
										setShowSearchExperienceMessage(!showSearchExperienceMessage);
										setMessageGif(null);}}
									/> 
								}
								<SendButton onClickCallback={async () => {
								   let tempMessage = message, tempGif = messageGif;
									setMessage(""); setMessageGif(null); setMediaFile(null);setMediaUrl(null);setResetImageUploaderTrigger(!resetImageUploaderTrigger);
									if (!isEditMode) await sendMessageCallback({message:tempMessage, messageGif:tempGif, parentMessageID:parentMessageID, mediaFile, mediaUrl});  //Invoke the Send Message callback
									else if (isEditMode && messageToEdit) await updateMessageCallback({isEditMode, messageToEdit, message:tempMessage, messageGif:tempGif, parentMessageID:parentMessageID});  //Invoke the Send Message callback
									else if (DEBUG_MODE) console.error("Error - improper params to process SEND button", isEditMode, messageToEdit);
								 }} /> 
							</>
						}
					</div>
				</div>  
				{!showPickerMessage || disabled ? null :
					<div className="TextStyle2 fullWidth" >
						<Picker
							pickerStyle={{ width: 'inherit'}}
							onEmojiClick={onEmojiClick}/>
					</div>
				}
			</div>

			{showSearchExperienceMessage ? 
				<SearchContextManager apiKey={GIPHY_SDK_KEY_WEB}>
					<SearchComponents giphyGridWidth={giphyGridWidth}/>
				</SearchContextManager>
			: null}
			{isEditMode ? <div className="TextStyle2 red editMode"> <i>editing - press esc to exit</i></div> : null }
		</div>
	);
});

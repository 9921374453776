//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//DATA
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOOD, COLOR_WARNING_LOW, COLOR_WARNING_HIGH, COLOR_ALERT, TEAM_COLOR, FAINT_GREY } from '../../../../shared/data/conectereConfigData';
import { userSummaryColumns } from '../../../../shared/data/gridColumns';

//React and AWS components
import React, { useState, useContext, useEffect, useRef } from 'react';

//Our Components
import ModalNoBackground from "../../../../shared/Components/modalNoBackground/modalNoBackground";
import { ConectereDivider } from "../../../../shared/utils/generalUtils";

//Utils
import { displayRecognizedUsersAvatars } from "../../../../shared/utils/spotlightUtils.js";
import { DisplayUserSelectComponent } from "../../../../shared/utils/userAndTeamUtils.js";
import { invokeSendGift } from "../../../../shared/utils/userAndTeamUtils.js";

//Icons
import { IconButton } from '@mui/material';
import RedeemIcon from '@mui/icons-material/Redeem';

//CONTEXT
import { CustomerContext } from '../../../../shared/context/customerContext';        //User Authentication context
import { AuthContext } from '../../../../shared/context/authContext';
import { NotificationContext } from '../../../../shared/context/notificationsContext';
import { DisplayContext } from '../../../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../../../shared/context/modalContext';            

const GiftCoins = ({ showModalGift, handleCloseGiftModal }) => {
	
	//Context
	const {  setShowSpinner } = useContext(DisplayContext); 
	const { users, participatingUsersOptions} = useContext(CustomerContext); 
	const { currentUser } = useContext(AuthContext);  
	const { pushNotification } = useContext(NotificationContext);
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	//State data
	const [selectedUsersToGift, setSelectedUsersToGift] = useState([]);     
	const [coinsToGift,setCoinsToGift] = useState("");
	const [giftMessage, setGiftMessage] = useState("");
	const [messageInputBarHeight, setMessageInputBarHeight] = useState("32px");
	const giftMessaeInputRef = useRef();



	async function handleConfirmationGift () {
		if (DEBUG_MODE >= 2) console.log("Recording Gift");
		setShowSpinner(true); //Show spinners

		//Main function call to transactions, send notifications, etc.
		const success = await invokeSendGift({
			 currentUser:currentUser,
			 users:users,
			 coinsToGift:coinsToGift,
			 giftMessage:giftMessage, 
			 selectedUsersToGift:selectedUsersToGift, 
			 pushNotification:pushNotification,
		});

		if (success) {
			 setModalGeneralMessage("Gift sent");
			 setModalGeneralTitle("Success!");
			 setShowModalGeneral(true);   
		} else {
			setModalGeneralMessage("Something went wrong.  Failed to send gift");
			 setModalGeneralTitle("Ooops!");
			 setShowModalGeneral(true);   
			 
		}
		setShowSpinner(false); //Hode spinners
		if (handleCloseGiftModal) handleCloseGiftModal();
  }

    //Verify numeric input
    const handleUpdateCoins  = (value) => {     
		if (!value) return;         //Exit if undefined	
		if (isNaN(value)) return;   //Exit if not a number		
		setCoinsToGift(parseInt(value));
  };

	function handleGiftMessageChange(value) {
		 
		//Dynamically adjust height of our input region as the user types
		if (giftMessaeInputRef && giftMessaeInputRef.current) {
			 var newHeight = (value.length < 20 ? "32px" : giftMessaeInputRef.current.scrollHeight.toString() + "px" );
			 setMessageInputBarHeight(newHeight);
			 // if (DEBUG_MODE >= 2) console.log("Gift Message input bar change.  Amount to scroll to bottom", giftMessaeInputRef.current.scrollHeight, newHeight);
		}             

		setGiftMessage(value);
		
  }    


   return (
		<>
			<ModalNoBackground showModal={showModalGift} closeCallback={handleCloseGiftModal} cardColor={TEAM_COLOR}>
				<div className="modalNoBkgInnerCard" style={{backgroundColor:"black"}}>
					<div className="modalNoBkgCenterColumn" style={{minHeight:"350px", backgroundColor:"black", padding:"10px"}}>
						<div className="ContainerNoHeightCenter">
							<div  style={{width:"100%", fontSize:"1.4em", fontWeight:"500", color:"#409cf7"}}>
									Gift coins to a colleague
							</div>  
								<div className = "rotateCoins"> 
									<img  className="ccImgXXXL" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt='coins'/> 
							</div>
						</div>
						<div className="spotlightRecognizedUsersAvatarsWrapper" style={{minHeight:"64px", position:"relative"}} >
							{displayRecognizedUsersAvatars({recognizedEmployees:selectedUsersToGift, maxUsersToShow:7, isSmall:false, isFull:true, showQuestionMarkIfNone:true, showHalo:true})}
						</div>       

						<ConectereDivider />
							
						<div className="inputContainer">
							<div className="ContainerNoHeightFlexLeft" style={{zIndex:"7777", alignItems:"flex-start", position:"relative" }}>
									<div style={{padding:"10px 10px"}}>
									Amount
								<div className="ContainerNoHeight" >
										<input
											style={{border: ( (!coinsToGift || Number(coinsToGift < 0)) ? "1px solid red" : "1px solid grey")}}
											name="coinsToGive"
											type="number"
											min="1" max="10000" maxLength="4"
											value={coinsToGift}
											onChange={event => setCoinsToGift(event.target.value)}
											required
										/>
								</div>
										
								</div>
							</div> 
						
							<DisplayUserSelectComponent name={"userDropdown"} usersOptions={participatingUsersOptions} handleSelectUser={setSelectedUsersToGift} isMulti={true} selectedUsersOptions={selectedUsersToGift} placeholder='Individuals' zIndex="99990"/>


							<div className = "conectereMessagingInputArea" >
								<textarea
										ref={giftMessaeInputRef}
										className="TextStyle3 conectereMessagingInputBar"
										style={{height:messageInputBarHeight}}
										value={giftMessage}
										onChange={event => handleGiftMessageChange(event.target.value)}
										placeholder="Add a message"
										rows="1"
								/>
							</div>  
					

						</div>
					</div>
					<div  className="ContainerNoHeightFlexEndCentered" style={{width:"100%", padding:"20px"}}>
						<button className="buttonStyle3" onClick={handleConfirmationGift} disabled={(!coinsToGift || coinsToGift==0 || isNaN(coinsToGift) || !selectedUsersToGift || selectedUsersToGift.length===0)} >
							<div className="ContainerNoHeightCenter"> 
								<div style={{paddingRight:"1.0rem"}}>GIVE </div>
								<RedeemIcon style={{color:"white", transform:"translate(0px,-2px)"}}/> 
							</div>
						</button>
					</div>
				</div>
			</ModalNoBackground >

	
		</>
   ); 
};

export default GiftCoins;
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { DEBUG_MODE, CONECTERE_CONFIG_DATA } from '../../../../shared/data/conectereConfigData';
import React, { useState, useContext } from 'react';

//Styles
import "./menuHeaderProfile.css";

//Context
import { DisplayContext } from '../../../../shared/context/displayContext';            //User Authentication Context


//Icons
import Menu from '@material-ui/core/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import {Person  } from '@material-ui/icons';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

//Utils
import { getUserInitials } from "../../../../shared/utils/userAndTeamUtils";
import { Divider } from "../../../../shared/utils/generalUtils";
// import { inMsftTeams } from "../../../../shared/utils/msftTeamsUtils";

// React component
const ProfileMenu = ({ currentUser, callback, isAdmin }) => {

	//Context
	const { innerWindowWidth, innerWindowHeight, darkMode, isRenderedWithinMsftClient } = useContext(DisplayContext); 

	//Local state
	const [showMenu, setShowMenu] = useState(null);

	const handleToggleMenu = () => {
		if (DEBUG_MODE >= 2) console.log("Toggline menu", );
		setShowMenu(!showMenu);
	};

  //Clear the anchor element
  const handleSelect = (event) => {
	setShowMenu(null);
	if (!event) return;
	const selectedValue  = event.currentTarget.dataset.value;
	if (DEBUG_MODE >= 2) console.log("Profile Menu item selected", selectedValue);
	
	//Invoke CallBack
	if (callback) callback(selectedValue);
  };

  
	if (!currentUser) return null;
	
   return (
		<>
			<div className="avatarImage avatarImageMediumLG" onClick={handleToggleMenu} style={{backgroundColor: "#82E0AA", cursor:"pointer"}}>
				{!currentUser.avatarUrl  ? 
					<div className="avatarInitials"> {getUserInitials(currentUser)} </div> : 
					<img className="avatarImageCenterPortrait" src={currentUser?.avatarUrl} alt={""} /> 
				}
			</div>
 
			<div className="menuHeaderWrapper" style={{display : (showMenu ? 'block' : 'none')}} onClick={() => handleToggleMenu()}>

					<div className="menuHeaderProfile TextStyle3 headerBlue boxShadow" style={{display : (showMenu ? 'block' : 'none')}} >
					<MenuList dense>
						<MenuItem onClick={handleSelect} data-value="profile">
							<div className="ContainerNoHeightCenter "> <Person /> <div style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}>My Profile </div></div>
						</MenuItem>
						<MenuItem onClick={handleSelect} data-value="wallet">
							<div className="ContainerNoHeightCenter "> <AccountBalanceWalletIcon /> <div style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}>Wallet </div></div>
						</MenuItem>

						<MenuItem onClick={handleSelect} data-value="streaks">
							<div className="ContainerNoHeightCenter "> <LocalFireDepartmentIcon /><div style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}> Current Streaks </div></div>
						</MenuItem>
						
						<Divider marginTop="clamp(5px,1vh,15px)" marginBottom="clamp(5px,1vh,15px)" />
						
						{/* REMOVED - ONLY AVAILABLE ON ACCOUNT SETTINGS PAGE FOR ADMIN 
						   <MenuItem onClick={handleSelect} data-value="slack" disabled={!isAdmin}>
							   <div className="ContainerNoHeightCenter headerBlue"> 
								   <img  style={{height:"20px"}} src={CONECTERE_CONFIG_DATA.SLACK_LOGO} /> 
								   {isAdmin ? 
									   <div style={{paddingLeft:"clamp(1px,0.3vh,3px)"}} className = {"headerBlue"} style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}>{!currentUser.customer.configDetails.slackEnabled ? "Connect Slack " : "Disconnect Slack" }  </div>
									:
									   <div style={{paddingLeft:"clamp(1px,0.3vh,3px)"}} className = {"light-grey"} style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}>{!currentUser.customer.configDetails.slackEnabled ? "Slack Disconnected " : "Slack Connected" }  </div>
								   }
							   </div>
						   </MenuItem>
						*/}

{isRenderedWithinMsftClient ? null :
					<div>
						<MenuItem onClick={handleSelect} data-value="ms365" disabled={currentUser.googleCalendarEnabled}>
							<div className="ContainerNoHeightCenter "> 
								<img  style={{height:"20px"}} src={CONECTERE_CONFIG_DATA.MSFT_LOGO} alt=''/> 
								<div className = {currentUser.googleCalendarEnabled ? "light-grey" : "headerBlue" } style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}> {!currentUser.ms365CalendarEnabled ? "Sync to Microsoft Calendar" : "Disconnect Microsoft Calendar" }  </div>
							</div>
						</MenuItem>
						<MenuItem onClick={handleSelect} data-value="google" disabled={currentUser.ms365CalendarEnabled}>
							<div className="ContainerNoHeightCenter "> 
								<img  style={{height:"20px"}} src={CONECTERE_CONFIG_DATA.GOOGLE_LOGO} alt=''/> 
								<div className = {currentUser.ms365CalendarEnabled ? "light-grey" : "headerBlue" } style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}>{!currentUser.googleCalendarEnabled ? "Sync to Google Calendar" : "Disconnect Google Calendar" }  </div>
							</div>
						</MenuItem>   
						<Divider marginTop="clamp(5px,1vh,15px)" marginBottom="clamp(5px,1vh,15px)" /> 
					</div>                   
}
{isRenderedWithinMsftClient ? null :
						<MenuItem onClick={handleSelect} data-value="settings">
							<div className="ContainerNoHeightCenter "> <SettingsIcon /> <div style={{paddingLeft:"clamp(1px,0.3vh,3px)"}}>Settings </div></div>
						</MenuItem>
}
						<MenuItem onClick={handleSelect} data-value="reset_pwd">
							<div className="ContainerNoHeightCenter ">  Reset Password </div>
						</MenuItem>    
						<MenuItem onClick={handleSelect} data-value="log_out">
						   <div className="ContainerNoHeightCenter ">   Log Out </div>
						</MenuItem>    
						</MenuList>
				   </div>
			</div>  
		</>
		);
};


export default React.memo(ProfileMenu);
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved
//

/* Federarted Signin via Amplify
      Resources:

*/
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, LEGAL_PRIVACY_POLICY, LEGAL_TERMS_AND_CONDITIONS,  } from '../../shared/data/conectereConfigData';

import '../../shared/styles/App.css';
import '../../shared/styles/authentication.css';

//Amplify, React
import { Authenticator } from "@aws-amplify/ui-react";         //Import Amplify's REACT UI components
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';

//Utils
import { authenticatorFormFields, authenticatorComponentsWhite } from "../../shared/utils/authUtils";
import axios from "axios";

//Context
import { DisplayContext } from '../../shared/context/displayContext';         
import { MsftTeamsContext } from '../../shared/context/msftTeamsContext';     

const backgroundImageURL = "url('" + process.env.PUBLIC_URL+"/img/conectivities-hero.png"+ "')";

// Determine our platform
const IS_MANAGMENT_PORTAL = (process.env ? process.env.IS_MANAGMENT_PORTAL : false);

//Direct API for our Teams Lambda (unauthorized call) for sending our auth code
const OUR_TEAMS_BOT_LOGIN_ENDPOINT = 'https://9c3m5ieozl.execute-api.us-east-2.amazonaws.com/dev/teamsLoginAPI'

const sendAuthCodeToTeamsLambda = async (teamsIdToken, context) => {
   try {
      const data = {
         context: context,
      }
      const options = {
         headers: {
            Authorization: `Bearer ${teamsIdToken}`
         }
      }
      const response = await axios.post(OUR_TEAMS_BOT_LOGIN_ENDPOINT, data, options);
      if (DEBUG_MODE) console.log("Our Teams BOT lambda returned", response);
      return response;
      
   } catch (err) {
      if (DEBUG_MODE) console.error("Error sending Teams auth code to our lambda", err);
   }
}

/*
   The call Auth.federatedSignIn() triggers the sign-in process via the identity provider (like Google, Facebook, etc.). 
   However, it doesn't return a response immediately when called. Instead, after the user successfully signs in 
   through the identity provider, they are redirected back to your application, where Amplify automatically handles 
   the authentication flow and stores the user session data.

   How It Works:
   Initial Call to federatedSignIn: This initiates the sign-in process by redirecting the user to the identity provider’s 
   login page (e.g., Google login page).

   Redirection to Callback URL: After the user successfully signs in with the provider, they are redirected back to the 
   callback URL (redirectSignIn) specified in your Amplify configuration (aws-exports.js).

   Amplify Handles the Token Exchange: Once the user is redirected back to your application, Amplify automatically 
   handles the exchange of the authorization code (or token) for user pool credentials.

   Storing Session: After successful sign-in, Amplify stores the authenticated user's session details (ID token, 
   access token, refresh token, etc.) locally.

   Checking Sign-In Status
   To retrieve the authenticated user's details after they've signed in, you can use 
   Auth.currentAuthenticatedUser() or Auth.currentSession() to get the user's information and session data.

*/


//React Component for this page
const UserLogin = () => {

   // Context
	const { isRenderedWithinMsftClient, isMobileViewPort, setShowSpinner } = useContext(DisplayContext);
   const { teamsIdToken, teamsContext, renderMicrosoftLogin } = useContext(MsftTeamsContext);

   //Render LOGIN components based on context
   const renderProperLogin = ({isRenderedWithinMsftClient, teamsIdToken, teamsContext}) => {
      //MSFT wrapper detected?
      if (isRenderedWithinMsftClient) return renderMicrosoftLogin(teamsIdToken, teamsContext);

      //Otherwise, return our standard login fields
      return (

            <Authenticator
               hideSignUp={true}
               formFields={authenticatorFormFields}
            >

            </Authenticator>

      )
   }   

   //Render function for authenticated user  
   return (
      <section className="user-login" >

         <div className="ContainerNoHeightCenter loginContainer" style={{backgroundImage: backgroundImageURL}} > 
            <div className = "loginWrap ContainerVerticalCenter" >

               <div className = "loginCard" >
                  <div className = "loginInnerCard ContainerVerticalCenter" >

                     <div  className=" ccImgXXXXXLSquare" > 
                        <img alt="Conectere logo"  src={CONECTERE_CONFIG_DATA.RING_LOGO_FULL_IMAGE_PATH} />
                     </div>
                        {renderProperLogin({isRenderedWithinMsftClient, teamsIdToken, teamsContext})}
                  </div>
               </div>


                  <div className="TextStyle3 white">New to Conectere? 
                     <a className="TextStyle3" href="https://signup.conectere.net " target="_blank" rel="noreferrer" >
                        &nbsp;Sign up today!
                     </a>
                  </div>

                  <p className="TextStyle3 white" style={{paddingBottom: "clamp(4px,1vh,10px)"}}> Need help? 
                     <a className="TextStyle3" href="https://www.conectere.net/contact.html" target="_blank" rel="noreferrer">
                        &nbsp;Contact us
                     </a>
                  </p>


               <div className="ContainerNoHeightCenter TextStyle2 white" style={{marginTop: "1.0rem"}}>
                  <div>By clicking Sign In, you agree to Conectere’s &nbsp;</div> 
                  <div> 
                     <a className="TextStyle4" href={LEGAL_PRIVACY_POLICY} target="_blank" rel="noreferrer" >Privacy Policy</a>                             
                     &nbsp; and &nbsp; 
                     <a className="TextStyle4 " href={LEGAL_TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer" >Terms & Conditions</a>
                  </div>
               </div>               
            </div>
         </div>
      </section>
   )

}

export default UserLogin;

/*
		ADMIN USERS

		PROPRIETARY AND CONFIDENTIAL

		PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
		copyright CONECTERE

*/

//Data
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, BALANCE_COLOR, TEAM_COLOR, COLOR_BLUE_HEADER, DATA_GRID_HIDE_ROW_THRESHOLD1, DATA_GRID_HIDE_ROW_THRESHOLD2, configDetailsInitialState, progressDataInitialState, walletInitialState, USER_INITIAL_STATE, userAnalyticsInitialState} from '../../shared/data/conectereConfigData';
import { USER_COLUMN_WITH_PERMISSIONS, TEAM_COLUMN, WALLET_COLUMN, CALENDAR_COLUMN } from '../../shared/data/gridColumns';

//React & Amplify
import React, { useEffect, useState, useContext, useRef } from 'react';
import {Authenticator } from '@aws-amplify/ui-react';

//Bootstrap and other React components

//Icons
import { Person  } from '@material-ui/icons';

//Our Components
import CCdisplayCardUserStats1 from "./Components/displayCardUserStats1/displayCardUserStats1";
import CCdisplayCardUserNumbers from "./Components/displayCardUserNumbers/displayCardUserNumbers";
import CCRadarChart from "./Components/radarChart/radarChart";
import MenuDataGrid from "../../shared/Components/menuDataGrid/menuDataGrid";
import UserConectivitySummary from "./Components/userConectivitySummary/userConectivitySummary";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import UserEditor from "../../shared/Components/userEditor/userEditor";

//CONTEXT
import { AuthContext } from '../../shared/context/authContext';                //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { AnalyticsContext } from '../../shared/context/analyticsContext';      // Analytics Context

//Utils
import { TOMORROW, TIME_ZONE_OPTIONS, DisplayTimeZoneSelectComponent } from "../../shared/utils/dateTimeUtils";
import { setHeaderColorByStringLength,  } from "../../shared/utils/conectivityUtils";
// import {fetchRecordsByPrimaryKey, queryDataTableWithPagination} from "../../shared/utils/databaseUtils";
import { compareTransactionsByDateReverseChronological}  from "../../shared/utils/transactionsUtils";
import { Divider, displayConectivityScoreAndRank, DisplayEmployee, displayAwards, validImageFileType, HelpButton, EditButton, DeleteButton, AddButton, SummaryButton, RadarButton, BonusButton } from "../../shared/utils/generalUtils";
import { buildTeamsForDisplay, getUserName, getUserInitials, DisplayUserSelectComponent, DisplayTeamSelectComponent, invokeCreateUser, invokeDeleteUser, invokeUpdateUser, autoScheduleNewEmployeeConectivities } from "../../shared/utils/userAndTeamUtils";
import { deleteImageFile, storeImageFile }  from "../../shared/utils/storageUtils";
import { fetchConfigByCustomer }  from "../../shared/utils/databaseUtils";

import moment from 'moment';


const AdminUsersPage = () => {
  
	//Context
	const { authState, currentUser, isSuperAdmin, isAdmin, permissionAnalytics } = useContext(AuthContext);    // Authentication context
	const { users } = useContext(CustomerContext);  // Customer context
	const { analyticsGenerated, userAnalytics, customerAnalytics, radarChartData, radarChartNames, radarChartColors, radarChartTargetName, computeRadarChartData, transactionsFiltered } = useContext(AnalyticsContext);

   //Local state data
	const [userToEdit, setUserToEdit] = useState(USER_INITIAL_STATE);              //Used to hold a user that is to be edited
	const [userRows,setUserRows]=useState([]);                                    //Formatted rows for user grid
	const [transactionsForUserSummary,setTransactionForUserSummary] = useState([]);
	const [userIDsToSummarize, setUserIDsToSummarize] = useState(null);         //User ID to summarize  


   //variable to control confirmation Modal if user presses DELETE; default to hidden
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalAdd, setShowModalAdd] = useState(false);    
	const [showModalSummary, setShowModalSummary] = useState(false);  

	
		
	// Main React useEffect for maintaining updated page data
	useEffect(() => {
		if (authState !== "signedin") return;
	   //wait for users, teams, transactions and corresponding analytics to be loaded by context
		// if (!analyticsGenerated) return;
		//Build rows for Data Grid
		buildUserRows();
	}, [users, userAnalytics, authState, analyticsGenerated]);

 
	//Define User Grid Format
	//Note - resizable appears to be available only in Data Grid Pro
	
	const userColumnsAdmin = [
		USER_COLUMN_WITH_PERMISSIONS,
	  {
		field: 'userRank',
		headerName: 'CONECTIVITY',
		sortable: true,
		headerAlign: 'center',
		align:'center',
		minWidth: 200,
		flex:1,
		 // resizable: true,
		color:'white',
		renderCell: (params)=> {
 
			return displayConectivityScoreAndRank(params.row.relativeConectivityScore, params.row.userRank);
	
		}

	  },
	  {
		field: 'conectivitiesCompleted',
		headerName: 'CONECTIVITIES',
		headerAlign: 'center',
		align:'center',
		minWidth: 150,
		flex:1,
		 // resizable: true,
		color:'white',
		hide:true,
	  },
	  WALLET_COLUMN,	  
/*      
	{
		field: 'badgesDEIAwarded',
		headerName: 'DEI',
		width: 105,
		editable: false,
		color:'white',
		hide:true,
		 renderCell: (params)=> {
			return (
					
					<div>{!params.row.badgesDEIAwarded ? "" :
						<div>
							{params.row.badgesDEIAwarded} 
							<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} />
						</div>
						}
					</div>
				)
		}
		
	  },
	  {
		field: 'badgesCSAwarded',
		headerName: 'CS',
		width: 105,
		editable: false,
		color:'white',
		hide:true,
		 renderCell: (params)=> {
			return (
					
				<div>{!params.row.badgesCSAwarded ? "" :
					<div>
						{params.row.badgesCSAwarded} 
						<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} />
					</div>
					}
				</div>
			)
		 }
	  },
*/
	TEAM_COLUMN,
	CALENDAR_COLUMN,
	  {
		field: ' ',
		headerName: ' ',
		headerAlign: 'center',
		align:'center',
		minWidth: 220,
		flex:0.5,
		 // resizable: true,
		color:'white',
		sortable: false,
		renderCell: (params)=> {

			if (isAdmin || isSuperAdmin) return (
				<div className="dataGridActionButtonRow" >
					<SummaryButton onClickCallback={handleSummary} onClickCallbackParam={params.row.dynamoDBID} />
					<RadarButton onClickCallback={computeRadarChartData} onClickCallbackParam={params.row.dynamoDBID} />
					<EditButton onClickCallback={handleEdit} onClickCallbackParam={{userToEdit:users[params.row.id-1], deleteFlag:false}} />
					<DeleteButton onClickCallback={handleEdit} onClickCallbackParam={{userToEdit:users[params.row.id-1], deleteFlag:true}} />
				</div>
			);
			else return (
				<div className="dataGridActionButtonRow" >
					<SummaryButton onClickCallback={handleSummary} onClickCallbackParam={params.row.dynamoDBID} />
					<RadarButton onClickCallback={computeRadarChartData} onClickCallbackParam={params.row.dynamoDBID} />
				</div>
			);
			}
		},
		
	];

	
	function buildUserRows() {
		
		//Safety check
		if (!users || users.length === 0) return;
		
	  //Build rows for Data Grid
		var tempUserRows = [];
		
		for (var i = 0; i<users.length; i++) {
			
			 //Set initial user values
			tempUserRows[i] = {
			id:i+1,
			dynamoDBID:users[i].id,
			name:getUserName(users[i]),
			initials:getUserInitials(users[i]),
			title:users[i].title,
			email: users[i].email,
			teams: buildTeamsForDisplay(users[i]),
			avatarUrl:users[i].avatarUrl,
			permissionAnalytics: (users[i].permissionAnalytics ? true : false),
			permissionEditor: (users[i].permissionEditor  ? true : false),
			permissionLaunch: (users[i].permissionLaunch ? true : false),
			permissionBilling: (users[i].permissionBilling ? true : false),
			permissionOrders: (users[i].permissionOrders ? true : false),

			//Calendar setttings
			googleCalendarEnabled: users[i].googleCalendarEnabled,
			msteamsCalendarEnabled: users[i].msteamsCalendarEnabled,
			msoutlookCalendarEnabled: users[i].msoutlookCalendarEnabled,
			ms365CalendarEnabled: users[i].ms365CalendarEnabled,
			icloudCalendarEnabled: users[i].icloudCalendarEnabled,
			
			//Current Balances           
			coinsAwarded: (users[i].ccWallet ? users[i].ccWallet.currentBalance : 0),
			badgesCSAwarded: (users[i].ccWallet ? users[i].ccWallet.badgesCS : 0),
			badgesDEIAwarded: (users[i].ccWallet ? users[i].ccWallet.badgesDEI : 0),
			badgesSpotlight: (users[i].ccWallet ? users[i].ccWallet.badgesSpotlight : 0),
			
			//Analytics
			relativeConectivityScore: 0,
			userRank: 0,
			conectivitiesCompleted: 0,
			};
			
			//Update with matching STATS object in User Numbers once available
			if (userAnalytics) {
				const tempCurrentUser = userAnalytics.find(user => user.userID === users[i].id);  // Grab match for DynamoDB ID
				if (tempCurrentUser) {
					// tempUserRows[i].coinsAwarded = tempCurrentUser.coinsAwarded;
					// tempUserRows[i].badgesCSAwarded = tempCurrentUser.badgesCSAwarded;
					// tempUserRows[i].badgesDEIAwarded = tempCurrentUser.badgesDEIAwarded;
					tempUserRows[i].userRank = tempCurrentUser.userRank;
					tempUserRows[i].relativeConectivityScore= tempCurrentUser.relativeConectivityScore;
					tempUserRows[i].conectivitiesCompleted = tempCurrentUser.conectivitiesCompleted;
					
					// if (DEBUG_MODE >= 2) console.log("Found matching user stats object for user", tempCurrentUser, users[i]);
				
				}
			} //End if user analytics
		} //End FOR each user
		
		// if (DEBUG_MODE >= 2) console.log("Built User Rows:", tempUserRows, users);
		setUserRows(tempUserRows);
}

	const handleAdd = () => {
		setShowModalAdd(true); //pop-up Modal 
		if (DEBUG_MODE >= 2) console.log("ADD button pressed.");
	};

	const handleEdit = ({userToEdit, deleteFlag}) => {
		if (DEBUG_MODE >= 2) console.log((deleteFlag ? 'Delete' : 'Edit') + "button pressed.  Edit User=", userToEdit, deleteFlag);
		setUserToEdit(userToEdit); //Store the passed in object into state data variable to record object being edit
		setShowModalEdit(!deleteFlag);
		setShowModalDelete(deleteFlag);
	};

	const handleCancelUserSummary = () => {
		setShowModalSummary(false);
	};
		
	async function handleSummary (id) {
		//Initialize an array of 1 entry as our component can summarize multiple individuals
		const tempArray = [id];
		setUserIDsToSummarize(tempArray);

		//Prep the transactions
		var tempTransactions = [];
		if(transactionsFiltered) {
			//Prep the transactions for the summary
			tempTransactions = transactionsFiltered.filter(transaction =>  transaction.userID === id);
			tempTransactions.sort(compareTransactionsByDateReverseChronological);
			setTransactionForUserSummary(tempTransactions); 
		}
		if (DEBUG_MODE >= 2) console.log("Displaying user summary modal", id, tempTransactions);
		
		setShowModalSummary(true);

	}
 
	const handleCloseEditor = (result) => {
		setShowModalEdit(false);
		setShowModalDelete(false);
		setShowModalAdd(false);
		setUserToEdit(USER_INITIAL_STATE);
		if (DEBUG_MODE >= 2) console.log("Closed User Editor", result);
	};	

	//Handle access by unauthenticated user
	if (authState !== "signedin"  || !currentUser) return null;
		
	//RENDER function for authenticated user 
	//confirm access to this page
  if (!isSuperAdmin && !isAdmin && !permissionAnalytics) {
	  return (
		  <Authenticator>
		  <div>
			<p></p>
				<h3 className="sectionHeader">EMPLOYEE DASHBOARD</h3>
				<center>Sorry, off limits</center>
		  </div>
		  </Authenticator>
	  );
  } else  return (


	<Authenticator>
		<UserEditor 
			showEditor={showModalAdd || showModalEdit || showModalDelete}
			user={userToEdit}
			isAdd={showModalAdd} isEdit={showModalEdit} isDelete={showModalDelete}
			handleCloseEditor={handleCloseEditor}  
		/>
			  

		<UserConectivitySummary 
			showUserSummary={showModalSummary} 
			transactions={transactionsForUserSummary} 
			handleCancelUserSummary={handleCancelUserSummary} 
			userIDsToSummarize={userIDsToSummarize} 
			showConectivityGraphTab={true}
		/>


		<div className="adminPage">
			<div className="dashboardContainer">

				 {analyticsGenerated ?
						<div className="dashboardCardRow">
						   <CCdisplayCardUserStats1 analytics={customerAnalytics}/>
						   <CCdisplayCardUserNumbers userAnalytics={userAnalytics} />
							<CCRadarChart 
							  data={radarChartData} title={true || !radarChartTargetName ? "Pillars of Conectivity" :  radarChartTargetName + " Conectivity"}  
							  dataKeyAngle="subject" 
							  radarName1={radarChartNames.length < 1 ? "" : radarChartNames[0]} dataKey1="A" color1={radarChartColors.length < 1 ? "" : radarChartColors[0]}
							  radarName2={radarChartNames.length < 2 ? "" : radarChartNames[1]} dataKey2="B" color2={radarChartColors.length <1 ? "" : radarChartColors[1]}
							  targetName={""}
							grid/>

					</div>
				: null }
					<div className="dashboardTitle">
						EMPLOYEES
						<AddButton icon = {<Person />} onClickCallback={handleAdd} hoverText="Add User" /> 
					</div>                  
					<div className="dataGridWrapperTop" >        
						<MenuDataGrid 
							dataGridRows={userRows} 
							dataGridColumns={userColumnsAdmin} 
						/>
					</div>
			</div>    
		</div>    
	</Authenticator>
 
  );
};

export default AdminUsersPage;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../data/conectereConfigData';

// import "./displayCardConectivityStats.css";

import React, {useState, useEffect  } from 'react';
import Switch from '@mui/material/Switch';

import { Divider } from "../../utils/generalUtils";

//React component
const CCdisplayCardConectivityStats = React.memo(({conectivityAnalytics}) => {

   if (!conectivityAnalytics) return null;

    const title="PORTFOLIO"; 
    const footer="";

    // if (DEBUG_MODE >= 2) console.log("Conectivity Stats Card called", conectivityAnalytics);
 
    
   return (

        <div className="dashboardCard">
            <div className="ContainerVerticalCenterFullWidth">
                <div className="ContainerNoHeightCenter" > 
                    <span className="TextStyle4 headerBlue heavy">{title}</span>
                    <span className = "dashboardScoreRectangle dashboardScoreRectangleTiny header-blue-background"  style={{margin:"5px"}}> 
                        {conectivityAnalytics.conectivitiesTotal.toString() }
                    </span>
                </div>
                <Divider width={80} />
            </div>
            <div className="ContainerNoHeightSpaceAround fullWidth" >
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.BALANCE_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall blue-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{conectivityAnalytics.totalStress.toString()}</div>
                </div>
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.TEAM_IMAGE_PATH} className="ccImgLarge" alt=''/>
                <div className="dashboardScoreRectangle dashboardScoreRectangleSmall purple-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{conectivityAnalytics.totalTeam.toString()}</div>
                </div>
    
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.SOCIAL_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall green-background" style={{marginTop:"clamp(4px,1vh,8px)"}} >{conectivityAnalytics.totalSocial.toString()}</div>
                </div>
    
                <div className="ContainerVertical" >
                    <img src={CONECTERE_CONFIG_DATA.GROWTH_IMAGE_PATH} className="ccImgLarge" alt=''/>
                    <div className="dashboardScoreRectangle dashboardScoreRectangleSmall yellow-background" style={{marginTop:"clamp(4px,1vh,8px)"}}>{conectivityAnalytics.totalPersonal.toString()}</div>
                </div>
            </div >

            <div className="ContainerNoHeightCenter TextStyle3">
                {conectivityAnalytics.totalDEI.toString()} <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /> 
                &nbsp;DEI Related
            </div >

            <div className="ContainerNoHeightCenter TextStyle3">
                {conectivityAnalytics.totalCS.toString()} <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /> 
                &nbsp;Community Service Related
            </div >
           <div className="ContainerNoHeightCenter TextStyle3">
                {conectivityAnalytics.leastCCs.toString() + " - " + conectivityAnalytics.mostCCs.toString()} <img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /> 
                &nbsp;Award Range 
            </div >
            
            <div className="ContainerNoHeightCenter TextStyle3">
                <div className="TextStyle3">
                    {(conectivityAnalytics.conectivitiesTotal - conectivityAnalytics.publishedInactive).toString() + " of " + conectivityAnalytics.conectivitiesTotal.toString()}
                    &nbsp;Active 
                </div>
            </div >
        </div>
        
    );        

});


export default CCdisplayCardConectivityStats;



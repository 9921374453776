//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright CONECTERE.  All Rights Reserved
//

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_WHITE, TEAM_COLOR, HEADER_FULL_MODE, COLOR_GOLD, BALANCE_COLOR, COLOR_BLUE_HEADER, CONECTIVITY_INITIAL_STATE, scheduledConectivityInitialState, RATING_INITIAL_STATE, CONECTIVITY_DESCRIPTION_MAX_LENGTH, GIPHY_ID_IDENTIFIER, COLOR_BLACK } from '../../shared/data/conectereConfigData';

import '../../shared/styles/App.css';
import './userHome.css';

//Amplify, React
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { Link } from 'react-router-dom';

//Context
import { AuthContext } from '../../shared/context/authContext';                    //User Authentication context
import { CustomerContext } from '../../shared/context/customerContext';            //Customer Authentication context
import { NotificationContext } from '../../shared/context/notificationsContext';
import { SpotlightContext } from '../../shared/context/spotlightContext';
import { ConectivityContext } from '../../shared/context/conectivitiesContext';
import { DisplayContext } from '../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../shared/context/modalContext';            

//Utils
import { SpecialEventsBadges, invokeProcessConectivity,   setBadgeByScheduledConectivity,   setConectivityImageUrlByCategory, setConectivityImageUrlByConectivity, setHeaderColorByScheduledConectivity, setHeaderColorByStringLength, setSpecialEventMultiplier  } from "../../shared/utils/conectivityUtils";
import { LaunchIcon, sortMessages, Divider } from "../../shared/utils/generalUtils";
import {NOW, NOW_ROUNDED_UP_TO_TEN_MIN, TODAY_NOON, ONE_YEAR_AGO, CALENDAR_REPEAT_OPTIONS, SIX_MONTHS_FROM_NOW, WEEK_REPEAT_OPTIONS, 
		MONTH_REPEAT_OPTIONS, MONTH_REPEAT_OPTIONS_2, MONTH_REPEAT_OPTIONS_3 } from "../../shared/utils/dateTimeUtils"; 
import { mergeInvitations, getMyInvitations } from "../../shared/utils/invitationUtils";
import { updateMessageReadAt } from "../../shared/utils/messageUtils";
import { DisplayUserSelectComponent, DisplayTeamSelectComponent } from "../../shared/utils/userAndTeamUtils";
import { SpotlightCreateButton }  from "../../shared/utils/spotlightUtils";

//Our Components
// import { SpotlightRow } from "../../shared/Components/spotlightRow/spotlightRow";
// import ConectivityDurationTimer from './Components/conectivityDurationTimer/conectivityDurationTimer';
import StarRatings from "../../shared/Components/starRatings/starRatings";
import { ScheduledConectivityCard  } from "../../shared/Components/scheduledConectivityCard/scheduledConectivityCard";
import { ScheduledConectivityDetailedView } from "../../shared/Components/scheduledConectivityDetailedView/scheduledConectivityDetailedView";
// import { ChatWindow } from "./Components/chatWindow/chatWindow";
import CCRingsAnimated from "./Components/ccRing/ccRingAnimated";
import ModalNoBackground from "../../shared/Components/modalNoBackground/modalNoBackground";
import ModalNoBackgroundFixed from "../../shared/Components/modalNoBackgroundFixed/modalNoBackgroundFixed";
import ModalBonus from "../../shared/Components/modalBonus/modalBonus";
import { SpotlightSendTool } from "../../shared/Components/spotlightSendTool/spotlightSendTool";
// import ConectivityDetailedView from "../../shared/Components/conectivityDetailedView/conectivityDetailedView"
import ConectivityLauncher from "../../shared/Components/conectivityLauncher/conectivityLauncher";
import CCRingsAnimatedView from './Components/ccRing/ccRingsAnimatedView';
import { AnimatedSpotlightCard } from '../../shared/Components/animatedSpotlightCard/animatedSpotlightCard';
import { ConnectivityInfoViewer } from '../../shared/Components/ConnectivityInfoViewer/ConnectivityinfoViewer';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SpecialEventsContext } from '../../shared/context/specialEventsContext';

const Torch = (props) => {
	const { opposite, isFlash } = props;
  
	return (
		<div
		  className={`${
			opposite
			  ? 'torch-opposite'
			  : 'torch'
		  }`}
		>
		  <div className='torch-img-container'>
			<img src={CONECTERE_CONFIG_DATA.SPOTLIGHT_FOCUS} alt='spotlight' height='100%' width='100%' />
		  </div>
		  <div className={`spotlight-effect-wrapper ${isFlash ? 'spotlight-effect' : ''}`} />
		</div>
	);
};

const UserDashboard = () => {

   // Context
	const { currentReactPage, setShowSpinner } = useContext(DisplayContext); 
	const { authState, currentUser } = useContext(AuthContext);
	const { users, participatingUsersOptions, teamsOptions, } = useContext(CustomerContext);  
	const { pushNotification, notificationClickedTriggerOtherPages, notificationRedirectHandled, setNotificationRedirectHandled, redirectState, } = useContext(NotificationContext);
	const { previewRecognitions, spotlightsLoaded, spotlightClickCallback,  handleDeleteSpotlightClick, handleEditSpotlightClick, } = useContext(SpotlightContext);
	const { conectivities, scheduledConectivities,  scheduledConectivitiesToDisplay, invitationToUpdate, setInvitationToUpdate,  messagesToShow, setMessagesToShow, setChatWindowID } = useContext(ConectivityContext);
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle,  setModalBonusLabel,  setModalBonusHeaderColor, setModalBonusMessage, setModalBonusAmount, setShowModalBonusDelayed,  } = useContext(ModalContext); 
	const { activeSpecialEvents } = useContext(SpecialEventsContext);
 
 
	// Local state data
	 const [showModal, setShowModal] = useState(false);
	 const [modalHeaderColor, setModalHeaderColor] = useState("");
	
	const [conectivityToUpdate, setConectivityToUpdate] = useState(CONECTIVITY_INITIAL_STATE);
	const [isUpdatingConectivity, setIsUpdatingConectivity] = useState(false);  //Used as a state variable set to TRUE when updating the backend
	const [scheduledConectivityToProcess, setScheduledConectivityToProcess] = useState(null);
	const [scheduledConectivityToPreview, setScheduledConectivityToPreview] = useState(scheduledConectivityInitialState);
	const [conectivityAction, setConectivityAction] = useState(null);
	const [pendingUpdateSCID, setPendingUpdateSCID] = useState(null);   //ID of an SC, if any, for which we are expecting an update from the backend
	const [launchRuleInstanceStartDateTime, setLaunchRuleInstanceStartDateTime] = useState(null);   //DateTime ISO string for the particular invitation instance that is being edited or deleted by the user
	const [launchRuleInstanceIndex, setLaunchRuleInstanceIndex] = useState(1); // Index of this invitation in a series of events, if the LR has an RRULE.  Index from 1 to N ...

	const [showSendSpotlightModal, setShowSendSpotlightModal] = useState(false);
	const [showModalScheduledConectivityDetailedView, setShowScheduledConectivityDetailedView] = useState(false);      //Detailed Conectivity View

	//Inputs to our Conectivity Launcher
	const [showModalNewInvitation, setShowModalNewInvitation] =  useState (false);
	const [showModalEditInvitation, setShowModalEditInvitation] =  useState (false);
	const [showModalDeleteInvitation, setShowModalDeleteInvitation] =  useState (false);
	const [autoRSVP, setAutoRSVP] =  useState (false);
	const [autoRsvpStatus, setAutoRsvpStatus] =  useState ('');
	const [autoSave, setAutoSave] = useState(false);

	//variable to store dynamic list of users as an option when completing a Conectivity
	// const [participatingUsersOptions, setParticipatingUsersOptions] = useState([]);
	const [participatingUsersDropDownSelection, setParticipatingUsersDropDownSelection] = useState([]);  //Note, this stores an object of the select Option.  See react-select
	const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([]);     //for multi-select
 
	//variables for list of potential invitees (all but invitation owner)
	// const [usersToInviteOptions, setUsersToInviteOptions] = useState([]); //NOW FROM CONTEXT
	// const [usersToInviteDropDownSelection, setUsersToInviteDropDownSelection] = useState([]);  //Note, this stores an object of the select Option.  See react-select

	// const [users,setUsers] = useState([]);
	
	const [expanded, setExpanded] = useState([]);
	
	const [userConectivityRating, setUserConectivityRating] = useState(0); //Range of 1 - 5 if entered by user
	const [isSpecialEvent, setIsSpecialEvent] = useState(false);
	const [specialEventHoverText, setSpecialEventHoverText] = useState('');
	const [specialEventGraphicText, setSpecialEventGraphicText] = useState('');

	const badgeTextColor = setBadgeByScheduledConectivity(scheduledConectivityToPreview, true);
	const badgeImage = setBadgeByScheduledConectivity(scheduledConectivityToPreview);

	//
	// USE EFFECT INVOKED ON ANY CHANGE TO SCHEDULED CONECTIVITIES FOR DISPLAY
	// HANDLE PAGE REDIRECT FROM NOTIFICATIONS OR OTHER PAGES OR BASED ON A CODE IN THE URL
	//
	
	useEffect(() => {

		//Threshold - on any change to SCs reset our pending SC change flag
		if (pendingUpdateSCID) { 
			setPendingUpdateSCID(null);
			if (DEBUG_MODE >= 2) console.log("Resetting pending update flag");
		}
		
		//Ignore until the page has finished fetching the scheduled conectivities and any invitations       
		if (scheduledConectivitiesToDisplay && scheduledConectivitiesToDisplay.length > 0) {
			
			//Is the User viewing a detailed view of a particular Scheduled Conectivity?  IF SO, UPDATE the SC to reflect the new state; may be a new invitation that we need to show
			if (showModalScheduledConectivityDetailedView ) {
				if (DEBUG_MODE >= 2) console.log("User viewing a detailed view of an SC; updating with current state");
				const newSCToDisplay = scheduledConectivities.find(sc => sc.id === scheduledConectivityToPreview.id);
				if (newSCToDisplay) {
					if (DEBUG_MODE >= 2) console.log("Found SC; updating SC To Preview", newSCToDisplay);
					setScheduledConectivityToPreview(newSCToDisplay); //Update the SC for Detailed view

					//Now, Update the messages for the chat window            
					//10-15-2023 - MESSAGE UPDATE HANDLED IN MESSAGE PACKET APP SYNC SUBSCRIPTION
 /*
				   const invitationsWithThisUser = getMyInvitations(newSCToDisplay, currentUser);  
					if (invitationsWithThisUser && invitationsWithThisUser.length > 0) {
						if (DEBUG_MODE >= 2) console.log("User has invitations for the SC being viewed in Detailed View");
						let invitationToView;
						
						//Was the user ALREADY viewing a specific invitation?
						if (invitationToUpdate && invitationToUpdate.id)  invitationToView = invitationsWithThisUser.find(invitation => invitation.id === invitationToUpdate.id);
						if (invitationToView && invitationToView.id) {
							if (DEBUG_MODE >= 2) console.log("SC Detailed View; user was viewing a specific invitation - initializing message window to THAT invitation on this SC",invitationToView);
						} else {
							invitationToView =  invitationsWithThisUser[0];
							if (DEBUG_MODE >= 2) console.log("SC Detailed View; user was not viewing a specific invitation - initializing message window to first invitation on this SC");
						} 
						setInvitationToUpdate(invitationToView);            //Pass the child component the particular invitation to which the messages correspond

						//Set the messages to display as well as the specific invitation
						const tempMessageList = invitationToView.messages.items;
						const sortedMessages = sortMessages(tempMessageList);
						const tempSortedMessages = [...sortedMessages]; //Make a copy to force a React update
						setMessagesToShow(tempSortedMessages);             //Update message currenty being viewed
						if (DEBUG_MODE >= 2) console.log("Updated MessagesToShow",sortedMessages);
				
					} else {
						if (DEBUG_MODE >= 2) console.log("no invitations for this user anymore for this SC; initializing blank message window");
						setMessagesToShow([]);          //initializing blank message window
						setInvitationToUpdate(null);    //Clear the cached invitation, which whill disable the chat window
					} //END IF user has invitations           
*/
				} 
			} //END IF user has Detailed View open

			//On any load of conectivities or a change to the trigger, check whether this was a redirect with state that should be displayed right away
			let matchingScheduledConentivity;
			
			//CHECK URL PARAMS FOR ANY CODE PASSED INTO THIS PAGE
			// VALID OPTIONS
			// slack-action=VIEW  sc-id=xxxx
			// slack-action=SKIP  sc-id=xxxx  user-id=xxxx
			// slack-action=DONE  sc-id=xxxx  user-id=xxxx
			
			const searchParams = new URLSearchParams(currentReactPage?.search);
			// if (DEBUG_MODE >= 2) console.log("Current React Page", currentReactPage);
			// if (DEBUG_MODE >= 2) console.log("Page React Search Params (after ?)", searchParams);
			if (DEBUG_MODE >= 2) console.log("Home Page - checking Path State Data on reload", searchParams, notificationRedirectHandled, scheduledConectivities, redirectState);
 
			//SLACK URL PARAMS?
			if (searchParams.has("slack-action")) { 
				if (DEBUG_MODE >= 1) console.log("Slack ACTION Detected!!");
				const slackAction = searchParams.get("slack-action");
				const scID = searchParams.get("sc-id");
				const userID = searchParams.get("user-id");
				console.log("Params from Slack:", slackAction, scID, userID);
				
				if (slackAction) {
					matchingScheduledConentivity = scheduledConectivitiesToDisplay.find (scheduledConectivity => scheduledConectivity.id === scID);
					
					//Did we find a matching scheduled conectivity that has a matching invitation for the state data we received?
					if (matchingScheduledConentivity) {
						if (DEBUG_MODE >= 2) console.log("Scheduled conectivity identified based on URL params");

						switch(slackAction) {
							case "DONE":
								if (DEBUG_MODE >= 2) console.log("Home Page - invoking DID IT handler based on URL params");
								handleDidItClick(null, matchingScheduledConentivity);
							break;
							 case "SKIP":
								if (DEBUG_MODE >= 2) console.log("Home Page - invoking SKIP handler based on URL params");
								handleSkipClick(null, matchingScheduledConentivity);
							break;
							 case "VIEW":
								if (DEBUG_MODE >= 2) console.log("Home Page - invoking VIEW conectivity based on URL params");
								handleDetailedView(matchingScheduledConentivity);
							break;
							default:
								if (DEBUG_MODE >= 1) console.log("Error - unrecognized SLACK command from URL params");
							break;
						   
						}
						
					} else if (DEBUG_MODE >= 1) console.log("ERROR - no matching scheduled conectivity identified based on URL params");

				}
		   }
			
			//CHECK OUR REDIRECT REACT STATE VAR THAT IS USED FOR INTERNAL REDIRECTS FROM OTHER PAGES
			if (redirectState && !notificationRedirectHandled) {
				
				//INTERNAL REDIRECT with a scheduledConectivityID??
				if (redirectState.scheduledConectivityID) {
					
					//Display the messaging window for the related invitation, assuming it's in our array of scheduled conectivities loaded for the page
					matchingScheduledConentivity = scheduledConectivitiesToDisplay.find (scheduledConectivity => scheduledConectivity.id === redirectState.scheduledConectivityID);
					
					//Did we find a matching scheduled conectivity that has a matching invitation for the state data we received?
					if (matchingScheduledConentivity) {
						handleDetailedView(matchingScheduledConentivity)
					} else {
						if (DEBUG_MODE >= 2) console.log("Notification redirect - No matching scheduled conectivity");
					}
				} else {
					if (DEBUG_MODE >= 2) console.log("Notification redirect - No invitation ID state or scheduled con ID state");
				}
			} else {
				if (DEBUG_MODE >= 2) console.log("Notification redirect - No state or flag");
			}
			setNotificationRedirectHandled(true);  //set handled flag in notification context

		} else {
			// if (DEBUG_MODE >= 2) console.log("Notification redirect - conectivities not loaded");
		}

	}, [scheduledConectivitiesToDisplay, notificationClickedTriggerOtherPages]);                    
 
 
	 //
	 // SPOTLIGHTS
	 //
 
   //Spotlight recognition handler

	function spotlightSendtoolCallbackHandler (successFlag, completionMessage) {

		if (DEBUG_MODE >= 2) console.log("User Home Callback called", successFlag, completionMessage);
		setShowSendSpotlightModal(false);

		if (successFlag) {
			setModalGeneralMessage("Spotlight cast!! Your peer recognition message will be posted shortly!");
			setModalGeneralTitle("Success!");
			setShowModalGeneral(true);   
		} else {
		   setModalGeneralMessage("Failed to send recognition");
			setModalGeneralTitle("Ooops!");
			setShowModalGeneral(true);   
		}
	}

	//
	//
	// INVITATION HANDLING FUNCTIONS
	//
	//
	//
	
	
	//   
	//Main function for processing a Conectivity that the user has completed
	//Also handles REJECT button
	//
	
	async function handleConfirmation(e) {
		
		e.preventDefault();
		setShowSpinner(true); //Show spinners

		//Hide Modal here so user can see screen updates
		setShowModal(false);
		setShowScheduledConectivityDetailedView(false); //close if open
		try {    


			//Main function call to create the Spotlight int the system
			var params = {
				user:currentUser,
				command:conectivityAction,
				scheduledConectivity:scheduledConectivityToProcess,
				conectivity:conectivityToUpdate,
				usersThatParticipatedWithMe:[],
				teamsThatParticipatedWithMe:[], 
				userRating:userConectivityRating,
			};
			
			
			//Handling a completed conectivity?  If so, construct an array of user IDs and an array of team IDs that participated in the Conectivity 
			//Loop through participating user options and construct an array of user IDs
			//Skip any ID that happens to be the same as the user recording the transaction
			if (conectivityAction==="COMPLETED_CONECTIVITY") {
				if (participatingUsersDropDownSelection) {
					for (var j=0 ; j < participatingUsersDropDownSelection.length; j++) {
						//if (DEBUG_MODE >= 2) console.log("Adding selected user:", participatingUsersDropDownSelection[j]);
						if (participatingUsersDropDownSelection[j].id !==currentUser.id) params.usersThatParticipatedWithMe.push (participatingUsersDropDownSelection[j].id);
					}
				}
				
				//Construct an array of IDs of teams that participated in the Conectivity 
				if (selectedTeamsOptions) {
					for (var j=0 ; j < selectedTeamsOptions.length; j++) {
						//if (DEBUG_MODE >= 2) console.log("Adding selected team:", selectedTeamsOptions[j]);
						params.teamsThatParticipatedWithMe.push (selectedTeamsOptions[j].id);
					}
				}
			}
			const returnObject = await invokeProcessConectivity(params);            
			if (DEBUG_MODE >= 2) console.log("Process Conectivity Lambda returned", returnObject);        
			
			//Did the user close a ring and earn a bonus?
			if (returnObject && returnObject.returnParams && returnObject.returnParams.successFlag && returnObject.returnParams.bonusGenerated) {
				if (DEBUG_MODE >= 2) console.log("BONUS earned", returnObject.returnParams.bonusMemo, returnObject.returnParams.bonusAmount);        
				setModalBonusLabel(conectivityToUpdate.category.label);
				setModalBonusMessage(returnObject.returnParams.bonusMemo);
				setModalBonusAmount(returnObject.returnParams.bonusAmount);
				setModalBonusHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivityToProcess)); 

	
				//Set a timer to trigger a modal after animation has closed ring 
				const bonusModalTimer = setTimeout(() => {
					setShowModalBonusDelayed(true);        //display Bonus Modal
				 }, 4000);                 
			} 
			//Did the lambda return an error?
			else if (returnObject && returnObject.returnParams && returnObject.returnParams.successFlag === false) {
				setModalGeneralTitle("Ooops!");
				setModalGeneralMessage('Something did not go quite right...');
				setShowModalGeneral(true);
			}
		}
		
		catch (err) {
			if (DEBUG_MODE >= 1) console.log('error creating transaction and updating wallet:', err);
		}
		setIsUpdatingConectivity(false); //enable buttons on main page
		setShowScheduledConectivityDetailedView(false); //close if open
		setShowModal(false);
		setShowSpinner(false); //Hide spinners
	}
		
	const handleCancel = () => {
			setShowModalEditInvitation(false);
			setShowModalDeleteInvitation(false);
			// setMessageInputBarHeight("30px");
			setShowSendSpotlightModal(false);
			setShowModal(false);
			setConectivityToUpdate(CONECTIVITY_INITIAL_STATE);
			setShowModalNewInvitation(false);
			setShowModalBonusDelayed(false);
			setShowModalGeneral(false);
			setIsUpdatingConectivity(false); //enable buttons on main page
			setAutoRSVP(false);
			setAutoRsvpStatus('');
			setAutoSave(false);

			// setShowPickerMessage(false);
			// setShowSearchExperienceSpotlight(false);
			// setShowSearchExperienceMessage(false);
			// setMessageGif(null);
			// if (DEBUG_MODE >= 2) console.log("Cancel button called")
		};
 
	 //This function handles the MAIN INVITE button used to create a new invitation
	//Pop-up a modal and allow user to update this particular invitation / group chat  
	//Note, we are reusing the same Modal as the approve and discard buttons
	//NOTE, INVITATION is NOT passed in since the user is sending the first invitation 
	
	const handleNewInvitation = (e, scheduledConectivity) => {
		if (e) e.stopPropagation();                     //Prevent outer Card OnClick from firing 

		setIsUpdatingConectivity(true); //disable buttons on main page
		
		if (DEBUG_MODE >= 2) console.log("Invite button pressed.  Scheduled Conectivty=", scheduledConectivity);


		setConectivityToUpdate(scheduledConectivity.conectivity);
		setConectivityAction("INVITES_CONECTIVITY");
		setScheduledConectivityToProcess(scheduledConectivity);
		setModalHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivity));
		setShowModalNewInvitation(true); //pop-up Modal
		setAutoRSVP(false);
		setAutoRsvpStatus('');
		setAutoSave(false);
	};

		const handleCloseConectivityLauncher = ({result, message}) => {
			
			//Reset state data
			setShowModalNewInvitation(false);
			setShowModalEditInvitation(false);
			setShowModalDeleteInvitation(false);
			setConectivityToUpdate(CONECTIVITY_INITIAL_STATE);
			setAutoRSVP(false);
			setAutoRsvpStatus('');
			setAutoSave(false);
			setIsUpdatingConectivity(false);

			if (result !== "CANCELED" && message) {
				if (result) {  
					setModalGeneralTitle("Success!!");  
					setPendingUpdateSCID(scheduledConectivityToProcess.id);  //Tell our SC components which one should be updated shortly by the backend
					if (DEBUG_MODE >= 2) console.log("Conectivity Launcher closed with success.  Setting pending update", scheduledConectivityToProcess.id);
					
				} else setModalGeneralTitle("Ooops!");
				
				setModalGeneralMessage(message);
				setShowModalGeneral(true);
				
			}
			
			if (DEBUG_MODE >= 2) console.log("Closed Conectivity Launcher", result, message);
		};
		
		
	 //This callback function is invoked by a chiled Scheduled Conectivity component and handles the EDIT INVITE button from an inviation sub-menu
	//Pop-up a modal and allow user to update this particular invitation 

	const handleEditInvitation = (invitation, scheduledConectivity, launchRuleInstanceStartDateTime, launchRuleInstanceIndex) => {

		if (DEBUG_MODE >= 2) console.log("Edit Invitation Button Pressed", invitation, scheduledConectivity, launchRuleInstanceStartDateTime);

		setIsUpdatingConectivity(true);     //disable buttons on main page
		setInvitationToUpdate(invitation);  /*Used by functions for updating backend */
		setLaunchRuleInstanceStartDateTime(launchRuleInstanceStartDateTime); // Record the particular date/time (instance) of the invitation
		setLaunchRuleInstanceIndex(launchRuleInstanceIndex);
		setConectivityToUpdate(scheduledConectivity.conectivity);
		setScheduledConectivityToProcess(scheduledConectivity);
		setPendingUpdateSCID(null);
		setShowModalEditInvitation(true);       //Invoke our launcher component
		setAutoRSVP(false);
		setAutoRsvpStatus('');
		setAutoSave(false);
	};

	//Local handler for deleting this invitation
	async function handleDeleteInvitation (e, invitation, scheduledConectivity, launchRuleInstanceStartDateTime) {
		if (DEBUG_MODE >= 2) console.log("Decline Invitation Button Pressed", invitation);
		if (e) e.stopPropagation();         //If on home page, prevent outer Card OnClick from firing and displaying full instruction modal
		setIsUpdatingConectivity(true);     //disable buttons on main page
		setInvitationToUpdate(invitation);  /*Used by functions for updating backend */
		setLaunchRuleInstanceStartDateTime(launchRuleInstanceStartDateTime); // Record the particular date/time (instance) of the invitation
		setConectivityToUpdate(scheduledConectivity.conectivity);
		setScheduledConectivityToProcess(scheduledConectivity);
		setPendingUpdateSCID(null);
		setAutoRSVP(false);
		setAutoRsvpStatus('');
		setAutoSave(false);    
		setShowModalDeleteInvitation(true);       //Invoke our launcher component          
	}

	//Local handler for deleting this invitation
	async function handleDeclineInvitation (e, invitation, scheduledConectivity, launchRuleInstanceStartDateTime) {
		if (DEBUG_MODE >= 2) console.log("Decline Invitation Button Pressed", invitation);
		if (e) e.stopPropagation();         //If on home page, prevent outer Card OnClick from firing and displaying full instruction modal
		setIsUpdatingConectivity(true);     //disable buttons on main page
		setInvitationToUpdate(invitation);  /*Used by functions for updating backend */
		setLaunchRuleInstanceStartDateTime(launchRuleInstanceStartDateTime); // Record the particular date/time (instance) of the invitation
		setConectivityToUpdate(scheduledConectivity.conectivity);
		setScheduledConectivityToProcess(scheduledConectivity);
		setPendingUpdateSCID(scheduledConectivity.id);  //Tell our SC components which one should be updated shortly by the backend
		setAutoRSVP(true);                
		setAutoRsvpStatus('DECLINED');                 
		setAutoSave(true);        
		setShowModalEditInvitation(true);   //Invoke our launcher component; no modal will be displayed since AutoSave is set to true
	}
	
	 //This function is called when the user wants to update an existing invite from the messaing window

	const [showUpdateInviteFields, setShowUpdateInviteFields] = useState (false); 
	const [showUpdateInviteCalendar, setShowUpdateInviteCalendar] = useState(false); 
	// const [minHeightEnabled, setMinHeightEnabled] = useState (false); 
   
	// const handleShowInviteUpdateFields = (e, view) => {

	//     //Toggle overflow visibility - need to switch to overflow visible when expanded so the React dropdown shows when used
	//     // const heightTimer = setTimeout(() => {
	//     //     setMinHeightEnabled(!minHeightEnabled);  
	//     // }, 2000);        

 
	//     //If currently NOT viewing edit fields, start timer to adjust minHeight and visibility of SELECT since these do NOT animate and need to be visible if the user selects the SELECT dropdown
	//     // if (!showUpdateInviteFields) {
	
	//     //     const heightTimer = setTimeout(() => {
	//     //         setMinHeightEnabled(!minHeightEnabled);  
	//     //     }, 4000);        
		
	//     // } else {
	//     //     //Make invisible right away before animation closes the area
	//     //     setMinHeightEnabled(!minHeightEnabled);  //if closing, remove minHeight immediately?
			
	//     // }
		
	//     //This will trigger the animation to expand / close the edit window
	//     setShowUpdateInviteFields(!showUpdateInviteFields); //expand to show hidden fields
	//     // if (DEBUG_MODE >= 2) console.log("Toggled show update fields to", !showUpdateInviteFields);
	// };
	
	 //This function handles the INVITE MANAGER button
	//Pop-up a modal and allow user to update invites. 

	// const handleInviteManager = (scheduledConectivity) => {

	//     setIsUpdatingConectivity(true); //disable buttons on main page
		
	//     //if (DEBUG_MODE >= 2) console.log("Invite MANAGER button pressed.  Scheduled Conectivty=", scheduledConectivity);

	//     setConectivityToUpdate(scheduledConectivity.conectivity);
	//     setConectivityAction("INVITES_CONECTIVITY");
	//     setScheduledConectivityToProcess(scheduledConectivity);
	//     setModalHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivity)); 
 
		
	//     //COMPILE A LIST OF INVIATIONS IN WHICH THIS USER IS A MEMBER
		
	//     //First, include ALL / ANY invitiations for which this user is a recipient
	//     const tempUserInvitations = scheduledConectivity.invitations.items.filter (invitation => invitation.invitees.items.find(invitee => invitee.inviteeID === currentUser.id));
	//     //if (DEBUG_MODE >= 2) console.log("Identified the following invitations of which this user is a recipient", tempUserInvitations);

	//     //Add user's own invitation, if any
	//     const userInvite = scheduledConectivity.invitations.items.find(invitation => invitation.senderID === currentUser.id);
	//     if (typeof userInvite !="undefined") {
	//         tempUserInvitations.push(userInvite);   //OK, push our OWN invitation for this Scheduled Conectivity
	//     }
	//     //if (DEBUG_MODE >= 2) console.log("Added any invitation for which this user is the owner", tempUserInvitations);

	//     setInvitationsForThisScheduledConectivity(tempUserInvitations);
		
	//     setShowModalNewInvitation(true);
	// };
			
		
	//This function handles marking a conectivity as complete; Pop-up a modal and ask user to confirm. Handle confirmation
	const handleDidItClick = (e, scheduledConectivityToProcess) => {
		if (DEBUG_MODE >= 2) console.log("Done button pressed.  Approved Conectivty=", scheduledConectivityToProcess.conectivity, e);
		if (e) e.stopPropagation();            //If on home page, prevent outer Card OnClick from firing and displaying full instruction modal
		setIsUpdatingConectivity(true); //disable buttons on main page
		setConectivityToUpdate(scheduledConectivityToProcess.conectivity);
		setConectivityAction("COMPLETED_CONECTIVITY")
		setScheduledConectivityToProcess(scheduledConectivityToProcess);
		setPendingUpdateSCID(null);
		setScheduledConectivityToPreview(scheduledConectivityToProcess);

		setModalHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivityToProcess)); 
 
		//To aid the user, let's pre-load the dropdown selections based on any original invitation data where we stored the IDs for invited Teams and Users
		//Note, let's only do this if there is exactly 1 invitation for the scheduled conectivity for this user
		
		// Get an array of invitations for which the user is an INVITEE

		const invitationsWithThisUser = getMyInvitations(scheduledConectivityToProcess, currentUser, true);
		
		if (DEBUG_MODE >= 2) console.log("Processing DID IT - Invitations for this user", invitationsWithThisUser);

		let tempSetParticipatingUsersDropDown = []; //Temp array to build out the options
		let tempSelectedTeamsOptions = [];          //Temp array to build out the options
 
		 if (invitationsWithThisUser?.length > 0) {  //At least 1 invitation with this user as an invitee?
		 
			//Preload participating user dropdown with all invitees on this invitation, which will include ALL users whether from a team or not
			//Note, we are not using the User IDs stored at the invitation level rather the actual full set of invitees, which may be different due to adds / drops / declines by individuals
			for (let i=0 ; i < invitationsWithThisUser[0].invitees.items.length; i++) {
			   //Look in the array of Participating Users Options (which excludes the current user) to find the existing dropdown option for the invitee on this particular invitation
				const userOption = participatingUsersOptions.find(userOption => userOption.id === invitationsWithThisUser[0].invitees.items[i].inviteeID);
			   if (userOption) {
				   tempSetParticipatingUsersDropDown.push(userOption); 
					if (DEBUG_MODE >= 2) console.log("preloaded user option for DID IT", userOption, tempSetParticipatingUsersDropDown);
			   }
			}
			
			if (tempSetParticipatingUsersDropDown.length >0) setParticipatingUsersDropDownSelection (tempSetParticipatingUsersDropDown);  //Update state data for multi-select			
			if (invitationsWithThisUser[0].teamsInvitedToParticipate !== null) {
			   //Preload Team dropdown with Team IDs stored at the invitation level
				for (let i=0 ; i < invitationsWithThisUser[0].teamsInvitedToParticipate.length; i++) {	
				   //Look in the array of Team Options to find the existing dropdown option for the team ID stored as an invitee on this particular invitation
					const teamOption = teamsOptions.find(teamOption => teamOption.id === invitationsWithThisUser[0].teamsInvitedToParticipate[i]);
				   if (teamOption) {
					   tempSelectedTeamsOptions.push(teamOption); 
						if (DEBUG_MODE >= 2) console.log("prelaoded team option for DID IT", teamOption, tempSelectedTeamsOptions);
				   }
				}
			}
		}

		//Update pre-loaded data; store emptly arrays if blank     
		setParticipatingUsersDropDownSelection(tempSetParticipatingUsersDropDown);  //Set participating users
		setSelectedTeamsOptions(tempSelectedTeamsOptions);                          //Set TEAMS
		setUserConectivityRating(5); //Default to FIVE stars
		setShowScheduledConectivityDetailedView(false);   //Close the detailed view in case the Done button came from there
		setShowModal(true); //pop-up Modal
	};
	
	
	/*
	//This function handles the message button when pressed on a specific SC card
	//Pop-up a modal and displays the current messages and who sent them
	const handleShowMessages = (e, invitation, scheduledConectivity, editMode) => {

		if (e) e.stopPropagation();        //If on home page, prevent outer Card OnClick from firing and displaying full instruction modal
		setIsUpdatingConectivity(true); //disable buttons on main page
		const tempMessageList = invitation.messages.items;
		const sortedMessages= sortMessages(tempMessageList);
		setMessagesToShow([...sortedMessages]);             //Update message currenty being viewed

		if (DEBUG_MODE >= 2) console.log("Show Modal Message button pressed. ", invitation, scheduledConectivity, editMode);
		
		// setInvitationToProcess(invitation); 
		setInvitationToUpdate(invitation);                                  //Record the InV - Used by functions for updating backend
		setConectivityToUpdate(scheduledConectivity.conectivity);           //Record the Con
		setScheduledConectivityToPreview(scheduledConectivity);             //Record the SC
		setScheduledConectivityToProcess(scheduledConectivity);
		setModalHeaderColor(setHeaderColorByScheduledConectivity(scheduledConectivity)); 
		setChatWindowID(invitation.launchRuleID);                 //Record the LR ID so we can determine whether to inject any messages received via subscription
		if (editMode == null) editMode = false;
		setShowModalMessages(true); //pop-up Modal
	};
	*/
	//This function handles the SKIP buttons
	//Pop-up a modal and ask user to confirm. Handle confirmation
	const handleSkipClick = (e, rejectedScheduledConectivity) => {
		
		if (e) e.stopPropagation();        //If on home page, prevent outer Card OnClick from firing and displaying full instruction modal

		setIsUpdatingConectivity(true); //disable buttons on main page
		
		// if (DEBUG_MODE >= 2) console.log("Skip button pressed.  Rejected Conectivty=", rejectedScheduledConectivity.conectivity);

		setModalHeaderColor(setHeaderColorByScheduledConectivity(rejectedScheduledConectivity)); 
		
		setConectivityToUpdate(rejectedScheduledConectivity.conectivity);
		setConectivityAction("REJECTED_CONECTIVITY");
		setScheduledConectivityToProcess(rejectedScheduledConectivity);//Grab the scheduled connectivity so we can collect data even though not approved
		setPendingUpdateSCID(null);
		setShowModal(true); //pop-up Modal
	};

	const handleParticipatingUsersSelect = async (eventKey) => {
		//if (DEBUG_MODE >= 2) console.log("Participating user added", eventKey);
		setParticipatingUsersDropDownSelection(eventKey);
	};

	const handleSelectParticipateUser = async (user) => {
		let data = JSON.parse(JSON.stringify(participatingUsersDropDownSelection))
		let fIndex = data?.findIndex(el => el?.id === user?.id);
		if (fIndex === -1) data.push(user);
		else data.splice(fIndex, 1);
		setParticipatingUsersDropDownSelection(data);
	};

	//Function invoked by this user to merge all the invitations that this user is on for a given scheduled conectivity
	async function handleMergeInvitations (e, scheduledConectivity) {

		e.stopPropagation();        //Prevent outer Card OnClick from firing 
		e.preventDefault();         //Required for backend calls
		setShowSpinner(true);
		//invoke our utility to merge all the invitations on this scheduled conectivity for this particular user
		mergeInvitations({scheduledConectivity:scheduledConectivity, currentUser:currentUser, users:users, pushNotification:pushNotification });   
		setIsUpdatingConectivity(false); //enable buttons on main page
		setShowSpinner(false);

	}

	 //Card to display when NO Conectivities
	const DisplayCardNoConectivties = ({isLoading}) => {
		return (
			<div className="conectivityCardOuterWrapper">
				<div  className="conectivityCardRoot" style={{minHeight:'100px'}}>
					<div className="ContainerVerticalStart positionRelative fullWidth " >
						<div className="ContainerNoHeightCenter fullWidth " style={{backgroundColor:'white', padding:"10px"}} >
							<img className="avatarImageLarge" src={ CONECTERE_CONFIG_DATA.RING_LOGO_IMAGE_PATH}  />
						</div>
						<Divider />
						{isLoading ?
							<>
								<div className="TextStyle4 grey" style={{textAlign: 'center', padding:'10px'}}>  One minute.  Loading ... </div>
							</>
					   
						: 
							<>
								<div className="TextStyle4 grey" style={{textAlign: 'center', padding:'10px'}}>  Well done!!  You've closed all your rings and completed all the active conectivities </div>
								<div className="TextStyle4 grey" style={{textAlign: 'center', padding:'10px'}}>  Looking for more ways to connect with your colleagues?  Browse the <Link className="purple" to="/launchpad">  launchpad </Link> and launch a conectivity <LaunchIcon color={TEAM_COLOR} />  whenever you'd like!</div>
							</>
						}
					</div>
				</div>
			</div>
		);
	};
	
	//Main function for assembling the table of Conectivities
	function displayConectivities () {
		if (DEBUG_MODE >= 2) console.log("Re-rendering scheduled contectivities", scheduledConectivitiesToDisplay);
		return (

				<div className="ContainerNoHeightFlexLeft wrap userHomeConectivitiesDisplayWrapper fullWidth">
	
					{scheduledConectivitiesToDisplay && scheduledConectivitiesToDisplay.length===0 ? DisplayCardNoConectivties({isLoading:(!conectivities || conectivities.length === 0 || !scheduledConectivities || scheduledConectivities.length === 0)}) : null}
				
					{scheduledConectivitiesToDisplay.map((scheduledConectivity, index) => (
							<ScheduledConectivityCard 
								key={scheduledConectivity.id} 
								currentUser={currentUser}
								scheduledConectivity={scheduledConectivity} 
								index={index} 
								newInvitationCallback={handleNewInvitation} 
								editInvitationCallback={handleEditInvitation} 
								deleteInvitationCallback={handleDeleteInvitation} 
								declineInvitationCallback={handleDeclineInvitation} 
								mergeInvitationsCallback={handleMergeInvitations} 
								handleDetailedView={handleDetailedView}
								handleShowMessages={handleDetailedView}
								handleDidItClick = {handleDidItClick} 
								handleSkipClick = {handleSkipClick}
								pendingUpdateSCID = {pendingUpdateSCID}
							/>
					))}				
				</div>
		);
	}   

/*
	const handleCloseModalBonus= () => {
		setShowModalBonusDelayed(false);
	};
	
	
	const handleCloseModalError = () => {
		setShowModalGeneral(false);
		setShowModalBonusDelayed(false);
	};
*/    
/*
	const handleCloseModalMessages = () => {
		if (DEBUG_MODE >= 2) console.log("Close Modal Messages invoked");
		setShowModalMessages(false);
		setIsUpdatingConectivity(false);
		setChatWindowID(null);
		setShowScheduledConectivityDetailedView(false);
		setScheduledConectivityToPreview(scheduledConectivityInitialState);
		if (invitationToUpdate) updateMessageReadAt(invitationToUpdate, currentUser);    //Update the timestamp for messages read of this invitation

	};
*/
	 //handle close modal
	const handleCancelDetailedView = () => {
			setShowScheduledConectivityDetailedView(false);
			setScheduledConectivityToPreview(scheduledConectivityInitialState);
			setChatWindowID(null);
			if (invitationToUpdate) updateMessageReadAt(invitationToUpdate, currentUser);    //Update the timestamp for messages read of this invitation
			if (DEBUG_MODE >= 2) console.log("Closed Scheduled Conectivity Detail View.");
		};
		
	//React callback from child components - handle open modal
	const  handleDetailedView = useCallback((scheduledConectivityToPreview) => {
			if (DEBUG_MODE >= 2) console.log("Opened Scheduled Conectivity Detail View.", scheduledConectivityToPreview);
			setScheduledConectivityToPreview(scheduledConectivityToPreview);
			setShowScheduledConectivityDetailedView(true);

			//Initialize the messaging window for this SC
			const invitationsWithThisUser = getMyInvitations(scheduledConectivityToPreview, currentUser, true);                                                                                    
			if (invitationsWithThisUser && invitationsWithThisUser.length > 0) {
				if (DEBUG_MODE >= 2) console.log("Detailed View Launch - initializing message window to first invitation");
				const tempMessageList = invitationsWithThisUser[0].messages.items;
				const sortedMessages= sortMessages(tempMessageList);
				setMessagesToShow([...sortedMessages]);             //Update message currenty being viewed
				setInvitationToUpdate(invitationsWithThisUser[0]);  //Pass the child component the particular invitation to which the messages correspond
				if (invitationsWithThisUser[0].launchRuleID) setChatWindowID(invitationsWithThisUser[0].launchRuleID);   //Record the ID of this invitation so we can updated the view in real-time if we receive any messages for this LR from the back-end
			} else {
				if (DEBUG_MODE >= 2) console.log("SC has NO invitation for this user; cleared InvitationToUpdate", scheduledConectivityToPreview);
				setInvitationToUpdate(null);
				if (scheduledConectivityToPreview.launchRuleID)  setChatWindowID(scheduledConectivityToPreview.launchRuleID); else setChatWindowID(null);
			}     
	},[scheduledConectivitiesToDisplay, invitationToUpdate]);   

		
	const handleCloseBonusModal = useCallback(() => {
		if (DEBUG_MODE > 1) console.log("Close BONUS modal callback invoked.  Closing modal");
		setShowModalBonusDelayed(false);
	}, []);

	useEffect(() => {
		let tempIsSpecialEvent = (scheduledConectivityToPreview.conectivity.isSpecialEvent ? true : false);

			//Does this particular SC matches an active special event?
			if (activeSpecialEvents && activeSpecialEvents.length > 0) {
				let {specialEventMultipler, reason} = setSpecialEventMultiplier({ conectivity:scheduledConectivityToPreview.conectivity, activeSpecialEvents });
				if (specialEventMultipler && specialEventMultipler > 1) {
					let multiplierString = specialEventMultipler.toString() + 'X';
					if (DEBUG_MODE > 1) console.log("Set multiplier string", multiplierString);
					tempIsSpecialEvent = true; setSpecialEventHoverText(reason);setSpecialEventGraphicText(multiplierString);									
				} else {
					setSpecialEventHoverText('');setSpecialEventGraphicText('');
				}
			}

			//Update our state
			setIsSpecialEvent(tempIsSpecialEvent);
	}, [scheduledConectivityToPreview]);

	// create interval for spotlight flash animation
	const intervalRef = useRef(null);
	const timeoutRef = useRef(null);
	const [isFlash, setIsFlash] = useState(false);

	const restartTimer = () => {
		if (intervalRef.current) clearInterval(intervalRef.current);
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		setIsFlash(true);
		intervalRef.current = setInterval(() => {
			setIsFlash(false);
			timeoutRef.current = setTimeout(() => {
				setIsFlash(true);
			}, 50);
		}, 8000);  // 8-second delay for the flash animation cycle
	};

	// Handle component unmount or cleanup
	useEffect(() => {
		// restartTimer();
		return () => {
			clearInterval(intervalRef.current);
			clearTimeout(timeoutRef.current);
		};
	}, []);

	// Handle next/prev button click
	const handleNextPrevClick = () => {
		setIsFlash(false);
		setTimeout(() => restartTimer() , 0);
	}


	// const onEmojiClick = (event, emojiObject) => {
	//     setInviteMessage(inviteMessage => inviteMessage + emojiObject.emoji);
	//     setShowPicker(false);
	// };
	

/* 10-9-2023 Removed in view of the ScheduledConectivityChatView component

	//Messaging Modal functions
	 function messagesModalHeader () {
		 
		 if (!scheduledConectivityToProcess) return null;
		 
		 return (
			   <div className="ContainerNoHeightCenter fullWidth maxHeight conectivityCardHeaderWrapper" style={{backgroundColor: setHeaderColorByScheduledConectivity(scheduledConectivityToProcess)}} >
					<div className="ContainerNoHeightCenter conectivityCardHeaderImageWrapper" >
						<img className="conectivityCardHeaderImage" src={setConectivityImageUrlByConectivity(scheduledConectivityToProcess.conectivity)} />
					</div>
					<div className="ContainerVerticalSpaceAround maxHeight conectivityCardHeaderTextWrapper" >
						<div className="TextStyle6 white" > {scheduledConectivityToProcess.conectivity.title} </div>
						<ConectivityDurationTimer closingDateTime={scheduledConectivityToProcess.closingDateTime} isLarge={true}/>
					</div>
				</div>
			 );
	 }
 

	function displayMessagingModal () {

		if (!showModalMessages) return null;
		
		const headerColor = setHeaderColorByScheduledConectivity(scheduledConectivityToProcess);
		
		return (
		  <Modal show={showModalMessages} onHide={handleCloseModalMessages} size="lg" dialogClassName="defaultBootstrapModal">
		  
			  <div style={{position:"absolute", top:"2px", right:"6px"}}><CloseIcon onClick={() => handleCloseModalMessages()} style={{color:headerColor, backgroundColor:"white", fontSize:"14px"}}/></div>

				<Modal.Header style={{backgroundColor: headerColor, color:"white"}} >
					{messagesModalHeader()}    
				</Modal.Header>
				
				<ChatWindow currentUser={currentUser} headerColor={headerColor} />    
		 </Modal>
		);        
	}
*/
  //
  // Page Render
  //


  //Define Page - require authentication before rendering the page; this way it will load after authentication
 
/*
  if (authState !== 'signedin') {
	  return ( 
		 <Authenticator>
				 <div slot="federated-buttons"></div>
		</Authenticator>
  ); 
  } 
*/ 

  //No authenticated yet? Return nothing
  if (authState !== "signedin"  || !currentUser) return null;

 //Render function for authenticated user  
  return (
		<Authenticator>	 
			<div className="userHomePageWrapper" >

				
				{/*displayMessagingModal()*/}

{/*
				<ScheduledConectivityChatView 
					showScheduledConectivityChatView={showModalMessages} 
					scheduledConectivity={scheduledConectivityToPreview} 
					invitationToUpdate={invitationToUpdate}
					messagesToShow={messagesToShow}
					currentUser={currentUser} 
					showInvitations={true} 
					mergeInvitationsCallback={handleMergeInvitations} 
					editInvitationCallback={handleEditInvitation} 
					deleteInvitationCallback={handleDeleteInvitation} 
					declineInvitationCallback={handleDeclineInvitation} 
					newInvitationCallback={handleNewInvitation}
					handleCloseModalMessages={handleCloseModalMessages} 
					pendingUpdateSCID={pendingUpdateSCID}
				/>            
*/}			
				<ConnectivityInfoViewer 
					showScheduledConectivityDetailedView={showModalScheduledConectivityDetailedView} 
					scheduledConectivity={scheduledConectivityToPreview} 
					// invitationToUpdate={invitationToUpdate}		- NO LONGER USED.  SC DETAILED VIEW AUTOMATICALLY FINDS THIS USER'S INVITATION(S) AND STARTS WITH THE LOWEST ONE
					messagesToShow={messagesToShow}
					currentUser={currentUser} 
					showInvitations={true} showCompletionButtons={true} showMessages={true} 
					newInvitationCallback={handleNewInvitation} 
					mergeInvitationsCallback={handleMergeInvitations} 
					editInvitationCallback={handleEditInvitation} 
					deleteInvitationCallback={handleDeleteInvitation} 
					declineInvitationCallback={handleDeclineInvitation} 
					handleCancelDetailedView={handleCancelDetailedView} 
					handleSkipClick={handleSkipClick} 
					handleDidItClick={handleDidItClick}
					pendingUpdateSCID={pendingUpdateSCID}
				/>

			  <ConectivityLauncher  
				showModalSendInviteToConect={showModalNewInvitation}  
				showModalEditInviteToConect={showModalEditInvitation}
				showModalDeleteInviteToConect={showModalDeleteInvitation}
				conectivityToProcess={conectivityToUpdate} 
				scheduledConectivityToProcess={scheduledConectivityToProcess} 
				invitationToUpdate={invitationToUpdate}                 
				launchRuleToEdit={invitationToUpdate ? invitationToUpdate.launchRule : null}                 
				handleCloseLauncher={handleCloseConectivityLauncher}  
				launchRuleInstanceStartDateTime={launchRuleInstanceStartDateTime}
				launchRuleInstanceIndex={launchRuleInstanceIndex}
				autoSave={autoSave}
				autoRSVP={autoRSVP}
				autoRsvpStatus={autoRsvpStatus}
			 />

             <div className='ContainerVerticalStart userHomePageInnerWrapper'>
                <div className='ContainerNoHeight userDashboardFirstRow'>
						{currentUser.customer.configDetails.spotlightsEnabled && spotlightsLoaded && scheduledConectivitiesToDisplay ? 
							<div className='dashboardSpotlightContainer'>
								{/* {currentUser.customer.configDetails.spotlightsEnabled && spotlightsLoaded && scheduledConectivitiesToDisplay ? 
									<SpotlightRow 
										key={"spotlightRowHomePage"} 
										spotlights={previewRecognitions} 
										isSmall={true} 
										callBack={spotlightClickCallback} 
										handleDeleteSpotlightClick={handleDeleteSpotlightClick} 
										handleEditSpotlightClick={handleEditSpotlightClick}
									/>
								: null } */}
								<div key="spotlightRowHomePage" className="fullWidth">
									<div key="spotlightRowHomePageContainer" className='spotlightCardWrapper cardShadow'>
										<Torch isFlash={isFlash} />
										<Torch isFlash={isFlash} opposite />
										<AnimatedSpotlightCard 
											key={"spotlightRowHomePage"} 
											spotlightsList={previewRecognitions}
											spotlightClickCallback={spotlightClickCallback} 
											handleDeleteSpotlightClick={handleDeleteSpotlightClick} 
											handleEditSpotlightClick={handleEditSpotlightClick}
											handleCastClick={setShowSendSpotlightModal}
											handleFlashNextPrevClick={handleNextPrevClick}
										/>
									</div>
								</div>
							</div>
						  : null }
                    <div className="ContainerNoHeight dashboardRingViewContainer">
                            <CCRingsAnimatedView userProgressData={currentUser.userProgressData}/>
                    </div>
                </div>
				<div className="ContainerVerticalLeftStart userHomeMyConnectivityWrapper fullWidth" >
					<p className="TextStyle7V2 bold">My Conectivities</p>
					{displayConectivities()}
				</div>
             </div>

			 {/* === done modal === */}
			 <div className="ModelContainer" hidden={!showModal}>
				<div className="ModalBgContainer" onClick={handleCancel} />
				<div className="SmallModal ConnectivityInfoViewPaper" style={{ maxHeight: "calc(90vh - 3em)" }}>
					<SpecialEventsBadges
						badgeImage={badgeImage}
						textColor={badgeTextColor}
						isSpecialEvent={isSpecialEvent}
						specialEventGraphicText={specialEventGraphicText}
						specialEventHoverText={specialEventHoverText}
					/>
					<div className='doneModalCard boxShadow ConectivitiesDetailedViewCard'>
						<CloseIcon className="TextStyle6 pointer dust-grey connectivityModalCloseIcon" onClick={handleCancel} />
						<div className='ContainerNoHeightCenter doneModalTitleWrapper'>
							<h4>
								{!conectivityToUpdate ? null : conectivityToUpdate.title}
							</h4>
						</div>
						<div className="ContainerVerticalStartStart doneModalCommonPadding">
							<div className="ContainerVerticalStartStart fullWidth" style={{ gap: 8 }}>
								{/* <div className='noteWrapper TextStyle2 white'>x2 Reward having 2 or more participants</div> */}
								<div className='ContainerNoHeightCenter fullWidth'>
									<span className='TextStyle4 black-text bold'>
										{conectivityAction === "REJECTED_CONECTIVITY"
											? "Would you like to skip this conectivity and have us pick a new one?"
											: conectivityAction === "COMPLETED_CONECTIVITY"
											? "Woohoo!! Way to go!"
											: null
										}
									</span>
								</div>
								<div className="ContainerNoHeightFlexLeft TextStyle2 black-text" style={{ gap: 20 }}>
									{!conectivityToUpdate.approvedPaidTime ? null : (
										<div className="ContainerNoHeightCenter doneModalRightBorderWrapper">
											<AccessTimeIcon  className="TextStyle4 dust-grey" />
											{conectivityToUpdate.approvedPaidTime + " min"}
										</div>
									)}
									{!conectivityToUpdate.conectCoins>0 ? null :  
										<div lassName="ContainerNoHeightCenter" > 
											<img className="ccImgMedium" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
											{!conectivityToUpdate ? "" : conectivityToUpdate.conectCoins} 
										</div>
									}
									{!conectivityToUpdate.badgesDEI>0 ? null : 
										<div className="ContainerNoHeightCenter">
											<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
											{!conectivityToUpdate ? "" : conectivityToUpdate.badgesDEI}
										</div>
									}
									{!conectivityToUpdate.badgesCS>0 ? null :  
										<div className="ContainerNoHeightCenter">
											<img  className="ccImgMedium" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/>
											{!conectivityToUpdate ? "" : conectivityToUpdate.badgesCS} 
										</div>
									}
								</div>
							</div>

							<div className="ContainerNoHeightSpaceBetween participateOuterWrapper fullWidth">
								<span className="TextStyle4 black-text bold">Who Participated?</span>
								<div className="ContainerNoHeightCenter participateActionBtnWrapper">
									<span className="TextStyle2 dust-grey pointer" onClick={() => {
										setParticipatingUsersDropDownSelection(participatingUsersOptions);
									}} >Select all</span>
									<span className="TextStyle2 dust-grey pointer" onClick={() => setParticipatingUsersDropDownSelection([])} >Deselect all</span>
								</div>
							</div>
							<div className="participateInnerWrapper">
								<div className='ContainerNoHeightFlexLeftWrap doneModalCommonPadding participateUserWrapperGap'>
									{participatingUsersOptions?.map((user) =>{
										let isActive = participatingUsersDropDownSelection?.some(el => el?.id === user?.id)
										return (
										<div key={`key-${user.id}`} className={`ContainerNoHeightCenter pointer participateCard ${isActive ? 'activeParticipateCard' : ''}`} onClick={() => handleSelectParticipateUser(user)}>
											<div className="avatarImage avatarImageSmall" style={{marginRight: 'unset', backgroundColor: setHeaderColorByStringLength(user.label)}}>
												{!user.avatarUrl
													? <div className="avatarInitialsSmall"> {user.avatarInitials} </div>
													: <img className="avatarImageCenterPortrait" src={user.avatarUrl} alt={""} />
												}
											</div>
											<span className="TextStyle3 dust-grey" style={{paddingLeft: "clamp(2px,0.5vw,5px)", whiteSpace:"nowrap"}}>{user.label}</span>
										</div>
									)})}
								</div>
							</div>
							<div className="ContainerVerticalStartStart participateOuterWrapper fullWidth" style={{ gap: 8 }}>
								<span className="TextStyle4 black-text bold">Rate this Connectivity</span>
								<div className="ContainerNoHeightSpaceBetween fullWidth">
									<StarRatings rating={userConectivityRating} setRating = {setUserConectivityRating} readOnly={false} className="TextStyle6 pointer" />
									<div className="ContainerNoHeightCenter participateFooterActionBtnWrapper">
										<button className="buttonStyle1 imageEditModalStyle buttonStyle1HeaderBlue buttonStyle1CommonBlueBtn" aria-label="done"  onClick={handleConfirmation} disabled={false}>
											{conectivityAction==="REJECTED_CONECTIVITY" ? "Yes" : ""} 
											{conectivityAction==="COMPLETED_CONECTIVITY" ? "Complete" : ""}
										</button>
										<button className="very-light-grey-border grey-text buttonStyle1 buttonStyle1HeaderBlue imageEditModalStyle white-background noBoxShadow" aria-label="cancel" onClick={handleCancel} style={{ borderRadius: "0.6vw" }}>
											{conectivityAction==="REJECTED_CONECTIVITY"  ? "No, Cancel" : "Not Yet..."} 
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			 </div>
	 
				
				{/* <ModalNoBackground showModal={showModal} closeCallback={handleCancel} cardColor={modalHeaderColor} closeButtonColor={COLOR_WHITE} closeButtonBackground={modalHeaderColor} >
					<div  className="modalNoBkgImage ccImgXXXXLSquare" style={{borderColor: modalHeaderColor}}> 
						<img  className="avatarImageCenterPortrait ccImagePadding" src={setConectivityImageUrlByCategory(conectivityToUpdate.category.label, true)}  alt=''/>  
					</div>
					
					<div className="ContainerNoHeightCenter" style={{backgroundColor: modalHeaderColor, marginTop:"clamp(12px,2vh,25px)"}} > 
								<h3 className="white" > {!conectivityToUpdate ? "" : conectivityToUpdate.title}</h3>
					</div>
					
					<div className="modalNoBkgInnerCard" >
						<div className="ContainerVerticalStart fullWidth" style={{padding:"clamp(8px,2vw,16px)"}}>

							<div className="TextStyle6 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}>
								{conectivityAction==="REJECTED_CONECTIVITY" ? "Would you like to skip this conectivity and have us pick a new one?" : ""}
								{conectivityAction==="COMPLETED_CONECTIVITY" ? "Woohoo!! Way to go!" : ""}
							</div>
							
							{conectivityAction === "REJECTED_CONECTIVITY" ? "" : 
							<>
								<div className="TextStyle5 headerBlue"  style={{paddingTop:"clamp(4px,1vw,10px)"}}> Who participated? </div> 
								<div className="ContainerVerticalLeft headerBlue" style={{minWidth:"clamp(150px,30vw,300px)"}}>
									<div className="TextStyle4 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}> Colleagues </div> 
									<DisplayUserSelectComponent usersOptions={participatingUsersOptions} handleSelectUser={handleParticipatingUsersSelect} isMulti={true} selectedUsersOptions={participatingUsersDropDownSelection} disabled={false} zIndex="9999" />
									<div className=" TextStyle4 headerBlue" style={{paddingTop:"clamp(8px,2vw,20px)"}}> Teams </div> 
									<DisplayTeamSelectComponent teamsOptions={teamsOptions} handleSelectTeam={setSelectedTeamsOptions} isMulti={true} selectedTeamsOptions={selectedTeamsOptions} disabled={false}  zIndex="8888"/>
								</div>
							</>
							}
						</div>

						
						<Divider percent={100} marginTop="clamp(8px,2vw,20px)"/>
						
						<div className="modalNoBkgFooter" >
								<div className="Container TextStyle4">
									{!conectivityToUpdate.conectCoins>0 ? "" :  
										<div className="Container" > 
											{!conectivityToUpdate ? "" : conectivityToUpdate.conectCoins} 
											<img className="ccImgLarge" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt=''/> 
										</div>
									}
									{!conectivityToUpdate.badgesDEI>0 ? "" :  
											<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} alt=''/> 
									}
									{!conectivityToUpdate.badgesCS>0 ? "" :  
											<img  className="ccImgLarge" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} alt=''/> 
									}
								</div>
									

								{(conectivityAction !== "COMPLETED_CONECTIVITY" || conectivityToUpdate.ratingStatsID == null) ? "" :
									<div className="ContainerVerticalCenter">
										<div className="TextStyle2"> Rate this conectivity! </div>
										<StarRatings rating={userConectivityRating} setRating = {setUserConectivityRating} readOnly={false} starSize={"28px"}/>
									</div>
								}                      
						
								<div className="ContainerNoHeightCenter">
										<button className="buttonStyle1 buttonStyle1HeaderBlue" aria-label="done"  onClick={handleConfirmation} disabled={false}>
												{conectivityAction==="REJECTED_CONECTIVITY" ? "Yes" : ""} 
												{conectivityAction==="COMPLETED_CONECTIVITY" ? "Done!" : ""}
										</button>
										<button className="buttonStyle2" aria-label="cancel" onClick={handleCancel} >
											{conectivityAction==="REJECTED_CONECTIVITY"  ? "Cancel" : "Not Yet..."} 
										</button>
								</div>
						</div>
						
					</div>

				</ModalNoBackground> */}
	
				<ModalNoBackground showModal={showSendSpotlightModal} closeCallback={handleCancel} cardColor={TEAM_COLOR} closeButtonClassName={"light-grey-background TextStyle6V2 borderRadius-xs-V2"} closeButtonColor={COLOR_BLACK} closeButtonBackground={TEAM_COLOR} hideInnerBorder={true} hideShadow={true} >
					<SpotlightSendTool backgroundColor={"black"} callbackOnComplete={spotlightSendtoolCallbackHandler} />
				</ModalNoBackground >				
			</div>
	 </Authenticator>
	
  );
};

export default UserDashboard;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved
//

import { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../data/conectereConfigData';
import React, { useEffect, useState, useContext, useRef } from "react";

//Context
import { AuthContext } from './authContext';
import { DisplayContext } from './displayContext';            //User Authentication Context

//Utils
import { fetchSpecialEvents } from "../utils/databaseUtils";
import { compareByEventStartDate, compareByEventEndDateReverseChronological, compareByEventStartDateReverseChronological } from "../utils/generalUtils";
import { NOW } from "../utils/dateTimeUtils";
import moment from 'moment';

const SpecialEventsContext = React.createContext();
const SpecialEventsProvider = ({ children }) => {

   // Authentication context
    const { authState, currentUser, } = useContext(AuthContext);
    const { currentReactPage } = useContext(DisplayContext); 

    //State variables 
    const [specialEvents, setSpecialEvents] = useState([]);
    const [enabledSpecialEvents, setEnabledSpecialEvents] = useState([]);     //Special evants that are currently active or upcoming enabled events for which we are in the announcement period
    const [activeSpecialEvents, setActiveSpecialEvents] = useState([]);       //Special evetns that for which we are in their active period

    // Get data when user authentication changes
    useEffect(() => {
        fetchData();
    },[authState]);

   async function fetchData () {
      if (authState !== "signedin" || !currentUser) return;

      // Fetch SpecialEvents
      try {       
         var tempSpecialEvents= await fetchSpecialEvents();    // grab all special events       
         tempSpecialEvents.sort(compareByEventStartDate);      //Sort the special events chronologically based on start date/time
         setSpecialEvents(tempSpecialEvents);
         if (DEBUG_MODE > 0) console.log("Fetched special events", tempSpecialEvents);
      } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching specialEvents', err); }    
   }

   /* Any time Special Events change, regen our ones for display */
   useEffect(() => {
      
      function selectEnabledSpecialEvents () {

         try {       

            //If DEV site then adjust a few of the special events so we can see them
            let tempSpecialEvents = specialEvents;
            if (currentReactPage.pathname.toUpperCase().includes("DEV.CONECTERE")) {
               tempSpecialEvents = [...specialEvents];
               if (tempSpecialEvents && tempSpecialEvents.length > 1) {
                  console.log("Debug mode detected.  Modifying a few special events to be viewable");
                  tempSpecialEvents[0].eventStatus = 'ENABLED';
                  tempSpecialEvents[0].eventStartDateTime = NOW().startOf('day');
                  tempSpecialEvents[0].eventEndDateTime = NOW().startOf('day').add(3,'days');
                  tempSpecialEvents[1].eventStatus = 'ENABLED';
                  tempSpecialEvents[1].eventStartDateTime = NOW().startOf('day').add(1,'days');
                  tempSpecialEvents[1].eventEndDateTime = NOW().startOf('day').add(2,'days');
               }
            }
      
            let activeSpecialEvents=[], upcomingSpecialEvents=[];
   
            //Determine those that are active and not in the past
            //Split into two groups so we can organize them a certain way on the top of the Home page.  Active first then upcoming events
            if (tempSpecialEvents && tempSpecialEvents.length > 0) {

               activeSpecialEvents = tempSpecialEvents.filter((event) => {
                  if (event.eventStatus !== 'ENABLED') return false;
                  if (!event.eventEndDateTime || moment(event.eventEndDateTime).isBefore(NOW(),'day')) return false;
                  if (!event.eventStartDateTime || moment(event.eventStartDateTime).isAfter(NOW(),'day')) return false;  //Only include the enabled events for which the start date has arrived
                  return true;
               });

               //Sort active events so closest end date is on the TOP
               if (activeSpecialEvents) activeSpecialEvents.sort(compareByEventEndDateReverseChronological);
               if (DEBUG_MODE > 0) console.log("Generated active special events", activeSpecialEvents);
               setActiveSpecialEvents(activeSpecialEvents);

               upcomingSpecialEvents = tempSpecialEvents.filter((event) => {
                  if (event.eventStatus !== 'ENABLED') return false;
                  if (!event.eventEndDateTime || moment(event.eventEndDateTime).isBefore(NOW(),'day')) return false; //Exclude events that have ended
                  if (!event.eventStartDateTime || !moment(event.eventStartDateTime).isAfter(NOW(),'day')) return false;  //Exclude events that have started
                  if (!event.announcementStartDateTime || moment(event.announcementStartDateTime).isAfter(NOW(),'day')) return false;  //Only include the enabled events for which the Announcement date has arrived but not the start date
                  return true;
               });
               
               //Sort active events so closest start date is on the TOP
               if (upcomingSpecialEvents) upcomingSpecialEvents.sort(compareByEventStartDateReverseChronological);
               if (DEBUG_MODE > 0) console.log("Generated upcoming special events", upcomingSpecialEvents);

            }//End If Special Events

            const specialEventsToDisplay = activeSpecialEvents.concat(upcomingSpecialEvents);

            //Sort special events based on event start date
            setEnabledSpecialEvents(specialEventsToDisplay);
            if (DEBUG_MODE > 0) console.log("Generated Enabled special events for display", specialEventsToDisplay);
         } catch (err) { if (DEBUG_MODE >= 2) console.log('error fetching specialEvents', err); }    
      }

      selectEnabledSpecialEvents();
  },[specialEvents]);

 

    //return the SpecialEvents Context provider
    return (
        <SpecialEventsContext.Provider value={
            {   
               specialEvents, setSpecialEvents, enabledSpecialEvents, activeSpecialEvents, 
            }}>
            {children}
        </SpecialEventsContext.Provider>
  );   
};


export { SpecialEventsContext, SpecialEventsProvider };
    

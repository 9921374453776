//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';

import React, { useState, useEffect} from 'react';

import Switch from '@material-ui/core/Switch';

//Utils
import { truncateIntegerByThousand } from "../../../../shared/utils/generalUtils";

//
//React component - receives an array of individual Team analytics
//

const CCdisplayCardTeamNumbers = React.memo(({teamAnalytics}) => {
    
    const[checkedMost, setCheckedMost] = useState(true);
    const[filterdTeamNumbers, setFilteredTeamNumbers] = useState([]);

    useEffect(() => {
        sortData();
        // if (DEBUG_MODE >= 2) console.log("Display Card Team Numbers", teamAnalytics);
    }, [teamAnalytics]);

    function compareTeamsMost(a, b) {
    
        if (a.relativeConectivityScore > b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
            }
        if (a.relativeConectivityScore < b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
        }


        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    function compareTeamsLeast(a, b) {
    
        if (a.relativeConectivityScore > b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.relativeConectivityScore < b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    const handleCheckedChange = (event) => {
        
     var tempTeamNumbers = [];

        //Most connected
        if (event.target.checked) {
    
            if (DEBUG_MODE >= 2) console.log("Team Numbers before sort 1:",teamAnalytics);
            const tempTeamNumbers = teamAnalytics.filter (team => team.coinsAwarded > 0); //First, drop any with zero from our initial list for MOST connected
            
            tempTeamNumbers.sort(compareTeamsMost);
            
            if (DEBUG_MODE >= 2) console.log("Team Numbers after sort 1:",tempTeamNumbers);
            setFilteredTeamNumbers (tempTeamNumbers.slice(0,5));
            
        } else {//Least Connected
            if (DEBUG_MODE >= 2) console.log("Team Numbers before sort 2:",teamAnalytics);
            const tempTeamNumbers = teamAnalytics;
            
            tempTeamNumbers.sort(compareTeamsLeast);
            
            if (DEBUG_MODE >= 2) console.log("Team Numbers after sort 2:",tempTeamNumbers);
            setFilteredTeamNumbers(tempTeamNumbers.slice(0,5));
            
        }

        setCheckedMost(!checkedMost);
        
      };


    function sortData () {
        if (teamAnalytics !== null) {
            
            // if (DEBUG_MODE >= 2) console.log("Team Numbers before sort:",teamAnalytics);
            const tempTeamNumbers = teamAnalytics.filter (team => team.coinsAwarded > 0); //First, drop any with zero from our initial list for MOST connected
            
            tempTeamNumbers.sort(compareTeamsMost);
            
            // if (DEBUG_MODE >= 2) console.log("Team Numbers after sort:",tempTeamNumbers);
            var tempFilterdTeamNumbers = tempTeamNumbers.slice(0,5);
            setFilteredTeamNumbers(tempFilterdTeamNumbers);
            
        }        
    }

    return (

        <div className="dashboardCard dashboardCardWideProfile dashboardCardFlexStartProfile" >
            <div className="ContainerNoHeightCenter" >
                <div className="TextStyle4 headerBlue heavy">{checkedMost ? "Most Connected Teams" : "Least Connected Teams"} </div>
                <div style={{ paddingLeft:"5px", }}> 
                                <Switch
                                size="small"
                                checked={checkedMost}
                                onChange={handleCheckedChange}
                                color="primary"
                                name="checkedStacked"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
              </div>

            </div>
            <div className="dashboardCardHeaderRow">
                 <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Score</div>
                 <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Team </div>
                 <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Coins</div>
                 <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>DEI Badges</div>
                 <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>CS Badges</div>
            </div >

            {filterdTeamNumbers.map((team, index) => (
                <div key={team.teamID} className="dashboardCardTextRow">
                    <div className="dashboardCardNumber" style={{flex:"1 1 0"}}>{team.relativeConectivityScore.toFixed(1)}</div>
                    <div className="dashboardCardText" style={{flex:"3 1 0"}}>{team.name} </div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(team.coinsAwarded)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /></div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(team.badgesDEIAwarded)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /></div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(team.badgesCSAwarded)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /></div>
                </div >
            ))}
            
            
       
        </div>
        
    );

});


export default CCdisplayCardTeamNumbers;



//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020-2023


import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, RADAR_CHART_WIDTH, RADAR_CHART_HEIGHT} from '../../data/conectereConfigData';

//Amplify, React
import  React from 'react';

import "./stackedBarChart.css";

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//React component
const CCStackedBarChart = ({title, data, dataKeyX, dataKeyY1, dataKeyY2, dataKeyY3, dataKeyY4, color1, color2, color3, color4, grid, height, width, label, name1, name2, name3, name4, renderTooltip, renderLegend}) => {

    
    if (label == null) label = false;
    if (!height) height = "clamp(450px, 50vh, 1200px)";
    if (!width) width = "clamp(800px, 60vw, 1400px)";

    // var widthString = parseInt(width) + "px";
    // var heightString = parseInt(height) + "px";
    
    // if (DEBUG_MODE >= 2) console.log("AreaChart called", title);
    // if (DEBUG_MODE >= 2) console.log("data", data);
    // if (DEBUG_MODE >= 2) console.log("dataKeyX", dataKeyX);
    // if (DEBUG_MODE >= 2) console.log("dataKeyY1", dataKeyY1);
    // if (DEBUG_MODE >= 2) console.log("dataKeyY2", dataKeyY2);
    // if (DEBUG_MODE >= 2) console.log("dataKeyY3", dataKeyY3);
    // if (DEBUG_MODE >= 2) console.log("grid", grid);
    // if (DEBUG_MODE >= 2) console.log("width", width);
    // if (DEBUG_MODE >= 2) console.log("height", height);
    // if (DEBUG_MODE >= 2) console.log("Name1", name1);
    
    if (!name1) name1 = dataKeyY1;
    if (!name2) name2 = dataKeyY2;
    if (!name3) name3 = dataKeyY3;
    if (!name4) name4 = dataKeyY4;

        return (
    
            <div className="stackedBarChart" >
                <div className="TextStyle4 headerBlue heavy">{title}</div>
                <div style={{width:width, height:height}}>
                    <ResponsiveContainer >
                        <BarChart data={data}  >
                            <XAxis 
                                dataKey={dataKeyX} 
                            />
                            <YAxis />
                            {dataKeyY1 && <Bar type="monotone" dataKey={dataKeyY1} stackId="1" stroke={color1} fill={color1} name={name1} />}
                            {dataKeyY2 && <Bar type="monotone" dataKey={dataKeyY2} stackId="1" stroke={color2} fill={color2} name={name2} />}
                            {dataKeyY3 && <Bar type="monotone" dataKey={dataKeyY3} stackId="1" stroke={color3} fill={color3} name={name3}/>}
                            {dataKeyY4 && <Bar type="monotone" dataKey={dataKeyY4} stackId="1" stroke={color4} fill={color4} name={name4}/>}
                            {grid && <CartesianGrid strokeDasharray="3 3" />}
                            { renderTooltip ?
                                <Tooltip content = {renderTooltip}/>
                                :
                                <Tooltip />
                            }
                            {renderLegend ?
                                <Legend 
                                    content = {renderLegend}
                                />
                            :
                                <Legend 
                                    verticalAlign="bottom" 
                                    height={14} 
                                    iconType={'circle'}
                                />
                            
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            
        );
    
  };
  
 /*
    
    return (

        <div className="stackedBarChart">
            <span className="stackedBarChartTitle">{title}</span>
            
            <ResponsiveContainer width="100%" aspect={4/1}>
                <BarChart data={data} >
                    <XAxis dataKey={dataKeyX} />
                    <YAxis />
                    {dataKeyY1 && <Bar type="monotone" dataKey={dataKeyY1} stackId="1" stroke={color1} fill={color1}  name={name2} />}
                    {dataKeyY2 && <Bar type="monotone" dataKey={dataKeyY2} stackId="1" stroke={color2} fill={color2} name={name2} />}
                    {dataKeyY3 && <Bar type="monotone" dataKey={dataKeyY3} stackId="1" stroke={color3} fill={color3} name={name3} />}
                    {dataKeyY4 && <Bar type="monotone" dataKey={dataKeyY4} stackId="1" stroke={color4} fill={color4} name={name4} />}
                    {grid && <CartesianGrid strokeDasharray="3 3" />}
                    <Tooltip />
                    <Legend />
                </BarChart>
          </ResponsiveContainer>
        </div>
        
    );
    
    */


export default CCStackedBarChart;



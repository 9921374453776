//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE } from '../../../../shared/data/conectereConfigData';

import React, { useState, useEffect} from 'react';

import Switch from '@material-ui/core/Switch';

//Utils
import { truncateIntegerByThousand } from "../../../../shared/utils/generalUtils";

//React component
const CCdisplayCardUserNumbers = React.memo(({userAnalytics}) => {
    
    const[checkedMost, setCheckedMost] = useState(true);
    const[filteredUserNumbers, setFilteredUserNumbers] = useState([]);

    useEffect(() => {
        sortData();
    }, [userAnalytics]);

    function compareUsersMost(a, b) {
    
        if (a.relativeConectivityScore > b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
            }
        if (a.relativeConectivityScore < b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
        }


        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    function compareUsersLeast(a, b) {
    
        if (a.relativeConectivityScore > b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return -1" , a , b)   
            return 1;
            }
        if (a.relativeConectivityScore < b.relativeConectivityScore) {
            // if (DEBUG_MODE >= 2) console.log("compare called, return 1" , a , b)   
            return -1;
        }
        
        // if (DEBUG_MODE >= 2) console.log("compare called, return 0" , a , b)   
        return 0;
    
    }

    const handleCheckedChange = (event) => {
    
        //Most connected
        if (event.target.checked) {
    
            // if (DEBUG_MODE >= 2) console.log("User Numbers before sort 1:",userAnalytics);
            const tempUserNumbers = userAnalytics.filter (user => user.coinsAwarded > 0); //First, drop any with zero from our initial list for MOST connected
            
            tempUserNumbers.sort(compareUsersMost);
            
            // if (DEBUG_MODE >= 2) console.log("User Numbers after sort 1:",tempUserNumbers);
            setFilteredUserNumbers (tempUserNumbers.slice(0,5));
            
        } else {
            //Least Connected
            
            // if (DEBUG_MODE >= 2) console.log("User Numbers before sort 2:",userAnalytics);
            const tempUserNumbers = userAnalytics;
            
            tempUserNumbers.sort(compareUsersLeast);
            
            // if (DEBUG_MODE >= 2) console.log("User Numbers after sort 2:",tempUserNumbers);
            setFilteredUserNumbers(tempUserNumbers.slice(0,5));
            
        }

        setCheckedMost(!checkedMost);
        
      };


    function sortData () {
        if (userAnalytics !== null){
            
            // if (DEBUG_MODE >= 2) console.log("User Numbers before sort:",userAnalytics);
            const tempUserNumbers = userAnalytics.filter (user => user.coinsAwarded > 0); //First, drop any with zero from our initial list for MOST connected
            
            tempUserNumbers.sort(compareUsersMost);
            
            // if (DEBUG_MODE >= 2) console.log("User Numbers after sort:",tempUserNumbers);
            var tempFilterdUserNumbers = tempUserNumbers.slice(0,5);
            setFilteredUserNumbers(tempFilterdUserNumbers);
            
        }        
    }

    // if (DEBUG_MODE >= 2) console.log("User Numbers Card called", userAnalytics);
    
            
    
    return (

        <div className="dashboardCard dashboardCardFlexStartProfile">
            <div className="ContainerNoHeightCenter" >
                  <div className="TextStyle4 headerBlue heavy">{checkedMost ? "Most Connected Employees" : "Least Connected Employees"} </div>
                  <div style={{ paddingLeft:"5px", }}> 
                        <Switch
                        size="small"
                        checked={checkedMost}
                        onChange={handleCheckedChange}
                        color="primary"
                        name="checkedStacked"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                  </div>
            </div>
            
            <div className="dashboardCardHeaderRow">
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Score</div>
                <div className="dashboardCardTextSmall" style={{flex:"3 1 0", textAlign:"center"}}>Name </div>
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>Coins awarded</div>
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>DEI Badges</div>
                <div className="dashboardCardTextSmall" style={{flex:"1 1 0", textAlign:"center"}}>CS Badges</div>
            </div >


            {filteredUserNumbers.map((user, index) => (
                <div key={user.userID} className="dashboardCardTextRow">
                    <div key={user.userID} className="dashboardCardNumber" style={{flex:"1 1 0"}}>{user.relativeConectivityScore.toFixed(1)}</div>
                    <div className="dashboardCardText" style={{flex:"3 1 0"}}>{user.name} </div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(user.coinsAwarded)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} /></div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(user.badgesDEIAwarded)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.DEI_BADGE_IMAGE_PATH} /></div>
                    <div className="dashboardCardAward" style={{flex:"1 1 0"}}>{truncateIntegerByThousand(user.badgesCSAwarded)}<img className="ccImgSmall" src={CONECTERE_CONFIG_DATA.CS_BADGE_IMAGE_PATH} /></div>
                </div >
            ))}
       
        </div>
        
    );

});


export default CCdisplayCardUserNumbers;



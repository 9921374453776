//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

import  { CONECTERE_CONFIG_DATA } from './conectereConfigData';

//
// HELP TEXT FOR CONECTERE 


export const HELP_TEXT_ADMIN_SPOTLIGHTS_1 = `When enabled, only an administator will be able to award Spotlights, 
which eliminates the peer-to-peer aspect but which provide more control over Spotlight awards`;
export const HELP_TEXT_ADMIN_SPOTLIGHTS_2 =`When enabled, all employees will be bcc'd on Spotlight recognition emails`;

export const HELP_TEXT_ADMIN_CALENDAR_1 = `Welcome to your Conectivities Calendar!  With this calendar, you can easily view and manage 
your schedule of conectivities with your colleagues and teammates. As administrator, you can also view the schedule of conectivities for specific employees or teams within your enterprise.  
Click on the search icon to access a control panel for controling the calendar view.  You can select users or teams to review,
and toggle filters for easily customizing your view.  Click the Add button to add a conectivity to your company calendar.`;

export const HELP_TEXT_NON_ADMIN_CALENDAR_1 = `Welcome to your Conectivities Calendar!  With this calendar, you can easily view and manage 
your conectivities with your colleagues and teammates.  Click on the search icon to access a mini control panel to easily set filters for tailoring your calendar view.  
Click the Add button to add a conectivity to your company calendar.`;

export const HELP_TEXT_ADMIN_MENTOR_1 = `
   <div className="ContainerNoHeightSpaceBetween fullWidth wrap" >
      <h4 className="blue">Customize and Automate your Employee Growth and Development Programs</h4>
      <div style={{flex:"1"}}> 
            <p className="black">
               Successful employee growth and development programs, such as mentorshp and sponsorship programs, are key to the sustained success and growth of any organization.  In addition to developing leaders and managers,
               the many benefits include transfering of institutional knowledge, boosting retention, and improving onboarding of new hires.
            </p>

            <p className="black">Use this dashboard to: 
               <ul className="black">
                  <li>customize your employee growth programs to reflect your company's culture</li>
                  <li>assigning mentor/mentees & sponsor/sponsorees</li>
                  <li>automate the scheduling of recurring mentorship & sponsorship conectivities that will be placed directly on their calendars</li>
                  <li>launch program-wide conectivities, such as special events or training sessions, for your defined employee assignments</li> 
               </ul>
            </p>
      </div>    
      <div className="imageContainer" style={{flex:"1"}}><img  className="ccImgXXXXXL" src={GROWTH_RING} alt=''/> </div>
   </div>
`;

export const HELP_TEXT_ADMIN_NEW_EMPLOYEE_SUPPORT_1 = `Welcome to your new employee support design center.  Use this page to customize the experience you want for your new employees during your onboarding process.  From this screen, 
you can easily automate the scheduling of conectivities for your new employees.  Automatically connect them with informally with team members, colleagues and mentors.  The onboarding process is critical for acclimating a new employee
to his or her new work environment, and helping them integrate into your company culture.  Let them know your are committed to an effective, positive onboarding experience for all new employees`;

export const HELP_TEXT_ADMIN_SLACK_BUTTON = `Link your Conectere account to Slack 👋!  Our App for Slack allows you to connect with team members and colleagues, view conectivities, chat with team members and even cast Spotlights 
to recognize and celebrate the achievements of your colleagues, all from team's Slack workspace!`


// SPOTLIGHTS
export const HELP_TEXT_SPOTLIGHT_AWARDS_1 = `All recognized individuals receive a Spotlight badge and coins for your company store.  In addition, you can award a service badge to highlight efforts related to community service
and a DEI badge for contributions related to diversity, equity and inclusion.`

export const HELP_TEXT_SPOTLIGHT_1 = `Spotlights make it easy to recognize and celebrate the success and contributions of your colleagues.
Informal, spontaneous peer-to-peer recognition is a powerful mechanism in creating a culture of gratitude and appreciation.  Plus, it's fun!!  So, cast 
Spotlights, earn rewards 💰 and close your Team achievement ring!!`;

// CUSTOMER SETTINGS //

// COIN VALUATION
export const HELP_TEXT_SET_COIN_VALUE = `Fine tune your expenses by setting the value of coins when redeemed in your employee store.  By default, the value of a coin is ${CONECTERE_CONFIG_DATA.DOLLAR_TO_COIN_CONVERSION} cents.
Employees earn a fixed amount of coins for each conectivity period based on the settings above.  Increasing the dollar value of the coins enables employees to buy more rewards
in your employee store but can increase your overall spend.  Lowering the dollar value of coins makes it take longer for employees to earn rewards and will reduce your overall costs.`;

export const HELP_TEXT_RING_CLOSURE_OVERRIDE = `Check this box to allow employees to earn coins for conectivities even though they have already achieved your company goal in
that category and have closed the corresponding ring.  By default, this override is disabled so that the maximum coins employees can earn is capped according to your conectivity goals
set out above.  By checking this box, you are disabling the cap, which has the benefit of further incentivizing colleagues to connect with conectivities but will also enable employees to buy more rewards
in your employee store and increase your overall spend.  Regardless of this setting, employees will always be able to earn coins for special Conectere events, such as Bring Your Child To Work Day,
even if they have already achieved their goal and closed their ring for that type of conectivity.`;

export const HELP_TEXT_RING_CLOSURE_FIRM_CAP = `Check this box to apply a firm cap on the amount of coins employees earn when completing the final conectivity to close a ring.  By default, employees earn
the full amount of coins allocated to the conectivity they completed to close their rings.  By checking this box, you are applying a firm cap so that employees will not be awarded more than the number of coins required to close their rings.  
As a result, when this box is checked, an employee may only receive partial credit of coins for the last conectivity they complete to close their ring.  Ring closure bonuses are exception to this firm cap and will still be awarded when
an employee closes his or her ring regardless of this setting.`;

export const HELP_TEXT_SPOTLIGHTS_AFTER_RING_CLOSURE = `Check this box to cap an employee's ability to earn coins for Spotlight recognitions once they have closed their Team ring for the given period. If unchecked, an 
employee may receive coins for being recognized with a Spotlight even if they have already closed their Team ring by completing team-related conectivities or receiving previous Spotlights.`

export const HELP_TEXT_SPOTLIGHT_SEND_CAP = `Check this box to limit the number of Spotlights an employee can send in a specific period.  Once checked, you can select the number and the period, such as 3 DAILY or 5 WEEKLY.`
export const HELP_TEXT_SERVICE_PLANS = `You can change your service plan at any time.  Take a quick look at our service plans and confirm you have selected the one you want for your team.  Changes will be reflected on your next month's bill.`

//Challenges
export const HELP_TEXT_CHALLENGE_EDITOR_1 = `Check this box if the conectivity involve a friendly challenge between participants, 
      like a <b>Get Your Steps!</b> challenge or an <b>Office Yoga Challenge</b> or a community-service event or a wellness challenge. The options are limitless!
      If so, use these settings to specify defaults You can craft nearly any type of conectivity as a fun challenge for your teams and colleagues! `

export const HELP_TEXT_CHALLENGE_SETUP = `
   <div className="ContainerVertical fullWidth wrap" >
      <h4 className="blue"> 🏃‍♀️🏃‍♂️🏃 Who's ready for a challenge?? 🏃🏃‍♀️🏃‍♂️ </h4>
      <div style={{flex:"1"}}> 
            <p className="black-text">
               Whether team-building activities like 'Get Your Steps!' or mentorship initiatives or community-service events, you can launch
               any conectivity as a fun challenge for your teams and colleagues!
            </p>

            <p className="black-text">
               When launched as a challenge, your conectivity will include an integrated leaderboard for all participants.  Each particpant will be able
               to update their progress throughout the challenge.  You can even set up rewards, which will automatically be distributed at the end of the challenge. 🏆🏆🏆
            </p>

            <p className="black-text">Tips: 
               <ul className="black-text">
                  <li>Include at least one colleague or team in this calendar invitation to enable the integrated challenge options</li>
                  <li>Want to launch the conectivity as a one-on-one challenge with a colleague?  Simply add him or her to the invite.</li>
                  <li>Want to challenge your team members?  Simply invite the entire team.</li>
                  <li>Ready to take on another team?  Include both teams on the invitation and select the <b>"compete as a team option"</b></li>
                  <li>Be sure to set the conectivity <b>start</b> and <b>end</b> dates to span the entire challenge period</li>
               </ul>
            </p>
      </div>    
      <div className="imageContainer" style={{flex:"1"}}><img  className="ccImgXXXXXL" src={GROWTH_RING} alt=''/> </div>
   </div>
`

export const HELP_TEXT_CHALLENGE_SETUP_2 = `
   <div className="ContainerNoHeightSpaceBetween fullWidth wrap" >
      <div className="TextStyle4 blue">How would you like user progress ranked on your leaderboard throughout the challenge?</div>
      <div className="TextStyle3" style={{flex:"1"}}> 
            <p className="black">
               <ul className="black">
                  <li>Cumulative (sum) of the entries throughout the challenge (e.g., steps, miles, minutes)</li><br>
                  <li>% Increase from 1st entry to the end of the challenge (e.g., % increase in weights lifted)</li><br>
                  <li>% Decrease from 1st entry to the end of the challenge (e.g., % weight or body fat lost)</li><br>
                  <li>Total Increase from 1st entry to the end of the challenge (e.g., number of push ups or sit ups increased)</li><br>
                  <li>Total Decrease from 1st entry to the end of the challenge (e.g., amount of weight or body fat lost)</li>
               </ul>
            </p>
      </div>    
      <div className="imageContainer" style={{flex:"1"}}><img  className="ccImgXXXXXL" src={GROWTH_RING} alt=''/> </div>
   </div>
`
export const HELP_TEXT_CHALLENGE_SETUP_3 = `Would you like to set a goal for the participants to achieve before the end of the challenge?  For example, you could set a goal of 3,000 steps walked, 20 bags of trash collected, 100 flowers delivered or anything, really.  
You can even specify rewards for participants that achieve the goal.`

export const HELP_TEXT_CHALLENGE_SETUP_4 = `Would you like the participants to compete as teams on the leaderboard (e.g. a team vs. team competition or even a single team with a goal) instead of individually?`

export const HELP_TEXT_CHALLENGE_SETUP_5 = `
   <div className="ContainerVertical fullWidth wrap" >
      <h4 className="blue"> 🏃‍♀️🏃‍♂️🏃 Who's ready for a challenge?? 🏃🏃‍♀️🏃‍♂️ </h4>
      <div style={{flex:"1"}}> 
            <p className="black-text">
               Whether team-building activities like 'Get Your Steps!' or mentorship initiatives or community-service events, you can launch
               any conectivity as a fun challenge for your teams and colleagues!
            </p>

            <p className="black-text">
               When launched as a challenge, your conectivity will include an integrated leaderboard for all participants.  Each particpant will be able
               to update their progress throughout the challenge.  You can even set up rewards, which will automatically be distributed at the end of the challenge. 🏆🏆🏆
            </p>

            <p className="black-text">Tips: 
               <ul className="black-text">
                  <li>Include at least one colleague or team in this calendar invitation to enable the integrated challenge options</li>
                  <li>Want to launch the conectivity as a one-on-one challenge with a colleague?  Simply add him or her to the invite.</li>
                  <li>Want to challenge your team members?  Simply invite the entire team.</li>
                  <li>Ready to take on another team?  Include both teams on the invitation and select the <b>"compete as a team option"</b></li>
                  <li>Be sure to set the conectivity <b>start</b> and <b>end</b> dates to span the entire challenge period</li>
               </ul>
            </p>
      </div>    
      <div className="imageContainer" style={{flex:"1"}}><img  className="ccImgXXXXXL" src={GROWTH_RING} alt=''/> </div>
   </div>
`

export const HELP_TEXT_CHALLENGE_SETUP_6 = `Specify rewards you would like participants to receive.  Rewards will automatically be distributed at the end fo the challenge.`

export const HELP_TEXT_CHALLENGE_ADMIN_PAGE = `🏆🏆🏆 Welcome to the company challenge control center.  Use this page to enable the ability to launch conectivities as friendly challenges and to customize the experience for your teams.`

export const HELP_TEXT_CHALLENGE_ADMIN_PAGE_2 = `Allow administrators to launch conectivitis as a challenge.  Conectivities launched as a challenge automatically include an integrated leaderboard for all participants.  Each particpant will be able to update their progress throughout the challenge.`

export const HELP_TEXT_CHALLENGE_ADMIN_PAGE_3 = `Allow ALL employees to launch conectivities as challenges.  When enabled, any conectivity can then be launched as a fun challenge!! Conectivities launched as a challenge automatically include an integrated leaderboard for all participants.  Each particpant will be able to update their progress throughout the challenge.`;

export const HELP_TEXT_CHALLENGE_ADMIN_PAGE_4 = `Would you like to allow employees to set up rewards for the challenges they launch?`;

export const HELP_TEXT_CHALLENGE_ADMIN_PAGE_5 = `Allow employees to specify rewards up to the caps you specify here.`;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateCustomerByCustomer = /* GraphQL */ `
  subscription OnUpdateCustomerByCustomer($customerID: ID) {
    onUpdateCustomerByCustomer(customerID: $customerID) {
      id
      name
      nickname
      logo
      virtualStore
      logoWidth
      logoHeight
      useExternalStore
      website
      activeEmployees
      fullName
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      configDetailsID
      customerBillingID
      createdAt
      updatedAt
      configDetails {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomerByCustomer = /* GraphQL */ `
  subscription OnDeleteCustomerByCustomer($customerID: ID) {
    onDeleteCustomerByCustomer(customerID: $customerID) {
      id
      name
      nickname
      logo
      virtualStore
      logoWidth
      logoHeight
      useExternalStore
      website
      activeEmployees
      fullName
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      configDetailsID
      customerBillingID
      createdAt
      updatedAt
      configDetails {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateConfigDataByCustomer = /* GraphQL */ `
  subscription OnUpdateConfigDataByCustomer($customerID: ID!) {
    onUpdateConfigDataByCustomer(customerID: $customerID) {
      id
      customerID
      master
      useBadges
      ringClosureFirmCap
      ringClosureOverride
      conectivityMinToCoinConversionOverride
      dollarToCoinConversion
      socialConectivitiesToCloseRing
      socialMinActiveConectivities
      socialCoinsToCloseRing
      socialPeriodToCloseRing
      socialRewardForClosingRing
      stressConectivitiesToCloseRing
      stressMinActiveConectivities
      stressCoinsToCloseRing
      stressPeriodToCloseRing
      stressRewardForClosingRing
      teamConectivitiesToCloseRing
      teamMinActiveConectivities
      teamCoinsToCloseRing
      teamPeriodToCloseRing
      teamRewardForClosingRing
      individualConectivitiesToCloseRing
      individualMinActiveConectivities
      individualCoinsToCloseRing
      individualPeriodToCloseRing
      individualRewardForClosingRing
      leaderBoardRewardDailyWin
      leaderBoardRewardWeeklyWin
      leaderBoardRewardMonthlyWin
      leaderBoardRewardQuarterlyWin
      leaderBoardRewardYearlyWin
      spotlightsEnabled
      spotlightsAdminSendOnly
      spotlightAmountSender
      spotlightAmountReceiver
      spotlightsFullBccEnabled
      spotlightAwardCappedAtTeamRing
      spotlightCastCapEnabled
      spotlightCastCapLimit
      spotlightCastCapPeriod
      newEmpConectivitySchedulingEnabled
      newEmployeeOnboardingConectivityID
      newEmployeeWelcomeConectivityID
      newEmployeeOneOnOneSupervisorConectivityID
      newEmployeeOneOnOneMentorConectivityID
      newEmployeeOneOnOneSponsorConectivityID
      newEmployeeLunchConectivityID
      newEmployeeOnboardingConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeWelcomeConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSupervisorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneMentorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSponsorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeLunchConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeConectivitiesToSchedule
      mentorProgramEnabled
      sponsorProgramEnabled
      mentorLaunchEventStartDateTime
      mentorLaunchRepeatOption
      mentorLaunchRepeatWeeklyOptions
      mentorLaunchRepeatMonthlyOptions
      mentorLaunchRepeatMonthlyOptions_2
      mentorLaunchRepeatMonthlyOptions_3
      mentorLaunchMonthOption1Checked
      mentorLaunchMonthOption2Checked
      sponsorLaunchEventStartDateTime
      sponsorLaunchRepeatOption
      sponsorLaunchRepeatWeeklyOptions
      sponsorLaunchRepeatMonthlyOptions
      sponsorLaunchRepeatMonthlyOptions_2
      sponsorLaunchRepeatMonthlyOptions_3
      sponsorLaunchMonthOption1Checked
      sponsorLaunchMonthOption2Checked
      slackEnabled
      slackSpotlightChannelID
      slackSpotlightChannelName
      slackChannelCreationEnabled
      slackOptions
      msTeamsEnabled
      msTeamsSpotlightChannelID
      msTeamsSpotlightWebhook
      msTeamsChannelCreationEnabled
      msTeamsSpotlightsInstallRecordID
      msTeamsOptions
      enableAdminChallenges
      enableEmployeeChallenges
      enableEmployeeChallengeRewards
      challengeCoinCap
      challengeBadgesDEICap
      challengeBadgesCSCap
      challengeRecognitionsCap
      disableAIChallengeRecognitionMessages
      enableEmployeeConnectionBuilder
      employeeConnectionBuilderOptions
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserByCustomer = /* GraphQL */ `
  subscription OnUpdateUserByCustomer($customerID: ID) {
    onUpdateUserByCustomer(customerID: $customerID) {
      id
      firstName
      middleName
      lastName
      email
      title
      pronouns
      bio
      userCognitoID
      userNameCognito
      avatarWidth
      avatarHeight
      avatarUrl
      customerID
      userProgressDataID
      ccWalletID
      mentorID
      sponsorID
      supervisorID
      canBeMentor
      canBeSponsor
      canBeSupervisor
      isUnderrepresentedGroup
      isAdmin
      isSuperAdmin
      permissionLaunch
      permissionAnalytics
      permissionEditor
      permissionOrders
      permissionBilling
      onNumberOfTeams
      tutorialLevel
      googleCalendarEnabled
      msteamsCalendarEnabled
      msoutlookCalendarEnabled
      ms365CalendarEnabled
      icloudCalendarEnabled
      slackEnabled
      timeZoneCode
      startDate
      displayTheme
      options
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teams {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      ccWallet {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      userProgressData {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUserByCustomer = /* GraphQL */ `
  subscription OnDeleteUserByCustomer($customerID: ID) {
    onDeleteUserByCustomer(customerID: $customerID) {
      id
      firstName
      middleName
      lastName
      email
      title
      pronouns
      bio
      userCognitoID
      userNameCognito
      avatarWidth
      avatarHeight
      avatarUrl
      customerID
      userProgressDataID
      ccWalletID
      mentorID
      sponsorID
      supervisorID
      canBeMentor
      canBeSponsor
      canBeSupervisor
      isUnderrepresentedGroup
      isAdmin
      isSuperAdmin
      permissionLaunch
      permissionAnalytics
      permissionEditor
      permissionOrders
      permissionBilling
      onNumberOfTeams
      tutorialLevel
      googleCalendarEnabled
      msteamsCalendarEnabled
      msoutlookCalendarEnabled
      ms365CalendarEnabled
      icloudCalendarEnabled
      slackEnabled
      timeZoneCode
      startDate
      displayTheme
      options
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teams {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      ccWallet {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      userProgressData {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTeamByCustomer = /* GraphQL */ `
  subscription OnUpdateTeamByCustomer($customerID: ID) {
    onUpdateTeamByCustomer(customerID: $customerID) {
      id
      name
      nickname
      department
      avatarUrl
      avatarInitials
      customerID
      memberCount
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      users {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTeamByCustomer = /* GraphQL */ `
  subscription OnDeleteTeamByCustomer($customerID: ID) {
    onDeleteTeamByCustomer(customerID: $customerID) {
      id
      name
      nickname
      department
      avatarUrl
      avatarInitials
      customerID
      memberCount
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      users {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateScheduledConectivityByCustomer = /* GraphQL */ `
  subscription OnUpdateScheduledConectivityByCustomer($customerID: ID!) {
    onUpdateScheduledConectivityByCustomer(customerID: $customerID) {
      id
      creationDateTime
      closingDateTime
      runningState
      customerID
      conectivityID
      launchRuleID
      options
      createdAt
      updatedAt
      conectivity {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      invitations {
        items {
          id
          customerID
          senderID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          messagesReadAt
          forLaunchedConectivity
          usersInvitedToParticipate
          teamsInvitedToParticipate
          scheduledConectivityID
          launchRuleID
          launchRuleInstanceIndex
          launchRuleInstanceStartDateTime
          launchRuleInstanceEndDateTime
          allDayEvent
          leaderboardID
          createdAt
          updatedAt
          launchRule {
            id
            customerID
            conectivityID
            usersToInvite
            teamsToInvite
            senderID
            message
            messageGIF
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            eventStartDateTime
            eventEndDateTime
            allDayEvent
            launchUntilDate
            launchUntilCount
            launchRepeatOption
            launchRepeatWeeklyOptions
            launchRepeatMonthlyOptions
            launchRepeatMonthlyOptions_2
            launchRepeatMonthlyOptions_3
            launchMonthOption1Checked
            launchMonthOption2Checked
            calendarUid
            calendarEventID
            parentRecurringEventId
            eventInstanceNumber
            originalLaunchStartDate
            nextInstanceNumber
            isMentorMeeting
            isSponsorMeeting
            messagesReadAtRecords {
              messagesReadAt
              userID
              __typename
            }
            rsvpRecords {
              userID
              rsvpStatus
              launchRuleID
              launchRuleInstanceIndex
              __typename
            }
            challengeID
            options
            createdAt
            updatedAt
            __typename
          }
          invitees {
            items {
              id
              inviteeID
              inviteeAvatarUrl
              inviteeAvatarName
              inviteeAvatarInitials
              invitationID
              messagesReadAt
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          messages {
            items {
              id
              customerID
              senderID
              message
              invitationID
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              parentMessage
              userLikes
              origin
              isSystemMessage
              mediaUrl
              launchRuleID
              recognitionID
              slackMessageID
              slackParentMessageID
              slackChannel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      designatedFor {
        items {
          id
          name
          label
          userID
          teamID
          scheduledConectivityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteScheduledConectivityByCustomer = /* GraphQL */ `
  subscription OnDeleteScheduledConectivityByCustomer($customerID: ID!) {
    onDeleteScheduledConectivityByCustomer(customerID: $customerID) {
      id
      creationDateTime
      closingDateTime
      runningState
      customerID
      conectivityID
      launchRuleID
      options
      createdAt
      updatedAt
      conectivity {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      invitations {
        items {
          id
          customerID
          senderID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          messagesReadAt
          forLaunchedConectivity
          usersInvitedToParticipate
          teamsInvitedToParticipate
          scheduledConectivityID
          launchRuleID
          launchRuleInstanceIndex
          launchRuleInstanceStartDateTime
          launchRuleInstanceEndDateTime
          allDayEvent
          leaderboardID
          createdAt
          updatedAt
          launchRule {
            id
            customerID
            conectivityID
            usersToInvite
            teamsToInvite
            senderID
            message
            messageGIF
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            eventStartDateTime
            eventEndDateTime
            allDayEvent
            launchUntilDate
            launchUntilCount
            launchRepeatOption
            launchRepeatWeeklyOptions
            launchRepeatMonthlyOptions
            launchRepeatMonthlyOptions_2
            launchRepeatMonthlyOptions_3
            launchMonthOption1Checked
            launchMonthOption2Checked
            calendarUid
            calendarEventID
            parentRecurringEventId
            eventInstanceNumber
            originalLaunchStartDate
            nextInstanceNumber
            isMentorMeeting
            isSponsorMeeting
            messagesReadAtRecords {
              messagesReadAt
              userID
              __typename
            }
            rsvpRecords {
              userID
              rsvpStatus
              launchRuleID
              launchRuleInstanceIndex
              __typename
            }
            challengeID
            options
            createdAt
            updatedAt
            __typename
          }
          invitees {
            items {
              id
              inviteeID
              inviteeAvatarUrl
              inviteeAvatarName
              inviteeAvatarInitials
              invitationID
              messagesReadAt
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          messages {
            items {
              id
              customerID
              senderID
              message
              invitationID
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              parentMessage
              userLikes
              origin
              isSystemMessage
              mediaUrl
              launchRuleID
              recognitionID
              slackMessageID
              slackParentMessageID
              slackChannel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      designatedFor {
        items {
          id
          name
          label
          userID
          teamID
          scheduledConectivityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCCWalletByUser = /* GraphQL */ `
  subscription OnUpdateCCWalletByUser($userID: ID!) {
    onUpdateCCWalletByUser(userID: $userID) {
      id
      dateCreated
      description
      currentBalance
      badgesDEI
      badgesCS
      badgesSpotlight
      status
      nextSequenceNumber
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUserProgressDataByUser = /* GraphQL */ `
  subscription OnUpdateUserProgressDataByUser($userID: ID!) {
    onUpdateUserProgressDataByUser(userID: $userID) {
      id
      userID
      customerID
      subscriptionTrigger
      socialPeriodCoinsEarned
      socialPeriodStart
      socialPeriodEnd
      socialPeriod
      socialPeriodCoinGoal
      socialPeriodConectivitiesComplete
      socialPeriodBonusGiven
      stressPeriodCoinsEarned
      stressPeriodStart
      stressPeriodEnd
      stressPeriod
      stressPeriodCoinGoal
      stressPeriodConectivitiesComplete
      stressPeriodBonusGiven
      teamPeriodCoinsEarned
      teamPeriodStart
      teamPeriodEnd
      teamPeriod
      teamPeriodCoinGoal
      teamPeriodConectivitiesComplete
      teamPeriodBonusGiven
      individualPeriodCoinsEarned
      individualPeriodStart
      individualPeriodEnd
      individualPeriod
      individualPeriodCoinGoal
      individualPeriodConectivitiesComplete
      individualPeriodBonusGiven
      spotlightPeriodNumCast
      spotlightPeriodStart
      spotlightPeriodEnd
      spotlightPeriod
      dayCoinsEarned
      dayClosingDateTime
      dayConectivitiesComplete
      weekCoinsEarned
      weekClosingDateTime
      weekConectivitiesComplete
      monthCoinsEarned
      monthClosingDateTime
      monthConectivitiesComplete
      quarterCoinsEarned
      quarterClosingDateTime
      quarterConectivitiesComplete
      yearCoinsEarned
      yearClosingDateTime
      yearConectivitiesComplete
      balanceStreakCount
      balanceStreakStartDate
      balanceStreakLastClosureDate
      socialStreakCount
      socialStreakStartDate
      socialStreakLastClosureDate
      teamStreakCount
      teamStreakStartDate
      teamStreakLastClosureDate
      growthStreakCount
      growthStreakStartDate
      growthStreakLastClosureDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInvitationDataByCustomer = /* GraphQL */ `
  subscription OnUpdateInvitationDataByCustomer($customerID: ID!) {
    onUpdateInvitationDataByCustomer(customerID: $customerID) {
      id
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      messagesReadAt
      forLaunchedConectivity
      usersInvitedToParticipate
      teamsInvitedToParticipate
      scheduledConectivityID
      launchRuleID
      launchRuleInstanceIndex
      launchRuleInstanceStartDateTime
      launchRuleInstanceEndDateTime
      allDayEvent
      leaderboardID
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      invitees {
        items {
          id
          inviteeID
          inviteeAvatarUrl
          inviteeAvatarName
          inviteeAvatarInitials
          invitationID
          messagesReadAt
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvitationDataByCustomer = /* GraphQL */ `
  subscription OnDeleteInvitationDataByCustomer($customerID: ID!) {
    onDeleteInvitationDataByCustomer(customerID: $customerID) {
      id
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      messagesReadAt
      forLaunchedConectivity
      usersInvitedToParticipate
      teamsInvitedToParticipate
      scheduledConectivityID
      launchRuleID
      launchRuleInstanceIndex
      launchRuleInstanceStartDateTime
      launchRuleInstanceEndDateTime
      allDayEvent
      leaderboardID
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      invitees {
        items {
          id
          inviteeID
          inviteeAvatarUrl
          inviteeAvatarName
          inviteeAvatarInitials
          invitationID
          messagesReadAt
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNotificationForUser = /* GraphQL */ `
  subscription OnCreateNotificationForUser($userID: ID!) {
    onCreateNotificationForUser(userID: $userID) {
      id
      expiration
      readByUser
      pushNotificationSent
      title
      text
      textFormatted
      image
      imageID
      notificationType
      scope
      invitationID
      spotlightID
      messageID
      commentID
      scheduledConectivityID
      recognitionID
      urlRedirect
      notificationTypeID
      customerID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotificationForUser = /* GraphQL */ `
  subscription OnUpdateNotificationForUser($userID: ID!) {
    onUpdateNotificationForUser(userID: $userID) {
      id
      expiration
      readByUser
      pushNotificationSent
      title
      text
      textFormatted
      image
      imageID
      notificationType
      scope
      invitationID
      spotlightID
      messageID
      commentID
      scheduledConectivityID
      recognitionID
      urlRedirect
      notificationTypeID
      customerID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecognitionForCustomer = /* GraphQL */ `
  subscription OnCreateRecognitionForCustomer($customerID: ID!) {
    onCreateRecognitionForCustomer(customerID: $customerID) {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRecognitionForCustomer = /* GraphQL */ `
  subscription OnUpdateRecognitionForCustomer($customerID: ID!) {
    onUpdateRecognitionForCustomer(customerID: $customerID) {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRecognitionByCustomer = /* GraphQL */ `
  subscription OnDeleteRecognitionByCustomer($customerID: ID!) {
    onDeleteRecognitionByCustomer(customerID: $customerID) {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLaunchRuleForCustomer = /* GraphQL */ `
  subscription OnCreateLaunchRuleForCustomer($customerID: ID!) {
    onCreateLaunchRuleForCustomer(customerID: $customerID) {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLaunchRuleForCustomer = /* GraphQL */ `
  subscription OnUpdateLaunchRuleForCustomer($customerID: ID!) {
    onUpdateLaunchRuleForCustomer(customerID: $customerID) {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLaunchRuleByCustomer = /* GraphQL */ `
  subscription OnDeleteLaunchRuleByCustomer($customerID: ID!) {
    onDeleteLaunchRuleByCustomer(customerID: $customerID) {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConectivityByScope = /* GraphQL */ `
  subscription OnCreateConectivityByScope(
    $conectivityScope: String!
    $publicationStatus: String
  ) {
    onCreateConectivityByScope(
      conectivityScope: $conectivityScope
      publicationStatus: $publicationStatus
    ) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateConectivityByScope = /* GraphQL */ `
  subscription OnUpdateConectivityByScope(
    $conectivityScope: String!
    $publicationStatus: String
  ) {
    onUpdateConectivityByScope(
      conectivityScope: $conectivityScope
      publicationStatus: $publicationStatus
    ) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteConectivityByScope = /* GraphQL */ `
  subscription OnDeleteConectivityByScope(
    $conectivityScope: String!
    $publicationStatus: String
  ) {
    onDeleteConectivityByScope(
      conectivityScope: $conectivityScope
      publicationStatus: $publicationStatus
    ) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateConectivityByCompany = /* GraphQL */ `
  subscription OnCreateConectivityByCompany(
    $createdByCustomerID: ID!
    $conectivityScope: String
    $publicationStatus: String
  ) {
    onCreateConectivityByCompany(
      createdByCustomerID: $createdByCustomerID
      conectivityScope: $conectivityScope
      publicationStatus: $publicationStatus
    ) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateConectivityByCompany = /* GraphQL */ `
  subscription OnUpdateConectivityByCompany(
    $createdByCustomerID: ID!
    $conectivityScope: String
    $publicationStatus: String
  ) {
    onUpdateConectivityByCompany(
      createdByCustomerID: $createdByCustomerID
      conectivityScope: $conectivityScope
      publicationStatus: $publicationStatus
    ) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteConectivityByCompany = /* GraphQL */ `
  subscription OnDeleteConectivityByCompany(
    $createdByCustomerID: ID!
    $conectivityScope: String
    $publicationStatus: String
  ) {
    onDeleteConectivityByCompany(
      createdByCustomerID: $createdByCustomerID
      conectivityScope: $conectivityScope
      publicationStatus: $publicationStatus
    ) {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateProductByCustomer = /* GraphQL */ `
  subscription OnCreateProductByCustomer($customerID: ID) {
    onCreateProductByCustomer(customerID: $customerID) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProductByCustomer = /* GraphQL */ `
  subscription OnUpdateProductByCustomer($customerID: ID) {
    onUpdateProductByCustomer(customerID: $customerID) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProductByCustomer = /* GraphQL */ `
  subscription OnDeleteProductByCustomer($customerID: ID) {
    onDeleteProductByCustomer(customerID: $customerID) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProductByScope = /* GraphQL */ `
  subscription OnCreateProductByScope($productScope: String!, $customerID: ID) {
    onCreateProductByScope(
      productScope: $productScope
      customerID: $customerID
    ) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProductByScope = /* GraphQL */ `
  subscription OnUpdateProductByScope($productScope: String!, $customerID: ID) {
    onUpdateProductByScope(
      productScope: $productScope
      customerID: $customerID
    ) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProductByScope = /* GraphQL */ `
  subscription OnDeleteProductByScope($productScope: String!, $customerID: ID) {
    onDeleteProductByScope(
      productScope: $productScope
      customerID: $customerID
    ) {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrderByCustomer = /* GraphQL */ `
  subscription OnCreateOrderByCustomer($customerID: ID) {
    onCreateOrderByCustomer(customerID: $customerID) {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrderByCustomer = /* GraphQL */ `
  subscription OnUpdateOrderByCustomer($customerID: ID) {
    onUpdateOrderByCustomer(customerID: $customerID) {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrderByCustomer = /* GraphQL */ `
  subscription OnDeleteOrderByCustomer($customerID: ID) {
    onDeleteOrderByCustomer(customerID: $customerID) {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoiceByCustomer = /* GraphQL */ `
  subscription OnCreateInvoiceByCustomer($customerID: ID) {
    onCreateInvoiceByCustomer(customerID: $customerID) {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInvoiceByCustomer = /* GraphQL */ `
  subscription OnUpdateInvoiceByCustomer($customerID: ID) {
    onUpdateInvoiceByCustomer(customerID: $customerID) {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvoiceByCustomer = /* GraphQL */ `
  subscription OnDeleteInvoiceByCustomer($customerID: ID) {
    onDeleteInvoiceByCustomer(customerID: $customerID) {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessagePacketByUser = /* GraphQL */ `
  subscription OnCreateMessagePacketByUser($userID: ID) {
    onCreateMessagePacketByUser(userID: $userID) {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessagePacketByUser = /* GraphQL */ `
  subscription OnUpdateMessagePacketByUser($userID: ID) {
    onUpdateMessagePacketByUser(userID: $userID) {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessagePacketByUser = /* GraphQL */ `
  subscription OnDeleteMessagePacketByUser($userID: ID) {
    onDeleteMessagePacketByUser(userID: $userID) {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCustomerLeaderBoardByCustomer = /* GraphQL */ `
  subscription OnCreateCustomerLeaderBoardByCustomer($customerID: ID!) {
    onCreateCustomerLeaderBoardByCustomer(customerID: $customerID) {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomerLeaderBoardByCustomer = /* GraphQL */ `
  subscription OnUpdateCustomerLeaderBoardByCustomer($customerID: ID!) {
    onUpdateCustomerLeaderBoardByCustomer(customerID: $customerID) {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomerLeaderBoardByCustomer = /* GraphQL */ `
  subscription OnDeleteCustomerLeaderBoardByCustomer($customerID: ID!) {
    onDeleteCustomerLeaderBoardByCustomer(customerID: $customerID) {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLeaderBoardSpotByCustomer = /* GraphQL */ `
  subscription OnCreateLeaderBoardSpotByCustomer($customerID: ID!) {
    onCreateLeaderBoardSpotByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLeaderBoardSpotByCustomer = /* GraphQL */ `
  subscription OnUpdateLeaderBoardSpotByCustomer($customerID: ID!) {
    onUpdateLeaderBoardSpotByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLeaderBoardSpotByCustomer = /* GraphQL */ `
  subscription OnDeleteLeaderBoardSpotByCustomer($customerID: ID!) {
    onDeleteLeaderBoardSpotByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChallengeByCustomer = /* GraphQL */ `
  subscription OnCreateChallengeByCustomer($customerID: ID) {
    onCreateChallengeByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateChallengeByCustomer = /* GraphQL */ `
  subscription OnUpdateChallengeByCustomer($customerID: ID) {
    onUpdateChallengeByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteChallengeByCustomer = /* GraphQL */ `
  subscription OnDeleteChallengeByCustomer($customerID: ID) {
    onDeleteChallengeByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChallengeDataByCustomer = /* GraphQL */ `
  subscription OnCreateChallengeDataByCustomer($customerID: ID) {
    onCreateChallengeDataByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChallengeDataByCustomer = /* GraphQL */ `
  subscription OnUpdateChallengeDataByCustomer($customerID: ID) {
    onUpdateChallengeDataByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChallengeDataByCustomer = /* GraphQL */ `
  subscription OnDeleteChallengeDataByCustomer($customerID: ID) {
    onDeleteChallengeDataByCustomer(customerID: $customerID) {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConectereConfig = /* GraphQL */ `
  subscription OnCreateConectereConfig {
    onCreateConectereConfig {
      id
      customerID
      master
      useBadges
      ringClosureFirmCap
      ringClosureOverride
      conectivityMinToCoinConversionOverride
      dollarToCoinConversion
      socialConectivitiesToCloseRing
      socialMinActiveConectivities
      socialCoinsToCloseRing
      socialPeriodToCloseRing
      socialRewardForClosingRing
      stressConectivitiesToCloseRing
      stressMinActiveConectivities
      stressCoinsToCloseRing
      stressPeriodToCloseRing
      stressRewardForClosingRing
      teamConectivitiesToCloseRing
      teamMinActiveConectivities
      teamCoinsToCloseRing
      teamPeriodToCloseRing
      teamRewardForClosingRing
      individualConectivitiesToCloseRing
      individualMinActiveConectivities
      individualCoinsToCloseRing
      individualPeriodToCloseRing
      individualRewardForClosingRing
      leaderBoardRewardDailyWin
      leaderBoardRewardWeeklyWin
      leaderBoardRewardMonthlyWin
      leaderBoardRewardQuarterlyWin
      leaderBoardRewardYearlyWin
      spotlightsEnabled
      spotlightsAdminSendOnly
      spotlightAmountSender
      spotlightAmountReceiver
      spotlightsFullBccEnabled
      spotlightAwardCappedAtTeamRing
      spotlightCastCapEnabled
      spotlightCastCapLimit
      spotlightCastCapPeriod
      newEmpConectivitySchedulingEnabled
      newEmployeeOnboardingConectivityID
      newEmployeeWelcomeConectivityID
      newEmployeeOneOnOneSupervisorConectivityID
      newEmployeeOneOnOneMentorConectivityID
      newEmployeeOneOnOneSponsorConectivityID
      newEmployeeLunchConectivityID
      newEmployeeOnboardingConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeWelcomeConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSupervisorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneMentorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSponsorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeLunchConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeConectivitiesToSchedule
      mentorProgramEnabled
      sponsorProgramEnabled
      mentorLaunchEventStartDateTime
      mentorLaunchRepeatOption
      mentorLaunchRepeatWeeklyOptions
      mentorLaunchRepeatMonthlyOptions
      mentorLaunchRepeatMonthlyOptions_2
      mentorLaunchRepeatMonthlyOptions_3
      mentorLaunchMonthOption1Checked
      mentorLaunchMonthOption2Checked
      sponsorLaunchEventStartDateTime
      sponsorLaunchRepeatOption
      sponsorLaunchRepeatWeeklyOptions
      sponsorLaunchRepeatMonthlyOptions
      sponsorLaunchRepeatMonthlyOptions_2
      sponsorLaunchRepeatMonthlyOptions_3
      sponsorLaunchMonthOption1Checked
      sponsorLaunchMonthOption2Checked
      slackEnabled
      slackSpotlightChannelID
      slackSpotlightChannelName
      slackChannelCreationEnabled
      slackOptions
      msTeamsEnabled
      msTeamsSpotlightChannelID
      msTeamsSpotlightWebhook
      msTeamsChannelCreationEnabled
      msTeamsSpotlightsInstallRecordID
      msTeamsOptions
      enableAdminChallenges
      enableEmployeeChallenges
      enableEmployeeChallengeRewards
      challengeCoinCap
      challengeBadgesDEICap
      challengeBadgesCSCap
      challengeRecognitionsCap
      disableAIChallengeRecognitionMessages
      enableEmployeeConnectionBuilder
      employeeConnectionBuilderOptions
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateConectereConfig = /* GraphQL */ `
  subscription OnUpdateConectereConfig {
    onUpdateConectereConfig {
      id
      customerID
      master
      useBadges
      ringClosureFirmCap
      ringClosureOverride
      conectivityMinToCoinConversionOverride
      dollarToCoinConversion
      socialConectivitiesToCloseRing
      socialMinActiveConectivities
      socialCoinsToCloseRing
      socialPeriodToCloseRing
      socialRewardForClosingRing
      stressConectivitiesToCloseRing
      stressMinActiveConectivities
      stressCoinsToCloseRing
      stressPeriodToCloseRing
      stressRewardForClosingRing
      teamConectivitiesToCloseRing
      teamMinActiveConectivities
      teamCoinsToCloseRing
      teamPeriodToCloseRing
      teamRewardForClosingRing
      individualConectivitiesToCloseRing
      individualMinActiveConectivities
      individualCoinsToCloseRing
      individualPeriodToCloseRing
      individualRewardForClosingRing
      leaderBoardRewardDailyWin
      leaderBoardRewardWeeklyWin
      leaderBoardRewardMonthlyWin
      leaderBoardRewardQuarterlyWin
      leaderBoardRewardYearlyWin
      spotlightsEnabled
      spotlightsAdminSendOnly
      spotlightAmountSender
      spotlightAmountReceiver
      spotlightsFullBccEnabled
      spotlightAwardCappedAtTeamRing
      spotlightCastCapEnabled
      spotlightCastCapLimit
      spotlightCastCapPeriod
      newEmpConectivitySchedulingEnabled
      newEmployeeOnboardingConectivityID
      newEmployeeWelcomeConectivityID
      newEmployeeOneOnOneSupervisorConectivityID
      newEmployeeOneOnOneMentorConectivityID
      newEmployeeOneOnOneSponsorConectivityID
      newEmployeeLunchConectivityID
      newEmployeeOnboardingConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeWelcomeConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSupervisorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneMentorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSponsorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeLunchConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeConectivitiesToSchedule
      mentorProgramEnabled
      sponsorProgramEnabled
      mentorLaunchEventStartDateTime
      mentorLaunchRepeatOption
      mentorLaunchRepeatWeeklyOptions
      mentorLaunchRepeatMonthlyOptions
      mentorLaunchRepeatMonthlyOptions_2
      mentorLaunchRepeatMonthlyOptions_3
      mentorLaunchMonthOption1Checked
      mentorLaunchMonthOption2Checked
      sponsorLaunchEventStartDateTime
      sponsorLaunchRepeatOption
      sponsorLaunchRepeatWeeklyOptions
      sponsorLaunchRepeatMonthlyOptions
      sponsorLaunchRepeatMonthlyOptions_2
      sponsorLaunchRepeatMonthlyOptions_3
      sponsorLaunchMonthOption1Checked
      sponsorLaunchMonthOption2Checked
      slackEnabled
      slackSpotlightChannelID
      slackSpotlightChannelName
      slackChannelCreationEnabled
      slackOptions
      msTeamsEnabled
      msTeamsSpotlightChannelID
      msTeamsSpotlightWebhook
      msTeamsChannelCreationEnabled
      msTeamsSpotlightsInstallRecordID
      msTeamsOptions
      enableAdminChallenges
      enableEmployeeChallenges
      enableEmployeeChallengeRewards
      challengeCoinCap
      challengeBadgesDEICap
      challengeBadgesCSCap
      challengeRecognitionsCap
      disableAIChallengeRecognitionMessages
      enableEmployeeConnectionBuilder
      employeeConnectionBuilderOptions
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteConectereConfig = /* GraphQL */ `
  subscription OnDeleteConectereConfig {
    onDeleteConectereConfig {
      id
      customerID
      master
      useBadges
      ringClosureFirmCap
      ringClosureOverride
      conectivityMinToCoinConversionOverride
      dollarToCoinConversion
      socialConectivitiesToCloseRing
      socialMinActiveConectivities
      socialCoinsToCloseRing
      socialPeriodToCloseRing
      socialRewardForClosingRing
      stressConectivitiesToCloseRing
      stressMinActiveConectivities
      stressCoinsToCloseRing
      stressPeriodToCloseRing
      stressRewardForClosingRing
      teamConectivitiesToCloseRing
      teamMinActiveConectivities
      teamCoinsToCloseRing
      teamPeriodToCloseRing
      teamRewardForClosingRing
      individualConectivitiesToCloseRing
      individualMinActiveConectivities
      individualCoinsToCloseRing
      individualPeriodToCloseRing
      individualRewardForClosingRing
      leaderBoardRewardDailyWin
      leaderBoardRewardWeeklyWin
      leaderBoardRewardMonthlyWin
      leaderBoardRewardQuarterlyWin
      leaderBoardRewardYearlyWin
      spotlightsEnabled
      spotlightsAdminSendOnly
      spotlightAmountSender
      spotlightAmountReceiver
      spotlightsFullBccEnabled
      spotlightAwardCappedAtTeamRing
      spotlightCastCapEnabled
      spotlightCastCapLimit
      spotlightCastCapPeriod
      newEmpConectivitySchedulingEnabled
      newEmployeeOnboardingConectivityID
      newEmployeeWelcomeConectivityID
      newEmployeeOneOnOneSupervisorConectivityID
      newEmployeeOneOnOneMentorConectivityID
      newEmployeeOneOnOneSponsorConectivityID
      newEmployeeLunchConectivityID
      newEmployeeOnboardingConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeWelcomeConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSupervisorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneMentorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeOneOnOneSponsorConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeLunchConectivityStartTime {
        startHour
        startMin
        __typename
      }
      newEmployeeConectivitiesToSchedule
      mentorProgramEnabled
      sponsorProgramEnabled
      mentorLaunchEventStartDateTime
      mentorLaunchRepeatOption
      mentorLaunchRepeatWeeklyOptions
      mentorLaunchRepeatMonthlyOptions
      mentorLaunchRepeatMonthlyOptions_2
      mentorLaunchRepeatMonthlyOptions_3
      mentorLaunchMonthOption1Checked
      mentorLaunchMonthOption2Checked
      sponsorLaunchEventStartDateTime
      sponsorLaunchRepeatOption
      sponsorLaunchRepeatWeeklyOptions
      sponsorLaunchRepeatMonthlyOptions
      sponsorLaunchRepeatMonthlyOptions_2
      sponsorLaunchRepeatMonthlyOptions_3
      sponsorLaunchMonthOption1Checked
      sponsorLaunchMonthOption2Checked
      slackEnabled
      slackSpotlightChannelID
      slackSpotlightChannelName
      slackChannelCreationEnabled
      slackOptions
      msTeamsEnabled
      msTeamsSpotlightChannelID
      msTeamsSpotlightWebhook
      msTeamsChannelCreationEnabled
      msTeamsSpotlightsInstallRecordID
      msTeamsOptions
      enableAdminChallenges
      enableEmployeeChallenges
      enableEmployeeChallengeRewards
      challengeCoinCap
      challengeBadgesDEICap
      challengeBadgesCSCap
      challengeRecognitionsCap
      disableAIChallengeRecognitionMessages
      enableEmployeeConnectionBuilder
      employeeConnectionBuilderOptions
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      name
      nickname
      logo
      virtualStore
      logoWidth
      logoHeight
      useExternalStore
      website
      activeEmployees
      fullName
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      configDetailsID
      customerBillingID
      createdAt
      updatedAt
      configDetails {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      name
      nickname
      logo
      virtualStore
      logoWidth
      logoHeight
      useExternalStore
      website
      activeEmployees
      fullName
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      configDetailsID
      customerBillingID
      createdAt
      updatedAt
      configDetails {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      name
      nickname
      logo
      virtualStore
      logoWidth
      logoHeight
      useExternalStore
      website
      activeEmployees
      fullName
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      configDetailsID
      customerBillingID
      createdAt
      updatedAt
      configDetails {
        id
        customerID
        master
        useBadges
        ringClosureFirmCap
        ringClosureOverride
        conectivityMinToCoinConversionOverride
        dollarToCoinConversion
        socialConectivitiesToCloseRing
        socialMinActiveConectivities
        socialCoinsToCloseRing
        socialPeriodToCloseRing
        socialRewardForClosingRing
        stressConectivitiesToCloseRing
        stressMinActiveConectivities
        stressCoinsToCloseRing
        stressPeriodToCloseRing
        stressRewardForClosingRing
        teamConectivitiesToCloseRing
        teamMinActiveConectivities
        teamCoinsToCloseRing
        teamPeriodToCloseRing
        teamRewardForClosingRing
        individualConectivitiesToCloseRing
        individualMinActiveConectivities
        individualCoinsToCloseRing
        individualPeriodToCloseRing
        individualRewardForClosingRing
        leaderBoardRewardDailyWin
        leaderBoardRewardWeeklyWin
        leaderBoardRewardMonthlyWin
        leaderBoardRewardQuarterlyWin
        leaderBoardRewardYearlyWin
        spotlightsEnabled
        spotlightsAdminSendOnly
        spotlightAmountSender
        spotlightAmountReceiver
        spotlightsFullBccEnabled
        spotlightAwardCappedAtTeamRing
        spotlightCastCapEnabled
        spotlightCastCapLimit
        spotlightCastCapPeriod
        newEmpConectivitySchedulingEnabled
        newEmployeeOnboardingConectivityID
        newEmployeeWelcomeConectivityID
        newEmployeeOneOnOneSupervisorConectivityID
        newEmployeeOneOnOneMentorConectivityID
        newEmployeeOneOnOneSponsorConectivityID
        newEmployeeLunchConectivityID
        newEmployeeOnboardingConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeWelcomeConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSupervisorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneMentorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeOneOnOneSponsorConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeLunchConectivityStartTime {
          startHour
          startMin
          __typename
        }
        newEmployeeConectivitiesToSchedule
        mentorProgramEnabled
        sponsorProgramEnabled
        mentorLaunchEventStartDateTime
        mentorLaunchRepeatOption
        mentorLaunchRepeatWeeklyOptions
        mentorLaunchRepeatMonthlyOptions
        mentorLaunchRepeatMonthlyOptions_2
        mentorLaunchRepeatMonthlyOptions_3
        mentorLaunchMonthOption1Checked
        mentorLaunchMonthOption2Checked
        sponsorLaunchEventStartDateTime
        sponsorLaunchRepeatOption
        sponsorLaunchRepeatWeeklyOptions
        sponsorLaunchRepeatMonthlyOptions
        sponsorLaunchRepeatMonthlyOptions_2
        sponsorLaunchRepeatMonthlyOptions_3
        sponsorLaunchMonthOption1Checked
        sponsorLaunchMonthOption2Checked
        slackEnabled
        slackSpotlightChannelID
        slackSpotlightChannelName
        slackChannelCreationEnabled
        slackOptions
        msTeamsEnabled
        msTeamsSpotlightChannelID
        msTeamsSpotlightWebhook
        msTeamsChannelCreationEnabled
        msTeamsSpotlightsInstallRecordID
        msTeamsOptions
        enableAdminChallenges
        enableEmployeeChallenges
        enableEmployeeChallengeRewards
        challengeCoinCap
        challengeBadgesDEICap
        challengeBadgesCSCap
        challengeRecognitionsCap
        disableAIChallengeRecognitionMessages
        enableEmployeeConnectionBuilder
        employeeConnectionBuilderOptions
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCustomerBilling = /* GraphQL */ `
  subscription OnCreateCustomerBilling {
    onCreateCustomerBilling {
      id
      customerID
      creditCardNumber
      creditCardHolder
      creditCardYear
      creditCardMonth
      creditCardExpiry
      creditCardIssuer
      creditCardCVC
      billingZipCode
      nextInvoiceNumber
      nextPaymentTransactionNumber
      subscriptionStartDate
      subscriptionEndDate
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomerBilling = /* GraphQL */ `
  subscription OnUpdateCustomerBilling {
    onUpdateCustomerBilling {
      id
      customerID
      creditCardNumber
      creditCardHolder
      creditCardYear
      creditCardMonth
      creditCardExpiry
      creditCardIssuer
      creditCardCVC
      billingZipCode
      nextInvoiceNumber
      nextPaymentTransactionNumber
      subscriptionStartDate
      subscriptionEndDate
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomerBilling = /* GraphQL */ `
  subscription OnDeleteCustomerBilling {
    onDeleteCustomerBilling {
      id
      customerID
      creditCardNumber
      creditCardHolder
      creditCardYear
      creditCardMonth
      creditCardExpiry
      creditCardIssuer
      creditCardCVC
      billingZipCode
      nextInvoiceNumber
      nextPaymentTransactionNumber
      subscriptionStartDate
      subscriptionEndDate
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      firstName
      middleName
      lastName
      email
      title
      pronouns
      bio
      userCognitoID
      userNameCognito
      avatarWidth
      avatarHeight
      avatarUrl
      customerID
      userProgressDataID
      ccWalletID
      mentorID
      sponsorID
      supervisorID
      canBeMentor
      canBeSponsor
      canBeSupervisor
      isUnderrepresentedGroup
      isAdmin
      isSuperAdmin
      permissionLaunch
      permissionAnalytics
      permissionEditor
      permissionOrders
      permissionBilling
      onNumberOfTeams
      tutorialLevel
      googleCalendarEnabled
      msteamsCalendarEnabled
      msoutlookCalendarEnabled
      ms365CalendarEnabled
      icloudCalendarEnabled
      slackEnabled
      timeZoneCode
      startDate
      displayTheme
      options
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teams {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      ccWallet {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      userProgressData {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      firstName
      middleName
      lastName
      email
      title
      pronouns
      bio
      userCognitoID
      userNameCognito
      avatarWidth
      avatarHeight
      avatarUrl
      customerID
      userProgressDataID
      ccWalletID
      mentorID
      sponsorID
      supervisorID
      canBeMentor
      canBeSponsor
      canBeSupervisor
      isUnderrepresentedGroup
      isAdmin
      isSuperAdmin
      permissionLaunch
      permissionAnalytics
      permissionEditor
      permissionOrders
      permissionBilling
      onNumberOfTeams
      tutorialLevel
      googleCalendarEnabled
      msteamsCalendarEnabled
      msoutlookCalendarEnabled
      ms365CalendarEnabled
      icloudCalendarEnabled
      slackEnabled
      timeZoneCode
      startDate
      displayTheme
      options
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teams {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      ccWallet {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      userProgressData {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      firstName
      middleName
      lastName
      email
      title
      pronouns
      bio
      userCognitoID
      userNameCognito
      avatarWidth
      avatarHeight
      avatarUrl
      customerID
      userProgressDataID
      ccWalletID
      mentorID
      sponsorID
      supervisorID
      canBeMentor
      canBeSponsor
      canBeSupervisor
      isUnderrepresentedGroup
      isAdmin
      isSuperAdmin
      permissionLaunch
      permissionAnalytics
      permissionEditor
      permissionOrders
      permissionBilling
      onNumberOfTeams
      tutorialLevel
      googleCalendarEnabled
      msteamsCalendarEnabled
      msoutlookCalendarEnabled
      ms365CalendarEnabled
      icloudCalendarEnabled
      slackEnabled
      timeZoneCode
      startDate
      displayTheme
      options
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teams {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      ccWallet {
        id
        dateCreated
        description
        currentBalance
        badgesDEI
        badgesCS
        badgesSpotlight
        status
        nextSequenceNumber
        userID
        createdAt
        updatedAt
        user {
          id
          firstName
          middleName
          lastName
          email
          title
          pronouns
          bio
          userCognitoID
          userNameCognito
          avatarWidth
          avatarHeight
          avatarUrl
          customerID
          userProgressDataID
          ccWalletID
          mentorID
          sponsorID
          supervisorID
          canBeMentor
          canBeSponsor
          canBeSupervisor
          isUnderrepresentedGroup
          isAdmin
          isSuperAdmin
          permissionLaunch
          permissionAnalytics
          permissionEditor
          permissionOrders
          permissionBilling
          onNumberOfTeams
          tutorialLevel
          googleCalendarEnabled
          msteamsCalendarEnabled
          msoutlookCalendarEnabled
          ms365CalendarEnabled
          icloudCalendarEnabled
          slackEnabled
          timeZoneCode
          startDate
          displayTheme
          options
          createdAt
          updatedAt
          customer {
            id
            name
            nickname
            logo
            virtualStore
            logoWidth
            logoHeight
            useExternalStore
            website
            activeEmployees
            fullName
            address1
            address2
            city
            state
            postalCode
            country
            phoneNumber
            email
            configDetailsID
            customerBillingID
            createdAt
            updatedAt
            configDetails {
              id
              customerID
              master
              useBadges
              ringClosureFirmCap
              ringClosureOverride
              conectivityMinToCoinConversionOverride
              dollarToCoinConversion
              socialConectivitiesToCloseRing
              socialMinActiveConectivities
              socialCoinsToCloseRing
              socialPeriodToCloseRing
              socialRewardForClosingRing
              stressConectivitiesToCloseRing
              stressMinActiveConectivities
              stressCoinsToCloseRing
              stressPeriodToCloseRing
              stressRewardForClosingRing
              teamConectivitiesToCloseRing
              teamMinActiveConectivities
              teamCoinsToCloseRing
              teamPeriodToCloseRing
              teamRewardForClosingRing
              individualConectivitiesToCloseRing
              individualMinActiveConectivities
              individualCoinsToCloseRing
              individualPeriodToCloseRing
              individualRewardForClosingRing
              leaderBoardRewardDailyWin
              leaderBoardRewardWeeklyWin
              leaderBoardRewardMonthlyWin
              leaderBoardRewardQuarterlyWin
              leaderBoardRewardYearlyWin
              spotlightsEnabled
              spotlightsAdminSendOnly
              spotlightAmountSender
              spotlightAmountReceiver
              spotlightsFullBccEnabled
              spotlightAwardCappedAtTeamRing
              spotlightCastCapEnabled
              spotlightCastCapLimit
              spotlightCastCapPeriod
              newEmpConectivitySchedulingEnabled
              newEmployeeOnboardingConectivityID
              newEmployeeWelcomeConectivityID
              newEmployeeOneOnOneSupervisorConectivityID
              newEmployeeOneOnOneMentorConectivityID
              newEmployeeOneOnOneSponsorConectivityID
              newEmployeeLunchConectivityID
              newEmployeeOnboardingConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeWelcomeConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSupervisorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneMentorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeOneOnOneSponsorConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeLunchConectivityStartTime {
                startHour
                startMin
                __typename
              }
              newEmployeeConectivitiesToSchedule
              mentorProgramEnabled
              sponsorProgramEnabled
              mentorLaunchEventStartDateTime
              mentorLaunchRepeatOption
              mentorLaunchRepeatWeeklyOptions
              mentorLaunchRepeatMonthlyOptions
              mentorLaunchRepeatMonthlyOptions_2
              mentorLaunchRepeatMonthlyOptions_3
              mentorLaunchMonthOption1Checked
              mentorLaunchMonthOption2Checked
              sponsorLaunchEventStartDateTime
              sponsorLaunchRepeatOption
              sponsorLaunchRepeatWeeklyOptions
              sponsorLaunchRepeatMonthlyOptions
              sponsorLaunchRepeatMonthlyOptions_2
              sponsorLaunchRepeatMonthlyOptions_3
              sponsorLaunchMonthOption1Checked
              sponsorLaunchMonthOption2Checked
              slackEnabled
              slackSpotlightChannelID
              slackSpotlightChannelName
              slackChannelCreationEnabled
              slackOptions
              msTeamsEnabled
              msTeamsSpotlightChannelID
              msTeamsSpotlightWebhook
              msTeamsChannelCreationEnabled
              msTeamsSpotlightsInstallRecordID
              msTeamsOptions
              enableAdminChallenges
              enableEmployeeChallenges
              enableEmployeeChallengeRewards
              challengeCoinCap
              challengeBadgesDEICap
              challengeBadgesCSCap
              challengeRecognitionsCap
              disableAIChallengeRecognitionMessages
              enableEmployeeConnectionBuilder
              employeeConnectionBuilderOptions
              options
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          teams {
            items {
              id
              customerID
              teamID
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              team {
                id
                name
                nickname
                department
                avatarUrl
                avatarInitials
                customerID
                memberCount
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          ccWallet {
            id
            dateCreated
            description
            currentBalance
            badgesDEI
            badgesCS
            badgesSpotlight
            status
            nextSequenceNumber
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          userProgressData {
            id
            userID
            customerID
            subscriptionTrigger
            socialPeriodCoinsEarned
            socialPeriodStart
            socialPeriodEnd
            socialPeriod
            socialPeriodCoinGoal
            socialPeriodConectivitiesComplete
            socialPeriodBonusGiven
            stressPeriodCoinsEarned
            stressPeriodStart
            stressPeriodEnd
            stressPeriod
            stressPeriodCoinGoal
            stressPeriodConectivitiesComplete
            stressPeriodBonusGiven
            teamPeriodCoinsEarned
            teamPeriodStart
            teamPeriodEnd
            teamPeriod
            teamPeriodCoinGoal
            teamPeriodConectivitiesComplete
            teamPeriodBonusGiven
            individualPeriodCoinsEarned
            individualPeriodStart
            individualPeriodEnd
            individualPeriod
            individualPeriodCoinGoal
            individualPeriodConectivitiesComplete
            individualPeriodBonusGiven
            spotlightPeriodNumCast
            spotlightPeriodStart
            spotlightPeriodEnd
            spotlightPeriod
            dayCoinsEarned
            dayClosingDateTime
            dayConectivitiesComplete
            weekCoinsEarned
            weekClosingDateTime
            weekConectivitiesComplete
            monthCoinsEarned
            monthClosingDateTime
            monthConectivitiesComplete
            quarterCoinsEarned
            quarterClosingDateTime
            quarterConectivitiesComplete
            yearCoinsEarned
            yearClosingDateTime
            yearConectivitiesComplete
            balanceStreakCount
            balanceStreakStartDate
            balanceStreakLastClosureDate
            socialStreakCount
            socialStreakStartDate
            socialStreakLastClosureDate
            teamStreakCount
            teamStreakStartDate
            teamStreakLastClosureDate
            growthStreakCount
            growthStreakStartDate
            growthStreakLastClosureDate
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      userProgressData {
        id
        userID
        customerID
        subscriptionTrigger
        socialPeriodCoinsEarned
        socialPeriodStart
        socialPeriodEnd
        socialPeriod
        socialPeriodCoinGoal
        socialPeriodConectivitiesComplete
        socialPeriodBonusGiven
        stressPeriodCoinsEarned
        stressPeriodStart
        stressPeriodEnd
        stressPeriod
        stressPeriodCoinGoal
        stressPeriodConectivitiesComplete
        stressPeriodBonusGiven
        teamPeriodCoinsEarned
        teamPeriodStart
        teamPeriodEnd
        teamPeriod
        teamPeriodCoinGoal
        teamPeriodConectivitiesComplete
        teamPeriodBonusGiven
        individualPeriodCoinsEarned
        individualPeriodStart
        individualPeriodEnd
        individualPeriod
        individualPeriodCoinGoal
        individualPeriodConectivitiesComplete
        individualPeriodBonusGiven
        spotlightPeriodNumCast
        spotlightPeriodStart
        spotlightPeriodEnd
        spotlightPeriod
        dayCoinsEarned
        dayClosingDateTime
        dayConectivitiesComplete
        weekCoinsEarned
        weekClosingDateTime
        weekConectivitiesComplete
        monthCoinsEarned
        monthClosingDateTime
        monthConectivitiesComplete
        quarterCoinsEarned
        quarterClosingDateTime
        quarterConectivitiesComplete
        yearCoinsEarned
        yearClosingDateTime
        yearConectivitiesComplete
        balanceStreakCount
        balanceStreakStartDate
        balanceStreakLastClosureDate
        socialStreakCount
        socialStreakStartDate
        socialStreakLastClosureDate
        teamStreakCount
        teamStreakStartDate
        teamStreakLastClosureDate
        growthStreakCount
        growthStreakStartDate
        growthStreakLastClosureDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      name
      nickname
      department
      avatarUrl
      avatarInitials
      customerID
      memberCount
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      users {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      name
      nickname
      department
      avatarUrl
      avatarInitials
      customerID
      memberCount
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      users {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      name
      nickname
      department
      avatarUrl
      avatarInitials
      customerID
      memberCount
      createdAt
      updatedAt
      customer {
        id
        name
        nickname
        logo
        virtualStore
        logoWidth
        logoHeight
        useExternalStore
        website
        activeEmployees
        fullName
        address1
        address2
        city
        state
        postalCode
        country
        phoneNumber
        email
        configDetailsID
        customerBillingID
        createdAt
        updatedAt
        configDetails {
          id
          customerID
          master
          useBadges
          ringClosureFirmCap
          ringClosureOverride
          conectivityMinToCoinConversionOverride
          dollarToCoinConversion
          socialConectivitiesToCloseRing
          socialMinActiveConectivities
          socialCoinsToCloseRing
          socialPeriodToCloseRing
          socialRewardForClosingRing
          stressConectivitiesToCloseRing
          stressMinActiveConectivities
          stressCoinsToCloseRing
          stressPeriodToCloseRing
          stressRewardForClosingRing
          teamConectivitiesToCloseRing
          teamMinActiveConectivities
          teamCoinsToCloseRing
          teamPeriodToCloseRing
          teamRewardForClosingRing
          individualConectivitiesToCloseRing
          individualMinActiveConectivities
          individualCoinsToCloseRing
          individualPeriodToCloseRing
          individualRewardForClosingRing
          leaderBoardRewardDailyWin
          leaderBoardRewardWeeklyWin
          leaderBoardRewardMonthlyWin
          leaderBoardRewardQuarterlyWin
          leaderBoardRewardYearlyWin
          spotlightsEnabled
          spotlightsAdminSendOnly
          spotlightAmountSender
          spotlightAmountReceiver
          spotlightsFullBccEnabled
          spotlightAwardCappedAtTeamRing
          spotlightCastCapEnabled
          spotlightCastCapLimit
          spotlightCastCapPeriod
          newEmpConectivitySchedulingEnabled
          newEmployeeOnboardingConectivityID
          newEmployeeWelcomeConectivityID
          newEmployeeOneOnOneSupervisorConectivityID
          newEmployeeOneOnOneMentorConectivityID
          newEmployeeOneOnOneSponsorConectivityID
          newEmployeeLunchConectivityID
          newEmployeeOnboardingConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeWelcomeConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSupervisorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneMentorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeOneOnOneSponsorConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeLunchConectivityStartTime {
            startHour
            startMin
            __typename
          }
          newEmployeeConectivitiesToSchedule
          mentorProgramEnabled
          sponsorProgramEnabled
          mentorLaunchEventStartDateTime
          mentorLaunchRepeatOption
          mentorLaunchRepeatWeeklyOptions
          mentorLaunchRepeatMonthlyOptions
          mentorLaunchRepeatMonthlyOptions_2
          mentorLaunchRepeatMonthlyOptions_3
          mentorLaunchMonthOption1Checked
          mentorLaunchMonthOption2Checked
          sponsorLaunchEventStartDateTime
          sponsorLaunchRepeatOption
          sponsorLaunchRepeatWeeklyOptions
          sponsorLaunchRepeatMonthlyOptions
          sponsorLaunchRepeatMonthlyOptions_2
          sponsorLaunchRepeatMonthlyOptions_3
          sponsorLaunchMonthOption1Checked
          sponsorLaunchMonthOption2Checked
          slackEnabled
          slackSpotlightChannelID
          slackSpotlightChannelName
          slackChannelCreationEnabled
          slackOptions
          msTeamsEnabled
          msTeamsSpotlightChannelID
          msTeamsSpotlightWebhook
          msTeamsChannelCreationEnabled
          msTeamsSpotlightsInstallRecordID
          msTeamsOptions
          enableAdminChallenges
          enableEmployeeChallenges
          enableEmployeeChallengeRewards
          challengeCoinCap
          challengeBadgesDEICap
          challengeBadgesCSCap
          challengeRecognitionsCap
          disableAIChallengeRecognitionMessages
          enableEmployeeConnectionBuilder
          employeeConnectionBuilderOptions
          options
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      users {
        items {
          id
          customerID
          teamID
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          team {
            id
            name
            nickname
            department
            avatarUrl
            avatarInitials
            customerID
            memberCount
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            users {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTeamUserJoin = /* GraphQL */ `
  subscription OnCreateTeamUserJoin {
    onCreateTeamUserJoin {
      id
      customerID
      teamID
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      team {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTeamUserJoin = /* GraphQL */ `
  subscription OnUpdateTeamUserJoin {
    onUpdateTeamUserJoin {
      id
      customerID
      teamID
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      team {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTeamUserJoin = /* GraphQL */ `
  subscription OnDeleteTeamUserJoin {
    onDeleteTeamUserJoin {
      id
      customerID
      teamID
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      team {
        id
        name
        nickname
        department
        avatarUrl
        avatarInitials
        customerID
        memberCount
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        users {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateConectivity = /* GraphQL */ `
  subscription OnCreateConectivity {
    onCreateConectivity {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateConectivity = /* GraphQL */ `
  subscription OnUpdateConectivity {
    onUpdateConectivity {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteConectivity = /* GraphQL */ `
  subscription OnDeleteConectivity {
    onDeleteConectivity {
      id
      title
      description
      instructions
      image
      approvedPaidTime
      conectCoins
      badgesDEI
      badgesCS
      costPerPerson
      costFixed
      signupDays
      completionDays
      categoryID
      conectivityScope
      publicationStatus
      inactiveForCustomers
      authorID
      author
      authorUrl
      authorInitials
      createdByCustomerID
      createdByCustomerName
      createdByCustomerlogo
      sharedDate
      sharedConectivityID
      subscribingCustomers
      numDownloads
      userLikes
      ratingStatsID
      imageID
      isSchedulableByAdminOnly
      isTemplate
      isTutorial
      isManuallyScheduledOnly
      isSpecialEvent
      inviteAllEmployees
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      hasNoSpecificTime
      launchRepeatOption
      launchUntilDate
      launchUntilCount
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      isChallenge
      challengeOptions
      isReoccuring
      reocurringFrequency
      reocurringParamI
      reocurringParamS
      options
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      imageS3 {
        id
        name
        title
        description
        width
        height
        url
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      ratingStats {
        id
        conectivityID
        numberOfRatings
        ratingsAverage
        ratingsMax
        ratingsMin
        numberOfCompletions
        numberOfSkips
        numberOfLaunches
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      name
      description
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      name
      description
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      name
      description
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateScheduledConectivity = /* GraphQL */ `
  subscription OnCreateScheduledConectivity {
    onCreateScheduledConectivity {
      id
      creationDateTime
      closingDateTime
      runningState
      customerID
      conectivityID
      launchRuleID
      options
      createdAt
      updatedAt
      conectivity {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      invitations {
        items {
          id
          customerID
          senderID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          messagesReadAt
          forLaunchedConectivity
          usersInvitedToParticipate
          teamsInvitedToParticipate
          scheduledConectivityID
          launchRuleID
          launchRuleInstanceIndex
          launchRuleInstanceStartDateTime
          launchRuleInstanceEndDateTime
          allDayEvent
          leaderboardID
          createdAt
          updatedAt
          launchRule {
            id
            customerID
            conectivityID
            usersToInvite
            teamsToInvite
            senderID
            message
            messageGIF
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            eventStartDateTime
            eventEndDateTime
            allDayEvent
            launchUntilDate
            launchUntilCount
            launchRepeatOption
            launchRepeatWeeklyOptions
            launchRepeatMonthlyOptions
            launchRepeatMonthlyOptions_2
            launchRepeatMonthlyOptions_3
            launchMonthOption1Checked
            launchMonthOption2Checked
            calendarUid
            calendarEventID
            parentRecurringEventId
            eventInstanceNumber
            originalLaunchStartDate
            nextInstanceNumber
            isMentorMeeting
            isSponsorMeeting
            messagesReadAtRecords {
              messagesReadAt
              userID
              __typename
            }
            rsvpRecords {
              userID
              rsvpStatus
              launchRuleID
              launchRuleInstanceIndex
              __typename
            }
            challengeID
            options
            createdAt
            updatedAt
            __typename
          }
          invitees {
            items {
              id
              inviteeID
              inviteeAvatarUrl
              inviteeAvatarName
              inviteeAvatarInitials
              invitationID
              messagesReadAt
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          messages {
            items {
              id
              customerID
              senderID
              message
              invitationID
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              parentMessage
              userLikes
              origin
              isSystemMessage
              mediaUrl
              launchRuleID
              recognitionID
              slackMessageID
              slackParentMessageID
              slackChannel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      designatedFor {
        items {
          id
          name
          label
          userID
          teamID
          scheduledConectivityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateScheduledConectivity = /* GraphQL */ `
  subscription OnUpdateScheduledConectivity {
    onUpdateScheduledConectivity {
      id
      creationDateTime
      closingDateTime
      runningState
      customerID
      conectivityID
      launchRuleID
      options
      createdAt
      updatedAt
      conectivity {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      invitations {
        items {
          id
          customerID
          senderID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          messagesReadAt
          forLaunchedConectivity
          usersInvitedToParticipate
          teamsInvitedToParticipate
          scheduledConectivityID
          launchRuleID
          launchRuleInstanceIndex
          launchRuleInstanceStartDateTime
          launchRuleInstanceEndDateTime
          allDayEvent
          leaderboardID
          createdAt
          updatedAt
          launchRule {
            id
            customerID
            conectivityID
            usersToInvite
            teamsToInvite
            senderID
            message
            messageGIF
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            eventStartDateTime
            eventEndDateTime
            allDayEvent
            launchUntilDate
            launchUntilCount
            launchRepeatOption
            launchRepeatWeeklyOptions
            launchRepeatMonthlyOptions
            launchRepeatMonthlyOptions_2
            launchRepeatMonthlyOptions_3
            launchMonthOption1Checked
            launchMonthOption2Checked
            calendarUid
            calendarEventID
            parentRecurringEventId
            eventInstanceNumber
            originalLaunchStartDate
            nextInstanceNumber
            isMentorMeeting
            isSponsorMeeting
            messagesReadAtRecords {
              messagesReadAt
              userID
              __typename
            }
            rsvpRecords {
              userID
              rsvpStatus
              launchRuleID
              launchRuleInstanceIndex
              __typename
            }
            challengeID
            options
            createdAt
            updatedAt
            __typename
          }
          invitees {
            items {
              id
              inviteeID
              inviteeAvatarUrl
              inviteeAvatarName
              inviteeAvatarInitials
              invitationID
              messagesReadAt
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          messages {
            items {
              id
              customerID
              senderID
              message
              invitationID
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              parentMessage
              userLikes
              origin
              isSystemMessage
              mediaUrl
              launchRuleID
              recognitionID
              slackMessageID
              slackParentMessageID
              slackChannel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      designatedFor {
        items {
          id
          name
          label
          userID
          teamID
          scheduledConectivityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteScheduledConectivity = /* GraphQL */ `
  subscription OnDeleteScheduledConectivity {
    onDeleteScheduledConectivity {
      id
      creationDateTime
      closingDateTime
      runningState
      customerID
      conectivityID
      launchRuleID
      options
      createdAt
      updatedAt
      conectivity {
        id
        title
        description
        instructions
        image
        approvedPaidTime
        conectCoins
        badgesDEI
        badgesCS
        costPerPerson
        costFixed
        signupDays
        completionDays
        categoryID
        conectivityScope
        publicationStatus
        inactiveForCustomers
        authorID
        author
        authorUrl
        authorInitials
        createdByCustomerID
        createdByCustomerName
        createdByCustomerlogo
        sharedDate
        sharedConectivityID
        subscribingCustomers
        numDownloads
        userLikes
        ratingStatsID
        imageID
        isSchedulableByAdminOnly
        isTemplate
        isTutorial
        isManuallyScheduledOnly
        isSpecialEvent
        inviteAllEmployees
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        hasNoSpecificTime
        launchRepeatOption
        launchUntilDate
        launchUntilCount
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        isChallenge
        challengeOptions
        isReoccuring
        reocurringFrequency
        reocurringParamI
        reocurringParamS
        options
        createdAt
        updatedAt
        category {
          id
          name
          description
          image
          label
          createdAt
          updatedAt
          __typename
        }
        imageS3 {
          id
          name
          title
          description
          width
          height
          url
          categoryID
          createdAt
          updatedAt
          category {
            id
            name
            description
            image
            label
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        ratingStats {
          id
          conectivityID
          numberOfRatings
          ratingsAverage
          ratingsMax
          ratingsMin
          numberOfCompletions
          numberOfSkips
          numberOfLaunches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      invitations {
        items {
          id
          customerID
          senderID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          messagesReadAt
          forLaunchedConectivity
          usersInvitedToParticipate
          teamsInvitedToParticipate
          scheduledConectivityID
          launchRuleID
          launchRuleInstanceIndex
          launchRuleInstanceStartDateTime
          launchRuleInstanceEndDateTime
          allDayEvent
          leaderboardID
          createdAt
          updatedAt
          launchRule {
            id
            customerID
            conectivityID
            usersToInvite
            teamsToInvite
            senderID
            message
            messageGIF
            senderAvatarUrl
            senderAvatarName
            senderAvatarInitials
            eventStartDateTime
            eventEndDateTime
            allDayEvent
            launchUntilDate
            launchUntilCount
            launchRepeatOption
            launchRepeatWeeklyOptions
            launchRepeatMonthlyOptions
            launchRepeatMonthlyOptions_2
            launchRepeatMonthlyOptions_3
            launchMonthOption1Checked
            launchMonthOption2Checked
            calendarUid
            calendarEventID
            parentRecurringEventId
            eventInstanceNumber
            originalLaunchStartDate
            nextInstanceNumber
            isMentorMeeting
            isSponsorMeeting
            messagesReadAtRecords {
              messagesReadAt
              userID
              __typename
            }
            rsvpRecords {
              userID
              rsvpStatus
              launchRuleID
              launchRuleInstanceIndex
              __typename
            }
            challengeID
            options
            createdAt
            updatedAt
            __typename
          }
          invitees {
            items {
              id
              inviteeID
              inviteeAvatarUrl
              inviteeAvatarName
              inviteeAvatarInitials
              invitationID
              messagesReadAt
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          messages {
            items {
              id
              customerID
              senderID
              message
              invitationID
              senderAvatarUrl
              senderAvatarName
              senderAvatarInitials
              parentMessage
              userLikes
              origin
              isSystemMessage
              mediaUrl
              launchRuleID
              recognitionID
              slackMessageID
              slackParentMessageID
              slackChannel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      designatedFor {
        items {
          id
          name
          label
          userID
          teamID
          scheduledConectivityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLaunchRule = /* GraphQL */ `
  subscription OnCreateLaunchRule {
    onCreateLaunchRule {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLaunchRule = /* GraphQL */ `
  subscription OnUpdateLaunchRule {
    onUpdateLaunchRule {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLaunchRule = /* GraphQL */ `
  subscription OnDeleteLaunchRule {
    onDeleteLaunchRule {
      id
      customerID
      conectivityID
      usersToInvite
      teamsToInvite
      senderID
      message
      messageGIF
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      eventStartDateTime
      eventEndDateTime
      allDayEvent
      launchUntilDate
      launchUntilCount
      launchRepeatOption
      launchRepeatWeeklyOptions
      launchRepeatMonthlyOptions
      launchRepeatMonthlyOptions_2
      launchRepeatMonthlyOptions_3
      launchMonthOption1Checked
      launchMonthOption2Checked
      calendarUid
      calendarEventID
      parentRecurringEventId
      eventInstanceNumber
      originalLaunchStartDate
      nextInstanceNumber
      isMentorMeeting
      isSponsorMeeting
      messagesReadAtRecords {
        messagesReadAt
        userID
        __typename
      }
      rsvpRecords {
        userID
        rsvpStatus
        launchRuleID
        launchRuleInstanceIndex
        __typename
      }
      challengeID
      options
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInvitation = /* GraphQL */ `
  subscription OnCreateInvitation {
    onCreateInvitation {
      id
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      messagesReadAt
      forLaunchedConectivity
      usersInvitedToParticipate
      teamsInvitedToParticipate
      scheduledConectivityID
      launchRuleID
      launchRuleInstanceIndex
      launchRuleInstanceStartDateTime
      launchRuleInstanceEndDateTime
      allDayEvent
      leaderboardID
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      invitees {
        items {
          id
          inviteeID
          inviteeAvatarUrl
          inviteeAvatarName
          inviteeAvatarInitials
          invitationID
          messagesReadAt
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInvitation = /* GraphQL */ `
  subscription OnUpdateInvitation {
    onUpdateInvitation {
      id
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      messagesReadAt
      forLaunchedConectivity
      usersInvitedToParticipate
      teamsInvitedToParticipate
      scheduledConectivityID
      launchRuleID
      launchRuleInstanceIndex
      launchRuleInstanceStartDateTime
      launchRuleInstanceEndDateTime
      allDayEvent
      leaderboardID
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      invitees {
        items {
          id
          inviteeID
          inviteeAvatarUrl
          inviteeAvatarName
          inviteeAvatarInitials
          invitationID
          messagesReadAt
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvitation = /* GraphQL */ `
  subscription OnDeleteInvitation {
    onDeleteInvitation {
      id
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      messagesReadAt
      forLaunchedConectivity
      usersInvitedToParticipate
      teamsInvitedToParticipate
      scheduledConectivityID
      launchRuleID
      launchRuleInstanceIndex
      launchRuleInstanceStartDateTime
      launchRuleInstanceEndDateTime
      allDayEvent
      leaderboardID
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      invitees {
        items {
          id
          inviteeID
          inviteeAvatarUrl
          inviteeAvatarName
          inviteeAvatarInitials
          invitationID
          messagesReadAt
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvitee = /* GraphQL */ `
  subscription OnCreateInvitee {
    onCreateInvitee {
      id
      inviteeID
      inviteeAvatarUrl
      inviteeAvatarName
      inviteeAvatarInitials
      invitationID
      messagesReadAt
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInvitee = /* GraphQL */ `
  subscription OnUpdateInvitee {
    onUpdateInvitee {
      id
      inviteeID
      inviteeAvatarUrl
      inviteeAvatarName
      inviteeAvatarInitials
      invitationID
      messagesReadAt
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInvitee = /* GraphQL */ `
  subscription OnDeleteInvitee {
    onDeleteInvitee {
      id
      inviteeID
      inviteeAvatarUrl
      inviteeAvatarName
      inviteeAvatarInitials
      invitationID
      messagesReadAt
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      customerID
      senderID
      message
      invitationID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentMessage
      userLikes
      origin
      isSystemMessage
      mediaUrl
      launchRuleID
      recognitionID
      slackMessageID
      slackParentMessageID
      slackChannel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      customerID
      senderID
      message
      invitationID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentMessage
      userLikes
      origin
      isSystemMessage
      mediaUrl
      launchRuleID
      recognitionID
      slackMessageID
      slackParentMessageID
      slackChannel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      customerID
      senderID
      message
      invitationID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentMessage
      userLikes
      origin
      isSystemMessage
      mediaUrl
      launchRuleID
      recognitionID
      slackMessageID
      slackParentMessageID
      slackChannel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessagePacket = /* GraphQL */ `
  subscription OnCreateMessagePacket {
    onCreateMessagePacket {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessagePacket = /* GraphQL */ `
  subscription OnUpdateMessagePacket {
    onUpdateMessagePacket {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessagePacket = /* GraphQL */ `
  subscription OnDeleteMessagePacket {
    onDeleteMessagePacket {
      id
      userID
      customerID
      messageID
      createdAt
      updatedAt
      message {
        id
        customerID
        senderID
        message
        invitationID
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        parentMessage
        userLikes
        origin
        isSystemMessage
        mediaUrl
        launchRuleID
        recognitionID
        slackMessageID
        slackParentMessageID
        slackChannel
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessageThread = /* GraphQL */ `
  subscription OnCreateMessageThread {
    onCreateMessageThread {
      id
      channelID
      threadID
      customerID
      launchRuleID
      invitationID
      scheduledConectivityID
      messageID
      spotlightID
      senderID
      inviteeID
      isConectivitySpecific
      isSpotlightSpecific
      threadStatus
      cloudProvider
      threadParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessageThread = /* GraphQL */ `
  subscription OnUpdateMessageThread {
    onUpdateMessageThread {
      id
      channelID
      threadID
      customerID
      launchRuleID
      invitationID
      scheduledConectivityID
      messageID
      spotlightID
      senderID
      inviteeID
      isConectivitySpecific
      isSpotlightSpecific
      threadStatus
      cloudProvider
      threadParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessageThread = /* GraphQL */ `
  subscription OnDeleteMessageThread {
    onDeleteMessageThread {
      id
      channelID
      threadID
      customerID
      launchRuleID
      invitationID
      scheduledConectivityID
      messageID
      spotlightID
      senderID
      inviteeID
      isConectivitySpecific
      isSpotlightSpecific
      threadStatus
      cloudProvider
      threadParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDesignator = /* GraphQL */ `
  subscription OnCreateDesignator {
    onCreateDesignator {
      id
      name
      label
      userID
      teamID
      scheduledConectivityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDesignator = /* GraphQL */ `
  subscription OnUpdateDesignator {
    onUpdateDesignator {
      id
      name
      label
      userID
      teamID
      scheduledConectivityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDesignator = /* GraphQL */ `
  subscription OnDeleteDesignator {
    onDeleteDesignator {
      id
      name
      label
      userID
      teamID
      scheduledConectivityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCCWallet = /* GraphQL */ `
  subscription OnCreateCCWallet {
    onCreateCCWallet {
      id
      dateCreated
      description
      currentBalance
      badgesDEI
      badgesCS
      badgesSpotlight
      status
      nextSequenceNumber
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCCWallet = /* GraphQL */ `
  subscription OnUpdateCCWallet {
    onUpdateCCWallet {
      id
      dateCreated
      description
      currentBalance
      badgesDEI
      badgesCS
      badgesSpotlight
      status
      nextSequenceNumber
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCCWallet = /* GraphQL */ `
  subscription OnDeleteCCWallet {
    onDeleteCCWallet {
      id
      dateCreated
      description
      currentBalance
      badgesDEI
      badgesCS
      badgesSpotlight
      status
      nextSequenceNumber
      userID
      createdAt
      updatedAt
      user {
        id
        firstName
        middleName
        lastName
        email
        title
        pronouns
        bio
        userCognitoID
        userNameCognito
        avatarWidth
        avatarHeight
        avatarUrl
        customerID
        userProgressDataID
        ccWalletID
        mentorID
        sponsorID
        supervisorID
        canBeMentor
        canBeSponsor
        canBeSupervisor
        isUnderrepresentedGroup
        isAdmin
        isSuperAdmin
        permissionLaunch
        permissionAnalytics
        permissionEditor
        permissionOrders
        permissionBilling
        onNumberOfTeams
        tutorialLevel
        googleCalendarEnabled
        msteamsCalendarEnabled
        msoutlookCalendarEnabled
        ms365CalendarEnabled
        icloudCalendarEnabled
        slackEnabled
        timeZoneCode
        startDate
        displayTheme
        options
        createdAt
        updatedAt
        customer {
          id
          name
          nickname
          logo
          virtualStore
          logoWidth
          logoHeight
          useExternalStore
          website
          activeEmployees
          fullName
          address1
          address2
          city
          state
          postalCode
          country
          phoneNumber
          email
          configDetailsID
          customerBillingID
          createdAt
          updatedAt
          configDetails {
            id
            customerID
            master
            useBadges
            ringClosureFirmCap
            ringClosureOverride
            conectivityMinToCoinConversionOverride
            dollarToCoinConversion
            socialConectivitiesToCloseRing
            socialMinActiveConectivities
            socialCoinsToCloseRing
            socialPeriodToCloseRing
            socialRewardForClosingRing
            stressConectivitiesToCloseRing
            stressMinActiveConectivities
            stressCoinsToCloseRing
            stressPeriodToCloseRing
            stressRewardForClosingRing
            teamConectivitiesToCloseRing
            teamMinActiveConectivities
            teamCoinsToCloseRing
            teamPeriodToCloseRing
            teamRewardForClosingRing
            individualConectivitiesToCloseRing
            individualMinActiveConectivities
            individualCoinsToCloseRing
            individualPeriodToCloseRing
            individualRewardForClosingRing
            leaderBoardRewardDailyWin
            leaderBoardRewardWeeklyWin
            leaderBoardRewardMonthlyWin
            leaderBoardRewardQuarterlyWin
            leaderBoardRewardYearlyWin
            spotlightsEnabled
            spotlightsAdminSendOnly
            spotlightAmountSender
            spotlightAmountReceiver
            spotlightsFullBccEnabled
            spotlightAwardCappedAtTeamRing
            spotlightCastCapEnabled
            spotlightCastCapLimit
            spotlightCastCapPeriod
            newEmpConectivitySchedulingEnabled
            newEmployeeOnboardingConectivityID
            newEmployeeWelcomeConectivityID
            newEmployeeOneOnOneSupervisorConectivityID
            newEmployeeOneOnOneMentorConectivityID
            newEmployeeOneOnOneSponsorConectivityID
            newEmployeeLunchConectivityID
            newEmployeeOnboardingConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeWelcomeConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSupervisorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneMentorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeOneOnOneSponsorConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeLunchConectivityStartTime {
              startHour
              startMin
              __typename
            }
            newEmployeeConectivitiesToSchedule
            mentorProgramEnabled
            sponsorProgramEnabled
            mentorLaunchEventStartDateTime
            mentorLaunchRepeatOption
            mentorLaunchRepeatWeeklyOptions
            mentorLaunchRepeatMonthlyOptions
            mentorLaunchRepeatMonthlyOptions_2
            mentorLaunchRepeatMonthlyOptions_3
            mentorLaunchMonthOption1Checked
            mentorLaunchMonthOption2Checked
            sponsorLaunchEventStartDateTime
            sponsorLaunchRepeatOption
            sponsorLaunchRepeatWeeklyOptions
            sponsorLaunchRepeatMonthlyOptions
            sponsorLaunchRepeatMonthlyOptions_2
            sponsorLaunchRepeatMonthlyOptions_3
            sponsorLaunchMonthOption1Checked
            sponsorLaunchMonthOption2Checked
            slackEnabled
            slackSpotlightChannelID
            slackSpotlightChannelName
            slackChannelCreationEnabled
            slackOptions
            msTeamsEnabled
            msTeamsSpotlightChannelID
            msTeamsSpotlightWebhook
            msTeamsChannelCreationEnabled
            msTeamsSpotlightsInstallRecordID
            msTeamsOptions
            enableAdminChallenges
            enableEmployeeChallenges
            enableEmployeeChallengeRewards
            challengeCoinCap
            challengeBadgesDEICap
            challengeBadgesCSCap
            challengeRecognitionsCap
            disableAIChallengeRecognitionMessages
            enableEmployeeConnectionBuilder
            employeeConnectionBuilderOptions
            options
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        teams {
          items {
            id
            customerID
            teamID
            userID
            createdAt
            updatedAt
            user {
              id
              firstName
              middleName
              lastName
              email
              title
              pronouns
              bio
              userCognitoID
              userNameCognito
              avatarWidth
              avatarHeight
              avatarUrl
              customerID
              userProgressDataID
              ccWalletID
              mentorID
              sponsorID
              supervisorID
              canBeMentor
              canBeSponsor
              canBeSupervisor
              isUnderrepresentedGroup
              isAdmin
              isSuperAdmin
              permissionLaunch
              permissionAnalytics
              permissionEditor
              permissionOrders
              permissionBilling
              onNumberOfTeams
              tutorialLevel
              googleCalendarEnabled
              msteamsCalendarEnabled
              msoutlookCalendarEnabled
              ms365CalendarEnabled
              icloudCalendarEnabled
              slackEnabled
              timeZoneCode
              startDate
              displayTheme
              options
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              teams {
                nextToken
                __typename
              }
              ccWallet {
                id
                dateCreated
                description
                currentBalance
                badgesDEI
                badgesCS
                badgesSpotlight
                status
                nextSequenceNumber
                userID
                createdAt
                updatedAt
                __typename
              }
              userProgressData {
                id
                userID
                customerID
                subscriptionTrigger
                socialPeriodCoinsEarned
                socialPeriodStart
                socialPeriodEnd
                socialPeriod
                socialPeriodCoinGoal
                socialPeriodConectivitiesComplete
                socialPeriodBonusGiven
                stressPeriodCoinsEarned
                stressPeriodStart
                stressPeriodEnd
                stressPeriod
                stressPeriodCoinGoal
                stressPeriodConectivitiesComplete
                stressPeriodBonusGiven
                teamPeriodCoinsEarned
                teamPeriodStart
                teamPeriodEnd
                teamPeriod
                teamPeriodCoinGoal
                teamPeriodConectivitiesComplete
                teamPeriodBonusGiven
                individualPeriodCoinsEarned
                individualPeriodStart
                individualPeriodEnd
                individualPeriod
                individualPeriodCoinGoal
                individualPeriodConectivitiesComplete
                individualPeriodBonusGiven
                spotlightPeriodNumCast
                spotlightPeriodStart
                spotlightPeriodEnd
                spotlightPeriod
                dayCoinsEarned
                dayClosingDateTime
                dayConectivitiesComplete
                weekCoinsEarned
                weekClosingDateTime
                weekConectivitiesComplete
                monthCoinsEarned
                monthClosingDateTime
                monthConectivitiesComplete
                quarterCoinsEarned
                quarterClosingDateTime
                quarterConectivitiesComplete
                yearCoinsEarned
                yearClosingDateTime
                yearConectivitiesComplete
                balanceStreakCount
                balanceStreakStartDate
                balanceStreakLastClosureDate
                socialStreakCount
                socialStreakStartDate
                socialStreakLastClosureDate
                teamStreakCount
                teamStreakStartDate
                teamStreakLastClosureDate
                growthStreakCount
                growthStreakStartDate
                growthStreakLastClosureDate
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            team {
              id
              name
              nickname
              department
              avatarUrl
              avatarInitials
              customerID
              memberCount
              createdAt
              updatedAt
              customer {
                id
                name
                nickname
                logo
                virtualStore
                logoWidth
                logoHeight
                useExternalStore
                website
                activeEmployees
                fullName
                address1
                address2
                city
                state
                postalCode
                country
                phoneNumber
                email
                configDetailsID
                customerBillingID
                createdAt
                updatedAt
                __typename
              }
              users {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        ccWallet {
          id
          dateCreated
          description
          currentBalance
          badgesDEI
          badgesCS
          badgesSpotlight
          status
          nextSequenceNumber
          userID
          createdAt
          updatedAt
          user {
            id
            firstName
            middleName
            lastName
            email
            title
            pronouns
            bio
            userCognitoID
            userNameCognito
            avatarWidth
            avatarHeight
            avatarUrl
            customerID
            userProgressDataID
            ccWalletID
            mentorID
            sponsorID
            supervisorID
            canBeMentor
            canBeSponsor
            canBeSupervisor
            isUnderrepresentedGroup
            isAdmin
            isSuperAdmin
            permissionLaunch
            permissionAnalytics
            permissionEditor
            permissionOrders
            permissionBilling
            onNumberOfTeams
            tutorialLevel
            googleCalendarEnabled
            msteamsCalendarEnabled
            msoutlookCalendarEnabled
            ms365CalendarEnabled
            icloudCalendarEnabled
            slackEnabled
            timeZoneCode
            startDate
            displayTheme
            options
            createdAt
            updatedAt
            customer {
              id
              name
              nickname
              logo
              virtualStore
              logoWidth
              logoHeight
              useExternalStore
              website
              activeEmployees
              fullName
              address1
              address2
              city
              state
              postalCode
              country
              phoneNumber
              email
              configDetailsID
              customerBillingID
              createdAt
              updatedAt
              configDetails {
                id
                customerID
                master
                useBadges
                ringClosureFirmCap
                ringClosureOverride
                conectivityMinToCoinConversionOverride
                dollarToCoinConversion
                socialConectivitiesToCloseRing
                socialMinActiveConectivities
                socialCoinsToCloseRing
                socialPeriodToCloseRing
                socialRewardForClosingRing
                stressConectivitiesToCloseRing
                stressMinActiveConectivities
                stressCoinsToCloseRing
                stressPeriodToCloseRing
                stressRewardForClosingRing
                teamConectivitiesToCloseRing
                teamMinActiveConectivities
                teamCoinsToCloseRing
                teamPeriodToCloseRing
                teamRewardForClosingRing
                individualConectivitiesToCloseRing
                individualMinActiveConectivities
                individualCoinsToCloseRing
                individualPeriodToCloseRing
                individualRewardForClosingRing
                leaderBoardRewardDailyWin
                leaderBoardRewardWeeklyWin
                leaderBoardRewardMonthlyWin
                leaderBoardRewardQuarterlyWin
                leaderBoardRewardYearlyWin
                spotlightsEnabled
                spotlightsAdminSendOnly
                spotlightAmountSender
                spotlightAmountReceiver
                spotlightsFullBccEnabled
                spotlightAwardCappedAtTeamRing
                spotlightCastCapEnabled
                spotlightCastCapLimit
                spotlightCastCapPeriod
                newEmpConectivitySchedulingEnabled
                newEmployeeOnboardingConectivityID
                newEmployeeWelcomeConectivityID
                newEmployeeOneOnOneSupervisorConectivityID
                newEmployeeOneOnOneMentorConectivityID
                newEmployeeOneOnOneSponsorConectivityID
                newEmployeeLunchConectivityID
                newEmployeeConectivitiesToSchedule
                mentorProgramEnabled
                sponsorProgramEnabled
                mentorLaunchEventStartDateTime
                mentorLaunchRepeatOption
                mentorLaunchRepeatWeeklyOptions
                mentorLaunchRepeatMonthlyOptions
                mentorLaunchRepeatMonthlyOptions_2
                mentorLaunchRepeatMonthlyOptions_3
                mentorLaunchMonthOption1Checked
                mentorLaunchMonthOption2Checked
                sponsorLaunchEventStartDateTime
                sponsorLaunchRepeatOption
                sponsorLaunchRepeatWeeklyOptions
                sponsorLaunchRepeatMonthlyOptions
                sponsorLaunchRepeatMonthlyOptions_2
                sponsorLaunchRepeatMonthlyOptions_3
                sponsorLaunchMonthOption1Checked
                sponsorLaunchMonthOption2Checked
                slackEnabled
                slackSpotlightChannelID
                slackSpotlightChannelName
                slackChannelCreationEnabled
                slackOptions
                msTeamsEnabled
                msTeamsSpotlightChannelID
                msTeamsSpotlightWebhook
                msTeamsChannelCreationEnabled
                msTeamsSpotlightsInstallRecordID
                msTeamsOptions
                enableAdminChallenges
                enableEmployeeChallenges
                enableEmployeeChallengeRewards
                challengeCoinCap
                challengeBadgesDEICap
                challengeBadgesCSCap
                challengeRecognitionsCap
                disableAIChallengeRecognitionMessages
                enableEmployeeConnectionBuilder
                employeeConnectionBuilderOptions
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            teams {
              items {
                id
                customerID
                teamID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            ccWallet {
              id
              dateCreated
              description
              currentBalance
              badgesDEI
              badgesCS
              badgesSpotlight
              status
              nextSequenceNumber
              userID
              createdAt
              updatedAt
              user {
                id
                firstName
                middleName
                lastName
                email
                title
                pronouns
                bio
                userCognitoID
                userNameCognito
                avatarWidth
                avatarHeight
                avatarUrl
                customerID
                userProgressDataID
                ccWalletID
                mentorID
                sponsorID
                supervisorID
                canBeMentor
                canBeSponsor
                canBeSupervisor
                isUnderrepresentedGroup
                isAdmin
                isSuperAdmin
                permissionLaunch
                permissionAnalytics
                permissionEditor
                permissionOrders
                permissionBilling
                onNumberOfTeams
                tutorialLevel
                googleCalendarEnabled
                msteamsCalendarEnabled
                msoutlookCalendarEnabled
                ms365CalendarEnabled
                icloudCalendarEnabled
                slackEnabled
                timeZoneCode
                startDate
                displayTheme
                options
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            userProgressData {
              id
              userID
              customerID
              subscriptionTrigger
              socialPeriodCoinsEarned
              socialPeriodStart
              socialPeriodEnd
              socialPeriod
              socialPeriodCoinGoal
              socialPeriodConectivitiesComplete
              socialPeriodBonusGiven
              stressPeriodCoinsEarned
              stressPeriodStart
              stressPeriodEnd
              stressPeriod
              stressPeriodCoinGoal
              stressPeriodConectivitiesComplete
              stressPeriodBonusGiven
              teamPeriodCoinsEarned
              teamPeriodStart
              teamPeriodEnd
              teamPeriod
              teamPeriodCoinGoal
              teamPeriodConectivitiesComplete
              teamPeriodBonusGiven
              individualPeriodCoinsEarned
              individualPeriodStart
              individualPeriodEnd
              individualPeriod
              individualPeriodCoinGoal
              individualPeriodConectivitiesComplete
              individualPeriodBonusGiven
              spotlightPeriodNumCast
              spotlightPeriodStart
              spotlightPeriodEnd
              spotlightPeriod
              dayCoinsEarned
              dayClosingDateTime
              dayConectivitiesComplete
              weekCoinsEarned
              weekClosingDateTime
              weekConectivitiesComplete
              monthCoinsEarned
              monthClosingDateTime
              monthConectivitiesComplete
              quarterCoinsEarned
              quarterClosingDateTime
              quarterConectivitiesComplete
              yearCoinsEarned
              yearClosingDateTime
              yearConectivitiesComplete
              balanceStreakCount
              balanceStreakStartDate
              balanceStreakLastClosureDate
              socialStreakCount
              socialStreakStartDate
              socialStreakLastClosureDate
              teamStreakCount
              teamStreakStartDate
              teamStreakLastClosureDate
              growthStreakCount
              growthStreakStartDate
              growthStreakLastClosureDate
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          __typename
        }
        userProgressData {
          id
          userID
          customerID
          subscriptionTrigger
          socialPeriodCoinsEarned
          socialPeriodStart
          socialPeriodEnd
          socialPeriod
          socialPeriodCoinGoal
          socialPeriodConectivitiesComplete
          socialPeriodBonusGiven
          stressPeriodCoinsEarned
          stressPeriodStart
          stressPeriodEnd
          stressPeriod
          stressPeriodCoinGoal
          stressPeriodConectivitiesComplete
          stressPeriodBonusGiven
          teamPeriodCoinsEarned
          teamPeriodStart
          teamPeriodEnd
          teamPeriod
          teamPeriodCoinGoal
          teamPeriodConectivitiesComplete
          teamPeriodBonusGiven
          individualPeriodCoinsEarned
          individualPeriodStart
          individualPeriodEnd
          individualPeriod
          individualPeriodCoinGoal
          individualPeriodConectivitiesComplete
          individualPeriodBonusGiven
          spotlightPeriodNumCast
          spotlightPeriodStart
          spotlightPeriodEnd
          spotlightPeriod
          dayCoinsEarned
          dayClosingDateTime
          dayConectivitiesComplete
          weekCoinsEarned
          weekClosingDateTime
          weekConectivitiesComplete
          monthCoinsEarned
          monthClosingDateTime
          monthConectivitiesComplete
          quarterCoinsEarned
          quarterClosingDateTime
          quarterConectivitiesComplete
          yearCoinsEarned
          yearClosingDateTime
          yearConectivitiesComplete
          balanceStreakCount
          balanceStreakStartDate
          balanceStreakLastClosureDate
          socialStreakCount
          socialStreakStartDate
          socialStreakLastClosureDate
          teamStreakCount
          teamStreakStartDate
          teamStreakLastClosureDate
          growthStreakCount
          growthStreakStartDate
          growthStreakLastClosureDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCCTransaction = /* GraphQL */ `
  subscription OnCreateCCTransaction {
    onCreateCCTransaction {
      id
      createdAt
      timeSeries
      sequenceNumber
      transactionType
      name
      amount
      dollarCost
      badgesDEI
      badgesCS
      badgesSpotlight
      newBalance
      badgesCSBalance
      badgesDEIBalance
      badgesSpotlightBalance
      memo
      image
      ccWalletID
      conectivityID
      category
      scheduledConectivityID
      usersThatParticipatedWithMe
      teamsThatParticipatedWithMe
      orderID
      recognitionID
      leaderboardID
      slotID
      customerID
      userID
      productID
      productTitle
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCCTransaction = /* GraphQL */ `
  subscription OnUpdateCCTransaction {
    onUpdateCCTransaction {
      id
      createdAt
      timeSeries
      sequenceNumber
      transactionType
      name
      amount
      dollarCost
      badgesDEI
      badgesCS
      badgesSpotlight
      newBalance
      badgesCSBalance
      badgesDEIBalance
      badgesSpotlightBalance
      memo
      image
      ccWalletID
      conectivityID
      category
      scheduledConectivityID
      usersThatParticipatedWithMe
      teamsThatParticipatedWithMe
      orderID
      recognitionID
      leaderboardID
      slotID
      customerID
      userID
      productID
      productTitle
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCCTransaction = /* GraphQL */ `
  subscription OnDeleteCCTransaction {
    onDeleteCCTransaction {
      id
      createdAt
      timeSeries
      sequenceNumber
      transactionType
      name
      amount
      dollarCost
      badgesDEI
      badgesCS
      badgesSpotlight
      newBalance
      badgesCSBalance
      badgesDEIBalance
      badgesSpotlightBalance
      memo
      image
      ccWalletID
      conectivityID
      category
      scheduledConectivityID
      usersThatParticipatedWithMe
      teamsThatParticipatedWithMe
      orderID
      recognitionID
      leaderboardID
      slotID
      customerID
      userID
      productID
      productTitle
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomerLeaderBoard = /* GraphQL */ `
  subscription OnCreateCustomerLeaderBoard {
    onCreateCustomerLeaderBoard {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomerLeaderBoard = /* GraphQL */ `
  subscription OnUpdateCustomerLeaderBoard {
    onUpdateCustomerLeaderBoard {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomerLeaderBoard = /* GraphQL */ `
  subscription OnDeleteCustomerLeaderBoard {
    onDeleteCustomerLeaderBoard {
      id
      customerID
      leaderBoardType
      isViewableCompanyWide
      isAllEmployees
      lastDeltaDate
      isChallenge
      launchRuleID
      launchRuleInstanceIndex
      startDateTime
      endDateTime
      allDayEvent
      challengeStatus
      createdAt
      updatedAt
      leaderBoardSpots {
        items {
          id
          customerID
          userID
          teamID
          customerLeaderBoardID
          currentBalance
          firstName
          lastName
          title
          rank
          userRank
          userMove
          avatarUrl
          leaderBoard
          firstEntry
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLeaderBoardSpot = /* GraphQL */ `
  subscription OnCreateLeaderBoardSpot {
    onCreateLeaderBoardSpot {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLeaderBoardSpot = /* GraphQL */ `
  subscription OnUpdateLeaderBoardSpot {
    onUpdateLeaderBoardSpot {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLeaderBoardSpot = /* GraphQL */ `
  subscription OnDeleteLeaderBoardSpot {
    onDeleteLeaderBoardSpot {
      id
      customerID
      userID
      teamID
      customerLeaderBoardID
      currentBalance
      firstName
      lastName
      title
      rank
      userRank
      userMove
      avatarUrl
      leaderBoard
      firstEntry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserProgressData = /* GraphQL */ `
  subscription OnCreateUserProgressData {
    onCreateUserProgressData {
      id
      userID
      customerID
      subscriptionTrigger
      socialPeriodCoinsEarned
      socialPeriodStart
      socialPeriodEnd
      socialPeriod
      socialPeriodCoinGoal
      socialPeriodConectivitiesComplete
      socialPeriodBonusGiven
      stressPeriodCoinsEarned
      stressPeriodStart
      stressPeriodEnd
      stressPeriod
      stressPeriodCoinGoal
      stressPeriodConectivitiesComplete
      stressPeriodBonusGiven
      teamPeriodCoinsEarned
      teamPeriodStart
      teamPeriodEnd
      teamPeriod
      teamPeriodCoinGoal
      teamPeriodConectivitiesComplete
      teamPeriodBonusGiven
      individualPeriodCoinsEarned
      individualPeriodStart
      individualPeriodEnd
      individualPeriod
      individualPeriodCoinGoal
      individualPeriodConectivitiesComplete
      individualPeriodBonusGiven
      spotlightPeriodNumCast
      spotlightPeriodStart
      spotlightPeriodEnd
      spotlightPeriod
      dayCoinsEarned
      dayClosingDateTime
      dayConectivitiesComplete
      weekCoinsEarned
      weekClosingDateTime
      weekConectivitiesComplete
      monthCoinsEarned
      monthClosingDateTime
      monthConectivitiesComplete
      quarterCoinsEarned
      quarterClosingDateTime
      quarterConectivitiesComplete
      yearCoinsEarned
      yearClosingDateTime
      yearConectivitiesComplete
      balanceStreakCount
      balanceStreakStartDate
      balanceStreakLastClosureDate
      socialStreakCount
      socialStreakStartDate
      socialStreakLastClosureDate
      teamStreakCount
      teamStreakStartDate
      teamStreakLastClosureDate
      growthStreakCount
      growthStreakStartDate
      growthStreakLastClosureDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserProgressData = /* GraphQL */ `
  subscription OnUpdateUserProgressData {
    onUpdateUserProgressData {
      id
      userID
      customerID
      subscriptionTrigger
      socialPeriodCoinsEarned
      socialPeriodStart
      socialPeriodEnd
      socialPeriod
      socialPeriodCoinGoal
      socialPeriodConectivitiesComplete
      socialPeriodBonusGiven
      stressPeriodCoinsEarned
      stressPeriodStart
      stressPeriodEnd
      stressPeriod
      stressPeriodCoinGoal
      stressPeriodConectivitiesComplete
      stressPeriodBonusGiven
      teamPeriodCoinsEarned
      teamPeriodStart
      teamPeriodEnd
      teamPeriod
      teamPeriodCoinGoal
      teamPeriodConectivitiesComplete
      teamPeriodBonusGiven
      individualPeriodCoinsEarned
      individualPeriodStart
      individualPeriodEnd
      individualPeriod
      individualPeriodCoinGoal
      individualPeriodConectivitiesComplete
      individualPeriodBonusGiven
      spotlightPeriodNumCast
      spotlightPeriodStart
      spotlightPeriodEnd
      spotlightPeriod
      dayCoinsEarned
      dayClosingDateTime
      dayConectivitiesComplete
      weekCoinsEarned
      weekClosingDateTime
      weekConectivitiesComplete
      monthCoinsEarned
      monthClosingDateTime
      monthConectivitiesComplete
      quarterCoinsEarned
      quarterClosingDateTime
      quarterConectivitiesComplete
      yearCoinsEarned
      yearClosingDateTime
      yearConectivitiesComplete
      balanceStreakCount
      balanceStreakStartDate
      balanceStreakLastClosureDate
      socialStreakCount
      socialStreakStartDate
      socialStreakLastClosureDate
      teamStreakCount
      teamStreakStartDate
      teamStreakLastClosureDate
      growthStreakCount
      growthStreakStartDate
      growthStreakLastClosureDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserProgressData = /* GraphQL */ `
  subscription OnDeleteUserProgressData {
    onDeleteUserProgressData {
      id
      userID
      customerID
      subscriptionTrigger
      socialPeriodCoinsEarned
      socialPeriodStart
      socialPeriodEnd
      socialPeriod
      socialPeriodCoinGoal
      socialPeriodConectivitiesComplete
      socialPeriodBonusGiven
      stressPeriodCoinsEarned
      stressPeriodStart
      stressPeriodEnd
      stressPeriod
      stressPeriodCoinGoal
      stressPeriodConectivitiesComplete
      stressPeriodBonusGiven
      teamPeriodCoinsEarned
      teamPeriodStart
      teamPeriodEnd
      teamPeriod
      teamPeriodCoinGoal
      teamPeriodConectivitiesComplete
      teamPeriodBonusGiven
      individualPeriodCoinsEarned
      individualPeriodStart
      individualPeriodEnd
      individualPeriod
      individualPeriodCoinGoal
      individualPeriodConectivitiesComplete
      individualPeriodBonusGiven
      spotlightPeriodNumCast
      spotlightPeriodStart
      spotlightPeriodEnd
      spotlightPeriod
      dayCoinsEarned
      dayClosingDateTime
      dayConectivitiesComplete
      weekCoinsEarned
      weekClosingDateTime
      weekConectivitiesComplete
      monthCoinsEarned
      monthClosingDateTime
      monthConectivitiesComplete
      quarterCoinsEarned
      quarterClosingDateTime
      quarterConectivitiesComplete
      yearCoinsEarned
      yearClosingDateTime
      yearConectivitiesComplete
      balanceStreakCount
      balanceStreakStartDate
      balanceStreakLastClosureDate
      socialStreakCount
      socialStreakStartDate
      socialStreakLastClosureDate
      teamStreakCount
      teamStreakStartDate
      teamStreakLastClosureDate
      growthStreakCount
      growthStreakStartDate
      growthStreakLastClosureDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      customerID
      title
      description
      image
      imageWidth
      imageHeight
      featured
      charity
      price
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      useInStore
      inactiveForCustomers
      productScope
      externalLink
      expiration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProductCategory = /* GraphQL */ `
  subscription OnCreateProductCategory {
    onCreateProductCategory {
      id
      title
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProductCategory = /* GraphQL */ `
  subscription OnUpdateProductCategory {
    onUpdateProductCategory {
      id
      title
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProductCategory = /* GraphQL */ `
  subscription OnDeleteProductCategory {
    onDeleteProductCategory {
      id
      title
      image
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      id
      name
      title
      description
      width
      height
      url
      categoryID
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      id
      name
      title
      description
      width
      height
      url
      categoryID
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      id
      name
      title
      description
      width
      height
      url
      categoryID
      createdAt
      updatedAt
      category {
        id
        name
        description
        image
        label
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      orderStatus
      amount
      badgesDEI
      badgesCS
      badgesSpotlight
      dollarCost
      tax
      shipping
      total
      dateProcessed
      memo
      productID
      customerID
      userID
      createdAt
      updatedAt
      product {
        id
        customerID
        title
        description
        image
        imageWidth
        imageHeight
        featured
        charity
        price
        badgesDEI
        badgesCS
        badgesSpotlight
        dollarCost
        useInStore
        inactiveForCustomers
        productScope
        externalLink
        expiration
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      createdAt
      id
      customerID
      customerName
      invoiceNumber
      billingPeriodStartDate
      billingPeriodEndingDate
      description
      charges
      credits
      tax
      shipping
      discount
      total
      activeUsersForBillingPeriod
      serviceForBillingPeriod
      invoiceStatus
      datePaid
      updatedAt
      lineItems {
        items {
          id
          invoiceID
          lineItemType
          memo
          amount
          taxable
          orderID
          quantity
          price
          discount
          tax
          shipping
          fulfillmentFee
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoiceLineItems = /* GraphQL */ `
  subscription OnCreateInvoiceLineItems {
    onCreateInvoiceLineItems {
      id
      invoiceID
      lineItemType
      memo
      amount
      taxable
      orderID
      quantity
      price
      discount
      tax
      shipping
      fulfillmentFee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInvoiceLineItems = /* GraphQL */ `
  subscription OnUpdateInvoiceLineItems {
    onUpdateInvoiceLineItems {
      id
      invoiceID
      lineItemType
      memo
      amount
      taxable
      orderID
      quantity
      price
      discount
      tax
      shipping
      fulfillmentFee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInvoiceLineItems = /* GraphQL */ `
  subscription OnDeleteInvoiceLineItems {
    onDeleteInvoiceLineItems {
      id
      invoiceID
      lineItemType
      memo
      amount
      taxable
      orderID
      quantity
      price
      discount
      tax
      shipping
      fulfillmentFee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePaymentTransaction = /* GraphQL */ `
  subscription OnCreatePaymentTransaction {
    onCreatePaymentTransaction {
      id
      customerID
      invoiceID
      transactionNumber
      memo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePaymentTransaction = /* GraphQL */ `
  subscription OnUpdatePaymentTransaction {
    onUpdatePaymentTransaction {
      id
      customerID
      invoiceID
      transactionNumber
      memo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePaymentTransaction = /* GraphQL */ `
  subscription OnDeletePaymentTransaction {
    onDeletePaymentTransaction {
      id
      customerID
      invoiceID
      transactionNumber
      memo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecognitionType = /* GraphQL */ `
  subscription OnCreateRecognitionType {
    onCreateRecognitionType {
      id
      recognitionType
      message
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRecognitionType = /* GraphQL */ `
  subscription OnUpdateRecognitionType {
    onUpdateRecognitionType {
      id
      recognitionType
      message
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRecognitionType = /* GraphQL */ `
  subscription OnDeleteRecognitionType {
    onDeleteRecognitionType {
      id
      recognitionType
      message
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecognition = /* GraphQL */ `
  subscription OnCreateRecognition {
    onCreateRecognition {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRecognition = /* GraphQL */ `
  subscription OnUpdateRecognition {
    onUpdateRecognition {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRecognition = /* GraphQL */ `
  subscription OnDeleteRecognition {
    onDeleteRecognition {
      id
      expiration
      recognitionType
      message
      image
      imageID
      customerID
      senderID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      amount
      senderAmount
      badgesDEI
      badgesCS
      userLikes
      isChallenge
      isSystemRecognition
      createdAt
      updatedAt
      messages {
        items {
          id
          customerID
          senderID
          message
          invitationID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentMessage
          userLikes
          origin
          isSystemMessage
          mediaUrl
          launchRuleID
          recognitionID
          slackMessageID
          slackParentMessageID
          slackChannel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recognizedEmployees {
        items {
          id
          customerID
          recognizedUserID
          recognizedUserAvatarUrl
          recognizedUserAvatarName
          recognizedUserAvatarFirstName
          recognizedUserAvatarInitials
          recognizedUserEmail
          recognitionID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          senderID
          comment
          recognitionID
          senderAvatarUrl
          senderAvatarName
          senderAvatarInitials
          parentComment
          userLikes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRecognizedUser = /* GraphQL */ `
  subscription OnCreateRecognizedUser {
    onCreateRecognizedUser {
      id
      customerID
      recognizedUserID
      recognizedUserAvatarUrl
      recognizedUserAvatarName
      recognizedUserAvatarFirstName
      recognizedUserAvatarInitials
      recognizedUserEmail
      recognitionID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRecognizedUser = /* GraphQL */ `
  subscription OnUpdateRecognizedUser {
    onUpdateRecognizedUser {
      id
      customerID
      recognizedUserID
      recognizedUserAvatarUrl
      recognizedUserAvatarName
      recognizedUserAvatarFirstName
      recognizedUserAvatarInitials
      recognizedUserEmail
      recognitionID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRecognizedUser = /* GraphQL */ `
  subscription OnDeleteRecognizedUser {
    onDeleteRecognizedUser {
      id
      customerID
      recognizedUserID
      recognizedUserAvatarUrl
      recognizedUserAvatarName
      recognizedUserAvatarFirstName
      recognizedUserAvatarInitials
      recognizedUserEmail
      recognitionID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecognitionComment = /* GraphQL */ `
  subscription OnCreateRecognitionComment {
    onCreateRecognitionComment {
      id
      senderID
      comment
      recognitionID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentComment
      userLikes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRecognitionComment = /* GraphQL */ `
  subscription OnUpdateRecognitionComment {
    onUpdateRecognitionComment {
      id
      senderID
      comment
      recognitionID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentComment
      userLikes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRecognitionComment = /* GraphQL */ `
  subscription OnDeleteRecognitionComment {
    onDeleteRecognitionComment {
      id
      senderID
      comment
      recognitionID
      senderAvatarUrl
      senderAvatarName
      senderAvatarInitials
      parentComment
      userLikes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotificationType = /* GraphQL */ `
  subscription OnCreateNotificationType {
    onCreateNotificationType {
      id
      notificationType
      title
      text
      textFormatted
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotificationType = /* GraphQL */ `
  subscription OnUpdateNotificationType {
    onUpdateNotificationType {
      id
      notificationType
      title
      text
      textFormatted
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotificationType = /* GraphQL */ `
  subscription OnDeleteNotificationType {
    onDeleteNotificationType {
      id
      notificationType
      title
      text
      textFormatted
      image
      imageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      expiration
      readByUser
      pushNotificationSent
      title
      text
      textFormatted
      image
      imageID
      notificationType
      scope
      invitationID
      spotlightID
      messageID
      commentID
      scheduledConectivityID
      recognitionID
      urlRedirect
      notificationTypeID
      customerID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      expiration
      readByUser
      pushNotificationSent
      title
      text
      textFormatted
      image
      imageID
      notificationType
      scope
      invitationID
      spotlightID
      messageID
      commentID
      scheduledConectivityID
      recognitionID
      urlRedirect
      notificationTypeID
      customerID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      expiration
      readByUser
      pushNotificationSent
      title
      text
      textFormatted
      image
      imageID
      notificationType
      scope
      invitationID
      spotlightID
      messageID
      commentID
      scheduledConectivityID
      recognitionID
      urlRedirect
      notificationTypeID
      customerID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePushToken = /* GraphQL */ `
  subscription OnCreatePushToken {
    onCreatePushToken {
      id
      pushToken
      channel
      expiration
      userID
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePushToken = /* GraphQL */ `
  subscription OnUpdatePushToken {
    onUpdatePushToken {
      id
      pushToken
      channel
      expiration
      userID
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePushToken = /* GraphQL */ `
  subscription OnDeletePushToken {
    onDeletePushToken {
      id
      pushToken
      channel
      expiration
      userID
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRating = /* GraphQL */ `
  subscription OnCreateRating {
    onCreateRating {
      id
      conectivityID
      userID
      customerID
      ratingValue
      comment
      processed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRating = /* GraphQL */ `
  subscription OnUpdateRating {
    onUpdateRating {
      id
      conectivityID
      userID
      customerID
      ratingValue
      comment
      processed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRating = /* GraphQL */ `
  subscription OnDeleteRating {
    onDeleteRating {
      id
      conectivityID
      userID
      customerID
      ratingValue
      comment
      processed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRatingStats = /* GraphQL */ `
  subscription OnCreateRatingStats {
    onCreateRatingStats {
      id
      conectivityID
      numberOfRatings
      ratingsAverage
      ratingsMax
      ratingsMin
      numberOfCompletions
      numberOfSkips
      numberOfLaunches
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRatingStats = /* GraphQL */ `
  subscription OnUpdateRatingStats {
    onUpdateRatingStats {
      id
      conectivityID
      numberOfRatings
      ratingsAverage
      ratingsMax
      ratingsMin
      numberOfCompletions
      numberOfSkips
      numberOfLaunches
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRatingStats = /* GraphQL */ `
  subscription OnDeleteRatingStats {
    onDeleteRatingStats {
      id
      conectivityID
      numberOfRatings
      ratingsAverage
      ratingsMax
      ratingsMin
      numberOfCompletions
      numberOfSkips
      numberOfLaunches
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNewsRelease = /* GraphQL */ `
  subscription OnCreateNewsRelease {
    onCreateNewsRelease {
      id
      title
      content
      image
      imageID
      releaseDate
      dateTestEmailSent
      sentToCustomers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNewsRelease = /* GraphQL */ `
  subscription OnUpdateNewsRelease {
    onUpdateNewsRelease {
      id
      title
      content
      image
      imageID
      releaseDate
      dateTestEmailSent
      sentToCustomers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNewsRelease = /* GraphQL */ `
  subscription OnDeleteNewsRelease {
    onDeleteNewsRelease {
      id
      title
      content
      image
      imageID
      releaseDate
      dateTestEmailSent
      sentToCustomers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserAnalytics = /* GraphQL */ `
  subscription OnCreateUserAnalytics {
    onCreateUserAnalytics {
      id
      userID
      customerID
      name
      title
      customer
      onNumberOfTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      userRank
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserAnalytics = /* GraphQL */ `
  subscription OnUpdateUserAnalytics {
    onUpdateUserAnalytics {
      id
      userID
      customerID
      name
      title
      customer
      onNumberOfTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      userRank
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserAnalytics = /* GraphQL */ `
  subscription OnDeleteUserAnalytics {
    onDeleteUserAnalytics {
      id
      userID
      customerID
      name
      title
      customer
      onNumberOfTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      userRank
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTeamAnalytics = /* GraphQL */ `
  subscription OnCreateTeamAnalytics {
    onCreateTeamAnalytics {
      id
      teamID
      customerID
      name
      nickname
      department
      customer
      memberCount
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      teamRank
      rank
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTeamAnalytics = /* GraphQL */ `
  subscription OnUpdateTeamAnalytics {
    onUpdateTeamAnalytics {
      id
      teamID
      customerID
      name
      nickname
      department
      customer
      memberCount
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      teamRank
      rank
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTeamAnalytics = /* GraphQL */ `
  subscription OnDeleteTeamAnalytics {
    onDeleteTeamAnalytics {
      id
      teamID
      customerID
      name
      nickname
      department
      customer
      memberCount
      conectivitiesCompleted
      coinsAwarded
      badgesDEIAwarded
      badgesCSAwarded
      socialConectivitiesCompleted
      stressConectivitiesCompleted
      teamConectivitiesCompleted
      individualConectivitiesCompleted
      socialCoinsEarned
      stressCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      spotlightsSent
      spotlightsReceived
      spotlightsCoinsEarned
      spotlightsBadgesDEIEarned
      spotlightsBadgesCSEarned
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      teamRank
      rank
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomerAnalytics = /* GraphQL */ `
  subscription OnCreateCustomerAnalytics {
    onCreateCustomerAnalytics {
      id
      customerID
      name
      initials
      employeesTotal
      teamsTotal
      largestTeam
      smallestTeam
      totalConectivitiesCompletedByUsers
      maxConectivitiesCompletedByUsers
      minConectivitiesCompletedByUsers
      avgConectivitiesCompletedByUsers
      totalCoinsEarnedByUsers
      maxCoinsEarnedByUsers
      minCoinsEarnedByUsers
      avgCoinsEarnedByUsers
      totalDEIBadgesEarnedByUsers
      maxDEIBadgesEarnedByUsers
      minDEIBadgesEarnedByUsers
      avgDEIBadgesEarnedByUsers
      totalCSBadgesEarnedByUsers
      maxCSBadgesEarnedByUsers
      minCSBadgesEarnedByUsers
      avgCSBadgesEarnedByUsers
      totalSocialConectivitiesCompletedByUsers
      maxSocialConectivitiesCompletedByUsers
      minSocialConectivitiesCompletedByUsers
      avgSocialConectivitiesCompletedByUsers
      totalStressConectivitiesCompletedByUsers
      maxStressConectivitiesCompletedByUsers
      minStressConectivitiesCompletedByUsers
      avgStressConectivitiesCompletedByUsers
      totalTeamConectivitiesCompletedByUsers
      maxTeamConectivitiesCompletedByUsers
      minTeamConectivitiesCompletedByUsers
      avgTeamConectivitiesCompletedByUsers
      totalIndividualConectivitiesCompletedByUsers
      maxIndividualConectivitiesCompletedByUsers
      minIndividualConectivitiesCompletedByUsers
      avgIndividualConectivitiesCompletedByUsers
      maxRelativeConectivityScoreByUsers
      minRelativeConectivityScoreByUsers
      avgRelativeConectivityScoreByUsers
      balanceStreakCount
      balanceStreakCountMax
      balanceStreakStartDateEarliest
      balanceStreakMaxUsers
      socialStreakCount
      socialStreakCountMax
      socialStreakStartDateEarliest
      socialStreakMaxUsers
      teamStreakCount
      teamStreakCountMax
      teamStreakStartDateEarliest
      teamStreakMaxUsers
      growthStreakCount
      growthStreakCountMax
      growthStreakStartDateEarliest
      growthStreakMaxUsers
      totalConectivitiesCompletedByTeams
      maxConectivitiesCompletedByTeams
      minConectivitiesCompletedByTeams
      avgConectivitiesCompletedByTeams
      totalCoinsEarnedByTeams
      maxCoinsEarnedByTeams
      minCoinsEarnedByTeams
      avgCoinsEarnedByTeams
      totalDEIBadgesEarnedByTeams
      maxDEIBadgesEarnedByTeams
      minDEIBadgesEarnedByTeams
      avgDEIBadgesEarnedByTeams
      totalCSBadgesEarnedByTeams
      maxCSBadgesEarnedByTeams
      minCSBadgesEarnedByTeams
      avgCSBadgesEarnedByTeams
      totalSocialConectivitiesCompletedByTeams
      maxSocialConectivitiesCompletedByTeams
      minSocialConectivitiesCompletedByTeams
      avgSocialConectivitiesCompletedByTeams
      totalStressConectivitiesCompletedByTeams
      maxStressConectivitiesCompletedByTeams
      minStressConectivitiesCompletedByTeams
      avgStressConectivitiesCompletedByTeams
      totalTeamConectivitiesCompletedByTeams
      maxTeamConectivitiesCompletedByTeams
      minTeamConectivitiesCompletedByTeams
      avgTeamConectivitiesCompletedByTeams
      totalIndividualConectivitiesCompletedByTeams
      maxIndividualConectivitiesCompletedByTeams
      minIndividualConectivitiesCompletedByTeams
      avgIndividualConectivitiesCompletedByTeams
      maxRelativeConectivityScoreByTeams
      minRelativeConectivityScoreByTeams
      avgRelativeConectivityScoreByTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      customerRank
      rank
      spotlightsSentTotal
      spotlightsReceivedTotal
      spotlightsCoinsEarnedTotal
      spotlightsBadgesDEIEarnedTotal
      spotlightsBadgesCSEarnedTotal
      leastSpotlightsReceived
      mostSpotlightsReceived
      leastSpotlightsSent
      mostSpotlightsSent
      avgSpotlightsSent
      avgSpotlightsReceived
      employeesRecognized
      employeesUnrecognized
      percentEmployeesRecognized
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      avgSpotlightsSentDay
      avgSpotlightsReceivedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      avgSpotlightsSentWeek
      avgSpotlightsReceivedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      avgSpotlightsSentMonth
      avgSpotlightsReceivedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      avgSpotlightsSentQuarter
      avgSpotlightsReceivedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      avgSpotlightsSentYear
      avgSpotlightsReceivedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      avgSpotlightsSentOneWeek
      avgSpotlightsReceivedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      avgSpotlightsSentOneMonth
      avgSpotlightsReceivedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      avgSpotlightsSentTwoMonths
      avgSpotlightsReceivedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      avgSpotlightsSentOneYear
      avgSpotlightsReceivedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomerAnalytics = /* GraphQL */ `
  subscription OnUpdateCustomerAnalytics {
    onUpdateCustomerAnalytics {
      id
      customerID
      name
      initials
      employeesTotal
      teamsTotal
      largestTeam
      smallestTeam
      totalConectivitiesCompletedByUsers
      maxConectivitiesCompletedByUsers
      minConectivitiesCompletedByUsers
      avgConectivitiesCompletedByUsers
      totalCoinsEarnedByUsers
      maxCoinsEarnedByUsers
      minCoinsEarnedByUsers
      avgCoinsEarnedByUsers
      totalDEIBadgesEarnedByUsers
      maxDEIBadgesEarnedByUsers
      minDEIBadgesEarnedByUsers
      avgDEIBadgesEarnedByUsers
      totalCSBadgesEarnedByUsers
      maxCSBadgesEarnedByUsers
      minCSBadgesEarnedByUsers
      avgCSBadgesEarnedByUsers
      totalSocialConectivitiesCompletedByUsers
      maxSocialConectivitiesCompletedByUsers
      minSocialConectivitiesCompletedByUsers
      avgSocialConectivitiesCompletedByUsers
      totalStressConectivitiesCompletedByUsers
      maxStressConectivitiesCompletedByUsers
      minStressConectivitiesCompletedByUsers
      avgStressConectivitiesCompletedByUsers
      totalTeamConectivitiesCompletedByUsers
      maxTeamConectivitiesCompletedByUsers
      minTeamConectivitiesCompletedByUsers
      avgTeamConectivitiesCompletedByUsers
      totalIndividualConectivitiesCompletedByUsers
      maxIndividualConectivitiesCompletedByUsers
      minIndividualConectivitiesCompletedByUsers
      avgIndividualConectivitiesCompletedByUsers
      maxRelativeConectivityScoreByUsers
      minRelativeConectivityScoreByUsers
      avgRelativeConectivityScoreByUsers
      balanceStreakCount
      balanceStreakCountMax
      balanceStreakStartDateEarliest
      balanceStreakMaxUsers
      socialStreakCount
      socialStreakCountMax
      socialStreakStartDateEarliest
      socialStreakMaxUsers
      teamStreakCount
      teamStreakCountMax
      teamStreakStartDateEarliest
      teamStreakMaxUsers
      growthStreakCount
      growthStreakCountMax
      growthStreakStartDateEarliest
      growthStreakMaxUsers
      totalConectivitiesCompletedByTeams
      maxConectivitiesCompletedByTeams
      minConectivitiesCompletedByTeams
      avgConectivitiesCompletedByTeams
      totalCoinsEarnedByTeams
      maxCoinsEarnedByTeams
      minCoinsEarnedByTeams
      avgCoinsEarnedByTeams
      totalDEIBadgesEarnedByTeams
      maxDEIBadgesEarnedByTeams
      minDEIBadgesEarnedByTeams
      avgDEIBadgesEarnedByTeams
      totalCSBadgesEarnedByTeams
      maxCSBadgesEarnedByTeams
      minCSBadgesEarnedByTeams
      avgCSBadgesEarnedByTeams
      totalSocialConectivitiesCompletedByTeams
      maxSocialConectivitiesCompletedByTeams
      minSocialConectivitiesCompletedByTeams
      avgSocialConectivitiesCompletedByTeams
      totalStressConectivitiesCompletedByTeams
      maxStressConectivitiesCompletedByTeams
      minStressConectivitiesCompletedByTeams
      avgStressConectivitiesCompletedByTeams
      totalTeamConectivitiesCompletedByTeams
      maxTeamConectivitiesCompletedByTeams
      minTeamConectivitiesCompletedByTeams
      avgTeamConectivitiesCompletedByTeams
      totalIndividualConectivitiesCompletedByTeams
      maxIndividualConectivitiesCompletedByTeams
      minIndividualConectivitiesCompletedByTeams
      avgIndividualConectivitiesCompletedByTeams
      maxRelativeConectivityScoreByTeams
      minRelativeConectivityScoreByTeams
      avgRelativeConectivityScoreByTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      customerRank
      rank
      spotlightsSentTotal
      spotlightsReceivedTotal
      spotlightsCoinsEarnedTotal
      spotlightsBadgesDEIEarnedTotal
      spotlightsBadgesCSEarnedTotal
      leastSpotlightsReceived
      mostSpotlightsReceived
      leastSpotlightsSent
      mostSpotlightsSent
      avgSpotlightsSent
      avgSpotlightsReceived
      employeesRecognized
      employeesUnrecognized
      percentEmployeesRecognized
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      avgSpotlightsSentDay
      avgSpotlightsReceivedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      avgSpotlightsSentWeek
      avgSpotlightsReceivedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      avgSpotlightsSentMonth
      avgSpotlightsReceivedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      avgSpotlightsSentQuarter
      avgSpotlightsReceivedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      avgSpotlightsSentYear
      avgSpotlightsReceivedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      avgSpotlightsSentOneWeek
      avgSpotlightsReceivedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      avgSpotlightsSentOneMonth
      avgSpotlightsReceivedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      avgSpotlightsSentTwoMonths
      avgSpotlightsReceivedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      avgSpotlightsSentOneYear
      avgSpotlightsReceivedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomerAnalytics = /* GraphQL */ `
  subscription OnDeleteCustomerAnalytics {
    onDeleteCustomerAnalytics {
      id
      customerID
      name
      initials
      employeesTotal
      teamsTotal
      largestTeam
      smallestTeam
      totalConectivitiesCompletedByUsers
      maxConectivitiesCompletedByUsers
      minConectivitiesCompletedByUsers
      avgConectivitiesCompletedByUsers
      totalCoinsEarnedByUsers
      maxCoinsEarnedByUsers
      minCoinsEarnedByUsers
      avgCoinsEarnedByUsers
      totalDEIBadgesEarnedByUsers
      maxDEIBadgesEarnedByUsers
      minDEIBadgesEarnedByUsers
      avgDEIBadgesEarnedByUsers
      totalCSBadgesEarnedByUsers
      maxCSBadgesEarnedByUsers
      minCSBadgesEarnedByUsers
      avgCSBadgesEarnedByUsers
      totalSocialConectivitiesCompletedByUsers
      maxSocialConectivitiesCompletedByUsers
      minSocialConectivitiesCompletedByUsers
      avgSocialConectivitiesCompletedByUsers
      totalStressConectivitiesCompletedByUsers
      maxStressConectivitiesCompletedByUsers
      minStressConectivitiesCompletedByUsers
      avgStressConectivitiesCompletedByUsers
      totalTeamConectivitiesCompletedByUsers
      maxTeamConectivitiesCompletedByUsers
      minTeamConectivitiesCompletedByUsers
      avgTeamConectivitiesCompletedByUsers
      totalIndividualConectivitiesCompletedByUsers
      maxIndividualConectivitiesCompletedByUsers
      minIndividualConectivitiesCompletedByUsers
      avgIndividualConectivitiesCompletedByUsers
      maxRelativeConectivityScoreByUsers
      minRelativeConectivityScoreByUsers
      avgRelativeConectivityScoreByUsers
      balanceStreakCount
      balanceStreakCountMax
      balanceStreakStartDateEarliest
      balanceStreakMaxUsers
      socialStreakCount
      socialStreakCountMax
      socialStreakStartDateEarliest
      socialStreakMaxUsers
      teamStreakCount
      teamStreakCountMax
      teamStreakStartDateEarliest
      teamStreakMaxUsers
      growthStreakCount
      growthStreakCountMax
      growthStreakStartDateEarliest
      growthStreakMaxUsers
      totalConectivitiesCompletedByTeams
      maxConectivitiesCompletedByTeams
      minConectivitiesCompletedByTeams
      avgConectivitiesCompletedByTeams
      totalCoinsEarnedByTeams
      maxCoinsEarnedByTeams
      minCoinsEarnedByTeams
      avgCoinsEarnedByTeams
      totalDEIBadgesEarnedByTeams
      maxDEIBadgesEarnedByTeams
      minDEIBadgesEarnedByTeams
      avgDEIBadgesEarnedByTeams
      totalCSBadgesEarnedByTeams
      maxCSBadgesEarnedByTeams
      minCSBadgesEarnedByTeams
      avgCSBadgesEarnedByTeams
      totalSocialConectivitiesCompletedByTeams
      maxSocialConectivitiesCompletedByTeams
      minSocialConectivitiesCompletedByTeams
      avgSocialConectivitiesCompletedByTeams
      totalStressConectivitiesCompletedByTeams
      maxStressConectivitiesCompletedByTeams
      minStressConectivitiesCompletedByTeams
      avgStressConectivitiesCompletedByTeams
      totalTeamConectivitiesCompletedByTeams
      maxTeamConectivitiesCompletedByTeams
      minTeamConectivitiesCompletedByTeams
      avgTeamConectivitiesCompletedByTeams
      totalIndividualConectivitiesCompletedByTeams
      maxIndividualConectivitiesCompletedByTeams
      minIndividualConectivitiesCompletedByTeams
      avgIndividualConectivitiesCompletedByTeams
      maxRelativeConectivityScoreByTeams
      minRelativeConectivityScoreByTeams
      avgRelativeConectivityScoreByTeams
      stressComponent
      socialComponent
      teamComponent
      individualComponent
      badgesCSComponent
      badgesDEIComponent
      relativeConectivityScore
      customerRank
      rank
      spotlightsSentTotal
      spotlightsReceivedTotal
      spotlightsCoinsEarnedTotal
      spotlightsBadgesDEIEarnedTotal
      spotlightsBadgesCSEarnedTotal
      leastSpotlightsReceived
      mostSpotlightsReceived
      leastSpotlightsSent
      mostSpotlightsSent
      avgSpotlightsSent
      avgSpotlightsReceived
      employeesRecognized
      employeesUnrecognized
      percentEmployeesRecognized
      conectivitiesDay
      coinsReceivedDay
      badgesDEIReceivedDay
      badgesCSReceivedDay
      purchasesDay
      coinsSpentDay
      badgesDEISpentDay
      badgesCSSpentDay
      socialPeriodCoinsEarnedDay
      socialPeriodConectivitiesCompleteDay
      stressPeriodCoinsEarnedDay
      stressPeriodConectivitiesCompleteDay
      teamPeriodCoinsEarnedDay
      teamPeriodConectivitiesCompleteDay
      individualPeriodCoinsEarnedDay
      individualPeriodConectivitiesCompleteDay
      spotlightsSentDay
      spotlightsReceivedDay
      spotlightsCoinsEarnedDay
      spotlightsBadgesDEIEarnedDay
      spotlightsBadgesCSEarnedDay
      avgSpotlightsSentDay
      avgSpotlightsReceivedDay
      conectivitiesWeek
      coinsReceivedWeek
      badgesDEIReceivedWeek
      badgesCSReceivedWeek
      purchasesWeek
      coinsSpentWeek
      badgesDEISpentWeek
      badgesCSSpentWeek
      socialPeriodCoinsEarnedWeek
      socialPeriodConectivitiesCompleteWeek
      stressPeriodCoinsEarnedWeek
      stressPeriodConectivitiesCompleteWeek
      teamPeriodCoinsEarnedWeek
      teamPeriodConectivitiesCompleteWeek
      individualPeriodCoinsEarnedWeek
      individualPeriodConectivitiesCompleteWeek
      spotlightsSentWeek
      spotlightsReceivedWeek
      spotlightsCoinsEarnedWeek
      spotlightsBadgesDEIEarnedWeek
      spotlightsBadgesCSEarnedWeek
      avgSpotlightsSentWeek
      avgSpotlightsReceivedWeek
      conectivitiesMonth
      coinsReceivedMonth
      badgesDEIReceivedMonth
      badgesCSReceivedMonth
      purchasesMonth
      coinsSpentMonth
      badgesDEISpentMonth
      badgesCSSpentMonth
      socialPeriodCoinsEarnedMonth
      socialPeriodConectivitiesCompleteMonth
      stressPeriodCoinsEarnedMonth
      stressPeriodConectivitiesCompleteMonth
      teamPeriodCoinsEarnedMonth
      teamPeriodConectivitiesCompleteMonth
      individualPeriodCoinsEarnedMonth
      individualPeriodConectivitiesCompleteMonth
      spotlightsSentMonth
      spotlightsReceivedMonth
      spotlightsCoinsEarnedMonth
      spotlightsBadgesDEIEarnedMonth
      spotlightsBadgesCSEarnedMonth
      avgSpotlightsSentMonth
      avgSpotlightsReceivedMonth
      conectivitiesQuarter
      coinsReceivedQuarter
      badgesDEIReceivedQuarter
      badgesCSReceivedQuarter
      purchasesQuarter
      coinsSpentQuarter
      badgesDEISpentQuarter
      badgesCSSpentQuarter
      socialPeriodCoinsEarnedQuarter
      socialPeriodConectivitiesCompleteQuarter
      stressPeriodCoinsEarnedQuarter
      stressPeriodConectivitiesCompleteQuarter
      teamPeriodCoinsEarnedQuarter
      teamPeriodConectivitiesCompleteQuarter
      individualPeriodCoinsEarnedQuarter
      individualPeriodConectivitiesCompleteQuarter
      spotlightsSentQuarter
      spotlightsReceivedQuarter
      spotlightsCoinsEarnedQuarter
      spotlightsBadgesDEIEarnedQuarter
      spotlightsBadgesCSEarnedQuarter
      avgSpotlightsSentQuarter
      avgSpotlightsReceivedQuarter
      conectivitiesYear
      coinsReceivedYear
      badgesDEIReceivedYear
      badgesCSReceivedYear
      purchasesYear
      coinsSpentYear
      badgesDEISpentYear
      badgesCSSpentYear
      socialPeriodCoinsEarnedYear
      socialPeriodConectivitiesCompleteYear
      stressPeriodCoinsEarnedYear
      stressPeriodConectivitiesCompleteYear
      teamPeriodCoinsEarnedYear
      teamPeriodConectivitiesCompleteYear
      individualPeriodCoinsEarnedYear
      individualPeriodConectivitiesCompleteYear
      spotlightsSentYear
      spotlightsReceivedYear
      spotlightsCoinsEarnedYear
      spotlightsBadgesDEIEarnedYear
      spotlightsBadgesCSEarnedYear
      avgSpotlightsSentYear
      avgSpotlightsReceivedYear
      conectivitiesOneWeek
      coinsReceivedOneWeek
      badgesDEIReceivedOneWeek
      badgesCSReceivedOneWeek
      purchasesOneWeek
      coinsSpentOneWeek
      badgesDEISpentOneWeek
      badgesCSSpentOneWeek
      socialPeriodCoinsEarnedOneWeek
      socialPeriodConectivitiesCompleteOneWeek
      stressPeriodCoinsEarnedOneWeek
      stressPeriodConectivitiesCompleteOneWeek
      teamPeriodCoinsEarnedOneWeek
      teamPeriodConectivitiesCompleteOneWeek
      individualPeriodCoinsEarnedOneWeek
      individualPeriodConectivitiesCompleteOneWeek
      spotlightsSentOneWeek
      spotlightsReceivedOneWeek
      spotlightsCoinsEarnedOneWeek
      spotlightsBadgesDEIEarnedOneWeek
      spotlightsBadgesCSEarnedOneWeek
      avgSpotlightsSentOneWeek
      avgSpotlightsReceivedOneWeek
      conectivitiesOneMonth
      coinsReceivedOneMonth
      badgesDEIReceivedOneMonth
      badgesCSReceivedOneMonth
      purchasesOneMonth
      coinsSpentOneMonth
      badgesDEISpentOneMonth
      badgesCSSpentOneMonth
      socialPeriodCoinsEarnedOneMonth
      socialPeriodConectivitiesCompleteOneMonth
      stressPeriodCoinsEarnedOneMonth
      stressPeriodConectivitiesCompleteOneMonth
      teamPeriodCoinsEarnedOneMonth
      teamPeriodConectivitiesCompleteOneMonth
      individualPeriodCoinsEarnedOneMonth
      individualPeriodConectivitiesCompleteOneMonth
      spotlightsSentOneMonth
      spotlightsReceivedOneMonth
      spotlightsCoinsEarnedOneMonth
      spotlightsBadgesDEIEarnedOneMonth
      spotlightsBadgesCSEarnedOneMonth
      avgSpotlightsSentOneMonth
      avgSpotlightsReceivedOneMonth
      conectivitiesTwoMonths
      coinsReceivedTwoMonths
      badgesDEIReceivedTwoMonths
      badgesCSReceivedTwoMonths
      purchasesTwoMonths
      coinsSpentTwoMonths
      badgesDEISpentTwoMonths
      badgesCSSpentTwoMonths
      socialPeriodCoinsEarnedTwoMonths
      socialPeriodConectivitiesCompleteTwoMonths
      stressPeriodCoinsEarnedTwoMonths
      stressPeriodConectivitiesCompleteTwoMonths
      teamPeriodCoinsEarnedTwoMonths
      teamPeriodConectivitiesCompleteTwoMonths
      individualPeriodCoinsEarnedTwoMonths
      individualPeriodConectivitiesCompleteTwoMonths
      spotlightsSentTwoMonths
      spotlightsReceivedTwoMonths
      spotlightsCoinsEarnedTwoMonths
      spotlightsBadgesDEIEarnedTwoMonths
      spotlightsBadgesCSEarnedTwoMonths
      avgSpotlightsSentTwoMonths
      avgSpotlightsReceivedTwoMonths
      conectivitiesOneYear
      coinsReceivedOneYear
      badgesDEIReceivedOneYear
      badgesCSReceivedOneYear
      purchasesOneYear
      coinsSpentOneYear
      badgesDEISpentOneYear
      badgesCSSpentOneYear
      socialPeriodCoinsEarnedOneYear
      socialPeriodConectivitiesCompleteOneYear
      stressPeriodCoinsEarnedOneYear
      stressPeriodConectivitiesCompleteOneYear
      teamPeriodCoinsEarnedOneYear
      teamPeriodConectivitiesCompleteOneYear
      individualPeriodCoinsEarnedOneYear
      individualPeriodConectivitiesCompleteOneYear
      spotlightsSentOneYear
      spotlightsReceivedOneYear
      spotlightsCoinsEarnedOneYear
      spotlightsBadgesDEIEarnedOneYear
      spotlightsBadgesCSEarnedOneYear
      avgSpotlightsSentOneYear
      avgSpotlightsReceivedOneYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGraphNode = /* GraphQL */ `
  subscription OnCreateGraphNode {
    onCreateGraphNode {
      id
      userID
      customerID
      userInitials
      label
      color
      edgeCount
      title
      shape
      borderWidth
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGraphNode = /* GraphQL */ `
  subscription OnUpdateGraphNode {
    onUpdateGraphNode {
      id
      userID
      customerID
      userInitials
      label
      color
      edgeCount
      title
      shape
      borderWidth
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGraphNode = /* GraphQL */ `
  subscription OnDeleteGraphNode {
    onDeleteGraphNode {
      id
      userID
      customerID
      userInitials
      label
      color
      edgeCount
      title
      shape
      borderWidth
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGraphEdge = /* GraphQL */ `
  subscription OnCreateGraphEdge {
    onCreateGraphEdge {
      id
      customerID
      graphID
      from
      to
      label
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGraphEdge = /* GraphQL */ `
  subscription OnUpdateGraphEdge {
    onUpdateGraphEdge {
      id
      customerID
      graphID
      from
      to
      label
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGraphEdge = /* GraphQL */ `
  subscription OnDeleteGraphEdge {
    onDeleteGraphEdge {
      id
      customerID
      graphID
      from
      to
      label
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGraph = /* GraphQL */ `
  subscription OnCreateGraph {
    onCreateGraph {
      id
      customerID
      period
      periodStart
      periodEnd
      category
      maxEdgeCount
      numEdges
      numNodes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGraph = /* GraphQL */ `
  subscription OnUpdateGraph {
    onUpdateGraph {
      id
      customerID
      period
      periodStart
      periodEnd
      category
      maxEdgeCount
      numEdges
      numNodes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGraph = /* GraphQL */ `
  subscription OnDeleteGraph {
    onDeleteGraph {
      id
      customerID
      period
      periodStart
      periodEnd
      category
      maxEdgeCount
      numEdges
      numNodes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGraphTimeSeriesValue = /* GraphQL */ `
  subscription OnCreateGraphTimeSeriesValue {
    onCreateGraphTimeSeriesValue {
      id
      customerID
      graphID
      period
      name
      conectivities
      balance
      social
      team
      personal
      amount
      socialCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      balanceCoinsEarned
      DEI_badges
      CS_badges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGraphTimeSeriesValue = /* GraphQL */ `
  subscription OnUpdateGraphTimeSeriesValue {
    onUpdateGraphTimeSeriesValue {
      id
      customerID
      graphID
      period
      name
      conectivities
      balance
      social
      team
      personal
      amount
      socialCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      balanceCoinsEarned
      DEI_badges
      CS_badges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGraphTimeSeriesValue = /* GraphQL */ `
  subscription OnDeleteGraphTimeSeriesValue {
    onDeleteGraphTimeSeriesValue {
      id
      customerID
      graphID
      period
      name
      conectivities
      balance
      social
      team
      personal
      amount
      socialCoinsEarned
      teamCoinsEarned
      individualCoinsEarned
      balanceCoinsEarned
      DEI_badges
      CS_badges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGraphTimeSeries = /* GraphQL */ `
  subscription OnCreateGraphTimeSeries {
    onCreateGraphTimeSeries {
      id
      customerID
      period
      periodStart
      periodEnd
      createdAt
      updatedAt
      values {
        items {
          id
          customerID
          graphID
          period
          name
          conectivities
          balance
          social
          team
          personal
          amount
          socialCoinsEarned
          teamCoinsEarned
          individualCoinsEarned
          balanceCoinsEarned
          DEI_badges
          CS_badges
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateGraphTimeSeries = /* GraphQL */ `
  subscription OnUpdateGraphTimeSeries {
    onUpdateGraphTimeSeries {
      id
      customerID
      period
      periodStart
      periodEnd
      createdAt
      updatedAt
      values {
        items {
          id
          customerID
          graphID
          period
          name
          conectivities
          balance
          social
          team
          personal
          amount
          socialCoinsEarned
          teamCoinsEarned
          individualCoinsEarned
          balanceCoinsEarned
          DEI_badges
          CS_badges
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteGraphTimeSeries = /* GraphQL */ `
  subscription OnDeleteGraphTimeSeries {
    onDeleteGraphTimeSeries {
      id
      customerID
      period
      periodStart
      periodEnd
      createdAt
      updatedAt
      values {
        items {
          id
          customerID
          graphID
          period
          name
          conectivities
          balance
          social
          team
          personal
          amount
          socialCoinsEarned
          teamCoinsEarned
          individualCoinsEarned
          balanceCoinsEarned
          DEI_badges
          CS_badges
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEngagementValue = /* GraphQL */ `
  subscription OnCreateEngagementValue {
    onCreateEngagementValue {
      id
      customerID
      userID
      engagementSignalID
      momentum
      alert
      alertTimeStamp
      sequenceNumber
      conectivityCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEngagementValue = /* GraphQL */ `
  subscription OnUpdateEngagementValue {
    onUpdateEngagementValue {
      id
      customerID
      userID
      engagementSignalID
      momentum
      alert
      alertTimeStamp
      sequenceNumber
      conectivityCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEngagementValue = /* GraphQL */ `
  subscription OnDeleteEngagementValue {
    onDeleteEngagementValue {
      id
      customerID
      userID
      engagementSignalID
      momentum
      alert
      alertTimeStamp
      sequenceNumber
      conectivityCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserEngagementSignal = /* GraphQL */ `
  subscription OnCreateUserEngagementSignal {
    onCreateUserEngagementSignal {
      id
      customerID
      userID
      category
      periodStart
      periodEnd
      timeStep
      nextSequenceNumber
      createdAt
      updatedAt
      engagementValues {
        items {
          id
          customerID
          userID
          engagementSignalID
          momentum
          alert
          alertTimeStamp
          sequenceNumber
          conectivityCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUserEngagementSignal = /* GraphQL */ `
  subscription OnUpdateUserEngagementSignal {
    onUpdateUserEngagementSignal {
      id
      customerID
      userID
      category
      periodStart
      periodEnd
      timeStep
      nextSequenceNumber
      createdAt
      updatedAt
      engagementValues {
        items {
          id
          customerID
          userID
          engagementSignalID
          momentum
          alert
          alertTimeStamp
          sequenceNumber
          conectivityCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUserEngagementSignal = /* GraphQL */ `
  subscription OnDeleteUserEngagementSignal {
    onDeleteUserEngagementSignal {
      id
      customerID
      userID
      category
      periodStart
      periodEnd
      timeStep
      nextSequenceNumber
      createdAt
      updatedAt
      engagementValues {
        items {
          id
          customerID
          userID
          engagementSignalID
          momentum
          alert
          alertTimeStamp
          sequenceNumber
          conectivityCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCalendarToken = /* GraphQL */ `
  subscription OnCreateCalendarToken {
    onCreateCalendarToken {
      id
      customerID
      userID
      expiration
      accessToken
      idToken
      refreshToken
      scope
      tokenType
      calendarType
      jwt
      tokenCache
      calendarUserID
      calendarUserName
      subscriptionID
      calendarID
      deltaLink
      deltaQueryEndDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCalendarToken = /* GraphQL */ `
  subscription OnUpdateCalendarToken {
    onUpdateCalendarToken {
      id
      customerID
      userID
      expiration
      accessToken
      idToken
      refreshToken
      scope
      tokenType
      calendarType
      jwt
      tokenCache
      calendarUserID
      calendarUserName
      subscriptionID
      calendarID
      deltaLink
      deltaQueryEndDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCalendarToken = /* GraphQL */ `
  subscription OnDeleteCalendarToken {
    onDeleteCalendarToken {
      id
      customerID
      userID
      expiration
      accessToken
      idToken
      refreshToken
      scope
      tokenType
      calendarType
      jwt
      tokenCache
      calendarUserID
      calendarUserName
      subscriptionID
      calendarID
      deltaLink
      deltaQueryEndDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSpecialEvent = /* GraphQL */ `
  subscription OnCreateSpecialEvent {
    onCreateSpecialEvent {
      id
      customerID
      eventStatus
      title
      description
      eventStartDateTime
      eventEndDateTime
      announcement
      announcementStartDateTime
      announcementEndDateTime
      notification
      forAdminOnly
      showCountdown
      rewardMultiplier
      keywords
      minParticipantsRequired
      applyToConectivities
      applyToSpotlights
      requireBadgesDEI
      requireBadgesCS
      categoryID
      isCustomerSpecific
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSpecialEvent = /* GraphQL */ `
  subscription OnUpdateSpecialEvent {
    onUpdateSpecialEvent {
      id
      customerID
      eventStatus
      title
      description
      eventStartDateTime
      eventEndDateTime
      announcement
      announcementStartDateTime
      announcementEndDateTime
      notification
      forAdminOnly
      showCountdown
      rewardMultiplier
      keywords
      minParticipantsRequired
      applyToConectivities
      applyToSpotlights
      requireBadgesDEI
      requireBadgesCS
      categoryID
      isCustomerSpecific
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSpecialEvent = /* GraphQL */ `
  subscription OnDeleteSpecialEvent {
    onDeleteSpecialEvent {
      id
      customerID
      eventStatus
      title
      description
      eventStartDateTime
      eventEndDateTime
      announcement
      announcementStartDateTime
      announcementEndDateTime
      notification
      forAdminOnly
      showCountdown
      rewardMultiplier
      keywords
      minParticipantsRequired
      applyToConectivities
      applyToSpotlights
      requireBadgesDEI
      requireBadgesCS
      categoryID
      isCustomerSpecific
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChallenge = /* GraphQL */ `
  subscription OnCreateChallenge {
    onCreateChallenge {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateChallenge = /* GraphQL */ `
  subscription OnUpdateChallenge {
    onUpdateChallenge {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteChallenge = /* GraphQL */ `
  subscription OnDeleteChallenge {
    onDeleteChallenge {
      id
      customerID
      userID
      captains
      title
      description
      inputPeriod
      inputLabel
      inputDataType
      inputEval
      isAnonymous
      isViewableCompanyWide
      isTeamCompetition
      giveRewards
      useGoal
      goal
      rewards
      adminInputOnly
      adminConfirmationReq
      launchRuleID
      challengeScope
      customers
      options
      challengeStatus
      createdAt
      updatedAt
      launchRule {
        id
        customerID
        conectivityID
        usersToInvite
        teamsToInvite
        senderID
        message
        messageGIF
        senderAvatarUrl
        senderAvatarName
        senderAvatarInitials
        eventStartDateTime
        eventEndDateTime
        allDayEvent
        launchUntilDate
        launchUntilCount
        launchRepeatOption
        launchRepeatWeeklyOptions
        launchRepeatMonthlyOptions
        launchRepeatMonthlyOptions_2
        launchRepeatMonthlyOptions_3
        launchMonthOption1Checked
        launchMonthOption2Checked
        calendarUid
        calendarEventID
        parentRecurringEventId
        eventInstanceNumber
        originalLaunchStartDate
        nextInstanceNumber
        isMentorMeeting
        isSponsorMeeting
        messagesReadAtRecords {
          messagesReadAt
          userID
          __typename
        }
        rsvpRecords {
          userID
          rsvpStatus
          launchRuleID
          launchRuleInstanceIndex
          __typename
        }
        challengeID
        options
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChallengeData = /* GraphQL */ `
  subscription OnCreateChallengeData {
    onCreateChallengeData {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChallengeData = /* GraphQL */ `
  subscription OnUpdateChallengeData {
    onUpdateChallengeData {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChallengeData = /* GraphQL */ `
  subscription OnDeleteChallengeData {
    onDeleteChallengeData {
      id
      customerID
      userID
      teamID
      activityDate
      leaderboardID
      leaderboardSpotID
      challengeID
      challengeDataValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSlackToken = /* GraphQL */ `
  subscription OnCreateSlackToken {
    onCreateSlackToken {
      id
      customerID
      userID
      slack_team_id
      accessToken
      slack_enterprise_id
      slack_user_id
      slack_user_name
      slack_email
      slack_team_name
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSlackToken = /* GraphQL */ `
  subscription OnUpdateSlackToken {
    onUpdateSlackToken {
      id
      customerID
      userID
      slack_team_id
      accessToken
      slack_enterprise_id
      slack_user_id
      slack_user_name
      slack_email
      slack_team_name
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSlackToken = /* GraphQL */ `
  subscription OnDeleteSlackToken {
    onDeleteSlackToken {
      id
      customerID
      userID
      slack_team_id
      accessToken
      slack_enterprise_id
      slack_user_id
      slack_user_name
      slack_email
      slack_team_name
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSlackBotToken = /* GraphQL */ `
  subscription OnCreateSlackBotToken {
    onCreateSlackBotToken {
      id
      customerID
      slack_team_id
      accessToken
      bot_user_id
      slack_team_name
      slack_enterprise_id
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSlackBotToken = /* GraphQL */ `
  subscription OnUpdateSlackBotToken {
    onUpdateSlackBotToken {
      id
      customerID
      slack_team_id
      accessToken
      bot_user_id
      slack_team_name
      slack_enterprise_id
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSlackBotToken = /* GraphQL */ `
  subscription OnDeleteSlackBotToken {
    onDeleteSlackBotToken {
      id
      customerID
      slack_team_id
      accessToken
      bot_user_id
      slack_team_name
      slack_enterprise_id
      slack_channel
      slack_channel_id
      slack_channel_url
      slack_configuration_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSlackUser = /* GraphQL */ `
  subscription OnCreateSlackUser {
    onCreateSlackUser {
      id
      customerID
      userID
      slack_team_id
      slack_user_id
      slack_user_name
      slack_email
      slack_display_name
      slack_real_name
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSlackUser = /* GraphQL */ `
  subscription OnUpdateSlackUser {
    onUpdateSlackUser {
      id
      customerID
      userID
      slack_team_id
      slack_user_id
      slack_user_name
      slack_email
      slack_display_name
      slack_real_name
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSlackUser = /* GraphQL */ `
  subscription OnDeleteSlackUser {
    onDeleteSlackUser {
      id
      customerID
      userID
      slack_team_id
      slack_user_id
      slack_user_name
      slack_email
      slack_display_name
      slack_real_name
      messages_tab_channel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMsftTeamsInstallation = /* GraphQL */ `
  subscription OnCreateMsftTeamsInstallation {
    onCreateMsftTeamsInstallation {
      id
      customerID
      userID
      teams_user_id
      tenant_id
      conversation_type
      conversation_id
      msft_team_id
      msft_team_name
      channel_name
      serviceUrl
      conversationReference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMsftTeamsInstallation = /* GraphQL */ `
  subscription OnUpdateMsftTeamsInstallation {
    onUpdateMsftTeamsInstallation {
      id
      customerID
      userID
      teams_user_id
      tenant_id
      conversation_type
      conversation_id
      msft_team_id
      msft_team_name
      channel_name
      serviceUrl
      conversationReference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMsftTeamsInstallation = /* GraphQL */ `
  subscription OnDeleteMsftTeamsInstallation {
    onDeleteMsftTeamsInstallation {
      id
      customerID
      userID
      teams_user_id
      tenant_id
      conversation_type
      conversation_id
      msft_team_id
      msft_team_name
      channel_name
      serviceUrl
      conversationReference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      createdAt
      contactName
      title
      company
      website
      numEmployees
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      timeZoneCode
      message
      identifiedBalance
      identifiedTeam
      identifiedSocial
      identifiedGrowth
      identifiedDEI
      identifiedCS
      surveyData
      hasOptedOut
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      createdAt
      contactName
      title
      company
      website
      numEmployees
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      timeZoneCode
      message
      identifiedBalance
      identifiedTeam
      identifiedSocial
      identifiedGrowth
      identifiedDEI
      identifiedCS
      surveyData
      hasOptedOut
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      createdAt
      contactName
      title
      company
      website
      numEmployees
      address1
      address2
      city
      state
      postalCode
      country
      phoneNumber
      email
      timeZoneCode
      message
      identifiedBalance
      identifiedTeam
      identifiedSocial
      identifiedGrowth
      identifiedDEI
      identifiedCS
      surveyData
      hasOptedOut
      updatedAt
      __typename
    }
  }
`;
export const onCreateContactSurvey = /* GraphQL */ `
  subscription OnCreateContactSurvey {
    onCreateContactSurvey {
      id
      isActive
      startDate
      startEndDate
      surveyName
      title
      totalVotes
      voteTotal1
      voteTotal2
      voteTotal3
      voteTotal4
      voteTotal5
      voteTotal6
      surveyData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContactSurvey = /* GraphQL */ `
  subscription OnUpdateContactSurvey {
    onUpdateContactSurvey {
      id
      isActive
      startDate
      startEndDate
      surveyName
      title
      totalVotes
      voteTotal1
      voteTotal2
      voteTotal3
      voteTotal4
      voteTotal5
      voteTotal6
      surveyData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContactSurvey = /* GraphQL */ `
  subscription OnDeleteContactSurvey {
    onDeleteContactSurvey {
      id
      isActive
      startDate
      startEndDate
      surveyName
      title
      totalVotes
      voteTotal1
      voteTotal2
      voteTotal3
      voteTotal4
      voteTotal5
      voteTotal6
      surveyData
      createdAt
      updatedAt
      __typename
    }
  }
`;

//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  copyright - 2020, 2021
//


import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { API } from 'aws-amplify';

import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, userAnalyticsInitialState, teamAnalyticsInitialState, customerWideAnalyticsInitialState} from '../data/conectereConfigData';

//Queries and Mutations
import { getGraph, getGraphEdge, getGraphByCustomer, getGraphNodesByCustomer, getGraphEdgesByCustomer, getGraphTimeSeriesByCustomer, getGraphNodesByGraph, getGraphEdgesByGraph} from '../graphql/queries';

//Context
import { AuthContext } from './authContext';
import { CustomerContext } from './customerContext';            //Customer Authentication context
import { DisplayContext } from './displayContext';            //User Authentication Context

import moment from 'moment';

//Utils
import {fetchRecordsByPrimaryKey, queryDataTableWithPagination} from "../utils/databaseUtils";
import {comparesByUserID}  from "../utils/userAndTeamUtils";
import { setNodeAndEdgeAttributes } from "../utils/analyticsUtils";

const GraphAnalyticsContext = React.createContext();

//ANALYTICS CONTEXT
const GraphAnalyticsProvider = ({ children }) => {
		
		const currentReactPage = useLocation(); //This React reference is updated every time the URL changes

		//State data for NETWORK graph representations
		//Brute forcing this because react-graph-vis has issues when the state data changes
		//See https://github.com/crubier/react-graph-vis/issues/92
		//I ran into same issues trying to change the state data, so I built all the options and rendered the selected graph
		
		const [graphDataBuiltFlag, setGraphDataBuiltFlag] = useState(false);
		const [masterGraphData, setMasterGraphData] = useState(null); 
		const [fetchRecordsTrigger, setFetchRecordsTrigger] = useState(false);   //This state variable is set by pages of interest that need to have the DB graph records loaded; thus loaded on an as needed basis
 
		//Context
		const {  authState,  selectedCustomerOptions, currentUser,   } = useContext(AuthContext);
		const {  users, teams, } = useContext(CustomerContext);      
		const {  setShowSpinner } = useContext(DisplayContext); 

		
		// Fetch analytics records based on Auth change
		useEffect(() => {
				
				// if (DEBUG_MODE >= 2) console.log("useEffect: GRAPH ANALYTICS CONTEXT", authState, selectedCustomerOptions);
				
				//Do nothing if not yet logged in the user
				if (authState != "signedin" || selectedCustomerOptions == null) {
						setGraphDataBuiltFlag(false);
						return;
				}

				//ONLY LOAD GRAPH RECORDS (BIG) IF
				if (currentReactPage.pathname.toUpperCase().includes("/ADMINGRAPHCONECTIVITY") || 
						currentReactPage.pathname.toUpperCase().includes("/ADMINTEAMSCONECTIVITY")) {

/* SKIPPING THIS FOR NOW - NETWORK GRAPHS CALCULATED ON CLIENT SIDE AGAIN          

						loadGraphAnalytics();
*/            


				}
	
		}, [authState, selectedCustomerOptions,fetchRecordsTrigger]);      //TRIGGERED ON ANY CHANGE TO AuthState OR SelectedCustomer (SuperAdmin)


		//This function loads the analytics from the backend DB and formulates the analytics context data for the app
		async function loadGraphAnalytics () {
				
				//Exit if we have already loaded the records from DynamoDB
				if (graphDataBuiltFlag) {
						if (DEBUG_MODE >= 2) console.log("Already loaded graph records; exiting useEffect");
						return;
				}
				
				var tempGraphs = [];
				var newEdges = [];
				var customerMasterNodeArray = [];
				
				setShowSpinner(true); //Show spinners
				
				if (DEBUG_MODE >= 2) console.log("Loading Graph Analytics Records for selected customer", selectedCustomerOptions);

				try {
						
						//Get all conectivity visualization graphs for this customer
						tempGraphs =  await fetchRecordsByPrimaryKey(getGraphByCustomer, "getGraphByCustomer","customerID", selectedCustomerOptions.id);
						if (DEBUG_MODE >= 2) console.log("Loaded network visualization graphs", tempGraphs);

						//Get master set of nodes for this customer
						 customerMasterNodeArray =  await fetchRecordsByPrimaryKey(getGraphNodesByCustomer, "getGraphNodesByCustomer","customerID", selectedCustomerOptions.id);
						if (DEBUG_MODE >= 2) console.log("Loaded nodes for this customer", customerMasterNodeArray);
						
						//For each Graph, seperately fetch it's edges and stitch together a graph object due to graphQL limits
						//Process each network graph to put in the proper form, i.e. remove the ".items" from the edges and nodes; basically we are adjusting pointers here
						for (var i =0; i < tempGraphs.length; i++) {
								
								//Fetch Edges
								 newEdges =  await fetchRecordsByPrimaryKey(getGraphEdgesByGraph, "getGraphEdgesByGraph","graphID", tempGraphs[i].id);
								// if (DEBUG_MODE >= 2) console.log("Loaded edges for this graph", newEdges);

								//Update the Edges and nodes of the fetched graph; nodes are a distinct copy of the master set of nodes
								tempGraphs[i].edges = newEdges;
								tempGraphs[i].nodes = customerMasterNodeArray.map (node => {return {...node}}); //Make a DEEP copy of nodes to be used with this graph
								// customerMasterNodeArray.forEach (node => tempGraphs[i].nodes.push({...node})); //Make a DEEP copy of nodes to be used with this graph
								// tempGraphs[i].nodes = [...customerMasterNodeArray]; 
								
								//Set edge counts for each node, adjust labels and set color of each node based on the max connectivity for any given user in the particular graph
								setNodeAndEdgeAttributes (tempGraphs[i]);   
								
								// if (DEBUG_MODE >= 2) console.log("Prepared network graph object", tempGraphs[i]);
								
						}

						 //New feature - generate Team Density and Distribution analytics for team members
						calcTeamDensityAnalytics(tempGraphs);

						if (DEBUG_MODE >= 2) console.log("Updated network visualization graphs", tempGraphs);
						setMasterGraphData(tempGraphs);
				 

				} catch (err) {
						if (DEBUG_MODE >= 2) console.log("Error loading analytics", err);
				}
				
 
				setShowSpinner(false); //Hide spinners
		 
				setGraphDataBuiltFlag (true);
				

		}
		


	 function calcTeamDensityAnalytics(networkGraphs) {
			 
			 if (DEBUG_MODE >= 2) console.log("Calculating team density analytics from network graphs", networkGraphs, users, teams);

				//For each Network Graph
			 for (var k=0; k < networkGraphs.length; k++) {   
					 
				//   if (DEBUG_MODE >= 2) console.log("Processing network graph", networkGraphs[k]);
					 
					 const memberConections=[]; //Temp variable to hold the team member data for this graph
							 
					 //For each edge
					 for (var i=0; i < networkGraphs[k].edges.length; i++) {
								
								//Get the source node and To node for this edge
								const sourceNode = networkGraphs[k].nodes.find(node => node.id == networkGraphs[k].edges[i].from);
								const toNode = networkGraphs[k].nodes.find(node => node.id == networkGraphs[k].edges[i].to);

								if (sourceNode != undefined && toNode != undefined) {
										//Get a list of TEAMS where the source user and To user are both members
										const commonTeams = teams.filter (team => (team.users.items.some(join => join.userID == sourceNode.userID) && team.users.items.some(join => join.userID == toNode.userID)));
										
										// if (commonTeams.length >0) if (DEBUG_MODE >= 2) console.log("Density - common teams", sourceNode, toNode, commonTeams);
		
										//Give the source node credit on each of these common teams
										for (var l=0; l < commonTeams.length; l++)  {   
												
												// if (DEBUG_MODE >= 2) console.log("Starting loop", memberConections.length);
												
													 //Get source node user data for this team
													 const memberIndex = memberConections.findIndex(member => member.userID == sourceNode.userID && member.teamID == commonTeams[l].id); 
													 
													 
													 //Update member object
													 if (memberIndex == -1) {
															 
															 memberConections.push({
																	 userID: sourceNode.userID,
																	 userInitials: sourceNode.userInitials,
																	 teamID: commonTeams[l].id,
																	 teamName:commonTeams[l].name,
																	 conectivitiesWithTeamMembers: parseInt(networkGraphs[k].edges[i].value),  //Credit for all connections between these two team members (SOURCE/TO)
															 });
		
														// if (DEBUG_MODE >= 2) console.log("Created entry for member on team data", memberConections);
															 
													 } else {
															memberConections[memberIndex].conectivitiesWithTeamMembers += parseInt(networkGraphs[k].edges[i].value);
															
																// if (DEBUG_MODE >= 2) console.log("Updated entry for member on team data", memberConections);
		
													 }
													
										}
								} else {
										if (DEBUG_MODE >= 2) console.log("Error with edge - undefined FROM or TO", networkGraphs[k].edges[i], sourceNode, toNode);
								}
								
								// if (DEBUG_MODE >= 2) console.log("Updated member conections data for this edge", memberConections, networkGraphs[k].edges[i]);

													
						}
						
						// if (DEBUG_MODE >= 2) console.log("Generated member conections data for this network graph", networkGraphs[k], memberConections);
								
						//Store team member connection data 
						networkGraphs[k].teamMemberConections = [...memberConections];

						
						//Compile data for each Team, for this graph
						
						const teamStats =[];
						
						for (var ll=0; ll < teams.length; ll++) {
							 
								//Get just this Team's density records, which includes a single record per user
								const thisTeamsUserRecords = memberConections.filter(record => record.teamID == teams[ll].id);
								
								//Run through all user records for this team and compile team stats
								var memberConectionsMax = 0;
								var memberConectionsTotal = 0;
								var memberConectionsMin = 999999;   
								var memberConnectionsMean = 0;
								var memberConnectionsNormalizedMean = 0;
								var memberConnectionsVariance = 0;
								var memberConnectionsStandardDeviation = 0;
								var memberConnectionsNormalizedStandardDeviation = 0;
								var maxPossibleInPeriod = setMaxConectivitiesInPeriod(networkGraphs[k].periodStart, networkGraphs[k].periodEnd,networkGraphs[k].category, currentUser.customer.configDetails);
								var memberConnectionsInverseNormalizedStandardDeviation = 0;
								

								for (var kk=0; kk < thisTeamsUserRecords.length; kk++) {
										
										memberConectionsTotal += thisTeamsUserRecords[kk].conectivitiesWithTeamMembers;
										if (thisTeamsUserRecords[kk].conectivitiesWithTeamMembers > memberConectionsMax)  memberConectionsMax = thisTeamsUserRecords[kk].conectivitiesWithTeamMembers;
										if (thisTeamsUserRecords[kk].conectivitiesWithTeamMembers < memberConectionsMin)  memberConectionsMin = thisTeamsUserRecords[kk].conectivitiesWithTeamMembers;
										
								}
								
								//Calculate the "normalized standard deviation" where the standard deviation is given as a fraction of its mean. 
								//Using this statistic allows the spread of the distribution of a variable with a large mean and correspondingly large standard deviation to be compared more appropriately with the spread of the distribution of another variable with smaller mean and correspondingly smaller standard deviation
								//Standard deviation is a statistic that measures the dispersion of a dataset relative to its mean

								if (teams[ll].memberCount > 1 && memberConectionsTotal > 0) {
										memberConnectionsMean = memberConectionsTotal / teams[ll].memberCount;
										if (maxPossibleInPeriod > 0) {
												memberConnectionsNormalizedMean = Math.round(100 * (memberConnectionsMean / maxPossibleInPeriod)); //Normalize to a score from 1 to 100
												
										}
								
										//Sum squares of distances of records
										for (var kk=0; kk < thisTeamsUserRecords.length; kk++)  memberConnectionsVariance += (thisTeamsUserRecords[kk].conectivitiesWithTeamMembers - memberConnectionsMean) ** 2;  
										
										//Add in the square of the distance from the mean for all members with no records (thus entries of zero)
										memberConnectionsVariance += (teams[ll].memberCount - thisTeamsUserRecords.length) * (memberConnectionsMean ** 2); 
										
										memberConnectionsVariance = memberConnectionsVariance / (teams[ll].memberCount-1);
										
										memberConnectionsStandardDeviation = Math.sqrt(memberConnectionsVariance);
										
										const maxCoefficientOfVariability = 5.0;
										//Calculate the coefficient of variability as a percentage; assume anything higher than 500% is MAX HIGH (0.0 on our scale)
										memberConnectionsNormalizedStandardDeviation = memberConnectionsStandardDeviation / memberConnectionsMean;
										if (memberConnectionsNormalizedStandardDeviation > maxCoefficientOfVariability) memberConnectionsNormalizedStandardDeviation = maxCoefficientOfVariability; //Clip at what is conisidered HIGH (5.0)
										if (memberConnectionsNormalizedStandardDeviation < 0) memberConnectionsNormalizedStandardDeviation = 0; //Clip at what is conisidered LOW (0.0)
										memberConnectionsInverseNormalizedStandardDeviation = maxCoefficientOfVariability - memberConnectionsNormalizedStandardDeviation; //Invert our percentage in the range of 0-5 where HIGH variability is a zero
										memberConnectionsInverseNormalizedStandardDeviation = Math.round(memberConnectionsInverseNormalizedStandardDeviation * (100/maxCoefficientOfVariability)) ; //Scale so a 5.0 is a 100
									 
										
										//Round the numbers
										memberConnectionsMean = Math.round(memberConnectionsMean * 100) / 100;
										memberConnectionsVariance =  Math.round(memberConnectionsVariance * 100) / 100;
										memberConnectionsStandardDeviation =  Math.round(memberConnectionsStandardDeviation * 100) / 100;
										memberConnectionsNormalizedStandardDeviation =  Math.round(memberConnectionsNormalizedStandardDeviation * 100) / 100;
										
										
								} else {
										memberConectionsMin = 0; // No records at all, so set min to zero
								}
								
								if (thisTeamsUserRecords.length < teams[ll].memberCount) memberConectionsMin = 0; // At least some team members with NO records, so set min to zero
								
								// if (DEBUG_MODE >= 2) console.log("Compiled density data for team", teams[ll].name, thisTeamsUserRecords);
								// if (DEBUG_MODE >= 2) console.log("Density - number of members", teams[ll].memberCount);
								// if (DEBUG_MODE >= 2) console.log("Density - Period", networkGraphs[k].period);
								// if (DEBUG_MODE >= 2) console.log("Density - Category", networkGraphs[k].category);
								// if (DEBUG_MODE >= 2) console.log("Density - Max Possible In Period", maxPossibleInPeriod);
								// if (DEBUG_MODE >= 2) console.log("Density - total member connections", memberConectionsTotal);
								// if (DEBUG_MODE >= 2) console.log("Density - max member connections", memberConectionsMax);
								// if (DEBUG_MODE >= 2) console.log("Density - min member connections", memberConectionsMin);
								// if (DEBUG_MODE >= 2) console.log("Density - range member connections", memberConectionsMax - memberConectionsMin);
								// if (DEBUG_MODE >= 2) console.log("Density - mean connections", memberConnectionsMean);
								// if (DEBUG_MODE >= 2) console.log("Density - mean connections normalized by max possible conectivities", memberConnectionsNormalizedMean);
								// if (DEBUG_MODE >= 2) console.log("Density - Variance", memberConnectionsVariance);
								// if (DEBUG_MODE >= 2) console.log("Density - Standard Deviation", memberConnectionsStandardDeviation);
								// if (DEBUG_MODE >= 2) console.log("Density - Normalized Standard Deviation", memberConnectionsNormalizedStandardDeviation);
								// if (DEBUG_MODE >= 2) console.log("Density - Inverse Normalized Standard Deviation", memberConnectionsInverseNormalizedStandardDeviation);

								teamStats.push({
										teamID:teams[ll].id,
										name:teams[ll].name,
										period:networkGraphs[k].period,
										category:networkGraphs[k].category,
										maxPossibleInPeriod:maxPossibleInPeriod,
										memberCount:teams[ll].memberCount,
										memberConectionsTotal: memberConectionsTotal,
										memberConectionsMax: memberConectionsMax,
										memberConectionsMin: memberConectionsMin,
										memberConectionsRange: memberConectionsMax - memberConectionsMin,
										memberConnectionsMean: memberConnectionsMean,
										memberConnectionsNormalizedMean: memberConnectionsNormalizedMean,
										memberConnectionsVariance: memberConnectionsVariance,
										memberConnectionsStandardDeviation: memberConnectionsStandardDeviation,
										memberConnectionsNormalizedStandardDeviation: memberConnectionsNormalizedStandardDeviation,
										memberConnectionsInverseNormalizedStandardDeviation: memberConnectionsInverseNormalizedStandardDeviation,
								});
								
						}
						
						//Store our results
						networkGraphs[k].teamStats = [...teamStats];
							 
			 }
			 
			 if (DEBUG_MODE >= 2) console.log("Calculated team density analytics for network graphs", networkGraphs);
			 
	 } 
	 

		function setMaxConectivitiesInPeriod(graphPeriodStart, graphPeriodEnd, category, configDetails) {
				
				//First, calculate an estiamte of how many conectivities of this category a user is expected to do in a day (fraction from 0 - 1)
				var estGoalConectivitiesPerDay = 0;
				
				switch (category) {
						 case "ALL":
										//For ALL, assume specific periods for now to come up with an estimated max possible Conectivities
										estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing + configDetails.socialConectivitiesToCloseRing / 5 + configDetails.teamConectivitiesToCloseRing / 5  + configDetails.individualConectivitiesToCloseRing / 21;
										break;
						 case "DEI":
										//For DEI & CS, assume specific periods for now to come up with an estimated max possible Conectivities
										estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing + configDetails.socialConectivitiesToCloseRing / 5 + configDetails.teamConectivitiesToCloseRing / 5  + configDetails.individualConectivitiesToCloseRing / 21;
										estGoalConectivitiesPerDay = .1 % estGoalConectivitiesPerDay; //Assume 10% conectivities for DEI, including balance, would be excellent
										break;
										
						 case "CS":
										//For ALL, assume specific periods for now to come up with an estimated max possible Conectivities
										estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing + configDetails.socialConectivitiesToCloseRing / 5 + configDetails.teamConectivitiesToCloseRing / 5  + configDetails.individualConectivitiesToCloseRing / 21;
										estGoalConectivitiesPerDay = .1 % estGoalConectivitiesPerDay; //Assume 10% conectivities for CS, including balance, would be excellent

										break;

								
						case "SOCIAL":
								switch (configDetails.socialPeriodToCloseRing) {
										case "DAILY":
										 estGoalConectivitiesPerDay =  configDetails.socialConectivitiesToCloseRing;
											break;
										case "WEEKLY":
											estGoalConectivitiesPerDay =  configDetails.socialConectivitiesToCloseRing / 5;

												break;
										case "MONTHLY":
											estGoalConectivitiesPerDay =  configDetails.socialConectivitiesToCloseRing / 21;


												break;
									 case "QUARTERLY":
											estGoalConectivitiesPerDay =  configDetails.socialConectivitiesToCloseRing / 65;

												break;
									 case "YEARLY":
											estGoalConectivitiesPerDay =  configDetails.socialConectivitiesToCloseRing / 260;

												break;
									 default:
											if (DEBUG_MODE >= 2) console.log("Error - no matching CONFIG period" );
								}
								break;
								
						case "TEAM":
								switch (configDetails.teamPeriodToCloseRing) {
									case "DAILY":
										 estGoalConectivitiesPerDay =  configDetails.teamConectivitiesToCloseRing;
											break;
										case "WEEKLY":
											estGoalConectivitiesPerDay =  configDetails.teamConectivitiesToCloseRing / 5;

												break;
										case "MONTHLY":
											estGoalConectivitiesPerDay =  configDetails.teamConectivitiesToCloseRing / 21;


												break;
									 case "QUARTERLY":
											estGoalConectivitiesPerDay =  configDetails.teamConectivitiesToCloseRing / 65;

												break;
									 case "YEARLY":
											estGoalConectivitiesPerDay =  configDetails.teamConectivitiesToCloseRing / 260;

												break;
									default:
											if (DEBUG_MODE >= 2) console.log("Error - no matching CONFIG period" );
								}
								break;
						 
						case "BALANCE":
								switch (configDetails.stressPeriodToCloseRing) {
									 case "DAILY":
										 estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing;
											break;
										case "WEEKLY":
											estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing / 5;

												break;
										case "MONTHLY":
											estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing / 21;


												break;
									 case "QUARTERLY":
											estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing / 65;

												break;
									 case "YEARLY":
											estGoalConectivitiesPerDay =  configDetails.stressConectivitiesToCloseRing / 260;

												break;
									default:
											if (DEBUG_MODE >= 2) console.log("Error - no matching CONFIG period" );
								}
								break;
						 
						case "PERSONAL":
								switch (configDetails.individualPeriodToCloseRing) {
									 case "DAILY":
										 estGoalConectivitiesPerDay =  configDetails.individualConectivitiesToCloseRing;
											break;
										case "WEEKLY":
											estGoalConectivitiesPerDay =  configDetails.individualConectivitiesToCloseRing / 5;

												break;
										case "MONTHLY":
											estGoalConectivitiesPerDay =  configDetails.individualConectivitiesToCloseRing / 21;


												break;
									 case "QUARTERLY":
											estGoalConectivitiesPerDay =  configDetails.individualConectivitiesToCloseRing / 65;

												break;
									 case "YEARLY":
											estGoalConectivitiesPerDay =  configDetails.individualConectivitiesToCloseRing / 260;

												break;
									 default:
											if (DEBUG_MODE >= 2) console.log("Error - no matching CONFIG period" );
								}
								break;
						default:
											if (DEBUG_MODE >= 2) console.log("Error - no matching Category", category, );
					}
 
		//   if (DEBUG_MODE >= 2) console.log("Set estimated max conectivities per day ", estGoalConectivitiesPerDay, graphPeriod, category, );


		//Now return the max possible based on the period of the graph by scaling up our daily estimate based on the actual DAYS in this particular period
		
				const periodStartMoment = moment(graphPeriodStart, "YYYY MM DDTHH mm ssZ");
				const periodEndMoment = moment(graphPeriodEnd, "YYYY MM DDTHH mm ssZ");
				
				let daysInPeriod = 0;
					while (periodStartMoment.isSameOrBefore(periodEndMoment, 'day')) {
						if (periodStartMoment.day() !== 0 && periodStartMoment.day() !== 6) {
							daysInPeriod++;
						}
						periodStartMoment.add(1, 'd');
					}        
				
				// if (DEBUG_MODE >= 2) console.log("Graph period start", periodStartMoment.toISOString());
				// if (DEBUG_MODE >= 2) console.log("Graph period end", periodEndMoment.toISOString());
				// if (DEBUG_MODE >= 2) console.log("Determined actual days in graph period", daysInPeriod);
				
				estGoalConectivitiesPerDay = Math.round(daysInPeriod * estGoalConectivitiesPerDay * 100) / 100;
				
				return estGoalConectivitiesPerDay;
		
		}
		

		//return the provider
	 return (
		<div>
				<GraphAnalyticsContext.Provider value={
				{   
						graphDataBuiltFlag, 
						masterGraphData,
						fetchRecordsTrigger, setFetchRecordsTrigger            
				}}>
					{children}
				</GraphAnalyticsContext.Provider>
				
		</div>
	); 
		
};


export { GraphAnalyticsContext, GraphAnalyticsProvider };
//
//  PROPRIETARY AND CONFIDENTIAL
//
//  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
//  Copyright 2020-2024.  All Rights Reserved

//DATA
import  { CONECTERE_CONFIG_DATA, DEBUG_MODE, COLOR_BLUE_HEADER, COLOR_GOOD, COLOR_WARNING_LOW, COLOR_WARNING_HIGH, COLOR_ALERT, TEAM_COLOR, FAINT_GREY } from '../../../../shared/data/conectereConfigData';

//React and AWS components
import React, { useState, useContext, useEffect, useRef } from 'react';

//Our Components
import ModalNoBackground from "../../../../shared/Components/modalNoBackground/modalNoBackground";
import { ConectereDivider } from "../../../../shared/utils/generalUtils";

//Other Components
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//Utils
import { displayRecognizedUsersAvatars } from "../../../../shared/utils/spotlightUtils.js";
import { DisplayUserSelectComponent, getUserInitials } from "../../../../shared/utils/userAndTeamUtils.js";
import { recordTransaction } from "../../../../shared/utils/databaseUtils.js";

//Icons
import RedeemIcon from '@mui/icons-material/Redeem';

//CONTEXT
import { CustomerContext } from '../../../../shared/context/customerContext';        //User Authentication context
import { NotificationContext } from '../../../../shared/context/notificationsContext';
import { DisplayContext } from '../../../../shared/context/displayContext';            //User Authentication Context
import { ModalContext } from '../../../../shared/context/modalContext';            

const TransactionAdjustment = ({ showModalAdjustment, handleCloseAdjustmentModal }) => {

	//Context
	const { setShowSpinner } = useContext(DisplayContext); 
	const { users, usersOptions} = useContext(CustomerContext); 
	const { pushNotification } = useContext(NotificationContext);
	const { setShowModalGeneral, setModalGeneralMessage, setModalGeneralTitle } = useContext(ModalContext); 

	//State data
	const [selectedUser, setSelectedUser] = useState(null);     
	const [coinsForAdjustment,setCoinsForAdjustment] = useState(0);
	const [transactionMessage, setTransactionMessage] = useState("");
	const [messageInputBarHeight, setMessageInputBarHeight] = useState("32px");
	const messaeInputRef = useRef();
	const [adjustmentType, setAdjustmentType] = useState('CREDIT');


	async function handleConfirmation() {
		if (DEBUG_MODE >= 2) console.log("Recording transaction");
		setShowSpinner(true); //Show spinners
		let success;

		const recipient = users.find(user => user.id === selectedUser.id);  
		if (!recipient && !recipient.id) {
			if (DEBUG_MODE) console.error("Error recording transaction.  Recipient not found", selectedUser);
			return;
		}

		try {
			//record transaction 
			let tempTransaction = await recordTransaction({
				walletOwner:recipient, 
				title: transactionMessage,
				memo:(adjustmentType==='CREDIT' ? "Credit of " + coinsForAdjustment + " applied" : "Debit of " + coinsForAdjustment + " applied"),
				amount:parseInt(coinsForAdjustment)* (adjustmentType==='CREDIT' ? 1 : -1),
				transactionType:"ADJUSTMENT",
			});              
			
			//Send a push notification to the recipient
			if (tempTransaction && tempTransaction.id) {
				await pushNotification({
					notificationTypeLabel:'ADJUSTMENT', 
					userForPush:recipient, 
					dynamicMessage: (adjustmentType==='CREDIT' ? "Credit of " + coinsForAdjustment + " applied" : "Debit of " + coinsForAdjustment + " applied"), 
				});  
				success = true;
			}
		} catch (err) {
			if (DEBUG_MODE >= 2) console.log('error creating transaction:', err);
		}

		if (success) {
			 setModalGeneralMessage("Transaction record.  Please refresh the page");
			 setModalGeneralTitle("Success!");
			 setShowModalGeneral(true);   
		} else {
			setModalGeneralMessage("Something went wrong.");
			 setModalGeneralTitle("Ooops!");
			 setShowModalGeneral(true);   
			 
		}
		setShowSpinner(false); //Hode spinners
		setCoinsForAdjustment(0);
		setTransactionMessage('');
		setSelectedUser(null);
		if (handleCloseAdjustmentModal) handleCloseAdjustmentModal();
  }

  function handleClose() {
	setCoinsForAdjustment(0);
	setTransactionMessage('');
	setSelectedUser(null);
	if (handleCloseAdjustmentModal) handleCloseAdjustmentModal();
  }
	function handleMessageChange(value) {
		 
		//Dynamically adjust height of our input region as the user types
		if (messaeInputRef && messaeInputRef.current) {
			 var newHeight = (value.length < 20 ? "32px" : messaeInputRef.current.scrollHeight.toString() + "px" );
			 setMessageInputBarHeight(newHeight);
			 // if (DEBUG_MODE >= 2) console.log("Gift Message input bar change.  Amount to scroll to bottom", messaeInputRef.current.scrollHeight, newHeight);
		}             
		setTransactionMessage(value);	
  }    

   return (
		<>
			<ModalNoBackground showModal={showModalAdjustment} closeCallback={handleClose} cardColor={TEAM_COLOR}>
				<div className="modalNoBkgInnerCard" style={{backgroundColor:"black"}}>
					<div className="modalNoBkgCenterColumn" style={{minHeight:"350px", padding:"10px"}}>
						<div className="ContainerNoHeightCenter">
							<div  style={{width:"100%", fontSize:"1.4em", fontWeight:"500", color:"#409cf7"}}>
									Make a transaction adjustment to a user's wallet
							</div>  
								<div className = "rotateCoins"> 
									<img  className="ccImgXXXL" src={CONECTERE_CONFIG_DATA.COIN_IMAGE_PATH} alt='coins'/> 
							</div>
						</div>
						<div className="spotlightRecognizedUsersAvatarsWrapper" style={{minHeight:"64px", position:"relative"}} >
							{displayRecognizedUsersAvatars({recognizedEmployees:[selectedUser], maxUsersToShow:7, isSmall:false, isFull:true, showQuestionMarkIfNone:true, showHalo:true})}
						</div>       

						<ConectereDivider />

						<div className="ContainerVerticalCenter">
							<div className="ContainerNoHeightCenter">
								<RadioGroup row aria-label="byCategory" name="byCategory" value={adjustmentType} onChange={(event) => setAdjustmentType(event.target.value)}>
										<FormControlLabel value="CREDIT" control={<Radio />} label="Credit" />
										<FormControlLabel value="DEBIT" control={<Radio />} label="Debit" />
								</RadioGroup>		

								<div className="ContainerNoHeight" style={{zIndex:"7777", alignItems:"flex-start", position:"relative", padding:"20px" }}>
										{adjustmentType==='CREDIT' ? <span className="TextStyle5 green" style={{minWidth:"20px"}}>+</span> : <span className="TextStyle5 red" style={{minWidth:"20px"}}>-</span>}
										<div className="ContainerNoHeight"  style={{alignItems: 'flex-end'}}>
												<input
													style={{border: ( (!coinsForAdjustment || Number(coinsForAdjustment < 0)) ? "1px solid red" : "1px solid grey")}}
													name="coinsToGive"
													type="number"
													min="1" max="10000" maxLength="4"
													value={coinsForAdjustment}
													onChange={event => setCoinsForAdjustment(event.target.value)}
													required
												/>
												<div className='TextStyle3 blue' style={{paddingLeft:"clamp(4px,1vw,10px)"}}> coins </div>
										</div>									
								</div> 
							</div>
						
							<DisplayUserSelectComponent name={"userDropdown"} usersOptions={usersOptions} handleSelectUser={setSelectedUser} isMulti={false} selectedUsersOptions={selectedUser} placeholder='Individuals' zIndex="99990"/>

							<div className = "conectereMessagingInputArea" >
								<textarea
										ref={messaeInputRef}
										className="TextStyle3 conectereMessagingInputBar"
										style={{height:messageInputBarHeight}}
										value={transactionMessage}
										onChange={event => handleMessageChange(event.target.value)}
										placeholder="Reason for adjustment"
										rows="1"
								/>
							</div>  
					

						</div>
						<div  className="ContainerNoHeightFlexEndCentered" style={{width:"100%", padding:"20px"}}>
							<button className="buttonStyle3" onClick={handleConfirmation} disabled={(!coinsForAdjustment || coinsForAdjustment==0 || isNaN(coinsForAdjustment) || !selectedUser || selectedUser.length===0)} >
								<div className="ContainerNoHeightCenter"> 
									Record
								</div>
							</button>
						</div>
					</div>
				</div>
			</ModalNoBackground >

	
		</>
   ); 
};

export default TransactionAdjustment;